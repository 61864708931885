const theme = {
  token: {
    colorPrimary: "#EA7E30",
    colorPrimaryBorder: "#FBE1C1",
    itemActiveBg: "rgba(255, 255, 255, 0)",
    colorLink: "#EA7E30",
    colorLinkActive: "#EA7E30",
    colorLinkHover: "#F5BE51",
    // colorTextDisabled: "#FFF7E8",
    contentFontSizeLG: 17.0,
    controlHeightLG: 48,
  },
  cssVar: true,
  hashed: false,
};

export default theme;
