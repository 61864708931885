import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { FormattedMessage, useIntl } from "react-intl";
import Image from "react-bootstrap/Image";
import {
    ConfigProvider,
    Tabs,
    DatePicker,
    Form,
    Row,
    Col,
    Checkbox,
    Input,
    InputNumber,
    Select,
    Modal,
    Button,
    Spin,
    message,
    Radio
} from "antd";
import theme from "@/AntDesignConfig";

import italicTextLogo from "@/assets/italic_text_logo.png";
import topAlertIcon from "@/assets/top_alert_icon.png";
import palletIcon from "@/assets/pallet_icon@3x.png";
import boxIcon from "@/assets/box_icon@3x.png";
import startIcon from "@/assets/Start@3x.png";
import endIcon from "@/assets/Stop@3x.png";
import JSONBig from "json-bigint";
import { BASE_API_URL } from "@/network.config";
import checkIcon from "@/assets/check.png";

import addCardIcon from "@/assets/add.svg";
import { createStyles } from "antd-style";
import timerIcon from "@/assets/timer_icon.png";

const useStyle = createStyles(({ prefixCls, css }) => ({
    linearGradientButton: css`
    &.${prefixCls}-btn-primary:not([disabled]):not(
        .${prefixCls}-btn-dangerous
      ) {
      border-width: 0;

      > span {
        position: relative;
        font-size: 17px;
        font-weight: 600;
      }

      &::before {
        content: "";
        background: linear-gradient(166deg, #ea7e30 0%, #f5be51 100%);
        position: absolute;
        inset: 0;
        opacity: 1;
        transition: all 0.3s;
        border-radius: inherit;
      }

      &:hover::before {
        opacity: 0;
      }
    }
  `,
}));

export default function OrderSuccess(props) {

    const { orderId } = props;

    const [messageApi, contextHolder] = message.useMessage();
    const [step, setStep] = useState(0);
    const showAlertMessage = (content) => {
        messageApi.open({
            type: "error",
            content: content,
            style: {
                marginTop: "30vh",
            },
        });
    };

    const [cardForm] = Form.useForm();
    const [addressForm] = Form.useForm();
    const [cardList, setCardList] = useState([
    ]);
    const [selectedCard, setSelectedCard] = useState(null);
    const [newCard, setNewCard] = useState(null);
    const [showForm, setShowForm] = useState(false);

    const [isNewAddress, setIsNewAddress] = useState(false);


    const goToQuote = () => {
        props.goToQuote();
    }

    const goToOrderList = () => {
        props.goToOrderList();
    }

    return (

            <div className="quote-wrapper">
                {contextHolder}
                <div className="quote-wrapper-3">
                    <Container className="form-container" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'end',
                        alignItems: 'end',
                    }}>
                        <div style={{ 
                            width: '100%', 
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'center',
                            backgroundColor: '#fff',
                            padding: '40px',
                            borderRadius: '8px',
                        }}>
                            <div style={{
                                background: 'linear-gradient(170.37deg, rgba(234,126,48,0.2) 0%, rgba(245,190,81,0.2) 100%)',
                                width: '68px',
                                height: '68px',
                                gap: '0px',
                                borderRadius: '34px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'relative',
                                zIndex: 0,
                                marginBottom: '20px'
                            }}>
                                <div style={{
                                    background: 'black',
                                    opacity:1,
                                    background: 'linear-gradient(170.37deg, #EA7E30 0%, #F5BE51 100%)',
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: '20px',
                                    position: 'absolute',
                                    zIndex: 999,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <img style={{
                                        width: '16.36px',
                                        height: '11.82px',
                                    }} src={checkIcon}></img>
                                </div>
                            </div>
                            <div 
                                style={{
                                    fontFamily: 'PingFang SC',
                                    fontSize: '22px',
                                    fontWeight: 400,
                                    lineHeight: '30.8px',
                                    textAlign: 'center',
                                    width: '100%',
                                    paddingLeft: '20px',
                                }}
                            >恭喜！下单成功！</div>
                            <div 
                                style={{
                                    width: '480px',
                                    height: '86px',
                                    padding: '32px 24px',
                                    gap: '10px',
                                    borderRadius: '8px 0 0 0',
                                    background: '#F2F3F5',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '40px 0'
                                }}
                            >
                                <img style={{
                                    width: '19.21px',
                                    height: '19.17px',
                                    top: '1.83px',
                                    gap: '0px',
                                    border: '2px 0px 0px 0px',
                                }} src={timerIcon}></img>
                                <div style={{
                                    fontFamily: 'PingFang SC',
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    lineHeight: '21.5px',
                                    textAlign: 'left'


                            }}>等待司机接单</div></div>
                            <div style={{
                                fontFamily: 'PingFang SC',
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '21.5px',
                                textAlign: 'left'
                            }}>订单号: {orderId}</div>
                            <div style={{
                                fontFamily: 'PingFang SC',
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '21.5px',
                                textAlign: 'left'
                            }}>请前往<span onClick={goToOrderList} style={{textDecoration: 'underline'}}>已发布订单</span>查看订单最新状态</div>
                        </div>

                        
                            <div style={{display: "flex", justifyContent: "end"}}>
                            <button 
                                style={{ 
                                    width:'194px',
                                    height:'60px',
                                    border: "1px solid #EA7E30",
                                    color: '#EA7E30',
                                    borderRadius: '8px', 
                                    marginTop: '50px',
                                    cursor: 'pointer',
                                    background: 'transparent',
                                    marginRight: '10px',
                                    // background: 'linear-gradient(165.96deg, #EA7E30 0%, #F5BE51 100%)',
                                }}
                                onClick={goToQuote}
                            >再下一单</button>
                            

                            <button 
                                style={{ 
                                    width:'194px',
                                    height:'60px',
                                    border: "none",
                                    color: '#fff',
                                    borderRadius: '8px', 
                                    marginTop: '50px',
                                    cursor: 'pointer',
                                    background: 'linear-gradient(165.96deg, #EA7E30 0%, #F5BE51 100%)',
                                }}
                                onClick={goToOrderList}
                            >订单页面</button>
                            </div>

                    </Container>
                </div>
            </div>
    );
}
