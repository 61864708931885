export default {
  "page.login.header.back.home": "返回首页",
  "page.login.header.manual": "使用手册",
  "page.login.title.main.1": "欢迎使用空仓货运",
  "page.login.title.main.2": "运单管理平台",
  "page.login.title.phone": "手机号",
  "page.login.title.code": "登录口令",
  "page.login.button.login": "登录",
  "page.login.error.required.phone": "请输入手机号!",
  "page.login.error.required.code": "请输入登录口令!",
};
