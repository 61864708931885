import React, { useState, useEffect, } from "react";
import Container from "react-bootstrap/Container";
import { FormattedMessage } from "react-intl";
import Image from "react-bootstrap/Image";
import {
  ConfigProvider,
  Tabs,
  Form,
  Input,
  Modal,
  Button,
  message,
} from "antd";
import QuoteForm from "./components/quote";
import PlaceOrder from "./components/placeOrder";
import theme from "@/AntDesignConfig";

import italicTextLogo from "@/assets/italic_text_logo.png";

import "./index.css";
import { createStyles } from "antd-style";
import CardForm from "./components/CardForm";

import JSONBig from "json-bigint";
import { BASE_API_URL } from "@/network.config";
import qs from "qs";
import OrderSuccess from "./components/orderSuccess";
import { useSearchParams } from "react-router-dom";

import OrderList from "./components/OrderList";
const useStyle = createStyles(({ prefixCls, css }) => ({
  linearGradientButton: css`
    &.${prefixCls}-btn-primary:not([disabled]):not(
        .${prefixCls}-btn-dangerous
      ) {
      border-width: 0;

      > span {
        position: relative;
        font-size: 17px;
        font-weight: 600;
      }

      &::before {
        content: "";
        background: linear-gradient(166deg, #ea7e30 0%, #f5be51 100%);
        position: absolute;
        inset: 0;
        opacity: 1;
        transition: all 0.3s;
        border-radius: inherit;
      }

      &:hover::before {
        opacity: 0;
      }
    }
  `,
}));

const useCountDown = (s) => {
  const [seconds, setSeconds] = useState(s);
  useEffect(() => {
    setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
  }, [seconds]);

  return [seconds, setSeconds];
};

export default function Quote() {

  const [searchParams] = useSearchParams();
  const { styles } = useStyle();

  const [messageApi, contextHolder] = message.useMessage();

  const [finalOrderData, setFinalOrderData] = useState(null);

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [loginForm] = Form.useForm();
  const [smsCount, setSMSCount] = useCountDown(0);
  const [isSMSLoading, setIsSMSLoading] = useState(false);
  const [shipperToken, setShipperToken] = useState(null);
  const [tab, setTab] = useState('1');

  const [tabBadge, setTabBadge] = useState({});

  const [orderSuccess, setOrderSuccess] = useState(false);

  // 订单数据 未支付
  const [orderId, setOrderId] = useState(undefined);

  const [quoteData, setQuoteData] = useState({});

  const [orderList, setOrderList] = useState([]);
  const [orderListLoading, setOrderListLoading] = useState(false);

  useEffect(() => {
    // get token form local storage using key : "shipper_token"
    let  token = localStorage.getItem("shipper_token");
    if (token) {
      setShipperToken(token);
      getTabBadge().then((badge) => {
        setTabBadge(badge);
      });
    }else{
      if (searchParams.get('modal') === '1') {
        setTimeout(() => {
          setIsLoginModalOpen(true);
          // remove modal param
          window.history.replaceState({}, document.title, window.location.pathname);
        }, 1000);
      }
    }
  }, []);

  const goToOrderList = () => {
    setTab('0');
  }

  const fetchOrderList = async (tab) => {
    setOrderListLoading(true);

    // param tab 0-我的发布，1-运单消息，2-运输中，3-已完成
    let payload = {
      tab: tab,
    }
    let params = {
      page_num: 1,
      page_size: 100,
      tab: tab,
    }
    let token = localStorage.getItem("shipper_token");
    let headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
    const response = await fetch(`${BASE_API_URL}/api/v1/app/shipper/orders?${qs.stringify(params)}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: headers,
    });
    if (response.status === 401) {
      setIsLoginModalOpen(true);
      setOrderListLoading(false);
      return;
    }
    if (!response.ok) {
      setOrderListLoading(false);
      return;
    }
    const text = await response.text();
    const result = JSONBig({ storeAsString: true }).parse(text);
    setOrderList(result.data.data);
    setOrderListLoading(false);
  }

  const deleteOrder = async (orderId) => {
    console.log('deleteOrder', orderId);
    let token = localStorage.getItem("shipper_token");
    let headers = {
      "Content-Type": "application/json", 
      "Authorization": `Bearer ${token}`
    }
    const response = await fetch(`${BASE_API_URL}/api/v1/app/shipper/orders/${orderId}`, {
      method: 'DELETE',
      headers: headers,
    });
    if (response.status === 401) {
      setIsLoginModalOpen(true);
      return;
    }
    if (!response.ok) {
      messageApi.error('Delete order failed');
      return;
    }
    messageApi.success('订单删除成功');
    await fetchOrderList(tab);
  }

  useEffect(() => {
    if (tab !== '1') {
      fetchOrderList(tab);
    }
  }, [tab]);

  const goToQuote = () => {
    setPlaceOrderShow(false);
    setOrderSuccess(false);
    setOrderId(undefined);
    setTab('1');
  }

  const login = async (params) => {
    const payload = {
      tel: params.phone,
      country_code: "+1",
      code: params.password,
      role: 0,
    }
    try {
      const response = await fetch(`${BASE_API_URL}/api/v1/app/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      if (result.code !== 0) {
        console.error(`Login failed: ${result.errmsg}`);
        messageApi.error(result.errmsg);
        return null;
      }
      const { access_token, user_id, current_role, is_verify } = result.data;
      localStorage.setItem('shipper_token', access_token);
      setShipperToken(access_token);
      setIsLoginModalOpen(false);

      return access_token;
    } catch (error) {
      console.error('Login error:', error);
      messageApi.error('Login failed. Please try again.');
      return null;
    }
  }

  const fetchCode = async (params) => {
    try {
      setIsSMSLoading(true);
      const response = await fetch(`${BASE_API_URL}/api/v1/app/sendsms`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      });

      if (response.status !== 200) {
        console.log(`request login send code api failed:${response.status}`);
        messageApi.error("网络错误");
        return;
      }
      setSMSCount(60);
    } catch (error) {
      console.log(`request login api error: ${error}`);
      messageApi.error("网络错误");
    } finally {
      setIsSMSLoading(false);
    }
  };

  const getTabBadge = async () => {
    try {
      const token = localStorage.getItem("shipper_token");
      if (!token) {
        return;
      }
      const response = await fetch(`${BASE_API_URL}/api/v1/app/unread/message/badge`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        }
      });
      if (response.status === 401){
        localStorage.removeItem("shipper_token");
        setShipperToken(null);
        return;
      }
      const text = await response.text();
      const result = JSONBig({ storeAsString: true }).parse(text);
      console.log('getTabBadge result', result);

    } catch (error) {
      console.log(`get badge api error: ${error.message}`);
    }
  };
  const placeOrder = async (data) => {
    try {
      const response = await fetch(`${BASE_API_URL}/api/v1/app/shipper/order/submit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${shipperToken}`
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const text = await response.text();
      const result = JSONBig({ storeAsString: true }).parse(text);
      console.log('place order result', result);
      if (result.code !== 0) {
        console.log(`place order api failed:${JSON.stringify(result)}`);
        messageApi.error(result.errmsg);
        return;
      }
      console.log('place order result', result);
      setOrderId(result.data.order_id);
      setFinalOrderData(data);
    } catch (error) {
      console.log(`place order api error: ${error.message}`);
      messageApi.error("网络错误");
    }
  };

  const getOrderDetail = async (orderId) => {
    // 订单详情
    // /api/v1/app/shipper/orders/{order_id}
    const response = await fetch(`${BASE_API_URL}/api/v1/app/shipper/orders/${orderId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${shipperToken}`
      }
    });
    const text = await response.text();
    const result = JSONBig({ storeAsString: true }).parse(text);
    console.log('getOrderDetail result', result);
    setFinalOrderData(result.data)
  }

  const continuePay = (orderId) => {
    console.log('continuePay', orderId);
    // 订单列表 未支付订单 支付
    setOrderId(orderId);
    getOrderDetail(orderId)
    setOrderSuccess(false);
    setTab('1');
  }

  // payment
  const pay = async (data) => {
    console.log('pay', data);
    let payload = {
      order_id: orderId,
      card_id: data.card_id,
      billing_address: data.billing_address ? data.billing_address : {
        name: finalOrderData.pickup_address.name,
        address_line1: finalOrderData.pickup_address.address_line1,
        address_line2: finalOrderData.pickup_address.address_line2,
        city: finalOrderData.pickup_address.city,
        state: finalOrderData.pickup_address.state,
        zipcode: finalOrderData.pickup_address.zipcode,
      },
    }
    try {
      const response = await fetch(`${BASE_API_URL}/api/v1/app/shipper/order/payment/v2`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${shipperToken}`
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const text = await response.text();
      const result = JSONBig({ storeAsString: true }).parse(text);

      if (result.code !== 0) {
        console.log(`payment api failed: ${JSON.stringify(result)}`);
        messageApi.error(result.errmsg);
        return;
      }
      console.log('payment result', result);
      setOrderSuccess(true);
    } catch (error) {
      console.log(`payment api error: ${error.message}`);
      messageApi.error("网络错误");
    }
  };

  const onTabChange = (key) => {
    if (['0','2', '3'].includes(key)) {
      if (!shipperToken) {
        setIsLoginModalOpen(true);
        return;
      }
    }
    setTab(key);
  };
  const tabItems = [
    {
      key: "1",
      label: "在线下单",
    },
    {
      key: "0",
      label: "已发布订单",
    },
    {
      key: "2",
      label: "运输中订单",
    },
    {
      key: "3",
      label: "已完成订单",
    },
  ];

  const [placeOrderShow, setPlaceOrderShow] = useState(false);
  const [isPallet, setIsPallet] = useState(false);
  const gotoPlaceOrder = (gotQuoteData) => {
    setQuoteData(gotQuoteData);
    setIsPallet(gotQuoteData.is_pallet)
    console.log('shipperToken', shipperToken);
    if (!shipperToken) {
      setIsLoginModalOpen(true);
      return;
    }
    setPlaceOrderShow(true)
  }

  return (
    <ConfigProvider
      theme={theme}
      button={{
        className: styles.linearGradientButton,
      }}
    >
      {contextHolder}
      <div className="quote-wrapper">
        <div className="quote-wrapper-0">
          <Container>
            <div className="slogan-wrapper">
              <h2 className="slogan-title">
                <FormattedMessage
                  id={"page.home.slogan-looking-for-a-transport-solution"}
                />
              </h2>
              <div className="slogan-sub-title">
                <span>
                  <FormattedMessage id={"page.home.choose"} />
                </span>
                <span>
                  <Image
                    src={italicTextLogo}
                    style={{ lineHeight: "56px", marginLeft: "15px" }}
                    className={"italic-text-logo"}
                  />
                </span>
              </div>
            </div>
          </Container>
        </div>
        <div className="quote-wrapper-1">
          <Container>
            <div className="quote-wrapper-row">
              <div className="quote-item-box">
                <p className="number">01</p>
                <p className="title">信赖之选</p>
                <p className="content">
                  我们的平台汇集了众多专业的运输公司，所有的订单都经过严格的审核和跟踪，以确保您的货物安全抵达
                </p>
              </div>
              <div className="quote-item-box">
                <p className="number">02</p>
                <p className="title">信赖之选</p>
                <p className="content">
                  通过我们的app，您只需几个简单的步骤就能快速地下单。只要输入货物信息，再点击确认，就完成了！
                </p>
              </div>
              <div className="quote-item-box">
                <p className="number">03</p>
                <p className="title">价格公道</p>
                <p className="content">
                  在ExtraSpace，您不需要为运输中大型货物而烦恼。我们提供了竞争力的价格，让您的运输需求不再负担沉重。{" "}
                </p>
              </div>
              <div className="quote-item-box">
                <p className="number">04</p>
                <p className="title">适合中大型货物</p>
                <p className="content">
                  无论是家具，设备，还是其他任何中大型货物，ExtraSpace都能轻松应对。我们的平台致力于让每一个货物都找到合适的运输公司。{" "}
                </p>
              </div>
            </div>
          </Container>
        </div>
        <div className="quote-wrapper-2">
          <Container>
            <Tabs
              // defaultActiveKey={tab}
              activeKey={tab}
              items={tabItems}
              onChange={onTabChange}
            />
          </Container>
        </div>
        {
          tab === '1' &&( orderSuccess ? <OrderSuccess 
            orderId={orderId}
            goToOrderList={goToOrderList} 
            goToQuote={goToQuote}
            /> : (
            orderId === undefined ? 
            (<div className="quote-wrapper-3">
              {!placeOrderShow ? 
                <QuoteForm nextStep={gotoPlaceOrder} /> : 
                <PlaceOrder quoteData={quoteData} goToQuote={goToQuote} placeOrder={placeOrder} />}
            </div>) : 
            (<div className="quote-wrapper-3">
              <CardForm pay={pay}/>
            </div>))
          )
        }
        {
          ['0', '2', '3'].includes(tab) && (
            <div className="quote-wrapper-3">
              <OrderList 
              loading={orderListLoading} 
              orderList={orderList} 
              goToQuote={goToQuote} 
              deleteOrder={deleteOrder}
              continuePay={continuePay} />
            </div>
          )
        }

      </div>
      <Modal
        open={isLoginModalOpen}
        onCancel={() => {
          setIsLoginModalOpen(false);
        }}
        centered
        footer={null}
        width={720}
      >
        <div className="login-form-container">
          <Form
            name="login"
            layout="vertical"
            variant="filled"
            requiredMark={false}
            form={loginForm}
            onFinish={login}
          >
            <Image
              src={italicTextLogo}
              style={{
                lineHeight: "56px",
                marginBottom: 32,
                marginLeft: 42.5,
                marginRight: 42.5,
              }}
              className={"italic-text-logo"}
            />
            <Form.Item
              style={{ width: 300 }}
              name="phone"
              label="电话号码"
              rules={[
                {
                  required: true,
                  message: "Please input your phone number!",
                },
              ]}
            >
              <Input
                placeholder="请输入电话号码"
                size="small"
                suffix={
                  <Button
                    type="link"
                    onClick={() => {
                      loginForm.validateFields(["phone"]).then((values) => {
                        console.log(values);
                        if (!isSMSLoading && smsCount === 0) {
                          fetchCode({ country_code: "+1", tel: values.phone });
                        }
                      });
                    }}
                    loading={isSMSLoading}
                    disabled={smsCount !== 0}
                  >
                    {smsCount > 0 ? `${smsCount}s` : "发送验证码"}
                  </Button>
                }
              />
            </Form.Item>
            <Form.Item
              name="password"
              label="验证码"
              rules={[
                {
                  required: true,
                  message: "Please input your SMS code!",
                },
              ]}
            >
              <Input
                placeholder="请输入验证码"
                size="small"
                suffix={<div style={{ width: 1, height: 32 }}></div>}
              />
            </Form.Item>
            <Form.Item>
              <Button block type="primary" htmlType="submit" size="large">
                登录
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </ConfigProvider>
  );
}
