import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import { FormattedMessage, useIntl } from "react-intl";
import Image from "react-bootstrap/Image";
import MyMap from "./Map";
import {
  ConfigProvider,
  Tabs,
  DatePicker,
  Form,
  Row,
  Col,
  Checkbox,
  Input,
  InputNumber,
  Select,
  Modal,
  Button,
  Spin,
  message,
  Radio,
} from "antd";
import theme from "@/AntDesignConfig";
import styled from "styled-components";

import { BASE_API_URL } from "@/network.config";
import qs from "qs";

import italicTextLogo from "@/assets/italic_text_logo.png";
import topAlertIcon from "@/assets/top_alert_icon.png";
import palletIcon from "@/assets/pallet_icon@3x.png";
import boxIcon from "@/assets/box_icon@3x.png";
import startIcon from "@/assets/Start@3x.png";
import endIcon from "@/assets/Stop@3x.png";
import iconAlert from "@/assets/icon_alert@2x.png";
import emptyIcon from "@/assets/empty_box.png";

import addCardIcon from "@/assets/add.svg";
import { createStyles } from "antd-style";

const useStyle = createStyles(({ prefixCls, css }) => ({
  linearGradientButton: css`
    &.${prefixCls}-btn-primary:not([disabled]):not(
        .${prefixCls}-btn-dangerous
      ) {
      border-width: 0;

      > span {
        position: relative;
        font-size: 17px;
        font-weight: 600;
      }

      &::before {
        content: "";
        background: linear-gradient(166deg, #ea7e30 0%, #f5be51 100%);
        position: absolute;
        inset: 0;
        opacity: 1;
        transition: all 0.3s;
        border-radius: inherit;
      }

      &:hover::before {
        opacity: 0;
      }
    }
  `,
}));

export default function OrderDetails(props) {
  const isPathLoaded = useRef(false);
  const [path, setPath] = useState([]);
  const [center, setCenter] = useState({});
  const [zoom, setZoom] = useState(12);

  const calculateCenter = (bounds) => {
    const latCenter = (bounds.northeast.lat + bounds.southwest.lat) / 2;
    const lngCenter = (bounds.northeast.lng + bounds.southwest.lng) / 2;
    return { lat: latCenter, lng: lngCenter };
  };

  const calculateZoom = (bounds, mapDim) => {
    const WORLD_DIM = { height: 256, width: 256 };
    const ZOOM_MAX = 21;
  
    const latRad = (lat) => {
      const sin = Math.sin((lat * Math.PI) / 180);
      const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    };
  
    const zoom = (mapPx, worldPx, fraction) =>
      Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  
    const latFraction =
      (latRad(bounds.northeast.lat) - latRad(bounds.southwest.lat)) / Math.PI;
  
    const lngDiff = bounds.northeast.lng - bounds.southwest.lng;
    const lngFraction = ((lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360);
  
    const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);
  
    return Math.min(latZoom, lngZoom, ZOOM_MAX);
  };

  const loadPath = async (params) => {
    try {
      let token = localStorage.getItem("shipper_token");
      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await fetch(
        `${BASE_API_URL}/api/v1/app/maps/directions?${qs.stringify(params)}`,
        {
          method: "GET",
          headers: headers,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();

      if (
        result.status !== "OK" &&
        result.routes &&
        Array.isArray(result.routes) &&
        result.routes.length > 0
      ) {
        console.error(`Got directions failed: ${result.errmsg}`);
        messageApi.error(result.errmsg);
        return null;
      }

      const decodePolyline = require("decode-google-map-polyline");
      const decodedPath = decodePolyline(
        result.routes[0].overview_polyline.points
      );
      setPath(decodedPath);
      
      const bounds = result.routes[0].bounds;
      const mapDimensions = { width: 1024, height: 555 };
      setCenter(calculateCenter(bounds))
      setZoom(calculateZoom(bounds, mapDimensions))
    } catch (error) {
      console.error("Got directions error:", error);
      messageApi.error("Got directions failed. Please try again.");
      return null;
    }
  };

  useEffect(() => {
    if (!isPathLoaded.current) {
      isPathLoaded.current = true;
      loadPath({
        origin: `${order.pickup_address.city}, ${order.pickup_address.state} ${order.pickup_address.zipcode}`,
        destination: `${order.delivery_address.city}, ${order.delivery_address.state} ${order.delivery_address.zipcode}`,
      });
    }
  }, []);

  const { order } = props;
  const { styles } = useStyle();

  /* doc for order
    {
                "order_id": 7219886337120444416,
                "pickup_estimated_date": "2024-07-19",
                "remark": "",
                "delivery_city": "Azusa",
                "delivery_zipcode": "91702",
                "delivery_img": "[\"https://opstoolbucket102220-dev.s3.us-west-1.amazonaws.com/public/7219886337120444416-2024-07-19_10:19:26.jpg\"]",
                "delivery_instance": -1.0,
                "complete_time": 1722586938,
                "pickup_type": null,
                "carrier_id": 61,
                "pickup_estimated_start_time": "10:15 AM",
                "weight": 20.0,
                "goods_type": "家具",
                "pickup_date": "2024-07-19",
                "signature_img": null,
                "next_match_time": null,
                "admin_note": null,
                "delivery_type": null,
                "user_id": 65,
                "pickup_estimated_end_time": "2:15 PM",
                "length": 40.0,
                "pickup_state": "CA",
                "pickup_date_end": "2024-07-31",
                "signature_name": null,
                "auto_checkout_time": 1722565210,
                "is_pallet": true,
                "pickup_ls": false,
                "status": 5,
                "estimated_date": "2024-07-19",
                "width": 48.0,
                "pickup_county": "Los Angeles County",
                "delivery_date": "2024-07-19",
                "require_evaluation": true,
                "accepted_time": 1721355333,
                "pallet_quantity": 1,
                "delivery_ls": false,
                "cost": 55.99,
                "estimated_start_time": "10:30 AM",
                "height": 71.0,
                "pickup_city": "Los Angeles",
                "payment_method": 0,
                "require_shipper_evaluation": true,
                "pickup_at": 1721355390,
                "bol_url": "https://opstoolbucket102220-dev.s3.us-west-1.amazonaws.com/public/pdf/20240719021538_bol_7219886337120444416.pdf",
                "created_at": "2024-07-19T02:10:41",
                "price": 50.9,
                "estimated_end_time": "2:30 PM",
                "goods_num": 0,
                "pickup_zipcode": "90013",
                "payment_status": 1,
                "pi_id": null,
                "delivery_at": 1721355610,
                "pod_url": "https://opstoolbucket102220-dev.s3.us-west-1.amazonaws.com/public/pdf/20240719022015_pod_7219886337120444416.pdf",
                "id": 315,
                "real_price": 50.9,
                "real_date": "2024-07-19",
                "goods_model": "",
                "delivery_state": "CA",
                "pickup_img": "[\"https://opstoolbucket102220-dev.s3.us-west-1.amazonaws.com/public/7219886337120444416-2024-07-19_10:16:27.jpg\"]",
                "test_mode": true,
                "cancel_time": null,
                "loader": null,
                "updated_at": "2024-08-02T08:22:18",
                "is_signature": false,
                "delivery_county": "Los Angeles County",
                "payment_card_id": 80,
                "counter": null,
                "_original_status": 5,
                "_listener_executed": false,
                "pickup_address": {
                    "order_id": 7219886337120444416,
                    "id": 321,
                    "name": "q",
                    "email": "",
                    "state": "CA",
                    "city": "Los Angeles",
                    "address_line2": "",
                    "created_at": "2024-07-19T02:10:41",
                    "updated_at": "2024-07-19T02:10:41",
                    "phone": "111",
                    "zipcode": "90013",
                    "county": "Los Angeles County",
                    "address_line1": "700 Winston St"
                },
                "delivery_address": {
                    "order_id": 7219886337120444416,
                    "id": 321,
                    "phone": "11",
                    "email": "",
                    "state": "CA",
                    "city": "Azusa",
                    "address_line2": "",
                    "created_at": "2024-07-19T02:10:41",
                    "updated_at": "2024-07-19T02:10:41",
                    "name": "qq",
                    "zipcode": "91702",
                    "county": "Los Angeles County",
                    "address_line1": "117 N Azusa Ave"
                },
                "real_pickup_time": 1721355390
            }
    
    */

  const [messageApi, contextHolder] = message.useMessage();
  const showAlertMessage = (content) => {
    messageApi.open({
      type: "error",
      content: content,
      style: {
        marginTop: "30vh",
      },
    });
  };

  const Wrapper = styled.div`
    .d-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 40px;
      background-color: #fff;
      border-radius: 8px;
      margin-bottom: 10px;
    }

    .d-box-address-item {
      width: 50%;
      // padding: 0 20px;
    }
    .d-box-address-item:first-child {
      border-right: 1px solid #e5e5e5;
    }
    .d-box-address-item:last-child {
      margin-left: 40px;
    }

    .d-box-title {
      font-size: 22px;
      font-weight: 600;
      line-height: 30.8px;
      text-align: left;
      margin-bottom: 16px;
    }
  `;

  return (
    <ConfigProvider
      theme={theme}
      button={{
        className: styles.linearGradientButton,
      }}
    >
      {contextHolder}
      <Wrapper>
        <div className="quote-wrapper">
          <div className="quote-wrapper-3">
            <Container className="form-container">
              <MyMap path={path} center={center} zoom={zoom} />
              {/* 订单号 */}
              <div className="d-box">
                <div>
                  <div className="d-box-title">订单号</div>
                  <div>{order.order_id}</div>
                </div>
                <div
                  style={{
                    fontFamily: "PingFang SC",
                    fontSize: "26px",
                    fontWeight: "600",
                    lineHeight: "40px",
                    textAlign: "left",
                    color: "#EA7E30",
                  }}
                >
                  ${order.price}
                </div>
              </div>
              {[-1, 0, 1, 2, 3].includes(order.status) && (
                <div
                  className="d-box"
                  style={{
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div className="d-box-title">
                    {order.estimated_date ? "送货时间" : "取货时间"}
                  </div>
                  <div
                    style={{
                      fontFamily: "PingFang SC",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "21.5px",
                    }}
                  >
                    {order.estimated_date &&
                      `${order.estimated_date} ${order.estimated_start_time} - ${order.estimated_end_time}`}
                    <br />
                    {!order.estimated_date &&
                      (order.pickup_estimated_start_time &&
                      order.pickup_estimated_end_time
                        ? `${order.pickup_estimated_date} ${order.pickup_estimated_start_time} - ${order.pickup_estimated_end_time}`
                        : `${order.pickup_date} - ${order.pickup_date_end}`)}
                  </div>
                </div>
              )}
              {[4, 5, 6].includes(order.status) && (
                <div
                  className="d-box"
                  style={{
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div className="d-box-title">货物送达</div>
                  <div
                    style={{
                      fontFamily: "PingFang SC",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "21.5px",
                      textAlign: "left",
                      color: "#EA7E30",
                      marginBottom: "10px",
                    }}
                  >
                    {order.delivery_at &&
                      new Date(order.delivery_at * 1000).toLocaleString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "numeric",
                          minute: "2-digit",
                          hour12: true,
                        }
                      )}
                  </div>
                  {order.pickup_img &&
                    JSON.parse(order.pickup_img).map((img, index) => (
                      <img
                        src={img}
                        alt="pickupImg"
                        key={index}
                        style={{
                          width: "540px",
                          height: "auto",
                          marginBottom: "10px",
                        }}
                      />
                    ))}
                  <div
                    style={{
                      fontFamily: "PingFang SC",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "21.5px",
                      textAlign: "left",
                      color: "#EA7E30",
                    }}
                  >
                    提示: 司机已送达，请确认收货情况。如未收到，请在平台投诉。
                  </div>
                </div>
              )}

              {/* 地址信息 */}
              <div className="d-box">
                <div className="d-box-address-item">
                  <div className="d-box-title">发货信息</div>
                  <div>
                    <div>
                      {order.pickup_address.name} {order.pickup_address.phone}
                    </div>
                    <div>
                      {order.pickup_address.address_line1}{" "}
                      {order.pickup_address.address_line2},{" "}
                      {order.pickup_address.city}, {order.pickup_address.state}{" "}
                      {order.pickup_address.zipcode}
                    </div>
                    <div>{order.pickup_ls ? "Liftgate Service" : ""}</div>
                    { order.pickup_type !== null && (
                      <div>
                        地址类别：{order.pickup_type === 1 ? "住宅" : "商业"}
                      </div>
                    )}
                  </div>
                </div>
                {/* 地址信息 */}
                <div className="d-box-address-item">
                  <div className="d-box-title">收货信息</div>
                  <div>
                    <div>
                      {order.delivery_address.name}{" "}
                      {order.delivery_address.phone}
                    </div>
                    <div>
                      {order.delivery_address.address_line1}{" "}
                      {order.delivery_address.address_line2},{" "}
                      {order.delivery_address.city},{" "}
                      {order.delivery_address.state}{" "}
                      {order.delivery_address.zipcode}
                    </div>
                    <div>{order.delivery_ls ? "Liftgate Service" : ""}</div>
                    { order.delivery_type !== null && (
                      <div>
                        地址类别：{order.delivery_type === 1 ? "住宅" : "商业"}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* 货物信息 */}
              <div className="d-box">
                <div
                  style={{
                    width: "33%",
                    borderRight: "1px solid #E5E5E5",
                  }}
                >
                  <div className="d-box-title">货物类别</div>
                  <div>
                    <div>{order.goods_type}</div>
                  </div>
                </div>
                <div
                  style={{
                    width: "33%",
                    borderRight: "1px solid #E5E5E5",
                    paddingLeft: "20px",
                  }}
                >
                  <div className="d-box-title">货物尺寸</div>
                  <div>
                    <div>
                      {/* 长40in, 宽60in， 高60in - 重120lbs */}长{order.length}
                      in, 宽{order.width}in， 高{order.height}in - 重
                      {order.weight}lbs
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "33%",
                    paddingLeft: "20px",
                  }}
                >
                  <div className="d-box-title">备注</div>
                  <div>
                    <div>{order.remark || "-"}</div>
                  </div>
                </div>
              </div>

              {/* BOL */}
              {order.bol_url && (
                <div
                  className="d-box"
                  style={{
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div className="d-box-title">BOL</div>
                  <div>
                    <a
                      style={{
                        color: "black",
                      }}
                      href={order.bol_url}
                      target="_blank"
                    >
                      点击此处浏览 BOL 表格
                    </a>
                  </div>
                </div>
              )}

              {/* 付款信息 */}
              <div
                className="d-box"
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <div className="d-box-title">付款信息</div>
                <div>
                  <div>
                    {order.payment_card_id && (
                      <div>{order.payment_card_id ? "Credit Card" : ""}</div>
                    )}
                    { !order.payment_card_id && (
                      <button style={{ 
                          padding: '4px 20px',
                          margin: '0 10px 20px 0',
                          border: "1px solid #EA7E30",
                          color: '#EA7E30', 
                          borderRadius: '20px', 
                          cursor: 'pointer', 
                          backgroundColor: '#fff'
                      }}
                      onClick={(e) => {
                          e.stopPropagation();
                          props.continuePay(order.order_id);
                      }}
                      >
                          支付
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </Wrapper>
    </ConfigProvider>
  );
}
