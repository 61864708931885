import React from "react";
import { Fragment } from "react";
import { createBrowserRouter } from "react-router-dom";
import Home from "@/pages/Home/index";
import Privacy from "@/pages/Privacy/index";
import TransactionSecurity from "@/pages/TransactionSecurity/index";
import ShippingPolicies from "@/pages/ShippingPpolicies/index";
import TermsOfService from "@/pages/TermsOfService/index";
import ContactUs from "@/pages/ContactUs";
import PostRouter from "@/pages/PostRouter";
import RedirectToAppStore from "@/pages/Start";
import Login from "@/pages/Login";
import CarrierOrderList from "@/pages/CarrierOrderList";
import Quote from "@/pages/Quote";
import ErrorPage from "@/components/ErrorPage";
import Header from "@/components/Header/Header";
import Footer from "@/components/Footer/index";
import Announcement from "@/pages/Announcement";

const getElement = (children, wihtHeader = true, withFooter = true) => (
  <Fragment>
    {wihtHeader ? <Header /> : null}
    {children}
    {withFooter ? <Footer /> : null}
  </Fragment>
);

const router = createBrowserRouter([
  {
    path: "/",
    element: getElement(<Home />),
    errorElement: <ErrorPage />,
  },
  {
    path: "/privacy-policy",
    element: getElement(<Privacy />),
  },
  {
    path: "/transaction-and-security",
    element: getElement(<TransactionSecurity />),
  },
  {
    path: "/shipping-policies",
    element: getElement(<ShippingPolicies />),
  },
  {
    path: "/terms-of-service",
    element: getElement(<TermsOfService />),
  },
  {
    path: "/contact-us",
    element: getElement(<ContactUs />),
  },
  {
    path: "/post-router",
    element: getElement(<PostRouter />),
  },
  {
    path: "/eslink/start",
    element: getElement(<RedirectToAppStore />),
  },
  {
    path: "/login/:phone?/:token?",
    element: getElement(<Login />, false, false),
  },
  {
    path: "/orders",
    element: getElement(<CarrierOrderList />),
  },
  {
    path: "/quote",
    element: getElement(<Quote />),
  },
  {
    path: "/announcement",
    element: getElement(<Announcement />),
  },
]);

export default router;
