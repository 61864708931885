export default {
  "page.login.header.back.home": "Back",
  "page.login.header.manual": "Manual",
  "page.login.title.main.1": "Welcome to ExtraSpace",
  "page.login.title.main.2": "Shipment management platform",
  "page.login.title.phone": "Phone",
  "page.login.title.code": "Code",
  "page.login.button.login": "Login",
  "page.login.error.required.phone": "Please input phone!",
  "page.login.error.required.code": "Please input code!",
};
