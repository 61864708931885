import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import {
  Row,
  Col,
  DatePicker,
  Checkbox,
  Form,
  InputNumber,
  Select,
  Spin,
  message,
} from "antd";
import Image from "react-bootstrap/Image";

import topAlertIcon from "@/assets/top_alert_icon.png";
import palletIcon from "@/assets/pallet_icon@3x.png";
import boxIcon from "@/assets/box_icon@3x.png";
import startIcon from "@/assets/Start@3x.png";
import endIcon from "@/assets/Stop@3x.png";

import { USCities } from "@/USCities";
import dayjs from "dayjs";

import JSONBig from "json-bigint";
import { BASE_API_URL } from "@/network.config";

const { RangePicker } = DatePicker;

export default function (props) {
  const [addressForm] = Form.useForm();
  const [cargoForm] = Form.useForm();

  const [messageApi, contextHolder] = message.useMessage();
  const showAlertMessage = (content) => {
    messageApi.open({
      type: "error",
      content: content,
      style: {
        marginTop: "30vh",
      },
    });
  };

  const [isPallet, setIsPallet] = useState(true);

  

  const nextStep = () => {
    let addressInfo = addressForm.getFieldsValue();
    let cargoInfo = cargoForm.getFieldsValue();
    const pickup_zipcode = addressInfo["pickup_postal_code"];
    const pickup_item = USCities.find(
      (entry) => entry.value === pickup_zipcode
    );
    const delivery_zipcode = addressInfo["delivery_postal_code"];
    const delivery_item = USCities.find(
      (entry) => entry.value === delivery_zipcode
    );
    let quoteData = {
      price: priceString,
      
      pickup_zipcode: pickup_zipcode,
      pickup_city: pickup_item["city"],
      pickup_state: pickup_item["state"],

      delivery_zipcode: delivery_zipcode,
      delivery_city: delivery_item["city"],
      delivery_state: delivery_item["state"],

      is_pallet: isPallet,
      quantity: cargoInfo.quantity,
      length: cargoInfo.length,
      width: cargoInfo.width,
      height: cargoInfo.height,
      weight: cargoInfo.weight,

      pickup_ls: addressInfo.pickup_ls,
      pickup_type: addressInfo.pickup_type,
      delivery_ls: addressInfo.delivery_ls,
      delivery_type: addressInfo.delivery_type,

      pickup_date: cargoInfo.date[0].format("YYYY-MM-DD"),
      delivery_date: cargoInfo.date[1].format("YYYY-MM-DD"),

    }
    props.nextStep(quoteData);
  };

  useEffect(() => {
    updateQuoteButtonStatus();
  }, [isPallet]);

  const updateQuoteButtonStatus = () => {
    const addressInfo = addressForm.getFieldsValue();
    const cargoInfo = cargoForm.getFieldsValue();

    var quoteEnable = true;

    if (
      typeof addressInfo["delivery_postal_code"] === "undefined" ||
      addressInfo["delivery_postal_code"] === null
    ) {
      quoteEnable = false;
    }
    if (
      typeof addressInfo["delivery_type"] === "undefined" ||
      addressInfo["delivery_type"] === null
    ) {
      quoteEnable = false;
    }
    if (
      typeof addressInfo["pickup_postal_code"] === "undefined" ||
      addressInfo["pickup_postal_code"] === null
    ) {
      quoteEnable = false;
    }
    if (
      typeof addressInfo["pickup_type"] === "undefined" ||
      addressInfo["pickup_type"] === null
    ) {
      quoteEnable = false;
    }

    if (
      typeof cargoInfo["date"] === "undefined" ||
      cargoInfo["date"] === null
    ) {
      quoteEnable = false;
    }
    if (
      typeof cargoInfo["weight"] === "undefined" ||
      cargoInfo["weight"] === null
    ) {
      quoteEnable = false;
    }

    if (isPallet) {
      if (
        typeof cargoInfo["quantity"] === "undefined" ||
        cargoInfo["quantity"] === null
      ) {
        quoteEnable = false;
      }
    } else {
      if (
        typeof cargoInfo["height"] === "undefined" ||
        cargoInfo["height"] === null
      ) {
        quoteEnable = false;
      }
      if (
        typeof cargoInfo["length"] === "undefined" ||
        cargoInfo["length"] === null
      ) {
        quoteEnable = false;
      }
      if (
        typeof cargoInfo["width"] === "undefined" ||
        cargoInfo["width"] === null
      ) {
        quoteEnable = false;
      }
    }

    setQuoteButtonEnable(quoteEnable);
  };

  const [quoteButtonEnable, setQuoteButtonEnable] = useState(false);
  const [quoteLoading, setQuoteLoading] = useState(false);
  const [isGotPrice, setIsGotPrice] = useState(false);

  const fetchQuote = async (params) => {
    try {
      setQuoteLoading(true);

      const response = await fetch(
        `${BASE_API_URL}/api/v1/app/shipper/order/appraisal/anonymous`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${appCtx.token}`,
          },
          body: JSON.stringify(params),
        }
      );

      if (!response.ok) {
        throw new Error(response);
      }
      const text = await response.text();
      const result = JSONBig({ storeAsString: true }).parse(text);

      if (result.code !== 0) {
        console.log(`request quote api failed:${result}`);
        messageApi.error(result.errmsg);
        return;
      }

      console.log(result.data);
      setPickupCityString(params.pickup_city);
      setDeliveryCityString(params.delivery_city);
      setPriceString(`${result.data.price}`);
      setIsGotPrice(true);
    } catch (error) {
      console.log(`request login api error: ${error}`);
      messageApi.error("网络错误");
    } finally {
      setQuoteLoading(false);
    }
  };

  const [priceString, setPriceString] = useState("-.--");
  const [pickupCityString, setPickupCityString] = useState("");
  const [deliveryCityString, setDeliveryCityString] = useState("");

  return (
    <Container className="form-container">
      {contextHolder}
      <Form.Provider
        onFormChange={() => {
          updateQuoteButtonStatus();
        }}
      >
        <div className="form-group">
          <div className="top-alert">
            <Image src={topAlertIcon} className={"top-alert-icon"} />
            <span>请准确填写，因信息不准确造成损失需自行承担。</span>
          </div>
          <Form
            layout="vertical"
            variant="filled"
            name="address"
            form={addressForm}
            disabled={isGotPrice}
          >
            <p className="form-group-title">地址信息</p>
            <Row gutter={[80, 0]}>
              <Col xs={24} md={12} xl={12}>
                <Form.Item label="取货点" name="pickup_postal_code">
                  <Select
                    showSearch
                    placeholder="选择取货点"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    options={USCities}
                  />
                </Form.Item>
                <Form.Item
                  name="pickup_ls"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Checkbox>Liftgate service</Checkbox>
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={12}>
                <Form.Item label="地址类别" name="pickup_type">
                  <Select>
                    <Select.Option value="1">Residential</Select.Option>
                    <Select.Option value="0">Commercial</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={12}>
                <Form.Item label="送货点" name="delivery_postal_code">
                  <Select
                    showSearch
                    placeholder="选择送货点"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    options={USCities}
                  />
                </Form.Item>
                <Form.Item
                  name="delivery_ls"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Checkbox>Liftgate service</Checkbox>
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={12}>
                <Form.Item label="地址类别" name="delivery_type">
                  <Select>
                    <Select.Option value="1">Residential</Select.Option>
                    <Select.Option value="0">Commercial</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="form-group mt-40">
          <Form
            layout="vertical"
            variant="filled"
            name="cargo"
            form={cargoForm}
            disabled={isGotPrice}
          >
            <p className="form-group-title">货物信息</p>
            <Form.Item label="货物形式">
              <Row gutter={[80, 0]}>
                <Col span={12}>
                  <div
                    className={`delivery-method-item ${
                      isPallet
                        ? "delivery-method-item-active"
                        : "delivery-method-item-normal"
                    }`}
                    onClick={() => {
                      if (!isGotPrice && !isPallet) {
                        setIsPallet(true);
                      }
                    }}
                  >
                    <Image
                      src={palletIcon}
                      className={"delivery-method-item-icon"}
                    />
                    <p>Pallet(s)</p>
                  </div>
                </Col>
                <Col span={12}>
                  <div
                    className={`delivery-method-item ${
                      isPallet
                        ? "delivery-method-item-normal"
                        : "delivery-method-item-active"
                    }`}
                    onClick={() => {
                      if (!isGotPrice && isPallet) {
                        setIsPallet(false);
                      }
                    }}
                  >
                    <Image
                      src={boxIcon}
                      className={"delivery-method-item-icon"}
                    />
                    <p>Box(es)</p>
                  </div>
                </Col>
              </Row>
            </Form.Item>
            <Row gutter={[80, 0]}>
              {isPallet ? (
                <Col xs={24} md={12} xl={12}>
                  <Form.Item label="货板数量" name="quantity">
                    <InputNumber
                      style={{ width: "100%" }}
                      min={1}
                      controls={false}
                    />
                  </Form.Item>
                </Col>
              ) : (
                <Col xs={24} md={12} xl={12}>
                  <Row gutter={[24, 0]}>
                    <Col span={8}>
                      <Form.Item label="长" name="length">
                        <InputNumber
                          style={{ width: "100%" }}
                          min={0.01}
                          controls={false}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="宽" name="width">
                        <InputNumber
                          style={{ width: "100%" }}
                          min={0.01}
                          controls={false}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="高" name="height">
                        <InputNumber
                          style={{ width: "100%" }}
                          min={0.01}
                          controls={false}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )}
              <Col xs={24} md={12} xl={12}>
                <Form.Item label="货物重量 lbs" name="weight">
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0.01}
                    controls={false}
                  />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item label="长">
                  <InputNumber style={{width: "100%"}} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="宽">
                  <InputNumber style={{width: "100%"}} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="高">
                  <InputNumber style={{width: "100%"}} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="货物重量 lbs">
                  <Input />
                </Form.Item>
              </Col> */}
              {/* <Col span={6}>
                <Form.Item label="长">
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="宽">
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="高">
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="货物重量 lbs">
                  <Input />
                </Form.Item>
              </Col> */}
              <Col xs={24} md={12} xl={12}>
                <Form.Item label="取货时间" name="date">
                  <RangePicker style={{ width: "100%" }} minDate={dayjs()} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Form.Provider>
      {!isGotPrice ? (
        <div className="quote-buttons-box mt-56">
          <Spin spinning={quoteLoading}>
            <div
              className={`quote-button ${
                quoteButtonEnable
                  ? "quote-button-enable"
                  : "quote-button-disable"
              }`}
              onClick={() => {
                const addressInfo = addressForm.getFieldsValue();
                const cargoInfo = cargoForm.getFieldsValue();

                if (
                  typeof addressInfo["pickup_postal_code"] === "undefined" ||
                  addressInfo["pickup_postal_code"] === null
                ) {
                  showAlertMessage("请选择取货地");
                  addressForm.scrollToField("pickup_postal_code");
                  return;
                }
                if (
                  typeof addressInfo["pickup_type"] === "undefined" ||
                  addressInfo["pickup_type"] === null
                ) {
                  showAlertMessage("请选择取货地 地址类型");
                  addressForm.scrollToField("pickup_type");
                  return;
                }

                if (
                  typeof addressInfo["delivery_postal_code"] === "undefined" ||
                  addressInfo["delivery_postal_code"] === null
                ) {
                  showAlertMessage("请选择送达地");
                  addressForm.scrollToField("delivery_postal_code");
                  return;
                }
                if (
                  typeof addressInfo["delivery_type"] === "undefined" ||
                  addressInfo["delivery_type"] === null
                ) {
                  showAlertMessage("请选择送达地 地址类型");
                  addressForm.scrollToField("delivery_type");
                  return;
                }

                if (!isPallet) {
                  if (
                    typeof cargoInfo["length"] === "undefined" ||
                    cargoInfo["length"] === null
                  ) {
                    showAlertMessage("请填写货物长度");
                    cargoForm.scrollToField("length");
                    return;
                  }
                  if (
                    typeof cargoInfo["width"] === "undefined" ||
                    cargoInfo["width"] === null
                  ) {
                    showAlertMessage("请填写货物宽度");
                    return;
                  }
                  if (
                    typeof cargoInfo["height"] === "undefined" ||
                    cargoInfo["quantity"] === null
                  ) {
                    showAlertMessage("请填写货物高度");
                    return;
                  }
                } else {
                  if (
                    typeof cargoInfo["quantity"] === "undefined" ||
                    cargoInfo["quantity"] === null
                  ) {
                    showAlertMessage("请填写货板数量");
                    return;
                  }
                }

                if (
                  typeof cargoInfo["weight"] === "undefined" ||
                  cargoInfo["weight"] === null
                ) {
                  showAlertMessage("请填写货物总重量");
                  return;
                }

                if (
                  typeof cargoInfo["date"] === "undefined" ||
                  cargoInfo["date"] === null
                ) {
                  showAlertMessage("请选择取货时间");
                  return;
                }

                if (quoteButtonEnable) {
                  const pickup_zipcode = addressInfo["pickup_postal_code"];
                  const pickup_item = USCities.find(
                    (entry) => entry.value === pickup_zipcode
                  );
                  const delivery_zipcode = addressInfo["delivery_postal_code"];
                  const delivery_item = USCities.find(
                    (entry) => entry.value === delivery_zipcode
                  );

                  var params = {
                    pickup_state: pickup_item["state"],
                    pickup_city: pickup_item["city"],
                    pickup_county: pickup_item["county"],
                    pickup_postal_code: pickup_zipcode,
                    delivery_state: delivery_item["state"],
                    delivery_city: delivery_item["city"],
                    delivery_county: delivery_item["county"],
                    delivery_postal_code: delivery_zipcode,
                    is_pallet: isPallet,
                    weight: cargoInfo["weight"],
                    pickup_type: addressInfo.pickup_type,
                    delivery_type: addressInfo.delivery_type,
                    pickup_ls: addressInfo.pickup_ls,
                    delivery_ls: addressInfo.delivery_ls,
                  };
                  if (isPallet) {
                    params["quantity"] = cargoInfo["quantity"];
                  } else {
                    params["length"] = cargoInfo["length"];
                    params["width"] = cargoInfo["width"];
                    params["height"] = cargoInfo["height"];
                  }
                  fetchQuote(params);
                }

                // console.log(cargoForm.getFieldsValue());
                // console.log(isPallet);
                // console.log(
                //   cargoForm.getFieldValue("date")[0].format("YYYY/MM/DD")
                // );
                // console.log(
                //   cargoForm.getFieldValue("date")[1].format("YYYY/MM/DD")
                // );
              }}
            >
              <span>{quoteLoading ? " " : "估价"}</span>
            </div>
          </Spin>
        </div>
      ) : (
        <div className="form-group mt-40">
          <p className="price-top-title">以下为您此次货运的估价：</p>
          <div className="price-bottom-box">
            <div className="left-box">
              <div className="address-item">
                <div className="top">
                  <Image src={startIcon} className={"icon"} />
                  <span className="title">取货点</span>
                </div>
                <span className="content">{pickupCityString}</span>
              </div>
              <div className="gradient-line"></div>
              <div className="address-item">
                <div className="top">
                  <Image src={endIcon} className={"icon"} />
                  <span className="title">送货点</span>
                </div>
                <span className="content">{deliveryCityString}</span>
              </div>
            </div>
            <span className="price-title">预计</span>
            <span className="price">{`$ ${priceString}`}</span>
            <div
              className="next-step-button"
              onClick={() => {
                nextStep();
              }}
            >
              <span>下一步</span>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
}
