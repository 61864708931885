import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { FormattedMessage, useIntl } from "react-intl";
import Image from "react-bootstrap/Image";
import {
    ConfigProvider,
    Tabs,
    DatePicker,
    Form,
    Row,
    Col,
    Checkbox,
    Input,
    InputNumber,
    Select,
    Modal,
    Button,
    Spin,
    message,
    Radio
} from "antd";
import theme from "@/AntDesignConfig";

import italicTextLogo from "@/assets/italic_text_logo.png";
import topAlertIcon from "@/assets/top_alert_icon.png";
import palletIcon from "@/assets/pallet_icon@3x.png";
import boxIcon from "@/assets/box_icon@3x.png";
import startIcon from "@/assets/Start@3x.png";
import endIcon from "@/assets/Stop@3x.png";
import JSONBig from "json-bigint";
import { BASE_API_URL } from "@/network.config";

import addCardIcon from "@/assets/add.svg";
import { createStyles } from "antd-style";

const useStyle = createStyles(({ prefixCls, css }) => ({
    linearGradientButton: css`
    &.${prefixCls}-btn-primary:not([disabled]):not(
        .${prefixCls}-btn-dangerous
      ) {
      border-width: 0;

      > span {
        position: relative;
        font-size: 17px;
        font-weight: 600;
      }

      &::before {
        content: "";
        background: linear-gradient(166deg, #ea7e30 0%, #f5be51 100%);
        position: absolute;
        inset: 0;
        opacity: 1;
        transition: all 0.3s;
        border-radius: inherit;
      }

      &:hover::before {
        opacity: 0;
      }
    }
  `,
}));

export default function CardForm(props) {
    const { styles } = useStyle();

    const [messageApi, contextHolder] = message.useMessage();
    const [step, setStep] = useState(0);
    const showAlertMessage = (content) => {
        messageApi.open({
            type: "error",
            content: content,
            style: {
                marginTop: "30vh",
            },
        });
    };

    const [cardForm] = Form.useForm();
    const [addressForm] = Form.useForm();
    const [cardList, setCardList] = useState([
    ]);
    const [selectedCard, setSelectedCard] = useState(null);
    const [newCard, setNewCard] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [loading, setLoading] = useState(false);

    const [isNewAddress, setIsNewAddress] = useState(false);

    const [payLoading, setPayLoading] = useState(false);

    const createCard = async (data) => {
        try {
            const response = await fetch(`${BASE_API_URL}/api/v1/app/shipper/cards`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("shipper_token")}`
                },
                body: JSON.stringify({
                    card_type: "credit", // Assuming credit card type
                    card_no: data.card_no,
                    card_cvv: data.card_cvv,
                    exp_month: data.exp_month,
                    exp_year: data.exp_year,
                    is_save: data.is_save,
                    is_web: true
                })
            });

            if (!response.ok) {
                throw new Error(response.statusText);
            }

            const text = await response.text();
            const result = JSONBig({ storeAsString: true }).parse(text);
            console.log('create card result', result);
            if (result.code !== 0) {
                messageApi.error(result.errmsg);
                return null;
            }
            setSelectedCard(result.data.card_id);
            setStep(1);
        } catch (error) {
            console.log(`Create card api error: ${error.message}`);
            messageApi.error("网络错误");
            return null;
        }
    }

    useEffect(() => {
        const getCardList = async () => {
            try {
                setLoading(true);
                const res = await fetch(
                    `${BASE_API_URL}/api/v1/app/shipper/cards`,
                    {
                method: "GET",
                headers: {
                  "Content-Type": "application/json", 
                  Authorization: `Bearer ${localStorage.getItem("shipper_token")}`,
                },
                }
            );
                if (!res.ok) {
                    throw new Error(res);
                }
                const text = await res.text();
                const result = JSONBig({ storeAsString: true }).parse(text);
                setCardList(result.data);
            } catch (error) {
                console.error('Failed to fetch card list:', error);
            } finally {
                setLoading(false);
            }
        }
        getCardList();
    }, []);

    const confirmCard = async () => {
        console.log(showForm, selectedCard);
        if (!showForm) {
            if (selectedCard === null) {
                messageApi.open({
                    type: "error",
                    content: "请选择银行卡",
                });
            }else{
                setStep(1);
            } 
        }
        if (showForm) {
            try {
                const values = await cardForm.validateFields();
                console.log('Form is valid:', values);
                // 验证过期时间 是否大于当前时间
                const currentDate = new Date();
                const expDate = new Date(values.exp_year, values.exp_month - 1);
                console.log('expDate', expDate);
                if (expDate < currentDate) {
                    showAlertMessage('过期时间不合法');
                    return;
                }
                //  验证卡号格式
                const cardNo = values.card_no;
                if (!/^\d{4} \d{4} \d{4} \d{4}$/.test(cardNo)) {
                    showAlertMessage('卡号格式不合法');
                    return;
                }
                // Form validation succeeded, values contains the form values
                setNewCard(values);
                createCard(values);
            } catch (errorInfo) {
                console.log('Form validation failed:', errorInfo);
                showAlertMessage('请填写完整的银行卡信息');
                return;
            }
        }
    }

    const onAddressTypeChange = (e) => {
        setIsNewAddress(e.target.value === "new");
    }

    const confirmPay = async () => {
        let addressFormValues = addressForm.getFieldsValue();
        console.log('addressFormValues', addressFormValues);
        let data = {
            card_id: selectedCard,
            billing_address: addressFormValues.address_type === "same" ? null : addressFormValues,
        }
        console.log('data', data);
        await props.pay(data);
    }

    const validateCVV = (rule, value) => {
        if (value.length !== 3) {
            return Promise.reject('安全码必须是3位数字');
        }
        return Promise.resolve();
    }
    const addressFormWrapper = () => {
        return (
            <Form.Provider
              onFormChange={() => {}}
            >
              <div className="form-group" style={{
                    flex: 1,
                    width: '100%',
                }}>
              <Row gutter={[80, 0]}>
                <Form
                  layout="vertical"
                  variant="filled"
                  name="billing-address-form"
                  form={addressForm}
                  style={{width: "100%"}}
                >
                    <Col xs={{span: 24}} md={{span: 12, offset: 6}} xl={{span: 12, offset: 6}}>
                    <p className="form-group-title">账单地址</p>
                    </Col>

                    <Col xs={{span: 24}} md={{span: 12, offset: 6}} xl={{span: 12, offset: 6}}>
                      {/* radio for two optin 和发货地址相同 & 输入一个新的账单地址 */}
                      <Form.Item
                        name="address_type"
                        initialValue="same"
                        rules={[{ required: true, message: "请选择账单地址" }]}>
                        <Radio.Group onChange={onAddressTypeChange} style={{display: "flex", gap: "10px", flexDirection: "column"}} defaultValue="same">
                          <Radio value="same">和发货地址相同</Radio>
                          <Radio value="new">输入一个新的账单地址</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    {
                        isNewAddress && (<>
                        <Col xs={{span: 24}} md={{span: 12, offset: 6}} xl={{span: 12, offset: 6}}>
                      <Form.Item label="姓名" name="name" rules={[{ required: true, message: "请输入姓名" }]}>
                        <Input placeholder="John Doe" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={{span: 12, offset: 6}} xl={{span: 12, offset: 6}}>
                      <Form.Item
                        name="address_line1"
                        label="地址行1"
                        rules={[{ required: true, message: "请输入地址行1" }]}
                      >
                        <Input placeholder="请输入地址信息" />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={{span: 12, offset: 6}} xl={{span: 12, offset: 6}}>
                      <Form.Item
                        name="address_line2"
                        label="地址行2"
                      >
                        <Input placeholder="请输入地址信息" />
                      </Form.Item>
                    </Col>
                    
                    <Col xs={24} md={{span: 12, offset: 6}} xl={{span: 12, offset: 6}}>
                    <Form.Item label="ZIP Code" name="zipcode" rules={[{ required: true, message: "请输入ZIP Code" }]}>
                      <Input placeholder="请输入ZIP Code" />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={{span: 12, offset: 6}} xl={{span: 12, offset: 6}}>
                      <Form.Item label='City' name="city" rules={[{ required: true, message: "请输入城市" }]}>
                        <Input placeholder="请输入城市" />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={{span: 12, offset: 6}} xl={{span: 12, offset: 6}}>
                      <Form.Item label='State' name="state" rules={[{ required: true, message: "请输入州" }]}>
                        <Input placeholder="请输入州" />
                            </Form.Item>
                        </Col>
                        </>)
                    }
                </Form>
                </Row>
              </div>
            </Form.Provider>
        )
    }

    const CardFormWrapper = () => {
       return (<Form.Provider
                            onFormChange={() => { }}
                        >
                            <div className="form-group" style={{
                                flex: 1,
                                width: '100%',
                            }}>
                                <Row gutter={[80, 0]}>
                                    <Col xs={{ span: 24 }} md={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                                        <div className="top-alert">
                                            <Image src={topAlertIcon} className={"top-alert-icon"} />
                                            <span>在司机确认接单之前，平台不会进行扣款</span>
                                        </div>
                                    </Col>

                                    <Col xs={{ span: 24 }} md={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                                        <p className="form-group-title">付款信息</p>
                                    </Col>
                                    {
                                        (cardList.length !== 0 && !showForm) && (
                                            <Col xs={{ span: 24 }} md={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                                                <div>
                                                    {
                                                        cardList.map((card) => (
                                                            <div key={card.id} style={{ display: "flex", padding: "16px 24px", gap: "10px", borderRadius: "8px", border: "1px solid var(--Light_2, #CECAC4)", background: "#FFF", justifyContent: "space-between", marginBottom: "8px" }}>
                                                                <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                                                    <div>{card.bank}</div>
                                                                    <div>Card ···· {card.last4}</div>
                                                                </div>
                                                                <Radio value={card.id} onChange={(e) => setSelectedCard(e.target.value)} checked={selectedCard === card.id}></Radio>
                                                            </div>
                                                        ))
                                                    }

                                                    <div onClick={() => setShowForm(true)} style={{ display: "flex", padding: "24px 24px 48px 0", gap: "10px", background: "#FFF", marginBottom: "8px", cursor: "pointer" }}>
                                                        <div >
                                                            <Image src={addCardIcon} style={{ width: "16px", height: "16px" }} />
                                                        </div>
                                                        <div>添加新的银行卡号</div>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    }

                                    {
                                        showForm && (
                                            <Form
                                                layout="vertical"
                                                variant="filled"
                                                name="card-form"
                                                form={cardForm}
                                                style={{ width: "100%" }}
                                            >


                                                <Col xs={{ span: 24 }} md={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                                                    <Form.Item label="银行卡类别" name="card_type" rules={[{ required: true, message: "请选择银行卡类别" }]}>
                                                        <Select
                                                            showSearch
                                                            placeholder="银行卡类别"
                                                            options={[{ label: "Visa", value: "visa" }, { label: "MasterCard", value: "master" }]}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                                                    <Form.Item
                                                        name="card_name"
                                                        label="银行卡上姓名"
                                                        rules={[{ required: true, message: "请输入银行卡上姓名" }]}
                                                    >
                                                        <Input placeholder="John Doe" />
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={24} md={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                                                    <Form.Item
                                                        name="card_no"
                                                        label="银行卡号"
                                                        rules={[{ required: true, message: "请输入银行卡号" }]}
                                                    >
                                                        <Input 
                                                            placeholder="0000-0000-0000-0000"
                                                            onChange={(e) => {
                                                                const value = e.target.value.replace(/\D/g, '');
                                                                const formatted = value.match(/.{1,4}/g) ? value.match(/.{1,4}/g).join(' ') : '';
                                                                cardForm.setFieldValue('card_no', formatted);
                                                            }}
                                                            maxLength={19}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={24} md={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                                                    <div style={{ display: "flex", gap: "10px" }}>
                                                        <Form.Item label="过期时间" name="exp_month" style={{ width: "50%" }} rules={[{ required: true, message: "请选择过期日期" }]}>
                                                            <Select
                                                                showSearch
                                                                placeholder="Month"
                                                                filterOption={(input, option) =>
                                                                    option.label
                                                                        .toLowerCase()
                                                                        .includes(input.toLowerCase())
                                                                }
                                                                options={[
                                                                    { label: "01", value: "1" },
                                                                    { label: "02", value: "2" },
                                                                    { label: "03", value: "3" },
                                                                    { label: "04", value: "4" },
                                                                    { label: "05", value: "5" },
                                                                    { label: "06", value: "6" },
                                                                    { label: "07", value: "7" },
                                                                    { label: "08", value: "8" },
                                                                    { label: "09", value: "9" },
                                                                    { label: "10", value: "10" },
                                                                    { label: "11", value: "11" },
                                                                    { label: "12", value: "12" },
                                                                ]}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item label=' ' name="exp_year" style={{ width: "50%" }} rules={[{ required: true, message: "请选择过期年份" }]}>
                                                            <Select
                                                                showSearch
                                                                placeholder="Year"
                                                                filterOption={(input, option) =>
                                                                    option.label
                                                                        .toLowerCase()
                                                                        .includes(input.toLowerCase())
                                                                }
                                                                options={[
                                                                    { label: "2024", value: "2024" },
                                                                    { label: "2025", value: "2025" },
                                                                    { label: "2026", value: "2026" },
                                                                    { label: "2027", value: "2027" },
                                                                    { label: "2028", value: "2028" },
                                                                    { label: "2029", value: "2029" },
                                                                    { label: "2030", value: "2030" },

                                                                ]}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </Col>

                                                <Col xs={24} md={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                                                    <Form.Item
                                                        name="card_cvv"
                                                        label="安全码"
                                                        rules={[{ required: true, message: "请输入安全码" }, { validator: validateCVV }]}
                                                    >
                                                        <Input placeholder="Code" />
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={24} md={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                                                    <Form.Item
                                                        name="is_save"
                                                        valuePropName="checked"
                                                        initialValue={false}
                                                    >
                                                        <Checkbox>保存银行卡</Checkbox>
                                                    </Form.Item>
                                                </Col>
                                            </Form>
                                        )
                                    }
                                </Row>
                            </div>
                        </Form.Provider>)
    }


    return (

            <div className="quote-wrapper">
                {contextHolder}
                <div className="quote-wrapper-3">
                    <Container className="form-container" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'end',
                        alignItems: 'end',
                    }}>

                        {
                            loading && <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '300px', background: '#fff', borderRadius: '8px'}}>
                                <Spin size="large" spinning={loading}></Spin>
                            </div>
                        }

                        {!loading && step === 0 && (   
                            <>
                            <CardFormWrapper/>
                            <button 
                            style={{ 
                                width:'194px',
                                height:'60px',
                                border: "none",
                                color: '#fff',
                                borderRadius: '8px', 
                                marginTop: '50px',
                                cursor: 'pointer',
                                background: 'linear-gradient(165.96deg, #EA7E30 0%, #F5BE51 100%)',
                                // float: 'right'
                                }}
                                onClick={confirmCard}
                            >
                        确定
                            </button>
                            </>
                            
                        )}
                        {!loading && step === 1 && (
                            <>  
                            {addressFormWrapper()}
                            <div style={{display: "flex", justifyContent: "end"}}>
                            <button 
                                style={{ 
                                    width:'194px',
                                    height:'60px',
                                    border: "1px solid #EA7E30",
                                    color: '#EA7E30',
                                    borderRadius: '8px', 
                                    marginTop: '50px',
                                    cursor: 'pointer',
                                    background: 'transparent',
                                    marginRight: '10px',
                                    // background: 'linear-gradient(165.96deg, #EA7E30 0%, #F5BE51 100%)',
                                }}
                                onClick={() => setStep(0)}
                            >上一步</button>
                            

                            <button 
                                style={{ 
                                    width:'194px',
                                    height:'60px',
                                    border: "none",
                                    color: '#fff',
                                    borderRadius: '8px', 
                                    marginTop: '50px',
                                    cursor: 'pointer',
                                    background: 'linear-gradient(165.96deg, #EA7E30 0%, #F5BE51 100%)',
                                }}
                                onClick={async ()=>{
                                    setPayLoading(true);
                                    await confirmPay();
                                    setPayLoading(false);
                                }}
                            >
                                {
                                    payLoading ? <div><Spin style={{color: '#fff', fontSize: '17px', fontWeight: '600', lineHeight: '60px'}} /></div> :
                                    '确定'
                                }
                            </button>
                            </div>


                            </>

                        )}
                    
                    </Container>
                </div>
            </div>
    );
}
