export const USCities = [
  {
    "value": "35004",
    "label": "Moody - AL 35004",
    "county": "St. Clair County",
    "state": "AL",
    "city": "Moody"
  },
  {
    "value": "35005",
    "label": "Adamsville - AL 35005",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Adamsville"
  },
  {
    "value": "35006",
    "label": "Adger - AL 35006",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Adger"
  },
  {
    "value": "35007",
    "label": "Alabaster - AL 35007",
    "county": "Shelby County",
    "state": "AL",
    "city": "Alabaster"
  },
  {
    "value": "35010",
    "label": "Alexander City - AL 35010",
    "county": "Tallapoosa County",
    "state": "AL",
    "city": "Alexander City"
  },
  {
    "value": "35014",
    "label": "Alpine - AL 35014",
    "county": "Talladega County",
    "state": "AL",
    "city": "Alpine"
  },
  {
    "value": "35016",
    "label": "Arab - AL 35016",
    "county": "Marshall County",
    "state": "AL",
    "city": "Arab"
  },
  {
    "value": "35019",
    "label": "Baileyton - AL 35019",
    "county": "Cullman County",
    "state": "AL",
    "city": "Baileyton"
  },
  {
    "value": "35020",
    "label": "Bessemer - AL 35020",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Bessemer"
  },
  {
    "value": "35022",
    "label": "Bessemer - AL 35022",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Bessemer"
  },
  {
    "value": "35023",
    "label": "Bessemer - AL 35023",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Bessemer"
  },
  {
    "value": "35031",
    "label": "Blountsville - AL 35031",
    "county": "Blount County",
    "state": "AL",
    "city": "Blountsville"
  },
  {
    "value": "35033",
    "label": "Bremen - AL 35033",
    "county": "Cullman County",
    "state": "AL",
    "city": "Bremen"
  },
  {
    "value": "35034",
    "label": "Brent - AL 35034",
    "county": "Bibb County",
    "state": "AL",
    "city": "Brent"
  },
  {
    "value": "35035",
    "label": "Brierfield - AL 35035",
    "county": "Bibb County",
    "state": "AL",
    "city": "Brierfield"
  },
  {
    "value": "35040",
    "label": "Calera - AL 35040",
    "county": "Shelby County",
    "state": "AL",
    "city": "Calera"
  },
  {
    "value": "35042",
    "label": "Centreville - AL 35042",
    "county": "Bibb County",
    "state": "AL",
    "city": "Centreville"
  },
  {
    "value": "35043",
    "label": "Chelsea - AL 35043",
    "county": "Shelby County",
    "state": "AL",
    "city": "Chelsea"
  },
  {
    "value": "35044",
    "label": "Childersburg - AL 35044",
    "county": "Talladega County",
    "state": "AL",
    "city": "Childersburg"
  },
  {
    "value": "35045",
    "label": "Clanton - AL 35045",
    "county": "Chilton County",
    "state": "AL",
    "city": "Clanton"
  },
  {
    "value": "35046",
    "label": "Clanton - AL 35046",
    "county": "Chilton County",
    "state": "AL",
    "city": "Clanton"
  },
  {
    "value": "35049",
    "label": "Cleveland - AL 35049",
    "county": "Blount County",
    "state": "AL",
    "city": "Cleveland"
  },
  {
    "value": "35051",
    "label": "Columbiana - AL 35051",
    "county": "Shelby County",
    "state": "AL",
    "city": "Columbiana"
  },
  {
    "value": "35053",
    "label": "Crane Hill - AL 35053",
    "county": "Cullman County",
    "state": "AL",
    "city": "Crane Hill"
  },
  {
    "value": "35054",
    "label": "Cropwell - AL 35054",
    "county": "St. Clair County",
    "state": "AL",
    "city": "Cropwell"
  },
  {
    "value": "35055",
    "label": "Cullman - AL 35055",
    "county": "Cullman County",
    "state": "AL",
    "city": "Cullman"
  },
  {
    "value": "35057",
    "label": "Cullman - AL 35057",
    "county": "Cullman County",
    "state": "AL",
    "city": "Cullman"
  },
  {
    "value": "35058",
    "label": "Cullman - AL 35058",
    "county": "Cullman County",
    "state": "AL",
    "city": "Cullman"
  },
  {
    "value": "35061",
    "label": "Dolomite - AL 35061",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Dolomite"
  },
  {
    "value": "35062",
    "label": "Dora - AL 35062",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Dora"
  },
  {
    "value": "35063",
    "label": "Empire - AL 35063",
    "county": "Walker County",
    "state": "AL",
    "city": "Empire"
  },
  {
    "value": "35064",
    "label": "Fairfield - AL 35064",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Fairfield"
  },
  {
    "value": "35068",
    "label": "Fultondale - AL 35068",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Fultondale"
  },
  {
    "value": "35071",
    "label": "Gardendale - AL 35071",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Gardendale"
  },
  {
    "value": "35072",
    "label": "Goodwater - AL 35072",
    "county": "Coosa County",
    "state": "AL",
    "city": "Goodwater"
  },
  {
    "value": "35073",
    "label": "Graysville - AL 35073",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Graysville"
  },
  {
    "value": "35077",
    "label": "Hanceville - AL 35077",
    "county": "Cullman County",
    "state": "AL",
    "city": "Hanceville"
  },
  {
    "value": "35078",
    "label": "Harpersville - AL 35078",
    "county": "Shelby County",
    "state": "AL",
    "city": "Harpersville"
  },
  {
    "value": "35079",
    "label": "Hayden - AL 35079",
    "county": "Blount County",
    "state": "AL",
    "city": "Hayden"
  },
  {
    "value": "35080",
    "label": "Helena - AL 35080",
    "county": "Shelby County",
    "state": "AL",
    "city": "Helena"
  },
  {
    "value": "35083",
    "label": "Holly Pond - AL 35083",
    "county": "Cullman County",
    "state": "AL",
    "city": "Holly Pond"
  },
  {
    "value": "35085",
    "label": "Jemison - AL 35085",
    "county": "Chilton County",
    "state": "AL",
    "city": "Jemison"
  },
  {
    "value": "35087",
    "label": "Joppa - AL 35087",
    "county": "Cullman County",
    "state": "AL",
    "city": "Joppa"
  },
  {
    "value": "35089",
    "label": "Kellyton - AL 35089",
    "county": "Coosa County",
    "state": "AL",
    "city": "Kellyton"
  },
  {
    "value": "35091",
    "label": "Kimberly - AL 35091",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Kimberly"
  },
  {
    "value": "35094",
    "label": "Leeds - AL 35094",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Leeds"
  },
  {
    "value": "35096",
    "label": "Lincoln - AL 35096",
    "county": "Talladega County",
    "state": "AL",
    "city": "Lincoln"
  },
  {
    "value": "35097",
    "label": "Locust Fork - AL 35097",
    "county": "Blount County",
    "state": "AL",
    "city": "Locust Fork"
  },
  {
    "value": "35098",
    "label": "Logan - AL 35098",
    "county": "Cullman County",
    "state": "AL",
    "city": "Logan"
  },
  {
    "value": "35111",
    "label": "Mc Calla - AL 35111",
    "county": "Tuscaloosa County",
    "state": "AL",
    "city": "Mc Calla"
  },
  {
    "value": "35114",
    "label": "Alabaster - AL 35114",
    "county": "Shelby County",
    "state": "AL",
    "city": "Alabaster"
  },
  {
    "value": "35115",
    "label": "Montevallo - AL 35115",
    "county": "Shelby County",
    "state": "AL",
    "city": "Montevallo"
  },
  {
    "value": "35116",
    "label": "Morris - AL 35116",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Morris"
  },
  {
    "value": "35117",
    "label": "Mount Olive - AL 35117",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Mount Olive"
  },
  {
    "value": "35118",
    "label": "Mulga - AL 35118",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Mulga"
  },
  {
    "value": "35120",
    "label": "Odenville - AL 35120",
    "county": "St. Clair County",
    "state": "AL",
    "city": "Odenville"
  },
  {
    "value": "35121",
    "label": "Oneonta - AL 35121",
    "county": "Blount County",
    "state": "AL",
    "city": "Oneonta"
  },
  {
    "value": "35124",
    "label": "Pelham - AL 35124",
    "county": "Shelby County",
    "state": "AL",
    "city": "Pelham"
  },
  {
    "value": "35125",
    "label": "Pell City - AL 35125",
    "county": "St. Clair County",
    "state": "AL",
    "city": "Pell City"
  },
  {
    "value": "35126",
    "label": "Pinson - AL 35126",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Pinson"
  },
  {
    "value": "35127",
    "label": "Pleasant Grove - AL 35127",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Pleasant Grove"
  },
  {
    "value": "35128",
    "label": "Pell City - AL 35128",
    "county": "St. Clair County",
    "state": "AL",
    "city": "Pell City"
  },
  {
    "value": "35130",
    "label": "Quinton - AL 35130",
    "county": "Walker County",
    "state": "AL",
    "city": "Quinton"
  },
  {
    "value": "35131",
    "label": "Ragland - AL 35131",
    "county": "St. Clair County",
    "state": "AL",
    "city": "Ragland"
  },
  {
    "value": "35133",
    "label": "Remlap - AL 35133",
    "county": "Blount County",
    "state": "AL",
    "city": "Remlap"
  },
  {
    "value": "35135",
    "label": "Riverside - AL 35135",
    "county": "St. Clair County",
    "state": "AL",
    "city": "Riverside"
  },
  {
    "value": "35136",
    "label": "Rockford - AL 35136",
    "county": "Coosa County",
    "state": "AL",
    "city": "Rockford"
  },
  {
    "value": "35143",
    "label": "Shelby - AL 35143",
    "county": "Shelby County",
    "state": "AL",
    "city": "Shelby"
  },
  {
    "value": "35146",
    "label": "Springville - AL 35146",
    "county": "St. Clair County",
    "state": "AL",
    "city": "Springville"
  },
  {
    "value": "35147",
    "label": "Sterrett - AL 35147",
    "county": "Shelby County",
    "state": "AL",
    "city": "Sterrett"
  },
  {
    "value": "35148",
    "label": "Sumiton - AL 35148",
    "county": "Walker County",
    "state": "AL",
    "city": "Sumiton"
  },
  {
    "value": "35150",
    "label": "Sylacauga - AL 35150",
    "county": "Talladega County",
    "state": "AL",
    "city": "Sylacauga"
  },
  {
    "value": "35151",
    "label": "Sylacauga - AL 35151",
    "county": "Talladega County",
    "state": "AL",
    "city": "Sylacauga"
  },
  {
    "value": "35160",
    "label": "Talladega - AL 35160",
    "county": "Talladega County",
    "state": "AL",
    "city": "Talladega"
  },
  {
    "value": "35171",
    "label": "Thorsby - AL 35171",
    "county": "Chilton County",
    "state": "AL",
    "city": "Thorsby"
  },
  {
    "value": "35172",
    "label": "Trafford - AL 35172",
    "county": "Blount County",
    "state": "AL",
    "city": "Trafford"
  },
  {
    "value": "35173",
    "label": "Trussville - AL 35173",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Trussville"
  },
  {
    "value": "35175",
    "label": "Union Grove - AL 35175",
    "county": "Marshall County",
    "state": "AL",
    "city": "Union Grove"
  },
  {
    "value": "35176",
    "label": "Vandiver - AL 35176",
    "county": "Shelby County",
    "state": "AL",
    "city": "Vandiver"
  },
  {
    "value": "35178",
    "label": "Vincent - AL 35178",
    "county": "Shelby County",
    "state": "AL",
    "city": "Vincent"
  },
  {
    "value": "35179",
    "label": "Vinemont - AL 35179",
    "county": "Cullman County",
    "state": "AL",
    "city": "Vinemont"
  },
  {
    "value": "35180",
    "label": "Warrior - AL 35180",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Warrior"
  },
  {
    "value": "35183",
    "label": "Weogufka - AL 35183",
    "county": "Coosa County",
    "state": "AL",
    "city": "Weogufka"
  },
  {
    "value": "35184",
    "label": "West Blocton - AL 35184",
    "county": "Bibb County",
    "state": "AL",
    "city": "West Blocton"
  },
  {
    "value": "35186",
    "label": "Wilsonville - AL 35186",
    "county": "Shelby County",
    "state": "AL",
    "city": "Wilsonville"
  },
  {
    "value": "35188",
    "label": "Woodstock - AL 35188",
    "county": "Bibb County",
    "state": "AL",
    "city": "Woodstock"
  },
  {
    "value": "35203",
    "label": "Birmingham - AL 35203",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35204",
    "label": "Birmingham - AL 35204",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35205",
    "label": "Birmingham - AL 35205",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35206",
    "label": "Birmingham - AL 35206",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35207",
    "label": "Birmingham - AL 35207",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35208",
    "label": "Birmingham - AL 35208",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35209",
    "label": "Birmingham - AL 35209",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35210",
    "label": "Birmingham - AL 35210",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35211",
    "label": "Birmingham - AL 35211",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35212",
    "label": "Birmingham - AL 35212",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35213",
    "label": "Birmingham - AL 35213",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35214",
    "label": "Birmingham - AL 35214",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35215",
    "label": "Birmingham - AL 35215",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35216",
    "label": "Birmingham - AL 35216",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35217",
    "label": "Birmingham - AL 35217",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35218",
    "label": "Birmingham - AL 35218",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35221",
    "label": "Birmingham - AL 35221",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35222",
    "label": "Birmingham - AL 35222",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35223",
    "label": "Birmingham - AL 35223",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35224",
    "label": "Birmingham - AL 35224",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35226",
    "label": "Birmingham - AL 35226",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35228",
    "label": "Birmingham - AL 35228",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35233",
    "label": "Birmingham - AL 35233",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35234",
    "label": "Birmingham - AL 35234",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35235",
    "label": "Birmingham - AL 35235",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35242",
    "label": "Birmingham - AL 35242",
    "county": "Shelby County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35243",
    "label": "Birmingham - AL 35243",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35244",
    "label": "Birmingham - AL 35244",
    "county": "Jefferson County",
    "state": "AL",
    "city": "Birmingham"
  },
  {
    "value": "35401",
    "label": "Tuscaloosa - AL 35401",
    "county": "Tuscaloosa County",
    "state": "AL",
    "city": "Tuscaloosa"
  },
  {
    "value": "35404",
    "label": "Tuscaloosa - AL 35404",
    "county": "Tuscaloosa County",
    "state": "AL",
    "city": "Tuscaloosa"
  },
  {
    "value": "35405",
    "label": "Tuscaloosa - AL 35405",
    "county": "Tuscaloosa County",
    "state": "AL",
    "city": "Tuscaloosa"
  },
  {
    "value": "35406",
    "label": "Tuscaloosa - AL 35406",
    "county": "Tuscaloosa County",
    "state": "AL",
    "city": "Tuscaloosa"
  },
  {
    "value": "35441",
    "label": "Akron - AL 35441",
    "county": "Hale County",
    "state": "AL",
    "city": "Akron"
  },
  {
    "value": "35442",
    "label": "Aliceville - AL 35442",
    "county": "Pickens County",
    "state": "AL",
    "city": "Aliceville"
  },
  {
    "value": "35443",
    "label": "Boligee - AL 35443",
    "county": "Greene County",
    "state": "AL",
    "city": "Boligee"
  },
  {
    "value": "35444",
    "label": "Brookwood - AL 35444",
    "county": "Tuscaloosa County",
    "state": "AL",
    "city": "Brookwood"
  },
  {
    "value": "35446",
    "label": "Buhl - AL 35446",
    "county": "Tuscaloosa County",
    "state": "AL",
    "city": "Buhl"
  },
  {
    "value": "35447",
    "label": "Carrollton - AL 35447",
    "county": "Pickens County",
    "state": "AL",
    "city": "Carrollton"
  },
  {
    "value": "35452",
    "label": "Coker - AL 35452",
    "county": "Tuscaloosa County",
    "state": "AL",
    "city": "Coker"
  },
  {
    "value": "35453",
    "label": "Cottondale - AL 35453",
    "county": "Tuscaloosa County",
    "state": "AL",
    "city": "Cottondale"
  },
  {
    "value": "35456",
    "label": "Duncanville - AL 35456",
    "county": "Tuscaloosa County",
    "state": "AL",
    "city": "Duncanville"
  },
  {
    "value": "35457",
    "label": "Echola - AL 35457",
    "county": "Tuscaloosa County",
    "state": "AL",
    "city": "Echola"
  },
  {
    "value": "35458",
    "label": "Elrod - AL 35458",
    "county": "Tuscaloosa County",
    "state": "AL",
    "city": "Elrod"
  },
  {
    "value": "35459",
    "label": "Emelle - AL 35459",
    "county": "Sumter County",
    "state": "AL",
    "city": "Emelle"
  },
  {
    "value": "35460",
    "label": "Epes - AL 35460",
    "county": "Sumter County",
    "state": "AL",
    "city": "Epes"
  },
  {
    "value": "35461",
    "label": "Ethelsville - AL 35461",
    "county": "Pickens County",
    "state": "AL",
    "city": "Ethelsville"
  },
  {
    "value": "35462",
    "label": "Eutaw - AL 35462",
    "county": "Greene County",
    "state": "AL",
    "city": "Eutaw"
  },
  {
    "value": "35463",
    "label": "Fosters - AL 35463",
    "county": "Tuscaloosa County",
    "state": "AL",
    "city": "Fosters"
  },
  {
    "value": "35464",
    "label": "Gainesville - AL 35464",
    "county": "Sumter County",
    "state": "AL",
    "city": "Gainesville"
  },
  {
    "value": "35466",
    "label": "Gordo - AL 35466",
    "county": "Pickens County",
    "state": "AL",
    "city": "Gordo"
  },
  {
    "value": "35469",
    "label": "Knoxville - AL 35469",
    "county": "Greene County",
    "state": "AL",
    "city": "Knoxville"
  },
  {
    "value": "35470",
    "label": "Livingston - AL 35470",
    "county": "Sumter County",
    "state": "AL",
    "city": "Livingston"
  },
  {
    "value": "35473",
    "label": "Northport - AL 35473",
    "county": "Tuscaloosa County",
    "state": "AL",
    "city": "Northport"
  },
  {
    "value": "35474",
    "label": "Moundville - AL 35474",
    "county": "Hale County",
    "state": "AL",
    "city": "Moundville"
  },
  {
    "value": "35475",
    "label": "Northport - AL 35475",
    "county": "Tuscaloosa County",
    "state": "AL",
    "city": "Northport"
  },
  {
    "value": "35476",
    "label": "Northport - AL 35476",
    "county": "Tuscaloosa County",
    "state": "AL",
    "city": "Northport"
  },
  {
    "value": "35480",
    "label": "Ralph - AL 35480",
    "county": "Tuscaloosa County",
    "state": "AL",
    "city": "Ralph"
  },
  {
    "value": "35481",
    "label": "Reform - AL 35481",
    "county": "Pickens County",
    "state": "AL",
    "city": "Reform"
  },
  {
    "value": "35490",
    "label": "Vance - AL 35490",
    "county": "Tuscaloosa County",
    "state": "AL",
    "city": "Vance"
  },
  {
    "value": "35501",
    "label": "Jasper - AL 35501",
    "county": "Walker County",
    "state": "AL",
    "city": "Jasper"
  },
  {
    "value": "35503",
    "label": "Jasper - AL 35503",
    "county": "Walker County",
    "state": "AL",
    "city": "Jasper"
  },
  {
    "value": "35504",
    "label": "Jasper - AL 35504",
    "county": "Walker County",
    "state": "AL",
    "city": "Jasper"
  },
  {
    "value": "35540",
    "label": "Addison - AL 35540",
    "county": "Winston County",
    "state": "AL",
    "city": "Addison"
  },
  {
    "value": "35541",
    "label": "Arley - AL 35541",
    "county": "Winston County",
    "state": "AL",
    "city": "Arley"
  },
  {
    "value": "35542",
    "label": "Bankston - AL 35542",
    "county": "Fayette County",
    "state": "AL",
    "city": "Bankston"
  },
  {
    "value": "35543",
    "label": "Bear Creek - AL 35543",
    "county": "Marion County",
    "state": "AL",
    "city": "Bear Creek"
  },
  {
    "value": "35544",
    "label": "Beaverton - AL 35544",
    "county": "Lamar County",
    "state": "AL",
    "city": "Beaverton"
  },
  {
    "value": "35546",
    "label": "Berry - AL 35546",
    "county": "Fayette County",
    "state": "AL",
    "city": "Berry"
  },
  {
    "value": "35548",
    "label": "Brilliant - AL 35548",
    "county": "Marion County",
    "state": "AL",
    "city": "Brilliant"
  },
  {
    "value": "35549",
    "label": "Carbon Hill - AL 35549",
    "county": "Walker County",
    "state": "AL",
    "city": "Carbon Hill"
  },
  {
    "value": "35550",
    "label": "Cordova - AL 35550",
    "county": "Walker County",
    "state": "AL",
    "city": "Cordova"
  },
  {
    "value": "35552",
    "label": "Detroit - AL 35552",
    "county": "Lamar County",
    "state": "AL",
    "city": "Detroit"
  },
  {
    "value": "35553",
    "label": "Double Springs - AL 35553",
    "county": "Winston County",
    "state": "AL",
    "city": "Double Springs"
  },
  {
    "value": "35554",
    "label": "Eldridge - AL 35554",
    "county": "Fayette County",
    "state": "AL",
    "city": "Eldridge"
  },
  {
    "value": "35555",
    "label": "Fayette - AL 35555",
    "county": "Fayette County",
    "state": "AL",
    "city": "Fayette"
  },
  {
    "value": "35563",
    "label": "Guin - AL 35563",
    "county": "Marion County",
    "state": "AL",
    "city": "Guin"
  },
  {
    "value": "35564",
    "label": "Hackleburg - AL 35564",
    "county": "Marion County",
    "state": "AL",
    "city": "Hackleburg"
  },
  {
    "value": "35565",
    "label": "Haleyville - AL 35565",
    "county": "Winston County",
    "state": "AL",
    "city": "Haleyville"
  },
  {
    "value": "35570",
    "label": "Hamilton - AL 35570",
    "county": "Marion County",
    "state": "AL",
    "city": "Hamilton"
  },
  {
    "value": "35571",
    "label": "Hodges - AL 35571",
    "county": "Franklin County",
    "state": "AL",
    "city": "Hodges"
  },
  {
    "value": "35572",
    "label": "Houston - AL 35572",
    "county": "Winston County",
    "state": "AL",
    "city": "Houston"
  },
  {
    "value": "35574",
    "label": "Kennedy - AL 35574",
    "county": "Lamar County",
    "state": "AL",
    "city": "Kennedy"
  },
  {
    "value": "35575",
    "label": "Lynn - AL 35575",
    "county": "Winston County",
    "state": "AL",
    "city": "Lynn"
  },
  {
    "value": "35576",
    "label": "Millport - AL 35576",
    "county": "Lamar County",
    "state": "AL",
    "city": "Millport"
  },
  {
    "value": "35578",
    "label": "Nauvoo - AL 35578",
    "county": "Walker County",
    "state": "AL",
    "city": "Nauvoo"
  },
  {
    "value": "35579",
    "label": "Oakman - AL 35579",
    "county": "Walker County",
    "state": "AL",
    "city": "Oakman"
  },
  {
    "value": "35580",
    "label": "Parrish - AL 35580",
    "county": "Walker County",
    "state": "AL",
    "city": "Parrish"
  },
  {
    "value": "35581",
    "label": "Phil Campbell - AL 35581",
    "county": "Franklin County",
    "state": "AL",
    "city": "Phil Campbell"
  },
  {
    "value": "35582",
    "label": "Red Bay - AL 35582",
    "county": "Franklin County",
    "state": "AL",
    "city": "Red Bay"
  },
  {
    "value": "35585",
    "label": "Spruce Pine - AL 35585",
    "county": "Franklin County",
    "state": "AL",
    "city": "Spruce Pine"
  },
  {
    "value": "35586",
    "label": "Sulligent - AL 35586",
    "county": "Lamar County",
    "state": "AL",
    "city": "Sulligent"
  },
  {
    "value": "35587",
    "label": "Townley - AL 35587",
    "county": "Walker County",
    "state": "AL",
    "city": "Townley"
  },
  {
    "value": "35592",
    "label": "Vernon - AL 35592",
    "county": "Lamar County",
    "state": "AL",
    "city": "Vernon"
  },
  {
    "value": "35593",
    "label": "Vina - AL 35593",
    "county": "Franklin County",
    "state": "AL",
    "city": "Vina"
  },
  {
    "value": "35594",
    "label": "Winfield - AL 35594",
    "county": "Marion County",
    "state": "AL",
    "city": "Winfield"
  },
  {
    "value": "35601",
    "label": "Decatur - AL 35601",
    "county": "Morgan County",
    "state": "AL",
    "city": "Decatur"
  },
  {
    "value": "35603",
    "label": "Decatur - AL 35603",
    "county": "Morgan County",
    "state": "AL",
    "city": "Decatur"
  },
  {
    "value": "35610",
    "label": "Anderson - AL 35610",
    "county": "Lauderdale County",
    "state": "AL",
    "city": "Anderson"
  },
  {
    "value": "35611",
    "label": "Athens - AL 35611",
    "county": "Limestone County",
    "state": "AL",
    "city": "Athens"
  },
  {
    "value": "35613",
    "label": "Athens - AL 35613",
    "county": "Limestone County",
    "state": "AL",
    "city": "Athens"
  },
  {
    "value": "35614",
    "label": "Athens - AL 35614",
    "county": "Limestone County",
    "state": "AL",
    "city": "Athens"
  },
  {
    "value": "35616",
    "label": "Cherokee - AL 35616",
    "county": "Colbert County",
    "state": "AL",
    "city": "Cherokee"
  },
  {
    "value": "35618",
    "label": "Courtland - AL 35618",
    "county": "Lawrence County",
    "state": "AL",
    "city": "Courtland"
  },
  {
    "value": "35619",
    "label": "Danville - AL 35619",
    "county": "Morgan County",
    "state": "AL",
    "city": "Danville"
  },
  {
    "value": "35620",
    "label": "Elkmont - AL 35620",
    "county": "Limestone County",
    "state": "AL",
    "city": "Elkmont"
  },
  {
    "value": "35621",
    "label": "Eva - AL 35621",
    "county": "Morgan County",
    "state": "AL",
    "city": "Eva"
  },
  {
    "value": "35622",
    "label": "Falkville - AL 35622",
    "county": "Morgan County",
    "state": "AL",
    "city": "Falkville"
  },
  {
    "value": "35630",
    "label": "Florence - AL 35630",
    "county": "Lauderdale County",
    "state": "AL",
    "city": "Florence"
  },
  {
    "value": "35633",
    "label": "Florence - AL 35633",
    "county": "Lauderdale County",
    "state": "AL",
    "city": "Florence"
  },
  {
    "value": "35634",
    "label": "Florence - AL 35634",
    "county": "Lauderdale County",
    "state": "AL",
    "city": "Florence"
  },
  {
    "value": "35640",
    "label": "Hartselle - AL 35640",
    "county": "Morgan County",
    "state": "AL",
    "city": "Hartselle"
  },
  {
    "value": "35643",
    "label": "Hillsboro - AL 35643",
    "county": "Lawrence County",
    "state": "AL",
    "city": "Hillsboro"
  },
  {
    "value": "35645",
    "label": "Killen - AL 35645",
    "county": "Lauderdale County",
    "state": "AL",
    "city": "Killen"
  },
  {
    "value": "35646",
    "label": "Leighton - AL 35646",
    "county": "Colbert County",
    "state": "AL",
    "city": "Leighton"
  },
  {
    "value": "35647",
    "label": "Lester - AL 35647",
    "county": "Limestone County",
    "state": "AL",
    "city": "Lester"
  },
  {
    "value": "35648",
    "label": "Lexington - AL 35648",
    "county": "Lauderdale County",
    "state": "AL",
    "city": "Lexington"
  },
  {
    "value": "35650",
    "label": "Moulton - AL 35650",
    "county": "Lawrence County",
    "state": "AL",
    "city": "Moulton"
  },
  {
    "value": "35651",
    "label": "Mount Hope - AL 35651",
    "county": "Lawrence County",
    "state": "AL",
    "city": "Mount Hope"
  },
  {
    "value": "35652",
    "label": "Rogersville - AL 35652",
    "county": "Lauderdale County",
    "state": "AL",
    "city": "Rogersville"
  },
  {
    "value": "35653",
    "label": "Russellville - AL 35653",
    "county": "Franklin County",
    "state": "AL",
    "city": "Russellville"
  },
  {
    "value": "35654",
    "label": "Russellville - AL 35654",
    "county": "Franklin County",
    "state": "AL",
    "city": "Russellville"
  },
  {
    "value": "35660",
    "label": "Sheffield - AL 35660",
    "county": "Colbert County",
    "state": "AL",
    "city": "Sheffield"
  },
  {
    "value": "35661",
    "label": "Muscle Shoals - AL 35661",
    "county": "Colbert County",
    "state": "AL",
    "city": "Muscle Shoals"
  },
  {
    "value": "35670",
    "label": "Somerville - AL 35670",
    "county": "Morgan County",
    "state": "AL",
    "city": "Somerville"
  },
  {
    "value": "35671",
    "label": "Tanner - AL 35671",
    "county": "Limestone County",
    "state": "AL",
    "city": "Tanner"
  },
  {
    "value": "35672",
    "label": "Town Creek - AL 35672",
    "county": "Lawrence County",
    "state": "AL",
    "city": "Town Creek"
  },
  {
    "value": "35673",
    "label": "Trinity - AL 35673",
    "county": "Morgan County",
    "state": "AL",
    "city": "Trinity"
  },
  {
    "value": "35674",
    "label": "Tuscumbia - AL 35674",
    "county": "Colbert County",
    "state": "AL",
    "city": "Tuscumbia"
  },
  {
    "value": "35677",
    "label": "Waterloo - AL 35677",
    "county": "Lauderdale County",
    "state": "AL",
    "city": "Waterloo"
  },
  {
    "value": "35739",
    "label": "Ardmore - AL 35739",
    "county": "Limestone County",
    "state": "AL",
    "city": "Ardmore"
  },
  {
    "value": "35740",
    "label": "Bridgeport - AL 35740",
    "county": "Jackson County",
    "state": "AL",
    "city": "Bridgeport"
  },
  {
    "value": "35741",
    "label": "Brownsboro - AL 35741",
    "county": "Madison County",
    "state": "AL",
    "city": "Brownsboro"
  },
  {
    "value": "35744",
    "label": "Dutton - AL 35744",
    "county": "Jackson County",
    "state": "AL",
    "city": "Dutton"
  },
  {
    "value": "35745",
    "label": "Estillfork - AL 35745",
    "county": "Jackson County",
    "state": "AL",
    "city": "Estillfork"
  },
  {
    "value": "35746",
    "label": "Fackler - AL 35746",
    "county": "Jackson County",
    "state": "AL",
    "city": "Fackler"
  },
  {
    "value": "35747",
    "label": "Grant - AL 35747",
    "county": "Marshall County",
    "state": "AL",
    "city": "Grant"
  },
  {
    "value": "35748",
    "label": "Gurley - AL 35748",
    "county": "Madison County",
    "state": "AL",
    "city": "Gurley"
  },
  {
    "value": "35749",
    "label": "Harvest - AL 35749",
    "county": "Madison County",
    "state": "AL",
    "city": "Harvest"
  },
  {
    "value": "35750",
    "label": "Hazel Green - AL 35750",
    "county": "Madison County",
    "state": "AL",
    "city": "Hazel Green"
  },
  {
    "value": "35751",
    "label": "Hollytree - AL 35751",
    "county": "Jackson County",
    "state": "AL",
    "city": "Hollytree"
  },
  {
    "value": "35752",
    "label": "Hollywood - AL 35752",
    "county": "Jackson County",
    "state": "AL",
    "city": "Hollywood"
  },
  {
    "value": "35754",
    "label": "Laceys Spring - AL 35754",
    "county": "Morgan County",
    "state": "AL",
    "city": "Laceys Spring"
  },
  {
    "value": "35755",
    "label": "Langston - AL 35755",
    "county": "Marshall County",
    "state": "AL",
    "city": "Langston"
  },
  {
    "value": "35756",
    "label": "Madison - AL 35756",
    "county": "Limestone County",
    "state": "AL",
    "city": "Madison"
  },
  {
    "value": "35757",
    "label": "Madison - AL 35757",
    "county": "Madison County",
    "state": "AL",
    "city": "Madison"
  },
  {
    "value": "35758",
    "label": "Madison - AL 35758",
    "county": "Madison County",
    "state": "AL",
    "city": "Madison"
  },
  {
    "value": "35759",
    "label": "Meridianville - AL 35759",
    "county": "Madison County",
    "state": "AL",
    "city": "Meridianville"
  },
  {
    "value": "35760",
    "label": "New Hope - AL 35760",
    "county": "Madison County",
    "state": "AL",
    "city": "New Hope"
  },
  {
    "value": "35761",
    "label": "New Market - AL 35761",
    "county": "Madison County",
    "state": "AL",
    "city": "New Market"
  },
  {
    "value": "35763",
    "label": "Owens Cross Roads - AL 35763",
    "county": "Madison County",
    "state": "AL",
    "city": "Owens Cross Roads"
  },
  {
    "value": "35764",
    "label": "Paint Rock - AL 35764",
    "county": "Jackson County",
    "state": "AL",
    "city": "Paint Rock"
  },
  {
    "value": "35765",
    "label": "Pisgah - AL 35765",
    "county": "Jackson County",
    "state": "AL",
    "city": "Pisgah"
  },
  {
    "value": "35766",
    "label": "Princeton - AL 35766",
    "county": "Jackson County",
    "state": "AL",
    "city": "Princeton"
  },
  {
    "value": "35768",
    "label": "Scottsboro - AL 35768",
    "county": "Jackson County",
    "state": "AL",
    "city": "Scottsboro"
  },
  {
    "value": "35769",
    "label": "Scottsboro - AL 35769",
    "county": "Jackson County",
    "state": "AL",
    "city": "Scottsboro"
  },
  {
    "value": "35771",
    "label": "Section - AL 35771",
    "county": "Jackson County",
    "state": "AL",
    "city": "Section"
  },
  {
    "value": "35772",
    "label": "Stevenson - AL 35772",
    "county": "Jackson County",
    "state": "AL",
    "city": "Stevenson"
  },
  {
    "value": "35773",
    "label": "Toney - AL 35773",
    "county": "Madison County",
    "state": "AL",
    "city": "Toney"
  },
  {
    "value": "35774",
    "label": "Trenton - AL 35774",
    "county": "Jackson County",
    "state": "AL",
    "city": "Trenton"
  },
  {
    "value": "35775",
    "label": "Valhermoso Springs - AL 35775",
    "county": "Morgan County",
    "state": "AL",
    "city": "Valhermoso Springs"
  },
  {
    "value": "35776",
    "label": "Woodville - AL 35776",
    "county": "Jackson County",
    "state": "AL",
    "city": "Woodville"
  },
  {
    "value": "35801",
    "label": "Huntsville - AL 35801",
    "county": "Madison County",
    "state": "AL",
    "city": "Huntsville"
  },
  {
    "value": "35802",
    "label": "Huntsville - AL 35802",
    "county": "Madison County",
    "state": "AL",
    "city": "Huntsville"
  },
  {
    "value": "35803",
    "label": "Huntsville - AL 35803",
    "county": "Madison County",
    "state": "AL",
    "city": "Huntsville"
  },
  {
    "value": "35805",
    "label": "Huntsville - AL 35805",
    "county": "Madison County",
    "state": "AL",
    "city": "Huntsville"
  },
  {
    "value": "35806",
    "label": "Huntsville - AL 35806",
    "county": "Madison County",
    "state": "AL",
    "city": "Huntsville"
  },
  {
    "value": "35808",
    "label": "Huntsville - AL 35808",
    "county": "Madison County",
    "state": "AL",
    "city": "Huntsville"
  },
  {
    "value": "35810",
    "label": "Huntsville - AL 35810",
    "county": "Madison County",
    "state": "AL",
    "city": "Huntsville"
  },
  {
    "value": "35811",
    "label": "Huntsville - AL 35811",
    "county": "Madison County",
    "state": "AL",
    "city": "Huntsville"
  },
  {
    "value": "35813",
    "label": "Huntsville - AL 35813",
    "county": "Madison County",
    "state": "AL",
    "city": "Huntsville"
  },
  {
    "value": "35816",
    "label": "Huntsville - AL 35816",
    "county": "Madison County",
    "state": "AL",
    "city": "Huntsville"
  },
  {
    "value": "35824",
    "label": "Huntsville - AL 35824",
    "county": "Madison County",
    "state": "AL",
    "city": "Huntsville"
  },
  {
    "value": "35901",
    "label": "Gadsden - AL 35901",
    "county": "Etowah County",
    "state": "AL",
    "city": "Gadsden"
  },
  {
    "value": "35903",
    "label": "Gadsden - AL 35903",
    "county": "Etowah County",
    "state": "AL",
    "city": "Gadsden"
  },
  {
    "value": "35904",
    "label": "Gadsden - AL 35904",
    "county": "Etowah County",
    "state": "AL",
    "city": "Gadsden"
  },
  {
    "value": "35905",
    "label": "Gadsden - AL 35905",
    "county": "Etowah County",
    "state": "AL",
    "city": "Gadsden"
  },
  {
    "value": "35906",
    "label": "Rainbow City - AL 35906",
    "county": "Etowah County",
    "state": "AL",
    "city": "Rainbow City"
  },
  {
    "value": "35907",
    "label": "Gadsden - AL 35907",
    "county": "Etowah County",
    "state": "AL",
    "city": "Gadsden"
  },
  {
    "value": "35950",
    "label": "Albertville - AL 35950",
    "county": "Marshall County",
    "state": "AL",
    "city": "Albertville"
  },
  {
    "value": "35951",
    "label": "Albertville - AL 35951",
    "county": "Marshall County",
    "state": "AL",
    "city": "Albertville"
  },
  {
    "value": "35952",
    "label": "Altoona - AL 35952",
    "county": "Etowah County",
    "state": "AL",
    "city": "Altoona"
  },
  {
    "value": "35953",
    "label": "Ashville - AL 35953",
    "county": "St. Clair County",
    "state": "AL",
    "city": "Ashville"
  },
  {
    "value": "35954",
    "label": "Attalla - AL 35954",
    "county": "Etowah County",
    "state": "AL",
    "city": "Attalla"
  },
  {
    "value": "35956",
    "label": "Boaz - AL 35956",
    "county": "Etowah County",
    "state": "AL",
    "city": "Boaz"
  },
  {
    "value": "35957",
    "label": "Boaz - AL 35957",
    "county": "Marshall County",
    "state": "AL",
    "city": "Boaz"
  },
  {
    "value": "35958",
    "label": "Bryant - AL 35958",
    "county": "Jackson County",
    "state": "AL",
    "city": "Bryant"
  },
  {
    "value": "35959",
    "label": "Cedar Bluff - AL 35959",
    "county": "Cherokee County",
    "state": "AL",
    "city": "Cedar Bluff"
  },
  {
    "value": "35960",
    "label": "Centre - AL 35960",
    "county": "Cherokee County",
    "state": "AL",
    "city": "Centre"
  },
  {
    "value": "35961",
    "label": "Collinsville - AL 35961",
    "county": "DeKalb County",
    "state": "AL",
    "city": "Collinsville"
  },
  {
    "value": "35962",
    "label": "Crossville - AL 35962",
    "county": "DeKalb County",
    "state": "AL",
    "city": "Crossville"
  },
  {
    "value": "35963",
    "label": "Dawson - AL 35963",
    "county": "DeKalb County",
    "state": "AL",
    "city": "Dawson"
  },
  {
    "value": "35966",
    "label": "Flat Rock - AL 35966",
    "county": "Jackson County",
    "state": "AL",
    "city": "Flat Rock"
  },
  {
    "value": "35967",
    "label": "Fort Payne - AL 35967",
    "county": "DeKalb County",
    "state": "AL",
    "city": "Fort Payne"
  },
  {
    "value": "35968",
    "label": "Fort Payne - AL 35968",
    "county": "DeKalb County",
    "state": "AL",
    "city": "Fort Payne"
  },
  {
    "value": "35971",
    "label": "Fyffe - AL 35971",
    "county": "DeKalb County",
    "state": "AL",
    "city": "Fyffe"
  },
  {
    "value": "35972",
    "label": "Gallant - AL 35972",
    "county": "Etowah County",
    "state": "AL",
    "city": "Gallant"
  },
  {
    "value": "35973",
    "label": "Gaylesville - AL 35973",
    "county": "Cherokee County",
    "state": "AL",
    "city": "Gaylesville"
  },
  {
    "value": "35974",
    "label": "Geraldine - AL 35974",
    "county": "DeKalb County",
    "state": "AL",
    "city": "Geraldine"
  },
  {
    "value": "35975",
    "label": "Groveoak - AL 35975",
    "county": "DeKalb County",
    "state": "AL",
    "city": "Groveoak"
  },
  {
    "value": "35976",
    "label": "Guntersville - AL 35976",
    "county": "Marshall County",
    "state": "AL",
    "city": "Guntersville"
  },
  {
    "value": "35978",
    "label": "Henagar - AL 35978",
    "county": "DeKalb County",
    "state": "AL",
    "city": "Henagar"
  },
  {
    "value": "35979",
    "label": "Higdon - AL 35979",
    "county": "DeKalb County",
    "state": "AL",
    "city": "Higdon"
  },
  {
    "value": "35980",
    "label": "Horton - AL 35980",
    "county": "Marshall County",
    "state": "AL",
    "city": "Horton"
  },
  {
    "value": "35981",
    "label": "Ider - AL 35981",
    "county": "DeKalb County",
    "state": "AL",
    "city": "Ider"
  },
  {
    "value": "35983",
    "label": "Leesburg - AL 35983",
    "county": "Cherokee County",
    "state": "AL",
    "city": "Leesburg"
  },
  {
    "value": "35984",
    "label": "Mentone - AL 35984",
    "county": "DeKalb County",
    "state": "AL",
    "city": "Mentone"
  },
  {
    "value": "35986",
    "label": "Rainsville - AL 35986",
    "county": "DeKalb County",
    "state": "AL",
    "city": "Rainsville"
  },
  {
    "value": "35987",
    "label": "Steele - AL 35987",
    "county": "St. Clair County",
    "state": "AL",
    "city": "Steele"
  },
  {
    "value": "35988",
    "label": "Sylvania - AL 35988",
    "county": "DeKalb County",
    "state": "AL",
    "city": "Sylvania"
  },
  {
    "value": "35989",
    "label": "Valley Head - AL 35989",
    "county": "DeKalb County",
    "state": "AL",
    "city": "Valley Head"
  },
  {
    "value": "36003",
    "label": "Autaugaville - AL 36003",
    "county": "Autauga County",
    "state": "AL",
    "city": "Autaugaville"
  },
  {
    "value": "36005",
    "label": "Banks - AL 36005",
    "county": "Pike County",
    "state": "AL",
    "city": "Banks"
  },
  {
    "value": "36006",
    "label": "Billingsley - AL 36006",
    "county": "Autauga County",
    "state": "AL",
    "city": "Billingsley"
  },
  {
    "value": "36008",
    "label": "Booth - AL 36008",
    "county": "Autauga County",
    "state": "AL",
    "city": "Booth"
  },
  {
    "value": "36009",
    "label": "Brantley - AL 36009",
    "county": "Crenshaw County",
    "state": "AL",
    "city": "Brantley"
  },
  {
    "value": "36010",
    "label": "Brundidge - AL 36010",
    "county": "Pike County",
    "state": "AL",
    "city": "Brundidge"
  },
  {
    "value": "36013",
    "label": "Cecil - AL 36013",
    "county": "Montgomery County",
    "state": "AL",
    "city": "Cecil"
  },
  {
    "value": "36016",
    "label": "Clayton - AL 36016",
    "county": "Barbour County",
    "state": "AL",
    "city": "Clayton"
  },
  {
    "value": "36017",
    "label": "Clio - AL 36017",
    "county": "Barbour County",
    "state": "AL",
    "city": "Clio"
  },
  {
    "value": "36020",
    "label": "Coosada - AL 36020",
    "county": "Elmore County",
    "state": "AL",
    "city": "Coosada"
  },
  {
    "value": "36022",
    "label": "Deatsville - AL 36022",
    "county": "Elmore County",
    "state": "AL",
    "city": "Deatsville"
  },
  {
    "value": "36024",
    "label": "Eclectic - AL 36024",
    "county": "Elmore County",
    "state": "AL",
    "city": "Eclectic"
  },
  {
    "value": "36025",
    "label": "Elmore - AL 36025",
    "county": "Elmore County",
    "state": "AL",
    "city": "Elmore"
  },
  {
    "value": "36026",
    "label": "Equality - AL 36026",
    "county": "Coosa County",
    "state": "AL",
    "city": "Equality"
  },
  {
    "value": "36027",
    "label": "Eufaula - AL 36027",
    "county": "Barbour County",
    "state": "AL",
    "city": "Eufaula"
  },
  {
    "value": "36028",
    "label": "Dozier - AL 36028",
    "county": "Covington County",
    "state": "AL",
    "city": "Dozier"
  },
  {
    "value": "36029",
    "label": "Fitzpatrick - AL 36029",
    "county": "Bullock County",
    "state": "AL",
    "city": "Fitzpatrick"
  },
  {
    "value": "36030",
    "label": "Forest Home - AL 36030",
    "county": "Butler County",
    "state": "AL",
    "city": "Forest Home"
  },
  {
    "value": "36032",
    "label": "Fort Deposit - AL 36032",
    "county": "Lowndes County",
    "state": "AL",
    "city": "Fort Deposit"
  },
  {
    "value": "36033",
    "label": "Georgiana - AL 36033",
    "county": "Butler County",
    "state": "AL",
    "city": "Georgiana"
  },
  {
    "value": "36034",
    "label": "Glenwood - AL 36034",
    "county": "Pike County",
    "state": "AL",
    "city": "Glenwood"
  },
  {
    "value": "36035",
    "label": "Goshen - AL 36035",
    "county": "Pike County",
    "state": "AL",
    "city": "Goshen"
  },
  {
    "value": "36036",
    "label": "Grady - AL 36036",
    "county": "Montgomery County",
    "state": "AL",
    "city": "Grady"
  },
  {
    "value": "36037",
    "label": "Greenville - AL 36037",
    "county": "Butler County",
    "state": "AL",
    "city": "Greenville"
  },
  {
    "value": "36039",
    "label": "Hardaway - AL 36039",
    "county": "Macon County",
    "state": "AL",
    "city": "Hardaway"
  },
  {
    "value": "36040",
    "label": "Hayneville - AL 36040",
    "county": "Lowndes County",
    "state": "AL",
    "city": "Hayneville"
  },
  {
    "value": "36041",
    "label": "Highland Home - AL 36041",
    "county": "Crenshaw County",
    "state": "AL",
    "city": "Highland Home"
  },
  {
    "value": "36042",
    "label": "Honoraville - AL 36042",
    "county": "Crenshaw County",
    "state": "AL",
    "city": "Honoraville"
  },
  {
    "value": "36043",
    "label": "Hope Hull - AL 36043",
    "county": "Montgomery County",
    "state": "AL",
    "city": "Hope Hull"
  },
  {
    "value": "36046",
    "label": "Lapine - AL 36046",
    "county": "Crenshaw County",
    "state": "AL",
    "city": "Lapine"
  },
  {
    "value": "36047",
    "label": "Letohatchee - AL 36047",
    "county": "Lowndes County",
    "state": "AL",
    "city": "Letohatchee"
  },
  {
    "value": "36048",
    "label": "Louisville - AL 36048",
    "county": "Barbour County",
    "state": "AL",
    "city": "Louisville"
  },
  {
    "value": "36049",
    "label": "Luverne - AL 36049",
    "county": "Crenshaw County",
    "state": "AL",
    "city": "Luverne"
  },
  {
    "value": "36051",
    "label": "Marbury - AL 36051",
    "county": "Autauga County",
    "state": "AL",
    "city": "Marbury"
  },
  {
    "value": "36052",
    "label": "Mathews - AL 36052",
    "county": "Montgomery County",
    "state": "AL",
    "city": "Mathews"
  },
  {
    "value": "36053",
    "label": "Midway - AL 36053",
    "county": "Bullock County",
    "state": "AL",
    "city": "Midway"
  },
  {
    "value": "36054",
    "label": "Millbrook - AL 36054",
    "county": "Elmore County",
    "state": "AL",
    "city": "Millbrook"
  },
  {
    "value": "36064",
    "label": "Pike Road - AL 36064",
    "county": "Montgomery County",
    "state": "AL",
    "city": "Pike Road"
  },
  {
    "value": "36066",
    "label": "Prattville - AL 36066",
    "county": "Autauga County",
    "state": "AL",
    "city": "Prattville"
  },
  {
    "value": "36067",
    "label": "Prattville - AL 36067",
    "county": "Autauga County",
    "state": "AL",
    "city": "Prattville"
  },
  {
    "value": "36069",
    "label": "Ramer - AL 36069",
    "county": "Montgomery County",
    "state": "AL",
    "city": "Ramer"
  },
  {
    "value": "36071",
    "label": "Rutledge - AL 36071",
    "county": "Crenshaw County",
    "state": "AL",
    "city": "Rutledge"
  },
  {
    "value": "36075",
    "label": "Shorter - AL 36075",
    "county": "Macon County",
    "state": "AL",
    "city": "Shorter"
  },
  {
    "value": "36078",
    "label": "Tallassee - AL 36078",
    "county": "Elmore County",
    "state": "AL",
    "city": "Tallassee"
  },
  {
    "value": "36079",
    "label": "Troy - AL 36079",
    "county": "Pike County",
    "state": "AL",
    "city": "Troy"
  },
  {
    "value": "36080",
    "label": "Titus - AL 36080",
    "county": "Elmore County",
    "state": "AL",
    "city": "Titus"
  },
  {
    "value": "36081",
    "label": "Troy - AL 36081",
    "county": "Pike County",
    "state": "AL",
    "city": "Troy"
  },
  {
    "value": "36083",
    "label": "Tuskegee - AL 36083",
    "county": "Macon County",
    "state": "AL",
    "city": "Tuskegee"
  },
  {
    "value": "36088",
    "label": "Tuskegee Institute - AL 36088",
    "county": "Macon County",
    "state": "AL",
    "city": "Tuskegee Institute"
  },
  {
    "value": "36089",
    "label": "Union Springs - AL 36089",
    "county": "Bullock County",
    "state": "AL",
    "city": "Union Springs"
  },
  {
    "value": "36091",
    "label": "Verbena - AL 36091",
    "county": "Chilton County",
    "state": "AL",
    "city": "Verbena"
  },
  {
    "value": "36092",
    "label": "Wetumpka - AL 36092",
    "county": "Elmore County",
    "state": "AL",
    "city": "Wetumpka"
  },
  {
    "value": "36093",
    "label": "Wetumpka - AL 36093",
    "county": "Elmore County",
    "state": "AL",
    "city": "Wetumpka"
  },
  {
    "value": "36104",
    "label": "Montgomery - AL 36104",
    "county": "Montgomery County",
    "state": "AL",
    "city": "Montgomery"
  },
  {
    "value": "36105",
    "label": "Montgomery - AL 36105",
    "county": "Montgomery County",
    "state": "AL",
    "city": "Montgomery"
  },
  {
    "value": "36106",
    "label": "Montgomery - AL 36106",
    "county": "Montgomery County",
    "state": "AL",
    "city": "Montgomery"
  },
  {
    "value": "36107",
    "label": "Montgomery - AL 36107",
    "county": "Montgomery County",
    "state": "AL",
    "city": "Montgomery"
  },
  {
    "value": "36108",
    "label": "Montgomery - AL 36108",
    "county": "Montgomery County",
    "state": "AL",
    "city": "Montgomery"
  },
  {
    "value": "36109",
    "label": "Montgomery - AL 36109",
    "county": "Montgomery County",
    "state": "AL",
    "city": "Montgomery"
  },
  {
    "value": "36110",
    "label": "Montgomery - AL 36110",
    "county": "Montgomery County",
    "state": "AL",
    "city": "Montgomery"
  },
  {
    "value": "36111",
    "label": "Montgomery - AL 36111",
    "county": "Montgomery County",
    "state": "AL",
    "city": "Montgomery"
  },
  {
    "value": "36112",
    "label": "Montgomery - AL 36112",
    "county": "Montgomery County",
    "state": "AL",
    "city": "Montgomery"
  },
  {
    "value": "36113",
    "label": "Montgomery - AL 36113",
    "county": "Montgomery County",
    "state": "AL",
    "city": "Montgomery"
  },
  {
    "value": "36114",
    "label": "Montgomery - AL 36114",
    "county": "Montgomery County",
    "state": "AL",
    "city": "Montgomery"
  },
  {
    "value": "36115",
    "label": "Montgomery - AL 36115",
    "county": "Montgomery County",
    "state": "AL",
    "city": "Montgomery"
  },
  {
    "value": "36116",
    "label": "Montgomery - AL 36116",
    "county": "Montgomery County",
    "state": "AL",
    "city": "Montgomery"
  },
  {
    "value": "36117",
    "label": "Montgomery - AL 36117",
    "county": "Montgomery County",
    "state": "AL",
    "city": "Montgomery"
  },
  {
    "value": "36119",
    "label": "Montgomery - AL 36119",
    "county": "Montgomery County",
    "state": "AL",
    "city": "Montgomery"
  },
  {
    "value": "36201",
    "label": "Anniston - AL 36201",
    "county": "Calhoun County",
    "state": "AL",
    "city": "Anniston"
  },
  {
    "value": "36203",
    "label": "Oxford - AL 36203",
    "county": "Calhoun County",
    "state": "AL",
    "city": "Oxford"
  },
  {
    "value": "36205",
    "label": "Anniston - AL 36205",
    "county": "Calhoun County",
    "state": "AL",
    "city": "Anniston"
  },
  {
    "value": "36206",
    "label": "Anniston - AL 36206",
    "county": "Calhoun County",
    "state": "AL",
    "city": "Anniston"
  },
  {
    "value": "36207",
    "label": "Anniston - AL 36207",
    "county": "Calhoun County",
    "state": "AL",
    "city": "Anniston"
  },
  {
    "value": "36250",
    "label": "Alexandria - AL 36250",
    "county": "Calhoun County",
    "state": "AL",
    "city": "Alexandria"
  },
  {
    "value": "36251",
    "label": "Ashland - AL 36251",
    "county": "Clay County",
    "state": "AL",
    "city": "Ashland"
  },
  {
    "value": "36255",
    "label": "Cragford - AL 36255",
    "county": "Clay County",
    "state": "AL",
    "city": "Cragford"
  },
  {
    "value": "36256",
    "label": "Daviston - AL 36256",
    "county": "Tallapoosa County",
    "state": "AL",
    "city": "Daviston"
  },
  {
    "value": "36258",
    "label": "Delta - AL 36258",
    "county": "Clay County",
    "state": "AL",
    "city": "Delta"
  },
  {
    "value": "36260",
    "label": "Eastaboga - AL 36260",
    "county": "Calhoun County",
    "state": "AL",
    "city": "Eastaboga"
  },
  {
    "value": "36262",
    "label": "Fruithurst - AL 36262",
    "county": "Cleburne County",
    "state": "AL",
    "city": "Fruithurst"
  },
  {
    "value": "36263",
    "label": "Graham - AL 36263",
    "county": "Randolph County",
    "state": "AL",
    "city": "Graham"
  },
  {
    "value": "36264",
    "label": "Heflin - AL 36264",
    "county": "Cleburne County",
    "state": "AL",
    "city": "Heflin"
  },
  {
    "value": "36265",
    "label": "Jacksonville - AL 36265",
    "county": "Calhoun County",
    "state": "AL",
    "city": "Jacksonville"
  },
  {
    "value": "36266",
    "label": "Lineville - AL 36266",
    "county": "Clay County",
    "state": "AL",
    "city": "Lineville"
  },
  {
    "value": "36268",
    "label": "Munford - AL 36268",
    "county": "Talladega County",
    "state": "AL",
    "city": "Munford"
  },
  {
    "value": "36269",
    "label": "Muscadine - AL 36269",
    "county": "Cleburne County",
    "state": "AL",
    "city": "Muscadine"
  },
  {
    "value": "36271",
    "label": "Ohatchee - AL 36271",
    "county": "Calhoun County",
    "state": "AL",
    "city": "Ohatchee"
  },
  {
    "value": "36272",
    "label": "Piedmont - AL 36272",
    "county": "Calhoun County",
    "state": "AL",
    "city": "Piedmont"
  },
  {
    "value": "36273",
    "label": "Ranburne - AL 36273",
    "county": "Cleburne County",
    "state": "AL",
    "city": "Ranburne"
  },
  {
    "value": "36274",
    "label": "Roanoke - AL 36274",
    "county": "Randolph County",
    "state": "AL",
    "city": "Roanoke"
  },
  {
    "value": "36276",
    "label": "Wadley - AL 36276",
    "county": "Randolph County",
    "state": "AL",
    "city": "Wadley"
  },
  {
    "value": "36277",
    "label": "Weaver - AL 36277",
    "county": "Calhoun County",
    "state": "AL",
    "city": "Weaver"
  },
  {
    "value": "36278",
    "label": "Wedowee - AL 36278",
    "county": "Randolph County",
    "state": "AL",
    "city": "Wedowee"
  },
  {
    "value": "36279",
    "label": "Wellington - AL 36279",
    "county": "Calhoun County",
    "state": "AL",
    "city": "Wellington"
  },
  {
    "value": "36280",
    "label": "Woodland - AL 36280",
    "county": "Randolph County",
    "state": "AL",
    "city": "Woodland"
  },
  {
    "value": "36301",
    "label": "Dothan - AL 36301",
    "county": "Houston County",
    "state": "AL",
    "city": "Dothan"
  },
  {
    "value": "36303",
    "label": "Dothan - AL 36303",
    "county": "Houston County",
    "state": "AL",
    "city": "Dothan"
  },
  {
    "value": "36305",
    "label": "Dothan - AL 36305",
    "county": "Houston County",
    "state": "AL",
    "city": "Dothan"
  },
  {
    "value": "36310",
    "label": "Abbeville - AL 36310",
    "county": "Henry County",
    "state": "AL",
    "city": "Abbeville"
  },
  {
    "value": "36311",
    "label": "Ariton - AL 36311",
    "county": "Dale County",
    "state": "AL",
    "city": "Ariton"
  },
  {
    "value": "36312",
    "label": "Ashford - AL 36312",
    "county": "Houston County",
    "state": "AL",
    "city": "Ashford"
  },
  {
    "value": "36314",
    "label": "Black - AL 36314",
    "county": "Geneva County",
    "state": "AL",
    "city": "Black"
  },
  {
    "value": "36316",
    "label": "Chancellor - AL 36316",
    "county": "Geneva County",
    "state": "AL",
    "city": "Chancellor"
  },
  {
    "value": "36317",
    "label": "Clopton - AL 36317",
    "county": "Henry County",
    "state": "AL",
    "city": "Clopton"
  },
  {
    "value": "36318",
    "label": "Coffee Springs - AL 36318",
    "county": "Geneva County",
    "state": "AL",
    "city": "Coffee Springs"
  },
  {
    "value": "36319",
    "label": "Columbia - AL 36319",
    "county": "Houston County",
    "state": "AL",
    "city": "Columbia"
  },
  {
    "value": "36320",
    "label": "Cottonwood - AL 36320",
    "county": "Houston County",
    "state": "AL",
    "city": "Cottonwood"
  },
  {
    "value": "36321",
    "label": "Cowarts - AL 36321",
    "county": "Houston County",
    "state": "AL",
    "city": "Cowarts"
  },
  {
    "value": "36322",
    "label": "Daleville - AL 36322",
    "county": "Dale County",
    "state": "AL",
    "city": "Daleville"
  },
  {
    "value": "36323",
    "label": "Elba - AL 36323",
    "county": "Coffee County",
    "state": "AL",
    "city": "Elba"
  },
  {
    "value": "36330",
    "label": "Enterprise - AL 36330",
    "county": "Coffee County",
    "state": "AL",
    "city": "Enterprise"
  },
  {
    "value": "36340",
    "label": "Geneva - AL 36340",
    "county": "Geneva County",
    "state": "AL",
    "city": "Geneva"
  },
  {
    "value": "36343",
    "label": "Gordon - AL 36343",
    "county": "Houston County",
    "state": "AL",
    "city": "Gordon"
  },
  {
    "value": "36344",
    "label": "Hartford - AL 36344",
    "county": "Geneva County",
    "state": "AL",
    "city": "Hartford"
  },
  {
    "value": "36345",
    "label": "Headland - AL 36345",
    "county": "Henry County",
    "state": "AL",
    "city": "Headland"
  },
  {
    "value": "36346",
    "label": "Jack - AL 36346",
    "county": "Coffee County",
    "state": "AL",
    "city": "Jack"
  },
  {
    "value": "36350",
    "label": "Midland City - AL 36350",
    "county": "Dale County",
    "state": "AL",
    "city": "Midland City"
  },
  {
    "value": "36351",
    "label": "New Brockton - AL 36351",
    "county": "Coffee County",
    "state": "AL",
    "city": "New Brockton"
  },
  {
    "value": "36352",
    "label": "Newton - AL 36352",
    "county": "Houston County",
    "state": "AL",
    "city": "Newton"
  },
  {
    "value": "36353",
    "label": "Newville - AL 36353",
    "county": "Henry County",
    "state": "AL",
    "city": "Newville"
  },
  {
    "value": "36360",
    "label": "Ozark - AL 36360",
    "county": "Dale County",
    "state": "AL",
    "city": "Ozark"
  },
  {
    "value": "36362",
    "label": "Fort Rucker - AL 36362",
    "county": "Dale County",
    "state": "AL",
    "city": "Fort Rucker"
  },
  {
    "value": "36370",
    "label": "Pansey - AL 36370",
    "county": "Houston County",
    "state": "AL",
    "city": "Pansey"
  },
  {
    "value": "36373",
    "label": "Shorterville - AL 36373",
    "county": "Henry County",
    "state": "AL",
    "city": "Shorterville"
  },
  {
    "value": "36374",
    "label": "Skipperville - AL 36374",
    "county": "Dale County",
    "state": "AL",
    "city": "Skipperville"
  },
  {
    "value": "36375",
    "label": "Slocomb - AL 36375",
    "county": "Geneva County",
    "state": "AL",
    "city": "Slocomb"
  },
  {
    "value": "36376",
    "label": "Webb - AL 36376",
    "county": "Houston County",
    "state": "AL",
    "city": "Webb"
  },
  {
    "value": "36401",
    "label": "Evergreen - AL 36401",
    "county": "Conecuh County",
    "state": "AL",
    "city": "Evergreen"
  },
  {
    "value": "36420",
    "label": "Andalusia - AL 36420",
    "county": "Covington County",
    "state": "AL",
    "city": "Andalusia"
  },
  {
    "value": "36421",
    "label": "Andalusia - AL 36421",
    "county": "Covington County",
    "state": "AL",
    "city": "Andalusia"
  },
  {
    "value": "36425",
    "label": "Beatrice - AL 36425",
    "county": "Monroe County",
    "state": "AL",
    "city": "Beatrice"
  },
  {
    "value": "36426",
    "label": "Brewton - AL 36426",
    "county": "Escambia County",
    "state": "AL",
    "city": "Brewton"
  },
  {
    "value": "36432",
    "label": "Castleberry - AL 36432",
    "county": "Conecuh County",
    "state": "AL",
    "city": "Castleberry"
  },
  {
    "value": "36435",
    "label": "Coy - AL 36435",
    "county": "Wilcox County",
    "state": "AL",
    "city": "Coy"
  },
  {
    "value": "36436",
    "label": "Dickinson - AL 36436",
    "county": "Clarke County",
    "state": "AL",
    "city": "Dickinson"
  },
  {
    "value": "36441",
    "label": "Flomaton - AL 36441",
    "county": "Escambia County",
    "state": "AL",
    "city": "Flomaton"
  },
  {
    "value": "36442",
    "label": "Florala - AL 36442",
    "county": "Covington County",
    "state": "AL",
    "city": "Florala"
  },
  {
    "value": "36444",
    "label": "Franklin - AL 36444",
    "county": "Monroe County",
    "state": "AL",
    "city": "Franklin"
  },
  {
    "value": "36445",
    "label": "Frisco City - AL 36445",
    "county": "Monroe County",
    "state": "AL",
    "city": "Frisco City"
  },
  {
    "value": "36451",
    "label": "Grove Hill - AL 36451",
    "county": "Clarke County",
    "state": "AL",
    "city": "Grove Hill"
  },
  {
    "value": "36453",
    "label": "Kinston - AL 36453",
    "county": "Covington County",
    "state": "AL",
    "city": "Kinston"
  },
  {
    "value": "36454",
    "label": "Lenox - AL 36454",
    "county": "Conecuh County",
    "state": "AL",
    "city": "Lenox"
  },
  {
    "value": "36456",
    "label": "Mc Kenzie - AL 36456",
    "county": "Butler County",
    "state": "AL",
    "city": "Mc Kenzie"
  },
  {
    "value": "36460",
    "label": "Monroeville - AL 36460",
    "county": "Monroe County",
    "state": "AL",
    "city": "Monroeville"
  },
  {
    "value": "36467",
    "label": "Opp - AL 36467",
    "county": "Covington County",
    "state": "AL",
    "city": "Opp"
  },
  {
    "value": "36471",
    "label": "Peterman - AL 36471",
    "county": "Monroe County",
    "state": "AL",
    "city": "Peterman"
  },
  {
    "value": "36473",
    "label": "Range - AL 36473",
    "county": "Conecuh County",
    "state": "AL",
    "city": "Range"
  },
  {
    "value": "36474",
    "label": "Red Level - AL 36474",
    "county": "Covington County",
    "state": "AL",
    "city": "Red Level"
  },
  {
    "value": "36475",
    "label": "Repton - AL 36475",
    "county": "Conecuh County",
    "state": "AL",
    "city": "Repton"
  },
  {
    "value": "36477",
    "label": "Samson - AL 36477",
    "county": "Geneva County",
    "state": "AL",
    "city": "Samson"
  },
  {
    "value": "36480",
    "label": "Uriah - AL 36480",
    "county": "Monroe County",
    "state": "AL",
    "city": "Uriah"
  },
  {
    "value": "36481",
    "label": "Vredenburgh - AL 36481",
    "county": "Monroe County",
    "state": "AL",
    "city": "Vredenburgh"
  },
  {
    "value": "36482",
    "label": "Whatley - AL 36482",
    "county": "Clarke County",
    "state": "AL",
    "city": "Whatley"
  },
  {
    "value": "36483",
    "label": "Wing - AL 36483",
    "county": "Covington County",
    "state": "AL",
    "city": "Wing"
  },
  {
    "value": "36501",
    "label": "Jackson - AL 36501",
    "county": "Clarke County",
    "state": "AL",
    "city": "Jackson"
  },
  {
    "value": "36502",
    "label": "Atmore - AL 36502",
    "county": "Escambia County",
    "state": "AL",
    "city": "Atmore"
  },
  {
    "value": "36505",
    "label": "Axis - AL 36505",
    "county": "Mobile County",
    "state": "AL",
    "city": "Axis"
  },
  {
    "value": "36507",
    "label": "Bay Minette - AL 36507",
    "county": "Baldwin County",
    "state": "AL",
    "city": "Bay Minette"
  },
  {
    "value": "36509",
    "label": "Bayou La Batre - AL 36509",
    "county": "Mobile County",
    "state": "AL",
    "city": "Bayou La Batre"
  },
  {
    "value": "36511",
    "label": "Bon Secour - AL 36511",
    "county": "Baldwin County",
    "state": "AL",
    "city": "Bon Secour"
  },
  {
    "value": "36515",
    "label": "Carlton - AL 36515",
    "county": "Clarke County",
    "state": "AL",
    "city": "Carlton"
  },
  {
    "value": "36518",
    "label": "Chatom - AL 36518",
    "county": "Washington County",
    "state": "AL",
    "city": "Chatom"
  },
  {
    "value": "36521",
    "label": "Chunchula - AL 36521",
    "county": "Mobile County",
    "state": "AL",
    "city": "Chunchula"
  },
  {
    "value": "36522",
    "label": "Citronelle - AL 36522",
    "county": "Mobile County",
    "state": "AL",
    "city": "Citronelle"
  },
  {
    "value": "36523",
    "label": "Coden - AL 36523",
    "county": "Mobile County",
    "state": "AL",
    "city": "Coden"
  },
  {
    "value": "36524",
    "label": "Coffeeville - AL 36524",
    "county": "Clarke County",
    "state": "AL",
    "city": "Coffeeville"
  },
  {
    "value": "36525",
    "label": "Creola - AL 36525",
    "county": "Mobile County",
    "state": "AL",
    "city": "Creola"
  },
  {
    "value": "36526",
    "label": "Daphne - AL 36526",
    "county": "Baldwin County",
    "state": "AL",
    "city": "Daphne"
  },
  {
    "value": "36527",
    "label": "Daphne - AL 36527",
    "county": "Baldwin County",
    "state": "AL",
    "city": "Daphne"
  },
  {
    "value": "36528",
    "label": "Dauphin Island - AL 36528",
    "county": "Mobile County",
    "state": "AL",
    "city": "Dauphin Island"
  },
  {
    "value": "36529",
    "label": "Deer Park - AL 36529",
    "county": "Washington County",
    "state": "AL",
    "city": "Deer Park"
  },
  {
    "value": "36530",
    "label": "Elberta - AL 36530",
    "county": "Baldwin County",
    "state": "AL",
    "city": "Elberta"
  },
  {
    "value": "36532",
    "label": "Fairhope - AL 36532",
    "county": "Baldwin County",
    "state": "AL",
    "city": "Fairhope"
  },
  {
    "value": "36535",
    "label": "Foley - AL 36535",
    "county": "Baldwin County",
    "state": "AL",
    "city": "Foley"
  },
  {
    "value": "36538",
    "label": "Frankville - AL 36538",
    "county": "Washington County",
    "state": "AL",
    "city": "Frankville"
  },
  {
    "value": "36539",
    "label": "Fruitdale - AL 36539",
    "county": "Washington County",
    "state": "AL",
    "city": "Fruitdale"
  },
  {
    "value": "36540",
    "label": "Gainestown - AL 36540",
    "county": "Clarke County",
    "state": "AL",
    "city": "Gainestown"
  },
  {
    "value": "36541",
    "label": "Grand Bay - AL 36541",
    "county": "Mobile County",
    "state": "AL",
    "city": "Grand Bay"
  },
  {
    "value": "36542",
    "label": "Gulf Shores - AL 36542",
    "county": "Baldwin County",
    "state": "AL",
    "city": "Gulf Shores"
  },
  {
    "value": "36544",
    "label": "Irvington - AL 36544",
    "county": "Mobile County",
    "state": "AL",
    "city": "Irvington"
  },
  {
    "value": "36545",
    "label": "Jackson - AL 36545",
    "county": "Clarke County",
    "state": "AL",
    "city": "Jackson"
  },
  {
    "value": "36548",
    "label": "Leroy - AL 36548",
    "county": "Washington County",
    "state": "AL",
    "city": "Leroy"
  },
  {
    "value": "36549",
    "label": "Lillian - AL 36549",
    "county": "Baldwin County",
    "state": "AL",
    "city": "Lillian"
  },
  {
    "value": "36550",
    "label": "Little River - AL 36550",
    "county": "Baldwin County",
    "state": "AL",
    "city": "Little River"
  },
  {
    "value": "36551",
    "label": "Loxley - AL 36551",
    "county": "Baldwin County",
    "state": "AL",
    "city": "Loxley"
  },
  {
    "value": "36553",
    "label": "Mc Intosh - AL 36553",
    "county": "Washington County",
    "state": "AL",
    "city": "Mc Intosh"
  },
  {
    "value": "36555",
    "label": "Magnolia Springs - AL 36555",
    "county": "Baldwin County",
    "state": "AL",
    "city": "Magnolia Springs"
  },
  {
    "value": "36558",
    "label": "Millry - AL 36558",
    "county": "Washington County",
    "state": "AL",
    "city": "Millry"
  },
  {
    "value": "36560",
    "label": "Mount Vernon - AL 36560",
    "county": "Mobile County",
    "state": "AL",
    "city": "Mount Vernon"
  },
  {
    "value": "36561",
    "label": "Orange Beach - AL 36561",
    "county": "Baldwin County",
    "state": "AL",
    "city": "Orange Beach"
  },
  {
    "value": "36562",
    "label": "Perdido - AL 36562",
    "county": "Baldwin County",
    "state": "AL",
    "city": "Perdido"
  },
  {
    "value": "36567",
    "label": "Robertsdale - AL 36567",
    "county": "Baldwin County",
    "state": "AL",
    "city": "Robertsdale"
  },
  {
    "value": "36569",
    "label": "Saint Stephens - AL 36569",
    "county": "Washington County",
    "state": "AL",
    "city": "Saint Stephens"
  },
  {
    "value": "36571",
    "label": "Saraland - AL 36571",
    "county": "Mobile County",
    "state": "AL",
    "city": "Saraland"
  },
  {
    "value": "36572",
    "label": "Satsuma - AL 36572",
    "county": "Mobile County",
    "state": "AL",
    "city": "Satsuma"
  },
  {
    "value": "36574",
    "label": "Seminole - AL 36574",
    "county": "Baldwin County",
    "state": "AL",
    "city": "Seminole"
  },
  {
    "value": "36575",
    "label": "Semmes - AL 36575",
    "county": "Mobile County",
    "state": "AL",
    "city": "Semmes"
  },
  {
    "value": "36576",
    "label": "Silverhill - AL 36576",
    "county": "Baldwin County",
    "state": "AL",
    "city": "Silverhill"
  },
  {
    "value": "36578",
    "label": "Stapleton - AL 36578",
    "county": "Baldwin County",
    "state": "AL",
    "city": "Stapleton"
  },
  {
    "value": "36579",
    "label": "Stockton - AL 36579",
    "county": "Baldwin County",
    "state": "AL",
    "city": "Stockton"
  },
  {
    "value": "36580",
    "label": "Summerdale - AL 36580",
    "county": "Baldwin County",
    "state": "AL",
    "city": "Summerdale"
  },
  {
    "value": "36581",
    "label": "Sunflower - AL 36581",
    "county": "Washington County",
    "state": "AL",
    "city": "Sunflower"
  },
  {
    "value": "36582",
    "label": "Theodore - AL 36582",
    "county": "Mobile County",
    "state": "AL",
    "city": "Theodore"
  },
  {
    "value": "36583",
    "label": "Tibbie - AL 36583",
    "county": "Washington County",
    "state": "AL",
    "city": "Tibbie"
  },
  {
    "value": "36584",
    "label": "Vinegar Bend - AL 36584",
    "county": "Washington County",
    "state": "AL",
    "city": "Vinegar Bend"
  },
  {
    "value": "36585",
    "label": "Wagarville - AL 36585",
    "county": "Washington County",
    "state": "AL",
    "city": "Wagarville"
  },
  {
    "value": "36587",
    "label": "Wilmer - AL 36587",
    "county": "Mobile County",
    "state": "AL",
    "city": "Wilmer"
  },
  {
    "value": "36602",
    "label": "Mobile - AL 36602",
    "county": "Mobile County",
    "state": "AL",
    "city": "Mobile"
  },
  {
    "value": "36603",
    "label": "Mobile - AL 36603",
    "county": "Mobile County",
    "state": "AL",
    "city": "Mobile"
  },
  {
    "value": "36604",
    "label": "Mobile - AL 36604",
    "county": "Mobile County",
    "state": "AL",
    "city": "Mobile"
  },
  {
    "value": "36605",
    "label": "Mobile - AL 36605",
    "county": "Mobile County",
    "state": "AL",
    "city": "Mobile"
  },
  {
    "value": "36606",
    "label": "Mobile - AL 36606",
    "county": "Mobile County",
    "state": "AL",
    "city": "Mobile"
  },
  {
    "value": "36607",
    "label": "Mobile - AL 36607",
    "county": "Mobile County",
    "state": "AL",
    "city": "Mobile"
  },
  {
    "value": "36608",
    "label": "Mobile - AL 36608",
    "county": "Mobile County",
    "state": "AL",
    "city": "Mobile"
  },
  {
    "value": "36609",
    "label": "Mobile - AL 36609",
    "county": "Mobile County",
    "state": "AL",
    "city": "Mobile"
  },
  {
    "value": "36610",
    "label": "Mobile - AL 36610",
    "county": "Mobile County",
    "state": "AL",
    "city": "Mobile"
  },
  {
    "value": "36611",
    "label": "Mobile - AL 36611",
    "county": "Mobile County",
    "state": "AL",
    "city": "Mobile"
  },
  {
    "value": "36612",
    "label": "Mobile - AL 36612",
    "county": "Mobile County",
    "state": "AL",
    "city": "Mobile"
  },
  {
    "value": "36613",
    "label": "Eight Mile - AL 36613",
    "county": "Mobile County",
    "state": "AL",
    "city": "Eight Mile"
  },
  {
    "value": "36615",
    "label": "Mobile - AL 36615",
    "county": "Mobile County",
    "state": "AL",
    "city": "Mobile"
  },
  {
    "value": "36617",
    "label": "Mobile - AL 36617",
    "county": "Mobile County",
    "state": "AL",
    "city": "Mobile"
  },
  {
    "value": "36618",
    "label": "Mobile - AL 36618",
    "county": "Mobile County",
    "state": "AL",
    "city": "Mobile"
  },
  {
    "value": "36619",
    "label": "Mobile - AL 36619",
    "county": "Mobile County",
    "state": "AL",
    "city": "Mobile"
  },
  {
    "value": "36693",
    "label": "Mobile - AL 36693",
    "county": "Mobile County",
    "state": "AL",
    "city": "Mobile"
  },
  {
    "value": "36695",
    "label": "Mobile - AL 36695",
    "county": "Mobile County",
    "state": "AL",
    "city": "Mobile"
  },
  {
    "value": "36701",
    "label": "Selma - AL 36701",
    "county": "Dallas County",
    "state": "AL",
    "city": "Selma"
  },
  {
    "value": "36703",
    "label": "Selma - AL 36703",
    "county": "Dallas County",
    "state": "AL",
    "city": "Selma"
  },
  {
    "value": "36720",
    "label": "Alberta - AL 36720",
    "county": "Wilcox County",
    "state": "AL",
    "city": "Alberta"
  },
  {
    "value": "36721",
    "label": "Annemanie - AL 36721",
    "county": "Wilcox County",
    "state": "AL",
    "city": "Annemanie"
  },
  {
    "value": "36722",
    "label": "Arlington - AL 36722",
    "county": "Wilcox County",
    "state": "AL",
    "city": "Arlington"
  },
  {
    "value": "36726",
    "label": "Camden - AL 36726",
    "county": "Wilcox County",
    "state": "AL",
    "city": "Camden"
  },
  {
    "value": "36727",
    "label": "Campbell - AL 36727",
    "county": "Clarke County",
    "state": "AL",
    "city": "Campbell"
  },
  {
    "value": "36728",
    "label": "Catherine - AL 36728",
    "county": "Wilcox County",
    "state": "AL",
    "city": "Catherine"
  },
  {
    "value": "36732",
    "label": "Demopolis - AL 36732",
    "county": "Marengo County",
    "state": "AL",
    "city": "Demopolis"
  },
  {
    "value": "36736",
    "label": "Dixons Mills - AL 36736",
    "county": "Marengo County",
    "state": "AL",
    "city": "Dixons Mills"
  },
  {
    "value": "36738",
    "label": "Faunsdale - AL 36738",
    "county": "Marengo County",
    "state": "AL",
    "city": "Faunsdale"
  },
  {
    "value": "36740",
    "label": "Forkland - AL 36740",
    "county": "Greene County",
    "state": "AL",
    "city": "Forkland"
  },
  {
    "value": "36742",
    "label": "Gallion - AL 36742",
    "county": "Marengo County",
    "state": "AL",
    "city": "Gallion"
  },
  {
    "value": "36744",
    "label": "Greensboro - AL 36744",
    "county": "Hale County",
    "state": "AL",
    "city": "Greensboro"
  },
  {
    "value": "36748",
    "label": "Linden - AL 36748",
    "county": "Marengo County",
    "state": "AL",
    "city": "Linden"
  },
  {
    "value": "36749",
    "label": "Jones - AL 36749",
    "county": "Autauga County",
    "state": "AL",
    "city": "Jones"
  },
  {
    "value": "36750",
    "label": "Maplesville - AL 36750",
    "county": "Chilton County",
    "state": "AL",
    "city": "Maplesville"
  },
  {
    "value": "36751",
    "label": "Lower Peach Tree - AL 36751",
    "county": "Monroe County",
    "state": "AL",
    "city": "Lower Peach Tree"
  },
  {
    "value": "36752",
    "label": "Lowndesboro - AL 36752",
    "county": "Lowndes County",
    "state": "AL",
    "city": "Lowndesboro"
  },
  {
    "value": "36754",
    "label": "Magnolia - AL 36754",
    "county": "Marengo County",
    "state": "AL",
    "city": "Magnolia"
  },
  {
    "value": "36756",
    "label": "Marion - AL 36756",
    "county": "Perry County",
    "state": "AL",
    "city": "Marion"
  },
  {
    "value": "36758",
    "label": "Plantersville - AL 36758",
    "county": "Dallas County",
    "state": "AL",
    "city": "Plantersville"
  },
  {
    "value": "36759",
    "label": "Marion Junction - AL 36759",
    "county": "Dallas County",
    "state": "AL",
    "city": "Marion Junction"
  },
  {
    "value": "36761",
    "label": "Minter - AL 36761",
    "county": "Dallas County",
    "state": "AL",
    "city": "Minter"
  },
  {
    "value": "36762",
    "label": "Morvin - AL 36762",
    "county": "Clarke County",
    "state": "AL",
    "city": "Morvin"
  },
  {
    "value": "36765",
    "label": "Newbern - AL 36765",
    "county": "Hale County",
    "state": "AL",
    "city": "Newbern"
  },
  {
    "value": "36767",
    "label": "Orrville - AL 36767",
    "county": "Dallas County",
    "state": "AL",
    "city": "Orrville"
  },
  {
    "value": "36768",
    "label": "Pine Apple - AL 36768",
    "county": "Wilcox County",
    "state": "AL",
    "city": "Pine Apple"
  },
  {
    "value": "36769",
    "label": "Pine Hill - AL 36769",
    "county": "Wilcox County",
    "state": "AL",
    "city": "Pine Hill"
  },
  {
    "value": "36773",
    "label": "Safford - AL 36773",
    "county": "Dallas County",
    "state": "AL",
    "city": "Safford"
  },
  {
    "value": "36775",
    "label": "Sardis - AL 36775",
    "county": "Dallas County",
    "state": "AL",
    "city": "Sardis"
  },
  {
    "value": "36776",
    "label": "Sawyerville - AL 36776",
    "county": "Hale County",
    "state": "AL",
    "city": "Sawyerville"
  },
  {
    "value": "36782",
    "label": "Sweet Water - AL 36782",
    "county": "Marengo County",
    "state": "AL",
    "city": "Sweet Water"
  },
  {
    "value": "36783",
    "label": "Thomaston - AL 36783",
    "county": "Marengo County",
    "state": "AL",
    "city": "Thomaston"
  },
  {
    "value": "36784",
    "label": "Thomasville - AL 36784",
    "county": "Clarke County",
    "state": "AL",
    "city": "Thomasville"
  },
  {
    "value": "36785",
    "label": "Tyler - AL 36785",
    "county": "Lowndes County",
    "state": "AL",
    "city": "Tyler"
  },
  {
    "value": "36786",
    "label": "Uniontown - AL 36786",
    "county": "Perry County",
    "state": "AL",
    "city": "Uniontown"
  },
  {
    "value": "36790",
    "label": "Stanton - AL 36790",
    "county": "Chilton County",
    "state": "AL",
    "city": "Stanton"
  },
  {
    "value": "36792",
    "label": "Randolph - AL 36792",
    "county": "Bibb County",
    "state": "AL",
    "city": "Randolph"
  },
  {
    "value": "36793",
    "label": "Lawley - AL 36793",
    "county": "Bibb County",
    "state": "AL",
    "city": "Lawley"
  },
  {
    "value": "36801",
    "label": "Opelika - AL 36801",
    "county": "Lee County",
    "state": "AL",
    "city": "Opelika"
  },
  {
    "value": "36804",
    "label": "Opelika - AL 36804",
    "county": "Lee County",
    "state": "AL",
    "city": "Opelika"
  },
  {
    "value": "36830",
    "label": "Auburn - AL 36830",
    "county": "Lee County",
    "state": "AL",
    "city": "Auburn"
  },
  {
    "value": "36832",
    "label": "Auburn - AL 36832",
    "county": "Lee County",
    "state": "AL",
    "city": "Auburn"
  },
  {
    "value": "36850",
    "label": "Camp Hill - AL 36850",
    "county": "Tallapoosa County",
    "state": "AL",
    "city": "Camp Hill"
  },
  {
    "value": "36852",
    "label": "Cusseta - AL 36852",
    "county": "Lee County",
    "state": "AL",
    "city": "Cusseta"
  },
  {
    "value": "36853",
    "label": "Dadeville - AL 36853",
    "county": "Tallapoosa County",
    "state": "AL",
    "city": "Dadeville"
  },
  {
    "value": "36854",
    "label": "Valley - AL 36854",
    "county": "Chambers County",
    "state": "AL",
    "city": "Valley"
  },
  {
    "value": "36855",
    "label": "Five Points - AL 36855",
    "county": "Chambers County",
    "state": "AL",
    "city": "Five Points"
  },
  {
    "value": "36856",
    "label": "Fort Mitchell - AL 36856",
    "county": "Russell County",
    "state": "AL",
    "city": "Fort Mitchell"
  },
  {
    "value": "36858",
    "label": "Hatchechubbee - AL 36858",
    "county": "Russell County",
    "state": "AL",
    "city": "Hatchechubbee"
  },
  {
    "value": "36860",
    "label": "Hurtsboro - AL 36860",
    "county": "Russell County",
    "state": "AL",
    "city": "Hurtsboro"
  },
  {
    "value": "36861",
    "label": "Jacksons Gap - AL 36861",
    "county": "Tallapoosa County",
    "state": "AL",
    "city": "Jacksons Gap"
  },
  {
    "value": "36862",
    "label": "Lafayette - AL 36862",
    "county": "Chambers County",
    "state": "AL",
    "city": "Lafayette"
  },
  {
    "value": "36863",
    "label": "Lanett - AL 36863",
    "county": "Chambers County",
    "state": "AL",
    "city": "Lanett"
  },
  {
    "value": "36866",
    "label": "Notasulga - AL 36866",
    "county": "Macon County",
    "state": "AL",
    "city": "Notasulga"
  },
  {
    "value": "36867",
    "label": "Phenix City - AL 36867",
    "county": "Russell County",
    "state": "AL",
    "city": "Phenix City"
  },
  {
    "value": "36869",
    "label": "Phenix City - AL 36869",
    "county": "Russell County",
    "state": "AL",
    "city": "Phenix City"
  },
  {
    "value": "36870",
    "label": "Phenix City - AL 36870",
    "county": "Lee County",
    "state": "AL",
    "city": "Phenix City"
  },
  {
    "value": "36871",
    "label": "Pittsview - AL 36871",
    "county": "Russell County",
    "state": "AL",
    "city": "Pittsview"
  },
  {
    "value": "36874",
    "label": "Salem - AL 36874",
    "county": "Lee County",
    "state": "AL",
    "city": "Salem"
  },
  {
    "value": "36875",
    "label": "Seale - AL 36875",
    "county": "Russell County",
    "state": "AL",
    "city": "Seale"
  },
  {
    "value": "36877",
    "label": "Smiths Station - AL 36877",
    "county": "Lee County",
    "state": "AL",
    "city": "Smiths Station"
  },
  {
    "value": "36879",
    "label": "Waverly - AL 36879",
    "county": "Lee County",
    "state": "AL",
    "city": "Waverly"
  },
  {
    "value": "36904",
    "label": "Butler - AL 36904",
    "county": "Choctaw County",
    "state": "AL",
    "city": "Butler"
  },
  {
    "value": "36907",
    "label": "Cuba - AL 36907",
    "county": "Sumter County",
    "state": "AL",
    "city": "Cuba"
  },
  {
    "value": "36908",
    "label": "Gilbertown - AL 36908",
    "county": "Choctaw County",
    "state": "AL",
    "city": "Gilbertown"
  },
  {
    "value": "36910",
    "label": "Jachin - AL 36910",
    "county": "Choctaw County",
    "state": "AL",
    "city": "Jachin"
  },
  {
    "value": "36912",
    "label": "Lisman - AL 36912",
    "county": "Choctaw County",
    "state": "AL",
    "city": "Lisman"
  },
  {
    "value": "36913",
    "label": "Melvin - AL 36913",
    "county": "Choctaw County",
    "state": "AL",
    "city": "Melvin"
  },
  {
    "value": "36915",
    "label": "Needham - AL 36915",
    "county": "Choctaw County",
    "state": "AL",
    "city": "Needham"
  },
  {
    "value": "36916",
    "label": "Pennington - AL 36916",
    "county": "Choctaw County",
    "state": "AL",
    "city": "Pennington"
  },
  {
    "value": "36919",
    "label": "Silas - AL 36919",
    "county": "Choctaw County",
    "state": "AL",
    "city": "Silas"
  },
  {
    "value": "36921",
    "label": "Toxey - AL 36921",
    "county": "Choctaw County",
    "state": "AL",
    "city": "Toxey"
  },
  {
    "value": "36922",
    "label": "Ward - AL 36922",
    "county": "Choctaw County",
    "state": "AL",
    "city": "Ward"
  },
  {
    "value": "36925",
    "label": "York - AL 36925",
    "county": "Sumter County",
    "state": "AL",
    "city": "York"
  },
  {
    "value": "99501",
    "label": "Anchorage - AK 99501",
    "county": "Anchorage Municipality",
    "state": "AK",
    "city": "Anchorage"
  },
  {
    "value": "99502",
    "label": "Anchorage - AK 99502",
    "county": "Anchorage Municipality",
    "state": "AK",
    "city": "Anchorage"
  },
  {
    "value": "99503",
    "label": "Anchorage - AK 99503",
    "county": "Anchorage Municipality",
    "state": "AK",
    "city": "Anchorage"
  },
  {
    "value": "99504",
    "label": "Anchorage - AK 99504",
    "county": "Anchorage Municipality",
    "state": "AK",
    "city": "Anchorage"
  },
  {
    "value": "99505",
    "label": "Jber - AK 99505",
    "county": "Anchorage Municipality",
    "state": "AK",
    "city": "Jber"
  },
  {
    "value": "99506",
    "label": "Jber - AK 99506",
    "county": "Anchorage Municipality",
    "state": "AK",
    "city": "Jber"
  },
  {
    "value": "99507",
    "label": "Anchorage - AK 99507",
    "county": "Anchorage Municipality",
    "state": "AK",
    "city": "Anchorage"
  },
  {
    "value": "99508",
    "label": "Anchorage - AK 99508",
    "county": "Anchorage Municipality",
    "state": "AK",
    "city": "Anchorage"
  },
  {
    "value": "99513",
    "label": "Anchorage - AK 99513",
    "county": "Anchorage Municipality",
    "state": "AK",
    "city": "Anchorage"
  },
  {
    "value": "99515",
    "label": "Anchorage - AK 99515",
    "county": "Anchorage Municipality",
    "state": "AK",
    "city": "Anchorage"
  },
  {
    "value": "99516",
    "label": "Anchorage - AK 99516",
    "county": "Anchorage Municipality",
    "state": "AK",
    "city": "Anchorage"
  },
  {
    "value": "99517",
    "label": "Anchorage - AK 99517",
    "county": "Anchorage Municipality",
    "state": "AK",
    "city": "Anchorage"
  },
  {
    "value": "99518",
    "label": "Anchorage - AK 99518",
    "county": "Anchorage Municipality",
    "state": "AK",
    "city": "Anchorage"
  },
  {
    "value": "99529",
    "label": "Anchorage - AK 99529",
    "county": "Municipality of Anchorage",
    "state": "AK",
    "city": "Anchorage"
  },
  {
    "value": "99530",
    "label": "Anchorage - AK 99530",
    "county": "Municipality of Anchorage",
    "state": "AK",
    "city": "Anchorage"
  },
  {
    "value": "99540",
    "label": "Indian - AK 99540",
    "county": "Anchorage Municipality",
    "state": "AK",
    "city": "Indian"
  },
  {
    "value": "99556",
    "label": "Anchor Point - AK 99556",
    "county": "Kenai Peninsula Borough",
    "state": "AK",
    "city": "Anchor Point"
  },
  {
    "value": "99567",
    "label": "Chugiak - AK 99567",
    "county": "Anchorage Municipality",
    "state": "AK",
    "city": "Chugiak"
  },
  {
    "value": "99568",
    "label": "Clam Gulch - AK 99568",
    "county": "Kenai Peninsula Borough",
    "state": "AK",
    "city": "Clam Gulch"
  },
  {
    "value": "99572",
    "label": "Cooper Landing - AK 99572",
    "county": "Kenai Peninsula Borough",
    "state": "AK",
    "city": "Cooper Landing"
  },
  {
    "value": "99573",
    "label": "Copper Center - AK 99573",
    "county": "Valdez-Cordova Census Area",
    "state": "AK",
    "city": "Copper Center"
  },
  {
    "value": "99577",
    "label": "Eagle River - AK 99577",
    "county": "Anchorage Municipality",
    "state": "AK",
    "city": "Eagle River"
  },
  {
    "value": "99586",
    "label": "Gakona - AK 99586",
    "county": "Valdez-Cordova Census Area",
    "state": "AK",
    "city": "Gakona"
  },
  {
    "value": "99588",
    "label": "Glennallen - AK 99588",
    "county": "Valdez-Cordova Census Area",
    "state": "AK",
    "city": "Glennallen"
  },
  {
    "value": "99599",
    "label": "Anchorage - AK 99599",
    "county": "Municipality of Anchorage",
    "state": "AK",
    "city": "Anchorage"
  },
  {
    "value": "99603",
    "label": "Homer - AK 99603",
    "county": "Kenai Peninsula Borough",
    "state": "AK",
    "city": "Homer"
  },
  {
    "value": "99605",
    "label": "Hope - AK 99605",
    "county": "Kenai Peninsula Borough",
    "state": "AK",
    "city": "Hope"
  },
  {
    "value": "99610",
    "label": "Kasilof - AK 99610",
    "county": "Kenai Peninsula Borough",
    "state": "AK",
    "city": "Kasilof"
  },
  {
    "value": "99611",
    "label": "Kenai - AK 99611",
    "county": "Kenai Peninsula Borough",
    "state": "AK",
    "city": "Kenai"
  },
  {
    "value": "99615",
    "label": "Kodiak - AK 99615",
    "county": "Kodiak Island Borough",
    "state": "AK",
    "city": "Kodiak"
  },
  {
    "value": "99623",
    "label": "Wasilla - AK 99623",
    "county": "",
    "state": "AK",
    "city": "Wasilla"
  },
  {
    "value": "99631",
    "label": "Moose Pass - AK 99631",
    "county": "Kenai Peninsula Borough",
    "state": "AK",
    "city": "Moose Pass"
  },
  {
    "value": "99639",
    "label": "Ninilchik - AK 99639",
    "county": "Kenai Peninsula Borough",
    "state": "AK",
    "city": "Ninilchik"
  },
  {
    "value": "99645",
    "label": "Palmer - AK 99645",
    "county": "Matanuska-Susitna Borough",
    "state": "AK",
    "city": "Palmer"
  },
  {
    "value": "99654",
    "label": "Wasilla - AK 99654",
    "county": "Matanuska-Susitna Borough",
    "state": "AK",
    "city": "Wasilla"
  },
  {
    "value": "99664",
    "label": "Seward - AK 99664",
    "county": "Kenai Peninsula Borough",
    "state": "AK",
    "city": "Seward"
  },
  {
    "value": "99669",
    "label": "Soldotna - AK 99669",
    "county": "Kenai Peninsula Borough",
    "state": "AK",
    "city": "Soldotna"
  },
  {
    "value": "99672",
    "label": "Sterling - AK 99672",
    "county": "Kenai Peninsula Borough",
    "state": "AK",
    "city": "Sterling"
  },
  {
    "value": "99674",
    "label": "Sutton - AK 99674",
    "county": "Matanuska-Susitna Borough",
    "state": "AK",
    "city": "Sutton"
  },
  {
    "value": "99676",
    "label": "Talkeetna - AK 99676",
    "county": "Matanuska-Susitna Borough",
    "state": "AK",
    "city": "Talkeetna"
  },
  {
    "value": "99686",
    "label": "Valdez - AK 99686",
    "county": "Valdez-Cordova Census Area",
    "state": "AK",
    "city": "Valdez"
  },
  {
    "value": "99688",
    "label": "Willow - AK 99688",
    "county": "Matanuska-Susitna Borough",
    "state": "AK",
    "city": "Willow"
  },
  {
    "value": "99701",
    "label": "Fairbanks - AK 99701",
    "county": "Fairbanks North Star Borough",
    "state": "AK",
    "city": "Fairbanks"
  },
  {
    "value": "99702",
    "label": "Eielson AFB - AK 99702",
    "county": "Fairbanks North Star Borough",
    "state": "AK",
    "city": "Eielson AFB"
  },
  {
    "value": "99703",
    "label": "Fort Wainwright - AK 99703",
    "county": "Fairbanks North Star Borough",
    "state": "AK",
    "city": "Fort Wainwright"
  },
  {
    "value": "99704",
    "label": "Clear - AK 99704",
    "county": "Denali Borough",
    "state": "AK",
    "city": "Clear"
  },
  {
    "value": "99705",
    "label": "North Pole - AK 99705",
    "county": "Fairbanks North Star Borough",
    "state": "AK",
    "city": "North Pole"
  },
  {
    "value": "99709",
    "label": "Fairbanks - AK 99709",
    "county": "Fairbanks North Star Borough",
    "state": "AK",
    "city": "Fairbanks"
  },
  {
    "value": "99712",
    "label": "Fairbanks - AK 99712",
    "county": "Fairbanks North Star Borough",
    "state": "AK",
    "city": "Fairbanks"
  },
  {
    "value": "99714",
    "label": "Salcha - AK 99714",
    "county": "Fairbanks North Star Borough",
    "state": "AK",
    "city": "Salcha"
  },
  {
    "value": "99737",
    "label": "Delta Junction - AK 99737",
    "county": "Southeast Fairbanks Census Area",
    "state": "AK",
    "city": "Delta Junction"
  },
  {
    "value": "99743",
    "label": "Healy - AK 99743",
    "county": "Denali Borough",
    "state": "AK",
    "city": "Healy"
  },
  {
    "value": "99760",
    "label": "Nenana - AK 99760",
    "county": "Yukon-Koyukuk Census Area",
    "state": "AK",
    "city": "Nenana"
  },
  {
    "value": "99779",
    "label": "Tok - AK 99779",
    "county": "Southeast Fairbanks Census Area",
    "state": "AK",
    "city": "Tok"
  },
  {
    "value": "99780",
    "label": "Tok - AK 99780",
    "county": "Southeast Fairbanks Census Area",
    "state": "AK",
    "city": "Tok"
  },
  {
    "value": "99790",
    "label": "Fairbanks - AK 99790",
    "county": "Yukon-Koyukuk Census Area",
    "state": "AK",
    "city": "Fairbanks"
  },
  {
    "value": "99801",
    "label": "Juneau - AK 99801",
    "county": "Juneau City and Borough",
    "state": "AK",
    "city": "Juneau"
  },
  {
    "value": "99824",
    "label": "Douglas - AK 99824",
    "county": "Juneau City and Borough",
    "state": "AK",
    "city": "Douglas"
  },
  {
    "value": "99827",
    "label": "Haines - AK 99827",
    "county": "Haines Borough",
    "state": "AK",
    "city": "Haines"
  },
  {
    "value": "99835",
    "label": "Sitka - AK 99835",
    "county": "Sitka City and Borough",
    "state": "AK",
    "city": "Sitka"
  },
  {
    "value": "99901",
    "label": "Ketchikan - AK 99901",
    "county": "Ketchikan Gateway Borough",
    "state": "AK",
    "city": "Ketchikan"
  },
  {
    "value": "85003",
    "label": "Phoenix - AZ 85003",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85004",
    "label": "Phoenix - AZ 85004",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85006",
    "label": "Phoenix - AZ 85006",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85007",
    "label": "Phoenix - AZ 85007",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85008",
    "label": "Phoenix - AZ 85008",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85009",
    "label": "Phoenix - AZ 85009",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85012",
    "label": "Phoenix - AZ 85012",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85013",
    "label": "Phoenix - AZ 85013",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85014",
    "label": "Phoenix - AZ 85014",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85015",
    "label": "Phoenix - AZ 85015",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85016",
    "label": "Phoenix - AZ 85016",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85017",
    "label": "Phoenix - AZ 85017",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85018",
    "label": "Phoenix - AZ 85018",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85019",
    "label": "Phoenix - AZ 85019",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85020",
    "label": "Phoenix - AZ 85020",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85021",
    "label": "Phoenix - AZ 85021",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85022",
    "label": "Phoenix - AZ 85022",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85023",
    "label": "Phoenix - AZ 85023",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85024",
    "label": "Phoenix - AZ 85024",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85025",
    "label": "Phoenix - AZ 85025",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85026",
    "label": "Phoenix - AZ 85026",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85027",
    "label": "Phoenix - AZ 85027",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85028",
    "label": "Phoenix - AZ 85028",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85029",
    "label": "Phoenix - AZ 85029",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85031",
    "label": "Phoenix - AZ 85031",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85032",
    "label": "Phoenix - AZ 85032",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85033",
    "label": "Phoenix - AZ 85033",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85034",
    "label": "Phoenix - AZ 85034",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85035",
    "label": "Phoenix - AZ 85035",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85037",
    "label": "Phoenix - AZ 85037",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85039",
    "label": "Phoenix - AZ 85039",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85040",
    "label": "Phoenix - AZ 85040",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85041",
    "label": "Phoenix - AZ 85041",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85042",
    "label": "Phoenix - AZ 85042",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85043",
    "label": "Phoenix - AZ 85043",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85044",
    "label": "Phoenix - AZ 85044",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85045",
    "label": "Phoenix - AZ 85045",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85048",
    "label": "Phoenix - AZ 85048",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85050",
    "label": "Phoenix - AZ 85050",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85051",
    "label": "Phoenix - AZ 85051",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85053",
    "label": "Phoenix - AZ 85053",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85054",
    "label": "Phoenix - AZ 85054",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85055",
    "label": "Phoenix - AZ 85055",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85065",
    "label": "Phoenix - AZ 85065",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85073",
    "label": "Phoenix - AZ 85073",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85083",
    "label": "Phoenix - AZ 85083",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85085",
    "label": "Phoenix - AZ 85085",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85086",
    "label": "Phoenix - AZ 85086",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85087",
    "label": "New River - AZ 85087",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "New River"
  },
  {
    "value": "85096",
    "label": "Phoenix - AZ 85096",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85097",
    "label": "Phoenix - AZ 85097",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Phoenix"
  },
  {
    "value": "85118",
    "label": "Gold Canyon - AZ 85118",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Gold Canyon"
  },
  {
    "value": "85119",
    "label": "Apache Junction - AZ 85119",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Apache Junction"
  },
  {
    "value": "85120",
    "label": "Apache Junction - AZ 85120",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Apache Junction"
  },
  {
    "value": "85122",
    "label": "Casa Grande - AZ 85122",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Casa Grande"
  },
  {
    "value": "85128",
    "label": "Coolidge - AZ 85128",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Coolidge"
  },
  {
    "value": "85131",
    "label": "Eloy - AZ 85131",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Eloy"
  },
  {
    "value": "85132",
    "label": "Florence - AZ 85132",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Florence"
  },
  {
    "value": "85137",
    "label": "Kearny - AZ 85137",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Kearny"
  },
  {
    "value": "85138",
    "label": "Maricopa - AZ 85138",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Maricopa"
  },
  {
    "value": "85139",
    "label": "Maricopa - AZ 85139",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Maricopa"
  },
  {
    "value": "85140",
    "label": "San Tan Valley - AZ 85140",
    "county": "Pinal County",
    "state": "AZ",
    "city": "San Tan Valley"
  },
  {
    "value": "85141",
    "label": "Picacho - AZ 85141",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Picacho"
  },
  {
    "value": "85142",
    "label": "Queen Creek - AZ 85142",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Queen Creek"
  },
  {
    "value": "85143",
    "label": "San Tan Valley - AZ 85143",
    "county": "Pinal County",
    "state": "AZ",
    "city": "San Tan Valley"
  },
  {
    "value": "85145",
    "label": "Red Rock - AZ 85145",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Red Rock"
  },
  {
    "value": "85172",
    "label": "Stanfield - AZ 85172",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Stanfield"
  },
  {
    "value": "85173",
    "label": "Superior - AZ 85173",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Superior"
  },
  {
    "value": "85192",
    "label": "Winkelman - AZ 85192",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Winkelman"
  },
  {
    "value": "85193",
    "label": "Casa Grande - AZ 85193",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Casa Grande"
  },
  {
    "value": "85194",
    "label": "Casa Grande - AZ 85194",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Casa Grande"
  },
  {
    "value": "85201",
    "label": "Mesa - AZ 85201",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Mesa"
  },
  {
    "value": "85202",
    "label": "Mesa - AZ 85202",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Mesa"
  },
  {
    "value": "85203",
    "label": "Mesa - AZ 85203",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Mesa"
  },
  {
    "value": "85204",
    "label": "Mesa - AZ 85204",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Mesa"
  },
  {
    "value": "85205",
    "label": "Mesa - AZ 85205",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Mesa"
  },
  {
    "value": "85206",
    "label": "Mesa - AZ 85206",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Mesa"
  },
  {
    "value": "85207",
    "label": "Mesa - AZ 85207",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Mesa"
  },
  {
    "value": "85208",
    "label": "Mesa - AZ 85208",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Mesa"
  },
  {
    "value": "85209",
    "label": "Mesa - AZ 85209",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Mesa"
  },
  {
    "value": "85210",
    "label": "Mesa - AZ 85210",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Mesa"
  },
  {
    "value": "85212",
    "label": "Mesa - AZ 85212",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Mesa"
  },
  {
    "value": "85213",
    "label": "Mesa - AZ 85213",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Mesa"
  },
  {
    "value": "85215",
    "label": "Mesa - AZ 85215",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Mesa"
  },
  {
    "value": "85217",
    "label": "Apache Junction - AZ 85217",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Apache Junction"
  },
  {
    "value": "85218",
    "label": "Apache Junction - AZ 85218",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Apache Junction"
  },
  {
    "value": "85219",
    "label": "Apache Junction - AZ 85219",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Apache Junction"
  },
  {
    "value": "85220",
    "label": "Apache Junction - AZ 85220",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Apache Junction"
  },
  {
    "value": "85222",
    "label": "Casa Grande - AZ 85222",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Casa Grande"
  },
  {
    "value": "85223",
    "label": "Arizona City - AZ 85223",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Arizona City"
  },
  {
    "value": "85224",
    "label": "Chandler - AZ 85224",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Chandler"
  },
  {
    "value": "85225",
    "label": "Chandler - AZ 85225",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Chandler"
  },
  {
    "value": "85226",
    "label": "Chandler - AZ 85226",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Chandler"
  },
  {
    "value": "85227",
    "label": "Chandler Heights - AZ 85227",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Chandler Heights"
  },
  {
    "value": "85228",
    "label": "Coolidge - AZ 85228",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Coolidge"
  },
  {
    "value": "85230",
    "label": "Casa Grande - AZ 85230",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Casa Grande"
  },
  {
    "value": "85231",
    "label": "Eloy - AZ 85231",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Eloy"
  },
  {
    "value": "85232",
    "label": "Florence - AZ 85232",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Florence"
  },
  {
    "value": "85233",
    "label": "Gilbert - AZ 85233",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Gilbert"
  },
  {
    "value": "85234",
    "label": "Gilbert - AZ 85234",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Gilbert"
  },
  {
    "value": "85237",
    "label": "Kearny - AZ 85237",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Kearny"
  },
  {
    "value": "85238",
    "label": "Maricopa - AZ 85238",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Maricopa"
  },
  {
    "value": "85239",
    "label": "Maricopa - AZ 85239",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Maricopa"
  },
  {
    "value": "85240",
    "label": "Queen Creek - AZ 85240",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Queen Creek"
  },
  {
    "value": "85242",
    "label": "Queen Creek - AZ 85242",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Queen Creek"
  },
  {
    "value": "85243",
    "label": "Queen Creek - AZ 85243",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Queen Creek"
  },
  {
    "value": "85248",
    "label": "Chandler - AZ 85248",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Chandler"
  },
  {
    "value": "85249",
    "label": "Chandler - AZ 85249",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Chandler"
  },
  {
    "value": "85250",
    "label": "Scottsdale - AZ 85250",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Scottsdale"
  },
  {
    "value": "85251",
    "label": "Scottsdale - AZ 85251",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Scottsdale"
  },
  {
    "value": "85253",
    "label": "Paradise Valley - AZ 85253",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Paradise Valley"
  },
  {
    "value": "85254",
    "label": "Scottsdale - AZ 85254",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Scottsdale"
  },
  {
    "value": "85255",
    "label": "Scottsdale - AZ 85255",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Scottsdale"
  },
  {
    "value": "85256",
    "label": "Scottsdale - AZ 85256",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Scottsdale"
  },
  {
    "value": "85257",
    "label": "Scottsdale - AZ 85257",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Scottsdale"
  },
  {
    "value": "85258",
    "label": "Scottsdale - AZ 85258",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Scottsdale"
  },
  {
    "value": "85259",
    "label": "Scottsdale - AZ 85259",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Scottsdale"
  },
  {
    "value": "85260",
    "label": "Scottsdale - AZ 85260",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Scottsdale"
  },
  {
    "value": "85262",
    "label": "Scottsdale - AZ 85262",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Scottsdale"
  },
  {
    "value": "85263",
    "label": "Rio Verde - AZ 85263",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Rio Verde"
  },
  {
    "value": "85264",
    "label": "Fort Mcdowell - AZ 85264",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Fort Mcdowell"
  },
  {
    "value": "85266",
    "label": "Scottsdale - AZ 85266",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Scottsdale"
  },
  {
    "value": "85268",
    "label": "Fountain Hills - AZ 85268",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Fountain Hills"
  },
  {
    "value": "85272",
    "label": "Stanfield - AZ 85272",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Stanfield"
  },
  {
    "value": "85273",
    "label": "Superior - AZ 85273",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Superior"
  },
  {
    "value": "85278",
    "label": "Apache Junction - AZ 85278",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Apache Junction"
  },
  {
    "value": "85279",
    "label": "Florence - AZ 85279",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Florence"
  },
  {
    "value": "85281",
    "label": "Tempe - AZ 85281",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Tempe"
  },
  {
    "value": "85282",
    "label": "Tempe - AZ 85282",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Tempe"
  },
  {
    "value": "85283",
    "label": "Tempe - AZ 85283",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Tempe"
  },
  {
    "value": "85284",
    "label": "Tempe - AZ 85284",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Tempe"
  },
  {
    "value": "85286",
    "label": "Chandler - AZ 85286",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Chandler"
  },
  {
    "value": "85288",
    "label": "Tempe - AZ 85288",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Tempe"
  },
  {
    "value": "85290",
    "label": "Apache Junction - AZ 85290",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Apache Junction"
  },
  {
    "value": "85292",
    "label": "Winkelman - AZ 85292",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Winkelman"
  },
  {
    "value": "85293",
    "label": "Casa Grande - AZ 85293",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Casa Grande"
  },
  {
    "value": "85294",
    "label": "Casa Grande - AZ 85294",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Casa Grande"
  },
  {
    "value": "85295",
    "label": "Gilbert - AZ 85295",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Gilbert"
  },
  {
    "value": "85296",
    "label": "Gilbert - AZ 85296",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Gilbert"
  },
  {
    "value": "85297",
    "label": "Gilbert - AZ 85297",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Gilbert"
  },
  {
    "value": "85298",
    "label": "Gilbert - AZ 85298",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Gilbert"
  },
  {
    "value": "85301",
    "label": "Glendale - AZ 85301",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Glendale"
  },
  {
    "value": "85302",
    "label": "Glendale - AZ 85302",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Glendale"
  },
  {
    "value": "85303",
    "label": "Glendale - AZ 85303",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Glendale"
  },
  {
    "value": "85304",
    "label": "Glendale - AZ 85304",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Glendale"
  },
  {
    "value": "85305",
    "label": "Glendale - AZ 85305",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Glendale"
  },
  {
    "value": "85306",
    "label": "Glendale - AZ 85306",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Glendale"
  },
  {
    "value": "85307",
    "label": "Glendale - AZ 85307",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Glendale"
  },
  {
    "value": "85308",
    "label": "Glendale - AZ 85308",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Glendale"
  },
  {
    "value": "85310",
    "label": "Glendale - AZ 85310",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Glendale"
  },
  {
    "value": "85321",
    "label": "Ajo - AZ 85321",
    "county": "Pima County",
    "state": "AZ",
    "city": "Ajo"
  },
  {
    "value": "85322",
    "label": "Arlington - AZ 85322",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Arlington"
  },
  {
    "value": "85323",
    "label": "Avondale - AZ 85323",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Avondale"
  },
  {
    "value": "85324",
    "label": "Black Canyon City - AZ 85324",
    "county": "Yavapai County",
    "state": "AZ",
    "city": "Black Canyon City"
  },
  {
    "value": "85326",
    "label": "Buckeye - AZ 85326",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Buckeye"
  },
  {
    "value": "85328",
    "label": "Cibola - AZ 85328",
    "county": "La Paz County",
    "state": "AZ",
    "city": "Cibola"
  },
  {
    "value": "85331",
    "label": "Cave Creek - AZ 85331",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Cave Creek"
  },
  {
    "value": "85333",
    "label": "Dateland - AZ 85333",
    "county": "Yuma County",
    "state": "AZ",
    "city": "Dateland"
  },
  {
    "value": "85335",
    "label": "El Mirage - AZ 85335",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "El Mirage"
  },
  {
    "value": "85337",
    "label": "Gila Bend - AZ 85337",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Gila Bend"
  },
  {
    "value": "85338",
    "label": "Goodyear - AZ 85338",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Goodyear"
  },
  {
    "value": "85339",
    "label": "Laveen - AZ 85339",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Laveen"
  },
  {
    "value": "85340",
    "label": "Litchfield Park - AZ 85340",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Litchfield Park"
  },
  {
    "value": "85342",
    "label": "Morristown - AZ 85342",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Morristown"
  },
  {
    "value": "85344",
    "label": "Parker - AZ 85344",
    "county": "La Paz County",
    "state": "AZ",
    "city": "Parker"
  },
  {
    "value": "85345",
    "label": "Peoria - AZ 85345",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Peoria"
  },
  {
    "value": "85347",
    "label": "Roll - AZ 85347",
    "county": "Yuma County",
    "state": "AZ",
    "city": "Roll"
  },
  {
    "value": "85348",
    "label": "Salome - AZ 85348",
    "county": "La Paz County",
    "state": "AZ",
    "city": "Salome"
  },
  {
    "value": "85350",
    "label": "Somerton - AZ 85350",
    "county": "Yuma County",
    "state": "AZ",
    "city": "Somerton"
  },
  {
    "value": "85351",
    "label": "Sun City - AZ 85351",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Sun City"
  },
  {
    "value": "85353",
    "label": "Tolleson - AZ 85353",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Tolleson"
  },
  {
    "value": "85354",
    "label": "Tonopah - AZ 85354",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Tonopah"
  },
  {
    "value": "85355",
    "label": "Waddell - AZ 85355",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Waddell"
  },
  {
    "value": "85356",
    "label": "Wellton - AZ 85356",
    "county": "Yuma County",
    "state": "AZ",
    "city": "Wellton"
  },
  {
    "value": "85361",
    "label": "Wittmann - AZ 85361",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Wittmann"
  },
  {
    "value": "85363",
    "label": "Youngtown - AZ 85363",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Youngtown"
  },
  {
    "value": "85364",
    "label": "Yuma - AZ 85364",
    "county": "Yuma County",
    "state": "AZ",
    "city": "Yuma"
  },
  {
    "value": "85365",
    "label": "Yuma - AZ 85365",
    "county": "Yuma County",
    "state": "AZ",
    "city": "Yuma"
  },
  {
    "value": "85367",
    "label": "Yuma - AZ 85367",
    "county": "Yuma County",
    "state": "AZ",
    "city": "Yuma"
  },
  {
    "value": "85373",
    "label": "Sun City - AZ 85373",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Sun City"
  },
  {
    "value": "85374",
    "label": "Surprise - AZ 85374",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Surprise"
  },
  {
    "value": "85375",
    "label": "Sun City West - AZ 85375",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Sun City West"
  },
  {
    "value": "85378",
    "label": "Surprise - AZ 85378",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Surprise"
  },
  {
    "value": "85379",
    "label": "Surprise - AZ 85379",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Surprise"
  },
  {
    "value": "85381",
    "label": "Peoria - AZ 85381",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Peoria"
  },
  {
    "value": "85382",
    "label": "Peoria - AZ 85382",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Peoria"
  },
  {
    "value": "85383",
    "label": "Peoria - AZ 85383",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Peoria"
  },
  {
    "value": "85387",
    "label": "Surprise - AZ 85387",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Surprise"
  },
  {
    "value": "85388",
    "label": "Surprise - AZ 85388",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Surprise"
  },
  {
    "value": "85390",
    "label": "Wickenburg - AZ 85390",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Wickenburg"
  },
  {
    "value": "85392",
    "label": "Avondale - AZ 85392",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Avondale"
  },
  {
    "value": "85395",
    "label": "Goodyear - AZ 85395",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Goodyear"
  },
  {
    "value": "85396",
    "label": "Buckeye - AZ 85396",
    "county": "Maricopa County",
    "state": "AZ",
    "city": "Buckeye"
  },
  {
    "value": "85501",
    "label": "Globe - AZ 85501",
    "county": "Gila County",
    "state": "AZ",
    "city": "Globe"
  },
  {
    "value": "85533",
    "label": "Clifton - AZ 85533",
    "county": "Greenlee County",
    "state": "AZ",
    "city": "Clifton"
  },
  {
    "value": "85534",
    "label": "Duncan - AZ 85534",
    "county": "Greenlee County",
    "state": "AZ",
    "city": "Duncan"
  },
  {
    "value": "85535",
    "label": "Eden - AZ 85535",
    "county": "Graham County",
    "state": "AZ",
    "city": "Eden"
  },
  {
    "value": "85539",
    "label": "Miami - AZ 85539",
    "county": "Gila County",
    "state": "AZ",
    "city": "Miami"
  },
  {
    "value": "85540",
    "label": "Morenci - AZ 85540",
    "county": "Greenlee County",
    "state": "AZ",
    "city": "Morenci"
  },
  {
    "value": "85541",
    "label": "Payson - AZ 85541",
    "county": "Gila County",
    "state": "AZ",
    "city": "Payson"
  },
  {
    "value": "85542",
    "label": "Peridot - AZ 85542",
    "county": "Gila County",
    "state": "AZ",
    "city": "Peridot"
  },
  {
    "value": "85543",
    "label": "Pima - AZ 85543",
    "county": "Graham County",
    "state": "AZ",
    "city": "Pima"
  },
  {
    "value": "85544",
    "label": "Pine - AZ 85544",
    "county": "Gila County",
    "state": "AZ",
    "city": "Pine"
  },
  {
    "value": "85545",
    "label": "Roosevelt - AZ 85545",
    "county": "Gila County",
    "state": "AZ",
    "city": "Roosevelt"
  },
  {
    "value": "85546",
    "label": "Safford - AZ 85546",
    "county": "Graham County",
    "state": "AZ",
    "city": "Safford"
  },
  {
    "value": "85552",
    "label": "Thatcher - AZ 85552",
    "county": "Graham County",
    "state": "AZ",
    "city": "Thatcher"
  },
  {
    "value": "85602",
    "label": "Benson - AZ 85602",
    "county": "Cochise County",
    "state": "AZ",
    "city": "Benson"
  },
  {
    "value": "85603",
    "label": "Bisbee - AZ 85603",
    "county": "Cochise County",
    "state": "AZ",
    "city": "Bisbee"
  },
  {
    "value": "85606",
    "label": "Cochise - AZ 85606",
    "county": "Cochise County",
    "state": "AZ",
    "city": "Cochise"
  },
  {
    "value": "85607",
    "label": "Douglas - AZ 85607",
    "county": "Cochise County",
    "state": "AZ",
    "city": "Douglas"
  },
  {
    "value": "85610",
    "label": "Elfrida - AZ 85610",
    "county": "Cochise County",
    "state": "AZ",
    "city": "Elfrida"
  },
  {
    "value": "85611",
    "label": "Elgin - AZ 85611",
    "county": "Santa Cruz County",
    "state": "AZ",
    "city": "Elgin"
  },
  {
    "value": "85613",
    "label": "Fort Huachuca - AZ 85613",
    "county": "Cochise County",
    "state": "AZ",
    "city": "Fort Huachuca"
  },
  {
    "value": "85614",
    "label": "Green Valley - AZ 85614",
    "county": "Pima County",
    "state": "AZ",
    "city": "Green Valley"
  },
  {
    "value": "85615",
    "label": "Hereford - AZ 85615",
    "county": "Cochise County",
    "state": "AZ",
    "city": "Hereford"
  },
  {
    "value": "85616",
    "label": "Huachuca City - AZ 85616",
    "county": "Cochise County",
    "state": "AZ",
    "city": "Huachuca City"
  },
  {
    "value": "85617",
    "label": "Mc Neal - AZ 85617",
    "county": "Cochise County",
    "state": "AZ",
    "city": "Mc Neal"
  },
  {
    "value": "85621",
    "label": "Nogales - AZ 85621",
    "county": "Santa Cruz County",
    "state": "AZ",
    "city": "Nogales"
  },
  {
    "value": "85622",
    "label": "Green Valley - AZ 85622",
    "county": "Pima County",
    "state": "AZ",
    "city": "Green Valley"
  },
  {
    "value": "85623",
    "label": "Oracle - AZ 85623",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Oracle"
  },
  {
    "value": "85624",
    "label": "Patagonia - AZ 85624",
    "county": "Santa Cruz County",
    "state": "AZ",
    "city": "Patagonia"
  },
  {
    "value": "85625",
    "label": "Pearce - AZ 85625",
    "county": "Cochise County",
    "state": "AZ",
    "city": "Pearce"
  },
  {
    "value": "85629",
    "label": "Sahuarita - AZ 85629",
    "county": "Pima County",
    "state": "AZ",
    "city": "Sahuarita"
  },
  {
    "value": "85630",
    "label": "Saint David - AZ 85630",
    "county": "Cochise County",
    "state": "AZ",
    "city": "Saint David"
  },
  {
    "value": "85631",
    "label": "San Manuel - AZ 85631",
    "county": "Pinal County",
    "state": "AZ",
    "city": "San Manuel"
  },
  {
    "value": "85632",
    "label": "San Simon - AZ 85632",
    "county": "Cochise County",
    "state": "AZ",
    "city": "San Simon"
  },
  {
    "value": "85634",
    "label": "Sells - AZ 85634",
    "county": "Pima County",
    "state": "AZ",
    "city": "Sells"
  },
  {
    "value": "85635",
    "label": "Sierra Vista - AZ 85635",
    "county": "Cochise County",
    "state": "AZ",
    "city": "Sierra Vista"
  },
  {
    "value": "85637",
    "label": "Sonoita - AZ 85637",
    "county": "Santa Cruz County",
    "state": "AZ",
    "city": "Sonoita"
  },
  {
    "value": "85638",
    "label": "Tombstone - AZ 85638",
    "county": "Cochise County",
    "state": "AZ",
    "city": "Tombstone"
  },
  {
    "value": "85640",
    "label": "Tumacacori - AZ 85640",
    "county": "Santa Cruz County",
    "state": "AZ",
    "city": "Tumacacori"
  },
  {
    "value": "85641",
    "label": "Vail - AZ 85641",
    "county": "Pima County",
    "state": "AZ",
    "city": "Vail"
  },
  {
    "value": "85643",
    "label": "Willcox - AZ 85643",
    "county": "Cochise County",
    "state": "AZ",
    "city": "Willcox"
  },
  {
    "value": "85645",
    "label": "Amado - AZ 85645",
    "county": "Pima County",
    "state": "AZ",
    "city": "Amado"
  },
  {
    "value": "85648",
    "label": "Rio Rico - AZ 85648",
    "county": "Santa Cruz County",
    "state": "AZ",
    "city": "Rio Rico"
  },
  {
    "value": "85650",
    "label": "Sierra Vista - AZ 85650",
    "county": "Cochise County",
    "state": "AZ",
    "city": "Sierra Vista"
  },
  {
    "value": "85653",
    "label": "Marana - AZ 85653",
    "county": "Pima County",
    "state": "AZ",
    "city": "Marana"
  },
  {
    "value": "85658",
    "label": "Marana - AZ 85658",
    "county": "Pima County",
    "state": "AZ",
    "city": "Marana"
  },
  {
    "value": "85701",
    "label": "Tucson - AZ 85701",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85704",
    "label": "Tucson - AZ 85704",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85705",
    "label": "Tucson - AZ 85705",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85706",
    "label": "Tucson - AZ 85706",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85708",
    "label": "Tucson - AZ 85708",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85710",
    "label": "Tucson - AZ 85710",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85711",
    "label": "Tucson - AZ 85711",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85712",
    "label": "Tucson - AZ 85712",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85713",
    "label": "Tucson - AZ 85713",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85714",
    "label": "Tucson - AZ 85714",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85715",
    "label": "Tucson - AZ 85715",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85716",
    "label": "Tucson - AZ 85716",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85718",
    "label": "Tucson - AZ 85718",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85719",
    "label": "Tucson - AZ 85719",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85730",
    "label": "Tucson - AZ 85730",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85735",
    "label": "Tucson - AZ 85735",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85736",
    "label": "Tucson - AZ 85736",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85737",
    "label": "Tucson - AZ 85737",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85739",
    "label": "Tucson - AZ 85739",
    "county": "Pinal County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85741",
    "label": "Tucson - AZ 85741",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85742",
    "label": "Tucson - AZ 85742",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85743",
    "label": "Tucson - AZ 85743",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85745",
    "label": "Tucson - AZ 85745",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85746",
    "label": "Tucson - AZ 85746",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85747",
    "label": "Tucson - AZ 85747",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85748",
    "label": "Tucson - AZ 85748",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85749",
    "label": "Tucson - AZ 85749",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85750",
    "label": "Tucson - AZ 85750",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85755",
    "label": "Tucson - AZ 85755",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85756",
    "label": "Tucson - AZ 85756",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85757",
    "label": "Tucson - AZ 85757",
    "county": "Pima County",
    "state": "AZ",
    "city": "Tucson"
  },
  {
    "value": "85901",
    "label": "Show Low - AZ 85901",
    "county": "Navajo County",
    "state": "AZ",
    "city": "Show Low"
  },
  {
    "value": "85920",
    "label": "Alpine - AZ 85920",
    "county": "Apache County",
    "state": "AZ",
    "city": "Alpine"
  },
  {
    "value": "85922",
    "label": "Blue - AZ 85922",
    "county": "Greenlee County",
    "state": "AZ",
    "city": "Blue"
  },
  {
    "value": "85924",
    "label": "Concho - AZ 85924",
    "county": "Apache County",
    "state": "AZ",
    "city": "Concho"
  },
  {
    "value": "85925",
    "label": "Eagar - AZ 85925",
    "county": "Apache County",
    "state": "AZ",
    "city": "Eagar"
  },
  {
    "value": "85927",
    "label": "Greer - AZ 85927",
    "county": "Apache County",
    "state": "AZ",
    "city": "Greer"
  },
  {
    "value": "85928",
    "label": "Heber - AZ 85928",
    "county": "Navajo County",
    "state": "AZ",
    "city": "Heber"
  },
  {
    "value": "85929",
    "label": "Lakeside - AZ 85929",
    "county": "Navajo County",
    "state": "AZ",
    "city": "Lakeside"
  },
  {
    "value": "85935",
    "label": "Pinetop - AZ 85935",
    "county": "Navajo County",
    "state": "AZ",
    "city": "Pinetop"
  },
  {
    "value": "85936",
    "label": "Saint Johns - AZ 85936",
    "county": "Apache County",
    "state": "AZ",
    "city": "Saint Johns"
  },
  {
    "value": "85937",
    "label": "Snowflake - AZ 85937",
    "county": "Navajo County",
    "state": "AZ",
    "city": "Snowflake"
  },
  {
    "value": "85938",
    "label": "Springerville - AZ 85938",
    "county": "Apache County",
    "state": "AZ",
    "city": "Springerville"
  },
  {
    "value": "86001",
    "label": "Flagstaff - AZ 86001",
    "county": "Coconino County",
    "state": "AZ",
    "city": "Flagstaff"
  },
  {
    "value": "86004",
    "label": "Flagstaff - AZ 86004",
    "county": "Coconino County",
    "state": "AZ",
    "city": "Flagstaff"
  },
  {
    "value": "86005",
    "label": "Flagstaff - AZ 86005",
    "county": "Coconino County",
    "state": "AZ",
    "city": "Flagstaff"
  },
  {
    "value": "86015",
    "label": "Bellemont - AZ 86015",
    "county": "Coconino County",
    "state": "AZ",
    "city": "Bellemont"
  },
  {
    "value": "86022",
    "label": "Fredonia - AZ 86022",
    "county": "Coconino County",
    "state": "AZ",
    "city": "Fredonia"
  },
  {
    "value": "86024",
    "label": "Happy Jack - AZ 86024",
    "county": "Coconino County",
    "state": "AZ",
    "city": "Happy Jack"
  },
  {
    "value": "86025",
    "label": "Holbrook - AZ 86025",
    "county": "Navajo County",
    "state": "AZ",
    "city": "Holbrook"
  },
  {
    "value": "86036",
    "label": "Marble Canyon - AZ 86036",
    "county": "Coconino County",
    "state": "AZ",
    "city": "Marble Canyon"
  },
  {
    "value": "86038",
    "label": "Mormon Lake - AZ 86038",
    "county": "Coconino County",
    "state": "AZ",
    "city": "Mormon Lake"
  },
  {
    "value": "86044",
    "label": "Tonalea - AZ 86044",
    "county": "San Juan County",
    "state": "AZ",
    "city": "Tonalea"
  },
  {
    "value": "86046",
    "label": "Williams - AZ 86046",
    "county": "Coconino County",
    "state": "AZ",
    "city": "Williams"
  },
  {
    "value": "86047",
    "label": "Winslow - AZ 86047",
    "county": "Navajo County",
    "state": "AZ",
    "city": "Winslow"
  },
  {
    "value": "86301",
    "label": "Prescott - AZ 86301",
    "county": "Yavapai County",
    "state": "AZ",
    "city": "Prescott"
  },
  {
    "value": "86303",
    "label": "Prescott - AZ 86303",
    "county": "Yavapai County",
    "state": "AZ",
    "city": "Prescott"
  },
  {
    "value": "86305",
    "label": "Prescott - AZ 86305",
    "county": "Yavapai County",
    "state": "AZ",
    "city": "Prescott"
  },
  {
    "value": "86314",
    "label": "Prescott Valley - AZ 86314",
    "county": "Yavapai County",
    "state": "AZ",
    "city": "Prescott Valley"
  },
  {
    "value": "86315",
    "label": "Prescott Valley - AZ 86315",
    "county": "Yavapai County",
    "state": "AZ",
    "city": "Prescott Valley"
  },
  {
    "value": "86321",
    "label": "Bagdad - AZ 86321",
    "county": "Yavapai County",
    "state": "AZ",
    "city": "Bagdad"
  },
  {
    "value": "86322",
    "label": "Camp Verde - AZ 86322",
    "county": "Yavapai County",
    "state": "AZ",
    "city": "Camp Verde"
  },
  {
    "value": "86323",
    "label": "Chino Valley - AZ 86323",
    "county": "Yavapai County",
    "state": "AZ",
    "city": "Chino Valley"
  },
  {
    "value": "86324",
    "label": "Clarkdale - AZ 86324",
    "county": "Yavapai County",
    "state": "AZ",
    "city": "Clarkdale"
  },
  {
    "value": "86325",
    "label": "Cornville - AZ 86325",
    "county": "Yavapai County",
    "state": "AZ",
    "city": "Cornville"
  },
  {
    "value": "86326",
    "label": "Cottonwood - AZ 86326",
    "county": "Yavapai County",
    "state": "AZ",
    "city": "Cottonwood"
  },
  {
    "value": "86327",
    "label": "Dewey - AZ 86327",
    "county": "Yavapai County",
    "state": "AZ",
    "city": "Dewey"
  },
  {
    "value": "86330",
    "label": "Prescott - AZ 86330",
    "county": "Yavapai County",
    "state": "AZ",
    "city": "Prescott"
  },
  {
    "value": "86332",
    "label": "Kirkland - AZ 86332",
    "county": "Yavapai County",
    "state": "AZ",
    "city": "Kirkland"
  },
  {
    "value": "86333",
    "label": "Mayer - AZ 86333",
    "county": "Yavapai County",
    "state": "AZ",
    "city": "Mayer"
  },
  {
    "value": "86334",
    "label": "Paulden - AZ 86334",
    "county": "Yavapai County",
    "state": "AZ",
    "city": "Paulden"
  },
  {
    "value": "86335",
    "label": "Rimrock - AZ 86335",
    "county": "Yavapai County",
    "state": "AZ",
    "city": "Rimrock"
  },
  {
    "value": "86336",
    "label": "Sedona - AZ 86336",
    "county": "Yavapai County",
    "state": "AZ",
    "city": "Sedona"
  },
  {
    "value": "86351",
    "label": "Sedona - AZ 86351",
    "county": "Yavapai County",
    "state": "AZ",
    "city": "Sedona"
  },
  {
    "value": "86401",
    "label": "Kingman - AZ 86401",
    "county": "Mohave County",
    "state": "AZ",
    "city": "Kingman"
  },
  {
    "value": "86403",
    "label": "Lake Havasu City - AZ 86403",
    "county": "Mohave County",
    "state": "AZ",
    "city": "Lake Havasu City"
  },
  {
    "value": "86404",
    "label": "Lake Havasu City - AZ 86404",
    "county": "Mohave County",
    "state": "AZ",
    "city": "Lake Havasu City"
  },
  {
    "value": "86406",
    "label": "Lake Havasu City - AZ 86406",
    "county": "Mohave County",
    "state": "AZ",
    "city": "Lake Havasu City"
  },
  {
    "value": "86409",
    "label": "Kingman - AZ 86409",
    "county": "Mohave County",
    "state": "AZ",
    "city": "Kingman"
  },
  {
    "value": "86411",
    "label": "Hackberry - AZ 86411",
    "county": "Mohave County",
    "state": "AZ",
    "city": "Hackberry"
  },
  {
    "value": "86413",
    "label": "Golden Valley - AZ 86413",
    "county": "Mohave County",
    "state": "AZ",
    "city": "Golden Valley"
  },
  {
    "value": "86426",
    "label": "Fort Mohave - AZ 86426",
    "county": "Mohave County",
    "state": "AZ",
    "city": "Fort Mohave"
  },
  {
    "value": "86429",
    "label": "Bullhead City - AZ 86429",
    "county": "Mohave County",
    "state": "AZ",
    "city": "Bullhead City"
  },
  {
    "value": "86434",
    "label": "Peach Springs - AZ 86434",
    "county": "Mohave County",
    "state": "AZ",
    "city": "Peach Springs"
  },
  {
    "value": "86436",
    "label": "Topock - AZ 86436",
    "county": "Mohave County",
    "state": "AZ",
    "city": "Topock"
  },
  {
    "value": "86437",
    "label": "Valentine - AZ 86437",
    "county": "Mohave County",
    "state": "AZ",
    "city": "Valentine"
  },
  {
    "value": "86440",
    "label": "Mohave Valley - AZ 86440",
    "county": "Mohave County",
    "state": "AZ",
    "city": "Mohave Valley"
  },
  {
    "value": "86441",
    "label": "Dolan Springs - AZ 86441",
    "county": "Mohave County",
    "state": "AZ",
    "city": "Dolan Springs"
  },
  {
    "value": "86442",
    "label": "Bullhead City - AZ 86442",
    "county": "Mohave County",
    "state": "AZ",
    "city": "Bullhead City"
  },
  {
    "value": "86443",
    "label": "Temple Bar Marina - AZ 86443",
    "county": "Mohave County",
    "state": "AZ",
    "city": "Temple Bar Marina"
  },
  {
    "value": "86444",
    "label": "Meadview - AZ 86444",
    "county": "Mohave County",
    "state": "AZ",
    "city": "Meadview"
  },
  {
    "value": "86445",
    "label": "Willow Beach - AZ 86445",
    "county": "Mohave County",
    "state": "AZ",
    "city": "Willow Beach"
  },
  {
    "value": "86503",
    "label": "Chinle - AZ 86503",
    "county": "Apache County",
    "state": "AZ",
    "city": "Chinle"
  },
  {
    "value": "86505",
    "label": "Ganado - AZ 86505",
    "county": "Apache County",
    "state": "AZ",
    "city": "Ganado"
  },
  {
    "value": "86514",
    "label": "Teec Nos Pos - AZ 86514",
    "county": "Apache County",
    "state": "AZ",
    "city": "Teec Nos Pos"
  },
  {
    "value": "86547",
    "label": "Round Rock - AZ 86547",
    "county": "Apache County",
    "state": "AZ",
    "city": "Round Rock"
  },
  {
    "value": "86555",
    "label": "Douglas - AZ 86555",
    "county": "Cochise County",
    "state": "AZ",
    "city": "Douglas"
  },
  {
    "value": "71601",
    "label": "Pine Bluff - AR 71601",
    "county": "Jefferson County",
    "state": "AR",
    "city": "Pine Bluff"
  },
  {
    "value": "71602",
    "label": "White Hall - AR 71602",
    "county": "Jefferson County",
    "state": "AR",
    "city": "White Hall"
  },
  {
    "value": "71603",
    "label": "Pine Bluff - AR 71603",
    "county": "Jefferson County",
    "state": "AR",
    "city": "Pine Bluff"
  },
  {
    "value": "71631",
    "label": "Banks - AR 71631",
    "county": "Bradley County",
    "state": "AR",
    "city": "Banks"
  },
  {
    "value": "71635",
    "label": "Crossett - AR 71635",
    "county": "Ashley County",
    "state": "AR",
    "city": "Crossett"
  },
  {
    "value": "71638",
    "label": "Dermott - AR 71638",
    "county": "Chicot County",
    "state": "AR",
    "city": "Dermott"
  },
  {
    "value": "71639",
    "label": "Dumas - AR 71639",
    "county": "Desha County",
    "state": "AR",
    "city": "Dumas"
  },
  {
    "value": "71640",
    "label": "Eudora - AR 71640",
    "county": "Chicot County",
    "state": "AR",
    "city": "Eudora"
  },
  {
    "value": "71642",
    "label": "Fountain Hill - AR 71642",
    "county": "Ashley County",
    "state": "AR",
    "city": "Fountain Hill"
  },
  {
    "value": "71643",
    "label": "Gould - AR 71643",
    "county": "Lincoln County",
    "state": "AR",
    "city": "Gould"
  },
  {
    "value": "71644",
    "label": "Grady - AR 71644",
    "county": "Lincoln County",
    "state": "AR",
    "city": "Grady"
  },
  {
    "value": "71646",
    "label": "Hamburg - AR 71646",
    "county": "Ashley County",
    "state": "AR",
    "city": "Hamburg"
  },
  {
    "value": "71647",
    "label": "Hermitage - AR 71647",
    "county": "Bradley County",
    "state": "AR",
    "city": "Hermitage"
  },
  {
    "value": "71651",
    "label": "Jersey - AR 71651",
    "county": "Bradley County",
    "state": "AR",
    "city": "Jersey"
  },
  {
    "value": "71652",
    "label": "Kingsland - AR 71652",
    "county": "Cleveland County",
    "state": "AR",
    "city": "Kingsland"
  },
  {
    "value": "71653",
    "label": "Lake Village - AR 71653",
    "county": "Chicot County",
    "state": "AR",
    "city": "Lake Village"
  },
  {
    "value": "71654",
    "label": "Mc Gehee - AR 71654",
    "county": "Desha County",
    "state": "AR",
    "city": "Mc Gehee"
  },
  {
    "value": "71655",
    "label": "Monticello - AR 71655",
    "county": "Drew County",
    "state": "AR",
    "city": "Monticello"
  },
  {
    "value": "71658",
    "label": "Montrose - AR 71658",
    "county": "Ashley County",
    "state": "AR",
    "city": "Montrose"
  },
  {
    "value": "71660",
    "label": "New Edinburg - AR 71660",
    "county": "Cleveland County",
    "state": "AR",
    "city": "New Edinburg"
  },
  {
    "value": "71661",
    "label": "Parkdale - AR 71661",
    "county": "Ashley County",
    "state": "AR",
    "city": "Parkdale"
  },
  {
    "value": "71662",
    "label": "Pickens - AR 71662",
    "county": "Desha County",
    "state": "AR",
    "city": "Pickens"
  },
  {
    "value": "71663",
    "label": "Portland - AR 71663",
    "county": "Ashley County",
    "state": "AR",
    "city": "Portland"
  },
  {
    "value": "71665",
    "label": "Rison - AR 71665",
    "county": "Cleveland County",
    "state": "AR",
    "city": "Rison"
  },
  {
    "value": "71667",
    "label": "Star City - AR 71667",
    "county": "Lincoln County",
    "state": "AR",
    "city": "Star City"
  },
  {
    "value": "71670",
    "label": "Tillar - AR 71670",
    "county": "Desha County",
    "state": "AR",
    "city": "Tillar"
  },
  {
    "value": "71671",
    "label": "Warren - AR 71671",
    "county": "Bradley County",
    "state": "AR",
    "city": "Warren"
  },
  {
    "value": "71674",
    "label": "Watson - AR 71674",
    "county": "Desha County",
    "state": "AR",
    "city": "Watson"
  },
  {
    "value": "71675",
    "label": "Wilmar - AR 71675",
    "county": "Drew County",
    "state": "AR",
    "city": "Wilmar"
  },
  {
    "value": "71676",
    "label": "Wilmot - AR 71676",
    "county": "Ashley County",
    "state": "AR",
    "city": "Wilmot"
  },
  {
    "value": "71701",
    "label": "Camden - AR 71701",
    "county": "Ouachita County",
    "state": "AR",
    "city": "Camden"
  },
  {
    "value": "71720",
    "label": "Bearden - AR 71720",
    "county": "Ouachita County",
    "state": "AR",
    "city": "Bearden"
  },
  {
    "value": "71722",
    "label": "Bluff City - AR 71722",
    "county": "Nevada County",
    "state": "AR",
    "city": "Bluff City"
  },
  {
    "value": "71725",
    "label": "Carthage - AR 71725",
    "county": "Dallas County",
    "state": "AR",
    "city": "Carthage"
  },
  {
    "value": "71726",
    "label": "Chidester - AR 71726",
    "county": "Ouachita County",
    "state": "AR",
    "city": "Chidester"
  },
  {
    "value": "71730",
    "label": "El Dorado - AR 71730",
    "county": "Union County",
    "state": "AR",
    "city": "El Dorado"
  },
  {
    "value": "71740",
    "label": "Emerson - AR 71740",
    "county": "Columbia County",
    "state": "AR",
    "city": "Emerson"
  },
  {
    "value": "71742",
    "label": "Fordyce - AR 71742",
    "county": "Dallas County",
    "state": "AR",
    "city": "Fordyce"
  },
  {
    "value": "71743",
    "label": "Gurdon - AR 71743",
    "county": "Clark County",
    "state": "AR",
    "city": "Gurdon"
  },
  {
    "value": "71744",
    "label": "Hampton - AR 71744",
    "county": "Calhoun County",
    "state": "AR",
    "city": "Hampton"
  },
  {
    "value": "71747",
    "label": "Huttig - AR 71747",
    "county": "Union County",
    "state": "AR",
    "city": "Huttig"
  },
  {
    "value": "71749",
    "label": "Junction City - AR 71749",
    "county": "Union County",
    "state": "AR",
    "city": "Junction City"
  },
  {
    "value": "71751",
    "label": "Louann - AR 71751",
    "county": "Ouachita County",
    "state": "AR",
    "city": "Louann"
  },
  {
    "value": "71752",
    "label": "Mc Neil - AR 71752",
    "county": "Columbia County",
    "state": "AR",
    "city": "Mc Neil"
  },
  {
    "value": "71753",
    "label": "Magnolia - AR 71753",
    "county": "Columbia County",
    "state": "AR",
    "city": "Magnolia"
  },
  {
    "value": "71758",
    "label": "Mount Holly - AR 71758",
    "county": "Union County",
    "state": "AR",
    "city": "Mount Holly"
  },
  {
    "value": "71762",
    "label": "Smackover - AR 71762",
    "county": "Union County",
    "state": "AR",
    "city": "Smackover"
  },
  {
    "value": "71763",
    "label": "Sparkman - AR 71763",
    "county": "Dallas County",
    "state": "AR",
    "city": "Sparkman"
  },
  {
    "value": "71764",
    "label": "Stephens - AR 71764",
    "county": "Ouachita County",
    "state": "AR",
    "city": "Stephens"
  },
  {
    "value": "71765",
    "label": "Strong - AR 71765",
    "county": "Union County",
    "state": "AR",
    "city": "Strong"
  },
  {
    "value": "71766",
    "label": "Thornton - AR 71766",
    "county": "Calhoun County",
    "state": "AR",
    "city": "Thornton"
  },
  {
    "value": "71768",
    "label": "El Dorado - AR 71768",
    "county": "Union County",
    "state": "AR",
    "city": "El Dorado"
  },
  {
    "value": "71770",
    "label": "Waldo - AR 71770",
    "county": "Columbia County",
    "state": "AR",
    "city": "Waldo"
  },
  {
    "value": "71801",
    "label": "Hope - AR 71801",
    "county": "Hempstead County",
    "state": "AR",
    "city": "Hope"
  },
  {
    "value": "71822",
    "label": "Ashdown - AR 71822",
    "county": "Little River County",
    "state": "AR",
    "city": "Ashdown"
  },
  {
    "value": "71825",
    "label": "Blevins - AR 71825",
    "county": "Hempstead County",
    "state": "AR",
    "city": "Blevins"
  },
  {
    "value": "71826",
    "label": "Bradley - AR 71826",
    "county": "Lafayette County",
    "state": "AR",
    "city": "Bradley"
  },
  {
    "value": "71827",
    "label": "Buckner - AR 71827",
    "county": "Lafayette County",
    "state": "AR",
    "city": "Buckner"
  },
  {
    "value": "71831",
    "label": "Columbus - AR 71831",
    "county": "Hempstead County",
    "state": "AR",
    "city": "Columbus"
  },
  {
    "value": "71832",
    "label": "De Queen - AR 71832",
    "county": "Sevier County",
    "state": "AR",
    "city": "De Queen"
  },
  {
    "value": "71833",
    "label": "Dierks - AR 71833",
    "county": "Howard County",
    "state": "AR",
    "city": "Dierks"
  },
  {
    "value": "71834",
    "label": "Doddridge - AR 71834",
    "county": "Miller County",
    "state": "AR",
    "city": "Doddridge"
  },
  {
    "value": "71835",
    "label": "Emmet - AR 71835",
    "county": "Nevada County",
    "state": "AR",
    "city": "Emmet"
  },
  {
    "value": "71836",
    "label": "Foreman - AR 71836",
    "county": "Little River County",
    "state": "AR",
    "city": "Foreman"
  },
  {
    "value": "71837",
    "label": "Fouke - AR 71837",
    "county": "Miller County",
    "state": "AR",
    "city": "Fouke"
  },
  {
    "value": "71838",
    "label": "Fulton - AR 71838",
    "county": "Hempstead County",
    "state": "AR",
    "city": "Fulton"
  },
  {
    "value": "71839",
    "label": "Garland City - AR 71839",
    "county": "Miller County",
    "state": "AR",
    "city": "Garland City"
  },
  {
    "value": "71841",
    "label": "Gillham - AR 71841",
    "county": "Sevier County",
    "state": "AR",
    "city": "Gillham"
  },
  {
    "value": "71842",
    "label": "Horatio - AR 71842",
    "county": "Sevier County",
    "state": "AR",
    "city": "Horatio"
  },
  {
    "value": "71845",
    "label": "Lewisville - AR 71845",
    "county": "Lafayette County",
    "state": "AR",
    "city": "Lewisville"
  },
  {
    "value": "71846",
    "label": "Lockesburg - AR 71846",
    "county": "Sevier County",
    "state": "AR",
    "city": "Lockesburg"
  },
  {
    "value": "71847",
    "label": "Mc Caskill - AR 71847",
    "county": "Hempstead County",
    "state": "AR",
    "city": "Mc Caskill"
  },
  {
    "value": "71851",
    "label": "Mineral Springs - AR 71851",
    "county": "Howard County",
    "state": "AR",
    "city": "Mineral Springs"
  },
  {
    "value": "71852",
    "label": "Nashville - AR 71852",
    "county": "Howard County",
    "state": "AR",
    "city": "Nashville"
  },
  {
    "value": "71853",
    "label": "Ogden - AR 71853",
    "county": "Little River County",
    "state": "AR",
    "city": "Ogden"
  },
  {
    "value": "71854",
    "label": "Texarkana - AR 71854",
    "county": "Miller County",
    "state": "AR",
    "city": "Texarkana"
  },
  {
    "value": "71855",
    "label": "Ozan - AR 71855",
    "county": "Hempstead County",
    "state": "AR",
    "city": "Ozan"
  },
  {
    "value": "71857",
    "label": "Prescott - AR 71857",
    "county": "Nevada County",
    "state": "AR",
    "city": "Prescott"
  },
  {
    "value": "71858",
    "label": "Rosston - AR 71858",
    "county": "Nevada County",
    "state": "AR",
    "city": "Rosston"
  },
  {
    "value": "71859",
    "label": "Saratoga - AR 71859",
    "county": "Hempstead County",
    "state": "AR",
    "city": "Saratoga"
  },
  {
    "value": "71860",
    "label": "Stamps - AR 71860",
    "county": "Lafayette County",
    "state": "AR",
    "city": "Stamps"
  },
  {
    "value": "71861",
    "label": "Taylor - AR 71861",
    "county": "Columbia County",
    "state": "AR",
    "city": "Taylor"
  },
  {
    "value": "71862",
    "label": "Washington - AR 71862",
    "county": "Hempstead County",
    "state": "AR",
    "city": "Washington"
  },
  {
    "value": "71866",
    "label": "Winthrop - AR 71866",
    "county": "Little River County",
    "state": "AR",
    "city": "Winthrop"
  },
  {
    "value": "71901",
    "label": "Hot Springs National Park - AR 71901",
    "county": "Garland County",
    "state": "AR",
    "city": "Hot Springs National Park"
  },
  {
    "value": "71909",
    "label": "Hot Springs Village - AR 71909",
    "county": "Garland County",
    "state": "AR",
    "city": "Hot Springs Village"
  },
  {
    "value": "71913",
    "label": "Hot Springs National Park - AR 71913",
    "county": "Garland County",
    "state": "AR",
    "city": "Hot Springs National Park"
  },
  {
    "value": "71920",
    "label": "Alpine - AR 71920",
    "county": "Clark County",
    "state": "AR",
    "city": "Alpine"
  },
  {
    "value": "71921",
    "label": "Amity - AR 71921",
    "county": "Clark County",
    "state": "AR",
    "city": "Amity"
  },
  {
    "value": "71923",
    "label": "Arkadelphia - AR 71923",
    "county": "Clark County",
    "state": "AR",
    "city": "Arkadelphia"
  },
  {
    "value": "71929",
    "label": "Bismarck - AR 71929",
    "county": "Hot Spring County",
    "state": "AR",
    "city": "Bismarck"
  },
  {
    "value": "71933",
    "label": "Bonnerdale - AR 71933",
    "county": "Garland County",
    "state": "AR",
    "city": "Bonnerdale"
  },
  {
    "value": "71935",
    "label": "Caddo Gap - AR 71935",
    "county": "Montgomery County",
    "state": "AR",
    "city": "Caddo Gap"
  },
  {
    "value": "71937",
    "label": "Cove - AR 71937",
    "county": "Polk County",
    "state": "AR",
    "city": "Cove"
  },
  {
    "value": "71940",
    "label": "Delight - AR 71940",
    "county": "Pike County",
    "state": "AR",
    "city": "Delight"
  },
  {
    "value": "71941",
    "label": "Donaldson - AR 71941",
    "county": "Hot Spring County",
    "state": "AR",
    "city": "Donaldson"
  },
  {
    "value": "71943",
    "label": "Glenwood - AR 71943",
    "county": "Pike County",
    "state": "AR",
    "city": "Glenwood"
  },
  {
    "value": "71944",
    "label": "Grannis - AR 71944",
    "county": "Polk County",
    "state": "AR",
    "city": "Grannis"
  },
  {
    "value": "71945",
    "label": "Hatfield - AR 71945",
    "county": "Polk County",
    "state": "AR",
    "city": "Hatfield"
  },
  {
    "value": "71949",
    "label": "Jessieville - AR 71949",
    "county": "Garland County",
    "state": "AR",
    "city": "Jessieville"
  },
  {
    "value": "71950",
    "label": "Kirby - AR 71950",
    "county": "Pike County",
    "state": "AR",
    "city": "Kirby"
  },
  {
    "value": "71951",
    "label": "Hot Springs National Park - AR 71951",
    "county": "Garland County",
    "state": "AR",
    "city": "Hot Springs National Park"
  },
  {
    "value": "71952",
    "label": "Langley - AR 71952",
    "county": "Pike County",
    "state": "AR",
    "city": "Langley"
  },
  {
    "value": "71953",
    "label": "Mena - AR 71953",
    "county": "Polk County",
    "state": "AR",
    "city": "Mena"
  },
  {
    "value": "71956",
    "label": "Mountain Pine - AR 71956",
    "county": "Garland County",
    "state": "AR",
    "city": "Mountain Pine"
  },
  {
    "value": "71957",
    "label": "Mount Ida - AR 71957",
    "county": "Montgomery County",
    "state": "AR",
    "city": "Mount Ida"
  },
  {
    "value": "71958",
    "label": "Murfreesboro - AR 71958",
    "county": "Pike County",
    "state": "AR",
    "city": "Murfreesboro"
  },
  {
    "value": "71959",
    "label": "Newhope - AR 71959",
    "county": "Pike County",
    "state": "AR",
    "city": "Newhope"
  },
  {
    "value": "71960",
    "label": "Norman - AR 71960",
    "county": "Montgomery County",
    "state": "AR",
    "city": "Norman"
  },
  {
    "value": "71961",
    "label": "Oden - AR 71961",
    "county": "Montgomery County",
    "state": "AR",
    "city": "Oden"
  },
  {
    "value": "71962",
    "label": "Okolona - AR 71962",
    "county": "Clark County",
    "state": "AR",
    "city": "Okolona"
  },
  {
    "value": "71964",
    "label": "Pearcy - AR 71964",
    "county": "Garland County",
    "state": "AR",
    "city": "Pearcy"
  },
  {
    "value": "71965",
    "label": "Pencil Bluff - AR 71965",
    "county": "Montgomery County",
    "state": "AR",
    "city": "Pencil Bluff"
  },
  {
    "value": "71968",
    "label": "Royal - AR 71968",
    "county": "Garland County",
    "state": "AR",
    "city": "Royal"
  },
  {
    "value": "71969",
    "label": "Sims - AR 71969",
    "county": "Montgomery County",
    "state": "AR",
    "city": "Sims"
  },
  {
    "value": "71970",
    "label": "Story - AR 71970",
    "county": "Montgomery County",
    "state": "AR",
    "city": "Story"
  },
  {
    "value": "71971",
    "label": "Umpire - AR 71971",
    "county": "Howard County",
    "state": "AR",
    "city": "Umpire"
  },
  {
    "value": "71972",
    "label": "Vandervoort - AR 71972",
    "county": "Polk County",
    "state": "AR",
    "city": "Vandervoort"
  },
  {
    "value": "71973",
    "label": "Wickes - AR 71973",
    "county": "Polk County",
    "state": "AR",
    "city": "Wickes"
  },
  {
    "value": "72001",
    "label": "Adona - AR 72001",
    "county": "Perry County",
    "state": "AR",
    "city": "Adona"
  },
  {
    "value": "72002",
    "label": "Alexander - AR 72002",
    "county": "Saline County",
    "state": "AR",
    "city": "Alexander"
  },
  {
    "value": "72003",
    "label": "Almyra - AR 72003",
    "county": "Arkansas County",
    "state": "AR",
    "city": "Almyra"
  },
  {
    "value": "72004",
    "label": "Altheimer - AR 72004",
    "county": "Jefferson County",
    "state": "AR",
    "city": "Altheimer"
  },
  {
    "value": "72005",
    "label": "Amagon - AR 72005",
    "county": "Jackson County",
    "state": "AR",
    "city": "Amagon"
  },
  {
    "value": "72006",
    "label": "Augusta - AR 72006",
    "county": "Woodruff County",
    "state": "AR",
    "city": "Augusta"
  },
  {
    "value": "72007",
    "label": "Austin - AR 72007",
    "county": "Lonoke County",
    "state": "AR",
    "city": "Austin"
  },
  {
    "value": "72010",
    "label": "Bald Knob - AR 72010",
    "county": "White County",
    "state": "AR",
    "city": "Bald Knob"
  },
  {
    "value": "72011",
    "label": "Bauxite - AR 72011",
    "county": "Saline County",
    "state": "AR",
    "city": "Bauxite"
  },
  {
    "value": "72012",
    "label": "Beebe - AR 72012",
    "county": "White County",
    "state": "AR",
    "city": "Beebe"
  },
  {
    "value": "72013",
    "label": "Bee Branch - AR 72013",
    "county": "Van Buren County",
    "state": "AR",
    "city": "Bee Branch"
  },
  {
    "value": "72015",
    "label": "Benton - AR 72015",
    "county": "Saline County",
    "state": "AR",
    "city": "Benton"
  },
  {
    "value": "72016",
    "label": "Bigelow - AR 72016",
    "county": "Perry County",
    "state": "AR",
    "city": "Bigelow"
  },
  {
    "value": "72017",
    "label": "Biscoe - AR 72017",
    "county": "Prairie County",
    "state": "AR",
    "city": "Biscoe"
  },
  {
    "value": "72019",
    "label": "Benton - AR 72019",
    "county": "Saline County",
    "state": "AR",
    "city": "Benton"
  },
  {
    "value": "72020",
    "label": "Bradford - AR 72020",
    "county": "White County",
    "state": "AR",
    "city": "Bradford"
  },
  {
    "value": "72021",
    "label": "Brinkley - AR 72021",
    "county": "Monroe County",
    "state": "AR",
    "city": "Brinkley"
  },
  {
    "value": "72022",
    "label": "Bryant - AR 72022",
    "county": "Saline County",
    "state": "AR",
    "city": "Bryant"
  },
  {
    "value": "72023",
    "label": "Cabot - AR 72023",
    "county": "Lonoke County",
    "state": "AR",
    "city": "Cabot"
  },
  {
    "value": "72024",
    "label": "Carlisle - AR 72024",
    "county": "Lonoke County",
    "state": "AR",
    "city": "Carlisle"
  },
  {
    "value": "72025",
    "label": "Casa - AR 72025",
    "county": "Perry County",
    "state": "AR",
    "city": "Casa"
  },
  {
    "value": "72026",
    "label": "Casscoe - AR 72026",
    "county": "Arkansas County",
    "state": "AR",
    "city": "Casscoe"
  },
  {
    "value": "72027",
    "label": "Center Ridge - AR 72027",
    "county": "Conway County",
    "state": "AR",
    "city": "Center Ridge"
  },
  {
    "value": "72029",
    "label": "Clarendon - AR 72029",
    "county": "Monroe County",
    "state": "AR",
    "city": "Clarendon"
  },
  {
    "value": "72030",
    "label": "Cleveland - AR 72030",
    "county": "Conway County",
    "state": "AR",
    "city": "Cleveland"
  },
  {
    "value": "72031",
    "label": "Clinton - AR 72031",
    "county": "Van Buren County",
    "state": "AR",
    "city": "Clinton"
  },
  {
    "value": "72032",
    "label": "Conway - AR 72032",
    "county": "Faulkner County",
    "state": "AR",
    "city": "Conway"
  },
  {
    "value": "72034",
    "label": "Conway - AR 72034",
    "county": "Faulkner County",
    "state": "AR",
    "city": "Conway"
  },
  {
    "value": "72036",
    "label": "Cotton Plant - AR 72036",
    "county": "Woodruff County",
    "state": "AR",
    "city": "Cotton Plant"
  },
  {
    "value": "72038",
    "label": "Crocketts Bluff - AR 72038",
    "county": "Arkansas County",
    "state": "AR",
    "city": "Crocketts Bluff"
  },
  {
    "value": "72039",
    "label": "Damascus - AR 72039",
    "county": "Faulkner County",
    "state": "AR",
    "city": "Damascus"
  },
  {
    "value": "72040",
    "label": "Des Arc - AR 72040",
    "county": "Prairie County",
    "state": "AR",
    "city": "Des Arc"
  },
  {
    "value": "72041",
    "label": "De Valls Bluff - AR 72041",
    "county": "Prairie County",
    "state": "AR",
    "city": "De Valls Bluff"
  },
  {
    "value": "72042",
    "label": "De Witt - AR 72042",
    "county": "Arkansas County",
    "state": "AR",
    "city": "De Witt"
  },
  {
    "value": "72044",
    "label": "Edgemont - AR 72044",
    "county": "Cleburne County",
    "state": "AR",
    "city": "Edgemont"
  },
  {
    "value": "72045",
    "label": "El Paso - AR 72045",
    "county": "White County",
    "state": "AR",
    "city": "El Paso"
  },
  {
    "value": "72046",
    "label": "England - AR 72046",
    "county": "Lonoke County",
    "state": "AR",
    "city": "England"
  },
  {
    "value": "72047",
    "label": "Enola - AR 72047",
    "county": "Faulkner County",
    "state": "AR",
    "city": "Enola"
  },
  {
    "value": "72048",
    "label": "Ethel - AR 72048",
    "county": "Arkansas County",
    "state": "AR",
    "city": "Ethel"
  },
  {
    "value": "72051",
    "label": "Fox - AR 72051",
    "county": "Stone County",
    "state": "AR",
    "city": "Fox"
  },
  {
    "value": "72055",
    "label": "Gillett - AR 72055",
    "county": "Arkansas County",
    "state": "AR",
    "city": "Gillett"
  },
  {
    "value": "72057",
    "label": "Grapevine - AR 72057",
    "county": "Grant County",
    "state": "AR",
    "city": "Grapevine"
  },
  {
    "value": "72058",
    "label": "Greenbrier - AR 72058",
    "county": "Faulkner County",
    "state": "AR",
    "city": "Greenbrier"
  },
  {
    "value": "72060",
    "label": "Griffithville - AR 72060",
    "county": "White County",
    "state": "AR",
    "city": "Griffithville"
  },
  {
    "value": "72061",
    "label": "Guy - AR 72061",
    "county": "Faulkner County",
    "state": "AR",
    "city": "Guy"
  },
  {
    "value": "72063",
    "label": "Hattieville - AR 72063",
    "county": "Conway County",
    "state": "AR",
    "city": "Hattieville"
  },
  {
    "value": "72064",
    "label": "Hazen - AR 72064",
    "county": "Prairie County",
    "state": "AR",
    "city": "Hazen"
  },
  {
    "value": "72065",
    "label": "Hensley - AR 72065",
    "county": "Saline County",
    "state": "AR",
    "city": "Hensley"
  },
  {
    "value": "72067",
    "label": "Higden - AR 72067",
    "county": "Cleburne County",
    "state": "AR",
    "city": "Higden"
  },
  {
    "value": "72068",
    "label": "Higginson - AR 72068",
    "county": "White County",
    "state": "AR",
    "city": "Higginson"
  },
  {
    "value": "72069",
    "label": "Holly Grove - AR 72069",
    "county": "Monroe County",
    "state": "AR",
    "city": "Holly Grove"
  },
  {
    "value": "72070",
    "label": "Houston - AR 72070",
    "county": "Perry County",
    "state": "AR",
    "city": "Houston"
  },
  {
    "value": "72072",
    "label": "Humnoke - AR 72072",
    "county": "Lonoke County",
    "state": "AR",
    "city": "Humnoke"
  },
  {
    "value": "72073",
    "label": "Humphrey - AR 72073",
    "county": "Arkansas County",
    "state": "AR",
    "city": "Humphrey"
  },
  {
    "value": "72076",
    "label": "Jacksonville - AR 72076",
    "county": "Pulaski County",
    "state": "AR",
    "city": "Jacksonville"
  },
  {
    "value": "72079",
    "label": "Jefferson - AR 72079",
    "county": "Jefferson County",
    "state": "AR",
    "city": "Jefferson"
  },
  {
    "value": "72080",
    "label": "Jerusalem - AR 72080",
    "county": "Conway County",
    "state": "AR",
    "city": "Jerusalem"
  },
  {
    "value": "72081",
    "label": "Judsonia - AR 72081",
    "county": "White County",
    "state": "AR",
    "city": "Judsonia"
  },
  {
    "value": "72082",
    "label": "Kensett - AR 72082",
    "county": "White County",
    "state": "AR",
    "city": "Kensett"
  },
  {
    "value": "72084",
    "label": "Leola - AR 72084",
    "county": "Grant County",
    "state": "AR",
    "city": "Leola"
  },
  {
    "value": "72086",
    "label": "Lonoke - AR 72086",
    "county": "Lonoke County",
    "state": "AR",
    "city": "Lonoke"
  },
  {
    "value": "72087",
    "label": "Lonsdale - AR 72087",
    "county": "Saline County",
    "state": "AR",
    "city": "Lonsdale"
  },
  {
    "value": "72088",
    "label": "Fairfield Bay - AR 72088",
    "county": "Van Buren County",
    "state": "AR",
    "city": "Fairfield Bay"
  },
  {
    "value": "72101",
    "label": "Mc Crory - AR 72101",
    "county": "Woodruff County",
    "state": "AR",
    "city": "Mc Crory"
  },
  {
    "value": "72102",
    "label": "Mc Rae - AR 72102",
    "county": "White County",
    "state": "AR",
    "city": "Mc Rae"
  },
  {
    "value": "72103",
    "label": "Mabelvale - AR 72103",
    "county": "Saline County",
    "state": "AR",
    "city": "Mabelvale"
  },
  {
    "value": "72104",
    "label": "Malvern - AR 72104",
    "county": "Hot Spring County",
    "state": "AR",
    "city": "Malvern"
  },
  {
    "value": "72106",
    "label": "Mayflower - AR 72106",
    "county": "Faulkner County",
    "state": "AR",
    "city": "Mayflower"
  },
  {
    "value": "72110",
    "label": "Morrilton - AR 72110",
    "county": "Conway County",
    "state": "AR",
    "city": "Morrilton"
  },
  {
    "value": "72111",
    "label": "Mount Vernon - AR 72111",
    "county": "Faulkner County",
    "state": "AR",
    "city": "Mount Vernon"
  },
  {
    "value": "72112",
    "label": "Newport - AR 72112",
    "county": "Jackson County",
    "state": "AR",
    "city": "Newport"
  },
  {
    "value": "72113",
    "label": "Maumelle - AR 72113",
    "county": "Pulaski County",
    "state": "AR",
    "city": "Maumelle"
  },
  {
    "value": "72114",
    "label": "North Little Rock - AR 72114",
    "county": "Pulaski County",
    "state": "AR",
    "city": "North Little Rock"
  },
  {
    "value": "72116",
    "label": "North Little Rock - AR 72116",
    "county": "Pulaski County",
    "state": "AR",
    "city": "North Little Rock"
  },
  {
    "value": "72117",
    "label": "North Little Rock - AR 72117",
    "county": "Pulaski County",
    "state": "AR",
    "city": "North Little Rock"
  },
  {
    "value": "72118",
    "label": "North Little Rock - AR 72118",
    "county": "Pulaski County",
    "state": "AR",
    "city": "North Little Rock"
  },
  {
    "value": "72120",
    "label": "Sherwood - AR 72120",
    "county": "Pulaski County",
    "state": "AR",
    "city": "Sherwood"
  },
  {
    "value": "72121",
    "label": "Pangburn - AR 72121",
    "county": "White County",
    "state": "AR",
    "city": "Pangburn"
  },
  {
    "value": "72122",
    "label": "Paron - AR 72122",
    "county": "Saline County",
    "state": "AR",
    "city": "Paron"
  },
  {
    "value": "72125",
    "label": "Perry - AR 72125",
    "county": "Perry County",
    "state": "AR",
    "city": "Perry"
  },
  {
    "value": "72126",
    "label": "Perryville - AR 72126",
    "county": "Perry County",
    "state": "AR",
    "city": "Perryville"
  },
  {
    "value": "72127",
    "label": "Plumerville - AR 72127",
    "county": "Conway County",
    "state": "AR",
    "city": "Plumerville"
  },
  {
    "value": "72128",
    "label": "Poyen - AR 72128",
    "county": "Grant County",
    "state": "AR",
    "city": "Poyen"
  },
  {
    "value": "72129",
    "label": "Prattsville - AR 72129",
    "county": "Grant County",
    "state": "AR",
    "city": "Prattsville"
  },
  {
    "value": "72130",
    "label": "Prim - AR 72130",
    "county": "Cleburne County",
    "state": "AR",
    "city": "Prim"
  },
  {
    "value": "72131",
    "label": "Quitman - AR 72131",
    "county": "Cleburne County",
    "state": "AR",
    "city": "Quitman"
  },
  {
    "value": "72132",
    "label": "Redfield - AR 72132",
    "county": "Jefferson County",
    "state": "AR",
    "city": "Redfield"
  },
  {
    "value": "72134",
    "label": "Roe - AR 72134",
    "county": "Monroe County",
    "state": "AR",
    "city": "Roe"
  },
  {
    "value": "72135",
    "label": "Roland - AR 72135",
    "county": "Pulaski County",
    "state": "AR",
    "city": "Roland"
  },
  {
    "value": "72136",
    "label": "Romance - AR 72136",
    "county": "White County",
    "state": "AR",
    "city": "Romance"
  },
  {
    "value": "72137",
    "label": "Rose Bud - AR 72137",
    "county": "White County",
    "state": "AR",
    "city": "Rose Bud"
  },
  {
    "value": "72140",
    "label": "Saint Charles - AR 72140",
    "county": "Arkansas County",
    "state": "AR",
    "city": "Saint Charles"
  },
  {
    "value": "72141",
    "label": "Scotland - AR 72141",
    "county": "Van Buren County",
    "state": "AR",
    "city": "Scotland"
  },
  {
    "value": "72142",
    "label": "Scott - AR 72142",
    "county": "Lonoke County",
    "state": "AR",
    "city": "Scott"
  },
  {
    "value": "72143",
    "label": "Searcy - AR 72143",
    "county": "White County",
    "state": "AR",
    "city": "Searcy"
  },
  {
    "value": "72150",
    "label": "Sheridan - AR 72150",
    "county": "Grant County",
    "state": "AR",
    "city": "Sheridan"
  },
  {
    "value": "72152",
    "label": "Sherrill - AR 72152",
    "county": "Jefferson County",
    "state": "AR",
    "city": "Sherrill"
  },
  {
    "value": "72153",
    "label": "Shirley - AR 72153",
    "county": "Van Buren County",
    "state": "AR",
    "city": "Shirley"
  },
  {
    "value": "72156",
    "label": "Solgohachia - AR 72156",
    "county": "Conway County",
    "state": "AR",
    "city": "Solgohachia"
  },
  {
    "value": "72157",
    "label": "Springfield - AR 72157",
    "county": "Conway County",
    "state": "AR",
    "city": "Springfield"
  },
  {
    "value": "72160",
    "label": "Stuttgart - AR 72160",
    "county": "Arkansas County",
    "state": "AR",
    "city": "Stuttgart"
  },
  {
    "value": "72165",
    "label": "Thida - AR 72165",
    "county": "Independence County",
    "state": "AR",
    "city": "Thida"
  },
  {
    "value": "72166",
    "label": "Tichnor - AR 72166",
    "county": "Arkansas County",
    "state": "AR",
    "city": "Tichnor"
  },
  {
    "value": "72167",
    "label": "Traskwood - AR 72167",
    "county": "Saline County",
    "state": "AR",
    "city": "Traskwood"
  },
  {
    "value": "72168",
    "label": "Tucker - AR 72168",
    "county": "Jefferson County",
    "state": "AR",
    "city": "Tucker"
  },
  {
    "value": "72173",
    "label": "Vilonia - AR 72173",
    "county": "Faulkner County",
    "state": "AR",
    "city": "Vilonia"
  },
  {
    "value": "72175",
    "label": "Wabbaseka - AR 72175",
    "county": "Jefferson County",
    "state": "AR",
    "city": "Wabbaseka"
  },
  {
    "value": "72176",
    "label": "Ward - AR 72176",
    "county": "Lonoke County",
    "state": "AR",
    "city": "Ward"
  },
  {
    "value": "72179",
    "label": "Wilburn - AR 72179",
    "county": "Cleburne County",
    "state": "AR",
    "city": "Wilburn"
  },
  {
    "value": "72181",
    "label": "Wooster - AR 72181",
    "county": "Faulkner County",
    "state": "AR",
    "city": "Wooster"
  },
  {
    "value": "72198",
    "label": "North Little Rock - AR 72198",
    "county": "Pulaski County",
    "state": "AR",
    "city": "North Little Rock"
  },
  {
    "value": "72201",
    "label": "Little Rock - AR 72201",
    "county": "Pulaski County",
    "state": "AR",
    "city": "Little Rock"
  },
  {
    "value": "72202",
    "label": "Little Rock - AR 72202",
    "county": "Pulaski County",
    "state": "AR",
    "city": "Little Rock"
  },
  {
    "value": "72204",
    "label": "Little Rock - AR 72204",
    "county": "Pulaski County",
    "state": "AR",
    "city": "Little Rock"
  },
  {
    "value": "72205",
    "label": "Little Rock - AR 72205",
    "county": "Pulaski County",
    "state": "AR",
    "city": "Little Rock"
  },
  {
    "value": "72206",
    "label": "Little Rock - AR 72206",
    "county": "Pulaski County",
    "state": "AR",
    "city": "Little Rock"
  },
  {
    "value": "72207",
    "label": "Little Rock - AR 72207",
    "county": "Pulaski County",
    "state": "AR",
    "city": "Little Rock"
  },
  {
    "value": "72209",
    "label": "Little Rock - AR 72209",
    "county": "Pulaski County",
    "state": "AR",
    "city": "Little Rock"
  },
  {
    "value": "72210",
    "label": "Little Rock - AR 72210",
    "county": "Pulaski County",
    "state": "AR",
    "city": "Little Rock"
  },
  {
    "value": "72211",
    "label": "Little Rock - AR 72211",
    "county": "Pulaski County",
    "state": "AR",
    "city": "Little Rock"
  },
  {
    "value": "72212",
    "label": "Little Rock - AR 72212",
    "county": "Pulaski County",
    "state": "AR",
    "city": "Little Rock"
  },
  {
    "value": "72223",
    "label": "Little Rock - AR 72223",
    "county": "Pulaski County",
    "state": "AR",
    "city": "Little Rock"
  },
  {
    "value": "72227",
    "label": "Little Rock - AR 72227",
    "county": "Pulaski County",
    "state": "AR",
    "city": "Little Rock"
  },
  {
    "value": "72301",
    "label": "West Memphis - AR 72301",
    "county": "Crittenden County",
    "state": "AR",
    "city": "West Memphis"
  },
  {
    "value": "72315",
    "label": "Blytheville - AR 72315",
    "county": "Mississippi County",
    "state": "AR",
    "city": "Blytheville"
  },
  {
    "value": "72320",
    "label": "Brickeys - AR 72320",
    "county": "Lee County",
    "state": "AR",
    "city": "Brickeys"
  },
  {
    "value": "72324",
    "label": "Cherry Valley - AR 72324",
    "county": "Cross County",
    "state": "AR",
    "city": "Cherry Valley"
  },
  {
    "value": "72326",
    "label": "Colt - AR 72326",
    "county": "St. Francis County",
    "state": "AR",
    "city": "Colt"
  },
  {
    "value": "72327",
    "label": "Crawfordsville - AR 72327",
    "county": "Crittenden County",
    "state": "AR",
    "city": "Crawfordsville"
  },
  {
    "value": "72330",
    "label": "Dyess - AR 72330",
    "county": "Mississippi County",
    "state": "AR",
    "city": "Dyess"
  },
  {
    "value": "72331",
    "label": "Earle - AR 72331",
    "county": "Crittenden County",
    "state": "AR",
    "city": "Earle"
  },
  {
    "value": "72333",
    "label": "Elaine - AR 72333",
    "county": "Phillips County",
    "state": "AR",
    "city": "Elaine"
  },
  {
    "value": "72335",
    "label": "Forrest City - AR 72335",
    "county": "St. Francis County",
    "state": "AR",
    "city": "Forrest City"
  },
  {
    "value": "72338",
    "label": "Frenchmans Bayou - AR 72338",
    "county": "Mississippi County",
    "state": "AR",
    "city": "Frenchmans Bayou"
  },
  {
    "value": "72342",
    "label": "Helena - AR 72342",
    "county": "Phillips County",
    "state": "AR",
    "city": "Helena"
  },
  {
    "value": "72346",
    "label": "Heth - AR 72346",
    "county": "St. Francis County",
    "state": "AR",
    "city": "Heth"
  },
  {
    "value": "72347",
    "label": "Hickory Ridge - AR 72347",
    "county": "Cross County",
    "state": "AR",
    "city": "Hickory Ridge"
  },
  {
    "value": "72348",
    "label": "Hughes - AR 72348",
    "county": "St. Francis County",
    "state": "AR",
    "city": "Hughes"
  },
  {
    "value": "72350",
    "label": "Joiner - AR 72350",
    "county": "Mississippi County",
    "state": "AR",
    "city": "Joiner"
  },
  {
    "value": "72354",
    "label": "Lepanto - AR 72354",
    "county": "Poinsett County",
    "state": "AR",
    "city": "Lepanto"
  },
  {
    "value": "72355",
    "label": "Lexa - AR 72355",
    "county": "Phillips County",
    "state": "AR",
    "city": "Lexa"
  },
  {
    "value": "72358",
    "label": "Luxora - AR 72358",
    "county": "Mississippi County",
    "state": "AR",
    "city": "Luxora"
  },
  {
    "value": "72360",
    "label": "Marianna - AR 72360",
    "county": "Lee County",
    "state": "AR",
    "city": "Marianna"
  },
  {
    "value": "72364",
    "label": "Marion - AR 72364",
    "county": "Crittenden County",
    "state": "AR",
    "city": "Marion"
  },
  {
    "value": "72365",
    "label": "Marked Tree - AR 72365",
    "county": "Poinsett County",
    "state": "AR",
    "city": "Marked Tree"
  },
  {
    "value": "72366",
    "label": "Marvell - AR 72366",
    "county": "Phillips County",
    "state": "AR",
    "city": "Marvell"
  },
  {
    "value": "72368",
    "label": "Moro - AR 72368",
    "county": "Lee County",
    "state": "AR",
    "city": "Moro"
  },
  {
    "value": "72370",
    "label": "Osceola - AR 72370",
    "county": "Mississippi County",
    "state": "AR",
    "city": "Osceola"
  },
  {
    "value": "72372",
    "label": "Palestine - AR 72372",
    "county": "St. Francis County",
    "state": "AR",
    "city": "Palestine"
  },
  {
    "value": "72373",
    "label": "Parkin - AR 72373",
    "county": "Cross County",
    "state": "AR",
    "city": "Parkin"
  },
  {
    "value": "72374",
    "label": "Poplar Grove - AR 72374",
    "county": "Phillips County",
    "state": "AR",
    "city": "Poplar Grove"
  },
  {
    "value": "72376",
    "label": "Proctor - AR 72376",
    "county": "Crittenden County",
    "state": "AR",
    "city": "Proctor"
  },
  {
    "value": "72384",
    "label": "Turrell - AR 72384",
    "county": "Crittenden County",
    "state": "AR",
    "city": "Turrell"
  },
  {
    "value": "72386",
    "label": "Tyronza - AR 72386",
    "county": "Poinsett County",
    "state": "AR",
    "city": "Tyronza"
  },
  {
    "value": "72390",
    "label": "West Helena - AR 72390",
    "county": "Phillips County",
    "state": "AR",
    "city": "West Helena"
  },
  {
    "value": "72392",
    "label": "Wheatley - AR 72392",
    "county": "St. Francis County",
    "state": "AR",
    "city": "Wheatley"
  },
  {
    "value": "72394",
    "label": "Widener - AR 72394",
    "county": "St. Francis County",
    "state": "AR",
    "city": "Widener"
  },
  {
    "value": "72395",
    "label": "Wilson - AR 72395",
    "county": "Mississippi County",
    "state": "AR",
    "city": "Wilson"
  },
  {
    "value": "72396",
    "label": "Wynne - AR 72396",
    "county": "Cross County",
    "state": "AR",
    "city": "Wynne"
  },
  {
    "value": "72401",
    "label": "Jonesboro - AR 72401",
    "county": "Craighead County",
    "state": "AR",
    "city": "Jonesboro"
  },
  {
    "value": "72404",
    "label": "Jonesboro - AR 72404",
    "county": "Craighead County",
    "state": "AR",
    "city": "Jonesboro"
  },
  {
    "value": "72405",
    "label": "Jonesboro - AR 72405",
    "county": "Craighead County",
    "state": "AR",
    "city": "Jonesboro"
  },
  {
    "value": "72410",
    "label": "Alicia - AR 72410",
    "county": "Lawrence County",
    "state": "AR",
    "city": "Alicia"
  },
  {
    "value": "72411",
    "label": "Bay - AR 72411",
    "county": "Craighead County",
    "state": "AR",
    "city": "Bay"
  },
  {
    "value": "72412",
    "label": "Beech Grove - AR 72412",
    "county": "Greene County",
    "state": "AR",
    "city": "Beech Grove"
  },
  {
    "value": "72413",
    "label": "Biggers - AR 72413",
    "county": "Randolph County",
    "state": "AR",
    "city": "Biggers"
  },
  {
    "value": "72414",
    "label": "Black Oak - AR 72414",
    "county": "Craighead County",
    "state": "AR",
    "city": "Black Oak"
  },
  {
    "value": "72415",
    "label": "Black Rock - AR 72415",
    "county": "Lawrence County",
    "state": "AR",
    "city": "Black Rock"
  },
  {
    "value": "72416",
    "label": "Bono - AR 72416",
    "county": "Craighead County",
    "state": "AR",
    "city": "Bono"
  },
  {
    "value": "72417",
    "label": "Brookland - AR 72417",
    "county": "Craighead County",
    "state": "AR",
    "city": "Brookland"
  },
  {
    "value": "72419",
    "label": "Caraway - AR 72419",
    "county": "Craighead County",
    "state": "AR",
    "city": "Caraway"
  },
  {
    "value": "72421",
    "label": "Cash - AR 72421",
    "county": "Craighead County",
    "state": "AR",
    "city": "Cash"
  },
  {
    "value": "72422",
    "label": "Corning - AR 72422",
    "county": "Clay County",
    "state": "AR",
    "city": "Corning"
  },
  {
    "value": "72425",
    "label": "Delaplaine - AR 72425",
    "county": "Greene County",
    "state": "AR",
    "city": "Delaplaine"
  },
  {
    "value": "72428",
    "label": "Etowah - AR 72428",
    "county": "Mississippi County",
    "state": "AR",
    "city": "Etowah"
  },
  {
    "value": "72429",
    "label": "Fisher - AR 72429",
    "county": "Poinsett County",
    "state": "AR",
    "city": "Fisher"
  },
  {
    "value": "72430",
    "label": "Greenway - AR 72430",
    "county": "Clay County",
    "state": "AR",
    "city": "Greenway"
  },
  {
    "value": "72432",
    "label": "Harrisburg - AR 72432",
    "county": "Poinsett County",
    "state": "AR",
    "city": "Harrisburg"
  },
  {
    "value": "72433",
    "label": "Hoxie - AR 72433",
    "county": "Lawrence County",
    "state": "AR",
    "city": "Hoxie"
  },
  {
    "value": "72434",
    "label": "Imboden - AR 72434",
    "county": "Lawrence County",
    "state": "AR",
    "city": "Imboden"
  },
  {
    "value": "72435",
    "label": "Knobel - AR 72435",
    "county": "Clay County",
    "state": "AR",
    "city": "Knobel"
  },
  {
    "value": "72436",
    "label": "Lafe - AR 72436",
    "county": "Greene County",
    "state": "AR",
    "city": "Lafe"
  },
  {
    "value": "72437",
    "label": "Lake City - AR 72437",
    "county": "Craighead County",
    "state": "AR",
    "city": "Lake City"
  },
  {
    "value": "72438",
    "label": "Leachville - AR 72438",
    "county": "Mississippi County",
    "state": "AR",
    "city": "Leachville"
  },
  {
    "value": "72439",
    "label": "Light - AR 72439",
    "county": "Greene County",
    "state": "AR",
    "city": "Light"
  },
  {
    "value": "72440",
    "label": "Lynn - AR 72440",
    "county": "Lawrence County",
    "state": "AR",
    "city": "Lynn"
  },
  {
    "value": "72442",
    "label": "Manila - AR 72442",
    "county": "Mississippi County",
    "state": "AR",
    "city": "Manila"
  },
  {
    "value": "72443",
    "label": "Marmaduke - AR 72443",
    "county": "Greene County",
    "state": "AR",
    "city": "Marmaduke"
  },
  {
    "value": "72444",
    "label": "Maynard - AR 72444",
    "county": "Randolph County",
    "state": "AR",
    "city": "Maynard"
  },
  {
    "value": "72447",
    "label": "Monette - AR 72447",
    "county": "Craighead County",
    "state": "AR",
    "city": "Monette"
  },
  {
    "value": "72450",
    "label": "Paragould - AR 72450",
    "county": "Greene County",
    "state": "AR",
    "city": "Paragould"
  },
  {
    "value": "72453",
    "label": "Peach Orchard - AR 72453",
    "county": "Clay County",
    "state": "AR",
    "city": "Peach Orchard"
  },
  {
    "value": "72454",
    "label": "Piggott - AR 72454",
    "county": "Clay County",
    "state": "AR",
    "city": "Piggott"
  },
  {
    "value": "72455",
    "label": "Pocahontas - AR 72455",
    "county": "Randolph County",
    "state": "AR",
    "city": "Pocahontas"
  },
  {
    "value": "72456",
    "label": "Pollard - AR 72456",
    "county": "Clay County",
    "state": "AR",
    "city": "Pollard"
  },
  {
    "value": "72458",
    "label": "Powhatan - AR 72458",
    "county": "Lawrence County",
    "state": "AR",
    "city": "Powhatan"
  },
  {
    "value": "72459",
    "label": "Ravenden - AR 72459",
    "county": "Lawrence County",
    "state": "AR",
    "city": "Ravenden"
  },
  {
    "value": "72460",
    "label": "Ravenden Springs - AR 72460",
    "county": "Randolph County",
    "state": "AR",
    "city": "Ravenden Springs"
  },
  {
    "value": "72461",
    "label": "Rector - AR 72461",
    "county": "Clay County",
    "state": "AR",
    "city": "Rector"
  },
  {
    "value": "72466",
    "label": "Smithville - AR 72466",
    "county": "Lawrence County",
    "state": "AR",
    "city": "Smithville"
  },
  {
    "value": "72469",
    "label": "Strawberry - AR 72469",
    "county": "Lawrence County",
    "state": "AR",
    "city": "Strawberry"
  },
  {
    "value": "72470",
    "label": "Success - AR 72470",
    "county": "Clay County",
    "state": "AR",
    "city": "Success"
  },
  {
    "value": "72471",
    "label": "Swifton - AR 72471",
    "county": "Jackson County",
    "state": "AR",
    "city": "Swifton"
  },
  {
    "value": "72472",
    "label": "Trumann - AR 72472",
    "county": "Poinsett County",
    "state": "AR",
    "city": "Trumann"
  },
  {
    "value": "72473",
    "label": "Tuckerman - AR 72473",
    "county": "Jackson County",
    "state": "AR",
    "city": "Tuckerman"
  },
  {
    "value": "72476",
    "label": "Walnut Ridge - AR 72476",
    "county": "Lawrence County",
    "state": "AR",
    "city": "Walnut Ridge"
  },
  {
    "value": "72478",
    "label": "Warm Springs - AR 72478",
    "county": "Randolph County",
    "state": "AR",
    "city": "Warm Springs"
  },
  {
    "value": "72479",
    "label": "Weiner - AR 72479",
    "county": "Poinsett County",
    "state": "AR",
    "city": "Weiner"
  },
  {
    "value": "72482",
    "label": "Williford - AR 72482",
    "county": "Sharp County",
    "state": "AR",
    "city": "Williford"
  },
  {
    "value": "72501",
    "label": "Batesville - AR 72501",
    "county": "Independence County",
    "state": "AR",
    "city": "Batesville"
  },
  {
    "value": "72512",
    "label": "Horseshoe Bend - AR 72512",
    "county": "Izard County",
    "state": "AR",
    "city": "Horseshoe Bend"
  },
  {
    "value": "72513",
    "label": "Ash Flat - AR 72513",
    "county": "Sharp County",
    "state": "AR",
    "city": "Ash Flat"
  },
  {
    "value": "72515",
    "label": "Bexar - AR 72515",
    "county": "Fulton County",
    "state": "AR",
    "city": "Bexar"
  },
  {
    "value": "72517",
    "label": "Brockwell - AR 72517",
    "county": "Izard County",
    "state": "AR",
    "city": "Brockwell"
  },
  {
    "value": "72519",
    "label": "Calico Rock - AR 72519",
    "county": "Izard County",
    "state": "AR",
    "city": "Calico Rock"
  },
  {
    "value": "72520",
    "label": "Camp - AR 72520",
    "county": "Fulton County",
    "state": "AR",
    "city": "Camp"
  },
  {
    "value": "72521",
    "label": "Cave City - AR 72521",
    "county": "Sharp County",
    "state": "AR",
    "city": "Cave City"
  },
  {
    "value": "72522",
    "label": "Charlotte - AR 72522",
    "county": "Independence County",
    "state": "AR",
    "city": "Charlotte"
  },
  {
    "value": "72523",
    "label": "Concord - AR 72523",
    "county": "Cleburne County",
    "state": "AR",
    "city": "Concord"
  },
  {
    "value": "72524",
    "label": "Cord - AR 72524",
    "county": "Independence County",
    "state": "AR",
    "city": "Cord"
  },
  {
    "value": "72527",
    "label": "Desha - AR 72527",
    "county": "Independence County",
    "state": "AR",
    "city": "Desha"
  },
  {
    "value": "72528",
    "label": "Dolph - AR 72528",
    "county": "Izard County",
    "state": "AR",
    "city": "Dolph"
  },
  {
    "value": "72529",
    "label": "Cherokee Village - AR 72529",
    "county": "Sharp County",
    "state": "AR",
    "city": "Cherokee Village"
  },
  {
    "value": "72530",
    "label": "Drasco - AR 72530",
    "county": "Cleburne County",
    "state": "AR",
    "city": "Drasco"
  },
  {
    "value": "72531",
    "label": "Elizabeth - AR 72531",
    "county": "Fulton County",
    "state": "AR",
    "city": "Elizabeth"
  },
  {
    "value": "72532",
    "label": "Evening Shade - AR 72532",
    "county": "Sharp County",
    "state": "AR",
    "city": "Evening Shade"
  },
  {
    "value": "72533",
    "label": "Fifty Six - AR 72533",
    "county": "Stone County",
    "state": "AR",
    "city": "Fifty Six"
  },
  {
    "value": "72534",
    "label": "Floral - AR 72534",
    "county": "Independence County",
    "state": "AR",
    "city": "Floral"
  },
  {
    "value": "72536",
    "label": "Franklin - AR 72536",
    "county": "Izard County",
    "state": "AR",
    "city": "Franklin"
  },
  {
    "value": "72537",
    "label": "Gamaliel - AR 72537",
    "county": "Baxter County",
    "state": "AR",
    "city": "Gamaliel"
  },
  {
    "value": "72538",
    "label": "Gepp - AR 72538",
    "county": "Fulton County",
    "state": "AR",
    "city": "Gepp"
  },
  {
    "value": "72539",
    "label": "Glencoe - AR 72539",
    "county": "Fulton County",
    "state": "AR",
    "city": "Glencoe"
  },
  {
    "value": "72540",
    "label": "Guion - AR 72540",
    "county": "Izard County",
    "state": "AR",
    "city": "Guion"
  },
  {
    "value": "72542",
    "label": "Hardy - AR 72542",
    "county": "Sharp County",
    "state": "AR",
    "city": "Hardy"
  },
  {
    "value": "72543",
    "label": "Heber Springs - AR 72543",
    "county": "Cleburne County",
    "state": "AR",
    "city": "Heber Springs"
  },
  {
    "value": "72544",
    "label": "Henderson - AR 72544",
    "county": "Baxter County",
    "state": "AR",
    "city": "Henderson"
  },
  {
    "value": "72546",
    "label": "Ida - AR 72546",
    "county": "Cleburne County",
    "state": "AR",
    "city": "Ida"
  },
  {
    "value": "72550",
    "label": "Locust Grove - AR 72550",
    "county": "Independence County",
    "state": "AR",
    "city": "Locust Grove"
  },
  {
    "value": "72554",
    "label": "Mammoth Spring - AR 72554",
    "county": "Fulton County",
    "state": "AR",
    "city": "Mammoth Spring"
  },
  {
    "value": "72555",
    "label": "Marcella - AR 72555",
    "county": "Stone County",
    "state": "AR",
    "city": "Marcella"
  },
  {
    "value": "72556",
    "label": "Melbourne - AR 72556",
    "county": "Izard County",
    "state": "AR",
    "city": "Melbourne"
  },
  {
    "value": "72560",
    "label": "Mountain View - AR 72560",
    "county": "Stone County",
    "state": "AR",
    "city": "Mountain View"
  },
  {
    "value": "72561",
    "label": "Mount Pleasant - AR 72561",
    "county": "Izard County",
    "state": "AR",
    "city": "Mount Pleasant"
  },
  {
    "value": "72562",
    "label": "Newark - AR 72562",
    "county": "Independence County",
    "state": "AR",
    "city": "Newark"
  },
  {
    "value": "72564",
    "label": "Oil Trough - AR 72564",
    "county": "Independence County",
    "state": "AR",
    "city": "Oil Trough"
  },
  {
    "value": "72565",
    "label": "Oxford - AR 72565",
    "county": "Izard County",
    "state": "AR",
    "city": "Oxford"
  },
  {
    "value": "72566",
    "label": "Pineville - AR 72566",
    "county": "Izard County",
    "state": "AR",
    "city": "Pineville"
  },
  {
    "value": "72567",
    "label": "Pleasant Grove - AR 72567",
    "county": "Stone County",
    "state": "AR",
    "city": "Pleasant Grove"
  },
  {
    "value": "72568",
    "label": "Pleasant Plains - AR 72568",
    "county": "Independence County",
    "state": "AR",
    "city": "Pleasant Plains"
  },
  {
    "value": "72569",
    "label": "Poughkeepsie - AR 72569",
    "county": "Sharp County",
    "state": "AR",
    "city": "Poughkeepsie"
  },
  {
    "value": "72571",
    "label": "Rosie - AR 72571",
    "county": "Independence County",
    "state": "AR",
    "city": "Rosie"
  },
  {
    "value": "72572",
    "label": "Saffell - AR 72572",
    "county": "Lawrence County",
    "state": "AR",
    "city": "Saffell"
  },
  {
    "value": "72573",
    "label": "Sage - AR 72573",
    "county": "Izard County",
    "state": "AR",
    "city": "Sage"
  },
  {
    "value": "72576",
    "label": "Salem - AR 72576",
    "county": "Fulton County",
    "state": "AR",
    "city": "Salem"
  },
  {
    "value": "72577",
    "label": "Sidney - AR 72577",
    "county": "Izard County",
    "state": "AR",
    "city": "Sidney"
  },
  {
    "value": "72578",
    "label": "Sturkie - AR 72578",
    "county": "Fulton County",
    "state": "AR",
    "city": "Sturkie"
  },
  {
    "value": "72579",
    "label": "Sulphur Rock - AR 72579",
    "county": "Independence County",
    "state": "AR",
    "city": "Sulphur Rock"
  },
  {
    "value": "72581",
    "label": "Tumbling Shoals - AR 72581",
    "county": "Cleburne County",
    "state": "AR",
    "city": "Tumbling Shoals"
  },
  {
    "value": "72583",
    "label": "Viola - AR 72583",
    "county": "Fulton County",
    "state": "AR",
    "city": "Viola"
  },
  {
    "value": "72584",
    "label": "Violet Hill - AR 72584",
    "county": "Izard County",
    "state": "AR",
    "city": "Violet Hill"
  },
  {
    "value": "72585",
    "label": "Wideman - AR 72585",
    "county": "Izard County",
    "state": "AR",
    "city": "Wideman"
  },
  {
    "value": "72587",
    "label": "Wiseman - AR 72587",
    "county": "Izard County",
    "state": "AR",
    "city": "Wiseman"
  },
  {
    "value": "72601",
    "label": "Harrison - AR 72601",
    "county": "Boone County",
    "state": "AR",
    "city": "Harrison"
  },
  {
    "value": "72611",
    "label": "Alpena - AR 72611",
    "county": "Carroll County",
    "state": "AR",
    "city": "Alpena"
  },
  {
    "value": "72616",
    "label": "Berryville - AR 72616",
    "county": "Carroll County",
    "state": "AR",
    "city": "Berryville"
  },
  {
    "value": "72617",
    "label": "Big Flat - AR 72617",
    "county": "Baxter County",
    "state": "AR",
    "city": "Big Flat"
  },
  {
    "value": "72619",
    "label": "Bull Shoals - AR 72619",
    "county": "Marion County",
    "state": "AR",
    "city": "Bull Shoals"
  },
  {
    "value": "72623",
    "label": "Clarkridge - AR 72623",
    "county": "Baxter County",
    "state": "AR",
    "city": "Clarkridge"
  },
  {
    "value": "72624",
    "label": "Compton - AR 72624",
    "county": "Newton County",
    "state": "AR",
    "city": "Compton"
  },
  {
    "value": "72626",
    "label": "Cotter - AR 72626",
    "county": "Baxter County",
    "state": "AR",
    "city": "Cotter"
  },
  {
    "value": "72628",
    "label": "Deer - AR 72628",
    "county": "Newton County",
    "state": "AR",
    "city": "Deer"
  },
  {
    "value": "72629",
    "label": "Dennard - AR 72629",
    "county": "Van Buren County",
    "state": "AR",
    "city": "Dennard"
  },
  {
    "value": "72631",
    "label": "Eureka Springs - AR 72631",
    "county": "Carroll County",
    "state": "AR",
    "city": "Eureka Springs"
  },
  {
    "value": "72632",
    "label": "Eureka Springs - AR 72632",
    "county": "Carroll County",
    "state": "AR",
    "city": "Eureka Springs"
  },
  {
    "value": "72633",
    "label": "Everton - AR 72633",
    "county": "Boone County",
    "state": "AR",
    "city": "Everton"
  },
  {
    "value": "72634",
    "label": "Flippin - AR 72634",
    "county": "Marion County",
    "state": "AR",
    "city": "Flippin"
  },
  {
    "value": "72635",
    "label": "Gassville - AR 72635",
    "county": "Baxter County",
    "state": "AR",
    "city": "Gassville"
  },
  {
    "value": "72638",
    "label": "Green Forest - AR 72638",
    "county": "Carroll County",
    "state": "AR",
    "city": "Green Forest"
  },
  {
    "value": "72639",
    "label": "Harriet - AR 72639",
    "county": "Searcy County",
    "state": "AR",
    "city": "Harriet"
  },
  {
    "value": "72640",
    "label": "Hasty - AR 72640",
    "county": "Newton County",
    "state": "AR",
    "city": "Hasty"
  },
  {
    "value": "72641",
    "label": "Jasper - AR 72641",
    "county": "Newton County",
    "state": "AR",
    "city": "Jasper"
  },
  {
    "value": "72642",
    "label": "Lakeview - AR 72642",
    "county": "Baxter County",
    "state": "AR",
    "city": "Lakeview"
  },
  {
    "value": "72644",
    "label": "Lead Hill - AR 72644",
    "county": "Boone County",
    "state": "AR",
    "city": "Lead Hill"
  },
  {
    "value": "72645",
    "label": "Leslie - AR 72645",
    "county": "Searcy County",
    "state": "AR",
    "city": "Leslie"
  },
  {
    "value": "72648",
    "label": "Marble Falls - AR 72648",
    "county": "Newton County",
    "state": "AR",
    "city": "Marble Falls"
  },
  {
    "value": "72650",
    "label": "Marshall - AR 72650",
    "county": "Searcy County",
    "state": "AR",
    "city": "Marshall"
  },
  {
    "value": "72651",
    "label": "Midway - AR 72651",
    "county": "Baxter County",
    "state": "AR",
    "city": "Midway"
  },
  {
    "value": "72653",
    "label": "Mountain Home - AR 72653",
    "county": "Baxter County",
    "state": "AR",
    "city": "Mountain Home"
  },
  {
    "value": "72655",
    "label": "Mount Judea - AR 72655",
    "county": "Newton County",
    "state": "AR",
    "city": "Mount Judea"
  },
  {
    "value": "72657",
    "label": "Timbo - AR 72657",
    "county": "Stone County",
    "state": "AR",
    "city": "Timbo"
  },
  {
    "value": "72658",
    "label": "Norfork - AR 72658",
    "county": "Baxter County",
    "state": "AR",
    "city": "Norfork"
  },
  {
    "value": "72659",
    "label": "Norfork - AR 72659",
    "county": "Baxter County",
    "state": "AR",
    "city": "Norfork"
  },
  {
    "value": "72660",
    "label": "Oak Grove - AR 72660",
    "county": "Carroll County",
    "state": "AR",
    "city": "Oak Grove"
  },
  {
    "value": "72661",
    "label": "Oakland - AR 72661",
    "county": "Marion County",
    "state": "AR",
    "city": "Oakland"
  },
  {
    "value": "72662",
    "label": "Omaha - AR 72662",
    "county": "Boone County",
    "state": "AR",
    "city": "Omaha"
  },
  {
    "value": "72663",
    "label": "Onia - AR 72663",
    "county": "Stone County",
    "state": "AR",
    "city": "Onia"
  },
  {
    "value": "72666",
    "label": "Parthenon - AR 72666",
    "county": "Newton County",
    "state": "AR",
    "city": "Parthenon"
  },
  {
    "value": "72668",
    "label": "Peel - AR 72668",
    "county": "Marion County",
    "state": "AR",
    "city": "Peel"
  },
  {
    "value": "72670",
    "label": "Ponca - AR 72670",
    "county": "Newton County",
    "state": "AR",
    "city": "Ponca"
  },
  {
    "value": "72675",
    "label": "Saint Joe - AR 72675",
    "county": "Searcy County",
    "state": "AR",
    "city": "Saint Joe"
  },
  {
    "value": "72679",
    "label": "Tilly - AR 72679",
    "county": "Pope County",
    "state": "AR",
    "city": "Tilly"
  },
  {
    "value": "72680",
    "label": "Timbo - AR 72680",
    "county": "Stone County",
    "state": "AR",
    "city": "Timbo"
  },
  {
    "value": "72682",
    "label": "Valley Springs - AR 72682",
    "county": "Marion County",
    "state": "AR",
    "city": "Valley Springs"
  },
  {
    "value": "72683",
    "label": "Vendor - AR 72683",
    "county": "Newton County",
    "state": "AR",
    "city": "Vendor"
  },
  {
    "value": "72685",
    "label": "Western Grove - AR 72685",
    "county": "Newton County",
    "state": "AR",
    "city": "Western Grove"
  },
  {
    "value": "72686",
    "label": "Witts Springs - AR 72686",
    "county": "Searcy County",
    "state": "AR",
    "city": "Witts Springs"
  },
  {
    "value": "72687",
    "label": "Yellville - AR 72687",
    "county": "Marion County",
    "state": "AR",
    "city": "Yellville"
  },
  {
    "value": "72701",
    "label": "Fayetteville - AR 72701",
    "county": "Washington County",
    "state": "AR",
    "city": "Fayetteville"
  },
  {
    "value": "72703",
    "label": "Fayetteville - AR 72703",
    "county": "Washington County",
    "state": "AR",
    "city": "Fayetteville"
  },
  {
    "value": "72704",
    "label": "Fayetteville - AR 72704",
    "county": "Washington County",
    "state": "AR",
    "city": "Fayetteville"
  },
  {
    "value": "72712",
    "label": "Bentonville - AR 72712",
    "county": "Benton County",
    "state": "AR",
    "city": "Bentonville"
  },
  {
    "value": "72713",
    "label": "Bentonville - AR 72713",
    "county": "Benton County",
    "state": "AR",
    "city": "Bentonville"
  },
  {
    "value": "72714",
    "label": "Bella Vista - AR 72714",
    "county": "Benton County",
    "state": "AR",
    "city": "Bella Vista"
  },
  {
    "value": "72715",
    "label": "Bella Vista - AR 72715",
    "county": "Benton County",
    "state": "AR",
    "city": "Bella Vista"
  },
  {
    "value": "72717",
    "label": "Canehill - AR 72717",
    "county": "Washington County",
    "state": "AR",
    "city": "Canehill"
  },
  {
    "value": "72718",
    "label": "Cave Springs - AR 72718",
    "county": "Benton County",
    "state": "AR",
    "city": "Cave Springs"
  },
  {
    "value": "72719",
    "label": "Centerton - AR 72719",
    "county": "Benton County",
    "state": "AR",
    "city": "Centerton"
  },
  {
    "value": "72721",
    "label": "Combs - AR 72721",
    "county": "Madison County",
    "state": "AR",
    "city": "Combs"
  },
  {
    "value": "72722",
    "label": "Decatur - AR 72722",
    "county": "Benton County",
    "state": "AR",
    "city": "Decatur"
  },
  {
    "value": "72727",
    "label": "Elkins - AR 72727",
    "county": "Washington County",
    "state": "AR",
    "city": "Elkins"
  },
  {
    "value": "72729",
    "label": "Evansville - AR 72729",
    "county": "Washington County",
    "state": "AR",
    "city": "Evansville"
  },
  {
    "value": "72730",
    "label": "Farmington - AR 72730",
    "county": "Washington County",
    "state": "AR",
    "city": "Farmington"
  },
  {
    "value": "72732",
    "label": "Garfield - AR 72732",
    "county": "Benton County",
    "state": "AR",
    "city": "Garfield"
  },
  {
    "value": "72734",
    "label": "Gentry - AR 72734",
    "county": "Benton County",
    "state": "AR",
    "city": "Gentry"
  },
  {
    "value": "72736",
    "label": "Gravette - AR 72736",
    "county": "Benton County",
    "state": "AR",
    "city": "Gravette"
  },
  {
    "value": "72738",
    "label": "Hindsville - AR 72738",
    "county": "Madison County",
    "state": "AR",
    "city": "Hindsville"
  },
  {
    "value": "72739",
    "label": "Hiwasse - AR 72739",
    "county": "Benton County",
    "state": "AR",
    "city": "Hiwasse"
  },
  {
    "value": "72740",
    "label": "Huntsville - AR 72740",
    "county": "Madison County",
    "state": "AR",
    "city": "Huntsville"
  },
  {
    "value": "72742",
    "label": "Kingston - AR 72742",
    "county": "Madison County",
    "state": "AR",
    "city": "Kingston"
  },
  {
    "value": "72744",
    "label": "Lincoln - AR 72744",
    "county": "Washington County",
    "state": "AR",
    "city": "Lincoln"
  },
  {
    "value": "72745",
    "label": "Lowell - AR 72745",
    "county": "Benton County",
    "state": "AR",
    "city": "Lowell"
  },
  {
    "value": "72747",
    "label": "Maysville - AR 72747",
    "county": "Benton County",
    "state": "AR",
    "city": "Maysville"
  },
  {
    "value": "72749",
    "label": "Morrow - AR 72749",
    "county": "Washington County",
    "state": "AR",
    "city": "Morrow"
  },
  {
    "value": "72751",
    "label": "Pea Ridge - AR 72751",
    "county": "Benton County",
    "state": "AR",
    "city": "Pea Ridge"
  },
  {
    "value": "72752",
    "label": "Pettigrew - AR 72752",
    "county": "Madison County",
    "state": "AR",
    "city": "Pettigrew"
  },
  {
    "value": "72753",
    "label": "Prairie Grove - AR 72753",
    "county": "Washington County",
    "state": "AR",
    "city": "Prairie Grove"
  },
  {
    "value": "72756",
    "label": "Rogers - AR 72756",
    "county": "Benton County",
    "state": "AR",
    "city": "Rogers"
  },
  {
    "value": "72758",
    "label": "Rogers - AR 72758",
    "county": "Benton County",
    "state": "AR",
    "city": "Rogers"
  },
  {
    "value": "72760",
    "label": "Saint Paul - AR 72760",
    "county": "Madison County",
    "state": "AR",
    "city": "Saint Paul"
  },
  {
    "value": "72761",
    "label": "Siloam Springs - AR 72761",
    "county": "Benton County",
    "state": "AR",
    "city": "Siloam Springs"
  },
  {
    "value": "72762",
    "label": "Springdale - AR 72762",
    "county": "Washington County",
    "state": "AR",
    "city": "Springdale"
  },
  {
    "value": "72764",
    "label": "Springdale - AR 72764",
    "county": "Washington County",
    "state": "AR",
    "city": "Springdale"
  },
  {
    "value": "72768",
    "label": "Sulphur Springs - AR 72768",
    "county": "Benton County",
    "state": "AR",
    "city": "Sulphur Springs"
  },
  {
    "value": "72769",
    "label": "Summers - AR 72769",
    "county": "Washington County",
    "state": "AR",
    "city": "Summers"
  },
  {
    "value": "72773",
    "label": "Wesley - AR 72773",
    "county": "Madison County",
    "state": "AR",
    "city": "Wesley"
  },
  {
    "value": "72774",
    "label": "West Fork - AR 72774",
    "county": "Washington County",
    "state": "AR",
    "city": "West Fork"
  },
  {
    "value": "72776",
    "label": "Witter - AR 72776",
    "county": "Madison County",
    "state": "AR",
    "city": "Witter"
  },
  {
    "value": "72801",
    "label": "Russellville - AR 72801",
    "county": "Pope County",
    "state": "AR",
    "city": "Russellville"
  },
  {
    "value": "72802",
    "label": "Russellville - AR 72802",
    "county": "Pope County",
    "state": "AR",
    "city": "Russellville"
  },
  {
    "value": "72821",
    "label": "Altus - AR 72821",
    "county": "Franklin County",
    "state": "AR",
    "city": "Altus"
  },
  {
    "value": "72823",
    "label": "Atkins - AR 72823",
    "county": "Pope County",
    "state": "AR",
    "city": "Atkins"
  },
  {
    "value": "72824",
    "label": "Belleville - AR 72824",
    "county": "Yell County",
    "state": "AR",
    "city": "Belleville"
  },
  {
    "value": "72827",
    "label": "Bluffton - AR 72827",
    "county": "Yell County",
    "state": "AR",
    "city": "Bluffton"
  },
  {
    "value": "72828",
    "label": "Briggsville - AR 72828",
    "county": "Yell County",
    "state": "AR",
    "city": "Briggsville"
  },
  {
    "value": "72830",
    "label": "Clarksville - AR 72830",
    "county": "Johnson County",
    "state": "AR",
    "city": "Clarksville"
  },
  {
    "value": "72832",
    "label": "Coal Hill - AR 72832",
    "county": "Johnson County",
    "state": "AR",
    "city": "Coal Hill"
  },
  {
    "value": "72833",
    "label": "Danville - AR 72833",
    "county": "Yell County",
    "state": "AR",
    "city": "Danville"
  },
  {
    "value": "72834",
    "label": "Dardanelle - AR 72834",
    "county": "Yell County",
    "state": "AR",
    "city": "Dardanelle"
  },
  {
    "value": "72835",
    "label": "Delaware - AR 72835",
    "county": "Logan County",
    "state": "AR",
    "city": "Delaware"
  },
  {
    "value": "72837",
    "label": "Dover - AR 72837",
    "county": "Pope County",
    "state": "AR",
    "city": "Dover"
  },
  {
    "value": "72838",
    "label": "Gravelly - AR 72838",
    "county": "Yell County",
    "state": "AR",
    "city": "Gravelly"
  },
  {
    "value": "72839",
    "label": "Hagarville - AR 72839",
    "county": "Johnson County",
    "state": "AR",
    "city": "Hagarville"
  },
  {
    "value": "72840",
    "label": "Hartman - AR 72840",
    "county": "Johnson County",
    "state": "AR",
    "city": "Hartman"
  },
  {
    "value": "72841",
    "label": "Harvey - AR 72841",
    "county": "Scott County",
    "state": "AR",
    "city": "Harvey"
  },
  {
    "value": "72842",
    "label": "Havana - AR 72842",
    "county": "Yell County",
    "state": "AR",
    "city": "Havana"
  },
  {
    "value": "72843",
    "label": "Hector - AR 72843",
    "county": "Pope County",
    "state": "AR",
    "city": "Hector"
  },
  {
    "value": "72845",
    "label": "Knoxville - AR 72845",
    "county": "Johnson County",
    "state": "AR",
    "city": "Knoxville"
  },
  {
    "value": "72846",
    "label": "Lamar - AR 72846",
    "county": "Johnson County",
    "state": "AR",
    "city": "Lamar"
  },
  {
    "value": "72847",
    "label": "London - AR 72847",
    "county": "Pope County",
    "state": "AR",
    "city": "London"
  },
  {
    "value": "72851",
    "label": "New Blaine - AR 72851",
    "county": "Logan County",
    "state": "AR",
    "city": "New Blaine"
  },
  {
    "value": "72852",
    "label": "Oark - AR 72852",
    "county": "Johnson County",
    "state": "AR",
    "city": "Oark"
  },
  {
    "value": "72853",
    "label": "Ola - AR 72853",
    "county": "Yell County",
    "state": "AR",
    "city": "Ola"
  },
  {
    "value": "72854",
    "label": "Ozone - AR 72854",
    "county": "Johnson County",
    "state": "AR",
    "city": "Ozone"
  },
  {
    "value": "72855",
    "label": "Paris - AR 72855",
    "county": "Logan County",
    "state": "AR",
    "city": "Paris"
  },
  {
    "value": "72856",
    "label": "Pelsor - AR 72856",
    "county": "Newton County",
    "state": "AR",
    "city": "Pelsor"
  },
  {
    "value": "72857",
    "label": "Plainview - AR 72857",
    "county": "Yell County",
    "state": "AR",
    "city": "Plainview"
  },
  {
    "value": "72858",
    "label": "Pottsville - AR 72858",
    "county": "Pope County",
    "state": "AR",
    "city": "Pottsville"
  },
  {
    "value": "72860",
    "label": "Rover - AR 72860",
    "county": "Yell County",
    "state": "AR",
    "city": "Rover"
  },
  {
    "value": "72863",
    "label": "Scranton - AR 72863",
    "county": "Logan County",
    "state": "AR",
    "city": "Scranton"
  },
  {
    "value": "72865",
    "label": "Subiaco - AR 72865",
    "county": "Logan County",
    "state": "AR",
    "city": "Subiaco"
  },
  {
    "value": "72901",
    "label": "Fort Smith - AR 72901",
    "county": "Sebastian County",
    "state": "AR",
    "city": "Fort Smith"
  },
  {
    "value": "72903",
    "label": "Fort Smith - AR 72903",
    "county": "Sebastian County",
    "state": "AR",
    "city": "Fort Smith"
  },
  {
    "value": "72904",
    "label": "Fort Smith - AR 72904",
    "county": "Sebastian County",
    "state": "AR",
    "city": "Fort Smith"
  },
  {
    "value": "72908",
    "label": "Fort Smith - AR 72908",
    "county": "Sebastian County",
    "state": "AR",
    "city": "Fort Smith"
  },
  {
    "value": "72916",
    "label": "Fort Smith - AR 72916",
    "county": "Sebastian County",
    "state": "AR",
    "city": "Fort Smith"
  },
  {
    "value": "72921",
    "label": "Alma - AR 72921",
    "county": "Crawford County",
    "state": "AR",
    "city": "Alma"
  },
  {
    "value": "72923",
    "label": "Barling - AR 72923",
    "county": "Sebastian County",
    "state": "AR",
    "city": "Barling"
  },
  {
    "value": "72926",
    "label": "Boles - AR 72926",
    "county": "Scott County",
    "state": "AR",
    "city": "Boles"
  },
  {
    "value": "72927",
    "label": "Booneville - AR 72927",
    "county": "Logan County",
    "state": "AR",
    "city": "Booneville"
  },
  {
    "value": "72928",
    "label": "Branch - AR 72928",
    "county": "Franklin County",
    "state": "AR",
    "city": "Branch"
  },
  {
    "value": "72930",
    "label": "Cecil - AR 72930",
    "county": "Franklin County",
    "state": "AR",
    "city": "Cecil"
  },
  {
    "value": "72932",
    "label": "Cedarville - AR 72932",
    "county": "Crawford County",
    "state": "AR",
    "city": "Cedarville"
  },
  {
    "value": "72933",
    "label": "Charleston - AR 72933",
    "county": "Franklin County",
    "state": "AR",
    "city": "Charleston"
  },
  {
    "value": "72934",
    "label": "Chester - AR 72934",
    "county": "Crawford County",
    "state": "AR",
    "city": "Chester"
  },
  {
    "value": "72936",
    "label": "Greenwood - AR 72936",
    "county": "Sebastian County",
    "state": "AR",
    "city": "Greenwood"
  },
  {
    "value": "72937",
    "label": "Hackett - AR 72937",
    "county": "Sebastian County",
    "state": "AR",
    "city": "Hackett"
  },
  {
    "value": "72938",
    "label": "Hartford - AR 72938",
    "county": "Sebastian County",
    "state": "AR",
    "city": "Hartford"
  },
  {
    "value": "72940",
    "label": "Huntington - AR 72940",
    "county": "Sebastian County",
    "state": "AR",
    "city": "Huntington"
  },
  {
    "value": "72941",
    "label": "Lavaca - AR 72941",
    "county": "Sebastian County",
    "state": "AR",
    "city": "Lavaca"
  },
  {
    "value": "72943",
    "label": "Magazine - AR 72943",
    "county": "Logan County",
    "state": "AR",
    "city": "Magazine"
  },
  {
    "value": "72944",
    "label": "Mansfield - AR 72944",
    "county": "Sebastian County",
    "state": "AR",
    "city": "Mansfield"
  },
  {
    "value": "72946",
    "label": "Mountainburg - AR 72946",
    "county": "Crawford County",
    "state": "AR",
    "city": "Mountainburg"
  },
  {
    "value": "72947",
    "label": "Mulberry - AR 72947",
    "county": "Crawford County",
    "state": "AR",
    "city": "Mulberry"
  },
  {
    "value": "72948",
    "label": "Natural Dam - AR 72948",
    "county": "Crawford County",
    "state": "AR",
    "city": "Natural Dam"
  },
  {
    "value": "72949",
    "label": "Ozark - AR 72949",
    "county": "Franklin County",
    "state": "AR",
    "city": "Ozark"
  },
  {
    "value": "72950",
    "label": "Parks - AR 72950",
    "county": "Scott County",
    "state": "AR",
    "city": "Parks"
  },
  {
    "value": "72951",
    "label": "Ratcliff - AR 72951",
    "county": "Logan County",
    "state": "AR",
    "city": "Ratcliff"
  },
  {
    "value": "72952",
    "label": "Rudy - AR 72952",
    "county": "Crawford County",
    "state": "AR",
    "city": "Rudy"
  },
  {
    "value": "72955",
    "label": "Uniontown - AR 72955",
    "county": "Crawford County",
    "state": "AR",
    "city": "Uniontown"
  },
  {
    "value": "72956",
    "label": "Van Buren - AR 72956",
    "county": "Crawford County",
    "state": "AR",
    "city": "Van Buren"
  },
  {
    "value": "72958",
    "label": "Waldron - AR 72958",
    "county": "Scott County",
    "state": "AR",
    "city": "Waldron"
  },
  {
    "value": "72959",
    "label": "Winslow - AR 72959",
    "county": "Washington County",
    "state": "AR",
    "city": "Winslow"
  },
  {
    "value": "90001",
    "label": "Los Angeles - CA 90001",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90002",
    "label": "Los Angeles - CA 90002",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90003",
    "label": "Los Angeles - CA 90003",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90004",
    "label": "Los Angeles - CA 90004",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90005",
    "label": "Los Angeles - CA 90005",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90006",
    "label": "Los Angeles - CA 90006",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90007",
    "label": "Los Angeles - CA 90007",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90008",
    "label": "Los Angeles - CA 90008",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90010",
    "label": "Los Angeles - CA 90010",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90011",
    "label": "Los Angeles - CA 90011",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90012",
    "label": "Los Angeles - CA 90012",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90013",
    "label": "Los Angeles - CA 90013",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90014",
    "label": "Los Angeles - CA 90014",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90015",
    "label": "Los Angeles - CA 90015",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90016",
    "label": "Los Angeles - CA 90016",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90017",
    "label": "Los Angeles - CA 90017",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90018",
    "label": "Los Angeles - CA 90018",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90019",
    "label": "Los Angeles - CA 90019",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90020",
    "label": "Los Angeles - CA 90020",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90021",
    "label": "Los Angeles - CA 90021",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90022",
    "label": "Los Angeles - CA 90022",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90023",
    "label": "Los Angeles - CA 90023",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90024",
    "label": "Los Angeles - CA 90024",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90025",
    "label": "Los Angeles - CA 90025",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90026",
    "label": "Los Angeles - CA 90026",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90027",
    "label": "Los Angeles - CA 90027",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90028",
    "label": "Los Angeles - CA 90028",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90029",
    "label": "Los Angeles - CA 90029",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90031",
    "label": "Los Angeles - CA 90031",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90032",
    "label": "Los Angeles - CA 90032",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90033",
    "label": "Los Angeles - CA 90033",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90034",
    "label": "Los Angeles - CA 90034",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90035",
    "label": "Los Angeles - CA 90035",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90036",
    "label": "Los Angeles - CA 90036",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90037",
    "label": "Los Angeles - CA 90037",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90038",
    "label": "Los Angeles - CA 90038",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90039",
    "label": "Los Angeles - CA 90039",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90040",
    "label": "Los Angeles - CA 90040",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90041",
    "label": "Los Angeles - CA 90041",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90042",
    "label": "Los Angeles - CA 90042",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90043",
    "label": "Los Angeles - CA 90043",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90044",
    "label": "Los Angeles - CA 90044",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90045",
    "label": "Los Angeles - CA 90045",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90046",
    "label": "Los Angeles - CA 90046",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90047",
    "label": "Los Angeles - CA 90047",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90048",
    "label": "Los Angeles - CA 90048",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90049",
    "label": "Los Angeles - CA 90049",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90056",
    "label": "Los Angeles - CA 90056",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90057",
    "label": "Los Angeles - CA 90057",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90058",
    "label": "Los Angeles - CA 90058",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90059",
    "label": "Los Angeles - CA 90059",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90061",
    "label": "Los Angeles - CA 90061",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90062",
    "label": "Los Angeles - CA 90062",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90063",
    "label": "Los Angeles - CA 90063",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90064",
    "label": "Los Angeles - CA 90064",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90065",
    "label": "Los Angeles - CA 90065",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90066",
    "label": "Los Angeles - CA 90066",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90067",
    "label": "Los Angeles - CA 90067",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90068",
    "label": "Los Angeles - CA 90068",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90069",
    "label": "West Hollywood - CA 90069",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "West Hollywood"
  },
  {
    "value": "90071",
    "label": "Los Angeles - CA 90071",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90077",
    "label": "Los Angeles - CA 90077",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90079",
    "label": "Los Angeles - CA 90079",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90094",
    "label": "Playa Vista - CA 90094",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Playa Vista"
  },
  {
    "value": "90101",
    "label": "Los Angeles - CA 90101",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90134",
    "label": "Los Angeles - CA 90134",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Los Angeles"
  },
  {
    "value": "90201",
    "label": "Bell Gardens - CA 90201",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Bell Gardens"
  },
  {
    "value": "90210",
    "label": "Beverly Hills - CA 90210",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Beverly Hills"
  },
  {
    "value": "90211",
    "label": "Beverly Hills - CA 90211",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Beverly Hills"
  },
  {
    "value": "90212",
    "label": "Beverly Hills - CA 90212",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Beverly Hills"
  },
  {
    "value": "90220",
    "label": "Compton - CA 90220",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Compton"
  },
  {
    "value": "90221",
    "label": "Compton - CA 90221",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Compton"
  },
  {
    "value": "90222",
    "label": "Compton - CA 90222",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Compton"
  },
  {
    "value": "90230",
    "label": "Culver City - CA 90230",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Culver City"
  },
  {
    "value": "90232",
    "label": "Culver City - CA 90232",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Culver City"
  },
  {
    "value": "90240",
    "label": "Downey - CA 90240",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Downey"
  },
  {
    "value": "90241",
    "label": "Downey - CA 90241",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Downey"
  },
  {
    "value": "90242",
    "label": "Downey - CA 90242",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Downey"
  },
  {
    "value": "90245",
    "label": "El Segundo - CA 90245",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "El Segundo"
  },
  {
    "value": "90247",
    "label": "Gardena - CA 90247",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Gardena"
  },
  {
    "value": "90248",
    "label": "Gardena - CA 90248",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Gardena"
  },
  {
    "value": "90249",
    "label": "Gardena - CA 90249",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Gardena"
  },
  {
    "value": "90250",
    "label": "Hawthorne - CA 90250",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Hawthorne"
  },
  {
    "value": "90254",
    "label": "Hermosa Beach - CA 90254",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Hermosa Beach"
  },
  {
    "value": "90255",
    "label": "Huntington Park - CA 90255",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Huntington Park"
  },
  {
    "value": "90260",
    "label": "Lawndale - CA 90260",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Lawndale"
  },
  {
    "value": "90261",
    "label": "Lawndale - CA 90261",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Lawndale"
  },
  {
    "value": "90262",
    "label": "Lynwood - CA 90262",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Lynwood"
  },
  {
    "value": "90265",
    "label": "Malibu - CA 90265",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Malibu"
  },
  {
    "value": "90266",
    "label": "Manhattan Beach - CA 90266",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Manhattan Beach"
  },
  {
    "value": "90270",
    "label": "Maywood - CA 90270",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Maywood"
  },
  {
    "value": "90272",
    "label": "Pacific Palisades - CA 90272",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Pacific Palisades"
  },
  {
    "value": "90274",
    "label": "Palos Verdes Peninsula - CA 90274",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Palos Verdes Peninsula"
  },
  {
    "value": "90275",
    "label": "Rancho Palos Verdes - CA 90275",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Rancho Palos Verdes"
  },
  {
    "value": "90277",
    "label": "Redondo Beach - CA 90277",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Redondo Beach"
  },
  {
    "value": "90278",
    "label": "Redondo Beach - CA 90278",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Redondo Beach"
  },
  {
    "value": "90280",
    "label": "South Gate - CA 90280",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "South Gate"
  },
  {
    "value": "90290",
    "label": "Topanga - CA 90290",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Topanga"
  },
  {
    "value": "90291",
    "label": "Venice - CA 90291",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Venice"
  },
  {
    "value": "90292",
    "label": "Marina Del Rey - CA 90292",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Marina Del Rey"
  },
  {
    "value": "90293",
    "label": "Playa Del Rey - CA 90293",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Playa Del Rey"
  },
  {
    "value": "90301",
    "label": "Inglewood - CA 90301",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Inglewood"
  },
  {
    "value": "90302",
    "label": "Inglewood - CA 90302",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Inglewood"
  },
  {
    "value": "90303",
    "label": "Inglewood - CA 90303",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Inglewood"
  },
  {
    "value": "90304",
    "label": "Inglewood - CA 90304",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Inglewood"
  },
  {
    "value": "90305",
    "label": "Inglewood - CA 90305",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Inglewood"
  },
  {
    "value": "90311",
    "label": "Inglewood - CA 90311",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Inglewood"
  },
  {
    "value": "90401",
    "label": "Santa Monica - CA 90401",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Santa Monica"
  },
  {
    "value": "90402",
    "label": "Santa Monica - CA 90402",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Santa Monica"
  },
  {
    "value": "90403",
    "label": "Santa Monica - CA 90403",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Santa Monica"
  },
  {
    "value": "90404",
    "label": "Santa Monica - CA 90404",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Santa Monica"
  },
  {
    "value": "90405",
    "label": "Santa Monica - CA 90405",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Santa Monica"
  },
  {
    "value": "90501",
    "label": "Torrance - CA 90501",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Torrance"
  },
  {
    "value": "90502",
    "label": "Torrance - CA 90502",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Torrance"
  },
  {
    "value": "90503",
    "label": "Torrance - CA 90503",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Torrance"
  },
  {
    "value": "90504",
    "label": "Torrance - CA 90504",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Torrance"
  },
  {
    "value": "90505",
    "label": "Torrance - CA 90505",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Torrance"
  },
  {
    "value": "90506",
    "label": "Torrance - CA 90506",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Torrance"
  },
  {
    "value": "90601",
    "label": "Whittier - CA 90601",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Whittier"
  },
  {
    "value": "90602",
    "label": "Whittier - CA 90602",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Whittier"
  },
  {
    "value": "90603",
    "label": "Whittier - CA 90603",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Whittier"
  },
  {
    "value": "90604",
    "label": "Whittier - CA 90604",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Whittier"
  },
  {
    "value": "90605",
    "label": "Whittier - CA 90605",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Whittier"
  },
  {
    "value": "90606",
    "label": "Whittier - CA 90606",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Whittier"
  },
  {
    "value": "90620",
    "label": "Buena Park - CA 90620",
    "county": "Orange County",
    "state": "CA",
    "city": "Buena Park"
  },
  {
    "value": "90621",
    "label": "Buena Park - CA 90621",
    "county": "Orange County",
    "state": "CA",
    "city": "Buena Park"
  },
  {
    "value": "90623",
    "label": "La Palma - CA 90623",
    "county": "Orange County",
    "state": "CA",
    "city": "La Palma"
  },
  {
    "value": "90630",
    "label": "Cypress - CA 90630",
    "county": "Orange County",
    "state": "CA",
    "city": "Cypress"
  },
  {
    "value": "90631",
    "label": "La Habra - CA 90631",
    "county": "Orange County",
    "state": "CA",
    "city": "La Habra"
  },
  {
    "value": "90638",
    "label": "La Mirada - CA 90638",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "La Mirada"
  },
  {
    "value": "90640",
    "label": "Montebello - CA 90640",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Montebello"
  },
  {
    "value": "90650",
    "label": "Norwalk - CA 90650",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Norwalk"
  },
  {
    "value": "90660",
    "label": "Pico Rivera - CA 90660",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Pico Rivera"
  },
  {
    "value": "90670",
    "label": "Santa Fe Springs - CA 90670",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Santa Fe Springs"
  },
  {
    "value": "90680",
    "label": "Stanton - CA 90680",
    "county": "Orange County",
    "state": "CA",
    "city": "Stanton"
  },
  {
    "value": "90701",
    "label": "Artesia - CA 90701",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Artesia"
  },
  {
    "value": "90703",
    "label": "Cerritos - CA 90703",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Cerritos"
  },
  {
    "value": "90706",
    "label": "Bellflower - CA 90706",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Bellflower"
  },
  {
    "value": "90710",
    "label": "Harbor City - CA 90710",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Harbor City"
  },
  {
    "value": "90712",
    "label": "Lakewood - CA 90712",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Lakewood"
  },
  {
    "value": "90713",
    "label": "Lakewood - CA 90713",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Lakewood"
  },
  {
    "value": "90715",
    "label": "Lakewood - CA 90715",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Lakewood"
  },
  {
    "value": "90716",
    "label": "Hawaiian Gardens - CA 90716",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Hawaiian Gardens"
  },
  {
    "value": "90717",
    "label": "Lomita - CA 90717",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Lomita"
  },
  {
    "value": "90720",
    "label": "Los Alamitos - CA 90720",
    "county": "Orange County",
    "state": "CA",
    "city": "Los Alamitos"
  },
  {
    "value": "90723",
    "label": "Paramount - CA 90723",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Paramount"
  },
  {
    "value": "90731",
    "label": "San Pedro - CA 90731",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "San Pedro"
  },
  {
    "value": "90732",
    "label": "San Pedro - CA 90732",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "San Pedro"
  },
  {
    "value": "90740",
    "label": "Seal Beach - CA 90740",
    "county": "Orange County",
    "state": "CA",
    "city": "Seal Beach"
  },
  {
    "value": "90744",
    "label": "Wilmington - CA 90744",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Wilmington"
  },
  {
    "value": "90745",
    "label": "Carson - CA 90745",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Carson"
  },
  {
    "value": "90746",
    "label": "Carson - CA 90746",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Carson"
  },
  {
    "value": "90755",
    "label": "Signal Hill - CA 90755",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Signal Hill"
  },
  {
    "value": "90802",
    "label": "Long Beach - CA 90802",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Long Beach"
  },
  {
    "value": "90803",
    "label": "Long Beach - CA 90803",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Long Beach"
  },
  {
    "value": "90804",
    "label": "Long Beach - CA 90804",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Long Beach"
  },
  {
    "value": "90805",
    "label": "Long Beach - CA 90805",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Long Beach"
  },
  {
    "value": "90806",
    "label": "Long Beach - CA 90806",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Long Beach"
  },
  {
    "value": "90807",
    "label": "Long Beach - CA 90807",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Long Beach"
  },
  {
    "value": "90808",
    "label": "Long Beach - CA 90808",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Long Beach"
  },
  {
    "value": "90810",
    "label": "Long Beach - CA 90810",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Long Beach"
  },
  {
    "value": "90813",
    "label": "Long Beach - CA 90813",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Long Beach"
  },
  {
    "value": "90814",
    "label": "Long Beach - CA 90814",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Long Beach"
  },
  {
    "value": "90815",
    "label": "Long Beach - CA 90815",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Long Beach"
  },
  {
    "value": "90822",
    "label": "Long Beach - CA 90822",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Long Beach"
  },
  {
    "value": "90831",
    "label": "Long Beach - CA 90831",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Long Beach"
  },
  {
    "value": "90833",
    "label": "Long Beach - CA 90833",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Long Beach"
  },
  {
    "value": "90834",
    "label": "Long Beach - CA 90834",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Long Beach"
  },
  {
    "value": "90835",
    "label": "Long Beach - CA 90835",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Long Beach"
  },
  {
    "value": "91001",
    "label": "Altadena - CA 91001",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Altadena"
  },
  {
    "value": "91006",
    "label": "Arcadia - CA 91006",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Arcadia"
  },
  {
    "value": "91007",
    "label": "Arcadia - CA 91007",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Arcadia"
  },
  {
    "value": "91008",
    "label": "Duarte - CA 91008",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Duarte"
  },
  {
    "value": "91010",
    "label": "Duarte - CA 91010",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Duarte"
  },
  {
    "value": "91011",
    "label": "La Canada Flintridge - CA 91011",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "La Canada Flintridge"
  },
  {
    "value": "91016",
    "label": "Monrovia - CA 91016",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Monrovia"
  },
  {
    "value": "91020",
    "label": "Montrose - CA 91020",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Montrose"
  },
  {
    "value": "91024",
    "label": "Sierra Madre - CA 91024",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Sierra Madre"
  },
  {
    "value": "91030",
    "label": "South Pasadena - CA 91030",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "South Pasadena"
  },
  {
    "value": "91040",
    "label": "Sunland - CA 91040",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Sunland"
  },
  {
    "value": "91042",
    "label": "Tujunga - CA 91042",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Tujunga"
  },
  {
    "value": "91101",
    "label": "Pasadena - CA 91101",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Pasadena"
  },
  {
    "value": "91103",
    "label": "Pasadena - CA 91103",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Pasadena"
  },
  {
    "value": "91104",
    "label": "Pasadena - CA 91104",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Pasadena"
  },
  {
    "value": "91105",
    "label": "Pasadena - CA 91105",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Pasadena"
  },
  {
    "value": "91106",
    "label": "Pasadena - CA 91106",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Pasadena"
  },
  {
    "value": "91107",
    "label": "Pasadena - CA 91107",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Pasadena"
  },
  {
    "value": "91108",
    "label": "San Marino - CA 91108",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "San Marino"
  },
  {
    "value": "91201",
    "label": "Glendale - CA 91201",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Glendale"
  },
  {
    "value": "91202",
    "label": "Glendale - CA 91202",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Glendale"
  },
  {
    "value": "91203",
    "label": "Glendale - CA 91203",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Glendale"
  },
  {
    "value": "91204",
    "label": "Glendale - CA 91204",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Glendale"
  },
  {
    "value": "91205",
    "label": "Glendale - CA 91205",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Glendale"
  },
  {
    "value": "91206",
    "label": "Glendale - CA 91206",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Glendale"
  },
  {
    "value": "91207",
    "label": "Glendale - CA 91207",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Glendale"
  },
  {
    "value": "91208",
    "label": "Glendale - CA 91208",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Glendale"
  },
  {
    "value": "91210",
    "label": "Glendale - CA 91210",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Glendale"
  },
  {
    "value": "91214",
    "label": "La Crescenta - CA 91214",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "La Crescenta"
  },
  {
    "value": "91301",
    "label": "Agoura Hills - CA 91301",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Agoura Hills"
  },
  {
    "value": "91302",
    "label": "Calabasas - CA 91302",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Calabasas"
  },
  {
    "value": "91303",
    "label": "Canoga Park - CA 91303",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Canoga Park"
  },
  {
    "value": "91304",
    "label": "Canoga Park - CA 91304",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Canoga Park"
  },
  {
    "value": "91306",
    "label": "Winnetka - CA 91306",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Winnetka"
  },
  {
    "value": "91307",
    "label": "West Hills - CA 91307",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "West Hills"
  },
  {
    "value": "91311",
    "label": "Chatsworth - CA 91311",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Chatsworth"
  },
  {
    "value": "91316",
    "label": "Encino - CA 91316",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Encino"
  },
  {
    "value": "91320",
    "label": "Newbury Park - CA 91320",
    "county": "Ventura County",
    "state": "CA",
    "city": "Newbury Park"
  },
  {
    "value": "91321",
    "label": "Newhall - CA 91321",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Newhall"
  },
  {
    "value": "91324",
    "label": "Northridge - CA 91324",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Northridge"
  },
  {
    "value": "91325",
    "label": "Northridge - CA 91325",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Northridge"
  },
  {
    "value": "91326",
    "label": "Porter Ranch - CA 91326",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Porter Ranch"
  },
  {
    "value": "91331",
    "label": "Pacoima - CA 91331",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Pacoima"
  },
  {
    "value": "91335",
    "label": "Reseda - CA 91335",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Reseda"
  },
  {
    "value": "91340",
    "label": "San Fernando - CA 91340",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "San Fernando"
  },
  {
    "value": "91342",
    "label": "Sylmar - CA 91342",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Sylmar"
  },
  {
    "value": "91343",
    "label": "North Hills - CA 91343",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "North Hills"
  },
  {
    "value": "91344",
    "label": "Granada Hills - CA 91344",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Granada Hills"
  },
  {
    "value": "91345",
    "label": "Mission Hills - CA 91345",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Mission Hills"
  },
  {
    "value": "91350",
    "label": "Santa Clarita - CA 91350",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Santa Clarita"
  },
  {
    "value": "91351",
    "label": "Canyon Country - CA 91351",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Canyon Country"
  },
  {
    "value": "91352",
    "label": "Sun Valley - CA 91352",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Sun Valley"
  },
  {
    "value": "91354",
    "label": "Valencia - CA 91354",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Valencia"
  },
  {
    "value": "91355",
    "label": "Valencia - CA 91355",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Valencia"
  },
  {
    "value": "91356",
    "label": "Tarzana - CA 91356",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Tarzana"
  },
  {
    "value": "91360",
    "label": "Thousand Oaks - CA 91360",
    "county": "Ventura County",
    "state": "CA",
    "city": "Thousand Oaks"
  },
  {
    "value": "91361",
    "label": "Westlake Village - CA 91361",
    "county": "Ventura County",
    "state": "CA",
    "city": "Westlake Village"
  },
  {
    "value": "91362",
    "label": "Thousand Oaks - CA 91362",
    "county": "Ventura County",
    "state": "CA",
    "city": "Thousand Oaks"
  },
  {
    "value": "91364",
    "label": "Woodland Hills - CA 91364",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Woodland Hills"
  },
  {
    "value": "91367",
    "label": "Woodland Hills - CA 91367",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Woodland Hills"
  },
  {
    "value": "91377",
    "label": "Oak Park - CA 91377",
    "county": "Ventura County",
    "state": "CA",
    "city": "Oak Park"
  },
  {
    "value": "91381",
    "label": "Stevenson Ranch - CA 91381",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Stevenson Ranch"
  },
  {
    "value": "91382",
    "label": "Santa Clarita - CA 91382",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Santa Clarita"
  },
  {
    "value": "91383",
    "label": "Santa Clarita - CA 91383",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Santa Clarita"
  },
  {
    "value": "91384",
    "label": "Castaic - CA 91384",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Castaic"
  },
  {
    "value": "91387",
    "label": "Canyon Country - CA 91387",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Canyon Country"
  },
  {
    "value": "91390",
    "label": "Santa Clarita - CA 91390",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Santa Clarita"
  },
  {
    "value": "91401",
    "label": "Van Nuys - CA 91401",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Van Nuys"
  },
  {
    "value": "91402",
    "label": "Panorama City - CA 91402",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Panorama City"
  },
  {
    "value": "91403",
    "label": "Sherman Oaks - CA 91403",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Sherman Oaks"
  },
  {
    "value": "91405",
    "label": "Van Nuys - CA 91405",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Van Nuys"
  },
  {
    "value": "91406",
    "label": "Van Nuys - CA 91406",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Van Nuys"
  },
  {
    "value": "91411",
    "label": "Van Nuys - CA 91411",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Van Nuys"
  },
  {
    "value": "91423",
    "label": "Sherman Oaks - CA 91423",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Sherman Oaks"
  },
  {
    "value": "91436",
    "label": "Encino - CA 91436",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Encino"
  },
  {
    "value": "91501",
    "label": "Burbank - CA 91501",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Burbank"
  },
  {
    "value": "91502",
    "label": "Burbank - CA 91502",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Burbank"
  },
  {
    "value": "91504",
    "label": "Burbank - CA 91504",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Burbank"
  },
  {
    "value": "91505",
    "label": "Burbank - CA 91505",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Burbank"
  },
  {
    "value": "91506",
    "label": "Burbank - CA 91506",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Burbank"
  },
  {
    "value": "91601",
    "label": "North Hollywood - CA 91601",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "North Hollywood"
  },
  {
    "value": "91602",
    "label": "North Hollywood - CA 91602",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "North Hollywood"
  },
  {
    "value": "91604",
    "label": "Studio City - CA 91604",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Studio City"
  },
  {
    "value": "91605",
    "label": "North Hollywood - CA 91605",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "North Hollywood"
  },
  {
    "value": "91606",
    "label": "North Hollywood - CA 91606",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "North Hollywood"
  },
  {
    "value": "91607",
    "label": "Valley Village - CA 91607",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Valley Village"
  },
  {
    "value": "91608",
    "label": "Universal City - CA 91608",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Universal City"
  },
  {
    "value": "91701",
    "label": "Rancho Cucamonga - CA 91701",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Rancho Cucamonga"
  },
  {
    "value": "91702",
    "label": "Azusa - CA 91702",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Azusa"
  },
  {
    "value": "91706",
    "label": "Baldwin Park - CA 91706",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Baldwin Park"
  },
  {
    "value": "91708",
    "label": "Chino - CA 91708",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Chino"
  },
  {
    "value": "91709",
    "label": "Chino Hills - CA 91709",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Chino Hills"
  },
  {
    "value": "91710",
    "label": "Chino - CA 91710",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Chino"
  },
  {
    "value": "91711",
    "label": "Claremont - CA 91711",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Claremont"
  },
  {
    "value": "91722",
    "label": "Covina - CA 91722",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Covina"
  },
  {
    "value": "91723",
    "label": "Covina - CA 91723",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Covina"
  },
  {
    "value": "91724",
    "label": "Covina - CA 91724",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Covina"
  },
  {
    "value": "91730",
    "label": "Rancho Cucamonga - CA 91730",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Rancho Cucamonga"
  },
  {
    "value": "91731",
    "label": "El Monte - CA 91731",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "El Monte"
  },
  {
    "value": "91732",
    "label": "El Monte - CA 91732",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "El Monte"
  },
  {
    "value": "91733",
    "label": "South El Monte - CA 91733",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "South El Monte"
  },
  {
    "value": "91737",
    "label": "Rancho Cucamonga - CA 91737",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Rancho Cucamonga"
  },
  {
    "value": "91739",
    "label": "Rancho Cucamonga - CA 91739",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Rancho Cucamonga"
  },
  {
    "value": "91740",
    "label": "Glendora - CA 91740",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Glendora"
  },
  {
    "value": "91741",
    "label": "Glendora - CA 91741",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Glendora"
  },
  {
    "value": "91744",
    "label": "La Puente - CA 91744",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "La Puente"
  },
  {
    "value": "91745",
    "label": "Hacienda Heights - CA 91745",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Hacienda Heights"
  },
  {
    "value": "91746",
    "label": "La Puente - CA 91746",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "La Puente"
  },
  {
    "value": "91748",
    "label": "Rowland Heights - CA 91748",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Rowland Heights"
  },
  {
    "value": "91750",
    "label": "La Verne - CA 91750",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "La Verne"
  },
  {
    "value": "91752",
    "label": "Mira Loma - CA 91752",
    "county": "Riverside County",
    "state": "CA",
    "city": "Mira Loma"
  },
  {
    "value": "91754",
    "label": "Monterey Park - CA 91754",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Monterey Park"
  },
  {
    "value": "91755",
    "label": "Monterey Park - CA 91755",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Monterey Park"
  },
  {
    "value": "91758",
    "label": "Ontario - CA 91758",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Ontario"
  },
  {
    "value": "91761",
    "label": "Ontario - CA 91761",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Ontario"
  },
  {
    "value": "91762",
    "label": "Ontario - CA 91762",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Ontario"
  },
  {
    "value": "91763",
    "label": "Montclair - CA 91763",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Montclair"
  },
  {
    "value": "91764",
    "label": "Ontario - CA 91764",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Ontario"
  },
  {
    "value": "91765",
    "label": "Diamond Bar - CA 91765",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Diamond Bar"
  },
  {
    "value": "91766",
    "label": "Pomona - CA 91766",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Pomona"
  },
  {
    "value": "91767",
    "label": "Pomona - CA 91767",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Pomona"
  },
  {
    "value": "91768",
    "label": "Pomona - CA 91768",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Pomona"
  },
  {
    "value": "91770",
    "label": "Rosemead - CA 91770",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Rosemead"
  },
  {
    "value": "91773",
    "label": "San Dimas - CA 91773",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "San Dimas"
  },
  {
    "value": "91775",
    "label": "San Gabriel - CA 91775",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "San Gabriel"
  },
  {
    "value": "91776",
    "label": "San Gabriel - CA 91776",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "San Gabriel"
  },
  {
    "value": "91780",
    "label": "Temple City - CA 91780",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Temple City"
  },
  {
    "value": "91784",
    "label": "Upland - CA 91784",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Upland"
  },
  {
    "value": "91786",
    "label": "Upland - CA 91786",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Upland"
  },
  {
    "value": "91789",
    "label": "Walnut - CA 91789",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Walnut"
  },
  {
    "value": "91790",
    "label": "West Covina - CA 91790",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "West Covina"
  },
  {
    "value": "91791",
    "label": "West Covina - CA 91791",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "West Covina"
  },
  {
    "value": "91792",
    "label": "West Covina - CA 91792",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "West Covina"
  },
  {
    "value": "91801",
    "label": "Alhambra - CA 91801",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Alhambra"
  },
  {
    "value": "91803",
    "label": "Alhambra - CA 91803",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Alhambra"
  },
  {
    "value": "91804",
    "label": "Alhambra - CA 91804",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Alhambra"
  },
  {
    "value": "91901",
    "label": "Alpine - CA 91901",
    "county": "San Diego County",
    "state": "CA",
    "city": "Alpine"
  },
  {
    "value": "91902",
    "label": "Bonita - CA 91902",
    "county": "San Diego County",
    "state": "CA",
    "city": "Bonita"
  },
  {
    "value": "91905",
    "label": "Boulevard - CA 91905",
    "county": "San Diego County",
    "state": "CA",
    "city": "Boulevard"
  },
  {
    "value": "91906",
    "label": "Campo - CA 91906",
    "county": "San Diego County",
    "state": "CA",
    "city": "Campo"
  },
  {
    "value": "91910",
    "label": "Chula Vista - CA 91910",
    "county": "San Diego County",
    "state": "CA",
    "city": "Chula Vista"
  },
  {
    "value": "91911",
    "label": "Chula Vista - CA 91911",
    "county": "San Diego County",
    "state": "CA",
    "city": "Chula Vista"
  },
  {
    "value": "91913",
    "label": "Chula Vista - CA 91913",
    "county": "San Diego County",
    "state": "CA",
    "city": "Chula Vista"
  },
  {
    "value": "91914",
    "label": "Chula Vista - CA 91914",
    "county": "San Diego County",
    "state": "CA",
    "city": "Chula Vista"
  },
  {
    "value": "91915",
    "label": "Chula Vista - CA 91915",
    "county": "San Diego County",
    "state": "CA",
    "city": "Chula Vista"
  },
  {
    "value": "91916",
    "label": "Descanso - CA 91916",
    "county": "San Diego County",
    "state": "CA",
    "city": "Descanso"
  },
  {
    "value": "91917",
    "label": "Dulzura - CA 91917",
    "county": "San Diego County",
    "state": "CA",
    "city": "Dulzura"
  },
  {
    "value": "91932",
    "label": "Imperial Beach - CA 91932",
    "county": "San Diego County",
    "state": "CA",
    "city": "Imperial Beach"
  },
  {
    "value": "91934",
    "label": "Jacumba - CA 91934",
    "county": "San Diego County",
    "state": "CA",
    "city": "Jacumba"
  },
  {
    "value": "91935",
    "label": "Jamul - CA 91935",
    "county": "San Diego County",
    "state": "CA",
    "city": "Jamul"
  },
  {
    "value": "91941",
    "label": "La Mesa - CA 91941",
    "county": "San Diego County",
    "state": "CA",
    "city": "La Mesa"
  },
  {
    "value": "91942",
    "label": "La Mesa - CA 91942",
    "county": "San Diego County",
    "state": "CA",
    "city": "La Mesa"
  },
  {
    "value": "91945",
    "label": "Lemon Grove - CA 91945",
    "county": "San Diego County",
    "state": "CA",
    "city": "Lemon Grove"
  },
  {
    "value": "91950",
    "label": "National City - CA 91950",
    "county": "San Diego County",
    "state": "CA",
    "city": "National City"
  },
  {
    "value": "91962",
    "label": "Pine Valley - CA 91962",
    "county": "San Diego County",
    "state": "CA",
    "city": "Pine Valley"
  },
  {
    "value": "91963",
    "label": "Potrero - CA 91963",
    "county": "San Diego County",
    "state": "CA",
    "city": "Potrero"
  },
  {
    "value": "91977",
    "label": "Spring Valley - CA 91977",
    "county": "San Diego County",
    "state": "CA",
    "city": "Spring Valley"
  },
  {
    "value": "91978",
    "label": "Spring Valley - CA 91978",
    "county": "San Diego County",
    "state": "CA",
    "city": "Spring Valley"
  },
  {
    "value": "91980",
    "label": "Tecate - CA 91980",
    "county": "San Diego County",
    "state": "CA",
    "city": "Tecate"
  },
  {
    "value": "92003",
    "label": "Bonsall - CA 92003",
    "county": "San Diego County",
    "state": "CA",
    "city": "Bonsall"
  },
  {
    "value": "92004",
    "label": "Borrego Springs - CA 92004",
    "county": "San Diego County",
    "state": "CA",
    "city": "Borrego Springs"
  },
  {
    "value": "92007",
    "label": "Cardiff By The Sea - CA 92007",
    "county": "San Diego County",
    "state": "CA",
    "city": "Cardiff By The Sea"
  },
  {
    "value": "92008",
    "label": "Carlsbad - CA 92008",
    "county": "San Diego County",
    "state": "CA",
    "city": "Carlsbad"
  },
  {
    "value": "92009",
    "label": "Carlsbad - CA 92009",
    "county": "San Diego County",
    "state": "CA",
    "city": "Carlsbad"
  },
  {
    "value": "92010",
    "label": "Carlsbad - CA 92010",
    "county": "San Diego County",
    "state": "CA",
    "city": "Carlsbad"
  },
  {
    "value": "92011",
    "label": "Carlsbad - CA 92011",
    "county": "San Diego County",
    "state": "CA",
    "city": "Carlsbad"
  },
  {
    "value": "92014",
    "label": "Del Mar - CA 92014",
    "county": "San Diego County",
    "state": "CA",
    "city": "Del Mar"
  },
  {
    "value": "92019",
    "label": "El Cajon - CA 92019",
    "county": "San Diego County",
    "state": "CA",
    "city": "El Cajon"
  },
  {
    "value": "92020",
    "label": "El Cajon - CA 92020",
    "county": "San Diego County",
    "state": "CA",
    "city": "El Cajon"
  },
  {
    "value": "92021",
    "label": "El Cajon - CA 92021",
    "county": "San Diego County",
    "state": "CA",
    "city": "El Cajon"
  },
  {
    "value": "92024",
    "label": "Encinitas - CA 92024",
    "county": "San Diego County",
    "state": "CA",
    "city": "Encinitas"
  },
  {
    "value": "92025",
    "label": "Escondido - CA 92025",
    "county": "San Diego County",
    "state": "CA",
    "city": "Escondido"
  },
  {
    "value": "92026",
    "label": "Escondido - CA 92026",
    "county": "San Diego County",
    "state": "CA",
    "city": "Escondido"
  },
  {
    "value": "92027",
    "label": "Escondido - CA 92027",
    "county": "San Diego County",
    "state": "CA",
    "city": "Escondido"
  },
  {
    "value": "92028",
    "label": "Fallbrook - CA 92028",
    "county": "San Diego County",
    "state": "CA",
    "city": "Fallbrook"
  },
  {
    "value": "92029",
    "label": "Escondido - CA 92029",
    "county": "San Diego County",
    "state": "CA",
    "city": "Escondido"
  },
  {
    "value": "92036",
    "label": "Julian - CA 92036",
    "county": "San Diego County",
    "state": "CA",
    "city": "Julian"
  },
  {
    "value": "92037",
    "label": "La Jolla - CA 92037",
    "county": "San Diego County",
    "state": "CA",
    "city": "La Jolla"
  },
  {
    "value": "92040",
    "label": "Lakeside - CA 92040",
    "county": "San Diego County",
    "state": "CA",
    "city": "Lakeside"
  },
  {
    "value": "92054",
    "label": "Oceanside - CA 92054",
    "county": "San Diego County",
    "state": "CA",
    "city": "Oceanside"
  },
  {
    "value": "92056",
    "label": "Oceanside - CA 92056",
    "county": "San Diego County",
    "state": "CA",
    "city": "Oceanside"
  },
  {
    "value": "92057",
    "label": "Oceanside - CA 92057",
    "county": "San Diego County",
    "state": "CA",
    "city": "Oceanside"
  },
  {
    "value": "92058",
    "label": "Oceanside - CA 92058",
    "county": "San Diego County",
    "state": "CA",
    "city": "Oceanside"
  },
  {
    "value": "92059",
    "label": "Pala - CA 92059",
    "county": "San Diego County",
    "state": "CA",
    "city": "Pala"
  },
  {
    "value": "92061",
    "label": "Pauma Valley - CA 92061",
    "county": "San Diego County",
    "state": "CA",
    "city": "Pauma Valley"
  },
  {
    "value": "92064",
    "label": "Poway - CA 92064",
    "county": "San Diego County",
    "state": "CA",
    "city": "Poway"
  },
  {
    "value": "92065",
    "label": "Ramona - CA 92065",
    "county": "San Diego County",
    "state": "CA",
    "city": "Ramona"
  },
  {
    "value": "92066",
    "label": "Ranchita - CA 92066",
    "county": "San Diego County",
    "state": "CA",
    "city": "Ranchita"
  },
  {
    "value": "92069",
    "label": "San Marcos - CA 92069",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Marcos"
  },
  {
    "value": "92070",
    "label": "Santa Ysabel - CA 92070",
    "county": "San Diego County",
    "state": "CA",
    "city": "Santa Ysabel"
  },
  {
    "value": "92071",
    "label": "Santee - CA 92071",
    "county": "San Diego County",
    "state": "CA",
    "city": "Santee"
  },
  {
    "value": "92075",
    "label": "Solana Beach - CA 92075",
    "county": "San Diego County",
    "state": "CA",
    "city": "Solana Beach"
  },
  {
    "value": "92078",
    "label": "San Marcos - CA 92078",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Marcos"
  },
  {
    "value": "92081",
    "label": "Vista - CA 92081",
    "county": "San Diego County",
    "state": "CA",
    "city": "Vista"
  },
  {
    "value": "92082",
    "label": "Valley Center - CA 92082",
    "county": "San Diego County",
    "state": "CA",
    "city": "Valley Center"
  },
  {
    "value": "92083",
    "label": "Vista - CA 92083",
    "county": "San Diego County",
    "state": "CA",
    "city": "Vista"
  },
  {
    "value": "92084",
    "label": "Vista - CA 92084",
    "county": "San Diego County",
    "state": "CA",
    "city": "Vista"
  },
  {
    "value": "92086",
    "label": "Warner Springs - CA 92086",
    "county": "San Diego County",
    "state": "CA",
    "city": "Warner Springs"
  },
  {
    "value": "92091",
    "label": "Rancho Santa Fe - CA 92091",
    "county": "San Diego County",
    "state": "CA",
    "city": "Rancho Santa Fe"
  },
  {
    "value": "92101",
    "label": "San Diego - CA 92101",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92102",
    "label": "San Diego - CA 92102",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92103",
    "label": "San Diego - CA 92103",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92104",
    "label": "San Diego - CA 92104",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92105",
    "label": "San Diego - CA 92105",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92106",
    "label": "San Diego - CA 92106",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92107",
    "label": "San Diego - CA 92107",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92108",
    "label": "San Diego - CA 92108",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92109",
    "label": "San Diego - CA 92109",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92110",
    "label": "San Diego - CA 92110",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92111",
    "label": "San Diego - CA 92111",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92113",
    "label": "San Diego - CA 92113",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92114",
    "label": "San Diego - CA 92114",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92115",
    "label": "San Diego - CA 92115",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92116",
    "label": "San Diego - CA 92116",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92117",
    "label": "San Diego - CA 92117",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92118",
    "label": "Coronado - CA 92118",
    "county": "San Diego County",
    "state": "CA",
    "city": "Coronado"
  },
  {
    "value": "92119",
    "label": "San Diego - CA 92119",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92120",
    "label": "San Diego - CA 92120",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92121",
    "label": "San Diego - CA 92121",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92122",
    "label": "San Diego - CA 92122",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92123",
    "label": "San Diego - CA 92123",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92124",
    "label": "San Diego - CA 92124",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92126",
    "label": "San Diego - CA 92126",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92127",
    "label": "San Diego - CA 92127",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92128",
    "label": "San Diego - CA 92128",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92129",
    "label": "San Diego - CA 92129",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92130",
    "label": "San Diego - CA 92130",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92131",
    "label": "San Diego - CA 92131",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92133",
    "label": "San Diego - CA 92133",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92139",
    "label": "San Diego - CA 92139",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92154",
    "label": "San Diego - CA 92154",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92173",
    "label": "San Ysidro - CA 92173",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Ysidro"
  },
  {
    "value": "92194",
    "label": "San Diego - CA 92194",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92199",
    "label": "San Diego - CA 92199",
    "county": "San Diego County",
    "state": "CA",
    "city": "San Diego"
  },
  {
    "value": "92201",
    "label": "Indio - CA 92201",
    "county": "Riverside County",
    "state": "CA",
    "city": "Indio"
  },
  {
    "value": "92203",
    "label": "Indio - CA 92203",
    "county": "Riverside County",
    "state": "CA",
    "city": "Indio"
  },
  {
    "value": "92210",
    "label": "Indian Wells - CA 92210",
    "county": "Riverside County",
    "state": "CA",
    "city": "Indian Wells"
  },
  {
    "value": "92211",
    "label": "Palm Desert - CA 92211",
    "county": "Riverside County",
    "state": "CA",
    "city": "Palm Desert"
  },
  {
    "value": "92220",
    "label": "Banning - CA 92220",
    "county": "Riverside County",
    "state": "CA",
    "city": "Banning"
  },
  {
    "value": "92223",
    "label": "Beaumont - CA 92223",
    "county": "Riverside County",
    "state": "CA",
    "city": "Beaumont"
  },
  {
    "value": "92225",
    "label": "Blythe - CA 92225",
    "county": "Riverside County",
    "state": "CA",
    "city": "Blythe"
  },
  {
    "value": "92227",
    "label": "Brawley - CA 92227",
    "county": "Imperial County",
    "state": "CA",
    "city": "Brawley"
  },
  {
    "value": "92230",
    "label": "Cabazon - CA 92230",
    "county": "Riverside County",
    "state": "CA",
    "city": "Cabazon"
  },
  {
    "value": "92231",
    "label": "Calexico - CA 92231",
    "county": "Imperial County",
    "state": "CA",
    "city": "Calexico"
  },
  {
    "value": "92233",
    "label": "Calipatria - CA 92233",
    "county": "Imperial County",
    "state": "CA",
    "city": "Calipatria"
  },
  {
    "value": "92234",
    "label": "Cathedral City - CA 92234",
    "county": "Riverside County",
    "state": "CA",
    "city": "Cathedral City"
  },
  {
    "value": "92236",
    "label": "Coachella - CA 92236",
    "county": "Riverside County",
    "state": "CA",
    "city": "Coachella"
  },
  {
    "value": "92240",
    "label": "Desert Hot Springs - CA 92240",
    "county": "Riverside County",
    "state": "CA",
    "city": "Desert Hot Springs"
  },
  {
    "value": "92241",
    "label": "Desert Hot Springs - CA 92241",
    "county": "Riverside County",
    "state": "CA",
    "city": "Desert Hot Springs"
  },
  {
    "value": "92242",
    "label": "Earp - CA 92242",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Earp"
  },
  {
    "value": "92243",
    "label": "El Centro - CA 92243",
    "county": "Imperial County",
    "state": "CA",
    "city": "El Centro"
  },
  {
    "value": "92249",
    "label": "Heber - CA 92249",
    "county": "Imperial County",
    "state": "CA",
    "city": "Heber"
  },
  {
    "value": "92250",
    "label": "Holtville - CA 92250",
    "county": "Imperial County",
    "state": "CA",
    "city": "Holtville"
  },
  {
    "value": "92251",
    "label": "Imperial - CA 92251",
    "county": "Imperial County",
    "state": "CA",
    "city": "Imperial"
  },
  {
    "value": "92252",
    "label": "Joshua Tree - CA 92252",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Joshua Tree"
  },
  {
    "value": "92253",
    "label": "La Quinta - CA 92253",
    "county": "Riverside County",
    "state": "CA",
    "city": "La Quinta"
  },
  {
    "value": "92254",
    "label": "Mecca - CA 92254",
    "county": "Riverside County",
    "state": "CA",
    "city": "Mecca"
  },
  {
    "value": "92256",
    "label": "Morongo Valley - CA 92256",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Morongo Valley"
  },
  {
    "value": "92257",
    "label": "Niland - CA 92257",
    "county": "Imperial County",
    "state": "CA",
    "city": "Niland"
  },
  {
    "value": "92260",
    "label": "Palm Desert - CA 92260",
    "county": "Riverside County",
    "state": "CA",
    "city": "Palm Desert"
  },
  {
    "value": "92262",
    "label": "Palm Springs - CA 92262",
    "county": "Riverside County",
    "state": "CA",
    "city": "Palm Springs"
  },
  {
    "value": "92264",
    "label": "Palm Springs - CA 92264",
    "county": "Riverside County",
    "state": "CA",
    "city": "Palm Springs"
  },
  {
    "value": "92270",
    "label": "Rancho Mirage - CA 92270",
    "county": "Riverside County",
    "state": "CA",
    "city": "Rancho Mirage"
  },
  {
    "value": "92274",
    "label": "Thermal - CA 92274",
    "county": "Riverside County",
    "state": "CA",
    "city": "Thermal"
  },
  {
    "value": "92276",
    "label": "Thousand Palms - CA 92276",
    "county": "Riverside County",
    "state": "CA",
    "city": "Thousand Palms"
  },
  {
    "value": "92277",
    "label": "Twentynine Palms - CA 92277",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Twentynine Palms"
  },
  {
    "value": "92280",
    "label": "Vidal - CA 92280",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Vidal"
  },
  {
    "value": "92282",
    "label": "Whitewater - CA 92282",
    "county": "Riverside County",
    "state": "CA",
    "city": "Whitewater"
  },
  {
    "value": "92283",
    "label": "Winterhaven - CA 92283",
    "county": "Imperial County",
    "state": "CA",
    "city": "Winterhaven"
  },
  {
    "value": "92284",
    "label": "Yucca Valley - CA 92284",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Yucca Valley"
  },
  {
    "value": "92285",
    "label": "Landers - CA 92285",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Landers"
  },
  {
    "value": "92292",
    "label": "Palm Springs - CA 92292",
    "county": "Riverside County",
    "state": "CA",
    "city": "Palm Springs"
  },
  {
    "value": "92301",
    "label": "Adelanto - CA 92301",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Adelanto"
  },
  {
    "value": "92305",
    "label": "Angelus Oaks - CA 92305",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Angelus Oaks"
  },
  {
    "value": "92307",
    "label": "Apple Valley - CA 92307",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Apple Valley"
  },
  {
    "value": "92308",
    "label": "Apple Valley - CA 92308",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Apple Valley"
  },
  {
    "value": "92309",
    "label": "Baker - CA 92309",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Baker"
  },
  {
    "value": "92310",
    "label": "Fort Irwin - CA 92310",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Fort Irwin"
  },
  {
    "value": "92311",
    "label": "Barstow - CA 92311",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Barstow"
  },
  {
    "value": "92313",
    "label": "Grand Terrace - CA 92313",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Grand Terrace"
  },
  {
    "value": "92314",
    "label": "Big Bear City - CA 92314",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Big Bear City"
  },
  {
    "value": "92315",
    "label": "Big Bear Lake - CA 92315",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Big Bear Lake"
  },
  {
    "value": "92316",
    "label": "Bloomington - CA 92316",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Bloomington"
  },
  {
    "value": "92318",
    "label": "Bryn Mawr - CA 92318",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Bryn Mawr"
  },
  {
    "value": "92320",
    "label": "Calimesa - CA 92320",
    "county": "Riverside County",
    "state": "CA",
    "city": "Calimesa"
  },
  {
    "value": "92323",
    "label": "Cima - CA 92323",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Cima"
  },
  {
    "value": "92324",
    "label": "Colton - CA 92324",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Colton"
  },
  {
    "value": "92327",
    "label": "Daggett - CA 92327",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Daggett"
  },
  {
    "value": "92328",
    "label": "Death Valley - CA 92328",
    "county": "Inyo County",
    "state": "CA",
    "city": "Death Valley"
  },
  {
    "value": "92332",
    "label": "Essex - CA 92332",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Essex"
  },
  {
    "value": "92335",
    "label": "Fontana - CA 92335",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Fontana"
  },
  {
    "value": "92336",
    "label": "Fontana - CA 92336",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Fontana"
  },
  {
    "value": "92337",
    "label": "Fontana - CA 92337",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Fontana"
  },
  {
    "value": "92338",
    "label": "Ludlow - CA 92338",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Ludlow"
  },
  {
    "value": "92339",
    "label": "Forest Falls - CA 92339",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Forest Falls"
  },
  {
    "value": "92342",
    "label": "Helendale - CA 92342",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Helendale"
  },
  {
    "value": "92344",
    "label": "Hesperia - CA 92344",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Hesperia"
  },
  {
    "value": "92345",
    "label": "Hesperia - CA 92345",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Hesperia"
  },
  {
    "value": "92346",
    "label": "Highland - CA 92346",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Highland"
  },
  {
    "value": "92347",
    "label": "Hinkley - CA 92347",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Hinkley"
  },
  {
    "value": "92354",
    "label": "Loma Linda - CA 92354",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Loma Linda"
  },
  {
    "value": "92356",
    "label": "Lucerne Valley - CA 92356",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Lucerne Valley"
  },
  {
    "value": "92358",
    "label": "Lytle Creek - CA 92358",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Lytle Creek"
  },
  {
    "value": "92359",
    "label": "Mentone - CA 92359",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Mentone"
  },
  {
    "value": "92363",
    "label": "Needles - CA 92363",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Needles"
  },
  {
    "value": "92364",
    "label": "Nipton - CA 92364",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Nipton"
  },
  {
    "value": "92365",
    "label": "Newberry Springs - CA 92365",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Newberry Springs"
  },
  {
    "value": "92366",
    "label": "Mountain Pass - CA 92366",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Mountain Pass"
  },
  {
    "value": "92368",
    "label": "Oro Grande - CA 92368",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Oro Grande"
  },
  {
    "value": "92371",
    "label": "Phelan - CA 92371",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Phelan"
  },
  {
    "value": "92372",
    "label": "Pinon Hills - CA 92372",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Pinon Hills"
  },
  {
    "value": "92373",
    "label": "Redlands - CA 92373",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Redlands"
  },
  {
    "value": "92374",
    "label": "Redlands - CA 92374",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Redlands"
  },
  {
    "value": "92376",
    "label": "Rialto - CA 92376",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Rialto"
  },
  {
    "value": "92377",
    "label": "Rialto - CA 92377",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Rialto"
  },
  {
    "value": "92392",
    "label": "Victorville - CA 92392",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Victorville"
  },
  {
    "value": "92394",
    "label": "Victorville - CA 92394",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Victorville"
  },
  {
    "value": "92395",
    "label": "Victorville - CA 92395",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Victorville"
  },
  {
    "value": "92397",
    "label": "Wrightwood - CA 92397",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Wrightwood"
  },
  {
    "value": "92399",
    "label": "Yucaipa - CA 92399",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Yucaipa"
  },
  {
    "value": "92401",
    "label": "San Bernardino - CA 92401",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "San Bernardino"
  },
  {
    "value": "92403",
    "label": "San Bernardino - CA 92403",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "San Bernardino"
  },
  {
    "value": "92404",
    "label": "San Bernardino - CA 92404",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "San Bernardino"
  },
  {
    "value": "92405",
    "label": "San Bernardino - CA 92405",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "San Bernardino"
  },
  {
    "value": "92407",
    "label": "San Bernardino - CA 92407",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "San Bernardino"
  },
  {
    "value": "92408",
    "label": "San Bernardino - CA 92408",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "San Bernardino"
  },
  {
    "value": "92410",
    "label": "San Bernardino - CA 92410",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "San Bernardino"
  },
  {
    "value": "92411",
    "label": "San Bernardino - CA 92411",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "San Bernardino"
  },
  {
    "value": "92501",
    "label": "Riverside - CA 92501",
    "county": "Riverside County",
    "state": "CA",
    "city": "Riverside"
  },
  {
    "value": "92503",
    "label": "Riverside - CA 92503",
    "county": "Riverside County",
    "state": "CA",
    "city": "Riverside"
  },
  {
    "value": "92504",
    "label": "Riverside - CA 92504",
    "county": "Riverside County",
    "state": "CA",
    "city": "Riverside"
  },
  {
    "value": "92505",
    "label": "Riverside - CA 92505",
    "county": "Riverside County",
    "state": "CA",
    "city": "Riverside"
  },
  {
    "value": "92506",
    "label": "Riverside - CA 92506",
    "county": "Riverside County",
    "state": "CA",
    "city": "Riverside"
  },
  {
    "value": "92507",
    "label": "Riverside - CA 92507",
    "county": "Riverside County",
    "state": "CA",
    "city": "Riverside"
  },
  {
    "value": "92508",
    "label": "Riverside - CA 92508",
    "county": "Riverside County",
    "state": "CA",
    "city": "Riverside"
  },
  {
    "value": "92509",
    "label": "Jurupa Valley - CA 92509",
    "county": "Riverside County",
    "state": "CA",
    "city": "Jurupa Valley"
  },
  {
    "value": "92518",
    "label": "March Air Reserve Base - CA 92518",
    "county": "Riverside County",
    "state": "CA",
    "city": "March Air Reserve Base"
  },
  {
    "value": "92530",
    "label": "Lake Elsinore - CA 92530",
    "county": "Riverside County",
    "state": "CA",
    "city": "Lake Elsinore"
  },
  {
    "value": "92532",
    "label": "Lake Elsinore - CA 92532",
    "county": "Riverside County",
    "state": "CA",
    "city": "Lake Elsinore"
  },
  {
    "value": "92536",
    "label": "Aguanga - CA 92536",
    "county": "Riverside County",
    "state": "CA",
    "city": "Aguanga"
  },
  {
    "value": "92539",
    "label": "Anza - CA 92539",
    "county": "Riverside County",
    "state": "CA",
    "city": "Anza"
  },
  {
    "value": "92543",
    "label": "Hemet - CA 92543",
    "county": "Riverside County",
    "state": "CA",
    "city": "Hemet"
  },
  {
    "value": "92544",
    "label": "Hemet - CA 92544",
    "county": "Riverside County",
    "state": "CA",
    "city": "Hemet"
  },
  {
    "value": "92545",
    "label": "Hemet - CA 92545",
    "county": "Riverside County",
    "state": "CA",
    "city": "Hemet"
  },
  {
    "value": "92548",
    "label": "Homeland - CA 92548",
    "county": "Riverside County",
    "state": "CA",
    "city": "Homeland"
  },
  {
    "value": "92551",
    "label": "Moreno Valley - CA 92551",
    "county": "Riverside County",
    "state": "CA",
    "city": "Moreno Valley"
  },
  {
    "value": "92553",
    "label": "Moreno Valley - CA 92553",
    "county": "Riverside County",
    "state": "CA",
    "city": "Moreno Valley"
  },
  {
    "value": "92555",
    "label": "Moreno Valley - CA 92555",
    "county": "Riverside County",
    "state": "CA",
    "city": "Moreno Valley"
  },
  {
    "value": "92557",
    "label": "Moreno Valley - CA 92557",
    "county": "Riverside County",
    "state": "CA",
    "city": "Moreno Valley"
  },
  {
    "value": "92561",
    "label": "Mountain Center - CA 92561",
    "county": "Riverside County",
    "state": "CA",
    "city": "Mountain Center"
  },
  {
    "value": "92562",
    "label": "Murrieta - CA 92562",
    "county": "Riverside County",
    "state": "CA",
    "city": "Murrieta"
  },
  {
    "value": "92563",
    "label": "Murrieta - CA 92563",
    "county": "Riverside County",
    "state": "CA",
    "city": "Murrieta"
  },
  {
    "value": "92567",
    "label": "Nuevo - CA 92567",
    "county": "Riverside County",
    "state": "CA",
    "city": "Nuevo"
  },
  {
    "value": "92570",
    "label": "Perris - CA 92570",
    "county": "Riverside County",
    "state": "CA",
    "city": "Perris"
  },
  {
    "value": "92571",
    "label": "Perris - CA 92571",
    "county": "Riverside County",
    "state": "CA",
    "city": "Perris"
  },
  {
    "value": "92582",
    "label": "San Jacinto - CA 92582",
    "county": "Riverside County",
    "state": "CA",
    "city": "San Jacinto"
  },
  {
    "value": "92583",
    "label": "San Jacinto - CA 92583",
    "county": "Riverside County",
    "state": "CA",
    "city": "San Jacinto"
  },
  {
    "value": "92584",
    "label": "Menifee - CA 92584",
    "county": "Riverside County",
    "state": "CA",
    "city": "Menifee"
  },
  {
    "value": "92585",
    "label": "Menifee - CA 92585",
    "county": "Riverside County",
    "state": "CA",
    "city": "Menifee"
  },
  {
    "value": "92586",
    "label": "Menifee - CA 92586",
    "county": "Riverside County",
    "state": "CA",
    "city": "Menifee"
  },
  {
    "value": "92587",
    "label": "Menifee - CA 92587",
    "county": "Riverside County",
    "state": "CA",
    "city": "Menifee"
  },
  {
    "value": "92590",
    "label": "Temecula - CA 92590",
    "county": "Riverside County",
    "state": "CA",
    "city": "Temecula"
  },
  {
    "value": "92591",
    "label": "Temecula - CA 92591",
    "county": "Riverside County",
    "state": "CA",
    "city": "Temecula"
  },
  {
    "value": "92592",
    "label": "Temecula - CA 92592",
    "county": "Riverside County",
    "state": "CA",
    "city": "Temecula"
  },
  {
    "value": "92595",
    "label": "Wildomar - CA 92595",
    "county": "Riverside County",
    "state": "CA",
    "city": "Wildomar"
  },
  {
    "value": "92596",
    "label": "Winchester - CA 92596",
    "county": "Riverside County",
    "state": "CA",
    "city": "Winchester"
  },
  {
    "value": "92602",
    "label": "Irvine - CA 92602",
    "county": "Orange County",
    "state": "CA",
    "city": "Irvine"
  },
  {
    "value": "92603",
    "label": "Irvine - CA 92603",
    "county": "Orange County",
    "state": "CA",
    "city": "Irvine"
  },
  {
    "value": "92604",
    "label": "Irvine - CA 92604",
    "county": "Orange County",
    "state": "CA",
    "city": "Irvine"
  },
  {
    "value": "92606",
    "label": "Irvine - CA 92606",
    "county": "Orange County",
    "state": "CA",
    "city": "Irvine"
  },
  {
    "value": "92610",
    "label": "Foothill Ranch - CA 92610",
    "county": "Orange County",
    "state": "CA",
    "city": "Foothill Ranch"
  },
  {
    "value": "92612",
    "label": "Irvine - CA 92612",
    "county": "Orange County",
    "state": "CA",
    "city": "Irvine"
  },
  {
    "value": "92614",
    "label": "Irvine - CA 92614",
    "county": "Orange County",
    "state": "CA",
    "city": "Irvine"
  },
  {
    "value": "92617",
    "label": "Irvine - CA 92617",
    "county": "Orange County",
    "state": "CA",
    "city": "Irvine"
  },
  {
    "value": "92618",
    "label": "Irvine - CA 92618",
    "county": "Orange County",
    "state": "CA",
    "city": "Irvine"
  },
  {
    "value": "92620",
    "label": "Irvine - CA 92620",
    "county": "Orange County",
    "state": "CA",
    "city": "Irvine"
  },
  {
    "value": "92624",
    "label": "Capistrano Beach - CA 92624",
    "county": "Orange County",
    "state": "CA",
    "city": "Capistrano Beach"
  },
  {
    "value": "92625",
    "label": "Corona Del Mar - CA 92625",
    "county": "Orange County",
    "state": "CA",
    "city": "Corona Del Mar"
  },
  {
    "value": "92626",
    "label": "Costa Mesa - CA 92626",
    "county": "Orange County",
    "state": "CA",
    "city": "Costa Mesa"
  },
  {
    "value": "92627",
    "label": "Costa Mesa - CA 92627",
    "county": "Orange County",
    "state": "CA",
    "city": "Costa Mesa"
  },
  {
    "value": "92629",
    "label": "Dana Point - CA 92629",
    "county": "Orange County",
    "state": "CA",
    "city": "Dana Point"
  },
  {
    "value": "92630",
    "label": "Lake Forest - CA 92630",
    "county": "Orange County",
    "state": "CA",
    "city": "Lake Forest"
  },
  {
    "value": "92637",
    "label": "Laguna Woods - CA 92637",
    "county": "Orange County",
    "state": "CA",
    "city": "Laguna Woods"
  },
  {
    "value": "92646",
    "label": "Huntington Beach - CA 92646",
    "county": "Orange County",
    "state": "CA",
    "city": "Huntington Beach"
  },
  {
    "value": "92647",
    "label": "Huntington Beach - CA 92647",
    "county": "Orange County",
    "state": "CA",
    "city": "Huntington Beach"
  },
  {
    "value": "92648",
    "label": "Huntington Beach - CA 92648",
    "county": "Orange County",
    "state": "CA",
    "city": "Huntington Beach"
  },
  {
    "value": "92649",
    "label": "Huntington Beach - CA 92649",
    "county": "Orange County",
    "state": "CA",
    "city": "Huntington Beach"
  },
  {
    "value": "92651",
    "label": "Laguna Beach - CA 92651",
    "county": "Orange County",
    "state": "CA",
    "city": "Laguna Beach"
  },
  {
    "value": "92653",
    "label": "Laguna Hills - CA 92653",
    "county": "Orange County",
    "state": "CA",
    "city": "Laguna Hills"
  },
  {
    "value": "92655",
    "label": "Midway City - CA 92655",
    "county": "Orange County",
    "state": "CA",
    "city": "Midway City"
  },
  {
    "value": "92656",
    "label": "Aliso Viejo - CA 92656",
    "county": "Orange County",
    "state": "CA",
    "city": "Aliso Viejo"
  },
  {
    "value": "92657",
    "label": "Newport Coast - CA 92657",
    "county": "Orange County",
    "state": "CA",
    "city": "Newport Coast"
  },
  {
    "value": "92660",
    "label": "Newport Beach - CA 92660",
    "county": "Orange County",
    "state": "CA",
    "city": "Newport Beach"
  },
  {
    "value": "92661",
    "label": "Newport Beach - CA 92661",
    "county": "Orange County",
    "state": "CA",
    "city": "Newport Beach"
  },
  {
    "value": "92662",
    "label": "Newport Beach - CA 92662",
    "county": "Orange County",
    "state": "CA",
    "city": "Newport Beach"
  },
  {
    "value": "92663",
    "label": "Newport Beach - CA 92663",
    "county": "Orange County",
    "state": "CA",
    "city": "Newport Beach"
  },
  {
    "value": "92672",
    "label": "San Clemente - CA 92672",
    "county": "Orange County",
    "state": "CA",
    "city": "San Clemente"
  },
  {
    "value": "92673",
    "label": "San Clemente - CA 92673",
    "county": "Orange County",
    "state": "CA",
    "city": "San Clemente"
  },
  {
    "value": "92675",
    "label": "San Juan Capistrano - CA 92675",
    "county": "Orange County",
    "state": "CA",
    "city": "San Juan Capistrano"
  },
  {
    "value": "92676",
    "label": "Silverado - CA 92676",
    "county": "Orange County",
    "state": "CA",
    "city": "Silverado"
  },
  {
    "value": "92677",
    "label": "Laguna Niguel - CA 92677",
    "county": "Orange County",
    "state": "CA",
    "city": "Laguna Niguel"
  },
  {
    "value": "92679",
    "label": "Trabuco Canyon - CA 92679",
    "county": "Orange County",
    "state": "CA",
    "city": "Trabuco Canyon"
  },
  {
    "value": "92683",
    "label": "Westminster - CA 92683",
    "county": "Orange County",
    "state": "CA",
    "city": "Westminster"
  },
  {
    "value": "92688",
    "label": "Rancho Santa Margarita - CA 92688",
    "county": "Orange County",
    "state": "CA",
    "city": "Rancho Santa Margarita"
  },
  {
    "value": "92691",
    "label": "Mission Viejo - CA 92691",
    "county": "Orange County",
    "state": "CA",
    "city": "Mission Viejo"
  },
  {
    "value": "92692",
    "label": "Mission Viejo - CA 92692",
    "county": "Orange County",
    "state": "CA",
    "city": "Mission Viejo"
  },
  {
    "value": "92694",
    "label": "Ladera Ranch - CA 92694",
    "county": "Orange County",
    "state": "CA",
    "city": "Ladera Ranch"
  },
  {
    "value": "92701",
    "label": "Santa Ana - CA 92701",
    "county": "Orange County",
    "state": "CA",
    "city": "Santa Ana"
  },
  {
    "value": "92703",
    "label": "Santa Ana - CA 92703",
    "county": "Orange County",
    "state": "CA",
    "city": "Santa Ana"
  },
  {
    "value": "92704",
    "label": "Santa Ana - CA 92704",
    "county": "Orange County",
    "state": "CA",
    "city": "Santa Ana"
  },
  {
    "value": "92705",
    "label": "Santa Ana - CA 92705",
    "county": "Orange County",
    "state": "CA",
    "city": "Santa Ana"
  },
  {
    "value": "92706",
    "label": "Santa Ana - CA 92706",
    "county": "Orange County",
    "state": "CA",
    "city": "Santa Ana"
  },
  {
    "value": "92707",
    "label": "Santa Ana - CA 92707",
    "county": "Orange County",
    "state": "CA",
    "city": "Santa Ana"
  },
  {
    "value": "92708",
    "label": "Fountain Valley - CA 92708",
    "county": "Orange County",
    "state": "CA",
    "city": "Fountain Valley"
  },
  {
    "value": "92780",
    "label": "Tustin - CA 92780",
    "county": "Orange County",
    "state": "CA",
    "city": "Tustin"
  },
  {
    "value": "92782",
    "label": "Tustin - CA 92782",
    "county": "Orange County",
    "state": "CA",
    "city": "Tustin"
  },
  {
    "value": "92799",
    "label": "Santa Ana - CA 92799",
    "county": "Orange County",
    "state": "CA",
    "city": "Santa Ana"
  },
  {
    "value": "92801",
    "label": "Anaheim - CA 92801",
    "county": "Orange County",
    "state": "CA",
    "city": "Anaheim"
  },
  {
    "value": "92802",
    "label": "Anaheim - CA 92802",
    "county": "Orange County",
    "state": "CA",
    "city": "Anaheim"
  },
  {
    "value": "92804",
    "label": "Anaheim - CA 92804",
    "county": "Orange County",
    "state": "CA",
    "city": "Anaheim"
  },
  {
    "value": "92805",
    "label": "Anaheim - CA 92805",
    "county": "Orange County",
    "state": "CA",
    "city": "Anaheim"
  },
  {
    "value": "92806",
    "label": "Anaheim - CA 92806",
    "county": "Orange County",
    "state": "CA",
    "city": "Anaheim"
  },
  {
    "value": "92807",
    "label": "Anaheim - CA 92807",
    "county": "Orange County",
    "state": "CA",
    "city": "Anaheim"
  },
  {
    "value": "92808",
    "label": "Anaheim - CA 92808",
    "county": "Orange County",
    "state": "CA",
    "city": "Anaheim"
  },
  {
    "value": "92809",
    "label": "Anaheim - CA 92809",
    "county": "Orange County",
    "state": "CA",
    "city": "Anaheim"
  },
  {
    "value": "92821",
    "label": "Brea - CA 92821",
    "county": "Orange County",
    "state": "CA",
    "city": "Brea"
  },
  {
    "value": "92823",
    "label": "Brea - CA 92823",
    "county": "Orange County",
    "state": "CA",
    "city": "Brea"
  },
  {
    "value": "92831",
    "label": "Fullerton - CA 92831",
    "county": "Orange County",
    "state": "CA",
    "city": "Fullerton"
  },
  {
    "value": "92832",
    "label": "Fullerton - CA 92832",
    "county": "Orange County",
    "state": "CA",
    "city": "Fullerton"
  },
  {
    "value": "92833",
    "label": "Fullerton - CA 92833",
    "county": "Orange County",
    "state": "CA",
    "city": "Fullerton"
  },
  {
    "value": "92835",
    "label": "Fullerton - CA 92835",
    "county": "Orange County",
    "state": "CA",
    "city": "Fullerton"
  },
  {
    "value": "92840",
    "label": "Garden Grove - CA 92840",
    "county": "Orange County",
    "state": "CA",
    "city": "Garden Grove"
  },
  {
    "value": "92841",
    "label": "Garden Grove - CA 92841",
    "county": "Orange County",
    "state": "CA",
    "city": "Garden Grove"
  },
  {
    "value": "92843",
    "label": "Garden Grove - CA 92843",
    "county": "Orange County",
    "state": "CA",
    "city": "Garden Grove"
  },
  {
    "value": "92844",
    "label": "Garden Grove - CA 92844",
    "county": "Orange County",
    "state": "CA",
    "city": "Garden Grove"
  },
  {
    "value": "92845",
    "label": "Garden Grove - CA 92845",
    "county": "Orange County",
    "state": "CA",
    "city": "Garden Grove"
  },
  {
    "value": "92860",
    "label": "Norco - CA 92860",
    "county": "Riverside County",
    "state": "CA",
    "city": "Norco"
  },
  {
    "value": "92861",
    "label": "Villa Park - CA 92861",
    "county": "Orange County",
    "state": "CA",
    "city": "Villa Park"
  },
  {
    "value": "92862",
    "label": "Orange - CA 92862",
    "county": "Orange County",
    "state": "CA",
    "city": "Orange"
  },
  {
    "value": "92865",
    "label": "Orange - CA 92865",
    "county": "Orange County",
    "state": "CA",
    "city": "Orange"
  },
  {
    "value": "92866",
    "label": "Orange - CA 92866",
    "county": "Orange County",
    "state": "CA",
    "city": "Orange"
  },
  {
    "value": "92867",
    "label": "Orange - CA 92867",
    "county": "Orange County",
    "state": "CA",
    "city": "Orange"
  },
  {
    "value": "92868",
    "label": "Orange - CA 92868",
    "county": "Orange County",
    "state": "CA",
    "city": "Orange"
  },
  {
    "value": "92869",
    "label": "Orange - CA 92869",
    "county": "Orange County",
    "state": "CA",
    "city": "Orange"
  },
  {
    "value": "92870",
    "label": "Placentia - CA 92870",
    "county": "Orange County",
    "state": "CA",
    "city": "Placentia"
  },
  {
    "value": "92878",
    "label": "Corona - CA 92878",
    "county": "Riverside County",
    "state": "CA",
    "city": "Corona"
  },
  {
    "value": "92879",
    "label": "Corona - CA 92879",
    "county": "Riverside County",
    "state": "CA",
    "city": "Corona"
  },
  {
    "value": "92880",
    "label": "Corona - CA 92880",
    "county": "Riverside County",
    "state": "CA",
    "city": "Corona"
  },
  {
    "value": "92881",
    "label": "Corona - CA 92881",
    "county": "Riverside County",
    "state": "CA",
    "city": "Corona"
  },
  {
    "value": "92882",
    "label": "Corona - CA 92882",
    "county": "Riverside County",
    "state": "CA",
    "city": "Corona"
  },
  {
    "value": "92883",
    "label": "Corona - CA 92883",
    "county": "Riverside County",
    "state": "CA",
    "city": "Corona"
  },
  {
    "value": "92886",
    "label": "Yorba Linda - CA 92886",
    "county": "Orange County",
    "state": "CA",
    "city": "Yorba Linda"
  },
  {
    "value": "92887",
    "label": "Yorba Linda - CA 92887",
    "county": "Orange County",
    "state": "CA",
    "city": "Yorba Linda"
  },
  {
    "value": "92899",
    "label": "Anaheim - CA 92899",
    "county": "Orange County",
    "state": "CA",
    "city": "Anaheim"
  },
  {
    "value": "93001",
    "label": "Ventura - CA 93001",
    "county": "Ventura County",
    "state": "CA",
    "city": "Ventura"
  },
  {
    "value": "93003",
    "label": "Ventura - CA 93003",
    "county": "Ventura County",
    "state": "CA",
    "city": "Ventura"
  },
  {
    "value": "93004",
    "label": "Ventura - CA 93004",
    "county": "Ventura County",
    "state": "CA",
    "city": "Ventura"
  },
  {
    "value": "93010",
    "label": "Camarillo - CA 93010",
    "county": "Ventura County",
    "state": "CA",
    "city": "Camarillo"
  },
  {
    "value": "93012",
    "label": "Camarillo - CA 93012",
    "county": "Ventura County",
    "state": "CA",
    "city": "Camarillo"
  },
  {
    "value": "93013",
    "label": "Carpinteria - CA 93013",
    "county": "Santa Barbara County",
    "state": "CA",
    "city": "Carpinteria"
  },
  {
    "value": "93015",
    "label": "Fillmore - CA 93015",
    "county": "Ventura County",
    "state": "CA",
    "city": "Fillmore"
  },
  {
    "value": "93021",
    "label": "Moorpark - CA 93021",
    "county": "Ventura County",
    "state": "CA",
    "city": "Moorpark"
  },
  {
    "value": "93022",
    "label": "Oak View - CA 93022",
    "county": "Ventura County",
    "state": "CA",
    "city": "Oak View"
  },
  {
    "value": "93023",
    "label": "Ojai - CA 93023",
    "county": "Ventura County",
    "state": "CA",
    "city": "Ojai"
  },
  {
    "value": "93030",
    "label": "Oxnard - CA 93030",
    "county": "Ventura County",
    "state": "CA",
    "city": "Oxnard"
  },
  {
    "value": "93033",
    "label": "Oxnard - CA 93033",
    "county": "Ventura County",
    "state": "CA",
    "city": "Oxnard"
  },
  {
    "value": "93035",
    "label": "Oxnard - CA 93035",
    "county": "Ventura County",
    "state": "CA",
    "city": "Oxnard"
  },
  {
    "value": "93036",
    "label": "Oxnard - CA 93036",
    "county": "Ventura County",
    "state": "CA",
    "city": "Oxnard"
  },
  {
    "value": "93041",
    "label": "Port Hueneme - CA 93041",
    "county": "Ventura County",
    "state": "CA",
    "city": "Port Hueneme"
  },
  {
    "value": "93060",
    "label": "Santa Paula - CA 93060",
    "county": "Ventura County",
    "state": "CA",
    "city": "Santa Paula"
  },
  {
    "value": "93063",
    "label": "Simi Valley - CA 93063",
    "county": "Ventura County",
    "state": "CA",
    "city": "Simi Valley"
  },
  {
    "value": "93065",
    "label": "Simi Valley - CA 93065",
    "county": "Ventura County",
    "state": "CA",
    "city": "Simi Valley"
  },
  {
    "value": "93066",
    "label": "Somis - CA 93066",
    "county": "Ventura County",
    "state": "CA",
    "city": "Somis"
  },
  {
    "value": "93093",
    "label": "Simi Valley - CA 93093",
    "county": "Ventura County",
    "state": "CA",
    "city": "Simi Valley"
  },
  {
    "value": "93101",
    "label": "Santa Barbara - CA 93101",
    "county": "Santa Barbara County",
    "state": "CA",
    "city": "Santa Barbara"
  },
  {
    "value": "93103",
    "label": "Santa Barbara - CA 93103",
    "county": "Santa Barbara County",
    "state": "CA",
    "city": "Santa Barbara"
  },
  {
    "value": "93105",
    "label": "Santa Barbara - CA 93105",
    "county": "Santa Barbara County",
    "state": "CA",
    "city": "Santa Barbara"
  },
  {
    "value": "93108",
    "label": "Santa Barbara - CA 93108",
    "county": "Santa Barbara County",
    "state": "CA",
    "city": "Santa Barbara"
  },
  {
    "value": "93109",
    "label": "Santa Barbara - CA 93109",
    "county": "Santa Barbara County",
    "state": "CA",
    "city": "Santa Barbara"
  },
  {
    "value": "93110",
    "label": "Santa Barbara - CA 93110",
    "county": "Santa Barbara County",
    "state": "CA",
    "city": "Santa Barbara"
  },
  {
    "value": "93111",
    "label": "Santa Barbara - CA 93111",
    "county": "Santa Barbara County",
    "state": "CA",
    "city": "Santa Barbara"
  },
  {
    "value": "93117",
    "label": "Goleta - CA 93117",
    "county": "Santa Barbara County",
    "state": "CA",
    "city": "Goleta"
  },
  {
    "value": "93202",
    "label": "Armona - CA 93202",
    "county": "Kings County",
    "state": "CA",
    "city": "Armona"
  },
  {
    "value": "93203",
    "label": "Arvin - CA 93203",
    "county": "Kern County",
    "state": "CA",
    "city": "Arvin"
  },
  {
    "value": "93204",
    "label": "Avenal - CA 93204",
    "county": "Kings County",
    "state": "CA",
    "city": "Avenal"
  },
  {
    "value": "93205",
    "label": "Bodfish - CA 93205",
    "county": "Kern County",
    "state": "CA",
    "city": "Bodfish"
  },
  {
    "value": "93206",
    "label": "Buttonwillow - CA 93206",
    "county": "Kern County",
    "state": "CA",
    "city": "Buttonwillow"
  },
  {
    "value": "93207",
    "label": "California Hot Springs - CA 93207",
    "county": "Tulare County",
    "state": "CA",
    "city": "California Hot Springs"
  },
  {
    "value": "93210",
    "label": "Coalinga - CA 93210",
    "county": "Fresno County",
    "state": "CA",
    "city": "Coalinga"
  },
  {
    "value": "93212",
    "label": "Corcoran - CA 93212",
    "county": "Kings County",
    "state": "CA",
    "city": "Corcoran"
  },
  {
    "value": "93215",
    "label": "Delano - CA 93215",
    "county": "Kern County",
    "state": "CA",
    "city": "Delano"
  },
  {
    "value": "93219",
    "label": "Earlimart - CA 93219",
    "county": "Tulare County",
    "state": "CA",
    "city": "Earlimart"
  },
  {
    "value": "93221",
    "label": "Exeter - CA 93221",
    "county": "Tulare County",
    "state": "CA",
    "city": "Exeter"
  },
  {
    "value": "93223",
    "label": "Farmersville - CA 93223",
    "county": "Tulare County",
    "state": "CA",
    "city": "Farmersville"
  },
  {
    "value": "93224",
    "label": "Fellows - CA 93224",
    "county": "Kern County",
    "state": "CA",
    "city": "Fellows"
  },
  {
    "value": "93225",
    "label": "Frazier Park - CA 93225",
    "county": "Kern County",
    "state": "CA",
    "city": "Frazier Park"
  },
  {
    "value": "93226",
    "label": "Glennville - CA 93226",
    "county": "Kern County",
    "state": "CA",
    "city": "Glennville"
  },
  {
    "value": "93230",
    "label": "Hanford - CA 93230",
    "county": "Kings County",
    "state": "CA",
    "city": "Hanford"
  },
  {
    "value": "93235",
    "label": "Ivanhoe - CA 93235",
    "county": "Tulare County",
    "state": "CA",
    "city": "Ivanhoe"
  },
  {
    "value": "93238",
    "label": "Kernville - CA 93238",
    "county": "Kern County",
    "state": "CA",
    "city": "Kernville"
  },
  {
    "value": "93239",
    "label": "Kettleman City - CA 93239",
    "county": "Kings County",
    "state": "CA",
    "city": "Kettleman City"
  },
  {
    "value": "93240",
    "label": "Lake Isabella - CA 93240",
    "county": "Kern County",
    "state": "CA",
    "city": "Lake Isabella"
  },
  {
    "value": "93241",
    "label": "Lamont - CA 93241",
    "county": "Kern County",
    "state": "CA",
    "city": "Lamont"
  },
  {
    "value": "93242",
    "label": "Laton - CA 93242",
    "county": "Fresno County",
    "state": "CA",
    "city": "Laton"
  },
  {
    "value": "93243",
    "label": "Lebec - CA 93243",
    "county": "Kern County",
    "state": "CA",
    "city": "Lebec"
  },
  {
    "value": "93244",
    "label": "Lemon Cove - CA 93244",
    "county": "Tulare County",
    "state": "CA",
    "city": "Lemon Cove"
  },
  {
    "value": "93245",
    "label": "Lemoore - CA 93245",
    "county": "Kings County",
    "state": "CA",
    "city": "Lemoore"
  },
  {
    "value": "93247",
    "label": "Lindsay - CA 93247",
    "county": "Tulare County",
    "state": "CA",
    "city": "Lindsay"
  },
  {
    "value": "93249",
    "label": "Lost Hills - CA 93249",
    "county": "Kern County",
    "state": "CA",
    "city": "Lost Hills"
  },
  {
    "value": "93250",
    "label": "Mc Farland - CA 93250",
    "county": "Kern County",
    "state": "CA",
    "city": "Mc Farland"
  },
  {
    "value": "93251",
    "label": "Mc Kittrick - CA 93251",
    "county": "Kern County",
    "state": "CA",
    "city": "Mc Kittrick"
  },
  {
    "value": "93252",
    "label": "Maricopa - CA 93252",
    "county": "Kern County",
    "state": "CA",
    "city": "Maricopa"
  },
  {
    "value": "93254",
    "label": "New Cuyama - CA 93254",
    "county": "Santa Barbara County",
    "state": "CA",
    "city": "New Cuyama"
  },
  {
    "value": "93255",
    "label": "Onyx - CA 93255",
    "county": "Kern County",
    "state": "CA",
    "city": "Onyx"
  },
  {
    "value": "93256",
    "label": "Pixley - CA 93256",
    "county": "Tulare County",
    "state": "CA",
    "city": "Pixley"
  },
  {
    "value": "93257",
    "label": "Porterville - CA 93257",
    "county": "Tulare County",
    "state": "CA",
    "city": "Porterville"
  },
  {
    "value": "93260",
    "label": "Posey - CA 93260",
    "county": "Tulare County",
    "state": "CA",
    "city": "Posey"
  },
  {
    "value": "93262",
    "label": "Sequoia National Park - CA 93262",
    "county": "Tulare County",
    "state": "CA",
    "city": "Sequoia National Park"
  },
  {
    "value": "93263",
    "label": "Shafter - CA 93263",
    "county": "Kern County",
    "state": "CA",
    "city": "Shafter"
  },
  {
    "value": "93265",
    "label": "Springville - CA 93265",
    "county": "Tulare County",
    "state": "CA",
    "city": "Springville"
  },
  {
    "value": "93266",
    "label": "Stratford - CA 93266",
    "county": "Kings County",
    "state": "CA",
    "city": "Stratford"
  },
  {
    "value": "93267",
    "label": "Strathmore - CA 93267",
    "county": "Tulare County",
    "state": "CA",
    "city": "Strathmore"
  },
  {
    "value": "93268",
    "label": "Taft - CA 93268",
    "county": "Kern County",
    "state": "CA",
    "city": "Taft"
  },
  {
    "value": "93270",
    "label": "Terra Bella - CA 93270",
    "county": "Tulare County",
    "state": "CA",
    "city": "Terra Bella"
  },
  {
    "value": "93271",
    "label": "Three Rivers - CA 93271",
    "county": "Tulare County",
    "state": "CA",
    "city": "Three Rivers"
  },
  {
    "value": "93272",
    "label": "Tipton - CA 93272",
    "county": "Tulare County",
    "state": "CA",
    "city": "Tipton"
  },
  {
    "value": "93274",
    "label": "Tulare - CA 93274",
    "county": "Tulare County",
    "state": "CA",
    "city": "Tulare"
  },
  {
    "value": "93277",
    "label": "Visalia - CA 93277",
    "county": "Tulare County",
    "state": "CA",
    "city": "Visalia"
  },
  {
    "value": "93280",
    "label": "Wasco - CA 93280",
    "county": "Kern County",
    "state": "CA",
    "city": "Wasco"
  },
  {
    "value": "93283",
    "label": "Weldon - CA 93283",
    "county": "Kern County",
    "state": "CA",
    "city": "Weldon"
  },
  {
    "value": "93285",
    "label": "Wofford Heights - CA 93285",
    "county": "Kern County",
    "state": "CA",
    "city": "Wofford Heights"
  },
  {
    "value": "93286",
    "label": "Woodlake - CA 93286",
    "county": "Tulare County",
    "state": "CA",
    "city": "Woodlake"
  },
  {
    "value": "93287",
    "label": "Woody - CA 93287",
    "county": "Kern County",
    "state": "CA",
    "city": "Woody"
  },
  {
    "value": "93291",
    "label": "Visalia - CA 93291",
    "county": "Tulare County",
    "state": "CA",
    "city": "Visalia"
  },
  {
    "value": "93292",
    "label": "Visalia - CA 93292",
    "county": "Tulare County",
    "state": "CA",
    "city": "Visalia"
  },
  {
    "value": "93301",
    "label": "Bakersfield - CA 93301",
    "county": "Kern County",
    "state": "CA",
    "city": "Bakersfield"
  },
  {
    "value": "93304",
    "label": "Bakersfield - CA 93304",
    "county": "Kern County",
    "state": "CA",
    "city": "Bakersfield"
  },
  {
    "value": "93305",
    "label": "Bakersfield - CA 93305",
    "county": "Kern County",
    "state": "CA",
    "city": "Bakersfield"
  },
  {
    "value": "93306",
    "label": "Bakersfield - CA 93306",
    "county": "Kern County",
    "state": "CA",
    "city": "Bakersfield"
  },
  {
    "value": "93307",
    "label": "Bakersfield - CA 93307",
    "county": "Kern County",
    "state": "CA",
    "city": "Bakersfield"
  },
  {
    "value": "93308",
    "label": "Bakersfield - CA 93308",
    "county": "Kern County",
    "state": "CA",
    "city": "Bakersfield"
  },
  {
    "value": "93309",
    "label": "Bakersfield - CA 93309",
    "county": "Kern County",
    "state": "CA",
    "city": "Bakersfield"
  },
  {
    "value": "93311",
    "label": "Bakersfield - CA 93311",
    "county": "Kern County",
    "state": "CA",
    "city": "Bakersfield"
  },
  {
    "value": "93312",
    "label": "Bakersfield - CA 93312",
    "county": "Kern County",
    "state": "CA",
    "city": "Bakersfield"
  },
  {
    "value": "93313",
    "label": "Bakersfield - CA 93313",
    "county": "Kern County",
    "state": "CA",
    "city": "Bakersfield"
  },
  {
    "value": "93314",
    "label": "Bakersfield - CA 93314",
    "county": "Kern County",
    "state": "CA",
    "city": "Bakersfield"
  },
  {
    "value": "93381",
    "label": "Bakersfield - CA 93381",
    "county": "Kern County",
    "state": "CA",
    "city": "Bakersfield"
  },
  {
    "value": "93382",
    "label": "Bakersfield - CA 93382",
    "county": "Kern County",
    "state": "CA",
    "city": "Bakersfield"
  },
  {
    "value": "93401",
    "label": "San Luis Obispo - CA 93401",
    "county": "San Luis Obispo County",
    "state": "CA",
    "city": "San Luis Obispo"
  },
  {
    "value": "93402",
    "label": "Los Osos - CA 93402",
    "county": "San Luis Obispo County",
    "state": "CA",
    "city": "Los Osos"
  },
  {
    "value": "93405",
    "label": "San Luis Obispo - CA 93405",
    "county": "San Luis Obispo County",
    "state": "CA",
    "city": "San Luis Obispo"
  },
  {
    "value": "93407",
    "label": "San Luis Obispo - CA 93407",
    "county": "San Luis Obispo County",
    "state": "CA",
    "city": "San Luis Obispo"
  },
  {
    "value": "93410",
    "label": "San Luis Obispo - CA 93410",
    "county": "San Luis Obispo County",
    "state": "CA",
    "city": "San Luis Obispo"
  },
  {
    "value": "93420",
    "label": "Arroyo Grande - CA 93420",
    "county": "San Luis Obispo County",
    "state": "CA",
    "city": "Arroyo Grande"
  },
  {
    "value": "93422",
    "label": "Atascadero - CA 93422",
    "county": "San Luis Obispo County",
    "state": "CA",
    "city": "Atascadero"
  },
  {
    "value": "93426",
    "label": "Bradley - CA 93426",
    "county": "Monterey County",
    "state": "CA",
    "city": "Bradley"
  },
  {
    "value": "93427",
    "label": "Buellton - CA 93427",
    "county": "Santa Barbara County",
    "state": "CA",
    "city": "Buellton"
  },
  {
    "value": "93428",
    "label": "Cambria - CA 93428",
    "county": "San Luis Obispo County",
    "state": "CA",
    "city": "Cambria"
  },
  {
    "value": "93430",
    "label": "Cayucos - CA 93430",
    "county": "San Luis Obispo County",
    "state": "CA",
    "city": "Cayucos"
  },
  {
    "value": "93432",
    "label": "Creston - CA 93432",
    "county": "San Luis Obispo County",
    "state": "CA",
    "city": "Creston"
  },
  {
    "value": "93433",
    "label": "Grover Beach - CA 93433",
    "county": "San Luis Obispo County",
    "state": "CA",
    "city": "Grover Beach"
  },
  {
    "value": "93434",
    "label": "Guadalupe - CA 93434",
    "county": "Santa Barbara County",
    "state": "CA",
    "city": "Guadalupe"
  },
  {
    "value": "93436",
    "label": "Lompoc - CA 93436",
    "county": "Santa Barbara County",
    "state": "CA",
    "city": "Lompoc"
  },
  {
    "value": "93437",
    "label": "Lompoc - CA 93437",
    "county": "Santa Barbara County",
    "state": "CA",
    "city": "Lompoc"
  },
  {
    "value": "93442",
    "label": "Morro Bay - CA 93442",
    "county": "San Luis Obispo County",
    "state": "CA",
    "city": "Morro Bay"
  },
  {
    "value": "93444",
    "label": "Nipomo - CA 93444",
    "county": "San Luis Obispo County",
    "state": "CA",
    "city": "Nipomo"
  },
  {
    "value": "93445",
    "label": "Oceano - CA 93445",
    "county": "San Luis Obispo County",
    "state": "CA",
    "city": "Oceano"
  },
  {
    "value": "93446",
    "label": "Paso Robles - CA 93446",
    "county": "San Luis Obispo County",
    "state": "CA",
    "city": "Paso Robles"
  },
  {
    "value": "93449",
    "label": "Pismo Beach - CA 93449",
    "county": "San Luis Obispo County",
    "state": "CA",
    "city": "Pismo Beach"
  },
  {
    "value": "93450",
    "label": "San Ardo - CA 93450",
    "county": "Monterey County",
    "state": "CA",
    "city": "San Ardo"
  },
  {
    "value": "93451",
    "label": "San Miguel - CA 93451",
    "county": "San Luis Obispo County",
    "state": "CA",
    "city": "San Miguel"
  },
  {
    "value": "93452",
    "label": "San Simeon - CA 93452",
    "county": "San Luis Obispo County",
    "state": "CA",
    "city": "San Simeon"
  },
  {
    "value": "93453",
    "label": "Santa Margarita - CA 93453",
    "county": "San Luis Obispo County",
    "state": "CA",
    "city": "Santa Margarita"
  },
  {
    "value": "93454",
    "label": "Santa Maria - CA 93454",
    "county": "Santa Barbara County",
    "state": "CA",
    "city": "Santa Maria"
  },
  {
    "value": "93455",
    "label": "Santa Maria - CA 93455",
    "county": "Santa Barbara County",
    "state": "CA",
    "city": "Santa Maria"
  },
  {
    "value": "93458",
    "label": "Santa Maria - CA 93458",
    "county": "Santa Barbara County",
    "state": "CA",
    "city": "Santa Maria"
  },
  {
    "value": "93460",
    "label": "Santa Ynez - CA 93460",
    "county": "Santa Barbara County",
    "state": "CA",
    "city": "Santa Ynez"
  },
  {
    "value": "93461",
    "label": "Shandon - CA 93461",
    "county": "San Luis Obispo County",
    "state": "CA",
    "city": "Shandon"
  },
  {
    "value": "93463",
    "label": "Solvang - CA 93463",
    "county": "Santa Barbara County",
    "state": "CA",
    "city": "Solvang"
  },
  {
    "value": "93465",
    "label": "Templeton - CA 93465",
    "county": "San Luis Obispo County",
    "state": "CA",
    "city": "Templeton"
  },
  {
    "value": "93501",
    "label": "Mojave - CA 93501",
    "county": "Kern County",
    "state": "CA",
    "city": "Mojave"
  },
  {
    "value": "93505",
    "label": "California City - CA 93505",
    "county": "Kern County",
    "state": "CA",
    "city": "California City"
  },
  {
    "value": "93510",
    "label": "Acton - CA 93510",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Acton"
  },
  {
    "value": "93512",
    "label": "Benton - CA 93512",
    "county": "Mono County",
    "state": "CA",
    "city": "Benton"
  },
  {
    "value": "93513",
    "label": "Big Pine - CA 93513",
    "county": "Inyo County",
    "state": "CA",
    "city": "Big Pine"
  },
  {
    "value": "93514",
    "label": "Bishop - CA 93514",
    "county": "Inyo County",
    "state": "CA",
    "city": "Bishop"
  },
  {
    "value": "93516",
    "label": "Boron - CA 93516",
    "county": "Kern County",
    "state": "CA",
    "city": "Boron"
  },
  {
    "value": "93517",
    "label": "Bridgeport - CA 93517",
    "county": "Mono County",
    "state": "CA",
    "city": "Bridgeport"
  },
  {
    "value": "93518",
    "label": "Caliente - CA 93518",
    "county": "Kern County",
    "state": "CA",
    "city": "Caliente"
  },
  {
    "value": "93523",
    "label": "Edwards - CA 93523",
    "county": "Kern County",
    "state": "CA",
    "city": "Edwards"
  },
  {
    "value": "93526",
    "label": "Independence - CA 93526",
    "county": "Inyo County",
    "state": "CA",
    "city": "Independence"
  },
  {
    "value": "93527",
    "label": "Inyokern - CA 93527",
    "county": "Kern County",
    "state": "CA",
    "city": "Inyokern"
  },
  {
    "value": "93531",
    "label": "Keene - CA 93531",
    "county": "Kern County",
    "state": "CA",
    "city": "Keene"
  },
  {
    "value": "93532",
    "label": "Lake Hughes - CA 93532",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Lake Hughes"
  },
  {
    "value": "93534",
    "label": "Lancaster - CA 93534",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Lancaster"
  },
  {
    "value": "93535",
    "label": "Lancaster - CA 93535",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Lancaster"
  },
  {
    "value": "93536",
    "label": "Lancaster - CA 93536",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Lancaster"
  },
  {
    "value": "93543",
    "label": "Littlerock - CA 93543",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Littlerock"
  },
  {
    "value": "93544",
    "label": "Llano - CA 93544",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Llano"
  },
  {
    "value": "93545",
    "label": "Lone Pine - CA 93545",
    "county": "Inyo County",
    "state": "CA",
    "city": "Lone Pine"
  },
  {
    "value": "93546",
    "label": "Mammoth Lakes - CA 93546",
    "county": "Mono County",
    "state": "CA",
    "city": "Mammoth Lakes"
  },
  {
    "value": "93550",
    "label": "Palmdale - CA 93550",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Palmdale"
  },
  {
    "value": "93551",
    "label": "Palmdale - CA 93551",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Palmdale"
  },
  {
    "value": "93552",
    "label": "Palmdale - CA 93552",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Palmdale"
  },
  {
    "value": "93553",
    "label": "Pearblossom - CA 93553",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Pearblossom"
  },
  {
    "value": "93555",
    "label": "Ridgecrest - CA 93555",
    "county": "Kern County",
    "state": "CA",
    "city": "Ridgecrest"
  },
  {
    "value": "93560",
    "label": "Rosamond - CA 93560",
    "county": "Kern County",
    "state": "CA",
    "city": "Rosamond"
  },
  {
    "value": "93561",
    "label": "Tehachapi - CA 93561",
    "county": "Kern County",
    "state": "CA",
    "city": "Tehachapi"
  },
  {
    "value": "93562",
    "label": "Trona - CA 93562",
    "county": "San Bernardino County",
    "state": "CA",
    "city": "Trona"
  },
  {
    "value": "93563",
    "label": "Valyermo - CA 93563",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Valyermo"
  },
  {
    "value": "93591",
    "label": "Palmdale - CA 93591",
    "county": "Los Angeles County",
    "state": "CA",
    "city": "Palmdale"
  },
  {
    "value": "93601",
    "label": "Ahwahnee - CA 93601",
    "county": "Madera County",
    "state": "CA",
    "city": "Ahwahnee"
  },
  {
    "value": "93602",
    "label": "Auberry - CA 93602",
    "county": "Fresno County",
    "state": "CA",
    "city": "Auberry"
  },
  {
    "value": "93603",
    "label": "Badger - CA 93603",
    "county": "Tulare County",
    "state": "CA",
    "city": "Badger"
  },
  {
    "value": "93604",
    "label": "Bass Lake - CA 93604",
    "county": "Madera County",
    "state": "CA",
    "city": "Bass Lake"
  },
  {
    "value": "93608",
    "label": "Cantua Creek - CA 93608",
    "county": "Fresno County",
    "state": "CA",
    "city": "Cantua Creek"
  },
  {
    "value": "93609",
    "label": "Caruthers - CA 93609",
    "county": "Fresno County",
    "state": "CA",
    "city": "Caruthers"
  },
  {
    "value": "93610",
    "label": "Chowchilla - CA 93610",
    "county": "Madera County",
    "state": "CA",
    "city": "Chowchilla"
  },
  {
    "value": "93611",
    "label": "Clovis - CA 93611",
    "county": "Fresno County",
    "state": "CA",
    "city": "Clovis"
  },
  {
    "value": "93612",
    "label": "Clovis - CA 93612",
    "county": "Fresno County",
    "state": "CA",
    "city": "Clovis"
  },
  {
    "value": "93614",
    "label": "Coarsegold - CA 93614",
    "county": "Madera County",
    "state": "CA",
    "city": "Coarsegold"
  },
  {
    "value": "93615",
    "label": "Cutler - CA 93615",
    "county": "Tulare County",
    "state": "CA",
    "city": "Cutler"
  },
  {
    "value": "93616",
    "label": "Del Rey - CA 93616",
    "county": "Fresno County",
    "state": "CA",
    "city": "Del Rey"
  },
  {
    "value": "93618",
    "label": "Dinuba - CA 93618",
    "county": "Tulare County",
    "state": "CA",
    "city": "Dinuba"
  },
  {
    "value": "93619",
    "label": "Clovis - CA 93619",
    "county": "Fresno County",
    "state": "CA",
    "city": "Clovis"
  },
  {
    "value": "93620",
    "label": "Dos Palos - CA 93620",
    "county": "Merced County",
    "state": "CA",
    "city": "Dos Palos"
  },
  {
    "value": "93621",
    "label": "Dunlap - CA 93621",
    "county": "Fresno County",
    "state": "CA",
    "city": "Dunlap"
  },
  {
    "value": "93622",
    "label": "Firebaugh - CA 93622",
    "county": "Fresno County",
    "state": "CA",
    "city": "Firebaugh"
  },
  {
    "value": "93623",
    "label": "Fish Camp - CA 93623",
    "county": "Mariposa County",
    "state": "CA",
    "city": "Fish Camp"
  },
  {
    "value": "93625",
    "label": "Fowler - CA 93625",
    "county": "Fresno County",
    "state": "CA",
    "city": "Fowler"
  },
  {
    "value": "93626",
    "label": "Friant - CA 93626",
    "county": "Fresno County",
    "state": "CA",
    "city": "Friant"
  },
  {
    "value": "93628",
    "label": "Hume - CA 93628",
    "county": "Fresno County",
    "state": "CA",
    "city": "Hume"
  },
  {
    "value": "93630",
    "label": "Kerman - CA 93630",
    "county": "Fresno County",
    "state": "CA",
    "city": "Kerman"
  },
  {
    "value": "93631",
    "label": "Kingsburg - CA 93631",
    "county": "Fresno County",
    "state": "CA",
    "city": "Kingsburg"
  },
  {
    "value": "93635",
    "label": "Los Banos - CA 93635",
    "county": "Merced County",
    "state": "CA",
    "city": "Los Banos"
  },
  {
    "value": "93636",
    "label": "Madera - CA 93636",
    "county": "Madera County",
    "state": "CA",
    "city": "Madera"
  },
  {
    "value": "93637",
    "label": "Madera - CA 93637",
    "county": "Madera County",
    "state": "CA",
    "city": "Madera"
  },
  {
    "value": "93638",
    "label": "Madera - CA 93638",
    "county": "Madera County",
    "state": "CA",
    "city": "Madera"
  },
  {
    "value": "93640",
    "label": "Mendota - CA 93640",
    "county": "Fresno County",
    "state": "CA",
    "city": "Mendota"
  },
  {
    "value": "93641",
    "label": "Miramonte - CA 93641",
    "county": "Fresno County",
    "state": "CA",
    "city": "Miramonte"
  },
  {
    "value": "93643",
    "label": "North Fork - CA 93643",
    "county": "Madera County",
    "state": "CA",
    "city": "North Fork"
  },
  {
    "value": "93644",
    "label": "Oakhurst - CA 93644",
    "county": "Madera County",
    "state": "CA",
    "city": "Oakhurst"
  },
  {
    "value": "93645",
    "label": "O Neals - CA 93645",
    "county": "Madera County",
    "state": "CA",
    "city": "O Neals"
  },
  {
    "value": "93646",
    "label": "Orange Cove - CA 93646",
    "county": "Fresno County",
    "state": "CA",
    "city": "Orange Cove"
  },
  {
    "value": "93647",
    "label": "Orosi - CA 93647",
    "county": "Tulare County",
    "state": "CA",
    "city": "Orosi"
  },
  {
    "value": "93648",
    "label": "Parlier - CA 93648",
    "county": "Fresno County",
    "state": "CA",
    "city": "Parlier"
  },
  {
    "value": "93650",
    "label": "Fresno - CA 93650",
    "county": "Fresno County",
    "state": "CA",
    "city": "Fresno"
  },
  {
    "value": "93651",
    "label": "Prather - CA 93651",
    "county": "Fresno County",
    "state": "CA",
    "city": "Prather"
  },
  {
    "value": "93652",
    "label": "Raisin City - CA 93652",
    "county": "Fresno County",
    "state": "CA",
    "city": "Raisin City"
  },
  {
    "value": "93653",
    "label": "Raymond - CA 93653",
    "county": "Madera County",
    "state": "CA",
    "city": "Raymond"
  },
  {
    "value": "93654",
    "label": "Reedley - CA 93654",
    "county": "Fresno County",
    "state": "CA",
    "city": "Reedley"
  },
  {
    "value": "93656",
    "label": "Riverdale - CA 93656",
    "county": "Fresno County",
    "state": "CA",
    "city": "Riverdale"
  },
  {
    "value": "93657",
    "label": "Sanger - CA 93657",
    "county": "Fresno County",
    "state": "CA",
    "city": "Sanger"
  },
  {
    "value": "93660",
    "label": "San Joaquin - CA 93660",
    "county": "Fresno County",
    "state": "CA",
    "city": "San Joaquin"
  },
  {
    "value": "93662",
    "label": "Selma - CA 93662",
    "county": "Fresno County",
    "state": "CA",
    "city": "Selma"
  },
  {
    "value": "93664",
    "label": "Shaver Lake - CA 93664",
    "county": "Fresno County",
    "state": "CA",
    "city": "Shaver Lake"
  },
  {
    "value": "93667",
    "label": "Tollhouse - CA 93667",
    "county": "Fresno County",
    "state": "CA",
    "city": "Tollhouse"
  },
  {
    "value": "93668",
    "label": "Tranquillity - CA 93668",
    "county": "Fresno County",
    "state": "CA",
    "city": "Tranquillity"
  },
  {
    "value": "93669",
    "label": "Wishon - CA 93669",
    "county": "Madera County",
    "state": "CA",
    "city": "Wishon"
  },
  {
    "value": "93675",
    "label": "Squaw Valley - CA 93675",
    "county": "Fresno County",
    "state": "CA",
    "city": "Squaw Valley"
  },
  {
    "value": "93701",
    "label": "Fresno - CA 93701",
    "county": "Fresno County",
    "state": "CA",
    "city": "Fresno"
  },
  {
    "value": "93702",
    "label": "Fresno - CA 93702",
    "county": "Fresno County",
    "state": "CA",
    "city": "Fresno"
  },
  {
    "value": "93703",
    "label": "Fresno - CA 93703",
    "county": "Fresno County",
    "state": "CA",
    "city": "Fresno"
  },
  {
    "value": "93704",
    "label": "Fresno - CA 93704",
    "county": "Fresno County",
    "state": "CA",
    "city": "Fresno"
  },
  {
    "value": "93705",
    "label": "Fresno - CA 93705",
    "county": "Fresno County",
    "state": "CA",
    "city": "Fresno"
  },
  {
    "value": "93706",
    "label": "Fresno - CA 93706",
    "county": "Fresno County",
    "state": "CA",
    "city": "Fresno"
  },
  {
    "value": "93710",
    "label": "Fresno - CA 93710",
    "county": "Fresno County",
    "state": "CA",
    "city": "Fresno"
  },
  {
    "value": "93711",
    "label": "Fresno - CA 93711",
    "county": "Fresno County",
    "state": "CA",
    "city": "Fresno"
  },
  {
    "value": "93720",
    "label": "Fresno - CA 93720",
    "county": "Fresno County",
    "state": "CA",
    "city": "Fresno"
  },
  {
    "value": "93721",
    "label": "Fresno - CA 93721",
    "county": "Fresno County",
    "state": "CA",
    "city": "Fresno"
  },
  {
    "value": "93722",
    "label": "Fresno - CA 93722",
    "county": "Fresno County",
    "state": "CA",
    "city": "Fresno"
  },
  {
    "value": "93723",
    "label": "Fresno - CA 93723",
    "county": "Fresno County",
    "state": "CA",
    "city": "Fresno"
  },
  {
    "value": "93725",
    "label": "Fresno - CA 93725",
    "county": "Fresno County",
    "state": "CA",
    "city": "Fresno"
  },
  {
    "value": "93726",
    "label": "Fresno - CA 93726",
    "county": "Fresno County",
    "state": "CA",
    "city": "Fresno"
  },
  {
    "value": "93727",
    "label": "Fresno - CA 93727",
    "county": "Fresno County",
    "state": "CA",
    "city": "Fresno"
  },
  {
    "value": "93728",
    "label": "Fresno - CA 93728",
    "county": "Fresno County",
    "state": "CA",
    "city": "Fresno"
  },
  {
    "value": "93730",
    "label": "Fresno - CA 93730",
    "county": "Fresno County",
    "state": "CA",
    "city": "Fresno"
  },
  {
    "value": "93737",
    "label": "Fresno - CA 93737",
    "county": "Fresno County",
    "state": "CA",
    "city": "Fresno"
  },
  {
    "value": "93901",
    "label": "Salinas - CA 93901",
    "county": "Monterey County",
    "state": "CA",
    "city": "Salinas"
  },
  {
    "value": "93905",
    "label": "Salinas - CA 93905",
    "county": "Monterey County",
    "state": "CA",
    "city": "Salinas"
  },
  {
    "value": "93906",
    "label": "Salinas - CA 93906",
    "county": "Monterey County",
    "state": "CA",
    "city": "Salinas"
  },
  {
    "value": "93907",
    "label": "Salinas - CA 93907",
    "county": "Monterey County",
    "state": "CA",
    "city": "Salinas"
  },
  {
    "value": "93908",
    "label": "Salinas - CA 93908",
    "county": "Monterey County",
    "state": "CA",
    "city": "Salinas"
  },
  {
    "value": "93920",
    "label": "Big Sur - CA 93920",
    "county": "Monterey County",
    "state": "CA",
    "city": "Big Sur"
  },
  {
    "value": "93923",
    "label": "Carmel - CA 93923",
    "county": "Monterey County",
    "state": "CA",
    "city": "Carmel"
  },
  {
    "value": "93924",
    "label": "Carmel Valley - CA 93924",
    "county": "Monterey County",
    "state": "CA",
    "city": "Carmel Valley"
  },
  {
    "value": "93925",
    "label": "Chualar - CA 93925",
    "county": "Monterey County",
    "state": "CA",
    "city": "Chualar"
  },
  {
    "value": "93926",
    "label": "Gonzales - CA 93926",
    "county": "Monterey County",
    "state": "CA",
    "city": "Gonzales"
  },
  {
    "value": "93927",
    "label": "Greenfield - CA 93927",
    "county": "Monterey County",
    "state": "CA",
    "city": "Greenfield"
  },
  {
    "value": "93930",
    "label": "King City - CA 93930",
    "county": "Monterey County",
    "state": "CA",
    "city": "King City"
  },
  {
    "value": "93933",
    "label": "Marina - CA 93933",
    "county": "Monterey County",
    "state": "CA",
    "city": "Marina"
  },
  {
    "value": "93940",
    "label": "Monterey - CA 93940",
    "county": "Monterey County",
    "state": "CA",
    "city": "Monterey"
  },
  {
    "value": "93943",
    "label": "Monterey - CA 93943",
    "county": "Monterey County",
    "state": "CA",
    "city": "Monterey"
  },
  {
    "value": "93950",
    "label": "Pacific Grove - CA 93950",
    "county": "Monterey County",
    "state": "CA",
    "city": "Pacific Grove"
  },
  {
    "value": "93953",
    "label": "Pebble Beach - CA 93953",
    "county": "Monterey County",
    "state": "CA",
    "city": "Pebble Beach"
  },
  {
    "value": "93955",
    "label": "Seaside - CA 93955",
    "county": "Monterey County",
    "state": "CA",
    "city": "Seaside"
  },
  {
    "value": "93960",
    "label": "Soledad - CA 93960",
    "county": "Monterey County",
    "state": "CA",
    "city": "Soledad"
  },
  {
    "value": "94002",
    "label": "Belmont - CA 94002",
    "county": "San Mateo County",
    "state": "CA",
    "city": "Belmont"
  },
  {
    "value": "94005",
    "label": "Brisbane - CA 94005",
    "county": "San Mateo County",
    "state": "CA",
    "city": "Brisbane"
  },
  {
    "value": "94010",
    "label": "Burlingame - CA 94010",
    "county": "San Mateo County",
    "state": "CA",
    "city": "Burlingame"
  },
  {
    "value": "94014",
    "label": "Daly City - CA 94014",
    "county": "San Mateo County",
    "state": "CA",
    "city": "Daly City"
  },
  {
    "value": "94015",
    "label": "Daly City - CA 94015",
    "county": "San Mateo County",
    "state": "CA",
    "city": "Daly City"
  },
  {
    "value": "94019",
    "label": "Half Moon Bay - CA 94019",
    "county": "San Mateo County",
    "state": "CA",
    "city": "Half Moon Bay"
  },
  {
    "value": "94020",
    "label": "La Honda - CA 94020",
    "county": "San Mateo County",
    "state": "CA",
    "city": "La Honda"
  },
  {
    "value": "94021",
    "label": "Loma Mar - CA 94021",
    "county": "San Mateo County",
    "state": "CA",
    "city": "Loma Mar"
  },
  {
    "value": "94022",
    "label": "Los Altos - CA 94022",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "Los Altos"
  },
  {
    "value": "94024",
    "label": "Los Altos - CA 94024",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "Los Altos"
  },
  {
    "value": "94025",
    "label": "Menlo Park - CA 94025",
    "county": "San Mateo County",
    "state": "CA",
    "city": "Menlo Park"
  },
  {
    "value": "94027",
    "label": "Atherton - CA 94027",
    "county": "San Mateo County",
    "state": "CA",
    "city": "Atherton"
  },
  {
    "value": "94028",
    "label": "Portola Valley - CA 94028",
    "county": "San Mateo County",
    "state": "CA",
    "city": "Portola Valley"
  },
  {
    "value": "94030",
    "label": "Millbrae - CA 94030",
    "county": "San Mateo County",
    "state": "CA",
    "city": "Millbrae"
  },
  {
    "value": "94038",
    "label": "Moss Beach - CA 94038",
    "county": "San Mateo County",
    "state": "CA",
    "city": "Moss Beach"
  },
  {
    "value": "94040",
    "label": "Mountain View - CA 94040",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "Mountain View"
  },
  {
    "value": "94041",
    "label": "Mountain View - CA 94041",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "Mountain View"
  },
  {
    "value": "94043",
    "label": "Mountain View - CA 94043",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "Mountain View"
  },
  {
    "value": "94044",
    "label": "Pacifica - CA 94044",
    "county": "San Mateo County",
    "state": "CA",
    "city": "Pacifica"
  },
  {
    "value": "94060",
    "label": "Pescadero - CA 94060",
    "county": "San Mateo County",
    "state": "CA",
    "city": "Pescadero"
  },
  {
    "value": "94061",
    "label": "Redwood City - CA 94061",
    "county": "San Mateo County",
    "state": "CA",
    "city": "Redwood City"
  },
  {
    "value": "94062",
    "label": "Redwood City - CA 94062",
    "county": "San Mateo County",
    "state": "CA",
    "city": "Redwood City"
  },
  {
    "value": "94063",
    "label": "Redwood City - CA 94063",
    "county": "San Mateo County",
    "state": "CA",
    "city": "Redwood City"
  },
  {
    "value": "94065",
    "label": "Redwood City - CA 94065",
    "county": "San Mateo County",
    "state": "CA",
    "city": "Redwood City"
  },
  {
    "value": "94066",
    "label": "San Bruno - CA 94066",
    "county": "San Mateo County",
    "state": "CA",
    "city": "San Bruno"
  },
  {
    "value": "94070",
    "label": "San Carlos - CA 94070",
    "county": "San Mateo County",
    "state": "CA",
    "city": "San Carlos"
  },
  {
    "value": "94074",
    "label": "San Gregorio - CA 94074",
    "county": "San Mateo County",
    "state": "CA",
    "city": "San Gregorio"
  },
  {
    "value": "94080",
    "label": "South San Francisco - CA 94080",
    "county": "San Mateo County",
    "state": "CA",
    "city": "South San Francisco"
  },
  {
    "value": "94085",
    "label": "Sunnyvale - CA 94085",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "Sunnyvale"
  },
  {
    "value": "94086",
    "label": "Sunnyvale - CA 94086",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "Sunnyvale"
  },
  {
    "value": "94087",
    "label": "Sunnyvale - CA 94087",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "Sunnyvale"
  },
  {
    "value": "94089",
    "label": "Sunnyvale - CA 94089",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "Sunnyvale"
  },
  {
    "value": "94101",
    "label": "San Francisco - CA 94101",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94102",
    "label": "San Francisco - CA 94102",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94103",
    "label": "San Francisco - CA 94103",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94104",
    "label": "San Francisco - CA 94104",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94105",
    "label": "San Francisco - CA 94105",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94107",
    "label": "San Francisco - CA 94107",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94108",
    "label": "San Francisco - CA 94108",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94109",
    "label": "San Francisco - CA 94109",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94110",
    "label": "San Francisco - CA 94110",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94111",
    "label": "San Francisco - CA 94111",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94112",
    "label": "San Francisco - CA 94112",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94114",
    "label": "San Francisco - CA 94114",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94115",
    "label": "San Francisco - CA 94115",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94116",
    "label": "San Francisco - CA 94116",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94117",
    "label": "San Francisco - CA 94117",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94118",
    "label": "San Francisco - CA 94118",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94121",
    "label": "San Francisco - CA 94121",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94122",
    "label": "San Francisco - CA 94122",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94123",
    "label": "San Francisco - CA 94123",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94124",
    "label": "San Francisco - CA 94124",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94127",
    "label": "San Francisco - CA 94127",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94128",
    "label": "San Francisco - CA 94128",
    "county": "San Mateo County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94129",
    "label": "San Francisco - CA 94129",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94130",
    "label": "San Francisco - CA 94130",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94131",
    "label": "San Francisco - CA 94131",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94132",
    "label": "San Francisco - CA 94132",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94133",
    "label": "San Francisco - CA 94133",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94134",
    "label": "San Francisco - CA 94134",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94141",
    "label": "San Francisco - CA 94141",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94143",
    "label": "San Francisco - CA 94143",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94158",
    "label": "San Francisco - CA 94158",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94188",
    "label": "San Francisco - CA 94188",
    "county": "San Francisco County",
    "state": "CA",
    "city": "San Francisco"
  },
  {
    "value": "94301",
    "label": "Palo Alto - CA 94301",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "Palo Alto"
  },
  {
    "value": "94303",
    "label": "Palo Alto - CA 94303",
    "county": "San Mateo County",
    "state": "CA",
    "city": "Palo Alto"
  },
  {
    "value": "94304",
    "label": "Palo Alto - CA 94304",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "Palo Alto"
  },
  {
    "value": "94305",
    "label": "Stanford - CA 94305",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "Stanford"
  },
  {
    "value": "94306",
    "label": "Palo Alto - CA 94306",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "Palo Alto"
  },
  {
    "value": "94401",
    "label": "San Mateo - CA 94401",
    "county": "San Mateo County",
    "state": "CA",
    "city": "San Mateo"
  },
  {
    "value": "94402",
    "label": "San Mateo - CA 94402",
    "county": "San Mateo County",
    "state": "CA",
    "city": "San Mateo"
  },
  {
    "value": "94403",
    "label": "San Mateo - CA 94403",
    "county": "San Mateo County",
    "state": "CA",
    "city": "San Mateo"
  },
  {
    "value": "94404",
    "label": "San Mateo - CA 94404",
    "county": "San Mateo County",
    "state": "CA",
    "city": "San Mateo"
  },
  {
    "value": "94501",
    "label": "Alameda - CA 94501",
    "county": "Alameda County",
    "state": "CA",
    "city": "Alameda"
  },
  {
    "value": "94502",
    "label": "Alameda - CA 94502",
    "county": "Alameda County",
    "state": "CA",
    "city": "Alameda"
  },
  {
    "value": "94503",
    "label": "American Canyon - CA 94503",
    "county": "Napa County",
    "state": "CA",
    "city": "American Canyon"
  },
  {
    "value": "94505",
    "label": "Discovery Bay - CA 94505",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Discovery Bay"
  },
  {
    "value": "94506",
    "label": "Danville - CA 94506",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Danville"
  },
  {
    "value": "94507",
    "label": "Alamo - CA 94507",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Alamo"
  },
  {
    "value": "94508",
    "label": "Angwin - CA 94508",
    "county": "Napa County",
    "state": "CA",
    "city": "Angwin"
  },
  {
    "value": "94509",
    "label": "Antioch - CA 94509",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Antioch"
  },
  {
    "value": "94510",
    "label": "Benicia - CA 94510",
    "county": "Solano County",
    "state": "CA",
    "city": "Benicia"
  },
  {
    "value": "94512",
    "label": "Birds Landing - CA 94512",
    "county": "Solano County",
    "state": "CA",
    "city": "Birds Landing"
  },
  {
    "value": "94513",
    "label": "Brentwood - CA 94513",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Brentwood"
  },
  {
    "value": "94514",
    "label": "Byron - CA 94514",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Byron"
  },
  {
    "value": "94515",
    "label": "Calistoga - CA 94515",
    "county": "Napa County",
    "state": "CA",
    "city": "Calistoga"
  },
  {
    "value": "94517",
    "label": "Clayton - CA 94517",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Clayton"
  },
  {
    "value": "94518",
    "label": "Concord - CA 94518",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Concord"
  },
  {
    "value": "94519",
    "label": "Concord - CA 94519",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Concord"
  },
  {
    "value": "94520",
    "label": "Concord - CA 94520",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Concord"
  },
  {
    "value": "94521",
    "label": "Concord - CA 94521",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Concord"
  },
  {
    "value": "94523",
    "label": "Pleasant Hill - CA 94523",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Pleasant Hill"
  },
  {
    "value": "94525",
    "label": "Crockett - CA 94525",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Crockett"
  },
  {
    "value": "94526",
    "label": "Danville - CA 94526",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Danville"
  },
  {
    "value": "94530",
    "label": "El Cerrito - CA 94530",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "El Cerrito"
  },
  {
    "value": "94531",
    "label": "Antioch - CA 94531",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Antioch"
  },
  {
    "value": "94533",
    "label": "Fairfield - CA 94533",
    "county": "Solano County",
    "state": "CA",
    "city": "Fairfield"
  },
  {
    "value": "94534",
    "label": "Fairfield - CA 94534",
    "county": "Solano County",
    "state": "CA",
    "city": "Fairfield"
  },
  {
    "value": "94535",
    "label": "Travis AFB - CA 94535",
    "county": "Solano County",
    "state": "CA",
    "city": "Travis AFB"
  },
  {
    "value": "94536",
    "label": "Fremont - CA 94536",
    "county": "Alameda County",
    "state": "CA",
    "city": "Fremont"
  },
  {
    "value": "94538",
    "label": "Fremont - CA 94538",
    "county": "Alameda County",
    "state": "CA",
    "city": "Fremont"
  },
  {
    "value": "94539",
    "label": "Fremont - CA 94539",
    "county": "Alameda County",
    "state": "CA",
    "city": "Fremont"
  },
  {
    "value": "94541",
    "label": "Hayward - CA 94541",
    "county": "Alameda County",
    "state": "CA",
    "city": "Hayward"
  },
  {
    "value": "94542",
    "label": "Hayward - CA 94542",
    "county": "Alameda County",
    "state": "CA",
    "city": "Hayward"
  },
  {
    "value": "94544",
    "label": "Hayward - CA 94544",
    "county": "Alameda County",
    "state": "CA",
    "city": "Hayward"
  },
  {
    "value": "94545",
    "label": "Hayward - CA 94545",
    "county": "Alameda County",
    "state": "CA",
    "city": "Hayward"
  },
  {
    "value": "94546",
    "label": "Castro Valley - CA 94546",
    "county": "Alameda County",
    "state": "CA",
    "city": "Castro Valley"
  },
  {
    "value": "94547",
    "label": "Hercules - CA 94547",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Hercules"
  },
  {
    "value": "94549",
    "label": "Lafayette - CA 94549",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Lafayette"
  },
  {
    "value": "94550",
    "label": "Livermore - CA 94550",
    "county": "Alameda County",
    "state": "CA",
    "city": "Livermore"
  },
  {
    "value": "94551",
    "label": "Livermore - CA 94551",
    "county": "Alameda County",
    "state": "CA",
    "city": "Livermore"
  },
  {
    "value": "94552",
    "label": "Castro Valley - CA 94552",
    "county": "Alameda County",
    "state": "CA",
    "city": "Castro Valley"
  },
  {
    "value": "94553",
    "label": "Martinez - CA 94553",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Martinez"
  },
  {
    "value": "94555",
    "label": "Fremont - CA 94555",
    "county": "Alameda County",
    "state": "CA",
    "city": "Fremont"
  },
  {
    "value": "94556",
    "label": "Moraga - CA 94556",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Moraga"
  },
  {
    "value": "94558",
    "label": "Napa - CA 94558",
    "county": "Napa County",
    "state": "CA",
    "city": "Napa"
  },
  {
    "value": "94559",
    "label": "Napa - CA 94559",
    "county": "Napa County",
    "state": "CA",
    "city": "Napa"
  },
  {
    "value": "94560",
    "label": "Newark - CA 94560",
    "county": "Alameda County",
    "state": "CA",
    "city": "Newark"
  },
  {
    "value": "94561",
    "label": "Oakley - CA 94561",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Oakley"
  },
  {
    "value": "94563",
    "label": "Orinda - CA 94563",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Orinda"
  },
  {
    "value": "94564",
    "label": "Pinole - CA 94564",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Pinole"
  },
  {
    "value": "94565",
    "label": "Pittsburg - CA 94565",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Pittsburg"
  },
  {
    "value": "94566",
    "label": "Pleasanton - CA 94566",
    "county": "Alameda County",
    "state": "CA",
    "city": "Pleasanton"
  },
  {
    "value": "94567",
    "label": "Pope Valley - CA 94567",
    "county": "Napa County",
    "state": "CA",
    "city": "Pope Valley"
  },
  {
    "value": "94568",
    "label": "Dublin - CA 94568",
    "county": "Alameda County",
    "state": "CA",
    "city": "Dublin"
  },
  {
    "value": "94571",
    "label": "Rio Vista - CA 94571",
    "county": "Solano County",
    "state": "CA",
    "city": "Rio Vista"
  },
  {
    "value": "94572",
    "label": "Rodeo - CA 94572",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Rodeo"
  },
  {
    "value": "94574",
    "label": "Saint Helena - CA 94574",
    "county": "Napa County",
    "state": "CA",
    "city": "Saint Helena"
  },
  {
    "value": "94575",
    "label": "Moraga - CA 94575",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Moraga"
  },
  {
    "value": "94576",
    "label": "Deer Park - CA 94576",
    "county": "Napa County",
    "state": "CA",
    "city": "Deer Park"
  },
  {
    "value": "94577",
    "label": "San Leandro - CA 94577",
    "county": "Alameda County",
    "state": "CA",
    "city": "San Leandro"
  },
  {
    "value": "94578",
    "label": "San Leandro - CA 94578",
    "county": "Alameda County",
    "state": "CA",
    "city": "San Leandro"
  },
  {
    "value": "94579",
    "label": "San Leandro - CA 94579",
    "county": "Alameda County",
    "state": "CA",
    "city": "San Leandro"
  },
  {
    "value": "94580",
    "label": "San Lorenzo - CA 94580",
    "county": "Alameda County",
    "state": "CA",
    "city": "San Lorenzo"
  },
  {
    "value": "94582",
    "label": "San Ramon - CA 94582",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "San Ramon"
  },
  {
    "value": "94583",
    "label": "San Ramon - CA 94583",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "San Ramon"
  },
  {
    "value": "94585",
    "label": "Suisun City - CA 94585",
    "county": "Solano County",
    "state": "CA",
    "city": "Suisun City"
  },
  {
    "value": "94586",
    "label": "Sunol - CA 94586",
    "county": "Alameda County",
    "state": "CA",
    "city": "Sunol"
  },
  {
    "value": "94587",
    "label": "Union City - CA 94587",
    "county": "Alameda County",
    "state": "CA",
    "city": "Union City"
  },
  {
    "value": "94588",
    "label": "Pleasanton - CA 94588",
    "county": "Alameda County",
    "state": "CA",
    "city": "Pleasanton"
  },
  {
    "value": "94589",
    "label": "Vallejo - CA 94589",
    "county": "Solano County",
    "state": "CA",
    "city": "Vallejo"
  },
  {
    "value": "94590",
    "label": "Vallejo - CA 94590",
    "county": "Solano County",
    "state": "CA",
    "city": "Vallejo"
  },
  {
    "value": "94591",
    "label": "Vallejo - CA 94591",
    "county": "Solano County",
    "state": "CA",
    "city": "Vallejo"
  },
  {
    "value": "94592",
    "label": "Vallejo - CA 94592",
    "county": "Solano County",
    "state": "CA",
    "city": "Vallejo"
  },
  {
    "value": "94595",
    "label": "Walnut Creek - CA 94595",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Walnut Creek"
  },
  {
    "value": "94596",
    "label": "Walnut Creek - CA 94596",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Walnut Creek"
  },
  {
    "value": "94597",
    "label": "Walnut Creek - CA 94597",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Walnut Creek"
  },
  {
    "value": "94598",
    "label": "Walnut Creek - CA 94598",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Walnut Creek"
  },
  {
    "value": "94599",
    "label": "Yountville - CA 94599",
    "county": "Napa County",
    "state": "CA",
    "city": "Yountville"
  },
  {
    "value": "94601",
    "label": "Oakland - CA 94601",
    "county": "Alameda County",
    "state": "CA",
    "city": "Oakland"
  },
  {
    "value": "94602",
    "label": "Oakland - CA 94602",
    "county": "Alameda County",
    "state": "CA",
    "city": "Oakland"
  },
  {
    "value": "94603",
    "label": "Oakland - CA 94603",
    "county": "Alameda County",
    "state": "CA",
    "city": "Oakland"
  },
  {
    "value": "94605",
    "label": "Oakland - CA 94605",
    "county": "Alameda County",
    "state": "CA",
    "city": "Oakland"
  },
  {
    "value": "94606",
    "label": "Oakland - CA 94606",
    "county": "Alameda County",
    "state": "CA",
    "city": "Oakland"
  },
  {
    "value": "94607",
    "label": "Oakland - CA 94607",
    "county": "Alameda County",
    "state": "CA",
    "city": "Oakland"
  },
  {
    "value": "94608",
    "label": "Emeryville - CA 94608",
    "county": "Alameda County",
    "state": "CA",
    "city": "Emeryville"
  },
  {
    "value": "94609",
    "label": "Oakland - CA 94609",
    "county": "Alameda County",
    "state": "CA",
    "city": "Oakland"
  },
  {
    "value": "94610",
    "label": "Oakland - CA 94610",
    "county": "Alameda County",
    "state": "CA",
    "city": "Oakland"
  },
  {
    "value": "94611",
    "label": "Oakland - CA 94611",
    "county": "Alameda County",
    "state": "CA",
    "city": "Oakland"
  },
  {
    "value": "94612",
    "label": "Oakland - CA 94612",
    "county": "Alameda County",
    "state": "CA",
    "city": "Oakland"
  },
  {
    "value": "94615",
    "label": "Oakland - CA 94615",
    "county": "Alameda County",
    "state": "CA",
    "city": "Oakland"
  },
  {
    "value": "94617",
    "label": "Oakland - CA 94617",
    "county": "Alameda County",
    "state": "CA",
    "city": "Oakland"
  },
  {
    "value": "94618",
    "label": "Oakland - CA 94618",
    "county": "Alameda County",
    "state": "CA",
    "city": "Oakland"
  },
  {
    "value": "94619",
    "label": "Oakland - CA 94619",
    "county": "Alameda County",
    "state": "CA",
    "city": "Oakland"
  },
  {
    "value": "94621",
    "label": "Oakland - CA 94621",
    "county": "Alameda County",
    "state": "CA",
    "city": "Oakland"
  },
  {
    "value": "94702",
    "label": "Berkeley - CA 94702",
    "county": "Alameda County",
    "state": "CA",
    "city": "Berkeley"
  },
  {
    "value": "94703",
    "label": "Berkeley - CA 94703",
    "county": "Alameda County",
    "state": "CA",
    "city": "Berkeley"
  },
  {
    "value": "94704",
    "label": "Berkeley - CA 94704",
    "county": "Alameda County",
    "state": "CA",
    "city": "Berkeley"
  },
  {
    "value": "94705",
    "label": "Berkeley - CA 94705",
    "county": "Alameda County",
    "state": "CA",
    "city": "Berkeley"
  },
  {
    "value": "94706",
    "label": "Albany - CA 94706",
    "county": "Alameda County",
    "state": "CA",
    "city": "Albany"
  },
  {
    "value": "94707",
    "label": "Berkeley - CA 94707",
    "county": "Alameda County",
    "state": "CA",
    "city": "Berkeley"
  },
  {
    "value": "94708",
    "label": "Berkeley - CA 94708",
    "county": "Alameda County",
    "state": "CA",
    "city": "Berkeley"
  },
  {
    "value": "94709",
    "label": "Berkeley - CA 94709",
    "county": "Alameda County",
    "state": "CA",
    "city": "Berkeley"
  },
  {
    "value": "94710",
    "label": "Berkeley - CA 94710",
    "county": "Alameda County",
    "state": "CA",
    "city": "Berkeley"
  },
  {
    "value": "94801",
    "label": "Richmond - CA 94801",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Richmond"
  },
  {
    "value": "94803",
    "label": "El Sobrante - CA 94803",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "El Sobrante"
  },
  {
    "value": "94804",
    "label": "Richmond - CA 94804",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Richmond"
  },
  {
    "value": "94805",
    "label": "Richmond - CA 94805",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Richmond"
  },
  {
    "value": "94806",
    "label": "San Pablo - CA 94806",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "San Pablo"
  },
  {
    "value": "94850",
    "label": "Richmond - CA 94850",
    "county": "Contra Costa County",
    "state": "CA",
    "city": "Richmond"
  },
  {
    "value": "94901",
    "label": "San Rafael - CA 94901",
    "county": "Marin County",
    "state": "CA",
    "city": "San Rafael"
  },
  {
    "value": "94903",
    "label": "San Rafael - CA 94903",
    "county": "Marin County",
    "state": "CA",
    "city": "San Rafael"
  },
  {
    "value": "94904",
    "label": "Greenbrae - CA 94904",
    "county": "Marin County",
    "state": "CA",
    "city": "Greenbrae"
  },
  {
    "value": "94920",
    "label": "Belvedere Tiburon - CA 94920",
    "county": "Marin County",
    "state": "CA",
    "city": "Belvedere Tiburon"
  },
  {
    "value": "94922",
    "label": "Bodega - CA 94922",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Bodega"
  },
  {
    "value": "94923",
    "label": "Bodega Bay - CA 94923",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Bodega Bay"
  },
  {
    "value": "94924",
    "label": "Bolinas - CA 94924",
    "county": "Marin County",
    "state": "CA",
    "city": "Bolinas"
  },
  {
    "value": "94925",
    "label": "Corte Madera - CA 94925",
    "county": "Marin County",
    "state": "CA",
    "city": "Corte Madera"
  },
  {
    "value": "94928",
    "label": "Rohnert Park - CA 94928",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Rohnert Park"
  },
  {
    "value": "94930",
    "label": "Fairfax - CA 94930",
    "county": "Marin County",
    "state": "CA",
    "city": "Fairfax"
  },
  {
    "value": "94931",
    "label": "Cotati - CA 94931",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Cotati"
  },
  {
    "value": "94937",
    "label": "Inverness - CA 94937",
    "county": "Marin County",
    "state": "CA",
    "city": "Inverness"
  },
  {
    "value": "94939",
    "label": "Larkspur - CA 94939",
    "county": "Marin County",
    "state": "CA",
    "city": "Larkspur"
  },
  {
    "value": "94940",
    "label": "Marshall - CA 94940",
    "county": "Marin County",
    "state": "CA",
    "city": "Marshall"
  },
  {
    "value": "94941",
    "label": "Mill Valley - CA 94941",
    "county": "Marin County",
    "state": "CA",
    "city": "Mill Valley"
  },
  {
    "value": "94945",
    "label": "Novato - CA 94945",
    "county": "Marin County",
    "state": "CA",
    "city": "Novato"
  },
  {
    "value": "94946",
    "label": "Nicasio - CA 94946",
    "county": "Marin County",
    "state": "CA",
    "city": "Nicasio"
  },
  {
    "value": "94947",
    "label": "Novato - CA 94947",
    "county": "Marin County",
    "state": "CA",
    "city": "Novato"
  },
  {
    "value": "94949",
    "label": "Novato - CA 94949",
    "county": "Marin County",
    "state": "CA",
    "city": "Novato"
  },
  {
    "value": "94951",
    "label": "Penngrove - CA 94951",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Penngrove"
  },
  {
    "value": "94952",
    "label": "Petaluma - CA 94952",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Petaluma"
  },
  {
    "value": "94954",
    "label": "Petaluma - CA 94954",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Petaluma"
  },
  {
    "value": "94956",
    "label": "Point Reyes Station - CA 94956",
    "county": "Marin County",
    "state": "CA",
    "city": "Point Reyes Station"
  },
  {
    "value": "94960",
    "label": "San Anselmo - CA 94960",
    "county": "Marin County",
    "state": "CA",
    "city": "San Anselmo"
  },
  {
    "value": "94965",
    "label": "Sausalito - CA 94965",
    "county": "Marin County",
    "state": "CA",
    "city": "Sausalito"
  },
  {
    "value": "94970",
    "label": "Stinson Beach - CA 94970",
    "county": "Marin County",
    "state": "CA",
    "city": "Stinson Beach"
  },
  {
    "value": "94972",
    "label": "Valley Ford - CA 94972",
    "county": "Marin County",
    "state": "CA",
    "city": "Valley Ford"
  },
  {
    "value": "94974",
    "label": "San Quentin - CA 94974",
    "county": "Marin County",
    "state": "CA",
    "city": "San Quentin"
  },
  {
    "value": "94999",
    "label": "Petaluma - CA 94999",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Petaluma"
  },
  {
    "value": "95003",
    "label": "Aptos - CA 95003",
    "county": "Santa Cruz County",
    "state": "CA",
    "city": "Aptos"
  },
  {
    "value": "95004",
    "label": "Aromas - CA 95004",
    "county": "Monterey County",
    "state": "CA",
    "city": "Aromas"
  },
  {
    "value": "95005",
    "label": "Ben Lomond - CA 95005",
    "county": "Santa Cruz County",
    "state": "CA",
    "city": "Ben Lomond"
  },
  {
    "value": "95006",
    "label": "Boulder Creek - CA 95006",
    "county": "Santa Cruz County",
    "state": "CA",
    "city": "Boulder Creek"
  },
  {
    "value": "95008",
    "label": "Campbell - CA 95008",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "Campbell"
  },
  {
    "value": "95010",
    "label": "Capitola - CA 95010",
    "county": "Santa Cruz County",
    "state": "CA",
    "city": "Capitola"
  },
  {
    "value": "95012",
    "label": "Castroville - CA 95012",
    "county": "Monterey County",
    "state": "CA",
    "city": "Castroville"
  },
  {
    "value": "95014",
    "label": "Cupertino - CA 95014",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "Cupertino"
  },
  {
    "value": "95017",
    "label": "Davenport - CA 95017",
    "county": "Santa Cruz County",
    "state": "CA",
    "city": "Davenport"
  },
  {
    "value": "95018",
    "label": "Felton - CA 95018",
    "county": "Santa Cruz County",
    "state": "CA",
    "city": "Felton"
  },
  {
    "value": "95019",
    "label": "Freedom - CA 95019",
    "county": "Santa Cruz County",
    "state": "CA",
    "city": "Freedom"
  },
  {
    "value": "95020",
    "label": "Gilroy - CA 95020",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "Gilroy"
  },
  {
    "value": "95023",
    "label": "Hollister - CA 95023",
    "county": "San Benito County",
    "state": "CA",
    "city": "Hollister"
  },
  {
    "value": "95030",
    "label": "Los Gatos - CA 95030",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "Los Gatos"
  },
  {
    "value": "95032",
    "label": "Los Gatos - CA 95032",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "Los Gatos"
  },
  {
    "value": "95033",
    "label": "Los Gatos - CA 95033",
    "county": "Santa Cruz County",
    "state": "CA",
    "city": "Los Gatos"
  },
  {
    "value": "95035",
    "label": "Milpitas - CA 95035",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "Milpitas"
  },
  {
    "value": "95037",
    "label": "Morgan Hill - CA 95037",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "Morgan Hill"
  },
  {
    "value": "95039",
    "label": "Moss Landing - CA 95039",
    "county": "Monterey County",
    "state": "CA",
    "city": "Moss Landing"
  },
  {
    "value": "95043",
    "label": "Paicines - CA 95043",
    "county": "San Benito County",
    "state": "CA",
    "city": "Paicines"
  },
  {
    "value": "95045",
    "label": "San Juan Bautista - CA 95045",
    "county": "San Benito County",
    "state": "CA",
    "city": "San Juan Bautista"
  },
  {
    "value": "95046",
    "label": "San Martin - CA 95046",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Martin"
  },
  {
    "value": "95050",
    "label": "Santa Clara - CA 95050",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "Santa Clara"
  },
  {
    "value": "95051",
    "label": "Santa Clara - CA 95051",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "Santa Clara"
  },
  {
    "value": "95054",
    "label": "Santa Clara - CA 95054",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "Santa Clara"
  },
  {
    "value": "95060",
    "label": "Santa Cruz - CA 95060",
    "county": "Santa Cruz County",
    "state": "CA",
    "city": "Santa Cruz"
  },
  {
    "value": "95062",
    "label": "Santa Cruz - CA 95062",
    "county": "Santa Cruz County",
    "state": "CA",
    "city": "Santa Cruz"
  },
  {
    "value": "95064",
    "label": "Santa Cruz - CA 95064",
    "county": "Santa Cruz County",
    "state": "CA",
    "city": "Santa Cruz"
  },
  {
    "value": "95065",
    "label": "Santa Cruz - CA 95065",
    "county": "Santa Cruz County",
    "state": "CA",
    "city": "Santa Cruz"
  },
  {
    "value": "95066",
    "label": "Scotts Valley - CA 95066",
    "county": "Santa Cruz County",
    "state": "CA",
    "city": "Scotts Valley"
  },
  {
    "value": "95070",
    "label": "Saratoga - CA 95070",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "Saratoga"
  },
  {
    "value": "95073",
    "label": "Soquel - CA 95073",
    "county": "Santa Cruz County",
    "state": "CA",
    "city": "Soquel"
  },
  {
    "value": "95075",
    "label": "Tres Pinos - CA 95075",
    "county": "San Benito County",
    "state": "CA",
    "city": "Tres Pinos"
  },
  {
    "value": "95076",
    "label": "Watsonville - CA 95076",
    "county": "Santa Cruz County",
    "state": "CA",
    "city": "Watsonville"
  },
  {
    "value": "95101",
    "label": "San Jose - CA 95101",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95110",
    "label": "San Jose - CA 95110",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95111",
    "label": "San Jose - CA 95111",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95112",
    "label": "San Jose - CA 95112",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95113",
    "label": "San Jose - CA 95113",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95116",
    "label": "San Jose - CA 95116",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95117",
    "label": "San Jose - CA 95117",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95118",
    "label": "San Jose - CA 95118",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95119",
    "label": "San Jose - CA 95119",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95120",
    "label": "San Jose - CA 95120",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95121",
    "label": "San Jose - CA 95121",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95122",
    "label": "San Jose - CA 95122",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95123",
    "label": "San Jose - CA 95123",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95124",
    "label": "San Jose - CA 95124",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95125",
    "label": "San Jose - CA 95125",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95126",
    "label": "San Jose - CA 95126",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95127",
    "label": "San Jose - CA 95127",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95128",
    "label": "San Jose - CA 95128",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95129",
    "label": "San Jose - CA 95129",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95130",
    "label": "San Jose - CA 95130",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95131",
    "label": "San Jose - CA 95131",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95132",
    "label": "San Jose - CA 95132",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95133",
    "label": "San Jose - CA 95133",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95134",
    "label": "San Jose - CA 95134",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95135",
    "label": "San Jose - CA 95135",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95136",
    "label": "San Jose - CA 95136",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95138",
    "label": "San Jose - CA 95138",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95139",
    "label": "San Jose - CA 95139",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95140",
    "label": "Mount Hamilton - CA 95140",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "Mount Hamilton"
  },
  {
    "value": "95141",
    "label": "San Jose - CA 95141",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95148",
    "label": "San Jose - CA 95148",
    "county": "Santa Clara County",
    "state": "CA",
    "city": "San Jose"
  },
  {
    "value": "95202",
    "label": "Stockton - CA 95202",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "Stockton"
  },
  {
    "value": "95203",
    "label": "Stockton - CA 95203",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "Stockton"
  },
  {
    "value": "95204",
    "label": "Stockton - CA 95204",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "Stockton"
  },
  {
    "value": "95205",
    "label": "Stockton - CA 95205",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "Stockton"
  },
  {
    "value": "95206",
    "label": "Stockton - CA 95206",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "Stockton"
  },
  {
    "value": "95207",
    "label": "Stockton - CA 95207",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "Stockton"
  },
  {
    "value": "95209",
    "label": "Stockton - CA 95209",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "Stockton"
  },
  {
    "value": "95210",
    "label": "Stockton - CA 95210",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "Stockton"
  },
  {
    "value": "95212",
    "label": "Stockton - CA 95212",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "Stockton"
  },
  {
    "value": "95215",
    "label": "Stockton - CA 95215",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "Stockton"
  },
  {
    "value": "95219",
    "label": "Stockton - CA 95219",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "Stockton"
  },
  {
    "value": "95220",
    "label": "Acampo - CA 95220",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "Acampo"
  },
  {
    "value": "95222",
    "label": "Angels Camp - CA 95222",
    "county": "Calaveras County",
    "state": "CA",
    "city": "Angels Camp"
  },
  {
    "value": "95223",
    "label": "Arnold - CA 95223",
    "county": "Calaveras County",
    "state": "CA",
    "city": "Arnold"
  },
  {
    "value": "95228",
    "label": "Copperopolis - CA 95228",
    "county": "Calaveras County",
    "state": "CA",
    "city": "Copperopolis"
  },
  {
    "value": "95230",
    "label": "Farmington - CA 95230",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "Farmington"
  },
  {
    "value": "95231",
    "label": "French Camp - CA 95231",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "French Camp"
  },
  {
    "value": "95232",
    "label": "Glencoe - CA 95232",
    "county": "Calaveras County",
    "state": "CA",
    "city": "Glencoe"
  },
  {
    "value": "95236",
    "label": "Linden - CA 95236",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "Linden"
  },
  {
    "value": "95237",
    "label": "Lockeford - CA 95237",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "Lockeford"
  },
  {
    "value": "95240",
    "label": "Lodi - CA 95240",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "Lodi"
  },
  {
    "value": "95242",
    "label": "Lodi - CA 95242",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "Lodi"
  },
  {
    "value": "95245",
    "label": "Mokelumne Hill - CA 95245",
    "county": "Calaveras County",
    "state": "CA",
    "city": "Mokelumne Hill"
  },
  {
    "value": "95246",
    "label": "Mountain Ranch - CA 95246",
    "county": "Calaveras County",
    "state": "CA",
    "city": "Mountain Ranch"
  },
  {
    "value": "95247",
    "label": "Murphys - CA 95247",
    "county": "Calaveras County",
    "state": "CA",
    "city": "Murphys"
  },
  {
    "value": "95249",
    "label": "San Andreas - CA 95249",
    "county": "Calaveras County",
    "state": "CA",
    "city": "San Andreas"
  },
  {
    "value": "95250",
    "label": "San Andreas - CA 95250",
    "county": "Calaveras County",
    "state": "CA",
    "city": "San Andreas"
  },
  {
    "value": "95251",
    "label": "Vallecito - CA 95251",
    "county": "Calaveras County",
    "state": "CA",
    "city": "Vallecito"
  },
  {
    "value": "95252",
    "label": "Valley Springs - CA 95252",
    "county": "Calaveras County",
    "state": "CA",
    "city": "Valley Springs"
  },
  {
    "value": "95255",
    "label": "West Point - CA 95255",
    "county": "Calaveras County",
    "state": "CA",
    "city": "West Point"
  },
  {
    "value": "95257",
    "label": "Wilseyville - CA 95257",
    "county": "Calaveras County",
    "state": "CA",
    "city": "Wilseyville"
  },
  {
    "value": "95258",
    "label": "Woodbridge - CA 95258",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "Woodbridge"
  },
  {
    "value": "95301",
    "label": "Atwater - CA 95301",
    "county": "Merced County",
    "state": "CA",
    "city": "Atwater"
  },
  {
    "value": "95303",
    "label": "Ballico - CA 95303",
    "county": "Merced County",
    "state": "CA",
    "city": "Ballico"
  },
  {
    "value": "95304",
    "label": "Tracy - CA 95304",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "Tracy"
  },
  {
    "value": "95306",
    "label": "Catheys Valley - CA 95306",
    "county": "Mariposa County",
    "state": "CA",
    "city": "Catheys Valley"
  },
  {
    "value": "95307",
    "label": "Ceres - CA 95307",
    "county": "Stanislaus County",
    "state": "CA",
    "city": "Ceres"
  },
  {
    "value": "95310",
    "label": "Columbia - CA 95310",
    "county": "Tuolumne County",
    "state": "CA",
    "city": "Columbia"
  },
  {
    "value": "95311",
    "label": "Coulterville - CA 95311",
    "county": "Mariposa County",
    "state": "CA",
    "city": "Coulterville"
  },
  {
    "value": "95313",
    "label": "Crows Landing - CA 95313",
    "county": "Stanislaus County",
    "state": "CA",
    "city": "Crows Landing"
  },
  {
    "value": "95314",
    "label": "Pinecrest - CA 95314",
    "county": "Tuolumne County",
    "state": "CA",
    "city": "Pinecrest"
  },
  {
    "value": "95315",
    "label": "Delhi - CA 95315",
    "county": "Merced County",
    "state": "CA",
    "city": "Delhi"
  },
  {
    "value": "95316",
    "label": "Denair - CA 95316",
    "county": "Stanislaus County",
    "state": "CA",
    "city": "Denair"
  },
  {
    "value": "95317",
    "label": "El Nido - CA 95317",
    "county": "Merced County",
    "state": "CA",
    "city": "El Nido"
  },
  {
    "value": "95320",
    "label": "Escalon - CA 95320",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "Escalon"
  },
  {
    "value": "95321",
    "label": "Groveland - CA 95321",
    "county": "Tuolumne County",
    "state": "CA",
    "city": "Groveland"
  },
  {
    "value": "95322",
    "label": "Gustine - CA 95322",
    "county": "Merced County",
    "state": "CA",
    "city": "Gustine"
  },
  {
    "value": "95323",
    "label": "Hickman - CA 95323",
    "county": "Stanislaus County",
    "state": "CA",
    "city": "Hickman"
  },
  {
    "value": "95324",
    "label": "Hilmar - CA 95324",
    "county": "Merced County",
    "state": "CA",
    "city": "Hilmar"
  },
  {
    "value": "95325",
    "label": "Hornitos - CA 95325",
    "county": "Mariposa County",
    "state": "CA",
    "city": "Hornitos"
  },
  {
    "value": "95326",
    "label": "Hughson - CA 95326",
    "county": "Stanislaus County",
    "state": "CA",
    "city": "Hughson"
  },
  {
    "value": "95327",
    "label": "Jamestown - CA 95327",
    "county": "Tuolumne County",
    "state": "CA",
    "city": "Jamestown"
  },
  {
    "value": "95328",
    "label": "Keyes - CA 95328",
    "county": "Stanislaus County",
    "state": "CA",
    "city": "Keyes"
  },
  {
    "value": "95329",
    "label": "La Grange - CA 95329",
    "county": "Tuolumne County",
    "state": "CA",
    "city": "La Grange"
  },
  {
    "value": "95330",
    "label": "Lathrop - CA 95330",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "Lathrop"
  },
  {
    "value": "95333",
    "label": "Le Grand - CA 95333",
    "county": "Merced County",
    "state": "CA",
    "city": "Le Grand"
  },
  {
    "value": "95334",
    "label": "Livingston - CA 95334",
    "county": "Merced County",
    "state": "CA",
    "city": "Livingston"
  },
  {
    "value": "95335",
    "label": "Long Barn - CA 95335",
    "county": "Tuolumne County",
    "state": "CA",
    "city": "Long Barn"
  },
  {
    "value": "95336",
    "label": "Manteca - CA 95336",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "Manteca"
  },
  {
    "value": "95337",
    "label": "Manteca - CA 95337",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "Manteca"
  },
  {
    "value": "95338",
    "label": "Mariposa - CA 95338",
    "county": "Mariposa County",
    "state": "CA",
    "city": "Mariposa"
  },
  {
    "value": "95340",
    "label": "Merced - CA 95340",
    "county": "Merced County",
    "state": "CA",
    "city": "Merced"
  },
  {
    "value": "95341",
    "label": "Merced - CA 95341",
    "county": "Merced County",
    "state": "CA",
    "city": "Merced"
  },
  {
    "value": "95343",
    "label": "Merced - CA 95343",
    "county": "Merced County",
    "state": "CA",
    "city": "Merced"
  },
  {
    "value": "95345",
    "label": "Midpines - CA 95345",
    "county": "Mariposa County",
    "state": "CA",
    "city": "Midpines"
  },
  {
    "value": "95346",
    "label": "Mi Wuk Village - CA 95346",
    "county": "Tuolumne County",
    "state": "CA",
    "city": "Mi Wuk Village"
  },
  {
    "value": "95348",
    "label": "Merced - CA 95348",
    "county": "Merced County",
    "state": "CA",
    "city": "Merced"
  },
  {
    "value": "95350",
    "label": "Modesto - CA 95350",
    "county": "Stanislaus County",
    "state": "CA",
    "city": "Modesto"
  },
  {
    "value": "95351",
    "label": "Modesto - CA 95351",
    "county": "Stanislaus County",
    "state": "CA",
    "city": "Modesto"
  },
  {
    "value": "95354",
    "label": "Modesto - CA 95354",
    "county": "Stanislaus County",
    "state": "CA",
    "city": "Modesto"
  },
  {
    "value": "95355",
    "label": "Modesto - CA 95355",
    "county": "Stanislaus County",
    "state": "CA",
    "city": "Modesto"
  },
  {
    "value": "95356",
    "label": "Modesto - CA 95356",
    "county": "Stanislaus County",
    "state": "CA",
    "city": "Modesto"
  },
  {
    "value": "95357",
    "label": "Modesto - CA 95357",
    "county": "Stanislaus County",
    "state": "CA",
    "city": "Modesto"
  },
  {
    "value": "95358",
    "label": "Modesto - CA 95358",
    "county": "Stanislaus County",
    "state": "CA",
    "city": "Modesto"
  },
  {
    "value": "95360",
    "label": "Newman - CA 95360",
    "county": "Stanislaus County",
    "state": "CA",
    "city": "Newman"
  },
  {
    "value": "95361",
    "label": "Oakdale - CA 95361",
    "county": "Stanislaus County",
    "state": "CA",
    "city": "Oakdale"
  },
  {
    "value": "95363",
    "label": "Patterson - CA 95363",
    "county": "Stanislaus County",
    "state": "CA",
    "city": "Patterson"
  },
  {
    "value": "95364",
    "label": "Pinecrest - CA 95364",
    "county": "Tuolumne County",
    "state": "CA",
    "city": "Pinecrest"
  },
  {
    "value": "95365",
    "label": "Planada - CA 95365",
    "county": "Merced County",
    "state": "CA",
    "city": "Planada"
  },
  {
    "value": "95366",
    "label": "Ripon - CA 95366",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "Ripon"
  },
  {
    "value": "95367",
    "label": "Riverbank - CA 95367",
    "county": "Stanislaus County",
    "state": "CA",
    "city": "Riverbank"
  },
  {
    "value": "95368",
    "label": "Salida - CA 95368",
    "county": "Stanislaus County",
    "state": "CA",
    "city": "Salida"
  },
  {
    "value": "95369",
    "label": "Snelling - CA 95369",
    "county": "Merced County",
    "state": "CA",
    "city": "Snelling"
  },
  {
    "value": "95370",
    "label": "Sonora - CA 95370",
    "county": "Tuolumne County",
    "state": "CA",
    "city": "Sonora"
  },
  {
    "value": "95372",
    "label": "Soulsbyville - CA 95372",
    "county": "Tuolumne County",
    "state": "CA",
    "city": "Soulsbyville"
  },
  {
    "value": "95374",
    "label": "Stevinson - CA 95374",
    "county": "Merced County",
    "state": "CA",
    "city": "Stevinson"
  },
  {
    "value": "95376",
    "label": "Tracy - CA 95376",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "Tracy"
  },
  {
    "value": "95377",
    "label": "Tracy - CA 95377",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "Tracy"
  },
  {
    "value": "95379",
    "label": "Tuolumne - CA 95379",
    "county": "Tuolumne County",
    "state": "CA",
    "city": "Tuolumne"
  },
  {
    "value": "95380",
    "label": "Turlock - CA 95380",
    "county": "Stanislaus County",
    "state": "CA",
    "city": "Turlock"
  },
  {
    "value": "95382",
    "label": "Turlock - CA 95382",
    "county": "Stanislaus County",
    "state": "CA",
    "city": "Turlock"
  },
  {
    "value": "95383",
    "label": "Twain Harte - CA 95383",
    "county": "Tuolumne County",
    "state": "CA",
    "city": "Twain Harte"
  },
  {
    "value": "95385",
    "label": "Vernalis - CA 95385",
    "county": "Stanislaus County",
    "state": "CA",
    "city": "Vernalis"
  },
  {
    "value": "95386",
    "label": "Waterford - CA 95386",
    "county": "Stanislaus County",
    "state": "CA",
    "city": "Waterford"
  },
  {
    "value": "95388",
    "label": "Winton - CA 95388",
    "county": "Merced County",
    "state": "CA",
    "city": "Winton"
  },
  {
    "value": "95389",
    "label": "Yosemite National Park - CA 95389",
    "county": "Mariposa County",
    "state": "CA",
    "city": "Yosemite National Park"
  },
  {
    "value": "95391",
    "label": "Tracy - CA 95391",
    "county": "San Joaquin County",
    "state": "CA",
    "city": "Tracy"
  },
  {
    "value": "95401",
    "label": "Santa Rosa - CA 95401",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Santa Rosa"
  },
  {
    "value": "95403",
    "label": "Santa Rosa - CA 95403",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Santa Rosa"
  },
  {
    "value": "95404",
    "label": "Santa Rosa - CA 95404",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Santa Rosa"
  },
  {
    "value": "95405",
    "label": "Santa Rosa - CA 95405",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Santa Rosa"
  },
  {
    "value": "95407",
    "label": "Santa Rosa - CA 95407",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Santa Rosa"
  },
  {
    "value": "95409",
    "label": "Santa Rosa - CA 95409",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Santa Rosa"
  },
  {
    "value": "95410",
    "label": "Albion - CA 95410",
    "county": "Mendocino County",
    "state": "CA",
    "city": "Albion"
  },
  {
    "value": "95412",
    "label": "Annapolis - CA 95412",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Annapolis"
  },
  {
    "value": "95415",
    "label": "Boonville - CA 95415",
    "county": "Mendocino County",
    "state": "CA",
    "city": "Boonville"
  },
  {
    "value": "95417",
    "label": "Branscomb - CA 95417",
    "county": "Mendocino County",
    "state": "CA",
    "city": "Branscomb"
  },
  {
    "value": "95420",
    "label": "Caspar - CA 95420",
    "county": "Mendocino County",
    "state": "CA",
    "city": "Caspar"
  },
  {
    "value": "95421",
    "label": "Cazadero - CA 95421",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Cazadero"
  },
  {
    "value": "95422",
    "label": "Clearlake - CA 95422",
    "county": "Lake County",
    "state": "CA",
    "city": "Clearlake"
  },
  {
    "value": "95423",
    "label": "Clearlake Oaks - CA 95423",
    "county": "Lake County",
    "state": "CA",
    "city": "Clearlake Oaks"
  },
  {
    "value": "95425",
    "label": "Cloverdale - CA 95425",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Cloverdale"
  },
  {
    "value": "95427",
    "label": "Comptche - CA 95427",
    "county": "Mendocino County",
    "state": "CA",
    "city": "Comptche"
  },
  {
    "value": "95428",
    "label": "Covelo - CA 95428",
    "county": "Mendocino County",
    "state": "CA",
    "city": "Covelo"
  },
  {
    "value": "95429",
    "label": "Dos Rios - CA 95429",
    "county": "Mendocino County",
    "state": "CA",
    "city": "Dos Rios"
  },
  {
    "value": "95432",
    "label": "Elk - CA 95432",
    "county": "Mendocino County",
    "state": "CA",
    "city": "Elk"
  },
  {
    "value": "95436",
    "label": "Forestville - CA 95436",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Forestville"
  },
  {
    "value": "95437",
    "label": "Fort Bragg - CA 95437",
    "county": "Mendocino County",
    "state": "CA",
    "city": "Fort Bragg"
  },
  {
    "value": "95439",
    "label": "Fulton - CA 95439",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Fulton"
  },
  {
    "value": "95441",
    "label": "Geyserville - CA 95441",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Geyserville"
  },
  {
    "value": "95442",
    "label": "Glen Ellen - CA 95442",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Glen Ellen"
  },
  {
    "value": "95443",
    "label": "Glenhaven - CA 95443",
    "county": "Lake County",
    "state": "CA",
    "city": "Glenhaven"
  },
  {
    "value": "95444",
    "label": "Graton - CA 95444",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Graton"
  },
  {
    "value": "95445",
    "label": "Gualala - CA 95445",
    "county": "Mendocino County",
    "state": "CA",
    "city": "Gualala"
  },
  {
    "value": "95446",
    "label": "Guerneville - CA 95446",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Guerneville"
  },
  {
    "value": "95448",
    "label": "Healdsburg - CA 95448",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Healdsburg"
  },
  {
    "value": "95449",
    "label": "Hopland - CA 95449",
    "county": "Mendocino County",
    "state": "CA",
    "city": "Hopland"
  },
  {
    "value": "95450",
    "label": "Jenner - CA 95450",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Jenner"
  },
  {
    "value": "95451",
    "label": "Kelseyville - CA 95451",
    "county": "Lake County",
    "state": "CA",
    "city": "Kelseyville"
  },
  {
    "value": "95452",
    "label": "Kenwood - CA 95452",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Kenwood"
  },
  {
    "value": "95453",
    "label": "Lakeport - CA 95453",
    "county": "Lake County",
    "state": "CA",
    "city": "Lakeport"
  },
  {
    "value": "95454",
    "label": "Laytonville - CA 95454",
    "county": "Mendocino County",
    "state": "CA",
    "city": "Laytonville"
  },
  {
    "value": "95456",
    "label": "Little River - CA 95456",
    "county": "Mendocino County",
    "state": "CA",
    "city": "Little River"
  },
  {
    "value": "95457",
    "label": "Lower Lake - CA 95457",
    "county": "Lake County",
    "state": "CA",
    "city": "Lower Lake"
  },
  {
    "value": "95458",
    "label": "Lucerne - CA 95458",
    "county": "Lake County",
    "state": "CA",
    "city": "Lucerne"
  },
  {
    "value": "95459",
    "label": "Manchester - CA 95459",
    "county": "Mendocino County",
    "state": "CA",
    "city": "Manchester"
  },
  {
    "value": "95460",
    "label": "Mendocino - CA 95460",
    "county": "Mendocino County",
    "state": "CA",
    "city": "Mendocino"
  },
  {
    "value": "95461",
    "label": "Middletown - CA 95461",
    "county": "Lake County",
    "state": "CA",
    "city": "Middletown"
  },
  {
    "value": "95462",
    "label": "Monte Rio - CA 95462",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Monte Rio"
  },
  {
    "value": "95463",
    "label": "Navarro - CA 95463",
    "county": "Mendocino County",
    "state": "CA",
    "city": "Navarro"
  },
  {
    "value": "95464",
    "label": "Nice - CA 95464",
    "county": "Lake County",
    "state": "CA",
    "city": "Nice"
  },
  {
    "value": "95465",
    "label": "Occidental - CA 95465",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Occidental"
  },
  {
    "value": "95466",
    "label": "Philo - CA 95466",
    "county": "Mendocino County",
    "state": "CA",
    "city": "Philo"
  },
  {
    "value": "95467",
    "label": "Hidden Valley Lake - CA 95467",
    "county": "Lake County",
    "state": "CA",
    "city": "Hidden Valley Lake"
  },
  {
    "value": "95468",
    "label": "Point Arena - CA 95468",
    "county": "Mendocino County",
    "state": "CA",
    "city": "Point Arena"
  },
  {
    "value": "95469",
    "label": "Potter Valley - CA 95469",
    "county": "Mendocino County",
    "state": "CA",
    "city": "Potter Valley"
  },
  {
    "value": "95470",
    "label": "Redwood Valley - CA 95470",
    "county": "Mendocino County",
    "state": "CA",
    "city": "Redwood Valley"
  },
  {
    "value": "95472",
    "label": "Sebastopol - CA 95472",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Sebastopol"
  },
  {
    "value": "95476",
    "label": "Sonoma - CA 95476",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Sonoma"
  },
  {
    "value": "95482",
    "label": "Ukiah - CA 95482",
    "county": "Mendocino County",
    "state": "CA",
    "city": "Ukiah"
  },
  {
    "value": "95485",
    "label": "Upper Lake - CA 95485",
    "county": "Lake County",
    "state": "CA",
    "city": "Upper Lake"
  },
  {
    "value": "95488",
    "label": "Westport - CA 95488",
    "county": "Mendocino County",
    "state": "CA",
    "city": "Westport"
  },
  {
    "value": "95490",
    "label": "Willits - CA 95490",
    "county": "Mendocino County",
    "state": "CA",
    "city": "Willits"
  },
  {
    "value": "95492",
    "label": "Windsor - CA 95492",
    "county": "Sonoma County",
    "state": "CA",
    "city": "Windsor"
  },
  {
    "value": "95493",
    "label": "Witter Springs - CA 95493",
    "county": "Lake County",
    "state": "CA",
    "city": "Witter Springs"
  },
  {
    "value": "95494",
    "label": "Yorkville - CA 95494",
    "county": "Mendocino County",
    "state": "CA",
    "city": "Yorkville"
  },
  {
    "value": "95501",
    "label": "Eureka - CA 95501",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Eureka"
  },
  {
    "value": "95503",
    "label": "Eureka - CA 95503",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Eureka"
  },
  {
    "value": "95511",
    "label": "Alderpoint - CA 95511",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Alderpoint"
  },
  {
    "value": "95514",
    "label": "Blocksburg - CA 95514",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Blocksburg"
  },
  {
    "value": "95519",
    "label": "Mckinleyville - CA 95519",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Mckinleyville"
  },
  {
    "value": "95521",
    "label": "Arcata - CA 95521",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Arcata"
  },
  {
    "value": "95524",
    "label": "Bayside - CA 95524",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Bayside"
  },
  {
    "value": "95525",
    "label": "Blue Lake - CA 95525",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Blue Lake"
  },
  {
    "value": "95526",
    "label": "Bridgeville - CA 95526",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Bridgeville"
  },
  {
    "value": "95527",
    "label": "Burnt Ranch - CA 95527",
    "county": "Trinity County",
    "state": "CA",
    "city": "Burnt Ranch"
  },
  {
    "value": "95528",
    "label": "Carlotta - CA 95528",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Carlotta"
  },
  {
    "value": "95531",
    "label": "Crescent City - CA 95531",
    "county": "Del Norte County",
    "state": "CA",
    "city": "Crescent City"
  },
  {
    "value": "95536",
    "label": "Ferndale - CA 95536",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Ferndale"
  },
  {
    "value": "95540",
    "label": "Fortuna - CA 95540",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Fortuna"
  },
  {
    "value": "95542",
    "label": "Garberville - CA 95542",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Garberville"
  },
  {
    "value": "95543",
    "label": "Gasquet - CA 95543",
    "county": "Del Norte County",
    "state": "CA",
    "city": "Gasquet"
  },
  {
    "value": "95546",
    "label": "Hoopa - CA 95546",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Hoopa"
  },
  {
    "value": "95547",
    "label": "Hydesville - CA 95547",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Hydesville"
  },
  {
    "value": "95548",
    "label": "Klamath - CA 95548",
    "county": "Del Norte County",
    "state": "CA",
    "city": "Klamath"
  },
  {
    "value": "95549",
    "label": "Kneeland - CA 95549",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Kneeland"
  },
  {
    "value": "95550",
    "label": "Korbel - CA 95550",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Korbel"
  },
  {
    "value": "95551",
    "label": "Loleta - CA 95551",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Loleta"
  },
  {
    "value": "95552",
    "label": "Mad River - CA 95552",
    "county": "Trinity County",
    "state": "CA",
    "city": "Mad River"
  },
  {
    "value": "95554",
    "label": "Myers Flat - CA 95554",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Myers Flat"
  },
  {
    "value": "95555",
    "label": "Orick - CA 95555",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Orick"
  },
  {
    "value": "95558",
    "label": "Petrolia - CA 95558",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Petrolia"
  },
  {
    "value": "95560",
    "label": "Redway - CA 95560",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Redway"
  },
  {
    "value": "95562",
    "label": "Rio Dell - CA 95562",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Rio Dell"
  },
  {
    "value": "95564",
    "label": "Samoa - CA 95564",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Samoa"
  },
  {
    "value": "95565",
    "label": "Scotia - CA 95565",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Scotia"
  },
  {
    "value": "95567",
    "label": "Smith River - CA 95567",
    "county": "Del Norte County",
    "state": "CA",
    "city": "Smith River"
  },
  {
    "value": "95568",
    "label": "Somes Bar - CA 95568",
    "county": "Siskiyou County",
    "state": "CA",
    "city": "Somes Bar"
  },
  {
    "value": "95569",
    "label": "Redcrest - CA 95569",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Redcrest"
  },
  {
    "value": "95570",
    "label": "Trinidad - CA 95570",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Trinidad"
  },
  {
    "value": "95573",
    "label": "Willow Creek - CA 95573",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Willow Creek"
  },
  {
    "value": "95585",
    "label": "Leggett - CA 95585",
    "county": "Mendocino County",
    "state": "CA",
    "city": "Leggett"
  },
  {
    "value": "95587",
    "label": "Piercy - CA 95587",
    "county": "Mendocino County",
    "state": "CA",
    "city": "Piercy"
  },
  {
    "value": "95589",
    "label": "Whitethorn - CA 95589",
    "county": "Humboldt County",
    "state": "CA",
    "city": "Whitethorn"
  },
  {
    "value": "95595",
    "label": "Zenia - CA 95595",
    "county": "Trinity County",
    "state": "CA",
    "city": "Zenia"
  },
  {
    "value": "95602",
    "label": "Auburn - CA 95602",
    "county": "Placer County",
    "state": "CA",
    "city": "Auburn"
  },
  {
    "value": "95603",
    "label": "Auburn - CA 95603",
    "county": "Placer County",
    "state": "CA",
    "city": "Auburn"
  },
  {
    "value": "95605",
    "label": "West Sacramento - CA 95605",
    "county": "Yolo County",
    "state": "CA",
    "city": "West Sacramento"
  },
  {
    "value": "95606",
    "label": "Brooks - CA 95606",
    "county": "Yolo County",
    "state": "CA",
    "city": "Brooks"
  },
  {
    "value": "95607",
    "label": "Capay - CA 95607",
    "county": "Yolo County",
    "state": "CA",
    "city": "Capay"
  },
  {
    "value": "95608",
    "label": "Carmichael - CA 95608",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Carmichael"
  },
  {
    "value": "95610",
    "label": "Citrus Heights - CA 95610",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Citrus Heights"
  },
  {
    "value": "95612",
    "label": "Clarksburg - CA 95612",
    "county": "Yolo County",
    "state": "CA",
    "city": "Clarksburg"
  },
  {
    "value": "95614",
    "label": "Cool - CA 95614",
    "county": "El Dorado County",
    "state": "CA",
    "city": "Cool"
  },
  {
    "value": "95615",
    "label": "Courtland - CA 95615",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Courtland"
  },
  {
    "value": "95616",
    "label": "Davis - CA 95616",
    "county": "Yolo County",
    "state": "CA",
    "city": "Davis"
  },
  {
    "value": "95618",
    "label": "Davis - CA 95618",
    "county": "Yolo County",
    "state": "CA",
    "city": "Davis"
  },
  {
    "value": "95619",
    "label": "Diamond Springs - CA 95619",
    "county": "El Dorado County",
    "state": "CA",
    "city": "Diamond Springs"
  },
  {
    "value": "95620",
    "label": "Dixon - CA 95620",
    "county": "Solano County",
    "state": "CA",
    "city": "Dixon"
  },
  {
    "value": "95621",
    "label": "Citrus Heights - CA 95621",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Citrus Heights"
  },
  {
    "value": "95623",
    "label": "El Dorado - CA 95623",
    "county": "El Dorado County",
    "state": "CA",
    "city": "El Dorado"
  },
  {
    "value": "95624",
    "label": "Elk Grove - CA 95624",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Elk Grove"
  },
  {
    "value": "95626",
    "label": "Elverta - CA 95626",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Elverta"
  },
  {
    "value": "95627",
    "label": "Esparto - CA 95627",
    "county": "Yolo County",
    "state": "CA",
    "city": "Esparto"
  },
  {
    "value": "95628",
    "label": "Fair Oaks - CA 95628",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Fair Oaks"
  },
  {
    "value": "95629",
    "label": "Fiddletown - CA 95629",
    "county": "Amador County",
    "state": "CA",
    "city": "Fiddletown"
  },
  {
    "value": "95630",
    "label": "Folsom - CA 95630",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Folsom"
  },
  {
    "value": "95631",
    "label": "Foresthill - CA 95631",
    "county": "Placer County",
    "state": "CA",
    "city": "Foresthill"
  },
  {
    "value": "95632",
    "label": "Galt - CA 95632",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Galt"
  },
  {
    "value": "95633",
    "label": "Garden Valley - CA 95633",
    "county": "El Dorado County",
    "state": "CA",
    "city": "Garden Valley"
  },
  {
    "value": "95634",
    "label": "Georgetown - CA 95634",
    "county": "El Dorado County",
    "state": "CA",
    "city": "Georgetown"
  },
  {
    "value": "95635",
    "label": "Greenwood - CA 95635",
    "county": "El Dorado County",
    "state": "CA",
    "city": "Greenwood"
  },
  {
    "value": "95636",
    "label": "Grizzly Flats - CA 95636",
    "county": "El Dorado County",
    "state": "CA",
    "city": "Grizzly Flats"
  },
  {
    "value": "95637",
    "label": "Guinda - CA 95637",
    "county": "Yolo County",
    "state": "CA",
    "city": "Guinda"
  },
  {
    "value": "95638",
    "label": "Herald - CA 95638",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Herald"
  },
  {
    "value": "95640",
    "label": "Ione - CA 95640",
    "county": "Amador County",
    "state": "CA",
    "city": "Ione"
  },
  {
    "value": "95641",
    "label": "Isleton - CA 95641",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Isleton"
  },
  {
    "value": "95642",
    "label": "Jackson - CA 95642",
    "county": "Amador County",
    "state": "CA",
    "city": "Jackson"
  },
  {
    "value": "95645",
    "label": "Knights Landing - CA 95645",
    "county": "Yolo County",
    "state": "CA",
    "city": "Knights Landing"
  },
  {
    "value": "95648",
    "label": "Lincoln - CA 95648",
    "county": "Placer County",
    "state": "CA",
    "city": "Lincoln"
  },
  {
    "value": "95650",
    "label": "Loomis - CA 95650",
    "county": "Placer County",
    "state": "CA",
    "city": "Loomis"
  },
  {
    "value": "95651",
    "label": "Lotus - CA 95651",
    "county": "El Dorado County",
    "state": "CA",
    "city": "Lotus"
  },
  {
    "value": "95652",
    "label": "Mcclellan - CA 95652",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Mcclellan"
  },
  {
    "value": "95655",
    "label": "Mather - CA 95655",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Mather"
  },
  {
    "value": "95658",
    "label": "Newcastle - CA 95658",
    "county": "Placer County",
    "state": "CA",
    "city": "Newcastle"
  },
  {
    "value": "95659",
    "label": "Nicolaus - CA 95659",
    "county": "Sutter County",
    "state": "CA",
    "city": "Nicolaus"
  },
  {
    "value": "95660",
    "label": "North Highlands - CA 95660",
    "county": "Sacramento County",
    "state": "CA",
    "city": "North Highlands"
  },
  {
    "value": "95661",
    "label": "Roseville - CA 95661",
    "county": "Placer County",
    "state": "CA",
    "city": "Roseville"
  },
  {
    "value": "95662",
    "label": "Orangevale - CA 95662",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Orangevale"
  },
  {
    "value": "95663",
    "label": "Penryn - CA 95663",
    "county": "Placer County",
    "state": "CA",
    "city": "Penryn"
  },
  {
    "value": "95664",
    "label": "Pilot Hill - CA 95664",
    "county": "El Dorado County",
    "state": "CA",
    "city": "Pilot Hill"
  },
  {
    "value": "95665",
    "label": "Pine Grove - CA 95665",
    "county": "Amador County",
    "state": "CA",
    "city": "Pine Grove"
  },
  {
    "value": "95666",
    "label": "Pioneer - CA 95666",
    "county": "Amador County",
    "state": "CA",
    "city": "Pioneer"
  },
  {
    "value": "95667",
    "label": "Placerville - CA 95667",
    "county": "El Dorado County",
    "state": "CA",
    "city": "Placerville"
  },
  {
    "value": "95668",
    "label": "Pleasant Grove - CA 95668",
    "county": "Sutter County",
    "state": "CA",
    "city": "Pleasant Grove"
  },
  {
    "value": "95669",
    "label": "Plymouth - CA 95669",
    "county": "Amador County",
    "state": "CA",
    "city": "Plymouth"
  },
  {
    "value": "95670",
    "label": "Rancho Cordova - CA 95670",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Rancho Cordova"
  },
  {
    "value": "95672",
    "label": "Rescue - CA 95672",
    "county": "El Dorado County",
    "state": "CA",
    "city": "Rescue"
  },
  {
    "value": "95673",
    "label": "Rio Linda - CA 95673",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Rio Linda"
  },
  {
    "value": "95674",
    "label": "Rio Oso - CA 95674",
    "county": "Sutter County",
    "state": "CA",
    "city": "Rio Oso"
  },
  {
    "value": "95677",
    "label": "Rocklin - CA 95677",
    "county": "Placer County",
    "state": "CA",
    "city": "Rocklin"
  },
  {
    "value": "95678",
    "label": "Roseville - CA 95678",
    "county": "Placer County",
    "state": "CA",
    "city": "Roseville"
  },
  {
    "value": "95681",
    "label": "Sheridan - CA 95681",
    "county": "Placer County",
    "state": "CA",
    "city": "Sheridan"
  },
  {
    "value": "95682",
    "label": "Shingle Springs - CA 95682",
    "county": "El Dorado County",
    "state": "CA",
    "city": "Shingle Springs"
  },
  {
    "value": "95683",
    "label": "Sloughhouse - CA 95683",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sloughhouse"
  },
  {
    "value": "95684",
    "label": "Somerset - CA 95684",
    "county": "El Dorado County",
    "state": "CA",
    "city": "Somerset"
  },
  {
    "value": "95685",
    "label": "Sutter Creek - CA 95685",
    "county": "Amador County",
    "state": "CA",
    "city": "Sutter Creek"
  },
  {
    "value": "95687",
    "label": "Vacaville - CA 95687",
    "county": "Solano County",
    "state": "CA",
    "city": "Vacaville"
  },
  {
    "value": "95688",
    "label": "Vacaville - CA 95688",
    "county": "Solano County",
    "state": "CA",
    "city": "Vacaville"
  },
  {
    "value": "95689",
    "label": "Volcano - CA 95689",
    "county": "Amador County",
    "state": "CA",
    "city": "Volcano"
  },
  {
    "value": "95690",
    "label": "Walnut Grove - CA 95690",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Walnut Grove"
  },
  {
    "value": "95691",
    "label": "West Sacramento - CA 95691",
    "county": "Yolo County",
    "state": "CA",
    "city": "West Sacramento"
  },
  {
    "value": "95692",
    "label": "Wheatland - CA 95692",
    "county": "Yuba County",
    "state": "CA",
    "city": "Wheatland"
  },
  {
    "value": "95693",
    "label": "Wilton - CA 95693",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Wilton"
  },
  {
    "value": "95694",
    "label": "Winters - CA 95694",
    "county": "Yolo County",
    "state": "CA",
    "city": "Winters"
  },
  {
    "value": "95695",
    "label": "Woodland - CA 95695",
    "county": "Yolo County",
    "state": "CA",
    "city": "Woodland"
  },
  {
    "value": "95703",
    "label": "Applegate - CA 95703",
    "county": "Placer County",
    "state": "CA",
    "city": "Applegate"
  },
  {
    "value": "95709",
    "label": "Camino - CA 95709",
    "county": "El Dorado County",
    "state": "CA",
    "city": "Camino"
  },
  {
    "value": "95713",
    "label": "Colfax - CA 95713",
    "county": "Placer County",
    "state": "CA",
    "city": "Colfax"
  },
  {
    "value": "95720",
    "label": "Kyburz - CA 95720",
    "county": "El Dorado County",
    "state": "CA",
    "city": "Kyburz"
  },
  {
    "value": "95722",
    "label": "Meadow Vista - CA 95722",
    "county": "Placer County",
    "state": "CA",
    "city": "Meadow Vista"
  },
  {
    "value": "95726",
    "label": "Pollock Pines - CA 95726",
    "county": "El Dorado County",
    "state": "CA",
    "city": "Pollock Pines"
  },
  {
    "value": "95735",
    "label": "Twin Bridges - CA 95735",
    "county": "El Dorado County",
    "state": "CA",
    "city": "Twin Bridges"
  },
  {
    "value": "95742",
    "label": "Rancho Cordova - CA 95742",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Rancho Cordova"
  },
  {
    "value": "95746",
    "label": "Granite Bay - CA 95746",
    "county": "Placer County",
    "state": "CA",
    "city": "Granite Bay"
  },
  {
    "value": "95747",
    "label": "Roseville - CA 95747",
    "county": "Placer County",
    "state": "CA",
    "city": "Roseville"
  },
  {
    "value": "95757",
    "label": "Elk Grove - CA 95757",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Elk Grove"
  },
  {
    "value": "95758",
    "label": "Elk Grove - CA 95758",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Elk Grove"
  },
  {
    "value": "95762",
    "label": "El Dorado Hills - CA 95762",
    "county": "El Dorado County",
    "state": "CA",
    "city": "El Dorado Hills"
  },
  {
    "value": "95765",
    "label": "Rocklin - CA 95765",
    "county": "Placer County",
    "state": "CA",
    "city": "Rocklin"
  },
  {
    "value": "95776",
    "label": "Woodland - CA 95776",
    "county": "Yolo County",
    "state": "CA",
    "city": "Woodland"
  },
  {
    "value": "95811",
    "label": "Sacramento - CA 95811",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95814",
    "label": "Sacramento - CA 95814",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95815",
    "label": "Sacramento - CA 95815",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95816",
    "label": "Sacramento - CA 95816",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95817",
    "label": "Sacramento - CA 95817",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95818",
    "label": "Sacramento - CA 95818",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95819",
    "label": "Sacramento - CA 95819",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95820",
    "label": "Sacramento - CA 95820",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95821",
    "label": "Sacramento - CA 95821",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95822",
    "label": "Sacramento - CA 95822",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95823",
    "label": "Sacramento - CA 95823",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95824",
    "label": "Sacramento - CA 95824",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95825",
    "label": "Sacramento - CA 95825",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95826",
    "label": "Sacramento - CA 95826",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95827",
    "label": "Sacramento - CA 95827",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95828",
    "label": "Sacramento - CA 95828",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95829",
    "label": "Sacramento - CA 95829",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95830",
    "label": "Sacramento - CA 95830",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95831",
    "label": "Sacramento - CA 95831",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95832",
    "label": "Sacramento - CA 95832",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95833",
    "label": "Sacramento - CA 95833",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95834",
    "label": "Sacramento - CA 95834",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95835",
    "label": "Sacramento - CA 95835",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95836",
    "label": "Sacramento - CA 95836",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95837",
    "label": "Sacramento - CA 95837",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95838",
    "label": "Sacramento - CA 95838",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95841",
    "label": "Sacramento - CA 95841",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95842",
    "label": "Sacramento - CA 95842",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95843",
    "label": "Antelope - CA 95843",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Antelope"
  },
  {
    "value": "95864",
    "label": "Sacramento - CA 95864",
    "county": "Sacramento County",
    "state": "CA",
    "city": "Sacramento"
  },
  {
    "value": "95901",
    "label": "Marysville - CA 95901",
    "county": "Yuba County",
    "state": "CA",
    "city": "Marysville"
  },
  {
    "value": "95903",
    "label": "Beale AFB - CA 95903",
    "county": "Yuba County",
    "state": "CA",
    "city": "Beale AFB"
  },
  {
    "value": "95910",
    "label": "Alleghany - CA 95910",
    "county": "Sierra County",
    "state": "CA",
    "city": "Alleghany"
  },
  {
    "value": "95912",
    "label": "Arbuckle - CA 95912",
    "county": "Colusa County",
    "state": "CA",
    "city": "Arbuckle"
  },
  {
    "value": "95914",
    "label": "Bangor - CA 95914",
    "county": "Butte County",
    "state": "CA",
    "city": "Bangor"
  },
  {
    "value": "95916",
    "label": "Berry Creek - CA 95916",
    "county": "Butte County",
    "state": "CA",
    "city": "Berry Creek"
  },
  {
    "value": "95917",
    "label": "Biggs - CA 95917",
    "county": "Butte County",
    "state": "CA",
    "city": "Biggs"
  },
  {
    "value": "95918",
    "label": "Browns Valley - CA 95918",
    "county": "Yuba County",
    "state": "CA",
    "city": "Browns Valley"
  },
  {
    "value": "95919",
    "label": "Brownsville - CA 95919",
    "county": "Yuba County",
    "state": "CA",
    "city": "Brownsville"
  },
  {
    "value": "95920",
    "label": "Butte City - CA 95920",
    "county": "Glenn County",
    "state": "CA",
    "city": "Butte City"
  },
  {
    "value": "95922",
    "label": "Camptonville - CA 95922",
    "county": "Yuba County",
    "state": "CA",
    "city": "Camptonville"
  },
  {
    "value": "95923",
    "label": "Canyon Dam - CA 95923",
    "county": "Plumas County",
    "state": "CA",
    "city": "Canyon Dam"
  },
  {
    "value": "95925",
    "label": "Challenge - CA 95925",
    "county": "Yuba County",
    "state": "CA",
    "city": "Challenge"
  },
  {
    "value": "95926",
    "label": "Chico - CA 95926",
    "county": "Butte County",
    "state": "CA",
    "city": "Chico"
  },
  {
    "value": "95928",
    "label": "Chico - CA 95928",
    "county": "Butte County",
    "state": "CA",
    "city": "Chico"
  },
  {
    "value": "95930",
    "label": "Clipper Mills - CA 95930",
    "county": "Butte County",
    "state": "CA",
    "city": "Clipper Mills"
  },
  {
    "value": "95932",
    "label": "Colusa - CA 95932",
    "county": "Colusa County",
    "state": "CA",
    "city": "Colusa"
  },
  {
    "value": "95934",
    "label": "Crescent Mills - CA 95934",
    "county": "Plumas County",
    "state": "CA",
    "city": "Crescent Mills"
  },
  {
    "value": "95935",
    "label": "Dobbins - CA 95935",
    "county": "Yuba County",
    "state": "CA",
    "city": "Dobbins"
  },
  {
    "value": "95938",
    "label": "Durham - CA 95938",
    "county": "Butte County",
    "state": "CA",
    "city": "Durham"
  },
  {
    "value": "95939",
    "label": "Elk Creek - CA 95939",
    "county": "Glenn County",
    "state": "CA",
    "city": "Elk Creek"
  },
  {
    "value": "95941",
    "label": "Forbestown - CA 95941",
    "county": "Butte County",
    "state": "CA",
    "city": "Forbestown"
  },
  {
    "value": "95942",
    "label": "Forest Ranch - CA 95942",
    "county": "Butte County",
    "state": "CA",
    "city": "Forest Ranch"
  },
  {
    "value": "95943",
    "label": "Glenn - CA 95943",
    "county": "Glenn County",
    "state": "CA",
    "city": "Glenn"
  },
  {
    "value": "95945",
    "label": "Grass Valley - CA 95945",
    "county": "Nevada County",
    "state": "CA",
    "city": "Grass Valley"
  },
  {
    "value": "95946",
    "label": "Penn Valley - CA 95946",
    "county": "Nevada County",
    "state": "CA",
    "city": "Penn Valley"
  },
  {
    "value": "95947",
    "label": "Greenville - CA 95947",
    "county": "Plumas County",
    "state": "CA",
    "city": "Greenville"
  },
  {
    "value": "95948",
    "label": "Gridley - CA 95948",
    "county": "Butte County",
    "state": "CA",
    "city": "Gridley"
  },
  {
    "value": "95949",
    "label": "Grass Valley - CA 95949",
    "county": "Nevada County",
    "state": "CA",
    "city": "Grass Valley"
  },
  {
    "value": "95953",
    "label": "Live Oak - CA 95953",
    "county": "Sutter County",
    "state": "CA",
    "city": "Live Oak"
  },
  {
    "value": "95954",
    "label": "Magalia - CA 95954",
    "county": "Butte County",
    "state": "CA",
    "city": "Magalia"
  },
  {
    "value": "95957",
    "label": "Meridian - CA 95957",
    "county": "Sutter County",
    "state": "CA",
    "city": "Meridian"
  },
  {
    "value": "95959",
    "label": "Nevada City - CA 95959",
    "county": "Nevada County",
    "state": "CA",
    "city": "Nevada City"
  },
  {
    "value": "95960",
    "label": "North San Juan - CA 95960",
    "county": "Nevada County",
    "state": "CA",
    "city": "North San Juan"
  },
  {
    "value": "95961",
    "label": "Olivehurst - CA 95961",
    "county": "Yuba County",
    "state": "CA",
    "city": "Olivehurst"
  },
  {
    "value": "95962",
    "label": "Oregon House - CA 95962",
    "county": "Yuba County",
    "state": "CA",
    "city": "Oregon House"
  },
  {
    "value": "95963",
    "label": "Orland - CA 95963",
    "county": "Glenn County",
    "state": "CA",
    "city": "Orland"
  },
  {
    "value": "95965",
    "label": "Oroville - CA 95965",
    "county": "Butte County",
    "state": "CA",
    "city": "Oroville"
  },
  {
    "value": "95966",
    "label": "Oroville - CA 95966",
    "county": "Butte County",
    "state": "CA",
    "city": "Oroville"
  },
  {
    "value": "95968",
    "label": "Palermo - CA 95968",
    "county": "Butte County",
    "state": "CA",
    "city": "Palermo"
  },
  {
    "value": "95969",
    "label": "Paradise - CA 95969",
    "county": "Butte County",
    "state": "CA",
    "city": "Paradise"
  },
  {
    "value": "95970",
    "label": "Princeton - CA 95970",
    "county": "Colusa County",
    "state": "CA",
    "city": "Princeton"
  },
  {
    "value": "95971",
    "label": "Quincy - CA 95971",
    "county": "Plumas County",
    "state": "CA",
    "city": "Quincy"
  },
  {
    "value": "95972",
    "label": "Rackerby - CA 95972",
    "county": "Yuba County",
    "state": "CA",
    "city": "Rackerby"
  },
  {
    "value": "95973",
    "label": "Chico - CA 95973",
    "county": "Butte County",
    "state": "CA",
    "city": "Chico"
  },
  {
    "value": "95975",
    "label": "Rough And Ready - CA 95975",
    "county": "Nevada County",
    "state": "CA",
    "city": "Rough And Ready"
  },
  {
    "value": "95977",
    "label": "Smartsville - CA 95977",
    "county": "Nevada County",
    "state": "CA",
    "city": "Smartsville"
  },
  {
    "value": "95979",
    "label": "Stonyford - CA 95979",
    "county": "Colusa County",
    "state": "CA",
    "city": "Stonyford"
  },
  {
    "value": "95981",
    "label": "Strawberry Valley - CA 95981",
    "county": "Yuba County",
    "state": "CA",
    "city": "Strawberry Valley"
  },
  {
    "value": "95982",
    "label": "Sutter - CA 95982",
    "county": "Sutter County",
    "state": "CA",
    "city": "Sutter"
  },
  {
    "value": "95983",
    "label": "Taylorsville - CA 95983",
    "county": "Plumas County",
    "state": "CA",
    "city": "Taylorsville"
  },
  {
    "value": "95987",
    "label": "Williams - CA 95987",
    "county": "Colusa County",
    "state": "CA",
    "city": "Williams"
  },
  {
    "value": "95988",
    "label": "Willows - CA 95988",
    "county": "Glenn County",
    "state": "CA",
    "city": "Willows"
  },
  {
    "value": "95991",
    "label": "Yuba City - CA 95991",
    "county": "Sutter County",
    "state": "CA",
    "city": "Yuba City"
  },
  {
    "value": "95993",
    "label": "Yuba City - CA 95993",
    "county": "Sutter County",
    "state": "CA",
    "city": "Yuba City"
  },
  {
    "value": "96001",
    "label": "Redding - CA 96001",
    "county": "Shasta County",
    "state": "CA",
    "city": "Redding"
  },
  {
    "value": "96002",
    "label": "Redding - CA 96002",
    "county": "Shasta County",
    "state": "CA",
    "city": "Redding"
  },
  {
    "value": "96003",
    "label": "Redding - CA 96003",
    "county": "Shasta County",
    "state": "CA",
    "city": "Redding"
  },
  {
    "value": "96006",
    "label": "Adin - CA 96006",
    "county": "Modoc County",
    "state": "CA",
    "city": "Adin"
  },
  {
    "value": "96007",
    "label": "Anderson - CA 96007",
    "county": "Shasta County",
    "state": "CA",
    "city": "Anderson"
  },
  {
    "value": "96008",
    "label": "Bella Vista - CA 96008",
    "county": "Shasta County",
    "state": "CA",
    "city": "Bella Vista"
  },
  {
    "value": "96010",
    "label": "Big Bar - CA 96010",
    "county": "Trinity County",
    "state": "CA",
    "city": "Big Bar"
  },
  {
    "value": "96013",
    "label": "Burney - CA 96013",
    "county": "Shasta County",
    "state": "CA",
    "city": "Burney"
  },
  {
    "value": "96014",
    "label": "Callahan - CA 96014",
    "county": "Siskiyou County",
    "state": "CA",
    "city": "Callahan"
  },
  {
    "value": "96015",
    "label": "Canby - CA 96015",
    "county": "Modoc County",
    "state": "CA",
    "city": "Canby"
  },
  {
    "value": "96016",
    "label": "Cassel - CA 96016",
    "county": "Shasta County",
    "state": "CA",
    "city": "Cassel"
  },
  {
    "value": "96017",
    "label": "Castella - CA 96017",
    "county": "Shasta County",
    "state": "CA",
    "city": "Castella"
  },
  {
    "value": "96019",
    "label": "Shasta Lake - CA 96019",
    "county": "Shasta County",
    "state": "CA",
    "city": "Shasta Lake"
  },
  {
    "value": "96020",
    "label": "Chester - CA 96020",
    "county": "Plumas County",
    "state": "CA",
    "city": "Chester"
  },
  {
    "value": "96021",
    "label": "Corning - CA 96021",
    "county": "Tehama County",
    "state": "CA",
    "city": "Corning"
  },
  {
    "value": "96022",
    "label": "Cottonwood - CA 96022",
    "county": "Tehama County",
    "state": "CA",
    "city": "Cottonwood"
  },
  {
    "value": "96023",
    "label": "Dorris - CA 96023",
    "county": "Siskiyou County",
    "state": "CA",
    "city": "Dorris"
  },
  {
    "value": "96024",
    "label": "Douglas City - CA 96024",
    "county": "Trinity County",
    "state": "CA",
    "city": "Douglas City"
  },
  {
    "value": "96025",
    "label": "Dunsmuir - CA 96025",
    "county": "Siskiyou County",
    "state": "CA",
    "city": "Dunsmuir"
  },
  {
    "value": "96027",
    "label": "Etna - CA 96027",
    "county": "Siskiyou County",
    "state": "CA",
    "city": "Etna"
  },
  {
    "value": "96028",
    "label": "Fall River Mills - CA 96028",
    "county": "Shasta County",
    "state": "CA",
    "city": "Fall River Mills"
  },
  {
    "value": "96029",
    "label": "Flournoy - CA 96029",
    "county": "Tehama County",
    "state": "CA",
    "city": "Flournoy"
  },
  {
    "value": "96031",
    "label": "Forks Of Salmon - CA 96031",
    "county": "Siskiyou County",
    "state": "CA",
    "city": "Forks Of Salmon"
  },
  {
    "value": "96032",
    "label": "Fort Jones - CA 96032",
    "county": "Siskiyou County",
    "state": "CA",
    "city": "Fort Jones"
  },
  {
    "value": "96033",
    "label": "French Gulch - CA 96033",
    "county": "Shasta County",
    "state": "CA",
    "city": "French Gulch"
  },
  {
    "value": "96034",
    "label": "Gazelle - CA 96034",
    "county": "Siskiyou County",
    "state": "CA",
    "city": "Gazelle"
  },
  {
    "value": "96035",
    "label": "Gerber - CA 96035",
    "county": "Tehama County",
    "state": "CA",
    "city": "Gerber"
  },
  {
    "value": "96038",
    "label": "Grenada - CA 96038",
    "county": "Siskiyou County",
    "state": "CA",
    "city": "Grenada"
  },
  {
    "value": "96039",
    "label": "Happy Camp - CA 96039",
    "county": "Siskiyou County",
    "state": "CA",
    "city": "Happy Camp"
  },
  {
    "value": "96040",
    "label": "Hat Creek - CA 96040",
    "county": "Shasta County",
    "state": "CA",
    "city": "Hat Creek"
  },
  {
    "value": "96041",
    "label": "Hayfork - CA 96041",
    "county": "Trinity County",
    "state": "CA",
    "city": "Hayfork"
  },
  {
    "value": "96044",
    "label": "Hornbrook - CA 96044",
    "county": "Siskiyou County",
    "state": "CA",
    "city": "Hornbrook"
  },
  {
    "value": "96047",
    "label": "Igo - CA 96047",
    "county": "Shasta County",
    "state": "CA",
    "city": "Igo"
  },
  {
    "value": "96048",
    "label": "Junction City - CA 96048",
    "county": "Trinity County",
    "state": "CA",
    "city": "Junction City"
  },
  {
    "value": "96050",
    "label": "Klamath River - CA 96050",
    "county": "Siskiyou County",
    "state": "CA",
    "city": "Klamath River"
  },
  {
    "value": "96051",
    "label": "Lakehead - CA 96051",
    "county": "Shasta County",
    "state": "CA",
    "city": "Lakehead"
  },
  {
    "value": "96052",
    "label": "Lewiston - CA 96052",
    "county": "Trinity County",
    "state": "CA",
    "city": "Lewiston"
  },
  {
    "value": "96055",
    "label": "Los Molinos - CA 96055",
    "county": "Tehama County",
    "state": "CA",
    "city": "Los Molinos"
  },
  {
    "value": "96056",
    "label": "Mcarthur - CA 96056",
    "county": "Shasta County",
    "state": "CA",
    "city": "Mcarthur"
  },
  {
    "value": "96057",
    "label": "Mccloud - CA 96057",
    "county": "Siskiyou County",
    "state": "CA",
    "city": "Mccloud"
  },
  {
    "value": "96058",
    "label": "Macdoel - CA 96058",
    "county": "Siskiyou County",
    "state": "CA",
    "city": "Macdoel"
  },
  {
    "value": "96059",
    "label": "Manton - CA 96059",
    "county": "Tehama County",
    "state": "CA",
    "city": "Manton"
  },
  {
    "value": "96061",
    "label": "Mill Creek - CA 96061",
    "county": "Tehama County",
    "state": "CA",
    "city": "Mill Creek"
  },
  {
    "value": "96062",
    "label": "Millville - CA 96062",
    "county": "Shasta County",
    "state": "CA",
    "city": "Millville"
  },
  {
    "value": "96064",
    "label": "Montague - CA 96064",
    "county": "Siskiyou County",
    "state": "CA",
    "city": "Montague"
  },
  {
    "value": "96065",
    "label": "Montgomery Creek - CA 96065",
    "county": "Shasta County",
    "state": "CA",
    "city": "Montgomery Creek"
  },
  {
    "value": "96067",
    "label": "Mount Shasta - CA 96067",
    "county": "Siskiyou County",
    "state": "CA",
    "city": "Mount Shasta"
  },
  {
    "value": "96069",
    "label": "Oak Run - CA 96069",
    "county": "Shasta County",
    "state": "CA",
    "city": "Oak Run"
  },
  {
    "value": "96071",
    "label": "Old Station - CA 96071",
    "county": "Shasta County",
    "state": "CA",
    "city": "Old Station"
  },
  {
    "value": "96073",
    "label": "Palo Cedro - CA 96073",
    "county": "Shasta County",
    "state": "CA",
    "city": "Palo Cedro"
  },
  {
    "value": "96075",
    "label": "Paynes Creek - CA 96075",
    "county": "Tehama County",
    "state": "CA",
    "city": "Paynes Creek"
  },
  {
    "value": "96076",
    "label": "Platina - CA 96076",
    "county": "Trinity County",
    "state": "CA",
    "city": "Platina"
  },
  {
    "value": "96080",
    "label": "Red Bluff - CA 96080",
    "county": "Tehama County",
    "state": "CA",
    "city": "Red Bluff"
  },
  {
    "value": "96085",
    "label": "Scott Bar - CA 96085",
    "county": "Siskiyou County",
    "state": "CA",
    "city": "Scott Bar"
  },
  {
    "value": "96086",
    "label": "Seiad Valley - CA 96086",
    "county": "Siskiyou County",
    "state": "CA",
    "city": "Seiad Valley"
  },
  {
    "value": "96088",
    "label": "Shingletown - CA 96088",
    "county": "Shasta County",
    "state": "CA",
    "city": "Shingletown"
  },
  {
    "value": "96091",
    "label": "Trinity Center - CA 96091",
    "county": "Trinity County",
    "state": "CA",
    "city": "Trinity Center"
  },
  {
    "value": "96094",
    "label": "Weed - CA 96094",
    "county": "Siskiyou County",
    "state": "CA",
    "city": "Weed"
  },
  {
    "value": "96096",
    "label": "Whitmore - CA 96096",
    "county": "Shasta County",
    "state": "CA",
    "city": "Whitmore"
  },
  {
    "value": "96097",
    "label": "Yreka - CA 96097",
    "county": "Siskiyou County",
    "state": "CA",
    "city": "Yreka"
  },
  {
    "value": "96101",
    "label": "Alturas - CA 96101",
    "county": "Modoc County",
    "state": "CA",
    "city": "Alturas"
  },
  {
    "value": "96103",
    "label": "Blairsden Graeagle - CA 96103",
    "county": "Plumas County",
    "state": "CA",
    "city": "Blairsden Graeagle"
  },
  {
    "value": "96104",
    "label": "Cedarville - CA 96104",
    "county": "Modoc County",
    "state": "CA",
    "city": "Cedarville"
  },
  {
    "value": "96105",
    "label": "Chilcoot - CA 96105",
    "county": "Plumas County",
    "state": "CA",
    "city": "Chilcoot"
  },
  {
    "value": "96107",
    "label": "Coleville - CA 96107",
    "county": "Mono County",
    "state": "CA",
    "city": "Coleville"
  },
  {
    "value": "96108",
    "label": "Davis Creek - CA 96108",
    "county": "Modoc County",
    "state": "CA",
    "city": "Davis Creek"
  },
  {
    "value": "96109",
    "label": "Doyle - CA 96109",
    "county": "Lassen County",
    "state": "CA",
    "city": "Doyle"
  },
  {
    "value": "96112",
    "label": "Fort Bidwell - CA 96112",
    "county": "Modoc County",
    "state": "CA",
    "city": "Fort Bidwell"
  },
  {
    "value": "96114",
    "label": "Janesville - CA 96114",
    "county": "Lassen County",
    "state": "CA",
    "city": "Janesville"
  },
  {
    "value": "96115",
    "label": "Lake City - CA 96115",
    "county": "Modoc County",
    "state": "CA",
    "city": "Lake City"
  },
  {
    "value": "96117",
    "label": "Litchfield - CA 96117",
    "county": "Lassen County",
    "state": "CA",
    "city": "Litchfield"
  },
  {
    "value": "96118",
    "label": "Loyalton - CA 96118",
    "county": "Sierra County",
    "state": "CA",
    "city": "Loyalton"
  },
  {
    "value": "96120",
    "label": "Markleeville - CA 96120",
    "county": "Alpine County",
    "state": "CA",
    "city": "Markleeville"
  },
  {
    "value": "96121",
    "label": "Milford - CA 96121",
    "county": "Lassen County",
    "state": "CA",
    "city": "Milford"
  },
  {
    "value": "96122",
    "label": "Portola - CA 96122",
    "county": "Plumas County",
    "state": "CA",
    "city": "Portola"
  },
  {
    "value": "96123",
    "label": "Ravendale - CA 96123",
    "county": "Lassen County",
    "state": "CA",
    "city": "Ravendale"
  },
  {
    "value": "96124",
    "label": "Calpine - CA 96124",
    "county": "Sierra County",
    "state": "CA",
    "city": "Calpine"
  },
  {
    "value": "96125",
    "label": "Sierra City - CA 96125",
    "county": "Sierra County",
    "state": "CA",
    "city": "Sierra City"
  },
  {
    "value": "96128",
    "label": "Standish - CA 96128",
    "county": "Lassen County",
    "state": "CA",
    "city": "Standish"
  },
  {
    "value": "96129",
    "label": "Beckwourth - CA 96129",
    "county": "Plumas County",
    "state": "CA",
    "city": "Beckwourth"
  },
  {
    "value": "96130",
    "label": "Susanville - CA 96130",
    "county": "Lassen County",
    "state": "CA",
    "city": "Susanville"
  },
  {
    "value": "96132",
    "label": "Termo - CA 96132",
    "county": "Lassen County",
    "state": "CA",
    "city": "Termo"
  },
  {
    "value": "96133",
    "label": "Topaz - CA 96133",
    "county": "Mono County",
    "state": "CA",
    "city": "Topaz"
  },
  {
    "value": "96134",
    "label": "Tulelake - CA 96134",
    "county": "Siskiyou County",
    "state": "CA",
    "city": "Tulelake"
  },
  {
    "value": "96136",
    "label": "Wendel - CA 96136",
    "county": "Lassen County",
    "state": "CA",
    "city": "Wendel"
  },
  {
    "value": "96137",
    "label": "Westwood - CA 96137",
    "county": "Lassen County",
    "state": "CA",
    "city": "Westwood"
  },
  {
    "value": "96146",
    "label": "Olympic Valley - CA 96146",
    "county": "Placer County",
    "state": "CA",
    "city": "Olympic Valley"
  },
  {
    "value": "96150",
    "label": "South Lake Tahoe - CA 96150",
    "county": "El Dorado County",
    "state": "CA",
    "city": "South Lake Tahoe"
  },
  {
    "value": "96161",
    "label": "Truckee - CA 96161",
    "county": "Nevada County",
    "state": "CA",
    "city": "Truckee"
  },
  {
    "value": "80002",
    "label": "Arvada - CO 80002",
    "county": "Jefferson County",
    "state": "CO",
    "city": "Arvada"
  },
  {
    "value": "80003",
    "label": "Arvada - CO 80003",
    "county": "Jefferson County",
    "state": "CO",
    "city": "Arvada"
  },
  {
    "value": "80004",
    "label": "Arvada - CO 80004",
    "county": "Jefferson County",
    "state": "CO",
    "city": "Arvada"
  },
  {
    "value": "80005",
    "label": "Arvada - CO 80005",
    "county": "Jefferson County",
    "state": "CO",
    "city": "Arvada"
  },
  {
    "value": "80007",
    "label": "Arvada - CO 80007",
    "county": "Jefferson County",
    "state": "CO",
    "city": "Arvada"
  },
  {
    "value": "80010",
    "label": "Aurora - CO 80010",
    "county": "Arapahoe County",
    "state": "CO",
    "city": "Aurora"
  },
  {
    "value": "80011",
    "label": "Aurora - CO 80011",
    "county": "Arapahoe County",
    "state": "CO",
    "city": "Aurora"
  },
  {
    "value": "80012",
    "label": "Aurora - CO 80012",
    "county": "Arapahoe County",
    "state": "CO",
    "city": "Aurora"
  },
  {
    "value": "80013",
    "label": "Aurora - CO 80013",
    "county": "Arapahoe County",
    "state": "CO",
    "city": "Aurora"
  },
  {
    "value": "80014",
    "label": "Aurora - CO 80014",
    "county": "Arapahoe County",
    "state": "CO",
    "city": "Aurora"
  },
  {
    "value": "80015",
    "label": "Aurora - CO 80015",
    "county": "Arapahoe County",
    "state": "CO",
    "city": "Aurora"
  },
  {
    "value": "80016",
    "label": "Aurora - CO 80016",
    "county": "Arapahoe County",
    "state": "CO",
    "city": "Aurora"
  },
  {
    "value": "80017",
    "label": "Aurora - CO 80017",
    "county": "Arapahoe County",
    "state": "CO",
    "city": "Aurora"
  },
  {
    "value": "80018",
    "label": "Aurora - CO 80018",
    "county": "Arapahoe County",
    "state": "CO",
    "city": "Aurora"
  },
  {
    "value": "80019",
    "label": "Aurora - CO 80019",
    "county": "Adams County",
    "state": "CO",
    "city": "Aurora"
  },
  {
    "value": "80020",
    "label": "Broomfield - CO 80020",
    "county": "Broomfield County",
    "state": "CO",
    "city": "Broomfield"
  },
  {
    "value": "80021",
    "label": "Broomfield - CO 80021",
    "county": "Jefferson County",
    "state": "CO",
    "city": "Broomfield"
  },
  {
    "value": "80022",
    "label": "Commerce City - CO 80022",
    "county": "Adams County",
    "state": "CO",
    "city": "Commerce City"
  },
  {
    "value": "80023",
    "label": "Broomfield - CO 80023",
    "county": "Broomfield County",
    "state": "CO",
    "city": "Broomfield"
  },
  {
    "value": "80026",
    "label": "Lafayette - CO 80026",
    "county": "Boulder County",
    "state": "CO",
    "city": "Lafayette"
  },
  {
    "value": "80027",
    "label": "Louisville - CO 80027",
    "county": "Boulder County",
    "state": "CO",
    "city": "Louisville"
  },
  {
    "value": "80030",
    "label": "Westminster - CO 80030",
    "county": "Adams County",
    "state": "CO",
    "city": "Westminster"
  },
  {
    "value": "80031",
    "label": "Westminster - CO 80031",
    "county": "Adams County",
    "state": "CO",
    "city": "Westminster"
  },
  {
    "value": "80033",
    "label": "Wheat Ridge - CO 80033",
    "county": "Jefferson County",
    "state": "CO",
    "city": "Wheat Ridge"
  },
  {
    "value": "80045",
    "label": "Aurora - CO 80045",
    "county": "Adams County",
    "state": "CO",
    "city": "Aurora"
  },
  {
    "value": "80101",
    "label": "Agate - CO 80101",
    "county": "Elbert County",
    "state": "CO",
    "city": "Agate"
  },
  {
    "value": "80102",
    "label": "Bennett - CO 80102",
    "county": "Adams County",
    "state": "CO",
    "city": "Bennett"
  },
  {
    "value": "80103",
    "label": "Byers - CO 80103",
    "county": "Arapahoe County",
    "state": "CO",
    "city": "Byers"
  },
  {
    "value": "80104",
    "label": "Castle Rock - CO 80104",
    "county": "Douglas County",
    "state": "CO",
    "city": "Castle Rock"
  },
  {
    "value": "80105",
    "label": "Deer Trail - CO 80105",
    "county": "Arapahoe County",
    "state": "CO",
    "city": "Deer Trail"
  },
  {
    "value": "80106",
    "label": "Elbert - CO 80106",
    "county": "El Paso County",
    "state": "CO",
    "city": "Elbert"
  },
  {
    "value": "80107",
    "label": "Elizabeth - CO 80107",
    "county": "Elbert County",
    "state": "CO",
    "city": "Elizabeth"
  },
  {
    "value": "80108",
    "label": "Castle Rock - CO 80108",
    "county": "Douglas County",
    "state": "CO",
    "city": "Castle Rock"
  },
  {
    "value": "80109",
    "label": "Castle Rock - CO 80109",
    "county": "Douglas County",
    "state": "CO",
    "city": "Castle Rock"
  },
  {
    "value": "80110",
    "label": "Englewood - CO 80110",
    "county": "Arapahoe County",
    "state": "CO",
    "city": "Englewood"
  },
  {
    "value": "80111",
    "label": "Englewood - CO 80111",
    "county": "Arapahoe County",
    "state": "CO",
    "city": "Englewood"
  },
  {
    "value": "80112",
    "label": "Englewood - CO 80112",
    "county": "Arapahoe County",
    "state": "CO",
    "city": "Englewood"
  },
  {
    "value": "80113",
    "label": "Englewood - CO 80113",
    "county": "Arapahoe County",
    "state": "CO",
    "city": "Englewood"
  },
  {
    "value": "80116",
    "label": "Franktown - CO 80116",
    "county": "Douglas County",
    "state": "CO",
    "city": "Franktown"
  },
  {
    "value": "80117",
    "label": "Kiowa - CO 80117",
    "county": "Elbert County",
    "state": "CO",
    "city": "Kiowa"
  },
  {
    "value": "80118",
    "label": "Larkspur - CO 80118",
    "county": "Douglas County",
    "state": "CO",
    "city": "Larkspur"
  },
  {
    "value": "80120",
    "label": "Littleton - CO 80120",
    "county": "Arapahoe County",
    "state": "CO",
    "city": "Littleton"
  },
  {
    "value": "80121",
    "label": "Littleton - CO 80121",
    "county": "Arapahoe County",
    "state": "CO",
    "city": "Littleton"
  },
  {
    "value": "80122",
    "label": "Littleton - CO 80122",
    "county": "Arapahoe County",
    "state": "CO",
    "city": "Littleton"
  },
  {
    "value": "80123",
    "label": "Littleton - CO 80123",
    "county": "Jefferson County",
    "state": "CO",
    "city": "Littleton"
  },
  {
    "value": "80124",
    "label": "Lone Tree - CO 80124",
    "county": "Douglas County",
    "state": "CO",
    "city": "Lone Tree"
  },
  {
    "value": "80125",
    "label": "Littleton - CO 80125",
    "county": "Douglas County",
    "state": "CO",
    "city": "Littleton"
  },
  {
    "value": "80126",
    "label": "Littleton - CO 80126",
    "county": "Douglas County",
    "state": "CO",
    "city": "Littleton"
  },
  {
    "value": "80127",
    "label": "Littleton - CO 80127",
    "county": "Jefferson County",
    "state": "CO",
    "city": "Littleton"
  },
  {
    "value": "80128",
    "label": "Littleton - CO 80128",
    "county": "Jefferson County",
    "state": "CO",
    "city": "Littleton"
  },
  {
    "value": "80129",
    "label": "Littleton - CO 80129",
    "county": "Douglas County",
    "state": "CO",
    "city": "Littleton"
  },
  {
    "value": "80130",
    "label": "Littleton - CO 80130",
    "county": "Douglas County",
    "state": "CO",
    "city": "Littleton"
  },
  {
    "value": "80132",
    "label": "Monument - CO 80132",
    "county": "El Paso County",
    "state": "CO",
    "city": "Monument"
  },
  {
    "value": "80133",
    "label": "Palmer Lake - CO 80133",
    "county": "El Paso County",
    "state": "CO",
    "city": "Palmer Lake"
  },
  {
    "value": "80134",
    "label": "Parker - CO 80134",
    "county": "Douglas County",
    "state": "CO",
    "city": "Parker"
  },
  {
    "value": "80135",
    "label": "Sedalia - CO 80135",
    "county": "Douglas County",
    "state": "CO",
    "city": "Sedalia"
  },
  {
    "value": "80136",
    "label": "Strasburg - CO 80136",
    "county": "Adams County",
    "state": "CO",
    "city": "Strasburg"
  },
  {
    "value": "80137",
    "label": "Watkins - CO 80137",
    "county": "Arapahoe County",
    "state": "CO",
    "city": "Watkins"
  },
  {
    "value": "80138",
    "label": "Parker - CO 80138",
    "county": "Douglas County",
    "state": "CO",
    "city": "Parker"
  },
  {
    "value": "80202",
    "label": "Denver - CO 80202",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80203",
    "label": "Denver - CO 80203",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80204",
    "label": "Denver - CO 80204",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80205",
    "label": "Denver - CO 80205",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80206",
    "label": "Denver - CO 80206",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80207",
    "label": "Denver - CO 80207",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80209",
    "label": "Denver - CO 80209",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80210",
    "label": "Denver - CO 80210",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80211",
    "label": "Denver - CO 80211",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80212",
    "label": "Denver - CO 80212",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80214",
    "label": "Denver - CO 80214",
    "county": "Jefferson County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80215",
    "label": "Denver - CO 80215",
    "county": "Jefferson County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80216",
    "label": "Denver - CO 80216",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80218",
    "label": "Denver - CO 80218",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80219",
    "label": "Denver - CO 80219",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80220",
    "label": "Denver - CO 80220",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80221",
    "label": "Denver - CO 80221",
    "county": "Adams County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80222",
    "label": "Denver - CO 80222",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80223",
    "label": "Denver - CO 80223",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80224",
    "label": "Denver - CO 80224",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80226",
    "label": "Denver - CO 80226",
    "county": "Jefferson County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80227",
    "label": "Denver - CO 80227",
    "county": "Jefferson County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80228",
    "label": "Denver - CO 80228",
    "county": "Jefferson County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80229",
    "label": "Denver - CO 80229",
    "county": "Adams County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80230",
    "label": "Denver - CO 80230",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80231",
    "label": "Denver - CO 80231",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80232",
    "label": "Denver - CO 80232",
    "county": "Jefferson County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80233",
    "label": "Denver - CO 80233",
    "county": "Adams County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80234",
    "label": "Denver - CO 80234",
    "county": "Adams County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80235",
    "label": "Denver - CO 80235",
    "county": "Jefferson County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80236",
    "label": "Denver - CO 80236",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80237",
    "label": "Denver - CO 80237",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80238",
    "label": "Denver - CO 80238",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80239",
    "label": "Denver - CO 80239",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80241",
    "label": "Thornton - CO 80241",
    "county": "Adams County",
    "state": "CO",
    "city": "Thornton"
  },
  {
    "value": "80246",
    "label": "Denver - CO 80246",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80247",
    "label": "Denver - CO 80247",
    "county": "Arapahoe County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80249",
    "label": "Denver - CO 80249",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80260",
    "label": "Denver - CO 80260",
    "county": "Adams County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80264",
    "label": "Denver - CO 80264",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80265",
    "label": "Denver - CO 80265",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80266",
    "label": "Denver - CO 80266",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80290",
    "label": "Denver - CO 80290",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80293",
    "label": "Denver - CO 80293",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80294",
    "label": "Denver - CO 80294",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80295",
    "label": "Denver - CO 80295",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80299",
    "label": "Denver - CO 80299",
    "county": "Denver County",
    "state": "CO",
    "city": "Denver"
  },
  {
    "value": "80301",
    "label": "Boulder - CO 80301",
    "county": "Boulder County",
    "state": "CO",
    "city": "Boulder"
  },
  {
    "value": "80302",
    "label": "Boulder - CO 80302",
    "county": "Boulder County",
    "state": "CO",
    "city": "Boulder"
  },
  {
    "value": "80303",
    "label": "Boulder - CO 80303",
    "county": "Boulder County",
    "state": "CO",
    "city": "Boulder"
  },
  {
    "value": "80304",
    "label": "Boulder - CO 80304",
    "county": "Boulder County",
    "state": "CO",
    "city": "Boulder"
  },
  {
    "value": "80305",
    "label": "Boulder - CO 80305",
    "county": "Boulder County",
    "state": "CO",
    "city": "Boulder"
  },
  {
    "value": "80401",
    "label": "Golden - CO 80401",
    "county": "Jefferson County",
    "state": "CO",
    "city": "Golden"
  },
  {
    "value": "80403",
    "label": "Golden - CO 80403",
    "county": "Jefferson County",
    "state": "CO",
    "city": "Golden"
  },
  {
    "value": "80421",
    "label": "Bailey - CO 80421",
    "county": "Park County",
    "state": "CO",
    "city": "Bailey"
  },
  {
    "value": "80422",
    "label": "Black Hawk - CO 80422",
    "county": "Gilpin County",
    "state": "CO",
    "city": "Black Hawk"
  },
  {
    "value": "80423",
    "label": "Bond - CO 80423",
    "county": "Eagle County",
    "state": "CO",
    "city": "Bond"
  },
  {
    "value": "80424",
    "label": "Breckenridge - CO 80424",
    "county": "Summit County",
    "state": "CO",
    "city": "Breckenridge"
  },
  {
    "value": "80428",
    "label": "Clark - CO 80428",
    "county": "Routt County",
    "state": "CO",
    "city": "Clark"
  },
  {
    "value": "80430",
    "label": "Coalmont - CO 80430",
    "county": "Jackson County",
    "state": "CO",
    "city": "Coalmont"
  },
  {
    "value": "80433",
    "label": "Conifer - CO 80433",
    "county": "Jefferson County",
    "state": "CO",
    "city": "Conifer"
  },
  {
    "value": "80435",
    "label": "Dillon - CO 80435",
    "county": "Summit County",
    "state": "CO",
    "city": "Dillon"
  },
  {
    "value": "80439",
    "label": "Evergreen - CO 80439",
    "county": "Jefferson County",
    "state": "CO",
    "city": "Evergreen"
  },
  {
    "value": "80440",
    "label": "Fairplay - CO 80440",
    "county": "Park County",
    "state": "CO",
    "city": "Fairplay"
  },
  {
    "value": "80446",
    "label": "Granby - CO 80446",
    "county": "Grand County",
    "state": "CO",
    "city": "Granby"
  },
  {
    "value": "80447",
    "label": "Grand Lake - CO 80447",
    "county": "Grand County",
    "state": "CO",
    "city": "Grand Lake"
  },
  {
    "value": "80449",
    "label": "Hartsel - CO 80449",
    "county": "Park County",
    "state": "CO",
    "city": "Hartsel"
  },
  {
    "value": "80452",
    "label": "Idaho Springs - CO 80452",
    "county": "Clear Creek County",
    "state": "CO",
    "city": "Idaho Springs"
  },
  {
    "value": "80455",
    "label": "Jamestown - CO 80455",
    "county": "Boulder County",
    "state": "CO",
    "city": "Jamestown"
  },
  {
    "value": "80456",
    "label": "Jefferson - CO 80456",
    "county": "Park County",
    "state": "CO",
    "city": "Jefferson"
  },
  {
    "value": "80459",
    "label": "Kremmling - CO 80459",
    "county": "Grand County",
    "state": "CO",
    "city": "Kremmling"
  },
  {
    "value": "80461",
    "label": "Leadville - CO 80461",
    "county": "Lake County",
    "state": "CO",
    "city": "Leadville"
  },
  {
    "value": "80463",
    "label": "Mc Coy - CO 80463",
    "county": "Routt County",
    "state": "CO",
    "city": "Mc Coy"
  },
  {
    "value": "80465",
    "label": "Morrison - CO 80465",
    "county": "Jefferson County",
    "state": "CO",
    "city": "Morrison"
  },
  {
    "value": "80466",
    "label": "Nederland - CO 80466",
    "county": "Boulder County",
    "state": "CO",
    "city": "Nederland"
  },
  {
    "value": "80467",
    "label": "Oak Creek - CO 80467",
    "county": "Routt County",
    "state": "CO",
    "city": "Oak Creek"
  },
  {
    "value": "80468",
    "label": "Parshall - CO 80468",
    "county": "Grand County",
    "state": "CO",
    "city": "Parshall"
  },
  {
    "value": "80470",
    "label": "Pine - CO 80470",
    "county": "Jefferson County",
    "state": "CO",
    "city": "Pine"
  },
  {
    "value": "80480",
    "label": "Walden - CO 80480",
    "county": "Jackson County",
    "state": "CO",
    "city": "Walden"
  },
  {
    "value": "80481",
    "label": "Ward - CO 80481",
    "county": "Boulder County",
    "state": "CO",
    "city": "Ward"
  },
  {
    "value": "80487",
    "label": "Steamboat Springs - CO 80487",
    "county": "Routt County",
    "state": "CO",
    "city": "Steamboat Springs"
  },
  {
    "value": "80498",
    "label": "Silverthorne - CO 80498",
    "county": "Summit County",
    "state": "CO",
    "city": "Silverthorne"
  },
  {
    "value": "80501",
    "label": "Longmont - CO 80501",
    "county": "Boulder County",
    "state": "CO",
    "city": "Longmont"
  },
  {
    "value": "80503",
    "label": "Longmont - CO 80503",
    "county": "Boulder County",
    "state": "CO",
    "city": "Longmont"
  },
  {
    "value": "80504",
    "label": "Longmont - CO 80504",
    "county": "Boulder County",
    "state": "CO",
    "city": "Longmont"
  },
  {
    "value": "80510",
    "label": "Allenspark - CO 80510",
    "county": "Boulder County",
    "state": "CO",
    "city": "Allenspark"
  },
  {
    "value": "80512",
    "label": "Bellvue - CO 80512",
    "county": "Larimer County",
    "state": "CO",
    "city": "Bellvue"
  },
  {
    "value": "80513",
    "label": "Berthoud - CO 80513",
    "county": "Larimer County",
    "state": "CO",
    "city": "Berthoud"
  },
  {
    "value": "80514",
    "label": "Dacono - CO 80514",
    "county": "Weld County",
    "state": "CO",
    "city": "Dacono"
  },
  {
    "value": "80515",
    "label": "Drake - CO 80515",
    "county": "Larimer County",
    "state": "CO",
    "city": "Drake"
  },
  {
    "value": "80516",
    "label": "Erie - CO 80516",
    "county": "Weld County",
    "state": "CO",
    "city": "Erie"
  },
  {
    "value": "80517",
    "label": "Estes Park - CO 80517",
    "county": "Larimer County",
    "state": "CO",
    "city": "Estes Park"
  },
  {
    "value": "80521",
    "label": "Fort Collins - CO 80521",
    "county": "Larimer County",
    "state": "CO",
    "city": "Fort Collins"
  },
  {
    "value": "80524",
    "label": "Fort Collins - CO 80524",
    "county": "Larimer County",
    "state": "CO",
    "city": "Fort Collins"
  },
  {
    "value": "80525",
    "label": "Fort Collins - CO 80525",
    "county": "Larimer County",
    "state": "CO",
    "city": "Fort Collins"
  },
  {
    "value": "80526",
    "label": "Fort Collins - CO 80526",
    "county": "Larimer County",
    "state": "CO",
    "city": "Fort Collins"
  },
  {
    "value": "80528",
    "label": "Fort Collins - CO 80528",
    "county": "Larimer County",
    "state": "CO",
    "city": "Fort Collins"
  },
  {
    "value": "80530",
    "label": "Frederick - CO 80530",
    "county": "Weld County",
    "state": "CO",
    "city": "Frederick"
  },
  {
    "value": "80534",
    "label": "Johnstown - CO 80534",
    "county": "Weld County",
    "state": "CO",
    "city": "Johnstown"
  },
  {
    "value": "80535",
    "label": "Laporte - CO 80535",
    "county": "Larimer County",
    "state": "CO",
    "city": "Laporte"
  },
  {
    "value": "80536",
    "label": "Livermore - CO 80536",
    "county": "Larimer County",
    "state": "CO",
    "city": "Livermore"
  },
  {
    "value": "80537",
    "label": "Loveland - CO 80537",
    "county": "Larimer County",
    "state": "CO",
    "city": "Loveland"
  },
  {
    "value": "80538",
    "label": "Loveland - CO 80538",
    "county": "Larimer County",
    "state": "CO",
    "city": "Loveland"
  },
  {
    "value": "80540",
    "label": "Lyons - CO 80540",
    "county": "Boulder County",
    "state": "CO",
    "city": "Lyons"
  },
  {
    "value": "80542",
    "label": "Mead - CO 80542",
    "county": "Weld County",
    "state": "CO",
    "city": "Mead"
  },
  {
    "value": "80543",
    "label": "Milliken - CO 80543",
    "county": "Weld County",
    "state": "CO",
    "city": "Milliken"
  },
  {
    "value": "80545",
    "label": "Red Feather Lakes - CO 80545",
    "county": "Larimer County",
    "state": "CO",
    "city": "Red Feather Lakes"
  },
  {
    "value": "80547",
    "label": "Timnath - CO 80547",
    "county": "Larimer County",
    "state": "CO",
    "city": "Timnath"
  },
  {
    "value": "80549",
    "label": "Wellington - CO 80549",
    "county": "Larimer County",
    "state": "CO",
    "city": "Wellington"
  },
  {
    "value": "80550",
    "label": "Windsor - CO 80550",
    "county": "Weld County",
    "state": "CO",
    "city": "Windsor"
  },
  {
    "value": "80601",
    "label": "Brighton - CO 80601",
    "county": "Adams County",
    "state": "CO",
    "city": "Brighton"
  },
  {
    "value": "80602",
    "label": "Brighton - CO 80602",
    "county": "Adams County",
    "state": "CO",
    "city": "Brighton"
  },
  {
    "value": "80603",
    "label": "Brighton - CO 80603",
    "county": "Weld County",
    "state": "CO",
    "city": "Brighton"
  },
  {
    "value": "80610",
    "label": "Ault - CO 80610",
    "county": "Weld County",
    "state": "CO",
    "city": "Ault"
  },
  {
    "value": "80611",
    "label": "Briggsdale - CO 80611",
    "county": "Weld County",
    "state": "CO",
    "city": "Briggsdale"
  },
  {
    "value": "80612",
    "label": "Carr - CO 80612",
    "county": "Weld County",
    "state": "CO",
    "city": "Carr"
  },
  {
    "value": "80615",
    "label": "Eaton - CO 80615",
    "county": "Weld County",
    "state": "CO",
    "city": "Eaton"
  },
  {
    "value": "80620",
    "label": "Evans - CO 80620",
    "county": "Weld County",
    "state": "CO",
    "city": "Evans"
  },
  {
    "value": "80621",
    "label": "Fort Lupton - CO 80621",
    "county": "Weld County",
    "state": "CO",
    "city": "Fort Lupton"
  },
  {
    "value": "80622",
    "label": "Galeton - CO 80622",
    "county": "Weld County",
    "state": "CO",
    "city": "Galeton"
  },
  {
    "value": "80624",
    "label": "Gill - CO 80624",
    "county": "Weld County",
    "state": "CO",
    "city": "Gill"
  },
  {
    "value": "80631",
    "label": "Greeley - CO 80631",
    "county": "Weld County",
    "state": "CO",
    "city": "Greeley"
  },
  {
    "value": "80634",
    "label": "Greeley - CO 80634",
    "county": "Weld County",
    "state": "CO",
    "city": "Greeley"
  },
  {
    "value": "80640",
    "label": "Henderson - CO 80640",
    "county": "Adams County",
    "state": "CO",
    "city": "Henderson"
  },
  {
    "value": "80642",
    "label": "Hudson - CO 80642",
    "county": "Weld County",
    "state": "CO",
    "city": "Hudson"
  },
  {
    "value": "80643",
    "label": "Keenesburg - CO 80643",
    "county": "Weld County",
    "state": "CO",
    "city": "Keenesburg"
  },
  {
    "value": "80644",
    "label": "Kersey - CO 80644",
    "county": "Weld County",
    "state": "CO",
    "city": "Kersey"
  },
  {
    "value": "80645",
    "label": "La Salle - CO 80645",
    "county": "Weld County",
    "state": "CO",
    "city": "La Salle"
  },
  {
    "value": "80648",
    "label": "Nunn - CO 80648",
    "county": "Weld County",
    "state": "CO",
    "city": "Nunn"
  },
  {
    "value": "80649",
    "label": "Orchard - CO 80649",
    "county": "Morgan County",
    "state": "CO",
    "city": "Orchard"
  },
  {
    "value": "80650",
    "label": "Pierce - CO 80650",
    "county": "Weld County",
    "state": "CO",
    "city": "Pierce"
  },
  {
    "value": "80651",
    "label": "Platteville - CO 80651",
    "county": "Weld County",
    "state": "CO",
    "city": "Platteville"
  },
  {
    "value": "80652",
    "label": "Roggen - CO 80652",
    "county": "Weld County",
    "state": "CO",
    "city": "Roggen"
  },
  {
    "value": "80653",
    "label": "Weldona - CO 80653",
    "county": "Morgan County",
    "state": "CO",
    "city": "Weldona"
  },
  {
    "value": "80654",
    "label": "Wiggins - CO 80654",
    "county": "Morgan County",
    "state": "CO",
    "city": "Wiggins"
  },
  {
    "value": "80701",
    "label": "Fort Morgan - CO 80701",
    "county": "Morgan County",
    "state": "CO",
    "city": "Fort Morgan"
  },
  {
    "value": "80705",
    "label": "Log Lane Village - CO 80705",
    "county": "Morgan County",
    "state": "CO",
    "city": "Log Lane Village"
  },
  {
    "value": "80720",
    "label": "Akron - CO 80720",
    "county": "Washington County",
    "state": "CO",
    "city": "Akron"
  },
  {
    "value": "80721",
    "label": "Amherst - CO 80721",
    "county": "Phillips County",
    "state": "CO",
    "city": "Amherst"
  },
  {
    "value": "80722",
    "label": "Atwood - CO 80722",
    "county": "Logan County",
    "state": "CO",
    "city": "Atwood"
  },
  {
    "value": "80723",
    "label": "Brush - CO 80723",
    "county": "Morgan County",
    "state": "CO",
    "city": "Brush"
  },
  {
    "value": "80726",
    "label": "Crook - CO 80726",
    "county": "Logan County",
    "state": "CO",
    "city": "Crook"
  },
  {
    "value": "80727",
    "label": "Eckley - CO 80727",
    "county": "Yuma County",
    "state": "CO",
    "city": "Eckley"
  },
  {
    "value": "80728",
    "label": "Fleming - CO 80728",
    "county": "Logan County",
    "state": "CO",
    "city": "Fleming"
  },
  {
    "value": "80729",
    "label": "Grover - CO 80729",
    "county": "Weld County",
    "state": "CO",
    "city": "Grover"
  },
  {
    "value": "80731",
    "label": "Haxtun - CO 80731",
    "county": "Phillips County",
    "state": "CO",
    "city": "Haxtun"
  },
  {
    "value": "80733",
    "label": "Hillrose - CO 80733",
    "county": "Morgan County",
    "state": "CO",
    "city": "Hillrose"
  },
  {
    "value": "80734",
    "label": "Holyoke - CO 80734",
    "county": "Phillips County",
    "state": "CO",
    "city": "Holyoke"
  },
  {
    "value": "80735",
    "label": "Idalia - CO 80735",
    "county": "Yuma County",
    "state": "CO",
    "city": "Idalia"
  },
  {
    "value": "80736",
    "label": "Iliff - CO 80736",
    "county": "Logan County",
    "state": "CO",
    "city": "Iliff"
  },
  {
    "value": "80737",
    "label": "Julesburg - CO 80737",
    "county": "Sedgwick County",
    "state": "CO",
    "city": "Julesburg"
  },
  {
    "value": "80740",
    "label": "Lindon - CO 80740",
    "county": "Washington County",
    "state": "CO",
    "city": "Lindon"
  },
  {
    "value": "80741",
    "label": "Merino - CO 80741",
    "county": "Logan County",
    "state": "CO",
    "city": "Merino"
  },
  {
    "value": "80742",
    "label": "New Raymer - CO 80742",
    "county": "Weld County",
    "state": "CO",
    "city": "New Raymer"
  },
  {
    "value": "80743",
    "label": "Otis - CO 80743",
    "county": "Washington County",
    "state": "CO",
    "city": "Otis"
  },
  {
    "value": "80744",
    "label": "Ovid - CO 80744",
    "county": "Sedgwick County",
    "state": "CO",
    "city": "Ovid"
  },
  {
    "value": "80745",
    "label": "Padroni - CO 80745",
    "county": "Logan County",
    "state": "CO",
    "city": "Padroni"
  },
  {
    "value": "80746",
    "label": "Paoli - CO 80746",
    "county": "Phillips County",
    "state": "CO",
    "city": "Paoli"
  },
  {
    "value": "80747",
    "label": "Peetz - CO 80747",
    "county": "Logan County",
    "state": "CO",
    "city": "Peetz"
  },
  {
    "value": "80749",
    "label": "Sedgwick - CO 80749",
    "county": "Sedgwick County",
    "state": "CO",
    "city": "Sedgwick"
  },
  {
    "value": "80750",
    "label": "Snyder - CO 80750",
    "county": "Morgan County",
    "state": "CO",
    "city": "Snyder"
  },
  {
    "value": "80751",
    "label": "Sterling - CO 80751",
    "county": "Logan County",
    "state": "CO",
    "city": "Sterling"
  },
  {
    "value": "80754",
    "label": "Stoneham - CO 80754",
    "county": "Weld County",
    "state": "CO",
    "city": "Stoneham"
  },
  {
    "value": "80755",
    "label": "Vernon - CO 80755",
    "county": "Yuma County",
    "state": "CO",
    "city": "Vernon"
  },
  {
    "value": "80757",
    "label": "Woodrow - CO 80757",
    "county": "Washington County",
    "state": "CO",
    "city": "Woodrow"
  },
  {
    "value": "80758",
    "label": "Wray - CO 80758",
    "county": "Yuma County",
    "state": "CO",
    "city": "Wray"
  },
  {
    "value": "80759",
    "label": "Yuma - CO 80759",
    "county": "Yuma County",
    "state": "CO",
    "city": "Yuma"
  },
  {
    "value": "80801",
    "label": "Anton - CO 80801",
    "county": "Washington County",
    "state": "CO",
    "city": "Anton"
  },
  {
    "value": "80802",
    "label": "Arapahoe - CO 80802",
    "county": "Cheyenne County",
    "state": "CO",
    "city": "Arapahoe"
  },
  {
    "value": "80804",
    "label": "Arriba - CO 80804",
    "county": "Lincoln County",
    "state": "CO",
    "city": "Arriba"
  },
  {
    "value": "80805",
    "label": "Bethune - CO 80805",
    "county": "Kit Carson County",
    "state": "CO",
    "city": "Bethune"
  },
  {
    "value": "80807",
    "label": "Burlington - CO 80807",
    "county": "Kit Carson County",
    "state": "CO",
    "city": "Burlington"
  },
  {
    "value": "80808",
    "label": "Calhan - CO 80808",
    "county": "El Paso County",
    "state": "CO",
    "city": "Calhan"
  },
  {
    "value": "80809",
    "label": "Cascade - CO 80809",
    "county": "El Paso County",
    "state": "CO",
    "city": "Cascade"
  },
  {
    "value": "80810",
    "label": "Cheyenne Wells - CO 80810",
    "county": "Cheyenne County",
    "state": "CO",
    "city": "Cheyenne Wells"
  },
  {
    "value": "80812",
    "label": "Cope - CO 80812",
    "county": "Washington County",
    "state": "CO",
    "city": "Cope"
  },
  {
    "value": "80813",
    "label": "Cripple Creek - CO 80813",
    "county": "Teller County",
    "state": "CO",
    "city": "Cripple Creek"
  },
  {
    "value": "80814",
    "label": "Divide - CO 80814",
    "county": "Teller County",
    "state": "CO",
    "city": "Divide"
  },
  {
    "value": "80815",
    "label": "Flagler - CO 80815",
    "county": "Kit Carson County",
    "state": "CO",
    "city": "Flagler"
  },
  {
    "value": "80816",
    "label": "Florissant - CO 80816",
    "county": "Teller County",
    "state": "CO",
    "city": "Florissant"
  },
  {
    "value": "80817",
    "label": "Fountain - CO 80817",
    "county": "El Paso County",
    "state": "CO",
    "city": "Fountain"
  },
  {
    "value": "80818",
    "label": "Genoa - CO 80818",
    "county": "Lincoln County",
    "state": "CO",
    "city": "Genoa"
  },
  {
    "value": "80820",
    "label": "Guffey - CO 80820",
    "county": "Park County",
    "state": "CO",
    "city": "Guffey"
  },
  {
    "value": "80821",
    "label": "Hugo - CO 80821",
    "county": "Lincoln County",
    "state": "CO",
    "city": "Hugo"
  },
  {
    "value": "80822",
    "label": "Joes - CO 80822",
    "county": "Yuma County",
    "state": "CO",
    "city": "Joes"
  },
  {
    "value": "80823",
    "label": "Karval - CO 80823",
    "county": "Lincoln County",
    "state": "CO",
    "city": "Karval"
  },
  {
    "value": "80824",
    "label": "Kirk - CO 80824",
    "county": "Yuma County",
    "state": "CO",
    "city": "Kirk"
  },
  {
    "value": "80825",
    "label": "Kit Carson - CO 80825",
    "county": "Cheyenne County",
    "state": "CO",
    "city": "Kit Carson"
  },
  {
    "value": "80827",
    "label": "Lake George - CO 80827",
    "county": "Park County",
    "state": "CO",
    "city": "Lake George"
  },
  {
    "value": "80828",
    "label": "Limon - CO 80828",
    "county": "Lincoln County",
    "state": "CO",
    "city": "Limon"
  },
  {
    "value": "80829",
    "label": "Manitou Springs - CO 80829",
    "county": "El Paso County",
    "state": "CO",
    "city": "Manitou Springs"
  },
  {
    "value": "80830",
    "label": "Matheson - CO 80830",
    "county": "Elbert County",
    "state": "CO",
    "city": "Matheson"
  },
  {
    "value": "80831",
    "label": "Peyton - CO 80831",
    "county": "El Paso County",
    "state": "CO",
    "city": "Peyton"
  },
  {
    "value": "80832",
    "label": "Ramah - CO 80832",
    "county": "El Paso County",
    "state": "CO",
    "city": "Ramah"
  },
  {
    "value": "80833",
    "label": "Rush - CO 80833",
    "county": "El Paso County",
    "state": "CO",
    "city": "Rush"
  },
  {
    "value": "80834",
    "label": "Seibert - CO 80834",
    "county": "Kit Carson County",
    "state": "CO",
    "city": "Seibert"
  },
  {
    "value": "80835",
    "label": "Simla - CO 80835",
    "county": "Elbert County",
    "state": "CO",
    "city": "Simla"
  },
  {
    "value": "80836",
    "label": "Stratton - CO 80836",
    "county": "Kit Carson County",
    "state": "CO",
    "city": "Stratton"
  },
  {
    "value": "80840",
    "label": "Usaf Academy - CO 80840",
    "county": "El Paso County",
    "state": "CO",
    "city": "Usaf Academy"
  },
  {
    "value": "80861",
    "label": "Vona - CO 80861",
    "county": "Kit Carson County",
    "state": "CO",
    "city": "Vona"
  },
  {
    "value": "80863",
    "label": "Woodland Park - CO 80863",
    "county": "Teller County",
    "state": "CO",
    "city": "Woodland Park"
  },
  {
    "value": "80864",
    "label": "Yoder - CO 80864",
    "county": "El Paso County",
    "state": "CO",
    "city": "Yoder"
  },
  {
    "value": "80902",
    "label": "Colorado Springs - CO 80902",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80903",
    "label": "Colorado Springs - CO 80903",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80904",
    "label": "Colorado Springs - CO 80904",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80905",
    "label": "Colorado Springs - CO 80905",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80906",
    "label": "Colorado Springs - CO 80906",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80907",
    "label": "Colorado Springs - CO 80907",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80908",
    "label": "Colorado Springs - CO 80908",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80909",
    "label": "Colorado Springs - CO 80909",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80910",
    "label": "Colorado Springs - CO 80910",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80911",
    "label": "Colorado Springs - CO 80911",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80912",
    "label": "Colorado Springs - CO 80912",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80913",
    "label": "Colorado Springs - CO 80913",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80915",
    "label": "Colorado Springs - CO 80915",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80916",
    "label": "Colorado Springs - CO 80916",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80917",
    "label": "Colorado Springs - CO 80917",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80918",
    "label": "Colorado Springs - CO 80918",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80919",
    "label": "Colorado Springs - CO 80919",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80920",
    "label": "Colorado Springs - CO 80920",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80921",
    "label": "Colorado Springs - CO 80921",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80922",
    "label": "Colorado Springs - CO 80922",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80923",
    "label": "Colorado Springs - CO 80923",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80924",
    "label": "Colorado Springs - CO 80924",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80925",
    "label": "Colorado Springs - CO 80925",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80926",
    "label": "Colorado Springs - CO 80926",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80927",
    "label": "Colorado Springs - CO 80927",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80928",
    "label": "Colorado Springs - CO 80928",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80929",
    "label": "Colorado Springs - CO 80929",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80930",
    "label": "Colorado Springs - CO 80930",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80938",
    "label": "Colorado Springs - CO 80938",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80939",
    "label": "Colorado Springs - CO 80939",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "80951",
    "label": "Colorado Springs - CO 80951",
    "county": "El Paso County",
    "state": "CO",
    "city": "Colorado Springs"
  },
  {
    "value": "81001",
    "label": "Pueblo - CO 81001",
    "county": "Pueblo County",
    "state": "CO",
    "city": "Pueblo"
  },
  {
    "value": "81003",
    "label": "Pueblo - CO 81003",
    "county": "Pueblo County",
    "state": "CO",
    "city": "Pueblo"
  },
  {
    "value": "81004",
    "label": "Pueblo - CO 81004",
    "county": "Pueblo County",
    "state": "CO",
    "city": "Pueblo"
  },
  {
    "value": "81005",
    "label": "Pueblo - CO 81005",
    "county": "Pueblo County",
    "state": "CO",
    "city": "Pueblo"
  },
  {
    "value": "81006",
    "label": "Pueblo - CO 81006",
    "county": "Pueblo County",
    "state": "CO",
    "city": "Pueblo"
  },
  {
    "value": "81007",
    "label": "Pueblo - CO 81007",
    "county": "Pueblo County",
    "state": "CO",
    "city": "Pueblo"
  },
  {
    "value": "81008",
    "label": "Pueblo - CO 81008",
    "county": "Pueblo County",
    "state": "CO",
    "city": "Pueblo"
  },
  {
    "value": "81020",
    "label": "Aguilar - CO 81020",
    "county": "Las Animas County",
    "state": "CO",
    "city": "Aguilar"
  },
  {
    "value": "81021",
    "label": "Arlington - CO 81021",
    "county": "Kiowa County",
    "state": "CO",
    "city": "Arlington"
  },
  {
    "value": "81022",
    "label": "Avondale - CO 81022",
    "county": "Pueblo County",
    "state": "CO",
    "city": "Avondale"
  },
  {
    "value": "81023",
    "label": "Beulah - CO 81023",
    "county": "Pueblo County",
    "state": "CO",
    "city": "Beulah"
  },
  {
    "value": "81024",
    "label": "Boncarbo - CO 81024",
    "county": "Las Animas County",
    "state": "CO",
    "city": "Boncarbo"
  },
  {
    "value": "81025",
    "label": "Boone - CO 81025",
    "county": "Pueblo County",
    "state": "CO",
    "city": "Boone"
  },
  {
    "value": "81027",
    "label": "Branson - CO 81027",
    "county": "Las Animas County",
    "state": "CO",
    "city": "Branson"
  },
  {
    "value": "81029",
    "label": "Campo - CO 81029",
    "county": "Baca County",
    "state": "CO",
    "city": "Campo"
  },
  {
    "value": "81036",
    "label": "Eads - CO 81036",
    "county": "Kiowa County",
    "state": "CO",
    "city": "Eads"
  },
  {
    "value": "81039",
    "label": "Fowler - CO 81039",
    "county": "Otero County",
    "state": "CO",
    "city": "Fowler"
  },
  {
    "value": "81040",
    "label": "Gardner - CO 81040",
    "county": "Huerfano County",
    "state": "CO",
    "city": "Gardner"
  },
  {
    "value": "81041",
    "label": "Granada - CO 81041",
    "county": "Prowers County",
    "state": "CO",
    "city": "Granada"
  },
  {
    "value": "81044",
    "label": "Hasty - CO 81044",
    "county": "Bent County",
    "state": "CO",
    "city": "Hasty"
  },
  {
    "value": "81045",
    "label": "Haswell - CO 81045",
    "county": "Kiowa County",
    "state": "CO",
    "city": "Haswell"
  },
  {
    "value": "81047",
    "label": "Holly - CO 81047",
    "county": "Prowers County",
    "state": "CO",
    "city": "Holly"
  },
  {
    "value": "81049",
    "label": "Kim - CO 81049",
    "county": "Las Animas County",
    "state": "CO",
    "city": "Kim"
  },
  {
    "value": "81050",
    "label": "La Junta - CO 81050",
    "county": "Otero County",
    "state": "CO",
    "city": "La Junta"
  },
  {
    "value": "81052",
    "label": "Lamar - CO 81052",
    "county": "Prowers County",
    "state": "CO",
    "city": "Lamar"
  },
  {
    "value": "81054",
    "label": "Las Animas - CO 81054",
    "county": "Bent County",
    "state": "CO",
    "city": "Las Animas"
  },
  {
    "value": "81055",
    "label": "La Veta - CO 81055",
    "county": "Huerfano County",
    "state": "CO",
    "city": "La Veta"
  },
  {
    "value": "81057",
    "label": "Mc Clave - CO 81057",
    "county": "Bent County",
    "state": "CO",
    "city": "Mc Clave"
  },
  {
    "value": "81058",
    "label": "Manzanola - CO 81058",
    "county": "Otero County",
    "state": "CO",
    "city": "Manzanola"
  },
  {
    "value": "81059",
    "label": "Model - CO 81059",
    "county": "Las Animas County",
    "state": "CO",
    "city": "Model"
  },
  {
    "value": "81062",
    "label": "Olney Springs - CO 81062",
    "county": "Crowley County",
    "state": "CO",
    "city": "Olney Springs"
  },
  {
    "value": "81063",
    "label": "Ordway - CO 81063",
    "county": "Crowley County",
    "state": "CO",
    "city": "Ordway"
  },
  {
    "value": "81064",
    "label": "Pritchett - CO 81064",
    "county": "Baca County",
    "state": "CO",
    "city": "Pritchett"
  },
  {
    "value": "81067",
    "label": "Rocky Ford - CO 81067",
    "county": "Otero County",
    "state": "CO",
    "city": "Rocky Ford"
  },
  {
    "value": "81069",
    "label": "Rye - CO 81069",
    "county": "Pueblo County",
    "state": "CO",
    "city": "Rye"
  },
  {
    "value": "81071",
    "label": "Sheridan Lake - CO 81071",
    "county": "Kiowa County",
    "state": "CO",
    "city": "Sheridan Lake"
  },
  {
    "value": "81073",
    "label": "Springfield - CO 81073",
    "county": "Baca County",
    "state": "CO",
    "city": "Springfield"
  },
  {
    "value": "81075",
    "label": "Stonington - CO 81075",
    "county": "",
    "state": "CO",
    "city": "Stonington"
  },
  {
    "value": "81076",
    "label": "Sugar City - CO 81076",
    "county": "Crowley County",
    "state": "CO",
    "city": "Sugar City"
  },
  {
    "value": "81081",
    "label": "Trinchera - CO 81081",
    "county": "Las Animas County",
    "state": "CO",
    "city": "Trinchera"
  },
  {
    "value": "81082",
    "label": "Trinidad - CO 81082",
    "county": "Las Animas County",
    "state": "CO",
    "city": "Trinidad"
  },
  {
    "value": "81084",
    "label": "Two Buttes - CO 81084",
    "county": "Baca County",
    "state": "CO",
    "city": "Two Buttes"
  },
  {
    "value": "81089",
    "label": "Walsenburg - CO 81089",
    "county": "Huerfano County",
    "state": "CO",
    "city": "Walsenburg"
  },
  {
    "value": "81090",
    "label": "Walsh - CO 81090",
    "county": "Baca County",
    "state": "CO",
    "city": "Walsh"
  },
  {
    "value": "81091",
    "label": "Weston - CO 81091",
    "county": "Las Animas County",
    "state": "CO",
    "city": "Weston"
  },
  {
    "value": "81092",
    "label": "Wiley - CO 81092",
    "county": "Prowers County",
    "state": "CO",
    "city": "Wiley"
  },
  {
    "value": "81101",
    "label": "Alamosa - CO 81101",
    "county": "Alamosa County",
    "state": "CO",
    "city": "Alamosa"
  },
  {
    "value": "81120",
    "label": "Antonito - CO 81120",
    "county": "Conejos County",
    "state": "CO",
    "city": "Antonito"
  },
  {
    "value": "81122",
    "label": "Bayfield - CO 81122",
    "county": "La Plata County",
    "state": "CO",
    "city": "Bayfield"
  },
  {
    "value": "81123",
    "label": "Blanca - CO 81123",
    "county": "Costilla County",
    "state": "CO",
    "city": "Blanca"
  },
  {
    "value": "81125",
    "label": "Center - CO 81125",
    "county": "Saguache County",
    "state": "CO",
    "city": "Center"
  },
  {
    "value": "81127",
    "label": "Chimney Rock - CO 81127",
    "county": "Archuleta County",
    "state": "CO",
    "city": "Chimney Rock"
  },
  {
    "value": "81130",
    "label": "Creede - CO 81130",
    "county": "Mineral County",
    "state": "CO",
    "city": "Creede"
  },
  {
    "value": "81132",
    "label": "Del Norte - CO 81132",
    "county": "Rio Grande County",
    "state": "CO",
    "city": "Del Norte"
  },
  {
    "value": "81133",
    "label": "Fort Garland - CO 81133",
    "county": "Costilla County",
    "state": "CO",
    "city": "Fort Garland"
  },
  {
    "value": "81134",
    "label": "San Luis - CO 81134",
    "county": "Costilla County",
    "state": "CO",
    "city": "San Luis"
  },
  {
    "value": "81136",
    "label": "Hooper - CO 81136",
    "county": "Alamosa County",
    "state": "CO",
    "city": "Hooper"
  },
  {
    "value": "81137",
    "label": "Ignacio - CO 81137",
    "county": "San Juan County",
    "state": "CO",
    "city": "Ignacio"
  },
  {
    "value": "81140",
    "label": "La Jara - CO 81140",
    "county": "Conejos County",
    "state": "CO",
    "city": "La Jara"
  },
  {
    "value": "81143",
    "label": "Moffat - CO 81143",
    "county": "Saguache County",
    "state": "CO",
    "city": "Moffat"
  },
  {
    "value": "81144",
    "label": "Monte Vista - CO 81144",
    "county": "Rio Grande County",
    "state": "CO",
    "city": "Monte Vista"
  },
  {
    "value": "81146",
    "label": "Mosca - CO 81146",
    "county": "Alamosa County",
    "state": "CO",
    "city": "Mosca"
  },
  {
    "value": "81147",
    "label": "Pagosa Springs - CO 81147",
    "county": "Archuleta County",
    "state": "CO",
    "city": "Pagosa Springs"
  },
  {
    "value": "81149",
    "label": "Saguache - CO 81149",
    "county": "Saguache County",
    "state": "CO",
    "city": "Saguache"
  },
  {
    "value": "81151",
    "label": "Sanford - CO 81151",
    "county": "Conejos County",
    "state": "CO",
    "city": "Sanford"
  },
  {
    "value": "81152",
    "label": "San Luis - CO 81152",
    "county": "Costilla County",
    "state": "CO",
    "city": "San Luis"
  },
  {
    "value": "81154",
    "label": "South Fork - CO 81154",
    "county": "Rio Grande County",
    "state": "CO",
    "city": "South Fork"
  },
  {
    "value": "81155",
    "label": "Villa Grove - CO 81155",
    "county": "Saguache County",
    "state": "CO",
    "city": "Villa Grove"
  },
  {
    "value": "81201",
    "label": "Salida - CO 81201",
    "county": "Chaffee County",
    "state": "CO",
    "city": "Salida"
  },
  {
    "value": "81210",
    "label": "Almont - CO 81210",
    "county": "Gunnison County",
    "state": "CO",
    "city": "Almont"
  },
  {
    "value": "81211",
    "label": "Buena Vista - CO 81211",
    "county": "Chaffee County",
    "state": "CO",
    "city": "Buena Vista"
  },
  {
    "value": "81212",
    "label": "Canon City - CO 81212",
    "county": "Fremont County",
    "state": "CO",
    "city": "Canon City"
  },
  {
    "value": "81220",
    "label": "Cimarron - CO 81220",
    "county": "Gunnison County",
    "state": "CO",
    "city": "Cimarron"
  },
  {
    "value": "81223",
    "label": "Cotopaxi - CO 81223",
    "county": "Fremont County",
    "state": "CO",
    "city": "Cotopaxi"
  },
  {
    "value": "81224",
    "label": "Crested Butte - CO 81224",
    "county": "Gunnison County",
    "state": "CO",
    "city": "Crested Butte"
  },
  {
    "value": "81226",
    "label": "Florence - CO 81226",
    "county": "Fremont County",
    "state": "CO",
    "city": "Florence"
  },
  {
    "value": "81228",
    "label": "Granite - CO 81228",
    "county": "Lake County",
    "state": "CO",
    "city": "Granite"
  },
  {
    "value": "81230",
    "label": "Gunnison - CO 81230",
    "county": "Gunnison County",
    "state": "CO",
    "city": "Gunnison"
  },
  {
    "value": "81233",
    "label": "Howard - CO 81233",
    "county": "Fremont County",
    "state": "CO",
    "city": "Howard"
  },
  {
    "value": "81235",
    "label": "Lake City - CO 81235",
    "county": "Hinsdale County",
    "state": "CO",
    "city": "Lake City"
  },
  {
    "value": "81236",
    "label": "Nathrop - CO 81236",
    "county": "Chaffee County",
    "state": "CO",
    "city": "Nathrop"
  },
  {
    "value": "81237",
    "label": "Ohio City - CO 81237",
    "county": "Gunnison County",
    "state": "CO",
    "city": "Ohio City"
  },
  {
    "value": "81239",
    "label": "Parlin - CO 81239",
    "county": "Gunnison County",
    "state": "CO",
    "city": "Parlin"
  },
  {
    "value": "81240",
    "label": "Penrose - CO 81240",
    "county": "Fremont County",
    "state": "CO",
    "city": "Penrose"
  },
  {
    "value": "81243",
    "label": "Powderhorn - CO 81243",
    "county": "Gunnison County",
    "state": "CO",
    "city": "Powderhorn"
  },
  {
    "value": "81247",
    "label": "Gunnison - CO 81247",
    "county": "Gunnison County",
    "state": "CO",
    "city": "Gunnison"
  },
  {
    "value": "81251",
    "label": "Twin Lakes - CO 81251",
    "county": "Lake County",
    "state": "CO",
    "city": "Twin Lakes"
  },
  {
    "value": "81252",
    "label": "Westcliffe - CO 81252",
    "county": "Custer County",
    "state": "CO",
    "city": "Westcliffe"
  },
  {
    "value": "81253",
    "label": "Wetmore - CO 81253",
    "county": "Custer County",
    "state": "CO",
    "city": "Wetmore"
  },
  {
    "value": "81301",
    "label": "Durango - CO 81301",
    "county": "La Plata County",
    "state": "CO",
    "city": "Durango"
  },
  {
    "value": "81303",
    "label": "Durango - CO 81303",
    "county": "La Plata County",
    "state": "CO",
    "city": "Durango"
  },
  {
    "value": "81320",
    "label": "Cahone - CO 81320",
    "county": "Dolores County",
    "state": "CO",
    "city": "Cahone"
  },
  {
    "value": "81321",
    "label": "Cortez - CO 81321",
    "county": "Montezuma County",
    "state": "CO",
    "city": "Cortez"
  },
  {
    "value": "81323",
    "label": "Dolores - CO 81323",
    "county": "Montezuma County",
    "state": "CO",
    "city": "Dolores"
  },
  {
    "value": "81324",
    "label": "Dove Creek - CO 81324",
    "county": "Dolores County",
    "state": "CO",
    "city": "Dove Creek"
  },
  {
    "value": "81325",
    "label": "Egnar - CO 81325",
    "county": "San Miguel County",
    "state": "CO",
    "city": "Egnar"
  },
  {
    "value": "81326",
    "label": "Hesperus - CO 81326",
    "county": "La Plata County",
    "state": "CO",
    "city": "Hesperus"
  },
  {
    "value": "81327",
    "label": "Lewis - CO 81327",
    "county": "Montezuma County",
    "state": "CO",
    "city": "Lewis"
  },
  {
    "value": "81328",
    "label": "Mancos - CO 81328",
    "county": "Montezuma County",
    "state": "CO",
    "city": "Mancos"
  },
  {
    "value": "81331",
    "label": "Pleasant View - CO 81331",
    "county": "Montezuma County",
    "state": "CO",
    "city": "Pleasant View"
  },
  {
    "value": "81335",
    "label": "Yellow Jacket - CO 81335",
    "county": "Montezuma County",
    "state": "CO",
    "city": "Yellow Jacket"
  },
  {
    "value": "81401",
    "label": "Montrose - CO 81401",
    "county": "Montrose County",
    "state": "CO",
    "city": "Montrose"
  },
  {
    "value": "81403",
    "label": "Montrose - CO 81403",
    "county": "Montrose County",
    "state": "CO",
    "city": "Montrose"
  },
  {
    "value": "81410",
    "label": "Austin - CO 81410",
    "county": "Delta County",
    "state": "CO",
    "city": "Austin"
  },
  {
    "value": "81411",
    "label": "Bedrock - CO 81411",
    "county": "Montrose County",
    "state": "CO",
    "city": "Bedrock"
  },
  {
    "value": "81413",
    "label": "Cedaredge - CO 81413",
    "county": "Delta County",
    "state": "CO",
    "city": "Cedaredge"
  },
  {
    "value": "81415",
    "label": "Crawford - CO 81415",
    "county": "Delta County",
    "state": "CO",
    "city": "Crawford"
  },
  {
    "value": "81416",
    "label": "Delta - CO 81416",
    "county": "Delta County",
    "state": "CO",
    "city": "Delta"
  },
  {
    "value": "81418",
    "label": "Eckert - CO 81418",
    "county": "Delta County",
    "state": "CO",
    "city": "Eckert"
  },
  {
    "value": "81419",
    "label": "Hotchkiss - CO 81419",
    "county": "Delta County",
    "state": "CO",
    "city": "Hotchkiss"
  },
  {
    "value": "81422",
    "label": "Naturita - CO 81422",
    "county": "Montrose County",
    "state": "CO",
    "city": "Naturita"
  },
  {
    "value": "81424",
    "label": "Nucla - CO 81424",
    "county": "Montrose County",
    "state": "CO",
    "city": "Nucla"
  },
  {
    "value": "81425",
    "label": "Olathe - CO 81425",
    "county": "Montrose County",
    "state": "CO",
    "city": "Olathe"
  },
  {
    "value": "81428",
    "label": "Paonia - CO 81428",
    "county": "Delta County",
    "state": "CO",
    "city": "Paonia"
  },
  {
    "value": "81431",
    "label": "Redvale - CO 81431",
    "county": "Montrose County",
    "state": "CO",
    "city": "Redvale"
  },
  {
    "value": "81432",
    "label": "Ridgway - CO 81432",
    "county": "Ouray County",
    "state": "CO",
    "city": "Ridgway"
  },
  {
    "value": "81434",
    "label": "Somerset - CO 81434",
    "county": "Gunnison County",
    "state": "CO",
    "city": "Somerset"
  },
  {
    "value": "81435",
    "label": "Telluride - CO 81435",
    "county": "San Miguel County",
    "state": "CO",
    "city": "Telluride"
  },
  {
    "value": "81501",
    "label": "Grand Junction - CO 81501",
    "county": "Mesa County",
    "state": "CO",
    "city": "Grand Junction"
  },
  {
    "value": "81503",
    "label": "Grand Junction - CO 81503",
    "county": "Mesa County",
    "state": "CO",
    "city": "Grand Junction"
  },
  {
    "value": "81504",
    "label": "Grand Junction - CO 81504",
    "county": "Mesa County",
    "state": "CO",
    "city": "Grand Junction"
  },
  {
    "value": "81505",
    "label": "Grand Junction - CO 81505",
    "county": "Mesa County",
    "state": "CO",
    "city": "Grand Junction"
  },
  {
    "value": "81506",
    "label": "Grand Junction - CO 81506",
    "county": "Mesa County",
    "state": "CO",
    "city": "Grand Junction"
  },
  {
    "value": "81507",
    "label": "Grand Junction - CO 81507",
    "county": "Mesa County",
    "state": "CO",
    "city": "Grand Junction"
  },
  {
    "value": "81520",
    "label": "Clifton - CO 81520",
    "county": "Mesa County",
    "state": "CO",
    "city": "Clifton"
  },
  {
    "value": "81521",
    "label": "Fruita - CO 81521",
    "county": "Mesa County",
    "state": "CO",
    "city": "Fruita"
  },
  {
    "value": "81522",
    "label": "Gateway - CO 81522",
    "county": "Mesa County",
    "state": "CO",
    "city": "Gateway"
  },
  {
    "value": "81523",
    "label": "Glade Park - CO 81523",
    "county": "Mesa County",
    "state": "CO",
    "city": "Glade Park"
  },
  {
    "value": "81524",
    "label": "Loma - CO 81524",
    "county": "Mesa County",
    "state": "CO",
    "city": "Loma"
  },
  {
    "value": "81525",
    "label": "Mack - CO 81525",
    "county": "Mesa County",
    "state": "CO",
    "city": "Mack"
  },
  {
    "value": "81526",
    "label": "Palisade - CO 81526",
    "county": "Mesa County",
    "state": "CO",
    "city": "Palisade"
  },
  {
    "value": "81527",
    "label": "Whitewater - CO 81527",
    "county": "Mesa County",
    "state": "CO",
    "city": "Whitewater"
  },
  {
    "value": "81601",
    "label": "Glenwood Springs - CO 81601",
    "county": "Garfield County",
    "state": "CO",
    "city": "Glenwood Springs"
  },
  {
    "value": "81610",
    "label": "Dinosaur - CO 81610",
    "county": "Moffat County",
    "state": "CO",
    "city": "Dinosaur"
  },
  {
    "value": "81611",
    "label": "Aspen - CO 81611",
    "county": "Pitkin County",
    "state": "CO",
    "city": "Aspen"
  },
  {
    "value": "81621",
    "label": "Basalt - CO 81621",
    "county": "Eagle County",
    "state": "CO",
    "city": "Basalt"
  },
  {
    "value": "81623",
    "label": "Carbondale - CO 81623",
    "county": "Garfield County",
    "state": "CO",
    "city": "Carbondale"
  },
  {
    "value": "81624",
    "label": "Collbran - CO 81624",
    "county": "Mesa County",
    "state": "CO",
    "city": "Collbran"
  },
  {
    "value": "81625",
    "label": "Craig - CO 81625",
    "county": "Moffat County",
    "state": "CO",
    "city": "Craig"
  },
  {
    "value": "81630",
    "label": "De Beque - CO 81630",
    "county": "Mesa County",
    "state": "CO",
    "city": "De Beque"
  },
  {
    "value": "81632",
    "label": "Edwards - CO 81632",
    "county": "Eagle County",
    "state": "CO",
    "city": "Edwards"
  },
  {
    "value": "81633",
    "label": "Dinosaur - CO 81633",
    "county": "Moffat County",
    "state": "CO",
    "city": "Dinosaur"
  },
  {
    "value": "81635",
    "label": "Parachute - CO 81635",
    "county": "Garfield County",
    "state": "CO",
    "city": "Parachute"
  },
  {
    "value": "81637",
    "label": "Gypsum - CO 81637",
    "county": "Eagle County",
    "state": "CO",
    "city": "Gypsum"
  },
  {
    "value": "81638",
    "label": "Hamilton - CO 81638",
    "county": "Moffat County",
    "state": "CO",
    "city": "Hamilton"
  },
  {
    "value": "81639",
    "label": "Hayden - CO 81639",
    "county": "Routt County",
    "state": "CO",
    "city": "Hayden"
  },
  {
    "value": "81640",
    "label": "Maybell - CO 81640",
    "county": "Moffat County",
    "state": "CO",
    "city": "Maybell"
  },
  {
    "value": "81641",
    "label": "Meeker - CO 81641",
    "county": "Rio Blanco County",
    "state": "CO",
    "city": "Meeker"
  },
  {
    "value": "81642",
    "label": "Meredith - CO 81642",
    "county": "Pitkin County",
    "state": "CO",
    "city": "Meredith"
  },
  {
    "value": "81647",
    "label": "New Castle - CO 81647",
    "county": "Garfield County",
    "state": "CO",
    "city": "New Castle"
  },
  {
    "value": "81648",
    "label": "Rangely - CO 81648",
    "county": "Rio Blanco County",
    "state": "CO",
    "city": "Rangely"
  },
  {
    "value": "81650",
    "label": "Rifle - CO 81650",
    "county": "Garfield County",
    "state": "CO",
    "city": "Rifle"
  },
  {
    "value": "81652",
    "label": "Silt - CO 81652",
    "county": "Garfield County",
    "state": "CO",
    "city": "Silt"
  },
  {
    "value": "81653",
    "label": "Slater - CO 81653",
    "county": "Moffat County",
    "state": "CO",
    "city": "Slater"
  },
  {
    "value": "81654",
    "label": "Snowmass - CO 81654",
    "county": "Pitkin County",
    "state": "CO",
    "city": "Snowmass"
  },
  {
    "value": "81657",
    "label": "Vail - CO 81657",
    "county": "Eagle County",
    "state": "CO",
    "city": "Vail"
  },
  {
    "value": "06001",
    "label": "Avon - CT 06001",
    "county": "Hartford County",
    "state": "CT",
    "city": "Avon"
  },
  {
    "value": "06002",
    "label": "Bloomfield - CT 06002",
    "county": "Hartford County",
    "state": "CT",
    "city": "Bloomfield"
  },
  {
    "value": "06010",
    "label": "Bristol - CT 06010",
    "county": "Hartford County",
    "state": "CT",
    "city": "Bristol"
  },
  {
    "value": "06013",
    "label": "Burlington - CT 06013",
    "county": "Hartford County",
    "state": "CT",
    "city": "Burlington"
  },
  {
    "value": "06016",
    "label": "Broad Brook - CT 06016",
    "county": "Hartford County",
    "state": "CT",
    "city": "Broad Brook"
  },
  {
    "value": "06018",
    "label": "Canaan - CT 06018",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Canaan"
  },
  {
    "value": "06019",
    "label": "Canton - CT 06019",
    "county": "Hartford County",
    "state": "CT",
    "city": "Canton"
  },
  {
    "value": "06021",
    "label": "Colebrook - CT 06021",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Colebrook"
  },
  {
    "value": "06023",
    "label": "East Berlin - CT 06023",
    "county": "Hartford County",
    "state": "CT",
    "city": "East Berlin"
  },
  {
    "value": "06024",
    "label": "East Canaan - CT 06024",
    "county": "Litchfield County",
    "state": "CT",
    "city": "East Canaan"
  },
  {
    "value": "06026",
    "label": "East Granby - CT 06026",
    "county": "Hartford County",
    "state": "CT",
    "city": "East Granby"
  },
  {
    "value": "06027",
    "label": "East Hartland - CT 06027",
    "county": "Hartford County",
    "state": "CT",
    "city": "East Hartland"
  },
  {
    "value": "06029",
    "label": "Ellington - CT 06029",
    "county": "Tolland County",
    "state": "CT",
    "city": "Ellington"
  },
  {
    "value": "06031",
    "label": "Falls Village - CT 06031",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Falls Village"
  },
  {
    "value": "06032",
    "label": "Farmington - CT 06032",
    "county": "Hartford County",
    "state": "CT",
    "city": "Farmington"
  },
  {
    "value": "06033",
    "label": "Glastonbury - CT 06033",
    "county": "Hartford County",
    "state": "CT",
    "city": "Glastonbury"
  },
  {
    "value": "06035",
    "label": "Granby - CT 06035",
    "county": "Hartford County",
    "state": "CT",
    "city": "Granby"
  },
  {
    "value": "06037",
    "label": "Berlin - CT 06037",
    "county": "Hartford County",
    "state": "CT",
    "city": "Berlin"
  },
  {
    "value": "06039",
    "label": "Lakeville - CT 06039",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Lakeville"
  },
  {
    "value": "06040",
    "label": "Manchester - CT 06040",
    "county": "Hartford County",
    "state": "CT",
    "city": "Manchester"
  },
  {
    "value": "06042",
    "label": "Manchester - CT 06042",
    "county": "Hartford County",
    "state": "CT",
    "city": "Manchester"
  },
  {
    "value": "06043",
    "label": "Bolton - CT 06043",
    "county": "Tolland County",
    "state": "CT",
    "city": "Bolton"
  },
  {
    "value": "06051",
    "label": "New Britain - CT 06051",
    "county": "Hartford County",
    "state": "CT",
    "city": "New Britain"
  },
  {
    "value": "06052",
    "label": "New Britain - CT 06052",
    "county": "Hartford County",
    "state": "CT",
    "city": "New Britain"
  },
  {
    "value": "06053",
    "label": "New Britain - CT 06053",
    "county": "Hartford County",
    "state": "CT",
    "city": "New Britain"
  },
  {
    "value": "06057",
    "label": "New Hartford - CT 06057",
    "county": "Litchfield County",
    "state": "CT",
    "city": "New Hartford"
  },
  {
    "value": "06058",
    "label": "Norfolk - CT 06058",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Norfolk"
  },
  {
    "value": "06060",
    "label": "North Granby - CT 06060",
    "county": "Hartford County",
    "state": "CT",
    "city": "North Granby"
  },
  {
    "value": "06062",
    "label": "Plainville - CT 06062",
    "county": "Hartford County",
    "state": "CT",
    "city": "Plainville"
  },
  {
    "value": "06063",
    "label": "Barkhamsted - CT 06063",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Barkhamsted"
  },
  {
    "value": "06065",
    "label": "Riverton - CT 06065",
    "county": "Hartford County",
    "state": "CT",
    "city": "Riverton"
  },
  {
    "value": "06066",
    "label": "Vernon Rockville - CT 06066",
    "county": "Tolland County",
    "state": "CT",
    "city": "Vernon Rockville"
  },
  {
    "value": "06067",
    "label": "Rocky Hill - CT 06067",
    "county": "Hartford County",
    "state": "CT",
    "city": "Rocky Hill"
  },
  {
    "value": "06068",
    "label": "Salisbury - CT 06068",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Salisbury"
  },
  {
    "value": "06069",
    "label": "Sharon - CT 06069",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Sharon"
  },
  {
    "value": "06070",
    "label": "Simsbury - CT 06070",
    "county": "Hartford County",
    "state": "CT",
    "city": "Simsbury"
  },
  {
    "value": "06071",
    "label": "Somers - CT 06071",
    "county": "Tolland County",
    "state": "CT",
    "city": "Somers"
  },
  {
    "value": "06073",
    "label": "South Glastonbury - CT 06073",
    "county": "Hartford County",
    "state": "CT",
    "city": "South Glastonbury"
  },
  {
    "value": "06074",
    "label": "South Windsor - CT 06074",
    "county": "Hartford County",
    "state": "CT",
    "city": "South Windsor"
  },
  {
    "value": "06076",
    "label": "Stafford Springs - CT 06076",
    "county": "Tolland County",
    "state": "CT",
    "city": "Stafford Springs"
  },
  {
    "value": "06078",
    "label": "Suffield - CT 06078",
    "county": "Hartford County",
    "state": "CT",
    "city": "Suffield"
  },
  {
    "value": "06081",
    "label": "Tariffville - CT 06081",
    "county": "Hartford County",
    "state": "CT",
    "city": "Tariffville"
  },
  {
    "value": "06082",
    "label": "Enfield - CT 06082",
    "county": "Hartford County",
    "state": "CT",
    "city": "Enfield"
  },
  {
    "value": "06084",
    "label": "Tolland - CT 06084",
    "county": "Tolland County",
    "state": "CT",
    "city": "Tolland"
  },
  {
    "value": "06085",
    "label": "Unionville - CT 06085",
    "county": "Hartford County",
    "state": "CT",
    "city": "Unionville"
  },
  {
    "value": "06088",
    "label": "East Windsor - CT 06088",
    "county": "Hartford County",
    "state": "CT",
    "city": "East Windsor"
  },
  {
    "value": "06089",
    "label": "Weatogue - CT 06089",
    "county": "Hartford County",
    "state": "CT",
    "city": "Weatogue"
  },
  {
    "value": "06090",
    "label": "West Granby - CT 06090",
    "county": "Hartford County",
    "state": "CT",
    "city": "West Granby"
  },
  {
    "value": "06091",
    "label": "West Hartland - CT 06091",
    "county": "Hartford County",
    "state": "CT",
    "city": "West Hartland"
  },
  {
    "value": "06092",
    "label": "West Simsbury - CT 06092",
    "county": "Hartford County",
    "state": "CT",
    "city": "West Simsbury"
  },
  {
    "value": "06093",
    "label": "West Suffield - CT 06093",
    "county": "Hartford County",
    "state": "CT",
    "city": "West Suffield"
  },
  {
    "value": "06095",
    "label": "Windsor - CT 06095",
    "county": "Hartford County",
    "state": "CT",
    "city": "Windsor"
  },
  {
    "value": "06096",
    "label": "Windsor Locks - CT 06096",
    "county": "Hartford County",
    "state": "CT",
    "city": "Windsor Locks"
  },
  {
    "value": "06098",
    "label": "Winsted - CT 06098",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Winsted"
  },
  {
    "value": "06101",
    "label": "Hartford - CT 06101",
    "county": "Hartford County",
    "state": "CT",
    "city": "Hartford"
  },
  {
    "value": "06103",
    "label": "Hartford - CT 06103",
    "county": "Hartford County",
    "state": "CT",
    "city": "Hartford"
  },
  {
    "value": "06105",
    "label": "Hartford - CT 06105",
    "county": "Hartford County",
    "state": "CT",
    "city": "Hartford"
  },
  {
    "value": "06106",
    "label": "Hartford - CT 06106",
    "county": "Hartford County",
    "state": "CT",
    "city": "Hartford"
  },
  {
    "value": "06107",
    "label": "West Hartford - CT 06107",
    "county": "Hartford County",
    "state": "CT",
    "city": "West Hartford"
  },
  {
    "value": "06108",
    "label": "East Hartford - CT 06108",
    "county": "Hartford County",
    "state": "CT",
    "city": "East Hartford"
  },
  {
    "value": "06109",
    "label": "Wethersfield - CT 06109",
    "county": "Hartford County",
    "state": "CT",
    "city": "Wethersfield"
  },
  {
    "value": "06110",
    "label": "West Hartford - CT 06110",
    "county": "Hartford County",
    "state": "CT",
    "city": "West Hartford"
  },
  {
    "value": "06111",
    "label": "Newington - CT 06111",
    "county": "Hartford County",
    "state": "CT",
    "city": "Newington"
  },
  {
    "value": "06112",
    "label": "Hartford - CT 06112",
    "county": "Hartford County",
    "state": "CT",
    "city": "Hartford"
  },
  {
    "value": "06114",
    "label": "Hartford - CT 06114",
    "county": "Hartford County",
    "state": "CT",
    "city": "Hartford"
  },
  {
    "value": "06117",
    "label": "West Hartford - CT 06117",
    "county": "Hartford County",
    "state": "CT",
    "city": "West Hartford"
  },
  {
    "value": "06118",
    "label": "East Hartford - CT 06118",
    "county": "Hartford County",
    "state": "CT",
    "city": "East Hartford"
  },
  {
    "value": "06119",
    "label": "West Hartford - CT 06119",
    "county": "Hartford County",
    "state": "CT",
    "city": "West Hartford"
  },
  {
    "value": "06120",
    "label": "Hartford - CT 06120",
    "county": "Hartford County",
    "state": "CT",
    "city": "Hartford"
  },
  {
    "value": "06152",
    "label": "Hartford - CT 06152",
    "county": "Hartford County",
    "state": "CT",
    "city": "Hartford"
  },
  {
    "value": "06226",
    "label": "Willimantic - CT 06226",
    "county": "Windham County",
    "state": "CT",
    "city": "Willimantic"
  },
  {
    "value": "06231",
    "label": "Amston - CT 06231",
    "county": "Tolland County",
    "state": "CT",
    "city": "Amston"
  },
  {
    "value": "06232",
    "label": "Andover - CT 06232",
    "county": "Tolland County",
    "state": "CT",
    "city": "Andover"
  },
  {
    "value": "06234",
    "label": "Brooklyn - CT 06234",
    "county": "Windham County",
    "state": "CT",
    "city": "Brooklyn"
  },
  {
    "value": "06235",
    "label": "Chaplin - CT 06235",
    "county": "Windham County",
    "state": "CT",
    "city": "Chaplin"
  },
  {
    "value": "06237",
    "label": "Columbia - CT 06237",
    "county": "Tolland County",
    "state": "CT",
    "city": "Columbia"
  },
  {
    "value": "06238",
    "label": "Coventry - CT 06238",
    "county": "Tolland County",
    "state": "CT",
    "city": "Coventry"
  },
  {
    "value": "06239",
    "label": "Danielson - CT 06239",
    "county": "Windham County",
    "state": "CT",
    "city": "Danielson"
  },
  {
    "value": "06241",
    "label": "Dayville - CT 06241",
    "county": "Windham County",
    "state": "CT",
    "city": "Dayville"
  },
  {
    "value": "06242",
    "label": "Eastford - CT 06242",
    "county": "Windham County",
    "state": "CT",
    "city": "Eastford"
  },
  {
    "value": "06243",
    "label": "East Killingly - CT 06243",
    "county": "Windham County",
    "state": "CT",
    "city": "East Killingly"
  },
  {
    "value": "06247",
    "label": "Hampton - CT 06247",
    "county": "Windham County",
    "state": "CT",
    "city": "Hampton"
  },
  {
    "value": "06248",
    "label": "Hebron - CT 06248",
    "county": "Tolland County",
    "state": "CT",
    "city": "Hebron"
  },
  {
    "value": "06249",
    "label": "Lebanon - CT 06249",
    "county": "New London County",
    "state": "CT",
    "city": "Lebanon"
  },
  {
    "value": "06250",
    "label": "Mansfield Center - CT 06250",
    "county": "Tolland County",
    "state": "CT",
    "city": "Mansfield Center"
  },
  {
    "value": "06254",
    "label": "North Franklin - CT 06254",
    "county": "New London County",
    "state": "CT",
    "city": "North Franklin"
  },
  {
    "value": "06255",
    "label": "North Grosvenordale - CT 06255",
    "county": "Windham County",
    "state": "CT",
    "city": "North Grosvenordale"
  },
  {
    "value": "06256",
    "label": "North Windham - CT 06256",
    "county": "Windham County",
    "state": "CT",
    "city": "North Windham"
  },
  {
    "value": "06259",
    "label": "Pomfret Center - CT 06259",
    "county": "Windham County",
    "state": "CT",
    "city": "Pomfret Center"
  },
  {
    "value": "06260",
    "label": "Putnam - CT 06260",
    "county": "Windham County",
    "state": "CT",
    "city": "Putnam"
  },
  {
    "value": "06262",
    "label": "Quinebaug - CT 06262",
    "county": "Windham County",
    "state": "CT",
    "city": "Quinebaug"
  },
  {
    "value": "06264",
    "label": "Scotland - CT 06264",
    "county": "Windham County",
    "state": "CT",
    "city": "Scotland"
  },
  {
    "value": "06265",
    "label": "South Willington - CT 06265",
    "county": "Tolland County",
    "state": "CT",
    "city": "South Willington"
  },
  {
    "value": "06266",
    "label": "South Windham - CT 06266",
    "county": "Windham County",
    "state": "CT",
    "city": "South Windham"
  },
  {
    "value": "06268",
    "label": "Storrs Mansfield - CT 06268",
    "county": "Tolland County",
    "state": "CT",
    "city": "Storrs Mansfield"
  },
  {
    "value": "06277",
    "label": "Thompson - CT 06277",
    "county": "Windham County",
    "state": "CT",
    "city": "Thompson"
  },
  {
    "value": "06278",
    "label": "Ashford - CT 06278",
    "county": "Windham County",
    "state": "CT",
    "city": "Ashford"
  },
  {
    "value": "06279",
    "label": "Willington - CT 06279",
    "county": "Tolland County",
    "state": "CT",
    "city": "Willington"
  },
  {
    "value": "06280",
    "label": "Windham - CT 06280",
    "county": "Windham County",
    "state": "CT",
    "city": "Windham"
  },
  {
    "value": "06281",
    "label": "Woodstock - CT 06281",
    "county": "Windham County",
    "state": "CT",
    "city": "Woodstock"
  },
  {
    "value": "06282",
    "label": "Woodstock Valley - CT 06282",
    "county": "Windham County",
    "state": "CT",
    "city": "Woodstock Valley"
  },
  {
    "value": "06320",
    "label": "New London - CT 06320",
    "county": "New London County",
    "state": "CT",
    "city": "New London"
  },
  {
    "value": "06330",
    "label": "Baltic - CT 06330",
    "county": "New London County",
    "state": "CT",
    "city": "Baltic"
  },
  {
    "value": "06331",
    "label": "Canterbury - CT 06331",
    "county": "Windham County",
    "state": "CT",
    "city": "Canterbury"
  },
  {
    "value": "06333",
    "label": "East Lyme - CT 06333",
    "county": "New London County",
    "state": "CT",
    "city": "East Lyme"
  },
  {
    "value": "06334",
    "label": "Bozrah - CT 06334",
    "county": "New London County",
    "state": "CT",
    "city": "Bozrah"
  },
  {
    "value": "06335",
    "label": "Gales Ferry - CT 06335",
    "county": "New London County",
    "state": "CT",
    "city": "Gales Ferry"
  },
  {
    "value": "06336",
    "label": "Gilman - CT 06336",
    "county": "New London County",
    "state": "CT",
    "city": "Gilman"
  },
  {
    "value": "06339",
    "label": "Ledyard - CT 06339",
    "county": "New London County",
    "state": "CT",
    "city": "Ledyard"
  },
  {
    "value": "06340",
    "label": "Groton - CT 06340",
    "county": "New London County",
    "state": "CT",
    "city": "Groton"
  },
  {
    "value": "06351",
    "label": "Jewett City - CT 06351",
    "county": "New London County",
    "state": "CT",
    "city": "Jewett City"
  },
  {
    "value": "06353",
    "label": "Montville - CT 06353",
    "county": "New London County",
    "state": "CT",
    "city": "Montville"
  },
  {
    "value": "06354",
    "label": "Moosup - CT 06354",
    "county": "Windham County",
    "state": "CT",
    "city": "Moosup"
  },
  {
    "value": "06355",
    "label": "Mystic - CT 06355",
    "county": "New London County",
    "state": "CT",
    "city": "Mystic"
  },
  {
    "value": "06357",
    "label": "Niantic - CT 06357",
    "county": "New London County",
    "state": "CT",
    "city": "Niantic"
  },
  {
    "value": "06359",
    "label": "North Stonington - CT 06359",
    "county": "New London County",
    "state": "CT",
    "city": "North Stonington"
  },
  {
    "value": "06360",
    "label": "Norwich - CT 06360",
    "county": "New London County",
    "state": "CT",
    "city": "Norwich"
  },
  {
    "value": "06365",
    "label": "Preston - CT 06365",
    "county": "New London County",
    "state": "CT",
    "city": "Preston"
  },
  {
    "value": "06370",
    "label": "Oakdale - CT 06370",
    "county": "New London County",
    "state": "CT",
    "city": "Oakdale"
  },
  {
    "value": "06371",
    "label": "Old Lyme - CT 06371",
    "county": "New London County",
    "state": "CT",
    "city": "Old Lyme"
  },
  {
    "value": "06374",
    "label": "Plainfield - CT 06374",
    "county": "Windham County",
    "state": "CT",
    "city": "Plainfield"
  },
  {
    "value": "06375",
    "label": "Quaker Hill - CT 06375",
    "county": "New London County",
    "state": "CT",
    "city": "Quaker Hill"
  },
  {
    "value": "06377",
    "label": "Sterling - CT 06377",
    "county": "Windham County",
    "state": "CT",
    "city": "Sterling"
  },
  {
    "value": "06378",
    "label": "Stonington - CT 06378",
    "county": "New London County",
    "state": "CT",
    "city": "Stonington"
  },
  {
    "value": "06379",
    "label": "Pawcatuck - CT 06379",
    "county": "New London County",
    "state": "CT",
    "city": "Pawcatuck"
  },
  {
    "value": "06380",
    "label": "Taftville - CT 06380",
    "county": "New London County",
    "state": "CT",
    "city": "Taftville"
  },
  {
    "value": "06382",
    "label": "Uncasville - CT 06382",
    "county": "New London County",
    "state": "CT",
    "city": "Uncasville"
  },
  {
    "value": "06384",
    "label": "Voluntown - CT 06384",
    "county": "New London County",
    "state": "CT",
    "city": "Voluntown"
  },
  {
    "value": "06385",
    "label": "Waterford - CT 06385",
    "county": "New London County",
    "state": "CT",
    "city": "Waterford"
  },
  {
    "value": "06389",
    "label": "Yantic - CT 06389",
    "county": "New London County",
    "state": "CT",
    "city": "Yantic"
  },
  {
    "value": "06401",
    "label": "Ansonia - CT 06401",
    "county": "New Haven County",
    "state": "CT",
    "city": "Ansonia"
  },
  {
    "value": "06403",
    "label": "Beacon Falls - CT 06403",
    "county": "New Haven County",
    "state": "CT",
    "city": "Beacon Falls"
  },
  {
    "value": "06405",
    "label": "Branford - CT 06405",
    "county": "New Haven County",
    "state": "CT",
    "city": "Branford"
  },
  {
    "value": "06409",
    "label": "Centerbrook - CT 06409",
    "county": "Middlesex County",
    "state": "CT",
    "city": "Centerbrook"
  },
  {
    "value": "06410",
    "label": "Cheshire - CT 06410",
    "county": "New Haven County",
    "state": "CT",
    "city": "Cheshire"
  },
  {
    "value": "06412",
    "label": "Chester - CT 06412",
    "county": "Middlesex County",
    "state": "CT",
    "city": "Chester"
  },
  {
    "value": "06413",
    "label": "Clinton - CT 06413",
    "county": "Middlesex County",
    "state": "CT",
    "city": "Clinton"
  },
  {
    "value": "06415",
    "label": "Colchester - CT 06415",
    "county": "New London County",
    "state": "CT",
    "city": "Colchester"
  },
  {
    "value": "06416",
    "label": "Cromwell - CT 06416",
    "county": "Middlesex County",
    "state": "CT",
    "city": "Cromwell"
  },
  {
    "value": "06417",
    "label": "Deep River - CT 06417",
    "county": "Middlesex County",
    "state": "CT",
    "city": "Deep River"
  },
  {
    "value": "06418",
    "label": "Derby - CT 06418",
    "county": "New Haven County",
    "state": "CT",
    "city": "Derby"
  },
  {
    "value": "06419",
    "label": "Killingworth - CT 06419",
    "county": "Middlesex County",
    "state": "CT",
    "city": "Killingworth"
  },
  {
    "value": "06420",
    "label": "Salem - CT 06420",
    "county": "New London County",
    "state": "CT",
    "city": "Salem"
  },
  {
    "value": "06422",
    "label": "Durham - CT 06422",
    "county": "Middlesex County",
    "state": "CT",
    "city": "Durham"
  },
  {
    "value": "06423",
    "label": "East Haddam - CT 06423",
    "county": "Middlesex County",
    "state": "CT",
    "city": "East Haddam"
  },
  {
    "value": "06424",
    "label": "East Hampton - CT 06424",
    "county": "Middlesex County",
    "state": "CT",
    "city": "East Hampton"
  },
  {
    "value": "06426",
    "label": "Essex - CT 06426",
    "county": "Middlesex County",
    "state": "CT",
    "city": "Essex"
  },
  {
    "value": "06437",
    "label": "Guilford - CT 06437",
    "county": "New Haven County",
    "state": "CT",
    "city": "Guilford"
  },
  {
    "value": "06438",
    "label": "Haddam - CT 06438",
    "county": "Middlesex County",
    "state": "CT",
    "city": "Haddam"
  },
  {
    "value": "06441",
    "label": "Higganum - CT 06441",
    "county": "Middlesex County",
    "state": "CT",
    "city": "Higganum"
  },
  {
    "value": "06442",
    "label": "Ivoryton - CT 06442",
    "county": "Middlesex County",
    "state": "CT",
    "city": "Ivoryton"
  },
  {
    "value": "06443",
    "label": "Madison - CT 06443",
    "county": "New Haven County",
    "state": "CT",
    "city": "Madison"
  },
  {
    "value": "06447",
    "label": "Marlborough - CT 06447",
    "county": "Hartford County",
    "state": "CT",
    "city": "Marlborough"
  },
  {
    "value": "06450",
    "label": "Meriden - CT 06450",
    "county": "New Haven County",
    "state": "CT",
    "city": "Meriden"
  },
  {
    "value": "06451",
    "label": "Meriden - CT 06451",
    "county": "New Haven County",
    "state": "CT",
    "city": "Meriden"
  },
  {
    "value": "06455",
    "label": "Middlefield - CT 06455",
    "county": "Middlesex County",
    "state": "CT",
    "city": "Middlefield"
  },
  {
    "value": "06457",
    "label": "Middletown - CT 06457",
    "county": "Middlesex County",
    "state": "CT",
    "city": "Middletown"
  },
  {
    "value": "06460",
    "label": "Milford - CT 06460",
    "county": "New Haven County",
    "state": "CT",
    "city": "Milford"
  },
  {
    "value": "06461",
    "label": "Milford - CT 06461",
    "county": "New Haven County",
    "state": "CT",
    "city": "Milford"
  },
  {
    "value": "06468",
    "label": "Monroe - CT 06468",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Monroe"
  },
  {
    "value": "06469",
    "label": "Moodus - CT 06469",
    "county": "Middlesex County",
    "state": "CT",
    "city": "Moodus"
  },
  {
    "value": "06470",
    "label": "Newtown - CT 06470",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Newtown"
  },
  {
    "value": "06471",
    "label": "North Branford - CT 06471",
    "county": "New Haven County",
    "state": "CT",
    "city": "North Branford"
  },
  {
    "value": "06472",
    "label": "Northford - CT 06472",
    "county": "New Haven County",
    "state": "CT",
    "city": "Northford"
  },
  {
    "value": "06473",
    "label": "North Haven - CT 06473",
    "county": "New Haven County",
    "state": "CT",
    "city": "North Haven"
  },
  {
    "value": "06475",
    "label": "Old Saybrook - CT 06475",
    "county": "Middlesex County",
    "state": "CT",
    "city": "Old Saybrook"
  },
  {
    "value": "06477",
    "label": "Orange - CT 06477",
    "county": "New Haven County",
    "state": "CT",
    "city": "Orange"
  },
  {
    "value": "06478",
    "label": "Oxford - CT 06478",
    "county": "New Haven County",
    "state": "CT",
    "city": "Oxford"
  },
  {
    "value": "06479",
    "label": "Plantsville - CT 06479",
    "county": "Hartford County",
    "state": "CT",
    "city": "Plantsville"
  },
  {
    "value": "06480",
    "label": "Portland - CT 06480",
    "county": "Middlesex County",
    "state": "CT",
    "city": "Portland"
  },
  {
    "value": "06481",
    "label": "Rockfall - CT 06481",
    "county": "Middlesex County",
    "state": "CT",
    "city": "Rockfall"
  },
  {
    "value": "06482",
    "label": "Sandy Hook - CT 06482",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Sandy Hook"
  },
  {
    "value": "06483",
    "label": "Seymour - CT 06483",
    "county": "New Haven County",
    "state": "CT",
    "city": "Seymour"
  },
  {
    "value": "06484",
    "label": "Shelton - CT 06484",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Shelton"
  },
  {
    "value": "06488",
    "label": "Southbury - CT 06488",
    "county": "New Haven County",
    "state": "CT",
    "city": "Southbury"
  },
  {
    "value": "06489",
    "label": "Southington - CT 06489",
    "county": "Hartford County",
    "state": "CT",
    "city": "Southington"
  },
  {
    "value": "06492",
    "label": "Wallingford - CT 06492",
    "county": "New Haven County",
    "state": "CT",
    "city": "Wallingford"
  },
  {
    "value": "06497",
    "label": "Stratford - CT 06497",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Stratford"
  },
  {
    "value": "06498",
    "label": "Westbrook - CT 06498",
    "county": "Middlesex County",
    "state": "CT",
    "city": "Westbrook"
  },
  {
    "value": "06510",
    "label": "New Haven - CT 06510",
    "county": "New Haven County",
    "state": "CT",
    "city": "New Haven"
  },
  {
    "value": "06511",
    "label": "New Haven - CT 06511",
    "county": "New Haven County",
    "state": "CT",
    "city": "New Haven"
  },
  {
    "value": "06512",
    "label": "East Haven - CT 06512",
    "county": "New Haven County",
    "state": "CT",
    "city": "East Haven"
  },
  {
    "value": "06513",
    "label": "New Haven - CT 06513",
    "county": "New Haven County",
    "state": "CT",
    "city": "New Haven"
  },
  {
    "value": "06514",
    "label": "Hamden - CT 06514",
    "county": "New Haven County",
    "state": "CT",
    "city": "Hamden"
  },
  {
    "value": "06515",
    "label": "New Haven - CT 06515",
    "county": "New Haven County",
    "state": "CT",
    "city": "New Haven"
  },
  {
    "value": "06516",
    "label": "West Haven - CT 06516",
    "county": "New Haven County",
    "state": "CT",
    "city": "West Haven"
  },
  {
    "value": "06517",
    "label": "Hamden - CT 06517",
    "county": "New Haven County",
    "state": "CT",
    "city": "Hamden"
  },
  {
    "value": "06518",
    "label": "Hamden - CT 06518",
    "county": "New Haven County",
    "state": "CT",
    "city": "Hamden"
  },
  {
    "value": "06519",
    "label": "New Haven - CT 06519",
    "county": "New Haven County",
    "state": "CT",
    "city": "New Haven"
  },
  {
    "value": "06524",
    "label": "Bethany - CT 06524",
    "county": "New Haven County",
    "state": "CT",
    "city": "Bethany"
  },
  {
    "value": "06525",
    "label": "Woodbridge - CT 06525",
    "county": "New Haven County",
    "state": "CT",
    "city": "Woodbridge"
  },
  {
    "value": "06604",
    "label": "Bridgeport - CT 06604",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Bridgeport"
  },
  {
    "value": "06605",
    "label": "Bridgeport - CT 06605",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Bridgeport"
  },
  {
    "value": "06606",
    "label": "Bridgeport - CT 06606",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Bridgeport"
  },
  {
    "value": "06607",
    "label": "Bridgeport - CT 06607",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Bridgeport"
  },
  {
    "value": "06608",
    "label": "Bridgeport - CT 06608",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Bridgeport"
  },
  {
    "value": "06610",
    "label": "Bridgeport - CT 06610",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Bridgeport"
  },
  {
    "value": "06611",
    "label": "Trumbull - CT 06611",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Trumbull"
  },
  {
    "value": "06612",
    "label": "Easton - CT 06612",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Easton"
  },
  {
    "value": "06614",
    "label": "Stratford - CT 06614",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Stratford"
  },
  {
    "value": "06615",
    "label": "Stratford - CT 06615",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Stratford"
  },
  {
    "value": "06650",
    "label": "Bridgeport - CT 06650",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Bridgeport"
  },
  {
    "value": "06701",
    "label": "Waterbury - CT 06701",
    "county": "New Haven County",
    "state": "CT",
    "city": "Waterbury"
  },
  {
    "value": "06702",
    "label": "Waterbury - CT 06702",
    "county": "New Haven County",
    "state": "CT",
    "city": "Waterbury"
  },
  {
    "value": "06704",
    "label": "Waterbury - CT 06704",
    "county": "New Haven County",
    "state": "CT",
    "city": "Waterbury"
  },
  {
    "value": "06705",
    "label": "Waterbury - CT 06705",
    "county": "New Haven County",
    "state": "CT",
    "city": "Waterbury"
  },
  {
    "value": "06706",
    "label": "Waterbury - CT 06706",
    "county": "New Haven County",
    "state": "CT",
    "city": "Waterbury"
  },
  {
    "value": "06708",
    "label": "Waterbury - CT 06708",
    "county": "New Haven County",
    "state": "CT",
    "city": "Waterbury"
  },
  {
    "value": "06710",
    "label": "Waterbury - CT 06710",
    "county": "New Haven County",
    "state": "CT",
    "city": "Waterbury"
  },
  {
    "value": "06712",
    "label": "Prospect - CT 06712",
    "county": "New Haven County",
    "state": "CT",
    "city": "Prospect"
  },
  {
    "value": "06716",
    "label": "Wolcott - CT 06716",
    "county": "New Haven County",
    "state": "CT",
    "city": "Wolcott"
  },
  {
    "value": "06750",
    "label": "Bantam - CT 06750",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Bantam"
  },
  {
    "value": "06751",
    "label": "Bethlehem - CT 06751",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Bethlehem"
  },
  {
    "value": "06752",
    "label": "Bridgewater - CT 06752",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Bridgewater"
  },
  {
    "value": "06754",
    "label": "Cornwall Bridge - CT 06754",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Cornwall Bridge"
  },
  {
    "value": "06755",
    "label": "Gaylordsville - CT 06755",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Gaylordsville"
  },
  {
    "value": "06756",
    "label": "Goshen - CT 06756",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Goshen"
  },
  {
    "value": "06757",
    "label": "Kent - CT 06757",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Kent"
  },
  {
    "value": "06758",
    "label": "Lakeside - CT 06758",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Lakeside"
  },
  {
    "value": "06759",
    "label": "Litchfield - CT 06759",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Litchfield"
  },
  {
    "value": "06762",
    "label": "Middlebury - CT 06762",
    "county": "New Haven County",
    "state": "CT",
    "city": "Middlebury"
  },
  {
    "value": "06763",
    "label": "Morris - CT 06763",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Morris"
  },
  {
    "value": "06770",
    "label": "Naugatuck - CT 06770",
    "county": "New Haven County",
    "state": "CT",
    "city": "Naugatuck"
  },
  {
    "value": "06776",
    "label": "New Milford - CT 06776",
    "county": "Litchfield County",
    "state": "CT",
    "city": "New Milford"
  },
  {
    "value": "06777",
    "label": "New Preston Marble Dale - CT 06777",
    "county": "Litchfield County",
    "state": "CT",
    "city": "New Preston Marble Dale"
  },
  {
    "value": "06778",
    "label": "Northfield - CT 06778",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Northfield"
  },
  {
    "value": "06779",
    "label": "Oakville - CT 06779",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Oakville"
  },
  {
    "value": "06782",
    "label": "Plymouth - CT 06782",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Plymouth"
  },
  {
    "value": "06783",
    "label": "Roxbury - CT 06783",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Roxbury"
  },
  {
    "value": "06784",
    "label": "Sherman - CT 06784",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Sherman"
  },
  {
    "value": "06785",
    "label": "South Kent - CT 06785",
    "county": "Litchfield County",
    "state": "CT",
    "city": "South Kent"
  },
  {
    "value": "06786",
    "label": "Terryville - CT 06786",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Terryville"
  },
  {
    "value": "06787",
    "label": "Thomaston - CT 06787",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Thomaston"
  },
  {
    "value": "06790",
    "label": "Torrington - CT 06790",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Torrington"
  },
  {
    "value": "06791",
    "label": "Harwinton - CT 06791",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Harwinton"
  },
  {
    "value": "06793",
    "label": "Washington - CT 06793",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Washington"
  },
  {
    "value": "06794",
    "label": "Washington Depot - CT 06794",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Washington Depot"
  },
  {
    "value": "06795",
    "label": "Watertown - CT 06795",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Watertown"
  },
  {
    "value": "06796",
    "label": "West Cornwall - CT 06796",
    "county": "Litchfield County",
    "state": "CT",
    "city": "West Cornwall"
  },
  {
    "value": "06798",
    "label": "Woodbury - CT 06798",
    "county": "Litchfield County",
    "state": "CT",
    "city": "Woodbury"
  },
  {
    "value": "06801",
    "label": "Bethel - CT 06801",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Bethel"
  },
  {
    "value": "06804",
    "label": "Brookfield - CT 06804",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Brookfield"
  },
  {
    "value": "06807",
    "label": "Cos Cob - CT 06807",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Cos Cob"
  },
  {
    "value": "06810",
    "label": "Danbury - CT 06810",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Danbury"
  },
  {
    "value": "06811",
    "label": "Danbury - CT 06811",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Danbury"
  },
  {
    "value": "06812",
    "label": "New Fairfield - CT 06812",
    "county": "Fairfield County",
    "state": "CT",
    "city": "New Fairfield"
  },
  {
    "value": "06820",
    "label": "Darien - CT 06820",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Darien"
  },
  {
    "value": "06824",
    "label": "Fairfield - CT 06824",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Fairfield"
  },
  {
    "value": "06825",
    "label": "Fairfield - CT 06825",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Fairfield"
  },
  {
    "value": "06828",
    "label": "Fairfield - CT 06828",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Fairfield"
  },
  {
    "value": "06830",
    "label": "Greenwich - CT 06830",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Greenwich"
  },
  {
    "value": "06831",
    "label": "Greenwich - CT 06831",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Greenwich"
  },
  {
    "value": "06840",
    "label": "New Canaan - CT 06840",
    "county": "Fairfield County",
    "state": "CT",
    "city": "New Canaan"
  },
  {
    "value": "06850",
    "label": "Norwalk - CT 06850",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Norwalk"
  },
  {
    "value": "06851",
    "label": "Norwalk - CT 06851",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Norwalk"
  },
  {
    "value": "06853",
    "label": "Norwalk - CT 06853",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Norwalk"
  },
  {
    "value": "06854",
    "label": "Norwalk - CT 06854",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Norwalk"
  },
  {
    "value": "06855",
    "label": "Norwalk - CT 06855",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Norwalk"
  },
  {
    "value": "06870",
    "label": "Old Greenwich - CT 06870",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Old Greenwich"
  },
  {
    "value": "06877",
    "label": "Ridgefield - CT 06877",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Ridgefield"
  },
  {
    "value": "06878",
    "label": "Riverside - CT 06878",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Riverside"
  },
  {
    "value": "06880",
    "label": "Westport - CT 06880",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Westport"
  },
  {
    "value": "06883",
    "label": "Weston - CT 06883",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Weston"
  },
  {
    "value": "06890",
    "label": "Southport - CT 06890",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Southport"
  },
  {
    "value": "06896",
    "label": "Redding - CT 06896",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Redding"
  },
  {
    "value": "06897",
    "label": "Wilton - CT 06897",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Wilton"
  },
  {
    "value": "06901",
    "label": "Stamford - CT 06901",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Stamford"
  },
  {
    "value": "06902",
    "label": "Stamford - CT 06902",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Stamford"
  },
  {
    "value": "06903",
    "label": "Stamford - CT 06903",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Stamford"
  },
  {
    "value": "06905",
    "label": "Stamford - CT 06905",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Stamford"
  },
  {
    "value": "06906",
    "label": "Stamford - CT 06906",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Stamford"
  },
  {
    "value": "06907",
    "label": "Stamford - CT 06907",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Stamford"
  },
  {
    "value": "06910",
    "label": "Stamford - CT 06910",
    "county": "Fairfield County",
    "state": "CT",
    "city": "Stamford"
  },
  {
    "value": "19701",
    "label": "Bear - DE 19701",
    "county": "New Castle County",
    "state": "DE",
    "city": "Bear"
  },
  {
    "value": "19702",
    "label": "Newark - DE 19702",
    "county": "New Castle County",
    "state": "DE",
    "city": "Newark"
  },
  {
    "value": "19703",
    "label": "Claymont - DE 19703",
    "county": "New Castle County",
    "state": "DE",
    "city": "Claymont"
  },
  {
    "value": "19707",
    "label": "Hockessin - DE 19707",
    "county": "New Castle County",
    "state": "DE",
    "city": "Hockessin"
  },
  {
    "value": "19709",
    "label": "Middletown - DE 19709",
    "county": "New Castle County",
    "state": "DE",
    "city": "Middletown"
  },
  {
    "value": "19711",
    "label": "Newark - DE 19711",
    "county": "New Castle County",
    "state": "DE",
    "city": "Newark"
  },
  {
    "value": "19713",
    "label": "Newark - DE 19713",
    "county": "New Castle County",
    "state": "DE",
    "city": "Newark"
  },
  {
    "value": "19716",
    "label": "Newark - DE 19716",
    "county": "New Castle County",
    "state": "DE",
    "city": "Newark"
  },
  {
    "value": "19717",
    "label": "Newark - DE 19717",
    "county": "New Castle County",
    "state": "DE",
    "city": "Newark"
  },
  {
    "value": "19720",
    "label": "New Castle - DE 19720",
    "county": "New Castle County",
    "state": "DE",
    "city": "New Castle"
  },
  {
    "value": "19734",
    "label": "Townsend - DE 19734",
    "county": "New Castle County",
    "state": "DE",
    "city": "Townsend"
  },
  {
    "value": "19735",
    "label": "Winterthur - DE 19735",
    "county": "New Castle County",
    "state": "DE",
    "city": "Winterthur"
  },
  {
    "value": "19736",
    "label": "Yorklyn - DE 19736",
    "county": "New Castle County",
    "state": "DE",
    "city": "Yorklyn"
  },
  {
    "value": "19801",
    "label": "Wilmington - DE 19801",
    "county": "New Castle County",
    "state": "DE",
    "city": "Wilmington"
  },
  {
    "value": "19802",
    "label": "Wilmington - DE 19802",
    "county": "New Castle County",
    "state": "DE",
    "city": "Wilmington"
  },
  {
    "value": "19803",
    "label": "Wilmington - DE 19803",
    "county": "New Castle County",
    "state": "DE",
    "city": "Wilmington"
  },
  {
    "value": "19804",
    "label": "Wilmington - DE 19804",
    "county": "New Castle County",
    "state": "DE",
    "city": "Wilmington"
  },
  {
    "value": "19805",
    "label": "Wilmington - DE 19805",
    "county": "New Castle County",
    "state": "DE",
    "city": "Wilmington"
  },
  {
    "value": "19806",
    "label": "Wilmington - DE 19806",
    "county": "New Castle County",
    "state": "DE",
    "city": "Wilmington"
  },
  {
    "value": "19807",
    "label": "Wilmington - DE 19807",
    "county": "New Castle County",
    "state": "DE",
    "city": "Wilmington"
  },
  {
    "value": "19808",
    "label": "Wilmington - DE 19808",
    "county": "New Castle County",
    "state": "DE",
    "city": "Wilmington"
  },
  {
    "value": "19809",
    "label": "Wilmington - DE 19809",
    "county": "New Castle County",
    "state": "DE",
    "city": "Wilmington"
  },
  {
    "value": "19810",
    "label": "Wilmington - DE 19810",
    "county": "New Castle County",
    "state": "DE",
    "city": "Wilmington"
  },
  {
    "value": "19901",
    "label": "Dover - DE 19901",
    "county": "Kent County",
    "state": "DE",
    "city": "Dover"
  },
  {
    "value": "19902",
    "label": "Dover AFB - DE 19902",
    "county": "Kent County",
    "state": "DE",
    "city": "Dover AFB"
  },
  {
    "value": "19904",
    "label": "Dover - DE 19904",
    "county": "Kent County",
    "state": "DE",
    "city": "Dover"
  },
  {
    "value": "19906",
    "label": "Dover - DE 19906",
    "county": "Kent County",
    "state": "DE",
    "city": "Dover"
  },
  {
    "value": "19930",
    "label": "Bethany Beach - DE 19930",
    "county": "Sussex County",
    "state": "DE",
    "city": "Bethany Beach"
  },
  {
    "value": "19931",
    "label": "Bethel - DE 19931",
    "county": "Sussex County",
    "state": "DE",
    "city": "Bethel"
  },
  {
    "value": "19933",
    "label": "Bridgeville - DE 19933",
    "county": "Sussex County",
    "state": "DE",
    "city": "Bridgeville"
  },
  {
    "value": "19934",
    "label": "Camden Wyoming - DE 19934",
    "county": "Kent County",
    "state": "DE",
    "city": "Camden Wyoming"
  },
  {
    "value": "19938",
    "label": "Clayton - DE 19938",
    "county": "Kent County",
    "state": "DE",
    "city": "Clayton"
  },
  {
    "value": "19939",
    "label": "Dagsboro - DE 19939",
    "county": "Sussex County",
    "state": "DE",
    "city": "Dagsboro"
  },
  {
    "value": "19940",
    "label": "Delmar - DE 19940",
    "county": "Sussex County",
    "state": "DE",
    "city": "Delmar"
  },
  {
    "value": "19941",
    "label": "Ellendale - DE 19941",
    "county": "Sussex County",
    "state": "DE",
    "city": "Ellendale"
  },
  {
    "value": "19943",
    "label": "Felton - DE 19943",
    "county": "Kent County",
    "state": "DE",
    "city": "Felton"
  },
  {
    "value": "19944",
    "label": "Fenwick Island - DE 19944",
    "county": "Sussex County",
    "state": "DE",
    "city": "Fenwick Island"
  },
  {
    "value": "19945",
    "label": "Frankford - DE 19945",
    "county": "Sussex County",
    "state": "DE",
    "city": "Frankford"
  },
  {
    "value": "19946",
    "label": "Frederica - DE 19946",
    "county": "Kent County",
    "state": "DE",
    "city": "Frederica"
  },
  {
    "value": "19947",
    "label": "Georgetown - DE 19947",
    "county": "Sussex County",
    "state": "DE",
    "city": "Georgetown"
  },
  {
    "value": "19950",
    "label": "Greenwood - DE 19950",
    "county": "Sussex County",
    "state": "DE",
    "city": "Greenwood"
  },
  {
    "value": "19951",
    "label": "Harbeson - DE 19951",
    "county": "Sussex County",
    "state": "DE",
    "city": "Harbeson"
  },
  {
    "value": "19952",
    "label": "Harrington - DE 19952",
    "county": "Kent County",
    "state": "DE",
    "city": "Harrington"
  },
  {
    "value": "19953",
    "label": "Hartly - DE 19953",
    "county": "Kent County",
    "state": "DE",
    "city": "Hartly"
  },
  {
    "value": "19954",
    "label": "Houston - DE 19954",
    "county": "Kent County",
    "state": "DE",
    "city": "Houston"
  },
  {
    "value": "19956",
    "label": "Laurel - DE 19956",
    "county": "Sussex County",
    "state": "DE",
    "city": "Laurel"
  },
  {
    "value": "19958",
    "label": "Lewes - DE 19958",
    "county": "Sussex County",
    "state": "DE",
    "city": "Lewes"
  },
  {
    "value": "19960",
    "label": "Lincoln - DE 19960",
    "county": "Sussex County",
    "state": "DE",
    "city": "Lincoln"
  },
  {
    "value": "19962",
    "label": "Magnolia - DE 19962",
    "county": "Kent County",
    "state": "DE",
    "city": "Magnolia"
  },
  {
    "value": "19963",
    "label": "Milford - DE 19963",
    "county": "Sussex County",
    "state": "DE",
    "city": "Milford"
  },
  {
    "value": "19964",
    "label": "Marydel - DE 19964",
    "county": "Kent County",
    "state": "DE",
    "city": "Marydel"
  },
  {
    "value": "19966",
    "label": "Millsboro - DE 19966",
    "county": "Sussex County",
    "state": "DE",
    "city": "Millsboro"
  },
  {
    "value": "19967",
    "label": "Millville - DE 19967",
    "county": "Sussex County",
    "state": "DE",
    "city": "Millville"
  },
  {
    "value": "19968",
    "label": "Milton - DE 19968",
    "county": "Sussex County",
    "state": "DE",
    "city": "Milton"
  },
  {
    "value": "19970",
    "label": "Ocean View - DE 19970",
    "county": "Sussex County",
    "state": "DE",
    "city": "Ocean View"
  },
  {
    "value": "19971",
    "label": "Rehoboth Beach - DE 19971",
    "county": "Sussex County",
    "state": "DE",
    "city": "Rehoboth Beach"
  },
  {
    "value": "19973",
    "label": "Seaford - DE 19973",
    "county": "Sussex County",
    "state": "DE",
    "city": "Seaford"
  },
  {
    "value": "19975",
    "label": "Selbyville - DE 19975",
    "county": "Sussex County",
    "state": "DE",
    "city": "Selbyville"
  },
  {
    "value": "19977",
    "label": "Smyrna - DE 19977",
    "county": "Kent County",
    "state": "DE",
    "city": "Smyrna"
  },
  {
    "value": "19979",
    "label": "Viola - DE 19979",
    "county": "Kent County",
    "state": "DE",
    "city": "Viola"
  },
  {
    "value": "20001",
    "label": "Washington - DC 20001",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington"
  },
  {
    "value": "20002",
    "label": "Washington - DC 20002",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington"
  },
  {
    "value": "20003",
    "label": "Washington - DC 20003",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington"
  },
  {
    "value": "20004",
    "label": "Washington - DC 20004",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington"
  },
  {
    "value": "20005",
    "label": "Washington - DC 20005",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington"
  },
  {
    "value": "20006",
    "label": "Washington - DC 20006",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington"
  },
  {
    "value": "20007",
    "label": "Washington - DC 20007",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington"
  },
  {
    "value": "20008",
    "label": "Washington - DC 20008",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington"
  },
  {
    "value": "20009",
    "label": "Washington - DC 20009",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington"
  },
  {
    "value": "20010",
    "label": "Washington - DC 20010",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington"
  },
  {
    "value": "20011",
    "label": "Washington - DC 20011",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington"
  },
  {
    "value": "20012",
    "label": "Washington - DC 20012",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington"
  },
  {
    "value": "20015",
    "label": "Washington - DC 20015",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington"
  },
  {
    "value": "20016",
    "label": "Washington - DC 20016",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington"
  },
  {
    "value": "20017",
    "label": "Washington - DC 20017",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington"
  },
  {
    "value": "20018",
    "label": "Washington - DC 20018",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington"
  },
  {
    "value": "20019",
    "label": "Washington - DC 20019",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington"
  },
  {
    "value": "20020",
    "label": "Washington - DC 20020",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington"
  },
  {
    "value": "20024",
    "label": "Washington - DC 20024",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington"
  },
  {
    "value": "20032",
    "label": "Washington - DC 20032",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington"
  },
  {
    "value": "20036",
    "label": "Washington - DC 20036",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington"
  },
  {
    "value": "20037",
    "label": "Washington - DC 20037",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington"
  },
  {
    "value": "20045",
    "label": "Washington - DC 20045",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington"
  },
  {
    "value": "20260",
    "label": "Washington - DC 20260",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington"
  },
  {
    "value": "20374",
    "label": "Washington Navy Yard - DC 20374",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington Navy Yard"
  },
  {
    "value": "20376",
    "label": "Washington Navy Yard - DC 20376",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington Navy Yard"
  },
  {
    "value": "20388",
    "label": "Washington Navy Yard - DC 20388",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington Navy Yard"
  },
  {
    "value": "20391",
    "label": "Washington Navy Yard - DC 20391",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington Navy Yard"
  },
  {
    "value": "20398",
    "label": "Washington Navy Yard - DC 20398",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington Navy Yard"
  },
  {
    "value": "20500",
    "label": "Washington - DC 20500",
    "county": "District of Columbia",
    "state": "DC",
    "city": "Washington"
  },
  {
    "value": "32003",
    "label": "Fleming Island - FL 32003",
    "county": "Clay County",
    "state": "FL",
    "city": "Fleming Island"
  },
  {
    "value": "32008",
    "label": "Branford - FL 32008",
    "county": "Suwannee County",
    "state": "FL",
    "city": "Branford"
  },
  {
    "value": "32009",
    "label": "Bryceville - FL 32009",
    "county": "Nassau County",
    "state": "FL",
    "city": "Bryceville"
  },
  {
    "value": "32011",
    "label": "Callahan - FL 32011",
    "county": "Nassau County",
    "state": "FL",
    "city": "Callahan"
  },
  {
    "value": "32024",
    "label": "Lake City - FL 32024",
    "county": "Columbia County",
    "state": "FL",
    "city": "Lake City"
  },
  {
    "value": "32025",
    "label": "Lake City - FL 32025",
    "county": "Columbia County",
    "state": "FL",
    "city": "Lake City"
  },
  {
    "value": "32033",
    "label": "Elkton - FL 32033",
    "county": "St. Johns County",
    "state": "FL",
    "city": "Elkton"
  },
  {
    "value": "32034",
    "label": "Fernandina Beach - FL 32034",
    "county": "Nassau County",
    "state": "FL",
    "city": "Fernandina Beach"
  },
  {
    "value": "32038",
    "label": "Fort White - FL 32038",
    "county": "Columbia County",
    "state": "FL",
    "city": "Fort White"
  },
  {
    "value": "32040",
    "label": "Glen Saint Mary - FL 32040",
    "county": "Baker County",
    "state": "FL",
    "city": "Glen Saint Mary"
  },
  {
    "value": "32043",
    "label": "Green Cove Springs - FL 32043",
    "county": "Clay County",
    "state": "FL",
    "city": "Green Cove Springs"
  },
  {
    "value": "32044",
    "label": "Hampton - FL 32044",
    "county": "Bradford County",
    "state": "FL",
    "city": "Hampton"
  },
  {
    "value": "32046",
    "label": "Hilliard - FL 32046",
    "county": "Nassau County",
    "state": "FL",
    "city": "Hilliard"
  },
  {
    "value": "32052",
    "label": "Jasper - FL 32052",
    "county": "Hamilton County",
    "state": "FL",
    "city": "Jasper"
  },
  {
    "value": "32053",
    "label": "Jennings - FL 32053",
    "county": "Hamilton County",
    "state": "FL",
    "city": "Jennings"
  },
  {
    "value": "32054",
    "label": "Lake Butler - FL 32054",
    "county": "Union County",
    "state": "FL",
    "city": "Lake Butler"
  },
  {
    "value": "32055",
    "label": "Lake City - FL 32055",
    "county": "Columbia County",
    "state": "FL",
    "city": "Lake City"
  },
  {
    "value": "32058",
    "label": "Lawtey - FL 32058",
    "county": "Bradford County",
    "state": "FL",
    "city": "Lawtey"
  },
  {
    "value": "32059",
    "label": "Lee - FL 32059",
    "county": "Madison County",
    "state": "FL",
    "city": "Lee"
  },
  {
    "value": "32060",
    "label": "Live Oak - FL 32060",
    "county": "Suwannee County",
    "state": "FL",
    "city": "Live Oak"
  },
  {
    "value": "32061",
    "label": "Lulu - FL 32061",
    "county": "Columbia County",
    "state": "FL",
    "city": "Lulu"
  },
  {
    "value": "32062",
    "label": "Mc Alpin - FL 32062",
    "county": "Suwannee County",
    "state": "FL",
    "city": "Mc Alpin"
  },
  {
    "value": "32063",
    "label": "Macclenny - FL 32063",
    "county": "Baker County",
    "state": "FL",
    "city": "Macclenny"
  },
  {
    "value": "32064",
    "label": "Live Oak - FL 32064",
    "county": "Suwannee County",
    "state": "FL",
    "city": "Live Oak"
  },
  {
    "value": "32065",
    "label": "Orange Park - FL 32065",
    "county": "Clay County",
    "state": "FL",
    "city": "Orange Park"
  },
  {
    "value": "32066",
    "label": "Mayo - FL 32066",
    "county": "Lafayette County",
    "state": "FL",
    "city": "Mayo"
  },
  {
    "value": "32068",
    "label": "Middleburg - FL 32068",
    "county": "Clay County",
    "state": "FL",
    "city": "Middleburg"
  },
  {
    "value": "32071",
    "label": "O Brien - FL 32071",
    "county": "Suwannee County",
    "state": "FL",
    "city": "O Brien"
  },
  {
    "value": "32073",
    "label": "Orange Park - FL 32073",
    "county": "Clay County",
    "state": "FL",
    "city": "Orange Park"
  },
  {
    "value": "32080",
    "label": "Saint Augustine - FL 32080",
    "county": "St. Johns County",
    "state": "FL",
    "city": "Saint Augustine"
  },
  {
    "value": "32081",
    "label": "Ponte Vedra - FL 32081",
    "county": "St. Johns County",
    "state": "FL",
    "city": "Ponte Vedra"
  },
  {
    "value": "32082",
    "label": "Ponte Vedra Beach - FL 32082",
    "county": "St. Johns County",
    "state": "FL",
    "city": "Ponte Vedra Beach"
  },
  {
    "value": "32083",
    "label": "Raiford - FL 32083",
    "county": "Union County",
    "state": "FL",
    "city": "Raiford"
  },
  {
    "value": "32084",
    "label": "Saint Augustine - FL 32084",
    "county": "St. Johns County",
    "state": "FL",
    "city": "Saint Augustine"
  },
  {
    "value": "32086",
    "label": "Saint Augustine - FL 32086",
    "county": "St. Johns County",
    "state": "FL",
    "city": "Saint Augustine"
  },
  {
    "value": "32087",
    "label": "Sanderson - FL 32087",
    "county": "Baker County",
    "state": "FL",
    "city": "Sanderson"
  },
  {
    "value": "32091",
    "label": "Starke - FL 32091",
    "county": "Bradford County",
    "state": "FL",
    "city": "Starke"
  },
  {
    "value": "32092",
    "label": "Saint Augustine - FL 32092",
    "county": "St. Johns County",
    "state": "FL",
    "city": "Saint Augustine"
  },
  {
    "value": "32094",
    "label": "Wellborn - FL 32094",
    "county": "Suwannee County",
    "state": "FL",
    "city": "Wellborn"
  },
  {
    "value": "32095",
    "label": "Saint Augustine - FL 32095",
    "county": "St. Johns County",
    "state": "FL",
    "city": "Saint Augustine"
  },
  {
    "value": "32096",
    "label": "White Springs - FL 32096",
    "county": "Hamilton County",
    "state": "FL",
    "city": "White Springs"
  },
  {
    "value": "32097",
    "label": "Yulee - FL 32097",
    "county": "Nassau County",
    "state": "FL",
    "city": "Yulee"
  },
  {
    "value": "32099",
    "label": "Jacksonville - FL 32099",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32102",
    "label": "Astor - FL 32102",
    "county": "Lake County",
    "state": "FL",
    "city": "Astor"
  },
  {
    "value": "32110",
    "label": "Bunnell - FL 32110",
    "county": "Flagler County",
    "state": "FL",
    "city": "Bunnell"
  },
  {
    "value": "32112",
    "label": "Crescent City - FL 32112",
    "county": "Putnam County",
    "state": "FL",
    "city": "Crescent City"
  },
  {
    "value": "32113",
    "label": "Citra - FL 32113",
    "county": "Marion County",
    "state": "FL",
    "city": "Citra"
  },
  {
    "value": "32114",
    "label": "Daytona Beach - FL 32114",
    "county": "Volusia County",
    "state": "FL",
    "city": "Daytona Beach"
  },
  {
    "value": "32117",
    "label": "Daytona Beach - FL 32117",
    "county": "Volusia County",
    "state": "FL",
    "city": "Daytona Beach"
  },
  {
    "value": "32118",
    "label": "Daytona Beach - FL 32118",
    "county": "Volusia County",
    "state": "FL",
    "city": "Daytona Beach"
  },
  {
    "value": "32119",
    "label": "Daytona Beach - FL 32119",
    "county": "Volusia County",
    "state": "FL",
    "city": "Daytona Beach"
  },
  {
    "value": "32124",
    "label": "Daytona Beach - FL 32124",
    "county": "Volusia County",
    "state": "FL",
    "city": "Daytona Beach"
  },
  {
    "value": "32127",
    "label": "Port Orange - FL 32127",
    "county": "Volusia County",
    "state": "FL",
    "city": "Port Orange"
  },
  {
    "value": "32128",
    "label": "Port Orange - FL 32128",
    "county": "Volusia County",
    "state": "FL",
    "city": "Port Orange"
  },
  {
    "value": "32129",
    "label": "Port Orange - FL 32129",
    "county": "Volusia County",
    "state": "FL",
    "city": "Port Orange"
  },
  {
    "value": "32130",
    "label": "De Leon Springs - FL 32130",
    "county": "Volusia County",
    "state": "FL",
    "city": "De Leon Springs"
  },
  {
    "value": "32131",
    "label": "East Palatka - FL 32131",
    "county": "Putnam County",
    "state": "FL",
    "city": "East Palatka"
  },
  {
    "value": "32132",
    "label": "Edgewater - FL 32132",
    "county": "Volusia County",
    "state": "FL",
    "city": "Edgewater"
  },
  {
    "value": "32134",
    "label": "Fort Mc Coy - FL 32134",
    "county": "Marion County",
    "state": "FL",
    "city": "Fort Mc Coy"
  },
  {
    "value": "32136",
    "label": "Flagler Beach - FL 32136",
    "county": "Flagler County",
    "state": "FL",
    "city": "Flagler Beach"
  },
  {
    "value": "32137",
    "label": "Palm Coast - FL 32137",
    "county": "Flagler County",
    "state": "FL",
    "city": "Palm Coast"
  },
  {
    "value": "32139",
    "label": "Georgetown - FL 32139",
    "county": "Putnam County",
    "state": "FL",
    "city": "Georgetown"
  },
  {
    "value": "32140",
    "label": "Florahome - FL 32140",
    "county": "Putnam County",
    "state": "FL",
    "city": "Florahome"
  },
  {
    "value": "32141",
    "label": "Edgewater - FL 32141",
    "county": "Volusia County",
    "state": "FL",
    "city": "Edgewater"
  },
  {
    "value": "32145",
    "label": "Hastings - FL 32145",
    "county": "St. Johns County",
    "state": "FL",
    "city": "Hastings"
  },
  {
    "value": "32148",
    "label": "Interlachen - FL 32148",
    "county": "Putnam County",
    "state": "FL",
    "city": "Interlachen"
  },
  {
    "value": "32159",
    "label": "Lady Lake - FL 32159",
    "county": "Lake County",
    "state": "FL",
    "city": "Lady Lake"
  },
  {
    "value": "32162",
    "label": "The Villages - FL 32162",
    "county": "Sumter County",
    "state": "FL",
    "city": "The Villages"
  },
  {
    "value": "32163",
    "label": "The Villages - FL 32163",
    "county": "Sumter County",
    "state": "FL",
    "city": "The Villages"
  },
  {
    "value": "32164",
    "label": "Palm Coast - FL 32164",
    "county": "Flagler County",
    "state": "FL",
    "city": "Palm Coast"
  },
  {
    "value": "32168",
    "label": "New Smyrna Beach - FL 32168",
    "county": "Volusia County",
    "state": "FL",
    "city": "New Smyrna Beach"
  },
  {
    "value": "32169",
    "label": "New Smyrna Beach - FL 32169",
    "county": "Volusia County",
    "state": "FL",
    "city": "New Smyrna Beach"
  },
  {
    "value": "32174",
    "label": "Ormond Beach - FL 32174",
    "county": "Volusia County",
    "state": "FL",
    "city": "Ormond Beach"
  },
  {
    "value": "32176",
    "label": "Ormond Beach - FL 32176",
    "county": "Volusia County",
    "state": "FL",
    "city": "Ormond Beach"
  },
  {
    "value": "32177",
    "label": "Palatka - FL 32177",
    "county": "Putnam County",
    "state": "FL",
    "city": "Palatka"
  },
  {
    "value": "32179",
    "label": "Ocklawaha - FL 32179",
    "county": "Marion County",
    "state": "FL",
    "city": "Ocklawaha"
  },
  {
    "value": "32180",
    "label": "Pierson - FL 32180",
    "county": "Volusia County",
    "state": "FL",
    "city": "Pierson"
  },
  {
    "value": "32181",
    "label": "Pomona Park - FL 32181",
    "county": "Putnam County",
    "state": "FL",
    "city": "Pomona Park"
  },
  {
    "value": "32187",
    "label": "San Mateo - FL 32187",
    "county": "Putnam County",
    "state": "FL",
    "city": "San Mateo"
  },
  {
    "value": "32189",
    "label": "Satsuma - FL 32189",
    "county": "Putnam County",
    "state": "FL",
    "city": "Satsuma"
  },
  {
    "value": "32190",
    "label": "Seville - FL 32190",
    "county": "Volusia County",
    "state": "FL",
    "city": "Seville"
  },
  {
    "value": "32193",
    "label": "Welaka - FL 32193",
    "county": "Putnam County",
    "state": "FL",
    "city": "Welaka"
  },
  {
    "value": "32195",
    "label": "Weirsdale - FL 32195",
    "county": "Marion County",
    "state": "FL",
    "city": "Weirsdale"
  },
  {
    "value": "32202",
    "label": "Jacksonville - FL 32202",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32204",
    "label": "Jacksonville - FL 32204",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32205",
    "label": "Jacksonville - FL 32205",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32206",
    "label": "Jacksonville - FL 32206",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32207",
    "label": "Jacksonville - FL 32207",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32208",
    "label": "Jacksonville - FL 32208",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32209",
    "label": "Jacksonville - FL 32209",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32210",
    "label": "Jacksonville - FL 32210",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32211",
    "label": "Jacksonville - FL 32211",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32212",
    "label": "Jacksonville - FL 32212",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32216",
    "label": "Jacksonville - FL 32216",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32217",
    "label": "Jacksonville - FL 32217",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32218",
    "label": "Jacksonville - FL 32218",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32219",
    "label": "Jacksonville - FL 32219",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32220",
    "label": "Jacksonville - FL 32220",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32221",
    "label": "Jacksonville - FL 32221",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32222",
    "label": "Jacksonville - FL 32222",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32223",
    "label": "Jacksonville - FL 32223",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32224",
    "label": "Jacksonville - FL 32224",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32225",
    "label": "Jacksonville - FL 32225",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32226",
    "label": "Jacksonville - FL 32226",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32227",
    "label": "Jacksonville - FL 32227",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32233",
    "label": "Atlantic Beach - FL 32233",
    "county": "Duval County",
    "state": "FL",
    "city": "Atlantic Beach"
  },
  {
    "value": "32234",
    "label": "Jacksonville - FL 32234",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32244",
    "label": "Jacksonville - FL 32244",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32246",
    "label": "Jacksonville - FL 32246",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32250",
    "label": "Jacksonville Beach - FL 32250",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville Beach"
  },
  {
    "value": "32254",
    "label": "Jacksonville - FL 32254",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32256",
    "label": "Jacksonville - FL 32256",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32257",
    "label": "Jacksonville - FL 32257",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32258",
    "label": "Jacksonville - FL 32258",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32259",
    "label": "Saint Johns - FL 32259",
    "county": "St. Johns County",
    "state": "FL",
    "city": "Saint Johns"
  },
  {
    "value": "32266",
    "label": "Neptune Beach - FL 32266",
    "county": "Duval County",
    "state": "FL",
    "city": "Neptune Beach"
  },
  {
    "value": "32267",
    "label": "Jacksonville - FL 32267",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32277",
    "label": "Jacksonville - FL 32277",
    "county": "Duval County",
    "state": "FL",
    "city": "Jacksonville"
  },
  {
    "value": "32301",
    "label": "Tallahassee - FL 32301",
    "county": "Leon County",
    "state": "FL",
    "city": "Tallahassee"
  },
  {
    "value": "32303",
    "label": "Tallahassee - FL 32303",
    "county": "Leon County",
    "state": "FL",
    "city": "Tallahassee"
  },
  {
    "value": "32304",
    "label": "Tallahassee - FL 32304",
    "county": "Leon County",
    "state": "FL",
    "city": "Tallahassee"
  },
  {
    "value": "32305",
    "label": "Tallahassee - FL 32305",
    "county": "Leon County",
    "state": "FL",
    "city": "Tallahassee"
  },
  {
    "value": "32308",
    "label": "Tallahassee - FL 32308",
    "county": "Leon County",
    "state": "FL",
    "city": "Tallahassee"
  },
  {
    "value": "32309",
    "label": "Tallahassee - FL 32309",
    "county": "Leon County",
    "state": "FL",
    "city": "Tallahassee"
  },
  {
    "value": "32310",
    "label": "Tallahassee - FL 32310",
    "county": "Leon County",
    "state": "FL",
    "city": "Tallahassee"
  },
  {
    "value": "32311",
    "label": "Tallahassee - FL 32311",
    "county": "Leon County",
    "state": "FL",
    "city": "Tallahassee"
  },
  {
    "value": "32312",
    "label": "Tallahassee - FL 32312",
    "county": "Leon County",
    "state": "FL",
    "city": "Tallahassee"
  },
  {
    "value": "32317",
    "label": "Tallahassee - FL 32317",
    "county": "Leon County",
    "state": "FL",
    "city": "Tallahassee"
  },
  {
    "value": "32320",
    "label": "Apalachicola - FL 32320",
    "county": "Franklin County",
    "state": "FL",
    "city": "Apalachicola"
  },
  {
    "value": "32321",
    "label": "Bristol - FL 32321",
    "county": "Liberty County",
    "state": "FL",
    "city": "Bristol"
  },
  {
    "value": "32322",
    "label": "Carrabelle - FL 32322",
    "county": "Franklin County",
    "state": "FL",
    "city": "Carrabelle"
  },
  {
    "value": "32324",
    "label": "Chattahoochee - FL 32324",
    "county": "Gadsden County",
    "state": "FL",
    "city": "Chattahoochee"
  },
  {
    "value": "32327",
    "label": "Crawfordville - FL 32327",
    "county": "Wakulla County",
    "state": "FL",
    "city": "Crawfordville"
  },
  {
    "value": "32328",
    "label": "Eastpoint - FL 32328",
    "county": "Franklin County",
    "state": "FL",
    "city": "Eastpoint"
  },
  {
    "value": "32331",
    "label": "Greenville - FL 32331",
    "county": "Madison County",
    "state": "FL",
    "city": "Greenville"
  },
  {
    "value": "32332",
    "label": "Gretna - FL 32332",
    "county": "Gadsden County",
    "state": "FL",
    "city": "Gretna"
  },
  {
    "value": "32333",
    "label": "Havana - FL 32333",
    "county": "Gadsden County",
    "state": "FL",
    "city": "Havana"
  },
  {
    "value": "32334",
    "label": "Hosford - FL 32334",
    "county": "Liberty County",
    "state": "FL",
    "city": "Hosford"
  },
  {
    "value": "32336",
    "label": "Lamont - FL 32336",
    "county": "Jefferson County",
    "state": "FL",
    "city": "Lamont"
  },
  {
    "value": "32340",
    "label": "Madison - FL 32340",
    "county": "Madison County",
    "state": "FL",
    "city": "Madison"
  },
  {
    "value": "32343",
    "label": "Midway - FL 32343",
    "county": "Gadsden County",
    "state": "FL",
    "city": "Midway"
  },
  {
    "value": "32344",
    "label": "Monticello - FL 32344",
    "county": "Jefferson County",
    "state": "FL",
    "city": "Monticello"
  },
  {
    "value": "32346",
    "label": "Panacea - FL 32346",
    "county": "Wakulla County",
    "state": "FL",
    "city": "Panacea"
  },
  {
    "value": "32347",
    "label": "Perry - FL 32347",
    "county": "Taylor County",
    "state": "FL",
    "city": "Perry"
  },
  {
    "value": "32348",
    "label": "Perry - FL 32348",
    "county": "Taylor County",
    "state": "FL",
    "city": "Perry"
  },
  {
    "value": "32350",
    "label": "Pinetta - FL 32350",
    "county": "Madison County",
    "state": "FL",
    "city": "Pinetta"
  },
  {
    "value": "32351",
    "label": "Quincy - FL 32351",
    "county": "Gadsden County",
    "state": "FL",
    "city": "Quincy"
  },
  {
    "value": "32352",
    "label": "Quincy - FL 32352",
    "county": "Gadsden County",
    "state": "FL",
    "city": "Quincy"
  },
  {
    "value": "32358",
    "label": "Sopchoppy - FL 32358",
    "county": "Wakulla County",
    "state": "FL",
    "city": "Sopchoppy"
  },
  {
    "value": "32359",
    "label": "Steinhatchee - FL 32359",
    "county": "Taylor County",
    "state": "FL",
    "city": "Steinhatchee"
  },
  {
    "value": "32401",
    "label": "Panama City - FL 32401",
    "county": "Bay County",
    "state": "FL",
    "city": "Panama City"
  },
  {
    "value": "32403",
    "label": "Panama City - FL 32403",
    "county": "Bay County",
    "state": "FL",
    "city": "Panama City"
  },
  {
    "value": "32404",
    "label": "Panama City - FL 32404",
    "county": "Bay County",
    "state": "FL",
    "city": "Panama City"
  },
  {
    "value": "32405",
    "label": "Panama City - FL 32405",
    "county": "Bay County",
    "state": "FL",
    "city": "Panama City"
  },
  {
    "value": "32407",
    "label": "Panama City Beach - FL 32407",
    "county": "Bay County",
    "state": "FL",
    "city": "Panama City Beach"
  },
  {
    "value": "32408",
    "label": "Panama City - FL 32408",
    "county": "Bay County",
    "state": "FL",
    "city": "Panama City"
  },
  {
    "value": "32409",
    "label": "Panama City - FL 32409",
    "county": "Bay County",
    "state": "FL",
    "city": "Panama City"
  },
  {
    "value": "32413",
    "label": "Panama City Beach - FL 32413",
    "county": "Bay County",
    "state": "FL",
    "city": "Panama City Beach"
  },
  {
    "value": "32420",
    "label": "Alford - FL 32420",
    "county": "Jackson County",
    "state": "FL",
    "city": "Alford"
  },
  {
    "value": "32421",
    "label": "Altha - FL 32421",
    "county": "Calhoun County",
    "state": "FL",
    "city": "Altha"
  },
  {
    "value": "32423",
    "label": "Bascom - FL 32423",
    "county": "Jackson County",
    "state": "FL",
    "city": "Bascom"
  },
  {
    "value": "32424",
    "label": "Blountstown - FL 32424",
    "county": "Calhoun County",
    "state": "FL",
    "city": "Blountstown"
  },
  {
    "value": "32425",
    "label": "Bonifay - FL 32425",
    "county": "Holmes County",
    "state": "FL",
    "city": "Bonifay"
  },
  {
    "value": "32426",
    "label": "Campbellton - FL 32426",
    "county": "Jackson County",
    "state": "FL",
    "city": "Campbellton"
  },
  {
    "value": "32427",
    "label": "Caryville - FL 32427",
    "county": "Washington County",
    "state": "FL",
    "city": "Caryville"
  },
  {
    "value": "32428",
    "label": "Chipley - FL 32428",
    "county": "Washington County",
    "state": "FL",
    "city": "Chipley"
  },
  {
    "value": "32430",
    "label": "Clarksville - FL 32430",
    "county": "Calhoun County",
    "state": "FL",
    "city": "Clarksville"
  },
  {
    "value": "32431",
    "label": "Cottondale - FL 32431",
    "county": "Jackson County",
    "state": "FL",
    "city": "Cottondale"
  },
  {
    "value": "32433",
    "label": "Defuniak Springs - FL 32433",
    "county": "Walton County",
    "state": "FL",
    "city": "Defuniak Springs"
  },
  {
    "value": "32435",
    "label": "Defuniak Springs - FL 32435",
    "county": "Walton County",
    "state": "FL",
    "city": "Defuniak Springs"
  },
  {
    "value": "32437",
    "label": "Ebro - FL 32437",
    "county": "Washington County",
    "state": "FL",
    "city": "Ebro"
  },
  {
    "value": "32438",
    "label": "Fountain - FL 32438",
    "county": "Bay County",
    "state": "FL",
    "city": "Fountain"
  },
  {
    "value": "32439",
    "label": "Freeport - FL 32439",
    "county": "Walton County",
    "state": "FL",
    "city": "Freeport"
  },
  {
    "value": "32440",
    "label": "Graceville - FL 32440",
    "county": "Jackson County",
    "state": "FL",
    "city": "Graceville"
  },
  {
    "value": "32442",
    "label": "Grand Ridge - FL 32442",
    "county": "Jackson County",
    "state": "FL",
    "city": "Grand Ridge"
  },
  {
    "value": "32443",
    "label": "Greenwood - FL 32443",
    "county": "Jackson County",
    "state": "FL",
    "city": "Greenwood"
  },
  {
    "value": "32444",
    "label": "Lynn Haven - FL 32444",
    "county": "Bay County",
    "state": "FL",
    "city": "Lynn Haven"
  },
  {
    "value": "32445",
    "label": "Malone - FL 32445",
    "county": "Jackson County",
    "state": "FL",
    "city": "Malone"
  },
  {
    "value": "32446",
    "label": "Marianna - FL 32446",
    "county": "Jackson County",
    "state": "FL",
    "city": "Marianna"
  },
  {
    "value": "32448",
    "label": "Marianna - FL 32448",
    "county": "Jackson County",
    "state": "FL",
    "city": "Marianna"
  },
  {
    "value": "32449",
    "label": "Wewahitchka - FL 32449",
    "county": "Calhoun County",
    "state": "FL",
    "city": "Wewahitchka"
  },
  {
    "value": "32455",
    "label": "Ponce De Leon - FL 32455",
    "county": "Walton County",
    "state": "FL",
    "city": "Ponce De Leon"
  },
  {
    "value": "32456",
    "label": "Port Saint Joe - FL 32456",
    "county": "Gulf County",
    "state": "FL",
    "city": "Port Saint Joe"
  },
  {
    "value": "32459",
    "label": "Santa Rosa Beach - FL 32459",
    "county": "Walton County",
    "state": "FL",
    "city": "Santa Rosa Beach"
  },
  {
    "value": "32460",
    "label": "Sneads - FL 32460",
    "county": "Jackson County",
    "state": "FL",
    "city": "Sneads"
  },
  {
    "value": "32461",
    "label": "Inlet Beach - FL 32461",
    "county": "Walton County",
    "state": "FL",
    "city": "Inlet Beach"
  },
  {
    "value": "32462",
    "label": "Vernon - FL 32462",
    "county": "Washington County",
    "state": "FL",
    "city": "Vernon"
  },
  {
    "value": "32464",
    "label": "Westville - FL 32464",
    "county": "Holmes County",
    "state": "FL",
    "city": "Westville"
  },
  {
    "value": "32465",
    "label": "Wewahitchka - FL 32465",
    "county": "Gulf County",
    "state": "FL",
    "city": "Wewahitchka"
  },
  {
    "value": "32466",
    "label": "Youngstown - FL 32466",
    "county": "Bay County",
    "state": "FL",
    "city": "Youngstown"
  },
  {
    "value": "32501",
    "label": "Pensacola - FL 32501",
    "county": "Escambia County",
    "state": "FL",
    "city": "Pensacola"
  },
  {
    "value": "32502",
    "label": "Pensacola - FL 32502",
    "county": "Escambia County",
    "state": "FL",
    "city": "Pensacola"
  },
  {
    "value": "32503",
    "label": "Pensacola - FL 32503",
    "county": "Escambia County",
    "state": "FL",
    "city": "Pensacola"
  },
  {
    "value": "32504",
    "label": "Pensacola - FL 32504",
    "county": "Escambia County",
    "state": "FL",
    "city": "Pensacola"
  },
  {
    "value": "32505",
    "label": "Pensacola - FL 32505",
    "county": "Escambia County",
    "state": "FL",
    "city": "Pensacola"
  },
  {
    "value": "32506",
    "label": "Pensacola - FL 32506",
    "county": "Escambia County",
    "state": "FL",
    "city": "Pensacola"
  },
  {
    "value": "32507",
    "label": "Pensacola - FL 32507",
    "county": "Escambia County",
    "state": "FL",
    "city": "Pensacola"
  },
  {
    "value": "32508",
    "label": "Pensacola - FL 32508",
    "county": "Escambia County",
    "state": "FL",
    "city": "Pensacola"
  },
  {
    "value": "32514",
    "label": "Pensacola - FL 32514",
    "county": "Escambia County",
    "state": "FL",
    "city": "Pensacola"
  },
  {
    "value": "32526",
    "label": "Pensacola - FL 32526",
    "county": "Escambia County",
    "state": "FL",
    "city": "Pensacola"
  },
  {
    "value": "32531",
    "label": "Baker - FL 32531",
    "county": "Okaloosa County",
    "state": "FL",
    "city": "Baker"
  },
  {
    "value": "32533",
    "label": "Cantonment - FL 32533",
    "county": "Escambia County",
    "state": "FL",
    "city": "Cantonment"
  },
  {
    "value": "32534",
    "label": "Pensacola - FL 32534",
    "county": "Escambia County",
    "state": "FL",
    "city": "Pensacola"
  },
  {
    "value": "32535",
    "label": "Century - FL 32535",
    "county": "Escambia County",
    "state": "FL",
    "city": "Century"
  },
  {
    "value": "32536",
    "label": "Crestview - FL 32536",
    "county": "Okaloosa County",
    "state": "FL",
    "city": "Crestview"
  },
  {
    "value": "32539",
    "label": "Crestview - FL 32539",
    "county": "Okaloosa County",
    "state": "FL",
    "city": "Crestview"
  },
  {
    "value": "32541",
    "label": "Destin - FL 32541",
    "county": "Okaloosa County",
    "state": "FL",
    "city": "Destin"
  },
  {
    "value": "32542",
    "label": "Eglin AFB - FL 32542",
    "county": "Okaloosa County",
    "state": "FL",
    "city": "Eglin AFB"
  },
  {
    "value": "32544",
    "label": "Hurlburt Field - FL 32544",
    "county": "Okaloosa County",
    "state": "FL",
    "city": "Hurlburt Field"
  },
  {
    "value": "32547",
    "label": "Fort Walton Beach - FL 32547",
    "county": "Okaloosa County",
    "state": "FL",
    "city": "Fort Walton Beach"
  },
  {
    "value": "32548",
    "label": "Fort Walton Beach - FL 32548",
    "county": "Okaloosa County",
    "state": "FL",
    "city": "Fort Walton Beach"
  },
  {
    "value": "32550",
    "label": "Miramar Beach - FL 32550",
    "county": "Walton County",
    "state": "FL",
    "city": "Miramar Beach"
  },
  {
    "value": "32561",
    "label": "Gulf Breeze - FL 32561",
    "county": "Santa Rosa County",
    "state": "FL",
    "city": "Gulf Breeze"
  },
  {
    "value": "32563",
    "label": "Gulf Breeze - FL 32563",
    "county": "Santa Rosa County",
    "state": "FL",
    "city": "Gulf Breeze"
  },
  {
    "value": "32564",
    "label": "Holt - FL 32564",
    "county": "Okaloosa County",
    "state": "FL",
    "city": "Holt"
  },
  {
    "value": "32565",
    "label": "Jay - FL 32565",
    "county": "Santa Rosa County",
    "state": "FL",
    "city": "Jay"
  },
  {
    "value": "32566",
    "label": "Navarre - FL 32566",
    "county": "Santa Rosa County",
    "state": "FL",
    "city": "Navarre"
  },
  {
    "value": "32567",
    "label": "Laurel Hill - FL 32567",
    "county": "Okaloosa County",
    "state": "FL",
    "city": "Laurel Hill"
  },
  {
    "value": "32568",
    "label": "Mc David - FL 32568",
    "county": "Escambia County",
    "state": "FL",
    "city": "Mc David"
  },
  {
    "value": "32569",
    "label": "Mary Esther - FL 32569",
    "county": "Okaloosa County",
    "state": "FL",
    "city": "Mary Esther"
  },
  {
    "value": "32570",
    "label": "Milton - FL 32570",
    "county": "Santa Rosa County",
    "state": "FL",
    "city": "Milton"
  },
  {
    "value": "32571",
    "label": "Milton - FL 32571",
    "county": "Santa Rosa County",
    "state": "FL",
    "city": "Milton"
  },
  {
    "value": "32577",
    "label": "Molino - FL 32577",
    "county": "Escambia County",
    "state": "FL",
    "city": "Molino"
  },
  {
    "value": "32578",
    "label": "Niceville - FL 32578",
    "county": "Okaloosa County",
    "state": "FL",
    "city": "Niceville"
  },
  {
    "value": "32579",
    "label": "Shalimar - FL 32579",
    "county": "Okaloosa County",
    "state": "FL",
    "city": "Shalimar"
  },
  {
    "value": "32580",
    "label": "Valparaiso - FL 32580",
    "county": "Okaloosa County",
    "state": "FL",
    "city": "Valparaiso"
  },
  {
    "value": "32583",
    "label": "Milton - FL 32583",
    "county": "Santa Rosa County",
    "state": "FL",
    "city": "Milton"
  },
  {
    "value": "32601",
    "label": "Gainesville - FL 32601",
    "county": "Alachua County",
    "state": "FL",
    "city": "Gainesville"
  },
  {
    "value": "32603",
    "label": "Gainesville - FL 32603",
    "county": "Alachua County",
    "state": "FL",
    "city": "Gainesville"
  },
  {
    "value": "32605",
    "label": "Gainesville - FL 32605",
    "county": "Alachua County",
    "state": "FL",
    "city": "Gainesville"
  },
  {
    "value": "32606",
    "label": "Gainesville - FL 32606",
    "county": "Alachua County",
    "state": "FL",
    "city": "Gainesville"
  },
  {
    "value": "32607",
    "label": "Gainesville - FL 32607",
    "county": "Alachua County",
    "state": "FL",
    "city": "Gainesville"
  },
  {
    "value": "32608",
    "label": "Gainesville - FL 32608",
    "county": "Alachua County",
    "state": "FL",
    "city": "Gainesville"
  },
  {
    "value": "32609",
    "label": "Gainesville - FL 32609",
    "county": "Alachua County",
    "state": "FL",
    "city": "Gainesville"
  },
  {
    "value": "32615",
    "label": "Alachua - FL 32615",
    "county": "Alachua County",
    "state": "FL",
    "city": "Alachua"
  },
  {
    "value": "32617",
    "label": "Anthony - FL 32617",
    "county": "Marion County",
    "state": "FL",
    "city": "Anthony"
  },
  {
    "value": "32618",
    "label": "Archer - FL 32618",
    "county": "Alachua County",
    "state": "FL",
    "city": "Archer"
  },
  {
    "value": "32619",
    "label": "Bell - FL 32619",
    "county": "Gilchrist County",
    "state": "FL",
    "city": "Bell"
  },
  {
    "value": "32621",
    "label": "Bronson - FL 32621",
    "county": "Levy County",
    "state": "FL",
    "city": "Bronson"
  },
  {
    "value": "32622",
    "label": "Brooker - FL 32622",
    "county": "Bradford County",
    "state": "FL",
    "city": "Brooker"
  },
  {
    "value": "32625",
    "label": "Cedar Key - FL 32625",
    "county": "Levy County",
    "state": "FL",
    "city": "Cedar Key"
  },
  {
    "value": "32626",
    "label": "Chiefland - FL 32626",
    "county": "Levy County",
    "state": "FL",
    "city": "Chiefland"
  },
  {
    "value": "32628",
    "label": "Cross City - FL 32628",
    "county": "Dixie County",
    "state": "FL",
    "city": "Cross City"
  },
  {
    "value": "32631",
    "label": "Earleton - FL 32631",
    "county": "Alachua County",
    "state": "FL",
    "city": "Earleton"
  },
  {
    "value": "32640",
    "label": "Hawthorne - FL 32640",
    "county": "Putnam County",
    "state": "FL",
    "city": "Hawthorne"
  },
  {
    "value": "32641",
    "label": "Gainesville - FL 32641",
    "county": "Alachua County",
    "state": "FL",
    "city": "Gainesville"
  },
  {
    "value": "32643",
    "label": "High Springs - FL 32643",
    "county": "Alachua County",
    "state": "FL",
    "city": "High Springs"
  },
  {
    "value": "32648",
    "label": "Horseshoe Beach - FL 32648",
    "county": "Dixie County",
    "state": "FL",
    "city": "Horseshoe Beach"
  },
  {
    "value": "32653",
    "label": "Gainesville - FL 32653",
    "county": "Alachua County",
    "state": "FL",
    "city": "Gainesville"
  },
  {
    "value": "32656",
    "label": "Keystone Heights - FL 32656",
    "county": "Clay County",
    "state": "FL",
    "city": "Keystone Heights"
  },
  {
    "value": "32666",
    "label": "Melrose - FL 32666",
    "county": "Putnam County",
    "state": "FL",
    "city": "Melrose"
  },
  {
    "value": "32667",
    "label": "Micanopy - FL 32667",
    "county": "Alachua County",
    "state": "FL",
    "city": "Micanopy"
  },
  {
    "value": "32668",
    "label": "Morriston - FL 32668",
    "county": "Levy County",
    "state": "FL",
    "city": "Morriston"
  },
  {
    "value": "32669",
    "label": "Newberry - FL 32669",
    "county": "Alachua County",
    "state": "FL",
    "city": "Newberry"
  },
  {
    "value": "32680",
    "label": "Old Town - FL 32680",
    "county": "Dixie County",
    "state": "FL",
    "city": "Old Town"
  },
  {
    "value": "32686",
    "label": "Reddick - FL 32686",
    "county": "Marion County",
    "state": "FL",
    "city": "Reddick"
  },
  {
    "value": "32693",
    "label": "Trenton - FL 32693",
    "county": "Gilchrist County",
    "state": "FL",
    "city": "Trenton"
  },
  {
    "value": "32694",
    "label": "Waldo - FL 32694",
    "county": "Alachua County",
    "state": "FL",
    "city": "Waldo"
  },
  {
    "value": "32696",
    "label": "Williston - FL 32696",
    "county": "Levy County",
    "state": "FL",
    "city": "Williston"
  },
  {
    "value": "32701",
    "label": "Altamonte Springs - FL 32701",
    "county": "Seminole County",
    "state": "FL",
    "city": "Altamonte Springs"
  },
  {
    "value": "32702",
    "label": "Altoona - FL 32702",
    "county": "Lake County",
    "state": "FL",
    "city": "Altoona"
  },
  {
    "value": "32703",
    "label": "Apopka - FL 32703",
    "county": "Orange County",
    "state": "FL",
    "city": "Apopka"
  },
  {
    "value": "32707",
    "label": "Casselberry - FL 32707",
    "county": "Seminole County",
    "state": "FL",
    "city": "Casselberry"
  },
  {
    "value": "32708",
    "label": "Winter Springs - FL 32708",
    "county": "Seminole County",
    "state": "FL",
    "city": "Winter Springs"
  },
  {
    "value": "32709",
    "label": "Christmas - FL 32709",
    "county": "Orange County",
    "state": "FL",
    "city": "Christmas"
  },
  {
    "value": "32712",
    "label": "Apopka - FL 32712",
    "county": "Orange County",
    "state": "FL",
    "city": "Apopka"
  },
  {
    "value": "32713",
    "label": "Debary - FL 32713",
    "county": "Volusia County",
    "state": "FL",
    "city": "Debary"
  },
  {
    "value": "32714",
    "label": "Altamonte Springs - FL 32714",
    "county": "Seminole County",
    "state": "FL",
    "city": "Altamonte Springs"
  },
  {
    "value": "32720",
    "label": "Deland - FL 32720",
    "county": "Volusia County",
    "state": "FL",
    "city": "Deland"
  },
  {
    "value": "32724",
    "label": "Deland - FL 32724",
    "county": "Volusia County",
    "state": "FL",
    "city": "Deland"
  },
  {
    "value": "32725",
    "label": "Deltona - FL 32725",
    "county": "Volusia County",
    "state": "FL",
    "city": "Deltona"
  },
  {
    "value": "32726",
    "label": "Eustis - FL 32726",
    "county": "Lake County",
    "state": "FL",
    "city": "Eustis"
  },
  {
    "value": "32730",
    "label": "Casselberry - FL 32730",
    "county": "Seminole County",
    "state": "FL",
    "city": "Casselberry"
  },
  {
    "value": "32732",
    "label": "Geneva - FL 32732",
    "county": "Seminole County",
    "state": "FL",
    "city": "Geneva"
  },
  {
    "value": "32735",
    "label": "Grand Island - FL 32735",
    "county": "Lake County",
    "state": "FL",
    "city": "Grand Island"
  },
  {
    "value": "32736",
    "label": "Eustis - FL 32736",
    "county": "Lake County",
    "state": "FL",
    "city": "Eustis"
  },
  {
    "value": "32738",
    "label": "Deltona - FL 32738",
    "county": "Volusia County",
    "state": "FL",
    "city": "Deltona"
  },
  {
    "value": "32744",
    "label": "Lake Helen - FL 32744",
    "county": "Volusia County",
    "state": "FL",
    "city": "Lake Helen"
  },
  {
    "value": "32746",
    "label": "Lake Mary - FL 32746",
    "county": "Seminole County",
    "state": "FL",
    "city": "Lake Mary"
  },
  {
    "value": "32750",
    "label": "Longwood - FL 32750",
    "county": "Seminole County",
    "state": "FL",
    "city": "Longwood"
  },
  {
    "value": "32751",
    "label": "Maitland - FL 32751",
    "county": "Orange County",
    "state": "FL",
    "city": "Maitland"
  },
  {
    "value": "32754",
    "label": "Mims - FL 32754",
    "county": "Brevard County",
    "state": "FL",
    "city": "Mims"
  },
  {
    "value": "32757",
    "label": "Mount Dora - FL 32757",
    "county": "Lake County",
    "state": "FL",
    "city": "Mount Dora"
  },
  {
    "value": "32759",
    "label": "Oak Hill - FL 32759",
    "county": "Volusia County",
    "state": "FL",
    "city": "Oak Hill"
  },
  {
    "value": "32763",
    "label": "Orange City - FL 32763",
    "county": "Volusia County",
    "state": "FL",
    "city": "Orange City"
  },
  {
    "value": "32764",
    "label": "Osteen - FL 32764",
    "county": "Volusia County",
    "state": "FL",
    "city": "Osteen"
  },
  {
    "value": "32765",
    "label": "Oviedo - FL 32765",
    "county": "Seminole County",
    "state": "FL",
    "city": "Oviedo"
  },
  {
    "value": "32766",
    "label": "Oviedo - FL 32766",
    "county": "Seminole County",
    "state": "FL",
    "city": "Oviedo"
  },
  {
    "value": "32767",
    "label": "Paisley - FL 32767",
    "county": "Lake County",
    "state": "FL",
    "city": "Paisley"
  },
  {
    "value": "32771",
    "label": "Sanford - FL 32771",
    "county": "Seminole County",
    "state": "FL",
    "city": "Sanford"
  },
  {
    "value": "32773",
    "label": "Sanford - FL 32773",
    "county": "Seminole County",
    "state": "FL",
    "city": "Sanford"
  },
  {
    "value": "32776",
    "label": "Sorrento - FL 32776",
    "county": "Lake County",
    "state": "FL",
    "city": "Sorrento"
  },
  {
    "value": "32778",
    "label": "Tavares - FL 32778",
    "county": "Lake County",
    "state": "FL",
    "city": "Tavares"
  },
  {
    "value": "32779",
    "label": "Longwood - FL 32779",
    "county": "Seminole County",
    "state": "FL",
    "city": "Longwood"
  },
  {
    "value": "32780",
    "label": "Titusville - FL 32780",
    "county": "Brevard County",
    "state": "FL",
    "city": "Titusville"
  },
  {
    "value": "32782",
    "label": "Titusville - FL 32782",
    "county": "Brevard County",
    "state": "FL",
    "city": "Titusville"
  },
  {
    "value": "32784",
    "label": "Umatilla - FL 32784",
    "county": "Lake County",
    "state": "FL",
    "city": "Umatilla"
  },
  {
    "value": "32789",
    "label": "Winter Park - FL 32789",
    "county": "Orange County",
    "state": "FL",
    "city": "Winter Park"
  },
  {
    "value": "32792",
    "label": "Winter Park - FL 32792",
    "county": "Orange County",
    "state": "FL",
    "city": "Winter Park"
  },
  {
    "value": "32796",
    "label": "Titusville - FL 32796",
    "county": "Brevard County",
    "state": "FL",
    "city": "Titusville"
  },
  {
    "value": "32798",
    "label": "Zellwood - FL 32798",
    "county": "Orange County",
    "state": "FL",
    "city": "Zellwood"
  },
  {
    "value": "32801",
    "label": "Orlando - FL 32801",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32803",
    "label": "Orlando - FL 32803",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32804",
    "label": "Orlando - FL 32804",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32805",
    "label": "Orlando - FL 32805",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32806",
    "label": "Orlando - FL 32806",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32807",
    "label": "Orlando - FL 32807",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32808",
    "label": "Orlando - FL 32808",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32809",
    "label": "Orlando - FL 32809",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32810",
    "label": "Orlando - FL 32810",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32811",
    "label": "Orlando - FL 32811",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32812",
    "label": "Orlando - FL 32812",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32814",
    "label": "Orlando - FL 32814",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32817",
    "label": "Orlando - FL 32817",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32818",
    "label": "Orlando - FL 32818",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32819",
    "label": "Orlando - FL 32819",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32820",
    "label": "Orlando - FL 32820",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32821",
    "label": "Orlando - FL 32821",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32822",
    "label": "Orlando - FL 32822",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32824",
    "label": "Orlando - FL 32824",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32825",
    "label": "Orlando - FL 32825",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32826",
    "label": "Orlando - FL 32826",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32827",
    "label": "Orlando - FL 32827",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32828",
    "label": "Orlando - FL 32828",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32829",
    "label": "Orlando - FL 32829",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32831",
    "label": "Orlando - FL 32831",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32832",
    "label": "Orlando - FL 32832",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32833",
    "label": "Orlando - FL 32833",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32834",
    "label": "Orlando - FL 32834",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32835",
    "label": "Orlando - FL 32835",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32836",
    "label": "Orlando - FL 32836",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32837",
    "label": "Orlando - FL 32837",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32839",
    "label": "Orlando - FL 32839",
    "county": "Orange County",
    "state": "FL",
    "city": "Orlando"
  },
  {
    "value": "32901",
    "label": "Melbourne - FL 32901",
    "county": "Brevard County",
    "state": "FL",
    "city": "Melbourne"
  },
  {
    "value": "32903",
    "label": "Indialantic - FL 32903",
    "county": "Brevard County",
    "state": "FL",
    "city": "Indialantic"
  },
  {
    "value": "32904",
    "label": "Melbourne - FL 32904",
    "county": "Brevard County",
    "state": "FL",
    "city": "Melbourne"
  },
  {
    "value": "32905",
    "label": "Palm Bay - FL 32905",
    "county": "Brevard County",
    "state": "FL",
    "city": "Palm Bay"
  },
  {
    "value": "32907",
    "label": "Palm Bay - FL 32907",
    "county": "Brevard County",
    "state": "FL",
    "city": "Palm Bay"
  },
  {
    "value": "32908",
    "label": "Palm Bay - FL 32908",
    "county": "Brevard County",
    "state": "FL",
    "city": "Palm Bay"
  },
  {
    "value": "32909",
    "label": "Palm Bay - FL 32909",
    "county": "Brevard County",
    "state": "FL",
    "city": "Palm Bay"
  },
  {
    "value": "32920",
    "label": "Cape Canaveral - FL 32920",
    "county": "Brevard County",
    "state": "FL",
    "city": "Cape Canaveral"
  },
  {
    "value": "32922",
    "label": "Cocoa - FL 32922",
    "county": "Brevard County",
    "state": "FL",
    "city": "Cocoa"
  },
  {
    "value": "32925",
    "label": "Patrick AFB - FL 32925",
    "county": "Brevard County",
    "state": "FL",
    "city": "Patrick AFB"
  },
  {
    "value": "32926",
    "label": "Cocoa - FL 32926",
    "county": "Brevard County",
    "state": "FL",
    "city": "Cocoa"
  },
  {
    "value": "32927",
    "label": "Cocoa - FL 32927",
    "county": "Brevard County",
    "state": "FL",
    "city": "Cocoa"
  },
  {
    "value": "32931",
    "label": "Cocoa Beach - FL 32931",
    "county": "Brevard County",
    "state": "FL",
    "city": "Cocoa Beach"
  },
  {
    "value": "32934",
    "label": "Melbourne - FL 32934",
    "county": "Brevard County",
    "state": "FL",
    "city": "Melbourne"
  },
  {
    "value": "32935",
    "label": "Melbourne - FL 32935",
    "county": "Brevard County",
    "state": "FL",
    "city": "Melbourne"
  },
  {
    "value": "32937",
    "label": "Satellite Beach - FL 32937",
    "county": "Brevard County",
    "state": "FL",
    "city": "Satellite Beach"
  },
  {
    "value": "32940",
    "label": "Melbourne - FL 32940",
    "county": "Brevard County",
    "state": "FL",
    "city": "Melbourne"
  },
  {
    "value": "32948",
    "label": "Fellsmere - FL 32948",
    "county": "Indian River County",
    "state": "FL",
    "city": "Fellsmere"
  },
  {
    "value": "32949",
    "label": "Grant - FL 32949",
    "county": "Brevard County",
    "state": "FL",
    "city": "Grant"
  },
  {
    "value": "32950",
    "label": "Malabar - FL 32950",
    "county": "Brevard County",
    "state": "FL",
    "city": "Malabar"
  },
  {
    "value": "32951",
    "label": "Melbourne Beach - FL 32951",
    "county": "Brevard County",
    "state": "FL",
    "city": "Melbourne Beach"
  },
  {
    "value": "32952",
    "label": "Merritt Island - FL 32952",
    "county": "Brevard County",
    "state": "FL",
    "city": "Merritt Island"
  },
  {
    "value": "32953",
    "label": "Merritt Island - FL 32953",
    "county": "Brevard County",
    "state": "FL",
    "city": "Merritt Island"
  },
  {
    "value": "32955",
    "label": "Rockledge - FL 32955",
    "county": "Brevard County",
    "state": "FL",
    "city": "Rockledge"
  },
  {
    "value": "32958",
    "label": "Sebastian - FL 32958",
    "county": "Indian River County",
    "state": "FL",
    "city": "Sebastian"
  },
  {
    "value": "32960",
    "label": "Vero Beach - FL 32960",
    "county": "Indian River County",
    "state": "FL",
    "city": "Vero Beach"
  },
  {
    "value": "32962",
    "label": "Vero Beach - FL 32962",
    "county": "Indian River County",
    "state": "FL",
    "city": "Vero Beach"
  },
  {
    "value": "32963",
    "label": "Vero Beach - FL 32963",
    "county": "Indian River County",
    "state": "FL",
    "city": "Vero Beach"
  },
  {
    "value": "32966",
    "label": "Vero Beach - FL 32966",
    "county": "Indian River County",
    "state": "FL",
    "city": "Vero Beach"
  },
  {
    "value": "32967",
    "label": "Vero Beach - FL 32967",
    "county": "Indian River County",
    "state": "FL",
    "city": "Vero Beach"
  },
  {
    "value": "32968",
    "label": "Vero Beach - FL 32968",
    "county": "Indian River County",
    "state": "FL",
    "city": "Vero Beach"
  },
  {
    "value": "32976",
    "label": "Sebastian - FL 32976",
    "county": "Brevard County",
    "state": "FL",
    "city": "Sebastian"
  },
  {
    "value": "33004",
    "label": "Dania - FL 33004",
    "county": "Broward County",
    "state": "FL",
    "city": "Dania"
  },
  {
    "value": "33009",
    "label": "Hallandale - FL 33009",
    "county": "Broward County",
    "state": "FL",
    "city": "Hallandale"
  },
  {
    "value": "33010",
    "label": "Hialeah - FL 33010",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Hialeah"
  },
  {
    "value": "33012",
    "label": "Hialeah - FL 33012",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Hialeah"
  },
  {
    "value": "33013",
    "label": "Hialeah - FL 33013",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Hialeah"
  },
  {
    "value": "33014",
    "label": "Hialeah - FL 33014",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Hialeah"
  },
  {
    "value": "33015",
    "label": "Hialeah - FL 33015",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Hialeah"
  },
  {
    "value": "33016",
    "label": "Hialeah - FL 33016",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Hialeah"
  },
  {
    "value": "33018",
    "label": "Hialeah - FL 33018",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Hialeah"
  },
  {
    "value": "33019",
    "label": "Hollywood - FL 33019",
    "county": "Broward County",
    "state": "FL",
    "city": "Hollywood"
  },
  {
    "value": "33020",
    "label": "Hollywood - FL 33020",
    "county": "Broward County",
    "state": "FL",
    "city": "Hollywood"
  },
  {
    "value": "33021",
    "label": "Hollywood - FL 33021",
    "county": "Broward County",
    "state": "FL",
    "city": "Hollywood"
  },
  {
    "value": "33023",
    "label": "Hollywood - FL 33023",
    "county": "Broward County",
    "state": "FL",
    "city": "Hollywood"
  },
  {
    "value": "33024",
    "label": "Hollywood - FL 33024",
    "county": "Broward County",
    "state": "FL",
    "city": "Hollywood"
  },
  {
    "value": "33025",
    "label": "Hollywood - FL 33025",
    "county": "Broward County",
    "state": "FL",
    "city": "Hollywood"
  },
  {
    "value": "33026",
    "label": "Hollywood - FL 33026",
    "county": "Broward County",
    "state": "FL",
    "city": "Hollywood"
  },
  {
    "value": "33027",
    "label": "Hollywood - FL 33027",
    "county": "Broward County",
    "state": "FL",
    "city": "Hollywood"
  },
  {
    "value": "33028",
    "label": "Pembroke Pines - FL 33028",
    "county": "Broward County",
    "state": "FL",
    "city": "Pembroke Pines"
  },
  {
    "value": "33029",
    "label": "Hollywood - FL 33029",
    "county": "Broward County",
    "state": "FL",
    "city": "Hollywood"
  },
  {
    "value": "33030",
    "label": "Homestead - FL 33030",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Homestead"
  },
  {
    "value": "33031",
    "label": "Homestead - FL 33031",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Homestead"
  },
  {
    "value": "33032",
    "label": "Homestead - FL 33032",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Homestead"
  },
  {
    "value": "33033",
    "label": "Homestead - FL 33033",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Homestead"
  },
  {
    "value": "33034",
    "label": "Homestead - FL 33034",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Homestead"
  },
  {
    "value": "33035",
    "label": "Homestead - FL 33035",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Homestead"
  },
  {
    "value": "33036",
    "label": "Islamorada - FL 33036",
    "county": "Monroe County",
    "state": "FL",
    "city": "Islamorada"
  },
  {
    "value": "33037",
    "label": "Key Largo - FL 33037",
    "county": "Monroe County",
    "state": "FL",
    "city": "Key Largo"
  },
  {
    "value": "33040",
    "label": "Key West - FL 33040",
    "county": "Monroe County",
    "state": "FL",
    "city": "Key West"
  },
  {
    "value": "33042",
    "label": "Summerland Key - FL 33042",
    "county": "Monroe County",
    "state": "FL",
    "city": "Summerland Key"
  },
  {
    "value": "33043",
    "label": "Big Pine Key - FL 33043",
    "county": "Monroe County",
    "state": "FL",
    "city": "Big Pine Key"
  },
  {
    "value": "33050",
    "label": "Marathon - FL 33050",
    "county": "Monroe County",
    "state": "FL",
    "city": "Marathon"
  },
  {
    "value": "33054",
    "label": "Opa Locka - FL 33054",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Opa Locka"
  },
  {
    "value": "33055",
    "label": "Opa Locka - FL 33055",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Opa Locka"
  },
  {
    "value": "33056",
    "label": "Miami Gardens - FL 33056",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami Gardens"
  },
  {
    "value": "33060",
    "label": "Pompano Beach - FL 33060",
    "county": "Broward County",
    "state": "FL",
    "city": "Pompano Beach"
  },
  {
    "value": "33062",
    "label": "Pompano Beach - FL 33062",
    "county": "Broward County",
    "state": "FL",
    "city": "Pompano Beach"
  },
  {
    "value": "33063",
    "label": "Pompano Beach - FL 33063",
    "county": "Broward County",
    "state": "FL",
    "city": "Pompano Beach"
  },
  {
    "value": "33064",
    "label": "Pompano Beach - FL 33064",
    "county": "Broward County",
    "state": "FL",
    "city": "Pompano Beach"
  },
  {
    "value": "33065",
    "label": "Coral Springs - FL 33065",
    "county": "Broward County",
    "state": "FL",
    "city": "Coral Springs"
  },
  {
    "value": "33066",
    "label": "Pompano Beach - FL 33066",
    "county": "Broward County",
    "state": "FL",
    "city": "Pompano Beach"
  },
  {
    "value": "33067",
    "label": "Pompano Beach - FL 33067",
    "county": "Broward County",
    "state": "FL",
    "city": "Pompano Beach"
  },
  {
    "value": "33068",
    "label": "Pompano Beach - FL 33068",
    "county": "Broward County",
    "state": "FL",
    "city": "Pompano Beach"
  },
  {
    "value": "33069",
    "label": "Pompano Beach - FL 33069",
    "county": "Broward County",
    "state": "FL",
    "city": "Pompano Beach"
  },
  {
    "value": "33070",
    "label": "Tavernier - FL 33070",
    "county": "Monroe County",
    "state": "FL",
    "city": "Tavernier"
  },
  {
    "value": "33071",
    "label": "Coral Springs - FL 33071",
    "county": "Broward County",
    "state": "FL",
    "city": "Coral Springs"
  },
  {
    "value": "33073",
    "label": "Pompano Beach - FL 33073",
    "county": "Broward County",
    "state": "FL",
    "city": "Pompano Beach"
  },
  {
    "value": "33076",
    "label": "Pompano Beach - FL 33076",
    "county": "Broward County",
    "state": "FL",
    "city": "Pompano Beach"
  },
  {
    "value": "33109",
    "label": "Miami Beach - FL 33109",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami Beach"
  },
  {
    "value": "33122",
    "label": "Miami - FL 33122",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33125",
    "label": "Miami - FL 33125",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33126",
    "label": "Miami - FL 33126",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33127",
    "label": "Miami - FL 33127",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33128",
    "label": "Miami - FL 33128",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33129",
    "label": "Miami - FL 33129",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33130",
    "label": "Miami - FL 33130",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33131",
    "label": "Miami - FL 33131",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33132",
    "label": "Miami - FL 33132",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33133",
    "label": "Miami - FL 33133",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33134",
    "label": "Miami - FL 33134",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33135",
    "label": "Miami - FL 33135",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33136",
    "label": "Miami - FL 33136",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33137",
    "label": "Miami - FL 33137",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33138",
    "label": "Miami - FL 33138",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33139",
    "label": "Miami Beach - FL 33139",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami Beach"
  },
  {
    "value": "33140",
    "label": "Miami Beach - FL 33140",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami Beach"
  },
  {
    "value": "33141",
    "label": "Miami Beach - FL 33141",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami Beach"
  },
  {
    "value": "33142",
    "label": "Miami - FL 33142",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33143",
    "label": "Miami - FL 33143",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33144",
    "label": "Miami - FL 33144",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33145",
    "label": "Miami - FL 33145",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33146",
    "label": "Miami - FL 33146",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33147",
    "label": "Miami - FL 33147",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33149",
    "label": "Key Biscayne - FL 33149",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Key Biscayne"
  },
  {
    "value": "33150",
    "label": "Miami - FL 33150",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33154",
    "label": "Miami Beach - FL 33154",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami Beach"
  },
  {
    "value": "33155",
    "label": "Miami - FL 33155",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33156",
    "label": "Miami - FL 33156",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33157",
    "label": "Miami - FL 33157",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33158",
    "label": "Miami - FL 33158",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33160",
    "label": "North Miami Beach - FL 33160",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "North Miami Beach"
  },
  {
    "value": "33161",
    "label": "Miami - FL 33161",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33162",
    "label": "Miami - FL 33162",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33165",
    "label": "Miami - FL 33165",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33166",
    "label": "Miami - FL 33166",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33167",
    "label": "Miami - FL 33167",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33168",
    "label": "Miami - FL 33168",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33169",
    "label": "Miami - FL 33169",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33170",
    "label": "Miami - FL 33170",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33172",
    "label": "Miami - FL 33172",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33173",
    "label": "Miami - FL 33173",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33174",
    "label": "Miami - FL 33174",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33175",
    "label": "Miami - FL 33175",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33176",
    "label": "Miami - FL 33176",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33177",
    "label": "Miami - FL 33177",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33178",
    "label": "Miami - FL 33178",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33179",
    "label": "Miami - FL 33179",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33180",
    "label": "Miami - FL 33180",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33181",
    "label": "Miami - FL 33181",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33182",
    "label": "Miami - FL 33182",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33183",
    "label": "Miami - FL 33183",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33184",
    "label": "Miami - FL 33184",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33185",
    "label": "Miami - FL 33185",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33186",
    "label": "Miami - FL 33186",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33187",
    "label": "Miami - FL 33187",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33189",
    "label": "Miami - FL 33189",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33190",
    "label": "Miami - FL 33190",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33193",
    "label": "Miami - FL 33193",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33194",
    "label": "Miami - FL 33194",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33195",
    "label": "Miami - FL 33195",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33196",
    "label": "Miami - FL 33196",
    "county": "Miami-Dade County",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33198",
    "label": "Miami - FL 33198",
    "county": "",
    "state": "FL",
    "city": "Miami"
  },
  {
    "value": "33301",
    "label": "Fort Lauderdale - FL 33301",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33304",
    "label": "Fort Lauderdale - FL 33304",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33305",
    "label": "Fort Lauderdale - FL 33305",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33306",
    "label": "Fort Lauderdale - FL 33306",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33308",
    "label": "Fort Lauderdale - FL 33308",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33309",
    "label": "Fort Lauderdale - FL 33309",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33311",
    "label": "Fort Lauderdale - FL 33311",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33312",
    "label": "Fort Lauderdale - FL 33312",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33313",
    "label": "Fort Lauderdale - FL 33313",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33314",
    "label": "Fort Lauderdale - FL 33314",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33315",
    "label": "Fort Lauderdale - FL 33315",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33316",
    "label": "Fort Lauderdale - FL 33316",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33317",
    "label": "Fort Lauderdale - FL 33317",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33319",
    "label": "Fort Lauderdale - FL 33319",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33321",
    "label": "Fort Lauderdale - FL 33321",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33322",
    "label": "Fort Lauderdale - FL 33322",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33323",
    "label": "Fort Lauderdale - FL 33323",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33324",
    "label": "Fort Lauderdale - FL 33324",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33325",
    "label": "Fort Lauderdale - FL 33325",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33326",
    "label": "Fort Lauderdale - FL 33326",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33327",
    "label": "Fort Lauderdale - FL 33327",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33328",
    "label": "Fort Lauderdale - FL 33328",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33330",
    "label": "Fort Lauderdale - FL 33330",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33331",
    "label": "Fort Lauderdale - FL 33331",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33332",
    "label": "Fort Lauderdale - FL 33332",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33334",
    "label": "Fort Lauderdale - FL 33334",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33351",
    "label": "Fort Lauderdale - FL 33351",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33388",
    "label": "Plantation - FL 33388",
    "county": "Broward County",
    "state": "FL",
    "city": "Plantation"
  },
  {
    "value": "33394",
    "label": "Fort Lauderdale - FL 33394",
    "county": "Broward County",
    "state": "FL",
    "city": "Fort Lauderdale"
  },
  {
    "value": "33401",
    "label": "West Palm Beach - FL 33401",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "West Palm Beach"
  },
  {
    "value": "33403",
    "label": "West Palm Beach - FL 33403",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "West Palm Beach"
  },
  {
    "value": "33404",
    "label": "West Palm Beach - FL 33404",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "West Palm Beach"
  },
  {
    "value": "33405",
    "label": "West Palm Beach - FL 33405",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "West Palm Beach"
  },
  {
    "value": "33406",
    "label": "West Palm Beach - FL 33406",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "West Palm Beach"
  },
  {
    "value": "33407",
    "label": "West Palm Beach - FL 33407",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "West Palm Beach"
  },
  {
    "value": "33408",
    "label": "North Palm Beach - FL 33408",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "North Palm Beach"
  },
  {
    "value": "33409",
    "label": "West Palm Beach - FL 33409",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "West Palm Beach"
  },
  {
    "value": "33410",
    "label": "Palm Beach Gardens - FL 33410",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Palm Beach Gardens"
  },
  {
    "value": "33411",
    "label": "West Palm Beach - FL 33411",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "West Palm Beach"
  },
  {
    "value": "33412",
    "label": "West Palm Beach - FL 33412",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "West Palm Beach"
  },
  {
    "value": "33413",
    "label": "West Palm Beach - FL 33413",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "West Palm Beach"
  },
  {
    "value": "33414",
    "label": "Wellington - FL 33414",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Wellington"
  },
  {
    "value": "33415",
    "label": "West Palm Beach - FL 33415",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "West Palm Beach"
  },
  {
    "value": "33417",
    "label": "West Palm Beach - FL 33417",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "West Palm Beach"
  },
  {
    "value": "33418",
    "label": "Palm Beach Gardens - FL 33418",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Palm Beach Gardens"
  },
  {
    "value": "33426",
    "label": "Boynton Beach - FL 33426",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Boynton Beach"
  },
  {
    "value": "33428",
    "label": "Boca Raton - FL 33428",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Boca Raton"
  },
  {
    "value": "33430",
    "label": "Belle Glade - FL 33430",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Belle Glade"
  },
  {
    "value": "33431",
    "label": "Boca Raton - FL 33431",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Boca Raton"
  },
  {
    "value": "33432",
    "label": "Boca Raton - FL 33432",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Boca Raton"
  },
  {
    "value": "33433",
    "label": "Boca Raton - FL 33433",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Boca Raton"
  },
  {
    "value": "33434",
    "label": "Boca Raton - FL 33434",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Boca Raton"
  },
  {
    "value": "33435",
    "label": "Boynton Beach - FL 33435",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Boynton Beach"
  },
  {
    "value": "33436",
    "label": "Boynton Beach - FL 33436",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Boynton Beach"
  },
  {
    "value": "33437",
    "label": "Boynton Beach - FL 33437",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Boynton Beach"
  },
  {
    "value": "33438",
    "label": "Canal Point - FL 33438",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Canal Point"
  },
  {
    "value": "33439",
    "label": "Canal Point - FL 33439",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Canal Point"
  },
  {
    "value": "33440",
    "label": "Clewiston - FL 33440",
    "county": "Hendry County",
    "state": "FL",
    "city": "Clewiston"
  },
  {
    "value": "33441",
    "label": "Deerfield Beach - FL 33441",
    "county": "Broward County",
    "state": "FL",
    "city": "Deerfield Beach"
  },
  {
    "value": "33442",
    "label": "Deerfield Beach - FL 33442",
    "county": "Broward County",
    "state": "FL",
    "city": "Deerfield Beach"
  },
  {
    "value": "33444",
    "label": "Delray Beach - FL 33444",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Delray Beach"
  },
  {
    "value": "33445",
    "label": "Delray Beach - FL 33445",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Delray Beach"
  },
  {
    "value": "33446",
    "label": "Delray Beach - FL 33446",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Delray Beach"
  },
  {
    "value": "33447",
    "label": "Delray Beach - FL 33447",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Delray Beach"
  },
  {
    "value": "33449",
    "label": "Lake Worth - FL 33449",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Lake Worth"
  },
  {
    "value": "33455",
    "label": "Hobe Sound - FL 33455",
    "county": "Martin County",
    "state": "FL",
    "city": "Hobe Sound"
  },
  {
    "value": "33458",
    "label": "Jupiter - FL 33458",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Jupiter"
  },
  {
    "value": "33460",
    "label": "Lake Worth Beach - FL 33460",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Lake Worth Beach"
  },
  {
    "value": "33461",
    "label": "Lake Worth - FL 33461",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Lake Worth"
  },
  {
    "value": "33462",
    "label": "Lake Worth - FL 33462",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Lake Worth"
  },
  {
    "value": "33463",
    "label": "Lake Worth - FL 33463",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Lake Worth"
  },
  {
    "value": "33467",
    "label": "Lake Worth - FL 33467",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Lake Worth"
  },
  {
    "value": "33469",
    "label": "Jupiter - FL 33469",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Jupiter"
  },
  {
    "value": "33470",
    "label": "Loxahatchee - FL 33470",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Loxahatchee"
  },
  {
    "value": "33471",
    "label": "Moore Haven - FL 33471",
    "county": "Glades County",
    "state": "FL",
    "city": "Moore Haven"
  },
  {
    "value": "33472",
    "label": "Boynton Beach - FL 33472",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Boynton Beach"
  },
  {
    "value": "33473",
    "label": "Boynton Beach - FL 33473",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Boynton Beach"
  },
  {
    "value": "33476",
    "label": "Pahokee - FL 33476",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Pahokee"
  },
  {
    "value": "33477",
    "label": "Jupiter - FL 33477",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Jupiter"
  },
  {
    "value": "33478",
    "label": "Jupiter - FL 33478",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Jupiter"
  },
  {
    "value": "33480",
    "label": "Palm Beach - FL 33480",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Palm Beach"
  },
  {
    "value": "33483",
    "label": "Delray Beach - FL 33483",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Delray Beach"
  },
  {
    "value": "33484",
    "label": "Delray Beach - FL 33484",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Delray Beach"
  },
  {
    "value": "33486",
    "label": "Boca Raton - FL 33486",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Boca Raton"
  },
  {
    "value": "33487",
    "label": "Boca Raton - FL 33487",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Boca Raton"
  },
  {
    "value": "33493",
    "label": "South Bay - FL 33493",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "South Bay"
  },
  {
    "value": "33496",
    "label": "Boca Raton - FL 33496",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Boca Raton"
  },
  {
    "value": "33498",
    "label": "Boca Raton - FL 33498",
    "county": "Palm Beach County",
    "state": "FL",
    "city": "Boca Raton"
  },
  {
    "value": "33510",
    "label": "Brandon - FL 33510",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Brandon"
  },
  {
    "value": "33511",
    "label": "Brandon - FL 33511",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Brandon"
  },
  {
    "value": "33513",
    "label": "Bushnell - FL 33513",
    "county": "Sumter County",
    "state": "FL",
    "city": "Bushnell"
  },
  {
    "value": "33514",
    "label": "Center Hill - FL 33514",
    "county": "Sumter County",
    "state": "FL",
    "city": "Center Hill"
  },
  {
    "value": "33523",
    "label": "Dade City - FL 33523",
    "county": "Pasco County",
    "state": "FL",
    "city": "Dade City"
  },
  {
    "value": "33525",
    "label": "Dade City - FL 33525",
    "county": "Pasco County",
    "state": "FL",
    "city": "Dade City"
  },
  {
    "value": "33527",
    "label": "Dover - FL 33527",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Dover"
  },
  {
    "value": "33534",
    "label": "Gibsonton - FL 33534",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Gibsonton"
  },
  {
    "value": "33538",
    "label": "Lake Panasoffkee - FL 33538",
    "county": "Sumter County",
    "state": "FL",
    "city": "Lake Panasoffkee"
  },
  {
    "value": "33540",
    "label": "Zephyrhills - FL 33540",
    "county": "Pasco County",
    "state": "FL",
    "city": "Zephyrhills"
  },
  {
    "value": "33541",
    "label": "Zephyrhills - FL 33541",
    "county": "Pasco County",
    "state": "FL",
    "city": "Zephyrhills"
  },
  {
    "value": "33542",
    "label": "Zephyrhills - FL 33542",
    "county": "Pasco County",
    "state": "FL",
    "city": "Zephyrhills"
  },
  {
    "value": "33543",
    "label": "Wesley Chapel - FL 33543",
    "county": "Pasco County",
    "state": "FL",
    "city": "Wesley Chapel"
  },
  {
    "value": "33544",
    "label": "Wesley Chapel - FL 33544",
    "county": "Pasco County",
    "state": "FL",
    "city": "Wesley Chapel"
  },
  {
    "value": "33545",
    "label": "Wesley Chapel - FL 33545",
    "county": "Pasco County",
    "state": "FL",
    "city": "Wesley Chapel"
  },
  {
    "value": "33547",
    "label": "Lithia - FL 33547",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Lithia"
  },
  {
    "value": "33548",
    "label": "Lutz - FL 33548",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Lutz"
  },
  {
    "value": "33549",
    "label": "Lutz - FL 33549",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Lutz"
  },
  {
    "value": "33556",
    "label": "Odessa - FL 33556",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Odessa"
  },
  {
    "value": "33558",
    "label": "Lutz - FL 33558",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Lutz"
  },
  {
    "value": "33559",
    "label": "Lutz - FL 33559",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Lutz"
  },
  {
    "value": "33563",
    "label": "Plant City - FL 33563",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Plant City"
  },
  {
    "value": "33565",
    "label": "Plant City - FL 33565",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Plant City"
  },
  {
    "value": "33566",
    "label": "Plant City - FL 33566",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Plant City"
  },
  {
    "value": "33567",
    "label": "Plant City - FL 33567",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Plant City"
  },
  {
    "value": "33569",
    "label": "Riverview - FL 33569",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Riverview"
  },
  {
    "value": "33570",
    "label": "Ruskin - FL 33570",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Ruskin"
  },
  {
    "value": "33572",
    "label": "Apollo Beach - FL 33572",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Apollo Beach"
  },
  {
    "value": "33573",
    "label": "Sun City Center - FL 33573",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Sun City Center"
  },
  {
    "value": "33576",
    "label": "San Antonio - FL 33576",
    "county": "Pasco County",
    "state": "FL",
    "city": "San Antonio"
  },
  {
    "value": "33578",
    "label": "Riverview - FL 33578",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Riverview"
  },
  {
    "value": "33579",
    "label": "Riverview - FL 33579",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Riverview"
  },
  {
    "value": "33584",
    "label": "Seffner - FL 33584",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Seffner"
  },
  {
    "value": "33585",
    "label": "Sumterville - FL 33585",
    "county": "Sumter County",
    "state": "FL",
    "city": "Sumterville"
  },
  {
    "value": "33592",
    "label": "Thonotosassa - FL 33592",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Thonotosassa"
  },
  {
    "value": "33594",
    "label": "Valrico - FL 33594",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Valrico"
  },
  {
    "value": "33596",
    "label": "Valrico - FL 33596",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Valrico"
  },
  {
    "value": "33597",
    "label": "Webster - FL 33597",
    "county": "Sumter County",
    "state": "FL",
    "city": "Webster"
  },
  {
    "value": "33598",
    "label": "Wimauma - FL 33598",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Wimauma"
  },
  {
    "value": "33602",
    "label": "Tampa - FL 33602",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Tampa"
  },
  {
    "value": "33603",
    "label": "Tampa - FL 33603",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Tampa"
  },
  {
    "value": "33604",
    "label": "Tampa - FL 33604",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Tampa"
  },
  {
    "value": "33605",
    "label": "Tampa - FL 33605",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Tampa"
  },
  {
    "value": "33606",
    "label": "Tampa - FL 33606",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Tampa"
  },
  {
    "value": "33607",
    "label": "Tampa - FL 33607",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Tampa"
  },
  {
    "value": "33609",
    "label": "Tampa - FL 33609",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Tampa"
  },
  {
    "value": "33610",
    "label": "Tampa - FL 33610",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Tampa"
  },
  {
    "value": "33611",
    "label": "Tampa - FL 33611",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Tampa"
  },
  {
    "value": "33612",
    "label": "Tampa - FL 33612",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Tampa"
  },
  {
    "value": "33613",
    "label": "Tampa - FL 33613",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Tampa"
  },
  {
    "value": "33614",
    "label": "Tampa - FL 33614",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Tampa"
  },
  {
    "value": "33615",
    "label": "Tampa - FL 33615",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Tampa"
  },
  {
    "value": "33616",
    "label": "Tampa - FL 33616",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Tampa"
  },
  {
    "value": "33617",
    "label": "Tampa - FL 33617",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Tampa"
  },
  {
    "value": "33618",
    "label": "Tampa - FL 33618",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Tampa"
  },
  {
    "value": "33619",
    "label": "Tampa - FL 33619",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Tampa"
  },
  {
    "value": "33621",
    "label": "Tampa - FL 33621",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Tampa"
  },
  {
    "value": "33624",
    "label": "Tampa - FL 33624",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Tampa"
  },
  {
    "value": "33625",
    "label": "Tampa - FL 33625",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Tampa"
  },
  {
    "value": "33626",
    "label": "Tampa - FL 33626",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Tampa"
  },
  {
    "value": "33629",
    "label": "Tampa - FL 33629",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Tampa"
  },
  {
    "value": "33634",
    "label": "Tampa - FL 33634",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Tampa"
  },
  {
    "value": "33635",
    "label": "Tampa - FL 33635",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Tampa"
  },
  {
    "value": "33637",
    "label": "Tampa - FL 33637",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Tampa"
  },
  {
    "value": "33647",
    "label": "Tampa - FL 33647",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Tampa"
  },
  {
    "value": "33690",
    "label": "Tampa - FL 33690",
    "county": "Hillsborough County",
    "state": "FL",
    "city": "Tampa"
  },
  {
    "value": "33701",
    "label": "Saint Petersburg - FL 33701",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Saint Petersburg"
  },
  {
    "value": "33702",
    "label": "Saint Petersburg - FL 33702",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Saint Petersburg"
  },
  {
    "value": "33703",
    "label": "Saint Petersburg - FL 33703",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Saint Petersburg"
  },
  {
    "value": "33704",
    "label": "Saint Petersburg - FL 33704",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Saint Petersburg"
  },
  {
    "value": "33705",
    "label": "Saint Petersburg - FL 33705",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Saint Petersburg"
  },
  {
    "value": "33706",
    "label": "Saint Petersburg - FL 33706",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Saint Petersburg"
  },
  {
    "value": "33707",
    "label": "Saint Petersburg - FL 33707",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Saint Petersburg"
  },
  {
    "value": "33708",
    "label": "Saint Petersburg - FL 33708",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Saint Petersburg"
  },
  {
    "value": "33709",
    "label": "Saint Petersburg - FL 33709",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Saint Petersburg"
  },
  {
    "value": "33710",
    "label": "Saint Petersburg - FL 33710",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Saint Petersburg"
  },
  {
    "value": "33711",
    "label": "Saint Petersburg - FL 33711",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Saint Petersburg"
  },
  {
    "value": "33712",
    "label": "Saint Petersburg - FL 33712",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Saint Petersburg"
  },
  {
    "value": "33713",
    "label": "Saint Petersburg - FL 33713",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Saint Petersburg"
  },
  {
    "value": "33714",
    "label": "Saint Petersburg - FL 33714",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Saint Petersburg"
  },
  {
    "value": "33715",
    "label": "Saint Petersburg - FL 33715",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Saint Petersburg"
  },
  {
    "value": "33716",
    "label": "Saint Petersburg - FL 33716",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Saint Petersburg"
  },
  {
    "value": "33730",
    "label": "Saint Petersburg - FL 33730",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Saint Petersburg"
  },
  {
    "value": "33755",
    "label": "Clearwater - FL 33755",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Clearwater"
  },
  {
    "value": "33756",
    "label": "Clearwater - FL 33756",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Clearwater"
  },
  {
    "value": "33759",
    "label": "Clearwater - FL 33759",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Clearwater"
  },
  {
    "value": "33760",
    "label": "Clearwater - FL 33760",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Clearwater"
  },
  {
    "value": "33761",
    "label": "Clearwater - FL 33761",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Clearwater"
  },
  {
    "value": "33762",
    "label": "Clearwater - FL 33762",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Clearwater"
  },
  {
    "value": "33763",
    "label": "Clearwater - FL 33763",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Clearwater"
  },
  {
    "value": "33764",
    "label": "Clearwater - FL 33764",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Clearwater"
  },
  {
    "value": "33765",
    "label": "Clearwater - FL 33765",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Clearwater"
  },
  {
    "value": "33767",
    "label": "Clearwater Beach - FL 33767",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Clearwater Beach"
  },
  {
    "value": "33770",
    "label": "Largo - FL 33770",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Largo"
  },
  {
    "value": "33771",
    "label": "Largo - FL 33771",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Largo"
  },
  {
    "value": "33772",
    "label": "Seminole - FL 33772",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Seminole"
  },
  {
    "value": "33773",
    "label": "Largo - FL 33773",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Largo"
  },
  {
    "value": "33774",
    "label": "Largo - FL 33774",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Largo"
  },
  {
    "value": "33776",
    "label": "Seminole - FL 33776",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Seminole"
  },
  {
    "value": "33777",
    "label": "Seminole - FL 33777",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Seminole"
  },
  {
    "value": "33778",
    "label": "Largo - FL 33778",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Largo"
  },
  {
    "value": "33781",
    "label": "Pinellas Park - FL 33781",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Pinellas Park"
  },
  {
    "value": "33782",
    "label": "Pinellas Park - FL 33782",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Pinellas Park"
  },
  {
    "value": "33785",
    "label": "Indian Rocks Beach - FL 33785",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Indian Rocks Beach"
  },
  {
    "value": "33786",
    "label": "Belleair Beach - FL 33786",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Belleair Beach"
  },
  {
    "value": "33801",
    "label": "Lakeland - FL 33801",
    "county": "Polk County",
    "state": "FL",
    "city": "Lakeland"
  },
  {
    "value": "33803",
    "label": "Lakeland - FL 33803",
    "county": "Polk County",
    "state": "FL",
    "city": "Lakeland"
  },
  {
    "value": "33805",
    "label": "Lakeland - FL 33805",
    "county": "Polk County",
    "state": "FL",
    "city": "Lakeland"
  },
  {
    "value": "33809",
    "label": "Lakeland - FL 33809",
    "county": "Polk County",
    "state": "FL",
    "city": "Lakeland"
  },
  {
    "value": "33810",
    "label": "Lakeland - FL 33810",
    "county": "Polk County",
    "state": "FL",
    "city": "Lakeland"
  },
  {
    "value": "33811",
    "label": "Lakeland - FL 33811",
    "county": "Polk County",
    "state": "FL",
    "city": "Lakeland"
  },
  {
    "value": "33812",
    "label": "Lakeland - FL 33812",
    "county": "Polk County",
    "state": "FL",
    "city": "Lakeland"
  },
  {
    "value": "33813",
    "label": "Lakeland - FL 33813",
    "county": "Polk County",
    "state": "FL",
    "city": "Lakeland"
  },
  {
    "value": "33815",
    "label": "Lakeland - FL 33815",
    "county": "Polk County",
    "state": "FL",
    "city": "Lakeland"
  },
  {
    "value": "33823",
    "label": "Auburndale - FL 33823",
    "county": "Polk County",
    "state": "FL",
    "city": "Auburndale"
  },
  {
    "value": "33825",
    "label": "Avon Park - FL 33825",
    "county": "Highlands County",
    "state": "FL",
    "city": "Avon Park"
  },
  {
    "value": "33827",
    "label": "Babson Park - FL 33827",
    "county": "Polk County",
    "state": "FL",
    "city": "Babson Park"
  },
  {
    "value": "33830",
    "label": "Bartow - FL 33830",
    "county": "Polk County",
    "state": "FL",
    "city": "Bartow"
  },
  {
    "value": "33834",
    "label": "Bowling Green - FL 33834",
    "county": "Hardee County",
    "state": "FL",
    "city": "Bowling Green"
  },
  {
    "value": "33837",
    "label": "Davenport - FL 33837",
    "county": "Polk County",
    "state": "FL",
    "city": "Davenport"
  },
  {
    "value": "33838",
    "label": "Dundee - FL 33838",
    "county": "Polk County",
    "state": "FL",
    "city": "Dundee"
  },
  {
    "value": "33839",
    "label": "Eagle Lake - FL 33839",
    "county": "Polk County",
    "state": "FL",
    "city": "Eagle Lake"
  },
  {
    "value": "33841",
    "label": "Fort Meade - FL 33841",
    "county": "Polk County",
    "state": "FL",
    "city": "Fort Meade"
  },
  {
    "value": "33843",
    "label": "Frostproof - FL 33843",
    "county": "Polk County",
    "state": "FL",
    "city": "Frostproof"
  },
  {
    "value": "33844",
    "label": "Haines City - FL 33844",
    "county": "Polk County",
    "state": "FL",
    "city": "Haines City"
  },
  {
    "value": "33849",
    "label": "Kathleen - FL 33849",
    "county": "Polk County",
    "state": "FL",
    "city": "Kathleen"
  },
  {
    "value": "33850",
    "label": "Lake Alfred - FL 33850",
    "county": "Polk County",
    "state": "FL",
    "city": "Lake Alfred"
  },
  {
    "value": "33852",
    "label": "Lake Placid - FL 33852",
    "county": "Highlands County",
    "state": "FL",
    "city": "Lake Placid"
  },
  {
    "value": "33853",
    "label": "Lake Wales - FL 33853",
    "county": "Polk County",
    "state": "FL",
    "city": "Lake Wales"
  },
  {
    "value": "33857",
    "label": "Lorida - FL 33857",
    "county": "Highlands County",
    "state": "FL",
    "city": "Lorida"
  },
  {
    "value": "33859",
    "label": "Lake Wales - FL 33859",
    "county": "Polk County",
    "state": "FL",
    "city": "Lake Wales"
  },
  {
    "value": "33860",
    "label": "Mulberry - FL 33860",
    "county": "Polk County",
    "state": "FL",
    "city": "Mulberry"
  },
  {
    "value": "33865",
    "label": "Ona - FL 33865",
    "county": "Hardee County",
    "state": "FL",
    "city": "Ona"
  },
  {
    "value": "33867",
    "label": "River Ranch - FL 33867",
    "county": "Polk County",
    "state": "FL",
    "city": "River Ranch"
  },
  {
    "value": "33868",
    "label": "Polk City - FL 33868",
    "county": "Polk County",
    "state": "FL",
    "city": "Polk City"
  },
  {
    "value": "33870",
    "label": "Sebring - FL 33870",
    "county": "Highlands County",
    "state": "FL",
    "city": "Sebring"
  },
  {
    "value": "33872",
    "label": "Sebring - FL 33872",
    "county": "Highlands County",
    "state": "FL",
    "city": "Sebring"
  },
  {
    "value": "33873",
    "label": "Wauchula - FL 33873",
    "county": "Hardee County",
    "state": "FL",
    "city": "Wauchula"
  },
  {
    "value": "33875",
    "label": "Sebring - FL 33875",
    "county": "Highlands County",
    "state": "FL",
    "city": "Sebring"
  },
  {
    "value": "33876",
    "label": "Sebring - FL 33876",
    "county": "Highlands County",
    "state": "FL",
    "city": "Sebring"
  },
  {
    "value": "33880",
    "label": "Winter Haven - FL 33880",
    "county": "Polk County",
    "state": "FL",
    "city": "Winter Haven"
  },
  {
    "value": "33881",
    "label": "Winter Haven - FL 33881",
    "county": "Polk County",
    "state": "FL",
    "city": "Winter Haven"
  },
  {
    "value": "33884",
    "label": "Winter Haven - FL 33884",
    "county": "Polk County",
    "state": "FL",
    "city": "Winter Haven"
  },
  {
    "value": "33890",
    "label": "Zolfo Springs - FL 33890",
    "county": "Hardee County",
    "state": "FL",
    "city": "Zolfo Springs"
  },
  {
    "value": "33896",
    "label": "Davenport - FL 33896",
    "county": "Polk County",
    "state": "FL",
    "city": "Davenport"
  },
  {
    "value": "33897",
    "label": "Davenport - FL 33897",
    "county": "Polk County",
    "state": "FL",
    "city": "Davenport"
  },
  {
    "value": "33898",
    "label": "Lake Wales - FL 33898",
    "county": "Polk County",
    "state": "FL",
    "city": "Lake Wales"
  },
  {
    "value": "33900",
    "label": "Fort Myers - FL 33900",
    "county": "Lee County",
    "state": "FL",
    "city": "Fort Myers"
  },
  {
    "value": "33901",
    "label": "Fort Myers - FL 33901",
    "county": "Lee County",
    "state": "FL",
    "city": "Fort Myers"
  },
  {
    "value": "33903",
    "label": "North Fort Myers - FL 33903",
    "county": "Lee County",
    "state": "FL",
    "city": "North Fort Myers"
  },
  {
    "value": "33904",
    "label": "Cape Coral - FL 33904",
    "county": "Lee County",
    "state": "FL",
    "city": "Cape Coral"
  },
  {
    "value": "33905",
    "label": "Fort Myers - FL 33905",
    "county": "Lee County",
    "state": "FL",
    "city": "Fort Myers"
  },
  {
    "value": "33907",
    "label": "Fort Myers - FL 33907",
    "county": "Lee County",
    "state": "FL",
    "city": "Fort Myers"
  },
  {
    "value": "33908",
    "label": "Fort Myers - FL 33908",
    "county": "Lee County",
    "state": "FL",
    "city": "Fort Myers"
  },
  {
    "value": "33909",
    "label": "Cape Coral - FL 33909",
    "county": "Lee County",
    "state": "FL",
    "city": "Cape Coral"
  },
  {
    "value": "33912",
    "label": "Fort Myers - FL 33912",
    "county": "Lee County",
    "state": "FL",
    "city": "Fort Myers"
  },
  {
    "value": "33913",
    "label": "Fort Myers - FL 33913",
    "county": "Lee County",
    "state": "FL",
    "city": "Fort Myers"
  },
  {
    "value": "33914",
    "label": "Cape Coral - FL 33914",
    "county": "Lee County",
    "state": "FL",
    "city": "Cape Coral"
  },
  {
    "value": "33916",
    "label": "Fort Myers - FL 33916",
    "county": "Lee County",
    "state": "FL",
    "city": "Fort Myers"
  },
  {
    "value": "33917",
    "label": "North Fort Myers - FL 33917",
    "county": "Lee County",
    "state": "FL",
    "city": "North Fort Myers"
  },
  {
    "value": "33919",
    "label": "Fort Myers - FL 33919",
    "county": "Lee County",
    "state": "FL",
    "city": "Fort Myers"
  },
  {
    "value": "33920",
    "label": "Alva - FL 33920",
    "county": "Lee County",
    "state": "FL",
    "city": "Alva"
  },
  {
    "value": "33922",
    "label": "Bokeelia - FL 33922",
    "county": "Lee County",
    "state": "FL",
    "city": "Bokeelia"
  },
  {
    "value": "33928",
    "label": "Estero - FL 33928",
    "county": "Lee County",
    "state": "FL",
    "city": "Estero"
  },
  {
    "value": "33931",
    "label": "Fort Myers Beach - FL 33931",
    "county": "Lee County",
    "state": "FL",
    "city": "Fort Myers Beach"
  },
  {
    "value": "33935",
    "label": "Labelle - FL 33935",
    "county": "Hendry County",
    "state": "FL",
    "city": "Labelle"
  },
  {
    "value": "33936",
    "label": "Lehigh Acres - FL 33936",
    "county": "Lee County",
    "state": "FL",
    "city": "Lehigh Acres"
  },
  {
    "value": "33946",
    "label": "Placida - FL 33946",
    "county": "Charlotte County",
    "state": "FL",
    "city": "Placida"
  },
  {
    "value": "33947",
    "label": "Rotonda West - FL 33947",
    "county": "Charlotte County",
    "state": "FL",
    "city": "Rotonda West"
  },
  {
    "value": "33948",
    "label": "Port Charlotte - FL 33948",
    "county": "Charlotte County",
    "state": "FL",
    "city": "Port Charlotte"
  },
  {
    "value": "33950",
    "label": "Punta Gorda - FL 33950",
    "county": "Charlotte County",
    "state": "FL",
    "city": "Punta Gorda"
  },
  {
    "value": "33952",
    "label": "Port Charlotte - FL 33952",
    "county": "Charlotte County",
    "state": "FL",
    "city": "Port Charlotte"
  },
  {
    "value": "33953",
    "label": "Port Charlotte - FL 33953",
    "county": "Charlotte County",
    "state": "FL",
    "city": "Port Charlotte"
  },
  {
    "value": "33954",
    "label": "Port Charlotte - FL 33954",
    "county": "Charlotte County",
    "state": "FL",
    "city": "Port Charlotte"
  },
  {
    "value": "33955",
    "label": "Punta Gorda - FL 33955",
    "county": "Charlotte County",
    "state": "FL",
    "city": "Punta Gorda"
  },
  {
    "value": "33956",
    "label": "Saint James City - FL 33956",
    "county": "Lee County",
    "state": "FL",
    "city": "Saint James City"
  },
  {
    "value": "33957",
    "label": "Sanibel - FL 33957",
    "county": "Lee County",
    "state": "FL",
    "city": "Sanibel"
  },
  {
    "value": "33960",
    "label": "Venus - FL 33960",
    "county": "Highlands County",
    "state": "FL",
    "city": "Venus"
  },
  {
    "value": "33966",
    "label": "Fort Myers - FL 33966",
    "county": "Lee County",
    "state": "FL",
    "city": "Fort Myers"
  },
  {
    "value": "33967",
    "label": "Fort Myers - FL 33967",
    "county": "Lee County",
    "state": "FL",
    "city": "Fort Myers"
  },
  {
    "value": "33971",
    "label": "Lehigh Acres - FL 33971",
    "county": "Lee County",
    "state": "FL",
    "city": "Lehigh Acres"
  },
  {
    "value": "33972",
    "label": "Lehigh Acres - FL 33972",
    "county": "Lee County",
    "state": "FL",
    "city": "Lehigh Acres"
  },
  {
    "value": "33973",
    "label": "Lehigh Acres - FL 33973",
    "county": "Lee County",
    "state": "FL",
    "city": "Lehigh Acres"
  },
  {
    "value": "33974",
    "label": "Lehigh Acres - FL 33974",
    "county": "Lee County",
    "state": "FL",
    "city": "Lehigh Acres"
  },
  {
    "value": "33976",
    "label": "Lehigh Acres - FL 33976",
    "county": "Lee County",
    "state": "FL",
    "city": "Lehigh Acres"
  },
  {
    "value": "33980",
    "label": "Punta Gorda - FL 33980",
    "county": "Charlotte County",
    "state": "FL",
    "city": "Punta Gorda"
  },
  {
    "value": "33981",
    "label": "Port Charlotte - FL 33981",
    "county": "Charlotte County",
    "state": "FL",
    "city": "Port Charlotte"
  },
  {
    "value": "33982",
    "label": "Punta Gorda - FL 33982",
    "county": "Charlotte County",
    "state": "FL",
    "city": "Punta Gorda"
  },
  {
    "value": "33983",
    "label": "Punta Gorda - FL 33983",
    "county": "Charlotte County",
    "state": "FL",
    "city": "Punta Gorda"
  },
  {
    "value": "33990",
    "label": "Cape Coral - FL 33990",
    "county": "Lee County",
    "state": "FL",
    "city": "Cape Coral"
  },
  {
    "value": "33991",
    "label": "Cape Coral - FL 33991",
    "county": "Lee County",
    "state": "FL",
    "city": "Cape Coral"
  },
  {
    "value": "33993",
    "label": "Cape Coral - FL 33993",
    "county": "Lee County",
    "state": "FL",
    "city": "Cape Coral"
  },
  {
    "value": "34102",
    "label": "Naples - FL 34102",
    "county": "Collier County",
    "state": "FL",
    "city": "Naples"
  },
  {
    "value": "34103",
    "label": "Naples - FL 34103",
    "county": "Collier County",
    "state": "FL",
    "city": "Naples"
  },
  {
    "value": "34104",
    "label": "Naples - FL 34104",
    "county": "Collier County",
    "state": "FL",
    "city": "Naples"
  },
  {
    "value": "34105",
    "label": "Naples - FL 34105",
    "county": "Collier County",
    "state": "FL",
    "city": "Naples"
  },
  {
    "value": "34108",
    "label": "Naples - FL 34108",
    "county": "Collier County",
    "state": "FL",
    "city": "Naples"
  },
  {
    "value": "34109",
    "label": "Naples - FL 34109",
    "county": "Collier County",
    "state": "FL",
    "city": "Naples"
  },
  {
    "value": "34110",
    "label": "Naples - FL 34110",
    "county": "Collier County",
    "state": "FL",
    "city": "Naples"
  },
  {
    "value": "34112",
    "label": "Naples - FL 34112",
    "county": "Collier County",
    "state": "FL",
    "city": "Naples"
  },
  {
    "value": "34113",
    "label": "Naples - FL 34113",
    "county": "Collier County",
    "state": "FL",
    "city": "Naples"
  },
  {
    "value": "34114",
    "label": "Naples - FL 34114",
    "county": "Collier County",
    "state": "FL",
    "city": "Naples"
  },
  {
    "value": "34116",
    "label": "Naples - FL 34116",
    "county": "Collier County",
    "state": "FL",
    "city": "Naples"
  },
  {
    "value": "34117",
    "label": "Naples - FL 34117",
    "county": "Collier County",
    "state": "FL",
    "city": "Naples"
  },
  {
    "value": "34119",
    "label": "Naples - FL 34119",
    "county": "Collier County",
    "state": "FL",
    "city": "Naples"
  },
  {
    "value": "34120",
    "label": "Naples - FL 34120",
    "county": "Collier County",
    "state": "FL",
    "city": "Naples"
  },
  {
    "value": "34134",
    "label": "Bonita Springs - FL 34134",
    "county": "Lee County",
    "state": "FL",
    "city": "Bonita Springs"
  },
  {
    "value": "34135",
    "label": "Bonita Springs - FL 34135",
    "county": "Lee County",
    "state": "FL",
    "city": "Bonita Springs"
  },
  {
    "value": "34141",
    "label": "Ochopee - FL 34141",
    "county": "Collier County",
    "state": "FL",
    "city": "Ochopee"
  },
  {
    "value": "34142",
    "label": "Immokalee - FL 34142",
    "county": "Collier County",
    "state": "FL",
    "city": "Immokalee"
  },
  {
    "value": "34145",
    "label": "Marco Island - FL 34145",
    "county": "Collier County",
    "state": "FL",
    "city": "Marco Island"
  },
  {
    "value": "34201",
    "label": "Bradenton - FL 34201",
    "county": "Manatee County",
    "state": "FL",
    "city": "Bradenton"
  },
  {
    "value": "34202",
    "label": "Bradenton - FL 34202",
    "county": "Manatee County",
    "state": "FL",
    "city": "Bradenton"
  },
  {
    "value": "34203",
    "label": "Bradenton - FL 34203",
    "county": "Manatee County",
    "state": "FL",
    "city": "Bradenton"
  },
  {
    "value": "34205",
    "label": "Bradenton - FL 34205",
    "county": "Manatee County",
    "state": "FL",
    "city": "Bradenton"
  },
  {
    "value": "34207",
    "label": "Bradenton - FL 34207",
    "county": "Manatee County",
    "state": "FL",
    "city": "Bradenton"
  },
  {
    "value": "34208",
    "label": "Bradenton - FL 34208",
    "county": "Manatee County",
    "state": "FL",
    "city": "Bradenton"
  },
  {
    "value": "34209",
    "label": "Bradenton - FL 34209",
    "county": "Manatee County",
    "state": "FL",
    "city": "Bradenton"
  },
  {
    "value": "34210",
    "label": "Bradenton - FL 34210",
    "county": "Manatee County",
    "state": "FL",
    "city": "Bradenton"
  },
  {
    "value": "34211",
    "label": "Bradenton - FL 34211",
    "county": "Manatee County",
    "state": "FL",
    "city": "Bradenton"
  },
  {
    "value": "34212",
    "label": "Bradenton - FL 34212",
    "county": "Manatee County",
    "state": "FL",
    "city": "Bradenton"
  },
  {
    "value": "34215",
    "label": "Cortez - FL 34215",
    "county": "Manatee County",
    "state": "FL",
    "city": "Cortez"
  },
  {
    "value": "34217",
    "label": "Bradenton Beach - FL 34217",
    "county": "Manatee County",
    "state": "FL",
    "city": "Bradenton Beach"
  },
  {
    "value": "34219",
    "label": "Parrish - FL 34219",
    "county": "Manatee County",
    "state": "FL",
    "city": "Parrish"
  },
  {
    "value": "34221",
    "label": "Palmetto - FL 34221",
    "county": "Manatee County",
    "state": "FL",
    "city": "Palmetto"
  },
  {
    "value": "34222",
    "label": "Ellenton - FL 34222",
    "county": "Manatee County",
    "state": "FL",
    "city": "Ellenton"
  },
  {
    "value": "34223",
    "label": "Englewood - FL 34223",
    "county": "Sarasota County",
    "state": "FL",
    "city": "Englewood"
  },
  {
    "value": "34224",
    "label": "Englewood - FL 34224",
    "county": "Charlotte County",
    "state": "FL",
    "city": "Englewood"
  },
  {
    "value": "34228",
    "label": "Longboat Key - FL 34228",
    "county": "Sarasota County",
    "state": "FL",
    "city": "Longboat Key"
  },
  {
    "value": "34229",
    "label": "Osprey - FL 34229",
    "county": "Sarasota County",
    "state": "FL",
    "city": "Osprey"
  },
  {
    "value": "34231",
    "label": "Sarasota - FL 34231",
    "county": "Sarasota County",
    "state": "FL",
    "city": "Sarasota"
  },
  {
    "value": "34232",
    "label": "Sarasota - FL 34232",
    "county": "Sarasota County",
    "state": "FL",
    "city": "Sarasota"
  },
  {
    "value": "34233",
    "label": "Sarasota - FL 34233",
    "county": "Sarasota County",
    "state": "FL",
    "city": "Sarasota"
  },
  {
    "value": "34234",
    "label": "Sarasota - FL 34234",
    "county": "Sarasota County",
    "state": "FL",
    "city": "Sarasota"
  },
  {
    "value": "34235",
    "label": "Sarasota - FL 34235",
    "county": "Sarasota County",
    "state": "FL",
    "city": "Sarasota"
  },
  {
    "value": "34236",
    "label": "Sarasota - FL 34236",
    "county": "Sarasota County",
    "state": "FL",
    "city": "Sarasota"
  },
  {
    "value": "34237",
    "label": "Sarasota - FL 34237",
    "county": "Sarasota County",
    "state": "FL",
    "city": "Sarasota"
  },
  {
    "value": "34238",
    "label": "Sarasota - FL 34238",
    "county": "Sarasota County",
    "state": "FL",
    "city": "Sarasota"
  },
  {
    "value": "34239",
    "label": "Sarasota - FL 34239",
    "county": "Sarasota County",
    "state": "FL",
    "city": "Sarasota"
  },
  {
    "value": "34240",
    "label": "Sarasota - FL 34240",
    "county": "Sarasota County",
    "state": "FL",
    "city": "Sarasota"
  },
  {
    "value": "34241",
    "label": "Sarasota - FL 34241",
    "county": "Sarasota County",
    "state": "FL",
    "city": "Sarasota"
  },
  {
    "value": "34242",
    "label": "Sarasota - FL 34242",
    "county": "Sarasota County",
    "state": "FL",
    "city": "Sarasota"
  },
  {
    "value": "34243",
    "label": "Sarasota - FL 34243",
    "county": "Manatee County",
    "state": "FL",
    "city": "Sarasota"
  },
  {
    "value": "34251",
    "label": "Myakka City - FL 34251",
    "county": "Manatee County",
    "state": "FL",
    "city": "Myakka City"
  },
  {
    "value": "34266",
    "label": "Arcadia - FL 34266",
    "county": "DeSoto County",
    "state": "FL",
    "city": "Arcadia"
  },
  {
    "value": "34269",
    "label": "Arcadia - FL 34269",
    "county": "DeSoto County",
    "state": "FL",
    "city": "Arcadia"
  },
  {
    "value": "34275",
    "label": "Nokomis - FL 34275",
    "county": "Sarasota County",
    "state": "FL",
    "city": "Nokomis"
  },
  {
    "value": "34285",
    "label": "Venice - FL 34285",
    "county": "Sarasota County",
    "state": "FL",
    "city": "Venice"
  },
  {
    "value": "34286",
    "label": "North Port - FL 34286",
    "county": "Sarasota County",
    "state": "FL",
    "city": "North Port"
  },
  {
    "value": "34287",
    "label": "North Port - FL 34287",
    "county": "Sarasota County",
    "state": "FL",
    "city": "North Port"
  },
  {
    "value": "34288",
    "label": "North Port - FL 34288",
    "county": "Sarasota County",
    "state": "FL",
    "city": "North Port"
  },
  {
    "value": "34289",
    "label": "North Port - FL 34289",
    "county": "Sarasota County",
    "state": "FL",
    "city": "North Port"
  },
  {
    "value": "34291",
    "label": "North Port - FL 34291",
    "county": "Sarasota County",
    "state": "FL",
    "city": "North Port"
  },
  {
    "value": "34292",
    "label": "Venice - FL 34292",
    "county": "Sarasota County",
    "state": "FL",
    "city": "Venice"
  },
  {
    "value": "34293",
    "label": "Venice - FL 34293",
    "county": "Sarasota County",
    "state": "FL",
    "city": "Venice"
  },
  {
    "value": "34420",
    "label": "Belleview - FL 34420",
    "county": "Marion County",
    "state": "FL",
    "city": "Belleview"
  },
  {
    "value": "34428",
    "label": "Crystal River - FL 34428",
    "county": "Citrus County",
    "state": "FL",
    "city": "Crystal River"
  },
  {
    "value": "34429",
    "label": "Crystal River - FL 34429",
    "county": "Citrus County",
    "state": "FL",
    "city": "Crystal River"
  },
  {
    "value": "34431",
    "label": "Dunnellon - FL 34431",
    "county": "Marion County",
    "state": "FL",
    "city": "Dunnellon"
  },
  {
    "value": "34432",
    "label": "Dunnellon - FL 34432",
    "county": "Marion County",
    "state": "FL",
    "city": "Dunnellon"
  },
  {
    "value": "34433",
    "label": "Dunnellon - FL 34433",
    "county": "Citrus County",
    "state": "FL",
    "city": "Dunnellon"
  },
  {
    "value": "34434",
    "label": "Dunnellon - FL 34434",
    "county": "Citrus County",
    "state": "FL",
    "city": "Dunnellon"
  },
  {
    "value": "34436",
    "label": "Floral City - FL 34436",
    "county": "Citrus County",
    "state": "FL",
    "city": "Floral City"
  },
  {
    "value": "34442",
    "label": "Hernando - FL 34442",
    "county": "Citrus County",
    "state": "FL",
    "city": "Hernando"
  },
  {
    "value": "34446",
    "label": "Homosassa - FL 34446",
    "county": "Citrus County",
    "state": "FL",
    "city": "Homosassa"
  },
  {
    "value": "34448",
    "label": "Homosassa - FL 34448",
    "county": "Citrus County",
    "state": "FL",
    "city": "Homosassa"
  },
  {
    "value": "34449",
    "label": "Inglis - FL 34449",
    "county": "Levy County",
    "state": "FL",
    "city": "Inglis"
  },
  {
    "value": "34450",
    "label": "Inverness - FL 34450",
    "county": "Citrus County",
    "state": "FL",
    "city": "Inverness"
  },
  {
    "value": "34452",
    "label": "Inverness - FL 34452",
    "county": "Citrus County",
    "state": "FL",
    "city": "Inverness"
  },
  {
    "value": "34453",
    "label": "Inverness - FL 34453",
    "county": "Citrus County",
    "state": "FL",
    "city": "Inverness"
  },
  {
    "value": "34461",
    "label": "Lecanto - FL 34461",
    "county": "Citrus County",
    "state": "FL",
    "city": "Lecanto"
  },
  {
    "value": "34465",
    "label": "Beverly Hills - FL 34465",
    "county": "Citrus County",
    "state": "FL",
    "city": "Beverly Hills"
  },
  {
    "value": "34470",
    "label": "Ocala - FL 34470",
    "county": "Marion County",
    "state": "FL",
    "city": "Ocala"
  },
  {
    "value": "34471",
    "label": "Ocala - FL 34471",
    "county": "Marion County",
    "state": "FL",
    "city": "Ocala"
  },
  {
    "value": "34472",
    "label": "Ocala - FL 34472",
    "county": "Marion County",
    "state": "FL",
    "city": "Ocala"
  },
  {
    "value": "34473",
    "label": "Ocala - FL 34473",
    "county": "Marion County",
    "state": "FL",
    "city": "Ocala"
  },
  {
    "value": "34474",
    "label": "Ocala - FL 34474",
    "county": "Marion County",
    "state": "FL",
    "city": "Ocala"
  },
  {
    "value": "34475",
    "label": "Ocala - FL 34475",
    "county": "Marion County",
    "state": "FL",
    "city": "Ocala"
  },
  {
    "value": "34476",
    "label": "Ocala - FL 34476",
    "county": "Marion County",
    "state": "FL",
    "city": "Ocala"
  },
  {
    "value": "34479",
    "label": "Ocala - FL 34479",
    "county": "Marion County",
    "state": "FL",
    "city": "Ocala"
  },
  {
    "value": "34480",
    "label": "Ocala - FL 34480",
    "county": "Marion County",
    "state": "FL",
    "city": "Ocala"
  },
  {
    "value": "34481",
    "label": "Ocala - FL 34481",
    "county": "Marion County",
    "state": "FL",
    "city": "Ocala"
  },
  {
    "value": "34482",
    "label": "Ocala - FL 34482",
    "county": "Marion County",
    "state": "FL",
    "city": "Ocala"
  },
  {
    "value": "34484",
    "label": "Oxford - FL 34484",
    "county": "Sumter County",
    "state": "FL",
    "city": "Oxford"
  },
  {
    "value": "34488",
    "label": "Silver Springs - FL 34488",
    "county": "Marion County",
    "state": "FL",
    "city": "Silver Springs"
  },
  {
    "value": "34491",
    "label": "Summerfield - FL 34491",
    "county": "Marion County",
    "state": "FL",
    "city": "Summerfield"
  },
  {
    "value": "34498",
    "label": "Yankeetown - FL 34498",
    "county": "Levy County",
    "state": "FL",
    "city": "Yankeetown"
  },
  {
    "value": "34601",
    "label": "Brooksville - FL 34601",
    "county": "Hernando County",
    "state": "FL",
    "city": "Brooksville"
  },
  {
    "value": "34602",
    "label": "Brooksville - FL 34602",
    "county": "Hernando County",
    "state": "FL",
    "city": "Brooksville"
  },
  {
    "value": "34604",
    "label": "Brooksville - FL 34604",
    "county": "Hernando County",
    "state": "FL",
    "city": "Brooksville"
  },
  {
    "value": "34606",
    "label": "Spring Hill - FL 34606",
    "county": "Hernando County",
    "state": "FL",
    "city": "Spring Hill"
  },
  {
    "value": "34607",
    "label": "Spring Hill - FL 34607",
    "county": "Hernando County",
    "state": "FL",
    "city": "Spring Hill"
  },
  {
    "value": "34608",
    "label": "Spring Hill - FL 34608",
    "county": "Hernando County",
    "state": "FL",
    "city": "Spring Hill"
  },
  {
    "value": "34609",
    "label": "Spring Hill - FL 34609",
    "county": "Hernando County",
    "state": "FL",
    "city": "Spring Hill"
  },
  {
    "value": "34610",
    "label": "Spring Hill - FL 34610",
    "county": "Pasco County",
    "state": "FL",
    "city": "Spring Hill"
  },
  {
    "value": "34613",
    "label": "Brooksville - FL 34613",
    "county": "Hernando County",
    "state": "FL",
    "city": "Brooksville"
  },
  {
    "value": "34614",
    "label": "Brooksville - FL 34614",
    "county": "Hernando County",
    "state": "FL",
    "city": "Brooksville"
  },
  {
    "value": "34637",
    "label": "Land O Lakes - FL 34637",
    "county": "Pasco County",
    "state": "FL",
    "city": "Land O Lakes"
  },
  {
    "value": "34638",
    "label": "Land O Lakes - FL 34638",
    "county": "Pasco County",
    "state": "FL",
    "city": "Land O Lakes"
  },
  {
    "value": "34639",
    "label": "Land O Lakes - FL 34639",
    "county": "Pasco County",
    "state": "FL",
    "city": "Land O Lakes"
  },
  {
    "value": "34652",
    "label": "New Port Richey - FL 34652",
    "county": "Pasco County",
    "state": "FL",
    "city": "New Port Richey"
  },
  {
    "value": "34653",
    "label": "New Port Richey - FL 34653",
    "county": "Pasco County",
    "state": "FL",
    "city": "New Port Richey"
  },
  {
    "value": "34654",
    "label": "New Port Richey - FL 34654",
    "county": "Pasco County",
    "state": "FL",
    "city": "New Port Richey"
  },
  {
    "value": "34655",
    "label": "New Port Richey - FL 34655",
    "county": "Pasco County",
    "state": "FL",
    "city": "New Port Richey"
  },
  {
    "value": "34667",
    "label": "Hudson - FL 34667",
    "county": "Pasco County",
    "state": "FL",
    "city": "Hudson"
  },
  {
    "value": "34668",
    "label": "Port Richey - FL 34668",
    "county": "Pasco County",
    "state": "FL",
    "city": "Port Richey"
  },
  {
    "value": "34669",
    "label": "Hudson - FL 34669",
    "county": "Pasco County",
    "state": "FL",
    "city": "Hudson"
  },
  {
    "value": "34677",
    "label": "Oldsmar - FL 34677",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Oldsmar"
  },
  {
    "value": "34683",
    "label": "Palm Harbor - FL 34683",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Palm Harbor"
  },
  {
    "value": "34684",
    "label": "Palm Harbor - FL 34684",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Palm Harbor"
  },
  {
    "value": "34685",
    "label": "Palm Harbor - FL 34685",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Palm Harbor"
  },
  {
    "value": "34688",
    "label": "Tarpon Springs - FL 34688",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Tarpon Springs"
  },
  {
    "value": "34689",
    "label": "Tarpon Springs - FL 34689",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Tarpon Springs"
  },
  {
    "value": "34690",
    "label": "Holiday - FL 34690",
    "county": "Pasco County",
    "state": "FL",
    "city": "Holiday"
  },
  {
    "value": "34691",
    "label": "Holiday - FL 34691",
    "county": "Pasco County",
    "state": "FL",
    "city": "Holiday"
  },
  {
    "value": "34695",
    "label": "Safety Harbor - FL 34695",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Safety Harbor"
  },
  {
    "value": "34698",
    "label": "Dunedin - FL 34698",
    "county": "Pinellas County",
    "state": "FL",
    "city": "Dunedin"
  },
  {
    "value": "34705",
    "label": "Astatula - FL 34705",
    "county": "Lake County",
    "state": "FL",
    "city": "Astatula"
  },
  {
    "value": "34711",
    "label": "Clermont - FL 34711",
    "county": "Lake County",
    "state": "FL",
    "city": "Clermont"
  },
  {
    "value": "34714",
    "label": "Clermont - FL 34714",
    "county": "Lake County",
    "state": "FL",
    "city": "Clermont"
  },
  {
    "value": "34715",
    "label": "Clermont - FL 34715",
    "county": "Lake County",
    "state": "FL",
    "city": "Clermont"
  },
  {
    "value": "34731",
    "label": "Fruitland Park - FL 34731",
    "county": "Lake County",
    "state": "FL",
    "city": "Fruitland Park"
  },
  {
    "value": "34734",
    "label": "Gotha - FL 34734",
    "county": "Orange County",
    "state": "FL",
    "city": "Gotha"
  },
  {
    "value": "34736",
    "label": "Groveland - FL 34736",
    "county": "Lake County",
    "state": "FL",
    "city": "Groveland"
  },
  {
    "value": "34737",
    "label": "Howey In The Hills - FL 34737",
    "county": "Lake County",
    "state": "FL",
    "city": "Howey In The Hills"
  },
  {
    "value": "34739",
    "label": "Kenansville - FL 34739",
    "county": "Osceola County",
    "state": "FL",
    "city": "Kenansville"
  },
  {
    "value": "34741",
    "label": "Kissimmee - FL 34741",
    "county": "Osceola County",
    "state": "FL",
    "city": "Kissimmee"
  },
  {
    "value": "34743",
    "label": "Kissimmee - FL 34743",
    "county": "Osceola County",
    "state": "FL",
    "city": "Kissimmee"
  },
  {
    "value": "34744",
    "label": "Kissimmee - FL 34744",
    "county": "Osceola County",
    "state": "FL",
    "city": "Kissimmee"
  },
  {
    "value": "34746",
    "label": "Kissimmee - FL 34746",
    "county": "Osceola County",
    "state": "FL",
    "city": "Kissimmee"
  },
  {
    "value": "34747",
    "label": "Kissimmee - FL 34747",
    "county": "Osceola County",
    "state": "FL",
    "city": "Kissimmee"
  },
  {
    "value": "34748",
    "label": "Leesburg - FL 34748",
    "county": "Lake County",
    "state": "FL",
    "city": "Leesburg"
  },
  {
    "value": "34753",
    "label": "Mascotte - FL 34753",
    "county": "Lake County",
    "state": "FL",
    "city": "Mascotte"
  },
  {
    "value": "34756",
    "label": "Montverde - FL 34756",
    "county": "Lake County",
    "state": "FL",
    "city": "Montverde"
  },
  {
    "value": "34758",
    "label": "Kissimmee - FL 34758",
    "county": "Osceola County",
    "state": "FL",
    "city": "Kissimmee"
  },
  {
    "value": "34759",
    "label": "Kissimmee - FL 34759",
    "county": "Polk County",
    "state": "FL",
    "city": "Kissimmee"
  },
  {
    "value": "34761",
    "label": "Ocoee - FL 34761",
    "county": "Orange County",
    "state": "FL",
    "city": "Ocoee"
  },
  {
    "value": "34762",
    "label": "Okahumpka - FL 34762",
    "county": "Lake County",
    "state": "FL",
    "city": "Okahumpka"
  },
  {
    "value": "34769",
    "label": "Saint Cloud - FL 34769",
    "county": "Osceola County",
    "state": "FL",
    "city": "Saint Cloud"
  },
  {
    "value": "34771",
    "label": "Saint Cloud - FL 34771",
    "county": "Osceola County",
    "state": "FL",
    "city": "Saint Cloud"
  },
  {
    "value": "34772",
    "label": "Saint Cloud - FL 34772",
    "county": "Osceola County",
    "state": "FL",
    "city": "Saint Cloud"
  },
  {
    "value": "34773",
    "label": "Saint Cloud - FL 34773",
    "county": "Osceola County",
    "state": "FL",
    "city": "Saint Cloud"
  },
  {
    "value": "34785",
    "label": "Wildwood - FL 34785",
    "county": "Sumter County",
    "state": "FL",
    "city": "Wildwood"
  },
  {
    "value": "34786",
    "label": "Windermere - FL 34786",
    "county": "Orange County",
    "state": "FL",
    "city": "Windermere"
  },
  {
    "value": "34787",
    "label": "Winter Garden - FL 34787",
    "county": "Orange County",
    "state": "FL",
    "city": "Winter Garden"
  },
  {
    "value": "34788",
    "label": "Leesburg - FL 34788",
    "county": "Lake County",
    "state": "FL",
    "city": "Leesburg"
  },
  {
    "value": "34797",
    "label": "Yalaha - FL 34797",
    "county": "Lake County",
    "state": "FL",
    "city": "Yalaha"
  },
  {
    "value": "34945",
    "label": "Fort Pierce - FL 34945",
    "county": "St. Lucie County",
    "state": "FL",
    "city": "Fort Pierce"
  },
  {
    "value": "34946",
    "label": "Fort Pierce - FL 34946",
    "county": "St. Lucie County",
    "state": "FL",
    "city": "Fort Pierce"
  },
  {
    "value": "34947",
    "label": "Fort Pierce - FL 34947",
    "county": "St. Lucie County",
    "state": "FL",
    "city": "Fort Pierce"
  },
  {
    "value": "34949",
    "label": "Fort Pierce - FL 34949",
    "county": "St. Lucie County",
    "state": "FL",
    "city": "Fort Pierce"
  },
  {
    "value": "34950",
    "label": "Fort Pierce - FL 34950",
    "county": "St. Lucie County",
    "state": "FL",
    "city": "Fort Pierce"
  },
  {
    "value": "34951",
    "label": "Fort Pierce - FL 34951",
    "county": "St. Lucie County",
    "state": "FL",
    "city": "Fort Pierce"
  },
  {
    "value": "34952",
    "label": "Port Saint Lucie - FL 34952",
    "county": "St. Lucie County",
    "state": "FL",
    "city": "Port Saint Lucie"
  },
  {
    "value": "34953",
    "label": "Port Saint Lucie - FL 34953",
    "county": "St. Lucie County",
    "state": "FL",
    "city": "Port Saint Lucie"
  },
  {
    "value": "34956",
    "label": "Indiantown - FL 34956",
    "county": "Martin County",
    "state": "FL",
    "city": "Indiantown"
  },
  {
    "value": "34957",
    "label": "Jensen Beach - FL 34957",
    "county": "Martin County",
    "state": "FL",
    "city": "Jensen Beach"
  },
  {
    "value": "34972",
    "label": "Okeechobee - FL 34972",
    "county": "Okeechobee County",
    "state": "FL",
    "city": "Okeechobee"
  },
  {
    "value": "34974",
    "label": "Okeechobee - FL 34974",
    "county": "Okeechobee County",
    "state": "FL",
    "city": "Okeechobee"
  },
  {
    "value": "34981",
    "label": "Fort Pierce - FL 34981",
    "county": "St. Lucie County",
    "state": "FL",
    "city": "Fort Pierce"
  },
  {
    "value": "34982",
    "label": "Fort Pierce - FL 34982",
    "county": "St. Lucie County",
    "state": "FL",
    "city": "Fort Pierce"
  },
  {
    "value": "34983",
    "label": "Port Saint Lucie - FL 34983",
    "county": "St. Lucie County",
    "state": "FL",
    "city": "Port Saint Lucie"
  },
  {
    "value": "34984",
    "label": "Port Saint Lucie - FL 34984",
    "county": "St. Lucie County",
    "state": "FL",
    "city": "Port Saint Lucie"
  },
  {
    "value": "34986",
    "label": "Port Saint Lucie - FL 34986",
    "county": "St. Lucie County",
    "state": "FL",
    "city": "Port Saint Lucie"
  },
  {
    "value": "34987",
    "label": "Port Saint Lucie - FL 34987",
    "county": "St. Lucie County",
    "state": "FL",
    "city": "Port Saint Lucie"
  },
  {
    "value": "34990",
    "label": "Palm City - FL 34990",
    "county": "Martin County",
    "state": "FL",
    "city": "Palm City"
  },
  {
    "value": "34994",
    "label": "Stuart - FL 34994",
    "county": "Martin County",
    "state": "FL",
    "city": "Stuart"
  },
  {
    "value": "34996",
    "label": "Stuart - FL 34996",
    "county": "Martin County",
    "state": "FL",
    "city": "Stuart"
  },
  {
    "value": "34997",
    "label": "Stuart - FL 34997",
    "county": "Martin County",
    "state": "FL",
    "city": "Stuart"
  },
  {
    "value": "30002",
    "label": "Avondale Estates - GA 30002",
    "county": "DeKalb County",
    "state": "GA",
    "city": "Avondale Estates"
  },
  {
    "value": "30004",
    "label": "Alpharetta - GA 30004",
    "county": "Fulton County",
    "state": "GA",
    "city": "Alpharetta"
  },
  {
    "value": "30005",
    "label": "Alpharetta - GA 30005",
    "county": "Fulton County",
    "state": "GA",
    "city": "Alpharetta"
  },
  {
    "value": "30008",
    "label": "Marietta - GA 30008",
    "county": "Cobb County",
    "state": "GA",
    "city": "Marietta"
  },
  {
    "value": "30009",
    "label": "Alpharetta - GA 30009",
    "county": "Fulton County",
    "state": "GA",
    "city": "Alpharetta"
  },
  {
    "value": "30011",
    "label": "Auburn - GA 30011",
    "county": "Barrow County",
    "state": "GA",
    "city": "Auburn"
  },
  {
    "value": "30012",
    "label": "Conyers - GA 30012",
    "county": "Rockdale County",
    "state": "GA",
    "city": "Conyers"
  },
  {
    "value": "30013",
    "label": "Conyers - GA 30013",
    "county": "Rockdale County",
    "state": "GA",
    "city": "Conyers"
  },
  {
    "value": "30014",
    "label": "Covington - GA 30014",
    "county": "Newton County",
    "state": "GA",
    "city": "Covington"
  },
  {
    "value": "30016",
    "label": "Covington - GA 30016",
    "county": "Newton County",
    "state": "GA",
    "city": "Covington"
  },
  {
    "value": "30017",
    "label": "Grayson - GA 30017",
    "county": "Gwinnett County",
    "state": "GA",
    "city": "Grayson"
  },
  {
    "value": "30019",
    "label": "Dacula - GA 30019",
    "county": "Gwinnett County",
    "state": "GA",
    "city": "Dacula"
  },
  {
    "value": "30021",
    "label": "Clarkston - GA 30021",
    "county": "DeKalb County",
    "state": "GA",
    "city": "Clarkston"
  },
  {
    "value": "30022",
    "label": "Alpharetta - GA 30022",
    "county": "Fulton County",
    "state": "GA",
    "city": "Alpharetta"
  },
  {
    "value": "30024",
    "label": "Suwanee - GA 30024",
    "county": "Gwinnett County",
    "state": "GA",
    "city": "Suwanee"
  },
  {
    "value": "30025",
    "label": "Social Circle - GA 30025",
    "county": "Walton County",
    "state": "GA",
    "city": "Social Circle"
  },
  {
    "value": "30028",
    "label": "Cumming - GA 30028",
    "county": "Forsyth County",
    "state": "GA",
    "city": "Cumming"
  },
  {
    "value": "30030",
    "label": "Decatur - GA 30030",
    "county": "DeKalb County",
    "state": "GA",
    "city": "Decatur"
  },
  {
    "value": "30032",
    "label": "Decatur - GA 30032",
    "county": "DeKalb County",
    "state": "GA",
    "city": "Decatur"
  },
  {
    "value": "30033",
    "label": "Decatur - GA 30033",
    "county": "DeKalb County",
    "state": "GA",
    "city": "Decatur"
  },
  {
    "value": "30034",
    "label": "Decatur - GA 30034",
    "county": "DeKalb County",
    "state": "GA",
    "city": "Decatur"
  },
  {
    "value": "30035",
    "label": "Decatur - GA 30035",
    "county": "DeKalb County",
    "state": "GA",
    "city": "Decatur"
  },
  {
    "value": "30038",
    "label": "Lithonia - GA 30038",
    "county": "DeKalb County",
    "state": "GA",
    "city": "Lithonia"
  },
  {
    "value": "30039",
    "label": "Snellville - GA 30039",
    "county": "Gwinnett County",
    "state": "GA",
    "city": "Snellville"
  },
  {
    "value": "30040",
    "label": "Cumming - GA 30040",
    "county": "Forsyth County",
    "state": "GA",
    "city": "Cumming"
  },
  {
    "value": "30041",
    "label": "Cumming - GA 30041",
    "county": "Forsyth County",
    "state": "GA",
    "city": "Cumming"
  },
  {
    "value": "30043",
    "label": "Lawrenceville - GA 30043",
    "county": "Gwinnett County",
    "state": "GA",
    "city": "Lawrenceville"
  },
  {
    "value": "30044",
    "label": "Lawrenceville - GA 30044",
    "county": "Gwinnett County",
    "state": "GA",
    "city": "Lawrenceville"
  },
  {
    "value": "30045",
    "label": "Lawrenceville - GA 30045",
    "county": "Gwinnett County",
    "state": "GA",
    "city": "Lawrenceville"
  },
  {
    "value": "30046",
    "label": "Lawrenceville - GA 30046",
    "county": "Gwinnett County",
    "state": "GA",
    "city": "Lawrenceville"
  },
  {
    "value": "30047",
    "label": "Lilburn - GA 30047",
    "county": "Gwinnett County",
    "state": "GA",
    "city": "Lilburn"
  },
  {
    "value": "30052",
    "label": "Loganville - GA 30052",
    "county": "Walton County",
    "state": "GA",
    "city": "Loganville"
  },
  {
    "value": "30054",
    "label": "Oxford - GA 30054",
    "county": "Newton County",
    "state": "GA",
    "city": "Oxford"
  },
  {
    "value": "30055",
    "label": "Mansfield - GA 30055",
    "county": "Jasper County",
    "state": "GA",
    "city": "Mansfield"
  },
  {
    "value": "30056",
    "label": "Newborn - GA 30056",
    "county": "Jasper County",
    "state": "GA",
    "city": "Newborn"
  },
  {
    "value": "30058",
    "label": "Lithonia - GA 30058",
    "county": "DeKalb County",
    "state": "GA",
    "city": "Lithonia"
  },
  {
    "value": "30060",
    "label": "Marietta - GA 30060",
    "county": "Cobb County",
    "state": "GA",
    "city": "Marietta"
  },
  {
    "value": "30062",
    "label": "Marietta - GA 30062",
    "county": "Cobb County",
    "state": "GA",
    "city": "Marietta"
  },
  {
    "value": "30064",
    "label": "Marietta - GA 30064",
    "county": "Cobb County",
    "state": "GA",
    "city": "Marietta"
  },
  {
    "value": "30066",
    "label": "Marietta - GA 30066",
    "county": "Cobb County",
    "state": "GA",
    "city": "Marietta"
  },
  {
    "value": "30067",
    "label": "Marietta - GA 30067",
    "county": "Cobb County",
    "state": "GA",
    "city": "Marietta"
  },
  {
    "value": "30068",
    "label": "Marietta - GA 30068",
    "county": "Cobb County",
    "state": "GA",
    "city": "Marietta"
  },
  {
    "value": "30071",
    "label": "Norcross - GA 30071",
    "county": "Gwinnett County",
    "state": "GA",
    "city": "Norcross"
  },
  {
    "value": "30073",
    "label": "Decatur - GA 30073",
    "county": "Dekalb County",
    "state": "GA",
    "city": "Decatur"
  },
  {
    "value": "30075",
    "label": "Roswell - GA 30075",
    "county": "Fulton County",
    "state": "GA",
    "city": "Roswell"
  },
  {
    "value": "30076",
    "label": "Roswell - GA 30076",
    "county": "Fulton County",
    "state": "GA",
    "city": "Roswell"
  },
  {
    "value": "30078",
    "label": "Snellville - GA 30078",
    "county": "Gwinnett County",
    "state": "GA",
    "city": "Snellville"
  },
  {
    "value": "30079",
    "label": "Scottdale - GA 30079",
    "county": "DeKalb County",
    "state": "GA",
    "city": "Scottdale"
  },
  {
    "value": "30080",
    "label": "Smyrna - GA 30080",
    "county": "Cobb County",
    "state": "GA",
    "city": "Smyrna"
  },
  {
    "value": "30082",
    "label": "Smyrna - GA 30082",
    "county": "Cobb County",
    "state": "GA",
    "city": "Smyrna"
  },
  {
    "value": "30083",
    "label": "Stone Mountain - GA 30083",
    "county": "DeKalb County",
    "state": "GA",
    "city": "Stone Mountain"
  },
  {
    "value": "30084",
    "label": "Tucker - GA 30084",
    "county": "DeKalb County",
    "state": "GA",
    "city": "Tucker"
  },
  {
    "value": "30087",
    "label": "Stone Mountain - GA 30087",
    "county": "DeKalb County",
    "state": "GA",
    "city": "Stone Mountain"
  },
  {
    "value": "30088",
    "label": "Stone Mountain - GA 30088",
    "county": "DeKalb County",
    "state": "GA",
    "city": "Stone Mountain"
  },
  {
    "value": "30090",
    "label": "Marietta - GA 30090",
    "county": "Cobb County",
    "state": "GA",
    "city": "Marietta"
  },
  {
    "value": "30092",
    "label": "Peachtree Corners - GA 30092",
    "county": "Gwinnett County",
    "state": "GA",
    "city": "Peachtree Corners"
  },
  {
    "value": "30093",
    "label": "Norcross - GA 30093",
    "county": "Gwinnett County",
    "state": "GA",
    "city": "Norcross"
  },
  {
    "value": "30094",
    "label": "Conyers - GA 30094",
    "county": "Rockdale County",
    "state": "GA",
    "city": "Conyers"
  },
  {
    "value": "30096",
    "label": "Duluth - GA 30096",
    "county": "Gwinnett County",
    "state": "GA",
    "city": "Duluth"
  },
  {
    "value": "30097",
    "label": "Duluth - GA 30097",
    "county": "Fulton County",
    "state": "GA",
    "city": "Duluth"
  },
  {
    "value": "30101",
    "label": "Acworth - GA 30101",
    "county": "Cobb County",
    "state": "GA",
    "city": "Acworth"
  },
  {
    "value": "30102",
    "label": "Acworth - GA 30102",
    "county": "Cherokee County",
    "state": "GA",
    "city": "Acworth"
  },
  {
    "value": "30103",
    "label": "Adairsville - GA 30103",
    "county": "Bartow County",
    "state": "GA",
    "city": "Adairsville"
  },
  {
    "value": "30104",
    "label": "Aragon - GA 30104",
    "county": "Polk County",
    "state": "GA",
    "city": "Aragon"
  },
  {
    "value": "30105",
    "label": "Armuchee - GA 30105",
    "county": "Floyd County",
    "state": "GA",
    "city": "Armuchee"
  },
  {
    "value": "30106",
    "label": "Austell - GA 30106",
    "county": "Cobb County",
    "state": "GA",
    "city": "Austell"
  },
  {
    "value": "30107",
    "label": "Ball Ground - GA 30107",
    "county": "Cherokee County",
    "state": "GA",
    "city": "Ball Ground"
  },
  {
    "value": "30108",
    "label": "Bowdon - GA 30108",
    "county": "Carroll County",
    "state": "GA",
    "city": "Bowdon"
  },
  {
    "value": "30110",
    "label": "Bremen - GA 30110",
    "county": "Haralson County",
    "state": "GA",
    "city": "Bremen"
  },
  {
    "value": "30113",
    "label": "Buchanan - GA 30113",
    "county": "Haralson County",
    "state": "GA",
    "city": "Buchanan"
  },
  {
    "value": "30114",
    "label": "Canton - GA 30114",
    "county": "Cherokee County",
    "state": "GA",
    "city": "Canton"
  },
  {
    "value": "30115",
    "label": "Canton - GA 30115",
    "county": "Cherokee County",
    "state": "GA",
    "city": "Canton"
  },
  {
    "value": "30116",
    "label": "Carrollton - GA 30116",
    "county": "Carroll County",
    "state": "GA",
    "city": "Carrollton"
  },
  {
    "value": "30117",
    "label": "Carrollton - GA 30117",
    "county": "Carroll County",
    "state": "GA",
    "city": "Carrollton"
  },
  {
    "value": "30120",
    "label": "Cartersville - GA 30120",
    "county": "Bartow County",
    "state": "GA",
    "city": "Cartersville"
  },
  {
    "value": "30121",
    "label": "Cartersville - GA 30121",
    "county": "Bartow County",
    "state": "GA",
    "city": "Cartersville"
  },
  {
    "value": "30122",
    "label": "Lithia Springs - GA 30122",
    "county": "Douglas County",
    "state": "GA",
    "city": "Lithia Springs"
  },
  {
    "value": "30124",
    "label": "Cave Spring - GA 30124",
    "county": "Floyd County",
    "state": "GA",
    "city": "Cave Spring"
  },
  {
    "value": "30125",
    "label": "Cedartown - GA 30125",
    "county": "Polk County",
    "state": "GA",
    "city": "Cedartown"
  },
  {
    "value": "30126",
    "label": "Mableton - GA 30126",
    "county": "Cobb County",
    "state": "GA",
    "city": "Mableton"
  },
  {
    "value": "30127",
    "label": "Powder Springs - GA 30127",
    "county": "Cobb County",
    "state": "GA",
    "city": "Powder Springs"
  },
  {
    "value": "30132",
    "label": "Dallas - GA 30132",
    "county": "Paulding County",
    "state": "GA",
    "city": "Dallas"
  },
  {
    "value": "30134",
    "label": "Douglasville - GA 30134",
    "county": "Douglas County",
    "state": "GA",
    "city": "Douglasville"
  },
  {
    "value": "30135",
    "label": "Douglasville - GA 30135",
    "county": "Douglas County",
    "state": "GA",
    "city": "Douglasville"
  },
  {
    "value": "30137",
    "label": "Emerson - GA 30137",
    "county": "Bartow County",
    "state": "GA",
    "city": "Emerson"
  },
  {
    "value": "30139",
    "label": "Fairmount - GA 30139",
    "county": "Gordon County",
    "state": "GA",
    "city": "Fairmount"
  },
  {
    "value": "30141",
    "label": "Hiram - GA 30141",
    "county": "Paulding County",
    "state": "GA",
    "city": "Hiram"
  },
  {
    "value": "30143",
    "label": "Jasper - GA 30143",
    "county": "Pickens County",
    "state": "GA",
    "city": "Jasper"
  },
  {
    "value": "30144",
    "label": "Kennesaw - GA 30144",
    "county": "Cobb County",
    "state": "GA",
    "city": "Kennesaw"
  },
  {
    "value": "30145",
    "label": "Kingston - GA 30145",
    "county": "Bartow County",
    "state": "GA",
    "city": "Kingston"
  },
  {
    "value": "30147",
    "label": "Lindale - GA 30147",
    "county": "Floyd County",
    "state": "GA",
    "city": "Lindale"
  },
  {
    "value": "30148",
    "label": "Marble Hill - GA 30148",
    "county": "Pickens County",
    "state": "GA",
    "city": "Marble Hill"
  },
  {
    "value": "30152",
    "label": "Kennesaw - GA 30152",
    "county": "Cobb County",
    "state": "GA",
    "city": "Kennesaw"
  },
  {
    "value": "30153",
    "label": "Rockmart - GA 30153",
    "county": "Polk County",
    "state": "GA",
    "city": "Rockmart"
  },
  {
    "value": "30157",
    "label": "Dallas - GA 30157",
    "county": "Paulding County",
    "state": "GA",
    "city": "Dallas"
  },
  {
    "value": "30161",
    "label": "Rome - GA 30161",
    "county": "Floyd County",
    "state": "GA",
    "city": "Rome"
  },
  {
    "value": "30165",
    "label": "Rome - GA 30165",
    "county": "Floyd County",
    "state": "GA",
    "city": "Rome"
  },
  {
    "value": "30168",
    "label": "Austell - GA 30168",
    "county": "Cobb County",
    "state": "GA",
    "city": "Austell"
  },
  {
    "value": "30170",
    "label": "Roopville - GA 30170",
    "county": "Carroll County",
    "state": "GA",
    "city": "Roopville"
  },
  {
    "value": "30171",
    "label": "Rydal - GA 30171",
    "county": "Bartow County",
    "state": "GA",
    "city": "Rydal"
  },
  {
    "value": "30173",
    "label": "Silver Creek - GA 30173",
    "county": "Floyd County",
    "state": "GA",
    "city": "Silver Creek"
  },
  {
    "value": "30175",
    "label": "Talking Rock - GA 30175",
    "county": "Pickens County",
    "state": "GA",
    "city": "Talking Rock"
  },
  {
    "value": "30176",
    "label": "Tallapoosa - GA 30176",
    "county": "Haralson County",
    "state": "GA",
    "city": "Tallapoosa"
  },
  {
    "value": "30177",
    "label": "Tate - GA 30177",
    "county": "Pickens County",
    "state": "GA",
    "city": "Tate"
  },
  {
    "value": "30178",
    "label": "Taylorsville - GA 30178",
    "county": "Bartow County",
    "state": "GA",
    "city": "Taylorsville"
  },
  {
    "value": "30179",
    "label": "Temple - GA 30179",
    "county": "Carroll County",
    "state": "GA",
    "city": "Temple"
  },
  {
    "value": "30180",
    "label": "Villa Rica - GA 30180",
    "county": "Carroll County",
    "state": "GA",
    "city": "Villa Rica"
  },
  {
    "value": "30182",
    "label": "Waco - GA 30182",
    "county": "Carroll County",
    "state": "GA",
    "city": "Waco"
  },
  {
    "value": "30183",
    "label": "Waleska - GA 30183",
    "county": "Cherokee County",
    "state": "GA",
    "city": "Waleska"
  },
  {
    "value": "30184",
    "label": "White - GA 30184",
    "county": "Bartow County",
    "state": "GA",
    "city": "White"
  },
  {
    "value": "30185",
    "label": "Whitesburg - GA 30185",
    "county": "Carroll County",
    "state": "GA",
    "city": "Whitesburg"
  },
  {
    "value": "30187",
    "label": "Winston - GA 30187",
    "county": "Douglas County",
    "state": "GA",
    "city": "Winston"
  },
  {
    "value": "30188",
    "label": "Woodstock - GA 30188",
    "county": "Cherokee County",
    "state": "GA",
    "city": "Woodstock"
  },
  {
    "value": "30189",
    "label": "Woodstock - GA 30189",
    "county": "Cherokee County",
    "state": "GA",
    "city": "Woodstock"
  },
  {
    "value": "30204",
    "label": "Barnesville - GA 30204",
    "county": "Lamar County",
    "state": "GA",
    "city": "Barnesville"
  },
  {
    "value": "30205",
    "label": "Brooks - GA 30205",
    "county": "Fayette County",
    "state": "GA",
    "city": "Brooks"
  },
  {
    "value": "30206",
    "label": "Concord - GA 30206",
    "county": "Pike County",
    "state": "GA",
    "city": "Concord"
  },
  {
    "value": "30213",
    "label": "Fairburn - GA 30213",
    "county": "Fulton County",
    "state": "GA",
    "city": "Fairburn"
  },
  {
    "value": "30214",
    "label": "Fayetteville - GA 30214",
    "county": "Fayette County",
    "state": "GA",
    "city": "Fayetteville"
  },
  {
    "value": "30215",
    "label": "Fayetteville - GA 30215",
    "county": "Fayette County",
    "state": "GA",
    "city": "Fayetteville"
  },
  {
    "value": "30216",
    "label": "Flovilla - GA 30216",
    "county": "Butts County",
    "state": "GA",
    "city": "Flovilla"
  },
  {
    "value": "30217",
    "label": "Franklin - GA 30217",
    "county": "Heard County",
    "state": "GA",
    "city": "Franklin"
  },
  {
    "value": "30218",
    "label": "Gay - GA 30218",
    "county": "Meriwether County",
    "state": "GA",
    "city": "Gay"
  },
  {
    "value": "30220",
    "label": "Grantville - GA 30220",
    "county": "Coweta County",
    "state": "GA",
    "city": "Grantville"
  },
  {
    "value": "30222",
    "label": "Greenville - GA 30222",
    "county": "Meriwether County",
    "state": "GA",
    "city": "Greenville"
  },
  {
    "value": "30223",
    "label": "Griffin - GA 30223",
    "county": "Spalding County",
    "state": "GA",
    "city": "Griffin"
  },
  {
    "value": "30224",
    "label": "Griffin - GA 30224",
    "county": "Spalding County",
    "state": "GA",
    "city": "Griffin"
  },
  {
    "value": "30228",
    "label": "Hampton - GA 30228",
    "county": "Henry County",
    "state": "GA",
    "city": "Hampton"
  },
  {
    "value": "30230",
    "label": "Hogansville - GA 30230",
    "county": "Troup County",
    "state": "GA",
    "city": "Hogansville"
  },
  {
    "value": "30233",
    "label": "Jackson - GA 30233",
    "county": "Butts County",
    "state": "GA",
    "city": "Jackson"
  },
  {
    "value": "30234",
    "label": "Jenkinsburg - GA 30234",
    "county": "Butts County",
    "state": "GA",
    "city": "Jenkinsburg"
  },
  {
    "value": "30236",
    "label": "Jonesboro - GA 30236",
    "county": "Clayton County",
    "state": "GA",
    "city": "Jonesboro"
  },
  {
    "value": "30238",
    "label": "Jonesboro - GA 30238",
    "county": "Clayton County",
    "state": "GA",
    "city": "Jonesboro"
  },
  {
    "value": "30240",
    "label": "Lagrange - GA 30240",
    "county": "Troup County",
    "state": "GA",
    "city": "Lagrange"
  },
  {
    "value": "30241",
    "label": "Lagrange - GA 30241",
    "county": "Troup County",
    "state": "GA",
    "city": "Lagrange"
  },
  {
    "value": "30248",
    "label": "Locust Grove - GA 30248",
    "county": "Henry County",
    "state": "GA",
    "city": "Locust Grove"
  },
  {
    "value": "30251",
    "label": "Luthersville - GA 30251",
    "county": "Meriwether County",
    "state": "GA",
    "city": "Luthersville"
  },
  {
    "value": "30252",
    "label": "Mcdonough - GA 30252",
    "county": "Henry County",
    "state": "GA",
    "city": "Mcdonough"
  },
  {
    "value": "30253",
    "label": "Mcdonough - GA 30253",
    "county": "Henry County",
    "state": "GA",
    "city": "Mcdonough"
  },
  {
    "value": "30256",
    "label": "Meansville - GA 30256",
    "county": "Pike County",
    "state": "GA",
    "city": "Meansville"
  },
  {
    "value": "30257",
    "label": "Milner - GA 30257",
    "county": "Lamar County",
    "state": "GA",
    "city": "Milner"
  },
  {
    "value": "30258",
    "label": "Molena - GA 30258",
    "county": "Pike County",
    "state": "GA",
    "city": "Molena"
  },
  {
    "value": "30259",
    "label": "Moreland - GA 30259",
    "county": "Coweta County",
    "state": "GA",
    "city": "Moreland"
  },
  {
    "value": "30260",
    "label": "Morrow - GA 30260",
    "county": "Clayton County",
    "state": "GA",
    "city": "Morrow"
  },
  {
    "value": "30263",
    "label": "Newnan - GA 30263",
    "county": "Coweta County",
    "state": "GA",
    "city": "Newnan"
  },
  {
    "value": "30265",
    "label": "Newnan - GA 30265",
    "county": "Coweta County",
    "state": "GA",
    "city": "Newnan"
  },
  {
    "value": "30268",
    "label": "Palmetto - GA 30268",
    "county": "Fulton County",
    "state": "GA",
    "city": "Palmetto"
  },
  {
    "value": "30269",
    "label": "Peachtree City - GA 30269",
    "county": "Fayette County",
    "state": "GA",
    "city": "Peachtree City"
  },
  {
    "value": "30273",
    "label": "Rex - GA 30273",
    "county": "Clayton County",
    "state": "GA",
    "city": "Rex"
  },
  {
    "value": "30274",
    "label": "Riverdale - GA 30274",
    "county": "Clayton County",
    "state": "GA",
    "city": "Riverdale"
  },
  {
    "value": "30276",
    "label": "Senoia - GA 30276",
    "county": "Coweta County",
    "state": "GA",
    "city": "Senoia"
  },
  {
    "value": "30277",
    "label": "Sharpsburg - GA 30277",
    "county": "Coweta County",
    "state": "GA",
    "city": "Sharpsburg"
  },
  {
    "value": "30281",
    "label": "Stockbridge - GA 30281",
    "county": "Henry County",
    "state": "GA",
    "city": "Stockbridge"
  },
  {
    "value": "30285",
    "label": "The Rock - GA 30285",
    "county": "Upson County",
    "state": "GA",
    "city": "The Rock"
  },
  {
    "value": "30286",
    "label": "Thomaston - GA 30286",
    "county": "Upson County",
    "state": "GA",
    "city": "Thomaston"
  },
  {
    "value": "30288",
    "label": "Conley - GA 30288",
    "county": "Clayton County",
    "state": "GA",
    "city": "Conley"
  },
  {
    "value": "30290",
    "label": "Tyrone - GA 30290",
    "county": "Fayette County",
    "state": "GA",
    "city": "Tyrone"
  },
  {
    "value": "30291",
    "label": "Union City - GA 30291",
    "county": "Fulton County",
    "state": "GA",
    "city": "Union City"
  },
  {
    "value": "30292",
    "label": "Williamson - GA 30292",
    "county": "Pike County",
    "state": "GA",
    "city": "Williamson"
  },
  {
    "value": "30293",
    "label": "Woodbury - GA 30293",
    "county": "Meriwether County",
    "state": "GA",
    "city": "Woodbury"
  },
  {
    "value": "30294",
    "label": "Ellenwood - GA 30294",
    "county": "DeKalb County",
    "state": "GA",
    "city": "Ellenwood"
  },
  {
    "value": "30295",
    "label": "Zebulon - GA 30295",
    "county": "Pike County",
    "state": "GA",
    "city": "Zebulon"
  },
  {
    "value": "30296",
    "label": "Riverdale - GA 30296",
    "county": "Clayton County",
    "state": "GA",
    "city": "Riverdale"
  },
  {
    "value": "30297",
    "label": "Forest Park - GA 30297",
    "county": "Clayton County",
    "state": "GA",
    "city": "Forest Park"
  },
  {
    "value": "30303",
    "label": "Atlanta - GA 30303",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30304",
    "label": "Atlanta - GA 30304",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30305",
    "label": "Atlanta - GA 30305",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30306",
    "label": "Atlanta - GA 30306",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30307",
    "label": "Atlanta - GA 30307",
    "county": "DeKalb County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30308",
    "label": "Atlanta - GA 30308",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30309",
    "label": "Atlanta - GA 30309",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30310",
    "label": "Atlanta - GA 30310",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30311",
    "label": "Atlanta - GA 30311",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30312",
    "label": "Atlanta - GA 30312",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30313",
    "label": "Atlanta - GA 30313",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30314",
    "label": "Atlanta - GA 30314",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30315",
    "label": "Atlanta - GA 30315",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30316",
    "label": "Atlanta - GA 30316",
    "county": "DeKalb County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30317",
    "label": "Atlanta - GA 30317",
    "county": "DeKalb County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30318",
    "label": "Atlanta - GA 30318",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30319",
    "label": "Atlanta - GA 30319",
    "county": "DeKalb County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30324",
    "label": "Atlanta - GA 30324",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30326",
    "label": "Atlanta - GA 30326",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30327",
    "label": "Atlanta - GA 30327",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30328",
    "label": "Atlanta - GA 30328",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30329",
    "label": "Atlanta - GA 30329",
    "county": "DeKalb County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30331",
    "label": "Atlanta - GA 30331",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30334",
    "label": "Atlanta - GA 30334",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30336",
    "label": "Atlanta - GA 30336",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30337",
    "label": "Atlanta - GA 30337",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30338",
    "label": "Atlanta - GA 30338",
    "county": "DeKalb County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30339",
    "label": "Atlanta - GA 30339",
    "county": "Cobb County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30340",
    "label": "Atlanta - GA 30340",
    "county": "DeKalb County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30341",
    "label": "Atlanta - GA 30341",
    "county": "DeKalb County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30342",
    "label": "Atlanta - GA 30342",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30344",
    "label": "Atlanta - GA 30344",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30345",
    "label": "Atlanta - GA 30345",
    "county": "DeKalb County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30346",
    "label": "Atlanta - GA 30346",
    "county": "DeKalb County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30347",
    "label": "Atlanta - GA 30347",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30349",
    "label": "Atlanta - GA 30349",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30350",
    "label": "Atlanta - GA 30350",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30354",
    "label": "Atlanta - GA 30354",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30360",
    "label": "Atlanta - GA 30360",
    "county": "DeKalb County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30361",
    "label": "Atlanta - GA 30361",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30363",
    "label": "Atlanta - GA 30363",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30369",
    "label": "Atlanta - GA 30369",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30376",
    "label": "Atlanta - GA 30376",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30379",
    "label": "Atlanta - GA 30379",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "30401",
    "label": "Swainsboro - GA 30401",
    "county": "Emanuel County",
    "state": "GA",
    "city": "Swainsboro"
  },
  {
    "value": "30410",
    "label": "Ailey - GA 30410",
    "county": "Montgomery County",
    "state": "GA",
    "city": "Ailey"
  },
  {
    "value": "30411",
    "label": "Alamo - GA 30411",
    "county": "Wheeler County",
    "state": "GA",
    "city": "Alamo"
  },
  {
    "value": "30413",
    "label": "Bartow - GA 30413",
    "county": "Jefferson County",
    "state": "GA",
    "city": "Bartow"
  },
  {
    "value": "30415",
    "label": "Brooklet - GA 30415",
    "county": "Bulloch County",
    "state": "GA",
    "city": "Brooklet"
  },
  {
    "value": "30417",
    "label": "Claxton - GA 30417",
    "county": "Evans County",
    "state": "GA",
    "city": "Claxton"
  },
  {
    "value": "30420",
    "label": "Cobbtown - GA 30420",
    "county": "Tattnall County",
    "state": "GA",
    "city": "Cobbtown"
  },
  {
    "value": "30421",
    "label": "Collins - GA 30421",
    "county": "Tattnall County",
    "state": "GA",
    "city": "Collins"
  },
  {
    "value": "30425",
    "label": "Garfield - GA 30425",
    "county": "Emanuel County",
    "state": "GA",
    "city": "Garfield"
  },
  {
    "value": "30426",
    "label": "Girard - GA 30426",
    "county": "Burke County",
    "state": "GA",
    "city": "Girard"
  },
  {
    "value": "30427",
    "label": "Glennville - GA 30427",
    "county": "Tattnall County",
    "state": "GA",
    "city": "Glennville"
  },
  {
    "value": "30428",
    "label": "Glenwood - GA 30428",
    "county": "Wheeler County",
    "state": "GA",
    "city": "Glenwood"
  },
  {
    "value": "30434",
    "label": "Louisville - GA 30434",
    "county": "Jefferson County",
    "state": "GA",
    "city": "Louisville"
  },
  {
    "value": "30436",
    "label": "Lyons - GA 30436",
    "county": "Toombs County",
    "state": "GA",
    "city": "Lyons"
  },
  {
    "value": "30439",
    "label": "Metter - GA 30439",
    "county": "Candler County",
    "state": "GA",
    "city": "Metter"
  },
  {
    "value": "30441",
    "label": "Midville - GA 30441",
    "county": "Emanuel County",
    "state": "GA",
    "city": "Midville"
  },
  {
    "value": "30442",
    "label": "Millen - GA 30442",
    "county": "Jenkins County",
    "state": "GA",
    "city": "Millen"
  },
  {
    "value": "30445",
    "label": "Mount Vernon - GA 30445",
    "county": "Montgomery County",
    "state": "GA",
    "city": "Mount Vernon"
  },
  {
    "value": "30446",
    "label": "Newington - GA 30446",
    "county": "Screven County",
    "state": "GA",
    "city": "Newington"
  },
  {
    "value": "30450",
    "label": "Portal - GA 30450",
    "county": "Bulloch County",
    "state": "GA",
    "city": "Portal"
  },
  {
    "value": "30452",
    "label": "Register - GA 30452",
    "county": "Bulloch County",
    "state": "GA",
    "city": "Register"
  },
  {
    "value": "30453",
    "label": "Reidsville - GA 30453",
    "county": "Tattnall County",
    "state": "GA",
    "city": "Reidsville"
  },
  {
    "value": "30454",
    "label": "Rockledge - GA 30454",
    "county": "Laurens County",
    "state": "GA",
    "city": "Rockledge"
  },
  {
    "value": "30455",
    "label": "Rocky Ford - GA 30455",
    "county": "Screven County",
    "state": "GA",
    "city": "Rocky Ford"
  },
  {
    "value": "30456",
    "label": "Sardis - GA 30456",
    "county": "Burke County",
    "state": "GA",
    "city": "Sardis"
  },
  {
    "value": "30457",
    "label": "Soperton - GA 30457",
    "county": "Treutlen County",
    "state": "GA",
    "city": "Soperton"
  },
  {
    "value": "30458",
    "label": "Statesboro - GA 30458",
    "county": "Bulloch County",
    "state": "GA",
    "city": "Statesboro"
  },
  {
    "value": "30461",
    "label": "Statesboro - GA 30461",
    "county": "Bulloch County",
    "state": "GA",
    "city": "Statesboro"
  },
  {
    "value": "30467",
    "label": "Sylvania - GA 30467",
    "county": "Screven County",
    "state": "GA",
    "city": "Sylvania"
  },
  {
    "value": "30470",
    "label": "Tarrytown - GA 30470",
    "county": "Montgomery County",
    "state": "GA",
    "city": "Tarrytown"
  },
  {
    "value": "30471",
    "label": "Twin City - GA 30471",
    "county": "Emanuel County",
    "state": "GA",
    "city": "Twin City"
  },
  {
    "value": "30473",
    "label": "Uvalda - GA 30473",
    "county": "Montgomery County",
    "state": "GA",
    "city": "Uvalda"
  },
  {
    "value": "30474",
    "label": "Vidalia - GA 30474",
    "county": "Toombs County",
    "state": "GA",
    "city": "Vidalia"
  },
  {
    "value": "30477",
    "label": "Wadley - GA 30477",
    "county": "Jefferson County",
    "state": "GA",
    "city": "Wadley"
  },
  {
    "value": "30501",
    "label": "Gainesville - GA 30501",
    "county": "Hall County",
    "state": "GA",
    "city": "Gainesville"
  },
  {
    "value": "30504",
    "label": "Gainesville - GA 30504",
    "county": "Hall County",
    "state": "GA",
    "city": "Gainesville"
  },
  {
    "value": "30506",
    "label": "Gainesville - GA 30506",
    "county": "Hall County",
    "state": "GA",
    "city": "Gainesville"
  },
  {
    "value": "30507",
    "label": "Gainesville - GA 30507",
    "county": "Hall County",
    "state": "GA",
    "city": "Gainesville"
  },
  {
    "value": "30510",
    "label": "Alto - GA 30510",
    "county": "Habersham County",
    "state": "GA",
    "city": "Alto"
  },
  {
    "value": "30511",
    "label": "Baldwin - GA 30511",
    "county": "Banks County",
    "state": "GA",
    "city": "Baldwin"
  },
  {
    "value": "30512",
    "label": "Blairsville - GA 30512",
    "county": "Union County",
    "state": "GA",
    "city": "Blairsville"
  },
  {
    "value": "30513",
    "label": "Blue Ridge - GA 30513",
    "county": "Fannin County",
    "state": "GA",
    "city": "Blue Ridge"
  },
  {
    "value": "30516",
    "label": "Bowersville - GA 30516",
    "county": "Hart County",
    "state": "GA",
    "city": "Bowersville"
  },
  {
    "value": "30517",
    "label": "Braselton - GA 30517",
    "county": "Jackson County",
    "state": "GA",
    "city": "Braselton"
  },
  {
    "value": "30518",
    "label": "Buford - GA 30518",
    "county": "Gwinnett County",
    "state": "GA",
    "city": "Buford"
  },
  {
    "value": "30519",
    "label": "Buford - GA 30519",
    "county": "Gwinnett County",
    "state": "GA",
    "city": "Buford"
  },
  {
    "value": "30520",
    "label": "Canon - GA 30520",
    "county": "Franklin County",
    "state": "GA",
    "city": "Canon"
  },
  {
    "value": "30521",
    "label": "Carnesville - GA 30521",
    "county": "Franklin County",
    "state": "GA",
    "city": "Carnesville"
  },
  {
    "value": "30522",
    "label": "Cherry Log - GA 30522",
    "county": "Gilmer County",
    "state": "GA",
    "city": "Cherry Log"
  },
  {
    "value": "30523",
    "label": "Clarkesville - GA 30523",
    "county": "Habersham County",
    "state": "GA",
    "city": "Clarkesville"
  },
  {
    "value": "30525",
    "label": "Clayton - GA 30525",
    "county": "Rabun County",
    "state": "GA",
    "city": "Clayton"
  },
  {
    "value": "30527",
    "label": "Clermont - GA 30527",
    "county": "Hall County",
    "state": "GA",
    "city": "Clermont"
  },
  {
    "value": "30528",
    "label": "Cleveland - GA 30528",
    "county": "White County",
    "state": "GA",
    "city": "Cleveland"
  },
  {
    "value": "30529",
    "label": "Commerce - GA 30529",
    "county": "Jackson County",
    "state": "GA",
    "city": "Commerce"
  },
  {
    "value": "30530",
    "label": "Commerce - GA 30530",
    "county": "Banks County",
    "state": "GA",
    "city": "Commerce"
  },
  {
    "value": "30531",
    "label": "Cornelia - GA 30531",
    "county": "Habersham County",
    "state": "GA",
    "city": "Cornelia"
  },
  {
    "value": "30533",
    "label": "Dahlonega - GA 30533",
    "county": "Lumpkin County",
    "state": "GA",
    "city": "Dahlonega"
  },
  {
    "value": "30534",
    "label": "Dawsonville - GA 30534",
    "county": "Dawson County",
    "state": "GA",
    "city": "Dawsonville"
  },
  {
    "value": "30535",
    "label": "Demorest - GA 30535",
    "county": "Habersham County",
    "state": "GA",
    "city": "Demorest"
  },
  {
    "value": "30536",
    "label": "Ellijay - GA 30536",
    "county": "Gilmer County",
    "state": "GA",
    "city": "Ellijay"
  },
  {
    "value": "30537",
    "label": "Dillard - GA 30537",
    "county": "Rabun County",
    "state": "GA",
    "city": "Dillard"
  },
  {
    "value": "30538",
    "label": "Eastanollee - GA 30538",
    "county": "Stephens County",
    "state": "GA",
    "city": "Eastanollee"
  },
  {
    "value": "30540",
    "label": "Ellijay - GA 30540",
    "county": "Gilmer County",
    "state": "GA",
    "city": "Ellijay"
  },
  {
    "value": "30541",
    "label": "Epworth - GA 30541",
    "county": "Fannin County",
    "state": "GA",
    "city": "Epworth"
  },
  {
    "value": "30542",
    "label": "Flowery Branch - GA 30542",
    "county": "Hall County",
    "state": "GA",
    "city": "Flowery Branch"
  },
  {
    "value": "30543",
    "label": "Gillsville - GA 30543",
    "county": "Hall County",
    "state": "GA",
    "city": "Gillsville"
  },
  {
    "value": "30545",
    "label": "Helen - GA 30545",
    "county": "White County",
    "state": "GA",
    "city": "Helen"
  },
  {
    "value": "30546",
    "label": "Hiawassee - GA 30546",
    "county": "Towns County",
    "state": "GA",
    "city": "Hiawassee"
  },
  {
    "value": "30547",
    "label": "Homer - GA 30547",
    "county": "Banks County",
    "state": "GA",
    "city": "Homer"
  },
  {
    "value": "30548",
    "label": "Hoschton - GA 30548",
    "county": "Jackson County",
    "state": "GA",
    "city": "Hoschton"
  },
  {
    "value": "30549",
    "label": "Jefferson - GA 30549",
    "county": "Jackson County",
    "state": "GA",
    "city": "Jefferson"
  },
  {
    "value": "30552",
    "label": "Lakemont - GA 30552",
    "county": "Rabun County",
    "state": "GA",
    "city": "Lakemont"
  },
  {
    "value": "30553",
    "label": "Lavonia - GA 30553",
    "county": "Franklin County",
    "state": "GA",
    "city": "Lavonia"
  },
  {
    "value": "30554",
    "label": "Lula - GA 30554",
    "county": "Hall County",
    "state": "GA",
    "city": "Lula"
  },
  {
    "value": "30555",
    "label": "Mc Caysville - GA 30555",
    "county": "Fannin County",
    "state": "GA",
    "city": "Mc Caysville"
  },
  {
    "value": "30557",
    "label": "Martin - GA 30557",
    "county": "Stephens County",
    "state": "GA",
    "city": "Martin"
  },
  {
    "value": "30558",
    "label": "Maysville - GA 30558",
    "county": "Jackson County",
    "state": "GA",
    "city": "Maysville"
  },
  {
    "value": "30559",
    "label": "Mineral Bluff - GA 30559",
    "county": "Fannin County",
    "state": "GA",
    "city": "Mineral Bluff"
  },
  {
    "value": "30560",
    "label": "Morganton - GA 30560",
    "county": "Fannin County",
    "state": "GA",
    "city": "Morganton"
  },
  {
    "value": "30563",
    "label": "Mount Airy - GA 30563",
    "county": "Habersham County",
    "state": "GA",
    "city": "Mount Airy"
  },
  {
    "value": "30564",
    "label": "Murrayville - GA 30564",
    "county": "Hall County",
    "state": "GA",
    "city": "Murrayville"
  },
  {
    "value": "30565",
    "label": "Nicholson - GA 30565",
    "county": "Jackson County",
    "state": "GA",
    "city": "Nicholson"
  },
  {
    "value": "30566",
    "label": "Oakwood - GA 30566",
    "county": "Hall County",
    "state": "GA",
    "city": "Oakwood"
  },
  {
    "value": "30567",
    "label": "Pendergrass - GA 30567",
    "county": "Jackson County",
    "state": "GA",
    "city": "Pendergrass"
  },
  {
    "value": "30568",
    "label": "Rabun Gap - GA 30568",
    "county": "Rabun County",
    "state": "GA",
    "city": "Rabun Gap"
  },
  {
    "value": "30571",
    "label": "Sautee Nacoochee - GA 30571",
    "county": "White County",
    "state": "GA",
    "city": "Sautee Nacoochee"
  },
  {
    "value": "30572",
    "label": "Suches - GA 30572",
    "county": "Union County",
    "state": "GA",
    "city": "Suches"
  },
  {
    "value": "30575",
    "label": "Talmo - GA 30575",
    "county": "Jackson County",
    "state": "GA",
    "city": "Talmo"
  },
  {
    "value": "30576",
    "label": "Tiger - GA 30576",
    "county": "Rabun County",
    "state": "GA",
    "city": "Tiger"
  },
  {
    "value": "30577",
    "label": "Toccoa - GA 30577",
    "county": "Stephens County",
    "state": "GA",
    "city": "Toccoa"
  },
  {
    "value": "30582",
    "label": "Young Harris - GA 30582",
    "county": "Towns County",
    "state": "GA",
    "city": "Young Harris"
  },
  {
    "value": "30601",
    "label": "Athens - GA 30601",
    "county": "Clarke County",
    "state": "GA",
    "city": "Athens"
  },
  {
    "value": "30605",
    "label": "Athens - GA 30605",
    "county": "Clarke County",
    "state": "GA",
    "city": "Athens"
  },
  {
    "value": "30606",
    "label": "Athens - GA 30606",
    "county": "Clarke County",
    "state": "GA",
    "city": "Athens"
  },
  {
    "value": "30607",
    "label": "Athens - GA 30607",
    "county": "Clarke County",
    "state": "GA",
    "city": "Athens"
  },
  {
    "value": "30619",
    "label": "Arnoldsville - GA 30619",
    "county": "Oglethorpe County",
    "state": "GA",
    "city": "Arnoldsville"
  },
  {
    "value": "30620",
    "label": "Bethlehem - GA 30620",
    "county": "Barrow County",
    "state": "GA",
    "city": "Bethlehem"
  },
  {
    "value": "30621",
    "label": "Bishop - GA 30621",
    "county": "Oconee County",
    "state": "GA",
    "city": "Bishop"
  },
  {
    "value": "30622",
    "label": "Bogart - GA 30622",
    "county": "Oconee County",
    "state": "GA",
    "city": "Bogart"
  },
  {
    "value": "30624",
    "label": "Bowman - GA 30624",
    "county": "Elbert County",
    "state": "GA",
    "city": "Bowman"
  },
  {
    "value": "30625",
    "label": "Buckhead - GA 30625",
    "county": "Morgan County",
    "state": "GA",
    "city": "Buckhead"
  },
  {
    "value": "30627",
    "label": "Carlton - GA 30627",
    "county": "Oglethorpe County",
    "state": "GA",
    "city": "Carlton"
  },
  {
    "value": "30628",
    "label": "Colbert - GA 30628",
    "county": "Madison County",
    "state": "GA",
    "city": "Colbert"
  },
  {
    "value": "30629",
    "label": "Comer - GA 30629",
    "county": "Madison County",
    "state": "GA",
    "city": "Comer"
  },
  {
    "value": "30630",
    "label": "Crawford - GA 30630",
    "county": "Oglethorpe County",
    "state": "GA",
    "city": "Crawford"
  },
  {
    "value": "30631",
    "label": "Crawfordville - GA 30631",
    "county": "Taliaferro County",
    "state": "GA",
    "city": "Crawfordville"
  },
  {
    "value": "30633",
    "label": "Danielsville - GA 30633",
    "county": "Madison County",
    "state": "GA",
    "city": "Danielsville"
  },
  {
    "value": "30634",
    "label": "Dewy Rose - GA 30634",
    "county": "Elbert County",
    "state": "GA",
    "city": "Dewy Rose"
  },
  {
    "value": "30635",
    "label": "Elberton - GA 30635",
    "county": "Elbert County",
    "state": "GA",
    "city": "Elberton"
  },
  {
    "value": "30641",
    "label": "Good Hope - GA 30641",
    "county": "Walton County",
    "state": "GA",
    "city": "Good Hope"
  },
  {
    "value": "30642",
    "label": "Greensboro - GA 30642",
    "county": "Greene County",
    "state": "GA",
    "city": "Greensboro"
  },
  {
    "value": "30643",
    "label": "Hartwell - GA 30643",
    "county": "Hart County",
    "state": "GA",
    "city": "Hartwell"
  },
  {
    "value": "30646",
    "label": "Hull - GA 30646",
    "county": "Madison County",
    "state": "GA",
    "city": "Hull"
  },
  {
    "value": "30648",
    "label": "Lexington - GA 30648",
    "county": "Oglethorpe County",
    "state": "GA",
    "city": "Lexington"
  },
  {
    "value": "30650",
    "label": "Madison - GA 30650",
    "county": "Morgan County",
    "state": "GA",
    "city": "Madison"
  },
  {
    "value": "30655",
    "label": "Monroe - GA 30655",
    "county": "Walton County",
    "state": "GA",
    "city": "Monroe"
  },
  {
    "value": "30656",
    "label": "Monroe - GA 30656",
    "county": "Walton County",
    "state": "GA",
    "city": "Monroe"
  },
  {
    "value": "30660",
    "label": "Rayle - GA 30660",
    "county": "Wilkes County",
    "state": "GA",
    "city": "Rayle"
  },
  {
    "value": "30662",
    "label": "Royston - GA 30662",
    "county": "Franklin County",
    "state": "GA",
    "city": "Royston"
  },
  {
    "value": "30663",
    "label": "Rutledge - GA 30663",
    "county": "Morgan County",
    "state": "GA",
    "city": "Rutledge"
  },
  {
    "value": "30666",
    "label": "Statham - GA 30666",
    "county": "Barrow County",
    "state": "GA",
    "city": "Statham"
  },
  {
    "value": "30667",
    "label": "Stephens - GA 30667",
    "county": "Oglethorpe County",
    "state": "GA",
    "city": "Stephens"
  },
  {
    "value": "30668",
    "label": "Tignall - GA 30668",
    "county": "Wilkes County",
    "state": "GA",
    "city": "Tignall"
  },
  {
    "value": "30669",
    "label": "Union Point - GA 30669",
    "county": "Greene County",
    "state": "GA",
    "city": "Union Point"
  },
  {
    "value": "30673",
    "label": "Washington - GA 30673",
    "county": "Wilkes County",
    "state": "GA",
    "city": "Washington"
  },
  {
    "value": "30677",
    "label": "Watkinsville - GA 30677",
    "county": "Oconee County",
    "state": "GA",
    "city": "Watkinsville"
  },
  {
    "value": "30678",
    "label": "White Plains - GA 30678",
    "county": "Greene County",
    "state": "GA",
    "city": "White Plains"
  },
  {
    "value": "30680",
    "label": "Winder - GA 30680",
    "county": "Barrow County",
    "state": "GA",
    "city": "Winder"
  },
  {
    "value": "30683",
    "label": "Winterville - GA 30683",
    "county": "Clarke County",
    "state": "GA",
    "city": "Winterville"
  },
  {
    "value": "30701",
    "label": "Calhoun - GA 30701",
    "county": "Gordon County",
    "state": "GA",
    "city": "Calhoun"
  },
  {
    "value": "30705",
    "label": "Chatsworth - GA 30705",
    "county": "Murray County",
    "state": "GA",
    "city": "Chatsworth"
  },
  {
    "value": "30707",
    "label": "Chickamauga - GA 30707",
    "county": "Walker County",
    "state": "GA",
    "city": "Chickamauga"
  },
  {
    "value": "30708",
    "label": "Cisco - GA 30708",
    "county": "Murray County",
    "state": "GA",
    "city": "Cisco"
  },
  {
    "value": "30710",
    "label": "Cohutta - GA 30710",
    "county": "Whitfield County",
    "state": "GA",
    "city": "Cohutta"
  },
  {
    "value": "30711",
    "label": "Crandall - GA 30711",
    "county": "Murray County",
    "state": "GA",
    "city": "Crandall"
  },
  {
    "value": "30720",
    "label": "Dalton - GA 30720",
    "county": "Whitfield County",
    "state": "GA",
    "city": "Dalton"
  },
  {
    "value": "30721",
    "label": "Dalton - GA 30721",
    "county": "Whitfield County",
    "state": "GA",
    "city": "Dalton"
  },
  {
    "value": "30725",
    "label": "Flintstone - GA 30725",
    "county": "Walker County",
    "state": "GA",
    "city": "Flintstone"
  },
  {
    "value": "30728",
    "label": "La Fayette - GA 30728",
    "county": "Walker County",
    "state": "GA",
    "city": "La Fayette"
  },
  {
    "value": "30730",
    "label": "Lyerly - GA 30730",
    "county": "Chattooga County",
    "state": "GA",
    "city": "Lyerly"
  },
  {
    "value": "30731",
    "label": "Menlo - GA 30731",
    "county": "Chattooga County",
    "state": "GA",
    "city": "Menlo"
  },
  {
    "value": "30733",
    "label": "Plainville - GA 30733",
    "county": "Gordon County",
    "state": "GA",
    "city": "Plainville"
  },
  {
    "value": "30734",
    "label": "Ranger - GA 30734",
    "county": "Gordon County",
    "state": "GA",
    "city": "Ranger"
  },
  {
    "value": "30735",
    "label": "Resaca - GA 30735",
    "county": "Gordon County",
    "state": "GA",
    "city": "Resaca"
  },
  {
    "value": "30736",
    "label": "Ringgold - GA 30736",
    "county": "Catoosa County",
    "state": "GA",
    "city": "Ringgold"
  },
  {
    "value": "30738",
    "label": "Rising Fawn - GA 30738",
    "county": "Dade County",
    "state": "GA",
    "city": "Rising Fawn"
  },
  {
    "value": "30739",
    "label": "Rock Spring - GA 30739",
    "county": "Walker County",
    "state": "GA",
    "city": "Rock Spring"
  },
  {
    "value": "30740",
    "label": "Rocky Face - GA 30740",
    "county": "Whitfield County",
    "state": "GA",
    "city": "Rocky Face"
  },
  {
    "value": "30741",
    "label": "Rossville - GA 30741",
    "county": "Walker County",
    "state": "GA",
    "city": "Rossville"
  },
  {
    "value": "30742",
    "label": "Fort Oglethorpe - GA 30742",
    "county": "Catoosa County",
    "state": "GA",
    "city": "Fort Oglethorpe"
  },
  {
    "value": "30746",
    "label": "Sugar Valley - GA 30746",
    "county": "Gordon County",
    "state": "GA",
    "city": "Sugar Valley"
  },
  {
    "value": "30747",
    "label": "Summerville - GA 30747",
    "county": "Chattooga County",
    "state": "GA",
    "city": "Summerville"
  },
  {
    "value": "30750",
    "label": "Lookout Mountain - GA 30750",
    "county": "Walker County",
    "state": "GA",
    "city": "Lookout Mountain"
  },
  {
    "value": "30752",
    "label": "Trenton - GA 30752",
    "county": "Dade County",
    "state": "GA",
    "city": "Trenton"
  },
  {
    "value": "30753",
    "label": "Trion - GA 30753",
    "county": "Chattooga County",
    "state": "GA",
    "city": "Trion"
  },
  {
    "value": "30755",
    "label": "Tunnel Hill - GA 30755",
    "county": "Whitfield County",
    "state": "GA",
    "city": "Tunnel Hill"
  },
  {
    "value": "30757",
    "label": "Wildwood - GA 30757",
    "county": "Dade County",
    "state": "GA",
    "city": "Wildwood"
  },
  {
    "value": "30802",
    "label": "Appling - GA 30802",
    "county": "Columbia County",
    "state": "GA",
    "city": "Appling"
  },
  {
    "value": "30803",
    "label": "Avera - GA 30803",
    "county": "Jefferson County",
    "state": "GA",
    "city": "Avera"
  },
  {
    "value": "30805",
    "label": "Blythe - GA 30805",
    "county": "Richmond County",
    "state": "GA",
    "city": "Blythe"
  },
  {
    "value": "30808",
    "label": "Dearing - GA 30808",
    "county": "McDuffie County",
    "state": "GA",
    "city": "Dearing"
  },
  {
    "value": "30809",
    "label": "Evans - GA 30809",
    "county": "Columbia County",
    "state": "GA",
    "city": "Evans"
  },
  {
    "value": "30810",
    "label": "Gibson - GA 30810",
    "county": "Glascock County",
    "state": "GA",
    "city": "Gibson"
  },
  {
    "value": "30813",
    "label": "Grovetown - GA 30813",
    "county": "Columbia County",
    "state": "GA",
    "city": "Grovetown"
  },
  {
    "value": "30814",
    "label": "Harlem - GA 30814",
    "county": "Columbia County",
    "state": "GA",
    "city": "Harlem"
  },
  {
    "value": "30815",
    "label": "Hephzibah - GA 30815",
    "county": "Richmond County",
    "state": "GA",
    "city": "Hephzibah"
  },
  {
    "value": "30816",
    "label": "Keysville - GA 30816",
    "county": "Burke County",
    "state": "GA",
    "city": "Keysville"
  },
  {
    "value": "30817",
    "label": "Lincolnton - GA 30817",
    "county": "Lincoln County",
    "state": "GA",
    "city": "Lincolnton"
  },
  {
    "value": "30818",
    "label": "Matthews - GA 30818",
    "county": "Jefferson County",
    "state": "GA",
    "city": "Matthews"
  },
  {
    "value": "30820",
    "label": "Mitchell - GA 30820",
    "county": "Glascock County",
    "state": "GA",
    "city": "Mitchell"
  },
  {
    "value": "30821",
    "label": "Norwood - GA 30821",
    "county": "Warren County",
    "state": "GA",
    "city": "Norwood"
  },
  {
    "value": "30823",
    "label": "Stapleton - GA 30823",
    "county": "Jefferson County",
    "state": "GA",
    "city": "Stapleton"
  },
  {
    "value": "30824",
    "label": "Thomson - GA 30824",
    "county": "McDuffie County",
    "state": "GA",
    "city": "Thomson"
  },
  {
    "value": "30828",
    "label": "Warrenton - GA 30828",
    "county": "Warren County",
    "state": "GA",
    "city": "Warrenton"
  },
  {
    "value": "30830",
    "label": "Waynesboro - GA 30830",
    "county": "Burke County",
    "state": "GA",
    "city": "Waynesboro"
  },
  {
    "value": "30833",
    "label": "Wrens - GA 30833",
    "county": "Jefferson County",
    "state": "GA",
    "city": "Wrens"
  },
  {
    "value": "30901",
    "label": "Augusta - GA 30901",
    "county": "Richmond County",
    "state": "GA",
    "city": "Augusta"
  },
  {
    "value": "30904",
    "label": "Augusta - GA 30904",
    "county": "Richmond County",
    "state": "GA",
    "city": "Augusta"
  },
  {
    "value": "30905",
    "label": "Augusta - GA 30905",
    "county": "Richmond County",
    "state": "GA",
    "city": "Augusta"
  },
  {
    "value": "30906",
    "label": "Augusta - GA 30906",
    "county": "Richmond County",
    "state": "GA",
    "city": "Augusta"
  },
  {
    "value": "30907",
    "label": "Augusta - GA 30907",
    "county": "Columbia County",
    "state": "GA",
    "city": "Augusta"
  },
  {
    "value": "30909",
    "label": "Augusta - GA 30909",
    "county": "Richmond County",
    "state": "GA",
    "city": "Augusta"
  },
  {
    "value": "30911",
    "label": "Augusta - GA 30911",
    "county": "Richmond County",
    "state": "GA",
    "city": "Augusta"
  },
  {
    "value": "30913",
    "label": "Augusta - GA 30913",
    "county": "Richmond County",
    "state": "GA",
    "city": "Augusta"
  },
  {
    "value": "31001",
    "label": "Abbeville - GA 31001",
    "county": "Wilcox County",
    "state": "GA",
    "city": "Abbeville"
  },
  {
    "value": "31002",
    "label": "Adrian - GA 31002",
    "county": "Emanuel County",
    "state": "GA",
    "city": "Adrian"
  },
  {
    "value": "31005",
    "label": "Bonaire - GA 31005",
    "county": "Houston County",
    "state": "GA",
    "city": "Bonaire"
  },
  {
    "value": "31006",
    "label": "Butler - GA 31006",
    "county": "Taylor County",
    "state": "GA",
    "city": "Butler"
  },
  {
    "value": "31007",
    "label": "Byromville - GA 31007",
    "county": "Dooly County",
    "state": "GA",
    "city": "Byromville"
  },
  {
    "value": "31008",
    "label": "Byron - GA 31008",
    "county": "Peach County",
    "state": "GA",
    "city": "Byron"
  },
  {
    "value": "31009",
    "label": "Cadwell - GA 31009",
    "county": "Laurens County",
    "state": "GA",
    "city": "Cadwell"
  },
  {
    "value": "31011",
    "label": "Chauncey - GA 31011",
    "county": "Dodge County",
    "state": "GA",
    "city": "Chauncey"
  },
  {
    "value": "31012",
    "label": "Chester - GA 31012",
    "county": "Dodge County",
    "state": "GA",
    "city": "Chester"
  },
  {
    "value": "31014",
    "label": "Cochran - GA 31014",
    "county": "Bleckley County",
    "state": "GA",
    "city": "Cochran"
  },
  {
    "value": "31015",
    "label": "Cordele - GA 31015",
    "county": "Crisp County",
    "state": "GA",
    "city": "Cordele"
  },
  {
    "value": "31016",
    "label": "Culloden - GA 31016",
    "county": "Monroe County",
    "state": "GA",
    "city": "Culloden"
  },
  {
    "value": "31017",
    "label": "Danville - GA 31017",
    "county": "Twiggs County",
    "state": "GA",
    "city": "Danville"
  },
  {
    "value": "31018",
    "label": "Davisboro - GA 31018",
    "county": "Washington County",
    "state": "GA",
    "city": "Davisboro"
  },
  {
    "value": "31019",
    "label": "Dexter - GA 31019",
    "county": "Laurens County",
    "state": "GA",
    "city": "Dexter"
  },
  {
    "value": "31020",
    "label": "Dry Branch - GA 31020",
    "county": "Twiggs County",
    "state": "GA",
    "city": "Dry Branch"
  },
  {
    "value": "31021",
    "label": "Dublin - GA 31021",
    "county": "Laurens County",
    "state": "GA",
    "city": "Dublin"
  },
  {
    "value": "31022",
    "label": "Dudley - GA 31022",
    "county": "Laurens County",
    "state": "GA",
    "city": "Dudley"
  },
  {
    "value": "31023",
    "label": "Eastman - GA 31023",
    "county": "Dodge County",
    "state": "GA",
    "city": "Eastman"
  },
  {
    "value": "31024",
    "label": "Eatonton - GA 31024",
    "county": "Putnam County",
    "state": "GA",
    "city": "Eatonton"
  },
  {
    "value": "31025",
    "label": "Elko - GA 31025",
    "county": "Houston County",
    "state": "GA",
    "city": "Elko"
  },
  {
    "value": "31027",
    "label": "East Dublin - GA 31027",
    "county": "Laurens County",
    "state": "GA",
    "city": "East Dublin"
  },
  {
    "value": "31028",
    "label": "Centerville - GA 31028",
    "county": "Houston County",
    "state": "GA",
    "city": "Centerville"
  },
  {
    "value": "31029",
    "label": "Forsyth - GA 31029",
    "county": "Monroe County",
    "state": "GA",
    "city": "Forsyth"
  },
  {
    "value": "31030",
    "label": "Fort Valley - GA 31030",
    "county": "Peach County",
    "state": "GA",
    "city": "Fort Valley"
  },
  {
    "value": "31031",
    "label": "Gordon - GA 31031",
    "county": "Wilkinson County",
    "state": "GA",
    "city": "Gordon"
  },
  {
    "value": "31032",
    "label": "Gray - GA 31032",
    "county": "Jones County",
    "state": "GA",
    "city": "Gray"
  },
  {
    "value": "31033",
    "label": "Haddock - GA 31033",
    "county": "Jones County",
    "state": "GA",
    "city": "Haddock"
  },
  {
    "value": "31035",
    "label": "Harrison - GA 31035",
    "county": "Washington County",
    "state": "GA",
    "city": "Harrison"
  },
  {
    "value": "31036",
    "label": "Hawkinsville - GA 31036",
    "county": "Pulaski County",
    "state": "GA",
    "city": "Hawkinsville"
  },
  {
    "value": "31037",
    "label": "Mc Rae Helena - GA 31037",
    "county": "Telfair County",
    "state": "GA",
    "city": "Mc Rae Helena"
  },
  {
    "value": "31038",
    "label": "Hillsboro - GA 31038",
    "county": "Jasper County",
    "state": "GA",
    "city": "Hillsboro"
  },
  {
    "value": "31041",
    "label": "Ideal - GA 31041",
    "county": "Macon County",
    "state": "GA",
    "city": "Ideal"
  },
  {
    "value": "31042",
    "label": "Irwinton - GA 31042",
    "county": "Wilkinson County",
    "state": "GA",
    "city": "Irwinton"
  },
  {
    "value": "31044",
    "label": "Jeffersonville - GA 31044",
    "county": "Twiggs County",
    "state": "GA",
    "city": "Jeffersonville"
  },
  {
    "value": "31045",
    "label": "Jewell - GA 31045",
    "county": "Warren County",
    "state": "GA",
    "city": "Jewell"
  },
  {
    "value": "31046",
    "label": "Juliette - GA 31046",
    "county": "Monroe County",
    "state": "GA",
    "city": "Juliette"
  },
  {
    "value": "31047",
    "label": "Kathleen - GA 31047",
    "county": "Houston County",
    "state": "GA",
    "city": "Kathleen"
  },
  {
    "value": "31049",
    "label": "Kite - GA 31049",
    "county": "Johnson County",
    "state": "GA",
    "city": "Kite"
  },
  {
    "value": "31050",
    "label": "Knoxville - GA 31050",
    "county": "Crawford County",
    "state": "GA",
    "city": "Knoxville"
  },
  {
    "value": "31052",
    "label": "Lizella - GA 31052",
    "county": "Bibb County",
    "state": "GA",
    "city": "Lizella"
  },
  {
    "value": "31054",
    "label": "Mc Intyre - GA 31054",
    "county": "Wilkinson County",
    "state": "GA",
    "city": "Mc Intyre"
  },
  {
    "value": "31055",
    "label": "Mc Rae Helena - GA 31055",
    "county": "Telfair County",
    "state": "GA",
    "city": "Mc Rae Helena"
  },
  {
    "value": "31057",
    "label": "Marshallville - GA 31057",
    "county": "Macon County",
    "state": "GA",
    "city": "Marshallville"
  },
  {
    "value": "31058",
    "label": "Mauk - GA 31058",
    "county": "Marion County",
    "state": "GA",
    "city": "Mauk"
  },
  {
    "value": "31060",
    "label": "Milan - GA 31060",
    "county": "Telfair County",
    "state": "GA",
    "city": "Milan"
  },
  {
    "value": "31061",
    "label": "Milledgeville - GA 31061",
    "county": "Baldwin County",
    "state": "GA",
    "city": "Milledgeville"
  },
  {
    "value": "31063",
    "label": "Montezuma - GA 31063",
    "county": "Macon County",
    "state": "GA",
    "city": "Montezuma"
  },
  {
    "value": "31064",
    "label": "Monticello - GA 31064",
    "county": "Jasper County",
    "state": "GA",
    "city": "Monticello"
  },
  {
    "value": "31065",
    "label": "Montrose - GA 31065",
    "county": "Laurens County",
    "state": "GA",
    "city": "Montrose"
  },
  {
    "value": "31066",
    "label": "Musella - GA 31066",
    "county": "Crawford County",
    "state": "GA",
    "city": "Musella"
  },
  {
    "value": "31068",
    "label": "Oglethorpe - GA 31068",
    "county": "Macon County",
    "state": "GA",
    "city": "Oglethorpe"
  },
  {
    "value": "31069",
    "label": "Perry - GA 31069",
    "county": "Houston County",
    "state": "GA",
    "city": "Perry"
  },
  {
    "value": "31070",
    "label": "Pinehurst - GA 31070",
    "county": "Dooly County",
    "state": "GA",
    "city": "Pinehurst"
  },
  {
    "value": "31071",
    "label": "Pineview - GA 31071",
    "county": "Wilcox County",
    "state": "GA",
    "city": "Pineview"
  },
  {
    "value": "31072",
    "label": "Pitts - GA 31072",
    "county": "Wilcox County",
    "state": "GA",
    "city": "Pitts"
  },
  {
    "value": "31075",
    "label": "Rentz - GA 31075",
    "county": "Laurens County",
    "state": "GA",
    "city": "Rentz"
  },
  {
    "value": "31076",
    "label": "Reynolds - GA 31076",
    "county": "Taylor County",
    "state": "GA",
    "city": "Reynolds"
  },
  {
    "value": "31077",
    "label": "Rhine - GA 31077",
    "county": "Dodge County",
    "state": "GA",
    "city": "Rhine"
  },
  {
    "value": "31078",
    "label": "Roberta - GA 31078",
    "county": "Crawford County",
    "state": "GA",
    "city": "Roberta"
  },
  {
    "value": "31079",
    "label": "Rochelle - GA 31079",
    "county": "Wilcox County",
    "state": "GA",
    "city": "Rochelle"
  },
  {
    "value": "31081",
    "label": "Rupert - GA 31081",
    "county": "Taylor County",
    "state": "GA",
    "city": "Rupert"
  },
  {
    "value": "31082",
    "label": "Sandersville - GA 31082",
    "county": "Washington County",
    "state": "GA",
    "city": "Sandersville"
  },
  {
    "value": "31085",
    "label": "Shady Dale - GA 31085",
    "county": "Jasper County",
    "state": "GA",
    "city": "Shady Dale"
  },
  {
    "value": "31087",
    "label": "Sparta - GA 31087",
    "county": "Hancock County",
    "state": "GA",
    "city": "Sparta"
  },
  {
    "value": "31088",
    "label": "Warner Robins - GA 31088",
    "county": "Houston County",
    "state": "GA",
    "city": "Warner Robins"
  },
  {
    "value": "31089",
    "label": "Tennille - GA 31089",
    "county": "Washington County",
    "state": "GA",
    "city": "Tennille"
  },
  {
    "value": "31090",
    "label": "Toomsboro - GA 31090",
    "county": "Wilkinson County",
    "state": "GA",
    "city": "Toomsboro"
  },
  {
    "value": "31091",
    "label": "Unadilla - GA 31091",
    "county": "Dooly County",
    "state": "GA",
    "city": "Unadilla"
  },
  {
    "value": "31092",
    "label": "Vienna - GA 31092",
    "county": "Dooly County",
    "state": "GA",
    "city": "Vienna"
  },
  {
    "value": "31093",
    "label": "Warner Robins - GA 31093",
    "county": "Houston County",
    "state": "GA",
    "city": "Warner Robins"
  },
  {
    "value": "31094",
    "label": "Warthen - GA 31094",
    "county": "Washington County",
    "state": "GA",
    "city": "Warthen"
  },
  {
    "value": "31096",
    "label": "Wrightsville - GA 31096",
    "county": "Johnson County",
    "state": "GA",
    "city": "Wrightsville"
  },
  {
    "value": "31097",
    "label": "Yatesville - GA 31097",
    "county": "Upson County",
    "state": "GA",
    "city": "Yatesville"
  },
  {
    "value": "31098",
    "label": "Warner Robins - GA 31098",
    "county": "Houston County",
    "state": "GA",
    "city": "Warner Robins"
  },
  {
    "value": "31120",
    "label": "Atlanta - GA 31120",
    "county": "Fulton County",
    "state": "GA",
    "city": "Atlanta"
  },
  {
    "value": "31201",
    "label": "Macon - GA 31201",
    "county": "Bibb County",
    "state": "GA",
    "city": "Macon"
  },
  {
    "value": "31204",
    "label": "Macon - GA 31204",
    "county": "Bibb County",
    "state": "GA",
    "city": "Macon"
  },
  {
    "value": "31206",
    "label": "Macon - GA 31206",
    "county": "Bibb County",
    "state": "GA",
    "city": "Macon"
  },
  {
    "value": "31210",
    "label": "Macon - GA 31210",
    "county": "Bibb County",
    "state": "GA",
    "city": "Macon"
  },
  {
    "value": "31211",
    "label": "Macon - GA 31211",
    "county": "Bibb County",
    "state": "GA",
    "city": "Macon"
  },
  {
    "value": "31216",
    "label": "Macon - GA 31216",
    "county": "Bibb County",
    "state": "GA",
    "city": "Macon"
  },
  {
    "value": "31217",
    "label": "Macon - GA 31217",
    "county": "Bibb County",
    "state": "GA",
    "city": "Macon"
  },
  {
    "value": "31220",
    "label": "Macon - GA 31220",
    "county": "Bibb County",
    "state": "GA",
    "city": "Macon"
  },
  {
    "value": "31301",
    "label": "Allenhurst - GA 31301",
    "county": "Liberty County",
    "state": "GA",
    "city": "Allenhurst"
  },
  {
    "value": "31302",
    "label": "Bloomingdale - GA 31302",
    "county": "Effingham County",
    "state": "GA",
    "city": "Bloomingdale"
  },
  {
    "value": "31303",
    "label": "Clyo - GA 31303",
    "county": "Effingham County",
    "state": "GA",
    "city": "Clyo"
  },
  {
    "value": "31305",
    "label": "Darien - GA 31305",
    "county": "McIntosh County",
    "state": "GA",
    "city": "Darien"
  },
  {
    "value": "31308",
    "label": "Ellabell - GA 31308",
    "county": "Bryan County",
    "state": "GA",
    "city": "Ellabell"
  },
  {
    "value": "31309",
    "label": "Fleming - GA 31309",
    "county": "Liberty County",
    "state": "GA",
    "city": "Fleming"
  },
  {
    "value": "31312",
    "label": "Guyton - GA 31312",
    "county": "Effingham County",
    "state": "GA",
    "city": "Guyton"
  },
  {
    "value": "31313",
    "label": "Hinesville - GA 31313",
    "county": "Liberty County",
    "state": "GA",
    "city": "Hinesville"
  },
  {
    "value": "31314",
    "label": "Fort Stewart - GA 31314",
    "county": "Liberty County",
    "state": "GA",
    "city": "Fort Stewart"
  },
  {
    "value": "31315",
    "label": "Fort Stewart - GA 31315",
    "county": "Liberty County",
    "state": "GA",
    "city": "Fort Stewart"
  },
  {
    "value": "31316",
    "label": "Ludowici - GA 31316",
    "county": "Long County",
    "state": "GA",
    "city": "Ludowici"
  },
  {
    "value": "31320",
    "label": "Midway - GA 31320",
    "county": "Liberty County",
    "state": "GA",
    "city": "Midway"
  },
  {
    "value": "31321",
    "label": "Pembroke - GA 31321",
    "county": "Bryan County",
    "state": "GA",
    "city": "Pembroke"
  },
  {
    "value": "31322",
    "label": "Pooler - GA 31322",
    "county": "Chatham County",
    "state": "GA",
    "city": "Pooler"
  },
  {
    "value": "31323",
    "label": "Riceboro - GA 31323",
    "county": "Liberty County",
    "state": "GA",
    "city": "Riceboro"
  },
  {
    "value": "31324",
    "label": "Richmond Hill - GA 31324",
    "county": "Bryan County",
    "state": "GA",
    "city": "Richmond Hill"
  },
  {
    "value": "31326",
    "label": "Rincon - GA 31326",
    "county": "Effingham County",
    "state": "GA",
    "city": "Rincon"
  },
  {
    "value": "31328",
    "label": "Tybee Island - GA 31328",
    "county": "Chatham County",
    "state": "GA",
    "city": "Tybee Island"
  },
  {
    "value": "31329",
    "label": "Springfield - GA 31329",
    "county": "Effingham County",
    "state": "GA",
    "city": "Springfield"
  },
  {
    "value": "31331",
    "label": "Townsend - GA 31331",
    "county": "McIntosh County",
    "state": "GA",
    "city": "Townsend"
  },
  {
    "value": "31401",
    "label": "Savannah - GA 31401",
    "county": "Chatham County",
    "state": "GA",
    "city": "Savannah"
  },
  {
    "value": "31404",
    "label": "Savannah - GA 31404",
    "county": "Chatham County",
    "state": "GA",
    "city": "Savannah"
  },
  {
    "value": "31405",
    "label": "Savannah - GA 31405",
    "county": "Chatham County",
    "state": "GA",
    "city": "Savannah"
  },
  {
    "value": "31406",
    "label": "Savannah - GA 31406",
    "county": "Chatham County",
    "state": "GA",
    "city": "Savannah"
  },
  {
    "value": "31407",
    "label": "Port Wentworth - GA 31407",
    "county": "Chatham County",
    "state": "GA",
    "city": "Port Wentworth"
  },
  {
    "value": "31408",
    "label": "Savannah - GA 31408",
    "county": "Chatham County",
    "state": "GA",
    "city": "Savannah"
  },
  {
    "value": "31410",
    "label": "Savannah - GA 31410",
    "county": "Chatham County",
    "state": "GA",
    "city": "Savannah"
  },
  {
    "value": "31411",
    "label": "Savannah - GA 31411",
    "county": "Chatham County",
    "state": "GA",
    "city": "Savannah"
  },
  {
    "value": "31415",
    "label": "Savannah - GA 31415",
    "county": "Chatham County",
    "state": "GA",
    "city": "Savannah"
  },
  {
    "value": "31419",
    "label": "Savannah - GA 31419",
    "county": "Chatham County",
    "state": "GA",
    "city": "Savannah"
  },
  {
    "value": "31421",
    "label": "Savannah - GA 31421",
    "county": "Chatham County",
    "state": "GA",
    "city": "Savannah"
  },
  {
    "value": "31501",
    "label": "Waycross - GA 31501",
    "county": "Ware County",
    "state": "GA",
    "city": "Waycross"
  },
  {
    "value": "31503",
    "label": "Waycross - GA 31503",
    "county": "Ware County",
    "state": "GA",
    "city": "Waycross"
  },
  {
    "value": "31510",
    "label": "Alma - GA 31510",
    "county": "Bacon County",
    "state": "GA",
    "city": "Alma"
  },
  {
    "value": "31512",
    "label": "Ambrose - GA 31512",
    "county": "Coffee County",
    "state": "GA",
    "city": "Ambrose"
  },
  {
    "value": "31513",
    "label": "Baxley - GA 31513",
    "county": "Appling County",
    "state": "GA",
    "city": "Baxley"
  },
  {
    "value": "31516",
    "label": "Blackshear - GA 31516",
    "county": "Pierce County",
    "state": "GA",
    "city": "Blackshear"
  },
  {
    "value": "31518",
    "label": "Bristol - GA 31518",
    "county": "Appling County",
    "state": "GA",
    "city": "Bristol"
  },
  {
    "value": "31519",
    "label": "Broxton - GA 31519",
    "county": "Coffee County",
    "state": "GA",
    "city": "Broxton"
  },
  {
    "value": "31520",
    "label": "Brunswick - GA 31520",
    "county": "Glynn County",
    "state": "GA",
    "city": "Brunswick"
  },
  {
    "value": "31522",
    "label": "Saint Simons Island - GA 31522",
    "county": "Glynn County",
    "state": "GA",
    "city": "Saint Simons Island"
  },
  {
    "value": "31523",
    "label": "Brunswick - GA 31523",
    "county": "Glynn County",
    "state": "GA",
    "city": "Brunswick"
  },
  {
    "value": "31525",
    "label": "Brunswick - GA 31525",
    "county": "Glynn County",
    "state": "GA",
    "city": "Brunswick"
  },
  {
    "value": "31527",
    "label": "Jekyll Island - GA 31527",
    "county": "Glynn County",
    "state": "GA",
    "city": "Jekyll Island"
  },
  {
    "value": "31532",
    "label": "Denton - GA 31532",
    "county": "Jeff Davis County",
    "state": "GA",
    "city": "Denton"
  },
  {
    "value": "31533",
    "label": "Douglas - GA 31533",
    "county": "Coffee County",
    "state": "GA",
    "city": "Douglas"
  },
  {
    "value": "31535",
    "label": "Douglas - GA 31535",
    "county": "Coffee County",
    "state": "GA",
    "city": "Douglas"
  },
  {
    "value": "31537",
    "label": "Folkston - GA 31537",
    "county": "Charlton County",
    "state": "GA",
    "city": "Folkston"
  },
  {
    "value": "31539",
    "label": "Hazlehurst - GA 31539",
    "county": "Jeff Davis County",
    "state": "GA",
    "city": "Hazlehurst"
  },
  {
    "value": "31542",
    "label": "Hoboken - GA 31542",
    "county": "Brantley County",
    "state": "GA",
    "city": "Hoboken"
  },
  {
    "value": "31543",
    "label": "Hortense - GA 31543",
    "county": "Brantley County",
    "state": "GA",
    "city": "Hortense"
  },
  {
    "value": "31544",
    "label": "Jacksonville - GA 31544",
    "county": "Telfair County",
    "state": "GA",
    "city": "Jacksonville"
  },
  {
    "value": "31545",
    "label": "Jesup - GA 31545",
    "county": "Wayne County",
    "state": "GA",
    "city": "Jesup"
  },
  {
    "value": "31546",
    "label": "Jesup - GA 31546",
    "county": "Wayne County",
    "state": "GA",
    "city": "Jesup"
  },
  {
    "value": "31547",
    "label": "Kings Bay - GA 31547",
    "county": "Camden County",
    "state": "GA",
    "city": "Kings Bay"
  },
  {
    "value": "31548",
    "label": "Kingsland - GA 31548",
    "county": "Camden County",
    "state": "GA",
    "city": "Kingsland"
  },
  {
    "value": "31549",
    "label": "Lumber City - GA 31549",
    "county": "Telfair County",
    "state": "GA",
    "city": "Lumber City"
  },
  {
    "value": "31550",
    "label": "Manor - GA 31550",
    "county": "Ware County",
    "state": "GA",
    "city": "Manor"
  },
  {
    "value": "31551",
    "label": "Mershon - GA 31551",
    "county": "Pierce County",
    "state": "GA",
    "city": "Mershon"
  },
  {
    "value": "31552",
    "label": "Millwood - GA 31552",
    "county": "Ware County",
    "state": "GA",
    "city": "Millwood"
  },
  {
    "value": "31553",
    "label": "Nahunta - GA 31553",
    "county": "Brantley County",
    "state": "GA",
    "city": "Nahunta"
  },
  {
    "value": "31554",
    "label": "Nicholls - GA 31554",
    "county": "Coffee County",
    "state": "GA",
    "city": "Nicholls"
  },
  {
    "value": "31555",
    "label": "Odum - GA 31555",
    "county": "Wayne County",
    "state": "GA",
    "city": "Odum"
  },
  {
    "value": "31557",
    "label": "Patterson - GA 31557",
    "county": "Pierce County",
    "state": "GA",
    "city": "Patterson"
  },
  {
    "value": "31558",
    "label": "Saint Marys - GA 31558",
    "county": "Camden County",
    "state": "GA",
    "city": "Saint Marys"
  },
  {
    "value": "31560",
    "label": "Screven - GA 31560",
    "county": "Wayne County",
    "state": "GA",
    "city": "Screven"
  },
  {
    "value": "31562",
    "label": "Saint George - GA 31562",
    "county": "Charlton County",
    "state": "GA",
    "city": "Saint George"
  },
  {
    "value": "31563",
    "label": "Surrency - GA 31563",
    "county": "Appling County",
    "state": "GA",
    "city": "Surrency"
  },
  {
    "value": "31565",
    "label": "Waverly - GA 31565",
    "county": "Camden County",
    "state": "GA",
    "city": "Waverly"
  },
  {
    "value": "31566",
    "label": "Waynesville - GA 31566",
    "county": "Brantley County",
    "state": "GA",
    "city": "Waynesville"
  },
  {
    "value": "31567",
    "label": "West Green - GA 31567",
    "county": "Coffee County",
    "state": "GA",
    "city": "West Green"
  },
  {
    "value": "31568",
    "label": "White Oak - GA 31568",
    "county": "Camden County",
    "state": "GA",
    "city": "White Oak"
  },
  {
    "value": "31569",
    "label": "Woodbine - GA 31569",
    "county": "Camden County",
    "state": "GA",
    "city": "Woodbine"
  },
  {
    "value": "31601",
    "label": "Valdosta - GA 31601",
    "county": "Lowndes County",
    "state": "GA",
    "city": "Valdosta"
  },
  {
    "value": "31602",
    "label": "Valdosta - GA 31602",
    "county": "Lowndes County",
    "state": "GA",
    "city": "Valdosta"
  },
  {
    "value": "31605",
    "label": "Valdosta - GA 31605",
    "county": "Lowndes County",
    "state": "GA",
    "city": "Valdosta"
  },
  {
    "value": "31606",
    "label": "Valdosta - GA 31606",
    "county": "Lowndes County",
    "state": "GA",
    "city": "Valdosta"
  },
  {
    "value": "31620",
    "label": "Adel - GA 31620",
    "county": "Cook County",
    "state": "GA",
    "city": "Adel"
  },
  {
    "value": "31622",
    "label": "Alapaha - GA 31622",
    "county": "Berrien County",
    "state": "GA",
    "city": "Alapaha"
  },
  {
    "value": "31624",
    "label": "Axson - GA 31624",
    "county": "Atkinson County",
    "state": "GA",
    "city": "Axson"
  },
  {
    "value": "31625",
    "label": "Barney - GA 31625",
    "county": "Brooks County",
    "state": "GA",
    "city": "Barney"
  },
  {
    "value": "31626",
    "label": "Boston - GA 31626",
    "county": "Thomas County",
    "state": "GA",
    "city": "Boston"
  },
  {
    "value": "31629",
    "label": "Dixie - GA 31629",
    "county": "Brooks County",
    "state": "GA",
    "city": "Dixie"
  },
  {
    "value": "31630",
    "label": "Du Pont - GA 31630",
    "county": "Clinch County",
    "state": "GA",
    "city": "Du Pont"
  },
  {
    "value": "31631",
    "label": "Fargo - GA 31631",
    "county": "Clinch County",
    "state": "GA",
    "city": "Fargo"
  },
  {
    "value": "31632",
    "label": "Hahira - GA 31632",
    "county": "Lowndes County",
    "state": "GA",
    "city": "Hahira"
  },
  {
    "value": "31634",
    "label": "Homerville - GA 31634",
    "county": "Clinch County",
    "state": "GA",
    "city": "Homerville"
  },
  {
    "value": "31635",
    "label": "Lakeland - GA 31635",
    "county": "Lanier County",
    "state": "GA",
    "city": "Lakeland"
  },
  {
    "value": "31636",
    "label": "Lake Park - GA 31636",
    "county": "Lowndes County",
    "state": "GA",
    "city": "Lake Park"
  },
  {
    "value": "31637",
    "label": "Lenox - GA 31637",
    "county": "Cook County",
    "state": "GA",
    "city": "Lenox"
  },
  {
    "value": "31638",
    "label": "Morven - GA 31638",
    "county": "Brooks County",
    "state": "GA",
    "city": "Morven"
  },
  {
    "value": "31639",
    "label": "Nashville - GA 31639",
    "county": "Berrien County",
    "state": "GA",
    "city": "Nashville"
  },
  {
    "value": "31641",
    "label": "Naylor - GA 31641",
    "county": "Lowndes County",
    "state": "GA",
    "city": "Naylor"
  },
  {
    "value": "31642",
    "label": "Pearson - GA 31642",
    "county": "Atkinson County",
    "state": "GA",
    "city": "Pearson"
  },
  {
    "value": "31643",
    "label": "Quitman - GA 31643",
    "county": "Brooks County",
    "state": "GA",
    "city": "Quitman"
  },
  {
    "value": "31645",
    "label": "Ray City - GA 31645",
    "county": "Berrien County",
    "state": "GA",
    "city": "Ray City"
  },
  {
    "value": "31647",
    "label": "Sparks - GA 31647",
    "county": "Cook County",
    "state": "GA",
    "city": "Sparks"
  },
  {
    "value": "31648",
    "label": "Statenville - GA 31648",
    "county": "Echols County",
    "state": "GA",
    "city": "Statenville"
  },
  {
    "value": "31649",
    "label": "Stockton - GA 31649",
    "county": "Lanier County",
    "state": "GA",
    "city": "Stockton"
  },
  {
    "value": "31650",
    "label": "Willacoochee - GA 31650",
    "county": "Atkinson County",
    "state": "GA",
    "city": "Willacoochee"
  },
  {
    "value": "31701",
    "label": "Albany - GA 31701",
    "county": "Dougherty County",
    "state": "GA",
    "city": "Albany"
  },
  {
    "value": "31705",
    "label": "Albany - GA 31705",
    "county": "Dougherty County",
    "state": "GA",
    "city": "Albany"
  },
  {
    "value": "31707",
    "label": "Albany - GA 31707",
    "county": "Dougherty County",
    "state": "GA",
    "city": "Albany"
  },
  {
    "value": "31709",
    "label": "Americus - GA 31709",
    "county": "Sumter County",
    "state": "GA",
    "city": "Americus"
  },
  {
    "value": "31711",
    "label": "Andersonville - GA 31711",
    "county": "Sumter County",
    "state": "GA",
    "city": "Andersonville"
  },
  {
    "value": "31712",
    "label": "Arabi - GA 31712",
    "county": "Crisp County",
    "state": "GA",
    "city": "Arabi"
  },
  {
    "value": "31714",
    "label": "Ashburn - GA 31714",
    "county": "Turner County",
    "state": "GA",
    "city": "Ashburn"
  },
  {
    "value": "31716",
    "label": "Baconton - GA 31716",
    "county": "Mitchell County",
    "state": "GA",
    "city": "Baconton"
  },
  {
    "value": "31719",
    "label": "Americus - GA 31719",
    "county": "Sumter County",
    "state": "GA",
    "city": "Americus"
  },
  {
    "value": "31721",
    "label": "Albany - GA 31721",
    "county": "Dougherty County",
    "state": "GA",
    "city": "Albany"
  },
  {
    "value": "31730",
    "label": "Camilla - GA 31730",
    "county": "Mitchell County",
    "state": "GA",
    "city": "Camilla"
  },
  {
    "value": "31733",
    "label": "Chula - GA 31733",
    "county": "Tift County",
    "state": "GA",
    "city": "Chula"
  },
  {
    "value": "31735",
    "label": "Cobb - GA 31735",
    "county": "Sumter County",
    "state": "GA",
    "city": "Cobb"
  },
  {
    "value": "31738",
    "label": "Coolidge - GA 31738",
    "county": "Thomas County",
    "state": "GA",
    "city": "Coolidge"
  },
  {
    "value": "31743",
    "label": "De Soto - GA 31743",
    "county": "Sumter County",
    "state": "GA",
    "city": "De Soto"
  },
  {
    "value": "31744",
    "label": "Doerun - GA 31744",
    "county": "Colquitt County",
    "state": "GA",
    "city": "Doerun"
  },
  {
    "value": "31749",
    "label": "Enigma - GA 31749",
    "county": "Berrien County",
    "state": "GA",
    "city": "Enigma"
  },
  {
    "value": "31750",
    "label": "Fitzgerald - GA 31750",
    "county": "Ben Hill County",
    "state": "GA",
    "city": "Fitzgerald"
  },
  {
    "value": "31756",
    "label": "Hartsfield - GA 31756",
    "county": "Colquitt County",
    "state": "GA",
    "city": "Hartsfield"
  },
  {
    "value": "31757",
    "label": "Thomasville - GA 31757",
    "county": "Thomas County",
    "state": "GA",
    "city": "Thomasville"
  },
  {
    "value": "31763",
    "label": "Leesburg - GA 31763",
    "county": "Lee County",
    "state": "GA",
    "city": "Leesburg"
  },
  {
    "value": "31764",
    "label": "Leslie - GA 31764",
    "county": "Sumter County",
    "state": "GA",
    "city": "Leslie"
  },
  {
    "value": "31765",
    "label": "Meigs - GA 31765",
    "county": "Thomas County",
    "state": "GA",
    "city": "Meigs"
  },
  {
    "value": "31768",
    "label": "Moultrie - GA 31768",
    "county": "Colquitt County",
    "state": "GA",
    "city": "Moultrie"
  },
  {
    "value": "31771",
    "label": "Norman Park - GA 31771",
    "county": "Colquitt County",
    "state": "GA",
    "city": "Norman Park"
  },
  {
    "value": "31772",
    "label": "Oakfield - GA 31772",
    "county": "Worth County",
    "state": "GA",
    "city": "Oakfield"
  },
  {
    "value": "31773",
    "label": "Ochlocknee - GA 31773",
    "county": "Thomas County",
    "state": "GA",
    "city": "Ochlocknee"
  },
  {
    "value": "31774",
    "label": "Ocilla - GA 31774",
    "county": "Irwin County",
    "state": "GA",
    "city": "Ocilla"
  },
  {
    "value": "31775",
    "label": "Omega - GA 31775",
    "county": "Tift County",
    "state": "GA",
    "city": "Omega"
  },
  {
    "value": "31778",
    "label": "Pavo - GA 31778",
    "county": "Thomas County",
    "state": "GA",
    "city": "Pavo"
  },
  {
    "value": "31779",
    "label": "Pelham - GA 31779",
    "county": "Mitchell County",
    "state": "GA",
    "city": "Pelham"
  },
  {
    "value": "31780",
    "label": "Plains - GA 31780",
    "county": "Sumter County",
    "state": "GA",
    "city": "Plains"
  },
  {
    "value": "31781",
    "label": "Poulan - GA 31781",
    "county": "Worth County",
    "state": "GA",
    "city": "Poulan"
  },
  {
    "value": "31783",
    "label": "Rebecca - GA 31783",
    "county": "Turner County",
    "state": "GA",
    "city": "Rebecca"
  },
  {
    "value": "31784",
    "label": "Sale City - GA 31784",
    "county": "Mitchell County",
    "state": "GA",
    "city": "Sale City"
  },
  {
    "value": "31787",
    "label": "Smithville - GA 31787",
    "county": "Lee County",
    "state": "GA",
    "city": "Smithville"
  },
  {
    "value": "31788",
    "label": "Moultrie - GA 31788",
    "county": "Colquitt County",
    "state": "GA",
    "city": "Moultrie"
  },
  {
    "value": "31789",
    "label": "Sumner - GA 31789",
    "county": "Worth County",
    "state": "GA",
    "city": "Sumner"
  },
  {
    "value": "31790",
    "label": "Sycamore - GA 31790",
    "county": "Turner County",
    "state": "GA",
    "city": "Sycamore"
  },
  {
    "value": "31791",
    "label": "Sylvester - GA 31791",
    "county": "Worth County",
    "state": "GA",
    "city": "Sylvester"
  },
  {
    "value": "31792",
    "label": "Thomasville - GA 31792",
    "county": "Thomas County",
    "state": "GA",
    "city": "Thomasville"
  },
  {
    "value": "31793",
    "label": "Tifton - GA 31793",
    "county": "Tift County",
    "state": "GA",
    "city": "Tifton"
  },
  {
    "value": "31794",
    "label": "Tifton - GA 31794",
    "county": "Tift County",
    "state": "GA",
    "city": "Tifton"
  },
  {
    "value": "31795",
    "label": "TY TY - GA 31795",
    "county": "Tift County",
    "state": "GA",
    "city": "TY TY"
  },
  {
    "value": "31796",
    "label": "Warwick - GA 31796",
    "county": "Worth County",
    "state": "GA",
    "city": "Warwick"
  },
  {
    "value": "31798",
    "label": "Wray - GA 31798",
    "county": "Irwin County",
    "state": "GA",
    "city": "Wray"
  },
  {
    "value": "31801",
    "label": "Box Springs - GA 31801",
    "county": "Marion County",
    "state": "GA",
    "city": "Box Springs"
  },
  {
    "value": "31803",
    "label": "Buena Vista - GA 31803",
    "county": "Marion County",
    "state": "GA",
    "city": "Buena Vista"
  },
  {
    "value": "31804",
    "label": "Cataula - GA 31804",
    "county": "Harris County",
    "state": "GA",
    "city": "Cataula"
  },
  {
    "value": "31805",
    "label": "Cusseta - GA 31805",
    "county": "Chattahoochee County",
    "state": "GA",
    "city": "Cusseta"
  },
  {
    "value": "31806",
    "label": "Ellaville - GA 31806",
    "county": "Schley County",
    "state": "GA",
    "city": "Ellaville"
  },
  {
    "value": "31807",
    "label": "Ellerslie - GA 31807",
    "county": "Harris County",
    "state": "GA",
    "city": "Ellerslie"
  },
  {
    "value": "31808",
    "label": "Fortson - GA 31808",
    "county": "Harris County",
    "state": "GA",
    "city": "Fortson"
  },
  {
    "value": "31811",
    "label": "Hamilton - GA 31811",
    "county": "Harris County",
    "state": "GA",
    "city": "Hamilton"
  },
  {
    "value": "31812",
    "label": "Junction City - GA 31812",
    "county": "Talbot County",
    "state": "GA",
    "city": "Junction City"
  },
  {
    "value": "31815",
    "label": "Lumpkin - GA 31815",
    "county": "Stewart County",
    "state": "GA",
    "city": "Lumpkin"
  },
  {
    "value": "31816",
    "label": "Manchester - GA 31816",
    "county": "Meriwether County",
    "state": "GA",
    "city": "Manchester"
  },
  {
    "value": "31820",
    "label": "Midland - GA 31820",
    "county": "Muscogee County",
    "state": "GA",
    "city": "Midland"
  },
  {
    "value": "31821",
    "label": "Omaha - GA 31821",
    "county": "Stewart County",
    "state": "GA",
    "city": "Omaha"
  },
  {
    "value": "31822",
    "label": "Pine Mountain - GA 31822",
    "county": "Harris County",
    "state": "GA",
    "city": "Pine Mountain"
  },
  {
    "value": "31823",
    "label": "Pine Mountain Valley - GA 31823",
    "county": "Harris County",
    "state": "GA",
    "city": "Pine Mountain Valley"
  },
  {
    "value": "31824",
    "label": "Preston - GA 31824",
    "county": "Webster County",
    "state": "GA",
    "city": "Preston"
  },
  {
    "value": "31825",
    "label": "Richland - GA 31825",
    "county": "Stewart County",
    "state": "GA",
    "city": "Richland"
  },
  {
    "value": "31826",
    "label": "Shiloh - GA 31826",
    "county": "Harris County",
    "state": "GA",
    "city": "Shiloh"
  },
  {
    "value": "31827",
    "label": "Talbotton - GA 31827",
    "county": "Talbot County",
    "state": "GA",
    "city": "Talbotton"
  },
  {
    "value": "31829",
    "label": "Upatoi - GA 31829",
    "county": "Muscogee County",
    "state": "GA",
    "city": "Upatoi"
  },
  {
    "value": "31830",
    "label": "Warm Springs - GA 31830",
    "county": "Meriwether County",
    "state": "GA",
    "city": "Warm Springs"
  },
  {
    "value": "31831",
    "label": "Waverly Hall - GA 31831",
    "county": "Harris County",
    "state": "GA",
    "city": "Waverly Hall"
  },
  {
    "value": "31832",
    "label": "Weston - GA 31832",
    "county": "Webster County",
    "state": "GA",
    "city": "Weston"
  },
  {
    "value": "31833",
    "label": "West Point - GA 31833",
    "county": "Troup County",
    "state": "GA",
    "city": "West Point"
  },
  {
    "value": "31836",
    "label": "Woodland - GA 31836",
    "county": "Talbot County",
    "state": "GA",
    "city": "Woodland"
  },
  {
    "value": "31901",
    "label": "Columbus - GA 31901",
    "county": "Muscogee County",
    "state": "GA",
    "city": "Columbus"
  },
  {
    "value": "31903",
    "label": "Columbus - GA 31903",
    "county": "Muscogee County",
    "state": "GA",
    "city": "Columbus"
  },
  {
    "value": "31904",
    "label": "Columbus - GA 31904",
    "county": "Muscogee County",
    "state": "GA",
    "city": "Columbus"
  },
  {
    "value": "31905",
    "label": "Fort Benning - GA 31905",
    "county": "Chattahoochee County",
    "state": "GA",
    "city": "Fort Benning"
  },
  {
    "value": "31906",
    "label": "Columbus - GA 31906",
    "county": "Muscogee County",
    "state": "GA",
    "city": "Columbus"
  },
  {
    "value": "31907",
    "label": "Columbus - GA 31907",
    "county": "Muscogee County",
    "state": "GA",
    "city": "Columbus"
  },
  {
    "value": "31909",
    "label": "Columbus - GA 31909",
    "county": "Muscogee County",
    "state": "GA",
    "city": "Columbus"
  },
  {
    "value": "39813",
    "label": "Arlington - GA 39813",
    "county": "Calhoun County",
    "state": "GA",
    "city": "Arlington"
  },
  {
    "value": "39815",
    "label": "Attapulgus - GA 39815",
    "county": "Decatur County",
    "state": "GA",
    "city": "Attapulgus"
  },
  {
    "value": "39817",
    "label": "Bainbridge - GA 39817",
    "county": "Decatur County",
    "state": "GA",
    "city": "Bainbridge"
  },
  {
    "value": "39819",
    "label": "Bainbridge - GA 39819",
    "county": "Decatur County",
    "state": "GA",
    "city": "Bainbridge"
  },
  {
    "value": "39823",
    "label": "Blakely - GA 39823",
    "county": "Early County",
    "state": "GA",
    "city": "Blakely"
  },
  {
    "value": "39824",
    "label": "Bluffton - GA 39824",
    "county": "Clay County",
    "state": "GA",
    "city": "Bluffton"
  },
  {
    "value": "39825",
    "label": "Brinson - GA 39825",
    "county": "Decatur County",
    "state": "GA",
    "city": "Brinson"
  },
  {
    "value": "39826",
    "label": "Bronwood - GA 39826",
    "county": "Terrell County",
    "state": "GA",
    "city": "Bronwood"
  },
  {
    "value": "39827",
    "label": "Cairo - GA 39827",
    "county": "Grady County",
    "state": "GA",
    "city": "Cairo"
  },
  {
    "value": "39828",
    "label": "Cairo - GA 39828",
    "county": "Grady County",
    "state": "GA",
    "city": "Cairo"
  },
  {
    "value": "39834",
    "label": "Climax - GA 39834",
    "county": "Decatur County",
    "state": "GA",
    "city": "Climax"
  },
  {
    "value": "39836",
    "label": "Coleman - GA 39836",
    "county": "Randolph County",
    "state": "GA",
    "city": "Coleman"
  },
  {
    "value": "39837",
    "label": "Colquitt - GA 39837",
    "county": "Miller County",
    "state": "GA",
    "city": "Colquitt"
  },
  {
    "value": "39840",
    "label": "Cuthbert - GA 39840",
    "county": "Randolph County",
    "state": "GA",
    "city": "Cuthbert"
  },
  {
    "value": "39841",
    "label": "Damascus - GA 39841",
    "county": "Early County",
    "state": "GA",
    "city": "Damascus"
  },
  {
    "value": "39842",
    "label": "Dawson - GA 39842",
    "county": "Terrell County",
    "state": "GA",
    "city": "Dawson"
  },
  {
    "value": "39845",
    "label": "Donalsonville - GA 39845",
    "county": "Seminole County",
    "state": "GA",
    "city": "Donalsonville"
  },
  {
    "value": "39846",
    "label": "Edison - GA 39846",
    "county": "Calhoun County",
    "state": "GA",
    "city": "Edison"
  },
  {
    "value": "39851",
    "label": "Fort Gaines - GA 39851",
    "county": "Clay County",
    "state": "GA",
    "city": "Fort Gaines"
  },
  {
    "value": "39854",
    "label": "Georgetown - GA 39854",
    "county": "Quitman County",
    "state": "GA",
    "city": "Georgetown"
  },
  {
    "value": "39859",
    "label": "Iron City - GA 39859",
    "county": "Seminole County",
    "state": "GA",
    "city": "Iron City"
  },
  {
    "value": "39861",
    "label": "Jakin - GA 39861",
    "county": "Early County",
    "state": "GA",
    "city": "Jakin"
  },
  {
    "value": "39862",
    "label": "Leary - GA 39862",
    "county": "Calhoun County",
    "state": "GA",
    "city": "Leary"
  },
  {
    "value": "39866",
    "label": "Morgan - GA 39866",
    "county": "Calhoun County",
    "state": "GA",
    "city": "Morgan"
  },
  {
    "value": "39867",
    "label": "Morris - GA 39867",
    "county": "Clay County",
    "state": "GA",
    "city": "Morris"
  },
  {
    "value": "39870",
    "label": "Newton - GA 39870",
    "county": "Baker County",
    "state": "GA",
    "city": "Newton"
  },
  {
    "value": "39877",
    "label": "Parrott - GA 39877",
    "county": "Terrell County",
    "state": "GA",
    "city": "Parrott"
  },
  {
    "value": "39886",
    "label": "Shellman - GA 39886",
    "county": "Randolph County",
    "state": "GA",
    "city": "Shellman"
  },
  {
    "value": "39897",
    "label": "Whigham - GA 39897",
    "county": "Grady County",
    "state": "GA",
    "city": "Whigham"
  },
  {
    "value": "96910",
    "label": "Hagatna - GU 96910",
    "county": "",
    "state": "GU",
    "city": "Hagatna"
  },
  {
    "value": "96913",
    "label": "Barrigada - GU 96913",
    "county": "",
    "state": "GU",
    "city": "Barrigada"
  },
  {
    "value": "96915",
    "label": "Santa Rita - GU 96915",
    "county": "",
    "state": "GU",
    "city": "Santa Rita"
  },
  {
    "value": "96919",
    "label": "Agana Heights - GU 96919",
    "county": "",
    "state": "GU",
    "city": "Agana Heights"
  },
  {
    "value": "96929",
    "label": "Yigo - GU 96929",
    "county": "",
    "state": "GU",
    "city": "Yigo"
  },
  {
    "value": "96701",
    "label": "Aiea - HI 96701",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Aiea"
  },
  {
    "value": "96704",
    "label": "Captain Cook - HI 96704",
    "county": "Hawaii County",
    "state": "HI",
    "city": "Captain Cook"
  },
  {
    "value": "96706",
    "label": "Ewa Beach - HI 96706",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Ewa Beach"
  },
  {
    "value": "96707",
    "label": "Kapolei - HI 96707",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Kapolei"
  },
  {
    "value": "96708",
    "label": "Haiku - HI 96708",
    "county": "Maui County",
    "state": "HI",
    "city": "Haiku"
  },
  {
    "value": "96712",
    "label": "Haleiwa - HI 96712",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Haleiwa"
  },
  {
    "value": "96713",
    "label": "Hana - HI 96713",
    "county": "Maui County",
    "state": "HI",
    "city": "Hana"
  },
  {
    "value": "96717",
    "label": "Hauula - HI 96717",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Hauula"
  },
  {
    "value": "96720",
    "label": "Hilo - HI 96720",
    "county": "Hawaii County",
    "state": "HI",
    "city": "Hilo"
  },
  {
    "value": "96722",
    "label": "Princeville - HI 96722",
    "county": "Kauai County",
    "state": "HI",
    "city": "Princeville"
  },
  {
    "value": "96725",
    "label": "Holualoa - HI 96725",
    "county": "Hawaii County",
    "state": "HI",
    "city": "Holualoa"
  },
  {
    "value": "96727",
    "label": "Honokaa - HI 96727",
    "county": "Hawaii County",
    "state": "HI",
    "city": "Honokaa"
  },
  {
    "value": "96730",
    "label": "Kaaawa - HI 96730",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Kaaawa"
  },
  {
    "value": "96731",
    "label": "Kahuku - HI 96731",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Kahuku"
  },
  {
    "value": "96732",
    "label": "Kahului - HI 96732",
    "county": "Maui County",
    "state": "HI",
    "city": "Kahului"
  },
  {
    "value": "96734",
    "label": "Kailua - HI 96734",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Kailua"
  },
  {
    "value": "96738",
    "label": "Waikoloa - HI 96738",
    "county": "Hawaii County",
    "state": "HI",
    "city": "Waikoloa"
  },
  {
    "value": "96740",
    "label": "Kailua Kona - HI 96740",
    "county": "Hawaii County",
    "state": "HI",
    "city": "Kailua Kona"
  },
  {
    "value": "96741",
    "label": "Kalaheo - HI 96741",
    "county": "Kauai County",
    "state": "HI",
    "city": "Kalaheo"
  },
  {
    "value": "96743",
    "label": "Kamuela - HI 96743",
    "county": "Hawaii County",
    "state": "HI",
    "city": "Kamuela"
  },
  {
    "value": "96744",
    "label": "Kaneohe - HI 96744",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Kaneohe"
  },
  {
    "value": "96746",
    "label": "Kapaa - HI 96746",
    "county": "Kauai County",
    "state": "HI",
    "city": "Kapaa"
  },
  {
    "value": "96748",
    "label": "Kaunakakai - HI 96748",
    "county": "Maui County",
    "state": "HI",
    "city": "Kaunakakai"
  },
  {
    "value": "96749",
    "label": "Keaau - HI 96749",
    "county": "Hawaii County",
    "state": "HI",
    "city": "Keaau"
  },
  {
    "value": "96750",
    "label": "Kealakekua - HI 96750",
    "county": "Hawaii County",
    "state": "HI",
    "city": "Kealakekua"
  },
  {
    "value": "96753",
    "label": "Kihei - HI 96753",
    "county": "Maui County",
    "state": "HI",
    "city": "Kihei"
  },
  {
    "value": "96754",
    "label": "Kilauea - HI 96754",
    "county": "Kauai County",
    "state": "HI",
    "city": "Kilauea"
  },
  {
    "value": "96756",
    "label": "Koloa - HI 96756",
    "county": "Kauai County",
    "state": "HI",
    "city": "Koloa"
  },
  {
    "value": "96760",
    "label": "Kurtistown - HI 96760",
    "county": "Hawaii County",
    "state": "HI",
    "city": "Kurtistown"
  },
  {
    "value": "96761",
    "label": "Lahaina - HI 96761",
    "county": "Maui County",
    "state": "HI",
    "city": "Lahaina"
  },
  {
    "value": "96762",
    "label": "Laie - HI 96762",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Laie"
  },
  {
    "value": "96766",
    "label": "Lihue - HI 96766",
    "county": "Kauai County",
    "state": "HI",
    "city": "Lihue"
  },
  {
    "value": "96768",
    "label": "Makawao - HI 96768",
    "county": "Maui County",
    "state": "HI",
    "city": "Makawao"
  },
  {
    "value": "96771",
    "label": "Mountain View - HI 96771",
    "county": "Hawaii County",
    "state": "HI",
    "city": "Mountain View"
  },
  {
    "value": "96778",
    "label": "Pahoa - HI 96778",
    "county": "Hawaii County",
    "state": "HI",
    "city": "Pahoa"
  },
  {
    "value": "96779",
    "label": "Paia - HI 96779",
    "county": "Maui County",
    "state": "HI",
    "city": "Paia"
  },
  {
    "value": "96781",
    "label": "Papaikou - HI 96781",
    "county": "Hawaii County",
    "state": "HI",
    "city": "Papaikou"
  },
  {
    "value": "96782",
    "label": "Pearl City - HI 96782",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Pearl City"
  },
  {
    "value": "96783",
    "label": "Pepeekeo - HI 96783",
    "county": "Hawaii County",
    "state": "HI",
    "city": "Pepeekeo"
  },
  {
    "value": "96786",
    "label": "Wahiawa - HI 96786",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Wahiawa"
  },
  {
    "value": "96789",
    "label": "Mililani - HI 96789",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Mililani"
  },
  {
    "value": "96790",
    "label": "Kula - HI 96790",
    "county": "Maui County",
    "state": "HI",
    "city": "Kula"
  },
  {
    "value": "96791",
    "label": "Waialua - HI 96791",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Waialua"
  },
  {
    "value": "96792",
    "label": "Waianae - HI 96792",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Waianae"
  },
  {
    "value": "96793",
    "label": "Wailuku - HI 96793",
    "county": "Maui County",
    "state": "HI",
    "city": "Wailuku"
  },
  {
    "value": "96795",
    "label": "Waimanalo - HI 96795",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Waimanalo"
  },
  {
    "value": "96797",
    "label": "Waipahu - HI 96797",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Waipahu"
  },
  {
    "value": "96813",
    "label": "Honolulu - HI 96813",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Honolulu"
  },
  {
    "value": "96814",
    "label": "Honolulu - HI 96814",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Honolulu"
  },
  {
    "value": "96815",
    "label": "Honolulu - HI 96815",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Honolulu"
  },
  {
    "value": "96816",
    "label": "Honolulu - HI 96816",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Honolulu"
  },
  {
    "value": "96817",
    "label": "Honolulu - HI 96817",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Honolulu"
  },
  {
    "value": "96818",
    "label": "Honolulu - HI 96818",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Honolulu"
  },
  {
    "value": "96819",
    "label": "Honolulu - HI 96819",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Honolulu"
  },
  {
    "value": "96821",
    "label": "Honolulu - HI 96821",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Honolulu"
  },
  {
    "value": "96822",
    "label": "Honolulu - HI 96822",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Honolulu"
  },
  {
    "value": "96825",
    "label": "Honolulu - HI 96825",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Honolulu"
  },
  {
    "value": "96826",
    "label": "Honolulu - HI 96826",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Honolulu"
  },
  {
    "value": "96827",
    "label": "Honolulu - HI 96827",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Honolulu"
  },
  {
    "value": "96835",
    "label": "Honolulu - HI 96835",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Honolulu"
  },
  {
    "value": "96850",
    "label": "Honolulu - HI 96850",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Honolulu"
  },
  {
    "value": "96853",
    "label": "Jbphh - HI 96853",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Jbphh"
  },
  {
    "value": "96854",
    "label": "Wheeler Army Airfield - HI 96854",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Wheeler Army Airfield"
  },
  {
    "value": "96857",
    "label": "Schofield Barracks - HI 96857",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Schofield Barracks"
  },
  {
    "value": "96858",
    "label": "Fort Shafter - HI 96858",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Fort Shafter"
  },
  {
    "value": "96859",
    "label": "Tripler Army Medical Center - HI 96859",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Tripler Army Medical Center"
  },
  {
    "value": "96860",
    "label": "Jbphh - HI 96860",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Jbphh"
  },
  {
    "value": "96861",
    "label": "Camp H M Smith - HI 96861",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Camp H M Smith"
  },
  {
    "value": "96863",
    "label": "Mcbh Kaneohe Bay - HI 96863",
    "county": "Honolulu County",
    "state": "HI",
    "city": "Mcbh Kaneohe Bay"
  },
  {
    "value": "83201",
    "label": "Pocatello - ID 83201",
    "county": "Bannock County",
    "state": "ID",
    "city": "Pocatello"
  },
  {
    "value": "83202",
    "label": "Pocatello - ID 83202",
    "county": "Bannock County",
    "state": "ID",
    "city": "Pocatello"
  },
  {
    "value": "83204",
    "label": "Pocatello - ID 83204",
    "county": "Bannock County",
    "state": "ID",
    "city": "Pocatello"
  },
  {
    "value": "83210",
    "label": "Aberdeen - ID 83210",
    "county": "Bingham County",
    "state": "ID",
    "city": "Aberdeen"
  },
  {
    "value": "83211",
    "label": "American Falls - ID 83211",
    "county": "Power County",
    "state": "ID",
    "city": "American Falls"
  },
  {
    "value": "83212",
    "label": "Arbon - ID 83212",
    "county": "Power County",
    "state": "ID",
    "city": "Arbon"
  },
  {
    "value": "83213",
    "label": "Arco - ID 83213",
    "county": "Butte County",
    "state": "ID",
    "city": "Arco"
  },
  {
    "value": "83214",
    "label": "Arimo - ID 83214",
    "county": "Bannock County",
    "state": "ID",
    "city": "Arimo"
  },
  {
    "value": "83215",
    "label": "Atomic City - ID 83215",
    "county": "Bingham County",
    "state": "ID",
    "city": "Atomic City"
  },
  {
    "value": "83217",
    "label": "Bancroft - ID 83217",
    "county": "Caribou County",
    "state": "ID",
    "city": "Bancroft"
  },
  {
    "value": "83220",
    "label": "Bern - ID 83220",
    "county": "Bear Lake County",
    "state": "ID",
    "city": "Bern"
  },
  {
    "value": "83221",
    "label": "Blackfoot - ID 83221",
    "county": "Bingham County",
    "state": "ID",
    "city": "Blackfoot"
  },
  {
    "value": "83226",
    "label": "Challis - ID 83226",
    "county": "Custer County",
    "state": "ID",
    "city": "Challis"
  },
  {
    "value": "83227",
    "label": "Clayton - ID 83227",
    "county": "Custer County",
    "state": "ID",
    "city": "Clayton"
  },
  {
    "value": "83228",
    "label": "Clifton - ID 83228",
    "county": "Franklin County",
    "state": "ID",
    "city": "Clifton"
  },
  {
    "value": "83229",
    "label": "Cobalt - ID 83229",
    "county": "Lemhi County",
    "state": "ID",
    "city": "Cobalt"
  },
  {
    "value": "83230",
    "label": "Conda - ID 83230",
    "county": "Caribou County",
    "state": "ID",
    "city": "Conda"
  },
  {
    "value": "83232",
    "label": "Dayton - ID 83232",
    "county": "Franklin County",
    "state": "ID",
    "city": "Dayton"
  },
  {
    "value": "83234",
    "label": "Downey - ID 83234",
    "county": "Bannock County",
    "state": "ID",
    "city": "Downey"
  },
  {
    "value": "83235",
    "label": "Ellis - ID 83235",
    "county": "Custer County",
    "state": "ID",
    "city": "Ellis"
  },
  {
    "value": "83236",
    "label": "Firth - ID 83236",
    "county": "Bingham County",
    "state": "ID",
    "city": "Firth"
  },
  {
    "value": "83237",
    "label": "Franklin - ID 83237",
    "county": "Franklin County",
    "state": "ID",
    "city": "Franklin"
  },
  {
    "value": "83238",
    "label": "Geneva - ID 83238",
    "county": "Bear Lake County",
    "state": "ID",
    "city": "Geneva"
  },
  {
    "value": "83241",
    "label": "Grace - ID 83241",
    "county": "Caribou County",
    "state": "ID",
    "city": "Grace"
  },
  {
    "value": "83243",
    "label": "Holbrook - ID 83243",
    "county": "Oneida County",
    "state": "ID",
    "city": "Holbrook"
  },
  {
    "value": "83244",
    "label": "Howe - ID 83244",
    "county": "Butte County",
    "state": "ID",
    "city": "Howe"
  },
  {
    "value": "83245",
    "label": "Inkom - ID 83245",
    "county": "Bannock County",
    "state": "ID",
    "city": "Inkom"
  },
  {
    "value": "83246",
    "label": "Lava Hot Springs - ID 83246",
    "county": "Bannock County",
    "state": "ID",
    "city": "Lava Hot Springs"
  },
  {
    "value": "83250",
    "label": "Mccammon - ID 83250",
    "county": "Bannock County",
    "state": "ID",
    "city": "Mccammon"
  },
  {
    "value": "83251",
    "label": "Mackay - ID 83251",
    "county": "Custer County",
    "state": "ID",
    "city": "Mackay"
  },
  {
    "value": "83252",
    "label": "Malad City - ID 83252",
    "county": "Oneida County",
    "state": "ID",
    "city": "Malad City"
  },
  {
    "value": "83253",
    "label": "May - ID 83253",
    "county": "Lemhi County",
    "state": "ID",
    "city": "May"
  },
  {
    "value": "83254",
    "label": "Montpelier - ID 83254",
    "county": "Bear Lake County",
    "state": "ID",
    "city": "Montpelier"
  },
  {
    "value": "83255",
    "label": "Moore - ID 83255",
    "county": "Butte County",
    "state": "ID",
    "city": "Moore"
  },
  {
    "value": "83262",
    "label": "Pingree - ID 83262",
    "county": "Bingham County",
    "state": "ID",
    "city": "Pingree"
  },
  {
    "value": "83263",
    "label": "Preston - ID 83263",
    "county": "Franklin County",
    "state": "ID",
    "city": "Preston"
  },
  {
    "value": "83271",
    "label": "Rockland - ID 83271",
    "county": "Power County",
    "state": "ID",
    "city": "Rockland"
  },
  {
    "value": "83272",
    "label": "Saint Charles - ID 83272",
    "county": "Bear Lake County",
    "state": "ID",
    "city": "Saint Charles"
  },
  {
    "value": "83274",
    "label": "Shelley - ID 83274",
    "county": "Bingham County",
    "state": "ID",
    "city": "Shelley"
  },
  {
    "value": "83276",
    "label": "Soda Springs - ID 83276",
    "county": "Caribou County",
    "state": "ID",
    "city": "Soda Springs"
  },
  {
    "value": "83277",
    "label": "Springfield - ID 83277",
    "county": "Bingham County",
    "state": "ID",
    "city": "Springfield"
  },
  {
    "value": "83278",
    "label": "Stanley - ID 83278",
    "county": "Custer County",
    "state": "ID",
    "city": "Stanley"
  },
  {
    "value": "83283",
    "label": "Thatcher - ID 83283",
    "county": "Franklin County",
    "state": "ID",
    "city": "Thatcher"
  },
  {
    "value": "83285",
    "label": "Wayan - ID 83285",
    "county": "Bonneville County",
    "state": "ID",
    "city": "Wayan"
  },
  {
    "value": "83286",
    "label": "Weston - ID 83286",
    "county": "Franklin County",
    "state": "ID",
    "city": "Weston"
  },
  {
    "value": "83287",
    "label": "Fish Haven - ID 83287",
    "county": "Bear Lake County",
    "state": "ID",
    "city": "Fish Haven"
  },
  {
    "value": "83301",
    "label": "Twin Falls - ID 83301",
    "county": "Twin Falls County",
    "state": "ID",
    "city": "Twin Falls"
  },
  {
    "value": "83302",
    "label": "Rogerson - ID 83302",
    "county": "Twin Falls County",
    "state": "ID",
    "city": "Rogerson"
  },
  {
    "value": "83311",
    "label": "Albion - ID 83311",
    "county": "Cassia County",
    "state": "ID",
    "city": "Albion"
  },
  {
    "value": "83313",
    "label": "Bellevue - ID 83313",
    "county": "Blaine County",
    "state": "ID",
    "city": "Bellevue"
  },
  {
    "value": "83314",
    "label": "Bliss - ID 83314",
    "county": "Gooding County",
    "state": "ID",
    "city": "Bliss"
  },
  {
    "value": "83316",
    "label": "Buhl - ID 83316",
    "county": "Twin Falls County",
    "state": "ID",
    "city": "Buhl"
  },
  {
    "value": "83318",
    "label": "Burley - ID 83318",
    "county": "Cassia County",
    "state": "ID",
    "city": "Burley"
  },
  {
    "value": "83320",
    "label": "Carey - ID 83320",
    "county": "Blaine County",
    "state": "ID",
    "city": "Carey"
  },
  {
    "value": "83321",
    "label": "Castleford - ID 83321",
    "county": "Twin Falls County",
    "state": "ID",
    "city": "Castleford"
  },
  {
    "value": "83322",
    "label": "Corral - ID 83322",
    "county": "Camas County",
    "state": "ID",
    "city": "Corral"
  },
  {
    "value": "83323",
    "label": "Declo - ID 83323",
    "county": "Cassia County",
    "state": "ID",
    "city": "Declo"
  },
  {
    "value": "83324",
    "label": "Dietrich - ID 83324",
    "county": "Lincoln County",
    "state": "ID",
    "city": "Dietrich"
  },
  {
    "value": "83325",
    "label": "Eden - ID 83325",
    "county": "Jerome County",
    "state": "ID",
    "city": "Eden"
  },
  {
    "value": "83327",
    "label": "Fairfield - ID 83327",
    "county": "Camas County",
    "state": "ID",
    "city": "Fairfield"
  },
  {
    "value": "83328",
    "label": "Filer - ID 83328",
    "county": "Twin Falls County",
    "state": "ID",
    "city": "Filer"
  },
  {
    "value": "83330",
    "label": "Gooding - ID 83330",
    "county": "Gooding County",
    "state": "ID",
    "city": "Gooding"
  },
  {
    "value": "83332",
    "label": "Hagerman - ID 83332",
    "county": "Gooding County",
    "state": "ID",
    "city": "Hagerman"
  },
  {
    "value": "83333",
    "label": "Hailey - ID 83333",
    "county": "Blaine County",
    "state": "ID",
    "city": "Hailey"
  },
  {
    "value": "83334",
    "label": "Hansen - ID 83334",
    "county": "Twin Falls County",
    "state": "ID",
    "city": "Hansen"
  },
  {
    "value": "83335",
    "label": "Hazelton - ID 83335",
    "county": "Jerome County",
    "state": "ID",
    "city": "Hazelton"
  },
  {
    "value": "83336",
    "label": "Heyburn - ID 83336",
    "county": "Minidoka County",
    "state": "ID",
    "city": "Heyburn"
  },
  {
    "value": "83337",
    "label": "Hill City - ID 83337",
    "county": "Camas County",
    "state": "ID",
    "city": "Hill City"
  },
  {
    "value": "83338",
    "label": "Jerome - ID 83338",
    "county": "Jerome County",
    "state": "ID",
    "city": "Jerome"
  },
  {
    "value": "83340",
    "label": "Ketchum - ID 83340",
    "county": "Blaine County",
    "state": "ID",
    "city": "Ketchum"
  },
  {
    "value": "83341",
    "label": "Kimberly - ID 83341",
    "county": "Twin Falls County",
    "state": "ID",
    "city": "Kimberly"
  },
  {
    "value": "83342",
    "label": "Malta - ID 83342",
    "county": "Cassia County",
    "state": "ID",
    "city": "Malta"
  },
  {
    "value": "83344",
    "label": "Murtaugh - ID 83344",
    "county": "Twin Falls County",
    "state": "ID",
    "city": "Murtaugh"
  },
  {
    "value": "83346",
    "label": "Oakley - ID 83346",
    "county": "Cassia County",
    "state": "ID",
    "city": "Oakley"
  },
  {
    "value": "83347",
    "label": "Paul - ID 83347",
    "county": "Minidoka County",
    "state": "ID",
    "city": "Paul"
  },
  {
    "value": "83348",
    "label": "Picabo - ID 83348",
    "county": "Blaine County",
    "state": "ID",
    "city": "Picabo"
  },
  {
    "value": "83349",
    "label": "Richfield - ID 83349",
    "county": "Lincoln County",
    "state": "ID",
    "city": "Richfield"
  },
  {
    "value": "83350",
    "label": "Rupert - ID 83350",
    "county": "Minidoka County",
    "state": "ID",
    "city": "Rupert"
  },
  {
    "value": "83352",
    "label": "Shoshone - ID 83352",
    "county": "Lincoln County",
    "state": "ID",
    "city": "Shoshone"
  },
  {
    "value": "83355",
    "label": "Wendell - ID 83355",
    "county": "Gooding County",
    "state": "ID",
    "city": "Wendell"
  },
  {
    "value": "83401",
    "label": "Idaho Falls - ID 83401",
    "county": "Bonneville County",
    "state": "ID",
    "city": "Idaho Falls"
  },
  {
    "value": "83402",
    "label": "Idaho Falls - ID 83402",
    "county": "Bonneville County",
    "state": "ID",
    "city": "Idaho Falls"
  },
  {
    "value": "83404",
    "label": "Idaho Falls - ID 83404",
    "county": "Bonneville County",
    "state": "ID",
    "city": "Idaho Falls"
  },
  {
    "value": "83406",
    "label": "Idaho Falls - ID 83406",
    "county": "Bonneville County",
    "state": "ID",
    "city": "Idaho Falls"
  },
  {
    "value": "83420",
    "label": "Ashton - ID 83420",
    "county": "Fremont County",
    "state": "ID",
    "city": "Ashton"
  },
  {
    "value": "83422",
    "label": "Driggs - ID 83422",
    "county": "Teton County",
    "state": "ID",
    "city": "Driggs"
  },
  {
    "value": "83423",
    "label": "Dubois - ID 83423",
    "county": "Clark County",
    "state": "ID",
    "city": "Dubois"
  },
  {
    "value": "83424",
    "label": "Felt - ID 83424",
    "county": "Teton County",
    "state": "ID",
    "city": "Felt"
  },
  {
    "value": "83425",
    "label": "Hamer - ID 83425",
    "county": "Jefferson County",
    "state": "ID",
    "city": "Hamer"
  },
  {
    "value": "83427",
    "label": "Iona - ID 83427",
    "county": "Bonneville County",
    "state": "ID",
    "city": "Iona"
  },
  {
    "value": "83428",
    "label": "Irwin - ID 83428",
    "county": "Bonneville County",
    "state": "ID",
    "city": "Irwin"
  },
  {
    "value": "83429",
    "label": "Island Park - ID 83429",
    "county": "Fremont County",
    "state": "ID",
    "city": "Island Park"
  },
  {
    "value": "83431",
    "label": "Lewisville - ID 83431",
    "county": "Jefferson County",
    "state": "ID",
    "city": "Lewisville"
  },
  {
    "value": "83434",
    "label": "Menan - ID 83434",
    "county": "Jefferson County",
    "state": "ID",
    "city": "Menan"
  },
  {
    "value": "83435",
    "label": "Monteview - ID 83435",
    "county": "Jefferson County",
    "state": "ID",
    "city": "Monteview"
  },
  {
    "value": "83436",
    "label": "Newdale - ID 83436",
    "county": "Fremont County",
    "state": "ID",
    "city": "Newdale"
  },
  {
    "value": "83440",
    "label": "Rexburg - ID 83440",
    "county": "Madison County",
    "state": "ID",
    "city": "Rexburg"
  },
  {
    "value": "83442",
    "label": "Rigby - ID 83442",
    "county": "Jefferson County",
    "state": "ID",
    "city": "Rigby"
  },
  {
    "value": "83443",
    "label": "Ririe - ID 83443",
    "county": "Jefferson County",
    "state": "ID",
    "city": "Ririe"
  },
  {
    "value": "83444",
    "label": "Roberts - ID 83444",
    "county": "Jefferson County",
    "state": "ID",
    "city": "Roberts"
  },
  {
    "value": "83445",
    "label": "Saint Anthony - ID 83445",
    "county": "Fremont County",
    "state": "ID",
    "city": "Saint Anthony"
  },
  {
    "value": "83446",
    "label": "Spencer - ID 83446",
    "county": "Clark County",
    "state": "ID",
    "city": "Spencer"
  },
  {
    "value": "83448",
    "label": "Sugar City - ID 83448",
    "county": "Madison County",
    "state": "ID",
    "city": "Sugar City"
  },
  {
    "value": "83449",
    "label": "Swan Valley - ID 83449",
    "county": "Bonneville County",
    "state": "ID",
    "city": "Swan Valley"
  },
  {
    "value": "83450",
    "label": "Terreton - ID 83450",
    "county": "Jefferson County",
    "state": "ID",
    "city": "Terreton"
  },
  {
    "value": "83451",
    "label": "Teton - ID 83451",
    "county": "Fremont County",
    "state": "ID",
    "city": "Teton"
  },
  {
    "value": "83452",
    "label": "Tetonia - ID 83452",
    "county": "Teton County",
    "state": "ID",
    "city": "Tetonia"
  },
  {
    "value": "83455",
    "label": "Victor - ID 83455",
    "county": "Teton County",
    "state": "ID",
    "city": "Victor"
  },
  {
    "value": "83462",
    "label": "Carmen - ID 83462",
    "county": "Lemhi County",
    "state": "ID",
    "city": "Carmen"
  },
  {
    "value": "83463",
    "label": "Gibbonsville - ID 83463",
    "county": "Lemhi County",
    "state": "ID",
    "city": "Gibbonsville"
  },
  {
    "value": "83464",
    "label": "Leadore - ID 83464",
    "county": "Lemhi County",
    "state": "ID",
    "city": "Leadore"
  },
  {
    "value": "83466",
    "label": "North Fork - ID 83466",
    "county": "Lemhi County",
    "state": "ID",
    "city": "North Fork"
  },
  {
    "value": "83467",
    "label": "Salmon - ID 83467",
    "county": "Lemhi County",
    "state": "ID",
    "city": "Salmon"
  },
  {
    "value": "83469",
    "label": "Shoup - ID 83469",
    "county": "Lemhi County",
    "state": "ID",
    "city": "Shoup"
  },
  {
    "value": "83501",
    "label": "Lewiston - ID 83501",
    "county": "Nez Perce County",
    "state": "ID",
    "city": "Lewiston"
  },
  {
    "value": "83520",
    "label": "Ahsahka - ID 83520",
    "county": "Clearwater County",
    "state": "ID",
    "city": "Ahsahka"
  },
  {
    "value": "83522",
    "label": "Cottonwood - ID 83522",
    "county": "Idaho County",
    "state": "ID",
    "city": "Cottonwood"
  },
  {
    "value": "83523",
    "label": "Craigmont - ID 83523",
    "county": "Lewis County",
    "state": "ID",
    "city": "Craigmont"
  },
  {
    "value": "83524",
    "label": "Culdesac - ID 83524",
    "county": "Nez Perce County",
    "state": "ID",
    "city": "Culdesac"
  },
  {
    "value": "83525",
    "label": "Elk City - ID 83525",
    "county": "Idaho County",
    "state": "ID",
    "city": "Elk City"
  },
  {
    "value": "83526",
    "label": "Ferdinand - ID 83526",
    "county": "Idaho County",
    "state": "ID",
    "city": "Ferdinand"
  },
  {
    "value": "83530",
    "label": "Grangeville - ID 83530",
    "county": "Idaho County",
    "state": "ID",
    "city": "Grangeville"
  },
  {
    "value": "83533",
    "label": "Greencreek - ID 83533",
    "county": "Idaho County",
    "state": "ID",
    "city": "Greencreek"
  },
  {
    "value": "83535",
    "label": "Juliaetta - ID 83535",
    "county": "Latah County",
    "state": "ID",
    "city": "Juliaetta"
  },
  {
    "value": "83536",
    "label": "Kamiah - ID 83536",
    "county": "Idaho County",
    "state": "ID",
    "city": "Kamiah"
  },
  {
    "value": "83537",
    "label": "Kendrick - ID 83537",
    "county": "Latah County",
    "state": "ID",
    "city": "Kendrick"
  },
  {
    "value": "83539",
    "label": "Kooskia - ID 83539",
    "county": "Idaho County",
    "state": "ID",
    "city": "Kooskia"
  },
  {
    "value": "83540",
    "label": "Lapwai - ID 83540",
    "county": "Nez Perce County",
    "state": "ID",
    "city": "Lapwai"
  },
  {
    "value": "83541",
    "label": "Lenore - ID 83541",
    "county": "Clearwater County",
    "state": "ID",
    "city": "Lenore"
  },
  {
    "value": "83542",
    "label": "Lucile - ID 83542",
    "county": "Idaho County",
    "state": "ID",
    "city": "Lucile"
  },
  {
    "value": "83543",
    "label": "Nezperce - ID 83543",
    "county": "Lewis County",
    "state": "ID",
    "city": "Nezperce"
  },
  {
    "value": "83544",
    "label": "Orofino - ID 83544",
    "county": "Clearwater County",
    "state": "ID",
    "city": "Orofino"
  },
  {
    "value": "83545",
    "label": "Peck - ID 83545",
    "county": "Nez Perce County",
    "state": "ID",
    "city": "Peck"
  },
  {
    "value": "83546",
    "label": "Pierce - ID 83546",
    "county": "Clearwater County",
    "state": "ID",
    "city": "Pierce"
  },
  {
    "value": "83547",
    "label": "Pollock - ID 83547",
    "county": "Idaho County",
    "state": "ID",
    "city": "Pollock"
  },
  {
    "value": "83548",
    "label": "Reubens - ID 83548",
    "county": "Lewis County",
    "state": "ID",
    "city": "Reubens"
  },
  {
    "value": "83549",
    "label": "Riggins - ID 83549",
    "county": "Idaho County",
    "state": "ID",
    "city": "Riggins"
  },
  {
    "value": "83552",
    "label": "Stites - ID 83552",
    "county": "Idaho County",
    "state": "ID",
    "city": "Stites"
  },
  {
    "value": "83553",
    "label": "Weippe - ID 83553",
    "county": "Clearwater County",
    "state": "ID",
    "city": "Weippe"
  },
  {
    "value": "83554",
    "label": "White Bird - ID 83554",
    "county": "Idaho County",
    "state": "ID",
    "city": "White Bird"
  },
  {
    "value": "83555",
    "label": "Winchester - ID 83555",
    "county": "Nez Perce County",
    "state": "ID",
    "city": "Winchester"
  },
  {
    "value": "83602",
    "label": "Banks - ID 83602",
    "county": "Boise County",
    "state": "ID",
    "city": "Banks"
  },
  {
    "value": "83604",
    "label": "Bruneau - ID 83604",
    "county": "Owyhee County",
    "state": "ID",
    "city": "Bruneau"
  },
  {
    "value": "83605",
    "label": "Caldwell - ID 83605",
    "county": "Canyon County",
    "state": "ID",
    "city": "Caldwell"
  },
  {
    "value": "83607",
    "label": "Caldwell - ID 83607",
    "county": "Canyon County",
    "state": "ID",
    "city": "Caldwell"
  },
  {
    "value": "83610",
    "label": "Cambridge - ID 83610",
    "county": "Washington County",
    "state": "ID",
    "city": "Cambridge"
  },
  {
    "value": "83611",
    "label": "Cascade - ID 83611",
    "county": "Valley County",
    "state": "ID",
    "city": "Cascade"
  },
  {
    "value": "83612",
    "label": "Council - ID 83612",
    "county": "Adams County",
    "state": "ID",
    "city": "Council"
  },
  {
    "value": "83615",
    "label": "Donnelly - ID 83615",
    "county": "Valley County",
    "state": "ID",
    "city": "Donnelly"
  },
  {
    "value": "83616",
    "label": "Eagle - ID 83616",
    "county": "Ada County",
    "state": "ID",
    "city": "Eagle"
  },
  {
    "value": "83617",
    "label": "Emmett - ID 83617",
    "county": "Gem County",
    "state": "ID",
    "city": "Emmett"
  },
  {
    "value": "83619",
    "label": "Fruitland - ID 83619",
    "county": "Payette County",
    "state": "ID",
    "city": "Fruitland"
  },
  {
    "value": "83622",
    "label": "Garden Valley - ID 83622",
    "county": "Boise County",
    "state": "ID",
    "city": "Garden Valley"
  },
  {
    "value": "83623",
    "label": "Glenns Ferry - ID 83623",
    "county": "Elmore County",
    "state": "ID",
    "city": "Glenns Ferry"
  },
  {
    "value": "83624",
    "label": "Grand View - ID 83624",
    "county": "Owyhee County",
    "state": "ID",
    "city": "Grand View"
  },
  {
    "value": "83626",
    "label": "Greenleaf - ID 83626",
    "county": "Canyon County",
    "state": "ID",
    "city": "Greenleaf"
  },
  {
    "value": "83627",
    "label": "Hammett - ID 83627",
    "county": "Elmore County",
    "state": "ID",
    "city": "Hammett"
  },
  {
    "value": "83628",
    "label": "Homedale - ID 83628",
    "county": "Owyhee County",
    "state": "ID",
    "city": "Homedale"
  },
  {
    "value": "83629",
    "label": "Horseshoe Bend - ID 83629",
    "county": "Boise County",
    "state": "ID",
    "city": "Horseshoe Bend"
  },
  {
    "value": "83631",
    "label": "Idaho City - ID 83631",
    "county": "Boise County",
    "state": "ID",
    "city": "Idaho City"
  },
  {
    "value": "83632",
    "label": "Indian Valley - ID 83632",
    "county": "Adams County",
    "state": "ID",
    "city": "Indian Valley"
  },
  {
    "value": "83633",
    "label": "King Hill - ID 83633",
    "county": "Elmore County",
    "state": "ID",
    "city": "King Hill"
  },
  {
    "value": "83634",
    "label": "Kuna - ID 83634",
    "county": "Ada County",
    "state": "ID",
    "city": "Kuna"
  },
  {
    "value": "83636",
    "label": "Letha - ID 83636",
    "county": "Gem County",
    "state": "ID",
    "city": "Letha"
  },
  {
    "value": "83637",
    "label": "Lowman - ID 83637",
    "county": "Boise County",
    "state": "ID",
    "city": "Lowman"
  },
  {
    "value": "83638",
    "label": "Mccall - ID 83638",
    "county": "Valley County",
    "state": "ID",
    "city": "Mccall"
  },
  {
    "value": "83639",
    "label": "Marsing - ID 83639",
    "county": "Owyhee County",
    "state": "ID",
    "city": "Marsing"
  },
  {
    "value": "83641",
    "label": "Melba - ID 83641",
    "county": "Canyon County",
    "state": "ID",
    "city": "Melba"
  },
  {
    "value": "83642",
    "label": "Meridian - ID 83642",
    "county": "Ada County",
    "state": "ID",
    "city": "Meridian"
  },
  {
    "value": "83643",
    "label": "Mesa - ID 83643",
    "county": "Adams County",
    "state": "ID",
    "city": "Mesa"
  },
  {
    "value": "83644",
    "label": "Middleton - ID 83644",
    "county": "Canyon County",
    "state": "ID",
    "city": "Middleton"
  },
  {
    "value": "83645",
    "label": "Midvale - ID 83645",
    "county": "Washington County",
    "state": "ID",
    "city": "Midvale"
  },
  {
    "value": "83646",
    "label": "Meridian - ID 83646",
    "county": "Ada County",
    "state": "ID",
    "city": "Meridian"
  },
  {
    "value": "83647",
    "label": "Mountain Home - ID 83647",
    "county": "Elmore County",
    "state": "ID",
    "city": "Mountain Home"
  },
  {
    "value": "83648",
    "label": "Mountain Home AFB - ID 83648",
    "county": "Elmore County",
    "state": "ID",
    "city": "Mountain Home AFB"
  },
  {
    "value": "83650",
    "label": "Murphy - ID 83650",
    "county": "Owyhee County",
    "state": "ID",
    "city": "Murphy"
  },
  {
    "value": "83651",
    "label": "Nampa - ID 83651",
    "county": "Canyon County",
    "state": "ID",
    "city": "Nampa"
  },
  {
    "value": "83654",
    "label": "New Meadows - ID 83654",
    "county": "Adams County",
    "state": "ID",
    "city": "New Meadows"
  },
  {
    "value": "83655",
    "label": "New Plymouth - ID 83655",
    "county": "Payette County",
    "state": "ID",
    "city": "New Plymouth"
  },
  {
    "value": "83657",
    "label": "Ola - ID 83657",
    "county": "Gem County",
    "state": "ID",
    "city": "Ola"
  },
  {
    "value": "83660",
    "label": "Parma - ID 83660",
    "county": "Canyon County",
    "state": "ID",
    "city": "Parma"
  },
  {
    "value": "83661",
    "label": "Payette - ID 83661",
    "county": "Payette County",
    "state": "ID",
    "city": "Payette"
  },
  {
    "value": "83666",
    "label": "Placerville - ID 83666",
    "county": "Boise County",
    "state": "ID",
    "city": "Placerville"
  },
  {
    "value": "83669",
    "label": "Star - ID 83669",
    "county": "Ada County",
    "state": "ID",
    "city": "Star"
  },
  {
    "value": "83670",
    "label": "Sweet - ID 83670",
    "county": "Gem County",
    "state": "ID",
    "city": "Sweet"
  },
  {
    "value": "83672",
    "label": "Weiser - ID 83672",
    "county": "Washington County",
    "state": "ID",
    "city": "Weiser"
  },
  {
    "value": "83676",
    "label": "Wilder - ID 83676",
    "county": "Canyon County",
    "state": "ID",
    "city": "Wilder"
  },
  {
    "value": "83677",
    "label": "Yellow Pine - ID 83677",
    "county": "Valley County",
    "state": "ID",
    "city": "Yellow Pine"
  },
  {
    "value": "83686",
    "label": "Nampa - ID 83686",
    "county": "Canyon County",
    "state": "ID",
    "city": "Nampa"
  },
  {
    "value": "83687",
    "label": "Nampa - ID 83687",
    "county": "Canyon County",
    "state": "ID",
    "city": "Nampa"
  },
  {
    "value": "83702",
    "label": "Boise - ID 83702",
    "county": "Ada County",
    "state": "ID",
    "city": "Boise"
  },
  {
    "value": "83703",
    "label": "Boise - ID 83703",
    "county": "Ada County",
    "state": "ID",
    "city": "Boise"
  },
  {
    "value": "83704",
    "label": "Boise - ID 83704",
    "county": "Ada County",
    "state": "ID",
    "city": "Boise"
  },
  {
    "value": "83705",
    "label": "Boise - ID 83705",
    "county": "Ada County",
    "state": "ID",
    "city": "Boise"
  },
  {
    "value": "83706",
    "label": "Boise - ID 83706",
    "county": "Ada County",
    "state": "ID",
    "city": "Boise"
  },
  {
    "value": "83708",
    "label": "Boise - ID 83708",
    "county": "Ada County",
    "state": "ID",
    "city": "Boise"
  },
  {
    "value": "83709",
    "label": "Boise - ID 83709",
    "county": "Ada County",
    "state": "ID",
    "city": "Boise"
  },
  {
    "value": "83712",
    "label": "Boise - ID 83712",
    "county": "Ada County",
    "state": "ID",
    "city": "Boise"
  },
  {
    "value": "83713",
    "label": "Boise - ID 83713",
    "county": "Ada County",
    "state": "ID",
    "city": "Boise"
  },
  {
    "value": "83714",
    "label": "Garden City - ID 83714",
    "county": "Ada County",
    "state": "ID",
    "city": "Garden City"
  },
  {
    "value": "83716",
    "label": "Boise - ID 83716",
    "county": "Ada County",
    "state": "ID",
    "city": "Boise"
  },
  {
    "value": "83801",
    "label": "Athol - ID 83801",
    "county": "Kootenai County",
    "state": "ID",
    "city": "Athol"
  },
  {
    "value": "83802",
    "label": "Avery - ID 83802",
    "county": "Shoshone County",
    "state": "ID",
    "city": "Avery"
  },
  {
    "value": "83803",
    "label": "Bayview - ID 83803",
    "county": "Kootenai County",
    "state": "ID",
    "city": "Bayview"
  },
  {
    "value": "83804",
    "label": "Blanchard - ID 83804",
    "county": "Bonner County",
    "state": "ID",
    "city": "Blanchard"
  },
  {
    "value": "83805",
    "label": "Bonners Ferry - ID 83805",
    "county": "Boundary County",
    "state": "ID",
    "city": "Bonners Ferry"
  },
  {
    "value": "83808",
    "label": "Calder - ID 83808",
    "county": "Shoshone County",
    "state": "ID",
    "city": "Calder"
  },
  {
    "value": "83809",
    "label": "Careywood - ID 83809",
    "county": "Bonner County",
    "state": "ID",
    "city": "Careywood"
  },
  {
    "value": "83810",
    "label": "Cataldo - ID 83810",
    "county": "Kootenai County",
    "state": "ID",
    "city": "Cataldo"
  },
  {
    "value": "83811",
    "label": "Clark Fork - ID 83811",
    "county": "Bonner County",
    "state": "ID",
    "city": "Clark Fork"
  },
  {
    "value": "83812",
    "label": "Clarkia - ID 83812",
    "county": "Shoshone County",
    "state": "ID",
    "city": "Clarkia"
  },
  {
    "value": "83813",
    "label": "Cocolalla - ID 83813",
    "county": "Bonner County",
    "state": "ID",
    "city": "Cocolalla"
  },
  {
    "value": "83814",
    "label": "Coeur D Alene - ID 83814",
    "county": "Kootenai County",
    "state": "ID",
    "city": "Coeur D Alene"
  },
  {
    "value": "83815",
    "label": "Coeur D Alene - ID 83815",
    "county": "Kootenai County",
    "state": "ID",
    "city": "Coeur D Alene"
  },
  {
    "value": "83821",
    "label": "Coolin - ID 83821",
    "county": "Bonner County",
    "state": "ID",
    "city": "Coolin"
  },
  {
    "value": "83822",
    "label": "Oldtown - ID 83822",
    "county": "Bonner County",
    "state": "ID",
    "city": "Oldtown"
  },
  {
    "value": "83823",
    "label": "Deary - ID 83823",
    "county": "Latah County",
    "state": "ID",
    "city": "Deary"
  },
  {
    "value": "83824",
    "label": "Desmet - ID 83824",
    "county": "Benewah County",
    "state": "ID",
    "city": "Desmet"
  },
  {
    "value": "83830",
    "label": "Fernwood - ID 83830",
    "county": "Benewah County",
    "state": "ID",
    "city": "Fernwood"
  },
  {
    "value": "83832",
    "label": "Genesee - ID 83832",
    "county": "Latah County",
    "state": "ID",
    "city": "Genesee"
  },
  {
    "value": "83833",
    "label": "Harrison - ID 83833",
    "county": "Kootenai County",
    "state": "ID",
    "city": "Harrison"
  },
  {
    "value": "83834",
    "label": "Harvard - ID 83834",
    "county": "Latah County",
    "state": "ID",
    "city": "Harvard"
  },
  {
    "value": "83835",
    "label": "Hayden - ID 83835",
    "county": "Kootenai County",
    "state": "ID",
    "city": "Hayden"
  },
  {
    "value": "83836",
    "label": "Hope - ID 83836",
    "county": "Bonner County",
    "state": "ID",
    "city": "Hope"
  },
  {
    "value": "83837",
    "label": "Kellogg - ID 83837",
    "county": "Shoshone County",
    "state": "ID",
    "city": "Kellogg"
  },
  {
    "value": "83839",
    "label": "Kingston - ID 83839",
    "county": "Shoshone County",
    "state": "ID",
    "city": "Kingston"
  },
  {
    "value": "83842",
    "label": "Medimont - ID 83842",
    "county": "Kootenai County",
    "state": "ID",
    "city": "Medimont"
  },
  {
    "value": "83843",
    "label": "Moscow - ID 83843",
    "county": "Latah County",
    "state": "ID",
    "city": "Moscow"
  },
  {
    "value": "83845",
    "label": "Moyie Springs - ID 83845",
    "county": "Boundary County",
    "state": "ID",
    "city": "Moyie Springs"
  },
  {
    "value": "83846",
    "label": "Mullan - ID 83846",
    "county": "Shoshone County",
    "state": "ID",
    "city": "Mullan"
  },
  {
    "value": "83847",
    "label": "Naples - ID 83847",
    "county": "Boundary County",
    "state": "ID",
    "city": "Naples"
  },
  {
    "value": "83848",
    "label": "Nordman - ID 83848",
    "county": "Bonner County",
    "state": "ID",
    "city": "Nordman"
  },
  {
    "value": "83850",
    "label": "Pinehurst - ID 83850",
    "county": "Shoshone County",
    "state": "ID",
    "city": "Pinehurst"
  },
  {
    "value": "83851",
    "label": "Plummer - ID 83851",
    "county": "Benewah County",
    "state": "ID",
    "city": "Plummer"
  },
  {
    "value": "83852",
    "label": "Ponderay - ID 83852",
    "county": "Bonner County",
    "state": "ID",
    "city": "Ponderay"
  },
  {
    "value": "83854",
    "label": "Post Falls - ID 83854",
    "county": "Kootenai County",
    "state": "ID",
    "city": "Post Falls"
  },
  {
    "value": "83855",
    "label": "Potlatch - ID 83855",
    "county": "Latah County",
    "state": "ID",
    "city": "Potlatch"
  },
  {
    "value": "83856",
    "label": "Priest River - ID 83856",
    "county": "Bonner County",
    "state": "ID",
    "city": "Priest River"
  },
  {
    "value": "83857",
    "label": "Princeton - ID 83857",
    "county": "Latah County",
    "state": "ID",
    "city": "Princeton"
  },
  {
    "value": "83858",
    "label": "Rathdrum - ID 83858",
    "county": "Kootenai County",
    "state": "ID",
    "city": "Rathdrum"
  },
  {
    "value": "83860",
    "label": "Sagle - ID 83860",
    "county": "Bonner County",
    "state": "ID",
    "city": "Sagle"
  },
  {
    "value": "83861",
    "label": "Saint Maries - ID 83861",
    "county": "Benewah County",
    "state": "ID",
    "city": "Saint Maries"
  },
  {
    "value": "83864",
    "label": "Sandpoint - ID 83864",
    "county": "Bonner County",
    "state": "ID",
    "city": "Sandpoint"
  },
  {
    "value": "83868",
    "label": "Smelterville - ID 83868",
    "county": "Shoshone County",
    "state": "ID",
    "city": "Smelterville"
  },
  {
    "value": "83869",
    "label": "Spirit Lake - ID 83869",
    "county": "Kootenai County",
    "state": "ID",
    "city": "Spirit Lake"
  },
  {
    "value": "83870",
    "label": "Tensed - ID 83870",
    "county": "Benewah County",
    "state": "ID",
    "city": "Tensed"
  },
  {
    "value": "83871",
    "label": "Troy - ID 83871",
    "county": "Latah County",
    "state": "ID",
    "city": "Troy"
  },
  {
    "value": "83872",
    "label": "Viola - ID 83872",
    "county": "Latah County",
    "state": "ID",
    "city": "Viola"
  },
  {
    "value": "83873",
    "label": "Wallace - ID 83873",
    "county": "Shoshone County",
    "state": "ID",
    "city": "Wallace"
  },
  {
    "value": "83874",
    "label": "Murray - ID 83874",
    "county": "Shoshone County",
    "state": "ID",
    "city": "Murray"
  },
  {
    "value": "83876",
    "label": "Worley - ID 83876",
    "county": "Kootenai County",
    "state": "ID",
    "city": "Worley"
  },
  {
    "value": "60002",
    "label": "Antioch - IL 60002",
    "county": "Lake County",
    "state": "IL",
    "city": "Antioch"
  },
  {
    "value": "60004",
    "label": "Arlington Heights - IL 60004",
    "county": "Cook County",
    "state": "IL",
    "city": "Arlington Heights"
  },
  {
    "value": "60005",
    "label": "Arlington Heights - IL 60005",
    "county": "Cook County",
    "state": "IL",
    "city": "Arlington Heights"
  },
  {
    "value": "60007",
    "label": "Elk Grove Village - IL 60007",
    "county": "Cook County",
    "state": "IL",
    "city": "Elk Grove Village"
  },
  {
    "value": "60008",
    "label": "Rolling Meadows - IL 60008",
    "county": "Cook County",
    "state": "IL",
    "city": "Rolling Meadows"
  },
  {
    "value": "60010",
    "label": "Barrington - IL 60010",
    "county": "Lake County",
    "state": "IL",
    "city": "Barrington"
  },
  {
    "value": "60012",
    "label": "Crystal Lake - IL 60012",
    "county": "McHenry County",
    "state": "IL",
    "city": "Crystal Lake"
  },
  {
    "value": "60013",
    "label": "Cary - IL 60013",
    "county": "McHenry County",
    "state": "IL",
    "city": "Cary"
  },
  {
    "value": "60014",
    "label": "Crystal Lake - IL 60014",
    "county": "McHenry County",
    "state": "IL",
    "city": "Crystal Lake"
  },
  {
    "value": "60015",
    "label": "Deerfield - IL 60015",
    "county": "Lake County",
    "state": "IL",
    "city": "Deerfield"
  },
  {
    "value": "60016",
    "label": "Des Plaines - IL 60016",
    "county": "Cook County",
    "state": "IL",
    "city": "Des Plaines"
  },
  {
    "value": "60018",
    "label": "Des Plaines - IL 60018",
    "county": "Cook County",
    "state": "IL",
    "city": "Des Plaines"
  },
  {
    "value": "60020",
    "label": "Fox Lake - IL 60020",
    "county": "Lake County",
    "state": "IL",
    "city": "Fox Lake"
  },
  {
    "value": "60021",
    "label": "Fox River Grove - IL 60021",
    "county": "McHenry County",
    "state": "IL",
    "city": "Fox River Grove"
  },
  {
    "value": "60022",
    "label": "Glencoe - IL 60022",
    "county": "Cook County",
    "state": "IL",
    "city": "Glencoe"
  },
  {
    "value": "60025",
    "label": "Glenview - IL 60025",
    "county": "Cook County",
    "state": "IL",
    "city": "Glenview"
  },
  {
    "value": "60026",
    "label": "Glenview - IL 60026",
    "county": "Cook County",
    "state": "IL",
    "city": "Glenview"
  },
  {
    "value": "60030",
    "label": "Grayslake - IL 60030",
    "county": "Lake County",
    "state": "IL",
    "city": "Grayslake"
  },
  {
    "value": "60031",
    "label": "Gurnee - IL 60031",
    "county": "Lake County",
    "state": "IL",
    "city": "Gurnee"
  },
  {
    "value": "60033",
    "label": "Harvard - IL 60033",
    "county": "McHenry County",
    "state": "IL",
    "city": "Harvard"
  },
  {
    "value": "60034",
    "label": "Hebron - IL 60034",
    "county": "McHenry County",
    "state": "IL",
    "city": "Hebron"
  },
  {
    "value": "60035",
    "label": "Highland Park - IL 60035",
    "county": "Lake County",
    "state": "IL",
    "city": "Highland Park"
  },
  {
    "value": "60037",
    "label": "Fort Sheridan - IL 60037",
    "county": "Lake County",
    "state": "IL",
    "city": "Fort Sheridan"
  },
  {
    "value": "60040",
    "label": "Highwood - IL 60040",
    "county": "Lake County",
    "state": "IL",
    "city": "Highwood"
  },
  {
    "value": "60041",
    "label": "Ingleside - IL 60041",
    "county": "Lake County",
    "state": "IL",
    "city": "Ingleside"
  },
  {
    "value": "60042",
    "label": "Island Lake - IL 60042",
    "county": "McHenry County",
    "state": "IL",
    "city": "Island Lake"
  },
  {
    "value": "60043",
    "label": "Kenilworth - IL 60043",
    "county": "Cook County",
    "state": "IL",
    "city": "Kenilworth"
  },
  {
    "value": "60044",
    "label": "Lake Bluff - IL 60044",
    "county": "Lake County",
    "state": "IL",
    "city": "Lake Bluff"
  },
  {
    "value": "60045",
    "label": "Lake Forest - IL 60045",
    "county": "Lake County",
    "state": "IL",
    "city": "Lake Forest"
  },
  {
    "value": "60046",
    "label": "Lake Villa - IL 60046",
    "county": "Lake County",
    "state": "IL",
    "city": "Lake Villa"
  },
  {
    "value": "60047",
    "label": "Lake Zurich - IL 60047",
    "county": "Lake County",
    "state": "IL",
    "city": "Lake Zurich"
  },
  {
    "value": "60048",
    "label": "Libertyville - IL 60048",
    "county": "Lake County",
    "state": "IL",
    "city": "Libertyville"
  },
  {
    "value": "60050",
    "label": "Mchenry - IL 60050",
    "county": "McHenry County",
    "state": "IL",
    "city": "Mchenry"
  },
  {
    "value": "60051",
    "label": "Mchenry - IL 60051",
    "county": "McHenry County",
    "state": "IL",
    "city": "Mchenry"
  },
  {
    "value": "60053",
    "label": "Morton Grove - IL 60053",
    "county": "Cook County",
    "state": "IL",
    "city": "Morton Grove"
  },
  {
    "value": "60056",
    "label": "Mount Prospect - IL 60056",
    "county": "Cook County",
    "state": "IL",
    "city": "Mount Prospect"
  },
  {
    "value": "60060",
    "label": "Mundelein - IL 60060",
    "county": "Lake County",
    "state": "IL",
    "city": "Mundelein"
  },
  {
    "value": "60061",
    "label": "Vernon Hills - IL 60061",
    "county": "Lake County",
    "state": "IL",
    "city": "Vernon Hills"
  },
  {
    "value": "60062",
    "label": "Northbrook - IL 60062",
    "county": "Cook County",
    "state": "IL",
    "city": "Northbrook"
  },
  {
    "value": "60064",
    "label": "North Chicago - IL 60064",
    "county": "Lake County",
    "state": "IL",
    "city": "North Chicago"
  },
  {
    "value": "60067",
    "label": "Palatine - IL 60067",
    "county": "Cook County",
    "state": "IL",
    "city": "Palatine"
  },
  {
    "value": "60068",
    "label": "Park Ridge - IL 60068",
    "county": "Cook County",
    "state": "IL",
    "city": "Park Ridge"
  },
  {
    "value": "60069",
    "label": "Lincolnshire - IL 60069",
    "county": "Lake County",
    "state": "IL",
    "city": "Lincolnshire"
  },
  {
    "value": "60070",
    "label": "Prospect Heights - IL 60070",
    "county": "Cook County",
    "state": "IL",
    "city": "Prospect Heights"
  },
  {
    "value": "60071",
    "label": "Richmond - IL 60071",
    "county": "McHenry County",
    "state": "IL",
    "city": "Richmond"
  },
  {
    "value": "60072",
    "label": "Ringwood - IL 60072",
    "county": "McHenry County",
    "state": "IL",
    "city": "Ringwood"
  },
  {
    "value": "60073",
    "label": "Round Lake - IL 60073",
    "county": "Lake County",
    "state": "IL",
    "city": "Round Lake"
  },
  {
    "value": "60074",
    "label": "Palatine - IL 60074",
    "county": "Cook County",
    "state": "IL",
    "city": "Palatine"
  },
  {
    "value": "60076",
    "label": "Skokie - IL 60076",
    "county": "Cook County",
    "state": "IL",
    "city": "Skokie"
  },
  {
    "value": "60077",
    "label": "Skokie - IL 60077",
    "county": "Cook County",
    "state": "IL",
    "city": "Skokie"
  },
  {
    "value": "60081",
    "label": "Spring Grove - IL 60081",
    "county": "McHenry County",
    "state": "IL",
    "city": "Spring Grove"
  },
  {
    "value": "60083",
    "label": "Wadsworth - IL 60083",
    "county": "Lake County",
    "state": "IL",
    "city": "Wadsworth"
  },
  {
    "value": "60084",
    "label": "Wauconda - IL 60084",
    "county": "Lake County",
    "state": "IL",
    "city": "Wauconda"
  },
  {
    "value": "60085",
    "label": "Waukegan - IL 60085",
    "county": "Lake County",
    "state": "IL",
    "city": "Waukegan"
  },
  {
    "value": "60087",
    "label": "Waukegan - IL 60087",
    "county": "Lake County",
    "state": "IL",
    "city": "Waukegan"
  },
  {
    "value": "60088",
    "label": "Great Lakes - IL 60088",
    "county": "Lake County",
    "state": "IL",
    "city": "Great Lakes"
  },
  {
    "value": "60089",
    "label": "Buffalo Grove - IL 60089",
    "county": "Lake County",
    "state": "IL",
    "city": "Buffalo Grove"
  },
  {
    "value": "60090",
    "label": "Wheeling - IL 60090",
    "county": "Cook County",
    "state": "IL",
    "city": "Wheeling"
  },
  {
    "value": "60091",
    "label": "Wilmette - IL 60091",
    "county": "Cook County",
    "state": "IL",
    "city": "Wilmette"
  },
  {
    "value": "60092",
    "label": "Libertyville - IL 60092",
    "county": "Lake County",
    "state": "IL",
    "city": "Libertyville"
  },
  {
    "value": "60093",
    "label": "Winnetka - IL 60093",
    "county": "Cook County",
    "state": "IL",
    "city": "Winnetka"
  },
  {
    "value": "60096",
    "label": "Winthrop Harbor - IL 60096",
    "county": "Lake County",
    "state": "IL",
    "city": "Winthrop Harbor"
  },
  {
    "value": "60097",
    "label": "Wonder Lake - IL 60097",
    "county": "McHenry County",
    "state": "IL",
    "city": "Wonder Lake"
  },
  {
    "value": "60098",
    "label": "Woodstock - IL 60098",
    "county": "McHenry County",
    "state": "IL",
    "city": "Woodstock"
  },
  {
    "value": "60099",
    "label": "Zion - IL 60099",
    "county": "Lake County",
    "state": "IL",
    "city": "Zion"
  },
  {
    "value": "60101",
    "label": "Addison - IL 60101",
    "county": "DuPage County",
    "state": "IL",
    "city": "Addison"
  },
  {
    "value": "60102",
    "label": "Algonquin - IL 60102",
    "county": "McHenry County",
    "state": "IL",
    "city": "Algonquin"
  },
  {
    "value": "60103",
    "label": "Bartlett - IL 60103",
    "county": "DuPage County",
    "state": "IL",
    "city": "Bartlett"
  },
  {
    "value": "60104",
    "label": "Bellwood - IL 60104",
    "county": "Cook County",
    "state": "IL",
    "city": "Bellwood"
  },
  {
    "value": "60106",
    "label": "Bensenville - IL 60106",
    "county": "DuPage County",
    "state": "IL",
    "city": "Bensenville"
  },
  {
    "value": "60107",
    "label": "Streamwood - IL 60107",
    "county": "Cook County",
    "state": "IL",
    "city": "Streamwood"
  },
  {
    "value": "60108",
    "label": "Bloomingdale - IL 60108",
    "county": "DuPage County",
    "state": "IL",
    "city": "Bloomingdale"
  },
  {
    "value": "60110",
    "label": "Carpentersville - IL 60110",
    "county": "Kane County",
    "state": "IL",
    "city": "Carpentersville"
  },
  {
    "value": "60111",
    "label": "Clare - IL 60111",
    "county": "DeKalb County",
    "state": "IL",
    "city": "Clare"
  },
  {
    "value": "60112",
    "label": "Cortland - IL 60112",
    "county": "DeKalb County",
    "state": "IL",
    "city": "Cortland"
  },
  {
    "value": "60115",
    "label": "Dekalb - IL 60115",
    "county": "DeKalb County",
    "state": "IL",
    "city": "Dekalb"
  },
  {
    "value": "60118",
    "label": "Dundee - IL 60118",
    "county": "Kane County",
    "state": "IL",
    "city": "Dundee"
  },
  {
    "value": "60119",
    "label": "Elburn - IL 60119",
    "county": "Kane County",
    "state": "IL",
    "city": "Elburn"
  },
  {
    "value": "60120",
    "label": "Elgin - IL 60120",
    "county": "Kane County",
    "state": "IL",
    "city": "Elgin"
  },
  {
    "value": "60123",
    "label": "Elgin - IL 60123",
    "county": "Kane County",
    "state": "IL",
    "city": "Elgin"
  },
  {
    "value": "60124",
    "label": "Elgin - IL 60124",
    "county": "Kane County",
    "state": "IL",
    "city": "Elgin"
  },
  {
    "value": "60126",
    "label": "Elmhurst - IL 60126",
    "county": "DuPage County",
    "state": "IL",
    "city": "Elmhurst"
  },
  {
    "value": "60129",
    "label": "Esmond - IL 60129",
    "county": "DeKalb County",
    "state": "IL",
    "city": "Esmond"
  },
  {
    "value": "60130",
    "label": "Forest Park - IL 60130",
    "county": "Cook County",
    "state": "IL",
    "city": "Forest Park"
  },
  {
    "value": "60131",
    "label": "Franklin Park - IL 60131",
    "county": "Cook County",
    "state": "IL",
    "city": "Franklin Park"
  },
  {
    "value": "60133",
    "label": "Hanover Park - IL 60133",
    "county": "Cook County",
    "state": "IL",
    "city": "Hanover Park"
  },
  {
    "value": "60134",
    "label": "Geneva - IL 60134",
    "county": "Kane County",
    "state": "IL",
    "city": "Geneva"
  },
  {
    "value": "60135",
    "label": "Genoa - IL 60135",
    "county": "DeKalb County",
    "state": "IL",
    "city": "Genoa"
  },
  {
    "value": "60136",
    "label": "Gilberts - IL 60136",
    "county": "Kane County",
    "state": "IL",
    "city": "Gilberts"
  },
  {
    "value": "60137",
    "label": "Glen Ellyn - IL 60137",
    "county": "DuPage County",
    "state": "IL",
    "city": "Glen Ellyn"
  },
  {
    "value": "60139",
    "label": "Glendale Heights - IL 60139",
    "county": "DuPage County",
    "state": "IL",
    "city": "Glendale Heights"
  },
  {
    "value": "60140",
    "label": "Hampshire - IL 60140",
    "county": "Kane County",
    "state": "IL",
    "city": "Hampshire"
  },
  {
    "value": "60142",
    "label": "Huntley - IL 60142",
    "county": "McHenry County",
    "state": "IL",
    "city": "Huntley"
  },
  {
    "value": "60143",
    "label": "Itasca - IL 60143",
    "county": "DuPage County",
    "state": "IL",
    "city": "Itasca"
  },
  {
    "value": "60145",
    "label": "Kingston - IL 60145",
    "county": "DeKalb County",
    "state": "IL",
    "city": "Kingston"
  },
  {
    "value": "60146",
    "label": "Kirkland - IL 60146",
    "county": "DeKalb County",
    "state": "IL",
    "city": "Kirkland"
  },
  {
    "value": "60148",
    "label": "Lombard - IL 60148",
    "county": "DuPage County",
    "state": "IL",
    "city": "Lombard"
  },
  {
    "value": "60150",
    "label": "Malta - IL 60150",
    "county": "DeKalb County",
    "state": "IL",
    "city": "Malta"
  },
  {
    "value": "60151",
    "label": "Maple Park - IL 60151",
    "county": "Kane County",
    "state": "IL",
    "city": "Maple Park"
  },
  {
    "value": "60152",
    "label": "Marengo - IL 60152",
    "county": "McHenry County",
    "state": "IL",
    "city": "Marengo"
  },
  {
    "value": "60153",
    "label": "Maywood - IL 60153",
    "county": "Cook County",
    "state": "IL",
    "city": "Maywood"
  },
  {
    "value": "60154",
    "label": "Westchester - IL 60154",
    "county": "Cook County",
    "state": "IL",
    "city": "Westchester"
  },
  {
    "value": "60155",
    "label": "Broadview - IL 60155",
    "county": "Cook County",
    "state": "IL",
    "city": "Broadview"
  },
  {
    "value": "60156",
    "label": "Lake In The Hills - IL 60156",
    "county": "McHenry County",
    "state": "IL",
    "city": "Lake In The Hills"
  },
  {
    "value": "60157",
    "label": "Medinah - IL 60157",
    "county": "DuPage County",
    "state": "IL",
    "city": "Medinah"
  },
  {
    "value": "60160",
    "label": "Melrose Park - IL 60160",
    "county": "Cook County",
    "state": "IL",
    "city": "Melrose Park"
  },
  {
    "value": "60162",
    "label": "Hillside - IL 60162",
    "county": "Cook County",
    "state": "IL",
    "city": "Hillside"
  },
  {
    "value": "60163",
    "label": "Berkeley - IL 60163",
    "county": "Cook County",
    "state": "IL",
    "city": "Berkeley"
  },
  {
    "value": "60164",
    "label": "Melrose Park - IL 60164",
    "county": "Cook County",
    "state": "IL",
    "city": "Melrose Park"
  },
  {
    "value": "60165",
    "label": "Stone Park - IL 60165",
    "county": "Cook County",
    "state": "IL",
    "city": "Stone Park"
  },
  {
    "value": "60169",
    "label": "Hoffman Estates - IL 60169",
    "county": "Cook County",
    "state": "IL",
    "city": "Hoffman Estates"
  },
  {
    "value": "60171",
    "label": "River Grove - IL 60171",
    "county": "Cook County",
    "state": "IL",
    "city": "River Grove"
  },
  {
    "value": "60172",
    "label": "Roselle - IL 60172",
    "county": "DuPage County",
    "state": "IL",
    "city": "Roselle"
  },
  {
    "value": "60173",
    "label": "Schaumburg - IL 60173",
    "county": "Cook County",
    "state": "IL",
    "city": "Schaumburg"
  },
  {
    "value": "60174",
    "label": "Saint Charles - IL 60174",
    "county": "Kane County",
    "state": "IL",
    "city": "Saint Charles"
  },
  {
    "value": "60175",
    "label": "Saint Charles - IL 60175",
    "county": "Kane County",
    "state": "IL",
    "city": "Saint Charles"
  },
  {
    "value": "60176",
    "label": "Schiller Park - IL 60176",
    "county": "Cook County",
    "state": "IL",
    "city": "Schiller Park"
  },
  {
    "value": "60177",
    "label": "South Elgin - IL 60177",
    "county": "Kane County",
    "state": "IL",
    "city": "South Elgin"
  },
  {
    "value": "60178",
    "label": "Sycamore - IL 60178",
    "county": "DeKalb County",
    "state": "IL",
    "city": "Sycamore"
  },
  {
    "value": "60180",
    "label": "Union - IL 60180",
    "county": "McHenry County",
    "state": "IL",
    "city": "Union"
  },
  {
    "value": "60181",
    "label": "Villa Park - IL 60181",
    "county": "DuPage County",
    "state": "IL",
    "city": "Villa Park"
  },
  {
    "value": "60184",
    "label": "Wayne - IL 60184",
    "county": "DuPage County",
    "state": "IL",
    "city": "Wayne"
  },
  {
    "value": "60185",
    "label": "West Chicago - IL 60185",
    "county": "DuPage County",
    "state": "IL",
    "city": "West Chicago"
  },
  {
    "value": "60187",
    "label": "Wheaton - IL 60187",
    "county": "DuPage County",
    "state": "IL",
    "city": "Wheaton"
  },
  {
    "value": "60188",
    "label": "Carol Stream - IL 60188",
    "county": "DuPage County",
    "state": "IL",
    "city": "Carol Stream"
  },
  {
    "value": "60189",
    "label": "Wheaton - IL 60189",
    "county": "DuPage County",
    "state": "IL",
    "city": "Wheaton"
  },
  {
    "value": "60190",
    "label": "Winfield - IL 60190",
    "county": "DuPage County",
    "state": "IL",
    "city": "Winfield"
  },
  {
    "value": "60191",
    "label": "Wood Dale - IL 60191",
    "county": "DuPage County",
    "state": "IL",
    "city": "Wood Dale"
  },
  {
    "value": "60192",
    "label": "Hoffman Estates - IL 60192",
    "county": "Cook County",
    "state": "IL",
    "city": "Hoffman Estates"
  },
  {
    "value": "60193",
    "label": "Schaumburg - IL 60193",
    "county": "Cook County",
    "state": "IL",
    "city": "Schaumburg"
  },
  {
    "value": "60194",
    "label": "Schaumburg - IL 60194",
    "county": "Cook County",
    "state": "IL",
    "city": "Schaumburg"
  },
  {
    "value": "60195",
    "label": "Schaumburg - IL 60195",
    "county": "Cook County",
    "state": "IL",
    "city": "Schaumburg"
  },
  {
    "value": "60199",
    "label": "Carol Stream - IL 60199",
    "county": "Dupage County",
    "state": "IL",
    "city": "Carol Stream"
  },
  {
    "value": "60201",
    "label": "Evanston - IL 60201",
    "county": "Cook County",
    "state": "IL",
    "city": "Evanston"
  },
  {
    "value": "60202",
    "label": "Evanston - IL 60202",
    "county": "Cook County",
    "state": "IL",
    "city": "Evanston"
  },
  {
    "value": "60203",
    "label": "Evanston - IL 60203",
    "county": "Cook County",
    "state": "IL",
    "city": "Evanston"
  },
  {
    "value": "60301",
    "label": "Oak Park - IL 60301",
    "county": "Cook County",
    "state": "IL",
    "city": "Oak Park"
  },
  {
    "value": "60302",
    "label": "Oak Park - IL 60302",
    "county": "Cook County",
    "state": "IL",
    "city": "Oak Park"
  },
  {
    "value": "60304",
    "label": "Oak Park - IL 60304",
    "county": "Cook County",
    "state": "IL",
    "city": "Oak Park"
  },
  {
    "value": "60305",
    "label": "River Forest - IL 60305",
    "county": "Cook County",
    "state": "IL",
    "city": "River Forest"
  },
  {
    "value": "60401",
    "label": "Beecher - IL 60401",
    "county": "Will County",
    "state": "IL",
    "city": "Beecher"
  },
  {
    "value": "60402",
    "label": "Berwyn - IL 60402",
    "county": "Cook County",
    "state": "IL",
    "city": "Berwyn"
  },
  {
    "value": "60403",
    "label": "Crest Hill - IL 60403",
    "county": "Will County",
    "state": "IL",
    "city": "Crest Hill"
  },
  {
    "value": "60404",
    "label": "Shorewood - IL 60404",
    "county": "Will County",
    "state": "IL",
    "city": "Shorewood"
  },
  {
    "value": "60406",
    "label": "Blue Island - IL 60406",
    "county": "Cook County",
    "state": "IL",
    "city": "Blue Island"
  },
  {
    "value": "60407",
    "label": "Braceville - IL 60407",
    "county": "Grundy County",
    "state": "IL",
    "city": "Braceville"
  },
  {
    "value": "60408",
    "label": "Braidwood - IL 60408",
    "county": "Will County",
    "state": "IL",
    "city": "Braidwood"
  },
  {
    "value": "60409",
    "label": "Calumet City - IL 60409",
    "county": "Cook County",
    "state": "IL",
    "city": "Calumet City"
  },
  {
    "value": "60410",
    "label": "Channahon - IL 60410",
    "county": "Will County",
    "state": "IL",
    "city": "Channahon"
  },
  {
    "value": "60411",
    "label": "Chicago Heights - IL 60411",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago Heights"
  },
  {
    "value": "60415",
    "label": "Chicago Ridge - IL 60415",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago Ridge"
  },
  {
    "value": "60416",
    "label": "Coal City - IL 60416",
    "county": "Grundy County",
    "state": "IL",
    "city": "Coal City"
  },
  {
    "value": "60417",
    "label": "Crete - IL 60417",
    "county": "Will County",
    "state": "IL",
    "city": "Crete"
  },
  {
    "value": "60418",
    "label": "Crestwood - IL 60418",
    "county": "Cook County",
    "state": "IL",
    "city": "Crestwood"
  },
  {
    "value": "60419",
    "label": "Dolton - IL 60419",
    "county": "Cook County",
    "state": "IL",
    "city": "Dolton"
  },
  {
    "value": "60420",
    "label": "Dwight - IL 60420",
    "county": "Livingston County",
    "state": "IL",
    "city": "Dwight"
  },
  {
    "value": "60421",
    "label": "Elwood - IL 60421",
    "county": "Will County",
    "state": "IL",
    "city": "Elwood"
  },
  {
    "value": "60422",
    "label": "Flossmoor - IL 60422",
    "county": "Cook County",
    "state": "IL",
    "city": "Flossmoor"
  },
  {
    "value": "60423",
    "label": "Frankfort - IL 60423",
    "county": "Will County",
    "state": "IL",
    "city": "Frankfort"
  },
  {
    "value": "60424",
    "label": "Gardner - IL 60424",
    "county": "Grundy County",
    "state": "IL",
    "city": "Gardner"
  },
  {
    "value": "60425",
    "label": "Glenwood - IL 60425",
    "county": "Cook County",
    "state": "IL",
    "city": "Glenwood"
  },
  {
    "value": "60426",
    "label": "Harvey - IL 60426",
    "county": "Cook County",
    "state": "IL",
    "city": "Harvey"
  },
  {
    "value": "60428",
    "label": "Markham - IL 60428",
    "county": "Cook County",
    "state": "IL",
    "city": "Markham"
  },
  {
    "value": "60429",
    "label": "Hazel Crest - IL 60429",
    "county": "Cook County",
    "state": "IL",
    "city": "Hazel Crest"
  },
  {
    "value": "60430",
    "label": "Homewood - IL 60430",
    "county": "Cook County",
    "state": "IL",
    "city": "Homewood"
  },
  {
    "value": "60431",
    "label": "Joliet - IL 60431",
    "county": "Will County",
    "state": "IL",
    "city": "Joliet"
  },
  {
    "value": "60432",
    "label": "Joliet - IL 60432",
    "county": "Will County",
    "state": "IL",
    "city": "Joliet"
  },
  {
    "value": "60433",
    "label": "Joliet - IL 60433",
    "county": "Will County",
    "state": "IL",
    "city": "Joliet"
  },
  {
    "value": "60435",
    "label": "Joliet - IL 60435",
    "county": "Will County",
    "state": "IL",
    "city": "Joliet"
  },
  {
    "value": "60436",
    "label": "Joliet - IL 60436",
    "county": "Will County",
    "state": "IL",
    "city": "Joliet"
  },
  {
    "value": "60437",
    "label": "Kinsman - IL 60437",
    "county": "Grundy County",
    "state": "IL",
    "city": "Kinsman"
  },
  {
    "value": "60438",
    "label": "Lansing - IL 60438",
    "county": "Cook County",
    "state": "IL",
    "city": "Lansing"
  },
  {
    "value": "60439",
    "label": "Lemont - IL 60439",
    "county": "Cook County",
    "state": "IL",
    "city": "Lemont"
  },
  {
    "value": "60440",
    "label": "Bolingbrook - IL 60440",
    "county": "Will County",
    "state": "IL",
    "city": "Bolingbrook"
  },
  {
    "value": "60441",
    "label": "Lockport - IL 60441",
    "county": "Will County",
    "state": "IL",
    "city": "Lockport"
  },
  {
    "value": "60442",
    "label": "Manhattan - IL 60442",
    "county": "Will County",
    "state": "IL",
    "city": "Manhattan"
  },
  {
    "value": "60443",
    "label": "Matteson - IL 60443",
    "county": "Cook County",
    "state": "IL",
    "city": "Matteson"
  },
  {
    "value": "60444",
    "label": "Mazon - IL 60444",
    "county": "Grundy County",
    "state": "IL",
    "city": "Mazon"
  },
  {
    "value": "60445",
    "label": "Midlothian - IL 60445",
    "county": "Cook County",
    "state": "IL",
    "city": "Midlothian"
  },
  {
    "value": "60446",
    "label": "Romeoville - IL 60446",
    "county": "Will County",
    "state": "IL",
    "city": "Romeoville"
  },
  {
    "value": "60447",
    "label": "Minooka - IL 60447",
    "county": "Grundy County",
    "state": "IL",
    "city": "Minooka"
  },
  {
    "value": "60448",
    "label": "Mokena - IL 60448",
    "county": "Will County",
    "state": "IL",
    "city": "Mokena"
  },
  {
    "value": "60449",
    "label": "Monee - IL 60449",
    "county": "Will County",
    "state": "IL",
    "city": "Monee"
  },
  {
    "value": "60450",
    "label": "Morris - IL 60450",
    "county": "Grundy County",
    "state": "IL",
    "city": "Morris"
  },
  {
    "value": "60451",
    "label": "New Lenox - IL 60451",
    "county": "Will County",
    "state": "IL",
    "city": "New Lenox"
  },
  {
    "value": "60452",
    "label": "Oak Forest - IL 60452",
    "county": "Cook County",
    "state": "IL",
    "city": "Oak Forest"
  },
  {
    "value": "60453",
    "label": "Oak Lawn - IL 60453",
    "county": "Cook County",
    "state": "IL",
    "city": "Oak Lawn"
  },
  {
    "value": "60455",
    "label": "Bridgeview - IL 60455",
    "county": "Cook County",
    "state": "IL",
    "city": "Bridgeview"
  },
  {
    "value": "60456",
    "label": "Hometown - IL 60456",
    "county": "Cook County",
    "state": "IL",
    "city": "Hometown"
  },
  {
    "value": "60457",
    "label": "Hickory Hills - IL 60457",
    "county": "Cook County",
    "state": "IL",
    "city": "Hickory Hills"
  },
  {
    "value": "60458",
    "label": "Justice - IL 60458",
    "county": "Cook County",
    "state": "IL",
    "city": "Justice"
  },
  {
    "value": "60459",
    "label": "Burbank - IL 60459",
    "county": "Cook County",
    "state": "IL",
    "city": "Burbank"
  },
  {
    "value": "60460",
    "label": "Odell - IL 60460",
    "county": "Livingston County",
    "state": "IL",
    "city": "Odell"
  },
  {
    "value": "60461",
    "label": "Olympia Fields - IL 60461",
    "county": "Cook County",
    "state": "IL",
    "city": "Olympia Fields"
  },
  {
    "value": "60462",
    "label": "Orland Park - IL 60462",
    "county": "Cook County",
    "state": "IL",
    "city": "Orland Park"
  },
  {
    "value": "60463",
    "label": "Palos Heights - IL 60463",
    "county": "Cook County",
    "state": "IL",
    "city": "Palos Heights"
  },
  {
    "value": "60464",
    "label": "Palos Park - IL 60464",
    "county": "Cook County",
    "state": "IL",
    "city": "Palos Park"
  },
  {
    "value": "60465",
    "label": "Palos Hills - IL 60465",
    "county": "Cook County",
    "state": "IL",
    "city": "Palos Hills"
  },
  {
    "value": "60466",
    "label": "Park Forest - IL 60466",
    "county": "Cook County",
    "state": "IL",
    "city": "Park Forest"
  },
  {
    "value": "60467",
    "label": "Orland Park - IL 60467",
    "county": "Cook County",
    "state": "IL",
    "city": "Orland Park"
  },
  {
    "value": "60468",
    "label": "Peotone - IL 60468",
    "county": "Will County",
    "state": "IL",
    "city": "Peotone"
  },
  {
    "value": "60469",
    "label": "Posen - IL 60469",
    "county": "Cook County",
    "state": "IL",
    "city": "Posen"
  },
  {
    "value": "60470",
    "label": "Ransom - IL 60470",
    "county": "LaSalle County",
    "state": "IL",
    "city": "Ransom"
  },
  {
    "value": "60471",
    "label": "Richton Park - IL 60471",
    "county": "Cook County",
    "state": "IL",
    "city": "Richton Park"
  },
  {
    "value": "60472",
    "label": "Robbins - IL 60472",
    "county": "Cook County",
    "state": "IL",
    "city": "Robbins"
  },
  {
    "value": "60473",
    "label": "South Holland - IL 60473",
    "county": "Cook County",
    "state": "IL",
    "city": "South Holland"
  },
  {
    "value": "60475",
    "label": "Steger - IL 60475",
    "county": "Will County",
    "state": "IL",
    "city": "Steger"
  },
  {
    "value": "60476",
    "label": "Thornton - IL 60476",
    "county": "Cook County",
    "state": "IL",
    "city": "Thornton"
  },
  {
    "value": "60477",
    "label": "Tinley Park - IL 60477",
    "county": "Cook County",
    "state": "IL",
    "city": "Tinley Park"
  },
  {
    "value": "60478",
    "label": "Country Club Hills - IL 60478",
    "county": "Cook County",
    "state": "IL",
    "city": "Country Club Hills"
  },
  {
    "value": "60479",
    "label": "Verona - IL 60479",
    "county": "Grundy County",
    "state": "IL",
    "city": "Verona"
  },
  {
    "value": "60480",
    "label": "Willow Springs - IL 60480",
    "county": "Cook County",
    "state": "IL",
    "city": "Willow Springs"
  },
  {
    "value": "60481",
    "label": "Wilmington - IL 60481",
    "county": "Will County",
    "state": "IL",
    "city": "Wilmington"
  },
  {
    "value": "60482",
    "label": "Worth - IL 60482",
    "county": "Cook County",
    "state": "IL",
    "city": "Worth"
  },
  {
    "value": "60484",
    "label": "University Park - IL 60484",
    "county": "Will County",
    "state": "IL",
    "city": "University Park"
  },
  {
    "value": "60487",
    "label": "Tinley Park - IL 60487",
    "county": "Cook County",
    "state": "IL",
    "city": "Tinley Park"
  },
  {
    "value": "60490",
    "label": "Bolingbrook - IL 60490",
    "county": "Will County",
    "state": "IL",
    "city": "Bolingbrook"
  },
  {
    "value": "60491",
    "label": "Homer Glen - IL 60491",
    "county": "Will County",
    "state": "IL",
    "city": "Homer Glen"
  },
  {
    "value": "60501",
    "label": "Summit Argo - IL 60501",
    "county": "Cook County",
    "state": "IL",
    "city": "Summit Argo"
  },
  {
    "value": "60502",
    "label": "Aurora - IL 60502",
    "county": "DuPage County",
    "state": "IL",
    "city": "Aurora"
  },
  {
    "value": "60503",
    "label": "Aurora - IL 60503",
    "county": "Will County",
    "state": "IL",
    "city": "Aurora"
  },
  {
    "value": "60504",
    "label": "Aurora - IL 60504",
    "county": "DuPage County",
    "state": "IL",
    "city": "Aurora"
  },
  {
    "value": "60505",
    "label": "Aurora - IL 60505",
    "county": "Kane County",
    "state": "IL",
    "city": "Aurora"
  },
  {
    "value": "60506",
    "label": "Aurora - IL 60506",
    "county": "Kane County",
    "state": "IL",
    "city": "Aurora"
  },
  {
    "value": "60510",
    "label": "Batavia - IL 60510",
    "county": "Kane County",
    "state": "IL",
    "city": "Batavia"
  },
  {
    "value": "60511",
    "label": "Big Rock - IL 60511",
    "county": "Kane County",
    "state": "IL",
    "city": "Big Rock"
  },
  {
    "value": "60512",
    "label": "Bristol - IL 60512",
    "county": "Kendall County",
    "state": "IL",
    "city": "Bristol"
  },
  {
    "value": "60513",
    "label": "Brookfield - IL 60513",
    "county": "Cook County",
    "state": "IL",
    "city": "Brookfield"
  },
  {
    "value": "60514",
    "label": "Clarendon Hills - IL 60514",
    "county": "DuPage County",
    "state": "IL",
    "city": "Clarendon Hills"
  },
  {
    "value": "60515",
    "label": "Downers Grove - IL 60515",
    "county": "DuPage County",
    "state": "IL",
    "city": "Downers Grove"
  },
  {
    "value": "60516",
    "label": "Downers Grove - IL 60516",
    "county": "DuPage County",
    "state": "IL",
    "city": "Downers Grove"
  },
  {
    "value": "60517",
    "label": "Woodridge - IL 60517",
    "county": "DuPage County",
    "state": "IL",
    "city": "Woodridge"
  },
  {
    "value": "60518",
    "label": "Earlville - IL 60518",
    "county": "LaSalle County",
    "state": "IL",
    "city": "Earlville"
  },
  {
    "value": "60520",
    "label": "Hinckley - IL 60520",
    "county": "DeKalb County",
    "state": "IL",
    "city": "Hinckley"
  },
  {
    "value": "60521",
    "label": "Hinsdale - IL 60521",
    "county": "DuPage County",
    "state": "IL",
    "city": "Hinsdale"
  },
  {
    "value": "60523",
    "label": "Oak Brook - IL 60523",
    "county": "DuPage County",
    "state": "IL",
    "city": "Oak Brook"
  },
  {
    "value": "60525",
    "label": "La Grange - IL 60525",
    "county": "Cook County",
    "state": "IL",
    "city": "La Grange"
  },
  {
    "value": "60526",
    "label": "La Grange Park - IL 60526",
    "county": "Cook County",
    "state": "IL",
    "city": "La Grange Park"
  },
  {
    "value": "60527",
    "label": "Willowbrook - IL 60527",
    "county": "DuPage County",
    "state": "IL",
    "city": "Willowbrook"
  },
  {
    "value": "60530",
    "label": "Lee - IL 60530",
    "county": "Lee County",
    "state": "IL",
    "city": "Lee"
  },
  {
    "value": "60531",
    "label": "Leland - IL 60531",
    "county": "LaSalle County",
    "state": "IL",
    "city": "Leland"
  },
  {
    "value": "60532",
    "label": "Lisle - IL 60532",
    "county": "DuPage County",
    "state": "IL",
    "city": "Lisle"
  },
  {
    "value": "60534",
    "label": "Lyons - IL 60534",
    "county": "Cook County",
    "state": "IL",
    "city": "Lyons"
  },
  {
    "value": "60538",
    "label": "Montgomery - IL 60538",
    "county": "Kendall County",
    "state": "IL",
    "city": "Montgomery"
  },
  {
    "value": "60539",
    "label": "Mooseheart - IL 60539",
    "county": "Kane County",
    "state": "IL",
    "city": "Mooseheart"
  },
  {
    "value": "60540",
    "label": "Naperville - IL 60540",
    "county": "DuPage County",
    "state": "IL",
    "city": "Naperville"
  },
  {
    "value": "60541",
    "label": "Newark - IL 60541",
    "county": "Kendall County",
    "state": "IL",
    "city": "Newark"
  },
  {
    "value": "60542",
    "label": "North Aurora - IL 60542",
    "county": "Kane County",
    "state": "IL",
    "city": "North Aurora"
  },
  {
    "value": "60543",
    "label": "Oswego - IL 60543",
    "county": "Kendall County",
    "state": "IL",
    "city": "Oswego"
  },
  {
    "value": "60544",
    "label": "Plainfield - IL 60544",
    "county": "Will County",
    "state": "IL",
    "city": "Plainfield"
  },
  {
    "value": "60545",
    "label": "Plano - IL 60545",
    "county": "Kendall County",
    "state": "IL",
    "city": "Plano"
  },
  {
    "value": "60546",
    "label": "Riverside - IL 60546",
    "county": "Cook County",
    "state": "IL",
    "city": "Riverside"
  },
  {
    "value": "60548",
    "label": "Sandwich - IL 60548",
    "county": "DeKalb County",
    "state": "IL",
    "city": "Sandwich"
  },
  {
    "value": "60549",
    "label": "Serena - IL 60549",
    "county": "LaSalle County",
    "state": "IL",
    "city": "Serena"
  },
  {
    "value": "60550",
    "label": "Shabbona - IL 60550",
    "county": "DeKalb County",
    "state": "IL",
    "city": "Shabbona"
  },
  {
    "value": "60551",
    "label": "Sheridan - IL 60551",
    "county": "LaSalle County",
    "state": "IL",
    "city": "Sheridan"
  },
  {
    "value": "60552",
    "label": "Somonauk - IL 60552",
    "county": "LaSalle County",
    "state": "IL",
    "city": "Somonauk"
  },
  {
    "value": "60553",
    "label": "Steward - IL 60553",
    "county": "Lee County",
    "state": "IL",
    "city": "Steward"
  },
  {
    "value": "60554",
    "label": "Sugar Grove - IL 60554",
    "county": "Kane County",
    "state": "IL",
    "city": "Sugar Grove"
  },
  {
    "value": "60555",
    "label": "Warrenville - IL 60555",
    "county": "DuPage County",
    "state": "IL",
    "city": "Warrenville"
  },
  {
    "value": "60556",
    "label": "Waterman - IL 60556",
    "county": "DeKalb County",
    "state": "IL",
    "city": "Waterman"
  },
  {
    "value": "60558",
    "label": "Western Springs - IL 60558",
    "county": "Cook County",
    "state": "IL",
    "city": "Western Springs"
  },
  {
    "value": "60559",
    "label": "Westmont - IL 60559",
    "county": "DuPage County",
    "state": "IL",
    "city": "Westmont"
  },
  {
    "value": "60560",
    "label": "Yorkville - IL 60560",
    "county": "Kendall County",
    "state": "IL",
    "city": "Yorkville"
  },
  {
    "value": "60561",
    "label": "Darien - IL 60561",
    "county": "DuPage County",
    "state": "IL",
    "city": "Darien"
  },
  {
    "value": "60563",
    "label": "Naperville - IL 60563",
    "county": "DuPage County",
    "state": "IL",
    "city": "Naperville"
  },
  {
    "value": "60564",
    "label": "Naperville - IL 60564",
    "county": "Will County",
    "state": "IL",
    "city": "Naperville"
  },
  {
    "value": "60565",
    "label": "Naperville - IL 60565",
    "county": "DuPage County",
    "state": "IL",
    "city": "Naperville"
  },
  {
    "value": "60585",
    "label": "Plainfield - IL 60585",
    "county": "Will County",
    "state": "IL",
    "city": "Plainfield"
  },
  {
    "value": "60586",
    "label": "Plainfield - IL 60586",
    "county": "Will County",
    "state": "IL",
    "city": "Plainfield"
  },
  {
    "value": "60599",
    "label": "Fox Valley - IL 60599",
    "county": "Dupage County",
    "state": "IL",
    "city": "Fox Valley"
  },
  {
    "value": "60601",
    "label": "Chicago - IL 60601",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60602",
    "label": "Chicago - IL 60602",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60603",
    "label": "Chicago - IL 60603",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60604",
    "label": "Chicago - IL 60604",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60605",
    "label": "Chicago - IL 60605",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60606",
    "label": "Chicago - IL 60606",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60607",
    "label": "Chicago - IL 60607",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60608",
    "label": "Chicago - IL 60608",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60609",
    "label": "Chicago - IL 60609",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60610",
    "label": "Chicago - IL 60610",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60611",
    "label": "Chicago - IL 60611",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60612",
    "label": "Chicago - IL 60612",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60613",
    "label": "Chicago - IL 60613",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60614",
    "label": "Chicago - IL 60614",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60615",
    "label": "Chicago - IL 60615",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60616",
    "label": "Chicago - IL 60616",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60617",
    "label": "Chicago - IL 60617",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60618",
    "label": "Chicago - IL 60618",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60619",
    "label": "Chicago - IL 60619",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60620",
    "label": "Chicago - IL 60620",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60621",
    "label": "Chicago - IL 60621",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60622",
    "label": "Chicago - IL 60622",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60623",
    "label": "Chicago - IL 60623",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60624",
    "label": "Chicago - IL 60624",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60625",
    "label": "Chicago - IL 60625",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60626",
    "label": "Chicago - IL 60626",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60628",
    "label": "Chicago - IL 60628",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60629",
    "label": "Chicago - IL 60629",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60630",
    "label": "Chicago - IL 60630",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60631",
    "label": "Chicago - IL 60631",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60632",
    "label": "Chicago - IL 60632",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60633",
    "label": "Chicago - IL 60633",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60634",
    "label": "Chicago - IL 60634",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60636",
    "label": "Chicago - IL 60636",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60637",
    "label": "Chicago - IL 60637",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60638",
    "label": "Chicago - IL 60638",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60639",
    "label": "Chicago - IL 60639",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60640",
    "label": "Chicago - IL 60640",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60641",
    "label": "Chicago - IL 60641",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60642",
    "label": "Chicago - IL 60642",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60643",
    "label": "Chicago - IL 60643",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60644",
    "label": "Chicago - IL 60644",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60645",
    "label": "Chicago - IL 60645",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60646",
    "label": "Chicago - IL 60646",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60647",
    "label": "Chicago - IL 60647",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60649",
    "label": "Chicago - IL 60649",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60651",
    "label": "Chicago - IL 60651",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60652",
    "label": "Chicago - IL 60652",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60653",
    "label": "Chicago - IL 60653",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60654",
    "label": "Chicago - IL 60654",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60655",
    "label": "Chicago - IL 60655",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60656",
    "label": "Chicago - IL 60656",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60657",
    "label": "Chicago - IL 60657",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60659",
    "label": "Chicago - IL 60659",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60660",
    "label": "Chicago - IL 60660",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60661",
    "label": "Chicago - IL 60661",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60699",
    "label": "Chicago - IL 60699",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60701",
    "label": "Chicago - IL 60701",
    "county": "Cook County",
    "state": "IL",
    "city": "Chicago"
  },
  {
    "value": "60706",
    "label": "Harwood Heights - IL 60706",
    "county": "Cook County",
    "state": "IL",
    "city": "Harwood Heights"
  },
  {
    "value": "60707",
    "label": "Elmwood Park - IL 60707",
    "county": "Cook County",
    "state": "IL",
    "city": "Elmwood Park"
  },
  {
    "value": "60712",
    "label": "Lincolnwood - IL 60712",
    "county": "Cook County",
    "state": "IL",
    "city": "Lincolnwood"
  },
  {
    "value": "60714",
    "label": "Niles - IL 60714",
    "county": "Cook County",
    "state": "IL",
    "city": "Niles"
  },
  {
    "value": "60803",
    "label": "Alsip - IL 60803",
    "county": "Cook County",
    "state": "IL",
    "city": "Alsip"
  },
  {
    "value": "60804",
    "label": "Cicero - IL 60804",
    "county": "Cook County",
    "state": "IL",
    "city": "Cicero"
  },
  {
    "value": "60805",
    "label": "Evergreen Park - IL 60805",
    "county": "Cook County",
    "state": "IL",
    "city": "Evergreen Park"
  },
  {
    "value": "60827",
    "label": "Riverdale - IL 60827",
    "county": "Cook County",
    "state": "IL",
    "city": "Riverdale"
  },
  {
    "value": "60901",
    "label": "Kankakee - IL 60901",
    "county": "Kankakee County",
    "state": "IL",
    "city": "Kankakee"
  },
  {
    "value": "60911",
    "label": "Ashkum - IL 60911",
    "county": "Iroquois County",
    "state": "IL",
    "city": "Ashkum"
  },
  {
    "value": "60912",
    "label": "Beaverville - IL 60912",
    "county": "Iroquois County",
    "state": "IL",
    "city": "Beaverville"
  },
  {
    "value": "60913",
    "label": "Bonfield - IL 60913",
    "county": "Kankakee County",
    "state": "IL",
    "city": "Bonfield"
  },
  {
    "value": "60914",
    "label": "Bourbonnais - IL 60914",
    "county": "Kankakee County",
    "state": "IL",
    "city": "Bourbonnais"
  },
  {
    "value": "60915",
    "label": "Bradley - IL 60915",
    "county": "Kankakee County",
    "state": "IL",
    "city": "Bradley"
  },
  {
    "value": "60917",
    "label": "Buckingham - IL 60917",
    "county": "Kankakee County",
    "state": "IL",
    "city": "Buckingham"
  },
  {
    "value": "60918",
    "label": "Buckley - IL 60918",
    "county": "Iroquois County",
    "state": "IL",
    "city": "Buckley"
  },
  {
    "value": "60919",
    "label": "Cabery - IL 60919",
    "county": "Ford County",
    "state": "IL",
    "city": "Cabery"
  },
  {
    "value": "60921",
    "label": "Chatsworth - IL 60921",
    "county": "Livingston County",
    "state": "IL",
    "city": "Chatsworth"
  },
  {
    "value": "60922",
    "label": "Chebanse - IL 60922",
    "county": "Kankakee County",
    "state": "IL",
    "city": "Chebanse"
  },
  {
    "value": "60924",
    "label": "Cissna Park - IL 60924",
    "county": "Iroquois County",
    "state": "IL",
    "city": "Cissna Park"
  },
  {
    "value": "60927",
    "label": "Clifton - IL 60927",
    "county": "Iroquois County",
    "state": "IL",
    "city": "Clifton"
  },
  {
    "value": "60928",
    "label": "Crescent City - IL 60928",
    "county": "Iroquois County",
    "state": "IL",
    "city": "Crescent City"
  },
  {
    "value": "60929",
    "label": "Cullom - IL 60929",
    "county": "Livingston County",
    "state": "IL",
    "city": "Cullom"
  },
  {
    "value": "60930",
    "label": "Danforth - IL 60930",
    "county": "Iroquois County",
    "state": "IL",
    "city": "Danforth"
  },
  {
    "value": "60931",
    "label": "Donovan - IL 60931",
    "county": "Iroquois County",
    "state": "IL",
    "city": "Donovan"
  },
  {
    "value": "60934",
    "label": "Emington - IL 60934",
    "county": "Livingston County",
    "state": "IL",
    "city": "Emington"
  },
  {
    "value": "60935",
    "label": "Essex - IL 60935",
    "county": "Kankakee County",
    "state": "IL",
    "city": "Essex"
  },
  {
    "value": "60936",
    "label": "Gibson City - IL 60936",
    "county": "Ford County",
    "state": "IL",
    "city": "Gibson City"
  },
  {
    "value": "60938",
    "label": "Gilman - IL 60938",
    "county": "Iroquois County",
    "state": "IL",
    "city": "Gilman"
  },
  {
    "value": "60940",
    "label": "Grant Park - IL 60940",
    "county": "Kankakee County",
    "state": "IL",
    "city": "Grant Park"
  },
  {
    "value": "60941",
    "label": "Herscher - IL 60941",
    "county": "Kankakee County",
    "state": "IL",
    "city": "Herscher"
  },
  {
    "value": "60942",
    "label": "Hoopeston - IL 60942",
    "county": "Vermilion County",
    "state": "IL",
    "city": "Hoopeston"
  },
  {
    "value": "60946",
    "label": "Kempton - IL 60946",
    "county": "Ford County",
    "state": "IL",
    "city": "Kempton"
  },
  {
    "value": "60948",
    "label": "Loda - IL 60948",
    "county": "Iroquois County",
    "state": "IL",
    "city": "Loda"
  },
  {
    "value": "60949",
    "label": "Ludlow - IL 60949",
    "county": "Champaign County",
    "state": "IL",
    "city": "Ludlow"
  },
  {
    "value": "60950",
    "label": "Manteno - IL 60950",
    "county": "Kankakee County",
    "state": "IL",
    "city": "Manteno"
  },
  {
    "value": "60951",
    "label": "Martinton - IL 60951",
    "county": "Iroquois County",
    "state": "IL",
    "city": "Martinton"
  },
  {
    "value": "60952",
    "label": "Melvin - IL 60952",
    "county": "Ford County",
    "state": "IL",
    "city": "Melvin"
  },
  {
    "value": "60953",
    "label": "Milford - IL 60953",
    "county": "Iroquois County",
    "state": "IL",
    "city": "Milford"
  },
  {
    "value": "60954",
    "label": "Momence - IL 60954",
    "county": "Kankakee County",
    "state": "IL",
    "city": "Momence"
  },
  {
    "value": "60955",
    "label": "Onarga - IL 60955",
    "county": "Iroquois County",
    "state": "IL",
    "city": "Onarga"
  },
  {
    "value": "60957",
    "label": "Paxton - IL 60957",
    "county": "Ford County",
    "state": "IL",
    "city": "Paxton"
  },
  {
    "value": "60958",
    "label": "Pembroke Township - IL 60958",
    "county": "Kankakee County",
    "state": "IL",
    "city": "Pembroke Township"
  },
  {
    "value": "60959",
    "label": "Piper City - IL 60959",
    "county": "Ford County",
    "state": "IL",
    "city": "Piper City"
  },
  {
    "value": "60960",
    "label": "Rankin - IL 60960",
    "county": "Vermilion County",
    "state": "IL",
    "city": "Rankin"
  },
  {
    "value": "60961",
    "label": "Reddick - IL 60961",
    "county": "Kankakee County",
    "state": "IL",
    "city": "Reddick"
  },
  {
    "value": "60962",
    "label": "Roberts - IL 60962",
    "county": "Ford County",
    "state": "IL",
    "city": "Roberts"
  },
  {
    "value": "60963",
    "label": "Rossville - IL 60963",
    "county": "Vermilion County",
    "state": "IL",
    "city": "Rossville"
  },
  {
    "value": "60964",
    "label": "Saint Anne - IL 60964",
    "county": "Kankakee County",
    "state": "IL",
    "city": "Saint Anne"
  },
  {
    "value": "60966",
    "label": "Sheldon - IL 60966",
    "county": "Iroquois County",
    "state": "IL",
    "city": "Sheldon"
  },
  {
    "value": "60968",
    "label": "Thawville - IL 60968",
    "county": "Iroquois County",
    "state": "IL",
    "city": "Thawville"
  },
  {
    "value": "60970",
    "label": "Watseka - IL 60970",
    "county": "Iroquois County",
    "state": "IL",
    "city": "Watseka"
  },
  {
    "value": "60973",
    "label": "Wellington - IL 60973",
    "county": "Iroquois County",
    "state": "IL",
    "city": "Wellington"
  },
  {
    "value": "61001",
    "label": "Apple River - IL 61001",
    "county": "Jo Daviess County",
    "state": "IL",
    "city": "Apple River"
  },
  {
    "value": "61006",
    "label": "Ashton - IL 61006",
    "county": "Lee County",
    "state": "IL",
    "city": "Ashton"
  },
  {
    "value": "61007",
    "label": "Baileyville - IL 61007",
    "county": "Ogle County",
    "state": "IL",
    "city": "Baileyville"
  },
  {
    "value": "61008",
    "label": "Belvidere - IL 61008",
    "county": "Boone County",
    "state": "IL",
    "city": "Belvidere"
  },
  {
    "value": "61010",
    "label": "Byron - IL 61010",
    "county": "Ogle County",
    "state": "IL",
    "city": "Byron"
  },
  {
    "value": "61011",
    "label": "Caledonia - IL 61011",
    "county": "Boone County",
    "state": "IL",
    "city": "Caledonia"
  },
  {
    "value": "61012",
    "label": "Capron - IL 61012",
    "county": "Boone County",
    "state": "IL",
    "city": "Capron"
  },
  {
    "value": "61014",
    "label": "Chadwick - IL 61014",
    "county": "Carroll County",
    "state": "IL",
    "city": "Chadwick"
  },
  {
    "value": "61015",
    "label": "Chana - IL 61015",
    "county": "Ogle County",
    "state": "IL",
    "city": "Chana"
  },
  {
    "value": "61016",
    "label": "Cherry Valley - IL 61016",
    "county": "Winnebago County",
    "state": "IL",
    "city": "Cherry Valley"
  },
  {
    "value": "61018",
    "label": "Dakota - IL 61018",
    "county": "Stephenson County",
    "state": "IL",
    "city": "Dakota"
  },
  {
    "value": "61019",
    "label": "Davis - IL 61019",
    "county": "Stephenson County",
    "state": "IL",
    "city": "Davis"
  },
  {
    "value": "61020",
    "label": "Davis Junction - IL 61020",
    "county": "Ogle County",
    "state": "IL",
    "city": "Davis Junction"
  },
  {
    "value": "61021",
    "label": "Dixon - IL 61021",
    "county": "Lee County",
    "state": "IL",
    "city": "Dixon"
  },
  {
    "value": "61024",
    "label": "Durand - IL 61024",
    "county": "Winnebago County",
    "state": "IL",
    "city": "Durand"
  },
  {
    "value": "61025",
    "label": "East Dubuque - IL 61025",
    "county": "Jo Daviess County",
    "state": "IL",
    "city": "East Dubuque"
  },
  {
    "value": "61028",
    "label": "Elizabeth - IL 61028",
    "county": "Jo Daviess County",
    "state": "IL",
    "city": "Elizabeth"
  },
  {
    "value": "61030",
    "label": "Forreston - IL 61030",
    "county": "Ogle County",
    "state": "IL",
    "city": "Forreston"
  },
  {
    "value": "61031",
    "label": "Franklin Grove - IL 61031",
    "county": "Lee County",
    "state": "IL",
    "city": "Franklin Grove"
  },
  {
    "value": "61032",
    "label": "Freeport - IL 61032",
    "county": "Stephenson County",
    "state": "IL",
    "city": "Freeport"
  },
  {
    "value": "61036",
    "label": "Galena - IL 61036",
    "county": "Jo Daviess County",
    "state": "IL",
    "city": "Galena"
  },
  {
    "value": "61038",
    "label": "Garden Prairie - IL 61038",
    "county": "Boone County",
    "state": "IL",
    "city": "Garden Prairie"
  },
  {
    "value": "61039",
    "label": "German Valley - IL 61039",
    "county": "Stephenson County",
    "state": "IL",
    "city": "German Valley"
  },
  {
    "value": "61041",
    "label": "Hanover - IL 61041",
    "county": "Jo Daviess County",
    "state": "IL",
    "city": "Hanover"
  },
  {
    "value": "61042",
    "label": "Harmon - IL 61042",
    "county": "Lee County",
    "state": "IL",
    "city": "Harmon"
  },
  {
    "value": "61044",
    "label": "Kent - IL 61044",
    "county": "Stephenson County",
    "state": "IL",
    "city": "Kent"
  },
  {
    "value": "61046",
    "label": "Lanark - IL 61046",
    "county": "Carroll County",
    "state": "IL",
    "city": "Lanark"
  },
  {
    "value": "61047",
    "label": "Leaf River - IL 61047",
    "county": "Ogle County",
    "state": "IL",
    "city": "Leaf River"
  },
  {
    "value": "61048",
    "label": "Lena - IL 61048",
    "county": "Stephenson County",
    "state": "IL",
    "city": "Lena"
  },
  {
    "value": "61049",
    "label": "Lindenwood - IL 61049",
    "county": "Ogle County",
    "state": "IL",
    "city": "Lindenwood"
  },
  {
    "value": "61050",
    "label": "Mc Connell - IL 61050",
    "county": "Stephenson County",
    "state": "IL",
    "city": "Mc Connell"
  },
  {
    "value": "61051",
    "label": "Milledgeville - IL 61051",
    "county": "Carroll County",
    "state": "IL",
    "city": "Milledgeville"
  },
  {
    "value": "61052",
    "label": "Monroe Center - IL 61052",
    "county": "Ogle County",
    "state": "IL",
    "city": "Monroe Center"
  },
  {
    "value": "61053",
    "label": "Mount Carroll - IL 61053",
    "county": "Carroll County",
    "state": "IL",
    "city": "Mount Carroll"
  },
  {
    "value": "61054",
    "label": "Mount Morris - IL 61054",
    "county": "Ogle County",
    "state": "IL",
    "city": "Mount Morris"
  },
  {
    "value": "61060",
    "label": "Orangeville - IL 61060",
    "county": "Stephenson County",
    "state": "IL",
    "city": "Orangeville"
  },
  {
    "value": "61061",
    "label": "Oregon - IL 61061",
    "county": "Ogle County",
    "state": "IL",
    "city": "Oregon"
  },
  {
    "value": "61062",
    "label": "Pearl City - IL 61062",
    "county": "Stephenson County",
    "state": "IL",
    "city": "Pearl City"
  },
  {
    "value": "61063",
    "label": "Pecatonica - IL 61063",
    "county": "Winnebago County",
    "state": "IL",
    "city": "Pecatonica"
  },
  {
    "value": "61064",
    "label": "Polo - IL 61064",
    "county": "Ogle County",
    "state": "IL",
    "city": "Polo"
  },
  {
    "value": "61065",
    "label": "Poplar Grove - IL 61065",
    "county": "Boone County",
    "state": "IL",
    "city": "Poplar Grove"
  },
  {
    "value": "61067",
    "label": "Ridott - IL 61067",
    "county": "Stephenson County",
    "state": "IL",
    "city": "Ridott"
  },
  {
    "value": "61068",
    "label": "Rochelle - IL 61068",
    "county": "Ogle County",
    "state": "IL",
    "city": "Rochelle"
  },
  {
    "value": "61070",
    "label": "Rock City - IL 61070",
    "county": "Stephenson County",
    "state": "IL",
    "city": "Rock City"
  },
  {
    "value": "61071",
    "label": "Rock Falls - IL 61071",
    "county": "Whiteside County",
    "state": "IL",
    "city": "Rock Falls"
  },
  {
    "value": "61072",
    "label": "Rockton - IL 61072",
    "county": "Winnebago County",
    "state": "IL",
    "city": "Rockton"
  },
  {
    "value": "61073",
    "label": "Roscoe - IL 61073",
    "county": "Winnebago County",
    "state": "IL",
    "city": "Roscoe"
  },
  {
    "value": "61074",
    "label": "Savanna - IL 61074",
    "county": "Carroll County",
    "state": "IL",
    "city": "Savanna"
  },
  {
    "value": "61075",
    "label": "Scales Mound - IL 61075",
    "county": "Jo Daviess County",
    "state": "IL",
    "city": "Scales Mound"
  },
  {
    "value": "61078",
    "label": "Shannon - IL 61078",
    "county": "Carroll County",
    "state": "IL",
    "city": "Shannon"
  },
  {
    "value": "61080",
    "label": "South Beloit - IL 61080",
    "county": "Winnebago County",
    "state": "IL",
    "city": "South Beloit"
  },
  {
    "value": "61081",
    "label": "Sterling - IL 61081",
    "county": "Whiteside County",
    "state": "IL",
    "city": "Sterling"
  },
  {
    "value": "61084",
    "label": "Stillman Valley - IL 61084",
    "county": "Ogle County",
    "state": "IL",
    "city": "Stillman Valley"
  },
  {
    "value": "61085",
    "label": "Stockton - IL 61085",
    "county": "Jo Daviess County",
    "state": "IL",
    "city": "Stockton"
  },
  {
    "value": "61087",
    "label": "Warren - IL 61087",
    "county": "Jo Daviess County",
    "state": "IL",
    "city": "Warren"
  },
  {
    "value": "61088",
    "label": "Winnebago - IL 61088",
    "county": "Winnebago County",
    "state": "IL",
    "city": "Winnebago"
  },
  {
    "value": "61089",
    "label": "Winslow - IL 61089",
    "county": "Stephenson County",
    "state": "IL",
    "city": "Winslow"
  },
  {
    "value": "61101",
    "label": "Rockford - IL 61101",
    "county": "Winnebago County",
    "state": "IL",
    "city": "Rockford"
  },
  {
    "value": "61102",
    "label": "Rockford - IL 61102",
    "county": "Winnebago County",
    "state": "IL",
    "city": "Rockford"
  },
  {
    "value": "61103",
    "label": "Rockford - IL 61103",
    "county": "Winnebago County",
    "state": "IL",
    "city": "Rockford"
  },
  {
    "value": "61104",
    "label": "Rockford - IL 61104",
    "county": "Winnebago County",
    "state": "IL",
    "city": "Rockford"
  },
  {
    "value": "61107",
    "label": "Rockford - IL 61107",
    "county": "Winnebago County",
    "state": "IL",
    "city": "Rockford"
  },
  {
    "value": "61108",
    "label": "Rockford - IL 61108",
    "county": "Winnebago County",
    "state": "IL",
    "city": "Rockford"
  },
  {
    "value": "61109",
    "label": "Rockford - IL 61109",
    "county": "Winnebago County",
    "state": "IL",
    "city": "Rockford"
  },
  {
    "value": "61111",
    "label": "Loves Park - IL 61111",
    "county": "Winnebago County",
    "state": "IL",
    "city": "Loves Park"
  },
  {
    "value": "61112",
    "label": "Rockford - IL 61112",
    "county": "Winnebago County",
    "state": "IL",
    "city": "Rockford"
  },
  {
    "value": "61114",
    "label": "Rockford - IL 61114",
    "county": "Winnebago County",
    "state": "IL",
    "city": "Rockford"
  },
  {
    "value": "61115",
    "label": "Machesney Park - IL 61115",
    "county": "Winnebago County",
    "state": "IL",
    "city": "Machesney Park"
  },
  {
    "value": "61201",
    "label": "Rock Island - IL 61201",
    "county": "Rock Island County",
    "state": "IL",
    "city": "Rock Island"
  },
  {
    "value": "61230",
    "label": "Albany - IL 61230",
    "county": "Whiteside County",
    "state": "IL",
    "city": "Albany"
  },
  {
    "value": "61231",
    "label": "Aledo - IL 61231",
    "county": "Mercer County",
    "state": "IL",
    "city": "Aledo"
  },
  {
    "value": "61232",
    "label": "Andalusia - IL 61232",
    "county": "Rock Island County",
    "state": "IL",
    "city": "Andalusia"
  },
  {
    "value": "61234",
    "label": "Annawan - IL 61234",
    "county": "Henry County",
    "state": "IL",
    "city": "Annawan"
  },
  {
    "value": "61235",
    "label": "Atkinson - IL 61235",
    "county": "Henry County",
    "state": "IL",
    "city": "Atkinson"
  },
  {
    "value": "61238",
    "label": "Cambridge - IL 61238",
    "county": "Henry County",
    "state": "IL",
    "city": "Cambridge"
  },
  {
    "value": "61239",
    "label": "Carbon Cliff - IL 61239",
    "county": "Rock Island County",
    "state": "IL",
    "city": "Carbon Cliff"
  },
  {
    "value": "61240",
    "label": "Coal Valley - IL 61240",
    "county": "Rock Island County",
    "state": "IL",
    "city": "Coal Valley"
  },
  {
    "value": "61241",
    "label": "Colona - IL 61241",
    "county": "Henry County",
    "state": "IL",
    "city": "Colona"
  },
  {
    "value": "61242",
    "label": "Cordova - IL 61242",
    "county": "Rock Island County",
    "state": "IL",
    "city": "Cordova"
  },
  {
    "value": "61243",
    "label": "Deer Grove - IL 61243",
    "county": "Whiteside County",
    "state": "IL",
    "city": "Deer Grove"
  },
  {
    "value": "61244",
    "label": "East Moline - IL 61244",
    "county": "Rock Island County",
    "state": "IL",
    "city": "East Moline"
  },
  {
    "value": "61250",
    "label": "Erie - IL 61250",
    "county": "Whiteside County",
    "state": "IL",
    "city": "Erie"
  },
  {
    "value": "61251",
    "label": "Fenton - IL 61251",
    "county": "Whiteside County",
    "state": "IL",
    "city": "Fenton"
  },
  {
    "value": "61252",
    "label": "Fulton - IL 61252",
    "county": "Whiteside County",
    "state": "IL",
    "city": "Fulton"
  },
  {
    "value": "61254",
    "label": "Geneseo - IL 61254",
    "county": "Henry County",
    "state": "IL",
    "city": "Geneseo"
  },
  {
    "value": "61256",
    "label": "Hampton - IL 61256",
    "county": "Rock Island County",
    "state": "IL",
    "city": "Hampton"
  },
  {
    "value": "61257",
    "label": "Hillsdale - IL 61257",
    "county": "Rock Island County",
    "state": "IL",
    "city": "Hillsdale"
  },
  {
    "value": "61258",
    "label": "Hooppole - IL 61258",
    "county": "Henry County",
    "state": "IL",
    "city": "Hooppole"
  },
  {
    "value": "61259",
    "label": "Illinois City - IL 61259",
    "county": "Rock Island County",
    "state": "IL",
    "city": "Illinois City"
  },
  {
    "value": "61260",
    "label": "Joy - IL 61260",
    "county": "Mercer County",
    "state": "IL",
    "city": "Joy"
  },
  {
    "value": "61261",
    "label": "Lyndon - IL 61261",
    "county": "Whiteside County",
    "state": "IL",
    "city": "Lyndon"
  },
  {
    "value": "61262",
    "label": "Lynn Center - IL 61262",
    "county": "Henry County",
    "state": "IL",
    "city": "Lynn Center"
  },
  {
    "value": "61264",
    "label": "Milan - IL 61264",
    "county": "Rock Island County",
    "state": "IL",
    "city": "Milan"
  },
  {
    "value": "61265",
    "label": "Moline - IL 61265",
    "county": "Rock Island County",
    "state": "IL",
    "city": "Moline"
  },
  {
    "value": "61270",
    "label": "Morrison - IL 61270",
    "county": "Whiteside County",
    "state": "IL",
    "city": "Morrison"
  },
  {
    "value": "61272",
    "label": "New Boston - IL 61272",
    "county": "Mercer County",
    "state": "IL",
    "city": "New Boston"
  },
  {
    "value": "61273",
    "label": "Orion - IL 61273",
    "county": "Henry County",
    "state": "IL",
    "city": "Orion"
  },
  {
    "value": "61274",
    "label": "Osco - IL 61274",
    "county": "Henry County",
    "state": "IL",
    "city": "Osco"
  },
  {
    "value": "61275",
    "label": "Port Byron - IL 61275",
    "county": "Rock Island County",
    "state": "IL",
    "city": "Port Byron"
  },
  {
    "value": "61277",
    "label": "Prophetstown - IL 61277",
    "county": "Whiteside County",
    "state": "IL",
    "city": "Prophetstown"
  },
  {
    "value": "61279",
    "label": "Reynolds - IL 61279",
    "county": "Rock Island County",
    "state": "IL",
    "city": "Reynolds"
  },
  {
    "value": "61281",
    "label": "Sherrard - IL 61281",
    "county": "Mercer County",
    "state": "IL",
    "city": "Sherrard"
  },
  {
    "value": "61282",
    "label": "Silvis - IL 61282",
    "county": "Rock Island County",
    "state": "IL",
    "city": "Silvis"
  },
  {
    "value": "61283",
    "label": "Tampico - IL 61283",
    "county": "Whiteside County",
    "state": "IL",
    "city": "Tampico"
  },
  {
    "value": "61284",
    "label": "Taylor Ridge - IL 61284",
    "county": "Rock Island County",
    "state": "IL",
    "city": "Taylor Ridge"
  },
  {
    "value": "61285",
    "label": "Thomson - IL 61285",
    "county": "Carroll County",
    "state": "IL",
    "city": "Thomson"
  },
  {
    "value": "61301",
    "label": "La Salle - IL 61301",
    "county": "LaSalle County",
    "state": "IL",
    "city": "La Salle"
  },
  {
    "value": "61310",
    "label": "Amboy - IL 61310",
    "county": "Lee County",
    "state": "IL",
    "city": "Amboy"
  },
  {
    "value": "61311",
    "label": "Ancona - IL 61311",
    "county": "Livingston County",
    "state": "IL",
    "city": "Ancona"
  },
  {
    "value": "61312",
    "label": "Arlington - IL 61312",
    "county": "Bureau County",
    "state": "IL",
    "city": "Arlington"
  },
  {
    "value": "61313",
    "label": "Blackstone - IL 61313",
    "county": "Livingston County",
    "state": "IL",
    "city": "Blackstone"
  },
  {
    "value": "61314",
    "label": "Buda - IL 61314",
    "county": "Bureau County",
    "state": "IL",
    "city": "Buda"
  },
  {
    "value": "61318",
    "label": "Compton - IL 61318",
    "county": "Lee County",
    "state": "IL",
    "city": "Compton"
  },
  {
    "value": "61319",
    "label": "Cornell - IL 61319",
    "county": "Livingston County",
    "state": "IL",
    "city": "Cornell"
  },
  {
    "value": "61320",
    "label": "Dalzell - IL 61320",
    "county": "Bureau County",
    "state": "IL",
    "city": "Dalzell"
  },
  {
    "value": "61321",
    "label": "Dana - IL 61321",
    "county": "LaSalle County",
    "state": "IL",
    "city": "Dana"
  },
  {
    "value": "61325",
    "label": "Grand Ridge - IL 61325",
    "county": "LaSalle County",
    "state": "IL",
    "city": "Grand Ridge"
  },
  {
    "value": "61326",
    "label": "Granville - IL 61326",
    "county": "Putnam County",
    "state": "IL",
    "city": "Granville"
  },
  {
    "value": "61327",
    "label": "Hennepin - IL 61327",
    "county": "Putnam County",
    "state": "IL",
    "city": "Hennepin"
  },
  {
    "value": "61329",
    "label": "Ladd - IL 61329",
    "county": "Bureau County",
    "state": "IL",
    "city": "Ladd"
  },
  {
    "value": "61330",
    "label": "La Moille - IL 61330",
    "county": "Bureau County",
    "state": "IL",
    "city": "La Moille"
  },
  {
    "value": "61333",
    "label": "Long Point - IL 61333",
    "county": "Livingston County",
    "state": "IL",
    "city": "Long Point"
  },
  {
    "value": "61334",
    "label": "Lostant - IL 61334",
    "county": "LaSalle County",
    "state": "IL",
    "city": "Lostant"
  },
  {
    "value": "61335",
    "label": "Mc Nabb - IL 61335",
    "county": "Putnam County",
    "state": "IL",
    "city": "Mc Nabb"
  },
  {
    "value": "61336",
    "label": "Magnolia - IL 61336",
    "county": "Putnam County",
    "state": "IL",
    "city": "Magnolia"
  },
  {
    "value": "61337",
    "label": "Malden - IL 61337",
    "county": "Bureau County",
    "state": "IL",
    "city": "Malden"
  },
  {
    "value": "61341",
    "label": "Marseilles - IL 61341",
    "county": "LaSalle County",
    "state": "IL",
    "city": "Marseilles"
  },
  {
    "value": "61342",
    "label": "Mendota - IL 61342",
    "county": "LaSalle County",
    "state": "IL",
    "city": "Mendota"
  },
  {
    "value": "61344",
    "label": "Mineral - IL 61344",
    "county": "Bureau County",
    "state": "IL",
    "city": "Mineral"
  },
  {
    "value": "61345",
    "label": "Neponset - IL 61345",
    "county": "Bureau County",
    "state": "IL",
    "city": "Neponset"
  },
  {
    "value": "61348",
    "label": "Oglesby - IL 61348",
    "county": "LaSalle County",
    "state": "IL",
    "city": "Oglesby"
  },
  {
    "value": "61349",
    "label": "Ohio - IL 61349",
    "county": "Bureau County",
    "state": "IL",
    "city": "Ohio"
  },
  {
    "value": "61350",
    "label": "Ottawa - IL 61350",
    "county": "LaSalle County",
    "state": "IL",
    "city": "Ottawa"
  },
  {
    "value": "61353",
    "label": "Paw Paw - IL 61353",
    "county": "Lee County",
    "state": "IL",
    "city": "Paw Paw"
  },
  {
    "value": "61354",
    "label": "Peru - IL 61354",
    "county": "LaSalle County",
    "state": "IL",
    "city": "Peru"
  },
  {
    "value": "61356",
    "label": "Princeton - IL 61356",
    "county": "Bureau County",
    "state": "IL",
    "city": "Princeton"
  },
  {
    "value": "61358",
    "label": "Rutland - IL 61358",
    "county": "LaSalle County",
    "state": "IL",
    "city": "Rutland"
  },
  {
    "value": "61360",
    "label": "Seneca - IL 61360",
    "county": "LaSalle County",
    "state": "IL",
    "city": "Seneca"
  },
  {
    "value": "61361",
    "label": "Sheffield - IL 61361",
    "county": "Bureau County",
    "state": "IL",
    "city": "Sheffield"
  },
  {
    "value": "61362",
    "label": "Spring Valley - IL 61362",
    "county": "Bureau County",
    "state": "IL",
    "city": "Spring Valley"
  },
  {
    "value": "61364",
    "label": "Streator - IL 61364",
    "county": "LaSalle County",
    "state": "IL",
    "city": "Streator"
  },
  {
    "value": "61367",
    "label": "Sublette - IL 61367",
    "county": "Lee County",
    "state": "IL",
    "city": "Sublette"
  },
  {
    "value": "61368",
    "label": "Tiskilwa - IL 61368",
    "county": "Bureau County",
    "state": "IL",
    "city": "Tiskilwa"
  },
  {
    "value": "61369",
    "label": "Toluca - IL 61369",
    "county": "Marshall County",
    "state": "IL",
    "city": "Toluca"
  },
  {
    "value": "61370",
    "label": "Tonica - IL 61370",
    "county": "LaSalle County",
    "state": "IL",
    "city": "Tonica"
  },
  {
    "value": "61373",
    "label": "Utica - IL 61373",
    "county": "LaSalle County",
    "state": "IL",
    "city": "Utica"
  },
  {
    "value": "61375",
    "label": "Varna - IL 61375",
    "county": "Marshall County",
    "state": "IL",
    "city": "Varna"
  },
  {
    "value": "61376",
    "label": "Walnut - IL 61376",
    "county": "Bureau County",
    "state": "IL",
    "city": "Walnut"
  },
  {
    "value": "61377",
    "label": "Wenona - IL 61377",
    "county": "Marshall County",
    "state": "IL",
    "city": "Wenona"
  },
  {
    "value": "61378",
    "label": "West Brooklyn - IL 61378",
    "county": "Lee County",
    "state": "IL",
    "city": "West Brooklyn"
  },
  {
    "value": "61379",
    "label": "Wyanet - IL 61379",
    "county": "Bureau County",
    "state": "IL",
    "city": "Wyanet"
  },
  {
    "value": "61401",
    "label": "Galesburg - IL 61401",
    "county": "Knox County",
    "state": "IL",
    "city": "Galesburg"
  },
  {
    "value": "61410",
    "label": "Abingdon - IL 61410",
    "county": "Knox County",
    "state": "IL",
    "city": "Abingdon"
  },
  {
    "value": "61411",
    "label": "Adair - IL 61411",
    "county": "McDonough County",
    "state": "IL",
    "city": "Adair"
  },
  {
    "value": "61412",
    "label": "Alexis - IL 61412",
    "county": "Warren County",
    "state": "IL",
    "city": "Alexis"
  },
  {
    "value": "61413",
    "label": "Alpha - IL 61413",
    "county": "Henry County",
    "state": "IL",
    "city": "Alpha"
  },
  {
    "value": "61414",
    "label": "Altona - IL 61414",
    "county": "Knox County",
    "state": "IL",
    "city": "Altona"
  },
  {
    "value": "61415",
    "label": "Avon - IL 61415",
    "county": "Fulton County",
    "state": "IL",
    "city": "Avon"
  },
  {
    "value": "61417",
    "label": "Berwick - IL 61417",
    "county": "Warren County",
    "state": "IL",
    "city": "Berwick"
  },
  {
    "value": "61418",
    "label": "Biggsville - IL 61418",
    "county": "Henderson County",
    "state": "IL",
    "city": "Biggsville"
  },
  {
    "value": "61420",
    "label": "Blandinsville - IL 61420",
    "county": "McDonough County",
    "state": "IL",
    "city": "Blandinsville"
  },
  {
    "value": "61421",
    "label": "Bradford - IL 61421",
    "county": "Stark County",
    "state": "IL",
    "city": "Bradford"
  },
  {
    "value": "61422",
    "label": "Bushnell - IL 61422",
    "county": "McDonough County",
    "state": "IL",
    "city": "Bushnell"
  },
  {
    "value": "61423",
    "label": "Cameron - IL 61423",
    "county": "Warren County",
    "state": "IL",
    "city": "Cameron"
  },
  {
    "value": "61425",
    "label": "Carman - IL 61425",
    "county": "Henderson County",
    "state": "IL",
    "city": "Carman"
  },
  {
    "value": "61427",
    "label": "Cuba - IL 61427",
    "county": "Fulton County",
    "state": "IL",
    "city": "Cuba"
  },
  {
    "value": "61428",
    "label": "Dahinda - IL 61428",
    "county": "Knox County",
    "state": "IL",
    "city": "Dahinda"
  },
  {
    "value": "61430",
    "label": "East Galesburg - IL 61430",
    "county": "Knox County",
    "state": "IL",
    "city": "East Galesburg"
  },
  {
    "value": "61431",
    "label": "Ellisville - IL 61431",
    "county": "Fulton County",
    "state": "IL",
    "city": "Ellisville"
  },
  {
    "value": "61432",
    "label": "Fairview - IL 61432",
    "county": "Fulton County",
    "state": "IL",
    "city": "Fairview"
  },
  {
    "value": "61434",
    "label": "Galva - IL 61434",
    "county": "Henry County",
    "state": "IL",
    "city": "Galva"
  },
  {
    "value": "61435",
    "label": "Gerlaw - IL 61435",
    "county": "Warren County",
    "state": "IL",
    "city": "Gerlaw"
  },
  {
    "value": "61436",
    "label": "Gilson - IL 61436",
    "county": "Knox County",
    "state": "IL",
    "city": "Gilson"
  },
  {
    "value": "61437",
    "label": "Gladstone - IL 61437",
    "county": "Henderson County",
    "state": "IL",
    "city": "Gladstone"
  },
  {
    "value": "61438",
    "label": "Good Hope - IL 61438",
    "county": "McDonough County",
    "state": "IL",
    "city": "Good Hope"
  },
  {
    "value": "61440",
    "label": "Industry - IL 61440",
    "county": "McDonough County",
    "state": "IL",
    "city": "Industry"
  },
  {
    "value": "61441",
    "label": "Ipava - IL 61441",
    "county": "Fulton County",
    "state": "IL",
    "city": "Ipava"
  },
  {
    "value": "61442",
    "label": "Keithsburg - IL 61442",
    "county": "Mercer County",
    "state": "IL",
    "city": "Keithsburg"
  },
  {
    "value": "61443",
    "label": "Kewanee - IL 61443",
    "county": "Henry County",
    "state": "IL",
    "city": "Kewanee"
  },
  {
    "value": "61447",
    "label": "Kirkwood - IL 61447",
    "county": "Warren County",
    "state": "IL",
    "city": "Kirkwood"
  },
  {
    "value": "61448",
    "label": "Knoxville - IL 61448",
    "county": "Knox County",
    "state": "IL",
    "city": "Knoxville"
  },
  {
    "value": "61449",
    "label": "La Fayette - IL 61449",
    "county": "Stark County",
    "state": "IL",
    "city": "La Fayette"
  },
  {
    "value": "61450",
    "label": "La Harpe - IL 61450",
    "county": "Hancock County",
    "state": "IL",
    "city": "La Harpe"
  },
  {
    "value": "61451",
    "label": "Laura - IL 61451",
    "county": "Peoria County",
    "state": "IL",
    "city": "Laura"
  },
  {
    "value": "61452",
    "label": "Littleton - IL 61452",
    "county": "Schuyler County",
    "state": "IL",
    "city": "Littleton"
  },
  {
    "value": "61453",
    "label": "Little York - IL 61453",
    "county": "Warren County",
    "state": "IL",
    "city": "Little York"
  },
  {
    "value": "61454",
    "label": "Lomax - IL 61454",
    "county": "Henderson County",
    "state": "IL",
    "city": "Lomax"
  },
  {
    "value": "61455",
    "label": "Macomb - IL 61455",
    "county": "McDonough County",
    "state": "IL",
    "city": "Macomb"
  },
  {
    "value": "61458",
    "label": "Maquon - IL 61458",
    "county": "Knox County",
    "state": "IL",
    "city": "Maquon"
  },
  {
    "value": "61459",
    "label": "Marietta - IL 61459",
    "county": "Fulton County",
    "state": "IL",
    "city": "Marietta"
  },
  {
    "value": "61460",
    "label": "Media - IL 61460",
    "county": "Henderson County",
    "state": "IL",
    "city": "Media"
  },
  {
    "value": "61462",
    "label": "Monmouth - IL 61462",
    "county": "Warren County",
    "state": "IL",
    "city": "Monmouth"
  },
  {
    "value": "61465",
    "label": "New Windsor - IL 61465",
    "county": "Mercer County",
    "state": "IL",
    "city": "New Windsor"
  },
  {
    "value": "61466",
    "label": "North Henderson - IL 61466",
    "county": "Mercer County",
    "state": "IL",
    "city": "North Henderson"
  },
  {
    "value": "61467",
    "label": "Oneida - IL 61467",
    "county": "Knox County",
    "state": "IL",
    "city": "Oneida"
  },
  {
    "value": "61468",
    "label": "Ophiem - IL 61468",
    "county": "Henry County",
    "state": "IL",
    "city": "Ophiem"
  },
  {
    "value": "61469",
    "label": "Oquawka - IL 61469",
    "county": "Henderson County",
    "state": "IL",
    "city": "Oquawka"
  },
  {
    "value": "61470",
    "label": "Prairie City - IL 61470",
    "county": "McDonough County",
    "state": "IL",
    "city": "Prairie City"
  },
  {
    "value": "61472",
    "label": "Rio - IL 61472",
    "county": "Knox County",
    "state": "IL",
    "city": "Rio"
  },
  {
    "value": "61473",
    "label": "Roseville - IL 61473",
    "county": "Warren County",
    "state": "IL",
    "city": "Roseville"
  },
  {
    "value": "61474",
    "label": "Saint Augustine - IL 61474",
    "county": "Knox County",
    "state": "IL",
    "city": "Saint Augustine"
  },
  {
    "value": "61475",
    "label": "Sciota - IL 61475",
    "county": "McDonough County",
    "state": "IL",
    "city": "Sciota"
  },
  {
    "value": "61476",
    "label": "Seaton - IL 61476",
    "county": "Mercer County",
    "state": "IL",
    "city": "Seaton"
  },
  {
    "value": "61477",
    "label": "Smithfield - IL 61477",
    "county": "Fulton County",
    "state": "IL",
    "city": "Smithfield"
  },
  {
    "value": "61478",
    "label": "Smithshire - IL 61478",
    "county": "Warren County",
    "state": "IL",
    "city": "Smithshire"
  },
  {
    "value": "61479",
    "label": "Speer - IL 61479",
    "county": "Stark County",
    "state": "IL",
    "city": "Speer"
  },
  {
    "value": "61480",
    "label": "Stronghurst - IL 61480",
    "county": "Henderson County",
    "state": "IL",
    "city": "Stronghurst"
  },
  {
    "value": "61482",
    "label": "Table Grove - IL 61482",
    "county": "Fulton County",
    "state": "IL",
    "city": "Table Grove"
  },
  {
    "value": "61483",
    "label": "Toulon - IL 61483",
    "county": "Stark County",
    "state": "IL",
    "city": "Toulon"
  },
  {
    "value": "61484",
    "label": "Vermont - IL 61484",
    "county": "Fulton County",
    "state": "IL",
    "city": "Vermont"
  },
  {
    "value": "61485",
    "label": "Victoria - IL 61485",
    "county": "Knox County",
    "state": "IL",
    "city": "Victoria"
  },
  {
    "value": "61486",
    "label": "Viola - IL 61486",
    "county": "Mercer County",
    "state": "IL",
    "city": "Viola"
  },
  {
    "value": "61488",
    "label": "Wataga - IL 61488",
    "county": "Knox County",
    "state": "IL",
    "city": "Wataga"
  },
  {
    "value": "61489",
    "label": "Williamsfield - IL 61489",
    "county": "Knox County",
    "state": "IL",
    "city": "Williamsfield"
  },
  {
    "value": "61490",
    "label": "Woodhull - IL 61490",
    "county": "Henry County",
    "state": "IL",
    "city": "Woodhull"
  },
  {
    "value": "61491",
    "label": "Wyoming - IL 61491",
    "county": "Stark County",
    "state": "IL",
    "city": "Wyoming"
  },
  {
    "value": "61501",
    "label": "Astoria - IL 61501",
    "county": "Fulton County",
    "state": "IL",
    "city": "Astoria"
  },
  {
    "value": "61516",
    "label": "Benson - IL 61516",
    "county": "Woodford County",
    "state": "IL",
    "city": "Benson"
  },
  {
    "value": "61517",
    "label": "Brimfield - IL 61517",
    "county": "Peoria County",
    "state": "IL",
    "city": "Brimfield"
  },
  {
    "value": "61520",
    "label": "Canton - IL 61520",
    "county": "Fulton County",
    "state": "IL",
    "city": "Canton"
  },
  {
    "value": "61523",
    "label": "Chillicothe - IL 61523",
    "county": "Peoria County",
    "state": "IL",
    "city": "Chillicothe"
  },
  {
    "value": "61525",
    "label": "Dunlap - IL 61525",
    "county": "Peoria County",
    "state": "IL",
    "city": "Dunlap"
  },
  {
    "value": "61526",
    "label": "Edelstein - IL 61526",
    "county": "Peoria County",
    "state": "IL",
    "city": "Edelstein"
  },
  {
    "value": "61528",
    "label": "Edwards - IL 61528",
    "county": "Peoria County",
    "state": "IL",
    "city": "Edwards"
  },
  {
    "value": "61529",
    "label": "Elmwood - IL 61529",
    "county": "Peoria County",
    "state": "IL",
    "city": "Elmwood"
  },
  {
    "value": "61530",
    "label": "Eureka - IL 61530",
    "county": "Woodford County",
    "state": "IL",
    "city": "Eureka"
  },
  {
    "value": "61531",
    "label": "Farmington - IL 61531",
    "county": "Fulton County",
    "state": "IL",
    "city": "Farmington"
  },
  {
    "value": "61532",
    "label": "Forest City - IL 61532",
    "county": "Mason County",
    "state": "IL",
    "city": "Forest City"
  },
  {
    "value": "61533",
    "label": "Glasford - IL 61533",
    "county": "Peoria County",
    "state": "IL",
    "city": "Glasford"
  },
  {
    "value": "61534",
    "label": "Green Valley - IL 61534",
    "county": "Tazewell County",
    "state": "IL",
    "city": "Green Valley"
  },
  {
    "value": "61535",
    "label": "Groveland - IL 61535",
    "county": "Tazewell County",
    "state": "IL",
    "city": "Groveland"
  },
  {
    "value": "61536",
    "label": "Hanna City - IL 61536",
    "county": "Peoria County",
    "state": "IL",
    "city": "Hanna City"
  },
  {
    "value": "61537",
    "label": "Henry - IL 61537",
    "county": "Marshall County",
    "state": "IL",
    "city": "Henry"
  },
  {
    "value": "61540",
    "label": "Lacon - IL 61540",
    "county": "Marshall County",
    "state": "IL",
    "city": "Lacon"
  },
  {
    "value": "61542",
    "label": "Lewistown - IL 61542",
    "county": "Fulton County",
    "state": "IL",
    "city": "Lewistown"
  },
  {
    "value": "61544",
    "label": "London Mills - IL 61544",
    "county": "Fulton County",
    "state": "IL",
    "city": "London Mills"
  },
  {
    "value": "61545",
    "label": "Lowpoint - IL 61545",
    "county": "Woodford County",
    "state": "IL",
    "city": "Lowpoint"
  },
  {
    "value": "61546",
    "label": "Manito - IL 61546",
    "county": "Mason County",
    "state": "IL",
    "city": "Manito"
  },
  {
    "value": "61547",
    "label": "Mapleton - IL 61547",
    "county": "Peoria County",
    "state": "IL",
    "city": "Mapleton"
  },
  {
    "value": "61548",
    "label": "Metamora - IL 61548",
    "county": "Woodford County",
    "state": "IL",
    "city": "Metamora"
  },
  {
    "value": "61550",
    "label": "Morton - IL 61550",
    "county": "Tazewell County",
    "state": "IL",
    "city": "Morton"
  },
  {
    "value": "61554",
    "label": "Pekin - IL 61554",
    "county": "Tazewell County",
    "state": "IL",
    "city": "Pekin"
  },
  {
    "value": "61559",
    "label": "Princeville - IL 61559",
    "county": "Peoria County",
    "state": "IL",
    "city": "Princeville"
  },
  {
    "value": "61560",
    "label": "Putnam - IL 61560",
    "county": "Putnam County",
    "state": "IL",
    "city": "Putnam"
  },
  {
    "value": "61561",
    "label": "Roanoke - IL 61561",
    "county": "Woodford County",
    "state": "IL",
    "city": "Roanoke"
  },
  {
    "value": "61565",
    "label": "Sparland - IL 61565",
    "county": "Marshall County",
    "state": "IL",
    "city": "Sparland"
  },
  {
    "value": "61567",
    "label": "Topeka - IL 61567",
    "county": "Mason County",
    "state": "IL",
    "city": "Topeka"
  },
  {
    "value": "61568",
    "label": "Tremont - IL 61568",
    "county": "Tazewell County",
    "state": "IL",
    "city": "Tremont"
  },
  {
    "value": "61569",
    "label": "Trivoli - IL 61569",
    "county": "Peoria County",
    "state": "IL",
    "city": "Trivoli"
  },
  {
    "value": "61570",
    "label": "Washburn - IL 61570",
    "county": "Woodford County",
    "state": "IL",
    "city": "Washburn"
  },
  {
    "value": "61571",
    "label": "Washington - IL 61571",
    "county": "Tazewell County",
    "state": "IL",
    "city": "Washington"
  },
  {
    "value": "61572",
    "label": "Yates City - IL 61572",
    "county": "Knox County",
    "state": "IL",
    "city": "Yates City"
  },
  {
    "value": "61602",
    "label": "Peoria - IL 61602",
    "county": "Peoria County",
    "state": "IL",
    "city": "Peoria"
  },
  {
    "value": "61603",
    "label": "Peoria - IL 61603",
    "county": "Peoria County",
    "state": "IL",
    "city": "Peoria"
  },
  {
    "value": "61604",
    "label": "Peoria - IL 61604",
    "county": "Peoria County",
    "state": "IL",
    "city": "Peoria"
  },
  {
    "value": "61605",
    "label": "Peoria - IL 61605",
    "county": "Peoria County",
    "state": "IL",
    "city": "Peoria"
  },
  {
    "value": "61606",
    "label": "Peoria - IL 61606",
    "county": "Peoria County",
    "state": "IL",
    "city": "Peoria"
  },
  {
    "value": "61607",
    "label": "Peoria - IL 61607",
    "county": "Peoria County",
    "state": "IL",
    "city": "Peoria"
  },
  {
    "value": "61610",
    "label": "Creve Coeur - IL 61610",
    "county": "Tazewell County",
    "state": "IL",
    "city": "Creve Coeur"
  },
  {
    "value": "61611",
    "label": "East Peoria - IL 61611",
    "county": "Tazewell County",
    "state": "IL",
    "city": "East Peoria"
  },
  {
    "value": "61614",
    "label": "Peoria - IL 61614",
    "county": "Peoria County",
    "state": "IL",
    "city": "Peoria"
  },
  {
    "value": "61615",
    "label": "Peoria - IL 61615",
    "county": "Peoria County",
    "state": "IL",
    "city": "Peoria"
  },
  {
    "value": "61616",
    "label": "Peoria Heights - IL 61616",
    "county": "Peoria County",
    "state": "IL",
    "city": "Peoria Heights"
  },
  {
    "value": "61701",
    "label": "Bloomington - IL 61701",
    "county": "McLean County",
    "state": "IL",
    "city": "Bloomington"
  },
  {
    "value": "61704",
    "label": "Bloomington - IL 61704",
    "county": "McLean County",
    "state": "IL",
    "city": "Bloomington"
  },
  {
    "value": "61705",
    "label": "Bloomington - IL 61705",
    "county": "McLean County",
    "state": "IL",
    "city": "Bloomington"
  },
  {
    "value": "61720",
    "label": "Anchor - IL 61720",
    "county": "McLean County",
    "state": "IL",
    "city": "Anchor"
  },
  {
    "value": "61721",
    "label": "Armington - IL 61721",
    "county": "Tazewell County",
    "state": "IL",
    "city": "Armington"
  },
  {
    "value": "61722",
    "label": "Arrowsmith - IL 61722",
    "county": "McLean County",
    "state": "IL",
    "city": "Arrowsmith"
  },
  {
    "value": "61723",
    "label": "Atlanta - IL 61723",
    "county": "Logan County",
    "state": "IL",
    "city": "Atlanta"
  },
  {
    "value": "61724",
    "label": "Bellflower - IL 61724",
    "county": "McLean County",
    "state": "IL",
    "city": "Bellflower"
  },
  {
    "value": "61725",
    "label": "Carlock - IL 61725",
    "county": "McLean County",
    "state": "IL",
    "city": "Carlock"
  },
  {
    "value": "61726",
    "label": "Chenoa - IL 61726",
    "county": "McLean County",
    "state": "IL",
    "city": "Chenoa"
  },
  {
    "value": "61727",
    "label": "Clinton - IL 61727",
    "county": "De Witt County",
    "state": "IL",
    "city": "Clinton"
  },
  {
    "value": "61728",
    "label": "Colfax - IL 61728",
    "county": "McLean County",
    "state": "IL",
    "city": "Colfax"
  },
  {
    "value": "61729",
    "label": "Congerville - IL 61729",
    "county": "Woodford County",
    "state": "IL",
    "city": "Congerville"
  },
  {
    "value": "61730",
    "label": "Cooksville - IL 61730",
    "county": "McLean County",
    "state": "IL",
    "city": "Cooksville"
  },
  {
    "value": "61731",
    "label": "Cropsey - IL 61731",
    "county": "McLean County",
    "state": "IL",
    "city": "Cropsey"
  },
  {
    "value": "61732",
    "label": "Danvers - IL 61732",
    "county": "McLean County",
    "state": "IL",
    "city": "Danvers"
  },
  {
    "value": "61733",
    "label": "Deer Creek - IL 61733",
    "county": "Tazewell County",
    "state": "IL",
    "city": "Deer Creek"
  },
  {
    "value": "61734",
    "label": "Delavan - IL 61734",
    "county": "Tazewell County",
    "state": "IL",
    "city": "Delavan"
  },
  {
    "value": "61735",
    "label": "Dewitt - IL 61735",
    "county": "De Witt County",
    "state": "IL",
    "city": "Dewitt"
  },
  {
    "value": "61736",
    "label": "Downs - IL 61736",
    "county": "McLean County",
    "state": "IL",
    "city": "Downs"
  },
  {
    "value": "61737",
    "label": "Ellsworth - IL 61737",
    "county": "McLean County",
    "state": "IL",
    "city": "Ellsworth"
  },
  {
    "value": "61738",
    "label": "El Paso - IL 61738",
    "county": "Woodford County",
    "state": "IL",
    "city": "El Paso"
  },
  {
    "value": "61739",
    "label": "Fairbury - IL 61739",
    "county": "Livingston County",
    "state": "IL",
    "city": "Fairbury"
  },
  {
    "value": "61740",
    "label": "Flanagan - IL 61740",
    "county": "Livingston County",
    "state": "IL",
    "city": "Flanagan"
  },
  {
    "value": "61741",
    "label": "Forrest - IL 61741",
    "county": "Livingston County",
    "state": "IL",
    "city": "Forrest"
  },
  {
    "value": "61742",
    "label": "Goodfield - IL 61742",
    "county": "Woodford County",
    "state": "IL",
    "city": "Goodfield"
  },
  {
    "value": "61743",
    "label": "Graymont - IL 61743",
    "county": "Livingston County",
    "state": "IL",
    "city": "Graymont"
  },
  {
    "value": "61744",
    "label": "Gridley - IL 61744",
    "county": "McLean County",
    "state": "IL",
    "city": "Gridley"
  },
  {
    "value": "61745",
    "label": "Heyworth - IL 61745",
    "county": "McLean County",
    "state": "IL",
    "city": "Heyworth"
  },
  {
    "value": "61747",
    "label": "Hopedale - IL 61747",
    "county": "Tazewell County",
    "state": "IL",
    "city": "Hopedale"
  },
  {
    "value": "61748",
    "label": "Hudson - IL 61748",
    "county": "McLean County",
    "state": "IL",
    "city": "Hudson"
  },
  {
    "value": "61749",
    "label": "Kenney - IL 61749",
    "county": "De Witt County",
    "state": "IL",
    "city": "Kenney"
  },
  {
    "value": "61752",
    "label": "Le Roy - IL 61752",
    "county": "McLean County",
    "state": "IL",
    "city": "Le Roy"
  },
  {
    "value": "61753",
    "label": "Lexington - IL 61753",
    "county": "McLean County",
    "state": "IL",
    "city": "Lexington"
  },
  {
    "value": "61754",
    "label": "Mc Lean - IL 61754",
    "county": "McLean County",
    "state": "IL",
    "city": "Mc Lean"
  },
  {
    "value": "61755",
    "label": "Mackinaw - IL 61755",
    "county": "Tazewell County",
    "state": "IL",
    "city": "Mackinaw"
  },
  {
    "value": "61756",
    "label": "Maroa - IL 61756",
    "county": "Macon County",
    "state": "IL",
    "city": "Maroa"
  },
  {
    "value": "61759",
    "label": "Minier - IL 61759",
    "county": "Tazewell County",
    "state": "IL",
    "city": "Minier"
  },
  {
    "value": "61760",
    "label": "Minonk - IL 61760",
    "county": "Woodford County",
    "state": "IL",
    "city": "Minonk"
  },
  {
    "value": "61761",
    "label": "Normal - IL 61761",
    "county": "McLean County",
    "state": "IL",
    "city": "Normal"
  },
  {
    "value": "61764",
    "label": "Pontiac - IL 61764",
    "county": "Livingston County",
    "state": "IL",
    "city": "Pontiac"
  },
  {
    "value": "61769",
    "label": "Saunemin - IL 61769",
    "county": "Livingston County",
    "state": "IL",
    "city": "Saunemin"
  },
  {
    "value": "61770",
    "label": "Saybrook - IL 61770",
    "county": "McLean County",
    "state": "IL",
    "city": "Saybrook"
  },
  {
    "value": "61771",
    "label": "Secor - IL 61771",
    "county": "Woodford County",
    "state": "IL",
    "city": "Secor"
  },
  {
    "value": "61772",
    "label": "Shirley - IL 61772",
    "county": "McLean County",
    "state": "IL",
    "city": "Shirley"
  },
  {
    "value": "61773",
    "label": "Sibley - IL 61773",
    "county": "Ford County",
    "state": "IL",
    "city": "Sibley"
  },
  {
    "value": "61774",
    "label": "Stanford - IL 61774",
    "county": "McLean County",
    "state": "IL",
    "city": "Stanford"
  },
  {
    "value": "61775",
    "label": "Strawn - IL 61775",
    "county": "Livingston County",
    "state": "IL",
    "city": "Strawn"
  },
  {
    "value": "61776",
    "label": "Towanda - IL 61776",
    "county": "McLean County",
    "state": "IL",
    "city": "Towanda"
  },
  {
    "value": "61777",
    "label": "Wapella - IL 61777",
    "county": "De Witt County",
    "state": "IL",
    "city": "Wapella"
  },
  {
    "value": "61778",
    "label": "Waynesville - IL 61778",
    "county": "De Witt County",
    "state": "IL",
    "city": "Waynesville"
  },
  {
    "value": "61801",
    "label": "Urbana - IL 61801",
    "county": "Champaign County",
    "state": "IL",
    "city": "Urbana"
  },
  {
    "value": "61802",
    "label": "Urbana - IL 61802",
    "county": "Champaign County",
    "state": "IL",
    "city": "Urbana"
  },
  {
    "value": "61810",
    "label": "Allerton - IL 61810",
    "county": "Vermilion County",
    "state": "IL",
    "city": "Allerton"
  },
  {
    "value": "61811",
    "label": "Alvin - IL 61811",
    "county": "Vermilion County",
    "state": "IL",
    "city": "Alvin"
  },
  {
    "value": "61812",
    "label": "Armstrong - IL 61812",
    "county": "Vermilion County",
    "state": "IL",
    "city": "Armstrong"
  },
  {
    "value": "61813",
    "label": "Bement - IL 61813",
    "county": "Piatt County",
    "state": "IL",
    "city": "Bement"
  },
  {
    "value": "61814",
    "label": "Bismarck - IL 61814",
    "county": "Vermilion County",
    "state": "IL",
    "city": "Bismarck"
  },
  {
    "value": "61816",
    "label": "Broadlands - IL 61816",
    "county": "Champaign County",
    "state": "IL",
    "city": "Broadlands"
  },
  {
    "value": "61817",
    "label": "Catlin - IL 61817",
    "county": "Vermilion County",
    "state": "IL",
    "city": "Catlin"
  },
  {
    "value": "61818",
    "label": "Cerro Gordo - IL 61818",
    "county": "Piatt County",
    "state": "IL",
    "city": "Cerro Gordo"
  },
  {
    "value": "61820",
    "label": "Champaign - IL 61820",
    "county": "Champaign County",
    "state": "IL",
    "city": "Champaign"
  },
  {
    "value": "61821",
    "label": "Champaign - IL 61821",
    "county": "Champaign County",
    "state": "IL",
    "city": "Champaign"
  },
  {
    "value": "61822",
    "label": "Champaign - IL 61822",
    "county": "Champaign County",
    "state": "IL",
    "city": "Champaign"
  },
  {
    "value": "61830",
    "label": "Cisco - IL 61830",
    "county": "Piatt County",
    "state": "IL",
    "city": "Cisco"
  },
  {
    "value": "61831",
    "label": "Collison - IL 61831",
    "county": "Vermilion County",
    "state": "IL",
    "city": "Collison"
  },
  {
    "value": "61832",
    "label": "Danville - IL 61832",
    "county": "Vermilion County",
    "state": "IL",
    "city": "Danville"
  },
  {
    "value": "61833",
    "label": "Tilton - IL 61833",
    "county": "Vermilion County",
    "state": "IL",
    "city": "Tilton"
  },
  {
    "value": "61834",
    "label": "Danville - IL 61834",
    "county": "Vermilion County",
    "state": "IL",
    "city": "Danville"
  },
  {
    "value": "61839",
    "label": "De Land - IL 61839",
    "county": "Piatt County",
    "state": "IL",
    "city": "De Land"
  },
  {
    "value": "61840",
    "label": "Dewey - IL 61840",
    "county": "Champaign County",
    "state": "IL",
    "city": "Dewey"
  },
  {
    "value": "61841",
    "label": "Fairmount - IL 61841",
    "county": "Vermilion County",
    "state": "IL",
    "city": "Fairmount"
  },
  {
    "value": "61842",
    "label": "Farmer City - IL 61842",
    "county": "De Witt County",
    "state": "IL",
    "city": "Farmer City"
  },
  {
    "value": "61843",
    "label": "Fisher - IL 61843",
    "county": "Champaign County",
    "state": "IL",
    "city": "Fisher"
  },
  {
    "value": "61844",
    "label": "Fithian - IL 61844",
    "county": "Vermilion County",
    "state": "IL",
    "city": "Fithian"
  },
  {
    "value": "61845",
    "label": "Foosland - IL 61845",
    "county": "Champaign County",
    "state": "IL",
    "city": "Foosland"
  },
  {
    "value": "61846",
    "label": "Georgetown - IL 61846",
    "county": "Vermilion County",
    "state": "IL",
    "city": "Georgetown"
  },
  {
    "value": "61847",
    "label": "Gifford - IL 61847",
    "county": "Champaign County",
    "state": "IL",
    "city": "Gifford"
  },
  {
    "value": "61849",
    "label": "Homer - IL 61849",
    "county": "Champaign County",
    "state": "IL",
    "city": "Homer"
  },
  {
    "value": "61850",
    "label": "Indianola - IL 61850",
    "county": "Vermilion County",
    "state": "IL",
    "city": "Indianola"
  },
  {
    "value": "61851",
    "label": "Ivesdale - IL 61851",
    "county": "Champaign County",
    "state": "IL",
    "city": "Ivesdale"
  },
  {
    "value": "61852",
    "label": "Longview - IL 61852",
    "county": "Champaign County",
    "state": "IL",
    "city": "Longview"
  },
  {
    "value": "61853",
    "label": "Mahomet - IL 61853",
    "county": "Champaign County",
    "state": "IL",
    "city": "Mahomet"
  },
  {
    "value": "61854",
    "label": "Mansfield - IL 61854",
    "county": "Piatt County",
    "state": "IL",
    "city": "Mansfield"
  },
  {
    "value": "61855",
    "label": "Milmine - IL 61855",
    "county": "Piatt County",
    "state": "IL",
    "city": "Milmine"
  },
  {
    "value": "61856",
    "label": "Monticello - IL 61856",
    "county": "Piatt County",
    "state": "IL",
    "city": "Monticello"
  },
  {
    "value": "61858",
    "label": "Oakwood - IL 61858",
    "county": "Vermilion County",
    "state": "IL",
    "city": "Oakwood"
  },
  {
    "value": "61859",
    "label": "Ogden - IL 61859",
    "county": "Champaign County",
    "state": "IL",
    "city": "Ogden"
  },
  {
    "value": "61862",
    "label": "Penfield - IL 61862",
    "county": "Champaign County",
    "state": "IL",
    "city": "Penfield"
  },
  {
    "value": "61863",
    "label": "Pesotum - IL 61863",
    "county": "Champaign County",
    "state": "IL",
    "city": "Pesotum"
  },
  {
    "value": "61864",
    "label": "Philo - IL 61864",
    "county": "Champaign County",
    "state": "IL",
    "city": "Philo"
  },
  {
    "value": "61865",
    "label": "Potomac - IL 61865",
    "county": "Vermilion County",
    "state": "IL",
    "city": "Potomac"
  },
  {
    "value": "61866",
    "label": "Rantoul - IL 61866",
    "county": "Champaign County",
    "state": "IL",
    "city": "Rantoul"
  },
  {
    "value": "61870",
    "label": "Ridge Farm - IL 61870",
    "county": "Vermilion County",
    "state": "IL",
    "city": "Ridge Farm"
  },
  {
    "value": "61872",
    "label": "Sadorus - IL 61872",
    "county": "Champaign County",
    "state": "IL",
    "city": "Sadorus"
  },
  {
    "value": "61873",
    "label": "Saint Joseph - IL 61873",
    "county": "Champaign County",
    "state": "IL",
    "city": "Saint Joseph"
  },
  {
    "value": "61874",
    "label": "Savoy - IL 61874",
    "county": "Champaign County",
    "state": "IL",
    "city": "Savoy"
  },
  {
    "value": "61875",
    "label": "Seymour - IL 61875",
    "county": "Champaign County",
    "state": "IL",
    "city": "Seymour"
  },
  {
    "value": "61876",
    "label": "Sidell - IL 61876",
    "county": "Vermilion County",
    "state": "IL",
    "city": "Sidell"
  },
  {
    "value": "61877",
    "label": "Sidney - IL 61877",
    "county": "Champaign County",
    "state": "IL",
    "city": "Sidney"
  },
  {
    "value": "61878",
    "label": "Thomasboro - IL 61878",
    "county": "Champaign County",
    "state": "IL",
    "city": "Thomasboro"
  },
  {
    "value": "61880",
    "label": "Tolono - IL 61880",
    "county": "Champaign County",
    "state": "IL",
    "city": "Tolono"
  },
  {
    "value": "61882",
    "label": "Weldon - IL 61882",
    "county": "De Witt County",
    "state": "IL",
    "city": "Weldon"
  },
  {
    "value": "61883",
    "label": "Westville - IL 61883",
    "county": "Vermilion County",
    "state": "IL",
    "city": "Westville"
  },
  {
    "value": "61884",
    "label": "White Heath - IL 61884",
    "county": "Piatt County",
    "state": "IL",
    "city": "White Heath"
  },
  {
    "value": "61910",
    "label": "Arcola - IL 61910",
    "county": "Douglas County",
    "state": "IL",
    "city": "Arcola"
  },
  {
    "value": "61911",
    "label": "Arthur - IL 61911",
    "county": "Douglas County",
    "state": "IL",
    "city": "Arthur"
  },
  {
    "value": "61912",
    "label": "Ashmore - IL 61912",
    "county": "Coles County",
    "state": "IL",
    "city": "Ashmore"
  },
  {
    "value": "61913",
    "label": "Atwood - IL 61913",
    "county": "Douglas County",
    "state": "IL",
    "city": "Atwood"
  },
  {
    "value": "61914",
    "label": "Bethany - IL 61914",
    "county": "Moultrie County",
    "state": "IL",
    "city": "Bethany"
  },
  {
    "value": "61917",
    "label": "Brocton - IL 61917",
    "county": "Edgar County",
    "state": "IL",
    "city": "Brocton"
  },
  {
    "value": "61919",
    "label": "Camargo - IL 61919",
    "county": "Douglas County",
    "state": "IL",
    "city": "Camargo"
  },
  {
    "value": "61920",
    "label": "Charleston - IL 61920",
    "county": "Coles County",
    "state": "IL",
    "city": "Charleston"
  },
  {
    "value": "61924",
    "label": "Chrisman - IL 61924",
    "county": "Edgar County",
    "state": "IL",
    "city": "Chrisman"
  },
  {
    "value": "61925",
    "label": "Dalton City - IL 61925",
    "county": "Moultrie County",
    "state": "IL",
    "city": "Dalton City"
  },
  {
    "value": "61928",
    "label": "Gays - IL 61928",
    "county": "Moultrie County",
    "state": "IL",
    "city": "Gays"
  },
  {
    "value": "61929",
    "label": "Hammond - IL 61929",
    "county": "Piatt County",
    "state": "IL",
    "city": "Hammond"
  },
  {
    "value": "61930",
    "label": "Hindsboro - IL 61930",
    "county": "Douglas County",
    "state": "IL",
    "city": "Hindsboro"
  },
  {
    "value": "61931",
    "label": "Humboldt - IL 61931",
    "county": "Coles County",
    "state": "IL",
    "city": "Humboldt"
  },
  {
    "value": "61932",
    "label": "Hume - IL 61932",
    "county": "Edgar County",
    "state": "IL",
    "city": "Hume"
  },
  {
    "value": "61933",
    "label": "Kansas - IL 61933",
    "county": "Edgar County",
    "state": "IL",
    "city": "Kansas"
  },
  {
    "value": "61937",
    "label": "Lovington - IL 61937",
    "county": "Moultrie County",
    "state": "IL",
    "city": "Lovington"
  },
  {
    "value": "61938",
    "label": "Mattoon - IL 61938",
    "county": "Coles County",
    "state": "IL",
    "city": "Mattoon"
  },
  {
    "value": "61940",
    "label": "Metcalf - IL 61940",
    "county": "Edgar County",
    "state": "IL",
    "city": "Metcalf"
  },
  {
    "value": "61942",
    "label": "Newman - IL 61942",
    "county": "Douglas County",
    "state": "IL",
    "city": "Newman"
  },
  {
    "value": "61943",
    "label": "Oakland - IL 61943",
    "county": "Coles County",
    "state": "IL",
    "city": "Oakland"
  },
  {
    "value": "61944",
    "label": "Paris - IL 61944",
    "county": "Edgar County",
    "state": "IL",
    "city": "Paris"
  },
  {
    "value": "61951",
    "label": "Sullivan - IL 61951",
    "county": "Moultrie County",
    "state": "IL",
    "city": "Sullivan"
  },
  {
    "value": "61953",
    "label": "Tuscola - IL 61953",
    "county": "Douglas County",
    "state": "IL",
    "city": "Tuscola"
  },
  {
    "value": "61956",
    "label": "Villa Grove - IL 61956",
    "county": "Douglas County",
    "state": "IL",
    "city": "Villa Grove"
  },
  {
    "value": "61957",
    "label": "Windsor - IL 61957",
    "county": "Shelby County",
    "state": "IL",
    "city": "Windsor"
  },
  {
    "value": "62001",
    "label": "Alhambra - IL 62001",
    "county": "Madison County",
    "state": "IL",
    "city": "Alhambra"
  },
  {
    "value": "62002",
    "label": "Alton - IL 62002",
    "county": "Madison County",
    "state": "IL",
    "city": "Alton"
  },
  {
    "value": "62006",
    "label": "Batchtown - IL 62006",
    "county": "Calhoun County",
    "state": "IL",
    "city": "Batchtown"
  },
  {
    "value": "62009",
    "label": "Benld - IL 62009",
    "county": "Macoupin County",
    "state": "IL",
    "city": "Benld"
  },
  {
    "value": "62010",
    "label": "Bethalto - IL 62010",
    "county": "Madison County",
    "state": "IL",
    "city": "Bethalto"
  },
  {
    "value": "62011",
    "label": "Bingham - IL 62011",
    "county": "Fayette County",
    "state": "IL",
    "city": "Bingham"
  },
  {
    "value": "62012",
    "label": "Brighton - IL 62012",
    "county": "Macoupin County",
    "state": "IL",
    "city": "Brighton"
  },
  {
    "value": "62013",
    "label": "Brussels - IL 62013",
    "county": "Calhoun County",
    "state": "IL",
    "city": "Brussels"
  },
  {
    "value": "62014",
    "label": "Bunker Hill - IL 62014",
    "county": "Macoupin County",
    "state": "IL",
    "city": "Bunker Hill"
  },
  {
    "value": "62015",
    "label": "Butler - IL 62015",
    "county": "Montgomery County",
    "state": "IL",
    "city": "Butler"
  },
  {
    "value": "62016",
    "label": "Carrollton - IL 62016",
    "county": "Greene County",
    "state": "IL",
    "city": "Carrollton"
  },
  {
    "value": "62017",
    "label": "Coffeen - IL 62017",
    "county": "Montgomery County",
    "state": "IL",
    "city": "Coffeen"
  },
  {
    "value": "62018",
    "label": "Cottage Hills - IL 62018",
    "county": "Madison County",
    "state": "IL",
    "city": "Cottage Hills"
  },
  {
    "value": "62019",
    "label": "Donnellson - IL 62019",
    "county": "Montgomery County",
    "state": "IL",
    "city": "Donnellson"
  },
  {
    "value": "62021",
    "label": "Dorsey - IL 62021",
    "county": "Madison County",
    "state": "IL",
    "city": "Dorsey"
  },
  {
    "value": "62022",
    "label": "Dow - IL 62022",
    "county": "Jersey County",
    "state": "IL",
    "city": "Dow"
  },
  {
    "value": "62024",
    "label": "East Alton - IL 62024",
    "county": "Madison County",
    "state": "IL",
    "city": "East Alton"
  },
  {
    "value": "62025",
    "label": "Edwardsville - IL 62025",
    "county": "Madison County",
    "state": "IL",
    "city": "Edwardsville"
  },
  {
    "value": "62027",
    "label": "Eldred - IL 62027",
    "county": "Greene County",
    "state": "IL",
    "city": "Eldred"
  },
  {
    "value": "62028",
    "label": "Elsah - IL 62028",
    "county": "Jersey County",
    "state": "IL",
    "city": "Elsah"
  },
  {
    "value": "62031",
    "label": "Fieldon - IL 62031",
    "county": "Jersey County",
    "state": "IL",
    "city": "Fieldon"
  },
  {
    "value": "62032",
    "label": "Fillmore - IL 62032",
    "county": "Montgomery County",
    "state": "IL",
    "city": "Fillmore"
  },
  {
    "value": "62033",
    "label": "Gillespie - IL 62033",
    "county": "Macoupin County",
    "state": "IL",
    "city": "Gillespie"
  },
  {
    "value": "62034",
    "label": "Glen Carbon - IL 62034",
    "county": "Madison County",
    "state": "IL",
    "city": "Glen Carbon"
  },
  {
    "value": "62035",
    "label": "Godfrey - IL 62035",
    "county": "Madison County",
    "state": "IL",
    "city": "Godfrey"
  },
  {
    "value": "62036",
    "label": "Golden Eagle - IL 62036",
    "county": "Calhoun County",
    "state": "IL",
    "city": "Golden Eagle"
  },
  {
    "value": "62037",
    "label": "Grafton - IL 62037",
    "county": "Jersey County",
    "state": "IL",
    "city": "Grafton"
  },
  {
    "value": "62040",
    "label": "Granite City - IL 62040",
    "county": "Madison County",
    "state": "IL",
    "city": "Granite City"
  },
  {
    "value": "62044",
    "label": "Greenfield - IL 62044",
    "county": "Greene County",
    "state": "IL",
    "city": "Greenfield"
  },
  {
    "value": "62045",
    "label": "Hamburg - IL 62045",
    "county": "Calhoun County",
    "state": "IL",
    "city": "Hamburg"
  },
  {
    "value": "62047",
    "label": "Hardin - IL 62047",
    "county": "Calhoun County",
    "state": "IL",
    "city": "Hardin"
  },
  {
    "value": "62048",
    "label": "Hartford - IL 62048",
    "county": "Madison County",
    "state": "IL",
    "city": "Hartford"
  },
  {
    "value": "62049",
    "label": "Hillsboro - IL 62049",
    "county": "Montgomery County",
    "state": "IL",
    "city": "Hillsboro"
  },
  {
    "value": "62050",
    "label": "Hillview - IL 62050",
    "county": "Greene County",
    "state": "IL",
    "city": "Hillview"
  },
  {
    "value": "62051",
    "label": "Irving - IL 62051",
    "county": "Montgomery County",
    "state": "IL",
    "city": "Irving"
  },
  {
    "value": "62052",
    "label": "Jerseyville - IL 62052",
    "county": "Jersey County",
    "state": "IL",
    "city": "Jerseyville"
  },
  {
    "value": "62053",
    "label": "Kampsville - IL 62053",
    "county": "Calhoun County",
    "state": "IL",
    "city": "Kampsville"
  },
  {
    "value": "62054",
    "label": "Kane - IL 62054",
    "county": "Greene County",
    "state": "IL",
    "city": "Kane"
  },
  {
    "value": "62056",
    "label": "Litchfield - IL 62056",
    "county": "Montgomery County",
    "state": "IL",
    "city": "Litchfield"
  },
  {
    "value": "62060",
    "label": "Madison - IL 62060",
    "county": "Madison County",
    "state": "IL",
    "city": "Madison"
  },
  {
    "value": "62061",
    "label": "Marine - IL 62061",
    "county": "Madison County",
    "state": "IL",
    "city": "Marine"
  },
  {
    "value": "62062",
    "label": "Maryville - IL 62062",
    "county": "Madison County",
    "state": "IL",
    "city": "Maryville"
  },
  {
    "value": "62063",
    "label": "Medora - IL 62063",
    "county": "Macoupin County",
    "state": "IL",
    "city": "Medora"
  },
  {
    "value": "62065",
    "label": "Michael - IL 62065",
    "county": "Calhoun County",
    "state": "IL",
    "city": "Michael"
  },
  {
    "value": "62067",
    "label": "Moro - IL 62067",
    "county": "Madison County",
    "state": "IL",
    "city": "Moro"
  },
  {
    "value": "62069",
    "label": "Mount Olive - IL 62069",
    "county": "Macoupin County",
    "state": "IL",
    "city": "Mount Olive"
  },
  {
    "value": "62070",
    "label": "Mozier - IL 62070",
    "county": "Calhoun County",
    "state": "IL",
    "city": "Mozier"
  },
  {
    "value": "62074",
    "label": "New Douglas - IL 62074",
    "county": "Madison County",
    "state": "IL",
    "city": "New Douglas"
  },
  {
    "value": "62075",
    "label": "Nokomis - IL 62075",
    "county": "Montgomery County",
    "state": "IL",
    "city": "Nokomis"
  },
  {
    "value": "62079",
    "label": "Piasa - IL 62079",
    "county": "Macoupin County",
    "state": "IL",
    "city": "Piasa"
  },
  {
    "value": "62080",
    "label": "Ramsey - IL 62080",
    "county": "Fayette County",
    "state": "IL",
    "city": "Ramsey"
  },
  {
    "value": "62081",
    "label": "Rockbridge - IL 62081",
    "county": "Greene County",
    "state": "IL",
    "city": "Rockbridge"
  },
  {
    "value": "62082",
    "label": "Roodhouse - IL 62082",
    "county": "Greene County",
    "state": "IL",
    "city": "Roodhouse"
  },
  {
    "value": "62083",
    "label": "Rosamond - IL 62083",
    "county": "Christian County",
    "state": "IL",
    "city": "Rosamond"
  },
  {
    "value": "62084",
    "label": "Roxana - IL 62084",
    "county": "Madison County",
    "state": "IL",
    "city": "Roxana"
  },
  {
    "value": "62086",
    "label": "Sorento - IL 62086",
    "county": "Bond County",
    "state": "IL",
    "city": "Sorento"
  },
  {
    "value": "62087",
    "label": "South Roxana - IL 62087",
    "county": "Madison County",
    "state": "IL",
    "city": "South Roxana"
  },
  {
    "value": "62088",
    "label": "Staunton - IL 62088",
    "county": "Macoupin County",
    "state": "IL",
    "city": "Staunton"
  },
  {
    "value": "62090",
    "label": "Venice - IL 62090",
    "county": "Madison County",
    "state": "IL",
    "city": "Venice"
  },
  {
    "value": "62091",
    "label": "Walshville - IL 62091",
    "county": "Montgomery County",
    "state": "IL",
    "city": "Walshville"
  },
  {
    "value": "62092",
    "label": "White Hall - IL 62092",
    "county": "Greene County",
    "state": "IL",
    "city": "White Hall"
  },
  {
    "value": "62094",
    "label": "Witt - IL 62094",
    "county": "Montgomery County",
    "state": "IL",
    "city": "Witt"
  },
  {
    "value": "62095",
    "label": "Wood River - IL 62095",
    "county": "Madison County",
    "state": "IL",
    "city": "Wood River"
  },
  {
    "value": "62097",
    "label": "Worden - IL 62097",
    "county": "Madison County",
    "state": "IL",
    "city": "Worden"
  },
  {
    "value": "62201",
    "label": "East Saint Louis - IL 62201",
    "county": "St. Clair County",
    "state": "IL",
    "city": "East Saint Louis"
  },
  {
    "value": "62203",
    "label": "East Saint Louis - IL 62203",
    "county": "St. Clair County",
    "state": "IL",
    "city": "East Saint Louis"
  },
  {
    "value": "62204",
    "label": "East Saint Louis - IL 62204",
    "county": "St. Clair County",
    "state": "IL",
    "city": "East Saint Louis"
  },
  {
    "value": "62205",
    "label": "East Saint Louis - IL 62205",
    "county": "St. Clair County",
    "state": "IL",
    "city": "East Saint Louis"
  },
  {
    "value": "62206",
    "label": "East Saint Louis - IL 62206",
    "county": "St. Clair County",
    "state": "IL",
    "city": "East Saint Louis"
  },
  {
    "value": "62207",
    "label": "East Saint Louis - IL 62207",
    "county": "St. Clair County",
    "state": "IL",
    "city": "East Saint Louis"
  },
  {
    "value": "62208",
    "label": "Fairview Heights - IL 62208",
    "county": "St. Clair County",
    "state": "IL",
    "city": "Fairview Heights"
  },
  {
    "value": "62214",
    "label": "Addieville - IL 62214",
    "county": "Washington County",
    "state": "IL",
    "city": "Addieville"
  },
  {
    "value": "62215",
    "label": "Albers - IL 62215",
    "county": "Clinton County",
    "state": "IL",
    "city": "Albers"
  },
  {
    "value": "62216",
    "label": "Aviston - IL 62216",
    "county": "Clinton County",
    "state": "IL",
    "city": "Aviston"
  },
  {
    "value": "62217",
    "label": "Baldwin - IL 62217",
    "county": "Randolph County",
    "state": "IL",
    "city": "Baldwin"
  },
  {
    "value": "62218",
    "label": "Bartelso - IL 62218",
    "county": "Clinton County",
    "state": "IL",
    "city": "Bartelso"
  },
  {
    "value": "62220",
    "label": "Belleville - IL 62220",
    "county": "St. Clair County",
    "state": "IL",
    "city": "Belleville"
  },
  {
    "value": "62221",
    "label": "Belleville - IL 62221",
    "county": "St. Clair County",
    "state": "IL",
    "city": "Belleville"
  },
  {
    "value": "62223",
    "label": "Belleville - IL 62223",
    "county": "St. Clair County",
    "state": "IL",
    "city": "Belleville"
  },
  {
    "value": "62225",
    "label": "Scott Air Force Base - IL 62225",
    "county": "St. Clair County",
    "state": "IL",
    "city": "Scott Air Force Base"
  },
  {
    "value": "62226",
    "label": "Belleville - IL 62226",
    "county": "St. Clair County",
    "state": "IL",
    "city": "Belleville"
  },
  {
    "value": "62230",
    "label": "Breese - IL 62230",
    "county": "Clinton County",
    "state": "IL",
    "city": "Breese"
  },
  {
    "value": "62231",
    "label": "Carlyle - IL 62231",
    "county": "Clinton County",
    "state": "IL",
    "city": "Carlyle"
  },
  {
    "value": "62232",
    "label": "Caseyville - IL 62232",
    "county": "St. Clair County",
    "state": "IL",
    "city": "Caseyville"
  },
  {
    "value": "62233",
    "label": "Chester - IL 62233",
    "county": "Randolph County",
    "state": "IL",
    "city": "Chester"
  },
  {
    "value": "62234",
    "label": "Collinsville - IL 62234",
    "county": "Madison County",
    "state": "IL",
    "city": "Collinsville"
  },
  {
    "value": "62236",
    "label": "Columbia - IL 62236",
    "county": "Monroe County",
    "state": "IL",
    "city": "Columbia"
  },
  {
    "value": "62237",
    "label": "Coulterville - IL 62237",
    "county": "Randolph County",
    "state": "IL",
    "city": "Coulterville"
  },
  {
    "value": "62238",
    "label": "Cutler - IL 62238",
    "county": "Perry County",
    "state": "IL",
    "city": "Cutler"
  },
  {
    "value": "62239",
    "label": "Dupo - IL 62239",
    "county": "St. Clair County",
    "state": "IL",
    "city": "Dupo"
  },
  {
    "value": "62240",
    "label": "East Carondelet - IL 62240",
    "county": "St. Clair County",
    "state": "IL",
    "city": "East Carondelet"
  },
  {
    "value": "62241",
    "label": "Ellis Grove - IL 62241",
    "county": "Randolph County",
    "state": "IL",
    "city": "Ellis Grove"
  },
  {
    "value": "62242",
    "label": "Evansville - IL 62242",
    "county": "Randolph County",
    "state": "IL",
    "city": "Evansville"
  },
  {
    "value": "62243",
    "label": "Freeburg - IL 62243",
    "county": "St. Clair County",
    "state": "IL",
    "city": "Freeburg"
  },
  {
    "value": "62244",
    "label": "Fults - IL 62244",
    "county": "Monroe County",
    "state": "IL",
    "city": "Fults"
  },
  {
    "value": "62245",
    "label": "Germantown - IL 62245",
    "county": "Clinton County",
    "state": "IL",
    "city": "Germantown"
  },
  {
    "value": "62246",
    "label": "Greenville - IL 62246",
    "county": "Bond County",
    "state": "IL",
    "city": "Greenville"
  },
  {
    "value": "62249",
    "label": "Highland - IL 62249",
    "county": "Madison County",
    "state": "IL",
    "city": "Highland"
  },
  {
    "value": "62250",
    "label": "Hoffman - IL 62250",
    "county": "Clinton County",
    "state": "IL",
    "city": "Hoffman"
  },
  {
    "value": "62253",
    "label": "Keyesport - IL 62253",
    "county": "Bond County",
    "state": "IL",
    "city": "Keyesport"
  },
  {
    "value": "62254",
    "label": "Lebanon - IL 62254",
    "county": "St. Clair County",
    "state": "IL",
    "city": "Lebanon"
  },
  {
    "value": "62255",
    "label": "Lenzburg - IL 62255",
    "county": "St. Clair County",
    "state": "IL",
    "city": "Lenzburg"
  },
  {
    "value": "62257",
    "label": "Marissa - IL 62257",
    "county": "St. Clair County",
    "state": "IL",
    "city": "Marissa"
  },
  {
    "value": "62258",
    "label": "Mascoutah - IL 62258",
    "county": "St. Clair County",
    "state": "IL",
    "city": "Mascoutah"
  },
  {
    "value": "62260",
    "label": "Millstadt - IL 62260",
    "county": "St. Clair County",
    "state": "IL",
    "city": "Millstadt"
  },
  {
    "value": "62261",
    "label": "Modoc - IL 62261",
    "county": "Randolph County",
    "state": "IL",
    "city": "Modoc"
  },
  {
    "value": "62262",
    "label": "Mulberry Grove - IL 62262",
    "county": "Bond County",
    "state": "IL",
    "city": "Mulberry Grove"
  },
  {
    "value": "62263",
    "label": "Nashville - IL 62263",
    "county": "Washington County",
    "state": "IL",
    "city": "Nashville"
  },
  {
    "value": "62264",
    "label": "New Athens - IL 62264",
    "county": "St. Clair County",
    "state": "IL",
    "city": "New Athens"
  },
  {
    "value": "62265",
    "label": "New Baden - IL 62265",
    "county": "Clinton County",
    "state": "IL",
    "city": "New Baden"
  },
  {
    "value": "62268",
    "label": "Oakdale - IL 62268",
    "county": "Washington County",
    "state": "IL",
    "city": "Oakdale"
  },
  {
    "value": "62269",
    "label": "O Fallon - IL 62269",
    "county": "St. Clair County",
    "state": "IL",
    "city": "O Fallon"
  },
  {
    "value": "62271",
    "label": "Okawville - IL 62271",
    "county": "Washington County",
    "state": "IL",
    "city": "Okawville"
  },
  {
    "value": "62272",
    "label": "Percy - IL 62272",
    "county": "Randolph County",
    "state": "IL",
    "city": "Percy"
  },
  {
    "value": "62274",
    "label": "Pinckneyville - IL 62274",
    "county": "Perry County",
    "state": "IL",
    "city": "Pinckneyville"
  },
  {
    "value": "62275",
    "label": "Pocahontas - IL 62275",
    "county": "Bond County",
    "state": "IL",
    "city": "Pocahontas"
  },
  {
    "value": "62277",
    "label": "Prairie Du Rocher - IL 62277",
    "county": "Randolph County",
    "state": "IL",
    "city": "Prairie Du Rocher"
  },
  {
    "value": "62278",
    "label": "Red Bud - IL 62278",
    "county": "Randolph County",
    "state": "IL",
    "city": "Red Bud"
  },
  {
    "value": "62280",
    "label": "Rockwood - IL 62280",
    "county": "Randolph County",
    "state": "IL",
    "city": "Rockwood"
  },
  {
    "value": "62281",
    "label": "Saint Jacob - IL 62281",
    "county": "Madison County",
    "state": "IL",
    "city": "Saint Jacob"
  },
  {
    "value": "62284",
    "label": "Smithboro - IL 62284",
    "county": "Bond County",
    "state": "IL",
    "city": "Smithboro"
  },
  {
    "value": "62285",
    "label": "Smithton - IL 62285",
    "county": "St. Clair County",
    "state": "IL",
    "city": "Smithton"
  },
  {
    "value": "62286",
    "label": "Sparta - IL 62286",
    "county": "Randolph County",
    "state": "IL",
    "city": "Sparta"
  },
  {
    "value": "62288",
    "label": "Steeleville - IL 62288",
    "county": "Randolph County",
    "state": "IL",
    "city": "Steeleville"
  },
  {
    "value": "62293",
    "label": "Trenton - IL 62293",
    "county": "Clinton County",
    "state": "IL",
    "city": "Trenton"
  },
  {
    "value": "62294",
    "label": "Troy - IL 62294",
    "county": "Madison County",
    "state": "IL",
    "city": "Troy"
  },
  {
    "value": "62295",
    "label": "Valmeyer - IL 62295",
    "county": "Monroe County",
    "state": "IL",
    "city": "Valmeyer"
  },
  {
    "value": "62297",
    "label": "Walsh - IL 62297",
    "county": "Randolph County",
    "state": "IL",
    "city": "Walsh"
  },
  {
    "value": "62298",
    "label": "Waterloo - IL 62298",
    "county": "Monroe County",
    "state": "IL",
    "city": "Waterloo"
  },
  {
    "value": "62301",
    "label": "Quincy - IL 62301",
    "county": "Adams County",
    "state": "IL",
    "city": "Quincy"
  },
  {
    "value": "62305",
    "label": "Quincy - IL 62305",
    "county": "Adams County",
    "state": "IL",
    "city": "Quincy"
  },
  {
    "value": "62311",
    "label": "Augusta - IL 62311",
    "county": "Hancock County",
    "state": "IL",
    "city": "Augusta"
  },
  {
    "value": "62312",
    "label": "Barry - IL 62312",
    "county": "Pike County",
    "state": "IL",
    "city": "Barry"
  },
  {
    "value": "62313",
    "label": "Basco - IL 62313",
    "county": "Hancock County",
    "state": "IL",
    "city": "Basco"
  },
  {
    "value": "62314",
    "label": "Baylis - IL 62314",
    "county": "Pike County",
    "state": "IL",
    "city": "Baylis"
  },
  {
    "value": "62316",
    "label": "Bowen - IL 62316",
    "county": "Hancock County",
    "state": "IL",
    "city": "Bowen"
  },
  {
    "value": "62319",
    "label": "Camden - IL 62319",
    "county": "Schuyler County",
    "state": "IL",
    "city": "Camden"
  },
  {
    "value": "62320",
    "label": "Camp Point - IL 62320",
    "county": "Adams County",
    "state": "IL",
    "city": "Camp Point"
  },
  {
    "value": "62321",
    "label": "Carthage - IL 62321",
    "county": "Hancock County",
    "state": "IL",
    "city": "Carthage"
  },
  {
    "value": "62323",
    "label": "Chambersburg - IL 62323",
    "county": "Pike County",
    "state": "IL",
    "city": "Chambersburg"
  },
  {
    "value": "62324",
    "label": "Clayton - IL 62324",
    "county": "Adams County",
    "state": "IL",
    "city": "Clayton"
  },
  {
    "value": "62325",
    "label": "Coatsburg - IL 62325",
    "county": "Adams County",
    "state": "IL",
    "city": "Coatsburg"
  },
  {
    "value": "62326",
    "label": "Colchester - IL 62326",
    "county": "McDonough County",
    "state": "IL",
    "city": "Colchester"
  },
  {
    "value": "62330",
    "label": "Dallas City - IL 62330",
    "county": "Hancock County",
    "state": "IL",
    "city": "Dallas City"
  },
  {
    "value": "62338",
    "label": "Fowler - IL 62338",
    "county": "Adams County",
    "state": "IL",
    "city": "Fowler"
  },
  {
    "value": "62339",
    "label": "Golden - IL 62339",
    "county": "Adams County",
    "state": "IL",
    "city": "Golden"
  },
  {
    "value": "62340",
    "label": "Griggsville - IL 62340",
    "county": "Pike County",
    "state": "IL",
    "city": "Griggsville"
  },
  {
    "value": "62341",
    "label": "Hamilton - IL 62341",
    "county": "Hancock County",
    "state": "IL",
    "city": "Hamilton"
  },
  {
    "value": "62343",
    "label": "Hull - IL 62343",
    "county": "Pike County",
    "state": "IL",
    "city": "Hull"
  },
  {
    "value": "62344",
    "label": "Huntsville - IL 62344",
    "county": "Schuyler County",
    "state": "IL",
    "city": "Huntsville"
  },
  {
    "value": "62345",
    "label": "Kinderhook - IL 62345",
    "county": "Pike County",
    "state": "IL",
    "city": "Kinderhook"
  },
  {
    "value": "62346",
    "label": "La Prairie - IL 62346",
    "county": "Adams County",
    "state": "IL",
    "city": "La Prairie"
  },
  {
    "value": "62347",
    "label": "Liberty - IL 62347",
    "county": "Adams County",
    "state": "IL",
    "city": "Liberty"
  },
  {
    "value": "62349",
    "label": "Loraine - IL 62349",
    "county": "Adams County",
    "state": "IL",
    "city": "Loraine"
  },
  {
    "value": "62351",
    "label": "Mendon - IL 62351",
    "county": "Adams County",
    "state": "IL",
    "city": "Mendon"
  },
  {
    "value": "62353",
    "label": "Mount Sterling - IL 62353",
    "county": "Brown County",
    "state": "IL",
    "city": "Mount Sterling"
  },
  {
    "value": "62354",
    "label": "Nauvoo - IL 62354",
    "county": "Hancock County",
    "state": "IL",
    "city": "Nauvoo"
  },
  {
    "value": "62355",
    "label": "Nebo - IL 62355",
    "county": "Pike County",
    "state": "IL",
    "city": "Nebo"
  },
  {
    "value": "62356",
    "label": "New Canton - IL 62356",
    "county": "Pike County",
    "state": "IL",
    "city": "New Canton"
  },
  {
    "value": "62357",
    "label": "New Salem - IL 62357",
    "county": "Pike County",
    "state": "IL",
    "city": "New Salem"
  },
  {
    "value": "62358",
    "label": "Niota - IL 62358",
    "county": "Hancock County",
    "state": "IL",
    "city": "Niota"
  },
  {
    "value": "62359",
    "label": "Paloma - IL 62359",
    "county": "Adams County",
    "state": "IL",
    "city": "Paloma"
  },
  {
    "value": "62360",
    "label": "Payson - IL 62360",
    "county": "Adams County",
    "state": "IL",
    "city": "Payson"
  },
  {
    "value": "62361",
    "label": "Pearl - IL 62361",
    "county": "Pike County",
    "state": "IL",
    "city": "Pearl"
  },
  {
    "value": "62363",
    "label": "Pittsfield - IL 62363",
    "county": "Pike County",
    "state": "IL",
    "city": "Pittsfield"
  },
  {
    "value": "62365",
    "label": "Plainville - IL 62365",
    "county": "Adams County",
    "state": "IL",
    "city": "Plainville"
  },
  {
    "value": "62366",
    "label": "Pleasant Hill - IL 62366",
    "county": "Pike County",
    "state": "IL",
    "city": "Pleasant Hill"
  },
  {
    "value": "62367",
    "label": "Plymouth - IL 62367",
    "county": "Hancock County",
    "state": "IL",
    "city": "Plymouth"
  },
  {
    "value": "62370",
    "label": "Rockport - IL 62370",
    "county": "Pike County",
    "state": "IL",
    "city": "Rockport"
  },
  {
    "value": "62373",
    "label": "Sutter - IL 62373",
    "county": "Hancock County",
    "state": "IL",
    "city": "Sutter"
  },
  {
    "value": "62374",
    "label": "Tennessee - IL 62374",
    "county": "McDonough County",
    "state": "IL",
    "city": "Tennessee"
  },
  {
    "value": "62375",
    "label": "Timewell - IL 62375",
    "county": "Brown County",
    "state": "IL",
    "city": "Timewell"
  },
  {
    "value": "62376",
    "label": "Ursa - IL 62376",
    "county": "Adams County",
    "state": "IL",
    "city": "Ursa"
  },
  {
    "value": "62378",
    "label": "Versailles - IL 62378",
    "county": "Brown County",
    "state": "IL",
    "city": "Versailles"
  },
  {
    "value": "62379",
    "label": "Warsaw - IL 62379",
    "county": "Hancock County",
    "state": "IL",
    "city": "Warsaw"
  },
  {
    "value": "62380",
    "label": "West Point - IL 62380",
    "county": "Hancock County",
    "state": "IL",
    "city": "West Point"
  },
  {
    "value": "62401",
    "label": "Effingham - IL 62401",
    "county": "Effingham County",
    "state": "IL",
    "city": "Effingham"
  },
  {
    "value": "62410",
    "label": "Allendale - IL 62410",
    "county": "Wabash County",
    "state": "IL",
    "city": "Allendale"
  },
  {
    "value": "62411",
    "label": "Altamont - IL 62411",
    "county": "Effingham County",
    "state": "IL",
    "city": "Altamont"
  },
  {
    "value": "62413",
    "label": "Annapolis - IL 62413",
    "county": "Crawford County",
    "state": "IL",
    "city": "Annapolis"
  },
  {
    "value": "62414",
    "label": "Beecher City - IL 62414",
    "county": "Fayette County",
    "state": "IL",
    "city": "Beecher City"
  },
  {
    "value": "62417",
    "label": "Bridgeport - IL 62417",
    "county": "Lawrence County",
    "state": "IL",
    "city": "Bridgeport"
  },
  {
    "value": "62418",
    "label": "Brownstown - IL 62418",
    "county": "Fayette County",
    "state": "IL",
    "city": "Brownstown"
  },
  {
    "value": "62419",
    "label": "Calhoun - IL 62419",
    "county": "Richland County",
    "state": "IL",
    "city": "Calhoun"
  },
  {
    "value": "62420",
    "label": "Casey - IL 62420",
    "county": "Clark County",
    "state": "IL",
    "city": "Casey"
  },
  {
    "value": "62421",
    "label": "Claremont - IL 62421",
    "county": "Richland County",
    "state": "IL",
    "city": "Claremont"
  },
  {
    "value": "62422",
    "label": "Cowden - IL 62422",
    "county": "Shelby County",
    "state": "IL",
    "city": "Cowden"
  },
  {
    "value": "62423",
    "label": "Dennison - IL 62423",
    "county": "Clark County",
    "state": "IL",
    "city": "Dennison"
  },
  {
    "value": "62424",
    "label": "Dieterich - IL 62424",
    "county": "Effingham County",
    "state": "IL",
    "city": "Dieterich"
  },
  {
    "value": "62425",
    "label": "Dundas - IL 62425",
    "county": "Richland County",
    "state": "IL",
    "city": "Dundas"
  },
  {
    "value": "62426",
    "label": "Edgewood - IL 62426",
    "county": "Effingham County",
    "state": "IL",
    "city": "Edgewood"
  },
  {
    "value": "62427",
    "label": "Flat Rock - IL 62427",
    "county": "Crawford County",
    "state": "IL",
    "city": "Flat Rock"
  },
  {
    "value": "62428",
    "label": "Greenup - IL 62428",
    "county": "Cumberland County",
    "state": "IL",
    "city": "Greenup"
  },
  {
    "value": "62431",
    "label": "Herrick - IL 62431",
    "county": "Shelby County",
    "state": "IL",
    "city": "Herrick"
  },
  {
    "value": "62432",
    "label": "Hidalgo - IL 62432",
    "county": "Jasper County",
    "state": "IL",
    "city": "Hidalgo"
  },
  {
    "value": "62433",
    "label": "Hutsonville - IL 62433",
    "county": "Crawford County",
    "state": "IL",
    "city": "Hutsonville"
  },
  {
    "value": "62434",
    "label": "Ingraham - IL 62434",
    "county": "Clay County",
    "state": "IL",
    "city": "Ingraham"
  },
  {
    "value": "62436",
    "label": "Jewett - IL 62436",
    "county": "Cumberland County",
    "state": "IL",
    "city": "Jewett"
  },
  {
    "value": "62438",
    "label": "Lakewood - IL 62438",
    "county": "Shelby County",
    "state": "IL",
    "city": "Lakewood"
  },
  {
    "value": "62439",
    "label": "Lawrenceville - IL 62439",
    "county": "Lawrence County",
    "state": "IL",
    "city": "Lawrenceville"
  },
  {
    "value": "62440",
    "label": "Lerna - IL 62440",
    "county": "Coles County",
    "state": "IL",
    "city": "Lerna"
  },
  {
    "value": "62441",
    "label": "Marshall - IL 62441",
    "county": "Clark County",
    "state": "IL",
    "city": "Marshall"
  },
  {
    "value": "62442",
    "label": "Martinsville - IL 62442",
    "county": "Clark County",
    "state": "IL",
    "city": "Martinsville"
  },
  {
    "value": "62443",
    "label": "Mason - IL 62443",
    "county": "Effingham County",
    "state": "IL",
    "city": "Mason"
  },
  {
    "value": "62444",
    "label": "Mode - IL 62444",
    "county": "Shelby County",
    "state": "IL",
    "city": "Mode"
  },
  {
    "value": "62445",
    "label": "Montrose - IL 62445",
    "county": "Cumberland County",
    "state": "IL",
    "city": "Montrose"
  },
  {
    "value": "62446",
    "label": "Mount Erie - IL 62446",
    "county": "Wayne County",
    "state": "IL",
    "city": "Mount Erie"
  },
  {
    "value": "62447",
    "label": "Neoga - IL 62447",
    "county": "Cumberland County",
    "state": "IL",
    "city": "Neoga"
  },
  {
    "value": "62448",
    "label": "Newton - IL 62448",
    "county": "Jasper County",
    "state": "IL",
    "city": "Newton"
  },
  {
    "value": "62449",
    "label": "Oblong - IL 62449",
    "county": "Crawford County",
    "state": "IL",
    "city": "Oblong"
  },
  {
    "value": "62450",
    "label": "Olney - IL 62450",
    "county": "Richland County",
    "state": "IL",
    "city": "Olney"
  },
  {
    "value": "62451",
    "label": "Palestine - IL 62451",
    "county": "Crawford County",
    "state": "IL",
    "city": "Palestine"
  },
  {
    "value": "62452",
    "label": "Parkersburg - IL 62452",
    "county": "Richland County",
    "state": "IL",
    "city": "Parkersburg"
  },
  {
    "value": "62454",
    "label": "Robinson - IL 62454",
    "county": "Crawford County",
    "state": "IL",
    "city": "Robinson"
  },
  {
    "value": "62458",
    "label": "Saint Elmo - IL 62458",
    "county": "Fayette County",
    "state": "IL",
    "city": "Saint Elmo"
  },
  {
    "value": "62460",
    "label": "Saint Francisville - IL 62460",
    "county": "Lawrence County",
    "state": "IL",
    "city": "Saint Francisville"
  },
  {
    "value": "62461",
    "label": "Shumway - IL 62461",
    "county": "Effingham County",
    "state": "IL",
    "city": "Shumway"
  },
  {
    "value": "62462",
    "label": "Sigel - IL 62462",
    "county": "Shelby County",
    "state": "IL",
    "city": "Sigel"
  },
  {
    "value": "62463",
    "label": "Stewardson - IL 62463",
    "county": "Shelby County",
    "state": "IL",
    "city": "Stewardson"
  },
  {
    "value": "62465",
    "label": "Strasburg - IL 62465",
    "county": "Shelby County",
    "state": "IL",
    "city": "Strasburg"
  },
  {
    "value": "62466",
    "label": "Sumner - IL 62466",
    "county": "Lawrence County",
    "state": "IL",
    "city": "Sumner"
  },
  {
    "value": "62467",
    "label": "Teutopolis - IL 62467",
    "county": "Effingham County",
    "state": "IL",
    "city": "Teutopolis"
  },
  {
    "value": "62468",
    "label": "Toledo - IL 62468",
    "county": "Cumberland County",
    "state": "IL",
    "city": "Toledo"
  },
  {
    "value": "62469",
    "label": "Trilla - IL 62469",
    "county": "Cumberland County",
    "state": "IL",
    "city": "Trilla"
  },
  {
    "value": "62471",
    "label": "Vandalia - IL 62471",
    "county": "Fayette County",
    "state": "IL",
    "city": "Vandalia"
  },
  {
    "value": "62473",
    "label": "Watson - IL 62473",
    "county": "Effingham County",
    "state": "IL",
    "city": "Watson"
  },
  {
    "value": "62474",
    "label": "Westfield - IL 62474",
    "county": "Clark County",
    "state": "IL",
    "city": "Westfield"
  },
  {
    "value": "62475",
    "label": "West Liberty - IL 62475",
    "county": "Jasper County",
    "state": "IL",
    "city": "West Liberty"
  },
  {
    "value": "62476",
    "label": "West Salem - IL 62476",
    "county": "Edwards County",
    "state": "IL",
    "city": "West Salem"
  },
  {
    "value": "62477",
    "label": "West Union - IL 62477",
    "county": "Clark County",
    "state": "IL",
    "city": "West Union"
  },
  {
    "value": "62478",
    "label": "West York - IL 62478",
    "county": "Crawford County",
    "state": "IL",
    "city": "West York"
  },
  {
    "value": "62479",
    "label": "Wheeler - IL 62479",
    "county": "Jasper County",
    "state": "IL",
    "city": "Wheeler"
  },
  {
    "value": "62480",
    "label": "Willow Hill - IL 62480",
    "county": "Jasper County",
    "state": "IL",
    "city": "Willow Hill"
  },
  {
    "value": "62481",
    "label": "Yale - IL 62481",
    "county": "Jasper County",
    "state": "IL",
    "city": "Yale"
  },
  {
    "value": "62501",
    "label": "Argenta - IL 62501",
    "county": "Macon County",
    "state": "IL",
    "city": "Argenta"
  },
  {
    "value": "62510",
    "label": "Assumption - IL 62510",
    "county": "Christian County",
    "state": "IL",
    "city": "Assumption"
  },
  {
    "value": "62512",
    "label": "Beason - IL 62512",
    "county": "Logan County",
    "state": "IL",
    "city": "Beason"
  },
  {
    "value": "62513",
    "label": "Blue Mound - IL 62513",
    "county": "Macon County",
    "state": "IL",
    "city": "Blue Mound"
  },
  {
    "value": "62515",
    "label": "Buffalo - IL 62515",
    "county": "Sangamon County",
    "state": "IL",
    "city": "Buffalo"
  },
  {
    "value": "62517",
    "label": "Bulpitt - IL 62517",
    "county": "Christian County",
    "state": "IL",
    "city": "Bulpitt"
  },
  {
    "value": "62518",
    "label": "Chestnut - IL 62518",
    "county": "Logan County",
    "state": "IL",
    "city": "Chestnut"
  },
  {
    "value": "62520",
    "label": "Dawson - IL 62520",
    "county": "Sangamon County",
    "state": "IL",
    "city": "Dawson"
  },
  {
    "value": "62521",
    "label": "Decatur - IL 62521",
    "county": "Macon County",
    "state": "IL",
    "city": "Decatur"
  },
  {
    "value": "62522",
    "label": "Decatur - IL 62522",
    "county": "Macon County",
    "state": "IL",
    "city": "Decatur"
  },
  {
    "value": "62523",
    "label": "Decatur - IL 62523",
    "county": "Macon County",
    "state": "IL",
    "city": "Decatur"
  },
  {
    "value": "62526",
    "label": "Decatur - IL 62526",
    "county": "Macon County",
    "state": "IL",
    "city": "Decatur"
  },
  {
    "value": "62530",
    "label": "Divernon - IL 62530",
    "county": "Sangamon County",
    "state": "IL",
    "city": "Divernon"
  },
  {
    "value": "62531",
    "label": "Edinburg - IL 62531",
    "county": "Christian County",
    "state": "IL",
    "city": "Edinburg"
  },
  {
    "value": "62533",
    "label": "Farmersville - IL 62533",
    "county": "Montgomery County",
    "state": "IL",
    "city": "Farmersville"
  },
  {
    "value": "62534",
    "label": "Findlay - IL 62534",
    "county": "Shelby County",
    "state": "IL",
    "city": "Findlay"
  },
  {
    "value": "62535",
    "label": "Forsyth - IL 62535",
    "county": "Macon County",
    "state": "IL",
    "city": "Forsyth"
  },
  {
    "value": "62536",
    "label": "Glenarm - IL 62536",
    "county": "Sangamon County",
    "state": "IL",
    "city": "Glenarm"
  },
  {
    "value": "62538",
    "label": "Harvel - IL 62538",
    "county": "Montgomery County",
    "state": "IL",
    "city": "Harvel"
  },
  {
    "value": "62539",
    "label": "Illiopolis - IL 62539",
    "county": "Sangamon County",
    "state": "IL",
    "city": "Illiopolis"
  },
  {
    "value": "62543",
    "label": "Latham - IL 62543",
    "county": "Logan County",
    "state": "IL",
    "city": "Latham"
  },
  {
    "value": "62544",
    "label": "Macon - IL 62544",
    "county": "Macon County",
    "state": "IL",
    "city": "Macon"
  },
  {
    "value": "62545",
    "label": "Mechanicsburg - IL 62545",
    "county": "Sangamon County",
    "state": "IL",
    "city": "Mechanicsburg"
  },
  {
    "value": "62546",
    "label": "Morrisonville - IL 62546",
    "county": "Christian County",
    "state": "IL",
    "city": "Morrisonville"
  },
  {
    "value": "62547",
    "label": "Mount Auburn - IL 62547",
    "county": "Christian County",
    "state": "IL",
    "city": "Mount Auburn"
  },
  {
    "value": "62548",
    "label": "Mount Pulaski - IL 62548",
    "county": "Logan County",
    "state": "IL",
    "city": "Mount Pulaski"
  },
  {
    "value": "62549",
    "label": "Mt Zion - IL 62549",
    "county": "Macon County",
    "state": "IL",
    "city": "Mt Zion"
  },
  {
    "value": "62550",
    "label": "Moweaqua - IL 62550",
    "county": "Shelby County",
    "state": "IL",
    "city": "Moweaqua"
  },
  {
    "value": "62551",
    "label": "Niantic - IL 62551",
    "county": "Macon County",
    "state": "IL",
    "city": "Niantic"
  },
  {
    "value": "62553",
    "label": "Oconee - IL 62553",
    "county": "Shelby County",
    "state": "IL",
    "city": "Oconee"
  },
  {
    "value": "62554",
    "label": "Oreana - IL 62554",
    "county": "Macon County",
    "state": "IL",
    "city": "Oreana"
  },
  {
    "value": "62555",
    "label": "Owaneco - IL 62555",
    "county": "Christian County",
    "state": "IL",
    "city": "Owaneco"
  },
  {
    "value": "62556",
    "label": "Palmer - IL 62556",
    "county": "Christian County",
    "state": "IL",
    "city": "Palmer"
  },
  {
    "value": "62557",
    "label": "Pana - IL 62557",
    "county": "Christian County",
    "state": "IL",
    "city": "Pana"
  },
  {
    "value": "62558",
    "label": "Pawnee - IL 62558",
    "county": "Sangamon County",
    "state": "IL",
    "city": "Pawnee"
  },
  {
    "value": "62560",
    "label": "Raymond - IL 62560",
    "county": "Montgomery County",
    "state": "IL",
    "city": "Raymond"
  },
  {
    "value": "62561",
    "label": "Riverton - IL 62561",
    "county": "Sangamon County",
    "state": "IL",
    "city": "Riverton"
  },
  {
    "value": "62563",
    "label": "Rochester - IL 62563",
    "county": "Sangamon County",
    "state": "IL",
    "city": "Rochester"
  },
  {
    "value": "62565",
    "label": "Shelbyville - IL 62565",
    "county": "Shelby County",
    "state": "IL",
    "city": "Shelbyville"
  },
  {
    "value": "62567",
    "label": "Stonington - IL 62567",
    "county": "Christian County",
    "state": "IL",
    "city": "Stonington"
  },
  {
    "value": "62568",
    "label": "Taylorville - IL 62568",
    "county": "Christian County",
    "state": "IL",
    "city": "Taylorville"
  },
  {
    "value": "62571",
    "label": "Tower Hill - IL 62571",
    "county": "Shelby County",
    "state": "IL",
    "city": "Tower Hill"
  },
  {
    "value": "62572",
    "label": "Waggoner - IL 62572",
    "county": "Montgomery County",
    "state": "IL",
    "city": "Waggoner"
  },
  {
    "value": "62573",
    "label": "Warrensburg - IL 62573",
    "county": "Macon County",
    "state": "IL",
    "city": "Warrensburg"
  },
  {
    "value": "62601",
    "label": "Alexander - IL 62601",
    "county": "Morgan County",
    "state": "IL",
    "city": "Alexander"
  },
  {
    "value": "62611",
    "label": "Arenzville - IL 62611",
    "county": "Cass County",
    "state": "IL",
    "city": "Arenzville"
  },
  {
    "value": "62612",
    "label": "Ashland - IL 62612",
    "county": "Cass County",
    "state": "IL",
    "city": "Ashland"
  },
  {
    "value": "62613",
    "label": "Athens - IL 62613",
    "county": "Menard County",
    "state": "IL",
    "city": "Athens"
  },
  {
    "value": "62615",
    "label": "Auburn - IL 62615",
    "county": "Sangamon County",
    "state": "IL",
    "city": "Auburn"
  },
  {
    "value": "62617",
    "label": "Bath - IL 62617",
    "county": "Mason County",
    "state": "IL",
    "city": "Bath"
  },
  {
    "value": "62618",
    "label": "Beardstown - IL 62618",
    "county": "Cass County",
    "state": "IL",
    "city": "Beardstown"
  },
  {
    "value": "62621",
    "label": "Bluffs - IL 62621",
    "county": "Scott County",
    "state": "IL",
    "city": "Bluffs"
  },
  {
    "value": "62624",
    "label": "Browning - IL 62624",
    "county": "Schuyler County",
    "state": "IL",
    "city": "Browning"
  },
  {
    "value": "62625",
    "label": "Cantrall - IL 62625",
    "county": "Sangamon County",
    "state": "IL",
    "city": "Cantrall"
  },
  {
    "value": "62626",
    "label": "Carlinville - IL 62626",
    "county": "Macoupin County",
    "state": "IL",
    "city": "Carlinville"
  },
  {
    "value": "62627",
    "label": "Chandlerville - IL 62627",
    "county": "Cass County",
    "state": "IL",
    "city": "Chandlerville"
  },
  {
    "value": "62628",
    "label": "Chapin - IL 62628",
    "county": "Morgan County",
    "state": "IL",
    "city": "Chapin"
  },
  {
    "value": "62629",
    "label": "Chatham - IL 62629",
    "county": "Sangamon County",
    "state": "IL",
    "city": "Chatham"
  },
  {
    "value": "62630",
    "label": "Chesterfield - IL 62630",
    "county": "Macoupin County",
    "state": "IL",
    "city": "Chesterfield"
  },
  {
    "value": "62631",
    "label": "Concord - IL 62631",
    "county": "Morgan County",
    "state": "IL",
    "city": "Concord"
  },
  {
    "value": "62633",
    "label": "Easton - IL 62633",
    "county": "Mason County",
    "state": "IL",
    "city": "Easton"
  },
  {
    "value": "62634",
    "label": "Elkhart - IL 62634",
    "county": "Logan County",
    "state": "IL",
    "city": "Elkhart"
  },
  {
    "value": "62635",
    "label": "Emden - IL 62635",
    "county": "Logan County",
    "state": "IL",
    "city": "Emden"
  },
  {
    "value": "62638",
    "label": "Franklin - IL 62638",
    "county": "Morgan County",
    "state": "IL",
    "city": "Franklin"
  },
  {
    "value": "62639",
    "label": "Frederick - IL 62639",
    "county": "Schuyler County",
    "state": "IL",
    "city": "Frederick"
  },
  {
    "value": "62640",
    "label": "Girard - IL 62640",
    "county": "Macoupin County",
    "state": "IL",
    "city": "Girard"
  },
  {
    "value": "62642",
    "label": "Greenview - IL 62642",
    "county": "Menard County",
    "state": "IL",
    "city": "Greenview"
  },
  {
    "value": "62643",
    "label": "Hartsburg - IL 62643",
    "county": "Logan County",
    "state": "IL",
    "city": "Hartsburg"
  },
  {
    "value": "62644",
    "label": "Havana - IL 62644",
    "county": "Mason County",
    "state": "IL",
    "city": "Havana"
  },
  {
    "value": "62649",
    "label": "Hettick - IL 62649",
    "county": "Macoupin County",
    "state": "IL",
    "city": "Hettick"
  },
  {
    "value": "62650",
    "label": "Jacksonville - IL 62650",
    "county": "Morgan County",
    "state": "IL",
    "city": "Jacksonville"
  },
  {
    "value": "62655",
    "label": "Kilbourne - IL 62655",
    "county": "Mason County",
    "state": "IL",
    "city": "Kilbourne"
  },
  {
    "value": "62656",
    "label": "Lincoln - IL 62656",
    "county": "Logan County",
    "state": "IL",
    "city": "Lincoln"
  },
  {
    "value": "62659",
    "label": "Lincolns New Salem - IL 62659",
    "county": "Menard County",
    "state": "IL",
    "city": "Lincolns New Salem"
  },
  {
    "value": "62661",
    "label": "Loami - IL 62661",
    "county": "Sangamon County",
    "state": "IL",
    "city": "Loami"
  },
  {
    "value": "62664",
    "label": "Mason City - IL 62664",
    "county": "Mason County",
    "state": "IL",
    "city": "Mason City"
  },
  {
    "value": "62665",
    "label": "Meredosia - IL 62665",
    "county": "Morgan County",
    "state": "IL",
    "city": "Meredosia"
  },
  {
    "value": "62666",
    "label": "Middletown - IL 62666",
    "county": "Logan County",
    "state": "IL",
    "city": "Middletown"
  },
  {
    "value": "62667",
    "label": "Modesto - IL 62667",
    "county": "Macoupin County",
    "state": "IL",
    "city": "Modesto"
  },
  {
    "value": "62668",
    "label": "Murrayville - IL 62668",
    "county": "Morgan County",
    "state": "IL",
    "city": "Murrayville"
  },
  {
    "value": "62670",
    "label": "New Berlin - IL 62670",
    "county": "Sangamon County",
    "state": "IL",
    "city": "New Berlin"
  },
  {
    "value": "62671",
    "label": "New Holland - IL 62671",
    "county": "Logan County",
    "state": "IL",
    "city": "New Holland"
  },
  {
    "value": "62673",
    "label": "Oakford - IL 62673",
    "county": "Menard County",
    "state": "IL",
    "city": "Oakford"
  },
  {
    "value": "62674",
    "label": "Palmyra - IL 62674",
    "county": "Macoupin County",
    "state": "IL",
    "city": "Palmyra"
  },
  {
    "value": "62675",
    "label": "Petersburg - IL 62675",
    "county": "Menard County",
    "state": "IL",
    "city": "Petersburg"
  },
  {
    "value": "62677",
    "label": "Pleasant Plains - IL 62677",
    "county": "Sangamon County",
    "state": "IL",
    "city": "Pleasant Plains"
  },
  {
    "value": "62681",
    "label": "Rushville - IL 62681",
    "county": "Schuyler County",
    "state": "IL",
    "city": "Rushville"
  },
  {
    "value": "62682",
    "label": "San Jose - IL 62682",
    "county": "Mason County",
    "state": "IL",
    "city": "San Jose"
  },
  {
    "value": "62684",
    "label": "Sherman - IL 62684",
    "county": "Sangamon County",
    "state": "IL",
    "city": "Sherman"
  },
  {
    "value": "62685",
    "label": "Shipman - IL 62685",
    "county": "Macoupin County",
    "state": "IL",
    "city": "Shipman"
  },
  {
    "value": "62688",
    "label": "Tallula - IL 62688",
    "county": "Menard County",
    "state": "IL",
    "city": "Tallula"
  },
  {
    "value": "62690",
    "label": "Virden - IL 62690",
    "county": "Macoupin County",
    "state": "IL",
    "city": "Virden"
  },
  {
    "value": "62691",
    "label": "Virginia - IL 62691",
    "county": "Cass County",
    "state": "IL",
    "city": "Virginia"
  },
  {
    "value": "62692",
    "label": "Waverly - IL 62692",
    "county": "Morgan County",
    "state": "IL",
    "city": "Waverly"
  },
  {
    "value": "62693",
    "label": "Williamsville - IL 62693",
    "county": "Sangamon County",
    "state": "IL",
    "city": "Williamsville"
  },
  {
    "value": "62694",
    "label": "Winchester - IL 62694",
    "county": "Scott County",
    "state": "IL",
    "city": "Winchester"
  },
  {
    "value": "62701",
    "label": "Springfield - IL 62701",
    "county": "Sangamon County",
    "state": "IL",
    "city": "Springfield"
  },
  {
    "value": "62702",
    "label": "Springfield - IL 62702",
    "county": "Sangamon County",
    "state": "IL",
    "city": "Springfield"
  },
  {
    "value": "62703",
    "label": "Springfield - IL 62703",
    "county": "Sangamon County",
    "state": "IL",
    "city": "Springfield"
  },
  {
    "value": "62704",
    "label": "Springfield - IL 62704",
    "county": "Sangamon County",
    "state": "IL",
    "city": "Springfield"
  },
  {
    "value": "62707",
    "label": "Springfield - IL 62707",
    "county": "Sangamon County",
    "state": "IL",
    "city": "Springfield"
  },
  {
    "value": "62711",
    "label": "Springfield - IL 62711",
    "county": "Sangamon County",
    "state": "IL",
    "city": "Springfield"
  },
  {
    "value": "62712",
    "label": "Springfield - IL 62712",
    "county": "Sangamon County",
    "state": "IL",
    "city": "Springfield"
  },
  {
    "value": "62801",
    "label": "Centralia - IL 62801",
    "county": "Marion County",
    "state": "IL",
    "city": "Centralia"
  },
  {
    "value": "62803",
    "label": "Hoyleton - IL 62803",
    "county": "Washington County",
    "state": "IL",
    "city": "Hoyleton"
  },
  {
    "value": "62806",
    "label": "Albion - IL 62806",
    "county": "Edwards County",
    "state": "IL",
    "city": "Albion"
  },
  {
    "value": "62807",
    "label": "Alma - IL 62807",
    "county": "Marion County",
    "state": "IL",
    "city": "Alma"
  },
  {
    "value": "62808",
    "label": "Ashley - IL 62808",
    "county": "Washington County",
    "state": "IL",
    "city": "Ashley"
  },
  {
    "value": "62809",
    "label": "Barnhill - IL 62809",
    "county": "Wayne County",
    "state": "IL",
    "city": "Barnhill"
  },
  {
    "value": "62810",
    "label": "Belle Rive - IL 62810",
    "county": "Jefferson County",
    "state": "IL",
    "city": "Belle Rive"
  },
  {
    "value": "62812",
    "label": "Benton - IL 62812",
    "county": "Franklin County",
    "state": "IL",
    "city": "Benton"
  },
  {
    "value": "62814",
    "label": "Bluford - IL 62814",
    "county": "Jefferson County",
    "state": "IL",
    "city": "Bluford"
  },
  {
    "value": "62815",
    "label": "Bone Gap - IL 62815",
    "county": "Edwards County",
    "state": "IL",
    "city": "Bone Gap"
  },
  {
    "value": "62816",
    "label": "Bonnie - IL 62816",
    "county": "Jefferson County",
    "state": "IL",
    "city": "Bonnie"
  },
  {
    "value": "62817",
    "label": "Broughton - IL 62817",
    "county": "Hamilton County",
    "state": "IL",
    "city": "Broughton"
  },
  {
    "value": "62818",
    "label": "Browns - IL 62818",
    "county": "Edwards County",
    "state": "IL",
    "city": "Browns"
  },
  {
    "value": "62819",
    "label": "Buckner - IL 62819",
    "county": "Franklin County",
    "state": "IL",
    "city": "Buckner"
  },
  {
    "value": "62820",
    "label": "Burnt Prairie - IL 62820",
    "county": "White County",
    "state": "IL",
    "city": "Burnt Prairie"
  },
  {
    "value": "62821",
    "label": "Carmi - IL 62821",
    "county": "White County",
    "state": "IL",
    "city": "Carmi"
  },
  {
    "value": "62822",
    "label": "Christopher - IL 62822",
    "county": "Franklin County",
    "state": "IL",
    "city": "Christopher"
  },
  {
    "value": "62823",
    "label": "Cisne - IL 62823",
    "county": "Wayne County",
    "state": "IL",
    "city": "Cisne"
  },
  {
    "value": "62824",
    "label": "Clay City - IL 62824",
    "county": "Clay County",
    "state": "IL",
    "city": "Clay City"
  },
  {
    "value": "62827",
    "label": "Crossville - IL 62827",
    "county": "White County",
    "state": "IL",
    "city": "Crossville"
  },
  {
    "value": "62828",
    "label": "Dahlgren - IL 62828",
    "county": "Hamilton County",
    "state": "IL",
    "city": "Dahlgren"
  },
  {
    "value": "62830",
    "label": "Dix - IL 62830",
    "county": "Jefferson County",
    "state": "IL",
    "city": "Dix"
  },
  {
    "value": "62831",
    "label": "Du Bois - IL 62831",
    "county": "Washington County",
    "state": "IL",
    "city": "Du Bois"
  },
  {
    "value": "62832",
    "label": "Du Quoin - IL 62832",
    "county": "Perry County",
    "state": "IL",
    "city": "Du Quoin"
  },
  {
    "value": "62833",
    "label": "Ellery - IL 62833",
    "county": "Wayne County",
    "state": "IL",
    "city": "Ellery"
  },
  {
    "value": "62835",
    "label": "Enfield - IL 62835",
    "county": "White County",
    "state": "IL",
    "city": "Enfield"
  },
  {
    "value": "62836",
    "label": "Ewing - IL 62836",
    "county": "Franklin County",
    "state": "IL",
    "city": "Ewing"
  },
  {
    "value": "62837",
    "label": "Fairfield - IL 62837",
    "county": "Wayne County",
    "state": "IL",
    "city": "Fairfield"
  },
  {
    "value": "62838",
    "label": "Farina - IL 62838",
    "county": "Fayette County",
    "state": "IL",
    "city": "Farina"
  },
  {
    "value": "62839",
    "label": "Flora - IL 62839",
    "county": "Clay County",
    "state": "IL",
    "city": "Flora"
  },
  {
    "value": "62842",
    "label": "Geff - IL 62842",
    "county": "Wayne County",
    "state": "IL",
    "city": "Geff"
  },
  {
    "value": "62843",
    "label": "Golden Gate - IL 62843",
    "county": "Wayne County",
    "state": "IL",
    "city": "Golden Gate"
  },
  {
    "value": "62844",
    "label": "Grayville - IL 62844",
    "county": "White County",
    "state": "IL",
    "city": "Grayville"
  },
  {
    "value": "62846",
    "label": "Ina - IL 62846",
    "county": "Jefferson County",
    "state": "IL",
    "city": "Ina"
  },
  {
    "value": "62849",
    "label": "Iuka - IL 62849",
    "county": "Marion County",
    "state": "IL",
    "city": "Iuka"
  },
  {
    "value": "62850",
    "label": "Johnsonville - IL 62850",
    "county": "Wayne County",
    "state": "IL",
    "city": "Johnsonville"
  },
  {
    "value": "62851",
    "label": "Keenes - IL 62851",
    "county": "Wayne County",
    "state": "IL",
    "city": "Keenes"
  },
  {
    "value": "62853",
    "label": "Kell - IL 62853",
    "county": "Marion County",
    "state": "IL",
    "city": "Kell"
  },
  {
    "value": "62854",
    "label": "Kinmundy - IL 62854",
    "county": "Marion County",
    "state": "IL",
    "city": "Kinmundy"
  },
  {
    "value": "62858",
    "label": "Louisville - IL 62858",
    "county": "Clay County",
    "state": "IL",
    "city": "Louisville"
  },
  {
    "value": "62859",
    "label": "Mc Leansboro - IL 62859",
    "county": "Hamilton County",
    "state": "IL",
    "city": "Mc Leansboro"
  },
  {
    "value": "62860",
    "label": "Macedonia - IL 62860",
    "county": "Franklin County",
    "state": "IL",
    "city": "Macedonia"
  },
  {
    "value": "62862",
    "label": "Mill Shoals - IL 62862",
    "county": "White County",
    "state": "IL",
    "city": "Mill Shoals"
  },
  {
    "value": "62863",
    "label": "Mount Carmel - IL 62863",
    "county": "Wabash County",
    "state": "IL",
    "city": "Mount Carmel"
  },
  {
    "value": "62864",
    "label": "Mount Vernon - IL 62864",
    "county": "Jefferson County",
    "state": "IL",
    "city": "Mount Vernon"
  },
  {
    "value": "62865",
    "label": "Mulkeytown - IL 62865",
    "county": "Franklin County",
    "state": "IL",
    "city": "Mulkeytown"
  },
  {
    "value": "62867",
    "label": "New Haven - IL 62867",
    "county": "Gallatin County",
    "state": "IL",
    "city": "New Haven"
  },
  {
    "value": "62868",
    "label": "Noble - IL 62868",
    "county": "Richland County",
    "state": "IL",
    "city": "Noble"
  },
  {
    "value": "62869",
    "label": "Norris City - IL 62869",
    "county": "White County",
    "state": "IL",
    "city": "Norris City"
  },
  {
    "value": "62870",
    "label": "Odin - IL 62870",
    "county": "Marion County",
    "state": "IL",
    "city": "Odin"
  },
  {
    "value": "62871",
    "label": "Omaha - IL 62871",
    "county": "Gallatin County",
    "state": "IL",
    "city": "Omaha"
  },
  {
    "value": "62872",
    "label": "Opdyke - IL 62872",
    "county": "Jefferson County",
    "state": "IL",
    "city": "Opdyke"
  },
  {
    "value": "62874",
    "label": "Orient - IL 62874",
    "county": "Franklin County",
    "state": "IL",
    "city": "Orient"
  },
  {
    "value": "62875",
    "label": "Patoka - IL 62875",
    "county": "Marion County",
    "state": "IL",
    "city": "Patoka"
  },
  {
    "value": "62877",
    "label": "Richview - IL 62877",
    "county": "Washington County",
    "state": "IL",
    "city": "Richview"
  },
  {
    "value": "62878",
    "label": "Rinard - IL 62878",
    "county": "Wayne County",
    "state": "IL",
    "city": "Rinard"
  },
  {
    "value": "62880",
    "label": "Saint Peter - IL 62880",
    "county": "Fayette County",
    "state": "IL",
    "city": "Saint Peter"
  },
  {
    "value": "62881",
    "label": "Salem - IL 62881",
    "county": "Marion County",
    "state": "IL",
    "city": "Salem"
  },
  {
    "value": "62882",
    "label": "Sandoval - IL 62882",
    "county": "Marion County",
    "state": "IL",
    "city": "Sandoval"
  },
  {
    "value": "62883",
    "label": "Scheller - IL 62883",
    "county": "Jefferson County",
    "state": "IL",
    "city": "Scheller"
  },
  {
    "value": "62884",
    "label": "Sesser - IL 62884",
    "county": "Franklin County",
    "state": "IL",
    "city": "Sesser"
  },
  {
    "value": "62885",
    "label": "Shobonier - IL 62885",
    "county": "Fayette County",
    "state": "IL",
    "city": "Shobonier"
  },
  {
    "value": "62886",
    "label": "Sims - IL 62886",
    "county": "Wayne County",
    "state": "IL",
    "city": "Sims"
  },
  {
    "value": "62887",
    "label": "Springerton - IL 62887",
    "county": "White County",
    "state": "IL",
    "city": "Springerton"
  },
  {
    "value": "62888",
    "label": "Tamaroa - IL 62888",
    "county": "Perry County",
    "state": "IL",
    "city": "Tamaroa"
  },
  {
    "value": "62889",
    "label": "Texico - IL 62889",
    "county": "Jefferson County",
    "state": "IL",
    "city": "Texico"
  },
  {
    "value": "62890",
    "label": "Thompsonville - IL 62890",
    "county": "Franklin County",
    "state": "IL",
    "city": "Thompsonville"
  },
  {
    "value": "62892",
    "label": "Vernon - IL 62892",
    "county": "Marion County",
    "state": "IL",
    "city": "Vernon"
  },
  {
    "value": "62893",
    "label": "Walnut Hill - IL 62893",
    "county": "Marion County",
    "state": "IL",
    "city": "Walnut Hill"
  },
  {
    "value": "62894",
    "label": "Waltonville - IL 62894",
    "county": "Jefferson County",
    "state": "IL",
    "city": "Waltonville"
  },
  {
    "value": "62895",
    "label": "Wayne City - IL 62895",
    "county": "Wayne County",
    "state": "IL",
    "city": "Wayne City"
  },
  {
    "value": "62896",
    "label": "West Frankfort - IL 62896",
    "county": "Franklin County",
    "state": "IL",
    "city": "West Frankfort"
  },
  {
    "value": "62897",
    "label": "Whittington - IL 62897",
    "county": "Franklin County",
    "state": "IL",
    "city": "Whittington"
  },
  {
    "value": "62898",
    "label": "Woodlawn - IL 62898",
    "county": "Jefferson County",
    "state": "IL",
    "city": "Woodlawn"
  },
  {
    "value": "62899",
    "label": "Xenia - IL 62899",
    "county": "Clay County",
    "state": "IL",
    "city": "Xenia"
  },
  {
    "value": "62901",
    "label": "Carbondale - IL 62901",
    "county": "Jackson County",
    "state": "IL",
    "city": "Carbondale"
  },
  {
    "value": "62902",
    "label": "Carbondale - IL 62902",
    "county": "Jackson County",
    "state": "IL",
    "city": "Carbondale"
  },
  {
    "value": "62903",
    "label": "Carbondale - IL 62903",
    "county": "Jackson County",
    "state": "IL",
    "city": "Carbondale"
  },
  {
    "value": "62905",
    "label": "Alto Pass - IL 62905",
    "county": "Union County",
    "state": "IL",
    "city": "Alto Pass"
  },
  {
    "value": "62906",
    "label": "Anna - IL 62906",
    "county": "Union County",
    "state": "IL",
    "city": "Anna"
  },
  {
    "value": "62907",
    "label": "Ava - IL 62907",
    "county": "Jackson County",
    "state": "IL",
    "city": "Ava"
  },
  {
    "value": "62908",
    "label": "Belknap - IL 62908",
    "county": "Massac County",
    "state": "IL",
    "city": "Belknap"
  },
  {
    "value": "62909",
    "label": "Boles - IL 62909",
    "county": "Johnson County",
    "state": "IL",
    "city": "Boles"
  },
  {
    "value": "62910",
    "label": "Brookport - IL 62910",
    "county": "Massac County",
    "state": "IL",
    "city": "Brookport"
  },
  {
    "value": "62912",
    "label": "Buncombe - IL 62912",
    "county": "Johnson County",
    "state": "IL",
    "city": "Buncombe"
  },
  {
    "value": "62914",
    "label": "Cairo - IL 62914",
    "county": "Alexander County",
    "state": "IL",
    "city": "Cairo"
  },
  {
    "value": "62916",
    "label": "Campbell Hill - IL 62916",
    "county": "Jackson County",
    "state": "IL",
    "city": "Campbell Hill"
  },
  {
    "value": "62917",
    "label": "Carrier Mills - IL 62917",
    "county": "Saline County",
    "state": "IL",
    "city": "Carrier Mills"
  },
  {
    "value": "62918",
    "label": "Carterville - IL 62918",
    "county": "Williamson County",
    "state": "IL",
    "city": "Carterville"
  },
  {
    "value": "62919",
    "label": "Cave In Rock - IL 62919",
    "county": "Hardin County",
    "state": "IL",
    "city": "Cave In Rock"
  },
  {
    "value": "62920",
    "label": "Cobden - IL 62920",
    "county": "Union County",
    "state": "IL",
    "city": "Cobden"
  },
  {
    "value": "62922",
    "label": "Creal Springs - IL 62922",
    "county": "Williamson County",
    "state": "IL",
    "city": "Creal Springs"
  },
  {
    "value": "62923",
    "label": "Cypress - IL 62923",
    "county": "Johnson County",
    "state": "IL",
    "city": "Cypress"
  },
  {
    "value": "62924",
    "label": "De Soto - IL 62924",
    "county": "Jackson County",
    "state": "IL",
    "city": "De Soto"
  },
  {
    "value": "62926",
    "label": "Dongola - IL 62926",
    "county": "Union County",
    "state": "IL",
    "city": "Dongola"
  },
  {
    "value": "62928",
    "label": "Eddyville - IL 62928",
    "county": "Pope County",
    "state": "IL",
    "city": "Eddyville"
  },
  {
    "value": "62930",
    "label": "Eldorado - IL 62930",
    "county": "Saline County",
    "state": "IL",
    "city": "Eldorado"
  },
  {
    "value": "62931",
    "label": "Elizabethtown - IL 62931",
    "county": "Hardin County",
    "state": "IL",
    "city": "Elizabethtown"
  },
  {
    "value": "62932",
    "label": "Elkville - IL 62932",
    "county": "Jackson County",
    "state": "IL",
    "city": "Elkville"
  },
  {
    "value": "62933",
    "label": "Energy - IL 62933",
    "county": "Williamson County",
    "state": "IL",
    "city": "Energy"
  },
  {
    "value": "62934",
    "label": "Equality - IL 62934",
    "county": "Gallatin County",
    "state": "IL",
    "city": "Equality"
  },
  {
    "value": "62935",
    "label": "Galatia - IL 62935",
    "county": "Saline County",
    "state": "IL",
    "city": "Galatia"
  },
  {
    "value": "62938",
    "label": "Golconda - IL 62938",
    "county": "Pope County",
    "state": "IL",
    "city": "Golconda"
  },
  {
    "value": "62939",
    "label": "Goreville - IL 62939",
    "county": "Johnson County",
    "state": "IL",
    "city": "Goreville"
  },
  {
    "value": "62940",
    "label": "Gorham - IL 62940",
    "county": "Jackson County",
    "state": "IL",
    "city": "Gorham"
  },
  {
    "value": "62941",
    "label": "Grand Chain - IL 62941",
    "county": "Pulaski County",
    "state": "IL",
    "city": "Grand Chain"
  },
  {
    "value": "62942",
    "label": "Grand Tower - IL 62942",
    "county": "Jackson County",
    "state": "IL",
    "city": "Grand Tower"
  },
  {
    "value": "62943",
    "label": "Grantsburg - IL 62943",
    "county": "Johnson County",
    "state": "IL",
    "city": "Grantsburg"
  },
  {
    "value": "62946",
    "label": "Harrisburg - IL 62946",
    "county": "Saline County",
    "state": "IL",
    "city": "Harrisburg"
  },
  {
    "value": "62947",
    "label": "Herod - IL 62947",
    "county": "Hardin County",
    "state": "IL",
    "city": "Herod"
  },
  {
    "value": "62948",
    "label": "Herrin - IL 62948",
    "county": "Williamson County",
    "state": "IL",
    "city": "Herrin"
  },
  {
    "value": "62950",
    "label": "Jacob - IL 62950",
    "county": "Jackson County",
    "state": "IL",
    "city": "Jacob"
  },
  {
    "value": "62951",
    "label": "Johnston City - IL 62951",
    "county": "Williamson County",
    "state": "IL",
    "city": "Johnston City"
  },
  {
    "value": "62952",
    "label": "Jonesboro - IL 62952",
    "county": "Union County",
    "state": "IL",
    "city": "Jonesboro"
  },
  {
    "value": "62954",
    "label": "Junction - IL 62954",
    "county": "Gallatin County",
    "state": "IL",
    "city": "Junction"
  },
  {
    "value": "62956",
    "label": "Karnak - IL 62956",
    "county": "Pulaski County",
    "state": "IL",
    "city": "Karnak"
  },
  {
    "value": "62957",
    "label": "Mc Clure - IL 62957",
    "county": "Alexander County",
    "state": "IL",
    "city": "Mc Clure"
  },
  {
    "value": "62958",
    "label": "Makanda - IL 62958",
    "county": "Jackson County",
    "state": "IL",
    "city": "Makanda"
  },
  {
    "value": "62959",
    "label": "Marion - IL 62959",
    "county": "Williamson County",
    "state": "IL",
    "city": "Marion"
  },
  {
    "value": "62960",
    "label": "Metropolis - IL 62960",
    "county": "Massac County",
    "state": "IL",
    "city": "Metropolis"
  },
  {
    "value": "62962",
    "label": "Miller City - IL 62962",
    "county": "Alexander County",
    "state": "IL",
    "city": "Miller City"
  },
  {
    "value": "62963",
    "label": "Mound City - IL 62963",
    "county": "Pulaski County",
    "state": "IL",
    "city": "Mound City"
  },
  {
    "value": "62964",
    "label": "Mounds - IL 62964",
    "county": "Pulaski County",
    "state": "IL",
    "city": "Mounds"
  },
  {
    "value": "62966",
    "label": "Murphysboro - IL 62966",
    "county": "Jackson County",
    "state": "IL",
    "city": "Murphysboro"
  },
  {
    "value": "62967",
    "label": "New Burnside - IL 62967",
    "county": "Johnson County",
    "state": "IL",
    "city": "New Burnside"
  },
  {
    "value": "62969",
    "label": "Olive Branch - IL 62969",
    "county": "Alexander County",
    "state": "IL",
    "city": "Olive Branch"
  },
  {
    "value": "62970",
    "label": "Olmsted - IL 62970",
    "county": "Pulaski County",
    "state": "IL",
    "city": "Olmsted"
  },
  {
    "value": "62972",
    "label": "Ozark - IL 62972",
    "county": "Johnson County",
    "state": "IL",
    "city": "Ozark"
  },
  {
    "value": "62974",
    "label": "Pittsburg - IL 62974",
    "county": "Williamson County",
    "state": "IL",
    "city": "Pittsburg"
  },
  {
    "value": "62975",
    "label": "Pomona - IL 62975",
    "county": "Jackson County",
    "state": "IL",
    "city": "Pomona"
  },
  {
    "value": "62976",
    "label": "Pulaski - IL 62976",
    "county": "Pulaski County",
    "state": "IL",
    "city": "Pulaski"
  },
  {
    "value": "62977",
    "label": "Raleigh - IL 62977",
    "county": "Saline County",
    "state": "IL",
    "city": "Raleigh"
  },
  {
    "value": "62979",
    "label": "Ridgway - IL 62979",
    "county": "Gallatin County",
    "state": "IL",
    "city": "Ridgway"
  },
  {
    "value": "62982",
    "label": "Rosiclare - IL 62982",
    "county": "Hardin County",
    "state": "IL",
    "city": "Rosiclare"
  },
  {
    "value": "62983",
    "label": "Royalton - IL 62983",
    "county": "Franklin County",
    "state": "IL",
    "city": "Royalton"
  },
  {
    "value": "62984",
    "label": "Shawneetown - IL 62984",
    "county": "Gallatin County",
    "state": "IL",
    "city": "Shawneetown"
  },
  {
    "value": "62985",
    "label": "Simpson - IL 62985",
    "county": "Johnson County",
    "state": "IL",
    "city": "Simpson"
  },
  {
    "value": "62987",
    "label": "Stonefort - IL 62987",
    "county": "Saline County",
    "state": "IL",
    "city": "Stonefort"
  },
  {
    "value": "62988",
    "label": "Tamms - IL 62988",
    "county": "Alexander County",
    "state": "IL",
    "city": "Tamms"
  },
  {
    "value": "62990",
    "label": "Thebes - IL 62990",
    "county": "Alexander County",
    "state": "IL",
    "city": "Thebes"
  },
  {
    "value": "62992",
    "label": "Ullin - IL 62992",
    "county": "Pulaski County",
    "state": "IL",
    "city": "Ullin"
  },
  {
    "value": "62994",
    "label": "Vergennes - IL 62994",
    "county": "Jackson County",
    "state": "IL",
    "city": "Vergennes"
  },
  {
    "value": "62995",
    "label": "Vienna - IL 62995",
    "county": "Johnson County",
    "state": "IL",
    "city": "Vienna"
  },
  {
    "value": "62996",
    "label": "Villa Ridge - IL 62996",
    "county": "Pulaski County",
    "state": "IL",
    "city": "Villa Ridge"
  },
  {
    "value": "62998",
    "label": "Wolf Lake - IL 62998",
    "county": "Union County",
    "state": "IL",
    "city": "Wolf Lake"
  },
  {
    "value": "62999",
    "label": "Zeigler - IL 62999",
    "county": "Franklin County",
    "state": "IL",
    "city": "Zeigler"
  },
  {
    "value": "46001",
    "label": "Alexandria - IN 46001",
    "county": "Madison County",
    "state": "IN",
    "city": "Alexandria"
  },
  {
    "value": "46011",
    "label": "Anderson - IN 46011",
    "county": "Madison County",
    "state": "IN",
    "city": "Anderson"
  },
  {
    "value": "46012",
    "label": "Anderson - IN 46012",
    "county": "Madison County",
    "state": "IN",
    "city": "Anderson"
  },
  {
    "value": "46013",
    "label": "Anderson - IN 46013",
    "county": "Madison County",
    "state": "IN",
    "city": "Anderson"
  },
  {
    "value": "46016",
    "label": "Anderson - IN 46016",
    "county": "Madison County",
    "state": "IN",
    "city": "Anderson"
  },
  {
    "value": "46017",
    "label": "Anderson - IN 46017",
    "county": "Madison County",
    "state": "IN",
    "city": "Anderson"
  },
  {
    "value": "46030",
    "label": "Arcadia - IN 46030",
    "county": "Hamilton County",
    "state": "IN",
    "city": "Arcadia"
  },
  {
    "value": "46031",
    "label": "Atlanta - IN 46031",
    "county": "Hamilton County",
    "state": "IN",
    "city": "Atlanta"
  },
  {
    "value": "46032",
    "label": "Carmel - IN 46032",
    "county": "Hamilton County",
    "state": "IN",
    "city": "Carmel"
  },
  {
    "value": "46033",
    "label": "Carmel - IN 46033",
    "county": "Hamilton County",
    "state": "IN",
    "city": "Carmel"
  },
  {
    "value": "46034",
    "label": "Cicero - IN 46034",
    "county": "Hamilton County",
    "state": "IN",
    "city": "Cicero"
  },
  {
    "value": "46035",
    "label": "Colfax - IN 46035",
    "county": "Clinton County",
    "state": "IN",
    "city": "Colfax"
  },
  {
    "value": "46036",
    "label": "Elwood - IN 46036",
    "county": "Madison County",
    "state": "IN",
    "city": "Elwood"
  },
  {
    "value": "46037",
    "label": "Fishers - IN 46037",
    "county": "Hamilton County",
    "state": "IN",
    "city": "Fishers"
  },
  {
    "value": "46038",
    "label": "Fishers - IN 46038",
    "county": "Hamilton County",
    "state": "IN",
    "city": "Fishers"
  },
  {
    "value": "46039",
    "label": "Forest - IN 46039",
    "county": "Clinton County",
    "state": "IN",
    "city": "Forest"
  },
  {
    "value": "46040",
    "label": "Fortville - IN 46040",
    "county": "Hancock County",
    "state": "IN",
    "city": "Fortville"
  },
  {
    "value": "46041",
    "label": "Frankfort - IN 46041",
    "county": "Clinton County",
    "state": "IN",
    "city": "Frankfort"
  },
  {
    "value": "46044",
    "label": "Frankton - IN 46044",
    "county": "Madison County",
    "state": "IN",
    "city": "Frankton"
  },
  {
    "value": "46048",
    "label": "Ingalls - IN 46048",
    "county": "Madison County",
    "state": "IN",
    "city": "Ingalls"
  },
  {
    "value": "46049",
    "label": "Kempton - IN 46049",
    "county": "Tipton County",
    "state": "IN",
    "city": "Kempton"
  },
  {
    "value": "46050",
    "label": "Kirklin - IN 46050",
    "county": "Clinton County",
    "state": "IN",
    "city": "Kirklin"
  },
  {
    "value": "46051",
    "label": "Lapel - IN 46051",
    "county": "Madison County",
    "state": "IN",
    "city": "Lapel"
  },
  {
    "value": "46052",
    "label": "Lebanon - IN 46052",
    "county": "Boone County",
    "state": "IN",
    "city": "Lebanon"
  },
  {
    "value": "46055",
    "label": "Mccordsville - IN 46055",
    "county": "Hancock County",
    "state": "IN",
    "city": "Mccordsville"
  },
  {
    "value": "46056",
    "label": "Markleville - IN 46056",
    "county": "Madison County",
    "state": "IN",
    "city": "Markleville"
  },
  {
    "value": "46057",
    "label": "Michigantown - IN 46057",
    "county": "Clinton County",
    "state": "IN",
    "city": "Michigantown"
  },
  {
    "value": "46058",
    "label": "Mulberry - IN 46058",
    "county": "Clinton County",
    "state": "IN",
    "city": "Mulberry"
  },
  {
    "value": "46060",
    "label": "Noblesville - IN 46060",
    "county": "Hamilton County",
    "state": "IN",
    "city": "Noblesville"
  },
  {
    "value": "46062",
    "label": "Noblesville - IN 46062",
    "county": "Hamilton County",
    "state": "IN",
    "city": "Noblesville"
  },
  {
    "value": "46064",
    "label": "Pendleton - IN 46064",
    "county": "Madison County",
    "state": "IN",
    "city": "Pendleton"
  },
  {
    "value": "46065",
    "label": "Rossville - IN 46065",
    "county": "Clinton County",
    "state": "IN",
    "city": "Rossville"
  },
  {
    "value": "46068",
    "label": "Sharpsville - IN 46068",
    "county": "Tipton County",
    "state": "IN",
    "city": "Sharpsville"
  },
  {
    "value": "46069",
    "label": "Sheridan - IN 46069",
    "county": "Hamilton County",
    "state": "IN",
    "city": "Sheridan"
  },
  {
    "value": "46070",
    "label": "Summitville - IN 46070",
    "county": "Madison County",
    "state": "IN",
    "city": "Summitville"
  },
  {
    "value": "46071",
    "label": "Thorntown - IN 46071",
    "county": "Boone County",
    "state": "IN",
    "city": "Thorntown"
  },
  {
    "value": "46072",
    "label": "Tipton - IN 46072",
    "county": "Tipton County",
    "state": "IN",
    "city": "Tipton"
  },
  {
    "value": "46074",
    "label": "Westfield - IN 46074",
    "county": "Hamilton County",
    "state": "IN",
    "city": "Westfield"
  },
  {
    "value": "46075",
    "label": "Whitestown - IN 46075",
    "county": "Boone County",
    "state": "IN",
    "city": "Whitestown"
  },
  {
    "value": "46076",
    "label": "Windfall - IN 46076",
    "county": "Tipton County",
    "state": "IN",
    "city": "Windfall"
  },
  {
    "value": "46077",
    "label": "Zionsville - IN 46077",
    "county": "Boone County",
    "state": "IN",
    "city": "Zionsville"
  },
  {
    "value": "46104",
    "label": "Arlington - IN 46104",
    "county": "Rush County",
    "state": "IN",
    "city": "Arlington"
  },
  {
    "value": "46105",
    "label": "Bainbridge - IN 46105",
    "county": "Putnam County",
    "state": "IN",
    "city": "Bainbridge"
  },
  {
    "value": "46106",
    "label": "Bargersville - IN 46106",
    "county": "Johnson County",
    "state": "IN",
    "city": "Bargersville"
  },
  {
    "value": "46107",
    "label": "Beech Grove - IN 46107",
    "county": "Marion County",
    "state": "IN",
    "city": "Beech Grove"
  },
  {
    "value": "46110",
    "label": "Boggstown - IN 46110",
    "county": "Shelby County",
    "state": "IN",
    "city": "Boggstown"
  },
  {
    "value": "46112",
    "label": "Brownsburg - IN 46112",
    "county": "Hendricks County",
    "state": "IN",
    "city": "Brownsburg"
  },
  {
    "value": "46113",
    "label": "Camby - IN 46113",
    "county": "Marion County",
    "state": "IN",
    "city": "Camby"
  },
  {
    "value": "46115",
    "label": "Carthage - IN 46115",
    "county": "Rush County",
    "state": "IN",
    "city": "Carthage"
  },
  {
    "value": "46117",
    "label": "Charlottesville - IN 46117",
    "county": "Hancock County",
    "state": "IN",
    "city": "Charlottesville"
  },
  {
    "value": "46118",
    "label": "Clayton - IN 46118",
    "county": "Hendricks County",
    "state": "IN",
    "city": "Clayton"
  },
  {
    "value": "46120",
    "label": "Cloverdale - IN 46120",
    "county": "Putnam County",
    "state": "IN",
    "city": "Cloverdale"
  },
  {
    "value": "46121",
    "label": "Coatesville - IN 46121",
    "county": "Putnam County",
    "state": "IN",
    "city": "Coatesville"
  },
  {
    "value": "46122",
    "label": "Danville - IN 46122",
    "county": "Hendricks County",
    "state": "IN",
    "city": "Danville"
  },
  {
    "value": "46123",
    "label": "Avon - IN 46123",
    "county": "Hendricks County",
    "state": "IN",
    "city": "Avon"
  },
  {
    "value": "46124",
    "label": "Edinburgh - IN 46124",
    "county": "Johnson County",
    "state": "IN",
    "city": "Edinburgh"
  },
  {
    "value": "46126",
    "label": "Fairland - IN 46126",
    "county": "Shelby County",
    "state": "IN",
    "city": "Fairland"
  },
  {
    "value": "46127",
    "label": "Falmouth - IN 46127",
    "county": "Rush County",
    "state": "IN",
    "city": "Falmouth"
  },
  {
    "value": "46128",
    "label": "Fillmore - IN 46128",
    "county": "Putnam County",
    "state": "IN",
    "city": "Fillmore"
  },
  {
    "value": "46130",
    "label": "Fountaintown - IN 46130",
    "county": "Shelby County",
    "state": "IN",
    "city": "Fountaintown"
  },
  {
    "value": "46131",
    "label": "Franklin - IN 46131",
    "county": "Johnson County",
    "state": "IN",
    "city": "Franklin"
  },
  {
    "value": "46133",
    "label": "Glenwood - IN 46133",
    "county": "Fayette County",
    "state": "IN",
    "city": "Glenwood"
  },
  {
    "value": "46135",
    "label": "Greencastle - IN 46135",
    "county": "Putnam County",
    "state": "IN",
    "city": "Greencastle"
  },
  {
    "value": "46140",
    "label": "Greenfield - IN 46140",
    "county": "Hancock County",
    "state": "IN",
    "city": "Greenfield"
  },
  {
    "value": "46142",
    "label": "Greenwood - IN 46142",
    "county": "Johnson County",
    "state": "IN",
    "city": "Greenwood"
  },
  {
    "value": "46143",
    "label": "Greenwood - IN 46143",
    "county": "Johnson County",
    "state": "IN",
    "city": "Greenwood"
  },
  {
    "value": "46146",
    "label": "Homer - IN 46146",
    "county": "Rush County",
    "state": "IN",
    "city": "Homer"
  },
  {
    "value": "46147",
    "label": "Jamestown - IN 46147",
    "county": "Boone County",
    "state": "IN",
    "city": "Jamestown"
  },
  {
    "value": "46148",
    "label": "Knightstown - IN 46148",
    "county": "Henry County",
    "state": "IN",
    "city": "Knightstown"
  },
  {
    "value": "46149",
    "label": "Lizton - IN 46149",
    "county": "Hendricks County",
    "state": "IN",
    "city": "Lizton"
  },
  {
    "value": "46150",
    "label": "Manilla - IN 46150",
    "county": "Rush County",
    "state": "IN",
    "city": "Manilla"
  },
  {
    "value": "46151",
    "label": "Martinsville - IN 46151",
    "county": "Morgan County",
    "state": "IN",
    "city": "Martinsville"
  },
  {
    "value": "46156",
    "label": "Milroy - IN 46156",
    "county": "Rush County",
    "state": "IN",
    "city": "Milroy"
  },
  {
    "value": "46157",
    "label": "Monrovia - IN 46157",
    "county": "Morgan County",
    "state": "IN",
    "city": "Monrovia"
  },
  {
    "value": "46158",
    "label": "Mooresville - IN 46158",
    "county": "Morgan County",
    "state": "IN",
    "city": "Mooresville"
  },
  {
    "value": "46160",
    "label": "Morgantown - IN 46160",
    "county": "Brown County",
    "state": "IN",
    "city": "Morgantown"
  },
  {
    "value": "46161",
    "label": "Morristown - IN 46161",
    "county": "Shelby County",
    "state": "IN",
    "city": "Morristown"
  },
  {
    "value": "46162",
    "label": "Needham - IN 46162",
    "county": "Johnson County",
    "state": "IN",
    "city": "Needham"
  },
  {
    "value": "46163",
    "label": "New Palestine - IN 46163",
    "county": "Hancock County",
    "state": "IN",
    "city": "New Palestine"
  },
  {
    "value": "46164",
    "label": "Nineveh - IN 46164",
    "county": "Johnson County",
    "state": "IN",
    "city": "Nineveh"
  },
  {
    "value": "46165",
    "label": "North Salem - IN 46165",
    "county": "Hendricks County",
    "state": "IN",
    "city": "North Salem"
  },
  {
    "value": "46166",
    "label": "Paragon - IN 46166",
    "county": "Morgan County",
    "state": "IN",
    "city": "Paragon"
  },
  {
    "value": "46167",
    "label": "Pittsboro - IN 46167",
    "county": "Hendricks County",
    "state": "IN",
    "city": "Pittsboro"
  },
  {
    "value": "46168",
    "label": "Plainfield - IN 46168",
    "county": "Hendricks County",
    "state": "IN",
    "city": "Plainfield"
  },
  {
    "value": "46170",
    "label": "Putnamville - IN 46170",
    "county": "Putnam County",
    "state": "IN",
    "city": "Putnamville"
  },
  {
    "value": "46171",
    "label": "Reelsville - IN 46171",
    "county": "Putnam County",
    "state": "IN",
    "city": "Reelsville"
  },
  {
    "value": "46172",
    "label": "Roachdale - IN 46172",
    "county": "Putnam County",
    "state": "IN",
    "city": "Roachdale"
  },
  {
    "value": "46173",
    "label": "Rushville - IN 46173",
    "county": "Rush County",
    "state": "IN",
    "city": "Rushville"
  },
  {
    "value": "46175",
    "label": "Russellville - IN 46175",
    "county": "Putnam County",
    "state": "IN",
    "city": "Russellville"
  },
  {
    "value": "46176",
    "label": "Shelbyville - IN 46176",
    "county": "Shelby County",
    "state": "IN",
    "city": "Shelbyville"
  },
  {
    "value": "46180",
    "label": "Stilesville - IN 46180",
    "county": "Hendricks County",
    "state": "IN",
    "city": "Stilesville"
  },
  {
    "value": "46181",
    "label": "Trafalgar - IN 46181",
    "county": "Johnson County",
    "state": "IN",
    "city": "Trafalgar"
  },
  {
    "value": "46182",
    "label": "Waldron - IN 46182",
    "county": "Shelby County",
    "state": "IN",
    "city": "Waldron"
  },
  {
    "value": "46184",
    "label": "Whiteland - IN 46184",
    "county": "Johnson County",
    "state": "IN",
    "city": "Whiteland"
  },
  {
    "value": "46186",
    "label": "Wilkinson - IN 46186",
    "county": "Hancock County",
    "state": "IN",
    "city": "Wilkinson"
  },
  {
    "value": "46201",
    "label": "Indianapolis - IN 46201",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46202",
    "label": "Indianapolis - IN 46202",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46203",
    "label": "Indianapolis - IN 46203",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46204",
    "label": "Indianapolis - IN 46204",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46205",
    "label": "Indianapolis - IN 46205",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46208",
    "label": "Indianapolis - IN 46208",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46214",
    "label": "Indianapolis - IN 46214",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46216",
    "label": "Indianapolis - IN 46216",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46217",
    "label": "Indianapolis - IN 46217",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46218",
    "label": "Indianapolis - IN 46218",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46219",
    "label": "Indianapolis - IN 46219",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46220",
    "label": "Indianapolis - IN 46220",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46221",
    "label": "Indianapolis - IN 46221",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46222",
    "label": "Indianapolis - IN 46222",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46224",
    "label": "Indianapolis - IN 46224",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46225",
    "label": "Indianapolis - IN 46225",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46226",
    "label": "Indianapolis - IN 46226",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46227",
    "label": "Indianapolis - IN 46227",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46228",
    "label": "Indianapolis - IN 46228",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46229",
    "label": "Indianapolis - IN 46229",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46231",
    "label": "Indianapolis - IN 46231",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46234",
    "label": "Indianapolis - IN 46234",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46235",
    "label": "Indianapolis - IN 46235",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46236",
    "label": "Indianapolis - IN 46236",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46237",
    "label": "Indianapolis - IN 46237",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46239",
    "label": "Indianapolis - IN 46239",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46240",
    "label": "Indianapolis - IN 46240",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46241",
    "label": "Indianapolis - IN 46241",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46250",
    "label": "Indianapolis - IN 46250",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46254",
    "label": "Indianapolis - IN 46254",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46256",
    "label": "Indianapolis - IN 46256",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46259",
    "label": "Indianapolis - IN 46259",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46260",
    "label": "Indianapolis - IN 46260",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46268",
    "label": "Indianapolis - IN 46268",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46278",
    "label": "Indianapolis - IN 46278",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46280",
    "label": "Indianapolis - IN 46280",
    "county": "Hamilton County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46282",
    "label": "Indianapolis - IN 46282",
    "county": "Marion County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46290",
    "label": "Indianapolis - IN 46290",
    "county": "Hamilton County",
    "state": "IN",
    "city": "Indianapolis"
  },
  {
    "value": "46303",
    "label": "Cedar Lake - IN 46303",
    "county": "Lake County",
    "state": "IN",
    "city": "Cedar Lake"
  },
  {
    "value": "46304",
    "label": "Chesterton - IN 46304",
    "county": "Porter County",
    "state": "IN",
    "city": "Chesterton"
  },
  {
    "value": "46307",
    "label": "Crown Point - IN 46307",
    "county": "Lake County",
    "state": "IN",
    "city": "Crown Point"
  },
  {
    "value": "46310",
    "label": "Demotte - IN 46310",
    "county": "Jasper County",
    "state": "IN",
    "city": "Demotte"
  },
  {
    "value": "46311",
    "label": "Dyer - IN 46311",
    "county": "Lake County",
    "state": "IN",
    "city": "Dyer"
  },
  {
    "value": "46312",
    "label": "East Chicago - IN 46312",
    "county": "Lake County",
    "state": "IN",
    "city": "East Chicago"
  },
  {
    "value": "46319",
    "label": "Griffith - IN 46319",
    "county": "Lake County",
    "state": "IN",
    "city": "Griffith"
  },
  {
    "value": "46320",
    "label": "Hammond - IN 46320",
    "county": "Lake County",
    "state": "IN",
    "city": "Hammond"
  },
  {
    "value": "46321",
    "label": "Munster - IN 46321",
    "county": "Lake County",
    "state": "IN",
    "city": "Munster"
  },
  {
    "value": "46322",
    "label": "Highland - IN 46322",
    "county": "Lake County",
    "state": "IN",
    "city": "Highland"
  },
  {
    "value": "46323",
    "label": "Hammond - IN 46323",
    "county": "Lake County",
    "state": "IN",
    "city": "Hammond"
  },
  {
    "value": "46324",
    "label": "Hammond - IN 46324",
    "county": "Lake County",
    "state": "IN",
    "city": "Hammond"
  },
  {
    "value": "46327",
    "label": "Hammond - IN 46327",
    "county": "Lake County",
    "state": "IN",
    "city": "Hammond"
  },
  {
    "value": "46340",
    "label": "Hanna - IN 46340",
    "county": "LaPorte County",
    "state": "IN",
    "city": "Hanna"
  },
  {
    "value": "46341",
    "label": "Hebron - IN 46341",
    "county": "Porter County",
    "state": "IN",
    "city": "Hebron"
  },
  {
    "value": "46342",
    "label": "Hobart - IN 46342",
    "county": "Lake County",
    "state": "IN",
    "city": "Hobart"
  },
  {
    "value": "46347",
    "label": "Kouts - IN 46347",
    "county": "Porter County",
    "state": "IN",
    "city": "Kouts"
  },
  {
    "value": "46348",
    "label": "La Crosse - IN 46348",
    "county": "LaPorte County",
    "state": "IN",
    "city": "La Crosse"
  },
  {
    "value": "46349",
    "label": "Lake Village - IN 46349",
    "county": "Newton County",
    "state": "IN",
    "city": "Lake Village"
  },
  {
    "value": "46350",
    "label": "La Porte - IN 46350",
    "county": "LaPorte County",
    "state": "IN",
    "city": "La Porte"
  },
  {
    "value": "46356",
    "label": "Lowell - IN 46356",
    "county": "Lake County",
    "state": "IN",
    "city": "Lowell"
  },
  {
    "value": "46360",
    "label": "Michigan City - IN 46360",
    "county": "LaPorte County",
    "state": "IN",
    "city": "Michigan City"
  },
  {
    "value": "46365",
    "label": "Mill Creek - IN 46365",
    "county": "LaPorte County",
    "state": "IN",
    "city": "Mill Creek"
  },
  {
    "value": "46366",
    "label": "North Judson - IN 46366",
    "county": "Starke County",
    "state": "IN",
    "city": "North Judson"
  },
  {
    "value": "46368",
    "label": "Portage - IN 46368",
    "county": "Porter County",
    "state": "IN",
    "city": "Portage"
  },
  {
    "value": "46371",
    "label": "Rolling Prairie - IN 46371",
    "county": "LaPorte County",
    "state": "IN",
    "city": "Rolling Prairie"
  },
  {
    "value": "46373",
    "label": "Saint John - IN 46373",
    "county": "Lake County",
    "state": "IN",
    "city": "Saint John"
  },
  {
    "value": "46374",
    "label": "San Pierre - IN 46374",
    "county": "Starke County",
    "state": "IN",
    "city": "San Pierre"
  },
  {
    "value": "46375",
    "label": "Schererville - IN 46375",
    "county": "Lake County",
    "state": "IN",
    "city": "Schererville"
  },
  {
    "value": "46381",
    "label": "Thayer - IN 46381",
    "county": "Newton County",
    "state": "IN",
    "city": "Thayer"
  },
  {
    "value": "46382",
    "label": "Union Mills - IN 46382",
    "county": "LaPorte County",
    "state": "IN",
    "city": "Union Mills"
  },
  {
    "value": "46383",
    "label": "Valparaiso - IN 46383",
    "county": "Porter County",
    "state": "IN",
    "city": "Valparaiso"
  },
  {
    "value": "46385",
    "label": "Valparaiso - IN 46385",
    "county": "Porter County",
    "state": "IN",
    "city": "Valparaiso"
  },
  {
    "value": "46390",
    "label": "Wanatah - IN 46390",
    "county": "LaPorte County",
    "state": "IN",
    "city": "Wanatah"
  },
  {
    "value": "46391",
    "label": "Westville - IN 46391",
    "county": "LaPorte County",
    "state": "IN",
    "city": "Westville"
  },
  {
    "value": "46392",
    "label": "Wheatfield - IN 46392",
    "county": "Jasper County",
    "state": "IN",
    "city": "Wheatfield"
  },
  {
    "value": "46394",
    "label": "Whiting - IN 46394",
    "county": "Lake County",
    "state": "IN",
    "city": "Whiting"
  },
  {
    "value": "46402",
    "label": "Gary - IN 46402",
    "county": "Lake County",
    "state": "IN",
    "city": "Gary"
  },
  {
    "value": "46403",
    "label": "Gary - IN 46403",
    "county": "Lake County",
    "state": "IN",
    "city": "Gary"
  },
  {
    "value": "46404",
    "label": "Gary - IN 46404",
    "county": "Lake County",
    "state": "IN",
    "city": "Gary"
  },
  {
    "value": "46405",
    "label": "Lake Station - IN 46405",
    "county": "Lake County",
    "state": "IN",
    "city": "Lake Station"
  },
  {
    "value": "46406",
    "label": "Gary - IN 46406",
    "county": "Lake County",
    "state": "IN",
    "city": "Gary"
  },
  {
    "value": "46407",
    "label": "Gary - IN 46407",
    "county": "Lake County",
    "state": "IN",
    "city": "Gary"
  },
  {
    "value": "46408",
    "label": "Gary - IN 46408",
    "county": "Lake County",
    "state": "IN",
    "city": "Gary"
  },
  {
    "value": "46409",
    "label": "Gary - IN 46409",
    "county": "Lake County",
    "state": "IN",
    "city": "Gary"
  },
  {
    "value": "46410",
    "label": "Merrillville - IN 46410",
    "county": "Lake County",
    "state": "IN",
    "city": "Merrillville"
  },
  {
    "value": "46501",
    "label": "Argos - IN 46501",
    "county": "Marshall County",
    "state": "IN",
    "city": "Argos"
  },
  {
    "value": "46504",
    "label": "Bourbon - IN 46504",
    "county": "Marshall County",
    "state": "IN",
    "city": "Bourbon"
  },
  {
    "value": "46506",
    "label": "Bremen - IN 46506",
    "county": "Marshall County",
    "state": "IN",
    "city": "Bremen"
  },
  {
    "value": "46507",
    "label": "Bristol - IN 46507",
    "county": "Elkhart County",
    "state": "IN",
    "city": "Bristol"
  },
  {
    "value": "46508",
    "label": "Burket - IN 46508",
    "county": "Kosciusko County",
    "state": "IN",
    "city": "Burket"
  },
  {
    "value": "46510",
    "label": "Claypool - IN 46510",
    "county": "Kosciusko County",
    "state": "IN",
    "city": "Claypool"
  },
  {
    "value": "46511",
    "label": "Culver - IN 46511",
    "county": "Marshall County",
    "state": "IN",
    "city": "Culver"
  },
  {
    "value": "46514",
    "label": "Elkhart - IN 46514",
    "county": "Elkhart County",
    "state": "IN",
    "city": "Elkhart"
  },
  {
    "value": "46516",
    "label": "Elkhart - IN 46516",
    "county": "Elkhart County",
    "state": "IN",
    "city": "Elkhart"
  },
  {
    "value": "46517",
    "label": "Elkhart - IN 46517",
    "county": "Elkhart County",
    "state": "IN",
    "city": "Elkhart"
  },
  {
    "value": "46524",
    "label": "Etna Green - IN 46524",
    "county": "Kosciusko County",
    "state": "IN",
    "city": "Etna Green"
  },
  {
    "value": "46526",
    "label": "Goshen - IN 46526",
    "county": "Elkhart County",
    "state": "IN",
    "city": "Goshen"
  },
  {
    "value": "46528",
    "label": "Goshen - IN 46528",
    "county": "Elkhart County",
    "state": "IN",
    "city": "Goshen"
  },
  {
    "value": "46530",
    "label": "Granger - IN 46530",
    "county": "St. Joseph County",
    "state": "IN",
    "city": "Granger"
  },
  {
    "value": "46531",
    "label": "Grovertown - IN 46531",
    "county": "Starke County",
    "state": "IN",
    "city": "Grovertown"
  },
  {
    "value": "46532",
    "label": "Hamlet - IN 46532",
    "county": "Starke County",
    "state": "IN",
    "city": "Hamlet"
  },
  {
    "value": "46534",
    "label": "Knox - IN 46534",
    "county": "Starke County",
    "state": "IN",
    "city": "Knox"
  },
  {
    "value": "46536",
    "label": "Lakeville - IN 46536",
    "county": "St. Joseph County",
    "state": "IN",
    "city": "Lakeville"
  },
  {
    "value": "46538",
    "label": "Leesburg - IN 46538",
    "county": "Kosciusko County",
    "state": "IN",
    "city": "Leesburg"
  },
  {
    "value": "46539",
    "label": "Mentone - IN 46539",
    "county": "Kosciusko County",
    "state": "IN",
    "city": "Mentone"
  },
  {
    "value": "46540",
    "label": "Middlebury - IN 46540",
    "county": "Elkhart County",
    "state": "IN",
    "city": "Middlebury"
  },
  {
    "value": "46542",
    "label": "Milford - IN 46542",
    "county": "Kosciusko County",
    "state": "IN",
    "city": "Milford"
  },
  {
    "value": "46543",
    "label": "Millersburg - IN 46543",
    "county": "Elkhart County",
    "state": "IN",
    "city": "Millersburg"
  },
  {
    "value": "46544",
    "label": "Mishawaka - IN 46544",
    "county": "St. Joseph County",
    "state": "IN",
    "city": "Mishawaka"
  },
  {
    "value": "46545",
    "label": "Mishawaka - IN 46545",
    "county": "St. Joseph County",
    "state": "IN",
    "city": "Mishawaka"
  },
  {
    "value": "46550",
    "label": "Nappanee - IN 46550",
    "county": "Elkhart County",
    "state": "IN",
    "city": "Nappanee"
  },
  {
    "value": "46552",
    "label": "New Carlisle - IN 46552",
    "county": "St. Joseph County",
    "state": "IN",
    "city": "New Carlisle"
  },
  {
    "value": "46553",
    "label": "New Paris - IN 46553",
    "county": "Elkhart County",
    "state": "IN",
    "city": "New Paris"
  },
  {
    "value": "46554",
    "label": "North Liberty - IN 46554",
    "county": "St. Joseph County",
    "state": "IN",
    "city": "North Liberty"
  },
  {
    "value": "46555",
    "label": "North Webster - IN 46555",
    "county": "Kosciusko County",
    "state": "IN",
    "city": "North Webster"
  },
  {
    "value": "46561",
    "label": "Osceola - IN 46561",
    "county": "St. Joseph County",
    "state": "IN",
    "city": "Osceola"
  },
  {
    "value": "46562",
    "label": "Pierceton - IN 46562",
    "county": "Kosciusko County",
    "state": "IN",
    "city": "Pierceton"
  },
  {
    "value": "46563",
    "label": "Plymouth - IN 46563",
    "county": "Marshall County",
    "state": "IN",
    "city": "Plymouth"
  },
  {
    "value": "46565",
    "label": "Shipshewana - IN 46565",
    "county": "LaGrange County",
    "state": "IN",
    "city": "Shipshewana"
  },
  {
    "value": "46567",
    "label": "Syracuse - IN 46567",
    "county": "Kosciusko County",
    "state": "IN",
    "city": "Syracuse"
  },
  {
    "value": "46570",
    "label": "Tippecanoe - IN 46570",
    "county": "Marshall County",
    "state": "IN",
    "city": "Tippecanoe"
  },
  {
    "value": "46571",
    "label": "Topeka - IN 46571",
    "county": "LaGrange County",
    "state": "IN",
    "city": "Topeka"
  },
  {
    "value": "46572",
    "label": "Tyner - IN 46572",
    "county": "Marshall County",
    "state": "IN",
    "city": "Tyner"
  },
  {
    "value": "46573",
    "label": "Wakarusa - IN 46573",
    "county": "Elkhart County",
    "state": "IN",
    "city": "Wakarusa"
  },
  {
    "value": "46574",
    "label": "Walkerton - IN 46574",
    "county": "St. Joseph County",
    "state": "IN",
    "city": "Walkerton"
  },
  {
    "value": "46580",
    "label": "Warsaw - IN 46580",
    "county": "Kosciusko County",
    "state": "IN",
    "city": "Warsaw"
  },
  {
    "value": "46582",
    "label": "Warsaw - IN 46582",
    "county": "Kosciusko County",
    "state": "IN",
    "city": "Warsaw"
  },
  {
    "value": "46590",
    "label": "Winona Lake - IN 46590",
    "county": "Kosciusko County",
    "state": "IN",
    "city": "Winona Lake"
  },
  {
    "value": "46601",
    "label": "South Bend - IN 46601",
    "county": "St. Joseph County",
    "state": "IN",
    "city": "South Bend"
  },
  {
    "value": "46613",
    "label": "South Bend - IN 46613",
    "county": "St. Joseph County",
    "state": "IN",
    "city": "South Bend"
  },
  {
    "value": "46614",
    "label": "South Bend - IN 46614",
    "county": "St. Joseph County",
    "state": "IN",
    "city": "South Bend"
  },
  {
    "value": "46615",
    "label": "South Bend - IN 46615",
    "county": "St. Joseph County",
    "state": "IN",
    "city": "South Bend"
  },
  {
    "value": "46616",
    "label": "South Bend - IN 46616",
    "county": "St. Joseph County",
    "state": "IN",
    "city": "South Bend"
  },
  {
    "value": "46617",
    "label": "South Bend - IN 46617",
    "county": "St. Joseph County",
    "state": "IN",
    "city": "South Bend"
  },
  {
    "value": "46619",
    "label": "South Bend - IN 46619",
    "county": "St. Joseph County",
    "state": "IN",
    "city": "South Bend"
  },
  {
    "value": "46628",
    "label": "South Bend - IN 46628",
    "county": "St. Joseph County",
    "state": "IN",
    "city": "South Bend"
  },
  {
    "value": "46635",
    "label": "South Bend - IN 46635",
    "county": "St. Joseph County",
    "state": "IN",
    "city": "South Bend"
  },
  {
    "value": "46637",
    "label": "South Bend - IN 46637",
    "county": "St. Joseph County",
    "state": "IN",
    "city": "South Bend"
  },
  {
    "value": "46701",
    "label": "Albion - IN 46701",
    "county": "Noble County",
    "state": "IN",
    "city": "Albion"
  },
  {
    "value": "46702",
    "label": "Andrews - IN 46702",
    "county": "Huntington County",
    "state": "IN",
    "city": "Andrews"
  },
  {
    "value": "46703",
    "label": "Angola - IN 46703",
    "county": "Steuben County",
    "state": "IN",
    "city": "Angola"
  },
  {
    "value": "46705",
    "label": "Ashley - IN 46705",
    "county": "DeKalb County",
    "state": "IN",
    "city": "Ashley"
  },
  {
    "value": "46706",
    "label": "Auburn - IN 46706",
    "county": "DeKalb County",
    "state": "IN",
    "city": "Auburn"
  },
  {
    "value": "46710",
    "label": "Avilla - IN 46710",
    "county": "Noble County",
    "state": "IN",
    "city": "Avilla"
  },
  {
    "value": "46711",
    "label": "Berne - IN 46711",
    "county": "Adams County",
    "state": "IN",
    "city": "Berne"
  },
  {
    "value": "46714",
    "label": "Bluffton - IN 46714",
    "county": "Wells County",
    "state": "IN",
    "city": "Bluffton"
  },
  {
    "value": "46721",
    "label": "Butler - IN 46721",
    "county": "DeKalb County",
    "state": "IN",
    "city": "Butler"
  },
  {
    "value": "46723",
    "label": "Churubusco - IN 46723",
    "county": "Whitley County",
    "state": "IN",
    "city": "Churubusco"
  },
  {
    "value": "46725",
    "label": "Columbia City - IN 46725",
    "county": "Whitley County",
    "state": "IN",
    "city": "Columbia City"
  },
  {
    "value": "46730",
    "label": "Corunna - IN 46730",
    "county": "DeKalb County",
    "state": "IN",
    "city": "Corunna"
  },
  {
    "value": "46731",
    "label": "Craigville - IN 46731",
    "county": "Wells County",
    "state": "IN",
    "city": "Craigville"
  },
  {
    "value": "46732",
    "label": "Cromwell - IN 46732",
    "county": "Noble County",
    "state": "IN",
    "city": "Cromwell"
  },
  {
    "value": "46733",
    "label": "Decatur - IN 46733",
    "county": "Adams County",
    "state": "IN",
    "city": "Decatur"
  },
  {
    "value": "46737",
    "label": "Fremont - IN 46737",
    "county": "Steuben County",
    "state": "IN",
    "city": "Fremont"
  },
  {
    "value": "46738",
    "label": "Garrett - IN 46738",
    "county": "DeKalb County",
    "state": "IN",
    "city": "Garrett"
  },
  {
    "value": "46740",
    "label": "Geneva - IN 46740",
    "county": "Adams County",
    "state": "IN",
    "city": "Geneva"
  },
  {
    "value": "46741",
    "label": "Grabill - IN 46741",
    "county": "Allen County",
    "state": "IN",
    "city": "Grabill"
  },
  {
    "value": "46742",
    "label": "Hamilton - IN 46742",
    "county": "Steuben County",
    "state": "IN",
    "city": "Hamilton"
  },
  {
    "value": "46743",
    "label": "Harlan - IN 46743",
    "county": "Allen County",
    "state": "IN",
    "city": "Harlan"
  },
  {
    "value": "46745",
    "label": "Hoagland - IN 46745",
    "county": "Allen County",
    "state": "IN",
    "city": "Hoagland"
  },
  {
    "value": "46746",
    "label": "Howe - IN 46746",
    "county": "LaGrange County",
    "state": "IN",
    "city": "Howe"
  },
  {
    "value": "46747",
    "label": "Hudson - IN 46747",
    "county": "Steuben County",
    "state": "IN",
    "city": "Hudson"
  },
  {
    "value": "46748",
    "label": "Huntertown - IN 46748",
    "county": "Allen County",
    "state": "IN",
    "city": "Huntertown"
  },
  {
    "value": "46750",
    "label": "Huntington - IN 46750",
    "county": "Huntington County",
    "state": "IN",
    "city": "Huntington"
  },
  {
    "value": "46755",
    "label": "Kendallville - IN 46755",
    "county": "Noble County",
    "state": "IN",
    "city": "Kendallville"
  },
  {
    "value": "46759",
    "label": "Keystone - IN 46759",
    "county": "Wells County",
    "state": "IN",
    "city": "Keystone"
  },
  {
    "value": "46760",
    "label": "Kimmell - IN 46760",
    "county": "Noble County",
    "state": "IN",
    "city": "Kimmell"
  },
  {
    "value": "46761",
    "label": "Lagrange - IN 46761",
    "county": "LaGrange County",
    "state": "IN",
    "city": "Lagrange"
  },
  {
    "value": "46763",
    "label": "Laotto - IN 46763",
    "county": "Noble County",
    "state": "IN",
    "city": "Laotto"
  },
  {
    "value": "46764",
    "label": "Larwill - IN 46764",
    "county": "Whitley County",
    "state": "IN",
    "city": "Larwill"
  },
  {
    "value": "46765",
    "label": "Leo - IN 46765",
    "county": "Allen County",
    "state": "IN",
    "city": "Leo"
  },
  {
    "value": "46766",
    "label": "Liberty Center - IN 46766",
    "county": "Wells County",
    "state": "IN",
    "city": "Liberty Center"
  },
  {
    "value": "46767",
    "label": "Ligonier - IN 46767",
    "county": "Noble County",
    "state": "IN",
    "city": "Ligonier"
  },
  {
    "value": "46770",
    "label": "Markle - IN 46770",
    "county": "Wells County",
    "state": "IN",
    "city": "Markle"
  },
  {
    "value": "46772",
    "label": "Monroe - IN 46772",
    "county": "Adams County",
    "state": "IN",
    "city": "Monroe"
  },
  {
    "value": "46773",
    "label": "Monroeville - IN 46773",
    "county": "Allen County",
    "state": "IN",
    "city": "Monroeville"
  },
  {
    "value": "46774",
    "label": "New Haven - IN 46774",
    "county": "Allen County",
    "state": "IN",
    "city": "New Haven"
  },
  {
    "value": "46776",
    "label": "Orland - IN 46776",
    "county": "Steuben County",
    "state": "IN",
    "city": "Orland"
  },
  {
    "value": "46777",
    "label": "Ossian - IN 46777",
    "county": "Wells County",
    "state": "IN",
    "city": "Ossian"
  },
  {
    "value": "46779",
    "label": "Pleasant Lake - IN 46779",
    "county": "Steuben County",
    "state": "IN",
    "city": "Pleasant Lake"
  },
  {
    "value": "46781",
    "label": "Poneto - IN 46781",
    "county": "Wells County",
    "state": "IN",
    "city": "Poneto"
  },
  {
    "value": "46783",
    "label": "Roanoke - IN 46783",
    "county": "Huntington County",
    "state": "IN",
    "city": "Roanoke"
  },
  {
    "value": "46784",
    "label": "Rome City - IN 46784",
    "county": "Noble County",
    "state": "IN",
    "city": "Rome City"
  },
  {
    "value": "46785",
    "label": "Saint Joe - IN 46785",
    "county": "DeKalb County",
    "state": "IN",
    "city": "Saint Joe"
  },
  {
    "value": "46787",
    "label": "South Whitley - IN 46787",
    "county": "Whitley County",
    "state": "IN",
    "city": "South Whitley"
  },
  {
    "value": "46788",
    "label": "Spencerville - IN 46788",
    "county": "Allen County",
    "state": "IN",
    "city": "Spencerville"
  },
  {
    "value": "46791",
    "label": "Uniondale - IN 46791",
    "county": "Wells County",
    "state": "IN",
    "city": "Uniondale"
  },
  {
    "value": "46792",
    "label": "Warren - IN 46792",
    "county": "Huntington County",
    "state": "IN",
    "city": "Warren"
  },
  {
    "value": "46793",
    "label": "Waterloo - IN 46793",
    "county": "DeKalb County",
    "state": "IN",
    "city": "Waterloo"
  },
  {
    "value": "46794",
    "label": "Wawaka - IN 46794",
    "county": "Noble County",
    "state": "IN",
    "city": "Wawaka"
  },
  {
    "value": "46795",
    "label": "Wolcottville - IN 46795",
    "county": "LaGrange County",
    "state": "IN",
    "city": "Wolcottville"
  },
  {
    "value": "46797",
    "label": "Woodburn - IN 46797",
    "county": "Allen County",
    "state": "IN",
    "city": "Woodburn"
  },
  {
    "value": "46798",
    "label": "Yoder - IN 46798",
    "county": "Allen County",
    "state": "IN",
    "city": "Yoder"
  },
  {
    "value": "46802",
    "label": "Fort Wayne - IN 46802",
    "county": "Allen County",
    "state": "IN",
    "city": "Fort Wayne"
  },
  {
    "value": "46803",
    "label": "Fort Wayne - IN 46803",
    "county": "Allen County",
    "state": "IN",
    "city": "Fort Wayne"
  },
  {
    "value": "46804",
    "label": "Fort Wayne - IN 46804",
    "county": "Allen County",
    "state": "IN",
    "city": "Fort Wayne"
  },
  {
    "value": "46805",
    "label": "Fort Wayne - IN 46805",
    "county": "Allen County",
    "state": "IN",
    "city": "Fort Wayne"
  },
  {
    "value": "46806",
    "label": "Fort Wayne - IN 46806",
    "county": "Allen County",
    "state": "IN",
    "city": "Fort Wayne"
  },
  {
    "value": "46807",
    "label": "Fort Wayne - IN 46807",
    "county": "Allen County",
    "state": "IN",
    "city": "Fort Wayne"
  },
  {
    "value": "46808",
    "label": "Fort Wayne - IN 46808",
    "county": "Allen County",
    "state": "IN",
    "city": "Fort Wayne"
  },
  {
    "value": "46809",
    "label": "Fort Wayne - IN 46809",
    "county": "Allen County",
    "state": "IN",
    "city": "Fort Wayne"
  },
  {
    "value": "46814",
    "label": "Fort Wayne - IN 46814",
    "county": "Allen County",
    "state": "IN",
    "city": "Fort Wayne"
  },
  {
    "value": "46815",
    "label": "Fort Wayne - IN 46815",
    "county": "Allen County",
    "state": "IN",
    "city": "Fort Wayne"
  },
  {
    "value": "46816",
    "label": "Fort Wayne - IN 46816",
    "county": "Allen County",
    "state": "IN",
    "city": "Fort Wayne"
  },
  {
    "value": "46818",
    "label": "Fort Wayne - IN 46818",
    "county": "Allen County",
    "state": "IN",
    "city": "Fort Wayne"
  },
  {
    "value": "46819",
    "label": "Fort Wayne - IN 46819",
    "county": "Allen County",
    "state": "IN",
    "city": "Fort Wayne"
  },
  {
    "value": "46825",
    "label": "Fort Wayne - IN 46825",
    "county": "Allen County",
    "state": "IN",
    "city": "Fort Wayne"
  },
  {
    "value": "46835",
    "label": "Fort Wayne - IN 46835",
    "county": "Allen County",
    "state": "IN",
    "city": "Fort Wayne"
  },
  {
    "value": "46845",
    "label": "Fort Wayne - IN 46845",
    "county": "Allen County",
    "state": "IN",
    "city": "Fort Wayne"
  },
  {
    "value": "46901",
    "label": "Kokomo - IN 46901",
    "county": "Howard County",
    "state": "IN",
    "city": "Kokomo"
  },
  {
    "value": "46902",
    "label": "Kokomo - IN 46902",
    "county": "Howard County",
    "state": "IN",
    "city": "Kokomo"
  },
  {
    "value": "46910",
    "label": "Akron - IN 46910",
    "county": "Fulton County",
    "state": "IN",
    "city": "Akron"
  },
  {
    "value": "46911",
    "label": "Amboy - IN 46911",
    "county": "Miami County",
    "state": "IN",
    "city": "Amboy"
  },
  {
    "value": "46913",
    "label": "Bringhurst - IN 46913",
    "county": "Carroll County",
    "state": "IN",
    "city": "Bringhurst"
  },
  {
    "value": "46914",
    "label": "Bunker Hill - IN 46914",
    "county": "Miami County",
    "state": "IN",
    "city": "Bunker Hill"
  },
  {
    "value": "46915",
    "label": "Burlington - IN 46915",
    "county": "Carroll County",
    "state": "IN",
    "city": "Burlington"
  },
  {
    "value": "46917",
    "label": "Camden - IN 46917",
    "county": "Carroll County",
    "state": "IN",
    "city": "Camden"
  },
  {
    "value": "46919",
    "label": "Converse - IN 46919",
    "county": "Miami County",
    "state": "IN",
    "city": "Converse"
  },
  {
    "value": "46920",
    "label": "Cutler - IN 46920",
    "county": "Carroll County",
    "state": "IN",
    "city": "Cutler"
  },
  {
    "value": "46923",
    "label": "Delphi - IN 46923",
    "county": "Carroll County",
    "state": "IN",
    "city": "Delphi"
  },
  {
    "value": "46926",
    "label": "Denver - IN 46926",
    "county": "Miami County",
    "state": "IN",
    "city": "Denver"
  },
  {
    "value": "46928",
    "label": "Fairmount - IN 46928",
    "county": "Grant County",
    "state": "IN",
    "city": "Fairmount"
  },
  {
    "value": "46929",
    "label": "Flora - IN 46929",
    "county": "Carroll County",
    "state": "IN",
    "city": "Flora"
  },
  {
    "value": "46930",
    "label": "Fowlerton - IN 46930",
    "county": "Grant County",
    "state": "IN",
    "city": "Fowlerton"
  },
  {
    "value": "46932",
    "label": "Galveston - IN 46932",
    "county": "Cass County",
    "state": "IN",
    "city": "Galveston"
  },
  {
    "value": "46933",
    "label": "Gas City - IN 46933",
    "county": "Grant County",
    "state": "IN",
    "city": "Gas City"
  },
  {
    "value": "46936",
    "label": "Greentown - IN 46936",
    "county": "Howard County",
    "state": "IN",
    "city": "Greentown"
  },
  {
    "value": "46938",
    "label": "Jonesboro - IN 46938",
    "county": "Grant County",
    "state": "IN",
    "city": "Jonesboro"
  },
  {
    "value": "46939",
    "label": "Kewanna - IN 46939",
    "county": "Fulton County",
    "state": "IN",
    "city": "Kewanna"
  },
  {
    "value": "46940",
    "label": "La Fontaine - IN 46940",
    "county": "Wabash County",
    "state": "IN",
    "city": "La Fontaine"
  },
  {
    "value": "46941",
    "label": "Lagro - IN 46941",
    "county": "Wabash County",
    "state": "IN",
    "city": "Lagro"
  },
  {
    "value": "46943",
    "label": "Laketon - IN 46943",
    "county": "Wabash County",
    "state": "IN",
    "city": "Laketon"
  },
  {
    "value": "46947",
    "label": "Logansport - IN 46947",
    "county": "Cass County",
    "state": "IN",
    "city": "Logansport"
  },
  {
    "value": "46950",
    "label": "Lucerne - IN 46950",
    "county": "Cass County",
    "state": "IN",
    "city": "Lucerne"
  },
  {
    "value": "46951",
    "label": "Macy - IN 46951",
    "county": "Miami County",
    "state": "IN",
    "city": "Macy"
  },
  {
    "value": "46952",
    "label": "Marion - IN 46952",
    "county": "Grant County",
    "state": "IN",
    "city": "Marion"
  },
  {
    "value": "46953",
    "label": "Marion - IN 46953",
    "county": "Grant County",
    "state": "IN",
    "city": "Marion"
  },
  {
    "value": "46959",
    "label": "Miami - IN 46959",
    "county": "Miami County",
    "state": "IN",
    "city": "Miami"
  },
  {
    "value": "46960",
    "label": "Monterey - IN 46960",
    "county": "Pulaski County",
    "state": "IN",
    "city": "Monterey"
  },
  {
    "value": "46961",
    "label": "New Waverly - IN 46961",
    "county": "Cass County",
    "state": "IN",
    "city": "New Waverly"
  },
  {
    "value": "46962",
    "label": "North Manchester - IN 46962",
    "county": "Wabash County",
    "state": "IN",
    "city": "North Manchester"
  },
  {
    "value": "46968",
    "label": "Ora - IN 46968",
    "county": "Starke County",
    "state": "IN",
    "city": "Ora"
  },
  {
    "value": "46970",
    "label": "Peru - IN 46970",
    "county": "Miami County",
    "state": "IN",
    "city": "Peru"
  },
  {
    "value": "46971",
    "label": "Grissom Arb - IN 46971",
    "county": "Miami County",
    "state": "IN",
    "city": "Grissom Arb"
  },
  {
    "value": "46974",
    "label": "Roann - IN 46974",
    "county": "Wabash County",
    "state": "IN",
    "city": "Roann"
  },
  {
    "value": "46975",
    "label": "Rochester - IN 46975",
    "county": "Fulton County",
    "state": "IN",
    "city": "Rochester"
  },
  {
    "value": "46977",
    "label": "Rockfield - IN 46977",
    "county": "Carroll County",
    "state": "IN",
    "city": "Rockfield"
  },
  {
    "value": "46978",
    "label": "Royal Center - IN 46978",
    "county": "Cass County",
    "state": "IN",
    "city": "Royal Center"
  },
  {
    "value": "46979",
    "label": "Russiaville - IN 46979",
    "county": "Howard County",
    "state": "IN",
    "city": "Russiaville"
  },
  {
    "value": "46982",
    "label": "Silver Lake - IN 46982",
    "county": "Kosciusko County",
    "state": "IN",
    "city": "Silver Lake"
  },
  {
    "value": "46985",
    "label": "Star City - IN 46985",
    "county": "Pulaski County",
    "state": "IN",
    "city": "Star City"
  },
  {
    "value": "46986",
    "label": "Swayzee - IN 46986",
    "county": "Grant County",
    "state": "IN",
    "city": "Swayzee"
  },
  {
    "value": "46988",
    "label": "Twelve Mile - IN 46988",
    "county": "Cass County",
    "state": "IN",
    "city": "Twelve Mile"
  },
  {
    "value": "46989",
    "label": "Upland - IN 46989",
    "county": "Grant County",
    "state": "IN",
    "city": "Upland"
  },
  {
    "value": "46990",
    "label": "Urbana - IN 46990",
    "county": "Wabash County",
    "state": "IN",
    "city": "Urbana"
  },
  {
    "value": "46991",
    "label": "Van Buren - IN 46991",
    "county": "Grant County",
    "state": "IN",
    "city": "Van Buren"
  },
  {
    "value": "46992",
    "label": "Wabash - IN 46992",
    "county": "Wabash County",
    "state": "IN",
    "city": "Wabash"
  },
  {
    "value": "46994",
    "label": "Walton - IN 46994",
    "county": "Cass County",
    "state": "IN",
    "city": "Walton"
  },
  {
    "value": "46996",
    "label": "Winamac - IN 46996",
    "county": "Pulaski County",
    "state": "IN",
    "city": "Winamac"
  },
  {
    "value": "46998",
    "label": "Young America - IN 46998",
    "county": "Cass County",
    "state": "IN",
    "city": "Young America"
  },
  {
    "value": "47001",
    "label": "Aurora - IN 47001",
    "county": "Dearborn County",
    "state": "IN",
    "city": "Aurora"
  },
  {
    "value": "47003",
    "label": "West College Corner - IN 47003",
    "county": "Union County",
    "state": "IN",
    "city": "West College Corner"
  },
  {
    "value": "47006",
    "label": "Batesville - IN 47006",
    "county": "Ripley County",
    "state": "IN",
    "city": "Batesville"
  },
  {
    "value": "47010",
    "label": "Bath - IN 47010",
    "county": "Franklin County",
    "state": "IN",
    "city": "Bath"
  },
  {
    "value": "47011",
    "label": "Bennington - IN 47011",
    "county": "Switzerland County",
    "state": "IN",
    "city": "Bennington"
  },
  {
    "value": "47012",
    "label": "Brookville - IN 47012",
    "county": "Franklin County",
    "state": "IN",
    "city": "Brookville"
  },
  {
    "value": "47016",
    "label": "Cedar Grove - IN 47016",
    "county": "Franklin County",
    "state": "IN",
    "city": "Cedar Grove"
  },
  {
    "value": "47017",
    "label": "Cross Plains - IN 47017",
    "county": "Ripley County",
    "state": "IN",
    "city": "Cross Plains"
  },
  {
    "value": "47018",
    "label": "Dillsboro - IN 47018",
    "county": "Dearborn County",
    "state": "IN",
    "city": "Dillsboro"
  },
  {
    "value": "47020",
    "label": "Florence - IN 47020",
    "county": "Switzerland County",
    "state": "IN",
    "city": "Florence"
  },
  {
    "value": "47022",
    "label": "Guilford - IN 47022",
    "county": "Dearborn County",
    "state": "IN",
    "city": "Guilford"
  },
  {
    "value": "47023",
    "label": "Holton - IN 47023",
    "county": "Ripley County",
    "state": "IN",
    "city": "Holton"
  },
  {
    "value": "47024",
    "label": "Laurel - IN 47024",
    "county": "Franklin County",
    "state": "IN",
    "city": "Laurel"
  },
  {
    "value": "47025",
    "label": "Lawrenceburg - IN 47025",
    "county": "Dearborn County",
    "state": "IN",
    "city": "Lawrenceburg"
  },
  {
    "value": "47030",
    "label": "Metamora - IN 47030",
    "county": "Franklin County",
    "state": "IN",
    "city": "Metamora"
  },
  {
    "value": "47031",
    "label": "Milan - IN 47031",
    "county": "Ripley County",
    "state": "IN",
    "city": "Milan"
  },
  {
    "value": "47032",
    "label": "Moores Hill - IN 47032",
    "county": "Dearborn County",
    "state": "IN",
    "city": "Moores Hill"
  },
  {
    "value": "47036",
    "label": "Oldenburg - IN 47036",
    "county": "Franklin County",
    "state": "IN",
    "city": "Oldenburg"
  },
  {
    "value": "47037",
    "label": "Osgood - IN 47037",
    "county": "Ripley County",
    "state": "IN",
    "city": "Osgood"
  },
  {
    "value": "47038",
    "label": "Patriot - IN 47038",
    "county": "Switzerland County",
    "state": "IN",
    "city": "Patriot"
  },
  {
    "value": "47040",
    "label": "Rising Sun - IN 47040",
    "county": "Ohio County",
    "state": "IN",
    "city": "Rising Sun"
  },
  {
    "value": "47041",
    "label": "Sunman - IN 47041",
    "county": "Ripley County",
    "state": "IN",
    "city": "Sunman"
  },
  {
    "value": "47042",
    "label": "Versailles - IN 47042",
    "county": "Ripley County",
    "state": "IN",
    "city": "Versailles"
  },
  {
    "value": "47043",
    "label": "Vevay - IN 47043",
    "county": "Switzerland County",
    "state": "IN",
    "city": "Vevay"
  },
  {
    "value": "47060",
    "label": "West Harrison - IN 47060",
    "county": "Dearborn County",
    "state": "IN",
    "city": "West Harrison"
  },
  {
    "value": "47102",
    "label": "Austin - IN 47102",
    "county": "Scott County",
    "state": "IN",
    "city": "Austin"
  },
  {
    "value": "47104",
    "label": "Bethlehem - IN 47104",
    "county": "Clark County",
    "state": "IN",
    "city": "Bethlehem"
  },
  {
    "value": "47106",
    "label": "Borden - IN 47106",
    "county": "Clark County",
    "state": "IN",
    "city": "Borden"
  },
  {
    "value": "47108",
    "label": "Campbellsburg - IN 47108",
    "county": "Washington County",
    "state": "IN",
    "city": "Campbellsburg"
  },
  {
    "value": "47110",
    "label": "Central - IN 47110",
    "county": "Harrison County",
    "state": "IN",
    "city": "Central"
  },
  {
    "value": "47111",
    "label": "Charlestown - IN 47111",
    "county": "Clark County",
    "state": "IN",
    "city": "Charlestown"
  },
  {
    "value": "47112",
    "label": "Corydon - IN 47112",
    "county": "Harrison County",
    "state": "IN",
    "city": "Corydon"
  },
  {
    "value": "47114",
    "label": "Crandall - IN 47114",
    "county": "Harrison County",
    "state": "IN",
    "city": "Crandall"
  },
  {
    "value": "47115",
    "label": "Depauw - IN 47115",
    "county": "Harrison County",
    "state": "IN",
    "city": "Depauw"
  },
  {
    "value": "47116",
    "label": "Eckerty - IN 47116",
    "county": "Crawford County",
    "state": "IN",
    "city": "Eckerty"
  },
  {
    "value": "47117",
    "label": "Elizabeth - IN 47117",
    "county": "Harrison County",
    "state": "IN",
    "city": "Elizabeth"
  },
  {
    "value": "47118",
    "label": "English - IN 47118",
    "county": "Crawford County",
    "state": "IN",
    "city": "English"
  },
  {
    "value": "47119",
    "label": "Floyds Knobs - IN 47119",
    "county": "Floyd County",
    "state": "IN",
    "city": "Floyds Knobs"
  },
  {
    "value": "47120",
    "label": "Fredericksburg - IN 47120",
    "county": "Washington County",
    "state": "IN",
    "city": "Fredericksburg"
  },
  {
    "value": "47122",
    "label": "Georgetown - IN 47122",
    "county": "Floyd County",
    "state": "IN",
    "city": "Georgetown"
  },
  {
    "value": "47123",
    "label": "Grantsburg - IN 47123",
    "county": "Crawford County",
    "state": "IN",
    "city": "Grantsburg"
  },
  {
    "value": "47124",
    "label": "Greenville - IN 47124",
    "county": "Floyd County",
    "state": "IN",
    "city": "Greenville"
  },
  {
    "value": "47125",
    "label": "Hardinsburg - IN 47125",
    "county": "Washington County",
    "state": "IN",
    "city": "Hardinsburg"
  },
  {
    "value": "47126",
    "label": "Henryville - IN 47126",
    "county": "Clark County",
    "state": "IN",
    "city": "Henryville"
  },
  {
    "value": "47129",
    "label": "Clarksville - IN 47129",
    "county": "Clark County",
    "state": "IN",
    "city": "Clarksville"
  },
  {
    "value": "47130",
    "label": "Jeffersonville - IN 47130",
    "county": "Clark County",
    "state": "IN",
    "city": "Jeffersonville"
  },
  {
    "value": "47135",
    "label": "Laconia - IN 47135",
    "county": "Harrison County",
    "state": "IN",
    "city": "Laconia"
  },
  {
    "value": "47136",
    "label": "Lanesville - IN 47136",
    "county": "Harrison County",
    "state": "IN",
    "city": "Lanesville"
  },
  {
    "value": "47137",
    "label": "Leavenworth - IN 47137",
    "county": "Crawford County",
    "state": "IN",
    "city": "Leavenworth"
  },
  {
    "value": "47138",
    "label": "Lexington - IN 47138",
    "county": "Scott County",
    "state": "IN",
    "city": "Lexington"
  },
  {
    "value": "47140",
    "label": "Marengo - IN 47140",
    "county": "Crawford County",
    "state": "IN",
    "city": "Marengo"
  },
  {
    "value": "47141",
    "label": "Marysville - IN 47141",
    "county": "Clark County",
    "state": "IN",
    "city": "Marysville"
  },
  {
    "value": "47142",
    "label": "Mauckport - IN 47142",
    "county": "Harrison County",
    "state": "IN",
    "city": "Mauckport"
  },
  {
    "value": "47143",
    "label": "Memphis - IN 47143",
    "county": "Clark County",
    "state": "IN",
    "city": "Memphis"
  },
  {
    "value": "47145",
    "label": "Milltown - IN 47145",
    "county": "Crawford County",
    "state": "IN",
    "city": "Milltown"
  },
  {
    "value": "47147",
    "label": "Nabb - IN 47147",
    "county": "Clark County",
    "state": "IN",
    "city": "Nabb"
  },
  {
    "value": "47150",
    "label": "New Albany - IN 47150",
    "county": "Floyd County",
    "state": "IN",
    "city": "New Albany"
  },
  {
    "value": "47160",
    "label": "New Middletown - IN 47160",
    "county": "Harrison County",
    "state": "IN",
    "city": "New Middletown"
  },
  {
    "value": "47161",
    "label": "New Salisbury - IN 47161",
    "county": "Harrison County",
    "state": "IN",
    "city": "New Salisbury"
  },
  {
    "value": "47162",
    "label": "New Washington - IN 47162",
    "county": "Clark County",
    "state": "IN",
    "city": "New Washington"
  },
  {
    "value": "47163",
    "label": "Otisco - IN 47163",
    "county": "Clark County",
    "state": "IN",
    "city": "Otisco"
  },
  {
    "value": "47164",
    "label": "Palmyra - IN 47164",
    "county": "Harrison County",
    "state": "IN",
    "city": "Palmyra"
  },
  {
    "value": "47165",
    "label": "Pekin - IN 47165",
    "county": "Washington County",
    "state": "IN",
    "city": "Pekin"
  },
  {
    "value": "47166",
    "label": "Ramsey - IN 47166",
    "county": "Harrison County",
    "state": "IN",
    "city": "Ramsey"
  },
  {
    "value": "47167",
    "label": "Salem - IN 47167",
    "county": "Washington County",
    "state": "IN",
    "city": "Salem"
  },
  {
    "value": "47170",
    "label": "Scottsburg - IN 47170",
    "county": "Scott County",
    "state": "IN",
    "city": "Scottsburg"
  },
  {
    "value": "47172",
    "label": "Sellersburg - IN 47172",
    "county": "Clark County",
    "state": "IN",
    "city": "Sellersburg"
  },
  {
    "value": "47174",
    "label": "Sulphur - IN 47174",
    "county": "Crawford County",
    "state": "IN",
    "city": "Sulphur"
  },
  {
    "value": "47175",
    "label": "Taswell - IN 47175",
    "county": "Crawford County",
    "state": "IN",
    "city": "Taswell"
  },
  {
    "value": "47177",
    "label": "Underwood - IN 47177",
    "county": "Clark County",
    "state": "IN",
    "city": "Underwood"
  },
  {
    "value": "47201",
    "label": "Columbus - IN 47201",
    "county": "Bartholomew County",
    "state": "IN",
    "city": "Columbus"
  },
  {
    "value": "47203",
    "label": "Columbus - IN 47203",
    "county": "Bartholomew County",
    "state": "IN",
    "city": "Columbus"
  },
  {
    "value": "47220",
    "label": "Brownstown - IN 47220",
    "county": "Jackson County",
    "state": "IN",
    "city": "Brownstown"
  },
  {
    "value": "47223",
    "label": "Butlerville - IN 47223",
    "county": "Jennings County",
    "state": "IN",
    "city": "Butlerville"
  },
  {
    "value": "47224",
    "label": "Canaan - IN 47224",
    "county": "Switzerland County",
    "state": "IN",
    "city": "Canaan"
  },
  {
    "value": "47227",
    "label": "Commiskey - IN 47227",
    "county": "Jennings County",
    "state": "IN",
    "city": "Commiskey"
  },
  {
    "value": "47229",
    "label": "Crothersville - IN 47229",
    "county": "Jackson County",
    "state": "IN",
    "city": "Crothersville"
  },
  {
    "value": "47230",
    "label": "Deputy - IN 47230",
    "county": "Jefferson County",
    "state": "IN",
    "city": "Deputy"
  },
  {
    "value": "47231",
    "label": "Dupont - IN 47231",
    "county": "Jefferson County",
    "state": "IN",
    "city": "Dupont"
  },
  {
    "value": "47232",
    "label": "Elizabethtown - IN 47232",
    "county": "Bartholomew County",
    "state": "IN",
    "city": "Elizabethtown"
  },
  {
    "value": "47234",
    "label": "Flat Rock - IN 47234",
    "county": "Shelby County",
    "state": "IN",
    "city": "Flat Rock"
  },
  {
    "value": "47235",
    "label": "Freetown - IN 47235",
    "county": "Jackson County",
    "state": "IN",
    "city": "Freetown"
  },
  {
    "value": "47236",
    "label": "Grammer - IN 47236",
    "county": "Bartholomew County",
    "state": "IN",
    "city": "Grammer"
  },
  {
    "value": "47240",
    "label": "Greensburg - IN 47240",
    "county": "Decatur County",
    "state": "IN",
    "city": "Greensburg"
  },
  {
    "value": "47243",
    "label": "Hanover - IN 47243",
    "county": "Jefferson County",
    "state": "IN",
    "city": "Hanover"
  },
  {
    "value": "47244",
    "label": "Hartsville - IN 47244",
    "county": "Bartholomew County",
    "state": "IN",
    "city": "Hartsville"
  },
  {
    "value": "47246",
    "label": "Hope - IN 47246",
    "county": "Bartholomew County",
    "state": "IN",
    "city": "Hope"
  },
  {
    "value": "47247",
    "label": "Jonesville - IN 47247",
    "county": "Bartholomew County",
    "state": "IN",
    "city": "Jonesville"
  },
  {
    "value": "47250",
    "label": "Madison - IN 47250",
    "county": "Jefferson County",
    "state": "IN",
    "city": "Madison"
  },
  {
    "value": "47260",
    "label": "Medora - IN 47260",
    "county": "Jackson County",
    "state": "IN",
    "city": "Medora"
  },
  {
    "value": "47264",
    "label": "Norman - IN 47264",
    "county": "Jackson County",
    "state": "IN",
    "city": "Norman"
  },
  {
    "value": "47265",
    "label": "North Vernon - IN 47265",
    "county": "Jennings County",
    "state": "IN",
    "city": "North Vernon"
  },
  {
    "value": "47270",
    "label": "Paris Crossing - IN 47270",
    "county": "Jennings County",
    "state": "IN",
    "city": "Paris Crossing"
  },
  {
    "value": "47272",
    "label": "Saint Paul - IN 47272",
    "county": "Decatur County",
    "state": "IN",
    "city": "Saint Paul"
  },
  {
    "value": "47273",
    "label": "Scipio - IN 47273",
    "county": "Jennings County",
    "state": "IN",
    "city": "Scipio"
  },
  {
    "value": "47274",
    "label": "Seymour - IN 47274",
    "county": "Jackson County",
    "state": "IN",
    "city": "Seymour"
  },
  {
    "value": "47281",
    "label": "Vallonia - IN 47281",
    "county": "Jackson County",
    "state": "IN",
    "city": "Vallonia"
  },
  {
    "value": "47283",
    "label": "Westport - IN 47283",
    "county": "Decatur County",
    "state": "IN",
    "city": "Westport"
  },
  {
    "value": "47302",
    "label": "Muncie - IN 47302",
    "county": "Delaware County",
    "state": "IN",
    "city": "Muncie"
  },
  {
    "value": "47303",
    "label": "Muncie - IN 47303",
    "county": "Delaware County",
    "state": "IN",
    "city": "Muncie"
  },
  {
    "value": "47304",
    "label": "Muncie - IN 47304",
    "county": "Delaware County",
    "state": "IN",
    "city": "Muncie"
  },
  {
    "value": "47305",
    "label": "Muncie - IN 47305",
    "county": "Delaware County",
    "state": "IN",
    "city": "Muncie"
  },
  {
    "value": "47320",
    "label": "Albany - IN 47320",
    "county": "Delaware County",
    "state": "IN",
    "city": "Albany"
  },
  {
    "value": "47325",
    "label": "Brownsville - IN 47325",
    "county": "Union County",
    "state": "IN",
    "city": "Brownsville"
  },
  {
    "value": "47326",
    "label": "Bryant - IN 47326",
    "county": "Jay County",
    "state": "IN",
    "city": "Bryant"
  },
  {
    "value": "47327",
    "label": "Cambridge City - IN 47327",
    "county": "Wayne County",
    "state": "IN",
    "city": "Cambridge City"
  },
  {
    "value": "47330",
    "label": "Centerville - IN 47330",
    "county": "Wayne County",
    "state": "IN",
    "city": "Centerville"
  },
  {
    "value": "47331",
    "label": "Connersville - IN 47331",
    "county": "Fayette County",
    "state": "IN",
    "city": "Connersville"
  },
  {
    "value": "47334",
    "label": "Daleville - IN 47334",
    "county": "Delaware County",
    "state": "IN",
    "city": "Daleville"
  },
  {
    "value": "47336",
    "label": "Dunkirk - IN 47336",
    "county": "Jay County",
    "state": "IN",
    "city": "Dunkirk"
  },
  {
    "value": "47338",
    "label": "Eaton - IN 47338",
    "county": "Delaware County",
    "state": "IN",
    "city": "Eaton"
  },
  {
    "value": "47339",
    "label": "Economy - IN 47339",
    "county": "Wayne County",
    "state": "IN",
    "city": "Economy"
  },
  {
    "value": "47340",
    "label": "Farmland - IN 47340",
    "county": "Randolph County",
    "state": "IN",
    "city": "Farmland"
  },
  {
    "value": "47341",
    "label": "Fountain City - IN 47341",
    "county": "Wayne County",
    "state": "IN",
    "city": "Fountain City"
  },
  {
    "value": "47342",
    "label": "Gaston - IN 47342",
    "county": "Delaware County",
    "state": "IN",
    "city": "Gaston"
  },
  {
    "value": "47345",
    "label": "Greens Fork - IN 47345",
    "county": "Wayne County",
    "state": "IN",
    "city": "Greens Fork"
  },
  {
    "value": "47346",
    "label": "Hagerstown - IN 47346",
    "county": "Wayne County",
    "state": "IN",
    "city": "Hagerstown"
  },
  {
    "value": "47348",
    "label": "Hartford City - IN 47348",
    "county": "Blackford County",
    "state": "IN",
    "city": "Hartford City"
  },
  {
    "value": "47352",
    "label": "Lewisville - IN 47352",
    "county": "Henry County",
    "state": "IN",
    "city": "Lewisville"
  },
  {
    "value": "47353",
    "label": "Liberty - IN 47353",
    "county": "Union County",
    "state": "IN",
    "city": "Liberty"
  },
  {
    "value": "47354",
    "label": "Losantville - IN 47354",
    "county": "Randolph County",
    "state": "IN",
    "city": "Losantville"
  },
  {
    "value": "47355",
    "label": "Lynn - IN 47355",
    "county": "Randolph County",
    "state": "IN",
    "city": "Lynn"
  },
  {
    "value": "47356",
    "label": "Middletown - IN 47356",
    "county": "Henry County",
    "state": "IN",
    "city": "Middletown"
  },
  {
    "value": "47357",
    "label": "Milton - IN 47357",
    "county": "Wayne County",
    "state": "IN",
    "city": "Milton"
  },
  {
    "value": "47358",
    "label": "Modoc - IN 47358",
    "county": "Randolph County",
    "state": "IN",
    "city": "Modoc"
  },
  {
    "value": "47359",
    "label": "Montpelier - IN 47359",
    "county": "Blackford County",
    "state": "IN",
    "city": "Montpelier"
  },
  {
    "value": "47360",
    "label": "Mooreland - IN 47360",
    "county": "Henry County",
    "state": "IN",
    "city": "Mooreland"
  },
  {
    "value": "47362",
    "label": "New Castle - IN 47362",
    "county": "Henry County",
    "state": "IN",
    "city": "New Castle"
  },
  {
    "value": "47368",
    "label": "Parker City - IN 47368",
    "county": "Randolph County",
    "state": "IN",
    "city": "Parker City"
  },
  {
    "value": "47369",
    "label": "Pennville - IN 47369",
    "county": "Jay County",
    "state": "IN",
    "city": "Pennville"
  },
  {
    "value": "47371",
    "label": "Portland - IN 47371",
    "county": "Jay County",
    "state": "IN",
    "city": "Portland"
  },
  {
    "value": "47373",
    "label": "Redkey - IN 47373",
    "county": "Jay County",
    "state": "IN",
    "city": "Redkey"
  },
  {
    "value": "47374",
    "label": "Richmond - IN 47374",
    "county": "Wayne County",
    "state": "IN",
    "city": "Richmond"
  },
  {
    "value": "47380",
    "label": "Ridgeville - IN 47380",
    "county": "Randolph County",
    "state": "IN",
    "city": "Ridgeville"
  },
  {
    "value": "47381",
    "label": "Salamonia - IN 47381",
    "county": "Jay County",
    "state": "IN",
    "city": "Salamonia"
  },
  {
    "value": "47383",
    "label": "Selma - IN 47383",
    "county": "Delaware County",
    "state": "IN",
    "city": "Selma"
  },
  {
    "value": "47384",
    "label": "Shirley - IN 47384",
    "county": "Henry County",
    "state": "IN",
    "city": "Shirley"
  },
  {
    "value": "47385",
    "label": "Spiceland - IN 47385",
    "county": "Henry County",
    "state": "IN",
    "city": "Spiceland"
  },
  {
    "value": "47386",
    "label": "Springport - IN 47386",
    "county": "Henry County",
    "state": "IN",
    "city": "Springport"
  },
  {
    "value": "47387",
    "label": "Straughn - IN 47387",
    "county": "Henry County",
    "state": "IN",
    "city": "Straughn"
  },
  {
    "value": "47390",
    "label": "Union City - IN 47390",
    "county": "Randolph County",
    "state": "IN",
    "city": "Union City"
  },
  {
    "value": "47392",
    "label": "Webster - IN 47392",
    "county": "Wayne County",
    "state": "IN",
    "city": "Webster"
  },
  {
    "value": "47393",
    "label": "Williamsburg - IN 47393",
    "county": "Wayne County",
    "state": "IN",
    "city": "Williamsburg"
  },
  {
    "value": "47394",
    "label": "Winchester - IN 47394",
    "county": "Randolph County",
    "state": "IN",
    "city": "Winchester"
  },
  {
    "value": "47396",
    "label": "Yorktown - IN 47396",
    "county": "Delaware County",
    "state": "IN",
    "city": "Yorktown"
  },
  {
    "value": "47401",
    "label": "Bloomington - IN 47401",
    "county": "Monroe County",
    "state": "IN",
    "city": "Bloomington"
  },
  {
    "value": "47403",
    "label": "Bloomington - IN 47403",
    "county": "Monroe County",
    "state": "IN",
    "city": "Bloomington"
  },
  {
    "value": "47404",
    "label": "Bloomington - IN 47404",
    "county": "Monroe County",
    "state": "IN",
    "city": "Bloomington"
  },
  {
    "value": "47405",
    "label": "Bloomington - IN 47405",
    "county": "Monroe County",
    "state": "IN",
    "city": "Bloomington"
  },
  {
    "value": "47406",
    "label": "Bloomington - IN 47406",
    "county": "Monroe County",
    "state": "IN",
    "city": "Bloomington"
  },
  {
    "value": "47408",
    "label": "Bloomington - IN 47408",
    "county": "Monroe County",
    "state": "IN",
    "city": "Bloomington"
  },
  {
    "value": "47421",
    "label": "Bedford - IN 47421",
    "county": "Lawrence County",
    "state": "IN",
    "city": "Bedford"
  },
  {
    "value": "47424",
    "label": "Bloomfield - IN 47424",
    "county": "Greene County",
    "state": "IN",
    "city": "Bloomfield"
  },
  {
    "value": "47427",
    "label": "Coal City - IN 47427",
    "county": "Owen County",
    "state": "IN",
    "city": "Coal City"
  },
  {
    "value": "47429",
    "label": "Ellettsville - IN 47429",
    "county": "Monroe County",
    "state": "IN",
    "city": "Ellettsville"
  },
  {
    "value": "47431",
    "label": "Freedom - IN 47431",
    "county": "Owen County",
    "state": "IN",
    "city": "Freedom"
  },
  {
    "value": "47432",
    "label": "French Lick - IN 47432",
    "county": "Orange County",
    "state": "IN",
    "city": "French Lick"
  },
  {
    "value": "47433",
    "label": "Gosport - IN 47433",
    "county": "Owen County",
    "state": "IN",
    "city": "Gosport"
  },
  {
    "value": "47436",
    "label": "Heltonville - IN 47436",
    "county": "Lawrence County",
    "state": "IN",
    "city": "Heltonville"
  },
  {
    "value": "47438",
    "label": "Jasonville - IN 47438",
    "county": "Greene County",
    "state": "IN",
    "city": "Jasonville"
  },
  {
    "value": "47441",
    "label": "Linton - IN 47441",
    "county": "Greene County",
    "state": "IN",
    "city": "Linton"
  },
  {
    "value": "47443",
    "label": "Lyons - IN 47443",
    "county": "Greene County",
    "state": "IN",
    "city": "Lyons"
  },
  {
    "value": "47446",
    "label": "Mitchell - IN 47446",
    "county": "Lawrence County",
    "state": "IN",
    "city": "Mitchell"
  },
  {
    "value": "47448",
    "label": "Nashville - IN 47448",
    "county": "Brown County",
    "state": "IN",
    "city": "Nashville"
  },
  {
    "value": "47449",
    "label": "Newberry - IN 47449",
    "county": "Greene County",
    "state": "IN",
    "city": "Newberry"
  },
  {
    "value": "47451",
    "label": "Oolitic - IN 47451",
    "county": "Lawrence County",
    "state": "IN",
    "city": "Oolitic"
  },
  {
    "value": "47452",
    "label": "Orleans - IN 47452",
    "county": "Orange County",
    "state": "IN",
    "city": "Orleans"
  },
  {
    "value": "47453",
    "label": "Owensburg - IN 47453",
    "county": "Greene County",
    "state": "IN",
    "city": "Owensburg"
  },
  {
    "value": "47454",
    "label": "Paoli - IN 47454",
    "county": "Orange County",
    "state": "IN",
    "city": "Paoli"
  },
  {
    "value": "47456",
    "label": "Quincy - IN 47456",
    "county": "Owen County",
    "state": "IN",
    "city": "Quincy"
  },
  {
    "value": "47457",
    "label": "Scotland - IN 47457",
    "county": "Greene County",
    "state": "IN",
    "city": "Scotland"
  },
  {
    "value": "47459",
    "label": "Solsberry - IN 47459",
    "county": "Greene County",
    "state": "IN",
    "city": "Solsberry"
  },
  {
    "value": "47460",
    "label": "Spencer - IN 47460",
    "county": "Owen County",
    "state": "IN",
    "city": "Spencer"
  },
  {
    "value": "47462",
    "label": "Springville - IN 47462",
    "county": "Lawrence County",
    "state": "IN",
    "city": "Springville"
  },
  {
    "value": "47465",
    "label": "Switz City - IN 47465",
    "county": "Greene County",
    "state": "IN",
    "city": "Switz City"
  },
  {
    "value": "47468",
    "label": "Unionville - IN 47468",
    "county": "Monroe County",
    "state": "IN",
    "city": "Unionville"
  },
  {
    "value": "47469",
    "label": "West Baden Springs - IN 47469",
    "county": "Orange County",
    "state": "IN",
    "city": "West Baden Springs"
  },
  {
    "value": "47470",
    "label": "Williams - IN 47470",
    "county": "Lawrence County",
    "state": "IN",
    "city": "Williams"
  },
  {
    "value": "47471",
    "label": "Worthington - IN 47471",
    "county": "Greene County",
    "state": "IN",
    "city": "Worthington"
  },
  {
    "value": "47501",
    "label": "Washington - IN 47501",
    "county": "Daviess County",
    "state": "IN",
    "city": "Washington"
  },
  {
    "value": "47512",
    "label": "Bicknell - IN 47512",
    "county": "Knox County",
    "state": "IN",
    "city": "Bicknell"
  },
  {
    "value": "47513",
    "label": "Birdseye - IN 47513",
    "county": "Dubois County",
    "state": "IN",
    "city": "Birdseye"
  },
  {
    "value": "47514",
    "label": "Branchville - IN 47514",
    "county": "Perry County",
    "state": "IN",
    "city": "Branchville"
  },
  {
    "value": "47515",
    "label": "Bristow - IN 47515",
    "county": "Perry County",
    "state": "IN",
    "city": "Bristow"
  },
  {
    "value": "47516",
    "label": "Bruceville - IN 47516",
    "county": "Knox County",
    "state": "IN",
    "city": "Bruceville"
  },
  {
    "value": "47519",
    "label": "Cannelburg - IN 47519",
    "county": "Daviess County",
    "state": "IN",
    "city": "Cannelburg"
  },
  {
    "value": "47520",
    "label": "Cannelton - IN 47520",
    "county": "Perry County",
    "state": "IN",
    "city": "Cannelton"
  },
  {
    "value": "47521",
    "label": "Celestine - IN 47521",
    "county": "Dubois County",
    "state": "IN",
    "city": "Celestine"
  },
  {
    "value": "47522",
    "label": "Crane - IN 47522",
    "county": "Martin County",
    "state": "IN",
    "city": "Crane"
  },
  {
    "value": "47523",
    "label": "Dale - IN 47523",
    "county": "Spencer County",
    "state": "IN",
    "city": "Dale"
  },
  {
    "value": "47524",
    "label": "Decker - IN 47524",
    "county": "Knox County",
    "state": "IN",
    "city": "Decker"
  },
  {
    "value": "47525",
    "label": "Derby - IN 47525",
    "county": "Perry County",
    "state": "IN",
    "city": "Derby"
  },
  {
    "value": "47527",
    "label": "Dubois - IN 47527",
    "county": "Dubois County",
    "state": "IN",
    "city": "Dubois"
  },
  {
    "value": "47528",
    "label": "Edwardsport - IN 47528",
    "county": "Knox County",
    "state": "IN",
    "city": "Edwardsport"
  },
  {
    "value": "47529",
    "label": "Elnora - IN 47529",
    "county": "Daviess County",
    "state": "IN",
    "city": "Elnora"
  },
  {
    "value": "47531",
    "label": "Evanston - IN 47531",
    "county": "Spencer County",
    "state": "IN",
    "city": "Evanston"
  },
  {
    "value": "47532",
    "label": "Ferdinand - IN 47532",
    "county": "Dubois County",
    "state": "IN",
    "city": "Ferdinand"
  },
  {
    "value": "47537",
    "label": "Gentryville - IN 47537",
    "county": "Spencer County",
    "state": "IN",
    "city": "Gentryville"
  },
  {
    "value": "47541",
    "label": "Holland - IN 47541",
    "county": "Dubois County",
    "state": "IN",
    "city": "Holland"
  },
  {
    "value": "47542",
    "label": "Huntingburg - IN 47542",
    "county": "Dubois County",
    "state": "IN",
    "city": "Huntingburg"
  },
  {
    "value": "47546",
    "label": "Jasper - IN 47546",
    "county": "Dubois County",
    "state": "IN",
    "city": "Jasper"
  },
  {
    "value": "47550",
    "label": "Lamar - IN 47550",
    "county": "Spencer County",
    "state": "IN",
    "city": "Lamar"
  },
  {
    "value": "47551",
    "label": "Leopold - IN 47551",
    "county": "Perry County",
    "state": "IN",
    "city": "Leopold"
  },
  {
    "value": "47552",
    "label": "Lincoln City - IN 47552",
    "county": "Spencer County",
    "state": "IN",
    "city": "Lincoln City"
  },
  {
    "value": "47553",
    "label": "Loogootee - IN 47553",
    "county": "Martin County",
    "state": "IN",
    "city": "Loogootee"
  },
  {
    "value": "47557",
    "label": "Monroe City - IN 47557",
    "county": "Knox County",
    "state": "IN",
    "city": "Monroe City"
  },
  {
    "value": "47558",
    "label": "Montgomery - IN 47558",
    "county": "Daviess County",
    "state": "IN",
    "city": "Montgomery"
  },
  {
    "value": "47561",
    "label": "Oaktown - IN 47561",
    "county": "Knox County",
    "state": "IN",
    "city": "Oaktown"
  },
  {
    "value": "47562",
    "label": "Odon - IN 47562",
    "county": "Daviess County",
    "state": "IN",
    "city": "Odon"
  },
  {
    "value": "47564",
    "label": "Otwell - IN 47564",
    "county": "Pike County",
    "state": "IN",
    "city": "Otwell"
  },
  {
    "value": "47567",
    "label": "Petersburg - IN 47567",
    "county": "Pike County",
    "state": "IN",
    "city": "Petersburg"
  },
  {
    "value": "47568",
    "label": "Plainville - IN 47568",
    "county": "Daviess County",
    "state": "IN",
    "city": "Plainville"
  },
  {
    "value": "47573",
    "label": "Ragsdale - IN 47573",
    "county": "Knox County",
    "state": "IN",
    "city": "Ragsdale"
  },
  {
    "value": "47574",
    "label": "Rome - IN 47574",
    "county": "Perry County",
    "state": "IN",
    "city": "Rome"
  },
  {
    "value": "47575",
    "label": "Saint Anthony - IN 47575",
    "county": "Dubois County",
    "state": "IN",
    "city": "Saint Anthony"
  },
  {
    "value": "47576",
    "label": "Saint Croix - IN 47576",
    "county": "Perry County",
    "state": "IN",
    "city": "Saint Croix"
  },
  {
    "value": "47577",
    "label": "Saint Meinrad - IN 47577",
    "county": "Spencer County",
    "state": "IN",
    "city": "Saint Meinrad"
  },
  {
    "value": "47578",
    "label": "Sandborn - IN 47578",
    "county": "Knox County",
    "state": "IN",
    "city": "Sandborn"
  },
  {
    "value": "47579",
    "label": "Santa Claus - IN 47579",
    "county": "Spencer County",
    "state": "IN",
    "city": "Santa Claus"
  },
  {
    "value": "47580",
    "label": "Schnellville - IN 47580",
    "county": "Dubois County",
    "state": "IN",
    "city": "Schnellville"
  },
  {
    "value": "47581",
    "label": "Shoals - IN 47581",
    "county": "Martin County",
    "state": "IN",
    "city": "Shoals"
  },
  {
    "value": "47585",
    "label": "Stendal - IN 47585",
    "county": "Pike County",
    "state": "IN",
    "city": "Stendal"
  },
  {
    "value": "47586",
    "label": "Tell City - IN 47586",
    "county": "Perry County",
    "state": "IN",
    "city": "Tell City"
  },
  {
    "value": "47588",
    "label": "Troy - IN 47588",
    "county": "Perry County",
    "state": "IN",
    "city": "Troy"
  },
  {
    "value": "47590",
    "label": "Velpen - IN 47590",
    "county": "Pike County",
    "state": "IN",
    "city": "Velpen"
  },
  {
    "value": "47591",
    "label": "Vincennes - IN 47591",
    "county": "Knox County",
    "state": "IN",
    "city": "Vincennes"
  },
  {
    "value": "47597",
    "label": "Wheatland - IN 47597",
    "county": "Knox County",
    "state": "IN",
    "city": "Wheatland"
  },
  {
    "value": "47598",
    "label": "Winslow - IN 47598",
    "county": "Pike County",
    "state": "IN",
    "city": "Winslow"
  },
  {
    "value": "47601",
    "label": "Boonville - IN 47601",
    "county": "Warrick County",
    "state": "IN",
    "city": "Boonville"
  },
  {
    "value": "47610",
    "label": "Chandler - IN 47610",
    "county": "Warrick County",
    "state": "IN",
    "city": "Chandler"
  },
  {
    "value": "47611",
    "label": "Chrisney - IN 47611",
    "county": "Spencer County",
    "state": "IN",
    "city": "Chrisney"
  },
  {
    "value": "47612",
    "label": "Cynthiana - IN 47612",
    "county": "Posey County",
    "state": "IN",
    "city": "Cynthiana"
  },
  {
    "value": "47613",
    "label": "Elberfeld - IN 47613",
    "county": "Warrick County",
    "state": "IN",
    "city": "Elberfeld"
  },
  {
    "value": "47615",
    "label": "Grandview - IN 47615",
    "county": "Spencer County",
    "state": "IN",
    "city": "Grandview"
  },
  {
    "value": "47616",
    "label": "Griffin - IN 47616",
    "county": "Posey County",
    "state": "IN",
    "city": "Griffin"
  },
  {
    "value": "47619",
    "label": "Lynnville - IN 47619",
    "county": "Warrick County",
    "state": "IN",
    "city": "Lynnville"
  },
  {
    "value": "47620",
    "label": "Mount Vernon - IN 47620",
    "county": "Posey County",
    "state": "IN",
    "city": "Mount Vernon"
  },
  {
    "value": "47630",
    "label": "Newburgh - IN 47630",
    "county": "Warrick County",
    "state": "IN",
    "city": "Newburgh"
  },
  {
    "value": "47631",
    "label": "New Harmony - IN 47631",
    "county": "Posey County",
    "state": "IN",
    "city": "New Harmony"
  },
  {
    "value": "47633",
    "label": "Poseyville - IN 47633",
    "county": "Posey County",
    "state": "IN",
    "city": "Poseyville"
  },
  {
    "value": "47634",
    "label": "Richland - IN 47634",
    "county": "Spencer County",
    "state": "IN",
    "city": "Richland"
  },
  {
    "value": "47635",
    "label": "Rockport - IN 47635",
    "county": "Spencer County",
    "state": "IN",
    "city": "Rockport"
  },
  {
    "value": "47637",
    "label": "Tennyson - IN 47637",
    "county": "Warrick County",
    "state": "IN",
    "city": "Tennyson"
  },
  {
    "value": "47638",
    "label": "Wadesville - IN 47638",
    "county": "Posey County",
    "state": "IN",
    "city": "Wadesville"
  },
  {
    "value": "47639",
    "label": "Haubstadt - IN 47639",
    "county": "Gibson County",
    "state": "IN",
    "city": "Haubstadt"
  },
  {
    "value": "47640",
    "label": "Hazleton - IN 47640",
    "county": "Gibson County",
    "state": "IN",
    "city": "Hazleton"
  },
  {
    "value": "47647",
    "label": "Buckskin - IN 47647",
    "county": "Gibson County",
    "state": "IN",
    "city": "Buckskin"
  },
  {
    "value": "47648",
    "label": "Fort Branch - IN 47648",
    "county": "Gibson County",
    "state": "IN",
    "city": "Fort Branch"
  },
  {
    "value": "47649",
    "label": "Francisco - IN 47649",
    "county": "Gibson County",
    "state": "IN",
    "city": "Francisco"
  },
  {
    "value": "47660",
    "label": "Oakland City - IN 47660",
    "county": "Gibson County",
    "state": "IN",
    "city": "Oakland City"
  },
  {
    "value": "47665",
    "label": "Owensville - IN 47665",
    "county": "Gibson County",
    "state": "IN",
    "city": "Owensville"
  },
  {
    "value": "47666",
    "label": "Patoka - IN 47666",
    "county": "Gibson County",
    "state": "IN",
    "city": "Patoka"
  },
  {
    "value": "47670",
    "label": "Princeton - IN 47670",
    "county": "Gibson County",
    "state": "IN",
    "city": "Princeton"
  },
  {
    "value": "47708",
    "label": "Evansville - IN 47708",
    "county": "Vanderburgh County",
    "state": "IN",
    "city": "Evansville"
  },
  {
    "value": "47710",
    "label": "Evansville - IN 47710",
    "county": "Vanderburgh County",
    "state": "IN",
    "city": "Evansville"
  },
  {
    "value": "47711",
    "label": "Evansville - IN 47711",
    "county": "Vanderburgh County",
    "state": "IN",
    "city": "Evansville"
  },
  {
    "value": "47712",
    "label": "Evansville - IN 47712",
    "county": "Vanderburgh County",
    "state": "IN",
    "city": "Evansville"
  },
  {
    "value": "47713",
    "label": "Evansville - IN 47713",
    "county": "Vanderburgh County",
    "state": "IN",
    "city": "Evansville"
  },
  {
    "value": "47714",
    "label": "Evansville - IN 47714",
    "county": "Vanderburgh County",
    "state": "IN",
    "city": "Evansville"
  },
  {
    "value": "47715",
    "label": "Evansville - IN 47715",
    "county": "Vanderburgh County",
    "state": "IN",
    "city": "Evansville"
  },
  {
    "value": "47720",
    "label": "Evansville - IN 47720",
    "county": "Vanderburgh County",
    "state": "IN",
    "city": "Evansville"
  },
  {
    "value": "47725",
    "label": "Evansville - IN 47725",
    "county": "Vanderburgh County",
    "state": "IN",
    "city": "Evansville"
  },
  {
    "value": "47802",
    "label": "Terre Haute - IN 47802",
    "county": "Vigo County",
    "state": "IN",
    "city": "Terre Haute"
  },
  {
    "value": "47803",
    "label": "Terre Haute - IN 47803",
    "county": "Vigo County",
    "state": "IN",
    "city": "Terre Haute"
  },
  {
    "value": "47804",
    "label": "Terre Haute - IN 47804",
    "county": "Vigo County",
    "state": "IN",
    "city": "Terre Haute"
  },
  {
    "value": "47805",
    "label": "Terre Haute - IN 47805",
    "county": "Vigo County",
    "state": "IN",
    "city": "Terre Haute"
  },
  {
    "value": "47807",
    "label": "Terre Haute - IN 47807",
    "county": "Vigo County",
    "state": "IN",
    "city": "Terre Haute"
  },
  {
    "value": "47832",
    "label": "Bloomingdale - IN 47832",
    "county": "Parke County",
    "state": "IN",
    "city": "Bloomingdale"
  },
  {
    "value": "47833",
    "label": "Bowling Green - IN 47833",
    "county": "Owen County",
    "state": "IN",
    "city": "Bowling Green"
  },
  {
    "value": "47834",
    "label": "Brazil - IN 47834",
    "county": "Clay County",
    "state": "IN",
    "city": "Brazil"
  },
  {
    "value": "47837",
    "label": "Carbon - IN 47837",
    "county": "Clay County",
    "state": "IN",
    "city": "Carbon"
  },
  {
    "value": "47838",
    "label": "Carlisle - IN 47838",
    "county": "Sullivan County",
    "state": "IN",
    "city": "Carlisle"
  },
  {
    "value": "47840",
    "label": "Centerpoint - IN 47840",
    "county": "Clay County",
    "state": "IN",
    "city": "Centerpoint"
  },
  {
    "value": "47841",
    "label": "Clay City - IN 47841",
    "county": "Clay County",
    "state": "IN",
    "city": "Clay City"
  },
  {
    "value": "47842",
    "label": "Clinton - IN 47842",
    "county": "Vermillion County",
    "state": "IN",
    "city": "Clinton"
  },
  {
    "value": "47846",
    "label": "Cory - IN 47846",
    "county": "Clay County",
    "state": "IN",
    "city": "Cory"
  },
  {
    "value": "47847",
    "label": "Dana - IN 47847",
    "county": "Vermillion County",
    "state": "IN",
    "city": "Dana"
  },
  {
    "value": "47848",
    "label": "Dugger - IN 47848",
    "county": "Sullivan County",
    "state": "IN",
    "city": "Dugger"
  },
  {
    "value": "47849",
    "label": "Fairbanks - IN 47849",
    "county": "Sullivan County",
    "state": "IN",
    "city": "Fairbanks"
  },
  {
    "value": "47850",
    "label": "Farmersburg - IN 47850",
    "county": "Sullivan County",
    "state": "IN",
    "city": "Farmersburg"
  },
  {
    "value": "47854",
    "label": "Hillsdale - IN 47854",
    "county": "Vermillion County",
    "state": "IN",
    "city": "Hillsdale"
  },
  {
    "value": "47858",
    "label": "Lewis - IN 47858",
    "county": "Clay County",
    "state": "IN",
    "city": "Lewis"
  },
  {
    "value": "47859",
    "label": "Marshall - IN 47859",
    "county": "Parke County",
    "state": "IN",
    "city": "Marshall"
  },
  {
    "value": "47861",
    "label": "Merom - IN 47861",
    "county": "Sullivan County",
    "state": "IN",
    "city": "Merom"
  },
  {
    "value": "47862",
    "label": "Montezuma - IN 47862",
    "county": "Parke County",
    "state": "IN",
    "city": "Montezuma"
  },
  {
    "value": "47864",
    "label": "Sullivan - IN 47864",
    "county": "Sullivan County",
    "state": "IN",
    "city": "Sullivan"
  },
  {
    "value": "47866",
    "label": "Pimento - IN 47866",
    "county": "Vigo County",
    "state": "IN",
    "city": "Pimento"
  },
  {
    "value": "47868",
    "label": "Poland - IN 47868",
    "county": "Owen County",
    "state": "IN",
    "city": "Poland"
  },
  {
    "value": "47872",
    "label": "Rockville - IN 47872",
    "county": "Parke County",
    "state": "IN",
    "city": "Rockville"
  },
  {
    "value": "47874",
    "label": "Rosedale - IN 47874",
    "county": "Parke County",
    "state": "IN",
    "city": "Rosedale"
  },
  {
    "value": "47879",
    "label": "Shelburn - IN 47879",
    "county": "Sullivan County",
    "state": "IN",
    "city": "Shelburn"
  },
  {
    "value": "47882",
    "label": "Sullivan - IN 47882",
    "county": "Sullivan County",
    "state": "IN",
    "city": "Sullivan"
  },
  {
    "value": "47885",
    "label": "West Terre Haute - IN 47885",
    "county": "Vigo County",
    "state": "IN",
    "city": "West Terre Haute"
  },
  {
    "value": "47901",
    "label": "Lafayette - IN 47901",
    "county": "Tippecanoe County",
    "state": "IN",
    "city": "Lafayette"
  },
  {
    "value": "47904",
    "label": "Lafayette - IN 47904",
    "county": "Tippecanoe County",
    "state": "IN",
    "city": "Lafayette"
  },
  {
    "value": "47905",
    "label": "Lafayette - IN 47905",
    "county": "Tippecanoe County",
    "state": "IN",
    "city": "Lafayette"
  },
  {
    "value": "47906",
    "label": "West Lafayette - IN 47906",
    "county": "Tippecanoe County",
    "state": "IN",
    "city": "West Lafayette"
  },
  {
    "value": "47907",
    "label": "West Lafayette - IN 47907",
    "county": "Tippecanoe County",
    "state": "IN",
    "city": "West Lafayette"
  },
  {
    "value": "47909",
    "label": "Lafayette - IN 47909",
    "county": "Tippecanoe County",
    "state": "IN",
    "city": "Lafayette"
  },
  {
    "value": "47917",
    "label": "Ambia - IN 47917",
    "county": "Benton County",
    "state": "IN",
    "city": "Ambia"
  },
  {
    "value": "47918",
    "label": "Attica - IN 47918",
    "county": "Fountain County",
    "state": "IN",
    "city": "Attica"
  },
  {
    "value": "47920",
    "label": "Battle Ground - IN 47920",
    "county": "Tippecanoe County",
    "state": "IN",
    "city": "Battle Ground"
  },
  {
    "value": "47921",
    "label": "Boswell - IN 47921",
    "county": "Benton County",
    "state": "IN",
    "city": "Boswell"
  },
  {
    "value": "47922",
    "label": "Brook - IN 47922",
    "county": "Newton County",
    "state": "IN",
    "city": "Brook"
  },
  {
    "value": "47923",
    "label": "Brookston - IN 47923",
    "county": "White County",
    "state": "IN",
    "city": "Brookston"
  },
  {
    "value": "47926",
    "label": "Burnettsville - IN 47926",
    "county": "White County",
    "state": "IN",
    "city": "Burnettsville"
  },
  {
    "value": "47928",
    "label": "Cayuga - IN 47928",
    "county": "Vermillion County",
    "state": "IN",
    "city": "Cayuga"
  },
  {
    "value": "47929",
    "label": "Chalmers - IN 47929",
    "county": "White County",
    "state": "IN",
    "city": "Chalmers"
  },
  {
    "value": "47930",
    "label": "Clarks Hill - IN 47930",
    "county": "Tippecanoe County",
    "state": "IN",
    "city": "Clarks Hill"
  },
  {
    "value": "47932",
    "label": "Covington - IN 47932",
    "county": "Fountain County",
    "state": "IN",
    "city": "Covington"
  },
  {
    "value": "47933",
    "label": "Crawfordsville - IN 47933",
    "county": "Montgomery County",
    "state": "IN",
    "city": "Crawfordsville"
  },
  {
    "value": "47940",
    "label": "Darlington - IN 47940",
    "county": "Montgomery County",
    "state": "IN",
    "city": "Darlington"
  },
  {
    "value": "47942",
    "label": "Earl Park - IN 47942",
    "county": "Benton County",
    "state": "IN",
    "city": "Earl Park"
  },
  {
    "value": "47943",
    "label": "Fair Oaks - IN 47943",
    "county": "Jasper County",
    "state": "IN",
    "city": "Fair Oaks"
  },
  {
    "value": "47944",
    "label": "Fowler - IN 47944",
    "county": "Benton County",
    "state": "IN",
    "city": "Fowler"
  },
  {
    "value": "47946",
    "label": "Francesville - IN 47946",
    "county": "Pulaski County",
    "state": "IN",
    "city": "Francesville"
  },
  {
    "value": "47948",
    "label": "Goodland - IN 47948",
    "county": "Newton County",
    "state": "IN",
    "city": "Goodland"
  },
  {
    "value": "47949",
    "label": "Hillsboro - IN 47949",
    "county": "Fountain County",
    "state": "IN",
    "city": "Hillsboro"
  },
  {
    "value": "47950",
    "label": "Idaville - IN 47950",
    "county": "White County",
    "state": "IN",
    "city": "Idaville"
  },
  {
    "value": "47951",
    "label": "Kentland - IN 47951",
    "county": "Newton County",
    "state": "IN",
    "city": "Kentland"
  },
  {
    "value": "47952",
    "label": "Kingman - IN 47952",
    "county": "Fountain County",
    "state": "IN",
    "city": "Kingman"
  },
  {
    "value": "47954",
    "label": "Ladoga - IN 47954",
    "county": "Montgomery County",
    "state": "IN",
    "city": "Ladoga"
  },
  {
    "value": "47955",
    "label": "Linden - IN 47955",
    "county": "Montgomery County",
    "state": "IN",
    "city": "Linden"
  },
  {
    "value": "47957",
    "label": "Medaryville - IN 47957",
    "county": "Pulaski County",
    "state": "IN",
    "city": "Medaryville"
  },
  {
    "value": "47959",
    "label": "Monon - IN 47959",
    "county": "White County",
    "state": "IN",
    "city": "Monon"
  },
  {
    "value": "47960",
    "label": "Monticello - IN 47960",
    "county": "White County",
    "state": "IN",
    "city": "Monticello"
  },
  {
    "value": "47963",
    "label": "Morocco - IN 47963",
    "county": "Newton County",
    "state": "IN",
    "city": "Morocco"
  },
  {
    "value": "47967",
    "label": "New Richmond - IN 47967",
    "county": "Montgomery County",
    "state": "IN",
    "city": "New Richmond"
  },
  {
    "value": "47968",
    "label": "New Ross - IN 47968",
    "county": "Montgomery County",
    "state": "IN",
    "city": "New Ross"
  },
  {
    "value": "47970",
    "label": "Otterbein - IN 47970",
    "county": "Benton County",
    "state": "IN",
    "city": "Otterbein"
  },
  {
    "value": "47971",
    "label": "Oxford - IN 47971",
    "county": "Benton County",
    "state": "IN",
    "city": "Oxford"
  },
  {
    "value": "47974",
    "label": "Perrysville - IN 47974",
    "county": "Vermillion County",
    "state": "IN",
    "city": "Perrysville"
  },
  {
    "value": "47975",
    "label": "Pine Village - IN 47975",
    "county": "Warren County",
    "state": "IN",
    "city": "Pine Village"
  },
  {
    "value": "47977",
    "label": "Remington - IN 47977",
    "county": "Jasper County",
    "state": "IN",
    "city": "Remington"
  },
  {
    "value": "47978",
    "label": "Rensselaer - IN 47978",
    "county": "Jasper County",
    "state": "IN",
    "city": "Rensselaer"
  },
  {
    "value": "47980",
    "label": "Reynolds - IN 47980",
    "county": "White County",
    "state": "IN",
    "city": "Reynolds"
  },
  {
    "value": "47981",
    "label": "Romney - IN 47981",
    "county": "Tippecanoe County",
    "state": "IN",
    "city": "Romney"
  },
  {
    "value": "47987",
    "label": "Veedersburg - IN 47987",
    "county": "Fountain County",
    "state": "IN",
    "city": "Veedersburg"
  },
  {
    "value": "47989",
    "label": "Waveland - IN 47989",
    "county": "Montgomery County",
    "state": "IN",
    "city": "Waveland"
  },
  {
    "value": "47990",
    "label": "Waynetown - IN 47990",
    "county": "Montgomery County",
    "state": "IN",
    "city": "Waynetown"
  },
  {
    "value": "47991",
    "label": "West Lebanon - IN 47991",
    "county": "Warren County",
    "state": "IN",
    "city": "West Lebanon"
  },
  {
    "value": "47992",
    "label": "Westpoint - IN 47992",
    "county": "Tippecanoe County",
    "state": "IN",
    "city": "Westpoint"
  },
  {
    "value": "47993",
    "label": "Williamsport - IN 47993",
    "county": "Warren County",
    "state": "IN",
    "city": "Williamsport"
  },
  {
    "value": "47994",
    "label": "Wingate - IN 47994",
    "county": "Montgomery County",
    "state": "IN",
    "city": "Wingate"
  },
  {
    "value": "47995",
    "label": "Wolcott - IN 47995",
    "county": "White County",
    "state": "IN",
    "city": "Wolcott"
  },
  {
    "value": "47997",
    "label": "Yeoman - IN 47997",
    "county": "Carroll County",
    "state": "IN",
    "city": "Yeoman"
  },
  {
    "value": "50001",
    "label": "Ackworth - IA 50001",
    "county": "Warren County",
    "state": "IA",
    "city": "Ackworth"
  },
  {
    "value": "50002",
    "label": "Adair - IA 50002",
    "county": "Adair County",
    "state": "IA",
    "city": "Adair"
  },
  {
    "value": "50003",
    "label": "Adel - IA 50003",
    "county": "Dallas County",
    "state": "IA",
    "city": "Adel"
  },
  {
    "value": "50005",
    "label": "Albion - IA 50005",
    "county": "Marshall County",
    "state": "IA",
    "city": "Albion"
  },
  {
    "value": "50006",
    "label": "Alden - IA 50006",
    "county": "Hardin County",
    "state": "IA",
    "city": "Alden"
  },
  {
    "value": "50007",
    "label": "Alleman - IA 50007",
    "county": "Polk County",
    "state": "IA",
    "city": "Alleman"
  },
  {
    "value": "50008",
    "label": "Allerton - IA 50008",
    "county": "Wayne County",
    "state": "IA",
    "city": "Allerton"
  },
  {
    "value": "50009",
    "label": "Altoona - IA 50009",
    "county": "Polk County",
    "state": "IA",
    "city": "Altoona"
  },
  {
    "value": "50010",
    "label": "Ames - IA 50010",
    "county": "Story County",
    "state": "IA",
    "city": "Ames"
  },
  {
    "value": "50014",
    "label": "Ames - IA 50014",
    "county": "Story County",
    "state": "IA",
    "city": "Ames"
  },
  {
    "value": "50020",
    "label": "Anita - IA 50020",
    "county": "Cass County",
    "state": "IA",
    "city": "Anita"
  },
  {
    "value": "50021",
    "label": "Ankeny - IA 50021",
    "county": "Polk County",
    "state": "IA",
    "city": "Ankeny"
  },
  {
    "value": "50022",
    "label": "Atlantic - IA 50022",
    "county": "Cass County",
    "state": "IA",
    "city": "Atlantic"
  },
  {
    "value": "50023",
    "label": "Ankeny - IA 50023",
    "county": "Polk County",
    "state": "IA",
    "city": "Ankeny"
  },
  {
    "value": "50025",
    "label": "Audubon - IA 50025",
    "county": "Audubon County",
    "state": "IA",
    "city": "Audubon"
  },
  {
    "value": "50026",
    "label": "Bagley - IA 50026",
    "county": "Guthrie County",
    "state": "IA",
    "city": "Bagley"
  },
  {
    "value": "50027",
    "label": "Barnes City - IA 50027",
    "county": "Mahaska County",
    "state": "IA",
    "city": "Barnes City"
  },
  {
    "value": "50028",
    "label": "Baxter - IA 50028",
    "county": "Jasper County",
    "state": "IA",
    "city": "Baxter"
  },
  {
    "value": "50029",
    "label": "Bayard - IA 50029",
    "county": "Guthrie County",
    "state": "IA",
    "city": "Bayard"
  },
  {
    "value": "50033",
    "label": "Bevington - IA 50033",
    "county": "Madison County",
    "state": "IA",
    "city": "Bevington"
  },
  {
    "value": "50034",
    "label": "Blairsburg - IA 50034",
    "county": "Hamilton County",
    "state": "IA",
    "city": "Blairsburg"
  },
  {
    "value": "50035",
    "label": "Bondurant - IA 50035",
    "county": "Polk County",
    "state": "IA",
    "city": "Bondurant"
  },
  {
    "value": "50036",
    "label": "Boone - IA 50036",
    "county": "Boone County",
    "state": "IA",
    "city": "Boone"
  },
  {
    "value": "50038",
    "label": "Booneville - IA 50038",
    "county": "Dallas County",
    "state": "IA",
    "city": "Booneville"
  },
  {
    "value": "50039",
    "label": "Bouton - IA 50039",
    "county": "Dallas County",
    "state": "IA",
    "city": "Bouton"
  },
  {
    "value": "50042",
    "label": "Brayton - IA 50042",
    "county": "Audubon County",
    "state": "IA",
    "city": "Brayton"
  },
  {
    "value": "50044",
    "label": "Bussey - IA 50044",
    "county": "Marion County",
    "state": "IA",
    "city": "Bussey"
  },
  {
    "value": "50046",
    "label": "Cambridge - IA 50046",
    "county": "Story County",
    "state": "IA",
    "city": "Cambridge"
  },
  {
    "value": "50047",
    "label": "Carlisle - IA 50047",
    "county": "Warren County",
    "state": "IA",
    "city": "Carlisle"
  },
  {
    "value": "50048",
    "label": "Casey - IA 50048",
    "county": "Guthrie County",
    "state": "IA",
    "city": "Casey"
  },
  {
    "value": "50049",
    "label": "Chariton - IA 50049",
    "county": "Lucas County",
    "state": "IA",
    "city": "Chariton"
  },
  {
    "value": "50050",
    "label": "Churdan - IA 50050",
    "county": "Greene County",
    "state": "IA",
    "city": "Churdan"
  },
  {
    "value": "50051",
    "label": "Clemons - IA 50051",
    "county": "Marshall County",
    "state": "IA",
    "city": "Clemons"
  },
  {
    "value": "50052",
    "label": "Clio - IA 50052",
    "county": "Wayne County",
    "state": "IA",
    "city": "Clio"
  },
  {
    "value": "50054",
    "label": "Colfax - IA 50054",
    "county": "Jasper County",
    "state": "IA",
    "city": "Colfax"
  },
  {
    "value": "50055",
    "label": "Collins - IA 50055",
    "county": "Story County",
    "state": "IA",
    "city": "Collins"
  },
  {
    "value": "50056",
    "label": "Colo - IA 50056",
    "county": "Story County",
    "state": "IA",
    "city": "Colo"
  },
  {
    "value": "50057",
    "label": "Columbia - IA 50057",
    "county": "Marion County",
    "state": "IA",
    "city": "Columbia"
  },
  {
    "value": "50058",
    "label": "Coon Rapids - IA 50058",
    "county": "Carroll County",
    "state": "IA",
    "city": "Coon Rapids"
  },
  {
    "value": "50059",
    "label": "Cooper - IA 50059",
    "county": "Greene County",
    "state": "IA",
    "city": "Cooper"
  },
  {
    "value": "50060",
    "label": "Corydon - IA 50060",
    "county": "Wayne County",
    "state": "IA",
    "city": "Corydon"
  },
  {
    "value": "50061",
    "label": "Cumming - IA 50061",
    "county": "Warren County",
    "state": "IA",
    "city": "Cumming"
  },
  {
    "value": "50062",
    "label": "Melcher Dallas - IA 50062",
    "county": "Marion County",
    "state": "IA",
    "city": "Melcher Dallas"
  },
  {
    "value": "50063",
    "label": "Dallas Center - IA 50063",
    "county": "Dallas County",
    "state": "IA",
    "city": "Dallas Center"
  },
  {
    "value": "50064",
    "label": "Dana - IA 50064",
    "county": "Greene County",
    "state": "IA",
    "city": "Dana"
  },
  {
    "value": "50065",
    "label": "Davis City - IA 50065",
    "county": "Decatur County",
    "state": "IA",
    "city": "Davis City"
  },
  {
    "value": "50066",
    "label": "Dawson - IA 50066",
    "county": "Dallas County",
    "state": "IA",
    "city": "Dawson"
  },
  {
    "value": "50067",
    "label": "Decatur - IA 50067",
    "county": "Decatur County",
    "state": "IA",
    "city": "Decatur"
  },
  {
    "value": "50068",
    "label": "Derby - IA 50068",
    "county": "Lucas County",
    "state": "IA",
    "city": "Derby"
  },
  {
    "value": "50069",
    "label": "De Soto - IA 50069",
    "county": "Dallas County",
    "state": "IA",
    "city": "De Soto"
  },
  {
    "value": "50070",
    "label": "Dexter - IA 50070",
    "county": "Dallas County",
    "state": "IA",
    "city": "Dexter"
  },
  {
    "value": "50071",
    "label": "Dows - IA 50071",
    "county": "Wright County",
    "state": "IA",
    "city": "Dows"
  },
  {
    "value": "50072",
    "label": "Earlham - IA 50072",
    "county": "Madison County",
    "state": "IA",
    "city": "Earlham"
  },
  {
    "value": "50073",
    "label": "Elkhart - IA 50073",
    "county": "Polk County",
    "state": "IA",
    "city": "Elkhart"
  },
  {
    "value": "50074",
    "label": "Ellston - IA 50074",
    "county": "Ringgold County",
    "state": "IA",
    "city": "Ellston"
  },
  {
    "value": "50075",
    "label": "Ellsworth - IA 50075",
    "county": "Hamilton County",
    "state": "IA",
    "city": "Ellsworth"
  },
  {
    "value": "50076",
    "label": "Exira - IA 50076",
    "county": "Audubon County",
    "state": "IA",
    "city": "Exira"
  },
  {
    "value": "50101",
    "label": "Galt - IA 50101",
    "county": "Wright County",
    "state": "IA",
    "city": "Galt"
  },
  {
    "value": "50103",
    "label": "Garden Grove - IA 50103",
    "county": "Decatur County",
    "state": "IA",
    "city": "Garden Grove"
  },
  {
    "value": "50104",
    "label": "Gibson - IA 50104",
    "county": "Keokuk County",
    "state": "IA",
    "city": "Gibson"
  },
  {
    "value": "50105",
    "label": "Gilbert - IA 50105",
    "county": "Story County",
    "state": "IA",
    "city": "Gilbert"
  },
  {
    "value": "50106",
    "label": "Gilman - IA 50106",
    "county": "Marshall County",
    "state": "IA",
    "city": "Gilman"
  },
  {
    "value": "50107",
    "label": "Grand Junction - IA 50107",
    "county": "Greene County",
    "state": "IA",
    "city": "Grand Junction"
  },
  {
    "value": "50108",
    "label": "Grand River - IA 50108",
    "county": "Decatur County",
    "state": "IA",
    "city": "Grand River"
  },
  {
    "value": "50109",
    "label": "Granger - IA 50109",
    "county": "Dallas County",
    "state": "IA",
    "city": "Granger"
  },
  {
    "value": "50111",
    "label": "Grimes - IA 50111",
    "county": "Polk County",
    "state": "IA",
    "city": "Grimes"
  },
  {
    "value": "50112",
    "label": "Grinnell - IA 50112",
    "county": "Poweshiek County",
    "state": "IA",
    "city": "Grinnell"
  },
  {
    "value": "50115",
    "label": "Guthrie Center - IA 50115",
    "county": "Guthrie County",
    "state": "IA",
    "city": "Guthrie Center"
  },
  {
    "value": "50116",
    "label": "Hamilton - IA 50116",
    "county": "Marion County",
    "state": "IA",
    "city": "Hamilton"
  },
  {
    "value": "50117",
    "label": "Hamlin - IA 50117",
    "county": "Audubon County",
    "state": "IA",
    "city": "Hamlin"
  },
  {
    "value": "50118",
    "label": "Hartford - IA 50118",
    "county": "Warren County",
    "state": "IA",
    "city": "Hartford"
  },
  {
    "value": "50119",
    "label": "Harvey - IA 50119",
    "county": "Marion County",
    "state": "IA",
    "city": "Harvey"
  },
  {
    "value": "50120",
    "label": "Haverhill - IA 50120",
    "county": "Marshall County",
    "state": "IA",
    "city": "Haverhill"
  },
  {
    "value": "50122",
    "label": "Hubbard - IA 50122",
    "county": "Hardin County",
    "state": "IA",
    "city": "Hubbard"
  },
  {
    "value": "50123",
    "label": "Humeston - IA 50123",
    "county": "Wayne County",
    "state": "IA",
    "city": "Humeston"
  },
  {
    "value": "50124",
    "label": "Huxley - IA 50124",
    "county": "Story County",
    "state": "IA",
    "city": "Huxley"
  },
  {
    "value": "50125",
    "label": "Indianola - IA 50125",
    "county": "Warren County",
    "state": "IA",
    "city": "Indianola"
  },
  {
    "value": "50126",
    "label": "Iowa Falls - IA 50126",
    "county": "Hardin County",
    "state": "IA",
    "city": "Iowa Falls"
  },
  {
    "value": "50127",
    "label": "Ira - IA 50127",
    "county": "Jasper County",
    "state": "IA",
    "city": "Ira"
  },
  {
    "value": "50128",
    "label": "Jamaica - IA 50128",
    "county": "Guthrie County",
    "state": "IA",
    "city": "Jamaica"
  },
  {
    "value": "50129",
    "label": "Jefferson - IA 50129",
    "county": "Greene County",
    "state": "IA",
    "city": "Jefferson"
  },
  {
    "value": "50130",
    "label": "Jewell - IA 50130",
    "county": "Hamilton County",
    "state": "IA",
    "city": "Jewell"
  },
  {
    "value": "50131",
    "label": "Johnston - IA 50131",
    "county": "Polk County",
    "state": "IA",
    "city": "Johnston"
  },
  {
    "value": "50132",
    "label": "Kamrar - IA 50132",
    "county": "Hamilton County",
    "state": "IA",
    "city": "Kamrar"
  },
  {
    "value": "50133",
    "label": "Kellerton - IA 50133",
    "county": "Ringgold County",
    "state": "IA",
    "city": "Kellerton"
  },
  {
    "value": "50134",
    "label": "Kelley - IA 50134",
    "county": "Story County",
    "state": "IA",
    "city": "Kelley"
  },
  {
    "value": "50135",
    "label": "Kellogg - IA 50135",
    "county": "Jasper County",
    "state": "IA",
    "city": "Kellogg"
  },
  {
    "value": "50136",
    "label": "Keswick - IA 50136",
    "county": "Keokuk County",
    "state": "IA",
    "city": "Keswick"
  },
  {
    "value": "50137",
    "label": "Killduff - IA 50137",
    "county": "Jasper County",
    "state": "IA",
    "city": "Killduff"
  },
  {
    "value": "50138",
    "label": "Knoxville - IA 50138",
    "county": "Marion County",
    "state": "IA",
    "city": "Knoxville"
  },
  {
    "value": "50139",
    "label": "Lacona - IA 50139",
    "county": "Warren County",
    "state": "IA",
    "city": "Lacona"
  },
  {
    "value": "50140",
    "label": "Lamoni - IA 50140",
    "county": "Decatur County",
    "state": "IA",
    "city": "Lamoni"
  },
  {
    "value": "50141",
    "label": "Laurel - IA 50141",
    "county": "Marshall County",
    "state": "IA",
    "city": "Laurel"
  },
  {
    "value": "50143",
    "label": "Leighton - IA 50143",
    "county": "Mahaska County",
    "state": "IA",
    "city": "Leighton"
  },
  {
    "value": "50144",
    "label": "Leon - IA 50144",
    "county": "Decatur County",
    "state": "IA",
    "city": "Leon"
  },
  {
    "value": "50146",
    "label": "Linden - IA 50146",
    "county": "Dallas County",
    "state": "IA",
    "city": "Linden"
  },
  {
    "value": "50147",
    "label": "Lineville - IA 50147",
    "county": "Wayne County",
    "state": "IA",
    "city": "Lineville"
  },
  {
    "value": "50148",
    "label": "Liscomb - IA 50148",
    "county": "Marshall County",
    "state": "IA",
    "city": "Liscomb"
  },
  {
    "value": "50149",
    "label": "Lorimor - IA 50149",
    "county": "Union County",
    "state": "IA",
    "city": "Lorimor"
  },
  {
    "value": "50150",
    "label": "Lovilia - IA 50150",
    "county": "Monroe County",
    "state": "IA",
    "city": "Lovilia"
  },
  {
    "value": "50151",
    "label": "Lucas - IA 50151",
    "county": "Lucas County",
    "state": "IA",
    "city": "Lucas"
  },
  {
    "value": "50153",
    "label": "Lynnville - IA 50153",
    "county": "Jasper County",
    "state": "IA",
    "city": "Lynnville"
  },
  {
    "value": "50154",
    "label": "Mc Callsburg - IA 50154",
    "county": "Story County",
    "state": "IA",
    "city": "Mc Callsburg"
  },
  {
    "value": "50155",
    "label": "Macksburg - IA 50155",
    "county": "Madison County",
    "state": "IA",
    "city": "Macksburg"
  },
  {
    "value": "50156",
    "label": "Madrid - IA 50156",
    "county": "Boone County",
    "state": "IA",
    "city": "Madrid"
  },
  {
    "value": "50157",
    "label": "Malcom - IA 50157",
    "county": "Poweshiek County",
    "state": "IA",
    "city": "Malcom"
  },
  {
    "value": "50158",
    "label": "Marshalltown - IA 50158",
    "county": "Marshall County",
    "state": "IA",
    "city": "Marshalltown"
  },
  {
    "value": "50160",
    "label": "Martensdale - IA 50160",
    "county": "Warren County",
    "state": "IA",
    "city": "Martensdale"
  },
  {
    "value": "50161",
    "label": "Maxwell - IA 50161",
    "county": "Story County",
    "state": "IA",
    "city": "Maxwell"
  },
  {
    "value": "50162",
    "label": "Melbourne - IA 50162",
    "county": "Marshall County",
    "state": "IA",
    "city": "Melbourne"
  },
  {
    "value": "50164",
    "label": "Menlo - IA 50164",
    "county": "Guthrie County",
    "state": "IA",
    "city": "Menlo"
  },
  {
    "value": "50165",
    "label": "Millerton - IA 50165",
    "county": "Wayne County",
    "state": "IA",
    "city": "Millerton"
  },
  {
    "value": "50166",
    "label": "Milo - IA 50166",
    "county": "Warren County",
    "state": "IA",
    "city": "Milo"
  },
  {
    "value": "50167",
    "label": "Minburn - IA 50167",
    "county": "Dallas County",
    "state": "IA",
    "city": "Minburn"
  },
  {
    "value": "50168",
    "label": "Mingo - IA 50168",
    "county": "Jasper County",
    "state": "IA",
    "city": "Mingo"
  },
  {
    "value": "50169",
    "label": "Mitchellville - IA 50169",
    "county": "Polk County",
    "state": "IA",
    "city": "Mitchellville"
  },
  {
    "value": "50170",
    "label": "Monroe - IA 50170",
    "county": "Jasper County",
    "state": "IA",
    "city": "Monroe"
  },
  {
    "value": "50171",
    "label": "Montezuma - IA 50171",
    "county": "Poweshiek County",
    "state": "IA",
    "city": "Montezuma"
  },
  {
    "value": "50173",
    "label": "Montour - IA 50173",
    "county": "Tama County",
    "state": "IA",
    "city": "Montour"
  },
  {
    "value": "50174",
    "label": "Murray - IA 50174",
    "county": "Clarke County",
    "state": "IA",
    "city": "Murray"
  },
  {
    "value": "50201",
    "label": "Nevada - IA 50201",
    "county": "Story County",
    "state": "IA",
    "city": "Nevada"
  },
  {
    "value": "50206",
    "label": "New Providence - IA 50206",
    "county": "Hardin County",
    "state": "IA",
    "city": "New Providence"
  },
  {
    "value": "50207",
    "label": "New Sharon - IA 50207",
    "county": "Mahaska County",
    "state": "IA",
    "city": "New Sharon"
  },
  {
    "value": "50208",
    "label": "Newton - IA 50208",
    "county": "Jasper County",
    "state": "IA",
    "city": "Newton"
  },
  {
    "value": "50210",
    "label": "New Virginia - IA 50210",
    "county": "Warren County",
    "state": "IA",
    "city": "New Virginia"
  },
  {
    "value": "50211",
    "label": "Norwalk - IA 50211",
    "county": "Warren County",
    "state": "IA",
    "city": "Norwalk"
  },
  {
    "value": "50212",
    "label": "Ogden - IA 50212",
    "county": "Boone County",
    "state": "IA",
    "city": "Ogden"
  },
  {
    "value": "50213",
    "label": "Osceola - IA 50213",
    "county": "Clarke County",
    "state": "IA",
    "city": "Osceola"
  },
  {
    "value": "50214",
    "label": "Otley - IA 50214",
    "county": "Marion County",
    "state": "IA",
    "city": "Otley"
  },
  {
    "value": "50216",
    "label": "Panora - IA 50216",
    "county": "Guthrie County",
    "state": "IA",
    "city": "Panora"
  },
  {
    "value": "50217",
    "label": "Paton - IA 50217",
    "county": "Greene County",
    "state": "IA",
    "city": "Paton"
  },
  {
    "value": "50218",
    "label": "Patterson - IA 50218",
    "county": "Madison County",
    "state": "IA",
    "city": "Patterson"
  },
  {
    "value": "50219",
    "label": "Pella - IA 50219",
    "county": "Marion County",
    "state": "IA",
    "city": "Pella"
  },
  {
    "value": "50220",
    "label": "Perry - IA 50220",
    "county": "Dallas County",
    "state": "IA",
    "city": "Perry"
  },
  {
    "value": "50222",
    "label": "Peru - IA 50222",
    "county": "Madison County",
    "state": "IA",
    "city": "Peru"
  },
  {
    "value": "50223",
    "label": "Pilot Mound - IA 50223",
    "county": "Boone County",
    "state": "IA",
    "city": "Pilot Mound"
  },
  {
    "value": "50225",
    "label": "Pleasantville - IA 50225",
    "county": "Marion County",
    "state": "IA",
    "city": "Pleasantville"
  },
  {
    "value": "50226",
    "label": "Polk City - IA 50226",
    "county": "Polk County",
    "state": "IA",
    "city": "Polk City"
  },
  {
    "value": "50227",
    "label": "Popejoy - IA 50227",
    "county": "Franklin County",
    "state": "IA",
    "city": "Popejoy"
  },
  {
    "value": "50228",
    "label": "Prairie City - IA 50228",
    "county": "Jasper County",
    "state": "IA",
    "city": "Prairie City"
  },
  {
    "value": "50229",
    "label": "Prole - IA 50229",
    "county": "Warren County",
    "state": "IA",
    "city": "Prole"
  },
  {
    "value": "50230",
    "label": "Radcliffe - IA 50230",
    "county": "Hardin County",
    "state": "IA",
    "city": "Radcliffe"
  },
  {
    "value": "50232",
    "label": "Reasnor - IA 50232",
    "county": "Jasper County",
    "state": "IA",
    "city": "Reasnor"
  },
  {
    "value": "50233",
    "label": "Redfield - IA 50233",
    "county": "Dallas County",
    "state": "IA",
    "city": "Redfield"
  },
  {
    "value": "50234",
    "label": "Rhodes - IA 50234",
    "county": "Marshall County",
    "state": "IA",
    "city": "Rhodes"
  },
  {
    "value": "50235",
    "label": "Rippey - IA 50235",
    "county": "Greene County",
    "state": "IA",
    "city": "Rippey"
  },
  {
    "value": "50236",
    "label": "Roland - IA 50236",
    "county": "Story County",
    "state": "IA",
    "city": "Roland"
  },
  {
    "value": "50237",
    "label": "Runnells - IA 50237",
    "county": "Polk County",
    "state": "IA",
    "city": "Runnells"
  },
  {
    "value": "50238",
    "label": "Russell - IA 50238",
    "county": "Lucas County",
    "state": "IA",
    "city": "Russell"
  },
  {
    "value": "50239",
    "label": "Saint Anthony - IA 50239",
    "county": "Marshall County",
    "state": "IA",
    "city": "Saint Anthony"
  },
  {
    "value": "50240",
    "label": "Saint Charles - IA 50240",
    "county": "Madison County",
    "state": "IA",
    "city": "Saint Charles"
  },
  {
    "value": "50242",
    "label": "Searsboro - IA 50242",
    "county": "Poweshiek County",
    "state": "IA",
    "city": "Searsboro"
  },
  {
    "value": "50244",
    "label": "Slater - IA 50244",
    "county": "Story County",
    "state": "IA",
    "city": "Slater"
  },
  {
    "value": "50246",
    "label": "Stanhope - IA 50246",
    "county": "Hamilton County",
    "state": "IA",
    "city": "Stanhope"
  },
  {
    "value": "50247",
    "label": "State Center - IA 50247",
    "county": "Marshall County",
    "state": "IA",
    "city": "State Center"
  },
  {
    "value": "50248",
    "label": "Story City - IA 50248",
    "county": "Story County",
    "state": "IA",
    "city": "Story City"
  },
  {
    "value": "50249",
    "label": "Stratford - IA 50249",
    "county": "Hamilton County",
    "state": "IA",
    "city": "Stratford"
  },
  {
    "value": "50250",
    "label": "Stuart - IA 50250",
    "county": "Guthrie County",
    "state": "IA",
    "city": "Stuart"
  },
  {
    "value": "50251",
    "label": "Sully - IA 50251",
    "county": "Jasper County",
    "state": "IA",
    "city": "Sully"
  },
  {
    "value": "50252",
    "label": "Swan - IA 50252",
    "county": "Marion County",
    "state": "IA",
    "city": "Swan"
  },
  {
    "value": "50254",
    "label": "Thayer - IA 50254",
    "county": "Union County",
    "state": "IA",
    "city": "Thayer"
  },
  {
    "value": "50256",
    "label": "Tracy - IA 50256",
    "county": "Marion County",
    "state": "IA",
    "city": "Tracy"
  },
  {
    "value": "50257",
    "label": "Truro - IA 50257",
    "county": "Madison County",
    "state": "IA",
    "city": "Truro"
  },
  {
    "value": "50258",
    "label": "Union - IA 50258",
    "county": "Hardin County",
    "state": "IA",
    "city": "Union"
  },
  {
    "value": "50259",
    "label": "Gifford - IA 50259",
    "county": "Hardin County",
    "state": "IA",
    "city": "Gifford"
  },
  {
    "value": "50261",
    "label": "Van Meter - IA 50261",
    "county": "Dallas County",
    "state": "IA",
    "city": "Van Meter"
  },
  {
    "value": "50262",
    "label": "Van Wert - IA 50262",
    "county": "Decatur County",
    "state": "IA",
    "city": "Van Wert"
  },
  {
    "value": "50263",
    "label": "Waukee - IA 50263",
    "county": "Dallas County",
    "state": "IA",
    "city": "Waukee"
  },
  {
    "value": "50264",
    "label": "Weldon - IA 50264",
    "county": "Decatur County",
    "state": "IA",
    "city": "Weldon"
  },
  {
    "value": "50265",
    "label": "West Des Moines - IA 50265",
    "county": "Polk County",
    "state": "IA",
    "city": "West Des Moines"
  },
  {
    "value": "50266",
    "label": "West Des Moines - IA 50266",
    "county": "Polk County",
    "state": "IA",
    "city": "West Des Moines"
  },
  {
    "value": "50268",
    "label": "What Cheer - IA 50268",
    "county": "Keokuk County",
    "state": "IA",
    "city": "What Cheer"
  },
  {
    "value": "50271",
    "label": "Williams - IA 50271",
    "county": "Hamilton County",
    "state": "IA",
    "city": "Williams"
  },
  {
    "value": "50273",
    "label": "Winterset - IA 50273",
    "county": "Madison County",
    "state": "IA",
    "city": "Winterset"
  },
  {
    "value": "50274",
    "label": "Wiota - IA 50274",
    "county": "Cass County",
    "state": "IA",
    "city": "Wiota"
  },
  {
    "value": "50275",
    "label": "Woodburn - IA 50275",
    "county": "Clarke County",
    "state": "IA",
    "city": "Woodburn"
  },
  {
    "value": "50276",
    "label": "Woodward - IA 50276",
    "county": "Dallas County",
    "state": "IA",
    "city": "Woodward"
  },
  {
    "value": "50277",
    "label": "Yale - IA 50277",
    "county": "Guthrie County",
    "state": "IA",
    "city": "Yale"
  },
  {
    "value": "50278",
    "label": "Zearing - IA 50278",
    "county": "Story County",
    "state": "IA",
    "city": "Zearing"
  },
  {
    "value": "50307",
    "label": "Des Moines - IA 50307",
    "county": "Polk County",
    "state": "IA",
    "city": "Des Moines"
  },
  {
    "value": "50308",
    "label": "Des Moines - IA 50308",
    "county": "Polk County",
    "state": "IA",
    "city": "Des Moines"
  },
  {
    "value": "50309",
    "label": "Des Moines - IA 50309",
    "county": "Polk County",
    "state": "IA",
    "city": "Des Moines"
  },
  {
    "value": "50310",
    "label": "Des Moines - IA 50310",
    "county": "Polk County",
    "state": "IA",
    "city": "Des Moines"
  },
  {
    "value": "50311",
    "label": "Des Moines - IA 50311",
    "county": "Polk County",
    "state": "IA",
    "city": "Des Moines"
  },
  {
    "value": "50312",
    "label": "Des Moines - IA 50312",
    "county": "Polk County",
    "state": "IA",
    "city": "Des Moines"
  },
  {
    "value": "50313",
    "label": "Des Moines - IA 50313",
    "county": "Polk County",
    "state": "IA",
    "city": "Des Moines"
  },
  {
    "value": "50314",
    "label": "Des Moines - IA 50314",
    "county": "Polk County",
    "state": "IA",
    "city": "Des Moines"
  },
  {
    "value": "50315",
    "label": "Des Moines - IA 50315",
    "county": "Polk County",
    "state": "IA",
    "city": "Des Moines"
  },
  {
    "value": "50316",
    "label": "Des Moines - IA 50316",
    "county": "Polk County",
    "state": "IA",
    "city": "Des Moines"
  },
  {
    "value": "50317",
    "label": "Des Moines - IA 50317",
    "county": "Polk County",
    "state": "IA",
    "city": "Des Moines"
  },
  {
    "value": "50319",
    "label": "Des Moines - IA 50319",
    "county": "Polk County",
    "state": "IA",
    "city": "Des Moines"
  },
  {
    "value": "50320",
    "label": "Des Moines - IA 50320",
    "county": "Polk County",
    "state": "IA",
    "city": "Des Moines"
  },
  {
    "value": "50321",
    "label": "Des Moines - IA 50321",
    "county": "Polk County",
    "state": "IA",
    "city": "Des Moines"
  },
  {
    "value": "50322",
    "label": "Urbandale - IA 50322",
    "county": "Polk County",
    "state": "IA",
    "city": "Urbandale"
  },
  {
    "value": "50323",
    "label": "Urbandale - IA 50323",
    "county": "Dallas County",
    "state": "IA",
    "city": "Urbandale"
  },
  {
    "value": "50324",
    "label": "Windsor Heights - IA 50324",
    "county": "Polk County",
    "state": "IA",
    "city": "Windsor Heights"
  },
  {
    "value": "50325",
    "label": "Clive - IA 50325",
    "county": "Polk County",
    "state": "IA",
    "city": "Clive"
  },
  {
    "value": "50327",
    "label": "Pleasant Hill - IA 50327",
    "county": "Polk County",
    "state": "IA",
    "city": "Pleasant Hill"
  },
  {
    "value": "50395",
    "label": "Des Moines - IA 50395",
    "county": "Polk County",
    "state": "IA",
    "city": "Des Moines"
  },
  {
    "value": "50401",
    "label": "Mason City - IA 50401",
    "county": "Cerro Gordo County",
    "state": "IA",
    "city": "Mason City"
  },
  {
    "value": "50420",
    "label": "Alexander - IA 50420",
    "county": "Franklin County",
    "state": "IA",
    "city": "Alexander"
  },
  {
    "value": "50421",
    "label": "Belmond - IA 50421",
    "county": "Wright County",
    "state": "IA",
    "city": "Belmond"
  },
  {
    "value": "50423",
    "label": "Britt - IA 50423",
    "county": "Hancock County",
    "state": "IA",
    "city": "Britt"
  },
  {
    "value": "50424",
    "label": "Buffalo Center - IA 50424",
    "county": "Winnebago County",
    "state": "IA",
    "city": "Buffalo Center"
  },
  {
    "value": "50426",
    "label": "Carpenter - IA 50426",
    "county": "Mitchell County",
    "state": "IA",
    "city": "Carpenter"
  },
  {
    "value": "50427",
    "label": "Chapin - IA 50427",
    "county": "Franklin County",
    "state": "IA",
    "city": "Chapin"
  },
  {
    "value": "50428",
    "label": "Clear Lake - IA 50428",
    "county": "Cerro Gordo County",
    "state": "IA",
    "city": "Clear Lake"
  },
  {
    "value": "50430",
    "label": "Corwith - IA 50430",
    "county": "Hancock County",
    "state": "IA",
    "city": "Corwith"
  },
  {
    "value": "50433",
    "label": "Dougherty - IA 50433",
    "county": "Cerro Gordo County",
    "state": "IA",
    "city": "Dougherty"
  },
  {
    "value": "50434",
    "label": "Fertile - IA 50434",
    "county": "Worth County",
    "state": "IA",
    "city": "Fertile"
  },
  {
    "value": "50435",
    "label": "Floyd - IA 50435",
    "county": "Floyd County",
    "state": "IA",
    "city": "Floyd"
  },
  {
    "value": "50436",
    "label": "Forest City - IA 50436",
    "county": "Winnebago County",
    "state": "IA",
    "city": "Forest City"
  },
  {
    "value": "50438",
    "label": "Garner - IA 50438",
    "county": "Hancock County",
    "state": "IA",
    "city": "Garner"
  },
  {
    "value": "50439",
    "label": "Goodell - IA 50439",
    "county": "Hancock County",
    "state": "IA",
    "city": "Goodell"
  },
  {
    "value": "50440",
    "label": "Grafton - IA 50440",
    "county": "Worth County",
    "state": "IA",
    "city": "Grafton"
  },
  {
    "value": "50441",
    "label": "Hampton - IA 50441",
    "county": "Franklin County",
    "state": "IA",
    "city": "Hampton"
  },
  {
    "value": "50444",
    "label": "Hanlontown - IA 50444",
    "county": "Worth County",
    "state": "IA",
    "city": "Hanlontown"
  },
  {
    "value": "50446",
    "label": "Joice - IA 50446",
    "county": "Worth County",
    "state": "IA",
    "city": "Joice"
  },
  {
    "value": "50447",
    "label": "Kanawha - IA 50447",
    "county": "Hancock County",
    "state": "IA",
    "city": "Kanawha"
  },
  {
    "value": "50448",
    "label": "Kensett - IA 50448",
    "county": "Worth County",
    "state": "IA",
    "city": "Kensett"
  },
  {
    "value": "50449",
    "label": "Klemme - IA 50449",
    "county": "Hancock County",
    "state": "IA",
    "city": "Klemme"
  },
  {
    "value": "50450",
    "label": "Lake Mills - IA 50450",
    "county": "Winnebago County",
    "state": "IA",
    "city": "Lake Mills"
  },
  {
    "value": "50451",
    "label": "Lakota - IA 50451",
    "county": "Kossuth County",
    "state": "IA",
    "city": "Lakota"
  },
  {
    "value": "50452",
    "label": "Latimer - IA 50452",
    "county": "Franklin County",
    "state": "IA",
    "city": "Latimer"
  },
  {
    "value": "50453",
    "label": "Leland - IA 50453",
    "county": "Winnebago County",
    "state": "IA",
    "city": "Leland"
  },
  {
    "value": "50454",
    "label": "Little Cedar - IA 50454",
    "county": "Mitchell County",
    "state": "IA",
    "city": "Little Cedar"
  },
  {
    "value": "50455",
    "label": "Mc Intire - IA 50455",
    "county": "Mitchell County",
    "state": "IA",
    "city": "Mc Intire"
  },
  {
    "value": "50456",
    "label": "Manly - IA 50456",
    "county": "Worth County",
    "state": "IA",
    "city": "Manly"
  },
  {
    "value": "50457",
    "label": "Meservey - IA 50457",
    "county": "Cerro Gordo County",
    "state": "IA",
    "city": "Meservey"
  },
  {
    "value": "50458",
    "label": "Nora Springs - IA 50458",
    "county": "Floyd County",
    "state": "IA",
    "city": "Nora Springs"
  },
  {
    "value": "50459",
    "label": "Northwood - IA 50459",
    "county": "Worth County",
    "state": "IA",
    "city": "Northwood"
  },
  {
    "value": "50460",
    "label": "Orchard - IA 50460",
    "county": "Mitchell County",
    "state": "IA",
    "city": "Orchard"
  },
  {
    "value": "50461",
    "label": "Osage - IA 50461",
    "county": "Mitchell County",
    "state": "IA",
    "city": "Osage"
  },
  {
    "value": "50464",
    "label": "Plymouth - IA 50464",
    "county": "Cerro Gordo County",
    "state": "IA",
    "city": "Plymouth"
  },
  {
    "value": "50466",
    "label": "Riceville - IA 50466",
    "county": "Mitchell County",
    "state": "IA",
    "city": "Riceville"
  },
  {
    "value": "50467",
    "label": "Rock Falls - IA 50467",
    "county": "Cerro Gordo County",
    "state": "IA",
    "city": "Rock Falls"
  },
  {
    "value": "50468",
    "label": "Rockford - IA 50468",
    "county": "Floyd County",
    "state": "IA",
    "city": "Rockford"
  },
  {
    "value": "50469",
    "label": "Rockwell - IA 50469",
    "county": "Cerro Gordo County",
    "state": "IA",
    "city": "Rockwell"
  },
  {
    "value": "50470",
    "label": "Rowan - IA 50470",
    "county": "Wright County",
    "state": "IA",
    "city": "Rowan"
  },
  {
    "value": "50471",
    "label": "Rudd - IA 50471",
    "county": "Floyd County",
    "state": "IA",
    "city": "Rudd"
  },
  {
    "value": "50472",
    "label": "Saint Ansgar - IA 50472",
    "county": "Mitchell County",
    "state": "IA",
    "city": "Saint Ansgar"
  },
  {
    "value": "50473",
    "label": "Scarville - IA 50473",
    "county": "Winnebago County",
    "state": "IA",
    "city": "Scarville"
  },
  {
    "value": "50475",
    "label": "Sheffield - IA 50475",
    "county": "Franklin County",
    "state": "IA",
    "city": "Sheffield"
  },
  {
    "value": "50476",
    "label": "Stacyville - IA 50476",
    "county": "Mitchell County",
    "state": "IA",
    "city": "Stacyville"
  },
  {
    "value": "50477",
    "label": "Swaledale - IA 50477",
    "county": "Cerro Gordo County",
    "state": "IA",
    "city": "Swaledale"
  },
  {
    "value": "50478",
    "label": "Thompson - IA 50478",
    "county": "Winnebago County",
    "state": "IA",
    "city": "Thompson"
  },
  {
    "value": "50479",
    "label": "Thornton - IA 50479",
    "county": "Cerro Gordo County",
    "state": "IA",
    "city": "Thornton"
  },
  {
    "value": "50480",
    "label": "Titonka - IA 50480",
    "county": "Kossuth County",
    "state": "IA",
    "city": "Titonka"
  },
  {
    "value": "50481",
    "label": "Toeterville - IA 50481",
    "county": "Mitchell County",
    "state": "IA",
    "city": "Toeterville"
  },
  {
    "value": "50482",
    "label": "Ventura - IA 50482",
    "county": "Cerro Gordo County",
    "state": "IA",
    "city": "Ventura"
  },
  {
    "value": "50483",
    "label": "Wesley - IA 50483",
    "county": "Kossuth County",
    "state": "IA",
    "city": "Wesley"
  },
  {
    "value": "50484",
    "label": "Woden - IA 50484",
    "county": "Hancock County",
    "state": "IA",
    "city": "Woden"
  },
  {
    "value": "50501",
    "label": "Fort Dodge - IA 50501",
    "county": "Webster County",
    "state": "IA",
    "city": "Fort Dodge"
  },
  {
    "value": "50510",
    "label": "Albert City - IA 50510",
    "county": "Buena Vista County",
    "state": "IA",
    "city": "Albert City"
  },
  {
    "value": "50511",
    "label": "Algona - IA 50511",
    "county": "Kossuth County",
    "state": "IA",
    "city": "Algona"
  },
  {
    "value": "50514",
    "label": "Armstrong - IA 50514",
    "county": "Emmet County",
    "state": "IA",
    "city": "Armstrong"
  },
  {
    "value": "50515",
    "label": "Ayrshire - IA 50515",
    "county": "Palo Alto County",
    "state": "IA",
    "city": "Ayrshire"
  },
  {
    "value": "50516",
    "label": "Badger - IA 50516",
    "county": "Webster County",
    "state": "IA",
    "city": "Badger"
  },
  {
    "value": "50517",
    "label": "Bancroft - IA 50517",
    "county": "Kossuth County",
    "state": "IA",
    "city": "Bancroft"
  },
  {
    "value": "50518",
    "label": "Barnum - IA 50518",
    "county": "Webster County",
    "state": "IA",
    "city": "Barnum"
  },
  {
    "value": "50519",
    "label": "Bode - IA 50519",
    "county": "Humboldt County",
    "state": "IA",
    "city": "Bode"
  },
  {
    "value": "50520",
    "label": "Bradgate - IA 50520",
    "county": "Humboldt County",
    "state": "IA",
    "city": "Bradgate"
  },
  {
    "value": "50522",
    "label": "Burt - IA 50522",
    "county": "Kossuth County",
    "state": "IA",
    "city": "Burt"
  },
  {
    "value": "50523",
    "label": "Callender - IA 50523",
    "county": "Webster County",
    "state": "IA",
    "city": "Callender"
  },
  {
    "value": "50524",
    "label": "Clare - IA 50524",
    "county": "Webster County",
    "state": "IA",
    "city": "Clare"
  },
  {
    "value": "50525",
    "label": "Clarion - IA 50525",
    "county": "Wright County",
    "state": "IA",
    "city": "Clarion"
  },
  {
    "value": "50527",
    "label": "Curlew - IA 50527",
    "county": "Palo Alto County",
    "state": "IA",
    "city": "Curlew"
  },
  {
    "value": "50528",
    "label": "Cylinder - IA 50528",
    "county": "Palo Alto County",
    "state": "IA",
    "city": "Cylinder"
  },
  {
    "value": "50529",
    "label": "Dakota City - IA 50529",
    "county": "Humboldt County",
    "state": "IA",
    "city": "Dakota City"
  },
  {
    "value": "50530",
    "label": "Dayton - IA 50530",
    "county": "Webster County",
    "state": "IA",
    "city": "Dayton"
  },
  {
    "value": "50531",
    "label": "Dolliver - IA 50531",
    "county": "Emmet County",
    "state": "IA",
    "city": "Dolliver"
  },
  {
    "value": "50532",
    "label": "Duncombe - IA 50532",
    "county": "Webster County",
    "state": "IA",
    "city": "Duncombe"
  },
  {
    "value": "50533",
    "label": "Eagle Grove - IA 50533",
    "county": "Wright County",
    "state": "IA",
    "city": "Eagle Grove"
  },
  {
    "value": "50535",
    "label": "Early - IA 50535",
    "county": "Sac County",
    "state": "IA",
    "city": "Early"
  },
  {
    "value": "50536",
    "label": "Emmetsburg - IA 50536",
    "county": "Palo Alto County",
    "state": "IA",
    "city": "Emmetsburg"
  },
  {
    "value": "50538",
    "label": "Farnhamville - IA 50538",
    "county": "Calhoun County",
    "state": "IA",
    "city": "Farnhamville"
  },
  {
    "value": "50539",
    "label": "Fenton - IA 50539",
    "county": "Kossuth County",
    "state": "IA",
    "city": "Fenton"
  },
  {
    "value": "50540",
    "label": "Fonda - IA 50540",
    "county": "Pocahontas County",
    "state": "IA",
    "city": "Fonda"
  },
  {
    "value": "50541",
    "label": "Gilmore City - IA 50541",
    "county": "Humboldt County",
    "state": "IA",
    "city": "Gilmore City"
  },
  {
    "value": "50542",
    "label": "Goldfield - IA 50542",
    "county": "Wright County",
    "state": "IA",
    "city": "Goldfield"
  },
  {
    "value": "50543",
    "label": "Gowrie - IA 50543",
    "county": "Webster County",
    "state": "IA",
    "city": "Gowrie"
  },
  {
    "value": "50544",
    "label": "Harcourt - IA 50544",
    "county": "Webster County",
    "state": "IA",
    "city": "Harcourt"
  },
  {
    "value": "50545",
    "label": "Hardy - IA 50545",
    "county": "Humboldt County",
    "state": "IA",
    "city": "Hardy"
  },
  {
    "value": "50546",
    "label": "Havelock - IA 50546",
    "county": "Pocahontas County",
    "state": "IA",
    "city": "Havelock"
  },
  {
    "value": "50548",
    "label": "Humboldt - IA 50548",
    "county": "Humboldt County",
    "state": "IA",
    "city": "Humboldt"
  },
  {
    "value": "50551",
    "label": "Jolley - IA 50551",
    "county": "Calhoun County",
    "state": "IA",
    "city": "Jolley"
  },
  {
    "value": "50554",
    "label": "Laurens - IA 50554",
    "county": "Pocahontas County",
    "state": "IA",
    "city": "Laurens"
  },
  {
    "value": "50556",
    "label": "Ledyard - IA 50556",
    "county": "Kossuth County",
    "state": "IA",
    "city": "Ledyard"
  },
  {
    "value": "50557",
    "label": "Lehigh - IA 50557",
    "county": "Webster County",
    "state": "IA",
    "city": "Lehigh"
  },
  {
    "value": "50558",
    "label": "Livermore - IA 50558",
    "county": "Humboldt County",
    "state": "IA",
    "city": "Livermore"
  },
  {
    "value": "50559",
    "label": "Lone Rock - IA 50559",
    "county": "Kossuth County",
    "state": "IA",
    "city": "Lone Rock"
  },
  {
    "value": "50560",
    "label": "Lu Verne - IA 50560",
    "county": "Kossuth County",
    "state": "IA",
    "city": "Lu Verne"
  },
  {
    "value": "50561",
    "label": "Lytton - IA 50561",
    "county": "Sac County",
    "state": "IA",
    "city": "Lytton"
  },
  {
    "value": "50562",
    "label": "Mallard - IA 50562",
    "county": "Palo Alto County",
    "state": "IA",
    "city": "Mallard"
  },
  {
    "value": "50563",
    "label": "Manson - IA 50563",
    "county": "Calhoun County",
    "state": "IA",
    "city": "Manson"
  },
  {
    "value": "50565",
    "label": "Marathon - IA 50565",
    "county": "Buena Vista County",
    "state": "IA",
    "city": "Marathon"
  },
  {
    "value": "50566",
    "label": "Moorland - IA 50566",
    "county": "Webster County",
    "state": "IA",
    "city": "Moorland"
  },
  {
    "value": "50567",
    "label": "Nemaha - IA 50567",
    "county": "Sac County",
    "state": "IA",
    "city": "Nemaha"
  },
  {
    "value": "50568",
    "label": "Newell - IA 50568",
    "county": "Buena Vista County",
    "state": "IA",
    "city": "Newell"
  },
  {
    "value": "50569",
    "label": "Otho - IA 50569",
    "county": "Webster County",
    "state": "IA",
    "city": "Otho"
  },
  {
    "value": "50570",
    "label": "Ottosen - IA 50570",
    "county": "Humboldt County",
    "state": "IA",
    "city": "Ottosen"
  },
  {
    "value": "50571",
    "label": "Palmer - IA 50571",
    "county": "Pocahontas County",
    "state": "IA",
    "city": "Palmer"
  },
  {
    "value": "50574",
    "label": "Pocahontas - IA 50574",
    "county": "Pocahontas County",
    "state": "IA",
    "city": "Pocahontas"
  },
  {
    "value": "50575",
    "label": "Pomeroy - IA 50575",
    "county": "Calhoun County",
    "state": "IA",
    "city": "Pomeroy"
  },
  {
    "value": "50576",
    "label": "Rembrandt - IA 50576",
    "county": "Buena Vista County",
    "state": "IA",
    "city": "Rembrandt"
  },
  {
    "value": "50577",
    "label": "Renwick - IA 50577",
    "county": "Humboldt County",
    "state": "IA",
    "city": "Renwick"
  },
  {
    "value": "50578",
    "label": "Ringsted - IA 50578",
    "county": "Emmet County",
    "state": "IA",
    "city": "Ringsted"
  },
  {
    "value": "50579",
    "label": "Rockwell City - IA 50579",
    "county": "Calhoun County",
    "state": "IA",
    "city": "Rockwell City"
  },
  {
    "value": "50581",
    "label": "Rolfe - IA 50581",
    "county": "Pocahontas County",
    "state": "IA",
    "city": "Rolfe"
  },
  {
    "value": "50582",
    "label": "Rutland - IA 50582",
    "county": "Humboldt County",
    "state": "IA",
    "city": "Rutland"
  },
  {
    "value": "50583",
    "label": "Sac City - IA 50583",
    "county": "Sac County",
    "state": "IA",
    "city": "Sac City"
  },
  {
    "value": "50585",
    "label": "Sioux Rapids - IA 50585",
    "county": "Buena Vista County",
    "state": "IA",
    "city": "Sioux Rapids"
  },
  {
    "value": "50586",
    "label": "Somers - IA 50586",
    "county": "Calhoun County",
    "state": "IA",
    "city": "Somers"
  },
  {
    "value": "50588",
    "label": "Storm Lake - IA 50588",
    "county": "Buena Vista County",
    "state": "IA",
    "city": "Storm Lake"
  },
  {
    "value": "50590",
    "label": "Swea City - IA 50590",
    "county": "Kossuth County",
    "state": "IA",
    "city": "Swea City"
  },
  {
    "value": "50591",
    "label": "Thor - IA 50591",
    "county": "Humboldt County",
    "state": "IA",
    "city": "Thor"
  },
  {
    "value": "50594",
    "label": "Vincent - IA 50594",
    "county": "Webster County",
    "state": "IA",
    "city": "Vincent"
  },
  {
    "value": "50595",
    "label": "Webster City - IA 50595",
    "county": "Hamilton County",
    "state": "IA",
    "city": "Webster City"
  },
  {
    "value": "50597",
    "label": "West Bend - IA 50597",
    "county": "Palo Alto County",
    "state": "IA",
    "city": "West Bend"
  },
  {
    "value": "50598",
    "label": "Whittemore - IA 50598",
    "county": "Kossuth County",
    "state": "IA",
    "city": "Whittemore"
  },
  {
    "value": "50599",
    "label": "Woolstock - IA 50599",
    "county": "Wright County",
    "state": "IA",
    "city": "Woolstock"
  },
  {
    "value": "50601",
    "label": "Ackley - IA 50601",
    "county": "Hardin County",
    "state": "IA",
    "city": "Ackley"
  },
  {
    "value": "50602",
    "label": "Allison - IA 50602",
    "county": "Butler County",
    "state": "IA",
    "city": "Allison"
  },
  {
    "value": "50603",
    "label": "Alta Vista - IA 50603",
    "county": "Chickasaw County",
    "state": "IA",
    "city": "Alta Vista"
  },
  {
    "value": "50604",
    "label": "Aplington - IA 50604",
    "county": "Butler County",
    "state": "IA",
    "city": "Aplington"
  },
  {
    "value": "50605",
    "label": "Aredale - IA 50605",
    "county": "Butler County",
    "state": "IA",
    "city": "Aredale"
  },
  {
    "value": "50606",
    "label": "Arlington - IA 50606",
    "county": "Fayette County",
    "state": "IA",
    "city": "Arlington"
  },
  {
    "value": "50607",
    "label": "Aurora - IA 50607",
    "county": "Buchanan County",
    "state": "IA",
    "city": "Aurora"
  },
  {
    "value": "50608",
    "label": "Austinville - IA 50608",
    "county": "Butler County",
    "state": "IA",
    "city": "Austinville"
  },
  {
    "value": "50609",
    "label": "Beaman - IA 50609",
    "county": "Grundy County",
    "state": "IA",
    "city": "Beaman"
  },
  {
    "value": "50611",
    "label": "Bristow - IA 50611",
    "county": "Butler County",
    "state": "IA",
    "city": "Bristow"
  },
  {
    "value": "50612",
    "label": "Buckingham - IA 50612",
    "county": "Tama County",
    "state": "IA",
    "city": "Buckingham"
  },
  {
    "value": "50613",
    "label": "Cedar Falls - IA 50613",
    "county": "Black Hawk County",
    "state": "IA",
    "city": "Cedar Falls"
  },
  {
    "value": "50616",
    "label": "Charles City - IA 50616",
    "county": "Floyd County",
    "state": "IA",
    "city": "Charles City"
  },
  {
    "value": "50619",
    "label": "Clarksville - IA 50619",
    "county": "Butler County",
    "state": "IA",
    "city": "Clarksville"
  },
  {
    "value": "50620",
    "label": "Colwell - IA 50620",
    "county": "Floyd County",
    "state": "IA",
    "city": "Colwell"
  },
  {
    "value": "50621",
    "label": "Conrad - IA 50621",
    "county": "Grundy County",
    "state": "IA",
    "city": "Conrad"
  },
  {
    "value": "50622",
    "label": "Denver - IA 50622",
    "county": "Bremer County",
    "state": "IA",
    "city": "Denver"
  },
  {
    "value": "50624",
    "label": "Dike - IA 50624",
    "county": "Grundy County",
    "state": "IA",
    "city": "Dike"
  },
  {
    "value": "50625",
    "label": "Dumont - IA 50625",
    "county": "Butler County",
    "state": "IA",
    "city": "Dumont"
  },
  {
    "value": "50626",
    "label": "Dunkerton - IA 50626",
    "county": "Black Hawk County",
    "state": "IA",
    "city": "Dunkerton"
  },
  {
    "value": "50627",
    "label": "Eldora - IA 50627",
    "county": "Hardin County",
    "state": "IA",
    "city": "Eldora"
  },
  {
    "value": "50628",
    "label": "Elma - IA 50628",
    "county": "Howard County",
    "state": "IA",
    "city": "Elma"
  },
  {
    "value": "50629",
    "label": "Fairbank - IA 50629",
    "county": "Buchanan County",
    "state": "IA",
    "city": "Fairbank"
  },
  {
    "value": "50630",
    "label": "Fredericksburg - IA 50630",
    "county": "Chickasaw County",
    "state": "IA",
    "city": "Fredericksburg"
  },
  {
    "value": "50632",
    "label": "Garwin - IA 50632",
    "county": "Tama County",
    "state": "IA",
    "city": "Garwin"
  },
  {
    "value": "50633",
    "label": "Geneva - IA 50633",
    "county": "Franklin County",
    "state": "IA",
    "city": "Geneva"
  },
  {
    "value": "50635",
    "label": "Gladbrook - IA 50635",
    "county": "Tama County",
    "state": "IA",
    "city": "Gladbrook"
  },
  {
    "value": "50636",
    "label": "Greene - IA 50636",
    "county": "Butler County",
    "state": "IA",
    "city": "Greene"
  },
  {
    "value": "50638",
    "label": "Grundy Center - IA 50638",
    "county": "Grundy County",
    "state": "IA",
    "city": "Grundy Center"
  },
  {
    "value": "50641",
    "label": "Hazleton - IA 50641",
    "county": "Buchanan County",
    "state": "IA",
    "city": "Hazleton"
  },
  {
    "value": "50642",
    "label": "Holland - IA 50642",
    "county": "Grundy County",
    "state": "IA",
    "city": "Holland"
  },
  {
    "value": "50643",
    "label": "Hudson - IA 50643",
    "county": "Black Hawk County",
    "state": "IA",
    "city": "Hudson"
  },
  {
    "value": "50644",
    "label": "Independence - IA 50644",
    "county": "Buchanan County",
    "state": "IA",
    "city": "Independence"
  },
  {
    "value": "50645",
    "label": "Ionia - IA 50645",
    "county": "Chickasaw County",
    "state": "IA",
    "city": "Ionia"
  },
  {
    "value": "50647",
    "label": "Janesville - IA 50647",
    "county": "Bremer County",
    "state": "IA",
    "city": "Janesville"
  },
  {
    "value": "50648",
    "label": "Jesup - IA 50648",
    "county": "Buchanan County",
    "state": "IA",
    "city": "Jesup"
  },
  {
    "value": "50649",
    "label": "Kesley - IA 50649",
    "county": "Butler County",
    "state": "IA",
    "city": "Kesley"
  },
  {
    "value": "50650",
    "label": "Lamont - IA 50650",
    "county": "Buchanan County",
    "state": "IA",
    "city": "Lamont"
  },
  {
    "value": "50651",
    "label": "La Porte City - IA 50651",
    "county": "Black Hawk County",
    "state": "IA",
    "city": "La Porte City"
  },
  {
    "value": "50653",
    "label": "Marble Rock - IA 50653",
    "county": "Floyd County",
    "state": "IA",
    "city": "Marble Rock"
  },
  {
    "value": "50654",
    "label": "Masonville - IA 50654",
    "county": "Delaware County",
    "state": "IA",
    "city": "Masonville"
  },
  {
    "value": "50655",
    "label": "Maynard - IA 50655",
    "county": "Fayette County",
    "state": "IA",
    "city": "Maynard"
  },
  {
    "value": "50657",
    "label": "Morrison - IA 50657",
    "county": "Grundy County",
    "state": "IA",
    "city": "Morrison"
  },
  {
    "value": "50658",
    "label": "Nashua - IA 50658",
    "county": "Chickasaw County",
    "state": "IA",
    "city": "Nashua"
  },
  {
    "value": "50659",
    "label": "New Hampton - IA 50659",
    "county": "Chickasaw County",
    "state": "IA",
    "city": "New Hampton"
  },
  {
    "value": "50660",
    "label": "New Hartford - IA 50660",
    "county": "Butler County",
    "state": "IA",
    "city": "New Hartford"
  },
  {
    "value": "50662",
    "label": "Oelwein - IA 50662",
    "county": "Fayette County",
    "state": "IA",
    "city": "Oelwein"
  },
  {
    "value": "50664",
    "label": "Oran - IA 50664",
    "county": "Fayette County",
    "state": "IA",
    "city": "Oran"
  },
  {
    "value": "50665",
    "label": "Parkersburg - IA 50665",
    "county": "Butler County",
    "state": "IA",
    "city": "Parkersburg"
  },
  {
    "value": "50666",
    "label": "Plainfield - IA 50666",
    "county": "Bremer County",
    "state": "IA",
    "city": "Plainfield"
  },
  {
    "value": "50667",
    "label": "Raymond - IA 50667",
    "county": "Black Hawk County",
    "state": "IA",
    "city": "Raymond"
  },
  {
    "value": "50668",
    "label": "Readlyn - IA 50668",
    "county": "Bremer County",
    "state": "IA",
    "city": "Readlyn"
  },
  {
    "value": "50669",
    "label": "Reinbeck - IA 50669",
    "county": "Grundy County",
    "state": "IA",
    "city": "Reinbeck"
  },
  {
    "value": "50670",
    "label": "Shell Rock - IA 50670",
    "county": "Butler County",
    "state": "IA",
    "city": "Shell Rock"
  },
  {
    "value": "50671",
    "label": "Stanley - IA 50671",
    "county": "Buchanan County",
    "state": "IA",
    "city": "Stanley"
  },
  {
    "value": "50672",
    "label": "Steamboat Rock - IA 50672",
    "county": "Hardin County",
    "state": "IA",
    "city": "Steamboat Rock"
  },
  {
    "value": "50674",
    "label": "Sumner - IA 50674",
    "county": "Bremer County",
    "state": "IA",
    "city": "Sumner"
  },
  {
    "value": "50675",
    "label": "Traer - IA 50675",
    "county": "Tama County",
    "state": "IA",
    "city": "Traer"
  },
  {
    "value": "50676",
    "label": "Tripoli - IA 50676",
    "county": "Bremer County",
    "state": "IA",
    "city": "Tripoli"
  },
  {
    "value": "50677",
    "label": "Waverly - IA 50677",
    "county": "Bremer County",
    "state": "IA",
    "city": "Waverly"
  },
  {
    "value": "50680",
    "label": "Wellsburg - IA 50680",
    "county": "Grundy County",
    "state": "IA",
    "city": "Wellsburg"
  },
  {
    "value": "50681",
    "label": "Westgate - IA 50681",
    "county": "Fayette County",
    "state": "IA",
    "city": "Westgate"
  },
  {
    "value": "50682",
    "label": "Winthrop - IA 50682",
    "county": "Buchanan County",
    "state": "IA",
    "city": "Winthrop"
  },
  {
    "value": "50701",
    "label": "Waterloo - IA 50701",
    "county": "Black Hawk County",
    "state": "IA",
    "city": "Waterloo"
  },
  {
    "value": "50702",
    "label": "Waterloo - IA 50702",
    "county": "Black Hawk County",
    "state": "IA",
    "city": "Waterloo"
  },
  {
    "value": "50703",
    "label": "Waterloo - IA 50703",
    "county": "Black Hawk County",
    "state": "IA",
    "city": "Waterloo"
  },
  {
    "value": "50706",
    "label": "Waterloo - IA 50706",
    "county": "Black Hawk County",
    "state": "IA",
    "city": "Waterloo"
  },
  {
    "value": "50707",
    "label": "Evansdale - IA 50707",
    "county": "Black Hawk County",
    "state": "IA",
    "city": "Evansdale"
  },
  {
    "value": "50801",
    "label": "Creston - IA 50801",
    "county": "Union County",
    "state": "IA",
    "city": "Creston"
  },
  {
    "value": "50830",
    "label": "Afton - IA 50830",
    "county": "Union County",
    "state": "IA",
    "city": "Afton"
  },
  {
    "value": "50833",
    "label": "Bedford - IA 50833",
    "county": "Taylor County",
    "state": "IA",
    "city": "Bedford"
  },
  {
    "value": "50835",
    "label": "Benton - IA 50835",
    "county": "Ringgold County",
    "state": "IA",
    "city": "Benton"
  },
  {
    "value": "50836",
    "label": "Blockton - IA 50836",
    "county": "Taylor County",
    "state": "IA",
    "city": "Blockton"
  },
  {
    "value": "50837",
    "label": "Bridgewater - IA 50837",
    "county": "Adair County",
    "state": "IA",
    "city": "Bridgewater"
  },
  {
    "value": "50839",
    "label": "Carbon - IA 50839",
    "county": "Adams County",
    "state": "IA",
    "city": "Carbon"
  },
  {
    "value": "50840",
    "label": "Clearfield - IA 50840",
    "county": "Taylor County",
    "state": "IA",
    "city": "Clearfield"
  },
  {
    "value": "50841",
    "label": "Corning - IA 50841",
    "county": "Adams County",
    "state": "IA",
    "city": "Corning"
  },
  {
    "value": "50842",
    "label": "Cromwell - IA 50842",
    "county": "Union County",
    "state": "IA",
    "city": "Cromwell"
  },
  {
    "value": "50843",
    "label": "Cumberland - IA 50843",
    "county": "Cass County",
    "state": "IA",
    "city": "Cumberland"
  },
  {
    "value": "50845",
    "label": "Diagonal - IA 50845",
    "county": "Ringgold County",
    "state": "IA",
    "city": "Diagonal"
  },
  {
    "value": "50846",
    "label": "Fontanelle - IA 50846",
    "county": "Adair County",
    "state": "IA",
    "city": "Fontanelle"
  },
  {
    "value": "50848",
    "label": "Gravity - IA 50848",
    "county": "Taylor County",
    "state": "IA",
    "city": "Gravity"
  },
  {
    "value": "50849",
    "label": "Greenfield - IA 50849",
    "county": "Adair County",
    "state": "IA",
    "city": "Greenfield"
  },
  {
    "value": "50851",
    "label": "Lenox - IA 50851",
    "county": "Taylor County",
    "state": "IA",
    "city": "Lenox"
  },
  {
    "value": "50853",
    "label": "Massena - IA 50853",
    "county": "Cass County",
    "state": "IA",
    "city": "Massena"
  },
  {
    "value": "50854",
    "label": "Mount Ayr - IA 50854",
    "county": "Ringgold County",
    "state": "IA",
    "city": "Mount Ayr"
  },
  {
    "value": "50857",
    "label": "Nodaway - IA 50857",
    "county": "Adams County",
    "state": "IA",
    "city": "Nodaway"
  },
  {
    "value": "50858",
    "label": "Orient - IA 50858",
    "county": "Adair County",
    "state": "IA",
    "city": "Orient"
  },
  {
    "value": "50859",
    "label": "Prescott - IA 50859",
    "county": "Adams County",
    "state": "IA",
    "city": "Prescott"
  },
  {
    "value": "50860",
    "label": "Redding - IA 50860",
    "county": "Ringgold County",
    "state": "IA",
    "city": "Redding"
  },
  {
    "value": "50861",
    "label": "Shannon City - IA 50861",
    "county": "Union County",
    "state": "IA",
    "city": "Shannon City"
  },
  {
    "value": "50862",
    "label": "Sharpsburg - IA 50862",
    "county": "Taylor County",
    "state": "IA",
    "city": "Sharpsburg"
  },
  {
    "value": "50863",
    "label": "Tingley - IA 50863",
    "county": "Ringgold County",
    "state": "IA",
    "city": "Tingley"
  },
  {
    "value": "50864",
    "label": "Villisca - IA 50864",
    "county": "Montgomery County",
    "state": "IA",
    "city": "Villisca"
  },
  {
    "value": "50950",
    "label": "Boone - IA 50950",
    "county": "Polk County",
    "state": "IA",
    "city": "Boone"
  },
  {
    "value": "51001",
    "label": "Akron - IA 51001",
    "county": "Plymouth County",
    "state": "IA",
    "city": "Akron"
  },
  {
    "value": "51002",
    "label": "Alta - IA 51002",
    "county": "Buena Vista County",
    "state": "IA",
    "city": "Alta"
  },
  {
    "value": "51003",
    "label": "Alton - IA 51003",
    "county": "Sioux County",
    "state": "IA",
    "city": "Alton"
  },
  {
    "value": "51004",
    "label": "Anthon - IA 51004",
    "county": "Woodbury County",
    "state": "IA",
    "city": "Anthon"
  },
  {
    "value": "51005",
    "label": "Aurelia - IA 51005",
    "county": "Cherokee County",
    "state": "IA",
    "city": "Aurelia"
  },
  {
    "value": "51006",
    "label": "Battle Creek - IA 51006",
    "county": "Ida County",
    "state": "IA",
    "city": "Battle Creek"
  },
  {
    "value": "51007",
    "label": "Bronson - IA 51007",
    "county": "Woodbury County",
    "state": "IA",
    "city": "Bronson"
  },
  {
    "value": "51010",
    "label": "Castana - IA 51010",
    "county": "Monona County",
    "state": "IA",
    "city": "Castana"
  },
  {
    "value": "51011",
    "label": "Chatsworth - IA 51011",
    "county": "Sioux County",
    "state": "IA",
    "city": "Chatsworth"
  },
  {
    "value": "51012",
    "label": "Cherokee - IA 51012",
    "county": "Cherokee County",
    "state": "IA",
    "city": "Cherokee"
  },
  {
    "value": "51014",
    "label": "Cleghorn - IA 51014",
    "county": "Cherokee County",
    "state": "IA",
    "city": "Cleghorn"
  },
  {
    "value": "51015",
    "label": "Climbing Hill - IA 51015",
    "county": "Woodbury County",
    "state": "IA",
    "city": "Climbing Hill"
  },
  {
    "value": "51016",
    "label": "Correctionville - IA 51016",
    "county": "Woodbury County",
    "state": "IA",
    "city": "Correctionville"
  },
  {
    "value": "51018",
    "label": "Cushing - IA 51018",
    "county": "Woodbury County",
    "state": "IA",
    "city": "Cushing"
  },
  {
    "value": "51019",
    "label": "Danbury - IA 51019",
    "county": "Woodbury County",
    "state": "IA",
    "city": "Danbury"
  },
  {
    "value": "51020",
    "label": "Galva - IA 51020",
    "county": "Ida County",
    "state": "IA",
    "city": "Galva"
  },
  {
    "value": "51022",
    "label": "Granville - IA 51022",
    "county": "Sioux County",
    "state": "IA",
    "city": "Granville"
  },
  {
    "value": "51023",
    "label": "Hawarden - IA 51023",
    "county": "Sioux County",
    "state": "IA",
    "city": "Hawarden"
  },
  {
    "value": "51024",
    "label": "Hinton - IA 51024",
    "county": "Plymouth County",
    "state": "IA",
    "city": "Hinton"
  },
  {
    "value": "51025",
    "label": "Holstein - IA 51025",
    "county": "Ida County",
    "state": "IA",
    "city": "Holstein"
  },
  {
    "value": "51026",
    "label": "Hornick - IA 51026",
    "county": "Woodbury County",
    "state": "IA",
    "city": "Hornick"
  },
  {
    "value": "51027",
    "label": "Ireton - IA 51027",
    "county": "Sioux County",
    "state": "IA",
    "city": "Ireton"
  },
  {
    "value": "51028",
    "label": "Kingsley - IA 51028",
    "county": "Plymouth County",
    "state": "IA",
    "city": "Kingsley"
  },
  {
    "value": "51029",
    "label": "Larrabee - IA 51029",
    "county": "Cherokee County",
    "state": "IA",
    "city": "Larrabee"
  },
  {
    "value": "51030",
    "label": "Lawton - IA 51030",
    "county": "Woodbury County",
    "state": "IA",
    "city": "Lawton"
  },
  {
    "value": "51031",
    "label": "Le Mars - IA 51031",
    "county": "Plymouth County",
    "state": "IA",
    "city": "Le Mars"
  },
  {
    "value": "51033",
    "label": "Linn Grove - IA 51033",
    "county": "Buena Vista County",
    "state": "IA",
    "city": "Linn Grove"
  },
  {
    "value": "51034",
    "label": "Mapleton - IA 51034",
    "county": "Monona County",
    "state": "IA",
    "city": "Mapleton"
  },
  {
    "value": "51035",
    "label": "Marcus - IA 51035",
    "county": "Cherokee County",
    "state": "IA",
    "city": "Marcus"
  },
  {
    "value": "51036",
    "label": "Maurice - IA 51036",
    "county": "Sioux County",
    "state": "IA",
    "city": "Maurice"
  },
  {
    "value": "51037",
    "label": "Meriden - IA 51037",
    "county": "Cherokee County",
    "state": "IA",
    "city": "Meriden"
  },
  {
    "value": "51038",
    "label": "Merrill - IA 51038",
    "county": "Plymouth County",
    "state": "IA",
    "city": "Merrill"
  },
  {
    "value": "51039",
    "label": "Moville - IA 51039",
    "county": "Woodbury County",
    "state": "IA",
    "city": "Moville"
  },
  {
    "value": "51040",
    "label": "Onawa - IA 51040",
    "county": "Monona County",
    "state": "IA",
    "city": "Onawa"
  },
  {
    "value": "51041",
    "label": "Orange City - IA 51041",
    "county": "Sioux County",
    "state": "IA",
    "city": "Orange City"
  },
  {
    "value": "51044",
    "label": "Oto - IA 51044",
    "county": "Woodbury County",
    "state": "IA",
    "city": "Oto"
  },
  {
    "value": "51046",
    "label": "Paullina - IA 51046",
    "county": "O'Brien County",
    "state": "IA",
    "city": "Paullina"
  },
  {
    "value": "51047",
    "label": "Peterson - IA 51047",
    "county": "Clay County",
    "state": "IA",
    "city": "Peterson"
  },
  {
    "value": "51048",
    "label": "Pierson - IA 51048",
    "county": "Woodbury County",
    "state": "IA",
    "city": "Pierson"
  },
  {
    "value": "51049",
    "label": "Quimby - IA 51049",
    "county": "Cherokee County",
    "state": "IA",
    "city": "Quimby"
  },
  {
    "value": "51050",
    "label": "Remsen - IA 51050",
    "county": "Plymouth County",
    "state": "IA",
    "city": "Remsen"
  },
  {
    "value": "51051",
    "label": "Rodney - IA 51051",
    "county": "Monona County",
    "state": "IA",
    "city": "Rodney"
  },
  {
    "value": "51052",
    "label": "Salix - IA 51052",
    "county": "Woodbury County",
    "state": "IA",
    "city": "Salix"
  },
  {
    "value": "51053",
    "label": "Schaller - IA 51053",
    "county": "Sac County",
    "state": "IA",
    "city": "Schaller"
  },
  {
    "value": "51054",
    "label": "Sergeant Bluff - IA 51054",
    "county": "Woodbury County",
    "state": "IA",
    "city": "Sergeant Bluff"
  },
  {
    "value": "51055",
    "label": "Sloan - IA 51055",
    "county": "Woodbury County",
    "state": "IA",
    "city": "Sloan"
  },
  {
    "value": "51056",
    "label": "Smithland - IA 51056",
    "county": "Woodbury County",
    "state": "IA",
    "city": "Smithland"
  },
  {
    "value": "51058",
    "label": "Sutherland - IA 51058",
    "county": "O'Brien County",
    "state": "IA",
    "city": "Sutherland"
  },
  {
    "value": "51060",
    "label": "Ute - IA 51060",
    "county": "Monona County",
    "state": "IA",
    "city": "Ute"
  },
  {
    "value": "51061",
    "label": "Washta - IA 51061",
    "county": "Cherokee County",
    "state": "IA",
    "city": "Washta"
  },
  {
    "value": "51062",
    "label": "Westfield - IA 51062",
    "county": "Plymouth County",
    "state": "IA",
    "city": "Westfield"
  },
  {
    "value": "51063",
    "label": "Whiting - IA 51063",
    "county": "Monona County",
    "state": "IA",
    "city": "Whiting"
  },
  {
    "value": "51101",
    "label": "Sioux City - IA 51101",
    "county": "Woodbury County",
    "state": "IA",
    "city": "Sioux City"
  },
  {
    "value": "51103",
    "label": "Sioux City - IA 51103",
    "county": "Woodbury County",
    "state": "IA",
    "city": "Sioux City"
  },
  {
    "value": "51104",
    "label": "Sioux City - IA 51104",
    "county": "Woodbury County",
    "state": "IA",
    "city": "Sioux City"
  },
  {
    "value": "51105",
    "label": "Sioux City - IA 51105",
    "county": "Woodbury County",
    "state": "IA",
    "city": "Sioux City"
  },
  {
    "value": "51106",
    "label": "Sioux City - IA 51106",
    "county": "Woodbury County",
    "state": "IA",
    "city": "Sioux City"
  },
  {
    "value": "51108",
    "label": "Sioux City - IA 51108",
    "county": "Woodbury County",
    "state": "IA",
    "city": "Sioux City"
  },
  {
    "value": "51109",
    "label": "Sioux City - IA 51109",
    "county": "Woodbury County",
    "state": "IA",
    "city": "Sioux City"
  },
  {
    "value": "51111",
    "label": "Sioux City - IA 51111",
    "county": "Woodbury County",
    "state": "IA",
    "city": "Sioux City"
  },
  {
    "value": "51201",
    "label": "Sheldon - IA 51201",
    "county": "O'Brien County",
    "state": "IA",
    "city": "Sheldon"
  },
  {
    "value": "51230",
    "label": "Alvord - IA 51230",
    "county": "Lyon County",
    "state": "IA",
    "city": "Alvord"
  },
  {
    "value": "51231",
    "label": "Archer - IA 51231",
    "county": "O'Brien County",
    "state": "IA",
    "city": "Archer"
  },
  {
    "value": "51232",
    "label": "Ashton - IA 51232",
    "county": "Osceola County",
    "state": "IA",
    "city": "Ashton"
  },
  {
    "value": "51234",
    "label": "Boyden - IA 51234",
    "county": "Sioux County",
    "state": "IA",
    "city": "Boyden"
  },
  {
    "value": "51235",
    "label": "Doon - IA 51235",
    "county": "Lyon County",
    "state": "IA",
    "city": "Doon"
  },
  {
    "value": "51237",
    "label": "George - IA 51237",
    "county": "Lyon County",
    "state": "IA",
    "city": "George"
  },
  {
    "value": "51238",
    "label": "Hospers - IA 51238",
    "county": "Sioux County",
    "state": "IA",
    "city": "Hospers"
  },
  {
    "value": "51239",
    "label": "Hull - IA 51239",
    "county": "Sioux County",
    "state": "IA",
    "city": "Hull"
  },
  {
    "value": "51240",
    "label": "Inwood - IA 51240",
    "county": "Lyon County",
    "state": "IA",
    "city": "Inwood"
  },
  {
    "value": "51241",
    "label": "Larchwood - IA 51241",
    "county": "Lyon County",
    "state": "IA",
    "city": "Larchwood"
  },
  {
    "value": "51243",
    "label": "Little Rock - IA 51243",
    "county": "Lyon County",
    "state": "IA",
    "city": "Little Rock"
  },
  {
    "value": "51244",
    "label": "Matlock - IA 51244",
    "county": "Sioux County",
    "state": "IA",
    "city": "Matlock"
  },
  {
    "value": "51245",
    "label": "Primghar - IA 51245",
    "county": "O'Brien County",
    "state": "IA",
    "city": "Primghar"
  },
  {
    "value": "51246",
    "label": "Rock Rapids - IA 51246",
    "county": "Lyon County",
    "state": "IA",
    "city": "Rock Rapids"
  },
  {
    "value": "51247",
    "label": "Rock Valley - IA 51247",
    "county": "Sioux County",
    "state": "IA",
    "city": "Rock Valley"
  },
  {
    "value": "51248",
    "label": "Sanborn - IA 51248",
    "county": "O'Brien County",
    "state": "IA",
    "city": "Sanborn"
  },
  {
    "value": "51249",
    "label": "Sibley - IA 51249",
    "county": "Osceola County",
    "state": "IA",
    "city": "Sibley"
  },
  {
    "value": "51250",
    "label": "Sioux Center - IA 51250",
    "county": "Sioux County",
    "state": "IA",
    "city": "Sioux Center"
  },
  {
    "value": "51301",
    "label": "Spencer - IA 51301",
    "county": "Clay County",
    "state": "IA",
    "city": "Spencer"
  },
  {
    "value": "51331",
    "label": "Arnolds Park - IA 51331",
    "county": "Dickinson County",
    "state": "IA",
    "city": "Arnolds Park"
  },
  {
    "value": "51333",
    "label": "Dickens - IA 51333",
    "county": "Clay County",
    "state": "IA",
    "city": "Dickens"
  },
  {
    "value": "51334",
    "label": "Estherville - IA 51334",
    "county": "Emmet County",
    "state": "IA",
    "city": "Estherville"
  },
  {
    "value": "51338",
    "label": "Everly - IA 51338",
    "county": "Clay County",
    "state": "IA",
    "city": "Everly"
  },
  {
    "value": "51342",
    "label": "Graettinger - IA 51342",
    "county": "Palo Alto County",
    "state": "IA",
    "city": "Graettinger"
  },
  {
    "value": "51343",
    "label": "Greenville - IA 51343",
    "county": "Clay County",
    "state": "IA",
    "city": "Greenville"
  },
  {
    "value": "51345",
    "label": "Harris - IA 51345",
    "county": "Osceola County",
    "state": "IA",
    "city": "Harris"
  },
  {
    "value": "51346",
    "label": "Hartley - IA 51346",
    "county": "O'Brien County",
    "state": "IA",
    "city": "Hartley"
  },
  {
    "value": "51347",
    "label": "Lake Park - IA 51347",
    "county": "Dickinson County",
    "state": "IA",
    "city": "Lake Park"
  },
  {
    "value": "51350",
    "label": "Melvin - IA 51350",
    "county": "Osceola County",
    "state": "IA",
    "city": "Melvin"
  },
  {
    "value": "51351",
    "label": "Milford - IA 51351",
    "county": "Dickinson County",
    "state": "IA",
    "city": "Milford"
  },
  {
    "value": "51354",
    "label": "Ocheyedan - IA 51354",
    "county": "Osceola County",
    "state": "IA",
    "city": "Ocheyedan"
  },
  {
    "value": "51355",
    "label": "Okoboji - IA 51355",
    "county": "Dickinson County",
    "state": "IA",
    "city": "Okoboji"
  },
  {
    "value": "51357",
    "label": "Royal - IA 51357",
    "county": "Clay County",
    "state": "IA",
    "city": "Royal"
  },
  {
    "value": "51358",
    "label": "Ruthven - IA 51358",
    "county": "Palo Alto County",
    "state": "IA",
    "city": "Ruthven"
  },
  {
    "value": "51360",
    "label": "Spirit Lake - IA 51360",
    "county": "Dickinson County",
    "state": "IA",
    "city": "Spirit Lake"
  },
  {
    "value": "51364",
    "label": "Terril - IA 51364",
    "county": "Dickinson County",
    "state": "IA",
    "city": "Terril"
  },
  {
    "value": "51365",
    "label": "Wallingford - IA 51365",
    "county": "Emmet County",
    "state": "IA",
    "city": "Wallingford"
  },
  {
    "value": "51366",
    "label": "Webb - IA 51366",
    "county": "Clay County",
    "state": "IA",
    "city": "Webb"
  },
  {
    "value": "51401",
    "label": "Carroll - IA 51401",
    "county": "Carroll County",
    "state": "IA",
    "city": "Carroll"
  },
  {
    "value": "51430",
    "label": "Arcadia - IA 51430",
    "county": "Carroll County",
    "state": "IA",
    "city": "Arcadia"
  },
  {
    "value": "51431",
    "label": "Arthur - IA 51431",
    "county": "Ida County",
    "state": "IA",
    "city": "Arthur"
  },
  {
    "value": "51433",
    "label": "Auburn - IA 51433",
    "county": "Sac County",
    "state": "IA",
    "city": "Auburn"
  },
  {
    "value": "51436",
    "label": "Breda - IA 51436",
    "county": "Carroll County",
    "state": "IA",
    "city": "Breda"
  },
  {
    "value": "51439",
    "label": "Charter Oak - IA 51439",
    "county": "Crawford County",
    "state": "IA",
    "city": "Charter Oak"
  },
  {
    "value": "51440",
    "label": "Dedham - IA 51440",
    "county": "Carroll County",
    "state": "IA",
    "city": "Dedham"
  },
  {
    "value": "51441",
    "label": "Deloit - IA 51441",
    "county": "Crawford County",
    "state": "IA",
    "city": "Deloit"
  },
  {
    "value": "51442",
    "label": "Denison - IA 51442",
    "county": "Crawford County",
    "state": "IA",
    "city": "Denison"
  },
  {
    "value": "51443",
    "label": "Glidden - IA 51443",
    "county": "Carroll County",
    "state": "IA",
    "city": "Glidden"
  },
  {
    "value": "51445",
    "label": "Ida Grove - IA 51445",
    "county": "Ida County",
    "state": "IA",
    "city": "Ida Grove"
  },
  {
    "value": "51446",
    "label": "Irwin - IA 51446",
    "county": "Shelby County",
    "state": "IA",
    "city": "Irwin"
  },
  {
    "value": "51447",
    "label": "Kirkman - IA 51447",
    "county": "Shelby County",
    "state": "IA",
    "city": "Kirkman"
  },
  {
    "value": "51448",
    "label": "Kiron - IA 51448",
    "county": "Crawford County",
    "state": "IA",
    "city": "Kiron"
  },
  {
    "value": "51449",
    "label": "Lake City - IA 51449",
    "county": "Calhoun County",
    "state": "IA",
    "city": "Lake City"
  },
  {
    "value": "51450",
    "label": "Lake View - IA 51450",
    "county": "Sac County",
    "state": "IA",
    "city": "Lake View"
  },
  {
    "value": "51453",
    "label": "Lohrville - IA 51453",
    "county": "Calhoun County",
    "state": "IA",
    "city": "Lohrville"
  },
  {
    "value": "51454",
    "label": "Manilla - IA 51454",
    "county": "Crawford County",
    "state": "IA",
    "city": "Manilla"
  },
  {
    "value": "51455",
    "label": "Manning - IA 51455",
    "county": "Carroll County",
    "state": "IA",
    "city": "Manning"
  },
  {
    "value": "51458",
    "label": "Odebolt - IA 51458",
    "county": "Sac County",
    "state": "IA",
    "city": "Odebolt"
  },
  {
    "value": "51459",
    "label": "Ralston - IA 51459",
    "county": "Carroll County",
    "state": "IA",
    "city": "Ralston"
  },
  {
    "value": "51461",
    "label": "Schleswig - IA 51461",
    "county": "Crawford County",
    "state": "IA",
    "city": "Schleswig"
  },
  {
    "value": "51462",
    "label": "Scranton - IA 51462",
    "county": "Greene County",
    "state": "IA",
    "city": "Scranton"
  },
  {
    "value": "51463",
    "label": "Templeton - IA 51463",
    "county": "Carroll County",
    "state": "IA",
    "city": "Templeton"
  },
  {
    "value": "51465",
    "label": "Vail - IA 51465",
    "county": "Crawford County",
    "state": "IA",
    "city": "Vail"
  },
  {
    "value": "51466",
    "label": "Wall Lake - IA 51466",
    "county": "Sac County",
    "state": "IA",
    "city": "Wall Lake"
  },
  {
    "value": "51467",
    "label": "Westside - IA 51467",
    "county": "Crawford County",
    "state": "IA",
    "city": "Westside"
  },
  {
    "value": "51501",
    "label": "Council Bluffs - IA 51501",
    "county": "Pottawattamie County",
    "state": "IA",
    "city": "Council Bluffs"
  },
  {
    "value": "51503",
    "label": "Council Bluffs - IA 51503",
    "county": "Pottawattamie County",
    "state": "IA",
    "city": "Council Bluffs"
  },
  {
    "value": "51510",
    "label": "Carter Lake - IA 51510",
    "county": "Pottawattamie County",
    "state": "IA",
    "city": "Carter Lake"
  },
  {
    "value": "51520",
    "label": "Arion - IA 51520",
    "county": "Crawford County",
    "state": "IA",
    "city": "Arion"
  },
  {
    "value": "51521",
    "label": "Avoca - IA 51521",
    "county": "Pottawattamie County",
    "state": "IA",
    "city": "Avoca"
  },
  {
    "value": "51523",
    "label": "Blencoe - IA 51523",
    "county": "Monona County",
    "state": "IA",
    "city": "Blencoe"
  },
  {
    "value": "51525",
    "label": "Carson - IA 51525",
    "county": "Pottawattamie County",
    "state": "IA",
    "city": "Carson"
  },
  {
    "value": "51526",
    "label": "Crescent - IA 51526",
    "county": "Pottawattamie County",
    "state": "IA",
    "city": "Crescent"
  },
  {
    "value": "51527",
    "label": "Defiance - IA 51527",
    "county": "Shelby County",
    "state": "IA",
    "city": "Defiance"
  },
  {
    "value": "51528",
    "label": "Dow City - IA 51528",
    "county": "Crawford County",
    "state": "IA",
    "city": "Dow City"
  },
  {
    "value": "51529",
    "label": "Dunlap - IA 51529",
    "county": "Harrison County",
    "state": "IA",
    "city": "Dunlap"
  },
  {
    "value": "51530",
    "label": "Earling - IA 51530",
    "county": "Shelby County",
    "state": "IA",
    "city": "Earling"
  },
  {
    "value": "51531",
    "label": "Elk Horn - IA 51531",
    "county": "Shelby County",
    "state": "IA",
    "city": "Elk Horn"
  },
  {
    "value": "51532",
    "label": "Elliott - IA 51532",
    "county": "Montgomery County",
    "state": "IA",
    "city": "Elliott"
  },
  {
    "value": "51533",
    "label": "Emerson - IA 51533",
    "county": "Mills County",
    "state": "IA",
    "city": "Emerson"
  },
  {
    "value": "51534",
    "label": "Glenwood - IA 51534",
    "county": "Mills County",
    "state": "IA",
    "city": "Glenwood"
  },
  {
    "value": "51535",
    "label": "Griswold - IA 51535",
    "county": "Cass County",
    "state": "IA",
    "city": "Griswold"
  },
  {
    "value": "51536",
    "label": "Hancock - IA 51536",
    "county": "Pottawattamie County",
    "state": "IA",
    "city": "Hancock"
  },
  {
    "value": "51537",
    "label": "Harlan - IA 51537",
    "county": "Shelby County",
    "state": "IA",
    "city": "Harlan"
  },
  {
    "value": "51540",
    "label": "Hastings - IA 51540",
    "county": "Mills County",
    "state": "IA",
    "city": "Hastings"
  },
  {
    "value": "51541",
    "label": "Henderson - IA 51541",
    "county": "Mills County",
    "state": "IA",
    "city": "Henderson"
  },
  {
    "value": "51542",
    "label": "Honey Creek - IA 51542",
    "county": "Pottawattamie County",
    "state": "IA",
    "city": "Honey Creek"
  },
  {
    "value": "51543",
    "label": "Kimballton - IA 51543",
    "county": "Audubon County",
    "state": "IA",
    "city": "Kimballton"
  },
  {
    "value": "51544",
    "label": "Lewis - IA 51544",
    "county": "Cass County",
    "state": "IA",
    "city": "Lewis"
  },
  {
    "value": "51545",
    "label": "Little Sioux - IA 51545",
    "county": "Harrison County",
    "state": "IA",
    "city": "Little Sioux"
  },
  {
    "value": "51546",
    "label": "Logan - IA 51546",
    "county": "Harrison County",
    "state": "IA",
    "city": "Logan"
  },
  {
    "value": "51548",
    "label": "Mc Clelland - IA 51548",
    "county": "Pottawattamie County",
    "state": "IA",
    "city": "Mc Clelland"
  },
  {
    "value": "51549",
    "label": "Macedonia - IA 51549",
    "county": "Pottawattamie County",
    "state": "IA",
    "city": "Macedonia"
  },
  {
    "value": "51550",
    "label": "Magnolia - IA 51550",
    "county": "Harrison County",
    "state": "IA",
    "city": "Magnolia"
  },
  {
    "value": "51551",
    "label": "Malvern - IA 51551",
    "county": "Mills County",
    "state": "IA",
    "city": "Malvern"
  },
  {
    "value": "51552",
    "label": "Marne - IA 51552",
    "county": "Cass County",
    "state": "IA",
    "city": "Marne"
  },
  {
    "value": "51553",
    "label": "Minden - IA 51553",
    "county": "Pottawattamie County",
    "state": "IA",
    "city": "Minden"
  },
  {
    "value": "51555",
    "label": "Missouri Valley - IA 51555",
    "county": "Harrison County",
    "state": "IA",
    "city": "Missouri Valley"
  },
  {
    "value": "51556",
    "label": "Modale - IA 51556",
    "county": "Harrison County",
    "state": "IA",
    "city": "Modale"
  },
  {
    "value": "51557",
    "label": "Mondamin - IA 51557",
    "county": "Harrison County",
    "state": "IA",
    "city": "Mondamin"
  },
  {
    "value": "51558",
    "label": "Moorhead - IA 51558",
    "county": "Monona County",
    "state": "IA",
    "city": "Moorhead"
  },
  {
    "value": "51559",
    "label": "Neola - IA 51559",
    "county": "Pottawattamie County",
    "state": "IA",
    "city": "Neola"
  },
  {
    "value": "51560",
    "label": "Oakland - IA 51560",
    "county": "Pottawattamie County",
    "state": "IA",
    "city": "Oakland"
  },
  {
    "value": "51561",
    "label": "Pacific Junction - IA 51561",
    "county": "Mills County",
    "state": "IA",
    "city": "Pacific Junction"
  },
  {
    "value": "51562",
    "label": "Panama - IA 51562",
    "county": "Shelby County",
    "state": "IA",
    "city": "Panama"
  },
  {
    "value": "51563",
    "label": "Persia - IA 51563",
    "county": "Harrison County",
    "state": "IA",
    "city": "Persia"
  },
  {
    "value": "51564",
    "label": "Pisgah - IA 51564",
    "county": "Harrison County",
    "state": "IA",
    "city": "Pisgah"
  },
  {
    "value": "51565",
    "label": "Portsmouth - IA 51565",
    "county": "Shelby County",
    "state": "IA",
    "city": "Portsmouth"
  },
  {
    "value": "51566",
    "label": "Red Oak - IA 51566",
    "county": "Montgomery County",
    "state": "IA",
    "city": "Red Oak"
  },
  {
    "value": "51570",
    "label": "Shelby - IA 51570",
    "county": "Shelby County",
    "state": "IA",
    "city": "Shelby"
  },
  {
    "value": "51571",
    "label": "Silver City - IA 51571",
    "county": "Mills County",
    "state": "IA",
    "city": "Silver City"
  },
  {
    "value": "51572",
    "label": "Soldier - IA 51572",
    "county": "Monona County",
    "state": "IA",
    "city": "Soldier"
  },
  {
    "value": "51573",
    "label": "Stanton - IA 51573",
    "county": "Montgomery County",
    "state": "IA",
    "city": "Stanton"
  },
  {
    "value": "51575",
    "label": "Treynor - IA 51575",
    "county": "Pottawattamie County",
    "state": "IA",
    "city": "Treynor"
  },
  {
    "value": "51576",
    "label": "Underwood - IA 51576",
    "county": "Pottawattamie County",
    "state": "IA",
    "city": "Underwood"
  },
  {
    "value": "51577",
    "label": "Walnut - IA 51577",
    "county": "Pottawattamie County",
    "state": "IA",
    "city": "Walnut"
  },
  {
    "value": "51579",
    "label": "Woodbine - IA 51579",
    "county": "Harrison County",
    "state": "IA",
    "city": "Woodbine"
  },
  {
    "value": "51601",
    "label": "Shenandoah - IA 51601",
    "county": "Page County",
    "state": "IA",
    "city": "Shenandoah"
  },
  {
    "value": "51630",
    "label": "Blanchard - IA 51630",
    "county": "Page County",
    "state": "IA",
    "city": "Blanchard"
  },
  {
    "value": "51631",
    "label": "Braddyville - IA 51631",
    "county": "Page County",
    "state": "IA",
    "city": "Braddyville"
  },
  {
    "value": "51632",
    "label": "Clarinda - IA 51632",
    "county": "Page County",
    "state": "IA",
    "city": "Clarinda"
  },
  {
    "value": "51636",
    "label": "Coin - IA 51636",
    "county": "Page County",
    "state": "IA",
    "city": "Coin"
  },
  {
    "value": "51638",
    "label": "Essex - IA 51638",
    "county": "Page County",
    "state": "IA",
    "city": "Essex"
  },
  {
    "value": "51639",
    "label": "Farragut - IA 51639",
    "county": "Fremont County",
    "state": "IA",
    "city": "Farragut"
  },
  {
    "value": "51640",
    "label": "Hamburg - IA 51640",
    "county": "Fremont County",
    "state": "IA",
    "city": "Hamburg"
  },
  {
    "value": "51645",
    "label": "Imogene - IA 51645",
    "county": "Fremont County",
    "state": "IA",
    "city": "Imogene"
  },
  {
    "value": "51646",
    "label": "New Market - IA 51646",
    "county": "Taylor County",
    "state": "IA",
    "city": "New Market"
  },
  {
    "value": "51647",
    "label": "Northboro - IA 51647",
    "county": "Page County",
    "state": "IA",
    "city": "Northboro"
  },
  {
    "value": "51648",
    "label": "Percival - IA 51648",
    "county": "Fremont County",
    "state": "IA",
    "city": "Percival"
  },
  {
    "value": "51649",
    "label": "Randolph - IA 51649",
    "county": "Fremont County",
    "state": "IA",
    "city": "Randolph"
  },
  {
    "value": "51650",
    "label": "Riverton - IA 51650",
    "county": "Fremont County",
    "state": "IA",
    "city": "Riverton"
  },
  {
    "value": "51652",
    "label": "Sidney - IA 51652",
    "county": "Fremont County",
    "state": "IA",
    "city": "Sidney"
  },
  {
    "value": "51653",
    "label": "Tabor - IA 51653",
    "county": "Fremont County",
    "state": "IA",
    "city": "Tabor"
  },
  {
    "value": "51654",
    "label": "Thurman - IA 51654",
    "county": "Fremont County",
    "state": "IA",
    "city": "Thurman"
  },
  {
    "value": "52001",
    "label": "Dubuque - IA 52001",
    "county": "Dubuque County",
    "state": "IA",
    "city": "Dubuque"
  },
  {
    "value": "52002",
    "label": "Dubuque - IA 52002",
    "county": "Dubuque County",
    "state": "IA",
    "city": "Dubuque"
  },
  {
    "value": "52003",
    "label": "Dubuque - IA 52003",
    "county": "Dubuque County",
    "state": "IA",
    "city": "Dubuque"
  },
  {
    "value": "52031",
    "label": "Bellevue - IA 52031",
    "county": "Jackson County",
    "state": "IA",
    "city": "Bellevue"
  },
  {
    "value": "52032",
    "label": "Bernard - IA 52032",
    "county": "Dubuque County",
    "state": "IA",
    "city": "Bernard"
  },
  {
    "value": "52033",
    "label": "Cascade - IA 52033",
    "county": "Dubuque County",
    "state": "IA",
    "city": "Cascade"
  },
  {
    "value": "52035",
    "label": "Colesburg - IA 52035",
    "county": "Delaware County",
    "state": "IA",
    "city": "Colesburg"
  },
  {
    "value": "52036",
    "label": "Delaware - IA 52036",
    "county": "Delaware County",
    "state": "IA",
    "city": "Delaware"
  },
  {
    "value": "52037",
    "label": "Delmar - IA 52037",
    "county": "Clinton County",
    "state": "IA",
    "city": "Delmar"
  },
  {
    "value": "52038",
    "label": "Dundee - IA 52038",
    "county": "Delaware County",
    "state": "IA",
    "city": "Dundee"
  },
  {
    "value": "52039",
    "label": "Durango - IA 52039",
    "county": "Dubuque County",
    "state": "IA",
    "city": "Durango"
  },
  {
    "value": "52040",
    "label": "Dyersville - IA 52040",
    "county": "Dubuque County",
    "state": "IA",
    "city": "Dyersville"
  },
  {
    "value": "52041",
    "label": "Earlville - IA 52041",
    "county": "Delaware County",
    "state": "IA",
    "city": "Earlville"
  },
  {
    "value": "52042",
    "label": "Edgewood - IA 52042",
    "county": "Clayton County",
    "state": "IA",
    "city": "Edgewood"
  },
  {
    "value": "52043",
    "label": "Elkader - IA 52043",
    "county": "Clayton County",
    "state": "IA",
    "city": "Elkader"
  },
  {
    "value": "52044",
    "label": "Elkport - IA 52044",
    "county": "Clayton County",
    "state": "IA",
    "city": "Elkport"
  },
  {
    "value": "52045",
    "label": "Epworth - IA 52045",
    "county": "Dubuque County",
    "state": "IA",
    "city": "Epworth"
  },
  {
    "value": "52046",
    "label": "Farley - IA 52046",
    "county": "Dubuque County",
    "state": "IA",
    "city": "Farley"
  },
  {
    "value": "52047",
    "label": "Farmersburg - IA 52047",
    "county": "Clayton County",
    "state": "IA",
    "city": "Farmersburg"
  },
  {
    "value": "52048",
    "label": "Garber - IA 52048",
    "county": "Clayton County",
    "state": "IA",
    "city": "Garber"
  },
  {
    "value": "52049",
    "label": "Garnavillo - IA 52049",
    "county": "Clayton County",
    "state": "IA",
    "city": "Garnavillo"
  },
  {
    "value": "52050",
    "label": "Greeley - IA 52050",
    "county": "Delaware County",
    "state": "IA",
    "city": "Greeley"
  },
  {
    "value": "52052",
    "label": "Guttenberg - IA 52052",
    "county": "Clayton County",
    "state": "IA",
    "city": "Guttenberg"
  },
  {
    "value": "52053",
    "label": "Holy Cross - IA 52053",
    "county": "Dubuque County",
    "state": "IA",
    "city": "Holy Cross"
  },
  {
    "value": "52054",
    "label": "La Motte - IA 52054",
    "county": "Jackson County",
    "state": "IA",
    "city": "La Motte"
  },
  {
    "value": "52057",
    "label": "Manchester - IA 52057",
    "county": "Delaware County",
    "state": "IA",
    "city": "Manchester"
  },
  {
    "value": "52060",
    "label": "Maquoketa - IA 52060",
    "county": "Jackson County",
    "state": "IA",
    "city": "Maquoketa"
  },
  {
    "value": "52064",
    "label": "Miles - IA 52064",
    "county": "Jackson County",
    "state": "IA",
    "city": "Miles"
  },
  {
    "value": "52065",
    "label": "New Vienna - IA 52065",
    "county": "Dubuque County",
    "state": "IA",
    "city": "New Vienna"
  },
  {
    "value": "52066",
    "label": "North Buena Vista - IA 52066",
    "county": "Clayton County",
    "state": "IA",
    "city": "North Buena Vista"
  },
  {
    "value": "52068",
    "label": "Peosta - IA 52068",
    "county": "Dubuque County",
    "state": "IA",
    "city": "Peosta"
  },
  {
    "value": "52069",
    "label": "Preston - IA 52069",
    "county": "Jackson County",
    "state": "IA",
    "city": "Preston"
  },
  {
    "value": "52070",
    "label": "Sabula - IA 52070",
    "county": "Jackson County",
    "state": "IA",
    "city": "Sabula"
  },
  {
    "value": "52072",
    "label": "Saint Olaf - IA 52072",
    "county": "Clayton County",
    "state": "IA",
    "city": "Saint Olaf"
  },
  {
    "value": "52073",
    "label": "Sherrill - IA 52073",
    "county": "Dubuque County",
    "state": "IA",
    "city": "Sherrill"
  },
  {
    "value": "52074",
    "label": "Spragueville - IA 52074",
    "county": "Jackson County",
    "state": "IA",
    "city": "Spragueville"
  },
  {
    "value": "52076",
    "label": "Strawberry Point - IA 52076",
    "county": "Clayton County",
    "state": "IA",
    "city": "Strawberry Point"
  },
  {
    "value": "52077",
    "label": "Volga - IA 52077",
    "county": "Clayton County",
    "state": "IA",
    "city": "Volga"
  },
  {
    "value": "52078",
    "label": "Worthington - IA 52078",
    "county": "Dubuque County",
    "state": "IA",
    "city": "Worthington"
  },
  {
    "value": "52079",
    "label": "Zwingle - IA 52079",
    "county": "Jackson County",
    "state": "IA",
    "city": "Zwingle"
  },
  {
    "value": "52101",
    "label": "Decorah - IA 52101",
    "county": "Winneshiek County",
    "state": "IA",
    "city": "Decorah"
  },
  {
    "value": "52132",
    "label": "Calmar - IA 52132",
    "county": "Winneshiek County",
    "state": "IA",
    "city": "Calmar"
  },
  {
    "value": "52133",
    "label": "Castalia - IA 52133",
    "county": "Winneshiek County",
    "state": "IA",
    "city": "Castalia"
  },
  {
    "value": "52134",
    "label": "Chester - IA 52134",
    "county": "Howard County",
    "state": "IA",
    "city": "Chester"
  },
  {
    "value": "52135",
    "label": "Clermont - IA 52135",
    "county": "Fayette County",
    "state": "IA",
    "city": "Clermont"
  },
  {
    "value": "52136",
    "label": "Cresco - IA 52136",
    "county": "Howard County",
    "state": "IA",
    "city": "Cresco"
  },
  {
    "value": "52140",
    "label": "Dorchester - IA 52140",
    "county": "Allamakee County",
    "state": "IA",
    "city": "Dorchester"
  },
  {
    "value": "52141",
    "label": "Elgin - IA 52141",
    "county": "Fayette County",
    "state": "IA",
    "city": "Elgin"
  },
  {
    "value": "52142",
    "label": "Fayette - IA 52142",
    "county": "Fayette County",
    "state": "IA",
    "city": "Fayette"
  },
  {
    "value": "52144",
    "label": "Fort Atkinson - IA 52144",
    "county": "Winneshiek County",
    "state": "IA",
    "city": "Fort Atkinson"
  },
  {
    "value": "52146",
    "label": "Harpers Ferry - IA 52146",
    "county": "Allamakee County",
    "state": "IA",
    "city": "Harpers Ferry"
  },
  {
    "value": "52147",
    "label": "Hawkeye - IA 52147",
    "county": "Fayette County",
    "state": "IA",
    "city": "Hawkeye"
  },
  {
    "value": "52151",
    "label": "Lansing - IA 52151",
    "county": "Allamakee County",
    "state": "IA",
    "city": "Lansing"
  },
  {
    "value": "52154",
    "label": "Lawler - IA 52154",
    "county": "Chickasaw County",
    "state": "IA",
    "city": "Lawler"
  },
  {
    "value": "52155",
    "label": "Lime Springs - IA 52155",
    "county": "Howard County",
    "state": "IA",
    "city": "Lime Springs"
  },
  {
    "value": "52156",
    "label": "Luana - IA 52156",
    "county": "Clayton County",
    "state": "IA",
    "city": "Luana"
  },
  {
    "value": "52157",
    "label": "Mc Gregor - IA 52157",
    "county": "Clayton County",
    "state": "IA",
    "city": "Mc Gregor"
  },
  {
    "value": "52159",
    "label": "Monona - IA 52159",
    "county": "Clayton County",
    "state": "IA",
    "city": "Monona"
  },
  {
    "value": "52160",
    "label": "New Albin - IA 52160",
    "county": "Allamakee County",
    "state": "IA",
    "city": "New Albin"
  },
  {
    "value": "52161",
    "label": "Ossian - IA 52161",
    "county": "Winneshiek County",
    "state": "IA",
    "city": "Ossian"
  },
  {
    "value": "52162",
    "label": "Postville - IA 52162",
    "county": "Allamakee County",
    "state": "IA",
    "city": "Postville"
  },
  {
    "value": "52164",
    "label": "Randalia - IA 52164",
    "county": "Fayette County",
    "state": "IA",
    "city": "Randalia"
  },
  {
    "value": "52165",
    "label": "Ridgeway - IA 52165",
    "county": "Winneshiek County",
    "state": "IA",
    "city": "Ridgeway"
  },
  {
    "value": "52169",
    "label": "Wadena - IA 52169",
    "county": "Fayette County",
    "state": "IA",
    "city": "Wadena"
  },
  {
    "value": "52170",
    "label": "Waterville - IA 52170",
    "county": "Allamakee County",
    "state": "IA",
    "city": "Waterville"
  },
  {
    "value": "52171",
    "label": "Waucoma - IA 52171",
    "county": "Fayette County",
    "state": "IA",
    "city": "Waucoma"
  },
  {
    "value": "52172",
    "label": "Waukon - IA 52172",
    "county": "Allamakee County",
    "state": "IA",
    "city": "Waukon"
  },
  {
    "value": "52175",
    "label": "West Union - IA 52175",
    "county": "Fayette County",
    "state": "IA",
    "city": "West Union"
  },
  {
    "value": "52201",
    "label": "Ainsworth - IA 52201",
    "county": "Washington County",
    "state": "IA",
    "city": "Ainsworth"
  },
  {
    "value": "52202",
    "label": "Alburnett - IA 52202",
    "county": "Linn County",
    "state": "IA",
    "city": "Alburnett"
  },
  {
    "value": "52203",
    "label": "Amana - IA 52203",
    "county": "Iowa County",
    "state": "IA",
    "city": "Amana"
  },
  {
    "value": "52205",
    "label": "Anamosa - IA 52205",
    "county": "Jones County",
    "state": "IA",
    "city": "Anamosa"
  },
  {
    "value": "52206",
    "label": "Atkins - IA 52206",
    "county": "Benton County",
    "state": "IA",
    "city": "Atkins"
  },
  {
    "value": "52207",
    "label": "Baldwin - IA 52207",
    "county": "Jackson County",
    "state": "IA",
    "city": "Baldwin"
  },
  {
    "value": "52208",
    "label": "Belle Plaine - IA 52208",
    "county": "Benton County",
    "state": "IA",
    "city": "Belle Plaine"
  },
  {
    "value": "52209",
    "label": "Blairstown - IA 52209",
    "county": "Benton County",
    "state": "IA",
    "city": "Blairstown"
  },
  {
    "value": "52210",
    "label": "Brandon - IA 52210",
    "county": "Buchanan County",
    "state": "IA",
    "city": "Brandon"
  },
  {
    "value": "52211",
    "label": "Brooklyn - IA 52211",
    "county": "Poweshiek County",
    "state": "IA",
    "city": "Brooklyn"
  },
  {
    "value": "52212",
    "label": "Center Junction - IA 52212",
    "county": "Jones County",
    "state": "IA",
    "city": "Center Junction"
  },
  {
    "value": "52213",
    "label": "Center Point - IA 52213",
    "county": "Linn County",
    "state": "IA",
    "city": "Center Point"
  },
  {
    "value": "52214",
    "label": "Central City - IA 52214",
    "county": "Linn County",
    "state": "IA",
    "city": "Central City"
  },
  {
    "value": "52215",
    "label": "Chelsea - IA 52215",
    "county": "Tama County",
    "state": "IA",
    "city": "Chelsea"
  },
  {
    "value": "52216",
    "label": "Clarence - IA 52216",
    "county": "Cedar County",
    "state": "IA",
    "city": "Clarence"
  },
  {
    "value": "52217",
    "label": "Clutier - IA 52217",
    "county": "Tama County",
    "state": "IA",
    "city": "Clutier"
  },
  {
    "value": "52218",
    "label": "Coggon - IA 52218",
    "county": "Linn County",
    "state": "IA",
    "city": "Coggon"
  },
  {
    "value": "52219",
    "label": "Prairieburg - IA 52219",
    "county": "Linn County",
    "state": "IA",
    "city": "Prairieburg"
  },
  {
    "value": "52221",
    "label": "Guernsey - IA 52221",
    "county": "Poweshiek County",
    "state": "IA",
    "city": "Guernsey"
  },
  {
    "value": "52222",
    "label": "Deep River - IA 52222",
    "county": "Poweshiek County",
    "state": "IA",
    "city": "Deep River"
  },
  {
    "value": "52223",
    "label": "Delhi - IA 52223",
    "county": "Delaware County",
    "state": "IA",
    "city": "Delhi"
  },
  {
    "value": "52224",
    "label": "Dysart - IA 52224",
    "county": "Tama County",
    "state": "IA",
    "city": "Dysart"
  },
  {
    "value": "52225",
    "label": "Elberon - IA 52225",
    "county": "Tama County",
    "state": "IA",
    "city": "Elberon"
  },
  {
    "value": "52227",
    "label": "Ely - IA 52227",
    "county": "Linn County",
    "state": "IA",
    "city": "Ely"
  },
  {
    "value": "52228",
    "label": "Fairfax - IA 52228",
    "county": "Linn County",
    "state": "IA",
    "city": "Fairfax"
  },
  {
    "value": "52229",
    "label": "Garrison - IA 52229",
    "county": "Benton County",
    "state": "IA",
    "city": "Garrison"
  },
  {
    "value": "52231",
    "label": "Harper - IA 52231",
    "county": "Keokuk County",
    "state": "IA",
    "city": "Harper"
  },
  {
    "value": "52232",
    "label": "Hartwick - IA 52232",
    "county": "Poweshiek County",
    "state": "IA",
    "city": "Hartwick"
  },
  {
    "value": "52233",
    "label": "Hiawatha - IA 52233",
    "county": "Linn County",
    "state": "IA",
    "city": "Hiawatha"
  },
  {
    "value": "52236",
    "label": "Homestead - IA 52236",
    "county": "Iowa County",
    "state": "IA",
    "city": "Homestead"
  },
  {
    "value": "52237",
    "label": "Hopkinton - IA 52237",
    "county": "Delaware County",
    "state": "IA",
    "city": "Hopkinton"
  },
  {
    "value": "52240",
    "label": "Iowa City - IA 52240",
    "county": "Johnson County",
    "state": "IA",
    "city": "Iowa City"
  },
  {
    "value": "52241",
    "label": "Coralville - IA 52241",
    "county": "Johnson County",
    "state": "IA",
    "city": "Coralville"
  },
  {
    "value": "52242",
    "label": "Iowa City - IA 52242",
    "county": "Johnson County",
    "state": "IA",
    "city": "Iowa City"
  },
  {
    "value": "52245",
    "label": "Iowa City - IA 52245",
    "county": "Johnson County",
    "state": "IA",
    "city": "Iowa City"
  },
  {
    "value": "52246",
    "label": "Iowa City - IA 52246",
    "county": "Johnson County",
    "state": "IA",
    "city": "Iowa City"
  },
  {
    "value": "52247",
    "label": "Kalona - IA 52247",
    "county": "Washington County",
    "state": "IA",
    "city": "Kalona"
  },
  {
    "value": "52248",
    "label": "Keota - IA 52248",
    "county": "Keokuk County",
    "state": "IA",
    "city": "Keota"
  },
  {
    "value": "52249",
    "label": "Keystone - IA 52249",
    "county": "Benton County",
    "state": "IA",
    "city": "Keystone"
  },
  {
    "value": "52251",
    "label": "Ladora - IA 52251",
    "county": "Iowa County",
    "state": "IA",
    "city": "Ladora"
  },
  {
    "value": "52252",
    "label": "Langworthy - IA 52252",
    "county": "Jones County",
    "state": "IA",
    "city": "Langworthy"
  },
  {
    "value": "52253",
    "label": "Lisbon - IA 52253",
    "county": "Linn County",
    "state": "IA",
    "city": "Lisbon"
  },
  {
    "value": "52254",
    "label": "Lost Nation - IA 52254",
    "county": "Clinton County",
    "state": "IA",
    "city": "Lost Nation"
  },
  {
    "value": "52255",
    "label": "Lowden - IA 52255",
    "county": "Cedar County",
    "state": "IA",
    "city": "Lowden"
  },
  {
    "value": "52257",
    "label": "Luzerne - IA 52257",
    "county": "Benton County",
    "state": "IA",
    "city": "Luzerne"
  },
  {
    "value": "52301",
    "label": "Marengo - IA 52301",
    "county": "Iowa County",
    "state": "IA",
    "city": "Marengo"
  },
  {
    "value": "52302",
    "label": "Marion - IA 52302",
    "county": "Linn County",
    "state": "IA",
    "city": "Marion"
  },
  {
    "value": "52305",
    "label": "Martelle - IA 52305",
    "county": "Jones County",
    "state": "IA",
    "city": "Martelle"
  },
  {
    "value": "52306",
    "label": "Mechanicsville - IA 52306",
    "county": "Cedar County",
    "state": "IA",
    "city": "Mechanicsville"
  },
  {
    "value": "52309",
    "label": "Monmouth - IA 52309",
    "county": "Jackson County",
    "state": "IA",
    "city": "Monmouth"
  },
  {
    "value": "52310",
    "label": "Monticello - IA 52310",
    "county": "Jones County",
    "state": "IA",
    "city": "Monticello"
  },
  {
    "value": "52312",
    "label": "Morley - IA 52312",
    "county": "Jones County",
    "state": "IA",
    "city": "Morley"
  },
  {
    "value": "52313",
    "label": "Mount Auburn - IA 52313",
    "county": "Benton County",
    "state": "IA",
    "city": "Mount Auburn"
  },
  {
    "value": "52314",
    "label": "Mount Vernon - IA 52314",
    "county": "Linn County",
    "state": "IA",
    "city": "Mount Vernon"
  },
  {
    "value": "52315",
    "label": "Newhall - IA 52315",
    "county": "Benton County",
    "state": "IA",
    "city": "Newhall"
  },
  {
    "value": "52316",
    "label": "North English - IA 52316",
    "county": "Iowa County",
    "state": "IA",
    "city": "North English"
  },
  {
    "value": "52317",
    "label": "North Liberty - IA 52317",
    "county": "Johnson County",
    "state": "IA",
    "city": "North Liberty"
  },
  {
    "value": "52318",
    "label": "Norway - IA 52318",
    "county": "Benton County",
    "state": "IA",
    "city": "Norway"
  },
  {
    "value": "52320",
    "label": "Olin - IA 52320",
    "county": "Jones County",
    "state": "IA",
    "city": "Olin"
  },
  {
    "value": "52321",
    "label": "Onslow - IA 52321",
    "county": "Jones County",
    "state": "IA",
    "city": "Onslow"
  },
  {
    "value": "52322",
    "label": "Oxford - IA 52322",
    "county": "Johnson County",
    "state": "IA",
    "city": "Oxford"
  },
  {
    "value": "52323",
    "label": "Oxford Junction - IA 52323",
    "county": "Jones County",
    "state": "IA",
    "city": "Oxford Junction"
  },
  {
    "value": "52324",
    "label": "Palo - IA 52324",
    "county": "Linn County",
    "state": "IA",
    "city": "Palo"
  },
  {
    "value": "52325",
    "label": "Parnell - IA 52325",
    "county": "Iowa County",
    "state": "IA",
    "city": "Parnell"
  },
  {
    "value": "52326",
    "label": "Quasqueton - IA 52326",
    "county": "Buchanan County",
    "state": "IA",
    "city": "Quasqueton"
  },
  {
    "value": "52327",
    "label": "Riverside - IA 52327",
    "county": "Washington County",
    "state": "IA",
    "city": "Riverside"
  },
  {
    "value": "52328",
    "label": "Robins - IA 52328",
    "county": "Linn County",
    "state": "IA",
    "city": "Robins"
  },
  {
    "value": "52329",
    "label": "Rowley - IA 52329",
    "county": "Buchanan County",
    "state": "IA",
    "city": "Rowley"
  },
  {
    "value": "52330",
    "label": "Ryan - IA 52330",
    "county": "Delaware County",
    "state": "IA",
    "city": "Ryan"
  },
  {
    "value": "52332",
    "label": "Shellsburg - IA 52332",
    "county": "Benton County",
    "state": "IA",
    "city": "Shellsburg"
  },
  {
    "value": "52333",
    "label": "Solon - IA 52333",
    "county": "Johnson County",
    "state": "IA",
    "city": "Solon"
  },
  {
    "value": "52334",
    "label": "South Amana - IA 52334",
    "county": "Iowa County",
    "state": "IA",
    "city": "South Amana"
  },
  {
    "value": "52335",
    "label": "South English - IA 52335",
    "county": "Keokuk County",
    "state": "IA",
    "city": "South English"
  },
  {
    "value": "52336",
    "label": "Springville - IA 52336",
    "county": "Linn County",
    "state": "IA",
    "city": "Springville"
  },
  {
    "value": "52337",
    "label": "Stanwood - IA 52337",
    "county": "Cedar County",
    "state": "IA",
    "city": "Stanwood"
  },
  {
    "value": "52338",
    "label": "Swisher - IA 52338",
    "county": "Johnson County",
    "state": "IA",
    "city": "Swisher"
  },
  {
    "value": "52339",
    "label": "Tama - IA 52339",
    "county": "Tama County",
    "state": "IA",
    "city": "Tama"
  },
  {
    "value": "52340",
    "label": "Tiffin - IA 52340",
    "county": "Johnson County",
    "state": "IA",
    "city": "Tiffin"
  },
  {
    "value": "52341",
    "label": "Toddville - IA 52341",
    "county": "Linn County",
    "state": "IA",
    "city": "Toddville"
  },
  {
    "value": "52342",
    "label": "Toledo - IA 52342",
    "county": "Tama County",
    "state": "IA",
    "city": "Toledo"
  },
  {
    "value": "52345",
    "label": "Urbana - IA 52345",
    "county": "Benton County",
    "state": "IA",
    "city": "Urbana"
  },
  {
    "value": "52346",
    "label": "Van Horne - IA 52346",
    "county": "Benton County",
    "state": "IA",
    "city": "Van Horne"
  },
  {
    "value": "52347",
    "label": "Victor - IA 52347",
    "county": "Iowa County",
    "state": "IA",
    "city": "Victor"
  },
  {
    "value": "52349",
    "label": "Vinton - IA 52349",
    "county": "Benton County",
    "state": "IA",
    "city": "Vinton"
  },
  {
    "value": "52351",
    "label": "Walford - IA 52351",
    "county": "Benton County",
    "state": "IA",
    "city": "Walford"
  },
  {
    "value": "52352",
    "label": "Walker - IA 52352",
    "county": "Linn County",
    "state": "IA",
    "city": "Walker"
  },
  {
    "value": "52353",
    "label": "Washington - IA 52353",
    "county": "Washington County",
    "state": "IA",
    "city": "Washington"
  },
  {
    "value": "52354",
    "label": "Watkins - IA 52354",
    "county": "Benton County",
    "state": "IA",
    "city": "Watkins"
  },
  {
    "value": "52355",
    "label": "Webster - IA 52355",
    "county": "Keokuk County",
    "state": "IA",
    "city": "Webster"
  },
  {
    "value": "52356",
    "label": "Wellman - IA 52356",
    "county": "Washington County",
    "state": "IA",
    "city": "Wellman"
  },
  {
    "value": "52358",
    "label": "West Branch - IA 52358",
    "county": "Cedar County",
    "state": "IA",
    "city": "West Branch"
  },
  {
    "value": "52359",
    "label": "West Chester - IA 52359",
    "county": "Washington County",
    "state": "IA",
    "city": "West Chester"
  },
  {
    "value": "52361",
    "label": "Williamsburg - IA 52361",
    "county": "Iowa County",
    "state": "IA",
    "city": "Williamsburg"
  },
  {
    "value": "52362",
    "label": "Wyoming - IA 52362",
    "county": "Jones County",
    "state": "IA",
    "city": "Wyoming"
  },
  {
    "value": "52401",
    "label": "Cedar Rapids - IA 52401",
    "county": "Linn County",
    "state": "IA",
    "city": "Cedar Rapids"
  },
  {
    "value": "52402",
    "label": "Cedar Rapids - IA 52402",
    "county": "Linn County",
    "state": "IA",
    "city": "Cedar Rapids"
  },
  {
    "value": "52403",
    "label": "Cedar Rapids - IA 52403",
    "county": "Linn County",
    "state": "IA",
    "city": "Cedar Rapids"
  },
  {
    "value": "52404",
    "label": "Cedar Rapids - IA 52404",
    "county": "Linn County",
    "state": "IA",
    "city": "Cedar Rapids"
  },
  {
    "value": "52405",
    "label": "Cedar Rapids - IA 52405",
    "county": "Linn County",
    "state": "IA",
    "city": "Cedar Rapids"
  },
  {
    "value": "52411",
    "label": "Cedar Rapids - IA 52411",
    "county": "Linn County",
    "state": "IA",
    "city": "Cedar Rapids"
  },
  {
    "value": "52501",
    "label": "Ottumwa - IA 52501",
    "county": "Wapello County",
    "state": "IA",
    "city": "Ottumwa"
  },
  {
    "value": "52530",
    "label": "Agency - IA 52530",
    "county": "Wapello County",
    "state": "IA",
    "city": "Agency"
  },
  {
    "value": "52531",
    "label": "Albia - IA 52531",
    "county": "Monroe County",
    "state": "IA",
    "city": "Albia"
  },
  {
    "value": "52533",
    "label": "Batavia - IA 52533",
    "county": "Jefferson County",
    "state": "IA",
    "city": "Batavia"
  },
  {
    "value": "52534",
    "label": "Beacon - IA 52534",
    "county": "Mahaska County",
    "state": "IA",
    "city": "Beacon"
  },
  {
    "value": "52535",
    "label": "Birmingham - IA 52535",
    "county": "Van Buren County",
    "state": "IA",
    "city": "Birmingham"
  },
  {
    "value": "52536",
    "label": "Blakesburg - IA 52536",
    "county": "Wapello County",
    "state": "IA",
    "city": "Blakesburg"
  },
  {
    "value": "52537",
    "label": "Bloomfield - IA 52537",
    "county": "Davis County",
    "state": "IA",
    "city": "Bloomfield"
  },
  {
    "value": "52538",
    "label": "West Grove - IA 52538",
    "county": "Davis County",
    "state": "IA",
    "city": "West Grove"
  },
  {
    "value": "52540",
    "label": "Brighton - IA 52540",
    "county": "Washington County",
    "state": "IA",
    "city": "Brighton"
  },
  {
    "value": "52542",
    "label": "Cantril - IA 52542",
    "county": "Van Buren County",
    "state": "IA",
    "city": "Cantril"
  },
  {
    "value": "52543",
    "label": "Cedar - IA 52543",
    "county": "Mahaska County",
    "state": "IA",
    "city": "Cedar"
  },
  {
    "value": "52544",
    "label": "Centerville - IA 52544",
    "county": "Appanoose County",
    "state": "IA",
    "city": "Centerville"
  },
  {
    "value": "52548",
    "label": "Chillicothe - IA 52548",
    "county": "Wapello County",
    "state": "IA",
    "city": "Chillicothe"
  },
  {
    "value": "52549",
    "label": "Cincinnati - IA 52549",
    "county": "Appanoose County",
    "state": "IA",
    "city": "Cincinnati"
  },
  {
    "value": "52550",
    "label": "Delta - IA 52550",
    "county": "Keokuk County",
    "state": "IA",
    "city": "Delta"
  },
  {
    "value": "52551",
    "label": "Douds - IA 52551",
    "county": "Van Buren County",
    "state": "IA",
    "city": "Douds"
  },
  {
    "value": "52552",
    "label": "Drakesville - IA 52552",
    "county": "Davis County",
    "state": "IA",
    "city": "Drakesville"
  },
  {
    "value": "52553",
    "label": "Eddyville - IA 52553",
    "county": "Wapello County",
    "state": "IA",
    "city": "Eddyville"
  },
  {
    "value": "52554",
    "label": "Eldon - IA 52554",
    "county": "Wapello County",
    "state": "IA",
    "city": "Eldon"
  },
  {
    "value": "52555",
    "label": "Exline - IA 52555",
    "county": "Appanoose County",
    "state": "IA",
    "city": "Exline"
  },
  {
    "value": "52556",
    "label": "Fairfield - IA 52556",
    "county": "Jefferson County",
    "state": "IA",
    "city": "Fairfield"
  },
  {
    "value": "52560",
    "label": "Floris - IA 52560",
    "county": "Davis County",
    "state": "IA",
    "city": "Floris"
  },
  {
    "value": "52561",
    "label": "Fremont - IA 52561",
    "county": "Mahaska County",
    "state": "IA",
    "city": "Fremont"
  },
  {
    "value": "52562",
    "label": "Hayesville - IA 52562",
    "county": "Keokuk County",
    "state": "IA",
    "city": "Hayesville"
  },
  {
    "value": "52563",
    "label": "Hedrick - IA 52563",
    "county": "Keokuk County",
    "state": "IA",
    "city": "Hedrick"
  },
  {
    "value": "52565",
    "label": "Keosauqua - IA 52565",
    "county": "Van Buren County",
    "state": "IA",
    "city": "Keosauqua"
  },
  {
    "value": "52566",
    "label": "Kirkville - IA 52566",
    "county": "Wapello County",
    "state": "IA",
    "city": "Kirkville"
  },
  {
    "value": "52567",
    "label": "Libertyville - IA 52567",
    "county": "Jefferson County",
    "state": "IA",
    "city": "Libertyville"
  },
  {
    "value": "52569",
    "label": "Melrose - IA 52569",
    "county": "Monroe County",
    "state": "IA",
    "city": "Melrose"
  },
  {
    "value": "52570",
    "label": "Milton - IA 52570",
    "county": "Van Buren County",
    "state": "IA",
    "city": "Milton"
  },
  {
    "value": "52571",
    "label": "Moravia - IA 52571",
    "county": "Appanoose County",
    "state": "IA",
    "city": "Moravia"
  },
  {
    "value": "52572",
    "label": "Moulton - IA 52572",
    "county": "Appanoose County",
    "state": "IA",
    "city": "Moulton"
  },
  {
    "value": "52573",
    "label": "Mount Sterling - IA 52573",
    "county": "Van Buren County",
    "state": "IA",
    "city": "Mount Sterling"
  },
  {
    "value": "52574",
    "label": "Mystic - IA 52574",
    "county": "Appanoose County",
    "state": "IA",
    "city": "Mystic"
  },
  {
    "value": "52576",
    "label": "Ollie - IA 52576",
    "county": "Keokuk County",
    "state": "IA",
    "city": "Ollie"
  },
  {
    "value": "52577",
    "label": "Oskaloosa - IA 52577",
    "county": "Mahaska County",
    "state": "IA",
    "city": "Oskaloosa"
  },
  {
    "value": "52580",
    "label": "Packwood - IA 52580",
    "county": "Jefferson County",
    "state": "IA",
    "city": "Packwood"
  },
  {
    "value": "52581",
    "label": "Plano - IA 52581",
    "county": "Appanoose County",
    "state": "IA",
    "city": "Plano"
  },
  {
    "value": "52583",
    "label": "Promise City - IA 52583",
    "county": "Wayne County",
    "state": "IA",
    "city": "Promise City"
  },
  {
    "value": "52584",
    "label": "Pulaski - IA 52584",
    "county": "Davis County",
    "state": "IA",
    "city": "Pulaski"
  },
  {
    "value": "52585",
    "label": "Richland - IA 52585",
    "county": "Keokuk County",
    "state": "IA",
    "city": "Richland"
  },
  {
    "value": "52586",
    "label": "Rose Hill - IA 52586",
    "county": "Mahaska County",
    "state": "IA",
    "city": "Rose Hill"
  },
  {
    "value": "52588",
    "label": "Selma - IA 52588",
    "county": "Van Buren County",
    "state": "IA",
    "city": "Selma"
  },
  {
    "value": "52590",
    "label": "Seymour - IA 52590",
    "county": "Wayne County",
    "state": "IA",
    "city": "Seymour"
  },
  {
    "value": "52591",
    "label": "Sigourney - IA 52591",
    "county": "Keokuk County",
    "state": "IA",
    "city": "Sigourney"
  },
  {
    "value": "52593",
    "label": "Udell - IA 52593",
    "county": "Appanoose County",
    "state": "IA",
    "city": "Udell"
  },
  {
    "value": "52594",
    "label": "Unionville - IA 52594",
    "county": "Appanoose County",
    "state": "IA",
    "city": "Unionville"
  },
  {
    "value": "52601",
    "label": "Burlington - IA 52601",
    "county": "Des Moines County",
    "state": "IA",
    "city": "Burlington"
  },
  {
    "value": "52619",
    "label": "Argyle - IA 52619",
    "county": "Lee County",
    "state": "IA",
    "city": "Argyle"
  },
  {
    "value": "52620",
    "label": "Bonaparte - IA 52620",
    "county": "Van Buren County",
    "state": "IA",
    "city": "Bonaparte"
  },
  {
    "value": "52621",
    "label": "Crawfordsville - IA 52621",
    "county": "Washington County",
    "state": "IA",
    "city": "Crawfordsville"
  },
  {
    "value": "52623",
    "label": "Danville - IA 52623",
    "county": "Des Moines County",
    "state": "IA",
    "city": "Danville"
  },
  {
    "value": "52625",
    "label": "Donnellson - IA 52625",
    "county": "Lee County",
    "state": "IA",
    "city": "Donnellson"
  },
  {
    "value": "52626",
    "label": "Farmington - IA 52626",
    "county": "Van Buren County",
    "state": "IA",
    "city": "Farmington"
  },
  {
    "value": "52627",
    "label": "Fort Madison - IA 52627",
    "county": "Lee County",
    "state": "IA",
    "city": "Fort Madison"
  },
  {
    "value": "52630",
    "label": "Hillsboro - IA 52630",
    "county": "Henry County",
    "state": "IA",
    "city": "Hillsboro"
  },
  {
    "value": "52632",
    "label": "Keokuk - IA 52632",
    "county": "Lee County",
    "state": "IA",
    "city": "Keokuk"
  },
  {
    "value": "52635",
    "label": "Lockridge - IA 52635",
    "county": "Jefferson County",
    "state": "IA",
    "city": "Lockridge"
  },
  {
    "value": "52637",
    "label": "Mediapolis - IA 52637",
    "county": "Des Moines County",
    "state": "IA",
    "city": "Mediapolis"
  },
  {
    "value": "52638",
    "label": "Middletown - IA 52638",
    "county": "Des Moines County",
    "state": "IA",
    "city": "Middletown"
  },
  {
    "value": "52639",
    "label": "Montrose - IA 52639",
    "county": "Lee County",
    "state": "IA",
    "city": "Montrose"
  },
  {
    "value": "52640",
    "label": "Morning Sun - IA 52640",
    "county": "Louisa County",
    "state": "IA",
    "city": "Morning Sun"
  },
  {
    "value": "52641",
    "label": "Mount Pleasant - IA 52641",
    "county": "Henry County",
    "state": "IA",
    "city": "Mount Pleasant"
  },
  {
    "value": "52644",
    "label": "Mount Union - IA 52644",
    "county": "Henry County",
    "state": "IA",
    "city": "Mount Union"
  },
  {
    "value": "52645",
    "label": "New London - IA 52645",
    "county": "Henry County",
    "state": "IA",
    "city": "New London"
  },
  {
    "value": "52646",
    "label": "Oakville - IA 52646",
    "county": "Louisa County",
    "state": "IA",
    "city": "Oakville"
  },
  {
    "value": "52648",
    "label": "Pilot Grove - IA 52648",
    "county": "Lee County",
    "state": "IA",
    "city": "Pilot Grove"
  },
  {
    "value": "52649",
    "label": "Salem - IA 52649",
    "county": "Henry County",
    "state": "IA",
    "city": "Salem"
  },
  {
    "value": "52650",
    "label": "Sperry - IA 52650",
    "county": "Des Moines County",
    "state": "IA",
    "city": "Sperry"
  },
  {
    "value": "52651",
    "label": "Stockport - IA 52651",
    "county": "Van Buren County",
    "state": "IA",
    "city": "Stockport"
  },
  {
    "value": "52653",
    "label": "Wapello - IA 52653",
    "county": "Louisa County",
    "state": "IA",
    "city": "Wapello"
  },
  {
    "value": "52654",
    "label": "Wayland - IA 52654",
    "county": "Henry County",
    "state": "IA",
    "city": "Wayland"
  },
  {
    "value": "52655",
    "label": "West Burlington - IA 52655",
    "county": "Des Moines County",
    "state": "IA",
    "city": "West Burlington"
  },
  {
    "value": "52656",
    "label": "West Point - IA 52656",
    "county": "Lee County",
    "state": "IA",
    "city": "West Point"
  },
  {
    "value": "52657",
    "label": "Saint Paul - IA 52657",
    "county": "Lee County",
    "state": "IA",
    "city": "Saint Paul"
  },
  {
    "value": "52658",
    "label": "Wever - IA 52658",
    "county": "Lee County",
    "state": "IA",
    "city": "Wever"
  },
  {
    "value": "52659",
    "label": "Winfield - IA 52659",
    "county": "Henry County",
    "state": "IA",
    "city": "Winfield"
  },
  {
    "value": "52660",
    "label": "Yarmouth - IA 52660",
    "county": "Des Moines County",
    "state": "IA",
    "city": "Yarmouth"
  },
  {
    "value": "52720",
    "label": "Atalissa - IA 52720",
    "county": "Muscatine County",
    "state": "IA",
    "city": "Atalissa"
  },
  {
    "value": "52721",
    "label": "Bennett - IA 52721",
    "county": "Cedar County",
    "state": "IA",
    "city": "Bennett"
  },
  {
    "value": "52722",
    "label": "Bettendorf - IA 52722",
    "county": "Scott County",
    "state": "IA",
    "city": "Bettendorf"
  },
  {
    "value": "52726",
    "label": "Blue Grass - IA 52726",
    "county": "Scott County",
    "state": "IA",
    "city": "Blue Grass"
  },
  {
    "value": "52727",
    "label": "Bryant - IA 52727",
    "county": "Clinton County",
    "state": "IA",
    "city": "Bryant"
  },
  {
    "value": "52729",
    "label": "Calamus - IA 52729",
    "county": "Clinton County",
    "state": "IA",
    "city": "Calamus"
  },
  {
    "value": "52730",
    "label": "Camanche - IA 52730",
    "county": "Clinton County",
    "state": "IA",
    "city": "Camanche"
  },
  {
    "value": "52731",
    "label": "Charlotte - IA 52731",
    "county": "Clinton County",
    "state": "IA",
    "city": "Charlotte"
  },
  {
    "value": "52732",
    "label": "Clinton - IA 52732",
    "county": "Clinton County",
    "state": "IA",
    "city": "Clinton"
  },
  {
    "value": "52737",
    "label": "Columbus City - IA 52737",
    "county": "Louisa County",
    "state": "IA",
    "city": "Columbus City"
  },
  {
    "value": "52738",
    "label": "Columbus Junction - IA 52738",
    "county": "Louisa County",
    "state": "IA",
    "city": "Columbus Junction"
  },
  {
    "value": "52739",
    "label": "Conesville - IA 52739",
    "county": "Muscatine County",
    "state": "IA",
    "city": "Conesville"
  },
  {
    "value": "52742",
    "label": "De Witt - IA 52742",
    "county": "Clinton County",
    "state": "IA",
    "city": "De Witt"
  },
  {
    "value": "52745",
    "label": "Dixon - IA 52745",
    "county": "Scott County",
    "state": "IA",
    "city": "Dixon"
  },
  {
    "value": "52746",
    "label": "Donahue - IA 52746",
    "county": "Scott County",
    "state": "IA",
    "city": "Donahue"
  },
  {
    "value": "52747",
    "label": "Durant - IA 52747",
    "county": "Cedar County",
    "state": "IA",
    "city": "Durant"
  },
  {
    "value": "52748",
    "label": "Eldridge - IA 52748",
    "county": "Scott County",
    "state": "IA",
    "city": "Eldridge"
  },
  {
    "value": "52749",
    "label": "Fruitland - IA 52749",
    "county": "Muscatine County",
    "state": "IA",
    "city": "Fruitland"
  },
  {
    "value": "52750",
    "label": "Goose Lake - IA 52750",
    "county": "Clinton County",
    "state": "IA",
    "city": "Goose Lake"
  },
  {
    "value": "52751",
    "label": "Grand Mound - IA 52751",
    "county": "Clinton County",
    "state": "IA",
    "city": "Grand Mound"
  },
  {
    "value": "52753",
    "label": "Le Claire - IA 52753",
    "county": "Scott County",
    "state": "IA",
    "city": "Le Claire"
  },
  {
    "value": "52754",
    "label": "Letts - IA 52754",
    "county": "Louisa County",
    "state": "IA",
    "city": "Letts"
  },
  {
    "value": "52755",
    "label": "Lone Tree - IA 52755",
    "county": "Johnson County",
    "state": "IA",
    "city": "Lone Tree"
  },
  {
    "value": "52756",
    "label": "Long Grove - IA 52756",
    "county": "Scott County",
    "state": "IA",
    "city": "Long Grove"
  },
  {
    "value": "52759",
    "label": "Montpelier - IA 52759",
    "county": "Muscatine County",
    "state": "IA",
    "city": "Montpelier"
  },
  {
    "value": "52760",
    "label": "Moscow - IA 52760",
    "county": "Muscatine County",
    "state": "IA",
    "city": "Moscow"
  },
  {
    "value": "52761",
    "label": "Muscatine - IA 52761",
    "county": "Muscatine County",
    "state": "IA",
    "city": "Muscatine"
  },
  {
    "value": "52765",
    "label": "New Liberty - IA 52765",
    "county": "Scott County",
    "state": "IA",
    "city": "New Liberty"
  },
  {
    "value": "52766",
    "label": "Nichols - IA 52766",
    "county": "Muscatine County",
    "state": "IA",
    "city": "Nichols"
  },
  {
    "value": "52768",
    "label": "Princeton - IA 52768",
    "county": "Scott County",
    "state": "IA",
    "city": "Princeton"
  },
  {
    "value": "52769",
    "label": "Stockton - IA 52769",
    "county": "Muscatine County",
    "state": "IA",
    "city": "Stockton"
  },
  {
    "value": "52771",
    "label": "Teeds Grove - IA 52771",
    "county": "Clinton County",
    "state": "IA",
    "city": "Teeds Grove"
  },
  {
    "value": "52772",
    "label": "Tipton - IA 52772",
    "county": "Cedar County",
    "state": "IA",
    "city": "Tipton"
  },
  {
    "value": "52773",
    "label": "Walcott - IA 52773",
    "county": "Scott County",
    "state": "IA",
    "city": "Walcott"
  },
  {
    "value": "52776",
    "label": "West Liberty - IA 52776",
    "county": "Muscatine County",
    "state": "IA",
    "city": "West Liberty"
  },
  {
    "value": "52777",
    "label": "Wheatland - IA 52777",
    "county": "Clinton County",
    "state": "IA",
    "city": "Wheatland"
  },
  {
    "value": "52778",
    "label": "Wilton - IA 52778",
    "county": "Muscatine County",
    "state": "IA",
    "city": "Wilton"
  },
  {
    "value": "52801",
    "label": "Davenport - IA 52801",
    "county": "Scott County",
    "state": "IA",
    "city": "Davenport"
  },
  {
    "value": "52802",
    "label": "Davenport - IA 52802",
    "county": "Scott County",
    "state": "IA",
    "city": "Davenport"
  },
  {
    "value": "52803",
    "label": "Davenport - IA 52803",
    "county": "Scott County",
    "state": "IA",
    "city": "Davenport"
  },
  {
    "value": "52804",
    "label": "Davenport - IA 52804",
    "county": "Scott County",
    "state": "IA",
    "city": "Davenport"
  },
  {
    "value": "52806",
    "label": "Davenport - IA 52806",
    "county": "Scott County",
    "state": "IA",
    "city": "Davenport"
  },
  {
    "value": "52807",
    "label": "Davenport - IA 52807",
    "county": "Scott County",
    "state": "IA",
    "city": "Davenport"
  },
  {
    "value": "66002",
    "label": "Atchison - KS 66002",
    "county": "Atchison County",
    "state": "KS",
    "city": "Atchison"
  },
  {
    "value": "66006",
    "label": "Baldwin City - KS 66006",
    "county": "Douglas County",
    "state": "KS",
    "city": "Baldwin City"
  },
  {
    "value": "66007",
    "label": "Basehor - KS 66007",
    "county": "Leavenworth County",
    "state": "KS",
    "city": "Basehor"
  },
  {
    "value": "66008",
    "label": "Bendena - KS 66008",
    "county": "Doniphan County",
    "state": "KS",
    "city": "Bendena"
  },
  {
    "value": "66010",
    "label": "Blue Mound - KS 66010",
    "county": "Linn County",
    "state": "KS",
    "city": "Blue Mound"
  },
  {
    "value": "66012",
    "label": "Bonner Springs - KS 66012",
    "county": "Wyandotte County",
    "state": "KS",
    "city": "Bonner Springs"
  },
  {
    "value": "66013",
    "label": "Bucyrus - KS 66013",
    "county": "Miami County",
    "state": "KS",
    "city": "Bucyrus"
  },
  {
    "value": "66014",
    "label": "Centerville - KS 66014",
    "county": "Linn County",
    "state": "KS",
    "city": "Centerville"
  },
  {
    "value": "66015",
    "label": "Colony - KS 66015",
    "county": "Anderson County",
    "state": "KS",
    "city": "Colony"
  },
  {
    "value": "66016",
    "label": "Cummings - KS 66016",
    "county": "Atchison County",
    "state": "KS",
    "city": "Cummings"
  },
  {
    "value": "66017",
    "label": "Denton - KS 66017",
    "county": "Doniphan County",
    "state": "KS",
    "city": "Denton"
  },
  {
    "value": "66018",
    "label": "De Soto - KS 66018",
    "county": "Johnson County",
    "state": "KS",
    "city": "De Soto"
  },
  {
    "value": "66020",
    "label": "Easton - KS 66020",
    "county": "Leavenworth County",
    "state": "KS",
    "city": "Easton"
  },
  {
    "value": "66021",
    "label": "Edgerton - KS 66021",
    "county": "Johnson County",
    "state": "KS",
    "city": "Edgerton"
  },
  {
    "value": "66023",
    "label": "Effingham - KS 66023",
    "county": "Atchison County",
    "state": "KS",
    "city": "Effingham"
  },
  {
    "value": "66025",
    "label": "Eudora - KS 66025",
    "county": "Douglas County",
    "state": "KS",
    "city": "Eudora"
  },
  {
    "value": "66026",
    "label": "Fontana - KS 66026",
    "county": "Miami County",
    "state": "KS",
    "city": "Fontana"
  },
  {
    "value": "66027",
    "label": "Fort Leavenworth - KS 66027",
    "county": "Leavenworth County",
    "state": "KS",
    "city": "Fort Leavenworth"
  },
  {
    "value": "66030",
    "label": "Gardner - KS 66030",
    "county": "Johnson County",
    "state": "KS",
    "city": "Gardner"
  },
  {
    "value": "66031",
    "label": "New Century - KS 66031",
    "county": "Johnson County",
    "state": "KS",
    "city": "New Century"
  },
  {
    "value": "66032",
    "label": "Garnett - KS 66032",
    "county": "Anderson County",
    "state": "KS",
    "city": "Garnett"
  },
  {
    "value": "66033",
    "label": "Greeley - KS 66033",
    "county": "Anderson County",
    "state": "KS",
    "city": "Greeley"
  },
  {
    "value": "66035",
    "label": "Highland - KS 66035",
    "county": "Doniphan County",
    "state": "KS",
    "city": "Highland"
  },
  {
    "value": "66039",
    "label": "Kincaid - KS 66039",
    "county": "Anderson County",
    "state": "KS",
    "city": "Kincaid"
  },
  {
    "value": "66040",
    "label": "La Cygne - KS 66040",
    "county": "Linn County",
    "state": "KS",
    "city": "La Cygne"
  },
  {
    "value": "66041",
    "label": "Lancaster - KS 66041",
    "county": "Atchison County",
    "state": "KS",
    "city": "Lancaster"
  },
  {
    "value": "66042",
    "label": "Lane - KS 66042",
    "county": "Franklin County",
    "state": "KS",
    "city": "Lane"
  },
  {
    "value": "66043",
    "label": "Lansing - KS 66043",
    "county": "Leavenworth County",
    "state": "KS",
    "city": "Lansing"
  },
  {
    "value": "66044",
    "label": "Lawrence - KS 66044",
    "county": "Douglas County",
    "state": "KS",
    "city": "Lawrence"
  },
  {
    "value": "66046",
    "label": "Lawrence - KS 66046",
    "county": "Douglas County",
    "state": "KS",
    "city": "Lawrence"
  },
  {
    "value": "66047",
    "label": "Lawrence - KS 66047",
    "county": "Douglas County",
    "state": "KS",
    "city": "Lawrence"
  },
  {
    "value": "66048",
    "label": "Leavenworth - KS 66048",
    "county": "Leavenworth County",
    "state": "KS",
    "city": "Leavenworth"
  },
  {
    "value": "66049",
    "label": "Lawrence - KS 66049",
    "county": "Douglas County",
    "state": "KS",
    "city": "Lawrence"
  },
  {
    "value": "66050",
    "label": "Lecompton - KS 66050",
    "county": "Douglas County",
    "state": "KS",
    "city": "Lecompton"
  },
  {
    "value": "66052",
    "label": "Linwood - KS 66052",
    "county": "Leavenworth County",
    "state": "KS",
    "city": "Linwood"
  },
  {
    "value": "66053",
    "label": "Louisburg - KS 66053",
    "county": "Miami County",
    "state": "KS",
    "city": "Louisburg"
  },
  {
    "value": "66054",
    "label": "Mc Louth - KS 66054",
    "county": "Jefferson County",
    "state": "KS",
    "city": "Mc Louth"
  },
  {
    "value": "66056",
    "label": "Mound City - KS 66056",
    "county": "Linn County",
    "state": "KS",
    "city": "Mound City"
  },
  {
    "value": "66058",
    "label": "Muscotah - KS 66058",
    "county": "Atchison County",
    "state": "KS",
    "city": "Muscotah"
  },
  {
    "value": "66060",
    "label": "Nortonville - KS 66060",
    "county": "Jefferson County",
    "state": "KS",
    "city": "Nortonville"
  },
  {
    "value": "66061",
    "label": "Olathe - KS 66061",
    "county": "Johnson County",
    "state": "KS",
    "city": "Olathe"
  },
  {
    "value": "66062",
    "label": "Olathe - KS 66062",
    "county": "Johnson County",
    "state": "KS",
    "city": "Olathe"
  },
  {
    "value": "66064",
    "label": "Osawatomie - KS 66064",
    "county": "Miami County",
    "state": "KS",
    "city": "Osawatomie"
  },
  {
    "value": "66066",
    "label": "Oskaloosa - KS 66066",
    "county": "Jefferson County",
    "state": "KS",
    "city": "Oskaloosa"
  },
  {
    "value": "66067",
    "label": "Ottawa - KS 66067",
    "county": "Franklin County",
    "state": "KS",
    "city": "Ottawa"
  },
  {
    "value": "66070",
    "label": "Ozawkie - KS 66070",
    "county": "Jefferson County",
    "state": "KS",
    "city": "Ozawkie"
  },
  {
    "value": "66071",
    "label": "Paola - KS 66071",
    "county": "Miami County",
    "state": "KS",
    "city": "Paola"
  },
  {
    "value": "66072",
    "label": "Parker - KS 66072",
    "county": "Linn County",
    "state": "KS",
    "city": "Parker"
  },
  {
    "value": "66073",
    "label": "Perry - KS 66073",
    "county": "Jefferson County",
    "state": "KS",
    "city": "Perry"
  },
  {
    "value": "66075",
    "label": "Pleasanton - KS 66075",
    "county": "Linn County",
    "state": "KS",
    "city": "Pleasanton"
  },
  {
    "value": "66076",
    "label": "Pomona - KS 66076",
    "county": "Franklin County",
    "state": "KS",
    "city": "Pomona"
  },
  {
    "value": "66078",
    "label": "Princeton - KS 66078",
    "county": "Franklin County",
    "state": "KS",
    "city": "Princeton"
  },
  {
    "value": "66079",
    "label": "Rantoul - KS 66079",
    "county": "Franklin County",
    "state": "KS",
    "city": "Rantoul"
  },
  {
    "value": "66080",
    "label": "Richmond - KS 66080",
    "county": "Franklin County",
    "state": "KS",
    "city": "Richmond"
  },
  {
    "value": "66083",
    "label": "Spring Hill - KS 66083",
    "county": "Johnson County",
    "state": "KS",
    "city": "Spring Hill"
  },
  {
    "value": "66085",
    "label": "Stilwell - KS 66085",
    "county": "Johnson County",
    "state": "KS",
    "city": "Stilwell"
  },
  {
    "value": "66086",
    "label": "Tonganoxie - KS 66086",
    "county": "Leavenworth County",
    "state": "KS",
    "city": "Tonganoxie"
  },
  {
    "value": "66087",
    "label": "Troy - KS 66087",
    "county": "Doniphan County",
    "state": "KS",
    "city": "Troy"
  },
  {
    "value": "66088",
    "label": "Valley Falls - KS 66088",
    "county": "Jefferson County",
    "state": "KS",
    "city": "Valley Falls"
  },
  {
    "value": "66090",
    "label": "Wathena - KS 66090",
    "county": "Doniphan County",
    "state": "KS",
    "city": "Wathena"
  },
  {
    "value": "66091",
    "label": "Welda - KS 66091",
    "county": "Anderson County",
    "state": "KS",
    "city": "Welda"
  },
  {
    "value": "66092",
    "label": "Wellsville - KS 66092",
    "county": "Franklin County",
    "state": "KS",
    "city": "Wellsville"
  },
  {
    "value": "66093",
    "label": "Westphalia - KS 66093",
    "county": "Anderson County",
    "state": "KS",
    "city": "Westphalia"
  },
  {
    "value": "66094",
    "label": "White Cloud - KS 66094",
    "county": "Doniphan County",
    "state": "KS",
    "city": "White Cloud"
  },
  {
    "value": "66095",
    "label": "Williamsburg - KS 66095",
    "county": "Franklin County",
    "state": "KS",
    "city": "Williamsburg"
  },
  {
    "value": "66097",
    "label": "Winchester - KS 66097",
    "county": "Jefferson County",
    "state": "KS",
    "city": "Winchester"
  },
  {
    "value": "66101",
    "label": "Kansas City - KS 66101",
    "county": "Wyandotte County",
    "state": "KS",
    "city": "Kansas City"
  },
  {
    "value": "66102",
    "label": "Kansas City - KS 66102",
    "county": "Wyandotte County",
    "state": "KS",
    "city": "Kansas City"
  },
  {
    "value": "66103",
    "label": "Kansas City - KS 66103",
    "county": "Wyandotte County",
    "state": "KS",
    "city": "Kansas City"
  },
  {
    "value": "66104",
    "label": "Kansas City - KS 66104",
    "county": "Wyandotte County",
    "state": "KS",
    "city": "Kansas City"
  },
  {
    "value": "66105",
    "label": "Kansas City - KS 66105",
    "county": "Wyandotte County",
    "state": "KS",
    "city": "Kansas City"
  },
  {
    "value": "66106",
    "label": "Kansas City - KS 66106",
    "county": "Wyandotte County",
    "state": "KS",
    "city": "Kansas City"
  },
  {
    "value": "66109",
    "label": "Kansas City - KS 66109",
    "county": "Wyandotte County",
    "state": "KS",
    "city": "Kansas City"
  },
  {
    "value": "66111",
    "label": "Kansas City - KS 66111",
    "county": "Wyandotte County",
    "state": "KS",
    "city": "Kansas City"
  },
  {
    "value": "66112",
    "label": "Kansas City - KS 66112",
    "county": "Wyandotte County",
    "state": "KS",
    "city": "Kansas City"
  },
  {
    "value": "66115",
    "label": "Kansas City - KS 66115",
    "county": "Wyandotte County",
    "state": "KS",
    "city": "Kansas City"
  },
  {
    "value": "66118",
    "label": "Kansas City - KS 66118",
    "county": "Wyandotte County",
    "state": "KS",
    "city": "Kansas City"
  },
  {
    "value": "66202",
    "label": "Mission - KS 66202",
    "county": "Johnson County",
    "state": "KS",
    "city": "Mission"
  },
  {
    "value": "66203",
    "label": "Shawnee - KS 66203",
    "county": "Johnson County",
    "state": "KS",
    "city": "Shawnee"
  },
  {
    "value": "66204",
    "label": "Overland Park - KS 66204",
    "county": "Johnson County",
    "state": "KS",
    "city": "Overland Park"
  },
  {
    "value": "66205",
    "label": "Mission - KS 66205",
    "county": "Johnson County",
    "state": "KS",
    "city": "Mission"
  },
  {
    "value": "66206",
    "label": "Leawood - KS 66206",
    "county": "Johnson County",
    "state": "KS",
    "city": "Leawood"
  },
  {
    "value": "66207",
    "label": "Overland Park - KS 66207",
    "county": "Johnson County",
    "state": "KS",
    "city": "Overland Park"
  },
  {
    "value": "66208",
    "label": "Prairie Village - KS 66208",
    "county": "Johnson County",
    "state": "KS",
    "city": "Prairie Village"
  },
  {
    "value": "66209",
    "label": "Leawood - KS 66209",
    "county": "Johnson County",
    "state": "KS",
    "city": "Leawood"
  },
  {
    "value": "66210",
    "label": "Overland Park - KS 66210",
    "county": "Johnson County",
    "state": "KS",
    "city": "Overland Park"
  },
  {
    "value": "66211",
    "label": "Leawood - KS 66211",
    "county": "Johnson County",
    "state": "KS",
    "city": "Leawood"
  },
  {
    "value": "66212",
    "label": "Overland Park - KS 66212",
    "county": "Johnson County",
    "state": "KS",
    "city": "Overland Park"
  },
  {
    "value": "66213",
    "label": "Overland Park - KS 66213",
    "county": "Johnson County",
    "state": "KS",
    "city": "Overland Park"
  },
  {
    "value": "66214",
    "label": "Overland Park - KS 66214",
    "county": "Johnson County",
    "state": "KS",
    "city": "Overland Park"
  },
  {
    "value": "66215",
    "label": "Lenexa - KS 66215",
    "county": "Johnson County",
    "state": "KS",
    "city": "Lenexa"
  },
  {
    "value": "66216",
    "label": "Shawnee - KS 66216",
    "county": "Johnson County",
    "state": "KS",
    "city": "Shawnee"
  },
  {
    "value": "66217",
    "label": "Shawnee - KS 66217",
    "county": "Johnson County",
    "state": "KS",
    "city": "Shawnee"
  },
  {
    "value": "66218",
    "label": "Shawnee - KS 66218",
    "county": "Johnson County",
    "state": "KS",
    "city": "Shawnee"
  },
  {
    "value": "66219",
    "label": "Lenexa - KS 66219",
    "county": "Johnson County",
    "state": "KS",
    "city": "Lenexa"
  },
  {
    "value": "66220",
    "label": "Lenexa - KS 66220",
    "county": "Johnson County",
    "state": "KS",
    "city": "Lenexa"
  },
  {
    "value": "66221",
    "label": "Overland Park - KS 66221",
    "county": "Johnson County",
    "state": "KS",
    "city": "Overland Park"
  },
  {
    "value": "66223",
    "label": "Overland Park - KS 66223",
    "county": "Johnson County",
    "state": "KS",
    "city": "Overland Park"
  },
  {
    "value": "66224",
    "label": "Overland Park - KS 66224",
    "county": "Johnson County",
    "state": "KS",
    "city": "Overland Park"
  },
  {
    "value": "66226",
    "label": "Shawnee - KS 66226",
    "county": "Johnson County",
    "state": "KS",
    "city": "Shawnee"
  },
  {
    "value": "66227",
    "label": "Lenexa - KS 66227",
    "county": "Johnson County",
    "state": "KS",
    "city": "Lenexa"
  },
  {
    "value": "66401",
    "label": "Alma - KS 66401",
    "county": "Wabaunsee County",
    "state": "KS",
    "city": "Alma"
  },
  {
    "value": "66402",
    "label": "Auburn - KS 66402",
    "county": "Shawnee County",
    "state": "KS",
    "city": "Auburn"
  },
  {
    "value": "66403",
    "label": "Axtell - KS 66403",
    "county": "Marshall County",
    "state": "KS",
    "city": "Axtell"
  },
  {
    "value": "66404",
    "label": "Baileyville - KS 66404",
    "county": "Nemaha County",
    "state": "KS",
    "city": "Baileyville"
  },
  {
    "value": "66406",
    "label": "Beattie - KS 66406",
    "county": "Marshall County",
    "state": "KS",
    "city": "Beattie"
  },
  {
    "value": "66407",
    "label": "Belvue - KS 66407",
    "county": "Pottawatomie County",
    "state": "KS",
    "city": "Belvue"
  },
  {
    "value": "66408",
    "label": "Bern - KS 66408",
    "county": "Nemaha County",
    "state": "KS",
    "city": "Bern"
  },
  {
    "value": "66409",
    "label": "Berryton - KS 66409",
    "county": "Shawnee County",
    "state": "KS",
    "city": "Berryton"
  },
  {
    "value": "66411",
    "label": "Blue Rapids - KS 66411",
    "county": "Marshall County",
    "state": "KS",
    "city": "Blue Rapids"
  },
  {
    "value": "66412",
    "label": "Bremen - KS 66412",
    "county": "Marshall County",
    "state": "KS",
    "city": "Bremen"
  },
  {
    "value": "66413",
    "label": "Burlingame - KS 66413",
    "county": "Osage County",
    "state": "KS",
    "city": "Burlingame"
  },
  {
    "value": "66414",
    "label": "Carbondale - KS 66414",
    "county": "Osage County",
    "state": "KS",
    "city": "Carbondale"
  },
  {
    "value": "66415",
    "label": "Centralia - KS 66415",
    "county": "Nemaha County",
    "state": "KS",
    "city": "Centralia"
  },
  {
    "value": "66416",
    "label": "Circleville - KS 66416",
    "county": "Jackson County",
    "state": "KS",
    "city": "Circleville"
  },
  {
    "value": "66417",
    "label": "Corning - KS 66417",
    "county": "Nemaha County",
    "state": "KS",
    "city": "Corning"
  },
  {
    "value": "66418",
    "label": "Delia - KS 66418",
    "county": "Jackson County",
    "state": "KS",
    "city": "Delia"
  },
  {
    "value": "66419",
    "label": "Denison - KS 66419",
    "county": "Jackson County",
    "state": "KS",
    "city": "Denison"
  },
  {
    "value": "66422",
    "label": "Emmett - KS 66422",
    "county": "Pottawatomie County",
    "state": "KS",
    "city": "Emmett"
  },
  {
    "value": "66423",
    "label": "Eskridge - KS 66423",
    "county": "Wabaunsee County",
    "state": "KS",
    "city": "Eskridge"
  },
  {
    "value": "66424",
    "label": "Everest - KS 66424",
    "county": "Brown County",
    "state": "KS",
    "city": "Everest"
  },
  {
    "value": "66425",
    "label": "Fairview - KS 66425",
    "county": "Brown County",
    "state": "KS",
    "city": "Fairview"
  },
  {
    "value": "66427",
    "label": "Frankfort - KS 66427",
    "county": "Marshall County",
    "state": "KS",
    "city": "Frankfort"
  },
  {
    "value": "66428",
    "label": "Goff - KS 66428",
    "county": "Nemaha County",
    "state": "KS",
    "city": "Goff"
  },
  {
    "value": "66429",
    "label": "Grantville - KS 66429",
    "county": "Jefferson County",
    "state": "KS",
    "city": "Grantville"
  },
  {
    "value": "66431",
    "label": "Harveyville - KS 66431",
    "county": "Wabaunsee County",
    "state": "KS",
    "city": "Harveyville"
  },
  {
    "value": "66432",
    "label": "Havensville - KS 66432",
    "county": "Pottawatomie County",
    "state": "KS",
    "city": "Havensville"
  },
  {
    "value": "66434",
    "label": "Hiawatha - KS 66434",
    "county": "Brown County",
    "state": "KS",
    "city": "Hiawatha"
  },
  {
    "value": "66436",
    "label": "Holton - KS 66436",
    "county": "Jackson County",
    "state": "KS",
    "city": "Holton"
  },
  {
    "value": "66438",
    "label": "Home - KS 66438",
    "county": "Marshall County",
    "state": "KS",
    "city": "Home"
  },
  {
    "value": "66439",
    "label": "Horton - KS 66439",
    "county": "Atchison County",
    "state": "KS",
    "city": "Horton"
  },
  {
    "value": "66440",
    "label": "Hoyt - KS 66440",
    "county": "Jackson County",
    "state": "KS",
    "city": "Hoyt"
  },
  {
    "value": "66441",
    "label": "Junction City - KS 66441",
    "county": "Geary County",
    "state": "KS",
    "city": "Junction City"
  },
  {
    "value": "66442",
    "label": "Fort Riley - KS 66442",
    "county": "Riley County",
    "state": "KS",
    "city": "Fort Riley"
  },
  {
    "value": "66449",
    "label": "Leonardville - KS 66449",
    "county": "Riley County",
    "state": "KS",
    "city": "Leonardville"
  },
  {
    "value": "66451",
    "label": "Lyndon - KS 66451",
    "county": "Osage County",
    "state": "KS",
    "city": "Lyndon"
  },
  {
    "value": "66502",
    "label": "Manhattan - KS 66502",
    "county": "Riley County",
    "state": "KS",
    "city": "Manhattan"
  },
  {
    "value": "66503",
    "label": "Manhattan - KS 66503",
    "county": "Riley County",
    "state": "KS",
    "city": "Manhattan"
  },
  {
    "value": "66506",
    "label": "Manhattan - KS 66506",
    "county": "Riley County",
    "state": "KS",
    "city": "Manhattan"
  },
  {
    "value": "66507",
    "label": "Maple Hill - KS 66507",
    "county": "Wabaunsee County",
    "state": "KS",
    "city": "Maple Hill"
  },
  {
    "value": "66508",
    "label": "Marysville - KS 66508",
    "county": "Marshall County",
    "state": "KS",
    "city": "Marysville"
  },
  {
    "value": "66509",
    "label": "Mayetta - KS 66509",
    "county": "Jackson County",
    "state": "KS",
    "city": "Mayetta"
  },
  {
    "value": "66510",
    "label": "Melvern - KS 66510",
    "county": "Osage County",
    "state": "KS",
    "city": "Melvern"
  },
  {
    "value": "66512",
    "label": "Meriden - KS 66512",
    "county": "Jefferson County",
    "state": "KS",
    "city": "Meriden"
  },
  {
    "value": "66514",
    "label": "Milford - KS 66514",
    "county": "Geary County",
    "state": "KS",
    "city": "Milford"
  },
  {
    "value": "66515",
    "label": "Morrill - KS 66515",
    "county": "Brown County",
    "state": "KS",
    "city": "Morrill"
  },
  {
    "value": "66516",
    "label": "Netawaka - KS 66516",
    "county": "Jackson County",
    "state": "KS",
    "city": "Netawaka"
  },
  {
    "value": "66517",
    "label": "Ogden - KS 66517",
    "county": "Riley County",
    "state": "KS",
    "city": "Ogden"
  },
  {
    "value": "66518",
    "label": "Oketo - KS 66518",
    "county": "Marshall County",
    "state": "KS",
    "city": "Oketo"
  },
  {
    "value": "66520",
    "label": "Olsburg - KS 66520",
    "county": "Pottawatomie County",
    "state": "KS",
    "city": "Olsburg"
  },
  {
    "value": "66521",
    "label": "Onaga - KS 66521",
    "county": "Pottawatomie County",
    "state": "KS",
    "city": "Onaga"
  },
  {
    "value": "66522",
    "label": "Oneida - KS 66522",
    "county": "Nemaha County",
    "state": "KS",
    "city": "Oneida"
  },
  {
    "value": "66523",
    "label": "Osage City - KS 66523",
    "county": "Osage County",
    "state": "KS",
    "city": "Osage City"
  },
  {
    "value": "66524",
    "label": "Overbrook - KS 66524",
    "county": "Osage County",
    "state": "KS",
    "city": "Overbrook"
  },
  {
    "value": "66526",
    "label": "Paxico - KS 66526",
    "county": "Wabaunsee County",
    "state": "KS",
    "city": "Paxico"
  },
  {
    "value": "66527",
    "label": "Powhattan - KS 66527",
    "county": "Brown County",
    "state": "KS",
    "city": "Powhattan"
  },
  {
    "value": "66528",
    "label": "Quenemo - KS 66528",
    "county": "Osage County",
    "state": "KS",
    "city": "Quenemo"
  },
  {
    "value": "66531",
    "label": "Riley - KS 66531",
    "county": "Riley County",
    "state": "KS",
    "city": "Riley"
  },
  {
    "value": "66532",
    "label": "Robinson - KS 66532",
    "county": "Brown County",
    "state": "KS",
    "city": "Robinson"
  },
  {
    "value": "66533",
    "label": "Rossville - KS 66533",
    "county": "Shawnee County",
    "state": "KS",
    "city": "Rossville"
  },
  {
    "value": "66534",
    "label": "Sabetha - KS 66534",
    "county": "Nemaha County",
    "state": "KS",
    "city": "Sabetha"
  },
  {
    "value": "66535",
    "label": "Saint George - KS 66535",
    "county": "Pottawatomie County",
    "state": "KS",
    "city": "Saint George"
  },
  {
    "value": "66536",
    "label": "Saint Marys - KS 66536",
    "county": "Pottawatomie County",
    "state": "KS",
    "city": "Saint Marys"
  },
  {
    "value": "66537",
    "label": "Scranton - KS 66537",
    "county": "Osage County",
    "state": "KS",
    "city": "Scranton"
  },
  {
    "value": "66538",
    "label": "Seneca - KS 66538",
    "county": "Nemaha County",
    "state": "KS",
    "city": "Seneca"
  },
  {
    "value": "66539",
    "label": "Silver Lake - KS 66539",
    "county": "Shawnee County",
    "state": "KS",
    "city": "Silver Lake"
  },
  {
    "value": "66540",
    "label": "Soldier - KS 66540",
    "county": "Jackson County",
    "state": "KS",
    "city": "Soldier"
  },
  {
    "value": "66541",
    "label": "Summerfield - KS 66541",
    "county": "Marshall County",
    "state": "KS",
    "city": "Summerfield"
  },
  {
    "value": "66542",
    "label": "Tecumseh - KS 66542",
    "county": "Shawnee County",
    "state": "KS",
    "city": "Tecumseh"
  },
  {
    "value": "66543",
    "label": "Vassar - KS 66543",
    "county": "Osage County",
    "state": "KS",
    "city": "Vassar"
  },
  {
    "value": "66544",
    "label": "Vermillion - KS 66544",
    "county": "Marshall County",
    "state": "KS",
    "city": "Vermillion"
  },
  {
    "value": "66546",
    "label": "Wakarusa - KS 66546",
    "county": "Shawnee County",
    "state": "KS",
    "city": "Wakarusa"
  },
  {
    "value": "66547",
    "label": "Wamego - KS 66547",
    "county": "Pottawatomie County",
    "state": "KS",
    "city": "Wamego"
  },
  {
    "value": "66548",
    "label": "Waterville - KS 66548",
    "county": "Marshall County",
    "state": "KS",
    "city": "Waterville"
  },
  {
    "value": "66549",
    "label": "Westmoreland - KS 66549",
    "county": "Pottawatomie County",
    "state": "KS",
    "city": "Westmoreland"
  },
  {
    "value": "66550",
    "label": "Wetmore - KS 66550",
    "county": "Nemaha County",
    "state": "KS",
    "city": "Wetmore"
  },
  {
    "value": "66552",
    "label": "Whiting - KS 66552",
    "county": "Jackson County",
    "state": "KS",
    "city": "Whiting"
  },
  {
    "value": "66554",
    "label": "Randolph - KS 66554",
    "county": "Riley County",
    "state": "KS",
    "city": "Randolph"
  },
  {
    "value": "66603",
    "label": "Topeka - KS 66603",
    "county": "Shawnee County",
    "state": "KS",
    "city": "Topeka"
  },
  {
    "value": "66604",
    "label": "Topeka - KS 66604",
    "county": "Shawnee County",
    "state": "KS",
    "city": "Topeka"
  },
  {
    "value": "66605",
    "label": "Topeka - KS 66605",
    "county": "Shawnee County",
    "state": "KS",
    "city": "Topeka"
  },
  {
    "value": "66606",
    "label": "Topeka - KS 66606",
    "county": "Shawnee County",
    "state": "KS",
    "city": "Topeka"
  },
  {
    "value": "66607",
    "label": "Topeka - KS 66607",
    "county": "Shawnee County",
    "state": "KS",
    "city": "Topeka"
  },
  {
    "value": "66608",
    "label": "Topeka - KS 66608",
    "county": "Shawnee County",
    "state": "KS",
    "city": "Topeka"
  },
  {
    "value": "66609",
    "label": "Topeka - KS 66609",
    "county": "Shawnee County",
    "state": "KS",
    "city": "Topeka"
  },
  {
    "value": "66610",
    "label": "Topeka - KS 66610",
    "county": "Shawnee County",
    "state": "KS",
    "city": "Topeka"
  },
  {
    "value": "66611",
    "label": "Topeka - KS 66611",
    "county": "Shawnee County",
    "state": "KS",
    "city": "Topeka"
  },
  {
    "value": "66612",
    "label": "Topeka - KS 66612",
    "county": "Shawnee County",
    "state": "KS",
    "city": "Topeka"
  },
  {
    "value": "66614",
    "label": "Topeka - KS 66614",
    "county": "Shawnee County",
    "state": "KS",
    "city": "Topeka"
  },
  {
    "value": "66615",
    "label": "Topeka - KS 66615",
    "county": "Shawnee County",
    "state": "KS",
    "city": "Topeka"
  },
  {
    "value": "66616",
    "label": "Topeka - KS 66616",
    "county": "Shawnee County",
    "state": "KS",
    "city": "Topeka"
  },
  {
    "value": "66617",
    "label": "Topeka - KS 66617",
    "county": "Shawnee County",
    "state": "KS",
    "city": "Topeka"
  },
  {
    "value": "66618",
    "label": "Topeka - KS 66618",
    "county": "Shawnee County",
    "state": "KS",
    "city": "Topeka"
  },
  {
    "value": "66619",
    "label": "Topeka - KS 66619",
    "county": "Shawnee County",
    "state": "KS",
    "city": "Topeka"
  },
  {
    "value": "66621",
    "label": "Topeka - KS 66621",
    "county": "Shawnee County",
    "state": "KS",
    "city": "Topeka"
  },
  {
    "value": "66624",
    "label": "Topeka - KS 66624",
    "county": "Shawnee County",
    "state": "KS",
    "city": "Topeka"
  },
  {
    "value": "66683",
    "label": "Topeka - KS 66683",
    "county": "Shawnee County",
    "state": "KS",
    "city": "Topeka"
  },
  {
    "value": "66701",
    "label": "Fort Scott - KS 66701",
    "county": "Bourbon County",
    "state": "KS",
    "city": "Fort Scott"
  },
  {
    "value": "66710",
    "label": "Altoona - KS 66710",
    "county": "Wilson County",
    "state": "KS",
    "city": "Altoona"
  },
  {
    "value": "66711",
    "label": "Arcadia - KS 66711",
    "county": "Crawford County",
    "state": "KS",
    "city": "Arcadia"
  },
  {
    "value": "66712",
    "label": "Arma - KS 66712",
    "county": "Crawford County",
    "state": "KS",
    "city": "Arma"
  },
  {
    "value": "66713",
    "label": "Baxter Springs - KS 66713",
    "county": "Cherokee County",
    "state": "KS",
    "city": "Baxter Springs"
  },
  {
    "value": "66714",
    "label": "Benedict - KS 66714",
    "county": "Wilson County",
    "state": "KS",
    "city": "Benedict"
  },
  {
    "value": "66716",
    "label": "Bronson - KS 66716",
    "county": "Bourbon County",
    "state": "KS",
    "city": "Bronson"
  },
  {
    "value": "66717",
    "label": "Buffalo - KS 66717",
    "county": "Wilson County",
    "state": "KS",
    "city": "Buffalo"
  },
  {
    "value": "66720",
    "label": "Chanute - KS 66720",
    "county": "Neosho County",
    "state": "KS",
    "city": "Chanute"
  },
  {
    "value": "66724",
    "label": "Cherokee - KS 66724",
    "county": "Crawford County",
    "state": "KS",
    "city": "Cherokee"
  },
  {
    "value": "66725",
    "label": "Columbus - KS 66725",
    "county": "Cherokee County",
    "state": "KS",
    "city": "Columbus"
  },
  {
    "value": "66732",
    "label": "Elsmore - KS 66732",
    "county": "Allen County",
    "state": "KS",
    "city": "Elsmore"
  },
  {
    "value": "66733",
    "label": "Erie - KS 66733",
    "county": "Neosho County",
    "state": "KS",
    "city": "Erie"
  },
  {
    "value": "66734",
    "label": "Farlington - KS 66734",
    "county": "Crawford County",
    "state": "KS",
    "city": "Farlington"
  },
  {
    "value": "66735",
    "label": "Franklin - KS 66735",
    "county": "Crawford County",
    "state": "KS",
    "city": "Franklin"
  },
  {
    "value": "66736",
    "label": "Fredonia - KS 66736",
    "county": "Wilson County",
    "state": "KS",
    "city": "Fredonia"
  },
  {
    "value": "66738",
    "label": "Fulton - KS 66738",
    "county": "Bourbon County",
    "state": "KS",
    "city": "Fulton"
  },
  {
    "value": "66739",
    "label": "Galena - KS 66739",
    "county": "Cherokee County",
    "state": "KS",
    "city": "Galena"
  },
  {
    "value": "66740",
    "label": "Galesburg - KS 66740",
    "county": "Neosho County",
    "state": "KS",
    "city": "Galesburg"
  },
  {
    "value": "66741",
    "label": "Garland - KS 66741",
    "county": "Bourbon County",
    "state": "KS",
    "city": "Garland"
  },
  {
    "value": "66743",
    "label": "Girard - KS 66743",
    "county": "Crawford County",
    "state": "KS",
    "city": "Girard"
  },
  {
    "value": "66746",
    "label": "Hepler - KS 66746",
    "county": "Crawford County",
    "state": "KS",
    "city": "Hepler"
  },
  {
    "value": "66748",
    "label": "Humboldt - KS 66748",
    "county": "Allen County",
    "state": "KS",
    "city": "Humboldt"
  },
  {
    "value": "66749",
    "label": "Iola - KS 66749",
    "county": "Allen County",
    "state": "KS",
    "city": "Iola"
  },
  {
    "value": "66751",
    "label": "La Harpe - KS 66751",
    "county": "Allen County",
    "state": "KS",
    "city": "La Harpe"
  },
  {
    "value": "66753",
    "label": "Mc Cune - KS 66753",
    "county": "Crawford County",
    "state": "KS",
    "city": "Mc Cune"
  },
  {
    "value": "66754",
    "label": "Mapleton - KS 66754",
    "county": "Bourbon County",
    "state": "KS",
    "city": "Mapleton"
  },
  {
    "value": "66755",
    "label": "Moran - KS 66755",
    "county": "Allen County",
    "state": "KS",
    "city": "Moran"
  },
  {
    "value": "66756",
    "label": "Mulberry - KS 66756",
    "county": "Crawford County",
    "state": "KS",
    "city": "Mulberry"
  },
  {
    "value": "66757",
    "label": "Neodesha - KS 66757",
    "county": "Wilson County",
    "state": "KS",
    "city": "Neodesha"
  },
  {
    "value": "66758",
    "label": "Neosho Falls - KS 66758",
    "county": "Woodson County",
    "state": "KS",
    "city": "Neosho Falls"
  },
  {
    "value": "66761",
    "label": "Piqua - KS 66761",
    "county": "Woodson County",
    "state": "KS",
    "city": "Piqua"
  },
  {
    "value": "66762",
    "label": "Pittsburg - KS 66762",
    "county": "Crawford County",
    "state": "KS",
    "city": "Pittsburg"
  },
  {
    "value": "66763",
    "label": "Frontenac - KS 66763",
    "county": "Crawford County",
    "state": "KS",
    "city": "Frontenac"
  },
  {
    "value": "66767",
    "label": "Prescott - KS 66767",
    "county": "Linn County",
    "state": "KS",
    "city": "Prescott"
  },
  {
    "value": "66769",
    "label": "Redfield - KS 66769",
    "county": "Bourbon County",
    "state": "KS",
    "city": "Redfield"
  },
  {
    "value": "66770",
    "label": "Riverton - KS 66770",
    "county": "Cherokee County",
    "state": "KS",
    "city": "Riverton"
  },
  {
    "value": "66771",
    "label": "Saint Paul - KS 66771",
    "county": "Neosho County",
    "state": "KS",
    "city": "Saint Paul"
  },
  {
    "value": "66772",
    "label": "Savonburg - KS 66772",
    "county": "Allen County",
    "state": "KS",
    "city": "Savonburg"
  },
  {
    "value": "66773",
    "label": "Scammon - KS 66773",
    "county": "Cherokee County",
    "state": "KS",
    "city": "Scammon"
  },
  {
    "value": "66775",
    "label": "Stark - KS 66775",
    "county": "Neosho County",
    "state": "KS",
    "city": "Stark"
  },
  {
    "value": "66776",
    "label": "Thayer - KS 66776",
    "county": "Neosho County",
    "state": "KS",
    "city": "Thayer"
  },
  {
    "value": "66777",
    "label": "Toronto - KS 66777",
    "county": "Woodson County",
    "state": "KS",
    "city": "Toronto"
  },
  {
    "value": "66778",
    "label": "Treece - KS 66778",
    "county": "Cherokee County",
    "state": "KS",
    "city": "Treece"
  },
  {
    "value": "66779",
    "label": "Uniontown - KS 66779",
    "county": "Bourbon County",
    "state": "KS",
    "city": "Uniontown"
  },
  {
    "value": "66780",
    "label": "Walnut - KS 66780",
    "county": "Crawford County",
    "state": "KS",
    "city": "Walnut"
  },
  {
    "value": "66781",
    "label": "Weir - KS 66781",
    "county": "Cherokee County",
    "state": "KS",
    "city": "Weir"
  },
  {
    "value": "66783",
    "label": "Yates Center - KS 66783",
    "county": "Woodson County",
    "state": "KS",
    "city": "Yates Center"
  },
  {
    "value": "66801",
    "label": "Emporia - KS 66801",
    "county": "Lyon County",
    "state": "KS",
    "city": "Emporia"
  },
  {
    "value": "66830",
    "label": "Admire - KS 66830",
    "county": "Lyon County",
    "state": "KS",
    "city": "Admire"
  },
  {
    "value": "66833",
    "label": "Allen - KS 66833",
    "county": "Lyon County",
    "state": "KS",
    "city": "Allen"
  },
  {
    "value": "66834",
    "label": "Alta Vista - KS 66834",
    "county": "Wabaunsee County",
    "state": "KS",
    "city": "Alta Vista"
  },
  {
    "value": "66835",
    "label": "Americus - KS 66835",
    "county": "Lyon County",
    "state": "KS",
    "city": "Americus"
  },
  {
    "value": "66838",
    "label": "Burdick - KS 66838",
    "county": "Morris County",
    "state": "KS",
    "city": "Burdick"
  },
  {
    "value": "66839",
    "label": "Burlington - KS 66839",
    "county": "Coffey County",
    "state": "KS",
    "city": "Burlington"
  },
  {
    "value": "66840",
    "label": "Burns - KS 66840",
    "county": "Butler County",
    "state": "KS",
    "city": "Burns"
  },
  {
    "value": "66842",
    "label": "Cassoday - KS 66842",
    "county": "Butler County",
    "state": "KS",
    "city": "Cassoday"
  },
  {
    "value": "66843",
    "label": "Cedar Point - KS 66843",
    "county": "Chase County",
    "state": "KS",
    "city": "Cedar Point"
  },
  {
    "value": "66845",
    "label": "Cottonwood Falls - KS 66845",
    "county": "Chase County",
    "state": "KS",
    "city": "Cottonwood Falls"
  },
  {
    "value": "66846",
    "label": "Council Grove - KS 66846",
    "county": "Morris County",
    "state": "KS",
    "city": "Council Grove"
  },
  {
    "value": "66849",
    "label": "Dwight - KS 66849",
    "county": "Morris County",
    "state": "KS",
    "city": "Dwight"
  },
  {
    "value": "66850",
    "label": "Elmdale - KS 66850",
    "county": "Chase County",
    "state": "KS",
    "city": "Elmdale"
  },
  {
    "value": "66851",
    "label": "Florence - KS 66851",
    "county": "Marion County",
    "state": "KS",
    "city": "Florence"
  },
  {
    "value": "66852",
    "label": "Gridley - KS 66852",
    "county": "Coffey County",
    "state": "KS",
    "city": "Gridley"
  },
  {
    "value": "66853",
    "label": "Hamilton - KS 66853",
    "county": "Greenwood County",
    "state": "KS",
    "city": "Hamilton"
  },
  {
    "value": "66854",
    "label": "Hartford - KS 66854",
    "county": "Lyon County",
    "state": "KS",
    "city": "Hartford"
  },
  {
    "value": "66855",
    "label": "Lamont - KS 66855",
    "county": "Greenwood County",
    "state": "KS",
    "city": "Lamont"
  },
  {
    "value": "66856",
    "label": "Lebo - KS 66856",
    "county": "Coffey County",
    "state": "KS",
    "city": "Lebo"
  },
  {
    "value": "66857",
    "label": "Le Roy - KS 66857",
    "county": "Coffey County",
    "state": "KS",
    "city": "Le Roy"
  },
  {
    "value": "66858",
    "label": "Lincolnville - KS 66858",
    "county": "Marion County",
    "state": "KS",
    "city": "Lincolnville"
  },
  {
    "value": "66859",
    "label": "Lost Springs - KS 66859",
    "county": "Marion County",
    "state": "KS",
    "city": "Lost Springs"
  },
  {
    "value": "66860",
    "label": "Madison - KS 66860",
    "county": "Greenwood County",
    "state": "KS",
    "city": "Madison"
  },
  {
    "value": "66861",
    "label": "Marion - KS 66861",
    "county": "Marion County",
    "state": "KS",
    "city": "Marion"
  },
  {
    "value": "66862",
    "label": "Matfield Green - KS 66862",
    "county": "Chase County",
    "state": "KS",
    "city": "Matfield Green"
  },
  {
    "value": "66864",
    "label": "Neosho Rapids - KS 66864",
    "county": "Lyon County",
    "state": "KS",
    "city": "Neosho Rapids"
  },
  {
    "value": "66865",
    "label": "Olpe - KS 66865",
    "county": "Lyon County",
    "state": "KS",
    "city": "Olpe"
  },
  {
    "value": "66866",
    "label": "Peabody - KS 66866",
    "county": "Marion County",
    "state": "KS",
    "city": "Peabody"
  },
  {
    "value": "66868",
    "label": "Reading - KS 66868",
    "county": "Lyon County",
    "state": "KS",
    "city": "Reading"
  },
  {
    "value": "66869",
    "label": "Strong City - KS 66869",
    "county": "Chase County",
    "state": "KS",
    "city": "Strong City"
  },
  {
    "value": "66870",
    "label": "Virgil - KS 66870",
    "county": "Greenwood County",
    "state": "KS",
    "city": "Virgil"
  },
  {
    "value": "66871",
    "label": "Waverly - KS 66871",
    "county": "Coffey County",
    "state": "KS",
    "city": "Waverly"
  },
  {
    "value": "66872",
    "label": "White City - KS 66872",
    "county": "Morris County",
    "state": "KS",
    "city": "White City"
  },
  {
    "value": "66873",
    "label": "Wilsey - KS 66873",
    "county": "Morris County",
    "state": "KS",
    "city": "Wilsey"
  },
  {
    "value": "66901",
    "label": "Concordia - KS 66901",
    "county": "Cloud County",
    "state": "KS",
    "city": "Concordia"
  },
  {
    "value": "66930",
    "label": "Agenda - KS 66930",
    "county": "Republic County",
    "state": "KS",
    "city": "Agenda"
  },
  {
    "value": "66932",
    "label": "Athol - KS 66932",
    "county": "Smith County",
    "state": "KS",
    "city": "Athol"
  },
  {
    "value": "66933",
    "label": "Barnes - KS 66933",
    "county": "Washington County",
    "state": "KS",
    "city": "Barnes"
  },
  {
    "value": "66935",
    "label": "Belleville - KS 66935",
    "county": "Republic County",
    "state": "KS",
    "city": "Belleville"
  },
  {
    "value": "66936",
    "label": "Burr Oak - KS 66936",
    "county": "Jewell County",
    "state": "KS",
    "city": "Burr Oak"
  },
  {
    "value": "66937",
    "label": "Clifton - KS 66937",
    "county": "Washington County",
    "state": "KS",
    "city": "Clifton"
  },
  {
    "value": "66938",
    "label": "Clyde - KS 66938",
    "county": "Cloud County",
    "state": "KS",
    "city": "Clyde"
  },
  {
    "value": "66939",
    "label": "Courtland - KS 66939",
    "county": "Republic County",
    "state": "KS",
    "city": "Courtland"
  },
  {
    "value": "66940",
    "label": "Cuba - KS 66940",
    "county": "Republic County",
    "state": "KS",
    "city": "Cuba"
  },
  {
    "value": "66941",
    "label": "Esbon - KS 66941",
    "county": "Jewell County",
    "state": "KS",
    "city": "Esbon"
  },
  {
    "value": "66942",
    "label": "Formoso - KS 66942",
    "county": "Jewell County",
    "state": "KS",
    "city": "Formoso"
  },
  {
    "value": "66943",
    "label": "Greenleaf - KS 66943",
    "county": "Washington County",
    "state": "KS",
    "city": "Greenleaf"
  },
  {
    "value": "66944",
    "label": "Haddam - KS 66944",
    "county": "Washington County",
    "state": "KS",
    "city": "Haddam"
  },
  {
    "value": "66945",
    "label": "Hanover - KS 66945",
    "county": "Washington County",
    "state": "KS",
    "city": "Hanover"
  },
  {
    "value": "66946",
    "label": "Hollenberg - KS 66946",
    "county": "Washington County",
    "state": "KS",
    "city": "Hollenberg"
  },
  {
    "value": "66948",
    "label": "Jamestown - KS 66948",
    "county": "Cloud County",
    "state": "KS",
    "city": "Jamestown"
  },
  {
    "value": "66949",
    "label": "Jewell - KS 66949",
    "county": "Jewell County",
    "state": "KS",
    "city": "Jewell"
  },
  {
    "value": "66951",
    "label": "Kensington - KS 66951",
    "county": "Smith County",
    "state": "KS",
    "city": "Kensington"
  },
  {
    "value": "66952",
    "label": "Lebanon - KS 66952",
    "county": "Smith County",
    "state": "KS",
    "city": "Lebanon"
  },
  {
    "value": "66953",
    "label": "Linn - KS 66953",
    "county": "Washington County",
    "state": "KS",
    "city": "Linn"
  },
  {
    "value": "66955",
    "label": "Mahaska - KS 66955",
    "county": "Washington County",
    "state": "KS",
    "city": "Mahaska"
  },
  {
    "value": "66956",
    "label": "Mankato - KS 66956",
    "county": "Jewell County",
    "state": "KS",
    "city": "Mankato"
  },
  {
    "value": "66958",
    "label": "Morrowville - KS 66958",
    "county": "Washington County",
    "state": "KS",
    "city": "Morrowville"
  },
  {
    "value": "66959",
    "label": "Munden - KS 66959",
    "county": "Republic County",
    "state": "KS",
    "city": "Munden"
  },
  {
    "value": "66960",
    "label": "Narka - KS 66960",
    "county": "Republic County",
    "state": "KS",
    "city": "Narka"
  },
  {
    "value": "66962",
    "label": "Palmer - KS 66962",
    "county": "Washington County",
    "state": "KS",
    "city": "Palmer"
  },
  {
    "value": "66963",
    "label": "Randall - KS 66963",
    "county": "Jewell County",
    "state": "KS",
    "city": "Randall"
  },
  {
    "value": "66964",
    "label": "Republic - KS 66964",
    "county": "Republic County",
    "state": "KS",
    "city": "Republic"
  },
  {
    "value": "66966",
    "label": "Scandia - KS 66966",
    "county": "Republic County",
    "state": "KS",
    "city": "Scandia"
  },
  {
    "value": "66967",
    "label": "Smith Center - KS 66967",
    "county": "Smith County",
    "state": "KS",
    "city": "Smith Center"
  },
  {
    "value": "66968",
    "label": "Washington - KS 66968",
    "county": "Washington County",
    "state": "KS",
    "city": "Washington"
  },
  {
    "value": "66970",
    "label": "Webber - KS 66970",
    "county": "Jewell County",
    "state": "KS",
    "city": "Webber"
  },
  {
    "value": "67001",
    "label": "Andale - KS 67001",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Andale"
  },
  {
    "value": "67002",
    "label": "Andover - KS 67002",
    "county": "Butler County",
    "state": "KS",
    "city": "Andover"
  },
  {
    "value": "67003",
    "label": "Anthony - KS 67003",
    "county": "Harper County",
    "state": "KS",
    "city": "Anthony"
  },
  {
    "value": "67004",
    "label": "Argonia - KS 67004",
    "county": "Sumner County",
    "state": "KS",
    "city": "Argonia"
  },
  {
    "value": "67005",
    "label": "Arkansas City - KS 67005",
    "county": "Cowley County",
    "state": "KS",
    "city": "Arkansas City"
  },
  {
    "value": "67008",
    "label": "Atlanta - KS 67008",
    "county": "Cowley County",
    "state": "KS",
    "city": "Atlanta"
  },
  {
    "value": "67009",
    "label": "Attica - KS 67009",
    "county": "Harper County",
    "state": "KS",
    "city": "Attica"
  },
  {
    "value": "67010",
    "label": "Augusta - KS 67010",
    "county": "Butler County",
    "state": "KS",
    "city": "Augusta"
  },
  {
    "value": "67013",
    "label": "Belle Plaine - KS 67013",
    "county": "Sumner County",
    "state": "KS",
    "city": "Belle Plaine"
  },
  {
    "value": "67017",
    "label": "Benton - KS 67017",
    "county": "Butler County",
    "state": "KS",
    "city": "Benton"
  },
  {
    "value": "67018",
    "label": "Bluff City - KS 67018",
    "county": "Harper County",
    "state": "KS",
    "city": "Bluff City"
  },
  {
    "value": "67019",
    "label": "Burden - KS 67019",
    "county": "Cowley County",
    "state": "KS",
    "city": "Burden"
  },
  {
    "value": "67020",
    "label": "Burrton - KS 67020",
    "county": "Harvey County",
    "state": "KS",
    "city": "Burrton"
  },
  {
    "value": "67021",
    "label": "Byers - KS 67021",
    "county": "Pratt County",
    "state": "KS",
    "city": "Byers"
  },
  {
    "value": "67022",
    "label": "Caldwell - KS 67022",
    "county": "Sumner County",
    "state": "KS",
    "city": "Caldwell"
  },
  {
    "value": "67023",
    "label": "Cambridge - KS 67023",
    "county": "Cowley County",
    "state": "KS",
    "city": "Cambridge"
  },
  {
    "value": "67024",
    "label": "Cedar Vale - KS 67024",
    "county": "Chautauqua County",
    "state": "KS",
    "city": "Cedar Vale"
  },
  {
    "value": "67025",
    "label": "Cheney - KS 67025",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Cheney"
  },
  {
    "value": "67026",
    "label": "Clearwater - KS 67026",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Clearwater"
  },
  {
    "value": "67028",
    "label": "Coats - KS 67028",
    "county": "Pratt County",
    "state": "KS",
    "city": "Coats"
  },
  {
    "value": "67029",
    "label": "Coldwater - KS 67029",
    "county": "Comanche County",
    "state": "KS",
    "city": "Coldwater"
  },
  {
    "value": "67030",
    "label": "Colwich - KS 67030",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Colwich"
  },
  {
    "value": "67031",
    "label": "Conway Springs - KS 67031",
    "county": "Sumner County",
    "state": "KS",
    "city": "Conway Springs"
  },
  {
    "value": "67035",
    "label": "Cunningham - KS 67035",
    "county": "Kingman County",
    "state": "KS",
    "city": "Cunningham"
  },
  {
    "value": "67036",
    "label": "Danville - KS 67036",
    "county": "Harper County",
    "state": "KS",
    "city": "Danville"
  },
  {
    "value": "67037",
    "label": "Derby - KS 67037",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Derby"
  },
  {
    "value": "67038",
    "label": "Dexter - KS 67038",
    "county": "Cowley County",
    "state": "KS",
    "city": "Dexter"
  },
  {
    "value": "67039",
    "label": "Douglass - KS 67039",
    "county": "Butler County",
    "state": "KS",
    "city": "Douglass"
  },
  {
    "value": "67042",
    "label": "El Dorado - KS 67042",
    "county": "Butler County",
    "state": "KS",
    "city": "El Dorado"
  },
  {
    "value": "67045",
    "label": "Eureka - KS 67045",
    "county": "Greenwood County",
    "state": "KS",
    "city": "Eureka"
  },
  {
    "value": "67047",
    "label": "Fall River - KS 67047",
    "county": "Greenwood County",
    "state": "KS",
    "city": "Fall River"
  },
  {
    "value": "67049",
    "label": "Freeport - KS 67049",
    "county": "Harper County",
    "state": "KS",
    "city": "Freeport"
  },
  {
    "value": "67050",
    "label": "Garden Plain - KS 67050",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Garden Plain"
  },
  {
    "value": "67051",
    "label": "Geuda Springs - KS 67051",
    "county": "Sumner County",
    "state": "KS",
    "city": "Geuda Springs"
  },
  {
    "value": "67052",
    "label": "Goddard - KS 67052",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Goddard"
  },
  {
    "value": "67054",
    "label": "Greensburg - KS 67054",
    "county": "Kiowa County",
    "state": "KS",
    "city": "Greensburg"
  },
  {
    "value": "67055",
    "label": "Greenwich - KS 67055",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Greenwich"
  },
  {
    "value": "67056",
    "label": "Halstead - KS 67056",
    "county": "Harvey County",
    "state": "KS",
    "city": "Halstead"
  },
  {
    "value": "67057",
    "label": "Hardtner - KS 67057",
    "county": "Barber County",
    "state": "KS",
    "city": "Hardtner"
  },
  {
    "value": "67058",
    "label": "Harper - KS 67058",
    "county": "Harper County",
    "state": "KS",
    "city": "Harper"
  },
  {
    "value": "67059",
    "label": "Haviland - KS 67059",
    "county": "Kiowa County",
    "state": "KS",
    "city": "Haviland"
  },
  {
    "value": "67060",
    "label": "Haysville - KS 67060",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Haysville"
  },
  {
    "value": "67061",
    "label": "Hazelton - KS 67061",
    "county": "Barber County",
    "state": "KS",
    "city": "Hazelton"
  },
  {
    "value": "67062",
    "label": "Hesston - KS 67062",
    "county": "Harvey County",
    "state": "KS",
    "city": "Hesston"
  },
  {
    "value": "67063",
    "label": "Hillsboro - KS 67063",
    "county": "Marion County",
    "state": "KS",
    "city": "Hillsboro"
  },
  {
    "value": "67065",
    "label": "Isabel - KS 67065",
    "county": "Barber County",
    "state": "KS",
    "city": "Isabel"
  },
  {
    "value": "67066",
    "label": "Iuka - KS 67066",
    "county": "Pratt County",
    "state": "KS",
    "city": "Iuka"
  },
  {
    "value": "67067",
    "label": "Kechi - KS 67067",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Kechi"
  },
  {
    "value": "67068",
    "label": "Kingman - KS 67068",
    "county": "Kingman County",
    "state": "KS",
    "city": "Kingman"
  },
  {
    "value": "67070",
    "label": "Kiowa - KS 67070",
    "county": "Barber County",
    "state": "KS",
    "city": "Kiowa"
  },
  {
    "value": "67071",
    "label": "Lake City - KS 67071",
    "county": "Barber County",
    "state": "KS",
    "city": "Lake City"
  },
  {
    "value": "67072",
    "label": "Latham - KS 67072",
    "county": "Butler County",
    "state": "KS",
    "city": "Latham"
  },
  {
    "value": "67073",
    "label": "Lehigh - KS 67073",
    "county": "Marion County",
    "state": "KS",
    "city": "Lehigh"
  },
  {
    "value": "67074",
    "label": "Leon - KS 67074",
    "county": "Butler County",
    "state": "KS",
    "city": "Leon"
  },
  {
    "value": "67101",
    "label": "Maize - KS 67101",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Maize"
  },
  {
    "value": "67102",
    "label": "Maple City - KS 67102",
    "county": "Cowley County",
    "state": "KS",
    "city": "Maple City"
  },
  {
    "value": "67103",
    "label": "Mayfield - KS 67103",
    "county": "Sumner County",
    "state": "KS",
    "city": "Mayfield"
  },
  {
    "value": "67104",
    "label": "Medicine Lodge - KS 67104",
    "county": "Barber County",
    "state": "KS",
    "city": "Medicine Lodge"
  },
  {
    "value": "67105",
    "label": "Milan - KS 67105",
    "county": "Sumner County",
    "state": "KS",
    "city": "Milan"
  },
  {
    "value": "67106",
    "label": "Milton - KS 67106",
    "county": "Sumner County",
    "state": "KS",
    "city": "Milton"
  },
  {
    "value": "67107",
    "label": "Moundridge - KS 67107",
    "county": "McPherson County",
    "state": "KS",
    "city": "Moundridge"
  },
  {
    "value": "67108",
    "label": "Mount Hope - KS 67108",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Mount Hope"
  },
  {
    "value": "67109",
    "label": "Mullinville - KS 67109",
    "county": "Kiowa County",
    "state": "KS",
    "city": "Mullinville"
  },
  {
    "value": "67110",
    "label": "Mulvane - KS 67110",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Mulvane"
  },
  {
    "value": "67111",
    "label": "Murdock - KS 67111",
    "county": "Kingman County",
    "state": "KS",
    "city": "Murdock"
  },
  {
    "value": "67112",
    "label": "Nashville - KS 67112",
    "county": "Kingman County",
    "state": "KS",
    "city": "Nashville"
  },
  {
    "value": "67114",
    "label": "Newton - KS 67114",
    "county": "Harvey County",
    "state": "KS",
    "city": "Newton"
  },
  {
    "value": "67117",
    "label": "North Newton - KS 67117",
    "county": "Harvey County",
    "state": "KS",
    "city": "North Newton"
  },
  {
    "value": "67118",
    "label": "Norwich - KS 67118",
    "county": "Kingman County",
    "state": "KS",
    "city": "Norwich"
  },
  {
    "value": "67119",
    "label": "Oxford - KS 67119",
    "county": "Sumner County",
    "state": "KS",
    "city": "Oxford"
  },
  {
    "value": "67120",
    "label": "Peck - KS 67120",
    "county": "Sumner County",
    "state": "KS",
    "city": "Peck"
  },
  {
    "value": "67122",
    "label": "Piedmont - KS 67122",
    "county": "Greenwood County",
    "state": "KS",
    "city": "Piedmont"
  },
  {
    "value": "67123",
    "label": "Potwin - KS 67123",
    "county": "Butler County",
    "state": "KS",
    "city": "Potwin"
  },
  {
    "value": "67124",
    "label": "Pratt - KS 67124",
    "county": "Pratt County",
    "state": "KS",
    "city": "Pratt"
  },
  {
    "value": "67127",
    "label": "Protection - KS 67127",
    "county": "Comanche County",
    "state": "KS",
    "city": "Protection"
  },
  {
    "value": "67131",
    "label": "Rock - KS 67131",
    "county": "Cowley County",
    "state": "KS",
    "city": "Rock"
  },
  {
    "value": "67132",
    "label": "Rosalia - KS 67132",
    "county": "Butler County",
    "state": "KS",
    "city": "Rosalia"
  },
  {
    "value": "67133",
    "label": "Rose Hill - KS 67133",
    "county": "Butler County",
    "state": "KS",
    "city": "Rose Hill"
  },
  {
    "value": "67134",
    "label": "Sawyer - KS 67134",
    "county": "Pratt County",
    "state": "KS",
    "city": "Sawyer"
  },
  {
    "value": "67135",
    "label": "Sedgwick - KS 67135",
    "county": "Harvey County",
    "state": "KS",
    "city": "Sedgwick"
  },
  {
    "value": "67137",
    "label": "Severy - KS 67137",
    "county": "Greenwood County",
    "state": "KS",
    "city": "Severy"
  },
  {
    "value": "67138",
    "label": "Sharon - KS 67138",
    "county": "Barber County",
    "state": "KS",
    "city": "Sharon"
  },
  {
    "value": "67140",
    "label": "South Haven - KS 67140",
    "county": "Sumner County",
    "state": "KS",
    "city": "South Haven"
  },
  {
    "value": "67142",
    "label": "Spivey - KS 67142",
    "county": "Kingman County",
    "state": "KS",
    "city": "Spivey"
  },
  {
    "value": "67143",
    "label": "Sun City - KS 67143",
    "county": "Barber County",
    "state": "KS",
    "city": "Sun City"
  },
  {
    "value": "67144",
    "label": "Towanda - KS 67144",
    "county": "Butler County",
    "state": "KS",
    "city": "Towanda"
  },
  {
    "value": "67146",
    "label": "Udall - KS 67146",
    "county": "Cowley County",
    "state": "KS",
    "city": "Udall"
  },
  {
    "value": "67147",
    "label": "Valley Center - KS 67147",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Valley Center"
  },
  {
    "value": "67149",
    "label": "Viola - KS 67149",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Viola"
  },
  {
    "value": "67150",
    "label": "Waldron - KS 67150",
    "county": "Harper County",
    "state": "KS",
    "city": "Waldron"
  },
  {
    "value": "67151",
    "label": "Walton - KS 67151",
    "county": "Harvey County",
    "state": "KS",
    "city": "Walton"
  },
  {
    "value": "67152",
    "label": "Wellington - KS 67152",
    "county": "Sumner County",
    "state": "KS",
    "city": "Wellington"
  },
  {
    "value": "67154",
    "label": "Whitewater - KS 67154",
    "county": "Butler County",
    "state": "KS",
    "city": "Whitewater"
  },
  {
    "value": "67155",
    "label": "Wilmore - KS 67155",
    "county": "Comanche County",
    "state": "KS",
    "city": "Wilmore"
  },
  {
    "value": "67156",
    "label": "Winfield - KS 67156",
    "county": "Cowley County",
    "state": "KS",
    "city": "Winfield"
  },
  {
    "value": "67159",
    "label": "Zenda - KS 67159",
    "county": "Kingman County",
    "state": "KS",
    "city": "Zenda"
  },
  {
    "value": "67202",
    "label": "Wichita - KS 67202",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Wichita"
  },
  {
    "value": "67203",
    "label": "Wichita - KS 67203",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Wichita"
  },
  {
    "value": "67204",
    "label": "Wichita - KS 67204",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Wichita"
  },
  {
    "value": "67205",
    "label": "Wichita - KS 67205",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Wichita"
  },
  {
    "value": "67206",
    "label": "Wichita - KS 67206",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Wichita"
  },
  {
    "value": "67207",
    "label": "Wichita - KS 67207",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Wichita"
  },
  {
    "value": "67208",
    "label": "Wichita - KS 67208",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Wichita"
  },
  {
    "value": "67209",
    "label": "Wichita - KS 67209",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Wichita"
  },
  {
    "value": "67210",
    "label": "Wichita - KS 67210",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Wichita"
  },
  {
    "value": "67211",
    "label": "Wichita - KS 67211",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Wichita"
  },
  {
    "value": "67212",
    "label": "Wichita - KS 67212",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Wichita"
  },
  {
    "value": "67213",
    "label": "Wichita - KS 67213",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Wichita"
  },
  {
    "value": "67214",
    "label": "Wichita - KS 67214",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Wichita"
  },
  {
    "value": "67215",
    "label": "Wichita - KS 67215",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Wichita"
  },
  {
    "value": "67216",
    "label": "Wichita - KS 67216",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Wichita"
  },
  {
    "value": "67217",
    "label": "Wichita - KS 67217",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Wichita"
  },
  {
    "value": "67218",
    "label": "Wichita - KS 67218",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Wichita"
  },
  {
    "value": "67219",
    "label": "Wichita - KS 67219",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Wichita"
  },
  {
    "value": "67220",
    "label": "Wichita - KS 67220",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Wichita"
  },
  {
    "value": "67221",
    "label": "Mcconnell AFB - KS 67221",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Mcconnell AFB"
  },
  {
    "value": "67223",
    "label": "Wichita - KS 67223",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Wichita"
  },
  {
    "value": "67226",
    "label": "Wichita - KS 67226",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Wichita"
  },
  {
    "value": "67227",
    "label": "Wichita - KS 67227",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Wichita"
  },
  {
    "value": "67228",
    "label": "Wichita - KS 67228",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Wichita"
  },
  {
    "value": "67230",
    "label": "Wichita - KS 67230",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Wichita"
  },
  {
    "value": "67232",
    "label": "Wichita - KS 67232",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Wichita"
  },
  {
    "value": "67235",
    "label": "Wichita - KS 67235",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Wichita"
  },
  {
    "value": "67276",
    "label": "Wichita - KS 67276",
    "county": "Sedgwick County",
    "state": "KS",
    "city": "Wichita"
  },
  {
    "value": "67301",
    "label": "Independence - KS 67301",
    "county": "Montgomery County",
    "state": "KS",
    "city": "Independence"
  },
  {
    "value": "67330",
    "label": "Altamont - KS 67330",
    "county": "Labette County",
    "state": "KS",
    "city": "Altamont"
  },
  {
    "value": "67332",
    "label": "Bartlett - KS 67332",
    "county": "Labette County",
    "state": "KS",
    "city": "Bartlett"
  },
  {
    "value": "67333",
    "label": "Caney - KS 67333",
    "county": "Montgomery County",
    "state": "KS",
    "city": "Caney"
  },
  {
    "value": "67335",
    "label": "Cherryvale - KS 67335",
    "county": "Montgomery County",
    "state": "KS",
    "city": "Cherryvale"
  },
  {
    "value": "67336",
    "label": "Chetopa - KS 67336",
    "county": "Labette County",
    "state": "KS",
    "city": "Chetopa"
  },
  {
    "value": "67337",
    "label": "Coffeyville - KS 67337",
    "county": "Montgomery County",
    "state": "KS",
    "city": "Coffeyville"
  },
  {
    "value": "67341",
    "label": "Dennis - KS 67341",
    "county": "Labette County",
    "state": "KS",
    "city": "Dennis"
  },
  {
    "value": "67342",
    "label": "Edna - KS 67342",
    "county": "Labette County",
    "state": "KS",
    "city": "Edna"
  },
  {
    "value": "67344",
    "label": "Elk City - KS 67344",
    "county": "Montgomery County",
    "state": "KS",
    "city": "Elk City"
  },
  {
    "value": "67345",
    "label": "Elk Falls - KS 67345",
    "county": "Elk County",
    "state": "KS",
    "city": "Elk Falls"
  },
  {
    "value": "67346",
    "label": "Grenola - KS 67346",
    "county": "Elk County",
    "state": "KS",
    "city": "Grenola"
  },
  {
    "value": "67347",
    "label": "Havana - KS 67347",
    "county": "Montgomery County",
    "state": "KS",
    "city": "Havana"
  },
  {
    "value": "67349",
    "label": "Howard - KS 67349",
    "county": "Elk County",
    "state": "KS",
    "city": "Howard"
  },
  {
    "value": "67351",
    "label": "Liberty - KS 67351",
    "county": "Montgomery County",
    "state": "KS",
    "city": "Liberty"
  },
  {
    "value": "67352",
    "label": "Longton - KS 67352",
    "county": "Elk County",
    "state": "KS",
    "city": "Longton"
  },
  {
    "value": "67353",
    "label": "Moline - KS 67353",
    "county": "Elk County",
    "state": "KS",
    "city": "Moline"
  },
  {
    "value": "67354",
    "label": "Mound Valley - KS 67354",
    "county": "Labette County",
    "state": "KS",
    "city": "Mound Valley"
  },
  {
    "value": "67355",
    "label": "Niotaze - KS 67355",
    "county": "Chautauqua County",
    "state": "KS",
    "city": "Niotaze"
  },
  {
    "value": "67356",
    "label": "Oswego - KS 67356",
    "county": "Labette County",
    "state": "KS",
    "city": "Oswego"
  },
  {
    "value": "67357",
    "label": "Parsons - KS 67357",
    "county": "Labette County",
    "state": "KS",
    "city": "Parsons"
  },
  {
    "value": "67360",
    "label": "Peru - KS 67360",
    "county": "Chautauqua County",
    "state": "KS",
    "city": "Peru"
  },
  {
    "value": "67361",
    "label": "Sedan - KS 67361",
    "county": "Chautauqua County",
    "state": "KS",
    "city": "Sedan"
  },
  {
    "value": "67401",
    "label": "Salina - KS 67401",
    "county": "Saline County",
    "state": "KS",
    "city": "Salina"
  },
  {
    "value": "67410",
    "label": "Abilene - KS 67410",
    "county": "Dickinson County",
    "state": "KS",
    "city": "Abilene"
  },
  {
    "value": "67416",
    "label": "Assaria - KS 67416",
    "county": "Saline County",
    "state": "KS",
    "city": "Assaria"
  },
  {
    "value": "67417",
    "label": "Aurora - KS 67417",
    "county": "Cloud County",
    "state": "KS",
    "city": "Aurora"
  },
  {
    "value": "67418",
    "label": "Barnard - KS 67418",
    "county": "Lincoln County",
    "state": "KS",
    "city": "Barnard"
  },
  {
    "value": "67420",
    "label": "Beloit - KS 67420",
    "county": "Mitchell County",
    "state": "KS",
    "city": "Beloit"
  },
  {
    "value": "67422",
    "label": "Bennington - KS 67422",
    "county": "Ottawa County",
    "state": "KS",
    "city": "Bennington"
  },
  {
    "value": "67423",
    "label": "Beverly - KS 67423",
    "county": "Lincoln County",
    "state": "KS",
    "city": "Beverly"
  },
  {
    "value": "67425",
    "label": "Brookville - KS 67425",
    "county": "Saline County",
    "state": "KS",
    "city": "Brookville"
  },
  {
    "value": "67427",
    "label": "Bushton - KS 67427",
    "county": "Rice County",
    "state": "KS",
    "city": "Bushton"
  },
  {
    "value": "67428",
    "label": "Canton - KS 67428",
    "county": "McPherson County",
    "state": "KS",
    "city": "Canton"
  },
  {
    "value": "67430",
    "label": "Cawker City - KS 67430",
    "county": "Mitchell County",
    "state": "KS",
    "city": "Cawker City"
  },
  {
    "value": "67431",
    "label": "Chapman - KS 67431",
    "county": "Dickinson County",
    "state": "KS",
    "city": "Chapman"
  },
  {
    "value": "67432",
    "label": "Clay Center - KS 67432",
    "county": "Clay County",
    "state": "KS",
    "city": "Clay Center"
  },
  {
    "value": "67436",
    "label": "Delphos - KS 67436",
    "county": "Ottawa County",
    "state": "KS",
    "city": "Delphos"
  },
  {
    "value": "67437",
    "label": "Downs - KS 67437",
    "county": "Osborne County",
    "state": "KS",
    "city": "Downs"
  },
  {
    "value": "67438",
    "label": "Durham - KS 67438",
    "county": "Marion County",
    "state": "KS",
    "city": "Durham"
  },
  {
    "value": "67439",
    "label": "Ellsworth - KS 67439",
    "county": "Ellsworth County",
    "state": "KS",
    "city": "Ellsworth"
  },
  {
    "value": "67441",
    "label": "Enterprise - KS 67441",
    "county": "Dickinson County",
    "state": "KS",
    "city": "Enterprise"
  },
  {
    "value": "67442",
    "label": "Falun - KS 67442",
    "county": "Saline County",
    "state": "KS",
    "city": "Falun"
  },
  {
    "value": "67443",
    "label": "Galva - KS 67443",
    "county": "McPherson County",
    "state": "KS",
    "city": "Galva"
  },
  {
    "value": "67444",
    "label": "Geneseo - KS 67444",
    "county": "Rice County",
    "state": "KS",
    "city": "Geneseo"
  },
  {
    "value": "67445",
    "label": "Glasco - KS 67445",
    "county": "Cloud County",
    "state": "KS",
    "city": "Glasco"
  },
  {
    "value": "67446",
    "label": "Glen Elder - KS 67446",
    "county": "Mitchell County",
    "state": "KS",
    "city": "Glen Elder"
  },
  {
    "value": "67447",
    "label": "Green - KS 67447",
    "county": "Clay County",
    "state": "KS",
    "city": "Green"
  },
  {
    "value": "67448",
    "label": "Gypsum - KS 67448",
    "county": "Saline County",
    "state": "KS",
    "city": "Gypsum"
  },
  {
    "value": "67449",
    "label": "Herington - KS 67449",
    "county": "Dickinson County",
    "state": "KS",
    "city": "Herington"
  },
  {
    "value": "67450",
    "label": "Holyrood - KS 67450",
    "county": "Ellsworth County",
    "state": "KS",
    "city": "Holyrood"
  },
  {
    "value": "67451",
    "label": "Hope - KS 67451",
    "county": "Dickinson County",
    "state": "KS",
    "city": "Hope"
  },
  {
    "value": "67452",
    "label": "Hunter - KS 67452",
    "county": "Mitchell County",
    "state": "KS",
    "city": "Hunter"
  },
  {
    "value": "67454",
    "label": "Kanopolis - KS 67454",
    "county": "Ellsworth County",
    "state": "KS",
    "city": "Kanopolis"
  },
  {
    "value": "67455",
    "label": "Lincoln - KS 67455",
    "county": "Lincoln County",
    "state": "KS",
    "city": "Lincoln"
  },
  {
    "value": "67456",
    "label": "Lindsborg - KS 67456",
    "county": "McPherson County",
    "state": "KS",
    "city": "Lindsborg"
  },
  {
    "value": "67457",
    "label": "Little River - KS 67457",
    "county": "Rice County",
    "state": "KS",
    "city": "Little River"
  },
  {
    "value": "67458",
    "label": "Longford - KS 67458",
    "county": "Clay County",
    "state": "KS",
    "city": "Longford"
  },
  {
    "value": "67459",
    "label": "Lorraine - KS 67459",
    "county": "Ellsworth County",
    "state": "KS",
    "city": "Lorraine"
  },
  {
    "value": "67460",
    "label": "Mcpherson - KS 67460",
    "county": "McPherson County",
    "state": "KS",
    "city": "Mcpherson"
  },
  {
    "value": "67464",
    "label": "Marquette - KS 67464",
    "county": "McPherson County",
    "state": "KS",
    "city": "Marquette"
  },
  {
    "value": "67466",
    "label": "Miltonvale - KS 67466",
    "county": "Cloud County",
    "state": "KS",
    "city": "Miltonvale"
  },
  {
    "value": "67467",
    "label": "Minneapolis - KS 67467",
    "county": "Ottawa County",
    "state": "KS",
    "city": "Minneapolis"
  },
  {
    "value": "67468",
    "label": "Morganville - KS 67468",
    "county": "Clay County",
    "state": "KS",
    "city": "Morganville"
  },
  {
    "value": "67470",
    "label": "New Cambria - KS 67470",
    "county": "Saline County",
    "state": "KS",
    "city": "New Cambria"
  },
  {
    "value": "67473",
    "label": "Osborne - KS 67473",
    "county": "Osborne County",
    "state": "KS",
    "city": "Osborne"
  },
  {
    "value": "67474",
    "label": "Portis - KS 67474",
    "county": "Osborne County",
    "state": "KS",
    "city": "Portis"
  },
  {
    "value": "67475",
    "label": "Ramona - KS 67475",
    "county": "Marion County",
    "state": "KS",
    "city": "Ramona"
  },
  {
    "value": "67480",
    "label": "Solomon - KS 67480",
    "county": "Dickinson County",
    "state": "KS",
    "city": "Solomon"
  },
  {
    "value": "67481",
    "label": "Sylvan Grove - KS 67481",
    "county": "Lincoln County",
    "state": "KS",
    "city": "Sylvan Grove"
  },
  {
    "value": "67483",
    "label": "Tampa - KS 67483",
    "county": "Marion County",
    "state": "KS",
    "city": "Tampa"
  },
  {
    "value": "67484",
    "label": "Tescott - KS 67484",
    "county": "Ottawa County",
    "state": "KS",
    "city": "Tescott"
  },
  {
    "value": "67485",
    "label": "Tipton - KS 67485",
    "county": "Mitchell County",
    "state": "KS",
    "city": "Tipton"
  },
  {
    "value": "67487",
    "label": "Wakefield - KS 67487",
    "county": "Clay County",
    "state": "KS",
    "city": "Wakefield"
  },
  {
    "value": "67490",
    "label": "Wilson - KS 67490",
    "county": "Ellsworth County",
    "state": "KS",
    "city": "Wilson"
  },
  {
    "value": "67491",
    "label": "Windom - KS 67491",
    "county": "McPherson County",
    "state": "KS",
    "city": "Windom"
  },
  {
    "value": "67492",
    "label": "Woodbine - KS 67492",
    "county": "Dickinson County",
    "state": "KS",
    "city": "Woodbine"
  },
  {
    "value": "67501",
    "label": "Hutchinson - KS 67501",
    "county": "Reno County",
    "state": "KS",
    "city": "Hutchinson"
  },
  {
    "value": "67502",
    "label": "Hutchinson - KS 67502",
    "county": "Reno County",
    "state": "KS",
    "city": "Hutchinson"
  },
  {
    "value": "67505",
    "label": "South Hutchinson - KS 67505",
    "county": "Reno County",
    "state": "KS",
    "city": "South Hutchinson"
  },
  {
    "value": "67510",
    "label": "Abbyville - KS 67510",
    "county": "Reno County",
    "state": "KS",
    "city": "Abbyville"
  },
  {
    "value": "67511",
    "label": "Albert - KS 67511",
    "county": "Barton County",
    "state": "KS",
    "city": "Albert"
  },
  {
    "value": "67512",
    "label": "Alden - KS 67512",
    "county": "Rice County",
    "state": "KS",
    "city": "Alden"
  },
  {
    "value": "67513",
    "label": "Alexander - KS 67513",
    "county": "Rush County",
    "state": "KS",
    "city": "Alexander"
  },
  {
    "value": "67514",
    "label": "Arlington - KS 67514",
    "county": "Reno County",
    "state": "KS",
    "city": "Arlington"
  },
  {
    "value": "67515",
    "label": "Arnold - KS 67515",
    "county": "Ness County",
    "state": "KS",
    "city": "Arnold"
  },
  {
    "value": "67516",
    "label": "Bazine - KS 67516",
    "county": "Ness County",
    "state": "KS",
    "city": "Bazine"
  },
  {
    "value": "67518",
    "label": "Beeler - KS 67518",
    "county": "Ness County",
    "state": "KS",
    "city": "Beeler"
  },
  {
    "value": "67519",
    "label": "Belpre - KS 67519",
    "county": "Edwards County",
    "state": "KS",
    "city": "Belpre"
  },
  {
    "value": "67520",
    "label": "Bison - KS 67520",
    "county": "Rush County",
    "state": "KS",
    "city": "Bison"
  },
  {
    "value": "67521",
    "label": "Brownell - KS 67521",
    "county": "Ness County",
    "state": "KS",
    "city": "Brownell"
  },
  {
    "value": "67522",
    "label": "Buhler - KS 67522",
    "county": "Reno County",
    "state": "KS",
    "city": "Buhler"
  },
  {
    "value": "67523",
    "label": "Burdett - KS 67523",
    "county": "Pawnee County",
    "state": "KS",
    "city": "Burdett"
  },
  {
    "value": "67524",
    "label": "Chase - KS 67524",
    "county": "Rice County",
    "state": "KS",
    "city": "Chase"
  },
  {
    "value": "67525",
    "label": "Claflin - KS 67525",
    "county": "Barton County",
    "state": "KS",
    "city": "Claflin"
  },
  {
    "value": "67526",
    "label": "Ellinwood - KS 67526",
    "county": "Barton County",
    "state": "KS",
    "city": "Ellinwood"
  },
  {
    "value": "67529",
    "label": "Garfield - KS 67529",
    "county": "Pawnee County",
    "state": "KS",
    "city": "Garfield"
  },
  {
    "value": "67530",
    "label": "Great Bend - KS 67530",
    "county": "Barton County",
    "state": "KS",
    "city": "Great Bend"
  },
  {
    "value": "67543",
    "label": "Haven - KS 67543",
    "county": "Reno County",
    "state": "KS",
    "city": "Haven"
  },
  {
    "value": "67544",
    "label": "Hoisington - KS 67544",
    "county": "Barton County",
    "state": "KS",
    "city": "Hoisington"
  },
  {
    "value": "67545",
    "label": "Hudson - KS 67545",
    "county": "Stafford County",
    "state": "KS",
    "city": "Hudson"
  },
  {
    "value": "67546",
    "label": "Inman - KS 67546",
    "county": "McPherson County",
    "state": "KS",
    "city": "Inman"
  },
  {
    "value": "67547",
    "label": "Kinsley - KS 67547",
    "county": "Edwards County",
    "state": "KS",
    "city": "Kinsley"
  },
  {
    "value": "67548",
    "label": "La Crosse - KS 67548",
    "county": "Rush County",
    "state": "KS",
    "city": "La Crosse"
  },
  {
    "value": "67550",
    "label": "Larned - KS 67550",
    "county": "Pawnee County",
    "state": "KS",
    "city": "Larned"
  },
  {
    "value": "67552",
    "label": "Lewis - KS 67552",
    "county": "Edwards County",
    "state": "KS",
    "city": "Lewis"
  },
  {
    "value": "67554",
    "label": "Lyons - KS 67554",
    "county": "Rice County",
    "state": "KS",
    "city": "Lyons"
  },
  {
    "value": "67556",
    "label": "Mc Cracken - KS 67556",
    "county": "Rush County",
    "state": "KS",
    "city": "Mc Cracken"
  },
  {
    "value": "67557",
    "label": "Macksville - KS 67557",
    "county": "Stafford County",
    "state": "KS",
    "city": "Macksville"
  },
  {
    "value": "67559",
    "label": "Nekoma - KS 67559",
    "county": "Rush County",
    "state": "KS",
    "city": "Nekoma"
  },
  {
    "value": "67560",
    "label": "Ness City - KS 67560",
    "county": "Ness County",
    "state": "KS",
    "city": "Ness City"
  },
  {
    "value": "67561",
    "label": "Nickerson - KS 67561",
    "county": "Reno County",
    "state": "KS",
    "city": "Nickerson"
  },
  {
    "value": "67563",
    "label": "Offerle - KS 67563",
    "county": "Edwards County",
    "state": "KS",
    "city": "Offerle"
  },
  {
    "value": "67564",
    "label": "Olmitz - KS 67564",
    "county": "Barton County",
    "state": "KS",
    "city": "Olmitz"
  },
  {
    "value": "67565",
    "label": "Otis - KS 67565",
    "county": "Rush County",
    "state": "KS",
    "city": "Otis"
  },
  {
    "value": "67566",
    "label": "Partridge - KS 67566",
    "county": "Reno County",
    "state": "KS",
    "city": "Partridge"
  },
  {
    "value": "67567",
    "label": "Pawnee Rock - KS 67567",
    "county": "Barton County",
    "state": "KS",
    "city": "Pawnee Rock"
  },
  {
    "value": "67568",
    "label": "Plevna - KS 67568",
    "county": "Reno County",
    "state": "KS",
    "city": "Plevna"
  },
  {
    "value": "67570",
    "label": "Pretty Prairie - KS 67570",
    "county": "Reno County",
    "state": "KS",
    "city": "Pretty Prairie"
  },
  {
    "value": "67572",
    "label": "Ransom - KS 67572",
    "county": "Ness County",
    "state": "KS",
    "city": "Ransom"
  },
  {
    "value": "67573",
    "label": "Raymond - KS 67573",
    "county": "Rice County",
    "state": "KS",
    "city": "Raymond"
  },
  {
    "value": "67574",
    "label": "Rozel - KS 67574",
    "county": "Pawnee County",
    "state": "KS",
    "city": "Rozel"
  },
  {
    "value": "67575",
    "label": "Rush Center - KS 67575",
    "county": "Rush County",
    "state": "KS",
    "city": "Rush Center"
  },
  {
    "value": "67576",
    "label": "St John - KS 67576",
    "county": "Stafford County",
    "state": "KS",
    "city": "St John"
  },
  {
    "value": "67578",
    "label": "Stafford - KS 67578",
    "county": "Stafford County",
    "state": "KS",
    "city": "Stafford"
  },
  {
    "value": "67579",
    "label": "Sterling - KS 67579",
    "county": "Rice County",
    "state": "KS",
    "city": "Sterling"
  },
  {
    "value": "67581",
    "label": "Sylvia - KS 67581",
    "county": "Reno County",
    "state": "KS",
    "city": "Sylvia"
  },
  {
    "value": "67583",
    "label": "Turon - KS 67583",
    "county": "Reno County",
    "state": "KS",
    "city": "Turon"
  },
  {
    "value": "67584",
    "label": "Utica - KS 67584",
    "county": "Ness County",
    "state": "KS",
    "city": "Utica"
  },
  {
    "value": "67601",
    "label": "Hays - KS 67601",
    "county": "Ellis County",
    "state": "KS",
    "city": "Hays"
  },
  {
    "value": "67621",
    "label": "Agra - KS 67621",
    "county": "Phillips County",
    "state": "KS",
    "city": "Agra"
  },
  {
    "value": "67622",
    "label": "Almena - KS 67622",
    "county": "Norton County",
    "state": "KS",
    "city": "Almena"
  },
  {
    "value": "67623",
    "label": "Alton - KS 67623",
    "county": "Osborne County",
    "state": "KS",
    "city": "Alton"
  },
  {
    "value": "67625",
    "label": "Bogue - KS 67625",
    "county": "Graham County",
    "state": "KS",
    "city": "Bogue"
  },
  {
    "value": "67626",
    "label": "Bunker Hill - KS 67626",
    "county": "Russell County",
    "state": "KS",
    "city": "Bunker Hill"
  },
  {
    "value": "67628",
    "label": "Cedar - KS 67628",
    "county": "Smith County",
    "state": "KS",
    "city": "Cedar"
  },
  {
    "value": "67629",
    "label": "Clayton - KS 67629",
    "county": "Norton County",
    "state": "KS",
    "city": "Clayton"
  },
  {
    "value": "67631",
    "label": "Collyer - KS 67631",
    "county": "Trego County",
    "state": "KS",
    "city": "Collyer"
  },
  {
    "value": "67632",
    "label": "Damar - KS 67632",
    "county": "Rooks County",
    "state": "KS",
    "city": "Damar"
  },
  {
    "value": "67634",
    "label": "Dorrance - KS 67634",
    "county": "Russell County",
    "state": "KS",
    "city": "Dorrance"
  },
  {
    "value": "67635",
    "label": "Dresden - KS 67635",
    "county": "Decatur County",
    "state": "KS",
    "city": "Dresden"
  },
  {
    "value": "67637",
    "label": "Ellis - KS 67637",
    "county": "Ellis County",
    "state": "KS",
    "city": "Ellis"
  },
  {
    "value": "67638",
    "label": "Gaylord - KS 67638",
    "county": "Smith County",
    "state": "KS",
    "city": "Gaylord"
  },
  {
    "value": "67639",
    "label": "Glade - KS 67639",
    "county": "Phillips County",
    "state": "KS",
    "city": "Glade"
  },
  {
    "value": "67640",
    "label": "Gorham - KS 67640",
    "county": "Russell County",
    "state": "KS",
    "city": "Gorham"
  },
  {
    "value": "67642",
    "label": "Hill City - KS 67642",
    "county": "Graham County",
    "state": "KS",
    "city": "Hill City"
  },
  {
    "value": "67643",
    "label": "Jennings - KS 67643",
    "county": "Decatur County",
    "state": "KS",
    "city": "Jennings"
  },
  {
    "value": "67644",
    "label": "Kirwin - KS 67644",
    "county": "Phillips County",
    "state": "KS",
    "city": "Kirwin"
  },
  {
    "value": "67645",
    "label": "Lenora - KS 67645",
    "county": "Norton County",
    "state": "KS",
    "city": "Lenora"
  },
  {
    "value": "67646",
    "label": "Logan - KS 67646",
    "county": "Phillips County",
    "state": "KS",
    "city": "Logan"
  },
  {
    "value": "67647",
    "label": "Long Island - KS 67647",
    "county": "Phillips County",
    "state": "KS",
    "city": "Long Island"
  },
  {
    "value": "67648",
    "label": "Lucas - KS 67648",
    "county": "Russell County",
    "state": "KS",
    "city": "Lucas"
  },
  {
    "value": "67649",
    "label": "Luray - KS 67649",
    "county": "Russell County",
    "state": "KS",
    "city": "Luray"
  },
  {
    "value": "67650",
    "label": "Morland - KS 67650",
    "county": "Graham County",
    "state": "KS",
    "city": "Morland"
  },
  {
    "value": "67651",
    "label": "Natoma - KS 67651",
    "county": "Osborne County",
    "state": "KS",
    "city": "Natoma"
  },
  {
    "value": "67653",
    "label": "Norcatur - KS 67653",
    "county": "Decatur County",
    "state": "KS",
    "city": "Norcatur"
  },
  {
    "value": "67654",
    "label": "Norton - KS 67654",
    "county": "Norton County",
    "state": "KS",
    "city": "Norton"
  },
  {
    "value": "67656",
    "label": "Ogallah - KS 67656",
    "county": "Trego County",
    "state": "KS",
    "city": "Ogallah"
  },
  {
    "value": "67657",
    "label": "Palco - KS 67657",
    "county": "Rooks County",
    "state": "KS",
    "city": "Palco"
  },
  {
    "value": "67658",
    "label": "Paradise - KS 67658",
    "county": "Russell County",
    "state": "KS",
    "city": "Paradise"
  },
  {
    "value": "67659",
    "label": "Penokee - KS 67659",
    "county": "Graham County",
    "state": "KS",
    "city": "Penokee"
  },
  {
    "value": "67661",
    "label": "Phillipsburg - KS 67661",
    "county": "Phillips County",
    "state": "KS",
    "city": "Phillipsburg"
  },
  {
    "value": "67663",
    "label": "Plainville - KS 67663",
    "county": "Rooks County",
    "state": "KS",
    "city": "Plainville"
  },
  {
    "value": "67664",
    "label": "Prairie View - KS 67664",
    "county": "Phillips County",
    "state": "KS",
    "city": "Prairie View"
  },
  {
    "value": "67665",
    "label": "Russell - KS 67665",
    "county": "Russell County",
    "state": "KS",
    "city": "Russell"
  },
  {
    "value": "67669",
    "label": "Stockton - KS 67669",
    "county": "Rooks County",
    "state": "KS",
    "city": "Stockton"
  },
  {
    "value": "67671",
    "label": "Victoria - KS 67671",
    "county": "Ellis County",
    "state": "KS",
    "city": "Victoria"
  },
  {
    "value": "67672",
    "label": "Wakeeney - KS 67672",
    "county": "Trego County",
    "state": "KS",
    "city": "Wakeeney"
  },
  {
    "value": "67673",
    "label": "Waldo - KS 67673",
    "county": "Osborne County",
    "state": "KS",
    "city": "Waldo"
  },
  {
    "value": "67675",
    "label": "Woodston - KS 67675",
    "county": "Rooks County",
    "state": "KS",
    "city": "Woodston"
  },
  {
    "value": "67701",
    "label": "Colby - KS 67701",
    "county": "Thomas County",
    "state": "KS",
    "city": "Colby"
  },
  {
    "value": "67730",
    "label": "Atwood - KS 67730",
    "county": "Rawlins County",
    "state": "KS",
    "city": "Atwood"
  },
  {
    "value": "67731",
    "label": "Bird City - KS 67731",
    "county": "Cheyenne County",
    "state": "KS",
    "city": "Bird City"
  },
  {
    "value": "67732",
    "label": "Brewster - KS 67732",
    "county": "Thomas County",
    "state": "KS",
    "city": "Brewster"
  },
  {
    "value": "67733",
    "label": "Edson - KS 67733",
    "county": "Sherman County",
    "state": "KS",
    "city": "Edson"
  },
  {
    "value": "67734",
    "label": "Gem - KS 67734",
    "county": "Thomas County",
    "state": "KS",
    "city": "Gem"
  },
  {
    "value": "67735",
    "label": "Goodland - KS 67735",
    "county": "Sherman County",
    "state": "KS",
    "city": "Goodland"
  },
  {
    "value": "67736",
    "label": "Gove - KS 67736",
    "county": "Gove County",
    "state": "KS",
    "city": "Gove"
  },
  {
    "value": "67737",
    "label": "Grainfield - KS 67737",
    "county": "Gove County",
    "state": "KS",
    "city": "Grainfield"
  },
  {
    "value": "67738",
    "label": "Grinnell - KS 67738",
    "county": "Gove County",
    "state": "KS",
    "city": "Grinnell"
  },
  {
    "value": "67739",
    "label": "Herndon - KS 67739",
    "county": "Rawlins County",
    "state": "KS",
    "city": "Herndon"
  },
  {
    "value": "67740",
    "label": "Hoxie - KS 67740",
    "county": "Sheridan County",
    "state": "KS",
    "city": "Hoxie"
  },
  {
    "value": "67741",
    "label": "Kanorado - KS 67741",
    "county": "Sherman County",
    "state": "KS",
    "city": "Kanorado"
  },
  {
    "value": "67743",
    "label": "Levant - KS 67743",
    "county": "Thomas County",
    "state": "KS",
    "city": "Levant"
  },
  {
    "value": "67744",
    "label": "Ludell - KS 67744",
    "county": "Rawlins County",
    "state": "KS",
    "city": "Ludell"
  },
  {
    "value": "67745",
    "label": "Mc Donald - KS 67745",
    "county": "Rawlins County",
    "state": "KS",
    "city": "Mc Donald"
  },
  {
    "value": "67747",
    "label": "Monument - KS 67747",
    "county": "Logan County",
    "state": "KS",
    "city": "Monument"
  },
  {
    "value": "67748",
    "label": "Oakley - KS 67748",
    "county": "Logan County",
    "state": "KS",
    "city": "Oakley"
  },
  {
    "value": "67749",
    "label": "Oberlin - KS 67749",
    "county": "Decatur County",
    "state": "KS",
    "city": "Oberlin"
  },
  {
    "value": "67751",
    "label": "Park - KS 67751",
    "county": "Gove County",
    "state": "KS",
    "city": "Park"
  },
  {
    "value": "67752",
    "label": "Quinter - KS 67752",
    "county": "Gove County",
    "state": "KS",
    "city": "Quinter"
  },
  {
    "value": "67753",
    "label": "Rexford - KS 67753",
    "county": "Thomas County",
    "state": "KS",
    "city": "Rexford"
  },
  {
    "value": "67756",
    "label": "Saint Francis - KS 67756",
    "county": "Cheyenne County",
    "state": "KS",
    "city": "Saint Francis"
  },
  {
    "value": "67757",
    "label": "Selden - KS 67757",
    "county": "Sheridan County",
    "state": "KS",
    "city": "Selden"
  },
  {
    "value": "67758",
    "label": "Sharon Springs - KS 67758",
    "county": "Wallace County",
    "state": "KS",
    "city": "Sharon Springs"
  },
  {
    "value": "67761",
    "label": "Wallace - KS 67761",
    "county": "Wallace County",
    "state": "KS",
    "city": "Wallace"
  },
  {
    "value": "67762",
    "label": "Weskan - KS 67762",
    "county": "Wallace County",
    "state": "KS",
    "city": "Weskan"
  },
  {
    "value": "67764",
    "label": "Winona - KS 67764",
    "county": "Logan County",
    "state": "KS",
    "city": "Winona"
  },
  {
    "value": "67801",
    "label": "Dodge City - KS 67801",
    "county": "Ford County",
    "state": "KS",
    "city": "Dodge City"
  },
  {
    "value": "67831",
    "label": "Ashland - KS 67831",
    "county": "Clark County",
    "state": "KS",
    "city": "Ashland"
  },
  {
    "value": "67834",
    "label": "Bucklin - KS 67834",
    "county": "Ford County",
    "state": "KS",
    "city": "Bucklin"
  },
  {
    "value": "67835",
    "label": "Cimarron - KS 67835",
    "county": "Gray County",
    "state": "KS",
    "city": "Cimarron"
  },
  {
    "value": "67837",
    "label": "Copeland - KS 67837",
    "county": "Gray County",
    "state": "KS",
    "city": "Copeland"
  },
  {
    "value": "67838",
    "label": "Deerfield - KS 67838",
    "county": "Kearny County",
    "state": "KS",
    "city": "Deerfield"
  },
  {
    "value": "67839",
    "label": "Dighton - KS 67839",
    "county": "Lane County",
    "state": "KS",
    "city": "Dighton"
  },
  {
    "value": "67841",
    "label": "Ensign - KS 67841",
    "county": "Gray County",
    "state": "KS",
    "city": "Ensign"
  },
  {
    "value": "67842",
    "label": "Ford - KS 67842",
    "county": "Ford County",
    "state": "KS",
    "city": "Ford"
  },
  {
    "value": "67843",
    "label": "Fort Dodge - KS 67843",
    "county": "Ford County",
    "state": "KS",
    "city": "Fort Dodge"
  },
  {
    "value": "67844",
    "label": "Fowler - KS 67844",
    "county": "Meade County",
    "state": "KS",
    "city": "Fowler"
  },
  {
    "value": "67846",
    "label": "Garden City - KS 67846",
    "county": "Finney County",
    "state": "KS",
    "city": "Garden City"
  },
  {
    "value": "67849",
    "label": "Hanston - KS 67849",
    "county": "Hodgeman County",
    "state": "KS",
    "city": "Hanston"
  },
  {
    "value": "67850",
    "label": "Healy - KS 67850",
    "county": "Lane County",
    "state": "KS",
    "city": "Healy"
  },
  {
    "value": "67851",
    "label": "Holcomb - KS 67851",
    "county": "Finney County",
    "state": "KS",
    "city": "Holcomb"
  },
  {
    "value": "67853",
    "label": "Ingalls - KS 67853",
    "county": "Gray County",
    "state": "KS",
    "city": "Ingalls"
  },
  {
    "value": "67854",
    "label": "Jetmore - KS 67854",
    "county": "Hodgeman County",
    "state": "KS",
    "city": "Jetmore"
  },
  {
    "value": "67855",
    "label": "Johnson - KS 67855",
    "county": "Stanton County",
    "state": "KS",
    "city": "Johnson"
  },
  {
    "value": "67857",
    "label": "Kendall - KS 67857",
    "county": "Hamilton County",
    "state": "KS",
    "city": "Kendall"
  },
  {
    "value": "67859",
    "label": "Kismet - KS 67859",
    "county": "Seward County",
    "state": "KS",
    "city": "Kismet"
  },
  {
    "value": "67860",
    "label": "Lakin - KS 67860",
    "county": "Kearny County",
    "state": "KS",
    "city": "Lakin"
  },
  {
    "value": "67861",
    "label": "Leoti - KS 67861",
    "county": "Wichita County",
    "state": "KS",
    "city": "Leoti"
  },
  {
    "value": "67862",
    "label": "Manter - KS 67862",
    "county": "Stanton County",
    "state": "KS",
    "city": "Manter"
  },
  {
    "value": "67863",
    "label": "Marienthal - KS 67863",
    "county": "Wichita County",
    "state": "KS",
    "city": "Marienthal"
  },
  {
    "value": "67864",
    "label": "Meade - KS 67864",
    "county": "Meade County",
    "state": "KS",
    "city": "Meade"
  },
  {
    "value": "67865",
    "label": "Minneola - KS 67865",
    "county": "Clark County",
    "state": "KS",
    "city": "Minneola"
  },
  {
    "value": "67867",
    "label": "Montezuma - KS 67867",
    "county": "Gray County",
    "state": "KS",
    "city": "Montezuma"
  },
  {
    "value": "67868",
    "label": "Pierceville - KS 67868",
    "county": "Finney County",
    "state": "KS",
    "city": "Pierceville"
  },
  {
    "value": "67869",
    "label": "Plains - KS 67869",
    "county": "Meade County",
    "state": "KS",
    "city": "Plains"
  },
  {
    "value": "67870",
    "label": "Satanta - KS 67870",
    "county": "Haskell County",
    "state": "KS",
    "city": "Satanta"
  },
  {
    "value": "67871",
    "label": "Scott City - KS 67871",
    "county": "Scott County",
    "state": "KS",
    "city": "Scott City"
  },
  {
    "value": "67876",
    "label": "Spearville - KS 67876",
    "county": "Ford County",
    "state": "KS",
    "city": "Spearville"
  },
  {
    "value": "67877",
    "label": "Sublette - KS 67877",
    "county": "Haskell County",
    "state": "KS",
    "city": "Sublette"
  },
  {
    "value": "67878",
    "label": "Syracuse - KS 67878",
    "county": "Hamilton County",
    "state": "KS",
    "city": "Syracuse"
  },
  {
    "value": "67879",
    "label": "Tribune - KS 67879",
    "county": "Greeley County",
    "state": "KS",
    "city": "Tribune"
  },
  {
    "value": "67880",
    "label": "Ulysses - KS 67880",
    "county": "Grant County",
    "state": "KS",
    "city": "Ulysses"
  },
  {
    "value": "67882",
    "label": "Wright - KS 67882",
    "county": "Ford County",
    "state": "KS",
    "city": "Wright"
  },
  {
    "value": "67901",
    "label": "Liberal - KS 67901",
    "county": "Seward County",
    "state": "KS",
    "city": "Liberal"
  },
  {
    "value": "67950",
    "label": "Elkhart - KS 67950",
    "county": "Morton County",
    "state": "KS",
    "city": "Elkhart"
  },
  {
    "value": "67951",
    "label": "Hugoton - KS 67951",
    "county": "Stevens County",
    "state": "KS",
    "city": "Hugoton"
  },
  {
    "value": "67952",
    "label": "Moscow - KS 67952",
    "county": "Stevens County",
    "state": "KS",
    "city": "Moscow"
  },
  {
    "value": "67953",
    "label": "Richfield - KS 67953",
    "county": "Morton County",
    "state": "KS",
    "city": "Richfield"
  },
  {
    "value": "67954",
    "label": "Rolla - KS 67954",
    "county": "Morton County",
    "state": "KS",
    "city": "Rolla"
  },
  {
    "value": "40003",
    "label": "Bagdad - KY 40003",
    "county": "Shelby County",
    "state": "KY",
    "city": "Bagdad"
  },
  {
    "value": "40004",
    "label": "Bardstown - KY 40004",
    "county": "Nelson County",
    "state": "KY",
    "city": "Bardstown"
  },
  {
    "value": "40006",
    "label": "Bedford - KY 40006",
    "county": "Trimble County",
    "state": "KY",
    "city": "Bedford"
  },
  {
    "value": "40007",
    "label": "Bethlehem - KY 40007",
    "county": "Henry County",
    "state": "KY",
    "city": "Bethlehem"
  },
  {
    "value": "40008",
    "label": "Bloomfield - KY 40008",
    "county": "Nelson County",
    "state": "KY",
    "city": "Bloomfield"
  },
  {
    "value": "40009",
    "label": "Bradfordsville - KY 40009",
    "county": "Marion County",
    "state": "KY",
    "city": "Bradfordsville"
  },
  {
    "value": "40010",
    "label": "Buckner - KY 40010",
    "county": "Oldham County",
    "state": "KY",
    "city": "Buckner"
  },
  {
    "value": "40011",
    "label": "Campbellsburg - KY 40011",
    "county": "Henry County",
    "state": "KY",
    "city": "Campbellsburg"
  },
  {
    "value": "40012",
    "label": "Chaplin - KY 40012",
    "county": "Nelson County",
    "state": "KY",
    "city": "Chaplin"
  },
  {
    "value": "40013",
    "label": "Coxs Creek - KY 40013",
    "county": "Nelson County",
    "state": "KY",
    "city": "Coxs Creek"
  },
  {
    "value": "40014",
    "label": "Crestwood - KY 40014",
    "county": "Oldham County",
    "state": "KY",
    "city": "Crestwood"
  },
  {
    "value": "40019",
    "label": "Eminence - KY 40019",
    "county": "Henry County",
    "state": "KY",
    "city": "Eminence"
  },
  {
    "value": "40022",
    "label": "Finchville - KY 40022",
    "county": "Shelby County",
    "state": "KY",
    "city": "Finchville"
  },
  {
    "value": "40023",
    "label": "Fisherville - KY 40023",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Fisherville"
  },
  {
    "value": "40026",
    "label": "Goshen - KY 40026",
    "county": "Oldham County",
    "state": "KY",
    "city": "Goshen"
  },
  {
    "value": "40031",
    "label": "La Grange - KY 40031",
    "county": "Oldham County",
    "state": "KY",
    "city": "La Grange"
  },
  {
    "value": "40033",
    "label": "Lebanon - KY 40033",
    "county": "Marion County",
    "state": "KY",
    "city": "Lebanon"
  },
  {
    "value": "40036",
    "label": "Lockport - KY 40036",
    "county": "Henry County",
    "state": "KY",
    "city": "Lockport"
  },
  {
    "value": "40037",
    "label": "Loretto - KY 40037",
    "county": "Marion County",
    "state": "KY",
    "city": "Loretto"
  },
  {
    "value": "40040",
    "label": "Mackville - KY 40040",
    "county": "Washington County",
    "state": "KY",
    "city": "Mackville"
  },
  {
    "value": "40041",
    "label": "Masonic Home - KY 40041",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Masonic Home"
  },
  {
    "value": "40045",
    "label": "Milton - KY 40045",
    "county": "Trimble County",
    "state": "KY",
    "city": "Milton"
  },
  {
    "value": "40046",
    "label": "Mount Eden - KY 40046",
    "county": "Spencer County",
    "state": "KY",
    "city": "Mount Eden"
  },
  {
    "value": "40047",
    "label": "Mount Washington - KY 40047",
    "county": "Bullitt County",
    "state": "KY",
    "city": "Mount Washington"
  },
  {
    "value": "40050",
    "label": "New Castle - KY 40050",
    "county": "Henry County",
    "state": "KY",
    "city": "New Castle"
  },
  {
    "value": "40051",
    "label": "New Haven - KY 40051",
    "county": "Nelson County",
    "state": "KY",
    "city": "New Haven"
  },
  {
    "value": "40052",
    "label": "New Hope - KY 40052",
    "county": "Nelson County",
    "state": "KY",
    "city": "New Hope"
  },
  {
    "value": "40055",
    "label": "Pendleton - KY 40055",
    "county": "Henry County",
    "state": "KY",
    "city": "Pendleton"
  },
  {
    "value": "40056",
    "label": "Pewee Valley - KY 40056",
    "county": "Oldham County",
    "state": "KY",
    "city": "Pewee Valley"
  },
  {
    "value": "40057",
    "label": "Pleasureville - KY 40057",
    "county": "Henry County",
    "state": "KY",
    "city": "Pleasureville"
  },
  {
    "value": "40059",
    "label": "Prospect - KY 40059",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Prospect"
  },
  {
    "value": "40060",
    "label": "Raywick - KY 40060",
    "county": "Marion County",
    "state": "KY",
    "city": "Raywick"
  },
  {
    "value": "40062",
    "label": "Saint Francis - KY 40062",
    "county": "Marion County",
    "state": "KY",
    "city": "Saint Francis"
  },
  {
    "value": "40065",
    "label": "Shelbyville - KY 40065",
    "county": "Shelby County",
    "state": "KY",
    "city": "Shelbyville"
  },
  {
    "value": "40067",
    "label": "Simpsonville - KY 40067",
    "county": "Shelby County",
    "state": "KY",
    "city": "Simpsonville"
  },
  {
    "value": "40068",
    "label": "Smithfield - KY 40068",
    "county": "Henry County",
    "state": "KY",
    "city": "Smithfield"
  },
  {
    "value": "40069",
    "label": "Springfield - KY 40069",
    "county": "Washington County",
    "state": "KY",
    "city": "Springfield"
  },
  {
    "value": "40070",
    "label": "Sulphur - KY 40070",
    "county": "Henry County",
    "state": "KY",
    "city": "Sulphur"
  },
  {
    "value": "40071",
    "label": "Taylorsville - KY 40071",
    "county": "Spencer County",
    "state": "KY",
    "city": "Taylorsville"
  },
  {
    "value": "40075",
    "label": "Turners Station - KY 40075",
    "county": "Henry County",
    "state": "KY",
    "city": "Turners Station"
  },
  {
    "value": "40076",
    "label": "Waddy - KY 40076",
    "county": "Shelby County",
    "state": "KY",
    "city": "Waddy"
  },
  {
    "value": "40077",
    "label": "Westport - KY 40077",
    "county": "Oldham County",
    "state": "KY",
    "city": "Westport"
  },
  {
    "value": "40078",
    "label": "Willisburg - KY 40078",
    "county": "Washington County",
    "state": "KY",
    "city": "Willisburg"
  },
  {
    "value": "40104",
    "label": "Battletown - KY 40104",
    "county": "Meade County",
    "state": "KY",
    "city": "Battletown"
  },
  {
    "value": "40107",
    "label": "Boston - KY 40107",
    "county": "Nelson County",
    "state": "KY",
    "city": "Boston"
  },
  {
    "value": "40108",
    "label": "Brandenburg - KY 40108",
    "county": "Meade County",
    "state": "KY",
    "city": "Brandenburg"
  },
  {
    "value": "40109",
    "label": "Brooks - KY 40109",
    "county": "Bullitt County",
    "state": "KY",
    "city": "Brooks"
  },
  {
    "value": "40111",
    "label": "Cloverport - KY 40111",
    "county": "Breckinridge County",
    "state": "KY",
    "city": "Cloverport"
  },
  {
    "value": "40115",
    "label": "Custer - KY 40115",
    "county": "Breckinridge County",
    "state": "KY",
    "city": "Custer"
  },
  {
    "value": "40117",
    "label": "Ekron - KY 40117",
    "county": "Meade County",
    "state": "KY",
    "city": "Ekron"
  },
  {
    "value": "40118",
    "label": "Fairdale - KY 40118",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Fairdale"
  },
  {
    "value": "40119",
    "label": "Falls Of Rough - KY 40119",
    "county": "Grayson County",
    "state": "KY",
    "city": "Falls Of Rough"
  },
  {
    "value": "40121",
    "label": "Fort Knox - KY 40121",
    "county": "Hardin County",
    "state": "KY",
    "city": "Fort Knox"
  },
  {
    "value": "40122",
    "label": "Fort Knox - KY 40122",
    "county": "Hardin County",
    "state": "KY",
    "city": "Fort Knox"
  },
  {
    "value": "40140",
    "label": "Garfield - KY 40140",
    "county": "Breckinridge County",
    "state": "KY",
    "city": "Garfield"
  },
  {
    "value": "40142",
    "label": "Guston - KY 40142",
    "county": "Meade County",
    "state": "KY",
    "city": "Guston"
  },
  {
    "value": "40143",
    "label": "Hardinsburg - KY 40143",
    "county": "Breckinridge County",
    "state": "KY",
    "city": "Hardinsburg"
  },
  {
    "value": "40144",
    "label": "Harned - KY 40144",
    "county": "Breckinridge County",
    "state": "KY",
    "city": "Harned"
  },
  {
    "value": "40145",
    "label": "Hudson - KY 40145",
    "county": "Breckinridge County",
    "state": "KY",
    "city": "Hudson"
  },
  {
    "value": "40146",
    "label": "Irvington - KY 40146",
    "county": "Breckinridge County",
    "state": "KY",
    "city": "Irvington"
  },
  {
    "value": "40150",
    "label": "Lebanon Junction - KY 40150",
    "county": "Bullitt County",
    "state": "KY",
    "city": "Lebanon Junction"
  },
  {
    "value": "40152",
    "label": "Mc Daniels - KY 40152",
    "county": "Breckinridge County",
    "state": "KY",
    "city": "Mc Daniels"
  },
  {
    "value": "40155",
    "label": "Muldraugh - KY 40155",
    "county": "Meade County",
    "state": "KY",
    "city": "Muldraugh"
  },
  {
    "value": "40157",
    "label": "Payneville - KY 40157",
    "county": "Meade County",
    "state": "KY",
    "city": "Payneville"
  },
  {
    "value": "40160",
    "label": "Radcliff - KY 40160",
    "county": "Hardin County",
    "state": "KY",
    "city": "Radcliff"
  },
  {
    "value": "40161",
    "label": "Rhodelia - KY 40161",
    "county": "Meade County",
    "state": "KY",
    "city": "Rhodelia"
  },
  {
    "value": "40162",
    "label": "Rineyville - KY 40162",
    "county": "Hardin County",
    "state": "KY",
    "city": "Rineyville"
  },
  {
    "value": "40165",
    "label": "Shepherdsville - KY 40165",
    "county": "Bullitt County",
    "state": "KY",
    "city": "Shepherdsville"
  },
  {
    "value": "40170",
    "label": "Stephensport - KY 40170",
    "county": "Breckinridge County",
    "state": "KY",
    "city": "Stephensport"
  },
  {
    "value": "40171",
    "label": "Union Star - KY 40171",
    "county": "Breckinridge County",
    "state": "KY",
    "city": "Union Star"
  },
  {
    "value": "40175",
    "label": "Vine Grove - KY 40175",
    "county": "Hardin County",
    "state": "KY",
    "city": "Vine Grove"
  },
  {
    "value": "40176",
    "label": "Webster - KY 40176",
    "county": "Breckinridge County",
    "state": "KY",
    "city": "Webster"
  },
  {
    "value": "40177",
    "label": "West Point - KY 40177",
    "county": "Hardin County",
    "state": "KY",
    "city": "West Point"
  },
  {
    "value": "40178",
    "label": "Westview - KY 40178",
    "county": "Breckinridge County",
    "state": "KY",
    "city": "Westview"
  },
  {
    "value": "40202",
    "label": "Louisville - KY 40202",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40203",
    "label": "Louisville - KY 40203",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40204",
    "label": "Louisville - KY 40204",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40205",
    "label": "Louisville - KY 40205",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40206",
    "label": "Louisville - KY 40206",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40207",
    "label": "Louisville - KY 40207",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40208",
    "label": "Louisville - KY 40208",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40209",
    "label": "Louisville - KY 40209",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40210",
    "label": "Louisville - KY 40210",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40211",
    "label": "Louisville - KY 40211",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40212",
    "label": "Louisville - KY 40212",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40213",
    "label": "Louisville - KY 40213",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40214",
    "label": "Louisville - KY 40214",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40215",
    "label": "Louisville - KY 40215",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40216",
    "label": "Louisville - KY 40216",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40217",
    "label": "Louisville - KY 40217",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40218",
    "label": "Louisville - KY 40218",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40219",
    "label": "Louisville - KY 40219",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40220",
    "label": "Louisville - KY 40220",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40222",
    "label": "Louisville - KY 40222",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40223",
    "label": "Louisville - KY 40223",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40228",
    "label": "Louisville - KY 40228",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40229",
    "label": "Louisville - KY 40229",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40241",
    "label": "Louisville - KY 40241",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40242",
    "label": "Louisville - KY 40242",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40243",
    "label": "Louisville - KY 40243",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40245",
    "label": "Louisville - KY 40245",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40258",
    "label": "Louisville - KY 40258",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40272",
    "label": "Louisville - KY 40272",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40291",
    "label": "Louisville - KY 40291",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40293",
    "label": "Louisville - KY 40293",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40299",
    "label": "Louisville - KY 40299",
    "county": "Jefferson County",
    "state": "KY",
    "city": "Louisville"
  },
  {
    "value": "40311",
    "label": "Carlisle - KY 40311",
    "county": "Nicholas County",
    "state": "KY",
    "city": "Carlisle"
  },
  {
    "value": "40312",
    "label": "Clay City - KY 40312",
    "county": "Powell County",
    "state": "KY",
    "city": "Clay City"
  },
  {
    "value": "40313",
    "label": "Clearfield - KY 40313",
    "county": "Rowan County",
    "state": "KY",
    "city": "Clearfield"
  },
  {
    "value": "40316",
    "label": "Denniston - KY 40316",
    "county": "Menifee County",
    "state": "KY",
    "city": "Denniston"
  },
  {
    "value": "40322",
    "label": "Frenchburg - KY 40322",
    "county": "Menifee County",
    "state": "KY",
    "city": "Frenchburg"
  },
  {
    "value": "40324",
    "label": "Georgetown - KY 40324",
    "county": "Scott County",
    "state": "KY",
    "city": "Georgetown"
  },
  {
    "value": "40328",
    "label": "Gravel Switch - KY 40328",
    "county": "Marion County",
    "state": "KY",
    "city": "Gravel Switch"
  },
  {
    "value": "40330",
    "label": "Harrodsburg - KY 40330",
    "county": "Mercer County",
    "state": "KY",
    "city": "Harrodsburg"
  },
  {
    "value": "40334",
    "label": "Hope - KY 40334",
    "county": "Montgomery County",
    "state": "KY",
    "city": "Hope"
  },
  {
    "value": "40336",
    "label": "Irvine - KY 40336",
    "county": "Estill County",
    "state": "KY",
    "city": "Irvine"
  },
  {
    "value": "40337",
    "label": "Jeffersonville - KY 40337",
    "county": "Montgomery County",
    "state": "KY",
    "city": "Jeffersonville"
  },
  {
    "value": "40342",
    "label": "Lawrenceburg - KY 40342",
    "county": "Anderson County",
    "state": "KY",
    "city": "Lawrenceburg"
  },
  {
    "value": "40346",
    "label": "Means - KY 40346",
    "county": "Menifee County",
    "state": "KY",
    "city": "Means"
  },
  {
    "value": "40347",
    "label": "Midway - KY 40347",
    "county": "Woodford County",
    "state": "KY",
    "city": "Midway"
  },
  {
    "value": "40350",
    "label": "Moorefield - KY 40350",
    "county": "Nicholas County",
    "state": "KY",
    "city": "Moorefield"
  },
  {
    "value": "40351",
    "label": "Morehead - KY 40351",
    "county": "Rowan County",
    "state": "KY",
    "city": "Morehead"
  },
  {
    "value": "40353",
    "label": "Mount Sterling - KY 40353",
    "county": "Montgomery County",
    "state": "KY",
    "city": "Mount Sterling"
  },
  {
    "value": "40355",
    "label": "New Liberty - KY 40355",
    "county": "Owen County",
    "state": "KY",
    "city": "New Liberty"
  },
  {
    "value": "40356",
    "label": "Nicholasville - KY 40356",
    "county": "Jessamine County",
    "state": "KY",
    "city": "Nicholasville"
  },
  {
    "value": "40358",
    "label": "Olympia - KY 40358",
    "county": "Bath County",
    "state": "KY",
    "city": "Olympia"
  },
  {
    "value": "40359",
    "label": "Owenton - KY 40359",
    "county": "Owen County",
    "state": "KY",
    "city": "Owenton"
  },
  {
    "value": "40360",
    "label": "Owingsville - KY 40360",
    "county": "Bath County",
    "state": "KY",
    "city": "Owingsville"
  },
  {
    "value": "40361",
    "label": "Paris - KY 40361",
    "county": "Bourbon County",
    "state": "KY",
    "city": "Paris"
  },
  {
    "value": "40370",
    "label": "Sadieville - KY 40370",
    "county": "Scott County",
    "state": "KY",
    "city": "Sadieville"
  },
  {
    "value": "40371",
    "label": "Salt Lick - KY 40371",
    "county": "Bath County",
    "state": "KY",
    "city": "Salt Lick"
  },
  {
    "value": "40372",
    "label": "Salvisa - KY 40372",
    "county": "Mercer County",
    "state": "KY",
    "city": "Salvisa"
  },
  {
    "value": "40374",
    "label": "Sharpsburg - KY 40374",
    "county": "Bath County",
    "state": "KY",
    "city": "Sharpsburg"
  },
  {
    "value": "40376",
    "label": "Slade - KY 40376",
    "county": "Powell County",
    "state": "KY",
    "city": "Slade"
  },
  {
    "value": "40379",
    "label": "Stamping Ground - KY 40379",
    "county": "Scott County",
    "state": "KY",
    "city": "Stamping Ground"
  },
  {
    "value": "40380",
    "label": "Stanton - KY 40380",
    "county": "Powell County",
    "state": "KY",
    "city": "Stanton"
  },
  {
    "value": "40383",
    "label": "Versailles - KY 40383",
    "county": "Woodford County",
    "state": "KY",
    "city": "Versailles"
  },
  {
    "value": "40385",
    "label": "Waco - KY 40385",
    "county": "Madison County",
    "state": "KY",
    "city": "Waco"
  },
  {
    "value": "40387",
    "label": "Wellington - KY 40387",
    "county": "Menifee County",
    "state": "KY",
    "city": "Wellington"
  },
  {
    "value": "40390",
    "label": "Wilmore - KY 40390",
    "county": "Jessamine County",
    "state": "KY",
    "city": "Wilmore"
  },
  {
    "value": "40391",
    "label": "Winchester - KY 40391",
    "county": "Clark County",
    "state": "KY",
    "city": "Winchester"
  },
  {
    "value": "40402",
    "label": "Annville - KY 40402",
    "county": "Jackson County",
    "state": "KY",
    "city": "Annville"
  },
  {
    "value": "40403",
    "label": "Berea - KY 40403",
    "county": "Madison County",
    "state": "KY",
    "city": "Berea"
  },
  {
    "value": "40409",
    "label": "Brodhead - KY 40409",
    "county": "Rockcastle County",
    "state": "KY",
    "city": "Brodhead"
  },
  {
    "value": "40419",
    "label": "Crab Orchard - KY 40419",
    "county": "Lincoln County",
    "state": "KY",
    "city": "Crab Orchard"
  },
  {
    "value": "40422",
    "label": "Danville - KY 40422",
    "county": "Boyle County",
    "state": "KY",
    "city": "Danville"
  },
  {
    "value": "40437",
    "label": "Hustonville - KY 40437",
    "county": "Lincoln County",
    "state": "KY",
    "city": "Hustonville"
  },
  {
    "value": "40440",
    "label": "Junction City - KY 40440",
    "county": "Boyle County",
    "state": "KY",
    "city": "Junction City"
  },
  {
    "value": "40442",
    "label": "Kings Mountain - KY 40442",
    "county": "Lincoln County",
    "state": "KY",
    "city": "Kings Mountain"
  },
  {
    "value": "40444",
    "label": "Lancaster - KY 40444",
    "county": "Garrard County",
    "state": "KY",
    "city": "Lancaster"
  },
  {
    "value": "40445",
    "label": "Livingston - KY 40445",
    "county": "Rockcastle County",
    "state": "KY",
    "city": "Livingston"
  },
  {
    "value": "40447",
    "label": "Mc Kee - KY 40447",
    "county": "Jackson County",
    "state": "KY",
    "city": "Mc Kee"
  },
  {
    "value": "40456",
    "label": "Mount Vernon - KY 40456",
    "county": "Rockcastle County",
    "state": "KY",
    "city": "Mount Vernon"
  },
  {
    "value": "40460",
    "label": "Orlando - KY 40460",
    "county": "Rockcastle County",
    "state": "KY",
    "city": "Orlando"
  },
  {
    "value": "40461",
    "label": "Paint Lick - KY 40461",
    "county": "Madison County",
    "state": "KY",
    "city": "Paint Lick"
  },
  {
    "value": "40464",
    "label": "Parksville - KY 40464",
    "county": "Boyle County",
    "state": "KY",
    "city": "Parksville"
  },
  {
    "value": "40468",
    "label": "Perryville - KY 40468",
    "county": "Boyle County",
    "state": "KY",
    "city": "Perryville"
  },
  {
    "value": "40472",
    "label": "Ravenna - KY 40472",
    "county": "Estill County",
    "state": "KY",
    "city": "Ravenna"
  },
  {
    "value": "40475",
    "label": "Richmond - KY 40475",
    "county": "Madison County",
    "state": "KY",
    "city": "Richmond"
  },
  {
    "value": "40481",
    "label": "Sandgap - KY 40481",
    "county": "Jackson County",
    "state": "KY",
    "city": "Sandgap"
  },
  {
    "value": "40484",
    "label": "Stanford - KY 40484",
    "county": "Lincoln County",
    "state": "KY",
    "city": "Stanford"
  },
  {
    "value": "40486",
    "label": "Tyner - KY 40486",
    "county": "Jackson County",
    "state": "KY",
    "city": "Tyner"
  },
  {
    "value": "40489",
    "label": "Waynesburg - KY 40489",
    "county": "Lincoln County",
    "state": "KY",
    "city": "Waynesburg"
  },
  {
    "value": "40502",
    "label": "Lexington - KY 40502",
    "county": "Fayette County",
    "state": "KY",
    "city": "Lexington"
  },
  {
    "value": "40503",
    "label": "Lexington - KY 40503",
    "county": "Fayette County",
    "state": "KY",
    "city": "Lexington"
  },
  {
    "value": "40504",
    "label": "Lexington - KY 40504",
    "county": "Fayette County",
    "state": "KY",
    "city": "Lexington"
  },
  {
    "value": "40505",
    "label": "Lexington - KY 40505",
    "county": "Fayette County",
    "state": "KY",
    "city": "Lexington"
  },
  {
    "value": "40507",
    "label": "Lexington - KY 40507",
    "county": "Fayette County",
    "state": "KY",
    "city": "Lexington"
  },
  {
    "value": "40508",
    "label": "Lexington - KY 40508",
    "county": "Fayette County",
    "state": "KY",
    "city": "Lexington"
  },
  {
    "value": "40509",
    "label": "Lexington - KY 40509",
    "county": "Fayette County",
    "state": "KY",
    "city": "Lexington"
  },
  {
    "value": "40510",
    "label": "Lexington - KY 40510",
    "county": "Fayette County",
    "state": "KY",
    "city": "Lexington"
  },
  {
    "value": "40511",
    "label": "Lexington - KY 40511",
    "county": "Fayette County",
    "state": "KY",
    "city": "Lexington"
  },
  {
    "value": "40513",
    "label": "Lexington - KY 40513",
    "county": "Fayette County",
    "state": "KY",
    "city": "Lexington"
  },
  {
    "value": "40514",
    "label": "Lexington - KY 40514",
    "county": "Fayette County",
    "state": "KY",
    "city": "Lexington"
  },
  {
    "value": "40515",
    "label": "Lexington - KY 40515",
    "county": "Fayette County",
    "state": "KY",
    "city": "Lexington"
  },
  {
    "value": "40516",
    "label": "Lexington - KY 40516",
    "county": "Fayette County",
    "state": "KY",
    "city": "Lexington"
  },
  {
    "value": "40517",
    "label": "Lexington - KY 40517",
    "county": "Fayette County",
    "state": "KY",
    "city": "Lexington"
  },
  {
    "value": "40601",
    "label": "Frankfort - KY 40601",
    "county": "Franklin County",
    "state": "KY",
    "city": "Frankfort"
  },
  {
    "value": "40701",
    "label": "Corbin - KY 40701",
    "county": "Whitley County",
    "state": "KY",
    "city": "Corbin"
  },
  {
    "value": "40729",
    "label": "East Bernstadt - KY 40729",
    "county": "Laurel County",
    "state": "KY",
    "city": "East Bernstadt"
  },
  {
    "value": "40734",
    "label": "Gray - KY 40734",
    "county": "Knox County",
    "state": "KY",
    "city": "Gray"
  },
  {
    "value": "40737",
    "label": "Keavy - KY 40737",
    "county": "Laurel County",
    "state": "KY",
    "city": "Keavy"
  },
  {
    "value": "40740",
    "label": "Lily - KY 40740",
    "county": "Laurel County",
    "state": "KY",
    "city": "Lily"
  },
  {
    "value": "40741",
    "label": "London - KY 40741",
    "county": "Laurel County",
    "state": "KY",
    "city": "London"
  },
  {
    "value": "40744",
    "label": "London - KY 40744",
    "county": "Laurel County",
    "state": "KY",
    "city": "London"
  },
  {
    "value": "40754",
    "label": "Nevisdale - KY 40754",
    "county": "Whitley County",
    "state": "KY",
    "city": "Nevisdale"
  },
  {
    "value": "40759",
    "label": "Rockholds - KY 40759",
    "county": "Whitley County",
    "state": "KY",
    "city": "Rockholds"
  },
  {
    "value": "40763",
    "label": "Siler - KY 40763",
    "county": "Whitley County",
    "state": "KY",
    "city": "Siler"
  },
  {
    "value": "40769",
    "label": "Williamsburg - KY 40769",
    "county": "Whitley County",
    "state": "KY",
    "city": "Williamsburg"
  },
  {
    "value": "40771",
    "label": "Woodbine - KY 40771",
    "county": "Knox County",
    "state": "KY",
    "city": "Woodbine"
  },
  {
    "value": "40806",
    "label": "Baxter - KY 40806",
    "county": "Harlan County",
    "state": "KY",
    "city": "Baxter"
  },
  {
    "value": "40808",
    "label": "Big Laurel - KY 40808",
    "county": "Harlan County",
    "state": "KY",
    "city": "Big Laurel"
  },
  {
    "value": "40810",
    "label": "Bledsoe - KY 40810",
    "county": "Harlan County",
    "state": "KY",
    "city": "Bledsoe"
  },
  {
    "value": "40813",
    "label": "Calvin - KY 40813",
    "county": "Bell County",
    "state": "KY",
    "city": "Calvin"
  },
  {
    "value": "40815",
    "label": "Cawood - KY 40815",
    "county": "Harlan County",
    "state": "KY",
    "city": "Cawood"
  },
  {
    "value": "40816",
    "label": "Chappell - KY 40816",
    "county": "Leslie County",
    "state": "KY",
    "city": "Chappell"
  },
  {
    "value": "40818",
    "label": "Coalgood - KY 40818",
    "county": "Harlan County",
    "state": "KY",
    "city": "Coalgood"
  },
  {
    "value": "40819",
    "label": "Coldiron - KY 40819",
    "county": "Harlan County",
    "state": "KY",
    "city": "Coldiron"
  },
  {
    "value": "40820",
    "label": "Cranks - KY 40820",
    "county": "Harlan County",
    "state": "KY",
    "city": "Cranks"
  },
  {
    "value": "40823",
    "label": "Cumberland - KY 40823",
    "county": "Harlan County",
    "state": "KY",
    "city": "Cumberland"
  },
  {
    "value": "40826",
    "label": "Eolia - KY 40826",
    "county": "Letcher County",
    "state": "KY",
    "city": "Eolia"
  },
  {
    "value": "40827",
    "label": "Essie - KY 40827",
    "county": "Leslie County",
    "state": "KY",
    "city": "Essie"
  },
  {
    "value": "40828",
    "label": "Evarts - KY 40828",
    "county": "Harlan County",
    "state": "KY",
    "city": "Evarts"
  },
  {
    "value": "40830",
    "label": "Gulston - KY 40830",
    "county": "Harlan County",
    "state": "KY",
    "city": "Gulston"
  },
  {
    "value": "40831",
    "label": "Harlan - KY 40831",
    "county": "Harlan County",
    "state": "KY",
    "city": "Harlan"
  },
  {
    "value": "40840",
    "label": "Helton - KY 40840",
    "county": "Leslie County",
    "state": "KY",
    "city": "Helton"
  },
  {
    "value": "40843",
    "label": "Holmes Mill - KY 40843",
    "county": "Harlan County",
    "state": "KY",
    "city": "Holmes Mill"
  },
  {
    "value": "40845",
    "label": "Hulen - KY 40845",
    "county": "Bell County",
    "state": "KY",
    "city": "Hulen"
  },
  {
    "value": "40856",
    "label": "Miracle - KY 40856",
    "county": "Bell County",
    "state": "KY",
    "city": "Miracle"
  },
  {
    "value": "40858",
    "label": "Mozelle - KY 40858",
    "county": "Leslie County",
    "state": "KY",
    "city": "Mozelle"
  },
  {
    "value": "40862",
    "label": "Partridge - KY 40862",
    "county": "Letcher County",
    "state": "KY",
    "city": "Partridge"
  },
  {
    "value": "40863",
    "label": "Pathfork - KY 40863",
    "county": "Harlan County",
    "state": "KY",
    "city": "Pathfork"
  },
  {
    "value": "40865",
    "label": "Putney - KY 40865",
    "county": "Harlan County",
    "state": "KY",
    "city": "Putney"
  },
  {
    "value": "40868",
    "label": "Stinnett - KY 40868",
    "county": "Leslie County",
    "state": "KY",
    "city": "Stinnett"
  },
  {
    "value": "40870",
    "label": "Totz - KY 40870",
    "county": "Harlan County",
    "state": "KY",
    "city": "Totz"
  },
  {
    "value": "40873",
    "label": "Wallins Creek - KY 40873",
    "county": "Harlan County",
    "state": "KY",
    "city": "Wallins Creek"
  },
  {
    "value": "40874",
    "label": "Warbranch - KY 40874",
    "county": "Leslie County",
    "state": "KY",
    "city": "Warbranch"
  },
  {
    "value": "40902",
    "label": "Arjay - KY 40902",
    "county": "Bell County",
    "state": "KY",
    "city": "Arjay"
  },
  {
    "value": "40903",
    "label": "Artemus - KY 40903",
    "county": "Knox County",
    "state": "KY",
    "city": "Artemus"
  },
  {
    "value": "40906",
    "label": "Barbourville - KY 40906",
    "county": "Knox County",
    "state": "KY",
    "city": "Barbourville"
  },
  {
    "value": "40913",
    "label": "Beverly - KY 40913",
    "county": "Clay County",
    "state": "KY",
    "city": "Beverly"
  },
  {
    "value": "40914",
    "label": "Big Creek - KY 40914",
    "county": "Clay County",
    "state": "KY",
    "city": "Big Creek"
  },
  {
    "value": "40915",
    "label": "Bimble - KY 40915",
    "county": "Knox County",
    "state": "KY",
    "city": "Bimble"
  },
  {
    "value": "40921",
    "label": "Bryants Store - KY 40921",
    "county": "Knox County",
    "state": "KY",
    "city": "Bryants Store"
  },
  {
    "value": "40923",
    "label": "Cannon - KY 40923",
    "county": "Knox County",
    "state": "KY",
    "city": "Cannon"
  },
  {
    "value": "40927",
    "label": "Closplint - KY 40927",
    "county": "Harlan County",
    "state": "KY",
    "city": "Closplint"
  },
  {
    "value": "40935",
    "label": "Flat Lick - KY 40935",
    "county": "Knox County",
    "state": "KY",
    "city": "Flat Lick"
  },
  {
    "value": "40939",
    "label": "Fourmile - KY 40939",
    "county": "Bell County",
    "state": "KY",
    "city": "Fourmile"
  },
  {
    "value": "40940",
    "label": "Frakes - KY 40940",
    "county": "Whitley County",
    "state": "KY",
    "city": "Frakes"
  },
  {
    "value": "40943",
    "label": "Girdler - KY 40943",
    "county": "Knox County",
    "state": "KY",
    "city": "Girdler"
  },
  {
    "value": "40946",
    "label": "Green Road - KY 40946",
    "county": "Knox County",
    "state": "KY",
    "city": "Green Road"
  },
  {
    "value": "40949",
    "label": "Heidrick - KY 40949",
    "county": "Knox County",
    "state": "KY",
    "city": "Heidrick"
  },
  {
    "value": "40953",
    "label": "Hinkle - KY 40953",
    "county": "Knox County",
    "state": "KY",
    "city": "Hinkle"
  },
  {
    "value": "40958",
    "label": "Kettle Island - KY 40958",
    "county": "Bell County",
    "state": "KY",
    "city": "Kettle Island"
  },
  {
    "value": "40962",
    "label": "Manchester - KY 40962",
    "county": "Clay County",
    "state": "KY",
    "city": "Manchester"
  },
  {
    "value": "40964",
    "label": "Mary Alice - KY 40964",
    "county": "Harlan County",
    "state": "KY",
    "city": "Mary Alice"
  },
  {
    "value": "40965",
    "label": "Middlesboro - KY 40965",
    "county": "Bell County",
    "state": "KY",
    "city": "Middlesboro"
  },
  {
    "value": "40972",
    "label": "Oneida - KY 40972",
    "county": "Clay County",
    "state": "KY",
    "city": "Oneida"
  },
  {
    "value": "40977",
    "label": "Pineville - KY 40977",
    "county": "Bell County",
    "state": "KY",
    "city": "Pineville"
  },
  {
    "value": "40979",
    "label": "Roark - KY 40979",
    "county": "Leslie County",
    "state": "KY",
    "city": "Roark"
  },
  {
    "value": "40982",
    "label": "Scalf - KY 40982",
    "county": "Knox County",
    "state": "KY",
    "city": "Scalf"
  },
  {
    "value": "40983",
    "label": "Sextons Creek - KY 40983",
    "county": "Clay County",
    "state": "KY",
    "city": "Sextons Creek"
  },
  {
    "value": "40988",
    "label": "Stoney Fork - KY 40988",
    "county": "Bell County",
    "state": "KY",
    "city": "Stoney Fork"
  },
  {
    "value": "40995",
    "label": "Trosper - KY 40995",
    "county": "Knox County",
    "state": "KY",
    "city": "Trosper"
  },
  {
    "value": "40997",
    "label": "Walker - KY 40997",
    "county": "Knox County",
    "state": "KY",
    "city": "Walker"
  },
  {
    "value": "40999",
    "label": "Woollum - KY 40999",
    "county": "Knox County",
    "state": "KY",
    "city": "Woollum"
  },
  {
    "value": "41001",
    "label": "Alexandria - KY 41001",
    "county": "Campbell County",
    "state": "KY",
    "city": "Alexandria"
  },
  {
    "value": "41002",
    "label": "Augusta - KY 41002",
    "county": "Bracken County",
    "state": "KY",
    "city": "Augusta"
  },
  {
    "value": "41003",
    "label": "Berry - KY 41003",
    "county": "Harrison County",
    "state": "KY",
    "city": "Berry"
  },
  {
    "value": "41004",
    "label": "Brooksville - KY 41004",
    "county": "Bracken County",
    "state": "KY",
    "city": "Brooksville"
  },
  {
    "value": "41005",
    "label": "Burlington - KY 41005",
    "county": "Boone County",
    "state": "KY",
    "city": "Burlington"
  },
  {
    "value": "41006",
    "label": "Butler - KY 41006",
    "county": "Pendleton County",
    "state": "KY",
    "city": "Butler"
  },
  {
    "value": "41007",
    "label": "California - KY 41007",
    "county": "Campbell County",
    "state": "KY",
    "city": "California"
  },
  {
    "value": "41008",
    "label": "Carrollton - KY 41008",
    "county": "Carroll County",
    "state": "KY",
    "city": "Carrollton"
  },
  {
    "value": "41010",
    "label": "Corinth - KY 41010",
    "county": "Grant County",
    "state": "KY",
    "city": "Corinth"
  },
  {
    "value": "41011",
    "label": "Covington - KY 41011",
    "county": "Kenton County",
    "state": "KY",
    "city": "Covington"
  },
  {
    "value": "41014",
    "label": "Covington - KY 41014",
    "county": "Kenton County",
    "state": "KY",
    "city": "Covington"
  },
  {
    "value": "41015",
    "label": "Latonia - KY 41015",
    "county": "Kenton County",
    "state": "KY",
    "city": "Latonia"
  },
  {
    "value": "41016",
    "label": "Covington - KY 41016",
    "county": "Kenton County",
    "state": "KY",
    "city": "Covington"
  },
  {
    "value": "41017",
    "label": "Ft Mitchell - KY 41017",
    "county": "Kenton County",
    "state": "KY",
    "city": "Ft Mitchell"
  },
  {
    "value": "41018",
    "label": "Erlanger - KY 41018",
    "county": "Kenton County",
    "state": "KY",
    "city": "Erlanger"
  },
  {
    "value": "41030",
    "label": "Crittenden - KY 41030",
    "county": "Grant County",
    "state": "KY",
    "city": "Crittenden"
  },
  {
    "value": "41031",
    "label": "Cynthiana - KY 41031",
    "county": "Harrison County",
    "state": "KY",
    "city": "Cynthiana"
  },
  {
    "value": "41033",
    "label": "De Mossville - KY 41033",
    "county": "Pendleton County",
    "state": "KY",
    "city": "De Mossville"
  },
  {
    "value": "41034",
    "label": "Dover - KY 41034",
    "county": "Mason County",
    "state": "KY",
    "city": "Dover"
  },
  {
    "value": "41035",
    "label": "Dry Ridge - KY 41035",
    "county": "Grant County",
    "state": "KY",
    "city": "Dry Ridge"
  },
  {
    "value": "41039",
    "label": "Ewing - KY 41039",
    "county": "Fleming County",
    "state": "KY",
    "city": "Ewing"
  },
  {
    "value": "41040",
    "label": "Falmouth - KY 41040",
    "county": "Pendleton County",
    "state": "KY",
    "city": "Falmouth"
  },
  {
    "value": "41041",
    "label": "Flemingsburg - KY 41041",
    "county": "Fleming County",
    "state": "KY",
    "city": "Flemingsburg"
  },
  {
    "value": "41042",
    "label": "Florence - KY 41042",
    "county": "Boone County",
    "state": "KY",
    "city": "Florence"
  },
  {
    "value": "41043",
    "label": "Foster - KY 41043",
    "county": "Bracken County",
    "state": "KY",
    "city": "Foster"
  },
  {
    "value": "41044",
    "label": "Germantown - KY 41044",
    "county": "Bracken County",
    "state": "KY",
    "city": "Germantown"
  },
  {
    "value": "41045",
    "label": "Ghent - KY 41045",
    "county": "Carroll County",
    "state": "KY",
    "city": "Ghent"
  },
  {
    "value": "41046",
    "label": "Glencoe - KY 41046",
    "county": "Gallatin County",
    "state": "KY",
    "city": "Glencoe"
  },
  {
    "value": "41048",
    "label": "Hebron - KY 41048",
    "county": "Boone County",
    "state": "KY",
    "city": "Hebron"
  },
  {
    "value": "41049",
    "label": "Hillsboro - KY 41049",
    "county": "Fleming County",
    "state": "KY",
    "city": "Hillsboro"
  },
  {
    "value": "41051",
    "label": "Independence - KY 41051",
    "county": "Kenton County",
    "state": "KY",
    "city": "Independence"
  },
  {
    "value": "41052",
    "label": "Jonesville - KY 41052",
    "county": "Grant County",
    "state": "KY",
    "city": "Jonesville"
  },
  {
    "value": "41055",
    "label": "Mayslick - KY 41055",
    "county": "Mason County",
    "state": "KY",
    "city": "Mayslick"
  },
  {
    "value": "41056",
    "label": "Maysville - KY 41056",
    "county": "Mason County",
    "state": "KY",
    "city": "Maysville"
  },
  {
    "value": "41059",
    "label": "Melbourne - KY 41059",
    "county": "Campbell County",
    "state": "KY",
    "city": "Melbourne"
  },
  {
    "value": "41063",
    "label": "Morning View - KY 41063",
    "county": "Kenton County",
    "state": "KY",
    "city": "Morning View"
  },
  {
    "value": "41064",
    "label": "Mount Olivet - KY 41064",
    "county": "Robertson County",
    "state": "KY",
    "city": "Mount Olivet"
  },
  {
    "value": "41071",
    "label": "Newport - KY 41071",
    "county": "Campbell County",
    "state": "KY",
    "city": "Newport"
  },
  {
    "value": "41073",
    "label": "Bellevue - KY 41073",
    "county": "Campbell County",
    "state": "KY",
    "city": "Bellevue"
  },
  {
    "value": "41074",
    "label": "Dayton - KY 41074",
    "county": "Campbell County",
    "state": "KY",
    "city": "Dayton"
  },
  {
    "value": "41075",
    "label": "Fort Thomas - KY 41075",
    "county": "Campbell County",
    "state": "KY",
    "city": "Fort Thomas"
  },
  {
    "value": "41076",
    "label": "Newport - KY 41076",
    "county": "Campbell County",
    "state": "KY",
    "city": "Newport"
  },
  {
    "value": "41080",
    "label": "Petersburg - KY 41080",
    "county": "Boone County",
    "state": "KY",
    "city": "Petersburg"
  },
  {
    "value": "41083",
    "label": "Sanders - KY 41083",
    "county": "Carroll County",
    "state": "KY",
    "city": "Sanders"
  },
  {
    "value": "41086",
    "label": "Sparta - KY 41086",
    "county": "Gallatin County",
    "state": "KY",
    "city": "Sparta"
  },
  {
    "value": "41091",
    "label": "Union - KY 41091",
    "county": "Boone County",
    "state": "KY",
    "city": "Union"
  },
  {
    "value": "41092",
    "label": "Verona - KY 41092",
    "county": "Boone County",
    "state": "KY",
    "city": "Verona"
  },
  {
    "value": "41093",
    "label": "Wallingford - KY 41093",
    "county": "Fleming County",
    "state": "KY",
    "city": "Wallingford"
  },
  {
    "value": "41094",
    "label": "Walton - KY 41094",
    "county": "Boone County",
    "state": "KY",
    "city": "Walton"
  },
  {
    "value": "41095",
    "label": "Warsaw - KY 41095",
    "county": "Gallatin County",
    "state": "KY",
    "city": "Warsaw"
  },
  {
    "value": "41097",
    "label": "Williamstown - KY 41097",
    "county": "Grant County",
    "state": "KY",
    "city": "Williamstown"
  },
  {
    "value": "41098",
    "label": "Worthville - KY 41098",
    "county": "Owen County",
    "state": "KY",
    "city": "Worthville"
  },
  {
    "value": "41101",
    "label": "Ashland - KY 41101",
    "county": "Boyd County",
    "state": "KY",
    "city": "Ashland"
  },
  {
    "value": "41102",
    "label": "Ashland - KY 41102",
    "county": "Boyd County",
    "state": "KY",
    "city": "Ashland"
  },
  {
    "value": "41121",
    "label": "Argillite - KY 41121",
    "county": "Greenup County",
    "state": "KY",
    "city": "Argillite"
  },
  {
    "value": "41124",
    "label": "Blaine - KY 41124",
    "county": "Lawrence County",
    "state": "KY",
    "city": "Blaine"
  },
  {
    "value": "41129",
    "label": "Catlettsburg - KY 41129",
    "county": "Boyd County",
    "state": "KY",
    "city": "Catlettsburg"
  },
  {
    "value": "41132",
    "label": "Denton - KY 41132",
    "county": "Carter County",
    "state": "KY",
    "city": "Denton"
  },
  {
    "value": "41135",
    "label": "Emerson - KY 41135",
    "county": "Lewis County",
    "state": "KY",
    "city": "Emerson"
  },
  {
    "value": "41139",
    "label": "Flatwoods - KY 41139",
    "county": "Greenup County",
    "state": "KY",
    "city": "Flatwoods"
  },
  {
    "value": "41141",
    "label": "Garrison - KY 41141",
    "county": "Lewis County",
    "state": "KY",
    "city": "Garrison"
  },
  {
    "value": "41143",
    "label": "Grayson - KY 41143",
    "county": "Carter County",
    "state": "KY",
    "city": "Grayson"
  },
  {
    "value": "41144",
    "label": "Greenup - KY 41144",
    "county": "Greenup County",
    "state": "KY",
    "city": "Greenup"
  },
  {
    "value": "41146",
    "label": "Hitchins - KY 41146",
    "county": "Carter County",
    "state": "KY",
    "city": "Hitchins"
  },
  {
    "value": "41149",
    "label": "Isonville - KY 41149",
    "county": "Elliott County",
    "state": "KY",
    "city": "Isonville"
  },
  {
    "value": "41159",
    "label": "Martha - KY 41159",
    "county": "Lawrence County",
    "state": "KY",
    "city": "Martha"
  },
  {
    "value": "41160",
    "label": "Mazie - KY 41160",
    "county": "Lawrence County",
    "state": "KY",
    "city": "Mazie"
  },
  {
    "value": "41164",
    "label": "Olive Hill - KY 41164",
    "county": "Carter County",
    "state": "KY",
    "city": "Olive Hill"
  },
  {
    "value": "41166",
    "label": "Quincy - KY 41166",
    "county": "Lewis County",
    "state": "KY",
    "city": "Quincy"
  },
  {
    "value": "41168",
    "label": "Rush - KY 41168",
    "county": "Boyd County",
    "state": "KY",
    "city": "Rush"
  },
  {
    "value": "41169",
    "label": "Russell - KY 41169",
    "county": "Greenup County",
    "state": "KY",
    "city": "Russell"
  },
  {
    "value": "41171",
    "label": "Sandy Hook - KY 41171",
    "county": "Elliott County",
    "state": "KY",
    "city": "Sandy Hook"
  },
  {
    "value": "41174",
    "label": "South Portsmouth - KY 41174",
    "county": "Greenup County",
    "state": "KY",
    "city": "South Portsmouth"
  },
  {
    "value": "41175",
    "label": "South Shore - KY 41175",
    "county": "Greenup County",
    "state": "KY",
    "city": "South Shore"
  },
  {
    "value": "41179",
    "label": "Vanceburg - KY 41179",
    "county": "Lewis County",
    "state": "KY",
    "city": "Vanceburg"
  },
  {
    "value": "41180",
    "label": "Webbville - KY 41180",
    "county": "Lawrence County",
    "state": "KY",
    "city": "Webbville"
  },
  {
    "value": "41183",
    "label": "Worthington - KY 41183",
    "county": "Greenup County",
    "state": "KY",
    "city": "Worthington"
  },
  {
    "value": "41189",
    "label": "Tollesboro - KY 41189",
    "county": "Lewis County",
    "state": "KY",
    "city": "Tollesboro"
  },
  {
    "value": "41201",
    "label": "Adams - KY 41201",
    "county": "Lawrence County",
    "state": "KY",
    "city": "Adams"
  },
  {
    "value": "41203",
    "label": "Beauty - KY 41203",
    "county": "Martin County",
    "state": "KY",
    "city": "Beauty"
  },
  {
    "value": "41204",
    "label": "Boons Camp - KY 41204",
    "county": "Johnson County",
    "state": "KY",
    "city": "Boons Camp"
  },
  {
    "value": "41214",
    "label": "Debord - KY 41214",
    "county": "Martin County",
    "state": "KY",
    "city": "Debord"
  },
  {
    "value": "41216",
    "label": "East Point - KY 41216",
    "county": "Floyd County",
    "state": "KY",
    "city": "East Point"
  },
  {
    "value": "41219",
    "label": "Flatgap - KY 41219",
    "county": "Johnson County",
    "state": "KY",
    "city": "Flatgap"
  },
  {
    "value": "41222",
    "label": "Hagerhill - KY 41222",
    "county": "Johnson County",
    "state": "KY",
    "city": "Hagerhill"
  },
  {
    "value": "41224",
    "label": "Inez - KY 41224",
    "county": "Martin County",
    "state": "KY",
    "city": "Inez"
  },
  {
    "value": "41226",
    "label": "Keaton - KY 41226",
    "county": "Johnson County",
    "state": "KY",
    "city": "Keaton"
  },
  {
    "value": "41230",
    "label": "Louisa - KY 41230",
    "county": "Lawrence County",
    "state": "KY",
    "city": "Louisa"
  },
  {
    "value": "41231",
    "label": "Lovely - KY 41231",
    "county": "Martin County",
    "state": "KY",
    "city": "Lovely"
  },
  {
    "value": "41232",
    "label": "Lowmansville - KY 41232",
    "county": "Lawrence County",
    "state": "KY",
    "city": "Lowmansville"
  },
  {
    "value": "41234",
    "label": "Meally - KY 41234",
    "county": "Johnson County",
    "state": "KY",
    "city": "Meally"
  },
  {
    "value": "41238",
    "label": "Oil Springs - KY 41238",
    "county": "Johnson County",
    "state": "KY",
    "city": "Oil Springs"
  },
  {
    "value": "41240",
    "label": "Paintsville - KY 41240",
    "county": "Johnson County",
    "state": "KY",
    "city": "Paintsville"
  },
  {
    "value": "41250",
    "label": "Pilgrim - KY 41250",
    "county": "Martin County",
    "state": "KY",
    "city": "Pilgrim"
  },
  {
    "value": "41254",
    "label": "River - KY 41254",
    "county": "Johnson County",
    "state": "KY",
    "city": "River"
  },
  {
    "value": "41255",
    "label": "Sitka - KY 41255",
    "county": "Johnson County",
    "state": "KY",
    "city": "Sitka"
  },
  {
    "value": "41256",
    "label": "Staffordsville - KY 41256",
    "county": "Johnson County",
    "state": "KY",
    "city": "Staffordsville"
  },
  {
    "value": "41257",
    "label": "Stambaugh - KY 41257",
    "county": "Johnson County",
    "state": "KY",
    "city": "Stambaugh"
  },
  {
    "value": "41260",
    "label": "Thelma - KY 41260",
    "county": "Johnson County",
    "state": "KY",
    "city": "Thelma"
  },
  {
    "value": "41262",
    "label": "Tomahawk - KY 41262",
    "county": "Martin County",
    "state": "KY",
    "city": "Tomahawk"
  },
  {
    "value": "41263",
    "label": "Tutor Key - KY 41263",
    "county": "Johnson County",
    "state": "KY",
    "city": "Tutor Key"
  },
  {
    "value": "41264",
    "label": "Ulysses - KY 41264",
    "county": "Lawrence County",
    "state": "KY",
    "city": "Ulysses"
  },
  {
    "value": "41265",
    "label": "Van Lear - KY 41265",
    "county": "Johnson County",
    "state": "KY",
    "city": "Van Lear"
  },
  {
    "value": "41267",
    "label": "Warfield - KY 41267",
    "county": "Martin County",
    "state": "KY",
    "city": "Warfield"
  },
  {
    "value": "41268",
    "label": "West Van Lear - KY 41268",
    "county": "Johnson County",
    "state": "KY",
    "city": "West Van Lear"
  },
  {
    "value": "41271",
    "label": "Williamsport - KY 41271",
    "county": "Johnson County",
    "state": "KY",
    "city": "Williamsport"
  },
  {
    "value": "41274",
    "label": "Wittensville - KY 41274",
    "county": "Johnson County",
    "state": "KY",
    "city": "Wittensville"
  },
  {
    "value": "41301",
    "label": "Campton - KY 41301",
    "county": "Wolfe County",
    "state": "KY",
    "city": "Campton"
  },
  {
    "value": "41307",
    "label": "Athol - KY 41307",
    "county": "Lee County",
    "state": "KY",
    "city": "Athol"
  },
  {
    "value": "41310",
    "label": "Bays - KY 41310",
    "county": "Breathitt County",
    "state": "KY",
    "city": "Bays"
  },
  {
    "value": "41311",
    "label": "Beattyville - KY 41311",
    "county": "Lee County",
    "state": "KY",
    "city": "Beattyville"
  },
  {
    "value": "41314",
    "label": "Booneville - KY 41314",
    "county": "Owsley County",
    "state": "KY",
    "city": "Booneville"
  },
  {
    "value": "41317",
    "label": "Clayhole - KY 41317",
    "county": "Breathitt County",
    "state": "KY",
    "city": "Clayhole"
  },
  {
    "value": "41332",
    "label": "Hazel Green - KY 41332",
    "county": "Wolfe County",
    "state": "KY",
    "city": "Hazel Green"
  },
  {
    "value": "41338",
    "label": "Island City - KY 41338",
    "county": "Owsley County",
    "state": "KY",
    "city": "Island City"
  },
  {
    "value": "41339",
    "label": "Jackson - KY 41339",
    "county": "Breathitt County",
    "state": "KY",
    "city": "Jackson"
  },
  {
    "value": "41348",
    "label": "Lost Creek - KY 41348",
    "county": "Breathitt County",
    "state": "KY",
    "city": "Lost Creek"
  },
  {
    "value": "41351",
    "label": "Mistletoe - KY 41351",
    "county": "Owsley County",
    "state": "KY",
    "city": "Mistletoe"
  },
  {
    "value": "41352",
    "label": "Mize - KY 41352",
    "county": "Morgan County",
    "state": "KY",
    "city": "Mize"
  },
  {
    "value": "41360",
    "label": "Pine Ridge - KY 41360",
    "county": "Wolfe County",
    "state": "KY",
    "city": "Pine Ridge"
  },
  {
    "value": "41364",
    "label": "Ricetown - KY 41364",
    "county": "Owsley County",
    "state": "KY",
    "city": "Ricetown"
  },
  {
    "value": "41365",
    "label": "Rogers - KY 41365",
    "county": "Wolfe County",
    "state": "KY",
    "city": "Rogers"
  },
  {
    "value": "41366",
    "label": "Rousseau - KY 41366",
    "county": "Breathitt County",
    "state": "KY",
    "city": "Rousseau"
  },
  {
    "value": "41367",
    "label": "Rowdy - KY 41367",
    "county": "Perry County",
    "state": "KY",
    "city": "Rowdy"
  },
  {
    "value": "41385",
    "label": "Vancleve - KY 41385",
    "county": "Breathitt County",
    "state": "KY",
    "city": "Vancleve"
  },
  {
    "value": "41386",
    "label": "Vincent - KY 41386",
    "county": "Owsley County",
    "state": "KY",
    "city": "Vincent"
  },
  {
    "value": "41390",
    "label": "Whick - KY 41390",
    "county": "Breathitt County",
    "state": "KY",
    "city": "Whick"
  },
  {
    "value": "41397",
    "label": "Zoe - KY 41397",
    "county": "Lee County",
    "state": "KY",
    "city": "Zoe"
  },
  {
    "value": "41408",
    "label": "Cannel City - KY 41408",
    "county": "Morgan County",
    "state": "KY",
    "city": "Cannel City"
  },
  {
    "value": "41421",
    "label": "Elkfork - KY 41421",
    "county": "Morgan County",
    "state": "KY",
    "city": "Elkfork"
  },
  {
    "value": "41425",
    "label": "Ezel - KY 41425",
    "county": "Morgan County",
    "state": "KY",
    "city": "Ezel"
  },
  {
    "value": "41464",
    "label": "Royalton - KY 41464",
    "county": "Magoffin County",
    "state": "KY",
    "city": "Royalton"
  },
  {
    "value": "41465",
    "label": "Salyersville - KY 41465",
    "county": "Magoffin County",
    "state": "KY",
    "city": "Salyersville"
  },
  {
    "value": "41472",
    "label": "West Liberty - KY 41472",
    "county": "Morgan County",
    "state": "KY",
    "city": "West Liberty"
  },
  {
    "value": "41501",
    "label": "Pikeville - KY 41501",
    "county": "Pike County",
    "state": "KY",
    "city": "Pikeville"
  },
  {
    "value": "41503",
    "label": "South Williamson - KY 41503",
    "county": "Pike County",
    "state": "KY",
    "city": "South Williamson"
  },
  {
    "value": "41512",
    "label": "Ashcamp - KY 41512",
    "county": "Pike County",
    "state": "KY",
    "city": "Ashcamp"
  },
  {
    "value": "41513",
    "label": "Belcher - KY 41513",
    "county": "Pike County",
    "state": "KY",
    "city": "Belcher"
  },
  {
    "value": "41514",
    "label": "Belfry - KY 41514",
    "county": "Pike County",
    "state": "KY",
    "city": "Belfry"
  },
  {
    "value": "41517",
    "label": "Burdine - KY 41517",
    "county": "Letcher County",
    "state": "KY",
    "city": "Burdine"
  },
  {
    "value": "41519",
    "label": "Canada - KY 41519",
    "county": "Pike County",
    "state": "KY",
    "city": "Canada"
  },
  {
    "value": "41522",
    "label": "Elkhorn City - KY 41522",
    "county": "Pike County",
    "state": "KY",
    "city": "Elkhorn City"
  },
  {
    "value": "41524",
    "label": "Fedscreek - KY 41524",
    "county": "Pike County",
    "state": "KY",
    "city": "Fedscreek"
  },
  {
    "value": "41527",
    "label": "Forest Hills - KY 41527",
    "county": "Pike County",
    "state": "KY",
    "city": "Forest Hills"
  },
  {
    "value": "41528",
    "label": "Freeburn - KY 41528",
    "county": "Pike County",
    "state": "KY",
    "city": "Freeburn"
  },
  {
    "value": "41531",
    "label": "Hardy - KY 41531",
    "county": "Pike County",
    "state": "KY",
    "city": "Hardy"
  },
  {
    "value": "41534",
    "label": "Hellier - KY 41534",
    "county": "Pike County",
    "state": "KY",
    "city": "Hellier"
  },
  {
    "value": "41535",
    "label": "Huddy - KY 41535",
    "county": "Pike County",
    "state": "KY",
    "city": "Huddy"
  },
  {
    "value": "41537",
    "label": "Jenkins - KY 41537",
    "county": "Letcher County",
    "state": "KY",
    "city": "Jenkins"
  },
  {
    "value": "41539",
    "label": "Kimper - KY 41539",
    "county": "Pike County",
    "state": "KY",
    "city": "Kimper"
  },
  {
    "value": "41540",
    "label": "Lick Creek - KY 41540",
    "county": "Pike County",
    "state": "KY",
    "city": "Lick Creek"
  },
  {
    "value": "41543",
    "label": "Mc Andrews - KY 41543",
    "county": "Pike County",
    "state": "KY",
    "city": "Mc Andrews"
  },
  {
    "value": "41544",
    "label": "Mc Carr - KY 41544",
    "county": "Pike County",
    "state": "KY",
    "city": "Mc Carr"
  },
  {
    "value": "41547",
    "label": "Majestic - KY 41547",
    "county": "Pike County",
    "state": "KY",
    "city": "Majestic"
  },
  {
    "value": "41548",
    "label": "Mouthcard - KY 41548",
    "county": "Pike County",
    "state": "KY",
    "city": "Mouthcard"
  },
  {
    "value": "41553",
    "label": "Phelps - KY 41553",
    "county": "Pike County",
    "state": "KY",
    "city": "Phelps"
  },
  {
    "value": "41554",
    "label": "Phyllis - KY 41554",
    "county": "Pike County",
    "state": "KY",
    "city": "Phyllis"
  },
  {
    "value": "41555",
    "label": "Pinsonfork - KY 41555",
    "county": "Pike County",
    "state": "KY",
    "city": "Pinsonfork"
  },
  {
    "value": "41557",
    "label": "Raccoon - KY 41557",
    "county": "Pike County",
    "state": "KY",
    "city": "Raccoon"
  },
  {
    "value": "41558",
    "label": "Ransom - KY 41558",
    "county": "Pike County",
    "state": "KY",
    "city": "Ransom"
  },
  {
    "value": "41559",
    "label": "Regina - KY 41559",
    "county": "Pike County",
    "state": "KY",
    "city": "Regina"
  },
  {
    "value": "41560",
    "label": "Robinson Creek - KY 41560",
    "county": "Pike County",
    "state": "KY",
    "city": "Robinson Creek"
  },
  {
    "value": "41562",
    "label": "Shelbiana - KY 41562",
    "county": "Pike County",
    "state": "KY",
    "city": "Shelbiana"
  },
  {
    "value": "41563",
    "label": "Shelby Gap - KY 41563",
    "county": "Pike County",
    "state": "KY",
    "city": "Shelby Gap"
  },
  {
    "value": "41564",
    "label": "Sidney - KY 41564",
    "county": "Pike County",
    "state": "KY",
    "city": "Sidney"
  },
  {
    "value": "41566",
    "label": "Steele - KY 41566",
    "county": "Pike County",
    "state": "KY",
    "city": "Steele"
  },
  {
    "value": "41567",
    "label": "Stone - KY 41567",
    "county": "Pike County",
    "state": "KY",
    "city": "Stone"
  },
  {
    "value": "41568",
    "label": "Stopover - KY 41568",
    "county": "Pike County",
    "state": "KY",
    "city": "Stopover"
  },
  {
    "value": "41571",
    "label": "Varney - KY 41571",
    "county": "Pike County",
    "state": "KY",
    "city": "Varney"
  },
  {
    "value": "41572",
    "label": "Virgie - KY 41572",
    "county": "Pike County",
    "state": "KY",
    "city": "Virgie"
  },
  {
    "value": "41601",
    "label": "Allen - KY 41601",
    "county": "Floyd County",
    "state": "KY",
    "city": "Allen"
  },
  {
    "value": "41602",
    "label": "Auxier - KY 41602",
    "county": "Floyd County",
    "state": "KY",
    "city": "Auxier"
  },
  {
    "value": "41603",
    "label": "Banner - KY 41603",
    "county": "Floyd County",
    "state": "KY",
    "city": "Banner"
  },
  {
    "value": "41604",
    "label": "Beaver - KY 41604",
    "county": "Floyd County",
    "state": "KY",
    "city": "Beaver"
  },
  {
    "value": "41605",
    "label": "Betsy Layne - KY 41605",
    "county": "Floyd County",
    "state": "KY",
    "city": "Betsy Layne"
  },
  {
    "value": "41606",
    "label": "Bevinsville - KY 41606",
    "county": "Floyd County",
    "state": "KY",
    "city": "Bevinsville"
  },
  {
    "value": "41607",
    "label": "Blue River - KY 41607",
    "county": "Floyd County",
    "state": "KY",
    "city": "Blue River"
  },
  {
    "value": "41612",
    "label": "Bypro - KY 41612",
    "county": "Floyd County",
    "state": "KY",
    "city": "Bypro"
  },
  {
    "value": "41615",
    "label": "Dana - KY 41615",
    "county": "Floyd County",
    "state": "KY",
    "city": "Dana"
  },
  {
    "value": "41616",
    "label": "David - KY 41616",
    "county": "Floyd County",
    "state": "KY",
    "city": "David"
  },
  {
    "value": "41619",
    "label": "Drift - KY 41619",
    "county": "Floyd County",
    "state": "KY",
    "city": "Drift"
  },
  {
    "value": "41621",
    "label": "Dwale - KY 41621",
    "county": "Floyd County",
    "state": "KY",
    "city": "Dwale"
  },
  {
    "value": "41622",
    "label": "Eastern - KY 41622",
    "county": "Floyd County",
    "state": "KY",
    "city": "Eastern"
  },
  {
    "value": "41630",
    "label": "Garrett - KY 41630",
    "county": "Floyd County",
    "state": "KY",
    "city": "Garrett"
  },
  {
    "value": "41631",
    "label": "Grethel - KY 41631",
    "county": "Floyd County",
    "state": "KY",
    "city": "Grethel"
  },
  {
    "value": "41632",
    "label": "Gunlock - KY 41632",
    "county": "Magoffin County",
    "state": "KY",
    "city": "Gunlock"
  },
  {
    "value": "41635",
    "label": "Harold - KY 41635",
    "county": "Floyd County",
    "state": "KY",
    "city": "Harold"
  },
  {
    "value": "41636",
    "label": "Hi Hat - KY 41636",
    "county": "Floyd County",
    "state": "KY",
    "city": "Hi Hat"
  },
  {
    "value": "41640",
    "label": "Hueysville - KY 41640",
    "county": "Floyd County",
    "state": "KY",
    "city": "Hueysville"
  },
  {
    "value": "41642",
    "label": "Ivel - KY 41642",
    "county": "Floyd County",
    "state": "KY",
    "city": "Ivel"
  },
  {
    "value": "41643",
    "label": "Lackey - KY 41643",
    "county": "Knott County",
    "state": "KY",
    "city": "Lackey"
  },
  {
    "value": "41645",
    "label": "Langley - KY 41645",
    "county": "Floyd County",
    "state": "KY",
    "city": "Langley"
  },
  {
    "value": "41647",
    "label": "Mc Dowell - KY 41647",
    "county": "Floyd County",
    "state": "KY",
    "city": "Mc Dowell"
  },
  {
    "value": "41649",
    "label": "Martin - KY 41649",
    "county": "Floyd County",
    "state": "KY",
    "city": "Martin"
  },
  {
    "value": "41650",
    "label": "Melvin - KY 41650",
    "county": "Floyd County",
    "state": "KY",
    "city": "Melvin"
  },
  {
    "value": "41651",
    "label": "Minnie - KY 41651",
    "county": "Floyd County",
    "state": "KY",
    "city": "Minnie"
  },
  {
    "value": "41653",
    "label": "Prestonsburg - KY 41653",
    "county": "Floyd County",
    "state": "KY",
    "city": "Prestonsburg"
  },
  {
    "value": "41655",
    "label": "Printer - KY 41655",
    "county": "Floyd County",
    "state": "KY",
    "city": "Printer"
  },
  {
    "value": "41659",
    "label": "Stanville - KY 41659",
    "county": "Floyd County",
    "state": "KY",
    "city": "Stanville"
  },
  {
    "value": "41660",
    "label": "Teaberry - KY 41660",
    "county": "Floyd County",
    "state": "KY",
    "city": "Teaberry"
  },
  {
    "value": "41663",
    "label": "Tram - KY 41663",
    "county": "Floyd County",
    "state": "KY",
    "city": "Tram"
  },
  {
    "value": "41666",
    "label": "Wayland - KY 41666",
    "county": "Floyd County",
    "state": "KY",
    "city": "Wayland"
  },
  {
    "value": "41667",
    "label": "Weeksbury - KY 41667",
    "county": "Floyd County",
    "state": "KY",
    "city": "Weeksbury"
  },
  {
    "value": "41669",
    "label": "Wheelwright - KY 41669",
    "county": "Floyd County",
    "state": "KY",
    "city": "Wheelwright"
  },
  {
    "value": "41701",
    "label": "Hazard - KY 41701",
    "county": "Perry County",
    "state": "KY",
    "city": "Hazard"
  },
  {
    "value": "41712",
    "label": "Ary - KY 41712",
    "county": "Perry County",
    "state": "KY",
    "city": "Ary"
  },
  {
    "value": "41714",
    "label": "Bear Branch - KY 41714",
    "county": "Leslie County",
    "state": "KY",
    "city": "Bear Branch"
  },
  {
    "value": "41719",
    "label": "Bonnyman - KY 41719",
    "county": "Perry County",
    "state": "KY",
    "city": "Bonnyman"
  },
  {
    "value": "41721",
    "label": "Buckhorn - KY 41721",
    "county": "Perry County",
    "state": "KY",
    "city": "Buckhorn"
  },
  {
    "value": "41722",
    "label": "Bulan - KY 41722",
    "county": "Perry County",
    "state": "KY",
    "city": "Bulan"
  },
  {
    "value": "41723",
    "label": "Busy - KY 41723",
    "county": "Perry County",
    "state": "KY",
    "city": "Busy"
  },
  {
    "value": "41727",
    "label": "Chavies - KY 41727",
    "county": "Perry County",
    "state": "KY",
    "city": "Chavies"
  },
  {
    "value": "41729",
    "label": "Combs - KY 41729",
    "county": "Perry County",
    "state": "KY",
    "city": "Combs"
  },
  {
    "value": "41731",
    "label": "Cornettsville - KY 41731",
    "county": "Perry County",
    "state": "KY",
    "city": "Cornettsville"
  },
  {
    "value": "41735",
    "label": "Delphia - KY 41735",
    "county": "Perry County",
    "state": "KY",
    "city": "Delphia"
  },
  {
    "value": "41740",
    "label": "Emmalena - KY 41740",
    "county": "Knott County",
    "state": "KY",
    "city": "Emmalena"
  },
  {
    "value": "41745",
    "label": "Gays Creek - KY 41745",
    "county": "Perry County",
    "state": "KY",
    "city": "Gays Creek"
  },
  {
    "value": "41746",
    "label": "Happy - KY 41746",
    "county": "Perry County",
    "state": "KY",
    "city": "Happy"
  },
  {
    "value": "41749",
    "label": "Hyden - KY 41749",
    "county": "Leslie County",
    "state": "KY",
    "city": "Hyden"
  },
  {
    "value": "41751",
    "label": "Jeff - KY 41751",
    "county": "Perry County",
    "state": "KY",
    "city": "Jeff"
  },
  {
    "value": "41754",
    "label": "Krypton - KY 41754",
    "county": "Perry County",
    "state": "KY",
    "city": "Krypton"
  },
  {
    "value": "41759",
    "label": "Sassafras - KY 41759",
    "county": "Knott County",
    "state": "KY",
    "city": "Sassafras"
  },
  {
    "value": "41760",
    "label": "Scuddy - KY 41760",
    "county": "Perry County",
    "state": "KY",
    "city": "Scuddy"
  },
  {
    "value": "41762",
    "label": "Sizerock - KY 41762",
    "county": "Leslie County",
    "state": "KY",
    "city": "Sizerock"
  },
  {
    "value": "41763",
    "label": "Slemp - KY 41763",
    "county": "Perry County",
    "state": "KY",
    "city": "Slemp"
  },
  {
    "value": "41764",
    "label": "Smilax - KY 41764",
    "county": "Leslie County",
    "state": "KY",
    "city": "Smilax"
  },
  {
    "value": "41766",
    "label": "Thousandsticks - KY 41766",
    "county": "Leslie County",
    "state": "KY",
    "city": "Thousandsticks"
  },
  {
    "value": "41772",
    "label": "Vest - KY 41772",
    "county": "Knott County",
    "state": "KY",
    "city": "Vest"
  },
  {
    "value": "41773",
    "label": "Vicco - KY 41773",
    "county": "Perry County",
    "state": "KY",
    "city": "Vicco"
  },
  {
    "value": "41774",
    "label": "Viper - KY 41774",
    "county": "Perry County",
    "state": "KY",
    "city": "Viper"
  },
  {
    "value": "41775",
    "label": "Wendover - KY 41775",
    "county": "Leslie County",
    "state": "KY",
    "city": "Wendover"
  },
  {
    "value": "41776",
    "label": "Wooton - KY 41776",
    "county": "Leslie County",
    "state": "KY",
    "city": "Wooton"
  },
  {
    "value": "41777",
    "label": "Yeaddiss - KY 41777",
    "county": "Leslie County",
    "state": "KY",
    "city": "Yeaddiss"
  },
  {
    "value": "41804",
    "label": "Blackey - KY 41804",
    "county": "Letcher County",
    "state": "KY",
    "city": "Blackey"
  },
  {
    "value": "41812",
    "label": "Deane - KY 41812",
    "county": "Letcher County",
    "state": "KY",
    "city": "Deane"
  },
  {
    "value": "41815",
    "label": "Ermine - KY 41815",
    "county": "Letcher County",
    "state": "KY",
    "city": "Ermine"
  },
  {
    "value": "41817",
    "label": "Garner - KY 41817",
    "county": "Knott County",
    "state": "KY",
    "city": "Garner"
  },
  {
    "value": "41819",
    "label": "Gordon - KY 41819",
    "county": "Letcher County",
    "state": "KY",
    "city": "Gordon"
  },
  {
    "value": "41821",
    "label": "Hallie - KY 41821",
    "county": "Letcher County",
    "state": "KY",
    "city": "Hallie"
  },
  {
    "value": "41822",
    "label": "Hindman - KY 41822",
    "county": "Knott County",
    "state": "KY",
    "city": "Hindman"
  },
  {
    "value": "41824",
    "label": "Isom - KY 41824",
    "county": "Letcher County",
    "state": "KY",
    "city": "Isom"
  },
  {
    "value": "41825",
    "label": "Jackhorn - KY 41825",
    "county": "Letcher County",
    "state": "KY",
    "city": "Jackhorn"
  },
  {
    "value": "41826",
    "label": "Jeremiah - KY 41826",
    "county": "Letcher County",
    "state": "KY",
    "city": "Jeremiah"
  },
  {
    "value": "41828",
    "label": "Kite - KY 41828",
    "county": "Knott County",
    "state": "KY",
    "city": "Kite"
  },
  {
    "value": "41831",
    "label": "Leburn - KY 41831",
    "county": "Knott County",
    "state": "KY",
    "city": "Leburn"
  },
  {
    "value": "41832",
    "label": "Letcher - KY 41832",
    "county": "Letcher County",
    "state": "KY",
    "city": "Letcher"
  },
  {
    "value": "41833",
    "label": "Linefork - KY 41833",
    "county": "Letcher County",
    "state": "KY",
    "city": "Linefork"
  },
  {
    "value": "41834",
    "label": "Littcarr - KY 41834",
    "county": "Knott County",
    "state": "KY",
    "city": "Littcarr"
  },
  {
    "value": "41835",
    "label": "Mc Roberts - KY 41835",
    "county": "Letcher County",
    "state": "KY",
    "city": "Mc Roberts"
  },
  {
    "value": "41836",
    "label": "Mallie - KY 41836",
    "county": "Knott County",
    "state": "KY",
    "city": "Mallie"
  },
  {
    "value": "41837",
    "label": "Mayking - KY 41837",
    "county": "Letcher County",
    "state": "KY",
    "city": "Mayking"
  },
  {
    "value": "41838",
    "label": "Millstone - KY 41838",
    "county": "Letcher County",
    "state": "KY",
    "city": "Millstone"
  },
  {
    "value": "41839",
    "label": "Mousie - KY 41839",
    "county": "Knott County",
    "state": "KY",
    "city": "Mousie"
  },
  {
    "value": "41840",
    "label": "Neon - KY 41840",
    "county": "Letcher County",
    "state": "KY",
    "city": "Neon"
  },
  {
    "value": "41843",
    "label": "Pine Top - KY 41843",
    "county": "Knott County",
    "state": "KY",
    "city": "Pine Top"
  },
  {
    "value": "41844",
    "label": "Pippa Passes - KY 41844",
    "county": "Knott County",
    "state": "KY",
    "city": "Pippa Passes"
  },
  {
    "value": "41845",
    "label": "Premium - KY 41845",
    "county": "Letcher County",
    "state": "KY",
    "city": "Premium"
  },
  {
    "value": "41847",
    "label": "Redfox - KY 41847",
    "county": "Knott County",
    "state": "KY",
    "city": "Redfox"
  },
  {
    "value": "41848",
    "label": "Roxana - KY 41848",
    "county": "Letcher County",
    "state": "KY",
    "city": "Roxana"
  },
  {
    "value": "41855",
    "label": "Thornton - KY 41855",
    "county": "Letcher County",
    "state": "KY",
    "city": "Thornton"
  },
  {
    "value": "41858",
    "label": "Whitesburg - KY 41858",
    "county": "Letcher County",
    "state": "KY",
    "city": "Whitesburg"
  },
  {
    "value": "41859",
    "label": "Dema - KY 41859",
    "county": "Knott County",
    "state": "KY",
    "city": "Dema"
  },
  {
    "value": "41861",
    "label": "Raven - KY 41861",
    "county": "Knott County",
    "state": "KY",
    "city": "Raven"
  },
  {
    "value": "41862",
    "label": "Topmost - KY 41862",
    "county": "Knott County",
    "state": "KY",
    "city": "Topmost"
  },
  {
    "value": "42001",
    "label": "Paducah - KY 42001",
    "county": "McCracken County",
    "state": "KY",
    "city": "Paducah"
  },
  {
    "value": "42003",
    "label": "Paducah - KY 42003",
    "county": "McCracken County",
    "state": "KY",
    "city": "Paducah"
  },
  {
    "value": "42020",
    "label": "Almo - KY 42020",
    "county": "Calloway County",
    "state": "KY",
    "city": "Almo"
  },
  {
    "value": "42021",
    "label": "Arlington - KY 42021",
    "county": "Carlisle County",
    "state": "KY",
    "city": "Arlington"
  },
  {
    "value": "42023",
    "label": "Bardwell - KY 42023",
    "county": "Carlisle County",
    "state": "KY",
    "city": "Bardwell"
  },
  {
    "value": "42024",
    "label": "Barlow - KY 42024",
    "county": "Ballard County",
    "state": "KY",
    "city": "Barlow"
  },
  {
    "value": "42025",
    "label": "Benton - KY 42025",
    "county": "Marshall County",
    "state": "KY",
    "city": "Benton"
  },
  {
    "value": "42027",
    "label": "Boaz - KY 42027",
    "county": "Graves County",
    "state": "KY",
    "city": "Boaz"
  },
  {
    "value": "42028",
    "label": "Burna - KY 42028",
    "county": "Livingston County",
    "state": "KY",
    "city": "Burna"
  },
  {
    "value": "42029",
    "label": "Calvert City - KY 42029",
    "county": "Marshall County",
    "state": "KY",
    "city": "Calvert City"
  },
  {
    "value": "42031",
    "label": "Clinton - KY 42031",
    "county": "Hickman County",
    "state": "KY",
    "city": "Clinton"
  },
  {
    "value": "42032",
    "label": "Columbus - KY 42032",
    "county": "Hickman County",
    "state": "KY",
    "city": "Columbus"
  },
  {
    "value": "42035",
    "label": "Cunningham - KY 42035",
    "county": "Carlisle County",
    "state": "KY",
    "city": "Cunningham"
  },
  {
    "value": "42036",
    "label": "Dexter - KY 42036",
    "county": "Calloway County",
    "state": "KY",
    "city": "Dexter"
  },
  {
    "value": "42038",
    "label": "Eddyville - KY 42038",
    "county": "Lyon County",
    "state": "KY",
    "city": "Eddyville"
  },
  {
    "value": "42039",
    "label": "Fancy Farm - KY 42039",
    "county": "Graves County",
    "state": "KY",
    "city": "Fancy Farm"
  },
  {
    "value": "42040",
    "label": "Farmington - KY 42040",
    "county": "Graves County",
    "state": "KY",
    "city": "Farmington"
  },
  {
    "value": "42041",
    "label": "Fulton - KY 42041",
    "county": "Fulton County",
    "state": "KY",
    "city": "Fulton"
  },
  {
    "value": "42044",
    "label": "Gilbertsville - KY 42044",
    "county": "Marshall County",
    "state": "KY",
    "city": "Gilbertsville"
  },
  {
    "value": "42045",
    "label": "Grand Rivers - KY 42045",
    "county": "Livingston County",
    "state": "KY",
    "city": "Grand Rivers"
  },
  {
    "value": "42047",
    "label": "Hampton - KY 42047",
    "county": "Livingston County",
    "state": "KY",
    "city": "Hampton"
  },
  {
    "value": "42048",
    "label": "Hardin - KY 42048",
    "county": "Marshall County",
    "state": "KY",
    "city": "Hardin"
  },
  {
    "value": "42049",
    "label": "Hazel - KY 42049",
    "county": "Calloway County",
    "state": "KY",
    "city": "Hazel"
  },
  {
    "value": "42050",
    "label": "Hickman - KY 42050",
    "county": "Fulton County",
    "state": "KY",
    "city": "Hickman"
  },
  {
    "value": "42051",
    "label": "Hickory - KY 42051",
    "county": "Graves County",
    "state": "KY",
    "city": "Hickory"
  },
  {
    "value": "42053",
    "label": "Kevil - KY 42053",
    "county": "McCracken County",
    "state": "KY",
    "city": "Kevil"
  },
  {
    "value": "42054",
    "label": "Kirksey - KY 42054",
    "county": "Calloway County",
    "state": "KY",
    "city": "Kirksey"
  },
  {
    "value": "42055",
    "label": "Kuttawa - KY 42055",
    "county": "Lyon County",
    "state": "KY",
    "city": "Kuttawa"
  },
  {
    "value": "42056",
    "label": "La Center - KY 42056",
    "county": "Ballard County",
    "state": "KY",
    "city": "La Center"
  },
  {
    "value": "42058",
    "label": "Ledbetter - KY 42058",
    "county": "Livingston County",
    "state": "KY",
    "city": "Ledbetter"
  },
  {
    "value": "42064",
    "label": "Marion - KY 42064",
    "county": "Crittenden County",
    "state": "KY",
    "city": "Marion"
  },
  {
    "value": "42066",
    "label": "Mayfield - KY 42066",
    "county": "Graves County",
    "state": "KY",
    "city": "Mayfield"
  },
  {
    "value": "42069",
    "label": "Melber - KY 42069",
    "county": "Graves County",
    "state": "KY",
    "city": "Melber"
  },
  {
    "value": "42071",
    "label": "Murray - KY 42071",
    "county": "Calloway County",
    "state": "KY",
    "city": "Murray"
  },
  {
    "value": "42076",
    "label": "New Concord - KY 42076",
    "county": "Calloway County",
    "state": "KY",
    "city": "New Concord"
  },
  {
    "value": "42078",
    "label": "Salem - KY 42078",
    "county": "Livingston County",
    "state": "KY",
    "city": "Salem"
  },
  {
    "value": "42079",
    "label": "Sedalia - KY 42079",
    "county": "Graves County",
    "state": "KY",
    "city": "Sedalia"
  },
  {
    "value": "42081",
    "label": "Smithland - KY 42081",
    "county": "Livingston County",
    "state": "KY",
    "city": "Smithland"
  },
  {
    "value": "42082",
    "label": "Symsonia - KY 42082",
    "county": "Graves County",
    "state": "KY",
    "city": "Symsonia"
  },
  {
    "value": "42083",
    "label": "Tiline - KY 42083",
    "county": "Livingston County",
    "state": "KY",
    "city": "Tiline"
  },
  {
    "value": "42085",
    "label": "Water Valley - KY 42085",
    "county": "Graves County",
    "state": "KY",
    "city": "Water Valley"
  },
  {
    "value": "42086",
    "label": "West Paducah - KY 42086",
    "county": "McCracken County",
    "state": "KY",
    "city": "West Paducah"
  },
  {
    "value": "42087",
    "label": "Wickliffe - KY 42087",
    "county": "Ballard County",
    "state": "KY",
    "city": "Wickliffe"
  },
  {
    "value": "42088",
    "label": "Wingo - KY 42088",
    "county": "Graves County",
    "state": "KY",
    "city": "Wingo"
  },
  {
    "value": "42101",
    "label": "Bowling Green - KY 42101",
    "county": "Warren County",
    "state": "KY",
    "city": "Bowling Green"
  },
  {
    "value": "42103",
    "label": "Bowling Green - KY 42103",
    "county": "Warren County",
    "state": "KY",
    "city": "Bowling Green"
  },
  {
    "value": "42104",
    "label": "Bowling Green - KY 42104",
    "county": "Warren County",
    "state": "KY",
    "city": "Bowling Green"
  },
  {
    "value": "42120",
    "label": "Adolphus - KY 42120",
    "county": "Allen County",
    "state": "KY",
    "city": "Adolphus"
  },
  {
    "value": "42122",
    "label": "Alvaton - KY 42122",
    "county": "Warren County",
    "state": "KY",
    "city": "Alvaton"
  },
  {
    "value": "42123",
    "label": "Austin - KY 42123",
    "county": "Barren County",
    "state": "KY",
    "city": "Austin"
  },
  {
    "value": "42124",
    "label": "Beaumont - KY 42124",
    "county": "Metcalfe County",
    "state": "KY",
    "city": "Beaumont"
  },
  {
    "value": "42127",
    "label": "Cave City - KY 42127",
    "county": "Barren County",
    "state": "KY",
    "city": "Cave City"
  },
  {
    "value": "42129",
    "label": "Edmonton - KY 42129",
    "county": "Metcalfe County",
    "state": "KY",
    "city": "Edmonton"
  },
  {
    "value": "42130",
    "label": "Eighty Eight - KY 42130",
    "county": "Barren County",
    "state": "KY",
    "city": "Eighty Eight"
  },
  {
    "value": "42131",
    "label": "Etoile - KY 42131",
    "county": "Barren County",
    "state": "KY",
    "city": "Etoile"
  },
  {
    "value": "42133",
    "label": "Fountain Run - KY 42133",
    "county": "Monroe County",
    "state": "KY",
    "city": "Fountain Run"
  },
  {
    "value": "42134",
    "label": "Franklin - KY 42134",
    "county": "Simpson County",
    "state": "KY",
    "city": "Franklin"
  },
  {
    "value": "42140",
    "label": "Gamaliel - KY 42140",
    "county": "Monroe County",
    "state": "KY",
    "city": "Gamaliel"
  },
  {
    "value": "42141",
    "label": "Glasgow - KY 42141",
    "county": "Barren County",
    "state": "KY",
    "city": "Glasgow"
  },
  {
    "value": "42151",
    "label": "Hestand - KY 42151",
    "county": "Monroe County",
    "state": "KY",
    "city": "Hestand"
  },
  {
    "value": "42153",
    "label": "Holland - KY 42153",
    "county": "Allen County",
    "state": "KY",
    "city": "Holland"
  },
  {
    "value": "42154",
    "label": "Knob Lick - KY 42154",
    "county": "Metcalfe County",
    "state": "KY",
    "city": "Knob Lick"
  },
  {
    "value": "42156",
    "label": "Lucas - KY 42156",
    "county": "Barren County",
    "state": "KY",
    "city": "Lucas"
  },
  {
    "value": "42157",
    "label": "Mount Hermon - KY 42157",
    "county": "Monroe County",
    "state": "KY",
    "city": "Mount Hermon"
  },
  {
    "value": "42159",
    "label": "Oakland - KY 42159",
    "county": "Warren County",
    "state": "KY",
    "city": "Oakland"
  },
  {
    "value": "42160",
    "label": "Park City - KY 42160",
    "county": "Barren County",
    "state": "KY",
    "city": "Park City"
  },
  {
    "value": "42163",
    "label": "Rocky Hill - KY 42163",
    "county": "Edmonson County",
    "state": "KY",
    "city": "Rocky Hill"
  },
  {
    "value": "42164",
    "label": "Scottsville - KY 42164",
    "county": "Allen County",
    "state": "KY",
    "city": "Scottsville"
  },
  {
    "value": "42166",
    "label": "Summer Shade - KY 42166",
    "county": "Metcalfe County",
    "state": "KY",
    "city": "Summer Shade"
  },
  {
    "value": "42167",
    "label": "Tompkinsville - KY 42167",
    "county": "Monroe County",
    "state": "KY",
    "city": "Tompkinsville"
  },
  {
    "value": "42170",
    "label": "Woodburn - KY 42170",
    "county": "Warren County",
    "state": "KY",
    "city": "Woodburn"
  },
  {
    "value": "42171",
    "label": "Smiths Grove - KY 42171",
    "county": "Warren County",
    "state": "KY",
    "city": "Smiths Grove"
  },
  {
    "value": "42202",
    "label": "Adairville - KY 42202",
    "county": "Logan County",
    "state": "KY",
    "city": "Adairville"
  },
  {
    "value": "42204",
    "label": "Allensville - KY 42204",
    "county": "Todd County",
    "state": "KY",
    "city": "Allensville"
  },
  {
    "value": "42206",
    "label": "Auburn - KY 42206",
    "county": "Logan County",
    "state": "KY",
    "city": "Auburn"
  },
  {
    "value": "42207",
    "label": "Bee Spring - KY 42207",
    "county": "Edmonson County",
    "state": "KY",
    "city": "Bee Spring"
  },
  {
    "value": "42210",
    "label": "Brownsville - KY 42210",
    "county": "Edmonson County",
    "state": "KY",
    "city": "Brownsville"
  },
  {
    "value": "42211",
    "label": "Cadiz - KY 42211",
    "county": "Trigg County",
    "state": "KY",
    "city": "Cadiz"
  },
  {
    "value": "42214",
    "label": "Center - KY 42214",
    "county": "Metcalfe County",
    "state": "KY",
    "city": "Center"
  },
  {
    "value": "42215",
    "label": "Cerulean - KY 42215",
    "county": "Christian County",
    "state": "KY",
    "city": "Cerulean"
  },
  {
    "value": "42217",
    "label": "Crofton - KY 42217",
    "county": "Christian County",
    "state": "KY",
    "city": "Crofton"
  },
  {
    "value": "42220",
    "label": "Elkton - KY 42220",
    "county": "Todd County",
    "state": "KY",
    "city": "Elkton"
  },
  {
    "value": "42223",
    "label": "Fort Campbell - KY 42223",
    "county": "Christian County",
    "state": "KY",
    "city": "Fort Campbell"
  },
  {
    "value": "42232",
    "label": "Gracey - KY 42232",
    "county": "Christian County",
    "state": "KY",
    "city": "Gracey"
  },
  {
    "value": "42234",
    "label": "Guthrie - KY 42234",
    "county": "Todd County",
    "state": "KY",
    "city": "Guthrie"
  },
  {
    "value": "42236",
    "label": "Herndon - KY 42236",
    "county": "Christian County",
    "state": "KY",
    "city": "Herndon"
  },
  {
    "value": "42240",
    "label": "Hopkinsville - KY 42240",
    "county": "Christian County",
    "state": "KY",
    "city": "Hopkinsville"
  },
  {
    "value": "42252",
    "label": "Jetson - KY 42252",
    "county": "Butler County",
    "state": "KY",
    "city": "Jetson"
  },
  {
    "value": "42254",
    "label": "La Fayette - KY 42254",
    "county": "Christian County",
    "state": "KY",
    "city": "La Fayette"
  },
  {
    "value": "42256",
    "label": "Lewisburg - KY 42256",
    "county": "Logan County",
    "state": "KY",
    "city": "Lewisburg"
  },
  {
    "value": "42259",
    "label": "Mammoth Cave - KY 42259",
    "county": "Edmonson County",
    "state": "KY",
    "city": "Mammoth Cave"
  },
  {
    "value": "42261",
    "label": "Morgantown - KY 42261",
    "county": "Butler County",
    "state": "KY",
    "city": "Morgantown"
  },
  {
    "value": "42262",
    "label": "Oak Grove - KY 42262",
    "county": "Christian County",
    "state": "KY",
    "city": "Oak Grove"
  },
  {
    "value": "42265",
    "label": "Olmstead - KY 42265",
    "county": "Logan County",
    "state": "KY",
    "city": "Olmstead"
  },
  {
    "value": "42266",
    "label": "Pembroke - KY 42266",
    "county": "Christian County",
    "state": "KY",
    "city": "Pembroke"
  },
  {
    "value": "42273",
    "label": "Rochester - KY 42273",
    "county": "Butler County",
    "state": "KY",
    "city": "Rochester"
  },
  {
    "value": "42274",
    "label": "Rockfield - KY 42274",
    "county": "Warren County",
    "state": "KY",
    "city": "Rockfield"
  },
  {
    "value": "42275",
    "label": "Roundhill - KY 42275",
    "county": "Butler County",
    "state": "KY",
    "city": "Roundhill"
  },
  {
    "value": "42276",
    "label": "Russellville - KY 42276",
    "county": "Logan County",
    "state": "KY",
    "city": "Russellville"
  },
  {
    "value": "42280",
    "label": "Sharon Grove - KY 42280",
    "county": "Todd County",
    "state": "KY",
    "city": "Sharon Grove"
  },
  {
    "value": "42285",
    "label": "Sweeden - KY 42285",
    "county": "Edmonson County",
    "state": "KY",
    "city": "Sweeden"
  },
  {
    "value": "42286",
    "label": "Trenton - KY 42286",
    "county": "Todd County",
    "state": "KY",
    "city": "Trenton"
  },
  {
    "value": "42287",
    "label": "Welchs Creek - KY 42287",
    "county": "Butler County",
    "state": "KY",
    "city": "Welchs Creek"
  },
  {
    "value": "42301",
    "label": "Owensboro - KY 42301",
    "county": "Daviess County",
    "state": "KY",
    "city": "Owensboro"
  },
  {
    "value": "42303",
    "label": "Owensboro - KY 42303",
    "county": "Daviess County",
    "state": "KY",
    "city": "Owensboro"
  },
  {
    "value": "42320",
    "label": "Beaver Dam - KY 42320",
    "county": "Ohio County",
    "state": "KY",
    "city": "Beaver Dam"
  },
  {
    "value": "42321",
    "label": "Beech Creek - KY 42321",
    "county": "Muhlenberg County",
    "state": "KY",
    "city": "Beech Creek"
  },
  {
    "value": "42323",
    "label": "Beechmont - KY 42323",
    "county": "Muhlenberg County",
    "state": "KY",
    "city": "Beechmont"
  },
  {
    "value": "42324",
    "label": "Belton - KY 42324",
    "county": "Muhlenberg County",
    "state": "KY",
    "city": "Belton"
  },
  {
    "value": "42325",
    "label": "Bremen - KY 42325",
    "county": "Muhlenberg County",
    "state": "KY",
    "city": "Bremen"
  },
  {
    "value": "42326",
    "label": "Browder - KY 42326",
    "county": "Muhlenberg County",
    "state": "KY",
    "city": "Browder"
  },
  {
    "value": "42327",
    "label": "Calhoun - KY 42327",
    "county": "McLean County",
    "state": "KY",
    "city": "Calhoun"
  },
  {
    "value": "42328",
    "label": "Centertown - KY 42328",
    "county": "Ohio County",
    "state": "KY",
    "city": "Centertown"
  },
  {
    "value": "42330",
    "label": "Central City - KY 42330",
    "county": "Muhlenberg County",
    "state": "KY",
    "city": "Central City"
  },
  {
    "value": "42333",
    "label": "Cromwell - KY 42333",
    "county": "Ohio County",
    "state": "KY",
    "city": "Cromwell"
  },
  {
    "value": "42337",
    "label": "Drakesboro - KY 42337",
    "county": "Muhlenberg County",
    "state": "KY",
    "city": "Drakesboro"
  },
  {
    "value": "42338",
    "label": "Dundee - KY 42338",
    "county": "Ohio County",
    "state": "KY",
    "city": "Dundee"
  },
  {
    "value": "42339",
    "label": "Dunmor - KY 42339",
    "county": "Muhlenberg County",
    "state": "KY",
    "city": "Dunmor"
  },
  {
    "value": "42343",
    "label": "Fordsville - KY 42343",
    "county": "Ohio County",
    "state": "KY",
    "city": "Fordsville"
  },
  {
    "value": "42344",
    "label": "Graham - KY 42344",
    "county": "Muhlenberg County",
    "state": "KY",
    "city": "Graham"
  },
  {
    "value": "42345",
    "label": "Greenville - KY 42345",
    "county": "Muhlenberg County",
    "state": "KY",
    "city": "Greenville"
  },
  {
    "value": "42347",
    "label": "Hartford - KY 42347",
    "county": "Ohio County",
    "state": "KY",
    "city": "Hartford"
  },
  {
    "value": "42348",
    "label": "Hawesville - KY 42348",
    "county": "Hancock County",
    "state": "KY",
    "city": "Hawesville"
  },
  {
    "value": "42349",
    "label": "Horse Branch - KY 42349",
    "county": "Ohio County",
    "state": "KY",
    "city": "Horse Branch"
  },
  {
    "value": "42350",
    "label": "Island - KY 42350",
    "county": "McLean County",
    "state": "KY",
    "city": "Island"
  },
  {
    "value": "42351",
    "label": "Lewisport - KY 42351",
    "county": "Hancock County",
    "state": "KY",
    "city": "Lewisport"
  },
  {
    "value": "42352",
    "label": "Livermore - KY 42352",
    "county": "McLean County",
    "state": "KY",
    "city": "Livermore"
  },
  {
    "value": "42354",
    "label": "Mc Henry - KY 42354",
    "county": "Ohio County",
    "state": "KY",
    "city": "Mc Henry"
  },
  {
    "value": "42355",
    "label": "Maceo - KY 42355",
    "county": "Daviess County",
    "state": "KY",
    "city": "Maceo"
  },
  {
    "value": "42361",
    "label": "Olaton - KY 42361",
    "county": "Ohio County",
    "state": "KY",
    "city": "Olaton"
  },
  {
    "value": "42366",
    "label": "Philpot - KY 42366",
    "county": "Daviess County",
    "state": "KY",
    "city": "Philpot"
  },
  {
    "value": "42367",
    "label": "Powderly - KY 42367",
    "county": "Muhlenberg County",
    "state": "KY",
    "city": "Powderly"
  },
  {
    "value": "42368",
    "label": "Reynolds Station - KY 42368",
    "county": "Ohio County",
    "state": "KY",
    "city": "Reynolds Station"
  },
  {
    "value": "42369",
    "label": "Rockport - KY 42369",
    "county": "Ohio County",
    "state": "KY",
    "city": "Rockport"
  },
  {
    "value": "42371",
    "label": "Rumsey - KY 42371",
    "county": "McLean County",
    "state": "KY",
    "city": "Rumsey"
  },
  {
    "value": "42372",
    "label": "Sacramento - KY 42372",
    "county": "McLean County",
    "state": "KY",
    "city": "Sacramento"
  },
  {
    "value": "42375",
    "label": "Stanley - KY 42375",
    "county": "Daviess County",
    "state": "KY",
    "city": "Stanley"
  },
  {
    "value": "42376",
    "label": "Utica - KY 42376",
    "county": "Daviess County",
    "state": "KY",
    "city": "Utica"
  },
  {
    "value": "42378",
    "label": "Whitesville - KY 42378",
    "county": "Daviess County",
    "state": "KY",
    "city": "Whitesville"
  },
  {
    "value": "42403",
    "label": "Blackford - KY 42403",
    "county": "Webster County",
    "state": "KY",
    "city": "Blackford"
  },
  {
    "value": "42404",
    "label": "Clay - KY 42404",
    "county": "Webster County",
    "state": "KY",
    "city": "Clay"
  },
  {
    "value": "42406",
    "label": "Corydon - KY 42406",
    "county": "Henderson County",
    "state": "KY",
    "city": "Corydon"
  },
  {
    "value": "42408",
    "label": "Dawson Springs - KY 42408",
    "county": "Hopkins County",
    "state": "KY",
    "city": "Dawson Springs"
  },
  {
    "value": "42409",
    "label": "Dixon - KY 42409",
    "county": "Webster County",
    "state": "KY",
    "city": "Dixon"
  },
  {
    "value": "42410",
    "label": "Earlington - KY 42410",
    "county": "Hopkins County",
    "state": "KY",
    "city": "Earlington"
  },
  {
    "value": "42411",
    "label": "Fredonia - KY 42411",
    "county": "Caldwell County",
    "state": "KY",
    "city": "Fredonia"
  },
  {
    "value": "42413",
    "label": "Hanson - KY 42413",
    "county": "Hopkins County",
    "state": "KY",
    "city": "Hanson"
  },
  {
    "value": "42420",
    "label": "Henderson - KY 42420",
    "county": "Henderson County",
    "state": "KY",
    "city": "Henderson"
  },
  {
    "value": "42431",
    "label": "Madisonville - KY 42431",
    "county": "Hopkins County",
    "state": "KY",
    "city": "Madisonville"
  },
  {
    "value": "42436",
    "label": "Manitou - KY 42436",
    "county": "Hopkins County",
    "state": "KY",
    "city": "Manitou"
  },
  {
    "value": "42437",
    "label": "Morganfield - KY 42437",
    "county": "Union County",
    "state": "KY",
    "city": "Morganfield"
  },
  {
    "value": "42441",
    "label": "Nebo - KY 42441",
    "county": "Hopkins County",
    "state": "KY",
    "city": "Nebo"
  },
  {
    "value": "42442",
    "label": "Nortonville - KY 42442",
    "county": "Hopkins County",
    "state": "KY",
    "city": "Nortonville"
  },
  {
    "value": "42445",
    "label": "Princeton - KY 42445",
    "county": "Caldwell County",
    "state": "KY",
    "city": "Princeton"
  },
  {
    "value": "42450",
    "label": "Providence - KY 42450",
    "county": "Webster County",
    "state": "KY",
    "city": "Providence"
  },
  {
    "value": "42451",
    "label": "Reed - KY 42451",
    "county": "Henderson County",
    "state": "KY",
    "city": "Reed"
  },
  {
    "value": "42452",
    "label": "Robards - KY 42452",
    "county": "Henderson County",
    "state": "KY",
    "city": "Robards"
  },
  {
    "value": "42453",
    "label": "Saint Charles - KY 42453",
    "county": "Hopkins County",
    "state": "KY",
    "city": "Saint Charles"
  },
  {
    "value": "42455",
    "label": "Sebree - KY 42455",
    "county": "Webster County",
    "state": "KY",
    "city": "Sebree"
  },
  {
    "value": "42456",
    "label": "Slaughters - KY 42456",
    "county": "Webster County",
    "state": "KY",
    "city": "Slaughters"
  },
  {
    "value": "42458",
    "label": "Spottsville - KY 42458",
    "county": "Henderson County",
    "state": "KY",
    "city": "Spottsville"
  },
  {
    "value": "42459",
    "label": "Sturgis - KY 42459",
    "county": "Union County",
    "state": "KY",
    "city": "Sturgis"
  },
  {
    "value": "42461",
    "label": "Uniontown - KY 42461",
    "county": "Union County",
    "state": "KY",
    "city": "Uniontown"
  },
  {
    "value": "42462",
    "label": "Waverly - KY 42462",
    "county": "Union County",
    "state": "KY",
    "city": "Waverly"
  },
  {
    "value": "42464",
    "label": "White Plains - KY 42464",
    "county": "Hopkins County",
    "state": "KY",
    "city": "White Plains"
  },
  {
    "value": "42501",
    "label": "Somerset - KY 42501",
    "county": "Pulaski County",
    "state": "KY",
    "city": "Somerset"
  },
  {
    "value": "42503",
    "label": "Somerset - KY 42503",
    "county": "Pulaski County",
    "state": "KY",
    "city": "Somerset"
  },
  {
    "value": "42516",
    "label": "Bethelridge - KY 42516",
    "county": "Casey County",
    "state": "KY",
    "city": "Bethelridge"
  },
  {
    "value": "42518",
    "label": "Bronston - KY 42518",
    "county": "Pulaski County",
    "state": "KY",
    "city": "Bronston"
  },
  {
    "value": "42519",
    "label": "Burnside - KY 42519",
    "county": "Pulaski County",
    "state": "KY",
    "city": "Burnside"
  },
  {
    "value": "42528",
    "label": "Dunnville - KY 42528",
    "county": "Casey County",
    "state": "KY",
    "city": "Dunnville"
  },
  {
    "value": "42533",
    "label": "Ferguson - KY 42533",
    "county": "Pulaski County",
    "state": "KY",
    "city": "Ferguson"
  },
  {
    "value": "42539",
    "label": "Liberty - KY 42539",
    "county": "Casey County",
    "state": "KY",
    "city": "Liberty"
  },
  {
    "value": "42541",
    "label": "Middleburg - KY 42541",
    "county": "Casey County",
    "state": "KY",
    "city": "Middleburg"
  },
  {
    "value": "42544",
    "label": "Nancy - KY 42544",
    "county": "Pulaski County",
    "state": "KY",
    "city": "Nancy"
  },
  {
    "value": "42553",
    "label": "Science Hill - KY 42553",
    "county": "Pulaski County",
    "state": "KY",
    "city": "Science Hill"
  },
  {
    "value": "42565",
    "label": "Windsor - KY 42565",
    "county": "Casey County",
    "state": "KY",
    "city": "Windsor"
  },
  {
    "value": "42566",
    "label": "Yosemite - KY 42566",
    "county": "Casey County",
    "state": "KY",
    "city": "Yosemite"
  },
  {
    "value": "42567",
    "label": "Eubank - KY 42567",
    "county": "Pulaski County",
    "state": "KY",
    "city": "Eubank"
  },
  {
    "value": "42602",
    "label": "Albany - KY 42602",
    "county": "Clinton County",
    "state": "KY",
    "city": "Albany"
  },
  {
    "value": "42603",
    "label": "Alpha - KY 42603",
    "county": "Clinton County",
    "state": "KY",
    "city": "Alpha"
  },
  {
    "value": "42629",
    "label": "Jamestown - KY 42629",
    "county": "Russell County",
    "state": "KY",
    "city": "Jamestown"
  },
  {
    "value": "42633",
    "label": "Monticello - KY 42633",
    "county": "Wayne County",
    "state": "KY",
    "city": "Monticello"
  },
  {
    "value": "42634",
    "label": "Parkers Lake - KY 42634",
    "county": "McCreary County",
    "state": "KY",
    "city": "Parkers Lake"
  },
  {
    "value": "42635",
    "label": "Pine Knot - KY 42635",
    "county": "McCreary County",
    "state": "KY",
    "city": "Pine Knot"
  },
  {
    "value": "42642",
    "label": "Russell Springs - KY 42642",
    "county": "Russell County",
    "state": "KY",
    "city": "Russell Springs"
  },
  {
    "value": "42647",
    "label": "Stearns - KY 42647",
    "county": "McCreary County",
    "state": "KY",
    "city": "Stearns"
  },
  {
    "value": "42649",
    "label": "Strunk - KY 42649",
    "county": "McCreary County",
    "state": "KY",
    "city": "Strunk"
  },
  {
    "value": "42653",
    "label": "Whitley City - KY 42653",
    "county": "McCreary County",
    "state": "KY",
    "city": "Whitley City"
  },
  {
    "value": "42701",
    "label": "Elizabethtown - KY 42701",
    "county": "Hardin County",
    "state": "KY",
    "city": "Elizabethtown"
  },
  {
    "value": "42712",
    "label": "Big Clifty - KY 42712",
    "county": "Grayson County",
    "state": "KY",
    "city": "Big Clifty"
  },
  {
    "value": "42713",
    "label": "Bonnieville - KY 42713",
    "county": "Hart County",
    "state": "KY",
    "city": "Bonnieville"
  },
  {
    "value": "42715",
    "label": "Breeding - KY 42715",
    "county": "Adair County",
    "state": "KY",
    "city": "Breeding"
  },
  {
    "value": "42716",
    "label": "Buffalo - KY 42716",
    "county": "Larue County",
    "state": "KY",
    "city": "Buffalo"
  },
  {
    "value": "42717",
    "label": "Burkesville - KY 42717",
    "county": "Cumberland County",
    "state": "KY",
    "city": "Burkesville"
  },
  {
    "value": "42718",
    "label": "Campbellsville - KY 42718",
    "county": "Taylor County",
    "state": "KY",
    "city": "Campbellsville"
  },
  {
    "value": "42721",
    "label": "Caneyville - KY 42721",
    "county": "Grayson County",
    "state": "KY",
    "city": "Caneyville"
  },
  {
    "value": "42722",
    "label": "Canmer - KY 42722",
    "county": "Hart County",
    "state": "KY",
    "city": "Canmer"
  },
  {
    "value": "42724",
    "label": "Cecilia - KY 42724",
    "county": "Hardin County",
    "state": "KY",
    "city": "Cecilia"
  },
  {
    "value": "42726",
    "label": "Clarkson - KY 42726",
    "county": "Grayson County",
    "state": "KY",
    "city": "Clarkson"
  },
  {
    "value": "42728",
    "label": "Columbia - KY 42728",
    "county": "Adair County",
    "state": "KY",
    "city": "Columbia"
  },
  {
    "value": "42729",
    "label": "Cub Run - KY 42729",
    "county": "Hart County",
    "state": "KY",
    "city": "Cub Run"
  },
  {
    "value": "42731",
    "label": "Dubre - KY 42731",
    "county": "Cumberland County",
    "state": "KY",
    "city": "Dubre"
  },
  {
    "value": "42732",
    "label": "Eastview - KY 42732",
    "county": "Hardin County",
    "state": "KY",
    "city": "Eastview"
  },
  {
    "value": "42733",
    "label": "Elk Horn - KY 42733",
    "county": "Taylor County",
    "state": "KY",
    "city": "Elk Horn"
  },
  {
    "value": "42740",
    "label": "Glendale - KY 42740",
    "county": "Hardin County",
    "state": "KY",
    "city": "Glendale"
  },
  {
    "value": "42741",
    "label": "Glens Fork - KY 42741",
    "county": "Adair County",
    "state": "KY",
    "city": "Glens Fork"
  },
  {
    "value": "42742",
    "label": "Gradyville - KY 42742",
    "county": "Adair County",
    "state": "KY",
    "city": "Gradyville"
  },
  {
    "value": "42743",
    "label": "Greensburg - KY 42743",
    "county": "Green County",
    "state": "KY",
    "city": "Greensburg"
  },
  {
    "value": "42746",
    "label": "Hardyville - KY 42746",
    "county": "Hart County",
    "state": "KY",
    "city": "Hardyville"
  },
  {
    "value": "42748",
    "label": "Hodgenville - KY 42748",
    "county": "Larue County",
    "state": "KY",
    "city": "Hodgenville"
  },
  {
    "value": "42749",
    "label": "Horse Cave - KY 42749",
    "county": "Hart County",
    "state": "KY",
    "city": "Horse Cave"
  },
  {
    "value": "42753",
    "label": "Knifley - KY 42753",
    "county": "Adair County",
    "state": "KY",
    "city": "Knifley"
  },
  {
    "value": "42754",
    "label": "Leitchfield - KY 42754",
    "county": "Grayson County",
    "state": "KY",
    "city": "Leitchfield"
  },
  {
    "value": "42757",
    "label": "Magnolia - KY 42757",
    "county": "Hart County",
    "state": "KY",
    "city": "Magnolia"
  },
  {
    "value": "42762",
    "label": "Millwood - KY 42762",
    "county": "Grayson County",
    "state": "KY",
    "city": "Millwood"
  },
  {
    "value": "42764",
    "label": "Mount Sherman - KY 42764",
    "county": "Green County",
    "state": "KY",
    "city": "Mount Sherman"
  },
  {
    "value": "42765",
    "label": "Munfordville - KY 42765",
    "county": "Hart County",
    "state": "KY",
    "city": "Munfordville"
  },
  {
    "value": "42776",
    "label": "Sonora - KY 42776",
    "county": "Hardin County",
    "state": "KY",
    "city": "Sonora"
  },
  {
    "value": "42782",
    "label": "Summersville - KY 42782",
    "county": "Green County",
    "state": "KY",
    "city": "Summersville"
  },
  {
    "value": "42784",
    "label": "Upton - KY 42784",
    "county": "Hardin County",
    "state": "KY",
    "city": "Upton"
  },
  {
    "value": "42788",
    "label": "White Mills - KY 42788",
    "county": "Hardin County",
    "state": "KY",
    "city": "White Mills"
  },
  {
    "value": "70001",
    "label": "Metairie - LA 70001",
    "county": "Jefferson Parish",
    "state": "LA",
    "city": "Metairie"
  },
  {
    "value": "70002",
    "label": "Metairie - LA 70002",
    "county": "Jefferson Parish",
    "state": "LA",
    "city": "Metairie"
  },
  {
    "value": "70003",
    "label": "Metairie - LA 70003",
    "county": "Jefferson Parish",
    "state": "LA",
    "city": "Metairie"
  },
  {
    "value": "70005",
    "label": "Metairie - LA 70005",
    "county": "Jefferson Parish",
    "state": "LA",
    "city": "Metairie"
  },
  {
    "value": "70006",
    "label": "Metairie - LA 70006",
    "county": "Jefferson Parish",
    "state": "LA",
    "city": "Metairie"
  },
  {
    "value": "70030",
    "label": "Des Allemands - LA 70030",
    "county": "St. Charles Parish",
    "state": "LA",
    "city": "Des Allemands"
  },
  {
    "value": "70031",
    "label": "Ama - LA 70031",
    "county": "St. Charles Parish",
    "state": "LA",
    "city": "Ama"
  },
  {
    "value": "70032",
    "label": "Arabi - LA 70032",
    "county": "St. Bernard Parish",
    "state": "LA",
    "city": "Arabi"
  },
  {
    "value": "70036",
    "label": "Barataria - LA 70036",
    "county": "Jefferson Parish",
    "state": "LA",
    "city": "Barataria"
  },
  {
    "value": "70037",
    "label": "Belle Chasse - LA 70037",
    "county": "Plaquemines Parish",
    "state": "LA",
    "city": "Belle Chasse"
  },
  {
    "value": "70039",
    "label": "Boutte - LA 70039",
    "county": "St. Charles Parish",
    "state": "LA",
    "city": "Boutte"
  },
  {
    "value": "70040",
    "label": "Braithwaite - LA 70040",
    "county": "Plaquemines Parish",
    "state": "LA",
    "city": "Braithwaite"
  },
  {
    "value": "70041",
    "label": "Buras - LA 70041",
    "county": "Plaquemines Parish",
    "state": "LA",
    "city": "Buras"
  },
  {
    "value": "70043",
    "label": "Chalmette - LA 70043",
    "county": "St. Bernard Parish",
    "state": "LA",
    "city": "Chalmette"
  },
  {
    "value": "70047",
    "label": "Destrehan - LA 70047",
    "county": "St. Charles Parish",
    "state": "LA",
    "city": "Destrehan"
  },
  {
    "value": "70049",
    "label": "Edgard - LA 70049",
    "county": "St. John the Baptist Parish",
    "state": "LA",
    "city": "Edgard"
  },
  {
    "value": "70051",
    "label": "Garyville - LA 70051",
    "county": "St. John the Baptist Parish",
    "state": "LA",
    "city": "Garyville"
  },
  {
    "value": "70052",
    "label": "Gramercy - LA 70052",
    "county": "St. James Parish",
    "state": "LA",
    "city": "Gramercy"
  },
  {
    "value": "70053",
    "label": "Gretna - LA 70053",
    "county": "Jefferson Parish",
    "state": "LA",
    "city": "Gretna"
  },
  {
    "value": "70056",
    "label": "Gretna - LA 70056",
    "county": "Jefferson Parish",
    "state": "LA",
    "city": "Gretna"
  },
  {
    "value": "70057",
    "label": "Hahnville - LA 70057",
    "county": "St. Charles Parish",
    "state": "LA",
    "city": "Hahnville"
  },
  {
    "value": "70058",
    "label": "Harvey - LA 70058",
    "county": "Jefferson Parish",
    "state": "LA",
    "city": "Harvey"
  },
  {
    "value": "70062",
    "label": "Kenner - LA 70062",
    "county": "Jefferson Parish",
    "state": "LA",
    "city": "Kenner"
  },
  {
    "value": "70065",
    "label": "Kenner - LA 70065",
    "county": "Jefferson Parish",
    "state": "LA",
    "city": "Kenner"
  },
  {
    "value": "70067",
    "label": "Lafitte - LA 70067",
    "county": "Jefferson Parish",
    "state": "LA",
    "city": "Lafitte"
  },
  {
    "value": "70068",
    "label": "La Place - LA 70068",
    "county": "St. John the Baptist Parish",
    "state": "LA",
    "city": "La Place"
  },
  {
    "value": "70070",
    "label": "Luling - LA 70070",
    "county": "St. Charles Parish",
    "state": "LA",
    "city": "Luling"
  },
  {
    "value": "70071",
    "label": "Lutcher - LA 70071",
    "county": "St. James Parish",
    "state": "LA",
    "city": "Lutcher"
  },
  {
    "value": "70072",
    "label": "Marrero - LA 70072",
    "county": "Jefferson Parish",
    "state": "LA",
    "city": "Marrero"
  },
  {
    "value": "70075",
    "label": "Meraux - LA 70075",
    "county": "St. Bernard Parish",
    "state": "LA",
    "city": "Meraux"
  },
  {
    "value": "70076",
    "label": "Mount Airy - LA 70076",
    "county": "St. John the Baptist Parish",
    "state": "LA",
    "city": "Mount Airy"
  },
  {
    "value": "70079",
    "label": "Norco - LA 70079",
    "county": "St. Charles Parish",
    "state": "LA",
    "city": "Norco"
  },
  {
    "value": "70080",
    "label": "Paradis - LA 70080",
    "county": "St. Charles Parish",
    "state": "LA",
    "city": "Paradis"
  },
  {
    "value": "70083",
    "label": "Port Sulphur - LA 70083",
    "county": "Plaquemines Parish",
    "state": "LA",
    "city": "Port Sulphur"
  },
  {
    "value": "70084",
    "label": "Reserve - LA 70084",
    "county": "St. John the Baptist Parish",
    "state": "LA",
    "city": "Reserve"
  },
  {
    "value": "70085",
    "label": "Saint Bernard - LA 70085",
    "county": "St. Bernard Parish",
    "state": "LA",
    "city": "Saint Bernard"
  },
  {
    "value": "70086",
    "label": "Saint James - LA 70086",
    "county": "St. James Parish",
    "state": "LA",
    "city": "Saint James"
  },
  {
    "value": "70087",
    "label": "Saint Rose - LA 70087",
    "county": "St. Charles Parish",
    "state": "LA",
    "city": "Saint Rose"
  },
  {
    "value": "70090",
    "label": "Vacherie - LA 70090",
    "county": "St. James Parish",
    "state": "LA",
    "city": "Vacherie"
  },
  {
    "value": "70091",
    "label": "Venice - LA 70091",
    "county": "Plaquemines Parish",
    "state": "LA",
    "city": "Venice"
  },
  {
    "value": "70092",
    "label": "Violet - LA 70092",
    "county": "St. Bernard Parish",
    "state": "LA",
    "city": "Violet"
  },
  {
    "value": "70094",
    "label": "Westwego - LA 70094",
    "county": "Jefferson Parish",
    "state": "LA",
    "city": "Westwego"
  },
  {
    "value": "70112",
    "label": "New Orleans - LA 70112",
    "county": "Orleans Parish",
    "state": "LA",
    "city": "New Orleans"
  },
  {
    "value": "70113",
    "label": "New Orleans - LA 70113",
    "county": "Orleans Parish",
    "state": "LA",
    "city": "New Orleans"
  },
  {
    "value": "70114",
    "label": "New Orleans - LA 70114",
    "county": "Orleans Parish",
    "state": "LA",
    "city": "New Orleans"
  },
  {
    "value": "70115",
    "label": "New Orleans - LA 70115",
    "county": "Orleans Parish",
    "state": "LA",
    "city": "New Orleans"
  },
  {
    "value": "70116",
    "label": "New Orleans - LA 70116",
    "county": "Orleans Parish",
    "state": "LA",
    "city": "New Orleans"
  },
  {
    "value": "70117",
    "label": "New Orleans - LA 70117",
    "county": "Orleans Parish",
    "state": "LA",
    "city": "New Orleans"
  },
  {
    "value": "70118",
    "label": "New Orleans - LA 70118",
    "county": "Orleans Parish",
    "state": "LA",
    "city": "New Orleans"
  },
  {
    "value": "70119",
    "label": "New Orleans - LA 70119",
    "county": "Orleans Parish",
    "state": "LA",
    "city": "New Orleans"
  },
  {
    "value": "70121",
    "label": "New Orleans - LA 70121",
    "county": "Jefferson Parish",
    "state": "LA",
    "city": "New Orleans"
  },
  {
    "value": "70122",
    "label": "New Orleans - LA 70122",
    "county": "Orleans Parish",
    "state": "LA",
    "city": "New Orleans"
  },
  {
    "value": "70123",
    "label": "New Orleans - LA 70123",
    "county": "Jefferson Parish",
    "state": "LA",
    "city": "New Orleans"
  },
  {
    "value": "70124",
    "label": "New Orleans - LA 70124",
    "county": "Orleans Parish",
    "state": "LA",
    "city": "New Orleans"
  },
  {
    "value": "70125",
    "label": "New Orleans - LA 70125",
    "county": "Orleans Parish",
    "state": "LA",
    "city": "New Orleans"
  },
  {
    "value": "70126",
    "label": "New Orleans - LA 70126",
    "county": "Orleans Parish",
    "state": "LA",
    "city": "New Orleans"
  },
  {
    "value": "70127",
    "label": "New Orleans - LA 70127",
    "county": "Orleans Parish",
    "state": "LA",
    "city": "New Orleans"
  },
  {
    "value": "70128",
    "label": "New Orleans - LA 70128",
    "county": "Orleans Parish",
    "state": "LA",
    "city": "New Orleans"
  },
  {
    "value": "70129",
    "label": "New Orleans - LA 70129",
    "county": "Orleans Parish",
    "state": "LA",
    "city": "New Orleans"
  },
  {
    "value": "70130",
    "label": "New Orleans - LA 70130",
    "county": "Orleans Parish",
    "state": "LA",
    "city": "New Orleans"
  },
  {
    "value": "70131",
    "label": "New Orleans - LA 70131",
    "county": "Orleans Parish",
    "state": "LA",
    "city": "New Orleans"
  },
  {
    "value": "70139",
    "label": "New Orleans - LA 70139",
    "county": "Orleans Parish",
    "state": "LA",
    "city": "New Orleans"
  },
  {
    "value": "70140",
    "label": "New Orleans - LA 70140",
    "county": "Orleans Parish",
    "state": "LA",
    "city": "New Orleans"
  },
  {
    "value": "70145",
    "label": "New Orleans - LA 70145",
    "county": "Orleans Parish",
    "state": "LA",
    "city": "New Orleans"
  },
  {
    "value": "70146",
    "label": "New Orleans - LA 70146",
    "county": "Orleans Parish",
    "state": "LA",
    "city": "New Orleans"
  },
  {
    "value": "70149",
    "label": "New Orleans - LA 70149",
    "county": "Orleans Parish",
    "state": "LA",
    "city": "New Orleans"
  },
  {
    "value": "70163",
    "label": "New Orleans - LA 70163",
    "county": "Orleans Parish",
    "state": "LA",
    "city": "New Orleans"
  },
  {
    "value": "70170",
    "label": "New Orleans - LA 70170",
    "county": "Orleans Parish",
    "state": "LA",
    "city": "New Orleans"
  },
  {
    "value": "70301",
    "label": "Thibodaux - LA 70301",
    "county": "Lafourche Parish",
    "state": "LA",
    "city": "Thibodaux"
  },
  {
    "value": "70339",
    "label": "Pierre Part - LA 70339",
    "county": "Assumption Parish",
    "state": "LA",
    "city": "Pierre Part"
  },
  {
    "value": "70341",
    "label": "Belle Rose - LA 70341",
    "county": "Assumption Parish",
    "state": "LA",
    "city": "Belle Rose"
  },
  {
    "value": "70342",
    "label": "Berwick - LA 70342",
    "county": "St. Mary Parish",
    "state": "LA",
    "city": "Berwick"
  },
  {
    "value": "70343",
    "label": "Bourg - LA 70343",
    "county": "Terrebonne Parish",
    "state": "LA",
    "city": "Bourg"
  },
  {
    "value": "70344",
    "label": "Chauvin - LA 70344",
    "county": "Terrebonne Parish",
    "state": "LA",
    "city": "Chauvin"
  },
  {
    "value": "70345",
    "label": "Cut Off - LA 70345",
    "county": "Lafourche Parish",
    "state": "LA",
    "city": "Cut Off"
  },
  {
    "value": "70346",
    "label": "Donaldsonville - LA 70346",
    "county": "Ascension Parish",
    "state": "LA",
    "city": "Donaldsonville"
  },
  {
    "value": "70352",
    "label": "Donner - LA 70352",
    "county": "Terrebonne Parish",
    "state": "LA",
    "city": "Donner"
  },
  {
    "value": "70353",
    "label": "Dulac - LA 70353",
    "county": "Terrebonne Parish",
    "state": "LA",
    "city": "Dulac"
  },
  {
    "value": "70354",
    "label": "Galliano - LA 70354",
    "county": "Lafourche Parish",
    "state": "LA",
    "city": "Galliano"
  },
  {
    "value": "70355",
    "label": "Gheens - LA 70355",
    "county": "Lafourche Parish",
    "state": "LA",
    "city": "Gheens"
  },
  {
    "value": "70356",
    "label": "Gibson - LA 70356",
    "county": "Terrebonne Parish",
    "state": "LA",
    "city": "Gibson"
  },
  {
    "value": "70357",
    "label": "Golden Meadow - LA 70357",
    "county": "Lafourche Parish",
    "state": "LA",
    "city": "Golden Meadow"
  },
  {
    "value": "70358",
    "label": "Grand Isle - LA 70358",
    "county": "Jefferson Parish",
    "state": "LA",
    "city": "Grand Isle"
  },
  {
    "value": "70359",
    "label": "Gray - LA 70359",
    "county": "Terrebonne Parish",
    "state": "LA",
    "city": "Gray"
  },
  {
    "value": "70360",
    "label": "Houma - LA 70360",
    "county": "Terrebonne Parish",
    "state": "LA",
    "city": "Houma"
  },
  {
    "value": "70363",
    "label": "Houma - LA 70363",
    "county": "Terrebonne Parish",
    "state": "LA",
    "city": "Houma"
  },
  {
    "value": "70364",
    "label": "Houma - LA 70364",
    "county": "Terrebonne Parish",
    "state": "LA",
    "city": "Houma"
  },
  {
    "value": "70372",
    "label": "Labadieville - LA 70372",
    "county": "Assumption Parish",
    "state": "LA",
    "city": "Labadieville"
  },
  {
    "value": "70373",
    "label": "Larose - LA 70373",
    "county": "Lafourche Parish",
    "state": "LA",
    "city": "Larose"
  },
  {
    "value": "70374",
    "label": "Lockport - LA 70374",
    "county": "Lafourche Parish",
    "state": "LA",
    "city": "Lockport"
  },
  {
    "value": "70375",
    "label": "Mathews - LA 70375",
    "county": "Lafourche Parish",
    "state": "LA",
    "city": "Mathews"
  },
  {
    "value": "70377",
    "label": "Montegut - LA 70377",
    "county": "Terrebonne Parish",
    "state": "LA",
    "city": "Montegut"
  },
  {
    "value": "70380",
    "label": "Morgan City - LA 70380",
    "county": "St. Mary Parish",
    "state": "LA",
    "city": "Morgan City"
  },
  {
    "value": "70390",
    "label": "Napoleonville - LA 70390",
    "county": "Assumption Parish",
    "state": "LA",
    "city": "Napoleonville"
  },
  {
    "value": "70392",
    "label": "Patterson - LA 70392",
    "county": "St. Mary Parish",
    "state": "LA",
    "city": "Patterson"
  },
  {
    "value": "70393",
    "label": "Plattenville - LA 70393",
    "county": "Assumption Parish",
    "state": "LA",
    "city": "Plattenville"
  },
  {
    "value": "70394",
    "label": "Raceland - LA 70394",
    "county": "Lafourche Parish",
    "state": "LA",
    "city": "Raceland"
  },
  {
    "value": "70395",
    "label": "Schriever - LA 70395",
    "county": "Terrebonne Parish",
    "state": "LA",
    "city": "Schriever"
  },
  {
    "value": "70397",
    "label": "Theriot - LA 70397",
    "county": "Terrebonne Parish",
    "state": "LA",
    "city": "Theriot"
  },
  {
    "value": "70401",
    "label": "Hammond - LA 70401",
    "county": "Tangipahoa Parish",
    "state": "LA",
    "city": "Hammond"
  },
  {
    "value": "70403",
    "label": "Hammond - LA 70403",
    "county": "Tangipahoa Parish",
    "state": "LA",
    "city": "Hammond"
  },
  {
    "value": "70420",
    "label": "Abita Springs - LA 70420",
    "county": "St. Tammany Parish",
    "state": "LA",
    "city": "Abita Springs"
  },
  {
    "value": "70421",
    "label": "Akers - LA 70421",
    "county": "Tangipahoa Parish",
    "state": "LA",
    "city": "Akers"
  },
  {
    "value": "70422",
    "label": "Amite - LA 70422",
    "county": "Tangipahoa Parish",
    "state": "LA",
    "city": "Amite"
  },
  {
    "value": "70426",
    "label": "Angie - LA 70426",
    "county": "Washington Parish",
    "state": "LA",
    "city": "Angie"
  },
  {
    "value": "70427",
    "label": "Bogalusa - LA 70427",
    "county": "Washington Parish",
    "state": "LA",
    "city": "Bogalusa"
  },
  {
    "value": "70431",
    "label": "Bush - LA 70431",
    "county": "St. Tammany Parish",
    "state": "LA",
    "city": "Bush"
  },
  {
    "value": "70433",
    "label": "Covington - LA 70433",
    "county": "St. Tammany Parish",
    "state": "LA",
    "city": "Covington"
  },
  {
    "value": "70435",
    "label": "Covington - LA 70435",
    "county": "St. Tammany Parish",
    "state": "LA",
    "city": "Covington"
  },
  {
    "value": "70436",
    "label": "Fluker - LA 70436",
    "county": "Tangipahoa Parish",
    "state": "LA",
    "city": "Fluker"
  },
  {
    "value": "70437",
    "label": "Folsom - LA 70437",
    "county": "St. Tammany Parish",
    "state": "LA",
    "city": "Folsom"
  },
  {
    "value": "70438",
    "label": "Franklinton - LA 70438",
    "county": "Washington Parish",
    "state": "LA",
    "city": "Franklinton"
  },
  {
    "value": "70441",
    "label": "Greensburg - LA 70441",
    "county": "St. Helena Parish",
    "state": "LA",
    "city": "Greensburg"
  },
  {
    "value": "70442",
    "label": "Husser - LA 70442",
    "county": "Tangipahoa Parish",
    "state": "LA",
    "city": "Husser"
  },
  {
    "value": "70443",
    "label": "Independence - LA 70443",
    "county": "Tangipahoa Parish",
    "state": "LA",
    "city": "Independence"
  },
  {
    "value": "70444",
    "label": "Kentwood - LA 70444",
    "county": "Tangipahoa Parish",
    "state": "LA",
    "city": "Kentwood"
  },
  {
    "value": "70445",
    "label": "Lacombe - LA 70445",
    "county": "St. Tammany Parish",
    "state": "LA",
    "city": "Lacombe"
  },
  {
    "value": "70446",
    "label": "Loranger - LA 70446",
    "county": "Tangipahoa Parish",
    "state": "LA",
    "city": "Loranger"
  },
  {
    "value": "70447",
    "label": "Madisonville - LA 70447",
    "county": "St. Tammany Parish",
    "state": "LA",
    "city": "Madisonville"
  },
  {
    "value": "70448",
    "label": "Mandeville - LA 70448",
    "county": "St. Tammany Parish",
    "state": "LA",
    "city": "Mandeville"
  },
  {
    "value": "70449",
    "label": "Maurepas - LA 70449",
    "county": "Livingston Parish",
    "state": "LA",
    "city": "Maurepas"
  },
  {
    "value": "70450",
    "label": "Mount Hermon - LA 70450",
    "county": "Washington Parish",
    "state": "LA",
    "city": "Mount Hermon"
  },
  {
    "value": "70452",
    "label": "Pearl River - LA 70452",
    "county": "St. Tammany Parish",
    "state": "LA",
    "city": "Pearl River"
  },
  {
    "value": "70453",
    "label": "Pine Grove - LA 70453",
    "county": "St. Helena Parish",
    "state": "LA",
    "city": "Pine Grove"
  },
  {
    "value": "70454",
    "label": "Ponchatoula - LA 70454",
    "county": "Tangipahoa Parish",
    "state": "LA",
    "city": "Ponchatoula"
  },
  {
    "value": "70455",
    "label": "Robert - LA 70455",
    "county": "Tangipahoa Parish",
    "state": "LA",
    "city": "Robert"
  },
  {
    "value": "70456",
    "label": "Roseland - LA 70456",
    "county": "Tangipahoa Parish",
    "state": "LA",
    "city": "Roseland"
  },
  {
    "value": "70458",
    "label": "Slidell - LA 70458",
    "county": "St. Tammany Parish",
    "state": "LA",
    "city": "Slidell"
  },
  {
    "value": "70460",
    "label": "Slidell - LA 70460",
    "county": "St. Tammany Parish",
    "state": "LA",
    "city": "Slidell"
  },
  {
    "value": "70461",
    "label": "Slidell - LA 70461",
    "county": "St. Tammany Parish",
    "state": "LA",
    "city": "Slidell"
  },
  {
    "value": "70462",
    "label": "Springfield - LA 70462",
    "county": "Livingston Parish",
    "state": "LA",
    "city": "Springfield"
  },
  {
    "value": "70466",
    "label": "Tickfaw - LA 70466",
    "county": "Tangipahoa Parish",
    "state": "LA",
    "city": "Tickfaw"
  },
  {
    "value": "70471",
    "label": "Mandeville - LA 70471",
    "county": "St. Tammany Parish",
    "state": "LA",
    "city": "Mandeville"
  },
  {
    "value": "70500",
    "label": "Lafayette - LA 70500",
    "county": "Lafayette Parish",
    "state": "LA",
    "city": "Lafayette"
  },
  {
    "value": "70501",
    "label": "Lafayette - LA 70501",
    "county": "Lafayette Parish",
    "state": "LA",
    "city": "Lafayette"
  },
  {
    "value": "70503",
    "label": "Lafayette - LA 70503",
    "county": "Lafayette Parish",
    "state": "LA",
    "city": "Lafayette"
  },
  {
    "value": "70506",
    "label": "Lafayette - LA 70506",
    "county": "Lafayette Parish",
    "state": "LA",
    "city": "Lafayette"
  },
  {
    "value": "70507",
    "label": "Lafayette - LA 70507",
    "county": "Lafayette Parish",
    "state": "LA",
    "city": "Lafayette"
  },
  {
    "value": "70508",
    "label": "Lafayette - LA 70508",
    "county": "Lafayette Parish",
    "state": "LA",
    "city": "Lafayette"
  },
  {
    "value": "70510",
    "label": "Abbeville - LA 70510",
    "county": "Vermilion Parish",
    "state": "LA",
    "city": "Abbeville"
  },
  {
    "value": "70512",
    "label": "Arnaudville - LA 70512",
    "county": "St. Landry Parish",
    "state": "LA",
    "city": "Arnaudville"
  },
  {
    "value": "70514",
    "label": "Baldwin - LA 70514",
    "county": "St. Mary Parish",
    "state": "LA",
    "city": "Baldwin"
  },
  {
    "value": "70515",
    "label": "Basile - LA 70515",
    "county": "Evangeline Parish",
    "state": "LA",
    "city": "Basile"
  },
  {
    "value": "70516",
    "label": "Branch - LA 70516",
    "county": "Acadia Parish",
    "state": "LA",
    "city": "Branch"
  },
  {
    "value": "70517",
    "label": "Breaux Bridge - LA 70517",
    "county": "St. Martin Parish",
    "state": "LA",
    "city": "Breaux Bridge"
  },
  {
    "value": "70518",
    "label": "Broussard - LA 70518",
    "county": "Lafayette Parish",
    "state": "LA",
    "city": "Broussard"
  },
  {
    "value": "70520",
    "label": "Carencro - LA 70520",
    "county": "Lafayette Parish",
    "state": "LA",
    "city": "Carencro"
  },
  {
    "value": "70525",
    "label": "Church Point - LA 70525",
    "county": "Acadia Parish",
    "state": "LA",
    "city": "Church Point"
  },
  {
    "value": "70526",
    "label": "Crowley - LA 70526",
    "county": "Acadia Parish",
    "state": "LA",
    "city": "Crowley"
  },
  {
    "value": "70528",
    "label": "Delcambre - LA 70528",
    "county": "Vermilion Parish",
    "state": "LA",
    "city": "Delcambre"
  },
  {
    "value": "70529",
    "label": "Duson - LA 70529",
    "county": "Lafayette Parish",
    "state": "LA",
    "city": "Duson"
  },
  {
    "value": "70531",
    "label": "Egan - LA 70531",
    "county": "Acadia Parish",
    "state": "LA",
    "city": "Egan"
  },
  {
    "value": "70532",
    "label": "Elton - LA 70532",
    "county": "Jefferson Davis Parish",
    "state": "LA",
    "city": "Elton"
  },
  {
    "value": "70533",
    "label": "Erath - LA 70533",
    "county": "Vermilion Parish",
    "state": "LA",
    "city": "Erath"
  },
  {
    "value": "70535",
    "label": "Eunice - LA 70535",
    "county": "St. Landry Parish",
    "state": "LA",
    "city": "Eunice"
  },
  {
    "value": "70537",
    "label": "Evangeline - LA 70537",
    "county": "Acadia Parish",
    "state": "LA",
    "city": "Evangeline"
  },
  {
    "value": "70538",
    "label": "Franklin - LA 70538",
    "county": "St. Mary Parish",
    "state": "LA",
    "city": "Franklin"
  },
  {
    "value": "70542",
    "label": "Gueydan - LA 70542",
    "county": "Vermilion Parish",
    "state": "LA",
    "city": "Gueydan"
  },
  {
    "value": "70543",
    "label": "Iota - LA 70543",
    "county": "Acadia Parish",
    "state": "LA",
    "city": "Iota"
  },
  {
    "value": "70544",
    "label": "Jeanerette - LA 70544",
    "county": "Iberia Parish",
    "state": "LA",
    "city": "Jeanerette"
  },
  {
    "value": "70546",
    "label": "Jennings - LA 70546",
    "county": "Jefferson Davis Parish",
    "state": "LA",
    "city": "Jennings"
  },
  {
    "value": "70548",
    "label": "Kaplan - LA 70548",
    "county": "Vermilion Parish",
    "state": "LA",
    "city": "Kaplan"
  },
  {
    "value": "70549",
    "label": "Lake Arthur - LA 70549",
    "county": "Jefferson Davis Parish",
    "state": "LA",
    "city": "Lake Arthur"
  },
  {
    "value": "70552",
    "label": "Loreauville - LA 70552",
    "county": "Iberia Parish",
    "state": "LA",
    "city": "Loreauville"
  },
  {
    "value": "70554",
    "label": "Mamou - LA 70554",
    "county": "Evangeline Parish",
    "state": "LA",
    "city": "Mamou"
  },
  {
    "value": "70555",
    "label": "Maurice - LA 70555",
    "county": "Vermilion Parish",
    "state": "LA",
    "city": "Maurice"
  },
  {
    "value": "70559",
    "label": "Morse - LA 70559",
    "county": "Acadia Parish",
    "state": "LA",
    "city": "Morse"
  },
  {
    "value": "70560",
    "label": "New Iberia - LA 70560",
    "county": "Iberia Parish",
    "state": "LA",
    "city": "New Iberia"
  },
  {
    "value": "70563",
    "label": "New Iberia - LA 70563",
    "county": "Iberia Parish",
    "state": "LA",
    "city": "New Iberia"
  },
  {
    "value": "70570",
    "label": "Opelousas - LA 70570",
    "county": "St. Landry Parish",
    "state": "LA",
    "city": "Opelousas"
  },
  {
    "value": "70577",
    "label": "Port Barre - LA 70577",
    "county": "St. Landry Parish",
    "state": "LA",
    "city": "Port Barre"
  },
  {
    "value": "70578",
    "label": "Rayne - LA 70578",
    "county": "Acadia Parish",
    "state": "LA",
    "city": "Rayne"
  },
  {
    "value": "70581",
    "label": "Roanoke - LA 70581",
    "county": "Jefferson Davis Parish",
    "state": "LA",
    "city": "Roanoke"
  },
  {
    "value": "70582",
    "label": "Saint Martinville - LA 70582",
    "county": "St. Martin Parish",
    "state": "LA",
    "city": "Saint Martinville"
  },
  {
    "value": "70583",
    "label": "Scott - LA 70583",
    "county": "Lafayette Parish",
    "state": "LA",
    "city": "Scott"
  },
  {
    "value": "70584",
    "label": "Sunset - LA 70584",
    "county": "St. Landry Parish",
    "state": "LA",
    "city": "Sunset"
  },
  {
    "value": "70586",
    "label": "Ville Platte - LA 70586",
    "county": "Evangeline Parish",
    "state": "LA",
    "city": "Ville Platte"
  },
  {
    "value": "70589",
    "label": "Washington - LA 70589",
    "county": "St. Landry Parish",
    "state": "LA",
    "city": "Washington"
  },
  {
    "value": "70591",
    "label": "Welsh - LA 70591",
    "county": "Jefferson Davis Parish",
    "state": "LA",
    "city": "Welsh"
  },
  {
    "value": "70592",
    "label": "Youngsville - LA 70592",
    "county": "Lafayette Parish",
    "state": "LA",
    "city": "Youngsville"
  },
  {
    "value": "70601",
    "label": "Lake Charles - LA 70601",
    "county": "Calcasieu Parish",
    "state": "LA",
    "city": "Lake Charles"
  },
  {
    "value": "70605",
    "label": "Lake Charles - LA 70605",
    "county": "Calcasieu Parish",
    "state": "LA",
    "city": "Lake Charles"
  },
  {
    "value": "70607",
    "label": "Lake Charles - LA 70607",
    "county": "Calcasieu Parish",
    "state": "LA",
    "city": "Lake Charles"
  },
  {
    "value": "70611",
    "label": "Lake Charles - LA 70611",
    "county": "Calcasieu Parish",
    "state": "LA",
    "city": "Lake Charles"
  },
  {
    "value": "70615",
    "label": "Lake Charles - LA 70615",
    "county": "Calcasieu Parish",
    "state": "LA",
    "city": "Lake Charles"
  },
  {
    "value": "70629",
    "label": "Lake Charles - LA 70629",
    "county": "Calcasieu Parish",
    "state": "LA",
    "city": "Lake Charles"
  },
  {
    "value": "70630",
    "label": "Bell City - LA 70630",
    "county": "Calcasieu Parish",
    "state": "LA",
    "city": "Bell City"
  },
  {
    "value": "70631",
    "label": "Cameron - LA 70631",
    "county": "Cameron Parish",
    "state": "LA",
    "city": "Cameron"
  },
  {
    "value": "70632",
    "label": "Creole - LA 70632",
    "county": "Cameron Parish",
    "state": "LA",
    "city": "Creole"
  },
  {
    "value": "70633",
    "label": "Dequincy - LA 70633",
    "county": "Calcasieu Parish",
    "state": "LA",
    "city": "Dequincy"
  },
  {
    "value": "70634",
    "label": "Deridder - LA 70634",
    "county": "Beauregard Parish",
    "state": "LA",
    "city": "Deridder"
  },
  {
    "value": "70637",
    "label": "Dry Creek - LA 70637",
    "county": "Allen Parish",
    "state": "LA",
    "city": "Dry Creek"
  },
  {
    "value": "70639",
    "label": "Evans - LA 70639",
    "county": "Vernon Parish",
    "state": "LA",
    "city": "Evans"
  },
  {
    "value": "70643",
    "label": "Grand Chenier - LA 70643",
    "county": "Cameron Parish",
    "state": "LA",
    "city": "Grand Chenier"
  },
  {
    "value": "70645",
    "label": "Hackberry - LA 70645",
    "county": "Cameron Parish",
    "state": "LA",
    "city": "Hackberry"
  },
  {
    "value": "70647",
    "label": "Iowa - LA 70647",
    "county": "Calcasieu Parish",
    "state": "LA",
    "city": "Iowa"
  },
  {
    "value": "70648",
    "label": "Kinder - LA 70648",
    "county": "Allen Parish",
    "state": "LA",
    "city": "Kinder"
  },
  {
    "value": "70652",
    "label": "Longville - LA 70652",
    "county": "Beauregard Parish",
    "state": "LA",
    "city": "Longville"
  },
  {
    "value": "70653",
    "label": "Merryville - LA 70653",
    "county": "Beauregard Parish",
    "state": "LA",
    "city": "Merryville"
  },
  {
    "value": "70654",
    "label": "Mittie - LA 70654",
    "county": "Allen Parish",
    "state": "LA",
    "city": "Mittie"
  },
  {
    "value": "70655",
    "label": "Oberlin - LA 70655",
    "county": "Allen Parish",
    "state": "LA",
    "city": "Oberlin"
  },
  {
    "value": "70656",
    "label": "Pitkin - LA 70656",
    "county": "Vernon Parish",
    "state": "LA",
    "city": "Pitkin"
  },
  {
    "value": "70657",
    "label": "Ragley - LA 70657",
    "county": "Beauregard Parish",
    "state": "LA",
    "city": "Ragley"
  },
  {
    "value": "70658",
    "label": "Reeves - LA 70658",
    "county": "Allen Parish",
    "state": "LA",
    "city": "Reeves"
  },
  {
    "value": "70660",
    "label": "Singer - LA 70660",
    "county": "Beauregard Parish",
    "state": "LA",
    "city": "Singer"
  },
  {
    "value": "70661",
    "label": "Starks - LA 70661",
    "county": "Calcasieu Parish",
    "state": "LA",
    "city": "Starks"
  },
  {
    "value": "70662",
    "label": "Sugartown - LA 70662",
    "county": "Beauregard Parish",
    "state": "LA",
    "city": "Sugartown"
  },
  {
    "value": "70663",
    "label": "Sulphur - LA 70663",
    "county": "Calcasieu Parish",
    "state": "LA",
    "city": "Sulphur"
  },
  {
    "value": "70665",
    "label": "Sulphur - LA 70665",
    "county": "Calcasieu Parish",
    "state": "LA",
    "city": "Sulphur"
  },
  {
    "value": "70668",
    "label": "Vinton - LA 70668",
    "county": "Calcasieu Parish",
    "state": "LA",
    "city": "Vinton"
  },
  {
    "value": "70669",
    "label": "Westlake - LA 70669",
    "county": "Calcasieu Parish",
    "state": "LA",
    "city": "Westlake"
  },
  {
    "value": "70706",
    "label": "Denham Springs - LA 70706",
    "county": "Livingston Parish",
    "state": "LA",
    "city": "Denham Springs"
  },
  {
    "value": "70710",
    "label": "Addis - LA 70710",
    "county": "West Baton Rouge Parish",
    "state": "LA",
    "city": "Addis"
  },
  {
    "value": "70711",
    "label": "Albany - LA 70711",
    "county": "Livingston Parish",
    "state": "LA",
    "city": "Albany"
  },
  {
    "value": "70714",
    "label": "Baker - LA 70714",
    "county": "East Baton Rouge Parish",
    "state": "LA",
    "city": "Baker"
  },
  {
    "value": "70715",
    "label": "Batchelor - LA 70715",
    "county": "Pointe Coupee Parish",
    "state": "LA",
    "city": "Batchelor"
  },
  {
    "value": "70719",
    "label": "Brusly - LA 70719",
    "county": "West Baton Rouge Parish",
    "state": "LA",
    "city": "Brusly"
  },
  {
    "value": "70721",
    "label": "Carville - LA 70721",
    "county": "Iberville Parish",
    "state": "LA",
    "city": "Carville"
  },
  {
    "value": "70722",
    "label": "Clinton - LA 70722",
    "county": "East Feliciana Parish",
    "state": "LA",
    "city": "Clinton"
  },
  {
    "value": "70723",
    "label": "Convent - LA 70723",
    "county": "St. James Parish",
    "state": "LA",
    "city": "Convent"
  },
  {
    "value": "70725",
    "label": "Darrow - LA 70725",
    "county": "Ascension Parish",
    "state": "LA",
    "city": "Darrow"
  },
  {
    "value": "70726",
    "label": "Denham Springs - LA 70726",
    "county": "Livingston Parish",
    "state": "LA",
    "city": "Denham Springs"
  },
  {
    "value": "70729",
    "label": "Erwinville - LA 70729",
    "county": "West Baton Rouge Parish",
    "state": "LA",
    "city": "Erwinville"
  },
  {
    "value": "70730",
    "label": "Ethel - LA 70730",
    "county": "East Feliciana Parish",
    "state": "LA",
    "city": "Ethel"
  },
  {
    "value": "70732",
    "label": "Fordoche - LA 70732",
    "county": "Pointe Coupee Parish",
    "state": "LA",
    "city": "Fordoche"
  },
  {
    "value": "70733",
    "label": "French Settlement - LA 70733",
    "county": "Livingston Parish",
    "state": "LA",
    "city": "French Settlement"
  },
  {
    "value": "70734",
    "label": "Geismar - LA 70734",
    "county": "Ascension Parish",
    "state": "LA",
    "city": "Geismar"
  },
  {
    "value": "70736",
    "label": "Glynn - LA 70736",
    "county": "Pointe Coupee Parish",
    "state": "LA",
    "city": "Glynn"
  },
  {
    "value": "70737",
    "label": "Gonzales - LA 70737",
    "county": "Ascension Parish",
    "state": "LA",
    "city": "Gonzales"
  },
  {
    "value": "70739",
    "label": "Greenwell Springs - LA 70739",
    "county": "East Baton Rouge Parish",
    "state": "LA",
    "city": "Greenwell Springs"
  },
  {
    "value": "70740",
    "label": "Grosse Tete - LA 70740",
    "county": "Iberville Parish",
    "state": "LA",
    "city": "Grosse Tete"
  },
  {
    "value": "70744",
    "label": "Holden - LA 70744",
    "county": "Livingston Parish",
    "state": "LA",
    "city": "Holden"
  },
  {
    "value": "70748",
    "label": "Jackson - LA 70748",
    "county": "East Feliciana Parish",
    "state": "LA",
    "city": "Jackson"
  },
  {
    "value": "70749",
    "label": "Jarreau - LA 70749",
    "county": "Pointe Coupee Parish",
    "state": "LA",
    "city": "Jarreau"
  },
  {
    "value": "70750",
    "label": "Krotz Springs - LA 70750",
    "county": "St. Landry Parish",
    "state": "LA",
    "city": "Krotz Springs"
  },
  {
    "value": "70752",
    "label": "Lakeland - LA 70752",
    "county": "Pointe Coupee Parish",
    "state": "LA",
    "city": "Lakeland"
  },
  {
    "value": "70753",
    "label": "Lettsworth - LA 70753",
    "county": "Pointe Coupee Parish",
    "state": "LA",
    "city": "Lettsworth"
  },
  {
    "value": "70754",
    "label": "Livingston - LA 70754",
    "county": "Livingston Parish",
    "state": "LA",
    "city": "Livingston"
  },
  {
    "value": "70755",
    "label": "Livonia - LA 70755",
    "county": "Pointe Coupee Parish",
    "state": "LA",
    "city": "Livonia"
  },
  {
    "value": "70756",
    "label": "Lottie - LA 70756",
    "county": "Pointe Coupee Parish",
    "state": "LA",
    "city": "Lottie"
  },
  {
    "value": "70757",
    "label": "Maringouin - LA 70757",
    "county": "Iberville Parish",
    "state": "LA",
    "city": "Maringouin"
  },
  {
    "value": "70759",
    "label": "Morganza - LA 70759",
    "county": "Pointe Coupee Parish",
    "state": "LA",
    "city": "Morganza"
  },
  {
    "value": "70760",
    "label": "New Roads - LA 70760",
    "county": "Pointe Coupee Parish",
    "state": "LA",
    "city": "New Roads"
  },
  {
    "value": "70761",
    "label": "Norwood - LA 70761",
    "county": "East Feliciana Parish",
    "state": "LA",
    "city": "Norwood"
  },
  {
    "value": "70762",
    "label": "Oscar - LA 70762",
    "county": "Pointe Coupee Parish",
    "state": "LA",
    "city": "Oscar"
  },
  {
    "value": "70763",
    "label": "Paulina - LA 70763",
    "county": "St. James Parish",
    "state": "LA",
    "city": "Paulina"
  },
  {
    "value": "70764",
    "label": "Plaquemine - LA 70764",
    "county": "Iberville Parish",
    "state": "LA",
    "city": "Plaquemine"
  },
  {
    "value": "70767",
    "label": "Port Allen - LA 70767",
    "county": "West Baton Rouge Parish",
    "state": "LA",
    "city": "Port Allen"
  },
  {
    "value": "70769",
    "label": "Prairieville - LA 70769",
    "county": "Ascension Parish",
    "state": "LA",
    "city": "Prairieville"
  },
  {
    "value": "70770",
    "label": "Pride - LA 70770",
    "county": "East Baton Rouge Parish",
    "state": "LA",
    "city": "Pride"
  },
  {
    "value": "70772",
    "label": "Rosedale - LA 70772",
    "county": "Iberville Parish",
    "state": "LA",
    "city": "Rosedale"
  },
  {
    "value": "70773",
    "label": "Rougon - LA 70773",
    "county": "Pointe Coupee Parish",
    "state": "LA",
    "city": "Rougon"
  },
  {
    "value": "70774",
    "label": "Saint Amant - LA 70774",
    "county": "Ascension Parish",
    "state": "LA",
    "city": "Saint Amant"
  },
  {
    "value": "70775",
    "label": "Saint Francisville - LA 70775",
    "county": "West Feliciana Parish",
    "state": "LA",
    "city": "Saint Francisville"
  },
  {
    "value": "70776",
    "label": "Saint Gabriel - LA 70776",
    "county": "Iberville Parish",
    "state": "LA",
    "city": "Saint Gabriel"
  },
  {
    "value": "70777",
    "label": "Slaughter - LA 70777",
    "county": "East Feliciana Parish",
    "state": "LA",
    "city": "Slaughter"
  },
  {
    "value": "70778",
    "label": "Sorrento - LA 70778",
    "county": "Ascension Parish",
    "state": "LA",
    "city": "Sorrento"
  },
  {
    "value": "70780",
    "label": "Sunshine - LA 70780",
    "county": "Iberville Parish",
    "state": "LA",
    "city": "Sunshine"
  },
  {
    "value": "70783",
    "label": "Ventress - LA 70783",
    "county": "Pointe Coupee Parish",
    "state": "LA",
    "city": "Ventress"
  },
  {
    "value": "70785",
    "label": "Walker - LA 70785",
    "county": "Livingston Parish",
    "state": "LA",
    "city": "Walker"
  },
  {
    "value": "70788",
    "label": "White Castle - LA 70788",
    "county": "Iberville Parish",
    "state": "LA",
    "city": "White Castle"
  },
  {
    "value": "70789",
    "label": "Wilson - LA 70789",
    "county": "East Feliciana Parish",
    "state": "LA",
    "city": "Wilson"
  },
  {
    "value": "70791",
    "label": "Zachary - LA 70791",
    "county": "East Baton Rouge Parish",
    "state": "LA",
    "city": "Zachary"
  },
  {
    "value": "70801",
    "label": "Baton Rouge - LA 70801",
    "county": "East Baton Rouge Parish",
    "state": "LA",
    "city": "Baton Rouge"
  },
  {
    "value": "70802",
    "label": "Baton Rouge - LA 70802",
    "county": "East Baton Rouge Parish",
    "state": "LA",
    "city": "Baton Rouge"
  },
  {
    "value": "70805",
    "label": "Baton Rouge - LA 70805",
    "county": "East Baton Rouge Parish",
    "state": "LA",
    "city": "Baton Rouge"
  },
  {
    "value": "70806",
    "label": "Baton Rouge - LA 70806",
    "county": "East Baton Rouge Parish",
    "state": "LA",
    "city": "Baton Rouge"
  },
  {
    "value": "70807",
    "label": "Baton Rouge - LA 70807",
    "county": "East Baton Rouge Parish",
    "state": "LA",
    "city": "Baton Rouge"
  },
  {
    "value": "70808",
    "label": "Baton Rouge - LA 70808",
    "county": "East Baton Rouge Parish",
    "state": "LA",
    "city": "Baton Rouge"
  },
  {
    "value": "70809",
    "label": "Baton Rouge - LA 70809",
    "county": "East Baton Rouge Parish",
    "state": "LA",
    "city": "Baton Rouge"
  },
  {
    "value": "70810",
    "label": "Baton Rouge - LA 70810",
    "county": "East Baton Rouge Parish",
    "state": "LA",
    "city": "Baton Rouge"
  },
  {
    "value": "70811",
    "label": "Baton Rouge - LA 70811",
    "county": "East Baton Rouge Parish",
    "state": "LA",
    "city": "Baton Rouge"
  },
  {
    "value": "70812",
    "label": "Baton Rouge - LA 70812",
    "county": "East Baton Rouge Parish",
    "state": "LA",
    "city": "Baton Rouge"
  },
  {
    "value": "70814",
    "label": "Baton Rouge - LA 70814",
    "county": "East Baton Rouge Parish",
    "state": "LA",
    "city": "Baton Rouge"
  },
  {
    "value": "70815",
    "label": "Baton Rouge - LA 70815",
    "county": "East Baton Rouge Parish",
    "state": "LA",
    "city": "Baton Rouge"
  },
  {
    "value": "70816",
    "label": "Baton Rouge - LA 70816",
    "county": "East Baton Rouge Parish",
    "state": "LA",
    "city": "Baton Rouge"
  },
  {
    "value": "70817",
    "label": "Baton Rouge - LA 70817",
    "county": "East Baton Rouge Parish",
    "state": "LA",
    "city": "Baton Rouge"
  },
  {
    "value": "70818",
    "label": "Baton Rouge - LA 70818",
    "county": "East Baton Rouge Parish",
    "state": "LA",
    "city": "Baton Rouge"
  },
  {
    "value": "70819",
    "label": "Baton Rouge - LA 70819",
    "county": "East Baton Rouge Parish",
    "state": "LA",
    "city": "Baton Rouge"
  },
  {
    "value": "70820",
    "label": "Baton Rouge - LA 70820",
    "county": "East Baton Rouge Parish",
    "state": "LA",
    "city": "Baton Rouge"
  },
  {
    "value": "70825",
    "label": "Baton Rouge - LA 70825",
    "county": "East Baton Rouge Parish",
    "state": "LA",
    "city": "Baton Rouge"
  },
  {
    "value": "70827",
    "label": "Baton Rouge - LA 70827",
    "county": "East Baton Rouge Parish",
    "state": "LA",
    "city": "Baton Rouge"
  },
  {
    "value": "70836",
    "label": "Baton Rouge - LA 70836",
    "county": "East Baton Rouge Parish",
    "state": "LA",
    "city": "Baton Rouge"
  },
  {
    "value": "71001",
    "label": "Arcadia - LA 71001",
    "county": "Bienville Parish",
    "state": "LA",
    "city": "Arcadia"
  },
  {
    "value": "71002",
    "label": "Ashland - LA 71002",
    "county": "Natchitoches Parish",
    "state": "LA",
    "city": "Ashland"
  },
  {
    "value": "71003",
    "label": "Athens - LA 71003",
    "county": "Claiborne Parish",
    "state": "LA",
    "city": "Athens"
  },
  {
    "value": "71004",
    "label": "Belcher - LA 71004",
    "county": "Caddo Parish",
    "state": "LA",
    "city": "Belcher"
  },
  {
    "value": "71006",
    "label": "Benton - LA 71006",
    "county": "Bossier Parish",
    "state": "LA",
    "city": "Benton"
  },
  {
    "value": "71007",
    "label": "Bethany - LA 71007",
    "county": "Caddo Parish",
    "state": "LA",
    "city": "Bethany"
  },
  {
    "value": "71008",
    "label": "Bienville - LA 71008",
    "county": "Bienville Parish",
    "state": "LA",
    "city": "Bienville"
  },
  {
    "value": "71016",
    "label": "Castor - LA 71016",
    "county": "Bienville Parish",
    "state": "LA",
    "city": "Castor"
  },
  {
    "value": "71018",
    "label": "Cotton Valley - LA 71018",
    "county": "Webster Parish",
    "state": "LA",
    "city": "Cotton Valley"
  },
  {
    "value": "71019",
    "label": "Coushatta - LA 71019",
    "county": "Red River Parish",
    "state": "LA",
    "city": "Coushatta"
  },
  {
    "value": "71023",
    "label": "Doyline - LA 71023",
    "county": "Webster Parish",
    "state": "LA",
    "city": "Doyline"
  },
  {
    "value": "71024",
    "label": "Dubberly - LA 71024",
    "county": "Webster Parish",
    "state": "LA",
    "city": "Dubberly"
  },
  {
    "value": "71027",
    "label": "Frierson - LA 71027",
    "county": "De Soto Parish",
    "state": "LA",
    "city": "Frierson"
  },
  {
    "value": "71028",
    "label": "Gibsland - LA 71028",
    "county": "Bienville Parish",
    "state": "LA",
    "city": "Gibsland"
  },
  {
    "value": "71029",
    "label": "Gilliam - LA 71029",
    "county": "Caddo Parish",
    "state": "LA",
    "city": "Gilliam"
  },
  {
    "value": "71030",
    "label": "Gloster - LA 71030",
    "county": "De Soto Parish",
    "state": "LA",
    "city": "Gloster"
  },
  {
    "value": "71031",
    "label": "Goldonna - LA 71031",
    "county": "Natchitoches Parish",
    "state": "LA",
    "city": "Goldonna"
  },
  {
    "value": "71032",
    "label": "Grand Cane - LA 71032",
    "county": "De Soto Parish",
    "state": "LA",
    "city": "Grand Cane"
  },
  {
    "value": "71033",
    "label": "Greenwood - LA 71033",
    "county": "Caddo Parish",
    "state": "LA",
    "city": "Greenwood"
  },
  {
    "value": "71034",
    "label": "Hall Summit - LA 71034",
    "county": "Red River Parish",
    "state": "LA",
    "city": "Hall Summit"
  },
  {
    "value": "71037",
    "label": "Haughton - LA 71037",
    "county": "Bossier Parish",
    "state": "LA",
    "city": "Haughton"
  },
  {
    "value": "71038",
    "label": "Haynesville - LA 71038",
    "county": "Claiborne Parish",
    "state": "LA",
    "city": "Haynesville"
  },
  {
    "value": "71039",
    "label": "Heflin - LA 71039",
    "county": "Webster Parish",
    "state": "LA",
    "city": "Heflin"
  },
  {
    "value": "71040",
    "label": "Homer - LA 71040",
    "county": "Claiborne Parish",
    "state": "LA",
    "city": "Homer"
  },
  {
    "value": "71043",
    "label": "Hosston - LA 71043",
    "county": "Caddo Parish",
    "state": "LA",
    "city": "Hosston"
  },
  {
    "value": "71044",
    "label": "Ida - LA 71044",
    "county": "Caddo Parish",
    "state": "LA",
    "city": "Ida"
  },
  {
    "value": "71045",
    "label": "Jamestown - LA 71045",
    "county": "Bienville Parish",
    "state": "LA",
    "city": "Jamestown"
  },
  {
    "value": "71046",
    "label": "Keatchie - LA 71046",
    "county": "De Soto Parish",
    "state": "LA",
    "city": "Keatchie"
  },
  {
    "value": "71047",
    "label": "Keithville - LA 71047",
    "county": "Caddo Parish",
    "state": "LA",
    "city": "Keithville"
  },
  {
    "value": "71048",
    "label": "Lisbon - LA 71048",
    "county": "Claiborne Parish",
    "state": "LA",
    "city": "Lisbon"
  },
  {
    "value": "71049",
    "label": "Logansport - LA 71049",
    "county": "De Soto Parish",
    "state": "LA",
    "city": "Logansport"
  },
  {
    "value": "71051",
    "label": "Elm Grove - LA 71051",
    "county": "Bossier Parish",
    "state": "LA",
    "city": "Elm Grove"
  },
  {
    "value": "71052",
    "label": "Mansfield - LA 71052",
    "county": "De Soto Parish",
    "state": "LA",
    "city": "Mansfield"
  },
  {
    "value": "71055",
    "label": "Minden - LA 71055",
    "county": "Webster Parish",
    "state": "LA",
    "city": "Minden"
  },
  {
    "value": "71060",
    "label": "Mooringsport - LA 71060",
    "county": "Caddo Parish",
    "state": "LA",
    "city": "Mooringsport"
  },
  {
    "value": "71061",
    "label": "Oil City - LA 71061",
    "county": "Caddo Parish",
    "state": "LA",
    "city": "Oil City"
  },
  {
    "value": "71063",
    "label": "Pelican - LA 71063",
    "county": "De Soto Parish",
    "state": "LA",
    "city": "Pelican"
  },
  {
    "value": "71064",
    "label": "Plain Dealing - LA 71064",
    "county": "Bossier Parish",
    "state": "LA",
    "city": "Plain Dealing"
  },
  {
    "value": "71065",
    "label": "Pleasant Hill - LA 71065",
    "county": "Sabine Parish",
    "state": "LA",
    "city": "Pleasant Hill"
  },
  {
    "value": "71067",
    "label": "Princeton - LA 71067",
    "county": "Bossier Parish",
    "state": "LA",
    "city": "Princeton"
  },
  {
    "value": "71068",
    "label": "Ringgold - LA 71068",
    "county": "Bienville Parish",
    "state": "LA",
    "city": "Ringgold"
  },
  {
    "value": "71069",
    "label": "Rodessa - LA 71069",
    "county": "Caddo Parish",
    "state": "LA",
    "city": "Rodessa"
  },
  {
    "value": "71070",
    "label": "Saline - LA 71070",
    "county": "Natchitoches Parish",
    "state": "LA",
    "city": "Saline"
  },
  {
    "value": "71071",
    "label": "Sarepta - LA 71071",
    "county": "Webster Parish",
    "state": "LA",
    "city": "Sarepta"
  },
  {
    "value": "71072",
    "label": "Shongaloo - LA 71072",
    "county": "Webster Parish",
    "state": "LA",
    "city": "Shongaloo"
  },
  {
    "value": "71073",
    "label": "Sibley - LA 71073",
    "county": "Webster Parish",
    "state": "LA",
    "city": "Sibley"
  },
  {
    "value": "71075",
    "label": "Springhill - LA 71075",
    "county": "Webster Parish",
    "state": "LA",
    "city": "Springhill"
  },
  {
    "value": "71078",
    "label": "Stonewall - LA 71078",
    "county": "De Soto Parish",
    "state": "LA",
    "city": "Stonewall"
  },
  {
    "value": "71079",
    "label": "Summerfield - LA 71079",
    "county": "Claiborne Parish",
    "state": "LA",
    "city": "Summerfield"
  },
  {
    "value": "71082",
    "label": "Vivian - LA 71082",
    "county": "Caddo Parish",
    "state": "LA",
    "city": "Vivian"
  },
  {
    "value": "71101",
    "label": "Shreveport - LA 71101",
    "county": "Caddo Parish",
    "state": "LA",
    "city": "Shreveport"
  },
  {
    "value": "71103",
    "label": "Shreveport - LA 71103",
    "county": "Caddo Parish",
    "state": "LA",
    "city": "Shreveport"
  },
  {
    "value": "71104",
    "label": "Shreveport - LA 71104",
    "county": "Caddo Parish",
    "state": "LA",
    "city": "Shreveport"
  },
  {
    "value": "71105",
    "label": "Shreveport - LA 71105",
    "county": "Caddo Parish",
    "state": "LA",
    "city": "Shreveport"
  },
  {
    "value": "71106",
    "label": "Shreveport - LA 71106",
    "county": "Caddo Parish",
    "state": "LA",
    "city": "Shreveport"
  },
  {
    "value": "71107",
    "label": "Shreveport - LA 71107",
    "county": "Caddo Parish",
    "state": "LA",
    "city": "Shreveport"
  },
  {
    "value": "71108",
    "label": "Shreveport - LA 71108",
    "county": "Caddo Parish",
    "state": "LA",
    "city": "Shreveport"
  },
  {
    "value": "71109",
    "label": "Shreveport - LA 71109",
    "county": "Caddo Parish",
    "state": "LA",
    "city": "Shreveport"
  },
  {
    "value": "71110",
    "label": "Barksdale AFB - LA 71110",
    "county": "Bossier Parish",
    "state": "LA",
    "city": "Barksdale AFB"
  },
  {
    "value": "71111",
    "label": "Bossier City - LA 71111",
    "county": "Bossier Parish",
    "state": "LA",
    "city": "Bossier City"
  },
  {
    "value": "71112",
    "label": "Bossier City - LA 71112",
    "county": "Bossier Parish",
    "state": "LA",
    "city": "Bossier City"
  },
  {
    "value": "71115",
    "label": "Shreveport - LA 71115",
    "county": "Caddo Parish",
    "state": "LA",
    "city": "Shreveport"
  },
  {
    "value": "71118",
    "label": "Shreveport - LA 71118",
    "county": "Caddo Parish",
    "state": "LA",
    "city": "Shreveport"
  },
  {
    "value": "71119",
    "label": "Shreveport - LA 71119",
    "county": "Caddo Parish",
    "state": "LA",
    "city": "Shreveport"
  },
  {
    "value": "71129",
    "label": "Shreveport - LA 71129",
    "county": "Caddo Parish",
    "state": "LA",
    "city": "Shreveport"
  },
  {
    "value": "71201",
    "label": "Monroe - LA 71201",
    "county": "Ouachita Parish",
    "state": "LA",
    "city": "Monroe"
  },
  {
    "value": "71202",
    "label": "Monroe - LA 71202",
    "county": "Ouachita Parish",
    "state": "LA",
    "city": "Monroe"
  },
  {
    "value": "71203",
    "label": "Monroe - LA 71203",
    "county": "Ouachita Parish",
    "state": "LA",
    "city": "Monroe"
  },
  {
    "value": "71219",
    "label": "Baskin - LA 71219",
    "county": "Franklin Parish",
    "state": "LA",
    "city": "Baskin"
  },
  {
    "value": "71220",
    "label": "Bastrop - LA 71220",
    "county": "Morehouse Parish",
    "state": "LA",
    "city": "Bastrop"
  },
  {
    "value": "71222",
    "label": "Bernice - LA 71222",
    "county": "Union Parish",
    "state": "LA",
    "city": "Bernice"
  },
  {
    "value": "71223",
    "label": "Bonita - LA 71223",
    "county": "Morehouse Parish",
    "state": "LA",
    "city": "Bonita"
  },
  {
    "value": "71225",
    "label": "Calhoun - LA 71225",
    "county": "Ouachita Parish",
    "state": "LA",
    "city": "Calhoun"
  },
  {
    "value": "71226",
    "label": "Chatham - LA 71226",
    "county": "Jackson Parish",
    "state": "LA",
    "city": "Chatham"
  },
  {
    "value": "71227",
    "label": "Choudrant - LA 71227",
    "county": "Lincoln Parish",
    "state": "LA",
    "city": "Choudrant"
  },
  {
    "value": "71229",
    "label": "Collinston - LA 71229",
    "county": "Morehouse Parish",
    "state": "LA",
    "city": "Collinston"
  },
  {
    "value": "71232",
    "label": "Delhi - LA 71232",
    "county": "Richland Parish",
    "state": "LA",
    "city": "Delhi"
  },
  {
    "value": "71234",
    "label": "Downsville - LA 71234",
    "county": "Union Parish",
    "state": "LA",
    "city": "Downsville"
  },
  {
    "value": "71235",
    "label": "Dubach - LA 71235",
    "county": "Lincoln Parish",
    "state": "LA",
    "city": "Dubach"
  },
  {
    "value": "71237",
    "label": "Epps - LA 71237",
    "county": "West Carroll Parish",
    "state": "LA",
    "city": "Epps"
  },
  {
    "value": "71238",
    "label": "Eros - LA 71238",
    "county": "Ouachita Parish",
    "state": "LA",
    "city": "Eros"
  },
  {
    "value": "71241",
    "label": "Farmerville - LA 71241",
    "county": "Union Parish",
    "state": "LA",
    "city": "Farmerville"
  },
  {
    "value": "71243",
    "label": "Fort Necessity - LA 71243",
    "county": "Franklin Parish",
    "state": "LA",
    "city": "Fort Necessity"
  },
  {
    "value": "71245",
    "label": "Grambling - LA 71245",
    "county": "Lincoln Parish",
    "state": "LA",
    "city": "Grambling"
  },
  {
    "value": "71250",
    "label": "Jones - LA 71250",
    "county": "Morehouse Parish",
    "state": "LA",
    "city": "Jones"
  },
  {
    "value": "71251",
    "label": "Jonesboro - LA 71251",
    "county": "Jackson Parish",
    "state": "LA",
    "city": "Jonesboro"
  },
  {
    "value": "71254",
    "label": "Lake Providence - LA 71254",
    "county": "East Carroll Parish",
    "state": "LA",
    "city": "Lake Providence"
  },
  {
    "value": "71256",
    "label": "Lillie - LA 71256",
    "county": "Union Parish",
    "state": "LA",
    "city": "Lillie"
  },
  {
    "value": "71259",
    "label": "Mangham - LA 71259",
    "county": "Richland Parish",
    "state": "LA",
    "city": "Mangham"
  },
  {
    "value": "71260",
    "label": "Marion - LA 71260",
    "county": "Union Parish",
    "state": "LA",
    "city": "Marion"
  },
  {
    "value": "71261",
    "label": "Mer Rouge - LA 71261",
    "county": "Morehouse Parish",
    "state": "LA",
    "city": "Mer Rouge"
  },
  {
    "value": "71263",
    "label": "Oak Grove - LA 71263",
    "county": "West Carroll Parish",
    "state": "LA",
    "city": "Oak Grove"
  },
  {
    "value": "71264",
    "label": "Oak Ridge - LA 71264",
    "county": "Richland Parish",
    "state": "LA",
    "city": "Oak Ridge"
  },
  {
    "value": "71266",
    "label": "Pioneer - LA 71266",
    "county": "West Carroll Parish",
    "state": "LA",
    "city": "Pioneer"
  },
  {
    "value": "71268",
    "label": "Quitman - LA 71268",
    "county": "Jackson Parish",
    "state": "LA",
    "city": "Quitman"
  },
  {
    "value": "71269",
    "label": "Rayville - LA 71269",
    "county": "Richland Parish",
    "state": "LA",
    "city": "Rayville"
  },
  {
    "value": "71270",
    "label": "Ruston - LA 71270",
    "county": "Lincoln Parish",
    "state": "LA",
    "city": "Ruston"
  },
  {
    "value": "71275",
    "label": "Simsboro - LA 71275",
    "county": "Lincoln Parish",
    "state": "LA",
    "city": "Simsboro"
  },
  {
    "value": "71276",
    "label": "Sondheimer - LA 71276",
    "county": "East Carroll Parish",
    "state": "LA",
    "city": "Sondheimer"
  },
  {
    "value": "71277",
    "label": "Spearsville - LA 71277",
    "county": "Union Parish",
    "state": "LA",
    "city": "Spearsville"
  },
  {
    "value": "71280",
    "label": "Sterlington - LA 71280",
    "county": "Ouachita Parish",
    "state": "LA",
    "city": "Sterlington"
  },
  {
    "value": "71282",
    "label": "Tallulah - LA 71282",
    "county": "Madison Parish",
    "state": "LA",
    "city": "Tallulah"
  },
  {
    "value": "71286",
    "label": "Transylvania - LA 71286",
    "county": "East Carroll Parish",
    "state": "LA",
    "city": "Transylvania"
  },
  {
    "value": "71291",
    "label": "West Monroe - LA 71291",
    "county": "Ouachita Parish",
    "state": "LA",
    "city": "West Monroe"
  },
  {
    "value": "71292",
    "label": "West Monroe - LA 71292",
    "county": "Ouachita Parish",
    "state": "LA",
    "city": "West Monroe"
  },
  {
    "value": "71295",
    "label": "Winnsboro - LA 71295",
    "county": "Franklin Parish",
    "state": "LA",
    "city": "Winnsboro"
  },
  {
    "value": "71301",
    "label": "Alexandria - LA 71301",
    "county": "Rapides Parish",
    "state": "LA",
    "city": "Alexandria"
  },
  {
    "value": "71302",
    "label": "Alexandria - LA 71302",
    "county": "Rapides Parish",
    "state": "LA",
    "city": "Alexandria"
  },
  {
    "value": "71303",
    "label": "Alexandria - LA 71303",
    "county": "Rapides Parish",
    "state": "LA",
    "city": "Alexandria"
  },
  {
    "value": "71316",
    "label": "Acme - LA 71316",
    "county": "Concordia Parish",
    "state": "LA",
    "city": "Acme"
  },
  {
    "value": "71322",
    "label": "Bunkie - LA 71322",
    "county": "Avoyelles Parish",
    "state": "LA",
    "city": "Bunkie"
  },
  {
    "value": "71323",
    "label": "Center Point - LA 71323",
    "county": "Avoyelles Parish",
    "state": "LA",
    "city": "Center Point"
  },
  {
    "value": "71325",
    "label": "Cheneyville - LA 71325",
    "county": "Rapides Parish",
    "state": "LA",
    "city": "Cheneyville"
  },
  {
    "value": "71326",
    "label": "Clayton - LA 71326",
    "county": "Concordia Parish",
    "state": "LA",
    "city": "Clayton"
  },
  {
    "value": "71327",
    "label": "Cottonport - LA 71327",
    "county": "Avoyelles Parish",
    "state": "LA",
    "city": "Cottonport"
  },
  {
    "value": "71328",
    "label": "Deville - LA 71328",
    "county": "Rapides Parish",
    "state": "LA",
    "city": "Deville"
  },
  {
    "value": "71331",
    "label": "Effie - LA 71331",
    "county": "Avoyelles Parish",
    "state": "LA",
    "city": "Effie"
  },
  {
    "value": "71333",
    "label": "Evergreen - LA 71333",
    "county": "Avoyelles Parish",
    "state": "LA",
    "city": "Evergreen"
  },
  {
    "value": "71334",
    "label": "Ferriday - LA 71334",
    "county": "Concordia Parish",
    "state": "LA",
    "city": "Ferriday"
  },
  {
    "value": "71336",
    "label": "Gilbert - LA 71336",
    "county": "Franklin Parish",
    "state": "LA",
    "city": "Gilbert"
  },
  {
    "value": "71340",
    "label": "Harrisonburg - LA 71340",
    "county": "Catahoula Parish",
    "state": "LA",
    "city": "Harrisonburg"
  },
  {
    "value": "71341",
    "label": "Hessmer - LA 71341",
    "county": "Avoyelles Parish",
    "state": "LA",
    "city": "Hessmer"
  },
  {
    "value": "71342",
    "label": "Jena - LA 71342",
    "county": "La Salle Parish",
    "state": "LA",
    "city": "Jena"
  },
  {
    "value": "71343",
    "label": "Jonesville - LA 71343",
    "county": "Catahoula Parish",
    "state": "LA",
    "city": "Jonesville"
  },
  {
    "value": "71346",
    "label": "Lecompte - LA 71346",
    "county": "Rapides Parish",
    "state": "LA",
    "city": "Lecompte"
  },
  {
    "value": "71350",
    "label": "Mansura - LA 71350",
    "county": "Avoyelles Parish",
    "state": "LA",
    "city": "Mansura"
  },
  {
    "value": "71351",
    "label": "Marksville - LA 71351",
    "county": "Avoyelles Parish",
    "state": "LA",
    "city": "Marksville"
  },
  {
    "value": "71353",
    "label": "Melville - LA 71353",
    "county": "St. Landry Parish",
    "state": "LA",
    "city": "Melville"
  },
  {
    "value": "71354",
    "label": "Monterey - LA 71354",
    "county": "Concordia Parish",
    "state": "LA",
    "city": "Monterey"
  },
  {
    "value": "71355",
    "label": "Moreauville - LA 71355",
    "county": "Avoyelles Parish",
    "state": "LA",
    "city": "Moreauville"
  },
  {
    "value": "71356",
    "label": "Morrow - LA 71356",
    "county": "St. Landry Parish",
    "state": "LA",
    "city": "Morrow"
  },
  {
    "value": "71357",
    "label": "Newellton - LA 71357",
    "county": "Tensas Parish",
    "state": "LA",
    "city": "Newellton"
  },
  {
    "value": "71358",
    "label": "Palmetto - LA 71358",
    "county": "St. Landry Parish",
    "state": "LA",
    "city": "Palmetto"
  },
  {
    "value": "71360",
    "label": "Pineville - LA 71360",
    "county": "Rapides Parish",
    "state": "LA",
    "city": "Pineville"
  },
  {
    "value": "71362",
    "label": "Plaucheville - LA 71362",
    "county": "Avoyelles Parish",
    "state": "LA",
    "city": "Plaucheville"
  },
  {
    "value": "71366",
    "label": "Saint Joseph - LA 71366",
    "county": "Tensas Parish",
    "state": "LA",
    "city": "Saint Joseph"
  },
  {
    "value": "71367",
    "label": "Saint Landry - LA 71367",
    "county": "Evangeline Parish",
    "state": "LA",
    "city": "Saint Landry"
  },
  {
    "value": "71368",
    "label": "Sicily Island - LA 71368",
    "county": "Catahoula Parish",
    "state": "LA",
    "city": "Sicily Island"
  },
  {
    "value": "71369",
    "label": "Simmesport - LA 71369",
    "county": "Avoyelles Parish",
    "state": "LA",
    "city": "Simmesport"
  },
  {
    "value": "71371",
    "label": "Trout - LA 71371",
    "county": "La Salle Parish",
    "state": "LA",
    "city": "Trout"
  },
  {
    "value": "71373",
    "label": "Vidalia - LA 71373",
    "county": "Concordia Parish",
    "state": "LA",
    "city": "Vidalia"
  },
  {
    "value": "71375",
    "label": "Waterproof - LA 71375",
    "county": "Tensas Parish",
    "state": "LA",
    "city": "Waterproof"
  },
  {
    "value": "71378",
    "label": "Wisner - LA 71378",
    "county": "Franklin Parish",
    "state": "LA",
    "city": "Wisner"
  },
  {
    "value": "71401",
    "label": "Aimwell - LA 71401",
    "county": "Catahoula Parish",
    "state": "LA",
    "city": "Aimwell"
  },
  {
    "value": "71403",
    "label": "Anacoco - LA 71403",
    "county": "Vernon Parish",
    "state": "LA",
    "city": "Anacoco"
  },
  {
    "value": "71404",
    "label": "Atlanta - LA 71404",
    "county": "Winn Parish",
    "state": "LA",
    "city": "Atlanta"
  },
  {
    "value": "71405",
    "label": "Ball - LA 71405",
    "county": "Rapides Parish",
    "state": "LA",
    "city": "Ball"
  },
  {
    "value": "71406",
    "label": "Belmont - LA 71406",
    "county": "Sabine Parish",
    "state": "LA",
    "city": "Belmont"
  },
  {
    "value": "71407",
    "label": "Bentley - LA 71407",
    "county": "Grant Parish",
    "state": "LA",
    "city": "Bentley"
  },
  {
    "value": "71409",
    "label": "Boyce - LA 71409",
    "county": "Rapides Parish",
    "state": "LA",
    "city": "Boyce"
  },
  {
    "value": "71411",
    "label": "Campti - LA 71411",
    "county": "Natchitoches Parish",
    "state": "LA",
    "city": "Campti"
  },
  {
    "value": "71416",
    "label": "Cloutierville - LA 71416",
    "county": "Natchitoches Parish",
    "state": "LA",
    "city": "Cloutierville"
  },
  {
    "value": "71417",
    "label": "Colfax - LA 71417",
    "county": "Grant Parish",
    "state": "LA",
    "city": "Colfax"
  },
  {
    "value": "71418",
    "label": "Columbia - LA 71418",
    "county": "Caldwell Parish",
    "state": "LA",
    "city": "Columbia"
  },
  {
    "value": "71419",
    "label": "Converse - LA 71419",
    "county": "Sabine Parish",
    "state": "LA",
    "city": "Converse"
  },
  {
    "value": "71422",
    "label": "Dodson - LA 71422",
    "county": "Winn Parish",
    "state": "LA",
    "city": "Dodson"
  },
  {
    "value": "71423",
    "label": "Dry Prong - LA 71423",
    "county": "Grant Parish",
    "state": "LA",
    "city": "Dry Prong"
  },
  {
    "value": "71424",
    "label": "Elmer - LA 71424",
    "county": "Rapides Parish",
    "state": "LA",
    "city": "Elmer"
  },
  {
    "value": "71425",
    "label": "Enterprise - LA 71425",
    "county": "Catahoula Parish",
    "state": "LA",
    "city": "Enterprise"
  },
  {
    "value": "71427",
    "label": "Flatwoods - LA 71427",
    "county": "Rapides Parish",
    "state": "LA",
    "city": "Flatwoods"
  },
  {
    "value": "71429",
    "label": "Florien - LA 71429",
    "county": "Sabine Parish",
    "state": "LA",
    "city": "Florien"
  },
  {
    "value": "71430",
    "label": "Forest Hill - LA 71430",
    "county": "Rapides Parish",
    "state": "LA",
    "city": "Forest Hill"
  },
  {
    "value": "71432",
    "label": "Georgetown - LA 71432",
    "county": "Grant Parish",
    "state": "LA",
    "city": "Georgetown"
  },
  {
    "value": "71433",
    "label": "Glenmora - LA 71433",
    "county": "Rapides Parish",
    "state": "LA",
    "city": "Glenmora"
  },
  {
    "value": "71435",
    "label": "Grayson - LA 71435",
    "county": "Caldwell Parish",
    "state": "LA",
    "city": "Grayson"
  },
  {
    "value": "71438",
    "label": "Hineston - LA 71438",
    "county": "Rapides Parish",
    "state": "LA",
    "city": "Hineston"
  },
  {
    "value": "71439",
    "label": "Hornbeck - LA 71439",
    "county": "Vernon Parish",
    "state": "LA",
    "city": "Hornbeck"
  },
  {
    "value": "71441",
    "label": "Kelly - LA 71441",
    "county": "Caldwell Parish",
    "state": "LA",
    "city": "Kelly"
  },
  {
    "value": "71446",
    "label": "Leesville - LA 71446",
    "county": "Vernon Parish",
    "state": "LA",
    "city": "Leesville"
  },
  {
    "value": "71447",
    "label": "Lena - LA 71447",
    "county": "Rapides Parish",
    "state": "LA",
    "city": "Lena"
  },
  {
    "value": "71449",
    "label": "Many - LA 71449",
    "county": "Sabine Parish",
    "state": "LA",
    "city": "Many"
  },
  {
    "value": "71450",
    "label": "Marthaville - LA 71450",
    "county": "Natchitoches Parish",
    "state": "LA",
    "city": "Marthaville"
  },
  {
    "value": "71452",
    "label": "Melrose - LA 71452",
    "county": "Natchitoches Parish",
    "state": "LA",
    "city": "Melrose"
  },
  {
    "value": "71454",
    "label": "Montgomery - LA 71454",
    "county": "Grant Parish",
    "state": "LA",
    "city": "Montgomery"
  },
  {
    "value": "71455",
    "label": "Mora - LA 71455",
    "county": "Rapides Parish",
    "state": "LA",
    "city": "Mora"
  },
  {
    "value": "71456",
    "label": "Natchez - LA 71456",
    "county": "Natchitoches Parish",
    "state": "LA",
    "city": "Natchez"
  },
  {
    "value": "71457",
    "label": "Natchitoches - LA 71457",
    "county": "Natchitoches Parish",
    "state": "LA",
    "city": "Natchitoches"
  },
  {
    "value": "71459",
    "label": "Fort Polk - LA 71459",
    "county": "Vernon Parish",
    "state": "LA",
    "city": "Fort Polk"
  },
  {
    "value": "71461",
    "label": "New Llano - LA 71461",
    "county": "Vernon Parish",
    "state": "LA",
    "city": "New Llano"
  },
  {
    "value": "71462",
    "label": "Noble - LA 71462",
    "county": "Sabine Parish",
    "state": "LA",
    "city": "Noble"
  },
  {
    "value": "71463",
    "label": "Oakdale - LA 71463",
    "county": "Allen Parish",
    "state": "LA",
    "city": "Oakdale"
  },
  {
    "value": "71465",
    "label": "Olla - LA 71465",
    "county": "La Salle Parish",
    "state": "LA",
    "city": "Olla"
  },
  {
    "value": "71466",
    "label": "Otis - LA 71466",
    "county": "Rapides Parish",
    "state": "LA",
    "city": "Otis"
  },
  {
    "value": "71467",
    "label": "Pollock - LA 71467",
    "county": "Grant Parish",
    "state": "LA",
    "city": "Pollock"
  },
  {
    "value": "71468",
    "label": "Provencal - LA 71468",
    "county": "Natchitoches Parish",
    "state": "LA",
    "city": "Provencal"
  },
  {
    "value": "71469",
    "label": "Robeline - LA 71469",
    "county": "Natchitoches Parish",
    "state": "LA",
    "city": "Robeline"
  },
  {
    "value": "71472",
    "label": "Sieper - LA 71472",
    "county": "Rapides Parish",
    "state": "LA",
    "city": "Sieper"
  },
  {
    "value": "71473",
    "label": "Sikes - LA 71473",
    "county": "Winn Parish",
    "state": "LA",
    "city": "Sikes"
  },
  {
    "value": "71479",
    "label": "Tullos - LA 71479",
    "county": "La Salle Parish",
    "state": "LA",
    "city": "Tullos"
  },
  {
    "value": "71483",
    "label": "Winnfield - LA 71483",
    "county": "Winn Parish",
    "state": "LA",
    "city": "Winnfield"
  },
  {
    "value": "71485",
    "label": "Woodworth - LA 71485",
    "county": "Rapides Parish",
    "state": "LA",
    "city": "Woodworth"
  },
  {
    "value": "71486",
    "label": "Zwolle - LA 71486",
    "county": "Sabine Parish",
    "state": "LA",
    "city": "Zwolle"
  },
  {
    "value": "03901",
    "label": "Berwick - ME 03901",
    "county": "York County",
    "state": "ME",
    "city": "Berwick"
  },
  {
    "value": "03902",
    "label": "Cape Neddick - ME 03902",
    "county": "York County",
    "state": "ME",
    "city": "Cape Neddick"
  },
  {
    "value": "03903",
    "label": "Eliot - ME 03903",
    "county": "York County",
    "state": "ME",
    "city": "Eliot"
  },
  {
    "value": "03904",
    "label": "Kittery - ME 03904",
    "county": "York County",
    "state": "ME",
    "city": "Kittery"
  },
  {
    "value": "03905",
    "label": "Kittery Point - ME 03905",
    "county": "York County",
    "state": "ME",
    "city": "Kittery Point"
  },
  {
    "value": "03906",
    "label": "North Berwick - ME 03906",
    "county": "York County",
    "state": "ME",
    "city": "North Berwick"
  },
  {
    "value": "03907",
    "label": "Ogunquit - ME 03907",
    "county": "York County",
    "state": "ME",
    "city": "Ogunquit"
  },
  {
    "value": "03908",
    "label": "South Berwick - ME 03908",
    "county": "York County",
    "state": "ME",
    "city": "South Berwick"
  },
  {
    "value": "03909",
    "label": "York - ME 03909",
    "county": "York County",
    "state": "ME",
    "city": "York"
  },
  {
    "value": "04001",
    "label": "Acton - ME 04001",
    "county": "York County",
    "state": "ME",
    "city": "Acton"
  },
  {
    "value": "04002",
    "label": "Alfred - ME 04002",
    "county": "York County",
    "state": "ME",
    "city": "Alfred"
  },
  {
    "value": "04003",
    "label": "Bailey Island - ME 04003",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Bailey Island"
  },
  {
    "value": "04005",
    "label": "Biddeford - ME 04005",
    "county": "York County",
    "state": "ME",
    "city": "Biddeford"
  },
  {
    "value": "04008",
    "label": "Bowdoinham - ME 04008",
    "county": "Sagadahoc County",
    "state": "ME",
    "city": "Bowdoinham"
  },
  {
    "value": "04009",
    "label": "Bridgton - ME 04009",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Bridgton"
  },
  {
    "value": "04010",
    "label": "Brownfield - ME 04010",
    "county": "Oxford County",
    "state": "ME",
    "city": "Brownfield"
  },
  {
    "value": "04011",
    "label": "Brunswick - ME 04011",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Brunswick"
  },
  {
    "value": "04013",
    "label": "Bustins Island - ME 04013",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Bustins Island"
  },
  {
    "value": "04015",
    "label": "Casco - ME 04015",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Casco"
  },
  {
    "value": "04017",
    "label": "Chebeague Island - ME 04017",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Chebeague Island"
  },
  {
    "value": "04020",
    "label": "Cornish - ME 04020",
    "county": "York County",
    "state": "ME",
    "city": "Cornish"
  },
  {
    "value": "04021",
    "label": "Cumberland Center - ME 04021",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Cumberland Center"
  },
  {
    "value": "04022",
    "label": "Denmark - ME 04022",
    "county": "Oxford County",
    "state": "ME",
    "city": "Denmark"
  },
  {
    "value": "04024",
    "label": "East Baldwin - ME 04024",
    "county": "Cumberland County",
    "state": "ME",
    "city": "East Baldwin"
  },
  {
    "value": "04027",
    "label": "Lebanon - ME 04027",
    "county": "York County",
    "state": "ME",
    "city": "Lebanon"
  },
  {
    "value": "04029",
    "label": "Sebago - ME 04029",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Sebago"
  },
  {
    "value": "04030",
    "label": "East Waterboro - ME 04030",
    "county": "York County",
    "state": "ME",
    "city": "East Waterboro"
  },
  {
    "value": "04032",
    "label": "Freeport - ME 04032",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Freeport"
  },
  {
    "value": "04037",
    "label": "Fryeburg - ME 04037",
    "county": "Oxford County",
    "state": "ME",
    "city": "Fryeburg"
  },
  {
    "value": "04038",
    "label": "Gorham - ME 04038",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Gorham"
  },
  {
    "value": "04039",
    "label": "Gray - ME 04039",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Gray"
  },
  {
    "value": "04040",
    "label": "Harrison - ME 04040",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Harrison"
  },
  {
    "value": "04041",
    "label": "Hiram - ME 04041",
    "county": "Oxford County",
    "state": "ME",
    "city": "Hiram"
  },
  {
    "value": "04042",
    "label": "Hollis Center - ME 04042",
    "county": "York County",
    "state": "ME",
    "city": "Hollis Center"
  },
  {
    "value": "04043",
    "label": "Kennebunk - ME 04043",
    "county": "York County",
    "state": "ME",
    "city": "Kennebunk"
  },
  {
    "value": "04046",
    "label": "Kennebunkport - ME 04046",
    "county": "York County",
    "state": "ME",
    "city": "Kennebunkport"
  },
  {
    "value": "04047",
    "label": "Parsonsfield - ME 04047",
    "county": "York County",
    "state": "ME",
    "city": "Parsonsfield"
  },
  {
    "value": "04048",
    "label": "Limerick - ME 04048",
    "county": "York County",
    "state": "ME",
    "city": "Limerick"
  },
  {
    "value": "04049",
    "label": "Limington - ME 04049",
    "county": "York County",
    "state": "ME",
    "city": "Limington"
  },
  {
    "value": "04050",
    "label": "Long Island - ME 04050",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Long Island"
  },
  {
    "value": "04051",
    "label": "Lovell - ME 04051",
    "county": "Oxford County",
    "state": "ME",
    "city": "Lovell"
  },
  {
    "value": "04055",
    "label": "Naples - ME 04055",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Naples"
  },
  {
    "value": "04061",
    "label": "North Waterboro - ME 04061",
    "county": "York County",
    "state": "ME",
    "city": "North Waterboro"
  },
  {
    "value": "04062",
    "label": "Windham - ME 04062",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Windham"
  },
  {
    "value": "04064",
    "label": "Old Orchard Beach - ME 04064",
    "county": "York County",
    "state": "ME",
    "city": "Old Orchard Beach"
  },
  {
    "value": "04066",
    "label": "Orrs Island - ME 04066",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Orrs Island"
  },
  {
    "value": "04068",
    "label": "Porter - ME 04068",
    "county": "Oxford County",
    "state": "ME",
    "city": "Porter"
  },
  {
    "value": "04069",
    "label": "Pownal - ME 04069",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Pownal"
  },
  {
    "value": "04071",
    "label": "Raymond - ME 04071",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Raymond"
  },
  {
    "value": "04072",
    "label": "Saco - ME 04072",
    "county": "York County",
    "state": "ME",
    "city": "Saco"
  },
  {
    "value": "04073",
    "label": "Sanford - ME 04073",
    "county": "York County",
    "state": "ME",
    "city": "Sanford"
  },
  {
    "value": "04074",
    "label": "Scarborough - ME 04074",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Scarborough"
  },
  {
    "value": "04075",
    "label": "Sebago Lake - ME 04075",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Sebago Lake"
  },
  {
    "value": "04076",
    "label": "Shapleigh - ME 04076",
    "county": "York County",
    "state": "ME",
    "city": "Shapleigh"
  },
  {
    "value": "04079",
    "label": "Harpswell - ME 04079",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Harpswell"
  },
  {
    "value": "04083",
    "label": "Springvale - ME 04083",
    "county": "York County",
    "state": "ME",
    "city": "Springvale"
  },
  {
    "value": "04084",
    "label": "Standish - ME 04084",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Standish"
  },
  {
    "value": "04085",
    "label": "Steep Falls - ME 04085",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Steep Falls"
  },
  {
    "value": "04086",
    "label": "Topsham - ME 04086",
    "county": "Sagadahoc County",
    "state": "ME",
    "city": "Topsham"
  },
  {
    "value": "04087",
    "label": "Waterboro - ME 04087",
    "county": "York County",
    "state": "ME",
    "city": "Waterboro"
  },
  {
    "value": "04088",
    "label": "Waterford - ME 04088",
    "county": "Oxford County",
    "state": "ME",
    "city": "Waterford"
  },
  {
    "value": "04090",
    "label": "Wells - ME 04090",
    "county": "York County",
    "state": "ME",
    "city": "Wells"
  },
  {
    "value": "04091",
    "label": "West Baldwin - ME 04091",
    "county": "Cumberland County",
    "state": "ME",
    "city": "West Baldwin"
  },
  {
    "value": "04092",
    "label": "Westbrook - ME 04092",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Westbrook"
  },
  {
    "value": "04093",
    "label": "Buxton - ME 04093",
    "county": "York County",
    "state": "ME",
    "city": "Buxton"
  },
  {
    "value": "04095",
    "label": "West Newfield - ME 04095",
    "county": "York County",
    "state": "ME",
    "city": "West Newfield"
  },
  {
    "value": "04096",
    "label": "Yarmouth - ME 04096",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Yarmouth"
  },
  {
    "value": "04097",
    "label": "North Yarmouth - ME 04097",
    "county": "Cumberland County",
    "state": "ME",
    "city": "North Yarmouth"
  },
  {
    "value": "04101",
    "label": "Portland - ME 04101",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Portland"
  },
  {
    "value": "04102",
    "label": "Portland - ME 04102",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Portland"
  },
  {
    "value": "04103",
    "label": "Portland - ME 04103",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Portland"
  },
  {
    "value": "04105",
    "label": "Falmouth - ME 04105",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Falmouth"
  },
  {
    "value": "04106",
    "label": "South Portland - ME 04106",
    "county": "Cumberland County",
    "state": "ME",
    "city": "South Portland"
  },
  {
    "value": "04107",
    "label": "Cape Elizabeth - ME 04107",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Cape Elizabeth"
  },
  {
    "value": "04108",
    "label": "Peaks Island - ME 04108",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Peaks Island"
  },
  {
    "value": "04109",
    "label": "Portland - ME 04109",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Portland"
  },
  {
    "value": "04110",
    "label": "Cumberland Foreside - ME 04110",
    "county": "Cumberland County",
    "state": "ME",
    "city": "Cumberland Foreside"
  },
  {
    "value": "04210",
    "label": "Auburn - ME 04210",
    "county": "Androscoggin County",
    "state": "ME",
    "city": "Auburn"
  },
  {
    "value": "04216",
    "label": "Andover - ME 04216",
    "county": "Oxford County",
    "state": "ME",
    "city": "Andover"
  },
  {
    "value": "04217",
    "label": "Bethel - ME 04217",
    "county": "Oxford County",
    "state": "ME",
    "city": "Bethel"
  },
  {
    "value": "04219",
    "label": "Bryant Pond - ME 04219",
    "county": "Oxford County",
    "state": "ME",
    "city": "Bryant Pond"
  },
  {
    "value": "04220",
    "label": "Buckfield - ME 04220",
    "county": "Oxford County",
    "state": "ME",
    "city": "Buckfield"
  },
  {
    "value": "04221",
    "label": "Canton - ME 04221",
    "county": "Oxford County",
    "state": "ME",
    "city": "Canton"
  },
  {
    "value": "04222",
    "label": "Durham - ME 04222",
    "county": "Androscoggin County",
    "state": "ME",
    "city": "Durham"
  },
  {
    "value": "04224",
    "label": "Dixfield - ME 04224",
    "county": "Oxford County",
    "state": "ME",
    "city": "Dixfield"
  },
  {
    "value": "04226",
    "label": "East Andover - ME 04226",
    "county": "Oxford County",
    "state": "ME",
    "city": "East Andover"
  },
  {
    "value": "04231",
    "label": "Stoneham - ME 04231",
    "county": "Oxford County",
    "state": "ME",
    "city": "Stoneham"
  },
  {
    "value": "04236",
    "label": "Greene - ME 04236",
    "county": "Androscoggin County",
    "state": "ME",
    "city": "Greene"
  },
  {
    "value": "04237",
    "label": "Hanover - ME 04237",
    "county": "Oxford County",
    "state": "ME",
    "city": "Hanover"
  },
  {
    "value": "04238",
    "label": "Hebron - ME 04238",
    "county": "Oxford County",
    "state": "ME",
    "city": "Hebron"
  },
  {
    "value": "04239",
    "label": "Jay - ME 04239",
    "county": "Franklin County",
    "state": "ME",
    "city": "Jay"
  },
  {
    "value": "04240",
    "label": "Lewiston - ME 04240",
    "county": "Androscoggin County",
    "state": "ME",
    "city": "Lewiston"
  },
  {
    "value": "04250",
    "label": "Lisbon - ME 04250",
    "county": "Androscoggin County",
    "state": "ME",
    "city": "Lisbon"
  },
  {
    "value": "04252",
    "label": "Lisbon Falls - ME 04252",
    "county": "Androscoggin County",
    "state": "ME",
    "city": "Lisbon Falls"
  },
  {
    "value": "04253",
    "label": "Livermore - ME 04253",
    "county": "Androscoggin County",
    "state": "ME",
    "city": "Livermore"
  },
  {
    "value": "04254",
    "label": "Livermore Falls - ME 04254",
    "county": "Androscoggin County",
    "state": "ME",
    "city": "Livermore Falls"
  },
  {
    "value": "04255",
    "label": "Greenwood - ME 04255",
    "county": "Oxford County",
    "state": "ME",
    "city": "Greenwood"
  },
  {
    "value": "04256",
    "label": "Mechanic Falls - ME 04256",
    "county": "Androscoggin County",
    "state": "ME",
    "city": "Mechanic Falls"
  },
  {
    "value": "04257",
    "label": "Mexico - ME 04257",
    "county": "Oxford County",
    "state": "ME",
    "city": "Mexico"
  },
  {
    "value": "04258",
    "label": "Minot - ME 04258",
    "county": "Androscoggin County",
    "state": "ME",
    "city": "Minot"
  },
  {
    "value": "04259",
    "label": "Monmouth - ME 04259",
    "county": "Kennebec County",
    "state": "ME",
    "city": "Monmouth"
  },
  {
    "value": "04260",
    "label": "New Gloucester - ME 04260",
    "county": "Cumberland County",
    "state": "ME",
    "city": "New Gloucester"
  },
  {
    "value": "04261",
    "label": "Newry - ME 04261",
    "county": "Oxford County",
    "state": "ME",
    "city": "Newry"
  },
  {
    "value": "04263",
    "label": "Leeds - ME 04263",
    "county": "Androscoggin County",
    "state": "ME",
    "city": "Leeds"
  },
  {
    "value": "04265",
    "label": "North Monmouth - ME 04265",
    "county": "Kennebec County",
    "state": "ME",
    "city": "North Monmouth"
  },
  {
    "value": "04268",
    "label": "Norway - ME 04268",
    "county": "Oxford County",
    "state": "ME",
    "city": "Norway"
  },
  {
    "value": "04270",
    "label": "Oxford - ME 04270",
    "county": "Oxford County",
    "state": "ME",
    "city": "Oxford"
  },
  {
    "value": "04274",
    "label": "Poland - ME 04274",
    "county": "Androscoggin County",
    "state": "ME",
    "city": "Poland"
  },
  {
    "value": "04275",
    "label": "Roxbury - ME 04275",
    "county": "Oxford County",
    "state": "ME",
    "city": "Roxbury"
  },
  {
    "value": "04276",
    "label": "Rumford - ME 04276",
    "county": "Oxford County",
    "state": "ME",
    "city": "Rumford"
  },
  {
    "value": "04280",
    "label": "Sabattus - ME 04280",
    "county": "Androscoggin County",
    "state": "ME",
    "city": "Sabattus"
  },
  {
    "value": "04281",
    "label": "South Paris - ME 04281",
    "county": "Oxford County",
    "state": "ME",
    "city": "South Paris"
  },
  {
    "value": "04282",
    "label": "Turner - ME 04282",
    "county": "Androscoggin County",
    "state": "ME",
    "city": "Turner"
  },
  {
    "value": "04284",
    "label": "Wayne - ME 04284",
    "county": "Kennebec County",
    "state": "ME",
    "city": "Wayne"
  },
  {
    "value": "04285",
    "label": "Weld - ME 04285",
    "county": "Franklin County",
    "state": "ME",
    "city": "Weld"
  },
  {
    "value": "04287",
    "label": "Bowdoin - ME 04287",
    "county": "Sagadahoc County",
    "state": "ME",
    "city": "Bowdoin"
  },
  {
    "value": "04289",
    "label": "West Paris - ME 04289",
    "county": "Oxford County",
    "state": "ME",
    "city": "West Paris"
  },
  {
    "value": "04290",
    "label": "Peru - ME 04290",
    "county": "Oxford County",
    "state": "ME",
    "city": "Peru"
  },
  {
    "value": "04292",
    "label": "Sumner - ME 04292",
    "county": "Oxford County",
    "state": "ME",
    "city": "Sumner"
  },
  {
    "value": "04294",
    "label": "Wilton - ME 04294",
    "county": "Franklin County",
    "state": "ME",
    "city": "Wilton"
  },
  {
    "value": "04330",
    "label": "Augusta - ME 04330",
    "county": "Kennebec County",
    "state": "ME",
    "city": "Augusta"
  },
  {
    "value": "04342",
    "label": "Dresden - ME 04342",
    "county": "Lincoln County",
    "state": "ME",
    "city": "Dresden"
  },
  {
    "value": "04344",
    "label": "Farmingdale - ME 04344",
    "county": "Kennebec County",
    "state": "ME",
    "city": "Farmingdale"
  },
  {
    "value": "04345",
    "label": "Gardiner - ME 04345",
    "county": "Kennebec County",
    "state": "ME",
    "city": "Gardiner"
  },
  {
    "value": "04346",
    "label": "Randolph - ME 04346",
    "county": "Kennebec County",
    "state": "ME",
    "city": "Randolph"
  },
  {
    "value": "04347",
    "label": "Hallowell - ME 04347",
    "county": "Kennebec County",
    "state": "ME",
    "city": "Hallowell"
  },
  {
    "value": "04348",
    "label": "Jefferson - ME 04348",
    "county": "Lincoln County",
    "state": "ME",
    "city": "Jefferson"
  },
  {
    "value": "04349",
    "label": "Kents Hill - ME 04349",
    "county": "Kennebec County",
    "state": "ME",
    "city": "Kents Hill"
  },
  {
    "value": "04350",
    "label": "Litchfield - ME 04350",
    "county": "Kennebec County",
    "state": "ME",
    "city": "Litchfield"
  },
  {
    "value": "04351",
    "label": "Manchester - ME 04351",
    "county": "Kennebec County",
    "state": "ME",
    "city": "Manchester"
  },
  {
    "value": "04352",
    "label": "Mount Vernon - ME 04352",
    "county": "Kennebec County",
    "state": "ME",
    "city": "Mount Vernon"
  },
  {
    "value": "04353",
    "label": "Whitefield - ME 04353",
    "county": "Lincoln County",
    "state": "ME",
    "city": "Whitefield"
  },
  {
    "value": "04354",
    "label": "Palermo - ME 04354",
    "county": "Waldo County",
    "state": "ME",
    "city": "Palermo"
  },
  {
    "value": "04355",
    "label": "Readfield - ME 04355",
    "county": "Kennebec County",
    "state": "ME",
    "city": "Readfield"
  },
  {
    "value": "04357",
    "label": "Richmond - ME 04357",
    "county": "Sagadahoc County",
    "state": "ME",
    "city": "Richmond"
  },
  {
    "value": "04358",
    "label": "South China - ME 04358",
    "county": "Kennebec County",
    "state": "ME",
    "city": "South China"
  },
  {
    "value": "04360",
    "label": "Vienna - ME 04360",
    "county": "Kennebec County",
    "state": "ME",
    "city": "Vienna"
  },
  {
    "value": "04363",
    "label": "Windsor - ME 04363",
    "county": "Kennebec County",
    "state": "ME",
    "city": "Windsor"
  },
  {
    "value": "04364",
    "label": "Winthrop - ME 04364",
    "county": "Kennebec County",
    "state": "ME",
    "city": "Winthrop"
  },
  {
    "value": "04401",
    "label": "Bangor - ME 04401",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Bangor"
  },
  {
    "value": "04406",
    "label": "Abbot - ME 04406",
    "county": "Piscataquis County",
    "state": "ME",
    "city": "Abbot"
  },
  {
    "value": "04408",
    "label": "Aurora - ME 04408",
    "county": "Hancock County",
    "state": "ME",
    "city": "Aurora"
  },
  {
    "value": "04410",
    "label": "Bradford - ME 04410",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Bradford"
  },
  {
    "value": "04411",
    "label": "Bradley - ME 04411",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Bradley"
  },
  {
    "value": "04412",
    "label": "Brewer - ME 04412",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Brewer"
  },
  {
    "value": "04413",
    "label": "Brookton - ME 04413",
    "county": "Washington County",
    "state": "ME",
    "city": "Brookton"
  },
  {
    "value": "04414",
    "label": "Brownville - ME 04414",
    "county": "Piscataquis County",
    "state": "ME",
    "city": "Brownville"
  },
  {
    "value": "04416",
    "label": "Bucksport - ME 04416",
    "county": "Hancock County",
    "state": "ME",
    "city": "Bucksport"
  },
  {
    "value": "04417",
    "label": "Burlington - ME 04417",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Burlington"
  },
  {
    "value": "04418",
    "label": "Greenbush - ME 04418",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Greenbush"
  },
  {
    "value": "04419",
    "label": "Carmel - ME 04419",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Carmel"
  },
  {
    "value": "04421",
    "label": "Castine - ME 04421",
    "county": "Hancock County",
    "state": "ME",
    "city": "Castine"
  },
  {
    "value": "04422",
    "label": "Charleston - ME 04422",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Charleston"
  },
  {
    "value": "04424",
    "label": "Danforth - ME 04424",
    "county": "Washington County",
    "state": "ME",
    "city": "Danforth"
  },
  {
    "value": "04426",
    "label": "Dover Foxcroft - ME 04426",
    "county": "Piscataquis County",
    "state": "ME",
    "city": "Dover Foxcroft"
  },
  {
    "value": "04427",
    "label": "Corinth - ME 04427",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Corinth"
  },
  {
    "value": "04428",
    "label": "Eddington - ME 04428",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Eddington"
  },
  {
    "value": "04429",
    "label": "Holden - ME 04429",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Holden"
  },
  {
    "value": "04430",
    "label": "East Millinocket - ME 04430",
    "county": "Penobscot County",
    "state": "ME",
    "city": "East Millinocket"
  },
  {
    "value": "04434",
    "label": "Etna - ME 04434",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Etna"
  },
  {
    "value": "04435",
    "label": "Exeter - ME 04435",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Exeter"
  },
  {
    "value": "04438",
    "label": "Frankfort - ME 04438",
    "county": "Waldo County",
    "state": "ME",
    "city": "Frankfort"
  },
  {
    "value": "04441",
    "label": "Greenville - ME 04441",
    "county": "Piscataquis County",
    "state": "ME",
    "city": "Greenville"
  },
  {
    "value": "04442",
    "label": "Greenville Junction - ME 04442",
    "county": "Piscataquis County",
    "state": "ME",
    "city": "Greenville Junction"
  },
  {
    "value": "04443",
    "label": "Guilford - ME 04443",
    "county": "Piscataquis County",
    "state": "ME",
    "city": "Guilford"
  },
  {
    "value": "04444",
    "label": "Hampden - ME 04444",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Hampden"
  },
  {
    "value": "04448",
    "label": "Howland - ME 04448",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Howland"
  },
  {
    "value": "04449",
    "label": "Hudson - ME 04449",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Hudson"
  },
  {
    "value": "04450",
    "label": "Kenduskeag - ME 04450",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Kenduskeag"
  },
  {
    "value": "04451",
    "label": "Kingman - ME 04451",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Kingman"
  },
  {
    "value": "04453",
    "label": "Lagrange - ME 04453",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Lagrange"
  },
  {
    "value": "04455",
    "label": "Lee - ME 04455",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Lee"
  },
  {
    "value": "04456",
    "label": "Levant - ME 04456",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Levant"
  },
  {
    "value": "04457",
    "label": "Lincoln - ME 04457",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Lincoln"
  },
  {
    "value": "04459",
    "label": "Mattawamkeag - ME 04459",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Mattawamkeag"
  },
  {
    "value": "04460",
    "label": "Medway - ME 04460",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Medway"
  },
  {
    "value": "04461",
    "label": "Milford - ME 04461",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Milford"
  },
  {
    "value": "04462",
    "label": "Millinocket - ME 04462",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Millinocket"
  },
  {
    "value": "04463",
    "label": "Milo - ME 04463",
    "county": "Piscataquis County",
    "state": "ME",
    "city": "Milo"
  },
  {
    "value": "04464",
    "label": "Monson - ME 04464",
    "county": "Piscataquis County",
    "state": "ME",
    "city": "Monson"
  },
  {
    "value": "04468",
    "label": "Old Town - ME 04468",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Old Town"
  },
  {
    "value": "04471",
    "label": "Orient - ME 04471",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Orient"
  },
  {
    "value": "04472",
    "label": "Orland - ME 04472",
    "county": "Hancock County",
    "state": "ME",
    "city": "Orland"
  },
  {
    "value": "04473",
    "label": "Orono - ME 04473",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Orono"
  },
  {
    "value": "04474",
    "label": "Orrington - ME 04474",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Orrington"
  },
  {
    "value": "04475",
    "label": "Passadumkeag - ME 04475",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Passadumkeag"
  },
  {
    "value": "04476",
    "label": "Penobscot - ME 04476",
    "county": "Hancock County",
    "state": "ME",
    "city": "Penobscot"
  },
  {
    "value": "04478",
    "label": "Rockwood - ME 04478",
    "county": "Somerset County",
    "state": "ME",
    "city": "Rockwood"
  },
  {
    "value": "04479",
    "label": "Sangerville - ME 04479",
    "county": "Piscataquis County",
    "state": "ME",
    "city": "Sangerville"
  },
  {
    "value": "04481",
    "label": "Sebec - ME 04481",
    "county": "Piscataquis County",
    "state": "ME",
    "city": "Sebec"
  },
  {
    "value": "04487",
    "label": "Springfield - ME 04487",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Springfield"
  },
  {
    "value": "04488",
    "label": "Stetson - ME 04488",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Stetson"
  },
  {
    "value": "04490",
    "label": "Topsfield - ME 04490",
    "county": "Washington County",
    "state": "ME",
    "city": "Topsfield"
  },
  {
    "value": "04492",
    "label": "Waite - ME 04492",
    "county": "Washington County",
    "state": "ME",
    "city": "Waite"
  },
  {
    "value": "04493",
    "label": "West Enfield - ME 04493",
    "county": "Penobscot County",
    "state": "ME",
    "city": "West Enfield"
  },
  {
    "value": "04495",
    "label": "Winn - ME 04495",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Winn"
  },
  {
    "value": "04496",
    "label": "Winterport - ME 04496",
    "county": "Waldo County",
    "state": "ME",
    "city": "Winterport"
  },
  {
    "value": "04497",
    "label": "Wytopitlock - ME 04497",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Wytopitlock"
  },
  {
    "value": "04530",
    "label": "Bath - ME 04530",
    "county": "Sagadahoc County",
    "state": "ME",
    "city": "Bath"
  },
  {
    "value": "04535",
    "label": "Alna - ME 04535",
    "county": "Lincoln County",
    "state": "ME",
    "city": "Alna"
  },
  {
    "value": "04537",
    "label": "Boothbay - ME 04537",
    "county": "Lincoln County",
    "state": "ME",
    "city": "Boothbay"
  },
  {
    "value": "04538",
    "label": "Boothbay Harbor - ME 04538",
    "county": "Lincoln County",
    "state": "ME",
    "city": "Boothbay Harbor"
  },
  {
    "value": "04539",
    "label": "Bristol - ME 04539",
    "county": "Lincoln County",
    "state": "ME",
    "city": "Bristol"
  },
  {
    "value": "04541",
    "label": "Chamberlain - ME 04541",
    "county": "Lincoln County",
    "state": "ME",
    "city": "Chamberlain"
  },
  {
    "value": "04543",
    "label": "Damariscotta - ME 04543",
    "county": "Lincoln County",
    "state": "ME",
    "city": "Damariscotta"
  },
  {
    "value": "04544",
    "label": "East Boothbay - ME 04544",
    "county": "Lincoln County",
    "state": "ME",
    "city": "East Boothbay"
  },
  {
    "value": "04547",
    "label": "Friendship - ME 04547",
    "county": "Knox County",
    "state": "ME",
    "city": "Friendship"
  },
  {
    "value": "04548",
    "label": "Georgetown - ME 04548",
    "county": "Sagadahoc County",
    "state": "ME",
    "city": "Georgetown"
  },
  {
    "value": "04549",
    "label": "Isle Of Springs - ME 04549",
    "county": "Lincoln County",
    "state": "ME",
    "city": "Isle Of Springs"
  },
  {
    "value": "04551",
    "label": "Bremen - ME 04551",
    "county": "Lincoln County",
    "state": "ME",
    "city": "Bremen"
  },
  {
    "value": "04553",
    "label": "Newcastle - ME 04553",
    "county": "Lincoln County",
    "state": "ME",
    "city": "Newcastle"
  },
  {
    "value": "04554",
    "label": "New Harbor - ME 04554",
    "county": "Lincoln County",
    "state": "ME",
    "city": "New Harbor"
  },
  {
    "value": "04555",
    "label": "Nobleboro - ME 04555",
    "county": "Lincoln County",
    "state": "ME",
    "city": "Nobleboro"
  },
  {
    "value": "04556",
    "label": "Edgecomb - ME 04556",
    "county": "Lincoln County",
    "state": "ME",
    "city": "Edgecomb"
  },
  {
    "value": "04558",
    "label": "Pemaquid - ME 04558",
    "county": "Lincoln County",
    "state": "ME",
    "city": "Pemaquid"
  },
  {
    "value": "04562",
    "label": "Phippsburg - ME 04562",
    "county": "Sagadahoc County",
    "state": "ME",
    "city": "Phippsburg"
  },
  {
    "value": "04563",
    "label": "Cushing - ME 04563",
    "county": "Knox County",
    "state": "ME",
    "city": "Cushing"
  },
  {
    "value": "04564",
    "label": "Round Pond - ME 04564",
    "county": "Lincoln County",
    "state": "ME",
    "city": "Round Pond"
  },
  {
    "value": "04568",
    "label": "South Bristol - ME 04568",
    "county": "Lincoln County",
    "state": "ME",
    "city": "South Bristol"
  },
  {
    "value": "04571",
    "label": "Trevett - ME 04571",
    "county": "Lincoln County",
    "state": "ME",
    "city": "Trevett"
  },
  {
    "value": "04572",
    "label": "Waldoboro - ME 04572",
    "county": "Lincoln County",
    "state": "ME",
    "city": "Waldoboro"
  },
  {
    "value": "04573",
    "label": "Walpole - ME 04573",
    "county": "Lincoln County",
    "state": "ME",
    "city": "Walpole"
  },
  {
    "value": "04574",
    "label": "Washington - ME 04574",
    "county": "Knox County",
    "state": "ME",
    "city": "Washington"
  },
  {
    "value": "04576",
    "label": "Southport - ME 04576",
    "county": "Lincoln County",
    "state": "ME",
    "city": "Southport"
  },
  {
    "value": "04578",
    "label": "Wiscasset - ME 04578",
    "county": "Lincoln County",
    "state": "ME",
    "city": "Wiscasset"
  },
  {
    "value": "04579",
    "label": "Woolwich - ME 04579",
    "county": "Sagadahoc County",
    "state": "ME",
    "city": "Woolwich"
  },
  {
    "value": "04605",
    "label": "Ellsworth - ME 04605",
    "county": "Hancock County",
    "state": "ME",
    "city": "Ellsworth"
  },
  {
    "value": "04606",
    "label": "Addison - ME 04606",
    "county": "Washington County",
    "state": "ME",
    "city": "Addison"
  },
  {
    "value": "04607",
    "label": "Gouldsboro - ME 04607",
    "county": "Hancock County",
    "state": "ME",
    "city": "Gouldsboro"
  },
  {
    "value": "04609",
    "label": "Bar Harbor - ME 04609",
    "county": "Hancock County",
    "state": "ME",
    "city": "Bar Harbor"
  },
  {
    "value": "04612",
    "label": "Bernard - ME 04612",
    "county": "Hancock County",
    "state": "ME",
    "city": "Bernard"
  },
  {
    "value": "04613",
    "label": "Birch Harbor - ME 04613",
    "county": "Hancock County",
    "state": "ME",
    "city": "Birch Harbor"
  },
  {
    "value": "04614",
    "label": "Blue Hill - ME 04614",
    "county": "Hancock County",
    "state": "ME",
    "city": "Blue Hill"
  },
  {
    "value": "04616",
    "label": "Brooklin - ME 04616",
    "county": "Hancock County",
    "state": "ME",
    "city": "Brooklin"
  },
  {
    "value": "04617",
    "label": "Brooksville - ME 04617",
    "county": "Hancock County",
    "state": "ME",
    "city": "Brooksville"
  },
  {
    "value": "04619",
    "label": "Calais - ME 04619",
    "county": "Washington County",
    "state": "ME",
    "city": "Calais"
  },
  {
    "value": "04622",
    "label": "Cherryfield - ME 04622",
    "county": "Washington County",
    "state": "ME",
    "city": "Cherryfield"
  },
  {
    "value": "04623",
    "label": "Columbia Falls - ME 04623",
    "county": "Washington County",
    "state": "ME",
    "city": "Columbia Falls"
  },
  {
    "value": "04624",
    "label": "Corea - ME 04624",
    "county": "Hancock County",
    "state": "ME",
    "city": "Corea"
  },
  {
    "value": "04626",
    "label": "Cutler - ME 04626",
    "county": "Washington County",
    "state": "ME",
    "city": "Cutler"
  },
  {
    "value": "04627",
    "label": "Deer Isle - ME 04627",
    "county": "Hancock County",
    "state": "ME",
    "city": "Deer Isle"
  },
  {
    "value": "04628",
    "label": "Dennysville - ME 04628",
    "county": "Washington County",
    "state": "ME",
    "city": "Dennysville"
  },
  {
    "value": "04630",
    "label": "East Machias - ME 04630",
    "county": "Washington County",
    "state": "ME",
    "city": "East Machias"
  },
  {
    "value": "04631",
    "label": "Eastport - ME 04631",
    "county": "Washington County",
    "state": "ME",
    "city": "Eastport"
  },
  {
    "value": "04634",
    "label": "Franklin - ME 04634",
    "county": "Hancock County",
    "state": "ME",
    "city": "Franklin"
  },
  {
    "value": "04640",
    "label": "Hancock - ME 04640",
    "county": "Hancock County",
    "state": "ME",
    "city": "Hancock"
  },
  {
    "value": "04642",
    "label": "Harborside - ME 04642",
    "county": "Hancock County",
    "state": "ME",
    "city": "Harborside"
  },
  {
    "value": "04643",
    "label": "Harrington - ME 04643",
    "county": "Washington County",
    "state": "ME",
    "city": "Harrington"
  },
  {
    "value": "04648",
    "label": "Jonesboro - ME 04648",
    "county": "Washington County",
    "state": "ME",
    "city": "Jonesboro"
  },
  {
    "value": "04649",
    "label": "Jonesport - ME 04649",
    "county": "Washington County",
    "state": "ME",
    "city": "Jonesport"
  },
  {
    "value": "04650",
    "label": "Little Deer Isle - ME 04650",
    "county": "Hancock County",
    "state": "ME",
    "city": "Little Deer Isle"
  },
  {
    "value": "04652",
    "label": "Lubec - ME 04652",
    "county": "Washington County",
    "state": "ME",
    "city": "Lubec"
  },
  {
    "value": "04653",
    "label": "Bass Harbor - ME 04653",
    "county": "Hancock County",
    "state": "ME",
    "city": "Bass Harbor"
  },
  {
    "value": "04654",
    "label": "Machias - ME 04654",
    "county": "Washington County",
    "state": "ME",
    "city": "Machias"
  },
  {
    "value": "04655",
    "label": "Machiasport - ME 04655",
    "county": "Washington County",
    "state": "ME",
    "city": "Machiasport"
  },
  {
    "value": "04657",
    "label": "Meddybemps - ME 04657",
    "county": "Washington County",
    "state": "ME",
    "city": "Meddybemps"
  },
  {
    "value": "04658",
    "label": "Milbridge - ME 04658",
    "county": "Washington County",
    "state": "ME",
    "city": "Milbridge"
  },
  {
    "value": "04660",
    "label": "Mount Desert - ME 04660",
    "county": "Hancock County",
    "state": "ME",
    "city": "Mount Desert"
  },
  {
    "value": "04664",
    "label": "Sullivan - ME 04664",
    "county": "Hancock County",
    "state": "ME",
    "city": "Sullivan"
  },
  {
    "value": "04666",
    "label": "Pembroke - ME 04666",
    "county": "Washington County",
    "state": "ME",
    "city": "Pembroke"
  },
  {
    "value": "04667",
    "label": "Perry - ME 04667",
    "county": "Washington County",
    "state": "ME",
    "city": "Perry"
  },
  {
    "value": "04668",
    "label": "Princeton - ME 04668",
    "county": "Washington County",
    "state": "ME",
    "city": "Princeton"
  },
  {
    "value": "04669",
    "label": "Prospect Harbor - ME 04669",
    "county": "Hancock County",
    "state": "ME",
    "city": "Prospect Harbor"
  },
  {
    "value": "04671",
    "label": "Robbinston - ME 04671",
    "county": "Washington County",
    "state": "ME",
    "city": "Robbinston"
  },
  {
    "value": "04673",
    "label": "Sargentville - ME 04673",
    "county": "Hancock County",
    "state": "ME",
    "city": "Sargentville"
  },
  {
    "value": "04674",
    "label": "Seal Cove - ME 04674",
    "county": "Hancock County",
    "state": "ME",
    "city": "Seal Cove"
  },
  {
    "value": "04676",
    "label": "Sedgwick - ME 04676",
    "county": "Hancock County",
    "state": "ME",
    "city": "Sedgwick"
  },
  {
    "value": "04677",
    "label": "Sorrento - ME 04677",
    "county": "Hancock County",
    "state": "ME",
    "city": "Sorrento"
  },
  {
    "value": "04679",
    "label": "Southwest Harbor - ME 04679",
    "county": "Hancock County",
    "state": "ME",
    "city": "Southwest Harbor"
  },
  {
    "value": "04680",
    "label": "Steuben - ME 04680",
    "county": "Washington County",
    "state": "ME",
    "city": "Steuben"
  },
  {
    "value": "04681",
    "label": "Stonington - ME 04681",
    "county": "Hancock County",
    "state": "ME",
    "city": "Stonington"
  },
  {
    "value": "04683",
    "label": "Sunset - ME 04683",
    "county": "Hancock County",
    "state": "ME",
    "city": "Sunset"
  },
  {
    "value": "04684",
    "label": "Surry - ME 04684",
    "county": "Hancock County",
    "state": "ME",
    "city": "Surry"
  },
  {
    "value": "04685",
    "label": "Swans Island - ME 04685",
    "county": "Hancock County",
    "state": "ME",
    "city": "Swans Island"
  },
  {
    "value": "04686",
    "label": "Wesley - ME 04686",
    "county": "Washington County",
    "state": "ME",
    "city": "Wesley"
  },
  {
    "value": "04691",
    "label": "Whiting - ME 04691",
    "county": "Washington County",
    "state": "ME",
    "city": "Whiting"
  },
  {
    "value": "04693",
    "label": "Winter Harbor - ME 04693",
    "county": "Hancock County",
    "state": "ME",
    "city": "Winter Harbor"
  },
  {
    "value": "04694",
    "label": "Baileyville - ME 04694",
    "county": "Washington County",
    "state": "ME",
    "city": "Baileyville"
  },
  {
    "value": "04730",
    "label": "Houlton - ME 04730",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Houlton"
  },
  {
    "value": "04732",
    "label": "Ashland - ME 04732",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Ashland"
  },
  {
    "value": "04733",
    "label": "Benedicta - ME 04733",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Benedicta"
  },
  {
    "value": "04735",
    "label": "Bridgewater - ME 04735",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Bridgewater"
  },
  {
    "value": "04736",
    "label": "Caribou - ME 04736",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Caribou"
  },
  {
    "value": "04739",
    "label": "Eagle Lake - ME 04739",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Eagle Lake"
  },
  {
    "value": "04740",
    "label": "Easton - ME 04740",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Easton"
  },
  {
    "value": "04742",
    "label": "Fort Fairfield - ME 04742",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Fort Fairfield"
  },
  {
    "value": "04743",
    "label": "Fort Kent - ME 04743",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Fort Kent"
  },
  {
    "value": "04745",
    "label": "Frenchville - ME 04745",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Frenchville"
  },
  {
    "value": "04746",
    "label": "Grand Isle - ME 04746",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Grand Isle"
  },
  {
    "value": "04747",
    "label": "Island Falls - ME 04747",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Island Falls"
  },
  {
    "value": "04750",
    "label": "Limestone - ME 04750",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Limestone"
  },
  {
    "value": "04756",
    "label": "Madawaska - ME 04756",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Madawaska"
  },
  {
    "value": "04757",
    "label": "Mapleton - ME 04757",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Mapleton"
  },
  {
    "value": "04758",
    "label": "Mars Hill - ME 04758",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Mars Hill"
  },
  {
    "value": "04760",
    "label": "Monticello - ME 04760",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Monticello"
  },
  {
    "value": "04761",
    "label": "New Limerick - ME 04761",
    "county": "Aroostook County",
    "state": "ME",
    "city": "New Limerick"
  },
  {
    "value": "04762",
    "label": "New Sweden - ME 04762",
    "county": "Aroostook County",
    "state": "ME",
    "city": "New Sweden"
  },
  {
    "value": "04763",
    "label": "Oakfield - ME 04763",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Oakfield"
  },
  {
    "value": "04764",
    "label": "Oxbow - ME 04764",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Oxbow"
  },
  {
    "value": "04765",
    "label": "Patten - ME 04765",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Patten"
  },
  {
    "value": "04766",
    "label": "Perham - ME 04766",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Perham"
  },
  {
    "value": "04768",
    "label": "Portage - ME 04768",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Portage"
  },
  {
    "value": "04769",
    "label": "Presque Isle - ME 04769",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Presque Isle"
  },
  {
    "value": "04772",
    "label": "Saint Agatha - ME 04772",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Saint Agatha"
  },
  {
    "value": "04773",
    "label": "Saint David - ME 04773",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Saint David"
  },
  {
    "value": "04774",
    "label": "Saint Francis - ME 04774",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Saint Francis"
  },
  {
    "value": "04776",
    "label": "Sherman - ME 04776",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Sherman"
  },
  {
    "value": "04777",
    "label": "Stacyville - ME 04777",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Stacyville"
  },
  {
    "value": "04779",
    "label": "Sinclair - ME 04779",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Sinclair"
  },
  {
    "value": "04780",
    "label": "Smyrna Mills - ME 04780",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Smyrna Mills"
  },
  {
    "value": "04781",
    "label": "Wallagrass - ME 04781",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Wallagrass"
  },
  {
    "value": "04783",
    "label": "Stockholm - ME 04783",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Stockholm"
  },
  {
    "value": "04785",
    "label": "Van Buren - ME 04785",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Van Buren"
  },
  {
    "value": "04786",
    "label": "Washburn - ME 04786",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Washburn"
  },
  {
    "value": "04787",
    "label": "Westfield - ME 04787",
    "county": "Aroostook County",
    "state": "ME",
    "city": "Westfield"
  },
  {
    "value": "04841",
    "label": "Rockland - ME 04841",
    "county": "Knox County",
    "state": "ME",
    "city": "Rockland"
  },
  {
    "value": "04843",
    "label": "Camden - ME 04843",
    "county": "Knox County",
    "state": "ME",
    "city": "Camden"
  },
  {
    "value": "04847",
    "label": "Hope - ME 04847",
    "county": "Knox County",
    "state": "ME",
    "city": "Hope"
  },
  {
    "value": "04848",
    "label": "Islesboro - ME 04848",
    "county": "Waldo County",
    "state": "ME",
    "city": "Islesboro"
  },
  {
    "value": "04849",
    "label": "Lincolnville - ME 04849",
    "county": "Waldo County",
    "state": "ME",
    "city": "Lincolnville"
  },
  {
    "value": "04853",
    "label": "North Haven - ME 04853",
    "county": "Knox County",
    "state": "ME",
    "city": "North Haven"
  },
  {
    "value": "04854",
    "label": "Owls Head - ME 04854",
    "county": "Knox County",
    "state": "ME",
    "city": "Owls Head"
  },
  {
    "value": "04856",
    "label": "Rockport - ME 04856",
    "county": "Knox County",
    "state": "ME",
    "city": "Rockport"
  },
  {
    "value": "04858",
    "label": "South Thomaston - ME 04858",
    "county": "Knox County",
    "state": "ME",
    "city": "South Thomaston"
  },
  {
    "value": "04859",
    "label": "Spruce Head - ME 04859",
    "county": "Knox County",
    "state": "ME",
    "city": "Spruce Head"
  },
  {
    "value": "04860",
    "label": "Tenants Harbor - ME 04860",
    "county": "Knox County",
    "state": "ME",
    "city": "Tenants Harbor"
  },
  {
    "value": "04861",
    "label": "Thomaston - ME 04861",
    "county": "Knox County",
    "state": "ME",
    "city": "Thomaston"
  },
  {
    "value": "04862",
    "label": "Union - ME 04862",
    "county": "Knox County",
    "state": "ME",
    "city": "Union"
  },
  {
    "value": "04863",
    "label": "Vinalhaven - ME 04863",
    "county": "Knox County",
    "state": "ME",
    "city": "Vinalhaven"
  },
  {
    "value": "04864",
    "label": "Warren - ME 04864",
    "county": "Knox County",
    "state": "ME",
    "city": "Warren"
  },
  {
    "value": "04901",
    "label": "Waterville - ME 04901",
    "county": "Kennebec County",
    "state": "ME",
    "city": "Waterville"
  },
  {
    "value": "04910",
    "label": "Albion - ME 04910",
    "county": "Kennebec County",
    "state": "ME",
    "city": "Albion"
  },
  {
    "value": "04911",
    "label": "Anson - ME 04911",
    "county": "Somerset County",
    "state": "ME",
    "city": "Anson"
  },
  {
    "value": "04912",
    "label": "Athens - ME 04912",
    "county": "Somerset County",
    "state": "ME",
    "city": "Athens"
  },
  {
    "value": "04915",
    "label": "Belfast - ME 04915",
    "county": "Waldo County",
    "state": "ME",
    "city": "Belfast"
  },
  {
    "value": "04917",
    "label": "Belgrade - ME 04917",
    "county": "Kennebec County",
    "state": "ME",
    "city": "Belgrade"
  },
  {
    "value": "04918",
    "label": "Belgrade Lakes - ME 04918",
    "county": "Kennebec County",
    "state": "ME",
    "city": "Belgrade Lakes"
  },
  {
    "value": "04920",
    "label": "Bingham - ME 04920",
    "county": "Somerset County",
    "state": "ME",
    "city": "Bingham"
  },
  {
    "value": "04921",
    "label": "Brooks - ME 04921",
    "county": "Waldo County",
    "state": "ME",
    "city": "Brooks"
  },
  {
    "value": "04922",
    "label": "Burnham - ME 04922",
    "county": "Waldo County",
    "state": "ME",
    "city": "Burnham"
  },
  {
    "value": "04923",
    "label": "Cambridge - ME 04923",
    "county": "Somerset County",
    "state": "ME",
    "city": "Cambridge"
  },
  {
    "value": "04924",
    "label": "Canaan - ME 04924",
    "county": "Somerset County",
    "state": "ME",
    "city": "Canaan"
  },
  {
    "value": "04925",
    "label": "Caratunk - ME 04925",
    "county": "Somerset County",
    "state": "ME",
    "city": "Caratunk"
  },
  {
    "value": "04927",
    "label": "Clinton - ME 04927",
    "county": "Kennebec County",
    "state": "ME",
    "city": "Clinton"
  },
  {
    "value": "04928",
    "label": "Corinna - ME 04928",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Corinna"
  },
  {
    "value": "04929",
    "label": "Detroit - ME 04929",
    "county": "Somerset County",
    "state": "ME",
    "city": "Detroit"
  },
  {
    "value": "04930",
    "label": "Dexter - ME 04930",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Dexter"
  },
  {
    "value": "04932",
    "label": "Dixmont - ME 04932",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Dixmont"
  },
  {
    "value": "04936",
    "label": "Eustis - ME 04936",
    "county": "Franklin County",
    "state": "ME",
    "city": "Eustis"
  },
  {
    "value": "04937",
    "label": "Fairfield - ME 04937",
    "county": "Somerset County",
    "state": "ME",
    "city": "Fairfield"
  },
  {
    "value": "04938",
    "label": "Farmington - ME 04938",
    "county": "Franklin County",
    "state": "ME",
    "city": "Farmington"
  },
  {
    "value": "04939",
    "label": "Garland - ME 04939",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Garland"
  },
  {
    "value": "04940",
    "label": "Farmington Falls - ME 04940",
    "county": "Franklin County",
    "state": "ME",
    "city": "Farmington Falls"
  },
  {
    "value": "04941",
    "label": "Freedom - ME 04941",
    "county": "Waldo County",
    "state": "ME",
    "city": "Freedom"
  },
  {
    "value": "04942",
    "label": "Harmony - ME 04942",
    "county": "Somerset County",
    "state": "ME",
    "city": "Harmony"
  },
  {
    "value": "04943",
    "label": "Hartland - ME 04943",
    "county": "Somerset County",
    "state": "ME",
    "city": "Hartland"
  },
  {
    "value": "04945",
    "label": "Jackman - ME 04945",
    "county": "Somerset County",
    "state": "ME",
    "city": "Jackman"
  },
  {
    "value": "04947",
    "label": "Kingfield - ME 04947",
    "county": "Franklin County",
    "state": "ME",
    "city": "Kingfield"
  },
  {
    "value": "04949",
    "label": "Liberty - ME 04949",
    "county": "Waldo County",
    "state": "ME",
    "city": "Liberty"
  },
  {
    "value": "04950",
    "label": "Madison - ME 04950",
    "county": "Somerset County",
    "state": "ME",
    "city": "Madison"
  },
  {
    "value": "04951",
    "label": "Monroe - ME 04951",
    "county": "Waldo County",
    "state": "ME",
    "city": "Monroe"
  },
  {
    "value": "04952",
    "label": "Morrill - ME 04952",
    "county": "Waldo County",
    "state": "ME",
    "city": "Morrill"
  },
  {
    "value": "04953",
    "label": "Newport - ME 04953",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Newport"
  },
  {
    "value": "04955",
    "label": "New Sharon - ME 04955",
    "county": "Franklin County",
    "state": "ME",
    "city": "New Sharon"
  },
  {
    "value": "04956",
    "label": "New Vineyard - ME 04956",
    "county": "Franklin County",
    "state": "ME",
    "city": "New Vineyard"
  },
  {
    "value": "04957",
    "label": "Norridgewock - ME 04957",
    "county": "Somerset County",
    "state": "ME",
    "city": "Norridgewock"
  },
  {
    "value": "04958",
    "label": "North Anson - ME 04958",
    "county": "Somerset County",
    "state": "ME",
    "city": "North Anson"
  },
  {
    "value": "04961",
    "label": "New Portland - ME 04961",
    "county": "Somerset County",
    "state": "ME",
    "city": "New Portland"
  },
  {
    "value": "04963",
    "label": "Oakland - ME 04963",
    "county": "Kennebec County",
    "state": "ME",
    "city": "Oakland"
  },
  {
    "value": "04965",
    "label": "Palmyra - ME 04965",
    "county": "Somerset County",
    "state": "ME",
    "city": "Palmyra"
  },
  {
    "value": "04966",
    "label": "Phillips - ME 04966",
    "county": "Franklin County",
    "state": "ME",
    "city": "Phillips"
  },
  {
    "value": "04967",
    "label": "Pittsfield - ME 04967",
    "county": "Somerset County",
    "state": "ME",
    "city": "Pittsfield"
  },
  {
    "value": "04969",
    "label": "Plymouth - ME 04969",
    "county": "Penobscot County",
    "state": "ME",
    "city": "Plymouth"
  },
  {
    "value": "04970",
    "label": "Rangeley - ME 04970",
    "county": "Franklin County",
    "state": "ME",
    "city": "Rangeley"
  },
  {
    "value": "04971",
    "label": "Saint Albans - ME 04971",
    "county": "Somerset County",
    "state": "ME",
    "city": "Saint Albans"
  },
  {
    "value": "04973",
    "label": "Searsmont - ME 04973",
    "county": "Waldo County",
    "state": "ME",
    "city": "Searsmont"
  },
  {
    "value": "04974",
    "label": "Searsport - ME 04974",
    "county": "Waldo County",
    "state": "ME",
    "city": "Searsport"
  },
  {
    "value": "04976",
    "label": "Skowhegan - ME 04976",
    "county": "Somerset County",
    "state": "ME",
    "city": "Skowhegan"
  },
  {
    "value": "04978",
    "label": "Smithfield - ME 04978",
    "county": "Somerset County",
    "state": "ME",
    "city": "Smithfield"
  },
  {
    "value": "04979",
    "label": "Solon - ME 04979",
    "county": "Somerset County",
    "state": "ME",
    "city": "Solon"
  },
  {
    "value": "04981",
    "label": "Stockton Springs - ME 04981",
    "county": "Waldo County",
    "state": "ME",
    "city": "Stockton Springs"
  },
  {
    "value": "04982",
    "label": "Stratton - ME 04982",
    "county": "Franklin County",
    "state": "ME",
    "city": "Stratton"
  },
  {
    "value": "04983",
    "label": "Strong - ME 04983",
    "county": "Franklin County",
    "state": "ME",
    "city": "Strong"
  },
  {
    "value": "04984",
    "label": "Temple - ME 04984",
    "county": "Franklin County",
    "state": "ME",
    "city": "Temple"
  },
  {
    "value": "04985",
    "label": "West Forks - ME 04985",
    "county": "Somerset County",
    "state": "ME",
    "city": "West Forks"
  },
  {
    "value": "04986",
    "label": "Thorndike - ME 04986",
    "county": "Waldo County",
    "state": "ME",
    "city": "Thorndike"
  },
  {
    "value": "04987",
    "label": "Troy - ME 04987",
    "county": "Waldo County",
    "state": "ME",
    "city": "Troy"
  },
  {
    "value": "04988",
    "label": "Unity - ME 04988",
    "county": "Waldo County",
    "state": "ME",
    "city": "Unity"
  },
  {
    "value": "04989",
    "label": "Vassalboro - ME 04989",
    "county": "Kennebec County",
    "state": "ME",
    "city": "Vassalboro"
  },
  {
    "value": "20601",
    "label": "Waldorf - MD 20601",
    "county": "Charles County",
    "state": "MD",
    "city": "Waldorf"
  },
  {
    "value": "20602",
    "label": "Waldorf - MD 20602",
    "county": "Charles County",
    "state": "MD",
    "city": "Waldorf"
  },
  {
    "value": "20603",
    "label": "Waldorf - MD 20603",
    "county": "Charles County",
    "state": "MD",
    "city": "Waldorf"
  },
  {
    "value": "20606",
    "label": "Abell - MD 20606",
    "county": "St. Mary's County",
    "state": "MD",
    "city": "Abell"
  },
  {
    "value": "20607",
    "label": "Accokeek - MD 20607",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Accokeek"
  },
  {
    "value": "20608",
    "label": "Aquasco - MD 20608",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Aquasco"
  },
  {
    "value": "20609",
    "label": "Avenue - MD 20609",
    "county": "St. Mary's County",
    "state": "MD",
    "city": "Avenue"
  },
  {
    "value": "20611",
    "label": "Bel Alton - MD 20611",
    "county": "Charles County",
    "state": "MD",
    "city": "Bel Alton"
  },
  {
    "value": "20613",
    "label": "Brandywine - MD 20613",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Brandywine"
  },
  {
    "value": "20615",
    "label": "Broomes Island - MD 20615",
    "county": "Calvert County",
    "state": "MD",
    "city": "Broomes Island"
  },
  {
    "value": "20616",
    "label": "Bryans Road - MD 20616",
    "county": "Charles County",
    "state": "MD",
    "city": "Bryans Road"
  },
  {
    "value": "20617",
    "label": "Bryantown - MD 20617",
    "county": "Charles County",
    "state": "MD",
    "city": "Bryantown"
  },
  {
    "value": "20618",
    "label": "Bushwood - MD 20618",
    "county": "St. Mary's County",
    "state": "MD",
    "city": "Bushwood"
  },
  {
    "value": "20619",
    "label": "California - MD 20619",
    "county": "St. Mary's County",
    "state": "MD",
    "city": "California"
  },
  {
    "value": "20620",
    "label": "Callaway - MD 20620",
    "county": "St. Mary's County",
    "state": "MD",
    "city": "Callaway"
  },
  {
    "value": "20621",
    "label": "Chaptico - MD 20621",
    "county": "St. Mary's County",
    "state": "MD",
    "city": "Chaptico"
  },
  {
    "value": "20622",
    "label": "Charlotte Hall - MD 20622",
    "county": "Charles County",
    "state": "MD",
    "city": "Charlotte Hall"
  },
  {
    "value": "20623",
    "label": "Cheltenham - MD 20623",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Cheltenham"
  },
  {
    "value": "20624",
    "label": "Clements - MD 20624",
    "county": "St. Mary's County",
    "state": "MD",
    "city": "Clements"
  },
  {
    "value": "20626",
    "label": "Coltons Point - MD 20626",
    "county": "St. Mary's County",
    "state": "MD",
    "city": "Coltons Point"
  },
  {
    "value": "20628",
    "label": "Dameron - MD 20628",
    "county": "St. Mary's County",
    "state": "MD",
    "city": "Dameron"
  },
  {
    "value": "20630",
    "label": "Drayden - MD 20630",
    "county": "St. Mary's County",
    "state": "MD",
    "city": "Drayden"
  },
  {
    "value": "20632",
    "label": "Faulkner - MD 20632",
    "county": "Charles County",
    "state": "MD",
    "city": "Faulkner"
  },
  {
    "value": "20634",
    "label": "Great Mills - MD 20634",
    "county": "St. Mary's County",
    "state": "MD",
    "city": "Great Mills"
  },
  {
    "value": "20636",
    "label": "Hollywood - MD 20636",
    "county": "St. Mary's County",
    "state": "MD",
    "city": "Hollywood"
  },
  {
    "value": "20637",
    "label": "Hughesville - MD 20637",
    "county": "Charles County",
    "state": "MD",
    "city": "Hughesville"
  },
  {
    "value": "20639",
    "label": "Huntingtown - MD 20639",
    "county": "Calvert County",
    "state": "MD",
    "city": "Huntingtown"
  },
  {
    "value": "20640",
    "label": "Indian Head - MD 20640",
    "county": "Charles County",
    "state": "MD",
    "city": "Indian Head"
  },
  {
    "value": "20645",
    "label": "Issue - MD 20645",
    "county": "Charles County",
    "state": "MD",
    "city": "Issue"
  },
  {
    "value": "20646",
    "label": "La Plata - MD 20646",
    "county": "Charles County",
    "state": "MD",
    "city": "La Plata"
  },
  {
    "value": "20650",
    "label": "Leonardtown - MD 20650",
    "county": "St. Mary's County",
    "state": "MD",
    "city": "Leonardtown"
  },
  {
    "value": "20653",
    "label": "Lexington Park - MD 20653",
    "county": "St. Mary's County",
    "state": "MD",
    "city": "Lexington Park"
  },
  {
    "value": "20656",
    "label": "Loveville - MD 20656",
    "county": "St. Mary's County",
    "state": "MD",
    "city": "Loveville"
  },
  {
    "value": "20657",
    "label": "Lusby - MD 20657",
    "county": "Calvert County",
    "state": "MD",
    "city": "Lusby"
  },
  {
    "value": "20658",
    "label": "Marbury - MD 20658",
    "county": "Charles County",
    "state": "MD",
    "city": "Marbury"
  },
  {
    "value": "20659",
    "label": "Mechanicsville - MD 20659",
    "county": "St. Mary's County",
    "state": "MD",
    "city": "Mechanicsville"
  },
  {
    "value": "20662",
    "label": "Nanjemoy - MD 20662",
    "county": "Charles County",
    "state": "MD",
    "city": "Nanjemoy"
  },
  {
    "value": "20664",
    "label": "Newburg - MD 20664",
    "county": "Charles County",
    "state": "MD",
    "city": "Newburg"
  },
  {
    "value": "20667",
    "label": "Park Hall - MD 20667",
    "county": "St. Mary's County",
    "state": "MD",
    "city": "Park Hall"
  },
  {
    "value": "20670",
    "label": "Patuxent River - MD 20670",
    "county": "St. Mary's County",
    "state": "MD",
    "city": "Patuxent River"
  },
  {
    "value": "20674",
    "label": "Piney Point - MD 20674",
    "county": "St. Mary's County",
    "state": "MD",
    "city": "Piney Point"
  },
  {
    "value": "20675",
    "label": "Pomfret - MD 20675",
    "county": "Charles County",
    "state": "MD",
    "city": "Pomfret"
  },
  {
    "value": "20676",
    "label": "Port Republic - MD 20676",
    "county": "Calvert County",
    "state": "MD",
    "city": "Port Republic"
  },
  {
    "value": "20677",
    "label": "Port Tobacco - MD 20677",
    "county": "Charles County",
    "state": "MD",
    "city": "Port Tobacco"
  },
  {
    "value": "20678",
    "label": "Prince Frederick - MD 20678",
    "county": "Calvert County",
    "state": "MD",
    "city": "Prince Frederick"
  },
  {
    "value": "20680",
    "label": "Ridge - MD 20680",
    "county": "St. Mary's County",
    "state": "MD",
    "city": "Ridge"
  },
  {
    "value": "20684",
    "label": "Saint Inigoes - MD 20684",
    "county": "St. Mary's County",
    "state": "MD",
    "city": "Saint Inigoes"
  },
  {
    "value": "20685",
    "label": "Saint Leonard - MD 20685",
    "county": "Calvert County",
    "state": "MD",
    "city": "Saint Leonard"
  },
  {
    "value": "20687",
    "label": "Scotland - MD 20687",
    "county": "St. Mary's County",
    "state": "MD",
    "city": "Scotland"
  },
  {
    "value": "20688",
    "label": "Solomons - MD 20688",
    "county": "Calvert County",
    "state": "MD",
    "city": "Solomons"
  },
  {
    "value": "20689",
    "label": "Sunderland - MD 20689",
    "county": "Calvert County",
    "state": "MD",
    "city": "Sunderland"
  },
  {
    "value": "20690",
    "label": "Tall Timbers - MD 20690",
    "county": "St. Mary's County",
    "state": "MD",
    "city": "Tall Timbers"
  },
  {
    "value": "20692",
    "label": "Valley Lee - MD 20692",
    "county": "St. Mary's County",
    "state": "MD",
    "city": "Valley Lee"
  },
  {
    "value": "20693",
    "label": "Welcome - MD 20693",
    "county": "Charles County",
    "state": "MD",
    "city": "Welcome"
  },
  {
    "value": "20695",
    "label": "White Plains - MD 20695",
    "county": "Charles County",
    "state": "MD",
    "city": "White Plains"
  },
  {
    "value": "20701",
    "label": "Annapolis Junction - MD 20701",
    "county": "Howard County",
    "state": "MD",
    "city": "Annapolis Junction"
  },
  {
    "value": "20705",
    "label": "Beltsville - MD 20705",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Beltsville"
  },
  {
    "value": "20706",
    "label": "Lanham - MD 20706",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Lanham"
  },
  {
    "value": "20707",
    "label": "Laurel - MD 20707",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Laurel"
  },
  {
    "value": "20708",
    "label": "Laurel - MD 20708",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Laurel"
  },
  {
    "value": "20710",
    "label": "Bladensburg - MD 20710",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Bladensburg"
  },
  {
    "value": "20711",
    "label": "Lothian - MD 20711",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Lothian"
  },
  {
    "value": "20712",
    "label": "Mount Rainier - MD 20712",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Mount Rainier"
  },
  {
    "value": "20714",
    "label": "North Beach - MD 20714",
    "county": "Calvert County",
    "state": "MD",
    "city": "North Beach"
  },
  {
    "value": "20715",
    "label": "Bowie - MD 20715",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Bowie"
  },
  {
    "value": "20716",
    "label": "Bowie - MD 20716",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Bowie"
  },
  {
    "value": "20720",
    "label": "Bowie - MD 20720",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Bowie"
  },
  {
    "value": "20721",
    "label": "Bowie - MD 20721",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Bowie"
  },
  {
    "value": "20722",
    "label": "Brentwood - MD 20722",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Brentwood"
  },
  {
    "value": "20723",
    "label": "Laurel - MD 20723",
    "county": "Howard County",
    "state": "MD",
    "city": "Laurel"
  },
  {
    "value": "20724",
    "label": "Laurel - MD 20724",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Laurel"
  },
  {
    "value": "20732",
    "label": "Chesapeake Beach - MD 20732",
    "county": "Calvert County",
    "state": "MD",
    "city": "Chesapeake Beach"
  },
  {
    "value": "20733",
    "label": "Churchton - MD 20733",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Churchton"
  },
  {
    "value": "20735",
    "label": "Clinton - MD 20735",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Clinton"
  },
  {
    "value": "20736",
    "label": "Owings - MD 20736",
    "county": "Calvert County",
    "state": "MD",
    "city": "Owings"
  },
  {
    "value": "20737",
    "label": "Riverdale - MD 20737",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Riverdale"
  },
  {
    "value": "20740",
    "label": "College Park - MD 20740",
    "county": "Prince George's County",
    "state": "MD",
    "city": "College Park"
  },
  {
    "value": "20743",
    "label": "Capitol Heights - MD 20743",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Capitol Heights"
  },
  {
    "value": "20744",
    "label": "Fort Washington - MD 20744",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Fort Washington"
  },
  {
    "value": "20745",
    "label": "Oxon Hill - MD 20745",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Oxon Hill"
  },
  {
    "value": "20746",
    "label": "Suitland - MD 20746",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Suitland"
  },
  {
    "value": "20747",
    "label": "District Heights - MD 20747",
    "county": "Prince George's County",
    "state": "MD",
    "city": "District Heights"
  },
  {
    "value": "20748",
    "label": "Temple Hills - MD 20748",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Temple Hills"
  },
  {
    "value": "20751",
    "label": "Deale - MD 20751",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Deale"
  },
  {
    "value": "20754",
    "label": "Dunkirk - MD 20754",
    "county": "Calvert County",
    "state": "MD",
    "city": "Dunkirk"
  },
  {
    "value": "20755",
    "label": "Fort George G Meade - MD 20755",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Fort George G Meade"
  },
  {
    "value": "20758",
    "label": "Friendship - MD 20758",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Friendship"
  },
  {
    "value": "20759",
    "label": "Fulton - MD 20759",
    "county": "Howard County",
    "state": "MD",
    "city": "Fulton"
  },
  {
    "value": "20762",
    "label": "Andrews Air Force Base - MD 20762",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Andrews Air Force Base"
  },
  {
    "value": "20763",
    "label": "Savage - MD 20763",
    "county": "Howard County",
    "state": "MD",
    "city": "Savage"
  },
  {
    "value": "20764",
    "label": "Shady Side - MD 20764",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Shady Side"
  },
  {
    "value": "20769",
    "label": "Glenn Dale - MD 20769",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Glenn Dale"
  },
  {
    "value": "20770",
    "label": "Greenbelt - MD 20770",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Greenbelt"
  },
  {
    "value": "20772",
    "label": "Upper Marlboro - MD 20772",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Upper Marlboro"
  },
  {
    "value": "20774",
    "label": "Upper Marlboro - MD 20774",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Upper Marlboro"
  },
  {
    "value": "20776",
    "label": "Harwood - MD 20776",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Harwood"
  },
  {
    "value": "20777",
    "label": "Highland - MD 20777",
    "county": "Howard County",
    "state": "MD",
    "city": "Highland"
  },
  {
    "value": "20778",
    "label": "West River - MD 20778",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "West River"
  },
  {
    "value": "20779",
    "label": "Tracys Landing - MD 20779",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Tracys Landing"
  },
  {
    "value": "20781",
    "label": "Hyattsville - MD 20781",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Hyattsville"
  },
  {
    "value": "20782",
    "label": "Hyattsville - MD 20782",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Hyattsville"
  },
  {
    "value": "20783",
    "label": "Hyattsville - MD 20783",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Hyattsville"
  },
  {
    "value": "20784",
    "label": "Hyattsville - MD 20784",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Hyattsville"
  },
  {
    "value": "20785",
    "label": "Hyattsville - MD 20785",
    "county": "Prince George's County",
    "state": "MD",
    "city": "Hyattsville"
  },
  {
    "value": "20794",
    "label": "Jessup - MD 20794",
    "county": "Howard County",
    "state": "MD",
    "city": "Jessup"
  },
  {
    "value": "20812",
    "label": "Glen Echo - MD 20812",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Glen Echo"
  },
  {
    "value": "20814",
    "label": "Bethesda - MD 20814",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Bethesda"
  },
  {
    "value": "20815",
    "label": "Chevy Chase - MD 20815",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Chevy Chase"
  },
  {
    "value": "20816",
    "label": "Bethesda - MD 20816",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Bethesda"
  },
  {
    "value": "20817",
    "label": "Bethesda - MD 20817",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Bethesda"
  },
  {
    "value": "20818",
    "label": "Cabin John - MD 20818",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Cabin John"
  },
  {
    "value": "20832",
    "label": "Olney - MD 20832",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Olney"
  },
  {
    "value": "20833",
    "label": "Brookeville - MD 20833",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Brookeville"
  },
  {
    "value": "20837",
    "label": "Poolesville - MD 20837",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Poolesville"
  },
  {
    "value": "20838",
    "label": "Barnesville - MD 20838",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Barnesville"
  },
  {
    "value": "20839",
    "label": "Beallsville - MD 20839",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Beallsville"
  },
  {
    "value": "20841",
    "label": "Boyds - MD 20841",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Boyds"
  },
  {
    "value": "20842",
    "label": "Dickerson - MD 20842",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Dickerson"
  },
  {
    "value": "20850",
    "label": "Rockville - MD 20850",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Rockville"
  },
  {
    "value": "20851",
    "label": "Rockville - MD 20851",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Rockville"
  },
  {
    "value": "20852",
    "label": "Rockville - MD 20852",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Rockville"
  },
  {
    "value": "20853",
    "label": "Rockville - MD 20853",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Rockville"
  },
  {
    "value": "20854",
    "label": "Potomac - MD 20854",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Potomac"
  },
  {
    "value": "20855",
    "label": "Derwood - MD 20855",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Derwood"
  },
  {
    "value": "20860",
    "label": "Sandy Spring - MD 20860",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Sandy Spring"
  },
  {
    "value": "20861",
    "label": "Ashton - MD 20861",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Ashton"
  },
  {
    "value": "20862",
    "label": "Brinklow - MD 20862",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Brinklow"
  },
  {
    "value": "20866",
    "label": "Burtonsville - MD 20866",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Burtonsville"
  },
  {
    "value": "20868",
    "label": "Spencerville - MD 20868",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Spencerville"
  },
  {
    "value": "20871",
    "label": "Clarksburg - MD 20871",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Clarksburg"
  },
  {
    "value": "20872",
    "label": "Damascus - MD 20872",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Damascus"
  },
  {
    "value": "20874",
    "label": "Germantown - MD 20874",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Germantown"
  },
  {
    "value": "20876",
    "label": "Germantown - MD 20876",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Germantown"
  },
  {
    "value": "20877",
    "label": "Gaithersburg - MD 20877",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Gaithersburg"
  },
  {
    "value": "20878",
    "label": "Gaithersburg - MD 20878",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Gaithersburg"
  },
  {
    "value": "20879",
    "label": "Gaithersburg - MD 20879",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Gaithersburg"
  },
  {
    "value": "20882",
    "label": "Gaithersburg - MD 20882",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Gaithersburg"
  },
  {
    "value": "20886",
    "label": "Montgomery Village - MD 20886",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Montgomery Village"
  },
  {
    "value": "20895",
    "label": "Kensington - MD 20895",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Kensington"
  },
  {
    "value": "20901",
    "label": "Silver Spring - MD 20901",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Silver Spring"
  },
  {
    "value": "20902",
    "label": "Silver Spring - MD 20902",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Silver Spring"
  },
  {
    "value": "20903",
    "label": "Silver Spring - MD 20903",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Silver Spring"
  },
  {
    "value": "20904",
    "label": "Silver Spring - MD 20904",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Silver Spring"
  },
  {
    "value": "20905",
    "label": "Silver Spring - MD 20905",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Silver Spring"
  },
  {
    "value": "20906",
    "label": "Silver Spring - MD 20906",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Silver Spring"
  },
  {
    "value": "20910",
    "label": "Silver Spring - MD 20910",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Silver Spring"
  },
  {
    "value": "20912",
    "label": "Takoma Park - MD 20912",
    "county": "Montgomery County",
    "state": "MD",
    "city": "Takoma Park"
  },
  {
    "value": "21001",
    "label": "Aberdeen - MD 21001",
    "county": "Harford County",
    "state": "MD",
    "city": "Aberdeen"
  },
  {
    "value": "21005",
    "label": "Aberdeen Proving Ground - MD 21005",
    "county": "Harford County",
    "state": "MD",
    "city": "Aberdeen Proving Ground"
  },
  {
    "value": "21009",
    "label": "Abingdon - MD 21009",
    "county": "Harford County",
    "state": "MD",
    "city": "Abingdon"
  },
  {
    "value": "21010",
    "label": "Gunpowder - MD 21010",
    "county": "Harford County",
    "state": "MD",
    "city": "Gunpowder"
  },
  {
    "value": "21012",
    "label": "Arnold - MD 21012",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Arnold"
  },
  {
    "value": "21013",
    "label": "Baldwin - MD 21013",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Baldwin"
  },
  {
    "value": "21014",
    "label": "Bel Air - MD 21014",
    "county": "Harford County",
    "state": "MD",
    "city": "Bel Air"
  },
  {
    "value": "21015",
    "label": "Bel Air - MD 21015",
    "county": "Harford County",
    "state": "MD",
    "city": "Bel Air"
  },
  {
    "value": "21017",
    "label": "Belcamp - MD 21017",
    "county": "Harford County",
    "state": "MD",
    "city": "Belcamp"
  },
  {
    "value": "21028",
    "label": "Churchville - MD 21028",
    "county": "Harford County",
    "state": "MD",
    "city": "Churchville"
  },
  {
    "value": "21029",
    "label": "Clarksville - MD 21029",
    "county": "Howard County",
    "state": "MD",
    "city": "Clarksville"
  },
  {
    "value": "21030",
    "label": "Cockeysville - MD 21030",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Cockeysville"
  },
  {
    "value": "21031",
    "label": "Hunt Valley - MD 21031",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Hunt Valley"
  },
  {
    "value": "21032",
    "label": "Crownsville - MD 21032",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Crownsville"
  },
  {
    "value": "21034",
    "label": "Darlington - MD 21034",
    "county": "Harford County",
    "state": "MD",
    "city": "Darlington"
  },
  {
    "value": "21035",
    "label": "Davidsonville - MD 21035",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Davidsonville"
  },
  {
    "value": "21036",
    "label": "Dayton - MD 21036",
    "county": "Howard County",
    "state": "MD",
    "city": "Dayton"
  },
  {
    "value": "21037",
    "label": "Edgewater - MD 21037",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Edgewater"
  },
  {
    "value": "21040",
    "label": "Edgewood - MD 21040",
    "county": "Harford County",
    "state": "MD",
    "city": "Edgewood"
  },
  {
    "value": "21042",
    "label": "Ellicott City - MD 21042",
    "county": "Howard County",
    "state": "MD",
    "city": "Ellicott City"
  },
  {
    "value": "21043",
    "label": "Ellicott City - MD 21043",
    "county": "Howard County",
    "state": "MD",
    "city": "Ellicott City"
  },
  {
    "value": "21044",
    "label": "Columbia - MD 21044",
    "county": "Howard County",
    "state": "MD",
    "city": "Columbia"
  },
  {
    "value": "21045",
    "label": "Columbia - MD 21045",
    "county": "Howard County",
    "state": "MD",
    "city": "Columbia"
  },
  {
    "value": "21046",
    "label": "Columbia - MD 21046",
    "county": "Howard County",
    "state": "MD",
    "city": "Columbia"
  },
  {
    "value": "21047",
    "label": "Fallston - MD 21047",
    "county": "Harford County",
    "state": "MD",
    "city": "Fallston"
  },
  {
    "value": "21048",
    "label": "Finksburg - MD 21048",
    "county": "Carroll County",
    "state": "MD",
    "city": "Finksburg"
  },
  {
    "value": "21050",
    "label": "Forest Hill - MD 21050",
    "county": "Harford County",
    "state": "MD",
    "city": "Forest Hill"
  },
  {
    "value": "21051",
    "label": "Fork - MD 21051",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Fork"
  },
  {
    "value": "21053",
    "label": "Freeland - MD 21053",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Freeland"
  },
  {
    "value": "21054",
    "label": "Gambrills - MD 21054",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Gambrills"
  },
  {
    "value": "21057",
    "label": "Glen Arm - MD 21057",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Glen Arm"
  },
  {
    "value": "21060",
    "label": "Glen Burnie - MD 21060",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Glen Burnie"
  },
  {
    "value": "21061",
    "label": "Glen Burnie - MD 21061",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Glen Burnie"
  },
  {
    "value": "21074",
    "label": "Hampstead - MD 21074",
    "county": "Carroll County",
    "state": "MD",
    "city": "Hampstead"
  },
  {
    "value": "21075",
    "label": "Elkridge - MD 21075",
    "county": "Howard County",
    "state": "MD",
    "city": "Elkridge"
  },
  {
    "value": "21076",
    "label": "Hanover - MD 21076",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Hanover"
  },
  {
    "value": "21077",
    "label": "Harmans - MD 21077",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Harmans"
  },
  {
    "value": "21078",
    "label": "Havre De Grace - MD 21078",
    "county": "Harford County",
    "state": "MD",
    "city": "Havre De Grace"
  },
  {
    "value": "21082",
    "label": "Hydes - MD 21082",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Hydes"
  },
  {
    "value": "21084",
    "label": "Jarrettsville - MD 21084",
    "county": "Harford County",
    "state": "MD",
    "city": "Jarrettsville"
  },
  {
    "value": "21085",
    "label": "Joppa - MD 21085",
    "county": "Harford County",
    "state": "MD",
    "city": "Joppa"
  },
  {
    "value": "21087",
    "label": "Kingsville - MD 21087",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Kingsville"
  },
  {
    "value": "21090",
    "label": "Linthicum Heights - MD 21090",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Linthicum Heights"
  },
  {
    "value": "21093",
    "label": "Lutherville Timonium - MD 21093",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Lutherville Timonium"
  },
  {
    "value": "21102",
    "label": "Manchester - MD 21102",
    "county": "Carroll County",
    "state": "MD",
    "city": "Manchester"
  },
  {
    "value": "21104",
    "label": "Marriottsville - MD 21104",
    "county": "Carroll County",
    "state": "MD",
    "city": "Marriottsville"
  },
  {
    "value": "21108",
    "label": "Millersville - MD 21108",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Millersville"
  },
  {
    "value": "21111",
    "label": "Monkton - MD 21111",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Monkton"
  },
  {
    "value": "21113",
    "label": "Odenton - MD 21113",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Odenton"
  },
  {
    "value": "21114",
    "label": "Crofton - MD 21114",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Crofton"
  },
  {
    "value": "21117",
    "label": "Owings Mills - MD 21117",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Owings Mills"
  },
  {
    "value": "21120",
    "label": "Parkton - MD 21120",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Parkton"
  },
  {
    "value": "21122",
    "label": "Pasadena - MD 21122",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Pasadena"
  },
  {
    "value": "21128",
    "label": "Perry Hall - MD 21128",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Perry Hall"
  },
  {
    "value": "21131",
    "label": "Phoenix - MD 21131",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Phoenix"
  },
  {
    "value": "21132",
    "label": "Pylesville - MD 21132",
    "county": "Harford County",
    "state": "MD",
    "city": "Pylesville"
  },
  {
    "value": "21133",
    "label": "Randallstown - MD 21133",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Randallstown"
  },
  {
    "value": "21136",
    "label": "Reisterstown - MD 21136",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Reisterstown"
  },
  {
    "value": "21140",
    "label": "Riva - MD 21140",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Riva"
  },
  {
    "value": "21144",
    "label": "Severn - MD 21144",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Severn"
  },
  {
    "value": "21146",
    "label": "Severna Park - MD 21146",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Severna Park"
  },
  {
    "value": "21152",
    "label": "Sparks Glencoe - MD 21152",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Sparks Glencoe"
  },
  {
    "value": "21154",
    "label": "Street - MD 21154",
    "county": "Harford County",
    "state": "MD",
    "city": "Street"
  },
  {
    "value": "21155",
    "label": "Upperco - MD 21155",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Upperco"
  },
  {
    "value": "21156",
    "label": "Upper Falls - MD 21156",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Upper Falls"
  },
  {
    "value": "21157",
    "label": "Westminster - MD 21157",
    "county": "Carroll County",
    "state": "MD",
    "city": "Westminster"
  },
  {
    "value": "21158",
    "label": "Westminster - MD 21158",
    "county": "Carroll County",
    "state": "MD",
    "city": "Westminster"
  },
  {
    "value": "21160",
    "label": "Whiteford - MD 21160",
    "county": "Harford County",
    "state": "MD",
    "city": "Whiteford"
  },
  {
    "value": "21161",
    "label": "White Hall - MD 21161",
    "county": "Harford County",
    "state": "MD",
    "city": "White Hall"
  },
  {
    "value": "21162",
    "label": "White Marsh - MD 21162",
    "county": "Baltimore County",
    "state": "MD",
    "city": "White Marsh"
  },
  {
    "value": "21163",
    "label": "Woodstock - MD 21163",
    "county": "Howard County",
    "state": "MD",
    "city": "Woodstock"
  },
  {
    "value": "21201",
    "label": "Baltimore - MD 21201",
    "county": "Baltimore city",
    "state": "MD",
    "city": "Baltimore"
  },
  {
    "value": "21202",
    "label": "Baltimore - MD 21202",
    "county": "Baltimore city",
    "state": "MD",
    "city": "Baltimore"
  },
  {
    "value": "21204",
    "label": "Towson - MD 21204",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Towson"
  },
  {
    "value": "21205",
    "label": "Baltimore - MD 21205",
    "county": "Baltimore city",
    "state": "MD",
    "city": "Baltimore"
  },
  {
    "value": "21206",
    "label": "Baltimore - MD 21206",
    "county": "Baltimore city",
    "state": "MD",
    "city": "Baltimore"
  },
  {
    "value": "21207",
    "label": "Gwynn Oak - MD 21207",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Gwynn Oak"
  },
  {
    "value": "21208",
    "label": "Pikesville - MD 21208",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Pikesville"
  },
  {
    "value": "21209",
    "label": "Baltimore - MD 21209",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Baltimore"
  },
  {
    "value": "21210",
    "label": "Baltimore - MD 21210",
    "county": "Baltimore city",
    "state": "MD",
    "city": "Baltimore"
  },
  {
    "value": "21211",
    "label": "Baltimore - MD 21211",
    "county": "Baltimore city",
    "state": "MD",
    "city": "Baltimore"
  },
  {
    "value": "21212",
    "label": "Baltimore - MD 21212",
    "county": "Baltimore city",
    "state": "MD",
    "city": "Baltimore"
  },
  {
    "value": "21213",
    "label": "Baltimore - MD 21213",
    "county": "Baltimore city",
    "state": "MD",
    "city": "Baltimore"
  },
  {
    "value": "21214",
    "label": "Baltimore - MD 21214",
    "county": "Baltimore city",
    "state": "MD",
    "city": "Baltimore"
  },
  {
    "value": "21215",
    "label": "Baltimore - MD 21215",
    "county": "Baltimore city",
    "state": "MD",
    "city": "Baltimore"
  },
  {
    "value": "21216",
    "label": "Baltimore - MD 21216",
    "county": "Baltimore city",
    "state": "MD",
    "city": "Baltimore"
  },
  {
    "value": "21217",
    "label": "Baltimore - MD 21217",
    "county": "Baltimore city",
    "state": "MD",
    "city": "Baltimore"
  },
  {
    "value": "21218",
    "label": "Baltimore - MD 21218",
    "county": "Baltimore city",
    "state": "MD",
    "city": "Baltimore"
  },
  {
    "value": "21219",
    "label": "Sparrows Point - MD 21219",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Sparrows Point"
  },
  {
    "value": "21220",
    "label": "Middle River - MD 21220",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Middle River"
  },
  {
    "value": "21221",
    "label": "Essex - MD 21221",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Essex"
  },
  {
    "value": "21222",
    "label": "Dundalk - MD 21222",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Dundalk"
  },
  {
    "value": "21223",
    "label": "Baltimore - MD 21223",
    "county": "Baltimore city",
    "state": "MD",
    "city": "Baltimore"
  },
  {
    "value": "21224",
    "label": "Baltimore - MD 21224",
    "county": "Baltimore city",
    "state": "MD",
    "city": "Baltimore"
  },
  {
    "value": "21225",
    "label": "Brooklyn - MD 21225",
    "county": "Baltimore city",
    "state": "MD",
    "city": "Brooklyn"
  },
  {
    "value": "21226",
    "label": "Curtis Bay - MD 21226",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Curtis Bay"
  },
  {
    "value": "21227",
    "label": "Halethorpe - MD 21227",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Halethorpe"
  },
  {
    "value": "21228",
    "label": "Catonsville - MD 21228",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Catonsville"
  },
  {
    "value": "21229",
    "label": "Baltimore - MD 21229",
    "county": "Baltimore city",
    "state": "MD",
    "city": "Baltimore"
  },
  {
    "value": "21230",
    "label": "Baltimore - MD 21230",
    "county": "Baltimore city",
    "state": "MD",
    "city": "Baltimore"
  },
  {
    "value": "21231",
    "label": "Baltimore - MD 21231",
    "county": "Baltimore city",
    "state": "MD",
    "city": "Baltimore"
  },
  {
    "value": "21233",
    "label": "Baltimore - MD 21233",
    "county": "Baltimore City",
    "state": "MD",
    "city": "Baltimore"
  },
  {
    "value": "21234",
    "label": "Parkville - MD 21234",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Parkville"
  },
  {
    "value": "21236",
    "label": "Nottingham - MD 21236",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Nottingham"
  },
  {
    "value": "21237",
    "label": "Rosedale - MD 21237",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Rosedale"
  },
  {
    "value": "21239",
    "label": "Baltimore - MD 21239",
    "county": "Baltimore city",
    "state": "MD",
    "city": "Baltimore"
  },
  {
    "value": "21240",
    "label": "Baltimore - MD 21240",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Baltimore"
  },
  {
    "value": "21244",
    "label": "Windsor Mill - MD 21244",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Windsor Mill"
  },
  {
    "value": "21260",
    "label": "Baltimore - MD 21260",
    "county": "Baltimore City",
    "state": "MD",
    "city": "Baltimore"
  },
  {
    "value": "21261",
    "label": "Baltimore - MD 21261",
    "county": "Baltimore City",
    "state": "MD",
    "city": "Baltimore"
  },
  {
    "value": "21286",
    "label": "Towson - MD 21286",
    "county": "Baltimore County",
    "state": "MD",
    "city": "Towson"
  },
  {
    "value": "21287",
    "label": "Baltimore - MD 21287",
    "county": "Baltimore City",
    "state": "MD",
    "city": "Baltimore"
  },
  {
    "value": "21401",
    "label": "Annapolis - MD 21401",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Annapolis"
  },
  {
    "value": "21402",
    "label": "Annapolis - MD 21402",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Annapolis"
  },
  {
    "value": "21403",
    "label": "Annapolis - MD 21403",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Annapolis"
  },
  {
    "value": "21405",
    "label": "Annapolis - MD 21405",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Annapolis"
  },
  {
    "value": "21409",
    "label": "Annapolis - MD 21409",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Annapolis"
  },
  {
    "value": "21412",
    "label": "Annapolis - MD 21412",
    "county": "Anne Arundel County",
    "state": "MD",
    "city": "Annapolis"
  },
  {
    "value": "21502",
    "label": "Cumberland - MD 21502",
    "county": "Allegany County",
    "state": "MD",
    "city": "Cumberland"
  },
  {
    "value": "21520",
    "label": "Accident - MD 21520",
    "county": "Garrett County",
    "state": "MD",
    "city": "Accident"
  },
  {
    "value": "21521",
    "label": "Barton - MD 21521",
    "county": "Allegany County",
    "state": "MD",
    "city": "Barton"
  },
  {
    "value": "21522",
    "label": "Bittinger - MD 21522",
    "county": "Garrett County",
    "state": "MD",
    "city": "Bittinger"
  },
  {
    "value": "21523",
    "label": "Bloomington - MD 21523",
    "county": "Garrett County",
    "state": "MD",
    "city": "Bloomington"
  },
  {
    "value": "21530",
    "label": "Flintstone - MD 21530",
    "county": "Allegany County",
    "state": "MD",
    "city": "Flintstone"
  },
  {
    "value": "21531",
    "label": "Friendsville - MD 21531",
    "county": "Garrett County",
    "state": "MD",
    "city": "Friendsville"
  },
  {
    "value": "21532",
    "label": "Frostburg - MD 21532",
    "county": "Allegany County",
    "state": "MD",
    "city": "Frostburg"
  },
  {
    "value": "21536",
    "label": "Grantsville - MD 21536",
    "county": "Garrett County",
    "state": "MD",
    "city": "Grantsville"
  },
  {
    "value": "21538",
    "label": "Kitzmiller - MD 21538",
    "county": "Garrett County",
    "state": "MD",
    "city": "Kitzmiller"
  },
  {
    "value": "21539",
    "label": "Lonaconing - MD 21539",
    "county": "Allegany County",
    "state": "MD",
    "city": "Lonaconing"
  },
  {
    "value": "21540",
    "label": "Luke - MD 21540",
    "county": "Allegany County",
    "state": "MD",
    "city": "Luke"
  },
  {
    "value": "21541",
    "label": "Mc Henry - MD 21541",
    "county": "Garrett County",
    "state": "MD",
    "city": "Mc Henry"
  },
  {
    "value": "21545",
    "label": "Mount Savage - MD 21545",
    "county": "Allegany County",
    "state": "MD",
    "city": "Mount Savage"
  },
  {
    "value": "21550",
    "label": "Oakland - MD 21550",
    "county": "Garrett County",
    "state": "MD",
    "city": "Oakland"
  },
  {
    "value": "21555",
    "label": "Oldtown - MD 21555",
    "county": "Allegany County",
    "state": "MD",
    "city": "Oldtown"
  },
  {
    "value": "21557",
    "label": "Rawlings - MD 21557",
    "county": "Allegany County",
    "state": "MD",
    "city": "Rawlings"
  },
  {
    "value": "21561",
    "label": "Swanton - MD 21561",
    "county": "Garrett County",
    "state": "MD",
    "city": "Swanton"
  },
  {
    "value": "21562",
    "label": "Westernport - MD 21562",
    "county": "Allegany County",
    "state": "MD",
    "city": "Westernport"
  },
  {
    "value": "21601",
    "label": "Easton - MD 21601",
    "county": "Talbot County",
    "state": "MD",
    "city": "Easton"
  },
  {
    "value": "21607",
    "label": "Barclay - MD 21607",
    "county": "Queen Anne's County",
    "state": "MD",
    "city": "Barclay"
  },
  {
    "value": "21610",
    "label": "Betterton - MD 21610",
    "county": "Kent County",
    "state": "MD",
    "city": "Betterton"
  },
  {
    "value": "21612",
    "label": "Bozman - MD 21612",
    "county": "Talbot County",
    "state": "MD",
    "city": "Bozman"
  },
  {
    "value": "21613",
    "label": "Cambridge - MD 21613",
    "county": "Dorchester County",
    "state": "MD",
    "city": "Cambridge"
  },
  {
    "value": "21617",
    "label": "Centreville - MD 21617",
    "county": "Queen Anne's County",
    "state": "MD",
    "city": "Centreville"
  },
  {
    "value": "21619",
    "label": "Chester - MD 21619",
    "county": "Queen Anne's County",
    "state": "MD",
    "city": "Chester"
  },
  {
    "value": "21620",
    "label": "Chestertown - MD 21620",
    "county": "Kent County",
    "state": "MD",
    "city": "Chestertown"
  },
  {
    "value": "21622",
    "label": "Church Creek - MD 21622",
    "county": "Dorchester County",
    "state": "MD",
    "city": "Church Creek"
  },
  {
    "value": "21623",
    "label": "Church Hill - MD 21623",
    "county": "Queen Anne's County",
    "state": "MD",
    "city": "Church Hill"
  },
  {
    "value": "21625",
    "label": "Cordova - MD 21625",
    "county": "Talbot County",
    "state": "MD",
    "city": "Cordova"
  },
  {
    "value": "21626",
    "label": "Crapo - MD 21626",
    "county": "Dorchester County",
    "state": "MD",
    "city": "Crapo"
  },
  {
    "value": "21627",
    "label": "Crocheron - MD 21627",
    "county": "Dorchester County",
    "state": "MD",
    "city": "Crocheron"
  },
  {
    "value": "21629",
    "label": "Denton - MD 21629",
    "county": "Caroline County",
    "state": "MD",
    "city": "Denton"
  },
  {
    "value": "21631",
    "label": "East New Market - MD 21631",
    "county": "Dorchester County",
    "state": "MD",
    "city": "East New Market"
  },
  {
    "value": "21632",
    "label": "Federalsburg - MD 21632",
    "county": "Caroline County",
    "state": "MD",
    "city": "Federalsburg"
  },
  {
    "value": "21634",
    "label": "Fishing Creek - MD 21634",
    "county": "Dorchester County",
    "state": "MD",
    "city": "Fishing Creek"
  },
  {
    "value": "21635",
    "label": "Galena - MD 21635",
    "county": "Kent County",
    "state": "MD",
    "city": "Galena"
  },
  {
    "value": "21636",
    "label": "Goldsboro - MD 21636",
    "county": "Caroline County",
    "state": "MD",
    "city": "Goldsboro"
  },
  {
    "value": "21638",
    "label": "Grasonville - MD 21638",
    "county": "Queen Anne's County",
    "state": "MD",
    "city": "Grasonville"
  },
  {
    "value": "21639",
    "label": "Greensboro - MD 21639",
    "county": "Caroline County",
    "state": "MD",
    "city": "Greensboro"
  },
  {
    "value": "21640",
    "label": "Henderson - MD 21640",
    "county": "Caroline County",
    "state": "MD",
    "city": "Henderson"
  },
  {
    "value": "21643",
    "label": "Hurlock - MD 21643",
    "county": "Dorchester County",
    "state": "MD",
    "city": "Hurlock"
  },
  {
    "value": "21644",
    "label": "Ingleside - MD 21644",
    "county": "Queen Anne's County",
    "state": "MD",
    "city": "Ingleside"
  },
  {
    "value": "21645",
    "label": "Kennedyville - MD 21645",
    "county": "Kent County",
    "state": "MD",
    "city": "Kennedyville"
  },
  {
    "value": "21647",
    "label": "Mcdaniel - MD 21647",
    "county": "Talbot County",
    "state": "MD",
    "city": "Mcdaniel"
  },
  {
    "value": "21648",
    "label": "Madison - MD 21648",
    "county": "Dorchester County",
    "state": "MD",
    "city": "Madison"
  },
  {
    "value": "21649",
    "label": "Marydel - MD 21649",
    "county": "Caroline County",
    "state": "MD",
    "city": "Marydel"
  },
  {
    "value": "21650",
    "label": "Massey - MD 21650",
    "county": "Kent County",
    "state": "MD",
    "city": "Massey"
  },
  {
    "value": "21651",
    "label": "Millington - MD 21651",
    "county": "Kent County",
    "state": "MD",
    "city": "Millington"
  },
  {
    "value": "21654",
    "label": "Oxford - MD 21654",
    "county": "Talbot County",
    "state": "MD",
    "city": "Oxford"
  },
  {
    "value": "21655",
    "label": "Preston - MD 21655",
    "county": "Caroline County",
    "state": "MD",
    "city": "Preston"
  },
  {
    "value": "21657",
    "label": "Queen Anne - MD 21657",
    "county": "Queen Anne's County",
    "state": "MD",
    "city": "Queen Anne"
  },
  {
    "value": "21658",
    "label": "Queenstown - MD 21658",
    "county": "Queen Anne's County",
    "state": "MD",
    "city": "Queenstown"
  },
  {
    "value": "21659",
    "label": "Rhodesdale - MD 21659",
    "county": "Dorchester County",
    "state": "MD",
    "city": "Rhodesdale"
  },
  {
    "value": "21660",
    "label": "Ridgely - MD 21660",
    "county": "Caroline County",
    "state": "MD",
    "city": "Ridgely"
  },
  {
    "value": "21661",
    "label": "Rock Hall - MD 21661",
    "county": "Kent County",
    "state": "MD",
    "city": "Rock Hall"
  },
  {
    "value": "21662",
    "label": "Royal Oak - MD 21662",
    "county": "Talbot County",
    "state": "MD",
    "city": "Royal Oak"
  },
  {
    "value": "21663",
    "label": "Saint Michaels - MD 21663",
    "county": "Talbot County",
    "state": "MD",
    "city": "Saint Michaels"
  },
  {
    "value": "21665",
    "label": "Sherwood - MD 21665",
    "county": "Talbot County",
    "state": "MD",
    "city": "Sherwood"
  },
  {
    "value": "21666",
    "label": "Stevensville - MD 21666",
    "county": "Queen Anne's County",
    "state": "MD",
    "city": "Stevensville"
  },
  {
    "value": "21667",
    "label": "Still Pond - MD 21667",
    "county": "Kent County",
    "state": "MD",
    "city": "Still Pond"
  },
  {
    "value": "21668",
    "label": "Sudlersville - MD 21668",
    "county": "Queen Anne's County",
    "state": "MD",
    "city": "Sudlersville"
  },
  {
    "value": "21671",
    "label": "Tilghman - MD 21671",
    "county": "Talbot County",
    "state": "MD",
    "city": "Tilghman"
  },
  {
    "value": "21672",
    "label": "Toddville - MD 21672",
    "county": "Dorchester County",
    "state": "MD",
    "city": "Toddville"
  },
  {
    "value": "21673",
    "label": "Trappe - MD 21673",
    "county": "Talbot County",
    "state": "MD",
    "city": "Trappe"
  },
  {
    "value": "21675",
    "label": "Wingate - MD 21675",
    "county": "Dorchester County",
    "state": "MD",
    "city": "Wingate"
  },
  {
    "value": "21676",
    "label": "Wittman - MD 21676",
    "county": "Talbot County",
    "state": "MD",
    "city": "Wittman"
  },
  {
    "value": "21677",
    "label": "Woolford - MD 21677",
    "county": "Dorchester County",
    "state": "MD",
    "city": "Woolford"
  },
  {
    "value": "21678",
    "label": "Worton - MD 21678",
    "county": "Kent County",
    "state": "MD",
    "city": "Worton"
  },
  {
    "value": "21679",
    "label": "Wye Mills - MD 21679",
    "county": "Talbot County",
    "state": "MD",
    "city": "Wye Mills"
  },
  {
    "value": "21701",
    "label": "Frederick - MD 21701",
    "county": "Frederick County",
    "state": "MD",
    "city": "Frederick"
  },
  {
    "value": "21702",
    "label": "Frederick - MD 21702",
    "county": "Frederick County",
    "state": "MD",
    "city": "Frederick"
  },
  {
    "value": "21703",
    "label": "Frederick - MD 21703",
    "county": "Frederick County",
    "state": "MD",
    "city": "Frederick"
  },
  {
    "value": "21704",
    "label": "Frederick - MD 21704",
    "county": "Frederick County",
    "state": "MD",
    "city": "Frederick"
  },
  {
    "value": "21710",
    "label": "Adamstown - MD 21710",
    "county": "Frederick County",
    "state": "MD",
    "city": "Adamstown"
  },
  {
    "value": "21711",
    "label": "Big Pool - MD 21711",
    "county": "Washington County",
    "state": "MD",
    "city": "Big Pool"
  },
  {
    "value": "21713",
    "label": "Boonsboro - MD 21713",
    "county": "Washington County",
    "state": "MD",
    "city": "Boonsboro"
  },
  {
    "value": "21716",
    "label": "Brunswick - MD 21716",
    "county": "Frederick County",
    "state": "MD",
    "city": "Brunswick"
  },
  {
    "value": "21718",
    "label": "Burkittsville - MD 21718",
    "county": "Frederick County",
    "state": "MD",
    "city": "Burkittsville"
  },
  {
    "value": "21719",
    "label": "Cascade - MD 21719",
    "county": "Washington County",
    "state": "MD",
    "city": "Cascade"
  },
  {
    "value": "21722",
    "label": "Clear Spring - MD 21722",
    "county": "Washington County",
    "state": "MD",
    "city": "Clear Spring"
  },
  {
    "value": "21723",
    "label": "Cooksville - MD 21723",
    "county": "Howard County",
    "state": "MD",
    "city": "Cooksville"
  },
  {
    "value": "21727",
    "label": "Emmitsburg - MD 21727",
    "county": "Frederick County",
    "state": "MD",
    "city": "Emmitsburg"
  },
  {
    "value": "21733",
    "label": "Fairplay - MD 21733",
    "county": "Washington County",
    "state": "MD",
    "city": "Fairplay"
  },
  {
    "value": "21737",
    "label": "Glenelg - MD 21737",
    "county": "Howard County",
    "state": "MD",
    "city": "Glenelg"
  },
  {
    "value": "21738",
    "label": "Glenwood - MD 21738",
    "county": "Howard County",
    "state": "MD",
    "city": "Glenwood"
  },
  {
    "value": "21740",
    "label": "Hagerstown - MD 21740",
    "county": "Washington County",
    "state": "MD",
    "city": "Hagerstown"
  },
  {
    "value": "21742",
    "label": "Hagerstown - MD 21742",
    "county": "Washington County",
    "state": "MD",
    "city": "Hagerstown"
  },
  {
    "value": "21750",
    "label": "Hancock - MD 21750",
    "county": "Washington County",
    "state": "MD",
    "city": "Hancock"
  },
  {
    "value": "21754",
    "label": "Ijamsville - MD 21754",
    "county": "Frederick County",
    "state": "MD",
    "city": "Ijamsville"
  },
  {
    "value": "21755",
    "label": "Jefferson - MD 21755",
    "county": "Frederick County",
    "state": "MD",
    "city": "Jefferson"
  },
  {
    "value": "21756",
    "label": "Keedysville - MD 21756",
    "county": "Washington County",
    "state": "MD",
    "city": "Keedysville"
  },
  {
    "value": "21757",
    "label": "Keymar - MD 21757",
    "county": "Frederick County",
    "state": "MD",
    "city": "Keymar"
  },
  {
    "value": "21758",
    "label": "Knoxville - MD 21758",
    "county": "Frederick County",
    "state": "MD",
    "city": "Knoxville"
  },
  {
    "value": "21766",
    "label": "Little Orleans - MD 21766",
    "county": "Allegany County",
    "state": "MD",
    "city": "Little Orleans"
  },
  {
    "value": "21767",
    "label": "Maugansville - MD 21767",
    "county": "Washington County",
    "state": "MD",
    "city": "Maugansville"
  },
  {
    "value": "21769",
    "label": "Middletown - MD 21769",
    "county": "Frederick County",
    "state": "MD",
    "city": "Middletown"
  },
  {
    "value": "21770",
    "label": "Monrovia - MD 21770",
    "county": "Frederick County",
    "state": "MD",
    "city": "Monrovia"
  },
  {
    "value": "21771",
    "label": "Mount Airy - MD 21771",
    "county": "Frederick County",
    "state": "MD",
    "city": "Mount Airy"
  },
  {
    "value": "21773",
    "label": "Myersville - MD 21773",
    "county": "Frederick County",
    "state": "MD",
    "city": "Myersville"
  },
  {
    "value": "21774",
    "label": "New Market - MD 21774",
    "county": "Frederick County",
    "state": "MD",
    "city": "New Market"
  },
  {
    "value": "21776",
    "label": "New Windsor - MD 21776",
    "county": "Carroll County",
    "state": "MD",
    "city": "New Windsor"
  },
  {
    "value": "21777",
    "label": "Point Of Rocks - MD 21777",
    "county": "Frederick County",
    "state": "MD",
    "city": "Point Of Rocks"
  },
  {
    "value": "21778",
    "label": "Rocky Ridge - MD 21778",
    "county": "Frederick County",
    "state": "MD",
    "city": "Rocky Ridge"
  },
  {
    "value": "21779",
    "label": "Rohrersville - MD 21779",
    "county": "Washington County",
    "state": "MD",
    "city": "Rohrersville"
  },
  {
    "value": "21780",
    "label": "Sabillasville - MD 21780",
    "county": "Frederick County",
    "state": "MD",
    "city": "Sabillasville"
  },
  {
    "value": "21782",
    "label": "Sharpsburg - MD 21782",
    "county": "Washington County",
    "state": "MD",
    "city": "Sharpsburg"
  },
  {
    "value": "21783",
    "label": "Smithsburg - MD 21783",
    "county": "Washington County",
    "state": "MD",
    "city": "Smithsburg"
  },
  {
    "value": "21784",
    "label": "Sykesville - MD 21784",
    "county": "Carroll County",
    "state": "MD",
    "city": "Sykesville"
  },
  {
    "value": "21787",
    "label": "Taneytown - MD 21787",
    "county": "Carroll County",
    "state": "MD",
    "city": "Taneytown"
  },
  {
    "value": "21788",
    "label": "Thurmont - MD 21788",
    "county": "Frederick County",
    "state": "MD",
    "city": "Thurmont"
  },
  {
    "value": "21790",
    "label": "Tuscarora - MD 21790",
    "county": "Frederick County",
    "state": "MD",
    "city": "Tuscarora"
  },
  {
    "value": "21791",
    "label": "Union Bridge - MD 21791",
    "county": "Carroll County",
    "state": "MD",
    "city": "Union Bridge"
  },
  {
    "value": "21793",
    "label": "Walkersville - MD 21793",
    "county": "Frederick County",
    "state": "MD",
    "city": "Walkersville"
  },
  {
    "value": "21794",
    "label": "West Friendship - MD 21794",
    "county": "Howard County",
    "state": "MD",
    "city": "West Friendship"
  },
  {
    "value": "21795",
    "label": "Williamsport - MD 21795",
    "county": "Washington County",
    "state": "MD",
    "city": "Williamsport"
  },
  {
    "value": "21797",
    "label": "Woodbine - MD 21797",
    "county": "Howard County",
    "state": "MD",
    "city": "Woodbine"
  },
  {
    "value": "21798",
    "label": "Woodsboro - MD 21798",
    "county": "Frederick County",
    "state": "MD",
    "city": "Woodsboro"
  },
  {
    "value": "21801",
    "label": "Salisbury - MD 21801",
    "county": "Wicomico County",
    "state": "MD",
    "city": "Salisbury"
  },
  {
    "value": "21804",
    "label": "Salisbury - MD 21804",
    "county": "Wicomico County",
    "state": "MD",
    "city": "Salisbury"
  },
  {
    "value": "21811",
    "label": "Berlin - MD 21811",
    "county": "Worcester County",
    "state": "MD",
    "city": "Berlin"
  },
  {
    "value": "21813",
    "label": "Bishopville - MD 21813",
    "county": "Worcester County",
    "state": "MD",
    "city": "Bishopville"
  },
  {
    "value": "21814",
    "label": "Bivalve - MD 21814",
    "county": "Wicomico County",
    "state": "MD",
    "city": "Bivalve"
  },
  {
    "value": "21817",
    "label": "Crisfield - MD 21817",
    "county": "Somerset County",
    "state": "MD",
    "city": "Crisfield"
  },
  {
    "value": "21821",
    "label": "Deal Island - MD 21821",
    "county": "Somerset County",
    "state": "MD",
    "city": "Deal Island"
  },
  {
    "value": "21822",
    "label": "Eden - MD 21822",
    "county": "Somerset County",
    "state": "MD",
    "city": "Eden"
  },
  {
    "value": "21824",
    "label": "Ewell - MD 21824",
    "county": "Somerset County",
    "state": "MD",
    "city": "Ewell"
  },
  {
    "value": "21826",
    "label": "Fruitland - MD 21826",
    "county": "Wicomico County",
    "state": "MD",
    "city": "Fruitland"
  },
  {
    "value": "21829",
    "label": "Girdletree - MD 21829",
    "county": "Worcester County",
    "state": "MD",
    "city": "Girdletree"
  },
  {
    "value": "21830",
    "label": "Hebron - MD 21830",
    "county": "Wicomico County",
    "state": "MD",
    "city": "Hebron"
  },
  {
    "value": "21835",
    "label": "Linkwood - MD 21835",
    "county": "Dorchester County",
    "state": "MD",
    "city": "Linkwood"
  },
  {
    "value": "21837",
    "label": "Mardela Springs - MD 21837",
    "county": "Wicomico County",
    "state": "MD",
    "city": "Mardela Springs"
  },
  {
    "value": "21838",
    "label": "Marion Station - MD 21838",
    "county": "Somerset County",
    "state": "MD",
    "city": "Marion Station"
  },
  {
    "value": "21840",
    "label": "Nanticoke - MD 21840",
    "county": "Wicomico County",
    "state": "MD",
    "city": "Nanticoke"
  },
  {
    "value": "21841",
    "label": "Newark - MD 21841",
    "county": "Worcester County",
    "state": "MD",
    "city": "Newark"
  },
  {
    "value": "21842",
    "label": "Ocean City - MD 21842",
    "county": "Worcester County",
    "state": "MD",
    "city": "Ocean City"
  },
  {
    "value": "21849",
    "label": "Parsonsburg - MD 21849",
    "county": "Wicomico County",
    "state": "MD",
    "city": "Parsonsburg"
  },
  {
    "value": "21850",
    "label": "Pittsville - MD 21850",
    "county": "Wicomico County",
    "state": "MD",
    "city": "Pittsville"
  },
  {
    "value": "21851",
    "label": "Pocomoke City - MD 21851",
    "county": "Worcester County",
    "state": "MD",
    "city": "Pocomoke City"
  },
  {
    "value": "21853",
    "label": "Princess Anne - MD 21853",
    "county": "Somerset County",
    "state": "MD",
    "city": "Princess Anne"
  },
  {
    "value": "21856",
    "label": "Quantico - MD 21856",
    "county": "Wicomico County",
    "state": "MD",
    "city": "Quantico"
  },
  {
    "value": "21862",
    "label": "Showell - MD 21862",
    "county": "Worcester County",
    "state": "MD",
    "city": "Showell"
  },
  {
    "value": "21863",
    "label": "Snow Hill - MD 21863",
    "county": "Worcester County",
    "state": "MD",
    "city": "Snow Hill"
  },
  {
    "value": "21864",
    "label": "Stockton - MD 21864",
    "county": "Worcester County",
    "state": "MD",
    "city": "Stockton"
  },
  {
    "value": "21865",
    "label": "Tyaskin - MD 21865",
    "county": "Wicomico County",
    "state": "MD",
    "city": "Tyaskin"
  },
  {
    "value": "21869",
    "label": "Vienna - MD 21869",
    "county": "Dorchester County",
    "state": "MD",
    "city": "Vienna"
  },
  {
    "value": "21871",
    "label": "Westover - MD 21871",
    "county": "Somerset County",
    "state": "MD",
    "city": "Westover"
  },
  {
    "value": "21872",
    "label": "Whaleyville - MD 21872",
    "county": "Worcester County",
    "state": "MD",
    "city": "Whaleyville"
  },
  {
    "value": "21874",
    "label": "Willards - MD 21874",
    "county": "Wicomico County",
    "state": "MD",
    "city": "Willards"
  },
  {
    "value": "21875",
    "label": "Delmar - MD 21875",
    "county": "Wicomico County",
    "state": "MD",
    "city": "Delmar"
  },
  {
    "value": "21901",
    "label": "North East - MD 21901",
    "county": "Cecil County",
    "state": "MD",
    "city": "North East"
  },
  {
    "value": "21903",
    "label": "Perryville - MD 21903",
    "county": "Cecil County",
    "state": "MD",
    "city": "Perryville"
  },
  {
    "value": "21904",
    "label": "Port Deposit - MD 21904",
    "county": "Cecil County",
    "state": "MD",
    "city": "Port Deposit"
  },
  {
    "value": "21911",
    "label": "Rising Sun - MD 21911",
    "county": "Cecil County",
    "state": "MD",
    "city": "Rising Sun"
  },
  {
    "value": "21912",
    "label": "Warwick - MD 21912",
    "county": "Cecil County",
    "state": "MD",
    "city": "Warwick"
  },
  {
    "value": "21915",
    "label": "Chesapeake City - MD 21915",
    "county": "Cecil County",
    "state": "MD",
    "city": "Chesapeake City"
  },
  {
    "value": "21917",
    "label": "Colora - MD 21917",
    "county": "Cecil County",
    "state": "MD",
    "city": "Colora"
  },
  {
    "value": "21918",
    "label": "Conowingo - MD 21918",
    "county": "Cecil County",
    "state": "MD",
    "city": "Conowingo"
  },
  {
    "value": "21919",
    "label": "Earleville - MD 21919",
    "county": "Cecil County",
    "state": "MD",
    "city": "Earleville"
  },
  {
    "value": "21921",
    "label": "Elkton - MD 21921",
    "county": "Cecil County",
    "state": "MD",
    "city": "Elkton"
  },
  {
    "value": "01001",
    "label": "Agawam - MA 01001",
    "county": "Hampden County",
    "state": "MA",
    "city": "Agawam"
  },
  {
    "value": "01002",
    "label": "Amherst - MA 01002",
    "county": "Hampshire County",
    "state": "MA",
    "city": "Amherst"
  },
  {
    "value": "01003",
    "label": "Amherst - MA 01003",
    "county": "Hampshire County",
    "state": "MA",
    "city": "Amherst"
  },
  {
    "value": "01005",
    "label": "Barre - MA 01005",
    "county": "Worcester County",
    "state": "MA",
    "city": "Barre"
  },
  {
    "value": "01007",
    "label": "Belchertown - MA 01007",
    "county": "Hampshire County",
    "state": "MA",
    "city": "Belchertown"
  },
  {
    "value": "01008",
    "label": "Blandford - MA 01008",
    "county": "Hampden County",
    "state": "MA",
    "city": "Blandford"
  },
  {
    "value": "01010",
    "label": "Brimfield - MA 01010",
    "county": "Hampden County",
    "state": "MA",
    "city": "Brimfield"
  },
  {
    "value": "01011",
    "label": "Chester - MA 01011",
    "county": "Hampden County",
    "state": "MA",
    "city": "Chester"
  },
  {
    "value": "01012",
    "label": "Chesterfield - MA 01012",
    "county": "Hampshire County",
    "state": "MA",
    "city": "Chesterfield"
  },
  {
    "value": "01013",
    "label": "Chicopee - MA 01013",
    "county": "Hampden County",
    "state": "MA",
    "city": "Chicopee"
  },
  {
    "value": "01020",
    "label": "Chicopee - MA 01020",
    "county": "Hampden County",
    "state": "MA",
    "city": "Chicopee"
  },
  {
    "value": "01022",
    "label": "Chicopee - MA 01022",
    "county": "Hampden County",
    "state": "MA",
    "city": "Chicopee"
  },
  {
    "value": "01026",
    "label": "Cummington - MA 01026",
    "county": "Hampshire County",
    "state": "MA",
    "city": "Cummington"
  },
  {
    "value": "01027",
    "label": "Easthampton - MA 01027",
    "county": "Hampshire County",
    "state": "MA",
    "city": "Easthampton"
  },
  {
    "value": "01028",
    "label": "East Longmeadow - MA 01028",
    "county": "Hampden County",
    "state": "MA",
    "city": "East Longmeadow"
  },
  {
    "value": "01030",
    "label": "Feeding Hills - MA 01030",
    "county": "Hampden County",
    "state": "MA",
    "city": "Feeding Hills"
  },
  {
    "value": "01031",
    "label": "Gilbertville - MA 01031",
    "county": "Worcester County",
    "state": "MA",
    "city": "Gilbertville"
  },
  {
    "value": "01032",
    "label": "Goshen - MA 01032",
    "county": "Hampshire County",
    "state": "MA",
    "city": "Goshen"
  },
  {
    "value": "01033",
    "label": "Granby - MA 01033",
    "county": "Hampshire County",
    "state": "MA",
    "city": "Granby"
  },
  {
    "value": "01034",
    "label": "Granville - MA 01034",
    "county": "Hampden County",
    "state": "MA",
    "city": "Granville"
  },
  {
    "value": "01035",
    "label": "Hadley - MA 01035",
    "county": "Hampshire County",
    "state": "MA",
    "city": "Hadley"
  },
  {
    "value": "01036",
    "label": "Hampden - MA 01036",
    "county": "Hampden County",
    "state": "MA",
    "city": "Hampden"
  },
  {
    "value": "01038",
    "label": "Hatfield - MA 01038",
    "county": "Hampshire County",
    "state": "MA",
    "city": "Hatfield"
  },
  {
    "value": "01039",
    "label": "Haydenville - MA 01039",
    "county": "Hampshire County",
    "state": "MA",
    "city": "Haydenville"
  },
  {
    "value": "01040",
    "label": "Holyoke - MA 01040",
    "county": "Hampden County",
    "state": "MA",
    "city": "Holyoke"
  },
  {
    "value": "01050",
    "label": "Huntington - MA 01050",
    "county": "Hampshire County",
    "state": "MA",
    "city": "Huntington"
  },
  {
    "value": "01053",
    "label": "Leeds - MA 01053",
    "county": "Hampshire County",
    "state": "MA",
    "city": "Leeds"
  },
  {
    "value": "01054",
    "label": "Leverett - MA 01054",
    "county": "Franklin County",
    "state": "MA",
    "city": "Leverett"
  },
  {
    "value": "01056",
    "label": "Ludlow - MA 01056",
    "county": "Hampden County",
    "state": "MA",
    "city": "Ludlow"
  },
  {
    "value": "01057",
    "label": "Monson - MA 01057",
    "county": "Hampden County",
    "state": "MA",
    "city": "Monson"
  },
  {
    "value": "01060",
    "label": "Northampton - MA 01060",
    "county": "Hampshire County",
    "state": "MA",
    "city": "Northampton"
  },
  {
    "value": "01062",
    "label": "Florence - MA 01062",
    "county": "Hampshire County",
    "state": "MA",
    "city": "Florence"
  },
  {
    "value": "01068",
    "label": "Oakham - MA 01068",
    "county": "Worcester County",
    "state": "MA",
    "city": "Oakham"
  },
  {
    "value": "01069",
    "label": "Palmer - MA 01069",
    "county": "Hampden County",
    "state": "MA",
    "city": "Palmer"
  },
  {
    "value": "01070",
    "label": "Plainfield - MA 01070",
    "county": "Hampshire County",
    "state": "MA",
    "city": "Plainfield"
  },
  {
    "value": "01071",
    "label": "Russell - MA 01071",
    "county": "Hampden County",
    "state": "MA",
    "city": "Russell"
  },
  {
    "value": "01072",
    "label": "Shutesbury - MA 01072",
    "county": "Franklin County",
    "state": "MA",
    "city": "Shutesbury"
  },
  {
    "value": "01073",
    "label": "Southampton - MA 01073",
    "county": "Hampshire County",
    "state": "MA",
    "city": "Southampton"
  },
  {
    "value": "01075",
    "label": "South Hadley - MA 01075",
    "county": "Hampshire County",
    "state": "MA",
    "city": "South Hadley"
  },
  {
    "value": "01077",
    "label": "Southwick - MA 01077",
    "county": "Hampden County",
    "state": "MA",
    "city": "Southwick"
  },
  {
    "value": "01080",
    "label": "Three Rivers - MA 01080",
    "county": "Hampden County",
    "state": "MA",
    "city": "Three Rivers"
  },
  {
    "value": "01081",
    "label": "Wales - MA 01081",
    "county": "Hampden County",
    "state": "MA",
    "city": "Wales"
  },
  {
    "value": "01082",
    "label": "Ware - MA 01082",
    "county": "Hampshire County",
    "state": "MA",
    "city": "Ware"
  },
  {
    "value": "01084",
    "label": "West Chesterfield - MA 01084",
    "county": "Hampshire County",
    "state": "MA",
    "city": "West Chesterfield"
  },
  {
    "value": "01085",
    "label": "Westfield - MA 01085",
    "county": "Hampden County",
    "state": "MA",
    "city": "Westfield"
  },
  {
    "value": "01088",
    "label": "West Hatfield - MA 01088",
    "county": "Hampshire County",
    "state": "MA",
    "city": "West Hatfield"
  },
  {
    "value": "01089",
    "label": "West Springfield - MA 01089",
    "county": "Hampden County",
    "state": "MA",
    "city": "West Springfield"
  },
  {
    "value": "01095",
    "label": "Wilbraham - MA 01095",
    "county": "Hampden County",
    "state": "MA",
    "city": "Wilbraham"
  },
  {
    "value": "01096",
    "label": "Williamsburg - MA 01096",
    "county": "Hampshire County",
    "state": "MA",
    "city": "Williamsburg"
  },
  {
    "value": "01098",
    "label": "Worthington - MA 01098",
    "county": "Hampshire County",
    "state": "MA",
    "city": "Worthington"
  },
  {
    "value": "01103",
    "label": "Springfield - MA 01103",
    "county": "Hampden County",
    "state": "MA",
    "city": "Springfield"
  },
  {
    "value": "01104",
    "label": "Springfield - MA 01104",
    "county": "Hampden County",
    "state": "MA",
    "city": "Springfield"
  },
  {
    "value": "01105",
    "label": "Springfield - MA 01105",
    "county": "Hampden County",
    "state": "MA",
    "city": "Springfield"
  },
  {
    "value": "01106",
    "label": "Longmeadow - MA 01106",
    "county": "Hampden County",
    "state": "MA",
    "city": "Longmeadow"
  },
  {
    "value": "01107",
    "label": "Springfield - MA 01107",
    "county": "Hampden County",
    "state": "MA",
    "city": "Springfield"
  },
  {
    "value": "01108",
    "label": "Springfield - MA 01108",
    "county": "Hampden County",
    "state": "MA",
    "city": "Springfield"
  },
  {
    "value": "01109",
    "label": "Springfield - MA 01109",
    "county": "Hampden County",
    "state": "MA",
    "city": "Springfield"
  },
  {
    "value": "01118",
    "label": "Springfield - MA 01118",
    "county": "Hampden County",
    "state": "MA",
    "city": "Springfield"
  },
  {
    "value": "01119",
    "label": "Springfield - MA 01119",
    "county": "Hampden County",
    "state": "MA",
    "city": "Springfield"
  },
  {
    "value": "01128",
    "label": "Springfield - MA 01128",
    "county": "Hampden County",
    "state": "MA",
    "city": "Springfield"
  },
  {
    "value": "01129",
    "label": "Springfield - MA 01129",
    "county": "Hampden County",
    "state": "MA",
    "city": "Springfield"
  },
  {
    "value": "01144",
    "label": "Springfield - MA 01144",
    "county": "Hampden County",
    "state": "MA",
    "city": "Springfield"
  },
  {
    "value": "01151",
    "label": "Indian Orchard - MA 01151",
    "county": "Hampden County",
    "state": "MA",
    "city": "Indian Orchard"
  },
  {
    "value": "01152",
    "label": "Springfield - MA 01152",
    "county": "Hampden County",
    "state": "MA",
    "city": "Springfield"
  },
  {
    "value": "01195",
    "label": "Springfield - MA 01195",
    "county": "Hampden County",
    "state": "MA",
    "city": "Springfield"
  },
  {
    "value": "01201",
    "label": "Pittsfield - MA 01201",
    "county": "Berkshire County",
    "state": "MA",
    "city": "Pittsfield"
  },
  {
    "value": "01220",
    "label": "Adams - MA 01220",
    "county": "Berkshire County",
    "state": "MA",
    "city": "Adams"
  },
  {
    "value": "01222",
    "label": "Ashley Falls - MA 01222",
    "county": "Berkshire County",
    "state": "MA",
    "city": "Ashley Falls"
  },
  {
    "value": "01223",
    "label": "Becket - MA 01223",
    "county": "Berkshire County",
    "state": "MA",
    "city": "Becket"
  },
  {
    "value": "01224",
    "label": "Berkshire - MA 01224",
    "county": "Berkshire County",
    "state": "MA",
    "city": "Berkshire"
  },
  {
    "value": "01225",
    "label": "Cheshire - MA 01225",
    "county": "Berkshire County",
    "state": "MA",
    "city": "Cheshire"
  },
  {
    "value": "01226",
    "label": "Dalton - MA 01226",
    "county": "Berkshire County",
    "state": "MA",
    "city": "Dalton"
  },
  {
    "value": "01230",
    "label": "Great Barrington - MA 01230",
    "county": "Berkshire County",
    "state": "MA",
    "city": "Great Barrington"
  },
  {
    "value": "01235",
    "label": "Hinsdale - MA 01235",
    "county": "Berkshire County",
    "state": "MA",
    "city": "Hinsdale"
  },
  {
    "value": "01236",
    "label": "Housatonic - MA 01236",
    "county": "Berkshire County",
    "state": "MA",
    "city": "Housatonic"
  },
  {
    "value": "01237",
    "label": "Lanesborough - MA 01237",
    "county": "Berkshire County",
    "state": "MA",
    "city": "Lanesborough"
  },
  {
    "value": "01238",
    "label": "Lee - MA 01238",
    "county": "Berkshire County",
    "state": "MA",
    "city": "Lee"
  },
  {
    "value": "01240",
    "label": "Lenox - MA 01240",
    "county": "Berkshire County",
    "state": "MA",
    "city": "Lenox"
  },
  {
    "value": "01245",
    "label": "Monterey - MA 01245",
    "county": "Berkshire County",
    "state": "MA",
    "city": "Monterey"
  },
  {
    "value": "01247",
    "label": "North Adams - MA 01247",
    "county": "Berkshire County",
    "state": "MA",
    "city": "North Adams"
  },
  {
    "value": "01252",
    "label": "North Egremont - MA 01252",
    "county": "Berkshire County",
    "state": "MA",
    "city": "North Egremont"
  },
  {
    "value": "01253",
    "label": "Otis - MA 01253",
    "county": "Berkshire County",
    "state": "MA",
    "city": "Otis"
  },
  {
    "value": "01254",
    "label": "Richmond - MA 01254",
    "county": "Berkshire County",
    "state": "MA",
    "city": "Richmond"
  },
  {
    "value": "01255",
    "label": "Sandisfield - MA 01255",
    "county": "Berkshire County",
    "state": "MA",
    "city": "Sandisfield"
  },
  {
    "value": "01256",
    "label": "Savoy - MA 01256",
    "county": "Berkshire County",
    "state": "MA",
    "city": "Savoy"
  },
  {
    "value": "01257",
    "label": "Sheffield - MA 01257",
    "county": "Berkshire County",
    "state": "MA",
    "city": "Sheffield"
  },
  {
    "value": "01259",
    "label": "Southfield - MA 01259",
    "county": "Berkshire County",
    "state": "MA",
    "city": "Southfield"
  },
  {
    "value": "01266",
    "label": "West Stockbridge - MA 01266",
    "county": "Berkshire County",
    "state": "MA",
    "city": "West Stockbridge"
  },
  {
    "value": "01267",
    "label": "Williamstown - MA 01267",
    "county": "Berkshire County",
    "state": "MA",
    "city": "Williamstown"
  },
  {
    "value": "01270",
    "label": "Windsor - MA 01270",
    "county": "Berkshire County",
    "state": "MA",
    "city": "Windsor"
  },
  {
    "value": "01301",
    "label": "Greenfield - MA 01301",
    "county": "Franklin County",
    "state": "MA",
    "city": "Greenfield"
  },
  {
    "value": "01330",
    "label": "Ashfield - MA 01330",
    "county": "Franklin County",
    "state": "MA",
    "city": "Ashfield"
  },
  {
    "value": "01331",
    "label": "Athol - MA 01331",
    "county": "Worcester County",
    "state": "MA",
    "city": "Athol"
  },
  {
    "value": "01337",
    "label": "Bernardston - MA 01337",
    "county": "Franklin County",
    "state": "MA",
    "city": "Bernardston"
  },
  {
    "value": "01338",
    "label": "Buckland - MA 01338",
    "county": "Franklin County",
    "state": "MA",
    "city": "Buckland"
  },
  {
    "value": "01339",
    "label": "Charlemont - MA 01339",
    "county": "Franklin County",
    "state": "MA",
    "city": "Charlemont"
  },
  {
    "value": "01340",
    "label": "Colrain - MA 01340",
    "county": "Franklin County",
    "state": "MA",
    "city": "Colrain"
  },
  {
    "value": "01341",
    "label": "Conway - MA 01341",
    "county": "Franklin County",
    "state": "MA",
    "city": "Conway"
  },
  {
    "value": "01342",
    "label": "Deerfield - MA 01342",
    "county": "Franklin County",
    "state": "MA",
    "city": "Deerfield"
  },
  {
    "value": "01343",
    "label": "Drury - MA 01343",
    "county": "Berkshire County",
    "state": "MA",
    "city": "Drury"
  },
  {
    "value": "01344",
    "label": "Erving - MA 01344",
    "county": "Franklin County",
    "state": "MA",
    "city": "Erving"
  },
  {
    "value": "01346",
    "label": "Heath - MA 01346",
    "county": "Franklin County",
    "state": "MA",
    "city": "Heath"
  },
  {
    "value": "01349",
    "label": "Millers Falls - MA 01349",
    "county": "Franklin County",
    "state": "MA",
    "city": "Millers Falls"
  },
  {
    "value": "01351",
    "label": "Montague - MA 01351",
    "county": "Franklin County",
    "state": "MA",
    "city": "Montague"
  },
  {
    "value": "01354",
    "label": "Gill - MA 01354",
    "county": "Franklin County",
    "state": "MA",
    "city": "Gill"
  },
  {
    "value": "01355",
    "label": "New Salem - MA 01355",
    "county": "Franklin County",
    "state": "MA",
    "city": "New Salem"
  },
  {
    "value": "01360",
    "label": "Northfield - MA 01360",
    "county": "Franklin County",
    "state": "MA",
    "city": "Northfield"
  },
  {
    "value": "01364",
    "label": "Orange - MA 01364",
    "county": "Franklin County",
    "state": "MA",
    "city": "Orange"
  },
  {
    "value": "01366",
    "label": "Petersham - MA 01366",
    "county": "Worcester County",
    "state": "MA",
    "city": "Petersham"
  },
  {
    "value": "01367",
    "label": "Rowe - MA 01367",
    "county": "Franklin County",
    "state": "MA",
    "city": "Rowe"
  },
  {
    "value": "01368",
    "label": "Royalston - MA 01368",
    "county": "Worcester County",
    "state": "MA",
    "city": "Royalston"
  },
  {
    "value": "01370",
    "label": "Shelburne Falls - MA 01370",
    "county": "Franklin County",
    "state": "MA",
    "city": "Shelburne Falls"
  },
  {
    "value": "01373",
    "label": "South Deerfield - MA 01373",
    "county": "Franklin County",
    "state": "MA",
    "city": "South Deerfield"
  },
  {
    "value": "01375",
    "label": "Sunderland - MA 01375",
    "county": "Franklin County",
    "state": "MA",
    "city": "Sunderland"
  },
  {
    "value": "01376",
    "label": "Turners Falls - MA 01376",
    "county": "Franklin County",
    "state": "MA",
    "city": "Turners Falls"
  },
  {
    "value": "01378",
    "label": "Warwick - MA 01378",
    "county": "Franklin County",
    "state": "MA",
    "city": "Warwick"
  },
  {
    "value": "01379",
    "label": "Wendell - MA 01379",
    "county": "Franklin County",
    "state": "MA",
    "city": "Wendell"
  },
  {
    "value": "01380",
    "label": "Wendell Depot - MA 01380",
    "county": "Franklin County",
    "state": "MA",
    "city": "Wendell Depot"
  },
  {
    "value": "01420",
    "label": "Fitchburg - MA 01420",
    "county": "Worcester County",
    "state": "MA",
    "city": "Fitchburg"
  },
  {
    "value": "01430",
    "label": "Ashburnham - MA 01430",
    "county": "Worcester County",
    "state": "MA",
    "city": "Ashburnham"
  },
  {
    "value": "01431",
    "label": "Ashby - MA 01431",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Ashby"
  },
  {
    "value": "01432",
    "label": "Ayer - MA 01432",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Ayer"
  },
  {
    "value": "01434",
    "label": "Devens - MA 01434",
    "county": "Worcester County",
    "state": "MA",
    "city": "Devens"
  },
  {
    "value": "01436",
    "label": "Baldwinville - MA 01436",
    "county": "Worcester County",
    "state": "MA",
    "city": "Baldwinville"
  },
  {
    "value": "01440",
    "label": "Gardner - MA 01440",
    "county": "Worcester County",
    "state": "MA",
    "city": "Gardner"
  },
  {
    "value": "01450",
    "label": "Groton - MA 01450",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Groton"
  },
  {
    "value": "01451",
    "label": "Harvard - MA 01451",
    "county": "Worcester County",
    "state": "MA",
    "city": "Harvard"
  },
  {
    "value": "01452",
    "label": "Hubbardston - MA 01452",
    "county": "Worcester County",
    "state": "MA",
    "city": "Hubbardston"
  },
  {
    "value": "01453",
    "label": "Leominster - MA 01453",
    "county": "Worcester County",
    "state": "MA",
    "city": "Leominster"
  },
  {
    "value": "01460",
    "label": "Littleton - MA 01460",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Littleton"
  },
  {
    "value": "01462",
    "label": "Lunenburg - MA 01462",
    "county": "Worcester County",
    "state": "MA",
    "city": "Lunenburg"
  },
  {
    "value": "01463",
    "label": "Pepperell - MA 01463",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Pepperell"
  },
  {
    "value": "01464",
    "label": "Shirley - MA 01464",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Shirley"
  },
  {
    "value": "01468",
    "label": "Templeton - MA 01468",
    "county": "Worcester County",
    "state": "MA",
    "city": "Templeton"
  },
  {
    "value": "01469",
    "label": "Townsend - MA 01469",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Townsend"
  },
  {
    "value": "01473",
    "label": "Westminster - MA 01473",
    "county": "Worcester County",
    "state": "MA",
    "city": "Westminster"
  },
  {
    "value": "01474",
    "label": "West Townsend - MA 01474",
    "county": "Middlesex County",
    "state": "MA",
    "city": "West Townsend"
  },
  {
    "value": "01475",
    "label": "Winchendon - MA 01475",
    "county": "Worcester County",
    "state": "MA",
    "city": "Winchendon"
  },
  {
    "value": "01501",
    "label": "Auburn - MA 01501",
    "county": "Worcester County",
    "state": "MA",
    "city": "Auburn"
  },
  {
    "value": "01503",
    "label": "Berlin - MA 01503",
    "county": "Worcester County",
    "state": "MA",
    "city": "Berlin"
  },
  {
    "value": "01504",
    "label": "Blackstone - MA 01504",
    "county": "Worcester County",
    "state": "MA",
    "city": "Blackstone"
  },
  {
    "value": "01505",
    "label": "Boylston - MA 01505",
    "county": "Worcester County",
    "state": "MA",
    "city": "Boylston"
  },
  {
    "value": "01506",
    "label": "Brookfield - MA 01506",
    "county": "Worcester County",
    "state": "MA",
    "city": "Brookfield"
  },
  {
    "value": "01507",
    "label": "Charlton - MA 01507",
    "county": "Worcester County",
    "state": "MA",
    "city": "Charlton"
  },
  {
    "value": "01510",
    "label": "Clinton - MA 01510",
    "county": "Worcester County",
    "state": "MA",
    "city": "Clinton"
  },
  {
    "value": "01515",
    "label": "East Brookfield - MA 01515",
    "county": "Worcester County",
    "state": "MA",
    "city": "East Brookfield"
  },
  {
    "value": "01516",
    "label": "Douglas - MA 01516",
    "county": "Worcester County",
    "state": "MA",
    "city": "Douglas"
  },
  {
    "value": "01518",
    "label": "Fiskdale - MA 01518",
    "county": "Worcester County",
    "state": "MA",
    "city": "Fiskdale"
  },
  {
    "value": "01519",
    "label": "Grafton - MA 01519",
    "county": "Worcester County",
    "state": "MA",
    "city": "Grafton"
  },
  {
    "value": "01520",
    "label": "Holden - MA 01520",
    "county": "Worcester County",
    "state": "MA",
    "city": "Holden"
  },
  {
    "value": "01521",
    "label": "Holland - MA 01521",
    "county": "Hampden County",
    "state": "MA",
    "city": "Holland"
  },
  {
    "value": "01522",
    "label": "Jefferson - MA 01522",
    "county": "Worcester County",
    "state": "MA",
    "city": "Jefferson"
  },
  {
    "value": "01523",
    "label": "Lancaster - MA 01523",
    "county": "Worcester County",
    "state": "MA",
    "city": "Lancaster"
  },
  {
    "value": "01524",
    "label": "Leicester - MA 01524",
    "county": "Worcester County",
    "state": "MA",
    "city": "Leicester"
  },
  {
    "value": "01527",
    "label": "Millbury - MA 01527",
    "county": "Worcester County",
    "state": "MA",
    "city": "Millbury"
  },
  {
    "value": "01529",
    "label": "Millville - MA 01529",
    "county": "Worcester County",
    "state": "MA",
    "city": "Millville"
  },
  {
    "value": "01531",
    "label": "New Braintree - MA 01531",
    "county": "Worcester County",
    "state": "MA",
    "city": "New Braintree"
  },
  {
    "value": "01532",
    "label": "Northborough - MA 01532",
    "county": "Worcester County",
    "state": "MA",
    "city": "Northborough"
  },
  {
    "value": "01534",
    "label": "Northbridge - MA 01534",
    "county": "Worcester County",
    "state": "MA",
    "city": "Northbridge"
  },
  {
    "value": "01535",
    "label": "North Brookfield - MA 01535",
    "county": "Worcester County",
    "state": "MA",
    "city": "North Brookfield"
  },
  {
    "value": "01536",
    "label": "North Grafton - MA 01536",
    "county": "Worcester County",
    "state": "MA",
    "city": "North Grafton"
  },
  {
    "value": "01537",
    "label": "North Oxford - MA 01537",
    "county": "Worcester County",
    "state": "MA",
    "city": "North Oxford"
  },
  {
    "value": "01540",
    "label": "Oxford - MA 01540",
    "county": "Worcester County",
    "state": "MA",
    "city": "Oxford"
  },
  {
    "value": "01541",
    "label": "Princeton - MA 01541",
    "county": "Worcester County",
    "state": "MA",
    "city": "Princeton"
  },
  {
    "value": "01542",
    "label": "Rochdale - MA 01542",
    "county": "Worcester County",
    "state": "MA",
    "city": "Rochdale"
  },
  {
    "value": "01543",
    "label": "Rutland - MA 01543",
    "county": "Worcester County",
    "state": "MA",
    "city": "Rutland"
  },
  {
    "value": "01545",
    "label": "Shrewsbury - MA 01545",
    "county": "Worcester County",
    "state": "MA",
    "city": "Shrewsbury"
  },
  {
    "value": "01550",
    "label": "Southbridge - MA 01550",
    "county": "Worcester County",
    "state": "MA",
    "city": "Southbridge"
  },
  {
    "value": "01560",
    "label": "South Grafton - MA 01560",
    "county": "Worcester County",
    "state": "MA",
    "city": "South Grafton"
  },
  {
    "value": "01562",
    "label": "Spencer - MA 01562",
    "county": "Worcester County",
    "state": "MA",
    "city": "Spencer"
  },
  {
    "value": "01564",
    "label": "Sterling - MA 01564",
    "county": "Worcester County",
    "state": "MA",
    "city": "Sterling"
  },
  {
    "value": "01566",
    "label": "Sturbridge - MA 01566",
    "county": "Worcester County",
    "state": "MA",
    "city": "Sturbridge"
  },
  {
    "value": "01568",
    "label": "Upton - MA 01568",
    "county": "Worcester County",
    "state": "MA",
    "city": "Upton"
  },
  {
    "value": "01569",
    "label": "Uxbridge - MA 01569",
    "county": "Worcester County",
    "state": "MA",
    "city": "Uxbridge"
  },
  {
    "value": "01570",
    "label": "Webster - MA 01570",
    "county": "Worcester County",
    "state": "MA",
    "city": "Webster"
  },
  {
    "value": "01571",
    "label": "Dudley - MA 01571",
    "county": "Worcester County",
    "state": "MA",
    "city": "Dudley"
  },
  {
    "value": "01581",
    "label": "Westborough - MA 01581",
    "county": "Worcester County",
    "state": "MA",
    "city": "Westborough"
  },
  {
    "value": "01583",
    "label": "West Boylston - MA 01583",
    "county": "Worcester County",
    "state": "MA",
    "city": "West Boylston"
  },
  {
    "value": "01585",
    "label": "West Brookfield - MA 01585",
    "county": "Worcester County",
    "state": "MA",
    "city": "West Brookfield"
  },
  {
    "value": "01588",
    "label": "Whitinsville - MA 01588",
    "county": "Worcester County",
    "state": "MA",
    "city": "Whitinsville"
  },
  {
    "value": "01590",
    "label": "Sutton - MA 01590",
    "county": "Worcester County",
    "state": "MA",
    "city": "Sutton"
  },
  {
    "value": "01602",
    "label": "Worcester - MA 01602",
    "county": "Worcester County",
    "state": "MA",
    "city": "Worcester"
  },
  {
    "value": "01603",
    "label": "Worcester - MA 01603",
    "county": "Worcester County",
    "state": "MA",
    "city": "Worcester"
  },
  {
    "value": "01604",
    "label": "Worcester - MA 01604",
    "county": "Worcester County",
    "state": "MA",
    "city": "Worcester"
  },
  {
    "value": "01605",
    "label": "Worcester - MA 01605",
    "county": "Worcester County",
    "state": "MA",
    "city": "Worcester"
  },
  {
    "value": "01606",
    "label": "Worcester - MA 01606",
    "county": "Worcester County",
    "state": "MA",
    "city": "Worcester"
  },
  {
    "value": "01607",
    "label": "Worcester - MA 01607",
    "county": "Worcester County",
    "state": "MA",
    "city": "Worcester"
  },
  {
    "value": "01608",
    "label": "Worcester - MA 01608",
    "county": "Worcester County",
    "state": "MA",
    "city": "Worcester"
  },
  {
    "value": "01609",
    "label": "Worcester - MA 01609",
    "county": "Worcester County",
    "state": "MA",
    "city": "Worcester"
  },
  {
    "value": "01610",
    "label": "Worcester - MA 01610",
    "county": "Worcester County",
    "state": "MA",
    "city": "Worcester"
  },
  {
    "value": "01611",
    "label": "Cherry Valley - MA 01611",
    "county": "Worcester County",
    "state": "MA",
    "city": "Cherry Valley"
  },
  {
    "value": "01612",
    "label": "Paxton - MA 01612",
    "county": "Worcester County",
    "state": "MA",
    "city": "Paxton"
  },
  {
    "value": "01655",
    "label": "Worcester - MA 01655",
    "county": "Worcester County",
    "state": "MA",
    "city": "Worcester"
  },
  {
    "value": "01701",
    "label": "Framingham - MA 01701",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Framingham"
  },
  {
    "value": "01702",
    "label": "Framingham - MA 01702",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Framingham"
  },
  {
    "value": "01718",
    "label": "Acton - MA 01718",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Acton"
  },
  {
    "value": "01719",
    "label": "Boxborough - MA 01719",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Boxborough"
  },
  {
    "value": "01720",
    "label": "Acton - MA 01720",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Acton"
  },
  {
    "value": "01721",
    "label": "Ashland - MA 01721",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Ashland"
  },
  {
    "value": "01730",
    "label": "Bedford - MA 01730",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Bedford"
  },
  {
    "value": "01731",
    "label": "Hanscom AFB - MA 01731",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Hanscom AFB"
  },
  {
    "value": "01740",
    "label": "Bolton - MA 01740",
    "county": "Worcester County",
    "state": "MA",
    "city": "Bolton"
  },
  {
    "value": "01741",
    "label": "Carlisle - MA 01741",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Carlisle"
  },
  {
    "value": "01742",
    "label": "Concord - MA 01742",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Concord"
  },
  {
    "value": "01745",
    "label": "Fayville - MA 01745",
    "county": "Worcester County",
    "state": "MA",
    "city": "Fayville"
  },
  {
    "value": "01746",
    "label": "Holliston - MA 01746",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Holliston"
  },
  {
    "value": "01747",
    "label": "Hopedale - MA 01747",
    "county": "Worcester County",
    "state": "MA",
    "city": "Hopedale"
  },
  {
    "value": "01748",
    "label": "Hopkinton - MA 01748",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Hopkinton"
  },
  {
    "value": "01749",
    "label": "Hudson - MA 01749",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Hudson"
  },
  {
    "value": "01752",
    "label": "Marlborough - MA 01752",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Marlborough"
  },
  {
    "value": "01754",
    "label": "Maynard - MA 01754",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Maynard"
  },
  {
    "value": "01756",
    "label": "Mendon - MA 01756",
    "county": "Worcester County",
    "state": "MA",
    "city": "Mendon"
  },
  {
    "value": "01757",
    "label": "Milford - MA 01757",
    "county": "Worcester County",
    "state": "MA",
    "city": "Milford"
  },
  {
    "value": "01760",
    "label": "Natick - MA 01760",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Natick"
  },
  {
    "value": "01770",
    "label": "Sherborn - MA 01770",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Sherborn"
  },
  {
    "value": "01772",
    "label": "Southborough - MA 01772",
    "county": "Worcester County",
    "state": "MA",
    "city": "Southborough"
  },
  {
    "value": "01773",
    "label": "Lincoln - MA 01773",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Lincoln"
  },
  {
    "value": "01775",
    "label": "Stow - MA 01775",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Stow"
  },
  {
    "value": "01776",
    "label": "Sudbury - MA 01776",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Sudbury"
  },
  {
    "value": "01778",
    "label": "Wayland - MA 01778",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Wayland"
  },
  {
    "value": "01801",
    "label": "Woburn - MA 01801",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Woburn"
  },
  {
    "value": "01803",
    "label": "Burlington - MA 01803",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Burlington"
  },
  {
    "value": "01810",
    "label": "Andover - MA 01810",
    "county": "Essex County",
    "state": "MA",
    "city": "Andover"
  },
  {
    "value": "01821",
    "label": "Billerica - MA 01821",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Billerica"
  },
  {
    "value": "01824",
    "label": "Chelmsford - MA 01824",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Chelmsford"
  },
  {
    "value": "01826",
    "label": "Dracut - MA 01826",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Dracut"
  },
  {
    "value": "01827",
    "label": "Dunstable - MA 01827",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Dunstable"
  },
  {
    "value": "01830",
    "label": "Haverhill - MA 01830",
    "county": "Essex County",
    "state": "MA",
    "city": "Haverhill"
  },
  {
    "value": "01832",
    "label": "Haverhill - MA 01832",
    "county": "Essex County",
    "state": "MA",
    "city": "Haverhill"
  },
  {
    "value": "01833",
    "label": "Georgetown - MA 01833",
    "county": "Essex County",
    "state": "MA",
    "city": "Georgetown"
  },
  {
    "value": "01834",
    "label": "Groveland - MA 01834",
    "county": "Essex County",
    "state": "MA",
    "city": "Groveland"
  },
  {
    "value": "01835",
    "label": "Haverhill - MA 01835",
    "county": "Essex County",
    "state": "MA",
    "city": "Haverhill"
  },
  {
    "value": "01840",
    "label": "Lawrence - MA 01840",
    "county": "Essex County",
    "state": "MA",
    "city": "Lawrence"
  },
  {
    "value": "01841",
    "label": "Lawrence - MA 01841",
    "county": "Essex County",
    "state": "MA",
    "city": "Lawrence"
  },
  {
    "value": "01843",
    "label": "Lawrence - MA 01843",
    "county": "Essex County",
    "state": "MA",
    "city": "Lawrence"
  },
  {
    "value": "01844",
    "label": "Methuen - MA 01844",
    "county": "Essex County",
    "state": "MA",
    "city": "Methuen"
  },
  {
    "value": "01845",
    "label": "North Andover - MA 01845",
    "county": "Essex County",
    "state": "MA",
    "city": "North Andover"
  },
  {
    "value": "01850",
    "label": "Lowell - MA 01850",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Lowell"
  },
  {
    "value": "01851",
    "label": "Lowell - MA 01851",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Lowell"
  },
  {
    "value": "01852",
    "label": "Lowell - MA 01852",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Lowell"
  },
  {
    "value": "01854",
    "label": "Lowell - MA 01854",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Lowell"
  },
  {
    "value": "01860",
    "label": "Merrimac - MA 01860",
    "county": "Essex County",
    "state": "MA",
    "city": "Merrimac"
  },
  {
    "value": "01862",
    "label": "North Billerica - MA 01862",
    "county": "Middlesex County",
    "state": "MA",
    "city": "North Billerica"
  },
  {
    "value": "01863",
    "label": "North Chelmsford - MA 01863",
    "county": "Middlesex County",
    "state": "MA",
    "city": "North Chelmsford"
  },
  {
    "value": "01864",
    "label": "North Reading - MA 01864",
    "county": "Middlesex County",
    "state": "MA",
    "city": "North Reading"
  },
  {
    "value": "01867",
    "label": "Reading - MA 01867",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Reading"
  },
  {
    "value": "01876",
    "label": "Tewksbury - MA 01876",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Tewksbury"
  },
  {
    "value": "01879",
    "label": "Tyngsboro - MA 01879",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Tyngsboro"
  },
  {
    "value": "01880",
    "label": "Wakefield - MA 01880",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Wakefield"
  },
  {
    "value": "01886",
    "label": "Westford - MA 01886",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Westford"
  },
  {
    "value": "01887",
    "label": "Wilmington - MA 01887",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Wilmington"
  },
  {
    "value": "01890",
    "label": "Winchester - MA 01890",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Winchester"
  },
  {
    "value": "01901",
    "label": "Lynn - MA 01901",
    "county": "Essex County",
    "state": "MA",
    "city": "Lynn"
  },
  {
    "value": "01902",
    "label": "Lynn - MA 01902",
    "county": "Essex County",
    "state": "MA",
    "city": "Lynn"
  },
  {
    "value": "01904",
    "label": "Lynn - MA 01904",
    "county": "Essex County",
    "state": "MA",
    "city": "Lynn"
  },
  {
    "value": "01905",
    "label": "Lynn - MA 01905",
    "county": "Essex County",
    "state": "MA",
    "city": "Lynn"
  },
  {
    "value": "01906",
    "label": "Saugus - MA 01906",
    "county": "Essex County",
    "state": "MA",
    "city": "Saugus"
  },
  {
    "value": "01907",
    "label": "Swampscott - MA 01907",
    "county": "Essex County",
    "state": "MA",
    "city": "Swampscott"
  },
  {
    "value": "01908",
    "label": "Nahant - MA 01908",
    "county": "Essex County",
    "state": "MA",
    "city": "Nahant"
  },
  {
    "value": "01913",
    "label": "Amesbury - MA 01913",
    "county": "Essex County",
    "state": "MA",
    "city": "Amesbury"
  },
  {
    "value": "01915",
    "label": "Beverly - MA 01915",
    "county": "Essex County",
    "state": "MA",
    "city": "Beverly"
  },
  {
    "value": "01921",
    "label": "Boxford - MA 01921",
    "county": "Essex County",
    "state": "MA",
    "city": "Boxford"
  },
  {
    "value": "01922",
    "label": "Byfield - MA 01922",
    "county": "Essex County",
    "state": "MA",
    "city": "Byfield"
  },
  {
    "value": "01923",
    "label": "Danvers - MA 01923",
    "county": "Essex County",
    "state": "MA",
    "city": "Danvers"
  },
  {
    "value": "01929",
    "label": "Essex - MA 01929",
    "county": "Essex County",
    "state": "MA",
    "city": "Essex"
  },
  {
    "value": "01930",
    "label": "Gloucester - MA 01930",
    "county": "Essex County",
    "state": "MA",
    "city": "Gloucester"
  },
  {
    "value": "01938",
    "label": "Ipswich - MA 01938",
    "county": "Essex County",
    "state": "MA",
    "city": "Ipswich"
  },
  {
    "value": "01940",
    "label": "Lynnfield - MA 01940",
    "county": "Essex County",
    "state": "MA",
    "city": "Lynnfield"
  },
  {
    "value": "01944",
    "label": "Manchester - MA 01944",
    "county": "Essex County",
    "state": "MA",
    "city": "Manchester"
  },
  {
    "value": "01945",
    "label": "Marblehead - MA 01945",
    "county": "Essex County",
    "state": "MA",
    "city": "Marblehead"
  },
  {
    "value": "01949",
    "label": "Middleton - MA 01949",
    "county": "Essex County",
    "state": "MA",
    "city": "Middleton"
  },
  {
    "value": "01950",
    "label": "Newburyport - MA 01950",
    "county": "Essex County",
    "state": "MA",
    "city": "Newburyport"
  },
  {
    "value": "01951",
    "label": "Newbury - MA 01951",
    "county": "Essex County",
    "state": "MA",
    "city": "Newbury"
  },
  {
    "value": "01952",
    "label": "Salisbury - MA 01952",
    "county": "Essex County",
    "state": "MA",
    "city": "Salisbury"
  },
  {
    "value": "01960",
    "label": "Peabody - MA 01960",
    "county": "Essex County",
    "state": "MA",
    "city": "Peabody"
  },
  {
    "value": "01966",
    "label": "Rockport - MA 01966",
    "county": "Essex County",
    "state": "MA",
    "city": "Rockport"
  },
  {
    "value": "01969",
    "label": "Rowley - MA 01969",
    "county": "Essex County",
    "state": "MA",
    "city": "Rowley"
  },
  {
    "value": "01970",
    "label": "Salem - MA 01970",
    "county": "Essex County",
    "state": "MA",
    "city": "Salem"
  },
  {
    "value": "01982",
    "label": "South Hamilton - MA 01982",
    "county": "Essex County",
    "state": "MA",
    "city": "South Hamilton"
  },
  {
    "value": "01983",
    "label": "Topsfield - MA 01983",
    "county": "Essex County",
    "state": "MA",
    "city": "Topsfield"
  },
  {
    "value": "01984",
    "label": "Wenham - MA 01984",
    "county": "Essex County",
    "state": "MA",
    "city": "Wenham"
  },
  {
    "value": "01985",
    "label": "West Newbury - MA 01985",
    "county": "Essex County",
    "state": "MA",
    "city": "West Newbury"
  },
  {
    "value": "02019",
    "label": "Bellingham - MA 02019",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Bellingham"
  },
  {
    "value": "02021",
    "label": "Canton - MA 02021",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Canton"
  },
  {
    "value": "02025",
    "label": "Cohasset - MA 02025",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Cohasset"
  },
  {
    "value": "02026",
    "label": "Dedham - MA 02026",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Dedham"
  },
  {
    "value": "02030",
    "label": "Dover - MA 02030",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Dover"
  },
  {
    "value": "02031",
    "label": "East Mansfield - MA 02031",
    "county": "Bristol County",
    "state": "MA",
    "city": "East Mansfield"
  },
  {
    "value": "02032",
    "label": "East Walpole - MA 02032",
    "county": "Norfolk County",
    "state": "MA",
    "city": "East Walpole"
  },
  {
    "value": "02035",
    "label": "Foxboro - MA 02035",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Foxboro"
  },
  {
    "value": "02038",
    "label": "Franklin - MA 02038",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Franklin"
  },
  {
    "value": "02043",
    "label": "Hingham - MA 02043",
    "county": "Plymouth County",
    "state": "MA",
    "city": "Hingham"
  },
  {
    "value": "02045",
    "label": "Hull - MA 02045",
    "county": "Plymouth County",
    "state": "MA",
    "city": "Hull"
  },
  {
    "value": "02048",
    "label": "Mansfield - MA 02048",
    "county": "Bristol County",
    "state": "MA",
    "city": "Mansfield"
  },
  {
    "value": "02050",
    "label": "Marshfield - MA 02050",
    "county": "Plymouth County",
    "state": "MA",
    "city": "Marshfield"
  },
  {
    "value": "02052",
    "label": "Medfield - MA 02052",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Medfield"
  },
  {
    "value": "02053",
    "label": "Medway - MA 02053",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Medway"
  },
  {
    "value": "02054",
    "label": "Millis - MA 02054",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Millis"
  },
  {
    "value": "02056",
    "label": "Norfolk - MA 02056",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Norfolk"
  },
  {
    "value": "02061",
    "label": "Norwell - MA 02061",
    "county": "Plymouth County",
    "state": "MA",
    "city": "Norwell"
  },
  {
    "value": "02062",
    "label": "Norwood - MA 02062",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Norwood"
  },
  {
    "value": "02066",
    "label": "Scituate - MA 02066",
    "county": "Plymouth County",
    "state": "MA",
    "city": "Scituate"
  },
  {
    "value": "02067",
    "label": "Sharon - MA 02067",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Sharon"
  },
  {
    "value": "02071",
    "label": "South Walpole - MA 02071",
    "county": "Norfolk County",
    "state": "MA",
    "city": "South Walpole"
  },
  {
    "value": "02072",
    "label": "Stoughton - MA 02072",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Stoughton"
  },
  {
    "value": "02081",
    "label": "Walpole - MA 02081",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Walpole"
  },
  {
    "value": "02090",
    "label": "Westwood - MA 02090",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Westwood"
  },
  {
    "value": "02093",
    "label": "Wrentham - MA 02093",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Wrentham"
  },
  {
    "value": "02108",
    "label": "Boston - MA 02108",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Boston"
  },
  {
    "value": "02109",
    "label": "Boston - MA 02109",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Boston"
  },
  {
    "value": "02110",
    "label": "Boston - MA 02110",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Boston"
  },
  {
    "value": "02111",
    "label": "Boston - MA 02111",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Boston"
  },
  {
    "value": "02113",
    "label": "Boston - MA 02113",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Boston"
  },
  {
    "value": "02114",
    "label": "Boston - MA 02114",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Boston"
  },
  {
    "value": "02115",
    "label": "Boston - MA 02115",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Boston"
  },
  {
    "value": "02116",
    "label": "Boston - MA 02116",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Boston"
  },
  {
    "value": "02118",
    "label": "Boston - MA 02118",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Boston"
  },
  {
    "value": "02119",
    "label": "Roxbury - MA 02119",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Roxbury"
  },
  {
    "value": "02120",
    "label": "Roxbury Crossing - MA 02120",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Roxbury Crossing"
  },
  {
    "value": "02121",
    "label": "Dorchester - MA 02121",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Dorchester"
  },
  {
    "value": "02122",
    "label": "Dorchester - MA 02122",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Dorchester"
  },
  {
    "value": "02124",
    "label": "Dorchester Center - MA 02124",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Dorchester Center"
  },
  {
    "value": "02125",
    "label": "Dorchester - MA 02125",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Dorchester"
  },
  {
    "value": "02126",
    "label": "Mattapan - MA 02126",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Mattapan"
  },
  {
    "value": "02127",
    "label": "South Boston - MA 02127",
    "county": "Suffolk County",
    "state": "MA",
    "city": "South Boston"
  },
  {
    "value": "02128",
    "label": "East Boston - MA 02128",
    "county": "Suffolk County",
    "state": "MA",
    "city": "East Boston"
  },
  {
    "value": "02129",
    "label": "Charlestown - MA 02129",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Charlestown"
  },
  {
    "value": "02130",
    "label": "Jamaica Plain - MA 02130",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Jamaica Plain"
  },
  {
    "value": "02131",
    "label": "Roslindale - MA 02131",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Roslindale"
  },
  {
    "value": "02132",
    "label": "West Roxbury - MA 02132",
    "county": "Suffolk County",
    "state": "MA",
    "city": "West Roxbury"
  },
  {
    "value": "02133",
    "label": "Boston - MA 02133",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Boston"
  },
  {
    "value": "02134",
    "label": "Allston - MA 02134",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Allston"
  },
  {
    "value": "02135",
    "label": "Brighton - MA 02135",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Brighton"
  },
  {
    "value": "02136",
    "label": "Hyde Park - MA 02136",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Hyde Park"
  },
  {
    "value": "02138",
    "label": "Cambridge - MA 02138",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Cambridge"
  },
  {
    "value": "02139",
    "label": "Cambridge - MA 02139",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Cambridge"
  },
  {
    "value": "02140",
    "label": "Cambridge - MA 02140",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Cambridge"
  },
  {
    "value": "02141",
    "label": "Cambridge - MA 02141",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Cambridge"
  },
  {
    "value": "02142",
    "label": "Cambridge - MA 02142",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Cambridge"
  },
  {
    "value": "02143",
    "label": "Somerville - MA 02143",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Somerville"
  },
  {
    "value": "02144",
    "label": "Somerville - MA 02144",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Somerville"
  },
  {
    "value": "02145",
    "label": "Somerville - MA 02145",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Somerville"
  },
  {
    "value": "02148",
    "label": "Malden - MA 02148",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Malden"
  },
  {
    "value": "02149",
    "label": "Everett - MA 02149",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Everett"
  },
  {
    "value": "02150",
    "label": "Chelsea - MA 02150",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Chelsea"
  },
  {
    "value": "02151",
    "label": "Revere - MA 02151",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Revere"
  },
  {
    "value": "02152",
    "label": "Winthrop - MA 02152",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Winthrop"
  },
  {
    "value": "02155",
    "label": "Medford - MA 02155",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Medford"
  },
  {
    "value": "02163",
    "label": "Boston - MA 02163",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Boston"
  },
  {
    "value": "02169",
    "label": "Quincy - MA 02169",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Quincy"
  },
  {
    "value": "02170",
    "label": "Quincy - MA 02170",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Quincy"
  },
  {
    "value": "02171",
    "label": "Quincy - MA 02171",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Quincy"
  },
  {
    "value": "02176",
    "label": "Melrose - MA 02176",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Melrose"
  },
  {
    "value": "02180",
    "label": "Stoneham - MA 02180",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Stoneham"
  },
  {
    "value": "02184",
    "label": "Braintree - MA 02184",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Braintree"
  },
  {
    "value": "02186",
    "label": "Milton - MA 02186",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Milton"
  },
  {
    "value": "02188",
    "label": "Weymouth - MA 02188",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Weymouth"
  },
  {
    "value": "02189",
    "label": "East Weymouth - MA 02189",
    "county": "Norfolk County",
    "state": "MA",
    "city": "East Weymouth"
  },
  {
    "value": "02190",
    "label": "South Weymouth - MA 02190",
    "county": "Norfolk County",
    "state": "MA",
    "city": "South Weymouth"
  },
  {
    "value": "02191",
    "label": "North Weymouth - MA 02191",
    "county": "Norfolk County",
    "state": "MA",
    "city": "North Weymouth"
  },
  {
    "value": "02199",
    "label": "Boston - MA 02199",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Boston"
  },
  {
    "value": "02203",
    "label": "Boston - MA 02203",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Boston"
  },
  {
    "value": "02210",
    "label": "Boston - MA 02210",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Boston"
  },
  {
    "value": "02215",
    "label": "Boston - MA 02215",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Boston"
  },
  {
    "value": "02222",
    "label": "Boston - MA 02222",
    "county": "Suffolk County",
    "state": "MA",
    "city": "Boston"
  },
  {
    "value": "02301",
    "label": "Brockton - MA 02301",
    "county": "Plymouth County",
    "state": "MA",
    "city": "Brockton"
  },
  {
    "value": "02302",
    "label": "Brockton - MA 02302",
    "county": "Plymouth County",
    "state": "MA",
    "city": "Brockton"
  },
  {
    "value": "02322",
    "label": "Avon - MA 02322",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Avon"
  },
  {
    "value": "02324",
    "label": "Bridgewater - MA 02324",
    "county": "Plymouth County",
    "state": "MA",
    "city": "Bridgewater"
  },
  {
    "value": "02330",
    "label": "Carver - MA 02330",
    "county": "Plymouth County",
    "state": "MA",
    "city": "Carver"
  },
  {
    "value": "02332",
    "label": "Duxbury - MA 02332",
    "county": "Plymouth County",
    "state": "MA",
    "city": "Duxbury"
  },
  {
    "value": "02333",
    "label": "East Bridgewater - MA 02333",
    "county": "Plymouth County",
    "state": "MA",
    "city": "East Bridgewater"
  },
  {
    "value": "02338",
    "label": "Halifax - MA 02338",
    "county": "Plymouth County",
    "state": "MA",
    "city": "Halifax"
  },
  {
    "value": "02339",
    "label": "Hanover - MA 02339",
    "county": "Plymouth County",
    "state": "MA",
    "city": "Hanover"
  },
  {
    "value": "02341",
    "label": "Hanson - MA 02341",
    "county": "Plymouth County",
    "state": "MA",
    "city": "Hanson"
  },
  {
    "value": "02343",
    "label": "Holbrook - MA 02343",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Holbrook"
  },
  {
    "value": "02346",
    "label": "Middleboro - MA 02346",
    "county": "Plymouth County",
    "state": "MA",
    "city": "Middleboro"
  },
  {
    "value": "02347",
    "label": "Lakeville - MA 02347",
    "county": "Plymouth County",
    "state": "MA",
    "city": "Lakeville"
  },
  {
    "value": "02351",
    "label": "Abington - MA 02351",
    "county": "Plymouth County",
    "state": "MA",
    "city": "Abington"
  },
  {
    "value": "02356",
    "label": "North Easton - MA 02356",
    "county": "Bristol County",
    "state": "MA",
    "city": "North Easton"
  },
  {
    "value": "02359",
    "label": "Pembroke - MA 02359",
    "county": "Plymouth County",
    "state": "MA",
    "city": "Pembroke"
  },
  {
    "value": "02360",
    "label": "Plymouth - MA 02360",
    "county": "Plymouth County",
    "state": "MA",
    "city": "Plymouth"
  },
  {
    "value": "02364",
    "label": "Kingston - MA 02364",
    "county": "Plymouth County",
    "state": "MA",
    "city": "Kingston"
  },
  {
    "value": "02367",
    "label": "Plympton - MA 02367",
    "county": "Plymouth County",
    "state": "MA",
    "city": "Plympton"
  },
  {
    "value": "02368",
    "label": "Randolph - MA 02368",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Randolph"
  },
  {
    "value": "02370",
    "label": "Rockland - MA 02370",
    "county": "Plymouth County",
    "state": "MA",
    "city": "Rockland"
  },
  {
    "value": "02375",
    "label": "South Easton - MA 02375",
    "county": "Bristol County",
    "state": "MA",
    "city": "South Easton"
  },
  {
    "value": "02379",
    "label": "West Bridgewater - MA 02379",
    "county": "Plymouth County",
    "state": "MA",
    "city": "West Bridgewater"
  },
  {
    "value": "02382",
    "label": "Whitman - MA 02382",
    "county": "Plymouth County",
    "state": "MA",
    "city": "Whitman"
  },
  {
    "value": "02420",
    "label": "Lexington - MA 02420",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Lexington"
  },
  {
    "value": "02421",
    "label": "Lexington - MA 02421",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Lexington"
  },
  {
    "value": "02445",
    "label": "Brookline - MA 02445",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Brookline"
  },
  {
    "value": "02446",
    "label": "Brookline - MA 02446",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Brookline"
  },
  {
    "value": "02451",
    "label": "Waltham - MA 02451",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Waltham"
  },
  {
    "value": "02452",
    "label": "Waltham - MA 02452",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Waltham"
  },
  {
    "value": "02453",
    "label": "Waltham - MA 02453",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Waltham"
  },
  {
    "value": "02458",
    "label": "Newton - MA 02458",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Newton"
  },
  {
    "value": "02459",
    "label": "Newton Center - MA 02459",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Newton Center"
  },
  {
    "value": "02460",
    "label": "Newtonville - MA 02460",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Newtonville"
  },
  {
    "value": "02461",
    "label": "Newton Highlands - MA 02461",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Newton Highlands"
  },
  {
    "value": "02462",
    "label": "Newton Lower Falls - MA 02462",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Newton Lower Falls"
  },
  {
    "value": "02464",
    "label": "Newton Upper Falls - MA 02464",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Newton Upper Falls"
  },
  {
    "value": "02465",
    "label": "West Newton - MA 02465",
    "county": "Middlesex County",
    "state": "MA",
    "city": "West Newton"
  },
  {
    "value": "02466",
    "label": "Auburndale - MA 02466",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Auburndale"
  },
  {
    "value": "02467",
    "label": "Chestnut Hill - MA 02467",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Chestnut Hill"
  },
  {
    "value": "02468",
    "label": "Waban - MA 02468",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Waban"
  },
  {
    "value": "02472",
    "label": "Watertown - MA 02472",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Watertown"
  },
  {
    "value": "02474",
    "label": "Arlington - MA 02474",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Arlington"
  },
  {
    "value": "02476",
    "label": "Arlington - MA 02476",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Arlington"
  },
  {
    "value": "02478",
    "label": "Belmont - MA 02478",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Belmont"
  },
  {
    "value": "02481",
    "label": "Wellesley Hills - MA 02481",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Wellesley Hills"
  },
  {
    "value": "02482",
    "label": "Wellesley - MA 02482",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Wellesley"
  },
  {
    "value": "02492",
    "label": "Needham - MA 02492",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Needham"
  },
  {
    "value": "02493",
    "label": "Weston - MA 02493",
    "county": "Middlesex County",
    "state": "MA",
    "city": "Weston"
  },
  {
    "value": "02494",
    "label": "Needham Heights - MA 02494",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Needham Heights"
  },
  {
    "value": "02532",
    "label": "Buzzards Bay - MA 02532",
    "county": "Barnstable County",
    "state": "MA",
    "city": "Buzzards Bay"
  },
  {
    "value": "02535",
    "label": "Chilmark - MA 02535",
    "county": "Dukes County",
    "state": "MA",
    "city": "Chilmark"
  },
  {
    "value": "02536",
    "label": "East Falmouth - MA 02536",
    "county": "Barnstable County",
    "state": "MA",
    "city": "East Falmouth"
  },
  {
    "value": "02537",
    "label": "East Sandwich - MA 02537",
    "county": "Barnstable County",
    "state": "MA",
    "city": "East Sandwich"
  },
  {
    "value": "02538",
    "label": "East Wareham - MA 02538",
    "county": "Plymouth County",
    "state": "MA",
    "city": "East Wareham"
  },
  {
    "value": "02539",
    "label": "Edgartown - MA 02539",
    "county": "Dukes County",
    "state": "MA",
    "city": "Edgartown"
  },
  {
    "value": "02540",
    "label": "Falmouth - MA 02540",
    "county": "Barnstable County",
    "state": "MA",
    "city": "Falmouth"
  },
  {
    "value": "02542",
    "label": "Buzzards Bay - MA 02542",
    "county": "Barnstable County",
    "state": "MA",
    "city": "Buzzards Bay"
  },
  {
    "value": "02543",
    "label": "Woods Hole - MA 02543",
    "county": "Barnstable County",
    "state": "MA",
    "city": "Woods Hole"
  },
  {
    "value": "02554",
    "label": "Nantucket - MA 02554",
    "county": "Nantucket County",
    "state": "MA",
    "city": "Nantucket"
  },
  {
    "value": "02556",
    "label": "North Falmouth - MA 02556",
    "county": "Barnstable County",
    "state": "MA",
    "city": "North Falmouth"
  },
  {
    "value": "02559",
    "label": "Pocasset - MA 02559",
    "county": "Barnstable County",
    "state": "MA",
    "city": "Pocasset"
  },
  {
    "value": "02562",
    "label": "Sagamore Beach - MA 02562",
    "county": "Barnstable County",
    "state": "MA",
    "city": "Sagamore Beach"
  },
  {
    "value": "02563",
    "label": "Sandwich - MA 02563",
    "county": "Barnstable County",
    "state": "MA",
    "city": "Sandwich"
  },
  {
    "value": "02568",
    "label": "Vineyard Haven - MA 02568",
    "county": "Dukes County",
    "state": "MA",
    "city": "Vineyard Haven"
  },
  {
    "value": "02571",
    "label": "Wareham - MA 02571",
    "county": "Plymouth County",
    "state": "MA",
    "city": "Wareham"
  },
  {
    "value": "02576",
    "label": "West Wareham - MA 02576",
    "county": "Plymouth County",
    "state": "MA",
    "city": "West Wareham"
  },
  {
    "value": "02601",
    "label": "Hyannis - MA 02601",
    "county": "Barnstable County",
    "state": "MA",
    "city": "Hyannis"
  },
  {
    "value": "02630",
    "label": "Barnstable - MA 02630",
    "county": "Barnstable County",
    "state": "MA",
    "city": "Barnstable"
  },
  {
    "value": "02631",
    "label": "Brewster - MA 02631",
    "county": "Barnstable County",
    "state": "MA",
    "city": "Brewster"
  },
  {
    "value": "02632",
    "label": "Centerville - MA 02632",
    "county": "Barnstable County",
    "state": "MA",
    "city": "Centerville"
  },
  {
    "value": "02633",
    "label": "Chatham - MA 02633",
    "county": "Barnstable County",
    "state": "MA",
    "city": "Chatham"
  },
  {
    "value": "02635",
    "label": "Cotuit - MA 02635",
    "county": "Barnstable County",
    "state": "MA",
    "city": "Cotuit"
  },
  {
    "value": "02636",
    "label": "Centerville - MA 02636",
    "county": "Barnstable County",
    "state": "MA",
    "city": "Centerville"
  },
  {
    "value": "02638",
    "label": "Dennis - MA 02638",
    "county": "Barnstable County",
    "state": "MA",
    "city": "Dennis"
  },
  {
    "value": "02639",
    "label": "Dennis Port - MA 02639",
    "county": "Barnstable County",
    "state": "MA",
    "city": "Dennis Port"
  },
  {
    "value": "02642",
    "label": "Eastham - MA 02642",
    "county": "Barnstable County",
    "state": "MA",
    "city": "Eastham"
  },
  {
    "value": "02644",
    "label": "Forestdale - MA 02644",
    "county": "Barnstable County",
    "state": "MA",
    "city": "Forestdale"
  },
  {
    "value": "02645",
    "label": "Harwich - MA 02645",
    "county": "Barnstable County",
    "state": "MA",
    "city": "Harwich"
  },
  {
    "value": "02646",
    "label": "Harwich Port - MA 02646",
    "county": "Barnstable County",
    "state": "MA",
    "city": "Harwich Port"
  },
  {
    "value": "02648",
    "label": "Marstons Mills - MA 02648",
    "county": "Barnstable County",
    "state": "MA",
    "city": "Marstons Mills"
  },
  {
    "value": "02649",
    "label": "Mashpee - MA 02649",
    "county": "Barnstable County",
    "state": "MA",
    "city": "Mashpee"
  },
  {
    "value": "02650",
    "label": "North Chatham - MA 02650",
    "county": "Barnstable County",
    "state": "MA",
    "city": "North Chatham"
  },
  {
    "value": "02653",
    "label": "Orleans - MA 02653",
    "county": "Barnstable County",
    "state": "MA",
    "city": "Orleans"
  },
  {
    "value": "02655",
    "label": "Osterville - MA 02655",
    "county": "Barnstable County",
    "state": "MA",
    "city": "Osterville"
  },
  {
    "value": "02657",
    "label": "Provincetown - MA 02657",
    "county": "Barnstable County",
    "state": "MA",
    "city": "Provincetown"
  },
  {
    "value": "02659",
    "label": "South Chatham - MA 02659",
    "county": "Barnstable County",
    "state": "MA",
    "city": "South Chatham"
  },
  {
    "value": "02660",
    "label": "South Dennis - MA 02660",
    "county": "Barnstable County",
    "state": "MA",
    "city": "South Dennis"
  },
  {
    "value": "02664",
    "label": "South Yarmouth - MA 02664",
    "county": "Barnstable County",
    "state": "MA",
    "city": "South Yarmouth"
  },
  {
    "value": "02667",
    "label": "Wellfleet - MA 02667",
    "county": "Barnstable County",
    "state": "MA",
    "city": "Wellfleet"
  },
  {
    "value": "02668",
    "label": "West Barnstable - MA 02668",
    "county": "Barnstable County",
    "state": "MA",
    "city": "West Barnstable"
  },
  {
    "value": "02670",
    "label": "West Dennis - MA 02670",
    "county": "Barnstable County",
    "state": "MA",
    "city": "West Dennis"
  },
  {
    "value": "02671",
    "label": "West Harwich - MA 02671",
    "county": "Barnstable County",
    "state": "MA",
    "city": "West Harwich"
  },
  {
    "value": "02673",
    "label": "West Yarmouth - MA 02673",
    "county": "Barnstable County",
    "state": "MA",
    "city": "West Yarmouth"
  },
  {
    "value": "02675",
    "label": "Yarmouth Port - MA 02675",
    "county": "Barnstable County",
    "state": "MA",
    "city": "Yarmouth Port"
  },
  {
    "value": "02702",
    "label": "Assonet - MA 02702",
    "county": "Bristol County",
    "state": "MA",
    "city": "Assonet"
  },
  {
    "value": "02703",
    "label": "Attleboro - MA 02703",
    "county": "Bristol County",
    "state": "MA",
    "city": "Attleboro"
  },
  {
    "value": "02715",
    "label": "Dighton - MA 02715",
    "county": "Bristol County",
    "state": "MA",
    "city": "Dighton"
  },
  {
    "value": "02717",
    "label": "East Freetown - MA 02717",
    "county": "Bristol County",
    "state": "MA",
    "city": "East Freetown"
  },
  {
    "value": "02718",
    "label": "East Taunton - MA 02718",
    "county": "Bristol County",
    "state": "MA",
    "city": "East Taunton"
  },
  {
    "value": "02719",
    "label": "Fairhaven - MA 02719",
    "county": "Bristol County",
    "state": "MA",
    "city": "Fairhaven"
  },
  {
    "value": "02720",
    "label": "Fall River - MA 02720",
    "county": "Bristol County",
    "state": "MA",
    "city": "Fall River"
  },
  {
    "value": "02721",
    "label": "Fall River - MA 02721",
    "county": "Bristol County",
    "state": "MA",
    "city": "Fall River"
  },
  {
    "value": "02723",
    "label": "Fall River - MA 02723",
    "county": "Bristol County",
    "state": "MA",
    "city": "Fall River"
  },
  {
    "value": "02724",
    "label": "Fall River - MA 02724",
    "county": "Bristol County",
    "state": "MA",
    "city": "Fall River"
  },
  {
    "value": "02725",
    "label": "Somerset - MA 02725",
    "county": "Bristol County",
    "state": "MA",
    "city": "Somerset"
  },
  {
    "value": "02726",
    "label": "Somerset - MA 02726",
    "county": "Bristol County",
    "state": "MA",
    "city": "Somerset"
  },
  {
    "value": "02738",
    "label": "Marion - MA 02738",
    "county": "Plymouth County",
    "state": "MA",
    "city": "Marion"
  },
  {
    "value": "02739",
    "label": "Mattapoisett - MA 02739",
    "county": "Plymouth County",
    "state": "MA",
    "city": "Mattapoisett"
  },
  {
    "value": "02740",
    "label": "New Bedford - MA 02740",
    "county": "Bristol County",
    "state": "MA",
    "city": "New Bedford"
  },
  {
    "value": "02743",
    "label": "Acushnet - MA 02743",
    "county": "Bristol County",
    "state": "MA",
    "city": "Acushnet"
  },
  {
    "value": "02744",
    "label": "New Bedford - MA 02744",
    "county": "Bristol County",
    "state": "MA",
    "city": "New Bedford"
  },
  {
    "value": "02745",
    "label": "New Bedford - MA 02745",
    "county": "Bristol County",
    "state": "MA",
    "city": "New Bedford"
  },
  {
    "value": "02746",
    "label": "New Bedford - MA 02746",
    "county": "Bristol County",
    "state": "MA",
    "city": "New Bedford"
  },
  {
    "value": "02747",
    "label": "North Dartmouth - MA 02747",
    "county": "Bristol County",
    "state": "MA",
    "city": "North Dartmouth"
  },
  {
    "value": "02748",
    "label": "South Dartmouth - MA 02748",
    "county": "Bristol County",
    "state": "MA",
    "city": "South Dartmouth"
  },
  {
    "value": "02760",
    "label": "North Attleboro - MA 02760",
    "county": "Bristol County",
    "state": "MA",
    "city": "North Attleboro"
  },
  {
    "value": "02762",
    "label": "Plainville - MA 02762",
    "county": "Norfolk County",
    "state": "MA",
    "city": "Plainville"
  },
  {
    "value": "02763",
    "label": "Attleboro Falls - MA 02763",
    "county": "Bristol County",
    "state": "MA",
    "city": "Attleboro Falls"
  },
  {
    "value": "02764",
    "label": "North Dighton - MA 02764",
    "county": "Bristol County",
    "state": "MA",
    "city": "North Dighton"
  },
  {
    "value": "02766",
    "label": "Norton - MA 02766",
    "county": "Bristol County",
    "state": "MA",
    "city": "Norton"
  },
  {
    "value": "02767",
    "label": "Raynham - MA 02767",
    "county": "Bristol County",
    "state": "MA",
    "city": "Raynham"
  },
  {
    "value": "02769",
    "label": "Rehoboth - MA 02769",
    "county": "Bristol County",
    "state": "MA",
    "city": "Rehoboth"
  },
  {
    "value": "02770",
    "label": "Rochester - MA 02770",
    "county": "Plymouth County",
    "state": "MA",
    "city": "Rochester"
  },
  {
    "value": "02771",
    "label": "Seekonk - MA 02771",
    "county": "Bristol County",
    "state": "MA",
    "city": "Seekonk"
  },
  {
    "value": "02777",
    "label": "Swansea - MA 02777",
    "county": "Bristol County",
    "state": "MA",
    "city": "Swansea"
  },
  {
    "value": "02779",
    "label": "Berkley - MA 02779",
    "county": "Bristol County",
    "state": "MA",
    "city": "Berkley"
  },
  {
    "value": "02780",
    "label": "Taunton - MA 02780",
    "county": "Bristol County",
    "state": "MA",
    "city": "Taunton"
  },
  {
    "value": "02790",
    "label": "Westport - MA 02790",
    "county": "Bristol County",
    "state": "MA",
    "city": "Westport"
  },
  {
    "value": "48001",
    "label": "Algonac - MI 48001",
    "county": "St. Clair County",
    "state": "MI",
    "city": "Algonac"
  },
  {
    "value": "48002",
    "label": "Allenton - MI 48002",
    "county": "St. Clair County",
    "state": "MI",
    "city": "Allenton"
  },
  {
    "value": "48003",
    "label": "Almont - MI 48003",
    "county": "Lapeer County",
    "state": "MI",
    "city": "Almont"
  },
  {
    "value": "48005",
    "label": "Armada - MI 48005",
    "county": "Macomb County",
    "state": "MI",
    "city": "Armada"
  },
  {
    "value": "48006",
    "label": "Avoca - MI 48006",
    "county": "St. Clair County",
    "state": "MI",
    "city": "Avoca"
  },
  {
    "value": "48009",
    "label": "Birmingham - MI 48009",
    "county": "Oakland County",
    "state": "MI",
    "city": "Birmingham"
  },
  {
    "value": "48014",
    "label": "Capac - MI 48014",
    "county": "St. Clair County",
    "state": "MI",
    "city": "Capac"
  },
  {
    "value": "48015",
    "label": "Center Line - MI 48015",
    "county": "Macomb County",
    "state": "MI",
    "city": "Center Line"
  },
  {
    "value": "48017",
    "label": "Clawson - MI 48017",
    "county": "Oakland County",
    "state": "MI",
    "city": "Clawson"
  },
  {
    "value": "48021",
    "label": "Eastpointe - MI 48021",
    "county": "Macomb County",
    "state": "MI",
    "city": "Eastpointe"
  },
  {
    "value": "48022",
    "label": "Emmett - MI 48022",
    "county": "St. Clair County",
    "state": "MI",
    "city": "Emmett"
  },
  {
    "value": "48023",
    "label": "Fair Haven - MI 48023",
    "county": "St. Clair County",
    "state": "MI",
    "city": "Fair Haven"
  },
  {
    "value": "48025",
    "label": "Franklin - MI 48025",
    "county": "Oakland County",
    "state": "MI",
    "city": "Franklin"
  },
  {
    "value": "48026",
    "label": "Fraser - MI 48026",
    "county": "Macomb County",
    "state": "MI",
    "city": "Fraser"
  },
  {
    "value": "48027",
    "label": "Goodells - MI 48027",
    "county": "St. Clair County",
    "state": "MI",
    "city": "Goodells"
  },
  {
    "value": "48028",
    "label": "Harsens Island - MI 48028",
    "county": "St. Clair County",
    "state": "MI",
    "city": "Harsens Island"
  },
  {
    "value": "48030",
    "label": "Hazel Park - MI 48030",
    "county": "Oakland County",
    "state": "MI",
    "city": "Hazel Park"
  },
  {
    "value": "48032",
    "label": "Jeddo - MI 48032",
    "county": "St. Clair County",
    "state": "MI",
    "city": "Jeddo"
  },
  {
    "value": "48033",
    "label": "Southfield - MI 48033",
    "county": "Oakland County",
    "state": "MI",
    "city": "Southfield"
  },
  {
    "value": "48034",
    "label": "Southfield - MI 48034",
    "county": "Oakland County",
    "state": "MI",
    "city": "Southfield"
  },
  {
    "value": "48035",
    "label": "Clinton Township - MI 48035",
    "county": "Macomb County",
    "state": "MI",
    "city": "Clinton Township"
  },
  {
    "value": "48036",
    "label": "Clinton Township - MI 48036",
    "county": "Macomb County",
    "state": "MI",
    "city": "Clinton Township"
  },
  {
    "value": "48038",
    "label": "Clinton Township - MI 48038",
    "county": "Macomb County",
    "state": "MI",
    "city": "Clinton Township"
  },
  {
    "value": "48039",
    "label": "Marine City - MI 48039",
    "county": "St. Clair County",
    "state": "MI",
    "city": "Marine City"
  },
  {
    "value": "48040",
    "label": "Marysville - MI 48040",
    "county": "St. Clair County",
    "state": "MI",
    "city": "Marysville"
  },
  {
    "value": "48041",
    "label": "Memphis - MI 48041",
    "county": "St. Clair County",
    "state": "MI",
    "city": "Memphis"
  },
  {
    "value": "48042",
    "label": "Macomb - MI 48042",
    "county": "Macomb County",
    "state": "MI",
    "city": "Macomb"
  },
  {
    "value": "48043",
    "label": "Mount Clemens - MI 48043",
    "county": "Macomb County",
    "state": "MI",
    "city": "Mount Clemens"
  },
  {
    "value": "48044",
    "label": "Macomb - MI 48044",
    "county": "Macomb County",
    "state": "MI",
    "city": "Macomb"
  },
  {
    "value": "48045",
    "label": "Harrison Township - MI 48045",
    "county": "Macomb County",
    "state": "MI",
    "city": "Harrison Township"
  },
  {
    "value": "48047",
    "label": "New Baltimore - MI 48047",
    "county": "Macomb County",
    "state": "MI",
    "city": "New Baltimore"
  },
  {
    "value": "48048",
    "label": "New Haven - MI 48048",
    "county": "Macomb County",
    "state": "MI",
    "city": "New Haven"
  },
  {
    "value": "48049",
    "label": "North Street - MI 48049",
    "county": "St. Clair County",
    "state": "MI",
    "city": "North Street"
  },
  {
    "value": "48050",
    "label": "New Haven - MI 48050",
    "county": "Macomb County",
    "state": "MI",
    "city": "New Haven"
  },
  {
    "value": "48051",
    "label": "New Baltimore - MI 48051",
    "county": "Macomb County",
    "state": "MI",
    "city": "New Baltimore"
  },
  {
    "value": "48054",
    "label": "East China - MI 48054",
    "county": "St. Clair County",
    "state": "MI",
    "city": "East China"
  },
  {
    "value": "48059",
    "label": "Fort Gratiot - MI 48059",
    "county": "St. Clair County",
    "state": "MI",
    "city": "Fort Gratiot"
  },
  {
    "value": "48060",
    "label": "Port Huron - MI 48060",
    "county": "St. Clair County",
    "state": "MI",
    "city": "Port Huron"
  },
  {
    "value": "48062",
    "label": "Richmond - MI 48062",
    "county": "Macomb County",
    "state": "MI",
    "city": "Richmond"
  },
  {
    "value": "48063",
    "label": "Columbus - MI 48063",
    "county": "St. Clair County",
    "state": "MI",
    "city": "Columbus"
  },
  {
    "value": "48064",
    "label": "Casco - MI 48064",
    "county": "St. Clair County",
    "state": "MI",
    "city": "Casco"
  },
  {
    "value": "48065",
    "label": "Romeo - MI 48065",
    "county": "Macomb County",
    "state": "MI",
    "city": "Romeo"
  },
  {
    "value": "48066",
    "label": "Roseville - MI 48066",
    "county": "Macomb County",
    "state": "MI",
    "city": "Roseville"
  },
  {
    "value": "48067",
    "label": "Royal Oak - MI 48067",
    "county": "Oakland County",
    "state": "MI",
    "city": "Royal Oak"
  },
  {
    "value": "48069",
    "label": "Pleasant Ridge - MI 48069",
    "county": "Oakland County",
    "state": "MI",
    "city": "Pleasant Ridge"
  },
  {
    "value": "48070",
    "label": "Huntington Woods - MI 48070",
    "county": "Oakland County",
    "state": "MI",
    "city": "Huntington Woods"
  },
  {
    "value": "48071",
    "label": "Madison Heights - MI 48071",
    "county": "Oakland County",
    "state": "MI",
    "city": "Madison Heights"
  },
  {
    "value": "48072",
    "label": "Berkley - MI 48072",
    "county": "Oakland County",
    "state": "MI",
    "city": "Berkley"
  },
  {
    "value": "48073",
    "label": "Royal Oak - MI 48073",
    "county": "Oakland County",
    "state": "MI",
    "city": "Royal Oak"
  },
  {
    "value": "48074",
    "label": "Smiths Creek - MI 48074",
    "county": "St. Clair County",
    "state": "MI",
    "city": "Smiths Creek"
  },
  {
    "value": "48075",
    "label": "Southfield - MI 48075",
    "county": "Oakland County",
    "state": "MI",
    "city": "Southfield"
  },
  {
    "value": "48076",
    "label": "Southfield - MI 48076",
    "county": "Oakland County",
    "state": "MI",
    "city": "Southfield"
  },
  {
    "value": "48079",
    "label": "Saint Clair - MI 48079",
    "county": "St. Clair County",
    "state": "MI",
    "city": "Saint Clair"
  },
  {
    "value": "48080",
    "label": "Saint Clair Shores - MI 48080",
    "county": "Macomb County",
    "state": "MI",
    "city": "Saint Clair Shores"
  },
  {
    "value": "48081",
    "label": "Saint Clair Shores - MI 48081",
    "county": "Macomb County",
    "state": "MI",
    "city": "Saint Clair Shores"
  },
  {
    "value": "48082",
    "label": "Saint Clair Shores - MI 48082",
    "county": "Macomb County",
    "state": "MI",
    "city": "Saint Clair Shores"
  },
  {
    "value": "48083",
    "label": "Troy - MI 48083",
    "county": "Oakland County",
    "state": "MI",
    "city": "Troy"
  },
  {
    "value": "48084",
    "label": "Troy - MI 48084",
    "county": "Oakland County",
    "state": "MI",
    "city": "Troy"
  },
  {
    "value": "48085",
    "label": "Troy - MI 48085",
    "county": "Oakland County",
    "state": "MI",
    "city": "Troy"
  },
  {
    "value": "48088",
    "label": "Warren - MI 48088",
    "county": "Macomb County",
    "state": "MI",
    "city": "Warren"
  },
  {
    "value": "48089",
    "label": "Warren - MI 48089",
    "county": "Macomb County",
    "state": "MI",
    "city": "Warren"
  },
  {
    "value": "48091",
    "label": "Warren - MI 48091",
    "county": "Macomb County",
    "state": "MI",
    "city": "Warren"
  },
  {
    "value": "48092",
    "label": "Warren - MI 48092",
    "county": "Macomb County",
    "state": "MI",
    "city": "Warren"
  },
  {
    "value": "48093",
    "label": "Warren - MI 48093",
    "county": "Macomb County",
    "state": "MI",
    "city": "Warren"
  },
  {
    "value": "48094",
    "label": "Washington - MI 48094",
    "county": "Macomb County",
    "state": "MI",
    "city": "Washington"
  },
  {
    "value": "48095",
    "label": "Washington - MI 48095",
    "county": "Macomb County",
    "state": "MI",
    "city": "Washington"
  },
  {
    "value": "48096",
    "label": "Ray - MI 48096",
    "county": "Macomb County",
    "state": "MI",
    "city": "Ray"
  },
  {
    "value": "48097",
    "label": "Yale - MI 48097",
    "county": "St. Clair County",
    "state": "MI",
    "city": "Yale"
  },
  {
    "value": "48098",
    "label": "Troy - MI 48098",
    "county": "Oakland County",
    "state": "MI",
    "city": "Troy"
  },
  {
    "value": "48101",
    "label": "Allen Park - MI 48101",
    "county": "Wayne County",
    "state": "MI",
    "city": "Allen Park"
  },
  {
    "value": "48103",
    "label": "Ann Arbor - MI 48103",
    "county": "Washtenaw County",
    "state": "MI",
    "city": "Ann Arbor"
  },
  {
    "value": "48104",
    "label": "Ann Arbor - MI 48104",
    "county": "Washtenaw County",
    "state": "MI",
    "city": "Ann Arbor"
  },
  {
    "value": "48105",
    "label": "Ann Arbor - MI 48105",
    "county": "Washtenaw County",
    "state": "MI",
    "city": "Ann Arbor"
  },
  {
    "value": "48108",
    "label": "Ann Arbor - MI 48108",
    "county": "Washtenaw County",
    "state": "MI",
    "city": "Ann Arbor"
  },
  {
    "value": "48109",
    "label": "Ann Arbor - MI 48109",
    "county": "Washtenaw County",
    "state": "MI",
    "city": "Ann Arbor"
  },
  {
    "value": "48111",
    "label": "Belleville - MI 48111",
    "county": "Wayne County",
    "state": "MI",
    "city": "Belleville"
  },
  {
    "value": "48114",
    "label": "Brighton - MI 48114",
    "county": "Livingston County",
    "state": "MI",
    "city": "Brighton"
  },
  {
    "value": "48116",
    "label": "Brighton - MI 48116",
    "county": "Livingston County",
    "state": "MI",
    "city": "Brighton"
  },
  {
    "value": "48117",
    "label": "Carleton - MI 48117",
    "county": "Monroe County",
    "state": "MI",
    "city": "Carleton"
  },
  {
    "value": "48118",
    "label": "Chelsea - MI 48118",
    "county": "Washtenaw County",
    "state": "MI",
    "city": "Chelsea"
  },
  {
    "value": "48120",
    "label": "Dearborn - MI 48120",
    "county": "Wayne County",
    "state": "MI",
    "city": "Dearborn"
  },
  {
    "value": "48122",
    "label": "Melvindale - MI 48122",
    "county": "Wayne County",
    "state": "MI",
    "city": "Melvindale"
  },
  {
    "value": "48124",
    "label": "Dearborn - MI 48124",
    "county": "Wayne County",
    "state": "MI",
    "city": "Dearborn"
  },
  {
    "value": "48125",
    "label": "Dearborn Heights - MI 48125",
    "county": "Wayne County",
    "state": "MI",
    "city": "Dearborn Heights"
  },
  {
    "value": "48126",
    "label": "Dearborn - MI 48126",
    "county": "Wayne County",
    "state": "MI",
    "city": "Dearborn"
  },
  {
    "value": "48127",
    "label": "Dearborn Heights - MI 48127",
    "county": "Wayne County",
    "state": "MI",
    "city": "Dearborn Heights"
  },
  {
    "value": "48128",
    "label": "Dearborn - MI 48128",
    "county": "Wayne County",
    "state": "MI",
    "city": "Dearborn"
  },
  {
    "value": "48130",
    "label": "Dexter - MI 48130",
    "county": "Washtenaw County",
    "state": "MI",
    "city": "Dexter"
  },
  {
    "value": "48131",
    "label": "Dundee - MI 48131",
    "county": "Monroe County",
    "state": "MI",
    "city": "Dundee"
  },
  {
    "value": "48133",
    "label": "Erie - MI 48133",
    "county": "Monroe County",
    "state": "MI",
    "city": "Erie"
  },
  {
    "value": "48134",
    "label": "Flat Rock - MI 48134",
    "county": "Wayne County",
    "state": "MI",
    "city": "Flat Rock"
  },
  {
    "value": "48135",
    "label": "Garden City - MI 48135",
    "county": "Wayne County",
    "state": "MI",
    "city": "Garden City"
  },
  {
    "value": "48137",
    "label": "Gregory - MI 48137",
    "county": "Livingston County",
    "state": "MI",
    "city": "Gregory"
  },
  {
    "value": "48138",
    "label": "Grosse Ile - MI 48138",
    "county": "Wayne County",
    "state": "MI",
    "city": "Grosse Ile"
  },
  {
    "value": "48140",
    "label": "Ida - MI 48140",
    "county": "Monroe County",
    "state": "MI",
    "city": "Ida"
  },
  {
    "value": "48141",
    "label": "Inkster - MI 48141",
    "county": "Wayne County",
    "state": "MI",
    "city": "Inkster"
  },
  {
    "value": "48144",
    "label": "Lambertville - MI 48144",
    "county": "Monroe County",
    "state": "MI",
    "city": "Lambertville"
  },
  {
    "value": "48145",
    "label": "La Salle - MI 48145",
    "county": "Monroe County",
    "state": "MI",
    "city": "La Salle"
  },
  {
    "value": "48146",
    "label": "Lincoln Park - MI 48146",
    "county": "Wayne County",
    "state": "MI",
    "city": "Lincoln Park"
  },
  {
    "value": "48150",
    "label": "Livonia - MI 48150",
    "county": "Wayne County",
    "state": "MI",
    "city": "Livonia"
  },
  {
    "value": "48152",
    "label": "Livonia - MI 48152",
    "county": "Wayne County",
    "state": "MI",
    "city": "Livonia"
  },
  {
    "value": "48154",
    "label": "Livonia - MI 48154",
    "county": "Wayne County",
    "state": "MI",
    "city": "Livonia"
  },
  {
    "value": "48157",
    "label": "Luna Pier - MI 48157",
    "county": "Monroe County",
    "state": "MI",
    "city": "Luna Pier"
  },
  {
    "value": "48158",
    "label": "Manchester - MI 48158",
    "county": "Washtenaw County",
    "state": "MI",
    "city": "Manchester"
  },
  {
    "value": "48159",
    "label": "Maybee - MI 48159",
    "county": "Monroe County",
    "state": "MI",
    "city": "Maybee"
  },
  {
    "value": "48160",
    "label": "Milan - MI 48160",
    "county": "Washtenaw County",
    "state": "MI",
    "city": "Milan"
  },
  {
    "value": "48161",
    "label": "Monroe - MI 48161",
    "county": "Monroe County",
    "state": "MI",
    "city": "Monroe"
  },
  {
    "value": "48162",
    "label": "Monroe - MI 48162",
    "county": "Monroe County",
    "state": "MI",
    "city": "Monroe"
  },
  {
    "value": "48164",
    "label": "New Boston - MI 48164",
    "county": "Wayne County",
    "state": "MI",
    "city": "New Boston"
  },
  {
    "value": "48165",
    "label": "New Hudson - MI 48165",
    "county": "Oakland County",
    "state": "MI",
    "city": "New Hudson"
  },
  {
    "value": "48166",
    "label": "Newport - MI 48166",
    "county": "Monroe County",
    "state": "MI",
    "city": "Newport"
  },
  {
    "value": "48167",
    "label": "Northville - MI 48167",
    "county": "Oakland County",
    "state": "MI",
    "city": "Northville"
  },
  {
    "value": "48168",
    "label": "Northville - MI 48168",
    "county": "Wayne County",
    "state": "MI",
    "city": "Northville"
  },
  {
    "value": "48169",
    "label": "Pinckney - MI 48169",
    "county": "Livingston County",
    "state": "MI",
    "city": "Pinckney"
  },
  {
    "value": "48170",
    "label": "Plymouth - MI 48170",
    "county": "Wayne County",
    "state": "MI",
    "city": "Plymouth"
  },
  {
    "value": "48173",
    "label": "Rockwood - MI 48173",
    "county": "Wayne County",
    "state": "MI",
    "city": "Rockwood"
  },
  {
    "value": "48174",
    "label": "Romulus - MI 48174",
    "county": "Wayne County",
    "state": "MI",
    "city": "Romulus"
  },
  {
    "value": "48176",
    "label": "Saline - MI 48176",
    "county": "Washtenaw County",
    "state": "MI",
    "city": "Saline"
  },
  {
    "value": "48178",
    "label": "South Lyon - MI 48178",
    "county": "Oakland County",
    "state": "MI",
    "city": "South Lyon"
  },
  {
    "value": "48179",
    "label": "South Rockwood - MI 48179",
    "county": "Monroe County",
    "state": "MI",
    "city": "South Rockwood"
  },
  {
    "value": "48180",
    "label": "Taylor - MI 48180",
    "county": "Wayne County",
    "state": "MI",
    "city": "Taylor"
  },
  {
    "value": "48182",
    "label": "Temperance - MI 48182",
    "county": "Monroe County",
    "state": "MI",
    "city": "Temperance"
  },
  {
    "value": "48183",
    "label": "Trenton - MI 48183",
    "county": "Wayne County",
    "state": "MI",
    "city": "Trenton"
  },
  {
    "value": "48184",
    "label": "Wayne - MI 48184",
    "county": "Wayne County",
    "state": "MI",
    "city": "Wayne"
  },
  {
    "value": "48185",
    "label": "Westland - MI 48185",
    "county": "Wayne County",
    "state": "MI",
    "city": "Westland"
  },
  {
    "value": "48186",
    "label": "Westland - MI 48186",
    "county": "Wayne County",
    "state": "MI",
    "city": "Westland"
  },
  {
    "value": "48187",
    "label": "Canton - MI 48187",
    "county": "Wayne County",
    "state": "MI",
    "city": "Canton"
  },
  {
    "value": "48188",
    "label": "Canton - MI 48188",
    "county": "Wayne County",
    "state": "MI",
    "city": "Canton"
  },
  {
    "value": "48189",
    "label": "Whitmore Lake - MI 48189",
    "county": "Washtenaw County",
    "state": "MI",
    "city": "Whitmore Lake"
  },
  {
    "value": "48191",
    "label": "Willis - MI 48191",
    "county": "Washtenaw County",
    "state": "MI",
    "city": "Willis"
  },
  {
    "value": "48192",
    "label": "Wyandotte - MI 48192",
    "county": "Wayne County",
    "state": "MI",
    "city": "Wyandotte"
  },
  {
    "value": "48193",
    "label": "Riverview - MI 48193",
    "county": "Wayne County",
    "state": "MI",
    "city": "Riverview"
  },
  {
    "value": "48195",
    "label": "Southgate - MI 48195",
    "county": "Wayne County",
    "state": "MI",
    "city": "Southgate"
  },
  {
    "value": "48197",
    "label": "Ypsilanti - MI 48197",
    "county": "Washtenaw County",
    "state": "MI",
    "city": "Ypsilanti"
  },
  {
    "value": "48198",
    "label": "Ypsilanti - MI 48198",
    "county": "Washtenaw County",
    "state": "MI",
    "city": "Ypsilanti"
  },
  {
    "value": "48201",
    "label": "Detroit - MI 48201",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48202",
    "label": "Detroit - MI 48202",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48203",
    "label": "Highland Park - MI 48203",
    "county": "Wayne County",
    "state": "MI",
    "city": "Highland Park"
  },
  {
    "value": "48204",
    "label": "Detroit - MI 48204",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48205",
    "label": "Detroit - MI 48205",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48206",
    "label": "Detroit - MI 48206",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48207",
    "label": "Detroit - MI 48207",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48208",
    "label": "Detroit - MI 48208",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48209",
    "label": "Detroit - MI 48209",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48210",
    "label": "Detroit - MI 48210",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48211",
    "label": "Detroit - MI 48211",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48212",
    "label": "Hamtramck - MI 48212",
    "county": "Wayne County",
    "state": "MI",
    "city": "Hamtramck"
  },
  {
    "value": "48213",
    "label": "Detroit - MI 48213",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48214",
    "label": "Detroit - MI 48214",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48215",
    "label": "Detroit - MI 48215",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48216",
    "label": "Detroit - MI 48216",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48217",
    "label": "Detroit - MI 48217",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48218",
    "label": "River Rouge - MI 48218",
    "county": "Wayne County",
    "state": "MI",
    "city": "River Rouge"
  },
  {
    "value": "48219",
    "label": "Detroit - MI 48219",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48220",
    "label": "Ferndale - MI 48220",
    "county": "Oakland County",
    "state": "MI",
    "city": "Ferndale"
  },
  {
    "value": "48221",
    "label": "Detroit - MI 48221",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48223",
    "label": "Detroit - MI 48223",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48224",
    "label": "Detroit - MI 48224",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48225",
    "label": "Harper Woods - MI 48225",
    "county": "Wayne County",
    "state": "MI",
    "city": "Harper Woods"
  },
  {
    "value": "48226",
    "label": "Detroit - MI 48226",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48227",
    "label": "Detroit - MI 48227",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48228",
    "label": "Detroit - MI 48228",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48229",
    "label": "Ecorse - MI 48229",
    "county": "Wayne County",
    "state": "MI",
    "city": "Ecorse"
  },
  {
    "value": "48230",
    "label": "Grosse Pointe - MI 48230",
    "county": "Wayne County",
    "state": "MI",
    "city": "Grosse Pointe"
  },
  {
    "value": "48233",
    "label": "Detroit - MI 48233",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48234",
    "label": "Detroit - MI 48234",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48235",
    "label": "Detroit - MI 48235",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48236",
    "label": "Grosse Pointe - MI 48236",
    "county": "Wayne County",
    "state": "MI",
    "city": "Grosse Pointe"
  },
  {
    "value": "48237",
    "label": "Oak Park - MI 48237",
    "county": "Oakland County",
    "state": "MI",
    "city": "Oak Park"
  },
  {
    "value": "48238",
    "label": "Detroit - MI 48238",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48239",
    "label": "Redford - MI 48239",
    "county": "Wayne County",
    "state": "MI",
    "city": "Redford"
  },
  {
    "value": "48240",
    "label": "Redford - MI 48240",
    "county": "Wayne County",
    "state": "MI",
    "city": "Redford"
  },
  {
    "value": "48242",
    "label": "Detroit - MI 48242",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48243",
    "label": "Detroit - MI 48243",
    "county": "Wayne County",
    "state": "MI",
    "city": "Detroit"
  },
  {
    "value": "48301",
    "label": "Bloomfield Hills - MI 48301",
    "county": "Oakland County",
    "state": "MI",
    "city": "Bloomfield Hills"
  },
  {
    "value": "48302",
    "label": "Bloomfield Hills - MI 48302",
    "county": "Oakland County",
    "state": "MI",
    "city": "Bloomfield Hills"
  },
  {
    "value": "48304",
    "label": "Bloomfield Hills - MI 48304",
    "county": "Oakland County",
    "state": "MI",
    "city": "Bloomfield Hills"
  },
  {
    "value": "48306",
    "label": "Rochester - MI 48306",
    "county": "Oakland County",
    "state": "MI",
    "city": "Rochester"
  },
  {
    "value": "48307",
    "label": "Rochester - MI 48307",
    "county": "Oakland County",
    "state": "MI",
    "city": "Rochester"
  },
  {
    "value": "48309",
    "label": "Rochester - MI 48309",
    "county": "Oakland County",
    "state": "MI",
    "city": "Rochester"
  },
  {
    "value": "48310",
    "label": "Sterling Heights - MI 48310",
    "county": "Macomb County",
    "state": "MI",
    "city": "Sterling Heights"
  },
  {
    "value": "48312",
    "label": "Sterling Heights - MI 48312",
    "county": "Macomb County",
    "state": "MI",
    "city": "Sterling Heights"
  },
  {
    "value": "48313",
    "label": "Sterling Heights - MI 48313",
    "county": "Macomb County",
    "state": "MI",
    "city": "Sterling Heights"
  },
  {
    "value": "48314",
    "label": "Sterling Heights - MI 48314",
    "county": "Macomb County",
    "state": "MI",
    "city": "Sterling Heights"
  },
  {
    "value": "48315",
    "label": "Utica - MI 48315",
    "county": "Macomb County",
    "state": "MI",
    "city": "Utica"
  },
  {
    "value": "48316",
    "label": "Utica - MI 48316",
    "county": "Macomb County",
    "state": "MI",
    "city": "Utica"
  },
  {
    "value": "48317",
    "label": "Utica - MI 48317",
    "county": "Macomb County",
    "state": "MI",
    "city": "Utica"
  },
  {
    "value": "48320",
    "label": "Keego Harbor - MI 48320",
    "county": "Oakland County",
    "state": "MI",
    "city": "Keego Harbor"
  },
  {
    "value": "48322",
    "label": "West Bloomfield - MI 48322",
    "county": "Oakland County",
    "state": "MI",
    "city": "West Bloomfield"
  },
  {
    "value": "48323",
    "label": "West Bloomfield - MI 48323",
    "county": "Oakland County",
    "state": "MI",
    "city": "West Bloomfield"
  },
  {
    "value": "48324",
    "label": "West Bloomfield - MI 48324",
    "county": "Oakland County",
    "state": "MI",
    "city": "West Bloomfield"
  },
  {
    "value": "48326",
    "label": "Auburn Hills - MI 48326",
    "county": "Oakland County",
    "state": "MI",
    "city": "Auburn Hills"
  },
  {
    "value": "48327",
    "label": "Waterford - MI 48327",
    "county": "Oakland County",
    "state": "MI",
    "city": "Waterford"
  },
  {
    "value": "48328",
    "label": "Waterford - MI 48328",
    "county": "Oakland County",
    "state": "MI",
    "city": "Waterford"
  },
  {
    "value": "48329",
    "label": "Waterford - MI 48329",
    "county": "Oakland County",
    "state": "MI",
    "city": "Waterford"
  },
  {
    "value": "48331",
    "label": "Farmington - MI 48331",
    "county": "Oakland County",
    "state": "MI",
    "city": "Farmington"
  },
  {
    "value": "48334",
    "label": "Farmington - MI 48334",
    "county": "Oakland County",
    "state": "MI",
    "city": "Farmington"
  },
  {
    "value": "48335",
    "label": "Farmington - MI 48335",
    "county": "Oakland County",
    "state": "MI",
    "city": "Farmington"
  },
  {
    "value": "48336",
    "label": "Farmington - MI 48336",
    "county": "Oakland County",
    "state": "MI",
    "city": "Farmington"
  },
  {
    "value": "48340",
    "label": "Pontiac - MI 48340",
    "county": "Oakland County",
    "state": "MI",
    "city": "Pontiac"
  },
  {
    "value": "48341",
    "label": "Pontiac - MI 48341",
    "county": "Oakland County",
    "state": "MI",
    "city": "Pontiac"
  },
  {
    "value": "48342",
    "label": "Pontiac - MI 48342",
    "county": "Oakland County",
    "state": "MI",
    "city": "Pontiac"
  },
  {
    "value": "48346",
    "label": "Clarkston - MI 48346",
    "county": "Oakland County",
    "state": "MI",
    "city": "Clarkston"
  },
  {
    "value": "48348",
    "label": "Clarkston - MI 48348",
    "county": "Oakland County",
    "state": "MI",
    "city": "Clarkston"
  },
  {
    "value": "48350",
    "label": "Davisburg - MI 48350",
    "county": "Oakland County",
    "state": "MI",
    "city": "Davisburg"
  },
  {
    "value": "48353",
    "label": "Hartland - MI 48353",
    "county": "Livingston County",
    "state": "MI",
    "city": "Hartland"
  },
  {
    "value": "48356",
    "label": "Highland - MI 48356",
    "county": "Oakland County",
    "state": "MI",
    "city": "Highland"
  },
  {
    "value": "48357",
    "label": "Highland - MI 48357",
    "county": "Oakland County",
    "state": "MI",
    "city": "Highland"
  },
  {
    "value": "48359",
    "label": "Lake Orion - MI 48359",
    "county": "Oakland County",
    "state": "MI",
    "city": "Lake Orion"
  },
  {
    "value": "48360",
    "label": "Lake Orion - MI 48360",
    "county": "Oakland County",
    "state": "MI",
    "city": "Lake Orion"
  },
  {
    "value": "48362",
    "label": "Lake Orion - MI 48362",
    "county": "Oakland County",
    "state": "MI",
    "city": "Lake Orion"
  },
  {
    "value": "48363",
    "label": "Oakland - MI 48363",
    "county": "Oakland County",
    "state": "MI",
    "city": "Oakland"
  },
  {
    "value": "48367",
    "label": "Leonard - MI 48367",
    "county": "Oakland County",
    "state": "MI",
    "city": "Leonard"
  },
  {
    "value": "48370",
    "label": "Oxford - MI 48370",
    "county": "Oakland County",
    "state": "MI",
    "city": "Oxford"
  },
  {
    "value": "48371",
    "label": "Oxford - MI 48371",
    "county": "Oakland County",
    "state": "MI",
    "city": "Oxford"
  },
  {
    "value": "48374",
    "label": "Novi - MI 48374",
    "county": "Oakland County",
    "state": "MI",
    "city": "Novi"
  },
  {
    "value": "48375",
    "label": "Novi - MI 48375",
    "county": "Oakland County",
    "state": "MI",
    "city": "Novi"
  },
  {
    "value": "48377",
    "label": "Novi - MI 48377",
    "county": "Oakland County",
    "state": "MI",
    "city": "Novi"
  },
  {
    "value": "48380",
    "label": "Milford - MI 48380",
    "county": "Oakland County",
    "state": "MI",
    "city": "Milford"
  },
  {
    "value": "48381",
    "label": "Milford - MI 48381",
    "county": "Oakland County",
    "state": "MI",
    "city": "Milford"
  },
  {
    "value": "48382",
    "label": "Commerce Township - MI 48382",
    "county": "Oakland County",
    "state": "MI",
    "city": "Commerce Township"
  },
  {
    "value": "48383",
    "label": "White Lake - MI 48383",
    "county": "Oakland County",
    "state": "MI",
    "city": "White Lake"
  },
  {
    "value": "48386",
    "label": "White Lake - MI 48386",
    "county": "Oakland County",
    "state": "MI",
    "city": "White Lake"
  },
  {
    "value": "48390",
    "label": "Walled Lake - MI 48390",
    "county": "Oakland County",
    "state": "MI",
    "city": "Walled Lake"
  },
  {
    "value": "48393",
    "label": "Wixom - MI 48393",
    "county": "Oakland County",
    "state": "MI",
    "city": "Wixom"
  },
  {
    "value": "48401",
    "label": "Applegate - MI 48401",
    "county": "Sanilac County",
    "state": "MI",
    "city": "Applegate"
  },
  {
    "value": "48412",
    "label": "Attica - MI 48412",
    "county": "Lapeer County",
    "state": "MI",
    "city": "Attica"
  },
  {
    "value": "48413",
    "label": "Bad Axe - MI 48413",
    "county": "Huron County",
    "state": "MI",
    "city": "Bad Axe"
  },
  {
    "value": "48414",
    "label": "Bancroft - MI 48414",
    "county": "Shiawassee County",
    "state": "MI",
    "city": "Bancroft"
  },
  {
    "value": "48415",
    "label": "Birch Run - MI 48415",
    "county": "Saginaw County",
    "state": "MI",
    "city": "Birch Run"
  },
  {
    "value": "48416",
    "label": "Brown City - MI 48416",
    "county": "Sanilac County",
    "state": "MI",
    "city": "Brown City"
  },
  {
    "value": "48417",
    "label": "Burt - MI 48417",
    "county": "Saginaw County",
    "state": "MI",
    "city": "Burt"
  },
  {
    "value": "48418",
    "label": "Byron - MI 48418",
    "county": "Shiawassee County",
    "state": "MI",
    "city": "Byron"
  },
  {
    "value": "48419",
    "label": "Carsonville - MI 48419",
    "county": "Sanilac County",
    "state": "MI",
    "city": "Carsonville"
  },
  {
    "value": "48420",
    "label": "Clio - MI 48420",
    "county": "Genesee County",
    "state": "MI",
    "city": "Clio"
  },
  {
    "value": "48421",
    "label": "Columbiaville - MI 48421",
    "county": "Lapeer County",
    "state": "MI",
    "city": "Columbiaville"
  },
  {
    "value": "48422",
    "label": "Croswell - MI 48422",
    "county": "Sanilac County",
    "state": "MI",
    "city": "Croswell"
  },
  {
    "value": "48423",
    "label": "Davison - MI 48423",
    "county": "Genesee County",
    "state": "MI",
    "city": "Davison"
  },
  {
    "value": "48426",
    "label": "Decker - MI 48426",
    "county": "Sanilac County",
    "state": "MI",
    "city": "Decker"
  },
  {
    "value": "48427",
    "label": "Deckerville - MI 48427",
    "county": "Sanilac County",
    "state": "MI",
    "city": "Deckerville"
  },
  {
    "value": "48428",
    "label": "Dryden - MI 48428",
    "county": "Lapeer County",
    "state": "MI",
    "city": "Dryden"
  },
  {
    "value": "48429",
    "label": "Durand - MI 48429",
    "county": "Shiawassee County",
    "state": "MI",
    "city": "Durand"
  },
  {
    "value": "48430",
    "label": "Fenton - MI 48430",
    "county": "Genesee County",
    "state": "MI",
    "city": "Fenton"
  },
  {
    "value": "48432",
    "label": "Filion - MI 48432",
    "county": "Huron County",
    "state": "MI",
    "city": "Filion"
  },
  {
    "value": "48433",
    "label": "Flushing - MI 48433",
    "county": "Genesee County",
    "state": "MI",
    "city": "Flushing"
  },
  {
    "value": "48435",
    "label": "Fostoria - MI 48435",
    "county": "Tuscola County",
    "state": "MI",
    "city": "Fostoria"
  },
  {
    "value": "48436",
    "label": "Gaines - MI 48436",
    "county": "Genesee County",
    "state": "MI",
    "city": "Gaines"
  },
  {
    "value": "48438",
    "label": "Goodrich - MI 48438",
    "county": "Genesee County",
    "state": "MI",
    "city": "Goodrich"
  },
  {
    "value": "48439",
    "label": "Grand Blanc - MI 48439",
    "county": "Genesee County",
    "state": "MI",
    "city": "Grand Blanc"
  },
  {
    "value": "48441",
    "label": "Harbor Beach - MI 48441",
    "county": "Huron County",
    "state": "MI",
    "city": "Harbor Beach"
  },
  {
    "value": "48442",
    "label": "Holly - MI 48442",
    "county": "Oakland County",
    "state": "MI",
    "city": "Holly"
  },
  {
    "value": "48444",
    "label": "Imlay City - MI 48444",
    "county": "Lapeer County",
    "state": "MI",
    "city": "Imlay City"
  },
  {
    "value": "48445",
    "label": "Kinde - MI 48445",
    "county": "Huron County",
    "state": "MI",
    "city": "Kinde"
  },
  {
    "value": "48446",
    "label": "Lapeer - MI 48446",
    "county": "Lapeer County",
    "state": "MI",
    "city": "Lapeer"
  },
  {
    "value": "48449",
    "label": "Lennon - MI 48449",
    "county": "Shiawassee County",
    "state": "MI",
    "city": "Lennon"
  },
  {
    "value": "48450",
    "label": "Lexington - MI 48450",
    "county": "Sanilac County",
    "state": "MI",
    "city": "Lexington"
  },
  {
    "value": "48451",
    "label": "Linden - MI 48451",
    "county": "Genesee County",
    "state": "MI",
    "city": "Linden"
  },
  {
    "value": "48453",
    "label": "Marlette - MI 48453",
    "county": "Sanilac County",
    "state": "MI",
    "city": "Marlette"
  },
  {
    "value": "48454",
    "label": "Melvin - MI 48454",
    "county": "Sanilac County",
    "state": "MI",
    "city": "Melvin"
  },
  {
    "value": "48455",
    "label": "Metamora - MI 48455",
    "county": "Lapeer County",
    "state": "MI",
    "city": "Metamora"
  },
  {
    "value": "48456",
    "label": "Minden City - MI 48456",
    "county": "Sanilac County",
    "state": "MI",
    "city": "Minden City"
  },
  {
    "value": "48457",
    "label": "Montrose - MI 48457",
    "county": "Genesee County",
    "state": "MI",
    "city": "Montrose"
  },
  {
    "value": "48458",
    "label": "Mount Morris - MI 48458",
    "county": "Genesee County",
    "state": "MI",
    "city": "Mount Morris"
  },
  {
    "value": "48460",
    "label": "New Lothrop - MI 48460",
    "county": "Shiawassee County",
    "state": "MI",
    "city": "New Lothrop"
  },
  {
    "value": "48461",
    "label": "North Branch - MI 48461",
    "county": "Lapeer County",
    "state": "MI",
    "city": "North Branch"
  },
  {
    "value": "48462",
    "label": "Ortonville - MI 48462",
    "county": "Oakland County",
    "state": "MI",
    "city": "Ortonville"
  },
  {
    "value": "48463",
    "label": "Otisville - MI 48463",
    "county": "Genesee County",
    "state": "MI",
    "city": "Otisville"
  },
  {
    "value": "48464",
    "label": "Otter Lake - MI 48464",
    "county": "Lapeer County",
    "state": "MI",
    "city": "Otter Lake"
  },
  {
    "value": "48465",
    "label": "Palms - MI 48465",
    "county": "Sanilac County",
    "state": "MI",
    "city": "Palms"
  },
  {
    "value": "48466",
    "label": "Peck - MI 48466",
    "county": "Sanilac County",
    "state": "MI",
    "city": "Peck"
  },
  {
    "value": "48467",
    "label": "Port Austin - MI 48467",
    "county": "Huron County",
    "state": "MI",
    "city": "Port Austin"
  },
  {
    "value": "48468",
    "label": "Port Hope - MI 48468",
    "county": "Huron County",
    "state": "MI",
    "city": "Port Hope"
  },
  {
    "value": "48469",
    "label": "Port Sanilac - MI 48469",
    "county": "Sanilac County",
    "state": "MI",
    "city": "Port Sanilac"
  },
  {
    "value": "48470",
    "label": "Ruth - MI 48470",
    "county": "Huron County",
    "state": "MI",
    "city": "Ruth"
  },
  {
    "value": "48471",
    "label": "Sandusky - MI 48471",
    "county": "Sanilac County",
    "state": "MI",
    "city": "Sandusky"
  },
  {
    "value": "48472",
    "label": "Snover - MI 48472",
    "county": "Sanilac County",
    "state": "MI",
    "city": "Snover"
  },
  {
    "value": "48473",
    "label": "Swartz Creek - MI 48473",
    "county": "Genesee County",
    "state": "MI",
    "city": "Swartz Creek"
  },
  {
    "value": "48475",
    "label": "Ubly - MI 48475",
    "county": "Huron County",
    "state": "MI",
    "city": "Ubly"
  },
  {
    "value": "48502",
    "label": "Flint - MI 48502",
    "county": "Genesee County",
    "state": "MI",
    "city": "Flint"
  },
  {
    "value": "48503",
    "label": "Flint - MI 48503",
    "county": "Genesee County",
    "state": "MI",
    "city": "Flint"
  },
  {
    "value": "48504",
    "label": "Flint - MI 48504",
    "county": "Genesee County",
    "state": "MI",
    "city": "Flint"
  },
  {
    "value": "48505",
    "label": "Flint - MI 48505",
    "county": "Genesee County",
    "state": "MI",
    "city": "Flint"
  },
  {
    "value": "48506",
    "label": "Flint - MI 48506",
    "county": "Genesee County",
    "state": "MI",
    "city": "Flint"
  },
  {
    "value": "48507",
    "label": "Flint - MI 48507",
    "county": "Genesee County",
    "state": "MI",
    "city": "Flint"
  },
  {
    "value": "48509",
    "label": "Burton - MI 48509",
    "county": "Genesee County",
    "state": "MI",
    "city": "Burton"
  },
  {
    "value": "48519",
    "label": "Burton - MI 48519",
    "county": "Genesee County",
    "state": "MI",
    "city": "Burton"
  },
  {
    "value": "48529",
    "label": "Burton - MI 48529",
    "county": "Genesee County",
    "state": "MI",
    "city": "Burton"
  },
  {
    "value": "48532",
    "label": "Flint - MI 48532",
    "county": "Genesee County",
    "state": "MI",
    "city": "Flint"
  },
  {
    "value": "48601",
    "label": "Saginaw - MI 48601",
    "county": "Saginaw County",
    "state": "MI",
    "city": "Saginaw"
  },
  {
    "value": "48602",
    "label": "Saginaw - MI 48602",
    "county": "Saginaw County",
    "state": "MI",
    "city": "Saginaw"
  },
  {
    "value": "48603",
    "label": "Saginaw - MI 48603",
    "county": "Saginaw County",
    "state": "MI",
    "city": "Saginaw"
  },
  {
    "value": "48604",
    "label": "Saginaw - MI 48604",
    "county": "Saginaw County",
    "state": "MI",
    "city": "Saginaw"
  },
  {
    "value": "48607",
    "label": "Saginaw - MI 48607",
    "county": "Saginaw County",
    "state": "MI",
    "city": "Saginaw"
  },
  {
    "value": "48609",
    "label": "Saginaw - MI 48609",
    "county": "Saginaw County",
    "state": "MI",
    "city": "Saginaw"
  },
  {
    "value": "48610",
    "label": "Alger - MI 48610",
    "county": "Arenac County",
    "state": "MI",
    "city": "Alger"
  },
  {
    "value": "48611",
    "label": "Auburn - MI 48611",
    "county": "Bay County",
    "state": "MI",
    "city": "Auburn"
  },
  {
    "value": "48612",
    "label": "Beaverton - MI 48612",
    "county": "Gladwin County",
    "state": "MI",
    "city": "Beaverton"
  },
  {
    "value": "48613",
    "label": "Bentley - MI 48613",
    "county": "Bay County",
    "state": "MI",
    "city": "Bentley"
  },
  {
    "value": "48614",
    "label": "Brant - MI 48614",
    "county": "Saginaw County",
    "state": "MI",
    "city": "Brant"
  },
  {
    "value": "48615",
    "label": "Breckenridge - MI 48615",
    "county": "Gratiot County",
    "state": "MI",
    "city": "Breckenridge"
  },
  {
    "value": "48616",
    "label": "Chesaning - MI 48616",
    "county": "Saginaw County",
    "state": "MI",
    "city": "Chesaning"
  },
  {
    "value": "48617",
    "label": "Clare - MI 48617",
    "county": "Clare County",
    "state": "MI",
    "city": "Clare"
  },
  {
    "value": "48618",
    "label": "Coleman - MI 48618",
    "county": "Midland County",
    "state": "MI",
    "city": "Coleman"
  },
  {
    "value": "48619",
    "label": "Comins - MI 48619",
    "county": "Oscoda County",
    "state": "MI",
    "city": "Comins"
  },
  {
    "value": "48621",
    "label": "Fairview - MI 48621",
    "county": "Oscoda County",
    "state": "MI",
    "city": "Fairview"
  },
  {
    "value": "48622",
    "label": "Farwell - MI 48622",
    "county": "Clare County",
    "state": "MI",
    "city": "Farwell"
  },
  {
    "value": "48623",
    "label": "Freeland - MI 48623",
    "county": "Saginaw County",
    "state": "MI",
    "city": "Freeland"
  },
  {
    "value": "48624",
    "label": "Gladwin - MI 48624",
    "county": "Gladwin County",
    "state": "MI",
    "city": "Gladwin"
  },
  {
    "value": "48625",
    "label": "Harrison - MI 48625",
    "county": "Clare County",
    "state": "MI",
    "city": "Harrison"
  },
  {
    "value": "48626",
    "label": "Hemlock - MI 48626",
    "county": "Saginaw County",
    "state": "MI",
    "city": "Hemlock"
  },
  {
    "value": "48628",
    "label": "Hope - MI 48628",
    "county": "Midland County",
    "state": "MI",
    "city": "Hope"
  },
  {
    "value": "48629",
    "label": "Houghton Lake - MI 48629",
    "county": "Roscommon County",
    "state": "MI",
    "city": "Houghton Lake"
  },
  {
    "value": "48631",
    "label": "Kawkawlin - MI 48631",
    "county": "Bay County",
    "state": "MI",
    "city": "Kawkawlin"
  },
  {
    "value": "48632",
    "label": "Lake - MI 48632",
    "county": "Clare County",
    "state": "MI",
    "city": "Lake"
  },
  {
    "value": "48634",
    "label": "Linwood - MI 48634",
    "county": "Bay County",
    "state": "MI",
    "city": "Linwood"
  },
  {
    "value": "48635",
    "label": "Lupton - MI 48635",
    "county": "Ogemaw County",
    "state": "MI",
    "city": "Lupton"
  },
  {
    "value": "48636",
    "label": "Luzerne - MI 48636",
    "county": "Oscoda County",
    "state": "MI",
    "city": "Luzerne"
  },
  {
    "value": "48637",
    "label": "Merrill - MI 48637",
    "county": "Saginaw County",
    "state": "MI",
    "city": "Merrill"
  },
  {
    "value": "48638",
    "label": "Saginaw - MI 48638",
    "county": "Saginaw County",
    "state": "MI",
    "city": "Saginaw"
  },
  {
    "value": "48640",
    "label": "Midland - MI 48640",
    "county": "Midland County",
    "state": "MI",
    "city": "Midland"
  },
  {
    "value": "48642",
    "label": "Midland - MI 48642",
    "county": "Midland County",
    "state": "MI",
    "city": "Midland"
  },
  {
    "value": "48647",
    "label": "Mio - MI 48647",
    "county": "Oscoda County",
    "state": "MI",
    "city": "Mio"
  },
  {
    "value": "48649",
    "label": "Oakley - MI 48649",
    "county": "Saginaw County",
    "state": "MI",
    "city": "Oakley"
  },
  {
    "value": "48650",
    "label": "Pinconning - MI 48650",
    "county": "Bay County",
    "state": "MI",
    "city": "Pinconning"
  },
  {
    "value": "48651",
    "label": "Prudenville - MI 48651",
    "county": "Roscommon County",
    "state": "MI",
    "city": "Prudenville"
  },
  {
    "value": "48652",
    "label": "Rhodes - MI 48652",
    "county": "Gladwin County",
    "state": "MI",
    "city": "Rhodes"
  },
  {
    "value": "48653",
    "label": "Roscommon - MI 48653",
    "county": "Roscommon County",
    "state": "MI",
    "city": "Roscommon"
  },
  {
    "value": "48654",
    "label": "Rose City - MI 48654",
    "county": "Ogemaw County",
    "state": "MI",
    "city": "Rose City"
  },
  {
    "value": "48655",
    "label": "Saint Charles - MI 48655",
    "county": "Saginaw County",
    "state": "MI",
    "city": "Saint Charles"
  },
  {
    "value": "48656",
    "label": "Saint Helen - MI 48656",
    "county": "Roscommon County",
    "state": "MI",
    "city": "Saint Helen"
  },
  {
    "value": "48657",
    "label": "Sanford - MI 48657",
    "county": "Midland County",
    "state": "MI",
    "city": "Sanford"
  },
  {
    "value": "48658",
    "label": "Standish - MI 48658",
    "county": "Arenac County",
    "state": "MI",
    "city": "Standish"
  },
  {
    "value": "48659",
    "label": "Sterling - MI 48659",
    "county": "Arenac County",
    "state": "MI",
    "city": "Sterling"
  },
  {
    "value": "48661",
    "label": "West Branch - MI 48661",
    "county": "Ogemaw County",
    "state": "MI",
    "city": "West Branch"
  },
  {
    "value": "48662",
    "label": "Wheeler - MI 48662",
    "county": "Gratiot County",
    "state": "MI",
    "city": "Wheeler"
  },
  {
    "value": "48701",
    "label": "Akron - MI 48701",
    "county": "Tuscola County",
    "state": "MI",
    "city": "Akron"
  },
  {
    "value": "48703",
    "label": "Au Gres - MI 48703",
    "county": "Arenac County",
    "state": "MI",
    "city": "Au Gres"
  },
  {
    "value": "48705",
    "label": "Barton City - MI 48705",
    "county": "Alcona County",
    "state": "MI",
    "city": "Barton City"
  },
  {
    "value": "48706",
    "label": "Bay City - MI 48706",
    "county": "Bay County",
    "state": "MI",
    "city": "Bay City"
  },
  {
    "value": "48708",
    "label": "Bay City - MI 48708",
    "county": "Bay County",
    "state": "MI",
    "city": "Bay City"
  },
  {
    "value": "48720",
    "label": "Bay Port - MI 48720",
    "county": "Huron County",
    "state": "MI",
    "city": "Bay Port"
  },
  {
    "value": "48721",
    "label": "Black River - MI 48721",
    "county": "Alcona County",
    "state": "MI",
    "city": "Black River"
  },
  {
    "value": "48722",
    "label": "Bridgeport - MI 48722",
    "county": "Saginaw County",
    "state": "MI",
    "city": "Bridgeport"
  },
  {
    "value": "48723",
    "label": "Caro - MI 48723",
    "county": "Tuscola County",
    "state": "MI",
    "city": "Caro"
  },
  {
    "value": "48725",
    "label": "Caseville - MI 48725",
    "county": "Huron County",
    "state": "MI",
    "city": "Caseville"
  },
  {
    "value": "48726",
    "label": "Cass City - MI 48726",
    "county": "Tuscola County",
    "state": "MI",
    "city": "Cass City"
  },
  {
    "value": "48727",
    "label": "Clifford - MI 48727",
    "county": "Lapeer County",
    "state": "MI",
    "city": "Clifford"
  },
  {
    "value": "48728",
    "label": "Curran - MI 48728",
    "county": "Alcona County",
    "state": "MI",
    "city": "Curran"
  },
  {
    "value": "48729",
    "label": "Deford - MI 48729",
    "county": "Tuscola County",
    "state": "MI",
    "city": "Deford"
  },
  {
    "value": "48730",
    "label": "East Tawas - MI 48730",
    "county": "Iosco County",
    "state": "MI",
    "city": "East Tawas"
  },
  {
    "value": "48731",
    "label": "Elkton - MI 48731",
    "county": "Huron County",
    "state": "MI",
    "city": "Elkton"
  },
  {
    "value": "48732",
    "label": "Essexville - MI 48732",
    "county": "Bay County",
    "state": "MI",
    "city": "Essexville"
  },
  {
    "value": "48733",
    "label": "Fairgrove - MI 48733",
    "county": "Tuscola County",
    "state": "MI",
    "city": "Fairgrove"
  },
  {
    "value": "48734",
    "label": "Frankenmuth - MI 48734",
    "county": "Saginaw County",
    "state": "MI",
    "city": "Frankenmuth"
  },
  {
    "value": "48735",
    "label": "Gagetown - MI 48735",
    "county": "Tuscola County",
    "state": "MI",
    "city": "Gagetown"
  },
  {
    "value": "48737",
    "label": "Glennie - MI 48737",
    "county": "Alcona County",
    "state": "MI",
    "city": "Glennie"
  },
  {
    "value": "48738",
    "label": "Greenbush - MI 48738",
    "county": "Alcona County",
    "state": "MI",
    "city": "Greenbush"
  },
  {
    "value": "48739",
    "label": "Hale - MI 48739",
    "county": "Iosco County",
    "state": "MI",
    "city": "Hale"
  },
  {
    "value": "48740",
    "label": "Harrisville - MI 48740",
    "county": "Alcona County",
    "state": "MI",
    "city": "Harrisville"
  },
  {
    "value": "48741",
    "label": "Kingston - MI 48741",
    "county": "Tuscola County",
    "state": "MI",
    "city": "Kingston"
  },
  {
    "value": "48742",
    "label": "Lincoln - MI 48742",
    "county": "Alcona County",
    "state": "MI",
    "city": "Lincoln"
  },
  {
    "value": "48743",
    "label": "Long Lake - MI 48743",
    "county": "Iosco County",
    "state": "MI",
    "city": "Long Lake"
  },
  {
    "value": "48744",
    "label": "Mayville - MI 48744",
    "county": "Tuscola County",
    "state": "MI",
    "city": "Mayville"
  },
  {
    "value": "48745",
    "label": "Mikado - MI 48745",
    "county": "Alcona County",
    "state": "MI",
    "city": "Mikado"
  },
  {
    "value": "48746",
    "label": "Millington - MI 48746",
    "county": "Tuscola County",
    "state": "MI",
    "city": "Millington"
  },
  {
    "value": "48747",
    "label": "Munger - MI 48747",
    "county": "Bay County",
    "state": "MI",
    "city": "Munger"
  },
  {
    "value": "48748",
    "label": "National City - MI 48748",
    "county": "Iosco County",
    "state": "MI",
    "city": "National City"
  },
  {
    "value": "48749",
    "label": "Omer - MI 48749",
    "county": "Arenac County",
    "state": "MI",
    "city": "Omer"
  },
  {
    "value": "48750",
    "label": "Oscoda - MI 48750",
    "county": "Iosco County",
    "state": "MI",
    "city": "Oscoda"
  },
  {
    "value": "48754",
    "label": "Owendale - MI 48754",
    "county": "Huron County",
    "state": "MI",
    "city": "Owendale"
  },
  {
    "value": "48755",
    "label": "Pigeon - MI 48755",
    "county": "Huron County",
    "state": "MI",
    "city": "Pigeon"
  },
  {
    "value": "48756",
    "label": "Prescott - MI 48756",
    "county": "Ogemaw County",
    "state": "MI",
    "city": "Prescott"
  },
  {
    "value": "48757",
    "label": "Reese - MI 48757",
    "county": "Tuscola County",
    "state": "MI",
    "city": "Reese"
  },
  {
    "value": "48759",
    "label": "Sebewaing - MI 48759",
    "county": "Huron County",
    "state": "MI",
    "city": "Sebewaing"
  },
  {
    "value": "48760",
    "label": "Silverwood - MI 48760",
    "county": "Tuscola County",
    "state": "MI",
    "city": "Silverwood"
  },
  {
    "value": "48761",
    "label": "South Branch - MI 48761",
    "county": "Iosco County",
    "state": "MI",
    "city": "South Branch"
  },
  {
    "value": "48762",
    "label": "Spruce - MI 48762",
    "county": "Alcona County",
    "state": "MI",
    "city": "Spruce"
  },
  {
    "value": "48763",
    "label": "Tawas City - MI 48763",
    "county": "Iosco County",
    "state": "MI",
    "city": "Tawas City"
  },
  {
    "value": "48765",
    "label": "Turner - MI 48765",
    "county": "Arenac County",
    "state": "MI",
    "city": "Turner"
  },
  {
    "value": "48766",
    "label": "Twining - MI 48766",
    "county": "Arenac County",
    "state": "MI",
    "city": "Twining"
  },
  {
    "value": "48767",
    "label": "Unionville - MI 48767",
    "county": "Tuscola County",
    "state": "MI",
    "city": "Unionville"
  },
  {
    "value": "48768",
    "label": "Vassar - MI 48768",
    "county": "Tuscola County",
    "state": "MI",
    "city": "Vassar"
  },
  {
    "value": "48769",
    "label": "Vassar - MI 48769",
    "county": "Tuscola County",
    "state": "MI",
    "city": "Vassar"
  },
  {
    "value": "48770",
    "label": "Whittemore - MI 48770",
    "county": "Iosco County",
    "state": "MI",
    "city": "Whittemore"
  },
  {
    "value": "48801",
    "label": "Alma - MI 48801",
    "county": "Gratiot County",
    "state": "MI",
    "city": "Alma"
  },
  {
    "value": "48806",
    "label": "Ashley - MI 48806",
    "county": "Gratiot County",
    "state": "MI",
    "city": "Ashley"
  },
  {
    "value": "48807",
    "label": "Bannister - MI 48807",
    "county": "Gratiot County",
    "state": "MI",
    "city": "Bannister"
  },
  {
    "value": "48808",
    "label": "Bath - MI 48808",
    "county": "Clinton County",
    "state": "MI",
    "city": "Bath"
  },
  {
    "value": "48809",
    "label": "Belding - MI 48809",
    "county": "Ionia County",
    "state": "MI",
    "city": "Belding"
  },
  {
    "value": "48811",
    "label": "Carson City - MI 48811",
    "county": "Montcalm County",
    "state": "MI",
    "city": "Carson City"
  },
  {
    "value": "48813",
    "label": "Charlotte - MI 48813",
    "county": "Eaton County",
    "state": "MI",
    "city": "Charlotte"
  },
  {
    "value": "48815",
    "label": "Clarksville - MI 48815",
    "county": "Ionia County",
    "state": "MI",
    "city": "Clarksville"
  },
  {
    "value": "48817",
    "label": "Corunna - MI 48817",
    "county": "Shiawassee County",
    "state": "MI",
    "city": "Corunna"
  },
  {
    "value": "48818",
    "label": "Crystal - MI 48818",
    "county": "Montcalm County",
    "state": "MI",
    "city": "Crystal"
  },
  {
    "value": "48819",
    "label": "Dansville - MI 48819",
    "county": "Ingham County",
    "state": "MI",
    "city": "Dansville"
  },
  {
    "value": "48820",
    "label": "Dewitt - MI 48820",
    "county": "Clinton County",
    "state": "MI",
    "city": "Dewitt"
  },
  {
    "value": "48821",
    "label": "Dimondale - MI 48821",
    "county": "Eaton County",
    "state": "MI",
    "city": "Dimondale"
  },
  {
    "value": "48822",
    "label": "Eagle - MI 48822",
    "county": "Clinton County",
    "state": "MI",
    "city": "Eagle"
  },
  {
    "value": "48823",
    "label": "East Lansing - MI 48823",
    "county": "Ingham County",
    "state": "MI",
    "city": "East Lansing"
  },
  {
    "value": "48824",
    "label": "East Lansing - MI 48824",
    "county": "Ingham County",
    "state": "MI",
    "city": "East Lansing"
  },
  {
    "value": "48825",
    "label": "East Lansing - MI 48825",
    "county": "Ingham County",
    "state": "MI",
    "city": "East Lansing"
  },
  {
    "value": "48827",
    "label": "Eaton Rapids - MI 48827",
    "county": "Eaton County",
    "state": "MI",
    "city": "Eaton Rapids"
  },
  {
    "value": "48829",
    "label": "Edmore - MI 48829",
    "county": "Montcalm County",
    "state": "MI",
    "city": "Edmore"
  },
  {
    "value": "48831",
    "label": "Elsie - MI 48831",
    "county": "Clinton County",
    "state": "MI",
    "city": "Elsie"
  },
  {
    "value": "48832",
    "label": "Elwell - MI 48832",
    "county": "Gratiot County",
    "state": "MI",
    "city": "Elwell"
  },
  {
    "value": "48834",
    "label": "Fenwick - MI 48834",
    "county": "Montcalm County",
    "state": "MI",
    "city": "Fenwick"
  },
  {
    "value": "48835",
    "label": "Fowler - MI 48835",
    "county": "Clinton County",
    "state": "MI",
    "city": "Fowler"
  },
  {
    "value": "48836",
    "label": "Fowlerville - MI 48836",
    "county": "Livingston County",
    "state": "MI",
    "city": "Fowlerville"
  },
  {
    "value": "48837",
    "label": "Grand Ledge - MI 48837",
    "county": "Eaton County",
    "state": "MI",
    "city": "Grand Ledge"
  },
  {
    "value": "48838",
    "label": "Greenville - MI 48838",
    "county": "Montcalm County",
    "state": "MI",
    "city": "Greenville"
  },
  {
    "value": "48840",
    "label": "Haslett - MI 48840",
    "county": "Ingham County",
    "state": "MI",
    "city": "Haslett"
  },
  {
    "value": "48841",
    "label": "Henderson - MI 48841",
    "county": "Shiawassee County",
    "state": "MI",
    "city": "Henderson"
  },
  {
    "value": "48842",
    "label": "Holt - MI 48842",
    "county": "Ingham County",
    "state": "MI",
    "city": "Holt"
  },
  {
    "value": "48843",
    "label": "Howell - MI 48843",
    "county": "Livingston County",
    "state": "MI",
    "city": "Howell"
  },
  {
    "value": "48845",
    "label": "Hubbardston - MI 48845",
    "county": "Ionia County",
    "state": "MI",
    "city": "Hubbardston"
  },
  {
    "value": "48846",
    "label": "Ionia - MI 48846",
    "county": "Ionia County",
    "state": "MI",
    "city": "Ionia"
  },
  {
    "value": "48847",
    "label": "Ithaca - MI 48847",
    "county": "Gratiot County",
    "state": "MI",
    "city": "Ithaca"
  },
  {
    "value": "48848",
    "label": "Laingsburg - MI 48848",
    "county": "Shiawassee County",
    "state": "MI",
    "city": "Laingsburg"
  },
  {
    "value": "48849",
    "label": "Lake Odessa - MI 48849",
    "county": "Ionia County",
    "state": "MI",
    "city": "Lake Odessa"
  },
  {
    "value": "48850",
    "label": "Lakeview - MI 48850",
    "county": "Montcalm County",
    "state": "MI",
    "city": "Lakeview"
  },
  {
    "value": "48851",
    "label": "Lyons - MI 48851",
    "county": "Ionia County",
    "state": "MI",
    "city": "Lyons"
  },
  {
    "value": "48854",
    "label": "Mason - MI 48854",
    "county": "Ingham County",
    "state": "MI",
    "city": "Mason"
  },
  {
    "value": "48855",
    "label": "Howell - MI 48855",
    "county": "Livingston County",
    "state": "MI",
    "city": "Howell"
  },
  {
    "value": "48856",
    "label": "Middleton - MI 48856",
    "county": "Gratiot County",
    "state": "MI",
    "city": "Middleton"
  },
  {
    "value": "48857",
    "label": "Morrice - MI 48857",
    "county": "Shiawassee County",
    "state": "MI",
    "city": "Morrice"
  },
  {
    "value": "48858",
    "label": "Mount Pleasant - MI 48858",
    "county": "Isabella County",
    "state": "MI",
    "city": "Mount Pleasant"
  },
  {
    "value": "48860",
    "label": "Muir - MI 48860",
    "county": "Ionia County",
    "state": "MI",
    "city": "Muir"
  },
  {
    "value": "48861",
    "label": "Mulliken - MI 48861",
    "county": "Eaton County",
    "state": "MI",
    "city": "Mulliken"
  },
  {
    "value": "48863",
    "label": "Howell - MI 48863",
    "county": "Livingston County",
    "state": "MI",
    "city": "Howell"
  },
  {
    "value": "48864",
    "label": "Okemos - MI 48864",
    "county": "Ingham County",
    "state": "MI",
    "city": "Okemos"
  },
  {
    "value": "48865",
    "label": "Orleans - MI 48865",
    "county": "Ionia County",
    "state": "MI",
    "city": "Orleans"
  },
  {
    "value": "48866",
    "label": "Ovid - MI 48866",
    "county": "Clinton County",
    "state": "MI",
    "city": "Ovid"
  },
  {
    "value": "48867",
    "label": "Owosso - MI 48867",
    "county": "Shiawassee County",
    "state": "MI",
    "city": "Owosso"
  },
  {
    "value": "48871",
    "label": "Perrinton - MI 48871",
    "county": "Gratiot County",
    "state": "MI",
    "city": "Perrinton"
  },
  {
    "value": "48872",
    "label": "Perry - MI 48872",
    "county": "Shiawassee County",
    "state": "MI",
    "city": "Perry"
  },
  {
    "value": "48873",
    "label": "Pewamo - MI 48873",
    "county": "Ionia County",
    "state": "MI",
    "city": "Pewamo"
  },
  {
    "value": "48875",
    "label": "Portland - MI 48875",
    "county": "Ionia County",
    "state": "MI",
    "city": "Portland"
  },
  {
    "value": "48876",
    "label": "Potterville - MI 48876",
    "county": "Eaton County",
    "state": "MI",
    "city": "Potterville"
  },
  {
    "value": "48877",
    "label": "Riverdale - MI 48877",
    "county": "Gratiot County",
    "state": "MI",
    "city": "Riverdale"
  },
  {
    "value": "48878",
    "label": "Rosebush - MI 48878",
    "county": "Isabella County",
    "state": "MI",
    "city": "Rosebush"
  },
  {
    "value": "48879",
    "label": "Saint Johns - MI 48879",
    "county": "Clinton County",
    "state": "MI",
    "city": "Saint Johns"
  },
  {
    "value": "48880",
    "label": "Saint Louis - MI 48880",
    "county": "Gratiot County",
    "state": "MI",
    "city": "Saint Louis"
  },
  {
    "value": "48881",
    "label": "Saranac - MI 48881",
    "county": "Ionia County",
    "state": "MI",
    "city": "Saranac"
  },
  {
    "value": "48883",
    "label": "Shepherd - MI 48883",
    "county": "Isabella County",
    "state": "MI",
    "city": "Shepherd"
  },
  {
    "value": "48884",
    "label": "Sheridan - MI 48884",
    "county": "Montcalm County",
    "state": "MI",
    "city": "Sheridan"
  },
  {
    "value": "48885",
    "label": "Sidney - MI 48885",
    "county": "Montcalm County",
    "state": "MI",
    "city": "Sidney"
  },
  {
    "value": "48886",
    "label": "Six Lakes - MI 48886",
    "county": "Montcalm County",
    "state": "MI",
    "city": "Six Lakes"
  },
  {
    "value": "48888",
    "label": "Stanton - MI 48888",
    "county": "Montcalm County",
    "state": "MI",
    "city": "Stanton"
  },
  {
    "value": "48889",
    "label": "Sumner - MI 48889",
    "county": "Gratiot County",
    "state": "MI",
    "city": "Sumner"
  },
  {
    "value": "48890",
    "label": "Sunfield - MI 48890",
    "county": "Eaton County",
    "state": "MI",
    "city": "Sunfield"
  },
  {
    "value": "48891",
    "label": "Vestaburg - MI 48891",
    "county": "Montcalm County",
    "state": "MI",
    "city": "Vestaburg"
  },
  {
    "value": "48892",
    "label": "Webberville - MI 48892",
    "county": "Ingham County",
    "state": "MI",
    "city": "Webberville"
  },
  {
    "value": "48893",
    "label": "Weidman - MI 48893",
    "county": "Isabella County",
    "state": "MI",
    "city": "Weidman"
  },
  {
    "value": "48894",
    "label": "Westphalia - MI 48894",
    "county": "Clinton County",
    "state": "MI",
    "city": "Westphalia"
  },
  {
    "value": "48895",
    "label": "Williamston - MI 48895",
    "county": "Ingham County",
    "state": "MI",
    "city": "Williamston"
  },
  {
    "value": "48897",
    "label": "Woodland - MI 48897",
    "county": "Barry County",
    "state": "MI",
    "city": "Woodland"
  },
  {
    "value": "48906",
    "label": "Lansing - MI 48906",
    "county": "Ingham County",
    "state": "MI",
    "city": "Lansing"
  },
  {
    "value": "48910",
    "label": "Lansing - MI 48910",
    "county": "Ingham County",
    "state": "MI",
    "city": "Lansing"
  },
  {
    "value": "48911",
    "label": "Lansing - MI 48911",
    "county": "Ingham County",
    "state": "MI",
    "city": "Lansing"
  },
  {
    "value": "48912",
    "label": "Lansing - MI 48912",
    "county": "Ingham County",
    "state": "MI",
    "city": "Lansing"
  },
  {
    "value": "48915",
    "label": "Lansing - MI 48915",
    "county": "Ingham County",
    "state": "MI",
    "city": "Lansing"
  },
  {
    "value": "48917",
    "label": "Lansing - MI 48917",
    "county": "Eaton County",
    "state": "MI",
    "city": "Lansing"
  },
  {
    "value": "48924",
    "label": "Lansing - MI 48924",
    "county": "Ingham County",
    "state": "MI",
    "city": "Lansing"
  },
  {
    "value": "48933",
    "label": "Lansing - MI 48933",
    "county": "Ingham County",
    "state": "MI",
    "city": "Lansing"
  },
  {
    "value": "49001",
    "label": "Kalamazoo - MI 49001",
    "county": "Kalamazoo County",
    "state": "MI",
    "city": "Kalamazoo"
  },
  {
    "value": "49002",
    "label": "Portage - MI 49002",
    "county": "Kalamazoo County",
    "state": "MI",
    "city": "Portage"
  },
  {
    "value": "49004",
    "label": "Kalamazoo - MI 49004",
    "county": "Kalamazoo County",
    "state": "MI",
    "city": "Kalamazoo"
  },
  {
    "value": "49006",
    "label": "Kalamazoo - MI 49006",
    "county": "Kalamazoo County",
    "state": "MI",
    "city": "Kalamazoo"
  },
  {
    "value": "49007",
    "label": "Kalamazoo - MI 49007",
    "county": "Kalamazoo County",
    "state": "MI",
    "city": "Kalamazoo"
  },
  {
    "value": "49008",
    "label": "Kalamazoo - MI 49008",
    "county": "Kalamazoo County",
    "state": "MI",
    "city": "Kalamazoo"
  },
  {
    "value": "49009",
    "label": "Kalamazoo - MI 49009",
    "county": "Kalamazoo County",
    "state": "MI",
    "city": "Kalamazoo"
  },
  {
    "value": "49010",
    "label": "Allegan - MI 49010",
    "county": "Allegan County",
    "state": "MI",
    "city": "Allegan"
  },
  {
    "value": "49011",
    "label": "Athens - MI 49011",
    "county": "Calhoun County",
    "state": "MI",
    "city": "Athens"
  },
  {
    "value": "49012",
    "label": "Augusta - MI 49012",
    "county": "Kalamazoo County",
    "state": "MI",
    "city": "Augusta"
  },
  {
    "value": "49013",
    "label": "Bangor - MI 49013",
    "county": "Van Buren County",
    "state": "MI",
    "city": "Bangor"
  },
  {
    "value": "49014",
    "label": "Battle Creek - MI 49014",
    "county": "Calhoun County",
    "state": "MI",
    "city": "Battle Creek"
  },
  {
    "value": "49015",
    "label": "Battle Creek - MI 49015",
    "county": "Calhoun County",
    "state": "MI",
    "city": "Battle Creek"
  },
  {
    "value": "49017",
    "label": "Battle Creek - MI 49017",
    "county": "Calhoun County",
    "state": "MI",
    "city": "Battle Creek"
  },
  {
    "value": "49021",
    "label": "Bellevue - MI 49021",
    "county": "Eaton County",
    "state": "MI",
    "city": "Bellevue"
  },
  {
    "value": "49022",
    "label": "Benton Harbor - MI 49022",
    "county": "Berrien County",
    "state": "MI",
    "city": "Benton Harbor"
  },
  {
    "value": "49024",
    "label": "Portage - MI 49024",
    "county": "Kalamazoo County",
    "state": "MI",
    "city": "Portage"
  },
  {
    "value": "49026",
    "label": "Bloomingdale - MI 49026",
    "county": "Van Buren County",
    "state": "MI",
    "city": "Bloomingdale"
  },
  {
    "value": "49028",
    "label": "Bronson - MI 49028",
    "county": "Branch County",
    "state": "MI",
    "city": "Bronson"
  },
  {
    "value": "49029",
    "label": "Burlington - MI 49029",
    "county": "Calhoun County",
    "state": "MI",
    "city": "Burlington"
  },
  {
    "value": "49030",
    "label": "Burr Oak - MI 49030",
    "county": "St. Joseph County",
    "state": "MI",
    "city": "Burr Oak"
  },
  {
    "value": "49031",
    "label": "Cassopolis - MI 49031",
    "county": "Cass County",
    "state": "MI",
    "city": "Cassopolis"
  },
  {
    "value": "49032",
    "label": "Centreville - MI 49032",
    "county": "St. Joseph County",
    "state": "MI",
    "city": "Centreville"
  },
  {
    "value": "49033",
    "label": "Ceresco - MI 49033",
    "county": "Calhoun County",
    "state": "MI",
    "city": "Ceresco"
  },
  {
    "value": "49034",
    "label": "Climax - MI 49034",
    "county": "Kalamazoo County",
    "state": "MI",
    "city": "Climax"
  },
  {
    "value": "49036",
    "label": "Coldwater - MI 49036",
    "county": "Branch County",
    "state": "MI",
    "city": "Coldwater"
  },
  {
    "value": "49037",
    "label": "Battle Creek - MI 49037",
    "county": "Calhoun County",
    "state": "MI",
    "city": "Battle Creek"
  },
  {
    "value": "49038",
    "label": "Coloma - MI 49038",
    "county": "Berrien County",
    "state": "MI",
    "city": "Coloma"
  },
  {
    "value": "49040",
    "label": "Colon - MI 49040",
    "county": "St. Joseph County",
    "state": "MI",
    "city": "Colon"
  },
  {
    "value": "49042",
    "label": "Constantine - MI 49042",
    "county": "St. Joseph County",
    "state": "MI",
    "city": "Constantine"
  },
  {
    "value": "49043",
    "label": "Covert - MI 49043",
    "county": "Van Buren County",
    "state": "MI",
    "city": "Covert"
  },
  {
    "value": "49045",
    "label": "Decatur - MI 49045",
    "county": "Van Buren County",
    "state": "MI",
    "city": "Decatur"
  },
  {
    "value": "49046",
    "label": "Delton - MI 49046",
    "county": "Barry County",
    "state": "MI",
    "city": "Delton"
  },
  {
    "value": "49047",
    "label": "Dowagiac - MI 49047",
    "county": "Cass County",
    "state": "MI",
    "city": "Dowagiac"
  },
  {
    "value": "49048",
    "label": "Kalamazoo - MI 49048",
    "county": "Kalamazoo County",
    "state": "MI",
    "city": "Kalamazoo"
  },
  {
    "value": "49050",
    "label": "Dowling - MI 49050",
    "county": "Barry County",
    "state": "MI",
    "city": "Dowling"
  },
  {
    "value": "49051",
    "label": "East Leroy - MI 49051",
    "county": "Calhoun County",
    "state": "MI",
    "city": "East Leroy"
  },
  {
    "value": "49052",
    "label": "Fulton - MI 49052",
    "county": "Kalamazoo County",
    "state": "MI",
    "city": "Fulton"
  },
  {
    "value": "49053",
    "label": "Galesburg - MI 49053",
    "county": "Kalamazoo County",
    "state": "MI",
    "city": "Galesburg"
  },
  {
    "value": "49055",
    "label": "Gobles - MI 49055",
    "county": "Van Buren County",
    "state": "MI",
    "city": "Gobles"
  },
  {
    "value": "49056",
    "label": "Grand Junction - MI 49056",
    "county": "Van Buren County",
    "state": "MI",
    "city": "Grand Junction"
  },
  {
    "value": "49057",
    "label": "Hartford - MI 49057",
    "county": "Van Buren County",
    "state": "MI",
    "city": "Hartford"
  },
  {
    "value": "49058",
    "label": "Hastings - MI 49058",
    "county": "Barry County",
    "state": "MI",
    "city": "Hastings"
  },
  {
    "value": "49060",
    "label": "Hickory Corners - MI 49060",
    "county": "Barry County",
    "state": "MI",
    "city": "Hickory Corners"
  },
  {
    "value": "49061",
    "label": "Jones - MI 49061",
    "county": "Cass County",
    "state": "MI",
    "city": "Jones"
  },
  {
    "value": "49064",
    "label": "Lawrence - MI 49064",
    "county": "Van Buren County",
    "state": "MI",
    "city": "Lawrence"
  },
  {
    "value": "49065",
    "label": "Lawton - MI 49065",
    "county": "Van Buren County",
    "state": "MI",
    "city": "Lawton"
  },
  {
    "value": "49066",
    "label": "Leonidas - MI 49066",
    "county": "St. Joseph County",
    "state": "MI",
    "city": "Leonidas"
  },
  {
    "value": "49067",
    "label": "Marcellus - MI 49067",
    "county": "Cass County",
    "state": "MI",
    "city": "Marcellus"
  },
  {
    "value": "49068",
    "label": "Marshall - MI 49068",
    "county": "Calhoun County",
    "state": "MI",
    "city": "Marshall"
  },
  {
    "value": "49070",
    "label": "Martin - MI 49070",
    "county": "Allegan County",
    "state": "MI",
    "city": "Martin"
  },
  {
    "value": "49071",
    "label": "Mattawan - MI 49071",
    "county": "Van Buren County",
    "state": "MI",
    "city": "Mattawan"
  },
  {
    "value": "49072",
    "label": "Mendon - MI 49072",
    "county": "St. Joseph County",
    "state": "MI",
    "city": "Mendon"
  },
  {
    "value": "49073",
    "label": "Nashville - MI 49073",
    "county": "Barry County",
    "state": "MI",
    "city": "Nashville"
  },
  {
    "value": "49076",
    "label": "Olivet - MI 49076",
    "county": "Eaton County",
    "state": "MI",
    "city": "Olivet"
  },
  {
    "value": "49078",
    "label": "Otsego - MI 49078",
    "county": "Allegan County",
    "state": "MI",
    "city": "Otsego"
  },
  {
    "value": "49079",
    "label": "Paw Paw - MI 49079",
    "county": "Van Buren County",
    "state": "MI",
    "city": "Paw Paw"
  },
  {
    "value": "49080",
    "label": "Plainwell - MI 49080",
    "county": "Allegan County",
    "state": "MI",
    "city": "Plainwell"
  },
  {
    "value": "49082",
    "label": "Quincy - MI 49082",
    "county": "Branch County",
    "state": "MI",
    "city": "Quincy"
  },
  {
    "value": "49083",
    "label": "Richland - MI 49083",
    "county": "Kalamazoo County",
    "state": "MI",
    "city": "Richland"
  },
  {
    "value": "49085",
    "label": "Saint Joseph - MI 49085",
    "county": "Berrien County",
    "state": "MI",
    "city": "Saint Joseph"
  },
  {
    "value": "49087",
    "label": "Schoolcraft - MI 49087",
    "county": "Kalamazoo County",
    "state": "MI",
    "city": "Schoolcraft"
  },
  {
    "value": "49088",
    "label": "Scotts - MI 49088",
    "county": "Kalamazoo County",
    "state": "MI",
    "city": "Scotts"
  },
  {
    "value": "49089",
    "label": "Sherwood - MI 49089",
    "county": "Branch County",
    "state": "MI",
    "city": "Sherwood"
  },
  {
    "value": "49090",
    "label": "South Haven - MI 49090",
    "county": "Van Buren County",
    "state": "MI",
    "city": "South Haven"
  },
  {
    "value": "49091",
    "label": "Sturgis - MI 49091",
    "county": "St. Joseph County",
    "state": "MI",
    "city": "Sturgis"
  },
  {
    "value": "49092",
    "label": "Tekonsha - MI 49092",
    "county": "Calhoun County",
    "state": "MI",
    "city": "Tekonsha"
  },
  {
    "value": "49093",
    "label": "Three Rivers - MI 49093",
    "county": "St. Joseph County",
    "state": "MI",
    "city": "Three Rivers"
  },
  {
    "value": "49094",
    "label": "Union City - MI 49094",
    "county": "Branch County",
    "state": "MI",
    "city": "Union City"
  },
  {
    "value": "49095",
    "label": "Vandalia - MI 49095",
    "county": "Cass County",
    "state": "MI",
    "city": "Vandalia"
  },
  {
    "value": "49096",
    "label": "Vermontville - MI 49096",
    "county": "Eaton County",
    "state": "MI",
    "city": "Vermontville"
  },
  {
    "value": "49097",
    "label": "Vicksburg - MI 49097",
    "county": "Kalamazoo County",
    "state": "MI",
    "city": "Vicksburg"
  },
  {
    "value": "49098",
    "label": "Watervliet - MI 49098",
    "county": "Berrien County",
    "state": "MI",
    "city": "Watervliet"
  },
  {
    "value": "49099",
    "label": "White Pigeon - MI 49099",
    "county": "St. Joseph County",
    "state": "MI",
    "city": "White Pigeon"
  },
  {
    "value": "49101",
    "label": "Baroda - MI 49101",
    "county": "Berrien County",
    "state": "MI",
    "city": "Baroda"
  },
  {
    "value": "49102",
    "label": "Berrien Center - MI 49102",
    "county": "Berrien County",
    "state": "MI",
    "city": "Berrien Center"
  },
  {
    "value": "49103",
    "label": "Berrien Springs - MI 49103",
    "county": "Berrien County",
    "state": "MI",
    "city": "Berrien Springs"
  },
  {
    "value": "49106",
    "label": "Bridgman - MI 49106",
    "county": "Berrien County",
    "state": "MI",
    "city": "Bridgman"
  },
  {
    "value": "49107",
    "label": "Buchanan - MI 49107",
    "county": "Berrien County",
    "state": "MI",
    "city": "Buchanan"
  },
  {
    "value": "49111",
    "label": "Eau Claire - MI 49111",
    "county": "Berrien County",
    "state": "MI",
    "city": "Eau Claire"
  },
  {
    "value": "49112",
    "label": "Edwardsburg - MI 49112",
    "county": "Cass County",
    "state": "MI",
    "city": "Edwardsburg"
  },
  {
    "value": "49113",
    "label": "Galien - MI 49113",
    "county": "Berrien County",
    "state": "MI",
    "city": "Galien"
  },
  {
    "value": "49116",
    "label": "Lakeside - MI 49116",
    "county": "Berrien County",
    "state": "MI",
    "city": "Lakeside"
  },
  {
    "value": "49117",
    "label": "New Buffalo - MI 49117",
    "county": "Berrien County",
    "state": "MI",
    "city": "New Buffalo"
  },
  {
    "value": "49120",
    "label": "Niles - MI 49120",
    "county": "Berrien County",
    "state": "MI",
    "city": "Niles"
  },
  {
    "value": "49125",
    "label": "Sawyer - MI 49125",
    "county": "Berrien County",
    "state": "MI",
    "city": "Sawyer"
  },
  {
    "value": "49126",
    "label": "Sodus - MI 49126",
    "county": "Berrien County",
    "state": "MI",
    "city": "Sodus"
  },
  {
    "value": "49127",
    "label": "Stevensville - MI 49127",
    "county": "Berrien County",
    "state": "MI",
    "city": "Stevensville"
  },
  {
    "value": "49128",
    "label": "Three Oaks - MI 49128",
    "county": "Berrien County",
    "state": "MI",
    "city": "Three Oaks"
  },
  {
    "value": "49129",
    "label": "Union Pier - MI 49129",
    "county": "Berrien County",
    "state": "MI",
    "city": "Union Pier"
  },
  {
    "value": "49130",
    "label": "Union - MI 49130",
    "county": "Cass County",
    "state": "MI",
    "city": "Union"
  },
  {
    "value": "49201",
    "label": "Jackson - MI 49201",
    "county": "Jackson County",
    "state": "MI",
    "city": "Jackson"
  },
  {
    "value": "49202",
    "label": "Jackson - MI 49202",
    "county": "Jackson County",
    "state": "MI",
    "city": "Jackson"
  },
  {
    "value": "49203",
    "label": "Jackson - MI 49203",
    "county": "Jackson County",
    "state": "MI",
    "city": "Jackson"
  },
  {
    "value": "49220",
    "label": "Addison - MI 49220",
    "county": "Lenawee County",
    "state": "MI",
    "city": "Addison"
  },
  {
    "value": "49221",
    "label": "Adrian - MI 49221",
    "county": "Lenawee County",
    "state": "MI",
    "city": "Adrian"
  },
  {
    "value": "49224",
    "label": "Albion - MI 49224",
    "county": "Calhoun County",
    "state": "MI",
    "city": "Albion"
  },
  {
    "value": "49227",
    "label": "Allen - MI 49227",
    "county": "Hillsdale County",
    "state": "MI",
    "city": "Allen"
  },
  {
    "value": "49228",
    "label": "Blissfield - MI 49228",
    "county": "Lenawee County",
    "state": "MI",
    "city": "Blissfield"
  },
  {
    "value": "49229",
    "label": "Britton - MI 49229",
    "county": "Lenawee County",
    "state": "MI",
    "city": "Britton"
  },
  {
    "value": "49230",
    "label": "Brooklyn - MI 49230",
    "county": "Jackson County",
    "state": "MI",
    "city": "Brooklyn"
  },
  {
    "value": "49232",
    "label": "Camden - MI 49232",
    "county": "Hillsdale County",
    "state": "MI",
    "city": "Camden"
  },
  {
    "value": "49233",
    "label": "Cement City - MI 49233",
    "county": "Hillsdale County",
    "state": "MI",
    "city": "Cement City"
  },
  {
    "value": "49234",
    "label": "Clarklake - MI 49234",
    "county": "Jackson County",
    "state": "MI",
    "city": "Clarklake"
  },
  {
    "value": "49235",
    "label": "Clayton - MI 49235",
    "county": "Lenawee County",
    "state": "MI",
    "city": "Clayton"
  },
  {
    "value": "49236",
    "label": "Clinton - MI 49236",
    "county": "Lenawee County",
    "state": "MI",
    "city": "Clinton"
  },
  {
    "value": "49237",
    "label": "Concord - MI 49237",
    "county": "Jackson County",
    "state": "MI",
    "city": "Concord"
  },
  {
    "value": "49238",
    "label": "Deerfield - MI 49238",
    "county": "Lenawee County",
    "state": "MI",
    "city": "Deerfield"
  },
  {
    "value": "49240",
    "label": "Grass Lake - MI 49240",
    "county": "Jackson County",
    "state": "MI",
    "city": "Grass Lake"
  },
  {
    "value": "49241",
    "label": "Hanover - MI 49241",
    "county": "Jackson County",
    "state": "MI",
    "city": "Hanover"
  },
  {
    "value": "49242",
    "label": "Hillsdale - MI 49242",
    "county": "Hillsdale County",
    "state": "MI",
    "city": "Hillsdale"
  },
  {
    "value": "49245",
    "label": "Homer - MI 49245",
    "county": "Calhoun County",
    "state": "MI",
    "city": "Homer"
  },
  {
    "value": "49246",
    "label": "Horton - MI 49246",
    "county": "Jackson County",
    "state": "MI",
    "city": "Horton"
  },
  {
    "value": "49247",
    "label": "Hudson - MI 49247",
    "county": "Lenawee County",
    "state": "MI",
    "city": "Hudson"
  },
  {
    "value": "49248",
    "label": "Jasper - MI 49248",
    "county": "Lenawee County",
    "state": "MI",
    "city": "Jasper"
  },
  {
    "value": "49249",
    "label": "Jerome - MI 49249",
    "county": "Hillsdale County",
    "state": "MI",
    "city": "Jerome"
  },
  {
    "value": "49250",
    "label": "Jonesville - MI 49250",
    "county": "Hillsdale County",
    "state": "MI",
    "city": "Jonesville"
  },
  {
    "value": "49251",
    "label": "Leslie - MI 49251",
    "county": "Ingham County",
    "state": "MI",
    "city": "Leslie"
  },
  {
    "value": "49252",
    "label": "Litchfield - MI 49252",
    "county": "Hillsdale County",
    "state": "MI",
    "city": "Litchfield"
  },
  {
    "value": "49253",
    "label": "Manitou Beach - MI 49253",
    "county": "Lenawee County",
    "state": "MI",
    "city": "Manitou Beach"
  },
  {
    "value": "49254",
    "label": "Michigan Center - MI 49254",
    "county": "Jackson County",
    "state": "MI",
    "city": "Michigan Center"
  },
  {
    "value": "49255",
    "label": "Montgomery - MI 49255",
    "county": "Branch County",
    "state": "MI",
    "city": "Montgomery"
  },
  {
    "value": "49256",
    "label": "Morenci - MI 49256",
    "county": "Lenawee County",
    "state": "MI",
    "city": "Morenci"
  },
  {
    "value": "49259",
    "label": "Munith - MI 49259",
    "county": "Jackson County",
    "state": "MI",
    "city": "Munith"
  },
  {
    "value": "49262",
    "label": "North Adams - MI 49262",
    "county": "Hillsdale County",
    "state": "MI",
    "city": "North Adams"
  },
  {
    "value": "49264",
    "label": "Onondaga - MI 49264",
    "county": "Ingham County",
    "state": "MI",
    "city": "Onondaga"
  },
  {
    "value": "49265",
    "label": "Onsted - MI 49265",
    "county": "Lenawee County",
    "state": "MI",
    "city": "Onsted"
  },
  {
    "value": "49266",
    "label": "Osseo - MI 49266",
    "county": "Hillsdale County",
    "state": "MI",
    "city": "Osseo"
  },
  {
    "value": "49267",
    "label": "Ottawa Lake - MI 49267",
    "county": "Monroe County",
    "state": "MI",
    "city": "Ottawa Lake"
  },
  {
    "value": "49268",
    "label": "Palmyra - MI 49268",
    "county": "Lenawee County",
    "state": "MI",
    "city": "Palmyra"
  },
  {
    "value": "49269",
    "label": "Parma - MI 49269",
    "county": "Jackson County",
    "state": "MI",
    "city": "Parma"
  },
  {
    "value": "49270",
    "label": "Petersburg - MI 49270",
    "county": "Monroe County",
    "state": "MI",
    "city": "Petersburg"
  },
  {
    "value": "49271",
    "label": "Pittsford - MI 49271",
    "county": "Hillsdale County",
    "state": "MI",
    "city": "Pittsford"
  },
  {
    "value": "49272",
    "label": "Pleasant Lake - MI 49272",
    "county": "Jackson County",
    "state": "MI",
    "city": "Pleasant Lake"
  },
  {
    "value": "49274",
    "label": "Reading - MI 49274",
    "county": "Hillsdale County",
    "state": "MI",
    "city": "Reading"
  },
  {
    "value": "49276",
    "label": "Riga - MI 49276",
    "county": "Lenawee County",
    "state": "MI",
    "city": "Riga"
  },
  {
    "value": "49277",
    "label": "Rives Junction - MI 49277",
    "county": "Jackson County",
    "state": "MI",
    "city": "Rives Junction"
  },
  {
    "value": "49279",
    "label": "Sand Creek - MI 49279",
    "county": "Lenawee County",
    "state": "MI",
    "city": "Sand Creek"
  },
  {
    "value": "49282",
    "label": "Somerset Center - MI 49282",
    "county": "Hillsdale County",
    "state": "MI",
    "city": "Somerset Center"
  },
  {
    "value": "49283",
    "label": "Spring Arbor - MI 49283",
    "county": "Jackson County",
    "state": "MI",
    "city": "Spring Arbor"
  },
  {
    "value": "49284",
    "label": "Springport - MI 49284",
    "county": "Jackson County",
    "state": "MI",
    "city": "Springport"
  },
  {
    "value": "49285",
    "label": "Stockbridge - MI 49285",
    "county": "Ingham County",
    "state": "MI",
    "city": "Stockbridge"
  },
  {
    "value": "49286",
    "label": "Tecumseh - MI 49286",
    "county": "Lenawee County",
    "state": "MI",
    "city": "Tecumseh"
  },
  {
    "value": "49287",
    "label": "Tipton - MI 49287",
    "county": "Lenawee County",
    "state": "MI",
    "city": "Tipton"
  },
  {
    "value": "49288",
    "label": "Waldron - MI 49288",
    "county": "Hillsdale County",
    "state": "MI",
    "city": "Waldron"
  },
  {
    "value": "49301",
    "label": "Ada - MI 49301",
    "county": "Kent County",
    "state": "MI",
    "city": "Ada"
  },
  {
    "value": "49302",
    "label": "Alto - MI 49302",
    "county": "Kent County",
    "state": "MI",
    "city": "Alto"
  },
  {
    "value": "49303",
    "label": "Bailey - MI 49303",
    "county": "Muskegon County",
    "state": "MI",
    "city": "Bailey"
  },
  {
    "value": "49304",
    "label": "Baldwin - MI 49304",
    "county": "Lake County",
    "state": "MI",
    "city": "Baldwin"
  },
  {
    "value": "49305",
    "label": "Barryton - MI 49305",
    "county": "Mecosta County",
    "state": "MI",
    "city": "Barryton"
  },
  {
    "value": "49306",
    "label": "Belmont - MI 49306",
    "county": "Kent County",
    "state": "MI",
    "city": "Belmont"
  },
  {
    "value": "49307",
    "label": "Big Rapids - MI 49307",
    "county": "Mecosta County",
    "state": "MI",
    "city": "Big Rapids"
  },
  {
    "value": "49309",
    "label": "Bitely - MI 49309",
    "county": "Newaygo County",
    "state": "MI",
    "city": "Bitely"
  },
  {
    "value": "49310",
    "label": "Blanchard - MI 49310",
    "county": "Isabella County",
    "state": "MI",
    "city": "Blanchard"
  },
  {
    "value": "49315",
    "label": "Byron Center - MI 49315",
    "county": "Kent County",
    "state": "MI",
    "city": "Byron Center"
  },
  {
    "value": "49316",
    "label": "Caledonia - MI 49316",
    "county": "Kent County",
    "state": "MI",
    "city": "Caledonia"
  },
  {
    "value": "49318",
    "label": "Casnovia - MI 49318",
    "county": "Muskegon County",
    "state": "MI",
    "city": "Casnovia"
  },
  {
    "value": "49319",
    "label": "Cedar Springs - MI 49319",
    "county": "Kent County",
    "state": "MI",
    "city": "Cedar Springs"
  },
  {
    "value": "49321",
    "label": "Comstock Park - MI 49321",
    "county": "Kent County",
    "state": "MI",
    "city": "Comstock Park"
  },
  {
    "value": "49322",
    "label": "Coral - MI 49322",
    "county": "Montcalm County",
    "state": "MI",
    "city": "Coral"
  },
  {
    "value": "49323",
    "label": "Dorr - MI 49323",
    "county": "Allegan County",
    "state": "MI",
    "city": "Dorr"
  },
  {
    "value": "49325",
    "label": "Freeport - MI 49325",
    "county": "Barry County",
    "state": "MI",
    "city": "Freeport"
  },
  {
    "value": "49326",
    "label": "Gowen - MI 49326",
    "county": "Kent County",
    "state": "MI",
    "city": "Gowen"
  },
  {
    "value": "49327",
    "label": "Grant - MI 49327",
    "county": "Newaygo County",
    "state": "MI",
    "city": "Grant"
  },
  {
    "value": "49328",
    "label": "Hopkins - MI 49328",
    "county": "Allegan County",
    "state": "MI",
    "city": "Hopkins"
  },
  {
    "value": "49329",
    "label": "Howard City - MI 49329",
    "county": "Montcalm County",
    "state": "MI",
    "city": "Howard City"
  },
  {
    "value": "49330",
    "label": "Kent City - MI 49330",
    "county": "Kent County",
    "state": "MI",
    "city": "Kent City"
  },
  {
    "value": "49331",
    "label": "Lowell - MI 49331",
    "county": "Kent County",
    "state": "MI",
    "city": "Lowell"
  },
  {
    "value": "49332",
    "label": "Mecosta - MI 49332",
    "county": "Mecosta County",
    "state": "MI",
    "city": "Mecosta"
  },
  {
    "value": "49333",
    "label": "Middleville - MI 49333",
    "county": "Barry County",
    "state": "MI",
    "city": "Middleville"
  },
  {
    "value": "49336",
    "label": "Morley - MI 49336",
    "county": "Mecosta County",
    "state": "MI",
    "city": "Morley"
  },
  {
    "value": "49337",
    "label": "Newaygo - MI 49337",
    "county": "Newaygo County",
    "state": "MI",
    "city": "Newaygo"
  },
  {
    "value": "49338",
    "label": "Paris - MI 49338",
    "county": "Mecosta County",
    "state": "MI",
    "city": "Paris"
  },
  {
    "value": "49339",
    "label": "Pierson - MI 49339",
    "county": "Montcalm County",
    "state": "MI",
    "city": "Pierson"
  },
  {
    "value": "49340",
    "label": "Remus - MI 49340",
    "county": "Mecosta County",
    "state": "MI",
    "city": "Remus"
  },
  {
    "value": "49341",
    "label": "Rockford - MI 49341",
    "county": "Kent County",
    "state": "MI",
    "city": "Rockford"
  },
  {
    "value": "49342",
    "label": "Rodney - MI 49342",
    "county": "Mecosta County",
    "state": "MI",
    "city": "Rodney"
  },
  {
    "value": "49343",
    "label": "Sand Lake - MI 49343",
    "county": "Kent County",
    "state": "MI",
    "city": "Sand Lake"
  },
  {
    "value": "49344",
    "label": "Shelbyville - MI 49344",
    "county": "Allegan County",
    "state": "MI",
    "city": "Shelbyville"
  },
  {
    "value": "49345",
    "label": "Sparta - MI 49345",
    "county": "Kent County",
    "state": "MI",
    "city": "Sparta"
  },
  {
    "value": "49346",
    "label": "Stanwood - MI 49346",
    "county": "Mecosta County",
    "state": "MI",
    "city": "Stanwood"
  },
  {
    "value": "49347",
    "label": "Trufant - MI 49347",
    "county": "Montcalm County",
    "state": "MI",
    "city": "Trufant"
  },
  {
    "value": "49348",
    "label": "Wayland - MI 49348",
    "county": "Allegan County",
    "state": "MI",
    "city": "Wayland"
  },
  {
    "value": "49349",
    "label": "White Cloud - MI 49349",
    "county": "Newaygo County",
    "state": "MI",
    "city": "White Cloud"
  },
  {
    "value": "49401",
    "label": "Allendale - MI 49401",
    "county": "Ottawa County",
    "state": "MI",
    "city": "Allendale"
  },
  {
    "value": "49402",
    "label": "Branch - MI 49402",
    "county": "Mason County",
    "state": "MI",
    "city": "Branch"
  },
  {
    "value": "49403",
    "label": "Conklin - MI 49403",
    "county": "Ottawa County",
    "state": "MI",
    "city": "Conklin"
  },
  {
    "value": "49404",
    "label": "Coopersville - MI 49404",
    "county": "Ottawa County",
    "state": "MI",
    "city": "Coopersville"
  },
  {
    "value": "49405",
    "label": "Custer - MI 49405",
    "county": "Mason County",
    "state": "MI",
    "city": "Custer"
  },
  {
    "value": "49408",
    "label": "Fennville - MI 49408",
    "county": "Allegan County",
    "state": "MI",
    "city": "Fennville"
  },
  {
    "value": "49410",
    "label": "Fountain - MI 49410",
    "county": "Mason County",
    "state": "MI",
    "city": "Fountain"
  },
  {
    "value": "49411",
    "label": "Free Soil - MI 49411",
    "county": "Mason County",
    "state": "MI",
    "city": "Free Soil"
  },
  {
    "value": "49412",
    "label": "Fremont - MI 49412",
    "county": "Newaygo County",
    "state": "MI",
    "city": "Fremont"
  },
  {
    "value": "49415",
    "label": "Fruitport - MI 49415",
    "county": "Muskegon County",
    "state": "MI",
    "city": "Fruitport"
  },
  {
    "value": "49417",
    "label": "Grand Haven - MI 49417",
    "county": "Ottawa County",
    "state": "MI",
    "city": "Grand Haven"
  },
  {
    "value": "49418",
    "label": "Grandville - MI 49418",
    "county": "Kent County",
    "state": "MI",
    "city": "Grandville"
  },
  {
    "value": "49419",
    "label": "Hamilton - MI 49419",
    "county": "Allegan County",
    "state": "MI",
    "city": "Hamilton"
  },
  {
    "value": "49420",
    "label": "Hart - MI 49420",
    "county": "Oceana County",
    "state": "MI",
    "city": "Hart"
  },
  {
    "value": "49421",
    "label": "Hesperia - MI 49421",
    "county": "Oceana County",
    "state": "MI",
    "city": "Hesperia"
  },
  {
    "value": "49423",
    "label": "Holland - MI 49423",
    "county": "Ottawa County",
    "state": "MI",
    "city": "Holland"
  },
  {
    "value": "49424",
    "label": "Holland - MI 49424",
    "county": "Ottawa County",
    "state": "MI",
    "city": "Holland"
  },
  {
    "value": "49425",
    "label": "Holton - MI 49425",
    "county": "Muskegon County",
    "state": "MI",
    "city": "Holton"
  },
  {
    "value": "49426",
    "label": "Hudsonville - MI 49426",
    "county": "Ottawa County",
    "state": "MI",
    "city": "Hudsonville"
  },
  {
    "value": "49428",
    "label": "Jenison - MI 49428",
    "county": "Ottawa County",
    "state": "MI",
    "city": "Jenison"
  },
  {
    "value": "49431",
    "label": "Ludington - MI 49431",
    "county": "Mason County",
    "state": "MI",
    "city": "Ludington"
  },
  {
    "value": "49435",
    "label": "Marne - MI 49435",
    "county": "Ottawa County",
    "state": "MI",
    "city": "Marne"
  },
  {
    "value": "49436",
    "label": "Mears - MI 49436",
    "county": "Oceana County",
    "state": "MI",
    "city": "Mears"
  },
  {
    "value": "49437",
    "label": "Montague - MI 49437",
    "county": "Muskegon County",
    "state": "MI",
    "city": "Montague"
  },
  {
    "value": "49440",
    "label": "Muskegon - MI 49440",
    "county": "Muskegon County",
    "state": "MI",
    "city": "Muskegon"
  },
  {
    "value": "49441",
    "label": "Muskegon - MI 49441",
    "county": "Muskegon County",
    "state": "MI",
    "city": "Muskegon"
  },
  {
    "value": "49442",
    "label": "Muskegon - MI 49442",
    "county": "Muskegon County",
    "state": "MI",
    "city": "Muskegon"
  },
  {
    "value": "49444",
    "label": "Muskegon - MI 49444",
    "county": "Muskegon County",
    "state": "MI",
    "city": "Muskegon"
  },
  {
    "value": "49445",
    "label": "Muskegon - MI 49445",
    "county": "Muskegon County",
    "state": "MI",
    "city": "Muskegon"
  },
  {
    "value": "49446",
    "label": "New Era - MI 49446",
    "county": "Oceana County",
    "state": "MI",
    "city": "New Era"
  },
  {
    "value": "49448",
    "label": "Nunica - MI 49448",
    "county": "Ottawa County",
    "state": "MI",
    "city": "Nunica"
  },
  {
    "value": "49449",
    "label": "Pentwater - MI 49449",
    "county": "Oceana County",
    "state": "MI",
    "city": "Pentwater"
  },
  {
    "value": "49450",
    "label": "Pullman - MI 49450",
    "county": "Allegan County",
    "state": "MI",
    "city": "Pullman"
  },
  {
    "value": "49451",
    "label": "Ravenna - MI 49451",
    "county": "Muskegon County",
    "state": "MI",
    "city": "Ravenna"
  },
  {
    "value": "49452",
    "label": "Rothbury - MI 49452",
    "county": "Oceana County",
    "state": "MI",
    "city": "Rothbury"
  },
  {
    "value": "49453",
    "label": "Saugatuck - MI 49453",
    "county": "Allegan County",
    "state": "MI",
    "city": "Saugatuck"
  },
  {
    "value": "49454",
    "label": "Scottville - MI 49454",
    "county": "Mason County",
    "state": "MI",
    "city": "Scottville"
  },
  {
    "value": "49455",
    "label": "Shelby - MI 49455",
    "county": "Oceana County",
    "state": "MI",
    "city": "Shelby"
  },
  {
    "value": "49456",
    "label": "Spring Lake - MI 49456",
    "county": "Ottawa County",
    "state": "MI",
    "city": "Spring Lake"
  },
  {
    "value": "49457",
    "label": "Twin Lake - MI 49457",
    "county": "Muskegon County",
    "state": "MI",
    "city": "Twin Lake"
  },
  {
    "value": "49459",
    "label": "Walkerville - MI 49459",
    "county": "Oceana County",
    "state": "MI",
    "city": "Walkerville"
  },
  {
    "value": "49460",
    "label": "West Olive - MI 49460",
    "county": "Ottawa County",
    "state": "MI",
    "city": "West Olive"
  },
  {
    "value": "49461",
    "label": "Whitehall - MI 49461",
    "county": "Muskegon County",
    "state": "MI",
    "city": "Whitehall"
  },
  {
    "value": "49464",
    "label": "Zeeland - MI 49464",
    "county": "Ottawa County",
    "state": "MI",
    "city": "Zeeland"
  },
  {
    "value": "49503",
    "label": "Grand Rapids - MI 49503",
    "county": "Kent County",
    "state": "MI",
    "city": "Grand Rapids"
  },
  {
    "value": "49504",
    "label": "Grand Rapids - MI 49504",
    "county": "Kent County",
    "state": "MI",
    "city": "Grand Rapids"
  },
  {
    "value": "49505",
    "label": "Grand Rapids - MI 49505",
    "county": "Kent County",
    "state": "MI",
    "city": "Grand Rapids"
  },
  {
    "value": "49506",
    "label": "Grand Rapids - MI 49506",
    "county": "Kent County",
    "state": "MI",
    "city": "Grand Rapids"
  },
  {
    "value": "49507",
    "label": "Grand Rapids - MI 49507",
    "county": "Kent County",
    "state": "MI",
    "city": "Grand Rapids"
  },
  {
    "value": "49508",
    "label": "Grand Rapids - MI 49508",
    "county": "Kent County",
    "state": "MI",
    "city": "Grand Rapids"
  },
  {
    "value": "49509",
    "label": "Wyoming - MI 49509",
    "county": "Kent County",
    "state": "MI",
    "city": "Wyoming"
  },
  {
    "value": "49512",
    "label": "Grand Rapids - MI 49512",
    "county": "Kent County",
    "state": "MI",
    "city": "Grand Rapids"
  },
  {
    "value": "49519",
    "label": "Wyoming - MI 49519",
    "county": "Kent County",
    "state": "MI",
    "city": "Wyoming"
  },
  {
    "value": "49525",
    "label": "Grand Rapids - MI 49525",
    "county": "Kent County",
    "state": "MI",
    "city": "Grand Rapids"
  },
  {
    "value": "49534",
    "label": "Grand Rapids - MI 49534",
    "county": "Kent County",
    "state": "MI",
    "city": "Grand Rapids"
  },
  {
    "value": "49544",
    "label": "Grand Rapids - MI 49544",
    "county": "Kent County",
    "state": "MI",
    "city": "Grand Rapids"
  },
  {
    "value": "49546",
    "label": "Grand Rapids - MI 49546",
    "county": "Kent County",
    "state": "MI",
    "city": "Grand Rapids"
  },
  {
    "value": "49548",
    "label": "Grand Rapids - MI 49548",
    "county": "Kent County",
    "state": "MI",
    "city": "Grand Rapids"
  },
  {
    "value": "49601",
    "label": "Cadillac - MI 49601",
    "county": "Wexford County",
    "state": "MI",
    "city": "Cadillac"
  },
  {
    "value": "49612",
    "label": "Alden - MI 49612",
    "county": "Antrim County",
    "state": "MI",
    "city": "Alden"
  },
  {
    "value": "49613",
    "label": "Arcadia - MI 49613",
    "county": "Manistee County",
    "state": "MI",
    "city": "Arcadia"
  },
  {
    "value": "49614",
    "label": "Bear Lake - MI 49614",
    "county": "Manistee County",
    "state": "MI",
    "city": "Bear Lake"
  },
  {
    "value": "49615",
    "label": "Bellaire - MI 49615",
    "county": "Antrim County",
    "state": "MI",
    "city": "Bellaire"
  },
  {
    "value": "49616",
    "label": "Benzonia - MI 49616",
    "county": "Benzie County",
    "state": "MI",
    "city": "Benzonia"
  },
  {
    "value": "49617",
    "label": "Beulah - MI 49617",
    "county": "Benzie County",
    "state": "MI",
    "city": "Beulah"
  },
  {
    "value": "49618",
    "label": "Boon - MI 49618",
    "county": "Wexford County",
    "state": "MI",
    "city": "Boon"
  },
  {
    "value": "49619",
    "label": "Brethren - MI 49619",
    "county": "Manistee County",
    "state": "MI",
    "city": "Brethren"
  },
  {
    "value": "49620",
    "label": "Buckley - MI 49620",
    "county": "Wexford County",
    "state": "MI",
    "city": "Buckley"
  },
  {
    "value": "49621",
    "label": "Cedar - MI 49621",
    "county": "Leelanau County",
    "state": "MI",
    "city": "Cedar"
  },
  {
    "value": "49622",
    "label": "Central Lake - MI 49622",
    "county": "Antrim County",
    "state": "MI",
    "city": "Central Lake"
  },
  {
    "value": "49623",
    "label": "Chase - MI 49623",
    "county": "Lake County",
    "state": "MI",
    "city": "Chase"
  },
  {
    "value": "49625",
    "label": "Copemish - MI 49625",
    "county": "Manistee County",
    "state": "MI",
    "city": "Copemish"
  },
  {
    "value": "49629",
    "label": "Elk Rapids - MI 49629",
    "county": "Antrim County",
    "state": "MI",
    "city": "Elk Rapids"
  },
  {
    "value": "49630",
    "label": "Empire - MI 49630",
    "county": "Leelanau County",
    "state": "MI",
    "city": "Empire"
  },
  {
    "value": "49631",
    "label": "Evart - MI 49631",
    "county": "Osceola County",
    "state": "MI",
    "city": "Evart"
  },
  {
    "value": "49632",
    "label": "Falmouth - MI 49632",
    "county": "Missaukee County",
    "state": "MI",
    "city": "Falmouth"
  },
  {
    "value": "49633",
    "label": "Fife Lake - MI 49633",
    "county": "Kalkaska County",
    "state": "MI",
    "city": "Fife Lake"
  },
  {
    "value": "49635",
    "label": "Frankfort - MI 49635",
    "county": "Benzie County",
    "state": "MI",
    "city": "Frankfort"
  },
  {
    "value": "49636",
    "label": "Glen Arbor - MI 49636",
    "county": "Leelanau County",
    "state": "MI",
    "city": "Glen Arbor"
  },
  {
    "value": "49637",
    "label": "Grawn - MI 49637",
    "county": "Grand Traverse County",
    "state": "MI",
    "city": "Grawn"
  },
  {
    "value": "49638",
    "label": "Harrietta - MI 49638",
    "county": "Wexford County",
    "state": "MI",
    "city": "Harrietta"
  },
  {
    "value": "49639",
    "label": "Hersey - MI 49639",
    "county": "Osceola County",
    "state": "MI",
    "city": "Hersey"
  },
  {
    "value": "49640",
    "label": "Honor - MI 49640",
    "county": "Benzie County",
    "state": "MI",
    "city": "Honor"
  },
  {
    "value": "49642",
    "label": "Idlewild - MI 49642",
    "county": "Lake County",
    "state": "MI",
    "city": "Idlewild"
  },
  {
    "value": "49643",
    "label": "Interlochen - MI 49643",
    "county": "Grand Traverse County",
    "state": "MI",
    "city": "Interlochen"
  },
  {
    "value": "49644",
    "label": "Irons - MI 49644",
    "county": "Lake County",
    "state": "MI",
    "city": "Irons"
  },
  {
    "value": "49645",
    "label": "Kaleva - MI 49645",
    "county": "Manistee County",
    "state": "MI",
    "city": "Kaleva"
  },
  {
    "value": "49646",
    "label": "Kalkaska - MI 49646",
    "county": "Kalkaska County",
    "state": "MI",
    "city": "Kalkaska"
  },
  {
    "value": "49648",
    "label": "Kewadin - MI 49648",
    "county": "Antrim County",
    "state": "MI",
    "city": "Kewadin"
  },
  {
    "value": "49649",
    "label": "Kingsley - MI 49649",
    "county": "Grand Traverse County",
    "state": "MI",
    "city": "Kingsley"
  },
  {
    "value": "49650",
    "label": "Lake Ann - MI 49650",
    "county": "Benzie County",
    "state": "MI",
    "city": "Lake Ann"
  },
  {
    "value": "49651",
    "label": "Lake City - MI 49651",
    "county": "Missaukee County",
    "state": "MI",
    "city": "Lake City"
  },
  {
    "value": "49653",
    "label": "Lake Leelanau - MI 49653",
    "county": "Leelanau County",
    "state": "MI",
    "city": "Lake Leelanau"
  },
  {
    "value": "49654",
    "label": "Leland - MI 49654",
    "county": "Leelanau County",
    "state": "MI",
    "city": "Leland"
  },
  {
    "value": "49655",
    "label": "Leroy - MI 49655",
    "county": "Osceola County",
    "state": "MI",
    "city": "Leroy"
  },
  {
    "value": "49656",
    "label": "Luther - MI 49656",
    "county": "Lake County",
    "state": "MI",
    "city": "Luther"
  },
  {
    "value": "49657",
    "label": "Mc Bain - MI 49657",
    "county": "Missaukee County",
    "state": "MI",
    "city": "Mc Bain"
  },
  {
    "value": "49659",
    "label": "Mancelona - MI 49659",
    "county": "Antrim County",
    "state": "MI",
    "city": "Mancelona"
  },
  {
    "value": "49660",
    "label": "Manistee - MI 49660",
    "county": "Manistee County",
    "state": "MI",
    "city": "Manistee"
  },
  {
    "value": "49663",
    "label": "Manton - MI 49663",
    "county": "Wexford County",
    "state": "MI",
    "city": "Manton"
  },
  {
    "value": "49664",
    "label": "Maple City - MI 49664",
    "county": "Leelanau County",
    "state": "MI",
    "city": "Maple City"
  },
  {
    "value": "49665",
    "label": "Marion - MI 49665",
    "county": "Osceola County",
    "state": "MI",
    "city": "Marion"
  },
  {
    "value": "49667",
    "label": "Merritt - MI 49667",
    "county": "Missaukee County",
    "state": "MI",
    "city": "Merritt"
  },
  {
    "value": "49668",
    "label": "Mesick - MI 49668",
    "county": "Wexford County",
    "state": "MI",
    "city": "Mesick"
  },
  {
    "value": "49670",
    "label": "Northport - MI 49670",
    "county": "Leelanau County",
    "state": "MI",
    "city": "Northport"
  },
  {
    "value": "49675",
    "label": "Onekama - MI 49675",
    "county": "Manistee County",
    "state": "MI",
    "city": "Onekama"
  },
  {
    "value": "49676",
    "label": "Rapid City - MI 49676",
    "county": "Kalkaska County",
    "state": "MI",
    "city": "Rapid City"
  },
  {
    "value": "49677",
    "label": "Reed City - MI 49677",
    "county": "Osceola County",
    "state": "MI",
    "city": "Reed City"
  },
  {
    "value": "49679",
    "label": "Sears - MI 49679",
    "county": "Osceola County",
    "state": "MI",
    "city": "Sears"
  },
  {
    "value": "49680",
    "label": "South Boardman - MI 49680",
    "county": "Kalkaska County",
    "state": "MI",
    "city": "South Boardman"
  },
  {
    "value": "49682",
    "label": "Suttons Bay - MI 49682",
    "county": "Leelanau County",
    "state": "MI",
    "city": "Suttons Bay"
  },
  {
    "value": "49683",
    "label": "Thompsonville - MI 49683",
    "county": "Benzie County",
    "state": "MI",
    "city": "Thompsonville"
  },
  {
    "value": "49684",
    "label": "Traverse City - MI 49684",
    "county": "Grand Traverse County",
    "state": "MI",
    "city": "Traverse City"
  },
  {
    "value": "49685",
    "label": "Traverse City - MI 49685",
    "county": "Grand Traverse County",
    "state": "MI",
    "city": "Traverse City"
  },
  {
    "value": "49686",
    "label": "Traverse City - MI 49686",
    "county": "Grand Traverse County",
    "state": "MI",
    "city": "Traverse City"
  },
  {
    "value": "49688",
    "label": "Tustin - MI 49688",
    "county": "Osceola County",
    "state": "MI",
    "city": "Tustin"
  },
  {
    "value": "49689",
    "label": "Wellston - MI 49689",
    "county": "Manistee County",
    "state": "MI",
    "city": "Wellston"
  },
  {
    "value": "49690",
    "label": "Williamsburg - MI 49690",
    "county": "Grand Traverse County",
    "state": "MI",
    "city": "Williamsburg"
  },
  {
    "value": "49696",
    "label": "Traverse City - MI 49696",
    "county": "Grand Traverse County",
    "state": "MI",
    "city": "Traverse City"
  },
  {
    "value": "49701",
    "label": "Mackinaw City - MI 49701",
    "county": "Emmet County",
    "state": "MI",
    "city": "Mackinaw City"
  },
  {
    "value": "49705",
    "label": "Afton - MI 49705",
    "county": "Cheboygan County",
    "state": "MI",
    "city": "Afton"
  },
  {
    "value": "49706",
    "label": "Alanson - MI 49706",
    "county": "Emmet County",
    "state": "MI",
    "city": "Alanson"
  },
  {
    "value": "49707",
    "label": "Alpena - MI 49707",
    "county": "Alpena County",
    "state": "MI",
    "city": "Alpena"
  },
  {
    "value": "49709",
    "label": "Atlanta - MI 49709",
    "county": "Montmorency County",
    "state": "MI",
    "city": "Atlanta"
  },
  {
    "value": "49710",
    "label": "Barbeau - MI 49710",
    "county": "Chippewa County",
    "state": "MI",
    "city": "Barbeau"
  },
  {
    "value": "49712",
    "label": "Boyne City - MI 49712",
    "county": "Charlevoix County",
    "state": "MI",
    "city": "Boyne City"
  },
  {
    "value": "49713",
    "label": "Boyne Falls - MI 49713",
    "county": "Charlevoix County",
    "state": "MI",
    "city": "Boyne Falls"
  },
  {
    "value": "49715",
    "label": "Brimley - MI 49715",
    "county": "Chippewa County",
    "state": "MI",
    "city": "Brimley"
  },
  {
    "value": "49716",
    "label": "Brutus - MI 49716",
    "county": "Emmet County",
    "state": "MI",
    "city": "Brutus"
  },
  {
    "value": "49718",
    "label": "Carp Lake - MI 49718",
    "county": "Emmet County",
    "state": "MI",
    "city": "Carp Lake"
  },
  {
    "value": "49719",
    "label": "Cedarville - MI 49719",
    "county": "Mackinac County",
    "state": "MI",
    "city": "Cedarville"
  },
  {
    "value": "49720",
    "label": "Charlevoix - MI 49720",
    "county": "Charlevoix County",
    "state": "MI",
    "city": "Charlevoix"
  },
  {
    "value": "49721",
    "label": "Cheboygan - MI 49721",
    "county": "Cheboygan County",
    "state": "MI",
    "city": "Cheboygan"
  },
  {
    "value": "49724",
    "label": "Dafter - MI 49724",
    "county": "Chippewa County",
    "state": "MI",
    "city": "Dafter"
  },
  {
    "value": "49725",
    "label": "De Tour Village - MI 49725",
    "county": "Chippewa County",
    "state": "MI",
    "city": "De Tour Village"
  },
  {
    "value": "49726",
    "label": "Drummond Island - MI 49726",
    "county": "Chippewa County",
    "state": "MI",
    "city": "Drummond Island"
  },
  {
    "value": "49727",
    "label": "East Jordan - MI 49727",
    "county": "Charlevoix County",
    "state": "MI",
    "city": "East Jordan"
  },
  {
    "value": "49728",
    "label": "Eckerman - MI 49728",
    "county": "Chippewa County",
    "state": "MI",
    "city": "Eckerman"
  },
  {
    "value": "49729",
    "label": "Ellsworth - MI 49729",
    "county": "Antrim County",
    "state": "MI",
    "city": "Ellsworth"
  },
  {
    "value": "49730",
    "label": "Elmira - MI 49730",
    "county": "Antrim County",
    "state": "MI",
    "city": "Elmira"
  },
  {
    "value": "49733",
    "label": "Frederic - MI 49733",
    "county": "Crawford County",
    "state": "MI",
    "city": "Frederic"
  },
  {
    "value": "49735",
    "label": "Gaylord - MI 49735",
    "county": "Otsego County",
    "state": "MI",
    "city": "Gaylord"
  },
  {
    "value": "49736",
    "label": "Goetzville - MI 49736",
    "county": "Chippewa County",
    "state": "MI",
    "city": "Goetzville"
  },
  {
    "value": "49738",
    "label": "Grayling - MI 49738",
    "county": "Crawford County",
    "state": "MI",
    "city": "Grayling"
  },
  {
    "value": "49740",
    "label": "Harbor Springs - MI 49740",
    "county": "Emmet County",
    "state": "MI",
    "city": "Harbor Springs"
  },
  {
    "value": "49743",
    "label": "Hawks - MI 49743",
    "county": "Presque Isle County",
    "state": "MI",
    "city": "Hawks"
  },
  {
    "value": "49744",
    "label": "Herron - MI 49744",
    "county": "Alpena County",
    "state": "MI",
    "city": "Herron"
  },
  {
    "value": "49745",
    "label": "Hessel - MI 49745",
    "county": "Mackinac County",
    "state": "MI",
    "city": "Hessel"
  },
  {
    "value": "49746",
    "label": "Hillman - MI 49746",
    "county": "Montmorency County",
    "state": "MI",
    "city": "Hillman"
  },
  {
    "value": "49747",
    "label": "Hubbard Lake - MI 49747",
    "county": "Alpena County",
    "state": "MI",
    "city": "Hubbard Lake"
  },
  {
    "value": "49749",
    "label": "Indian River - MI 49749",
    "county": "Cheboygan County",
    "state": "MI",
    "city": "Indian River"
  },
  {
    "value": "49751",
    "label": "Johannesburg - MI 49751",
    "county": "Otsego County",
    "state": "MI",
    "city": "Johannesburg"
  },
  {
    "value": "49752",
    "label": "Kinross - MI 49752",
    "county": "Chippewa County",
    "state": "MI",
    "city": "Kinross"
  },
  {
    "value": "49753",
    "label": "Lachine - MI 49753",
    "county": "Alpena County",
    "state": "MI",
    "city": "Lachine"
  },
  {
    "value": "49755",
    "label": "Levering - MI 49755",
    "county": "Emmet County",
    "state": "MI",
    "city": "Levering"
  },
  {
    "value": "49756",
    "label": "Lewiston - MI 49756",
    "county": "Montmorency County",
    "state": "MI",
    "city": "Lewiston"
  },
  {
    "value": "49759",
    "label": "Millersburg - MI 49759",
    "county": "Presque Isle County",
    "state": "MI",
    "city": "Millersburg"
  },
  {
    "value": "49760",
    "label": "Moran - MI 49760",
    "county": "Mackinac County",
    "state": "MI",
    "city": "Moran"
  },
  {
    "value": "49762",
    "label": "Naubinway - MI 49762",
    "county": "Mackinac County",
    "state": "MI",
    "city": "Naubinway"
  },
  {
    "value": "49765",
    "label": "Onaway - MI 49765",
    "county": "Presque Isle County",
    "state": "MI",
    "city": "Onaway"
  },
  {
    "value": "49766",
    "label": "Ossineke - MI 49766",
    "county": "Alpena County",
    "state": "MI",
    "city": "Ossineke"
  },
  {
    "value": "49768",
    "label": "Paradise - MI 49768",
    "county": "Chippewa County",
    "state": "MI",
    "city": "Paradise"
  },
  {
    "value": "49769",
    "label": "Pellston - MI 49769",
    "county": "Emmet County",
    "state": "MI",
    "city": "Pellston"
  },
  {
    "value": "49770",
    "label": "Petoskey - MI 49770",
    "county": "Emmet County",
    "state": "MI",
    "city": "Petoskey"
  },
  {
    "value": "49774",
    "label": "Pickford - MI 49774",
    "county": "Chippewa County",
    "state": "MI",
    "city": "Pickford"
  },
  {
    "value": "49775",
    "label": "Pointe Aux Pins - MI 49775",
    "county": "Mackinac County",
    "state": "MI",
    "city": "Pointe Aux Pins"
  },
  {
    "value": "49776",
    "label": "Posen - MI 49776",
    "county": "Presque Isle County",
    "state": "MI",
    "city": "Posen"
  },
  {
    "value": "49777",
    "label": "Presque Isle - MI 49777",
    "county": "Presque Isle County",
    "state": "MI",
    "city": "Presque Isle"
  },
  {
    "value": "49779",
    "label": "Rogers City - MI 49779",
    "county": "Presque Isle County",
    "state": "MI",
    "city": "Rogers City"
  },
  {
    "value": "49780",
    "label": "Rudyard - MI 49780",
    "county": "Chippewa County",
    "state": "MI",
    "city": "Rudyard"
  },
  {
    "value": "49781",
    "label": "Saint Ignace - MI 49781",
    "county": "Mackinac County",
    "state": "MI",
    "city": "Saint Ignace"
  },
  {
    "value": "49782",
    "label": "Beaver Island - MI 49782",
    "county": "Charlevoix County",
    "state": "MI",
    "city": "Beaver Island"
  },
  {
    "value": "49783",
    "label": "Sault Sainte Marie - MI 49783",
    "county": "Chippewa County",
    "state": "MI",
    "city": "Sault Sainte Marie"
  },
  {
    "value": "49788",
    "label": "Kincheloe - MI 49788",
    "county": "Chippewa County",
    "state": "MI",
    "city": "Kincheloe"
  },
  {
    "value": "49790",
    "label": "Eckerman - MI 49790",
    "county": "Chippewa County",
    "state": "MI",
    "city": "Eckerman"
  },
  {
    "value": "49795",
    "label": "Vanderbilt - MI 49795",
    "county": "Otsego County",
    "state": "MI",
    "city": "Vanderbilt"
  },
  {
    "value": "49799",
    "label": "Wolverine - MI 49799",
    "county": "Cheboygan County",
    "state": "MI",
    "city": "Wolverine"
  },
  {
    "value": "49801",
    "label": "Iron Mountain - MI 49801",
    "county": "Dickinson County",
    "state": "MI",
    "city": "Iron Mountain"
  },
  {
    "value": "49802",
    "label": "Kingsford - MI 49802",
    "county": "Dickinson County",
    "state": "MI",
    "city": "Kingsford"
  },
  {
    "value": "49805",
    "label": "Allouez - MI 49805",
    "county": "Keweenaw County",
    "state": "MI",
    "city": "Allouez"
  },
  {
    "value": "49806",
    "label": "Au Train - MI 49806",
    "county": "Alger County",
    "state": "MI",
    "city": "Au Train"
  },
  {
    "value": "49807",
    "label": "Bark River - MI 49807",
    "county": "Delta County",
    "state": "MI",
    "city": "Bark River"
  },
  {
    "value": "49812",
    "label": "Carney - MI 49812",
    "county": "Menominee County",
    "state": "MI",
    "city": "Carney"
  },
  {
    "value": "49814",
    "label": "Champion - MI 49814",
    "county": "Marquette County",
    "state": "MI",
    "city": "Champion"
  },
  {
    "value": "49815",
    "label": "Channing - MI 49815",
    "county": "Dickinson County",
    "state": "MI",
    "city": "Channing"
  },
  {
    "value": "49816",
    "label": "Chatham - MI 49816",
    "county": "Alger County",
    "state": "MI",
    "city": "Chatham"
  },
  {
    "value": "49817",
    "label": "Cooks - MI 49817",
    "county": "Schoolcraft County",
    "state": "MI",
    "city": "Cooks"
  },
  {
    "value": "49818",
    "label": "Cornell - MI 49818",
    "county": "Delta County",
    "state": "MI",
    "city": "Cornell"
  },
  {
    "value": "49820",
    "label": "Curtis - MI 49820",
    "county": "Mackinac County",
    "state": "MI",
    "city": "Curtis"
  },
  {
    "value": "49821",
    "label": "Daggett - MI 49821",
    "county": "Menominee County",
    "state": "MI",
    "city": "Daggett"
  },
  {
    "value": "49822",
    "label": "Deerton - MI 49822",
    "county": "Alger County",
    "state": "MI",
    "city": "Deerton"
  },
  {
    "value": "49827",
    "label": "Engadine - MI 49827",
    "county": "Mackinac County",
    "state": "MI",
    "city": "Engadine"
  },
  {
    "value": "49829",
    "label": "Escanaba - MI 49829",
    "county": "Delta County",
    "state": "MI",
    "city": "Escanaba"
  },
  {
    "value": "49831",
    "label": "Felch - MI 49831",
    "county": "Dickinson County",
    "state": "MI",
    "city": "Felch"
  },
  {
    "value": "49834",
    "label": "Foster City - MI 49834",
    "county": "Dickinson County",
    "state": "MI",
    "city": "Foster City"
  },
  {
    "value": "49835",
    "label": "Garden - MI 49835",
    "county": "Delta County",
    "state": "MI",
    "city": "Garden"
  },
  {
    "value": "49836",
    "label": "Germfask - MI 49836",
    "county": "Schoolcraft County",
    "state": "MI",
    "city": "Germfask"
  },
  {
    "value": "49837",
    "label": "Gladstone - MI 49837",
    "county": "Delta County",
    "state": "MI",
    "city": "Gladstone"
  },
  {
    "value": "49838",
    "label": "Gould City - MI 49838",
    "county": "Mackinac County",
    "state": "MI",
    "city": "Gould City"
  },
  {
    "value": "49840",
    "label": "Gulliver - MI 49840",
    "county": "Schoolcraft County",
    "state": "MI",
    "city": "Gulliver"
  },
  {
    "value": "49841",
    "label": "Gwinn - MI 49841",
    "county": "Marquette County",
    "state": "MI",
    "city": "Gwinn"
  },
  {
    "value": "49847",
    "label": "Hermansville - MI 49847",
    "county": "Menominee County",
    "state": "MI",
    "city": "Hermansville"
  },
  {
    "value": "49848",
    "label": "Ingalls - MI 49848",
    "county": "Menominee County",
    "state": "MI",
    "city": "Ingalls"
  },
  {
    "value": "49849",
    "label": "Ishpeming - MI 49849",
    "county": "Marquette County",
    "state": "MI",
    "city": "Ishpeming"
  },
  {
    "value": "49852",
    "label": "Loretto - MI 49852",
    "county": "Dickinson County",
    "state": "MI",
    "city": "Loretto"
  },
  {
    "value": "49853",
    "label": "Mc Millan - MI 49853",
    "county": "Luce County",
    "state": "MI",
    "city": "Mc Millan"
  },
  {
    "value": "49854",
    "label": "Manistique - MI 49854",
    "county": "Schoolcraft County",
    "state": "MI",
    "city": "Manistique"
  },
  {
    "value": "49855",
    "label": "Marquette - MI 49855",
    "county": "Marquette County",
    "state": "MI",
    "city": "Marquette"
  },
  {
    "value": "49858",
    "label": "Menominee - MI 49858",
    "county": "Menominee County",
    "state": "MI",
    "city": "Menominee"
  },
  {
    "value": "49861",
    "label": "Michigamme - MI 49861",
    "county": "Marquette County",
    "state": "MI",
    "city": "Michigamme"
  },
  {
    "value": "49862",
    "label": "Munising - MI 49862",
    "county": "Alger County",
    "state": "MI",
    "city": "Munising"
  },
  {
    "value": "49866",
    "label": "Negaunee - MI 49866",
    "county": "Marquette County",
    "state": "MI",
    "city": "Negaunee"
  },
  {
    "value": "49868",
    "label": "Newberry - MI 49868",
    "county": "Luce County",
    "state": "MI",
    "city": "Newberry"
  },
  {
    "value": "49870",
    "label": "Norway - MI 49870",
    "county": "Dickinson County",
    "state": "MI",
    "city": "Norway"
  },
  {
    "value": "49873",
    "label": "Perronville - MI 49873",
    "county": "Menominee County",
    "state": "MI",
    "city": "Perronville"
  },
  {
    "value": "49874",
    "label": "Powers - MI 49874",
    "county": "Menominee County",
    "state": "MI",
    "city": "Powers"
  },
  {
    "value": "49876",
    "label": "Quinnesec - MI 49876",
    "county": "Dickinson County",
    "state": "MI",
    "city": "Quinnesec"
  },
  {
    "value": "49878",
    "label": "Rapid River - MI 49878",
    "county": "Delta County",
    "state": "MI",
    "city": "Rapid River"
  },
  {
    "value": "49879",
    "label": "Republic - MI 49879",
    "county": "Marquette County",
    "state": "MI",
    "city": "Republic"
  },
  {
    "value": "49880",
    "label": "Rock - MI 49880",
    "county": "Delta County",
    "state": "MI",
    "city": "Rock"
  },
  {
    "value": "49881",
    "label": "Sagola - MI 49881",
    "county": "Dickinson County",
    "state": "MI",
    "city": "Sagola"
  },
  {
    "value": "49883",
    "label": "Seney - MI 49883",
    "county": "Schoolcraft County",
    "state": "MI",
    "city": "Seney"
  },
  {
    "value": "49884",
    "label": "Shingleton - MI 49884",
    "county": "Alger County",
    "state": "MI",
    "city": "Shingleton"
  },
  {
    "value": "49885",
    "label": "Skandia - MI 49885",
    "county": "Marquette County",
    "state": "MI",
    "city": "Skandia"
  },
  {
    "value": "49886",
    "label": "Spalding - MI 49886",
    "county": "Menominee County",
    "state": "MI",
    "city": "Spalding"
  },
  {
    "value": "49887",
    "label": "Stephenson - MI 49887",
    "county": "Menominee County",
    "state": "MI",
    "city": "Stephenson"
  },
  {
    "value": "49891",
    "label": "Trenary - MI 49891",
    "county": "Alger County",
    "state": "MI",
    "city": "Trenary"
  },
  {
    "value": "49892",
    "label": "Vulcan - MI 49892",
    "county": "Dickinson County",
    "state": "MI",
    "city": "Vulcan"
  },
  {
    "value": "49893",
    "label": "Wallace - MI 49893",
    "county": "Menominee County",
    "state": "MI",
    "city": "Wallace"
  },
  {
    "value": "49894",
    "label": "Wells - MI 49894",
    "county": "Delta County",
    "state": "MI",
    "city": "Wells"
  },
  {
    "value": "49895",
    "label": "Wetmore - MI 49895",
    "county": "Alger County",
    "state": "MI",
    "city": "Wetmore"
  },
  {
    "value": "49896",
    "label": "Wilson - MI 49896",
    "county": "Menominee County",
    "state": "MI",
    "city": "Wilson"
  },
  {
    "value": "49905",
    "label": "Atlantic Mine - MI 49905",
    "county": "Houghton County",
    "state": "MI",
    "city": "Atlantic Mine"
  },
  {
    "value": "49908",
    "label": "Baraga - MI 49908",
    "county": "Baraga County",
    "state": "MI",
    "city": "Baraga"
  },
  {
    "value": "49910",
    "label": "Bergland - MI 49910",
    "county": "Ontonagon County",
    "state": "MI",
    "city": "Bergland"
  },
  {
    "value": "49911",
    "label": "Bessemer - MI 49911",
    "county": "Gogebic County",
    "state": "MI",
    "city": "Bessemer"
  },
  {
    "value": "49912",
    "label": "Bruce Crossing - MI 49912",
    "county": "Ontonagon County",
    "state": "MI",
    "city": "Bruce Crossing"
  },
  {
    "value": "49913",
    "label": "Calumet - MI 49913",
    "county": "Houghton County",
    "state": "MI",
    "city": "Calumet"
  },
  {
    "value": "49916",
    "label": "Chassell - MI 49916",
    "county": "Houghton County",
    "state": "MI",
    "city": "Chassell"
  },
  {
    "value": "49919",
    "label": "Covington - MI 49919",
    "county": "Baraga County",
    "state": "MI",
    "city": "Covington"
  },
  {
    "value": "49920",
    "label": "Crystal Falls - MI 49920",
    "county": "Iron County",
    "state": "MI",
    "city": "Crystal Falls"
  },
  {
    "value": "49921",
    "label": "Dodgeville - MI 49921",
    "county": "Houghton County",
    "state": "MI",
    "city": "Dodgeville"
  },
  {
    "value": "49925",
    "label": "Ewen - MI 49925",
    "county": "Ontonagon County",
    "state": "MI",
    "city": "Ewen"
  },
  {
    "value": "49927",
    "label": "Gaastra - MI 49927",
    "county": "Iron County",
    "state": "MI",
    "city": "Gaastra"
  },
  {
    "value": "49930",
    "label": "Hancock - MI 49930",
    "county": "Houghton County",
    "state": "MI",
    "city": "Hancock"
  },
  {
    "value": "49931",
    "label": "Houghton - MI 49931",
    "county": "Houghton County",
    "state": "MI",
    "city": "Houghton"
  },
  {
    "value": "49935",
    "label": "Iron River - MI 49935",
    "county": "Iron County",
    "state": "MI",
    "city": "Iron River"
  },
  {
    "value": "49938",
    "label": "Ironwood - MI 49938",
    "county": "Gogebic County",
    "state": "MI",
    "city": "Ironwood"
  },
  {
    "value": "49945",
    "label": "Lake Linden - MI 49945",
    "county": "Houghton County",
    "state": "MI",
    "city": "Lake Linden"
  },
  {
    "value": "49946",
    "label": "Lanse - MI 49946",
    "county": "Baraga County",
    "state": "MI",
    "city": "Lanse"
  },
  {
    "value": "49947",
    "label": "Marenisco - MI 49947",
    "county": "Gogebic County",
    "state": "MI",
    "city": "Marenisco"
  },
  {
    "value": "49948",
    "label": "Mass City - MI 49948",
    "county": "Ontonagon County",
    "state": "MI",
    "city": "Mass City"
  },
  {
    "value": "49950",
    "label": "Mohawk - MI 49950",
    "county": "Keweenaw County",
    "state": "MI",
    "city": "Mohawk"
  },
  {
    "value": "49952",
    "label": "Nisula - MI 49952",
    "county": "Houghton County",
    "state": "MI",
    "city": "Nisula"
  },
  {
    "value": "49953",
    "label": "Ontonagon - MI 49953",
    "county": "Ontonagon County",
    "state": "MI",
    "city": "Ontonagon"
  },
  {
    "value": "49958",
    "label": "Pelkie - MI 49958",
    "county": "Houghton County",
    "state": "MI",
    "city": "Pelkie"
  },
  {
    "value": "49962",
    "label": "Skanee - MI 49962",
    "county": "Baraga County",
    "state": "MI",
    "city": "Skanee"
  },
  {
    "value": "49965",
    "label": "Toivola - MI 49965",
    "county": "Houghton County",
    "state": "MI",
    "city": "Toivola"
  },
  {
    "value": "49967",
    "label": "Trout Creek - MI 49967",
    "county": "Ontonagon County",
    "state": "MI",
    "city": "Trout Creek"
  },
  {
    "value": "49968",
    "label": "Wakefield - MI 49968",
    "county": "Gogebic County",
    "state": "MI",
    "city": "Wakefield"
  },
  {
    "value": "49969",
    "label": "Watersmeet - MI 49969",
    "county": "Gogebic County",
    "state": "MI",
    "city": "Watersmeet"
  },
  {
    "value": "49970",
    "label": "Watton - MI 49970",
    "county": "Baraga County",
    "state": "MI",
    "city": "Watton"
  },
  {
    "value": "55001",
    "label": "Afton - MN 55001",
    "county": "Washington County",
    "state": "MN",
    "city": "Afton"
  },
  {
    "value": "55003",
    "label": "Bayport - MN 55003",
    "county": "Washington County",
    "state": "MN",
    "city": "Bayport"
  },
  {
    "value": "55005",
    "label": "Bethel - MN 55005",
    "county": "Anoka County",
    "state": "MN",
    "city": "Bethel"
  },
  {
    "value": "55006",
    "label": "Braham - MN 55006",
    "county": "Isanti County",
    "state": "MN",
    "city": "Braham"
  },
  {
    "value": "55007",
    "label": "Brook Park - MN 55007",
    "county": "Kanabec County",
    "state": "MN",
    "city": "Brook Park"
  },
  {
    "value": "55008",
    "label": "Cambridge - MN 55008",
    "county": "Isanti County",
    "state": "MN",
    "city": "Cambridge"
  },
  {
    "value": "55009",
    "label": "Cannon Falls - MN 55009",
    "county": "Goodhue County",
    "state": "MN",
    "city": "Cannon Falls"
  },
  {
    "value": "55011",
    "label": "Cedar - MN 55011",
    "county": "Anoka County",
    "state": "MN",
    "city": "Cedar"
  },
  {
    "value": "55012",
    "label": "Center City - MN 55012",
    "county": "Chisago County",
    "state": "MN",
    "city": "Center City"
  },
  {
    "value": "55013",
    "label": "Chisago City - MN 55013",
    "county": "Chisago County",
    "state": "MN",
    "city": "Chisago City"
  },
  {
    "value": "55014",
    "label": "Circle Pines - MN 55014",
    "county": "Anoka County",
    "state": "MN",
    "city": "Circle Pines"
  },
  {
    "value": "55016",
    "label": "Cottage Grove - MN 55016",
    "county": "Washington County",
    "state": "MN",
    "city": "Cottage Grove"
  },
  {
    "value": "55017",
    "label": "Dalbo - MN 55017",
    "county": "Isanti County",
    "state": "MN",
    "city": "Dalbo"
  },
  {
    "value": "55018",
    "label": "Dennison - MN 55018",
    "county": "Goodhue County",
    "state": "MN",
    "city": "Dennison"
  },
  {
    "value": "55019",
    "label": "Dundas - MN 55019",
    "county": "Rice County",
    "state": "MN",
    "city": "Dundas"
  },
  {
    "value": "55020",
    "label": "Elko New Market - MN 55020",
    "county": "Scott County",
    "state": "MN",
    "city": "Elko New Market"
  },
  {
    "value": "55021",
    "label": "Faribault - MN 55021",
    "county": "Rice County",
    "state": "MN",
    "city": "Faribault"
  },
  {
    "value": "55024",
    "label": "Farmington - MN 55024",
    "county": "Dakota County",
    "state": "MN",
    "city": "Farmington"
  },
  {
    "value": "55025",
    "label": "Forest Lake - MN 55025",
    "county": "Washington County",
    "state": "MN",
    "city": "Forest Lake"
  },
  {
    "value": "55026",
    "label": "Frontenac - MN 55026",
    "county": "Goodhue County",
    "state": "MN",
    "city": "Frontenac"
  },
  {
    "value": "55027",
    "label": "Goodhue - MN 55027",
    "county": "Goodhue County",
    "state": "MN",
    "city": "Goodhue"
  },
  {
    "value": "55030",
    "label": "Grasston - MN 55030",
    "county": "Pine County",
    "state": "MN",
    "city": "Grasston"
  },
  {
    "value": "55031",
    "label": "Hampton - MN 55031",
    "county": "Dakota County",
    "state": "MN",
    "city": "Hampton"
  },
  {
    "value": "55032",
    "label": "Harris - MN 55032",
    "county": "Chisago County",
    "state": "MN",
    "city": "Harris"
  },
  {
    "value": "55033",
    "label": "Hastings - MN 55033",
    "county": "Dakota County",
    "state": "MN",
    "city": "Hastings"
  },
  {
    "value": "55036",
    "label": "Henriette - MN 55036",
    "county": "Pine County",
    "state": "MN",
    "city": "Henriette"
  },
  {
    "value": "55037",
    "label": "Hinckley - MN 55037",
    "county": "Pine County",
    "state": "MN",
    "city": "Hinckley"
  },
  {
    "value": "55038",
    "label": "Hugo - MN 55038",
    "county": "Washington County",
    "state": "MN",
    "city": "Hugo"
  },
  {
    "value": "55040",
    "label": "Isanti - MN 55040",
    "county": "Isanti County",
    "state": "MN",
    "city": "Isanti"
  },
  {
    "value": "55041",
    "label": "Lake City - MN 55041",
    "county": "Wabasha County",
    "state": "MN",
    "city": "Lake City"
  },
  {
    "value": "55042",
    "label": "Lake Elmo - MN 55042",
    "county": "Washington County",
    "state": "MN",
    "city": "Lake Elmo"
  },
  {
    "value": "55043",
    "label": "Lakeland - MN 55043",
    "county": "Washington County",
    "state": "MN",
    "city": "Lakeland"
  },
  {
    "value": "55044",
    "label": "Lakeville - MN 55044",
    "county": "Dakota County",
    "state": "MN",
    "city": "Lakeville"
  },
  {
    "value": "55045",
    "label": "Lindstrom - MN 55045",
    "county": "Chisago County",
    "state": "MN",
    "city": "Lindstrom"
  },
  {
    "value": "55046",
    "label": "Lonsdale - MN 55046",
    "county": "Rice County",
    "state": "MN",
    "city": "Lonsdale"
  },
  {
    "value": "55047",
    "label": "Marine On Saint Croix - MN 55047",
    "county": "Washington County",
    "state": "MN",
    "city": "Marine On Saint Croix"
  },
  {
    "value": "55049",
    "label": "Medford - MN 55049",
    "county": "Steele County",
    "state": "MN",
    "city": "Medford"
  },
  {
    "value": "55051",
    "label": "Mora - MN 55051",
    "county": "Kanabec County",
    "state": "MN",
    "city": "Mora"
  },
  {
    "value": "55052",
    "label": "Morristown - MN 55052",
    "county": "Rice County",
    "state": "MN",
    "city": "Morristown"
  },
  {
    "value": "55053",
    "label": "Nerstrand - MN 55053",
    "county": "Rice County",
    "state": "MN",
    "city": "Nerstrand"
  },
  {
    "value": "55054",
    "label": "Elko New Market - MN 55054",
    "county": "Scott County",
    "state": "MN",
    "city": "Elko New Market"
  },
  {
    "value": "55055",
    "label": "Newport - MN 55055",
    "county": "Washington County",
    "state": "MN",
    "city": "Newport"
  },
  {
    "value": "55056",
    "label": "North Branch - MN 55056",
    "county": "Chisago County",
    "state": "MN",
    "city": "North Branch"
  },
  {
    "value": "55057",
    "label": "Northfield - MN 55057",
    "county": "Rice County",
    "state": "MN",
    "city": "Northfield"
  },
  {
    "value": "55060",
    "label": "Owatonna - MN 55060",
    "county": "Steele County",
    "state": "MN",
    "city": "Owatonna"
  },
  {
    "value": "55063",
    "label": "Pine City - MN 55063",
    "county": "Pine County",
    "state": "MN",
    "city": "Pine City"
  },
  {
    "value": "55065",
    "label": "Randolph - MN 55065",
    "county": "Dakota County",
    "state": "MN",
    "city": "Randolph"
  },
  {
    "value": "55066",
    "label": "Red Wing - MN 55066",
    "county": "Goodhue County",
    "state": "MN",
    "city": "Red Wing"
  },
  {
    "value": "55068",
    "label": "Rosemount - MN 55068",
    "county": "Dakota County",
    "state": "MN",
    "city": "Rosemount"
  },
  {
    "value": "55069",
    "label": "Rush City - MN 55069",
    "county": "Chisago County",
    "state": "MN",
    "city": "Rush City"
  },
  {
    "value": "55070",
    "label": "Saint Francis - MN 55070",
    "county": "Anoka County",
    "state": "MN",
    "city": "Saint Francis"
  },
  {
    "value": "55071",
    "label": "Saint Paul Park - MN 55071",
    "county": "Washington County",
    "state": "MN",
    "city": "Saint Paul Park"
  },
  {
    "value": "55072",
    "label": "Sandstone - MN 55072",
    "county": "Pine County",
    "state": "MN",
    "city": "Sandstone"
  },
  {
    "value": "55073",
    "label": "Scandia - MN 55073",
    "county": "Washington County",
    "state": "MN",
    "city": "Scandia"
  },
  {
    "value": "55074",
    "label": "Shafer - MN 55074",
    "county": "Chisago County",
    "state": "MN",
    "city": "Shafer"
  },
  {
    "value": "55075",
    "label": "South Saint Paul - MN 55075",
    "county": "Dakota County",
    "state": "MN",
    "city": "South Saint Paul"
  },
  {
    "value": "55076",
    "label": "Inver Grove Heights - MN 55076",
    "county": "Dakota County",
    "state": "MN",
    "city": "Inver Grove Heights"
  },
  {
    "value": "55077",
    "label": "Inver Grove Heights - MN 55077",
    "county": "Dakota County",
    "state": "MN",
    "city": "Inver Grove Heights"
  },
  {
    "value": "55079",
    "label": "Stacy - MN 55079",
    "county": "Chisago County",
    "state": "MN",
    "city": "Stacy"
  },
  {
    "value": "55080",
    "label": "Stanchfield - MN 55080",
    "county": "Isanti County",
    "state": "MN",
    "city": "Stanchfield"
  },
  {
    "value": "55082",
    "label": "Stillwater - MN 55082",
    "county": "Washington County",
    "state": "MN",
    "city": "Stillwater"
  },
  {
    "value": "55084",
    "label": "Taylors Falls - MN 55084",
    "county": "Chisago County",
    "state": "MN",
    "city": "Taylors Falls"
  },
  {
    "value": "55087",
    "label": "Warsaw - MN 55087",
    "county": "Rice County",
    "state": "MN",
    "city": "Warsaw"
  },
  {
    "value": "55088",
    "label": "Webster - MN 55088",
    "county": "Rice County",
    "state": "MN",
    "city": "Webster"
  },
  {
    "value": "55089",
    "label": "Welch - MN 55089",
    "county": "Goodhue County",
    "state": "MN",
    "city": "Welch"
  },
  {
    "value": "55092",
    "label": "Wyoming - MN 55092",
    "county": "Chisago County",
    "state": "MN",
    "city": "Wyoming"
  },
  {
    "value": "55101",
    "label": "Saint Paul - MN 55101",
    "county": "Ramsey County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55102",
    "label": "Saint Paul - MN 55102",
    "county": "Ramsey County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55103",
    "label": "Saint Paul - MN 55103",
    "county": "Ramsey County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55104",
    "label": "Saint Paul - MN 55104",
    "county": "Ramsey County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55105",
    "label": "Saint Paul - MN 55105",
    "county": "Ramsey County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55106",
    "label": "Saint Paul - MN 55106",
    "county": "Ramsey County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55107",
    "label": "Saint Paul - MN 55107",
    "county": "Ramsey County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55108",
    "label": "Saint Paul - MN 55108",
    "county": "Ramsey County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55109",
    "label": "Saint Paul - MN 55109",
    "county": "Ramsey County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55110",
    "label": "Saint Paul - MN 55110",
    "county": "Ramsey County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55111",
    "label": "Saint Paul - MN 55111",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55112",
    "label": "Saint Paul - MN 55112",
    "county": "Ramsey County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55113",
    "label": "Saint Paul - MN 55113",
    "county": "Ramsey County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55114",
    "label": "Saint Paul - MN 55114",
    "county": "Ramsey County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55115",
    "label": "Saint Paul - MN 55115",
    "county": "Washington County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55116",
    "label": "Saint Paul - MN 55116",
    "county": "Ramsey County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55117",
    "label": "Saint Paul - MN 55117",
    "county": "Ramsey County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55118",
    "label": "Saint Paul - MN 55118",
    "county": "Dakota County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55119",
    "label": "Saint Paul - MN 55119",
    "county": "Ramsey County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55120",
    "label": "Saint Paul - MN 55120",
    "county": "Dakota County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55121",
    "label": "Saint Paul - MN 55121",
    "county": "Dakota County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55122",
    "label": "Saint Paul - MN 55122",
    "county": "Dakota County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55123",
    "label": "Saint Paul - MN 55123",
    "county": "Dakota County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55124",
    "label": "Saint Paul - MN 55124",
    "county": "Dakota County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55125",
    "label": "Saint Paul - MN 55125",
    "county": "Washington County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55126",
    "label": "Saint Paul - MN 55126",
    "county": "Ramsey County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55127",
    "label": "Saint Paul - MN 55127",
    "county": "Ramsey County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55128",
    "label": "Saint Paul - MN 55128",
    "county": "Washington County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55129",
    "label": "Saint Paul - MN 55129",
    "county": "Washington County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55130",
    "label": "Saint Paul - MN 55130",
    "county": "Ramsey County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55131",
    "label": "Saint Paul - MN 55131",
    "county": "Ramsey County",
    "state": "MN",
    "city": "Saint Paul"
  },
  {
    "value": "55301",
    "label": "Albertville - MN 55301",
    "county": "Wright County",
    "state": "MN",
    "city": "Albertville"
  },
  {
    "value": "55302",
    "label": "Annandale - MN 55302",
    "county": "Wright County",
    "state": "MN",
    "city": "Annandale"
  },
  {
    "value": "55303",
    "label": "Anoka - MN 55303",
    "county": "Anoka County",
    "state": "MN",
    "city": "Anoka"
  },
  {
    "value": "55304",
    "label": "Andover - MN 55304",
    "county": "Anoka County",
    "state": "MN",
    "city": "Andover"
  },
  {
    "value": "55305",
    "label": "Hopkins - MN 55305",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Hopkins"
  },
  {
    "value": "55306",
    "label": "Burnsville - MN 55306",
    "county": "Dakota County",
    "state": "MN",
    "city": "Burnsville"
  },
  {
    "value": "55307",
    "label": "Arlington - MN 55307",
    "county": "Sibley County",
    "state": "MN",
    "city": "Arlington"
  },
  {
    "value": "55308",
    "label": "Becker - MN 55308",
    "county": "Sherburne County",
    "state": "MN",
    "city": "Becker"
  },
  {
    "value": "55309",
    "label": "Big Lake - MN 55309",
    "county": "Sherburne County",
    "state": "MN",
    "city": "Big Lake"
  },
  {
    "value": "55310",
    "label": "Bird Island - MN 55310",
    "county": "Renville County",
    "state": "MN",
    "city": "Bird Island"
  },
  {
    "value": "55311",
    "label": "Maple Grove - MN 55311",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Maple Grove"
  },
  {
    "value": "55312",
    "label": "Brownton - MN 55312",
    "county": "McLeod County",
    "state": "MN",
    "city": "Brownton"
  },
  {
    "value": "55313",
    "label": "Buffalo - MN 55313",
    "county": "Wright County",
    "state": "MN",
    "city": "Buffalo"
  },
  {
    "value": "55314",
    "label": "Buffalo Lake - MN 55314",
    "county": "Renville County",
    "state": "MN",
    "city": "Buffalo Lake"
  },
  {
    "value": "55315",
    "label": "Carver - MN 55315",
    "county": "Carver County",
    "state": "MN",
    "city": "Carver"
  },
  {
    "value": "55316",
    "label": "Champlin - MN 55316",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Champlin"
  },
  {
    "value": "55317",
    "label": "Chanhassen - MN 55317",
    "county": "Carver County",
    "state": "MN",
    "city": "Chanhassen"
  },
  {
    "value": "55318",
    "label": "Chaska - MN 55318",
    "county": "Carver County",
    "state": "MN",
    "city": "Chaska"
  },
  {
    "value": "55319",
    "label": "Clear Lake - MN 55319",
    "county": "Sherburne County",
    "state": "MN",
    "city": "Clear Lake"
  },
  {
    "value": "55320",
    "label": "Clearwater - MN 55320",
    "county": "Wright County",
    "state": "MN",
    "city": "Clearwater"
  },
  {
    "value": "55321",
    "label": "Cokato - MN 55321",
    "county": "Wright County",
    "state": "MN",
    "city": "Cokato"
  },
  {
    "value": "55322",
    "label": "Cologne - MN 55322",
    "county": "Carver County",
    "state": "MN",
    "city": "Cologne"
  },
  {
    "value": "55324",
    "label": "Darwin - MN 55324",
    "county": "Meeker County",
    "state": "MN",
    "city": "Darwin"
  },
  {
    "value": "55325",
    "label": "Dassel - MN 55325",
    "county": "Meeker County",
    "state": "MN",
    "city": "Dassel"
  },
  {
    "value": "55327",
    "label": "Dayton - MN 55327",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Dayton"
  },
  {
    "value": "55328",
    "label": "Delano - MN 55328",
    "county": "Wright County",
    "state": "MN",
    "city": "Delano"
  },
  {
    "value": "55329",
    "label": "Eden Valley - MN 55329",
    "county": "Stearns County",
    "state": "MN",
    "city": "Eden Valley"
  },
  {
    "value": "55330",
    "label": "Elk River - MN 55330",
    "county": "Sherburne County",
    "state": "MN",
    "city": "Elk River"
  },
  {
    "value": "55331",
    "label": "Excelsior - MN 55331",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Excelsior"
  },
  {
    "value": "55332",
    "label": "Fairfax - MN 55332",
    "county": "Renville County",
    "state": "MN",
    "city": "Fairfax"
  },
  {
    "value": "55333",
    "label": "Franklin - MN 55333",
    "county": "Renville County",
    "state": "MN",
    "city": "Franklin"
  },
  {
    "value": "55334",
    "label": "Gaylord - MN 55334",
    "county": "Sibley County",
    "state": "MN",
    "city": "Gaylord"
  },
  {
    "value": "55335",
    "label": "Gibbon - MN 55335",
    "county": "Sibley County",
    "state": "MN",
    "city": "Gibbon"
  },
  {
    "value": "55336",
    "label": "Glencoe - MN 55336",
    "county": "McLeod County",
    "state": "MN",
    "city": "Glencoe"
  },
  {
    "value": "55337",
    "label": "Burnsville - MN 55337",
    "county": "Dakota County",
    "state": "MN",
    "city": "Burnsville"
  },
  {
    "value": "55338",
    "label": "Green Isle - MN 55338",
    "county": "Sibley County",
    "state": "MN",
    "city": "Green Isle"
  },
  {
    "value": "55339",
    "label": "Hamburg - MN 55339",
    "county": "Carver County",
    "state": "MN",
    "city": "Hamburg"
  },
  {
    "value": "55340",
    "label": "Hamel - MN 55340",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Hamel"
  },
  {
    "value": "55341",
    "label": "Hanover - MN 55341",
    "county": "Wright County",
    "state": "MN",
    "city": "Hanover"
  },
  {
    "value": "55342",
    "label": "Hector - MN 55342",
    "county": "Renville County",
    "state": "MN",
    "city": "Hector"
  },
  {
    "value": "55343",
    "label": "Hopkins - MN 55343",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Hopkins"
  },
  {
    "value": "55344",
    "label": "Eden Prairie - MN 55344",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Eden Prairie"
  },
  {
    "value": "55345",
    "label": "Minnetonka - MN 55345",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minnetonka"
  },
  {
    "value": "55346",
    "label": "Eden Prairie - MN 55346",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Eden Prairie"
  },
  {
    "value": "55347",
    "label": "Eden Prairie - MN 55347",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Eden Prairie"
  },
  {
    "value": "55349",
    "label": "Howard Lake - MN 55349",
    "county": "Wright County",
    "state": "MN",
    "city": "Howard Lake"
  },
  {
    "value": "55350",
    "label": "Hutchinson - MN 55350",
    "county": "McLeod County",
    "state": "MN",
    "city": "Hutchinson"
  },
  {
    "value": "55352",
    "label": "Jordan - MN 55352",
    "county": "Scott County",
    "state": "MN",
    "city": "Jordan"
  },
  {
    "value": "55353",
    "label": "Kimball - MN 55353",
    "county": "Stearns County",
    "state": "MN",
    "city": "Kimball"
  },
  {
    "value": "55354",
    "label": "Lester Prairie - MN 55354",
    "county": "McLeod County",
    "state": "MN",
    "city": "Lester Prairie"
  },
  {
    "value": "55355",
    "label": "Litchfield - MN 55355",
    "county": "Meeker County",
    "state": "MN",
    "city": "Litchfield"
  },
  {
    "value": "55356",
    "label": "Long Lake - MN 55356",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Long Lake"
  },
  {
    "value": "55357",
    "label": "Loretto - MN 55357",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Loretto"
  },
  {
    "value": "55358",
    "label": "Maple Lake - MN 55358",
    "county": "Wright County",
    "state": "MN",
    "city": "Maple Lake"
  },
  {
    "value": "55359",
    "label": "Maple Plain - MN 55359",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Maple Plain"
  },
  {
    "value": "55360",
    "label": "Mayer - MN 55360",
    "county": "Carver County",
    "state": "MN",
    "city": "Mayer"
  },
  {
    "value": "55362",
    "label": "Monticello - MN 55362",
    "county": "Wright County",
    "state": "MN",
    "city": "Monticello"
  },
  {
    "value": "55363",
    "label": "Montrose - MN 55363",
    "county": "Wright County",
    "state": "MN",
    "city": "Montrose"
  },
  {
    "value": "55364",
    "label": "Mound - MN 55364",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Mound"
  },
  {
    "value": "55367",
    "label": "New Germany - MN 55367",
    "county": "Carver County",
    "state": "MN",
    "city": "New Germany"
  },
  {
    "value": "55368",
    "label": "Norwood Young America - MN 55368",
    "county": "Carver County",
    "state": "MN",
    "city": "Norwood Young America"
  },
  {
    "value": "55369",
    "label": "Osseo - MN 55369",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Osseo"
  },
  {
    "value": "55370",
    "label": "Plato - MN 55370",
    "county": "McLeod County",
    "state": "MN",
    "city": "Plato"
  },
  {
    "value": "55371",
    "label": "Princeton - MN 55371",
    "county": "Mille Lacs County",
    "state": "MN",
    "city": "Princeton"
  },
  {
    "value": "55372",
    "label": "Prior Lake - MN 55372",
    "county": "Scott County",
    "state": "MN",
    "city": "Prior Lake"
  },
  {
    "value": "55373",
    "label": "Rockford - MN 55373",
    "county": "Wright County",
    "state": "MN",
    "city": "Rockford"
  },
  {
    "value": "55374",
    "label": "Rogers - MN 55374",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Rogers"
  },
  {
    "value": "55375",
    "label": "Saint Bonifacius - MN 55375",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Saint Bonifacius"
  },
  {
    "value": "55376",
    "label": "Saint Michael - MN 55376",
    "county": "Wright County",
    "state": "MN",
    "city": "Saint Michael"
  },
  {
    "value": "55378",
    "label": "Savage - MN 55378",
    "county": "Scott County",
    "state": "MN",
    "city": "Savage"
  },
  {
    "value": "55379",
    "label": "Shakopee - MN 55379",
    "county": "Scott County",
    "state": "MN",
    "city": "Shakopee"
  },
  {
    "value": "55381",
    "label": "Silver Lake - MN 55381",
    "county": "McLeod County",
    "state": "MN",
    "city": "Silver Lake"
  },
  {
    "value": "55382",
    "label": "South Haven - MN 55382",
    "county": "Stearns County",
    "state": "MN",
    "city": "South Haven"
  },
  {
    "value": "55384",
    "label": "Spring Park - MN 55384",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Spring Park"
  },
  {
    "value": "55385",
    "label": "Stewart - MN 55385",
    "county": "McLeod County",
    "state": "MN",
    "city": "Stewart"
  },
  {
    "value": "55386",
    "label": "Victoria - MN 55386",
    "county": "Carver County",
    "state": "MN",
    "city": "Victoria"
  },
  {
    "value": "55387",
    "label": "Waconia - MN 55387",
    "county": "Carver County",
    "state": "MN",
    "city": "Waconia"
  },
  {
    "value": "55388",
    "label": "Watertown - MN 55388",
    "county": "Carver County",
    "state": "MN",
    "city": "Watertown"
  },
  {
    "value": "55389",
    "label": "Watkins - MN 55389",
    "county": "Meeker County",
    "state": "MN",
    "city": "Watkins"
  },
  {
    "value": "55390",
    "label": "Waverly - MN 55390",
    "county": "Wright County",
    "state": "MN",
    "city": "Waverly"
  },
  {
    "value": "55391",
    "label": "Wayzata - MN 55391",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Wayzata"
  },
  {
    "value": "55395",
    "label": "Winsted - MN 55395",
    "county": "McLeod County",
    "state": "MN",
    "city": "Winsted"
  },
  {
    "value": "55396",
    "label": "Winthrop - MN 55396",
    "county": "Sibley County",
    "state": "MN",
    "city": "Winthrop"
  },
  {
    "value": "55397",
    "label": "Young America - MN 55397",
    "county": "Carver County",
    "state": "MN",
    "city": "Young America"
  },
  {
    "value": "55398",
    "label": "Zimmerman - MN 55398",
    "county": "Sherburne County",
    "state": "MN",
    "city": "Zimmerman"
  },
  {
    "value": "55401",
    "label": "Minneapolis - MN 55401",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55402",
    "label": "Minneapolis - MN 55402",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55403",
    "label": "Minneapolis - MN 55403",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55404",
    "label": "Minneapolis - MN 55404",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55405",
    "label": "Minneapolis - MN 55405",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55406",
    "label": "Minneapolis - MN 55406",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55407",
    "label": "Minneapolis - MN 55407",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55408",
    "label": "Minneapolis - MN 55408",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55409",
    "label": "Minneapolis - MN 55409",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55410",
    "label": "Minneapolis - MN 55410",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55411",
    "label": "Minneapolis - MN 55411",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55412",
    "label": "Minneapolis - MN 55412",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55413",
    "label": "Minneapolis - MN 55413",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55414",
    "label": "Minneapolis - MN 55414",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55415",
    "label": "Minneapolis - MN 55415",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55416",
    "label": "Minneapolis - MN 55416",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55417",
    "label": "Minneapolis - MN 55417",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55418",
    "label": "Minneapolis - MN 55418",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55419",
    "label": "Minneapolis - MN 55419",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55420",
    "label": "Minneapolis - MN 55420",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55421",
    "label": "Minneapolis - MN 55421",
    "county": "Anoka County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55422",
    "label": "Minneapolis - MN 55422",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55423",
    "label": "Minneapolis - MN 55423",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55424",
    "label": "Minneapolis - MN 55424",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55425",
    "label": "Minneapolis - MN 55425",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55426",
    "label": "Minneapolis - MN 55426",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55427",
    "label": "Minneapolis - MN 55427",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55428",
    "label": "Minneapolis - MN 55428",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55429",
    "label": "Minneapolis - MN 55429",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55430",
    "label": "Minneapolis - MN 55430",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55431",
    "label": "Minneapolis - MN 55431",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55432",
    "label": "Minneapolis - MN 55432",
    "county": "Anoka County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55433",
    "label": "Minneapolis - MN 55433",
    "county": "Anoka County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55434",
    "label": "Minneapolis - MN 55434",
    "county": "Anoka County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55435",
    "label": "Minneapolis - MN 55435",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55436",
    "label": "Minneapolis - MN 55436",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55437",
    "label": "Minneapolis - MN 55437",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55438",
    "label": "Minneapolis - MN 55438",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55439",
    "label": "Minneapolis - MN 55439",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55441",
    "label": "Minneapolis - MN 55441",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55442",
    "label": "Minneapolis - MN 55442",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55443",
    "label": "Minneapolis - MN 55443",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55444",
    "label": "Minneapolis - MN 55444",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55445",
    "label": "Minneapolis - MN 55445",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55446",
    "label": "Minneapolis - MN 55446",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55447",
    "label": "Minneapolis - MN 55447",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55448",
    "label": "Minneapolis - MN 55448",
    "county": "Anoka County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55449",
    "label": "Minneapolis - MN 55449",
    "county": "Anoka County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55450",
    "label": "Minneapolis - MN 55450",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55454",
    "label": "Minneapolis - MN 55454",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55455",
    "label": "Minneapolis - MN 55455",
    "county": "Hennepin County",
    "state": "MN",
    "city": "Minneapolis"
  },
  {
    "value": "55561",
    "label": "Monticello - MN 55561",
    "county": "Wright County",
    "state": "MN",
    "city": "Monticello"
  },
  {
    "value": "55563",
    "label": "Monticello - MN 55563",
    "county": "Wright County",
    "state": "MN",
    "city": "Monticello"
  },
  {
    "value": "55602",
    "label": "Brimson - MN 55602",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Brimson"
  },
  {
    "value": "55603",
    "label": "Finland - MN 55603",
    "county": "Lake County",
    "state": "MN",
    "city": "Finland"
  },
  {
    "value": "55604",
    "label": "Grand Marais - MN 55604",
    "county": "Cook County",
    "state": "MN",
    "city": "Grand Marais"
  },
  {
    "value": "55605",
    "label": "Grand Portage - MN 55605",
    "county": "Cook County",
    "state": "MN",
    "city": "Grand Portage"
  },
  {
    "value": "55606",
    "label": "Hovland - MN 55606",
    "county": "Cook County",
    "state": "MN",
    "city": "Hovland"
  },
  {
    "value": "55607",
    "label": "Isabella - MN 55607",
    "county": "Lake County",
    "state": "MN",
    "city": "Isabella"
  },
  {
    "value": "55612",
    "label": "Lutsen - MN 55612",
    "county": "Cook County",
    "state": "MN",
    "city": "Lutsen"
  },
  {
    "value": "55613",
    "label": "Schroeder - MN 55613",
    "county": "Cook County",
    "state": "MN",
    "city": "Schroeder"
  },
  {
    "value": "55614",
    "label": "Silver Bay - MN 55614",
    "county": "Lake County",
    "state": "MN",
    "city": "Silver Bay"
  },
  {
    "value": "55615",
    "label": "Tofte - MN 55615",
    "county": "Cook County",
    "state": "MN",
    "city": "Tofte"
  },
  {
    "value": "55616",
    "label": "Two Harbors - MN 55616",
    "county": "Lake County",
    "state": "MN",
    "city": "Two Harbors"
  },
  {
    "value": "55701",
    "label": "Adolph - MN 55701",
    "county": "St Louis County",
    "state": "MN",
    "city": "Adolph"
  },
  {
    "value": "55702",
    "label": "Alborn - MN 55702",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Alborn"
  },
  {
    "value": "55703",
    "label": "Angora - MN 55703",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Angora"
  },
  {
    "value": "55704",
    "label": "Askov - MN 55704",
    "county": "Pine County",
    "state": "MN",
    "city": "Askov"
  },
  {
    "value": "55705",
    "label": "Aurora - MN 55705",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Aurora"
  },
  {
    "value": "55706",
    "label": "Babbitt - MN 55706",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Babbitt"
  },
  {
    "value": "55707",
    "label": "Barnum - MN 55707",
    "county": "Carlton County",
    "state": "MN",
    "city": "Barnum"
  },
  {
    "value": "55709",
    "label": "Bovey - MN 55709",
    "county": "Itasca County",
    "state": "MN",
    "city": "Bovey"
  },
  {
    "value": "55710",
    "label": "Britt - MN 55710",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Britt"
  },
  {
    "value": "55711",
    "label": "Brookston - MN 55711",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Brookston"
  },
  {
    "value": "55712",
    "label": "Bruno - MN 55712",
    "county": "Pine County",
    "state": "MN",
    "city": "Bruno"
  },
  {
    "value": "55717",
    "label": "Canyon - MN 55717",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Canyon"
  },
  {
    "value": "55718",
    "label": "Carlton - MN 55718",
    "county": "Carlton County",
    "state": "MN",
    "city": "Carlton"
  },
  {
    "value": "55719",
    "label": "Chisholm - MN 55719",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Chisholm"
  },
  {
    "value": "55720",
    "label": "Cloquet - MN 55720",
    "county": "Carlton County",
    "state": "MN",
    "city": "Cloquet"
  },
  {
    "value": "55721",
    "label": "Cohasset - MN 55721",
    "county": "Itasca County",
    "state": "MN",
    "city": "Cohasset"
  },
  {
    "value": "55723",
    "label": "Cook - MN 55723",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Cook"
  },
  {
    "value": "55724",
    "label": "Cotton - MN 55724",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Cotton"
  },
  {
    "value": "55725",
    "label": "Crane Lake - MN 55725",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Crane Lake"
  },
  {
    "value": "55726",
    "label": "Cromwell - MN 55726",
    "county": "Carlton County",
    "state": "MN",
    "city": "Cromwell"
  },
  {
    "value": "55731",
    "label": "Ely - MN 55731",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Ely"
  },
  {
    "value": "55732",
    "label": "Embarrass - MN 55732",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Embarrass"
  },
  {
    "value": "55733",
    "label": "Esko - MN 55733",
    "county": "Carlton County",
    "state": "MN",
    "city": "Esko"
  },
  {
    "value": "55734",
    "label": "Eveleth - MN 55734",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Eveleth"
  },
  {
    "value": "55735",
    "label": "Finlayson - MN 55735",
    "county": "Pine County",
    "state": "MN",
    "city": "Finlayson"
  },
  {
    "value": "55736",
    "label": "Floodwood - MN 55736",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Floodwood"
  },
  {
    "value": "55738",
    "label": "Forbes - MN 55738",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Forbes"
  },
  {
    "value": "55741",
    "label": "Gilbert - MN 55741",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Gilbert"
  },
  {
    "value": "55742",
    "label": "Goodland - MN 55742",
    "county": "Itasca County",
    "state": "MN",
    "city": "Goodland"
  },
  {
    "value": "55744",
    "label": "Grand Rapids - MN 55744",
    "county": "Itasca County",
    "state": "MN",
    "city": "Grand Rapids"
  },
  {
    "value": "55746",
    "label": "Hibbing - MN 55746",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Hibbing"
  },
  {
    "value": "55748",
    "label": "Hill City - MN 55748",
    "county": "Aitkin County",
    "state": "MN",
    "city": "Hill City"
  },
  {
    "value": "55749",
    "label": "Holyoke - MN 55749",
    "county": "Carlton County",
    "state": "MN",
    "city": "Holyoke"
  },
  {
    "value": "55750",
    "label": "Hoyt Lakes - MN 55750",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Hoyt Lakes"
  },
  {
    "value": "55751",
    "label": "Iron - MN 55751",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Iron"
  },
  {
    "value": "55752",
    "label": "Jacobson - MN 55752",
    "county": "Aitkin County",
    "state": "MN",
    "city": "Jacobson"
  },
  {
    "value": "55756",
    "label": "Kerrick - MN 55756",
    "county": "Pine County",
    "state": "MN",
    "city": "Kerrick"
  },
  {
    "value": "55757",
    "label": "Kettle River - MN 55757",
    "county": "Carlton County",
    "state": "MN",
    "city": "Kettle River"
  },
  {
    "value": "55758",
    "label": "Kinney - MN 55758",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Kinney"
  },
  {
    "value": "55760",
    "label": "Mcgregor - MN 55760",
    "county": "Aitkin County",
    "state": "MN",
    "city": "Mcgregor"
  },
  {
    "value": "55763",
    "label": "Makinen - MN 55763",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Makinen"
  },
  {
    "value": "55765",
    "label": "Meadowlands - MN 55765",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Meadowlands"
  },
  {
    "value": "55767",
    "label": "Moose Lake - MN 55767",
    "county": "Carlton County",
    "state": "MN",
    "city": "Moose Lake"
  },
  {
    "value": "55768",
    "label": "Mountain Iron - MN 55768",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Mountain Iron"
  },
  {
    "value": "55769",
    "label": "Nashwauk - MN 55769",
    "county": "Itasca County",
    "state": "MN",
    "city": "Nashwauk"
  },
  {
    "value": "55771",
    "label": "Orr - MN 55771",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Orr"
  },
  {
    "value": "55775",
    "label": "Pengilly - MN 55775",
    "county": "Itasca County",
    "state": "MN",
    "city": "Pengilly"
  },
  {
    "value": "55779",
    "label": "Saginaw - MN 55779",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Saginaw"
  },
  {
    "value": "55780",
    "label": "Sawyer - MN 55780",
    "county": "Carlton County",
    "state": "MN",
    "city": "Sawyer"
  },
  {
    "value": "55781",
    "label": "Side Lake - MN 55781",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Side Lake"
  },
  {
    "value": "55783",
    "label": "Sturgeon Lake - MN 55783",
    "county": "Pine County",
    "state": "MN",
    "city": "Sturgeon Lake"
  },
  {
    "value": "55784",
    "label": "Swan River - MN 55784",
    "county": "Itasca County",
    "state": "MN",
    "city": "Swan River"
  },
  {
    "value": "55785",
    "label": "Swatara - MN 55785",
    "county": "Aitkin County",
    "state": "MN",
    "city": "Swatara"
  },
  {
    "value": "55787",
    "label": "Tamarack - MN 55787",
    "county": "Aitkin County",
    "state": "MN",
    "city": "Tamarack"
  },
  {
    "value": "55790",
    "label": "Tower - MN 55790",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Tower"
  },
  {
    "value": "55792",
    "label": "Virginia - MN 55792",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Virginia"
  },
  {
    "value": "55793",
    "label": "Warba - MN 55793",
    "county": "Itasca County",
    "state": "MN",
    "city": "Warba"
  },
  {
    "value": "55795",
    "label": "Willow River - MN 55795",
    "county": "Pine County",
    "state": "MN",
    "city": "Willow River"
  },
  {
    "value": "55796",
    "label": "Winton - MN 55796",
    "county": "St Louis County",
    "state": "MN",
    "city": "Winton"
  },
  {
    "value": "55797",
    "label": "Wrenshall - MN 55797",
    "county": "Carlton County",
    "state": "MN",
    "city": "Wrenshall"
  },
  {
    "value": "55798",
    "label": "Wright - MN 55798",
    "county": "Carlton County",
    "state": "MN",
    "city": "Wright"
  },
  {
    "value": "55802",
    "label": "Duluth - MN 55802",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Duluth"
  },
  {
    "value": "55803",
    "label": "Duluth - MN 55803",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Duluth"
  },
  {
    "value": "55804",
    "label": "Duluth - MN 55804",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Duluth"
  },
  {
    "value": "55805",
    "label": "Duluth - MN 55805",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Duluth"
  },
  {
    "value": "55806",
    "label": "Duluth - MN 55806",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Duluth"
  },
  {
    "value": "55807",
    "label": "Duluth - MN 55807",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Duluth"
  },
  {
    "value": "55808",
    "label": "Duluth - MN 55808",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Duluth"
  },
  {
    "value": "55810",
    "label": "Duluth - MN 55810",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Duluth"
  },
  {
    "value": "55811",
    "label": "Duluth - MN 55811",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Duluth"
  },
  {
    "value": "55812",
    "label": "Duluth - MN 55812",
    "county": "St. Louis County",
    "state": "MN",
    "city": "Duluth"
  },
  {
    "value": "55901",
    "label": "Rochester - MN 55901",
    "county": "Olmsted County",
    "state": "MN",
    "city": "Rochester"
  },
  {
    "value": "55902",
    "label": "Rochester - MN 55902",
    "county": "Olmsted County",
    "state": "MN",
    "city": "Rochester"
  },
  {
    "value": "55904",
    "label": "Rochester - MN 55904",
    "county": "Olmsted County",
    "state": "MN",
    "city": "Rochester"
  },
  {
    "value": "55906",
    "label": "Rochester - MN 55906",
    "county": "Olmsted County",
    "state": "MN",
    "city": "Rochester"
  },
  {
    "value": "55909",
    "label": "Adams - MN 55909",
    "county": "Mower County",
    "state": "MN",
    "city": "Adams"
  },
  {
    "value": "55910",
    "label": "Altura - MN 55910",
    "county": "Winona County",
    "state": "MN",
    "city": "Altura"
  },
  {
    "value": "55912",
    "label": "Austin - MN 55912",
    "county": "Mower County",
    "state": "MN",
    "city": "Austin"
  },
  {
    "value": "55917",
    "label": "Blooming Prairie - MN 55917",
    "county": "Steele County",
    "state": "MN",
    "city": "Blooming Prairie"
  },
  {
    "value": "55918",
    "label": "Brownsdale - MN 55918",
    "county": "Mower County",
    "state": "MN",
    "city": "Brownsdale"
  },
  {
    "value": "55919",
    "label": "Brownsville - MN 55919",
    "county": "Houston County",
    "state": "MN",
    "city": "Brownsville"
  },
  {
    "value": "55920",
    "label": "Byron - MN 55920",
    "county": "Olmsted County",
    "state": "MN",
    "city": "Byron"
  },
  {
    "value": "55921",
    "label": "Caledonia - MN 55921",
    "county": "Houston County",
    "state": "MN",
    "city": "Caledonia"
  },
  {
    "value": "55922",
    "label": "Canton - MN 55922",
    "county": "Fillmore County",
    "state": "MN",
    "city": "Canton"
  },
  {
    "value": "55923",
    "label": "Chatfield - MN 55923",
    "county": "Fillmore County",
    "state": "MN",
    "city": "Chatfield"
  },
  {
    "value": "55924",
    "label": "Claremont - MN 55924",
    "county": "Dodge County",
    "state": "MN",
    "city": "Claremont"
  },
  {
    "value": "55925",
    "label": "Dakota - MN 55925",
    "county": "Winona County",
    "state": "MN",
    "city": "Dakota"
  },
  {
    "value": "55926",
    "label": "Dexter - MN 55926",
    "county": "Mower County",
    "state": "MN",
    "city": "Dexter"
  },
  {
    "value": "55927",
    "label": "Dodge Center - MN 55927",
    "county": "Dodge County",
    "state": "MN",
    "city": "Dodge Center"
  },
  {
    "value": "55929",
    "label": "Dover - MN 55929",
    "county": "Olmsted County",
    "state": "MN",
    "city": "Dover"
  },
  {
    "value": "55931",
    "label": "Eitzen - MN 55931",
    "county": "Houston County",
    "state": "MN",
    "city": "Eitzen"
  },
  {
    "value": "55932",
    "label": "Elgin - MN 55932",
    "county": "Wabasha County",
    "state": "MN",
    "city": "Elgin"
  },
  {
    "value": "55933",
    "label": "Elkton - MN 55933",
    "county": "Mower County",
    "state": "MN",
    "city": "Elkton"
  },
  {
    "value": "55934",
    "label": "Eyota - MN 55934",
    "county": "Olmsted County",
    "state": "MN",
    "city": "Eyota"
  },
  {
    "value": "55935",
    "label": "Fountain - MN 55935",
    "county": "Fillmore County",
    "state": "MN",
    "city": "Fountain"
  },
  {
    "value": "55936",
    "label": "Grand Meadow - MN 55936",
    "county": "Mower County",
    "state": "MN",
    "city": "Grand Meadow"
  },
  {
    "value": "55939",
    "label": "Harmony - MN 55939",
    "county": "Fillmore County",
    "state": "MN",
    "city": "Harmony"
  },
  {
    "value": "55940",
    "label": "Hayfield - MN 55940",
    "county": "Dodge County",
    "state": "MN",
    "city": "Hayfield"
  },
  {
    "value": "55941",
    "label": "Hokah - MN 55941",
    "county": "Houston County",
    "state": "MN",
    "city": "Hokah"
  },
  {
    "value": "55943",
    "label": "Houston - MN 55943",
    "county": "Houston County",
    "state": "MN",
    "city": "Houston"
  },
  {
    "value": "55944",
    "label": "Kasson - MN 55944",
    "county": "Dodge County",
    "state": "MN",
    "city": "Kasson"
  },
  {
    "value": "55945",
    "label": "Kellogg - MN 55945",
    "county": "Wabasha County",
    "state": "MN",
    "city": "Kellogg"
  },
  {
    "value": "55946",
    "label": "Kenyon - MN 55946",
    "county": "Goodhue County",
    "state": "MN",
    "city": "Kenyon"
  },
  {
    "value": "55947",
    "label": "La Crescent - MN 55947",
    "county": "Houston County",
    "state": "MN",
    "city": "La Crescent"
  },
  {
    "value": "55949",
    "label": "Lanesboro - MN 55949",
    "county": "Fillmore County",
    "state": "MN",
    "city": "Lanesboro"
  },
  {
    "value": "55951",
    "label": "Le Roy - MN 55951",
    "county": "Mower County",
    "state": "MN",
    "city": "Le Roy"
  },
  {
    "value": "55952",
    "label": "Lewiston - MN 55952",
    "county": "Winona County",
    "state": "MN",
    "city": "Lewiston"
  },
  {
    "value": "55953",
    "label": "Lyle - MN 55953",
    "county": "Mower County",
    "state": "MN",
    "city": "Lyle"
  },
  {
    "value": "55954",
    "label": "Mabel - MN 55954",
    "county": "Fillmore County",
    "state": "MN",
    "city": "Mabel"
  },
  {
    "value": "55955",
    "label": "Mantorville - MN 55955",
    "county": "Dodge County",
    "state": "MN",
    "city": "Mantorville"
  },
  {
    "value": "55956",
    "label": "Mazeppa - MN 55956",
    "county": "Wabasha County",
    "state": "MN",
    "city": "Mazeppa"
  },
  {
    "value": "55957",
    "label": "Millville - MN 55957",
    "county": "Wabasha County",
    "state": "MN",
    "city": "Millville"
  },
  {
    "value": "55959",
    "label": "Minnesota City - MN 55959",
    "county": "Winona County",
    "state": "MN",
    "city": "Minnesota City"
  },
  {
    "value": "55960",
    "label": "Oronoco - MN 55960",
    "county": "Olmsted County",
    "state": "MN",
    "city": "Oronoco"
  },
  {
    "value": "55961",
    "label": "Ostrander - MN 55961",
    "county": "Fillmore County",
    "state": "MN",
    "city": "Ostrander"
  },
  {
    "value": "55962",
    "label": "Peterson - MN 55962",
    "county": "Fillmore County",
    "state": "MN",
    "city": "Peterson"
  },
  {
    "value": "55963",
    "label": "Pine Island - MN 55963",
    "county": "Goodhue County",
    "state": "MN",
    "city": "Pine Island"
  },
  {
    "value": "55964",
    "label": "Plainview - MN 55964",
    "county": "Wabasha County",
    "state": "MN",
    "city": "Plainview"
  },
  {
    "value": "55965",
    "label": "Preston - MN 55965",
    "county": "Fillmore County",
    "state": "MN",
    "city": "Preston"
  },
  {
    "value": "55967",
    "label": "Racine - MN 55967",
    "county": "Mower County",
    "state": "MN",
    "city": "Racine"
  },
  {
    "value": "55968",
    "label": "Reads Landing - MN 55968",
    "county": "Wabasha County",
    "state": "MN",
    "city": "Reads Landing"
  },
  {
    "value": "55969",
    "label": "Rollingstone - MN 55969",
    "county": "Winona County",
    "state": "MN",
    "city": "Rollingstone"
  },
  {
    "value": "55970",
    "label": "Rose Creek - MN 55970",
    "county": "Mower County",
    "state": "MN",
    "city": "Rose Creek"
  },
  {
    "value": "55971",
    "label": "Rushford - MN 55971",
    "county": "Fillmore County",
    "state": "MN",
    "city": "Rushford"
  },
  {
    "value": "55972",
    "label": "Saint Charles - MN 55972",
    "county": "Winona County",
    "state": "MN",
    "city": "Saint Charles"
  },
  {
    "value": "55973",
    "label": "Sargeant - MN 55973",
    "county": "Mower County",
    "state": "MN",
    "city": "Sargeant"
  },
  {
    "value": "55974",
    "label": "Spring Grove - MN 55974",
    "county": "Houston County",
    "state": "MN",
    "city": "Spring Grove"
  },
  {
    "value": "55975",
    "label": "Spring Valley - MN 55975",
    "county": "Fillmore County",
    "state": "MN",
    "city": "Spring Valley"
  },
  {
    "value": "55976",
    "label": "Stewartville - MN 55976",
    "county": "Olmsted County",
    "state": "MN",
    "city": "Stewartville"
  },
  {
    "value": "55977",
    "label": "Taopi - MN 55977",
    "county": "Mower County",
    "state": "MN",
    "city": "Taopi"
  },
  {
    "value": "55979",
    "label": "Utica - MN 55979",
    "county": "Winona County",
    "state": "MN",
    "city": "Utica"
  },
  {
    "value": "55981",
    "label": "Wabasha - MN 55981",
    "county": "Wabasha County",
    "state": "MN",
    "city": "Wabasha"
  },
  {
    "value": "55982",
    "label": "Waltham - MN 55982",
    "county": "Mower County",
    "state": "MN",
    "city": "Waltham"
  },
  {
    "value": "55983",
    "label": "Wanamingo - MN 55983",
    "county": "Goodhue County",
    "state": "MN",
    "city": "Wanamingo"
  },
  {
    "value": "55985",
    "label": "West Concord - MN 55985",
    "county": "Dodge County",
    "state": "MN",
    "city": "West Concord"
  },
  {
    "value": "55987",
    "label": "Winona - MN 55987",
    "county": "Winona County",
    "state": "MN",
    "city": "Winona"
  },
  {
    "value": "55990",
    "label": "Wykoff - MN 55990",
    "county": "Fillmore County",
    "state": "MN",
    "city": "Wykoff"
  },
  {
    "value": "55991",
    "label": "Zumbro Falls - MN 55991",
    "county": "Wabasha County",
    "state": "MN",
    "city": "Zumbro Falls"
  },
  {
    "value": "55992",
    "label": "Zumbrota - MN 55992",
    "county": "Goodhue County",
    "state": "MN",
    "city": "Zumbrota"
  },
  {
    "value": "56001",
    "label": "Mankato - MN 56001",
    "county": "Blue Earth County",
    "state": "MN",
    "city": "Mankato"
  },
  {
    "value": "56003",
    "label": "Mankato - MN 56003",
    "county": "Nicollet County",
    "state": "MN",
    "city": "Mankato"
  },
  {
    "value": "56007",
    "label": "Albert Lea - MN 56007",
    "county": "Freeborn County",
    "state": "MN",
    "city": "Albert Lea"
  },
  {
    "value": "56009",
    "label": "Alden - MN 56009",
    "county": "Freeborn County",
    "state": "MN",
    "city": "Alden"
  },
  {
    "value": "56010",
    "label": "Amboy - MN 56010",
    "county": "Blue Earth County",
    "state": "MN",
    "city": "Amboy"
  },
  {
    "value": "56011",
    "label": "Belle Plaine - MN 56011",
    "county": "Scott County",
    "state": "MN",
    "city": "Belle Plaine"
  },
  {
    "value": "56013",
    "label": "Blue Earth - MN 56013",
    "county": "Faribault County",
    "state": "MN",
    "city": "Blue Earth"
  },
  {
    "value": "56014",
    "label": "Bricelyn - MN 56014",
    "county": "Faribault County",
    "state": "MN",
    "city": "Bricelyn"
  },
  {
    "value": "56016",
    "label": "Clarks Grove - MN 56016",
    "county": "Freeborn County",
    "state": "MN",
    "city": "Clarks Grove"
  },
  {
    "value": "56017",
    "label": "Cleveland - MN 56017",
    "county": "Le Sueur County",
    "state": "MN",
    "city": "Cleveland"
  },
  {
    "value": "56019",
    "label": "Comfrey - MN 56019",
    "county": "Brown County",
    "state": "MN",
    "city": "Comfrey"
  },
  {
    "value": "56021",
    "label": "Courtland - MN 56021",
    "county": "Nicollet County",
    "state": "MN",
    "city": "Courtland"
  },
  {
    "value": "56023",
    "label": "Delavan - MN 56023",
    "county": "Faribault County",
    "state": "MN",
    "city": "Delavan"
  },
  {
    "value": "56024",
    "label": "Eagle Lake - MN 56024",
    "county": "Blue Earth County",
    "state": "MN",
    "city": "Eagle Lake"
  },
  {
    "value": "56025",
    "label": "Easton - MN 56025",
    "county": "Faribault County",
    "state": "MN",
    "city": "Easton"
  },
  {
    "value": "56026",
    "label": "Ellendale - MN 56026",
    "county": "Steele County",
    "state": "MN",
    "city": "Ellendale"
  },
  {
    "value": "56027",
    "label": "Elmore - MN 56027",
    "county": "Faribault County",
    "state": "MN",
    "city": "Elmore"
  },
  {
    "value": "56028",
    "label": "Elysian - MN 56028",
    "county": "Le Sueur County",
    "state": "MN",
    "city": "Elysian"
  },
  {
    "value": "56029",
    "label": "Emmons - MN 56029",
    "county": "Freeborn County",
    "state": "MN",
    "city": "Emmons"
  },
  {
    "value": "56031",
    "label": "Fairmont - MN 56031",
    "county": "Martin County",
    "state": "MN",
    "city": "Fairmont"
  },
  {
    "value": "56033",
    "label": "Frost - MN 56033",
    "county": "Faribault County",
    "state": "MN",
    "city": "Frost"
  },
  {
    "value": "56034",
    "label": "Garden City - MN 56034",
    "county": "Blue Earth County",
    "state": "MN",
    "city": "Garden City"
  },
  {
    "value": "56036",
    "label": "Glenville - MN 56036",
    "county": "Freeborn County",
    "state": "MN",
    "city": "Glenville"
  },
  {
    "value": "56037",
    "label": "Good Thunder - MN 56037",
    "county": "Blue Earth County",
    "state": "MN",
    "city": "Good Thunder"
  },
  {
    "value": "56039",
    "label": "Granada - MN 56039",
    "county": "Martin County",
    "state": "MN",
    "city": "Granada"
  },
  {
    "value": "56041",
    "label": "Hanska - MN 56041",
    "county": "Brown County",
    "state": "MN",
    "city": "Hanska"
  },
  {
    "value": "56042",
    "label": "Hartland - MN 56042",
    "county": "Freeborn County",
    "state": "MN",
    "city": "Hartland"
  },
  {
    "value": "56043",
    "label": "Hayward - MN 56043",
    "county": "Freeborn County",
    "state": "MN",
    "city": "Hayward"
  },
  {
    "value": "56044",
    "label": "Henderson - MN 56044",
    "county": "Sibley County",
    "state": "MN",
    "city": "Henderson"
  },
  {
    "value": "56045",
    "label": "Hollandale - MN 56045",
    "county": "Freeborn County",
    "state": "MN",
    "city": "Hollandale"
  },
  {
    "value": "56048",
    "label": "Janesville - MN 56048",
    "county": "Waseca County",
    "state": "MN",
    "city": "Janesville"
  },
  {
    "value": "56050",
    "label": "Kasota - MN 56050",
    "county": "Le Sueur County",
    "state": "MN",
    "city": "Kasota"
  },
  {
    "value": "56051",
    "label": "Kiester - MN 56051",
    "county": "Faribault County",
    "state": "MN",
    "city": "Kiester"
  },
  {
    "value": "56052",
    "label": "Kilkenny - MN 56052",
    "county": "Le Sueur County",
    "state": "MN",
    "city": "Kilkenny"
  },
  {
    "value": "56054",
    "label": "Lafayette - MN 56054",
    "county": "Nicollet County",
    "state": "MN",
    "city": "Lafayette"
  },
  {
    "value": "56055",
    "label": "Lake Crystal - MN 56055",
    "county": "Blue Earth County",
    "state": "MN",
    "city": "Lake Crystal"
  },
  {
    "value": "56057",
    "label": "Le Center - MN 56057",
    "county": "Le Sueur County",
    "state": "MN",
    "city": "Le Center"
  },
  {
    "value": "56058",
    "label": "Le Sueur - MN 56058",
    "county": "Le Sueur County",
    "state": "MN",
    "city": "Le Sueur"
  },
  {
    "value": "56060",
    "label": "Lewisville - MN 56060",
    "county": "Watonwan County",
    "state": "MN",
    "city": "Lewisville"
  },
  {
    "value": "56062",
    "label": "Madelia - MN 56062",
    "county": "Watonwan County",
    "state": "MN",
    "city": "Madelia"
  },
  {
    "value": "56063",
    "label": "Madison Lake - MN 56063",
    "county": "Blue Earth County",
    "state": "MN",
    "city": "Madison Lake"
  },
  {
    "value": "56065",
    "label": "Mapleton - MN 56065",
    "county": "Blue Earth County",
    "state": "MN",
    "city": "Mapleton"
  },
  {
    "value": "56068",
    "label": "Minnesota Lake - MN 56068",
    "county": "Faribault County",
    "state": "MN",
    "city": "Minnesota Lake"
  },
  {
    "value": "56069",
    "label": "Montgomery - MN 56069",
    "county": "Le Sueur County",
    "state": "MN",
    "city": "Montgomery"
  },
  {
    "value": "56071",
    "label": "New Prague - MN 56071",
    "county": "Scott County",
    "state": "MN",
    "city": "New Prague"
  },
  {
    "value": "56072",
    "label": "New Richland - MN 56072",
    "county": "Waseca County",
    "state": "MN",
    "city": "New Richland"
  },
  {
    "value": "56073",
    "label": "New Ulm - MN 56073",
    "county": "Brown County",
    "state": "MN",
    "city": "New Ulm"
  },
  {
    "value": "56074",
    "label": "Nicollet - MN 56074",
    "county": "Nicollet County",
    "state": "MN",
    "city": "Nicollet"
  },
  {
    "value": "56078",
    "label": "Pemberton - MN 56078",
    "county": "Blue Earth County",
    "state": "MN",
    "city": "Pemberton"
  },
  {
    "value": "56081",
    "label": "Saint James - MN 56081",
    "county": "Watonwan County",
    "state": "MN",
    "city": "Saint James"
  },
  {
    "value": "56082",
    "label": "Saint Peter - MN 56082",
    "county": "Nicollet County",
    "state": "MN",
    "city": "Saint Peter"
  },
  {
    "value": "56083",
    "label": "Sanborn - MN 56083",
    "county": "Redwood County",
    "state": "MN",
    "city": "Sanborn"
  },
  {
    "value": "56085",
    "label": "Sleepy Eye - MN 56085",
    "county": "Brown County",
    "state": "MN",
    "city": "Sleepy Eye"
  },
  {
    "value": "56087",
    "label": "Springfield - MN 56087",
    "county": "Brown County",
    "state": "MN",
    "city": "Springfield"
  },
  {
    "value": "56088",
    "label": "Truman - MN 56088",
    "county": "Martin County",
    "state": "MN",
    "city": "Truman"
  },
  {
    "value": "56089",
    "label": "Twin Lakes - MN 56089",
    "county": "Freeborn County",
    "state": "MN",
    "city": "Twin Lakes"
  },
  {
    "value": "56090",
    "label": "Vernon Center - MN 56090",
    "county": "Blue Earth County",
    "state": "MN",
    "city": "Vernon Center"
  },
  {
    "value": "56091",
    "label": "Waldorf - MN 56091",
    "county": "Waseca County",
    "state": "MN",
    "city": "Waldorf"
  },
  {
    "value": "56093",
    "label": "Waseca - MN 56093",
    "county": "Waseca County",
    "state": "MN",
    "city": "Waseca"
  },
  {
    "value": "56096",
    "label": "Waterville - MN 56096",
    "county": "Le Sueur County",
    "state": "MN",
    "city": "Waterville"
  },
  {
    "value": "56097",
    "label": "Wells - MN 56097",
    "county": "Faribault County",
    "state": "MN",
    "city": "Wells"
  },
  {
    "value": "56098",
    "label": "Winnebago - MN 56098",
    "county": "Faribault County",
    "state": "MN",
    "city": "Winnebago"
  },
  {
    "value": "56101",
    "label": "Windom - MN 56101",
    "county": "Cottonwood County",
    "state": "MN",
    "city": "Windom"
  },
  {
    "value": "56110",
    "label": "Adrian - MN 56110",
    "county": "Nobles County",
    "state": "MN",
    "city": "Adrian"
  },
  {
    "value": "56111",
    "label": "Alpha - MN 56111",
    "county": "Jackson County",
    "state": "MN",
    "city": "Alpha"
  },
  {
    "value": "56113",
    "label": "Arco - MN 56113",
    "county": "Lincoln County",
    "state": "MN",
    "city": "Arco"
  },
  {
    "value": "56114",
    "label": "Avoca - MN 56114",
    "county": "Murray County",
    "state": "MN",
    "city": "Avoca"
  },
  {
    "value": "56115",
    "label": "Balaton - MN 56115",
    "county": "Lyon County",
    "state": "MN",
    "city": "Balaton"
  },
  {
    "value": "56116",
    "label": "Beaver Creek - MN 56116",
    "county": "Rock County",
    "state": "MN",
    "city": "Beaver Creek"
  },
  {
    "value": "56117",
    "label": "Bigelow - MN 56117",
    "county": "Nobles County",
    "state": "MN",
    "city": "Bigelow"
  },
  {
    "value": "56118",
    "label": "Bingham Lake - MN 56118",
    "county": "Cottonwood County",
    "state": "MN",
    "city": "Bingham Lake"
  },
  {
    "value": "56119",
    "label": "Brewster - MN 56119",
    "county": "Nobles County",
    "state": "MN",
    "city": "Brewster"
  },
  {
    "value": "56120",
    "label": "Butterfield - MN 56120",
    "county": "Watonwan County",
    "state": "MN",
    "city": "Butterfield"
  },
  {
    "value": "56121",
    "label": "Ceylon - MN 56121",
    "county": "Martin County",
    "state": "MN",
    "city": "Ceylon"
  },
  {
    "value": "56122",
    "label": "Chandler - MN 56122",
    "county": "Murray County",
    "state": "MN",
    "city": "Chandler"
  },
  {
    "value": "56123",
    "label": "Currie - MN 56123",
    "county": "Murray County",
    "state": "MN",
    "city": "Currie"
  },
  {
    "value": "56125",
    "label": "Dovray - MN 56125",
    "county": "Murray County",
    "state": "MN",
    "city": "Dovray"
  },
  {
    "value": "56127",
    "label": "Dunnell - MN 56127",
    "county": "Martin County",
    "state": "MN",
    "city": "Dunnell"
  },
  {
    "value": "56128",
    "label": "Edgerton - MN 56128",
    "county": "Pipestone County",
    "state": "MN",
    "city": "Edgerton"
  },
  {
    "value": "56129",
    "label": "Ellsworth - MN 56129",
    "county": "Nobles County",
    "state": "MN",
    "city": "Ellsworth"
  },
  {
    "value": "56131",
    "label": "Fulda - MN 56131",
    "county": "Murray County",
    "state": "MN",
    "city": "Fulda"
  },
  {
    "value": "56132",
    "label": "Garvin - MN 56132",
    "county": "Lyon County",
    "state": "MN",
    "city": "Garvin"
  },
  {
    "value": "56134",
    "label": "Hardwick - MN 56134",
    "county": "Rock County",
    "state": "MN",
    "city": "Hardwick"
  },
  {
    "value": "56136",
    "label": "Hendricks - MN 56136",
    "county": "Lincoln County",
    "state": "MN",
    "city": "Hendricks"
  },
  {
    "value": "56137",
    "label": "Heron Lake - MN 56137",
    "county": "Jackson County",
    "state": "MN",
    "city": "Heron Lake"
  },
  {
    "value": "56138",
    "label": "Hills - MN 56138",
    "county": "Rock County",
    "state": "MN",
    "city": "Hills"
  },
  {
    "value": "56139",
    "label": "Holland - MN 56139",
    "county": "Pipestone County",
    "state": "MN",
    "city": "Holland"
  },
  {
    "value": "56141",
    "label": "Iona - MN 56141",
    "county": "Murray County",
    "state": "MN",
    "city": "Iona"
  },
  {
    "value": "56142",
    "label": "Ivanhoe - MN 56142",
    "county": "Lincoln County",
    "state": "MN",
    "city": "Ivanhoe"
  },
  {
    "value": "56143",
    "label": "Jackson - MN 56143",
    "county": "Jackson County",
    "state": "MN",
    "city": "Jackson"
  },
  {
    "value": "56144",
    "label": "Jasper - MN 56144",
    "county": "Pipestone County",
    "state": "MN",
    "city": "Jasper"
  },
  {
    "value": "56145",
    "label": "Jeffers - MN 56145",
    "county": "Cottonwood County",
    "state": "MN",
    "city": "Jeffers"
  },
  {
    "value": "56146",
    "label": "Kanaranzi - MN 56146",
    "county": "Rock County",
    "state": "MN",
    "city": "Kanaranzi"
  },
  {
    "value": "56147",
    "label": "Kenneth - MN 56147",
    "county": "Rock County",
    "state": "MN",
    "city": "Kenneth"
  },
  {
    "value": "56149",
    "label": "Lake Benton - MN 56149",
    "county": "Lincoln County",
    "state": "MN",
    "city": "Lake Benton"
  },
  {
    "value": "56150",
    "label": "Lakefield - MN 56150",
    "county": "Jackson County",
    "state": "MN",
    "city": "Lakefield"
  },
  {
    "value": "56151",
    "label": "Lake Wilson - MN 56151",
    "county": "Murray County",
    "state": "MN",
    "city": "Lake Wilson"
  },
  {
    "value": "56152",
    "label": "Lamberton - MN 56152",
    "county": "Redwood County",
    "state": "MN",
    "city": "Lamberton"
  },
  {
    "value": "56155",
    "label": "Lismore - MN 56155",
    "county": "Nobles County",
    "state": "MN",
    "city": "Lismore"
  },
  {
    "value": "56156",
    "label": "Luverne - MN 56156",
    "county": "Rock County",
    "state": "MN",
    "city": "Luverne"
  },
  {
    "value": "56157",
    "label": "Lynd - MN 56157",
    "county": "Lyon County",
    "state": "MN",
    "city": "Lynd"
  },
  {
    "value": "56158",
    "label": "Magnolia - MN 56158",
    "county": "Rock County",
    "state": "MN",
    "city": "Magnolia"
  },
  {
    "value": "56159",
    "label": "Mountain Lake - MN 56159",
    "county": "Cottonwood County",
    "state": "MN",
    "city": "Mountain Lake"
  },
  {
    "value": "56160",
    "label": "Odin - MN 56160",
    "county": "Watonwan County",
    "state": "MN",
    "city": "Odin"
  },
  {
    "value": "56161",
    "label": "Okabena - MN 56161",
    "county": "Jackson County",
    "state": "MN",
    "city": "Okabena"
  },
  {
    "value": "56162",
    "label": "Ormsby - MN 56162",
    "county": "Martin County",
    "state": "MN",
    "city": "Ormsby"
  },
  {
    "value": "56164",
    "label": "Pipestone - MN 56164",
    "county": "Pipestone County",
    "state": "MN",
    "city": "Pipestone"
  },
  {
    "value": "56165",
    "label": "Reading - MN 56165",
    "county": "Nobles County",
    "state": "MN",
    "city": "Reading"
  },
  {
    "value": "56166",
    "label": "Revere - MN 56166",
    "county": "Redwood County",
    "state": "MN",
    "city": "Revere"
  },
  {
    "value": "56167",
    "label": "Round Lake - MN 56167",
    "county": "Nobles County",
    "state": "MN",
    "city": "Round Lake"
  },
  {
    "value": "56168",
    "label": "Rushmore - MN 56168",
    "county": "Nobles County",
    "state": "MN",
    "city": "Rushmore"
  },
  {
    "value": "56169",
    "label": "Russell - MN 56169",
    "county": "Lyon County",
    "state": "MN",
    "city": "Russell"
  },
  {
    "value": "56170",
    "label": "Ruthton - MN 56170",
    "county": "Pipestone County",
    "state": "MN",
    "city": "Ruthton"
  },
  {
    "value": "56171",
    "label": "Sherburn - MN 56171",
    "county": "Martin County",
    "state": "MN",
    "city": "Sherburn"
  },
  {
    "value": "56172",
    "label": "Slayton - MN 56172",
    "county": "Murray County",
    "state": "MN",
    "city": "Slayton"
  },
  {
    "value": "56173",
    "label": "Steen - MN 56173",
    "county": "Rock County",
    "state": "MN",
    "city": "Steen"
  },
  {
    "value": "56174",
    "label": "Storden - MN 56174",
    "county": "Cottonwood County",
    "state": "MN",
    "city": "Storden"
  },
  {
    "value": "56175",
    "label": "Tracy - MN 56175",
    "county": "Lyon County",
    "state": "MN",
    "city": "Tracy"
  },
  {
    "value": "56176",
    "label": "Trimont - MN 56176",
    "county": "Martin County",
    "state": "MN",
    "city": "Trimont"
  },
  {
    "value": "56178",
    "label": "Tyler - MN 56178",
    "county": "Lincoln County",
    "state": "MN",
    "city": "Tyler"
  },
  {
    "value": "56180",
    "label": "Walnut Grove - MN 56180",
    "county": "Redwood County",
    "state": "MN",
    "city": "Walnut Grove"
  },
  {
    "value": "56181",
    "label": "Welcome - MN 56181",
    "county": "Martin County",
    "state": "MN",
    "city": "Welcome"
  },
  {
    "value": "56183",
    "label": "Westbrook - MN 56183",
    "county": "Cottonwood County",
    "state": "MN",
    "city": "Westbrook"
  },
  {
    "value": "56185",
    "label": "Wilmont - MN 56185",
    "county": "Nobles County",
    "state": "MN",
    "city": "Wilmont"
  },
  {
    "value": "56186",
    "label": "Woodstock - MN 56186",
    "county": "Pipestone County",
    "state": "MN",
    "city": "Woodstock"
  },
  {
    "value": "56187",
    "label": "Worthington - MN 56187",
    "county": "Nobles County",
    "state": "MN",
    "city": "Worthington"
  },
  {
    "value": "56201",
    "label": "Willmar - MN 56201",
    "county": "Kandiyohi County",
    "state": "MN",
    "city": "Willmar"
  },
  {
    "value": "56207",
    "label": "Alberta - MN 56207",
    "county": "Stevens County",
    "state": "MN",
    "city": "Alberta"
  },
  {
    "value": "56208",
    "label": "Appleton - MN 56208",
    "county": "Swift County",
    "state": "MN",
    "city": "Appleton"
  },
  {
    "value": "56209",
    "label": "Atwater - MN 56209",
    "county": "Kandiyohi County",
    "state": "MN",
    "city": "Atwater"
  },
  {
    "value": "56211",
    "label": "Beardsley - MN 56211",
    "county": "Big Stone County",
    "state": "MN",
    "city": "Beardsley"
  },
  {
    "value": "56212",
    "label": "Bellingham - MN 56212",
    "county": "Lac qui Parle County",
    "state": "MN",
    "city": "Bellingham"
  },
  {
    "value": "56214",
    "label": "Belview - MN 56214",
    "county": "Redwood County",
    "state": "MN",
    "city": "Belview"
  },
  {
    "value": "56215",
    "label": "Benson - MN 56215",
    "county": "Swift County",
    "state": "MN",
    "city": "Benson"
  },
  {
    "value": "56216",
    "label": "Blomkest - MN 56216",
    "county": "Kandiyohi County",
    "state": "MN",
    "city": "Blomkest"
  },
  {
    "value": "56218",
    "label": "Boyd - MN 56218",
    "county": "Lac qui Parle County",
    "state": "MN",
    "city": "Boyd"
  },
  {
    "value": "56219",
    "label": "Browns Valley - MN 56219",
    "county": "Traverse County",
    "state": "MN",
    "city": "Browns Valley"
  },
  {
    "value": "56220",
    "label": "Canby - MN 56220",
    "county": "Yellow Medicine County",
    "state": "MN",
    "city": "Canby"
  },
  {
    "value": "56221",
    "label": "Chokio - MN 56221",
    "county": "Stevens County",
    "state": "MN",
    "city": "Chokio"
  },
  {
    "value": "56222",
    "label": "Clara City - MN 56222",
    "county": "Chippewa County",
    "state": "MN",
    "city": "Clara City"
  },
  {
    "value": "56223",
    "label": "Clarkfield - MN 56223",
    "county": "Yellow Medicine County",
    "state": "MN",
    "city": "Clarkfield"
  },
  {
    "value": "56224",
    "label": "Clements - MN 56224",
    "county": "Redwood County",
    "state": "MN",
    "city": "Clements"
  },
  {
    "value": "56225",
    "label": "Clinton - MN 56225",
    "county": "Big Stone County",
    "state": "MN",
    "city": "Clinton"
  },
  {
    "value": "56226",
    "label": "Clontarf - MN 56226",
    "county": "Swift County",
    "state": "MN",
    "city": "Clontarf"
  },
  {
    "value": "56227",
    "label": "Correll - MN 56227",
    "county": "Big Stone County",
    "state": "MN",
    "city": "Correll"
  },
  {
    "value": "56228",
    "label": "Cosmos - MN 56228",
    "county": "Meeker County",
    "state": "MN",
    "city": "Cosmos"
  },
  {
    "value": "56229",
    "label": "Cottonwood - MN 56229",
    "county": "Lyon County",
    "state": "MN",
    "city": "Cottonwood"
  },
  {
    "value": "56230",
    "label": "Danube - MN 56230",
    "county": "Renville County",
    "state": "MN",
    "city": "Danube"
  },
  {
    "value": "56231",
    "label": "Danvers - MN 56231",
    "county": "Swift County",
    "state": "MN",
    "city": "Danvers"
  },
  {
    "value": "56232",
    "label": "Dawson - MN 56232",
    "county": "Lac qui Parle County",
    "state": "MN",
    "city": "Dawson"
  },
  {
    "value": "56235",
    "label": "Donnelly - MN 56235",
    "county": "Stevens County",
    "state": "MN",
    "city": "Donnelly"
  },
  {
    "value": "56236",
    "label": "Dumont - MN 56236",
    "county": "Traverse County",
    "state": "MN",
    "city": "Dumont"
  },
  {
    "value": "56237",
    "label": "Echo - MN 56237",
    "county": "Yellow Medicine County",
    "state": "MN",
    "city": "Echo"
  },
  {
    "value": "56239",
    "label": "Ghent - MN 56239",
    "county": "Lyon County",
    "state": "MN",
    "city": "Ghent"
  },
  {
    "value": "56240",
    "label": "Graceville - MN 56240",
    "county": "Big Stone County",
    "state": "MN",
    "city": "Graceville"
  },
  {
    "value": "56241",
    "label": "Granite Falls - MN 56241",
    "county": "Yellow Medicine County",
    "state": "MN",
    "city": "Granite Falls"
  },
  {
    "value": "56243",
    "label": "Grove City - MN 56243",
    "county": "Meeker County",
    "state": "MN",
    "city": "Grove City"
  },
  {
    "value": "56244",
    "label": "Hancock - MN 56244",
    "county": "Stevens County",
    "state": "MN",
    "city": "Hancock"
  },
  {
    "value": "56245",
    "label": "Hanley Falls - MN 56245",
    "county": "Yellow Medicine County",
    "state": "MN",
    "city": "Hanley Falls"
  },
  {
    "value": "56248",
    "label": "Herman - MN 56248",
    "county": "Grant County",
    "state": "MN",
    "city": "Herman"
  },
  {
    "value": "56249",
    "label": "Holloway - MN 56249",
    "county": "Swift County",
    "state": "MN",
    "city": "Holloway"
  },
  {
    "value": "56251",
    "label": "Kandiyohi - MN 56251",
    "county": "Kandiyohi County",
    "state": "MN",
    "city": "Kandiyohi"
  },
  {
    "value": "56252",
    "label": "Kerkhoven - MN 56252",
    "county": "Swift County",
    "state": "MN",
    "city": "Kerkhoven"
  },
  {
    "value": "56253",
    "label": "Lake Lillian - MN 56253",
    "county": "Kandiyohi County",
    "state": "MN",
    "city": "Lake Lillian"
  },
  {
    "value": "56255",
    "label": "Lucan - MN 56255",
    "county": "Redwood County",
    "state": "MN",
    "city": "Lucan"
  },
  {
    "value": "56256",
    "label": "Madison - MN 56256",
    "county": "Lac qui Parle County",
    "state": "MN",
    "city": "Madison"
  },
  {
    "value": "56257",
    "label": "Marietta - MN 56257",
    "county": "Lac qui Parle County",
    "state": "MN",
    "city": "Marietta"
  },
  {
    "value": "56258",
    "label": "Marshall - MN 56258",
    "county": "Lyon County",
    "state": "MN",
    "city": "Marshall"
  },
  {
    "value": "56260",
    "label": "Maynard - MN 56260",
    "county": "Chippewa County",
    "state": "MN",
    "city": "Maynard"
  },
  {
    "value": "56262",
    "label": "Milan - MN 56262",
    "county": "Chippewa County",
    "state": "MN",
    "city": "Milan"
  },
  {
    "value": "56263",
    "label": "Milroy - MN 56263",
    "county": "Redwood County",
    "state": "MN",
    "city": "Milroy"
  },
  {
    "value": "56264",
    "label": "Minneota - MN 56264",
    "county": "Lyon County",
    "state": "MN",
    "city": "Minneota"
  },
  {
    "value": "56265",
    "label": "Montevideo - MN 56265",
    "county": "Chippewa County",
    "state": "MN",
    "city": "Montevideo"
  },
  {
    "value": "56266",
    "label": "Morgan - MN 56266",
    "county": "Redwood County",
    "state": "MN",
    "city": "Morgan"
  },
  {
    "value": "56267",
    "label": "Morris - MN 56267",
    "county": "Stevens County",
    "state": "MN",
    "city": "Morris"
  },
  {
    "value": "56270",
    "label": "Morton - MN 56270",
    "county": "Renville County",
    "state": "MN",
    "city": "Morton"
  },
  {
    "value": "56271",
    "label": "Murdock - MN 56271",
    "county": "Swift County",
    "state": "MN",
    "city": "Murdock"
  },
  {
    "value": "56273",
    "label": "New London - MN 56273",
    "county": "Kandiyohi County",
    "state": "MN",
    "city": "New London"
  },
  {
    "value": "56274",
    "label": "Norcross - MN 56274",
    "county": "Grant County",
    "state": "MN",
    "city": "Norcross"
  },
  {
    "value": "56276",
    "label": "Odessa - MN 56276",
    "county": "Big Stone County",
    "state": "MN",
    "city": "Odessa"
  },
  {
    "value": "56277",
    "label": "Olivia - MN 56277",
    "county": "Renville County",
    "state": "MN",
    "city": "Olivia"
  },
  {
    "value": "56278",
    "label": "Ortonville - MN 56278",
    "county": "Big Stone County",
    "state": "MN",
    "city": "Ortonville"
  },
  {
    "value": "56279",
    "label": "Pennock - MN 56279",
    "county": "Kandiyohi County",
    "state": "MN",
    "city": "Pennock"
  },
  {
    "value": "56280",
    "label": "Porter - MN 56280",
    "county": "Yellow Medicine County",
    "state": "MN",
    "city": "Porter"
  },
  {
    "value": "56281",
    "label": "Prinsburg - MN 56281",
    "county": "Kandiyohi County",
    "state": "MN",
    "city": "Prinsburg"
  },
  {
    "value": "56282",
    "label": "Raymond - MN 56282",
    "county": "Kandiyohi County",
    "state": "MN",
    "city": "Raymond"
  },
  {
    "value": "56283",
    "label": "Redwood Falls - MN 56283",
    "county": "Redwood County",
    "state": "MN",
    "city": "Redwood Falls"
  },
  {
    "value": "56284",
    "label": "Renville - MN 56284",
    "county": "Renville County",
    "state": "MN",
    "city": "Renville"
  },
  {
    "value": "56285",
    "label": "Sacred Heart - MN 56285",
    "county": "Renville County",
    "state": "MN",
    "city": "Sacred Heart"
  },
  {
    "value": "56287",
    "label": "Seaforth - MN 56287",
    "county": "Redwood County",
    "state": "MN",
    "city": "Seaforth"
  },
  {
    "value": "56288",
    "label": "Spicer - MN 56288",
    "county": "Kandiyohi County",
    "state": "MN",
    "city": "Spicer"
  },
  {
    "value": "56289",
    "label": "Sunburg - MN 56289",
    "county": "Kandiyohi County",
    "state": "MN",
    "city": "Sunburg"
  },
  {
    "value": "56291",
    "label": "Taunton - MN 56291",
    "county": "Lyon County",
    "state": "MN",
    "city": "Taunton"
  },
  {
    "value": "56292",
    "label": "Vesta - MN 56292",
    "county": "Redwood County",
    "state": "MN",
    "city": "Vesta"
  },
  {
    "value": "56293",
    "label": "Wabasso - MN 56293",
    "county": "Redwood County",
    "state": "MN",
    "city": "Wabasso"
  },
  {
    "value": "56295",
    "label": "Watson - MN 56295",
    "county": "Chippewa County",
    "state": "MN",
    "city": "Watson"
  },
  {
    "value": "56296",
    "label": "Wheaton - MN 56296",
    "county": "Traverse County",
    "state": "MN",
    "city": "Wheaton"
  },
  {
    "value": "56297",
    "label": "Wood Lake - MN 56297",
    "county": "Yellow Medicine County",
    "state": "MN",
    "city": "Wood Lake"
  },
  {
    "value": "56301",
    "label": "Saint Cloud - MN 56301",
    "county": "Stearns County",
    "state": "MN",
    "city": "Saint Cloud"
  },
  {
    "value": "56303",
    "label": "Saint Cloud - MN 56303",
    "county": "Stearns County",
    "state": "MN",
    "city": "Saint Cloud"
  },
  {
    "value": "56304",
    "label": "Saint Cloud - MN 56304",
    "county": "Sherburne County",
    "state": "MN",
    "city": "Saint Cloud"
  },
  {
    "value": "56307",
    "label": "Albany - MN 56307",
    "county": "Stearns County",
    "state": "MN",
    "city": "Albany"
  },
  {
    "value": "56308",
    "label": "Alexandria - MN 56308",
    "county": "Douglas County",
    "state": "MN",
    "city": "Alexandria"
  },
  {
    "value": "56309",
    "label": "Ashby - MN 56309",
    "county": "Grant County",
    "state": "MN",
    "city": "Ashby"
  },
  {
    "value": "56310",
    "label": "Avon - MN 56310",
    "county": "Stearns County",
    "state": "MN",
    "city": "Avon"
  },
  {
    "value": "56311",
    "label": "Barrett - MN 56311",
    "county": "Grant County",
    "state": "MN",
    "city": "Barrett"
  },
  {
    "value": "56312",
    "label": "Belgrade - MN 56312",
    "county": "Stearns County",
    "state": "MN",
    "city": "Belgrade"
  },
  {
    "value": "56314",
    "label": "Bowlus - MN 56314",
    "county": "Morrison County",
    "state": "MN",
    "city": "Bowlus"
  },
  {
    "value": "56315",
    "label": "Brandon - MN 56315",
    "county": "Douglas County",
    "state": "MN",
    "city": "Brandon"
  },
  {
    "value": "56316",
    "label": "Brooten - MN 56316",
    "county": "Stearns County",
    "state": "MN",
    "city": "Brooten"
  },
  {
    "value": "56318",
    "label": "Burtrum - MN 56318",
    "county": "Todd County",
    "state": "MN",
    "city": "Burtrum"
  },
  {
    "value": "56319",
    "label": "Carlos - MN 56319",
    "county": "Douglas County",
    "state": "MN",
    "city": "Carlos"
  },
  {
    "value": "56320",
    "label": "Cold Spring - MN 56320",
    "county": "Stearns County",
    "state": "MN",
    "city": "Cold Spring"
  },
  {
    "value": "56323",
    "label": "Cyrus - MN 56323",
    "county": "Pope County",
    "state": "MN",
    "city": "Cyrus"
  },
  {
    "value": "56324",
    "label": "Dalton - MN 56324",
    "county": "Otter Tail County",
    "state": "MN",
    "city": "Dalton"
  },
  {
    "value": "56326",
    "label": "Evansville - MN 56326",
    "county": "Douglas County",
    "state": "MN",
    "city": "Evansville"
  },
  {
    "value": "56327",
    "label": "Farwell - MN 56327",
    "county": "Douglas County",
    "state": "MN",
    "city": "Farwell"
  },
  {
    "value": "56329",
    "label": "Foley - MN 56329",
    "county": "Benton County",
    "state": "MN",
    "city": "Foley"
  },
  {
    "value": "56330",
    "label": "Foreston - MN 56330",
    "county": "Mille Lacs County",
    "state": "MN",
    "city": "Foreston"
  },
  {
    "value": "56331",
    "label": "Freeport - MN 56331",
    "county": "Stearns County",
    "state": "MN",
    "city": "Freeport"
  },
  {
    "value": "56332",
    "label": "Garfield - MN 56332",
    "county": "Douglas County",
    "state": "MN",
    "city": "Garfield"
  },
  {
    "value": "56334",
    "label": "Glenwood - MN 56334",
    "county": "Pope County",
    "state": "MN",
    "city": "Glenwood"
  },
  {
    "value": "56336",
    "label": "Grey Eagle - MN 56336",
    "county": "Todd County",
    "state": "MN",
    "city": "Grey Eagle"
  },
  {
    "value": "56338",
    "label": "Hillman - MN 56338",
    "county": "Morrison County",
    "state": "MN",
    "city": "Hillman"
  },
  {
    "value": "56339",
    "label": "Hoffman - MN 56339",
    "county": "Grant County",
    "state": "MN",
    "city": "Hoffman"
  },
  {
    "value": "56340",
    "label": "Holdingford - MN 56340",
    "county": "Stearns County",
    "state": "MN",
    "city": "Holdingford"
  },
  {
    "value": "56342",
    "label": "Isle - MN 56342",
    "county": "Mille Lacs County",
    "state": "MN",
    "city": "Isle"
  },
  {
    "value": "56343",
    "label": "Kensington - MN 56343",
    "county": "Douglas County",
    "state": "MN",
    "city": "Kensington"
  },
  {
    "value": "56345",
    "label": "Little Falls - MN 56345",
    "county": "Morrison County",
    "state": "MN",
    "city": "Little Falls"
  },
  {
    "value": "56347",
    "label": "Long Prairie - MN 56347",
    "county": "Todd County",
    "state": "MN",
    "city": "Long Prairie"
  },
  {
    "value": "56349",
    "label": "Lowry - MN 56349",
    "county": "Pope County",
    "state": "MN",
    "city": "Lowry"
  },
  {
    "value": "56350",
    "label": "Mc Grath - MN 56350",
    "county": "Aitkin County",
    "state": "MN",
    "city": "Mc Grath"
  },
  {
    "value": "56352",
    "label": "Melrose - MN 56352",
    "county": "Stearns County",
    "state": "MN",
    "city": "Melrose"
  },
  {
    "value": "56353",
    "label": "Milaca - MN 56353",
    "county": "Mille Lacs County",
    "state": "MN",
    "city": "Milaca"
  },
  {
    "value": "56354",
    "label": "Miltona - MN 56354",
    "county": "Douglas County",
    "state": "MN",
    "city": "Miltona"
  },
  {
    "value": "56355",
    "label": "Nelson - MN 56355",
    "county": "Douglas County",
    "state": "MN",
    "city": "Nelson"
  },
  {
    "value": "56357",
    "label": "Oak Park - MN 56357",
    "county": "Benton County",
    "state": "MN",
    "city": "Oak Park"
  },
  {
    "value": "56358",
    "label": "Ogilvie - MN 56358",
    "county": "Kanabec County",
    "state": "MN",
    "city": "Ogilvie"
  },
  {
    "value": "56359",
    "label": "Onamia - MN 56359",
    "county": "Mille Lacs County",
    "state": "MN",
    "city": "Onamia"
  },
  {
    "value": "56360",
    "label": "Osakis - MN 56360",
    "county": "Douglas County",
    "state": "MN",
    "city": "Osakis"
  },
  {
    "value": "56361",
    "label": "Parkers Prairie - MN 56361",
    "county": "Otter Tail County",
    "state": "MN",
    "city": "Parkers Prairie"
  },
  {
    "value": "56362",
    "label": "Paynesville - MN 56362",
    "county": "Stearns County",
    "state": "MN",
    "city": "Paynesville"
  },
  {
    "value": "56364",
    "label": "Pierz - MN 56364",
    "county": "Morrison County",
    "state": "MN",
    "city": "Pierz"
  },
  {
    "value": "56367",
    "label": "Rice - MN 56367",
    "county": "Benton County",
    "state": "MN",
    "city": "Rice"
  },
  {
    "value": "56368",
    "label": "Richmond - MN 56368",
    "county": "Stearns County",
    "state": "MN",
    "city": "Richmond"
  },
  {
    "value": "56371",
    "label": "Roscoe - MN 56371",
    "county": "Stearns County",
    "state": "MN",
    "city": "Roscoe"
  },
  {
    "value": "56373",
    "label": "Royalton - MN 56373",
    "county": "Morrison County",
    "state": "MN",
    "city": "Royalton"
  },
  {
    "value": "56374",
    "label": "Saint Joseph - MN 56374",
    "county": "Stearns County",
    "state": "MN",
    "city": "Saint Joseph"
  },
  {
    "value": "56375",
    "label": "Saint Stephen - MN 56375",
    "county": "Stearns County",
    "state": "MN",
    "city": "Saint Stephen"
  },
  {
    "value": "56377",
    "label": "Sartell - MN 56377",
    "county": "Stearns County",
    "state": "MN",
    "city": "Sartell"
  },
  {
    "value": "56378",
    "label": "Sauk Centre - MN 56378",
    "county": "Stearns County",
    "state": "MN",
    "city": "Sauk Centre"
  },
  {
    "value": "56379",
    "label": "Sauk Rapids - MN 56379",
    "county": "Benton County",
    "state": "MN",
    "city": "Sauk Rapids"
  },
  {
    "value": "56381",
    "label": "Starbuck - MN 56381",
    "county": "Pope County",
    "state": "MN",
    "city": "Starbuck"
  },
  {
    "value": "56382",
    "label": "Swanville - MN 56382",
    "county": "Morrison County",
    "state": "MN",
    "city": "Swanville"
  },
  {
    "value": "56384",
    "label": "Upsala - MN 56384",
    "county": "Morrison County",
    "state": "MN",
    "city": "Upsala"
  },
  {
    "value": "56385",
    "label": "Villard - MN 56385",
    "county": "Pope County",
    "state": "MN",
    "city": "Villard"
  },
  {
    "value": "56386",
    "label": "Wahkon - MN 56386",
    "county": "Mille Lacs County",
    "state": "MN",
    "city": "Wahkon"
  },
  {
    "value": "56387",
    "label": "Waite Park - MN 56387",
    "county": "Stearns County",
    "state": "MN",
    "city": "Waite Park"
  },
  {
    "value": "56401",
    "label": "Brainerd - MN 56401",
    "county": "Crow Wing County",
    "state": "MN",
    "city": "Brainerd"
  },
  {
    "value": "56425",
    "label": "Baxter - MN 56425",
    "county": "Crow Wing County",
    "state": "MN",
    "city": "Baxter"
  },
  {
    "value": "56431",
    "label": "Aitkin - MN 56431",
    "county": "Aitkin County",
    "state": "MN",
    "city": "Aitkin"
  },
  {
    "value": "56433",
    "label": "Akeley - MN 56433",
    "county": "Hubbard County",
    "state": "MN",
    "city": "Akeley"
  },
  {
    "value": "56434",
    "label": "Aldrich - MN 56434",
    "county": "Todd County",
    "state": "MN",
    "city": "Aldrich"
  },
  {
    "value": "56435",
    "label": "Backus - MN 56435",
    "county": "Cass County",
    "state": "MN",
    "city": "Backus"
  },
  {
    "value": "56437",
    "label": "Bertha - MN 56437",
    "county": "Todd County",
    "state": "MN",
    "city": "Bertha"
  },
  {
    "value": "56438",
    "label": "Browerville - MN 56438",
    "county": "Todd County",
    "state": "MN",
    "city": "Browerville"
  },
  {
    "value": "56440",
    "label": "Clarissa - MN 56440",
    "county": "Todd County",
    "state": "MN",
    "city": "Clarissa"
  },
  {
    "value": "56441",
    "label": "Crosby - MN 56441",
    "county": "Crow Wing County",
    "state": "MN",
    "city": "Crosby"
  },
  {
    "value": "56442",
    "label": "Crosslake - MN 56442",
    "county": "Crow Wing County",
    "state": "MN",
    "city": "Crosslake"
  },
  {
    "value": "56443",
    "label": "Cushing - MN 56443",
    "county": "Morrison County",
    "state": "MN",
    "city": "Cushing"
  },
  {
    "value": "56444",
    "label": "Deerwood - MN 56444",
    "county": "Crow Wing County",
    "state": "MN",
    "city": "Deerwood"
  },
  {
    "value": "56446",
    "label": "Eagle Bend - MN 56446",
    "county": "Todd County",
    "state": "MN",
    "city": "Eagle Bend"
  },
  {
    "value": "56447",
    "label": "Emily - MN 56447",
    "county": "Crow Wing County",
    "state": "MN",
    "city": "Emily"
  },
  {
    "value": "56448",
    "label": "Fifty Lakes - MN 56448",
    "county": "Crow Wing County",
    "state": "MN",
    "city": "Fifty Lakes"
  },
  {
    "value": "56449",
    "label": "Fort Ripley - MN 56449",
    "county": "Crow Wing County",
    "state": "MN",
    "city": "Fort Ripley"
  },
  {
    "value": "56450",
    "label": "Garrison - MN 56450",
    "county": "Crow Wing County",
    "state": "MN",
    "city": "Garrison"
  },
  {
    "value": "56452",
    "label": "Hackensack - MN 56452",
    "county": "Cass County",
    "state": "MN",
    "city": "Hackensack"
  },
  {
    "value": "56453",
    "label": "Hewitt - MN 56453",
    "county": "Todd County",
    "state": "MN",
    "city": "Hewitt"
  },
  {
    "value": "56455",
    "label": "Ironton - MN 56455",
    "county": "Crow Wing County",
    "state": "MN",
    "city": "Ironton"
  },
  {
    "value": "56458",
    "label": "Lake George - MN 56458",
    "county": "Hubbard County",
    "state": "MN",
    "city": "Lake George"
  },
  {
    "value": "56461",
    "label": "Laporte - MN 56461",
    "county": "Hubbard County",
    "state": "MN",
    "city": "Laporte"
  },
  {
    "value": "56464",
    "label": "Menahga - MN 56464",
    "county": "Wadena County",
    "state": "MN",
    "city": "Menahga"
  },
  {
    "value": "56465",
    "label": "Merrifield - MN 56465",
    "county": "Crow Wing County",
    "state": "MN",
    "city": "Merrifield"
  },
  {
    "value": "56466",
    "label": "Motley - MN 56466",
    "county": "Cass County",
    "state": "MN",
    "city": "Motley"
  },
  {
    "value": "56467",
    "label": "Nevis - MN 56467",
    "county": "Hubbard County",
    "state": "MN",
    "city": "Nevis"
  },
  {
    "value": "56468",
    "label": "Nisswa - MN 56468",
    "county": "Crow Wing County",
    "state": "MN",
    "city": "Nisswa"
  },
  {
    "value": "56469",
    "label": "Palisade - MN 56469",
    "county": "Aitkin County",
    "state": "MN",
    "city": "Palisade"
  },
  {
    "value": "56470",
    "label": "Park Rapids - MN 56470",
    "county": "Hubbard County",
    "state": "MN",
    "city": "Park Rapids"
  },
  {
    "value": "56472",
    "label": "Pequot Lakes - MN 56472",
    "county": "Crow Wing County",
    "state": "MN",
    "city": "Pequot Lakes"
  },
  {
    "value": "56473",
    "label": "Pillager - MN 56473",
    "county": "Cass County",
    "state": "MN",
    "city": "Pillager"
  },
  {
    "value": "56474",
    "label": "Pine River - MN 56474",
    "county": "Cass County",
    "state": "MN",
    "city": "Pine River"
  },
  {
    "value": "56475",
    "label": "Randall - MN 56475",
    "county": "Morrison County",
    "state": "MN",
    "city": "Randall"
  },
  {
    "value": "56477",
    "label": "Sebeka - MN 56477",
    "county": "Wadena County",
    "state": "MN",
    "city": "Sebeka"
  },
  {
    "value": "56478",
    "label": "Nimrod - MN 56478",
    "county": "Wadena County",
    "state": "MN",
    "city": "Nimrod"
  },
  {
    "value": "56479",
    "label": "Staples - MN 56479",
    "county": "Todd County",
    "state": "MN",
    "city": "Staples"
  },
  {
    "value": "56481",
    "label": "Verndale - MN 56481",
    "county": "Wadena County",
    "state": "MN",
    "city": "Verndale"
  },
  {
    "value": "56482",
    "label": "Wadena - MN 56482",
    "county": "Wadena County",
    "state": "MN",
    "city": "Wadena"
  },
  {
    "value": "56484",
    "label": "Walker - MN 56484",
    "county": "Cass County",
    "state": "MN",
    "city": "Walker"
  },
  {
    "value": "56501",
    "label": "Detroit Lakes - MN 56501",
    "county": "Becker County",
    "state": "MN",
    "city": "Detroit Lakes"
  },
  {
    "value": "56510",
    "label": "Ada - MN 56510",
    "county": "Norman County",
    "state": "MN",
    "city": "Ada"
  },
  {
    "value": "56511",
    "label": "Audubon - MN 56511",
    "county": "Becker County",
    "state": "MN",
    "city": "Audubon"
  },
  {
    "value": "56514",
    "label": "Barnesville - MN 56514",
    "county": "Clay County",
    "state": "MN",
    "city": "Barnesville"
  },
  {
    "value": "56515",
    "label": "Battle Lake - MN 56515",
    "county": "Otter Tail County",
    "state": "MN",
    "city": "Battle Lake"
  },
  {
    "value": "56516",
    "label": "Bejou - MN 56516",
    "county": "Norman County",
    "state": "MN",
    "city": "Bejou"
  },
  {
    "value": "56517",
    "label": "Beltrami - MN 56517",
    "county": "Polk County",
    "state": "MN",
    "city": "Beltrami"
  },
  {
    "value": "56518",
    "label": "Bluffton - MN 56518",
    "county": "Otter Tail County",
    "state": "MN",
    "city": "Bluffton"
  },
  {
    "value": "56519",
    "label": "Borup - MN 56519",
    "county": "Norman County",
    "state": "MN",
    "city": "Borup"
  },
  {
    "value": "56520",
    "label": "Breckenridge - MN 56520",
    "county": "Wilkin County",
    "state": "MN",
    "city": "Breckenridge"
  },
  {
    "value": "56521",
    "label": "Callaway - MN 56521",
    "county": "Becker County",
    "state": "MN",
    "city": "Callaway"
  },
  {
    "value": "56522",
    "label": "Campbell - MN 56522",
    "county": "Wilkin County",
    "state": "MN",
    "city": "Campbell"
  },
  {
    "value": "56523",
    "label": "Climax - MN 56523",
    "county": "Polk County",
    "state": "MN",
    "city": "Climax"
  },
  {
    "value": "56524",
    "label": "Clitherall - MN 56524",
    "county": "Otter Tail County",
    "state": "MN",
    "city": "Clitherall"
  },
  {
    "value": "56527",
    "label": "Deer Creek - MN 56527",
    "county": "Otter Tail County",
    "state": "MN",
    "city": "Deer Creek"
  },
  {
    "value": "56528",
    "label": "Dent - MN 56528",
    "county": "Otter Tail County",
    "state": "MN",
    "city": "Dent"
  },
  {
    "value": "56529",
    "label": "Dilworth - MN 56529",
    "county": "Clay County",
    "state": "MN",
    "city": "Dilworth"
  },
  {
    "value": "56531",
    "label": "Elbow Lake - MN 56531",
    "county": "Grant County",
    "state": "MN",
    "city": "Elbow Lake"
  },
  {
    "value": "56533",
    "label": "Elizabeth - MN 56533",
    "county": "Otter Tail County",
    "state": "MN",
    "city": "Elizabeth"
  },
  {
    "value": "56534",
    "label": "Erhard - MN 56534",
    "county": "Otter Tail County",
    "state": "MN",
    "city": "Erhard"
  },
  {
    "value": "56535",
    "label": "Erskine - MN 56535",
    "county": "Polk County",
    "state": "MN",
    "city": "Erskine"
  },
  {
    "value": "56536",
    "label": "Felton - MN 56536",
    "county": "Clay County",
    "state": "MN",
    "city": "Felton"
  },
  {
    "value": "56537",
    "label": "Fergus Falls - MN 56537",
    "county": "Otter Tail County",
    "state": "MN",
    "city": "Fergus Falls"
  },
  {
    "value": "56540",
    "label": "Fertile - MN 56540",
    "county": "Polk County",
    "state": "MN",
    "city": "Fertile"
  },
  {
    "value": "56542",
    "label": "Fosston - MN 56542",
    "county": "Polk County",
    "state": "MN",
    "city": "Fosston"
  },
  {
    "value": "56543",
    "label": "Foxhome - MN 56543",
    "county": "Wilkin County",
    "state": "MN",
    "city": "Foxhome"
  },
  {
    "value": "56544",
    "label": "Frazee - MN 56544",
    "county": "Becker County",
    "state": "MN",
    "city": "Frazee"
  },
  {
    "value": "56545",
    "label": "Gary - MN 56545",
    "county": "Norman County",
    "state": "MN",
    "city": "Gary"
  },
  {
    "value": "56546",
    "label": "Georgetown - MN 56546",
    "county": "Clay County",
    "state": "MN",
    "city": "Georgetown"
  },
  {
    "value": "56547",
    "label": "Glyndon - MN 56547",
    "county": "Clay County",
    "state": "MN",
    "city": "Glyndon"
  },
  {
    "value": "56548",
    "label": "Halstad - MN 56548",
    "county": "Norman County",
    "state": "MN",
    "city": "Halstad"
  },
  {
    "value": "56549",
    "label": "Hawley - MN 56549",
    "county": "Clay County",
    "state": "MN",
    "city": "Hawley"
  },
  {
    "value": "56550",
    "label": "Hendrum - MN 56550",
    "county": "Norman County",
    "state": "MN",
    "city": "Hendrum"
  },
  {
    "value": "56551",
    "label": "Henning - MN 56551",
    "county": "Otter Tail County",
    "state": "MN",
    "city": "Henning"
  },
  {
    "value": "56552",
    "label": "Hitterdal - MN 56552",
    "county": "Clay County",
    "state": "MN",
    "city": "Hitterdal"
  },
  {
    "value": "56553",
    "label": "Kent - MN 56553",
    "county": "Wilkin County",
    "state": "MN",
    "city": "Kent"
  },
  {
    "value": "56554",
    "label": "Lake Park - MN 56554",
    "county": "Becker County",
    "state": "MN",
    "city": "Lake Park"
  },
  {
    "value": "56556",
    "label": "Mcintosh - MN 56556",
    "county": "Polk County",
    "state": "MN",
    "city": "Mcintosh"
  },
  {
    "value": "56557",
    "label": "Mahnomen - MN 56557",
    "county": "Norman County",
    "state": "MN",
    "city": "Mahnomen"
  },
  {
    "value": "56560",
    "label": "Moorhead - MN 56560",
    "county": "Clay County",
    "state": "MN",
    "city": "Moorhead"
  },
  {
    "value": "56562",
    "label": "Moorhead - MN 56562",
    "county": "Clay County",
    "state": "MN",
    "city": "Moorhead"
  },
  {
    "value": "56563",
    "label": "Moorhead - MN 56563",
    "county": "Clay County",
    "state": "MN",
    "city": "Moorhead"
  },
  {
    "value": "56565",
    "label": "Nashua - MN 56565",
    "county": "Wilkin County",
    "state": "MN",
    "city": "Nashua"
  },
  {
    "value": "56566",
    "label": "Naytahwaush - MN 56566",
    "county": "Mahnomen County",
    "state": "MN",
    "city": "Naytahwaush"
  },
  {
    "value": "56567",
    "label": "New York Mills - MN 56567",
    "county": "Otter Tail County",
    "state": "MN",
    "city": "New York Mills"
  },
  {
    "value": "56568",
    "label": "Nielsville - MN 56568",
    "county": "Polk County",
    "state": "MN",
    "city": "Nielsville"
  },
  {
    "value": "56569",
    "label": "Ogema - MN 56569",
    "county": "Becker County",
    "state": "MN",
    "city": "Ogema"
  },
  {
    "value": "56570",
    "label": "Osage - MN 56570",
    "county": "Becker County",
    "state": "MN",
    "city": "Osage"
  },
  {
    "value": "56571",
    "label": "Ottertail - MN 56571",
    "county": "Otter Tail County",
    "state": "MN",
    "city": "Ottertail"
  },
  {
    "value": "56572",
    "label": "Pelican Rapids - MN 56572",
    "county": "Otter Tail County",
    "state": "MN",
    "city": "Pelican Rapids"
  },
  {
    "value": "56573",
    "label": "Perham - MN 56573",
    "county": "Otter Tail County",
    "state": "MN",
    "city": "Perham"
  },
  {
    "value": "56574",
    "label": "Perley - MN 56574",
    "county": "Norman County",
    "state": "MN",
    "city": "Perley"
  },
  {
    "value": "56575",
    "label": "Ponsford - MN 56575",
    "county": "Becker County",
    "state": "MN",
    "city": "Ponsford"
  },
  {
    "value": "56576",
    "label": "Richville - MN 56576",
    "county": "Otter Tail County",
    "state": "MN",
    "city": "Richville"
  },
  {
    "value": "56578",
    "label": "Rochert - MN 56578",
    "county": "Becker County",
    "state": "MN",
    "city": "Rochert"
  },
  {
    "value": "56579",
    "label": "Rothsay - MN 56579",
    "county": "Wilkin County",
    "state": "MN",
    "city": "Rothsay"
  },
  {
    "value": "56580",
    "label": "Sabin - MN 56580",
    "county": "Clay County",
    "state": "MN",
    "city": "Sabin"
  },
  {
    "value": "56581",
    "label": "Shelly - MN 56581",
    "county": "Norman County",
    "state": "MN",
    "city": "Shelly"
  },
  {
    "value": "56583",
    "label": "Tintah - MN 56583",
    "county": "Traverse County",
    "state": "MN",
    "city": "Tintah"
  },
  {
    "value": "56584",
    "label": "Twin Valley - MN 56584",
    "county": "Norman County",
    "state": "MN",
    "city": "Twin Valley"
  },
  {
    "value": "56585",
    "label": "Ulen - MN 56585",
    "county": "Clay County",
    "state": "MN",
    "city": "Ulen"
  },
  {
    "value": "56586",
    "label": "Underwood - MN 56586",
    "county": "Otter Tail County",
    "state": "MN",
    "city": "Underwood"
  },
  {
    "value": "56587",
    "label": "Vergas - MN 56587",
    "county": "Otter Tail County",
    "state": "MN",
    "city": "Vergas"
  },
  {
    "value": "56588",
    "label": "Vining - MN 56588",
    "county": "Otter Tail County",
    "state": "MN",
    "city": "Vining"
  },
  {
    "value": "56589",
    "label": "Waubun - MN 56589",
    "county": "Clearwater County",
    "state": "MN",
    "city": "Waubun"
  },
  {
    "value": "56590",
    "label": "Wendell - MN 56590",
    "county": "Grant County",
    "state": "MN",
    "city": "Wendell"
  },
  {
    "value": "56592",
    "label": "Winger - MN 56592",
    "county": "Polk County",
    "state": "MN",
    "city": "Winger"
  },
  {
    "value": "56594",
    "label": "Wolverton - MN 56594",
    "county": "Wilkin County",
    "state": "MN",
    "city": "Wolverton"
  },
  {
    "value": "56601",
    "label": "Bemidji - MN 56601",
    "county": "Beltrami County",
    "state": "MN",
    "city": "Bemidji"
  },
  {
    "value": "56621",
    "label": "Bagley - MN 56621",
    "county": "Clearwater County",
    "state": "MN",
    "city": "Bagley"
  },
  {
    "value": "56623",
    "label": "Baudette - MN 56623",
    "county": "Lake of the Woods County",
    "state": "MN",
    "city": "Baudette"
  },
  {
    "value": "56626",
    "label": "Bena - MN 56626",
    "county": "Cass County",
    "state": "MN",
    "city": "Bena"
  },
  {
    "value": "56627",
    "label": "Big Falls - MN 56627",
    "county": "Koochiching County",
    "state": "MN",
    "city": "Big Falls"
  },
  {
    "value": "56628",
    "label": "Bigfork - MN 56628",
    "county": "Itasca County",
    "state": "MN",
    "city": "Bigfork"
  },
  {
    "value": "56630",
    "label": "Blackduck - MN 56630",
    "county": "Beltrami County",
    "state": "MN",
    "city": "Blackduck"
  },
  {
    "value": "56633",
    "label": "Cass Lake - MN 56633",
    "county": "Hubbard County",
    "state": "MN",
    "city": "Cass Lake"
  },
  {
    "value": "56634",
    "label": "Clearbrook - MN 56634",
    "county": "Clearwater County",
    "state": "MN",
    "city": "Clearbrook"
  },
  {
    "value": "56636",
    "label": "Deer River - MN 56636",
    "county": "Itasca County",
    "state": "MN",
    "city": "Deer River"
  },
  {
    "value": "56637",
    "label": "Talmoon - MN 56637",
    "county": "Itasca County",
    "state": "MN",
    "city": "Talmoon"
  },
  {
    "value": "56639",
    "label": "Effie - MN 56639",
    "county": "Itasca County",
    "state": "MN",
    "city": "Effie"
  },
  {
    "value": "56641",
    "label": "Federal Dam - MN 56641",
    "county": "Cass County",
    "state": "MN",
    "city": "Federal Dam"
  },
  {
    "value": "56644",
    "label": "Gonvick - MN 56644",
    "county": "Clearwater County",
    "state": "MN",
    "city": "Gonvick"
  },
  {
    "value": "56646",
    "label": "Gully - MN 56646",
    "county": "Polk County",
    "state": "MN",
    "city": "Gully"
  },
  {
    "value": "56647",
    "label": "Hines - MN 56647",
    "county": "Beltrami County",
    "state": "MN",
    "city": "Hines"
  },
  {
    "value": "56649",
    "label": "International Falls - MN 56649",
    "county": "Koochiching County",
    "state": "MN",
    "city": "International Falls"
  },
  {
    "value": "56650",
    "label": "Kelliher - MN 56650",
    "county": "Beltrami County",
    "state": "MN",
    "city": "Kelliher"
  },
  {
    "value": "56651",
    "label": "Lengby - MN 56651",
    "county": "Polk County",
    "state": "MN",
    "city": "Lengby"
  },
  {
    "value": "56652",
    "label": "Leonard - MN 56652",
    "county": "Clearwater County",
    "state": "MN",
    "city": "Leonard"
  },
  {
    "value": "56653",
    "label": "Littlefork - MN 56653",
    "county": "Koochiching County",
    "state": "MN",
    "city": "Littlefork"
  },
  {
    "value": "56654",
    "label": "Loman - MN 56654",
    "county": "Koochiching County",
    "state": "MN",
    "city": "Loman"
  },
  {
    "value": "56655",
    "label": "Longville - MN 56655",
    "county": "Cass County",
    "state": "MN",
    "city": "Longville"
  },
  {
    "value": "56657",
    "label": "Marcell - MN 56657",
    "county": "Itasca County",
    "state": "MN",
    "city": "Marcell"
  },
  {
    "value": "56658",
    "label": "Margie - MN 56658",
    "county": "Koochiching County",
    "state": "MN",
    "city": "Margie"
  },
  {
    "value": "56659",
    "label": "Max - MN 56659",
    "county": "Itasca County",
    "state": "MN",
    "city": "Max"
  },
  {
    "value": "56660",
    "label": "Mizpah - MN 56660",
    "county": "Koochiching County",
    "state": "MN",
    "city": "Mizpah"
  },
  {
    "value": "56661",
    "label": "Northome - MN 56661",
    "county": "Koochiching County",
    "state": "MN",
    "city": "Northome"
  },
  {
    "value": "56662",
    "label": "Outing - MN 56662",
    "county": "Cass County",
    "state": "MN",
    "city": "Outing"
  },
  {
    "value": "56663",
    "label": "Pennington - MN 56663",
    "county": "Beltrami County",
    "state": "MN",
    "city": "Pennington"
  },
  {
    "value": "56667",
    "label": "Puposky - MN 56667",
    "county": "Beltrami County",
    "state": "MN",
    "city": "Puposky"
  },
  {
    "value": "56669",
    "label": "Kabetogama - MN 56669",
    "county": "Koochiching County",
    "state": "MN",
    "city": "Kabetogama"
  },
  {
    "value": "56672",
    "label": "Remer - MN 56672",
    "county": "Cass County",
    "state": "MN",
    "city": "Remer"
  },
  {
    "value": "56673",
    "label": "Roosevelt - MN 56673",
    "county": "Roseau County",
    "state": "MN",
    "city": "Roosevelt"
  },
  {
    "value": "56676",
    "label": "Shevlin - MN 56676",
    "county": "Clearwater County",
    "state": "MN",
    "city": "Shevlin"
  },
  {
    "value": "56678",
    "label": "Solway - MN 56678",
    "county": "Beltrami County",
    "state": "MN",
    "city": "Solway"
  },
  {
    "value": "56680",
    "label": "Spring Lake - MN 56680",
    "county": "Itasca County",
    "state": "MN",
    "city": "Spring Lake"
  },
  {
    "value": "56681",
    "label": "Squaw Lake - MN 56681",
    "county": "Itasca County",
    "state": "MN",
    "city": "Squaw Lake"
  },
  {
    "value": "56682",
    "label": "Swift - MN 56682",
    "county": "Roseau County",
    "state": "MN",
    "city": "Swift"
  },
  {
    "value": "56683",
    "label": "Tenstrike - MN 56683",
    "county": "Beltrami County",
    "state": "MN",
    "city": "Tenstrike"
  },
  {
    "value": "56684",
    "label": "Trail - MN 56684",
    "county": "Polk County",
    "state": "MN",
    "city": "Trail"
  },
  {
    "value": "56685",
    "label": "Waskish - MN 56685",
    "county": "Beltrami County",
    "state": "MN",
    "city": "Waskish"
  },
  {
    "value": "56686",
    "label": "Williams - MN 56686",
    "county": "Lake of the Woods County",
    "state": "MN",
    "city": "Williams"
  },
  {
    "value": "56688",
    "label": "Wirt - MN 56688",
    "county": "Itasca County",
    "state": "MN",
    "city": "Wirt"
  },
  {
    "value": "56701",
    "label": "Thief River Falls - MN 56701",
    "county": "Pennington County",
    "state": "MN",
    "city": "Thief River Falls"
  },
  {
    "value": "56710",
    "label": "Alvarado - MN 56710",
    "county": "Marshall County",
    "state": "MN",
    "city": "Alvarado"
  },
  {
    "value": "56713",
    "label": "Argyle - MN 56713",
    "county": "Marshall County",
    "state": "MN",
    "city": "Argyle"
  },
  {
    "value": "56714",
    "label": "Badger - MN 56714",
    "county": "Roseau County",
    "state": "MN",
    "city": "Badger"
  },
  {
    "value": "56715",
    "label": "Brooks - MN 56715",
    "county": "Red Lake County",
    "state": "MN",
    "city": "Brooks"
  },
  {
    "value": "56716",
    "label": "Crookston - MN 56716",
    "county": "Polk County",
    "state": "MN",
    "city": "Crookston"
  },
  {
    "value": "56721",
    "label": "East Grand Forks - MN 56721",
    "county": "Polk County",
    "state": "MN",
    "city": "East Grand Forks"
  },
  {
    "value": "56722",
    "label": "Euclid - MN 56722",
    "county": "Polk County",
    "state": "MN",
    "city": "Euclid"
  },
  {
    "value": "56723",
    "label": "Fisher - MN 56723",
    "county": "Polk County",
    "state": "MN",
    "city": "Fisher"
  },
  {
    "value": "56724",
    "label": "Gatzke - MN 56724",
    "county": "Marshall County",
    "state": "MN",
    "city": "Gatzke"
  },
  {
    "value": "56725",
    "label": "Goodridge - MN 56725",
    "county": "Pennington County",
    "state": "MN",
    "city": "Goodridge"
  },
  {
    "value": "56726",
    "label": "Greenbush - MN 56726",
    "county": "Roseau County",
    "state": "MN",
    "city": "Greenbush"
  },
  {
    "value": "56727",
    "label": "Grygla - MN 56727",
    "county": "Marshall County",
    "state": "MN",
    "city": "Grygla"
  },
  {
    "value": "56728",
    "label": "Hallock - MN 56728",
    "county": "Kittson County",
    "state": "MN",
    "city": "Hallock"
  },
  {
    "value": "56729",
    "label": "Halma - MN 56729",
    "county": "Kittson County",
    "state": "MN",
    "city": "Halma"
  },
  {
    "value": "56732",
    "label": "Karlstad - MN 56732",
    "county": "Kittson County",
    "state": "MN",
    "city": "Karlstad"
  },
  {
    "value": "56733",
    "label": "Kennedy - MN 56733",
    "county": "Kittson County",
    "state": "MN",
    "city": "Kennedy"
  },
  {
    "value": "56734",
    "label": "Lake Bronson - MN 56734",
    "county": "Kittson County",
    "state": "MN",
    "city": "Lake Bronson"
  },
  {
    "value": "56735",
    "label": "Lancaster - MN 56735",
    "county": "Kittson County",
    "state": "MN",
    "city": "Lancaster"
  },
  {
    "value": "56736",
    "label": "Mentor - MN 56736",
    "county": "Polk County",
    "state": "MN",
    "city": "Mentor"
  },
  {
    "value": "56737",
    "label": "Middle River - MN 56737",
    "county": "Marshall County",
    "state": "MN",
    "city": "Middle River"
  },
  {
    "value": "56738",
    "label": "Newfolden - MN 56738",
    "county": "Marshall County",
    "state": "MN",
    "city": "Newfolden"
  },
  {
    "value": "56740",
    "label": "Noyes - MN 56740",
    "county": "Kittson County",
    "state": "MN",
    "city": "Noyes"
  },
  {
    "value": "56742",
    "label": "Oklee - MN 56742",
    "county": "Red Lake County",
    "state": "MN",
    "city": "Oklee"
  },
  {
    "value": "56744",
    "label": "Oslo - MN 56744",
    "county": "Marshall County",
    "state": "MN",
    "city": "Oslo"
  },
  {
    "value": "56748",
    "label": "Plummer - MN 56748",
    "county": "Red Lake County",
    "state": "MN",
    "city": "Plummer"
  },
  {
    "value": "56750",
    "label": "Red Lake Falls - MN 56750",
    "county": "Red Lake County",
    "state": "MN",
    "city": "Red Lake Falls"
  },
  {
    "value": "56751",
    "label": "Roseau - MN 56751",
    "county": "Roseau County",
    "state": "MN",
    "city": "Roseau"
  },
  {
    "value": "56754",
    "label": "Saint Hilaire - MN 56754",
    "county": "Pennington County",
    "state": "MN",
    "city": "Saint Hilaire"
  },
  {
    "value": "56755",
    "label": "Saint Vincent - MN 56755",
    "county": "Kittson County",
    "state": "MN",
    "city": "Saint Vincent"
  },
  {
    "value": "56756",
    "label": "Salol - MN 56756",
    "county": "Roseau County",
    "state": "MN",
    "city": "Salol"
  },
  {
    "value": "56757",
    "label": "Stephen - MN 56757",
    "county": "Marshall County",
    "state": "MN",
    "city": "Stephen"
  },
  {
    "value": "56758",
    "label": "Strandquist - MN 56758",
    "county": "Marshall County",
    "state": "MN",
    "city": "Strandquist"
  },
  {
    "value": "56759",
    "label": "Strathcona - MN 56759",
    "county": "Roseau County",
    "state": "MN",
    "city": "Strathcona"
  },
  {
    "value": "56760",
    "label": "Viking - MN 56760",
    "county": "Marshall County",
    "state": "MN",
    "city": "Viking"
  },
  {
    "value": "56761",
    "label": "Wannaska - MN 56761",
    "county": "Roseau County",
    "state": "MN",
    "city": "Wannaska"
  },
  {
    "value": "56762",
    "label": "Warren - MN 56762",
    "county": "Marshall County",
    "state": "MN",
    "city": "Warren"
  },
  {
    "value": "56763",
    "label": "Warroad - MN 56763",
    "county": "Roseau County",
    "state": "MN",
    "city": "Warroad"
  },
  {
    "value": "38601",
    "label": "Abbeville - MS 38601",
    "county": "Lafayette County",
    "state": "MS",
    "city": "Abbeville"
  },
  {
    "value": "38603",
    "label": "Ashland - MS 38603",
    "county": "Benton County",
    "state": "MS",
    "city": "Ashland"
  },
  {
    "value": "38606",
    "label": "Batesville - MS 38606",
    "county": "Panola County",
    "state": "MS",
    "city": "Batesville"
  },
  {
    "value": "38609",
    "label": "Marks - MS 38609",
    "county": "Quitman County",
    "state": "MS",
    "city": "Marks"
  },
  {
    "value": "38610",
    "label": "Blue Mountain - MS 38610",
    "county": "Tippah County",
    "state": "MS",
    "city": "Blue Mountain"
  },
  {
    "value": "38611",
    "label": "Byhalia - MS 38611",
    "county": "Marshall County",
    "state": "MS",
    "city": "Byhalia"
  },
  {
    "value": "38614",
    "label": "Clarksdale - MS 38614",
    "county": "Coahoma County",
    "state": "MS",
    "city": "Clarksdale"
  },
  {
    "value": "38617",
    "label": "Coahoma - MS 38617",
    "county": "Coahoma County",
    "state": "MS",
    "city": "Coahoma"
  },
  {
    "value": "38618",
    "label": "Coldwater - MS 38618",
    "county": "Tate County",
    "state": "MS",
    "city": "Coldwater"
  },
  {
    "value": "38619",
    "label": "Como - MS 38619",
    "county": "Panola County",
    "state": "MS",
    "city": "Como"
  },
  {
    "value": "38620",
    "label": "Courtland - MS 38620",
    "county": "Panola County",
    "state": "MS",
    "city": "Courtland"
  },
  {
    "value": "38621",
    "label": "Crenshaw - MS 38621",
    "county": "Panola County",
    "state": "MS",
    "city": "Crenshaw"
  },
  {
    "value": "38625",
    "label": "Dumas - MS 38625",
    "county": "Tippah County",
    "state": "MS",
    "city": "Dumas"
  },
  {
    "value": "38626",
    "label": "Dundee - MS 38626",
    "county": "Tunica County",
    "state": "MS",
    "city": "Dundee"
  },
  {
    "value": "38627",
    "label": "Etta - MS 38627",
    "county": "Union County",
    "state": "MS",
    "city": "Etta"
  },
  {
    "value": "38628",
    "label": "Sledge - MS 38628",
    "county": "Quitman County",
    "state": "MS",
    "city": "Sledge"
  },
  {
    "value": "38629",
    "label": "Falkner - MS 38629",
    "county": "Tippah County",
    "state": "MS",
    "city": "Falkner"
  },
  {
    "value": "38632",
    "label": "Hernando - MS 38632",
    "county": "DeSoto County",
    "state": "MS",
    "city": "Hernando"
  },
  {
    "value": "38633",
    "label": "Hickory Flat - MS 38633",
    "county": "Benton County",
    "state": "MS",
    "city": "Hickory Flat"
  },
  {
    "value": "38635",
    "label": "Holly Springs - MS 38635",
    "county": "Marshall County",
    "state": "MS",
    "city": "Holly Springs"
  },
  {
    "value": "38637",
    "label": "Horn Lake - MS 38637",
    "county": "DeSoto County",
    "state": "MS",
    "city": "Horn Lake"
  },
  {
    "value": "38641",
    "label": "Lake Cormorant - MS 38641",
    "county": "DeSoto County",
    "state": "MS",
    "city": "Lake Cormorant"
  },
  {
    "value": "38642",
    "label": "Lamar - MS 38642",
    "county": "Benton County",
    "state": "MS",
    "city": "Lamar"
  },
  {
    "value": "38643",
    "label": "Lambert - MS 38643",
    "county": "Quitman County",
    "state": "MS",
    "city": "Lambert"
  },
  {
    "value": "38645",
    "label": "Lyon - MS 38645",
    "county": "Coahoma County",
    "state": "MS",
    "city": "Lyon"
  },
  {
    "value": "38646",
    "label": "Marks - MS 38646",
    "county": "Quitman County",
    "state": "MS",
    "city": "Marks"
  },
  {
    "value": "38647",
    "label": "Michigan City - MS 38647",
    "county": "Benton County",
    "state": "MS",
    "city": "Michigan City"
  },
  {
    "value": "38650",
    "label": "Myrtle - MS 38650",
    "county": "Union County",
    "state": "MS",
    "city": "Myrtle"
  },
  {
    "value": "38651",
    "label": "Nesbit - MS 38651",
    "county": "DeSoto County",
    "state": "MS",
    "city": "Nesbit"
  },
  {
    "value": "38652",
    "label": "New Albany - MS 38652",
    "county": "Union County",
    "state": "MS",
    "city": "New Albany"
  },
  {
    "value": "38654",
    "label": "Olive Branch - MS 38654",
    "county": "DeSoto County",
    "state": "MS",
    "city": "Olive Branch"
  },
  {
    "value": "38655",
    "label": "Oxford - MS 38655",
    "county": "Lafayette County",
    "state": "MS",
    "city": "Oxford"
  },
  {
    "value": "38658",
    "label": "Pope - MS 38658",
    "county": "Panola County",
    "state": "MS",
    "city": "Pope"
  },
  {
    "value": "38659",
    "label": "Potts Camp - MS 38659",
    "county": "Marshall County",
    "state": "MS",
    "city": "Potts Camp"
  },
  {
    "value": "38661",
    "label": "Red Banks - MS 38661",
    "county": "Marshall County",
    "state": "MS",
    "city": "Red Banks"
  },
  {
    "value": "38663",
    "label": "Ripley - MS 38663",
    "county": "Tippah County",
    "state": "MS",
    "city": "Ripley"
  },
  {
    "value": "38664",
    "label": "Robinsonville - MS 38664",
    "county": "Tunica County",
    "state": "MS",
    "city": "Robinsonville"
  },
  {
    "value": "38665",
    "label": "Sarah - MS 38665",
    "county": "Tate County",
    "state": "MS",
    "city": "Sarah"
  },
  {
    "value": "38666",
    "label": "Sardis - MS 38666",
    "county": "Panola County",
    "state": "MS",
    "city": "Sardis"
  },
  {
    "value": "38668",
    "label": "Senatobia - MS 38668",
    "county": "Tate County",
    "state": "MS",
    "city": "Senatobia"
  },
  {
    "value": "38670",
    "label": "Sledge - MS 38670",
    "county": "Quitman County",
    "state": "MS",
    "city": "Sledge"
  },
  {
    "value": "38671",
    "label": "Southaven - MS 38671",
    "county": "DeSoto County",
    "state": "MS",
    "city": "Southaven"
  },
  {
    "value": "38672",
    "label": "Southaven - MS 38672",
    "county": "DeSoto County",
    "state": "MS",
    "city": "Southaven"
  },
  {
    "value": "38673",
    "label": "Taylor - MS 38673",
    "county": "Lafayette County",
    "state": "MS",
    "city": "Taylor"
  },
  {
    "value": "38674",
    "label": "Tiplersville - MS 38674",
    "county": "Tippah County",
    "state": "MS",
    "city": "Tiplersville"
  },
  {
    "value": "38675",
    "label": "Memphis Stc - MS 38675",
    "county": "Lafayette County",
    "state": "MS",
    "city": "Memphis Stc"
  },
  {
    "value": "38676",
    "label": "Tunica - MS 38676",
    "county": "Tunica County",
    "state": "MS",
    "city": "Tunica"
  },
  {
    "value": "38680",
    "label": "Walls - MS 38680",
    "county": "DeSoto County",
    "state": "MS",
    "city": "Walls"
  },
  {
    "value": "38683",
    "label": "Walnut - MS 38683",
    "county": "Tippah County",
    "state": "MS",
    "city": "Walnut"
  },
  {
    "value": "38685",
    "label": "Waterford - MS 38685",
    "county": "Marshall County",
    "state": "MS",
    "city": "Waterford"
  },
  {
    "value": "38701",
    "label": "Greenville - MS 38701",
    "county": "Washington County",
    "state": "MS",
    "city": "Greenville"
  },
  {
    "value": "38703",
    "label": "Greenville - MS 38703",
    "county": "Washington County",
    "state": "MS",
    "city": "Greenville"
  },
  {
    "value": "38720",
    "label": "Alligator - MS 38720",
    "county": "Coahoma County",
    "state": "MS",
    "city": "Alligator"
  },
  {
    "value": "38721",
    "label": "Anguilla - MS 38721",
    "county": "Sharkey County",
    "state": "MS",
    "city": "Anguilla"
  },
  {
    "value": "38725",
    "label": "Benoit - MS 38725",
    "county": "Bolivar County",
    "state": "MS",
    "city": "Benoit"
  },
  {
    "value": "38726",
    "label": "Beulah - MS 38726",
    "county": "Bolivar County",
    "state": "MS",
    "city": "Beulah"
  },
  {
    "value": "38730",
    "label": "Boyle - MS 38730",
    "county": "Bolivar County",
    "state": "MS",
    "city": "Boyle"
  },
  {
    "value": "38731",
    "label": "Chatham - MS 38731",
    "county": "Washington County",
    "state": "MS",
    "city": "Chatham"
  },
  {
    "value": "38732",
    "label": "Cleveland - MS 38732",
    "county": "Bolivar County",
    "state": "MS",
    "city": "Cleveland"
  },
  {
    "value": "38733",
    "label": "Cleveland - MS 38733",
    "county": "Bolivar County",
    "state": "MS",
    "city": "Cleveland"
  },
  {
    "value": "38736",
    "label": "Doddsville - MS 38736",
    "county": "Sunflower County",
    "state": "MS",
    "city": "Doddsville"
  },
  {
    "value": "38737",
    "label": "Drew - MS 38737",
    "county": "Sunflower County",
    "state": "MS",
    "city": "Drew"
  },
  {
    "value": "38740",
    "label": "Duncan - MS 38740",
    "county": "Bolivar County",
    "state": "MS",
    "city": "Duncan"
  },
  {
    "value": "38744",
    "label": "Glen Allan - MS 38744",
    "county": "Washington County",
    "state": "MS",
    "city": "Glen Allan"
  },
  {
    "value": "38746",
    "label": "Gunnison - MS 38746",
    "county": "Bolivar County",
    "state": "MS",
    "city": "Gunnison"
  },
  {
    "value": "38748",
    "label": "Hollandale - MS 38748",
    "county": "Washington County",
    "state": "MS",
    "city": "Hollandale"
  },
  {
    "value": "38751",
    "label": "Indianola - MS 38751",
    "county": "Sunflower County",
    "state": "MS",
    "city": "Indianola"
  },
  {
    "value": "38753",
    "label": "Inverness - MS 38753",
    "county": "Sunflower County",
    "state": "MS",
    "city": "Inverness"
  },
  {
    "value": "38754",
    "label": "Isola - MS 38754",
    "county": "Humphreys County",
    "state": "MS",
    "city": "Isola"
  },
  {
    "value": "38756",
    "label": "Leland - MS 38756",
    "county": "Washington County",
    "state": "MS",
    "city": "Leland"
  },
  {
    "value": "38759",
    "label": "Merigold - MS 38759",
    "county": "Bolivar County",
    "state": "MS",
    "city": "Merigold"
  },
  {
    "value": "38761",
    "label": "Moorhead - MS 38761",
    "county": "Sunflower County",
    "state": "MS",
    "city": "Moorhead"
  },
  {
    "value": "38762",
    "label": "Mound Bayou - MS 38762",
    "county": "Bolivar County",
    "state": "MS",
    "city": "Mound Bayou"
  },
  {
    "value": "38769",
    "label": "Rosedale - MS 38769",
    "county": "Bolivar County",
    "state": "MS",
    "city": "Rosedale"
  },
  {
    "value": "38771",
    "label": "Ruleville - MS 38771",
    "county": "Sunflower County",
    "state": "MS",
    "city": "Ruleville"
  },
  {
    "value": "38773",
    "label": "Shaw - MS 38773",
    "county": "Bolivar County",
    "state": "MS",
    "city": "Shaw"
  },
  {
    "value": "38774",
    "label": "Shelby - MS 38774",
    "county": "Bolivar County",
    "state": "MS",
    "city": "Shelby"
  },
  {
    "value": "38778",
    "label": "Sunflower - MS 38778",
    "county": "Sunflower County",
    "state": "MS",
    "city": "Sunflower"
  },
  {
    "value": "38801",
    "label": "Tupelo - MS 38801",
    "county": "Lee County",
    "state": "MS",
    "city": "Tupelo"
  },
  {
    "value": "38804",
    "label": "Tupelo - MS 38804",
    "county": "Lee County",
    "state": "MS",
    "city": "Tupelo"
  },
  {
    "value": "38821",
    "label": "Amory - MS 38821",
    "county": "Monroe County",
    "state": "MS",
    "city": "Amory"
  },
  {
    "value": "38824",
    "label": "Baldwyn - MS 38824",
    "county": "Prentiss County",
    "state": "MS",
    "city": "Baldwyn"
  },
  {
    "value": "38826",
    "label": "Belden - MS 38826",
    "county": "Lee County",
    "state": "MS",
    "city": "Belden"
  },
  {
    "value": "38827",
    "label": "Belmont - MS 38827",
    "county": "Tishomingo County",
    "state": "MS",
    "city": "Belmont"
  },
  {
    "value": "38828",
    "label": "Blue Springs - MS 38828",
    "county": "Union County",
    "state": "MS",
    "city": "Blue Springs"
  },
  {
    "value": "38829",
    "label": "Booneville - MS 38829",
    "county": "Prentiss County",
    "state": "MS",
    "city": "Booneville"
  },
  {
    "value": "38833",
    "label": "Burnsville - MS 38833",
    "county": "Tishomingo County",
    "state": "MS",
    "city": "Burnsville"
  },
  {
    "value": "38834",
    "label": "Corinth - MS 38834",
    "county": "Alcorn County",
    "state": "MS",
    "city": "Corinth"
  },
  {
    "value": "38838",
    "label": "Dennis - MS 38838",
    "county": "Tishomingo County",
    "state": "MS",
    "city": "Dennis"
  },
  {
    "value": "38841",
    "label": "Ecru - MS 38841",
    "county": "Pontotoc County",
    "state": "MS",
    "city": "Ecru"
  },
  {
    "value": "38843",
    "label": "Fulton - MS 38843",
    "county": "Itawamba County",
    "state": "MS",
    "city": "Fulton"
  },
  {
    "value": "38844",
    "label": "Gattman - MS 38844",
    "county": "Monroe County",
    "state": "MS",
    "city": "Gattman"
  },
  {
    "value": "38846",
    "label": "Glen - MS 38846",
    "county": "Alcorn County",
    "state": "MS",
    "city": "Glen"
  },
  {
    "value": "38847",
    "label": "Golden - MS 38847",
    "county": "Itawamba County",
    "state": "MS",
    "city": "Golden"
  },
  {
    "value": "38848",
    "label": "Greenwood Springs - MS 38848",
    "county": "Monroe County",
    "state": "MS",
    "city": "Greenwood Springs"
  },
  {
    "value": "38849",
    "label": "Guntown - MS 38849",
    "county": "Lee County",
    "state": "MS",
    "city": "Guntown"
  },
  {
    "value": "38850",
    "label": "Houlka - MS 38850",
    "county": "Chickasaw County",
    "state": "MS",
    "city": "Houlka"
  },
  {
    "value": "38851",
    "label": "Houston - MS 38851",
    "county": "Chickasaw County",
    "state": "MS",
    "city": "Houston"
  },
  {
    "value": "38852",
    "label": "Iuka - MS 38852",
    "county": "Tishomingo County",
    "state": "MS",
    "city": "Iuka"
  },
  {
    "value": "38855",
    "label": "Mantachie - MS 38855",
    "county": "Itawamba County",
    "state": "MS",
    "city": "Mantachie"
  },
  {
    "value": "38856",
    "label": "Marietta - MS 38856",
    "county": "Itawamba County",
    "state": "MS",
    "city": "Marietta"
  },
  {
    "value": "38857",
    "label": "Mooreville - MS 38857",
    "county": "Lee County",
    "state": "MS",
    "city": "Mooreville"
  },
  {
    "value": "38858",
    "label": "Nettleton - MS 38858",
    "county": "Monroe County",
    "state": "MS",
    "city": "Nettleton"
  },
  {
    "value": "38859",
    "label": "New Site - MS 38859",
    "county": "Prentiss County",
    "state": "MS",
    "city": "New Site"
  },
  {
    "value": "38860",
    "label": "Okolona - MS 38860",
    "county": "Chickasaw County",
    "state": "MS",
    "city": "Okolona"
  },
  {
    "value": "38862",
    "label": "Plantersville - MS 38862",
    "county": "Lee County",
    "state": "MS",
    "city": "Plantersville"
  },
  {
    "value": "38863",
    "label": "Pontotoc - MS 38863",
    "county": "Pontotoc County",
    "state": "MS",
    "city": "Pontotoc"
  },
  {
    "value": "38864",
    "label": "Randolph - MS 38864",
    "county": "Pontotoc County",
    "state": "MS",
    "city": "Randolph"
  },
  {
    "value": "38865",
    "label": "Rienzi - MS 38865",
    "county": "Alcorn County",
    "state": "MS",
    "city": "Rienzi"
  },
  {
    "value": "38866",
    "label": "Saltillo - MS 38866",
    "county": "Lee County",
    "state": "MS",
    "city": "Saltillo"
  },
  {
    "value": "38868",
    "label": "Shannon - MS 38868",
    "county": "Lee County",
    "state": "MS",
    "city": "Shannon"
  },
  {
    "value": "38870",
    "label": "Smithville - MS 38870",
    "county": "Monroe County",
    "state": "MS",
    "city": "Smithville"
  },
  {
    "value": "38871",
    "label": "Thaxton - MS 38871",
    "county": "Pontotoc County",
    "state": "MS",
    "city": "Thaxton"
  },
  {
    "value": "38873",
    "label": "Tishomingo - MS 38873",
    "county": "Tishomingo County",
    "state": "MS",
    "city": "Tishomingo"
  },
  {
    "value": "38876",
    "label": "Tremont - MS 38876",
    "county": "Itawamba County",
    "state": "MS",
    "city": "Tremont"
  },
  {
    "value": "38878",
    "label": "Vardaman - MS 38878",
    "county": "Calhoun County",
    "state": "MS",
    "city": "Vardaman"
  },
  {
    "value": "38901",
    "label": "Grenada - MS 38901",
    "county": "Grenada County",
    "state": "MS",
    "city": "Grenada"
  },
  {
    "value": "38913",
    "label": "Banner - MS 38913",
    "county": "Calhoun County",
    "state": "MS",
    "city": "Banner"
  },
  {
    "value": "38914",
    "label": "Big Creek - MS 38914",
    "county": "Calhoun County",
    "state": "MS",
    "city": "Big Creek"
  },
  {
    "value": "38915",
    "label": "Bruce - MS 38915",
    "county": "Calhoun County",
    "state": "MS",
    "city": "Bruce"
  },
  {
    "value": "38916",
    "label": "Calhoun City - MS 38916",
    "county": "Calhoun County",
    "state": "MS",
    "city": "Calhoun City"
  },
  {
    "value": "38917",
    "label": "Carrollton - MS 38917",
    "county": "Carroll County",
    "state": "MS",
    "city": "Carrollton"
  },
  {
    "value": "38920",
    "label": "Cascilla - MS 38920",
    "county": "Tallahatchie County",
    "state": "MS",
    "city": "Cascilla"
  },
  {
    "value": "38921",
    "label": "Charleston - MS 38921",
    "county": "Tallahatchie County",
    "state": "MS",
    "city": "Charleston"
  },
  {
    "value": "38922",
    "label": "Coffeeville - MS 38922",
    "county": "Yalobusha County",
    "state": "MS",
    "city": "Coffeeville"
  },
  {
    "value": "38923",
    "label": "Coila - MS 38923",
    "county": "Carroll County",
    "state": "MS",
    "city": "Coila"
  },
  {
    "value": "38924",
    "label": "Cruger - MS 38924",
    "county": "Holmes County",
    "state": "MS",
    "city": "Cruger"
  },
  {
    "value": "38925",
    "label": "Duck Hill - MS 38925",
    "county": "Montgomery County",
    "state": "MS",
    "city": "Duck Hill"
  },
  {
    "value": "38927",
    "label": "Enid - MS 38927",
    "county": "Tallahatchie County",
    "state": "MS",
    "city": "Enid"
  },
  {
    "value": "38928",
    "label": "Glendora - MS 38928",
    "county": "Tallahatchie County",
    "state": "MS",
    "city": "Glendora"
  },
  {
    "value": "38929",
    "label": "Gore Springs - MS 38929",
    "county": "Grenada County",
    "state": "MS",
    "city": "Gore Springs"
  },
  {
    "value": "38930",
    "label": "Greenwood - MS 38930",
    "county": "Leflore County",
    "state": "MS",
    "city": "Greenwood"
  },
  {
    "value": "38940",
    "label": "Holcomb - MS 38940",
    "county": "Grenada County",
    "state": "MS",
    "city": "Holcomb"
  },
  {
    "value": "38941",
    "label": "Itta Bena - MS 38941",
    "county": "Leflore County",
    "state": "MS",
    "city": "Itta Bena"
  },
  {
    "value": "38943",
    "label": "Mc Carley - MS 38943",
    "county": "Carroll County",
    "state": "MS",
    "city": "Mc Carley"
  },
  {
    "value": "38944",
    "label": "Minter City - MS 38944",
    "county": "Leflore County",
    "state": "MS",
    "city": "Minter City"
  },
  {
    "value": "38948",
    "label": "Oakland - MS 38948",
    "county": "Yalobusha County",
    "state": "MS",
    "city": "Oakland"
  },
  {
    "value": "38949",
    "label": "Paris - MS 38949",
    "county": "Lafayette County",
    "state": "MS",
    "city": "Paris"
  },
  {
    "value": "38950",
    "label": "Philipp - MS 38950",
    "county": "Tallahatchie County",
    "state": "MS",
    "city": "Philipp"
  },
  {
    "value": "38951",
    "label": "Pittsboro - MS 38951",
    "county": "Calhoun County",
    "state": "MS",
    "city": "Pittsboro"
  },
  {
    "value": "38952",
    "label": "Schlater - MS 38952",
    "county": "Leflore County",
    "state": "MS",
    "city": "Schlater"
  },
  {
    "value": "38953",
    "label": "Scobey - MS 38953",
    "county": "Tallahatchie County",
    "state": "MS",
    "city": "Scobey"
  },
  {
    "value": "38954",
    "label": "Sidon - MS 38954",
    "county": "Leflore County",
    "state": "MS",
    "city": "Sidon"
  },
  {
    "value": "38961",
    "label": "Tillatoba - MS 38961",
    "county": "Yalobusha County",
    "state": "MS",
    "city": "Tillatoba"
  },
  {
    "value": "38963",
    "label": "Tutwiler - MS 38963",
    "county": "Tallahatchie County",
    "state": "MS",
    "city": "Tutwiler"
  },
  {
    "value": "38964",
    "label": "Vance - MS 38964",
    "county": "Quitman County",
    "state": "MS",
    "city": "Vance"
  },
  {
    "value": "38965",
    "label": "Water Valley - MS 38965",
    "county": "Yalobusha County",
    "state": "MS",
    "city": "Water Valley"
  },
  {
    "value": "38967",
    "label": "Winona - MS 38967",
    "county": "Montgomery County",
    "state": "MS",
    "city": "Winona"
  },
  {
    "value": "39038",
    "label": "Belzoni - MS 39038",
    "county": "Humphreys County",
    "state": "MS",
    "city": "Belzoni"
  },
  {
    "value": "39039",
    "label": "Benton - MS 39039",
    "county": "Yazoo County",
    "state": "MS",
    "city": "Benton"
  },
  {
    "value": "39040",
    "label": "Bentonia - MS 39040",
    "county": "Yazoo County",
    "state": "MS",
    "city": "Bentonia"
  },
  {
    "value": "39041",
    "label": "Bolton - MS 39041",
    "county": "Hinds County",
    "state": "MS",
    "city": "Bolton"
  },
  {
    "value": "39042",
    "label": "Brandon - MS 39042",
    "county": "Rankin County",
    "state": "MS",
    "city": "Brandon"
  },
  {
    "value": "39044",
    "label": "Braxton - MS 39044",
    "county": "Simpson County",
    "state": "MS",
    "city": "Braxton"
  },
  {
    "value": "39045",
    "label": "Camden - MS 39045",
    "county": "Madison County",
    "state": "MS",
    "city": "Camden"
  },
  {
    "value": "39046",
    "label": "Canton - MS 39046",
    "county": "Madison County",
    "state": "MS",
    "city": "Canton"
  },
  {
    "value": "39047",
    "label": "Brandon - MS 39047",
    "county": "Rankin County",
    "state": "MS",
    "city": "Brandon"
  },
  {
    "value": "39051",
    "label": "Carthage - MS 39051",
    "county": "Leake County",
    "state": "MS",
    "city": "Carthage"
  },
  {
    "value": "39054",
    "label": "Cary - MS 39054",
    "county": "Sharkey County",
    "state": "MS",
    "city": "Cary"
  },
  {
    "value": "39056",
    "label": "Clinton - MS 39056",
    "county": "Hinds County",
    "state": "MS",
    "city": "Clinton"
  },
  {
    "value": "39057",
    "label": "Conehatta - MS 39057",
    "county": "Newton County",
    "state": "MS",
    "city": "Conehatta"
  },
  {
    "value": "39059",
    "label": "Crystal Springs - MS 39059",
    "county": "Copiah County",
    "state": "MS",
    "city": "Crystal Springs"
  },
  {
    "value": "39063",
    "label": "Durant - MS 39063",
    "county": "Holmes County",
    "state": "MS",
    "city": "Durant"
  },
  {
    "value": "39066",
    "label": "Edwards - MS 39066",
    "county": "Hinds County",
    "state": "MS",
    "city": "Edwards"
  },
  {
    "value": "39067",
    "label": "Ethel - MS 39067",
    "county": "Attala County",
    "state": "MS",
    "city": "Ethel"
  },
  {
    "value": "39069",
    "label": "Fayette - MS 39069",
    "county": "Jefferson County",
    "state": "MS",
    "city": "Fayette"
  },
  {
    "value": "39071",
    "label": "Flora - MS 39071",
    "county": "Madison County",
    "state": "MS",
    "city": "Flora"
  },
  {
    "value": "39073",
    "label": "Florence - MS 39073",
    "county": "Rankin County",
    "state": "MS",
    "city": "Florence"
  },
  {
    "value": "39074",
    "label": "Forest - MS 39074",
    "county": "Scott County",
    "state": "MS",
    "city": "Forest"
  },
  {
    "value": "39078",
    "label": "Georgetown - MS 39078",
    "county": "Copiah County",
    "state": "MS",
    "city": "Georgetown"
  },
  {
    "value": "39079",
    "label": "Goodman - MS 39079",
    "county": "Holmes County",
    "state": "MS",
    "city": "Goodman"
  },
  {
    "value": "39082",
    "label": "Harrisville - MS 39082",
    "county": "Simpson County",
    "state": "MS",
    "city": "Harrisville"
  },
  {
    "value": "39083",
    "label": "Hazlehurst - MS 39083",
    "county": "Copiah County",
    "state": "MS",
    "city": "Hazlehurst"
  },
  {
    "value": "39086",
    "label": "Hermanville - MS 39086",
    "county": "Claiborne County",
    "state": "MS",
    "city": "Hermanville"
  },
  {
    "value": "39088",
    "label": "Holly Bluff - MS 39088",
    "county": "Yazoo County",
    "state": "MS",
    "city": "Holly Bluff"
  },
  {
    "value": "39090",
    "label": "Kosciusko - MS 39090",
    "county": "Attala County",
    "state": "MS",
    "city": "Kosciusko"
  },
  {
    "value": "39092",
    "label": "Lake - MS 39092",
    "county": "Scott County",
    "state": "MS",
    "city": "Lake"
  },
  {
    "value": "39094",
    "label": "Lena - MS 39094",
    "county": "Leake County",
    "state": "MS",
    "city": "Lena"
  },
  {
    "value": "39095",
    "label": "Lexington - MS 39095",
    "county": "Holmes County",
    "state": "MS",
    "city": "Lexington"
  },
  {
    "value": "39096",
    "label": "Lorman - MS 39096",
    "county": "Claiborne County",
    "state": "MS",
    "city": "Lorman"
  },
  {
    "value": "39097",
    "label": "Louise - MS 39097",
    "county": "Humphreys County",
    "state": "MS",
    "city": "Louise"
  },
  {
    "value": "39108",
    "label": "Mc Cool - MS 39108",
    "county": "Attala County",
    "state": "MS",
    "city": "Mc Cool"
  },
  {
    "value": "39110",
    "label": "Madison - MS 39110",
    "county": "Madison County",
    "state": "MS",
    "city": "Madison"
  },
  {
    "value": "39111",
    "label": "Magee - MS 39111",
    "county": "Simpson County",
    "state": "MS",
    "city": "Magee"
  },
  {
    "value": "39114",
    "label": "Mendenhall - MS 39114",
    "county": "Simpson County",
    "state": "MS",
    "city": "Mendenhall"
  },
  {
    "value": "39116",
    "label": "Mize - MS 39116",
    "county": "Smith County",
    "state": "MS",
    "city": "Mize"
  },
  {
    "value": "39117",
    "label": "Morton - MS 39117",
    "county": "Scott County",
    "state": "MS",
    "city": "Morton"
  },
  {
    "value": "39119",
    "label": "Mount Olive - MS 39119",
    "county": "Covington County",
    "state": "MS",
    "city": "Mount Olive"
  },
  {
    "value": "39120",
    "label": "Natchez - MS 39120",
    "county": "Adams County",
    "state": "MS",
    "city": "Natchez"
  },
  {
    "value": "39140",
    "label": "Newhebron - MS 39140",
    "county": "Lawrence County",
    "state": "MS",
    "city": "Newhebron"
  },
  {
    "value": "39144",
    "label": "Pattison - MS 39144",
    "county": "Claiborne County",
    "state": "MS",
    "city": "Pattison"
  },
  {
    "value": "39145",
    "label": "Pelahatchie - MS 39145",
    "county": "Rankin County",
    "state": "MS",
    "city": "Pelahatchie"
  },
  {
    "value": "39146",
    "label": "Pickens - MS 39146",
    "county": "Holmes County",
    "state": "MS",
    "city": "Pickens"
  },
  {
    "value": "39149",
    "label": "Pinola - MS 39149",
    "county": "Simpson County",
    "state": "MS",
    "city": "Pinola"
  },
  {
    "value": "39150",
    "label": "Port Gibson - MS 39150",
    "county": "Claiborne County",
    "state": "MS",
    "city": "Port Gibson"
  },
  {
    "value": "39152",
    "label": "Pulaski - MS 39152",
    "county": "Smith County",
    "state": "MS",
    "city": "Pulaski"
  },
  {
    "value": "39153",
    "label": "Raleigh - MS 39153",
    "county": "Smith County",
    "state": "MS",
    "city": "Raleigh"
  },
  {
    "value": "39154",
    "label": "Raymond - MS 39154",
    "county": "Hinds County",
    "state": "MS",
    "city": "Raymond"
  },
  {
    "value": "39156",
    "label": "Redwood - MS 39156",
    "county": "Warren County",
    "state": "MS",
    "city": "Redwood"
  },
  {
    "value": "39157",
    "label": "Ridgeland - MS 39157",
    "county": "Madison County",
    "state": "MS",
    "city": "Ridgeland"
  },
  {
    "value": "39159",
    "label": "Rolling Fork - MS 39159",
    "county": "Sharkey County",
    "state": "MS",
    "city": "Rolling Fork"
  },
  {
    "value": "39160",
    "label": "Sallis - MS 39160",
    "county": "Attala County",
    "state": "MS",
    "city": "Sallis"
  },
  {
    "value": "39162",
    "label": "Satartia - MS 39162",
    "county": "Yazoo County",
    "state": "MS",
    "city": "Satartia"
  },
  {
    "value": "39166",
    "label": "Silver City - MS 39166",
    "county": "Humphreys County",
    "state": "MS",
    "city": "Silver City"
  },
  {
    "value": "39168",
    "label": "Taylorsville - MS 39168",
    "county": "Smith County",
    "state": "MS",
    "city": "Taylorsville"
  },
  {
    "value": "39169",
    "label": "Tchula - MS 39169",
    "county": "Holmes County",
    "state": "MS",
    "city": "Tchula"
  },
  {
    "value": "39170",
    "label": "Terry - MS 39170",
    "county": "Hinds County",
    "state": "MS",
    "city": "Terry"
  },
  {
    "value": "39173",
    "label": "Tinsley - MS 39173",
    "county": "Yazoo County",
    "state": "MS",
    "city": "Tinsley"
  },
  {
    "value": "39175",
    "label": "Utica - MS 39175",
    "county": "Hinds County",
    "state": "MS",
    "city": "Utica"
  },
  {
    "value": "39176",
    "label": "Vaiden - MS 39176",
    "county": "Carroll County",
    "state": "MS",
    "city": "Vaiden"
  },
  {
    "value": "39179",
    "label": "Vaughan - MS 39179",
    "county": "Yazoo County",
    "state": "MS",
    "city": "Vaughan"
  },
  {
    "value": "39180",
    "label": "Vicksburg - MS 39180",
    "county": "Warren County",
    "state": "MS",
    "city": "Vicksburg"
  },
  {
    "value": "39183",
    "label": "Vicksburg - MS 39183",
    "county": "Warren County",
    "state": "MS",
    "city": "Vicksburg"
  },
  {
    "value": "39189",
    "label": "Walnut Grove - MS 39189",
    "county": "Leake County",
    "state": "MS",
    "city": "Walnut Grove"
  },
  {
    "value": "39191",
    "label": "Wesson - MS 39191",
    "county": "Copiah County",
    "state": "MS",
    "city": "Wesson"
  },
  {
    "value": "39192",
    "label": "West - MS 39192",
    "county": "Attala County",
    "state": "MS",
    "city": "West"
  },
  {
    "value": "39194",
    "label": "Yazoo City - MS 39194",
    "county": "Yazoo County",
    "state": "MS",
    "city": "Yazoo City"
  },
  {
    "value": "39201",
    "label": "Jackson - MS 39201",
    "county": "Hinds County",
    "state": "MS",
    "city": "Jackson"
  },
  {
    "value": "39202",
    "label": "Jackson - MS 39202",
    "county": "Hinds County",
    "state": "MS",
    "city": "Jackson"
  },
  {
    "value": "39203",
    "label": "Jackson - MS 39203",
    "county": "Hinds County",
    "state": "MS",
    "city": "Jackson"
  },
  {
    "value": "39204",
    "label": "Jackson - MS 39204",
    "county": "Hinds County",
    "state": "MS",
    "city": "Jackson"
  },
  {
    "value": "39206",
    "label": "Jackson - MS 39206",
    "county": "Hinds County",
    "state": "MS",
    "city": "Jackson"
  },
  {
    "value": "39208",
    "label": "Pearl - MS 39208",
    "county": "Rankin County",
    "state": "MS",
    "city": "Pearl"
  },
  {
    "value": "39209",
    "label": "Jackson - MS 39209",
    "county": "Hinds County",
    "state": "MS",
    "city": "Jackson"
  },
  {
    "value": "39211",
    "label": "Jackson - MS 39211",
    "county": "Hinds County",
    "state": "MS",
    "city": "Jackson"
  },
  {
    "value": "39212",
    "label": "Jackson - MS 39212",
    "county": "Hinds County",
    "state": "MS",
    "city": "Jackson"
  },
  {
    "value": "39213",
    "label": "Jackson - MS 39213",
    "county": "Hinds County",
    "state": "MS",
    "city": "Jackson"
  },
  {
    "value": "39216",
    "label": "Jackson - MS 39216",
    "county": "Hinds County",
    "state": "MS",
    "city": "Jackson"
  },
  {
    "value": "39218",
    "label": "Richland - MS 39218",
    "county": "Rankin County",
    "state": "MS",
    "city": "Richland"
  },
  {
    "value": "39232",
    "label": "Flowood - MS 39232",
    "county": "Rankin County",
    "state": "MS",
    "city": "Flowood"
  },
  {
    "value": "39269",
    "label": "Jackson - MS 39269",
    "county": "Hinds County",
    "state": "MS",
    "city": "Jackson"
  },
  {
    "value": "39272",
    "label": "Byram - MS 39272",
    "county": "Hinds County",
    "state": "MS",
    "city": "Byram"
  },
  {
    "value": "39301",
    "label": "Meridian - MS 39301",
    "county": "Lauderdale County",
    "state": "MS",
    "city": "Meridian"
  },
  {
    "value": "39305",
    "label": "Meridian - MS 39305",
    "county": "Lauderdale County",
    "state": "MS",
    "city": "Meridian"
  },
  {
    "value": "39307",
    "label": "Meridian - MS 39307",
    "county": "Lauderdale County",
    "state": "MS",
    "city": "Meridian"
  },
  {
    "value": "39320",
    "label": "Bailey - MS 39320",
    "county": "Lauderdale County",
    "state": "MS",
    "city": "Bailey"
  },
  {
    "value": "39322",
    "label": "Buckatunna - MS 39322",
    "county": "Wayne County",
    "state": "MS",
    "city": "Buckatunna"
  },
  {
    "value": "39323",
    "label": "Chunky - MS 39323",
    "county": "Newton County",
    "state": "MS",
    "city": "Chunky"
  },
  {
    "value": "39325",
    "label": "Collinsville - MS 39325",
    "county": "Lauderdale County",
    "state": "MS",
    "city": "Collinsville"
  },
  {
    "value": "39326",
    "label": "Daleville - MS 39326",
    "county": "Lauderdale County",
    "state": "MS",
    "city": "Daleville"
  },
  {
    "value": "39327",
    "label": "Decatur - MS 39327",
    "county": "Newton County",
    "state": "MS",
    "city": "Decatur"
  },
  {
    "value": "39328",
    "label": "De Kalb - MS 39328",
    "county": "Kemper County",
    "state": "MS",
    "city": "De Kalb"
  },
  {
    "value": "39330",
    "label": "Enterprise - MS 39330",
    "county": "Clarke County",
    "state": "MS",
    "city": "Enterprise"
  },
  {
    "value": "39332",
    "label": "Hickory - MS 39332",
    "county": "Newton County",
    "state": "MS",
    "city": "Hickory"
  },
  {
    "value": "39335",
    "label": "Lauderdale - MS 39335",
    "county": "Lauderdale County",
    "state": "MS",
    "city": "Lauderdale"
  },
  {
    "value": "39336",
    "label": "Lawrence - MS 39336",
    "county": "Newton County",
    "state": "MS",
    "city": "Lawrence"
  },
  {
    "value": "39337",
    "label": "Little Rock - MS 39337",
    "county": "Newton County",
    "state": "MS",
    "city": "Little Rock"
  },
  {
    "value": "39338",
    "label": "Louin - MS 39338",
    "county": "Jasper County",
    "state": "MS",
    "city": "Louin"
  },
  {
    "value": "39339",
    "label": "Louisville - MS 39339",
    "county": "Winston County",
    "state": "MS",
    "city": "Louisville"
  },
  {
    "value": "39341",
    "label": "Macon - MS 39341",
    "county": "Noxubee County",
    "state": "MS",
    "city": "Macon"
  },
  {
    "value": "39342",
    "label": "Marion - MS 39342",
    "county": "Lauderdale County",
    "state": "MS",
    "city": "Marion"
  },
  {
    "value": "39345",
    "label": "Newton - MS 39345",
    "county": "Newton County",
    "state": "MS",
    "city": "Newton"
  },
  {
    "value": "39346",
    "label": "Noxapater - MS 39346",
    "county": "Winston County",
    "state": "MS",
    "city": "Noxapater"
  },
  {
    "value": "39347",
    "label": "Pachuta - MS 39347",
    "county": "Jasper County",
    "state": "MS",
    "city": "Pachuta"
  },
  {
    "value": "39348",
    "label": "Paulding - MS 39348",
    "county": "Jasper County",
    "state": "MS",
    "city": "Paulding"
  },
  {
    "value": "39350",
    "label": "Philadelphia - MS 39350",
    "county": "Neshoba County",
    "state": "MS",
    "city": "Philadelphia"
  },
  {
    "value": "39352",
    "label": "Porterville - MS 39352",
    "county": "Kemper County",
    "state": "MS",
    "city": "Porterville"
  },
  {
    "value": "39354",
    "label": "Preston - MS 39354",
    "county": "Kemper County",
    "state": "MS",
    "city": "Preston"
  },
  {
    "value": "39355",
    "label": "Quitman - MS 39355",
    "county": "Clarke County",
    "state": "MS",
    "city": "Quitman"
  },
  {
    "value": "39356",
    "label": "Rose Hill - MS 39356",
    "county": "Jasper County",
    "state": "MS",
    "city": "Rose Hill"
  },
  {
    "value": "39358",
    "label": "Scooba - MS 39358",
    "county": "Kemper County",
    "state": "MS",
    "city": "Scooba"
  },
  {
    "value": "39360",
    "label": "Shubuta - MS 39360",
    "county": "Clarke County",
    "state": "MS",
    "city": "Shubuta"
  },
  {
    "value": "39361",
    "label": "Shuqualak - MS 39361",
    "county": "Noxubee County",
    "state": "MS",
    "city": "Shuqualak"
  },
  {
    "value": "39362",
    "label": "State Line - MS 39362",
    "county": "Wayne County",
    "state": "MS",
    "city": "State Line"
  },
  {
    "value": "39363",
    "label": "Stonewall - MS 39363",
    "county": "Clarke County",
    "state": "MS",
    "city": "Stonewall"
  },
  {
    "value": "39364",
    "label": "Toomsuba - MS 39364",
    "county": "Lauderdale County",
    "state": "MS",
    "city": "Toomsuba"
  },
  {
    "value": "39365",
    "label": "Union - MS 39365",
    "county": "Neshoba County",
    "state": "MS",
    "city": "Union"
  },
  {
    "value": "39366",
    "label": "Vossburg - MS 39366",
    "county": "Jasper County",
    "state": "MS",
    "city": "Vossburg"
  },
  {
    "value": "39367",
    "label": "Waynesboro - MS 39367",
    "county": "Wayne County",
    "state": "MS",
    "city": "Waynesboro"
  },
  {
    "value": "39401",
    "label": "Hattiesburg - MS 39401",
    "county": "Forrest County",
    "state": "MS",
    "city": "Hattiesburg"
  },
  {
    "value": "39402",
    "label": "Hattiesburg - MS 39402",
    "county": "Lamar County",
    "state": "MS",
    "city": "Hattiesburg"
  },
  {
    "value": "39421",
    "label": "Bassfield - MS 39421",
    "county": "Jefferson Davis County",
    "state": "MS",
    "city": "Bassfield"
  },
  {
    "value": "39422",
    "label": "Bay Springs - MS 39422",
    "county": "Jasper County",
    "state": "MS",
    "city": "Bay Springs"
  },
  {
    "value": "39423",
    "label": "Beaumont - MS 39423",
    "county": "Perry County",
    "state": "MS",
    "city": "Beaumont"
  },
  {
    "value": "39425",
    "label": "Brooklyn - MS 39425",
    "county": "Forrest County",
    "state": "MS",
    "city": "Brooklyn"
  },
  {
    "value": "39426",
    "label": "Carriere - MS 39426",
    "county": "Pearl River County",
    "state": "MS",
    "city": "Carriere"
  },
  {
    "value": "39427",
    "label": "Carson - MS 39427",
    "county": "Jefferson Davis County",
    "state": "MS",
    "city": "Carson"
  },
  {
    "value": "39428",
    "label": "Collins - MS 39428",
    "county": "Covington County",
    "state": "MS",
    "city": "Collins"
  },
  {
    "value": "39429",
    "label": "Columbia - MS 39429",
    "county": "Marion County",
    "state": "MS",
    "city": "Columbia"
  },
  {
    "value": "39437",
    "label": "Ellisville - MS 39437",
    "county": "Jones County",
    "state": "MS",
    "city": "Ellisville"
  },
  {
    "value": "39439",
    "label": "Heidelberg - MS 39439",
    "county": "Jasper County",
    "state": "MS",
    "city": "Heidelberg"
  },
  {
    "value": "39440",
    "label": "Laurel - MS 39440",
    "county": "Jones County",
    "state": "MS",
    "city": "Laurel"
  },
  {
    "value": "39443",
    "label": "Laurel - MS 39443",
    "county": "Jones County",
    "state": "MS",
    "city": "Laurel"
  },
  {
    "value": "39451",
    "label": "Leakesville - MS 39451",
    "county": "Greene County",
    "state": "MS",
    "city": "Leakesville"
  },
  {
    "value": "39452",
    "label": "Lucedale - MS 39452",
    "county": "George County",
    "state": "MS",
    "city": "Lucedale"
  },
  {
    "value": "39455",
    "label": "Lumberton - MS 39455",
    "county": "Lamar County",
    "state": "MS",
    "city": "Lumberton"
  },
  {
    "value": "39456",
    "label": "Mc Lain - MS 39456",
    "county": "Greene County",
    "state": "MS",
    "city": "Mc Lain"
  },
  {
    "value": "39459",
    "label": "Moselle - MS 39459",
    "county": "Jones County",
    "state": "MS",
    "city": "Moselle"
  },
  {
    "value": "39461",
    "label": "Neely - MS 39461",
    "county": "Greene County",
    "state": "MS",
    "city": "Neely"
  },
  {
    "value": "39462",
    "label": "New Augusta - MS 39462",
    "county": "Perry County",
    "state": "MS",
    "city": "New Augusta"
  },
  {
    "value": "39464",
    "label": "Ovett - MS 39464",
    "county": "Jones County",
    "state": "MS",
    "city": "Ovett"
  },
  {
    "value": "39465",
    "label": "Petal - MS 39465",
    "county": "Forrest County",
    "state": "MS",
    "city": "Petal"
  },
  {
    "value": "39466",
    "label": "Picayune - MS 39466",
    "county": "Pearl River County",
    "state": "MS",
    "city": "Picayune"
  },
  {
    "value": "39470",
    "label": "Poplarville - MS 39470",
    "county": "Pearl River County",
    "state": "MS",
    "city": "Poplarville"
  },
  {
    "value": "39474",
    "label": "Prentiss - MS 39474",
    "county": "Jefferson Davis County",
    "state": "MS",
    "city": "Prentiss"
  },
  {
    "value": "39475",
    "label": "Purvis - MS 39475",
    "county": "Lamar County",
    "state": "MS",
    "city": "Purvis"
  },
  {
    "value": "39476",
    "label": "Richton - MS 39476",
    "county": "Perry County",
    "state": "MS",
    "city": "Richton"
  },
  {
    "value": "39478",
    "label": "Sandy Hook - MS 39478",
    "county": "Marion County",
    "state": "MS",
    "city": "Sandy Hook"
  },
  {
    "value": "39479",
    "label": "Seminary - MS 39479",
    "county": "Covington County",
    "state": "MS",
    "city": "Seminary"
  },
  {
    "value": "39480",
    "label": "Soso - MS 39480",
    "county": "Jones County",
    "state": "MS",
    "city": "Soso"
  },
  {
    "value": "39481",
    "label": "Stringer - MS 39481",
    "county": "Jasper County",
    "state": "MS",
    "city": "Stringer"
  },
  {
    "value": "39482",
    "label": "Sumrall - MS 39482",
    "county": "Lamar County",
    "state": "MS",
    "city": "Sumrall"
  },
  {
    "value": "39483",
    "label": "Foxworth - MS 39483",
    "county": "Marion County",
    "state": "MS",
    "city": "Foxworth"
  },
  {
    "value": "39501",
    "label": "Gulfport - MS 39501",
    "county": "Harrison County",
    "state": "MS",
    "city": "Gulfport"
  },
  {
    "value": "39503",
    "label": "Gulfport - MS 39503",
    "county": "Harrison County",
    "state": "MS",
    "city": "Gulfport"
  },
  {
    "value": "39507",
    "label": "Gulfport - MS 39507",
    "county": "Harrison County",
    "state": "MS",
    "city": "Gulfport"
  },
  {
    "value": "39520",
    "label": "Bay Saint Louis - MS 39520",
    "county": "Hancock County",
    "state": "MS",
    "city": "Bay Saint Louis"
  },
  {
    "value": "39525",
    "label": "Diamondhead - MS 39525",
    "county": "Hancock County",
    "state": "MS",
    "city": "Diamondhead"
  },
  {
    "value": "39530",
    "label": "Biloxi - MS 39530",
    "county": "Harrison County",
    "state": "MS",
    "city": "Biloxi"
  },
  {
    "value": "39531",
    "label": "Biloxi - MS 39531",
    "county": "Harrison County",
    "state": "MS",
    "city": "Biloxi"
  },
  {
    "value": "39532",
    "label": "Biloxi - MS 39532",
    "county": "Harrison County",
    "state": "MS",
    "city": "Biloxi"
  },
  {
    "value": "39540",
    "label": "Diberville - MS 39540",
    "county": "Harrison County",
    "state": "MS",
    "city": "Diberville"
  },
  {
    "value": "39553",
    "label": "Gautier - MS 39553",
    "county": "Jackson County",
    "state": "MS",
    "city": "Gautier"
  },
  {
    "value": "39556",
    "label": "Kiln - MS 39556",
    "county": "Hancock County",
    "state": "MS",
    "city": "Kiln"
  },
  {
    "value": "39560",
    "label": "Long Beach - MS 39560",
    "county": "Harrison County",
    "state": "MS",
    "city": "Long Beach"
  },
  {
    "value": "39561",
    "label": "Mc Henry - MS 39561",
    "county": "Stone County",
    "state": "MS",
    "city": "Mc Henry"
  },
  {
    "value": "39562",
    "label": "Moss Point - MS 39562",
    "county": "Jackson County",
    "state": "MS",
    "city": "Moss Point"
  },
  {
    "value": "39563",
    "label": "Moss Point - MS 39563",
    "county": "Jackson County",
    "state": "MS",
    "city": "Moss Point"
  },
  {
    "value": "39564",
    "label": "Ocean Springs - MS 39564",
    "county": "Jackson County",
    "state": "MS",
    "city": "Ocean Springs"
  },
  {
    "value": "39565",
    "label": "Vancleave - MS 39565",
    "county": "Jackson County",
    "state": "MS",
    "city": "Vancleave"
  },
  {
    "value": "39567",
    "label": "Pascagoula - MS 39567",
    "county": "Jackson County",
    "state": "MS",
    "city": "Pascagoula"
  },
  {
    "value": "39571",
    "label": "Pass Christian - MS 39571",
    "county": "Harrison County",
    "state": "MS",
    "city": "Pass Christian"
  },
  {
    "value": "39572",
    "label": "Pearlington - MS 39572",
    "county": "Hancock County",
    "state": "MS",
    "city": "Pearlington"
  },
  {
    "value": "39573",
    "label": "Perkinston - MS 39573",
    "county": "Stone County",
    "state": "MS",
    "city": "Perkinston"
  },
  {
    "value": "39574",
    "label": "Saucier - MS 39574",
    "county": "Harrison County",
    "state": "MS",
    "city": "Saucier"
  },
  {
    "value": "39576",
    "label": "Waveland - MS 39576",
    "county": "Hancock County",
    "state": "MS",
    "city": "Waveland"
  },
  {
    "value": "39577",
    "label": "Wiggins - MS 39577",
    "county": "Stone County",
    "state": "MS",
    "city": "Wiggins"
  },
  {
    "value": "39581",
    "label": "Pascagoula - MS 39581",
    "county": "Jackson County",
    "state": "MS",
    "city": "Pascagoula"
  },
  {
    "value": "39601",
    "label": "Brookhaven - MS 39601",
    "county": "Lincoln County",
    "state": "MS",
    "city": "Brookhaven"
  },
  {
    "value": "39629",
    "label": "Bogue Chitto - MS 39629",
    "county": "Lincoln County",
    "state": "MS",
    "city": "Bogue Chitto"
  },
  {
    "value": "39630",
    "label": "Bude - MS 39630",
    "county": "Franklin County",
    "state": "MS",
    "city": "Bude"
  },
  {
    "value": "39631",
    "label": "Centreville - MS 39631",
    "county": "Wilkinson County",
    "state": "MS",
    "city": "Centreville"
  },
  {
    "value": "39633",
    "label": "Crosby - MS 39633",
    "county": "Wilkinson County",
    "state": "MS",
    "city": "Crosby"
  },
  {
    "value": "39638",
    "label": "Gloster - MS 39638",
    "county": "Amite County",
    "state": "MS",
    "city": "Gloster"
  },
  {
    "value": "39641",
    "label": "Jayess - MS 39641",
    "county": "Walthall County",
    "state": "MS",
    "city": "Jayess"
  },
  {
    "value": "39643",
    "label": "Kokomo - MS 39643",
    "county": "Marion County",
    "state": "MS",
    "city": "Kokomo"
  },
  {
    "value": "39645",
    "label": "Liberty - MS 39645",
    "county": "Amite County",
    "state": "MS",
    "city": "Liberty"
  },
  {
    "value": "39647",
    "label": "Mc Call Creek - MS 39647",
    "county": "Franklin County",
    "state": "MS",
    "city": "Mc Call Creek"
  },
  {
    "value": "39648",
    "label": "Mccomb - MS 39648",
    "county": "Pike County",
    "state": "MS",
    "city": "Mccomb"
  },
  {
    "value": "39652",
    "label": "Magnolia - MS 39652",
    "county": "Pike County",
    "state": "MS",
    "city": "Magnolia"
  },
  {
    "value": "39653",
    "label": "Meadville - MS 39653",
    "county": "Franklin County",
    "state": "MS",
    "city": "Meadville"
  },
  {
    "value": "39654",
    "label": "Monticello - MS 39654",
    "county": "Lawrence County",
    "state": "MS",
    "city": "Monticello"
  },
  {
    "value": "39656",
    "label": "Oak Vale - MS 39656",
    "county": "Jefferson Davis County",
    "state": "MS",
    "city": "Oak Vale"
  },
  {
    "value": "39657",
    "label": "Osyka - MS 39657",
    "county": "Pike County",
    "state": "MS",
    "city": "Osyka"
  },
  {
    "value": "39661",
    "label": "Roxie - MS 39661",
    "county": "Franklin County",
    "state": "MS",
    "city": "Roxie"
  },
  {
    "value": "39662",
    "label": "Ruth - MS 39662",
    "county": "Lincoln County",
    "state": "MS",
    "city": "Ruth"
  },
  {
    "value": "39663",
    "label": "Silver Creek - MS 39663",
    "county": "Lawrence County",
    "state": "MS",
    "city": "Silver Creek"
  },
  {
    "value": "39664",
    "label": "Smithdale - MS 39664",
    "county": "Amite County",
    "state": "MS",
    "city": "Smithdale"
  },
  {
    "value": "39665",
    "label": "Sontag - MS 39665",
    "county": "Lawrence County",
    "state": "MS",
    "city": "Sontag"
  },
  {
    "value": "39666",
    "label": "Summit - MS 39666",
    "county": "Pike County",
    "state": "MS",
    "city": "Summit"
  },
  {
    "value": "39667",
    "label": "Tylertown - MS 39667",
    "county": "Walthall County",
    "state": "MS",
    "city": "Tylertown"
  },
  {
    "value": "39668",
    "label": "Union Church - MS 39668",
    "county": "Jefferson County",
    "state": "MS",
    "city": "Union Church"
  },
  {
    "value": "39669",
    "label": "Woodville - MS 39669",
    "county": "Wilkinson County",
    "state": "MS",
    "city": "Woodville"
  },
  {
    "value": "39701",
    "label": "Columbus - MS 39701",
    "county": "Lowndes County",
    "state": "MS",
    "city": "Columbus"
  },
  {
    "value": "39702",
    "label": "Columbus - MS 39702",
    "county": "Lowndes County",
    "state": "MS",
    "city": "Columbus"
  },
  {
    "value": "39705",
    "label": "Columbus - MS 39705",
    "county": "Lowndes County",
    "state": "MS",
    "city": "Columbus"
  },
  {
    "value": "39730",
    "label": "Aberdeen - MS 39730",
    "county": "Monroe County",
    "state": "MS",
    "city": "Aberdeen"
  },
  {
    "value": "39735",
    "label": "Ackerman - MS 39735",
    "county": "Choctaw County",
    "state": "MS",
    "city": "Ackerman"
  },
  {
    "value": "39737",
    "label": "Bellefontaine - MS 39737",
    "county": "Webster County",
    "state": "MS",
    "city": "Bellefontaine"
  },
  {
    "value": "39739",
    "label": "Brooksville - MS 39739",
    "county": "Noxubee County",
    "state": "MS",
    "city": "Brooksville"
  },
  {
    "value": "39740",
    "label": "Caledonia - MS 39740",
    "county": "Lowndes County",
    "state": "MS",
    "city": "Caledonia"
  },
  {
    "value": "39741",
    "label": "Cedarbluff - MS 39741",
    "county": "Clay County",
    "state": "MS",
    "city": "Cedarbluff"
  },
  {
    "value": "39743",
    "label": "Crawford - MS 39743",
    "county": "Lowndes County",
    "state": "MS",
    "city": "Crawford"
  },
  {
    "value": "39744",
    "label": "Eupora - MS 39744",
    "county": "Webster County",
    "state": "MS",
    "city": "Eupora"
  },
  {
    "value": "39745",
    "label": "French Camp - MS 39745",
    "county": "Choctaw County",
    "state": "MS",
    "city": "French Camp"
  },
  {
    "value": "39746",
    "label": "Hamilton - MS 39746",
    "county": "Monroe County",
    "state": "MS",
    "city": "Hamilton"
  },
  {
    "value": "39747",
    "label": "Kilmichael - MS 39747",
    "county": "Montgomery County",
    "state": "MS",
    "city": "Kilmichael"
  },
  {
    "value": "39750",
    "label": "Maben - MS 39750",
    "county": "Webster County",
    "state": "MS",
    "city": "Maben"
  },
  {
    "value": "39751",
    "label": "Mantee - MS 39751",
    "county": "Webster County",
    "state": "MS",
    "city": "Mantee"
  },
  {
    "value": "39752",
    "label": "Mathiston - MS 39752",
    "county": "Webster County",
    "state": "MS",
    "city": "Mathiston"
  },
  {
    "value": "39755",
    "label": "Pheba - MS 39755",
    "county": "Clay County",
    "state": "MS",
    "city": "Pheba"
  },
  {
    "value": "39756",
    "label": "Prairie - MS 39756",
    "county": "Clay County",
    "state": "MS",
    "city": "Prairie"
  },
  {
    "value": "39759",
    "label": "Starkville - MS 39759",
    "county": "Oktibbeha County",
    "state": "MS",
    "city": "Starkville"
  },
  {
    "value": "39766",
    "label": "Steens - MS 39766",
    "county": "Lowndes County",
    "state": "MS",
    "city": "Steens"
  },
  {
    "value": "39767",
    "label": "Stewart - MS 39767",
    "county": "Webster County",
    "state": "MS",
    "city": "Stewart"
  },
  {
    "value": "39769",
    "label": "Sturgis - MS 39769",
    "county": "Oktibbeha County",
    "state": "MS",
    "city": "Sturgis"
  },
  {
    "value": "39771",
    "label": "Walthall - MS 39771",
    "county": "Webster County",
    "state": "MS",
    "city": "Walthall"
  },
  {
    "value": "39772",
    "label": "Weir - MS 39772",
    "county": "Choctaw County",
    "state": "MS",
    "city": "Weir"
  },
  {
    "value": "39773",
    "label": "West Point - MS 39773",
    "county": "Clay County",
    "state": "MS",
    "city": "West Point"
  },
  {
    "value": "39776",
    "label": "Woodland - MS 39776",
    "county": "Chickasaw County",
    "state": "MS",
    "city": "Woodland"
  },
  {
    "value": "63005",
    "label": "Chesterfield - MO 63005",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Chesterfield"
  },
  {
    "value": "63010",
    "label": "Arnold - MO 63010",
    "county": "Jefferson County",
    "state": "MO",
    "city": "Arnold"
  },
  {
    "value": "63011",
    "label": "Ballwin - MO 63011",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Ballwin"
  },
  {
    "value": "63012",
    "label": "Barnhart - MO 63012",
    "county": "Jefferson County",
    "state": "MO",
    "city": "Barnhart"
  },
  {
    "value": "63013",
    "label": "Beaufort - MO 63013",
    "county": "Franklin County",
    "state": "MO",
    "city": "Beaufort"
  },
  {
    "value": "63014",
    "label": "Berger - MO 63014",
    "county": "Franklin County",
    "state": "MO",
    "city": "Berger"
  },
  {
    "value": "63015",
    "label": "Catawissa - MO 63015",
    "county": "Franklin County",
    "state": "MO",
    "city": "Catawissa"
  },
  {
    "value": "63016",
    "label": "Cedar Hill - MO 63016",
    "county": "Jefferson County",
    "state": "MO",
    "city": "Cedar Hill"
  },
  {
    "value": "63017",
    "label": "Chesterfield - MO 63017",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Chesterfield"
  },
  {
    "value": "63019",
    "label": "Crystal City - MO 63019",
    "county": "Jefferson County",
    "state": "MO",
    "city": "Crystal City"
  },
  {
    "value": "63020",
    "label": "De Soto - MO 63020",
    "county": "Jefferson County",
    "state": "MO",
    "city": "De Soto"
  },
  {
    "value": "63021",
    "label": "Ballwin - MO 63021",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Ballwin"
  },
  {
    "value": "63023",
    "label": "Dittmer - MO 63023",
    "county": "Jefferson County",
    "state": "MO",
    "city": "Dittmer"
  },
  {
    "value": "63025",
    "label": "Eureka - MO 63025",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Eureka"
  },
  {
    "value": "63026",
    "label": "Fenton - MO 63026",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Fenton"
  },
  {
    "value": "63028",
    "label": "Festus - MO 63028",
    "county": "Jefferson County",
    "state": "MO",
    "city": "Festus"
  },
  {
    "value": "63030",
    "label": "Fletcher - MO 63030",
    "county": "Washington County",
    "state": "MO",
    "city": "Fletcher"
  },
  {
    "value": "63031",
    "label": "Florissant - MO 63031",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Florissant"
  },
  {
    "value": "63033",
    "label": "Florissant - MO 63033",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Florissant"
  },
  {
    "value": "63034",
    "label": "Florissant - MO 63034",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Florissant"
  },
  {
    "value": "63036",
    "label": "French Village - MO 63036",
    "county": "St. Francois County",
    "state": "MO",
    "city": "French Village"
  },
  {
    "value": "63037",
    "label": "Gerald - MO 63037",
    "county": "Franklin County",
    "state": "MO",
    "city": "Gerald"
  },
  {
    "value": "63038",
    "label": "Wildwood - MO 63038",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Wildwood"
  },
  {
    "value": "63039",
    "label": "Gray Summit - MO 63039",
    "county": "Franklin County",
    "state": "MO",
    "city": "Gray Summit"
  },
  {
    "value": "63040",
    "label": "Wildwood - MO 63040",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Wildwood"
  },
  {
    "value": "63041",
    "label": "Grubville - MO 63041",
    "county": "Franklin County",
    "state": "MO",
    "city": "Grubville"
  },
  {
    "value": "63042",
    "label": "Hazelwood - MO 63042",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Hazelwood"
  },
  {
    "value": "63043",
    "label": "Maryland Heights - MO 63043",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Maryland Heights"
  },
  {
    "value": "63044",
    "label": "Bridgeton - MO 63044",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Bridgeton"
  },
  {
    "value": "63045",
    "label": "Earth City - MO 63045",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Earth City"
  },
  {
    "value": "63048",
    "label": "Herculaneum - MO 63048",
    "county": "Jefferson County",
    "state": "MO",
    "city": "Herculaneum"
  },
  {
    "value": "63049",
    "label": "High Ridge - MO 63049",
    "county": "Jefferson County",
    "state": "MO",
    "city": "High Ridge"
  },
  {
    "value": "63050",
    "label": "Hillsboro - MO 63050",
    "county": "Jefferson County",
    "state": "MO",
    "city": "Hillsboro"
  },
  {
    "value": "63051",
    "label": "House Springs - MO 63051",
    "county": "Jefferson County",
    "state": "MO",
    "city": "House Springs"
  },
  {
    "value": "63052",
    "label": "Imperial - MO 63052",
    "county": "Jefferson County",
    "state": "MO",
    "city": "Imperial"
  },
  {
    "value": "63055",
    "label": "Labadie - MO 63055",
    "county": "Franklin County",
    "state": "MO",
    "city": "Labadie"
  },
  {
    "value": "63056",
    "label": "Leslie - MO 63056",
    "county": "Franklin County",
    "state": "MO",
    "city": "Leslie"
  },
  {
    "value": "63060",
    "label": "Lonedell - MO 63060",
    "county": "Franklin County",
    "state": "MO",
    "city": "Lonedell"
  },
  {
    "value": "63061",
    "label": "Luebbering - MO 63061",
    "county": "Franklin County",
    "state": "MO",
    "city": "Luebbering"
  },
  {
    "value": "63068",
    "label": "New Haven - MO 63068",
    "county": "Franklin County",
    "state": "MO",
    "city": "New Haven"
  },
  {
    "value": "63069",
    "label": "Pacific - MO 63069",
    "county": "Franklin County",
    "state": "MO",
    "city": "Pacific"
  },
  {
    "value": "63070",
    "label": "Pevely - MO 63070",
    "county": "Jefferson County",
    "state": "MO",
    "city": "Pevely"
  },
  {
    "value": "63071",
    "label": "Richwoods - MO 63071",
    "county": "Washington County",
    "state": "MO",
    "city": "Richwoods"
  },
  {
    "value": "63072",
    "label": "Robertsville - MO 63072",
    "county": "Franklin County",
    "state": "MO",
    "city": "Robertsville"
  },
  {
    "value": "63074",
    "label": "Saint Ann - MO 63074",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Ann"
  },
  {
    "value": "63077",
    "label": "Saint Clair - MO 63077",
    "county": "Franklin County",
    "state": "MO",
    "city": "Saint Clair"
  },
  {
    "value": "63080",
    "label": "Sullivan - MO 63080",
    "county": "Franklin County",
    "state": "MO",
    "city": "Sullivan"
  },
  {
    "value": "63084",
    "label": "Union - MO 63084",
    "county": "Franklin County",
    "state": "MO",
    "city": "Union"
  },
  {
    "value": "63087",
    "label": "Valles Mines - MO 63087",
    "county": "St. Francois County",
    "state": "MO",
    "city": "Valles Mines"
  },
  {
    "value": "63088",
    "label": "Valley Park - MO 63088",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Valley Park"
  },
  {
    "value": "63089",
    "label": "Villa Ridge - MO 63089",
    "county": "Franklin County",
    "state": "MO",
    "city": "Villa Ridge"
  },
  {
    "value": "63090",
    "label": "Washington - MO 63090",
    "county": "Franklin County",
    "state": "MO",
    "city": "Washington"
  },
  {
    "value": "63091",
    "label": "Rosebud - MO 63091",
    "county": "Gasconade County",
    "state": "MO",
    "city": "Rosebud"
  },
  {
    "value": "63101",
    "label": "Saint Louis - MO 63101",
    "county": "St. Louis city",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63102",
    "label": "Saint Louis - MO 63102",
    "county": "St. Louis city",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63103",
    "label": "Saint Louis - MO 63103",
    "county": "St. Louis city",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63104",
    "label": "Saint Louis - MO 63104",
    "county": "St. Louis city",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63105",
    "label": "Saint Louis - MO 63105",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63106",
    "label": "Saint Louis - MO 63106",
    "county": "St. Louis city",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63107",
    "label": "Saint Louis - MO 63107",
    "county": "St. Louis city",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63108",
    "label": "Saint Louis - MO 63108",
    "county": "St. Louis city",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63109",
    "label": "Saint Louis - MO 63109",
    "county": "St. Louis city",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63110",
    "label": "Saint Louis - MO 63110",
    "county": "St. Louis city",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63111",
    "label": "Saint Louis - MO 63111",
    "county": "St. Louis city",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63112",
    "label": "Saint Louis - MO 63112",
    "county": "St. Louis city",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63113",
    "label": "Saint Louis - MO 63113",
    "county": "St. Louis city",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63114",
    "label": "Saint Louis - MO 63114",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63115",
    "label": "Saint Louis - MO 63115",
    "county": "St. Louis city",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63116",
    "label": "Saint Louis - MO 63116",
    "county": "St. Louis city",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63117",
    "label": "Saint Louis - MO 63117",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63118",
    "label": "Saint Louis - MO 63118",
    "county": "St. Louis city",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63119",
    "label": "Saint Louis - MO 63119",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63120",
    "label": "Saint Louis - MO 63120",
    "county": "St. Louis city",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63121",
    "label": "Saint Louis - MO 63121",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63122",
    "label": "Saint Louis - MO 63122",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63123",
    "label": "Saint Louis - MO 63123",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63124",
    "label": "Saint Louis - MO 63124",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63125",
    "label": "Saint Louis - MO 63125",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63126",
    "label": "Saint Louis - MO 63126",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63127",
    "label": "Saint Louis - MO 63127",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63128",
    "label": "Saint Louis - MO 63128",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63129",
    "label": "Saint Louis - MO 63129",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63130",
    "label": "Saint Louis - MO 63130",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63131",
    "label": "Saint Louis - MO 63131",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63132",
    "label": "Saint Louis - MO 63132",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63133",
    "label": "Saint Louis - MO 63133",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63134",
    "label": "Saint Louis - MO 63134",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63135",
    "label": "Saint Louis - MO 63135",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63136",
    "label": "Saint Louis - MO 63136",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63137",
    "label": "Saint Louis - MO 63137",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63138",
    "label": "Saint Louis - MO 63138",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63139",
    "label": "Saint Louis - MO 63139",
    "county": "St. Louis city",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63140",
    "label": "Saint Louis - MO 63140",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63141",
    "label": "Saint Louis - MO 63141",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63143",
    "label": "Saint Louis - MO 63143",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63144",
    "label": "Saint Louis - MO 63144",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63146",
    "label": "Saint Louis - MO 63146",
    "county": "St. Louis County",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63147",
    "label": "Saint Louis - MO 63147",
    "county": "St. Louis city",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63155",
    "label": "Saint Louis - MO 63155",
    "county": "St. Louis city",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63180",
    "label": "Saint Louis - MO 63180",
    "county": "St. Louis City",
    "state": "MO",
    "city": "Saint Louis"
  },
  {
    "value": "63301",
    "label": "Saint Charles - MO 63301",
    "county": "St. Charles County",
    "state": "MO",
    "city": "Saint Charles"
  },
  {
    "value": "63303",
    "label": "Saint Charles - MO 63303",
    "county": "St. Charles County",
    "state": "MO",
    "city": "Saint Charles"
  },
  {
    "value": "63304",
    "label": "Saint Charles - MO 63304",
    "county": "St. Charles County",
    "state": "MO",
    "city": "Saint Charles"
  },
  {
    "value": "63330",
    "label": "Annada - MO 63330",
    "county": "Pike County",
    "state": "MO",
    "city": "Annada"
  },
  {
    "value": "63332",
    "label": "Augusta - MO 63332",
    "county": "St. Charles County",
    "state": "MO",
    "city": "Augusta"
  },
  {
    "value": "63333",
    "label": "Bellflower - MO 63333",
    "county": "Montgomery County",
    "state": "MO",
    "city": "Bellflower"
  },
  {
    "value": "63334",
    "label": "Bowling Green - MO 63334",
    "county": "Pike County",
    "state": "MO",
    "city": "Bowling Green"
  },
  {
    "value": "63336",
    "label": "Clarksville - MO 63336",
    "county": "Pike County",
    "state": "MO",
    "city": "Clarksville"
  },
  {
    "value": "63339",
    "label": "Curryville - MO 63339",
    "county": "Pike County",
    "state": "MO",
    "city": "Curryville"
  },
  {
    "value": "63341",
    "label": "Defiance - MO 63341",
    "county": "St. Charles County",
    "state": "MO",
    "city": "Defiance"
  },
  {
    "value": "63343",
    "label": "Elsberry - MO 63343",
    "county": "Lincoln County",
    "state": "MO",
    "city": "Elsberry"
  },
  {
    "value": "63344",
    "label": "Eolia - MO 63344",
    "county": "Pike County",
    "state": "MO",
    "city": "Eolia"
  },
  {
    "value": "63345",
    "label": "Farber - MO 63345",
    "county": "Audrain County",
    "state": "MO",
    "city": "Farber"
  },
  {
    "value": "63347",
    "label": "Foley - MO 63347",
    "county": "Lincoln County",
    "state": "MO",
    "city": "Foley"
  },
  {
    "value": "63348",
    "label": "Foristell - MO 63348",
    "county": "St. Charles County",
    "state": "MO",
    "city": "Foristell"
  },
  {
    "value": "63349",
    "label": "Hawk Point - MO 63349",
    "county": "Lincoln County",
    "state": "MO",
    "city": "Hawk Point"
  },
  {
    "value": "63350",
    "label": "High Hill - MO 63350",
    "county": "Montgomery County",
    "state": "MO",
    "city": "High Hill"
  },
  {
    "value": "63351",
    "label": "Jonesburg - MO 63351",
    "county": "Montgomery County",
    "state": "MO",
    "city": "Jonesburg"
  },
  {
    "value": "63352",
    "label": "Laddonia - MO 63352",
    "county": "Audrain County",
    "state": "MO",
    "city": "Laddonia"
  },
  {
    "value": "63353",
    "label": "Louisiana - MO 63353",
    "county": "Pike County",
    "state": "MO",
    "city": "Louisiana"
  },
  {
    "value": "63357",
    "label": "Marthasville - MO 63357",
    "county": "Warren County",
    "state": "MO",
    "city": "Marthasville"
  },
  {
    "value": "63359",
    "label": "Middletown - MO 63359",
    "county": "Montgomery County",
    "state": "MO",
    "city": "Middletown"
  },
  {
    "value": "63361",
    "label": "Montgomery City - MO 63361",
    "county": "Montgomery County",
    "state": "MO",
    "city": "Montgomery City"
  },
  {
    "value": "63362",
    "label": "Moscow Mills - MO 63362",
    "county": "Lincoln County",
    "state": "MO",
    "city": "Moscow Mills"
  },
  {
    "value": "63363",
    "label": "New Florence - MO 63363",
    "county": "Montgomery County",
    "state": "MO",
    "city": "New Florence"
  },
  {
    "value": "63366",
    "label": "O Fallon - MO 63366",
    "county": "St. Charles County",
    "state": "MO",
    "city": "O Fallon"
  },
  {
    "value": "63367",
    "label": "Lake Saint Louis - MO 63367",
    "county": "St. Charles County",
    "state": "MO",
    "city": "Lake Saint Louis"
  },
  {
    "value": "63368",
    "label": "O Fallon - MO 63368",
    "county": "St. Charles County",
    "state": "MO",
    "city": "O Fallon"
  },
  {
    "value": "63369",
    "label": "Old Monroe - MO 63369",
    "county": "Lincoln County",
    "state": "MO",
    "city": "Old Monroe"
  },
  {
    "value": "63373",
    "label": "Portage Des Sioux - MO 63373",
    "county": "St. Charles County",
    "state": "MO",
    "city": "Portage Des Sioux"
  },
  {
    "value": "63376",
    "label": "Saint Peters - MO 63376",
    "county": "St. Charles County",
    "state": "MO",
    "city": "Saint Peters"
  },
  {
    "value": "63377",
    "label": "Silex - MO 63377",
    "county": "Lincoln County",
    "state": "MO",
    "city": "Silex"
  },
  {
    "value": "63379",
    "label": "Troy - MO 63379",
    "county": "Lincoln County",
    "state": "MO",
    "city": "Troy"
  },
  {
    "value": "63380",
    "label": "Truesdale - MO 63380",
    "county": "Warren County",
    "state": "MO",
    "city": "Truesdale"
  },
  {
    "value": "63381",
    "label": "Truxton - MO 63381",
    "county": "Warren County",
    "state": "MO",
    "city": "Truxton"
  },
  {
    "value": "63382",
    "label": "Vandalia - MO 63382",
    "county": "Audrain County",
    "state": "MO",
    "city": "Vandalia"
  },
  {
    "value": "63383",
    "label": "Warrenton - MO 63383",
    "county": "Warren County",
    "state": "MO",
    "city": "Warrenton"
  },
  {
    "value": "63384",
    "label": "Wellsville - MO 63384",
    "county": "Montgomery County",
    "state": "MO",
    "city": "Wellsville"
  },
  {
    "value": "63385",
    "label": "Wentzville - MO 63385",
    "county": "St. Charles County",
    "state": "MO",
    "city": "Wentzville"
  },
  {
    "value": "63386",
    "label": "West Alton - MO 63386",
    "county": "St. Charles County",
    "state": "MO",
    "city": "West Alton"
  },
  {
    "value": "63388",
    "label": "Williamsburg - MO 63388",
    "county": "Callaway County",
    "state": "MO",
    "city": "Williamsburg"
  },
  {
    "value": "63389",
    "label": "Winfield - MO 63389",
    "county": "Lincoln County",
    "state": "MO",
    "city": "Winfield"
  },
  {
    "value": "63390",
    "label": "Wright City - MO 63390",
    "county": "Warren County",
    "state": "MO",
    "city": "Wright City"
  },
  {
    "value": "63401",
    "label": "Hannibal - MO 63401",
    "county": "Marion County",
    "state": "MO",
    "city": "Hannibal"
  },
  {
    "value": "63430",
    "label": "Alexandria - MO 63430",
    "county": "Clark County",
    "state": "MO",
    "city": "Alexandria"
  },
  {
    "value": "63431",
    "label": "Anabel - MO 63431",
    "county": "Macon County",
    "state": "MO",
    "city": "Anabel"
  },
  {
    "value": "63432",
    "label": "Arbela - MO 63432",
    "county": "Scotland County",
    "state": "MO",
    "city": "Arbela"
  },
  {
    "value": "63433",
    "label": "Ashburn - MO 63433",
    "county": "Pike County",
    "state": "MO",
    "city": "Ashburn"
  },
  {
    "value": "63434",
    "label": "Bethel - MO 63434",
    "county": "Shelby County",
    "state": "MO",
    "city": "Bethel"
  },
  {
    "value": "63435",
    "label": "Canton - MO 63435",
    "county": "Lewis County",
    "state": "MO",
    "city": "Canton"
  },
  {
    "value": "63436",
    "label": "Center - MO 63436",
    "county": "Ralls County",
    "state": "MO",
    "city": "Center"
  },
  {
    "value": "63437",
    "label": "Clarence - MO 63437",
    "county": "Shelby County",
    "state": "MO",
    "city": "Clarence"
  },
  {
    "value": "63438",
    "label": "Durham - MO 63438",
    "county": "Lewis County",
    "state": "MO",
    "city": "Durham"
  },
  {
    "value": "63439",
    "label": "Emden - MO 63439",
    "county": "Shelby County",
    "state": "MO",
    "city": "Emden"
  },
  {
    "value": "63440",
    "label": "Ewing - MO 63440",
    "county": "Lewis County",
    "state": "MO",
    "city": "Ewing"
  },
  {
    "value": "63441",
    "label": "Frankford - MO 63441",
    "county": "Pike County",
    "state": "MO",
    "city": "Frankford"
  },
  {
    "value": "63442",
    "label": "Granger - MO 63442",
    "county": "Scotland County",
    "state": "MO",
    "city": "Granger"
  },
  {
    "value": "63443",
    "label": "Hunnewell - MO 63443",
    "county": "Shelby County",
    "state": "MO",
    "city": "Hunnewell"
  },
  {
    "value": "63445",
    "label": "Kahoka - MO 63445",
    "county": "Clark County",
    "state": "MO",
    "city": "Kahoka"
  },
  {
    "value": "63446",
    "label": "Knox City - MO 63446",
    "county": "Knox County",
    "state": "MO",
    "city": "Knox City"
  },
  {
    "value": "63447",
    "label": "La Belle - MO 63447",
    "county": "Lewis County",
    "state": "MO",
    "city": "La Belle"
  },
  {
    "value": "63448",
    "label": "La Grange - MO 63448",
    "county": "Lewis County",
    "state": "MO",
    "city": "La Grange"
  },
  {
    "value": "63450",
    "label": "Lentner - MO 63450",
    "county": "Shelby County",
    "state": "MO",
    "city": "Lentner"
  },
  {
    "value": "63451",
    "label": "Leonard - MO 63451",
    "county": "Shelby County",
    "state": "MO",
    "city": "Leonard"
  },
  {
    "value": "63452",
    "label": "Lewistown - MO 63452",
    "county": "Lewis County",
    "state": "MO",
    "city": "Lewistown"
  },
  {
    "value": "63453",
    "label": "Luray - MO 63453",
    "county": "Clark County",
    "state": "MO",
    "city": "Luray"
  },
  {
    "value": "63454",
    "label": "Maywood - MO 63454",
    "county": "Marion County",
    "state": "MO",
    "city": "Maywood"
  },
  {
    "value": "63456",
    "label": "Monroe City - MO 63456",
    "county": "Monroe County",
    "state": "MO",
    "city": "Monroe City"
  },
  {
    "value": "63457",
    "label": "Monticello - MO 63457",
    "county": "Lewis County",
    "state": "MO",
    "city": "Monticello"
  },
  {
    "value": "63458",
    "label": "Newark - MO 63458",
    "county": "Knox County",
    "state": "MO",
    "city": "Newark"
  },
  {
    "value": "63459",
    "label": "New London - MO 63459",
    "county": "Ralls County",
    "state": "MO",
    "city": "New London"
  },
  {
    "value": "63460",
    "label": "Novelty - MO 63460",
    "county": "Knox County",
    "state": "MO",
    "city": "Novelty"
  },
  {
    "value": "63461",
    "label": "Palmyra - MO 63461",
    "county": "Marion County",
    "state": "MO",
    "city": "Palmyra"
  },
  {
    "value": "63462",
    "label": "Perry - MO 63462",
    "county": "Ralls County",
    "state": "MO",
    "city": "Perry"
  },
  {
    "value": "63463",
    "label": "Philadelphia - MO 63463",
    "county": "Marion County",
    "state": "MO",
    "city": "Philadelphia"
  },
  {
    "value": "63464",
    "label": "Plevna - MO 63464",
    "county": "Knox County",
    "state": "MO",
    "city": "Plevna"
  },
  {
    "value": "63465",
    "label": "Revere - MO 63465",
    "county": "Clark County",
    "state": "MO",
    "city": "Revere"
  },
  {
    "value": "63468",
    "label": "Shelbina - MO 63468",
    "county": "Shelby County",
    "state": "MO",
    "city": "Shelbina"
  },
  {
    "value": "63469",
    "label": "Shelbyville - MO 63469",
    "county": "Shelby County",
    "state": "MO",
    "city": "Shelbyville"
  },
  {
    "value": "63471",
    "label": "Taylor - MO 63471",
    "county": "Marion County",
    "state": "MO",
    "city": "Taylor"
  },
  {
    "value": "63472",
    "label": "Wayland - MO 63472",
    "county": "Clark County",
    "state": "MO",
    "city": "Wayland"
  },
  {
    "value": "63473",
    "label": "Williamstown - MO 63473",
    "county": "Lewis County",
    "state": "MO",
    "city": "Williamstown"
  },
  {
    "value": "63474",
    "label": "Wyaconda - MO 63474",
    "county": "Clark County",
    "state": "MO",
    "city": "Wyaconda"
  },
  {
    "value": "63501",
    "label": "Kirksville - MO 63501",
    "county": "Adair County",
    "state": "MO",
    "city": "Kirksville"
  },
  {
    "value": "63530",
    "label": "Atlanta - MO 63530",
    "county": "Macon County",
    "state": "MO",
    "city": "Atlanta"
  },
  {
    "value": "63531",
    "label": "Baring - MO 63531",
    "county": "Knox County",
    "state": "MO",
    "city": "Baring"
  },
  {
    "value": "63532",
    "label": "Bevier - MO 63532",
    "county": "Macon County",
    "state": "MO",
    "city": "Bevier"
  },
  {
    "value": "63533",
    "label": "Brashear - MO 63533",
    "county": "Adair County",
    "state": "MO",
    "city": "Brashear"
  },
  {
    "value": "63534",
    "label": "Callao - MO 63534",
    "county": "Macon County",
    "state": "MO",
    "city": "Callao"
  },
  {
    "value": "63535",
    "label": "Coatsville - MO 63535",
    "county": "Schuyler County",
    "state": "MO",
    "city": "Coatsville"
  },
  {
    "value": "63536",
    "label": "Downing - MO 63536",
    "county": "Schuyler County",
    "state": "MO",
    "city": "Downing"
  },
  {
    "value": "63537",
    "label": "Edina - MO 63537",
    "county": "Knox County",
    "state": "MO",
    "city": "Edina"
  },
  {
    "value": "63538",
    "label": "Elmer - MO 63538",
    "county": "Macon County",
    "state": "MO",
    "city": "Elmer"
  },
  {
    "value": "63539",
    "label": "Ethel - MO 63539",
    "county": "Macon County",
    "state": "MO",
    "city": "Ethel"
  },
  {
    "value": "63540",
    "label": "Gibbs - MO 63540",
    "county": "Adair County",
    "state": "MO",
    "city": "Gibbs"
  },
  {
    "value": "63541",
    "label": "Glenwood - MO 63541",
    "county": "Schuyler County",
    "state": "MO",
    "city": "Glenwood"
  },
  {
    "value": "63543",
    "label": "Gorin - MO 63543",
    "county": "Scotland County",
    "state": "MO",
    "city": "Gorin"
  },
  {
    "value": "63544",
    "label": "Green Castle - MO 63544",
    "county": "Sullivan County",
    "state": "MO",
    "city": "Green Castle"
  },
  {
    "value": "63545",
    "label": "Green City - MO 63545",
    "county": "Sullivan County",
    "state": "MO",
    "city": "Green City"
  },
  {
    "value": "63546",
    "label": "Greentop - MO 63546",
    "county": "Adair County",
    "state": "MO",
    "city": "Greentop"
  },
  {
    "value": "63547",
    "label": "Hurdland - MO 63547",
    "county": "Knox County",
    "state": "MO",
    "city": "Hurdland"
  },
  {
    "value": "63548",
    "label": "Lancaster - MO 63548",
    "county": "Schuyler County",
    "state": "MO",
    "city": "Lancaster"
  },
  {
    "value": "63549",
    "label": "La Plata - MO 63549",
    "county": "Macon County",
    "state": "MO",
    "city": "La Plata"
  },
  {
    "value": "63551",
    "label": "Livonia - MO 63551",
    "county": "Putnam County",
    "state": "MO",
    "city": "Livonia"
  },
  {
    "value": "63552",
    "label": "Macon - MO 63552",
    "county": "Macon County",
    "state": "MO",
    "city": "Macon"
  },
  {
    "value": "63555",
    "label": "Memphis - MO 63555",
    "county": "Scotland County",
    "state": "MO",
    "city": "Memphis"
  },
  {
    "value": "63556",
    "label": "Milan - MO 63556",
    "county": "Sullivan County",
    "state": "MO",
    "city": "Milan"
  },
  {
    "value": "63557",
    "label": "New Boston - MO 63557",
    "county": "Linn County",
    "state": "MO",
    "city": "New Boston"
  },
  {
    "value": "63558",
    "label": "New Cambria - MO 63558",
    "county": "Macon County",
    "state": "MO",
    "city": "New Cambria"
  },
  {
    "value": "63559",
    "label": "Novinger - MO 63559",
    "county": "Adair County",
    "state": "MO",
    "city": "Novinger"
  },
  {
    "value": "63560",
    "label": "Pollock - MO 63560",
    "county": "Sullivan County",
    "state": "MO",
    "city": "Pollock"
  },
  {
    "value": "63561",
    "label": "Queen City - MO 63561",
    "county": "Schuyler County",
    "state": "MO",
    "city": "Queen City"
  },
  {
    "value": "63563",
    "label": "Rutledge - MO 63563",
    "county": "Scotland County",
    "state": "MO",
    "city": "Rutledge"
  },
  {
    "value": "63565",
    "label": "Unionville - MO 63565",
    "county": "Putnam County",
    "state": "MO",
    "city": "Unionville"
  },
  {
    "value": "63566",
    "label": "Winigan - MO 63566",
    "county": "Sullivan County",
    "state": "MO",
    "city": "Winigan"
  },
  {
    "value": "63567",
    "label": "Worthington - MO 63567",
    "county": "Putnam County",
    "state": "MO",
    "city": "Worthington"
  },
  {
    "value": "63601",
    "label": "Park Hills - MO 63601",
    "county": "St. Francois County",
    "state": "MO",
    "city": "Park Hills"
  },
  {
    "value": "63620",
    "label": "Annapolis - MO 63620",
    "county": "Iron County",
    "state": "MO",
    "city": "Annapolis"
  },
  {
    "value": "63621",
    "label": "Arcadia - MO 63621",
    "county": "Iron County",
    "state": "MO",
    "city": "Arcadia"
  },
  {
    "value": "63622",
    "label": "Belgrade - MO 63622",
    "county": "Washington County",
    "state": "MO",
    "city": "Belgrade"
  },
  {
    "value": "63623",
    "label": "Belleview - MO 63623",
    "county": "Iron County",
    "state": "MO",
    "city": "Belleview"
  },
  {
    "value": "63624",
    "label": "Bismarck - MO 63624",
    "county": "St. Francois County",
    "state": "MO",
    "city": "Bismarck"
  },
  {
    "value": "63625",
    "label": "Black - MO 63625",
    "county": "Reynolds County",
    "state": "MO",
    "city": "Black"
  },
  {
    "value": "63626",
    "label": "Blackwell - MO 63626",
    "county": "Washington County",
    "state": "MO",
    "city": "Blackwell"
  },
  {
    "value": "63627",
    "label": "Bloomsdale - MO 63627",
    "county": "Ste. Genevieve County",
    "state": "MO",
    "city": "Bloomsdale"
  },
  {
    "value": "63628",
    "label": "Bonne Terre - MO 63628",
    "county": "St. Francois County",
    "state": "MO",
    "city": "Bonne Terre"
  },
  {
    "value": "63629",
    "label": "Bunker - MO 63629",
    "county": "Reynolds County",
    "state": "MO",
    "city": "Bunker"
  },
  {
    "value": "63630",
    "label": "Cadet - MO 63630",
    "county": "Washington County",
    "state": "MO",
    "city": "Cadet"
  },
  {
    "value": "63631",
    "label": "Caledonia - MO 63631",
    "county": "Washington County",
    "state": "MO",
    "city": "Caledonia"
  },
  {
    "value": "63633",
    "label": "Centerville - MO 63633",
    "county": "Reynolds County",
    "state": "MO",
    "city": "Centerville"
  },
  {
    "value": "63636",
    "label": "Des Arc - MO 63636",
    "county": "Iron County",
    "state": "MO",
    "city": "Des Arc"
  },
  {
    "value": "63637",
    "label": "Doe Run - MO 63637",
    "county": "St. Francois County",
    "state": "MO",
    "city": "Doe Run"
  },
  {
    "value": "63638",
    "label": "Ellington - MO 63638",
    "county": "Reynolds County",
    "state": "MO",
    "city": "Ellington"
  },
  {
    "value": "63640",
    "label": "Farmington - MO 63640",
    "county": "St. Francois County",
    "state": "MO",
    "city": "Farmington"
  },
  {
    "value": "63645",
    "label": "Fredericktown - MO 63645",
    "county": "Madison County",
    "state": "MO",
    "city": "Fredericktown"
  },
  {
    "value": "63648",
    "label": "Irondale - MO 63648",
    "county": "Washington County",
    "state": "MO",
    "city": "Irondale"
  },
  {
    "value": "63650",
    "label": "Ironton - MO 63650",
    "county": "Iron County",
    "state": "MO",
    "city": "Ironton"
  },
  {
    "value": "63653",
    "label": "Leadwood - MO 63653",
    "county": "St. Francois County",
    "state": "MO",
    "city": "Leadwood"
  },
  {
    "value": "63654",
    "label": "Lesterville - MO 63654",
    "county": "Reynolds County",
    "state": "MO",
    "city": "Lesterville"
  },
  {
    "value": "63655",
    "label": "Marquand - MO 63655",
    "county": "Madison County",
    "state": "MO",
    "city": "Marquand"
  },
  {
    "value": "63656",
    "label": "Middle Brook - MO 63656",
    "county": "Iron County",
    "state": "MO",
    "city": "Middle Brook"
  },
  {
    "value": "63660",
    "label": "Mineral Point - MO 63660",
    "county": "Washington County",
    "state": "MO",
    "city": "Mineral Point"
  },
  {
    "value": "63662",
    "label": "Patton - MO 63662",
    "county": "Bollinger County",
    "state": "MO",
    "city": "Patton"
  },
  {
    "value": "63664",
    "label": "Potosi - MO 63664",
    "county": "Washington County",
    "state": "MO",
    "city": "Potosi"
  },
  {
    "value": "63665",
    "label": "Redford - MO 63665",
    "county": "Reynolds County",
    "state": "MO",
    "city": "Redford"
  },
  {
    "value": "63670",
    "label": "Sainte Genevieve - MO 63670",
    "county": "Ste. Genevieve County",
    "state": "MO",
    "city": "Sainte Genevieve"
  },
  {
    "value": "63673",
    "label": "Saint Mary - MO 63673",
    "county": "Ste. Genevieve County",
    "state": "MO",
    "city": "Saint Mary"
  },
  {
    "value": "63675",
    "label": "Vulcan - MO 63675",
    "county": "Iron County",
    "state": "MO",
    "city": "Vulcan"
  },
  {
    "value": "63701",
    "label": "Cape Girardeau - MO 63701",
    "county": "Cape Girardeau County",
    "state": "MO",
    "city": "Cape Girardeau"
  },
  {
    "value": "63703",
    "label": "Cape Girardeau - MO 63703",
    "county": "Cape Girardeau County",
    "state": "MO",
    "city": "Cape Girardeau"
  },
  {
    "value": "63730",
    "label": "Advance - MO 63730",
    "county": "Stoddard County",
    "state": "MO",
    "city": "Advance"
  },
  {
    "value": "63732",
    "label": "Altenburg - MO 63732",
    "county": "Perry County",
    "state": "MO",
    "city": "Altenburg"
  },
  {
    "value": "63735",
    "label": "Bell City - MO 63735",
    "county": "Stoddard County",
    "state": "MO",
    "city": "Bell City"
  },
  {
    "value": "63736",
    "label": "Benton - MO 63736",
    "county": "Scott County",
    "state": "MO",
    "city": "Benton"
  },
  {
    "value": "63737",
    "label": "Brazeau - MO 63737",
    "county": "Perry County",
    "state": "MO",
    "city": "Brazeau"
  },
  {
    "value": "63739",
    "label": "Burfordville - MO 63739",
    "county": "Cape Girardeau County",
    "state": "MO",
    "city": "Burfordville"
  },
  {
    "value": "63740",
    "label": "Chaffee - MO 63740",
    "county": "Scott County",
    "state": "MO",
    "city": "Chaffee"
  },
  {
    "value": "63743",
    "label": "Daisy - MO 63743",
    "county": "Cape Girardeau County",
    "state": "MO",
    "city": "Daisy"
  },
  {
    "value": "63747",
    "label": "Friedheim - MO 63747",
    "county": "Cape Girardeau County",
    "state": "MO",
    "city": "Friedheim"
  },
  {
    "value": "63748",
    "label": "Frohna - MO 63748",
    "county": "Perry County",
    "state": "MO",
    "city": "Frohna"
  },
  {
    "value": "63750",
    "label": "Gipsy - MO 63750",
    "county": "Bollinger County",
    "state": "MO",
    "city": "Gipsy"
  },
  {
    "value": "63751",
    "label": "Glenallen - MO 63751",
    "county": "Bollinger County",
    "state": "MO",
    "city": "Glenallen"
  },
  {
    "value": "63755",
    "label": "Jackson - MO 63755",
    "county": "Cape Girardeau County",
    "state": "MO",
    "city": "Jackson"
  },
  {
    "value": "63760",
    "label": "Leopold - MO 63760",
    "county": "Bollinger County",
    "state": "MO",
    "city": "Leopold"
  },
  {
    "value": "63763",
    "label": "Mc Gee - MO 63763",
    "county": "Wayne County",
    "state": "MO",
    "city": "Mc Gee"
  },
  {
    "value": "63764",
    "label": "Marble Hill - MO 63764",
    "county": "Bollinger County",
    "state": "MO",
    "city": "Marble Hill"
  },
  {
    "value": "63766",
    "label": "Millersville - MO 63766",
    "county": "Cape Girardeau County",
    "state": "MO",
    "city": "Millersville"
  },
  {
    "value": "63769",
    "label": "Oak Ridge - MO 63769",
    "county": "Cape Girardeau County",
    "state": "MO",
    "city": "Oak Ridge"
  },
  {
    "value": "63770",
    "label": "Old Appleton - MO 63770",
    "county": "Cape Girardeau County",
    "state": "MO",
    "city": "Old Appleton"
  },
  {
    "value": "63771",
    "label": "Oran - MO 63771",
    "county": "Scott County",
    "state": "MO",
    "city": "Oran"
  },
  {
    "value": "63775",
    "label": "Perryville - MO 63775",
    "county": "Perry County",
    "state": "MO",
    "city": "Perryville"
  },
  {
    "value": "63780",
    "label": "Scott City - MO 63780",
    "county": "Scott County",
    "state": "MO",
    "city": "Scott City"
  },
  {
    "value": "63781",
    "label": "Sedgewickville - MO 63781",
    "county": "Bollinger County",
    "state": "MO",
    "city": "Sedgewickville"
  },
  {
    "value": "63782",
    "label": "Sturdivant - MO 63782",
    "county": "Bollinger County",
    "state": "MO",
    "city": "Sturdivant"
  },
  {
    "value": "63783",
    "label": "Uniontown - MO 63783",
    "county": "Perry County",
    "state": "MO",
    "city": "Uniontown"
  },
  {
    "value": "63785",
    "label": "Whitewater - MO 63785",
    "county": "Cape Girardeau County",
    "state": "MO",
    "city": "Whitewater"
  },
  {
    "value": "63787",
    "label": "Zalma - MO 63787",
    "county": "Bollinger County",
    "state": "MO",
    "city": "Zalma"
  },
  {
    "value": "63801",
    "label": "Sikeston - MO 63801",
    "county": "Scott County",
    "state": "MO",
    "city": "Sikeston"
  },
  {
    "value": "63821",
    "label": "Arbyrd - MO 63821",
    "county": "Dunklin County",
    "state": "MO",
    "city": "Arbyrd"
  },
  {
    "value": "63822",
    "label": "Bernie - MO 63822",
    "county": "Stoddard County",
    "state": "MO",
    "city": "Bernie"
  },
  {
    "value": "63823",
    "label": "Bertrand - MO 63823",
    "county": "Mississippi County",
    "state": "MO",
    "city": "Bertrand"
  },
  {
    "value": "63825",
    "label": "Bloomfield - MO 63825",
    "county": "Stoddard County",
    "state": "MO",
    "city": "Bloomfield"
  },
  {
    "value": "63827",
    "label": "Bragg City - MO 63827",
    "county": "Pemiscot County",
    "state": "MO",
    "city": "Bragg City"
  },
  {
    "value": "63829",
    "label": "Cardwell - MO 63829",
    "county": "Dunklin County",
    "state": "MO",
    "city": "Cardwell"
  },
  {
    "value": "63830",
    "label": "Caruthersville - MO 63830",
    "county": "Pemiscot County",
    "state": "MO",
    "city": "Caruthersville"
  },
  {
    "value": "63833",
    "label": "Catron - MO 63833",
    "county": "New Madrid County",
    "state": "MO",
    "city": "Catron"
  },
  {
    "value": "63834",
    "label": "Charleston - MO 63834",
    "county": "Mississippi County",
    "state": "MO",
    "city": "Charleston"
  },
  {
    "value": "63837",
    "label": "Clarkton - MO 63837",
    "county": "Dunklin County",
    "state": "MO",
    "city": "Clarkton"
  },
  {
    "value": "63841",
    "label": "Dexter - MO 63841",
    "county": "Stoddard County",
    "state": "MO",
    "city": "Dexter"
  },
  {
    "value": "63845",
    "label": "East Prairie - MO 63845",
    "county": "Mississippi County",
    "state": "MO",
    "city": "East Prairie"
  },
  {
    "value": "63846",
    "label": "Essex - MO 63846",
    "county": "Stoddard County",
    "state": "MO",
    "city": "Essex"
  },
  {
    "value": "63848",
    "label": "Gideon - MO 63848",
    "county": "New Madrid County",
    "state": "MO",
    "city": "Gideon"
  },
  {
    "value": "63849",
    "label": "Gobler - MO 63849",
    "county": "Dunklin County",
    "state": "MO",
    "city": "Gobler"
  },
  {
    "value": "63851",
    "label": "Hayti - MO 63851",
    "county": "Pemiscot County",
    "state": "MO",
    "city": "Hayti"
  },
  {
    "value": "63852",
    "label": "Holcomb - MO 63852",
    "county": "Dunklin County",
    "state": "MO",
    "city": "Holcomb"
  },
  {
    "value": "63855",
    "label": "Hornersville - MO 63855",
    "county": "Dunklin County",
    "state": "MO",
    "city": "Hornersville"
  },
  {
    "value": "63857",
    "label": "Kennett - MO 63857",
    "county": "Dunklin County",
    "state": "MO",
    "city": "Kennett"
  },
  {
    "value": "63862",
    "label": "Lilbourn - MO 63862",
    "county": "New Madrid County",
    "state": "MO",
    "city": "Lilbourn"
  },
  {
    "value": "63863",
    "label": "Malden - MO 63863",
    "county": "Dunklin County",
    "state": "MO",
    "city": "Malden"
  },
  {
    "value": "63867",
    "label": "Matthews - MO 63867",
    "county": "New Madrid County",
    "state": "MO",
    "city": "Matthews"
  },
  {
    "value": "63869",
    "label": "New Madrid - MO 63869",
    "county": "New Madrid County",
    "state": "MO",
    "city": "New Madrid"
  },
  {
    "value": "63870",
    "label": "Parma - MO 63870",
    "county": "New Madrid County",
    "state": "MO",
    "city": "Parma"
  },
  {
    "value": "63873",
    "label": "Portageville - MO 63873",
    "county": "New Madrid County",
    "state": "MO",
    "city": "Portageville"
  },
  {
    "value": "63876",
    "label": "Senath - MO 63876",
    "county": "Dunklin County",
    "state": "MO",
    "city": "Senath"
  },
  {
    "value": "63877",
    "label": "Steele - MO 63877",
    "county": "Pemiscot County",
    "state": "MO",
    "city": "Steele"
  },
  {
    "value": "63879",
    "label": "Wardell - MO 63879",
    "county": "Pemiscot County",
    "state": "MO",
    "city": "Wardell"
  },
  {
    "value": "63901",
    "label": "Poplar Bluff - MO 63901",
    "county": "Butler County",
    "state": "MO",
    "city": "Poplar Bluff"
  },
  {
    "value": "63931",
    "label": "Briar - MO 63931",
    "county": "Ripley County",
    "state": "MO",
    "city": "Briar"
  },
  {
    "value": "63932",
    "label": "Broseley - MO 63932",
    "county": "Butler County",
    "state": "MO",
    "city": "Broseley"
  },
  {
    "value": "63933",
    "label": "Campbell - MO 63933",
    "county": "Dunklin County",
    "state": "MO",
    "city": "Campbell"
  },
  {
    "value": "63934",
    "label": "Clubb - MO 63934",
    "county": "Wayne County",
    "state": "MO",
    "city": "Clubb"
  },
  {
    "value": "63935",
    "label": "Doniphan - MO 63935",
    "county": "Ripley County",
    "state": "MO",
    "city": "Doniphan"
  },
  {
    "value": "63936",
    "label": "Dudley - MO 63936",
    "county": "Stoddard County",
    "state": "MO",
    "city": "Dudley"
  },
  {
    "value": "63937",
    "label": "Ellsinore - MO 63937",
    "county": "Carter County",
    "state": "MO",
    "city": "Ellsinore"
  },
  {
    "value": "63939",
    "label": "Fairdealing - MO 63939",
    "county": "Ripley County",
    "state": "MO",
    "city": "Fairdealing"
  },
  {
    "value": "63940",
    "label": "Fisk - MO 63940",
    "county": "Butler County",
    "state": "MO",
    "city": "Fisk"
  },
  {
    "value": "63941",
    "label": "Fremont - MO 63941",
    "county": "Carter County",
    "state": "MO",
    "city": "Fremont"
  },
  {
    "value": "63942",
    "label": "Gatewood - MO 63942",
    "county": "Ripley County",
    "state": "MO",
    "city": "Gatewood"
  },
  {
    "value": "63943",
    "label": "Grandin - MO 63943",
    "county": "Carter County",
    "state": "MO",
    "city": "Grandin"
  },
  {
    "value": "63944",
    "label": "Greenville - MO 63944",
    "county": "Wayne County",
    "state": "MO",
    "city": "Greenville"
  },
  {
    "value": "63945",
    "label": "Harviell - MO 63945",
    "county": "Butler County",
    "state": "MO",
    "city": "Harviell"
  },
  {
    "value": "63950",
    "label": "Lodi - MO 63950",
    "county": "Wayne County",
    "state": "MO",
    "city": "Lodi"
  },
  {
    "value": "63951",
    "label": "Lowndes - MO 63951",
    "county": "Wayne County",
    "state": "MO",
    "city": "Lowndes"
  },
  {
    "value": "63952",
    "label": "Mill Spring - MO 63952",
    "county": "Wayne County",
    "state": "MO",
    "city": "Mill Spring"
  },
  {
    "value": "63953",
    "label": "Naylor - MO 63953",
    "county": "Ripley County",
    "state": "MO",
    "city": "Naylor"
  },
  {
    "value": "63954",
    "label": "Neelyville - MO 63954",
    "county": "Butler County",
    "state": "MO",
    "city": "Neelyville"
  },
  {
    "value": "63955",
    "label": "Oxly - MO 63955",
    "county": "Ripley County",
    "state": "MO",
    "city": "Oxly"
  },
  {
    "value": "63956",
    "label": "Patterson - MO 63956",
    "county": "Wayne County",
    "state": "MO",
    "city": "Patterson"
  },
  {
    "value": "63957",
    "label": "Piedmont - MO 63957",
    "county": "Wayne County",
    "state": "MO",
    "city": "Piedmont"
  },
  {
    "value": "63960",
    "label": "Puxico - MO 63960",
    "county": "Stoddard County",
    "state": "MO",
    "city": "Puxico"
  },
  {
    "value": "63961",
    "label": "Qulin - MO 63961",
    "county": "Butler County",
    "state": "MO",
    "city": "Qulin"
  },
  {
    "value": "63963",
    "label": "Shook - MO 63963",
    "county": "Wayne County",
    "state": "MO",
    "city": "Shook"
  },
  {
    "value": "63964",
    "label": "Silva - MO 63964",
    "county": "Wayne County",
    "state": "MO",
    "city": "Silva"
  },
  {
    "value": "63965",
    "label": "Van Buren - MO 63965",
    "county": "Carter County",
    "state": "MO",
    "city": "Van Buren"
  },
  {
    "value": "63966",
    "label": "Wappapello - MO 63966",
    "county": "Wayne County",
    "state": "MO",
    "city": "Wappapello"
  },
  {
    "value": "63967",
    "label": "Williamsville - MO 63967",
    "county": "Wayne County",
    "state": "MO",
    "city": "Williamsville"
  },
  {
    "value": "64001",
    "label": "Alma - MO 64001",
    "county": "Lafayette County",
    "state": "MO",
    "city": "Alma"
  },
  {
    "value": "64011",
    "label": "Bates City - MO 64011",
    "county": "Lafayette County",
    "state": "MO",
    "city": "Bates City"
  },
  {
    "value": "64012",
    "label": "Belton - MO 64012",
    "county": "Cass County",
    "state": "MO",
    "city": "Belton"
  },
  {
    "value": "64014",
    "label": "Blue Springs - MO 64014",
    "county": "Jackson County",
    "state": "MO",
    "city": "Blue Springs"
  },
  {
    "value": "64015",
    "label": "Blue Springs - MO 64015",
    "county": "Jackson County",
    "state": "MO",
    "city": "Blue Springs"
  },
  {
    "value": "64016",
    "label": "Buckner - MO 64016",
    "county": "Jackson County",
    "state": "MO",
    "city": "Buckner"
  },
  {
    "value": "64017",
    "label": "Camden - MO 64017",
    "county": "Ray County",
    "state": "MO",
    "city": "Camden"
  },
  {
    "value": "64018",
    "label": "Camden Point - MO 64018",
    "county": "Platte County",
    "state": "MO",
    "city": "Camden Point"
  },
  {
    "value": "64019",
    "label": "Centerview - MO 64019",
    "county": "Johnson County",
    "state": "MO",
    "city": "Centerview"
  },
  {
    "value": "64020",
    "label": "Concordia - MO 64020",
    "county": "Lafayette County",
    "state": "MO",
    "city": "Concordia"
  },
  {
    "value": "64021",
    "label": "Corder - MO 64021",
    "county": "Lafayette County",
    "state": "MO",
    "city": "Corder"
  },
  {
    "value": "64022",
    "label": "Dover - MO 64022",
    "county": "Lafayette County",
    "state": "MO",
    "city": "Dover"
  },
  {
    "value": "64024",
    "label": "Excelsior Springs - MO 64024",
    "county": "Clay County",
    "state": "MO",
    "city": "Excelsior Springs"
  },
  {
    "value": "64029",
    "label": "Grain Valley - MO 64029",
    "county": "Jackson County",
    "state": "MO",
    "city": "Grain Valley"
  },
  {
    "value": "64030",
    "label": "Grandview - MO 64030",
    "county": "Jackson County",
    "state": "MO",
    "city": "Grandview"
  },
  {
    "value": "64034",
    "label": "Greenwood - MO 64034",
    "county": "Jackson County",
    "state": "MO",
    "city": "Greenwood"
  },
  {
    "value": "64035",
    "label": "Hardin - MO 64035",
    "county": "Ray County",
    "state": "MO",
    "city": "Hardin"
  },
  {
    "value": "64036",
    "label": "Henrietta - MO 64036",
    "county": "Ray County",
    "state": "MO",
    "city": "Henrietta"
  },
  {
    "value": "64037",
    "label": "Higginsville - MO 64037",
    "county": "Lafayette County",
    "state": "MO",
    "city": "Higginsville"
  },
  {
    "value": "64040",
    "label": "Holden - MO 64040",
    "county": "Johnson County",
    "state": "MO",
    "city": "Holden"
  },
  {
    "value": "64048",
    "label": "Holt - MO 64048",
    "county": "Clay County",
    "state": "MO",
    "city": "Holt"
  },
  {
    "value": "64050",
    "label": "Independence - MO 64050",
    "county": "Jackson County",
    "state": "MO",
    "city": "Independence"
  },
  {
    "value": "64052",
    "label": "Independence - MO 64052",
    "county": "Jackson County",
    "state": "MO",
    "city": "Independence"
  },
  {
    "value": "64053",
    "label": "Independence - MO 64053",
    "county": "Jackson County",
    "state": "MO",
    "city": "Independence"
  },
  {
    "value": "64054",
    "label": "Independence - MO 64054",
    "county": "Jackson County",
    "state": "MO",
    "city": "Independence"
  },
  {
    "value": "64055",
    "label": "Independence - MO 64055",
    "county": "Jackson County",
    "state": "MO",
    "city": "Independence"
  },
  {
    "value": "64056",
    "label": "Independence - MO 64056",
    "county": "Jackson County",
    "state": "MO",
    "city": "Independence"
  },
  {
    "value": "64057",
    "label": "Independence - MO 64057",
    "county": "Jackson County",
    "state": "MO",
    "city": "Independence"
  },
  {
    "value": "64058",
    "label": "Independence - MO 64058",
    "county": "Jackson County",
    "state": "MO",
    "city": "Independence"
  },
  {
    "value": "64060",
    "label": "Kearney - MO 64060",
    "county": "Clay County",
    "state": "MO",
    "city": "Kearney"
  },
  {
    "value": "64061",
    "label": "Kingsville - MO 64061",
    "county": "Johnson County",
    "state": "MO",
    "city": "Kingsville"
  },
  {
    "value": "64062",
    "label": "Lawson - MO 64062",
    "county": "Ray County",
    "state": "MO",
    "city": "Lawson"
  },
  {
    "value": "64063",
    "label": "Lees Summit - MO 64063",
    "county": "Jackson County",
    "state": "MO",
    "city": "Lees Summit"
  },
  {
    "value": "64064",
    "label": "Lees Summit - MO 64064",
    "county": "Jackson County",
    "state": "MO",
    "city": "Lees Summit"
  },
  {
    "value": "64067",
    "label": "Lexington - MO 64067",
    "county": "Lafayette County",
    "state": "MO",
    "city": "Lexington"
  },
  {
    "value": "64068",
    "label": "Liberty - MO 64068",
    "county": "Clay County",
    "state": "MO",
    "city": "Liberty"
  },
  {
    "value": "64070",
    "label": "Lone Jack - MO 64070",
    "county": "Jackson County",
    "state": "MO",
    "city": "Lone Jack"
  },
  {
    "value": "64071",
    "label": "Mayview - MO 64071",
    "county": "Lafayette County",
    "state": "MO",
    "city": "Mayview"
  },
  {
    "value": "64074",
    "label": "Napoleon - MO 64074",
    "county": "Lafayette County",
    "state": "MO",
    "city": "Napoleon"
  },
  {
    "value": "64075",
    "label": "Oak Grove - MO 64075",
    "county": "Jackson County",
    "state": "MO",
    "city": "Oak Grove"
  },
  {
    "value": "64076",
    "label": "Odessa - MO 64076",
    "county": "Lafayette County",
    "state": "MO",
    "city": "Odessa"
  },
  {
    "value": "64077",
    "label": "Orrick - MO 64077",
    "county": "Ray County",
    "state": "MO",
    "city": "Orrick"
  },
  {
    "value": "64078",
    "label": "Peculiar - MO 64078",
    "county": "Cass County",
    "state": "MO",
    "city": "Peculiar"
  },
  {
    "value": "64079",
    "label": "Platte City - MO 64079",
    "county": "Platte County",
    "state": "MO",
    "city": "Platte City"
  },
  {
    "value": "64080",
    "label": "Pleasant Hill - MO 64080",
    "county": "Cass County",
    "state": "MO",
    "city": "Pleasant Hill"
  },
  {
    "value": "64081",
    "label": "Lees Summit - MO 64081",
    "county": "Jackson County",
    "state": "MO",
    "city": "Lees Summit"
  },
  {
    "value": "64082",
    "label": "Lees Summit - MO 64082",
    "county": "Jackson County",
    "state": "MO",
    "city": "Lees Summit"
  },
  {
    "value": "64083",
    "label": "Raymore - MO 64083",
    "county": "Cass County",
    "state": "MO",
    "city": "Raymore"
  },
  {
    "value": "64084",
    "label": "Rayville - MO 64084",
    "county": "Ray County",
    "state": "MO",
    "city": "Rayville"
  },
  {
    "value": "64085",
    "label": "Richmond - MO 64085",
    "county": "Ray County",
    "state": "MO",
    "city": "Richmond"
  },
  {
    "value": "64086",
    "label": "Lees Summit - MO 64086",
    "county": "Jackson County",
    "state": "MO",
    "city": "Lees Summit"
  },
  {
    "value": "64088",
    "label": "Sibley - MO 64088",
    "county": "Jackson County",
    "state": "MO",
    "city": "Sibley"
  },
  {
    "value": "64089",
    "label": "Smithville - MO 64089",
    "county": "Clay County",
    "state": "MO",
    "city": "Smithville"
  },
  {
    "value": "64093",
    "label": "Warrensburg - MO 64093",
    "county": "Johnson County",
    "state": "MO",
    "city": "Warrensburg"
  },
  {
    "value": "64096",
    "label": "Waverly - MO 64096",
    "county": "Lafayette County",
    "state": "MO",
    "city": "Waverly"
  },
  {
    "value": "64097",
    "label": "Wellington - MO 64097",
    "county": "Lafayette County",
    "state": "MO",
    "city": "Wellington"
  },
  {
    "value": "64098",
    "label": "Weston - MO 64098",
    "county": "Platte County",
    "state": "MO",
    "city": "Weston"
  },
  {
    "value": "64101",
    "label": "Kansas City - MO 64101",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64102",
    "label": "Kansas City - MO 64102",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64105",
    "label": "Kansas City - MO 64105",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64106",
    "label": "Kansas City - MO 64106",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64108",
    "label": "Kansas City - MO 64108",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64109",
    "label": "Kansas City - MO 64109",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64110",
    "label": "Kansas City - MO 64110",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64111",
    "label": "Kansas City - MO 64111",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64112",
    "label": "Kansas City - MO 64112",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64113",
    "label": "Kansas City - MO 64113",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64114",
    "label": "Kansas City - MO 64114",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64116",
    "label": "Kansas City - MO 64116",
    "county": "Clay County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64117",
    "label": "Kansas City - MO 64117",
    "county": "Clay County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64118",
    "label": "Kansas City - MO 64118",
    "county": "Clay County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64119",
    "label": "Kansas City - MO 64119",
    "county": "Clay County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64120",
    "label": "Kansas City - MO 64120",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64123",
    "label": "Kansas City - MO 64123",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64124",
    "label": "Kansas City - MO 64124",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64125",
    "label": "Kansas City - MO 64125",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64126",
    "label": "Kansas City - MO 64126",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64127",
    "label": "Kansas City - MO 64127",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64128",
    "label": "Kansas City - MO 64128",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64129",
    "label": "Kansas City - MO 64129",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64130",
    "label": "Kansas City - MO 64130",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64131",
    "label": "Kansas City - MO 64131",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64132",
    "label": "Kansas City - MO 64132",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64133",
    "label": "Kansas City - MO 64133",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64134",
    "label": "Kansas City - MO 64134",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64136",
    "label": "Kansas City - MO 64136",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64137",
    "label": "Kansas City - MO 64137",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64138",
    "label": "Kansas City - MO 64138",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64139",
    "label": "Kansas City - MO 64139",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64145",
    "label": "Kansas City - MO 64145",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64146",
    "label": "Kansas City - MO 64146",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64147",
    "label": "Kansas City - MO 64147",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64149",
    "label": "Kansas City - MO 64149",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64150",
    "label": "Riverside - MO 64150",
    "county": "Platte County",
    "state": "MO",
    "city": "Riverside"
  },
  {
    "value": "64151",
    "label": "Kansas City - MO 64151",
    "county": "Platte County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64152",
    "label": "Kansas City - MO 64152",
    "county": "Platte County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64153",
    "label": "Kansas City - MO 64153",
    "county": "Platte County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64154",
    "label": "Kansas City - MO 64154",
    "county": "Platte County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64155",
    "label": "Kansas City - MO 64155",
    "county": "Clay County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64156",
    "label": "Kansas City - MO 64156",
    "county": "Clay County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64157",
    "label": "Kansas City - MO 64157",
    "county": "Clay County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64158",
    "label": "Kansas City - MO 64158",
    "county": "Clay County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64161",
    "label": "Kansas City - MO 64161",
    "county": "Clay County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64163",
    "label": "Kansas City - MO 64163",
    "county": "Platte County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64164",
    "label": "Kansas City - MO 64164",
    "county": "Platte County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64165",
    "label": "Kansas City - MO 64165",
    "county": "Clay County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64166",
    "label": "Kansas City - MO 64166",
    "county": "Clay County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64167",
    "label": "Kansas City - MO 64167",
    "county": "Clay County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64172",
    "label": "Kansas City - MO 64172",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64192",
    "label": "Kansas City - MO 64192",
    "county": "Jackson County",
    "state": "MO",
    "city": "Kansas City"
  },
  {
    "value": "64401",
    "label": "Agency - MO 64401",
    "county": "Buchanan County",
    "state": "MO",
    "city": "Agency"
  },
  {
    "value": "64402",
    "label": "Albany - MO 64402",
    "county": "Gentry County",
    "state": "MO",
    "city": "Albany"
  },
  {
    "value": "64421",
    "label": "Amazonia - MO 64421",
    "county": "Andrew County",
    "state": "MO",
    "city": "Amazonia"
  },
  {
    "value": "64422",
    "label": "Amity - MO 64422",
    "county": "DeKalb County",
    "state": "MO",
    "city": "Amity"
  },
  {
    "value": "64423",
    "label": "Barnard - MO 64423",
    "county": "Nodaway County",
    "state": "MO",
    "city": "Barnard"
  },
  {
    "value": "64424",
    "label": "Bethany - MO 64424",
    "county": "Harrison County",
    "state": "MO",
    "city": "Bethany"
  },
  {
    "value": "64426",
    "label": "Blythedale - MO 64426",
    "county": "Harrison County",
    "state": "MO",
    "city": "Blythedale"
  },
  {
    "value": "64427",
    "label": "Bolckow - MO 64427",
    "county": "Andrew County",
    "state": "MO",
    "city": "Bolckow"
  },
  {
    "value": "64428",
    "label": "Burlington Junction - MO 64428",
    "county": "Nodaway County",
    "state": "MO",
    "city": "Burlington Junction"
  },
  {
    "value": "64429",
    "label": "Cameron - MO 64429",
    "county": "Clinton County",
    "state": "MO",
    "city": "Cameron"
  },
  {
    "value": "64430",
    "label": "Clarksdale - MO 64430",
    "county": "DeKalb County",
    "state": "MO",
    "city": "Clarksdale"
  },
  {
    "value": "64431",
    "label": "Clearmont - MO 64431",
    "county": "Nodaway County",
    "state": "MO",
    "city": "Clearmont"
  },
  {
    "value": "64432",
    "label": "Clyde - MO 64432",
    "county": "Nodaway County",
    "state": "MO",
    "city": "Clyde"
  },
  {
    "value": "64433",
    "label": "Conception - MO 64433",
    "county": "Nodaway County",
    "state": "MO",
    "city": "Conception"
  },
  {
    "value": "64434",
    "label": "Conception Junction - MO 64434",
    "county": "Nodaway County",
    "state": "MO",
    "city": "Conception Junction"
  },
  {
    "value": "64436",
    "label": "Cosby - MO 64436",
    "county": "Andrew County",
    "state": "MO",
    "city": "Cosby"
  },
  {
    "value": "64437",
    "label": "Craig - MO 64437",
    "county": "Holt County",
    "state": "MO",
    "city": "Craig"
  },
  {
    "value": "64438",
    "label": "Darlington - MO 64438",
    "county": "Gentry County",
    "state": "MO",
    "city": "Darlington"
  },
  {
    "value": "64439",
    "label": "Dearborn - MO 64439",
    "county": "Platte County",
    "state": "MO",
    "city": "Dearborn"
  },
  {
    "value": "64440",
    "label": "De Kalb - MO 64440",
    "county": "Buchanan County",
    "state": "MO",
    "city": "De Kalb"
  },
  {
    "value": "64441",
    "label": "Denver - MO 64441",
    "county": "Worth County",
    "state": "MO",
    "city": "Denver"
  },
  {
    "value": "64442",
    "label": "Eagleville - MO 64442",
    "county": "Harrison County",
    "state": "MO",
    "city": "Eagleville"
  },
  {
    "value": "64443",
    "label": "Easton - MO 64443",
    "county": "Buchanan County",
    "state": "MO",
    "city": "Easton"
  },
  {
    "value": "64444",
    "label": "Edgerton - MO 64444",
    "county": "Platte County",
    "state": "MO",
    "city": "Edgerton"
  },
  {
    "value": "64445",
    "label": "Elmo - MO 64445",
    "county": "Nodaway County",
    "state": "MO",
    "city": "Elmo"
  },
  {
    "value": "64446",
    "label": "Fairfax - MO 64446",
    "county": "Atchison County",
    "state": "MO",
    "city": "Fairfax"
  },
  {
    "value": "64447",
    "label": "Maysville - MO 64447",
    "county": "Dekalb County",
    "state": "MO",
    "city": "Maysville"
  },
  {
    "value": "64448",
    "label": "Faucett - MO 64448",
    "county": "Buchanan County",
    "state": "MO",
    "city": "Faucett"
  },
  {
    "value": "64449",
    "label": "Fillmore - MO 64449",
    "county": "Andrew County",
    "state": "MO",
    "city": "Fillmore"
  },
  {
    "value": "64451",
    "label": "Forest City - MO 64451",
    "county": "Holt County",
    "state": "MO",
    "city": "Forest City"
  },
  {
    "value": "64453",
    "label": "Gentry - MO 64453",
    "county": "Gentry County",
    "state": "MO",
    "city": "Gentry"
  },
  {
    "value": "64454",
    "label": "Gower - MO 64454",
    "county": "Clinton County",
    "state": "MO",
    "city": "Gower"
  },
  {
    "value": "64455",
    "label": "Graham - MO 64455",
    "county": "Nodaway County",
    "state": "MO",
    "city": "Graham"
  },
  {
    "value": "64456",
    "label": "Grant City - MO 64456",
    "county": "Worth County",
    "state": "MO",
    "city": "Grant City"
  },
  {
    "value": "64457",
    "label": "Guilford - MO 64457",
    "county": "Nodaway County",
    "state": "MO",
    "city": "Guilford"
  },
  {
    "value": "64458",
    "label": "Hatfield - MO 64458",
    "county": "Harrison County",
    "state": "MO",
    "city": "Hatfield"
  },
  {
    "value": "64459",
    "label": "Helena - MO 64459",
    "county": "Andrew County",
    "state": "MO",
    "city": "Helena"
  },
  {
    "value": "64461",
    "label": "Hopkins - MO 64461",
    "county": "Nodaway County",
    "state": "MO",
    "city": "Hopkins"
  },
  {
    "value": "64463",
    "label": "King City - MO 64463",
    "county": "Gentry County",
    "state": "MO",
    "city": "King City"
  },
  {
    "value": "64465",
    "label": "Lathrop - MO 64465",
    "county": "Clinton County",
    "state": "MO",
    "city": "Lathrop"
  },
  {
    "value": "64466",
    "label": "Maitland - MO 64466",
    "county": "Holt County",
    "state": "MO",
    "city": "Maitland"
  },
  {
    "value": "64467",
    "label": "Martinsville - MO 64467",
    "county": "Harrison County",
    "state": "MO",
    "city": "Martinsville"
  },
  {
    "value": "64468",
    "label": "Maryville - MO 64468",
    "county": "Nodaway County",
    "state": "MO",
    "city": "Maryville"
  },
  {
    "value": "64469",
    "label": "Maysville - MO 64469",
    "county": "DeKalb County",
    "state": "MO",
    "city": "Maysville"
  },
  {
    "value": "64470",
    "label": "Mound City - MO 64470",
    "county": "Holt County",
    "state": "MO",
    "city": "Mound City"
  },
  {
    "value": "64471",
    "label": "New Hampton - MO 64471",
    "county": "Harrison County",
    "state": "MO",
    "city": "New Hampton"
  },
  {
    "value": "64473",
    "label": "Oregon - MO 64473",
    "county": "Holt County",
    "state": "MO",
    "city": "Oregon"
  },
  {
    "value": "64474",
    "label": "Osborn - MO 64474",
    "county": "DeKalb County",
    "state": "MO",
    "city": "Osborn"
  },
  {
    "value": "64475",
    "label": "Parnell - MO 64475",
    "county": "Nodaway County",
    "state": "MO",
    "city": "Parnell"
  },
  {
    "value": "64476",
    "label": "Pickering - MO 64476",
    "county": "Nodaway County",
    "state": "MO",
    "city": "Pickering"
  },
  {
    "value": "64477",
    "label": "Plattsburg - MO 64477",
    "county": "Clinton County",
    "state": "MO",
    "city": "Plattsburg"
  },
  {
    "value": "64479",
    "label": "Ravenwood - MO 64479",
    "county": "Nodaway County",
    "state": "MO",
    "city": "Ravenwood"
  },
  {
    "value": "64480",
    "label": "Rea - MO 64480",
    "county": "Andrew County",
    "state": "MO",
    "city": "Rea"
  },
  {
    "value": "64481",
    "label": "Ridgeway - MO 64481",
    "county": "Harrison County",
    "state": "MO",
    "city": "Ridgeway"
  },
  {
    "value": "64482",
    "label": "Rock Port - MO 64482",
    "county": "Atchison County",
    "state": "MO",
    "city": "Rock Port"
  },
  {
    "value": "64483",
    "label": "Rosendale - MO 64483",
    "county": "Andrew County",
    "state": "MO",
    "city": "Rosendale"
  },
  {
    "value": "64484",
    "label": "Rushville - MO 64484",
    "county": "Buchanan County",
    "state": "MO",
    "city": "Rushville"
  },
  {
    "value": "64485",
    "label": "Savannah - MO 64485",
    "county": "Andrew County",
    "state": "MO",
    "city": "Savannah"
  },
  {
    "value": "64486",
    "label": "Sheridan - MO 64486",
    "county": "Worth County",
    "state": "MO",
    "city": "Sheridan"
  },
  {
    "value": "64487",
    "label": "Skidmore - MO 64487",
    "county": "Nodaway County",
    "state": "MO",
    "city": "Skidmore"
  },
  {
    "value": "64489",
    "label": "Stanberry - MO 64489",
    "county": "Gentry County",
    "state": "MO",
    "city": "Stanberry"
  },
  {
    "value": "64490",
    "label": "Stewartsville - MO 64490",
    "county": "DeKalb County",
    "state": "MO",
    "city": "Stewartsville"
  },
  {
    "value": "64491",
    "label": "Tarkio - MO 64491",
    "county": "Atchison County",
    "state": "MO",
    "city": "Tarkio"
  },
  {
    "value": "64492",
    "label": "Trimble - MO 64492",
    "county": "Clinton County",
    "state": "MO",
    "city": "Trimble"
  },
  {
    "value": "64493",
    "label": "Turney - MO 64493",
    "county": "Clinton County",
    "state": "MO",
    "city": "Turney"
  },
  {
    "value": "64494",
    "label": "Union Star - MO 64494",
    "county": "DeKalb County",
    "state": "MO",
    "city": "Union Star"
  },
  {
    "value": "64496",
    "label": "Watson - MO 64496",
    "county": "Atchison County",
    "state": "MO",
    "city": "Watson"
  },
  {
    "value": "64497",
    "label": "Weatherby - MO 64497",
    "county": "DeKalb County",
    "state": "MO",
    "city": "Weatherby"
  },
  {
    "value": "64498",
    "label": "Westboro - MO 64498",
    "county": "Atchison County",
    "state": "MO",
    "city": "Westboro"
  },
  {
    "value": "64499",
    "label": "Worth - MO 64499",
    "county": "Worth County",
    "state": "MO",
    "city": "Worth"
  },
  {
    "value": "64501",
    "label": "Saint Joseph - MO 64501",
    "county": "Buchanan County",
    "state": "MO",
    "city": "Saint Joseph"
  },
  {
    "value": "64503",
    "label": "Saint Joseph - MO 64503",
    "county": "Buchanan County",
    "state": "MO",
    "city": "Saint Joseph"
  },
  {
    "value": "64504",
    "label": "Saint Joseph - MO 64504",
    "county": "Buchanan County",
    "state": "MO",
    "city": "Saint Joseph"
  },
  {
    "value": "64505",
    "label": "Saint Joseph - MO 64505",
    "county": "Buchanan County",
    "state": "MO",
    "city": "Saint Joseph"
  },
  {
    "value": "64506",
    "label": "Saint Joseph - MO 64506",
    "county": "Buchanan County",
    "state": "MO",
    "city": "Saint Joseph"
  },
  {
    "value": "64507",
    "label": "Saint Joseph - MO 64507",
    "county": "Buchanan County",
    "state": "MO",
    "city": "Saint Joseph"
  },
  {
    "value": "64601",
    "label": "Chillicothe - MO 64601",
    "county": "Livingston County",
    "state": "MO",
    "city": "Chillicothe"
  },
  {
    "value": "64620",
    "label": "Altamont - MO 64620",
    "county": "Daviess County",
    "state": "MO",
    "city": "Altamont"
  },
  {
    "value": "64622",
    "label": "Bogard - MO 64622",
    "county": "Carroll County",
    "state": "MO",
    "city": "Bogard"
  },
  {
    "value": "64623",
    "label": "Bosworth - MO 64623",
    "county": "Carroll County",
    "state": "MO",
    "city": "Bosworth"
  },
  {
    "value": "64624",
    "label": "Braymer - MO 64624",
    "county": "Caldwell County",
    "state": "MO",
    "city": "Braymer"
  },
  {
    "value": "64625",
    "label": "Breckenridge - MO 64625",
    "county": "Caldwell County",
    "state": "MO",
    "city": "Breckenridge"
  },
  {
    "value": "64628",
    "label": "Brookfield - MO 64628",
    "county": "Linn County",
    "state": "MO",
    "city": "Brookfield"
  },
  {
    "value": "64630",
    "label": "Browning - MO 64630",
    "county": "Linn County",
    "state": "MO",
    "city": "Browning"
  },
  {
    "value": "64631",
    "label": "Bucklin - MO 64631",
    "county": "Linn County",
    "state": "MO",
    "city": "Bucklin"
  },
  {
    "value": "64632",
    "label": "Cainsville - MO 64632",
    "county": "Harrison County",
    "state": "MO",
    "city": "Cainsville"
  },
  {
    "value": "64633",
    "label": "Carrollton - MO 64633",
    "county": "Carroll County",
    "state": "MO",
    "city": "Carrollton"
  },
  {
    "value": "64635",
    "label": "Chula - MO 64635",
    "county": "Livingston County",
    "state": "MO",
    "city": "Chula"
  },
  {
    "value": "64636",
    "label": "Coffey - MO 64636",
    "county": "Daviess County",
    "state": "MO",
    "city": "Coffey"
  },
  {
    "value": "64637",
    "label": "Cowgill - MO 64637",
    "county": "Caldwell County",
    "state": "MO",
    "city": "Cowgill"
  },
  {
    "value": "64638",
    "label": "Dawn - MO 64638",
    "county": "Livingston County",
    "state": "MO",
    "city": "Dawn"
  },
  {
    "value": "64639",
    "label": "De Witt - MO 64639",
    "county": "Carroll County",
    "state": "MO",
    "city": "De Witt"
  },
  {
    "value": "64640",
    "label": "Gallatin - MO 64640",
    "county": "Daviess County",
    "state": "MO",
    "city": "Gallatin"
  },
  {
    "value": "64641",
    "label": "Galt - MO 64641",
    "county": "Grundy County",
    "state": "MO",
    "city": "Galt"
  },
  {
    "value": "64642",
    "label": "Gilman City - MO 64642",
    "county": "Harrison County",
    "state": "MO",
    "city": "Gilman City"
  },
  {
    "value": "64643",
    "label": "Hale - MO 64643",
    "county": "Carroll County",
    "state": "MO",
    "city": "Hale"
  },
  {
    "value": "64644",
    "label": "Hamilton - MO 64644",
    "county": "Caldwell County",
    "state": "MO",
    "city": "Hamilton"
  },
  {
    "value": "64645",
    "label": "Harris - MO 64645",
    "county": "Sullivan County",
    "state": "MO",
    "city": "Harris"
  },
  {
    "value": "64646",
    "label": "Humphreys - MO 64646",
    "county": "Sullivan County",
    "state": "MO",
    "city": "Humphreys"
  },
  {
    "value": "64647",
    "label": "Jameson - MO 64647",
    "county": "Daviess County",
    "state": "MO",
    "city": "Jameson"
  },
  {
    "value": "64648",
    "label": "Jamesport - MO 64648",
    "county": "Daviess County",
    "state": "MO",
    "city": "Jamesport"
  },
  {
    "value": "64649",
    "label": "Kidder - MO 64649",
    "county": "Caldwell County",
    "state": "MO",
    "city": "Kidder"
  },
  {
    "value": "64650",
    "label": "Kingston - MO 64650",
    "county": "Caldwell County",
    "state": "MO",
    "city": "Kingston"
  },
  {
    "value": "64651",
    "label": "Laclede - MO 64651",
    "county": "Linn County",
    "state": "MO",
    "city": "Laclede"
  },
  {
    "value": "64652",
    "label": "Laredo - MO 64652",
    "county": "Grundy County",
    "state": "MO",
    "city": "Laredo"
  },
  {
    "value": "64653",
    "label": "Linneus - MO 64653",
    "county": "Linn County",
    "state": "MO",
    "city": "Linneus"
  },
  {
    "value": "64655",
    "label": "Lucerne - MO 64655",
    "county": "Putnam County",
    "state": "MO",
    "city": "Lucerne"
  },
  {
    "value": "64656",
    "label": "Ludlow - MO 64656",
    "county": "Livingston County",
    "state": "MO",
    "city": "Ludlow"
  },
  {
    "value": "64657",
    "label": "Mc Fall - MO 64657",
    "county": "Gentry County",
    "state": "MO",
    "city": "Mc Fall"
  },
  {
    "value": "64658",
    "label": "Marceline - MO 64658",
    "county": "Linn County",
    "state": "MO",
    "city": "Marceline"
  },
  {
    "value": "64659",
    "label": "Meadville - MO 64659",
    "county": "Linn County",
    "state": "MO",
    "city": "Meadville"
  },
  {
    "value": "64660",
    "label": "Mendon - MO 64660",
    "county": "Chariton County",
    "state": "MO",
    "city": "Mendon"
  },
  {
    "value": "64661",
    "label": "Mercer - MO 64661",
    "county": "Mercer County",
    "state": "MO",
    "city": "Mercer"
  },
  {
    "value": "64664",
    "label": "Mooresville - MO 64664",
    "county": "Livingston County",
    "state": "MO",
    "city": "Mooresville"
  },
  {
    "value": "64667",
    "label": "Newtown - MO 64667",
    "county": "Sullivan County",
    "state": "MO",
    "city": "Newtown"
  },
  {
    "value": "64668",
    "label": "Norborne - MO 64668",
    "county": "Carroll County",
    "state": "MO",
    "city": "Norborne"
  },
  {
    "value": "64670",
    "label": "Pattonsburg - MO 64670",
    "county": "Daviess County",
    "state": "MO",
    "city": "Pattonsburg"
  },
  {
    "value": "64671",
    "label": "Polo - MO 64671",
    "county": "Caldwell County",
    "state": "MO",
    "city": "Polo"
  },
  {
    "value": "64672",
    "label": "Powersville - MO 64672",
    "county": "Putnam County",
    "state": "MO",
    "city": "Powersville"
  },
  {
    "value": "64673",
    "label": "Princeton - MO 64673",
    "county": "Mercer County",
    "state": "MO",
    "city": "Princeton"
  },
  {
    "value": "64674",
    "label": "Purdin - MO 64674",
    "county": "Linn County",
    "state": "MO",
    "city": "Purdin"
  },
  {
    "value": "64676",
    "label": "Rothville - MO 64676",
    "county": "Chariton County",
    "state": "MO",
    "city": "Rothville"
  },
  {
    "value": "64679",
    "label": "Spickard - MO 64679",
    "county": "Grundy County",
    "state": "MO",
    "city": "Spickard"
  },
  {
    "value": "64681",
    "label": "Sumner - MO 64681",
    "county": "Chariton County",
    "state": "MO",
    "city": "Sumner"
  },
  {
    "value": "64682",
    "label": "Tina - MO 64682",
    "county": "Carroll County",
    "state": "MO",
    "city": "Tina"
  },
  {
    "value": "64683",
    "label": "Trenton - MO 64683",
    "county": "Grundy County",
    "state": "MO",
    "city": "Trenton"
  },
  {
    "value": "64688",
    "label": "Wheeling - MO 64688",
    "county": "Livingston County",
    "state": "MO",
    "city": "Wheeling"
  },
  {
    "value": "64689",
    "label": "Winston - MO 64689",
    "county": "Daviess County",
    "state": "MO",
    "city": "Winston"
  },
  {
    "value": "64701",
    "label": "Harrisonville - MO 64701",
    "county": "Cass County",
    "state": "MO",
    "city": "Harrisonville"
  },
  {
    "value": "64720",
    "label": "Adrian - MO 64720",
    "county": "Bates County",
    "state": "MO",
    "city": "Adrian"
  },
  {
    "value": "64722",
    "label": "Amoret - MO 64722",
    "county": "Bates County",
    "state": "MO",
    "city": "Amoret"
  },
  {
    "value": "64723",
    "label": "Amsterdam - MO 64723",
    "county": "Bates County",
    "state": "MO",
    "city": "Amsterdam"
  },
  {
    "value": "64724",
    "label": "Appleton City - MO 64724",
    "county": "St. Clair County",
    "state": "MO",
    "city": "Appleton City"
  },
  {
    "value": "64725",
    "label": "Archie - MO 64725",
    "county": "Cass County",
    "state": "MO",
    "city": "Archie"
  },
  {
    "value": "64726",
    "label": "Blairstown - MO 64726",
    "county": "Henry County",
    "state": "MO",
    "city": "Blairstown"
  },
  {
    "value": "64728",
    "label": "Bronaugh - MO 64728",
    "county": "Vernon County",
    "state": "MO",
    "city": "Bronaugh"
  },
  {
    "value": "64730",
    "label": "Butler - MO 64730",
    "county": "Bates County",
    "state": "MO",
    "city": "Butler"
  },
  {
    "value": "64733",
    "label": "Chilhowee - MO 64733",
    "county": "Johnson County",
    "state": "MO",
    "city": "Chilhowee"
  },
  {
    "value": "64734",
    "label": "Cleveland - MO 64734",
    "county": "Cass County",
    "state": "MO",
    "city": "Cleveland"
  },
  {
    "value": "64735",
    "label": "Clinton - MO 64735",
    "county": "Henry County",
    "state": "MO",
    "city": "Clinton"
  },
  {
    "value": "64738",
    "label": "Collins - MO 64738",
    "county": "St. Clair County",
    "state": "MO",
    "city": "Collins"
  },
  {
    "value": "64739",
    "label": "Creighton - MO 64739",
    "county": "Cass County",
    "state": "MO",
    "city": "Creighton"
  },
  {
    "value": "64740",
    "label": "Deepwater - MO 64740",
    "county": "Henry County",
    "state": "MO",
    "city": "Deepwater"
  },
  {
    "value": "64741",
    "label": "Deerfield - MO 64741",
    "county": "Vernon County",
    "state": "MO",
    "city": "Deerfield"
  },
  {
    "value": "64742",
    "label": "Drexel - MO 64742",
    "county": "Cass County",
    "state": "MO",
    "city": "Drexel"
  },
  {
    "value": "64744",
    "label": "El Dorado Springs - MO 64744",
    "county": "Cedar County",
    "state": "MO",
    "city": "El Dorado Springs"
  },
  {
    "value": "64745",
    "label": "Foster - MO 64745",
    "county": "Bates County",
    "state": "MO",
    "city": "Foster"
  },
  {
    "value": "64746",
    "label": "Freeman - MO 64746",
    "county": "Cass County",
    "state": "MO",
    "city": "Freeman"
  },
  {
    "value": "64747",
    "label": "Garden City - MO 64747",
    "county": "Cass County",
    "state": "MO",
    "city": "Garden City"
  },
  {
    "value": "64748",
    "label": "Golden City - MO 64748",
    "county": "Barton County",
    "state": "MO",
    "city": "Golden City"
  },
  {
    "value": "64750",
    "label": "Harwood - MO 64750",
    "county": "Vernon County",
    "state": "MO",
    "city": "Harwood"
  },
  {
    "value": "64752",
    "label": "Hume - MO 64752",
    "county": "Bates County",
    "state": "MO",
    "city": "Hume"
  },
  {
    "value": "64755",
    "label": "Jasper - MO 64755",
    "county": "Jasper County",
    "state": "MO",
    "city": "Jasper"
  },
  {
    "value": "64756",
    "label": "Jerico Springs - MO 64756",
    "county": "Cedar County",
    "state": "MO",
    "city": "Jerico Springs"
  },
  {
    "value": "64759",
    "label": "Lamar - MO 64759",
    "county": "Barton County",
    "state": "MO",
    "city": "Lamar"
  },
  {
    "value": "64761",
    "label": "Leeton - MO 64761",
    "county": "Johnson County",
    "state": "MO",
    "city": "Leeton"
  },
  {
    "value": "64762",
    "label": "Liberal - MO 64762",
    "county": "Barton County",
    "state": "MO",
    "city": "Liberal"
  },
  {
    "value": "64763",
    "label": "Lowry City - MO 64763",
    "county": "St. Clair County",
    "state": "MO",
    "city": "Lowry City"
  },
  {
    "value": "64767",
    "label": "Milo - MO 64767",
    "county": "Vernon County",
    "state": "MO",
    "city": "Milo"
  },
  {
    "value": "64769",
    "label": "Mindenmines - MO 64769",
    "county": "Barton County",
    "state": "MO",
    "city": "Mindenmines"
  },
  {
    "value": "64770",
    "label": "Montrose - MO 64770",
    "county": "Henry County",
    "state": "MO",
    "city": "Montrose"
  },
  {
    "value": "64771",
    "label": "Moundville - MO 64771",
    "county": "Vernon County",
    "state": "MO",
    "city": "Moundville"
  },
  {
    "value": "64772",
    "label": "Nevada - MO 64772",
    "county": "Vernon County",
    "state": "MO",
    "city": "Nevada"
  },
  {
    "value": "64776",
    "label": "Osceola - MO 64776",
    "county": "St. Clair County",
    "state": "MO",
    "city": "Osceola"
  },
  {
    "value": "64778",
    "label": "Richards - MO 64778",
    "county": "Vernon County",
    "state": "MO",
    "city": "Richards"
  },
  {
    "value": "64779",
    "label": "Rich Hill - MO 64779",
    "county": "Bates County",
    "state": "MO",
    "city": "Rich Hill"
  },
  {
    "value": "64780",
    "label": "Rockville - MO 64780",
    "county": "Bates County",
    "state": "MO",
    "city": "Rockville"
  },
  {
    "value": "64783",
    "label": "Schell City - MO 64783",
    "county": "Vernon County",
    "state": "MO",
    "city": "Schell City"
  },
  {
    "value": "64784",
    "label": "Sheldon - MO 64784",
    "county": "Vernon County",
    "state": "MO",
    "city": "Sheldon"
  },
  {
    "value": "64788",
    "label": "Urich - MO 64788",
    "county": "Henry County",
    "state": "MO",
    "city": "Urich"
  },
  {
    "value": "64789",
    "label": "Osceola - MO 64789",
    "county": "St. Clair County",
    "state": "MO",
    "city": "Osceola"
  },
  {
    "value": "64790",
    "label": "Walker - MO 64790",
    "county": "Vernon County",
    "state": "MO",
    "city": "Walker"
  },
  {
    "value": "64801",
    "label": "Joplin - MO 64801",
    "county": "Jasper County",
    "state": "MO",
    "city": "Joplin"
  },
  {
    "value": "64804",
    "label": "Joplin - MO 64804",
    "county": "Jasper County",
    "state": "MO",
    "city": "Joplin"
  },
  {
    "value": "64831",
    "label": "Anderson - MO 64831",
    "county": "McDonald County",
    "state": "MO",
    "city": "Anderson"
  },
  {
    "value": "64832",
    "label": "Asbury - MO 64832",
    "county": "Jasper County",
    "state": "MO",
    "city": "Asbury"
  },
  {
    "value": "64834",
    "label": "Carl Junction - MO 64834",
    "county": "Jasper County",
    "state": "MO",
    "city": "Carl Junction"
  },
  {
    "value": "64835",
    "label": "Carterville - MO 64835",
    "county": "Jasper County",
    "state": "MO",
    "city": "Carterville"
  },
  {
    "value": "64836",
    "label": "Carthage - MO 64836",
    "county": "Jasper County",
    "state": "MO",
    "city": "Carthage"
  },
  {
    "value": "64840",
    "label": "Diamond - MO 64840",
    "county": "Newton County",
    "state": "MO",
    "city": "Diamond"
  },
  {
    "value": "64842",
    "label": "Fairview - MO 64842",
    "county": "Newton County",
    "state": "MO",
    "city": "Fairview"
  },
  {
    "value": "64843",
    "label": "Goodman - MO 64843",
    "county": "McDonald County",
    "state": "MO",
    "city": "Goodman"
  },
  {
    "value": "64844",
    "label": "Granby - MO 64844",
    "county": "Newton County",
    "state": "MO",
    "city": "Granby"
  },
  {
    "value": "64847",
    "label": "Lanagan - MO 64847",
    "county": "McDonald County",
    "state": "MO",
    "city": "Lanagan"
  },
  {
    "value": "64848",
    "label": "La Russell - MO 64848",
    "county": "Lawrence County",
    "state": "MO",
    "city": "La Russell"
  },
  {
    "value": "64850",
    "label": "Neosho - MO 64850",
    "county": "Newton County",
    "state": "MO",
    "city": "Neosho"
  },
  {
    "value": "64854",
    "label": "Noel - MO 64854",
    "county": "McDonald County",
    "state": "MO",
    "city": "Noel"
  },
  {
    "value": "64855",
    "label": "Oronogo - MO 64855",
    "county": "Jasper County",
    "state": "MO",
    "city": "Oronogo"
  },
  {
    "value": "64856",
    "label": "Pineville - MO 64856",
    "county": "McDonald County",
    "state": "MO",
    "city": "Pineville"
  },
  {
    "value": "64859",
    "label": "Reeds - MO 64859",
    "county": "Jasper County",
    "state": "MO",
    "city": "Reeds"
  },
  {
    "value": "64861",
    "label": "Rocky Comfort - MO 64861",
    "county": "McDonald County",
    "state": "MO",
    "city": "Rocky Comfort"
  },
  {
    "value": "64862",
    "label": "Sarcoxie - MO 64862",
    "county": "Jasper County",
    "state": "MO",
    "city": "Sarcoxie"
  },
  {
    "value": "64863",
    "label": "South West City - MO 64863",
    "county": "McDonald County",
    "state": "MO",
    "city": "South West City"
  },
  {
    "value": "64865",
    "label": "Seneca - MO 64865",
    "county": "Newton County",
    "state": "MO",
    "city": "Seneca"
  },
  {
    "value": "64866",
    "label": "Stark City - MO 64866",
    "county": "Newton County",
    "state": "MO",
    "city": "Stark City"
  },
  {
    "value": "64867",
    "label": "Stella - MO 64867",
    "county": "McDonald County",
    "state": "MO",
    "city": "Stella"
  },
  {
    "value": "64870",
    "label": "Webb City - MO 64870",
    "county": "Jasper County",
    "state": "MO",
    "city": "Webb City"
  },
  {
    "value": "64873",
    "label": "Wentworth - MO 64873",
    "county": "Lawrence County",
    "state": "MO",
    "city": "Wentworth"
  },
  {
    "value": "65001",
    "label": "Argyle - MO 65001",
    "county": "Osage County",
    "state": "MO",
    "city": "Argyle"
  },
  {
    "value": "65010",
    "label": "Ashland - MO 65010",
    "county": "Boone County",
    "state": "MO",
    "city": "Ashland"
  },
  {
    "value": "65011",
    "label": "Barnett - MO 65011",
    "county": "Morgan County",
    "state": "MO",
    "city": "Barnett"
  },
  {
    "value": "65013",
    "label": "Belle - MO 65013",
    "county": "Maries County",
    "state": "MO",
    "city": "Belle"
  },
  {
    "value": "65014",
    "label": "Bland - MO 65014",
    "county": "Gasconade County",
    "state": "MO",
    "city": "Bland"
  },
  {
    "value": "65016",
    "label": "Bonnots Mill - MO 65016",
    "county": "Osage County",
    "state": "MO",
    "city": "Bonnots Mill"
  },
  {
    "value": "65017",
    "label": "Brumley - MO 65017",
    "county": "Miller County",
    "state": "MO",
    "city": "Brumley"
  },
  {
    "value": "65018",
    "label": "California - MO 65018",
    "county": "Moniteau County",
    "state": "MO",
    "city": "California"
  },
  {
    "value": "65020",
    "label": "Camdenton - MO 65020",
    "county": "Camden County",
    "state": "MO",
    "city": "Camdenton"
  },
  {
    "value": "65023",
    "label": "Centertown - MO 65023",
    "county": "Cole County",
    "state": "MO",
    "city": "Centertown"
  },
  {
    "value": "65024",
    "label": "Chamois - MO 65024",
    "county": "Osage County",
    "state": "MO",
    "city": "Chamois"
  },
  {
    "value": "65025",
    "label": "Clarksburg - MO 65025",
    "county": "Moniteau County",
    "state": "MO",
    "city": "Clarksburg"
  },
  {
    "value": "65026",
    "label": "Eldon - MO 65026",
    "county": "Miller County",
    "state": "MO",
    "city": "Eldon"
  },
  {
    "value": "65032",
    "label": "Eugene - MO 65032",
    "county": "Miller County",
    "state": "MO",
    "city": "Eugene"
  },
  {
    "value": "65034",
    "label": "Fortuna - MO 65034",
    "county": "Morgan County",
    "state": "MO",
    "city": "Fortuna"
  },
  {
    "value": "65035",
    "label": "Freeburg - MO 65035",
    "county": "Osage County",
    "state": "MO",
    "city": "Freeburg"
  },
  {
    "value": "65037",
    "label": "Gravois Mills - MO 65037",
    "county": "Morgan County",
    "state": "MO",
    "city": "Gravois Mills"
  },
  {
    "value": "65039",
    "label": "Hartsburg - MO 65039",
    "county": "Boone County",
    "state": "MO",
    "city": "Hartsburg"
  },
  {
    "value": "65040",
    "label": "Henley - MO 65040",
    "county": "Cole County",
    "state": "MO",
    "city": "Henley"
  },
  {
    "value": "65041",
    "label": "Hermann - MO 65041",
    "county": "Gasconade County",
    "state": "MO",
    "city": "Hermann"
  },
  {
    "value": "65043",
    "label": "Holts Summit - MO 65043",
    "county": "Callaway County",
    "state": "MO",
    "city": "Holts Summit"
  },
  {
    "value": "65046",
    "label": "Jamestown - MO 65046",
    "county": "Moniteau County",
    "state": "MO",
    "city": "Jamestown"
  },
  {
    "value": "65047",
    "label": "Kaiser - MO 65047",
    "county": "Miller County",
    "state": "MO",
    "city": "Kaiser"
  },
  {
    "value": "65048",
    "label": "Koeltztown - MO 65048",
    "county": "Osage County",
    "state": "MO",
    "city": "Koeltztown"
  },
  {
    "value": "65049",
    "label": "Lake Ozark - MO 65049",
    "county": "Camden County",
    "state": "MO",
    "city": "Lake Ozark"
  },
  {
    "value": "65050",
    "label": "Latham - MO 65050",
    "county": "Moniteau County",
    "state": "MO",
    "city": "Latham"
  },
  {
    "value": "65051",
    "label": "Linn - MO 65051",
    "county": "Osage County",
    "state": "MO",
    "city": "Linn"
  },
  {
    "value": "65052",
    "label": "Linn Creek - MO 65052",
    "county": "Camden County",
    "state": "MO",
    "city": "Linn Creek"
  },
  {
    "value": "65053",
    "label": "Lohman - MO 65053",
    "county": "Cole County",
    "state": "MO",
    "city": "Lohman"
  },
  {
    "value": "65054",
    "label": "Loose Creek - MO 65054",
    "county": "Osage County",
    "state": "MO",
    "city": "Loose Creek"
  },
  {
    "value": "65058",
    "label": "Meta - MO 65058",
    "county": "Maries County",
    "state": "MO",
    "city": "Meta"
  },
  {
    "value": "65059",
    "label": "Mokane - MO 65059",
    "county": "Callaway County",
    "state": "MO",
    "city": "Mokane"
  },
  {
    "value": "65061",
    "label": "Morrison - MO 65061",
    "county": "Gasconade County",
    "state": "MO",
    "city": "Morrison"
  },
  {
    "value": "65062",
    "label": "Mount Sterling - MO 65062",
    "county": "Gasconade County",
    "state": "MO",
    "city": "Mount Sterling"
  },
  {
    "value": "65063",
    "label": "New Bloomfield - MO 65063",
    "county": "Callaway County",
    "state": "MO",
    "city": "New Bloomfield"
  },
  {
    "value": "65064",
    "label": "Olean - MO 65064",
    "county": "Miller County",
    "state": "MO",
    "city": "Olean"
  },
  {
    "value": "65065",
    "label": "Osage Beach - MO 65065",
    "county": "Camden County",
    "state": "MO",
    "city": "Osage Beach"
  },
  {
    "value": "65066",
    "label": "Owensville - MO 65066",
    "county": "Gasconade County",
    "state": "MO",
    "city": "Owensville"
  },
  {
    "value": "65067",
    "label": "Portland - MO 65067",
    "county": "Callaway County",
    "state": "MO",
    "city": "Portland"
  },
  {
    "value": "65068",
    "label": "Prairie Home - MO 65068",
    "county": "Cooper County",
    "state": "MO",
    "city": "Prairie Home"
  },
  {
    "value": "65069",
    "label": "Rhineland - MO 65069",
    "county": "Montgomery County",
    "state": "MO",
    "city": "Rhineland"
  },
  {
    "value": "65072",
    "label": "Rocky Mount - MO 65072",
    "county": "Morgan County",
    "state": "MO",
    "city": "Rocky Mount"
  },
  {
    "value": "65074",
    "label": "Russellville - MO 65074",
    "county": "Cole County",
    "state": "MO",
    "city": "Russellville"
  },
  {
    "value": "65075",
    "label": "Saint Elizabeth - MO 65075",
    "county": "Miller County",
    "state": "MO",
    "city": "Saint Elizabeth"
  },
  {
    "value": "65076",
    "label": "Saint Thomas - MO 65076",
    "county": "Cole County",
    "state": "MO",
    "city": "Saint Thomas"
  },
  {
    "value": "65077",
    "label": "Steedman - MO 65077",
    "county": "Callaway County",
    "state": "MO",
    "city": "Steedman"
  },
  {
    "value": "65078",
    "label": "Stover - MO 65078",
    "county": "Morgan County",
    "state": "MO",
    "city": "Stover"
  },
  {
    "value": "65079",
    "label": "Sunrise Beach - MO 65079",
    "county": "Camden County",
    "state": "MO",
    "city": "Sunrise Beach"
  },
  {
    "value": "65080",
    "label": "Tebbetts - MO 65080",
    "county": "Callaway County",
    "state": "MO",
    "city": "Tebbetts"
  },
  {
    "value": "65081",
    "label": "Tipton - MO 65081",
    "county": "Moniteau County",
    "state": "MO",
    "city": "Tipton"
  },
  {
    "value": "65082",
    "label": "Tuscumbia - MO 65082",
    "county": "Miller County",
    "state": "MO",
    "city": "Tuscumbia"
  },
  {
    "value": "65083",
    "label": "Ulman - MO 65083",
    "county": "Miller County",
    "state": "MO",
    "city": "Ulman"
  },
  {
    "value": "65084",
    "label": "Versailles - MO 65084",
    "county": "Morgan County",
    "state": "MO",
    "city": "Versailles"
  },
  {
    "value": "65085",
    "label": "Westphalia - MO 65085",
    "county": "Osage County",
    "state": "MO",
    "city": "Westphalia"
  },
  {
    "value": "65101",
    "label": "Jefferson City - MO 65101",
    "county": "Cole County",
    "state": "MO",
    "city": "Jefferson City"
  },
  {
    "value": "65109",
    "label": "Jefferson City - MO 65109",
    "county": "Cole County",
    "state": "MO",
    "city": "Jefferson City"
  },
  {
    "value": "65201",
    "label": "Columbia - MO 65201",
    "county": "Boone County",
    "state": "MO",
    "city": "Columbia"
  },
  {
    "value": "65202",
    "label": "Columbia - MO 65202",
    "county": "Boone County",
    "state": "MO",
    "city": "Columbia"
  },
  {
    "value": "65203",
    "label": "Columbia - MO 65203",
    "county": "Boone County",
    "state": "MO",
    "city": "Columbia"
  },
  {
    "value": "65230",
    "label": "Armstrong - MO 65230",
    "county": "Howard County",
    "state": "MO",
    "city": "Armstrong"
  },
  {
    "value": "65231",
    "label": "Auxvasse - MO 65231",
    "county": "Callaway County",
    "state": "MO",
    "city": "Auxvasse"
  },
  {
    "value": "65232",
    "label": "Benton City - MO 65232",
    "county": "Audrain County",
    "state": "MO",
    "city": "Benton City"
  },
  {
    "value": "65233",
    "label": "Boonville - MO 65233",
    "county": "Cooper County",
    "state": "MO",
    "city": "Boonville"
  },
  {
    "value": "65236",
    "label": "Brunswick - MO 65236",
    "county": "Chariton County",
    "state": "MO",
    "city": "Brunswick"
  },
  {
    "value": "65237",
    "label": "Bunceton - MO 65237",
    "county": "Cooper County",
    "state": "MO",
    "city": "Bunceton"
  },
  {
    "value": "65239",
    "label": "Cairo - MO 65239",
    "county": "Randolph County",
    "state": "MO",
    "city": "Cairo"
  },
  {
    "value": "65240",
    "label": "Centralia - MO 65240",
    "county": "Boone County",
    "state": "MO",
    "city": "Centralia"
  },
  {
    "value": "65243",
    "label": "Clark - MO 65243",
    "county": "Randolph County",
    "state": "MO",
    "city": "Clark"
  },
  {
    "value": "65244",
    "label": "Clifton Hill - MO 65244",
    "county": "Randolph County",
    "state": "MO",
    "city": "Clifton Hill"
  },
  {
    "value": "65246",
    "label": "Dalton - MO 65246",
    "county": "Chariton County",
    "state": "MO",
    "city": "Dalton"
  },
  {
    "value": "65247",
    "label": "Excello - MO 65247",
    "county": "Macon County",
    "state": "MO",
    "city": "Excello"
  },
  {
    "value": "65248",
    "label": "Fayette - MO 65248",
    "county": "Howard County",
    "state": "MO",
    "city": "Fayette"
  },
  {
    "value": "65250",
    "label": "Franklin - MO 65250",
    "county": "Howard County",
    "state": "MO",
    "city": "Franklin"
  },
  {
    "value": "65251",
    "label": "Fulton - MO 65251",
    "county": "Callaway County",
    "state": "MO",
    "city": "Fulton"
  },
  {
    "value": "65254",
    "label": "Glasgow - MO 65254",
    "county": "Howard County",
    "state": "MO",
    "city": "Glasgow"
  },
  {
    "value": "65255",
    "label": "Hallsville - MO 65255",
    "county": "Boone County",
    "state": "MO",
    "city": "Hallsville"
  },
  {
    "value": "65256",
    "label": "Harrisburg - MO 65256",
    "county": "Boone County",
    "state": "MO",
    "city": "Harrisburg"
  },
  {
    "value": "65257",
    "label": "Higbee - MO 65257",
    "county": "Randolph County",
    "state": "MO",
    "city": "Higbee"
  },
  {
    "value": "65258",
    "label": "Holliday - MO 65258",
    "county": "Monroe County",
    "state": "MO",
    "city": "Holliday"
  },
  {
    "value": "65259",
    "label": "Huntsville - MO 65259",
    "county": "Randolph County",
    "state": "MO",
    "city": "Huntsville"
  },
  {
    "value": "65260",
    "label": "Jacksonville - MO 65260",
    "county": "Randolph County",
    "state": "MO",
    "city": "Jacksonville"
  },
  {
    "value": "65261",
    "label": "Keytesville - MO 65261",
    "county": "Chariton County",
    "state": "MO",
    "city": "Keytesville"
  },
  {
    "value": "65262",
    "label": "Kingdom City - MO 65262",
    "county": "Callaway County",
    "state": "MO",
    "city": "Kingdom City"
  },
  {
    "value": "65263",
    "label": "Madison - MO 65263",
    "county": "Monroe County",
    "state": "MO",
    "city": "Madison"
  },
  {
    "value": "65264",
    "label": "Martinsburg - MO 65264",
    "county": "Audrain County",
    "state": "MO",
    "city": "Martinsburg"
  },
  {
    "value": "65265",
    "label": "Mexico - MO 65265",
    "county": "Audrain County",
    "state": "MO",
    "city": "Mexico"
  },
  {
    "value": "65270",
    "label": "Moberly - MO 65270",
    "county": "Randolph County",
    "state": "MO",
    "city": "Moberly"
  },
  {
    "value": "65274",
    "label": "New Franklin - MO 65274",
    "county": "Howard County",
    "state": "MO",
    "city": "New Franklin"
  },
  {
    "value": "65275",
    "label": "Paris - MO 65275",
    "county": "Monroe County",
    "state": "MO",
    "city": "Paris"
  },
  {
    "value": "65276",
    "label": "Pilot Grove - MO 65276",
    "county": "Cooper County",
    "state": "MO",
    "city": "Pilot Grove"
  },
  {
    "value": "65279",
    "label": "Rocheport - MO 65279",
    "county": "Boone County",
    "state": "MO",
    "city": "Rocheport"
  },
  {
    "value": "65280",
    "label": "Rush Hill - MO 65280",
    "county": "Audrain County",
    "state": "MO",
    "city": "Rush Hill"
  },
  {
    "value": "65281",
    "label": "Salisbury - MO 65281",
    "county": "Chariton County",
    "state": "MO",
    "city": "Salisbury"
  },
  {
    "value": "65282",
    "label": "Santa Fe - MO 65282",
    "county": "Monroe County",
    "state": "MO",
    "city": "Santa Fe"
  },
  {
    "value": "65283",
    "label": "Stoutsville - MO 65283",
    "county": "Monroe County",
    "state": "MO",
    "city": "Stoutsville"
  },
  {
    "value": "65284",
    "label": "Sturgeon - MO 65284",
    "county": "Boone County",
    "state": "MO",
    "city": "Sturgeon"
  },
  {
    "value": "65285",
    "label": "Thompson - MO 65285",
    "county": "Audrain County",
    "state": "MO",
    "city": "Thompson"
  },
  {
    "value": "65286",
    "label": "Triplett - MO 65286",
    "county": "Chariton County",
    "state": "MO",
    "city": "Triplett"
  },
  {
    "value": "65287",
    "label": "Wooldridge - MO 65287",
    "county": "Cooper County",
    "state": "MO",
    "city": "Wooldridge"
  },
  {
    "value": "65299",
    "label": "Columbia - MO 65299",
    "county": "Boone County",
    "state": "MO",
    "city": "Columbia"
  },
  {
    "value": "65301",
    "label": "Sedalia - MO 65301",
    "county": "Pettis County",
    "state": "MO",
    "city": "Sedalia"
  },
  {
    "value": "65305",
    "label": "Whiteman Air Force Base - MO 65305",
    "county": "Johnson County",
    "state": "MO",
    "city": "Whiteman Air Force Base"
  },
  {
    "value": "65321",
    "label": "Blackburn - MO 65321",
    "county": "Saline County",
    "state": "MO",
    "city": "Blackburn"
  },
  {
    "value": "65322",
    "label": "Blackwater - MO 65322",
    "county": "Cooper County",
    "state": "MO",
    "city": "Blackwater"
  },
  {
    "value": "65323",
    "label": "Calhoun - MO 65323",
    "county": "Henry County",
    "state": "MO",
    "city": "Calhoun"
  },
  {
    "value": "65324",
    "label": "Climax Springs - MO 65324",
    "county": "Camden County",
    "state": "MO",
    "city": "Climax Springs"
  },
  {
    "value": "65325",
    "label": "Cole Camp - MO 65325",
    "county": "Benton County",
    "state": "MO",
    "city": "Cole Camp"
  },
  {
    "value": "65326",
    "label": "Edwards - MO 65326",
    "county": "Benton County",
    "state": "MO",
    "city": "Edwards"
  },
  {
    "value": "65329",
    "label": "Florence - MO 65329",
    "county": "Morgan County",
    "state": "MO",
    "city": "Florence"
  },
  {
    "value": "65330",
    "label": "Gilliam - MO 65330",
    "county": "Saline County",
    "state": "MO",
    "city": "Gilliam"
  },
  {
    "value": "65332",
    "label": "Green Ridge - MO 65332",
    "county": "Pettis County",
    "state": "MO",
    "city": "Green Ridge"
  },
  {
    "value": "65333",
    "label": "Houstonia - MO 65333",
    "county": "Pettis County",
    "state": "MO",
    "city": "Houstonia"
  },
  {
    "value": "65334",
    "label": "Hughesville - MO 65334",
    "county": "Pettis County",
    "state": "MO",
    "city": "Hughesville"
  },
  {
    "value": "65335",
    "label": "Ionia - MO 65335",
    "county": "Benton County",
    "state": "MO",
    "city": "Ionia"
  },
  {
    "value": "65336",
    "label": "Knob Noster - MO 65336",
    "county": "Johnson County",
    "state": "MO",
    "city": "Knob Noster"
  },
  {
    "value": "65337",
    "label": "La Monte - MO 65337",
    "county": "Pettis County",
    "state": "MO",
    "city": "La Monte"
  },
  {
    "value": "65338",
    "label": "Lincoln - MO 65338",
    "county": "Benton County",
    "state": "MO",
    "city": "Lincoln"
  },
  {
    "value": "65339",
    "label": "Malta Bend - MO 65339",
    "county": "Saline County",
    "state": "MO",
    "city": "Malta Bend"
  },
  {
    "value": "65340",
    "label": "Marshall - MO 65340",
    "county": "Saline County",
    "state": "MO",
    "city": "Marshall"
  },
  {
    "value": "65344",
    "label": "Miami - MO 65344",
    "county": "Saline County",
    "state": "MO",
    "city": "Miami"
  },
  {
    "value": "65345",
    "label": "Mora - MO 65345",
    "county": "Pettis County",
    "state": "MO",
    "city": "Mora"
  },
  {
    "value": "65347",
    "label": "Nelson - MO 65347",
    "county": "Saline County",
    "state": "MO",
    "city": "Nelson"
  },
  {
    "value": "65348",
    "label": "Otterville - MO 65348",
    "county": "Cooper County",
    "state": "MO",
    "city": "Otterville"
  },
  {
    "value": "65349",
    "label": "Slater - MO 65349",
    "county": "Saline County",
    "state": "MO",
    "city": "Slater"
  },
  {
    "value": "65350",
    "label": "Smithton - MO 65350",
    "county": "Pettis County",
    "state": "MO",
    "city": "Smithton"
  },
  {
    "value": "65351",
    "label": "Sweet Springs - MO 65351",
    "county": "Saline County",
    "state": "MO",
    "city": "Sweet Springs"
  },
  {
    "value": "65354",
    "label": "Syracuse - MO 65354",
    "county": "Morgan County",
    "state": "MO",
    "city": "Syracuse"
  },
  {
    "value": "65355",
    "label": "Warsaw - MO 65355",
    "county": "Benton County",
    "state": "MO",
    "city": "Warsaw"
  },
  {
    "value": "65360",
    "label": "Windsor - MO 65360",
    "county": "Henry County",
    "state": "MO",
    "city": "Windsor"
  },
  {
    "value": "65401",
    "label": "Rolla - MO 65401",
    "county": "Phelps County",
    "state": "MO",
    "city": "Rolla"
  },
  {
    "value": "65436",
    "label": "Beulah - MO 65436",
    "county": "Phelps County",
    "state": "MO",
    "city": "Beulah"
  },
  {
    "value": "65438",
    "label": "Birch Tree - MO 65438",
    "county": "Shannon County",
    "state": "MO",
    "city": "Birch Tree"
  },
  {
    "value": "65439",
    "label": "Bixby - MO 65439",
    "county": "Iron County",
    "state": "MO",
    "city": "Bixby"
  },
  {
    "value": "65440",
    "label": "Boss - MO 65440",
    "county": "Dent County",
    "state": "MO",
    "city": "Boss"
  },
  {
    "value": "65441",
    "label": "Bourbon - MO 65441",
    "county": "Crawford County",
    "state": "MO",
    "city": "Bourbon"
  },
  {
    "value": "65443",
    "label": "Brinktown - MO 65443",
    "county": "Maries County",
    "state": "MO",
    "city": "Brinktown"
  },
  {
    "value": "65444",
    "label": "Bucyrus - MO 65444",
    "county": "Texas County",
    "state": "MO",
    "city": "Bucyrus"
  },
  {
    "value": "65446",
    "label": "Cherryville - MO 65446",
    "county": "Crawford County",
    "state": "MO",
    "city": "Cherryville"
  },
  {
    "value": "65449",
    "label": "Cook Sta - MO 65449",
    "county": "Crawford County",
    "state": "MO",
    "city": "Cook Sta"
  },
  {
    "value": "65452",
    "label": "Crocker - MO 65452",
    "county": "Pulaski County",
    "state": "MO",
    "city": "Crocker"
  },
  {
    "value": "65453",
    "label": "Cuba - MO 65453",
    "county": "Crawford County",
    "state": "MO",
    "city": "Cuba"
  },
  {
    "value": "65456",
    "label": "Davisville - MO 65456",
    "county": "Crawford County",
    "state": "MO",
    "city": "Davisville"
  },
  {
    "value": "65457",
    "label": "Devils Elbow - MO 65457",
    "county": "Pulaski County",
    "state": "MO",
    "city": "Devils Elbow"
  },
  {
    "value": "65459",
    "label": "Dixon - MO 65459",
    "county": "Pulaski County",
    "state": "MO",
    "city": "Dixon"
  },
  {
    "value": "65461",
    "label": "Duke - MO 65461",
    "county": "Phelps County",
    "state": "MO",
    "city": "Duke"
  },
  {
    "value": "65462",
    "label": "Edgar Springs - MO 65462",
    "county": "Phelps County",
    "state": "MO",
    "city": "Edgar Springs"
  },
  {
    "value": "65463",
    "label": "Eldridge - MO 65463",
    "county": "Laclede County",
    "state": "MO",
    "city": "Eldridge"
  },
  {
    "value": "65464",
    "label": "Elk Creek - MO 65464",
    "county": "Texas County",
    "state": "MO",
    "city": "Elk Creek"
  },
  {
    "value": "65466",
    "label": "Eminence - MO 65466",
    "county": "Shannon County",
    "state": "MO",
    "city": "Eminence"
  },
  {
    "value": "65468",
    "label": "Eunice - MO 65468",
    "county": "Texas County",
    "state": "MO",
    "city": "Eunice"
  },
  {
    "value": "65470",
    "label": "Falcon - MO 65470",
    "county": "Laclede County",
    "state": "MO",
    "city": "Falcon"
  },
  {
    "value": "65473",
    "label": "Fort Leonard Wood - MO 65473",
    "county": "Pulaski County",
    "state": "MO",
    "city": "Fort Leonard Wood"
  },
  {
    "value": "65479",
    "label": "Hartshorn - MO 65479",
    "county": "Texas County",
    "state": "MO",
    "city": "Hartshorn"
  },
  {
    "value": "65483",
    "label": "Houston - MO 65483",
    "county": "Texas County",
    "state": "MO",
    "city": "Houston"
  },
  {
    "value": "65484",
    "label": "Huggins - MO 65484",
    "county": "Texas County",
    "state": "MO",
    "city": "Huggins"
  },
  {
    "value": "65486",
    "label": "Iberia - MO 65486",
    "county": "Miller County",
    "state": "MO",
    "city": "Iberia"
  },
  {
    "value": "65501",
    "label": "Jadwin - MO 65501",
    "county": "Dent County",
    "state": "MO",
    "city": "Jadwin"
  },
  {
    "value": "65529",
    "label": "Jerome - MO 65529",
    "county": "Phelps County",
    "state": "MO",
    "city": "Jerome"
  },
  {
    "value": "65534",
    "label": "Laquey - MO 65534",
    "county": "Pulaski County",
    "state": "MO",
    "city": "Laquey"
  },
  {
    "value": "65535",
    "label": "Leasburg - MO 65535",
    "county": "Crawford County",
    "state": "MO",
    "city": "Leasburg"
  },
  {
    "value": "65536",
    "label": "Lebanon - MO 65536",
    "county": "Laclede County",
    "state": "MO",
    "city": "Lebanon"
  },
  {
    "value": "65541",
    "label": "Lenox - MO 65541",
    "county": "Dent County",
    "state": "MO",
    "city": "Lenox"
  },
  {
    "value": "65542",
    "label": "Licking - MO 65542",
    "county": "Texas County",
    "state": "MO",
    "city": "Licking"
  },
  {
    "value": "65543",
    "label": "Lynchburg - MO 65543",
    "county": "Laclede County",
    "state": "MO",
    "city": "Lynchburg"
  },
  {
    "value": "65548",
    "label": "Mountain View - MO 65548",
    "county": "Howell County",
    "state": "MO",
    "city": "Mountain View"
  },
  {
    "value": "65550",
    "label": "Newburg - MO 65550",
    "county": "Phelps County",
    "state": "MO",
    "city": "Newburg"
  },
  {
    "value": "65552",
    "label": "Plato - MO 65552",
    "county": "Texas County",
    "state": "MO",
    "city": "Plato"
  },
  {
    "value": "65555",
    "label": "Raymondville - MO 65555",
    "county": "Texas County",
    "state": "MO",
    "city": "Raymondville"
  },
  {
    "value": "65556",
    "label": "Richland - MO 65556",
    "county": "Pulaski County",
    "state": "MO",
    "city": "Richland"
  },
  {
    "value": "65557",
    "label": "Roby - MO 65557",
    "county": "Texas County",
    "state": "MO",
    "city": "Roby"
  },
  {
    "value": "65559",
    "label": "Saint James - MO 65559",
    "county": "Phelps County",
    "state": "MO",
    "city": "Saint James"
  },
  {
    "value": "65560",
    "label": "Salem - MO 65560",
    "county": "Dent County",
    "state": "MO",
    "city": "Salem"
  },
  {
    "value": "65564",
    "label": "Solo - MO 65564",
    "county": "Texas County",
    "state": "MO",
    "city": "Solo"
  },
  {
    "value": "65565",
    "label": "Steelville - MO 65565",
    "county": "Crawford County",
    "state": "MO",
    "city": "Steelville"
  },
  {
    "value": "65566",
    "label": "Viburnum - MO 65566",
    "county": "Iron County",
    "state": "MO",
    "city": "Viburnum"
  },
  {
    "value": "65567",
    "label": "Stoutland - MO 65567",
    "county": "Camden County",
    "state": "MO",
    "city": "Stoutland"
  },
  {
    "value": "65570",
    "label": "Success - MO 65570",
    "county": "Texas County",
    "state": "MO",
    "city": "Success"
  },
  {
    "value": "65571",
    "label": "Summersville - MO 65571",
    "county": "Texas County",
    "state": "MO",
    "city": "Summersville"
  },
  {
    "value": "65580",
    "label": "Vichy - MO 65580",
    "county": "Maries County",
    "state": "MO",
    "city": "Vichy"
  },
  {
    "value": "65582",
    "label": "Vienna - MO 65582",
    "county": "Maries County",
    "state": "MO",
    "city": "Vienna"
  },
  {
    "value": "65583",
    "label": "Waynesville - MO 65583",
    "county": "Pulaski County",
    "state": "MO",
    "city": "Waynesville"
  },
  {
    "value": "65584",
    "label": "Saint Robert - MO 65584",
    "county": "Pulaski County",
    "state": "MO",
    "city": "Saint Robert"
  },
  {
    "value": "65588",
    "label": "Winona - MO 65588",
    "county": "Shannon County",
    "state": "MO",
    "city": "Winona"
  },
  {
    "value": "65589",
    "label": "Yukon - MO 65589",
    "county": "Texas County",
    "state": "MO",
    "city": "Yukon"
  },
  {
    "value": "65590",
    "label": "Long Lane - MO 65590",
    "county": "Dallas County",
    "state": "MO",
    "city": "Long Lane"
  },
  {
    "value": "65591",
    "label": "Montreal - MO 65591",
    "county": "Camden County",
    "state": "MO",
    "city": "Montreal"
  },
  {
    "value": "65601",
    "label": "Aldrich - MO 65601",
    "county": "Polk County",
    "state": "MO",
    "city": "Aldrich"
  },
  {
    "value": "65603",
    "label": "Arcola - MO 65603",
    "county": "Dade County",
    "state": "MO",
    "city": "Arcola"
  },
  {
    "value": "65604",
    "label": "Ash Grove - MO 65604",
    "county": "Greene County",
    "state": "MO",
    "city": "Ash Grove"
  },
  {
    "value": "65605",
    "label": "Aurora - MO 65605",
    "county": "Lawrence County",
    "state": "MO",
    "city": "Aurora"
  },
  {
    "value": "65606",
    "label": "Alton - MO 65606",
    "county": "Oregon County",
    "state": "MO",
    "city": "Alton"
  },
  {
    "value": "65608",
    "label": "Ava - MO 65608",
    "county": "Douglas County",
    "state": "MO",
    "city": "Ava"
  },
  {
    "value": "65609",
    "label": "Bakersfield - MO 65609",
    "county": "Ozark County",
    "state": "MO",
    "city": "Bakersfield"
  },
  {
    "value": "65610",
    "label": "Billings - MO 65610",
    "county": "Christian County",
    "state": "MO",
    "city": "Billings"
  },
  {
    "value": "65611",
    "label": "Blue Eye - MO 65611",
    "county": "Stone County",
    "state": "MO",
    "city": "Blue Eye"
  },
  {
    "value": "65612",
    "label": "Bois D Arc - MO 65612",
    "county": "Greene County",
    "state": "MO",
    "city": "Bois D Arc"
  },
  {
    "value": "65613",
    "label": "Bolivar - MO 65613",
    "county": "Polk County",
    "state": "MO",
    "city": "Bolivar"
  },
  {
    "value": "65614",
    "label": "Bradleyville - MO 65614",
    "county": "Taney County",
    "state": "MO",
    "city": "Bradleyville"
  },
  {
    "value": "65616",
    "label": "Branson - MO 65616",
    "county": "Taney County",
    "state": "MO",
    "city": "Branson"
  },
  {
    "value": "65617",
    "label": "Brighton - MO 65617",
    "county": "Polk County",
    "state": "MO",
    "city": "Brighton"
  },
  {
    "value": "65618",
    "label": "Brixey - MO 65618",
    "county": "Ozark County",
    "state": "MO",
    "city": "Brixey"
  },
  {
    "value": "65619",
    "label": "Brookline - MO 65619",
    "county": "Greene County",
    "state": "MO",
    "city": "Brookline"
  },
  {
    "value": "65620",
    "label": "Bruner - MO 65620",
    "county": "Christian County",
    "state": "MO",
    "city": "Bruner"
  },
  {
    "value": "65622",
    "label": "Buffalo - MO 65622",
    "county": "Dallas County",
    "state": "MO",
    "city": "Buffalo"
  },
  {
    "value": "65624",
    "label": "Cape Fair - MO 65624",
    "county": "Stone County",
    "state": "MO",
    "city": "Cape Fair"
  },
  {
    "value": "65625",
    "label": "Cassville - MO 65625",
    "county": "Barry County",
    "state": "MO",
    "city": "Cassville"
  },
  {
    "value": "65626",
    "label": "Caulfield - MO 65626",
    "county": "Ozark County",
    "state": "MO",
    "city": "Caulfield"
  },
  {
    "value": "65627",
    "label": "Cedarcreek - MO 65627",
    "county": "Taney County",
    "state": "MO",
    "city": "Cedarcreek"
  },
  {
    "value": "65629",
    "label": "Chadwick - MO 65629",
    "county": "Christian County",
    "state": "MO",
    "city": "Chadwick"
  },
  {
    "value": "65630",
    "label": "Chestnutridge - MO 65630",
    "county": "Christian County",
    "state": "MO",
    "city": "Chestnutridge"
  },
  {
    "value": "65631",
    "label": "Clever - MO 65631",
    "county": "Christian County",
    "state": "MO",
    "city": "Clever"
  },
  {
    "value": "65632",
    "label": "Conway - MO 65632",
    "county": "Laclede County",
    "state": "MO",
    "city": "Conway"
  },
  {
    "value": "65633",
    "label": "Crane - MO 65633",
    "county": "Stone County",
    "state": "MO",
    "city": "Crane"
  },
  {
    "value": "65634",
    "label": "Cross Timbers - MO 65634",
    "county": "Hickory County",
    "state": "MO",
    "city": "Cross Timbers"
  },
  {
    "value": "65635",
    "label": "Dadeville - MO 65635",
    "county": "Dade County",
    "state": "MO",
    "city": "Dadeville"
  },
  {
    "value": "65637",
    "label": "Dora - MO 65637",
    "county": "Ozark County",
    "state": "MO",
    "city": "Dora"
  },
  {
    "value": "65638",
    "label": "Drury - MO 65638",
    "county": "Douglas County",
    "state": "MO",
    "city": "Drury"
  },
  {
    "value": "65640",
    "label": "Dunnegan - MO 65640",
    "county": "Polk County",
    "state": "MO",
    "city": "Dunnegan"
  },
  {
    "value": "65641",
    "label": "Eagle Rock - MO 65641",
    "county": "Barry County",
    "state": "MO",
    "city": "Eagle Rock"
  },
  {
    "value": "65644",
    "label": "Elkland - MO 65644",
    "county": "Dallas County",
    "state": "MO",
    "city": "Elkland"
  },
  {
    "value": "65646",
    "label": "Everton - MO 65646",
    "county": "Dade County",
    "state": "MO",
    "city": "Everton"
  },
  {
    "value": "65647",
    "label": "Exeter - MO 65647",
    "county": "Barry County",
    "state": "MO",
    "city": "Exeter"
  },
  {
    "value": "65648",
    "label": "Fair Grove - MO 65648",
    "county": "Greene County",
    "state": "MO",
    "city": "Fair Grove"
  },
  {
    "value": "65649",
    "label": "Fair Play - MO 65649",
    "county": "Polk County",
    "state": "MO",
    "city": "Fair Play"
  },
  {
    "value": "65650",
    "label": "Flemington - MO 65650",
    "county": "Hickory County",
    "state": "MO",
    "city": "Flemington"
  },
  {
    "value": "65652",
    "label": "Fordland - MO 65652",
    "county": "Webster County",
    "state": "MO",
    "city": "Fordland"
  },
  {
    "value": "65653",
    "label": "Forsyth - MO 65653",
    "county": "Taney County",
    "state": "MO",
    "city": "Forsyth"
  },
  {
    "value": "65655",
    "label": "Gainesville - MO 65655",
    "county": "Ozark County",
    "state": "MO",
    "city": "Gainesville"
  },
  {
    "value": "65656",
    "label": "Galena - MO 65656",
    "county": "Stone County",
    "state": "MO",
    "city": "Galena"
  },
  {
    "value": "65657",
    "label": "Garrison - MO 65657",
    "county": "Christian County",
    "state": "MO",
    "city": "Garrison"
  },
  {
    "value": "65658",
    "label": "Golden - MO 65658",
    "county": "Barry County",
    "state": "MO",
    "city": "Golden"
  },
  {
    "value": "65660",
    "label": "Graff - MO 65660",
    "county": "Wright County",
    "state": "MO",
    "city": "Graff"
  },
  {
    "value": "65661",
    "label": "Greenfield - MO 65661",
    "county": "Dade County",
    "state": "MO",
    "city": "Greenfield"
  },
  {
    "value": "65662",
    "label": "Grovespring - MO 65662",
    "county": "Wright County",
    "state": "MO",
    "city": "Grovespring"
  },
  {
    "value": "65663",
    "label": "Half Way - MO 65663",
    "county": "Polk County",
    "state": "MO",
    "city": "Half Way"
  },
  {
    "value": "65666",
    "label": "Hardenville - MO 65666",
    "county": "Ozark County",
    "state": "MO",
    "city": "Hardenville"
  },
  {
    "value": "65667",
    "label": "Hartville - MO 65667",
    "county": "Wright County",
    "state": "MO",
    "city": "Hartville"
  },
  {
    "value": "65668",
    "label": "Hermitage - MO 65668",
    "county": "Hickory County",
    "state": "MO",
    "city": "Hermitage"
  },
  {
    "value": "65669",
    "label": "Highlandville - MO 65669",
    "county": "Christian County",
    "state": "MO",
    "city": "Highlandville"
  },
  {
    "value": "65672",
    "label": "Hollister - MO 65672",
    "county": "Taney County",
    "state": "MO",
    "city": "Hollister"
  },
  {
    "value": "65674",
    "label": "Humansville - MO 65674",
    "county": "Polk County",
    "state": "MO",
    "city": "Humansville"
  },
  {
    "value": "65676",
    "label": "Isabella - MO 65676",
    "county": "Ozark County",
    "state": "MO",
    "city": "Isabella"
  },
  {
    "value": "65679",
    "label": "Kirbyville - MO 65679",
    "county": "Taney County",
    "state": "MO",
    "city": "Kirbyville"
  },
  {
    "value": "65680",
    "label": "Kissee Mills - MO 65680",
    "county": "Taney County",
    "state": "MO",
    "city": "Kissee Mills"
  },
  {
    "value": "65681",
    "label": "Lampe - MO 65681",
    "county": "Stone County",
    "state": "MO",
    "city": "Lampe"
  },
  {
    "value": "65682",
    "label": "Lockwood - MO 65682",
    "county": "Dade County",
    "state": "MO",
    "city": "Lockwood"
  },
  {
    "value": "65685",
    "label": "Louisburg - MO 65685",
    "county": "Dallas County",
    "state": "MO",
    "city": "Louisburg"
  },
  {
    "value": "65686",
    "label": "Kimberling City - MO 65686",
    "county": "Stone County",
    "state": "MO",
    "city": "Kimberling City"
  },
  {
    "value": "65689",
    "label": "Cabool - MO 65689",
    "county": "Texas County",
    "state": "MO",
    "city": "Cabool"
  },
  {
    "value": "65690",
    "label": "Couch - MO 65690",
    "county": "Oregon County",
    "state": "MO",
    "city": "Couch"
  },
  {
    "value": "65692",
    "label": "Koshkonong - MO 65692",
    "county": "Oregon County",
    "state": "MO",
    "city": "Koshkonong"
  },
  {
    "value": "65701",
    "label": "Mc Clurg - MO 65701",
    "county": "Taney County",
    "state": "MO",
    "city": "Mc Clurg"
  },
  {
    "value": "65702",
    "label": "Macomb - MO 65702",
    "county": "Wright County",
    "state": "MO",
    "city": "Macomb"
  },
  {
    "value": "65704",
    "label": "Mansfield - MO 65704",
    "county": "Wright County",
    "state": "MO",
    "city": "Mansfield"
  },
  {
    "value": "65705",
    "label": "Marionville - MO 65705",
    "county": "Lawrence County",
    "state": "MO",
    "city": "Marionville"
  },
  {
    "value": "65706",
    "label": "Marshfield - MO 65706",
    "county": "Webster County",
    "state": "MO",
    "city": "Marshfield"
  },
  {
    "value": "65707",
    "label": "Miller - MO 65707",
    "county": "Lawrence County",
    "state": "MO",
    "city": "Miller"
  },
  {
    "value": "65708",
    "label": "Monett - MO 65708",
    "county": "Barry County",
    "state": "MO",
    "city": "Monett"
  },
  {
    "value": "65710",
    "label": "Morrisville - MO 65710",
    "county": "Polk County",
    "state": "MO",
    "city": "Morrisville"
  },
  {
    "value": "65711",
    "label": "Mountain Grove - MO 65711",
    "county": "Wright County",
    "state": "MO",
    "city": "Mountain Grove"
  },
  {
    "value": "65712",
    "label": "Mount Vernon - MO 65712",
    "county": "Lawrence County",
    "state": "MO",
    "city": "Mount Vernon"
  },
  {
    "value": "65713",
    "label": "Niangua - MO 65713",
    "county": "Webster County",
    "state": "MO",
    "city": "Niangua"
  },
  {
    "value": "65714",
    "label": "Nixa - MO 65714",
    "county": "Christian County",
    "state": "MO",
    "city": "Nixa"
  },
  {
    "value": "65715",
    "label": "Noble - MO 65715",
    "county": "Ozark County",
    "state": "MO",
    "city": "Noble"
  },
  {
    "value": "65717",
    "label": "Norwood - MO 65717",
    "county": "Wright County",
    "state": "MO",
    "city": "Norwood"
  },
  {
    "value": "65720",
    "label": "Oldfield - MO 65720",
    "county": "Christian County",
    "state": "MO",
    "city": "Oldfield"
  },
  {
    "value": "65721",
    "label": "Ozark - MO 65721",
    "county": "Christian County",
    "state": "MO",
    "city": "Ozark"
  },
  {
    "value": "65722",
    "label": "Phillipsburg - MO 65722",
    "county": "Laclede County",
    "state": "MO",
    "city": "Phillipsburg"
  },
  {
    "value": "65723",
    "label": "Pierce City - MO 65723",
    "county": "Lawrence County",
    "state": "MO",
    "city": "Pierce City"
  },
  {
    "value": "65724",
    "label": "Pittsburg - MO 65724",
    "county": "Hickory County",
    "state": "MO",
    "city": "Pittsburg"
  },
  {
    "value": "65725",
    "label": "Pleasant Hope - MO 65725",
    "county": "Polk County",
    "state": "MO",
    "city": "Pleasant Hope"
  },
  {
    "value": "65727",
    "label": "Polk - MO 65727",
    "county": "Polk County",
    "state": "MO",
    "city": "Polk"
  },
  {
    "value": "65728",
    "label": "Ponce De Leon - MO 65728",
    "county": "Stone County",
    "state": "MO",
    "city": "Ponce De Leon"
  },
  {
    "value": "65729",
    "label": "Pontiac - MO 65729",
    "county": "Ozark County",
    "state": "MO",
    "city": "Pontiac"
  },
  {
    "value": "65730",
    "label": "Powell - MO 65730",
    "county": "McDonald County",
    "state": "MO",
    "city": "Powell"
  },
  {
    "value": "65731",
    "label": "Powersite - MO 65731",
    "county": "Taney County",
    "state": "MO",
    "city": "Powersite"
  },
  {
    "value": "65732",
    "label": "Preston - MO 65732",
    "county": "Hickory County",
    "state": "MO",
    "city": "Preston"
  },
  {
    "value": "65733",
    "label": "Protem - MO 65733",
    "county": "Taney County",
    "state": "MO",
    "city": "Protem"
  },
  {
    "value": "65734",
    "label": "Purdy - MO 65734",
    "county": "Barry County",
    "state": "MO",
    "city": "Purdy"
  },
  {
    "value": "65735",
    "label": "Quincy - MO 65735",
    "county": "Hickory County",
    "state": "MO",
    "city": "Quincy"
  },
  {
    "value": "65737",
    "label": "Reeds Spring - MO 65737",
    "county": "Stone County",
    "state": "MO",
    "city": "Reeds Spring"
  },
  {
    "value": "65738",
    "label": "Republic - MO 65738",
    "county": "Greene County",
    "state": "MO",
    "city": "Republic"
  },
  {
    "value": "65739",
    "label": "Ridgedale - MO 65739",
    "county": "Taney County",
    "state": "MO",
    "city": "Ridgedale"
  },
  {
    "value": "65740",
    "label": "Rockaway Beach - MO 65740",
    "county": "Taney County",
    "state": "MO",
    "city": "Rockaway Beach"
  },
  {
    "value": "65742",
    "label": "Rogersville - MO 65742",
    "county": "Webster County",
    "state": "MO",
    "city": "Rogersville"
  },
  {
    "value": "65744",
    "label": "Rueter - MO 65744",
    "county": "Taney County",
    "state": "MO",
    "city": "Rueter"
  },
  {
    "value": "65745",
    "label": "Seligman - MO 65745",
    "county": "Barry County",
    "state": "MO",
    "city": "Seligman"
  },
  {
    "value": "65746",
    "label": "Seymour - MO 65746",
    "county": "Webster County",
    "state": "MO",
    "city": "Seymour"
  },
  {
    "value": "65747",
    "label": "Shell Knob - MO 65747",
    "county": "Barry County",
    "state": "MO",
    "city": "Shell Knob"
  },
  {
    "value": "65752",
    "label": "South Greenfield - MO 65752",
    "county": "Dade County",
    "state": "MO",
    "city": "South Greenfield"
  },
  {
    "value": "65753",
    "label": "Sparta - MO 65753",
    "county": "Christian County",
    "state": "MO",
    "city": "Sparta"
  },
  {
    "value": "65754",
    "label": "Spokane - MO 65754",
    "county": "Christian County",
    "state": "MO",
    "city": "Spokane"
  },
  {
    "value": "65755",
    "label": "Squires - MO 65755",
    "county": "Douglas County",
    "state": "MO",
    "city": "Squires"
  },
  {
    "value": "65756",
    "label": "Stotts City - MO 65756",
    "county": "Lawrence County",
    "state": "MO",
    "city": "Stotts City"
  },
  {
    "value": "65757",
    "label": "Strafford - MO 65757",
    "county": "Greene County",
    "state": "MO",
    "city": "Strafford"
  },
  {
    "value": "65759",
    "label": "Taneyville - MO 65759",
    "county": "Taney County",
    "state": "MO",
    "city": "Taneyville"
  },
  {
    "value": "65760",
    "label": "Tecumseh - MO 65760",
    "county": "Ozark County",
    "state": "MO",
    "city": "Tecumseh"
  },
  {
    "value": "65761",
    "label": "Theodosia - MO 65761",
    "county": "Ozark County",
    "state": "MO",
    "city": "Theodosia"
  },
  {
    "value": "65762",
    "label": "Thornfield - MO 65762",
    "county": "Ozark County",
    "state": "MO",
    "city": "Thornfield"
  },
  {
    "value": "65764",
    "label": "Tunas - MO 65764",
    "county": "Dallas County",
    "state": "MO",
    "city": "Tunas"
  },
  {
    "value": "65766",
    "label": "Udall - MO 65766",
    "county": "Ozark County",
    "state": "MO",
    "city": "Udall"
  },
  {
    "value": "65767",
    "label": "Urbana - MO 65767",
    "county": "Dallas County",
    "state": "MO",
    "city": "Urbana"
  },
  {
    "value": "65768",
    "label": "Vanzant - MO 65768",
    "county": "Douglas County",
    "state": "MO",
    "city": "Vanzant"
  },
  {
    "value": "65769",
    "label": "Verona - MO 65769",
    "county": "Lawrence County",
    "state": "MO",
    "city": "Verona"
  },
  {
    "value": "65770",
    "label": "Walnut Grove - MO 65770",
    "county": "Greene County",
    "state": "MO",
    "city": "Walnut Grove"
  },
  {
    "value": "65771",
    "label": "Walnut Shade - MO 65771",
    "county": "Taney County",
    "state": "MO",
    "city": "Walnut Shade"
  },
  {
    "value": "65772",
    "label": "Washburn - MO 65772",
    "county": "Barry County",
    "state": "MO",
    "city": "Washburn"
  },
  {
    "value": "65773",
    "label": "Wasola - MO 65773",
    "county": "Ozark County",
    "state": "MO",
    "city": "Wasola"
  },
  {
    "value": "65774",
    "label": "Weaubleau - MO 65774",
    "county": "Hickory County",
    "state": "MO",
    "city": "Weaubleau"
  },
  {
    "value": "65775",
    "label": "West Plains - MO 65775",
    "county": "Howell County",
    "state": "MO",
    "city": "West Plains"
  },
  {
    "value": "65776",
    "label": "South Fork - MO 65776",
    "county": "Howell County",
    "state": "MO",
    "city": "South Fork"
  },
  {
    "value": "65777",
    "label": "Moody - MO 65777",
    "county": "Howell County",
    "state": "MO",
    "city": "Moody"
  },
  {
    "value": "65778",
    "label": "Myrtle - MO 65778",
    "county": "Oregon County",
    "state": "MO",
    "city": "Myrtle"
  },
  {
    "value": "65779",
    "label": "Wheatland - MO 65779",
    "county": "Hickory County",
    "state": "MO",
    "city": "Wheatland"
  },
  {
    "value": "65781",
    "label": "Willard - MO 65781",
    "county": "Greene County",
    "state": "MO",
    "city": "Willard"
  },
  {
    "value": "65783",
    "label": "Windyville - MO 65783",
    "county": "Dallas County",
    "state": "MO",
    "city": "Windyville"
  },
  {
    "value": "65784",
    "label": "Zanoni - MO 65784",
    "county": "Ozark County",
    "state": "MO",
    "city": "Zanoni"
  },
  {
    "value": "65785",
    "label": "Stockton - MO 65785",
    "county": "Cedar County",
    "state": "MO",
    "city": "Stockton"
  },
  {
    "value": "65786",
    "label": "Macks Creek - MO 65786",
    "county": "Camden County",
    "state": "MO",
    "city": "Macks Creek"
  },
  {
    "value": "65787",
    "label": "Roach - MO 65787",
    "county": "Camden County",
    "state": "MO",
    "city": "Roach"
  },
  {
    "value": "65788",
    "label": "Peace Valley - MO 65788",
    "county": "Howell County",
    "state": "MO",
    "city": "Peace Valley"
  },
  {
    "value": "65789",
    "label": "Pomona - MO 65789",
    "county": "Howell County",
    "state": "MO",
    "city": "Pomona"
  },
  {
    "value": "65790",
    "label": "Pottersville - MO 65790",
    "county": "Howell County",
    "state": "MO",
    "city": "Pottersville"
  },
  {
    "value": "65791",
    "label": "Thayer - MO 65791",
    "county": "Oregon County",
    "state": "MO",
    "city": "Thayer"
  },
  {
    "value": "65793",
    "label": "Willow Springs - MO 65793",
    "county": "Howell County",
    "state": "MO",
    "city": "Willow Springs"
  },
  {
    "value": "65802",
    "label": "Springfield - MO 65802",
    "county": "Greene County",
    "state": "MO",
    "city": "Springfield"
  },
  {
    "value": "65803",
    "label": "Springfield - MO 65803",
    "county": "Greene County",
    "state": "MO",
    "city": "Springfield"
  },
  {
    "value": "65804",
    "label": "Springfield - MO 65804",
    "county": "Greene County",
    "state": "MO",
    "city": "Springfield"
  },
  {
    "value": "65806",
    "label": "Springfield - MO 65806",
    "county": "Greene County",
    "state": "MO",
    "city": "Springfield"
  },
  {
    "value": "65807",
    "label": "Springfield - MO 65807",
    "county": "Greene County",
    "state": "MO",
    "city": "Springfield"
  },
  {
    "value": "65809",
    "label": "Springfield - MO 65809",
    "county": "Greene County",
    "state": "MO",
    "city": "Springfield"
  },
  {
    "value": "65810",
    "label": "Springfield - MO 65810",
    "county": "Greene County",
    "state": "MO",
    "city": "Springfield"
  },
  {
    "value": "72643",
    "label": "Lead Hill - MO 72643",
    "county": "Webster County",
    "state": "MO",
    "city": "Lead Hill"
  },
  {
    "value": "59001",
    "label": "Absarokee - MT 59001",
    "county": "Stillwater County",
    "state": "MT",
    "city": "Absarokee"
  },
  {
    "value": "59002",
    "label": "Acton - MT 59002",
    "county": "Yellowstone County",
    "state": "MT",
    "city": "Acton"
  },
  {
    "value": "59003",
    "label": "Ashland - MT 59003",
    "county": "Rosebud County",
    "state": "MT",
    "city": "Ashland"
  },
  {
    "value": "59006",
    "label": "Ballantine - MT 59006",
    "county": "Yellowstone County",
    "state": "MT",
    "city": "Ballantine"
  },
  {
    "value": "59007",
    "label": "Bearcreek - MT 59007",
    "county": "Carbon County",
    "state": "MT",
    "city": "Bearcreek"
  },
  {
    "value": "59008",
    "label": "Belfry - MT 59008",
    "county": "Carbon County",
    "state": "MT",
    "city": "Belfry"
  },
  {
    "value": "59010",
    "label": "Bighorn - MT 59010",
    "county": "Treasure County",
    "state": "MT",
    "city": "Bighorn"
  },
  {
    "value": "59011",
    "label": "Big Timber - MT 59011",
    "county": "Sweet Grass County",
    "state": "MT",
    "city": "Big Timber"
  },
  {
    "value": "59012",
    "label": "Birney - MT 59012",
    "county": "Rosebud County",
    "state": "MT",
    "city": "Birney"
  },
  {
    "value": "59014",
    "label": "Bridger - MT 59014",
    "county": "Carbon County",
    "state": "MT",
    "city": "Bridger"
  },
  {
    "value": "59015",
    "label": "Broadview - MT 59015",
    "county": "Yellowstone County",
    "state": "MT",
    "city": "Broadview"
  },
  {
    "value": "59016",
    "label": "Busby - MT 59016",
    "county": "Big Horn County",
    "state": "MT",
    "city": "Busby"
  },
  {
    "value": "59019",
    "label": "Columbus - MT 59019",
    "county": "Stillwater County",
    "state": "MT",
    "city": "Columbus"
  },
  {
    "value": "59022",
    "label": "Crow Agency - MT 59022",
    "county": "Big Horn County",
    "state": "MT",
    "city": "Crow Agency"
  },
  {
    "value": "59024",
    "label": "Custer - MT 59024",
    "county": "Yellowstone County",
    "state": "MT",
    "city": "Custer"
  },
  {
    "value": "59025",
    "label": "Decker - MT 59025",
    "county": "Big Horn County",
    "state": "MT",
    "city": "Decker"
  },
  {
    "value": "59026",
    "label": "Edgar - MT 59026",
    "county": "Carbon County",
    "state": "MT",
    "city": "Edgar"
  },
  {
    "value": "59027",
    "label": "Emigrant - MT 59027",
    "county": "Park County",
    "state": "MT",
    "city": "Emigrant"
  },
  {
    "value": "59028",
    "label": "Fishtail - MT 59028",
    "county": "Stillwater County",
    "state": "MT",
    "city": "Fishtail"
  },
  {
    "value": "59029",
    "label": "Fromberg - MT 59029",
    "county": "Carbon County",
    "state": "MT",
    "city": "Fromberg"
  },
  {
    "value": "59030",
    "label": "Gardiner - MT 59030",
    "county": "Park County",
    "state": "MT",
    "city": "Gardiner"
  },
  {
    "value": "59031",
    "label": "Garryowen - MT 59031",
    "county": "Big Horn County",
    "state": "MT",
    "city": "Garryowen"
  },
  {
    "value": "59032",
    "label": "Grass Range - MT 59032",
    "county": "Fergus County",
    "state": "MT",
    "city": "Grass Range"
  },
  {
    "value": "59033",
    "label": "Greycliff - MT 59033",
    "county": "Sweet Grass County",
    "state": "MT",
    "city": "Greycliff"
  },
  {
    "value": "59034",
    "label": "Hardin - MT 59034",
    "county": "Big Horn County",
    "state": "MT",
    "city": "Hardin"
  },
  {
    "value": "59037",
    "label": "Huntley - MT 59037",
    "county": "Yellowstone County",
    "state": "MT",
    "city": "Huntley"
  },
  {
    "value": "59038",
    "label": "Hysham - MT 59038",
    "county": "Treasure County",
    "state": "MT",
    "city": "Hysham"
  },
  {
    "value": "59039",
    "label": "Ingomar - MT 59039",
    "county": "Rosebud County",
    "state": "MT",
    "city": "Ingomar"
  },
  {
    "value": "59041",
    "label": "Joliet - MT 59041",
    "county": "Carbon County",
    "state": "MT",
    "city": "Joliet"
  },
  {
    "value": "59044",
    "label": "Laurel - MT 59044",
    "county": "Yellowstone County",
    "state": "MT",
    "city": "Laurel"
  },
  {
    "value": "59046",
    "label": "Lavina - MT 59046",
    "county": "Golden Valley County",
    "state": "MT",
    "city": "Lavina"
  },
  {
    "value": "59047",
    "label": "Livingston - MT 59047",
    "county": "Park County",
    "state": "MT",
    "city": "Livingston"
  },
  {
    "value": "59050",
    "label": "Lodge Grass - MT 59050",
    "county": "Big Horn County",
    "state": "MT",
    "city": "Lodge Grass"
  },
  {
    "value": "59052",
    "label": "Mc Leod - MT 59052",
    "county": "Sweet Grass County",
    "state": "MT",
    "city": "Mc Leod"
  },
  {
    "value": "59053",
    "label": "Martinsdale - MT 59053",
    "county": "Meagher County",
    "state": "MT",
    "city": "Martinsdale"
  },
  {
    "value": "59055",
    "label": "Melville - MT 59055",
    "county": "Sweet Grass County",
    "state": "MT",
    "city": "Melville"
  },
  {
    "value": "59057",
    "label": "Molt - MT 59057",
    "county": "Yellowstone County",
    "state": "MT",
    "city": "Molt"
  },
  {
    "value": "59058",
    "label": "Mosby - MT 59058",
    "county": "Garfield County",
    "state": "MT",
    "city": "Mosby"
  },
  {
    "value": "59059",
    "label": "Musselshell - MT 59059",
    "county": "Musselshell County",
    "state": "MT",
    "city": "Musselshell"
  },
  {
    "value": "59061",
    "label": "Nye - MT 59061",
    "county": "Stillwater County",
    "state": "MT",
    "city": "Nye"
  },
  {
    "value": "59062",
    "label": "Otter - MT 59062",
    "county": "Powder River County",
    "state": "MT",
    "city": "Otter"
  },
  {
    "value": "59063",
    "label": "Park City - MT 59063",
    "county": "Stillwater County",
    "state": "MT",
    "city": "Park City"
  },
  {
    "value": "59064",
    "label": "Pompeys Pillar - MT 59064",
    "county": "Yellowstone County",
    "state": "MT",
    "city": "Pompeys Pillar"
  },
  {
    "value": "59065",
    "label": "Pray - MT 59065",
    "county": "Park County",
    "state": "MT",
    "city": "Pray"
  },
  {
    "value": "59067",
    "label": "Rapelje - MT 59067",
    "county": "Stillwater County",
    "state": "MT",
    "city": "Rapelje"
  },
  {
    "value": "59068",
    "label": "Red Lodge - MT 59068",
    "county": "Carbon County",
    "state": "MT",
    "city": "Red Lodge"
  },
  {
    "value": "59069",
    "label": "Reed Point - MT 59069",
    "county": "Stillwater County",
    "state": "MT",
    "city": "Reed Point"
  },
  {
    "value": "59070",
    "label": "Roberts - MT 59070",
    "county": "Carbon County",
    "state": "MT",
    "city": "Roberts"
  },
  {
    "value": "59071",
    "label": "Roscoe - MT 59071",
    "county": "Carbon County",
    "state": "MT",
    "city": "Roscoe"
  },
  {
    "value": "59072",
    "label": "Roundup - MT 59072",
    "county": "Musselshell County",
    "state": "MT",
    "city": "Roundup"
  },
  {
    "value": "59074",
    "label": "Ryegate - MT 59074",
    "county": "Golden Valley County",
    "state": "MT",
    "city": "Ryegate"
  },
  {
    "value": "59075",
    "label": "Saint Xavier - MT 59075",
    "county": "Big Horn County",
    "state": "MT",
    "city": "Saint Xavier"
  },
  {
    "value": "59076",
    "label": "Sanders - MT 59076",
    "county": "Treasure County",
    "state": "MT",
    "city": "Sanders"
  },
  {
    "value": "59077",
    "label": "Sand Springs - MT 59077",
    "county": "Garfield County",
    "state": "MT",
    "city": "Sand Springs"
  },
  {
    "value": "59078",
    "label": "Shawmut - MT 59078",
    "county": "Wheatland County",
    "state": "MT",
    "city": "Shawmut"
  },
  {
    "value": "59079",
    "label": "Shepherd - MT 59079",
    "county": "Yellowstone County",
    "state": "MT",
    "city": "Shepherd"
  },
  {
    "value": "59081",
    "label": "Silver Gate - MT 59081",
    "county": "Park County",
    "state": "MT",
    "city": "Silver Gate"
  },
  {
    "value": "59082",
    "label": "Springdale - MT 59082",
    "county": "Park County",
    "state": "MT",
    "city": "Springdale"
  },
  {
    "value": "59084",
    "label": "Teigen - MT 59084",
    "county": "Petroleum County",
    "state": "MT",
    "city": "Teigen"
  },
  {
    "value": "59085",
    "label": "Two Dot - MT 59085",
    "county": "Wheatland County",
    "state": "MT",
    "city": "Two Dot"
  },
  {
    "value": "59086",
    "label": "Wilsall - MT 59086",
    "county": "Park County",
    "state": "MT",
    "city": "Wilsall"
  },
  {
    "value": "59087",
    "label": "Winnett - MT 59087",
    "county": "Petroleum County",
    "state": "MT",
    "city": "Winnett"
  },
  {
    "value": "59088",
    "label": "Worden - MT 59088",
    "county": "Yellowstone County",
    "state": "MT",
    "city": "Worden"
  },
  {
    "value": "59089",
    "label": "Wyola - MT 59089",
    "county": "Big Horn County",
    "state": "MT",
    "city": "Wyola"
  },
  {
    "value": "59101",
    "label": "Billings - MT 59101",
    "county": "Yellowstone County",
    "state": "MT",
    "city": "Billings"
  },
  {
    "value": "59102",
    "label": "Billings - MT 59102",
    "county": "Yellowstone County",
    "state": "MT",
    "city": "Billings"
  },
  {
    "value": "59105",
    "label": "Billings - MT 59105",
    "county": "Yellowstone County",
    "state": "MT",
    "city": "Billings"
  },
  {
    "value": "59106",
    "label": "Billings - MT 59106",
    "county": "Yellowstone County",
    "state": "MT",
    "city": "Billings"
  },
  {
    "value": "59201",
    "label": "Wolf Point - MT 59201",
    "county": "McCone County",
    "state": "MT",
    "city": "Wolf Point"
  },
  {
    "value": "59211",
    "label": "Antelope - MT 59211",
    "county": "Sheridan County",
    "state": "MT",
    "city": "Antelope"
  },
  {
    "value": "59212",
    "label": "Bainville - MT 59212",
    "county": "Roosevelt County",
    "state": "MT",
    "city": "Bainville"
  },
  {
    "value": "59213",
    "label": "Brockton - MT 59213",
    "county": "Richland County",
    "state": "MT",
    "city": "Brockton"
  },
  {
    "value": "59214",
    "label": "Brockway - MT 59214",
    "county": "McCone County",
    "state": "MT",
    "city": "Brockway"
  },
  {
    "value": "59215",
    "label": "Circle - MT 59215",
    "county": "McCone County",
    "state": "MT",
    "city": "Circle"
  },
  {
    "value": "59218",
    "label": "Culbertson - MT 59218",
    "county": "Roosevelt County",
    "state": "MT",
    "city": "Culbertson"
  },
  {
    "value": "59219",
    "label": "Dagmar - MT 59219",
    "county": "Sheridan County",
    "state": "MT",
    "city": "Dagmar"
  },
  {
    "value": "59221",
    "label": "Fairview - MT 59221",
    "county": "Richland County",
    "state": "MT",
    "city": "Fairview"
  },
  {
    "value": "59222",
    "label": "Flaxville - MT 59222",
    "county": "Daniels County",
    "state": "MT",
    "city": "Flaxville"
  },
  {
    "value": "59223",
    "label": "Fort Peck - MT 59223",
    "county": "Valley County",
    "state": "MT",
    "city": "Fort Peck"
  },
  {
    "value": "59225",
    "label": "Frazer - MT 59225",
    "county": "Valley County",
    "state": "MT",
    "city": "Frazer"
  },
  {
    "value": "59226",
    "label": "Froid - MT 59226",
    "county": "Roosevelt County",
    "state": "MT",
    "city": "Froid"
  },
  {
    "value": "59230",
    "label": "Glasgow - MT 59230",
    "county": "Valley County",
    "state": "MT",
    "city": "Glasgow"
  },
  {
    "value": "59240",
    "label": "Glentana - MT 59240",
    "county": "Valley County",
    "state": "MT",
    "city": "Glentana"
  },
  {
    "value": "59241",
    "label": "Hinsdale - MT 59241",
    "county": "Valley County",
    "state": "MT",
    "city": "Hinsdale"
  },
  {
    "value": "59242",
    "label": "Homestead - MT 59242",
    "county": "Sheridan County",
    "state": "MT",
    "city": "Homestead"
  },
  {
    "value": "59243",
    "label": "Lambert - MT 59243",
    "county": "Richland County",
    "state": "MT",
    "city": "Lambert"
  },
  {
    "value": "59244",
    "label": "Larslan - MT 59244",
    "county": "Valley County",
    "state": "MT",
    "city": "Larslan"
  },
  {
    "value": "59247",
    "label": "Medicine Lake - MT 59247",
    "county": "Sheridan County",
    "state": "MT",
    "city": "Medicine Lake"
  },
  {
    "value": "59248",
    "label": "Nashua - MT 59248",
    "county": "Valley County",
    "state": "MT",
    "city": "Nashua"
  },
  {
    "value": "59250",
    "label": "Opheim - MT 59250",
    "county": "Valley County",
    "state": "MT",
    "city": "Opheim"
  },
  {
    "value": "59252",
    "label": "Outlook - MT 59252",
    "county": "Sheridan County",
    "state": "MT",
    "city": "Outlook"
  },
  {
    "value": "59253",
    "label": "Peerless - MT 59253",
    "county": "Daniels County",
    "state": "MT",
    "city": "Peerless"
  },
  {
    "value": "59254",
    "label": "Plentywood - MT 59254",
    "county": "Sheridan County",
    "state": "MT",
    "city": "Plentywood"
  },
  {
    "value": "59255",
    "label": "Poplar - MT 59255",
    "county": "Richland County",
    "state": "MT",
    "city": "Poplar"
  },
  {
    "value": "59257",
    "label": "Redstone - MT 59257",
    "county": "Sheridan County",
    "state": "MT",
    "city": "Redstone"
  },
  {
    "value": "59258",
    "label": "Reserve - MT 59258",
    "county": "Sheridan County",
    "state": "MT",
    "city": "Reserve"
  },
  {
    "value": "59259",
    "label": "Richey - MT 59259",
    "county": "Dawson County",
    "state": "MT",
    "city": "Richey"
  },
  {
    "value": "59260",
    "label": "Richland - MT 59260",
    "county": "Valley County",
    "state": "MT",
    "city": "Richland"
  },
  {
    "value": "59261",
    "label": "Saco - MT 59261",
    "county": "Phillips County",
    "state": "MT",
    "city": "Saco"
  },
  {
    "value": "59262",
    "label": "Savage - MT 59262",
    "county": "Richland County",
    "state": "MT",
    "city": "Savage"
  },
  {
    "value": "59263",
    "label": "Scobey - MT 59263",
    "county": "Daniels County",
    "state": "MT",
    "city": "Scobey"
  },
  {
    "value": "59270",
    "label": "Sidney - MT 59270",
    "county": "Richland County",
    "state": "MT",
    "city": "Sidney"
  },
  {
    "value": "59273",
    "label": "Vandalia - MT 59273",
    "county": "Valley County",
    "state": "MT",
    "city": "Vandalia"
  },
  {
    "value": "59274",
    "label": "Vida - MT 59274",
    "county": "McCone County",
    "state": "MT",
    "city": "Vida"
  },
  {
    "value": "59275",
    "label": "Westby - MT 59275",
    "county": "Sheridan County",
    "state": "MT",
    "city": "Westby"
  },
  {
    "value": "59276",
    "label": "Whitetail - MT 59276",
    "county": "Daniels County",
    "state": "MT",
    "city": "Whitetail"
  },
  {
    "value": "59301",
    "label": "Miles City - MT 59301",
    "county": "Custer County",
    "state": "MT",
    "city": "Miles City"
  },
  {
    "value": "59311",
    "label": "Alzada - MT 59311",
    "county": "Carter County",
    "state": "MT",
    "city": "Alzada"
  },
  {
    "value": "59312",
    "label": "Angela - MT 59312",
    "county": "Garfield County",
    "state": "MT",
    "city": "Angela"
  },
  {
    "value": "59313",
    "label": "Baker - MT 59313",
    "county": "Fallon County",
    "state": "MT",
    "city": "Baker"
  },
  {
    "value": "59314",
    "label": "Biddle - MT 59314",
    "county": "Powder River County",
    "state": "MT",
    "city": "Biddle"
  },
  {
    "value": "59315",
    "label": "Bloomfield - MT 59315",
    "county": "Dawson County",
    "state": "MT",
    "city": "Bloomfield"
  },
  {
    "value": "59316",
    "label": "Boyes - MT 59316",
    "county": "Carter County",
    "state": "MT",
    "city": "Boyes"
  },
  {
    "value": "59317",
    "label": "Broadus - MT 59317",
    "county": "Powder River County",
    "state": "MT",
    "city": "Broadus"
  },
  {
    "value": "59318",
    "label": "Brusett - MT 59318",
    "county": "Garfield County",
    "state": "MT",
    "city": "Brusett"
  },
  {
    "value": "59319",
    "label": "Capitol - MT 59319",
    "county": "Carter County",
    "state": "MT",
    "city": "Capitol"
  },
  {
    "value": "59322",
    "label": "Cohagen - MT 59322",
    "county": "Garfield County",
    "state": "MT",
    "city": "Cohagen"
  },
  {
    "value": "59324",
    "label": "Ekalaka - MT 59324",
    "county": "Carter County",
    "state": "MT",
    "city": "Ekalaka"
  },
  {
    "value": "59326",
    "label": "Fallon - MT 59326",
    "county": "Prairie County",
    "state": "MT",
    "city": "Fallon"
  },
  {
    "value": "59327",
    "label": "Forsyth - MT 59327",
    "county": "Rosebud County",
    "state": "MT",
    "city": "Forsyth"
  },
  {
    "value": "59330",
    "label": "Glendive - MT 59330",
    "county": "Dawson County",
    "state": "MT",
    "city": "Glendive"
  },
  {
    "value": "59332",
    "label": "Hammond - MT 59332",
    "county": "Carter County",
    "state": "MT",
    "city": "Hammond"
  },
  {
    "value": "59333",
    "label": "Hathaway - MT 59333",
    "county": "Rosebud County",
    "state": "MT",
    "city": "Hathaway"
  },
  {
    "value": "59336",
    "label": "Ismay - MT 59336",
    "county": "Custer County",
    "state": "MT",
    "city": "Ismay"
  },
  {
    "value": "59337",
    "label": "Jordan - MT 59337",
    "county": "Garfield County",
    "state": "MT",
    "city": "Jordan"
  },
  {
    "value": "59338",
    "label": "Kinsey - MT 59338",
    "county": "Custer County",
    "state": "MT",
    "city": "Kinsey"
  },
  {
    "value": "59339",
    "label": "Lindsay - MT 59339",
    "county": "Dawson County",
    "state": "MT",
    "city": "Lindsay"
  },
  {
    "value": "59341",
    "label": "Mildred - MT 59341",
    "county": "Prairie County",
    "state": "MT",
    "city": "Mildred"
  },
  {
    "value": "59343",
    "label": "Olive - MT 59343",
    "county": "Powder River County",
    "state": "MT",
    "city": "Olive"
  },
  {
    "value": "59344",
    "label": "Plevna - MT 59344",
    "county": "Fallon County",
    "state": "MT",
    "city": "Plevna"
  },
  {
    "value": "59345",
    "label": "Powderville - MT 59345",
    "county": "Powder River County",
    "state": "MT",
    "city": "Powderville"
  },
  {
    "value": "59347",
    "label": "Rosebud - MT 59347",
    "county": "Rosebud County",
    "state": "MT",
    "city": "Rosebud"
  },
  {
    "value": "59349",
    "label": "Terry - MT 59349",
    "county": "Prairie County",
    "state": "MT",
    "city": "Terry"
  },
  {
    "value": "59351",
    "label": "Volborg - MT 59351",
    "county": "Powder River County",
    "state": "MT",
    "city": "Volborg"
  },
  {
    "value": "59353",
    "label": "Wibaux - MT 59353",
    "county": "Wibaux County",
    "state": "MT",
    "city": "Wibaux"
  },
  {
    "value": "59401",
    "label": "Great Falls - MT 59401",
    "county": "Cascade County",
    "state": "MT",
    "city": "Great Falls"
  },
  {
    "value": "59402",
    "label": "Malmstrom AFB - MT 59402",
    "county": "Cascade County",
    "state": "MT",
    "city": "Malmstrom AFB"
  },
  {
    "value": "59404",
    "label": "Great Falls - MT 59404",
    "county": "Cascade County",
    "state": "MT",
    "city": "Great Falls"
  },
  {
    "value": "59405",
    "label": "Great Falls - MT 59405",
    "county": "Cascade County",
    "state": "MT",
    "city": "Great Falls"
  },
  {
    "value": "59412",
    "label": "Belt - MT 59412",
    "county": "Cascade County",
    "state": "MT",
    "city": "Belt"
  },
  {
    "value": "59414",
    "label": "Black Eagle - MT 59414",
    "county": "Cascade County",
    "state": "MT",
    "city": "Black Eagle"
  },
  {
    "value": "59416",
    "label": "Brady - MT 59416",
    "county": "Pondera County",
    "state": "MT",
    "city": "Brady"
  },
  {
    "value": "59417",
    "label": "Browning - MT 59417",
    "county": "Glacier County",
    "state": "MT",
    "city": "Browning"
  },
  {
    "value": "59418",
    "label": "Buffalo - MT 59418",
    "county": "Judith Basin County",
    "state": "MT",
    "city": "Buffalo"
  },
  {
    "value": "59419",
    "label": "Bynum - MT 59419",
    "county": "Teton County",
    "state": "MT",
    "city": "Bynum"
  },
  {
    "value": "59420",
    "label": "Carter - MT 59420",
    "county": "Chouteau County",
    "state": "MT",
    "city": "Carter"
  },
  {
    "value": "59421",
    "label": "Cascade - MT 59421",
    "county": "Cascade County",
    "state": "MT",
    "city": "Cascade"
  },
  {
    "value": "59422",
    "label": "Choteau - MT 59422",
    "county": "Teton County",
    "state": "MT",
    "city": "Choteau"
  },
  {
    "value": "59424",
    "label": "Coffee Creek - MT 59424",
    "county": "Fergus County",
    "state": "MT",
    "city": "Coffee Creek"
  },
  {
    "value": "59425",
    "label": "Conrad - MT 59425",
    "county": "Pondera County",
    "state": "MT",
    "city": "Conrad"
  },
  {
    "value": "59427",
    "label": "Cut Bank - MT 59427",
    "county": "Glacier County",
    "state": "MT",
    "city": "Cut Bank"
  },
  {
    "value": "59430",
    "label": "Denton - MT 59430",
    "county": "Fergus County",
    "state": "MT",
    "city": "Denton"
  },
  {
    "value": "59433",
    "label": "Dutton - MT 59433",
    "county": "Teton County",
    "state": "MT",
    "city": "Dutton"
  },
  {
    "value": "59436",
    "label": "Fairfield - MT 59436",
    "county": "Teton County",
    "state": "MT",
    "city": "Fairfield"
  },
  {
    "value": "59440",
    "label": "Floweree - MT 59440",
    "county": "Chouteau County",
    "state": "MT",
    "city": "Floweree"
  },
  {
    "value": "59441",
    "label": "Forest Grove - MT 59441",
    "county": "Fergus County",
    "state": "MT",
    "city": "Forest Grove"
  },
  {
    "value": "59442",
    "label": "Fort Benton - MT 59442",
    "county": "Chouteau County",
    "state": "MT",
    "city": "Fort Benton"
  },
  {
    "value": "59443",
    "label": "Fort Shaw - MT 59443",
    "county": "Cascade County",
    "state": "MT",
    "city": "Fort Shaw"
  },
  {
    "value": "59444",
    "label": "Galata - MT 59444",
    "county": "Liberty County",
    "state": "MT",
    "city": "Galata"
  },
  {
    "value": "59446",
    "label": "Geraldine - MT 59446",
    "county": "Chouteau County",
    "state": "MT",
    "city": "Geraldine"
  },
  {
    "value": "59447",
    "label": "Geyser - MT 59447",
    "county": "Judith Basin County",
    "state": "MT",
    "city": "Geyser"
  },
  {
    "value": "59450",
    "label": "Highwood - MT 59450",
    "county": "Chouteau County",
    "state": "MT",
    "city": "Highwood"
  },
  {
    "value": "59451",
    "label": "Hilger - MT 59451",
    "county": "Fergus County",
    "state": "MT",
    "city": "Hilger"
  },
  {
    "value": "59452",
    "label": "Hobson - MT 59452",
    "county": "Judith Basin County",
    "state": "MT",
    "city": "Hobson"
  },
  {
    "value": "59453",
    "label": "Judith Gap - MT 59453",
    "county": "Wheatland County",
    "state": "MT",
    "city": "Judith Gap"
  },
  {
    "value": "59454",
    "label": "Kevin - MT 59454",
    "county": "Toole County",
    "state": "MT",
    "city": "Kevin"
  },
  {
    "value": "59456",
    "label": "Ledger - MT 59456",
    "county": "Toole County",
    "state": "MT",
    "city": "Ledger"
  },
  {
    "value": "59457",
    "label": "Lewistown - MT 59457",
    "county": "Fergus County",
    "state": "MT",
    "city": "Lewistown"
  },
  {
    "value": "59460",
    "label": "Loma - MT 59460",
    "county": "Chouteau County",
    "state": "MT",
    "city": "Loma"
  },
  {
    "value": "59461",
    "label": "Lothair - MT 59461",
    "county": "Liberty County",
    "state": "MT",
    "city": "Lothair"
  },
  {
    "value": "59462",
    "label": "Moccasin - MT 59462",
    "county": "Judith Basin County",
    "state": "MT",
    "city": "Moccasin"
  },
  {
    "value": "59463",
    "label": "Monarch - MT 59463",
    "county": "Cascade County",
    "state": "MT",
    "city": "Monarch"
  },
  {
    "value": "59464",
    "label": "Moore - MT 59464",
    "county": "Fergus County",
    "state": "MT",
    "city": "Moore"
  },
  {
    "value": "59465",
    "label": "Neihart - MT 59465",
    "county": "Cascade County",
    "state": "MT",
    "city": "Neihart"
  },
  {
    "value": "59466",
    "label": "Oilmont - MT 59466",
    "county": "Toole County",
    "state": "MT",
    "city": "Oilmont"
  },
  {
    "value": "59468",
    "label": "Power - MT 59468",
    "county": "Teton County",
    "state": "MT",
    "city": "Power"
  },
  {
    "value": "59469",
    "label": "Raynesford - MT 59469",
    "county": "Judith Basin County",
    "state": "MT",
    "city": "Raynesford"
  },
  {
    "value": "59471",
    "label": "Roy - MT 59471",
    "county": "Fergus County",
    "state": "MT",
    "city": "Roy"
  },
  {
    "value": "59472",
    "label": "Sand Coulee - MT 59472",
    "county": "Cascade County",
    "state": "MT",
    "city": "Sand Coulee"
  },
  {
    "value": "59474",
    "label": "Shelby - MT 59474",
    "county": "Toole County",
    "state": "MT",
    "city": "Shelby"
  },
  {
    "value": "59479",
    "label": "Stanford - MT 59479",
    "county": "Judith Basin County",
    "state": "MT",
    "city": "Stanford"
  },
  {
    "value": "59480",
    "label": "Stockett - MT 59480",
    "county": "Cascade County",
    "state": "MT",
    "city": "Stockett"
  },
  {
    "value": "59482",
    "label": "Sunburst - MT 59482",
    "county": "Toole County",
    "state": "MT",
    "city": "Sunburst"
  },
  {
    "value": "59483",
    "label": "Sun River - MT 59483",
    "county": "Cascade County",
    "state": "MT",
    "city": "Sun River"
  },
  {
    "value": "59484",
    "label": "Sweet Grass - MT 59484",
    "county": "Toole County",
    "state": "MT",
    "city": "Sweet Grass"
  },
  {
    "value": "59486",
    "label": "Valier - MT 59486",
    "county": "Pondera County",
    "state": "MT",
    "city": "Valier"
  },
  {
    "value": "59487",
    "label": "Vaughn - MT 59487",
    "county": "Cascade County",
    "state": "MT",
    "city": "Vaughn"
  },
  {
    "value": "59489",
    "label": "Winifred - MT 59489",
    "county": "Fergus County",
    "state": "MT",
    "city": "Winifred"
  },
  {
    "value": "59501",
    "label": "Havre - MT 59501",
    "county": "Hill County",
    "state": "MT",
    "city": "Havre"
  },
  {
    "value": "59520",
    "label": "Big Sandy - MT 59520",
    "county": "Chouteau County",
    "state": "MT",
    "city": "Big Sandy"
  },
  {
    "value": "59521",
    "label": "Box Elder - MT 59521",
    "county": "Hill County",
    "state": "MT",
    "city": "Box Elder"
  },
  {
    "value": "59522",
    "label": "Chester - MT 59522",
    "county": "Liberty County",
    "state": "MT",
    "city": "Chester"
  },
  {
    "value": "59523",
    "label": "Chinook - MT 59523",
    "county": "Blaine County",
    "state": "MT",
    "city": "Chinook"
  },
  {
    "value": "59524",
    "label": "Dodson - MT 59524",
    "county": "Phillips County",
    "state": "MT",
    "city": "Dodson"
  },
  {
    "value": "59525",
    "label": "Gildford - MT 59525",
    "county": "Hill County",
    "state": "MT",
    "city": "Gildford"
  },
  {
    "value": "59526",
    "label": "Harlem - MT 59526",
    "county": "Blaine County",
    "state": "MT",
    "city": "Harlem"
  },
  {
    "value": "59528",
    "label": "Hingham - MT 59528",
    "county": "Hill County",
    "state": "MT",
    "city": "Hingham"
  },
  {
    "value": "59529",
    "label": "Hogeland - MT 59529",
    "county": "Blaine County",
    "state": "MT",
    "city": "Hogeland"
  },
  {
    "value": "59530",
    "label": "Inverness - MT 59530",
    "county": "Hill County",
    "state": "MT",
    "city": "Inverness"
  },
  {
    "value": "59531",
    "label": "Joplin - MT 59531",
    "county": "Liberty County",
    "state": "MT",
    "city": "Joplin"
  },
  {
    "value": "59532",
    "label": "Kremlin - MT 59532",
    "county": "Hill County",
    "state": "MT",
    "city": "Kremlin"
  },
  {
    "value": "59535",
    "label": "Lloyd - MT 59535",
    "county": "Blaine County",
    "state": "MT",
    "city": "Lloyd"
  },
  {
    "value": "59537",
    "label": "Loring - MT 59537",
    "county": "Phillips County",
    "state": "MT",
    "city": "Loring"
  },
  {
    "value": "59538",
    "label": "Malta - MT 59538",
    "county": "Phillips County",
    "state": "MT",
    "city": "Malta"
  },
  {
    "value": "59540",
    "label": "Rudyard - MT 59540",
    "county": "Hill County",
    "state": "MT",
    "city": "Rudyard"
  },
  {
    "value": "59542",
    "label": "Turner - MT 59542",
    "county": "Blaine County",
    "state": "MT",
    "city": "Turner"
  },
  {
    "value": "59544",
    "label": "Whitewater - MT 59544",
    "county": "Phillips County",
    "state": "MT",
    "city": "Whitewater"
  },
  {
    "value": "59545",
    "label": "Whitlash - MT 59545",
    "county": "Liberty County",
    "state": "MT",
    "city": "Whitlash"
  },
  {
    "value": "59546",
    "label": "Zortman - MT 59546",
    "county": "Phillips County",
    "state": "MT",
    "city": "Zortman"
  },
  {
    "value": "59547",
    "label": "Zurich - MT 59547",
    "county": "Blaine County",
    "state": "MT",
    "city": "Zurich"
  },
  {
    "value": "59601",
    "label": "Helena - MT 59601",
    "county": "Lewis and Clark County",
    "state": "MT",
    "city": "Helena"
  },
  {
    "value": "59602",
    "label": "Helena - MT 59602",
    "county": "Lewis and Clark County",
    "state": "MT",
    "city": "Helena"
  },
  {
    "value": "59626",
    "label": "Helena - MT 59626",
    "county": "Lewis and Clark County",
    "state": "MT",
    "city": "Helena"
  },
  {
    "value": "59632",
    "label": "Boulder - MT 59632",
    "county": "Jefferson County",
    "state": "MT",
    "city": "Boulder"
  },
  {
    "value": "59633",
    "label": "Canyon Creek - MT 59633",
    "county": "Lewis and Clark County",
    "state": "MT",
    "city": "Canyon Creek"
  },
  {
    "value": "59634",
    "label": "Clancy - MT 59634",
    "county": "Jefferson County",
    "state": "MT",
    "city": "Clancy"
  },
  {
    "value": "59635",
    "label": "East Helena - MT 59635",
    "county": "Lewis and Clark County",
    "state": "MT",
    "city": "East Helena"
  },
  {
    "value": "59639",
    "label": "Lincoln - MT 59639",
    "county": "Lewis and Clark County",
    "state": "MT",
    "city": "Lincoln"
  },
  {
    "value": "59640",
    "label": "Marysville - MT 59640",
    "county": "Lewis and Clark County",
    "state": "MT",
    "city": "Marysville"
  },
  {
    "value": "59641",
    "label": "Radersburg - MT 59641",
    "county": "Broadwater County",
    "state": "MT",
    "city": "Radersburg"
  },
  {
    "value": "59643",
    "label": "Toston - MT 59643",
    "county": "Broadwater County",
    "state": "MT",
    "city": "Toston"
  },
  {
    "value": "59644",
    "label": "Townsend - MT 59644",
    "county": "Broadwater County",
    "state": "MT",
    "city": "Townsend"
  },
  {
    "value": "59645",
    "label": "White Sulphur Springs - MT 59645",
    "county": "Meagher County",
    "state": "MT",
    "city": "White Sulphur Springs"
  },
  {
    "value": "59647",
    "label": "Winston - MT 59647",
    "county": "Broadwater County",
    "state": "MT",
    "city": "Winston"
  },
  {
    "value": "59648",
    "label": "Wolf Creek - MT 59648",
    "county": "Lewis and Clark County",
    "state": "MT",
    "city": "Wolf Creek"
  },
  {
    "value": "59701",
    "label": "Butte - MT 59701",
    "county": "Silver Bow County",
    "state": "MT",
    "city": "Butte"
  },
  {
    "value": "59711",
    "label": "Anaconda - MT 59711",
    "county": "Deer Lodge County",
    "state": "MT",
    "city": "Anaconda"
  },
  {
    "value": "59714",
    "label": "Belgrade - MT 59714",
    "county": "Gallatin County",
    "state": "MT",
    "city": "Belgrade"
  },
  {
    "value": "59715",
    "label": "Bozeman - MT 59715",
    "county": "Gallatin County",
    "state": "MT",
    "city": "Bozeman"
  },
  {
    "value": "59718",
    "label": "Bozeman - MT 59718",
    "county": "Gallatin County",
    "state": "MT",
    "city": "Bozeman"
  },
  {
    "value": "59720",
    "label": "Cameron - MT 59720",
    "county": "Madison County",
    "state": "MT",
    "city": "Cameron"
  },
  {
    "value": "59721",
    "label": "Cardwell - MT 59721",
    "county": "Madison County",
    "state": "MT",
    "city": "Cardwell"
  },
  {
    "value": "59722",
    "label": "Deer Lodge - MT 59722",
    "county": "Powell County",
    "state": "MT",
    "city": "Deer Lodge"
  },
  {
    "value": "59725",
    "label": "Dillon - MT 59725",
    "county": "Beaverhead County",
    "state": "MT",
    "city": "Dillon"
  },
  {
    "value": "59727",
    "label": "Divide - MT 59727",
    "county": "Silver Bow County",
    "state": "MT",
    "city": "Divide"
  },
  {
    "value": "59729",
    "label": "Ennis - MT 59729",
    "county": "Madison County",
    "state": "MT",
    "city": "Ennis"
  },
  {
    "value": "59730",
    "label": "Gallatin Gateway - MT 59730",
    "county": "Gallatin County",
    "state": "MT",
    "city": "Gallatin Gateway"
  },
  {
    "value": "59731",
    "label": "Garrison - MT 59731",
    "county": "Powell County",
    "state": "MT",
    "city": "Garrison"
  },
  {
    "value": "59732",
    "label": "Glen - MT 59732",
    "county": "Beaverhead County",
    "state": "MT",
    "city": "Glen"
  },
  {
    "value": "59733",
    "label": "Gold Creek - MT 59733",
    "county": "Powell County",
    "state": "MT",
    "city": "Gold Creek"
  },
  {
    "value": "59736",
    "label": "Jackson - MT 59736",
    "county": "Beaverhead County",
    "state": "MT",
    "city": "Jackson"
  },
  {
    "value": "59739",
    "label": "Lima - MT 59739",
    "county": "Beaverhead County",
    "state": "MT",
    "city": "Lima"
  },
  {
    "value": "59741",
    "label": "Manhattan - MT 59741",
    "county": "Gallatin County",
    "state": "MT",
    "city": "Manhattan"
  },
  {
    "value": "59745",
    "label": "Norris - MT 59745",
    "county": "Madison County",
    "state": "MT",
    "city": "Norris"
  },
  {
    "value": "59746",
    "label": "Polaris - MT 59746",
    "county": "Beaverhead County",
    "state": "MT",
    "city": "Polaris"
  },
  {
    "value": "59749",
    "label": "Sheridan - MT 59749",
    "county": "Madison County",
    "state": "MT",
    "city": "Sheridan"
  },
  {
    "value": "59750",
    "label": "Butte - MT 59750",
    "county": "Silver Bow County",
    "state": "MT",
    "city": "Butte"
  },
  {
    "value": "59751",
    "label": "Silver Star - MT 59751",
    "county": "Madison County",
    "state": "MT",
    "city": "Silver Star"
  },
  {
    "value": "59752",
    "label": "Three Forks - MT 59752",
    "county": "Gallatin County",
    "state": "MT",
    "city": "Three Forks"
  },
  {
    "value": "59754",
    "label": "Twin Bridges - MT 59754",
    "county": "Madison County",
    "state": "MT",
    "city": "Twin Bridges"
  },
  {
    "value": "59758",
    "label": "West Yellowstone - MT 59758",
    "county": "Gallatin County",
    "state": "MT",
    "city": "West Yellowstone"
  },
  {
    "value": "59759",
    "label": "Whitehall - MT 59759",
    "county": "Jefferson County",
    "state": "MT",
    "city": "Whitehall"
  },
  {
    "value": "59761",
    "label": "Wisdom - MT 59761",
    "county": "Beaverhead County",
    "state": "MT",
    "city": "Wisdom"
  },
  {
    "value": "59762",
    "label": "Wise River - MT 59762",
    "county": "Beaverhead County",
    "state": "MT",
    "city": "Wise River"
  },
  {
    "value": "59773",
    "label": "Bozeman - MT 59773",
    "county": "Gallatin County",
    "state": "MT",
    "city": "Bozeman"
  },
  {
    "value": "59801",
    "label": "Missoula - MT 59801",
    "county": "Missoula County",
    "state": "MT",
    "city": "Missoula"
  },
  {
    "value": "59802",
    "label": "Missoula - MT 59802",
    "county": "Missoula County",
    "state": "MT",
    "city": "Missoula"
  },
  {
    "value": "59803",
    "label": "Missoula - MT 59803",
    "county": "Missoula County",
    "state": "MT",
    "city": "Missoula"
  },
  {
    "value": "59804",
    "label": "Missoula - MT 59804",
    "county": "Missoula County",
    "state": "MT",
    "city": "Missoula"
  },
  {
    "value": "59808",
    "label": "Missoula - MT 59808",
    "county": "Missoula County",
    "state": "MT",
    "city": "Missoula"
  },
  {
    "value": "59820",
    "label": "Alberton - MT 59820",
    "county": "Mineral County",
    "state": "MT",
    "city": "Alberton"
  },
  {
    "value": "59821",
    "label": "Arlee - MT 59821",
    "county": "Lake County",
    "state": "MT",
    "city": "Arlee"
  },
  {
    "value": "59823",
    "label": "Bonner - MT 59823",
    "county": "Missoula County",
    "state": "MT",
    "city": "Bonner"
  },
  {
    "value": "59824",
    "label": "Charlo - MT 59824",
    "county": "Lake County",
    "state": "MT",
    "city": "Charlo"
  },
  {
    "value": "59825",
    "label": "Clinton - MT 59825",
    "county": "Missoula County",
    "state": "MT",
    "city": "Clinton"
  },
  {
    "value": "59826",
    "label": "Condon - MT 59826",
    "county": "Missoula County",
    "state": "MT",
    "city": "Condon"
  },
  {
    "value": "59827",
    "label": "Conner - MT 59827",
    "county": "Ravalli County",
    "state": "MT",
    "city": "Conner"
  },
  {
    "value": "59828",
    "label": "Corvallis - MT 59828",
    "county": "Ravalli County",
    "state": "MT",
    "city": "Corvallis"
  },
  {
    "value": "59829",
    "label": "Darby - MT 59829",
    "county": "Ravalli County",
    "state": "MT",
    "city": "Darby"
  },
  {
    "value": "59831",
    "label": "Dixon - MT 59831",
    "county": "Sanders County",
    "state": "MT",
    "city": "Dixon"
  },
  {
    "value": "59832",
    "label": "Drummond - MT 59832",
    "county": "Granite County",
    "state": "MT",
    "city": "Drummond"
  },
  {
    "value": "59833",
    "label": "Florence - MT 59833",
    "county": "Ravalli County",
    "state": "MT",
    "city": "Florence"
  },
  {
    "value": "59834",
    "label": "Frenchtown - MT 59834",
    "county": "Missoula County",
    "state": "MT",
    "city": "Frenchtown"
  },
  {
    "value": "59837",
    "label": "Hall - MT 59837",
    "county": "Granite County",
    "state": "MT",
    "city": "Hall"
  },
  {
    "value": "59840",
    "label": "Hamilton - MT 59840",
    "county": "Ravalli County",
    "state": "MT",
    "city": "Hamilton"
  },
  {
    "value": "59843",
    "label": "Helmville - MT 59843",
    "county": "Powell County",
    "state": "MT",
    "city": "Helmville"
  },
  {
    "value": "59844",
    "label": "Heron - MT 59844",
    "county": "Sanders County",
    "state": "MT",
    "city": "Heron"
  },
  {
    "value": "59845",
    "label": "Hot Springs - MT 59845",
    "county": "Flathead County",
    "state": "MT",
    "city": "Hot Springs"
  },
  {
    "value": "59846",
    "label": "Huson - MT 59846",
    "county": "Missoula County",
    "state": "MT",
    "city": "Huson"
  },
  {
    "value": "59847",
    "label": "Lolo - MT 59847",
    "county": "Missoula County",
    "state": "MT",
    "city": "Lolo"
  },
  {
    "value": "59848",
    "label": "Lonepine - MT 59848",
    "county": "Sanders County",
    "state": "MT",
    "city": "Lonepine"
  },
  {
    "value": "59853",
    "label": "Noxon - MT 59853",
    "county": "Sanders County",
    "state": "MT",
    "city": "Noxon"
  },
  {
    "value": "59854",
    "label": "Ovando - MT 59854",
    "county": "Powell County",
    "state": "MT",
    "city": "Ovando"
  },
  {
    "value": "59858",
    "label": "Philipsburg - MT 59858",
    "county": "Granite County",
    "state": "MT",
    "city": "Philipsburg"
  },
  {
    "value": "59859",
    "label": "Plains - MT 59859",
    "county": "Sanders County",
    "state": "MT",
    "city": "Plains"
  },
  {
    "value": "59860",
    "label": "Polson - MT 59860",
    "county": "Lake County",
    "state": "MT",
    "city": "Polson"
  },
  {
    "value": "59863",
    "label": "Ravalli - MT 59863",
    "county": "Lake County",
    "state": "MT",
    "city": "Ravalli"
  },
  {
    "value": "59864",
    "label": "Ronan - MT 59864",
    "county": "Lake County",
    "state": "MT",
    "city": "Ronan"
  },
  {
    "value": "59865",
    "label": "Saint Ignatius - MT 59865",
    "county": "Lake County",
    "state": "MT",
    "city": "Saint Ignatius"
  },
  {
    "value": "59866",
    "label": "Saint Regis - MT 59866",
    "county": "Mineral County",
    "state": "MT",
    "city": "Saint Regis"
  },
  {
    "value": "59867",
    "label": "Saltese - MT 59867",
    "county": "Mineral County",
    "state": "MT",
    "city": "Saltese"
  },
  {
    "value": "59868",
    "label": "Seeley Lake - MT 59868",
    "county": "Missoula County",
    "state": "MT",
    "city": "Seeley Lake"
  },
  {
    "value": "59870",
    "label": "Stevensville - MT 59870",
    "county": "Ravalli County",
    "state": "MT",
    "city": "Stevensville"
  },
  {
    "value": "59871",
    "label": "Sula - MT 59871",
    "county": "Ravalli County",
    "state": "MT",
    "city": "Sula"
  },
  {
    "value": "59872",
    "label": "Superior - MT 59872",
    "county": "Mineral County",
    "state": "MT",
    "city": "Superior"
  },
  {
    "value": "59873",
    "label": "Thompson Falls - MT 59873",
    "county": "Sanders County",
    "state": "MT",
    "city": "Thompson Falls"
  },
  {
    "value": "59874",
    "label": "Trout Creek - MT 59874",
    "county": "Sanders County",
    "state": "MT",
    "city": "Trout Creek"
  },
  {
    "value": "59875",
    "label": "Victor - MT 59875",
    "county": "Ravalli County",
    "state": "MT",
    "city": "Victor"
  },
  {
    "value": "59901",
    "label": "Kalispell - MT 59901",
    "county": "Flathead County",
    "state": "MT",
    "city": "Kalispell"
  },
  {
    "value": "59910",
    "label": "Big Arm - MT 59910",
    "county": "Lake County",
    "state": "MT",
    "city": "Big Arm"
  },
  {
    "value": "59911",
    "label": "Bigfork - MT 59911",
    "county": "Flathead County",
    "state": "MT",
    "city": "Bigfork"
  },
  {
    "value": "59912",
    "label": "Columbia Falls - MT 59912",
    "county": "Flathead County",
    "state": "MT",
    "city": "Columbia Falls"
  },
  {
    "value": "59914",
    "label": "Dayton - MT 59914",
    "county": "Lake County",
    "state": "MT",
    "city": "Dayton"
  },
  {
    "value": "59915",
    "label": "Elmo - MT 59915",
    "county": "Lake County",
    "state": "MT",
    "city": "Elmo"
  },
  {
    "value": "59916",
    "label": "Essex - MT 59916",
    "county": "Flathead County",
    "state": "MT",
    "city": "Essex"
  },
  {
    "value": "59917",
    "label": "Eureka - MT 59917",
    "county": "Lincoln County",
    "state": "MT",
    "city": "Eureka"
  },
  {
    "value": "59920",
    "label": "Kila - MT 59920",
    "county": "Flathead County",
    "state": "MT",
    "city": "Kila"
  },
  {
    "value": "59922",
    "label": "Lakeside - MT 59922",
    "county": "Flathead County",
    "state": "MT",
    "city": "Lakeside"
  },
  {
    "value": "59923",
    "label": "Libby - MT 59923",
    "county": "Lincoln County",
    "state": "MT",
    "city": "Libby"
  },
  {
    "value": "59925",
    "label": "Marion - MT 59925",
    "county": "Flathead County",
    "state": "MT",
    "city": "Marion"
  },
  {
    "value": "59926",
    "label": "Martin City - MT 59926",
    "county": "Flathead County",
    "state": "MT",
    "city": "Martin City"
  },
  {
    "value": "59928",
    "label": "Polebridge - MT 59928",
    "county": "Flathead County",
    "state": "MT",
    "city": "Polebridge"
  },
  {
    "value": "59929",
    "label": "Proctor - MT 59929",
    "county": "Lake County",
    "state": "MT",
    "city": "Proctor"
  },
  {
    "value": "59930",
    "label": "Rexford - MT 59930",
    "county": "Lincoln County",
    "state": "MT",
    "city": "Rexford"
  },
  {
    "value": "59931",
    "label": "Rollins - MT 59931",
    "county": "Lake County",
    "state": "MT",
    "city": "Rollins"
  },
  {
    "value": "59932",
    "label": "Somers - MT 59932",
    "county": "Flathead County",
    "state": "MT",
    "city": "Somers"
  },
  {
    "value": "59935",
    "label": "Troy - MT 59935",
    "county": "Lincoln County",
    "state": "MT",
    "city": "Troy"
  },
  {
    "value": "59937",
    "label": "Whitefish - MT 59937",
    "county": "Flathead County",
    "state": "MT",
    "city": "Whitefish"
  },
  {
    "value": "68001",
    "label": "Abie - NE 68001",
    "county": "Butler County",
    "state": "NE",
    "city": "Abie"
  },
  {
    "value": "68002",
    "label": "Arlington - NE 68002",
    "county": "Washington County",
    "state": "NE",
    "city": "Arlington"
  },
  {
    "value": "68003",
    "label": "Ashland - NE 68003",
    "county": "Saunders County",
    "state": "NE",
    "city": "Ashland"
  },
  {
    "value": "68004",
    "label": "Bancroft - NE 68004",
    "county": "Cuming County",
    "state": "NE",
    "city": "Bancroft"
  },
  {
    "value": "68005",
    "label": "Bellevue - NE 68005",
    "county": "Sarpy County",
    "state": "NE",
    "city": "Bellevue"
  },
  {
    "value": "68007",
    "label": "Bennington - NE 68007",
    "county": "Douglas County",
    "state": "NE",
    "city": "Bennington"
  },
  {
    "value": "68008",
    "label": "Blair - NE 68008",
    "county": "Washington County",
    "state": "NE",
    "city": "Blair"
  },
  {
    "value": "68014",
    "label": "Bruno - NE 68014",
    "county": "Butler County",
    "state": "NE",
    "city": "Bruno"
  },
  {
    "value": "68015",
    "label": "Cedar Bluffs - NE 68015",
    "county": "Saunders County",
    "state": "NE",
    "city": "Cedar Bluffs"
  },
  {
    "value": "68017",
    "label": "Ceresco - NE 68017",
    "county": "Saunders County",
    "state": "NE",
    "city": "Ceresco"
  },
  {
    "value": "68018",
    "label": "Colon - NE 68018",
    "county": "Saunders County",
    "state": "NE",
    "city": "Colon"
  },
  {
    "value": "68019",
    "label": "Craig - NE 68019",
    "county": "Burt County",
    "state": "NE",
    "city": "Craig"
  },
  {
    "value": "68020",
    "label": "Decatur - NE 68020",
    "county": "Burt County",
    "state": "NE",
    "city": "Decatur"
  },
  {
    "value": "68022",
    "label": "Elkhorn - NE 68022",
    "county": "Douglas County",
    "state": "NE",
    "city": "Elkhorn"
  },
  {
    "value": "68023",
    "label": "Fort Calhoun - NE 68023",
    "county": "Washington County",
    "state": "NE",
    "city": "Fort Calhoun"
  },
  {
    "value": "68025",
    "label": "Fremont - NE 68025",
    "county": "Dodge County",
    "state": "NE",
    "city": "Fremont"
  },
  {
    "value": "68028",
    "label": "Gretna - NE 68028",
    "county": "Sarpy County",
    "state": "NE",
    "city": "Gretna"
  },
  {
    "value": "68029",
    "label": "Herman - NE 68029",
    "county": "Washington County",
    "state": "NE",
    "city": "Herman"
  },
  {
    "value": "68030",
    "label": "Homer - NE 68030",
    "county": "Dakota County",
    "state": "NE",
    "city": "Homer"
  },
  {
    "value": "68031",
    "label": "Hooper - NE 68031",
    "county": "Dodge County",
    "state": "NE",
    "city": "Hooper"
  },
  {
    "value": "68033",
    "label": "Ithaca - NE 68033",
    "county": "Saunders County",
    "state": "NE",
    "city": "Ithaca"
  },
  {
    "value": "68034",
    "label": "Kennard - NE 68034",
    "county": "Washington County",
    "state": "NE",
    "city": "Kennard"
  },
  {
    "value": "68036",
    "label": "Linwood - NE 68036",
    "county": "Butler County",
    "state": "NE",
    "city": "Linwood"
  },
  {
    "value": "68037",
    "label": "Louisville - NE 68037",
    "county": "Cass County",
    "state": "NE",
    "city": "Louisville"
  },
  {
    "value": "68038",
    "label": "Lyons - NE 68038",
    "county": "Burt County",
    "state": "NE",
    "city": "Lyons"
  },
  {
    "value": "68039",
    "label": "Macy - NE 68039",
    "county": "Thurston County",
    "state": "NE",
    "city": "Macy"
  },
  {
    "value": "68040",
    "label": "Malmo - NE 68040",
    "county": "Saunders County",
    "state": "NE",
    "city": "Malmo"
  },
  {
    "value": "68041",
    "label": "Mead - NE 68041",
    "county": "Saunders County",
    "state": "NE",
    "city": "Mead"
  },
  {
    "value": "68042",
    "label": "Memphis - NE 68042",
    "county": "Saunders County",
    "state": "NE",
    "city": "Memphis"
  },
  {
    "value": "68044",
    "label": "Nickerson - NE 68044",
    "county": "Dodge County",
    "state": "NE",
    "city": "Nickerson"
  },
  {
    "value": "68045",
    "label": "Oakland - NE 68045",
    "county": "Burt County",
    "state": "NE",
    "city": "Oakland"
  },
  {
    "value": "68046",
    "label": "Papillion - NE 68046",
    "county": "Sarpy County",
    "state": "NE",
    "city": "Papillion"
  },
  {
    "value": "68047",
    "label": "Pender - NE 68047",
    "county": "Wayne County",
    "state": "NE",
    "city": "Pender"
  },
  {
    "value": "68048",
    "label": "Plattsmouth - NE 68048",
    "county": "Cass County",
    "state": "NE",
    "city": "Plattsmouth"
  },
  {
    "value": "68050",
    "label": "Prague - NE 68050",
    "county": "Saunders County",
    "state": "NE",
    "city": "Prague"
  },
  {
    "value": "68055",
    "label": "Rosalie - NE 68055",
    "county": "Thurston County",
    "state": "NE",
    "city": "Rosalie"
  },
  {
    "value": "68056",
    "label": "St Columbans - NE 68056",
    "county": "Sarpy County",
    "state": "NE",
    "city": "St Columbans"
  },
  {
    "value": "68057",
    "label": "Scribner - NE 68057",
    "county": "Dodge County",
    "state": "NE",
    "city": "Scribner"
  },
  {
    "value": "68059",
    "label": "Springfield - NE 68059",
    "county": "Sarpy County",
    "state": "NE",
    "city": "Springfield"
  },
  {
    "value": "68061",
    "label": "Tekamah - NE 68061",
    "county": "Burt County",
    "state": "NE",
    "city": "Tekamah"
  },
  {
    "value": "68062",
    "label": "Thurston - NE 68062",
    "county": "Thurston County",
    "state": "NE",
    "city": "Thurston"
  },
  {
    "value": "68064",
    "label": "Valley - NE 68064",
    "county": "Douglas County",
    "state": "NE",
    "city": "Valley"
  },
  {
    "value": "68065",
    "label": "Valparaiso - NE 68065",
    "county": "Saunders County",
    "state": "NE",
    "city": "Valparaiso"
  },
  {
    "value": "68066",
    "label": "Wahoo - NE 68066",
    "county": "Saunders County",
    "state": "NE",
    "city": "Wahoo"
  },
  {
    "value": "68067",
    "label": "Walthill - NE 68067",
    "county": "Thurston County",
    "state": "NE",
    "city": "Walthill"
  },
  {
    "value": "68068",
    "label": "Washington - NE 68068",
    "county": "Washington County",
    "state": "NE",
    "city": "Washington"
  },
  {
    "value": "68069",
    "label": "Waterloo - NE 68069",
    "county": "Douglas County",
    "state": "NE",
    "city": "Waterloo"
  },
  {
    "value": "68070",
    "label": "Weston - NE 68070",
    "county": "Saunders County",
    "state": "NE",
    "city": "Weston"
  },
  {
    "value": "68071",
    "label": "Winnebago - NE 68071",
    "county": "Thurston County",
    "state": "NE",
    "city": "Winnebago"
  },
  {
    "value": "68073",
    "label": "Yutan - NE 68073",
    "county": "Saunders County",
    "state": "NE",
    "city": "Yutan"
  },
  {
    "value": "68102",
    "label": "Omaha - NE 68102",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68104",
    "label": "Omaha - NE 68104",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68105",
    "label": "Omaha - NE 68105",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68106",
    "label": "Omaha - NE 68106",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68107",
    "label": "Omaha - NE 68107",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68108",
    "label": "Omaha - NE 68108",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68110",
    "label": "Omaha - NE 68110",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68111",
    "label": "Omaha - NE 68111",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68112",
    "label": "Omaha - NE 68112",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68113",
    "label": "Offutt AFB - NE 68113",
    "county": "Sarpy County",
    "state": "NE",
    "city": "Offutt AFB"
  },
  {
    "value": "68114",
    "label": "Omaha - NE 68114",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68116",
    "label": "Omaha - NE 68116",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68117",
    "label": "Omaha - NE 68117",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68118",
    "label": "Omaha - NE 68118",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68122",
    "label": "Omaha - NE 68122",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68123",
    "label": "Bellevue - NE 68123",
    "county": "Sarpy County",
    "state": "NE",
    "city": "Bellevue"
  },
  {
    "value": "68124",
    "label": "Omaha - NE 68124",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68127",
    "label": "Omaha - NE 68127",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68128",
    "label": "La Vista - NE 68128",
    "county": "Sarpy County",
    "state": "NE",
    "city": "La Vista"
  },
  {
    "value": "68130",
    "label": "Omaha - NE 68130",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68131",
    "label": "Omaha - NE 68131",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68132",
    "label": "Omaha - NE 68132",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68133",
    "label": "Papillion - NE 68133",
    "county": "Sarpy County",
    "state": "NE",
    "city": "Papillion"
  },
  {
    "value": "68134",
    "label": "Omaha - NE 68134",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68135",
    "label": "Omaha - NE 68135",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68136",
    "label": "Omaha - NE 68136",
    "county": "Sarpy County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68137",
    "label": "Omaha - NE 68137",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68138",
    "label": "Omaha - NE 68138",
    "county": "Sarpy County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68142",
    "label": "Omaha - NE 68142",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68144",
    "label": "Omaha - NE 68144",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68147",
    "label": "Bellevue - NE 68147",
    "county": "Sarpy County",
    "state": "NE",
    "city": "Bellevue"
  },
  {
    "value": "68152",
    "label": "Omaha - NE 68152",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68154",
    "label": "Omaha - NE 68154",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68157",
    "label": "Omaha - NE 68157",
    "county": "Sarpy County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68164",
    "label": "Omaha - NE 68164",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68176",
    "label": "Omaha - NE 68176",
    "county": "Douglas County",
    "state": "NE",
    "city": "Omaha"
  },
  {
    "value": "68301",
    "label": "Adams - NE 68301",
    "county": "Gage County",
    "state": "NE",
    "city": "Adams"
  },
  {
    "value": "68303",
    "label": "Alexandria - NE 68303",
    "county": "Thayer County",
    "state": "NE",
    "city": "Alexandria"
  },
  {
    "value": "68304",
    "label": "Alvo - NE 68304",
    "county": "Cass County",
    "state": "NE",
    "city": "Alvo"
  },
  {
    "value": "68305",
    "label": "Auburn - NE 68305",
    "county": "Nemaha County",
    "state": "NE",
    "city": "Auburn"
  },
  {
    "value": "68307",
    "label": "Avoca - NE 68307",
    "county": "Cass County",
    "state": "NE",
    "city": "Avoca"
  },
  {
    "value": "68309",
    "label": "Barneston - NE 68309",
    "county": "Gage County",
    "state": "NE",
    "city": "Barneston"
  },
  {
    "value": "68310",
    "label": "Beatrice - NE 68310",
    "county": "Gage County",
    "state": "NE",
    "city": "Beatrice"
  },
  {
    "value": "68313",
    "label": "Beaver Crossing - NE 68313",
    "county": "Seward County",
    "state": "NE",
    "city": "Beaver Crossing"
  },
  {
    "value": "68314",
    "label": "Bee - NE 68314",
    "county": "Seward County",
    "state": "NE",
    "city": "Bee"
  },
  {
    "value": "68315",
    "label": "Belvidere - NE 68315",
    "county": "Thayer County",
    "state": "NE",
    "city": "Belvidere"
  },
  {
    "value": "68316",
    "label": "Benedict - NE 68316",
    "county": "York County",
    "state": "NE",
    "city": "Benedict"
  },
  {
    "value": "68317",
    "label": "Bennet - NE 68317",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Bennet"
  },
  {
    "value": "68318",
    "label": "Blue Springs - NE 68318",
    "county": "Gage County",
    "state": "NE",
    "city": "Blue Springs"
  },
  {
    "value": "68319",
    "label": "Bradshaw - NE 68319",
    "county": "York County",
    "state": "NE",
    "city": "Bradshaw"
  },
  {
    "value": "68320",
    "label": "Brock - NE 68320",
    "county": "Nemaha County",
    "state": "NE",
    "city": "Brock"
  },
  {
    "value": "68321",
    "label": "Brownville - NE 68321",
    "county": "Nemaha County",
    "state": "NE",
    "city": "Brownville"
  },
  {
    "value": "68322",
    "label": "Bruning - NE 68322",
    "county": "Thayer County",
    "state": "NE",
    "city": "Bruning"
  },
  {
    "value": "68323",
    "label": "Burchard - NE 68323",
    "county": "Pawnee County",
    "state": "NE",
    "city": "Burchard"
  },
  {
    "value": "68324",
    "label": "Burr - NE 68324",
    "county": "Otoe County",
    "state": "NE",
    "city": "Burr"
  },
  {
    "value": "68325",
    "label": "Byron - NE 68325",
    "county": "Thayer County",
    "state": "NE",
    "city": "Byron"
  },
  {
    "value": "68326",
    "label": "Carleton - NE 68326",
    "county": "Thayer County",
    "state": "NE",
    "city": "Carleton"
  },
  {
    "value": "68327",
    "label": "Chester - NE 68327",
    "county": "Thayer County",
    "state": "NE",
    "city": "Chester"
  },
  {
    "value": "68328",
    "label": "Clatonia - NE 68328",
    "county": "Gage County",
    "state": "NE",
    "city": "Clatonia"
  },
  {
    "value": "68329",
    "label": "Cook - NE 68329",
    "county": "Johnson County",
    "state": "NE",
    "city": "Cook"
  },
  {
    "value": "68331",
    "label": "Cortland - NE 68331",
    "county": "Gage County",
    "state": "NE",
    "city": "Cortland"
  },
  {
    "value": "68332",
    "label": "Crab Orchard - NE 68332",
    "county": "Johnson County",
    "state": "NE",
    "city": "Crab Orchard"
  },
  {
    "value": "68333",
    "label": "Crete - NE 68333",
    "county": "Saline County",
    "state": "NE",
    "city": "Crete"
  },
  {
    "value": "68335",
    "label": "Davenport - NE 68335",
    "county": "Thayer County",
    "state": "NE",
    "city": "Davenport"
  },
  {
    "value": "68336",
    "label": "Davey - NE 68336",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Davey"
  },
  {
    "value": "68337",
    "label": "Dawson - NE 68337",
    "county": "Richardson County",
    "state": "NE",
    "city": "Dawson"
  },
  {
    "value": "68338",
    "label": "Daykin - NE 68338",
    "county": "Jefferson County",
    "state": "NE",
    "city": "Daykin"
  },
  {
    "value": "68339",
    "label": "Denton - NE 68339",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Denton"
  },
  {
    "value": "68340",
    "label": "Deshler - NE 68340",
    "county": "Thayer County",
    "state": "NE",
    "city": "Deshler"
  },
  {
    "value": "68341",
    "label": "De Witt - NE 68341",
    "county": "Saline County",
    "state": "NE",
    "city": "De Witt"
  },
  {
    "value": "68342",
    "label": "Diller - NE 68342",
    "county": "Jefferson County",
    "state": "NE",
    "city": "Diller"
  },
  {
    "value": "68343",
    "label": "Dorchester - NE 68343",
    "county": "Saline County",
    "state": "NE",
    "city": "Dorchester"
  },
  {
    "value": "68344",
    "label": "Douglas - NE 68344",
    "county": "Otoe County",
    "state": "NE",
    "city": "Douglas"
  },
  {
    "value": "68345",
    "label": "Du Bois - NE 68345",
    "county": "Pawnee County",
    "state": "NE",
    "city": "Du Bois"
  },
  {
    "value": "68346",
    "label": "Dunbar - NE 68346",
    "county": "Otoe County",
    "state": "NE",
    "city": "Dunbar"
  },
  {
    "value": "68347",
    "label": "Eagle - NE 68347",
    "county": "Cass County",
    "state": "NE",
    "city": "Eagle"
  },
  {
    "value": "68348",
    "label": "Elk Creek - NE 68348",
    "county": "Johnson County",
    "state": "NE",
    "city": "Elk Creek"
  },
  {
    "value": "68349",
    "label": "Elmwood - NE 68349",
    "county": "Cass County",
    "state": "NE",
    "city": "Elmwood"
  },
  {
    "value": "68350",
    "label": "Endicott - NE 68350",
    "county": "Jefferson County",
    "state": "NE",
    "city": "Endicott"
  },
  {
    "value": "68351",
    "label": "Exeter - NE 68351",
    "county": "Fillmore County",
    "state": "NE",
    "city": "Exeter"
  },
  {
    "value": "68352",
    "label": "Fairbury - NE 68352",
    "county": "Jefferson County",
    "state": "NE",
    "city": "Fairbury"
  },
  {
    "value": "68354",
    "label": "Fairmont - NE 68354",
    "county": "Fillmore County",
    "state": "NE",
    "city": "Fairmont"
  },
  {
    "value": "68355",
    "label": "Falls City - NE 68355",
    "county": "Richardson County",
    "state": "NE",
    "city": "Falls City"
  },
  {
    "value": "68357",
    "label": "Filley - NE 68357",
    "county": "Gage County",
    "state": "NE",
    "city": "Filley"
  },
  {
    "value": "68358",
    "label": "Firth - NE 68358",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Firth"
  },
  {
    "value": "68359",
    "label": "Friend - NE 68359",
    "county": "Saline County",
    "state": "NE",
    "city": "Friend"
  },
  {
    "value": "68360",
    "label": "Garland - NE 68360",
    "county": "Seward County",
    "state": "NE",
    "city": "Garland"
  },
  {
    "value": "68361",
    "label": "Geneva - NE 68361",
    "county": "Fillmore County",
    "state": "NE",
    "city": "Geneva"
  },
  {
    "value": "68365",
    "label": "Grafton - NE 68365",
    "county": "Fillmore County",
    "state": "NE",
    "city": "Grafton"
  },
  {
    "value": "68366",
    "label": "Greenwood - NE 68366",
    "county": "Cass County",
    "state": "NE",
    "city": "Greenwood"
  },
  {
    "value": "68367",
    "label": "Gresham - NE 68367",
    "county": "York County",
    "state": "NE",
    "city": "Gresham"
  },
  {
    "value": "68368",
    "label": "Hallam - NE 68368",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Hallam"
  },
  {
    "value": "68370",
    "label": "Hebron - NE 68370",
    "county": "Thayer County",
    "state": "NE",
    "city": "Hebron"
  },
  {
    "value": "68371",
    "label": "Henderson - NE 68371",
    "county": "York County",
    "state": "NE",
    "city": "Henderson"
  },
  {
    "value": "68372",
    "label": "Hickman - NE 68372",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Hickman"
  },
  {
    "value": "68375",
    "label": "Hubbell - NE 68375",
    "county": "Thayer County",
    "state": "NE",
    "city": "Hubbell"
  },
  {
    "value": "68376",
    "label": "Humboldt - NE 68376",
    "county": "Richardson County",
    "state": "NE",
    "city": "Humboldt"
  },
  {
    "value": "68377",
    "label": "Jansen - NE 68377",
    "county": "Jefferson County",
    "state": "NE",
    "city": "Jansen"
  },
  {
    "value": "68378",
    "label": "Johnson - NE 68378",
    "county": "Nemaha County",
    "state": "NE",
    "city": "Johnson"
  },
  {
    "value": "68379",
    "label": "Julian - NE 68379",
    "county": "Nemaha County",
    "state": "NE",
    "city": "Julian"
  },
  {
    "value": "68380",
    "label": "Lewiston - NE 68380",
    "county": "Pawnee County",
    "state": "NE",
    "city": "Lewiston"
  },
  {
    "value": "68381",
    "label": "Liberty - NE 68381",
    "county": "Gage County",
    "state": "NE",
    "city": "Liberty"
  },
  {
    "value": "68382",
    "label": "Lorton - NE 68382",
    "county": "Otoe County",
    "state": "NE",
    "city": "Lorton"
  },
  {
    "value": "68401",
    "label": "Mc Cool Junction - NE 68401",
    "county": "York County",
    "state": "NE",
    "city": "Mc Cool Junction"
  },
  {
    "value": "68402",
    "label": "Malcolm - NE 68402",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Malcolm"
  },
  {
    "value": "68404",
    "label": "Martell - NE 68404",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Martell"
  },
  {
    "value": "68405",
    "label": "Milford - NE 68405",
    "county": "Seward County",
    "state": "NE",
    "city": "Milford"
  },
  {
    "value": "68406",
    "label": "Milligan - NE 68406",
    "county": "Fillmore County",
    "state": "NE",
    "city": "Milligan"
  },
  {
    "value": "68407",
    "label": "Murdock - NE 68407",
    "county": "Cass County",
    "state": "NE",
    "city": "Murdock"
  },
  {
    "value": "68409",
    "label": "Murray - NE 68409",
    "county": "Cass County",
    "state": "NE",
    "city": "Murray"
  },
  {
    "value": "68410",
    "label": "Nebraska City - NE 68410",
    "county": "Otoe County",
    "state": "NE",
    "city": "Nebraska City"
  },
  {
    "value": "68413",
    "label": "Nehawka - NE 68413",
    "county": "Cass County",
    "state": "NE",
    "city": "Nehawka"
  },
  {
    "value": "68414",
    "label": "Nemaha - NE 68414",
    "county": "Nemaha County",
    "state": "NE",
    "city": "Nemaha"
  },
  {
    "value": "68415",
    "label": "Odell - NE 68415",
    "county": "Gage County",
    "state": "NE",
    "city": "Odell"
  },
  {
    "value": "68416",
    "label": "Ohiowa - NE 68416",
    "county": "Fillmore County",
    "state": "NE",
    "city": "Ohiowa"
  },
  {
    "value": "68417",
    "label": "Otoe - NE 68417",
    "county": "Otoe County",
    "state": "NE",
    "city": "Otoe"
  },
  {
    "value": "68418",
    "label": "Palmyra - NE 68418",
    "county": "Otoe County",
    "state": "NE",
    "city": "Palmyra"
  },
  {
    "value": "68420",
    "label": "Pawnee City - NE 68420",
    "county": "Pawnee County",
    "state": "NE",
    "city": "Pawnee City"
  },
  {
    "value": "68421",
    "label": "Peru - NE 68421",
    "county": "Nemaha County",
    "state": "NE",
    "city": "Peru"
  },
  {
    "value": "68422",
    "label": "Pickrell - NE 68422",
    "county": "Gage County",
    "state": "NE",
    "city": "Pickrell"
  },
  {
    "value": "68423",
    "label": "Pleasant Dale - NE 68423",
    "county": "Seward County",
    "state": "NE",
    "city": "Pleasant Dale"
  },
  {
    "value": "68424",
    "label": "Plymouth - NE 68424",
    "county": "Jefferson County",
    "state": "NE",
    "city": "Plymouth"
  },
  {
    "value": "68428",
    "label": "Raymond - NE 68428",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Raymond"
  },
  {
    "value": "68429",
    "label": "Reynolds - NE 68429",
    "county": "Jefferson County",
    "state": "NE",
    "city": "Reynolds"
  },
  {
    "value": "68430",
    "label": "Roca - NE 68430",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Roca"
  },
  {
    "value": "68431",
    "label": "Rulo - NE 68431",
    "county": "Richardson County",
    "state": "NE",
    "city": "Rulo"
  },
  {
    "value": "68433",
    "label": "Salem - NE 68433",
    "county": "Richardson County",
    "state": "NE",
    "city": "Salem"
  },
  {
    "value": "68434",
    "label": "Seward - NE 68434",
    "county": "Seward County",
    "state": "NE",
    "city": "Seward"
  },
  {
    "value": "68436",
    "label": "Shickley - NE 68436",
    "county": "Fillmore County",
    "state": "NE",
    "city": "Shickley"
  },
  {
    "value": "68437",
    "label": "Shubert - NE 68437",
    "county": "Richardson County",
    "state": "NE",
    "city": "Shubert"
  },
  {
    "value": "68439",
    "label": "Staplehurst - NE 68439",
    "county": "Seward County",
    "state": "NE",
    "city": "Staplehurst"
  },
  {
    "value": "68440",
    "label": "Steele City - NE 68440",
    "county": "Jefferson County",
    "state": "NE",
    "city": "Steele City"
  },
  {
    "value": "68441",
    "label": "Steinauer - NE 68441",
    "county": "Pawnee County",
    "state": "NE",
    "city": "Steinauer"
  },
  {
    "value": "68442",
    "label": "Stella - NE 68442",
    "county": "Richardson County",
    "state": "NE",
    "city": "Stella"
  },
  {
    "value": "68443",
    "label": "Sterling - NE 68443",
    "county": "Johnson County",
    "state": "NE",
    "city": "Sterling"
  },
  {
    "value": "68444",
    "label": "Strang - NE 68444",
    "county": "Fillmore County",
    "state": "NE",
    "city": "Strang"
  },
  {
    "value": "68445",
    "label": "Swanton - NE 68445",
    "county": "Saline County",
    "state": "NE",
    "city": "Swanton"
  },
  {
    "value": "68446",
    "label": "Syracuse - NE 68446",
    "county": "Otoe County",
    "state": "NE",
    "city": "Syracuse"
  },
  {
    "value": "68447",
    "label": "Table Rock - NE 68447",
    "county": "Pawnee County",
    "state": "NE",
    "city": "Table Rock"
  },
  {
    "value": "68448",
    "label": "Talmage - NE 68448",
    "county": "Otoe County",
    "state": "NE",
    "city": "Talmage"
  },
  {
    "value": "68450",
    "label": "Tecumseh - NE 68450",
    "county": "Johnson County",
    "state": "NE",
    "city": "Tecumseh"
  },
  {
    "value": "68452",
    "label": "Ong - NE 68452",
    "county": "Clay County",
    "state": "NE",
    "city": "Ong"
  },
  {
    "value": "68453",
    "label": "Tobias - NE 68453",
    "county": "Saline County",
    "state": "NE",
    "city": "Tobias"
  },
  {
    "value": "68454",
    "label": "Unadilla - NE 68454",
    "county": "Otoe County",
    "state": "NE",
    "city": "Unadilla"
  },
  {
    "value": "68455",
    "label": "Union - NE 68455",
    "county": "Cass County",
    "state": "NE",
    "city": "Union"
  },
  {
    "value": "68456",
    "label": "Utica - NE 68456",
    "county": "Seward County",
    "state": "NE",
    "city": "Utica"
  },
  {
    "value": "68457",
    "label": "Verdon - NE 68457",
    "county": "Richardson County",
    "state": "NE",
    "city": "Verdon"
  },
  {
    "value": "68458",
    "label": "Virginia - NE 68458",
    "county": "Gage County",
    "state": "NE",
    "city": "Virginia"
  },
  {
    "value": "68460",
    "label": "Waco - NE 68460",
    "county": "York County",
    "state": "NE",
    "city": "Waco"
  },
  {
    "value": "68461",
    "label": "Walton - NE 68461",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Walton"
  },
  {
    "value": "68462",
    "label": "Waverly - NE 68462",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Waverly"
  },
  {
    "value": "68463",
    "label": "Weeping Water - NE 68463",
    "county": "Cass County",
    "state": "NE",
    "city": "Weeping Water"
  },
  {
    "value": "68464",
    "label": "Western - NE 68464",
    "county": "Saline County",
    "state": "NE",
    "city": "Western"
  },
  {
    "value": "68465",
    "label": "Wilber - NE 68465",
    "county": "Saline County",
    "state": "NE",
    "city": "Wilber"
  },
  {
    "value": "68466",
    "label": "Wymore - NE 68466",
    "county": "Gage County",
    "state": "NE",
    "city": "Wymore"
  },
  {
    "value": "68467",
    "label": "York - NE 68467",
    "county": "York County",
    "state": "NE",
    "city": "York"
  },
  {
    "value": "68502",
    "label": "Lincoln - NE 68502",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Lincoln"
  },
  {
    "value": "68503",
    "label": "Lincoln - NE 68503",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Lincoln"
  },
  {
    "value": "68504",
    "label": "Lincoln - NE 68504",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Lincoln"
  },
  {
    "value": "68505",
    "label": "Lincoln - NE 68505",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Lincoln"
  },
  {
    "value": "68506",
    "label": "Lincoln - NE 68506",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Lincoln"
  },
  {
    "value": "68507",
    "label": "Lincoln - NE 68507",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Lincoln"
  },
  {
    "value": "68508",
    "label": "Lincoln - NE 68508",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Lincoln"
  },
  {
    "value": "68510",
    "label": "Lincoln - NE 68510",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Lincoln"
  },
  {
    "value": "68512",
    "label": "Lincoln - NE 68512",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Lincoln"
  },
  {
    "value": "68514",
    "label": "Lincoln - NE 68514",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Lincoln"
  },
  {
    "value": "68516",
    "label": "Lincoln - NE 68516",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Lincoln"
  },
  {
    "value": "68517",
    "label": "Lincoln - NE 68517",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Lincoln"
  },
  {
    "value": "68520",
    "label": "Lincoln - NE 68520",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Lincoln"
  },
  {
    "value": "68521",
    "label": "Lincoln - NE 68521",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Lincoln"
  },
  {
    "value": "68522",
    "label": "Lincoln - NE 68522",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Lincoln"
  },
  {
    "value": "68523",
    "label": "Lincoln - NE 68523",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Lincoln"
  },
  {
    "value": "68524",
    "label": "Lincoln - NE 68524",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Lincoln"
  },
  {
    "value": "68526",
    "label": "Lincoln - NE 68526",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Lincoln"
  },
  {
    "value": "68527",
    "label": "Lincoln - NE 68527",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Lincoln"
  },
  {
    "value": "68528",
    "label": "Lincoln - NE 68528",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Lincoln"
  },
  {
    "value": "68531",
    "label": "Lincoln - NE 68531",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Lincoln"
  },
  {
    "value": "68532",
    "label": "Lincoln - NE 68532",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Lincoln"
  },
  {
    "value": "68588",
    "label": "Lincoln - NE 68588",
    "county": "Lancaster County",
    "state": "NE",
    "city": "Lincoln"
  },
  {
    "value": "68601",
    "label": "Columbus - NE 68601",
    "county": "Platte County",
    "state": "NE",
    "city": "Columbus"
  },
  {
    "value": "68620",
    "label": "Albion - NE 68620",
    "county": "Boone County",
    "state": "NE",
    "city": "Albion"
  },
  {
    "value": "68621",
    "label": "Ames - NE 68621",
    "county": "Dodge County",
    "state": "NE",
    "city": "Ames"
  },
  {
    "value": "68622",
    "label": "Bartlett - NE 68622",
    "county": "Wheeler County",
    "state": "NE",
    "city": "Bartlett"
  },
  {
    "value": "68623",
    "label": "Belgrade - NE 68623",
    "county": "Nance County",
    "state": "NE",
    "city": "Belgrade"
  },
  {
    "value": "68624",
    "label": "Bellwood - NE 68624",
    "county": "Butler County",
    "state": "NE",
    "city": "Bellwood"
  },
  {
    "value": "68626",
    "label": "Brainard - NE 68626",
    "county": "Butler County",
    "state": "NE",
    "city": "Brainard"
  },
  {
    "value": "68627",
    "label": "Cedar Rapids - NE 68627",
    "county": "Boone County",
    "state": "NE",
    "city": "Cedar Rapids"
  },
  {
    "value": "68628",
    "label": "Clarks - NE 68628",
    "county": "Merrick County",
    "state": "NE",
    "city": "Clarks"
  },
  {
    "value": "68629",
    "label": "Clarkson - NE 68629",
    "county": "Colfax County",
    "state": "NE",
    "city": "Clarkson"
  },
  {
    "value": "68631",
    "label": "Creston - NE 68631",
    "county": "Platte County",
    "state": "NE",
    "city": "Creston"
  },
  {
    "value": "68632",
    "label": "David City - NE 68632",
    "county": "Butler County",
    "state": "NE",
    "city": "David City"
  },
  {
    "value": "68633",
    "label": "Dodge - NE 68633",
    "county": "Dodge County",
    "state": "NE",
    "city": "Dodge"
  },
  {
    "value": "68635",
    "label": "Dwight - NE 68635",
    "county": "Butler County",
    "state": "NE",
    "city": "Dwight"
  },
  {
    "value": "68636",
    "label": "Elgin - NE 68636",
    "county": "Antelope County",
    "state": "NE",
    "city": "Elgin"
  },
  {
    "value": "68637",
    "label": "Ericson - NE 68637",
    "county": "Wheeler County",
    "state": "NE",
    "city": "Ericson"
  },
  {
    "value": "68638",
    "label": "Fullerton - NE 68638",
    "county": "Nance County",
    "state": "NE",
    "city": "Fullerton"
  },
  {
    "value": "68640",
    "label": "Genoa - NE 68640",
    "county": "Nance County",
    "state": "NE",
    "city": "Genoa"
  },
  {
    "value": "68641",
    "label": "Howells - NE 68641",
    "county": "Colfax County",
    "state": "NE",
    "city": "Howells"
  },
  {
    "value": "68642",
    "label": "Humphrey - NE 68642",
    "county": "Platte County",
    "state": "NE",
    "city": "Humphrey"
  },
  {
    "value": "68643",
    "label": "Leigh - NE 68643",
    "county": "Colfax County",
    "state": "NE",
    "city": "Leigh"
  },
  {
    "value": "68644",
    "label": "Lindsay - NE 68644",
    "county": "Platte County",
    "state": "NE",
    "city": "Lindsay"
  },
  {
    "value": "68647",
    "label": "Monroe - NE 68647",
    "county": "Platte County",
    "state": "NE",
    "city": "Monroe"
  },
  {
    "value": "68648",
    "label": "Morse Bluff - NE 68648",
    "county": "Saunders County",
    "state": "NE",
    "city": "Morse Bluff"
  },
  {
    "value": "68649",
    "label": "North Bend - NE 68649",
    "county": "Dodge County",
    "state": "NE",
    "city": "North Bend"
  },
  {
    "value": "68651",
    "label": "Osceola - NE 68651",
    "county": "Polk County",
    "state": "NE",
    "city": "Osceola"
  },
  {
    "value": "68652",
    "label": "Petersburg - NE 68652",
    "county": "Boone County",
    "state": "NE",
    "city": "Petersburg"
  },
  {
    "value": "68653",
    "label": "Platte Center - NE 68653",
    "county": "Platte County",
    "state": "NE",
    "city": "Platte Center"
  },
  {
    "value": "68654",
    "label": "Polk - NE 68654",
    "county": "Polk County",
    "state": "NE",
    "city": "Polk"
  },
  {
    "value": "68655",
    "label": "Primrose - NE 68655",
    "county": "Boone County",
    "state": "NE",
    "city": "Primrose"
  },
  {
    "value": "68658",
    "label": "Rising City - NE 68658",
    "county": "Butler County",
    "state": "NE",
    "city": "Rising City"
  },
  {
    "value": "68659",
    "label": "Rogers - NE 68659",
    "county": "Colfax County",
    "state": "NE",
    "city": "Rogers"
  },
  {
    "value": "68660",
    "label": "Saint Edward - NE 68660",
    "county": "Boone County",
    "state": "NE",
    "city": "Saint Edward"
  },
  {
    "value": "68661",
    "label": "Schuyler - NE 68661",
    "county": "Colfax County",
    "state": "NE",
    "city": "Schuyler"
  },
  {
    "value": "68662",
    "label": "Shelby - NE 68662",
    "county": "Polk County",
    "state": "NE",
    "city": "Shelby"
  },
  {
    "value": "68663",
    "label": "Silver Creek - NE 68663",
    "county": "Merrick County",
    "state": "NE",
    "city": "Silver Creek"
  },
  {
    "value": "68665",
    "label": "Spalding - NE 68665",
    "county": "Greeley County",
    "state": "NE",
    "city": "Spalding"
  },
  {
    "value": "68666",
    "label": "Stromsburg - NE 68666",
    "county": "Polk County",
    "state": "NE",
    "city": "Stromsburg"
  },
  {
    "value": "68667",
    "label": "Surprise - NE 68667",
    "county": "Butler County",
    "state": "NE",
    "city": "Surprise"
  },
  {
    "value": "68669",
    "label": "Ulysses - NE 68669",
    "county": "Butler County",
    "state": "NE",
    "city": "Ulysses"
  },
  {
    "value": "68701",
    "label": "Norfolk - NE 68701",
    "county": "Madison County",
    "state": "NE",
    "city": "Norfolk"
  },
  {
    "value": "68710",
    "label": "Allen - NE 68710",
    "county": "Dixon County",
    "state": "NE",
    "city": "Allen"
  },
  {
    "value": "68711",
    "label": "Amelia - NE 68711",
    "county": "Holt County",
    "state": "NE",
    "city": "Amelia"
  },
  {
    "value": "68713",
    "label": "Atkinson - NE 68713",
    "county": "Holt County",
    "state": "NE",
    "city": "Atkinson"
  },
  {
    "value": "68714",
    "label": "Bassett - NE 68714",
    "county": "Rock County",
    "state": "NE",
    "city": "Bassett"
  },
  {
    "value": "68715",
    "label": "Battle Creek - NE 68715",
    "county": "Madison County",
    "state": "NE",
    "city": "Battle Creek"
  },
  {
    "value": "68716",
    "label": "Beemer - NE 68716",
    "county": "Cuming County",
    "state": "NE",
    "city": "Beemer"
  },
  {
    "value": "68717",
    "label": "Belden - NE 68717",
    "county": "Cedar County",
    "state": "NE",
    "city": "Belden"
  },
  {
    "value": "68718",
    "label": "Bloomfield - NE 68718",
    "county": "Knox County",
    "state": "NE",
    "city": "Bloomfield"
  },
  {
    "value": "68719",
    "label": "Bristow - NE 68719",
    "county": "Boyd County",
    "state": "NE",
    "city": "Bristow"
  },
  {
    "value": "68720",
    "label": "Brunswick - NE 68720",
    "county": "Antelope County",
    "state": "NE",
    "city": "Brunswick"
  },
  {
    "value": "68722",
    "label": "Butte - NE 68722",
    "county": "Boyd County",
    "state": "NE",
    "city": "Butte"
  },
  {
    "value": "68723",
    "label": "Carroll - NE 68723",
    "county": "Wayne County",
    "state": "NE",
    "city": "Carroll"
  },
  {
    "value": "68724",
    "label": "Center - NE 68724",
    "county": "Knox County",
    "state": "NE",
    "city": "Center"
  },
  {
    "value": "68725",
    "label": "Chambers - NE 68725",
    "county": "Holt County",
    "state": "NE",
    "city": "Chambers"
  },
  {
    "value": "68726",
    "label": "Clearwater - NE 68726",
    "county": "Antelope County",
    "state": "NE",
    "city": "Clearwater"
  },
  {
    "value": "68727",
    "label": "Coleridge - NE 68727",
    "county": "Cedar County",
    "state": "NE",
    "city": "Coleridge"
  },
  {
    "value": "68728",
    "label": "Concord - NE 68728",
    "county": "Dixon County",
    "state": "NE",
    "city": "Concord"
  },
  {
    "value": "68729",
    "label": "Creighton - NE 68729",
    "county": "Knox County",
    "state": "NE",
    "city": "Creighton"
  },
  {
    "value": "68730",
    "label": "Crofton - NE 68730",
    "county": "Knox County",
    "state": "NE",
    "city": "Crofton"
  },
  {
    "value": "68731",
    "label": "Dakota City - NE 68731",
    "county": "Dakota County",
    "state": "NE",
    "city": "Dakota City"
  },
  {
    "value": "68732",
    "label": "Dixon - NE 68732",
    "county": "Dixon County",
    "state": "NE",
    "city": "Dixon"
  },
  {
    "value": "68733",
    "label": "Emerson - NE 68733",
    "county": "Dakota County",
    "state": "NE",
    "city": "Emerson"
  },
  {
    "value": "68734",
    "label": "Emmet - NE 68734",
    "county": "Holt County",
    "state": "NE",
    "city": "Emmet"
  },
  {
    "value": "68735",
    "label": "Ewing - NE 68735",
    "county": "Holt County",
    "state": "NE",
    "city": "Ewing"
  },
  {
    "value": "68736",
    "label": "Fordyce - NE 68736",
    "county": "Cedar County",
    "state": "NE",
    "city": "Fordyce"
  },
  {
    "value": "68739",
    "label": "Hartington - NE 68739",
    "county": "Cedar County",
    "state": "NE",
    "city": "Hartington"
  },
  {
    "value": "68740",
    "label": "Hoskins - NE 68740",
    "county": "Wayne County",
    "state": "NE",
    "city": "Hoskins"
  },
  {
    "value": "68741",
    "label": "Hubbard - NE 68741",
    "county": "Dakota County",
    "state": "NE",
    "city": "Hubbard"
  },
  {
    "value": "68742",
    "label": "Inman - NE 68742",
    "county": "Holt County",
    "state": "NE",
    "city": "Inman"
  },
  {
    "value": "68743",
    "label": "Jackson - NE 68743",
    "county": "Dakota County",
    "state": "NE",
    "city": "Jackson"
  },
  {
    "value": "68745",
    "label": "Laurel - NE 68745",
    "county": "Cedar County",
    "state": "NE",
    "city": "Laurel"
  },
  {
    "value": "68746",
    "label": "Lynch - NE 68746",
    "county": "Boyd County",
    "state": "NE",
    "city": "Lynch"
  },
  {
    "value": "68747",
    "label": "Mclean - NE 68747",
    "county": "Pierce County",
    "state": "NE",
    "city": "Mclean"
  },
  {
    "value": "68748",
    "label": "Madison - NE 68748",
    "county": "Madison County",
    "state": "NE",
    "city": "Madison"
  },
  {
    "value": "68751",
    "label": "Maskell - NE 68751",
    "county": "Dixon County",
    "state": "NE",
    "city": "Maskell"
  },
  {
    "value": "68752",
    "label": "Meadow Grove - NE 68752",
    "county": "Madison County",
    "state": "NE",
    "city": "Meadow Grove"
  },
  {
    "value": "68753",
    "label": "Mills - NE 68753",
    "county": "Keya Paha County",
    "state": "NE",
    "city": "Mills"
  },
  {
    "value": "68755",
    "label": "Naper - NE 68755",
    "county": "Boyd County",
    "state": "NE",
    "city": "Naper"
  },
  {
    "value": "68756",
    "label": "Neligh - NE 68756",
    "county": "Antelope County",
    "state": "NE",
    "city": "Neligh"
  },
  {
    "value": "68757",
    "label": "Newcastle - NE 68757",
    "county": "Dixon County",
    "state": "NE",
    "city": "Newcastle"
  },
  {
    "value": "68758",
    "label": "Newman Grove - NE 68758",
    "county": "Madison County",
    "state": "NE",
    "city": "Newman Grove"
  },
  {
    "value": "68759",
    "label": "Newport - NE 68759",
    "county": "Rock County",
    "state": "NE",
    "city": "Newport"
  },
  {
    "value": "68760",
    "label": "Niobrara - NE 68760",
    "county": "Knox County",
    "state": "NE",
    "city": "Niobrara"
  },
  {
    "value": "68761",
    "label": "Oakdale - NE 68761",
    "county": "Antelope County",
    "state": "NE",
    "city": "Oakdale"
  },
  {
    "value": "68763",
    "label": "Oneill - NE 68763",
    "county": "Holt County",
    "state": "NE",
    "city": "Oneill"
  },
  {
    "value": "68764",
    "label": "Orchard - NE 68764",
    "county": "Antelope County",
    "state": "NE",
    "city": "Orchard"
  },
  {
    "value": "68765",
    "label": "Osmond - NE 68765",
    "county": "Pierce County",
    "state": "NE",
    "city": "Osmond"
  },
  {
    "value": "68766",
    "label": "Page - NE 68766",
    "county": "Holt County",
    "state": "NE",
    "city": "Page"
  },
  {
    "value": "68767",
    "label": "Pierce - NE 68767",
    "county": "Pierce County",
    "state": "NE",
    "city": "Pierce"
  },
  {
    "value": "68768",
    "label": "Pilger - NE 68768",
    "county": "Stanton County",
    "state": "NE",
    "city": "Pilger"
  },
  {
    "value": "68769",
    "label": "Plainview - NE 68769",
    "county": "Pierce County",
    "state": "NE",
    "city": "Plainview"
  },
  {
    "value": "68770",
    "label": "Ponca - NE 68770",
    "county": "Dixon County",
    "state": "NE",
    "city": "Ponca"
  },
  {
    "value": "68771",
    "label": "Randolph - NE 68771",
    "county": "Cedar County",
    "state": "NE",
    "city": "Randolph"
  },
  {
    "value": "68773",
    "label": "Royal - NE 68773",
    "county": "Antelope County",
    "state": "NE",
    "city": "Royal"
  },
  {
    "value": "68774",
    "label": "Saint Helena - NE 68774",
    "county": "Cedar County",
    "state": "NE",
    "city": "Saint Helena"
  },
  {
    "value": "68776",
    "label": "South Sioux City - NE 68776",
    "county": "Dakota County",
    "state": "NE",
    "city": "South Sioux City"
  },
  {
    "value": "68777",
    "label": "Spencer - NE 68777",
    "county": "Boyd County",
    "state": "NE",
    "city": "Spencer"
  },
  {
    "value": "68778",
    "label": "Springview - NE 68778",
    "county": "Keya Paha County",
    "state": "NE",
    "city": "Springview"
  },
  {
    "value": "68779",
    "label": "Stanton - NE 68779",
    "county": "Stanton County",
    "state": "NE",
    "city": "Stanton"
  },
  {
    "value": "68780",
    "label": "Stuart - NE 68780",
    "county": "Holt County",
    "state": "NE",
    "city": "Stuart"
  },
  {
    "value": "68781",
    "label": "Tilden - NE 68781",
    "county": "Madison County",
    "state": "NE",
    "city": "Tilden"
  },
  {
    "value": "68783",
    "label": "Verdigre - NE 68783",
    "county": "Knox County",
    "state": "NE",
    "city": "Verdigre"
  },
  {
    "value": "68784",
    "label": "Wakefield - NE 68784",
    "county": "Dixon County",
    "state": "NE",
    "city": "Wakefield"
  },
  {
    "value": "68785",
    "label": "Waterbury - NE 68785",
    "county": "Dixon County",
    "state": "NE",
    "city": "Waterbury"
  },
  {
    "value": "68786",
    "label": "Wausa - NE 68786",
    "county": "Knox County",
    "state": "NE",
    "city": "Wausa"
  },
  {
    "value": "68787",
    "label": "Wayne - NE 68787",
    "county": "Wayne County",
    "state": "NE",
    "city": "Wayne"
  },
  {
    "value": "68788",
    "label": "West Point - NE 68788",
    "county": "Cuming County",
    "state": "NE",
    "city": "West Point"
  },
  {
    "value": "68789",
    "label": "Winnetoon - NE 68789",
    "county": "Knox County",
    "state": "NE",
    "city": "Winnetoon"
  },
  {
    "value": "68790",
    "label": "Winside - NE 68790",
    "county": "Wayne County",
    "state": "NE",
    "city": "Winside"
  },
  {
    "value": "68791",
    "label": "Wisner - NE 68791",
    "county": "Cuming County",
    "state": "NE",
    "city": "Wisner"
  },
  {
    "value": "68792",
    "label": "Wynot - NE 68792",
    "county": "Cedar County",
    "state": "NE",
    "city": "Wynot"
  },
  {
    "value": "68801",
    "label": "Grand Island - NE 68801",
    "county": "Hall County",
    "state": "NE",
    "city": "Grand Island"
  },
  {
    "value": "68803",
    "label": "Grand Island - NE 68803",
    "county": "Hall County",
    "state": "NE",
    "city": "Grand Island"
  },
  {
    "value": "68810",
    "label": "Alda - NE 68810",
    "county": "Hall County",
    "state": "NE",
    "city": "Alda"
  },
  {
    "value": "68812",
    "label": "Amherst - NE 68812",
    "county": "Buffalo County",
    "state": "NE",
    "city": "Amherst"
  },
  {
    "value": "68813",
    "label": "Anselmo - NE 68813",
    "county": "Custer County",
    "state": "NE",
    "city": "Anselmo"
  },
  {
    "value": "68814",
    "label": "Ansley - NE 68814",
    "county": "Custer County",
    "state": "NE",
    "city": "Ansley"
  },
  {
    "value": "68815",
    "label": "Arcadia - NE 68815",
    "county": "Valley County",
    "state": "NE",
    "city": "Arcadia"
  },
  {
    "value": "68816",
    "label": "Archer - NE 68816",
    "county": "Merrick County",
    "state": "NE",
    "city": "Archer"
  },
  {
    "value": "68817",
    "label": "Ashton - NE 68817",
    "county": "Sherman County",
    "state": "NE",
    "city": "Ashton"
  },
  {
    "value": "68818",
    "label": "Aurora - NE 68818",
    "county": "Hamilton County",
    "state": "NE",
    "city": "Aurora"
  },
  {
    "value": "68820",
    "label": "Boelus - NE 68820",
    "county": "Howard County",
    "state": "NE",
    "city": "Boelus"
  },
  {
    "value": "68821",
    "label": "Brewster - NE 68821",
    "county": "Blaine County",
    "state": "NE",
    "city": "Brewster"
  },
  {
    "value": "68822",
    "label": "Broken Bow - NE 68822",
    "county": "Custer County",
    "state": "NE",
    "city": "Broken Bow"
  },
  {
    "value": "68823",
    "label": "Burwell - NE 68823",
    "county": "Garfield County",
    "state": "NE",
    "city": "Burwell"
  },
  {
    "value": "68824",
    "label": "Cairo - NE 68824",
    "county": "Hall County",
    "state": "NE",
    "city": "Cairo"
  },
  {
    "value": "68825",
    "label": "Callaway - NE 68825",
    "county": "Custer County",
    "state": "NE",
    "city": "Callaway"
  },
  {
    "value": "68826",
    "label": "Central City - NE 68826",
    "county": "Merrick County",
    "state": "NE",
    "city": "Central City"
  },
  {
    "value": "68827",
    "label": "Chapman - NE 68827",
    "county": "Merrick County",
    "state": "NE",
    "city": "Chapman"
  },
  {
    "value": "68828",
    "label": "Comstock - NE 68828",
    "county": "Custer County",
    "state": "NE",
    "city": "Comstock"
  },
  {
    "value": "68831",
    "label": "Dannebrog - NE 68831",
    "county": "Howard County",
    "state": "NE",
    "city": "Dannebrog"
  },
  {
    "value": "68832",
    "label": "Doniphan - NE 68832",
    "county": "Hall County",
    "state": "NE",
    "city": "Doniphan"
  },
  {
    "value": "68833",
    "label": "Dunning - NE 68833",
    "county": "Blaine County",
    "state": "NE",
    "city": "Dunning"
  },
  {
    "value": "68834",
    "label": "Eddyville - NE 68834",
    "county": "Dawson County",
    "state": "NE",
    "city": "Eddyville"
  },
  {
    "value": "68835",
    "label": "Elba - NE 68835",
    "county": "Howard County",
    "state": "NE",
    "city": "Elba"
  },
  {
    "value": "68836",
    "label": "Elm Creek - NE 68836",
    "county": "Buffalo County",
    "state": "NE",
    "city": "Elm Creek"
  },
  {
    "value": "68837",
    "label": "Elyria - NE 68837",
    "county": "Valley County",
    "state": "NE",
    "city": "Elyria"
  },
  {
    "value": "68838",
    "label": "Farwell - NE 68838",
    "county": "Howard County",
    "state": "NE",
    "city": "Farwell"
  },
  {
    "value": "68840",
    "label": "Gibbon - NE 68840",
    "county": "Buffalo County",
    "state": "NE",
    "city": "Gibbon"
  },
  {
    "value": "68841",
    "label": "Giltner - NE 68841",
    "county": "Hamilton County",
    "state": "NE",
    "city": "Giltner"
  },
  {
    "value": "68842",
    "label": "Greeley - NE 68842",
    "county": "Greeley County",
    "state": "NE",
    "city": "Greeley"
  },
  {
    "value": "68843",
    "label": "Hampton - NE 68843",
    "county": "Hamilton County",
    "state": "NE",
    "city": "Hampton"
  },
  {
    "value": "68844",
    "label": "Hazard - NE 68844",
    "county": "Sherman County",
    "state": "NE",
    "city": "Hazard"
  },
  {
    "value": "68845",
    "label": "Kearney - NE 68845",
    "county": "Buffalo County",
    "state": "NE",
    "city": "Kearney"
  },
  {
    "value": "68846",
    "label": "Hordville - NE 68846",
    "county": "Hamilton County",
    "state": "NE",
    "city": "Hordville"
  },
  {
    "value": "68847",
    "label": "Kearney - NE 68847",
    "county": "Buffalo County",
    "state": "NE",
    "city": "Kearney"
  },
  {
    "value": "68850",
    "label": "Lexington - NE 68850",
    "county": "Dawson County",
    "state": "NE",
    "city": "Lexington"
  },
  {
    "value": "68852",
    "label": "Litchfield - NE 68852",
    "county": "Sherman County",
    "state": "NE",
    "city": "Litchfield"
  },
  {
    "value": "68853",
    "label": "Loup City - NE 68853",
    "county": "Sherman County",
    "state": "NE",
    "city": "Loup City"
  },
  {
    "value": "68854",
    "label": "Marquette - NE 68854",
    "county": "Hamilton County",
    "state": "NE",
    "city": "Marquette"
  },
  {
    "value": "68855",
    "label": "Mason City - NE 68855",
    "county": "Custer County",
    "state": "NE",
    "city": "Mason City"
  },
  {
    "value": "68856",
    "label": "Merna - NE 68856",
    "county": "Custer County",
    "state": "NE",
    "city": "Merna"
  },
  {
    "value": "68858",
    "label": "Miller - NE 68858",
    "county": "Buffalo County",
    "state": "NE",
    "city": "Miller"
  },
  {
    "value": "68859",
    "label": "North Loup - NE 68859",
    "county": "Valley County",
    "state": "NE",
    "city": "North Loup"
  },
  {
    "value": "68860",
    "label": "Oconto - NE 68860",
    "county": "Custer County",
    "state": "NE",
    "city": "Oconto"
  },
  {
    "value": "68861",
    "label": "Odessa - NE 68861",
    "county": "Buffalo County",
    "state": "NE",
    "city": "Odessa"
  },
  {
    "value": "68862",
    "label": "Ord - NE 68862",
    "county": "Valley County",
    "state": "NE",
    "city": "Ord"
  },
  {
    "value": "68863",
    "label": "Overton - NE 68863",
    "county": "Dawson County",
    "state": "NE",
    "city": "Overton"
  },
  {
    "value": "68864",
    "label": "Palmer - NE 68864",
    "county": "Merrick County",
    "state": "NE",
    "city": "Palmer"
  },
  {
    "value": "68865",
    "label": "Phillips - NE 68865",
    "county": "Hamilton County",
    "state": "NE",
    "city": "Phillips"
  },
  {
    "value": "68866",
    "label": "Pleasanton - NE 68866",
    "county": "Buffalo County",
    "state": "NE",
    "city": "Pleasanton"
  },
  {
    "value": "68869",
    "label": "Ravenna - NE 68869",
    "county": "Buffalo County",
    "state": "NE",
    "city": "Ravenna"
  },
  {
    "value": "68870",
    "label": "Riverdale - NE 68870",
    "county": "Buffalo County",
    "state": "NE",
    "city": "Riverdale"
  },
  {
    "value": "68871",
    "label": "Rockville - NE 68871",
    "county": "Sherman County",
    "state": "NE",
    "city": "Rockville"
  },
  {
    "value": "68872",
    "label": "Saint Libory - NE 68872",
    "county": "Howard County",
    "state": "NE",
    "city": "Saint Libory"
  },
  {
    "value": "68873",
    "label": "Saint Paul - NE 68873",
    "county": "Howard County",
    "state": "NE",
    "city": "Saint Paul"
  },
  {
    "value": "68874",
    "label": "Sargent - NE 68874",
    "county": "Custer County",
    "state": "NE",
    "city": "Sargent"
  },
  {
    "value": "68875",
    "label": "Scotia - NE 68875",
    "county": "Greeley County",
    "state": "NE",
    "city": "Scotia"
  },
  {
    "value": "68876",
    "label": "Shelton - NE 68876",
    "county": "Buffalo County",
    "state": "NE",
    "city": "Shelton"
  },
  {
    "value": "68878",
    "label": "Sumner - NE 68878",
    "county": "Dawson County",
    "state": "NE",
    "city": "Sumner"
  },
  {
    "value": "68879",
    "label": "Taylor - NE 68879",
    "county": "Loup County",
    "state": "NE",
    "city": "Taylor"
  },
  {
    "value": "68881",
    "label": "Westerville - NE 68881",
    "county": "Custer County",
    "state": "NE",
    "city": "Westerville"
  },
  {
    "value": "68882",
    "label": "Wolbach - NE 68882",
    "county": "Greeley County",
    "state": "NE",
    "city": "Wolbach"
  },
  {
    "value": "68883",
    "label": "Wood River - NE 68883",
    "county": "Hall County",
    "state": "NE",
    "city": "Wood River"
  },
  {
    "value": "68901",
    "label": "Hastings - NE 68901",
    "county": "Adams County",
    "state": "NE",
    "city": "Hastings"
  },
  {
    "value": "68920",
    "label": "Alma - NE 68920",
    "county": "Harlan County",
    "state": "NE",
    "city": "Alma"
  },
  {
    "value": "68922",
    "label": "Arapahoe - NE 68922",
    "county": "Furnas County",
    "state": "NE",
    "city": "Arapahoe"
  },
  {
    "value": "68924",
    "label": "Axtell - NE 68924",
    "county": "Kearney County",
    "state": "NE",
    "city": "Axtell"
  },
  {
    "value": "68925",
    "label": "Ayr - NE 68925",
    "county": "Adams County",
    "state": "NE",
    "city": "Ayr"
  },
  {
    "value": "68926",
    "label": "Beaver City - NE 68926",
    "county": "Furnas County",
    "state": "NE",
    "city": "Beaver City"
  },
  {
    "value": "68927",
    "label": "Bertrand - NE 68927",
    "county": "Phelps County",
    "state": "NE",
    "city": "Bertrand"
  },
  {
    "value": "68928",
    "label": "Bladen - NE 68928",
    "county": "Webster County",
    "state": "NE",
    "city": "Bladen"
  },
  {
    "value": "68929",
    "label": "Bloomington - NE 68929",
    "county": "Franklin County",
    "state": "NE",
    "city": "Bloomington"
  },
  {
    "value": "68930",
    "label": "Blue Hill - NE 68930",
    "county": "Webster County",
    "state": "NE",
    "city": "Blue Hill"
  },
  {
    "value": "68932",
    "label": "Campbell - NE 68932",
    "county": "Franklin County",
    "state": "NE",
    "city": "Campbell"
  },
  {
    "value": "68933",
    "label": "Clay Center - NE 68933",
    "county": "Clay County",
    "state": "NE",
    "city": "Clay Center"
  },
  {
    "value": "68934",
    "label": "Deweese - NE 68934",
    "county": "Clay County",
    "state": "NE",
    "city": "Deweese"
  },
  {
    "value": "68935",
    "label": "Edgar - NE 68935",
    "county": "Clay County",
    "state": "NE",
    "city": "Edgar"
  },
  {
    "value": "68936",
    "label": "Edison - NE 68936",
    "county": "Furnas County",
    "state": "NE",
    "city": "Edison"
  },
  {
    "value": "68937",
    "label": "Elwood - NE 68937",
    "county": "Gosper County",
    "state": "NE",
    "city": "Elwood"
  },
  {
    "value": "68938",
    "label": "Fairfield - NE 68938",
    "county": "Clay County",
    "state": "NE",
    "city": "Fairfield"
  },
  {
    "value": "68939",
    "label": "Franklin - NE 68939",
    "county": "Franklin County",
    "state": "NE",
    "city": "Franklin"
  },
  {
    "value": "68940",
    "label": "Funk - NE 68940",
    "county": "Phelps County",
    "state": "NE",
    "city": "Funk"
  },
  {
    "value": "68941",
    "label": "Glenvil - NE 68941",
    "county": "Clay County",
    "state": "NE",
    "city": "Glenvil"
  },
  {
    "value": "68942",
    "label": "Guide Rock - NE 68942",
    "county": "Webster County",
    "state": "NE",
    "city": "Guide Rock"
  },
  {
    "value": "68943",
    "label": "Hardy - NE 68943",
    "county": "Nuckolls County",
    "state": "NE",
    "city": "Hardy"
  },
  {
    "value": "68944",
    "label": "Harvard - NE 68944",
    "county": "Clay County",
    "state": "NE",
    "city": "Harvard"
  },
  {
    "value": "68945",
    "label": "Heartwell - NE 68945",
    "county": "Kearney County",
    "state": "NE",
    "city": "Heartwell"
  },
  {
    "value": "68946",
    "label": "Hendley - NE 68946",
    "county": "Furnas County",
    "state": "NE",
    "city": "Hendley"
  },
  {
    "value": "68947",
    "label": "Hildreth - NE 68947",
    "county": "Franklin County",
    "state": "NE",
    "city": "Hildreth"
  },
  {
    "value": "68948",
    "label": "Holbrook - NE 68948",
    "county": "Furnas County",
    "state": "NE",
    "city": "Holbrook"
  },
  {
    "value": "68949",
    "label": "Holdrege - NE 68949",
    "county": "Phelps County",
    "state": "NE",
    "city": "Holdrege"
  },
  {
    "value": "68950",
    "label": "Holstein - NE 68950",
    "county": "Adams County",
    "state": "NE",
    "city": "Holstein"
  },
  {
    "value": "68952",
    "label": "Inavale - NE 68952",
    "county": "Webster County",
    "state": "NE",
    "city": "Inavale"
  },
  {
    "value": "68954",
    "label": "Inland - NE 68954",
    "county": "Clay County",
    "state": "NE",
    "city": "Inland"
  },
  {
    "value": "68955",
    "label": "Juniata - NE 68955",
    "county": "Adams County",
    "state": "NE",
    "city": "Juniata"
  },
  {
    "value": "68956",
    "label": "Kenesaw - NE 68956",
    "county": "Adams County",
    "state": "NE",
    "city": "Kenesaw"
  },
  {
    "value": "68957",
    "label": "Lawrence - NE 68957",
    "county": "Nuckolls County",
    "state": "NE",
    "city": "Lawrence"
  },
  {
    "value": "68958",
    "label": "Loomis - NE 68958",
    "county": "Phelps County",
    "state": "NE",
    "city": "Loomis"
  },
  {
    "value": "68959",
    "label": "Minden - NE 68959",
    "county": "Kearney County",
    "state": "NE",
    "city": "Minden"
  },
  {
    "value": "68960",
    "label": "Naponee - NE 68960",
    "county": "Franklin County",
    "state": "NE",
    "city": "Naponee"
  },
  {
    "value": "68961",
    "label": "Nelson - NE 68961",
    "county": "Nuckolls County",
    "state": "NE",
    "city": "Nelson"
  },
  {
    "value": "68964",
    "label": "Oak - NE 68964",
    "county": "Nuckolls County",
    "state": "NE",
    "city": "Oak"
  },
  {
    "value": "68966",
    "label": "Orleans - NE 68966",
    "county": "Harlan County",
    "state": "NE",
    "city": "Orleans"
  },
  {
    "value": "68967",
    "label": "Oxford - NE 68967",
    "county": "Furnas County",
    "state": "NE",
    "city": "Oxford"
  },
  {
    "value": "68970",
    "label": "Red Cloud - NE 68970",
    "county": "Webster County",
    "state": "NE",
    "city": "Red Cloud"
  },
  {
    "value": "68971",
    "label": "Republican City - NE 68971",
    "county": "Harlan County",
    "state": "NE",
    "city": "Republican City"
  },
  {
    "value": "68972",
    "label": "Riverton - NE 68972",
    "county": "Franklin County",
    "state": "NE",
    "city": "Riverton"
  },
  {
    "value": "68973",
    "label": "Roseland - NE 68973",
    "county": "Adams County",
    "state": "NE",
    "city": "Roseland"
  },
  {
    "value": "68974",
    "label": "Ruskin - NE 68974",
    "county": "Nuckolls County",
    "state": "NE",
    "city": "Ruskin"
  },
  {
    "value": "68975",
    "label": "Saronville - NE 68975",
    "county": "Clay County",
    "state": "NE",
    "city": "Saronville"
  },
  {
    "value": "68976",
    "label": "Smithfield - NE 68976",
    "county": "Gosper County",
    "state": "NE",
    "city": "Smithfield"
  },
  {
    "value": "68977",
    "label": "Stamford - NE 68977",
    "county": "Harlan County",
    "state": "NE",
    "city": "Stamford"
  },
  {
    "value": "68978",
    "label": "Superior - NE 68978",
    "county": "Nuckolls County",
    "state": "NE",
    "city": "Superior"
  },
  {
    "value": "68979",
    "label": "Sutton - NE 68979",
    "county": "Clay County",
    "state": "NE",
    "city": "Sutton"
  },
  {
    "value": "68980",
    "label": "Trumbull - NE 68980",
    "county": "Clay County",
    "state": "NE",
    "city": "Trumbull"
  },
  {
    "value": "68981",
    "label": "Upland - NE 68981",
    "county": "Franklin County",
    "state": "NE",
    "city": "Upland"
  },
  {
    "value": "68982",
    "label": "Wilcox - NE 68982",
    "county": "Kearney County",
    "state": "NE",
    "city": "Wilcox"
  },
  {
    "value": "69001",
    "label": "Mc Cook - NE 69001",
    "county": "Red Willow County",
    "state": "NE",
    "city": "Mc Cook"
  },
  {
    "value": "69020",
    "label": "Bartley - NE 69020",
    "county": "Red Willow County",
    "state": "NE",
    "city": "Bartley"
  },
  {
    "value": "69021",
    "label": "Benkelman - NE 69021",
    "county": "Dundy County",
    "state": "NE",
    "city": "Benkelman"
  },
  {
    "value": "69022",
    "label": "Cambridge - NE 69022",
    "county": "Furnas County",
    "state": "NE",
    "city": "Cambridge"
  },
  {
    "value": "69023",
    "label": "Champion - NE 69023",
    "county": "Chase County",
    "state": "NE",
    "city": "Champion"
  },
  {
    "value": "69024",
    "label": "Culbertson - NE 69024",
    "county": "Hitchcock County",
    "state": "NE",
    "city": "Culbertson"
  },
  {
    "value": "69025",
    "label": "Curtis - NE 69025",
    "county": "Frontier County",
    "state": "NE",
    "city": "Curtis"
  },
  {
    "value": "69026",
    "label": "Danbury - NE 69026",
    "county": "Red Willow County",
    "state": "NE",
    "city": "Danbury"
  },
  {
    "value": "69027",
    "label": "Enders - NE 69027",
    "county": "Chase County",
    "state": "NE",
    "city": "Enders"
  },
  {
    "value": "69028",
    "label": "Eustis - NE 69028",
    "county": "Frontier County",
    "state": "NE",
    "city": "Eustis"
  },
  {
    "value": "69029",
    "label": "Farnam - NE 69029",
    "county": "Dawson County",
    "state": "NE",
    "city": "Farnam"
  },
  {
    "value": "69030",
    "label": "Haigler - NE 69030",
    "county": "Dundy County",
    "state": "NE",
    "city": "Haigler"
  },
  {
    "value": "69032",
    "label": "Hayes Center - NE 69032",
    "county": "Hayes County",
    "state": "NE",
    "city": "Hayes Center"
  },
  {
    "value": "69033",
    "label": "Imperial - NE 69033",
    "county": "Chase County",
    "state": "NE",
    "city": "Imperial"
  },
  {
    "value": "69034",
    "label": "Indianola - NE 69034",
    "county": "Red Willow County",
    "state": "NE",
    "city": "Indianola"
  },
  {
    "value": "69036",
    "label": "Lebanon - NE 69036",
    "county": "Red Willow County",
    "state": "NE",
    "city": "Lebanon"
  },
  {
    "value": "69037",
    "label": "Max - NE 69037",
    "county": "Dundy County",
    "state": "NE",
    "city": "Max"
  },
  {
    "value": "69038",
    "label": "Maywood - NE 69038",
    "county": "Frontier County",
    "state": "NE",
    "city": "Maywood"
  },
  {
    "value": "69039",
    "label": "Moorefield - NE 69039",
    "county": "Frontier County",
    "state": "NE",
    "city": "Moorefield"
  },
  {
    "value": "69040",
    "label": "Palisade - NE 69040",
    "county": "Hitchcock County",
    "state": "NE",
    "city": "Palisade"
  },
  {
    "value": "69041",
    "label": "Parks - NE 69041",
    "county": "Dundy County",
    "state": "NE",
    "city": "Parks"
  },
  {
    "value": "69042",
    "label": "Stockville - NE 69042",
    "county": "Frontier County",
    "state": "NE",
    "city": "Stockville"
  },
  {
    "value": "69043",
    "label": "Stratton - NE 69043",
    "county": "Hitchcock County",
    "state": "NE",
    "city": "Stratton"
  },
  {
    "value": "69044",
    "label": "Trenton - NE 69044",
    "county": "Hitchcock County",
    "state": "NE",
    "city": "Trenton"
  },
  {
    "value": "69045",
    "label": "Wauneta - NE 69045",
    "county": "Chase County",
    "state": "NE",
    "city": "Wauneta"
  },
  {
    "value": "69046",
    "label": "Wilsonville - NE 69046",
    "county": "Furnas County",
    "state": "NE",
    "city": "Wilsonville"
  },
  {
    "value": "69101",
    "label": "North Platte - NE 69101",
    "county": "Lincoln County",
    "state": "NE",
    "city": "North Platte"
  },
  {
    "value": "69120",
    "label": "Arnold - NE 69120",
    "county": "Custer County",
    "state": "NE",
    "city": "Arnold"
  },
  {
    "value": "69121",
    "label": "Arthur - NE 69121",
    "county": "Arthur County",
    "state": "NE",
    "city": "Arthur"
  },
  {
    "value": "69122",
    "label": "Big Springs - NE 69122",
    "county": "Deuel County",
    "state": "NE",
    "city": "Big Springs"
  },
  {
    "value": "69123",
    "label": "Brady - NE 69123",
    "county": "Lincoln County",
    "state": "NE",
    "city": "Brady"
  },
  {
    "value": "69125",
    "label": "Broadwater - NE 69125",
    "county": "Morrill County",
    "state": "NE",
    "city": "Broadwater"
  },
  {
    "value": "69127",
    "label": "Brule - NE 69127",
    "county": "Keith County",
    "state": "NE",
    "city": "Brule"
  },
  {
    "value": "69128",
    "label": "Bushnell - NE 69128",
    "county": "Kimball County",
    "state": "NE",
    "city": "Bushnell"
  },
  {
    "value": "69129",
    "label": "Chappell - NE 69129",
    "county": "Deuel County",
    "state": "NE",
    "city": "Chappell"
  },
  {
    "value": "69130",
    "label": "Cozad - NE 69130",
    "county": "Dawson County",
    "state": "NE",
    "city": "Cozad"
  },
  {
    "value": "69131",
    "label": "Dalton - NE 69131",
    "county": "Cheyenne County",
    "state": "NE",
    "city": "Dalton"
  },
  {
    "value": "69132",
    "label": "Dickens - NE 69132",
    "county": "Lincoln County",
    "state": "NE",
    "city": "Dickens"
  },
  {
    "value": "69133",
    "label": "Dix - NE 69133",
    "county": "Kimball County",
    "state": "NE",
    "city": "Dix"
  },
  {
    "value": "69134",
    "label": "Elsie - NE 69134",
    "county": "Perkins County",
    "state": "NE",
    "city": "Elsie"
  },
  {
    "value": "69135",
    "label": "Elsmere - NE 69135",
    "county": "Cherry County",
    "state": "NE",
    "city": "Elsmere"
  },
  {
    "value": "69138",
    "label": "Gothenburg - NE 69138",
    "county": "Dawson County",
    "state": "NE",
    "city": "Gothenburg"
  },
  {
    "value": "69140",
    "label": "Grant - NE 69140",
    "county": "Perkins County",
    "state": "NE",
    "city": "Grant"
  },
  {
    "value": "69141",
    "label": "Gurley - NE 69141",
    "county": "Cheyenne County",
    "state": "NE",
    "city": "Gurley"
  },
  {
    "value": "69142",
    "label": "Halsey - NE 69142",
    "county": "Thomas County",
    "state": "NE",
    "city": "Halsey"
  },
  {
    "value": "69143",
    "label": "Hershey - NE 69143",
    "county": "Lincoln County",
    "state": "NE",
    "city": "Hershey"
  },
  {
    "value": "69144",
    "label": "Keystone - NE 69144",
    "county": "Keith County",
    "state": "NE",
    "city": "Keystone"
  },
  {
    "value": "69145",
    "label": "Kimball - NE 69145",
    "county": "Kimball County",
    "state": "NE",
    "city": "Kimball"
  },
  {
    "value": "69146",
    "label": "Lemoyne - NE 69146",
    "county": "Keith County",
    "state": "NE",
    "city": "Lemoyne"
  },
  {
    "value": "69147",
    "label": "Lewellen - NE 69147",
    "county": "Garden County",
    "state": "NE",
    "city": "Lewellen"
  },
  {
    "value": "69148",
    "label": "Lisco - NE 69148",
    "county": "Garden County",
    "state": "NE",
    "city": "Lisco"
  },
  {
    "value": "69149",
    "label": "Lodgepole - NE 69149",
    "county": "Cheyenne County",
    "state": "NE",
    "city": "Lodgepole"
  },
  {
    "value": "69150",
    "label": "Madrid - NE 69150",
    "county": "Perkins County",
    "state": "NE",
    "city": "Madrid"
  },
  {
    "value": "69151",
    "label": "Maxwell - NE 69151",
    "county": "Lincoln County",
    "state": "NE",
    "city": "Maxwell"
  },
  {
    "value": "69152",
    "label": "Mullen - NE 69152",
    "county": "Hooker County",
    "state": "NE",
    "city": "Mullen"
  },
  {
    "value": "69153",
    "label": "Ogallala - NE 69153",
    "county": "Keith County",
    "state": "NE",
    "city": "Ogallala"
  },
  {
    "value": "69154",
    "label": "Oshkosh - NE 69154",
    "county": "Garden County",
    "state": "NE",
    "city": "Oshkosh"
  },
  {
    "value": "69155",
    "label": "Paxton - NE 69155",
    "county": "Keith County",
    "state": "NE",
    "city": "Paxton"
  },
  {
    "value": "69156",
    "label": "Potter - NE 69156",
    "county": "Cheyenne County",
    "state": "NE",
    "city": "Potter"
  },
  {
    "value": "69157",
    "label": "Purdum - NE 69157",
    "county": "Blaine County",
    "state": "NE",
    "city": "Purdum"
  },
  {
    "value": "69161",
    "label": "Seneca - NE 69161",
    "county": "Thomas County",
    "state": "NE",
    "city": "Seneca"
  },
  {
    "value": "69162",
    "label": "Sidney - NE 69162",
    "county": "Cheyenne County",
    "state": "NE",
    "city": "Sidney"
  },
  {
    "value": "69163",
    "label": "Stapleton - NE 69163",
    "county": "Logan County",
    "state": "NE",
    "city": "Stapleton"
  },
  {
    "value": "69165",
    "label": "Sutherland - NE 69165",
    "county": "Lincoln County",
    "state": "NE",
    "city": "Sutherland"
  },
  {
    "value": "69166",
    "label": "Thedford - NE 69166",
    "county": "Thomas County",
    "state": "NE",
    "city": "Thedford"
  },
  {
    "value": "69167",
    "label": "Tryon - NE 69167",
    "county": "McPherson County",
    "state": "NE",
    "city": "Tryon"
  },
  {
    "value": "69168",
    "label": "Venango - NE 69168",
    "county": "Perkins County",
    "state": "NE",
    "city": "Venango"
  },
  {
    "value": "69169",
    "label": "Wallace - NE 69169",
    "county": "Lincoln County",
    "state": "NE",
    "city": "Wallace"
  },
  {
    "value": "69170",
    "label": "Wellfleet - NE 69170",
    "county": "Lincoln County",
    "state": "NE",
    "city": "Wellfleet"
  },
  {
    "value": "69171",
    "label": "Willow Island - NE 69171",
    "county": "Dawson County",
    "state": "NE",
    "city": "Willow Island"
  },
  {
    "value": "69201",
    "label": "Valentine - NE 69201",
    "county": "Cherry County",
    "state": "NE",
    "city": "Valentine"
  },
  {
    "value": "69210",
    "label": "Ainsworth - NE 69210",
    "county": "Brown County",
    "state": "NE",
    "city": "Ainsworth"
  },
  {
    "value": "69211",
    "label": "Cody - NE 69211",
    "county": "Cherry County",
    "state": "NE",
    "city": "Cody"
  },
  {
    "value": "69212",
    "label": "Crookston - NE 69212",
    "county": "Cherry County",
    "state": "NE",
    "city": "Crookston"
  },
  {
    "value": "69214",
    "label": "Johnstown - NE 69214",
    "county": "Brown County",
    "state": "NE",
    "city": "Johnstown"
  },
  {
    "value": "69216",
    "label": "Kilgore - NE 69216",
    "county": "Cherry County",
    "state": "NE",
    "city": "Kilgore"
  },
  {
    "value": "69217",
    "label": "Long Pine - NE 69217",
    "county": "Brown County",
    "state": "NE",
    "city": "Long Pine"
  },
  {
    "value": "69218",
    "label": "Merriman - NE 69218",
    "county": "Cherry County",
    "state": "NE",
    "city": "Merriman"
  },
  {
    "value": "69219",
    "label": "Nenzel - NE 69219",
    "county": "Cherry County",
    "state": "NE",
    "city": "Nenzel"
  },
  {
    "value": "69220",
    "label": "Sparks - NE 69220",
    "county": "Cherry County",
    "state": "NE",
    "city": "Sparks"
  },
  {
    "value": "69221",
    "label": "Wood Lake - NE 69221",
    "county": "Cherry County",
    "state": "NE",
    "city": "Wood Lake"
  },
  {
    "value": "69301",
    "label": "Alliance - NE 69301",
    "county": "Box Butte County",
    "state": "NE",
    "city": "Alliance"
  },
  {
    "value": "69331",
    "label": "Angora - NE 69331",
    "county": "Morrill County",
    "state": "NE",
    "city": "Angora"
  },
  {
    "value": "69333",
    "label": "Ashby - NE 69333",
    "county": "Grant County",
    "state": "NE",
    "city": "Ashby"
  },
  {
    "value": "69334",
    "label": "Bayard - NE 69334",
    "county": "Morrill County",
    "state": "NE",
    "city": "Bayard"
  },
  {
    "value": "69335",
    "label": "Bingham - NE 69335",
    "county": "Sheridan County",
    "state": "NE",
    "city": "Bingham"
  },
  {
    "value": "69336",
    "label": "Bridgeport - NE 69336",
    "county": "Morrill County",
    "state": "NE",
    "city": "Bridgeport"
  },
  {
    "value": "69337",
    "label": "Chadron - NE 69337",
    "county": "Dawes County",
    "state": "NE",
    "city": "Chadron"
  },
  {
    "value": "69339",
    "label": "Crawford - NE 69339",
    "county": "Dawes County",
    "state": "NE",
    "city": "Crawford"
  },
  {
    "value": "69340",
    "label": "Ellsworth - NE 69340",
    "county": "Sheridan County",
    "state": "NE",
    "city": "Ellsworth"
  },
  {
    "value": "69341",
    "label": "Gering - NE 69341",
    "county": "Scotts Bluff County",
    "state": "NE",
    "city": "Gering"
  },
  {
    "value": "69343",
    "label": "Gordon - NE 69343",
    "county": "Sheridan County",
    "state": "NE",
    "city": "Gordon"
  },
  {
    "value": "69345",
    "label": "Harrisburg - NE 69345",
    "county": "Banner County",
    "state": "NE",
    "city": "Harrisburg"
  },
  {
    "value": "69346",
    "label": "Harrison - NE 69346",
    "county": "Sioux County",
    "state": "NE",
    "city": "Harrison"
  },
  {
    "value": "69347",
    "label": "Hay Springs - NE 69347",
    "county": "Sheridan County",
    "state": "NE",
    "city": "Hay Springs"
  },
  {
    "value": "69348",
    "label": "Hemingford - NE 69348",
    "county": "Box Butte County",
    "state": "NE",
    "city": "Hemingford"
  },
  {
    "value": "69350",
    "label": "Hyannis - NE 69350",
    "county": "Grant County",
    "state": "NE",
    "city": "Hyannis"
  },
  {
    "value": "69351",
    "label": "Lakeside - NE 69351",
    "county": "Sheridan County",
    "state": "NE",
    "city": "Lakeside"
  },
  {
    "value": "69352",
    "label": "Lyman - NE 69352",
    "county": "Scotts Bluff County",
    "state": "NE",
    "city": "Lyman"
  },
  {
    "value": "69354",
    "label": "Marsland - NE 69354",
    "county": "Dawes County",
    "state": "NE",
    "city": "Marsland"
  },
  {
    "value": "69356",
    "label": "Minatare - NE 69356",
    "county": "Scotts Bluff County",
    "state": "NE",
    "city": "Minatare"
  },
  {
    "value": "69357",
    "label": "Mitchell - NE 69357",
    "county": "Scotts Bluff County",
    "state": "NE",
    "city": "Mitchell"
  },
  {
    "value": "69358",
    "label": "Morrill - NE 69358",
    "county": "Scotts Bluff County",
    "state": "NE",
    "city": "Morrill"
  },
  {
    "value": "69360",
    "label": "Rushville - NE 69360",
    "county": "Sheridan County",
    "state": "NE",
    "city": "Rushville"
  },
  {
    "value": "69361",
    "label": "Scottsbluff - NE 69361",
    "county": "Scotts Bluff County",
    "state": "NE",
    "city": "Scottsbluff"
  },
  {
    "value": "69366",
    "label": "Whitman - NE 69366",
    "county": "Grant County",
    "state": "NE",
    "city": "Whitman"
  },
  {
    "value": "69367",
    "label": "Whitney - NE 69367",
    "county": "Dawes County",
    "state": "NE",
    "city": "Whitney"
  },
  {
    "value": "89001",
    "label": "Alamo - NV 89001",
    "county": "Lincoln County",
    "state": "NV",
    "city": "Alamo"
  },
  {
    "value": "89002",
    "label": "Henderson - NV 89002",
    "county": "Clark County",
    "state": "NV",
    "city": "Henderson"
  },
  {
    "value": "89005",
    "label": "Boulder City - NV 89005",
    "county": "Clark County",
    "state": "NV",
    "city": "Boulder City"
  },
  {
    "value": "89008",
    "label": "Caliente - NV 89008",
    "county": "Lincoln County",
    "state": "NV",
    "city": "Caliente"
  },
  {
    "value": "89010",
    "label": "Dyer - NV 89010",
    "county": "Esmeralda County",
    "state": "NV",
    "city": "Dyer"
  },
  {
    "value": "89011",
    "label": "Henderson - NV 89011",
    "county": "Clark County",
    "state": "NV",
    "city": "Henderson"
  },
  {
    "value": "89012",
    "label": "Henderson - NV 89012",
    "county": "Clark County",
    "state": "NV",
    "city": "Henderson"
  },
  {
    "value": "89013",
    "label": "Goldfield - NV 89013",
    "county": "Esmeralda County",
    "state": "NV",
    "city": "Goldfield"
  },
  {
    "value": "89014",
    "label": "Henderson - NV 89014",
    "county": "Clark County",
    "state": "NV",
    "city": "Henderson"
  },
  {
    "value": "89015",
    "label": "Henderson - NV 89015",
    "county": "Clark County",
    "state": "NV",
    "city": "Henderson"
  },
  {
    "value": "89017",
    "label": "Hiko - NV 89017",
    "county": "Lincoln County",
    "state": "NV",
    "city": "Hiko"
  },
  {
    "value": "89019",
    "label": "Jean - NV 89019",
    "county": "Clark County",
    "state": "NV",
    "city": "Jean"
  },
  {
    "value": "89020",
    "label": "Amargosa Valley - NV 89020",
    "county": "Nye County",
    "state": "NV",
    "city": "Amargosa Valley"
  },
  {
    "value": "89027",
    "label": "Mesquite - NV 89027",
    "county": "Clark County",
    "state": "NV",
    "city": "Mesquite"
  },
  {
    "value": "89029",
    "label": "Laughlin - NV 89029",
    "county": "Clark County",
    "state": "NV",
    "city": "Laughlin"
  },
  {
    "value": "89030",
    "label": "North Las Vegas - NV 89030",
    "county": "Clark County",
    "state": "NV",
    "city": "North Las Vegas"
  },
  {
    "value": "89031",
    "label": "North Las Vegas - NV 89031",
    "county": "Clark County",
    "state": "NV",
    "city": "North Las Vegas"
  },
  {
    "value": "89032",
    "label": "North Las Vegas - NV 89032",
    "county": "Clark County",
    "state": "NV",
    "city": "North Las Vegas"
  },
  {
    "value": "89033",
    "label": "North Las Vegas - NV 89033",
    "county": "Clark County",
    "state": "NV",
    "city": "North Las Vegas"
  },
  {
    "value": "89034",
    "label": "Mesquite - NV 89034",
    "county": "Clark County",
    "state": "NV",
    "city": "Mesquite"
  },
  {
    "value": "89039",
    "label": "Cal Nev Ari - NV 89039",
    "county": "Clark County",
    "state": "NV",
    "city": "Cal Nev Ari"
  },
  {
    "value": "89040",
    "label": "Overton - NV 89040",
    "county": "Clark County",
    "state": "NV",
    "city": "Overton"
  },
  {
    "value": "89043",
    "label": "Pioche - NV 89043",
    "county": "Lincoln County",
    "state": "NV",
    "city": "Pioche"
  },
  {
    "value": "89044",
    "label": "Henderson - NV 89044",
    "county": "Clark County",
    "state": "NV",
    "city": "Henderson"
  },
  {
    "value": "89045",
    "label": "Round Mountain - NV 89045",
    "county": "Nye County",
    "state": "NV",
    "city": "Round Mountain"
  },
  {
    "value": "89046",
    "label": "Searchlight - NV 89046",
    "county": "Clark County",
    "state": "NV",
    "city": "Searchlight"
  },
  {
    "value": "89048",
    "label": "Pahrump - NV 89048",
    "county": "Nye County",
    "state": "NV",
    "city": "Pahrump"
  },
  {
    "value": "89049",
    "label": "Tonopah - NV 89049",
    "county": "Nye County",
    "state": "NV",
    "city": "Tonopah"
  },
  {
    "value": "89052",
    "label": "Henderson - NV 89052",
    "county": "Clark County",
    "state": "NV",
    "city": "Henderson"
  },
  {
    "value": "89054",
    "label": "Sloan - NV 89054",
    "county": "Clark County",
    "state": "NV",
    "city": "Sloan"
  },
  {
    "value": "89060",
    "label": "Pahrump - NV 89060",
    "county": "Nye County",
    "state": "NV",
    "city": "Pahrump"
  },
  {
    "value": "89061",
    "label": "Pahrump - NV 89061",
    "county": "Nye County",
    "state": "NV",
    "city": "Pahrump"
  },
  {
    "value": "89067",
    "label": "Coyote Springs - NV 89067",
    "county": "Lincoln County",
    "state": "NV",
    "city": "Coyote Springs"
  },
  {
    "value": "89074",
    "label": "Henderson - NV 89074",
    "county": "Clark County",
    "state": "NV",
    "city": "Henderson"
  },
  {
    "value": "89081",
    "label": "North Las Vegas - NV 89081",
    "county": "Clark County",
    "state": "NV",
    "city": "North Las Vegas"
  },
  {
    "value": "89084",
    "label": "North Las Vegas - NV 89084",
    "county": "Clark County",
    "state": "NV",
    "city": "North Las Vegas"
  },
  {
    "value": "89085",
    "label": "North Las Vegas - NV 89085",
    "county": "Clark County",
    "state": "NV",
    "city": "North Las Vegas"
  },
  {
    "value": "89086",
    "label": "North Las Vegas - NV 89086",
    "county": "Clark County",
    "state": "NV",
    "city": "North Las Vegas"
  },
  {
    "value": "89087",
    "label": "North Las Vegas - NV 89087",
    "county": "Clark County",
    "state": "NV",
    "city": "North Las Vegas"
  },
  {
    "value": "89101",
    "label": "Las Vegas - NV 89101",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89102",
    "label": "Las Vegas - NV 89102",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89103",
    "label": "Las Vegas - NV 89103",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89104",
    "label": "Las Vegas - NV 89104",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89106",
    "label": "Las Vegas - NV 89106",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89107",
    "label": "Las Vegas - NV 89107",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89108",
    "label": "Las Vegas - NV 89108",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89109",
    "label": "Las Vegas - NV 89109",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89110",
    "label": "Las Vegas - NV 89110",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89113",
    "label": "Las Vegas - NV 89113",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89115",
    "label": "Las Vegas - NV 89115",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89117",
    "label": "Las Vegas - NV 89117",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89118",
    "label": "Las Vegas - NV 89118",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89119",
    "label": "Las Vegas - NV 89119",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89120",
    "label": "Las Vegas - NV 89120",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89121",
    "label": "Las Vegas - NV 89121",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89122",
    "label": "Las Vegas - NV 89122",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89123",
    "label": "Las Vegas - NV 89123",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89124",
    "label": "Las Vegas - NV 89124",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89128",
    "label": "Las Vegas - NV 89128",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89129",
    "label": "Las Vegas - NV 89129",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89130",
    "label": "Las Vegas - NV 89130",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89131",
    "label": "Las Vegas - NV 89131",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89134",
    "label": "Las Vegas - NV 89134",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89135",
    "label": "Las Vegas - NV 89135",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89138",
    "label": "Las Vegas - NV 89138",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89139",
    "label": "Las Vegas - NV 89139",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89141",
    "label": "Las Vegas - NV 89141",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89142",
    "label": "Las Vegas - NV 89142",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89143",
    "label": "Las Vegas - NV 89143",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89144",
    "label": "Las Vegas - NV 89144",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89145",
    "label": "Las Vegas - NV 89145",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89146",
    "label": "Las Vegas - NV 89146",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89147",
    "label": "Las Vegas - NV 89147",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89148",
    "label": "Las Vegas - NV 89148",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89149",
    "label": "Las Vegas - NV 89149",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89156",
    "label": "Las Vegas - NV 89156",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89158",
    "label": "Las Vegas - NV 89158",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89161",
    "label": "Las Vegas - NV 89161",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89165",
    "label": "Las Vegas - NV 89165",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89166",
    "label": "Las Vegas - NV 89166",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89169",
    "label": "Las Vegas - NV 89169",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89178",
    "label": "Las Vegas - NV 89178",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89179",
    "label": "Las Vegas - NV 89179",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89183",
    "label": "Las Vegas - NV 89183",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89199",
    "label": "Las Vegas - NV 89199",
    "county": "Clark County",
    "state": "NV",
    "city": "Las Vegas"
  },
  {
    "value": "89301",
    "label": "Ely - NV 89301",
    "county": "White Pine County",
    "state": "NV",
    "city": "Ely"
  },
  {
    "value": "89310",
    "label": "Austin - NV 89310",
    "county": "Lander County",
    "state": "NV",
    "city": "Austin"
  },
  {
    "value": "89311",
    "label": "Baker - NV 89311",
    "county": "White Pine County",
    "state": "NV",
    "city": "Baker"
  },
  {
    "value": "89314",
    "label": "Duckwater - NV 89314",
    "county": "Nye County",
    "state": "NV",
    "city": "Duckwater"
  },
  {
    "value": "89316",
    "label": "Eureka - NV 89316",
    "county": "Eureka County",
    "state": "NV",
    "city": "Eureka"
  },
  {
    "value": "89403",
    "label": "Dayton - NV 89403",
    "county": "Lyon County",
    "state": "NV",
    "city": "Dayton"
  },
  {
    "value": "89406",
    "label": "Fallon - NV 89406",
    "county": "Churchill County",
    "state": "NV",
    "city": "Fallon"
  },
  {
    "value": "89408",
    "label": "Fernley - NV 89408",
    "county": "Lyon County",
    "state": "NV",
    "city": "Fernley"
  },
  {
    "value": "89410",
    "label": "Gardnerville - NV 89410",
    "county": "Douglas County",
    "state": "NV",
    "city": "Gardnerville"
  },
  {
    "value": "89413",
    "label": "Glenbrook - NV 89413",
    "county": "Douglas County",
    "state": "NV",
    "city": "Glenbrook"
  },
  {
    "value": "89414",
    "label": "Golconda - NV 89414",
    "county": "Humboldt County",
    "state": "NV",
    "city": "Golconda"
  },
  {
    "value": "89415",
    "label": "Hawthorne - NV 89415",
    "county": "Mineral County",
    "state": "NV",
    "city": "Hawthorne"
  },
  {
    "value": "89418",
    "label": "Imlay - NV 89418",
    "county": "Pershing County",
    "state": "NV",
    "city": "Imlay"
  },
  {
    "value": "89419",
    "label": "Lovelock - NV 89419",
    "county": "Pershing County",
    "state": "NV",
    "city": "Lovelock"
  },
  {
    "value": "89420",
    "label": "Luning - NV 89420",
    "county": "Mineral County",
    "state": "NV",
    "city": "Luning"
  },
  {
    "value": "89423",
    "label": "Minden - NV 89423",
    "county": "Douglas County",
    "state": "NV",
    "city": "Minden"
  },
  {
    "value": "89425",
    "label": "Orovada - NV 89425",
    "county": "Humboldt County",
    "state": "NV",
    "city": "Orovada"
  },
  {
    "value": "89429",
    "label": "Silver Springs - NV 89429",
    "county": "Lyon County",
    "state": "NV",
    "city": "Silver Springs"
  },
  {
    "value": "89430",
    "label": "Smith - NV 89430",
    "county": "Lyon County",
    "state": "NV",
    "city": "Smith"
  },
  {
    "value": "89431",
    "label": "Sparks - NV 89431",
    "county": "Washoe County",
    "state": "NV",
    "city": "Sparks"
  },
  {
    "value": "89433",
    "label": "Sun Valley - NV 89433",
    "county": "Washoe County",
    "state": "NV",
    "city": "Sun Valley"
  },
  {
    "value": "89434",
    "label": "Sparks - NV 89434",
    "county": "Washoe County",
    "state": "NV",
    "city": "Sparks"
  },
  {
    "value": "89436",
    "label": "Sparks - NV 89436",
    "county": "Washoe County",
    "state": "NV",
    "city": "Sparks"
  },
  {
    "value": "89437",
    "label": "Sparks - NV 89437",
    "county": "Washoe County",
    "state": "NV",
    "city": "Sparks"
  },
  {
    "value": "89441",
    "label": "Sparks - NV 89441",
    "county": "Washoe County",
    "state": "NV",
    "city": "Sparks"
  },
  {
    "value": "89444",
    "label": "Wellington - NV 89444",
    "county": "Lyon County",
    "state": "NV",
    "city": "Wellington"
  },
  {
    "value": "89445",
    "label": "Winnemucca - NV 89445",
    "county": "Humboldt County",
    "state": "NV",
    "city": "Winnemucca"
  },
  {
    "value": "89447",
    "label": "Yerington - NV 89447",
    "county": "Lyon County",
    "state": "NV",
    "city": "Yerington"
  },
  {
    "value": "89451",
    "label": "Incline Village - NV 89451",
    "county": "Washoe County",
    "state": "NV",
    "city": "Incline Village"
  },
  {
    "value": "89460",
    "label": "Gardnerville - NV 89460",
    "county": "Douglas County",
    "state": "NV",
    "city": "Gardnerville"
  },
  {
    "value": "89501",
    "label": "Reno - NV 89501",
    "county": "Washoe County",
    "state": "NV",
    "city": "Reno"
  },
  {
    "value": "89502",
    "label": "Reno - NV 89502",
    "county": "Washoe County",
    "state": "NV",
    "city": "Reno"
  },
  {
    "value": "89503",
    "label": "Reno - NV 89503",
    "county": "Washoe County",
    "state": "NV",
    "city": "Reno"
  },
  {
    "value": "89506",
    "label": "Reno - NV 89506",
    "county": "Washoe County",
    "state": "NV",
    "city": "Reno"
  },
  {
    "value": "89508",
    "label": "Reno - NV 89508",
    "county": "Washoe County",
    "state": "NV",
    "city": "Reno"
  },
  {
    "value": "89509",
    "label": "Reno - NV 89509",
    "county": "Washoe County",
    "state": "NV",
    "city": "Reno"
  },
  {
    "value": "89510",
    "label": "Reno - NV 89510",
    "county": "Washoe County",
    "state": "NV",
    "city": "Reno"
  },
  {
    "value": "89511",
    "label": "Reno - NV 89511",
    "county": "Washoe County",
    "state": "NV",
    "city": "Reno"
  },
  {
    "value": "89512",
    "label": "Reno - NV 89512",
    "county": "Washoe County",
    "state": "NV",
    "city": "Reno"
  },
  {
    "value": "89519",
    "label": "Reno - NV 89519",
    "county": "Washoe County",
    "state": "NV",
    "city": "Reno"
  },
  {
    "value": "89521",
    "label": "Reno - NV 89521",
    "county": "Washoe County",
    "state": "NV",
    "city": "Reno"
  },
  {
    "value": "89523",
    "label": "Reno - NV 89523",
    "county": "Washoe County",
    "state": "NV",
    "city": "Reno"
  },
  {
    "value": "89701",
    "label": "Carson City - NV 89701",
    "county": "Carson City",
    "state": "NV",
    "city": "Carson City"
  },
  {
    "value": "89703",
    "label": "Carson City - NV 89703",
    "county": "Carson City",
    "state": "NV",
    "city": "Carson City"
  },
  {
    "value": "89704",
    "label": "Washoe Valley - NV 89704",
    "county": "Washoe County",
    "state": "NV",
    "city": "Washoe Valley"
  },
  {
    "value": "89705",
    "label": "Carson City - NV 89705",
    "county": "Douglas County",
    "state": "NV",
    "city": "Carson City"
  },
  {
    "value": "89706",
    "label": "Carson City - NV 89706",
    "county": "Carson City",
    "state": "NV",
    "city": "Carson City"
  },
  {
    "value": "89801",
    "label": "Elko - NV 89801",
    "county": "Elko County",
    "state": "NV",
    "city": "Elko"
  },
  {
    "value": "89815",
    "label": "Spring Creek - NV 89815",
    "county": "Elko County",
    "state": "NV",
    "city": "Spring Creek"
  },
  {
    "value": "89820",
    "label": "Battle Mountain - NV 89820",
    "county": "Lander County",
    "state": "NV",
    "city": "Battle Mountain"
  },
  {
    "value": "89821",
    "label": "Crescent Valley - NV 89821",
    "county": "Eureka County",
    "state": "NV",
    "city": "Crescent Valley"
  },
  {
    "value": "89822",
    "label": "Carlin - NV 89822",
    "county": "Elko County",
    "state": "NV",
    "city": "Carlin"
  },
  {
    "value": "89823",
    "label": "Deeth - NV 89823",
    "county": "Elko County",
    "state": "NV",
    "city": "Deeth"
  },
  {
    "value": "89825",
    "label": "Jackpot - NV 89825",
    "county": "Elko County",
    "state": "NV",
    "city": "Jackpot"
  },
  {
    "value": "89828",
    "label": "Lamoille - NV 89828",
    "county": "Elko County",
    "state": "NV",
    "city": "Lamoille"
  },
  {
    "value": "89831",
    "label": "Mountain City - NV 89831",
    "county": "Elko County",
    "state": "NV",
    "city": "Mountain City"
  },
  {
    "value": "89833",
    "label": "Ruby Valley - NV 89833",
    "county": "Elko County",
    "state": "NV",
    "city": "Ruby Valley"
  },
  {
    "value": "89834",
    "label": "Tuscarora - NV 89834",
    "county": "Elko County",
    "state": "NV",
    "city": "Tuscarora"
  },
  {
    "value": "89835",
    "label": "Wells - NV 89835",
    "county": "Elko County",
    "state": "NV",
    "city": "Wells"
  },
  {
    "value": "03031",
    "label": "Amherst - NH 03031",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Amherst"
  },
  {
    "value": "03032",
    "label": "Auburn - NH 03032",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Auburn"
  },
  {
    "value": "03033",
    "label": "Brookline - NH 03033",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Brookline"
  },
  {
    "value": "03034",
    "label": "Candia - NH 03034",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Candia"
  },
  {
    "value": "03036",
    "label": "Chester - NH 03036",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Chester"
  },
  {
    "value": "03037",
    "label": "Deerfield - NH 03037",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Deerfield"
  },
  {
    "value": "03038",
    "label": "Derry - NH 03038",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Derry"
  },
  {
    "value": "03042",
    "label": "Epping - NH 03042",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Epping"
  },
  {
    "value": "03043",
    "label": "Francestown - NH 03043",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Francestown"
  },
  {
    "value": "03044",
    "label": "Fremont - NH 03044",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Fremont"
  },
  {
    "value": "03045",
    "label": "Goffstown - NH 03045",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Goffstown"
  },
  {
    "value": "03046",
    "label": "Dunbarton - NH 03046",
    "county": "Merrimack County",
    "state": "NH",
    "city": "Dunbarton"
  },
  {
    "value": "03047",
    "label": "Greenfield - NH 03047",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Greenfield"
  },
  {
    "value": "03048",
    "label": "Greenville - NH 03048",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Greenville"
  },
  {
    "value": "03049",
    "label": "Hollis - NH 03049",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Hollis"
  },
  {
    "value": "03051",
    "label": "Hudson - NH 03051",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Hudson"
  },
  {
    "value": "03052",
    "label": "Litchfield - NH 03052",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Litchfield"
  },
  {
    "value": "03053",
    "label": "Londonderry - NH 03053",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Londonderry"
  },
  {
    "value": "03054",
    "label": "Merrimack - NH 03054",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Merrimack"
  },
  {
    "value": "03055",
    "label": "Milford - NH 03055",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Milford"
  },
  {
    "value": "03057",
    "label": "Mont Vernon - NH 03057",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Mont Vernon"
  },
  {
    "value": "03060",
    "label": "Nashua - NH 03060",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Nashua"
  },
  {
    "value": "03062",
    "label": "Nashua - NH 03062",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Nashua"
  },
  {
    "value": "03063",
    "label": "Nashua - NH 03063",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Nashua"
  },
  {
    "value": "03064",
    "label": "Nashua - NH 03064",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Nashua"
  },
  {
    "value": "03070",
    "label": "New Boston - NH 03070",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "New Boston"
  },
  {
    "value": "03071",
    "label": "New Ipswich - NH 03071",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "New Ipswich"
  },
  {
    "value": "03076",
    "label": "Pelham - NH 03076",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Pelham"
  },
  {
    "value": "03077",
    "label": "Raymond - NH 03077",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Raymond"
  },
  {
    "value": "03079",
    "label": "Salem - NH 03079",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Salem"
  },
  {
    "value": "03082",
    "label": "Lyndeborough - NH 03082",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Lyndeborough"
  },
  {
    "value": "03084",
    "label": "Temple - NH 03084",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Temple"
  },
  {
    "value": "03086",
    "label": "Wilton - NH 03086",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Wilton"
  },
  {
    "value": "03087",
    "label": "Windham - NH 03087",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Windham"
  },
  {
    "value": "03101",
    "label": "Manchester - NH 03101",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Manchester"
  },
  {
    "value": "03102",
    "label": "Manchester - NH 03102",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Manchester"
  },
  {
    "value": "03103",
    "label": "Manchester - NH 03103",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Manchester"
  },
  {
    "value": "03104",
    "label": "Manchester - NH 03104",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Manchester"
  },
  {
    "value": "03106",
    "label": "Hooksett - NH 03106",
    "county": "Merrimack County",
    "state": "NH",
    "city": "Hooksett"
  },
  {
    "value": "03109",
    "label": "Manchester - NH 03109",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Manchester"
  },
  {
    "value": "03110",
    "label": "Bedford - NH 03110",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Bedford"
  },
  {
    "value": "03216",
    "label": "Andover - NH 03216",
    "county": "Merrimack County",
    "state": "NH",
    "city": "Andover"
  },
  {
    "value": "03217",
    "label": "Ashland - NH 03217",
    "county": "Grafton County",
    "state": "NH",
    "city": "Ashland"
  },
  {
    "value": "03218",
    "label": "Barnstead - NH 03218",
    "county": "Belknap County",
    "state": "NH",
    "city": "Barnstead"
  },
  {
    "value": "03220",
    "label": "Belmont - NH 03220",
    "county": "Belknap County",
    "state": "NH",
    "city": "Belmont"
  },
  {
    "value": "03221",
    "label": "Bradford - NH 03221",
    "county": "Merrimack County",
    "state": "NH",
    "city": "Bradford"
  },
  {
    "value": "03222",
    "label": "Bristol - NH 03222",
    "county": "Grafton County",
    "state": "NH",
    "city": "Bristol"
  },
  {
    "value": "03223",
    "label": "Campton - NH 03223",
    "county": "Grafton County",
    "state": "NH",
    "city": "Campton"
  },
  {
    "value": "03224",
    "label": "Canterbury - NH 03224",
    "county": "Merrimack County",
    "state": "NH",
    "city": "Canterbury"
  },
  {
    "value": "03225",
    "label": "Center Barnstead - NH 03225",
    "county": "Belknap County",
    "state": "NH",
    "city": "Center Barnstead"
  },
  {
    "value": "03226",
    "label": "Center Harbor - NH 03226",
    "county": "Belknap County",
    "state": "NH",
    "city": "Center Harbor"
  },
  {
    "value": "03227",
    "label": "Center Sandwich - NH 03227",
    "county": "Carroll County",
    "state": "NH",
    "city": "Center Sandwich"
  },
  {
    "value": "03229",
    "label": "Contoocook - NH 03229",
    "county": "Merrimack County",
    "state": "NH",
    "city": "Contoocook"
  },
  {
    "value": "03230",
    "label": "Danbury - NH 03230",
    "county": "Merrimack County",
    "state": "NH",
    "city": "Danbury"
  },
  {
    "value": "03233",
    "label": "Elkins - NH 03233",
    "county": "Merrimack County",
    "state": "NH",
    "city": "Elkins"
  },
  {
    "value": "03234",
    "label": "Epsom - NH 03234",
    "county": "Merrimack County",
    "state": "NH",
    "city": "Epsom"
  },
  {
    "value": "03235",
    "label": "Franklin - NH 03235",
    "county": "Merrimack County",
    "state": "NH",
    "city": "Franklin"
  },
  {
    "value": "03237",
    "label": "Gilmanton - NH 03237",
    "county": "Belknap County",
    "state": "NH",
    "city": "Gilmanton"
  },
  {
    "value": "03240",
    "label": "Grafton - NH 03240",
    "county": "Grafton County",
    "state": "NH",
    "city": "Grafton"
  },
  {
    "value": "03241",
    "label": "Hebron - NH 03241",
    "county": "Grafton County",
    "state": "NH",
    "city": "Hebron"
  },
  {
    "value": "03242",
    "label": "Henniker - NH 03242",
    "county": "Merrimack County",
    "state": "NH",
    "city": "Henniker"
  },
  {
    "value": "03243",
    "label": "Hill - NH 03243",
    "county": "Merrimack County",
    "state": "NH",
    "city": "Hill"
  },
  {
    "value": "03244",
    "label": "Hillsborough - NH 03244",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Hillsborough"
  },
  {
    "value": "03245",
    "label": "Holderness - NH 03245",
    "county": "Grafton County",
    "state": "NH",
    "city": "Holderness"
  },
  {
    "value": "03246",
    "label": "Laconia - NH 03246",
    "county": "Belknap County",
    "state": "NH",
    "city": "Laconia"
  },
  {
    "value": "03249",
    "label": "Gilford - NH 03249",
    "county": "Belknap County",
    "state": "NH",
    "city": "Gilford"
  },
  {
    "value": "03251",
    "label": "Lincoln - NH 03251",
    "county": "Grafton County",
    "state": "NH",
    "city": "Lincoln"
  },
  {
    "value": "03253",
    "label": "Meredith - NH 03253",
    "county": "Belknap County",
    "state": "NH",
    "city": "Meredith"
  },
  {
    "value": "03254",
    "label": "Moultonborough - NH 03254",
    "county": "Carroll County",
    "state": "NH",
    "city": "Moultonborough"
  },
  {
    "value": "03255",
    "label": "Newbury - NH 03255",
    "county": "Merrimack County",
    "state": "NH",
    "city": "Newbury"
  },
  {
    "value": "03256",
    "label": "New Hampton - NH 03256",
    "county": "Belknap County",
    "state": "NH",
    "city": "New Hampton"
  },
  {
    "value": "03257",
    "label": "New London - NH 03257",
    "county": "Merrimack County",
    "state": "NH",
    "city": "New London"
  },
  {
    "value": "03258",
    "label": "Chichester - NH 03258",
    "county": "Merrimack County",
    "state": "NH",
    "city": "Chichester"
  },
  {
    "value": "03259",
    "label": "North Sandwich - NH 03259",
    "county": "Carroll County",
    "state": "NH",
    "city": "North Sandwich"
  },
  {
    "value": "03261",
    "label": "Northwood - NH 03261",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Northwood"
  },
  {
    "value": "03262",
    "label": "North Woodstock - NH 03262",
    "county": "Grafton County",
    "state": "NH",
    "city": "North Woodstock"
  },
  {
    "value": "03263",
    "label": "Pittsfield - NH 03263",
    "county": "Merrimack County",
    "state": "NH",
    "city": "Pittsfield"
  },
  {
    "value": "03264",
    "label": "Plymouth - NH 03264",
    "county": "Grafton County",
    "state": "NH",
    "city": "Plymouth"
  },
  {
    "value": "03266",
    "label": "Rumney - NH 03266",
    "county": "Grafton County",
    "state": "NH",
    "city": "Rumney"
  },
  {
    "value": "03268",
    "label": "Salisbury - NH 03268",
    "county": "Merrimack County",
    "state": "NH",
    "city": "Salisbury"
  },
  {
    "value": "03269",
    "label": "Sanbornton - NH 03269",
    "county": "Belknap County",
    "state": "NH",
    "city": "Sanbornton"
  },
  {
    "value": "03275",
    "label": "Suncook - NH 03275",
    "county": "Merrimack County",
    "state": "NH",
    "city": "Suncook"
  },
  {
    "value": "03276",
    "label": "Tilton - NH 03276",
    "county": "Merrimack County",
    "state": "NH",
    "city": "Tilton"
  },
  {
    "value": "03278",
    "label": "Warner - NH 03278",
    "county": "Merrimack County",
    "state": "NH",
    "city": "Warner"
  },
  {
    "value": "03279",
    "label": "Warren - NH 03279",
    "county": "Grafton County",
    "state": "NH",
    "city": "Warren"
  },
  {
    "value": "03280",
    "label": "Washington - NH 03280",
    "county": "Sullivan County",
    "state": "NH",
    "city": "Washington"
  },
  {
    "value": "03281",
    "label": "Weare - NH 03281",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Weare"
  },
  {
    "value": "03282",
    "label": "Wentworth - NH 03282",
    "county": "Grafton County",
    "state": "NH",
    "city": "Wentworth"
  },
  {
    "value": "03284",
    "label": "Springfield - NH 03284",
    "county": "Sullivan County",
    "state": "NH",
    "city": "Springfield"
  },
  {
    "value": "03285",
    "label": "Thornton - NH 03285",
    "county": "Grafton County",
    "state": "NH",
    "city": "Thornton"
  },
  {
    "value": "03287",
    "label": "Wilmot - NH 03287",
    "county": "Merrimack County",
    "state": "NH",
    "city": "Wilmot"
  },
  {
    "value": "03290",
    "label": "Nottingham - NH 03290",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Nottingham"
  },
  {
    "value": "03291",
    "label": "West Nottingham - NH 03291",
    "county": "Rockingham County",
    "state": "NH",
    "city": "West Nottingham"
  },
  {
    "value": "03301",
    "label": "Concord - NH 03301",
    "county": "Merrimack County",
    "state": "NH",
    "city": "Concord"
  },
  {
    "value": "03303",
    "label": "Concord - NH 03303",
    "county": "Merrimack County",
    "state": "NH",
    "city": "Concord"
  },
  {
    "value": "03304",
    "label": "Bow - NH 03304",
    "county": "Merrimack County",
    "state": "NH",
    "city": "Bow"
  },
  {
    "value": "03307",
    "label": "Loudon - NH 03307",
    "county": "Merrimack County",
    "state": "NH",
    "city": "Loudon"
  },
  {
    "value": "03431",
    "label": "Keene - NH 03431",
    "county": "Cheshire County",
    "state": "NH",
    "city": "Keene"
  },
  {
    "value": "03440",
    "label": "Antrim - NH 03440",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Antrim"
  },
  {
    "value": "03441",
    "label": "Ashuelot - NH 03441",
    "county": "Cheshire County",
    "state": "NH",
    "city": "Ashuelot"
  },
  {
    "value": "03442",
    "label": "Bennington - NH 03442",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Bennington"
  },
  {
    "value": "03443",
    "label": "Chesterfield - NH 03443",
    "county": "Cheshire County",
    "state": "NH",
    "city": "Chesterfield"
  },
  {
    "value": "03444",
    "label": "Dublin - NH 03444",
    "county": "Cheshire County",
    "state": "NH",
    "city": "Dublin"
  },
  {
    "value": "03445",
    "label": "Sullivan - NH 03445",
    "county": "Cheshire County",
    "state": "NH",
    "city": "Sullivan"
  },
  {
    "value": "03446",
    "label": "Swanzey - NH 03446",
    "county": "Cheshire County",
    "state": "NH",
    "city": "Swanzey"
  },
  {
    "value": "03447",
    "label": "Fitzwilliam - NH 03447",
    "county": "Cheshire County",
    "state": "NH",
    "city": "Fitzwilliam"
  },
  {
    "value": "03448",
    "label": "Gilsum - NH 03448",
    "county": "Cheshire County",
    "state": "NH",
    "city": "Gilsum"
  },
  {
    "value": "03449",
    "label": "Hancock - NH 03449",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Hancock"
  },
  {
    "value": "03450",
    "label": "Harrisville - NH 03450",
    "county": "Cheshire County",
    "state": "NH",
    "city": "Harrisville"
  },
  {
    "value": "03451",
    "label": "Hinsdale - NH 03451",
    "county": "Cheshire County",
    "state": "NH",
    "city": "Hinsdale"
  },
  {
    "value": "03452",
    "label": "Jaffrey - NH 03452",
    "county": "Cheshire County",
    "state": "NH",
    "city": "Jaffrey"
  },
  {
    "value": "03455",
    "label": "Marlborough - NH 03455",
    "county": "Cheshire County",
    "state": "NH",
    "city": "Marlborough"
  },
  {
    "value": "03456",
    "label": "Marlow - NH 03456",
    "county": "Cheshire County",
    "state": "NH",
    "city": "Marlow"
  },
  {
    "value": "03457",
    "label": "Nelson - NH 03457",
    "county": "Cheshire County",
    "state": "NH",
    "city": "Nelson"
  },
  {
    "value": "03458",
    "label": "Peterborough - NH 03458",
    "county": "Hillsborough County",
    "state": "NH",
    "city": "Peterborough"
  },
  {
    "value": "03461",
    "label": "Rindge - NH 03461",
    "county": "Cheshire County",
    "state": "NH",
    "city": "Rindge"
  },
  {
    "value": "03462",
    "label": "Spofford - NH 03462",
    "county": "Cheshire County",
    "state": "NH",
    "city": "Spofford"
  },
  {
    "value": "03464",
    "label": "Stoddard - NH 03464",
    "county": "Cheshire County",
    "state": "NH",
    "city": "Stoddard"
  },
  {
    "value": "03465",
    "label": "Troy - NH 03465",
    "county": "Cheshire County",
    "state": "NH",
    "city": "Troy"
  },
  {
    "value": "03466",
    "label": "West Chesterfield - NH 03466",
    "county": "Cheshire County",
    "state": "NH",
    "city": "West Chesterfield"
  },
  {
    "value": "03467",
    "label": "Westmoreland - NH 03467",
    "county": "Cheshire County",
    "state": "NH",
    "city": "Westmoreland"
  },
  {
    "value": "03470",
    "label": "Winchester - NH 03470",
    "county": "Cheshire County",
    "state": "NH",
    "city": "Winchester"
  },
  {
    "value": "03561",
    "label": "Littleton - NH 03561",
    "county": "Grafton County",
    "state": "NH",
    "city": "Littleton"
  },
  {
    "value": "03570",
    "label": "Berlin - NH 03570",
    "county": "Coos County",
    "state": "NH",
    "city": "Berlin"
  },
  {
    "value": "03574",
    "label": "Bethlehem - NH 03574",
    "county": "Grafton County",
    "state": "NH",
    "city": "Bethlehem"
  },
  {
    "value": "03576",
    "label": "Colebrook - NH 03576",
    "county": "Coos County",
    "state": "NH",
    "city": "Colebrook"
  },
  {
    "value": "03579",
    "label": "Errol - NH 03579",
    "county": "Coos County",
    "state": "NH",
    "city": "Errol"
  },
  {
    "value": "03580",
    "label": "Franconia - NH 03580",
    "county": "Grafton County",
    "state": "NH",
    "city": "Franconia"
  },
  {
    "value": "03581",
    "label": "Gorham - NH 03581",
    "county": "Coos County",
    "state": "NH",
    "city": "Gorham"
  },
  {
    "value": "03582",
    "label": "Groveton - NH 03582",
    "county": "Coos County",
    "state": "NH",
    "city": "Groveton"
  },
  {
    "value": "03583",
    "label": "Jefferson - NH 03583",
    "county": "Coos County",
    "state": "NH",
    "city": "Jefferson"
  },
  {
    "value": "03584",
    "label": "Lancaster - NH 03584",
    "county": "Coos County",
    "state": "NH",
    "city": "Lancaster"
  },
  {
    "value": "03585",
    "label": "Lisbon - NH 03585",
    "county": "Grafton County",
    "state": "NH",
    "city": "Lisbon"
  },
  {
    "value": "03586",
    "label": "Sugar Hill - NH 03586",
    "county": "Grafton County",
    "state": "NH",
    "city": "Sugar Hill"
  },
  {
    "value": "03588",
    "label": "Milan - NH 03588",
    "county": "Coos County",
    "state": "NH",
    "city": "Milan"
  },
  {
    "value": "03590",
    "label": "North Stratford - NH 03590",
    "county": "Coos County",
    "state": "NH",
    "city": "North Stratford"
  },
  {
    "value": "03592",
    "label": "Pittsburg - NH 03592",
    "county": "Coos County",
    "state": "NH",
    "city": "Pittsburg"
  },
  {
    "value": "03593",
    "label": "Randolph - NH 03593",
    "county": "Coos County",
    "state": "NH",
    "city": "Randolph"
  },
  {
    "value": "03598",
    "label": "Whitefield - NH 03598",
    "county": "Coos County",
    "state": "NH",
    "city": "Whitefield"
  },
  {
    "value": "03601",
    "label": "Acworth - NH 03601",
    "county": "Sullivan County",
    "state": "NH",
    "city": "Acworth"
  },
  {
    "value": "03602",
    "label": "Alstead - NH 03602",
    "county": "Cheshire County",
    "state": "NH",
    "city": "Alstead"
  },
  {
    "value": "03603",
    "label": "Charlestown - NH 03603",
    "county": "Sullivan County",
    "state": "NH",
    "city": "Charlestown"
  },
  {
    "value": "03605",
    "label": "Lempster - NH 03605",
    "county": "Sullivan County",
    "state": "NH",
    "city": "Lempster"
  },
  {
    "value": "03607",
    "label": "South Acworth - NH 03607",
    "county": "Sullivan County",
    "state": "NH",
    "city": "South Acworth"
  },
  {
    "value": "03608",
    "label": "Walpole - NH 03608",
    "county": "Cheshire County",
    "state": "NH",
    "city": "Walpole"
  },
  {
    "value": "03609",
    "label": "North Walpole - NH 03609",
    "county": "Cheshire County",
    "state": "NH",
    "city": "North Walpole"
  },
  {
    "value": "03740",
    "label": "Bath - NH 03740",
    "county": "Grafton County",
    "state": "NH",
    "city": "Bath"
  },
  {
    "value": "03741",
    "label": "Canaan - NH 03741",
    "county": "Grafton County",
    "state": "NH",
    "city": "Canaan"
  },
  {
    "value": "03743",
    "label": "Claremont - NH 03743",
    "county": "Sullivan County",
    "state": "NH",
    "city": "Claremont"
  },
  {
    "value": "03745",
    "label": "Cornish - NH 03745",
    "county": "Sullivan County",
    "state": "NH",
    "city": "Cornish"
  },
  {
    "value": "03748",
    "label": "Enfield - NH 03748",
    "county": "Grafton County",
    "state": "NH",
    "city": "Enfield"
  },
  {
    "value": "03750",
    "label": "Etna - NH 03750",
    "county": "Grafton County",
    "state": "NH",
    "city": "Etna"
  },
  {
    "value": "03752",
    "label": "Goshen - NH 03752",
    "county": "Sullivan County",
    "state": "NH",
    "city": "Goshen"
  },
  {
    "value": "03753",
    "label": "Grantham - NH 03753",
    "county": "Sullivan County",
    "state": "NH",
    "city": "Grantham"
  },
  {
    "value": "03755",
    "label": "Hanover - NH 03755",
    "county": "Grafton County",
    "state": "NH",
    "city": "Hanover"
  },
  {
    "value": "03756",
    "label": "Lebanon - NH 03756",
    "county": "Grafton County",
    "state": "NH",
    "city": "Lebanon"
  },
  {
    "value": "03765",
    "label": "Haverhill - NH 03765",
    "county": "Grafton County",
    "state": "NH",
    "city": "Haverhill"
  },
  {
    "value": "03766",
    "label": "Lebanon - NH 03766",
    "county": "Grafton County",
    "state": "NH",
    "city": "Lebanon"
  },
  {
    "value": "03768",
    "label": "Lyme - NH 03768",
    "county": "Grafton County",
    "state": "NH",
    "city": "Lyme"
  },
  {
    "value": "03770",
    "label": "Meriden - NH 03770",
    "county": "Sullivan County",
    "state": "NH",
    "city": "Meriden"
  },
  {
    "value": "03771",
    "label": "Monroe - NH 03771",
    "county": "Grafton County",
    "state": "NH",
    "city": "Monroe"
  },
  {
    "value": "03773",
    "label": "Newport - NH 03773",
    "county": "Sullivan County",
    "state": "NH",
    "city": "Newport"
  },
  {
    "value": "03774",
    "label": "North Haverhill - NH 03774",
    "county": "Grafton County",
    "state": "NH",
    "city": "North Haverhill"
  },
  {
    "value": "03777",
    "label": "Orford - NH 03777",
    "county": "Grafton County",
    "state": "NH",
    "city": "Orford"
  },
  {
    "value": "03779",
    "label": "Piermont - NH 03779",
    "county": "Grafton County",
    "state": "NH",
    "city": "Piermont"
  },
  {
    "value": "03780",
    "label": "Pike - NH 03780",
    "county": "Grafton County",
    "state": "NH",
    "city": "Pike"
  },
  {
    "value": "03781",
    "label": "Plainfield - NH 03781",
    "county": "Sullivan County",
    "state": "NH",
    "city": "Plainfield"
  },
  {
    "value": "03782",
    "label": "Sunapee - NH 03782",
    "county": "Sullivan County",
    "state": "NH",
    "city": "Sunapee"
  },
  {
    "value": "03784",
    "label": "West Lebanon - NH 03784",
    "county": "Grafton County",
    "state": "NH",
    "city": "West Lebanon"
  },
  {
    "value": "03785",
    "label": "Woodsville - NH 03785",
    "county": "Grafton County",
    "state": "NH",
    "city": "Woodsville"
  },
  {
    "value": "03801",
    "label": "Portsmouth - NH 03801",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Portsmouth"
  },
  {
    "value": "03805",
    "label": "Newington - NH 03805",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Newington"
  },
  {
    "value": "03809",
    "label": "Alton - NH 03809",
    "county": "Belknap County",
    "state": "NH",
    "city": "Alton"
  },
  {
    "value": "03810",
    "label": "Alton Bay - NH 03810",
    "county": "Belknap County",
    "state": "NH",
    "city": "Alton Bay"
  },
  {
    "value": "03811",
    "label": "Atkinson - NH 03811",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Atkinson"
  },
  {
    "value": "03812",
    "label": "Bartlett - NH 03812",
    "county": "Carroll County",
    "state": "NH",
    "city": "Bartlett"
  },
  {
    "value": "03813",
    "label": "Center Conway - NH 03813",
    "county": "Carroll County",
    "state": "NH",
    "city": "Center Conway"
  },
  {
    "value": "03814",
    "label": "Center Ossipee - NH 03814",
    "county": "Carroll County",
    "state": "NH",
    "city": "Center Ossipee"
  },
  {
    "value": "03816",
    "label": "Center Tuftonboro - NH 03816",
    "county": "Carroll County",
    "state": "NH",
    "city": "Center Tuftonboro"
  },
  {
    "value": "03817",
    "label": "Chocorua - NH 03817",
    "county": "Carroll County",
    "state": "NH",
    "city": "Chocorua"
  },
  {
    "value": "03818",
    "label": "Conway - NH 03818",
    "county": "Carroll County",
    "state": "NH",
    "city": "Conway"
  },
  {
    "value": "03819",
    "label": "Danville - NH 03819",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Danville"
  },
  {
    "value": "03820",
    "label": "Dover - NH 03820",
    "county": "Strafford County",
    "state": "NH",
    "city": "Dover"
  },
  {
    "value": "03823",
    "label": "Madbury - NH 03823",
    "county": "Strafford County",
    "state": "NH",
    "city": "Madbury"
  },
  {
    "value": "03824",
    "label": "Durham - NH 03824",
    "county": "Strafford County",
    "state": "NH",
    "city": "Durham"
  },
  {
    "value": "03825",
    "label": "Barrington - NH 03825",
    "county": "Strafford County",
    "state": "NH",
    "city": "Barrington"
  },
  {
    "value": "03826",
    "label": "East Hampstead - NH 03826",
    "county": "Rockingham County",
    "state": "NH",
    "city": "East Hampstead"
  },
  {
    "value": "03827",
    "label": "East Kingston - NH 03827",
    "county": "Rockingham County",
    "state": "NH",
    "city": "East Kingston"
  },
  {
    "value": "03830",
    "label": "East Wakefield - NH 03830",
    "county": "Carroll County",
    "state": "NH",
    "city": "East Wakefield"
  },
  {
    "value": "03833",
    "label": "Exeter - NH 03833",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Exeter"
  },
  {
    "value": "03835",
    "label": "Farmington - NH 03835",
    "county": "Strafford County",
    "state": "NH",
    "city": "Farmington"
  },
  {
    "value": "03836",
    "label": "Freedom - NH 03836",
    "county": "Carroll County",
    "state": "NH",
    "city": "Freedom"
  },
  {
    "value": "03837",
    "label": "Gilmanton Iron Works - NH 03837",
    "county": "Belknap County",
    "state": "NH",
    "city": "Gilmanton Iron Works"
  },
  {
    "value": "03838",
    "label": "Glen - NH 03838",
    "county": "Carroll County",
    "state": "NH",
    "city": "Glen"
  },
  {
    "value": "03839",
    "label": "Rochester - NH 03839",
    "county": "Strafford County",
    "state": "NH",
    "city": "Rochester"
  },
  {
    "value": "03840",
    "label": "Greenland - NH 03840",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Greenland"
  },
  {
    "value": "03841",
    "label": "Hampstead - NH 03841",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Hampstead"
  },
  {
    "value": "03842",
    "label": "Hampton - NH 03842",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Hampton"
  },
  {
    "value": "03844",
    "label": "Hampton Falls - NH 03844",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Hampton Falls"
  },
  {
    "value": "03845",
    "label": "Intervale - NH 03845",
    "county": "Carroll County",
    "state": "NH",
    "city": "Intervale"
  },
  {
    "value": "03846",
    "label": "Jackson - NH 03846",
    "county": "Carroll County",
    "state": "NH",
    "city": "Jackson"
  },
  {
    "value": "03848",
    "label": "Kingston - NH 03848",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Kingston"
  },
  {
    "value": "03849",
    "label": "Madison - NH 03849",
    "county": "Carroll County",
    "state": "NH",
    "city": "Madison"
  },
  {
    "value": "03851",
    "label": "Milton - NH 03851",
    "county": "Strafford County",
    "state": "NH",
    "city": "Milton"
  },
  {
    "value": "03852",
    "label": "Milton Mills - NH 03852",
    "county": "Strafford County",
    "state": "NH",
    "city": "Milton Mills"
  },
  {
    "value": "03853",
    "label": "Mirror Lake - NH 03853",
    "county": "Carroll County",
    "state": "NH",
    "city": "Mirror Lake"
  },
  {
    "value": "03855",
    "label": "New Durham - NH 03855",
    "county": "Strafford County",
    "state": "NH",
    "city": "New Durham"
  },
  {
    "value": "03856",
    "label": "Newfields - NH 03856",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Newfields"
  },
  {
    "value": "03857",
    "label": "Newmarket - NH 03857",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Newmarket"
  },
  {
    "value": "03858",
    "label": "Newton - NH 03858",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Newton"
  },
  {
    "value": "03860",
    "label": "North Conway - NH 03860",
    "county": "Carroll County",
    "state": "NH",
    "city": "North Conway"
  },
  {
    "value": "03861",
    "label": "Lee - NH 03861",
    "county": "Strafford County",
    "state": "NH",
    "city": "Lee"
  },
  {
    "value": "03862",
    "label": "North Hampton - NH 03862",
    "county": "Rockingham County",
    "state": "NH",
    "city": "North Hampton"
  },
  {
    "value": "03864",
    "label": "Ossipee - NH 03864",
    "county": "Carroll County",
    "state": "NH",
    "city": "Ossipee"
  },
  {
    "value": "03865",
    "label": "Plaistow - NH 03865",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Plaistow"
  },
  {
    "value": "03867",
    "label": "Rochester - NH 03867",
    "county": "Strafford County",
    "state": "NH",
    "city": "Rochester"
  },
  {
    "value": "03868",
    "label": "Rochester - NH 03868",
    "county": "Strafford County",
    "state": "NH",
    "city": "Rochester"
  },
  {
    "value": "03869",
    "label": "Rollinsford - NH 03869",
    "county": "Strafford County",
    "state": "NH",
    "city": "Rollinsford"
  },
  {
    "value": "03870",
    "label": "Rye - NH 03870",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Rye"
  },
  {
    "value": "03872",
    "label": "Sanbornville - NH 03872",
    "county": "Carroll County",
    "state": "NH",
    "city": "Sanbornville"
  },
  {
    "value": "03873",
    "label": "Sandown - NH 03873",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Sandown"
  },
  {
    "value": "03874",
    "label": "Seabrook - NH 03874",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Seabrook"
  },
  {
    "value": "03875",
    "label": "Silver Lake - NH 03875",
    "county": "Carroll County",
    "state": "NH",
    "city": "Silver Lake"
  },
  {
    "value": "03878",
    "label": "Somersworth - NH 03878",
    "county": "Strafford County",
    "state": "NH",
    "city": "Somersworth"
  },
  {
    "value": "03882",
    "label": "Effingham - NH 03882",
    "county": "Carroll County",
    "state": "NH",
    "city": "Effingham"
  },
  {
    "value": "03883",
    "label": "South Tamworth - NH 03883",
    "county": "Carroll County",
    "state": "NH",
    "city": "South Tamworth"
  },
  {
    "value": "03884",
    "label": "Strafford - NH 03884",
    "county": "Strafford County",
    "state": "NH",
    "city": "Strafford"
  },
  {
    "value": "03885",
    "label": "Stratham - NH 03885",
    "county": "Rockingham County",
    "state": "NH",
    "city": "Stratham"
  },
  {
    "value": "03886",
    "label": "Tamworth - NH 03886",
    "county": "Carroll County",
    "state": "NH",
    "city": "Tamworth"
  },
  {
    "value": "03887",
    "label": "Union - NH 03887",
    "county": "Strafford County",
    "state": "NH",
    "city": "Union"
  },
  {
    "value": "03890",
    "label": "West Ossipee - NH 03890",
    "county": "Carroll County",
    "state": "NH",
    "city": "West Ossipee"
  },
  {
    "value": "03894",
    "label": "Wolfeboro - NH 03894",
    "county": "Carroll County",
    "state": "NH",
    "city": "Wolfeboro"
  },
  {
    "value": "03897",
    "label": "Wonalancet - NH 03897",
    "county": "Carroll County",
    "state": "NH",
    "city": "Wonalancet"
  },
  {
    "value": "07001",
    "label": "Avenel - NJ 07001",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Avenel"
  },
  {
    "value": "07002",
    "label": "Bayonne - NJ 07002",
    "county": "Hudson County",
    "state": "NJ",
    "city": "Bayonne"
  },
  {
    "value": "07003",
    "label": "Bloomfield - NJ 07003",
    "county": "Essex County",
    "state": "NJ",
    "city": "Bloomfield"
  },
  {
    "value": "07004",
    "label": "Fairfield - NJ 07004",
    "county": "Essex County",
    "state": "NJ",
    "city": "Fairfield"
  },
  {
    "value": "07005",
    "label": "Boonton - NJ 07005",
    "county": "Morris County",
    "state": "NJ",
    "city": "Boonton"
  },
  {
    "value": "07006",
    "label": "Caldwell - NJ 07006",
    "county": "Essex County",
    "state": "NJ",
    "city": "Caldwell"
  },
  {
    "value": "07008",
    "label": "Carteret - NJ 07008",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Carteret"
  },
  {
    "value": "07009",
    "label": "Cedar Grove - NJ 07009",
    "county": "Essex County",
    "state": "NJ",
    "city": "Cedar Grove"
  },
  {
    "value": "07010",
    "label": "Cliffside Park - NJ 07010",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Cliffside Park"
  },
  {
    "value": "07011",
    "label": "Clifton - NJ 07011",
    "county": "Passaic County",
    "state": "NJ",
    "city": "Clifton"
  },
  {
    "value": "07012",
    "label": "Clifton - NJ 07012",
    "county": "Passaic County",
    "state": "NJ",
    "city": "Clifton"
  },
  {
    "value": "07013",
    "label": "Clifton - NJ 07013",
    "county": "Passaic County",
    "state": "NJ",
    "city": "Clifton"
  },
  {
    "value": "07014",
    "label": "Clifton - NJ 07014",
    "county": "Passaic County",
    "state": "NJ",
    "city": "Clifton"
  },
  {
    "value": "07016",
    "label": "Cranford - NJ 07016",
    "county": "Union County",
    "state": "NJ",
    "city": "Cranford"
  },
  {
    "value": "07017",
    "label": "East Orange - NJ 07017",
    "county": "Essex County",
    "state": "NJ",
    "city": "East Orange"
  },
  {
    "value": "07018",
    "label": "East Orange - NJ 07018",
    "county": "Essex County",
    "state": "NJ",
    "city": "East Orange"
  },
  {
    "value": "07020",
    "label": "Edgewater - NJ 07020",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Edgewater"
  },
  {
    "value": "07021",
    "label": "Essex Fells - NJ 07021",
    "county": "Essex County",
    "state": "NJ",
    "city": "Essex Fells"
  },
  {
    "value": "07022",
    "label": "Fairview - NJ 07022",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Fairview"
  },
  {
    "value": "07023",
    "label": "Fanwood - NJ 07023",
    "county": "Union County",
    "state": "NJ",
    "city": "Fanwood"
  },
  {
    "value": "07024",
    "label": "Fort Lee - NJ 07024",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Fort Lee"
  },
  {
    "value": "07026",
    "label": "Garfield - NJ 07026",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Garfield"
  },
  {
    "value": "07027",
    "label": "Garwood - NJ 07027",
    "county": "Union County",
    "state": "NJ",
    "city": "Garwood"
  },
  {
    "value": "07028",
    "label": "Glen Ridge - NJ 07028",
    "county": "Essex County",
    "state": "NJ",
    "city": "Glen Ridge"
  },
  {
    "value": "07029",
    "label": "Harrison - NJ 07029",
    "county": "Hudson County",
    "state": "NJ",
    "city": "Harrison"
  },
  {
    "value": "07030",
    "label": "Hoboken - NJ 07030",
    "county": "Hudson County",
    "state": "NJ",
    "city": "Hoboken"
  },
  {
    "value": "07031",
    "label": "North Arlington - NJ 07031",
    "county": "Bergen County",
    "state": "NJ",
    "city": "North Arlington"
  },
  {
    "value": "07032",
    "label": "Kearny - NJ 07032",
    "county": "Hudson County",
    "state": "NJ",
    "city": "Kearny"
  },
  {
    "value": "07033",
    "label": "Kenilworth - NJ 07033",
    "county": "Union County",
    "state": "NJ",
    "city": "Kenilworth"
  },
  {
    "value": "07034",
    "label": "Lake Hiawatha - NJ 07034",
    "county": "Morris County",
    "state": "NJ",
    "city": "Lake Hiawatha"
  },
  {
    "value": "07035",
    "label": "Lincoln Park - NJ 07035",
    "county": "Morris County",
    "state": "NJ",
    "city": "Lincoln Park"
  },
  {
    "value": "07036",
    "label": "Linden - NJ 07036",
    "county": "Union County",
    "state": "NJ",
    "city": "Linden"
  },
  {
    "value": "07039",
    "label": "Livingston - NJ 07039",
    "county": "Essex County",
    "state": "NJ",
    "city": "Livingston"
  },
  {
    "value": "07040",
    "label": "Maplewood - NJ 07040",
    "county": "Essex County",
    "state": "NJ",
    "city": "Maplewood"
  },
  {
    "value": "07041",
    "label": "Millburn - NJ 07041",
    "county": "Essex County",
    "state": "NJ",
    "city": "Millburn"
  },
  {
    "value": "07042",
    "label": "Montclair - NJ 07042",
    "county": "Essex County",
    "state": "NJ",
    "city": "Montclair"
  },
  {
    "value": "07043",
    "label": "Montclair - NJ 07043",
    "county": "Essex County",
    "state": "NJ",
    "city": "Montclair"
  },
  {
    "value": "07044",
    "label": "Verona - NJ 07044",
    "county": "Essex County",
    "state": "NJ",
    "city": "Verona"
  },
  {
    "value": "07045",
    "label": "Montville - NJ 07045",
    "county": "Morris County",
    "state": "NJ",
    "city": "Montville"
  },
  {
    "value": "07046",
    "label": "Mountain Lakes - NJ 07046",
    "county": "Morris County",
    "state": "NJ",
    "city": "Mountain Lakes"
  },
  {
    "value": "07047",
    "label": "North Bergen - NJ 07047",
    "county": "Hudson County",
    "state": "NJ",
    "city": "North Bergen"
  },
  {
    "value": "07050",
    "label": "Orange - NJ 07050",
    "county": "Essex County",
    "state": "NJ",
    "city": "Orange"
  },
  {
    "value": "07052",
    "label": "West Orange - NJ 07052",
    "county": "Essex County",
    "state": "NJ",
    "city": "West Orange"
  },
  {
    "value": "07054",
    "label": "Parsippany - NJ 07054",
    "county": "Morris County",
    "state": "NJ",
    "city": "Parsippany"
  },
  {
    "value": "07055",
    "label": "Passaic - NJ 07055",
    "county": "Passaic County",
    "state": "NJ",
    "city": "Passaic"
  },
  {
    "value": "07057",
    "label": "Wallington - NJ 07057",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Wallington"
  },
  {
    "value": "07058",
    "label": "Pine Brook - NJ 07058",
    "county": "Morris County",
    "state": "NJ",
    "city": "Pine Brook"
  },
  {
    "value": "07059",
    "label": "Warren - NJ 07059",
    "county": "Somerset County",
    "state": "NJ",
    "city": "Warren"
  },
  {
    "value": "07060",
    "label": "Plainfield - NJ 07060",
    "county": "Union County",
    "state": "NJ",
    "city": "Plainfield"
  },
  {
    "value": "07062",
    "label": "Plainfield - NJ 07062",
    "county": "Union County",
    "state": "NJ",
    "city": "Plainfield"
  },
  {
    "value": "07063",
    "label": "Plainfield - NJ 07063",
    "county": "Union County",
    "state": "NJ",
    "city": "Plainfield"
  },
  {
    "value": "07064",
    "label": "Port Reading - NJ 07064",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Port Reading"
  },
  {
    "value": "07065",
    "label": "Rahway - NJ 07065",
    "county": "Union County",
    "state": "NJ",
    "city": "Rahway"
  },
  {
    "value": "07066",
    "label": "Clark - NJ 07066",
    "county": "Union County",
    "state": "NJ",
    "city": "Clark"
  },
  {
    "value": "07067",
    "label": "Colonia - NJ 07067",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Colonia"
  },
  {
    "value": "07068",
    "label": "Roseland - NJ 07068",
    "county": "Essex County",
    "state": "NJ",
    "city": "Roseland"
  },
  {
    "value": "07069",
    "label": "Watchung - NJ 07069",
    "county": "Somerset County",
    "state": "NJ",
    "city": "Watchung"
  },
  {
    "value": "07070",
    "label": "Rutherford - NJ 07070",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Rutherford"
  },
  {
    "value": "07071",
    "label": "Lyndhurst - NJ 07071",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Lyndhurst"
  },
  {
    "value": "07072",
    "label": "Carlstadt - NJ 07072",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Carlstadt"
  },
  {
    "value": "07073",
    "label": "East Rutherford - NJ 07073",
    "county": "Bergen County",
    "state": "NJ",
    "city": "East Rutherford"
  },
  {
    "value": "07074",
    "label": "Moonachie - NJ 07074",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Moonachie"
  },
  {
    "value": "07075",
    "label": "Wood Ridge - NJ 07075",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Wood Ridge"
  },
  {
    "value": "07076",
    "label": "Scotch Plains - NJ 07076",
    "county": "Union County",
    "state": "NJ",
    "city": "Scotch Plains"
  },
  {
    "value": "07077",
    "label": "Sewaren - NJ 07077",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Sewaren"
  },
  {
    "value": "07078",
    "label": "Short Hills - NJ 07078",
    "county": "Essex County",
    "state": "NJ",
    "city": "Short Hills"
  },
  {
    "value": "07079",
    "label": "South Orange - NJ 07079",
    "county": "Essex County",
    "state": "NJ",
    "city": "South Orange"
  },
  {
    "value": "07080",
    "label": "South Plainfield - NJ 07080",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "South Plainfield"
  },
  {
    "value": "07081",
    "label": "Springfield - NJ 07081",
    "county": "Union County",
    "state": "NJ",
    "city": "Springfield"
  },
  {
    "value": "07082",
    "label": "Towaco - NJ 07082",
    "county": "Morris County",
    "state": "NJ",
    "city": "Towaco"
  },
  {
    "value": "07083",
    "label": "Union - NJ 07083",
    "county": "Union County",
    "state": "NJ",
    "city": "Union"
  },
  {
    "value": "07086",
    "label": "Weehawken - NJ 07086",
    "county": "Hudson County",
    "state": "NJ",
    "city": "Weehawken"
  },
  {
    "value": "07087",
    "label": "Union City - NJ 07087",
    "county": "Hudson County",
    "state": "NJ",
    "city": "Union City"
  },
  {
    "value": "07088",
    "label": "Vauxhall - NJ 07088",
    "county": "Union County",
    "state": "NJ",
    "city": "Vauxhall"
  },
  {
    "value": "07090",
    "label": "Westfield - NJ 07090",
    "county": "Union County",
    "state": "NJ",
    "city": "Westfield"
  },
  {
    "value": "07092",
    "label": "Mountainside - NJ 07092",
    "county": "Union County",
    "state": "NJ",
    "city": "Mountainside"
  },
  {
    "value": "07093",
    "label": "West New York - NJ 07093",
    "county": "Hudson County",
    "state": "NJ",
    "city": "West New York"
  },
  {
    "value": "07094",
    "label": "Secaucus - NJ 07094",
    "county": "Hudson County",
    "state": "NJ",
    "city": "Secaucus"
  },
  {
    "value": "07095",
    "label": "Woodbridge - NJ 07095",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Woodbridge"
  },
  {
    "value": "07102",
    "label": "Newark - NJ 07102",
    "county": "Essex County",
    "state": "NJ",
    "city": "Newark"
  },
  {
    "value": "07103",
    "label": "Newark - NJ 07103",
    "county": "Essex County",
    "state": "NJ",
    "city": "Newark"
  },
  {
    "value": "07104",
    "label": "Newark - NJ 07104",
    "county": "Essex County",
    "state": "NJ",
    "city": "Newark"
  },
  {
    "value": "07105",
    "label": "Newark - NJ 07105",
    "county": "Essex County",
    "state": "NJ",
    "city": "Newark"
  },
  {
    "value": "07106",
    "label": "Newark - NJ 07106",
    "county": "Essex County",
    "state": "NJ",
    "city": "Newark"
  },
  {
    "value": "07107",
    "label": "Newark - NJ 07107",
    "county": "Essex County",
    "state": "NJ",
    "city": "Newark"
  },
  {
    "value": "07108",
    "label": "Newark - NJ 07108",
    "county": "Essex County",
    "state": "NJ",
    "city": "Newark"
  },
  {
    "value": "07109",
    "label": "Belleville - NJ 07109",
    "county": "Essex County",
    "state": "NJ",
    "city": "Belleville"
  },
  {
    "value": "07110",
    "label": "Nutley - NJ 07110",
    "county": "Essex County",
    "state": "NJ",
    "city": "Nutley"
  },
  {
    "value": "07111",
    "label": "Irvington - NJ 07111",
    "county": "Essex County",
    "state": "NJ",
    "city": "Irvington"
  },
  {
    "value": "07112",
    "label": "Newark - NJ 07112",
    "county": "Essex County",
    "state": "NJ",
    "city": "Newark"
  },
  {
    "value": "07114",
    "label": "Newark - NJ 07114",
    "county": "Essex County",
    "state": "NJ",
    "city": "Newark"
  },
  {
    "value": "07201",
    "label": "Elizabeth - NJ 07201",
    "county": "Union County",
    "state": "NJ",
    "city": "Elizabeth"
  },
  {
    "value": "07202",
    "label": "Elizabeth - NJ 07202",
    "county": "Union County",
    "state": "NJ",
    "city": "Elizabeth"
  },
  {
    "value": "07203",
    "label": "Roselle - NJ 07203",
    "county": "Union County",
    "state": "NJ",
    "city": "Roselle"
  },
  {
    "value": "07204",
    "label": "Roselle Park - NJ 07204",
    "county": "Union County",
    "state": "NJ",
    "city": "Roselle Park"
  },
  {
    "value": "07205",
    "label": "Hillside - NJ 07205",
    "county": "Union County",
    "state": "NJ",
    "city": "Hillside"
  },
  {
    "value": "07206",
    "label": "Elizabethport - NJ 07206",
    "county": "Union County",
    "state": "NJ",
    "city": "Elizabethport"
  },
  {
    "value": "07208",
    "label": "Elizabeth - NJ 07208",
    "county": "Union County",
    "state": "NJ",
    "city": "Elizabeth"
  },
  {
    "value": "07302",
    "label": "Jersey City - NJ 07302",
    "county": "Hudson County",
    "state": "NJ",
    "city": "Jersey City"
  },
  {
    "value": "07304",
    "label": "Jersey City - NJ 07304",
    "county": "Hudson County",
    "state": "NJ",
    "city": "Jersey City"
  },
  {
    "value": "07305",
    "label": "Jersey City - NJ 07305",
    "county": "Hudson County",
    "state": "NJ",
    "city": "Jersey City"
  },
  {
    "value": "07306",
    "label": "Jersey City - NJ 07306",
    "county": "Hudson County",
    "state": "NJ",
    "city": "Jersey City"
  },
  {
    "value": "07307",
    "label": "Jersey City - NJ 07307",
    "county": "Hudson County",
    "state": "NJ",
    "city": "Jersey City"
  },
  {
    "value": "07309",
    "label": "Jersey City - NJ 07309",
    "county": "Hudson County",
    "state": "NJ",
    "city": "Jersey City"
  },
  {
    "value": "07310",
    "label": "Jersey City - NJ 07310",
    "county": "Hudson County",
    "state": "NJ",
    "city": "Jersey City"
  },
  {
    "value": "07311",
    "label": "Jersey City - NJ 07311",
    "county": "Hudson County",
    "state": "NJ",
    "city": "Jersey City"
  },
  {
    "value": "07401",
    "label": "Allendale - NJ 07401",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Allendale"
  },
  {
    "value": "07403",
    "label": "Bloomingdale - NJ 07403",
    "county": "Passaic County",
    "state": "NJ",
    "city": "Bloomingdale"
  },
  {
    "value": "07405",
    "label": "Butler - NJ 07405",
    "county": "Morris County",
    "state": "NJ",
    "city": "Butler"
  },
  {
    "value": "07407",
    "label": "Elmwood Park - NJ 07407",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Elmwood Park"
  },
  {
    "value": "07410",
    "label": "Fair Lawn - NJ 07410",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Fair Lawn"
  },
  {
    "value": "07416",
    "label": "Franklin - NJ 07416",
    "county": "Sussex County",
    "state": "NJ",
    "city": "Franklin"
  },
  {
    "value": "07417",
    "label": "Franklin Lakes - NJ 07417",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Franklin Lakes"
  },
  {
    "value": "07418",
    "label": "Glenwood - NJ 07418",
    "county": "Sussex County",
    "state": "NJ",
    "city": "Glenwood"
  },
  {
    "value": "07419",
    "label": "Hamburg - NJ 07419",
    "county": "Sussex County",
    "state": "NJ",
    "city": "Hamburg"
  },
  {
    "value": "07420",
    "label": "Haskell - NJ 07420",
    "county": "Passaic County",
    "state": "NJ",
    "city": "Haskell"
  },
  {
    "value": "07421",
    "label": "Hewitt - NJ 07421",
    "county": "Passaic County",
    "state": "NJ",
    "city": "Hewitt"
  },
  {
    "value": "07422",
    "label": "Highland Lakes - NJ 07422",
    "county": "Sussex County",
    "state": "NJ",
    "city": "Highland Lakes"
  },
  {
    "value": "07423",
    "label": "Ho Ho Kus - NJ 07423",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Ho Ho Kus"
  },
  {
    "value": "07424",
    "label": "Little Falls - NJ 07424",
    "county": "Passaic County",
    "state": "NJ",
    "city": "Little Falls"
  },
  {
    "value": "07430",
    "label": "Mahwah - NJ 07430",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Mahwah"
  },
  {
    "value": "07432",
    "label": "Midland Park - NJ 07432",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Midland Park"
  },
  {
    "value": "07435",
    "label": "Newfoundland - NJ 07435",
    "county": "Passaic County",
    "state": "NJ",
    "city": "Newfoundland"
  },
  {
    "value": "07436",
    "label": "Oakland - NJ 07436",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Oakland"
  },
  {
    "value": "07438",
    "label": "Oak Ridge - NJ 07438",
    "county": "Morris County",
    "state": "NJ",
    "city": "Oak Ridge"
  },
  {
    "value": "07439",
    "label": "Ogdensburg - NJ 07439",
    "county": "Sussex County",
    "state": "NJ",
    "city": "Ogdensburg"
  },
  {
    "value": "07440",
    "label": "Pequannock - NJ 07440",
    "county": "Morris County",
    "state": "NJ",
    "city": "Pequannock"
  },
  {
    "value": "07442",
    "label": "Pompton Lakes - NJ 07442",
    "county": "Passaic County",
    "state": "NJ",
    "city": "Pompton Lakes"
  },
  {
    "value": "07444",
    "label": "Pompton Plains - NJ 07444",
    "county": "Morris County",
    "state": "NJ",
    "city": "Pompton Plains"
  },
  {
    "value": "07446",
    "label": "Ramsey - NJ 07446",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Ramsey"
  },
  {
    "value": "07450",
    "label": "Ridgewood - NJ 07450",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Ridgewood"
  },
  {
    "value": "07452",
    "label": "Glen Rock - NJ 07452",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Glen Rock"
  },
  {
    "value": "07456",
    "label": "Ringwood - NJ 07456",
    "county": "Passaic County",
    "state": "NJ",
    "city": "Ringwood"
  },
  {
    "value": "07457",
    "label": "Riverdale - NJ 07457",
    "county": "Morris County",
    "state": "NJ",
    "city": "Riverdale"
  },
  {
    "value": "07458",
    "label": "Saddle River - NJ 07458",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Saddle River"
  },
  {
    "value": "07460",
    "label": "Stockholm - NJ 07460",
    "county": "Sussex County",
    "state": "NJ",
    "city": "Stockholm"
  },
  {
    "value": "07461",
    "label": "Sussex - NJ 07461",
    "county": "Sussex County",
    "state": "NJ",
    "city": "Sussex"
  },
  {
    "value": "07462",
    "label": "Vernon - NJ 07462",
    "county": "Sussex County",
    "state": "NJ",
    "city": "Vernon"
  },
  {
    "value": "07463",
    "label": "Waldwick - NJ 07463",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Waldwick"
  },
  {
    "value": "07465",
    "label": "Wanaque - NJ 07465",
    "county": "Passaic County",
    "state": "NJ",
    "city": "Wanaque"
  },
  {
    "value": "07470",
    "label": "Wayne - NJ 07470",
    "county": "Passaic County",
    "state": "NJ",
    "city": "Wayne"
  },
  {
    "value": "07480",
    "label": "West Milford - NJ 07480",
    "county": "Passaic County",
    "state": "NJ",
    "city": "West Milford"
  },
  {
    "value": "07481",
    "label": "Wyckoff - NJ 07481",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Wyckoff"
  },
  {
    "value": "07495",
    "label": "Mahwah - NJ 07495",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Mahwah"
  },
  {
    "value": "07501",
    "label": "Paterson - NJ 07501",
    "county": "Passaic County",
    "state": "NJ",
    "city": "Paterson"
  },
  {
    "value": "07502",
    "label": "Paterson - NJ 07502",
    "county": "Passaic County",
    "state": "NJ",
    "city": "Paterson"
  },
  {
    "value": "07503",
    "label": "Paterson - NJ 07503",
    "county": "Passaic County",
    "state": "NJ",
    "city": "Paterson"
  },
  {
    "value": "07504",
    "label": "Paterson - NJ 07504",
    "county": "Passaic County",
    "state": "NJ",
    "city": "Paterson"
  },
  {
    "value": "07505",
    "label": "Paterson - NJ 07505",
    "county": "Passaic County",
    "state": "NJ",
    "city": "Paterson"
  },
  {
    "value": "07506",
    "label": "Hawthorne - NJ 07506",
    "county": "Passaic County",
    "state": "NJ",
    "city": "Hawthorne"
  },
  {
    "value": "07508",
    "label": "Haledon - NJ 07508",
    "county": "Passaic County",
    "state": "NJ",
    "city": "Haledon"
  },
  {
    "value": "07510",
    "label": "Paterson - NJ 07510",
    "county": "Passaic County",
    "state": "NJ",
    "city": "Paterson"
  },
  {
    "value": "07512",
    "label": "Totowa - NJ 07512",
    "county": "Passaic County",
    "state": "NJ",
    "city": "Totowa"
  },
  {
    "value": "07513",
    "label": "Paterson - NJ 07513",
    "county": "Passaic County",
    "state": "NJ",
    "city": "Paterson"
  },
  {
    "value": "07514",
    "label": "Paterson - NJ 07514",
    "county": "Passaic County",
    "state": "NJ",
    "city": "Paterson"
  },
  {
    "value": "07522",
    "label": "Paterson - NJ 07522",
    "county": "Passaic County",
    "state": "NJ",
    "city": "Paterson"
  },
  {
    "value": "07524",
    "label": "Paterson - NJ 07524",
    "county": "Passaic County",
    "state": "NJ",
    "city": "Paterson"
  },
  {
    "value": "07601",
    "label": "Hackensack - NJ 07601",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Hackensack"
  },
  {
    "value": "07603",
    "label": "Bogota - NJ 07603",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Bogota"
  },
  {
    "value": "07604",
    "label": "Hasbrouck Heights - NJ 07604",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Hasbrouck Heights"
  },
  {
    "value": "07605",
    "label": "Leonia - NJ 07605",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Leonia"
  },
  {
    "value": "07606",
    "label": "South Hackensack - NJ 07606",
    "county": "Bergen County",
    "state": "NJ",
    "city": "South Hackensack"
  },
  {
    "value": "07607",
    "label": "Maywood - NJ 07607",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Maywood"
  },
  {
    "value": "07608",
    "label": "Teterboro - NJ 07608",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Teterboro"
  },
  {
    "value": "07621",
    "label": "Bergenfield - NJ 07621",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Bergenfield"
  },
  {
    "value": "07624",
    "label": "Closter - NJ 07624",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Closter"
  },
  {
    "value": "07626",
    "label": "Cresskill - NJ 07626",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Cresskill"
  },
  {
    "value": "07627",
    "label": "Demarest - NJ 07627",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Demarest"
  },
  {
    "value": "07628",
    "label": "Dumont - NJ 07628",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Dumont"
  },
  {
    "value": "07630",
    "label": "Emerson - NJ 07630",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Emerson"
  },
  {
    "value": "07631",
    "label": "Englewood - NJ 07631",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Englewood"
  },
  {
    "value": "07632",
    "label": "Englewood Cliffs - NJ 07632",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Englewood Cliffs"
  },
  {
    "value": "07640",
    "label": "Harrington Park - NJ 07640",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Harrington Park"
  },
  {
    "value": "07641",
    "label": "Haworth - NJ 07641",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Haworth"
  },
  {
    "value": "07642",
    "label": "Hillsdale - NJ 07642",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Hillsdale"
  },
  {
    "value": "07643",
    "label": "Little Ferry - NJ 07643",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Little Ferry"
  },
  {
    "value": "07644",
    "label": "Lodi - NJ 07644",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Lodi"
  },
  {
    "value": "07645",
    "label": "Montvale - NJ 07645",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Montvale"
  },
  {
    "value": "07646",
    "label": "New Milford - NJ 07646",
    "county": "Bergen County",
    "state": "NJ",
    "city": "New Milford"
  },
  {
    "value": "07647",
    "label": "Northvale - NJ 07647",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Northvale"
  },
  {
    "value": "07648",
    "label": "Norwood - NJ 07648",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Norwood"
  },
  {
    "value": "07649",
    "label": "Oradell - NJ 07649",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Oradell"
  },
  {
    "value": "07650",
    "label": "Palisades Park - NJ 07650",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Palisades Park"
  },
  {
    "value": "07652",
    "label": "Paramus - NJ 07652",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Paramus"
  },
  {
    "value": "07656",
    "label": "Park Ridge - NJ 07656",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Park Ridge"
  },
  {
    "value": "07657",
    "label": "Ridgefield - NJ 07657",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Ridgefield"
  },
  {
    "value": "07660",
    "label": "Ridgefield Park - NJ 07660",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Ridgefield Park"
  },
  {
    "value": "07661",
    "label": "River Edge - NJ 07661",
    "county": "Bergen County",
    "state": "NJ",
    "city": "River Edge"
  },
  {
    "value": "07662",
    "label": "Rochelle Park - NJ 07662",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Rochelle Park"
  },
  {
    "value": "07663",
    "label": "Saddle Brook - NJ 07663",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Saddle Brook"
  },
  {
    "value": "07666",
    "label": "Teaneck - NJ 07666",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Teaneck"
  },
  {
    "value": "07670",
    "label": "Tenafly - NJ 07670",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Tenafly"
  },
  {
    "value": "07675",
    "label": "Westwood - NJ 07675",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Westwood"
  },
  {
    "value": "07676",
    "label": "Township Of Washington - NJ 07676",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Township Of Washington"
  },
  {
    "value": "07677",
    "label": "Woodcliff Lake - NJ 07677",
    "county": "Bergen County",
    "state": "NJ",
    "city": "Woodcliff Lake"
  },
  {
    "value": "07701",
    "label": "Red Bank - NJ 07701",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Red Bank"
  },
  {
    "value": "07702",
    "label": "Shrewsbury - NJ 07702",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Shrewsbury"
  },
  {
    "value": "07703",
    "label": "Fort Monmouth - NJ 07703",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Fort Monmouth"
  },
  {
    "value": "07704",
    "label": "Fair Haven - NJ 07704",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Fair Haven"
  },
  {
    "value": "07711",
    "label": "Allenhurst - NJ 07711",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Allenhurst"
  },
  {
    "value": "07712",
    "label": "Asbury Park - NJ 07712",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Asbury Park"
  },
  {
    "value": "07716",
    "label": "Atlantic Highlands - NJ 07716",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Atlantic Highlands"
  },
  {
    "value": "07717",
    "label": "Avon By The Sea - NJ 07717",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Avon By The Sea"
  },
  {
    "value": "07718",
    "label": "Belford - NJ 07718",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Belford"
  },
  {
    "value": "07719",
    "label": "Belmar - NJ 07719",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Belmar"
  },
  {
    "value": "07720",
    "label": "Bradley Beach - NJ 07720",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Bradley Beach"
  },
  {
    "value": "07721",
    "label": "Cliffwood - NJ 07721",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Cliffwood"
  },
  {
    "value": "07722",
    "label": "Colts Neck - NJ 07722",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Colts Neck"
  },
  {
    "value": "07723",
    "label": "Deal - NJ 07723",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Deal"
  },
  {
    "value": "07724",
    "label": "Eatontown - NJ 07724",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Eatontown"
  },
  {
    "value": "07726",
    "label": "Englishtown - NJ 07726",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Englishtown"
  },
  {
    "value": "07727",
    "label": "Farmingdale - NJ 07727",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Farmingdale"
  },
  {
    "value": "07728",
    "label": "Freehold - NJ 07728",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Freehold"
  },
  {
    "value": "07730",
    "label": "Hazlet - NJ 07730",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Hazlet"
  },
  {
    "value": "07731",
    "label": "Howell - NJ 07731",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Howell"
  },
  {
    "value": "07732",
    "label": "Highlands - NJ 07732",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Highlands"
  },
  {
    "value": "07733",
    "label": "Holmdel - NJ 07733",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Holmdel"
  },
  {
    "value": "07734",
    "label": "Keansburg - NJ 07734",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Keansburg"
  },
  {
    "value": "07735",
    "label": "Keyport - NJ 07735",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Keyport"
  },
  {
    "value": "07737",
    "label": "Leonardo - NJ 07737",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Leonardo"
  },
  {
    "value": "07738",
    "label": "Lincroft - NJ 07738",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Lincroft"
  },
  {
    "value": "07739",
    "label": "Little Silver - NJ 07739",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Little Silver"
  },
  {
    "value": "07740",
    "label": "Long Branch - NJ 07740",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Long Branch"
  },
  {
    "value": "07746",
    "label": "Marlboro - NJ 07746",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Marlboro"
  },
  {
    "value": "07747",
    "label": "Matawan - NJ 07747",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Matawan"
  },
  {
    "value": "07748",
    "label": "Middletown - NJ 07748",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Middletown"
  },
  {
    "value": "07750",
    "label": "Monmouth Beach - NJ 07750",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Monmouth Beach"
  },
  {
    "value": "07751",
    "label": "Morganville - NJ 07751",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Morganville"
  },
  {
    "value": "07753",
    "label": "Neptune - NJ 07753",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Neptune"
  },
  {
    "value": "07755",
    "label": "Oakhurst - NJ 07755",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Oakhurst"
  },
  {
    "value": "07756",
    "label": "Ocean Grove - NJ 07756",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Ocean Grove"
  },
  {
    "value": "07757",
    "label": "Oceanport - NJ 07757",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Oceanport"
  },
  {
    "value": "07758",
    "label": "Port Monmouth - NJ 07758",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Port Monmouth"
  },
  {
    "value": "07760",
    "label": "Rumson - NJ 07760",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Rumson"
  },
  {
    "value": "07762",
    "label": "Spring Lake - NJ 07762",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Spring Lake"
  },
  {
    "value": "07764",
    "label": "West Long Branch - NJ 07764",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "West Long Branch"
  },
  {
    "value": "07799",
    "label": "Eatontown - NJ 07799",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Eatontown"
  },
  {
    "value": "07801",
    "label": "Dover - NJ 07801",
    "county": "Morris County",
    "state": "NJ",
    "city": "Dover"
  },
  {
    "value": "07803",
    "label": "Mine Hill - NJ 07803",
    "county": "Morris County",
    "state": "NJ",
    "city": "Mine Hill"
  },
  {
    "value": "07806",
    "label": "Picatinny Arsenal - NJ 07806",
    "county": "Morris County",
    "state": "NJ",
    "city": "Picatinny Arsenal"
  },
  {
    "value": "07821",
    "label": "Andover - NJ 07821",
    "county": "Sussex County",
    "state": "NJ",
    "city": "Andover"
  },
  {
    "value": "07822",
    "label": "Augusta - NJ 07822",
    "county": "Sussex County",
    "state": "NJ",
    "city": "Augusta"
  },
  {
    "value": "07823",
    "label": "Belvidere - NJ 07823",
    "county": "Warren County",
    "state": "NJ",
    "city": "Belvidere"
  },
  {
    "value": "07825",
    "label": "Blairstown - NJ 07825",
    "county": "Warren County",
    "state": "NJ",
    "city": "Blairstown"
  },
  {
    "value": "07826",
    "label": "Branchville - NJ 07826",
    "county": "Sussex County",
    "state": "NJ",
    "city": "Branchville"
  },
  {
    "value": "07827",
    "label": "Montague - NJ 07827",
    "county": "Sussex County",
    "state": "NJ",
    "city": "Montague"
  },
  {
    "value": "07828",
    "label": "Budd Lake - NJ 07828",
    "county": "Morris County",
    "state": "NJ",
    "city": "Budd Lake"
  },
  {
    "value": "07830",
    "label": "Califon - NJ 07830",
    "county": "Hunterdon County",
    "state": "NJ",
    "city": "Califon"
  },
  {
    "value": "07831",
    "label": "Changewater - NJ 07831",
    "county": "Hunterdon County",
    "state": "NJ",
    "city": "Changewater"
  },
  {
    "value": "07832",
    "label": "Columbia - NJ 07832",
    "county": "Warren County",
    "state": "NJ",
    "city": "Columbia"
  },
  {
    "value": "07834",
    "label": "Denville - NJ 07834",
    "county": "Morris County",
    "state": "NJ",
    "city": "Denville"
  },
  {
    "value": "07836",
    "label": "Flanders - NJ 07836",
    "county": "Morris County",
    "state": "NJ",
    "city": "Flanders"
  },
  {
    "value": "07838",
    "label": "Great Meadows - NJ 07838",
    "county": "Warren County",
    "state": "NJ",
    "city": "Great Meadows"
  },
  {
    "value": "07840",
    "label": "Hackettstown - NJ 07840",
    "county": "Warren County",
    "state": "NJ",
    "city": "Hackettstown"
  },
  {
    "value": "07843",
    "label": "Hopatcong - NJ 07843",
    "county": "Sussex County",
    "state": "NJ",
    "city": "Hopatcong"
  },
  {
    "value": "07847",
    "label": "Kenvil - NJ 07847",
    "county": "Morris County",
    "state": "NJ",
    "city": "Kenvil"
  },
  {
    "value": "07848",
    "label": "Lafayette - NJ 07848",
    "county": "Sussex County",
    "state": "NJ",
    "city": "Lafayette"
  },
  {
    "value": "07849",
    "label": "Lake Hopatcong - NJ 07849",
    "county": "Morris County",
    "state": "NJ",
    "city": "Lake Hopatcong"
  },
  {
    "value": "07850",
    "label": "Landing - NJ 07850",
    "county": "Morris County",
    "state": "NJ",
    "city": "Landing"
  },
  {
    "value": "07851",
    "label": "Layton - NJ 07851",
    "county": "Sussex County",
    "state": "NJ",
    "city": "Layton"
  },
  {
    "value": "07852",
    "label": "Ledgewood - NJ 07852",
    "county": "Morris County",
    "state": "NJ",
    "city": "Ledgewood"
  },
  {
    "value": "07853",
    "label": "Long Valley - NJ 07853",
    "county": "Morris County",
    "state": "NJ",
    "city": "Long Valley"
  },
  {
    "value": "07856",
    "label": "Mount Arlington - NJ 07856",
    "county": "Morris County",
    "state": "NJ",
    "city": "Mount Arlington"
  },
  {
    "value": "07857",
    "label": "Netcong - NJ 07857",
    "county": "Morris County",
    "state": "NJ",
    "city": "Netcong"
  },
  {
    "value": "07860",
    "label": "Newton - NJ 07860",
    "county": "Sussex County",
    "state": "NJ",
    "city": "Newton"
  },
  {
    "value": "07863",
    "label": "Oxford - NJ 07863",
    "county": "Warren County",
    "state": "NJ",
    "city": "Oxford"
  },
  {
    "value": "07865",
    "label": "Port Murray - NJ 07865",
    "county": "Warren County",
    "state": "NJ",
    "city": "Port Murray"
  },
  {
    "value": "07866",
    "label": "Rockaway - NJ 07866",
    "county": "Morris County",
    "state": "NJ",
    "city": "Rockaway"
  },
  {
    "value": "07869",
    "label": "Randolph - NJ 07869",
    "county": "Morris County",
    "state": "NJ",
    "city": "Randolph"
  },
  {
    "value": "07871",
    "label": "Sparta - NJ 07871",
    "county": "Sussex County",
    "state": "NJ",
    "city": "Sparta"
  },
  {
    "value": "07874",
    "label": "Stanhope - NJ 07874",
    "county": "Sussex County",
    "state": "NJ",
    "city": "Stanhope"
  },
  {
    "value": "07876",
    "label": "Succasunna - NJ 07876",
    "county": "Morris County",
    "state": "NJ",
    "city": "Succasunna"
  },
  {
    "value": "07881",
    "label": "Wallpack Center - NJ 07881",
    "county": "Sussex County",
    "state": "NJ",
    "city": "Wallpack Center"
  },
  {
    "value": "07882",
    "label": "Washington - NJ 07882",
    "county": "Warren County",
    "state": "NJ",
    "city": "Washington"
  },
  {
    "value": "07885",
    "label": "Wharton - NJ 07885",
    "county": "Morris County",
    "state": "NJ",
    "city": "Wharton"
  },
  {
    "value": "07901",
    "label": "Summit - NJ 07901",
    "county": "Union County",
    "state": "NJ",
    "city": "Summit"
  },
  {
    "value": "07920",
    "label": "Basking Ridge - NJ 07920",
    "county": "Somerset County",
    "state": "NJ",
    "city": "Basking Ridge"
  },
  {
    "value": "07921",
    "label": "Bedminster - NJ 07921",
    "county": "Somerset County",
    "state": "NJ",
    "city": "Bedminster"
  },
  {
    "value": "07922",
    "label": "Berkeley Heights - NJ 07922",
    "county": "Union County",
    "state": "NJ",
    "city": "Berkeley Heights"
  },
  {
    "value": "07924",
    "label": "Bernardsville - NJ 07924",
    "county": "Somerset County",
    "state": "NJ",
    "city": "Bernardsville"
  },
  {
    "value": "07927",
    "label": "Cedar Knolls - NJ 07927",
    "county": "Morris County",
    "state": "NJ",
    "city": "Cedar Knolls"
  },
  {
    "value": "07928",
    "label": "Chatham - NJ 07928",
    "county": "Morris County",
    "state": "NJ",
    "city": "Chatham"
  },
  {
    "value": "07930",
    "label": "Chester - NJ 07930",
    "county": "Morris County",
    "state": "NJ",
    "city": "Chester"
  },
  {
    "value": "07931",
    "label": "Far Hills - NJ 07931",
    "county": "Somerset County",
    "state": "NJ",
    "city": "Far Hills"
  },
  {
    "value": "07932",
    "label": "Florham Park - NJ 07932",
    "county": "Morris County",
    "state": "NJ",
    "city": "Florham Park"
  },
  {
    "value": "07933",
    "label": "Gillette - NJ 07933",
    "county": "Morris County",
    "state": "NJ",
    "city": "Gillette"
  },
  {
    "value": "07934",
    "label": "Gladstone - NJ 07934",
    "county": "Somerset County",
    "state": "NJ",
    "city": "Gladstone"
  },
  {
    "value": "07935",
    "label": "Green Village - NJ 07935",
    "county": "Morris County",
    "state": "NJ",
    "city": "Green Village"
  },
  {
    "value": "07936",
    "label": "East Hanover - NJ 07936",
    "county": "Morris County",
    "state": "NJ",
    "city": "East Hanover"
  },
  {
    "value": "07939",
    "label": "Lyons - NJ 07939",
    "county": "Somerset County",
    "state": "NJ",
    "city": "Lyons"
  },
  {
    "value": "07940",
    "label": "Madison - NJ 07940",
    "county": "Morris County",
    "state": "NJ",
    "city": "Madison"
  },
  {
    "value": "07945",
    "label": "Mendham - NJ 07945",
    "county": "Morris County",
    "state": "NJ",
    "city": "Mendham"
  },
  {
    "value": "07946",
    "label": "Millington - NJ 07946",
    "county": "Morris County",
    "state": "NJ",
    "city": "Millington"
  },
  {
    "value": "07950",
    "label": "Morris Plains - NJ 07950",
    "county": "Morris County",
    "state": "NJ",
    "city": "Morris Plains"
  },
  {
    "value": "07960",
    "label": "Morristown - NJ 07960",
    "county": "Morris County",
    "state": "NJ",
    "city": "Morristown"
  },
  {
    "value": "07974",
    "label": "New Providence - NJ 07974",
    "county": "Union County",
    "state": "NJ",
    "city": "New Providence"
  },
  {
    "value": "07976",
    "label": "New Vernon - NJ 07976",
    "county": "Morris County",
    "state": "NJ",
    "city": "New Vernon"
  },
  {
    "value": "07980",
    "label": "Stirling - NJ 07980",
    "county": "Morris County",
    "state": "NJ",
    "city": "Stirling"
  },
  {
    "value": "07981",
    "label": "Whippany - NJ 07981",
    "county": "Morris County",
    "state": "NJ",
    "city": "Whippany"
  },
  {
    "value": "07999",
    "label": "Whippany - NJ 07999",
    "county": "Morris County",
    "state": "NJ",
    "city": "Whippany"
  },
  {
    "value": "08002",
    "label": "Cherry Hill - NJ 08002",
    "county": "Camden County",
    "state": "NJ",
    "city": "Cherry Hill"
  },
  {
    "value": "08003",
    "label": "Cherry Hill - NJ 08003",
    "county": "Camden County",
    "state": "NJ",
    "city": "Cherry Hill"
  },
  {
    "value": "08004",
    "label": "Atco - NJ 08004",
    "county": "Camden County",
    "state": "NJ",
    "city": "Atco"
  },
  {
    "value": "08005",
    "label": "Barnegat - NJ 08005",
    "county": "Ocean County",
    "state": "NJ",
    "city": "Barnegat"
  },
  {
    "value": "08007",
    "label": "Barrington - NJ 08007",
    "county": "Camden County",
    "state": "NJ",
    "city": "Barrington"
  },
  {
    "value": "08008",
    "label": "Beach Haven - NJ 08008",
    "county": "Ocean County",
    "state": "NJ",
    "city": "Beach Haven"
  },
  {
    "value": "08009",
    "label": "Berlin - NJ 08009",
    "county": "Camden County",
    "state": "NJ",
    "city": "Berlin"
  },
  {
    "value": "08010",
    "label": "Beverly - NJ 08010",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Beverly"
  },
  {
    "value": "08012",
    "label": "Blackwood - NJ 08012",
    "county": "Camden County",
    "state": "NJ",
    "city": "Blackwood"
  },
  {
    "value": "08014",
    "label": "Bridgeport - NJ 08014",
    "county": "Gloucester County",
    "state": "NJ",
    "city": "Bridgeport"
  },
  {
    "value": "08015",
    "label": "Browns Mills - NJ 08015",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Browns Mills"
  },
  {
    "value": "08016",
    "label": "Burlington - NJ 08016",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Burlington"
  },
  {
    "value": "08019",
    "label": "Chatsworth - NJ 08019",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Chatsworth"
  },
  {
    "value": "08020",
    "label": "Clarksboro - NJ 08020",
    "county": "Gloucester County",
    "state": "NJ",
    "city": "Clarksboro"
  },
  {
    "value": "08021",
    "label": "Clementon - NJ 08021",
    "county": "Camden County",
    "state": "NJ",
    "city": "Clementon"
  },
  {
    "value": "08022",
    "label": "Columbus - NJ 08022",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Columbus"
  },
  {
    "value": "08026",
    "label": "Gibbsboro - NJ 08026",
    "county": "Camden County",
    "state": "NJ",
    "city": "Gibbsboro"
  },
  {
    "value": "08027",
    "label": "Gibbstown - NJ 08027",
    "county": "Gloucester County",
    "state": "NJ",
    "city": "Gibbstown"
  },
  {
    "value": "08028",
    "label": "Glassboro - NJ 08028",
    "county": "Gloucester County",
    "state": "NJ",
    "city": "Glassboro"
  },
  {
    "value": "08029",
    "label": "Glendora - NJ 08029",
    "county": "Camden County",
    "state": "NJ",
    "city": "Glendora"
  },
  {
    "value": "08030",
    "label": "Gloucester City - NJ 08030",
    "county": "Camden County",
    "state": "NJ",
    "city": "Gloucester City"
  },
  {
    "value": "08031",
    "label": "Bellmawr - NJ 08031",
    "county": "Camden County",
    "state": "NJ",
    "city": "Bellmawr"
  },
  {
    "value": "08032",
    "label": "Grenloch - NJ 08032",
    "county": "Gloucester County",
    "state": "NJ",
    "city": "Grenloch"
  },
  {
    "value": "08033",
    "label": "Haddonfield - NJ 08033",
    "county": "Camden County",
    "state": "NJ",
    "city": "Haddonfield"
  },
  {
    "value": "08034",
    "label": "Cherry Hill - NJ 08034",
    "county": "Camden County",
    "state": "NJ",
    "city": "Cherry Hill"
  },
  {
    "value": "08035",
    "label": "Haddon Heights - NJ 08035",
    "county": "Camden County",
    "state": "NJ",
    "city": "Haddon Heights"
  },
  {
    "value": "08036",
    "label": "Hainesport - NJ 08036",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Hainesport"
  },
  {
    "value": "08037",
    "label": "Hammonton - NJ 08037",
    "county": "Atlantic County",
    "state": "NJ",
    "city": "Hammonton"
  },
  {
    "value": "08041",
    "label": "Jobstown - NJ 08041",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Jobstown"
  },
  {
    "value": "08043",
    "label": "Voorhees - NJ 08043",
    "county": "Camden County",
    "state": "NJ",
    "city": "Voorhees"
  },
  {
    "value": "08045",
    "label": "Lawnside - NJ 08045",
    "county": "Camden County",
    "state": "NJ",
    "city": "Lawnside"
  },
  {
    "value": "08046",
    "label": "Willingboro - NJ 08046",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Willingboro"
  },
  {
    "value": "08048",
    "label": "Lumberton - NJ 08048",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Lumberton"
  },
  {
    "value": "08049",
    "label": "Magnolia - NJ 08049",
    "county": "Camden County",
    "state": "NJ",
    "city": "Magnolia"
  },
  {
    "value": "08050",
    "label": "Manahawkin - NJ 08050",
    "county": "Ocean County",
    "state": "NJ",
    "city": "Manahawkin"
  },
  {
    "value": "08051",
    "label": "Mantua - NJ 08051",
    "county": "Gloucester County",
    "state": "NJ",
    "city": "Mantua"
  },
  {
    "value": "08052",
    "label": "Maple Shade - NJ 08052",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Maple Shade"
  },
  {
    "value": "08053",
    "label": "Marlton - NJ 08053",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Marlton"
  },
  {
    "value": "08054",
    "label": "Mount Laurel - NJ 08054",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Mount Laurel"
  },
  {
    "value": "08055",
    "label": "Medford - NJ 08055",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Medford"
  },
  {
    "value": "08056",
    "label": "Mickleton - NJ 08056",
    "county": "Gloucester County",
    "state": "NJ",
    "city": "Mickleton"
  },
  {
    "value": "08057",
    "label": "Moorestown - NJ 08057",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Moorestown"
  },
  {
    "value": "08059",
    "label": "Mount Ephraim - NJ 08059",
    "county": "Camden County",
    "state": "NJ",
    "city": "Mount Ephraim"
  },
  {
    "value": "08060",
    "label": "Mount Holly - NJ 08060",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Mount Holly"
  },
  {
    "value": "08061",
    "label": "Mount Royal - NJ 08061",
    "county": "Gloucester County",
    "state": "NJ",
    "city": "Mount Royal"
  },
  {
    "value": "08062",
    "label": "Mullica Hill - NJ 08062",
    "county": "Gloucester County",
    "state": "NJ",
    "city": "Mullica Hill"
  },
  {
    "value": "08063",
    "label": "National Park - NJ 08063",
    "county": "Gloucester County",
    "state": "NJ",
    "city": "National Park"
  },
  {
    "value": "08065",
    "label": "Palmyra - NJ 08065",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Palmyra"
  },
  {
    "value": "08066",
    "label": "Paulsboro - NJ 08066",
    "county": "Gloucester County",
    "state": "NJ",
    "city": "Paulsboro"
  },
  {
    "value": "08067",
    "label": "Pedricktown - NJ 08067",
    "county": "Salem County",
    "state": "NJ",
    "city": "Pedricktown"
  },
  {
    "value": "08068",
    "label": "Pemberton - NJ 08068",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Pemberton"
  },
  {
    "value": "08069",
    "label": "Penns Grove - NJ 08069",
    "county": "Salem County",
    "state": "NJ",
    "city": "Penns Grove"
  },
  {
    "value": "08070",
    "label": "Pennsville - NJ 08070",
    "county": "Salem County",
    "state": "NJ",
    "city": "Pennsville"
  },
  {
    "value": "08071",
    "label": "Pitman - NJ 08071",
    "county": "Gloucester County",
    "state": "NJ",
    "city": "Pitman"
  },
  {
    "value": "08075",
    "label": "Riverside - NJ 08075",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Riverside"
  },
  {
    "value": "08077",
    "label": "Riverton - NJ 08077",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Riverton"
  },
  {
    "value": "08078",
    "label": "Runnemede - NJ 08078",
    "county": "Camden County",
    "state": "NJ",
    "city": "Runnemede"
  },
  {
    "value": "08079",
    "label": "Salem - NJ 08079",
    "county": "Salem County",
    "state": "NJ",
    "city": "Salem"
  },
  {
    "value": "08080",
    "label": "Sewell - NJ 08080",
    "county": "Gloucester County",
    "state": "NJ",
    "city": "Sewell"
  },
  {
    "value": "08081",
    "label": "Sicklerville - NJ 08081",
    "county": "Camden County",
    "state": "NJ",
    "city": "Sicklerville"
  },
  {
    "value": "08083",
    "label": "Somerdale - NJ 08083",
    "county": "Camden County",
    "state": "NJ",
    "city": "Somerdale"
  },
  {
    "value": "08084",
    "label": "Stratford - NJ 08084",
    "county": "Camden County",
    "state": "NJ",
    "city": "Stratford"
  },
  {
    "value": "08085",
    "label": "Swedesboro - NJ 08085",
    "county": "Gloucester County",
    "state": "NJ",
    "city": "Swedesboro"
  },
  {
    "value": "08086",
    "label": "Thorofare - NJ 08086",
    "county": "Gloucester County",
    "state": "NJ",
    "city": "Thorofare"
  },
  {
    "value": "08087",
    "label": "Tuckerton - NJ 08087",
    "county": "Ocean County",
    "state": "NJ",
    "city": "Tuckerton"
  },
  {
    "value": "08088",
    "label": "Vincentown - NJ 08088",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Vincentown"
  },
  {
    "value": "08089",
    "label": "Waterford Works - NJ 08089",
    "county": "Camden County",
    "state": "NJ",
    "city": "Waterford Works"
  },
  {
    "value": "08090",
    "label": "Wenonah - NJ 08090",
    "county": "Gloucester County",
    "state": "NJ",
    "city": "Wenonah"
  },
  {
    "value": "08091",
    "label": "West Berlin - NJ 08091",
    "county": "Camden County",
    "state": "NJ",
    "city": "West Berlin"
  },
  {
    "value": "08092",
    "label": "West Creek - NJ 08092",
    "county": "Ocean County",
    "state": "NJ",
    "city": "West Creek"
  },
  {
    "value": "08093",
    "label": "Westville - NJ 08093",
    "county": "Gloucester County",
    "state": "NJ",
    "city": "Westville"
  },
  {
    "value": "08094",
    "label": "Williamstown - NJ 08094",
    "county": "Gloucester County",
    "state": "NJ",
    "city": "Williamstown"
  },
  {
    "value": "08096",
    "label": "Woodbury - NJ 08096",
    "county": "Gloucester County",
    "state": "NJ",
    "city": "Woodbury"
  },
  {
    "value": "08097",
    "label": "Woodbury Heights - NJ 08097",
    "county": "Gloucester County",
    "state": "NJ",
    "city": "Woodbury Heights"
  },
  {
    "value": "08098",
    "label": "Woodstown - NJ 08098",
    "county": "Salem County",
    "state": "NJ",
    "city": "Woodstown"
  },
  {
    "value": "08102",
    "label": "Camden - NJ 08102",
    "county": "Camden County",
    "state": "NJ",
    "city": "Camden"
  },
  {
    "value": "08103",
    "label": "Camden - NJ 08103",
    "county": "Camden County",
    "state": "NJ",
    "city": "Camden"
  },
  {
    "value": "08104",
    "label": "Camden - NJ 08104",
    "county": "Camden County",
    "state": "NJ",
    "city": "Camden"
  },
  {
    "value": "08105",
    "label": "Camden - NJ 08105",
    "county": "Camden County",
    "state": "NJ",
    "city": "Camden"
  },
  {
    "value": "08106",
    "label": "Audubon - NJ 08106",
    "county": "Camden County",
    "state": "NJ",
    "city": "Audubon"
  },
  {
    "value": "08107",
    "label": "Oaklyn - NJ 08107",
    "county": "Camden County",
    "state": "NJ",
    "city": "Oaklyn"
  },
  {
    "value": "08108",
    "label": "Collingswood - NJ 08108",
    "county": "Camden County",
    "state": "NJ",
    "city": "Collingswood"
  },
  {
    "value": "08109",
    "label": "Merchantville - NJ 08109",
    "county": "Camden County",
    "state": "NJ",
    "city": "Merchantville"
  },
  {
    "value": "08110",
    "label": "Pennsauken - NJ 08110",
    "county": "Camden County",
    "state": "NJ",
    "city": "Pennsauken"
  },
  {
    "value": "08201",
    "label": "Absecon - NJ 08201",
    "county": "Atlantic County",
    "state": "NJ",
    "city": "Absecon"
  },
  {
    "value": "08202",
    "label": "Avalon - NJ 08202",
    "county": "Cape May County",
    "state": "NJ",
    "city": "Avalon"
  },
  {
    "value": "08203",
    "label": "Brigantine - NJ 08203",
    "county": "Atlantic County",
    "state": "NJ",
    "city": "Brigantine"
  },
  {
    "value": "08204",
    "label": "Cape May - NJ 08204",
    "county": "Cape May County",
    "state": "NJ",
    "city": "Cape May"
  },
  {
    "value": "08205",
    "label": "Absecon - NJ 08205",
    "county": "Atlantic County",
    "state": "NJ",
    "city": "Absecon"
  },
  {
    "value": "08210",
    "label": "Cape May Court House - NJ 08210",
    "county": "Cape May County",
    "state": "NJ",
    "city": "Cape May Court House"
  },
  {
    "value": "08215",
    "label": "Egg Harbor City - NJ 08215",
    "county": "Atlantic County",
    "state": "NJ",
    "city": "Egg Harbor City"
  },
  {
    "value": "08221",
    "label": "Linwood - NJ 08221",
    "county": "Atlantic County",
    "state": "NJ",
    "city": "Linwood"
  },
  {
    "value": "08223",
    "label": "Marmora - NJ 08223",
    "county": "Cape May County",
    "state": "NJ",
    "city": "Marmora"
  },
  {
    "value": "08225",
    "label": "Northfield - NJ 08225",
    "county": "Atlantic County",
    "state": "NJ",
    "city": "Northfield"
  },
  {
    "value": "08226",
    "label": "Ocean City - NJ 08226",
    "county": "Cape May County",
    "state": "NJ",
    "city": "Ocean City"
  },
  {
    "value": "08230",
    "label": "Ocean View - NJ 08230",
    "county": "Cape May County",
    "state": "NJ",
    "city": "Ocean View"
  },
  {
    "value": "08232",
    "label": "Pleasantville - NJ 08232",
    "county": "Atlantic County",
    "state": "NJ",
    "city": "Pleasantville"
  },
  {
    "value": "08234",
    "label": "Egg Harbor Township - NJ 08234",
    "county": "Atlantic County",
    "state": "NJ",
    "city": "Egg Harbor Township"
  },
  {
    "value": "08241",
    "label": "Port Republic - NJ 08241",
    "county": "Atlantic County",
    "state": "NJ",
    "city": "Port Republic"
  },
  {
    "value": "08242",
    "label": "Rio Grande - NJ 08242",
    "county": "Cape May County",
    "state": "NJ",
    "city": "Rio Grande"
  },
  {
    "value": "08243",
    "label": "Sea Isle City - NJ 08243",
    "county": "Cape May County",
    "state": "NJ",
    "city": "Sea Isle City"
  },
  {
    "value": "08244",
    "label": "Somers Point - NJ 08244",
    "county": "Atlantic County",
    "state": "NJ",
    "city": "Somers Point"
  },
  {
    "value": "08247",
    "label": "Stone Harbor - NJ 08247",
    "county": "Cape May County",
    "state": "NJ",
    "city": "Stone Harbor"
  },
  {
    "value": "08251",
    "label": "Villas - NJ 08251",
    "county": "Cape May County",
    "state": "NJ",
    "city": "Villas"
  },
  {
    "value": "08260",
    "label": "Wildwood - NJ 08260",
    "county": "Cape May County",
    "state": "NJ",
    "city": "Wildwood"
  },
  {
    "value": "08270",
    "label": "Woodbine - NJ 08270",
    "county": "Cape May County",
    "state": "NJ",
    "city": "Woodbine"
  },
  {
    "value": "08302",
    "label": "Bridgeton - NJ 08302",
    "county": "Cumberland County",
    "state": "NJ",
    "city": "Bridgeton"
  },
  {
    "value": "08310",
    "label": "Buena - NJ 08310",
    "county": "Atlantic County",
    "state": "NJ",
    "city": "Buena"
  },
  {
    "value": "08311",
    "label": "Cedarville - NJ 08311",
    "county": "Cumberland County",
    "state": "NJ",
    "city": "Cedarville"
  },
  {
    "value": "08312",
    "label": "Clayton - NJ 08312",
    "county": "Gloucester County",
    "state": "NJ",
    "city": "Clayton"
  },
  {
    "value": "08314",
    "label": "Delmont - NJ 08314",
    "county": "Cumberland County",
    "state": "NJ",
    "city": "Delmont"
  },
  {
    "value": "08317",
    "label": "Dorothy - NJ 08317",
    "county": "Atlantic County",
    "state": "NJ",
    "city": "Dorothy"
  },
  {
    "value": "08318",
    "label": "Elmer - NJ 08318",
    "county": "Salem County",
    "state": "NJ",
    "city": "Elmer"
  },
  {
    "value": "08319",
    "label": "Estell Manor - NJ 08319",
    "county": "Atlantic County",
    "state": "NJ",
    "city": "Estell Manor"
  },
  {
    "value": "08322",
    "label": "Franklinville - NJ 08322",
    "county": "Gloucester County",
    "state": "NJ",
    "city": "Franklinville"
  },
  {
    "value": "08323",
    "label": "Greenwich - NJ 08323",
    "county": "Cumberland County",
    "state": "NJ",
    "city": "Greenwich"
  },
  {
    "value": "08324",
    "label": "Heislerville - NJ 08324",
    "county": "Cumberland County",
    "state": "NJ",
    "city": "Heislerville"
  },
  {
    "value": "08326",
    "label": "Landisville - NJ 08326",
    "county": "Atlantic County",
    "state": "NJ",
    "city": "Landisville"
  },
  {
    "value": "08327",
    "label": "Leesburg - NJ 08327",
    "county": "Cumberland County",
    "state": "NJ",
    "city": "Leesburg"
  },
  {
    "value": "08328",
    "label": "Malaga - NJ 08328",
    "county": "Gloucester County",
    "state": "NJ",
    "city": "Malaga"
  },
  {
    "value": "08330",
    "label": "Mays Landing - NJ 08330",
    "county": "Atlantic County",
    "state": "NJ",
    "city": "Mays Landing"
  },
  {
    "value": "08332",
    "label": "Millville - NJ 08332",
    "county": "Cumberland County",
    "state": "NJ",
    "city": "Millville"
  },
  {
    "value": "08340",
    "label": "Milmay - NJ 08340",
    "county": "Atlantic County",
    "state": "NJ",
    "city": "Milmay"
  },
  {
    "value": "08341",
    "label": "Minotola - NJ 08341",
    "county": "Atlantic County",
    "state": "NJ",
    "city": "Minotola"
  },
  {
    "value": "08343",
    "label": "Monroeville - NJ 08343",
    "county": "Gloucester County",
    "state": "NJ",
    "city": "Monroeville"
  },
  {
    "value": "08344",
    "label": "Newfield - NJ 08344",
    "county": "Gloucester County",
    "state": "NJ",
    "city": "Newfield"
  },
  {
    "value": "08345",
    "label": "Newport - NJ 08345",
    "county": "Cumberland County",
    "state": "NJ",
    "city": "Newport"
  },
  {
    "value": "08346",
    "label": "Newtonville - NJ 08346",
    "county": "Atlantic County",
    "state": "NJ",
    "city": "Newtonville"
  },
  {
    "value": "08348",
    "label": "Port Elizabeth - NJ 08348",
    "county": "Cumberland County",
    "state": "NJ",
    "city": "Port Elizabeth"
  },
  {
    "value": "08349",
    "label": "Port Norris - NJ 08349",
    "county": "Cumberland County",
    "state": "NJ",
    "city": "Port Norris"
  },
  {
    "value": "08350",
    "label": "Richland - NJ 08350",
    "county": "Atlantic County",
    "state": "NJ",
    "city": "Richland"
  },
  {
    "value": "08353",
    "label": "Shiloh - NJ 08353",
    "county": "Cumberland County",
    "state": "NJ",
    "city": "Shiloh"
  },
  {
    "value": "08360",
    "label": "Vineland - NJ 08360",
    "county": "Cumberland County",
    "state": "NJ",
    "city": "Vineland"
  },
  {
    "value": "08361",
    "label": "Vineland - NJ 08361",
    "county": "Cumberland County",
    "state": "NJ",
    "city": "Vineland"
  },
  {
    "value": "08401",
    "label": "Atlantic City - NJ 08401",
    "county": "Atlantic County",
    "state": "NJ",
    "city": "Atlantic City"
  },
  {
    "value": "08402",
    "label": "Margate City - NJ 08402",
    "county": "Atlantic County",
    "state": "NJ",
    "city": "Margate City"
  },
  {
    "value": "08403",
    "label": "Longport - NJ 08403",
    "county": "Atlantic County",
    "state": "NJ",
    "city": "Longport"
  },
  {
    "value": "08406",
    "label": "Ventnor City - NJ 08406",
    "county": "Atlantic County",
    "state": "NJ",
    "city": "Ventnor City"
  },
  {
    "value": "08501",
    "label": "Allentown - NJ 08501",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Allentown"
  },
  {
    "value": "08502",
    "label": "Belle Mead - NJ 08502",
    "county": "Somerset County",
    "state": "NJ",
    "city": "Belle Mead"
  },
  {
    "value": "08505",
    "label": "Bordentown - NJ 08505",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Bordentown"
  },
  {
    "value": "08510",
    "label": "Millstone Township - NJ 08510",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Millstone Township"
  },
  {
    "value": "08511",
    "label": "Cookstown - NJ 08511",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Cookstown"
  },
  {
    "value": "08512",
    "label": "Cranbury - NJ 08512",
    "county": "Mercer County",
    "state": "NJ",
    "city": "Cranbury"
  },
  {
    "value": "08514",
    "label": "Cream Ridge - NJ 08514",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Cream Ridge"
  },
  {
    "value": "08515",
    "label": "Chesterfield - NJ 08515",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Chesterfield"
  },
  {
    "value": "08518",
    "label": "Florence - NJ 08518",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Florence"
  },
  {
    "value": "08520",
    "label": "Hightstown - NJ 08520",
    "county": "Mercer County",
    "state": "NJ",
    "city": "Hightstown"
  },
  {
    "value": "08525",
    "label": "Hopewell - NJ 08525",
    "county": "Mercer County",
    "state": "NJ",
    "city": "Hopewell"
  },
  {
    "value": "08527",
    "label": "Jackson - NJ 08527",
    "county": "Ocean County",
    "state": "NJ",
    "city": "Jackson"
  },
  {
    "value": "08528",
    "label": "Kingston - NJ 08528",
    "county": "Somerset County",
    "state": "NJ",
    "city": "Kingston"
  },
  {
    "value": "08530",
    "label": "Lambertville - NJ 08530",
    "county": "Hunterdon County",
    "state": "NJ",
    "city": "Lambertville"
  },
  {
    "value": "08533",
    "label": "New Egypt - NJ 08533",
    "county": "Ocean County",
    "state": "NJ",
    "city": "New Egypt"
  },
  {
    "value": "08534",
    "label": "Pennington - NJ 08534",
    "county": "Mercer County",
    "state": "NJ",
    "city": "Pennington"
  },
  {
    "value": "08535",
    "label": "Millstone Township - NJ 08535",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Millstone Township"
  },
  {
    "value": "08536",
    "label": "Plainsboro - NJ 08536",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Plainsboro"
  },
  {
    "value": "08540",
    "label": "Princeton - NJ 08540",
    "county": "Mercer County",
    "state": "NJ",
    "city": "Princeton"
  },
  {
    "value": "08542",
    "label": "Princeton - NJ 08542",
    "county": "Mercer County",
    "state": "NJ",
    "city": "Princeton"
  },
  {
    "value": "08550",
    "label": "Princeton Junction - NJ 08550",
    "county": "Mercer County",
    "state": "NJ",
    "city": "Princeton Junction"
  },
  {
    "value": "08551",
    "label": "Ringoes - NJ 08551",
    "county": "Hunterdon County",
    "state": "NJ",
    "city": "Ringoes"
  },
  {
    "value": "08553",
    "label": "Rocky Hill - NJ 08553",
    "county": "Somerset County",
    "state": "NJ",
    "city": "Rocky Hill"
  },
  {
    "value": "08554",
    "label": "Roebling - NJ 08554",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Roebling"
  },
  {
    "value": "08556",
    "label": "Rosemont - NJ 08556",
    "county": "Hunterdon County",
    "state": "NJ",
    "city": "Rosemont"
  },
  {
    "value": "08558",
    "label": "Skillman - NJ 08558",
    "county": "Somerset County",
    "state": "NJ",
    "city": "Skillman"
  },
  {
    "value": "08559",
    "label": "Stockton - NJ 08559",
    "county": "Hunterdon County",
    "state": "NJ",
    "city": "Stockton"
  },
  {
    "value": "08560",
    "label": "Titusville - NJ 08560",
    "county": "Mercer County",
    "state": "NJ",
    "city": "Titusville"
  },
  {
    "value": "08562",
    "label": "Wrightstown - NJ 08562",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Wrightstown"
  },
  {
    "value": "08608",
    "label": "Trenton - NJ 08608",
    "county": "Mercer County",
    "state": "NJ",
    "city": "Trenton"
  },
  {
    "value": "08609",
    "label": "Trenton - NJ 08609",
    "county": "Mercer County",
    "state": "NJ",
    "city": "Trenton"
  },
  {
    "value": "08610",
    "label": "Trenton - NJ 08610",
    "county": "Mercer County",
    "state": "NJ",
    "city": "Trenton"
  },
  {
    "value": "08611",
    "label": "Trenton - NJ 08611",
    "county": "Mercer County",
    "state": "NJ",
    "city": "Trenton"
  },
  {
    "value": "08618",
    "label": "Trenton - NJ 08618",
    "county": "Mercer County",
    "state": "NJ",
    "city": "Trenton"
  },
  {
    "value": "08619",
    "label": "Trenton - NJ 08619",
    "county": "Mercer County",
    "state": "NJ",
    "city": "Trenton"
  },
  {
    "value": "08620",
    "label": "Trenton - NJ 08620",
    "county": "Mercer County",
    "state": "NJ",
    "city": "Trenton"
  },
  {
    "value": "08628",
    "label": "Trenton - NJ 08628",
    "county": "Mercer County",
    "state": "NJ",
    "city": "Trenton"
  },
  {
    "value": "08629",
    "label": "Trenton - NJ 08629",
    "county": "Mercer County",
    "state": "NJ",
    "city": "Trenton"
  },
  {
    "value": "08638",
    "label": "Trenton - NJ 08638",
    "county": "Mercer County",
    "state": "NJ",
    "city": "Trenton"
  },
  {
    "value": "08640",
    "label": "Joint Base Mdl - NJ 08640",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Joint Base Mdl"
  },
  {
    "value": "08641",
    "label": "Joint Base Mdl - NJ 08641",
    "county": "Burlington County",
    "state": "NJ",
    "city": "Joint Base Mdl"
  },
  {
    "value": "08644",
    "label": "Trenton - NJ 08644",
    "county": "",
    "state": "NJ",
    "city": "Trenton"
  },
  {
    "value": "08648",
    "label": "Lawrence Township - NJ 08648",
    "county": "Mercer County",
    "state": "NJ",
    "city": "Lawrence Township"
  },
  {
    "value": "08690",
    "label": "Trenton - NJ 08690",
    "county": "Mercer County",
    "state": "NJ",
    "city": "Trenton"
  },
  {
    "value": "08691",
    "label": "Robbinsville - NJ 08691",
    "county": "Mercer County",
    "state": "NJ",
    "city": "Robbinsville"
  },
  {
    "value": "08701",
    "label": "Lakewood - NJ 08701",
    "county": "Ocean County",
    "state": "NJ",
    "city": "Lakewood"
  },
  {
    "value": "08721",
    "label": "Bayville - NJ 08721",
    "county": "Ocean County",
    "state": "NJ",
    "city": "Bayville"
  },
  {
    "value": "08722",
    "label": "Beachwood - NJ 08722",
    "county": "Ocean County",
    "state": "NJ",
    "city": "Beachwood"
  },
  {
    "value": "08723",
    "label": "Brick - NJ 08723",
    "county": "Ocean County",
    "state": "NJ",
    "city": "Brick"
  },
  {
    "value": "08724",
    "label": "Brick - NJ 08724",
    "county": "Ocean County",
    "state": "NJ",
    "city": "Brick"
  },
  {
    "value": "08730",
    "label": "Brielle - NJ 08730",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Brielle"
  },
  {
    "value": "08731",
    "label": "Forked River - NJ 08731",
    "county": "Ocean County",
    "state": "NJ",
    "city": "Forked River"
  },
  {
    "value": "08733",
    "label": "Lakehurst - NJ 08733",
    "county": "Ocean County",
    "state": "NJ",
    "city": "Lakehurst"
  },
  {
    "value": "08734",
    "label": "Lanoka Harbor - NJ 08734",
    "county": "Ocean County",
    "state": "NJ",
    "city": "Lanoka Harbor"
  },
  {
    "value": "08735",
    "label": "Lavallette - NJ 08735",
    "county": "Ocean County",
    "state": "NJ",
    "city": "Lavallette"
  },
  {
    "value": "08736",
    "label": "Manasquan - NJ 08736",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Manasquan"
  },
  {
    "value": "08738",
    "label": "Mantoloking - NJ 08738",
    "county": "Ocean County",
    "state": "NJ",
    "city": "Mantoloking"
  },
  {
    "value": "08741",
    "label": "Pine Beach - NJ 08741",
    "county": "Ocean County",
    "state": "NJ",
    "city": "Pine Beach"
  },
  {
    "value": "08742",
    "label": "Point Pleasant Beach - NJ 08742",
    "county": "Ocean County",
    "state": "NJ",
    "city": "Point Pleasant Beach"
  },
  {
    "value": "08750",
    "label": "Sea Girt - NJ 08750",
    "county": "Monmouth County",
    "state": "NJ",
    "city": "Sea Girt"
  },
  {
    "value": "08751",
    "label": "Seaside Heights - NJ 08751",
    "county": "Ocean County",
    "state": "NJ",
    "city": "Seaside Heights"
  },
  {
    "value": "08752",
    "label": "Seaside Park - NJ 08752",
    "county": "Ocean County",
    "state": "NJ",
    "city": "Seaside Park"
  },
  {
    "value": "08753",
    "label": "Toms River - NJ 08753",
    "county": "Ocean County",
    "state": "NJ",
    "city": "Toms River"
  },
  {
    "value": "08755",
    "label": "Toms River - NJ 08755",
    "county": "Ocean County",
    "state": "NJ",
    "city": "Toms River"
  },
  {
    "value": "08757",
    "label": "Toms River - NJ 08757",
    "county": "Ocean County",
    "state": "NJ",
    "city": "Toms River"
  },
  {
    "value": "08758",
    "label": "Waretown - NJ 08758",
    "county": "Ocean County",
    "state": "NJ",
    "city": "Waretown"
  },
  {
    "value": "08759",
    "label": "Manchester Township - NJ 08759",
    "county": "Ocean County",
    "state": "NJ",
    "city": "Manchester Township"
  },
  {
    "value": "08801",
    "label": "Annandale - NJ 08801",
    "county": "Hunterdon County",
    "state": "NJ",
    "city": "Annandale"
  },
  {
    "value": "08802",
    "label": "Asbury - NJ 08802",
    "county": "Hunterdon County",
    "state": "NJ",
    "city": "Asbury"
  },
  {
    "value": "08804",
    "label": "Bloomsbury - NJ 08804",
    "county": "Hunterdon County",
    "state": "NJ",
    "city": "Bloomsbury"
  },
  {
    "value": "08805",
    "label": "Bound Brook - NJ 08805",
    "county": "Somerset County",
    "state": "NJ",
    "city": "Bound Brook"
  },
  {
    "value": "08807",
    "label": "Bridgewater - NJ 08807",
    "county": "Somerset County",
    "state": "NJ",
    "city": "Bridgewater"
  },
  {
    "value": "08809",
    "label": "Clinton - NJ 08809",
    "county": "Hunterdon County",
    "state": "NJ",
    "city": "Clinton"
  },
  {
    "value": "08810",
    "label": "Dayton - NJ 08810",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Dayton"
  },
  {
    "value": "08812",
    "label": "Dunellen - NJ 08812",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Dunellen"
  },
  {
    "value": "08816",
    "label": "East Brunswick - NJ 08816",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "East Brunswick"
  },
  {
    "value": "08817",
    "label": "Edison - NJ 08817",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Edison"
  },
  {
    "value": "08820",
    "label": "Edison - NJ 08820",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Edison"
  },
  {
    "value": "08822",
    "label": "Flemington - NJ 08822",
    "county": "Hunterdon County",
    "state": "NJ",
    "city": "Flemington"
  },
  {
    "value": "08823",
    "label": "Franklin Park - NJ 08823",
    "county": "Somerset County",
    "state": "NJ",
    "city": "Franklin Park"
  },
  {
    "value": "08824",
    "label": "Kendall Park - NJ 08824",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Kendall Park"
  },
  {
    "value": "08825",
    "label": "Frenchtown - NJ 08825",
    "county": "Hunterdon County",
    "state": "NJ",
    "city": "Frenchtown"
  },
  {
    "value": "08826",
    "label": "Glen Gardner - NJ 08826",
    "county": "Hunterdon County",
    "state": "NJ",
    "city": "Glen Gardner"
  },
  {
    "value": "08827",
    "label": "Hampton - NJ 08827",
    "county": "Hunterdon County",
    "state": "NJ",
    "city": "Hampton"
  },
  {
    "value": "08828",
    "label": "Helmetta - NJ 08828",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Helmetta"
  },
  {
    "value": "08829",
    "label": "High Bridge - NJ 08829",
    "county": "Hunterdon County",
    "state": "NJ",
    "city": "High Bridge"
  },
  {
    "value": "08830",
    "label": "Iselin - NJ 08830",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Iselin"
  },
  {
    "value": "08831",
    "label": "Monroe Township - NJ 08831",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Monroe Township"
  },
  {
    "value": "08832",
    "label": "Keasbey - NJ 08832",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Keasbey"
  },
  {
    "value": "08833",
    "label": "Lebanon - NJ 08833",
    "county": "Hunterdon County",
    "state": "NJ",
    "city": "Lebanon"
  },
  {
    "value": "08835",
    "label": "Manville - NJ 08835",
    "county": "Somerset County",
    "state": "NJ",
    "city": "Manville"
  },
  {
    "value": "08836",
    "label": "Martinsville - NJ 08836",
    "county": "Somerset County",
    "state": "NJ",
    "city": "Martinsville"
  },
  {
    "value": "08837",
    "label": "Edison - NJ 08837",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Edison"
  },
  {
    "value": "08840",
    "label": "Metuchen - NJ 08840",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Metuchen"
  },
  {
    "value": "08844",
    "label": "Hillsborough - NJ 08844",
    "county": "Somerset County",
    "state": "NJ",
    "city": "Hillsborough"
  },
  {
    "value": "08846",
    "label": "Middlesex - NJ 08846",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Middlesex"
  },
  {
    "value": "08848",
    "label": "Milford - NJ 08848",
    "county": "Hunterdon County",
    "state": "NJ",
    "city": "Milford"
  },
  {
    "value": "08850",
    "label": "Milltown - NJ 08850",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Milltown"
  },
  {
    "value": "08852",
    "label": "Monmouth Junction - NJ 08852",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Monmouth Junction"
  },
  {
    "value": "08853",
    "label": "Neshanic Station - NJ 08853",
    "county": "Somerset County",
    "state": "NJ",
    "city": "Neshanic Station"
  },
  {
    "value": "08854",
    "label": "Piscataway - NJ 08854",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Piscataway"
  },
  {
    "value": "08857",
    "label": "Old Bridge - NJ 08857",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Old Bridge"
  },
  {
    "value": "08859",
    "label": "Parlin - NJ 08859",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Parlin"
  },
  {
    "value": "08861",
    "label": "Perth Amboy - NJ 08861",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Perth Amboy"
  },
  {
    "value": "08863",
    "label": "Fords - NJ 08863",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Fords"
  },
  {
    "value": "08865",
    "label": "Phillipsburg - NJ 08865",
    "county": "Warren County",
    "state": "NJ",
    "city": "Phillipsburg"
  },
  {
    "value": "08867",
    "label": "Pittstown - NJ 08867",
    "county": "Hunterdon County",
    "state": "NJ",
    "city": "Pittstown"
  },
  {
    "value": "08869",
    "label": "Raritan - NJ 08869",
    "county": "Somerset County",
    "state": "NJ",
    "city": "Raritan"
  },
  {
    "value": "08872",
    "label": "Sayreville - NJ 08872",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Sayreville"
  },
  {
    "value": "08873",
    "label": "Somerset - NJ 08873",
    "county": "Somerset County",
    "state": "NJ",
    "city": "Somerset"
  },
  {
    "value": "08876",
    "label": "Somerville - NJ 08876",
    "county": "Somerset County",
    "state": "NJ",
    "city": "Somerville"
  },
  {
    "value": "08879",
    "label": "South Amboy - NJ 08879",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "South Amboy"
  },
  {
    "value": "08880",
    "label": "South Bound Brook - NJ 08880",
    "county": "Somerset County",
    "state": "NJ",
    "city": "South Bound Brook"
  },
  {
    "value": "08882",
    "label": "South River - NJ 08882",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "South River"
  },
  {
    "value": "08884",
    "label": "Spotswood - NJ 08884",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Spotswood"
  },
  {
    "value": "08886",
    "label": "Stewartsville - NJ 08886",
    "county": "Warren County",
    "state": "NJ",
    "city": "Stewartsville"
  },
  {
    "value": "08887",
    "label": "Three Bridges - NJ 08887",
    "county": "Hunterdon County",
    "state": "NJ",
    "city": "Three Bridges"
  },
  {
    "value": "08889",
    "label": "Whitehouse Station - NJ 08889",
    "county": "Hunterdon County",
    "state": "NJ",
    "city": "Whitehouse Station"
  },
  {
    "value": "08899",
    "label": "Edison - NJ 08899",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Edison"
  },
  {
    "value": "08901",
    "label": "New Brunswick - NJ 08901",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "New Brunswick"
  },
  {
    "value": "08902",
    "label": "North Brunswick - NJ 08902",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "North Brunswick"
  },
  {
    "value": "08904",
    "label": "Highland Park - NJ 08904",
    "county": "Middlesex County",
    "state": "NJ",
    "city": "Highland Park"
  },
  {
    "value": "87001",
    "label": "Algodones - NM 87001",
    "county": "Sandoval County",
    "state": "NM",
    "city": "Algodones"
  },
  {
    "value": "87002",
    "label": "Belen - NM 87002",
    "county": "Valencia County",
    "state": "NM",
    "city": "Belen"
  },
  {
    "value": "87004",
    "label": "Bernalillo - NM 87004",
    "county": "Sandoval County",
    "state": "NM",
    "city": "Bernalillo"
  },
  {
    "value": "87005",
    "label": "Bluewater - NM 87005",
    "county": "Cibola County",
    "state": "NM",
    "city": "Bluewater"
  },
  {
    "value": "87006",
    "label": "Bosque - NM 87006",
    "county": "Socorro County",
    "state": "NM",
    "city": "Bosque"
  },
  {
    "value": "87008",
    "label": "Cedar Crest - NM 87008",
    "county": "Bernalillo County",
    "state": "NM",
    "city": "Cedar Crest"
  },
  {
    "value": "87009",
    "label": "Cedarvale - NM 87009",
    "county": "Torrance County",
    "state": "NM",
    "city": "Cedarvale"
  },
  {
    "value": "87010",
    "label": "Cerrillos - NM 87010",
    "county": "Santa Fe County",
    "state": "NM",
    "city": "Cerrillos"
  },
  {
    "value": "87012",
    "label": "Coyote - NM 87012",
    "county": "Rio Arriba County",
    "state": "NM",
    "city": "Coyote"
  },
  {
    "value": "87013",
    "label": "Cuba - NM 87013",
    "county": "Sandoval County",
    "state": "NM",
    "city": "Cuba"
  },
  {
    "value": "87014",
    "label": "Cubero - NM 87014",
    "county": "Cibola County",
    "state": "NM",
    "city": "Cubero"
  },
  {
    "value": "87015",
    "label": "Edgewood - NM 87015",
    "county": "Santa Fe County",
    "state": "NM",
    "city": "Edgewood"
  },
  {
    "value": "87016",
    "label": "Estancia - NM 87016",
    "county": "Torrance County",
    "state": "NM",
    "city": "Estancia"
  },
  {
    "value": "87017",
    "label": "Gallina - NM 87017",
    "county": "Rio Arriba County",
    "state": "NM",
    "city": "Gallina"
  },
  {
    "value": "87020",
    "label": "Grants - NM 87020",
    "county": "Cibola County",
    "state": "NM",
    "city": "Grants"
  },
  {
    "value": "87023",
    "label": "Jarales - NM 87023",
    "county": "Valencia County",
    "state": "NM",
    "city": "Jarales"
  },
  {
    "value": "87024",
    "label": "Jemez Pueblo - NM 87024",
    "county": "Sandoval County",
    "state": "NM",
    "city": "Jemez Pueblo"
  },
  {
    "value": "87025",
    "label": "Jemez Springs - NM 87025",
    "county": "Sandoval County",
    "state": "NM",
    "city": "Jemez Springs"
  },
  {
    "value": "87026",
    "label": "Laguna - NM 87026",
    "county": "Valencia County",
    "state": "NM",
    "city": "Laguna"
  },
  {
    "value": "87027",
    "label": "La Jara - NM 87027",
    "county": "Sandoval County",
    "state": "NM",
    "city": "La Jara"
  },
  {
    "value": "87028",
    "label": "La Joya - NM 87028",
    "county": "Socorro County",
    "state": "NM",
    "city": "La Joya"
  },
  {
    "value": "87029",
    "label": "Lindrith - NM 87029",
    "county": "Rio Arriba County",
    "state": "NM",
    "city": "Lindrith"
  },
  {
    "value": "87031",
    "label": "Los Lunas - NM 87031",
    "county": "Valencia County",
    "state": "NM",
    "city": "Los Lunas"
  },
  {
    "value": "87035",
    "label": "Moriarty - NM 87035",
    "county": "Torrance County",
    "state": "NM",
    "city": "Moriarty"
  },
  {
    "value": "87036",
    "label": "Mountainair - NM 87036",
    "county": "Torrance County",
    "state": "NM",
    "city": "Mountainair"
  },
  {
    "value": "87041",
    "label": "Pena Blanca - NM 87041",
    "county": "Sandoval County",
    "state": "NM",
    "city": "Pena Blanca"
  },
  {
    "value": "87042",
    "label": "Peralta - NM 87042",
    "county": "Valencia County",
    "state": "NM",
    "city": "Peralta"
  },
  {
    "value": "87043",
    "label": "Placitas - NM 87043",
    "county": "Sandoval County",
    "state": "NM",
    "city": "Placitas"
  },
  {
    "value": "87044",
    "label": "Ponderosa - NM 87044",
    "county": "Sandoval County",
    "state": "NM",
    "city": "Ponderosa"
  },
  {
    "value": "87045",
    "label": "Prewitt - NM 87045",
    "county": "McKinley County",
    "state": "NM",
    "city": "Prewitt"
  },
  {
    "value": "87046",
    "label": "Regina - NM 87046",
    "county": "Sandoval County",
    "state": "NM",
    "city": "Regina"
  },
  {
    "value": "87047",
    "label": "Sandia Park - NM 87047",
    "county": "Bernalillo County",
    "state": "NM",
    "city": "Sandia Park"
  },
  {
    "value": "87048",
    "label": "Corrales - NM 87048",
    "county": "Sandoval County",
    "state": "NM",
    "city": "Corrales"
  },
  {
    "value": "87052",
    "label": "Santo Domingo Pueblo - NM 87052",
    "county": "Sandoval County",
    "state": "NM",
    "city": "Santo Domingo Pueblo"
  },
  {
    "value": "87053",
    "label": "San Ysidro - NM 87053",
    "county": "Sandoval County",
    "state": "NM",
    "city": "San Ysidro"
  },
  {
    "value": "87056",
    "label": "Stanley - NM 87056",
    "county": "Santa Fe County",
    "state": "NM",
    "city": "Stanley"
  },
  {
    "value": "87059",
    "label": "Tijeras - NM 87059",
    "county": "Bernalillo County",
    "state": "NM",
    "city": "Tijeras"
  },
  {
    "value": "87062",
    "label": "Veguita - NM 87062",
    "county": "Socorro County",
    "state": "NM",
    "city": "Veguita"
  },
  {
    "value": "87063",
    "label": "Willard - NM 87063",
    "county": "Torrance County",
    "state": "NM",
    "city": "Willard"
  },
  {
    "value": "87064",
    "label": "Youngsville - NM 87064",
    "county": "Rio Arriba County",
    "state": "NM",
    "city": "Youngsville"
  },
  {
    "value": "87068",
    "label": "Bosque Farms - NM 87068",
    "county": "Valencia County",
    "state": "NM",
    "city": "Bosque Farms"
  },
  {
    "value": "87083",
    "label": "Cochiti Lake - NM 87083",
    "county": "Sandoval County",
    "state": "NM",
    "city": "Cochiti Lake"
  },
  {
    "value": "87101",
    "label": "Albuquerque - NM 87101",
    "county": "Bernalillo County",
    "state": "NM",
    "city": "Albuquerque"
  },
  {
    "value": "87102",
    "label": "Albuquerque - NM 87102",
    "county": "Bernalillo County",
    "state": "NM",
    "city": "Albuquerque"
  },
  {
    "value": "87104",
    "label": "Albuquerque - NM 87104",
    "county": "Bernalillo County",
    "state": "NM",
    "city": "Albuquerque"
  },
  {
    "value": "87105",
    "label": "Albuquerque - NM 87105",
    "county": "Bernalillo County",
    "state": "NM",
    "city": "Albuquerque"
  },
  {
    "value": "87106",
    "label": "Albuquerque - NM 87106",
    "county": "Bernalillo County",
    "state": "NM",
    "city": "Albuquerque"
  },
  {
    "value": "87107",
    "label": "Albuquerque - NM 87107",
    "county": "Bernalillo County",
    "state": "NM",
    "city": "Albuquerque"
  },
  {
    "value": "87108",
    "label": "Albuquerque - NM 87108",
    "county": "Bernalillo County",
    "state": "NM",
    "city": "Albuquerque"
  },
  {
    "value": "87109",
    "label": "Albuquerque - NM 87109",
    "county": "Bernalillo County",
    "state": "NM",
    "city": "Albuquerque"
  },
  {
    "value": "87110",
    "label": "Albuquerque - NM 87110",
    "county": "Bernalillo County",
    "state": "NM",
    "city": "Albuquerque"
  },
  {
    "value": "87111",
    "label": "Albuquerque - NM 87111",
    "county": "Bernalillo County",
    "state": "NM",
    "city": "Albuquerque"
  },
  {
    "value": "87112",
    "label": "Albuquerque - NM 87112",
    "county": "Bernalillo County",
    "state": "NM",
    "city": "Albuquerque"
  },
  {
    "value": "87113",
    "label": "Albuquerque - NM 87113",
    "county": "Bernalillo County",
    "state": "NM",
    "city": "Albuquerque"
  },
  {
    "value": "87114",
    "label": "Albuquerque - NM 87114",
    "county": "Bernalillo County",
    "state": "NM",
    "city": "Albuquerque"
  },
  {
    "value": "87115",
    "label": "Albuquerque - NM 87115",
    "county": "Bernalillo County",
    "state": "NM",
    "city": "Albuquerque"
  },
  {
    "value": "87116",
    "label": "Albuquerque - NM 87116",
    "county": "Bernalillo County",
    "state": "NM",
    "city": "Albuquerque"
  },
  {
    "value": "87120",
    "label": "Albuquerque - NM 87120",
    "county": "Bernalillo County",
    "state": "NM",
    "city": "Albuquerque"
  },
  {
    "value": "87121",
    "label": "Albuquerque - NM 87121",
    "county": "Bernalillo County",
    "state": "NM",
    "city": "Albuquerque"
  },
  {
    "value": "87122",
    "label": "Albuquerque - NM 87122",
    "county": "Bernalillo County",
    "state": "NM",
    "city": "Albuquerque"
  },
  {
    "value": "87123",
    "label": "Albuquerque - NM 87123",
    "county": "Bernalillo County",
    "state": "NM",
    "city": "Albuquerque"
  },
  {
    "value": "87124",
    "label": "Rio Rancho - NM 87124",
    "county": "Sandoval County",
    "state": "NM",
    "city": "Rio Rancho"
  },
  {
    "value": "87144",
    "label": "Rio Rancho - NM 87144",
    "county": "Sandoval County",
    "state": "NM",
    "city": "Rio Rancho"
  },
  {
    "value": "87301",
    "label": "Gallup - NM 87301",
    "county": "McKinley County",
    "state": "NM",
    "city": "Gallup"
  },
  {
    "value": "87312",
    "label": "Continental Divide - NM 87312",
    "county": "McKinley County",
    "state": "NM",
    "city": "Continental Divide"
  },
  {
    "value": "87315",
    "label": "Fence Lake - NM 87315",
    "county": "Cibola County",
    "state": "NM",
    "city": "Fence Lake"
  },
  {
    "value": "87317",
    "label": "Gamerco - NM 87317",
    "county": "McKinley County",
    "state": "NM",
    "city": "Gamerco"
  },
  {
    "value": "87321",
    "label": "Ramah - NM 87321",
    "county": "McKinley County",
    "state": "NM",
    "city": "Ramah"
  },
  {
    "value": "87323",
    "label": "Thoreau - NM 87323",
    "county": "McKinley County",
    "state": "NM",
    "city": "Thoreau"
  },
  {
    "value": "87325",
    "label": "Tohatchi - NM 87325",
    "county": "McKinley County",
    "state": "NM",
    "city": "Tohatchi"
  },
  {
    "value": "87401",
    "label": "Farmington - NM 87401",
    "county": "San Juan County",
    "state": "NM",
    "city": "Farmington"
  },
  {
    "value": "87402",
    "label": "Farmington - NM 87402",
    "county": "San Juan County",
    "state": "NM",
    "city": "Farmington"
  },
  {
    "value": "87410",
    "label": "Aztec - NM 87410",
    "county": "San Juan County",
    "state": "NM",
    "city": "Aztec"
  },
  {
    "value": "87412",
    "label": "Blanco - NM 87412",
    "county": "San Juan County",
    "state": "NM",
    "city": "Blanco"
  },
  {
    "value": "87413",
    "label": "Bloomfield - NM 87413",
    "county": "San Juan County",
    "state": "NM",
    "city": "Bloomfield"
  },
  {
    "value": "87415",
    "label": "Flora Vista - NM 87415",
    "county": "San Juan County",
    "state": "NM",
    "city": "Flora Vista"
  },
  {
    "value": "87416",
    "label": "Fruitland - NM 87416",
    "county": "San Juan County",
    "state": "NM",
    "city": "Fruitland"
  },
  {
    "value": "87417",
    "label": "Kirtland - NM 87417",
    "county": "San Juan County",
    "state": "NM",
    "city": "Kirtland"
  },
  {
    "value": "87418",
    "label": "La Plata - NM 87418",
    "county": "San Juan County",
    "state": "NM",
    "city": "La Plata"
  },
  {
    "value": "87419",
    "label": "Navajo Dam - NM 87419",
    "county": "San Juan County",
    "state": "NM",
    "city": "Navajo Dam"
  },
  {
    "value": "87420",
    "label": "Shiprock - NM 87420",
    "county": "San Juan County",
    "state": "NM",
    "city": "Shiprock"
  },
  {
    "value": "87421",
    "label": "Waterflow - NM 87421",
    "county": "San Juan County",
    "state": "NM",
    "city": "Waterflow"
  },
  {
    "value": "87501",
    "label": "Santa Fe - NM 87501",
    "county": "Santa Fe County",
    "state": "NM",
    "city": "Santa Fe"
  },
  {
    "value": "87505",
    "label": "Santa Fe - NM 87505",
    "county": "Santa Fe County",
    "state": "NM",
    "city": "Santa Fe"
  },
  {
    "value": "87506",
    "label": "Santa Fe - NM 87506",
    "county": "Santa Fe County",
    "state": "NM",
    "city": "Santa Fe"
  },
  {
    "value": "87507",
    "label": "Santa Fe - NM 87507",
    "county": "Santa Fe County",
    "state": "NM",
    "city": "Santa Fe"
  },
  {
    "value": "87508",
    "label": "Santa Fe - NM 87508",
    "county": "Santa Fe County",
    "state": "NM",
    "city": "Santa Fe"
  },
  {
    "value": "87510",
    "label": "Abiquiu - NM 87510",
    "county": "Rio Arriba County",
    "state": "NM",
    "city": "Abiquiu"
  },
  {
    "value": "87516",
    "label": "Canones - NM 87516",
    "county": "Rio Arriba County",
    "state": "NM",
    "city": "Canones"
  },
  {
    "value": "87520",
    "label": "Chama - NM 87520",
    "county": "Rio Arriba County",
    "state": "NM",
    "city": "Chama"
  },
  {
    "value": "87521",
    "label": "Chamisal - NM 87521",
    "county": "Taos County",
    "state": "NM",
    "city": "Chamisal"
  },
  {
    "value": "87522",
    "label": "Chimayo - NM 87522",
    "county": "Rio Arriba County",
    "state": "NM",
    "city": "Chimayo"
  },
  {
    "value": "87528",
    "label": "Dulce - NM 87528",
    "county": "Rio Arriba County",
    "state": "NM",
    "city": "Dulce"
  },
  {
    "value": "87529",
    "label": "El Prado - NM 87529",
    "county": "Taos County",
    "state": "NM",
    "city": "El Prado"
  },
  {
    "value": "87530",
    "label": "El Rito - NM 87530",
    "county": "Rio Arriba County",
    "state": "NM",
    "city": "El Rito"
  },
  {
    "value": "87531",
    "label": "Embudo - NM 87531",
    "county": "Rio Arriba County",
    "state": "NM",
    "city": "Embudo"
  },
  {
    "value": "87532",
    "label": "Espanola - NM 87532",
    "county": "Santa Fe County",
    "state": "NM",
    "city": "Espanola"
  },
  {
    "value": "87535",
    "label": "Glorieta - NM 87535",
    "county": "Santa Fe County",
    "state": "NM",
    "city": "Glorieta"
  },
  {
    "value": "87537",
    "label": "Hernandez - NM 87537",
    "county": "Rio Arriba County",
    "state": "NM",
    "city": "Hernandez"
  },
  {
    "value": "87539",
    "label": "La Madera - NM 87539",
    "county": "Rio Arriba County",
    "state": "NM",
    "city": "La Madera"
  },
  {
    "value": "87540",
    "label": "Lamy - NM 87540",
    "county": "Santa Fe County",
    "state": "NM",
    "city": "Lamy"
  },
  {
    "value": "87544",
    "label": "Los Alamos - NM 87544",
    "county": "Los Alamos County",
    "state": "NM",
    "city": "Los Alamos"
  },
  {
    "value": "87547",
    "label": "White Rock - NM 87547",
    "county": "",
    "state": "NM",
    "city": "White Rock"
  },
  {
    "value": "87549",
    "label": "Ojo Caliente - NM 87549",
    "county": "Rio Arriba County",
    "state": "NM",
    "city": "Ojo Caliente"
  },
  {
    "value": "87551",
    "label": "Los Ojos - NM 87551",
    "county": "Rio Arriba County",
    "state": "NM",
    "city": "Los Ojos"
  },
  {
    "value": "87552",
    "label": "Pecos - NM 87552",
    "county": "San Miguel County",
    "state": "NM",
    "city": "Pecos"
  },
  {
    "value": "87553",
    "label": "Penasco - NM 87553",
    "county": "Taos County",
    "state": "NM",
    "city": "Penasco"
  },
  {
    "value": "87556",
    "label": "Questa - NM 87556",
    "county": "Taos County",
    "state": "NM",
    "city": "Questa"
  },
  {
    "value": "87557",
    "label": "Ranchos De Taos - NM 87557",
    "county": "Taos County",
    "state": "NM",
    "city": "Ranchos De Taos"
  },
  {
    "value": "87560",
    "label": "Ribera - NM 87560",
    "county": "San Miguel County",
    "state": "NM",
    "city": "Ribera"
  },
  {
    "value": "87565",
    "label": "San Jose - NM 87565",
    "county": "San Miguel County",
    "state": "NM",
    "city": "San Jose"
  },
  {
    "value": "87566",
    "label": "Ohkay Owingeh - NM 87566",
    "county": "Rio Arriba County",
    "state": "NM",
    "city": "Ohkay Owingeh"
  },
  {
    "value": "87567",
    "label": "Santa Cruz - NM 87567",
    "county": "Santa Fe County",
    "state": "NM",
    "city": "Santa Cruz"
  },
  {
    "value": "87571",
    "label": "Taos - NM 87571",
    "county": "Taos County",
    "state": "NM",
    "city": "Taos"
  },
  {
    "value": "87573",
    "label": "Tererro - NM 87573",
    "county": "San Miguel County",
    "state": "NM",
    "city": "Tererro"
  },
  {
    "value": "87575",
    "label": "Tierra Amarilla - NM 87575",
    "county": "Rio Arriba County",
    "state": "NM",
    "city": "Tierra Amarilla"
  },
  {
    "value": "87576",
    "label": "Trampas - NM 87576",
    "county": "Taos County",
    "state": "NM",
    "city": "Trampas"
  },
  {
    "value": "87579",
    "label": "Vadito - NM 87579",
    "county": "Taos County",
    "state": "NM",
    "city": "Vadito"
  },
  {
    "value": "87580",
    "label": "Valdez - NM 87580",
    "county": "Taos County",
    "state": "NM",
    "city": "Valdez"
  },
  {
    "value": "87581",
    "label": "Vallecitos - NM 87581",
    "county": "Rio Arriba County",
    "state": "NM",
    "city": "Vallecitos"
  },
  {
    "value": "87701",
    "label": "Las Vegas - NM 87701",
    "county": "San Miguel County",
    "state": "NM",
    "city": "Las Vegas"
  },
  {
    "value": "87711",
    "label": "Anton Chico - NM 87711",
    "county": "Guadalupe County",
    "state": "NM",
    "city": "Anton Chico"
  },
  {
    "value": "87713",
    "label": "Chacon - NM 87713",
    "county": "Mora County",
    "state": "NM",
    "city": "Chacon"
  },
  {
    "value": "87714",
    "label": "Cimarron - NM 87714",
    "county": "Colfax County",
    "state": "NM",
    "city": "Cimarron"
  },
  {
    "value": "87715",
    "label": "Cleveland - NM 87715",
    "county": "Mora County",
    "state": "NM",
    "city": "Cleveland"
  },
  {
    "value": "87718",
    "label": "Eagle Nest - NM 87718",
    "county": "Colfax County",
    "state": "NM",
    "city": "Eagle Nest"
  },
  {
    "value": "87722",
    "label": "Guadalupita - NM 87722",
    "county": "Mora County",
    "state": "NM",
    "city": "Guadalupita"
  },
  {
    "value": "87723",
    "label": "Holman - NM 87723",
    "county": "Mora County",
    "state": "NM",
    "city": "Holman"
  },
  {
    "value": "87724",
    "label": "La Loma - NM 87724",
    "county": "Guadalupe County",
    "state": "NM",
    "city": "La Loma"
  },
  {
    "value": "87728",
    "label": "Maxwell - NM 87728",
    "county": "Colfax County",
    "state": "NM",
    "city": "Maxwell"
  },
  {
    "value": "87729",
    "label": "Miami - NM 87729",
    "county": "Colfax County",
    "state": "NM",
    "city": "Miami"
  },
  {
    "value": "87730",
    "label": "Mills - NM 87730",
    "county": "Harding County",
    "state": "NM",
    "city": "Mills"
  },
  {
    "value": "87731",
    "label": "Montezuma - NM 87731",
    "county": "San Miguel County",
    "state": "NM",
    "city": "Montezuma"
  },
  {
    "value": "87732",
    "label": "Mora - NM 87732",
    "county": "Mora County",
    "state": "NM",
    "city": "Mora"
  },
  {
    "value": "87733",
    "label": "Mosquero - NM 87733",
    "county": "Harding County",
    "state": "NM",
    "city": "Mosquero"
  },
  {
    "value": "87734",
    "label": "Ocate - NM 87734",
    "county": "Mora County",
    "state": "NM",
    "city": "Ocate"
  },
  {
    "value": "87735",
    "label": "Ojo Feliz - NM 87735",
    "county": "Mora County",
    "state": "NM",
    "city": "Ojo Feliz"
  },
  {
    "value": "87740",
    "label": "Raton - NM 87740",
    "county": "Colfax County",
    "state": "NM",
    "city": "Raton"
  },
  {
    "value": "87742",
    "label": "Rociada - NM 87742",
    "county": "San Miguel County",
    "state": "NM",
    "city": "Rociada"
  },
  {
    "value": "87743",
    "label": "Roy - NM 87743",
    "county": "Harding County",
    "state": "NM",
    "city": "Roy"
  },
  {
    "value": "87745",
    "label": "Sapello - NM 87745",
    "county": "San Miguel County",
    "state": "NM",
    "city": "Sapello"
  },
  {
    "value": "87746",
    "label": "Solano - NM 87746",
    "county": "San Miguel County",
    "state": "NM",
    "city": "Solano"
  },
  {
    "value": "87747",
    "label": "Springer - NM 87747",
    "county": "Colfax County",
    "state": "NM",
    "city": "Springer"
  },
  {
    "value": "87750",
    "label": "Valmora - NM 87750",
    "county": "Mora County",
    "state": "NM",
    "city": "Valmora"
  },
  {
    "value": "87752",
    "label": "Wagon Mound - NM 87752",
    "county": "Mora County",
    "state": "NM",
    "city": "Wagon Mound"
  },
  {
    "value": "87801",
    "label": "Socorro - NM 87801",
    "county": "Socorro County",
    "state": "NM",
    "city": "Socorro"
  },
  {
    "value": "87820",
    "label": "Aragon - NM 87820",
    "county": "Catron County",
    "state": "NM",
    "city": "Aragon"
  },
  {
    "value": "87821",
    "label": "Datil - NM 87821",
    "county": "Catron County",
    "state": "NM",
    "city": "Datil"
  },
  {
    "value": "87823",
    "label": "Lemitar - NM 87823",
    "county": "Socorro County",
    "state": "NM",
    "city": "Lemitar"
  },
  {
    "value": "87825",
    "label": "Magdalena - NM 87825",
    "county": "Socorro County",
    "state": "NM",
    "city": "Magdalena"
  },
  {
    "value": "87827",
    "label": "Pie Town - NM 87827",
    "county": "Catron County",
    "state": "NM",
    "city": "Pie Town"
  },
  {
    "value": "87828",
    "label": "Polvadera - NM 87828",
    "county": "Socorro County",
    "state": "NM",
    "city": "Polvadera"
  },
  {
    "value": "87829",
    "label": "Quemado - NM 87829",
    "county": "Catron County",
    "state": "NM",
    "city": "Quemado"
  },
  {
    "value": "87830",
    "label": "Reserve - NM 87830",
    "county": "Catron County",
    "state": "NM",
    "city": "Reserve"
  },
  {
    "value": "87831",
    "label": "San Acacia - NM 87831",
    "county": "Socorro County",
    "state": "NM",
    "city": "San Acacia"
  },
  {
    "value": "87832",
    "label": "San Antonio - NM 87832",
    "county": "Socorro County",
    "state": "NM",
    "city": "San Antonio"
  },
  {
    "value": "87901",
    "label": "Truth Or Consequences - NM 87901",
    "county": "Sierra County",
    "state": "NM",
    "city": "Truth Or Consequences"
  },
  {
    "value": "87930",
    "label": "Arrey - NM 87930",
    "county": "Sierra County",
    "state": "NM",
    "city": "Arrey"
  },
  {
    "value": "87931",
    "label": "Caballo - NM 87931",
    "county": "Sierra County",
    "state": "NM",
    "city": "Caballo"
  },
  {
    "value": "87933",
    "label": "Derry - NM 87933",
    "county": "Sierra County",
    "state": "NM",
    "city": "Derry"
  },
  {
    "value": "87936",
    "label": "Garfield - NM 87936",
    "county": "Dona Ana County",
    "state": "NM",
    "city": "Garfield"
  },
  {
    "value": "87937",
    "label": "Hatch - NM 87937",
    "county": "Dona Ana County",
    "state": "NM",
    "city": "Hatch"
  },
  {
    "value": "87939",
    "label": "Monticello - NM 87939",
    "county": "Sierra County",
    "state": "NM",
    "city": "Monticello"
  },
  {
    "value": "87940",
    "label": "Rincon - NM 87940",
    "county": "Dona Ana County",
    "state": "NM",
    "city": "Rincon"
  },
  {
    "value": "87941",
    "label": "Salem - NM 87941",
    "county": "Dona Ana County",
    "state": "NM",
    "city": "Salem"
  },
  {
    "value": "87942",
    "label": "Williamsburg - NM 87942",
    "county": "Sierra County",
    "state": "NM",
    "city": "Williamsburg"
  },
  {
    "value": "87943",
    "label": "Winston - NM 87943",
    "county": "Sierra County",
    "state": "NM",
    "city": "Winston"
  },
  {
    "value": "88001",
    "label": "Las Cruces - NM 88001",
    "county": "Dona Ana County",
    "state": "NM",
    "city": "Las Cruces"
  },
  {
    "value": "88002",
    "label": "White Sands Missile Range - NM 88002",
    "county": "Dona Ana County",
    "state": "NM",
    "city": "White Sands Missile Range"
  },
  {
    "value": "88005",
    "label": "Las Cruces - NM 88005",
    "county": "Dona Ana County",
    "state": "NM",
    "city": "Las Cruces"
  },
  {
    "value": "88007",
    "label": "Las Cruces - NM 88007",
    "county": "Dona Ana County",
    "state": "NM",
    "city": "Las Cruces"
  },
  {
    "value": "88008",
    "label": "Santa Teresa - NM 88008",
    "county": "Dona Ana County",
    "state": "NM",
    "city": "Santa Teresa"
  },
  {
    "value": "88011",
    "label": "Las Cruces - NM 88011",
    "county": "Dona Ana County",
    "state": "NM",
    "city": "Las Cruces"
  },
  {
    "value": "88012",
    "label": "Las Cruces - NM 88012",
    "county": "Dona Ana County",
    "state": "NM",
    "city": "Las Cruces"
  },
  {
    "value": "88020",
    "label": "Animas - NM 88020",
    "county": "Hidalgo County",
    "state": "NM",
    "city": "Animas"
  },
  {
    "value": "88021",
    "label": "Anthony - NM 88021",
    "county": "Dona Ana County",
    "state": "NM",
    "city": "Anthony"
  },
  {
    "value": "88022",
    "label": "Arenas Valley - NM 88022",
    "county": "Grant County",
    "state": "NM",
    "city": "Arenas Valley"
  },
  {
    "value": "88023",
    "label": "Bayard - NM 88023",
    "county": "Grant County",
    "state": "NM",
    "city": "Bayard"
  },
  {
    "value": "88025",
    "label": "Buckhorn - NM 88025",
    "county": "Grant County",
    "state": "NM",
    "city": "Buckhorn"
  },
  {
    "value": "88030",
    "label": "Deming - NM 88030",
    "county": "Luna County",
    "state": "NM",
    "city": "Deming"
  },
  {
    "value": "88034",
    "label": "Faywood - NM 88034",
    "county": "Grant County",
    "state": "NM",
    "city": "Faywood"
  },
  {
    "value": "88039",
    "label": "Glenwood - NM 88039",
    "county": "Catron County",
    "state": "NM",
    "city": "Glenwood"
  },
  {
    "value": "88041",
    "label": "Hanover - NM 88041",
    "county": "Grant County",
    "state": "NM",
    "city": "Hanover"
  },
  {
    "value": "88042",
    "label": "Hillsboro - NM 88042",
    "county": "Sierra County",
    "state": "NM",
    "city": "Hillsboro"
  },
  {
    "value": "88043",
    "label": "Hurley - NM 88043",
    "county": "Grant County",
    "state": "NM",
    "city": "Hurley"
  },
  {
    "value": "88044",
    "label": "La Mesa - NM 88044",
    "county": "Dona Ana County",
    "state": "NM",
    "city": "La Mesa"
  },
  {
    "value": "88045",
    "label": "Lordsburg - NM 88045",
    "county": "Hidalgo County",
    "state": "NM",
    "city": "Lordsburg"
  },
  {
    "value": "88047",
    "label": "Mesilla Park - NM 88047",
    "county": "Dona Ana County",
    "state": "NM",
    "city": "Mesilla Park"
  },
  {
    "value": "88048",
    "label": "Mesquite - NM 88048",
    "county": "Dona Ana County",
    "state": "NM",
    "city": "Mesquite"
  },
  {
    "value": "88049",
    "label": "Mimbres - NM 88049",
    "county": "Grant County",
    "state": "NM",
    "city": "Mimbres"
  },
  {
    "value": "88061",
    "label": "Silver City - NM 88061",
    "county": "Grant County",
    "state": "NM",
    "city": "Silver City"
  },
  {
    "value": "88063",
    "label": "Sunland Park - NM 88063",
    "county": "Dona Ana County",
    "state": "NM",
    "city": "Sunland Park"
  },
  {
    "value": "88072",
    "label": "Vado - NM 88072",
    "county": "Dona Ana County",
    "state": "NM",
    "city": "Vado"
  },
  {
    "value": "88081",
    "label": "Chaparral - NM 88081",
    "county": "Otero County",
    "state": "NM",
    "city": "Chaparral"
  },
  {
    "value": "88101",
    "label": "Clovis - NM 88101",
    "county": "Curry County",
    "state": "NM",
    "city": "Clovis"
  },
  {
    "value": "88112",
    "label": "Broadview - NM 88112",
    "county": "Curry County",
    "state": "NM",
    "city": "Broadview"
  },
  {
    "value": "88113",
    "label": "Causey - NM 88113",
    "county": "Roosevelt County",
    "state": "NM",
    "city": "Causey"
  },
  {
    "value": "88114",
    "label": "Crossroads - NM 88114",
    "county": "Lea County",
    "state": "NM",
    "city": "Crossroads"
  },
  {
    "value": "88116",
    "label": "Elida - NM 88116",
    "county": "Roosevelt County",
    "state": "NM",
    "city": "Elida"
  },
  {
    "value": "88118",
    "label": "Floyd - NM 88118",
    "county": "Roosevelt County",
    "state": "NM",
    "city": "Floyd"
  },
  {
    "value": "88119",
    "label": "Fort Sumner - NM 88119",
    "county": "De Baca County",
    "state": "NM",
    "city": "Fort Sumner"
  },
  {
    "value": "88120",
    "label": "Grady - NM 88120",
    "county": "Curry County",
    "state": "NM",
    "city": "Grady"
  },
  {
    "value": "88121",
    "label": "House - NM 88121",
    "county": "Quay County",
    "state": "NM",
    "city": "House"
  },
  {
    "value": "88123",
    "label": "Lingo - NM 88123",
    "county": "Roosevelt County",
    "state": "NM",
    "city": "Lingo"
  },
  {
    "value": "88124",
    "label": "Melrose - NM 88124",
    "county": "Curry County",
    "state": "NM",
    "city": "Melrose"
  },
  {
    "value": "88125",
    "label": "Milnesand - NM 88125",
    "county": "Roosevelt County",
    "state": "NM",
    "city": "Milnesand"
  },
  {
    "value": "88126",
    "label": "Pep - NM 88126",
    "county": "Roosevelt County",
    "state": "NM",
    "city": "Pep"
  },
  {
    "value": "88130",
    "label": "Portales - NM 88130",
    "county": "Roosevelt County",
    "state": "NM",
    "city": "Portales"
  },
  {
    "value": "88132",
    "label": "Rogers - NM 88132",
    "county": "Roosevelt County",
    "state": "NM",
    "city": "Rogers"
  },
  {
    "value": "88133",
    "label": "Saint Vrain - NM 88133",
    "county": "Curry County",
    "state": "NM",
    "city": "Saint Vrain"
  },
  {
    "value": "88134",
    "label": "Taiban - NM 88134",
    "county": "De Baca County",
    "state": "NM",
    "city": "Taiban"
  },
  {
    "value": "88135",
    "label": "Texico - NM 88135",
    "county": "Curry County",
    "state": "NM",
    "city": "Texico"
  },
  {
    "value": "88136",
    "label": "Yeso - NM 88136",
    "county": "De Baca County",
    "state": "NM",
    "city": "Yeso"
  },
  {
    "value": "88201",
    "label": "Roswell - NM 88201",
    "county": "Chaves County",
    "state": "NM",
    "city": "Roswell"
  },
  {
    "value": "88203",
    "label": "Roswell - NM 88203",
    "county": "Chaves County",
    "state": "NM",
    "city": "Roswell"
  },
  {
    "value": "88210",
    "label": "Artesia - NM 88210",
    "county": "Eddy County",
    "state": "NM",
    "city": "Artesia"
  },
  {
    "value": "88213",
    "label": "Caprock - NM 88213",
    "county": "Lea County",
    "state": "NM",
    "city": "Caprock"
  },
  {
    "value": "88220",
    "label": "Carlsbad - NM 88220",
    "county": "Eddy County",
    "state": "NM",
    "city": "Carlsbad"
  },
  {
    "value": "88230",
    "label": "Dexter - NM 88230",
    "county": "Chaves County",
    "state": "NM",
    "city": "Dexter"
  },
  {
    "value": "88232",
    "label": "Hagerman - NM 88232",
    "county": "Chaves County",
    "state": "NM",
    "city": "Hagerman"
  },
  {
    "value": "88240",
    "label": "Hobbs - NM 88240",
    "county": "Lea County",
    "state": "NM",
    "city": "Hobbs"
  },
  {
    "value": "88242",
    "label": "Hobbs - NM 88242",
    "county": "Lea County",
    "state": "NM",
    "city": "Hobbs"
  },
  {
    "value": "88250",
    "label": "Hope - NM 88250",
    "county": "Eddy County",
    "state": "NM",
    "city": "Hope"
  },
  {
    "value": "88252",
    "label": "Jal - NM 88252",
    "county": "Lea County",
    "state": "NM",
    "city": "Jal"
  },
  {
    "value": "88253",
    "label": "Lake Arthur - NM 88253",
    "county": "Chaves County",
    "state": "NM",
    "city": "Lake Arthur"
  },
  {
    "value": "88256",
    "label": "Loving - NM 88256",
    "county": "Eddy County",
    "state": "NM",
    "city": "Loving"
  },
  {
    "value": "88260",
    "label": "Lovington - NM 88260",
    "county": "Lea County",
    "state": "NM",
    "city": "Lovington"
  },
  {
    "value": "88264",
    "label": "Maljamar - NM 88264",
    "county": "Lea County",
    "state": "NM",
    "city": "Maljamar"
  },
  {
    "value": "88265",
    "label": "Monument - NM 88265",
    "county": "Lea County",
    "state": "NM",
    "city": "Monument"
  },
  {
    "value": "88267",
    "label": "Tatum - NM 88267",
    "county": "Lea County",
    "state": "NM",
    "city": "Tatum"
  },
  {
    "value": "88301",
    "label": "Carrizozo - NM 88301",
    "county": "Lincoln County",
    "state": "NM",
    "city": "Carrizozo"
  },
  {
    "value": "88310",
    "label": "Alamogordo - NM 88310",
    "county": "Otero County",
    "state": "NM",
    "city": "Alamogordo"
  },
  {
    "value": "88312",
    "label": "Alto - NM 88312",
    "county": "Lincoln County",
    "state": "NM",
    "city": "Alto"
  },
  {
    "value": "88314",
    "label": "Bent - NM 88314",
    "county": "Otero County",
    "state": "NM",
    "city": "Bent"
  },
  {
    "value": "88316",
    "label": "Capitan - NM 88316",
    "county": "Lincoln County",
    "state": "NM",
    "city": "Capitan"
  },
  {
    "value": "88317",
    "label": "Cloudcroft - NM 88317",
    "county": "Otero County",
    "state": "NM",
    "city": "Cloudcroft"
  },
  {
    "value": "88318",
    "label": "Corona - NM 88318",
    "county": "Lincoln County",
    "state": "NM",
    "city": "Corona"
  },
  {
    "value": "88321",
    "label": "Encino - NM 88321",
    "county": "Torrance County",
    "state": "NM",
    "city": "Encino"
  },
  {
    "value": "88324",
    "label": "Glencoe - NM 88324",
    "county": "Lincoln County",
    "state": "NM",
    "city": "Glencoe"
  },
  {
    "value": "88330",
    "label": "Holloman Air Force Base - NM 88330",
    "county": "Otero County",
    "state": "NM",
    "city": "Holloman Air Force Base"
  },
  {
    "value": "88336",
    "label": "Hondo - NM 88336",
    "county": "Lincoln County",
    "state": "NM",
    "city": "Hondo"
  },
  {
    "value": "88337",
    "label": "La Luz - NM 88337",
    "county": "Otero County",
    "state": "NM",
    "city": "La Luz"
  },
  {
    "value": "88339",
    "label": "Mayhill - NM 88339",
    "county": "Otero County",
    "state": "NM",
    "city": "Mayhill"
  },
  {
    "value": "88340",
    "label": "Mescalero - NM 88340",
    "county": "Otero County",
    "state": "NM",
    "city": "Mescalero"
  },
  {
    "value": "88341",
    "label": "Nogal - NM 88341",
    "county": "Lincoln County",
    "state": "NM",
    "city": "Nogal"
  },
  {
    "value": "88344",
    "label": "Pinon - NM 88344",
    "county": "Otero County",
    "state": "NM",
    "city": "Pinon"
  },
  {
    "value": "88345",
    "label": "Ruidoso - NM 88345",
    "county": "Lincoln County",
    "state": "NM",
    "city": "Ruidoso"
  },
  {
    "value": "88346",
    "label": "Ruidoso Downs - NM 88346",
    "county": "Lincoln County",
    "state": "NM",
    "city": "Ruidoso Downs"
  },
  {
    "value": "88347",
    "label": "Sacramento - NM 88347",
    "county": "Otero County",
    "state": "NM",
    "city": "Sacramento"
  },
  {
    "value": "88348",
    "label": "San Patricio - NM 88348",
    "county": "Lincoln County",
    "state": "NM",
    "city": "San Patricio"
  },
  {
    "value": "88351",
    "label": "Tinnie - NM 88351",
    "county": "Lincoln County",
    "state": "NM",
    "city": "Tinnie"
  },
  {
    "value": "88352",
    "label": "Tularosa - NM 88352",
    "county": "Otero County",
    "state": "NM",
    "city": "Tularosa"
  },
  {
    "value": "88354",
    "label": "Weed - NM 88354",
    "county": "Otero County",
    "state": "NM",
    "city": "Weed"
  },
  {
    "value": "88401",
    "label": "Tucumcari - NM 88401",
    "county": "Quay County",
    "state": "NM",
    "city": "Tucumcari"
  },
  {
    "value": "88410",
    "label": "Amistad - NM 88410",
    "county": "Union County",
    "state": "NM",
    "city": "Amistad"
  },
  {
    "value": "88411",
    "label": "Bard - NM 88411",
    "county": "Quay County",
    "state": "NM",
    "city": "Bard"
  },
  {
    "value": "88414",
    "label": "Capulin - NM 88414",
    "county": "Union County",
    "state": "NM",
    "city": "Capulin"
  },
  {
    "value": "88415",
    "label": "Clayton - NM 88415",
    "county": "Union County",
    "state": "NM",
    "city": "Clayton"
  },
  {
    "value": "88417",
    "label": "Cuervo - NM 88417",
    "county": "Guadalupe County",
    "state": "NM",
    "city": "Cuervo"
  },
  {
    "value": "88418",
    "label": "Des Moines - NM 88418",
    "county": "Union County",
    "state": "NM",
    "city": "Des Moines"
  },
  {
    "value": "88419",
    "label": "Folsom - NM 88419",
    "county": "Union County",
    "state": "NM",
    "city": "Folsom"
  },
  {
    "value": "88421",
    "label": "Garita - NM 88421",
    "county": "San Miguel County",
    "state": "NM",
    "city": "Garita"
  },
  {
    "value": "88422",
    "label": "Gladstone - NM 88422",
    "county": "Union County",
    "state": "NM",
    "city": "Gladstone"
  },
  {
    "value": "88424",
    "label": "Grenville - NM 88424",
    "county": "Union County",
    "state": "NM",
    "city": "Grenville"
  },
  {
    "value": "88426",
    "label": "Logan - NM 88426",
    "county": "Quay County",
    "state": "NM",
    "city": "Logan"
  },
  {
    "value": "88427",
    "label": "Mcalister - NM 88427",
    "county": "Quay County",
    "state": "NM",
    "city": "Mcalister"
  },
  {
    "value": "88430",
    "label": "Nara Visa - NM 88430",
    "county": "Quay County",
    "state": "NM",
    "city": "Nara Visa"
  },
  {
    "value": "88431",
    "label": "Newkirk - NM 88431",
    "county": "Guadalupe County",
    "state": "NM",
    "city": "Newkirk"
  },
  {
    "value": "88433",
    "label": "Quay - NM 88433",
    "county": "Quay County",
    "state": "NM",
    "city": "Quay"
  },
  {
    "value": "88434",
    "label": "San Jon - NM 88434",
    "county": "Quay County",
    "state": "NM",
    "city": "San Jon"
  },
  {
    "value": "88435",
    "label": "Santa Rosa - NM 88435",
    "county": "Guadalupe County",
    "state": "NM",
    "city": "Santa Rosa"
  },
  {
    "value": "88436",
    "label": "Sedan - NM 88436",
    "county": "Union County",
    "state": "NM",
    "city": "Sedan"
  },
  {
    "value": "88439",
    "label": "Trementina - NM 88439",
    "county": "San Miguel County",
    "state": "NM",
    "city": "Trementina"
  },
  {
    "value": "10001",
    "label": "New York - NY 10001",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10002",
    "label": "New York - NY 10002",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10003",
    "label": "New York - NY 10003",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10004",
    "label": "New York - NY 10004",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10005",
    "label": "New York - NY 10005",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10006",
    "label": "New York - NY 10006",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10007",
    "label": "New York - NY 10007",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10009",
    "label": "New York - NY 10009",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10010",
    "label": "New York - NY 10010",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10011",
    "label": "New York - NY 10011",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10012",
    "label": "New York - NY 10012",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10013",
    "label": "New York - NY 10013",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10014",
    "label": "New York - NY 10014",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10015",
    "label": "New York - NY 10015",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10016",
    "label": "New York - NY 10016",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10017",
    "label": "New York - NY 10017",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10018",
    "label": "New York - NY 10018",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10019",
    "label": "New York - NY 10019",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10020",
    "label": "New York - NY 10020",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10021",
    "label": "New York - NY 10021",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10022",
    "label": "New York - NY 10022",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10023",
    "label": "New York - NY 10023",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10024",
    "label": "New York - NY 10024",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10025",
    "label": "New York - NY 10025",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10026",
    "label": "New York - NY 10026",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10027",
    "label": "New York - NY 10027",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10028",
    "label": "New York - NY 10028",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10029",
    "label": "New York - NY 10029",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10030",
    "label": "New York - NY 10030",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10031",
    "label": "New York - NY 10031",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10032",
    "label": "New York - NY 10032",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10033",
    "label": "New York - NY 10033",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10034",
    "label": "New York - NY 10034",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10035",
    "label": "New York - NY 10035",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10036",
    "label": "New York - NY 10036",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10037",
    "label": "New York - NY 10037",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10038",
    "label": "New York - NY 10038",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10039",
    "label": "New York - NY 10039",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10040",
    "label": "New York - NY 10040",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10041",
    "label": "New York - NY 10041",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10044",
    "label": "New York - NY 10044",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10045",
    "label": "New York - NY 10045",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10048",
    "label": "New York - NY 10048",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10055",
    "label": "New York - NY 10055",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10060",
    "label": "New York - NY 10060",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10065",
    "label": "New York - NY 10065",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10069",
    "label": "New York - NY 10069",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10075",
    "label": "New York - NY 10075",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10090",
    "label": "New York - NY 10090",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10095",
    "label": "New York - NY 10095",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10098",
    "label": "New York - NY 10098",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10099",
    "label": "New York - NY 10099",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10103",
    "label": "New York - NY 10103",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10104",
    "label": "New York - NY 10104",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10105",
    "label": "New York - NY 10105",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10106",
    "label": "New York - NY 10106",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10107",
    "label": "New York - NY 10107",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10110",
    "label": "New York - NY 10110",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10111",
    "label": "New York - NY 10111",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10112",
    "label": "New York - NY 10112",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10115",
    "label": "New York - NY 10115",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10118",
    "label": "New York - NY 10118",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10119",
    "label": "New York - NY 10119",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10120",
    "label": "New York - NY 10120",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10121",
    "label": "New York - NY 10121",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10122",
    "label": "New York - NY 10122",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10123",
    "label": "New York - NY 10123",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10128",
    "label": "New York - NY 10128",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10151",
    "label": "New York - NY 10151",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10152",
    "label": "New York - NY 10152",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10153",
    "label": "New York - NY 10153",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10154",
    "label": "New York - NY 10154",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10155",
    "label": "New York - NY 10155",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10158",
    "label": "New York - NY 10158",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10161",
    "label": "New York - NY 10161",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10162",
    "label": "New York - NY 10162",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10165",
    "label": "New York - NY 10165",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10166",
    "label": "New York - NY 10166",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10167",
    "label": "New York - NY 10167",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10168",
    "label": "New York - NY 10168",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10169",
    "label": "New York - NY 10169",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10170",
    "label": "New York - NY 10170",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10171",
    "label": "New York - NY 10171",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10172",
    "label": "New York - NY 10172",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10173",
    "label": "New York - NY 10173",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10174",
    "label": "New York - NY 10174",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10175",
    "label": "New York - NY 10175",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10176",
    "label": "New York - NY 10176",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10177",
    "label": "New York - NY 10177",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10178",
    "label": "New York - NY 10178",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10199",
    "label": "New York - NY 10199",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10200",
    "label": "New York - NY 10200",
    "county": "",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10260",
    "label": "New York - NY 10260",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10265",
    "label": "New York - NY 10265",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10270",
    "label": "New York - NY 10270",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10271",
    "label": "New York - NY 10271",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10278",
    "label": "New York - NY 10278",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10279",
    "label": "New York - NY 10279",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10280",
    "label": "New York - NY 10280",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10281",
    "label": "New York - NY 10281",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10282",
    "label": "New York - NY 10282",
    "county": "New York County",
    "state": "NY",
    "city": "New York"
  },
  {
    "value": "10301",
    "label": "Staten Island - NY 10301",
    "county": "Richmond County",
    "state": "NY",
    "city": "Staten Island"
  },
  {
    "value": "10302",
    "label": "Staten Island - NY 10302",
    "county": "Richmond County",
    "state": "NY",
    "city": "Staten Island"
  },
  {
    "value": "10303",
    "label": "Staten Island - NY 10303",
    "county": "Richmond County",
    "state": "NY",
    "city": "Staten Island"
  },
  {
    "value": "10304",
    "label": "Staten Island - NY 10304",
    "county": "Richmond County",
    "state": "NY",
    "city": "Staten Island"
  },
  {
    "value": "10305",
    "label": "Staten Island - NY 10305",
    "county": "Richmond County",
    "state": "NY",
    "city": "Staten Island"
  },
  {
    "value": "10306",
    "label": "Staten Island - NY 10306",
    "county": "Richmond County",
    "state": "NY",
    "city": "Staten Island"
  },
  {
    "value": "10307",
    "label": "Staten Island - NY 10307",
    "county": "Richmond County",
    "state": "NY",
    "city": "Staten Island"
  },
  {
    "value": "10308",
    "label": "Staten Island - NY 10308",
    "county": "Richmond County",
    "state": "NY",
    "city": "Staten Island"
  },
  {
    "value": "10309",
    "label": "Staten Island - NY 10309",
    "county": "Richmond County",
    "state": "NY",
    "city": "Staten Island"
  },
  {
    "value": "10310",
    "label": "Staten Island - NY 10310",
    "county": "Richmond County",
    "state": "NY",
    "city": "Staten Island"
  },
  {
    "value": "10311",
    "label": "Staten Island - NY 10311",
    "county": "Richmond County",
    "state": "NY",
    "city": "Staten Island"
  },
  {
    "value": "10312",
    "label": "Staten Island - NY 10312",
    "county": "Richmond County",
    "state": "NY",
    "city": "Staten Island"
  },
  {
    "value": "10314",
    "label": "Staten Island - NY 10314",
    "county": "Richmond County",
    "state": "NY",
    "city": "Staten Island"
  },
  {
    "value": "10451",
    "label": "Bronx - NY 10451",
    "county": "Bronx County",
    "state": "NY",
    "city": "Bronx"
  },
  {
    "value": "10452",
    "label": "Bronx - NY 10452",
    "county": "Bronx County",
    "state": "NY",
    "city": "Bronx"
  },
  {
    "value": "10453",
    "label": "Bronx - NY 10453",
    "county": "Bronx County",
    "state": "NY",
    "city": "Bronx"
  },
  {
    "value": "10454",
    "label": "Bronx - NY 10454",
    "county": "Bronx County",
    "state": "NY",
    "city": "Bronx"
  },
  {
    "value": "10455",
    "label": "Bronx - NY 10455",
    "county": "Bronx County",
    "state": "NY",
    "city": "Bronx"
  },
  {
    "value": "10456",
    "label": "Bronx - NY 10456",
    "county": "Bronx County",
    "state": "NY",
    "city": "Bronx"
  },
  {
    "value": "10457",
    "label": "Bronx - NY 10457",
    "county": "Bronx County",
    "state": "NY",
    "city": "Bronx"
  },
  {
    "value": "10458",
    "label": "Bronx - NY 10458",
    "county": "Bronx County",
    "state": "NY",
    "city": "Bronx"
  },
  {
    "value": "10459",
    "label": "Bronx - NY 10459",
    "county": "Bronx County",
    "state": "NY",
    "city": "Bronx"
  },
  {
    "value": "10460",
    "label": "Bronx - NY 10460",
    "county": "Bronx County",
    "state": "NY",
    "city": "Bronx"
  },
  {
    "value": "10461",
    "label": "Bronx - NY 10461",
    "county": "Bronx County",
    "state": "NY",
    "city": "Bronx"
  },
  {
    "value": "10462",
    "label": "Bronx - NY 10462",
    "county": "Bronx County",
    "state": "NY",
    "city": "Bronx"
  },
  {
    "value": "10463",
    "label": "Bronx - NY 10463",
    "county": "Bronx County",
    "state": "NY",
    "city": "Bronx"
  },
  {
    "value": "10464",
    "label": "Bronx - NY 10464",
    "county": "Bronx County",
    "state": "NY",
    "city": "Bronx"
  },
  {
    "value": "10465",
    "label": "Bronx - NY 10465",
    "county": "Bronx County",
    "state": "NY",
    "city": "Bronx"
  },
  {
    "value": "10466",
    "label": "Bronx - NY 10466",
    "county": "Bronx County",
    "state": "NY",
    "city": "Bronx"
  },
  {
    "value": "10467",
    "label": "Bronx - NY 10467",
    "county": "Bronx County",
    "state": "NY",
    "city": "Bronx"
  },
  {
    "value": "10468",
    "label": "Bronx - NY 10468",
    "county": "Bronx County",
    "state": "NY",
    "city": "Bronx"
  },
  {
    "value": "10469",
    "label": "Bronx - NY 10469",
    "county": "Bronx County",
    "state": "NY",
    "city": "Bronx"
  },
  {
    "value": "10470",
    "label": "Bronx - NY 10470",
    "county": "Bronx County",
    "state": "NY",
    "city": "Bronx"
  },
  {
    "value": "10471",
    "label": "Bronx - NY 10471",
    "county": "Bronx County",
    "state": "NY",
    "city": "Bronx"
  },
  {
    "value": "10472",
    "label": "Bronx - NY 10472",
    "county": "Bronx County",
    "state": "NY",
    "city": "Bronx"
  },
  {
    "value": "10473",
    "label": "Bronx - NY 10473",
    "county": "Bronx County",
    "state": "NY",
    "city": "Bronx"
  },
  {
    "value": "10474",
    "label": "Bronx - NY 10474",
    "county": "Bronx County",
    "state": "NY",
    "city": "Bronx"
  },
  {
    "value": "10475",
    "label": "Bronx - NY 10475",
    "county": "Bronx County",
    "state": "NY",
    "city": "Bronx"
  },
  {
    "value": "10501",
    "label": "Amawalk - NY 10501",
    "county": "Westchester County",
    "state": "NY",
    "city": "Amawalk"
  },
  {
    "value": "10502",
    "label": "Ardsley - NY 10502",
    "county": "Westchester County",
    "state": "NY",
    "city": "Ardsley"
  },
  {
    "value": "10504",
    "label": "Armonk - NY 10504",
    "county": "Westchester County",
    "state": "NY",
    "city": "Armonk"
  },
  {
    "value": "10505",
    "label": "Baldwin Place - NY 10505",
    "county": "Westchester County",
    "state": "NY",
    "city": "Baldwin Place"
  },
  {
    "value": "10506",
    "label": "Bedford - NY 10506",
    "county": "Westchester County",
    "state": "NY",
    "city": "Bedford"
  },
  {
    "value": "10507",
    "label": "Bedford Hills - NY 10507",
    "county": "Westchester County",
    "state": "NY",
    "city": "Bedford Hills"
  },
  {
    "value": "10509",
    "label": "Brewster - NY 10509",
    "county": "Putnam County",
    "state": "NY",
    "city": "Brewster"
  },
  {
    "value": "10510",
    "label": "Briarcliff Manor - NY 10510",
    "county": "Westchester County",
    "state": "NY",
    "city": "Briarcliff Manor"
  },
  {
    "value": "10511",
    "label": "Buchanan - NY 10511",
    "county": "Westchester County",
    "state": "NY",
    "city": "Buchanan"
  },
  {
    "value": "10512",
    "label": "Carmel - NY 10512",
    "county": "Putnam County",
    "state": "NY",
    "city": "Carmel"
  },
  {
    "value": "10514",
    "label": "Chappaqua - NY 10514",
    "county": "Westchester County",
    "state": "NY",
    "city": "Chappaqua"
  },
  {
    "value": "10516",
    "label": "Cold Spring - NY 10516",
    "county": "Putnam County",
    "state": "NY",
    "city": "Cold Spring"
  },
  {
    "value": "10518",
    "label": "Cross River - NY 10518",
    "county": "Westchester County",
    "state": "NY",
    "city": "Cross River"
  },
  {
    "value": "10520",
    "label": "Croton On Hudson - NY 10520",
    "county": "Westchester County",
    "state": "NY",
    "city": "Croton On Hudson"
  },
  {
    "value": "10522",
    "label": "Dobbs Ferry - NY 10522",
    "county": "Westchester County",
    "state": "NY",
    "city": "Dobbs Ferry"
  },
  {
    "value": "10523",
    "label": "Elmsford - NY 10523",
    "county": "Westchester County",
    "state": "NY",
    "city": "Elmsford"
  },
  {
    "value": "10524",
    "label": "Garrison - NY 10524",
    "county": "Putnam County",
    "state": "NY",
    "city": "Garrison"
  },
  {
    "value": "10526",
    "label": "Goldens Bridge - NY 10526",
    "county": "Westchester County",
    "state": "NY",
    "city": "Goldens Bridge"
  },
  {
    "value": "10527",
    "label": "Granite Springs - NY 10527",
    "county": "Westchester County",
    "state": "NY",
    "city": "Granite Springs"
  },
  {
    "value": "10528",
    "label": "Harrison - NY 10528",
    "county": "Westchester County",
    "state": "NY",
    "city": "Harrison"
  },
  {
    "value": "10530",
    "label": "Hartsdale - NY 10530",
    "county": "Westchester County",
    "state": "NY",
    "city": "Hartsdale"
  },
  {
    "value": "10532",
    "label": "Hawthorne - NY 10532",
    "county": "Westchester County",
    "state": "NY",
    "city": "Hawthorne"
  },
  {
    "value": "10533",
    "label": "Irvington - NY 10533",
    "county": "Westchester County",
    "state": "NY",
    "city": "Irvington"
  },
  {
    "value": "10535",
    "label": "Jefferson Valley - NY 10535",
    "county": "Westchester County",
    "state": "NY",
    "city": "Jefferson Valley"
  },
  {
    "value": "10536",
    "label": "Katonah - NY 10536",
    "county": "Westchester County",
    "state": "NY",
    "city": "Katonah"
  },
  {
    "value": "10537",
    "label": "Lake Peekskill - NY 10537",
    "county": "Putnam County",
    "state": "NY",
    "city": "Lake Peekskill"
  },
  {
    "value": "10538",
    "label": "Larchmont - NY 10538",
    "county": "Westchester County",
    "state": "NY",
    "city": "Larchmont"
  },
  {
    "value": "10541",
    "label": "Mahopac - NY 10541",
    "county": "Putnam County",
    "state": "NY",
    "city": "Mahopac"
  },
  {
    "value": "10543",
    "label": "Mamaroneck - NY 10543",
    "county": "Westchester County",
    "state": "NY",
    "city": "Mamaroneck"
  },
  {
    "value": "10546",
    "label": "Millwood - NY 10546",
    "county": "Westchester County",
    "state": "NY",
    "city": "Millwood"
  },
  {
    "value": "10547",
    "label": "Mohegan Lake - NY 10547",
    "county": "Westchester County",
    "state": "NY",
    "city": "Mohegan Lake"
  },
  {
    "value": "10548",
    "label": "Montrose - NY 10548",
    "county": "Westchester County",
    "state": "NY",
    "city": "Montrose"
  },
  {
    "value": "10549",
    "label": "Mount Kisco - NY 10549",
    "county": "Westchester County",
    "state": "NY",
    "city": "Mount Kisco"
  },
  {
    "value": "10550",
    "label": "Mount Vernon - NY 10550",
    "county": "Westchester County",
    "state": "NY",
    "city": "Mount Vernon"
  },
  {
    "value": "10552",
    "label": "Mount Vernon - NY 10552",
    "county": "Westchester County",
    "state": "NY",
    "city": "Mount Vernon"
  },
  {
    "value": "10553",
    "label": "Mount Vernon - NY 10553",
    "county": "Westchester County",
    "state": "NY",
    "city": "Mount Vernon"
  },
  {
    "value": "10560",
    "label": "North Salem - NY 10560",
    "county": "Westchester County",
    "state": "NY",
    "city": "North Salem"
  },
  {
    "value": "10562",
    "label": "Ossining - NY 10562",
    "county": "Westchester County",
    "state": "NY",
    "city": "Ossining"
  },
  {
    "value": "10566",
    "label": "Peekskill - NY 10566",
    "county": "Westchester County",
    "state": "NY",
    "city": "Peekskill"
  },
  {
    "value": "10567",
    "label": "Cortlandt Manor - NY 10567",
    "county": "Westchester County",
    "state": "NY",
    "city": "Cortlandt Manor"
  },
  {
    "value": "10570",
    "label": "Pleasantville - NY 10570",
    "county": "Westchester County",
    "state": "NY",
    "city": "Pleasantville"
  },
  {
    "value": "10573",
    "label": "Port Chester - NY 10573",
    "county": "Westchester County",
    "state": "NY",
    "city": "Port Chester"
  },
  {
    "value": "10576",
    "label": "Pound Ridge - NY 10576",
    "county": "Westchester County",
    "state": "NY",
    "city": "Pound Ridge"
  },
  {
    "value": "10577",
    "label": "Purchase - NY 10577",
    "county": "Westchester County",
    "state": "NY",
    "city": "Purchase"
  },
  {
    "value": "10578",
    "label": "Purdys - NY 10578",
    "county": "Westchester County",
    "state": "NY",
    "city": "Purdys"
  },
  {
    "value": "10579",
    "label": "Putnam Valley - NY 10579",
    "county": "Putnam County",
    "state": "NY",
    "city": "Putnam Valley"
  },
  {
    "value": "10580",
    "label": "Rye - NY 10580",
    "county": "Westchester County",
    "state": "NY",
    "city": "Rye"
  },
  {
    "value": "10583",
    "label": "Scarsdale - NY 10583",
    "county": "Westchester County",
    "state": "NY",
    "city": "Scarsdale"
  },
  {
    "value": "10588",
    "label": "Shrub Oak - NY 10588",
    "county": "Westchester County",
    "state": "NY",
    "city": "Shrub Oak"
  },
  {
    "value": "10589",
    "label": "Somers - NY 10589",
    "county": "Westchester County",
    "state": "NY",
    "city": "Somers"
  },
  {
    "value": "10590",
    "label": "South Salem - NY 10590",
    "county": "Westchester County",
    "state": "NY",
    "city": "South Salem"
  },
  {
    "value": "10591",
    "label": "Tarrytown - NY 10591",
    "county": "Westchester County",
    "state": "NY",
    "city": "Tarrytown"
  },
  {
    "value": "10594",
    "label": "Thornwood - NY 10594",
    "county": "Westchester County",
    "state": "NY",
    "city": "Thornwood"
  },
  {
    "value": "10595",
    "label": "Valhalla - NY 10595",
    "county": "Westchester County",
    "state": "NY",
    "city": "Valhalla"
  },
  {
    "value": "10597",
    "label": "Waccabuc - NY 10597",
    "county": "Westchester County",
    "state": "NY",
    "city": "Waccabuc"
  },
  {
    "value": "10598",
    "label": "Yorktown Heights - NY 10598",
    "county": "Westchester County",
    "state": "NY",
    "city": "Yorktown Heights"
  },
  {
    "value": "10601",
    "label": "White Plains - NY 10601",
    "county": "Westchester County",
    "state": "NY",
    "city": "White Plains"
  },
  {
    "value": "10603",
    "label": "White Plains - NY 10603",
    "county": "Westchester County",
    "state": "NY",
    "city": "White Plains"
  },
  {
    "value": "10604",
    "label": "West Harrison - NY 10604",
    "county": "Westchester County",
    "state": "NY",
    "city": "West Harrison"
  },
  {
    "value": "10605",
    "label": "White Plains - NY 10605",
    "county": "Westchester County",
    "state": "NY",
    "city": "White Plains"
  },
  {
    "value": "10606",
    "label": "White Plains - NY 10606",
    "county": "Westchester County",
    "state": "NY",
    "city": "White Plains"
  },
  {
    "value": "10607",
    "label": "White Plains - NY 10607",
    "county": "Westchester County",
    "state": "NY",
    "city": "White Plains"
  },
  {
    "value": "10701",
    "label": "Yonkers - NY 10701",
    "county": "Westchester County",
    "state": "NY",
    "city": "Yonkers"
  },
  {
    "value": "10703",
    "label": "Yonkers - NY 10703",
    "county": "Westchester County",
    "state": "NY",
    "city": "Yonkers"
  },
  {
    "value": "10704",
    "label": "Yonkers - NY 10704",
    "county": "Westchester County",
    "state": "NY",
    "city": "Yonkers"
  },
  {
    "value": "10705",
    "label": "Yonkers - NY 10705",
    "county": "Westchester County",
    "state": "NY",
    "city": "Yonkers"
  },
  {
    "value": "10706",
    "label": "Hastings On Hudson - NY 10706",
    "county": "Westchester County",
    "state": "NY",
    "city": "Hastings On Hudson"
  },
  {
    "value": "10707",
    "label": "Tuckahoe - NY 10707",
    "county": "Westchester County",
    "state": "NY",
    "city": "Tuckahoe"
  },
  {
    "value": "10708",
    "label": "Bronxville - NY 10708",
    "county": "Westchester County",
    "state": "NY",
    "city": "Bronxville"
  },
  {
    "value": "10709",
    "label": "Eastchester - NY 10709",
    "county": "Westchester County",
    "state": "NY",
    "city": "Eastchester"
  },
  {
    "value": "10710",
    "label": "Yonkers - NY 10710",
    "county": "Westchester County",
    "state": "NY",
    "city": "Yonkers"
  },
  {
    "value": "10801",
    "label": "New Rochelle - NY 10801",
    "county": "Westchester County",
    "state": "NY",
    "city": "New Rochelle"
  },
  {
    "value": "10803",
    "label": "Pelham - NY 10803",
    "county": "Westchester County",
    "state": "NY",
    "city": "Pelham"
  },
  {
    "value": "10804",
    "label": "New Rochelle - NY 10804",
    "county": "Westchester County",
    "state": "NY",
    "city": "New Rochelle"
  },
  {
    "value": "10805",
    "label": "New Rochelle - NY 10805",
    "county": "Westchester County",
    "state": "NY",
    "city": "New Rochelle"
  },
  {
    "value": "10901",
    "label": "Suffern - NY 10901",
    "county": "Rockland County",
    "state": "NY",
    "city": "Suffern"
  },
  {
    "value": "10911",
    "label": "Bear Mountain - NY 10911",
    "county": "Orange County",
    "state": "NY",
    "city": "Bear Mountain"
  },
  {
    "value": "10913",
    "label": "Blauvelt - NY 10913",
    "county": "Rockland County",
    "state": "NY",
    "city": "Blauvelt"
  },
  {
    "value": "10916",
    "label": "Campbell Hall - NY 10916",
    "county": "Orange County",
    "state": "NY",
    "city": "Campbell Hall"
  },
  {
    "value": "10917",
    "label": "Central Valley - NY 10917",
    "county": "Orange County",
    "state": "NY",
    "city": "Central Valley"
  },
  {
    "value": "10918",
    "label": "Chester - NY 10918",
    "county": "Orange County",
    "state": "NY",
    "city": "Chester"
  },
  {
    "value": "10919",
    "label": "Circleville - NY 10919",
    "county": "Orange County",
    "state": "NY",
    "city": "Circleville"
  },
  {
    "value": "10920",
    "label": "Congers - NY 10920",
    "county": "Rockland County",
    "state": "NY",
    "city": "Congers"
  },
  {
    "value": "10921",
    "label": "Florida - NY 10921",
    "county": "Orange County",
    "state": "NY",
    "city": "Florida"
  },
  {
    "value": "10923",
    "label": "Garnerville - NY 10923",
    "county": "Rockland County",
    "state": "NY",
    "city": "Garnerville"
  },
  {
    "value": "10924",
    "label": "Goshen - NY 10924",
    "county": "Orange County",
    "state": "NY",
    "city": "Goshen"
  },
  {
    "value": "10925",
    "label": "Greenwood Lake - NY 10925",
    "county": "Orange County",
    "state": "NY",
    "city": "Greenwood Lake"
  },
  {
    "value": "10926",
    "label": "Harriman - NY 10926",
    "county": "Orange County",
    "state": "NY",
    "city": "Harriman"
  },
  {
    "value": "10927",
    "label": "Haverstraw - NY 10927",
    "county": "Rockland County",
    "state": "NY",
    "city": "Haverstraw"
  },
  {
    "value": "10928",
    "label": "Highland Falls - NY 10928",
    "county": "Orange County",
    "state": "NY",
    "city": "Highland Falls"
  },
  {
    "value": "10930",
    "label": "Highland Mills - NY 10930",
    "county": "Orange County",
    "state": "NY",
    "city": "Highland Mills"
  },
  {
    "value": "10931",
    "label": "Hillburn - NY 10931",
    "county": "Rockland County",
    "state": "NY",
    "city": "Hillburn"
  },
  {
    "value": "10940",
    "label": "Middletown - NY 10940",
    "county": "Orange County",
    "state": "NY",
    "city": "Middletown"
  },
  {
    "value": "10941",
    "label": "Middletown - NY 10941",
    "county": "Orange County",
    "state": "NY",
    "city": "Middletown"
  },
  {
    "value": "10950",
    "label": "Monroe - NY 10950",
    "county": "Orange County",
    "state": "NY",
    "city": "Monroe"
  },
  {
    "value": "10952",
    "label": "Monsey - NY 10952",
    "county": "Rockland County",
    "state": "NY",
    "city": "Monsey"
  },
  {
    "value": "10954",
    "label": "Nanuet - NY 10954",
    "county": "Rockland County",
    "state": "NY",
    "city": "Nanuet"
  },
  {
    "value": "10956",
    "label": "New City - NY 10956",
    "county": "Rockland County",
    "state": "NY",
    "city": "New City"
  },
  {
    "value": "10958",
    "label": "New Hampton - NY 10958",
    "county": "Orange County",
    "state": "NY",
    "city": "New Hampton"
  },
  {
    "value": "10960",
    "label": "Nyack - NY 10960",
    "county": "Rockland County",
    "state": "NY",
    "city": "Nyack"
  },
  {
    "value": "10962",
    "label": "Orangeburg - NY 10962",
    "county": "Rockland County",
    "state": "NY",
    "city": "Orangeburg"
  },
  {
    "value": "10963",
    "label": "Otisville - NY 10963",
    "county": "Orange County",
    "state": "NY",
    "city": "Otisville"
  },
  {
    "value": "10964",
    "label": "Palisades - NY 10964",
    "county": "Rockland County",
    "state": "NY",
    "city": "Palisades"
  },
  {
    "value": "10965",
    "label": "Pearl River - NY 10965",
    "county": "Rockland County",
    "state": "NY",
    "city": "Pearl River"
  },
  {
    "value": "10968",
    "label": "Piermont - NY 10968",
    "county": "Rockland County",
    "state": "NY",
    "city": "Piermont"
  },
  {
    "value": "10969",
    "label": "Pine Island - NY 10969",
    "county": "Orange County",
    "state": "NY",
    "city": "Pine Island"
  },
  {
    "value": "10970",
    "label": "Pomona - NY 10970",
    "county": "Rockland County",
    "state": "NY",
    "city": "Pomona"
  },
  {
    "value": "10973",
    "label": "Slate Hill - NY 10973",
    "county": "Orange County",
    "state": "NY",
    "city": "Slate Hill"
  },
  {
    "value": "10974",
    "label": "Sloatsburg - NY 10974",
    "county": "Rockland County",
    "state": "NY",
    "city": "Sloatsburg"
  },
  {
    "value": "10975",
    "label": "Southfields - NY 10975",
    "county": "Orange County",
    "state": "NY",
    "city": "Southfields"
  },
  {
    "value": "10976",
    "label": "Sparkill - NY 10976",
    "county": "Rockland County",
    "state": "NY",
    "city": "Sparkill"
  },
  {
    "value": "10977",
    "label": "Spring Valley - NY 10977",
    "county": "Rockland County",
    "state": "NY",
    "city": "Spring Valley"
  },
  {
    "value": "10980",
    "label": "Stony Point - NY 10980",
    "county": "Rockland County",
    "state": "NY",
    "city": "Stony Point"
  },
  {
    "value": "10983",
    "label": "Tappan - NY 10983",
    "county": "Rockland County",
    "state": "NY",
    "city": "Tappan"
  },
  {
    "value": "10984",
    "label": "Thiells - NY 10984",
    "county": "Rockland County",
    "state": "NY",
    "city": "Thiells"
  },
  {
    "value": "10985",
    "label": "Thompson Ridge - NY 10985",
    "county": "Orange County",
    "state": "NY",
    "city": "Thompson Ridge"
  },
  {
    "value": "10986",
    "label": "Tomkins Cove - NY 10986",
    "county": "Rockland County",
    "state": "NY",
    "city": "Tomkins Cove"
  },
  {
    "value": "10987",
    "label": "Tuxedo Park - NY 10987",
    "county": "Orange County",
    "state": "NY",
    "city": "Tuxedo Park"
  },
  {
    "value": "10989",
    "label": "Valley Cottage - NY 10989",
    "county": "Rockland County",
    "state": "NY",
    "city": "Valley Cottage"
  },
  {
    "value": "10990",
    "label": "Warwick - NY 10990",
    "county": "Orange County",
    "state": "NY",
    "city": "Warwick"
  },
  {
    "value": "10992",
    "label": "Washingtonville - NY 10992",
    "county": "Orange County",
    "state": "NY",
    "city": "Washingtonville"
  },
  {
    "value": "10993",
    "label": "West Haverstraw - NY 10993",
    "county": "Rockland County",
    "state": "NY",
    "city": "West Haverstraw"
  },
  {
    "value": "10994",
    "label": "West Nyack - NY 10994",
    "county": "Rockland County",
    "state": "NY",
    "city": "West Nyack"
  },
  {
    "value": "10996",
    "label": "West Point - NY 10996",
    "county": "Orange County",
    "state": "NY",
    "city": "West Point"
  },
  {
    "value": "10998",
    "label": "Westtown - NY 10998",
    "county": "Orange County",
    "state": "NY",
    "city": "Westtown"
  },
  {
    "value": "11001",
    "label": "Floral Park - NY 11001",
    "county": "Nassau County",
    "state": "NY",
    "city": "Floral Park"
  },
  {
    "value": "11003",
    "label": "Elmont - NY 11003",
    "county": "Nassau County",
    "state": "NY",
    "city": "Elmont"
  },
  {
    "value": "11004",
    "label": "Glen Oaks - NY 11004",
    "county": "Queens County",
    "state": "NY",
    "city": "Glen Oaks"
  },
  {
    "value": "11005",
    "label": "Floral Park - NY 11005",
    "county": "Queens County",
    "state": "NY",
    "city": "Floral Park"
  },
  {
    "value": "11010",
    "label": "Franklin Square - NY 11010",
    "county": "Nassau County",
    "state": "NY",
    "city": "Franklin Square"
  },
  {
    "value": "11020",
    "label": "Great Neck - NY 11020",
    "county": "Nassau County",
    "state": "NY",
    "city": "Great Neck"
  },
  {
    "value": "11021",
    "label": "Great Neck - NY 11021",
    "county": "Nassau County",
    "state": "NY",
    "city": "Great Neck"
  },
  {
    "value": "11023",
    "label": "Great Neck - NY 11023",
    "county": "Nassau County",
    "state": "NY",
    "city": "Great Neck"
  },
  {
    "value": "11024",
    "label": "Great Neck - NY 11024",
    "county": "Nassau County",
    "state": "NY",
    "city": "Great Neck"
  },
  {
    "value": "11030",
    "label": "Manhasset - NY 11030",
    "county": "Nassau County",
    "state": "NY",
    "city": "Manhasset"
  },
  {
    "value": "11040",
    "label": "New Hyde Park - NY 11040",
    "county": "Nassau County",
    "state": "NY",
    "city": "New Hyde Park"
  },
  {
    "value": "11042",
    "label": "New Hyde Park - NY 11042",
    "county": "Nassau County",
    "state": "NY",
    "city": "New Hyde Park"
  },
  {
    "value": "11050",
    "label": "Port Washington - NY 11050",
    "county": "Nassau County",
    "state": "NY",
    "city": "Port Washington"
  },
  {
    "value": "11096",
    "label": "Inwood - NY 11096",
    "county": "Nassau County",
    "state": "NY",
    "city": "Inwood"
  },
  {
    "value": "11101",
    "label": "Long Island City - NY 11101",
    "county": "Queens County",
    "state": "NY",
    "city": "Long Island City"
  },
  {
    "value": "11102",
    "label": "Astoria - NY 11102",
    "county": "Queens County",
    "state": "NY",
    "city": "Astoria"
  },
  {
    "value": "11103",
    "label": "Astoria - NY 11103",
    "county": "Queens County",
    "state": "NY",
    "city": "Astoria"
  },
  {
    "value": "11104",
    "label": "Sunnyside - NY 11104",
    "county": "Queens County",
    "state": "NY",
    "city": "Sunnyside"
  },
  {
    "value": "11105",
    "label": "Astoria - NY 11105",
    "county": "Queens County",
    "state": "NY",
    "city": "Astoria"
  },
  {
    "value": "11106",
    "label": "Astoria - NY 11106",
    "county": "Queens County",
    "state": "NY",
    "city": "Astoria"
  },
  {
    "value": "11109",
    "label": "Long Island City - NY 11109",
    "county": "Queens County",
    "state": "NY",
    "city": "Long Island City"
  },
  {
    "value": "11201",
    "label": "Brooklyn - NY 11201",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11203",
    "label": "Brooklyn - NY 11203",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11204",
    "label": "Brooklyn - NY 11204",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11205",
    "label": "Brooklyn - NY 11205",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11206",
    "label": "Brooklyn - NY 11206",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11207",
    "label": "Brooklyn - NY 11207",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11208",
    "label": "Brooklyn - NY 11208",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11209",
    "label": "Brooklyn - NY 11209",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11210",
    "label": "Brooklyn - NY 11210",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11211",
    "label": "Brooklyn - NY 11211",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11212",
    "label": "Brooklyn - NY 11212",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11213",
    "label": "Brooklyn - NY 11213",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11214",
    "label": "Brooklyn - NY 11214",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11215",
    "label": "Brooklyn - NY 11215",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11216",
    "label": "Brooklyn - NY 11216",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11217",
    "label": "Brooklyn - NY 11217",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11218",
    "label": "Brooklyn - NY 11218",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11219",
    "label": "Brooklyn - NY 11219",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11220",
    "label": "Brooklyn - NY 11220",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11221",
    "label": "Brooklyn - NY 11221",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11222",
    "label": "Brooklyn - NY 11222",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11223",
    "label": "Brooklyn - NY 11223",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11224",
    "label": "Brooklyn - NY 11224",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11225",
    "label": "Brooklyn - NY 11225",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11226",
    "label": "Brooklyn - NY 11226",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11228",
    "label": "Brooklyn - NY 11228",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11229",
    "label": "Brooklyn - NY 11229",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11230",
    "label": "Brooklyn - NY 11230",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11231",
    "label": "Brooklyn - NY 11231",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11232",
    "label": "Brooklyn - NY 11232",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11233",
    "label": "Brooklyn - NY 11233",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11234",
    "label": "Brooklyn - NY 11234",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11235",
    "label": "Brooklyn - NY 11235",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11236",
    "label": "Brooklyn - NY 11236",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11237",
    "label": "Brooklyn - NY 11237",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11238",
    "label": "Brooklyn - NY 11238",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11239",
    "label": "Brooklyn - NY 11239",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11240",
    "label": "Brooklyn - NY 11240",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11241",
    "label": "Brooklyn - NY 11241",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11242",
    "label": "Brooklyn - NY 11242",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11243",
    "label": "Brooklyn - NY 11243",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11249",
    "label": "Brooklyn - NY 11249",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11252",
    "label": "Brooklyn - NY 11252",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11256",
    "label": "Brooklyn - NY 11256",
    "county": "Kings County",
    "state": "NY",
    "city": "Brooklyn"
  },
  {
    "value": "11351",
    "label": "Flushing - NY 11351",
    "county": "Queens County",
    "state": "NY",
    "city": "Flushing"
  },
  {
    "value": "11354",
    "label": "Flushing - NY 11354",
    "county": "Queens County",
    "state": "NY",
    "city": "Flushing"
  },
  {
    "value": "11355",
    "label": "Flushing - NY 11355",
    "county": "Queens County",
    "state": "NY",
    "city": "Flushing"
  },
  {
    "value": "11356",
    "label": "College Point - NY 11356",
    "county": "Queens County",
    "state": "NY",
    "city": "College Point"
  },
  {
    "value": "11357",
    "label": "Whitestone - NY 11357",
    "county": "Queens County",
    "state": "NY",
    "city": "Whitestone"
  },
  {
    "value": "11358",
    "label": "Flushing - NY 11358",
    "county": "Queens County",
    "state": "NY",
    "city": "Flushing"
  },
  {
    "value": "11359",
    "label": "Bayside - NY 11359",
    "county": "Queens County",
    "state": "NY",
    "city": "Bayside"
  },
  {
    "value": "11360",
    "label": "Bayside - NY 11360",
    "county": "Queens County",
    "state": "NY",
    "city": "Bayside"
  },
  {
    "value": "11361",
    "label": "Bayside - NY 11361",
    "county": "Queens County",
    "state": "NY",
    "city": "Bayside"
  },
  {
    "value": "11362",
    "label": "Little Neck - NY 11362",
    "county": "Queens County",
    "state": "NY",
    "city": "Little Neck"
  },
  {
    "value": "11363",
    "label": "Little Neck - NY 11363",
    "county": "Queens County",
    "state": "NY",
    "city": "Little Neck"
  },
  {
    "value": "11364",
    "label": "Oakland Gardens - NY 11364",
    "county": "Queens County",
    "state": "NY",
    "city": "Oakland Gardens"
  },
  {
    "value": "11365",
    "label": "Fresh Meadows - NY 11365",
    "county": "Queens County",
    "state": "NY",
    "city": "Fresh Meadows"
  },
  {
    "value": "11366",
    "label": "Fresh Meadows - NY 11366",
    "county": "Queens County",
    "state": "NY",
    "city": "Fresh Meadows"
  },
  {
    "value": "11367",
    "label": "Flushing - NY 11367",
    "county": "Queens County",
    "state": "NY",
    "city": "Flushing"
  },
  {
    "value": "11368",
    "label": "Corona - NY 11368",
    "county": "Queens County",
    "state": "NY",
    "city": "Corona"
  },
  {
    "value": "11369",
    "label": "East Elmhurst - NY 11369",
    "county": "Queens County",
    "state": "NY",
    "city": "East Elmhurst"
  },
  {
    "value": "11370",
    "label": "East Elmhurst - NY 11370",
    "county": "Queens County",
    "state": "NY",
    "city": "East Elmhurst"
  },
  {
    "value": "11371",
    "label": "Flushing - NY 11371",
    "county": "Queens County",
    "state": "NY",
    "city": "Flushing"
  },
  {
    "value": "11372",
    "label": "Jackson Heights - NY 11372",
    "county": "Queens County",
    "state": "NY",
    "city": "Jackson Heights"
  },
  {
    "value": "11373",
    "label": "Elmhurst - NY 11373",
    "county": "Queens County",
    "state": "NY",
    "city": "Elmhurst"
  },
  {
    "value": "11374",
    "label": "Rego Park - NY 11374",
    "county": "Queens County",
    "state": "NY",
    "city": "Rego Park"
  },
  {
    "value": "11375",
    "label": "Forest Hills - NY 11375",
    "county": "Queens County",
    "state": "NY",
    "city": "Forest Hills"
  },
  {
    "value": "11377",
    "label": "Woodside - NY 11377",
    "county": "Queens County",
    "state": "NY",
    "city": "Woodside"
  },
  {
    "value": "11378",
    "label": "Maspeth - NY 11378",
    "county": "Queens County",
    "state": "NY",
    "city": "Maspeth"
  },
  {
    "value": "11379",
    "label": "Middle Village - NY 11379",
    "county": "Queens County",
    "state": "NY",
    "city": "Middle Village"
  },
  {
    "value": "11385",
    "label": "Ridgewood - NY 11385",
    "county": "Queens County",
    "state": "NY",
    "city": "Ridgewood"
  },
  {
    "value": "11411",
    "label": "Cambria Heights - NY 11411",
    "county": "Queens County",
    "state": "NY",
    "city": "Cambria Heights"
  },
  {
    "value": "11412",
    "label": "Saint Albans - NY 11412",
    "county": "Queens County",
    "state": "NY",
    "city": "Saint Albans"
  },
  {
    "value": "11413",
    "label": "Springfield Gardens - NY 11413",
    "county": "Queens County",
    "state": "NY",
    "city": "Springfield Gardens"
  },
  {
    "value": "11414",
    "label": "Howard Beach - NY 11414",
    "county": "Queens County",
    "state": "NY",
    "city": "Howard Beach"
  },
  {
    "value": "11415",
    "label": "Kew Gardens - NY 11415",
    "county": "Queens County",
    "state": "NY",
    "city": "Kew Gardens"
  },
  {
    "value": "11416",
    "label": "Ozone Park - NY 11416",
    "county": "Queens County",
    "state": "NY",
    "city": "Ozone Park"
  },
  {
    "value": "11417",
    "label": "Ozone Park - NY 11417",
    "county": "Queens County",
    "state": "NY",
    "city": "Ozone Park"
  },
  {
    "value": "11418",
    "label": "Richmond Hill - NY 11418",
    "county": "Queens County",
    "state": "NY",
    "city": "Richmond Hill"
  },
  {
    "value": "11419",
    "label": "South Richmond Hill - NY 11419",
    "county": "Queens County",
    "state": "NY",
    "city": "South Richmond Hill"
  },
  {
    "value": "11420",
    "label": "South Ozone Park - NY 11420",
    "county": "Queens County",
    "state": "NY",
    "city": "South Ozone Park"
  },
  {
    "value": "11421",
    "label": "Woodhaven - NY 11421",
    "county": "Queens County",
    "state": "NY",
    "city": "Woodhaven"
  },
  {
    "value": "11422",
    "label": "Rosedale - NY 11422",
    "county": "Queens County",
    "state": "NY",
    "city": "Rosedale"
  },
  {
    "value": "11423",
    "label": "Hollis - NY 11423",
    "county": "Queens County",
    "state": "NY",
    "city": "Hollis"
  },
  {
    "value": "11426",
    "label": "Bellerose - NY 11426",
    "county": "Queens County",
    "state": "NY",
    "city": "Bellerose"
  },
  {
    "value": "11427",
    "label": "Queens Village - NY 11427",
    "county": "Queens County",
    "state": "NY",
    "city": "Queens Village"
  },
  {
    "value": "11428",
    "label": "Queens Village - NY 11428",
    "county": "Queens County",
    "state": "NY",
    "city": "Queens Village"
  },
  {
    "value": "11429",
    "label": "Queens Village - NY 11429",
    "county": "Queens County",
    "state": "NY",
    "city": "Queens Village"
  },
  {
    "value": "11430",
    "label": "Jamaica - NY 11430",
    "county": "Queens County",
    "state": "NY",
    "city": "Jamaica"
  },
  {
    "value": "11432",
    "label": "Jamaica - NY 11432",
    "county": "Queens County",
    "state": "NY",
    "city": "Jamaica"
  },
  {
    "value": "11433",
    "label": "Jamaica - NY 11433",
    "county": "Queens County",
    "state": "NY",
    "city": "Jamaica"
  },
  {
    "value": "11434",
    "label": "Jamaica - NY 11434",
    "county": "Queens County",
    "state": "NY",
    "city": "Jamaica"
  },
  {
    "value": "11435",
    "label": "Jamaica - NY 11435",
    "county": "Queens County",
    "state": "NY",
    "city": "Jamaica"
  },
  {
    "value": "11436",
    "label": "Jamaica - NY 11436",
    "county": "Queens County",
    "state": "NY",
    "city": "Jamaica"
  },
  {
    "value": "11501",
    "label": "Mineola - NY 11501",
    "county": "Nassau County",
    "state": "NY",
    "city": "Mineola"
  },
  {
    "value": "11507",
    "label": "Albertson - NY 11507",
    "county": "Nassau County",
    "state": "NY",
    "city": "Albertson"
  },
  {
    "value": "11509",
    "label": "Atlantic Beach - NY 11509",
    "county": "Nassau County",
    "state": "NY",
    "city": "Atlantic Beach"
  },
  {
    "value": "11510",
    "label": "Baldwin - NY 11510",
    "county": "Nassau County",
    "state": "NY",
    "city": "Baldwin"
  },
  {
    "value": "11514",
    "label": "Carle Place - NY 11514",
    "county": "Nassau County",
    "state": "NY",
    "city": "Carle Place"
  },
  {
    "value": "11516",
    "label": "Cedarhurst - NY 11516",
    "county": "Nassau County",
    "state": "NY",
    "city": "Cedarhurst"
  },
  {
    "value": "11518",
    "label": "East Rockaway - NY 11518",
    "county": "Nassau County",
    "state": "NY",
    "city": "East Rockaway"
  },
  {
    "value": "11520",
    "label": "Freeport - NY 11520",
    "county": "Nassau County",
    "state": "NY",
    "city": "Freeport"
  },
  {
    "value": "11530",
    "label": "Garden City - NY 11530",
    "county": "Nassau County",
    "state": "NY",
    "city": "Garden City"
  },
  {
    "value": "11542",
    "label": "Glen Cove - NY 11542",
    "county": "Nassau County",
    "state": "NY",
    "city": "Glen Cove"
  },
  {
    "value": "11545",
    "label": "Glen Head - NY 11545",
    "county": "Nassau County",
    "state": "NY",
    "city": "Glen Head"
  },
  {
    "value": "11548",
    "label": "Greenvale - NY 11548",
    "county": "Nassau County",
    "state": "NY",
    "city": "Greenvale"
  },
  {
    "value": "11550",
    "label": "Hempstead - NY 11550",
    "county": "Nassau County",
    "state": "NY",
    "city": "Hempstead"
  },
  {
    "value": "11552",
    "label": "West Hempstead - NY 11552",
    "county": "Nassau County",
    "state": "NY",
    "city": "West Hempstead"
  },
  {
    "value": "11553",
    "label": "Uniondale - NY 11553",
    "county": "Nassau County",
    "state": "NY",
    "city": "Uniondale"
  },
  {
    "value": "11554",
    "label": "East Meadow - NY 11554",
    "county": "Nassau County",
    "state": "NY",
    "city": "East Meadow"
  },
  {
    "value": "11556",
    "label": "Uniondale - NY 11556",
    "county": "Nassau County",
    "state": "NY",
    "city": "Uniondale"
  },
  {
    "value": "11557",
    "label": "Hewlett - NY 11557",
    "county": "Nassau County",
    "state": "NY",
    "city": "Hewlett"
  },
  {
    "value": "11558",
    "label": "Island Park - NY 11558",
    "county": "Nassau County",
    "state": "NY",
    "city": "Island Park"
  },
  {
    "value": "11559",
    "label": "Lawrence - NY 11559",
    "county": "Nassau County",
    "state": "NY",
    "city": "Lawrence"
  },
  {
    "value": "11560",
    "label": "Locust Valley - NY 11560",
    "county": "Nassau County",
    "state": "NY",
    "city": "Locust Valley"
  },
  {
    "value": "11561",
    "label": "Long Beach - NY 11561",
    "county": "Nassau County",
    "state": "NY",
    "city": "Long Beach"
  },
  {
    "value": "11563",
    "label": "Lynbrook - NY 11563",
    "county": "Nassau County",
    "state": "NY",
    "city": "Lynbrook"
  },
  {
    "value": "11565",
    "label": "Malverne - NY 11565",
    "county": "Nassau County",
    "state": "NY",
    "city": "Malverne"
  },
  {
    "value": "11566",
    "label": "Merrick - NY 11566",
    "county": "Nassau County",
    "state": "NY",
    "city": "Merrick"
  },
  {
    "value": "11568",
    "label": "Old Westbury - NY 11568",
    "county": "Nassau County",
    "state": "NY",
    "city": "Old Westbury"
  },
  {
    "value": "11570",
    "label": "Rockville Centre - NY 11570",
    "county": "Nassau County",
    "state": "NY",
    "city": "Rockville Centre"
  },
  {
    "value": "11572",
    "label": "Oceanside - NY 11572",
    "county": "Nassau County",
    "state": "NY",
    "city": "Oceanside"
  },
  {
    "value": "11575",
    "label": "Roosevelt - NY 11575",
    "county": "Nassau County",
    "state": "NY",
    "city": "Roosevelt"
  },
  {
    "value": "11576",
    "label": "Roslyn - NY 11576",
    "county": "Nassau County",
    "state": "NY",
    "city": "Roslyn"
  },
  {
    "value": "11577",
    "label": "Roslyn Heights - NY 11577",
    "county": "Nassau County",
    "state": "NY",
    "city": "Roslyn Heights"
  },
  {
    "value": "11579",
    "label": "Sea Cliff - NY 11579",
    "county": "Nassau County",
    "state": "NY",
    "city": "Sea Cliff"
  },
  {
    "value": "11580",
    "label": "Valley Stream - NY 11580",
    "county": "Nassau County",
    "state": "NY",
    "city": "Valley Stream"
  },
  {
    "value": "11581",
    "label": "Valley Stream - NY 11581",
    "county": "Nassau County",
    "state": "NY",
    "city": "Valley Stream"
  },
  {
    "value": "11590",
    "label": "Westbury - NY 11590",
    "county": "Nassau County",
    "state": "NY",
    "city": "Westbury"
  },
  {
    "value": "11596",
    "label": "Williston Park - NY 11596",
    "county": "Nassau County",
    "state": "NY",
    "city": "Williston Park"
  },
  {
    "value": "11598",
    "label": "Woodmere - NY 11598",
    "county": "Nassau County",
    "state": "NY",
    "city": "Woodmere"
  },
  {
    "value": "11599",
    "label": "Garden City - NY 11599",
    "county": "Nassau County",
    "state": "NY",
    "city": "Garden City"
  },
  {
    "value": "11691",
    "label": "Far Rockaway - NY 11691",
    "county": "Queens County",
    "state": "NY",
    "city": "Far Rockaway"
  },
  {
    "value": "11692",
    "label": "Arverne - NY 11692",
    "county": "Queens County",
    "state": "NY",
    "city": "Arverne"
  },
  {
    "value": "11693",
    "label": "Far Rockaway - NY 11693",
    "county": "Queens County",
    "state": "NY",
    "city": "Far Rockaway"
  },
  {
    "value": "11694",
    "label": "Rockaway Park - NY 11694",
    "county": "Queens County",
    "state": "NY",
    "city": "Rockaway Park"
  },
  {
    "value": "11697",
    "label": "Breezy Point - NY 11697",
    "county": "Queens County",
    "state": "NY",
    "city": "Breezy Point"
  },
  {
    "value": "11701",
    "label": "Amityville - NY 11701",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Amityville"
  },
  {
    "value": "11702",
    "label": "Babylon - NY 11702",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Babylon"
  },
  {
    "value": "11703",
    "label": "North Babylon - NY 11703",
    "county": "Suffolk County",
    "state": "NY",
    "city": "North Babylon"
  },
  {
    "value": "11704",
    "label": "West Babylon - NY 11704",
    "county": "Suffolk County",
    "state": "NY",
    "city": "West Babylon"
  },
  {
    "value": "11705",
    "label": "Bayport - NY 11705",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Bayport"
  },
  {
    "value": "11706",
    "label": "Bay Shore - NY 11706",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Bay Shore"
  },
  {
    "value": "11708",
    "label": "Amityville - NY 11708",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Amityville"
  },
  {
    "value": "11709",
    "label": "Bayville - NY 11709",
    "county": "Nassau County",
    "state": "NY",
    "city": "Bayville"
  },
  {
    "value": "11710",
    "label": "Bellmore - NY 11710",
    "county": "Nassau County",
    "state": "NY",
    "city": "Bellmore"
  },
  {
    "value": "11713",
    "label": "Bellport - NY 11713",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Bellport"
  },
  {
    "value": "11714",
    "label": "Bethpage - NY 11714",
    "county": "Nassau County",
    "state": "NY",
    "city": "Bethpage"
  },
  {
    "value": "11715",
    "label": "Blue Point - NY 11715",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Blue Point"
  },
  {
    "value": "11716",
    "label": "Bohemia - NY 11716",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Bohemia"
  },
  {
    "value": "11717",
    "label": "Brentwood - NY 11717",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Brentwood"
  },
  {
    "value": "11718",
    "label": "Brightwaters - NY 11718",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Brightwaters"
  },
  {
    "value": "11719",
    "label": "Brookhaven - NY 11719",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Brookhaven"
  },
  {
    "value": "11720",
    "label": "Centereach - NY 11720",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Centereach"
  },
  {
    "value": "11721",
    "label": "Centerport - NY 11721",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Centerport"
  },
  {
    "value": "11722",
    "label": "Central Islip - NY 11722",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Central Islip"
  },
  {
    "value": "11724",
    "label": "Cold Spring Harbor - NY 11724",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Cold Spring Harbor"
  },
  {
    "value": "11725",
    "label": "Commack - NY 11725",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Commack"
  },
  {
    "value": "11726",
    "label": "Copiague - NY 11726",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Copiague"
  },
  {
    "value": "11727",
    "label": "Coram - NY 11727",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Coram"
  },
  {
    "value": "11729",
    "label": "Deer Park - NY 11729",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Deer Park"
  },
  {
    "value": "11730",
    "label": "East Islip - NY 11730",
    "county": "Suffolk County",
    "state": "NY",
    "city": "East Islip"
  },
  {
    "value": "11731",
    "label": "East Northport - NY 11731",
    "county": "Suffolk County",
    "state": "NY",
    "city": "East Northport"
  },
  {
    "value": "11732",
    "label": "East Norwich - NY 11732",
    "county": "Nassau County",
    "state": "NY",
    "city": "East Norwich"
  },
  {
    "value": "11733",
    "label": "East Setauket - NY 11733",
    "county": "Suffolk County",
    "state": "NY",
    "city": "East Setauket"
  },
  {
    "value": "11735",
    "label": "Farmingdale - NY 11735",
    "county": "Nassau County",
    "state": "NY",
    "city": "Farmingdale"
  },
  {
    "value": "11737",
    "label": "Farmingdale - NY 11737",
    "county": "Nassau County",
    "state": "NY",
    "city": "Farmingdale"
  },
  {
    "value": "11738",
    "label": "Farmingville - NY 11738",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Farmingville"
  },
  {
    "value": "11740",
    "label": "Greenlawn - NY 11740",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Greenlawn"
  },
  {
    "value": "11741",
    "label": "Holbrook - NY 11741",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Holbrook"
  },
  {
    "value": "11742",
    "label": "Holtsville - NY 11742",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Holtsville"
  },
  {
    "value": "11743",
    "label": "Huntington - NY 11743",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Huntington"
  },
  {
    "value": "11746",
    "label": "Huntington Station - NY 11746",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Huntington Station"
  },
  {
    "value": "11747",
    "label": "Melville - NY 11747",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Melville"
  },
  {
    "value": "11749",
    "label": "Islandia - NY 11749",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Islandia"
  },
  {
    "value": "11751",
    "label": "Islip - NY 11751",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Islip"
  },
  {
    "value": "11752",
    "label": "Islip Terrace - NY 11752",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Islip Terrace"
  },
  {
    "value": "11753",
    "label": "Jericho - NY 11753",
    "county": "Nassau County",
    "state": "NY",
    "city": "Jericho"
  },
  {
    "value": "11754",
    "label": "Kings Park - NY 11754",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Kings Park"
  },
  {
    "value": "11755",
    "label": "Lake Grove - NY 11755",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Lake Grove"
  },
  {
    "value": "11756",
    "label": "Levittown - NY 11756",
    "county": "Nassau County",
    "state": "NY",
    "city": "Levittown"
  },
  {
    "value": "11757",
    "label": "Lindenhurst - NY 11757",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Lindenhurst"
  },
  {
    "value": "11758",
    "label": "Massapequa - NY 11758",
    "county": "Nassau County",
    "state": "NY",
    "city": "Massapequa"
  },
  {
    "value": "11762",
    "label": "Massapequa Park - NY 11762",
    "county": "Nassau County",
    "state": "NY",
    "city": "Massapequa Park"
  },
  {
    "value": "11763",
    "label": "Medford - NY 11763",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Medford"
  },
  {
    "value": "11764",
    "label": "Miller Place - NY 11764",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Miller Place"
  },
  {
    "value": "11765",
    "label": "Mill Neck - NY 11765",
    "county": "Nassau County",
    "state": "NY",
    "city": "Mill Neck"
  },
  {
    "value": "11766",
    "label": "Mount Sinai - NY 11766",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Mount Sinai"
  },
  {
    "value": "11767",
    "label": "Nesconset - NY 11767",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Nesconset"
  },
  {
    "value": "11768",
    "label": "Northport - NY 11768",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Northport"
  },
  {
    "value": "11769",
    "label": "Oakdale - NY 11769",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Oakdale"
  },
  {
    "value": "11771",
    "label": "Oyster Bay - NY 11771",
    "county": "Nassau County",
    "state": "NY",
    "city": "Oyster Bay"
  },
  {
    "value": "11772",
    "label": "Patchogue - NY 11772",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Patchogue"
  },
  {
    "value": "11776",
    "label": "Port Jefferson Station - NY 11776",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Port Jefferson Station"
  },
  {
    "value": "11777",
    "label": "Port Jefferson - NY 11777",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Port Jefferson"
  },
  {
    "value": "11778",
    "label": "Rocky Point - NY 11778",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Rocky Point"
  },
  {
    "value": "11779",
    "label": "Ronkonkoma - NY 11779",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Ronkonkoma"
  },
  {
    "value": "11780",
    "label": "Saint James - NY 11780",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Saint James"
  },
  {
    "value": "11782",
    "label": "Sayville - NY 11782",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Sayville"
  },
  {
    "value": "11783",
    "label": "Seaford - NY 11783",
    "county": "Nassau County",
    "state": "NY",
    "city": "Seaford"
  },
  {
    "value": "11784",
    "label": "Selden - NY 11784",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Selden"
  },
  {
    "value": "11786",
    "label": "Shoreham - NY 11786",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Shoreham"
  },
  {
    "value": "11787",
    "label": "Smithtown - NY 11787",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Smithtown"
  },
  {
    "value": "11788",
    "label": "Hauppauge - NY 11788",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Hauppauge"
  },
  {
    "value": "11789",
    "label": "Sound Beach - NY 11789",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Sound Beach"
  },
  {
    "value": "11790",
    "label": "Stony Brook - NY 11790",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Stony Brook"
  },
  {
    "value": "11791",
    "label": "Syosset - NY 11791",
    "county": "Nassau County",
    "state": "NY",
    "city": "Syosset"
  },
  {
    "value": "11792",
    "label": "Wading River - NY 11792",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Wading River"
  },
  {
    "value": "11793",
    "label": "Wantagh - NY 11793",
    "county": "Nassau County",
    "state": "NY",
    "city": "Wantagh"
  },
  {
    "value": "11795",
    "label": "West Islip - NY 11795",
    "county": "Suffolk County",
    "state": "NY",
    "city": "West Islip"
  },
  {
    "value": "11796",
    "label": "West Sayville - NY 11796",
    "county": "Suffolk County",
    "state": "NY",
    "city": "West Sayville"
  },
  {
    "value": "11797",
    "label": "Woodbury - NY 11797",
    "county": "Nassau County",
    "state": "NY",
    "city": "Woodbury"
  },
  {
    "value": "11798",
    "label": "Wyandanch - NY 11798",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Wyandanch"
  },
  {
    "value": "11801",
    "label": "Hicksville - NY 11801",
    "county": "Nassau County",
    "state": "NY",
    "city": "Hicksville"
  },
  {
    "value": "11803",
    "label": "Plainview - NY 11803",
    "county": "Nassau County",
    "state": "NY",
    "city": "Plainview"
  },
  {
    "value": "11804",
    "label": "Old Bethpage - NY 11804",
    "county": "Nassau County",
    "state": "NY",
    "city": "Old Bethpage"
  },
  {
    "value": "11901",
    "label": "Riverhead - NY 11901",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Riverhead"
  },
  {
    "value": "11933",
    "label": "Calverton - NY 11933",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Calverton"
  },
  {
    "value": "11934",
    "label": "Center Moriches - NY 11934",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Center Moriches"
  },
  {
    "value": "11935",
    "label": "Cutchogue - NY 11935",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Cutchogue"
  },
  {
    "value": "11937",
    "label": "East Hampton - NY 11937",
    "county": "Suffolk County",
    "state": "NY",
    "city": "East Hampton"
  },
  {
    "value": "11939",
    "label": "East Marion - NY 11939",
    "county": "Suffolk County",
    "state": "NY",
    "city": "East Marion"
  },
  {
    "value": "11940",
    "label": "East Moriches - NY 11940",
    "county": "Suffolk County",
    "state": "NY",
    "city": "East Moriches"
  },
  {
    "value": "11941",
    "label": "Eastport - NY 11941",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Eastport"
  },
  {
    "value": "11942",
    "label": "East Quogue - NY 11942",
    "county": "Suffolk County",
    "state": "NY",
    "city": "East Quogue"
  },
  {
    "value": "11944",
    "label": "Greenport - NY 11944",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Greenport"
  },
  {
    "value": "11946",
    "label": "Hampton Bays - NY 11946",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Hampton Bays"
  },
  {
    "value": "11948",
    "label": "Laurel - NY 11948",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Laurel"
  },
  {
    "value": "11949",
    "label": "Manorville - NY 11949",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Manorville"
  },
  {
    "value": "11950",
    "label": "Mastic - NY 11950",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Mastic"
  },
  {
    "value": "11951",
    "label": "Mastic Beach - NY 11951",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Mastic Beach"
  },
  {
    "value": "11952",
    "label": "Mattituck - NY 11952",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Mattituck"
  },
  {
    "value": "11953",
    "label": "Middle Island - NY 11953",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Middle Island"
  },
  {
    "value": "11954",
    "label": "Montauk - NY 11954",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Montauk"
  },
  {
    "value": "11955",
    "label": "Moriches - NY 11955",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Moriches"
  },
  {
    "value": "11957",
    "label": "Orient - NY 11957",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Orient"
  },
  {
    "value": "11958",
    "label": "Peconic - NY 11958",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Peconic"
  },
  {
    "value": "11961",
    "label": "Ridge - NY 11961",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Ridge"
  },
  {
    "value": "11963",
    "label": "Sag Harbor - NY 11963",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Sag Harbor"
  },
  {
    "value": "11967",
    "label": "Shirley - NY 11967",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Shirley"
  },
  {
    "value": "11968",
    "label": "Southampton - NY 11968",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Southampton"
  },
  {
    "value": "11971",
    "label": "Southold - NY 11971",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Southold"
  },
  {
    "value": "11976",
    "label": "Water Mill - NY 11976",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Water Mill"
  },
  {
    "value": "11977",
    "label": "Westhampton - NY 11977",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Westhampton"
  },
  {
    "value": "11978",
    "label": "Westhampton Beach - NY 11978",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Westhampton Beach"
  },
  {
    "value": "11980",
    "label": "Yaphank - NY 11980",
    "county": "Suffolk County",
    "state": "NY",
    "city": "Yaphank"
  },
  {
    "value": "12007",
    "label": "Alcove - NY 12007",
    "county": "Albany County",
    "state": "NY",
    "city": "Alcove"
  },
  {
    "value": "12008",
    "label": "Alplaus - NY 12008",
    "county": "Schenectady County",
    "state": "NY",
    "city": "Alplaus"
  },
  {
    "value": "12009",
    "label": "Altamont - NY 12009",
    "county": "Albany County",
    "state": "NY",
    "city": "Altamont"
  },
  {
    "value": "12010",
    "label": "Amsterdam - NY 12010",
    "county": "Montgomery County",
    "state": "NY",
    "city": "Amsterdam"
  },
  {
    "value": "12015",
    "label": "Athens - NY 12015",
    "county": "Greene County",
    "state": "NY",
    "city": "Athens"
  },
  {
    "value": "12016",
    "label": "Auriesville - NY 12016",
    "county": "Montgomery County",
    "state": "NY",
    "city": "Auriesville"
  },
  {
    "value": "12017",
    "label": "Austerlitz - NY 12017",
    "county": "Columbia County",
    "state": "NY",
    "city": "Austerlitz"
  },
  {
    "value": "12018",
    "label": "Averill Park - NY 12018",
    "county": "Rensselaer County",
    "state": "NY",
    "city": "Averill Park"
  },
  {
    "value": "12019",
    "label": "Ballston Lake - NY 12019",
    "county": "Saratoga County",
    "state": "NY",
    "city": "Ballston Lake"
  },
  {
    "value": "12020",
    "label": "Ballston Spa - NY 12020",
    "county": "Saratoga County",
    "state": "NY",
    "city": "Ballston Spa"
  },
  {
    "value": "12022",
    "label": "Berlin - NY 12022",
    "county": "Rensselaer County",
    "state": "NY",
    "city": "Berlin"
  },
  {
    "value": "12023",
    "label": "Berne - NY 12023",
    "county": "Albany County",
    "state": "NY",
    "city": "Berne"
  },
  {
    "value": "12024",
    "label": "Brainard - NY 12024",
    "county": "Columbia County",
    "state": "NY",
    "city": "Brainard"
  },
  {
    "value": "12025",
    "label": "Broadalbin - NY 12025",
    "county": "Fulton County",
    "state": "NY",
    "city": "Broadalbin"
  },
  {
    "value": "12027",
    "label": "Burnt Hills - NY 12027",
    "county": "Saratoga County",
    "state": "NY",
    "city": "Burnt Hills"
  },
  {
    "value": "12028",
    "label": "Buskirk - NY 12028",
    "county": "Rensselaer County",
    "state": "NY",
    "city": "Buskirk"
  },
  {
    "value": "12029",
    "label": "Canaan - NY 12029",
    "county": "Columbia County",
    "state": "NY",
    "city": "Canaan"
  },
  {
    "value": "12031",
    "label": "Carlisle - NY 12031",
    "county": "Schoharie County",
    "state": "NY",
    "city": "Carlisle"
  },
  {
    "value": "12032",
    "label": "Caroga Lake - NY 12032",
    "county": "Fulton County",
    "state": "NY",
    "city": "Caroga Lake"
  },
  {
    "value": "12033",
    "label": "Castleton On Hudson - NY 12033",
    "county": "Rensselaer County",
    "state": "NY",
    "city": "Castleton On Hudson"
  },
  {
    "value": "12035",
    "label": "Central Bridge - NY 12035",
    "county": "Schoharie County",
    "state": "NY",
    "city": "Central Bridge"
  },
  {
    "value": "12036",
    "label": "Charlotteville - NY 12036",
    "county": "Schoharie County",
    "state": "NY",
    "city": "Charlotteville"
  },
  {
    "value": "12037",
    "label": "Chatham - NY 12037",
    "county": "Columbia County",
    "state": "NY",
    "city": "Chatham"
  },
  {
    "value": "12041",
    "label": "Clarksville - NY 12041",
    "county": "Albany County",
    "state": "NY",
    "city": "Clarksville"
  },
  {
    "value": "12042",
    "label": "Climax - NY 12042",
    "county": "Greene County",
    "state": "NY",
    "city": "Climax"
  },
  {
    "value": "12043",
    "label": "Cobleskill - NY 12043",
    "county": "Schoharie County",
    "state": "NY",
    "city": "Cobleskill"
  },
  {
    "value": "12046",
    "label": "Coeymans Hollow - NY 12046",
    "county": "Albany County",
    "state": "NY",
    "city": "Coeymans Hollow"
  },
  {
    "value": "12047",
    "label": "Cohoes - NY 12047",
    "county": "Albany County",
    "state": "NY",
    "city": "Cohoes"
  },
  {
    "value": "12051",
    "label": "Coxsackie - NY 12051",
    "county": "Greene County",
    "state": "NY",
    "city": "Coxsackie"
  },
  {
    "value": "12052",
    "label": "Cropseyville - NY 12052",
    "county": "Rensselaer County",
    "state": "NY",
    "city": "Cropseyville"
  },
  {
    "value": "12053",
    "label": "Delanson - NY 12053",
    "county": "Schenectady County",
    "state": "NY",
    "city": "Delanson"
  },
  {
    "value": "12054",
    "label": "Delmar - NY 12054",
    "county": "Albany County",
    "state": "NY",
    "city": "Delmar"
  },
  {
    "value": "12055",
    "label": "Dormansville - NY 12055",
    "county": "Albany County",
    "state": "NY",
    "city": "Dormansville"
  },
  {
    "value": "12056",
    "label": "Duanesburg - NY 12056",
    "county": "Schenectady County",
    "state": "NY",
    "city": "Duanesburg"
  },
  {
    "value": "12057",
    "label": "Eagle Bridge - NY 12057",
    "county": "Washington County",
    "state": "NY",
    "city": "Eagle Bridge"
  },
  {
    "value": "12058",
    "label": "Earlton - NY 12058",
    "county": "Greene County",
    "state": "NY",
    "city": "Earlton"
  },
  {
    "value": "12059",
    "label": "East Berne - NY 12059",
    "county": "Albany County",
    "state": "NY",
    "city": "East Berne"
  },
  {
    "value": "12060",
    "label": "East Chatham - NY 12060",
    "county": "Columbia County",
    "state": "NY",
    "city": "East Chatham"
  },
  {
    "value": "12061",
    "label": "East Greenbush - NY 12061",
    "county": "Rensselaer County",
    "state": "NY",
    "city": "East Greenbush"
  },
  {
    "value": "12062",
    "label": "East Nassau - NY 12062",
    "county": "Rensselaer County",
    "state": "NY",
    "city": "East Nassau"
  },
  {
    "value": "12063",
    "label": "East Schodack - NY 12063",
    "county": "Rensselaer County",
    "state": "NY",
    "city": "East Schodack"
  },
  {
    "value": "12064",
    "label": "East Worcester - NY 12064",
    "county": "Otsego County",
    "state": "NY",
    "city": "East Worcester"
  },
  {
    "value": "12065",
    "label": "Clifton Park - NY 12065",
    "county": "Saratoga County",
    "state": "NY",
    "city": "Clifton Park"
  },
  {
    "value": "12066",
    "label": "Esperance - NY 12066",
    "county": "Schoharie County",
    "state": "NY",
    "city": "Esperance"
  },
  {
    "value": "12067",
    "label": "Feura Bush - NY 12067",
    "county": "Albany County",
    "state": "NY",
    "city": "Feura Bush"
  },
  {
    "value": "12068",
    "label": "Fonda - NY 12068",
    "county": "Montgomery County",
    "state": "NY",
    "city": "Fonda"
  },
  {
    "value": "12070",
    "label": "Fort Johnson - NY 12070",
    "county": "Montgomery County",
    "state": "NY",
    "city": "Fort Johnson"
  },
  {
    "value": "12071",
    "label": "Fultonham - NY 12071",
    "county": "Schoharie County",
    "state": "NY",
    "city": "Fultonham"
  },
  {
    "value": "12072",
    "label": "Fultonville - NY 12072",
    "county": "Montgomery County",
    "state": "NY",
    "city": "Fultonville"
  },
  {
    "value": "12074",
    "label": "Galway - NY 12074",
    "county": "Saratoga County",
    "state": "NY",
    "city": "Galway"
  },
  {
    "value": "12075",
    "label": "Ghent - NY 12075",
    "county": "Columbia County",
    "state": "NY",
    "city": "Ghent"
  },
  {
    "value": "12076",
    "label": "Gilboa - NY 12076",
    "county": "Schoharie County",
    "state": "NY",
    "city": "Gilboa"
  },
  {
    "value": "12077",
    "label": "Glenmont - NY 12077",
    "county": "Albany County",
    "state": "NY",
    "city": "Glenmont"
  },
  {
    "value": "12078",
    "label": "Gloversville - NY 12078",
    "county": "Fulton County",
    "state": "NY",
    "city": "Gloversville"
  },
  {
    "value": "12083",
    "label": "Greenville - NY 12083",
    "county": "Greene County",
    "state": "NY",
    "city": "Greenville"
  },
  {
    "value": "12084",
    "label": "Guilderland - NY 12084",
    "county": "Albany County",
    "state": "NY",
    "city": "Guilderland"
  },
  {
    "value": "12085",
    "label": "Guilderland Center - NY 12085",
    "county": "Albany County",
    "state": "NY",
    "city": "Guilderland Center"
  },
  {
    "value": "12086",
    "label": "Hagaman - NY 12086",
    "county": "Montgomery County",
    "state": "NY",
    "city": "Hagaman"
  },
  {
    "value": "12087",
    "label": "Hannacroix - NY 12087",
    "county": "Greene County",
    "state": "NY",
    "city": "Hannacroix"
  },
  {
    "value": "12090",
    "label": "Hoosick Falls - NY 12090",
    "county": "Rensselaer County",
    "state": "NY",
    "city": "Hoosick Falls"
  },
  {
    "value": "12092",
    "label": "Howes Cave - NY 12092",
    "county": "Schoharie County",
    "state": "NY",
    "city": "Howes Cave"
  },
  {
    "value": "12093",
    "label": "Jefferson - NY 12093",
    "county": "Schoharie County",
    "state": "NY",
    "city": "Jefferson"
  },
  {
    "value": "12094",
    "label": "Johnsonville - NY 12094",
    "county": "Rensselaer County",
    "state": "NY",
    "city": "Johnsonville"
  },
  {
    "value": "12095",
    "label": "Johnstown - NY 12095",
    "county": "Fulton County",
    "state": "NY",
    "city": "Johnstown"
  },
  {
    "value": "12106",
    "label": "Kinderhook - NY 12106",
    "county": "Columbia County",
    "state": "NY",
    "city": "Kinderhook"
  },
  {
    "value": "12108",
    "label": "Lake Pleasant - NY 12108",
    "county": "Hamilton County",
    "state": "NY",
    "city": "Lake Pleasant"
  },
  {
    "value": "12110",
    "label": "Latham - NY 12110",
    "county": "Albany County",
    "state": "NY",
    "city": "Latham"
  },
  {
    "value": "12115",
    "label": "Malden Bridge - NY 12115",
    "county": "Columbia County",
    "state": "NY",
    "city": "Malden Bridge"
  },
  {
    "value": "12116",
    "label": "Maryland - NY 12116",
    "county": "Otsego County",
    "state": "NY",
    "city": "Maryland"
  },
  {
    "value": "12117",
    "label": "Mayfield - NY 12117",
    "county": "Fulton County",
    "state": "NY",
    "city": "Mayfield"
  },
  {
    "value": "12118",
    "label": "Mechanicville - NY 12118",
    "county": "Saratoga County",
    "state": "NY",
    "city": "Mechanicville"
  },
  {
    "value": "12120",
    "label": "Medusa - NY 12120",
    "county": "Albany County",
    "state": "NY",
    "city": "Medusa"
  },
  {
    "value": "12121",
    "label": "Melrose - NY 12121",
    "county": "Rensselaer County",
    "state": "NY",
    "city": "Melrose"
  },
  {
    "value": "12122",
    "label": "Middleburgh - NY 12122",
    "county": "Schoharie County",
    "state": "NY",
    "city": "Middleburgh"
  },
  {
    "value": "12123",
    "label": "Nassau - NY 12123",
    "county": "Rensselaer County",
    "state": "NY",
    "city": "Nassau"
  },
  {
    "value": "12125",
    "label": "New Lebanon - NY 12125",
    "county": "Columbia County",
    "state": "NY",
    "city": "New Lebanon"
  },
  {
    "value": "12130",
    "label": "Niverville - NY 12130",
    "county": "Columbia County",
    "state": "NY",
    "city": "Niverville"
  },
  {
    "value": "12131",
    "label": "North Blenheim - NY 12131",
    "county": "Schoharie County",
    "state": "NY",
    "city": "North Blenheim"
  },
  {
    "value": "12134",
    "label": "Northville - NY 12134",
    "county": "Fulton County",
    "state": "NY",
    "city": "Northville"
  },
  {
    "value": "12136",
    "label": "Old Chatham - NY 12136",
    "county": "Columbia County",
    "state": "NY",
    "city": "Old Chatham"
  },
  {
    "value": "12137",
    "label": "Pattersonville - NY 12137",
    "county": "Schenectady County",
    "state": "NY",
    "city": "Pattersonville"
  },
  {
    "value": "12138",
    "label": "Petersburg - NY 12138",
    "county": "Rensselaer County",
    "state": "NY",
    "city": "Petersburg"
  },
  {
    "value": "12139",
    "label": "Piseco - NY 12139",
    "county": "Hamilton County",
    "state": "NY",
    "city": "Piseco"
  },
  {
    "value": "12140",
    "label": "Poestenkill - NY 12140",
    "county": "Rensselaer County",
    "state": "NY",
    "city": "Poestenkill"
  },
  {
    "value": "12143",
    "label": "Ravena - NY 12143",
    "county": "Albany County",
    "state": "NY",
    "city": "Ravena"
  },
  {
    "value": "12144",
    "label": "Rensselaer - NY 12144",
    "county": "Rensselaer County",
    "state": "NY",
    "city": "Rensselaer"
  },
  {
    "value": "12147",
    "label": "Rensselaerville - NY 12147",
    "county": "Albany County",
    "state": "NY",
    "city": "Rensselaerville"
  },
  {
    "value": "12148",
    "label": "Rexford - NY 12148",
    "county": "Saratoga County",
    "state": "NY",
    "city": "Rexford"
  },
  {
    "value": "12149",
    "label": "Richmondville - NY 12149",
    "county": "Schoharie County",
    "state": "NY",
    "city": "Richmondville"
  },
  {
    "value": "12150",
    "label": "Rotterdam Junction - NY 12150",
    "county": "Schenectady County",
    "state": "NY",
    "city": "Rotterdam Junction"
  },
  {
    "value": "12151",
    "label": "Round Lake - NY 12151",
    "county": "Saratoga County",
    "state": "NY",
    "city": "Round Lake"
  },
  {
    "value": "12153",
    "label": "Sand Lake - NY 12153",
    "county": "Rensselaer County",
    "state": "NY",
    "city": "Sand Lake"
  },
  {
    "value": "12154",
    "label": "Schaghticoke - NY 12154",
    "county": "Rensselaer County",
    "state": "NY",
    "city": "Schaghticoke"
  },
  {
    "value": "12155",
    "label": "Schenevus - NY 12155",
    "county": "Otsego County",
    "state": "NY",
    "city": "Schenevus"
  },
  {
    "value": "12156",
    "label": "Schodack Landing - NY 12156",
    "county": "Rensselaer County",
    "state": "NY",
    "city": "Schodack Landing"
  },
  {
    "value": "12157",
    "label": "Schoharie - NY 12157",
    "county": "Schoharie County",
    "state": "NY",
    "city": "Schoharie"
  },
  {
    "value": "12158",
    "label": "Selkirk - NY 12158",
    "county": "Albany County",
    "state": "NY",
    "city": "Selkirk"
  },
  {
    "value": "12159",
    "label": "Slingerlands - NY 12159",
    "county": "Albany County",
    "state": "NY",
    "city": "Slingerlands"
  },
  {
    "value": "12160",
    "label": "Sloansville - NY 12160",
    "county": "Schoharie County",
    "state": "NY",
    "city": "Sloansville"
  },
  {
    "value": "12164",
    "label": "Speculator - NY 12164",
    "county": "Hamilton County",
    "state": "NY",
    "city": "Speculator"
  },
  {
    "value": "12165",
    "label": "Spencertown - NY 12165",
    "county": "Columbia County",
    "state": "NY",
    "city": "Spencertown"
  },
  {
    "value": "12166",
    "label": "Sprakers - NY 12166",
    "county": "Montgomery County",
    "state": "NY",
    "city": "Sprakers"
  },
  {
    "value": "12167",
    "label": "Stamford - NY 12167",
    "county": "Delaware County",
    "state": "NY",
    "city": "Stamford"
  },
  {
    "value": "12168",
    "label": "Stephentown - NY 12168",
    "county": "Rensselaer County",
    "state": "NY",
    "city": "Stephentown"
  },
  {
    "value": "12169",
    "label": "Stephentown - NY 12169",
    "county": "Rensselaer County",
    "state": "NY",
    "city": "Stephentown"
  },
  {
    "value": "12170",
    "label": "Stillwater - NY 12170",
    "county": "Saratoga County",
    "state": "NY",
    "city": "Stillwater"
  },
  {
    "value": "12173",
    "label": "Stuyvesant - NY 12173",
    "county": "Columbia County",
    "state": "NY",
    "city": "Stuyvesant"
  },
  {
    "value": "12175",
    "label": "Summit - NY 12175",
    "county": "Schoharie County",
    "state": "NY",
    "city": "Summit"
  },
  {
    "value": "12176",
    "label": "Surprise - NY 12176",
    "county": "Greene County",
    "state": "NY",
    "city": "Surprise"
  },
  {
    "value": "12180",
    "label": "Troy - NY 12180",
    "county": "Rensselaer County",
    "state": "NY",
    "city": "Troy"
  },
  {
    "value": "12182",
    "label": "Troy - NY 12182",
    "county": "Rensselaer County",
    "state": "NY",
    "city": "Troy"
  },
  {
    "value": "12183",
    "label": "Troy - NY 12183",
    "county": "Albany County",
    "state": "NY",
    "city": "Troy"
  },
  {
    "value": "12184",
    "label": "Valatie - NY 12184",
    "county": "Columbia County",
    "state": "NY",
    "city": "Valatie"
  },
  {
    "value": "12185",
    "label": "Valley Falls - NY 12185",
    "county": "Rensselaer County",
    "state": "NY",
    "city": "Valley Falls"
  },
  {
    "value": "12186",
    "label": "Voorheesville - NY 12186",
    "county": "Albany County",
    "state": "NY",
    "city": "Voorheesville"
  },
  {
    "value": "12187",
    "label": "Warnerville - NY 12187",
    "county": "Schoharie County",
    "state": "NY",
    "city": "Warnerville"
  },
  {
    "value": "12188",
    "label": "Waterford - NY 12188",
    "county": "Saratoga County",
    "state": "NY",
    "city": "Waterford"
  },
  {
    "value": "12189",
    "label": "Watervliet - NY 12189",
    "county": "Albany County",
    "state": "NY",
    "city": "Watervliet"
  },
  {
    "value": "12190",
    "label": "Wells - NY 12190",
    "county": "Hamilton County",
    "state": "NY",
    "city": "Wells"
  },
  {
    "value": "12192",
    "label": "West Coxsackie - NY 12192",
    "county": "Greene County",
    "state": "NY",
    "city": "West Coxsackie"
  },
  {
    "value": "12193",
    "label": "Westerlo - NY 12193",
    "county": "Albany County",
    "state": "NY",
    "city": "Westerlo"
  },
  {
    "value": "12194",
    "label": "West Fulton - NY 12194",
    "county": "Schoharie County",
    "state": "NY",
    "city": "West Fulton"
  },
  {
    "value": "12196",
    "label": "West Sand Lake - NY 12196",
    "county": "Rensselaer County",
    "state": "NY",
    "city": "West Sand Lake"
  },
  {
    "value": "12197",
    "label": "Worcester - NY 12197",
    "county": "Otsego County",
    "state": "NY",
    "city": "Worcester"
  },
  {
    "value": "12198",
    "label": "Wynantskill - NY 12198",
    "county": "Rensselaer County",
    "state": "NY",
    "city": "Wynantskill"
  },
  {
    "value": "12202",
    "label": "Albany - NY 12202",
    "county": "Albany County",
    "state": "NY",
    "city": "Albany"
  },
  {
    "value": "12203",
    "label": "Albany - NY 12203",
    "county": "Albany County",
    "state": "NY",
    "city": "Albany"
  },
  {
    "value": "12204",
    "label": "Albany - NY 12204",
    "county": "Albany County",
    "state": "NY",
    "city": "Albany"
  },
  {
    "value": "12205",
    "label": "Albany - NY 12205",
    "county": "Albany County",
    "state": "NY",
    "city": "Albany"
  },
  {
    "value": "12206",
    "label": "Albany - NY 12206",
    "county": "Albany County",
    "state": "NY",
    "city": "Albany"
  },
  {
    "value": "12207",
    "label": "Albany - NY 12207",
    "county": "Albany County",
    "state": "NY",
    "city": "Albany"
  },
  {
    "value": "12208",
    "label": "Albany - NY 12208",
    "county": "Albany County",
    "state": "NY",
    "city": "Albany"
  },
  {
    "value": "12209",
    "label": "Albany - NY 12209",
    "county": "Albany County",
    "state": "NY",
    "city": "Albany"
  },
  {
    "value": "12210",
    "label": "Albany - NY 12210",
    "county": "Albany County",
    "state": "NY",
    "city": "Albany"
  },
  {
    "value": "12211",
    "label": "Albany - NY 12211",
    "county": "Albany County",
    "state": "NY",
    "city": "Albany"
  },
  {
    "value": "12223",
    "label": "Albany - NY 12223",
    "county": "Albany County",
    "state": "NY",
    "city": "Albany"
  },
  {
    "value": "12226",
    "label": "Albany - NY 12226",
    "county": "Albany County",
    "state": "NY",
    "city": "Albany"
  },
  {
    "value": "12260",
    "label": "Albany - NY 12260",
    "county": "Albany County",
    "state": "NY",
    "city": "Albany"
  },
  {
    "value": "12302",
    "label": "Schenectady - NY 12302",
    "county": "Schenectady County",
    "state": "NY",
    "city": "Schenectady"
  },
  {
    "value": "12303",
    "label": "Schenectady - NY 12303",
    "county": "Schenectady County",
    "state": "NY",
    "city": "Schenectady"
  },
  {
    "value": "12304",
    "label": "Schenectady - NY 12304",
    "county": "Schenectady County",
    "state": "NY",
    "city": "Schenectady"
  },
  {
    "value": "12305",
    "label": "Schenectady - NY 12305",
    "county": "Schenectady County",
    "state": "NY",
    "city": "Schenectady"
  },
  {
    "value": "12306",
    "label": "Schenectady - NY 12306",
    "county": "Schenectady County",
    "state": "NY",
    "city": "Schenectady"
  },
  {
    "value": "12307",
    "label": "Schenectady - NY 12307",
    "county": "Schenectady County",
    "state": "NY",
    "city": "Schenectady"
  },
  {
    "value": "12308",
    "label": "Schenectady - NY 12308",
    "county": "Schenectady County",
    "state": "NY",
    "city": "Schenectady"
  },
  {
    "value": "12309",
    "label": "Schenectady - NY 12309",
    "county": "Schenectady County",
    "state": "NY",
    "city": "Schenectady"
  },
  {
    "value": "12401",
    "label": "Kingston - NY 12401",
    "county": "Ulster County",
    "state": "NY",
    "city": "Kingston"
  },
  {
    "value": "12404",
    "label": "Accord - NY 12404",
    "county": "Ulster County",
    "state": "NY",
    "city": "Accord"
  },
  {
    "value": "12405",
    "label": "Acra - NY 12405",
    "county": "Greene County",
    "state": "NY",
    "city": "Acra"
  },
  {
    "value": "12406",
    "label": "Arkville - NY 12406",
    "county": "Delaware County",
    "state": "NY",
    "city": "Arkville"
  },
  {
    "value": "12407",
    "label": "Ashland - NY 12407",
    "county": "Greene County",
    "state": "NY",
    "city": "Ashland"
  },
  {
    "value": "12409",
    "label": "Bearsville - NY 12409",
    "county": "Ulster County",
    "state": "NY",
    "city": "Bearsville"
  },
  {
    "value": "12410",
    "label": "Big Indian - NY 12410",
    "county": "Ulster County",
    "state": "NY",
    "city": "Big Indian"
  },
  {
    "value": "12411",
    "label": "Bloomington - NY 12411",
    "county": "Ulster County",
    "state": "NY",
    "city": "Bloomington"
  },
  {
    "value": "12412",
    "label": "Boiceville - NY 12412",
    "county": "Ulster County",
    "state": "NY",
    "city": "Boiceville"
  },
  {
    "value": "12413",
    "label": "Cairo - NY 12413",
    "county": "Greene County",
    "state": "NY",
    "city": "Cairo"
  },
  {
    "value": "12414",
    "label": "Catskill - NY 12414",
    "county": "Greene County",
    "state": "NY",
    "city": "Catskill"
  },
  {
    "value": "12416",
    "label": "Chichester - NY 12416",
    "county": "Ulster County",
    "state": "NY",
    "city": "Chichester"
  },
  {
    "value": "12418",
    "label": "Cornwallville - NY 12418",
    "county": "Greene County",
    "state": "NY",
    "city": "Cornwallville"
  },
  {
    "value": "12419",
    "label": "Cottekill - NY 12419",
    "county": "Ulster County",
    "state": "NY",
    "city": "Cottekill"
  },
  {
    "value": "12421",
    "label": "Denver - NY 12421",
    "county": "Delaware County",
    "state": "NY",
    "city": "Denver"
  },
  {
    "value": "12422",
    "label": "Durham - NY 12422",
    "county": "Greene County",
    "state": "NY",
    "city": "Durham"
  },
  {
    "value": "12423",
    "label": "East Durham - NY 12423",
    "county": "Greene County",
    "state": "NY",
    "city": "East Durham"
  },
  {
    "value": "12424",
    "label": "East Jewett - NY 12424",
    "county": "Greene County",
    "state": "NY",
    "city": "East Jewett"
  },
  {
    "value": "12427",
    "label": "Elka Park - NY 12427",
    "county": "Greene County",
    "state": "NY",
    "city": "Elka Park"
  },
  {
    "value": "12428",
    "label": "Ellenville - NY 12428",
    "county": "Ulster County",
    "state": "NY",
    "city": "Ellenville"
  },
  {
    "value": "12430",
    "label": "Fleischmanns - NY 12430",
    "county": "Delaware County",
    "state": "NY",
    "city": "Fleischmanns"
  },
  {
    "value": "12431",
    "label": "Freehold - NY 12431",
    "county": "Greene County",
    "state": "NY",
    "city": "Freehold"
  },
  {
    "value": "12433",
    "label": "Glenford - NY 12433",
    "county": "Ulster County",
    "state": "NY",
    "city": "Glenford"
  },
  {
    "value": "12434",
    "label": "Grand Gorge - NY 12434",
    "county": "Delaware County",
    "state": "NY",
    "city": "Grand Gorge"
  },
  {
    "value": "12435",
    "label": "Greenfield Park - NY 12435",
    "county": "Ulster County",
    "state": "NY",
    "city": "Greenfield Park"
  },
  {
    "value": "12439",
    "label": "Hensonville - NY 12439",
    "county": "Greene County",
    "state": "NY",
    "city": "Hensonville"
  },
  {
    "value": "12440",
    "label": "High Falls - NY 12440",
    "county": "Ulster County",
    "state": "NY",
    "city": "High Falls"
  },
  {
    "value": "12442",
    "label": "Hunter - NY 12442",
    "county": "Greene County",
    "state": "NY",
    "city": "Hunter"
  },
  {
    "value": "12443",
    "label": "Hurley - NY 12443",
    "county": "Ulster County",
    "state": "NY",
    "city": "Hurley"
  },
  {
    "value": "12444",
    "label": "Jewett - NY 12444",
    "county": "Greene County",
    "state": "NY",
    "city": "Jewett"
  },
  {
    "value": "12446",
    "label": "Kerhonkson - NY 12446",
    "county": "Ulster County",
    "state": "NY",
    "city": "Kerhonkson"
  },
  {
    "value": "12448",
    "label": "Lake Hill - NY 12448",
    "county": "Ulster County",
    "state": "NY",
    "city": "Lake Hill"
  },
  {
    "value": "12449",
    "label": "Lake Katrine - NY 12449",
    "county": "Ulster County",
    "state": "NY",
    "city": "Lake Katrine"
  },
  {
    "value": "12450",
    "label": "Lanesville - NY 12450",
    "county": "Greene County",
    "state": "NY",
    "city": "Lanesville"
  },
  {
    "value": "12451",
    "label": "Leeds - NY 12451",
    "county": "Greene County",
    "state": "NY",
    "city": "Leeds"
  },
  {
    "value": "12454",
    "label": "Maplecrest - NY 12454",
    "county": "Greene County",
    "state": "NY",
    "city": "Maplecrest"
  },
  {
    "value": "12455",
    "label": "Margaretville - NY 12455",
    "county": "Delaware County",
    "state": "NY",
    "city": "Margaretville"
  },
  {
    "value": "12456",
    "label": "Mount Marion - NY 12456",
    "county": "Ulster County",
    "state": "NY",
    "city": "Mount Marion"
  },
  {
    "value": "12457",
    "label": "Mount Tremper - NY 12457",
    "county": "Ulster County",
    "state": "NY",
    "city": "Mount Tremper"
  },
  {
    "value": "12458",
    "label": "Napanoch - NY 12458",
    "county": "Ulster County",
    "state": "NY",
    "city": "Napanoch"
  },
  {
    "value": "12460",
    "label": "Oak Hill - NY 12460",
    "county": "Greene County",
    "state": "NY",
    "city": "Oak Hill"
  },
  {
    "value": "12461",
    "label": "Olivebridge - NY 12461",
    "county": "Ulster County",
    "state": "NY",
    "city": "Olivebridge"
  },
  {
    "value": "12463",
    "label": "Palenville - NY 12463",
    "county": "Greene County",
    "state": "NY",
    "city": "Palenville"
  },
  {
    "value": "12464",
    "label": "Phoenicia - NY 12464",
    "county": "Ulster County",
    "state": "NY",
    "city": "Phoenicia"
  },
  {
    "value": "12465",
    "label": "Pine Hill - NY 12465",
    "county": "Ulster County",
    "state": "NY",
    "city": "Pine Hill"
  },
  {
    "value": "12466",
    "label": "Port Ewen - NY 12466",
    "county": "Ulster County",
    "state": "NY",
    "city": "Port Ewen"
  },
  {
    "value": "12468",
    "label": "Prattsville - NY 12468",
    "county": "Greene County",
    "state": "NY",
    "city": "Prattsville"
  },
  {
    "value": "12469",
    "label": "Preston Hollow - NY 12469",
    "county": "Albany County",
    "state": "NY",
    "city": "Preston Hollow"
  },
  {
    "value": "12470",
    "label": "Purling - NY 12470",
    "county": "Greene County",
    "state": "NY",
    "city": "Purling"
  },
  {
    "value": "12472",
    "label": "Rosendale - NY 12472",
    "county": "Ulster County",
    "state": "NY",
    "city": "Rosendale"
  },
  {
    "value": "12473",
    "label": "Round Top - NY 12473",
    "county": "Greene County",
    "state": "NY",
    "city": "Round Top"
  },
  {
    "value": "12474",
    "label": "Roxbury - NY 12474",
    "county": "Delaware County",
    "state": "NY",
    "city": "Roxbury"
  },
  {
    "value": "12477",
    "label": "Saugerties - NY 12477",
    "county": "Ulster County",
    "state": "NY",
    "city": "Saugerties"
  },
  {
    "value": "12480",
    "label": "Shandaken - NY 12480",
    "county": "Ulster County",
    "state": "NY",
    "city": "Shandaken"
  },
  {
    "value": "12481",
    "label": "Shokan - NY 12481",
    "county": "Ulster County",
    "state": "NY",
    "city": "Shokan"
  },
  {
    "value": "12482",
    "label": "South Cairo - NY 12482",
    "county": "Greene County",
    "state": "NY",
    "city": "South Cairo"
  },
  {
    "value": "12484",
    "label": "Stone Ridge - NY 12484",
    "county": "Ulster County",
    "state": "NY",
    "city": "Stone Ridge"
  },
  {
    "value": "12485",
    "label": "Tannersville - NY 12485",
    "county": "Greene County",
    "state": "NY",
    "city": "Tannersville"
  },
  {
    "value": "12486",
    "label": "Tillson - NY 12486",
    "county": "Ulster County",
    "state": "NY",
    "city": "Tillson"
  },
  {
    "value": "12487",
    "label": "Ulster Park - NY 12487",
    "county": "Ulster County",
    "state": "NY",
    "city": "Ulster Park"
  },
  {
    "value": "12491",
    "label": "West Hurley - NY 12491",
    "county": "Ulster County",
    "state": "NY",
    "city": "West Hurley"
  },
  {
    "value": "12492",
    "label": "West Kill - NY 12492",
    "county": "Greene County",
    "state": "NY",
    "city": "West Kill"
  },
  {
    "value": "12494",
    "label": "West Shokan - NY 12494",
    "county": "Ulster County",
    "state": "NY",
    "city": "West Shokan"
  },
  {
    "value": "12495",
    "label": "Willow - NY 12495",
    "county": "Ulster County",
    "state": "NY",
    "city": "Willow"
  },
  {
    "value": "12496",
    "label": "Windham - NY 12496",
    "county": "Greene County",
    "state": "NY",
    "city": "Windham"
  },
  {
    "value": "12498",
    "label": "Woodstock - NY 12498",
    "county": "Ulster County",
    "state": "NY",
    "city": "Woodstock"
  },
  {
    "value": "12501",
    "label": "Amenia - NY 12501",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Amenia"
  },
  {
    "value": "12502",
    "label": "Ancram - NY 12502",
    "county": "Columbia County",
    "state": "NY",
    "city": "Ancram"
  },
  {
    "value": "12503",
    "label": "Ancramdale - NY 12503",
    "county": "Columbia County",
    "state": "NY",
    "city": "Ancramdale"
  },
  {
    "value": "12507",
    "label": "Barrytown - NY 12507",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Barrytown"
  },
  {
    "value": "12508",
    "label": "Beacon - NY 12508",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Beacon"
  },
  {
    "value": "12513",
    "label": "Claverack - NY 12513",
    "county": "Columbia County",
    "state": "NY",
    "city": "Claverack"
  },
  {
    "value": "12514",
    "label": "Clinton Corners - NY 12514",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Clinton Corners"
  },
  {
    "value": "12515",
    "label": "Clintondale - NY 12515",
    "county": "Ulster County",
    "state": "NY",
    "city": "Clintondale"
  },
  {
    "value": "12516",
    "label": "Copake - NY 12516",
    "county": "Columbia County",
    "state": "NY",
    "city": "Copake"
  },
  {
    "value": "12517",
    "label": "Copake Falls - NY 12517",
    "county": "Columbia County",
    "state": "NY",
    "city": "Copake Falls"
  },
  {
    "value": "12518",
    "label": "Cornwall - NY 12518",
    "county": "Orange County",
    "state": "NY",
    "city": "Cornwall"
  },
  {
    "value": "12520",
    "label": "Cornwall On Hudson - NY 12520",
    "county": "Orange County",
    "state": "NY",
    "city": "Cornwall On Hudson"
  },
  {
    "value": "12521",
    "label": "Craryville - NY 12521",
    "county": "Columbia County",
    "state": "NY",
    "city": "Craryville"
  },
  {
    "value": "12522",
    "label": "Dover Plains - NY 12522",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Dover Plains"
  },
  {
    "value": "12523",
    "label": "Elizaville - NY 12523",
    "county": "Columbia County",
    "state": "NY",
    "city": "Elizaville"
  },
  {
    "value": "12524",
    "label": "Fishkill - NY 12524",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Fishkill"
  },
  {
    "value": "12525",
    "label": "Gardiner - NY 12525",
    "county": "Ulster County",
    "state": "NY",
    "city": "Gardiner"
  },
  {
    "value": "12526",
    "label": "Germantown - NY 12526",
    "county": "Columbia County",
    "state": "NY",
    "city": "Germantown"
  },
  {
    "value": "12528",
    "label": "Highland - NY 12528",
    "county": "Ulster County",
    "state": "NY",
    "city": "Highland"
  },
  {
    "value": "12529",
    "label": "Hillsdale - NY 12529",
    "county": "Columbia County",
    "state": "NY",
    "city": "Hillsdale"
  },
  {
    "value": "12531",
    "label": "Holmes - NY 12531",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Holmes"
  },
  {
    "value": "12533",
    "label": "Hopewell Junction - NY 12533",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Hopewell Junction"
  },
  {
    "value": "12534",
    "label": "Hudson - NY 12534",
    "county": "Columbia County",
    "state": "NY",
    "city": "Hudson"
  },
  {
    "value": "12538",
    "label": "Hyde Park - NY 12538",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Hyde Park"
  },
  {
    "value": "12540",
    "label": "Lagrangeville - NY 12540",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Lagrangeville"
  },
  {
    "value": "12542",
    "label": "Marlboro - NY 12542",
    "county": "Ulster County",
    "state": "NY",
    "city": "Marlboro"
  },
  {
    "value": "12543",
    "label": "Maybrook - NY 12543",
    "county": "Orange County",
    "state": "NY",
    "city": "Maybrook"
  },
  {
    "value": "12545",
    "label": "Millbrook - NY 12545",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Millbrook"
  },
  {
    "value": "12546",
    "label": "Millerton - NY 12546",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Millerton"
  },
  {
    "value": "12547",
    "label": "Milton - NY 12547",
    "county": "Ulster County",
    "state": "NY",
    "city": "Milton"
  },
  {
    "value": "12548",
    "label": "Modena - NY 12548",
    "county": "Ulster County",
    "state": "NY",
    "city": "Modena"
  },
  {
    "value": "12549",
    "label": "Montgomery - NY 12549",
    "county": "Orange County",
    "state": "NY",
    "city": "Montgomery"
  },
  {
    "value": "12550",
    "label": "Newburgh - NY 12550",
    "county": "Orange County",
    "state": "NY",
    "city": "Newburgh"
  },
  {
    "value": "12553",
    "label": "New Windsor - NY 12553",
    "county": "Orange County",
    "state": "NY",
    "city": "New Windsor"
  },
  {
    "value": "12561",
    "label": "New Paltz - NY 12561",
    "county": "Ulster County",
    "state": "NY",
    "city": "New Paltz"
  },
  {
    "value": "12563",
    "label": "Patterson - NY 12563",
    "county": "Putnam County",
    "state": "NY",
    "city": "Patterson"
  },
  {
    "value": "12564",
    "label": "Pawling - NY 12564",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Pawling"
  },
  {
    "value": "12565",
    "label": "Philmont - NY 12565",
    "county": "Columbia County",
    "state": "NY",
    "city": "Philmont"
  },
  {
    "value": "12566",
    "label": "Pine Bush - NY 12566",
    "county": "Ulster County",
    "state": "NY",
    "city": "Pine Bush"
  },
  {
    "value": "12567",
    "label": "Pine Plains - NY 12567",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Pine Plains"
  },
  {
    "value": "12569",
    "label": "Pleasant Valley - NY 12569",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Pleasant Valley"
  },
  {
    "value": "12570",
    "label": "Poughquag - NY 12570",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Poughquag"
  },
  {
    "value": "12571",
    "label": "Red Hook - NY 12571",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Red Hook"
  },
  {
    "value": "12572",
    "label": "Rhinebeck - NY 12572",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Rhinebeck"
  },
  {
    "value": "12575",
    "label": "Rock Tavern - NY 12575",
    "county": "Orange County",
    "state": "NY",
    "city": "Rock Tavern"
  },
  {
    "value": "12577",
    "label": "Salisbury Mills - NY 12577",
    "county": "Orange County",
    "state": "NY",
    "city": "Salisbury Mills"
  },
  {
    "value": "12578",
    "label": "Salt Point - NY 12578",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Salt Point"
  },
  {
    "value": "12580",
    "label": "Staatsburg - NY 12580",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Staatsburg"
  },
  {
    "value": "12581",
    "label": "Stanfordville - NY 12581",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Stanfordville"
  },
  {
    "value": "12582",
    "label": "Stormville - NY 12582",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Stormville"
  },
  {
    "value": "12583",
    "label": "Tivoli - NY 12583",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Tivoli"
  },
  {
    "value": "12585",
    "label": "Verbank - NY 12585",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Verbank"
  },
  {
    "value": "12586",
    "label": "Walden - NY 12586",
    "county": "Orange County",
    "state": "NY",
    "city": "Walden"
  },
  {
    "value": "12589",
    "label": "Wallkill - NY 12589",
    "county": "Ulster County",
    "state": "NY",
    "city": "Wallkill"
  },
  {
    "value": "12590",
    "label": "Wappingers Falls - NY 12590",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Wappingers Falls"
  },
  {
    "value": "12592",
    "label": "Wassaic - NY 12592",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Wassaic"
  },
  {
    "value": "12593",
    "label": "West Copake - NY 12593",
    "county": "Columbia County",
    "state": "NY",
    "city": "West Copake"
  },
  {
    "value": "12594",
    "label": "Wingdale - NY 12594",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Wingdale"
  },
  {
    "value": "12601",
    "label": "Poughkeepsie - NY 12601",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Poughkeepsie"
  },
  {
    "value": "12603",
    "label": "Poughkeepsie - NY 12603",
    "county": "Dutchess County",
    "state": "NY",
    "city": "Poughkeepsie"
  },
  {
    "value": "12701",
    "label": "Monticello - NY 12701",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Monticello"
  },
  {
    "value": "12719",
    "label": "Barryville - NY 12719",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Barryville"
  },
  {
    "value": "12720",
    "label": "Bethel - NY 12720",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Bethel"
  },
  {
    "value": "12721",
    "label": "Bloomingburg - NY 12721",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Bloomingburg"
  },
  {
    "value": "12723",
    "label": "Callicoon - NY 12723",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Callicoon"
  },
  {
    "value": "12725",
    "label": "Claryville - NY 12725",
    "county": "Ulster County",
    "state": "NY",
    "city": "Claryville"
  },
  {
    "value": "12726",
    "label": "Cochecton - NY 12726",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Cochecton"
  },
  {
    "value": "12727",
    "label": "Cochecton - NY 12727",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Cochecton"
  },
  {
    "value": "12729",
    "label": "Cuddebackville - NY 12729",
    "county": "Orange County",
    "state": "NY",
    "city": "Cuddebackville"
  },
  {
    "value": "12732",
    "label": "Eldred - NY 12732",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Eldred"
  },
  {
    "value": "12733",
    "label": "Fallsburg - NY 12733",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Fallsburg"
  },
  {
    "value": "12734",
    "label": "Ferndale - NY 12734",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Ferndale"
  },
  {
    "value": "12736",
    "label": "Fremont Center - NY 12736",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Fremont Center"
  },
  {
    "value": "12737",
    "label": "Glen Spey - NY 12737",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Glen Spey"
  },
  {
    "value": "12738",
    "label": "Glen Wild - NY 12738",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Glen Wild"
  },
  {
    "value": "12740",
    "label": "Grahamsville - NY 12740",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Grahamsville"
  },
  {
    "value": "12741",
    "label": "Hankins - NY 12741",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Hankins"
  },
  {
    "value": "12742",
    "label": "Harris - NY 12742",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Harris"
  },
  {
    "value": "12743",
    "label": "Highland Lake - NY 12743",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Highland Lake"
  },
  {
    "value": "12745",
    "label": "Hortonville - NY 12745",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Hortonville"
  },
  {
    "value": "12746",
    "label": "Huguenot - NY 12746",
    "county": "Orange County",
    "state": "NY",
    "city": "Huguenot"
  },
  {
    "value": "12747",
    "label": "Hurleyville - NY 12747",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Hurleyville"
  },
  {
    "value": "12748",
    "label": "Jeffersonville - NY 12748",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Jeffersonville"
  },
  {
    "value": "12750",
    "label": "Kenoza Lake - NY 12750",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Kenoza Lake"
  },
  {
    "value": "12751",
    "label": "Kiamesha Lake - NY 12751",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Kiamesha Lake"
  },
  {
    "value": "12752",
    "label": "Lake Huntington - NY 12752",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Lake Huntington"
  },
  {
    "value": "12754",
    "label": "Liberty - NY 12754",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Liberty"
  },
  {
    "value": "12758",
    "label": "Livingston Manor - NY 12758",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Livingston Manor"
  },
  {
    "value": "12759",
    "label": "Loch Sheldrake - NY 12759",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Loch Sheldrake"
  },
  {
    "value": "12760",
    "label": "Long Eddy - NY 12760",
    "county": "Delaware County",
    "state": "NY",
    "city": "Long Eddy"
  },
  {
    "value": "12762",
    "label": "Mongaup Valley - NY 12762",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Mongaup Valley"
  },
  {
    "value": "12763",
    "label": "Mountain Dale - NY 12763",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Mountain Dale"
  },
  {
    "value": "12764",
    "label": "Narrowsburg - NY 12764",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Narrowsburg"
  },
  {
    "value": "12765",
    "label": "Neversink - NY 12765",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Neversink"
  },
  {
    "value": "12766",
    "label": "North Branch - NY 12766",
    "county": "Sullivan County",
    "state": "NY",
    "city": "North Branch"
  },
  {
    "value": "12768",
    "label": "Parksville - NY 12768",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Parksville"
  },
  {
    "value": "12770",
    "label": "Pond Eddy - NY 12770",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Pond Eddy"
  },
  {
    "value": "12771",
    "label": "Port Jervis - NY 12771",
    "county": "Orange County",
    "state": "NY",
    "city": "Port Jervis"
  },
  {
    "value": "12775",
    "label": "Rock Hill - NY 12775",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Rock Hill"
  },
  {
    "value": "12776",
    "label": "Roscoe - NY 12776",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Roscoe"
  },
  {
    "value": "12777",
    "label": "Forestburgh - NY 12777",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Forestburgh"
  },
  {
    "value": "12779",
    "label": "South Fallsburg - NY 12779",
    "county": "Sullivan County",
    "state": "NY",
    "city": "South Fallsburg"
  },
  {
    "value": "12780",
    "label": "Sparrow Bush - NY 12780",
    "county": "Orange County",
    "state": "NY",
    "city": "Sparrow Bush"
  },
  {
    "value": "12783",
    "label": "Swan Lake - NY 12783",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Swan Lake"
  },
  {
    "value": "12786",
    "label": "White Lake - NY 12786",
    "county": "Sullivan County",
    "state": "NY",
    "city": "White Lake"
  },
  {
    "value": "12787",
    "label": "White Sulphur Springs - NY 12787",
    "county": "Sullivan County",
    "state": "NY",
    "city": "White Sulphur Springs"
  },
  {
    "value": "12788",
    "label": "Woodbourne - NY 12788",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Woodbourne"
  },
  {
    "value": "12789",
    "label": "Woodridge - NY 12789",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Woodridge"
  },
  {
    "value": "12790",
    "label": "Wurtsboro - NY 12790",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Wurtsboro"
  },
  {
    "value": "12791",
    "label": "Youngsville - NY 12791",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Youngsville"
  },
  {
    "value": "12792",
    "label": "Yulan - NY 12792",
    "county": "Sullivan County",
    "state": "NY",
    "city": "Yulan"
  },
  {
    "value": "12801",
    "label": "Glens Falls - NY 12801",
    "county": "Warren County",
    "state": "NY",
    "city": "Glens Falls"
  },
  {
    "value": "12803",
    "label": "South Glens Falls - NY 12803",
    "county": "Saratoga County",
    "state": "NY",
    "city": "South Glens Falls"
  },
  {
    "value": "12804",
    "label": "Queensbury - NY 12804",
    "county": "Warren County",
    "state": "NY",
    "city": "Queensbury"
  },
  {
    "value": "12808",
    "label": "Adirondack - NY 12808",
    "county": "Warren County",
    "state": "NY",
    "city": "Adirondack"
  },
  {
    "value": "12809",
    "label": "Argyle - NY 12809",
    "county": "Washington County",
    "state": "NY",
    "city": "Argyle"
  },
  {
    "value": "12810",
    "label": "Athol - NY 12810",
    "county": "Warren County",
    "state": "NY",
    "city": "Athol"
  },
  {
    "value": "12812",
    "label": "Blue Mountain Lake - NY 12812",
    "county": "Hamilton County",
    "state": "NY",
    "city": "Blue Mountain Lake"
  },
  {
    "value": "12814",
    "label": "Bolton Landing - NY 12814",
    "county": "Warren County",
    "state": "NY",
    "city": "Bolton Landing"
  },
  {
    "value": "12815",
    "label": "Brant Lake - NY 12815",
    "county": "Warren County",
    "state": "NY",
    "city": "Brant Lake"
  },
  {
    "value": "12816",
    "label": "Cambridge - NY 12816",
    "county": "Washington County",
    "state": "NY",
    "city": "Cambridge"
  },
  {
    "value": "12817",
    "label": "Chestertown - NY 12817",
    "county": "Warren County",
    "state": "NY",
    "city": "Chestertown"
  },
  {
    "value": "12819",
    "label": "Clemons - NY 12819",
    "county": "Washington County",
    "state": "NY",
    "city": "Clemons"
  },
  {
    "value": "12821",
    "label": "Comstock - NY 12821",
    "county": "Washington County",
    "state": "NY",
    "city": "Comstock"
  },
  {
    "value": "12822",
    "label": "Corinth - NY 12822",
    "county": "Saratoga County",
    "state": "NY",
    "city": "Corinth"
  },
  {
    "value": "12823",
    "label": "Cossayuna - NY 12823",
    "county": "Washington County",
    "state": "NY",
    "city": "Cossayuna"
  },
  {
    "value": "12824",
    "label": "Diamond Point - NY 12824",
    "county": "Warren County",
    "state": "NY",
    "city": "Diamond Point"
  },
  {
    "value": "12827",
    "label": "Fort Ann - NY 12827",
    "county": "Washington County",
    "state": "NY",
    "city": "Fort Ann"
  },
  {
    "value": "12828",
    "label": "Fort Edward - NY 12828",
    "county": "Washington County",
    "state": "NY",
    "city": "Fort Edward"
  },
  {
    "value": "12831",
    "label": "Gansevoort - NY 12831",
    "county": "Saratoga County",
    "state": "NY",
    "city": "Gansevoort"
  },
  {
    "value": "12832",
    "label": "Granville - NY 12832",
    "county": "Washington County",
    "state": "NY",
    "city": "Granville"
  },
  {
    "value": "12833",
    "label": "Greenfield Center - NY 12833",
    "county": "Saratoga County",
    "state": "NY",
    "city": "Greenfield Center"
  },
  {
    "value": "12834",
    "label": "Greenwich - NY 12834",
    "county": "Washington County",
    "state": "NY",
    "city": "Greenwich"
  },
  {
    "value": "12835",
    "label": "Hadley - NY 12835",
    "county": "Saratoga County",
    "state": "NY",
    "city": "Hadley"
  },
  {
    "value": "12836",
    "label": "Hague - NY 12836",
    "county": "Warren County",
    "state": "NY",
    "city": "Hague"
  },
  {
    "value": "12837",
    "label": "Hampton - NY 12837",
    "county": "Washington County",
    "state": "NY",
    "city": "Hampton"
  },
  {
    "value": "12838",
    "label": "Hartford - NY 12838",
    "county": "Washington County",
    "state": "NY",
    "city": "Hartford"
  },
  {
    "value": "12839",
    "label": "Hudson Falls - NY 12839",
    "county": "Washington County",
    "state": "NY",
    "city": "Hudson Falls"
  },
  {
    "value": "12842",
    "label": "Indian Lake - NY 12842",
    "county": "Hamilton County",
    "state": "NY",
    "city": "Indian Lake"
  },
  {
    "value": "12843",
    "label": "Johnsburg - NY 12843",
    "county": "Warren County",
    "state": "NY",
    "city": "Johnsburg"
  },
  {
    "value": "12844",
    "label": "Kattskill Bay - NY 12844",
    "county": "Washington County",
    "state": "NY",
    "city": "Kattskill Bay"
  },
  {
    "value": "12845",
    "label": "Lake George - NY 12845",
    "county": "Warren County",
    "state": "NY",
    "city": "Lake George"
  },
  {
    "value": "12846",
    "label": "Lake Luzerne - NY 12846",
    "county": "Warren County",
    "state": "NY",
    "city": "Lake Luzerne"
  },
  {
    "value": "12847",
    "label": "Long Lake - NY 12847",
    "county": "Hamilton County",
    "state": "NY",
    "city": "Long Lake"
  },
  {
    "value": "12849",
    "label": "Middle Granville - NY 12849",
    "county": "Washington County",
    "state": "NY",
    "city": "Middle Granville"
  },
  {
    "value": "12850",
    "label": "Middle Grove - NY 12850",
    "county": "Saratoga County",
    "state": "NY",
    "city": "Middle Grove"
  },
  {
    "value": "12851",
    "label": "Minerva - NY 12851",
    "county": "Essex County",
    "state": "NY",
    "city": "Minerva"
  },
  {
    "value": "12852",
    "label": "Newcomb - NY 12852",
    "county": "Essex County",
    "state": "NY",
    "city": "Newcomb"
  },
  {
    "value": "12853",
    "label": "North Creek - NY 12853",
    "county": "Warren County",
    "state": "NY",
    "city": "North Creek"
  },
  {
    "value": "12854",
    "label": "North Granville - NY 12854",
    "county": "Washington County",
    "state": "NY",
    "city": "North Granville"
  },
  {
    "value": "12855",
    "label": "North Hudson - NY 12855",
    "county": "Essex County",
    "state": "NY",
    "city": "North Hudson"
  },
  {
    "value": "12857",
    "label": "Olmstedville - NY 12857",
    "county": "Essex County",
    "state": "NY",
    "city": "Olmstedville"
  },
  {
    "value": "12858",
    "label": "Paradox - NY 12858",
    "county": "Essex County",
    "state": "NY",
    "city": "Paradox"
  },
  {
    "value": "12859",
    "label": "Porter Corners - NY 12859",
    "county": "Saratoga County",
    "state": "NY",
    "city": "Porter Corners"
  },
  {
    "value": "12860",
    "label": "Pottersville - NY 12860",
    "county": "Warren County",
    "state": "NY",
    "city": "Pottersville"
  },
  {
    "value": "12861",
    "label": "Putnam Station - NY 12861",
    "county": "Washington County",
    "state": "NY",
    "city": "Putnam Station"
  },
  {
    "value": "12863",
    "label": "Rock City Falls - NY 12863",
    "county": "Saratoga County",
    "state": "NY",
    "city": "Rock City Falls"
  },
  {
    "value": "12864",
    "label": "Sabael - NY 12864",
    "county": "Hamilton County",
    "state": "NY",
    "city": "Sabael"
  },
  {
    "value": "12865",
    "label": "Salem - NY 12865",
    "county": "Washington County",
    "state": "NY",
    "city": "Salem"
  },
  {
    "value": "12866",
    "label": "Saratoga Springs - NY 12866",
    "county": "Saratoga County",
    "state": "NY",
    "city": "Saratoga Springs"
  },
  {
    "value": "12870",
    "label": "Schroon Lake - NY 12870",
    "county": "Essex County",
    "state": "NY",
    "city": "Schroon Lake"
  },
  {
    "value": "12871",
    "label": "Schuylerville - NY 12871",
    "county": "Saratoga County",
    "state": "NY",
    "city": "Schuylerville"
  },
  {
    "value": "12873",
    "label": "Shushan - NY 12873",
    "county": "Washington County",
    "state": "NY",
    "city": "Shushan"
  },
  {
    "value": "12874",
    "label": "Silver Bay - NY 12874",
    "county": "Warren County",
    "state": "NY",
    "city": "Silver Bay"
  },
  {
    "value": "12878",
    "label": "Stony Creek - NY 12878",
    "county": "Warren County",
    "state": "NY",
    "city": "Stony Creek"
  },
  {
    "value": "12879",
    "label": "Newcomb - NY 12879",
    "county": "Essex County",
    "state": "NY",
    "city": "Newcomb"
  },
  {
    "value": "12883",
    "label": "Ticonderoga - NY 12883",
    "county": "Essex County",
    "state": "NY",
    "city": "Ticonderoga"
  },
  {
    "value": "12885",
    "label": "Warrensburg - NY 12885",
    "county": "Warren County",
    "state": "NY",
    "city": "Warrensburg"
  },
  {
    "value": "12886",
    "label": "Wevertown - NY 12886",
    "county": "Warren County",
    "state": "NY",
    "city": "Wevertown"
  },
  {
    "value": "12887",
    "label": "Whitehall - NY 12887",
    "county": "Washington County",
    "state": "NY",
    "city": "Whitehall"
  },
  {
    "value": "12901",
    "label": "Plattsburgh - NY 12901",
    "county": "Clinton County",
    "state": "NY",
    "city": "Plattsburgh"
  },
  {
    "value": "12903",
    "label": "Plattsburgh - NY 12903",
    "county": "Clinton County",
    "state": "NY",
    "city": "Plattsburgh"
  },
  {
    "value": "12910",
    "label": "Altona - NY 12910",
    "county": "Clinton County",
    "state": "NY",
    "city": "Altona"
  },
  {
    "value": "12911",
    "label": "Keeseville - NY 12911",
    "county": "Clinton County",
    "state": "NY",
    "city": "Keeseville"
  },
  {
    "value": "12912",
    "label": "Au Sable Forks - NY 12912",
    "county": "Clinton County",
    "state": "NY",
    "city": "Au Sable Forks"
  },
  {
    "value": "12913",
    "label": "Bloomingdale - NY 12913",
    "county": "Essex County",
    "state": "NY",
    "city": "Bloomingdale"
  },
  {
    "value": "12914",
    "label": "Bombay - NY 12914",
    "county": "Franklin County",
    "state": "NY",
    "city": "Bombay"
  },
  {
    "value": "12916",
    "label": "Brushton - NY 12916",
    "county": "Franklin County",
    "state": "NY",
    "city": "Brushton"
  },
  {
    "value": "12917",
    "label": "Burke - NY 12917",
    "county": "Franklin County",
    "state": "NY",
    "city": "Burke"
  },
  {
    "value": "12918",
    "label": "Cadyville - NY 12918",
    "county": "Clinton County",
    "state": "NY",
    "city": "Cadyville"
  },
  {
    "value": "12919",
    "label": "Champlain - NY 12919",
    "county": "Clinton County",
    "state": "NY",
    "city": "Champlain"
  },
  {
    "value": "12920",
    "label": "Chateaugay - NY 12920",
    "county": "Franklin County",
    "state": "NY",
    "city": "Chateaugay"
  },
  {
    "value": "12921",
    "label": "Chazy - NY 12921",
    "county": "Clinton County",
    "state": "NY",
    "city": "Chazy"
  },
  {
    "value": "12922",
    "label": "Childwold - NY 12922",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Childwold"
  },
  {
    "value": "12923",
    "label": "Churubusco - NY 12923",
    "county": "Clinton County",
    "state": "NY",
    "city": "Churubusco"
  },
  {
    "value": "12924",
    "label": "Keeseville - NY 12924",
    "county": "Clinton County",
    "state": "NY",
    "city": "Keeseville"
  },
  {
    "value": "12926",
    "label": "Constable - NY 12926",
    "county": "Franklin County",
    "state": "NY",
    "city": "Constable"
  },
  {
    "value": "12928",
    "label": "Crown Point - NY 12928",
    "county": "Essex County",
    "state": "NY",
    "city": "Crown Point"
  },
  {
    "value": "12930",
    "label": "Dickinson Center - NY 12930",
    "county": "Franklin County",
    "state": "NY",
    "city": "Dickinson Center"
  },
  {
    "value": "12932",
    "label": "Elizabethtown - NY 12932",
    "county": "Essex County",
    "state": "NY",
    "city": "Elizabethtown"
  },
  {
    "value": "12934",
    "label": "Ellenburg Center - NY 12934",
    "county": "Clinton County",
    "state": "NY",
    "city": "Ellenburg Center"
  },
  {
    "value": "12935",
    "label": "Ellenburg Depot - NY 12935",
    "county": "Clinton County",
    "state": "NY",
    "city": "Ellenburg Depot"
  },
  {
    "value": "12936",
    "label": "Essex - NY 12936",
    "county": "Essex County",
    "state": "NY",
    "city": "Essex"
  },
  {
    "value": "12937",
    "label": "Fort Covington - NY 12937",
    "county": "Franklin County",
    "state": "NY",
    "city": "Fort Covington"
  },
  {
    "value": "12941",
    "label": "Jay - NY 12941",
    "county": "Essex County",
    "state": "NY",
    "city": "Jay"
  },
  {
    "value": "12942",
    "label": "Keene - NY 12942",
    "county": "Essex County",
    "state": "NY",
    "city": "Keene"
  },
  {
    "value": "12943",
    "label": "Keene Valley - NY 12943",
    "county": "Essex County",
    "state": "NY",
    "city": "Keene Valley"
  },
  {
    "value": "12944",
    "label": "Keeseville - NY 12944",
    "county": "Essex County",
    "state": "NY",
    "city": "Keeseville"
  },
  {
    "value": "12945",
    "label": "Lake Clear - NY 12945",
    "county": "Franklin County",
    "state": "NY",
    "city": "Lake Clear"
  },
  {
    "value": "12946",
    "label": "Lake Placid - NY 12946",
    "county": "Essex County",
    "state": "NY",
    "city": "Lake Placid"
  },
  {
    "value": "12949",
    "label": "Lawrenceville - NY 12949",
    "county": "St Lawrence County",
    "state": "NY",
    "city": "Lawrenceville"
  },
  {
    "value": "12950",
    "label": "Lewis - NY 12950",
    "county": "Essex County",
    "state": "NY",
    "city": "Lewis"
  },
  {
    "value": "12952",
    "label": "Lyon Mountain - NY 12952",
    "county": "Clinton County",
    "state": "NY",
    "city": "Lyon Mountain"
  },
  {
    "value": "12953",
    "label": "Malone - NY 12953",
    "county": "Franklin County",
    "state": "NY",
    "city": "Malone"
  },
  {
    "value": "12955",
    "label": "Lyon Mountain - NY 12955",
    "county": "Clinton County",
    "state": "NY",
    "city": "Lyon Mountain"
  },
  {
    "value": "12956",
    "label": "Mineville - NY 12956",
    "county": "Essex County",
    "state": "NY",
    "city": "Mineville"
  },
  {
    "value": "12957",
    "label": "Moira - NY 12957",
    "county": "Franklin County",
    "state": "NY",
    "city": "Moira"
  },
  {
    "value": "12958",
    "label": "Mooers - NY 12958",
    "county": "Clinton County",
    "state": "NY",
    "city": "Mooers"
  },
  {
    "value": "12959",
    "label": "Mooers Forks - NY 12959",
    "county": "Clinton County",
    "state": "NY",
    "city": "Mooers Forks"
  },
  {
    "value": "12960",
    "label": "Moriah - NY 12960",
    "county": "Essex County",
    "state": "NY",
    "city": "Moriah"
  },
  {
    "value": "12961",
    "label": "Moriah Center - NY 12961",
    "county": "Essex County",
    "state": "NY",
    "city": "Moriah Center"
  },
  {
    "value": "12962",
    "label": "Morrisonville - NY 12962",
    "county": "Clinton County",
    "state": "NY",
    "city": "Morrisonville"
  },
  {
    "value": "12964",
    "label": "New Russia - NY 12964",
    "county": "Essex County",
    "state": "NY",
    "city": "New Russia"
  },
  {
    "value": "12965",
    "label": "Nicholville - NY 12965",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Nicholville"
  },
  {
    "value": "12966",
    "label": "North Bangor - NY 12966",
    "county": "Franklin County",
    "state": "NY",
    "city": "North Bangor"
  },
  {
    "value": "12967",
    "label": "North Lawrence - NY 12967",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "North Lawrence"
  },
  {
    "value": "12969",
    "label": "Owls Head - NY 12969",
    "county": "Franklin County",
    "state": "NY",
    "city": "Owls Head"
  },
  {
    "value": "12970",
    "label": "Paul Smiths - NY 12970",
    "county": "Franklin County",
    "state": "NY",
    "city": "Paul Smiths"
  },
  {
    "value": "12972",
    "label": "Peru - NY 12972",
    "county": "Clinton County",
    "state": "NY",
    "city": "Peru"
  },
  {
    "value": "12974",
    "label": "Port Henry - NY 12974",
    "county": "Essex County",
    "state": "NY",
    "city": "Port Henry"
  },
  {
    "value": "12978",
    "label": "Redford - NY 12978",
    "county": "Clinton County",
    "state": "NY",
    "city": "Redford"
  },
  {
    "value": "12979",
    "label": "Rouses Point - NY 12979",
    "county": "Clinton County",
    "state": "NY",
    "city": "Rouses Point"
  },
  {
    "value": "12980",
    "label": "Saint Regis Falls - NY 12980",
    "county": "Franklin County",
    "state": "NY",
    "city": "Saint Regis Falls"
  },
  {
    "value": "12981",
    "label": "Saranac - NY 12981",
    "county": "Clinton County",
    "state": "NY",
    "city": "Saranac"
  },
  {
    "value": "12983",
    "label": "Saranac Lake - NY 12983",
    "county": "Franklin County",
    "state": "NY",
    "city": "Saranac Lake"
  },
  {
    "value": "12985",
    "label": "Schuyler Falls - NY 12985",
    "county": "Clinton County",
    "state": "NY",
    "city": "Schuyler Falls"
  },
  {
    "value": "12986",
    "label": "Tupper Lake - NY 12986",
    "county": "Franklin County",
    "state": "NY",
    "city": "Tupper Lake"
  },
  {
    "value": "12987",
    "label": "Upper Jay - NY 12987",
    "county": "Essex County",
    "state": "NY",
    "city": "Upper Jay"
  },
  {
    "value": "12989",
    "label": "Vermontville - NY 12989",
    "county": "Franklin County",
    "state": "NY",
    "city": "Vermontville"
  },
  {
    "value": "12992",
    "label": "West Chazy - NY 12992",
    "county": "Clinton County",
    "state": "NY",
    "city": "West Chazy"
  },
  {
    "value": "12993",
    "label": "Westport - NY 12993",
    "county": "Essex County",
    "state": "NY",
    "city": "Westport"
  },
  {
    "value": "12996",
    "label": "Willsboro - NY 12996",
    "county": "Essex County",
    "state": "NY",
    "city": "Willsboro"
  },
  {
    "value": "12997",
    "label": "Wilmington - NY 12997",
    "county": "Essex County",
    "state": "NY",
    "city": "Wilmington"
  },
  {
    "value": "12998",
    "label": "Witherbee - NY 12998",
    "county": "Essex County",
    "state": "NY",
    "city": "Witherbee"
  },
  {
    "value": "13021",
    "label": "Auburn - NY 13021",
    "county": "Cayuga County",
    "state": "NY",
    "city": "Auburn"
  },
  {
    "value": "13026",
    "label": "Aurora - NY 13026",
    "county": "Cayuga County",
    "state": "NY",
    "city": "Aurora"
  },
  {
    "value": "13027",
    "label": "Baldwinsville - NY 13027",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Baldwinsville"
  },
  {
    "value": "13028",
    "label": "Bernhards Bay - NY 13028",
    "county": "Oswego County",
    "state": "NY",
    "city": "Bernhards Bay"
  },
  {
    "value": "13029",
    "label": "Brewerton - NY 13029",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Brewerton"
  },
  {
    "value": "13030",
    "label": "Bridgeport - NY 13030",
    "county": "Madison County",
    "state": "NY",
    "city": "Bridgeport"
  },
  {
    "value": "13031",
    "label": "Camillus - NY 13031",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Camillus"
  },
  {
    "value": "13032",
    "label": "Canastota - NY 13032",
    "county": "Madison County",
    "state": "NY",
    "city": "Canastota"
  },
  {
    "value": "13033",
    "label": "Cato - NY 13033",
    "county": "Cayuga County",
    "state": "NY",
    "city": "Cato"
  },
  {
    "value": "13034",
    "label": "Cayuga - NY 13034",
    "county": "Cayuga County",
    "state": "NY",
    "city": "Cayuga"
  },
  {
    "value": "13035",
    "label": "Cazenovia - NY 13035",
    "county": "Madison County",
    "state": "NY",
    "city": "Cazenovia"
  },
  {
    "value": "13036",
    "label": "Central Square - NY 13036",
    "county": "Oswego County",
    "state": "NY",
    "city": "Central Square"
  },
  {
    "value": "13037",
    "label": "Chittenango - NY 13037",
    "county": "Madison County",
    "state": "NY",
    "city": "Chittenango"
  },
  {
    "value": "13039",
    "label": "Cicero - NY 13039",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Cicero"
  },
  {
    "value": "13040",
    "label": "Cincinnatus - NY 13040",
    "county": "Cortland County",
    "state": "NY",
    "city": "Cincinnatus"
  },
  {
    "value": "13041",
    "label": "Clay - NY 13041",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Clay"
  },
  {
    "value": "13042",
    "label": "Cleveland - NY 13042",
    "county": "Oswego County",
    "state": "NY",
    "city": "Cleveland"
  },
  {
    "value": "13044",
    "label": "Constantia - NY 13044",
    "county": "Oswego County",
    "state": "NY",
    "city": "Constantia"
  },
  {
    "value": "13045",
    "label": "Cortland - NY 13045",
    "county": "Cortland County",
    "state": "NY",
    "city": "Cortland"
  },
  {
    "value": "13052",
    "label": "De Ruyter - NY 13052",
    "county": "Madison County",
    "state": "NY",
    "city": "De Ruyter"
  },
  {
    "value": "13053",
    "label": "Dryden - NY 13053",
    "county": "Tompkins County",
    "state": "NY",
    "city": "Dryden"
  },
  {
    "value": "13054",
    "label": "Durhamville - NY 13054",
    "county": "Oneida County",
    "state": "NY",
    "city": "Durhamville"
  },
  {
    "value": "13057",
    "label": "East Syracuse - NY 13057",
    "county": "Onondaga County",
    "state": "NY",
    "city": "East Syracuse"
  },
  {
    "value": "13060",
    "label": "Elbridge - NY 13060",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Elbridge"
  },
  {
    "value": "13061",
    "label": "Erieville - NY 13061",
    "county": "Madison County",
    "state": "NY",
    "city": "Erieville"
  },
  {
    "value": "13063",
    "label": "Fabius - NY 13063",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Fabius"
  },
  {
    "value": "13066",
    "label": "Fayetteville - NY 13066",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Fayetteville"
  },
  {
    "value": "13068",
    "label": "Freeville - NY 13068",
    "county": "Tompkins County",
    "state": "NY",
    "city": "Freeville"
  },
  {
    "value": "13069",
    "label": "Fulton - NY 13069",
    "county": "Oswego County",
    "state": "NY",
    "city": "Fulton"
  },
  {
    "value": "13071",
    "label": "Genoa - NY 13071",
    "county": "Cayuga County",
    "state": "NY",
    "city": "Genoa"
  },
  {
    "value": "13072",
    "label": "Georgetown - NY 13072",
    "county": "Madison County",
    "state": "NY",
    "city": "Georgetown"
  },
  {
    "value": "13073",
    "label": "Groton - NY 13073",
    "county": "Tompkins County",
    "state": "NY",
    "city": "Groton"
  },
  {
    "value": "13074",
    "label": "Hannibal - NY 13074",
    "county": "Oswego County",
    "state": "NY",
    "city": "Hannibal"
  },
  {
    "value": "13076",
    "label": "Hastings - NY 13076",
    "county": "Oswego County",
    "state": "NY",
    "city": "Hastings"
  },
  {
    "value": "13077",
    "label": "Homer - NY 13077",
    "county": "Cortland County",
    "state": "NY",
    "city": "Homer"
  },
  {
    "value": "13078",
    "label": "Jamesville - NY 13078",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Jamesville"
  },
  {
    "value": "13080",
    "label": "Jordan - NY 13080",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Jordan"
  },
  {
    "value": "13081",
    "label": "King Ferry - NY 13081",
    "county": "Cayuga County",
    "state": "NY",
    "city": "King Ferry"
  },
  {
    "value": "13082",
    "label": "Kirkville - NY 13082",
    "county": "Madison County",
    "state": "NY",
    "city": "Kirkville"
  },
  {
    "value": "13083",
    "label": "Lacona - NY 13083",
    "county": "Oswego County",
    "state": "NY",
    "city": "Lacona"
  },
  {
    "value": "13084",
    "label": "La Fayette - NY 13084",
    "county": "Onondaga County",
    "state": "NY",
    "city": "La Fayette"
  },
  {
    "value": "13088",
    "label": "Liverpool - NY 13088",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Liverpool"
  },
  {
    "value": "13090",
    "label": "Liverpool - NY 13090",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Liverpool"
  },
  {
    "value": "13092",
    "label": "Locke - NY 13092",
    "county": "Cayuga County",
    "state": "NY",
    "city": "Locke"
  },
  {
    "value": "13101",
    "label": "Mc Graw - NY 13101",
    "county": "Cortland County",
    "state": "NY",
    "city": "Mc Graw"
  },
  {
    "value": "13103",
    "label": "Mallory - NY 13103",
    "county": "Oswego County",
    "state": "NY",
    "city": "Mallory"
  },
  {
    "value": "13104",
    "label": "Manlius - NY 13104",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Manlius"
  },
  {
    "value": "13108",
    "label": "Marcellus - NY 13108",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Marcellus"
  },
  {
    "value": "13110",
    "label": "Marietta - NY 13110",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Marietta"
  },
  {
    "value": "13111",
    "label": "Martville - NY 13111",
    "county": "Cayuga County",
    "state": "NY",
    "city": "Martville"
  },
  {
    "value": "13112",
    "label": "Memphis - NY 13112",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Memphis"
  },
  {
    "value": "13114",
    "label": "Mexico - NY 13114",
    "county": "Oswego County",
    "state": "NY",
    "city": "Mexico"
  },
  {
    "value": "13116",
    "label": "Minoa - NY 13116",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Minoa"
  },
  {
    "value": "13118",
    "label": "Moravia - NY 13118",
    "county": "Cayuga County",
    "state": "NY",
    "city": "Moravia"
  },
  {
    "value": "13120",
    "label": "Nedrow - NY 13120",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Nedrow"
  },
  {
    "value": "13122",
    "label": "New Woodstock - NY 13122",
    "county": "Madison County",
    "state": "NY",
    "city": "New Woodstock"
  },
  {
    "value": "13124",
    "label": "North Pitcher - NY 13124",
    "county": "Chenango County",
    "state": "NY",
    "city": "North Pitcher"
  },
  {
    "value": "13126",
    "label": "Oswego - NY 13126",
    "county": "Oswego County",
    "state": "NY",
    "city": "Oswego"
  },
  {
    "value": "13131",
    "label": "Parish - NY 13131",
    "county": "Oswego County",
    "state": "NY",
    "city": "Parish"
  },
  {
    "value": "13132",
    "label": "Pennellville - NY 13132",
    "county": "Oswego County",
    "state": "NY",
    "city": "Pennellville"
  },
  {
    "value": "13135",
    "label": "Phoenix - NY 13135",
    "county": "Oswego County",
    "state": "NY",
    "city": "Phoenix"
  },
  {
    "value": "13136",
    "label": "Pitcher - NY 13136",
    "county": "Chenango County",
    "state": "NY",
    "city": "Pitcher"
  },
  {
    "value": "13140",
    "label": "Port Byron - NY 13140",
    "county": "Cayuga County",
    "state": "NY",
    "city": "Port Byron"
  },
  {
    "value": "13141",
    "label": "Preble - NY 13141",
    "county": "Cortland County",
    "state": "NY",
    "city": "Preble"
  },
  {
    "value": "13142",
    "label": "Pulaski - NY 13142",
    "county": "Oswego County",
    "state": "NY",
    "city": "Pulaski"
  },
  {
    "value": "13143",
    "label": "Red Creek - NY 13143",
    "county": "Wayne County",
    "state": "NY",
    "city": "Red Creek"
  },
  {
    "value": "13144",
    "label": "Richland - NY 13144",
    "county": "Oswego County",
    "state": "NY",
    "city": "Richland"
  },
  {
    "value": "13145",
    "label": "Sandy Creek - NY 13145",
    "county": "Oswego County",
    "state": "NY",
    "city": "Sandy Creek"
  },
  {
    "value": "13146",
    "label": "Savannah - NY 13146",
    "county": "Wayne County",
    "state": "NY",
    "city": "Savannah"
  },
  {
    "value": "13147",
    "label": "Scipio Center - NY 13147",
    "county": "Cayuga County",
    "state": "NY",
    "city": "Scipio Center"
  },
  {
    "value": "13148",
    "label": "Seneca Falls - NY 13148",
    "county": "Seneca County",
    "state": "NY",
    "city": "Seneca Falls"
  },
  {
    "value": "13152",
    "label": "Skaneateles - NY 13152",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Skaneateles"
  },
  {
    "value": "13155",
    "label": "South Otselic - NY 13155",
    "county": "Chenango County",
    "state": "NY",
    "city": "South Otselic"
  },
  {
    "value": "13156",
    "label": "Sterling - NY 13156",
    "county": "Cayuga County",
    "state": "NY",
    "city": "Sterling"
  },
  {
    "value": "13158",
    "label": "Truxton - NY 13158",
    "county": "Cortland County",
    "state": "NY",
    "city": "Truxton"
  },
  {
    "value": "13159",
    "label": "Tully - NY 13159",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Tully"
  },
  {
    "value": "13160",
    "label": "Union Springs - NY 13160",
    "county": "Cayuga County",
    "state": "NY",
    "city": "Union Springs"
  },
  {
    "value": "13164",
    "label": "Warners - NY 13164",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Warners"
  },
  {
    "value": "13165",
    "label": "Waterloo - NY 13165",
    "county": "Seneca County",
    "state": "NY",
    "city": "Waterloo"
  },
  {
    "value": "13166",
    "label": "Weedsport - NY 13166",
    "county": "Cayuga County",
    "state": "NY",
    "city": "Weedsport"
  },
  {
    "value": "13167",
    "label": "West Monroe - NY 13167",
    "county": "Oswego County",
    "state": "NY",
    "city": "West Monroe"
  },
  {
    "value": "13202",
    "label": "Syracuse - NY 13202",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Syracuse"
  },
  {
    "value": "13203",
    "label": "Syracuse - NY 13203",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Syracuse"
  },
  {
    "value": "13204",
    "label": "Syracuse - NY 13204",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Syracuse"
  },
  {
    "value": "13205",
    "label": "Syracuse - NY 13205",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Syracuse"
  },
  {
    "value": "13206",
    "label": "Syracuse - NY 13206",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Syracuse"
  },
  {
    "value": "13207",
    "label": "Syracuse - NY 13207",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Syracuse"
  },
  {
    "value": "13208",
    "label": "Syracuse - NY 13208",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Syracuse"
  },
  {
    "value": "13209",
    "label": "Syracuse - NY 13209",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Syracuse"
  },
  {
    "value": "13210",
    "label": "Syracuse - NY 13210",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Syracuse"
  },
  {
    "value": "13211",
    "label": "Syracuse - NY 13211",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Syracuse"
  },
  {
    "value": "13212",
    "label": "Syracuse - NY 13212",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Syracuse"
  },
  {
    "value": "13214",
    "label": "Syracuse - NY 13214",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Syracuse"
  },
  {
    "value": "13215",
    "label": "Syracuse - NY 13215",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Syracuse"
  },
  {
    "value": "13219",
    "label": "Syracuse - NY 13219",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Syracuse"
  },
  {
    "value": "13224",
    "label": "Syracuse - NY 13224",
    "county": "Onondaga County",
    "state": "NY",
    "city": "Syracuse"
  },
  {
    "value": "13301",
    "label": "Alder Creek - NY 13301",
    "county": "Oneida County",
    "state": "NY",
    "city": "Alder Creek"
  },
  {
    "value": "13302",
    "label": "Altmar - NY 13302",
    "county": "Oswego County",
    "state": "NY",
    "city": "Altmar"
  },
  {
    "value": "13303",
    "label": "Ava - NY 13303",
    "county": "Oneida County",
    "state": "NY",
    "city": "Ava"
  },
  {
    "value": "13304",
    "label": "Barneveld - NY 13304",
    "county": "Oneida County",
    "state": "NY",
    "city": "Barneveld"
  },
  {
    "value": "13308",
    "label": "Blossvale - NY 13308",
    "county": "Oneida County",
    "state": "NY",
    "city": "Blossvale"
  },
  {
    "value": "13309",
    "label": "Boonville - NY 13309",
    "county": "Oneida County",
    "state": "NY",
    "city": "Boonville"
  },
  {
    "value": "13310",
    "label": "Bouckville - NY 13310",
    "county": "Madison County",
    "state": "NY",
    "city": "Bouckville"
  },
  {
    "value": "13314",
    "label": "Brookfield - NY 13314",
    "county": "Madison County",
    "state": "NY",
    "city": "Brookfield"
  },
  {
    "value": "13315",
    "label": "Burlington Flats - NY 13315",
    "county": "Otsego County",
    "state": "NY",
    "city": "Burlington Flats"
  },
  {
    "value": "13316",
    "label": "Camden - NY 13316",
    "county": "Oneida County",
    "state": "NY",
    "city": "Camden"
  },
  {
    "value": "13317",
    "label": "Canajoharie - NY 13317",
    "county": "Montgomery County",
    "state": "NY",
    "city": "Canajoharie"
  },
  {
    "value": "13318",
    "label": "Cassville - NY 13318",
    "county": "Oneida County",
    "state": "NY",
    "city": "Cassville"
  },
  {
    "value": "13319",
    "label": "Chadwicks - NY 13319",
    "county": "Oneida County",
    "state": "NY",
    "city": "Chadwicks"
  },
  {
    "value": "13320",
    "label": "Cherry Valley - NY 13320",
    "county": "Otsego County",
    "state": "NY",
    "city": "Cherry Valley"
  },
  {
    "value": "13322",
    "label": "Clayville - NY 13322",
    "county": "Oneida County",
    "state": "NY",
    "city": "Clayville"
  },
  {
    "value": "13323",
    "label": "Clinton - NY 13323",
    "county": "Oneida County",
    "state": "NY",
    "city": "Clinton"
  },
  {
    "value": "13324",
    "label": "Cold Brook - NY 13324",
    "county": "Herkimer County",
    "state": "NY",
    "city": "Cold Brook"
  },
  {
    "value": "13325",
    "label": "Constableville - NY 13325",
    "county": "Lewis County",
    "state": "NY",
    "city": "Constableville"
  },
  {
    "value": "13326",
    "label": "Cooperstown - NY 13326",
    "county": "Otsego County",
    "state": "NY",
    "city": "Cooperstown"
  },
  {
    "value": "13327",
    "label": "Croghan - NY 13327",
    "county": "Lewis County",
    "state": "NY",
    "city": "Croghan"
  },
  {
    "value": "13328",
    "label": "Deansboro - NY 13328",
    "county": "Oneida County",
    "state": "NY",
    "city": "Deansboro"
  },
  {
    "value": "13329",
    "label": "Dolgeville - NY 13329",
    "county": "Herkimer County",
    "state": "NY",
    "city": "Dolgeville"
  },
  {
    "value": "13331",
    "label": "Eagle Bay - NY 13331",
    "county": "Herkimer County",
    "state": "NY",
    "city": "Eagle Bay"
  },
  {
    "value": "13332",
    "label": "Earlville - NY 13332",
    "county": "Madison County",
    "state": "NY",
    "city": "Earlville"
  },
  {
    "value": "13333",
    "label": "East Springfield - NY 13333",
    "county": "Otsego County",
    "state": "NY",
    "city": "East Springfield"
  },
  {
    "value": "13334",
    "label": "Eaton - NY 13334",
    "county": "Madison County",
    "state": "NY",
    "city": "Eaton"
  },
  {
    "value": "13335",
    "label": "Edmeston - NY 13335",
    "county": "Otsego County",
    "state": "NY",
    "city": "Edmeston"
  },
  {
    "value": "13337",
    "label": "Fly Creek - NY 13337",
    "county": "Otsego County",
    "state": "NY",
    "city": "Fly Creek"
  },
  {
    "value": "13338",
    "label": "Forestport - NY 13338",
    "county": "Oneida County",
    "state": "NY",
    "city": "Forestport"
  },
  {
    "value": "13339",
    "label": "Fort Plain - NY 13339",
    "county": "Montgomery County",
    "state": "NY",
    "city": "Fort Plain"
  },
  {
    "value": "13340",
    "label": "Frankfort - NY 13340",
    "county": "Herkimer County",
    "state": "NY",
    "city": "Frankfort"
  },
  {
    "value": "13342",
    "label": "Garrattsville - NY 13342",
    "county": "Otsego County",
    "state": "NY",
    "city": "Garrattsville"
  },
  {
    "value": "13343",
    "label": "Glenfield - NY 13343",
    "county": "Lewis County",
    "state": "NY",
    "city": "Glenfield"
  },
  {
    "value": "13345",
    "label": "Greig - NY 13345",
    "county": "Lewis County",
    "state": "NY",
    "city": "Greig"
  },
  {
    "value": "13346",
    "label": "Hamilton - NY 13346",
    "county": "Madison County",
    "state": "NY",
    "city": "Hamilton"
  },
  {
    "value": "13348",
    "label": "Hartwick - NY 13348",
    "county": "Otsego County",
    "state": "NY",
    "city": "Hartwick"
  },
  {
    "value": "13350",
    "label": "Herkimer - NY 13350",
    "county": "Herkimer County",
    "state": "NY",
    "city": "Herkimer"
  },
  {
    "value": "13354",
    "label": "Holland Patent - NY 13354",
    "county": "Oneida County",
    "state": "NY",
    "city": "Holland Patent"
  },
  {
    "value": "13355",
    "label": "Hubbardsville - NY 13355",
    "county": "Madison County",
    "state": "NY",
    "city": "Hubbardsville"
  },
  {
    "value": "13357",
    "label": "Ilion - NY 13357",
    "county": "Herkimer County",
    "state": "NY",
    "city": "Ilion"
  },
  {
    "value": "13360",
    "label": "Inlet - NY 13360",
    "county": "Hamilton County",
    "state": "NY",
    "city": "Inlet"
  },
  {
    "value": "13361",
    "label": "Jordanville - NY 13361",
    "county": "Herkimer County",
    "state": "NY",
    "city": "Jordanville"
  },
  {
    "value": "13363",
    "label": "Lee Center - NY 13363",
    "county": "Oneida County",
    "state": "NY",
    "city": "Lee Center"
  },
  {
    "value": "13365",
    "label": "Little Falls - NY 13365",
    "county": "Herkimer County",
    "state": "NY",
    "city": "Little Falls"
  },
  {
    "value": "13367",
    "label": "Lowville - NY 13367",
    "county": "Lewis County",
    "state": "NY",
    "city": "Lowville"
  },
  {
    "value": "13368",
    "label": "Lyons Falls - NY 13368",
    "county": "Lewis County",
    "state": "NY",
    "city": "Lyons Falls"
  },
  {
    "value": "13402",
    "label": "Madison - NY 13402",
    "county": "Madison County",
    "state": "NY",
    "city": "Madison"
  },
  {
    "value": "13403",
    "label": "Marcy - NY 13403",
    "county": "Oneida County",
    "state": "NY",
    "city": "Marcy"
  },
  {
    "value": "13406",
    "label": "Middleville - NY 13406",
    "county": "Herkimer County",
    "state": "NY",
    "city": "Middleville"
  },
  {
    "value": "13407",
    "label": "Mohawk - NY 13407",
    "county": "Herkimer County",
    "state": "NY",
    "city": "Mohawk"
  },
  {
    "value": "13408",
    "label": "Morrisville - NY 13408",
    "county": "Madison County",
    "state": "NY",
    "city": "Morrisville"
  },
  {
    "value": "13409",
    "label": "Munnsville - NY 13409",
    "county": "Madison County",
    "state": "NY",
    "city": "Munnsville"
  },
  {
    "value": "13411",
    "label": "New Berlin - NY 13411",
    "county": "Chenango County",
    "state": "NY",
    "city": "New Berlin"
  },
  {
    "value": "13413",
    "label": "New Hartford - NY 13413",
    "county": "Oneida County",
    "state": "NY",
    "city": "New Hartford"
  },
  {
    "value": "13415",
    "label": "New Lisbon - NY 13415",
    "county": "Otsego County",
    "state": "NY",
    "city": "New Lisbon"
  },
  {
    "value": "13416",
    "label": "Newport - NY 13416",
    "county": "Herkimer County",
    "state": "NY",
    "city": "Newport"
  },
  {
    "value": "13417",
    "label": "New York Mills - NY 13417",
    "county": "Oneida County",
    "state": "NY",
    "city": "New York Mills"
  },
  {
    "value": "13418",
    "label": "North Brookfield - NY 13418",
    "county": "Madison County",
    "state": "NY",
    "city": "North Brookfield"
  },
  {
    "value": "13420",
    "label": "Old Forge - NY 13420",
    "county": "Herkimer County",
    "state": "NY",
    "city": "Old Forge"
  },
  {
    "value": "13421",
    "label": "Oneida - NY 13421",
    "county": "Madison County",
    "state": "NY",
    "city": "Oneida"
  },
  {
    "value": "13424",
    "label": "Oriskany - NY 13424",
    "county": "Oneida County",
    "state": "NY",
    "city": "Oriskany"
  },
  {
    "value": "13425",
    "label": "Oriskany Falls - NY 13425",
    "county": "Oneida County",
    "state": "NY",
    "city": "Oriskany Falls"
  },
  {
    "value": "13428",
    "label": "Palatine Bridge - NY 13428",
    "county": "Montgomery County",
    "state": "NY",
    "city": "Palatine Bridge"
  },
  {
    "value": "13431",
    "label": "Poland - NY 13431",
    "county": "Herkimer County",
    "state": "NY",
    "city": "Poland"
  },
  {
    "value": "13433",
    "label": "Port Leyden - NY 13433",
    "county": "Lewis County",
    "state": "NY",
    "city": "Port Leyden"
  },
  {
    "value": "13436",
    "label": "Raquette Lake - NY 13436",
    "county": "Hamilton County",
    "state": "NY",
    "city": "Raquette Lake"
  },
  {
    "value": "13437",
    "label": "Redfield - NY 13437",
    "county": "Oswego County",
    "state": "NY",
    "city": "Redfield"
  },
  {
    "value": "13438",
    "label": "Remsen - NY 13438",
    "county": "Oneida County",
    "state": "NY",
    "city": "Remsen"
  },
  {
    "value": "13439",
    "label": "Richfield Springs - NY 13439",
    "county": "Otsego County",
    "state": "NY",
    "city": "Richfield Springs"
  },
  {
    "value": "13440",
    "label": "Rome - NY 13440",
    "county": "Oneida County",
    "state": "NY",
    "city": "Rome"
  },
  {
    "value": "13441",
    "label": "Rome - NY 13441",
    "county": "Oneida County",
    "state": "NY",
    "city": "Rome"
  },
  {
    "value": "13450",
    "label": "Roseboom - NY 13450",
    "county": "Otsego County",
    "state": "NY",
    "city": "Roseboom"
  },
  {
    "value": "13452",
    "label": "Saint Johnsville - NY 13452",
    "county": "Montgomery County",
    "state": "NY",
    "city": "Saint Johnsville"
  },
  {
    "value": "13454",
    "label": "Salisbury Center - NY 13454",
    "county": "Herkimer County",
    "state": "NY",
    "city": "Salisbury Center"
  },
  {
    "value": "13456",
    "label": "Sauquoit - NY 13456",
    "county": "Oneida County",
    "state": "NY",
    "city": "Sauquoit"
  },
  {
    "value": "13459",
    "label": "Sharon Springs - NY 13459",
    "county": "Schoharie County",
    "state": "NY",
    "city": "Sharon Springs"
  },
  {
    "value": "13460",
    "label": "Sherburne - NY 13460",
    "county": "Chenango County",
    "state": "NY",
    "city": "Sherburne"
  },
  {
    "value": "13461",
    "label": "Sherrill - NY 13461",
    "county": "Oneida County",
    "state": "NY",
    "city": "Sherrill"
  },
  {
    "value": "13464",
    "label": "Smyrna - NY 13464",
    "county": "Chenango County",
    "state": "NY",
    "city": "Smyrna"
  },
  {
    "value": "13465",
    "label": "Solsville - NY 13465",
    "county": "Madison County",
    "state": "NY",
    "city": "Solsville"
  },
  {
    "value": "13468",
    "label": "Springfield Center - NY 13468",
    "county": "Otsego County",
    "state": "NY",
    "city": "Springfield Center"
  },
  {
    "value": "13469",
    "label": "Stittville - NY 13469",
    "county": "Oneida County",
    "state": "NY",
    "city": "Stittville"
  },
  {
    "value": "13470",
    "label": "Stratford - NY 13470",
    "county": "Fulton County",
    "state": "NY",
    "city": "Stratford"
  },
  {
    "value": "13471",
    "label": "Taberg - NY 13471",
    "county": "Oneida County",
    "state": "NY",
    "city": "Taberg"
  },
  {
    "value": "13473",
    "label": "Turin - NY 13473",
    "county": "Lewis County",
    "state": "NY",
    "city": "Turin"
  },
  {
    "value": "13475",
    "label": "Van Hornesville - NY 13475",
    "county": "Herkimer County",
    "state": "NY",
    "city": "Van Hornesville"
  },
  {
    "value": "13476",
    "label": "Vernon - NY 13476",
    "county": "Oneida County",
    "state": "NY",
    "city": "Vernon"
  },
  {
    "value": "13477",
    "label": "Vernon Center - NY 13477",
    "county": "Oneida County",
    "state": "NY",
    "city": "Vernon Center"
  },
  {
    "value": "13478",
    "label": "Verona - NY 13478",
    "county": "Oneida County",
    "state": "NY",
    "city": "Verona"
  },
  {
    "value": "13480",
    "label": "Waterville - NY 13480",
    "county": "Oneida County",
    "state": "NY",
    "city": "Waterville"
  },
  {
    "value": "13482",
    "label": "West Burlington - NY 13482",
    "county": "Otsego County",
    "state": "NY",
    "city": "West Burlington"
  },
  {
    "value": "13483",
    "label": "Westdale - NY 13483",
    "county": "Oneida County",
    "state": "NY",
    "city": "Westdale"
  },
  {
    "value": "13485",
    "label": "West Edmeston - NY 13485",
    "county": "Madison County",
    "state": "NY",
    "city": "West Edmeston"
  },
  {
    "value": "13486",
    "label": "Westernville - NY 13486",
    "county": "Oneida County",
    "state": "NY",
    "city": "Westernville"
  },
  {
    "value": "13488",
    "label": "Westford - NY 13488",
    "county": "Otsego County",
    "state": "NY",
    "city": "Westford"
  },
  {
    "value": "13489",
    "label": "West Leyden - NY 13489",
    "county": "Lewis County",
    "state": "NY",
    "city": "West Leyden"
  },
  {
    "value": "13490",
    "label": "Westmoreland - NY 13490",
    "county": "Oneida County",
    "state": "NY",
    "city": "Westmoreland"
  },
  {
    "value": "13491",
    "label": "West Winfield - NY 13491",
    "county": "Herkimer County",
    "state": "NY",
    "city": "West Winfield"
  },
  {
    "value": "13492",
    "label": "Whitesboro - NY 13492",
    "county": "Oneida County",
    "state": "NY",
    "city": "Whitesboro"
  },
  {
    "value": "13493",
    "label": "Williamstown - NY 13493",
    "county": "Oswego County",
    "state": "NY",
    "city": "Williamstown"
  },
  {
    "value": "13494",
    "label": "Woodgate - NY 13494",
    "county": "Oneida County",
    "state": "NY",
    "city": "Woodgate"
  },
  {
    "value": "13495",
    "label": "Yorkville - NY 13495",
    "county": "Oneida County",
    "state": "NY",
    "city": "Yorkville"
  },
  {
    "value": "13501",
    "label": "Utica - NY 13501",
    "county": "Oneida County",
    "state": "NY",
    "city": "Utica"
  },
  {
    "value": "13502",
    "label": "Utica - NY 13502",
    "county": "Oneida County",
    "state": "NY",
    "city": "Utica"
  },
  {
    "value": "13601",
    "label": "Watertown - NY 13601",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Watertown"
  },
  {
    "value": "13602",
    "label": "Fort Drum - NY 13602",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Fort Drum"
  },
  {
    "value": "13603",
    "label": "Watertown - NY 13603",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Watertown"
  },
  {
    "value": "13605",
    "label": "Adams - NY 13605",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Adams"
  },
  {
    "value": "13606",
    "label": "Adams Center - NY 13606",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Adams Center"
  },
  {
    "value": "13607",
    "label": "Alexandria Bay - NY 13607",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Alexandria Bay"
  },
  {
    "value": "13608",
    "label": "Antwerp - NY 13608",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Antwerp"
  },
  {
    "value": "13612",
    "label": "Black River - NY 13612",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Black River"
  },
  {
    "value": "13613",
    "label": "Brasher Falls - NY 13613",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Brasher Falls"
  },
  {
    "value": "13614",
    "label": "Brier Hill - NY 13614",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Brier Hill"
  },
  {
    "value": "13616",
    "label": "Calcium - NY 13616",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Calcium"
  },
  {
    "value": "13617",
    "label": "Canton - NY 13617",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Canton"
  },
  {
    "value": "13618",
    "label": "Cape Vincent - NY 13618",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Cape Vincent"
  },
  {
    "value": "13619",
    "label": "Carthage - NY 13619",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Carthage"
  },
  {
    "value": "13620",
    "label": "Castorland - NY 13620",
    "county": "Lewis County",
    "state": "NY",
    "city": "Castorland"
  },
  {
    "value": "13621",
    "label": "Chase Mills - NY 13621",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Chase Mills"
  },
  {
    "value": "13622",
    "label": "Chaumont - NY 13622",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Chaumont"
  },
  {
    "value": "13624",
    "label": "Clayton - NY 13624",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Clayton"
  },
  {
    "value": "13625",
    "label": "Colton - NY 13625",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Colton"
  },
  {
    "value": "13626",
    "label": "Copenhagen - NY 13626",
    "county": "Lewis County",
    "state": "NY",
    "city": "Copenhagen"
  },
  {
    "value": "13630",
    "label": "De Kalb Junction - NY 13630",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "De Kalb Junction"
  },
  {
    "value": "13633",
    "label": "De Peyster - NY 13633",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "De Peyster"
  },
  {
    "value": "13634",
    "label": "Dexter - NY 13634",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Dexter"
  },
  {
    "value": "13635",
    "label": "Edwards - NY 13635",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Edwards"
  },
  {
    "value": "13636",
    "label": "Ellisburg - NY 13636",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Ellisburg"
  },
  {
    "value": "13637",
    "label": "Evans Mills - NY 13637",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Evans Mills"
  },
  {
    "value": "13638",
    "label": "Felts Mills - NY 13638",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Felts Mills"
  },
  {
    "value": "13639",
    "label": "Fine - NY 13639",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Fine"
  },
  {
    "value": "13640",
    "label": "Wellesley Island - NY 13640",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Wellesley Island"
  },
  {
    "value": "13642",
    "label": "Gouverneur - NY 13642",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Gouverneur"
  },
  {
    "value": "13646",
    "label": "Hammond - NY 13646",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Hammond"
  },
  {
    "value": "13648",
    "label": "Harrisville - NY 13648",
    "county": "Lewis County",
    "state": "NY",
    "city": "Harrisville"
  },
  {
    "value": "13650",
    "label": "Henderson - NY 13650",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Henderson"
  },
  {
    "value": "13652",
    "label": "Hermon - NY 13652",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Hermon"
  },
  {
    "value": "13654",
    "label": "Heuvelton - NY 13654",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Heuvelton"
  },
  {
    "value": "13655",
    "label": "Hogansburg - NY 13655",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Hogansburg"
  },
  {
    "value": "13656",
    "label": "La Fargeville - NY 13656",
    "county": "Jefferson County",
    "state": "NY",
    "city": "La Fargeville"
  },
  {
    "value": "13658",
    "label": "Lisbon - NY 13658",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Lisbon"
  },
  {
    "value": "13659",
    "label": "Lorraine - NY 13659",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Lorraine"
  },
  {
    "value": "13660",
    "label": "Madrid - NY 13660",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Madrid"
  },
  {
    "value": "13661",
    "label": "Mannsville - NY 13661",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Mannsville"
  },
  {
    "value": "13662",
    "label": "Massena - NY 13662",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Massena"
  },
  {
    "value": "13664",
    "label": "Morristown - NY 13664",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Morristown"
  },
  {
    "value": "13665",
    "label": "Natural Bridge - NY 13665",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Natural Bridge"
  },
  {
    "value": "13667",
    "label": "Norfolk - NY 13667",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Norfolk"
  },
  {
    "value": "13668",
    "label": "Norwood - NY 13668",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Norwood"
  },
  {
    "value": "13669",
    "label": "Ogdensburg - NY 13669",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Ogdensburg"
  },
  {
    "value": "13670",
    "label": "Oswegatchie - NY 13670",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Oswegatchie"
  },
  {
    "value": "13672",
    "label": "Parishville - NY 13672",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Parishville"
  },
  {
    "value": "13673",
    "label": "Philadelphia - NY 13673",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Philadelphia"
  },
  {
    "value": "13675",
    "label": "Plessis - NY 13675",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Plessis"
  },
  {
    "value": "13676",
    "label": "Potsdam - NY 13676",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Potsdam"
  },
  {
    "value": "13679",
    "label": "Redwood - NY 13679",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Redwood"
  },
  {
    "value": "13680",
    "label": "Rensselaer Falls - NY 13680",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Rensselaer Falls"
  },
  {
    "value": "13681",
    "label": "Richville - NY 13681",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Richville"
  },
  {
    "value": "13682",
    "label": "Rodman - NY 13682",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Rodman"
  },
  {
    "value": "13684",
    "label": "Russell - NY 13684",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Russell"
  },
  {
    "value": "13685",
    "label": "Sackets Harbor - NY 13685",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Sackets Harbor"
  },
  {
    "value": "13687",
    "label": "South Colton - NY 13687",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "South Colton"
  },
  {
    "value": "13690",
    "label": "Star Lake - NY 13690",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Star Lake"
  },
  {
    "value": "13691",
    "label": "Theresa - NY 13691",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Theresa"
  },
  {
    "value": "13693",
    "label": "Three Mile Bay - NY 13693",
    "county": "Jefferson County",
    "state": "NY",
    "city": "Three Mile Bay"
  },
  {
    "value": "13694",
    "label": "Waddington - NY 13694",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Waddington"
  },
  {
    "value": "13696",
    "label": "West Stockholm - NY 13696",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "West Stockholm"
  },
  {
    "value": "13697",
    "label": "Winthrop - NY 13697",
    "county": "St. Lawrence County",
    "state": "NY",
    "city": "Winthrop"
  },
  {
    "value": "13730",
    "label": "Afton - NY 13730",
    "county": "Chenango County",
    "state": "NY",
    "city": "Afton"
  },
  {
    "value": "13731",
    "label": "Andes - NY 13731",
    "county": "Delaware County",
    "state": "NY",
    "city": "Andes"
  },
  {
    "value": "13732",
    "label": "Apalachin - NY 13732",
    "county": "Tioga County",
    "state": "NY",
    "city": "Apalachin"
  },
  {
    "value": "13733",
    "label": "Bainbridge - NY 13733",
    "county": "Chenango County",
    "state": "NY",
    "city": "Bainbridge"
  },
  {
    "value": "13734",
    "label": "Barton - NY 13734",
    "county": "Tioga County",
    "state": "NY",
    "city": "Barton"
  },
  {
    "value": "13736",
    "label": "Berkshire - NY 13736",
    "county": "Tioga County",
    "state": "NY",
    "city": "Berkshire"
  },
  {
    "value": "13739",
    "label": "Bloomville - NY 13739",
    "county": "Delaware County",
    "state": "NY",
    "city": "Bloomville"
  },
  {
    "value": "13740",
    "label": "Bovina Center - NY 13740",
    "county": "Delaware County",
    "state": "NY",
    "city": "Bovina Center"
  },
  {
    "value": "13743",
    "label": "Candor - NY 13743",
    "county": "Tioga County",
    "state": "NY",
    "city": "Candor"
  },
  {
    "value": "13744",
    "label": "Castle Creek - NY 13744",
    "county": "Broome County",
    "state": "NY",
    "city": "Castle Creek"
  },
  {
    "value": "13746",
    "label": "Chenango Forks - NY 13746",
    "county": "Broome County",
    "state": "NY",
    "city": "Chenango Forks"
  },
  {
    "value": "13748",
    "label": "Conklin - NY 13748",
    "county": "Broome County",
    "state": "NY",
    "city": "Conklin"
  },
  {
    "value": "13750",
    "label": "Davenport - NY 13750",
    "county": "Delaware County",
    "state": "NY",
    "city": "Davenport"
  },
  {
    "value": "13751",
    "label": "Davenport Center - NY 13751",
    "county": "Delaware County",
    "state": "NY",
    "city": "Davenport Center"
  },
  {
    "value": "13752",
    "label": "Delancey - NY 13752",
    "county": "Delaware County",
    "state": "NY",
    "city": "Delancey"
  },
  {
    "value": "13753",
    "label": "Delhi - NY 13753",
    "county": "Delaware County",
    "state": "NY",
    "city": "Delhi"
  },
  {
    "value": "13754",
    "label": "Deposit - NY 13754",
    "county": "Broome County",
    "state": "NY",
    "city": "Deposit"
  },
  {
    "value": "13755",
    "label": "Downsville - NY 13755",
    "county": "Delaware County",
    "state": "NY",
    "city": "Downsville"
  },
  {
    "value": "13756",
    "label": "East Branch - NY 13756",
    "county": "Delaware County",
    "state": "NY",
    "city": "East Branch"
  },
  {
    "value": "13757",
    "label": "East Meredith - NY 13757",
    "county": "Delaware County",
    "state": "NY",
    "city": "East Meredith"
  },
  {
    "value": "13760",
    "label": "Endicott - NY 13760",
    "county": "Broome County",
    "state": "NY",
    "city": "Endicott"
  },
  {
    "value": "13775",
    "label": "Franklin - NY 13775",
    "county": "Delaware County",
    "state": "NY",
    "city": "Franklin"
  },
  {
    "value": "13776",
    "label": "Gilbertsville - NY 13776",
    "county": "Otsego County",
    "state": "NY",
    "city": "Gilbertsville"
  },
  {
    "value": "13777",
    "label": "Glen Aubrey - NY 13777",
    "county": "Broome County",
    "state": "NY",
    "city": "Glen Aubrey"
  },
  {
    "value": "13778",
    "label": "Greene - NY 13778",
    "county": "Chenango County",
    "state": "NY",
    "city": "Greene"
  },
  {
    "value": "13780",
    "label": "Guilford - NY 13780",
    "county": "Chenango County",
    "state": "NY",
    "city": "Guilford"
  },
  {
    "value": "13782",
    "label": "Hamden - NY 13782",
    "county": "Delaware County",
    "state": "NY",
    "city": "Hamden"
  },
  {
    "value": "13783",
    "label": "Hancock - NY 13783",
    "county": "Delaware County",
    "state": "NY",
    "city": "Hancock"
  },
  {
    "value": "13786",
    "label": "Harpersfield - NY 13786",
    "county": "Delaware County",
    "state": "NY",
    "city": "Harpersfield"
  },
  {
    "value": "13787",
    "label": "Harpursville - NY 13787",
    "county": "Broome County",
    "state": "NY",
    "city": "Harpursville"
  },
  {
    "value": "13788",
    "label": "Hobart - NY 13788",
    "county": "Delaware County",
    "state": "NY",
    "city": "Hobart"
  },
  {
    "value": "13790",
    "label": "Johnson City - NY 13790",
    "county": "Broome County",
    "state": "NY",
    "city": "Johnson City"
  },
  {
    "value": "13795",
    "label": "Kirkwood - NY 13795",
    "county": "Broome County",
    "state": "NY",
    "city": "Kirkwood"
  },
  {
    "value": "13796",
    "label": "Laurens - NY 13796",
    "county": "Otsego County",
    "state": "NY",
    "city": "Laurens"
  },
  {
    "value": "13797",
    "label": "Lisle - NY 13797",
    "county": "Broome County",
    "state": "NY",
    "city": "Lisle"
  },
  {
    "value": "13801",
    "label": "Mc Donough - NY 13801",
    "county": "Chenango County",
    "state": "NY",
    "city": "Mc Donough"
  },
  {
    "value": "13802",
    "label": "Maine - NY 13802",
    "county": "Broome County",
    "state": "NY",
    "city": "Maine"
  },
  {
    "value": "13803",
    "label": "Marathon - NY 13803",
    "county": "Cortland County",
    "state": "NY",
    "city": "Marathon"
  },
  {
    "value": "13804",
    "label": "Masonville - NY 13804",
    "county": "Delaware County",
    "state": "NY",
    "city": "Masonville"
  },
  {
    "value": "13806",
    "label": "Meridale - NY 13806",
    "county": "Delaware County",
    "state": "NY",
    "city": "Meridale"
  },
  {
    "value": "13807",
    "label": "Milford - NY 13807",
    "county": "Otsego County",
    "state": "NY",
    "city": "Milford"
  },
  {
    "value": "13808",
    "label": "Morris - NY 13808",
    "county": "Otsego County",
    "state": "NY",
    "city": "Morris"
  },
  {
    "value": "13809",
    "label": "Mount Upton - NY 13809",
    "county": "Chenango County",
    "state": "NY",
    "city": "Mount Upton"
  },
  {
    "value": "13810",
    "label": "Mount Vision - NY 13810",
    "county": "Otsego County",
    "state": "NY",
    "city": "Mount Vision"
  },
  {
    "value": "13811",
    "label": "Newark Valley - NY 13811",
    "county": "Tioga County",
    "state": "NY",
    "city": "Newark Valley"
  },
  {
    "value": "13812",
    "label": "Nichols - NY 13812",
    "county": "Tioga County",
    "state": "NY",
    "city": "Nichols"
  },
  {
    "value": "13813",
    "label": "Nineveh - NY 13813",
    "county": "Broome County",
    "state": "NY",
    "city": "Nineveh"
  },
  {
    "value": "13815",
    "label": "Norwich - NY 13815",
    "county": "Chenango County",
    "state": "NY",
    "city": "Norwich"
  },
  {
    "value": "13820",
    "label": "Oneonta - NY 13820",
    "county": "Otsego County",
    "state": "NY",
    "city": "Oneonta"
  },
  {
    "value": "13825",
    "label": "Otego - NY 13825",
    "county": "Otsego County",
    "state": "NY",
    "city": "Otego"
  },
  {
    "value": "13826",
    "label": "Ouaquaga - NY 13826",
    "county": "Broome County",
    "state": "NY",
    "city": "Ouaquaga"
  },
  {
    "value": "13827",
    "label": "Owego - NY 13827",
    "county": "Tioga County",
    "state": "NY",
    "city": "Owego"
  },
  {
    "value": "13830",
    "label": "Oxford - NY 13830",
    "county": "Chenango County",
    "state": "NY",
    "city": "Oxford"
  },
  {
    "value": "13832",
    "label": "Plymouth - NY 13832",
    "county": "Chenango County",
    "state": "NY",
    "city": "Plymouth"
  },
  {
    "value": "13833",
    "label": "Port Crane - NY 13833",
    "county": "Broome County",
    "state": "NY",
    "city": "Port Crane"
  },
  {
    "value": "13834",
    "label": "Portlandville - NY 13834",
    "county": "Otsego County",
    "state": "NY",
    "city": "Portlandville"
  },
  {
    "value": "13835",
    "label": "Richford - NY 13835",
    "county": "Tioga County",
    "state": "NY",
    "city": "Richford"
  },
  {
    "value": "13838",
    "label": "Sidney - NY 13838",
    "county": "Delaware County",
    "state": "NY",
    "city": "Sidney"
  },
  {
    "value": "13839",
    "label": "Sidney Center - NY 13839",
    "county": "Delaware County",
    "state": "NY",
    "city": "Sidney Center"
  },
  {
    "value": "13841",
    "label": "Smithville Flats - NY 13841",
    "county": "Chenango County",
    "state": "NY",
    "city": "Smithville Flats"
  },
  {
    "value": "13842",
    "label": "South Kortright - NY 13842",
    "county": "Delaware County",
    "state": "NY",
    "city": "South Kortright"
  },
  {
    "value": "13843",
    "label": "South New Berlin - NY 13843",
    "county": "Chenango County",
    "state": "NY",
    "city": "South New Berlin"
  },
  {
    "value": "13844",
    "label": "South Plymouth - NY 13844",
    "county": "Chenango County",
    "state": "NY",
    "city": "South Plymouth"
  },
  {
    "value": "13846",
    "label": "Treadwell - NY 13846",
    "county": "Delaware County",
    "state": "NY",
    "city": "Treadwell"
  },
  {
    "value": "13849",
    "label": "Unadilla - NY 13849",
    "county": "Otsego County",
    "state": "NY",
    "city": "Unadilla"
  },
  {
    "value": "13850",
    "label": "Vestal - NY 13850",
    "county": "Broome County",
    "state": "NY",
    "city": "Vestal"
  },
  {
    "value": "13856",
    "label": "Walton - NY 13856",
    "county": "Delaware County",
    "state": "NY",
    "city": "Walton"
  },
  {
    "value": "13859",
    "label": "Wells Bridge - NY 13859",
    "county": "Otsego County",
    "state": "NY",
    "city": "Wells Bridge"
  },
  {
    "value": "13861",
    "label": "West Oneonta - NY 13861",
    "county": "Otsego County",
    "state": "NY",
    "city": "West Oneonta"
  },
  {
    "value": "13862",
    "label": "Whitney Point - NY 13862",
    "county": "Broome County",
    "state": "NY",
    "city": "Whitney Point"
  },
  {
    "value": "13863",
    "label": "Willet - NY 13863",
    "county": "Cortland County",
    "state": "NY",
    "city": "Willet"
  },
  {
    "value": "13864",
    "label": "Willseyville - NY 13864",
    "county": "Tioga County",
    "state": "NY",
    "city": "Willseyville"
  },
  {
    "value": "13865",
    "label": "Windsor - NY 13865",
    "county": "Broome County",
    "state": "NY",
    "city": "Windsor"
  },
  {
    "value": "13901",
    "label": "Binghamton - NY 13901",
    "county": "Broome County",
    "state": "NY",
    "city": "Binghamton"
  },
  {
    "value": "13903",
    "label": "Binghamton - NY 13903",
    "county": "Broome County",
    "state": "NY",
    "city": "Binghamton"
  },
  {
    "value": "13904",
    "label": "Binghamton - NY 13904",
    "county": "Broome County",
    "state": "NY",
    "city": "Binghamton"
  },
  {
    "value": "13905",
    "label": "Binghamton - NY 13905",
    "county": "Broome County",
    "state": "NY",
    "city": "Binghamton"
  },
  {
    "value": "14001",
    "label": "Akron - NY 14001",
    "county": "Erie County",
    "state": "NY",
    "city": "Akron"
  },
  {
    "value": "14004",
    "label": "Alden - NY 14004",
    "county": "Erie County",
    "state": "NY",
    "city": "Alden"
  },
  {
    "value": "14005",
    "label": "Alexander - NY 14005",
    "county": "Genesee County",
    "state": "NY",
    "city": "Alexander"
  },
  {
    "value": "14006",
    "label": "Angola - NY 14006",
    "county": "Erie County",
    "state": "NY",
    "city": "Angola"
  },
  {
    "value": "14008",
    "label": "Appleton - NY 14008",
    "county": "Niagara County",
    "state": "NY",
    "city": "Appleton"
  },
  {
    "value": "14009",
    "label": "Arcade - NY 14009",
    "county": "Wyoming County",
    "state": "NY",
    "city": "Arcade"
  },
  {
    "value": "14011",
    "label": "Attica - NY 14011",
    "county": "Wyoming County",
    "state": "NY",
    "city": "Attica"
  },
  {
    "value": "14012",
    "label": "Barker - NY 14012",
    "county": "Niagara County",
    "state": "NY",
    "city": "Barker"
  },
  {
    "value": "14013",
    "label": "Basom - NY 14013",
    "county": "Genesee County",
    "state": "NY",
    "city": "Basom"
  },
  {
    "value": "14020",
    "label": "Batavia - NY 14020",
    "county": "Genesee County",
    "state": "NY",
    "city": "Batavia"
  },
  {
    "value": "14024",
    "label": "Bliss - NY 14024",
    "county": "Wyoming County",
    "state": "NY",
    "city": "Bliss"
  },
  {
    "value": "14025",
    "label": "Boston - NY 14025",
    "county": "Erie County",
    "state": "NY",
    "city": "Boston"
  },
  {
    "value": "14026",
    "label": "Bowmansville - NY 14026",
    "county": "Erie County",
    "state": "NY",
    "city": "Bowmansville"
  },
  {
    "value": "14028",
    "label": "Burt - NY 14028",
    "county": "Niagara County",
    "state": "NY",
    "city": "Burt"
  },
  {
    "value": "14030",
    "label": "Chaffee - NY 14030",
    "county": "Erie County",
    "state": "NY",
    "city": "Chaffee"
  },
  {
    "value": "14031",
    "label": "Clarence - NY 14031",
    "county": "Erie County",
    "state": "NY",
    "city": "Clarence"
  },
  {
    "value": "14032",
    "label": "Clarence Center - NY 14032",
    "county": "Erie County",
    "state": "NY",
    "city": "Clarence Center"
  },
  {
    "value": "14033",
    "label": "Colden - NY 14033",
    "county": "Erie County",
    "state": "NY",
    "city": "Colden"
  },
  {
    "value": "14034",
    "label": "Collins - NY 14034",
    "county": "Erie County",
    "state": "NY",
    "city": "Collins"
  },
  {
    "value": "14036",
    "label": "Corfu - NY 14036",
    "county": "Genesee County",
    "state": "NY",
    "city": "Corfu"
  },
  {
    "value": "14037",
    "label": "Cowlesville - NY 14037",
    "county": "Wyoming County",
    "state": "NY",
    "city": "Cowlesville"
  },
  {
    "value": "14039",
    "label": "Dale - NY 14039",
    "county": "Wyoming County",
    "state": "NY",
    "city": "Dale"
  },
  {
    "value": "14040",
    "label": "Darien Center - NY 14040",
    "county": "Genesee County",
    "state": "NY",
    "city": "Darien Center"
  },
  {
    "value": "14041",
    "label": "Dayton - NY 14041",
    "county": "Cattaraugus County",
    "state": "NY",
    "city": "Dayton"
  },
  {
    "value": "14042",
    "label": "Delevan - NY 14042",
    "county": "Cattaraugus County",
    "state": "NY",
    "city": "Delevan"
  },
  {
    "value": "14043",
    "label": "Depew - NY 14043",
    "county": "Erie County",
    "state": "NY",
    "city": "Depew"
  },
  {
    "value": "14047",
    "label": "Derby - NY 14047",
    "county": "Erie County",
    "state": "NY",
    "city": "Derby"
  },
  {
    "value": "14048",
    "label": "Dunkirk - NY 14048",
    "county": "Chautauqua County",
    "state": "NY",
    "city": "Dunkirk"
  },
  {
    "value": "14051",
    "label": "East Amherst - NY 14051",
    "county": "Erie County",
    "state": "NY",
    "city": "East Amherst"
  },
  {
    "value": "14052",
    "label": "East Aurora - NY 14052",
    "county": "Erie County",
    "state": "NY",
    "city": "East Aurora"
  },
  {
    "value": "14054",
    "label": "East Bethany - NY 14054",
    "county": "Genesee County",
    "state": "NY",
    "city": "East Bethany"
  },
  {
    "value": "14055",
    "label": "East Concord - NY 14055",
    "county": "Erie County",
    "state": "NY",
    "city": "East Concord"
  },
  {
    "value": "14057",
    "label": "Eden - NY 14057",
    "county": "Erie County",
    "state": "NY",
    "city": "Eden"
  },
  {
    "value": "14058",
    "label": "Elba - NY 14058",
    "county": "Genesee County",
    "state": "NY",
    "city": "Elba"
  },
  {
    "value": "14059",
    "label": "Elma - NY 14059",
    "county": "Erie County",
    "state": "NY",
    "city": "Elma"
  },
  {
    "value": "14060",
    "label": "Farmersville Station - NY 14060",
    "county": "Cattaraugus County",
    "state": "NY",
    "city": "Farmersville Station"
  },
  {
    "value": "14062",
    "label": "Forestville - NY 14062",
    "county": "Chautauqua County",
    "state": "NY",
    "city": "Forestville"
  },
  {
    "value": "14063",
    "label": "Fredonia - NY 14063",
    "county": "Chautauqua County",
    "state": "NY",
    "city": "Fredonia"
  },
  {
    "value": "14065",
    "label": "Freedom - NY 14065",
    "county": "Cattaraugus County",
    "state": "NY",
    "city": "Freedom"
  },
  {
    "value": "14066",
    "label": "Gainesville - NY 14066",
    "county": "Wyoming County",
    "state": "NY",
    "city": "Gainesville"
  },
  {
    "value": "14067",
    "label": "Gasport - NY 14067",
    "county": "Niagara County",
    "state": "NY",
    "city": "Gasport"
  },
  {
    "value": "14068",
    "label": "Getzville - NY 14068",
    "county": "Erie County",
    "state": "NY",
    "city": "Getzville"
  },
  {
    "value": "14069",
    "label": "Glenwood - NY 14069",
    "county": "Erie County",
    "state": "NY",
    "city": "Glenwood"
  },
  {
    "value": "14070",
    "label": "Gowanda - NY 14070",
    "county": "Erie County",
    "state": "NY",
    "city": "Gowanda"
  },
  {
    "value": "14072",
    "label": "Grand Island - NY 14072",
    "county": "Erie County",
    "state": "NY",
    "city": "Grand Island"
  },
  {
    "value": "14075",
    "label": "Hamburg - NY 14075",
    "county": "Erie County",
    "state": "NY",
    "city": "Hamburg"
  },
  {
    "value": "14080",
    "label": "Holland - NY 14080",
    "county": "Erie County",
    "state": "NY",
    "city": "Holland"
  },
  {
    "value": "14081",
    "label": "Irving - NY 14081",
    "county": "Chautauqua County",
    "state": "NY",
    "city": "Irving"
  },
  {
    "value": "14082",
    "label": "Java Center - NY 14082",
    "county": "Wyoming County",
    "state": "NY",
    "city": "Java Center"
  },
  {
    "value": "14083",
    "label": "Java Village - NY 14083",
    "county": "Wyoming County",
    "state": "NY",
    "city": "Java Village"
  },
  {
    "value": "14085",
    "label": "Lake View - NY 14085",
    "county": "Erie County",
    "state": "NY",
    "city": "Lake View"
  },
  {
    "value": "14086",
    "label": "Lancaster - NY 14086",
    "county": "Erie County",
    "state": "NY",
    "city": "Lancaster"
  },
  {
    "value": "14091",
    "label": "Lawtons - NY 14091",
    "county": "Erie County",
    "state": "NY",
    "city": "Lawtons"
  },
  {
    "value": "14092",
    "label": "Lewiston - NY 14092",
    "county": "Niagara County",
    "state": "NY",
    "city": "Lewiston"
  },
  {
    "value": "14094",
    "label": "Lockport - NY 14094",
    "county": "Niagara County",
    "state": "NY",
    "city": "Lockport"
  },
  {
    "value": "14098",
    "label": "Lyndonville - NY 14098",
    "county": "Orleans County",
    "state": "NY",
    "city": "Lyndonville"
  },
  {
    "value": "14101",
    "label": "Machias - NY 14101",
    "county": "Cattaraugus County",
    "state": "NY",
    "city": "Machias"
  },
  {
    "value": "14102",
    "label": "Marilla - NY 14102",
    "county": "Erie County",
    "state": "NY",
    "city": "Marilla"
  },
  {
    "value": "14103",
    "label": "Medina - NY 14103",
    "county": "Orleans County",
    "state": "NY",
    "city": "Medina"
  },
  {
    "value": "14105",
    "label": "Middleport - NY 14105",
    "county": "Niagara County",
    "state": "NY",
    "city": "Middleport"
  },
  {
    "value": "14108",
    "label": "Newfane - NY 14108",
    "county": "Niagara County",
    "state": "NY",
    "city": "Newfane"
  },
  {
    "value": "14111",
    "label": "North Collins - NY 14111",
    "county": "Erie County",
    "state": "NY",
    "city": "North Collins"
  },
  {
    "value": "14113",
    "label": "North Java - NY 14113",
    "county": "Wyoming County",
    "state": "NY",
    "city": "North Java"
  },
  {
    "value": "14120",
    "label": "North Tonawanda - NY 14120",
    "county": "Niagara County",
    "state": "NY",
    "city": "North Tonawanda"
  },
  {
    "value": "14125",
    "label": "Oakfield - NY 14125",
    "county": "Genesee County",
    "state": "NY",
    "city": "Oakfield"
  },
  {
    "value": "14127",
    "label": "Orchard Park - NY 14127",
    "county": "Erie County",
    "state": "NY",
    "city": "Orchard Park"
  },
  {
    "value": "14129",
    "label": "Perrysburg - NY 14129",
    "county": "Cattaraugus County",
    "state": "NY",
    "city": "Perrysburg"
  },
  {
    "value": "14131",
    "label": "Ransomville - NY 14131",
    "county": "Niagara County",
    "state": "NY",
    "city": "Ransomville"
  },
  {
    "value": "14132",
    "label": "Sanborn - NY 14132",
    "county": "Niagara County",
    "state": "NY",
    "city": "Sanborn"
  },
  {
    "value": "14134",
    "label": "Sardinia - NY 14134",
    "county": "Erie County",
    "state": "NY",
    "city": "Sardinia"
  },
  {
    "value": "14136",
    "label": "Silver Creek - NY 14136",
    "county": "Chautauqua County",
    "state": "NY",
    "city": "Silver Creek"
  },
  {
    "value": "14138",
    "label": "South Dayton - NY 14138",
    "county": "Cattaraugus County",
    "state": "NY",
    "city": "South Dayton"
  },
  {
    "value": "14139",
    "label": "South Wales - NY 14139",
    "county": "Erie County",
    "state": "NY",
    "city": "South Wales"
  },
  {
    "value": "14141",
    "label": "Springville - NY 14141",
    "county": "Erie County",
    "state": "NY",
    "city": "Springville"
  },
  {
    "value": "14143",
    "label": "Stafford - NY 14143",
    "county": "Genesee County",
    "state": "NY",
    "city": "Stafford"
  },
  {
    "value": "14144",
    "label": "Stella Niagara - NY 14144",
    "county": "Niagara County",
    "state": "NY",
    "city": "Stella Niagara"
  },
  {
    "value": "14145",
    "label": "Strykersville - NY 14145",
    "county": "Wyoming County",
    "state": "NY",
    "city": "Strykersville"
  },
  {
    "value": "14150",
    "label": "Tonawanda - NY 14150",
    "county": "Erie County",
    "state": "NY",
    "city": "Tonawanda"
  },
  {
    "value": "14167",
    "label": "Varysburg - NY 14167",
    "county": "Wyoming County",
    "state": "NY",
    "city": "Varysburg"
  },
  {
    "value": "14170",
    "label": "West Falls - NY 14170",
    "county": "Erie County",
    "state": "NY",
    "city": "West Falls"
  },
  {
    "value": "14171",
    "label": "West Valley - NY 14171",
    "county": "Cattaraugus County",
    "state": "NY",
    "city": "West Valley"
  },
  {
    "value": "14172",
    "label": "Wilson - NY 14172",
    "county": "Niagara County",
    "state": "NY",
    "city": "Wilson"
  },
  {
    "value": "14174",
    "label": "Youngstown - NY 14174",
    "county": "Niagara County",
    "state": "NY",
    "city": "Youngstown"
  },
  {
    "value": "14201",
    "label": "Buffalo - NY 14201",
    "county": "Erie County",
    "state": "NY",
    "city": "Buffalo"
  },
  {
    "value": "14202",
    "label": "Buffalo - NY 14202",
    "county": "Erie County",
    "state": "NY",
    "city": "Buffalo"
  },
  {
    "value": "14203",
    "label": "Buffalo - NY 14203",
    "county": "Erie County",
    "state": "NY",
    "city": "Buffalo"
  },
  {
    "value": "14204",
    "label": "Buffalo - NY 14204",
    "county": "Erie County",
    "state": "NY",
    "city": "Buffalo"
  },
  {
    "value": "14206",
    "label": "Buffalo - NY 14206",
    "county": "Erie County",
    "state": "NY",
    "city": "Buffalo"
  },
  {
    "value": "14207",
    "label": "Buffalo - NY 14207",
    "county": "Erie County",
    "state": "NY",
    "city": "Buffalo"
  },
  {
    "value": "14208",
    "label": "Buffalo - NY 14208",
    "county": "Erie County",
    "state": "NY",
    "city": "Buffalo"
  },
  {
    "value": "14209",
    "label": "Buffalo - NY 14209",
    "county": "Erie County",
    "state": "NY",
    "city": "Buffalo"
  },
  {
    "value": "14210",
    "label": "Buffalo - NY 14210",
    "county": "Erie County",
    "state": "NY",
    "city": "Buffalo"
  },
  {
    "value": "14211",
    "label": "Buffalo - NY 14211",
    "county": "Erie County",
    "state": "NY",
    "city": "Buffalo"
  },
  {
    "value": "14212",
    "label": "Buffalo - NY 14212",
    "county": "Erie County",
    "state": "NY",
    "city": "Buffalo"
  },
  {
    "value": "14213",
    "label": "Buffalo - NY 14213",
    "county": "Erie County",
    "state": "NY",
    "city": "Buffalo"
  },
  {
    "value": "14214",
    "label": "Buffalo - NY 14214",
    "county": "Erie County",
    "state": "NY",
    "city": "Buffalo"
  },
  {
    "value": "14215",
    "label": "Buffalo - NY 14215",
    "county": "Erie County",
    "state": "NY",
    "city": "Buffalo"
  },
  {
    "value": "14216",
    "label": "Buffalo - NY 14216",
    "county": "Erie County",
    "state": "NY",
    "city": "Buffalo"
  },
  {
    "value": "14217",
    "label": "Buffalo - NY 14217",
    "county": "Erie County",
    "state": "NY",
    "city": "Buffalo"
  },
  {
    "value": "14218",
    "label": "Buffalo - NY 14218",
    "county": "Erie County",
    "state": "NY",
    "city": "Buffalo"
  },
  {
    "value": "14219",
    "label": "Buffalo - NY 14219",
    "county": "Erie County",
    "state": "NY",
    "city": "Buffalo"
  },
  {
    "value": "14220",
    "label": "Buffalo - NY 14220",
    "county": "Erie County",
    "state": "NY",
    "city": "Buffalo"
  },
  {
    "value": "14221",
    "label": "Buffalo - NY 14221",
    "county": "Erie County",
    "state": "NY",
    "city": "Buffalo"
  },
  {
    "value": "14222",
    "label": "Buffalo - NY 14222",
    "county": "Erie County",
    "state": "NY",
    "city": "Buffalo"
  },
  {
    "value": "14223",
    "label": "Buffalo - NY 14223",
    "county": "Erie County",
    "state": "NY",
    "city": "Buffalo"
  },
  {
    "value": "14224",
    "label": "Buffalo - NY 14224",
    "county": "Erie County",
    "state": "NY",
    "city": "Buffalo"
  },
  {
    "value": "14225",
    "label": "Buffalo - NY 14225",
    "county": "Erie County",
    "state": "NY",
    "city": "Buffalo"
  },
  {
    "value": "14226",
    "label": "Buffalo - NY 14226",
    "county": "Erie County",
    "state": "NY",
    "city": "Buffalo"
  },
  {
    "value": "14227",
    "label": "Buffalo - NY 14227",
    "county": "Erie County",
    "state": "NY",
    "city": "Buffalo"
  },
  {
    "value": "14228",
    "label": "Buffalo - NY 14228",
    "county": "Erie County",
    "state": "NY",
    "city": "Buffalo"
  },
  {
    "value": "14301",
    "label": "Niagara Falls - NY 14301",
    "county": "Niagara County",
    "state": "NY",
    "city": "Niagara Falls"
  },
  {
    "value": "14303",
    "label": "Niagara Falls - NY 14303",
    "county": "Niagara County",
    "state": "NY",
    "city": "Niagara Falls"
  },
  {
    "value": "14304",
    "label": "Niagara Falls - NY 14304",
    "county": "Niagara County",
    "state": "NY",
    "city": "Niagara Falls"
  },
  {
    "value": "14305",
    "label": "Niagara Falls - NY 14305",
    "county": "Niagara County",
    "state": "NY",
    "city": "Niagara Falls"
  },
  {
    "value": "14411",
    "label": "Albion - NY 14411",
    "county": "Orleans County",
    "state": "NY",
    "city": "Albion"
  },
  {
    "value": "14414",
    "label": "Avon - NY 14414",
    "county": "Livingston County",
    "state": "NY",
    "city": "Avon"
  },
  {
    "value": "14415",
    "label": "Bellona - NY 14415",
    "county": "Yates County",
    "state": "NY",
    "city": "Bellona"
  },
  {
    "value": "14416",
    "label": "Bergen - NY 14416",
    "county": "Genesee County",
    "state": "NY",
    "city": "Bergen"
  },
  {
    "value": "14418",
    "label": "Branchport - NY 14418",
    "county": "Yates County",
    "state": "NY",
    "city": "Branchport"
  },
  {
    "value": "14420",
    "label": "Brockport - NY 14420",
    "county": "Monroe County",
    "state": "NY",
    "city": "Brockport"
  },
  {
    "value": "14422",
    "label": "Byron - NY 14422",
    "county": "Genesee County",
    "state": "NY",
    "city": "Byron"
  },
  {
    "value": "14423",
    "label": "Caledonia - NY 14423",
    "county": "Livingston County",
    "state": "NY",
    "city": "Caledonia"
  },
  {
    "value": "14424",
    "label": "Canandaigua - NY 14424",
    "county": "Ontario County",
    "state": "NY",
    "city": "Canandaigua"
  },
  {
    "value": "14425",
    "label": "Farmington - NY 14425",
    "county": "Ontario County",
    "state": "NY",
    "city": "Farmington"
  },
  {
    "value": "14427",
    "label": "Castile - NY 14427",
    "county": "Wyoming County",
    "state": "NY",
    "city": "Castile"
  },
  {
    "value": "14428",
    "label": "Churchville - NY 14428",
    "county": "Monroe County",
    "state": "NY",
    "city": "Churchville"
  },
  {
    "value": "14432",
    "label": "Clifton Springs - NY 14432",
    "county": "Ontario County",
    "state": "NY",
    "city": "Clifton Springs"
  },
  {
    "value": "14433",
    "label": "Clyde - NY 14433",
    "county": "Wayne County",
    "state": "NY",
    "city": "Clyde"
  },
  {
    "value": "14435",
    "label": "Conesus - NY 14435",
    "county": "Livingston County",
    "state": "NY",
    "city": "Conesus"
  },
  {
    "value": "14437",
    "label": "Dansville - NY 14437",
    "county": "Livingston County",
    "state": "NY",
    "city": "Dansville"
  },
  {
    "value": "14441",
    "label": "Dresden - NY 14441",
    "county": "Yates County",
    "state": "NY",
    "city": "Dresden"
  },
  {
    "value": "14445",
    "label": "East Rochester - NY 14445",
    "county": "Monroe County",
    "state": "NY",
    "city": "East Rochester"
  },
  {
    "value": "14450",
    "label": "Fairport - NY 14450",
    "county": "Monroe County",
    "state": "NY",
    "city": "Fairport"
  },
  {
    "value": "14454",
    "label": "Geneseo - NY 14454",
    "county": "Livingston County",
    "state": "NY",
    "city": "Geneseo"
  },
  {
    "value": "14456",
    "label": "Geneva - NY 14456",
    "county": "Ontario County",
    "state": "NY",
    "city": "Geneva"
  },
  {
    "value": "14462",
    "label": "Groveland - NY 14462",
    "county": "Livingston County",
    "state": "NY",
    "city": "Groveland"
  },
  {
    "value": "14464",
    "label": "Hamlin - NY 14464",
    "county": "Monroe County",
    "state": "NY",
    "city": "Hamlin"
  },
  {
    "value": "14466",
    "label": "Hemlock - NY 14466",
    "county": "Ontario County",
    "state": "NY",
    "city": "Hemlock"
  },
  {
    "value": "14467",
    "label": "Henrietta - NY 14467",
    "county": "Monroe County",
    "state": "NY",
    "city": "Henrietta"
  },
  {
    "value": "14468",
    "label": "Hilton - NY 14468",
    "county": "Monroe County",
    "state": "NY",
    "city": "Hilton"
  },
  {
    "value": "14469",
    "label": "Bloomfield - NY 14469",
    "county": "Ontario County",
    "state": "NY",
    "city": "Bloomfield"
  },
  {
    "value": "14470",
    "label": "Holley - NY 14470",
    "county": "Orleans County",
    "state": "NY",
    "city": "Holley"
  },
  {
    "value": "14471",
    "label": "Honeoye - NY 14471",
    "county": "Ontario County",
    "state": "NY",
    "city": "Honeoye"
  },
  {
    "value": "14472",
    "label": "Honeoye Falls - NY 14472",
    "county": "Monroe County",
    "state": "NY",
    "city": "Honeoye Falls"
  },
  {
    "value": "14475",
    "label": "Ionia - NY 14475",
    "county": "Ontario County",
    "state": "NY",
    "city": "Ionia"
  },
  {
    "value": "14476",
    "label": "Kendall - NY 14476",
    "county": "Orleans County",
    "state": "NY",
    "city": "Kendall"
  },
  {
    "value": "14477",
    "label": "Kent - NY 14477",
    "county": "Orleans County",
    "state": "NY",
    "city": "Kent"
  },
  {
    "value": "14478",
    "label": "Keuka Park - NY 14478",
    "county": "Yates County",
    "state": "NY",
    "city": "Keuka Park"
  },
  {
    "value": "14479",
    "label": "Knowlesville - NY 14479",
    "county": "Orleans County",
    "state": "NY",
    "city": "Knowlesville"
  },
  {
    "value": "14480",
    "label": "Lakeville - NY 14480",
    "county": "Livingston County",
    "state": "NY",
    "city": "Lakeville"
  },
  {
    "value": "14481",
    "label": "Leicester - NY 14481",
    "county": "Livingston County",
    "state": "NY",
    "city": "Leicester"
  },
  {
    "value": "14482",
    "label": "Le Roy - NY 14482",
    "county": "Genesee County",
    "state": "NY",
    "city": "Le Roy"
  },
  {
    "value": "14485",
    "label": "Lima - NY 14485",
    "county": "Livingston County",
    "state": "NY",
    "city": "Lima"
  },
  {
    "value": "14486",
    "label": "Linwood - NY 14486",
    "county": "Livingston County",
    "state": "NY",
    "city": "Linwood"
  },
  {
    "value": "14487",
    "label": "Livonia - NY 14487",
    "county": "Livingston County",
    "state": "NY",
    "city": "Livonia"
  },
  {
    "value": "14489",
    "label": "Lyons - NY 14489",
    "county": "Wayne County",
    "state": "NY",
    "city": "Lyons"
  },
  {
    "value": "14502",
    "label": "Macedon - NY 14502",
    "county": "Wayne County",
    "state": "NY",
    "city": "Macedon"
  },
  {
    "value": "14504",
    "label": "Manchester - NY 14504",
    "county": "Ontario County",
    "state": "NY",
    "city": "Manchester"
  },
  {
    "value": "14505",
    "label": "Marion - NY 14505",
    "county": "Wayne County",
    "state": "NY",
    "city": "Marion"
  },
  {
    "value": "14506",
    "label": "Mendon - NY 14506",
    "county": "Monroe County",
    "state": "NY",
    "city": "Mendon"
  },
  {
    "value": "14507",
    "label": "Middlesex - NY 14507",
    "county": "Yates County",
    "state": "NY",
    "city": "Middlesex"
  },
  {
    "value": "14510",
    "label": "Mount Morris - NY 14510",
    "county": "Livingston County",
    "state": "NY",
    "city": "Mount Morris"
  },
  {
    "value": "14512",
    "label": "Naples - NY 14512",
    "county": "Ontario County",
    "state": "NY",
    "city": "Naples"
  },
  {
    "value": "14513",
    "label": "Newark - NY 14513",
    "county": "Wayne County",
    "state": "NY",
    "city": "Newark"
  },
  {
    "value": "14514",
    "label": "North Chili - NY 14514",
    "county": "Monroe County",
    "state": "NY",
    "city": "North Chili"
  },
  {
    "value": "14516",
    "label": "North Rose - NY 14516",
    "county": "Wayne County",
    "state": "NY",
    "city": "North Rose"
  },
  {
    "value": "14517",
    "label": "Nunda - NY 14517",
    "county": "Livingston County",
    "state": "NY",
    "city": "Nunda"
  },
  {
    "value": "14519",
    "label": "Ontario - NY 14519",
    "county": "Wayne County",
    "state": "NY",
    "city": "Ontario"
  },
  {
    "value": "14521",
    "label": "Ovid - NY 14521",
    "county": "Seneca County",
    "state": "NY",
    "city": "Ovid"
  },
  {
    "value": "14522",
    "label": "Palmyra - NY 14522",
    "county": "Wayne County",
    "state": "NY",
    "city": "Palmyra"
  },
  {
    "value": "14525",
    "label": "Pavilion - NY 14525",
    "county": "Genesee County",
    "state": "NY",
    "city": "Pavilion"
  },
  {
    "value": "14526",
    "label": "Penfield - NY 14526",
    "county": "Monroe County",
    "state": "NY",
    "city": "Penfield"
  },
  {
    "value": "14527",
    "label": "Penn Yan - NY 14527",
    "county": "Yates County",
    "state": "NY",
    "city": "Penn Yan"
  },
  {
    "value": "14530",
    "label": "Perry - NY 14530",
    "county": "Wyoming County",
    "state": "NY",
    "city": "Perry"
  },
  {
    "value": "14532",
    "label": "Phelps - NY 14532",
    "county": "Ontario County",
    "state": "NY",
    "city": "Phelps"
  },
  {
    "value": "14533",
    "label": "Piffard - NY 14533",
    "county": "Livingston County",
    "state": "NY",
    "city": "Piffard"
  },
  {
    "value": "14534",
    "label": "Pittsford - NY 14534",
    "county": "Monroe County",
    "state": "NY",
    "city": "Pittsford"
  },
  {
    "value": "14536",
    "label": "Portageville - NY 14536",
    "county": "Wyoming County",
    "state": "NY",
    "city": "Portageville"
  },
  {
    "value": "14541",
    "label": "Romulus - NY 14541",
    "county": "Seneca County",
    "state": "NY",
    "city": "Romulus"
  },
  {
    "value": "14543",
    "label": "Rush - NY 14543",
    "county": "Monroe County",
    "state": "NY",
    "city": "Rush"
  },
  {
    "value": "14544",
    "label": "Rushville - NY 14544",
    "county": "Yates County",
    "state": "NY",
    "city": "Rushville"
  },
  {
    "value": "14545",
    "label": "Scottsburg - NY 14545",
    "county": "Livingston County",
    "state": "NY",
    "city": "Scottsburg"
  },
  {
    "value": "14546",
    "label": "Scottsville - NY 14546",
    "county": "Monroe County",
    "state": "NY",
    "city": "Scottsville"
  },
  {
    "value": "14548",
    "label": "Shortsville - NY 14548",
    "county": "Ontario County",
    "state": "NY",
    "city": "Shortsville"
  },
  {
    "value": "14550",
    "label": "Silver Springs - NY 14550",
    "county": "Wyoming County",
    "state": "NY",
    "city": "Silver Springs"
  },
  {
    "value": "14551",
    "label": "Sodus - NY 14551",
    "county": "Wayne County",
    "state": "NY",
    "city": "Sodus"
  },
  {
    "value": "14555",
    "label": "Sodus Point - NY 14555",
    "county": "Wayne County",
    "state": "NY",
    "city": "Sodus Point"
  },
  {
    "value": "14559",
    "label": "Spencerport - NY 14559",
    "county": "Monroe County",
    "state": "NY",
    "city": "Spencerport"
  },
  {
    "value": "14560",
    "label": "Springwater - NY 14560",
    "county": "Livingston County",
    "state": "NY",
    "city": "Springwater"
  },
  {
    "value": "14561",
    "label": "Stanley - NY 14561",
    "county": "Ontario County",
    "state": "NY",
    "city": "Stanley"
  },
  {
    "value": "14564",
    "label": "Victor - NY 14564",
    "county": "Ontario County",
    "state": "NY",
    "city": "Victor"
  },
  {
    "value": "14568",
    "label": "Walworth - NY 14568",
    "county": "Wayne County",
    "state": "NY",
    "city": "Walworth"
  },
  {
    "value": "14569",
    "label": "Warsaw - NY 14569",
    "county": "Wyoming County",
    "state": "NY",
    "city": "Warsaw"
  },
  {
    "value": "14571",
    "label": "Waterport - NY 14571",
    "county": "Orleans County",
    "state": "NY",
    "city": "Waterport"
  },
  {
    "value": "14572",
    "label": "Wayland - NY 14572",
    "county": "Steuben County",
    "state": "NY",
    "city": "Wayland"
  },
  {
    "value": "14580",
    "label": "Webster - NY 14580",
    "county": "Monroe County",
    "state": "NY",
    "city": "Webster"
  },
  {
    "value": "14585",
    "label": "West Bloomfield - NY 14585",
    "county": "Ontario County",
    "state": "NY",
    "city": "West Bloomfield"
  },
  {
    "value": "14586",
    "label": "West Henrietta - NY 14586",
    "county": "Monroe County",
    "state": "NY",
    "city": "West Henrietta"
  },
  {
    "value": "14589",
    "label": "Williamson - NY 14589",
    "county": "Wayne County",
    "state": "NY",
    "city": "Williamson"
  },
  {
    "value": "14590",
    "label": "Wolcott - NY 14590",
    "county": "Wayne County",
    "state": "NY",
    "city": "Wolcott"
  },
  {
    "value": "14591",
    "label": "Wyoming - NY 14591",
    "county": "Wyoming County",
    "state": "NY",
    "city": "Wyoming"
  },
  {
    "value": "14604",
    "label": "Rochester - NY 14604",
    "county": "Monroe County",
    "state": "NY",
    "city": "Rochester"
  },
  {
    "value": "14605",
    "label": "Rochester - NY 14605",
    "county": "Monroe County",
    "state": "NY",
    "city": "Rochester"
  },
  {
    "value": "14606",
    "label": "Rochester - NY 14606",
    "county": "Monroe County",
    "state": "NY",
    "city": "Rochester"
  },
  {
    "value": "14607",
    "label": "Rochester - NY 14607",
    "county": "Monroe County",
    "state": "NY",
    "city": "Rochester"
  },
  {
    "value": "14608",
    "label": "Rochester - NY 14608",
    "county": "Monroe County",
    "state": "NY",
    "city": "Rochester"
  },
  {
    "value": "14609",
    "label": "Rochester - NY 14609",
    "county": "Monroe County",
    "state": "NY",
    "city": "Rochester"
  },
  {
    "value": "14610",
    "label": "Rochester - NY 14610",
    "county": "Monroe County",
    "state": "NY",
    "city": "Rochester"
  },
  {
    "value": "14611",
    "label": "Rochester - NY 14611",
    "county": "Monroe County",
    "state": "NY",
    "city": "Rochester"
  },
  {
    "value": "14612",
    "label": "Rochester - NY 14612",
    "county": "Monroe County",
    "state": "NY",
    "city": "Rochester"
  },
  {
    "value": "14613",
    "label": "Rochester - NY 14613",
    "county": "Monroe County",
    "state": "NY",
    "city": "Rochester"
  },
  {
    "value": "14614",
    "label": "Rochester - NY 14614",
    "county": "Monroe County",
    "state": "NY",
    "city": "Rochester"
  },
  {
    "value": "14615",
    "label": "Rochester - NY 14615",
    "county": "Monroe County",
    "state": "NY",
    "city": "Rochester"
  },
  {
    "value": "14616",
    "label": "Rochester - NY 14616",
    "county": "Monroe County",
    "state": "NY",
    "city": "Rochester"
  },
  {
    "value": "14617",
    "label": "Rochester - NY 14617",
    "county": "Monroe County",
    "state": "NY",
    "city": "Rochester"
  },
  {
    "value": "14618",
    "label": "Rochester - NY 14618",
    "county": "Monroe County",
    "state": "NY",
    "city": "Rochester"
  },
  {
    "value": "14619",
    "label": "Rochester - NY 14619",
    "county": "Monroe County",
    "state": "NY",
    "city": "Rochester"
  },
  {
    "value": "14620",
    "label": "Rochester - NY 14620",
    "county": "Monroe County",
    "state": "NY",
    "city": "Rochester"
  },
  {
    "value": "14621",
    "label": "Rochester - NY 14621",
    "county": "Monroe County",
    "state": "NY",
    "city": "Rochester"
  },
  {
    "value": "14622",
    "label": "Rochester - NY 14622",
    "county": "Monroe County",
    "state": "NY",
    "city": "Rochester"
  },
  {
    "value": "14623",
    "label": "Rochester - NY 14623",
    "county": "Monroe County",
    "state": "NY",
    "city": "Rochester"
  },
  {
    "value": "14624",
    "label": "Rochester - NY 14624",
    "county": "Monroe County",
    "state": "NY",
    "city": "Rochester"
  },
  {
    "value": "14625",
    "label": "Rochester - NY 14625",
    "county": "Monroe County",
    "state": "NY",
    "city": "Rochester"
  },
  {
    "value": "14626",
    "label": "Rochester - NY 14626",
    "county": "Monroe County",
    "state": "NY",
    "city": "Rochester"
  },
  {
    "value": "14701",
    "label": "Jamestown - NY 14701",
    "county": "Chautauqua County",
    "state": "NY",
    "city": "Jamestown"
  },
  {
    "value": "14706",
    "label": "Allegany - NY 14706",
    "county": "Cattaraugus County",
    "state": "NY",
    "city": "Allegany"
  },
  {
    "value": "14708",
    "label": "Alma - NY 14708",
    "county": "Allegany County",
    "state": "NY",
    "city": "Alma"
  },
  {
    "value": "14709",
    "label": "Angelica - NY 14709",
    "county": "Allegany County",
    "state": "NY",
    "city": "Angelica"
  },
  {
    "value": "14710",
    "label": "Ashville - NY 14710",
    "county": "Chautauqua County",
    "state": "NY",
    "city": "Ashville"
  },
  {
    "value": "14711",
    "label": "Belfast - NY 14711",
    "county": "Allegany County",
    "state": "NY",
    "city": "Belfast"
  },
  {
    "value": "14712",
    "label": "Bemus Point - NY 14712",
    "county": "Chautauqua County",
    "state": "NY",
    "city": "Bemus Point"
  },
  {
    "value": "14714",
    "label": "Black Creek - NY 14714",
    "county": "Allegany County",
    "state": "NY",
    "city": "Black Creek"
  },
  {
    "value": "14715",
    "label": "Bolivar - NY 14715",
    "county": "Allegany County",
    "state": "NY",
    "city": "Bolivar"
  },
  {
    "value": "14716",
    "label": "Brocton - NY 14716",
    "county": "Chautauqua County",
    "state": "NY",
    "city": "Brocton"
  },
  {
    "value": "14717",
    "label": "Caneadea - NY 14717",
    "county": "Allegany County",
    "state": "NY",
    "city": "Caneadea"
  },
  {
    "value": "14718",
    "label": "Cassadaga - NY 14718",
    "county": "Chautauqua County",
    "state": "NY",
    "city": "Cassadaga"
  },
  {
    "value": "14719",
    "label": "Cattaraugus - NY 14719",
    "county": "Cattaraugus County",
    "state": "NY",
    "city": "Cattaraugus"
  },
  {
    "value": "14721",
    "label": "Ceres - NY 14721",
    "county": "Allegany County",
    "state": "NY",
    "city": "Ceres"
  },
  {
    "value": "14723",
    "label": "Cherry Creek - NY 14723",
    "county": "Chautauqua County",
    "state": "NY",
    "city": "Cherry Creek"
  },
  {
    "value": "14724",
    "label": "Clymer - NY 14724",
    "county": "Chautauqua County",
    "state": "NY",
    "city": "Clymer"
  },
  {
    "value": "14726",
    "label": "Conewango Valley - NY 14726",
    "county": "Cattaraugus County",
    "state": "NY",
    "city": "Conewango Valley"
  },
  {
    "value": "14727",
    "label": "Cuba - NY 14727",
    "county": "Allegany County",
    "state": "NY",
    "city": "Cuba"
  },
  {
    "value": "14728",
    "label": "Dewittville - NY 14728",
    "county": "Chautauqua County",
    "state": "NY",
    "city": "Dewittville"
  },
  {
    "value": "14729",
    "label": "East Otto - NY 14729",
    "county": "Cattaraugus County",
    "state": "NY",
    "city": "East Otto"
  },
  {
    "value": "14731",
    "label": "Ellicottville - NY 14731",
    "county": "Cattaraugus County",
    "state": "NY",
    "city": "Ellicottville"
  },
  {
    "value": "14733",
    "label": "Falconer - NY 14733",
    "county": "Chautauqua County",
    "state": "NY",
    "city": "Falconer"
  },
  {
    "value": "14735",
    "label": "Fillmore - NY 14735",
    "county": "Allegany County",
    "state": "NY",
    "city": "Fillmore"
  },
  {
    "value": "14736",
    "label": "Findley Lake - NY 14736",
    "county": "Chautauqua County",
    "state": "NY",
    "city": "Findley Lake"
  },
  {
    "value": "14737",
    "label": "Franklinville - NY 14737",
    "county": "Cattaraugus County",
    "state": "NY",
    "city": "Franklinville"
  },
  {
    "value": "14738",
    "label": "Frewsburg - NY 14738",
    "county": "Chautauqua County",
    "state": "NY",
    "city": "Frewsburg"
  },
  {
    "value": "14739",
    "label": "Friendship - NY 14739",
    "county": "Allegany County",
    "state": "NY",
    "city": "Friendship"
  },
  {
    "value": "14740",
    "label": "Gerry - NY 14740",
    "county": "Chautauqua County",
    "state": "NY",
    "city": "Gerry"
  },
  {
    "value": "14741",
    "label": "Great Valley - NY 14741",
    "county": "Cattaraugus County",
    "state": "NY",
    "city": "Great Valley"
  },
  {
    "value": "14743",
    "label": "Hinsdale - NY 14743",
    "county": "Cattaraugus County",
    "state": "NY",
    "city": "Hinsdale"
  },
  {
    "value": "14744",
    "label": "Houghton - NY 14744",
    "county": "Allegany County",
    "state": "NY",
    "city": "Houghton"
  },
  {
    "value": "14747",
    "label": "Kennedy - NY 14747",
    "county": "Chautauqua County",
    "state": "NY",
    "city": "Kennedy"
  },
  {
    "value": "14748",
    "label": "Kill Buck - NY 14748",
    "county": "Cattaraugus County",
    "state": "NY",
    "city": "Kill Buck"
  },
  {
    "value": "14750",
    "label": "Lakewood - NY 14750",
    "county": "Chautauqua County",
    "state": "NY",
    "city": "Lakewood"
  },
  {
    "value": "14753",
    "label": "Limestone - NY 14753",
    "county": "Cattaraugus County",
    "state": "NY",
    "city": "Limestone"
  },
  {
    "value": "14754",
    "label": "Little Genesee - NY 14754",
    "county": "Allegany County",
    "state": "NY",
    "city": "Little Genesee"
  },
  {
    "value": "14755",
    "label": "Little Valley - NY 14755",
    "county": "Cattaraugus County",
    "state": "NY",
    "city": "Little Valley"
  },
  {
    "value": "14757",
    "label": "Mayville - NY 14757",
    "county": "Chautauqua County",
    "state": "NY",
    "city": "Mayville"
  },
  {
    "value": "14760",
    "label": "Olean - NY 14760",
    "county": "Cattaraugus County",
    "state": "NY",
    "city": "Olean"
  },
  {
    "value": "14767",
    "label": "Panama - NY 14767",
    "county": "Chautauqua County",
    "state": "NY",
    "city": "Panama"
  },
  {
    "value": "14769",
    "label": "Portland - NY 14769",
    "county": "Chautauqua County",
    "state": "NY",
    "city": "Portland"
  },
  {
    "value": "14770",
    "label": "Portville - NY 14770",
    "county": "Cattaraugus County",
    "state": "NY",
    "city": "Portville"
  },
  {
    "value": "14772",
    "label": "Randolph - NY 14772",
    "county": "Cattaraugus County",
    "state": "NY",
    "city": "Randolph"
  },
  {
    "value": "14775",
    "label": "Ripley - NY 14775",
    "county": "Chautauqua County",
    "state": "NY",
    "city": "Ripley"
  },
  {
    "value": "14777",
    "label": "Rushford - NY 14777",
    "county": "Allegany County",
    "state": "NY",
    "city": "Rushford"
  },
  {
    "value": "14779",
    "label": "Salamanca - NY 14779",
    "county": "Cattaraugus County",
    "state": "NY",
    "city": "Salamanca"
  },
  {
    "value": "14781",
    "label": "Sherman - NY 14781",
    "county": "Chautauqua County",
    "state": "NY",
    "city": "Sherman"
  },
  {
    "value": "14782",
    "label": "Sinclairville - NY 14782",
    "county": "Chautauqua County",
    "state": "NY",
    "city": "Sinclairville"
  },
  {
    "value": "14784",
    "label": "Stockton - NY 14784",
    "county": "Chautauqua County",
    "state": "NY",
    "city": "Stockton"
  },
  {
    "value": "14787",
    "label": "Westfield - NY 14787",
    "county": "Chautauqua County",
    "state": "NY",
    "city": "Westfield"
  },
  {
    "value": "14801",
    "label": "Addison - NY 14801",
    "county": "Steuben County",
    "state": "NY",
    "city": "Addison"
  },
  {
    "value": "14802",
    "label": "Alfred - NY 14802",
    "county": "Allegany County",
    "state": "NY",
    "city": "Alfred"
  },
  {
    "value": "14803",
    "label": "Alfred Station - NY 14803",
    "county": "Allegany County",
    "state": "NY",
    "city": "Alfred Station"
  },
  {
    "value": "14804",
    "label": "Almond - NY 14804",
    "county": "Allegany County",
    "state": "NY",
    "city": "Almond"
  },
  {
    "value": "14805",
    "label": "Alpine - NY 14805",
    "county": "Schuyler County",
    "state": "NY",
    "city": "Alpine"
  },
  {
    "value": "14806",
    "label": "Andover - NY 14806",
    "county": "Allegany County",
    "state": "NY",
    "city": "Andover"
  },
  {
    "value": "14807",
    "label": "Arkport - NY 14807",
    "county": "Steuben County",
    "state": "NY",
    "city": "Arkport"
  },
  {
    "value": "14808",
    "label": "Atlanta - NY 14808",
    "county": "Steuben County",
    "state": "NY",
    "city": "Atlanta"
  },
  {
    "value": "14809",
    "label": "Avoca - NY 14809",
    "county": "Steuben County",
    "state": "NY",
    "city": "Avoca"
  },
  {
    "value": "14810",
    "label": "Bath - NY 14810",
    "county": "Steuben County",
    "state": "NY",
    "city": "Bath"
  },
  {
    "value": "14812",
    "label": "Beaver Dams - NY 14812",
    "county": "Schuyler County",
    "state": "NY",
    "city": "Beaver Dams"
  },
  {
    "value": "14813",
    "label": "Belmont - NY 14813",
    "county": "Allegany County",
    "state": "NY",
    "city": "Belmont"
  },
  {
    "value": "14814",
    "label": "Big Flats - NY 14814",
    "county": "Chemung County",
    "state": "NY",
    "city": "Big Flats"
  },
  {
    "value": "14815",
    "label": "Bradford - NY 14815",
    "county": "Schuyler County",
    "state": "NY",
    "city": "Bradford"
  },
  {
    "value": "14816",
    "label": "Breesport - NY 14816",
    "county": "Chemung County",
    "state": "NY",
    "city": "Breesport"
  },
  {
    "value": "14817",
    "label": "Brooktondale - NY 14817",
    "county": "Tompkins County",
    "state": "NY",
    "city": "Brooktondale"
  },
  {
    "value": "14818",
    "label": "Burdett - NY 14818",
    "county": "Schuyler County",
    "state": "NY",
    "city": "Burdett"
  },
  {
    "value": "14819",
    "label": "Cameron - NY 14819",
    "county": "Steuben County",
    "state": "NY",
    "city": "Cameron"
  },
  {
    "value": "14820",
    "label": "Cameron Mills - NY 14820",
    "county": "Steuben County",
    "state": "NY",
    "city": "Cameron Mills"
  },
  {
    "value": "14821",
    "label": "Campbell - NY 14821",
    "county": "Steuben County",
    "state": "NY",
    "city": "Campbell"
  },
  {
    "value": "14822",
    "label": "Canaseraga - NY 14822",
    "county": "Allegany County",
    "state": "NY",
    "city": "Canaseraga"
  },
  {
    "value": "14823",
    "label": "Canisteo - NY 14823",
    "county": "Steuben County",
    "state": "NY",
    "city": "Canisteo"
  },
  {
    "value": "14824",
    "label": "Cayuta - NY 14824",
    "county": "Schuyler County",
    "state": "NY",
    "city": "Cayuta"
  },
  {
    "value": "14825",
    "label": "Chemung - NY 14825",
    "county": "Chemung County",
    "state": "NY",
    "city": "Chemung"
  },
  {
    "value": "14826",
    "label": "Cohocton - NY 14826",
    "county": "Steuben County",
    "state": "NY",
    "city": "Cohocton"
  },
  {
    "value": "14830",
    "label": "Corning - NY 14830",
    "county": "Steuben County",
    "state": "NY",
    "city": "Corning"
  },
  {
    "value": "14836",
    "label": "Dalton - NY 14836",
    "county": "Livingston County",
    "state": "NY",
    "city": "Dalton"
  },
  {
    "value": "14837",
    "label": "Dundee - NY 14837",
    "county": "Yates County",
    "state": "NY",
    "city": "Dundee"
  },
  {
    "value": "14838",
    "label": "Erin - NY 14838",
    "county": "Chemung County",
    "state": "NY",
    "city": "Erin"
  },
  {
    "value": "14839",
    "label": "Greenwood - NY 14839",
    "county": "Steuben County",
    "state": "NY",
    "city": "Greenwood"
  },
  {
    "value": "14840",
    "label": "Hammondsport - NY 14840",
    "county": "Steuben County",
    "state": "NY",
    "city": "Hammondsport"
  },
  {
    "value": "14841",
    "label": "Hector - NY 14841",
    "county": "Schuyler County",
    "state": "NY",
    "city": "Hector"
  },
  {
    "value": "14842",
    "label": "Himrod - NY 14842",
    "county": "Yates County",
    "state": "NY",
    "city": "Himrod"
  },
  {
    "value": "14843",
    "label": "Hornell - NY 14843",
    "county": "Steuben County",
    "state": "NY",
    "city": "Hornell"
  },
  {
    "value": "14845",
    "label": "Horseheads - NY 14845",
    "county": "Chemung County",
    "state": "NY",
    "city": "Horseheads"
  },
  {
    "value": "14846",
    "label": "Hunt - NY 14846",
    "county": "Livingston County",
    "state": "NY",
    "city": "Hunt"
  },
  {
    "value": "14847",
    "label": "Interlaken - NY 14847",
    "county": "Seneca County",
    "state": "NY",
    "city": "Interlaken"
  },
  {
    "value": "14850",
    "label": "Ithaca - NY 14850",
    "county": "Tompkins County",
    "state": "NY",
    "city": "Ithaca"
  },
  {
    "value": "14853",
    "label": "Ithaca - NY 14853",
    "county": "Tompkins County",
    "state": "NY",
    "city": "Ithaca"
  },
  {
    "value": "14855",
    "label": "Jasper - NY 14855",
    "county": "Steuben County",
    "state": "NY",
    "city": "Jasper"
  },
  {
    "value": "14858",
    "label": "Lindley - NY 14858",
    "county": "Steuben County",
    "state": "NY",
    "city": "Lindley"
  },
  {
    "value": "14859",
    "label": "Lockwood - NY 14859",
    "county": "Tioga County",
    "state": "NY",
    "city": "Lockwood"
  },
  {
    "value": "14860",
    "label": "Lodi - NY 14860",
    "county": "Seneca County",
    "state": "NY",
    "city": "Lodi"
  },
  {
    "value": "14861",
    "label": "Lowman - NY 14861",
    "county": "Chemung County",
    "state": "NY",
    "city": "Lowman"
  },
  {
    "value": "14864",
    "label": "Millport - NY 14864",
    "county": "Chemung County",
    "state": "NY",
    "city": "Millport"
  },
  {
    "value": "14865",
    "label": "Montour Falls - NY 14865",
    "county": "Schuyler County",
    "state": "NY",
    "city": "Montour Falls"
  },
  {
    "value": "14867",
    "label": "Newfield - NY 14867",
    "county": "Tompkins County",
    "state": "NY",
    "city": "Newfield"
  },
  {
    "value": "14869",
    "label": "Odessa - NY 14869",
    "county": "Schuyler County",
    "state": "NY",
    "city": "Odessa"
  },
  {
    "value": "14870",
    "label": "Painted Post - NY 14870",
    "county": "Steuben County",
    "state": "NY",
    "city": "Painted Post"
  },
  {
    "value": "14871",
    "label": "Pine City - NY 14871",
    "county": "Chemung County",
    "state": "NY",
    "city": "Pine City"
  },
  {
    "value": "14872",
    "label": "Pine Valley - NY 14872",
    "county": "Chemung County",
    "state": "NY",
    "city": "Pine Valley"
  },
  {
    "value": "14873",
    "label": "Prattsburgh - NY 14873",
    "county": "Steuben County",
    "state": "NY",
    "city": "Prattsburgh"
  },
  {
    "value": "14874",
    "label": "Pulteney - NY 14874",
    "county": "Steuben County",
    "state": "NY",
    "city": "Pulteney"
  },
  {
    "value": "14877",
    "label": "Rexville - NY 14877",
    "county": "Steuben County",
    "state": "NY",
    "city": "Rexville"
  },
  {
    "value": "14878",
    "label": "Rock Stream - NY 14878",
    "county": "Schuyler County",
    "state": "NY",
    "city": "Rock Stream"
  },
  {
    "value": "14879",
    "label": "Savona - NY 14879",
    "county": "Steuben County",
    "state": "NY",
    "city": "Savona"
  },
  {
    "value": "14880",
    "label": "Scio - NY 14880",
    "county": "Allegany County",
    "state": "NY",
    "city": "Scio"
  },
  {
    "value": "14881",
    "label": "Slaterville Springs - NY 14881",
    "county": "Tompkins County",
    "state": "NY",
    "city": "Slaterville Springs"
  },
  {
    "value": "14882",
    "label": "Lansing - NY 14882",
    "county": "Tompkins County",
    "state": "NY",
    "city": "Lansing"
  },
  {
    "value": "14883",
    "label": "Spencer - NY 14883",
    "county": "Tioga County",
    "state": "NY",
    "city": "Spencer"
  },
  {
    "value": "14884",
    "label": "Swain - NY 14884",
    "county": "Allegany County",
    "state": "NY",
    "city": "Swain"
  },
  {
    "value": "14885",
    "label": "Troupsburg - NY 14885",
    "county": "Steuben County",
    "state": "NY",
    "city": "Troupsburg"
  },
  {
    "value": "14886",
    "label": "Trumansburg - NY 14886",
    "county": "Tompkins County",
    "state": "NY",
    "city": "Trumansburg"
  },
  {
    "value": "14889",
    "label": "Van Etten - NY 14889",
    "county": "Chemung County",
    "state": "NY",
    "city": "Van Etten"
  },
  {
    "value": "14891",
    "label": "Watkins Glen - NY 14891",
    "county": "Schuyler County",
    "state": "NY",
    "city": "Watkins Glen"
  },
  {
    "value": "14892",
    "label": "Waverly - NY 14892",
    "county": "Tioga County",
    "state": "NY",
    "city": "Waverly"
  },
  {
    "value": "14894",
    "label": "Wellsburg - NY 14894",
    "county": "Chemung County",
    "state": "NY",
    "city": "Wellsburg"
  },
  {
    "value": "14895",
    "label": "Wellsville - NY 14895",
    "county": "Allegany County",
    "state": "NY",
    "city": "Wellsville"
  },
  {
    "value": "14897",
    "label": "Whitesville - NY 14897",
    "county": "Allegany County",
    "state": "NY",
    "city": "Whitesville"
  },
  {
    "value": "14898",
    "label": "Woodhull - NY 14898",
    "county": "Steuben County",
    "state": "NY",
    "city": "Woodhull"
  },
  {
    "value": "14901",
    "label": "Elmira - NY 14901",
    "county": "Chemung County",
    "state": "NY",
    "city": "Elmira"
  },
  {
    "value": "14903",
    "label": "Elmira - NY 14903",
    "county": "Chemung County",
    "state": "NY",
    "city": "Elmira"
  },
  {
    "value": "14904",
    "label": "Elmira - NY 14904",
    "county": "Chemung County",
    "state": "NY",
    "city": "Elmira"
  },
  {
    "value": "14905",
    "label": "Elmira - NY 14905",
    "county": "Chemung County",
    "state": "NY",
    "city": "Elmira"
  },
  {
    "value": "14925",
    "label": "Elmira - NY 14925",
    "county": "Chemung County",
    "state": "NY",
    "city": "Elmira"
  },
  {
    "value": "27006",
    "label": "Advance - NC 27006",
    "county": "Davie County",
    "state": "NC",
    "city": "Advance"
  },
  {
    "value": "27007",
    "label": "Ararat - NC 27007",
    "county": "Surry County",
    "state": "NC",
    "city": "Ararat"
  },
  {
    "value": "27009",
    "label": "Belews Creek - NC 27009",
    "county": "Forsyth County",
    "state": "NC",
    "city": "Belews Creek"
  },
  {
    "value": "27011",
    "label": "Boonville - NC 27011",
    "county": "Yadkin County",
    "state": "NC",
    "city": "Boonville"
  },
  {
    "value": "27012",
    "label": "Clemmons - NC 27012",
    "county": "Forsyth County",
    "state": "NC",
    "city": "Clemmons"
  },
  {
    "value": "27013",
    "label": "Cleveland - NC 27013",
    "county": "Rowan County",
    "state": "NC",
    "city": "Cleveland"
  },
  {
    "value": "27016",
    "label": "Danbury - NC 27016",
    "county": "Stokes County",
    "state": "NC",
    "city": "Danbury"
  },
  {
    "value": "27017",
    "label": "Dobson - NC 27017",
    "county": "Surry County",
    "state": "NC",
    "city": "Dobson"
  },
  {
    "value": "27018",
    "label": "East Bend - NC 27018",
    "county": "Yadkin County",
    "state": "NC",
    "city": "East Bend"
  },
  {
    "value": "27019",
    "label": "Germanton - NC 27019",
    "county": "Stokes County",
    "state": "NC",
    "city": "Germanton"
  },
  {
    "value": "27020",
    "label": "Hamptonville - NC 27020",
    "county": "Yadkin County",
    "state": "NC",
    "city": "Hamptonville"
  },
  {
    "value": "27021",
    "label": "King - NC 27021",
    "county": "Stokes County",
    "state": "NC",
    "city": "King"
  },
  {
    "value": "27022",
    "label": "Lawsonville - NC 27022",
    "county": "Stokes County",
    "state": "NC",
    "city": "Lawsonville"
  },
  {
    "value": "27023",
    "label": "Lewisville - NC 27023",
    "county": "Forsyth County",
    "state": "NC",
    "city": "Lewisville"
  },
  {
    "value": "27024",
    "label": "Lowgap - NC 27024",
    "county": "Surry County",
    "state": "NC",
    "city": "Lowgap"
  },
  {
    "value": "27025",
    "label": "Madison - NC 27025",
    "county": "Rockingham County",
    "state": "NC",
    "city": "Madison"
  },
  {
    "value": "27027",
    "label": "Mayodan - NC 27027",
    "county": "Rockingham County",
    "state": "NC",
    "city": "Mayodan"
  },
  {
    "value": "27028",
    "label": "Mocksville - NC 27028",
    "county": "Davie County",
    "state": "NC",
    "city": "Mocksville"
  },
  {
    "value": "27030",
    "label": "Mount Airy - NC 27030",
    "county": "Surry County",
    "state": "NC",
    "city": "Mount Airy"
  },
  {
    "value": "27040",
    "label": "Pfafftown - NC 27040",
    "county": "Forsyth County",
    "state": "NC",
    "city": "Pfafftown"
  },
  {
    "value": "27041",
    "label": "Pilot Mountain - NC 27041",
    "county": "Surry County",
    "state": "NC",
    "city": "Pilot Mountain"
  },
  {
    "value": "27042",
    "label": "Pine Hall - NC 27042",
    "county": "Stokes County",
    "state": "NC",
    "city": "Pine Hall"
  },
  {
    "value": "27043",
    "label": "Pinnacle - NC 27043",
    "county": "Stokes County",
    "state": "NC",
    "city": "Pinnacle"
  },
  {
    "value": "27045",
    "label": "Rural Hall - NC 27045",
    "county": "Forsyth County",
    "state": "NC",
    "city": "Rural Hall"
  },
  {
    "value": "27046",
    "label": "Sandy Ridge - NC 27046",
    "county": "Stokes County",
    "state": "NC",
    "city": "Sandy Ridge"
  },
  {
    "value": "27047",
    "label": "Siloam - NC 27047",
    "county": "Surry County",
    "state": "NC",
    "city": "Siloam"
  },
  {
    "value": "27048",
    "label": "Stoneville - NC 27048",
    "county": "Rockingham County",
    "state": "NC",
    "city": "Stoneville"
  },
  {
    "value": "27050",
    "label": "Tobaccoville - NC 27050",
    "county": "Forsyth County",
    "state": "NC",
    "city": "Tobaccoville"
  },
  {
    "value": "27051",
    "label": "Walkertown - NC 27051",
    "county": "Forsyth County",
    "state": "NC",
    "city": "Walkertown"
  },
  {
    "value": "27052",
    "label": "Walnut Cove - NC 27052",
    "county": "Stokes County",
    "state": "NC",
    "city": "Walnut Cove"
  },
  {
    "value": "27053",
    "label": "Westfield - NC 27053",
    "county": "Stokes County",
    "state": "NC",
    "city": "Westfield"
  },
  {
    "value": "27054",
    "label": "Woodleaf - NC 27054",
    "county": "Rowan County",
    "state": "NC",
    "city": "Woodleaf"
  },
  {
    "value": "27055",
    "label": "Yadkinville - NC 27055",
    "county": "Yadkin County",
    "state": "NC",
    "city": "Yadkinville"
  },
  {
    "value": "27101",
    "label": "Winston Salem - NC 27101",
    "county": "Forsyth County",
    "state": "NC",
    "city": "Winston Salem"
  },
  {
    "value": "27103",
    "label": "Winston Salem - NC 27103",
    "county": "Forsyth County",
    "state": "NC",
    "city": "Winston Salem"
  },
  {
    "value": "27104",
    "label": "Winston Salem - NC 27104",
    "county": "Forsyth County",
    "state": "NC",
    "city": "Winston Salem"
  },
  {
    "value": "27105",
    "label": "Winston Salem - NC 27105",
    "county": "Forsyth County",
    "state": "NC",
    "city": "Winston Salem"
  },
  {
    "value": "27106",
    "label": "Winston Salem - NC 27106",
    "county": "Forsyth County",
    "state": "NC",
    "city": "Winston Salem"
  },
  {
    "value": "27107",
    "label": "Winston Salem - NC 27107",
    "county": "Forsyth County",
    "state": "NC",
    "city": "Winston Salem"
  },
  {
    "value": "27109",
    "label": "Winston Salem - NC 27109",
    "county": "Forsyth County",
    "state": "NC",
    "city": "Winston Salem"
  },
  {
    "value": "27127",
    "label": "Winston Salem - NC 27127",
    "county": "Forsyth County",
    "state": "NC",
    "city": "Winston Salem"
  },
  {
    "value": "27203",
    "label": "Asheboro - NC 27203",
    "county": "Randolph County",
    "state": "NC",
    "city": "Asheboro"
  },
  {
    "value": "27205",
    "label": "Asheboro - NC 27205",
    "county": "Randolph County",
    "state": "NC",
    "city": "Asheboro"
  },
  {
    "value": "27207",
    "label": "Bear Creek - NC 27207",
    "county": "Chatham County",
    "state": "NC",
    "city": "Bear Creek"
  },
  {
    "value": "27208",
    "label": "Bennett - NC 27208",
    "county": "Chatham County",
    "state": "NC",
    "city": "Bennett"
  },
  {
    "value": "27209",
    "label": "Biscoe - NC 27209",
    "county": "Montgomery County",
    "state": "NC",
    "city": "Biscoe"
  },
  {
    "value": "27212",
    "label": "Blanch - NC 27212",
    "county": "Caswell County",
    "state": "NC",
    "city": "Blanch"
  },
  {
    "value": "27214",
    "label": "Browns Summit - NC 27214",
    "county": "Guilford County",
    "state": "NC",
    "city": "Browns Summit"
  },
  {
    "value": "27215",
    "label": "Burlington - NC 27215",
    "county": "Alamance County",
    "state": "NC",
    "city": "Burlington"
  },
  {
    "value": "27217",
    "label": "Burlington - NC 27217",
    "county": "Alamance County",
    "state": "NC",
    "city": "Burlington"
  },
  {
    "value": "27229",
    "label": "Candor - NC 27229",
    "county": "Montgomery County",
    "state": "NC",
    "city": "Candor"
  },
  {
    "value": "27231",
    "label": "Cedar Grove - NC 27231",
    "county": "Orange County",
    "state": "NC",
    "city": "Cedar Grove"
  },
  {
    "value": "27233",
    "label": "Climax - NC 27233",
    "county": "Randolph County",
    "state": "NC",
    "city": "Climax"
  },
  {
    "value": "27235",
    "label": "Colfax - NC 27235",
    "county": "Guilford County",
    "state": "NC",
    "city": "Colfax"
  },
  {
    "value": "27239",
    "label": "Denton - NC 27239",
    "county": "Davidson County",
    "state": "NC",
    "city": "Denton"
  },
  {
    "value": "27242",
    "label": "Eagle Springs - NC 27242",
    "county": "Moore County",
    "state": "NC",
    "city": "Eagle Springs"
  },
  {
    "value": "27243",
    "label": "Efland - NC 27243",
    "county": "Orange County",
    "state": "NC",
    "city": "Efland"
  },
  {
    "value": "27244",
    "label": "Elon - NC 27244",
    "county": "Alamance County",
    "state": "NC",
    "city": "Elon"
  },
  {
    "value": "27248",
    "label": "Franklinville - NC 27248",
    "county": "Randolph County",
    "state": "NC",
    "city": "Franklinville"
  },
  {
    "value": "27249",
    "label": "Gibsonville - NC 27249",
    "county": "Guilford County",
    "state": "NC",
    "city": "Gibsonville"
  },
  {
    "value": "27252",
    "label": "Goldston - NC 27252",
    "county": "Chatham County",
    "state": "NC",
    "city": "Goldston"
  },
  {
    "value": "27253",
    "label": "Graham - NC 27253",
    "county": "Alamance County",
    "state": "NC",
    "city": "Graham"
  },
  {
    "value": "27258",
    "label": "Haw River - NC 27258",
    "county": "Alamance County",
    "state": "NC",
    "city": "Haw River"
  },
  {
    "value": "27260",
    "label": "High Point - NC 27260",
    "county": "Guilford County",
    "state": "NC",
    "city": "High Point"
  },
  {
    "value": "27262",
    "label": "High Point - NC 27262",
    "county": "Guilford County",
    "state": "NC",
    "city": "High Point"
  },
  {
    "value": "27263",
    "label": "High Point - NC 27263",
    "county": "Randolph County",
    "state": "NC",
    "city": "High Point"
  },
  {
    "value": "27265",
    "label": "High Point - NC 27265",
    "county": "Guilford County",
    "state": "NC",
    "city": "High Point"
  },
  {
    "value": "27278",
    "label": "Hillsborough - NC 27278",
    "county": "Orange County",
    "state": "NC",
    "city": "Hillsborough"
  },
  {
    "value": "27281",
    "label": "Jackson Springs - NC 27281",
    "county": "Moore County",
    "state": "NC",
    "city": "Jackson Springs"
  },
  {
    "value": "27282",
    "label": "Jamestown - NC 27282",
    "county": "Guilford County",
    "state": "NC",
    "city": "Jamestown"
  },
  {
    "value": "27283",
    "label": "Julian - NC 27283",
    "county": "Guilford County",
    "state": "NC",
    "city": "Julian"
  },
  {
    "value": "27284",
    "label": "Kernersville - NC 27284",
    "county": "Forsyth County",
    "state": "NC",
    "city": "Kernersville"
  },
  {
    "value": "27288",
    "label": "Eden - NC 27288",
    "county": "Rockingham County",
    "state": "NC",
    "city": "Eden"
  },
  {
    "value": "27291",
    "label": "Leasburg - NC 27291",
    "county": "Caswell County",
    "state": "NC",
    "city": "Leasburg"
  },
  {
    "value": "27292",
    "label": "Lexington - NC 27292",
    "county": "Davidson County",
    "state": "NC",
    "city": "Lexington"
  },
  {
    "value": "27295",
    "label": "Lexington - NC 27295",
    "county": "Davidson County",
    "state": "NC",
    "city": "Lexington"
  },
  {
    "value": "27298",
    "label": "Liberty - NC 27298",
    "county": "Randolph County",
    "state": "NC",
    "city": "Liberty"
  },
  {
    "value": "27299",
    "label": "Linwood - NC 27299",
    "county": "Davidson County",
    "state": "NC",
    "city": "Linwood"
  },
  {
    "value": "27301",
    "label": "Mc Leansville - NC 27301",
    "county": "Guilford County",
    "state": "NC",
    "city": "Mc Leansville"
  },
  {
    "value": "27302",
    "label": "Mebane - NC 27302",
    "county": "Alamance County",
    "state": "NC",
    "city": "Mebane"
  },
  {
    "value": "27305",
    "label": "Milton - NC 27305",
    "county": "Caswell County",
    "state": "NC",
    "city": "Milton"
  },
  {
    "value": "27306",
    "label": "Mount Gilead - NC 27306",
    "county": "Montgomery County",
    "state": "NC",
    "city": "Mount Gilead"
  },
  {
    "value": "27310",
    "label": "Oak Ridge - NC 27310",
    "county": "Guilford County",
    "state": "NC",
    "city": "Oak Ridge"
  },
  {
    "value": "27311",
    "label": "Pelham - NC 27311",
    "county": "Caswell County",
    "state": "NC",
    "city": "Pelham"
  },
  {
    "value": "27312",
    "label": "Pittsboro - NC 27312",
    "county": "Chatham County",
    "state": "NC",
    "city": "Pittsboro"
  },
  {
    "value": "27313",
    "label": "Pleasant Garden - NC 27313",
    "county": "Guilford County",
    "state": "NC",
    "city": "Pleasant Garden"
  },
  {
    "value": "27314",
    "label": "Prospect Hill - NC 27314",
    "county": "Caswell County",
    "state": "NC",
    "city": "Prospect Hill"
  },
  {
    "value": "27315",
    "label": "Providence - NC 27315",
    "county": "Caswell County",
    "state": "NC",
    "city": "Providence"
  },
  {
    "value": "27316",
    "label": "Ramseur - NC 27316",
    "county": "Randolph County",
    "state": "NC",
    "city": "Ramseur"
  },
  {
    "value": "27317",
    "label": "Randleman - NC 27317",
    "county": "Randolph County",
    "state": "NC",
    "city": "Randleman"
  },
  {
    "value": "27320",
    "label": "Reidsville - NC 27320",
    "county": "Rockingham County",
    "state": "NC",
    "city": "Reidsville"
  },
  {
    "value": "27325",
    "label": "Robbins - NC 27325",
    "county": "Moore County",
    "state": "NC",
    "city": "Robbins"
  },
  {
    "value": "27326",
    "label": "Ruffin - NC 27326",
    "county": "Rockingham County",
    "state": "NC",
    "city": "Ruffin"
  },
  {
    "value": "27330",
    "label": "Sanford - NC 27330",
    "county": "Lee County",
    "state": "NC",
    "city": "Sanford"
  },
  {
    "value": "27332",
    "label": "Sanford - NC 27332",
    "county": "Lee County",
    "state": "NC",
    "city": "Sanford"
  },
  {
    "value": "27341",
    "label": "Seagrove - NC 27341",
    "county": "Randolph County",
    "state": "NC",
    "city": "Seagrove"
  },
  {
    "value": "27343",
    "label": "Semora - NC 27343",
    "county": "Person County",
    "state": "NC",
    "city": "Semora"
  },
  {
    "value": "27344",
    "label": "Siler City - NC 27344",
    "county": "Chatham County",
    "state": "NC",
    "city": "Siler City"
  },
  {
    "value": "27349",
    "label": "Snow Camp - NC 27349",
    "county": "Alamance County",
    "state": "NC",
    "city": "Snow Camp"
  },
  {
    "value": "27350",
    "label": "Sophia - NC 27350",
    "county": "Randolph County",
    "state": "NC",
    "city": "Sophia"
  },
  {
    "value": "27355",
    "label": "Staley - NC 27355",
    "county": "Randolph County",
    "state": "NC",
    "city": "Staley"
  },
  {
    "value": "27356",
    "label": "Star - NC 27356",
    "county": "Montgomery County",
    "state": "NC",
    "city": "Star"
  },
  {
    "value": "27357",
    "label": "Stokesdale - NC 27357",
    "county": "Rockingham County",
    "state": "NC",
    "city": "Stokesdale"
  },
  {
    "value": "27358",
    "label": "Summerfield - NC 27358",
    "county": "Guilford County",
    "state": "NC",
    "city": "Summerfield"
  },
  {
    "value": "27360",
    "label": "Thomasville - NC 27360",
    "county": "Davidson County",
    "state": "NC",
    "city": "Thomasville"
  },
  {
    "value": "27370",
    "label": "Trinity - NC 27370",
    "county": "Randolph County",
    "state": "NC",
    "city": "Trinity"
  },
  {
    "value": "27371",
    "label": "Troy - NC 27371",
    "county": "Montgomery County",
    "state": "NC",
    "city": "Troy"
  },
  {
    "value": "27376",
    "label": "West End - NC 27376",
    "county": "Moore County",
    "state": "NC",
    "city": "West End"
  },
  {
    "value": "27377",
    "label": "Whitsett - NC 27377",
    "county": "Guilford County",
    "state": "NC",
    "city": "Whitsett"
  },
  {
    "value": "27379",
    "label": "Yanceyville - NC 27379",
    "county": "Caswell County",
    "state": "NC",
    "city": "Yanceyville"
  },
  {
    "value": "27395",
    "label": "Greensboro - NC 27395",
    "county": "Guilford County",
    "state": "NC",
    "city": "Greensboro"
  },
  {
    "value": "27401",
    "label": "Greensboro - NC 27401",
    "county": "Guilford County",
    "state": "NC",
    "city": "Greensboro"
  },
  {
    "value": "27403",
    "label": "Greensboro - NC 27403",
    "county": "Guilford County",
    "state": "NC",
    "city": "Greensboro"
  },
  {
    "value": "27405",
    "label": "Greensboro - NC 27405",
    "county": "Guilford County",
    "state": "NC",
    "city": "Greensboro"
  },
  {
    "value": "27406",
    "label": "Greensboro - NC 27406",
    "county": "Guilford County",
    "state": "NC",
    "city": "Greensboro"
  },
  {
    "value": "27407",
    "label": "Greensboro - NC 27407",
    "county": "Guilford County",
    "state": "NC",
    "city": "Greensboro"
  },
  {
    "value": "27408",
    "label": "Greensboro - NC 27408",
    "county": "Guilford County",
    "state": "NC",
    "city": "Greensboro"
  },
  {
    "value": "27409",
    "label": "Greensboro - NC 27409",
    "county": "Guilford County",
    "state": "NC",
    "city": "Greensboro"
  },
  {
    "value": "27410",
    "label": "Greensboro - NC 27410",
    "county": "Guilford County",
    "state": "NC",
    "city": "Greensboro"
  },
  {
    "value": "27455",
    "label": "Greensboro - NC 27455",
    "county": "Guilford County",
    "state": "NC",
    "city": "Greensboro"
  },
  {
    "value": "27495",
    "label": "Greensboro - NC 27495",
    "county": "Guilford County",
    "state": "NC",
    "city": "Greensboro"
  },
  {
    "value": "27501",
    "label": "Angier - NC 27501",
    "county": "Harnett County",
    "state": "NC",
    "city": "Angier"
  },
  {
    "value": "27502",
    "label": "Apex - NC 27502",
    "county": "Wake County",
    "state": "NC",
    "city": "Apex"
  },
  {
    "value": "27503",
    "label": "Bahama - NC 27503",
    "county": "Durham County",
    "state": "NC",
    "city": "Bahama"
  },
  {
    "value": "27504",
    "label": "Benson - NC 27504",
    "county": "Johnston County",
    "state": "NC",
    "city": "Benson"
  },
  {
    "value": "27505",
    "label": "Broadway - NC 27505",
    "county": "Harnett County",
    "state": "NC",
    "city": "Broadway"
  },
  {
    "value": "27507",
    "label": "Bullock - NC 27507",
    "county": "Granville County",
    "state": "NC",
    "city": "Bullock"
  },
  {
    "value": "27508",
    "label": "Bunn - NC 27508",
    "county": "Franklin County",
    "state": "NC",
    "city": "Bunn"
  },
  {
    "value": "27509",
    "label": "Butner - NC 27509",
    "county": "Granville County",
    "state": "NC",
    "city": "Butner"
  },
  {
    "value": "27510",
    "label": "Carrboro - NC 27510",
    "county": "Orange County",
    "state": "NC",
    "city": "Carrboro"
  },
  {
    "value": "27511",
    "label": "Cary - NC 27511",
    "county": "Wake County",
    "state": "NC",
    "city": "Cary"
  },
  {
    "value": "27513",
    "label": "Cary - NC 27513",
    "county": "Wake County",
    "state": "NC",
    "city": "Cary"
  },
  {
    "value": "27514",
    "label": "Chapel Hill - NC 27514",
    "county": "Orange County",
    "state": "NC",
    "city": "Chapel Hill"
  },
  {
    "value": "27516",
    "label": "Chapel Hill - NC 27516",
    "county": "Orange County",
    "state": "NC",
    "city": "Chapel Hill"
  },
  {
    "value": "27517",
    "label": "Chapel Hill - NC 27517",
    "county": "Orange County",
    "state": "NC",
    "city": "Chapel Hill"
  },
  {
    "value": "27518",
    "label": "Cary - NC 27518",
    "county": "Wake County",
    "state": "NC",
    "city": "Cary"
  },
  {
    "value": "27519",
    "label": "Cary - NC 27519",
    "county": "Wake County",
    "state": "NC",
    "city": "Cary"
  },
  {
    "value": "27520",
    "label": "Clayton - NC 27520",
    "county": "Johnston County",
    "state": "NC",
    "city": "Clayton"
  },
  {
    "value": "27521",
    "label": "Coats - NC 27521",
    "county": "Harnett County",
    "state": "NC",
    "city": "Coats"
  },
  {
    "value": "27522",
    "label": "Creedmoor - NC 27522",
    "county": "Granville County",
    "state": "NC",
    "city": "Creedmoor"
  },
  {
    "value": "27523",
    "label": "Apex - NC 27523",
    "county": "Wake County",
    "state": "NC",
    "city": "Apex"
  },
  {
    "value": "27524",
    "label": "Four Oaks - NC 27524",
    "county": "Johnston County",
    "state": "NC",
    "city": "Four Oaks"
  },
  {
    "value": "27525",
    "label": "Franklinton - NC 27525",
    "county": "Franklin County",
    "state": "NC",
    "city": "Franklinton"
  },
  {
    "value": "27526",
    "label": "Fuquay Varina - NC 27526",
    "county": "Wake County",
    "state": "NC",
    "city": "Fuquay Varina"
  },
  {
    "value": "27527",
    "label": "Clayton - NC 27527",
    "county": "Johnston County",
    "state": "NC",
    "city": "Clayton"
  },
  {
    "value": "27528",
    "label": "Clayton - NC 27528",
    "county": "Johnston County",
    "state": "NC",
    "city": "Clayton"
  },
  {
    "value": "27529",
    "label": "Garner - NC 27529",
    "county": "Wake County",
    "state": "NC",
    "city": "Garner"
  },
  {
    "value": "27530",
    "label": "Goldsboro - NC 27530",
    "county": "Wayne County",
    "state": "NC",
    "city": "Goldsboro"
  },
  {
    "value": "27531",
    "label": "Goldsboro - NC 27531",
    "county": "Wayne County",
    "state": "NC",
    "city": "Goldsboro"
  },
  {
    "value": "27534",
    "label": "Goldsboro - NC 27534",
    "county": "Wayne County",
    "state": "NC",
    "city": "Goldsboro"
  },
  {
    "value": "27536",
    "label": "Henderson - NC 27536",
    "county": "Vance County",
    "state": "NC",
    "city": "Henderson"
  },
  {
    "value": "27537",
    "label": "Henderson - NC 27537",
    "county": "Vance County",
    "state": "NC",
    "city": "Henderson"
  },
  {
    "value": "27539",
    "label": "Apex - NC 27539",
    "county": "Wake County",
    "state": "NC",
    "city": "Apex"
  },
  {
    "value": "27540",
    "label": "Holly Springs - NC 27540",
    "county": "Wake County",
    "state": "NC",
    "city": "Holly Springs"
  },
  {
    "value": "27541",
    "label": "Hurdle Mills - NC 27541",
    "county": "Person County",
    "state": "NC",
    "city": "Hurdle Mills"
  },
  {
    "value": "27542",
    "label": "Kenly - NC 27542",
    "county": "Johnston County",
    "state": "NC",
    "city": "Kenly"
  },
  {
    "value": "27544",
    "label": "Kittrell - NC 27544",
    "county": "Vance County",
    "state": "NC",
    "city": "Kittrell"
  },
  {
    "value": "27545",
    "label": "Knightdale - NC 27545",
    "county": "Wake County",
    "state": "NC",
    "city": "Knightdale"
  },
  {
    "value": "27546",
    "label": "Lillington - NC 27546",
    "county": "Harnett County",
    "state": "NC",
    "city": "Lillington"
  },
  {
    "value": "27549",
    "label": "Louisburg - NC 27549",
    "county": "Franklin County",
    "state": "NC",
    "city": "Louisburg"
  },
  {
    "value": "27551",
    "label": "Macon - NC 27551",
    "county": "Warren County",
    "state": "NC",
    "city": "Macon"
  },
  {
    "value": "27553",
    "label": "Manson - NC 27553",
    "county": "Warren County",
    "state": "NC",
    "city": "Manson"
  },
  {
    "value": "27557",
    "label": "Middlesex - NC 27557",
    "county": "Nash County",
    "state": "NC",
    "city": "Middlesex"
  },
  {
    "value": "27559",
    "label": "Moncure - NC 27559",
    "county": "Chatham County",
    "state": "NC",
    "city": "Moncure"
  },
  {
    "value": "27560",
    "label": "Morrisville - NC 27560",
    "county": "Wake County",
    "state": "NC",
    "city": "Morrisville"
  },
  {
    "value": "27562",
    "label": "New Hill - NC 27562",
    "county": "Chatham County",
    "state": "NC",
    "city": "New Hill"
  },
  {
    "value": "27563",
    "label": "Norlina - NC 27563",
    "county": "Warren County",
    "state": "NC",
    "city": "Norlina"
  },
  {
    "value": "27564",
    "label": "Creedmoor - NC 27564",
    "county": "Granville County",
    "state": "NC",
    "city": "Creedmoor"
  },
  {
    "value": "27565",
    "label": "Oxford - NC 27565",
    "county": "Granville County",
    "state": "NC",
    "city": "Oxford"
  },
  {
    "value": "27569",
    "label": "Princeton - NC 27569",
    "county": "Johnston County",
    "state": "NC",
    "city": "Princeton"
  },
  {
    "value": "27571",
    "label": "Rolesville - NC 27571",
    "county": "Wake County",
    "state": "NC",
    "city": "Rolesville"
  },
  {
    "value": "27572",
    "label": "Rougemont - NC 27572",
    "county": "Person County",
    "state": "NC",
    "city": "Rougemont"
  },
  {
    "value": "27573",
    "label": "Roxboro - NC 27573",
    "county": "Person County",
    "state": "NC",
    "city": "Roxboro"
  },
  {
    "value": "27574",
    "label": "Roxboro - NC 27574",
    "county": "Person County",
    "state": "NC",
    "city": "Roxboro"
  },
  {
    "value": "27576",
    "label": "Selma - NC 27576",
    "county": "Johnston County",
    "state": "NC",
    "city": "Selma"
  },
  {
    "value": "27577",
    "label": "Smithfield - NC 27577",
    "county": "Johnston County",
    "state": "NC",
    "city": "Smithfield"
  },
  {
    "value": "27581",
    "label": "Stem - NC 27581",
    "county": "Granville County",
    "state": "NC",
    "city": "Stem"
  },
  {
    "value": "27583",
    "label": "Timberlake - NC 27583",
    "county": "Person County",
    "state": "NC",
    "city": "Timberlake"
  },
  {
    "value": "27587",
    "label": "Wake Forest - NC 27587",
    "county": "Wake County",
    "state": "NC",
    "city": "Wake Forest"
  },
  {
    "value": "27589",
    "label": "Warrenton - NC 27589",
    "county": "Warren County",
    "state": "NC",
    "city": "Warrenton"
  },
  {
    "value": "27591",
    "label": "Wendell - NC 27591",
    "county": "Wake County",
    "state": "NC",
    "city": "Wendell"
  },
  {
    "value": "27592",
    "label": "Willow Spring - NC 27592",
    "county": "Wake County",
    "state": "NC",
    "city": "Willow Spring"
  },
  {
    "value": "27596",
    "label": "Youngsville - NC 27596",
    "county": "Franklin County",
    "state": "NC",
    "city": "Youngsville"
  },
  {
    "value": "27597",
    "label": "Zebulon - NC 27597",
    "county": "Wake County",
    "state": "NC",
    "city": "Zebulon"
  },
  {
    "value": "27601",
    "label": "Raleigh - NC 27601",
    "county": "Wake County",
    "state": "NC",
    "city": "Raleigh"
  },
  {
    "value": "27603",
    "label": "Raleigh - NC 27603",
    "county": "Wake County",
    "state": "NC",
    "city": "Raleigh"
  },
  {
    "value": "27604",
    "label": "Raleigh - NC 27604",
    "county": "Wake County",
    "state": "NC",
    "city": "Raleigh"
  },
  {
    "value": "27605",
    "label": "Raleigh - NC 27605",
    "county": "Wake County",
    "state": "NC",
    "city": "Raleigh"
  },
  {
    "value": "27606",
    "label": "Raleigh - NC 27606",
    "county": "Wake County",
    "state": "NC",
    "city": "Raleigh"
  },
  {
    "value": "27607",
    "label": "Raleigh - NC 27607",
    "county": "Wake County",
    "state": "NC",
    "city": "Raleigh"
  },
  {
    "value": "27608",
    "label": "Raleigh - NC 27608",
    "county": "Wake County",
    "state": "NC",
    "city": "Raleigh"
  },
  {
    "value": "27609",
    "label": "Raleigh - NC 27609",
    "county": "Wake County",
    "state": "NC",
    "city": "Raleigh"
  },
  {
    "value": "27610",
    "label": "Raleigh - NC 27610",
    "county": "Wake County",
    "state": "NC",
    "city": "Raleigh"
  },
  {
    "value": "27612",
    "label": "Raleigh - NC 27612",
    "county": "Wake County",
    "state": "NC",
    "city": "Raleigh"
  },
  {
    "value": "27613",
    "label": "Raleigh - NC 27613",
    "county": "Wake County",
    "state": "NC",
    "city": "Raleigh"
  },
  {
    "value": "27614",
    "label": "Raleigh - NC 27614",
    "county": "Wake County",
    "state": "NC",
    "city": "Raleigh"
  },
  {
    "value": "27615",
    "label": "Raleigh - NC 27615",
    "county": "Wake County",
    "state": "NC",
    "city": "Raleigh"
  },
  {
    "value": "27616",
    "label": "Raleigh - NC 27616",
    "county": "Wake County",
    "state": "NC",
    "city": "Raleigh"
  },
  {
    "value": "27617",
    "label": "Raleigh - NC 27617",
    "county": "Wake County",
    "state": "NC",
    "city": "Raleigh"
  },
  {
    "value": "27701",
    "label": "Durham - NC 27701",
    "county": "Durham County",
    "state": "NC",
    "city": "Durham"
  },
  {
    "value": "27703",
    "label": "Durham - NC 27703",
    "county": "Durham County",
    "state": "NC",
    "city": "Durham"
  },
  {
    "value": "27704",
    "label": "Durham - NC 27704",
    "county": "Durham County",
    "state": "NC",
    "city": "Durham"
  },
  {
    "value": "27705",
    "label": "Durham - NC 27705",
    "county": "Durham County",
    "state": "NC",
    "city": "Durham"
  },
  {
    "value": "27706",
    "label": "Durham - NC 27706",
    "county": "Durham County",
    "state": "NC",
    "city": "Durham"
  },
  {
    "value": "27707",
    "label": "Durham - NC 27707",
    "county": "Durham County",
    "state": "NC",
    "city": "Durham"
  },
  {
    "value": "27712",
    "label": "Durham - NC 27712",
    "county": "Durham County",
    "state": "NC",
    "city": "Durham"
  },
  {
    "value": "27713",
    "label": "Durham - NC 27713",
    "county": "Durham County",
    "state": "NC",
    "city": "Durham"
  },
  {
    "value": "27801",
    "label": "Rocky Mount - NC 27801",
    "county": "Edgecombe County",
    "state": "NC",
    "city": "Rocky Mount"
  },
  {
    "value": "27803",
    "label": "Rocky Mount - NC 27803",
    "county": "Nash County",
    "state": "NC",
    "city": "Rocky Mount"
  },
  {
    "value": "27804",
    "label": "Rocky Mount - NC 27804",
    "county": "Nash County",
    "state": "NC",
    "city": "Rocky Mount"
  },
  {
    "value": "27805",
    "label": "Aulander - NC 27805",
    "county": "Hertford County",
    "state": "NC",
    "city": "Aulander"
  },
  {
    "value": "27806",
    "label": "Aurora - NC 27806",
    "county": "Beaufort County",
    "state": "NC",
    "city": "Aurora"
  },
  {
    "value": "27807",
    "label": "Bailey - NC 27807",
    "county": "Nash County",
    "state": "NC",
    "city": "Bailey"
  },
  {
    "value": "27808",
    "label": "Bath - NC 27808",
    "county": "Beaufort County",
    "state": "NC",
    "city": "Bath"
  },
  {
    "value": "27809",
    "label": "Battleboro - NC 27809",
    "county": "Nash County",
    "state": "NC",
    "city": "Battleboro"
  },
  {
    "value": "27810",
    "label": "Belhaven - NC 27810",
    "county": "Beaufort County",
    "state": "NC",
    "city": "Belhaven"
  },
  {
    "value": "27812",
    "label": "Bethel - NC 27812",
    "county": "Pitt County",
    "state": "NC",
    "city": "Bethel"
  },
  {
    "value": "27814",
    "label": "Blounts Creek - NC 27814",
    "county": "Beaufort County",
    "state": "NC",
    "city": "Blounts Creek"
  },
  {
    "value": "27816",
    "label": "Castalia - NC 27816",
    "county": "Nash County",
    "state": "NC",
    "city": "Castalia"
  },
  {
    "value": "27817",
    "label": "Chocowinity - NC 27817",
    "county": "Beaufort County",
    "state": "NC",
    "city": "Chocowinity"
  },
  {
    "value": "27818",
    "label": "Como - NC 27818",
    "county": "Hertford County",
    "state": "NC",
    "city": "Como"
  },
  {
    "value": "27820",
    "label": "Conway - NC 27820",
    "county": "Northampton County",
    "state": "NC",
    "city": "Conway"
  },
  {
    "value": "27821",
    "label": "Edward - NC 27821",
    "county": "Beaufort County",
    "state": "NC",
    "city": "Edward"
  },
  {
    "value": "27822",
    "label": "Elm City - NC 27822",
    "county": "Wilson County",
    "state": "NC",
    "city": "Elm City"
  },
  {
    "value": "27823",
    "label": "Enfield - NC 27823",
    "county": "Halifax County",
    "state": "NC",
    "city": "Enfield"
  },
  {
    "value": "27824",
    "label": "Engelhard - NC 27824",
    "county": "Hyde County",
    "state": "NC",
    "city": "Engelhard"
  },
  {
    "value": "27826",
    "label": "Fairfield - NC 27826",
    "county": "Hyde County",
    "state": "NC",
    "city": "Fairfield"
  },
  {
    "value": "27828",
    "label": "Farmville - NC 27828",
    "county": "Pitt County",
    "state": "NC",
    "city": "Farmville"
  },
  {
    "value": "27829",
    "label": "Fountain - NC 27829",
    "county": "Pitt County",
    "state": "NC",
    "city": "Fountain"
  },
  {
    "value": "27830",
    "label": "Fremont - NC 27830",
    "county": "Wayne County",
    "state": "NC",
    "city": "Fremont"
  },
  {
    "value": "27831",
    "label": "Garysburg - NC 27831",
    "county": "Northampton County",
    "state": "NC",
    "city": "Garysburg"
  },
  {
    "value": "27832",
    "label": "Gaston - NC 27832",
    "county": "Northampton County",
    "state": "NC",
    "city": "Gaston"
  },
  {
    "value": "27834",
    "label": "Greenville - NC 27834",
    "county": "Pitt County",
    "state": "NC",
    "city": "Greenville"
  },
  {
    "value": "27837",
    "label": "Grimesland - NC 27837",
    "county": "Pitt County",
    "state": "NC",
    "city": "Grimesland"
  },
  {
    "value": "27839",
    "label": "Halifax - NC 27839",
    "county": "Halifax County",
    "state": "NC",
    "city": "Halifax"
  },
  {
    "value": "27840",
    "label": "Hamilton - NC 27840",
    "county": "Martin County",
    "state": "NC",
    "city": "Hamilton"
  },
  {
    "value": "27842",
    "label": "Henrico - NC 27842",
    "county": "Northampton County",
    "state": "NC",
    "city": "Henrico"
  },
  {
    "value": "27843",
    "label": "Hobgood - NC 27843",
    "county": "Halifax County",
    "state": "NC",
    "city": "Hobgood"
  },
  {
    "value": "27844",
    "label": "Hollister - NC 27844",
    "county": "Halifax County",
    "state": "NC",
    "city": "Hollister"
  },
  {
    "value": "27845",
    "label": "Jackson - NC 27845",
    "county": "Northampton County",
    "state": "NC",
    "city": "Jackson"
  },
  {
    "value": "27846",
    "label": "Jamesville - NC 27846",
    "county": "Martin County",
    "state": "NC",
    "city": "Jamesville"
  },
  {
    "value": "27847",
    "label": "Kelford - NC 27847",
    "county": "Bertie County",
    "state": "NC",
    "city": "Kelford"
  },
  {
    "value": "27849",
    "label": "Lewiston Woodville - NC 27849",
    "county": "Bertie County",
    "state": "NC",
    "city": "Lewiston Woodville"
  },
  {
    "value": "27850",
    "label": "Littleton - NC 27850",
    "county": "Halifax County",
    "state": "NC",
    "city": "Littleton"
  },
  {
    "value": "27851",
    "label": "Lucama - NC 27851",
    "county": "Wilson County",
    "state": "NC",
    "city": "Lucama"
  },
  {
    "value": "27852",
    "label": "Macclesfield - NC 27852",
    "county": "Edgecombe County",
    "state": "NC",
    "city": "Macclesfield"
  },
  {
    "value": "27853",
    "label": "Margarettsville - NC 27853",
    "county": "Northampton County",
    "state": "NC",
    "city": "Margarettsville"
  },
  {
    "value": "27854",
    "label": "Milwaukee - NC 27854",
    "county": "Northampton County",
    "state": "NC",
    "city": "Milwaukee"
  },
  {
    "value": "27855",
    "label": "Murfreesboro - NC 27855",
    "county": "Hertford County",
    "state": "NC",
    "city": "Murfreesboro"
  },
  {
    "value": "27856",
    "label": "Nashville - NC 27856",
    "county": "Nash County",
    "state": "NC",
    "city": "Nashville"
  },
  {
    "value": "27857",
    "label": "Oak City - NC 27857",
    "county": "Martin County",
    "state": "NC",
    "city": "Oak City"
  },
  {
    "value": "27858",
    "label": "Greenville - NC 27858",
    "county": "Pitt County",
    "state": "NC",
    "city": "Greenville"
  },
  {
    "value": "27860",
    "label": "Pantego - NC 27860",
    "county": "Beaufort County",
    "state": "NC",
    "city": "Pantego"
  },
  {
    "value": "27862",
    "label": "Pendleton - NC 27862",
    "county": "Northampton County",
    "state": "NC",
    "city": "Pendleton"
  },
  {
    "value": "27863",
    "label": "Pikeville - NC 27863",
    "county": "Wayne County",
    "state": "NC",
    "city": "Pikeville"
  },
  {
    "value": "27864",
    "label": "Pinetops - NC 27864",
    "county": "Edgecombe County",
    "state": "NC",
    "city": "Pinetops"
  },
  {
    "value": "27865",
    "label": "Pinetown - NC 27865",
    "county": "Beaufort County",
    "state": "NC",
    "city": "Pinetown"
  },
  {
    "value": "27866",
    "label": "Pleasant Hill - NC 27866",
    "county": "Northampton County",
    "state": "NC",
    "city": "Pleasant Hill"
  },
  {
    "value": "27869",
    "label": "Rich Square - NC 27869",
    "county": "Northampton County",
    "state": "NC",
    "city": "Rich Square"
  },
  {
    "value": "27870",
    "label": "Roanoke Rapids - NC 27870",
    "county": "Halifax County",
    "state": "NC",
    "city": "Roanoke Rapids"
  },
  {
    "value": "27871",
    "label": "Robersonville - NC 27871",
    "county": "Martin County",
    "state": "NC",
    "city": "Robersonville"
  },
  {
    "value": "27872",
    "label": "Roxobel - NC 27872",
    "county": "Bertie County",
    "state": "NC",
    "city": "Roxobel"
  },
  {
    "value": "27874",
    "label": "Scotland Neck - NC 27874",
    "county": "Halifax County",
    "state": "NC",
    "city": "Scotland Neck"
  },
  {
    "value": "27875",
    "label": "Scranton - NC 27875",
    "county": "Hyde County",
    "state": "NC",
    "city": "Scranton"
  },
  {
    "value": "27876",
    "label": "Seaboard - NC 27876",
    "county": "Northampton County",
    "state": "NC",
    "city": "Seaboard"
  },
  {
    "value": "27880",
    "label": "Sims - NC 27880",
    "county": "Wilson County",
    "state": "NC",
    "city": "Sims"
  },
  {
    "value": "27882",
    "label": "Spring Hope - NC 27882",
    "county": "Nash County",
    "state": "NC",
    "city": "Spring Hope"
  },
  {
    "value": "27883",
    "label": "Stantonsburg - NC 27883",
    "county": "Wilson County",
    "state": "NC",
    "city": "Stantonsburg"
  },
  {
    "value": "27884",
    "label": "Stokes - NC 27884",
    "county": "Pitt County",
    "state": "NC",
    "city": "Stokes"
  },
  {
    "value": "27885",
    "label": "Swanquarter - NC 27885",
    "county": "Hyde County",
    "state": "NC",
    "city": "Swanquarter"
  },
  {
    "value": "27886",
    "label": "Tarboro - NC 27886",
    "county": "Edgecombe County",
    "state": "NC",
    "city": "Tarboro"
  },
  {
    "value": "27888",
    "label": "Walstonburg - NC 27888",
    "county": "Greene County",
    "state": "NC",
    "city": "Walstonburg"
  },
  {
    "value": "27889",
    "label": "Washington - NC 27889",
    "county": "Beaufort County",
    "state": "NC",
    "city": "Washington"
  },
  {
    "value": "27890",
    "label": "Weldon - NC 27890",
    "county": "Halifax County",
    "state": "NC",
    "city": "Weldon"
  },
  {
    "value": "27891",
    "label": "Whitakers - NC 27891",
    "county": "Nash County",
    "state": "NC",
    "city": "Whitakers"
  },
  {
    "value": "27892",
    "label": "Williamston - NC 27892",
    "county": "Martin County",
    "state": "NC",
    "city": "Williamston"
  },
  {
    "value": "27893",
    "label": "Wilson - NC 27893",
    "county": "Wilson County",
    "state": "NC",
    "city": "Wilson"
  },
  {
    "value": "27896",
    "label": "Wilson - NC 27896",
    "county": "Wilson County",
    "state": "NC",
    "city": "Wilson"
  },
  {
    "value": "27897",
    "label": "Woodland - NC 27897",
    "county": "Northampton County",
    "state": "NC",
    "city": "Woodland"
  },
  {
    "value": "27909",
    "label": "Elizabeth City - NC 27909",
    "county": "Pasquotank County",
    "state": "NC",
    "city": "Elizabeth City"
  },
  {
    "value": "27910",
    "label": "Ahoskie - NC 27910",
    "county": "Hertford County",
    "state": "NC",
    "city": "Ahoskie"
  },
  {
    "value": "27916",
    "label": "Aydlett - NC 27916",
    "county": "Currituck County",
    "state": "NC",
    "city": "Aydlett"
  },
  {
    "value": "27917",
    "label": "Barco - NC 27917",
    "county": "Currituck County",
    "state": "NC",
    "city": "Barco"
  },
  {
    "value": "27919",
    "label": "Belvidere - NC 27919",
    "county": "Perquimans County",
    "state": "NC",
    "city": "Belvidere"
  },
  {
    "value": "27921",
    "label": "Camden - NC 27921",
    "county": "Camden County",
    "state": "NC",
    "city": "Camden"
  },
  {
    "value": "27922",
    "label": "Cofield - NC 27922",
    "county": "Hertford County",
    "state": "NC",
    "city": "Cofield"
  },
  {
    "value": "27923",
    "label": "Coinjock - NC 27923",
    "county": "Currituck County",
    "state": "NC",
    "city": "Coinjock"
  },
  {
    "value": "27924",
    "label": "Colerain - NC 27924",
    "county": "Bertie County",
    "state": "NC",
    "city": "Colerain"
  },
  {
    "value": "27925",
    "label": "Columbia - NC 27925",
    "county": "Tyrrell County",
    "state": "NC",
    "city": "Columbia"
  },
  {
    "value": "27926",
    "label": "Corapeake - NC 27926",
    "county": "Gates County",
    "state": "NC",
    "city": "Corapeake"
  },
  {
    "value": "27927",
    "label": "Corolla - NC 27927",
    "county": "Currituck County",
    "state": "NC",
    "city": "Corolla"
  },
  {
    "value": "27928",
    "label": "Creswell - NC 27928",
    "county": "Washington County",
    "state": "NC",
    "city": "Creswell"
  },
  {
    "value": "27929",
    "label": "Currituck - NC 27929",
    "county": "Currituck County",
    "state": "NC",
    "city": "Currituck"
  },
  {
    "value": "27932",
    "label": "Edenton - NC 27932",
    "county": "Chowan County",
    "state": "NC",
    "city": "Edenton"
  },
  {
    "value": "27935",
    "label": "Eure - NC 27935",
    "county": "Gates County",
    "state": "NC",
    "city": "Eure"
  },
  {
    "value": "27937",
    "label": "Gates - NC 27937",
    "county": "Gates County",
    "state": "NC",
    "city": "Gates"
  },
  {
    "value": "27938",
    "label": "Gatesville - NC 27938",
    "county": "Gates County",
    "state": "NC",
    "city": "Gatesville"
  },
  {
    "value": "27939",
    "label": "Grandy - NC 27939",
    "county": "Currituck County",
    "state": "NC",
    "city": "Grandy"
  },
  {
    "value": "27941",
    "label": "Harbinger - NC 27941",
    "county": "Currituck County",
    "state": "NC",
    "city": "Harbinger"
  },
  {
    "value": "27942",
    "label": "Harrellsville - NC 27942",
    "county": "Hertford County",
    "state": "NC",
    "city": "Harrellsville"
  },
  {
    "value": "27944",
    "label": "Hertford - NC 27944",
    "county": "Perquimans County",
    "state": "NC",
    "city": "Hertford"
  },
  {
    "value": "27946",
    "label": "Hobbsville - NC 27946",
    "county": "Gates County",
    "state": "NC",
    "city": "Hobbsville"
  },
  {
    "value": "27947",
    "label": "Jarvisburg - NC 27947",
    "county": "Currituck County",
    "state": "NC",
    "city": "Jarvisburg"
  },
  {
    "value": "27948",
    "label": "Kill Devil Hills - NC 27948",
    "county": "Dare County",
    "state": "NC",
    "city": "Kill Devil Hills"
  },
  {
    "value": "27949",
    "label": "Kitty Hawk - NC 27949",
    "county": "Dare County",
    "state": "NC",
    "city": "Kitty Hawk"
  },
  {
    "value": "27950",
    "label": "Knotts Island - NC 27950",
    "county": "Currituck County",
    "state": "NC",
    "city": "Knotts Island"
  },
  {
    "value": "27953",
    "label": "Manns Harbor - NC 27953",
    "county": "Dare County",
    "state": "NC",
    "city": "Manns Harbor"
  },
  {
    "value": "27954",
    "label": "Manteo - NC 27954",
    "county": "Dare County",
    "state": "NC",
    "city": "Manteo"
  },
  {
    "value": "27956",
    "label": "Maple - NC 27956",
    "county": "Currituck County",
    "state": "NC",
    "city": "Maple"
  },
  {
    "value": "27957",
    "label": "Merry Hill - NC 27957",
    "county": "Bertie County",
    "state": "NC",
    "city": "Merry Hill"
  },
  {
    "value": "27958",
    "label": "Moyock - NC 27958",
    "county": "Currituck County",
    "state": "NC",
    "city": "Moyock"
  },
  {
    "value": "27959",
    "label": "Nags Head - NC 27959",
    "county": "Dare County",
    "state": "NC",
    "city": "Nags Head"
  },
  {
    "value": "27962",
    "label": "Plymouth - NC 27962",
    "county": "Washington County",
    "state": "NC",
    "city": "Plymouth"
  },
  {
    "value": "27964",
    "label": "Point Harbor - NC 27964",
    "county": "Currituck County",
    "state": "NC",
    "city": "Point Harbor"
  },
  {
    "value": "27965",
    "label": "Poplar Branch - NC 27965",
    "county": "Currituck County",
    "state": "NC",
    "city": "Poplar Branch"
  },
  {
    "value": "27966",
    "label": "Powells Point - NC 27966",
    "county": "Currituck County",
    "state": "NC",
    "city": "Powells Point"
  },
  {
    "value": "27970",
    "label": "Roper - NC 27970",
    "county": "Washington County",
    "state": "NC",
    "city": "Roper"
  },
  {
    "value": "27973",
    "label": "Shawboro - NC 27973",
    "county": "Currituck County",
    "state": "NC",
    "city": "Shawboro"
  },
  {
    "value": "27974",
    "label": "Shiloh - NC 27974",
    "county": "Camden County",
    "state": "NC",
    "city": "Shiloh"
  },
  {
    "value": "27976",
    "label": "South Mills - NC 27976",
    "county": "Camden County",
    "state": "NC",
    "city": "South Mills"
  },
  {
    "value": "27978",
    "label": "Stumpy Point - NC 27978",
    "county": "Dare County",
    "state": "NC",
    "city": "Stumpy Point"
  },
  {
    "value": "27979",
    "label": "Sunbury - NC 27979",
    "county": "Gates County",
    "state": "NC",
    "city": "Sunbury"
  },
  {
    "value": "27980",
    "label": "Tyner - NC 27980",
    "county": "Chowan County",
    "state": "NC",
    "city": "Tyner"
  },
  {
    "value": "27981",
    "label": "Wanchese - NC 27981",
    "county": "Dare County",
    "state": "NC",
    "city": "Wanchese"
  },
  {
    "value": "27983",
    "label": "Windsor - NC 27983",
    "county": "Bertie County",
    "state": "NC",
    "city": "Windsor"
  },
  {
    "value": "27986",
    "label": "Winton - NC 27986",
    "county": "Hertford County",
    "state": "NC",
    "city": "Winton"
  },
  {
    "value": "28001",
    "label": "Albemarle - NC 28001",
    "county": "Stanly County",
    "state": "NC",
    "city": "Albemarle"
  },
  {
    "value": "28006",
    "label": "Alexis - NC 28006",
    "county": "Gaston County",
    "state": "NC",
    "city": "Alexis"
  },
  {
    "value": "28012",
    "label": "Belmont - NC 28012",
    "county": "Gaston County",
    "state": "NC",
    "city": "Belmont"
  },
  {
    "value": "28016",
    "label": "Bessemer City - NC 28016",
    "county": "Gaston County",
    "state": "NC",
    "city": "Bessemer City"
  },
  {
    "value": "28018",
    "label": "Bostic - NC 28018",
    "county": "Rutherford County",
    "state": "NC",
    "city": "Bostic"
  },
  {
    "value": "28020",
    "label": "Casar - NC 28020",
    "county": "Cleveland County",
    "state": "NC",
    "city": "Casar"
  },
  {
    "value": "28021",
    "label": "Cherryville - NC 28021",
    "county": "Gaston County",
    "state": "NC",
    "city": "Cherryville"
  },
  {
    "value": "28023",
    "label": "China Grove - NC 28023",
    "county": "Rowan County",
    "state": "NC",
    "city": "China Grove"
  },
  {
    "value": "28025",
    "label": "Concord - NC 28025",
    "county": "Cabarrus County",
    "state": "NC",
    "city": "Concord"
  },
  {
    "value": "28027",
    "label": "Concord - NC 28027",
    "county": "Cabarrus County",
    "state": "NC",
    "city": "Concord"
  },
  {
    "value": "28031",
    "label": "Cornelius - NC 28031",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Cornelius"
  },
  {
    "value": "28032",
    "label": "Cramerton - NC 28032",
    "county": "Gaston County",
    "state": "NC",
    "city": "Cramerton"
  },
  {
    "value": "28033",
    "label": "Crouse - NC 28033",
    "county": "Lincoln County",
    "state": "NC",
    "city": "Crouse"
  },
  {
    "value": "28034",
    "label": "Dallas - NC 28034",
    "county": "Gaston County",
    "state": "NC",
    "city": "Dallas"
  },
  {
    "value": "28036",
    "label": "Davidson - NC 28036",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Davidson"
  },
  {
    "value": "28037",
    "label": "Denver - NC 28037",
    "county": "Lincoln County",
    "state": "NC",
    "city": "Denver"
  },
  {
    "value": "28040",
    "label": "Ellenboro - NC 28040",
    "county": "Rutherford County",
    "state": "NC",
    "city": "Ellenboro"
  },
  {
    "value": "28043",
    "label": "Forest City - NC 28043",
    "county": "Rutherford County",
    "state": "NC",
    "city": "Forest City"
  },
  {
    "value": "28052",
    "label": "Gastonia - NC 28052",
    "county": "Gaston County",
    "state": "NC",
    "city": "Gastonia"
  },
  {
    "value": "28054",
    "label": "Gastonia - NC 28054",
    "county": "Gaston County",
    "state": "NC",
    "city": "Gastonia"
  },
  {
    "value": "28056",
    "label": "Gastonia - NC 28056",
    "county": "Gaston County",
    "state": "NC",
    "city": "Gastonia"
  },
  {
    "value": "28071",
    "label": "Gold Hill - NC 28071",
    "county": "Rowan County",
    "state": "NC",
    "city": "Gold Hill"
  },
  {
    "value": "28073",
    "label": "Grover - NC 28073",
    "county": "Cleveland County",
    "state": "NC",
    "city": "Grover"
  },
  {
    "value": "28075",
    "label": "Harrisburg - NC 28075",
    "county": "Cabarrus County",
    "state": "NC",
    "city": "Harrisburg"
  },
  {
    "value": "28078",
    "label": "Huntersville - NC 28078",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Huntersville"
  },
  {
    "value": "28079",
    "label": "Indian Trail - NC 28079",
    "county": "Union County",
    "state": "NC",
    "city": "Indian Trail"
  },
  {
    "value": "28080",
    "label": "Iron Station - NC 28080",
    "county": "Lincoln County",
    "state": "NC",
    "city": "Iron Station"
  },
  {
    "value": "28081",
    "label": "Kannapolis - NC 28081",
    "county": "Cabarrus County",
    "state": "NC",
    "city": "Kannapolis"
  },
  {
    "value": "28083",
    "label": "Kannapolis - NC 28083",
    "county": "Cabarrus County",
    "state": "NC",
    "city": "Kannapolis"
  },
  {
    "value": "28086",
    "label": "Kings Mountain - NC 28086",
    "county": "Cleveland County",
    "state": "NC",
    "city": "Kings Mountain"
  },
  {
    "value": "28088",
    "label": "Landis - NC 28088",
    "county": "Rowan County",
    "state": "NC",
    "city": "Landis"
  },
  {
    "value": "28090",
    "label": "Lawndale - NC 28090",
    "county": "Cleveland County",
    "state": "NC",
    "city": "Lawndale"
  },
  {
    "value": "28091",
    "label": "Lilesville - NC 28091",
    "county": "Anson County",
    "state": "NC",
    "city": "Lilesville"
  },
  {
    "value": "28092",
    "label": "Lincolnton - NC 28092",
    "county": "Lincoln County",
    "state": "NC",
    "city": "Lincolnton"
  },
  {
    "value": "28097",
    "label": "Locust - NC 28097",
    "county": "Stanly County",
    "state": "NC",
    "city": "Locust"
  },
  {
    "value": "28098",
    "label": "Lowell - NC 28098",
    "county": "Gaston County",
    "state": "NC",
    "city": "Lowell"
  },
  {
    "value": "28101",
    "label": "Mc Adenville - NC 28101",
    "county": "Gaston County",
    "state": "NC",
    "city": "Mc Adenville"
  },
  {
    "value": "28103",
    "label": "Marshville - NC 28103",
    "county": "Union County",
    "state": "NC",
    "city": "Marshville"
  },
  {
    "value": "28104",
    "label": "Matthews - NC 28104",
    "county": "Union County",
    "state": "NC",
    "city": "Matthews"
  },
  {
    "value": "28105",
    "label": "Matthews - NC 28105",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Matthews"
  },
  {
    "value": "28107",
    "label": "Midland - NC 28107",
    "county": "Cabarrus County",
    "state": "NC",
    "city": "Midland"
  },
  {
    "value": "28110",
    "label": "Monroe - NC 28110",
    "county": "Union County",
    "state": "NC",
    "city": "Monroe"
  },
  {
    "value": "28112",
    "label": "Monroe - NC 28112",
    "county": "Union County",
    "state": "NC",
    "city": "Monroe"
  },
  {
    "value": "28114",
    "label": "Mooresboro - NC 28114",
    "county": "Rutherford County",
    "state": "NC",
    "city": "Mooresboro"
  },
  {
    "value": "28115",
    "label": "Mooresville - NC 28115",
    "county": "Iredell County",
    "state": "NC",
    "city": "Mooresville"
  },
  {
    "value": "28117",
    "label": "Mooresville - NC 28117",
    "county": "Iredell County",
    "state": "NC",
    "city": "Mooresville"
  },
  {
    "value": "28119",
    "label": "Morven - NC 28119",
    "county": "Anson County",
    "state": "NC",
    "city": "Morven"
  },
  {
    "value": "28120",
    "label": "Mount Holly - NC 28120",
    "county": "Gaston County",
    "state": "NC",
    "city": "Mount Holly"
  },
  {
    "value": "28124",
    "label": "Mount Pleasant - NC 28124",
    "county": "Cabarrus County",
    "state": "NC",
    "city": "Mount Pleasant"
  },
  {
    "value": "28125",
    "label": "Mount Ulla - NC 28125",
    "county": "Rowan County",
    "state": "NC",
    "city": "Mount Ulla"
  },
  {
    "value": "28127",
    "label": "New London - NC 28127",
    "county": "Stanly County",
    "state": "NC",
    "city": "New London"
  },
  {
    "value": "28128",
    "label": "Norwood - NC 28128",
    "county": "Stanly County",
    "state": "NC",
    "city": "Norwood"
  },
  {
    "value": "28129",
    "label": "Oakboro - NC 28129",
    "county": "Stanly County",
    "state": "NC",
    "city": "Oakboro"
  },
  {
    "value": "28133",
    "label": "Peachland - NC 28133",
    "county": "Anson County",
    "state": "NC",
    "city": "Peachland"
  },
  {
    "value": "28134",
    "label": "Pineville - NC 28134",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Pineville"
  },
  {
    "value": "28135",
    "label": "Polkton - NC 28135",
    "county": "Anson County",
    "state": "NC",
    "city": "Polkton"
  },
  {
    "value": "28137",
    "label": "Richfield - NC 28137",
    "county": "Stanly County",
    "state": "NC",
    "city": "Richfield"
  },
  {
    "value": "28138",
    "label": "Rockwell - NC 28138",
    "county": "Rowan County",
    "state": "NC",
    "city": "Rockwell"
  },
  {
    "value": "28139",
    "label": "Rutherfordton - NC 28139",
    "county": "Rutherford County",
    "state": "NC",
    "city": "Rutherfordton"
  },
  {
    "value": "28144",
    "label": "Salisbury - NC 28144",
    "county": "Rowan County",
    "state": "NC",
    "city": "Salisbury"
  },
  {
    "value": "28146",
    "label": "Salisbury - NC 28146",
    "county": "Rowan County",
    "state": "NC",
    "city": "Salisbury"
  },
  {
    "value": "28147",
    "label": "Salisbury - NC 28147",
    "county": "Rowan County",
    "state": "NC",
    "city": "Salisbury"
  },
  {
    "value": "28150",
    "label": "Shelby - NC 28150",
    "county": "Cleveland County",
    "state": "NC",
    "city": "Shelby"
  },
  {
    "value": "28152",
    "label": "Shelby - NC 28152",
    "county": "Cleveland County",
    "state": "NC",
    "city": "Shelby"
  },
  {
    "value": "28159",
    "label": "Spencer - NC 28159",
    "county": "Rowan County",
    "state": "NC",
    "city": "Spencer"
  },
  {
    "value": "28160",
    "label": "Spindale - NC 28160",
    "county": "Rutherford County",
    "state": "NC",
    "city": "Spindale"
  },
  {
    "value": "28163",
    "label": "Stanfield - NC 28163",
    "county": "Stanly County",
    "state": "NC",
    "city": "Stanfield"
  },
  {
    "value": "28164",
    "label": "Stanley - NC 28164",
    "county": "Gaston County",
    "state": "NC",
    "city": "Stanley"
  },
  {
    "value": "28166",
    "label": "Troutman - NC 28166",
    "county": "Iredell County",
    "state": "NC",
    "city": "Troutman"
  },
  {
    "value": "28167",
    "label": "Union Mills - NC 28167",
    "county": "Rutherford County",
    "state": "NC",
    "city": "Union Mills"
  },
  {
    "value": "28168",
    "label": "Vale - NC 28168",
    "county": "Lincoln County",
    "state": "NC",
    "city": "Vale"
  },
  {
    "value": "28170",
    "label": "Wadesboro - NC 28170",
    "county": "Anson County",
    "state": "NC",
    "city": "Wadesboro"
  },
  {
    "value": "28173",
    "label": "Waxhaw - NC 28173",
    "county": "Union County",
    "state": "NC",
    "city": "Waxhaw"
  },
  {
    "value": "28174",
    "label": "Wingate - NC 28174",
    "county": "Union County",
    "state": "NC",
    "city": "Wingate"
  },
  {
    "value": "28202",
    "label": "Charlotte - NC 28202",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28203",
    "label": "Charlotte - NC 28203",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28204",
    "label": "Charlotte - NC 28204",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28205",
    "label": "Charlotte - NC 28205",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28206",
    "label": "Charlotte - NC 28206",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28207",
    "label": "Charlotte - NC 28207",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28208",
    "label": "Charlotte - NC 28208",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28209",
    "label": "Charlotte - NC 28209",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28210",
    "label": "Charlotte - NC 28210",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28211",
    "label": "Charlotte - NC 28211",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28212",
    "label": "Charlotte - NC 28212",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28213",
    "label": "Charlotte - NC 28213",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28214",
    "label": "Charlotte - NC 28214",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28215",
    "label": "Charlotte - NC 28215",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28216",
    "label": "Charlotte - NC 28216",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28217",
    "label": "Charlotte - NC 28217",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28226",
    "label": "Charlotte - NC 28226",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28227",
    "label": "Charlotte - NC 28227",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28244",
    "label": "Charlotte - NC 28244",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28246",
    "label": "Charlotte - NC 28246",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28254",
    "label": "Charlotte - NC 28254",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28262",
    "label": "Charlotte - NC 28262",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28269",
    "label": "Charlotte - NC 28269",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28270",
    "label": "Charlotte - NC 28270",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28273",
    "label": "Charlotte - NC 28273",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28277",
    "label": "Charlotte - NC 28277",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28278",
    "label": "Charlotte - NC 28278",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28280",
    "label": "Charlotte - NC 28280",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28281",
    "label": "Charlotte - NC 28281",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28282",
    "label": "Charlotte - NC 28282",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28284",
    "label": "Charlotte - NC 28284",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28285",
    "label": "Charlotte - NC 28285",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28287",
    "label": "Charlotte - NC 28287",
    "county": "Mecklenburg County",
    "state": "NC",
    "city": "Charlotte"
  },
  {
    "value": "28301",
    "label": "Fayetteville - NC 28301",
    "county": "Cumberland County",
    "state": "NC",
    "city": "Fayetteville"
  },
  {
    "value": "28303",
    "label": "Fayetteville - NC 28303",
    "county": "Cumberland County",
    "state": "NC",
    "city": "Fayetteville"
  },
  {
    "value": "28304",
    "label": "Fayetteville - NC 28304",
    "county": "Cumberland County",
    "state": "NC",
    "city": "Fayetteville"
  },
  {
    "value": "28305",
    "label": "Fayetteville - NC 28305",
    "county": "Cumberland County",
    "state": "NC",
    "city": "Fayetteville"
  },
  {
    "value": "28306",
    "label": "Fayetteville - NC 28306",
    "county": "Bladen County",
    "state": "NC",
    "city": "Fayetteville"
  },
  {
    "value": "28307",
    "label": "Fort Bragg - NC 28307",
    "county": "Cumberland County",
    "state": "NC",
    "city": "Fort Bragg"
  },
  {
    "value": "28308",
    "label": "Pope Army Airfield - NC 28308",
    "county": "Cumberland County",
    "state": "NC",
    "city": "Pope Army Airfield"
  },
  {
    "value": "28311",
    "label": "Fayetteville - NC 28311",
    "county": "Cumberland County",
    "state": "NC",
    "city": "Fayetteville"
  },
  {
    "value": "28312",
    "label": "Fayetteville - NC 28312",
    "county": "Cumberland County",
    "state": "NC",
    "city": "Fayetteville"
  },
  {
    "value": "28314",
    "label": "Fayetteville - NC 28314",
    "county": "Cumberland County",
    "state": "NC",
    "city": "Fayetteville"
  },
  {
    "value": "28315",
    "label": "Aberdeen - NC 28315",
    "county": "Moore County",
    "state": "NC",
    "city": "Aberdeen"
  },
  {
    "value": "28318",
    "label": "Autryville - NC 28318",
    "county": "Sampson County",
    "state": "NC",
    "city": "Autryville"
  },
  {
    "value": "28320",
    "label": "Bladenboro - NC 28320",
    "county": "Bladen County",
    "state": "NC",
    "city": "Bladenboro"
  },
  {
    "value": "28323",
    "label": "Bunnlevel - NC 28323",
    "county": "Harnett County",
    "state": "NC",
    "city": "Bunnlevel"
  },
  {
    "value": "28326",
    "label": "Cameron - NC 28326",
    "county": "Harnett County",
    "state": "NC",
    "city": "Cameron"
  },
  {
    "value": "28327",
    "label": "Carthage - NC 28327",
    "county": "Moore County",
    "state": "NC",
    "city": "Carthage"
  },
  {
    "value": "28328",
    "label": "Clinton - NC 28328",
    "county": "Sampson County",
    "state": "NC",
    "city": "Clinton"
  },
  {
    "value": "28333",
    "label": "Dudley - NC 28333",
    "county": "Wayne County",
    "state": "NC",
    "city": "Dudley"
  },
  {
    "value": "28334",
    "label": "Dunn - NC 28334",
    "county": "Sampson County",
    "state": "NC",
    "city": "Dunn"
  },
  {
    "value": "28337",
    "label": "Elizabethtown - NC 28337",
    "county": "Bladen County",
    "state": "NC",
    "city": "Elizabethtown"
  },
  {
    "value": "28338",
    "label": "Ellerbe - NC 28338",
    "county": "Richmond County",
    "state": "NC",
    "city": "Ellerbe"
  },
  {
    "value": "28339",
    "label": "Erwin - NC 28339",
    "county": "Harnett County",
    "state": "NC",
    "city": "Erwin"
  },
  {
    "value": "28340",
    "label": "Fairmont - NC 28340",
    "county": "Robeson County",
    "state": "NC",
    "city": "Fairmont"
  },
  {
    "value": "28341",
    "label": "Faison - NC 28341",
    "county": "Duplin County",
    "state": "NC",
    "city": "Faison"
  },
  {
    "value": "28343",
    "label": "Gibson - NC 28343",
    "county": "Scotland County",
    "state": "NC",
    "city": "Gibson"
  },
  {
    "value": "28344",
    "label": "Godwin - NC 28344",
    "county": "Sampson County",
    "state": "NC",
    "city": "Godwin"
  },
  {
    "value": "28345",
    "label": "Hamlet - NC 28345",
    "county": "Richmond County",
    "state": "NC",
    "city": "Hamlet"
  },
  {
    "value": "28347",
    "label": "Hoffman - NC 28347",
    "county": "Richmond County",
    "state": "NC",
    "city": "Hoffman"
  },
  {
    "value": "28348",
    "label": "Hope Mills - NC 28348",
    "county": "Cumberland County",
    "state": "NC",
    "city": "Hope Mills"
  },
  {
    "value": "28349",
    "label": "Kenansville - NC 28349",
    "county": "Duplin County",
    "state": "NC",
    "city": "Kenansville"
  },
  {
    "value": "28351",
    "label": "Laurel Hill - NC 28351",
    "county": "Scotland County",
    "state": "NC",
    "city": "Laurel Hill"
  },
  {
    "value": "28352",
    "label": "Laurinburg - NC 28352",
    "county": "Scotland County",
    "state": "NC",
    "city": "Laurinburg"
  },
  {
    "value": "28356",
    "label": "Linden - NC 28356",
    "county": "Harnett County",
    "state": "NC",
    "city": "Linden"
  },
  {
    "value": "28357",
    "label": "Lumber Bridge - NC 28357",
    "county": "Robeson County",
    "state": "NC",
    "city": "Lumber Bridge"
  },
  {
    "value": "28358",
    "label": "Lumberton - NC 28358",
    "county": "Robeson County",
    "state": "NC",
    "city": "Lumberton"
  },
  {
    "value": "28360",
    "label": "Lumberton - NC 28360",
    "county": "Robeson County",
    "state": "NC",
    "city": "Lumberton"
  },
  {
    "value": "28363",
    "label": "Marston - NC 28363",
    "county": "Richmond County",
    "state": "NC",
    "city": "Marston"
  },
  {
    "value": "28364",
    "label": "Maxton - NC 28364",
    "county": "Robeson County",
    "state": "NC",
    "city": "Maxton"
  },
  {
    "value": "28365",
    "label": "Mount Olive - NC 28365",
    "county": "Wayne County",
    "state": "NC",
    "city": "Mount Olive"
  },
  {
    "value": "28366",
    "label": "Newton Grove - NC 28366",
    "county": "Sampson County",
    "state": "NC",
    "city": "Newton Grove"
  },
  {
    "value": "28369",
    "label": "Orrum - NC 28369",
    "county": "Robeson County",
    "state": "NC",
    "city": "Orrum"
  },
  {
    "value": "28371",
    "label": "Parkton - NC 28371",
    "county": "Robeson County",
    "state": "NC",
    "city": "Parkton"
  },
  {
    "value": "28372",
    "label": "Pembroke - NC 28372",
    "county": "Robeson County",
    "state": "NC",
    "city": "Pembroke"
  },
  {
    "value": "28373",
    "label": "Pinebluff - NC 28373",
    "county": "Moore County",
    "state": "NC",
    "city": "Pinebluff"
  },
  {
    "value": "28374",
    "label": "Pinehurst - NC 28374",
    "county": "Moore County",
    "state": "NC",
    "city": "Pinehurst"
  },
  {
    "value": "28376",
    "label": "Raeford - NC 28376",
    "county": "Hoke County",
    "state": "NC",
    "city": "Raeford"
  },
  {
    "value": "28377",
    "label": "Red Springs - NC 28377",
    "county": "Robeson County",
    "state": "NC",
    "city": "Red Springs"
  },
  {
    "value": "28379",
    "label": "Rockingham - NC 28379",
    "county": "Richmond County",
    "state": "NC",
    "city": "Rockingham"
  },
  {
    "value": "28382",
    "label": "Roseboro - NC 28382",
    "county": "Sampson County",
    "state": "NC",
    "city": "Roseboro"
  },
  {
    "value": "28383",
    "label": "Rowland - NC 28383",
    "county": "Robeson County",
    "state": "NC",
    "city": "Rowland"
  },
  {
    "value": "28384",
    "label": "Saint Pauls - NC 28384",
    "county": "Bladen County",
    "state": "NC",
    "city": "Saint Pauls"
  },
  {
    "value": "28385",
    "label": "Salemburg - NC 28385",
    "county": "Sampson County",
    "state": "NC",
    "city": "Salemburg"
  },
  {
    "value": "28386",
    "label": "Shannon - NC 28386",
    "county": "Robeson County",
    "state": "NC",
    "city": "Shannon"
  },
  {
    "value": "28387",
    "label": "Southern Pines - NC 28387",
    "county": "Moore County",
    "state": "NC",
    "city": "Southern Pines"
  },
  {
    "value": "28390",
    "label": "Spring Lake - NC 28390",
    "county": "Harnett County",
    "state": "NC",
    "city": "Spring Lake"
  },
  {
    "value": "28391",
    "label": "Stedman - NC 28391",
    "county": "Cumberland County",
    "state": "NC",
    "city": "Stedman"
  },
  {
    "value": "28392",
    "label": "Tar Heel - NC 28392",
    "county": "Bladen County",
    "state": "NC",
    "city": "Tar Heel"
  },
  {
    "value": "28393",
    "label": "Turkey - NC 28393",
    "county": "Sampson County",
    "state": "NC",
    "city": "Turkey"
  },
  {
    "value": "28394",
    "label": "Vass - NC 28394",
    "county": "Moore County",
    "state": "NC",
    "city": "Vass"
  },
  {
    "value": "28395",
    "label": "Wade - NC 28395",
    "county": "Cumberland County",
    "state": "NC",
    "city": "Wade"
  },
  {
    "value": "28396",
    "label": "Wagram - NC 28396",
    "county": "Scotland County",
    "state": "NC",
    "city": "Wagram"
  },
  {
    "value": "28398",
    "label": "Warsaw - NC 28398",
    "county": "Duplin County",
    "state": "NC",
    "city": "Warsaw"
  },
  {
    "value": "28399",
    "label": "White Oak - NC 28399",
    "county": "Bladen County",
    "state": "NC",
    "city": "White Oak"
  },
  {
    "value": "28401",
    "label": "Wilmington - NC 28401",
    "county": "New Hanover County",
    "state": "NC",
    "city": "Wilmington"
  },
  {
    "value": "28403",
    "label": "Wilmington - NC 28403",
    "county": "New Hanover County",
    "state": "NC",
    "city": "Wilmington"
  },
  {
    "value": "28405",
    "label": "Wilmington - NC 28405",
    "county": "New Hanover County",
    "state": "NC",
    "city": "Wilmington"
  },
  {
    "value": "28409",
    "label": "Wilmington - NC 28409",
    "county": "New Hanover County",
    "state": "NC",
    "city": "Wilmington"
  },
  {
    "value": "28411",
    "label": "Wilmington - NC 28411",
    "county": "New Hanover County",
    "state": "NC",
    "city": "Wilmington"
  },
  {
    "value": "28412",
    "label": "Wilmington - NC 28412",
    "county": "New Hanover County",
    "state": "NC",
    "city": "Wilmington"
  },
  {
    "value": "28420",
    "label": "Ash - NC 28420",
    "county": "Brunswick County",
    "state": "NC",
    "city": "Ash"
  },
  {
    "value": "28421",
    "label": "Atkinson - NC 28421",
    "county": "Pender County",
    "state": "NC",
    "city": "Atkinson"
  },
  {
    "value": "28422",
    "label": "Bolivia - NC 28422",
    "county": "Brunswick County",
    "state": "NC",
    "city": "Bolivia"
  },
  {
    "value": "28423",
    "label": "Bolton - NC 28423",
    "county": "Columbus County",
    "state": "NC",
    "city": "Bolton"
  },
  {
    "value": "28425",
    "label": "Burgaw - NC 28425",
    "county": "Pender County",
    "state": "NC",
    "city": "Burgaw"
  },
  {
    "value": "28428",
    "label": "Carolina Beach - NC 28428",
    "county": "New Hanover County",
    "state": "NC",
    "city": "Carolina Beach"
  },
  {
    "value": "28429",
    "label": "Castle Hayne - NC 28429",
    "county": "New Hanover County",
    "state": "NC",
    "city": "Castle Hayne"
  },
  {
    "value": "28430",
    "label": "Cerro Gordo - NC 28430",
    "county": "Columbus County",
    "state": "NC",
    "city": "Cerro Gordo"
  },
  {
    "value": "28431",
    "label": "Chadbourn - NC 28431",
    "county": "Columbus County",
    "state": "NC",
    "city": "Chadbourn"
  },
  {
    "value": "28432",
    "label": "Clarendon - NC 28432",
    "county": "Columbus County",
    "state": "NC",
    "city": "Clarendon"
  },
  {
    "value": "28433",
    "label": "Clarkton - NC 28433",
    "county": "Bladen County",
    "state": "NC",
    "city": "Clarkton"
  },
  {
    "value": "28434",
    "label": "Council - NC 28434",
    "county": "Bladen County",
    "state": "NC",
    "city": "Council"
  },
  {
    "value": "28435",
    "label": "Currie - NC 28435",
    "county": "Pender County",
    "state": "NC",
    "city": "Currie"
  },
  {
    "value": "28436",
    "label": "Delco - NC 28436",
    "county": "Columbus County",
    "state": "NC",
    "city": "Delco"
  },
  {
    "value": "28438",
    "label": "Evergreen - NC 28438",
    "county": "Columbus County",
    "state": "NC",
    "city": "Evergreen"
  },
  {
    "value": "28439",
    "label": "Fair Bluff - NC 28439",
    "county": "Columbus County",
    "state": "NC",
    "city": "Fair Bluff"
  },
  {
    "value": "28441",
    "label": "Garland - NC 28441",
    "county": "Sampson County",
    "state": "NC",
    "city": "Garland"
  },
  {
    "value": "28442",
    "label": "Hallsboro - NC 28442",
    "county": "Columbus County",
    "state": "NC",
    "city": "Hallsboro"
  },
  {
    "value": "28443",
    "label": "Hampstead - NC 28443",
    "county": "Pender County",
    "state": "NC",
    "city": "Hampstead"
  },
  {
    "value": "28444",
    "label": "Harrells - NC 28444",
    "county": "Sampson County",
    "state": "NC",
    "city": "Harrells"
  },
  {
    "value": "28445",
    "label": "Holly Ridge - NC 28445",
    "county": "Onslow County",
    "state": "NC",
    "city": "Holly Ridge"
  },
  {
    "value": "28447",
    "label": "Ivanhoe - NC 28447",
    "county": "Sampson County",
    "state": "NC",
    "city": "Ivanhoe"
  },
  {
    "value": "28448",
    "label": "Kelly - NC 28448",
    "county": "Bladen County",
    "state": "NC",
    "city": "Kelly"
  },
  {
    "value": "28449",
    "label": "Kure Beach - NC 28449",
    "county": "New Hanover County",
    "state": "NC",
    "city": "Kure Beach"
  },
  {
    "value": "28450",
    "label": "Lake Waccamaw - NC 28450",
    "county": "Columbus County",
    "state": "NC",
    "city": "Lake Waccamaw"
  },
  {
    "value": "28451",
    "label": "Leland - NC 28451",
    "county": "Brunswick County",
    "state": "NC",
    "city": "Leland"
  },
  {
    "value": "28452",
    "label": "Longwood - NC 28452",
    "county": "Brunswick County",
    "state": "NC",
    "city": "Longwood"
  },
  {
    "value": "28453",
    "label": "Magnolia - NC 28453",
    "county": "Duplin County",
    "state": "NC",
    "city": "Magnolia"
  },
  {
    "value": "28454",
    "label": "Maple Hill - NC 28454",
    "county": "Onslow County",
    "state": "NC",
    "city": "Maple Hill"
  },
  {
    "value": "28455",
    "label": "Nakina - NC 28455",
    "county": "Columbus County",
    "state": "NC",
    "city": "Nakina"
  },
  {
    "value": "28456",
    "label": "Riegelwood - NC 28456",
    "county": "Columbus County",
    "state": "NC",
    "city": "Riegelwood"
  },
  {
    "value": "28457",
    "label": "Rocky Point - NC 28457",
    "county": "Pender County",
    "state": "NC",
    "city": "Rocky Point"
  },
  {
    "value": "28458",
    "label": "Rose Hill - NC 28458",
    "county": "Duplin County",
    "state": "NC",
    "city": "Rose Hill"
  },
  {
    "value": "28460",
    "label": "Sneads Ferry - NC 28460",
    "county": "Onslow County",
    "state": "NC",
    "city": "Sneads Ferry"
  },
  {
    "value": "28461",
    "label": "Southport - NC 28461",
    "county": "Brunswick County",
    "state": "NC",
    "city": "Southport"
  },
  {
    "value": "28462",
    "label": "Supply - NC 28462",
    "county": "Brunswick County",
    "state": "NC",
    "city": "Supply"
  },
  {
    "value": "28463",
    "label": "Tabor City - NC 28463",
    "county": "Columbus County",
    "state": "NC",
    "city": "Tabor City"
  },
  {
    "value": "28464",
    "label": "Teachey - NC 28464",
    "county": "Duplin County",
    "state": "NC",
    "city": "Teachey"
  },
  {
    "value": "28465",
    "label": "Oak Island - NC 28465",
    "county": "Brunswick County",
    "state": "NC",
    "city": "Oak Island"
  },
  {
    "value": "28466",
    "label": "Wallace - NC 28466",
    "county": "Duplin County",
    "state": "NC",
    "city": "Wallace"
  },
  {
    "value": "28467",
    "label": "Calabash - NC 28467",
    "county": "Brunswick County",
    "state": "NC",
    "city": "Calabash"
  },
  {
    "value": "28468",
    "label": "Sunset Beach - NC 28468",
    "county": "Brunswick County",
    "state": "NC",
    "city": "Sunset Beach"
  },
  {
    "value": "28469",
    "label": "Ocean Isle Beach - NC 28469",
    "county": "Brunswick County",
    "state": "NC",
    "city": "Ocean Isle Beach"
  },
  {
    "value": "28470",
    "label": "Shallotte - NC 28470",
    "county": "Brunswick County",
    "state": "NC",
    "city": "Shallotte"
  },
  {
    "value": "28472",
    "label": "Whiteville - NC 28472",
    "county": "Columbus County",
    "state": "NC",
    "city": "Whiteville"
  },
  {
    "value": "28478",
    "label": "Willard - NC 28478",
    "county": "Pender County",
    "state": "NC",
    "city": "Willard"
  },
  {
    "value": "28479",
    "label": "Winnabow - NC 28479",
    "county": "Brunswick County",
    "state": "NC",
    "city": "Winnabow"
  },
  {
    "value": "28480",
    "label": "Wrightsville Beach - NC 28480",
    "county": "New Hanover County",
    "state": "NC",
    "city": "Wrightsville Beach"
  },
  {
    "value": "28501",
    "label": "Kinston - NC 28501",
    "county": "Lenoir County",
    "state": "NC",
    "city": "Kinston"
  },
  {
    "value": "28504",
    "label": "Kinston - NC 28504",
    "county": "Lenoir County",
    "state": "NC",
    "city": "Kinston"
  },
  {
    "value": "28508",
    "label": "Albertson - NC 28508",
    "county": "Duplin County",
    "state": "NC",
    "city": "Albertson"
  },
  {
    "value": "28510",
    "label": "Arapahoe - NC 28510",
    "county": "Pamlico County",
    "state": "NC",
    "city": "Arapahoe"
  },
  {
    "value": "28511",
    "label": "Atlantic - NC 28511",
    "county": "Carteret County",
    "state": "NC",
    "city": "Atlantic"
  },
  {
    "value": "28512",
    "label": "Atlantic Beach - NC 28512",
    "county": "Carteret County",
    "state": "NC",
    "city": "Atlantic Beach"
  },
  {
    "value": "28513",
    "label": "Ayden - NC 28513",
    "county": "Pitt County",
    "state": "NC",
    "city": "Ayden"
  },
  {
    "value": "28515",
    "label": "Bayboro - NC 28515",
    "county": "Pamlico County",
    "state": "NC",
    "city": "Bayboro"
  },
  {
    "value": "28516",
    "label": "Beaufort - NC 28516",
    "county": "Carteret County",
    "state": "NC",
    "city": "Beaufort"
  },
  {
    "value": "28518",
    "label": "Beulaville - NC 28518",
    "county": "Duplin County",
    "state": "NC",
    "city": "Beulaville"
  },
  {
    "value": "28520",
    "label": "Cedar Island - NC 28520",
    "county": "Carteret County",
    "state": "NC",
    "city": "Cedar Island"
  },
  {
    "value": "28521",
    "label": "Chinquapin - NC 28521",
    "county": "Duplin County",
    "state": "NC",
    "city": "Chinquapin"
  },
  {
    "value": "28523",
    "label": "Cove City - NC 28523",
    "county": "Craven County",
    "state": "NC",
    "city": "Cove City"
  },
  {
    "value": "28524",
    "label": "Davis - NC 28524",
    "county": "Carteret County",
    "state": "NC",
    "city": "Davis"
  },
  {
    "value": "28525",
    "label": "Deep Run - NC 28525",
    "county": "Lenoir County",
    "state": "NC",
    "city": "Deep Run"
  },
  {
    "value": "28526",
    "label": "Dover - NC 28526",
    "county": "Craven County",
    "state": "NC",
    "city": "Dover"
  },
  {
    "value": "28527",
    "label": "Ernul - NC 28527",
    "county": "Craven County",
    "state": "NC",
    "city": "Ernul"
  },
  {
    "value": "28528",
    "label": "Gloucester - NC 28528",
    "county": "Carteret County",
    "state": "NC",
    "city": "Gloucester"
  },
  {
    "value": "28529",
    "label": "Grantsboro - NC 28529",
    "county": "Pamlico County",
    "state": "NC",
    "city": "Grantsboro"
  },
  {
    "value": "28530",
    "label": "Grifton - NC 28530",
    "county": "Pitt County",
    "state": "NC",
    "city": "Grifton"
  },
  {
    "value": "28531",
    "label": "Harkers Island - NC 28531",
    "county": "Carteret County",
    "state": "NC",
    "city": "Harkers Island"
  },
  {
    "value": "28532",
    "label": "Havelock - NC 28532",
    "county": "Craven County",
    "state": "NC",
    "city": "Havelock"
  },
  {
    "value": "28533",
    "label": "Cherry Point - NC 28533",
    "county": "Craven County",
    "state": "NC",
    "city": "Cherry Point"
  },
  {
    "value": "28537",
    "label": "Hobucken - NC 28537",
    "county": "Pamlico County",
    "state": "NC",
    "city": "Hobucken"
  },
  {
    "value": "28538",
    "label": "Hookerton - NC 28538",
    "county": "Greene County",
    "state": "NC",
    "city": "Hookerton"
  },
  {
    "value": "28539",
    "label": "Hubert - NC 28539",
    "county": "Onslow County",
    "state": "NC",
    "city": "Hubert"
  },
  {
    "value": "28540",
    "label": "Jacksonville - NC 28540",
    "county": "Onslow County",
    "state": "NC",
    "city": "Jacksonville"
  },
  {
    "value": "28543",
    "label": "Tarawa Terrace - NC 28543",
    "county": "Onslow County",
    "state": "NC",
    "city": "Tarawa Terrace"
  },
  {
    "value": "28544",
    "label": "Midway Park - NC 28544",
    "county": "Onslow County",
    "state": "NC",
    "city": "Midway Park"
  },
  {
    "value": "28545",
    "label": "Mccutcheon Field - NC 28545",
    "county": "Onslow County",
    "state": "NC",
    "city": "Mccutcheon Field"
  },
  {
    "value": "28546",
    "label": "Jacksonville - NC 28546",
    "county": "Onslow County",
    "state": "NC",
    "city": "Jacksonville"
  },
  {
    "value": "28547",
    "label": "Camp Lejeune - NC 28547",
    "county": "Onslow County",
    "state": "NC",
    "city": "Camp Lejeune"
  },
  {
    "value": "28551",
    "label": "La Grange - NC 28551",
    "county": "Lenoir County",
    "state": "NC",
    "city": "La Grange"
  },
  {
    "value": "28552",
    "label": "Lowland - NC 28552",
    "county": "Pamlico County",
    "state": "NC",
    "city": "Lowland"
  },
  {
    "value": "28553",
    "label": "Marshallberg - NC 28553",
    "county": "Carteret County",
    "state": "NC",
    "city": "Marshallberg"
  },
  {
    "value": "28555",
    "label": "Maysville - NC 28555",
    "county": "Onslow County",
    "state": "NC",
    "city": "Maysville"
  },
  {
    "value": "28556",
    "label": "Merritt - NC 28556",
    "county": "Pamlico County",
    "state": "NC",
    "city": "Merritt"
  },
  {
    "value": "28557",
    "label": "Morehead City - NC 28557",
    "county": "Carteret County",
    "state": "NC",
    "city": "Morehead City"
  },
  {
    "value": "28560",
    "label": "New Bern - NC 28560",
    "county": "Craven County",
    "state": "NC",
    "city": "New Bern"
  },
  {
    "value": "28562",
    "label": "New Bern - NC 28562",
    "county": "Craven County",
    "state": "NC",
    "city": "New Bern"
  },
  {
    "value": "28570",
    "label": "Newport - NC 28570",
    "county": "Carteret County",
    "state": "NC",
    "city": "Newport"
  },
  {
    "value": "28571",
    "label": "Oriental - NC 28571",
    "county": "Pamlico County",
    "state": "NC",
    "city": "Oriental"
  },
  {
    "value": "28572",
    "label": "Pink Hill - NC 28572",
    "county": "Duplin County",
    "state": "NC",
    "city": "Pink Hill"
  },
  {
    "value": "28573",
    "label": "Pollocksville - NC 28573",
    "county": "Jones County",
    "state": "NC",
    "city": "Pollocksville"
  },
  {
    "value": "28574",
    "label": "Richlands - NC 28574",
    "county": "Onslow County",
    "state": "NC",
    "city": "Richlands"
  },
  {
    "value": "28577",
    "label": "Sealevel - NC 28577",
    "county": "Carteret County",
    "state": "NC",
    "city": "Sealevel"
  },
  {
    "value": "28578",
    "label": "Seven Springs - NC 28578",
    "county": "Wayne County",
    "state": "NC",
    "city": "Seven Springs"
  },
  {
    "value": "28579",
    "label": "Smyrna - NC 28579",
    "county": "Carteret County",
    "state": "NC",
    "city": "Smyrna"
  },
  {
    "value": "28580",
    "label": "Snow Hill - NC 28580",
    "county": "Greene County",
    "state": "NC",
    "city": "Snow Hill"
  },
  {
    "value": "28581",
    "label": "Stacy - NC 28581",
    "county": "Carteret County",
    "state": "NC",
    "city": "Stacy"
  },
  {
    "value": "28582",
    "label": "Stella - NC 28582",
    "county": "Onslow County",
    "state": "NC",
    "city": "Stella"
  },
  {
    "value": "28584",
    "label": "Swansboro - NC 28584",
    "county": "Carteret County",
    "state": "NC",
    "city": "Swansboro"
  },
  {
    "value": "28585",
    "label": "Trenton - NC 28585",
    "county": "Jones County",
    "state": "NC",
    "city": "Trenton"
  },
  {
    "value": "28586",
    "label": "Vanceboro - NC 28586",
    "county": "Craven County",
    "state": "NC",
    "city": "Vanceboro"
  },
  {
    "value": "28587",
    "label": "Vandemere - NC 28587",
    "county": "Pamlico County",
    "state": "NC",
    "city": "Vandemere"
  },
  {
    "value": "28590",
    "label": "Winterville - NC 28590",
    "county": "Pitt County",
    "state": "NC",
    "city": "Winterville"
  },
  {
    "value": "28594",
    "label": "Emerald Isle - NC 28594",
    "county": "Carteret County",
    "state": "NC",
    "city": "Emerald Isle"
  },
  {
    "value": "28601",
    "label": "Hickory - NC 28601",
    "county": "Catawba County",
    "state": "NC",
    "city": "Hickory"
  },
  {
    "value": "28602",
    "label": "Hickory - NC 28602",
    "county": "Catawba County",
    "state": "NC",
    "city": "Hickory"
  },
  {
    "value": "28604",
    "label": "Banner Elk - NC 28604",
    "county": "Watauga County",
    "state": "NC",
    "city": "Banner Elk"
  },
  {
    "value": "28605",
    "label": "Blowing Rock - NC 28605",
    "county": "Watauga County",
    "state": "NC",
    "city": "Blowing Rock"
  },
  {
    "value": "28606",
    "label": "Boomer - NC 28606",
    "county": "Wilkes County",
    "state": "NC",
    "city": "Boomer"
  },
  {
    "value": "28607",
    "label": "Boone - NC 28607",
    "county": "Watauga County",
    "state": "NC",
    "city": "Boone"
  },
  {
    "value": "28609",
    "label": "Catawba - NC 28609",
    "county": "Catawba County",
    "state": "NC",
    "city": "Catawba"
  },
  {
    "value": "28610",
    "label": "Claremont - NC 28610",
    "county": "Catawba County",
    "state": "NC",
    "city": "Claremont"
  },
  {
    "value": "28611",
    "label": "Collettsville - NC 28611",
    "county": "Caldwell County",
    "state": "NC",
    "city": "Collettsville"
  },
  {
    "value": "28612",
    "label": "Connelly Springs - NC 28612",
    "county": "Burke County",
    "state": "NC",
    "city": "Connelly Springs"
  },
  {
    "value": "28613",
    "label": "Conover - NC 28613",
    "county": "Catawba County",
    "state": "NC",
    "city": "Conover"
  },
  {
    "value": "28615",
    "label": "Creston - NC 28615",
    "county": "Ashe County",
    "state": "NC",
    "city": "Creston"
  },
  {
    "value": "28617",
    "label": "Crumpler - NC 28617",
    "county": "Ashe County",
    "state": "NC",
    "city": "Crumpler"
  },
  {
    "value": "28618",
    "label": "Deep Gap - NC 28618",
    "county": "Watauga County",
    "state": "NC",
    "city": "Deep Gap"
  },
  {
    "value": "28621",
    "label": "Elkin - NC 28621",
    "county": "Surry County",
    "state": "NC",
    "city": "Elkin"
  },
  {
    "value": "28622",
    "label": "Elk Park - NC 28622",
    "county": "Avery County",
    "state": "NC",
    "city": "Elk Park"
  },
  {
    "value": "28623",
    "label": "Ennice - NC 28623",
    "county": "Alleghany County",
    "state": "NC",
    "city": "Ennice"
  },
  {
    "value": "28624",
    "label": "Ferguson - NC 28624",
    "county": "Wilkes County",
    "state": "NC",
    "city": "Ferguson"
  },
  {
    "value": "28625",
    "label": "Statesville - NC 28625",
    "county": "Iredell County",
    "state": "NC",
    "city": "Statesville"
  },
  {
    "value": "28626",
    "label": "Fleetwood - NC 28626",
    "county": "Ashe County",
    "state": "NC",
    "city": "Fleetwood"
  },
  {
    "value": "28627",
    "label": "Glade Valley - NC 28627",
    "county": "Alleghany County",
    "state": "NC",
    "city": "Glade Valley"
  },
  {
    "value": "28629",
    "label": "Glendale Springs - NC 28629",
    "county": "Ashe County",
    "state": "NC",
    "city": "Glendale Springs"
  },
  {
    "value": "28630",
    "label": "Granite Falls - NC 28630",
    "county": "Caldwell County",
    "state": "NC",
    "city": "Granite Falls"
  },
  {
    "value": "28631",
    "label": "Grassy Creek - NC 28631",
    "county": "Ashe County",
    "state": "NC",
    "city": "Grassy Creek"
  },
  {
    "value": "28634",
    "label": "Harmony - NC 28634",
    "county": "Iredell County",
    "state": "NC",
    "city": "Harmony"
  },
  {
    "value": "28635",
    "label": "Hays - NC 28635",
    "county": "Wilkes County",
    "state": "NC",
    "city": "Hays"
  },
  {
    "value": "28636",
    "label": "Hiddenite - NC 28636",
    "county": "Alexander County",
    "state": "NC",
    "city": "Hiddenite"
  },
  {
    "value": "28637",
    "label": "Hildebran - NC 28637",
    "county": "Burke County",
    "state": "NC",
    "city": "Hildebran"
  },
  {
    "value": "28638",
    "label": "Hudson - NC 28638",
    "county": "Caldwell County",
    "state": "NC",
    "city": "Hudson"
  },
  {
    "value": "28640",
    "label": "Jefferson - NC 28640",
    "county": "Ashe County",
    "state": "NC",
    "city": "Jefferson"
  },
  {
    "value": "28642",
    "label": "Jonesville - NC 28642",
    "county": "Yadkin County",
    "state": "NC",
    "city": "Jonesville"
  },
  {
    "value": "28643",
    "label": "Lansing - NC 28643",
    "county": "Ashe County",
    "state": "NC",
    "city": "Lansing"
  },
  {
    "value": "28644",
    "label": "Laurel Springs - NC 28644",
    "county": "Alleghany County",
    "state": "NC",
    "city": "Laurel Springs"
  },
  {
    "value": "28645",
    "label": "Lenoir - NC 28645",
    "county": "Caldwell County",
    "state": "NC",
    "city": "Lenoir"
  },
  {
    "value": "28649",
    "label": "Mc Grady - NC 28649",
    "county": "Wilkes County",
    "state": "NC",
    "city": "Mc Grady"
  },
  {
    "value": "28650",
    "label": "Maiden - NC 28650",
    "county": "Catawba County",
    "state": "NC",
    "city": "Maiden"
  },
  {
    "value": "28651",
    "label": "Millers Creek - NC 28651",
    "county": "Wilkes County",
    "state": "NC",
    "city": "Millers Creek"
  },
  {
    "value": "28654",
    "label": "Moravian Falls - NC 28654",
    "county": "Wilkes County",
    "state": "NC",
    "city": "Moravian Falls"
  },
  {
    "value": "28655",
    "label": "Morganton - NC 28655",
    "county": "Burke County",
    "state": "NC",
    "city": "Morganton"
  },
  {
    "value": "28657",
    "label": "Newland - NC 28657",
    "county": "Avery County",
    "state": "NC",
    "city": "Newland"
  },
  {
    "value": "28658",
    "label": "Newton - NC 28658",
    "county": "Catawba County",
    "state": "NC",
    "city": "Newton"
  },
  {
    "value": "28659",
    "label": "North Wilkesboro - NC 28659",
    "county": "Wilkes County",
    "state": "NC",
    "city": "North Wilkesboro"
  },
  {
    "value": "28660",
    "label": "Olin - NC 28660",
    "county": "Iredell County",
    "state": "NC",
    "city": "Olin"
  },
  {
    "value": "28663",
    "label": "Piney Creek - NC 28663",
    "county": "Alleghany County",
    "state": "NC",
    "city": "Piney Creek"
  },
  {
    "value": "28665",
    "label": "Purlear - NC 28665",
    "county": "Wilkes County",
    "state": "NC",
    "city": "Purlear"
  },
  {
    "value": "28668",
    "label": "Roaring Gap - NC 28668",
    "county": "Alleghany County",
    "state": "NC",
    "city": "Roaring Gap"
  },
  {
    "value": "28669",
    "label": "Roaring River - NC 28669",
    "county": "Wilkes County",
    "state": "NC",
    "city": "Roaring River"
  },
  {
    "value": "28670",
    "label": "Ronda - NC 28670",
    "county": "Wilkes County",
    "state": "NC",
    "city": "Ronda"
  },
  {
    "value": "28672",
    "label": "Scottville - NC 28672",
    "county": "Ashe County",
    "state": "NC",
    "city": "Scottville"
  },
  {
    "value": "28673",
    "label": "Sherrills Ford - NC 28673",
    "county": "Catawba County",
    "state": "NC",
    "city": "Sherrills Ford"
  },
  {
    "value": "28675",
    "label": "Sparta - NC 28675",
    "county": "Alleghany County",
    "state": "NC",
    "city": "Sparta"
  },
  {
    "value": "28676",
    "label": "State Road - NC 28676",
    "county": "Surry County",
    "state": "NC",
    "city": "State Road"
  },
  {
    "value": "28677",
    "label": "Statesville - NC 28677",
    "county": "Iredell County",
    "state": "NC",
    "city": "Statesville"
  },
  {
    "value": "28678",
    "label": "Stony Point - NC 28678",
    "county": "Alexander County",
    "state": "NC",
    "city": "Stony Point"
  },
  {
    "value": "28679",
    "label": "Sugar Grove - NC 28679",
    "county": "Watauga County",
    "state": "NC",
    "city": "Sugar Grove"
  },
  {
    "value": "28681",
    "label": "Taylorsville - NC 28681",
    "county": "Alexander County",
    "state": "NC",
    "city": "Taylorsville"
  },
  {
    "value": "28682",
    "label": "Terrell - NC 28682",
    "county": "Catawba County",
    "state": "NC",
    "city": "Terrell"
  },
  {
    "value": "28683",
    "label": "Thurmond - NC 28683",
    "county": "Surry County",
    "state": "NC",
    "city": "Thurmond"
  },
  {
    "value": "28684",
    "label": "Todd - NC 28684",
    "county": "Ashe County",
    "state": "NC",
    "city": "Todd"
  },
  {
    "value": "28685",
    "label": "Traphill - NC 28685",
    "county": "Wilkes County",
    "state": "NC",
    "city": "Traphill"
  },
  {
    "value": "28689",
    "label": "Union Grove - NC 28689",
    "county": "Iredell County",
    "state": "NC",
    "city": "Union Grove"
  },
  {
    "value": "28690",
    "label": "Valdese - NC 28690",
    "county": "Burke County",
    "state": "NC",
    "city": "Valdese"
  },
  {
    "value": "28692",
    "label": "Vilas - NC 28692",
    "county": "Watauga County",
    "state": "NC",
    "city": "Vilas"
  },
  {
    "value": "28693",
    "label": "Warrensville - NC 28693",
    "county": "Ashe County",
    "state": "NC",
    "city": "Warrensville"
  },
  {
    "value": "28694",
    "label": "West Jefferson - NC 28694",
    "county": "Ashe County",
    "state": "NC",
    "city": "West Jefferson"
  },
  {
    "value": "28697",
    "label": "Wilkesboro - NC 28697",
    "county": "Wilkes County",
    "state": "NC",
    "city": "Wilkesboro"
  },
  {
    "value": "28698",
    "label": "Zionville - NC 28698",
    "county": "Watauga County",
    "state": "NC",
    "city": "Zionville"
  },
  {
    "value": "28701",
    "label": "Alexander - NC 28701",
    "county": "Buncombe County",
    "state": "NC",
    "city": "Alexander"
  },
  {
    "value": "28702",
    "label": "Almond - NC 28702",
    "county": "Graham County",
    "state": "NC",
    "city": "Almond"
  },
  {
    "value": "28704",
    "label": "Arden - NC 28704",
    "county": "Buncombe County",
    "state": "NC",
    "city": "Arden"
  },
  {
    "value": "28705",
    "label": "Bakersville - NC 28705",
    "county": "Mitchell County",
    "state": "NC",
    "city": "Bakersville"
  },
  {
    "value": "28708",
    "label": "Balsam Grove - NC 28708",
    "county": "Transylvania County",
    "state": "NC",
    "city": "Balsam Grove"
  },
  {
    "value": "28709",
    "label": "Barnardsville - NC 28709",
    "county": "Buncombe County",
    "state": "NC",
    "city": "Barnardsville"
  },
  {
    "value": "28711",
    "label": "Black Mountain - NC 28711",
    "county": "Buncombe County",
    "state": "NC",
    "city": "Black Mountain"
  },
  {
    "value": "28712",
    "label": "Brevard - NC 28712",
    "county": "Transylvania County",
    "state": "NC",
    "city": "Brevard"
  },
  {
    "value": "28713",
    "label": "Bryson City - NC 28713",
    "county": "Swain County",
    "state": "NC",
    "city": "Bryson City"
  },
  {
    "value": "28714",
    "label": "Burnsville - NC 28714",
    "county": "Yancey County",
    "state": "NC",
    "city": "Burnsville"
  },
  {
    "value": "28715",
    "label": "Candler - NC 28715",
    "county": "Buncombe County",
    "state": "NC",
    "city": "Candler"
  },
  {
    "value": "28716",
    "label": "Canton - NC 28716",
    "county": "Haywood County",
    "state": "NC",
    "city": "Canton"
  },
  {
    "value": "28717",
    "label": "Cashiers - NC 28717",
    "county": "Jackson County",
    "state": "NC",
    "city": "Cashiers"
  },
  {
    "value": "28718",
    "label": "Cedar Mountain - NC 28718",
    "county": "Transylvania County",
    "state": "NC",
    "city": "Cedar Mountain"
  },
  {
    "value": "28719",
    "label": "Cherokee - NC 28719",
    "county": "Jackson County",
    "state": "NC",
    "city": "Cherokee"
  },
  {
    "value": "28721",
    "label": "Clyde - NC 28721",
    "county": "Haywood County",
    "state": "NC",
    "city": "Clyde"
  },
  {
    "value": "28722",
    "label": "Columbus - NC 28722",
    "county": "Polk County",
    "state": "NC",
    "city": "Columbus"
  },
  {
    "value": "28723",
    "label": "Cullowhee - NC 28723",
    "county": "Jackson County",
    "state": "NC",
    "city": "Cullowhee"
  },
  {
    "value": "28726",
    "label": "East Flat Rock - NC 28726",
    "county": "Henderson County",
    "state": "NC",
    "city": "East Flat Rock"
  },
  {
    "value": "28729",
    "label": "Etowah - NC 28729",
    "county": "Henderson County",
    "state": "NC",
    "city": "Etowah"
  },
  {
    "value": "28730",
    "label": "Fairview - NC 28730",
    "county": "Buncombe County",
    "state": "NC",
    "city": "Fairview"
  },
  {
    "value": "28731",
    "label": "Flat Rock - NC 28731",
    "county": "Henderson County",
    "state": "NC",
    "city": "Flat Rock"
  },
  {
    "value": "28732",
    "label": "Fletcher - NC 28732",
    "county": "Henderson County",
    "state": "NC",
    "city": "Fletcher"
  },
  {
    "value": "28733",
    "label": "Fontana Dam - NC 28733",
    "county": "Graham County",
    "state": "NC",
    "city": "Fontana Dam"
  },
  {
    "value": "28734",
    "label": "Franklin - NC 28734",
    "county": "Macon County",
    "state": "NC",
    "city": "Franklin"
  },
  {
    "value": "28735",
    "label": "Gerton - NC 28735",
    "county": "Henderson County",
    "state": "NC",
    "city": "Gerton"
  },
  {
    "value": "28736",
    "label": "Glenville - NC 28736",
    "county": "Jackson County",
    "state": "NC",
    "city": "Glenville"
  },
  {
    "value": "28739",
    "label": "Hendersonville - NC 28739",
    "county": "Henderson County",
    "state": "NC",
    "city": "Hendersonville"
  },
  {
    "value": "28740",
    "label": "Green Mountain - NC 28740",
    "county": "Yancey County",
    "state": "NC",
    "city": "Green Mountain"
  },
  {
    "value": "28741",
    "label": "Highlands - NC 28741",
    "county": "Macon County",
    "state": "NC",
    "city": "Highlands"
  },
  {
    "value": "28742",
    "label": "Horse Shoe - NC 28742",
    "county": "Henderson County",
    "state": "NC",
    "city": "Horse Shoe"
  },
  {
    "value": "28743",
    "label": "Hot Springs - NC 28743",
    "county": "Madison County",
    "state": "NC",
    "city": "Hot Springs"
  },
  {
    "value": "28745",
    "label": "Lake Junaluska - NC 28745",
    "county": "Haywood County",
    "state": "NC",
    "city": "Lake Junaluska"
  },
  {
    "value": "28746",
    "label": "Lake Lure - NC 28746",
    "county": "Rutherford County",
    "state": "NC",
    "city": "Lake Lure"
  },
  {
    "value": "28747",
    "label": "Lake Toxaway - NC 28747",
    "county": "Transylvania County",
    "state": "NC",
    "city": "Lake Toxaway"
  },
  {
    "value": "28748",
    "label": "Leicester - NC 28748",
    "county": "Buncombe County",
    "state": "NC",
    "city": "Leicester"
  },
  {
    "value": "28751",
    "label": "Maggie Valley - NC 28751",
    "county": "Haywood County",
    "state": "NC",
    "city": "Maggie Valley"
  },
  {
    "value": "28752",
    "label": "Marion - NC 28752",
    "county": "McDowell County",
    "state": "NC",
    "city": "Marion"
  },
  {
    "value": "28753",
    "label": "Marshall - NC 28753",
    "county": "Madison County",
    "state": "NC",
    "city": "Marshall"
  },
  {
    "value": "28754",
    "label": "Mars Hill - NC 28754",
    "county": "Madison County",
    "state": "NC",
    "city": "Mars Hill"
  },
  {
    "value": "28756",
    "label": "Mill Spring - NC 28756",
    "county": "Polk County",
    "state": "NC",
    "city": "Mill Spring"
  },
  {
    "value": "28759",
    "label": "Mills River - NC 28759",
    "county": "Henderson County",
    "state": "NC",
    "city": "Mills River"
  },
  {
    "value": "28761",
    "label": "Nebo - NC 28761",
    "county": "McDowell County",
    "state": "NC",
    "city": "Nebo"
  },
  {
    "value": "28762",
    "label": "Old Fort - NC 28762",
    "county": "McDowell County",
    "state": "NC",
    "city": "Old Fort"
  },
  {
    "value": "28763",
    "label": "Otto - NC 28763",
    "county": "Macon County",
    "state": "NC",
    "city": "Otto"
  },
  {
    "value": "28766",
    "label": "Penrose - NC 28766",
    "county": "Transylvania County",
    "state": "NC",
    "city": "Penrose"
  },
  {
    "value": "28768",
    "label": "Pisgah Forest - NC 28768",
    "county": "Transylvania County",
    "state": "NC",
    "city": "Pisgah Forest"
  },
  {
    "value": "28771",
    "label": "Robbinsville - NC 28771",
    "county": "Graham County",
    "state": "NC",
    "city": "Robbinsville"
  },
  {
    "value": "28772",
    "label": "Rosman - NC 28772",
    "county": "Transylvania County",
    "state": "NC",
    "city": "Rosman"
  },
  {
    "value": "28773",
    "label": "Saluda - NC 28773",
    "county": "Polk County",
    "state": "NC",
    "city": "Saluda"
  },
  {
    "value": "28774",
    "label": "Sapphire - NC 28774",
    "county": "Transylvania County",
    "state": "NC",
    "city": "Sapphire"
  },
  {
    "value": "28775",
    "label": "Scaly Mountain - NC 28775",
    "county": "Macon County",
    "state": "NC",
    "city": "Scaly Mountain"
  },
  {
    "value": "28777",
    "label": "Spruce Pine - NC 28777",
    "county": "Mitchell County",
    "state": "NC",
    "city": "Spruce Pine"
  },
  {
    "value": "28778",
    "label": "Swannanoa - NC 28778",
    "county": "Buncombe County",
    "state": "NC",
    "city": "Swannanoa"
  },
  {
    "value": "28779",
    "label": "Sylva - NC 28779",
    "county": "Jackson County",
    "state": "NC",
    "city": "Sylva"
  },
  {
    "value": "28781",
    "label": "Topton - NC 28781",
    "county": "Macon County",
    "state": "NC",
    "city": "Topton"
  },
  {
    "value": "28782",
    "label": "Tryon - NC 28782",
    "county": "Polk County",
    "state": "NC",
    "city": "Tryon"
  },
  {
    "value": "28783",
    "label": "Tuckasegee - NC 28783",
    "county": "Jackson County",
    "state": "NC",
    "city": "Tuckasegee"
  },
  {
    "value": "28785",
    "label": "Waynesville - NC 28785",
    "county": "Haywood County",
    "state": "NC",
    "city": "Waynesville"
  },
  {
    "value": "28786",
    "label": "Waynesville - NC 28786",
    "county": "Haywood County",
    "state": "NC",
    "city": "Waynesville"
  },
  {
    "value": "28787",
    "label": "Weaverville - NC 28787",
    "county": "Buncombe County",
    "state": "NC",
    "city": "Weaverville"
  },
  {
    "value": "28789",
    "label": "Whittier - NC 28789",
    "county": "Jackson County",
    "state": "NC",
    "city": "Whittier"
  },
  {
    "value": "28790",
    "label": "Zirconia - NC 28790",
    "county": "Henderson County",
    "state": "NC",
    "city": "Zirconia"
  },
  {
    "value": "28791",
    "label": "Hendersonville - NC 28791",
    "county": "Henderson County",
    "state": "NC",
    "city": "Hendersonville"
  },
  {
    "value": "28792",
    "label": "Hendersonville - NC 28792",
    "county": "Henderson County",
    "state": "NC",
    "city": "Hendersonville"
  },
  {
    "value": "28801",
    "label": "Asheville - NC 28801",
    "county": "Buncombe County",
    "state": "NC",
    "city": "Asheville"
  },
  {
    "value": "28803",
    "label": "Asheville - NC 28803",
    "county": "Buncombe County",
    "state": "NC",
    "city": "Asheville"
  },
  {
    "value": "28804",
    "label": "Asheville - NC 28804",
    "county": "Buncombe County",
    "state": "NC",
    "city": "Asheville"
  },
  {
    "value": "28805",
    "label": "Asheville - NC 28805",
    "county": "Buncombe County",
    "state": "NC",
    "city": "Asheville"
  },
  {
    "value": "28806",
    "label": "Asheville - NC 28806",
    "county": "Buncombe County",
    "state": "NC",
    "city": "Asheville"
  },
  {
    "value": "28810",
    "label": "Asheville - NC 28810",
    "county": "Buncombe County",
    "state": "NC",
    "city": "Asheville"
  },
  {
    "value": "28901",
    "label": "Andrews - NC 28901",
    "county": "Cherokee County",
    "state": "NC",
    "city": "Andrews"
  },
  {
    "value": "28902",
    "label": "Brasstown - NC 28902",
    "county": "Clay County",
    "state": "NC",
    "city": "Brasstown"
  },
  {
    "value": "28904",
    "label": "Hayesville - NC 28904",
    "county": "Clay County",
    "state": "NC",
    "city": "Hayesville"
  },
  {
    "value": "28905",
    "label": "Marble - NC 28905",
    "county": "Cherokee County",
    "state": "NC",
    "city": "Marble"
  },
  {
    "value": "28906",
    "label": "Murphy - NC 28906",
    "county": "Cherokee County",
    "state": "NC",
    "city": "Murphy"
  },
  {
    "value": "28909",
    "label": "Warne - NC 28909",
    "county": "Clay County",
    "state": "NC",
    "city": "Warne"
  },
  {
    "value": "58002",
    "label": "Absaraka - ND 58002",
    "county": "Cass County",
    "state": "ND",
    "city": "Absaraka"
  },
  {
    "value": "58004",
    "label": "Amenia - ND 58004",
    "county": "Cass County",
    "state": "ND",
    "city": "Amenia"
  },
  {
    "value": "58005",
    "label": "Argusville - ND 58005",
    "county": "Cass County",
    "state": "ND",
    "city": "Argusville"
  },
  {
    "value": "58006",
    "label": "Arthur - ND 58006",
    "county": "Cass County",
    "state": "ND",
    "city": "Arthur"
  },
  {
    "value": "58007",
    "label": "Ayr - ND 58007",
    "county": "Cass County",
    "state": "ND",
    "city": "Ayr"
  },
  {
    "value": "58008",
    "label": "Barney - ND 58008",
    "county": "Richland County",
    "state": "ND",
    "city": "Barney"
  },
  {
    "value": "58009",
    "label": "Blanchard - ND 58009",
    "county": "Traill County",
    "state": "ND",
    "city": "Blanchard"
  },
  {
    "value": "58011",
    "label": "Buffalo - ND 58011",
    "county": "Cass County",
    "state": "ND",
    "city": "Buffalo"
  },
  {
    "value": "58012",
    "label": "Casselton - ND 58012",
    "county": "Cass County",
    "state": "ND",
    "city": "Casselton"
  },
  {
    "value": "58013",
    "label": "Cayuga - ND 58013",
    "county": "Sargent County",
    "state": "ND",
    "city": "Cayuga"
  },
  {
    "value": "58015",
    "label": "Christine - ND 58015",
    "county": "Richland County",
    "state": "ND",
    "city": "Christine"
  },
  {
    "value": "58016",
    "label": "Clifford - ND 58016",
    "county": "Traill County",
    "state": "ND",
    "city": "Clifford"
  },
  {
    "value": "58017",
    "label": "Cogswell - ND 58017",
    "county": "Sargent County",
    "state": "ND",
    "city": "Cogswell"
  },
  {
    "value": "58018",
    "label": "Colfax - ND 58018",
    "county": "Richland County",
    "state": "ND",
    "city": "Colfax"
  },
  {
    "value": "58021",
    "label": "Davenport - ND 58021",
    "county": "Cass County",
    "state": "ND",
    "city": "Davenport"
  },
  {
    "value": "58027",
    "label": "Enderlin - ND 58027",
    "county": "Ransom County",
    "state": "ND",
    "city": "Enderlin"
  },
  {
    "value": "58029",
    "label": "Erie - ND 58029",
    "county": "Cass County",
    "state": "ND",
    "city": "Erie"
  },
  {
    "value": "58030",
    "label": "Fairmount - ND 58030",
    "county": "Richland County",
    "state": "ND",
    "city": "Fairmount"
  },
  {
    "value": "58031",
    "label": "Fingal - ND 58031",
    "county": "Barnes County",
    "state": "ND",
    "city": "Fingal"
  },
  {
    "value": "58032",
    "label": "Forman - ND 58032",
    "county": "Sargent County",
    "state": "ND",
    "city": "Forman"
  },
  {
    "value": "58033",
    "label": "Fort Ransom - ND 58033",
    "county": "Ransom County",
    "state": "ND",
    "city": "Fort Ransom"
  },
  {
    "value": "58035",
    "label": "Galesburg - ND 58035",
    "county": "Traill County",
    "state": "ND",
    "city": "Galesburg"
  },
  {
    "value": "58036",
    "label": "Gardner - ND 58036",
    "county": "Cass County",
    "state": "ND",
    "city": "Gardner"
  },
  {
    "value": "58038",
    "label": "Grandin - ND 58038",
    "county": "Cass County",
    "state": "ND",
    "city": "Grandin"
  },
  {
    "value": "58040",
    "label": "Gwinner - ND 58040",
    "county": "Sargent County",
    "state": "ND",
    "city": "Gwinner"
  },
  {
    "value": "58041",
    "label": "Hankinson - ND 58041",
    "county": "Richland County",
    "state": "ND",
    "city": "Hankinson"
  },
  {
    "value": "58042",
    "label": "Harwood - ND 58042",
    "county": "Cass County",
    "state": "ND",
    "city": "Harwood"
  },
  {
    "value": "58043",
    "label": "Havana - ND 58043",
    "county": "Sargent County",
    "state": "ND",
    "city": "Havana"
  },
  {
    "value": "58045",
    "label": "Hillsboro - ND 58045",
    "county": "Traill County",
    "state": "ND",
    "city": "Hillsboro"
  },
  {
    "value": "58046",
    "label": "Hope - ND 58046",
    "county": "Steele County",
    "state": "ND",
    "city": "Hope"
  },
  {
    "value": "58047",
    "label": "Horace - ND 58047",
    "county": "Cass County",
    "state": "ND",
    "city": "Horace"
  },
  {
    "value": "58048",
    "label": "Hunter - ND 58048",
    "county": "Cass County",
    "state": "ND",
    "city": "Hunter"
  },
  {
    "value": "58049",
    "label": "Kathryn - ND 58049",
    "county": "Barnes County",
    "state": "ND",
    "city": "Kathryn"
  },
  {
    "value": "58051",
    "label": "Kindred - ND 58051",
    "county": "Cass County",
    "state": "ND",
    "city": "Kindred"
  },
  {
    "value": "58052",
    "label": "Leonard - ND 58052",
    "county": "Cass County",
    "state": "ND",
    "city": "Leonard"
  },
  {
    "value": "58053",
    "label": "Lidgerwood - ND 58053",
    "county": "Richland County",
    "state": "ND",
    "city": "Lidgerwood"
  },
  {
    "value": "58054",
    "label": "Lisbon - ND 58054",
    "county": "Ransom County",
    "state": "ND",
    "city": "Lisbon"
  },
  {
    "value": "58056",
    "label": "Luverne - ND 58056",
    "county": "Steele County",
    "state": "ND",
    "city": "Luverne"
  },
  {
    "value": "58057",
    "label": "Mcleod - ND 58057",
    "county": "Richland County",
    "state": "ND",
    "city": "Mcleod"
  },
  {
    "value": "58058",
    "label": "Mantador - ND 58058",
    "county": "Richland County",
    "state": "ND",
    "city": "Mantador"
  },
  {
    "value": "58059",
    "label": "Mapleton - ND 58059",
    "county": "Cass County",
    "state": "ND",
    "city": "Mapleton"
  },
  {
    "value": "58060",
    "label": "Milnor - ND 58060",
    "county": "Sargent County",
    "state": "ND",
    "city": "Milnor"
  },
  {
    "value": "58061",
    "label": "Mooreton - ND 58061",
    "county": "Richland County",
    "state": "ND",
    "city": "Mooreton"
  },
  {
    "value": "58062",
    "label": "Nome - ND 58062",
    "county": "Barnes County",
    "state": "ND",
    "city": "Nome"
  },
  {
    "value": "58063",
    "label": "Oriska - ND 58063",
    "county": "Barnes County",
    "state": "ND",
    "city": "Oriska"
  },
  {
    "value": "58064",
    "label": "Page - ND 58064",
    "county": "Cass County",
    "state": "ND",
    "city": "Page"
  },
  {
    "value": "58065",
    "label": "Pillsbury - ND 58065",
    "county": "Barnes County",
    "state": "ND",
    "city": "Pillsbury"
  },
  {
    "value": "58067",
    "label": "Rutland - ND 58067",
    "county": "Sargent County",
    "state": "ND",
    "city": "Rutland"
  },
  {
    "value": "58068",
    "label": "Sheldon - ND 58068",
    "county": "Ransom County",
    "state": "ND",
    "city": "Sheldon"
  },
  {
    "value": "58069",
    "label": "Stirum - ND 58069",
    "county": "Sargent County",
    "state": "ND",
    "city": "Stirum"
  },
  {
    "value": "58071",
    "label": "Tower City - ND 58071",
    "county": "Cass County",
    "state": "ND",
    "city": "Tower City"
  },
  {
    "value": "58072",
    "label": "Valley City - ND 58072",
    "county": "Barnes County",
    "state": "ND",
    "city": "Valley City"
  },
  {
    "value": "58075",
    "label": "Wahpeton - ND 58075",
    "county": "Richland County",
    "state": "ND",
    "city": "Wahpeton"
  },
  {
    "value": "58077",
    "label": "Walcott - ND 58077",
    "county": "Richland County",
    "state": "ND",
    "city": "Walcott"
  },
  {
    "value": "58078",
    "label": "West Fargo - ND 58078",
    "county": "Cass County",
    "state": "ND",
    "city": "West Fargo"
  },
  {
    "value": "58079",
    "label": "Wheatland - ND 58079",
    "county": "Cass County",
    "state": "ND",
    "city": "Wheatland"
  },
  {
    "value": "58081",
    "label": "Wyndmere - ND 58081",
    "county": "Richland County",
    "state": "ND",
    "city": "Wyndmere"
  },
  {
    "value": "58102",
    "label": "Fargo - ND 58102",
    "county": "Cass County",
    "state": "ND",
    "city": "Fargo"
  },
  {
    "value": "58103",
    "label": "Fargo - ND 58103",
    "county": "Cass County",
    "state": "ND",
    "city": "Fargo"
  },
  {
    "value": "58104",
    "label": "Fargo - ND 58104",
    "county": "Cass County",
    "state": "ND",
    "city": "Fargo"
  },
  {
    "value": "58201",
    "label": "Grand Forks - ND 58201",
    "county": "Grand Forks County",
    "state": "ND",
    "city": "Grand Forks"
  },
  {
    "value": "58202",
    "label": "Grand Forks - ND 58202",
    "county": "Grand Forks County",
    "state": "ND",
    "city": "Grand Forks"
  },
  {
    "value": "58203",
    "label": "Grand Forks - ND 58203",
    "county": "Grand Forks County",
    "state": "ND",
    "city": "Grand Forks"
  },
  {
    "value": "58204",
    "label": "Grand Forks AFB - ND 58204",
    "county": "Grand Forks County",
    "state": "ND",
    "city": "Grand Forks AFB"
  },
  {
    "value": "58205",
    "label": "Grand Forks AFB - ND 58205",
    "county": "Grand Forks County",
    "state": "ND",
    "city": "Grand Forks AFB"
  },
  {
    "value": "58210",
    "label": "Adams - ND 58210",
    "county": "Walsh County",
    "state": "ND",
    "city": "Adams"
  },
  {
    "value": "58212",
    "label": "Aneta - ND 58212",
    "county": "Nelson County",
    "state": "ND",
    "city": "Aneta"
  },
  {
    "value": "58214",
    "label": "Arvilla - ND 58214",
    "county": "Grand Forks County",
    "state": "ND",
    "city": "Arvilla"
  },
  {
    "value": "58216",
    "label": "Bathgate - ND 58216",
    "county": "Pembina County",
    "state": "ND",
    "city": "Bathgate"
  },
  {
    "value": "58218",
    "label": "Buxton - ND 58218",
    "county": "Traill County",
    "state": "ND",
    "city": "Buxton"
  },
  {
    "value": "58219",
    "label": "Caledonia - ND 58219",
    "county": "Traill County",
    "state": "ND",
    "city": "Caledonia"
  },
  {
    "value": "58220",
    "label": "Cavalier - ND 58220",
    "county": "Pembina County",
    "state": "ND",
    "city": "Cavalier"
  },
  {
    "value": "58222",
    "label": "Crystal - ND 58222",
    "county": "Pembina County",
    "state": "ND",
    "city": "Crystal"
  },
  {
    "value": "58223",
    "label": "Cummings - ND 58223",
    "county": "Traill County",
    "state": "ND",
    "city": "Cummings"
  },
  {
    "value": "58224",
    "label": "Dahlen - ND 58224",
    "county": "Nelson County",
    "state": "ND",
    "city": "Dahlen"
  },
  {
    "value": "58225",
    "label": "Drayton - ND 58225",
    "county": "Pembina County",
    "state": "ND",
    "city": "Drayton"
  },
  {
    "value": "58227",
    "label": "Edinburg - ND 58227",
    "county": "Walsh County",
    "state": "ND",
    "city": "Edinburg"
  },
  {
    "value": "58228",
    "label": "Emerado - ND 58228",
    "county": "Grand Forks County",
    "state": "ND",
    "city": "Emerado"
  },
  {
    "value": "58229",
    "label": "Fairdale - ND 58229",
    "county": "Walsh County",
    "state": "ND",
    "city": "Fairdale"
  },
  {
    "value": "58230",
    "label": "Finley - ND 58230",
    "county": "Steele County",
    "state": "ND",
    "city": "Finley"
  },
  {
    "value": "58231",
    "label": "Fordville - ND 58231",
    "county": "Walsh County",
    "state": "ND",
    "city": "Fordville"
  },
  {
    "value": "58233",
    "label": "Forest River - ND 58233",
    "county": "Walsh County",
    "state": "ND",
    "city": "Forest River"
  },
  {
    "value": "58235",
    "label": "Gilby - ND 58235",
    "county": "Grand Forks County",
    "state": "ND",
    "city": "Gilby"
  },
  {
    "value": "58236",
    "label": "Glasston - ND 58236",
    "county": "Pembina County",
    "state": "ND",
    "city": "Glasston"
  },
  {
    "value": "58237",
    "label": "Grafton - ND 58237",
    "county": "Walsh County",
    "state": "ND",
    "city": "Grafton"
  },
  {
    "value": "58238",
    "label": "Hamilton - ND 58238",
    "county": "Pembina County",
    "state": "ND",
    "city": "Hamilton"
  },
  {
    "value": "58239",
    "label": "Hannah - ND 58239",
    "county": "Cavalier County",
    "state": "ND",
    "city": "Hannah"
  },
  {
    "value": "58240",
    "label": "Hatton - ND 58240",
    "county": "Traill County",
    "state": "ND",
    "city": "Hatton"
  },
  {
    "value": "58241",
    "label": "Hensel - ND 58241",
    "county": "Pembina County",
    "state": "ND",
    "city": "Hensel"
  },
  {
    "value": "58243",
    "label": "Hoople - ND 58243",
    "county": "Walsh County",
    "state": "ND",
    "city": "Hoople"
  },
  {
    "value": "58244",
    "label": "Inkster - ND 58244",
    "county": "Grand Forks County",
    "state": "ND",
    "city": "Inkster"
  },
  {
    "value": "58249",
    "label": "Langdon - ND 58249",
    "county": "Cavalier County",
    "state": "ND",
    "city": "Langdon"
  },
  {
    "value": "58250",
    "label": "Lankin - ND 58250",
    "county": "Walsh County",
    "state": "ND",
    "city": "Lankin"
  },
  {
    "value": "58251",
    "label": "Larimore - ND 58251",
    "county": "Grand Forks County",
    "state": "ND",
    "city": "Larimore"
  },
  {
    "value": "58254",
    "label": "Mcville - ND 58254",
    "county": "Nelson County",
    "state": "ND",
    "city": "Mcville"
  },
  {
    "value": "58255",
    "label": "Maida - ND 58255",
    "county": "Cavalier County",
    "state": "ND",
    "city": "Maida"
  },
  {
    "value": "58256",
    "label": "Manvel - ND 58256",
    "county": "Grand Forks County",
    "state": "ND",
    "city": "Manvel"
  },
  {
    "value": "58257",
    "label": "Mayville - ND 58257",
    "county": "Traill County",
    "state": "ND",
    "city": "Mayville"
  },
  {
    "value": "58258",
    "label": "Mekinock - ND 58258",
    "county": "Grand Forks County",
    "state": "ND",
    "city": "Mekinock"
  },
  {
    "value": "58259",
    "label": "Michigan - ND 58259",
    "county": "Nelson County",
    "state": "ND",
    "city": "Michigan"
  },
  {
    "value": "58260",
    "label": "Milton - ND 58260",
    "county": "Cavalier County",
    "state": "ND",
    "city": "Milton"
  },
  {
    "value": "58261",
    "label": "Minto - ND 58261",
    "county": "Walsh County",
    "state": "ND",
    "city": "Minto"
  },
  {
    "value": "58262",
    "label": "Mountain - ND 58262",
    "county": "Pembina County",
    "state": "ND",
    "city": "Mountain"
  },
  {
    "value": "58265",
    "label": "Neche - ND 58265",
    "county": "Pembina County",
    "state": "ND",
    "city": "Neche"
  },
  {
    "value": "58266",
    "label": "Niagara - ND 58266",
    "county": "Grand Forks County",
    "state": "ND",
    "city": "Niagara"
  },
  {
    "value": "58267",
    "label": "Northwood - ND 58267",
    "county": "Grand Forks County",
    "state": "ND",
    "city": "Northwood"
  },
  {
    "value": "58269",
    "label": "Osnabrock - ND 58269",
    "county": "Cavalier County",
    "state": "ND",
    "city": "Osnabrock"
  },
  {
    "value": "58270",
    "label": "Park River - ND 58270",
    "county": "Walsh County",
    "state": "ND",
    "city": "Park River"
  },
  {
    "value": "58271",
    "label": "Pembina - ND 58271",
    "county": "Pembina County",
    "state": "ND",
    "city": "Pembina"
  },
  {
    "value": "58272",
    "label": "Petersburg - ND 58272",
    "county": "Nelson County",
    "state": "ND",
    "city": "Petersburg"
  },
  {
    "value": "58273",
    "label": "Pisek - ND 58273",
    "county": "Walsh County",
    "state": "ND",
    "city": "Pisek"
  },
  {
    "value": "58274",
    "label": "Portland - ND 58274",
    "county": "Traill County",
    "state": "ND",
    "city": "Portland"
  },
  {
    "value": "58275",
    "label": "Reynolds - ND 58275",
    "county": "Grand Forks County",
    "state": "ND",
    "city": "Reynolds"
  },
  {
    "value": "58276",
    "label": "Saint Thomas - ND 58276",
    "county": "Pembina County",
    "state": "ND",
    "city": "Saint Thomas"
  },
  {
    "value": "58277",
    "label": "Sharon - ND 58277",
    "county": "Steele County",
    "state": "ND",
    "city": "Sharon"
  },
  {
    "value": "58278",
    "label": "Thompson - ND 58278",
    "county": "Grand Forks County",
    "state": "ND",
    "city": "Thompson"
  },
  {
    "value": "58281",
    "label": "Wales - ND 58281",
    "county": "Cavalier County",
    "state": "ND",
    "city": "Wales"
  },
  {
    "value": "58282",
    "label": "Walhalla - ND 58282",
    "county": "Pembina County",
    "state": "ND",
    "city": "Walhalla"
  },
  {
    "value": "58301",
    "label": "Devils Lake - ND 58301",
    "county": "Ramsey County",
    "state": "ND",
    "city": "Devils Lake"
  },
  {
    "value": "58310",
    "label": "Agate - ND 58310",
    "county": "Rolette County",
    "state": "ND",
    "city": "Agate"
  },
  {
    "value": "58311",
    "label": "Alsen - ND 58311",
    "county": "Cavalier County",
    "state": "ND",
    "city": "Alsen"
  },
  {
    "value": "58313",
    "label": "Balta - ND 58313",
    "county": "Pierce County",
    "state": "ND",
    "city": "Balta"
  },
  {
    "value": "58316",
    "label": "Belcourt - ND 58316",
    "county": "Rolette County",
    "state": "ND",
    "city": "Belcourt"
  },
  {
    "value": "58317",
    "label": "Bisbee - ND 58317",
    "county": "Towner County",
    "state": "ND",
    "city": "Bisbee"
  },
  {
    "value": "58318",
    "label": "Bottineau - ND 58318",
    "county": "Bottineau County",
    "state": "ND",
    "city": "Bottineau"
  },
  {
    "value": "58319",
    "label": "Bremen - ND 58319",
    "county": "Wells County",
    "state": "ND",
    "city": "Bremen"
  },
  {
    "value": "58321",
    "label": "Brocket - ND 58321",
    "county": "Ramsey County",
    "state": "ND",
    "city": "Brocket"
  },
  {
    "value": "58323",
    "label": "Calvin - ND 58323",
    "county": "Cavalier County",
    "state": "ND",
    "city": "Calvin"
  },
  {
    "value": "58324",
    "label": "Cando - ND 58324",
    "county": "Towner County",
    "state": "ND",
    "city": "Cando"
  },
  {
    "value": "58325",
    "label": "Churchs Ferry - ND 58325",
    "county": "Ramsey County",
    "state": "ND",
    "city": "Churchs Ferry"
  },
  {
    "value": "58327",
    "label": "Crary - ND 58327",
    "county": "Ramsey County",
    "state": "ND",
    "city": "Crary"
  },
  {
    "value": "58329",
    "label": "Dunseith - ND 58329",
    "county": "Rolette County",
    "state": "ND",
    "city": "Dunseith"
  },
  {
    "value": "58330",
    "label": "Edmore - ND 58330",
    "county": "Ramsey County",
    "state": "ND",
    "city": "Edmore"
  },
  {
    "value": "58331",
    "label": "Egeland - ND 58331",
    "county": "Towner County",
    "state": "ND",
    "city": "Egeland"
  },
  {
    "value": "58332",
    "label": "Esmond - ND 58332",
    "county": "Benson County",
    "state": "ND",
    "city": "Esmond"
  },
  {
    "value": "58338",
    "label": "Hampden - ND 58338",
    "county": "Ramsey County",
    "state": "ND",
    "city": "Hampden"
  },
  {
    "value": "58339",
    "label": "Hansboro - ND 58339",
    "county": "Towner County",
    "state": "ND",
    "city": "Hansboro"
  },
  {
    "value": "58341",
    "label": "Harvey - ND 58341",
    "county": "Wells County",
    "state": "ND",
    "city": "Harvey"
  },
  {
    "value": "58343",
    "label": "Knox - ND 58343",
    "county": "Benson County",
    "state": "ND",
    "city": "Knox"
  },
  {
    "value": "58344",
    "label": "Lakota - ND 58344",
    "county": "Nelson County",
    "state": "ND",
    "city": "Lakota"
  },
  {
    "value": "58345",
    "label": "Lawton - ND 58345",
    "county": "Ramsey County",
    "state": "ND",
    "city": "Lawton"
  },
  {
    "value": "58346",
    "label": "Leeds - ND 58346",
    "county": "Benson County",
    "state": "ND",
    "city": "Leeds"
  },
  {
    "value": "58348",
    "label": "Maddock - ND 58348",
    "county": "Benson County",
    "state": "ND",
    "city": "Maddock"
  },
  {
    "value": "58351",
    "label": "Minnewaukan - ND 58351",
    "county": "Benson County",
    "state": "ND",
    "city": "Minnewaukan"
  },
  {
    "value": "58352",
    "label": "Munich - ND 58352",
    "county": "Cavalier County",
    "state": "ND",
    "city": "Munich"
  },
  {
    "value": "58353",
    "label": "Mylo - ND 58353",
    "county": "Rolette County",
    "state": "ND",
    "city": "Mylo"
  },
  {
    "value": "58355",
    "label": "Nekoma - ND 58355",
    "county": "Cavalier County",
    "state": "ND",
    "city": "Nekoma"
  },
  {
    "value": "58356",
    "label": "New Rockford - ND 58356",
    "county": "Eddy County",
    "state": "ND",
    "city": "New Rockford"
  },
  {
    "value": "58357",
    "label": "Oberon - ND 58357",
    "county": "Benson County",
    "state": "ND",
    "city": "Oberon"
  },
  {
    "value": "58361",
    "label": "Pekin - ND 58361",
    "county": "Nelson County",
    "state": "ND",
    "city": "Pekin"
  },
  {
    "value": "58362",
    "label": "Penn - ND 58362",
    "county": "Ramsey County",
    "state": "ND",
    "city": "Penn"
  },
  {
    "value": "58363",
    "label": "Perth - ND 58363",
    "county": "Towner County",
    "state": "ND",
    "city": "Perth"
  },
  {
    "value": "58365",
    "label": "Rocklake - ND 58365",
    "county": "Towner County",
    "state": "ND",
    "city": "Rocklake"
  },
  {
    "value": "58366",
    "label": "Rolette - ND 58366",
    "county": "Rolette County",
    "state": "ND",
    "city": "Rolette"
  },
  {
    "value": "58367",
    "label": "Rolla - ND 58367",
    "county": "Rolette County",
    "state": "ND",
    "city": "Rolla"
  },
  {
    "value": "58368",
    "label": "Rugby - ND 58368",
    "county": "Pierce County",
    "state": "ND",
    "city": "Rugby"
  },
  {
    "value": "58369",
    "label": "Saint John - ND 58369",
    "county": "Rolette County",
    "state": "ND",
    "city": "Saint John"
  },
  {
    "value": "58370",
    "label": "Saint Michael - ND 58370",
    "county": "Benson County",
    "state": "ND",
    "city": "Saint Michael"
  },
  {
    "value": "58372",
    "label": "Sarles - ND 58372",
    "county": "Cavalier County",
    "state": "ND",
    "city": "Sarles"
  },
  {
    "value": "58374",
    "label": "Sheyenne - ND 58374",
    "county": "Eddy County",
    "state": "ND",
    "city": "Sheyenne"
  },
  {
    "value": "58377",
    "label": "Starkweather - ND 58377",
    "county": "Ramsey County",
    "state": "ND",
    "city": "Starkweather"
  },
  {
    "value": "58380",
    "label": "Tolna - ND 58380",
    "county": "Nelson County",
    "state": "ND",
    "city": "Tolna"
  },
  {
    "value": "58381",
    "label": "Warwick - ND 58381",
    "county": "Eddy County",
    "state": "ND",
    "city": "Warwick"
  },
  {
    "value": "58382",
    "label": "Webster - ND 58382",
    "county": "Ramsey County",
    "state": "ND",
    "city": "Webster"
  },
  {
    "value": "58384",
    "label": "Willow City - ND 58384",
    "county": "Bottineau County",
    "state": "ND",
    "city": "Willow City"
  },
  {
    "value": "58385",
    "label": "Wolford - ND 58385",
    "county": "Pierce County",
    "state": "ND",
    "city": "Wolford"
  },
  {
    "value": "58386",
    "label": "York - ND 58386",
    "county": "Benson County",
    "state": "ND",
    "city": "York"
  },
  {
    "value": "58401",
    "label": "Jamestown - ND 58401",
    "county": "Stutsman County",
    "state": "ND",
    "city": "Jamestown"
  },
  {
    "value": "58413",
    "label": "Ashley - ND 58413",
    "county": "McIntosh County",
    "state": "ND",
    "city": "Ashley"
  },
  {
    "value": "58415",
    "label": "Berlin - ND 58415",
    "county": "LaMoure County",
    "state": "ND",
    "city": "Berlin"
  },
  {
    "value": "58416",
    "label": "Binford - ND 58416",
    "county": "Griggs County",
    "state": "ND",
    "city": "Binford"
  },
  {
    "value": "58418",
    "label": "Bowdon - ND 58418",
    "county": "Wells County",
    "state": "ND",
    "city": "Bowdon"
  },
  {
    "value": "58420",
    "label": "Buchanan - ND 58420",
    "county": "Stutsman County",
    "state": "ND",
    "city": "Buchanan"
  },
  {
    "value": "58421",
    "label": "Carrington - ND 58421",
    "county": "Foster County",
    "state": "ND",
    "city": "Carrington"
  },
  {
    "value": "58422",
    "label": "Cathay - ND 58422",
    "county": "Wells County",
    "state": "ND",
    "city": "Cathay"
  },
  {
    "value": "58423",
    "label": "Chaseley - ND 58423",
    "county": "Wells County",
    "state": "ND",
    "city": "Chaseley"
  },
  {
    "value": "58424",
    "label": "Cleveland - ND 58424",
    "county": "Stutsman County",
    "state": "ND",
    "city": "Cleveland"
  },
  {
    "value": "58425",
    "label": "Cooperstown - ND 58425",
    "county": "Griggs County",
    "state": "ND",
    "city": "Cooperstown"
  },
  {
    "value": "58426",
    "label": "Courtenay - ND 58426",
    "county": "Stutsman County",
    "state": "ND",
    "city": "Courtenay"
  },
  {
    "value": "58428",
    "label": "Dawson - ND 58428",
    "county": "Kidder County",
    "state": "ND",
    "city": "Dawson"
  },
  {
    "value": "58429",
    "label": "Dazey - ND 58429",
    "county": "Barnes County",
    "state": "ND",
    "city": "Dazey"
  },
  {
    "value": "58430",
    "label": "Denhoff - ND 58430",
    "county": "Sheridan County",
    "state": "ND",
    "city": "Denhoff"
  },
  {
    "value": "58431",
    "label": "Dickey - ND 58431",
    "county": "LaMoure County",
    "state": "ND",
    "city": "Dickey"
  },
  {
    "value": "58433",
    "label": "Edgeley - ND 58433",
    "county": "LaMoure County",
    "state": "ND",
    "city": "Edgeley"
  },
  {
    "value": "58436",
    "label": "Ellendale - ND 58436",
    "county": "Dickey County",
    "state": "ND",
    "city": "Ellendale"
  },
  {
    "value": "58438",
    "label": "Fessenden - ND 58438",
    "county": "Wells County",
    "state": "ND",
    "city": "Fessenden"
  },
  {
    "value": "58439",
    "label": "Forbes - ND 58439",
    "county": "McPherson County",
    "state": "ND",
    "city": "Forbes"
  },
  {
    "value": "58440",
    "label": "Fredonia - ND 58440",
    "county": "Logan County",
    "state": "ND",
    "city": "Fredonia"
  },
  {
    "value": "58441",
    "label": "Fullerton - ND 58441",
    "county": "Dickey County",
    "state": "ND",
    "city": "Fullerton"
  },
  {
    "value": "58442",
    "label": "Gackle - ND 58442",
    "county": "Logan County",
    "state": "ND",
    "city": "Gackle"
  },
  {
    "value": "58443",
    "label": "Glenfield - ND 58443",
    "county": "Foster County",
    "state": "ND",
    "city": "Glenfield"
  },
  {
    "value": "58444",
    "label": "Goodrich - ND 58444",
    "county": "Sheridan County",
    "state": "ND",
    "city": "Goodrich"
  },
  {
    "value": "58445",
    "label": "Grace City - ND 58445",
    "county": "Foster County",
    "state": "ND",
    "city": "Grace City"
  },
  {
    "value": "58448",
    "label": "Hannaford - ND 58448",
    "county": "Griggs County",
    "state": "ND",
    "city": "Hannaford"
  },
  {
    "value": "58451",
    "label": "Hurdsfield - ND 58451",
    "county": "Wells County",
    "state": "ND",
    "city": "Hurdsfield"
  },
  {
    "value": "58452",
    "label": "Jessie - ND 58452",
    "county": "Griggs County",
    "state": "ND",
    "city": "Jessie"
  },
  {
    "value": "58454",
    "label": "Jud - ND 58454",
    "county": "LaMoure County",
    "state": "ND",
    "city": "Jud"
  },
  {
    "value": "58455",
    "label": "Kensal - ND 58455",
    "county": "Stutsman County",
    "state": "ND",
    "city": "Kensal"
  },
  {
    "value": "58456",
    "label": "Kulm - ND 58456",
    "county": "LaMoure County",
    "state": "ND",
    "city": "Kulm"
  },
  {
    "value": "58458",
    "label": "Lamoure - ND 58458",
    "county": "LaMoure County",
    "state": "ND",
    "city": "Lamoure"
  },
  {
    "value": "58460",
    "label": "Lehr - ND 58460",
    "county": "McIntosh County",
    "state": "ND",
    "city": "Lehr"
  },
  {
    "value": "58461",
    "label": "Litchville - ND 58461",
    "county": "Barnes County",
    "state": "ND",
    "city": "Litchville"
  },
  {
    "value": "58463",
    "label": "Mcclusky - ND 58463",
    "county": "Sheridan County",
    "state": "ND",
    "city": "Mcclusky"
  },
  {
    "value": "58464",
    "label": "Mchenry - ND 58464",
    "county": "Foster County",
    "state": "ND",
    "city": "Mchenry"
  },
  {
    "value": "58466",
    "label": "Marion - ND 58466",
    "county": "LaMoure County",
    "state": "ND",
    "city": "Marion"
  },
  {
    "value": "58467",
    "label": "Medina - ND 58467",
    "county": "Stutsman County",
    "state": "ND",
    "city": "Medina"
  },
  {
    "value": "58472",
    "label": "Montpelier - ND 58472",
    "county": "Stutsman County",
    "state": "ND",
    "city": "Montpelier"
  },
  {
    "value": "58474",
    "label": "Oakes - ND 58474",
    "county": "Dickey County",
    "state": "ND",
    "city": "Oakes"
  },
  {
    "value": "58475",
    "label": "Pettibone - ND 58475",
    "county": "Kidder County",
    "state": "ND",
    "city": "Pettibone"
  },
  {
    "value": "58476",
    "label": "Pingree - ND 58476",
    "county": "Stutsman County",
    "state": "ND",
    "city": "Pingree"
  },
  {
    "value": "58477",
    "label": "Regan - ND 58477",
    "county": "Burleigh County",
    "state": "ND",
    "city": "Regan"
  },
  {
    "value": "58478",
    "label": "Robinson - ND 58478",
    "county": "Kidder County",
    "state": "ND",
    "city": "Robinson"
  },
  {
    "value": "58479",
    "label": "Rogers - ND 58479",
    "county": "Barnes County",
    "state": "ND",
    "city": "Rogers"
  },
  {
    "value": "58480",
    "label": "Sanborn - ND 58480",
    "county": "Barnes County",
    "state": "ND",
    "city": "Sanborn"
  },
  {
    "value": "58481",
    "label": "Spiritwood - ND 58481",
    "county": "Barnes County",
    "state": "ND",
    "city": "Spiritwood"
  },
  {
    "value": "58482",
    "label": "Steele - ND 58482",
    "county": "Kidder County",
    "state": "ND",
    "city": "Steele"
  },
  {
    "value": "58483",
    "label": "Streeter - ND 58483",
    "county": "Stutsman County",
    "state": "ND",
    "city": "Streeter"
  },
  {
    "value": "58484",
    "label": "Sutton - ND 58484",
    "county": "Griggs County",
    "state": "ND",
    "city": "Sutton"
  },
  {
    "value": "58486",
    "label": "Sykeston - ND 58486",
    "county": "Wells County",
    "state": "ND",
    "city": "Sykeston"
  },
  {
    "value": "58487",
    "label": "Tappen - ND 58487",
    "county": "Kidder County",
    "state": "ND",
    "city": "Tappen"
  },
  {
    "value": "58488",
    "label": "Tuttle - ND 58488",
    "county": "Kidder County",
    "state": "ND",
    "city": "Tuttle"
  },
  {
    "value": "58490",
    "label": "Verona - ND 58490",
    "county": "LaMoure County",
    "state": "ND",
    "city": "Verona"
  },
  {
    "value": "58492",
    "label": "Wimbledon - ND 58492",
    "county": "Barnes County",
    "state": "ND",
    "city": "Wimbledon"
  },
  {
    "value": "58494",
    "label": "Wing - ND 58494",
    "county": "Burleigh County",
    "state": "ND",
    "city": "Wing"
  },
  {
    "value": "58495",
    "label": "Wishek - ND 58495",
    "county": "McIntosh County",
    "state": "ND",
    "city": "Wishek"
  },
  {
    "value": "58496",
    "label": "Woodworth - ND 58496",
    "county": "Stutsman County",
    "state": "ND",
    "city": "Woodworth"
  },
  {
    "value": "58497",
    "label": "Ypsilanti - ND 58497",
    "county": "Stutsman County",
    "state": "ND",
    "city": "Ypsilanti"
  },
  {
    "value": "58501",
    "label": "Bismarck - ND 58501",
    "county": "Burleigh County",
    "state": "ND",
    "city": "Bismarck"
  },
  {
    "value": "58503",
    "label": "Bismarck - ND 58503",
    "county": "Burleigh County",
    "state": "ND",
    "city": "Bismarck"
  },
  {
    "value": "58504",
    "label": "Bismarck - ND 58504",
    "county": "Burleigh County",
    "state": "ND",
    "city": "Bismarck"
  },
  {
    "value": "58505",
    "label": "Bismarck - ND 58505",
    "county": "Burleigh County",
    "state": "ND",
    "city": "Bismarck"
  },
  {
    "value": "58520",
    "label": "Almont - ND 58520",
    "county": "Morton County",
    "state": "ND",
    "city": "Almont"
  },
  {
    "value": "58521",
    "label": "Baldwin - ND 58521",
    "county": "Burleigh County",
    "state": "ND",
    "city": "Baldwin"
  },
  {
    "value": "58523",
    "label": "Beulah - ND 58523",
    "county": "Mercer County",
    "state": "ND",
    "city": "Beulah"
  },
  {
    "value": "58524",
    "label": "Braddock - ND 58524",
    "county": "Emmons County",
    "state": "ND",
    "city": "Braddock"
  },
  {
    "value": "58528",
    "label": "Cannon Ball - ND 58528",
    "county": "Sioux County",
    "state": "ND",
    "city": "Cannon Ball"
  },
  {
    "value": "58529",
    "label": "Carson - ND 58529",
    "county": "Grant County",
    "state": "ND",
    "city": "Carson"
  },
  {
    "value": "58530",
    "label": "Center - ND 58530",
    "county": "Oliver County",
    "state": "ND",
    "city": "Center"
  },
  {
    "value": "58531",
    "label": "Coleharbor - ND 58531",
    "county": "McLean County",
    "state": "ND",
    "city": "Coleharbor"
  },
  {
    "value": "58532",
    "label": "Driscoll - ND 58532",
    "county": "Burleigh County",
    "state": "ND",
    "city": "Driscoll"
  },
  {
    "value": "58533",
    "label": "Elgin - ND 58533",
    "county": "Grant County",
    "state": "ND",
    "city": "Elgin"
  },
  {
    "value": "58535",
    "label": "Flasher - ND 58535",
    "county": "Morton County",
    "state": "ND",
    "city": "Flasher"
  },
  {
    "value": "58538",
    "label": "Fort Yates - ND 58538",
    "county": "Sioux County",
    "state": "ND",
    "city": "Fort Yates"
  },
  {
    "value": "58540",
    "label": "Garrison - ND 58540",
    "county": "McLean County",
    "state": "ND",
    "city": "Garrison"
  },
  {
    "value": "58541",
    "label": "Golden Valley - ND 58541",
    "county": "Mercer County",
    "state": "ND",
    "city": "Golden Valley"
  },
  {
    "value": "58542",
    "label": "Hague - ND 58542",
    "county": "Emmons County",
    "state": "ND",
    "city": "Hague"
  },
  {
    "value": "58544",
    "label": "Hazelton - ND 58544",
    "county": "Emmons County",
    "state": "ND",
    "city": "Hazelton"
  },
  {
    "value": "58545",
    "label": "Hazen - ND 58545",
    "county": "Mercer County",
    "state": "ND",
    "city": "Hazen"
  },
  {
    "value": "58549",
    "label": "Kintyre - ND 58549",
    "county": "Emmons County",
    "state": "ND",
    "city": "Kintyre"
  },
  {
    "value": "58552",
    "label": "Linton - ND 58552",
    "county": "Emmons County",
    "state": "ND",
    "city": "Linton"
  },
  {
    "value": "58554",
    "label": "Mandan - ND 58554",
    "county": "Morton County",
    "state": "ND",
    "city": "Mandan"
  },
  {
    "value": "58558",
    "label": "Menoken - ND 58558",
    "county": "Burleigh County",
    "state": "ND",
    "city": "Menoken"
  },
  {
    "value": "58559",
    "label": "Mercer - ND 58559",
    "county": "McLean County",
    "state": "ND",
    "city": "Mercer"
  },
  {
    "value": "58560",
    "label": "Moffit - ND 58560",
    "county": "Burleigh County",
    "state": "ND",
    "city": "Moffit"
  },
  {
    "value": "58561",
    "label": "Napoleon - ND 58561",
    "county": "Logan County",
    "state": "ND",
    "city": "Napoleon"
  },
  {
    "value": "58562",
    "label": "New Leipzig - ND 58562",
    "county": "Grant County",
    "state": "ND",
    "city": "New Leipzig"
  },
  {
    "value": "58563",
    "label": "New Salem - ND 58563",
    "county": "Morton County",
    "state": "ND",
    "city": "New Salem"
  },
  {
    "value": "58564",
    "label": "Raleigh - ND 58564",
    "county": "Grant County",
    "state": "ND",
    "city": "Raleigh"
  },
  {
    "value": "58565",
    "label": "Riverdale - ND 58565",
    "county": "McLean County",
    "state": "ND",
    "city": "Riverdale"
  },
  {
    "value": "58566",
    "label": "Saint Anthony - ND 58566",
    "county": "Morton County",
    "state": "ND",
    "city": "Saint Anthony"
  },
  {
    "value": "58568",
    "label": "Selfridge - ND 58568",
    "county": "Sioux County",
    "state": "ND",
    "city": "Selfridge"
  },
  {
    "value": "58569",
    "label": "Shields - ND 58569",
    "county": "Grant County",
    "state": "ND",
    "city": "Shields"
  },
  {
    "value": "58570",
    "label": "Solen - ND 58570",
    "county": "Morton County",
    "state": "ND",
    "city": "Solen"
  },
  {
    "value": "58571",
    "label": "Stanton - ND 58571",
    "county": "Mercer County",
    "state": "ND",
    "city": "Stanton"
  },
  {
    "value": "58572",
    "label": "Sterling - ND 58572",
    "county": "Burleigh County",
    "state": "ND",
    "city": "Sterling"
  },
  {
    "value": "58573",
    "label": "Strasburg - ND 58573",
    "county": "Emmons County",
    "state": "ND",
    "city": "Strasburg"
  },
  {
    "value": "58575",
    "label": "Turtle Lake - ND 58575",
    "county": "McLean County",
    "state": "ND",
    "city": "Turtle Lake"
  },
  {
    "value": "58576",
    "label": "Underwood - ND 58576",
    "county": "McLean County",
    "state": "ND",
    "city": "Underwood"
  },
  {
    "value": "58577",
    "label": "Washburn - ND 58577",
    "county": "McLean County",
    "state": "ND",
    "city": "Washburn"
  },
  {
    "value": "58579",
    "label": "Wilton - ND 58579",
    "county": "McLean County",
    "state": "ND",
    "city": "Wilton"
  },
  {
    "value": "58580",
    "label": "Zap - ND 58580",
    "county": "Mercer County",
    "state": "ND",
    "city": "Zap"
  },
  {
    "value": "58581",
    "label": "Zeeland - ND 58581",
    "county": "McIntosh County",
    "state": "ND",
    "city": "Zeeland"
  },
  {
    "value": "58601",
    "label": "Dickinson - ND 58601",
    "county": "Stark County",
    "state": "ND",
    "city": "Dickinson"
  },
  {
    "value": "58620",
    "label": "Amidon - ND 58620",
    "county": "Slope County",
    "state": "ND",
    "city": "Amidon"
  },
  {
    "value": "58621",
    "label": "Beach - ND 58621",
    "county": "Golden Valley County",
    "state": "ND",
    "city": "Beach"
  },
  {
    "value": "58622",
    "label": "Belfield - ND 58622",
    "county": "Stark County",
    "state": "ND",
    "city": "Belfield"
  },
  {
    "value": "58623",
    "label": "Bowman - ND 58623",
    "county": "Bowman County",
    "state": "ND",
    "city": "Bowman"
  },
  {
    "value": "58625",
    "label": "Dodge - ND 58625",
    "county": "Dunn County",
    "state": "ND",
    "city": "Dodge"
  },
  {
    "value": "58626",
    "label": "Dunn Center - ND 58626",
    "county": "Dunn County",
    "state": "ND",
    "city": "Dunn Center"
  },
  {
    "value": "58627",
    "label": "Fairfield - ND 58627",
    "county": "Billings County",
    "state": "ND",
    "city": "Fairfield"
  },
  {
    "value": "58630",
    "label": "Gladstone - ND 58630",
    "county": "Stark County",
    "state": "ND",
    "city": "Gladstone"
  },
  {
    "value": "58631",
    "label": "Glen Ullin - ND 58631",
    "county": "Morton County",
    "state": "ND",
    "city": "Glen Ullin"
  },
  {
    "value": "58632",
    "label": "Golva - ND 58632",
    "county": "Golden Valley County",
    "state": "ND",
    "city": "Golva"
  },
  {
    "value": "58634",
    "label": "Grassy Butte - ND 58634",
    "county": "McKenzie County",
    "state": "ND",
    "city": "Grassy Butte"
  },
  {
    "value": "58636",
    "label": "Halliday - ND 58636",
    "county": "Dunn County",
    "state": "ND",
    "city": "Halliday"
  },
  {
    "value": "58638",
    "label": "Hebron - ND 58638",
    "county": "Morton County",
    "state": "ND",
    "city": "Hebron"
  },
  {
    "value": "58639",
    "label": "Hettinger - ND 58639",
    "county": "Adams County",
    "state": "ND",
    "city": "Hettinger"
  },
  {
    "value": "58640",
    "label": "Killdeer - ND 58640",
    "county": "Dunn County",
    "state": "ND",
    "city": "Killdeer"
  },
  {
    "value": "58641",
    "label": "Lefor - ND 58641",
    "county": "Stark County",
    "state": "ND",
    "city": "Lefor"
  },
  {
    "value": "58642",
    "label": "Manning - ND 58642",
    "county": "Dunn County",
    "state": "ND",
    "city": "Manning"
  },
  {
    "value": "58643",
    "label": "Marmarth - ND 58643",
    "county": "Slope County",
    "state": "ND",
    "city": "Marmarth"
  },
  {
    "value": "58644",
    "label": "Marshall - ND 58644",
    "county": "Dunn County",
    "state": "ND",
    "city": "Marshall"
  },
  {
    "value": "58645",
    "label": "Medora - ND 58645",
    "county": "Billings County",
    "state": "ND",
    "city": "Medora"
  },
  {
    "value": "58646",
    "label": "Mott - ND 58646",
    "county": "Hettinger County",
    "state": "ND",
    "city": "Mott"
  },
  {
    "value": "58647",
    "label": "New England - ND 58647",
    "county": "Hettinger County",
    "state": "ND",
    "city": "New England"
  },
  {
    "value": "58649",
    "label": "Reeder - ND 58649",
    "county": "Adams County",
    "state": "ND",
    "city": "Reeder"
  },
  {
    "value": "58650",
    "label": "Regent - ND 58650",
    "county": "Hettinger County",
    "state": "ND",
    "city": "Regent"
  },
  {
    "value": "58651",
    "label": "Rhame - ND 58651",
    "county": "Bowman County",
    "state": "ND",
    "city": "Rhame"
  },
  {
    "value": "58652",
    "label": "Richardton - ND 58652",
    "county": "Stark County",
    "state": "ND",
    "city": "Richardton"
  },
  {
    "value": "58653",
    "label": "Scranton - ND 58653",
    "county": "Bowman County",
    "state": "ND",
    "city": "Scranton"
  },
  {
    "value": "58654",
    "label": "Sentinel Butte - ND 58654",
    "county": "Golden Valley County",
    "state": "ND",
    "city": "Sentinel Butte"
  },
  {
    "value": "58655",
    "label": "South Heart - ND 58655",
    "county": "Stark County",
    "state": "ND",
    "city": "South Heart"
  },
  {
    "value": "58656",
    "label": "Taylor - ND 58656",
    "county": "Stark County",
    "state": "ND",
    "city": "Taylor"
  },
  {
    "value": "58701",
    "label": "Minot - ND 58701",
    "county": "Ward County",
    "state": "ND",
    "city": "Minot"
  },
  {
    "value": "58703",
    "label": "Minot - ND 58703",
    "county": "Ward County",
    "state": "ND",
    "city": "Minot"
  },
  {
    "value": "58704",
    "label": "Minot AFB - ND 58704",
    "county": "Ward County",
    "state": "ND",
    "city": "Minot AFB"
  },
  {
    "value": "58705",
    "label": "Minot AFB - ND 58705",
    "county": "Ward County",
    "state": "ND",
    "city": "Minot AFB"
  },
  {
    "value": "58710",
    "label": "Anamoose - ND 58710",
    "county": "McHenry County",
    "state": "ND",
    "city": "Anamoose"
  },
  {
    "value": "58711",
    "label": "Antler - ND 58711",
    "county": "Bottineau County",
    "state": "ND",
    "city": "Antler"
  },
  {
    "value": "58712",
    "label": "Balfour - ND 58712",
    "county": "McHenry County",
    "state": "ND",
    "city": "Balfour"
  },
  {
    "value": "58713",
    "label": "Bantry - ND 58713",
    "county": "McHenry County",
    "state": "ND",
    "city": "Bantry"
  },
  {
    "value": "58716",
    "label": "Benedict - ND 58716",
    "county": "McLean County",
    "state": "ND",
    "city": "Benedict"
  },
  {
    "value": "58718",
    "label": "Berthold - ND 58718",
    "county": "Ward County",
    "state": "ND",
    "city": "Berthold"
  },
  {
    "value": "58721",
    "label": "Bowbells - ND 58721",
    "county": "Burke County",
    "state": "ND",
    "city": "Bowbells"
  },
  {
    "value": "58722",
    "label": "Burlington - ND 58722",
    "county": "Ward County",
    "state": "ND",
    "city": "Burlington"
  },
  {
    "value": "58723",
    "label": "Butte - ND 58723",
    "county": "McLean County",
    "state": "ND",
    "city": "Butte"
  },
  {
    "value": "58725",
    "label": "Carpio - ND 58725",
    "county": "Ward County",
    "state": "ND",
    "city": "Carpio"
  },
  {
    "value": "58727",
    "label": "Columbus - ND 58727",
    "county": "Burke County",
    "state": "ND",
    "city": "Columbus"
  },
  {
    "value": "58730",
    "label": "Crosby - ND 58730",
    "county": "Divide County",
    "state": "ND",
    "city": "Crosby"
  },
  {
    "value": "58731",
    "label": "Deering - ND 58731",
    "county": "McHenry County",
    "state": "ND",
    "city": "Deering"
  },
  {
    "value": "58733",
    "label": "Des Lacs - ND 58733",
    "county": "Ward County",
    "state": "ND",
    "city": "Des Lacs"
  },
  {
    "value": "58734",
    "label": "Donnybrook - ND 58734",
    "county": "Ward County",
    "state": "ND",
    "city": "Donnybrook"
  },
  {
    "value": "58735",
    "label": "Douglas - ND 58735",
    "county": "Ward County",
    "state": "ND",
    "city": "Douglas"
  },
  {
    "value": "58736",
    "label": "Drake - ND 58736",
    "county": "McHenry County",
    "state": "ND",
    "city": "Drake"
  },
  {
    "value": "58737",
    "label": "Flaxton - ND 58737",
    "county": "Burke County",
    "state": "ND",
    "city": "Flaxton"
  },
  {
    "value": "58740",
    "label": "Glenburn - ND 58740",
    "county": "Renville County",
    "state": "ND",
    "city": "Glenburn"
  },
  {
    "value": "58741",
    "label": "Granville - ND 58741",
    "county": "McHenry County",
    "state": "ND",
    "city": "Granville"
  },
  {
    "value": "58744",
    "label": "Karlsruhe - ND 58744",
    "county": "McHenry County",
    "state": "ND",
    "city": "Karlsruhe"
  },
  {
    "value": "58746",
    "label": "Kenmare - ND 58746",
    "county": "Ward County",
    "state": "ND",
    "city": "Kenmare"
  },
  {
    "value": "58748",
    "label": "Kramer - ND 58748",
    "county": "Bottineau County",
    "state": "ND",
    "city": "Kramer"
  },
  {
    "value": "58750",
    "label": "Lansford - ND 58750",
    "county": "Bottineau County",
    "state": "ND",
    "city": "Lansford"
  },
  {
    "value": "58752",
    "label": "Lignite - ND 58752",
    "county": "Burke County",
    "state": "ND",
    "city": "Lignite"
  },
  {
    "value": "58755",
    "label": "Mcgregor - ND 58755",
    "county": "Williams County",
    "state": "ND",
    "city": "Mcgregor"
  },
  {
    "value": "58756",
    "label": "Makoti - ND 58756",
    "county": "Ward County",
    "state": "ND",
    "city": "Makoti"
  },
  {
    "value": "58757",
    "label": "Mandaree - ND 58757",
    "county": "Dunn County",
    "state": "ND",
    "city": "Mandaree"
  },
  {
    "value": "58758",
    "label": "Martin - ND 58758",
    "county": "Sheridan County",
    "state": "ND",
    "city": "Martin"
  },
  {
    "value": "58759",
    "label": "Max - ND 58759",
    "county": "McLean County",
    "state": "ND",
    "city": "Max"
  },
  {
    "value": "58760",
    "label": "Maxbass - ND 58760",
    "county": "Bottineau County",
    "state": "ND",
    "city": "Maxbass"
  },
  {
    "value": "58761",
    "label": "Mohall - ND 58761",
    "county": "Renville County",
    "state": "ND",
    "city": "Mohall"
  },
  {
    "value": "58762",
    "label": "Newburg - ND 58762",
    "county": "Bottineau County",
    "state": "ND",
    "city": "Newburg"
  },
  {
    "value": "58763",
    "label": "New Town - ND 58763",
    "county": "Mountrail County",
    "state": "ND",
    "city": "New Town"
  },
  {
    "value": "58765",
    "label": "Noonan - ND 58765",
    "county": "Divide County",
    "state": "ND",
    "city": "Noonan"
  },
  {
    "value": "58768",
    "label": "Norwich - ND 58768",
    "county": "McHenry County",
    "state": "ND",
    "city": "Norwich"
  },
  {
    "value": "58769",
    "label": "Palermo - ND 58769",
    "county": "Mountrail County",
    "state": "ND",
    "city": "Palermo"
  },
  {
    "value": "58770",
    "label": "Parshall - ND 58770",
    "county": "Mountrail County",
    "state": "ND",
    "city": "Parshall"
  },
  {
    "value": "58771",
    "label": "Plaza - ND 58771",
    "county": "Mountrail County",
    "state": "ND",
    "city": "Plaza"
  },
  {
    "value": "58772",
    "label": "Portal - ND 58772",
    "county": "Burke County",
    "state": "ND",
    "city": "Portal"
  },
  {
    "value": "58773",
    "label": "Powers Lake - ND 58773",
    "county": "Burke County",
    "state": "ND",
    "city": "Powers Lake"
  },
  {
    "value": "58775",
    "label": "Roseglen - ND 58775",
    "county": "McLean County",
    "state": "ND",
    "city": "Roseglen"
  },
  {
    "value": "58776",
    "label": "Ross - ND 58776",
    "county": "Mountrail County",
    "state": "ND",
    "city": "Ross"
  },
  {
    "value": "58778",
    "label": "Ruso - ND 58778",
    "county": "McLean County",
    "state": "ND",
    "city": "Ruso"
  },
  {
    "value": "58779",
    "label": "Ryder - ND 58779",
    "county": "Ward County",
    "state": "ND",
    "city": "Ryder"
  },
  {
    "value": "58781",
    "label": "Sawyer - ND 58781",
    "county": "Ward County",
    "state": "ND",
    "city": "Sawyer"
  },
  {
    "value": "58782",
    "label": "Sherwood - ND 58782",
    "county": "Renville County",
    "state": "ND",
    "city": "Sherwood"
  },
  {
    "value": "58783",
    "label": "Souris - ND 58783",
    "county": "Bottineau County",
    "state": "ND",
    "city": "Souris"
  },
  {
    "value": "58784",
    "label": "Stanley - ND 58784",
    "county": "Mountrail County",
    "state": "ND",
    "city": "Stanley"
  },
  {
    "value": "58785",
    "label": "Surrey - ND 58785",
    "county": "Ward County",
    "state": "ND",
    "city": "Surrey"
  },
  {
    "value": "58787",
    "label": "Tolley - ND 58787",
    "county": "Renville County",
    "state": "ND",
    "city": "Tolley"
  },
  {
    "value": "58788",
    "label": "Towner - ND 58788",
    "county": "McHenry County",
    "state": "ND",
    "city": "Towner"
  },
  {
    "value": "58789",
    "label": "Upham - ND 58789",
    "county": "McHenry County",
    "state": "ND",
    "city": "Upham"
  },
  {
    "value": "58790",
    "label": "Velva - ND 58790",
    "county": "McHenry County",
    "state": "ND",
    "city": "Velva"
  },
  {
    "value": "58792",
    "label": "Voltaire - ND 58792",
    "county": "McHenry County",
    "state": "ND",
    "city": "Voltaire"
  },
  {
    "value": "58793",
    "label": "Westhope - ND 58793",
    "county": "Bottineau County",
    "state": "ND",
    "city": "Westhope"
  },
  {
    "value": "58794",
    "label": "White Earth - ND 58794",
    "county": "Mountrail County",
    "state": "ND",
    "city": "White Earth"
  },
  {
    "value": "58795",
    "label": "Wildrose - ND 58795",
    "county": "Williams County",
    "state": "ND",
    "city": "Wildrose"
  },
  {
    "value": "58801",
    "label": "Williston - ND 58801",
    "county": "Williams County",
    "state": "ND",
    "city": "Williston"
  },
  {
    "value": "58830",
    "label": "Alamo - ND 58830",
    "county": "Williams County",
    "state": "ND",
    "city": "Alamo"
  },
  {
    "value": "58831",
    "label": "Alexander - ND 58831",
    "county": "McKenzie County",
    "state": "ND",
    "city": "Alexander"
  },
  {
    "value": "58833",
    "label": "Ambrose - ND 58833",
    "county": "Divide County",
    "state": "ND",
    "city": "Ambrose"
  },
  {
    "value": "58835",
    "label": "Arnegard - ND 58835",
    "county": "McKenzie County",
    "state": "ND",
    "city": "Arnegard"
  },
  {
    "value": "58838",
    "label": "Cartwright - ND 58838",
    "county": "McKenzie County",
    "state": "ND",
    "city": "Cartwright"
  },
  {
    "value": "58843",
    "label": "Epping - ND 58843",
    "county": "Williams County",
    "state": "ND",
    "city": "Epping"
  },
  {
    "value": "58844",
    "label": "Fortuna - ND 58844",
    "county": "Divide County",
    "state": "ND",
    "city": "Fortuna"
  },
  {
    "value": "58845",
    "label": "Grenora - ND 58845",
    "county": "Williams County",
    "state": "ND",
    "city": "Grenora"
  },
  {
    "value": "58847",
    "label": "Keene - ND 58847",
    "county": "McKenzie County",
    "state": "ND",
    "city": "Keene"
  },
  {
    "value": "58849",
    "label": "Ray - ND 58849",
    "county": "Williams County",
    "state": "ND",
    "city": "Ray"
  },
  {
    "value": "58852",
    "label": "Tioga - ND 58852",
    "county": "Williams County",
    "state": "ND",
    "city": "Tioga"
  },
  {
    "value": "58854",
    "label": "Watford City - ND 58854",
    "county": "McKenzie County",
    "state": "ND",
    "city": "Watford City"
  },
  {
    "value": "58856",
    "label": "Zahl - ND 58856",
    "county": "Williams County",
    "state": "ND",
    "city": "Zahl"
  },
  {
    "value": "43001",
    "label": "Alexandria - OH 43001",
    "county": "Licking County",
    "state": "OH",
    "city": "Alexandria"
  },
  {
    "value": "43003",
    "label": "Ashley - OH 43003",
    "county": "Delaware County",
    "state": "OH",
    "city": "Ashley"
  },
  {
    "value": "43004",
    "label": "Blacklick - OH 43004",
    "county": "Franklin County",
    "state": "OH",
    "city": "Blacklick"
  },
  {
    "value": "43006",
    "label": "Brinkhaven - OH 43006",
    "county": "Holmes County",
    "state": "OH",
    "city": "Brinkhaven"
  },
  {
    "value": "43009",
    "label": "Cable - OH 43009",
    "county": "Champaign County",
    "state": "OH",
    "city": "Cable"
  },
  {
    "value": "43011",
    "label": "Centerburg - OH 43011",
    "county": "Knox County",
    "state": "OH",
    "city": "Centerburg"
  },
  {
    "value": "43013",
    "label": "Croton - OH 43013",
    "county": "Licking County",
    "state": "OH",
    "city": "Croton"
  },
  {
    "value": "43014",
    "label": "Danville - OH 43014",
    "county": "Knox County",
    "state": "OH",
    "city": "Danville"
  },
  {
    "value": "43015",
    "label": "Delaware - OH 43015",
    "county": "Delaware County",
    "state": "OH",
    "city": "Delaware"
  },
  {
    "value": "43016",
    "label": "Dublin - OH 43016",
    "county": "Franklin County",
    "state": "OH",
    "city": "Dublin"
  },
  {
    "value": "43017",
    "label": "Dublin - OH 43017",
    "county": "Franklin County",
    "state": "OH",
    "city": "Dublin"
  },
  {
    "value": "43019",
    "label": "Fredericktown - OH 43019",
    "county": "Knox County",
    "state": "OH",
    "city": "Fredericktown"
  },
  {
    "value": "43021",
    "label": "Galena - OH 43021",
    "county": "Delaware County",
    "state": "OH",
    "city": "Galena"
  },
  {
    "value": "43022",
    "label": "Gambier - OH 43022",
    "county": "Knox County",
    "state": "OH",
    "city": "Gambier"
  },
  {
    "value": "43023",
    "label": "Granville - OH 43023",
    "county": "Licking County",
    "state": "OH",
    "city": "Granville"
  },
  {
    "value": "43025",
    "label": "Hebron - OH 43025",
    "county": "Licking County",
    "state": "OH",
    "city": "Hebron"
  },
  {
    "value": "43026",
    "label": "Hilliard - OH 43026",
    "county": "Franklin County",
    "state": "OH",
    "city": "Hilliard"
  },
  {
    "value": "43028",
    "label": "Howard - OH 43028",
    "county": "Knox County",
    "state": "OH",
    "city": "Howard"
  },
  {
    "value": "43029",
    "label": "Irwin - OH 43029",
    "county": "Madison County",
    "state": "OH",
    "city": "Irwin"
  },
  {
    "value": "43031",
    "label": "Johnstown - OH 43031",
    "county": "Licking County",
    "state": "OH",
    "city": "Johnstown"
  },
  {
    "value": "43035",
    "label": "Lewis Center - OH 43035",
    "county": "Delaware County",
    "state": "OH",
    "city": "Lewis Center"
  },
  {
    "value": "43037",
    "label": "Martinsburg - OH 43037",
    "county": "Knox County",
    "state": "OH",
    "city": "Martinsburg"
  },
  {
    "value": "43040",
    "label": "Marysville - OH 43040",
    "county": "Union County",
    "state": "OH",
    "city": "Marysville"
  },
  {
    "value": "43044",
    "label": "Mechanicsburg - OH 43044",
    "county": "Champaign County",
    "state": "OH",
    "city": "Mechanicsburg"
  },
  {
    "value": "43045",
    "label": "Milford Center - OH 43045",
    "county": "Union County",
    "state": "OH",
    "city": "Milford Center"
  },
  {
    "value": "43046",
    "label": "Millersport - OH 43046",
    "county": "Fairfield County",
    "state": "OH",
    "city": "Millersport"
  },
  {
    "value": "43050",
    "label": "Mount Vernon - OH 43050",
    "county": "Knox County",
    "state": "OH",
    "city": "Mount Vernon"
  },
  {
    "value": "43054",
    "label": "New Albany - OH 43054",
    "county": "Franklin County",
    "state": "OH",
    "city": "New Albany"
  },
  {
    "value": "43055",
    "label": "Newark - OH 43055",
    "county": "Licking County",
    "state": "OH",
    "city": "Newark"
  },
  {
    "value": "43056",
    "label": "Heath - OH 43056",
    "county": "Licking County",
    "state": "OH",
    "city": "Heath"
  },
  {
    "value": "43060",
    "label": "North Lewisburg - OH 43060",
    "county": "Champaign County",
    "state": "OH",
    "city": "North Lewisburg"
  },
  {
    "value": "43061",
    "label": "Ostrander - OH 43061",
    "county": "Delaware County",
    "state": "OH",
    "city": "Ostrander"
  },
  {
    "value": "43062",
    "label": "Pataskala - OH 43062",
    "county": "Licking County",
    "state": "OH",
    "city": "Pataskala"
  },
  {
    "value": "43064",
    "label": "Plain City - OH 43064",
    "county": "Madison County",
    "state": "OH",
    "city": "Plain City"
  },
  {
    "value": "43065",
    "label": "Powell - OH 43065",
    "county": "Delaware County",
    "state": "OH",
    "city": "Powell"
  },
  {
    "value": "43066",
    "label": "Radnor - OH 43066",
    "county": "Delaware County",
    "state": "OH",
    "city": "Radnor"
  },
  {
    "value": "43067",
    "label": "Raymond - OH 43067",
    "county": "Union County",
    "state": "OH",
    "city": "Raymond"
  },
  {
    "value": "43068",
    "label": "Reynoldsburg - OH 43068",
    "county": "Franklin County",
    "state": "OH",
    "city": "Reynoldsburg"
  },
  {
    "value": "43071",
    "label": "Saint Louisville - OH 43071",
    "county": "Licking County",
    "state": "OH",
    "city": "Saint Louisville"
  },
  {
    "value": "43072",
    "label": "Saint Paris - OH 43072",
    "county": "Champaign County",
    "state": "OH",
    "city": "Saint Paris"
  },
  {
    "value": "43074",
    "label": "Sunbury - OH 43074",
    "county": "Delaware County",
    "state": "OH",
    "city": "Sunbury"
  },
  {
    "value": "43076",
    "label": "Thornville - OH 43076",
    "county": "Perry County",
    "state": "OH",
    "city": "Thornville"
  },
  {
    "value": "43078",
    "label": "Urbana - OH 43078",
    "county": "Champaign County",
    "state": "OH",
    "city": "Urbana"
  },
  {
    "value": "43080",
    "label": "Utica - OH 43080",
    "county": "Licking County",
    "state": "OH",
    "city": "Utica"
  },
  {
    "value": "43081",
    "label": "Westerville - OH 43081",
    "county": "Franklin County",
    "state": "OH",
    "city": "Westerville"
  },
  {
    "value": "43082",
    "label": "Westerville - OH 43082",
    "county": "Delaware County",
    "state": "OH",
    "city": "Westerville"
  },
  {
    "value": "43084",
    "label": "Woodstock - OH 43084",
    "county": "Champaign County",
    "state": "OH",
    "city": "Woodstock"
  },
  {
    "value": "43085",
    "label": "Columbus - OH 43085",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43102",
    "label": "Amanda - OH 43102",
    "county": "Fairfield County",
    "state": "OH",
    "city": "Amanda"
  },
  {
    "value": "43103",
    "label": "Ashville - OH 43103",
    "county": "Pickaway County",
    "state": "OH",
    "city": "Ashville"
  },
  {
    "value": "43105",
    "label": "Baltimore - OH 43105",
    "county": "Fairfield County",
    "state": "OH",
    "city": "Baltimore"
  },
  {
    "value": "43106",
    "label": "Bloomingburg - OH 43106",
    "county": "Fayette County",
    "state": "OH",
    "city": "Bloomingburg"
  },
  {
    "value": "43107",
    "label": "Bremen - OH 43107",
    "county": "Fairfield County",
    "state": "OH",
    "city": "Bremen"
  },
  {
    "value": "43110",
    "label": "Canal Winchester - OH 43110",
    "county": "Franklin County",
    "state": "OH",
    "city": "Canal Winchester"
  },
  {
    "value": "43112",
    "label": "Carroll - OH 43112",
    "county": "Fairfield County",
    "state": "OH",
    "city": "Carroll"
  },
  {
    "value": "43113",
    "label": "Circleville - OH 43113",
    "county": "Pickaway County",
    "state": "OH",
    "city": "Circleville"
  },
  {
    "value": "43115",
    "label": "Clarksburg - OH 43115",
    "county": "Ross County",
    "state": "OH",
    "city": "Clarksburg"
  },
  {
    "value": "43116",
    "label": "Commercial Point - OH 43116",
    "county": "Pickaway County",
    "state": "OH",
    "city": "Commercial Point"
  },
  {
    "value": "43119",
    "label": "Galloway - OH 43119",
    "county": "Franklin County",
    "state": "OH",
    "city": "Galloway"
  },
  {
    "value": "43123",
    "label": "Grove City - OH 43123",
    "county": "Franklin County",
    "state": "OH",
    "city": "Grove City"
  },
  {
    "value": "43125",
    "label": "Groveport - OH 43125",
    "county": "Franklin County",
    "state": "OH",
    "city": "Groveport"
  },
  {
    "value": "43128",
    "label": "Jeffersonville - OH 43128",
    "county": "Fayette County",
    "state": "OH",
    "city": "Jeffersonville"
  },
  {
    "value": "43130",
    "label": "Lancaster - OH 43130",
    "county": "Fairfield County",
    "state": "OH",
    "city": "Lancaster"
  },
  {
    "value": "43135",
    "label": "Laurelville - OH 43135",
    "county": "Hocking County",
    "state": "OH",
    "city": "Laurelville"
  },
  {
    "value": "43136",
    "label": "Lithopolis - OH 43136",
    "county": "Fairfield County",
    "state": "OH",
    "city": "Lithopolis"
  },
  {
    "value": "43137",
    "label": "Lockbourne - OH 43137",
    "county": "Pickaway County",
    "state": "OH",
    "city": "Lockbourne"
  },
  {
    "value": "43138",
    "label": "Logan - OH 43138",
    "county": "Hocking County",
    "state": "OH",
    "city": "Logan"
  },
  {
    "value": "43140",
    "label": "London - OH 43140",
    "county": "Madison County",
    "state": "OH",
    "city": "London"
  },
  {
    "value": "43143",
    "label": "Mount Sterling - OH 43143",
    "county": "Madison County",
    "state": "OH",
    "city": "Mount Sterling"
  },
  {
    "value": "43145",
    "label": "New Holland - OH 43145",
    "county": "Pickaway County",
    "state": "OH",
    "city": "New Holland"
  },
  {
    "value": "43146",
    "label": "Orient - OH 43146",
    "county": "Pickaway County",
    "state": "OH",
    "city": "Orient"
  },
  {
    "value": "43147",
    "label": "Pickerington - OH 43147",
    "county": "Fairfield County",
    "state": "OH",
    "city": "Pickerington"
  },
  {
    "value": "43148",
    "label": "Pleasantville - OH 43148",
    "county": "Fairfield County",
    "state": "OH",
    "city": "Pleasantville"
  },
  {
    "value": "43149",
    "label": "Rockbridge - OH 43149",
    "county": "Hocking County",
    "state": "OH",
    "city": "Rockbridge"
  },
  {
    "value": "43150",
    "label": "Rushville - OH 43150",
    "county": "Fairfield County",
    "state": "OH",
    "city": "Rushville"
  },
  {
    "value": "43152",
    "label": "South Bloomingville - OH 43152",
    "county": "Hocking County",
    "state": "OH",
    "city": "South Bloomingville"
  },
  {
    "value": "43153",
    "label": "South Solon - OH 43153",
    "county": "Madison County",
    "state": "OH",
    "city": "South Solon"
  },
  {
    "value": "43154",
    "label": "Stoutsville - OH 43154",
    "county": "Fairfield County",
    "state": "OH",
    "city": "Stoutsville"
  },
  {
    "value": "43155",
    "label": "Sugar Grove - OH 43155",
    "county": "Fairfield County",
    "state": "OH",
    "city": "Sugar Grove"
  },
  {
    "value": "43160",
    "label": "Washington Court House - OH 43160",
    "county": "Fayette County",
    "state": "OH",
    "city": "Washington Court House"
  },
  {
    "value": "43162",
    "label": "West Jefferson - OH 43162",
    "county": "Madison County",
    "state": "OH",
    "city": "West Jefferson"
  },
  {
    "value": "43163",
    "label": "West Rushville - OH 43163",
    "county": "Fairfield County",
    "state": "OH",
    "city": "West Rushville"
  },
  {
    "value": "43164",
    "label": "Williamsport - OH 43164",
    "county": "Pickaway County",
    "state": "OH",
    "city": "Williamsport"
  },
  {
    "value": "43201",
    "label": "Columbus - OH 43201",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43202",
    "label": "Columbus - OH 43202",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43203",
    "label": "Columbus - OH 43203",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43204",
    "label": "Columbus - OH 43204",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43205",
    "label": "Columbus - OH 43205",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43206",
    "label": "Columbus - OH 43206",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43207",
    "label": "Columbus - OH 43207",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43209",
    "label": "Columbus - OH 43209",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43210",
    "label": "Columbus - OH 43210",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43211",
    "label": "Columbus - OH 43211",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43212",
    "label": "Columbus - OH 43212",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43213",
    "label": "Columbus - OH 43213",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43214",
    "label": "Columbus - OH 43214",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43215",
    "label": "Columbus - OH 43215",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43217",
    "label": "Columbus - OH 43217",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43219",
    "label": "Columbus - OH 43219",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43220",
    "label": "Columbus - OH 43220",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43221",
    "label": "Columbus - OH 43221",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43222",
    "label": "Columbus - OH 43222",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43223",
    "label": "Columbus - OH 43223",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43224",
    "label": "Columbus - OH 43224",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43227",
    "label": "Columbus - OH 43227",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43228",
    "label": "Columbus - OH 43228",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43229",
    "label": "Columbus - OH 43229",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43230",
    "label": "Columbus - OH 43230",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43231",
    "label": "Columbus - OH 43231",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43232",
    "label": "Columbus - OH 43232",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43235",
    "label": "Columbus - OH 43235",
    "county": "Franklin County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43240",
    "label": "Columbus - OH 43240",
    "county": "Delaware County",
    "state": "OH",
    "city": "Columbus"
  },
  {
    "value": "43302",
    "label": "Marion - OH 43302",
    "county": "Marion County",
    "state": "OH",
    "city": "Marion"
  },
  {
    "value": "43310",
    "label": "Belle Center - OH 43310",
    "county": "Logan County",
    "state": "OH",
    "city": "Belle Center"
  },
  {
    "value": "43311",
    "label": "Bellefontaine - OH 43311",
    "county": "Logan County",
    "state": "OH",
    "city": "Bellefontaine"
  },
  {
    "value": "43314",
    "label": "Caledonia - OH 43314",
    "county": "Marion County",
    "state": "OH",
    "city": "Caledonia"
  },
  {
    "value": "43315",
    "label": "Cardington - OH 43315",
    "county": "Morrow County",
    "state": "OH",
    "city": "Cardington"
  },
  {
    "value": "43316",
    "label": "Carey - OH 43316",
    "county": "Wyandot County",
    "state": "OH",
    "city": "Carey"
  },
  {
    "value": "43318",
    "label": "De Graff - OH 43318",
    "county": "Logan County",
    "state": "OH",
    "city": "De Graff"
  },
  {
    "value": "43319",
    "label": "East Liberty - OH 43319",
    "county": "Logan County",
    "state": "OH",
    "city": "East Liberty"
  },
  {
    "value": "43320",
    "label": "Edison - OH 43320",
    "county": "Morrow County",
    "state": "OH",
    "city": "Edison"
  },
  {
    "value": "43321",
    "label": "Fulton - OH 43321",
    "county": "Morrow County",
    "state": "OH",
    "city": "Fulton"
  },
  {
    "value": "43323",
    "label": "Harpster - OH 43323",
    "county": "Wyandot County",
    "state": "OH",
    "city": "Harpster"
  },
  {
    "value": "43324",
    "label": "Huntsville - OH 43324",
    "county": "Logan County",
    "state": "OH",
    "city": "Huntsville"
  },
  {
    "value": "43326",
    "label": "Kenton - OH 43326",
    "county": "Hardin County",
    "state": "OH",
    "city": "Kenton"
  },
  {
    "value": "43331",
    "label": "Lakeview - OH 43331",
    "county": "Logan County",
    "state": "OH",
    "city": "Lakeview"
  },
  {
    "value": "43332",
    "label": "La Rue - OH 43332",
    "county": "Marion County",
    "state": "OH",
    "city": "La Rue"
  },
  {
    "value": "43333",
    "label": "Lewistown - OH 43333",
    "county": "Logan County",
    "state": "OH",
    "city": "Lewistown"
  },
  {
    "value": "43334",
    "label": "Marengo - OH 43334",
    "county": "Morrow County",
    "state": "OH",
    "city": "Marengo"
  },
  {
    "value": "43337",
    "label": "Morral - OH 43337",
    "county": "Marion County",
    "state": "OH",
    "city": "Morral"
  },
  {
    "value": "43338",
    "label": "Mount Gilead - OH 43338",
    "county": "Morrow County",
    "state": "OH",
    "city": "Mount Gilead"
  },
  {
    "value": "43340",
    "label": "Mount Victory - OH 43340",
    "county": "Hardin County",
    "state": "OH",
    "city": "Mount Victory"
  },
  {
    "value": "43341",
    "label": "New Bloomington - OH 43341",
    "county": "Marion County",
    "state": "OH",
    "city": "New Bloomington"
  },
  {
    "value": "43342",
    "label": "Prospect - OH 43342",
    "county": "Marion County",
    "state": "OH",
    "city": "Prospect"
  },
  {
    "value": "43343",
    "label": "Quincy - OH 43343",
    "county": "Logan County",
    "state": "OH",
    "city": "Quincy"
  },
  {
    "value": "43344",
    "label": "Richwood - OH 43344",
    "county": "Union County",
    "state": "OH",
    "city": "Richwood"
  },
  {
    "value": "43345",
    "label": "Ridgeway - OH 43345",
    "county": "Hardin County",
    "state": "OH",
    "city": "Ridgeway"
  },
  {
    "value": "43347",
    "label": "Rushsylvania - OH 43347",
    "county": "Logan County",
    "state": "OH",
    "city": "Rushsylvania"
  },
  {
    "value": "43348",
    "label": "Russells Point - OH 43348",
    "county": "Logan County",
    "state": "OH",
    "city": "Russells Point"
  },
  {
    "value": "43351",
    "label": "Upper Sandusky - OH 43351",
    "county": "Wyandot County",
    "state": "OH",
    "city": "Upper Sandusky"
  },
  {
    "value": "43356",
    "label": "Waldo - OH 43356",
    "county": "Marion County",
    "state": "OH",
    "city": "Waldo"
  },
  {
    "value": "43357",
    "label": "West Liberty - OH 43357",
    "county": "Logan County",
    "state": "OH",
    "city": "West Liberty"
  },
  {
    "value": "43358",
    "label": "West Mansfield - OH 43358",
    "county": "Logan County",
    "state": "OH",
    "city": "West Mansfield"
  },
  {
    "value": "43359",
    "label": "Wharton - OH 43359",
    "county": "Wyandot County",
    "state": "OH",
    "city": "Wharton"
  },
  {
    "value": "43360",
    "label": "Zanesfield - OH 43360",
    "county": "Logan County",
    "state": "OH",
    "city": "Zanesfield"
  },
  {
    "value": "43402",
    "label": "Bowling Green - OH 43402",
    "county": "Wood County",
    "state": "OH",
    "city": "Bowling Green"
  },
  {
    "value": "43406",
    "label": "Bradner - OH 43406",
    "county": "Wood County",
    "state": "OH",
    "city": "Bradner"
  },
  {
    "value": "43407",
    "label": "Burgoon - OH 43407",
    "county": "Sandusky County",
    "state": "OH",
    "city": "Burgoon"
  },
  {
    "value": "43410",
    "label": "Clyde - OH 43410",
    "county": "Sandusky County",
    "state": "OH",
    "city": "Clyde"
  },
  {
    "value": "43412",
    "label": "Curtice - OH 43412",
    "county": "Lucas County",
    "state": "OH",
    "city": "Curtice"
  },
  {
    "value": "43413",
    "label": "Cygnet - OH 43413",
    "county": "Wood County",
    "state": "OH",
    "city": "Cygnet"
  },
  {
    "value": "43416",
    "label": "Elmore - OH 43416",
    "county": "Ottawa County",
    "state": "OH",
    "city": "Elmore"
  },
  {
    "value": "43420",
    "label": "Fremont - OH 43420",
    "county": "Sandusky County",
    "state": "OH",
    "city": "Fremont"
  },
  {
    "value": "43430",
    "label": "Genoa - OH 43430",
    "county": "Ottawa County",
    "state": "OH",
    "city": "Genoa"
  },
  {
    "value": "43431",
    "label": "Gibsonburg - OH 43431",
    "county": "Sandusky County",
    "state": "OH",
    "city": "Gibsonburg"
  },
  {
    "value": "43432",
    "label": "Graytown - OH 43432",
    "county": "Ottawa County",
    "state": "OH",
    "city": "Graytown"
  },
  {
    "value": "43435",
    "label": "Helena - OH 43435",
    "county": "Sandusky County",
    "state": "OH",
    "city": "Helena"
  },
  {
    "value": "43436",
    "label": "Isle Saint George - OH 43436",
    "county": "Ottawa County",
    "state": "OH",
    "city": "Isle Saint George"
  },
  {
    "value": "43440",
    "label": "Lakeside Marblehead - OH 43440",
    "county": "Ottawa County",
    "state": "OH",
    "city": "Lakeside Marblehead"
  },
  {
    "value": "43442",
    "label": "Lindsey - OH 43442",
    "county": "Sandusky County",
    "state": "OH",
    "city": "Lindsey"
  },
  {
    "value": "43443",
    "label": "Luckey - OH 43443",
    "county": "Wood County",
    "state": "OH",
    "city": "Luckey"
  },
  {
    "value": "43445",
    "label": "Martin - OH 43445",
    "county": "Ottawa County",
    "state": "OH",
    "city": "Martin"
  },
  {
    "value": "43447",
    "label": "Millbury - OH 43447",
    "county": "Wood County",
    "state": "OH",
    "city": "Millbury"
  },
  {
    "value": "43449",
    "label": "Oak Harbor - OH 43449",
    "county": "Ottawa County",
    "state": "OH",
    "city": "Oak Harbor"
  },
  {
    "value": "43450",
    "label": "Pemberville - OH 43450",
    "county": "Wood County",
    "state": "OH",
    "city": "Pemberville"
  },
  {
    "value": "43451",
    "label": "Portage - OH 43451",
    "county": "Wood County",
    "state": "OH",
    "city": "Portage"
  },
  {
    "value": "43452",
    "label": "Port Clinton - OH 43452",
    "county": "Ottawa County",
    "state": "OH",
    "city": "Port Clinton"
  },
  {
    "value": "43457",
    "label": "Risingsun - OH 43457",
    "county": "Wood County",
    "state": "OH",
    "city": "Risingsun"
  },
  {
    "value": "43460",
    "label": "Rossford - OH 43460",
    "county": "Wood County",
    "state": "OH",
    "city": "Rossford"
  },
  {
    "value": "43462",
    "label": "Rudolph - OH 43462",
    "county": "Wood County",
    "state": "OH",
    "city": "Rudolph"
  },
  {
    "value": "43464",
    "label": "Vickery - OH 43464",
    "county": "Sandusky County",
    "state": "OH",
    "city": "Vickery"
  },
  {
    "value": "43465",
    "label": "Walbridge - OH 43465",
    "county": "Wood County",
    "state": "OH",
    "city": "Walbridge"
  },
  {
    "value": "43466",
    "label": "Wayne - OH 43466",
    "county": "Wood County",
    "state": "OH",
    "city": "Wayne"
  },
  {
    "value": "43469",
    "label": "Woodville - OH 43469",
    "county": "Sandusky County",
    "state": "OH",
    "city": "Woodville"
  },
  {
    "value": "43501",
    "label": "Alvordton - OH 43501",
    "county": "Williams County",
    "state": "OH",
    "city": "Alvordton"
  },
  {
    "value": "43502",
    "label": "Archbold - OH 43502",
    "county": "Fulton County",
    "state": "OH",
    "city": "Archbold"
  },
  {
    "value": "43504",
    "label": "Berkey - OH 43504",
    "county": "Lucas County",
    "state": "OH",
    "city": "Berkey"
  },
  {
    "value": "43506",
    "label": "Bryan - OH 43506",
    "county": "Williams County",
    "state": "OH",
    "city": "Bryan"
  },
  {
    "value": "43511",
    "label": "Custar - OH 43511",
    "county": "Wood County",
    "state": "OH",
    "city": "Custar"
  },
  {
    "value": "43512",
    "label": "Defiance - OH 43512",
    "county": "Defiance County",
    "state": "OH",
    "city": "Defiance"
  },
  {
    "value": "43515",
    "label": "Delta - OH 43515",
    "county": "Fulton County",
    "state": "OH",
    "city": "Delta"
  },
  {
    "value": "43516",
    "label": "Deshler - OH 43516",
    "county": "Henry County",
    "state": "OH",
    "city": "Deshler"
  },
  {
    "value": "43517",
    "label": "Edgerton - OH 43517",
    "county": "Williams County",
    "state": "OH",
    "city": "Edgerton"
  },
  {
    "value": "43518",
    "label": "Edon - OH 43518",
    "county": "Williams County",
    "state": "OH",
    "city": "Edon"
  },
  {
    "value": "43521",
    "label": "Fayette - OH 43521",
    "county": "Fulton County",
    "state": "OH",
    "city": "Fayette"
  },
  {
    "value": "43522",
    "label": "Grand Rapids - OH 43522",
    "county": "Wood County",
    "state": "OH",
    "city": "Grand Rapids"
  },
  {
    "value": "43524",
    "label": "Hamler - OH 43524",
    "county": "Henry County",
    "state": "OH",
    "city": "Hamler"
  },
  {
    "value": "43525",
    "label": "Haskins - OH 43525",
    "county": "Wood County",
    "state": "OH",
    "city": "Haskins"
  },
  {
    "value": "43526",
    "label": "Hicksville - OH 43526",
    "county": "Defiance County",
    "state": "OH",
    "city": "Hicksville"
  },
  {
    "value": "43527",
    "label": "Holgate - OH 43527",
    "county": "Henry County",
    "state": "OH",
    "city": "Holgate"
  },
  {
    "value": "43528",
    "label": "Holland - OH 43528",
    "county": "Lucas County",
    "state": "OH",
    "city": "Holland"
  },
  {
    "value": "43532",
    "label": "Liberty Center - OH 43532",
    "county": "Henry County",
    "state": "OH",
    "city": "Liberty Center"
  },
  {
    "value": "43533",
    "label": "Lyons - OH 43533",
    "county": "Fulton County",
    "state": "OH",
    "city": "Lyons"
  },
  {
    "value": "43534",
    "label": "Mc Clure - OH 43534",
    "county": "Henry County",
    "state": "OH",
    "city": "Mc Clure"
  },
  {
    "value": "43535",
    "label": "Malinta - OH 43535",
    "county": "Henry County",
    "state": "OH",
    "city": "Malinta"
  },
  {
    "value": "43536",
    "label": "Mark Center - OH 43536",
    "county": "Defiance County",
    "state": "OH",
    "city": "Mark Center"
  },
  {
    "value": "43537",
    "label": "Maumee - OH 43537",
    "county": "Lucas County",
    "state": "OH",
    "city": "Maumee"
  },
  {
    "value": "43540",
    "label": "Metamora - OH 43540",
    "county": "Fulton County",
    "state": "OH",
    "city": "Metamora"
  },
  {
    "value": "43541",
    "label": "Milton Center - OH 43541",
    "county": "Wood County",
    "state": "OH",
    "city": "Milton Center"
  },
  {
    "value": "43542",
    "label": "Monclova - OH 43542",
    "county": "Lucas County",
    "state": "OH",
    "city": "Monclova"
  },
  {
    "value": "43543",
    "label": "Montpelier - OH 43543",
    "county": "Williams County",
    "state": "OH",
    "city": "Montpelier"
  },
  {
    "value": "43545",
    "label": "Napoleon - OH 43545",
    "county": "Henry County",
    "state": "OH",
    "city": "Napoleon"
  },
  {
    "value": "43548",
    "label": "New Bavaria - OH 43548",
    "county": "Henry County",
    "state": "OH",
    "city": "New Bavaria"
  },
  {
    "value": "43549",
    "label": "Ney - OH 43549",
    "county": "Defiance County",
    "state": "OH",
    "city": "Ney"
  },
  {
    "value": "43551",
    "label": "Perrysburg - OH 43551",
    "county": "Wood County",
    "state": "OH",
    "city": "Perrysburg"
  },
  {
    "value": "43554",
    "label": "Pioneer - OH 43554",
    "county": "Williams County",
    "state": "OH",
    "city": "Pioneer"
  },
  {
    "value": "43556",
    "label": "Sherwood - OH 43556",
    "county": "Defiance County",
    "state": "OH",
    "city": "Sherwood"
  },
  {
    "value": "43557",
    "label": "Stryker - OH 43557",
    "county": "Williams County",
    "state": "OH",
    "city": "Stryker"
  },
  {
    "value": "43558",
    "label": "Swanton - OH 43558",
    "county": "Fulton County",
    "state": "OH",
    "city": "Swanton"
  },
  {
    "value": "43560",
    "label": "Sylvania - OH 43560",
    "county": "Lucas County",
    "state": "OH",
    "city": "Sylvania"
  },
  {
    "value": "43566",
    "label": "Waterville - OH 43566",
    "county": "Lucas County",
    "state": "OH",
    "city": "Waterville"
  },
  {
    "value": "43567",
    "label": "Wauseon - OH 43567",
    "county": "Fulton County",
    "state": "OH",
    "city": "Wauseon"
  },
  {
    "value": "43569",
    "label": "Weston - OH 43569",
    "county": "Wood County",
    "state": "OH",
    "city": "Weston"
  },
  {
    "value": "43570",
    "label": "West Unity - OH 43570",
    "county": "Williams County",
    "state": "OH",
    "city": "West Unity"
  },
  {
    "value": "43571",
    "label": "Whitehouse - OH 43571",
    "county": "Lucas County",
    "state": "OH",
    "city": "Whitehouse"
  },
  {
    "value": "43601",
    "label": "Toledo - OH 43601",
    "county": "Lucas County",
    "state": "OH",
    "city": "Toledo"
  },
  {
    "value": "43604",
    "label": "Toledo - OH 43604",
    "county": "Lucas County",
    "state": "OH",
    "city": "Toledo"
  },
  {
    "value": "43605",
    "label": "Toledo - OH 43605",
    "county": "Lucas County",
    "state": "OH",
    "city": "Toledo"
  },
  {
    "value": "43606",
    "label": "Toledo - OH 43606",
    "county": "Lucas County",
    "state": "OH",
    "city": "Toledo"
  },
  {
    "value": "43607",
    "label": "Toledo - OH 43607",
    "county": "Lucas County",
    "state": "OH",
    "city": "Toledo"
  },
  {
    "value": "43608",
    "label": "Toledo - OH 43608",
    "county": "Lucas County",
    "state": "OH",
    "city": "Toledo"
  },
  {
    "value": "43609",
    "label": "Toledo - OH 43609",
    "county": "Lucas County",
    "state": "OH",
    "city": "Toledo"
  },
  {
    "value": "43610",
    "label": "Toledo - OH 43610",
    "county": "Lucas County",
    "state": "OH",
    "city": "Toledo"
  },
  {
    "value": "43611",
    "label": "Toledo - OH 43611",
    "county": "Lucas County",
    "state": "OH",
    "city": "Toledo"
  },
  {
    "value": "43612",
    "label": "Toledo - OH 43612",
    "county": "Lucas County",
    "state": "OH",
    "city": "Toledo"
  },
  {
    "value": "43613",
    "label": "Toledo - OH 43613",
    "county": "Lucas County",
    "state": "OH",
    "city": "Toledo"
  },
  {
    "value": "43614",
    "label": "Toledo - OH 43614",
    "county": "Lucas County",
    "state": "OH",
    "city": "Toledo"
  },
  {
    "value": "43615",
    "label": "Toledo - OH 43615",
    "county": "Lucas County",
    "state": "OH",
    "city": "Toledo"
  },
  {
    "value": "43616",
    "label": "Oregon - OH 43616",
    "county": "Lucas County",
    "state": "OH",
    "city": "Oregon"
  },
  {
    "value": "43617",
    "label": "Toledo - OH 43617",
    "county": "Lucas County",
    "state": "OH",
    "city": "Toledo"
  },
  {
    "value": "43618",
    "label": "Oregon - OH 43618",
    "county": "Lucas County",
    "state": "OH",
    "city": "Oregon"
  },
  {
    "value": "43619",
    "label": "Northwood - OH 43619",
    "county": "Wood County",
    "state": "OH",
    "city": "Northwood"
  },
  {
    "value": "43620",
    "label": "Toledo - OH 43620",
    "county": "Lucas County",
    "state": "OH",
    "city": "Toledo"
  },
  {
    "value": "43623",
    "label": "Toledo - OH 43623",
    "county": "Lucas County",
    "state": "OH",
    "city": "Toledo"
  },
  {
    "value": "43701",
    "label": "Zanesville - OH 43701",
    "county": "Muskingum County",
    "state": "OH",
    "city": "Zanesville"
  },
  {
    "value": "43713",
    "label": "Barnesville - OH 43713",
    "county": "Belmont County",
    "state": "OH",
    "city": "Barnesville"
  },
  {
    "value": "43716",
    "label": "Beallsville - OH 43716",
    "county": "Monroe County",
    "state": "OH",
    "city": "Beallsville"
  },
  {
    "value": "43718",
    "label": "Belmont - OH 43718",
    "county": "Belmont County",
    "state": "OH",
    "city": "Belmont"
  },
  {
    "value": "43719",
    "label": "Bethesda - OH 43719",
    "county": "Belmont County",
    "state": "OH",
    "city": "Bethesda"
  },
  {
    "value": "43720",
    "label": "Blue Rock - OH 43720",
    "county": "Muskingum County",
    "state": "OH",
    "city": "Blue Rock"
  },
  {
    "value": "43723",
    "label": "Byesville - OH 43723",
    "county": "Guernsey County",
    "state": "OH",
    "city": "Byesville"
  },
  {
    "value": "43724",
    "label": "Caldwell - OH 43724",
    "county": "Noble County",
    "state": "OH",
    "city": "Caldwell"
  },
  {
    "value": "43725",
    "label": "Cambridge - OH 43725",
    "county": "Guernsey County",
    "state": "OH",
    "city": "Cambridge"
  },
  {
    "value": "43727",
    "label": "Chandlersville - OH 43727",
    "county": "Muskingum County",
    "state": "OH",
    "city": "Chandlersville"
  },
  {
    "value": "43728",
    "label": "Chesterhill - OH 43728",
    "county": "Morgan County",
    "state": "OH",
    "city": "Chesterhill"
  },
  {
    "value": "43730",
    "label": "Corning - OH 43730",
    "county": "Perry County",
    "state": "OH",
    "city": "Corning"
  },
  {
    "value": "43731",
    "label": "Crooksville - OH 43731",
    "county": "Perry County",
    "state": "OH",
    "city": "Crooksville"
  },
  {
    "value": "43732",
    "label": "Cumberland - OH 43732",
    "county": "Guernsey County",
    "state": "OH",
    "city": "Cumberland"
  },
  {
    "value": "43734",
    "label": "Duncan Falls - OH 43734",
    "county": "Muskingum County",
    "state": "OH",
    "city": "Duncan Falls"
  },
  {
    "value": "43739",
    "label": "Glenford - OH 43739",
    "county": "Perry County",
    "state": "OH",
    "city": "Glenford"
  },
  {
    "value": "43746",
    "label": "Hopewell - OH 43746",
    "county": "Muskingum County",
    "state": "OH",
    "city": "Hopewell"
  },
  {
    "value": "43747",
    "label": "Jerusalem - OH 43747",
    "county": "Monroe County",
    "state": "OH",
    "city": "Jerusalem"
  },
  {
    "value": "43748",
    "label": "Junction City - OH 43748",
    "county": "Perry County",
    "state": "OH",
    "city": "Junction City"
  },
  {
    "value": "43749",
    "label": "Kimbolton - OH 43749",
    "county": "Guernsey County",
    "state": "OH",
    "city": "Kimbolton"
  },
  {
    "value": "43754",
    "label": "Lewisville - OH 43754",
    "county": "Monroe County",
    "state": "OH",
    "city": "Lewisville"
  },
  {
    "value": "43755",
    "label": "Lore City - OH 43755",
    "county": "Guernsey County",
    "state": "OH",
    "city": "Lore City"
  },
  {
    "value": "43756",
    "label": "Mcconnelsville - OH 43756",
    "county": "Morgan County",
    "state": "OH",
    "city": "Mcconnelsville"
  },
  {
    "value": "43758",
    "label": "Malta - OH 43758",
    "county": "Morgan County",
    "state": "OH",
    "city": "Malta"
  },
  {
    "value": "43760",
    "label": "Mount Perry - OH 43760",
    "county": "Perry County",
    "state": "OH",
    "city": "Mount Perry"
  },
  {
    "value": "43762",
    "label": "New Concord - OH 43762",
    "county": "Muskingum County",
    "state": "OH",
    "city": "New Concord"
  },
  {
    "value": "43764",
    "label": "New Lexington - OH 43764",
    "county": "Perry County",
    "state": "OH",
    "city": "New Lexington"
  },
  {
    "value": "43766",
    "label": "New Straitsville - OH 43766",
    "county": "Perry County",
    "state": "OH",
    "city": "New Straitsville"
  },
  {
    "value": "43767",
    "label": "Norwich - OH 43767",
    "county": "Muskingum County",
    "state": "OH",
    "city": "Norwich"
  },
  {
    "value": "43771",
    "label": "Philo - OH 43771",
    "county": "Muskingum County",
    "state": "OH",
    "city": "Philo"
  },
  {
    "value": "43772",
    "label": "Pleasant City - OH 43772",
    "county": "Guernsey County",
    "state": "OH",
    "city": "Pleasant City"
  },
  {
    "value": "43773",
    "label": "Quaker City - OH 43773",
    "county": "Guernsey County",
    "state": "OH",
    "city": "Quaker City"
  },
  {
    "value": "43777",
    "label": "Roseville - OH 43777",
    "county": "Muskingum County",
    "state": "OH",
    "city": "Roseville"
  },
  {
    "value": "43778",
    "label": "Salesville - OH 43778",
    "county": "Guernsey County",
    "state": "OH",
    "city": "Salesville"
  },
  {
    "value": "43779",
    "label": "Sarahsville - OH 43779",
    "county": "Noble County",
    "state": "OH",
    "city": "Sarahsville"
  },
  {
    "value": "43780",
    "label": "Senecaville - OH 43780",
    "county": "Guernsey County",
    "state": "OH",
    "city": "Senecaville"
  },
  {
    "value": "43782",
    "label": "Shawnee - OH 43782",
    "county": "Perry County",
    "state": "OH",
    "city": "Shawnee"
  },
  {
    "value": "43783",
    "label": "Somerset - OH 43783",
    "county": "Perry County",
    "state": "OH",
    "city": "Somerset"
  },
  {
    "value": "43787",
    "label": "Stockport - OH 43787",
    "county": "Morgan County",
    "state": "OH",
    "city": "Stockport"
  },
  {
    "value": "43788",
    "label": "Summerfield - OH 43788",
    "county": "Noble County",
    "state": "OH",
    "city": "Summerfield"
  },
  {
    "value": "43793",
    "label": "Woodsfield - OH 43793",
    "county": "Monroe County",
    "state": "OH",
    "city": "Woodsfield"
  },
  {
    "value": "43802",
    "label": "Adamsville - OH 43802",
    "county": "Muskingum County",
    "state": "OH",
    "city": "Adamsville"
  },
  {
    "value": "43804",
    "label": "Baltic - OH 43804",
    "county": "Holmes County",
    "state": "OH",
    "city": "Baltic"
  },
  {
    "value": "43811",
    "label": "Conesville - OH 43811",
    "county": "Coshocton County",
    "state": "OH",
    "city": "Conesville"
  },
  {
    "value": "43812",
    "label": "Coshocton - OH 43812",
    "county": "Coshocton County",
    "state": "OH",
    "city": "Coshocton"
  },
  {
    "value": "43821",
    "label": "Dresden - OH 43821",
    "county": "Muskingum County",
    "state": "OH",
    "city": "Dresden"
  },
  {
    "value": "43822",
    "label": "Frazeysburg - OH 43822",
    "county": "Muskingum County",
    "state": "OH",
    "city": "Frazeysburg"
  },
  {
    "value": "43824",
    "label": "Fresno - OH 43824",
    "county": "Coshocton County",
    "state": "OH",
    "city": "Fresno"
  },
  {
    "value": "43830",
    "label": "Nashport - OH 43830",
    "county": "Muskingum County",
    "state": "OH",
    "city": "Nashport"
  },
  {
    "value": "43832",
    "label": "Newcomerstown - OH 43832",
    "county": "Tuscarawas County",
    "state": "OH",
    "city": "Newcomerstown"
  },
  {
    "value": "43837",
    "label": "Port Washington - OH 43837",
    "county": "Tuscarawas County",
    "state": "OH",
    "city": "Port Washington"
  },
  {
    "value": "43840",
    "label": "Stone Creek - OH 43840",
    "county": "Tuscarawas County",
    "state": "OH",
    "city": "Stone Creek"
  },
  {
    "value": "43843",
    "label": "Walhonding - OH 43843",
    "county": "Coshocton County",
    "state": "OH",
    "city": "Walhonding"
  },
  {
    "value": "43844",
    "label": "Warsaw - OH 43844",
    "county": "Coshocton County",
    "state": "OH",
    "city": "Warsaw"
  },
  {
    "value": "43845",
    "label": "West Lafayette - OH 43845",
    "county": "Coshocton County",
    "state": "OH",
    "city": "West Lafayette"
  },
  {
    "value": "43901",
    "label": "Adena - OH 43901",
    "county": "Jefferson County",
    "state": "OH",
    "city": "Adena"
  },
  {
    "value": "43902",
    "label": "Alledonia - OH 43902",
    "county": "Belmont County",
    "state": "OH",
    "city": "Alledonia"
  },
  {
    "value": "43903",
    "label": "Amsterdam - OH 43903",
    "county": "Jefferson County",
    "state": "OH",
    "city": "Amsterdam"
  },
  {
    "value": "43906",
    "label": "Bellaire - OH 43906",
    "county": "Belmont County",
    "state": "OH",
    "city": "Bellaire"
  },
  {
    "value": "43907",
    "label": "Cadiz - OH 43907",
    "county": "Harrison County",
    "state": "OH",
    "city": "Cadiz"
  },
  {
    "value": "43908",
    "label": "Bergholz - OH 43908",
    "county": "Jefferson County",
    "state": "OH",
    "city": "Bergholz"
  },
  {
    "value": "43910",
    "label": "Bloomingdale - OH 43910",
    "county": "Jefferson County",
    "state": "OH",
    "city": "Bloomingdale"
  },
  {
    "value": "43912",
    "label": "Bridgeport - OH 43912",
    "county": "Belmont County",
    "state": "OH",
    "city": "Bridgeport"
  },
  {
    "value": "43913",
    "label": "Brilliant - OH 43913",
    "county": "Jefferson County",
    "state": "OH",
    "city": "Brilliant"
  },
  {
    "value": "43915",
    "label": "Clarington - OH 43915",
    "county": "Monroe County",
    "state": "OH",
    "city": "Clarington"
  },
  {
    "value": "43917",
    "label": "Dillonvale - OH 43917",
    "county": "Jefferson County",
    "state": "OH",
    "city": "Dillonvale"
  },
  {
    "value": "43920",
    "label": "East Liverpool - OH 43920",
    "county": "Columbiana County",
    "state": "OH",
    "city": "East Liverpool"
  },
  {
    "value": "43930",
    "label": "Hammondsville - OH 43930",
    "county": "Jefferson County",
    "state": "OH",
    "city": "Hammondsville"
  },
  {
    "value": "43932",
    "label": "Irondale - OH 43932",
    "county": "Jefferson County",
    "state": "OH",
    "city": "Irondale"
  },
  {
    "value": "43933",
    "label": "Jacobsburg - OH 43933",
    "county": "Belmont County",
    "state": "OH",
    "city": "Jacobsburg"
  },
  {
    "value": "43935",
    "label": "Martins Ferry - OH 43935",
    "county": "Belmont County",
    "state": "OH",
    "city": "Martins Ferry"
  },
  {
    "value": "43938",
    "label": "Mingo Junction - OH 43938",
    "county": "Jefferson County",
    "state": "OH",
    "city": "Mingo Junction"
  },
  {
    "value": "43942",
    "label": "Powhatan Point - OH 43942",
    "county": "Belmont County",
    "state": "OH",
    "city": "Powhatan Point"
  },
  {
    "value": "43943",
    "label": "Rayland - OH 43943",
    "county": "Jefferson County",
    "state": "OH",
    "city": "Rayland"
  },
  {
    "value": "43944",
    "label": "Richmond - OH 43944",
    "county": "Jefferson County",
    "state": "OH",
    "city": "Richmond"
  },
  {
    "value": "43945",
    "label": "Salineville - OH 43945",
    "county": "Columbiana County",
    "state": "OH",
    "city": "Salineville"
  },
  {
    "value": "43946",
    "label": "Sardis - OH 43946",
    "county": "Monroe County",
    "state": "OH",
    "city": "Sardis"
  },
  {
    "value": "43947",
    "label": "Shadyside - OH 43947",
    "county": "Belmont County",
    "state": "OH",
    "city": "Shadyside"
  },
  {
    "value": "43950",
    "label": "Saint Clairsville - OH 43950",
    "county": "Belmont County",
    "state": "OH",
    "city": "Saint Clairsville"
  },
  {
    "value": "43952",
    "label": "Steubenville - OH 43952",
    "county": "Jefferson County",
    "state": "OH",
    "city": "Steubenville"
  },
  {
    "value": "43953",
    "label": "Steubenville - OH 43953",
    "county": "Jefferson County",
    "state": "OH",
    "city": "Steubenville"
  },
  {
    "value": "43963",
    "label": "Tiltonsville - OH 43963",
    "county": "Jefferson County",
    "state": "OH",
    "city": "Tiltonsville"
  },
  {
    "value": "43964",
    "label": "Toronto - OH 43964",
    "county": "Jefferson County",
    "state": "OH",
    "city": "Toronto"
  },
  {
    "value": "43968",
    "label": "Wellsville - OH 43968",
    "county": "Columbiana County",
    "state": "OH",
    "city": "Wellsville"
  },
  {
    "value": "43971",
    "label": "Yorkville - OH 43971",
    "county": "Jefferson County",
    "state": "OH",
    "city": "Yorkville"
  },
  {
    "value": "43973",
    "label": "Freeport - OH 43973",
    "county": "Harrison County",
    "state": "OH",
    "city": "Freeport"
  },
  {
    "value": "43976",
    "label": "Hopedale - OH 43976",
    "county": "Harrison County",
    "state": "OH",
    "city": "Hopedale"
  },
  {
    "value": "43977",
    "label": "Flushing - OH 43977",
    "county": "Belmont County",
    "state": "OH",
    "city": "Flushing"
  },
  {
    "value": "43983",
    "label": "Piedmont - OH 43983",
    "county": "Belmont County",
    "state": "OH",
    "city": "Piedmont"
  },
  {
    "value": "43986",
    "label": "Jewett - OH 43986",
    "county": "Harrison County",
    "state": "OH",
    "city": "Jewett"
  },
  {
    "value": "43988",
    "label": "Scio - OH 43988",
    "county": "Harrison County",
    "state": "OH",
    "city": "Scio"
  },
  {
    "value": "44001",
    "label": "Amherst - OH 44001",
    "county": "Lorain County",
    "state": "OH",
    "city": "Amherst"
  },
  {
    "value": "44003",
    "label": "Andover - OH 44003",
    "county": "Ashtabula County",
    "state": "OH",
    "city": "Andover"
  },
  {
    "value": "44004",
    "label": "Ashtabula - OH 44004",
    "county": "Ashtabula County",
    "state": "OH",
    "city": "Ashtabula"
  },
  {
    "value": "44010",
    "label": "Austinburg - OH 44010",
    "county": "Ashtabula County",
    "state": "OH",
    "city": "Austinburg"
  },
  {
    "value": "44011",
    "label": "Avon - OH 44011",
    "county": "Lorain County",
    "state": "OH",
    "city": "Avon"
  },
  {
    "value": "44012",
    "label": "Avon Lake - OH 44012",
    "county": "Lorain County",
    "state": "OH",
    "city": "Avon Lake"
  },
  {
    "value": "44017",
    "label": "Berea - OH 44017",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Berea"
  },
  {
    "value": "44021",
    "label": "Burton - OH 44021",
    "county": "Geauga County",
    "state": "OH",
    "city": "Burton"
  },
  {
    "value": "44022",
    "label": "Chagrin Falls - OH 44022",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Chagrin Falls"
  },
  {
    "value": "44023",
    "label": "Chagrin Falls - OH 44023",
    "county": "Geauga County",
    "state": "OH",
    "city": "Chagrin Falls"
  },
  {
    "value": "44024",
    "label": "Chardon - OH 44024",
    "county": "Geauga County",
    "state": "OH",
    "city": "Chardon"
  },
  {
    "value": "44026",
    "label": "Chesterland - OH 44026",
    "county": "Geauga County",
    "state": "OH",
    "city": "Chesterland"
  },
  {
    "value": "44028",
    "label": "Columbia Station - OH 44028",
    "county": "Lorain County",
    "state": "OH",
    "city": "Columbia Station"
  },
  {
    "value": "44030",
    "label": "Conneaut - OH 44030",
    "county": "Ashtabula County",
    "state": "OH",
    "city": "Conneaut"
  },
  {
    "value": "44032",
    "label": "Dorset - OH 44032",
    "county": "Ashtabula County",
    "state": "OH",
    "city": "Dorset"
  },
  {
    "value": "44035",
    "label": "Elyria - OH 44035",
    "county": "Lorain County",
    "state": "OH",
    "city": "Elyria"
  },
  {
    "value": "44039",
    "label": "North Ridgeville - OH 44039",
    "county": "Lorain County",
    "state": "OH",
    "city": "North Ridgeville"
  },
  {
    "value": "44040",
    "label": "Gates Mills - OH 44040",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Gates Mills"
  },
  {
    "value": "44041",
    "label": "Geneva - OH 44041",
    "county": "Ashtabula County",
    "state": "OH",
    "city": "Geneva"
  },
  {
    "value": "44044",
    "label": "Grafton - OH 44044",
    "county": "Lorain County",
    "state": "OH",
    "city": "Grafton"
  },
  {
    "value": "44046",
    "label": "Huntsburg - OH 44046",
    "county": "Geauga County",
    "state": "OH",
    "city": "Huntsburg"
  },
  {
    "value": "44047",
    "label": "Jefferson - OH 44047",
    "county": "Ashtabula County",
    "state": "OH",
    "city": "Jefferson"
  },
  {
    "value": "44048",
    "label": "Kingsville - OH 44048",
    "county": "Ashtabula County",
    "state": "OH",
    "city": "Kingsville"
  },
  {
    "value": "44050",
    "label": "Lagrange - OH 44050",
    "county": "Lorain County",
    "state": "OH",
    "city": "Lagrange"
  },
  {
    "value": "44052",
    "label": "Lorain - OH 44052",
    "county": "Lorain County",
    "state": "OH",
    "city": "Lorain"
  },
  {
    "value": "44053",
    "label": "Lorain - OH 44053",
    "county": "Lorain County",
    "state": "OH",
    "city": "Lorain"
  },
  {
    "value": "44054",
    "label": "Sheffield Lake - OH 44054",
    "county": "Lorain County",
    "state": "OH",
    "city": "Sheffield Lake"
  },
  {
    "value": "44055",
    "label": "Lorain - OH 44055",
    "county": "Lorain County",
    "state": "OH",
    "city": "Lorain"
  },
  {
    "value": "44056",
    "label": "Macedonia - OH 44056",
    "county": "Summit County",
    "state": "OH",
    "city": "Macedonia"
  },
  {
    "value": "44057",
    "label": "Madison - OH 44057",
    "county": "Lake County",
    "state": "OH",
    "city": "Madison"
  },
  {
    "value": "44060",
    "label": "Mentor - OH 44060",
    "county": "Lake County",
    "state": "OH",
    "city": "Mentor"
  },
  {
    "value": "44062",
    "label": "Middlefield - OH 44062",
    "county": "Geauga County",
    "state": "OH",
    "city": "Middlefield"
  },
  {
    "value": "44064",
    "label": "Montville - OH 44064",
    "county": "Geauga County",
    "state": "OH",
    "city": "Montville"
  },
  {
    "value": "44065",
    "label": "Newbury - OH 44065",
    "county": "Geauga County",
    "state": "OH",
    "city": "Newbury"
  },
  {
    "value": "44067",
    "label": "Northfield - OH 44067",
    "county": "Summit County",
    "state": "OH",
    "city": "Northfield"
  },
  {
    "value": "44070",
    "label": "North Olmsted - OH 44070",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "North Olmsted"
  },
  {
    "value": "44072",
    "label": "Novelty - OH 44072",
    "county": "Geauga County",
    "state": "OH",
    "city": "Novelty"
  },
  {
    "value": "44074",
    "label": "Oberlin - OH 44074",
    "county": "Lorain County",
    "state": "OH",
    "city": "Oberlin"
  },
  {
    "value": "44076",
    "label": "Orwell - OH 44076",
    "county": "Ashtabula County",
    "state": "OH",
    "city": "Orwell"
  },
  {
    "value": "44077",
    "label": "Painesville - OH 44077",
    "county": "Lake County",
    "state": "OH",
    "city": "Painesville"
  },
  {
    "value": "44081",
    "label": "Perry - OH 44081",
    "county": "Lake County",
    "state": "OH",
    "city": "Perry"
  },
  {
    "value": "44082",
    "label": "Pierpont - OH 44082",
    "county": "Ashtabula County",
    "state": "OH",
    "city": "Pierpont"
  },
  {
    "value": "44084",
    "label": "Rock Creek - OH 44084",
    "county": "Ashtabula County",
    "state": "OH",
    "city": "Rock Creek"
  },
  {
    "value": "44085",
    "label": "Rome - OH 44085",
    "county": "Ashtabula County",
    "state": "OH",
    "city": "Rome"
  },
  {
    "value": "44086",
    "label": "Thompson - OH 44086",
    "county": "Geauga County",
    "state": "OH",
    "city": "Thompson"
  },
  {
    "value": "44087",
    "label": "Twinsburg - OH 44087",
    "county": "Summit County",
    "state": "OH",
    "city": "Twinsburg"
  },
  {
    "value": "44089",
    "label": "Vermilion - OH 44089",
    "county": "Erie County",
    "state": "OH",
    "city": "Vermilion"
  },
  {
    "value": "44090",
    "label": "Wellington - OH 44090",
    "county": "Lorain County",
    "state": "OH",
    "city": "Wellington"
  },
  {
    "value": "44092",
    "label": "Wickliffe - OH 44092",
    "county": "Lake County",
    "state": "OH",
    "city": "Wickliffe"
  },
  {
    "value": "44093",
    "label": "Williamsfield - OH 44093",
    "county": "Ashtabula County",
    "state": "OH",
    "city": "Williamsfield"
  },
  {
    "value": "44094",
    "label": "Willoughby - OH 44094",
    "county": "Lake County",
    "state": "OH",
    "city": "Willoughby"
  },
  {
    "value": "44095",
    "label": "Eastlake - OH 44095",
    "county": "Lake County",
    "state": "OH",
    "city": "Eastlake"
  },
  {
    "value": "44099",
    "label": "Windsor - OH 44099",
    "county": "Ashtabula County",
    "state": "OH",
    "city": "Windsor"
  },
  {
    "value": "44102",
    "label": "Cleveland - OH 44102",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44103",
    "label": "Cleveland - OH 44103",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44104",
    "label": "Cleveland - OH 44104",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44105",
    "label": "Cleveland - OH 44105",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44106",
    "label": "Cleveland - OH 44106",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44107",
    "label": "Lakewood - OH 44107",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Lakewood"
  },
  {
    "value": "44108",
    "label": "Cleveland - OH 44108",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44109",
    "label": "Cleveland - OH 44109",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44110",
    "label": "Cleveland - OH 44110",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44111",
    "label": "Cleveland - OH 44111",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44112",
    "label": "Cleveland - OH 44112",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44113",
    "label": "Cleveland - OH 44113",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44114",
    "label": "Cleveland - OH 44114",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44115",
    "label": "Cleveland - OH 44115",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44116",
    "label": "Rocky River - OH 44116",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Rocky River"
  },
  {
    "value": "44117",
    "label": "Euclid - OH 44117",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Euclid"
  },
  {
    "value": "44118",
    "label": "Cleveland - OH 44118",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44119",
    "label": "Cleveland - OH 44119",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44120",
    "label": "Cleveland - OH 44120",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44121",
    "label": "Cleveland - OH 44121",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44122",
    "label": "Beachwood - OH 44122",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Beachwood"
  },
  {
    "value": "44123",
    "label": "Euclid - OH 44123",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Euclid"
  },
  {
    "value": "44124",
    "label": "Cleveland - OH 44124",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44125",
    "label": "Cleveland - OH 44125",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44126",
    "label": "Cleveland - OH 44126",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44127",
    "label": "Cleveland - OH 44127",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44128",
    "label": "Cleveland - OH 44128",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44129",
    "label": "Cleveland - OH 44129",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44130",
    "label": "Cleveland - OH 44130",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44131",
    "label": "Independence - OH 44131",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Independence"
  },
  {
    "value": "44132",
    "label": "Euclid - OH 44132",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Euclid"
  },
  {
    "value": "44133",
    "label": "North Royalton - OH 44133",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "North Royalton"
  },
  {
    "value": "44134",
    "label": "Cleveland - OH 44134",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44135",
    "label": "Cleveland - OH 44135",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44136",
    "label": "Strongsville - OH 44136",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Strongsville"
  },
  {
    "value": "44137",
    "label": "Maple Heights - OH 44137",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Maple Heights"
  },
  {
    "value": "44138",
    "label": "Olmsted Falls - OH 44138",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Olmsted Falls"
  },
  {
    "value": "44139",
    "label": "Solon - OH 44139",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Solon"
  },
  {
    "value": "44140",
    "label": "Bay Village - OH 44140",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Bay Village"
  },
  {
    "value": "44141",
    "label": "Brecksville - OH 44141",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Brecksville"
  },
  {
    "value": "44142",
    "label": "Brookpark - OH 44142",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Brookpark"
  },
  {
    "value": "44143",
    "label": "Cleveland - OH 44143",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44144",
    "label": "Cleveland - OH 44144",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Cleveland"
  },
  {
    "value": "44145",
    "label": "Westlake - OH 44145",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Westlake"
  },
  {
    "value": "44146",
    "label": "Bedford - OH 44146",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Bedford"
  },
  {
    "value": "44147",
    "label": "Broadview Heights - OH 44147",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Broadview Heights"
  },
  {
    "value": "44149",
    "label": "Strongsville - OH 44149",
    "county": "Cuyahoga County",
    "state": "OH",
    "city": "Strongsville"
  },
  {
    "value": "44201",
    "label": "Atwater - OH 44201",
    "county": "Portage County",
    "state": "OH",
    "city": "Atwater"
  },
  {
    "value": "44202",
    "label": "Aurora - OH 44202",
    "county": "Portage County",
    "state": "OH",
    "city": "Aurora"
  },
  {
    "value": "44203",
    "label": "Barberton - OH 44203",
    "county": "Summit County",
    "state": "OH",
    "city": "Barberton"
  },
  {
    "value": "44212",
    "label": "Brunswick - OH 44212",
    "county": "Medina County",
    "state": "OH",
    "city": "Brunswick"
  },
  {
    "value": "44214",
    "label": "Burbank - OH 44214",
    "county": "Wayne County",
    "state": "OH",
    "city": "Burbank"
  },
  {
    "value": "44215",
    "label": "Chippewa Lake - OH 44215",
    "county": "Medina County",
    "state": "OH",
    "city": "Chippewa Lake"
  },
  {
    "value": "44216",
    "label": "Clinton - OH 44216",
    "county": "Summit County",
    "state": "OH",
    "city": "Clinton"
  },
  {
    "value": "44217",
    "label": "Creston - OH 44217",
    "county": "Wayne County",
    "state": "OH",
    "city": "Creston"
  },
  {
    "value": "44221",
    "label": "Cuyahoga Falls - OH 44221",
    "county": "Summit County",
    "state": "OH",
    "city": "Cuyahoga Falls"
  },
  {
    "value": "44223",
    "label": "Cuyahoga Falls - OH 44223",
    "county": "Summit County",
    "state": "OH",
    "city": "Cuyahoga Falls"
  },
  {
    "value": "44224",
    "label": "Stow - OH 44224",
    "county": "Summit County",
    "state": "OH",
    "city": "Stow"
  },
  {
    "value": "44230",
    "label": "Doylestown - OH 44230",
    "county": "Wayne County",
    "state": "OH",
    "city": "Doylestown"
  },
  {
    "value": "44231",
    "label": "Garrettsville - OH 44231",
    "county": "Portage County",
    "state": "OH",
    "city": "Garrettsville"
  },
  {
    "value": "44233",
    "label": "Hinckley - OH 44233",
    "county": "Medina County",
    "state": "OH",
    "city": "Hinckley"
  },
  {
    "value": "44234",
    "label": "Hiram - OH 44234",
    "county": "Portage County",
    "state": "OH",
    "city": "Hiram"
  },
  {
    "value": "44235",
    "label": "Homerville - OH 44235",
    "county": "Medina County",
    "state": "OH",
    "city": "Homerville"
  },
  {
    "value": "44236",
    "label": "Hudson - OH 44236",
    "county": "Summit County",
    "state": "OH",
    "city": "Hudson"
  },
  {
    "value": "44240",
    "label": "Kent - OH 44240",
    "county": "Portage County",
    "state": "OH",
    "city": "Kent"
  },
  {
    "value": "44241",
    "label": "Streetsboro - OH 44241",
    "county": "Portage County",
    "state": "OH",
    "city": "Streetsboro"
  },
  {
    "value": "44243",
    "label": "Kent - OH 44243",
    "county": "Portage County",
    "state": "OH",
    "city": "Kent"
  },
  {
    "value": "44253",
    "label": "Litchfield - OH 44253",
    "county": "Medina County",
    "state": "OH",
    "city": "Litchfield"
  },
  {
    "value": "44254",
    "label": "Lodi - OH 44254",
    "county": "Medina County",
    "state": "OH",
    "city": "Lodi"
  },
  {
    "value": "44255",
    "label": "Mantua - OH 44255",
    "county": "Portage County",
    "state": "OH",
    "city": "Mantua"
  },
  {
    "value": "44256",
    "label": "Medina - OH 44256",
    "county": "Medina County",
    "state": "OH",
    "city": "Medina"
  },
  {
    "value": "44260",
    "label": "Mogadore - OH 44260",
    "county": "Portage County",
    "state": "OH",
    "city": "Mogadore"
  },
  {
    "value": "44262",
    "label": "Munroe Falls - OH 44262",
    "county": "Summit County",
    "state": "OH",
    "city": "Munroe Falls"
  },
  {
    "value": "44264",
    "label": "Peninsula - OH 44264",
    "county": "Summit County",
    "state": "OH",
    "city": "Peninsula"
  },
  {
    "value": "44266",
    "label": "Ravenna - OH 44266",
    "county": "Portage County",
    "state": "OH",
    "city": "Ravenna"
  },
  {
    "value": "44270",
    "label": "Rittman - OH 44270",
    "county": "Wayne County",
    "state": "OH",
    "city": "Rittman"
  },
  {
    "value": "44272",
    "label": "Rootstown - OH 44272",
    "county": "Portage County",
    "state": "OH",
    "city": "Rootstown"
  },
  {
    "value": "44273",
    "label": "Seville - OH 44273",
    "county": "Medina County",
    "state": "OH",
    "city": "Seville"
  },
  {
    "value": "44275",
    "label": "Spencer - OH 44275",
    "county": "Medina County",
    "state": "OH",
    "city": "Spencer"
  },
  {
    "value": "44276",
    "label": "Sterling - OH 44276",
    "county": "Wayne County",
    "state": "OH",
    "city": "Sterling"
  },
  {
    "value": "44278",
    "label": "Tallmadge - OH 44278",
    "county": "Summit County",
    "state": "OH",
    "city": "Tallmadge"
  },
  {
    "value": "44280",
    "label": "Valley City - OH 44280",
    "county": "Medina County",
    "state": "OH",
    "city": "Valley City"
  },
  {
    "value": "44281",
    "label": "Wadsworth - OH 44281",
    "county": "Medina County",
    "state": "OH",
    "city": "Wadsworth"
  },
  {
    "value": "44286",
    "label": "Richfield - OH 44286",
    "county": "Summit County",
    "state": "OH",
    "city": "Richfield"
  },
  {
    "value": "44287",
    "label": "West Salem - OH 44287",
    "county": "Wayne County",
    "state": "OH",
    "city": "West Salem"
  },
  {
    "value": "44288",
    "label": "Windham - OH 44288",
    "county": "Portage County",
    "state": "OH",
    "city": "Windham"
  },
  {
    "value": "44301",
    "label": "Akron - OH 44301",
    "county": "Summit County",
    "state": "OH",
    "city": "Akron"
  },
  {
    "value": "44302",
    "label": "Akron - OH 44302",
    "county": "Summit County",
    "state": "OH",
    "city": "Akron"
  },
  {
    "value": "44303",
    "label": "Akron - OH 44303",
    "county": "Summit County",
    "state": "OH",
    "city": "Akron"
  },
  {
    "value": "44304",
    "label": "Akron - OH 44304",
    "county": "Summit County",
    "state": "OH",
    "city": "Akron"
  },
  {
    "value": "44305",
    "label": "Akron - OH 44305",
    "county": "Summit County",
    "state": "OH",
    "city": "Akron"
  },
  {
    "value": "44306",
    "label": "Akron - OH 44306",
    "county": "Summit County",
    "state": "OH",
    "city": "Akron"
  },
  {
    "value": "44307",
    "label": "Akron - OH 44307",
    "county": "Summit County",
    "state": "OH",
    "city": "Akron"
  },
  {
    "value": "44308",
    "label": "Akron - OH 44308",
    "county": "Summit County",
    "state": "OH",
    "city": "Akron"
  },
  {
    "value": "44310",
    "label": "Akron - OH 44310",
    "county": "Summit County",
    "state": "OH",
    "city": "Akron"
  },
  {
    "value": "44311",
    "label": "Akron - OH 44311",
    "county": "Summit County",
    "state": "OH",
    "city": "Akron"
  },
  {
    "value": "44312",
    "label": "Akron - OH 44312",
    "county": "Summit County",
    "state": "OH",
    "city": "Akron"
  },
  {
    "value": "44313",
    "label": "Akron - OH 44313",
    "county": "Summit County",
    "state": "OH",
    "city": "Akron"
  },
  {
    "value": "44314",
    "label": "Akron - OH 44314",
    "county": "Summit County",
    "state": "OH",
    "city": "Akron"
  },
  {
    "value": "44319",
    "label": "Akron - OH 44319",
    "county": "Summit County",
    "state": "OH",
    "city": "Akron"
  },
  {
    "value": "44320",
    "label": "Akron - OH 44320",
    "county": "Summit County",
    "state": "OH",
    "city": "Akron"
  },
  {
    "value": "44321",
    "label": "Akron - OH 44321",
    "county": "Summit County",
    "state": "OH",
    "city": "Akron"
  },
  {
    "value": "44322",
    "label": "Akron - OH 44322",
    "county": "Summit County",
    "state": "OH",
    "city": "Akron"
  },
  {
    "value": "44333",
    "label": "Akron - OH 44333",
    "county": "Summit County",
    "state": "OH",
    "city": "Akron"
  },
  {
    "value": "44401",
    "label": "Berlin Center - OH 44401",
    "county": "Mahoning County",
    "state": "OH",
    "city": "Berlin Center"
  },
  {
    "value": "44402",
    "label": "Bristolville - OH 44402",
    "county": "Trumbull County",
    "state": "OH",
    "city": "Bristolville"
  },
  {
    "value": "44403",
    "label": "Brookfield - OH 44403",
    "county": "Trumbull County",
    "state": "OH",
    "city": "Brookfield"
  },
  {
    "value": "44404",
    "label": "Burghill - OH 44404",
    "county": "Trumbull County",
    "state": "OH",
    "city": "Burghill"
  },
  {
    "value": "44405",
    "label": "Campbell - OH 44405",
    "county": "Mahoning County",
    "state": "OH",
    "city": "Campbell"
  },
  {
    "value": "44406",
    "label": "Canfield - OH 44406",
    "county": "Mahoning County",
    "state": "OH",
    "city": "Canfield"
  },
  {
    "value": "44408",
    "label": "Columbiana - OH 44408",
    "county": "Columbiana County",
    "state": "OH",
    "city": "Columbiana"
  },
  {
    "value": "44410",
    "label": "Cortland - OH 44410",
    "county": "Trumbull County",
    "state": "OH",
    "city": "Cortland"
  },
  {
    "value": "44411",
    "label": "Deerfield - OH 44411",
    "county": "Portage County",
    "state": "OH",
    "city": "Deerfield"
  },
  {
    "value": "44412",
    "label": "Diamond - OH 44412",
    "county": "Portage County",
    "state": "OH",
    "city": "Diamond"
  },
  {
    "value": "44413",
    "label": "East Palestine - OH 44413",
    "county": "Columbiana County",
    "state": "OH",
    "city": "East Palestine"
  },
  {
    "value": "44417",
    "label": "Farmdale - OH 44417",
    "county": "Trumbull County",
    "state": "OH",
    "city": "Farmdale"
  },
  {
    "value": "44418",
    "label": "Fowler - OH 44418",
    "county": "Trumbull County",
    "state": "OH",
    "city": "Fowler"
  },
  {
    "value": "44420",
    "label": "Girard - OH 44420",
    "county": "Trumbull County",
    "state": "OH",
    "city": "Girard"
  },
  {
    "value": "44423",
    "label": "Hanoverton - OH 44423",
    "county": "Columbiana County",
    "state": "OH",
    "city": "Hanoverton"
  },
  {
    "value": "44425",
    "label": "Hubbard - OH 44425",
    "county": "Trumbull County",
    "state": "OH",
    "city": "Hubbard"
  },
  {
    "value": "44427",
    "label": "Kensington - OH 44427",
    "county": "Columbiana County",
    "state": "OH",
    "city": "Kensington"
  },
  {
    "value": "44428",
    "label": "Kinsman - OH 44428",
    "county": "Trumbull County",
    "state": "OH",
    "city": "Kinsman"
  },
  {
    "value": "44429",
    "label": "Lake Milton - OH 44429",
    "county": "Mahoning County",
    "state": "OH",
    "city": "Lake Milton"
  },
  {
    "value": "44430",
    "label": "Leavittsburg - OH 44430",
    "county": "Trumbull County",
    "state": "OH",
    "city": "Leavittsburg"
  },
  {
    "value": "44431",
    "label": "Leetonia - OH 44431",
    "county": "Columbiana County",
    "state": "OH",
    "city": "Leetonia"
  },
  {
    "value": "44432",
    "label": "Lisbon - OH 44432",
    "county": "Columbiana County",
    "state": "OH",
    "city": "Lisbon"
  },
  {
    "value": "44436",
    "label": "Lowellville - OH 44436",
    "county": "Mahoning County",
    "state": "OH",
    "city": "Lowellville"
  },
  {
    "value": "44437",
    "label": "Mc Donald - OH 44437",
    "county": "Trumbull County",
    "state": "OH",
    "city": "Mc Donald"
  },
  {
    "value": "44438",
    "label": "Masury - OH 44438",
    "county": "Trumbull County",
    "state": "OH",
    "city": "Masury"
  },
  {
    "value": "44440",
    "label": "Mineral Ridge - OH 44440",
    "county": "Trumbull County",
    "state": "OH",
    "city": "Mineral Ridge"
  },
  {
    "value": "44441",
    "label": "Negley - OH 44441",
    "county": "Columbiana County",
    "state": "OH",
    "city": "Negley"
  },
  {
    "value": "44442",
    "label": "New Middletown - OH 44442",
    "county": "Mahoning County",
    "state": "OH",
    "city": "New Middletown"
  },
  {
    "value": "44443",
    "label": "New Springfield - OH 44443",
    "county": "Mahoning County",
    "state": "OH",
    "city": "New Springfield"
  },
  {
    "value": "44444",
    "label": "Newton Falls - OH 44444",
    "county": "Trumbull County",
    "state": "OH",
    "city": "Newton Falls"
  },
  {
    "value": "44445",
    "label": "New Waterford - OH 44445",
    "county": "Columbiana County",
    "state": "OH",
    "city": "New Waterford"
  },
  {
    "value": "44446",
    "label": "Niles - OH 44446",
    "county": "Trumbull County",
    "state": "OH",
    "city": "Niles"
  },
  {
    "value": "44449",
    "label": "North Benton - OH 44449",
    "county": "Mahoning County",
    "state": "OH",
    "city": "North Benton"
  },
  {
    "value": "44450",
    "label": "North Bloomfield - OH 44450",
    "county": "Trumbull County",
    "state": "OH",
    "city": "North Bloomfield"
  },
  {
    "value": "44451",
    "label": "North Jackson - OH 44451",
    "county": "Mahoning County",
    "state": "OH",
    "city": "North Jackson"
  },
  {
    "value": "44452",
    "label": "North Lima - OH 44452",
    "county": "Mahoning County",
    "state": "OH",
    "city": "North Lima"
  },
  {
    "value": "44454",
    "label": "Petersburg - OH 44454",
    "county": "Mahoning County",
    "state": "OH",
    "city": "Petersburg"
  },
  {
    "value": "44455",
    "label": "Rogers - OH 44455",
    "county": "Columbiana County",
    "state": "OH",
    "city": "Rogers"
  },
  {
    "value": "44460",
    "label": "Salem - OH 44460",
    "county": "Columbiana County",
    "state": "OH",
    "city": "Salem"
  },
  {
    "value": "44470",
    "label": "Southington - OH 44470",
    "county": "Trumbull County",
    "state": "OH",
    "city": "Southington"
  },
  {
    "value": "44471",
    "label": "Struthers - OH 44471",
    "county": "Mahoning County",
    "state": "OH",
    "city": "Struthers"
  },
  {
    "value": "44473",
    "label": "Vienna - OH 44473",
    "county": "Trumbull County",
    "state": "OH",
    "city": "Vienna"
  },
  {
    "value": "44481",
    "label": "Warren - OH 44481",
    "county": "Trumbull County",
    "state": "OH",
    "city": "Warren"
  },
  {
    "value": "44483",
    "label": "Warren - OH 44483",
    "county": "Trumbull County",
    "state": "OH",
    "city": "Warren"
  },
  {
    "value": "44484",
    "label": "Warren - OH 44484",
    "county": "Trumbull County",
    "state": "OH",
    "city": "Warren"
  },
  {
    "value": "44485",
    "label": "Warren - OH 44485",
    "county": "Trumbull County",
    "state": "OH",
    "city": "Warren"
  },
  {
    "value": "44490",
    "label": "Washingtonville - OH 44490",
    "county": "Columbiana County",
    "state": "OH",
    "city": "Washingtonville"
  },
  {
    "value": "44491",
    "label": "West Farmington - OH 44491",
    "county": "Trumbull County",
    "state": "OH",
    "city": "West Farmington"
  },
  {
    "value": "44502",
    "label": "Youngstown - OH 44502",
    "county": "Mahoning County",
    "state": "OH",
    "city": "Youngstown"
  },
  {
    "value": "44503",
    "label": "Youngstown - OH 44503",
    "county": "Mahoning County",
    "state": "OH",
    "city": "Youngstown"
  },
  {
    "value": "44504",
    "label": "Youngstown - OH 44504",
    "county": "Mahoning County",
    "state": "OH",
    "city": "Youngstown"
  },
  {
    "value": "44505",
    "label": "Youngstown - OH 44505",
    "county": "Mahoning County",
    "state": "OH",
    "city": "Youngstown"
  },
  {
    "value": "44506",
    "label": "Youngstown - OH 44506",
    "county": "Mahoning County",
    "state": "OH",
    "city": "Youngstown"
  },
  {
    "value": "44507",
    "label": "Youngstown - OH 44507",
    "county": "Mahoning County",
    "state": "OH",
    "city": "Youngstown"
  },
  {
    "value": "44509",
    "label": "Youngstown - OH 44509",
    "county": "Mahoning County",
    "state": "OH",
    "city": "Youngstown"
  },
  {
    "value": "44510",
    "label": "Youngstown - OH 44510",
    "county": "Mahoning County",
    "state": "OH",
    "city": "Youngstown"
  },
  {
    "value": "44511",
    "label": "Youngstown - OH 44511",
    "county": "Mahoning County",
    "state": "OH",
    "city": "Youngstown"
  },
  {
    "value": "44512",
    "label": "Youngstown - OH 44512",
    "county": "Mahoning County",
    "state": "OH",
    "city": "Youngstown"
  },
  {
    "value": "44514",
    "label": "Youngstown - OH 44514",
    "county": "Mahoning County",
    "state": "OH",
    "city": "Youngstown"
  },
  {
    "value": "44515",
    "label": "Youngstown - OH 44515",
    "county": "Mahoning County",
    "state": "OH",
    "city": "Youngstown"
  },
  {
    "value": "44601",
    "label": "Alliance - OH 44601",
    "county": "Stark County",
    "state": "OH",
    "city": "Alliance"
  },
  {
    "value": "44606",
    "label": "Apple Creek - OH 44606",
    "county": "Wayne County",
    "state": "OH",
    "city": "Apple Creek"
  },
  {
    "value": "44608",
    "label": "Beach City - OH 44608",
    "county": "Stark County",
    "state": "OH",
    "city": "Beach City"
  },
  {
    "value": "44609",
    "label": "Beloit - OH 44609",
    "county": "Mahoning County",
    "state": "OH",
    "city": "Beloit"
  },
  {
    "value": "44611",
    "label": "Big Prairie - OH 44611",
    "county": "Holmes County",
    "state": "OH",
    "city": "Big Prairie"
  },
  {
    "value": "44612",
    "label": "Bolivar - OH 44612",
    "county": "Tuscarawas County",
    "state": "OH",
    "city": "Bolivar"
  },
  {
    "value": "44613",
    "label": "Brewster - OH 44613",
    "county": "Stark County",
    "state": "OH",
    "city": "Brewster"
  },
  {
    "value": "44614",
    "label": "Canal Fulton - OH 44614",
    "county": "Stark County",
    "state": "OH",
    "city": "Canal Fulton"
  },
  {
    "value": "44615",
    "label": "Carrollton - OH 44615",
    "county": "Carroll County",
    "state": "OH",
    "city": "Carrollton"
  },
  {
    "value": "44618",
    "label": "Dalton - OH 44618",
    "county": "Wayne County",
    "state": "OH",
    "city": "Dalton"
  },
  {
    "value": "44620",
    "label": "Dellroy - OH 44620",
    "county": "Carroll County",
    "state": "OH",
    "city": "Dellroy"
  },
  {
    "value": "44621",
    "label": "Dennison - OH 44621",
    "county": "Tuscarawas County",
    "state": "OH",
    "city": "Dennison"
  },
  {
    "value": "44622",
    "label": "Dover - OH 44622",
    "county": "Tuscarawas County",
    "state": "OH",
    "city": "Dover"
  },
  {
    "value": "44624",
    "label": "Dundee - OH 44624",
    "county": "Holmes County",
    "state": "OH",
    "city": "Dundee"
  },
  {
    "value": "44625",
    "label": "East Rochester - OH 44625",
    "county": "Columbiana County",
    "state": "OH",
    "city": "East Rochester"
  },
  {
    "value": "44626",
    "label": "East Sparta - OH 44626",
    "county": "Stark County",
    "state": "OH",
    "city": "East Sparta"
  },
  {
    "value": "44627",
    "label": "Fredericksburg - OH 44627",
    "county": "Wayne County",
    "state": "OH",
    "city": "Fredericksburg"
  },
  {
    "value": "44628",
    "label": "Glenmont - OH 44628",
    "county": "Holmes County",
    "state": "OH",
    "city": "Glenmont"
  },
  {
    "value": "44629",
    "label": "Gnadenhutten - OH 44629",
    "county": "Tuscarawas County",
    "state": "OH",
    "city": "Gnadenhutten"
  },
  {
    "value": "44631",
    "label": "Harlem Springs - OH 44631",
    "county": "Carroll County",
    "state": "OH",
    "city": "Harlem Springs"
  },
  {
    "value": "44632",
    "label": "Hartville - OH 44632",
    "county": "Stark County",
    "state": "OH",
    "city": "Hartville"
  },
  {
    "value": "44633",
    "label": "Holmesville - OH 44633",
    "county": "Holmes County",
    "state": "OH",
    "city": "Holmesville"
  },
  {
    "value": "44634",
    "label": "Homeworth - OH 44634",
    "county": "Columbiana County",
    "state": "OH",
    "city": "Homeworth"
  },
  {
    "value": "44637",
    "label": "Killbuck - OH 44637",
    "county": "Holmes County",
    "state": "OH",
    "city": "Killbuck"
  },
  {
    "value": "44638",
    "label": "Lakeville - OH 44638",
    "county": "Holmes County",
    "state": "OH",
    "city": "Lakeville"
  },
  {
    "value": "44641",
    "label": "Louisville - OH 44641",
    "county": "Stark County",
    "state": "OH",
    "city": "Louisville"
  },
  {
    "value": "44643",
    "label": "Magnolia - OH 44643",
    "county": "Stark County",
    "state": "OH",
    "city": "Magnolia"
  },
  {
    "value": "44644",
    "label": "Malvern - OH 44644",
    "county": "Carroll County",
    "state": "OH",
    "city": "Malvern"
  },
  {
    "value": "44645",
    "label": "Marshallville - OH 44645",
    "county": "Wayne County",
    "state": "OH",
    "city": "Marshallville"
  },
  {
    "value": "44646",
    "label": "Massillon - OH 44646",
    "county": "Stark County",
    "state": "OH",
    "city": "Massillon"
  },
  {
    "value": "44647",
    "label": "Massillon - OH 44647",
    "county": "Stark County",
    "state": "OH",
    "city": "Massillon"
  },
  {
    "value": "44651",
    "label": "Mechanicstown - OH 44651",
    "county": "Carroll County",
    "state": "OH",
    "city": "Mechanicstown"
  },
  {
    "value": "44654",
    "label": "Millersburg - OH 44654",
    "county": "Holmes County",
    "state": "OH",
    "city": "Millersburg"
  },
  {
    "value": "44656",
    "label": "Mineral City - OH 44656",
    "county": "Tuscarawas County",
    "state": "OH",
    "city": "Mineral City"
  },
  {
    "value": "44657",
    "label": "Minerva - OH 44657",
    "county": "Stark County",
    "state": "OH",
    "city": "Minerva"
  },
  {
    "value": "44662",
    "label": "Navarre - OH 44662",
    "county": "Stark County",
    "state": "OH",
    "city": "Navarre"
  },
  {
    "value": "44663",
    "label": "New Philadelphia - OH 44663",
    "county": "Tuscarawas County",
    "state": "OH",
    "city": "New Philadelphia"
  },
  {
    "value": "44666",
    "label": "North Lawrence - OH 44666",
    "county": "Stark County",
    "state": "OH",
    "city": "North Lawrence"
  },
  {
    "value": "44667",
    "label": "Orrville - OH 44667",
    "county": "Wayne County",
    "state": "OH",
    "city": "Orrville"
  },
  {
    "value": "44669",
    "label": "Paris - OH 44669",
    "county": "Stark County",
    "state": "OH",
    "city": "Paris"
  },
  {
    "value": "44672",
    "label": "Sebring - OH 44672",
    "county": "Mahoning County",
    "state": "OH",
    "city": "Sebring"
  },
  {
    "value": "44675",
    "label": "Sherrodsville - OH 44675",
    "county": "Carroll County",
    "state": "OH",
    "city": "Sherrodsville"
  },
  {
    "value": "44676",
    "label": "Shreve - OH 44676",
    "county": "Wayne County",
    "state": "OH",
    "city": "Shreve"
  },
  {
    "value": "44677",
    "label": "Smithville - OH 44677",
    "county": "Wayne County",
    "state": "OH",
    "city": "Smithville"
  },
  {
    "value": "44680",
    "label": "Strasburg - OH 44680",
    "county": "Tuscarawas County",
    "state": "OH",
    "city": "Strasburg"
  },
  {
    "value": "44681",
    "label": "Sugarcreek - OH 44681",
    "county": "Tuscarawas County",
    "state": "OH",
    "city": "Sugarcreek"
  },
  {
    "value": "44683",
    "label": "Uhrichsville - OH 44683",
    "county": "Tuscarawas County",
    "state": "OH",
    "city": "Uhrichsville"
  },
  {
    "value": "44685",
    "label": "Uniontown - OH 44685",
    "county": "Summit County",
    "state": "OH",
    "city": "Uniontown"
  },
  {
    "value": "44688",
    "label": "Waynesburg - OH 44688",
    "county": "Stark County",
    "state": "OH",
    "city": "Waynesburg"
  },
  {
    "value": "44689",
    "label": "Wilmot - OH 44689",
    "county": "Stark County",
    "state": "OH",
    "city": "Wilmot"
  },
  {
    "value": "44691",
    "label": "Wooster - OH 44691",
    "county": "Wayne County",
    "state": "OH",
    "city": "Wooster"
  },
  {
    "value": "44695",
    "label": "Bowerston - OH 44695",
    "county": "Carroll County",
    "state": "OH",
    "city": "Bowerston"
  },
  {
    "value": "44699",
    "label": "Tippecanoe - OH 44699",
    "county": "Harrison County",
    "state": "OH",
    "city": "Tippecanoe"
  },
  {
    "value": "44702",
    "label": "Canton - OH 44702",
    "county": "Stark County",
    "state": "OH",
    "city": "Canton"
  },
  {
    "value": "44703",
    "label": "Canton - OH 44703",
    "county": "Stark County",
    "state": "OH",
    "city": "Canton"
  },
  {
    "value": "44704",
    "label": "Canton - OH 44704",
    "county": "Stark County",
    "state": "OH",
    "city": "Canton"
  },
  {
    "value": "44705",
    "label": "Canton - OH 44705",
    "county": "Stark County",
    "state": "OH",
    "city": "Canton"
  },
  {
    "value": "44706",
    "label": "Canton - OH 44706",
    "county": "Stark County",
    "state": "OH",
    "city": "Canton"
  },
  {
    "value": "44707",
    "label": "Canton - OH 44707",
    "county": "Stark County",
    "state": "OH",
    "city": "Canton"
  },
  {
    "value": "44708",
    "label": "Canton - OH 44708",
    "county": "Stark County",
    "state": "OH",
    "city": "Canton"
  },
  {
    "value": "44709",
    "label": "Canton - OH 44709",
    "county": "Stark County",
    "state": "OH",
    "city": "Canton"
  },
  {
    "value": "44710",
    "label": "Canton - OH 44710",
    "county": "Stark County",
    "state": "OH",
    "city": "Canton"
  },
  {
    "value": "44714",
    "label": "Canton - OH 44714",
    "county": "Stark County",
    "state": "OH",
    "city": "Canton"
  },
  {
    "value": "44718",
    "label": "Canton - OH 44718",
    "county": "Stark County",
    "state": "OH",
    "city": "Canton"
  },
  {
    "value": "44720",
    "label": "North Canton - OH 44720",
    "county": "Stark County",
    "state": "OH",
    "city": "North Canton"
  },
  {
    "value": "44721",
    "label": "Canton - OH 44721",
    "county": "Stark County",
    "state": "OH",
    "city": "Canton"
  },
  {
    "value": "44730",
    "label": "East Canton - OH 44730",
    "county": "Stark County",
    "state": "OH",
    "city": "East Canton"
  },
  {
    "value": "44802",
    "label": "Alvada - OH 44802",
    "county": "Seneca County",
    "state": "OH",
    "city": "Alvada"
  },
  {
    "value": "44804",
    "label": "Arcadia - OH 44804",
    "county": "Hancock County",
    "state": "OH",
    "city": "Arcadia"
  },
  {
    "value": "44805",
    "label": "Ashland - OH 44805",
    "county": "Ashland County",
    "state": "OH",
    "city": "Ashland"
  },
  {
    "value": "44807",
    "label": "Attica - OH 44807",
    "county": "Seneca County",
    "state": "OH",
    "city": "Attica"
  },
  {
    "value": "44811",
    "label": "Bellevue - OH 44811",
    "county": "Sandusky County",
    "state": "OH",
    "city": "Bellevue"
  },
  {
    "value": "44813",
    "label": "Bellville - OH 44813",
    "county": "Richland County",
    "state": "OH",
    "city": "Bellville"
  },
  {
    "value": "44814",
    "label": "Berlin Heights - OH 44814",
    "county": "Erie County",
    "state": "OH",
    "city": "Berlin Heights"
  },
  {
    "value": "44817",
    "label": "Bloomdale - OH 44817",
    "county": "Wood County",
    "state": "OH",
    "city": "Bloomdale"
  },
  {
    "value": "44818",
    "label": "Bloomville - OH 44818",
    "county": "Seneca County",
    "state": "OH",
    "city": "Bloomville"
  },
  {
    "value": "44820",
    "label": "Bucyrus - OH 44820",
    "county": "Crawford County",
    "state": "OH",
    "city": "Bucyrus"
  },
  {
    "value": "44822",
    "label": "Butler - OH 44822",
    "county": "Richland County",
    "state": "OH",
    "city": "Butler"
  },
  {
    "value": "44824",
    "label": "Castalia - OH 44824",
    "county": "Erie County",
    "state": "OH",
    "city": "Castalia"
  },
  {
    "value": "44826",
    "label": "Collins - OH 44826",
    "county": "Huron County",
    "state": "OH",
    "city": "Collins"
  },
  {
    "value": "44827",
    "label": "Crestline - OH 44827",
    "county": "Crawford County",
    "state": "OH",
    "city": "Crestline"
  },
  {
    "value": "44830",
    "label": "Fostoria - OH 44830",
    "county": "Seneca County",
    "state": "OH",
    "city": "Fostoria"
  },
  {
    "value": "44833",
    "label": "Galion - OH 44833",
    "county": "Crawford County",
    "state": "OH",
    "city": "Galion"
  },
  {
    "value": "44836",
    "label": "Green Springs - OH 44836",
    "county": "Seneca County",
    "state": "OH",
    "city": "Green Springs"
  },
  {
    "value": "44837",
    "label": "Greenwich - OH 44837",
    "county": "Huron County",
    "state": "OH",
    "city": "Greenwich"
  },
  {
    "value": "44839",
    "label": "Huron - OH 44839",
    "county": "Erie County",
    "state": "OH",
    "city": "Huron"
  },
  {
    "value": "44840",
    "label": "Jeromesville - OH 44840",
    "county": "Ashland County",
    "state": "OH",
    "city": "Jeromesville"
  },
  {
    "value": "44841",
    "label": "Kansas - OH 44841",
    "county": "Seneca County",
    "state": "OH",
    "city": "Kansas"
  },
  {
    "value": "44842",
    "label": "Loudonville - OH 44842",
    "county": "Ashland County",
    "state": "OH",
    "city": "Loudonville"
  },
  {
    "value": "44843",
    "label": "Lucas - OH 44843",
    "county": "Richland County",
    "state": "OH",
    "city": "Lucas"
  },
  {
    "value": "44844",
    "label": "Mc Cutchenville - OH 44844",
    "county": "Wyandot County",
    "state": "OH",
    "city": "Mc Cutchenville"
  },
  {
    "value": "44846",
    "label": "Milan - OH 44846",
    "county": "Erie County",
    "state": "OH",
    "city": "Milan"
  },
  {
    "value": "44847",
    "label": "Monroeville - OH 44847",
    "county": "Huron County",
    "state": "OH",
    "city": "Monroeville"
  },
  {
    "value": "44849",
    "label": "Nevada - OH 44849",
    "county": "Wyandot County",
    "state": "OH",
    "city": "Nevada"
  },
  {
    "value": "44851",
    "label": "New London - OH 44851",
    "county": "Huron County",
    "state": "OH",
    "city": "New London"
  },
  {
    "value": "44853",
    "label": "New Riegel - OH 44853",
    "county": "Seneca County",
    "state": "OH",
    "city": "New Riegel"
  },
  {
    "value": "44854",
    "label": "New Washington - OH 44854",
    "county": "Crawford County",
    "state": "OH",
    "city": "New Washington"
  },
  {
    "value": "44855",
    "label": "North Fairfield - OH 44855",
    "county": "Huron County",
    "state": "OH",
    "city": "North Fairfield"
  },
  {
    "value": "44857",
    "label": "Norwalk - OH 44857",
    "county": "Huron County",
    "state": "OH",
    "city": "Norwalk"
  },
  {
    "value": "44859",
    "label": "Nova - OH 44859",
    "county": "Ashland County",
    "state": "OH",
    "city": "Nova"
  },
  {
    "value": "44864",
    "label": "Perrysville - OH 44864",
    "county": "Ashland County",
    "state": "OH",
    "city": "Perrysville"
  },
  {
    "value": "44865",
    "label": "Plymouth - OH 44865",
    "county": "Huron County",
    "state": "OH",
    "city": "Plymouth"
  },
  {
    "value": "44866",
    "label": "Polk - OH 44866",
    "county": "Ashland County",
    "state": "OH",
    "city": "Polk"
  },
  {
    "value": "44867",
    "label": "Republic - OH 44867",
    "county": "Seneca County",
    "state": "OH",
    "city": "Republic"
  },
  {
    "value": "44870",
    "label": "Sandusky - OH 44870",
    "county": "Erie County",
    "state": "OH",
    "city": "Sandusky"
  },
  {
    "value": "44875",
    "label": "Shelby - OH 44875",
    "county": "Richland County",
    "state": "OH",
    "city": "Shelby"
  },
  {
    "value": "44878",
    "label": "Shiloh - OH 44878",
    "county": "Richland County",
    "state": "OH",
    "city": "Shiloh"
  },
  {
    "value": "44880",
    "label": "Sullivan - OH 44880",
    "county": "Ashland County",
    "state": "OH",
    "city": "Sullivan"
  },
  {
    "value": "44882",
    "label": "Sycamore - OH 44882",
    "county": "Wyandot County",
    "state": "OH",
    "city": "Sycamore"
  },
  {
    "value": "44883",
    "label": "Tiffin - OH 44883",
    "county": "Seneca County",
    "state": "OH",
    "city": "Tiffin"
  },
  {
    "value": "44887",
    "label": "Tiro - OH 44887",
    "county": "Crawford County",
    "state": "OH",
    "city": "Tiro"
  },
  {
    "value": "44889",
    "label": "Wakeman - OH 44889",
    "county": "Huron County",
    "state": "OH",
    "city": "Wakeman"
  },
  {
    "value": "44890",
    "label": "Willard - OH 44890",
    "county": "Huron County",
    "state": "OH",
    "city": "Willard"
  },
  {
    "value": "44902",
    "label": "Mansfield - OH 44902",
    "county": "Richland County",
    "state": "OH",
    "city": "Mansfield"
  },
  {
    "value": "44903",
    "label": "Mansfield - OH 44903",
    "county": "Richland County",
    "state": "OH",
    "city": "Mansfield"
  },
  {
    "value": "44904",
    "label": "Mansfield - OH 44904",
    "county": "Richland County",
    "state": "OH",
    "city": "Mansfield"
  },
  {
    "value": "44905",
    "label": "Mansfield - OH 44905",
    "county": "Richland County",
    "state": "OH",
    "city": "Mansfield"
  },
  {
    "value": "44906",
    "label": "Mansfield - OH 44906",
    "county": "Richland County",
    "state": "OH",
    "city": "Mansfield"
  },
  {
    "value": "44907",
    "label": "Mansfield - OH 44907",
    "county": "Richland County",
    "state": "OH",
    "city": "Mansfield"
  },
  {
    "value": "45002",
    "label": "Cleves - OH 45002",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cleves"
  },
  {
    "value": "45003",
    "label": "College Corner - OH 45003",
    "county": "Preble County",
    "state": "OH",
    "city": "College Corner"
  },
  {
    "value": "45005",
    "label": "Franklin - OH 45005",
    "county": "Warren County",
    "state": "OH",
    "city": "Franklin"
  },
  {
    "value": "45011",
    "label": "Hamilton - OH 45011",
    "county": "Butler County",
    "state": "OH",
    "city": "Hamilton"
  },
  {
    "value": "45013",
    "label": "Hamilton - OH 45013",
    "county": "Butler County",
    "state": "OH",
    "city": "Hamilton"
  },
  {
    "value": "45014",
    "label": "Fairfield - OH 45014",
    "county": "Butler County",
    "state": "OH",
    "city": "Fairfield"
  },
  {
    "value": "45015",
    "label": "Hamilton - OH 45015",
    "county": "Butler County",
    "state": "OH",
    "city": "Hamilton"
  },
  {
    "value": "45030",
    "label": "Harrison - OH 45030",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Harrison"
  },
  {
    "value": "45034",
    "label": "Kings Mills - OH 45034",
    "county": "Warren County",
    "state": "OH",
    "city": "Kings Mills"
  },
  {
    "value": "45036",
    "label": "Lebanon - OH 45036",
    "county": "Warren County",
    "state": "OH",
    "city": "Lebanon"
  },
  {
    "value": "45039",
    "label": "Maineville - OH 45039",
    "county": "Warren County",
    "state": "OH",
    "city": "Maineville"
  },
  {
    "value": "45040",
    "label": "Mason - OH 45040",
    "county": "Warren County",
    "state": "OH",
    "city": "Mason"
  },
  {
    "value": "45042",
    "label": "Middletown - OH 45042",
    "county": "Butler County",
    "state": "OH",
    "city": "Middletown"
  },
  {
    "value": "45044",
    "label": "Middletown - OH 45044",
    "county": "Butler County",
    "state": "OH",
    "city": "Middletown"
  },
  {
    "value": "45050",
    "label": "Monroe - OH 45050",
    "county": "Butler County",
    "state": "OH",
    "city": "Monroe"
  },
  {
    "value": "45051",
    "label": "Mount Saint Joseph - OH 45051",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Mount Saint Joseph"
  },
  {
    "value": "45052",
    "label": "North Bend - OH 45052",
    "county": "Hamilton County",
    "state": "OH",
    "city": "North Bend"
  },
  {
    "value": "45053",
    "label": "Okeana - OH 45053",
    "county": "Butler County",
    "state": "OH",
    "city": "Okeana"
  },
  {
    "value": "45054",
    "label": "Oregonia - OH 45054",
    "county": "Warren County",
    "state": "OH",
    "city": "Oregonia"
  },
  {
    "value": "45056",
    "label": "Oxford - OH 45056",
    "county": "Butler County",
    "state": "OH",
    "city": "Oxford"
  },
  {
    "value": "45064",
    "label": "Somerville - OH 45064",
    "county": "Butler County",
    "state": "OH",
    "city": "Somerville"
  },
  {
    "value": "45065",
    "label": "South Lebanon - OH 45065",
    "county": "Warren County",
    "state": "OH",
    "city": "South Lebanon"
  },
  {
    "value": "45066",
    "label": "Springboro - OH 45066",
    "county": "Warren County",
    "state": "OH",
    "city": "Springboro"
  },
  {
    "value": "45067",
    "label": "Trenton - OH 45067",
    "county": "Butler County",
    "state": "OH",
    "city": "Trenton"
  },
  {
    "value": "45068",
    "label": "Waynesville - OH 45068",
    "county": "Warren County",
    "state": "OH",
    "city": "Waynesville"
  },
  {
    "value": "45069",
    "label": "West Chester - OH 45069",
    "county": "Butler County",
    "state": "OH",
    "city": "West Chester"
  },
  {
    "value": "45101",
    "label": "Aberdeen - OH 45101",
    "county": "Brown County",
    "state": "OH",
    "city": "Aberdeen"
  },
  {
    "value": "45102",
    "label": "Amelia - OH 45102",
    "county": "Clermont County",
    "state": "OH",
    "city": "Amelia"
  },
  {
    "value": "45103",
    "label": "Batavia - OH 45103",
    "county": "Clermont County",
    "state": "OH",
    "city": "Batavia"
  },
  {
    "value": "45106",
    "label": "Bethel - OH 45106",
    "county": "Clermont County",
    "state": "OH",
    "city": "Bethel"
  },
  {
    "value": "45107",
    "label": "Blanchester - OH 45107",
    "county": "Clinton County",
    "state": "OH",
    "city": "Blanchester"
  },
  {
    "value": "45111",
    "label": "Camp Dennison - OH 45111",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Camp Dennison"
  },
  {
    "value": "45113",
    "label": "Clarksville - OH 45113",
    "county": "Clinton County",
    "state": "OH",
    "city": "Clarksville"
  },
  {
    "value": "45118",
    "label": "Fayetteville - OH 45118",
    "county": "Brown County",
    "state": "OH",
    "city": "Fayetteville"
  },
  {
    "value": "45120",
    "label": "Felicity - OH 45120",
    "county": "Clermont County",
    "state": "OH",
    "city": "Felicity"
  },
  {
    "value": "45121",
    "label": "Georgetown - OH 45121",
    "county": "Brown County",
    "state": "OH",
    "city": "Georgetown"
  },
  {
    "value": "45122",
    "label": "Goshen - OH 45122",
    "county": "Clermont County",
    "state": "OH",
    "city": "Goshen"
  },
  {
    "value": "45123",
    "label": "Greenfield - OH 45123",
    "county": "Highland County",
    "state": "OH",
    "city": "Greenfield"
  },
  {
    "value": "45130",
    "label": "Hamersville - OH 45130",
    "county": "Brown County",
    "state": "OH",
    "city": "Hamersville"
  },
  {
    "value": "45133",
    "label": "Hillsboro - OH 45133",
    "county": "Highland County",
    "state": "OH",
    "city": "Hillsboro"
  },
  {
    "value": "45135",
    "label": "Leesburg - OH 45135",
    "county": "Highland County",
    "state": "OH",
    "city": "Leesburg"
  },
  {
    "value": "45140",
    "label": "Loveland - OH 45140",
    "county": "Clermont County",
    "state": "OH",
    "city": "Loveland"
  },
  {
    "value": "45142",
    "label": "Lynchburg - OH 45142",
    "county": "Highland County",
    "state": "OH",
    "city": "Lynchburg"
  },
  {
    "value": "45144",
    "label": "Manchester - OH 45144",
    "county": "Adams County",
    "state": "OH",
    "city": "Manchester"
  },
  {
    "value": "45146",
    "label": "Martinsville - OH 45146",
    "county": "Clinton County",
    "state": "OH",
    "city": "Martinsville"
  },
  {
    "value": "45148",
    "label": "Midland - OH 45148",
    "county": "Clinton County",
    "state": "OH",
    "city": "Midland"
  },
  {
    "value": "45150",
    "label": "Milford - OH 45150",
    "county": "Clermont County",
    "state": "OH",
    "city": "Milford"
  },
  {
    "value": "45152",
    "label": "Morrow - OH 45152",
    "county": "Warren County",
    "state": "OH",
    "city": "Morrow"
  },
  {
    "value": "45153",
    "label": "Moscow - OH 45153",
    "county": "Clermont County",
    "state": "OH",
    "city": "Moscow"
  },
  {
    "value": "45154",
    "label": "Mount Orab - OH 45154",
    "county": "Brown County",
    "state": "OH",
    "city": "Mount Orab"
  },
  {
    "value": "45157",
    "label": "New Richmond - OH 45157",
    "county": "Clermont County",
    "state": "OH",
    "city": "New Richmond"
  },
  {
    "value": "45159",
    "label": "New Vienna - OH 45159",
    "county": "Clinton County",
    "state": "OH",
    "city": "New Vienna"
  },
  {
    "value": "45160",
    "label": "Owensville - OH 45160",
    "county": "Clermont County",
    "state": "OH",
    "city": "Owensville"
  },
  {
    "value": "45162",
    "label": "Pleasant Plain - OH 45162",
    "county": "Warren County",
    "state": "OH",
    "city": "Pleasant Plain"
  },
  {
    "value": "45167",
    "label": "Ripley - OH 45167",
    "county": "Brown County",
    "state": "OH",
    "city": "Ripley"
  },
  {
    "value": "45168",
    "label": "Russellville - OH 45168",
    "county": "Brown County",
    "state": "OH",
    "city": "Russellville"
  },
  {
    "value": "45169",
    "label": "Sabina - OH 45169",
    "county": "Clinton County",
    "state": "OH",
    "city": "Sabina"
  },
  {
    "value": "45171",
    "label": "Sardinia - OH 45171",
    "county": "Brown County",
    "state": "OH",
    "city": "Sardinia"
  },
  {
    "value": "45174",
    "label": "Terrace Park - OH 45174",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Terrace Park"
  },
  {
    "value": "45176",
    "label": "Williamsburg - OH 45176",
    "county": "Clermont County",
    "state": "OH",
    "city": "Williamsburg"
  },
  {
    "value": "45177",
    "label": "Wilmington - OH 45177",
    "county": "Clinton County",
    "state": "OH",
    "city": "Wilmington"
  },
  {
    "value": "45202",
    "label": "Cincinnati - OH 45202",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45203",
    "label": "Cincinnati - OH 45203",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45204",
    "label": "Cincinnati - OH 45204",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45205",
    "label": "Cincinnati - OH 45205",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45206",
    "label": "Cincinnati - OH 45206",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45207",
    "label": "Cincinnati - OH 45207",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45208",
    "label": "Cincinnati - OH 45208",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45209",
    "label": "Cincinnati - OH 45209",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45211",
    "label": "Cincinnati - OH 45211",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45212",
    "label": "Cincinnati - OH 45212",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45213",
    "label": "Cincinnati - OH 45213",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45214",
    "label": "Cincinnati - OH 45214",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45215",
    "label": "Cincinnati - OH 45215",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45216",
    "label": "Cincinnati - OH 45216",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45217",
    "label": "Cincinnati - OH 45217",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45218",
    "label": "Cincinnati - OH 45218",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45219",
    "label": "Cincinnati - OH 45219",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45220",
    "label": "Cincinnati - OH 45220",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45223",
    "label": "Cincinnati - OH 45223",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45224",
    "label": "Cincinnati - OH 45224",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45225",
    "label": "Cincinnati - OH 45225",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45226",
    "label": "Cincinnati - OH 45226",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45227",
    "label": "Cincinnati - OH 45227",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45228",
    "label": "Cincinnati - OH 45228",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45229",
    "label": "Cincinnati - OH 45229",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45230",
    "label": "Cincinnati - OH 45230",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45231",
    "label": "Cincinnati - OH 45231",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45232",
    "label": "Cincinnati - OH 45232",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45233",
    "label": "Cincinnati - OH 45233",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45234",
    "label": "Cincinnati - OH 45234",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45235",
    "label": "Cincinnati - OH 45235",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45236",
    "label": "Cincinnati - OH 45236",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45237",
    "label": "Cincinnati - OH 45237",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45238",
    "label": "Cincinnati - OH 45238",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45239",
    "label": "Cincinnati - OH 45239",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45240",
    "label": "Cincinnati - OH 45240",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45241",
    "label": "Cincinnati - OH 45241",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45242",
    "label": "Cincinnati - OH 45242",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45243",
    "label": "Cincinnati - OH 45243",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45244",
    "label": "Cincinnati - OH 45244",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45245",
    "label": "Cincinnati - OH 45245",
    "county": "Clermont County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45246",
    "label": "Cincinnati - OH 45246",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45247",
    "label": "Cincinnati - OH 45247",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45248",
    "label": "Cincinnati - OH 45248",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45249",
    "label": "Cincinnati - OH 45249",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45251",
    "label": "Cincinnati - OH 45251",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45252",
    "label": "Cincinnati - OH 45252",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45255",
    "label": "Cincinnati - OH 45255",
    "county": "Hamilton County",
    "state": "OH",
    "city": "Cincinnati"
  },
  {
    "value": "45302",
    "label": "Anna - OH 45302",
    "county": "Shelby County",
    "state": "OH",
    "city": "Anna"
  },
  {
    "value": "45303",
    "label": "Ansonia - OH 45303",
    "county": "Darke County",
    "state": "OH",
    "city": "Ansonia"
  },
  {
    "value": "45304",
    "label": "Arcanum - OH 45304",
    "county": "Darke County",
    "state": "OH",
    "city": "Arcanum"
  },
  {
    "value": "45305",
    "label": "Bellbrook - OH 45305",
    "county": "Greene County",
    "state": "OH",
    "city": "Bellbrook"
  },
  {
    "value": "45306",
    "label": "Botkins - OH 45306",
    "county": "Shelby County",
    "state": "OH",
    "city": "Botkins"
  },
  {
    "value": "45308",
    "label": "Bradford - OH 45308",
    "county": "Darke County",
    "state": "OH",
    "city": "Bradford"
  },
  {
    "value": "45309",
    "label": "Brookville - OH 45309",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Brookville"
  },
  {
    "value": "45311",
    "label": "Camden - OH 45311",
    "county": "Preble County",
    "state": "OH",
    "city": "Camden"
  },
  {
    "value": "45312",
    "label": "Casstown - OH 45312",
    "county": "Miami County",
    "state": "OH",
    "city": "Casstown"
  },
  {
    "value": "45314",
    "label": "Cedarville - OH 45314",
    "county": "Greene County",
    "state": "OH",
    "city": "Cedarville"
  },
  {
    "value": "45315",
    "label": "Clayton - OH 45315",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Clayton"
  },
  {
    "value": "45317",
    "label": "Conover - OH 45317",
    "county": "Champaign County",
    "state": "OH",
    "city": "Conover"
  },
  {
    "value": "45318",
    "label": "Covington - OH 45318",
    "county": "Miami County",
    "state": "OH",
    "city": "Covington"
  },
  {
    "value": "45320",
    "label": "Eaton - OH 45320",
    "county": "Preble County",
    "state": "OH",
    "city": "Eaton"
  },
  {
    "value": "45321",
    "label": "Eldorado - OH 45321",
    "county": "Preble County",
    "state": "OH",
    "city": "Eldorado"
  },
  {
    "value": "45322",
    "label": "Englewood - OH 45322",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Englewood"
  },
  {
    "value": "45323",
    "label": "Enon - OH 45323",
    "county": "Clark County",
    "state": "OH",
    "city": "Enon"
  },
  {
    "value": "45324",
    "label": "Fairborn - OH 45324",
    "county": "Greene County",
    "state": "OH",
    "city": "Fairborn"
  },
  {
    "value": "45325",
    "label": "Farmersville - OH 45325",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Farmersville"
  },
  {
    "value": "45326",
    "label": "Fletcher - OH 45326",
    "county": "Miami County",
    "state": "OH",
    "city": "Fletcher"
  },
  {
    "value": "45327",
    "label": "Germantown - OH 45327",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Germantown"
  },
  {
    "value": "45331",
    "label": "Greenville - OH 45331",
    "county": "Darke County",
    "state": "OH",
    "city": "Greenville"
  },
  {
    "value": "45332",
    "label": "Hollansburg - OH 45332",
    "county": "Darke County",
    "state": "OH",
    "city": "Hollansburg"
  },
  {
    "value": "45333",
    "label": "Houston - OH 45333",
    "county": "Shelby County",
    "state": "OH",
    "city": "Houston"
  },
  {
    "value": "45334",
    "label": "Jackson Center - OH 45334",
    "county": "Shelby County",
    "state": "OH",
    "city": "Jackson Center"
  },
  {
    "value": "45335",
    "label": "Jamestown - OH 45335",
    "county": "Greene County",
    "state": "OH",
    "city": "Jamestown"
  },
  {
    "value": "45337",
    "label": "Laura - OH 45337",
    "county": "Miami County",
    "state": "OH",
    "city": "Laura"
  },
  {
    "value": "45338",
    "label": "Lewisburg - OH 45338",
    "county": "Preble County",
    "state": "OH",
    "city": "Lewisburg"
  },
  {
    "value": "45339",
    "label": "Ludlow Falls - OH 45339",
    "county": "Miami County",
    "state": "OH",
    "city": "Ludlow Falls"
  },
  {
    "value": "45340",
    "label": "Maplewood - OH 45340",
    "county": "Shelby County",
    "state": "OH",
    "city": "Maplewood"
  },
  {
    "value": "45341",
    "label": "Medway - OH 45341",
    "county": "Clark County",
    "state": "OH",
    "city": "Medway"
  },
  {
    "value": "45342",
    "label": "Miamisburg - OH 45342",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Miamisburg"
  },
  {
    "value": "45344",
    "label": "New Carlisle - OH 45344",
    "county": "Clark County",
    "state": "OH",
    "city": "New Carlisle"
  },
  {
    "value": "45345",
    "label": "New Lebanon - OH 45345",
    "county": "Montgomery County",
    "state": "OH",
    "city": "New Lebanon"
  },
  {
    "value": "45346",
    "label": "New Madison - OH 45346",
    "county": "Darke County",
    "state": "OH",
    "city": "New Madison"
  },
  {
    "value": "45347",
    "label": "New Paris - OH 45347",
    "county": "Preble County",
    "state": "OH",
    "city": "New Paris"
  },
  {
    "value": "45348",
    "label": "New Weston - OH 45348",
    "county": "Darke County",
    "state": "OH",
    "city": "New Weston"
  },
  {
    "value": "45356",
    "label": "Piqua - OH 45356",
    "county": "Miami County",
    "state": "OH",
    "city": "Piqua"
  },
  {
    "value": "45359",
    "label": "Pleasant Hill - OH 45359",
    "county": "Miami County",
    "state": "OH",
    "city": "Pleasant Hill"
  },
  {
    "value": "45362",
    "label": "Rossburg - OH 45362",
    "county": "Darke County",
    "state": "OH",
    "city": "Rossburg"
  },
  {
    "value": "45363",
    "label": "Russia - OH 45363",
    "county": "Shelby County",
    "state": "OH",
    "city": "Russia"
  },
  {
    "value": "45365",
    "label": "Sidney - OH 45365",
    "county": "Shelby County",
    "state": "OH",
    "city": "Sidney"
  },
  {
    "value": "45368",
    "label": "South Charleston - OH 45368",
    "county": "Clark County",
    "state": "OH",
    "city": "South Charleston"
  },
  {
    "value": "45369",
    "label": "South Vienna - OH 45369",
    "county": "Clark County",
    "state": "OH",
    "city": "South Vienna"
  },
  {
    "value": "45370",
    "label": "Spring Valley - OH 45370",
    "county": "Greene County",
    "state": "OH",
    "city": "Spring Valley"
  },
  {
    "value": "45371",
    "label": "Tipp City - OH 45371",
    "county": "Miami County",
    "state": "OH",
    "city": "Tipp City"
  },
  {
    "value": "45373",
    "label": "Troy - OH 45373",
    "county": "Miami County",
    "state": "OH",
    "city": "Troy"
  },
  {
    "value": "45377",
    "label": "Vandalia - OH 45377",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Vandalia"
  },
  {
    "value": "45380",
    "label": "Versailles - OH 45380",
    "county": "Darke County",
    "state": "OH",
    "city": "Versailles"
  },
  {
    "value": "45381",
    "label": "West Alexandria - OH 45381",
    "county": "Preble County",
    "state": "OH",
    "city": "West Alexandria"
  },
  {
    "value": "45382",
    "label": "West Manchester - OH 45382",
    "county": "Preble County",
    "state": "OH",
    "city": "West Manchester"
  },
  {
    "value": "45383",
    "label": "West Milton - OH 45383",
    "county": "Miami County",
    "state": "OH",
    "city": "West Milton"
  },
  {
    "value": "45385",
    "label": "Xenia - OH 45385",
    "county": "Greene County",
    "state": "OH",
    "city": "Xenia"
  },
  {
    "value": "45387",
    "label": "Yellow Springs - OH 45387",
    "county": "Greene County",
    "state": "OH",
    "city": "Yellow Springs"
  },
  {
    "value": "45388",
    "label": "Yorkshire - OH 45388",
    "county": "Darke County",
    "state": "OH",
    "city": "Yorkshire"
  },
  {
    "value": "45390",
    "label": "Union City - OH 45390",
    "county": "Darke County",
    "state": "OH",
    "city": "Union City"
  },
  {
    "value": "45400",
    "label": "Dayton - OH 45400",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45402",
    "label": "Dayton - OH 45402",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45403",
    "label": "Dayton - OH 45403",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45404",
    "label": "Dayton - OH 45404",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45405",
    "label": "Dayton - OH 45405",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45406",
    "label": "Dayton - OH 45406",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45408",
    "label": "Dayton - OH 45408",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45409",
    "label": "Dayton - OH 45409",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45410",
    "label": "Dayton - OH 45410",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45414",
    "label": "Dayton - OH 45414",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45415",
    "label": "Dayton - OH 45415",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45416",
    "label": "Dayton - OH 45416",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45417",
    "label": "Dayton - OH 45417",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45418",
    "label": "Dayton - OH 45418",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45419",
    "label": "Dayton - OH 45419",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45420",
    "label": "Dayton - OH 45420",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45423",
    "label": "Dayton - OH 45423",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45424",
    "label": "Dayton - OH 45424",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45426",
    "label": "Dayton - OH 45426",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45427",
    "label": "Dayton - OH 45427",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45429",
    "label": "Dayton - OH 45429",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45430",
    "label": "Dayton - OH 45430",
    "county": "Greene County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45431",
    "label": "Dayton - OH 45431",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45432",
    "label": "Dayton - OH 45432",
    "county": "Greene County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45433",
    "label": "Dayton - OH 45433",
    "county": "Greene County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45434",
    "label": "Dayton - OH 45434",
    "county": "Greene County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45439",
    "label": "Dayton - OH 45439",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45440",
    "label": "Dayton - OH 45440",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45449",
    "label": "Dayton - OH 45449",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45458",
    "label": "Dayton - OH 45458",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45459",
    "label": "Dayton - OH 45459",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45479",
    "label": "Dayton - OH 45479",
    "county": "Montgomery County",
    "state": "OH",
    "city": "Dayton"
  },
  {
    "value": "45502",
    "label": "Springfield - OH 45502",
    "county": "Clark County",
    "state": "OH",
    "city": "Springfield"
  },
  {
    "value": "45503",
    "label": "Springfield - OH 45503",
    "county": "Clark County",
    "state": "OH",
    "city": "Springfield"
  },
  {
    "value": "45504",
    "label": "Springfield - OH 45504",
    "county": "Clark County",
    "state": "OH",
    "city": "Springfield"
  },
  {
    "value": "45505",
    "label": "Springfield - OH 45505",
    "county": "Clark County",
    "state": "OH",
    "city": "Springfield"
  },
  {
    "value": "45506",
    "label": "Springfield - OH 45506",
    "county": "Clark County",
    "state": "OH",
    "city": "Springfield"
  },
  {
    "value": "45601",
    "label": "Chillicothe - OH 45601",
    "county": "Ross County",
    "state": "OH",
    "city": "Chillicothe"
  },
  {
    "value": "45612",
    "label": "Bainbridge - OH 45612",
    "county": "Ross County",
    "state": "OH",
    "city": "Bainbridge"
  },
  {
    "value": "45613",
    "label": "Beaver - OH 45613",
    "county": "Pike County",
    "state": "OH",
    "city": "Beaver"
  },
  {
    "value": "45614",
    "label": "Bidwell - OH 45614",
    "county": "Gallia County",
    "state": "OH",
    "city": "Bidwell"
  },
  {
    "value": "45616",
    "label": "Blue Creek - OH 45616",
    "county": "Adams County",
    "state": "OH",
    "city": "Blue Creek"
  },
  {
    "value": "45619",
    "label": "Chesapeake - OH 45619",
    "county": "Lawrence County",
    "state": "OH",
    "city": "Chesapeake"
  },
  {
    "value": "45620",
    "label": "Cheshire - OH 45620",
    "county": "Gallia County",
    "state": "OH",
    "city": "Cheshire"
  },
  {
    "value": "45622",
    "label": "Creola - OH 45622",
    "county": "Vinton County",
    "state": "OH",
    "city": "Creola"
  },
  {
    "value": "45623",
    "label": "Crown City - OH 45623",
    "county": "Gallia County",
    "state": "OH",
    "city": "Crown City"
  },
  {
    "value": "45628",
    "label": "Frankfort - OH 45628",
    "county": "Ross County",
    "state": "OH",
    "city": "Frankfort"
  },
  {
    "value": "45629",
    "label": "Franklin Furnace - OH 45629",
    "county": "Scioto County",
    "state": "OH",
    "city": "Franklin Furnace"
  },
  {
    "value": "45631",
    "label": "Gallipolis - OH 45631",
    "county": "Gallia County",
    "state": "OH",
    "city": "Gallipolis"
  },
  {
    "value": "45634",
    "label": "Hamden - OH 45634",
    "county": "Vinton County",
    "state": "OH",
    "city": "Hamden"
  },
  {
    "value": "45638",
    "label": "Ironton - OH 45638",
    "county": "Lawrence County",
    "state": "OH",
    "city": "Ironton"
  },
  {
    "value": "45640",
    "label": "Jackson - OH 45640",
    "county": "Jackson County",
    "state": "OH",
    "city": "Jackson"
  },
  {
    "value": "45644",
    "label": "Kingston - OH 45644",
    "county": "Ross County",
    "state": "OH",
    "city": "Kingston"
  },
  {
    "value": "45645",
    "label": "Kitts Hill - OH 45645",
    "county": "Lawrence County",
    "state": "OH",
    "city": "Kitts Hill"
  },
  {
    "value": "45646",
    "label": "Latham - OH 45646",
    "county": "Pike County",
    "state": "OH",
    "city": "Latham"
  },
  {
    "value": "45647",
    "label": "Londonderry - OH 45647",
    "county": "Ross County",
    "state": "OH",
    "city": "Londonderry"
  },
  {
    "value": "45648",
    "label": "Lucasville - OH 45648",
    "county": "Scioto County",
    "state": "OH",
    "city": "Lucasville"
  },
  {
    "value": "45650",
    "label": "Lynx - OH 45650",
    "county": "Adams County",
    "state": "OH",
    "city": "Lynx"
  },
  {
    "value": "45651",
    "label": "Mc Arthur - OH 45651",
    "county": "Vinton County",
    "state": "OH",
    "city": "Mc Arthur"
  },
  {
    "value": "45652",
    "label": "Mc Dermott - OH 45652",
    "county": "Scioto County",
    "state": "OH",
    "city": "Mc Dermott"
  },
  {
    "value": "45653",
    "label": "Minford - OH 45653",
    "county": "Scioto County",
    "state": "OH",
    "city": "Minford"
  },
  {
    "value": "45654",
    "label": "New Plymouth - OH 45654",
    "county": "Vinton County",
    "state": "OH",
    "city": "New Plymouth"
  },
  {
    "value": "45656",
    "label": "Oak Hill - OH 45656",
    "county": "Jackson County",
    "state": "OH",
    "city": "Oak Hill"
  },
  {
    "value": "45657",
    "label": "Otway - OH 45657",
    "county": "Scioto County",
    "state": "OH",
    "city": "Otway"
  },
  {
    "value": "45658",
    "label": "Patriot - OH 45658",
    "county": "Gallia County",
    "state": "OH",
    "city": "Patriot"
  },
  {
    "value": "45659",
    "label": "Pedro - OH 45659",
    "county": "Lawrence County",
    "state": "OH",
    "city": "Pedro"
  },
  {
    "value": "45660",
    "label": "Peebles - OH 45660",
    "county": "Adams County",
    "state": "OH",
    "city": "Peebles"
  },
  {
    "value": "45661",
    "label": "Piketon - OH 45661",
    "county": "Pike County",
    "state": "OH",
    "city": "Piketon"
  },
  {
    "value": "45662",
    "label": "Portsmouth - OH 45662",
    "county": "Scioto County",
    "state": "OH",
    "city": "Portsmouth"
  },
  {
    "value": "45663",
    "label": "West Portsmouth - OH 45663",
    "county": "Scioto County",
    "state": "OH",
    "city": "West Portsmouth"
  },
  {
    "value": "45669",
    "label": "Proctorville - OH 45669",
    "county": "Lawrence County",
    "state": "OH",
    "city": "Proctorville"
  },
  {
    "value": "45671",
    "label": "Rarden - OH 45671",
    "county": "Scioto County",
    "state": "OH",
    "city": "Rarden"
  },
  {
    "value": "45672",
    "label": "Ray - OH 45672",
    "county": "Jackson County",
    "state": "OH",
    "city": "Ray"
  },
  {
    "value": "45673",
    "label": "Richmond Dale - OH 45673",
    "county": "Ross County",
    "state": "OH",
    "city": "Richmond Dale"
  },
  {
    "value": "45678",
    "label": "Scottown - OH 45678",
    "county": "Lawrence County",
    "state": "OH",
    "city": "Scottown"
  },
  {
    "value": "45679",
    "label": "Seaman - OH 45679",
    "county": "Adams County",
    "state": "OH",
    "city": "Seaman"
  },
  {
    "value": "45680",
    "label": "South Point - OH 45680",
    "county": "Lawrence County",
    "state": "OH",
    "city": "South Point"
  },
  {
    "value": "45681",
    "label": "South Salem - OH 45681",
    "county": "Ross County",
    "state": "OH",
    "city": "South Salem"
  },
  {
    "value": "45682",
    "label": "South Webster - OH 45682",
    "county": "Scioto County",
    "state": "OH",
    "city": "South Webster"
  },
  {
    "value": "45684",
    "label": "Stout - OH 45684",
    "county": "Scioto County",
    "state": "OH",
    "city": "Stout"
  },
  {
    "value": "45685",
    "label": "Thurman - OH 45685",
    "county": "Gallia County",
    "state": "OH",
    "city": "Thurman"
  },
  {
    "value": "45686",
    "label": "Vinton - OH 45686",
    "county": "Gallia County",
    "state": "OH",
    "city": "Vinton"
  },
  {
    "value": "45688",
    "label": "Waterloo - OH 45688",
    "county": "Lawrence County",
    "state": "OH",
    "city": "Waterloo"
  },
  {
    "value": "45690",
    "label": "Waverly - OH 45690",
    "county": "Pike County",
    "state": "OH",
    "city": "Waverly"
  },
  {
    "value": "45692",
    "label": "Wellston - OH 45692",
    "county": "Jackson County",
    "state": "OH",
    "city": "Wellston"
  },
  {
    "value": "45693",
    "label": "West Union - OH 45693",
    "county": "Adams County",
    "state": "OH",
    "city": "West Union"
  },
  {
    "value": "45694",
    "label": "Wheelersburg - OH 45694",
    "county": "Scioto County",
    "state": "OH",
    "city": "Wheelersburg"
  },
  {
    "value": "45695",
    "label": "Wilkesville - OH 45695",
    "county": "Vinton County",
    "state": "OH",
    "city": "Wilkesville"
  },
  {
    "value": "45696",
    "label": "Willow Wood - OH 45696",
    "county": "Lawrence County",
    "state": "OH",
    "city": "Willow Wood"
  },
  {
    "value": "45697",
    "label": "Winchester - OH 45697",
    "county": "Adams County",
    "state": "OH",
    "city": "Winchester"
  },
  {
    "value": "45701",
    "label": "Athens - OH 45701",
    "county": "Athens County",
    "state": "OH",
    "city": "Athens"
  },
  {
    "value": "45710",
    "label": "Albany - OH 45710",
    "county": "Athens County",
    "state": "OH",
    "city": "Albany"
  },
  {
    "value": "45711",
    "label": "Amesville - OH 45711",
    "county": "Athens County",
    "state": "OH",
    "city": "Amesville"
  },
  {
    "value": "45714",
    "label": "Belpre - OH 45714",
    "county": "Washington County",
    "state": "OH",
    "city": "Belpre"
  },
  {
    "value": "45715",
    "label": "Beverly - OH 45715",
    "county": "Washington County",
    "state": "OH",
    "city": "Beverly"
  },
  {
    "value": "45721",
    "label": "Coal Run - OH 45721",
    "county": "Washington County",
    "state": "OH",
    "city": "Coal Run"
  },
  {
    "value": "45723",
    "label": "Coolville - OH 45723",
    "county": "Athens County",
    "state": "OH",
    "city": "Coolville"
  },
  {
    "value": "45724",
    "label": "Cutler - OH 45724",
    "county": "Washington County",
    "state": "OH",
    "city": "Cutler"
  },
  {
    "value": "45727",
    "label": "Dexter City - OH 45727",
    "county": "Noble County",
    "state": "OH",
    "city": "Dexter City"
  },
  {
    "value": "45729",
    "label": "Fleming - OH 45729",
    "county": "Washington County",
    "state": "OH",
    "city": "Fleming"
  },
  {
    "value": "45732",
    "label": "Glouster - OH 45732",
    "county": "Athens County",
    "state": "OH",
    "city": "Glouster"
  },
  {
    "value": "45734",
    "label": "Graysville - OH 45734",
    "county": "Monroe County",
    "state": "OH",
    "city": "Graysville"
  },
  {
    "value": "45735",
    "label": "Guysville - OH 45735",
    "county": "Athens County",
    "state": "OH",
    "city": "Guysville"
  },
  {
    "value": "45741",
    "label": "Langsville - OH 45741",
    "county": "Meigs County",
    "state": "OH",
    "city": "Langsville"
  },
  {
    "value": "45742",
    "label": "Little Hocking - OH 45742",
    "county": "Washington County",
    "state": "OH",
    "city": "Little Hocking"
  },
  {
    "value": "45743",
    "label": "Long Bottom - OH 45743",
    "county": "Meigs County",
    "state": "OH",
    "city": "Long Bottom"
  },
  {
    "value": "45744",
    "label": "Lowell - OH 45744",
    "county": "Washington County",
    "state": "OH",
    "city": "Lowell"
  },
  {
    "value": "45745",
    "label": "Lower Salem - OH 45745",
    "county": "Washington County",
    "state": "OH",
    "city": "Lower Salem"
  },
  {
    "value": "45746",
    "label": "Macksburg - OH 45746",
    "county": "Washington County",
    "state": "OH",
    "city": "Macksburg"
  },
  {
    "value": "45750",
    "label": "Marietta - OH 45750",
    "county": "Washington County",
    "state": "OH",
    "city": "Marietta"
  },
  {
    "value": "45760",
    "label": "Middleport - OH 45760",
    "county": "Meigs County",
    "state": "OH",
    "city": "Middleport"
  },
  {
    "value": "45761",
    "label": "Millfield - OH 45761",
    "county": "Athens County",
    "state": "OH",
    "city": "Millfield"
  },
  {
    "value": "45764",
    "label": "Nelsonville - OH 45764",
    "county": "Athens County",
    "state": "OH",
    "city": "Nelsonville"
  },
  {
    "value": "45766",
    "label": "New Marshfield - OH 45766",
    "county": "Athens County",
    "state": "OH",
    "city": "New Marshfield"
  },
  {
    "value": "45767",
    "label": "New Matamoras - OH 45767",
    "county": "Washington County",
    "state": "OH",
    "city": "New Matamoras"
  },
  {
    "value": "45768",
    "label": "Newport - OH 45768",
    "county": "Washington County",
    "state": "OH",
    "city": "Newport"
  },
  {
    "value": "45769",
    "label": "Pomeroy - OH 45769",
    "county": "Meigs County",
    "state": "OH",
    "city": "Pomeroy"
  },
  {
    "value": "45770",
    "label": "Portland - OH 45770",
    "county": "Meigs County",
    "state": "OH",
    "city": "Portland"
  },
  {
    "value": "45771",
    "label": "Racine - OH 45771",
    "county": "Meigs County",
    "state": "OH",
    "city": "Racine"
  },
  {
    "value": "45772",
    "label": "Reedsville - OH 45772",
    "county": "Meigs County",
    "state": "OH",
    "city": "Reedsville"
  },
  {
    "value": "45773",
    "label": "Reno - OH 45773",
    "county": "Washington County",
    "state": "OH",
    "city": "Reno"
  },
  {
    "value": "45775",
    "label": "Rutland - OH 45775",
    "county": "Meigs County",
    "state": "OH",
    "city": "Rutland"
  },
  {
    "value": "45776",
    "label": "Shade - OH 45776",
    "county": "Meigs County",
    "state": "OH",
    "city": "Shade"
  },
  {
    "value": "45778",
    "label": "Stewart - OH 45778",
    "county": "Athens County",
    "state": "OH",
    "city": "Stewart"
  },
  {
    "value": "45780",
    "label": "The Plains - OH 45780",
    "county": "Athens County",
    "state": "OH",
    "city": "The Plains"
  },
  {
    "value": "45784",
    "label": "Vincent - OH 45784",
    "county": "Washington County",
    "state": "OH",
    "city": "Vincent"
  },
  {
    "value": "45786",
    "label": "Waterford - OH 45786",
    "county": "Washington County",
    "state": "OH",
    "city": "Waterford"
  },
  {
    "value": "45788",
    "label": "Whipple - OH 45788",
    "county": "Washington County",
    "state": "OH",
    "city": "Whipple"
  },
  {
    "value": "45789",
    "label": "Wingett Run - OH 45789",
    "county": "Washington County",
    "state": "OH",
    "city": "Wingett Run"
  },
  {
    "value": "45801",
    "label": "Lima - OH 45801",
    "county": "Allen County",
    "state": "OH",
    "city": "Lima"
  },
  {
    "value": "45804",
    "label": "Lima - OH 45804",
    "county": "Allen County",
    "state": "OH",
    "city": "Lima"
  },
  {
    "value": "45805",
    "label": "Lima - OH 45805",
    "county": "Allen County",
    "state": "OH",
    "city": "Lima"
  },
  {
    "value": "45806",
    "label": "Lima - OH 45806",
    "county": "Allen County",
    "state": "OH",
    "city": "Lima"
  },
  {
    "value": "45807",
    "label": "Lima - OH 45807",
    "county": "Allen County",
    "state": "OH",
    "city": "Lima"
  },
  {
    "value": "45808",
    "label": "Beaverdam - OH 45808",
    "county": "Allen County",
    "state": "OH",
    "city": "Beaverdam"
  },
  {
    "value": "45809",
    "label": "Gomer - OH 45809",
    "county": "Allen County",
    "state": "OH",
    "city": "Gomer"
  },
  {
    "value": "45810",
    "label": "Ada - OH 45810",
    "county": "Hardin County",
    "state": "OH",
    "city": "Ada"
  },
  {
    "value": "45812",
    "label": "Alger - OH 45812",
    "county": "Hardin County",
    "state": "OH",
    "city": "Alger"
  },
  {
    "value": "45813",
    "label": "Antwerp - OH 45813",
    "county": "Paulding County",
    "state": "OH",
    "city": "Antwerp"
  },
  {
    "value": "45814",
    "label": "Arlington - OH 45814",
    "county": "Hancock County",
    "state": "OH",
    "city": "Arlington"
  },
  {
    "value": "45817",
    "label": "Bluffton - OH 45817",
    "county": "Allen County",
    "state": "OH",
    "city": "Bluffton"
  },
  {
    "value": "45819",
    "label": "Buckland - OH 45819",
    "county": "Auglaize County",
    "state": "OH",
    "city": "Buckland"
  },
  {
    "value": "45821",
    "label": "Cecil - OH 45821",
    "county": "Paulding County",
    "state": "OH",
    "city": "Cecil"
  },
  {
    "value": "45822",
    "label": "Celina - OH 45822",
    "county": "Mercer County",
    "state": "OH",
    "city": "Celina"
  },
  {
    "value": "45827",
    "label": "Cloverdale - OH 45827",
    "county": "Putnam County",
    "state": "OH",
    "city": "Cloverdale"
  },
  {
    "value": "45828",
    "label": "Coldwater - OH 45828",
    "county": "Mercer County",
    "state": "OH",
    "city": "Coldwater"
  },
  {
    "value": "45830",
    "label": "Columbus Grove - OH 45830",
    "county": "Putnam County",
    "state": "OH",
    "city": "Columbus Grove"
  },
  {
    "value": "45831",
    "label": "Continental - OH 45831",
    "county": "Putnam County",
    "state": "OH",
    "city": "Continental"
  },
  {
    "value": "45832",
    "label": "Convoy - OH 45832",
    "county": "Van Wert County",
    "state": "OH",
    "city": "Convoy"
  },
  {
    "value": "45833",
    "label": "Delphos - OH 45833",
    "county": "Allen County",
    "state": "OH",
    "city": "Delphos"
  },
  {
    "value": "45835",
    "label": "Dola - OH 45835",
    "county": "Hardin County",
    "state": "OH",
    "city": "Dola"
  },
  {
    "value": "45836",
    "label": "Dunkirk - OH 45836",
    "county": "Hardin County",
    "state": "OH",
    "city": "Dunkirk"
  },
  {
    "value": "45840",
    "label": "Findlay - OH 45840",
    "county": "Hancock County",
    "state": "OH",
    "city": "Findlay"
  },
  {
    "value": "45841",
    "label": "Jenera - OH 45841",
    "county": "Hancock County",
    "state": "OH",
    "city": "Jenera"
  },
  {
    "value": "45843",
    "label": "Forest - OH 45843",
    "county": "Hardin County",
    "state": "OH",
    "city": "Forest"
  },
  {
    "value": "45844",
    "label": "Fort Jennings - OH 45844",
    "county": "Putnam County",
    "state": "OH",
    "city": "Fort Jennings"
  },
  {
    "value": "45845",
    "label": "Fort Loramie - OH 45845",
    "county": "Shelby County",
    "state": "OH",
    "city": "Fort Loramie"
  },
  {
    "value": "45846",
    "label": "Fort Recovery - OH 45846",
    "county": "Mercer County",
    "state": "OH",
    "city": "Fort Recovery"
  },
  {
    "value": "45849",
    "label": "Grover Hill - OH 45849",
    "county": "Paulding County",
    "state": "OH",
    "city": "Grover Hill"
  },
  {
    "value": "45850",
    "label": "Harrod - OH 45850",
    "county": "Allen County",
    "state": "OH",
    "city": "Harrod"
  },
  {
    "value": "45851",
    "label": "Haviland - OH 45851",
    "county": "Paulding County",
    "state": "OH",
    "city": "Haviland"
  },
  {
    "value": "45856",
    "label": "Leipsic - OH 45856",
    "county": "Putnam County",
    "state": "OH",
    "city": "Leipsic"
  },
  {
    "value": "45858",
    "label": "Mc Comb - OH 45858",
    "county": "Hancock County",
    "state": "OH",
    "city": "Mc Comb"
  },
  {
    "value": "45860",
    "label": "Maria Stein - OH 45860",
    "county": "Mercer County",
    "state": "OH",
    "city": "Maria Stein"
  },
  {
    "value": "45862",
    "label": "Mendon - OH 45862",
    "county": "Mercer County",
    "state": "OH",
    "city": "Mendon"
  },
  {
    "value": "45863",
    "label": "Middle Point - OH 45863",
    "county": "Van Wert County",
    "state": "OH",
    "city": "Middle Point"
  },
  {
    "value": "45865",
    "label": "Minster - OH 45865",
    "county": "Auglaize County",
    "state": "OH",
    "city": "Minster"
  },
  {
    "value": "45867",
    "label": "Mount Blanchard - OH 45867",
    "county": "Hancock County",
    "state": "OH",
    "city": "Mount Blanchard"
  },
  {
    "value": "45868",
    "label": "Mount Cory - OH 45868",
    "county": "Hancock County",
    "state": "OH",
    "city": "Mount Cory"
  },
  {
    "value": "45869",
    "label": "New Bremen - OH 45869",
    "county": "Auglaize County",
    "state": "OH",
    "city": "New Bremen"
  },
  {
    "value": "45871",
    "label": "New Knoxville - OH 45871",
    "county": "Auglaize County",
    "state": "OH",
    "city": "New Knoxville"
  },
  {
    "value": "45872",
    "label": "North Baltimore - OH 45872",
    "county": "Wood County",
    "state": "OH",
    "city": "North Baltimore"
  },
  {
    "value": "45873",
    "label": "Oakwood - OH 45873",
    "county": "Paulding County",
    "state": "OH",
    "city": "Oakwood"
  },
  {
    "value": "45874",
    "label": "Ohio City - OH 45874",
    "county": "Van Wert County",
    "state": "OH",
    "city": "Ohio City"
  },
  {
    "value": "45875",
    "label": "Ottawa - OH 45875",
    "county": "Putnam County",
    "state": "OH",
    "city": "Ottawa"
  },
  {
    "value": "45877",
    "label": "Pandora - OH 45877",
    "county": "Putnam County",
    "state": "OH",
    "city": "Pandora"
  },
  {
    "value": "45879",
    "label": "Paulding - OH 45879",
    "county": "Paulding County",
    "state": "OH",
    "city": "Paulding"
  },
  {
    "value": "45880",
    "label": "Payne - OH 45880",
    "county": "Paulding County",
    "state": "OH",
    "city": "Payne"
  },
  {
    "value": "45881",
    "label": "Rawson - OH 45881",
    "county": "Hancock County",
    "state": "OH",
    "city": "Rawson"
  },
  {
    "value": "45882",
    "label": "Rockford - OH 45882",
    "county": "Mercer County",
    "state": "OH",
    "city": "Rockford"
  },
  {
    "value": "45883",
    "label": "Saint Henry - OH 45883",
    "county": "Mercer County",
    "state": "OH",
    "city": "Saint Henry"
  },
  {
    "value": "45885",
    "label": "Saint Marys - OH 45885",
    "county": "Auglaize County",
    "state": "OH",
    "city": "Saint Marys"
  },
  {
    "value": "45886",
    "label": "Scott - OH 45886",
    "county": "Van Wert County",
    "state": "OH",
    "city": "Scott"
  },
  {
    "value": "45887",
    "label": "Spencerville - OH 45887",
    "county": "Allen County",
    "state": "OH",
    "city": "Spencerville"
  },
  {
    "value": "45889",
    "label": "Van Buren - OH 45889",
    "county": "Hancock County",
    "state": "OH",
    "city": "Van Buren"
  },
  {
    "value": "45890",
    "label": "Vanlue - OH 45890",
    "county": "Hancock County",
    "state": "OH",
    "city": "Vanlue"
  },
  {
    "value": "45891",
    "label": "Van Wert - OH 45891",
    "county": "Van Wert County",
    "state": "OH",
    "city": "Van Wert"
  },
  {
    "value": "45894",
    "label": "Venedocia - OH 45894",
    "county": "Van Wert County",
    "state": "OH",
    "city": "Venedocia"
  },
  {
    "value": "45895",
    "label": "Wapakoneta - OH 45895",
    "county": "Auglaize County",
    "state": "OH",
    "city": "Wapakoneta"
  },
  {
    "value": "45896",
    "label": "Waynesfield - OH 45896",
    "county": "Auglaize County",
    "state": "OH",
    "city": "Waynesfield"
  },
  {
    "value": "45897",
    "label": "Williamstown - OH 45897",
    "county": "Hancock County",
    "state": "OH",
    "city": "Williamstown"
  },
  {
    "value": "45898",
    "label": "Willshire - OH 45898",
    "county": "Van Wert County",
    "state": "OH",
    "city": "Willshire"
  },
  {
    "value": "73002",
    "label": "Alex - OK 73002",
    "county": "Grady County",
    "state": "OK",
    "city": "Alex"
  },
  {
    "value": "73003",
    "label": "Edmond - OK 73003",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Edmond"
  },
  {
    "value": "73004",
    "label": "Amber - OK 73004",
    "county": "Grady County",
    "state": "OK",
    "city": "Amber"
  },
  {
    "value": "73005",
    "label": "Anadarko - OK 73005",
    "county": "Caddo County",
    "state": "OK",
    "city": "Anadarko"
  },
  {
    "value": "73006",
    "label": "Apache - OK 73006",
    "county": "Caddo County",
    "state": "OK",
    "city": "Apache"
  },
  {
    "value": "73007",
    "label": "Arcadia - OK 73007",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Arcadia"
  },
  {
    "value": "73008",
    "label": "Bethany - OK 73008",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Bethany"
  },
  {
    "value": "73009",
    "label": "Binger - OK 73009",
    "county": "Caddo County",
    "state": "OK",
    "city": "Binger"
  },
  {
    "value": "73010",
    "label": "Blanchard - OK 73010",
    "county": "McClain County",
    "state": "OK",
    "city": "Blanchard"
  },
  {
    "value": "73011",
    "label": "Bradley - OK 73011",
    "county": "Grady County",
    "state": "OK",
    "city": "Bradley"
  },
  {
    "value": "73012",
    "label": "Edmond - OK 73012",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Edmond"
  },
  {
    "value": "73013",
    "label": "Edmond - OK 73013",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Edmond"
  },
  {
    "value": "73014",
    "label": "Calumet - OK 73014",
    "county": "Canadian County",
    "state": "OK",
    "city": "Calumet"
  },
  {
    "value": "73015",
    "label": "Carnegie - OK 73015",
    "county": "Caddo County",
    "state": "OK",
    "city": "Carnegie"
  },
  {
    "value": "73016",
    "label": "Cashion - OK 73016",
    "county": "Logan County",
    "state": "OK",
    "city": "Cashion"
  },
  {
    "value": "73017",
    "label": "Cement - OK 73017",
    "county": "Caddo County",
    "state": "OK",
    "city": "Cement"
  },
  {
    "value": "73018",
    "label": "Chickasha - OK 73018",
    "county": "Grady County",
    "state": "OK",
    "city": "Chickasha"
  },
  {
    "value": "73020",
    "label": "Choctaw - OK 73020",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Choctaw"
  },
  {
    "value": "73021",
    "label": "Colony - OK 73021",
    "county": "Washita County",
    "state": "OK",
    "city": "Colony"
  },
  {
    "value": "73024",
    "label": "Corn - OK 73024",
    "county": "Washita County",
    "state": "OK",
    "city": "Corn"
  },
  {
    "value": "73025",
    "label": "Edmond - OK 73025",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Edmond"
  },
  {
    "value": "73026",
    "label": "Norman - OK 73026",
    "county": "Cleveland County",
    "state": "OK",
    "city": "Norman"
  },
  {
    "value": "73027",
    "label": "Coyle - OK 73027",
    "county": "Payne County",
    "state": "OK",
    "city": "Coyle"
  },
  {
    "value": "73028",
    "label": "Crescent - OK 73028",
    "county": "Logan County",
    "state": "OK",
    "city": "Crescent"
  },
  {
    "value": "73029",
    "label": "Cyril - OK 73029",
    "county": "Caddo County",
    "state": "OK",
    "city": "Cyril"
  },
  {
    "value": "73030",
    "label": "Davis - OK 73030",
    "county": "Murray County",
    "state": "OK",
    "city": "Davis"
  },
  {
    "value": "73034",
    "label": "Edmond - OK 73034",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Edmond"
  },
  {
    "value": "73036",
    "label": "El Reno - OK 73036",
    "county": "Canadian County",
    "state": "OK",
    "city": "El Reno"
  },
  {
    "value": "73038",
    "label": "Fort Cobb - OK 73038",
    "county": "Caddo County",
    "state": "OK",
    "city": "Fort Cobb"
  },
  {
    "value": "73040",
    "label": "Geary - OK 73040",
    "county": "Blaine County",
    "state": "OK",
    "city": "Geary"
  },
  {
    "value": "73041",
    "label": "Gotebo - OK 73041",
    "county": "Kiowa County",
    "state": "OK",
    "city": "Gotebo"
  },
  {
    "value": "73042",
    "label": "Gracemont - OK 73042",
    "county": "Caddo County",
    "state": "OK",
    "city": "Gracemont"
  },
  {
    "value": "73043",
    "label": "Greenfield - OK 73043",
    "county": "Blaine County",
    "state": "OK",
    "city": "Greenfield"
  },
  {
    "value": "73044",
    "label": "Guthrie - OK 73044",
    "county": "Logan County",
    "state": "OK",
    "city": "Guthrie"
  },
  {
    "value": "73045",
    "label": "Harrah - OK 73045",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Harrah"
  },
  {
    "value": "73047",
    "label": "Hinton - OK 73047",
    "county": "",
    "state": "OK",
    "city": "Hinton"
  },
  {
    "value": "73048",
    "label": "Hydro - OK 73048",
    "county": "Caddo County",
    "state": "OK",
    "city": "Hydro"
  },
  {
    "value": "73049",
    "label": "Jones - OK 73049",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Jones"
  },
  {
    "value": "73051",
    "label": "Lexington - OK 73051",
    "county": "Cleveland County",
    "state": "OK",
    "city": "Lexington"
  },
  {
    "value": "73052",
    "label": "Lindsay - OK 73052",
    "county": "Garvin County",
    "state": "OK",
    "city": "Lindsay"
  },
  {
    "value": "73053",
    "label": "Lookeba - OK 73053",
    "county": "Caddo County",
    "state": "OK",
    "city": "Lookeba"
  },
  {
    "value": "73054",
    "label": "Luther - OK 73054",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Luther"
  },
  {
    "value": "73055",
    "label": "Marlow - OK 73055",
    "county": "Stephens County",
    "state": "OK",
    "city": "Marlow"
  },
  {
    "value": "73056",
    "label": "Marshall - OK 73056",
    "county": "Logan County",
    "state": "OK",
    "city": "Marshall"
  },
  {
    "value": "73057",
    "label": "Maysville - OK 73057",
    "county": "Garvin County",
    "state": "OK",
    "city": "Maysville"
  },
  {
    "value": "73058",
    "label": "Meridian - OK 73058",
    "county": "Logan County",
    "state": "OK",
    "city": "Meridian"
  },
  {
    "value": "73059",
    "label": "Minco - OK 73059",
    "county": "Grady County",
    "state": "OK",
    "city": "Minco"
  },
  {
    "value": "73061",
    "label": "Morrison - OK 73061",
    "county": "Noble County",
    "state": "OK",
    "city": "Morrison"
  },
  {
    "value": "73062",
    "label": "Mountain View - OK 73062",
    "county": "Kiowa County",
    "state": "OK",
    "city": "Mountain View"
  },
  {
    "value": "73063",
    "label": "Mulhall - OK 73063",
    "county": "Logan County",
    "state": "OK",
    "city": "Mulhall"
  },
  {
    "value": "73064",
    "label": "Mustang - OK 73064",
    "county": "Canadian County",
    "state": "OK",
    "city": "Mustang"
  },
  {
    "value": "73065",
    "label": "Newcastle - OK 73065",
    "county": "McClain County",
    "state": "OK",
    "city": "Newcastle"
  },
  {
    "value": "73067",
    "label": "Ninnekah - OK 73067",
    "county": "Grady County",
    "state": "OK",
    "city": "Ninnekah"
  },
  {
    "value": "73068",
    "label": "Noble - OK 73068",
    "county": "Cleveland County",
    "state": "OK",
    "city": "Noble"
  },
  {
    "value": "73069",
    "label": "Norman - OK 73069",
    "county": "Cleveland County",
    "state": "OK",
    "city": "Norman"
  },
  {
    "value": "73071",
    "label": "Norman - OK 73071",
    "county": "Cleveland County",
    "state": "OK",
    "city": "Norman"
  },
  {
    "value": "73072",
    "label": "Norman - OK 73072",
    "county": "Cleveland County",
    "state": "OK",
    "city": "Norman"
  },
  {
    "value": "73073",
    "label": "Orlando - OK 73073",
    "county": "Logan County",
    "state": "OK",
    "city": "Orlando"
  },
  {
    "value": "73074",
    "label": "Paoli - OK 73074",
    "county": "Garvin County",
    "state": "OK",
    "city": "Paoli"
  },
  {
    "value": "73075",
    "label": "Pauls Valley - OK 73075",
    "county": "Garvin County",
    "state": "OK",
    "city": "Pauls Valley"
  },
  {
    "value": "73077",
    "label": "Perry - OK 73077",
    "county": "Noble County",
    "state": "OK",
    "city": "Perry"
  },
  {
    "value": "73078",
    "label": "Piedmont - OK 73078",
    "county": "Canadian County",
    "state": "OK",
    "city": "Piedmont"
  },
  {
    "value": "73079",
    "label": "Pocasset - OK 73079",
    "county": "Grady County",
    "state": "OK",
    "city": "Pocasset"
  },
  {
    "value": "73080",
    "label": "Purcell - OK 73080",
    "county": "McClain County",
    "state": "OK",
    "city": "Purcell"
  },
  {
    "value": "73082",
    "label": "Rush Springs - OK 73082",
    "county": "Grady County",
    "state": "OK",
    "city": "Rush Springs"
  },
  {
    "value": "73084",
    "label": "Spencer - OK 73084",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Spencer"
  },
  {
    "value": "73086",
    "label": "Sulphur - OK 73086",
    "county": "Murray County",
    "state": "OK",
    "city": "Sulphur"
  },
  {
    "value": "73089",
    "label": "Tuttle - OK 73089",
    "county": "Grady County",
    "state": "OK",
    "city": "Tuttle"
  },
  {
    "value": "73090",
    "label": "Union City - OK 73090",
    "county": "Canadian County",
    "state": "OK",
    "city": "Union City"
  },
  {
    "value": "73092",
    "label": "Verden - OK 73092",
    "county": "Grady County",
    "state": "OK",
    "city": "Verden"
  },
  {
    "value": "73093",
    "label": "Washington - OK 73093",
    "county": "McClain County",
    "state": "OK",
    "city": "Washington"
  },
  {
    "value": "73094",
    "label": "Washita - OK 73094",
    "county": "Caddo County",
    "state": "OK",
    "city": "Washita"
  },
  {
    "value": "73095",
    "label": "Wayne - OK 73095",
    "county": "McClain County",
    "state": "OK",
    "city": "Wayne"
  },
  {
    "value": "73096",
    "label": "Weatherford - OK 73096",
    "county": "Custer County",
    "state": "OK",
    "city": "Weatherford"
  },
  {
    "value": "73098",
    "label": "Wynnewood - OK 73098",
    "county": "Garvin County",
    "state": "OK",
    "city": "Wynnewood"
  },
  {
    "value": "73099",
    "label": "Yukon - OK 73099",
    "county": "Canadian County",
    "state": "OK",
    "city": "Yukon"
  },
  {
    "value": "73102",
    "label": "Oklahoma City - OK 73102",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73103",
    "label": "Oklahoma City - OK 73103",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73104",
    "label": "Oklahoma City - OK 73104",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73105",
    "label": "Oklahoma City - OK 73105",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73106",
    "label": "Oklahoma City - OK 73106",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73107",
    "label": "Oklahoma City - OK 73107",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73108",
    "label": "Oklahoma City - OK 73108",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73109",
    "label": "Oklahoma City - OK 73109",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73110",
    "label": "Oklahoma City - OK 73110",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73111",
    "label": "Oklahoma City - OK 73111",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73112",
    "label": "Oklahoma City - OK 73112",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73114",
    "label": "Oklahoma City - OK 73114",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73115",
    "label": "Oklahoma City - OK 73115",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73116",
    "label": "Oklahoma City - OK 73116",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73117",
    "label": "Oklahoma City - OK 73117",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73118",
    "label": "Oklahoma City - OK 73118",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73119",
    "label": "Oklahoma City - OK 73119",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73120",
    "label": "Oklahoma City - OK 73120",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73121",
    "label": "Oklahoma City - OK 73121",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73122",
    "label": "Oklahoma City - OK 73122",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73127",
    "label": "Oklahoma City - OK 73127",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73128",
    "label": "Oklahoma City - OK 73128",
    "county": "Canadian County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73129",
    "label": "Oklahoma City - OK 73129",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73130",
    "label": "Oklahoma City - OK 73130",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73131",
    "label": "Oklahoma City - OK 73131",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73132",
    "label": "Oklahoma City - OK 73132",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73134",
    "label": "Oklahoma City - OK 73134",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73135",
    "label": "Oklahoma City - OK 73135",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73139",
    "label": "Oklahoma City - OK 73139",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73141",
    "label": "Oklahoma City - OK 73141",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73142",
    "label": "Oklahoma City - OK 73142",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73145",
    "label": "Oklahoma City - OK 73145",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73149",
    "label": "Oklahoma City - OK 73149",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73150",
    "label": "Oklahoma City - OK 73150",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73151",
    "label": "Oklahoma City - OK 73151",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73159",
    "label": "Oklahoma City - OK 73159",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73160",
    "label": "Oklahoma City - OK 73160",
    "county": "Cleveland County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73162",
    "label": "Oklahoma City - OK 73162",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73163",
    "label": "Oklahoma City - OK 73163",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73165",
    "label": "Oklahoma City - OK 73165",
    "county": "Cleveland County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73167",
    "label": "Oklahoma City - OK 73167",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73169",
    "label": "Oklahoma City - OK 73169",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73170",
    "label": "Oklahoma City - OK 73170",
    "county": "Cleveland County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73173",
    "label": "Oklahoma City - OK 73173",
    "county": "Cleveland County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73179",
    "label": "Oklahoma City - OK 73179",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73195",
    "label": "Oklahoma City - OK 73195",
    "county": "Oklahoma County",
    "state": "OK",
    "city": "Oklahoma City"
  },
  {
    "value": "73401",
    "label": "Ardmore - OK 73401",
    "county": "Carter County",
    "state": "OK",
    "city": "Ardmore"
  },
  {
    "value": "73430",
    "label": "Burneyville - OK 73430",
    "county": "Love County",
    "state": "OK",
    "city": "Burneyville"
  },
  {
    "value": "73432",
    "label": "Coleman - OK 73432",
    "county": "Johnston County",
    "state": "OK",
    "city": "Coleman"
  },
  {
    "value": "73433",
    "label": "Elmore City - OK 73433",
    "county": "Garvin County",
    "state": "OK",
    "city": "Elmore City"
  },
  {
    "value": "73434",
    "label": "Foster - OK 73434",
    "county": "Garvin County",
    "state": "OK",
    "city": "Foster"
  },
  {
    "value": "73437",
    "label": "Graham - OK 73437",
    "county": "Carter County",
    "state": "OK",
    "city": "Graham"
  },
  {
    "value": "73438",
    "label": "Healdton - OK 73438",
    "county": "Carter County",
    "state": "OK",
    "city": "Healdton"
  },
  {
    "value": "73439",
    "label": "Kingston - OK 73439",
    "county": "Marshall County",
    "state": "OK",
    "city": "Kingston"
  },
  {
    "value": "73440",
    "label": "Lebanon - OK 73440",
    "county": "Marshall County",
    "state": "OK",
    "city": "Lebanon"
  },
  {
    "value": "73441",
    "label": "Leon - OK 73441",
    "county": "Love County",
    "state": "OK",
    "city": "Leon"
  },
  {
    "value": "73442",
    "label": "Loco - OK 73442",
    "county": "Stephens County",
    "state": "OK",
    "city": "Loco"
  },
  {
    "value": "73443",
    "label": "Lone Grove - OK 73443",
    "county": "Carter County",
    "state": "OK",
    "city": "Lone Grove"
  },
  {
    "value": "73444",
    "label": "Hennepin - OK 73444",
    "county": "Garvin County",
    "state": "OK",
    "city": "Hennepin"
  },
  {
    "value": "73446",
    "label": "Madill - OK 73446",
    "county": "Marshall County",
    "state": "OK",
    "city": "Madill"
  },
  {
    "value": "73447",
    "label": "Mannsville - OK 73447",
    "county": "Johnston County",
    "state": "OK",
    "city": "Mannsville"
  },
  {
    "value": "73448",
    "label": "Marietta - OK 73448",
    "county": "Love County",
    "state": "OK",
    "city": "Marietta"
  },
  {
    "value": "73449",
    "label": "Mead - OK 73449",
    "county": "Bryan County",
    "state": "OK",
    "city": "Mead"
  },
  {
    "value": "73450",
    "label": "Milburn - OK 73450",
    "county": "Johnston County",
    "state": "OK",
    "city": "Milburn"
  },
  {
    "value": "73451",
    "label": "Ardmore - OK 73451",
    "county": "",
    "state": "OK",
    "city": "Ardmore"
  },
  {
    "value": "73453",
    "label": "Overbrook - OK 73453",
    "county": "Love County",
    "state": "OK",
    "city": "Overbrook"
  },
  {
    "value": "73454",
    "label": "Ardmore - OK 73454",
    "county": "",
    "state": "OK",
    "city": "Ardmore"
  },
  {
    "value": "73456",
    "label": "Ringling - OK 73456",
    "county": "Jefferson County",
    "state": "OK",
    "city": "Ringling"
  },
  {
    "value": "73458",
    "label": "Springer - OK 73458",
    "county": "Carter County",
    "state": "OK",
    "city": "Springer"
  },
  {
    "value": "73459",
    "label": "Thackerville - OK 73459",
    "county": "Love County",
    "state": "OK",
    "city": "Thackerville"
  },
  {
    "value": "73460",
    "label": "Tishomingo - OK 73460",
    "county": "Johnston County",
    "state": "OK",
    "city": "Tishomingo"
  },
  {
    "value": "73461",
    "label": "Wapanucka - OK 73461",
    "county": "Johnston County",
    "state": "OK",
    "city": "Wapanucka"
  },
  {
    "value": "73463",
    "label": "Wilson - OK 73463",
    "county": "Carter County",
    "state": "OK",
    "city": "Wilson"
  },
  {
    "value": "73481",
    "label": "Ratliff City - OK 73481",
    "county": "Carter County",
    "state": "OK",
    "city": "Ratliff City"
  },
  {
    "value": "73488",
    "label": "Tussy - OK 73488",
    "county": "Carter County",
    "state": "OK",
    "city": "Tussy"
  },
  {
    "value": "73501",
    "label": "Lawton - OK 73501",
    "county": "Comanche County",
    "state": "OK",
    "city": "Lawton"
  },
  {
    "value": "73503",
    "label": "Fort Sill - OK 73503",
    "county": "Comanche County",
    "state": "OK",
    "city": "Fort Sill"
  },
  {
    "value": "73505",
    "label": "Lawton - OK 73505",
    "county": "Comanche County",
    "state": "OK",
    "city": "Lawton"
  },
  {
    "value": "73507",
    "label": "Lawton - OK 73507",
    "county": "Comanche County",
    "state": "OK",
    "city": "Lawton"
  },
  {
    "value": "73521",
    "label": "Altus - OK 73521",
    "county": "Jackson County",
    "state": "OK",
    "city": "Altus"
  },
  {
    "value": "73523",
    "label": "Altus AFB - OK 73523",
    "county": "Jackson County",
    "state": "OK",
    "city": "Altus AFB"
  },
  {
    "value": "73526",
    "label": "Blair - OK 73526",
    "county": "Jackson County",
    "state": "OK",
    "city": "Blair"
  },
  {
    "value": "73527",
    "label": "Cache - OK 73527",
    "county": "Comanche County",
    "state": "OK",
    "city": "Cache"
  },
  {
    "value": "73528",
    "label": "Chattanooga - OK 73528",
    "county": "Tillman County",
    "state": "OK",
    "city": "Chattanooga"
  },
  {
    "value": "73529",
    "label": "Comanche - OK 73529",
    "county": "Stephens County",
    "state": "OK",
    "city": "Comanche"
  },
  {
    "value": "73530",
    "label": "Davidson - OK 73530",
    "county": "Tillman County",
    "state": "OK",
    "city": "Davidson"
  },
  {
    "value": "73531",
    "label": "Devol - OK 73531",
    "county": "Cotton County",
    "state": "OK",
    "city": "Devol"
  },
  {
    "value": "73532",
    "label": "Duke - OK 73532",
    "county": "Jackson County",
    "state": "OK",
    "city": "Duke"
  },
  {
    "value": "73533",
    "label": "Duncan - OK 73533",
    "county": "Stephens County",
    "state": "OK",
    "city": "Duncan"
  },
  {
    "value": "73537",
    "label": "Eldorado - OK 73537",
    "county": "Jackson County",
    "state": "OK",
    "city": "Eldorado"
  },
  {
    "value": "73538",
    "label": "Elgin - OK 73538",
    "county": "Comanche County",
    "state": "OK",
    "city": "Elgin"
  },
  {
    "value": "73539",
    "label": "Elmer - OK 73539",
    "county": "Jackson County",
    "state": "OK",
    "city": "Elmer"
  },
  {
    "value": "73540",
    "label": "Faxon - OK 73540",
    "county": "Comanche County",
    "state": "OK",
    "city": "Faxon"
  },
  {
    "value": "73541",
    "label": "Fletcher - OK 73541",
    "county": "Comanche County",
    "state": "OK",
    "city": "Fletcher"
  },
  {
    "value": "73542",
    "label": "Frederick - OK 73542",
    "county": "Tillman County",
    "state": "OK",
    "city": "Frederick"
  },
  {
    "value": "73543",
    "label": "Geronimo - OK 73543",
    "county": "Comanche County",
    "state": "OK",
    "city": "Geronimo"
  },
  {
    "value": "73544",
    "label": "Gould - OK 73544",
    "county": "Harmon County",
    "state": "OK",
    "city": "Gould"
  },
  {
    "value": "73546",
    "label": "Grandfield - OK 73546",
    "county": "Tillman County",
    "state": "OK",
    "city": "Grandfield"
  },
  {
    "value": "73547",
    "label": "Granite - OK 73547",
    "county": "Greer County",
    "state": "OK",
    "city": "Granite"
  },
  {
    "value": "73548",
    "label": "Hastings - OK 73548",
    "county": "Jefferson County",
    "state": "OK",
    "city": "Hastings"
  },
  {
    "value": "73549",
    "label": "Headrick - OK 73549",
    "county": "Jackson County",
    "state": "OK",
    "city": "Headrick"
  },
  {
    "value": "73550",
    "label": "Hollis - OK 73550",
    "county": "Harmon County",
    "state": "OK",
    "city": "Hollis"
  },
  {
    "value": "73551",
    "label": "Hollister - OK 73551",
    "county": "Tillman County",
    "state": "OK",
    "city": "Hollister"
  },
  {
    "value": "73552",
    "label": "Indiahoma - OK 73552",
    "county": "Comanche County",
    "state": "OK",
    "city": "Indiahoma"
  },
  {
    "value": "73553",
    "label": "Loveland - OK 73553",
    "county": "Tillman County",
    "state": "OK",
    "city": "Loveland"
  },
  {
    "value": "73554",
    "label": "Mangum - OK 73554",
    "county": "Greer County",
    "state": "OK",
    "city": "Mangum"
  },
  {
    "value": "73559",
    "label": "Mountain Park - OK 73559",
    "county": "Kiowa County",
    "state": "OK",
    "city": "Mountain Park"
  },
  {
    "value": "73560",
    "label": "Olustee - OK 73560",
    "county": "Jackson County",
    "state": "OK",
    "city": "Olustee"
  },
  {
    "value": "73561",
    "label": "Oscar - OK 73561",
    "county": "Jefferson County",
    "state": "OK",
    "city": "Oscar"
  },
  {
    "value": "73562",
    "label": "Randlett - OK 73562",
    "county": "Cotton County",
    "state": "OK",
    "city": "Randlett"
  },
  {
    "value": "73564",
    "label": "Roosevelt - OK 73564",
    "county": "Kiowa County",
    "state": "OK",
    "city": "Roosevelt"
  },
  {
    "value": "73565",
    "label": "Ryan - OK 73565",
    "county": "Jefferson County",
    "state": "OK",
    "city": "Ryan"
  },
  {
    "value": "73566",
    "label": "Snyder - OK 73566",
    "county": "Kiowa County",
    "state": "OK",
    "city": "Snyder"
  },
  {
    "value": "73568",
    "label": "Temple - OK 73568",
    "county": "Cotton County",
    "state": "OK",
    "city": "Temple"
  },
  {
    "value": "73569",
    "label": "Terral - OK 73569",
    "county": "Jefferson County",
    "state": "OK",
    "city": "Terral"
  },
  {
    "value": "73570",
    "label": "Tipton - OK 73570",
    "county": "Tillman County",
    "state": "OK",
    "city": "Tipton"
  },
  {
    "value": "73571",
    "label": "Vinson - OK 73571",
    "county": "Harmon County",
    "state": "OK",
    "city": "Vinson"
  },
  {
    "value": "73572",
    "label": "Walters - OK 73572",
    "county": "Cotton County",
    "state": "OK",
    "city": "Walters"
  },
  {
    "value": "73573",
    "label": "Waurika - OK 73573",
    "county": "Jefferson County",
    "state": "OK",
    "city": "Waurika"
  },
  {
    "value": "73601",
    "label": "Clinton - OK 73601",
    "county": "Custer County",
    "state": "OK",
    "city": "Clinton"
  },
  {
    "value": "73620",
    "label": "Arapaho - OK 73620",
    "county": "Custer County",
    "state": "OK",
    "city": "Arapaho"
  },
  {
    "value": "73622",
    "label": "Bessie - OK 73622",
    "county": "Washita County",
    "state": "OK",
    "city": "Bessie"
  },
  {
    "value": "73625",
    "label": "Butler - OK 73625",
    "county": "Custer County",
    "state": "OK",
    "city": "Butler"
  },
  {
    "value": "73626",
    "label": "Canute - OK 73626",
    "county": "Washita County",
    "state": "OK",
    "city": "Canute"
  },
  {
    "value": "73627",
    "label": "Carter - OK 73627",
    "county": "Beckham County",
    "state": "OK",
    "city": "Carter"
  },
  {
    "value": "73628",
    "label": "Cheyenne - OK 73628",
    "county": "Roger Mills County",
    "state": "OK",
    "city": "Cheyenne"
  },
  {
    "value": "73632",
    "label": "Cordell - OK 73632",
    "county": "Washita County",
    "state": "OK",
    "city": "Cordell"
  },
  {
    "value": "73638",
    "label": "Crawford - OK 73638",
    "county": "Roger Mills County",
    "state": "OK",
    "city": "Crawford"
  },
  {
    "value": "73639",
    "label": "Custer City - OK 73639",
    "county": "Custer County",
    "state": "OK",
    "city": "Custer City"
  },
  {
    "value": "73641",
    "label": "Dill City - OK 73641",
    "county": "Washita County",
    "state": "OK",
    "city": "Dill City"
  },
  {
    "value": "73642",
    "label": "Durham - OK 73642",
    "county": "Roger Mills County",
    "state": "OK",
    "city": "Durham"
  },
  {
    "value": "73644",
    "label": "Elk City - OK 73644",
    "county": "Beckham County",
    "state": "OK",
    "city": "Elk City"
  },
  {
    "value": "73645",
    "label": "Erick - OK 73645",
    "county": "Beckham County",
    "state": "OK",
    "city": "Erick"
  },
  {
    "value": "73646",
    "label": "Fay - OK 73646",
    "county": "Dewey County",
    "state": "OK",
    "city": "Fay"
  },
  {
    "value": "73647",
    "label": "Foss - OK 73647",
    "county": "Washita County",
    "state": "OK",
    "city": "Foss"
  },
  {
    "value": "73650",
    "label": "Hammon - OK 73650",
    "county": "Roger Mills County",
    "state": "OK",
    "city": "Hammon"
  },
  {
    "value": "73651",
    "label": "Hobart - OK 73651",
    "county": "Kiowa County",
    "state": "OK",
    "city": "Hobart"
  },
  {
    "value": "73654",
    "label": "Leedey - OK 73654",
    "county": "Dewey County",
    "state": "OK",
    "city": "Leedey"
  },
  {
    "value": "73655",
    "label": "Lone Wolf - OK 73655",
    "county": "Kiowa County",
    "state": "OK",
    "city": "Lone Wolf"
  },
  {
    "value": "73658",
    "label": "Oakwood - OK 73658",
    "county": "Dewey County",
    "state": "OK",
    "city": "Oakwood"
  },
  {
    "value": "73659",
    "label": "Putnam - OK 73659",
    "county": "Dewey County",
    "state": "OK",
    "city": "Putnam"
  },
  {
    "value": "73660",
    "label": "Reydon - OK 73660",
    "county": "Roger Mills County",
    "state": "OK",
    "city": "Reydon"
  },
  {
    "value": "73661",
    "label": "Rocky - OK 73661",
    "county": "Washita County",
    "state": "OK",
    "city": "Rocky"
  },
  {
    "value": "73662",
    "label": "Sayre - OK 73662",
    "county": "Beckham County",
    "state": "OK",
    "city": "Sayre"
  },
  {
    "value": "73663",
    "label": "Seiling - OK 73663",
    "county": "Major County",
    "state": "OK",
    "city": "Seiling"
  },
  {
    "value": "73664",
    "label": "Sentinel - OK 73664",
    "county": "Washita County",
    "state": "OK",
    "city": "Sentinel"
  },
  {
    "value": "73666",
    "label": "Sweetwater - OK 73666",
    "county": "Roger Mills County",
    "state": "OK",
    "city": "Sweetwater"
  },
  {
    "value": "73667",
    "label": "Taloga - OK 73667",
    "county": "Dewey County",
    "state": "OK",
    "city": "Taloga"
  },
  {
    "value": "73668",
    "label": "Texola - OK 73668",
    "county": "Beckham County",
    "state": "OK",
    "city": "Texola"
  },
  {
    "value": "73669",
    "label": "Thomas - OK 73669",
    "county": "Custer County",
    "state": "OK",
    "city": "Thomas"
  },
  {
    "value": "73673",
    "label": "Willow - OK 73673",
    "county": "Greer County",
    "state": "OK",
    "city": "Willow"
  },
  {
    "value": "73701",
    "label": "Enid - OK 73701",
    "county": "Garfield County",
    "state": "OK",
    "city": "Enid"
  },
  {
    "value": "73703",
    "label": "Enid - OK 73703",
    "county": "Garfield County",
    "state": "OK",
    "city": "Enid"
  },
  {
    "value": "73705",
    "label": "Enid - OK 73705",
    "county": "Garfield County",
    "state": "OK",
    "city": "Enid"
  },
  {
    "value": "73716",
    "label": "Aline - OK 73716",
    "county": "Alfalfa County",
    "state": "OK",
    "city": "Aline"
  },
  {
    "value": "73717",
    "label": "Alva - OK 73717",
    "county": "Woods County",
    "state": "OK",
    "city": "Alva"
  },
  {
    "value": "73718",
    "label": "Ames - OK 73718",
    "county": "Major County",
    "state": "OK",
    "city": "Ames"
  },
  {
    "value": "73719",
    "label": "Amorita - OK 73719",
    "county": "Alfalfa County",
    "state": "OK",
    "city": "Amorita"
  },
  {
    "value": "73720",
    "label": "Bison - OK 73720",
    "county": "Garfield County",
    "state": "OK",
    "city": "Bison"
  },
  {
    "value": "73722",
    "label": "Burlington - OK 73722",
    "county": "Alfalfa County",
    "state": "OK",
    "city": "Burlington"
  },
  {
    "value": "73724",
    "label": "Canton - OK 73724",
    "county": "Blaine County",
    "state": "OK",
    "city": "Canton"
  },
  {
    "value": "73726",
    "label": "Carmen - OK 73726",
    "county": "Alfalfa County",
    "state": "OK",
    "city": "Carmen"
  },
  {
    "value": "73727",
    "label": "Carrier - OK 73727",
    "county": "Garfield County",
    "state": "OK",
    "city": "Carrier"
  },
  {
    "value": "73728",
    "label": "Cherokee - OK 73728",
    "county": "Alfalfa County",
    "state": "OK",
    "city": "Cherokee"
  },
  {
    "value": "73729",
    "label": "Cleo Springs - OK 73729",
    "county": "Major County",
    "state": "OK",
    "city": "Cleo Springs"
  },
  {
    "value": "73730",
    "label": "Covington - OK 73730",
    "county": "Garfield County",
    "state": "OK",
    "city": "Covington"
  },
  {
    "value": "73731",
    "label": "Dacoma - OK 73731",
    "county": "Woods County",
    "state": "OK",
    "city": "Dacoma"
  },
  {
    "value": "73733",
    "label": "Douglas - OK 73733",
    "county": "Garfield County",
    "state": "OK",
    "city": "Douglas"
  },
  {
    "value": "73734",
    "label": "Dover - OK 73734",
    "county": "Kingfisher County",
    "state": "OK",
    "city": "Dover"
  },
  {
    "value": "73735",
    "label": "Drummond - OK 73735",
    "county": "Garfield County",
    "state": "OK",
    "city": "Drummond"
  },
  {
    "value": "73736",
    "label": "Fairmont - OK 73736",
    "county": "Garfield County",
    "state": "OK",
    "city": "Fairmont"
  },
  {
    "value": "73737",
    "label": "Fairview - OK 73737",
    "county": "Major County",
    "state": "OK",
    "city": "Fairview"
  },
  {
    "value": "73738",
    "label": "Garber - OK 73738",
    "county": "Garfield County",
    "state": "OK",
    "city": "Garber"
  },
  {
    "value": "73739",
    "label": "Goltry - OK 73739",
    "county": "Alfalfa County",
    "state": "OK",
    "city": "Goltry"
  },
  {
    "value": "73741",
    "label": "Helena - OK 73741",
    "county": "Alfalfa County",
    "state": "OK",
    "city": "Helena"
  },
  {
    "value": "73742",
    "label": "Hennessey - OK 73742",
    "county": "Kingfisher County",
    "state": "OK",
    "city": "Hennessey"
  },
  {
    "value": "73744",
    "label": "Hitchcock - OK 73744",
    "county": "Blaine County",
    "state": "OK",
    "city": "Hitchcock"
  },
  {
    "value": "73747",
    "label": "Isabella - OK 73747",
    "county": "Major County",
    "state": "OK",
    "city": "Isabella"
  },
  {
    "value": "73749",
    "label": "Jet - OK 73749",
    "county": "Alfalfa County",
    "state": "OK",
    "city": "Jet"
  },
  {
    "value": "73750",
    "label": "Kingfisher - OK 73750",
    "county": "Kingfisher County",
    "state": "OK",
    "city": "Kingfisher"
  },
  {
    "value": "73753",
    "label": "Kremlin - OK 73753",
    "county": "Garfield County",
    "state": "OK",
    "city": "Kremlin"
  },
  {
    "value": "73754",
    "label": "Lahoma - OK 73754",
    "county": "Garfield County",
    "state": "OK",
    "city": "Lahoma"
  },
  {
    "value": "73755",
    "label": "Longdale - OK 73755",
    "county": "Major County",
    "state": "OK",
    "city": "Longdale"
  },
  {
    "value": "73756",
    "label": "Loyal - OK 73756",
    "county": "Kingfisher County",
    "state": "OK",
    "city": "Loyal"
  },
  {
    "value": "73757",
    "label": "Lucien - OK 73757",
    "county": "Noble County",
    "state": "OK",
    "city": "Lucien"
  },
  {
    "value": "73758",
    "label": "Manchester - OK 73758",
    "county": "Grant County",
    "state": "OK",
    "city": "Manchester"
  },
  {
    "value": "73759",
    "label": "Medford - OK 73759",
    "county": "Grant County",
    "state": "OK",
    "city": "Medford"
  },
  {
    "value": "73760",
    "label": "Meno - OK 73760",
    "county": "Major County",
    "state": "OK",
    "city": "Meno"
  },
  {
    "value": "73761",
    "label": "Nash - OK 73761",
    "county": "Grant County",
    "state": "OK",
    "city": "Nash"
  },
  {
    "value": "73762",
    "label": "Okarche - OK 73762",
    "county": "Kingfisher County",
    "state": "OK",
    "city": "Okarche"
  },
  {
    "value": "73763",
    "label": "Okeene - OK 73763",
    "county": "Major County",
    "state": "OK",
    "city": "Okeene"
  },
  {
    "value": "73764",
    "label": "Omega - OK 73764",
    "county": "Kingfisher County",
    "state": "OK",
    "city": "Omega"
  },
  {
    "value": "73766",
    "label": "Pond Creek - OK 73766",
    "county": "Grant County",
    "state": "OK",
    "city": "Pond Creek"
  },
  {
    "value": "73768",
    "label": "Ringwood - OK 73768",
    "county": "Major County",
    "state": "OK",
    "city": "Ringwood"
  },
  {
    "value": "73770",
    "label": "Southard - OK 73770",
    "county": "Blaine County",
    "state": "OK",
    "city": "Southard"
  },
  {
    "value": "73771",
    "label": "Wakita - OK 73771",
    "county": "Grant County",
    "state": "OK",
    "city": "Wakita"
  },
  {
    "value": "73772",
    "label": "Watonga - OK 73772",
    "county": "Blaine County",
    "state": "OK",
    "city": "Watonga"
  },
  {
    "value": "73773",
    "label": "Waukomis - OK 73773",
    "county": "Garfield County",
    "state": "OK",
    "city": "Waukomis"
  },
  {
    "value": "73801",
    "label": "Woodward - OK 73801",
    "county": "Woodward County",
    "state": "OK",
    "city": "Woodward"
  },
  {
    "value": "73832",
    "label": "Arnett - OK 73832",
    "county": "Ellis County",
    "state": "OK",
    "city": "Arnett"
  },
  {
    "value": "73834",
    "label": "Buffalo - OK 73834",
    "county": "Harper County",
    "state": "OK",
    "city": "Buffalo"
  },
  {
    "value": "73835",
    "label": "Camargo - OK 73835",
    "county": "Dewey County",
    "state": "OK",
    "city": "Camargo"
  },
  {
    "value": "73838",
    "label": "Chester - OK 73838",
    "county": "Major County",
    "state": "OK",
    "city": "Chester"
  },
  {
    "value": "73840",
    "label": "Fargo - OK 73840",
    "county": "Ellis County",
    "state": "OK",
    "city": "Fargo"
  },
  {
    "value": "73841",
    "label": "Fort Supply - OK 73841",
    "county": "Woodward County",
    "state": "OK",
    "city": "Fort Supply"
  },
  {
    "value": "73842",
    "label": "Freedom - OK 73842",
    "county": "Woods County",
    "state": "OK",
    "city": "Freedom"
  },
  {
    "value": "73843",
    "label": "Gage - OK 73843",
    "county": "Ellis County",
    "state": "OK",
    "city": "Gage"
  },
  {
    "value": "73844",
    "label": "Gate - OK 73844",
    "county": "Beaver County",
    "state": "OK",
    "city": "Gate"
  },
  {
    "value": "73848",
    "label": "Laverne - OK 73848",
    "county": "Harper County",
    "state": "OK",
    "city": "Laverne"
  },
  {
    "value": "73851",
    "label": "May - OK 73851",
    "county": "Harper County",
    "state": "OK",
    "city": "May"
  },
  {
    "value": "73852",
    "label": "Mooreland - OK 73852",
    "county": "Woodward County",
    "state": "OK",
    "city": "Mooreland"
  },
  {
    "value": "73853",
    "label": "Mutual - OK 73853",
    "county": "Woodward County",
    "state": "OK",
    "city": "Mutual"
  },
  {
    "value": "73855",
    "label": "Rosston - OK 73855",
    "county": "Harper County",
    "state": "OK",
    "city": "Rosston"
  },
  {
    "value": "73857",
    "label": "Sharon - OK 73857",
    "county": "Woodward County",
    "state": "OK",
    "city": "Sharon"
  },
  {
    "value": "73858",
    "label": "Shattuck - OK 73858",
    "county": "Ellis County",
    "state": "OK",
    "city": "Shattuck"
  },
  {
    "value": "73859",
    "label": "Vici - OK 73859",
    "county": "Woodward County",
    "state": "OK",
    "city": "Vici"
  },
  {
    "value": "73860",
    "label": "Waynoka - OK 73860",
    "county": "Woods County",
    "state": "OK",
    "city": "Waynoka"
  },
  {
    "value": "73931",
    "label": "Balko - OK 73931",
    "county": "Beaver County",
    "state": "OK",
    "city": "Balko"
  },
  {
    "value": "73932",
    "label": "Beaver - OK 73932",
    "county": "Beaver County",
    "state": "OK",
    "city": "Beaver"
  },
  {
    "value": "73933",
    "label": "Boise City - OK 73933",
    "county": "Cimarron County",
    "state": "OK",
    "city": "Boise City"
  },
  {
    "value": "73937",
    "label": "Felt - OK 73937",
    "county": "Cimarron County",
    "state": "OK",
    "city": "Felt"
  },
  {
    "value": "73938",
    "label": "Forgan - OK 73938",
    "county": "Beaver County",
    "state": "OK",
    "city": "Forgan"
  },
  {
    "value": "73939",
    "label": "Goodwell - OK 73939",
    "county": "Texas County",
    "state": "OK",
    "city": "Goodwell"
  },
  {
    "value": "73942",
    "label": "Guymon - OK 73942",
    "county": "Texas County",
    "state": "OK",
    "city": "Guymon"
  },
  {
    "value": "73944",
    "label": "Hardesty - OK 73944",
    "county": "Texas County",
    "state": "OK",
    "city": "Hardesty"
  },
  {
    "value": "73945",
    "label": "Hooker - OK 73945",
    "county": "Texas County",
    "state": "OK",
    "city": "Hooker"
  },
  {
    "value": "73946",
    "label": "Kenton - OK 73946",
    "county": "Cimarron County",
    "state": "OK",
    "city": "Kenton"
  },
  {
    "value": "73947",
    "label": "Keyes - OK 73947",
    "county": "Cimarron County",
    "state": "OK",
    "city": "Keyes"
  },
  {
    "value": "73949",
    "label": "Texhoma - OK 73949",
    "county": "Texas County",
    "state": "OK",
    "city": "Texhoma"
  },
  {
    "value": "73950",
    "label": "Turpin - OK 73950",
    "county": "Beaver County",
    "state": "OK",
    "city": "Turpin"
  },
  {
    "value": "73951",
    "label": "Tyrone - OK 73951",
    "county": "Texas County",
    "state": "OK",
    "city": "Tyrone"
  },
  {
    "value": "74002",
    "label": "Barnsdall - OK 74002",
    "county": "Osage County",
    "state": "OK",
    "city": "Barnsdall"
  },
  {
    "value": "74003",
    "label": "Bartlesville - OK 74003",
    "county": "Osage County",
    "state": "OK",
    "city": "Bartlesville"
  },
  {
    "value": "74006",
    "label": "Bartlesville - OK 74006",
    "county": "Washington County",
    "state": "OK",
    "city": "Bartlesville"
  },
  {
    "value": "74008",
    "label": "Bixby - OK 74008",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Bixby"
  },
  {
    "value": "74010",
    "label": "Bristow - OK 74010",
    "county": "Creek County",
    "state": "OK",
    "city": "Bristow"
  },
  {
    "value": "74011",
    "label": "Broken Arrow - OK 74011",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Broken Arrow"
  },
  {
    "value": "74012",
    "label": "Broken Arrow - OK 74012",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Broken Arrow"
  },
  {
    "value": "74014",
    "label": "Broken Arrow - OK 74014",
    "county": "Wagoner County",
    "state": "OK",
    "city": "Broken Arrow"
  },
  {
    "value": "74015",
    "label": "Catoosa - OK 74015",
    "county": "Rogers County",
    "state": "OK",
    "city": "Catoosa"
  },
  {
    "value": "74016",
    "label": "Chelsea - OK 74016",
    "county": "Rogers County",
    "state": "OK",
    "city": "Chelsea"
  },
  {
    "value": "74017",
    "label": "Claremore - OK 74017",
    "county": "Rogers County",
    "state": "OK",
    "city": "Claremore"
  },
  {
    "value": "74019",
    "label": "Claremore - OK 74019",
    "county": "Rogers County",
    "state": "OK",
    "city": "Claremore"
  },
  {
    "value": "74020",
    "label": "Cleveland - OK 74020",
    "county": "Pawnee County",
    "state": "OK",
    "city": "Cleveland"
  },
  {
    "value": "74021",
    "label": "Collinsville - OK 74021",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Collinsville"
  },
  {
    "value": "74022",
    "label": "Copan - OK 74022",
    "county": "Washington County",
    "state": "OK",
    "city": "Copan"
  },
  {
    "value": "74023",
    "label": "Cushing - OK 74023",
    "county": "Payne County",
    "state": "OK",
    "city": "Cushing"
  },
  {
    "value": "74027",
    "label": "Delaware - OK 74027",
    "county": "Nowata County",
    "state": "OK",
    "city": "Delaware"
  },
  {
    "value": "74028",
    "label": "Depew - OK 74028",
    "county": "Creek County",
    "state": "OK",
    "city": "Depew"
  },
  {
    "value": "74029",
    "label": "Dewey - OK 74029",
    "county": "Washington County",
    "state": "OK",
    "city": "Dewey"
  },
  {
    "value": "74030",
    "label": "Drumright - OK 74030",
    "county": "Creek County",
    "state": "OK",
    "city": "Drumright"
  },
  {
    "value": "74032",
    "label": "Glencoe - OK 74032",
    "county": "Payne County",
    "state": "OK",
    "city": "Glencoe"
  },
  {
    "value": "74033",
    "label": "Glenpool - OK 74033",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Glenpool"
  },
  {
    "value": "74035",
    "label": "Hominy - OK 74035",
    "county": "Osage County",
    "state": "OK",
    "city": "Hominy"
  },
  {
    "value": "74036",
    "label": "Inola - OK 74036",
    "county": "Rogers County",
    "state": "OK",
    "city": "Inola"
  },
  {
    "value": "74037",
    "label": "Jenks - OK 74037",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Jenks"
  },
  {
    "value": "74038",
    "label": "Jennings - OK 74038",
    "county": "Pawnee County",
    "state": "OK",
    "city": "Jennings"
  },
  {
    "value": "74039",
    "label": "Kellyville - OK 74039",
    "county": "Creek County",
    "state": "OK",
    "city": "Kellyville"
  },
  {
    "value": "74041",
    "label": "Kiefer - OK 74041",
    "county": "Creek County",
    "state": "OK",
    "city": "Kiefer"
  },
  {
    "value": "74042",
    "label": "Lenapah - OK 74042",
    "county": "Nowata County",
    "state": "OK",
    "city": "Lenapah"
  },
  {
    "value": "74044",
    "label": "Mannford - OK 74044",
    "county": "Creek County",
    "state": "OK",
    "city": "Mannford"
  },
  {
    "value": "74045",
    "label": "Maramec - OK 74045",
    "county": "Payne County",
    "state": "OK",
    "city": "Maramec"
  },
  {
    "value": "74047",
    "label": "Mounds - OK 74047",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Mounds"
  },
  {
    "value": "74048",
    "label": "Nowata - OK 74048",
    "county": "Nowata County",
    "state": "OK",
    "city": "Nowata"
  },
  {
    "value": "74051",
    "label": "Ochelata - OK 74051",
    "county": "Washington County",
    "state": "OK",
    "city": "Ochelata"
  },
  {
    "value": "74053",
    "label": "Oologah - OK 74053",
    "county": "Rogers County",
    "state": "OK",
    "city": "Oologah"
  },
  {
    "value": "74054",
    "label": "Osage - OK 74054",
    "county": "Osage County",
    "state": "OK",
    "city": "Osage"
  },
  {
    "value": "74055",
    "label": "Owasso - OK 74055",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Owasso"
  },
  {
    "value": "74056",
    "label": "Pawhuska - OK 74056",
    "county": "Osage County",
    "state": "OK",
    "city": "Pawhuska"
  },
  {
    "value": "74058",
    "label": "Pawnee - OK 74058",
    "county": "Pawnee County",
    "state": "OK",
    "city": "Pawnee"
  },
  {
    "value": "74059",
    "label": "Perkins - OK 74059",
    "county": "Payne County",
    "state": "OK",
    "city": "Perkins"
  },
  {
    "value": "74060",
    "label": "Prue - OK 74060",
    "county": "Osage County",
    "state": "OK",
    "city": "Prue"
  },
  {
    "value": "74061",
    "label": "Ramona - OK 74061",
    "county": "Washington County",
    "state": "OK",
    "city": "Ramona"
  },
  {
    "value": "74062",
    "label": "Ripley - OK 74062",
    "county": "Payne County",
    "state": "OK",
    "city": "Ripley"
  },
  {
    "value": "74063",
    "label": "Sand Springs - OK 74063",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Sand Springs"
  },
  {
    "value": "74066",
    "label": "Sapulpa - OK 74066",
    "county": "Creek County",
    "state": "OK",
    "city": "Sapulpa"
  },
  {
    "value": "74070",
    "label": "Skiatook - OK 74070",
    "county": "Osage County",
    "state": "OK",
    "city": "Skiatook"
  },
  {
    "value": "74072",
    "label": "S Coffeyville - OK 74072",
    "county": "Nowata County",
    "state": "OK",
    "city": "S Coffeyville"
  },
  {
    "value": "74073",
    "label": "Sperry - OK 74073",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Sperry"
  },
  {
    "value": "74074",
    "label": "Stillwater - OK 74074",
    "county": "Payne County",
    "state": "OK",
    "city": "Stillwater"
  },
  {
    "value": "74075",
    "label": "Stillwater - OK 74075",
    "county": "Payne County",
    "state": "OK",
    "city": "Stillwater"
  },
  {
    "value": "74079",
    "label": "Stroud - OK 74079",
    "county": "Lincoln County",
    "state": "OK",
    "city": "Stroud"
  },
  {
    "value": "74080",
    "label": "Talala - OK 74080",
    "county": "Rogers County",
    "state": "OK",
    "city": "Talala"
  },
  {
    "value": "74081",
    "label": "Terlton - OK 74081",
    "county": "Pawnee County",
    "state": "OK",
    "city": "Terlton"
  },
  {
    "value": "74083",
    "label": "Wann - OK 74083",
    "county": "Nowata County",
    "state": "OK",
    "city": "Wann"
  },
  {
    "value": "74084",
    "label": "Wynona - OK 74084",
    "county": "Osage County",
    "state": "OK",
    "city": "Wynona"
  },
  {
    "value": "74085",
    "label": "Yale - OK 74085",
    "county": "Payne County",
    "state": "OK",
    "city": "Yale"
  },
  {
    "value": "74103",
    "label": "Tulsa - OK 74103",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74104",
    "label": "Tulsa - OK 74104",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74105",
    "label": "Tulsa - OK 74105",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74106",
    "label": "Tulsa - OK 74106",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74107",
    "label": "Tulsa - OK 74107",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74108",
    "label": "Tulsa - OK 74108",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74110",
    "label": "Tulsa - OK 74110",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74112",
    "label": "Tulsa - OK 74112",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74114",
    "label": "Tulsa - OK 74114",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74115",
    "label": "Tulsa - OK 74115",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74116",
    "label": "Tulsa - OK 74116",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74117",
    "label": "Tulsa - OK 74117",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74119",
    "label": "Tulsa - OK 74119",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74120",
    "label": "Tulsa - OK 74120",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74126",
    "label": "Tulsa - OK 74126",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74127",
    "label": "Tulsa - OK 74127",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74128",
    "label": "Tulsa - OK 74128",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74129",
    "label": "Tulsa - OK 74129",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74130",
    "label": "Tulsa - OK 74130",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74131",
    "label": "Tulsa - OK 74131",
    "county": "Creek County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74132",
    "label": "Tulsa - OK 74132",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74133",
    "label": "Tulsa - OK 74133",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74134",
    "label": "Tulsa - OK 74134",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74135",
    "label": "Tulsa - OK 74135",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74136",
    "label": "Tulsa - OK 74136",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74137",
    "label": "Tulsa - OK 74137",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74145",
    "label": "Tulsa - OK 74145",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74146",
    "label": "Tulsa - OK 74146",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74172",
    "label": "Tulsa - OK 74172",
    "county": "Tulsa County",
    "state": "OK",
    "city": "Tulsa"
  },
  {
    "value": "74301",
    "label": "Vinita - OK 74301",
    "county": "Craig County",
    "state": "OK",
    "city": "Vinita"
  },
  {
    "value": "74330",
    "label": "Adair - OK 74330",
    "county": "Mayes County",
    "state": "OK",
    "city": "Adair"
  },
  {
    "value": "74331",
    "label": "Afton - OK 74331",
    "county": "Delaware County",
    "state": "OK",
    "city": "Afton"
  },
  {
    "value": "74332",
    "label": "Big Cabin - OK 74332",
    "county": "Craig County",
    "state": "OK",
    "city": "Big Cabin"
  },
  {
    "value": "74333",
    "label": "Bluejacket - OK 74333",
    "county": "Craig County",
    "state": "OK",
    "city": "Bluejacket"
  },
  {
    "value": "74337",
    "label": "Chouteau - OK 74337",
    "county": "Mayes County",
    "state": "OK",
    "city": "Chouteau"
  },
  {
    "value": "74338",
    "label": "Colcord - OK 74338",
    "county": "Delaware County",
    "state": "OK",
    "city": "Colcord"
  },
  {
    "value": "74339",
    "label": "Commerce - OK 74339",
    "county": "Ottawa County",
    "state": "OK",
    "city": "Commerce"
  },
  {
    "value": "74342",
    "label": "Eucha - OK 74342",
    "county": "Delaware County",
    "state": "OK",
    "city": "Eucha"
  },
  {
    "value": "74343",
    "label": "Fairland - OK 74343",
    "county": "Ottawa County",
    "state": "OK",
    "city": "Fairland"
  },
  {
    "value": "74344",
    "label": "Grove - OK 74344",
    "county": "Delaware County",
    "state": "OK",
    "city": "Grove"
  },
  {
    "value": "74346",
    "label": "Jay - OK 74346",
    "county": "Delaware County",
    "state": "OK",
    "city": "Jay"
  },
  {
    "value": "74347",
    "label": "Kansas - OK 74347",
    "county": "Delaware County",
    "state": "OK",
    "city": "Kansas"
  },
  {
    "value": "74352",
    "label": "Locust Grove - OK 74352",
    "county": "Mayes County",
    "state": "OK",
    "city": "Locust Grove"
  },
  {
    "value": "74354",
    "label": "Miami - OK 74354",
    "county": "Ottawa County",
    "state": "OK",
    "city": "Miami"
  },
  {
    "value": "74359",
    "label": "Oaks - OK 74359",
    "county": "Delaware County",
    "state": "OK",
    "city": "Oaks"
  },
  {
    "value": "74360",
    "label": "Picher - OK 74360",
    "county": "Ottawa County",
    "state": "OK",
    "city": "Picher"
  },
  {
    "value": "74361",
    "label": "Pryor - OK 74361",
    "county": "Mayes County",
    "state": "OK",
    "city": "Pryor"
  },
  {
    "value": "74363",
    "label": "Quapaw - OK 74363",
    "county": "Ottawa County",
    "state": "OK",
    "city": "Quapaw"
  },
  {
    "value": "74364",
    "label": "Rose - OK 74364",
    "county": "Delaware County",
    "state": "OK",
    "city": "Rose"
  },
  {
    "value": "74365",
    "label": "Salina - OK 74365",
    "county": "Mayes County",
    "state": "OK",
    "city": "Salina"
  },
  {
    "value": "74366",
    "label": "Spavinaw - OK 74366",
    "county": "Mayes County",
    "state": "OK",
    "city": "Spavinaw"
  },
  {
    "value": "74367",
    "label": "Strang - OK 74367",
    "county": "Mayes County",
    "state": "OK",
    "city": "Strang"
  },
  {
    "value": "74368",
    "label": "Twin Oaks - OK 74368",
    "county": "Delaware County",
    "state": "OK",
    "city": "Twin Oaks"
  },
  {
    "value": "74369",
    "label": "Welch - OK 74369",
    "county": "Craig County",
    "state": "OK",
    "city": "Welch"
  },
  {
    "value": "74370",
    "label": "Wyandotte - OK 74370",
    "county": "Ottawa County",
    "state": "OK",
    "city": "Wyandotte"
  },
  {
    "value": "74401",
    "label": "Muskogee - OK 74401",
    "county": "Muskogee County",
    "state": "OK",
    "city": "Muskogee"
  },
  {
    "value": "74403",
    "label": "Muskogee - OK 74403",
    "county": "Muskogee County",
    "state": "OK",
    "city": "Muskogee"
  },
  {
    "value": "74421",
    "label": "Beggs - OK 74421",
    "county": "Okmulgee County",
    "state": "OK",
    "city": "Beggs"
  },
  {
    "value": "74422",
    "label": "Boynton - OK 74422",
    "county": "Muskogee County",
    "state": "OK",
    "city": "Boynton"
  },
  {
    "value": "74423",
    "label": "Braggs - OK 74423",
    "county": "Muskogee County",
    "state": "OK",
    "city": "Braggs"
  },
  {
    "value": "74425",
    "label": "Canadian - OK 74425",
    "county": "Pittsburg County",
    "state": "OK",
    "city": "Canadian"
  },
  {
    "value": "74426",
    "label": "Checotah - OK 74426",
    "county": "McIntosh County",
    "state": "OK",
    "city": "Checotah"
  },
  {
    "value": "74427",
    "label": "Cookson - OK 74427",
    "county": "Cherokee County",
    "state": "OK",
    "city": "Cookson"
  },
  {
    "value": "74428",
    "label": "Council Hill - OK 74428",
    "county": "McIntosh County",
    "state": "OK",
    "city": "Council Hill"
  },
  {
    "value": "74429",
    "label": "Coweta - OK 74429",
    "county": "Wagoner County",
    "state": "OK",
    "city": "Coweta"
  },
  {
    "value": "74432",
    "label": "Eufaula - OK 74432",
    "county": "McIntosh County",
    "state": "OK",
    "city": "Eufaula"
  },
  {
    "value": "74434",
    "label": "Fort Gibson - OK 74434",
    "county": "Muskogee County",
    "state": "OK",
    "city": "Fort Gibson"
  },
  {
    "value": "74435",
    "label": "Gore - OK 74435",
    "county": "Sequoyah County",
    "state": "OK",
    "city": "Gore"
  },
  {
    "value": "74436",
    "label": "Haskell - OK 74436",
    "county": "Muskogee County",
    "state": "OK",
    "city": "Haskell"
  },
  {
    "value": "74437",
    "label": "Henryetta - OK 74437",
    "county": "Okmulgee County",
    "state": "OK",
    "city": "Henryetta"
  },
  {
    "value": "74441",
    "label": "Hulbert - OK 74441",
    "county": "Cherokee County",
    "state": "OK",
    "city": "Hulbert"
  },
  {
    "value": "74442",
    "label": "Indianola - OK 74442",
    "county": "Pittsburg County",
    "state": "OK",
    "city": "Indianola"
  },
  {
    "value": "74445",
    "label": "Morris - OK 74445",
    "county": "Okmulgee County",
    "state": "OK",
    "city": "Morris"
  },
  {
    "value": "74447",
    "label": "Okmulgee - OK 74447",
    "county": "Okmulgee County",
    "state": "OK",
    "city": "Okmulgee"
  },
  {
    "value": "74450",
    "label": "Oktaha - OK 74450",
    "county": "Muskogee County",
    "state": "OK",
    "city": "Oktaha"
  },
  {
    "value": "74451",
    "label": "Park Hill - OK 74451",
    "county": "Cherokee County",
    "state": "OK",
    "city": "Park Hill"
  },
  {
    "value": "74452",
    "label": "Peggs - OK 74452",
    "county": "Cherokee County",
    "state": "OK",
    "city": "Peggs"
  },
  {
    "value": "74454",
    "label": "Porter - OK 74454",
    "county": "Wagoner County",
    "state": "OK",
    "city": "Porter"
  },
  {
    "value": "74455",
    "label": "Porum - OK 74455",
    "county": "Muskogee County",
    "state": "OK",
    "city": "Porum"
  },
  {
    "value": "74457",
    "label": "Proctor - OK 74457",
    "county": "Adair County",
    "state": "OK",
    "city": "Proctor"
  },
  {
    "value": "74458",
    "label": "Redbird - OK 74458",
    "county": "Wagoner County",
    "state": "OK",
    "city": "Redbird"
  },
  {
    "value": "74461",
    "label": "Stidham - OK 74461",
    "county": "McIntosh County",
    "state": "OK",
    "city": "Stidham"
  },
  {
    "value": "74462",
    "label": "Stigler - OK 74462",
    "county": "Haskell County",
    "state": "OK",
    "city": "Stigler"
  },
  {
    "value": "74464",
    "label": "Tahlequah - OK 74464",
    "county": "Cherokee County",
    "state": "OK",
    "city": "Tahlequah"
  },
  {
    "value": "74467",
    "label": "Wagoner - OK 74467",
    "county": "Wagoner County",
    "state": "OK",
    "city": "Wagoner"
  },
  {
    "value": "74469",
    "label": "Warner - OK 74469",
    "county": "Muskogee County",
    "state": "OK",
    "city": "Warner"
  },
  {
    "value": "74470",
    "label": "Webbers Falls - OK 74470",
    "county": "Muskogee County",
    "state": "OK",
    "city": "Webbers Falls"
  },
  {
    "value": "74471",
    "label": "Welling - OK 74471",
    "county": "Cherokee County",
    "state": "OK",
    "city": "Welling"
  },
  {
    "value": "74472",
    "label": "Whitefield - OK 74472",
    "county": "Haskell County",
    "state": "OK",
    "city": "Whitefield"
  },
  {
    "value": "74501",
    "label": "Mcalester - OK 74501",
    "county": "Pittsburg County",
    "state": "OK",
    "city": "Mcalester"
  },
  {
    "value": "74523",
    "label": "Antlers - OK 74523",
    "county": "Pushmataha County",
    "state": "OK",
    "city": "Antlers"
  },
  {
    "value": "74525",
    "label": "Atoka - OK 74525",
    "county": "Atoka County",
    "state": "OK",
    "city": "Atoka"
  },
  {
    "value": "74528",
    "label": "Blanco - OK 74528",
    "county": "Pittsburg County",
    "state": "OK",
    "city": "Blanco"
  },
  {
    "value": "74529",
    "label": "Blocker - OK 74529",
    "county": "Pittsburg County",
    "state": "OK",
    "city": "Blocker"
  },
  {
    "value": "74531",
    "label": "Calvin - OK 74531",
    "county": "Hughes County",
    "state": "OK",
    "city": "Calvin"
  },
  {
    "value": "74533",
    "label": "Caney - OK 74533",
    "county": "Atoka County",
    "state": "OK",
    "city": "Caney"
  },
  {
    "value": "74534",
    "label": "Centrahoma - OK 74534",
    "county": "Coal County",
    "state": "OK",
    "city": "Centrahoma"
  },
  {
    "value": "74536",
    "label": "Clayton - OK 74536",
    "county": "Pushmataha County",
    "state": "OK",
    "city": "Clayton"
  },
  {
    "value": "74538",
    "label": "Coalgate - OK 74538",
    "county": "Coal County",
    "state": "OK",
    "city": "Coalgate"
  },
  {
    "value": "74540",
    "label": "Daisy - OK 74540",
    "county": "Atoka County",
    "state": "OK",
    "city": "Daisy"
  },
  {
    "value": "74542",
    "label": "Atoka - OK 74542",
    "county": "Atoka County",
    "state": "OK",
    "city": "Atoka"
  },
  {
    "value": "74543",
    "label": "Finley - OK 74543",
    "county": "Pushmataha County",
    "state": "OK",
    "city": "Finley"
  },
  {
    "value": "74547",
    "label": "Hartshorne - OK 74547",
    "county": "Pittsburg County",
    "state": "OK",
    "city": "Hartshorne"
  },
  {
    "value": "74549",
    "label": "Honobia - OK 74549",
    "county": "Pushmataha County",
    "state": "OK",
    "city": "Honobia"
  },
  {
    "value": "74552",
    "label": "Kinta - OK 74552",
    "county": "Haskell County",
    "state": "OK",
    "city": "Kinta"
  },
  {
    "value": "74553",
    "label": "Kiowa - OK 74553",
    "county": "Pittsburg County",
    "state": "OK",
    "city": "Kiowa"
  },
  {
    "value": "74555",
    "label": "Lane - OK 74555",
    "county": "Atoka County",
    "state": "OK",
    "city": "Lane"
  },
  {
    "value": "74557",
    "label": "Moyers - OK 74557",
    "county": "Pushmataha County",
    "state": "OK",
    "city": "Moyers"
  },
  {
    "value": "74558",
    "label": "Nashoba - OK 74558",
    "county": "Pushmataha County",
    "state": "OK",
    "city": "Nashoba"
  },
  {
    "value": "74560",
    "label": "Pittsburg - OK 74560",
    "county": "Pittsburg County",
    "state": "OK",
    "city": "Pittsburg"
  },
  {
    "value": "74561",
    "label": "Quinton - OK 74561",
    "county": "Pittsburg County",
    "state": "OK",
    "city": "Quinton"
  },
  {
    "value": "74562",
    "label": "Rattan - OK 74562",
    "county": "Pushmataha County",
    "state": "OK",
    "city": "Rattan"
  },
  {
    "value": "74563",
    "label": "Red Oak - OK 74563",
    "county": "Latimer County",
    "state": "OK",
    "city": "Red Oak"
  },
  {
    "value": "74567",
    "label": "Snow - OK 74567",
    "county": "Pushmataha County",
    "state": "OK",
    "city": "Snow"
  },
  {
    "value": "74569",
    "label": "Stringtown - OK 74569",
    "county": "Atoka County",
    "state": "OK",
    "city": "Stringtown"
  },
  {
    "value": "74570",
    "label": "Stuart - OK 74570",
    "county": "Hughes County",
    "state": "OK",
    "city": "Stuart"
  },
  {
    "value": "74571",
    "label": "Talihina - OK 74571",
    "county": "Le Flore County",
    "state": "OK",
    "city": "Talihina"
  },
  {
    "value": "74572",
    "label": "Tupelo - OK 74572",
    "county": "Coal County",
    "state": "OK",
    "city": "Tupelo"
  },
  {
    "value": "74574",
    "label": "Tuskahoma - OK 74574",
    "county": "Pushmataha County",
    "state": "OK",
    "city": "Tuskahoma"
  },
  {
    "value": "74576",
    "label": "Wardville - OK 74576",
    "county": "Atoka County",
    "state": "OK",
    "city": "Wardville"
  },
  {
    "value": "74577",
    "label": "Whitesboro - OK 74577",
    "county": "Le Flore County",
    "state": "OK",
    "city": "Whitesboro"
  },
  {
    "value": "74578",
    "label": "Wilburton - OK 74578",
    "county": "Latimer County",
    "state": "OK",
    "city": "Wilburton"
  },
  {
    "value": "74601",
    "label": "Ponca City - OK 74601",
    "county": "Kay County",
    "state": "OK",
    "city": "Ponca City"
  },
  {
    "value": "74604",
    "label": "Ponca City - OK 74604",
    "county": "Kay County",
    "state": "OK",
    "city": "Ponca City"
  },
  {
    "value": "74630",
    "label": "Billings - OK 74630",
    "county": "Noble County",
    "state": "OK",
    "city": "Billings"
  },
  {
    "value": "74631",
    "label": "Blackwell - OK 74631",
    "county": "Kay County",
    "state": "OK",
    "city": "Blackwell"
  },
  {
    "value": "74632",
    "label": "Braman - OK 74632",
    "county": "Kay County",
    "state": "OK",
    "city": "Braman"
  },
  {
    "value": "74633",
    "label": "Burbank - OK 74633",
    "county": "Osage County",
    "state": "OK",
    "city": "Burbank"
  },
  {
    "value": "74636",
    "label": "Deer Creek - OK 74636",
    "county": "Grant County",
    "state": "OK",
    "city": "Deer Creek"
  },
  {
    "value": "74637",
    "label": "Fairfax - OK 74637",
    "county": "Osage County",
    "state": "OK",
    "city": "Fairfax"
  },
  {
    "value": "74640",
    "label": "Hunter - OK 74640",
    "county": "Garfield County",
    "state": "OK",
    "city": "Hunter"
  },
  {
    "value": "74641",
    "label": "Kaw City - OK 74641",
    "county": "Kay County",
    "state": "OK",
    "city": "Kaw City"
  },
  {
    "value": "74643",
    "label": "Lamont - OK 74643",
    "county": "Grant County",
    "state": "OK",
    "city": "Lamont"
  },
  {
    "value": "74644",
    "label": "Marland - OK 74644",
    "county": "Noble County",
    "state": "OK",
    "city": "Marland"
  },
  {
    "value": "74646",
    "label": "Nardin - OK 74646",
    "county": "Kay County",
    "state": "OK",
    "city": "Nardin"
  },
  {
    "value": "74647",
    "label": "Newkirk - OK 74647",
    "county": "Kay County",
    "state": "OK",
    "city": "Newkirk"
  },
  {
    "value": "74650",
    "label": "Ralston - OK 74650",
    "county": "Pawnee County",
    "state": "OK",
    "city": "Ralston"
  },
  {
    "value": "74651",
    "label": "Red Rock - OK 74651",
    "county": "Noble County",
    "state": "OK",
    "city": "Red Rock"
  },
  {
    "value": "74652",
    "label": "Shidler - OK 74652",
    "county": "Osage County",
    "state": "OK",
    "city": "Shidler"
  },
  {
    "value": "74653",
    "label": "Tonkawa - OK 74653",
    "county": "Noble County",
    "state": "OK",
    "city": "Tonkawa"
  },
  {
    "value": "74701",
    "label": "Durant - OK 74701",
    "county": "Bryan County",
    "state": "OK",
    "city": "Durant"
  },
  {
    "value": "74723",
    "label": "Bennington - OK 74723",
    "county": "Bryan County",
    "state": "OK",
    "city": "Bennington"
  },
  {
    "value": "74724",
    "label": "Bethel - OK 74724",
    "county": "McCurtain County",
    "state": "OK",
    "city": "Bethel"
  },
  {
    "value": "74726",
    "label": "Bokchito - OK 74726",
    "county": "Bryan County",
    "state": "OK",
    "city": "Bokchito"
  },
  {
    "value": "74727",
    "label": "Boswell - OK 74727",
    "county": "Choctaw County",
    "state": "OK",
    "city": "Boswell"
  },
  {
    "value": "74728",
    "label": "Broken Bow - OK 74728",
    "county": "McCurtain County",
    "state": "OK",
    "city": "Broken Bow"
  },
  {
    "value": "74729",
    "label": "Caddo - OK 74729",
    "county": "Bryan County",
    "state": "OK",
    "city": "Caddo"
  },
  {
    "value": "74730",
    "label": "Calera - OK 74730",
    "county": "Bryan County",
    "state": "OK",
    "city": "Calera"
  },
  {
    "value": "74731",
    "label": "Cartwright - OK 74731",
    "county": "Bryan County",
    "state": "OK",
    "city": "Cartwright"
  },
  {
    "value": "74733",
    "label": "Colbert - OK 74733",
    "county": "Bryan County",
    "state": "OK",
    "city": "Colbert"
  },
  {
    "value": "74734",
    "label": "Eagletown - OK 74734",
    "county": "McCurtain County",
    "state": "OK",
    "city": "Eagletown"
  },
  {
    "value": "74735",
    "label": "Fort Towson - OK 74735",
    "county": "Choctaw County",
    "state": "OK",
    "city": "Fort Towson"
  },
  {
    "value": "74736",
    "label": "Garvin - OK 74736",
    "county": "McCurtain County",
    "state": "OK",
    "city": "Garvin"
  },
  {
    "value": "74738",
    "label": "Grant - OK 74738",
    "county": "Choctaw County",
    "state": "OK",
    "city": "Grant"
  },
  {
    "value": "74740",
    "label": "Haworth - OK 74740",
    "county": "McCurtain County",
    "state": "OK",
    "city": "Haworth"
  },
  {
    "value": "74741",
    "label": "Hendrix - OK 74741",
    "county": "Bryan County",
    "state": "OK",
    "city": "Hendrix"
  },
  {
    "value": "74743",
    "label": "Hugo - OK 74743",
    "county": "Choctaw County",
    "state": "OK",
    "city": "Hugo"
  },
  {
    "value": "74745",
    "label": "Idabel - OK 74745",
    "county": "McCurtain County",
    "state": "OK",
    "city": "Idabel"
  },
  {
    "value": "74748",
    "label": "Kenefic - OK 74748",
    "county": "Bryan County",
    "state": "OK",
    "city": "Kenefic"
  },
  {
    "value": "74754",
    "label": "Ringold - OK 74754",
    "county": "McCurtain County",
    "state": "OK",
    "city": "Ringold"
  },
  {
    "value": "74755",
    "label": "Rufe - OK 74755",
    "county": "McCurtain County",
    "state": "OK",
    "city": "Rufe"
  },
  {
    "value": "74756",
    "label": "Sawyer - OK 74756",
    "county": "Choctaw County",
    "state": "OK",
    "city": "Sawyer"
  },
  {
    "value": "74759",
    "label": "Soper - OK 74759",
    "county": "Choctaw County",
    "state": "OK",
    "city": "Soper"
  },
  {
    "value": "74760",
    "label": "Spencerville - OK 74760",
    "county": "Choctaw County",
    "state": "OK",
    "city": "Spencerville"
  },
  {
    "value": "74764",
    "label": "Valliant - OK 74764",
    "county": "McCurtain County",
    "state": "OK",
    "city": "Valliant"
  },
  {
    "value": "74766",
    "label": "Wright City - OK 74766",
    "county": "McCurtain County",
    "state": "OK",
    "city": "Wright City"
  },
  {
    "value": "74801",
    "label": "Shawnee - OK 74801",
    "county": "Pottawatomie County",
    "state": "OK",
    "city": "Shawnee"
  },
  {
    "value": "74804",
    "label": "Shawnee - OK 74804",
    "county": "Pottawatomie County",
    "state": "OK",
    "city": "Shawnee"
  },
  {
    "value": "74820",
    "label": "Ada - OK 74820",
    "county": "Pontotoc County",
    "state": "OK",
    "city": "Ada"
  },
  {
    "value": "74824",
    "label": "Agra - OK 74824",
    "county": "Lincoln County",
    "state": "OK",
    "city": "Agra"
  },
  {
    "value": "74825",
    "label": "Allen - OK 74825",
    "county": "Pontotoc County",
    "state": "OK",
    "city": "Allen"
  },
  {
    "value": "74826",
    "label": "Asher - OK 74826",
    "county": "Pottawatomie County",
    "state": "OK",
    "city": "Asher"
  },
  {
    "value": "74827",
    "label": "Atwood - OK 74827",
    "county": "Hughes County",
    "state": "OK",
    "city": "Atwood"
  },
  {
    "value": "74829",
    "label": "Boley - OK 74829",
    "county": "Okfuskee County",
    "state": "OK",
    "city": "Boley"
  },
  {
    "value": "74831",
    "label": "Byars - OK 74831",
    "county": "McClain County",
    "state": "OK",
    "city": "Byars"
  },
  {
    "value": "74832",
    "label": "Carney - OK 74832",
    "county": "Lincoln County",
    "state": "OK",
    "city": "Carney"
  },
  {
    "value": "74833",
    "label": "Castle - OK 74833",
    "county": "Okfuskee County",
    "state": "OK",
    "city": "Castle"
  },
  {
    "value": "74834",
    "label": "Chandler - OK 74834",
    "county": "Lincoln County",
    "state": "OK",
    "city": "Chandler"
  },
  {
    "value": "74839",
    "label": "Dustin - OK 74839",
    "county": "Hughes County",
    "state": "OK",
    "city": "Dustin"
  },
  {
    "value": "74840",
    "label": "Earlsboro - OK 74840",
    "county": "Pottawatomie County",
    "state": "OK",
    "city": "Earlsboro"
  },
  {
    "value": "74843",
    "label": "Fitzhugh - OK 74843",
    "county": "Pontotoc County",
    "state": "OK",
    "city": "Fitzhugh"
  },
  {
    "value": "74845",
    "label": "Hanna - OK 74845",
    "county": "McIntosh County",
    "state": "OK",
    "city": "Hanna"
  },
  {
    "value": "74848",
    "label": "Holdenville - OK 74848",
    "county": "Hughes County",
    "state": "OK",
    "city": "Holdenville"
  },
  {
    "value": "74849",
    "label": "Konawa - OK 74849",
    "county": "Seminole County",
    "state": "OK",
    "city": "Konawa"
  },
  {
    "value": "74850",
    "label": "Lamar - OK 74850",
    "county": "Hughes County",
    "state": "OK",
    "city": "Lamar"
  },
  {
    "value": "74851",
    "label": "Mcloud - OK 74851",
    "county": "Pottawatomie County",
    "state": "OK",
    "city": "Mcloud"
  },
  {
    "value": "74852",
    "label": "Macomb - OK 74852",
    "county": "Pottawatomie County",
    "state": "OK",
    "city": "Macomb"
  },
  {
    "value": "74854",
    "label": "Maud - OK 74854",
    "county": "Seminole County",
    "state": "OK",
    "city": "Maud"
  },
  {
    "value": "74855",
    "label": "Meeker - OK 74855",
    "county": "Lincoln County",
    "state": "OK",
    "city": "Meeker"
  },
  {
    "value": "74856",
    "label": "Mill Creek - OK 74856",
    "county": "Johnston County",
    "state": "OK",
    "city": "Mill Creek"
  },
  {
    "value": "74857",
    "label": "Newalla - OK 74857",
    "county": "Cleveland County",
    "state": "OK",
    "city": "Newalla"
  },
  {
    "value": "74859",
    "label": "Okemah - OK 74859",
    "county": "Okfuskee County",
    "state": "OK",
    "city": "Okemah"
  },
  {
    "value": "74860",
    "label": "Paden - OK 74860",
    "county": "Okfuskee County",
    "state": "OK",
    "city": "Paden"
  },
  {
    "value": "74864",
    "label": "Prague - OK 74864",
    "county": "Lincoln County",
    "state": "OK",
    "city": "Prague"
  },
  {
    "value": "74865",
    "label": "Roff - OK 74865",
    "county": "Pontotoc County",
    "state": "OK",
    "city": "Roff"
  },
  {
    "value": "74867",
    "label": "Sasakwa - OK 74867",
    "county": "Seminole County",
    "state": "OK",
    "city": "Sasakwa"
  },
  {
    "value": "74868",
    "label": "Seminole - OK 74868",
    "county": "Seminole County",
    "state": "OK",
    "city": "Seminole"
  },
  {
    "value": "74869",
    "label": "Sparks - OK 74869",
    "county": "Lincoln County",
    "state": "OK",
    "city": "Sparks"
  },
  {
    "value": "74871",
    "label": "Stonewall - OK 74871",
    "county": "Pontotoc County",
    "state": "OK",
    "city": "Stonewall"
  },
  {
    "value": "74872",
    "label": "Stratford - OK 74872",
    "county": "Garvin County",
    "state": "OK",
    "city": "Stratford"
  },
  {
    "value": "74873",
    "label": "Tecumseh - OK 74873",
    "county": "Pottawatomie County",
    "state": "OK",
    "city": "Tecumseh"
  },
  {
    "value": "74875",
    "label": "Tryon - OK 74875",
    "county": "Lincoln County",
    "state": "OK",
    "city": "Tryon"
  },
  {
    "value": "74878",
    "label": "Wanette - OK 74878",
    "county": "Pottawatomie County",
    "state": "OK",
    "city": "Wanette"
  },
  {
    "value": "74880",
    "label": "Weleetka - OK 74880",
    "county": "Okfuskee County",
    "state": "OK",
    "city": "Weleetka"
  },
  {
    "value": "74881",
    "label": "Wellston - OK 74881",
    "county": "Lincoln County",
    "state": "OK",
    "city": "Wellston"
  },
  {
    "value": "74883",
    "label": "Wetumka - OK 74883",
    "county": "Hughes County",
    "state": "OK",
    "city": "Wetumka"
  },
  {
    "value": "74884",
    "label": "Wewoka - OK 74884",
    "county": "Seminole County",
    "state": "OK",
    "city": "Wewoka"
  },
  {
    "value": "74901",
    "label": "Arkoma - OK 74901",
    "county": "Le Flore County",
    "state": "OK",
    "city": "Arkoma"
  },
  {
    "value": "74902",
    "label": "Pocola - OK 74902",
    "county": "Le Flore County",
    "state": "OK",
    "city": "Pocola"
  },
  {
    "value": "74930",
    "label": "Bokoshe - OK 74930",
    "county": "Le Flore County",
    "state": "OK",
    "city": "Bokoshe"
  },
  {
    "value": "74931",
    "label": "Bunch - OK 74931",
    "county": "Adair County",
    "state": "OK",
    "city": "Bunch"
  },
  {
    "value": "74932",
    "label": "Cameron - OK 74932",
    "county": "Le Flore County",
    "state": "OK",
    "city": "Cameron"
  },
  {
    "value": "74936",
    "label": "Gans - OK 74936",
    "county": "Sequoyah County",
    "state": "OK",
    "city": "Gans"
  },
  {
    "value": "74937",
    "label": "Heavener - OK 74937",
    "county": "Le Flore County",
    "state": "OK",
    "city": "Heavener"
  },
  {
    "value": "74939",
    "label": "Hodgen - OK 74939",
    "county": "Le Flore County",
    "state": "OK",
    "city": "Hodgen"
  },
  {
    "value": "74940",
    "label": "Howe - OK 74940",
    "county": "Le Flore County",
    "state": "OK",
    "city": "Howe"
  },
  {
    "value": "74941",
    "label": "Keota - OK 74941",
    "county": "Haskell County",
    "state": "OK",
    "city": "Keota"
  },
  {
    "value": "74944",
    "label": "Mccurtain - OK 74944",
    "county": "Haskell County",
    "state": "OK",
    "city": "Mccurtain"
  },
  {
    "value": "74948",
    "label": "Muldrow - OK 74948",
    "county": "Sequoyah County",
    "state": "OK",
    "city": "Muldrow"
  },
  {
    "value": "74949",
    "label": "Muse - OK 74949",
    "county": "Le Flore County",
    "state": "OK",
    "city": "Muse"
  },
  {
    "value": "74953",
    "label": "Poteau - OK 74953",
    "county": "Le Flore County",
    "state": "OK",
    "city": "Poteau"
  },
  {
    "value": "74954",
    "label": "Roland - OK 74954",
    "county": "Sequoyah County",
    "state": "OK",
    "city": "Roland"
  },
  {
    "value": "74955",
    "label": "Sallisaw - OK 74955",
    "county": "Sequoyah County",
    "state": "OK",
    "city": "Sallisaw"
  },
  {
    "value": "74956",
    "label": "Shady Point - OK 74956",
    "county": "Le Flore County",
    "state": "OK",
    "city": "Shady Point"
  },
  {
    "value": "74957",
    "label": "Smithville - OK 74957",
    "county": "McCurtain County",
    "state": "OK",
    "city": "Smithville"
  },
  {
    "value": "74959",
    "label": "Spiro - OK 74959",
    "county": "Le Flore County",
    "state": "OK",
    "city": "Spiro"
  },
  {
    "value": "74960",
    "label": "Stilwell - OK 74960",
    "county": "Adair County",
    "state": "OK",
    "city": "Stilwell"
  },
  {
    "value": "74962",
    "label": "Vian - OK 74962",
    "county": "Sequoyah County",
    "state": "OK",
    "city": "Vian"
  },
  {
    "value": "74963",
    "label": "Watson - OK 74963",
    "county": "McCurtain County",
    "state": "OK",
    "city": "Watson"
  },
  {
    "value": "74964",
    "label": "Watts - OK 74964",
    "county": "Adair County",
    "state": "OK",
    "city": "Watts"
  },
  {
    "value": "74965",
    "label": "Westville - OK 74965",
    "county": "Adair County",
    "state": "OK",
    "city": "Westville"
  },
  {
    "value": "74966",
    "label": "Wister - OK 74966",
    "county": "Le Flore County",
    "state": "OK",
    "city": "Wister"
  },
  {
    "value": "97002",
    "label": "Aurora - OR 97002",
    "county": "Marion County",
    "state": "OR",
    "city": "Aurora"
  },
  {
    "value": "97003",
    "label": "Beaverton - OR 97003",
    "county": "Washington County",
    "state": "OR",
    "city": "Beaverton"
  },
  {
    "value": "97004",
    "label": "Beavercreek - OR 97004",
    "county": "Clackamas County",
    "state": "OR",
    "city": "Beavercreek"
  },
  {
    "value": "97005",
    "label": "Beaverton - OR 97005",
    "county": "Washington County",
    "state": "OR",
    "city": "Beaverton"
  },
  {
    "value": "97006",
    "label": "Beaverton - OR 97006",
    "county": "Washington County",
    "state": "OR",
    "city": "Beaverton"
  },
  {
    "value": "97007",
    "label": "Beaverton - OR 97007",
    "county": "Washington County",
    "state": "OR",
    "city": "Beaverton"
  },
  {
    "value": "97008",
    "label": "Beaverton - OR 97008",
    "county": "Washington County",
    "state": "OR",
    "city": "Beaverton"
  },
  {
    "value": "97009",
    "label": "Boring - OR 97009",
    "county": "Clackamas County",
    "state": "OR",
    "city": "Boring"
  },
  {
    "value": "97011",
    "label": "Brightwood - OR 97011",
    "county": "Clackamas County",
    "state": "OR",
    "city": "Brightwood"
  },
  {
    "value": "97013",
    "label": "Canby - OR 97013",
    "county": "Clackamas County",
    "state": "OR",
    "city": "Canby"
  },
  {
    "value": "97014",
    "label": "Cascade Locks - OR 97014",
    "county": "Hood River County",
    "state": "OR",
    "city": "Cascade Locks"
  },
  {
    "value": "97015",
    "label": "Clackamas - OR 97015",
    "county": "Clackamas County",
    "state": "OR",
    "city": "Clackamas"
  },
  {
    "value": "97016",
    "label": "Clatskanie - OR 97016",
    "county": "Columbia County",
    "state": "OR",
    "city": "Clatskanie"
  },
  {
    "value": "97017",
    "label": "Colton - OR 97017",
    "county": "Clackamas County",
    "state": "OR",
    "city": "Colton"
  },
  {
    "value": "97018",
    "label": "Columbia City - OR 97018",
    "county": "Columbia County",
    "state": "OR",
    "city": "Columbia City"
  },
  {
    "value": "97019",
    "label": "Corbett - OR 97019",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Corbett"
  },
  {
    "value": "97020",
    "label": "Donald - OR 97020",
    "county": "Marion County",
    "state": "OR",
    "city": "Donald"
  },
  {
    "value": "97021",
    "label": "Dufur - OR 97021",
    "county": "Wasco County",
    "state": "OR",
    "city": "Dufur"
  },
  {
    "value": "97022",
    "label": "Eagle Creek - OR 97022",
    "county": "Clackamas County",
    "state": "OR",
    "city": "Eagle Creek"
  },
  {
    "value": "97023",
    "label": "Estacada - OR 97023",
    "county": "Clackamas County",
    "state": "OR",
    "city": "Estacada"
  },
  {
    "value": "97024",
    "label": "Fairview - OR 97024",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Fairview"
  },
  {
    "value": "97026",
    "label": "Gervais - OR 97026",
    "county": "Marion County",
    "state": "OR",
    "city": "Gervais"
  },
  {
    "value": "97027",
    "label": "Gladstone - OR 97027",
    "county": "Clackamas County",
    "state": "OR",
    "city": "Gladstone"
  },
  {
    "value": "97028",
    "label": "Government Camp - OR 97028",
    "county": "Clackamas County",
    "state": "OR",
    "city": "Government Camp"
  },
  {
    "value": "97029",
    "label": "Grass Valley - OR 97029",
    "county": "Sherman County",
    "state": "OR",
    "city": "Grass Valley"
  },
  {
    "value": "97030",
    "label": "Gresham - OR 97030",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Gresham"
  },
  {
    "value": "97031",
    "label": "Hood River - OR 97031",
    "county": "Hood River County",
    "state": "OR",
    "city": "Hood River"
  },
  {
    "value": "97032",
    "label": "Hubbard - OR 97032",
    "county": "Marion County",
    "state": "OR",
    "city": "Hubbard"
  },
  {
    "value": "97034",
    "label": "Lake Oswego - OR 97034",
    "county": "Clackamas County",
    "state": "OR",
    "city": "Lake Oswego"
  },
  {
    "value": "97035",
    "label": "Lake Oswego - OR 97035",
    "county": "Clackamas County",
    "state": "OR",
    "city": "Lake Oswego"
  },
  {
    "value": "97037",
    "label": "Maupin - OR 97037",
    "county": "Wasco County",
    "state": "OR",
    "city": "Maupin"
  },
  {
    "value": "97038",
    "label": "Molalla - OR 97038",
    "county": "Clackamas County",
    "state": "OR",
    "city": "Molalla"
  },
  {
    "value": "97039",
    "label": "Moro - OR 97039",
    "county": "Sherman County",
    "state": "OR",
    "city": "Moro"
  },
  {
    "value": "97040",
    "label": "Mosier - OR 97040",
    "county": "Wasco County",
    "state": "OR",
    "city": "Mosier"
  },
  {
    "value": "97041",
    "label": "Mount Hood Parkdale - OR 97041",
    "county": "Hood River County",
    "state": "OR",
    "city": "Mount Hood Parkdale"
  },
  {
    "value": "97042",
    "label": "Mulino - OR 97042",
    "county": "Clackamas County",
    "state": "OR",
    "city": "Mulino"
  },
  {
    "value": "97045",
    "label": "Oregon City - OR 97045",
    "county": "Clackamas County",
    "state": "OR",
    "city": "Oregon City"
  },
  {
    "value": "97048",
    "label": "Rainier - OR 97048",
    "county": "Columbia County",
    "state": "OR",
    "city": "Rainier"
  },
  {
    "value": "97049",
    "label": "Rhododendron - OR 97049",
    "county": "Clackamas County",
    "state": "OR",
    "city": "Rhododendron"
  },
  {
    "value": "97051",
    "label": "Saint Helens - OR 97051",
    "county": "Columbia County",
    "state": "OR",
    "city": "Saint Helens"
  },
  {
    "value": "97053",
    "label": "Warren - OR 97053",
    "county": "Columbia County",
    "state": "OR",
    "city": "Warren"
  },
  {
    "value": "97054",
    "label": "Deer Island - OR 97054",
    "county": "Columbia County",
    "state": "OR",
    "city": "Deer Island"
  },
  {
    "value": "97055",
    "label": "Sandy - OR 97055",
    "county": "Clackamas County",
    "state": "OR",
    "city": "Sandy"
  },
  {
    "value": "97056",
    "label": "Scappoose - OR 97056",
    "county": "Columbia County",
    "state": "OR",
    "city": "Scappoose"
  },
  {
    "value": "97058",
    "label": "The Dalles - OR 97058",
    "county": "Wasco County",
    "state": "OR",
    "city": "The Dalles"
  },
  {
    "value": "97060",
    "label": "Troutdale - OR 97060",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Troutdale"
  },
  {
    "value": "97062",
    "label": "Tualatin - OR 97062",
    "county": "Washington County",
    "state": "OR",
    "city": "Tualatin"
  },
  {
    "value": "97063",
    "label": "Tygh Valley - OR 97063",
    "county": "Wasco County",
    "state": "OR",
    "city": "Tygh Valley"
  },
  {
    "value": "97064",
    "label": "Vernonia - OR 97064",
    "county": "Columbia County",
    "state": "OR",
    "city": "Vernonia"
  },
  {
    "value": "97065",
    "label": "Wasco - OR 97065",
    "county": "Sherman County",
    "state": "OR",
    "city": "Wasco"
  },
  {
    "value": "97067",
    "label": "Welches - OR 97067",
    "county": "Clackamas County",
    "state": "OR",
    "city": "Welches"
  },
  {
    "value": "97068",
    "label": "West Linn - OR 97068",
    "county": "Clackamas County",
    "state": "OR",
    "city": "West Linn"
  },
  {
    "value": "97070",
    "label": "Wilsonville - OR 97070",
    "county": "Clackamas County",
    "state": "OR",
    "city": "Wilsonville"
  },
  {
    "value": "97071",
    "label": "Woodburn - OR 97071",
    "county": "Marion County",
    "state": "OR",
    "city": "Woodburn"
  },
  {
    "value": "97078",
    "label": "Beaverton - OR 97078",
    "county": "Washington County",
    "state": "OR",
    "city": "Beaverton"
  },
  {
    "value": "97080",
    "label": "Gresham - OR 97080",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Gresham"
  },
  {
    "value": "97086",
    "label": "Happy Valley - OR 97086",
    "county": "Clackamas County",
    "state": "OR",
    "city": "Happy Valley"
  },
  {
    "value": "97089",
    "label": "Damascus - OR 97089",
    "county": "Clackamas County",
    "state": "OR",
    "city": "Damascus"
  },
  {
    "value": "97101",
    "label": "Amity - OR 97101",
    "county": "Yamhill County",
    "state": "OR",
    "city": "Amity"
  },
  {
    "value": "97102",
    "label": "Arch Cape - OR 97102",
    "county": "Clatsop County",
    "state": "OR",
    "city": "Arch Cape"
  },
  {
    "value": "97103",
    "label": "Astoria - OR 97103",
    "county": "Clatsop County",
    "state": "OR",
    "city": "Astoria"
  },
  {
    "value": "97106",
    "label": "Banks - OR 97106",
    "county": "Washington County",
    "state": "OR",
    "city": "Banks"
  },
  {
    "value": "97107",
    "label": "Bay City - OR 97107",
    "county": "Tillamook County",
    "state": "OR",
    "city": "Bay City"
  },
  {
    "value": "97108",
    "label": "Beaver - OR 97108",
    "county": "Tillamook County",
    "state": "OR",
    "city": "Beaver"
  },
  {
    "value": "97109",
    "label": "Buxton - OR 97109",
    "county": "Washington County",
    "state": "OR",
    "city": "Buxton"
  },
  {
    "value": "97111",
    "label": "Carlton - OR 97111",
    "county": "Yamhill County",
    "state": "OR",
    "city": "Carlton"
  },
  {
    "value": "97112",
    "label": "Cloverdale - OR 97112",
    "county": "Tillamook County",
    "state": "OR",
    "city": "Cloverdale"
  },
  {
    "value": "97113",
    "label": "Cornelius - OR 97113",
    "county": "Washington County",
    "state": "OR",
    "city": "Cornelius"
  },
  {
    "value": "97114",
    "label": "Dayton - OR 97114",
    "county": "Yamhill County",
    "state": "OR",
    "city": "Dayton"
  },
  {
    "value": "97115",
    "label": "Dundee - OR 97115",
    "county": "Yamhill County",
    "state": "OR",
    "city": "Dundee"
  },
  {
    "value": "97116",
    "label": "Forest Grove - OR 97116",
    "county": "Washington County",
    "state": "OR",
    "city": "Forest Grove"
  },
  {
    "value": "97117",
    "label": "Gales Creek - OR 97117",
    "county": "Washington County",
    "state": "OR",
    "city": "Gales Creek"
  },
  {
    "value": "97119",
    "label": "Gaston - OR 97119",
    "county": "Washington County",
    "state": "OR",
    "city": "Gaston"
  },
  {
    "value": "97121",
    "label": "Hammond - OR 97121",
    "county": "Clatsop County",
    "state": "OR",
    "city": "Hammond"
  },
  {
    "value": "97122",
    "label": "Hebo - OR 97122",
    "county": "Tillamook County",
    "state": "OR",
    "city": "Hebo"
  },
  {
    "value": "97123",
    "label": "Hillsboro - OR 97123",
    "county": "Washington County",
    "state": "OR",
    "city": "Hillsboro"
  },
  {
    "value": "97124",
    "label": "Hillsboro - OR 97124",
    "county": "Washington County",
    "state": "OR",
    "city": "Hillsboro"
  },
  {
    "value": "97125",
    "label": "Manning - OR 97125",
    "county": "Washington County",
    "state": "OR",
    "city": "Manning"
  },
  {
    "value": "97127",
    "label": "Lafayette - OR 97127",
    "county": "Yamhill County",
    "state": "OR",
    "city": "Lafayette"
  },
  {
    "value": "97128",
    "label": "Mcminnville - OR 97128",
    "county": "Yamhill County",
    "state": "OR",
    "city": "Mcminnville"
  },
  {
    "value": "97131",
    "label": "Nehalem - OR 97131",
    "county": "Tillamook County",
    "state": "OR",
    "city": "Nehalem"
  },
  {
    "value": "97132",
    "label": "Newberg - OR 97132",
    "county": "Yamhill County",
    "state": "OR",
    "city": "Newberg"
  },
  {
    "value": "97133",
    "label": "North Plains - OR 97133",
    "county": "Washington County",
    "state": "OR",
    "city": "North Plains"
  },
  {
    "value": "97136",
    "label": "Rockaway Beach - OR 97136",
    "county": "Tillamook County",
    "state": "OR",
    "city": "Rockaway Beach"
  },
  {
    "value": "97137",
    "label": "Saint Paul - OR 97137",
    "county": "Marion County",
    "state": "OR",
    "city": "Saint Paul"
  },
  {
    "value": "97138",
    "label": "Seaside - OR 97138",
    "county": "Clatsop County",
    "state": "OR",
    "city": "Seaside"
  },
  {
    "value": "97140",
    "label": "Sherwood - OR 97140",
    "county": "Washington County",
    "state": "OR",
    "city": "Sherwood"
  },
  {
    "value": "97141",
    "label": "Tillamook - OR 97141",
    "county": "Tillamook County",
    "state": "OR",
    "city": "Tillamook"
  },
  {
    "value": "97144",
    "label": "Timber - OR 97144",
    "county": "Washington County",
    "state": "OR",
    "city": "Timber"
  },
  {
    "value": "97146",
    "label": "Warrenton - OR 97146",
    "county": "Clatsop County",
    "state": "OR",
    "city": "Warrenton"
  },
  {
    "value": "97148",
    "label": "Yamhill - OR 97148",
    "county": "Yamhill County",
    "state": "OR",
    "city": "Yamhill"
  },
  {
    "value": "97149",
    "label": "Neskowin - OR 97149",
    "county": "Tillamook County",
    "state": "OR",
    "city": "Neskowin"
  },
  {
    "value": "97201",
    "label": "Portland - OR 97201",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97202",
    "label": "Portland - OR 97202",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97203",
    "label": "Portland - OR 97203",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97204",
    "label": "Portland - OR 97204",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97205",
    "label": "Portland - OR 97205",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97206",
    "label": "Portland - OR 97206",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97209",
    "label": "Portland - OR 97209",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97210",
    "label": "Portland - OR 97210",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97211",
    "label": "Portland - OR 97211",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97212",
    "label": "Portland - OR 97212",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97213",
    "label": "Portland - OR 97213",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97214",
    "label": "Portland - OR 97214",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97215",
    "label": "Portland - OR 97215",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97216",
    "label": "Portland - OR 97216",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97217",
    "label": "Portland - OR 97217",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97218",
    "label": "Portland - OR 97218",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97219",
    "label": "Portland - OR 97219",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97220",
    "label": "Portland - OR 97220",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97221",
    "label": "Portland - OR 97221",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97222",
    "label": "Portland - OR 97222",
    "county": "Clackamas County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97223",
    "label": "Portland - OR 97223",
    "county": "Washington County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97224",
    "label": "Portland - OR 97224",
    "county": "Washington County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97225",
    "label": "Portland - OR 97225",
    "county": "Washington County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97227",
    "label": "Portland - OR 97227",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97229",
    "label": "Portland - OR 97229",
    "county": "Washington County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97230",
    "label": "Portland - OR 97230",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97231",
    "label": "Portland - OR 97231",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97232",
    "label": "Portland - OR 97232",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97233",
    "label": "Portland - OR 97233",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97236",
    "label": "Portland - OR 97236",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97239",
    "label": "Portland - OR 97239",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97258",
    "label": "Portland - OR 97258",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97266",
    "label": "Portland - OR 97266",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97267",
    "label": "Portland - OR 97267",
    "county": "Clackamas County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97299",
    "label": "Portland - OR 97299",
    "county": "Multnomah County",
    "state": "OR",
    "city": "Portland"
  },
  {
    "value": "97301",
    "label": "Salem - OR 97301",
    "county": "Marion County",
    "state": "OR",
    "city": "Salem"
  },
  {
    "value": "97302",
    "label": "Salem - OR 97302",
    "county": "Marion County",
    "state": "OR",
    "city": "Salem"
  },
  {
    "value": "97303",
    "label": "Salem - OR 97303",
    "county": "Marion County",
    "state": "OR",
    "city": "Salem"
  },
  {
    "value": "97304",
    "label": "Salem - OR 97304",
    "county": "Polk County",
    "state": "OR",
    "city": "Salem"
  },
  {
    "value": "97305",
    "label": "Salem - OR 97305",
    "county": "Marion County",
    "state": "OR",
    "city": "Salem"
  },
  {
    "value": "97306",
    "label": "Salem - OR 97306",
    "county": "Marion County",
    "state": "OR",
    "city": "Salem"
  },
  {
    "value": "97317",
    "label": "Salem - OR 97317",
    "county": "Marion County",
    "state": "OR",
    "city": "Salem"
  },
  {
    "value": "97321",
    "label": "Albany - OR 97321",
    "county": "Linn County",
    "state": "OR",
    "city": "Albany"
  },
  {
    "value": "97322",
    "label": "Albany - OR 97322",
    "county": "Linn County",
    "state": "OR",
    "city": "Albany"
  },
  {
    "value": "97324",
    "label": "Alsea - OR 97324",
    "county": "Benton County",
    "state": "OR",
    "city": "Alsea"
  },
  {
    "value": "97325",
    "label": "Aumsville - OR 97325",
    "county": "Marion County",
    "state": "OR",
    "city": "Aumsville"
  },
  {
    "value": "97326",
    "label": "Blodgett - OR 97326",
    "county": "Benton County",
    "state": "OR",
    "city": "Blodgett"
  },
  {
    "value": "97327",
    "label": "Brownsville - OR 97327",
    "county": "Linn County",
    "state": "OR",
    "city": "Brownsville"
  },
  {
    "value": "97329",
    "label": "Cascadia - OR 97329",
    "county": "Linn County",
    "state": "OR",
    "city": "Cascadia"
  },
  {
    "value": "97330",
    "label": "Corvallis - OR 97330",
    "county": "Benton County",
    "state": "OR",
    "city": "Corvallis"
  },
  {
    "value": "97331",
    "label": "Corvallis - OR 97331",
    "county": "Benton County",
    "state": "OR",
    "city": "Corvallis"
  },
  {
    "value": "97333",
    "label": "Corvallis - OR 97333",
    "county": "Benton County",
    "state": "OR",
    "city": "Corvallis"
  },
  {
    "value": "97338",
    "label": "Dallas - OR 97338",
    "county": "Polk County",
    "state": "OR",
    "city": "Dallas"
  },
  {
    "value": "97341",
    "label": "Depoe Bay - OR 97341",
    "county": "Lincoln County",
    "state": "OR",
    "city": "Depoe Bay"
  },
  {
    "value": "97343",
    "label": "Eddyville - OR 97343",
    "county": "Lincoln County",
    "state": "OR",
    "city": "Eddyville"
  },
  {
    "value": "97344",
    "label": "Falls City - OR 97344",
    "county": "Polk County",
    "state": "OR",
    "city": "Falls City"
  },
  {
    "value": "97345",
    "label": "Foster - OR 97345",
    "county": "Linn County",
    "state": "OR",
    "city": "Foster"
  },
  {
    "value": "97346",
    "label": "Gates - OR 97346",
    "county": "Marion County",
    "state": "OR",
    "city": "Gates"
  },
  {
    "value": "97347",
    "label": "Grand Ronde - OR 97347",
    "county": "Polk County",
    "state": "OR",
    "city": "Grand Ronde"
  },
  {
    "value": "97348",
    "label": "Halsey - OR 97348",
    "county": "Linn County",
    "state": "OR",
    "city": "Halsey"
  },
  {
    "value": "97350",
    "label": "Idanha - OR 97350",
    "county": "Marion County",
    "state": "OR",
    "city": "Idanha"
  },
  {
    "value": "97351",
    "label": "Independence - OR 97351",
    "county": "Polk County",
    "state": "OR",
    "city": "Independence"
  },
  {
    "value": "97352",
    "label": "Jefferson - OR 97352",
    "county": "Marion County",
    "state": "OR",
    "city": "Jefferson"
  },
  {
    "value": "97355",
    "label": "Lebanon - OR 97355",
    "county": "Linn County",
    "state": "OR",
    "city": "Lebanon"
  },
  {
    "value": "97357",
    "label": "Logsden - OR 97357",
    "county": "Lincoln County",
    "state": "OR",
    "city": "Logsden"
  },
  {
    "value": "97358",
    "label": "Lyons - OR 97358",
    "county": "Linn County",
    "state": "OR",
    "city": "Lyons"
  },
  {
    "value": "97360",
    "label": "Mill City - OR 97360",
    "county": "Linn County",
    "state": "OR",
    "city": "Mill City"
  },
  {
    "value": "97361",
    "label": "Monmouth - OR 97361",
    "county": "Polk County",
    "state": "OR",
    "city": "Monmouth"
  },
  {
    "value": "97362",
    "label": "Mount Angel - OR 97362",
    "county": "Marion County",
    "state": "OR",
    "city": "Mount Angel"
  },
  {
    "value": "97364",
    "label": "Neotsu - OR 97364",
    "county": "Lincoln County",
    "state": "OR",
    "city": "Neotsu"
  },
  {
    "value": "97365",
    "label": "Newport - OR 97365",
    "county": "Lincoln County",
    "state": "OR",
    "city": "Newport"
  },
  {
    "value": "97366",
    "label": "South Beach - OR 97366",
    "county": "Lincoln County",
    "state": "OR",
    "city": "South Beach"
  },
  {
    "value": "97367",
    "label": "Lincoln City - OR 97367",
    "county": "Lincoln County",
    "state": "OR",
    "city": "Lincoln City"
  },
  {
    "value": "97368",
    "label": "Otis - OR 97368",
    "county": "Lincoln County",
    "state": "OR",
    "city": "Otis"
  },
  {
    "value": "97369",
    "label": "Otter Rock - OR 97369",
    "county": "Lincoln County",
    "state": "OR",
    "city": "Otter Rock"
  },
  {
    "value": "97370",
    "label": "Philomath - OR 97370",
    "county": "Benton County",
    "state": "OR",
    "city": "Philomath"
  },
  {
    "value": "97371",
    "label": "Rickreall - OR 97371",
    "county": "Polk County",
    "state": "OR",
    "city": "Rickreall"
  },
  {
    "value": "97372",
    "label": "Rose Lodge - OR 97372",
    "county": "Lincoln County",
    "state": "OR",
    "city": "Rose Lodge"
  },
  {
    "value": "97374",
    "label": "Scio - OR 97374",
    "county": "Linn County",
    "state": "OR",
    "city": "Scio"
  },
  {
    "value": "97375",
    "label": "Scotts Mills - OR 97375",
    "county": "Marion County",
    "state": "OR",
    "city": "Scotts Mills"
  },
  {
    "value": "97376",
    "label": "Seal Rock - OR 97376",
    "county": "Lincoln County",
    "state": "OR",
    "city": "Seal Rock"
  },
  {
    "value": "97377",
    "label": "Shedd - OR 97377",
    "county": "Linn County",
    "state": "OR",
    "city": "Shedd"
  },
  {
    "value": "97378",
    "label": "Sheridan - OR 97378",
    "county": "Yamhill County",
    "state": "OR",
    "city": "Sheridan"
  },
  {
    "value": "97380",
    "label": "Siletz - OR 97380",
    "county": "Lincoln County",
    "state": "OR",
    "city": "Siletz"
  },
  {
    "value": "97381",
    "label": "Silverton - OR 97381",
    "county": "Marion County",
    "state": "OR",
    "city": "Silverton"
  },
  {
    "value": "97383",
    "label": "Stayton - OR 97383",
    "county": "Marion County",
    "state": "OR",
    "city": "Stayton"
  },
  {
    "value": "97385",
    "label": "Sublimity - OR 97385",
    "county": "Marion County",
    "state": "OR",
    "city": "Sublimity"
  },
  {
    "value": "97386",
    "label": "Sweet Home - OR 97386",
    "county": "Linn County",
    "state": "OR",
    "city": "Sweet Home"
  },
  {
    "value": "97389",
    "label": "Tangent - OR 97389",
    "county": "Linn County",
    "state": "OR",
    "city": "Tangent"
  },
  {
    "value": "97390",
    "label": "Tidewater - OR 97390",
    "county": "Lincoln County",
    "state": "OR",
    "city": "Tidewater"
  },
  {
    "value": "97391",
    "label": "Toledo - OR 97391",
    "county": "Lincoln County",
    "state": "OR",
    "city": "Toledo"
  },
  {
    "value": "97392",
    "label": "Turner - OR 97392",
    "county": "Marion County",
    "state": "OR",
    "city": "Turner"
  },
  {
    "value": "97394",
    "label": "Waldport - OR 97394",
    "county": "Lincoln County",
    "state": "OR",
    "city": "Waldport"
  },
  {
    "value": "97396",
    "label": "Willamina - OR 97396",
    "county": "Yamhill County",
    "state": "OR",
    "city": "Willamina"
  },
  {
    "value": "97401",
    "label": "Eugene - OR 97401",
    "county": "Lane County",
    "state": "OR",
    "city": "Eugene"
  },
  {
    "value": "97402",
    "label": "Eugene - OR 97402",
    "county": "Lane County",
    "state": "OR",
    "city": "Eugene"
  },
  {
    "value": "97403",
    "label": "Eugene - OR 97403",
    "county": "Lane County",
    "state": "OR",
    "city": "Eugene"
  },
  {
    "value": "97404",
    "label": "Eugene - OR 97404",
    "county": "Lane County",
    "state": "OR",
    "city": "Eugene"
  },
  {
    "value": "97405",
    "label": "Eugene - OR 97405",
    "county": "Lane County",
    "state": "OR",
    "city": "Eugene"
  },
  {
    "value": "97406",
    "label": "Agness - OR 97406",
    "county": "Curry County",
    "state": "OR",
    "city": "Agness"
  },
  {
    "value": "97408",
    "label": "Eugene - OR 97408",
    "county": "Lane County",
    "state": "OR",
    "city": "Eugene"
  },
  {
    "value": "97410",
    "label": "Azalea - OR 97410",
    "county": "Douglas County",
    "state": "OR",
    "city": "Azalea"
  },
  {
    "value": "97411",
    "label": "Bandon - OR 97411",
    "county": "Coos County",
    "state": "OR",
    "city": "Bandon"
  },
  {
    "value": "97412",
    "label": "Blachly - OR 97412",
    "county": "Lane County",
    "state": "OR",
    "city": "Blachly"
  },
  {
    "value": "97413",
    "label": "Blue River - OR 97413",
    "county": "Lane County",
    "state": "OR",
    "city": "Blue River"
  },
  {
    "value": "97414",
    "label": "Broadbent - OR 97414",
    "county": "Coos County",
    "state": "OR",
    "city": "Broadbent"
  },
  {
    "value": "97415",
    "label": "Brookings - OR 97415",
    "county": "Curry County",
    "state": "OR",
    "city": "Brookings"
  },
  {
    "value": "97416",
    "label": "Camas Valley - OR 97416",
    "county": "Douglas County",
    "state": "OR",
    "city": "Camas Valley"
  },
  {
    "value": "97417",
    "label": "Canyonville - OR 97417",
    "county": "Douglas County",
    "state": "OR",
    "city": "Canyonville"
  },
  {
    "value": "97419",
    "label": "Cheshire - OR 97419",
    "county": "Lane County",
    "state": "OR",
    "city": "Cheshire"
  },
  {
    "value": "97420",
    "label": "Coos Bay - OR 97420",
    "county": "Coos County",
    "state": "OR",
    "city": "Coos Bay"
  },
  {
    "value": "97423",
    "label": "Coquille - OR 97423",
    "county": "Coos County",
    "state": "OR",
    "city": "Coquille"
  },
  {
    "value": "97424",
    "label": "Cottage Grove - OR 97424",
    "county": "Lane County",
    "state": "OR",
    "city": "Cottage Grove"
  },
  {
    "value": "97426",
    "label": "Creswell - OR 97426",
    "county": "Lane County",
    "state": "OR",
    "city": "Creswell"
  },
  {
    "value": "97427",
    "label": "Culp Creek - OR 97427",
    "county": "Lane County",
    "state": "OR",
    "city": "Culp Creek"
  },
  {
    "value": "97429",
    "label": "Days Creek - OR 97429",
    "county": "Douglas County",
    "state": "OR",
    "city": "Days Creek"
  },
  {
    "value": "97430",
    "label": "Deadwood - OR 97430",
    "county": "Lane County",
    "state": "OR",
    "city": "Deadwood"
  },
  {
    "value": "97431",
    "label": "Dexter - OR 97431",
    "county": "Lane County",
    "state": "OR",
    "city": "Dexter"
  },
  {
    "value": "97434",
    "label": "Dorena - OR 97434",
    "county": "Lane County",
    "state": "OR",
    "city": "Dorena"
  },
  {
    "value": "97435",
    "label": "Drain - OR 97435",
    "county": "Douglas County",
    "state": "OR",
    "city": "Drain"
  },
  {
    "value": "97436",
    "label": "Elkton - OR 97436",
    "county": "Douglas County",
    "state": "OR",
    "city": "Elkton"
  },
  {
    "value": "97437",
    "label": "Elmira - OR 97437",
    "county": "Lane County",
    "state": "OR",
    "city": "Elmira"
  },
  {
    "value": "97438",
    "label": "Fall Creek - OR 97438",
    "county": "Lane County",
    "state": "OR",
    "city": "Fall Creek"
  },
  {
    "value": "97439",
    "label": "Florence - OR 97439",
    "county": "Lane County",
    "state": "OR",
    "city": "Florence"
  },
  {
    "value": "97441",
    "label": "Gardiner - OR 97441",
    "county": "Douglas County",
    "state": "OR",
    "city": "Gardiner"
  },
  {
    "value": "97442",
    "label": "Glendale - OR 97442",
    "county": "Douglas County",
    "state": "OR",
    "city": "Glendale"
  },
  {
    "value": "97443",
    "label": "Glide - OR 97443",
    "county": "Douglas County",
    "state": "OR",
    "city": "Glide"
  },
  {
    "value": "97444",
    "label": "Gold Beach - OR 97444",
    "county": "Curry County",
    "state": "OR",
    "city": "Gold Beach"
  },
  {
    "value": "97446",
    "label": "Harrisburg - OR 97446",
    "county": "Linn County",
    "state": "OR",
    "city": "Harrisburg"
  },
  {
    "value": "97447",
    "label": "Idleyld Park - OR 97447",
    "county": "Douglas County",
    "state": "OR",
    "city": "Idleyld Park"
  },
  {
    "value": "97448",
    "label": "Junction City - OR 97448",
    "county": "Lane County",
    "state": "OR",
    "city": "Junction City"
  },
  {
    "value": "97449",
    "label": "Lakeside - OR 97449",
    "county": "Coos County",
    "state": "OR",
    "city": "Lakeside"
  },
  {
    "value": "97450",
    "label": "Langlois - OR 97450",
    "county": "Curry County",
    "state": "OR",
    "city": "Langlois"
  },
  {
    "value": "97451",
    "label": "Lorane - OR 97451",
    "county": "Lane County",
    "state": "OR",
    "city": "Lorane"
  },
  {
    "value": "97452",
    "label": "Lowell - OR 97452",
    "county": "Lane County",
    "state": "OR",
    "city": "Lowell"
  },
  {
    "value": "97453",
    "label": "Mapleton - OR 97453",
    "county": "Lane County",
    "state": "OR",
    "city": "Mapleton"
  },
  {
    "value": "97454",
    "label": "Marcola - OR 97454",
    "county": "Lane County",
    "state": "OR",
    "city": "Marcola"
  },
  {
    "value": "97455",
    "label": "Pleasant Hill - OR 97455",
    "county": "Lane County",
    "state": "OR",
    "city": "Pleasant Hill"
  },
  {
    "value": "97456",
    "label": "Monroe - OR 97456",
    "county": "Benton County",
    "state": "OR",
    "city": "Monroe"
  },
  {
    "value": "97457",
    "label": "Myrtle Creek - OR 97457",
    "county": "Douglas County",
    "state": "OR",
    "city": "Myrtle Creek"
  },
  {
    "value": "97458",
    "label": "Myrtle Point - OR 97458",
    "county": "Coos County",
    "state": "OR",
    "city": "Myrtle Point"
  },
  {
    "value": "97459",
    "label": "North Bend - OR 97459",
    "county": "Coos County",
    "state": "OR",
    "city": "North Bend"
  },
  {
    "value": "97461",
    "label": "Noti - OR 97461",
    "county": "Lane County",
    "state": "OR",
    "city": "Noti"
  },
  {
    "value": "97462",
    "label": "Oakland - OR 97462",
    "county": "Douglas County",
    "state": "OR",
    "city": "Oakland"
  },
  {
    "value": "97463",
    "label": "Oakridge - OR 97463",
    "county": "Lane County",
    "state": "OR",
    "city": "Oakridge"
  },
  {
    "value": "97465",
    "label": "Port Orford - OR 97465",
    "county": "Curry County",
    "state": "OR",
    "city": "Port Orford"
  },
  {
    "value": "97466",
    "label": "Powers - OR 97466",
    "county": "Coos County",
    "state": "OR",
    "city": "Powers"
  },
  {
    "value": "97467",
    "label": "Reedsport - OR 97467",
    "county": "Douglas County",
    "state": "OR",
    "city": "Reedsport"
  },
  {
    "value": "97469",
    "label": "Riddle - OR 97469",
    "county": "Douglas County",
    "state": "OR",
    "city": "Riddle"
  },
  {
    "value": "97470",
    "label": "Roseburg - OR 97470",
    "county": "Douglas County",
    "state": "OR",
    "city": "Roseburg"
  },
  {
    "value": "97471",
    "label": "Roseburg - OR 97471",
    "county": "Douglas County",
    "state": "OR",
    "city": "Roseburg"
  },
  {
    "value": "97472",
    "label": "Cottage Grove - OR 97472",
    "county": "Lane County",
    "state": "OR",
    "city": "Cottage Grove"
  },
  {
    "value": "97473",
    "label": "Scottsburg - OR 97473",
    "county": "Douglas County",
    "state": "OR",
    "city": "Scottsburg"
  },
  {
    "value": "97476",
    "label": "Sixes - OR 97476",
    "county": "Curry County",
    "state": "OR",
    "city": "Sixes"
  },
  {
    "value": "97477",
    "label": "Springfield - OR 97477",
    "county": "Lane County",
    "state": "OR",
    "city": "Springfield"
  },
  {
    "value": "97478",
    "label": "Springfield - OR 97478",
    "county": "Lane County",
    "state": "OR",
    "city": "Springfield"
  },
  {
    "value": "97479",
    "label": "Sutherlin - OR 97479",
    "county": "Douglas County",
    "state": "OR",
    "city": "Sutherlin"
  },
  {
    "value": "97480",
    "label": "Swisshome - OR 97480",
    "county": "Lane County",
    "state": "OR",
    "city": "Swisshome"
  },
  {
    "value": "97481",
    "label": "Tenmile - OR 97481",
    "county": "Douglas County",
    "state": "OR",
    "city": "Tenmile"
  },
  {
    "value": "97482",
    "label": "Thurston - OR 97482",
    "county": "Lane County",
    "state": "OR",
    "city": "Thurston"
  },
  {
    "value": "97484",
    "label": "Tiller - OR 97484",
    "county": "Douglas County",
    "state": "OR",
    "city": "Tiller"
  },
  {
    "value": "97486",
    "label": "Umpqua - OR 97486",
    "county": "Douglas County",
    "state": "OR",
    "city": "Umpqua"
  },
  {
    "value": "97487",
    "label": "Veneta - OR 97487",
    "county": "Lane County",
    "state": "OR",
    "city": "Veneta"
  },
  {
    "value": "97488",
    "label": "Vida - OR 97488",
    "county": "Lane County",
    "state": "OR",
    "city": "Vida"
  },
  {
    "value": "97489",
    "label": "Walterville - OR 97489",
    "county": "Lane County",
    "state": "OR",
    "city": "Walterville"
  },
  {
    "value": "97490",
    "label": "Walton - OR 97490",
    "county": "Lane County",
    "state": "OR",
    "city": "Walton"
  },
  {
    "value": "97492",
    "label": "Westfir - OR 97492",
    "county": "Lane County",
    "state": "OR",
    "city": "Westfir"
  },
  {
    "value": "97493",
    "label": "Westlake - OR 97493",
    "county": "Lane County",
    "state": "OR",
    "city": "Westlake"
  },
  {
    "value": "97495",
    "label": "Winchester - OR 97495",
    "county": "Douglas County",
    "state": "OR",
    "city": "Winchester"
  },
  {
    "value": "97496",
    "label": "Winston - OR 97496",
    "county": "Douglas County",
    "state": "OR",
    "city": "Winston"
  },
  {
    "value": "97497",
    "label": "Wolf Creek - OR 97497",
    "county": "Josephine County",
    "state": "OR",
    "city": "Wolf Creek"
  },
  {
    "value": "97498",
    "label": "Yachats - OR 97498",
    "county": "Lincoln County",
    "state": "OR",
    "city": "Yachats"
  },
  {
    "value": "97499",
    "label": "Yoncalla - OR 97499",
    "county": "Douglas County",
    "state": "OR",
    "city": "Yoncalla"
  },
  {
    "value": "97501",
    "label": "Medford - OR 97501",
    "county": "Jackson County",
    "state": "OR",
    "city": "Medford"
  },
  {
    "value": "97502",
    "label": "Central Point - OR 97502",
    "county": "Jackson County",
    "state": "OR",
    "city": "Central Point"
  },
  {
    "value": "97503",
    "label": "White City - OR 97503",
    "county": "Jackson County",
    "state": "OR",
    "city": "White City"
  },
  {
    "value": "97504",
    "label": "Medford - OR 97504",
    "county": "Jackson County",
    "state": "OR",
    "city": "Medford"
  },
  {
    "value": "97520",
    "label": "Ashland - OR 97520",
    "county": "Jackson County",
    "state": "OR",
    "city": "Ashland"
  },
  {
    "value": "97522",
    "label": "Butte Falls - OR 97522",
    "county": "Jackson County",
    "state": "OR",
    "city": "Butte Falls"
  },
  {
    "value": "97523",
    "label": "Cave Junction - OR 97523",
    "county": "Josephine County",
    "state": "OR",
    "city": "Cave Junction"
  },
  {
    "value": "97524",
    "label": "Eagle Point - OR 97524",
    "county": "Jackson County",
    "state": "OR",
    "city": "Eagle Point"
  },
  {
    "value": "97525",
    "label": "Gold Hill - OR 97525",
    "county": "Jackson County",
    "state": "OR",
    "city": "Gold Hill"
  },
  {
    "value": "97526",
    "label": "Grants Pass - OR 97526",
    "county": "Josephine County",
    "state": "OR",
    "city": "Grants Pass"
  },
  {
    "value": "97527",
    "label": "Grants Pass - OR 97527",
    "county": "Josephine County",
    "state": "OR",
    "city": "Grants Pass"
  },
  {
    "value": "97530",
    "label": "Jacksonville - OR 97530",
    "county": "Jackson County",
    "state": "OR",
    "city": "Jacksonville"
  },
  {
    "value": "97531",
    "label": "Kerby - OR 97531",
    "county": "Josephine County",
    "state": "OR",
    "city": "Kerby"
  },
  {
    "value": "97532",
    "label": "Merlin - OR 97532",
    "county": "Josephine County",
    "state": "OR",
    "city": "Merlin"
  },
  {
    "value": "97534",
    "label": "O Brien - OR 97534",
    "county": "Josephine County",
    "state": "OR",
    "city": "O Brien"
  },
  {
    "value": "97535",
    "label": "Phoenix - OR 97535",
    "county": "Jackson County",
    "state": "OR",
    "city": "Phoenix"
  },
  {
    "value": "97536",
    "label": "Prospect - OR 97536",
    "county": "Jackson County",
    "state": "OR",
    "city": "Prospect"
  },
  {
    "value": "97537",
    "label": "Rogue River - OR 97537",
    "county": "Jackson County",
    "state": "OR",
    "city": "Rogue River"
  },
  {
    "value": "97538",
    "label": "Selma - OR 97538",
    "county": "Josephine County",
    "state": "OR",
    "city": "Selma"
  },
  {
    "value": "97539",
    "label": "Shady Cove - OR 97539",
    "county": "Jackson County",
    "state": "OR",
    "city": "Shady Cove"
  },
  {
    "value": "97540",
    "label": "Talent - OR 97540",
    "county": "Jackson County",
    "state": "OR",
    "city": "Talent"
  },
  {
    "value": "97541",
    "label": "Trail - OR 97541",
    "county": "Jackson County",
    "state": "OR",
    "city": "Trail"
  },
  {
    "value": "97543",
    "label": "Wilderville - OR 97543",
    "county": "Josephine County",
    "state": "OR",
    "city": "Wilderville"
  },
  {
    "value": "97544",
    "label": "Williams - OR 97544",
    "county": "Josephine County",
    "state": "OR",
    "city": "Williams"
  },
  {
    "value": "97601",
    "label": "Klamath Falls - OR 97601",
    "county": "Klamath County",
    "state": "OR",
    "city": "Klamath Falls"
  },
  {
    "value": "97603",
    "label": "Klamath Falls - OR 97603",
    "county": "Klamath County",
    "state": "OR",
    "city": "Klamath Falls"
  },
  {
    "value": "97621",
    "label": "Beatty - OR 97621",
    "county": "Klamath County",
    "state": "OR",
    "city": "Beatty"
  },
  {
    "value": "97623",
    "label": "Bonanza - OR 97623",
    "county": "Klamath County",
    "state": "OR",
    "city": "Bonanza"
  },
  {
    "value": "97624",
    "label": "Chiloquin - OR 97624",
    "county": "Klamath County",
    "state": "OR",
    "city": "Chiloquin"
  },
  {
    "value": "97625",
    "label": "Dairy - OR 97625",
    "county": "Klamath County",
    "state": "OR",
    "city": "Dairy"
  },
  {
    "value": "97627",
    "label": "Keno - OR 97627",
    "county": "Klamath County",
    "state": "OR",
    "city": "Keno"
  },
  {
    "value": "97630",
    "label": "Lakeview - OR 97630",
    "county": "Lake County",
    "state": "OR",
    "city": "Lakeview"
  },
  {
    "value": "97632",
    "label": "Malin - OR 97632",
    "county": "Klamath County",
    "state": "OR",
    "city": "Malin"
  },
  {
    "value": "97633",
    "label": "Merrill - OR 97633",
    "county": "Klamath County",
    "state": "OR",
    "city": "Merrill"
  },
  {
    "value": "97635",
    "label": "New Pine Creek - OR 97635",
    "county": "Modoc County",
    "state": "OR",
    "city": "New Pine Creek"
  },
  {
    "value": "97636",
    "label": "Paisley - OR 97636",
    "county": "Lake County",
    "state": "OR",
    "city": "Paisley"
  },
  {
    "value": "97637",
    "label": "Plush - OR 97637",
    "county": "Lake County",
    "state": "OR",
    "city": "Plush"
  },
  {
    "value": "97638",
    "label": "Silver Lake - OR 97638",
    "county": "Lake County",
    "state": "OR",
    "city": "Silver Lake"
  },
  {
    "value": "97639",
    "label": "Sprague River - OR 97639",
    "county": "Klamath County",
    "state": "OR",
    "city": "Sprague River"
  },
  {
    "value": "97640",
    "label": "Summer Lake - OR 97640",
    "county": "Lake County",
    "state": "OR",
    "city": "Summer Lake"
  },
  {
    "value": "97701",
    "label": "Bend - OR 97701",
    "county": "Deschutes County",
    "state": "OR",
    "city": "Bend"
  },
  {
    "value": "97702",
    "label": "Bend - OR 97702",
    "county": "Deschutes County",
    "state": "OR",
    "city": "Bend"
  },
  {
    "value": "97703",
    "label": "Bend - OR 97703",
    "county": "Deschutes County",
    "state": "OR",
    "city": "Bend"
  },
  {
    "value": "97707",
    "label": "Bend - OR 97707",
    "county": "Deschutes County",
    "state": "OR",
    "city": "Bend"
  },
  {
    "value": "97710",
    "label": "Fields - OR 97710",
    "county": "Harney County",
    "state": "OR",
    "city": "Fields"
  },
  {
    "value": "97711",
    "label": "Ashwood - OR 97711",
    "county": "Jefferson County",
    "state": "OR",
    "city": "Ashwood"
  },
  {
    "value": "97712",
    "label": "Brothers - OR 97712",
    "county": "Deschutes County",
    "state": "OR",
    "city": "Brothers"
  },
  {
    "value": "97720",
    "label": "Burns - OR 97720",
    "county": "Harney County",
    "state": "OR",
    "city": "Burns"
  },
  {
    "value": "97721",
    "label": "Princeton - OR 97721",
    "county": "Harney County",
    "state": "OR",
    "city": "Princeton"
  },
  {
    "value": "97722",
    "label": "Diamond - OR 97722",
    "county": "Harney County",
    "state": "OR",
    "city": "Diamond"
  },
  {
    "value": "97730",
    "label": "Camp Sherman - OR 97730",
    "county": "Jefferson County",
    "state": "OR",
    "city": "Camp Sherman"
  },
  {
    "value": "97731",
    "label": "Chemult - OR 97731",
    "county": "Klamath County",
    "state": "OR",
    "city": "Chemult"
  },
  {
    "value": "97733",
    "label": "Crescent - OR 97733",
    "county": "Klamath County",
    "state": "OR",
    "city": "Crescent"
  },
  {
    "value": "97734",
    "label": "Culver - OR 97734",
    "county": "Jefferson County",
    "state": "OR",
    "city": "Culver"
  },
  {
    "value": "97736",
    "label": "Frenchglen - OR 97736",
    "county": "Harney County",
    "state": "OR",
    "city": "Frenchglen"
  },
  {
    "value": "97737",
    "label": "Gilchrist - OR 97737",
    "county": "Klamath County",
    "state": "OR",
    "city": "Gilchrist"
  },
  {
    "value": "97738",
    "label": "Hines - OR 97738",
    "county": "Harney County",
    "state": "OR",
    "city": "Hines"
  },
  {
    "value": "97739",
    "label": "La Pine - OR 97739",
    "county": "Deschutes County",
    "state": "OR",
    "city": "La Pine"
  },
  {
    "value": "97741",
    "label": "Madras - OR 97741",
    "county": "Jefferson County",
    "state": "OR",
    "city": "Madras"
  },
  {
    "value": "97750",
    "label": "Mitchell - OR 97750",
    "county": "Wheeler County",
    "state": "OR",
    "city": "Mitchell"
  },
  {
    "value": "97751",
    "label": "Paulina - OR 97751",
    "county": "Crook County",
    "state": "OR",
    "city": "Paulina"
  },
  {
    "value": "97752",
    "label": "Post - OR 97752",
    "county": "Crook County",
    "state": "OR",
    "city": "Post"
  },
  {
    "value": "97753",
    "label": "Powell Butte - OR 97753",
    "county": "Crook County",
    "state": "OR",
    "city": "Powell Butte"
  },
  {
    "value": "97754",
    "label": "Prineville - OR 97754",
    "county": "Crook County",
    "state": "OR",
    "city": "Prineville"
  },
  {
    "value": "97756",
    "label": "Redmond - OR 97756",
    "county": "Deschutes County",
    "state": "OR",
    "city": "Redmond"
  },
  {
    "value": "97758",
    "label": "Riley - OR 97758",
    "county": "Harney County",
    "state": "OR",
    "city": "Riley"
  },
  {
    "value": "97759",
    "label": "Sisters - OR 97759",
    "county": "Deschutes County",
    "state": "OR",
    "city": "Sisters"
  },
  {
    "value": "97760",
    "label": "Terrebonne - OR 97760",
    "county": "Jefferson County",
    "state": "OR",
    "city": "Terrebonne"
  },
  {
    "value": "97801",
    "label": "Pendleton - OR 97801",
    "county": "Umatilla County",
    "state": "OR",
    "city": "Pendleton"
  },
  {
    "value": "97810",
    "label": "Adams - OR 97810",
    "county": "Umatilla County",
    "state": "OR",
    "city": "Adams"
  },
  {
    "value": "97812",
    "label": "Arlington - OR 97812",
    "county": "Gilliam County",
    "state": "OR",
    "city": "Arlington"
  },
  {
    "value": "97813",
    "label": "Athena - OR 97813",
    "county": "Umatilla County",
    "state": "OR",
    "city": "Athena"
  },
  {
    "value": "97814",
    "label": "Baker City - OR 97814",
    "county": "Baker County",
    "state": "OR",
    "city": "Baker City"
  },
  {
    "value": "97817",
    "label": "Bates - OR 97817",
    "county": "Grant County",
    "state": "OR",
    "city": "Bates"
  },
  {
    "value": "97818",
    "label": "Boardman - OR 97818",
    "county": "Morrow County",
    "state": "OR",
    "city": "Boardman"
  },
  {
    "value": "97819",
    "label": "Bridgeport - OR 97819",
    "county": "Baker County",
    "state": "OR",
    "city": "Bridgeport"
  },
  {
    "value": "97820",
    "label": "Canyon City - OR 97820",
    "county": "Grant County",
    "state": "OR",
    "city": "Canyon City"
  },
  {
    "value": "97823",
    "label": "Condon - OR 97823",
    "county": "Gilliam County",
    "state": "OR",
    "city": "Condon"
  },
  {
    "value": "97824",
    "label": "Cove - OR 97824",
    "county": "Union County",
    "state": "OR",
    "city": "Cove"
  },
  {
    "value": "97825",
    "label": "Dayville - OR 97825",
    "county": "Grant County",
    "state": "OR",
    "city": "Dayville"
  },
  {
    "value": "97826",
    "label": "Echo - OR 97826",
    "county": "Umatilla County",
    "state": "OR",
    "city": "Echo"
  },
  {
    "value": "97827",
    "label": "Elgin - OR 97827",
    "county": "Union County",
    "state": "OR",
    "city": "Elgin"
  },
  {
    "value": "97828",
    "label": "Enterprise - OR 97828",
    "county": "Wallowa County",
    "state": "OR",
    "city": "Enterprise"
  },
  {
    "value": "97830",
    "label": "Fossil - OR 97830",
    "county": "Wheeler County",
    "state": "OR",
    "city": "Fossil"
  },
  {
    "value": "97833",
    "label": "Haines - OR 97833",
    "county": "Baker County",
    "state": "OR",
    "city": "Haines"
  },
  {
    "value": "97834",
    "label": "Halfway - OR 97834",
    "county": "Baker County",
    "state": "OR",
    "city": "Halfway"
  },
  {
    "value": "97835",
    "label": "Helix - OR 97835",
    "county": "Umatilla County",
    "state": "OR",
    "city": "Helix"
  },
  {
    "value": "97836",
    "label": "Heppner - OR 97836",
    "county": "Morrow County",
    "state": "OR",
    "city": "Heppner"
  },
  {
    "value": "97837",
    "label": "Hereford - OR 97837",
    "county": "Baker County",
    "state": "OR",
    "city": "Hereford"
  },
  {
    "value": "97838",
    "label": "Hermiston - OR 97838",
    "county": "Umatilla County",
    "state": "OR",
    "city": "Hermiston"
  },
  {
    "value": "97839",
    "label": "Lexington - OR 97839",
    "county": "Morrow County",
    "state": "OR",
    "city": "Lexington"
  },
  {
    "value": "97840",
    "label": "Oxbow - OR 97840",
    "county": "Baker County",
    "state": "OR",
    "city": "Oxbow"
  },
  {
    "value": "97841",
    "label": "Imbler - OR 97841",
    "county": "Union County",
    "state": "OR",
    "city": "Imbler"
  },
  {
    "value": "97842",
    "label": "Imnaha - OR 97842",
    "county": "Wallowa County",
    "state": "OR",
    "city": "Imnaha"
  },
  {
    "value": "97843",
    "label": "Ione - OR 97843",
    "county": "Morrow County",
    "state": "OR",
    "city": "Ione"
  },
  {
    "value": "97844",
    "label": "Irrigon - OR 97844",
    "county": "Morrow County",
    "state": "OR",
    "city": "Irrigon"
  },
  {
    "value": "97845",
    "label": "John Day - OR 97845",
    "county": "Grant County",
    "state": "OR",
    "city": "John Day"
  },
  {
    "value": "97846",
    "label": "Joseph - OR 97846",
    "county": "Wallowa County",
    "state": "OR",
    "city": "Joseph"
  },
  {
    "value": "97848",
    "label": "Kimberly - OR 97848",
    "county": "Grant County",
    "state": "OR",
    "city": "Kimberly"
  },
  {
    "value": "97850",
    "label": "La Grande - OR 97850",
    "county": "Union County",
    "state": "OR",
    "city": "La Grande"
  },
  {
    "value": "97856",
    "label": "Long Creek - OR 97856",
    "county": "Grant County",
    "state": "OR",
    "city": "Long Creek"
  },
  {
    "value": "97857",
    "label": "Lostine - OR 97857",
    "county": "Wallowa County",
    "state": "OR",
    "city": "Lostine"
  },
  {
    "value": "97861",
    "label": "Mikkalo - OR 97861",
    "county": "Gilliam County",
    "state": "OR",
    "city": "Mikkalo"
  },
  {
    "value": "97862",
    "label": "Milton Freewater - OR 97862",
    "county": "Umatilla County",
    "state": "OR",
    "city": "Milton Freewater"
  },
  {
    "value": "97864",
    "label": "Monument - OR 97864",
    "county": "Grant County",
    "state": "OR",
    "city": "Monument"
  },
  {
    "value": "97865",
    "label": "Mount Vernon - OR 97865",
    "county": "Grant County",
    "state": "OR",
    "city": "Mount Vernon"
  },
  {
    "value": "97867",
    "label": "North Powder - OR 97867",
    "county": "Union County",
    "state": "OR",
    "city": "North Powder"
  },
  {
    "value": "97868",
    "label": "Pilot Rock - OR 97868",
    "county": "Umatilla County",
    "state": "OR",
    "city": "Pilot Rock"
  },
  {
    "value": "97869",
    "label": "Prairie City - OR 97869",
    "county": "Grant County",
    "state": "OR",
    "city": "Prairie City"
  },
  {
    "value": "97870",
    "label": "Richland - OR 97870",
    "county": "Baker County",
    "state": "OR",
    "city": "Richland"
  },
  {
    "value": "97873",
    "label": "Seneca - OR 97873",
    "county": "Grant County",
    "state": "OR",
    "city": "Seneca"
  },
  {
    "value": "97874",
    "label": "Spray - OR 97874",
    "county": "Wheeler County",
    "state": "OR",
    "city": "Spray"
  },
  {
    "value": "97875",
    "label": "Stanfield - OR 97875",
    "county": "Umatilla County",
    "state": "OR",
    "city": "Stanfield"
  },
  {
    "value": "97876",
    "label": "Summerville - OR 97876",
    "county": "Union County",
    "state": "OR",
    "city": "Summerville"
  },
  {
    "value": "97877",
    "label": "Sumpter - OR 97877",
    "county": "Baker County",
    "state": "OR",
    "city": "Sumpter"
  },
  {
    "value": "97882",
    "label": "Umatilla - OR 97882",
    "county": "Umatilla County",
    "state": "OR",
    "city": "Umatilla"
  },
  {
    "value": "97883",
    "label": "Union - OR 97883",
    "county": "Union County",
    "state": "OR",
    "city": "Union"
  },
  {
    "value": "97884",
    "label": "Unity - OR 97884",
    "county": "Baker County",
    "state": "OR",
    "city": "Unity"
  },
  {
    "value": "97885",
    "label": "Wallowa - OR 97885",
    "county": "Wallowa County",
    "state": "OR",
    "city": "Wallowa"
  },
  {
    "value": "97886",
    "label": "Weston - OR 97886",
    "county": "Umatilla County",
    "state": "OR",
    "city": "Weston"
  },
  {
    "value": "97901",
    "label": "Adrian - OR 97901",
    "county": "Malheur County",
    "state": "OR",
    "city": "Adrian"
  },
  {
    "value": "97903",
    "label": "Brogan - OR 97903",
    "county": "Malheur County",
    "state": "OR",
    "city": "Brogan"
  },
  {
    "value": "97904",
    "label": "Drewsey - OR 97904",
    "county": "Harney County",
    "state": "OR",
    "city": "Drewsey"
  },
  {
    "value": "97906",
    "label": "Harper - OR 97906",
    "county": "Malheur County",
    "state": "OR",
    "city": "Harper"
  },
  {
    "value": "97907",
    "label": "Huntington - OR 97907",
    "county": "Baker County",
    "state": "OR",
    "city": "Huntington"
  },
  {
    "value": "97908",
    "label": "Ironside - OR 97908",
    "county": "Malheur County",
    "state": "OR",
    "city": "Ironside"
  },
  {
    "value": "97909",
    "label": "Jamieson - OR 97909",
    "county": "Malheur County",
    "state": "OR",
    "city": "Jamieson"
  },
  {
    "value": "97910",
    "label": "Jordan Valley - OR 97910",
    "county": "Malheur County",
    "state": "OR",
    "city": "Jordan Valley"
  },
  {
    "value": "97911",
    "label": "Juntura - OR 97911",
    "county": "Malheur County",
    "state": "OR",
    "city": "Juntura"
  },
  {
    "value": "97913",
    "label": "Nyssa - OR 97913",
    "county": "Malheur County",
    "state": "OR",
    "city": "Nyssa"
  },
  {
    "value": "97914",
    "label": "Ontario - OR 97914",
    "county": "Malheur County",
    "state": "OR",
    "city": "Ontario"
  },
  {
    "value": "97917",
    "label": "Riverside - OR 97917",
    "county": "Malheur County",
    "state": "OR",
    "city": "Riverside"
  },
  {
    "value": "97918",
    "label": "Vale - OR 97918",
    "county": "Malheur County",
    "state": "OR",
    "city": "Vale"
  },
  {
    "value": "15001",
    "label": "Aliquippa - PA 15001",
    "county": "Beaver County",
    "state": "PA",
    "city": "Aliquippa"
  },
  {
    "value": "15003",
    "label": "Ambridge - PA 15003",
    "county": "Beaver County",
    "state": "PA",
    "city": "Ambridge"
  },
  {
    "value": "15005",
    "label": "Baden - PA 15005",
    "county": "Beaver County",
    "state": "PA",
    "city": "Baden"
  },
  {
    "value": "15007",
    "label": "Bakerstown - PA 15007",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Bakerstown"
  },
  {
    "value": "15009",
    "label": "Beaver - PA 15009",
    "county": "Beaver County",
    "state": "PA",
    "city": "Beaver"
  },
  {
    "value": "15010",
    "label": "Beaver Falls - PA 15010",
    "county": "Beaver County",
    "state": "PA",
    "city": "Beaver Falls"
  },
  {
    "value": "15012",
    "label": "Belle Vernon - PA 15012",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Belle Vernon"
  },
  {
    "value": "15014",
    "label": "Brackenridge - PA 15014",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Brackenridge"
  },
  {
    "value": "15015",
    "label": "Bradfordwoods - PA 15015",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Bradfordwoods"
  },
  {
    "value": "15017",
    "label": "Bridgeville - PA 15017",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Bridgeville"
  },
  {
    "value": "15018",
    "label": "Buena Vista - PA 15018",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Buena Vista"
  },
  {
    "value": "15019",
    "label": "Bulger - PA 15019",
    "county": "Washington County",
    "state": "PA",
    "city": "Bulger"
  },
  {
    "value": "15021",
    "label": "Burgettstown - PA 15021",
    "county": "Washington County",
    "state": "PA",
    "city": "Burgettstown"
  },
  {
    "value": "15022",
    "label": "Charleroi - PA 15022",
    "county": "Washington County",
    "state": "PA",
    "city": "Charleroi"
  },
  {
    "value": "15024",
    "label": "Cheswick - PA 15024",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Cheswick"
  },
  {
    "value": "15025",
    "label": "Clairton - PA 15025",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Clairton"
  },
  {
    "value": "15026",
    "label": "Clinton - PA 15026",
    "county": "Beaver County",
    "state": "PA",
    "city": "Clinton"
  },
  {
    "value": "15027",
    "label": "Conway - PA 15027",
    "county": "Beaver County",
    "state": "PA",
    "city": "Conway"
  },
  {
    "value": "15030",
    "label": "Creighton - PA 15030",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Creighton"
  },
  {
    "value": "15031",
    "label": "Cuddy - PA 15031",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Cuddy"
  },
  {
    "value": "15033",
    "label": "Donora - PA 15033",
    "county": "Washington County",
    "state": "PA",
    "city": "Donora"
  },
  {
    "value": "15034",
    "label": "Dravosburg - PA 15034",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Dravosburg"
  },
  {
    "value": "15035",
    "label": "East Mc Keesport - PA 15035",
    "county": "Allegheny County",
    "state": "PA",
    "city": "East Mc Keesport"
  },
  {
    "value": "15037",
    "label": "Elizabeth - PA 15037",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Elizabeth"
  },
  {
    "value": "15042",
    "label": "Freedom - PA 15042",
    "county": "Beaver County",
    "state": "PA",
    "city": "Freedom"
  },
  {
    "value": "15043",
    "label": "Georgetown - PA 15043",
    "county": "Beaver County",
    "state": "PA",
    "city": "Georgetown"
  },
  {
    "value": "15044",
    "label": "Gibsonia - PA 15044",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Gibsonia"
  },
  {
    "value": "15045",
    "label": "Glassport - PA 15045",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Glassport"
  },
  {
    "value": "15046",
    "label": "Crescent - PA 15046",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Crescent"
  },
  {
    "value": "15049",
    "label": "Harwick - PA 15049",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Harwick"
  },
  {
    "value": "15050",
    "label": "Hookstown - PA 15050",
    "county": "Beaver County",
    "state": "PA",
    "city": "Hookstown"
  },
  {
    "value": "15051",
    "label": "Indianola - PA 15051",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Indianola"
  },
  {
    "value": "15052",
    "label": "Industry - PA 15052",
    "county": "Beaver County",
    "state": "PA",
    "city": "Industry"
  },
  {
    "value": "15053",
    "label": "Joffre - PA 15053",
    "county": "Washington County",
    "state": "PA",
    "city": "Joffre"
  },
  {
    "value": "15055",
    "label": "Lawrence - PA 15055",
    "county": "Washington County",
    "state": "PA",
    "city": "Lawrence"
  },
  {
    "value": "15056",
    "label": "Leetsdale - PA 15056",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Leetsdale"
  },
  {
    "value": "15057",
    "label": "Mc Donald - PA 15057",
    "county": "Washington County",
    "state": "PA",
    "city": "Mc Donald"
  },
  {
    "value": "15059",
    "label": "Midland - PA 15059",
    "county": "Beaver County",
    "state": "PA",
    "city": "Midland"
  },
  {
    "value": "15061",
    "label": "Monaca - PA 15061",
    "county": "Beaver County",
    "state": "PA",
    "city": "Monaca"
  },
  {
    "value": "15062",
    "label": "Monessen - PA 15062",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Monessen"
  },
  {
    "value": "15063",
    "label": "Monongahela - PA 15063",
    "county": "Washington County",
    "state": "PA",
    "city": "Monongahela"
  },
  {
    "value": "15064",
    "label": "Morgan - PA 15064",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Morgan"
  },
  {
    "value": "15065",
    "label": "Natrona Heights - PA 15065",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Natrona Heights"
  },
  {
    "value": "15066",
    "label": "New Brighton - PA 15066",
    "county": "Beaver County",
    "state": "PA",
    "city": "New Brighton"
  },
  {
    "value": "15067",
    "label": "New Eagle - PA 15067",
    "county": "Washington County",
    "state": "PA",
    "city": "New Eagle"
  },
  {
    "value": "15068",
    "label": "New Kensington - PA 15068",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "New Kensington"
  },
  {
    "value": "15071",
    "label": "Oakdale - PA 15071",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Oakdale"
  },
  {
    "value": "15074",
    "label": "Rochester - PA 15074",
    "county": "Beaver County",
    "state": "PA",
    "city": "Rochester"
  },
  {
    "value": "15076",
    "label": "Russellton - PA 15076",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Russellton"
  },
  {
    "value": "15078",
    "label": "Slovan - PA 15078",
    "county": "Washington County",
    "state": "PA",
    "city": "Slovan"
  },
  {
    "value": "15083",
    "label": "Sutersville - PA 15083",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Sutersville"
  },
  {
    "value": "15084",
    "label": "Tarentum - PA 15084",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Tarentum"
  },
  {
    "value": "15085",
    "label": "Trafford - PA 15085",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Trafford"
  },
  {
    "value": "15086",
    "label": "Warrendale - PA 15086",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Warrendale"
  },
  {
    "value": "15089",
    "label": "West Newton - PA 15089",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "West Newton"
  },
  {
    "value": "15090",
    "label": "Wexford - PA 15090",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Wexford"
  },
  {
    "value": "15101",
    "label": "Allison Park - PA 15101",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Allison Park"
  },
  {
    "value": "15102",
    "label": "Bethel Park - PA 15102",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Bethel Park"
  },
  {
    "value": "15104",
    "label": "Braddock - PA 15104",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Braddock"
  },
  {
    "value": "15106",
    "label": "Carnegie - PA 15106",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Carnegie"
  },
  {
    "value": "15108",
    "label": "Coraopolis - PA 15108",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Coraopolis"
  },
  {
    "value": "15110",
    "label": "Duquesne - PA 15110",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Duquesne"
  },
  {
    "value": "15112",
    "label": "East Pittsburgh - PA 15112",
    "county": "Allegheny County",
    "state": "PA",
    "city": "East Pittsburgh"
  },
  {
    "value": "15116",
    "label": "Glenshaw - PA 15116",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Glenshaw"
  },
  {
    "value": "15120",
    "label": "Homestead - PA 15120",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Homestead"
  },
  {
    "value": "15122",
    "label": "West Mifflin - PA 15122",
    "county": "Allegheny County",
    "state": "PA",
    "city": "West Mifflin"
  },
  {
    "value": "15123",
    "label": "West Mifflin - PA 15123",
    "county": "Allegheny County",
    "state": "PA",
    "city": "West Mifflin"
  },
  {
    "value": "15126",
    "label": "Imperial - PA 15126",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Imperial"
  },
  {
    "value": "15129",
    "label": "South Park - PA 15129",
    "county": "Allegheny County",
    "state": "PA",
    "city": "South Park"
  },
  {
    "value": "15131",
    "label": "Mckeesport - PA 15131",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Mckeesport"
  },
  {
    "value": "15132",
    "label": "Mckeesport - PA 15132",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Mckeesport"
  },
  {
    "value": "15133",
    "label": "Mckeesport - PA 15133",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Mckeesport"
  },
  {
    "value": "15135",
    "label": "Mckeesport - PA 15135",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Mckeesport"
  },
  {
    "value": "15136",
    "label": "Mc Kees Rocks - PA 15136",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Mc Kees Rocks"
  },
  {
    "value": "15137",
    "label": "North Versailles - PA 15137",
    "county": "Allegheny County",
    "state": "PA",
    "city": "North Versailles"
  },
  {
    "value": "15139",
    "label": "Oakmont - PA 15139",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Oakmont"
  },
  {
    "value": "15140",
    "label": "Pitcairn - PA 15140",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pitcairn"
  },
  {
    "value": "15142",
    "label": "Presto - PA 15142",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Presto"
  },
  {
    "value": "15143",
    "label": "Sewickley - PA 15143",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Sewickley"
  },
  {
    "value": "15144",
    "label": "Springdale - PA 15144",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Springdale"
  },
  {
    "value": "15145",
    "label": "Turtle Creek - PA 15145",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Turtle Creek"
  },
  {
    "value": "15146",
    "label": "Monroeville - PA 15146",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Monroeville"
  },
  {
    "value": "15147",
    "label": "Verona - PA 15147",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Verona"
  },
  {
    "value": "15148",
    "label": "Wilmerding - PA 15148",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Wilmerding"
  },
  {
    "value": "15201",
    "label": "Pittsburgh - PA 15201",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15202",
    "label": "Pittsburgh - PA 15202",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15203",
    "label": "Pittsburgh - PA 15203",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15204",
    "label": "Pittsburgh - PA 15204",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15205",
    "label": "Pittsburgh - PA 15205",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15206",
    "label": "Pittsburgh - PA 15206",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15207",
    "label": "Pittsburgh - PA 15207",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15208",
    "label": "Pittsburgh - PA 15208",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15209",
    "label": "Pittsburgh - PA 15209",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15210",
    "label": "Pittsburgh - PA 15210",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15211",
    "label": "Pittsburgh - PA 15211",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15212",
    "label": "Pittsburgh - PA 15212",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15213",
    "label": "Pittsburgh - PA 15213",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15214",
    "label": "Pittsburgh - PA 15214",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15215",
    "label": "Pittsburgh - PA 15215",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15216",
    "label": "Pittsburgh - PA 15216",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15217",
    "label": "Pittsburgh - PA 15217",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15218",
    "label": "Pittsburgh - PA 15218",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15219",
    "label": "Pittsburgh - PA 15219",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15220",
    "label": "Pittsburgh - PA 15220",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15221",
    "label": "Pittsburgh - PA 15221",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15222",
    "label": "Pittsburgh - PA 15222",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15223",
    "label": "Pittsburgh - PA 15223",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15224",
    "label": "Pittsburgh - PA 15224",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15225",
    "label": "Pittsburgh - PA 15225",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15226",
    "label": "Pittsburgh - PA 15226",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15227",
    "label": "Pittsburgh - PA 15227",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15228",
    "label": "Pittsburgh - PA 15228",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15229",
    "label": "Pittsburgh - PA 15229",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15232",
    "label": "Pittsburgh - PA 15232",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15233",
    "label": "Pittsburgh - PA 15233",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15234",
    "label": "Pittsburgh - PA 15234",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15235",
    "label": "Pittsburgh - PA 15235",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15236",
    "label": "Pittsburgh - PA 15236",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15237",
    "label": "Pittsburgh - PA 15237",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15238",
    "label": "Pittsburgh - PA 15238",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15239",
    "label": "Pittsburgh - PA 15239",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15241",
    "label": "Pittsburgh - PA 15241",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15243",
    "label": "Pittsburgh - PA 15243",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15260",
    "label": "Pittsburgh - PA 15260",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15272",
    "label": "Pittsburgh - PA 15272",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15273",
    "label": "Pittsburgh - PA 15273",
    "county": "",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15275",
    "label": "Pittsburgh - PA 15275",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15276",
    "label": "Pittsburgh - PA 15276",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15288",
    "label": "Pittsburgh - PA 15288",
    "county": "",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15290",
    "label": "Pittsburgh - PA 15290",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15295",
    "label": "Pittsburgh - PA 15295",
    "county": "Allegheny County",
    "state": "PA",
    "city": "Pittsburgh"
  },
  {
    "value": "15301",
    "label": "Washington - PA 15301",
    "county": "Washington County",
    "state": "PA",
    "city": "Washington"
  },
  {
    "value": "15310",
    "label": "Aleppo - PA 15310",
    "county": "Greene County",
    "state": "PA",
    "city": "Aleppo"
  },
  {
    "value": "15311",
    "label": "Amity - PA 15311",
    "county": "Washington County",
    "state": "PA",
    "city": "Amity"
  },
  {
    "value": "15312",
    "label": "Avella - PA 15312",
    "county": "Washington County",
    "state": "PA",
    "city": "Avella"
  },
  {
    "value": "15314",
    "label": "Bentleyville - PA 15314",
    "county": "Washington County",
    "state": "PA",
    "city": "Bentleyville"
  },
  {
    "value": "15316",
    "label": "Brave - PA 15316",
    "county": "Greene County",
    "state": "PA",
    "city": "Brave"
  },
  {
    "value": "15317",
    "label": "Canonsburg - PA 15317",
    "county": "Washington County",
    "state": "PA",
    "city": "Canonsburg"
  },
  {
    "value": "15320",
    "label": "Carmichaels - PA 15320",
    "county": "Greene County",
    "state": "PA",
    "city": "Carmichaels"
  },
  {
    "value": "15321",
    "label": "Cecil - PA 15321",
    "county": "Washington County",
    "state": "PA",
    "city": "Cecil"
  },
  {
    "value": "15322",
    "label": "Clarksville - PA 15322",
    "county": "Greene County",
    "state": "PA",
    "city": "Clarksville"
  },
  {
    "value": "15323",
    "label": "Claysville - PA 15323",
    "county": "Washington County",
    "state": "PA",
    "city": "Claysville"
  },
  {
    "value": "15327",
    "label": "Dilliner - PA 15327",
    "county": "Greene County",
    "state": "PA",
    "city": "Dilliner"
  },
  {
    "value": "15329",
    "label": "Prosperity - PA 15329",
    "county": "Washington County",
    "state": "PA",
    "city": "Prosperity"
  },
  {
    "value": "15330",
    "label": "Eighty Four - PA 15330",
    "county": "Washington County",
    "state": "PA",
    "city": "Eighty Four"
  },
  {
    "value": "15332",
    "label": "Finleyville - PA 15332",
    "county": "Washington County",
    "state": "PA",
    "city": "Finleyville"
  },
  {
    "value": "15333",
    "label": "Fredericktown - PA 15333",
    "county": "Washington County",
    "state": "PA",
    "city": "Fredericktown"
  },
  {
    "value": "15334",
    "label": "Garards Fort - PA 15334",
    "county": "Greene County",
    "state": "PA",
    "city": "Garards Fort"
  },
  {
    "value": "15337",
    "label": "Graysville - PA 15337",
    "county": "Greene County",
    "state": "PA",
    "city": "Graysville"
  },
  {
    "value": "15338",
    "label": "Greensboro - PA 15338",
    "county": "Greene County",
    "state": "PA",
    "city": "Greensboro"
  },
  {
    "value": "15340",
    "label": "Hickory - PA 15340",
    "county": "Washington County",
    "state": "PA",
    "city": "Hickory"
  },
  {
    "value": "15341",
    "label": "Holbrook - PA 15341",
    "county": "Greene County",
    "state": "PA",
    "city": "Holbrook"
  },
  {
    "value": "15342",
    "label": "Houston - PA 15342",
    "county": "Washington County",
    "state": "PA",
    "city": "Houston"
  },
  {
    "value": "15344",
    "label": "Jefferson - PA 15344",
    "county": "Greene County",
    "state": "PA",
    "city": "Jefferson"
  },
  {
    "value": "15345",
    "label": "Marianna - PA 15345",
    "county": "Washington County",
    "state": "PA",
    "city": "Marianna"
  },
  {
    "value": "15349",
    "label": "Mount Morris - PA 15349",
    "county": "Greene County",
    "state": "PA",
    "city": "Mount Morris"
  },
  {
    "value": "15352",
    "label": "New Freeport - PA 15352",
    "county": "Greene County",
    "state": "PA",
    "city": "New Freeport"
  },
  {
    "value": "15357",
    "label": "Rices Landing - PA 15357",
    "county": "Greene County",
    "state": "PA",
    "city": "Rices Landing"
  },
  {
    "value": "15359",
    "label": "Rogersville - PA 15359",
    "county": "Greene County",
    "state": "PA",
    "city": "Rogersville"
  },
  {
    "value": "15360",
    "label": "Scenery Hill - PA 15360",
    "county": "Washington County",
    "state": "PA",
    "city": "Scenery Hill"
  },
  {
    "value": "15362",
    "label": "Spraggs - PA 15362",
    "county": "Greene County",
    "state": "PA",
    "city": "Spraggs"
  },
  {
    "value": "15363",
    "label": "Strabane - PA 15363",
    "county": "Washington County",
    "state": "PA",
    "city": "Strabane"
  },
  {
    "value": "15364",
    "label": "Sycamore - PA 15364",
    "county": "Greene County",
    "state": "PA",
    "city": "Sycamore"
  },
  {
    "value": "15367",
    "label": "Venetia - PA 15367",
    "county": "Washington County",
    "state": "PA",
    "city": "Venetia"
  },
  {
    "value": "15370",
    "label": "Waynesburg - PA 15370",
    "county": "Greene County",
    "state": "PA",
    "city": "Waynesburg"
  },
  {
    "value": "15376",
    "label": "West Alexander - PA 15376",
    "county": "Washington County",
    "state": "PA",
    "city": "West Alexander"
  },
  {
    "value": "15377",
    "label": "West Finley - PA 15377",
    "county": "Washington County",
    "state": "PA",
    "city": "West Finley"
  },
  {
    "value": "15380",
    "label": "Wind Ridge - PA 15380",
    "county": "Greene County",
    "state": "PA",
    "city": "Wind Ridge"
  },
  {
    "value": "15401",
    "label": "Uniontown - PA 15401",
    "county": "Fayette County",
    "state": "PA",
    "city": "Uniontown"
  },
  {
    "value": "15410",
    "label": "Adah - PA 15410",
    "county": "Fayette County",
    "state": "PA",
    "city": "Adah"
  },
  {
    "value": "15411",
    "label": "Addison - PA 15411",
    "county": "Somerset County",
    "state": "PA",
    "city": "Addison"
  },
  {
    "value": "15413",
    "label": "Allison - PA 15413",
    "county": "Fayette County",
    "state": "PA",
    "city": "Allison"
  },
  {
    "value": "15417",
    "label": "Brownsville - PA 15417",
    "county": "Fayette County",
    "state": "PA",
    "city": "Brownsville"
  },
  {
    "value": "15419",
    "label": "California - PA 15419",
    "county": "Washington County",
    "state": "PA",
    "city": "California"
  },
  {
    "value": "15423",
    "label": "Coal Center - PA 15423",
    "county": "Washington County",
    "state": "PA",
    "city": "Coal Center"
  },
  {
    "value": "15424",
    "label": "Confluence - PA 15424",
    "county": "Somerset County",
    "state": "PA",
    "city": "Confluence"
  },
  {
    "value": "15425",
    "label": "Connellsville - PA 15425",
    "county": "Fayette County",
    "state": "PA",
    "city": "Connellsville"
  },
  {
    "value": "15427",
    "label": "Daisytown - PA 15427",
    "county": "Washington County",
    "state": "PA",
    "city": "Daisytown"
  },
  {
    "value": "15428",
    "label": "Dawson - PA 15428",
    "county": "Fayette County",
    "state": "PA",
    "city": "Dawson"
  },
  {
    "value": "15431",
    "label": "Dunbar - PA 15431",
    "county": "Fayette County",
    "state": "PA",
    "city": "Dunbar"
  },
  {
    "value": "15433",
    "label": "East Millsboro - PA 15433",
    "county": "Fayette County",
    "state": "PA",
    "city": "East Millsboro"
  },
  {
    "value": "15436",
    "label": "Fairchance - PA 15436",
    "county": "Fayette County",
    "state": "PA",
    "city": "Fairchance"
  },
  {
    "value": "15437",
    "label": "Farmington - PA 15437",
    "county": "Fayette County",
    "state": "PA",
    "city": "Farmington"
  },
  {
    "value": "15438",
    "label": "Fayette City - PA 15438",
    "county": "Fayette County",
    "state": "PA",
    "city": "Fayette City"
  },
  {
    "value": "15440",
    "label": "Gibbon Glade - PA 15440",
    "county": "Fayette County",
    "state": "PA",
    "city": "Gibbon Glade"
  },
  {
    "value": "15442",
    "label": "Grindstone - PA 15442",
    "county": "Fayette County",
    "state": "PA",
    "city": "Grindstone"
  },
  {
    "value": "15444",
    "label": "Hiller - PA 15444",
    "county": "Fayette County",
    "state": "PA",
    "city": "Hiller"
  },
  {
    "value": "15445",
    "label": "Hopwood - PA 15445",
    "county": "Fayette County",
    "state": "PA",
    "city": "Hopwood"
  },
  {
    "value": "15446",
    "label": "Indian Head - PA 15446",
    "county": "Fayette County",
    "state": "PA",
    "city": "Indian Head"
  },
  {
    "value": "15450",
    "label": "La Belle - PA 15450",
    "county": "Fayette County",
    "state": "PA",
    "city": "La Belle"
  },
  {
    "value": "15451",
    "label": "Lake Lynn - PA 15451",
    "county": "Fayette County",
    "state": "PA",
    "city": "Lake Lynn"
  },
  {
    "value": "15456",
    "label": "Lemont Furnace - PA 15456",
    "county": "Fayette County",
    "state": "PA",
    "city": "Lemont Furnace"
  },
  {
    "value": "15458",
    "label": "Mc Clellandtown - PA 15458",
    "county": "Fayette County",
    "state": "PA",
    "city": "Mc Clellandtown"
  },
  {
    "value": "15459",
    "label": "Markleysburg - PA 15459",
    "county": "Fayette County",
    "state": "PA",
    "city": "Markleysburg"
  },
  {
    "value": "15461",
    "label": "Masontown - PA 15461",
    "county": "Fayette County",
    "state": "PA",
    "city": "Masontown"
  },
  {
    "value": "15462",
    "label": "Melcroft - PA 15462",
    "county": "Fayette County",
    "state": "PA",
    "city": "Melcroft"
  },
  {
    "value": "15464",
    "label": "Mill Run - PA 15464",
    "county": "Fayette County",
    "state": "PA",
    "city": "Mill Run"
  },
  {
    "value": "15468",
    "label": "New Salem - PA 15468",
    "county": "Fayette County",
    "state": "PA",
    "city": "New Salem"
  },
  {
    "value": "15469",
    "label": "Normalville - PA 15469",
    "county": "Fayette County",
    "state": "PA",
    "city": "Normalville"
  },
  {
    "value": "15470",
    "label": "Ohiopyle - PA 15470",
    "county": "Fayette County",
    "state": "PA",
    "city": "Ohiopyle"
  },
  {
    "value": "15473",
    "label": "Perryopolis - PA 15473",
    "county": "Fayette County",
    "state": "PA",
    "city": "Perryopolis"
  },
  {
    "value": "15474",
    "label": "Point Marion - PA 15474",
    "county": "Fayette County",
    "state": "PA",
    "city": "Point Marion"
  },
  {
    "value": "15478",
    "label": "Smithfield - PA 15478",
    "county": "Fayette County",
    "state": "PA",
    "city": "Smithfield"
  },
  {
    "value": "15479",
    "label": "Smithton - PA 15479",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Smithton"
  },
  {
    "value": "15480",
    "label": "Smock - PA 15480",
    "county": "Fayette County",
    "state": "PA",
    "city": "Smock"
  },
  {
    "value": "15486",
    "label": "Vanderbilt - PA 15486",
    "county": "Fayette County",
    "state": "PA",
    "city": "Vanderbilt"
  },
  {
    "value": "15488",
    "label": "Waltersburg - PA 15488",
    "county": "Fayette County",
    "state": "PA",
    "city": "Waltersburg"
  },
  {
    "value": "15490",
    "label": "White - PA 15490",
    "county": "Fayette County",
    "state": "PA",
    "city": "White"
  },
  {
    "value": "15501",
    "label": "Somerset - PA 15501",
    "county": "Somerset County",
    "state": "PA",
    "city": "Somerset"
  },
  {
    "value": "15521",
    "label": "Alum Bank - PA 15521",
    "county": "Bedford County",
    "state": "PA",
    "city": "Alum Bank"
  },
  {
    "value": "15522",
    "label": "Bedford - PA 15522",
    "county": "Bedford County",
    "state": "PA",
    "city": "Bedford"
  },
  {
    "value": "15530",
    "label": "Berlin - PA 15530",
    "county": "Somerset County",
    "state": "PA",
    "city": "Berlin"
  },
  {
    "value": "15531",
    "label": "Boswell - PA 15531",
    "county": "Somerset County",
    "state": "PA",
    "city": "Boswell"
  },
  {
    "value": "15533",
    "label": "Breezewood - PA 15533",
    "county": "Bedford County",
    "state": "PA",
    "city": "Breezewood"
  },
  {
    "value": "15534",
    "label": "Buffalo Mills - PA 15534",
    "county": "Bedford County",
    "state": "PA",
    "city": "Buffalo Mills"
  },
  {
    "value": "15535",
    "label": "Clearville - PA 15535",
    "county": "Bedford County",
    "state": "PA",
    "city": "Clearville"
  },
  {
    "value": "15536",
    "label": "Crystal Spring - PA 15536",
    "county": "Fulton County",
    "state": "PA",
    "city": "Crystal Spring"
  },
  {
    "value": "15537",
    "label": "Everett - PA 15537",
    "county": "Bedford County",
    "state": "PA",
    "city": "Everett"
  },
  {
    "value": "15538",
    "label": "Fairhope - PA 15538",
    "county": "Somerset County",
    "state": "PA",
    "city": "Fairhope"
  },
  {
    "value": "15539",
    "label": "Fishertown - PA 15539",
    "county": "Bedford County",
    "state": "PA",
    "city": "Fishertown"
  },
  {
    "value": "15540",
    "label": "Fort Hill - PA 15540",
    "county": "Somerset County",
    "state": "PA",
    "city": "Fort Hill"
  },
  {
    "value": "15541",
    "label": "Friedens - PA 15541",
    "county": "Somerset County",
    "state": "PA",
    "city": "Friedens"
  },
  {
    "value": "15542",
    "label": "Garrett - PA 15542",
    "county": "Somerset County",
    "state": "PA",
    "city": "Garrett"
  },
  {
    "value": "15545",
    "label": "Hyndman - PA 15545",
    "county": "Bedford County",
    "state": "PA",
    "city": "Hyndman"
  },
  {
    "value": "15546",
    "label": "Jenners - PA 15546",
    "county": "Somerset County",
    "state": "PA",
    "city": "Jenners"
  },
  {
    "value": "15548",
    "label": "Kantner - PA 15548",
    "county": "Somerset County",
    "state": "PA",
    "city": "Kantner"
  },
  {
    "value": "15550",
    "label": "Manns Choice - PA 15550",
    "county": "Bedford County",
    "state": "PA",
    "city": "Manns Choice"
  },
  {
    "value": "15551",
    "label": "Markleton - PA 15551",
    "county": "Somerset County",
    "state": "PA",
    "city": "Markleton"
  },
  {
    "value": "15552",
    "label": "Meyersdale - PA 15552",
    "county": "Somerset County",
    "state": "PA",
    "city": "Meyersdale"
  },
  {
    "value": "15554",
    "label": "New Paris - PA 15554",
    "county": "Bedford County",
    "state": "PA",
    "city": "New Paris"
  },
  {
    "value": "15557",
    "label": "Rockwood - PA 15557",
    "county": "Somerset County",
    "state": "PA",
    "city": "Rockwood"
  },
  {
    "value": "15558",
    "label": "Salisbury - PA 15558",
    "county": "Somerset County",
    "state": "PA",
    "city": "Salisbury"
  },
  {
    "value": "15559",
    "label": "Schellsburg - PA 15559",
    "county": "Bedford County",
    "state": "PA",
    "city": "Schellsburg"
  },
  {
    "value": "15562",
    "label": "Springs - PA 15562",
    "county": "Somerset County",
    "state": "PA",
    "city": "Springs"
  },
  {
    "value": "15563",
    "label": "Stoystown - PA 15563",
    "county": "Somerset County",
    "state": "PA",
    "city": "Stoystown"
  },
  {
    "value": "15564",
    "label": "Wellersburg - PA 15564",
    "county": "Somerset County",
    "state": "PA",
    "city": "Wellersburg"
  },
  {
    "value": "15565",
    "label": "West Salisbury - PA 15565",
    "county": "Somerset County",
    "state": "PA",
    "city": "West Salisbury"
  },
  {
    "value": "15601",
    "label": "Greensburg - PA 15601",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Greensburg"
  },
  {
    "value": "15610",
    "label": "Acme - PA 15610",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Acme"
  },
  {
    "value": "15611",
    "label": "Adamsburg - PA 15611",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Adamsburg"
  },
  {
    "value": "15612",
    "label": "Alverton - PA 15612",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Alverton"
  },
  {
    "value": "15613",
    "label": "Apollo - PA 15613",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Apollo"
  },
  {
    "value": "15615",
    "label": "Ardara - PA 15615",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Ardara"
  },
  {
    "value": "15618",
    "label": "Avonmore - PA 15618",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Avonmore"
  },
  {
    "value": "15620",
    "label": "Bradenville - PA 15620",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Bradenville"
  },
  {
    "value": "15622",
    "label": "Champion - PA 15622",
    "county": "Fayette County",
    "state": "PA",
    "city": "Champion"
  },
  {
    "value": "15623",
    "label": "Claridge - PA 15623",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Claridge"
  },
  {
    "value": "15626",
    "label": "Delmont - PA 15626",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Delmont"
  },
  {
    "value": "15627",
    "label": "Derry - PA 15627",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Derry"
  },
  {
    "value": "15628",
    "label": "Donegal - PA 15628",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Donegal"
  },
  {
    "value": "15632",
    "label": "Export - PA 15632",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Export"
  },
  {
    "value": "15634",
    "label": "Grapeville - PA 15634",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Grapeville"
  },
  {
    "value": "15636",
    "label": "Harrison City - PA 15636",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Harrison City"
  },
  {
    "value": "15637",
    "label": "Herminie - PA 15637",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Herminie"
  },
  {
    "value": "15639",
    "label": "Hunker - PA 15639",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Hunker"
  },
  {
    "value": "15641",
    "label": "Hyde Park - PA 15641",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Hyde Park"
  },
  {
    "value": "15642",
    "label": "Irwin - PA 15642",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Irwin"
  },
  {
    "value": "15644",
    "label": "Jeannette - PA 15644",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Jeannette"
  },
  {
    "value": "15646",
    "label": "Jones Mills - PA 15646",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Jones Mills"
  },
  {
    "value": "15647",
    "label": "Larimer - PA 15647",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Larimer"
  },
  {
    "value": "15650",
    "label": "Latrobe - PA 15650",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Latrobe"
  },
  {
    "value": "15655",
    "label": "Laughlintown - PA 15655",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Laughlintown"
  },
  {
    "value": "15656",
    "label": "Leechburg - PA 15656",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Leechburg"
  },
  {
    "value": "15658",
    "label": "Ligonier - PA 15658",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Ligonier"
  },
  {
    "value": "15661",
    "label": "Loyalhanna - PA 15661",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Loyalhanna"
  },
  {
    "value": "15665",
    "label": "Manor - PA 15665",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Manor"
  },
  {
    "value": "15666",
    "label": "Mount Pleasant - PA 15666",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Mount Pleasant"
  },
  {
    "value": "15668",
    "label": "Murrysville - PA 15668",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Murrysville"
  },
  {
    "value": "15670",
    "label": "New Alexandria - PA 15670",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "New Alexandria"
  },
  {
    "value": "15671",
    "label": "New Derry - PA 15671",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "New Derry"
  },
  {
    "value": "15672",
    "label": "New Stanton - PA 15672",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "New Stanton"
  },
  {
    "value": "15675",
    "label": "Penn - PA 15675",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Penn"
  },
  {
    "value": "15677",
    "label": "Rector - PA 15677",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Rector"
  },
  {
    "value": "15678",
    "label": "Rillton - PA 15678",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Rillton"
  },
  {
    "value": "15679",
    "label": "Ruffs Dale - PA 15679",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Ruffs Dale"
  },
  {
    "value": "15681",
    "label": "Saltsburg - PA 15681",
    "county": "Indiana County",
    "state": "PA",
    "city": "Saltsburg"
  },
  {
    "value": "15683",
    "label": "Scottdale - PA 15683",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Scottdale"
  },
  {
    "value": "15684",
    "label": "Slickville - PA 15684",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Slickville"
  },
  {
    "value": "15686",
    "label": "Spring Church - PA 15686",
    "county": "Armstrong County",
    "state": "PA",
    "city": "Spring Church"
  },
  {
    "value": "15687",
    "label": "Stahlstown - PA 15687",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Stahlstown"
  },
  {
    "value": "15688",
    "label": "Tarrs - PA 15688",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Tarrs"
  },
  {
    "value": "15690",
    "label": "Vandergrift - PA 15690",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Vandergrift"
  },
  {
    "value": "15692",
    "label": "Westmoreland City - PA 15692",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Westmoreland City"
  },
  {
    "value": "15697",
    "label": "Youngwood - PA 15697",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Youngwood"
  },
  {
    "value": "15698",
    "label": "Yukon - PA 15698",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Yukon"
  },
  {
    "value": "15701",
    "label": "Indiana - PA 15701",
    "county": "Indiana County",
    "state": "PA",
    "city": "Indiana"
  },
  {
    "value": "15711",
    "label": "Anita - PA 15711",
    "county": "Jefferson County",
    "state": "PA",
    "city": "Anita"
  },
  {
    "value": "15713",
    "label": "Aultman - PA 15713",
    "county": "Indiana County",
    "state": "PA",
    "city": "Aultman"
  },
  {
    "value": "15714",
    "label": "Northern Cambria - PA 15714",
    "county": "Cambria County",
    "state": "PA",
    "city": "Northern Cambria"
  },
  {
    "value": "15717",
    "label": "Blairsville - PA 15717",
    "county": "Indiana County",
    "state": "PA",
    "city": "Blairsville"
  },
  {
    "value": "15722",
    "label": "Carrolltown - PA 15722",
    "county": "Cambria County",
    "state": "PA",
    "city": "Carrolltown"
  },
  {
    "value": "15724",
    "label": "Cherry Tree - PA 15724",
    "county": "Indiana County",
    "state": "PA",
    "city": "Cherry Tree"
  },
  {
    "value": "15725",
    "label": "Clarksburg - PA 15725",
    "county": "Indiana County",
    "state": "PA",
    "city": "Clarksburg"
  },
  {
    "value": "15728",
    "label": "Clymer - PA 15728",
    "county": "Indiana County",
    "state": "PA",
    "city": "Clymer"
  },
  {
    "value": "15729",
    "label": "Commodore - PA 15729",
    "county": "Indiana County",
    "state": "PA",
    "city": "Commodore"
  },
  {
    "value": "15730",
    "label": "Coolspring - PA 15730",
    "county": "Jefferson County",
    "state": "PA",
    "city": "Coolspring"
  },
  {
    "value": "15732",
    "label": "Creekside - PA 15732",
    "county": "Indiana County",
    "state": "PA",
    "city": "Creekside"
  },
  {
    "value": "15740",
    "label": "Frostburg - PA 15740",
    "county": "Jefferson County",
    "state": "PA",
    "city": "Frostburg"
  },
  {
    "value": "15742",
    "label": "Glen Campbell - PA 15742",
    "county": "Indiana County",
    "state": "PA",
    "city": "Glen Campbell"
  },
  {
    "value": "15744",
    "label": "Hamilton - PA 15744",
    "county": "Jefferson County",
    "state": "PA",
    "city": "Hamilton"
  },
  {
    "value": "15747",
    "label": "Home - PA 15747",
    "county": "Indiana County",
    "state": "PA",
    "city": "Home"
  },
  {
    "value": "15748",
    "label": "Homer City - PA 15748",
    "county": "Indiana County",
    "state": "PA",
    "city": "Homer City"
  },
  {
    "value": "15750",
    "label": "Josephine - PA 15750",
    "county": "Indiana County",
    "state": "PA",
    "city": "Josephine"
  },
  {
    "value": "15753",
    "label": "La Jose - PA 15753",
    "county": "Clearfield County",
    "state": "PA",
    "city": "La Jose"
  },
  {
    "value": "15757",
    "label": "Mahaffey - PA 15757",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Mahaffey"
  },
  {
    "value": "15758",
    "label": "Marchand - PA 15758",
    "county": "Indiana County",
    "state": "PA",
    "city": "Marchand"
  },
  {
    "value": "15759",
    "label": "Marion Center - PA 15759",
    "county": "Indiana County",
    "state": "PA",
    "city": "Marion Center"
  },
  {
    "value": "15760",
    "label": "Marsteller - PA 15760",
    "county": "Cambria County",
    "state": "PA",
    "city": "Marsteller"
  },
  {
    "value": "15761",
    "label": "Mentcle - PA 15761",
    "county": "Indiana County",
    "state": "PA",
    "city": "Mentcle"
  },
  {
    "value": "15762",
    "label": "Nicktown - PA 15762",
    "county": "Cambria County",
    "state": "PA",
    "city": "Nicktown"
  },
  {
    "value": "15763",
    "label": "Northpoint - PA 15763",
    "county": "Indiana County",
    "state": "PA",
    "city": "Northpoint"
  },
  {
    "value": "15764",
    "label": "Oliveburg - PA 15764",
    "county": "Jefferson County",
    "state": "PA",
    "city": "Oliveburg"
  },
  {
    "value": "15765",
    "label": "Penn Run - PA 15765",
    "county": "Indiana County",
    "state": "PA",
    "city": "Penn Run"
  },
  {
    "value": "15767",
    "label": "Punxsutawney - PA 15767",
    "county": "Jefferson County",
    "state": "PA",
    "city": "Punxsutawney"
  },
  {
    "value": "15770",
    "label": "Ringgold - PA 15770",
    "county": "Jefferson County",
    "state": "PA",
    "city": "Ringgold"
  },
  {
    "value": "15771",
    "label": "Rochester Mills - PA 15771",
    "county": "Indiana County",
    "state": "PA",
    "city": "Rochester Mills"
  },
  {
    "value": "15772",
    "label": "Rossiter - PA 15772",
    "county": "Indiana County",
    "state": "PA",
    "city": "Rossiter"
  },
  {
    "value": "15773",
    "label": "Saint Benedict - PA 15773",
    "county": "Cambria County",
    "state": "PA",
    "city": "Saint Benedict"
  },
  {
    "value": "15774",
    "label": "Shelocta - PA 15774",
    "county": "Indiana County",
    "state": "PA",
    "city": "Shelocta"
  },
  {
    "value": "15775",
    "label": "Spangler - PA 15775",
    "county": "Cambria County",
    "state": "PA",
    "city": "Spangler"
  },
  {
    "value": "15776",
    "label": "Sprankle Mills - PA 15776",
    "county": "Jefferson County",
    "state": "PA",
    "city": "Sprankle Mills"
  },
  {
    "value": "15777",
    "label": "Starford - PA 15777",
    "county": "Indiana County",
    "state": "PA",
    "city": "Starford"
  },
  {
    "value": "15778",
    "label": "Timblin - PA 15778",
    "county": "Jefferson County",
    "state": "PA",
    "city": "Timblin"
  },
  {
    "value": "15780",
    "label": "Valier - PA 15780",
    "county": "Jefferson County",
    "state": "PA",
    "city": "Valier"
  },
  {
    "value": "15784",
    "label": "Worthville - PA 15784",
    "county": "Jefferson County",
    "state": "PA",
    "city": "Worthville"
  },
  {
    "value": "15801",
    "label": "Du Bois - PA 15801",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Du Bois"
  },
  {
    "value": "15821",
    "label": "Benezett - PA 15821",
    "county": "Elk County",
    "state": "PA",
    "city": "Benezett"
  },
  {
    "value": "15822",
    "label": "Brandy Camp - PA 15822",
    "county": "Elk County",
    "state": "PA",
    "city": "Brandy Camp"
  },
  {
    "value": "15823",
    "label": "Brockport - PA 15823",
    "county": "Elk County",
    "state": "PA",
    "city": "Brockport"
  },
  {
    "value": "15824",
    "label": "Brockway - PA 15824",
    "county": "Jefferson County",
    "state": "PA",
    "city": "Brockway"
  },
  {
    "value": "15825",
    "label": "Brookville - PA 15825",
    "county": "Jefferson County",
    "state": "PA",
    "city": "Brookville"
  },
  {
    "value": "15827",
    "label": "Byrnedale - PA 15827",
    "county": "Elk County",
    "state": "PA",
    "city": "Byrnedale"
  },
  {
    "value": "15828",
    "label": "Clarington - PA 15828",
    "county": "Jefferson County",
    "state": "PA",
    "city": "Clarington"
  },
  {
    "value": "15829",
    "label": "Corsica - PA 15829",
    "county": "Jefferson County",
    "state": "PA",
    "city": "Corsica"
  },
  {
    "value": "15832",
    "label": "Driftwood - PA 15832",
    "county": "Cameron County",
    "state": "PA",
    "city": "Driftwood"
  },
  {
    "value": "15834",
    "label": "Emporium - PA 15834",
    "county": "Cameron County",
    "state": "PA",
    "city": "Emporium"
  },
  {
    "value": "15840",
    "label": "Falls Creek - PA 15840",
    "county": "Jefferson County",
    "state": "PA",
    "city": "Falls Creek"
  },
  {
    "value": "15845",
    "label": "Johnsonburg - PA 15845",
    "county": "Elk County",
    "state": "PA",
    "city": "Johnsonburg"
  },
  {
    "value": "15846",
    "label": "Kersey - PA 15846",
    "county": "Elk County",
    "state": "PA",
    "city": "Kersey"
  },
  {
    "value": "15848",
    "label": "Luthersburg - PA 15848",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Luthersburg"
  },
  {
    "value": "15849",
    "label": "Penfield - PA 15849",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Penfield"
  },
  {
    "value": "15851",
    "label": "Reynoldsville - PA 15851",
    "county": "Jefferson County",
    "state": "PA",
    "city": "Reynoldsville"
  },
  {
    "value": "15853",
    "label": "Ridgway - PA 15853",
    "county": "Elk County",
    "state": "PA",
    "city": "Ridgway"
  },
  {
    "value": "15856",
    "label": "Rockton - PA 15856",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Rockton"
  },
  {
    "value": "15857",
    "label": "Saint Marys - PA 15857",
    "county": "Elk County",
    "state": "PA",
    "city": "Saint Marys"
  },
  {
    "value": "15860",
    "label": "Sigel - PA 15860",
    "county": "Jefferson County",
    "state": "PA",
    "city": "Sigel"
  },
  {
    "value": "15861",
    "label": "Sinnamahoning - PA 15861",
    "county": "Cameron County",
    "state": "PA",
    "city": "Sinnamahoning"
  },
  {
    "value": "15864",
    "label": "Summerville - PA 15864",
    "county": "Jefferson County",
    "state": "PA",
    "city": "Summerville"
  },
  {
    "value": "15865",
    "label": "Sykesville - PA 15865",
    "county": "Jefferson County",
    "state": "PA",
    "city": "Sykesville"
  },
  {
    "value": "15866",
    "label": "Troutville - PA 15866",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Troutville"
  },
  {
    "value": "15868",
    "label": "Weedville - PA 15868",
    "county": "Elk County",
    "state": "PA",
    "city": "Weedville"
  },
  {
    "value": "15870",
    "label": "Wilcox - PA 15870",
    "county": "Elk County",
    "state": "PA",
    "city": "Wilcox"
  },
  {
    "value": "15901",
    "label": "Johnstown - PA 15901",
    "county": "Cambria County",
    "state": "PA",
    "city": "Johnstown"
  },
  {
    "value": "15902",
    "label": "Johnstown - PA 15902",
    "county": "Cambria County",
    "state": "PA",
    "city": "Johnstown"
  },
  {
    "value": "15904",
    "label": "Johnstown - PA 15904",
    "county": "Cambria County",
    "state": "PA",
    "city": "Johnstown"
  },
  {
    "value": "15905",
    "label": "Johnstown - PA 15905",
    "county": "Cambria County",
    "state": "PA",
    "city": "Johnstown"
  },
  {
    "value": "15906",
    "label": "Johnstown - PA 15906",
    "county": "Cambria County",
    "state": "PA",
    "city": "Johnstown"
  },
  {
    "value": "15909",
    "label": "Johnstown - PA 15909",
    "county": "Cambria County",
    "state": "PA",
    "city": "Johnstown"
  },
  {
    "value": "15915",
    "label": "Johnstown - PA 15915",
    "county": "Cambria County",
    "state": "PA",
    "city": "Johnstown"
  },
  {
    "value": "15920",
    "label": "Armagh - PA 15920",
    "county": "Indiana County",
    "state": "PA",
    "city": "Armagh"
  },
  {
    "value": "15923",
    "label": "Bolivar - PA 15923",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "Bolivar"
  },
  {
    "value": "15924",
    "label": "Cairnbrook - PA 15924",
    "county": "Somerset County",
    "state": "PA",
    "city": "Cairnbrook"
  },
  {
    "value": "15926",
    "label": "Central City - PA 15926",
    "county": "Somerset County",
    "state": "PA",
    "city": "Central City"
  },
  {
    "value": "15927",
    "label": "Colver - PA 15927",
    "county": "Cambria County",
    "state": "PA",
    "city": "Colver"
  },
  {
    "value": "15928",
    "label": "Davidsville - PA 15928",
    "county": "Somerset County",
    "state": "PA",
    "city": "Davidsville"
  },
  {
    "value": "15931",
    "label": "Ebensburg - PA 15931",
    "county": "Cambria County",
    "state": "PA",
    "city": "Ebensburg"
  },
  {
    "value": "15935",
    "label": "Hollsopple - PA 15935",
    "county": "Somerset County",
    "state": "PA",
    "city": "Hollsopple"
  },
  {
    "value": "15936",
    "label": "Hooversville - PA 15936",
    "county": "Somerset County",
    "state": "PA",
    "city": "Hooversville"
  },
  {
    "value": "15938",
    "label": "Lilly - PA 15938",
    "county": "Cambria County",
    "state": "PA",
    "city": "Lilly"
  },
  {
    "value": "15940",
    "label": "Loretto - PA 15940",
    "county": "Cambria County",
    "state": "PA",
    "city": "Loretto"
  },
  {
    "value": "15942",
    "label": "Mineral Point - PA 15942",
    "county": "Cambria County",
    "state": "PA",
    "city": "Mineral Point"
  },
  {
    "value": "15943",
    "label": "Nanty Glo - PA 15943",
    "county": "Cambria County",
    "state": "PA",
    "city": "Nanty Glo"
  },
  {
    "value": "15944",
    "label": "New Florence - PA 15944",
    "county": "Westmoreland County",
    "state": "PA",
    "city": "New Florence"
  },
  {
    "value": "15945",
    "label": "Parkhill - PA 15945",
    "county": "Cambria County",
    "state": "PA",
    "city": "Parkhill"
  },
  {
    "value": "15946",
    "label": "Portage - PA 15946",
    "county": "Cambria County",
    "state": "PA",
    "city": "Portage"
  },
  {
    "value": "15949",
    "label": "Robinson - PA 15949",
    "county": "Indiana County",
    "state": "PA",
    "city": "Robinson"
  },
  {
    "value": "15952",
    "label": "Salix - PA 15952",
    "county": "Cambria County",
    "state": "PA",
    "city": "Salix"
  },
  {
    "value": "15953",
    "label": "Seanor - PA 15953",
    "county": "Somerset County",
    "state": "PA",
    "city": "Seanor"
  },
  {
    "value": "15954",
    "label": "Seward - PA 15954",
    "county": "Indiana County",
    "state": "PA",
    "city": "Seward"
  },
  {
    "value": "15955",
    "label": "Sidman - PA 15955",
    "county": "Cambria County",
    "state": "PA",
    "city": "Sidman"
  },
  {
    "value": "15956",
    "label": "South Fork - PA 15956",
    "county": "Cambria County",
    "state": "PA",
    "city": "South Fork"
  },
  {
    "value": "15957",
    "label": "Strongstown - PA 15957",
    "county": "Indiana County",
    "state": "PA",
    "city": "Strongstown"
  },
  {
    "value": "15958",
    "label": "Summerhill - PA 15958",
    "county": "Cambria County",
    "state": "PA",
    "city": "Summerhill"
  },
  {
    "value": "15961",
    "label": "Vintondale - PA 15961",
    "county": "Indiana County",
    "state": "PA",
    "city": "Vintondale"
  },
  {
    "value": "15963",
    "label": "Windber - PA 15963",
    "county": "Somerset County",
    "state": "PA",
    "city": "Windber"
  },
  {
    "value": "16001",
    "label": "Butler - PA 16001",
    "county": "Butler County",
    "state": "PA",
    "city": "Butler"
  },
  {
    "value": "16002",
    "label": "Butler - PA 16002",
    "county": "Butler County",
    "state": "PA",
    "city": "Butler"
  },
  {
    "value": "16020",
    "label": "Boyers - PA 16020",
    "county": "Butler County",
    "state": "PA",
    "city": "Boyers"
  },
  {
    "value": "16021",
    "label": "Branchton - PA 16021",
    "county": "Butler County",
    "state": "PA",
    "city": "Branchton"
  },
  {
    "value": "16023",
    "label": "Cabot - PA 16023",
    "county": "Butler County",
    "state": "PA",
    "city": "Cabot"
  },
  {
    "value": "16025",
    "label": "Chicora - PA 16025",
    "county": "Butler County",
    "state": "PA",
    "city": "Chicora"
  },
  {
    "value": "16028",
    "label": "East Brady - PA 16028",
    "county": "Clarion County",
    "state": "PA",
    "city": "East Brady"
  },
  {
    "value": "16033",
    "label": "Evans City - PA 16033",
    "county": "Butler County",
    "state": "PA",
    "city": "Evans City"
  },
  {
    "value": "16034",
    "label": "Fenelton - PA 16034",
    "county": "Butler County",
    "state": "PA",
    "city": "Fenelton"
  },
  {
    "value": "16037",
    "label": "Harmony - PA 16037",
    "county": "Butler County",
    "state": "PA",
    "city": "Harmony"
  },
  {
    "value": "16038",
    "label": "Harrisville - PA 16038",
    "county": "Butler County",
    "state": "PA",
    "city": "Harrisville"
  },
  {
    "value": "16040",
    "label": "Hilliards - PA 16040",
    "county": "Butler County",
    "state": "PA",
    "city": "Hilliards"
  },
  {
    "value": "16041",
    "label": "Karns City - PA 16041",
    "county": "Butler County",
    "state": "PA",
    "city": "Karns City"
  },
  {
    "value": "16045",
    "label": "Lyndora - PA 16045",
    "county": "Butler County",
    "state": "PA",
    "city": "Lyndora"
  },
  {
    "value": "16046",
    "label": "Mars - PA 16046",
    "county": "Butler County",
    "state": "PA",
    "city": "Mars"
  },
  {
    "value": "16049",
    "label": "Parker - PA 16049",
    "county": "Clarion County",
    "state": "PA",
    "city": "Parker"
  },
  {
    "value": "16050",
    "label": "Petrolia - PA 16050",
    "county": "Butler County",
    "state": "PA",
    "city": "Petrolia"
  },
  {
    "value": "16051",
    "label": "Portersville - PA 16051",
    "county": "Butler County",
    "state": "PA",
    "city": "Portersville"
  },
  {
    "value": "16052",
    "label": "Prospect - PA 16052",
    "county": "Butler County",
    "state": "PA",
    "city": "Prospect"
  },
  {
    "value": "16053",
    "label": "Renfrew - PA 16053",
    "county": "Butler County",
    "state": "PA",
    "city": "Renfrew"
  },
  {
    "value": "16055",
    "label": "Sarver - PA 16055",
    "county": "Butler County",
    "state": "PA",
    "city": "Sarver"
  },
  {
    "value": "16056",
    "label": "Saxonburg - PA 16056",
    "county": "Butler County",
    "state": "PA",
    "city": "Saxonburg"
  },
  {
    "value": "16057",
    "label": "Slippery Rock - PA 16057",
    "county": "Butler County",
    "state": "PA",
    "city": "Slippery Rock"
  },
  {
    "value": "16059",
    "label": "Valencia - PA 16059",
    "county": "Butler County",
    "state": "PA",
    "city": "Valencia"
  },
  {
    "value": "16061",
    "label": "West Sunbury - PA 16061",
    "county": "Butler County",
    "state": "PA",
    "city": "West Sunbury"
  },
  {
    "value": "16063",
    "label": "Zelienople - PA 16063",
    "county": "Butler County",
    "state": "PA",
    "city": "Zelienople"
  },
  {
    "value": "16066",
    "label": "Cranberry Township - PA 16066",
    "county": "Butler County",
    "state": "PA",
    "city": "Cranberry Township"
  },
  {
    "value": "16101",
    "label": "New Castle - PA 16101",
    "county": "Lawrence County",
    "state": "PA",
    "city": "New Castle"
  },
  {
    "value": "16102",
    "label": "New Castle - PA 16102",
    "county": "Lawrence County",
    "state": "PA",
    "city": "New Castle"
  },
  {
    "value": "16105",
    "label": "New Castle - PA 16105",
    "county": "Lawrence County",
    "state": "PA",
    "city": "New Castle"
  },
  {
    "value": "16108",
    "label": "New Castle - PA 16108",
    "county": "Lawrence County",
    "state": "PA",
    "city": "New Castle"
  },
  {
    "value": "16110",
    "label": "Adamsville - PA 16110",
    "county": "Crawford County",
    "state": "PA",
    "city": "Adamsville"
  },
  {
    "value": "16111",
    "label": "Atlantic - PA 16111",
    "county": "Crawford County",
    "state": "PA",
    "city": "Atlantic"
  },
  {
    "value": "16112",
    "label": "Bessemer - PA 16112",
    "county": "Lawrence County",
    "state": "PA",
    "city": "Bessemer"
  },
  {
    "value": "16114",
    "label": "Clarks Mills - PA 16114",
    "county": "Mercer County",
    "state": "PA",
    "city": "Clarks Mills"
  },
  {
    "value": "16115",
    "label": "Darlington - PA 16115",
    "county": "Beaver County",
    "state": "PA",
    "city": "Darlington"
  },
  {
    "value": "16116",
    "label": "Edinburg - PA 16116",
    "county": "Lawrence County",
    "state": "PA",
    "city": "Edinburg"
  },
  {
    "value": "16117",
    "label": "Ellwood City - PA 16117",
    "county": "Lawrence County",
    "state": "PA",
    "city": "Ellwood City"
  },
  {
    "value": "16120",
    "label": "Enon Valley - PA 16120",
    "county": "Lawrence County",
    "state": "PA",
    "city": "Enon Valley"
  },
  {
    "value": "16121",
    "label": "Farrell - PA 16121",
    "county": "Mercer County",
    "state": "PA",
    "city": "Farrell"
  },
  {
    "value": "16123",
    "label": "Fombell - PA 16123",
    "county": "Beaver County",
    "state": "PA",
    "city": "Fombell"
  },
  {
    "value": "16124",
    "label": "Fredonia - PA 16124",
    "county": "Mercer County",
    "state": "PA",
    "city": "Fredonia"
  },
  {
    "value": "16125",
    "label": "Greenville - PA 16125",
    "county": "Mercer County",
    "state": "PA",
    "city": "Greenville"
  },
  {
    "value": "16127",
    "label": "Grove City - PA 16127",
    "county": "Mercer County",
    "state": "PA",
    "city": "Grove City"
  },
  {
    "value": "16130",
    "label": "Hadley - PA 16130",
    "county": "Mercer County",
    "state": "PA",
    "city": "Hadley"
  },
  {
    "value": "16131",
    "label": "Hartstown - PA 16131",
    "county": "Crawford County",
    "state": "PA",
    "city": "Hartstown"
  },
  {
    "value": "16133",
    "label": "Jackson Center - PA 16133",
    "county": "Mercer County",
    "state": "PA",
    "city": "Jackson Center"
  },
  {
    "value": "16134",
    "label": "Jamestown - PA 16134",
    "county": "Crawford County",
    "state": "PA",
    "city": "Jamestown"
  },
  {
    "value": "16137",
    "label": "Mercer - PA 16137",
    "county": "Mercer County",
    "state": "PA",
    "city": "Mercer"
  },
  {
    "value": "16141",
    "label": "New Galilee - PA 16141",
    "county": "Lawrence County",
    "state": "PA",
    "city": "New Galilee"
  },
  {
    "value": "16142",
    "label": "New Wilmington - PA 16142",
    "county": "Lawrence County",
    "state": "PA",
    "city": "New Wilmington"
  },
  {
    "value": "16143",
    "label": "Pulaski - PA 16143",
    "county": "Lawrence County",
    "state": "PA",
    "city": "Pulaski"
  },
  {
    "value": "16145",
    "label": "Sandy Lake - PA 16145",
    "county": "Mercer County",
    "state": "PA",
    "city": "Sandy Lake"
  },
  {
    "value": "16146",
    "label": "Sharon - PA 16146",
    "county": "Mercer County",
    "state": "PA",
    "city": "Sharon"
  },
  {
    "value": "16148",
    "label": "Hermitage - PA 16148",
    "county": "Mercer County",
    "state": "PA",
    "city": "Hermitage"
  },
  {
    "value": "16150",
    "label": "Sharpsville - PA 16150",
    "county": "Mercer County",
    "state": "PA",
    "city": "Sharpsville"
  },
  {
    "value": "16153",
    "label": "Stoneboro - PA 16153",
    "county": "Mercer County",
    "state": "PA",
    "city": "Stoneboro"
  },
  {
    "value": "16154",
    "label": "Transfer - PA 16154",
    "county": "Mercer County",
    "state": "PA",
    "city": "Transfer"
  },
  {
    "value": "16156",
    "label": "Volant - PA 16156",
    "county": "Lawrence County",
    "state": "PA",
    "city": "Volant"
  },
  {
    "value": "16157",
    "label": "Wampum - PA 16157",
    "county": "Lawrence County",
    "state": "PA",
    "city": "Wampum"
  },
  {
    "value": "16159",
    "label": "West Middlesex - PA 16159",
    "county": "Mercer County",
    "state": "PA",
    "city": "West Middlesex"
  },
  {
    "value": "16201",
    "label": "Kittanning - PA 16201",
    "county": "Armstrong County",
    "state": "PA",
    "city": "Kittanning"
  },
  {
    "value": "16210",
    "label": "Adrian - PA 16210",
    "county": "Armstrong County",
    "state": "PA",
    "city": "Adrian"
  },
  {
    "value": "16214",
    "label": "Clarion - PA 16214",
    "county": "Clarion County",
    "state": "PA",
    "city": "Clarion"
  },
  {
    "value": "16217",
    "label": "Cooksburg - PA 16217",
    "county": "Forest County",
    "state": "PA",
    "city": "Cooksburg"
  },
  {
    "value": "16218",
    "label": "Cowansville - PA 16218",
    "county": "Armstrong County",
    "state": "PA",
    "city": "Cowansville"
  },
  {
    "value": "16222",
    "label": "Dayton - PA 16222",
    "county": "Armstrong County",
    "state": "PA",
    "city": "Dayton"
  },
  {
    "value": "16224",
    "label": "Fairmount City - PA 16224",
    "county": "Clarion County",
    "state": "PA",
    "city": "Fairmount City"
  },
  {
    "value": "16226",
    "label": "Ford City - PA 16226",
    "county": "Armstrong County",
    "state": "PA",
    "city": "Ford City"
  },
  {
    "value": "16228",
    "label": "Ford Cliff - PA 16228",
    "county": "Armstrong County",
    "state": "PA",
    "city": "Ford Cliff"
  },
  {
    "value": "16229",
    "label": "Freeport - PA 16229",
    "county": "Armstrong County",
    "state": "PA",
    "city": "Freeport"
  },
  {
    "value": "16232",
    "label": "Knox - PA 16232",
    "county": "Clarion County",
    "state": "PA",
    "city": "Knox"
  },
  {
    "value": "16233",
    "label": "Leeper - PA 16233",
    "county": "Clarion County",
    "state": "PA",
    "city": "Leeper"
  },
  {
    "value": "16235",
    "label": "Lucinda - PA 16235",
    "county": "Clarion County",
    "state": "PA",
    "city": "Lucinda"
  },
  {
    "value": "16239",
    "label": "Marienville - PA 16239",
    "county": "Forest County",
    "state": "PA",
    "city": "Marienville"
  },
  {
    "value": "16240",
    "label": "Mayport - PA 16240",
    "county": "Clarion County",
    "state": "PA",
    "city": "Mayport"
  },
  {
    "value": "16242",
    "label": "New Bethlehem - PA 16242",
    "county": "Clarion County",
    "state": "PA",
    "city": "New Bethlehem"
  },
  {
    "value": "16248",
    "label": "Rimersburg - PA 16248",
    "county": "Clarion County",
    "state": "PA",
    "city": "Rimersburg"
  },
  {
    "value": "16249",
    "label": "Rural Valley - PA 16249",
    "county": "Armstrong County",
    "state": "PA",
    "city": "Rural Valley"
  },
  {
    "value": "16253",
    "label": "Seminole - PA 16253",
    "county": "Armstrong County",
    "state": "PA",
    "city": "Seminole"
  },
  {
    "value": "16254",
    "label": "Shippenville - PA 16254",
    "county": "Clarion County",
    "state": "PA",
    "city": "Shippenville"
  },
  {
    "value": "16255",
    "label": "Sligo - PA 16255",
    "county": "Clarion County",
    "state": "PA",
    "city": "Sligo"
  },
  {
    "value": "16256",
    "label": "Smicksburg - PA 16256",
    "county": "Indiana County",
    "state": "PA",
    "city": "Smicksburg"
  },
  {
    "value": "16257",
    "label": "Snydersburg - PA 16257",
    "county": "Clarion County",
    "state": "PA",
    "city": "Snydersburg"
  },
  {
    "value": "16258",
    "label": "Strattanville - PA 16258",
    "county": "Clarion County",
    "state": "PA",
    "city": "Strattanville"
  },
  {
    "value": "16259",
    "label": "Templeton - PA 16259",
    "county": "Armstrong County",
    "state": "PA",
    "city": "Templeton"
  },
  {
    "value": "16260",
    "label": "Vowinckel - PA 16260",
    "county": "Clarion County",
    "state": "PA",
    "city": "Vowinckel"
  },
  {
    "value": "16261",
    "label": "Widnoon - PA 16261",
    "county": "Armstrong County",
    "state": "PA",
    "city": "Widnoon"
  },
  {
    "value": "16262",
    "label": "Worthington - PA 16262",
    "county": "Armstrong County",
    "state": "PA",
    "city": "Worthington"
  },
  {
    "value": "16301",
    "label": "Oil City - PA 16301",
    "county": "Venango County",
    "state": "PA",
    "city": "Oil City"
  },
  {
    "value": "16311",
    "label": "Carlton - PA 16311",
    "county": "Mercer County",
    "state": "PA",
    "city": "Carlton"
  },
  {
    "value": "16313",
    "label": "Clarendon - PA 16313",
    "county": "Warren County",
    "state": "PA",
    "city": "Clarendon"
  },
  {
    "value": "16314",
    "label": "Cochranton - PA 16314",
    "county": "Crawford County",
    "state": "PA",
    "city": "Cochranton"
  },
  {
    "value": "16316",
    "label": "Conneaut Lake - PA 16316",
    "county": "Crawford County",
    "state": "PA",
    "city": "Conneaut Lake"
  },
  {
    "value": "16317",
    "label": "Cooperstown - PA 16317",
    "county": "Venango County",
    "state": "PA",
    "city": "Cooperstown"
  },
  {
    "value": "16319",
    "label": "Cranberry - PA 16319",
    "county": "Venango County",
    "state": "PA",
    "city": "Cranberry"
  },
  {
    "value": "16323",
    "label": "Franklin - PA 16323",
    "county": "Venango County",
    "state": "PA",
    "city": "Franklin"
  },
  {
    "value": "16326",
    "label": "Fryburg - PA 16326",
    "county": "Clarion County",
    "state": "PA",
    "city": "Fryburg"
  },
  {
    "value": "16327",
    "label": "Guys Mills - PA 16327",
    "county": "Crawford County",
    "state": "PA",
    "city": "Guys Mills"
  },
  {
    "value": "16329",
    "label": "Irvine - PA 16329",
    "county": "Warren County",
    "state": "PA",
    "city": "Irvine"
  },
  {
    "value": "16331",
    "label": "Kossuth - PA 16331",
    "county": "Clarion County",
    "state": "PA",
    "city": "Kossuth"
  },
  {
    "value": "16332",
    "label": "Lickingville - PA 16332",
    "county": "Clarion County",
    "state": "PA",
    "city": "Lickingville"
  },
  {
    "value": "16334",
    "label": "Marble - PA 16334",
    "county": "Clarion County",
    "state": "PA",
    "city": "Marble"
  },
  {
    "value": "16335",
    "label": "Meadville - PA 16335",
    "county": "Crawford County",
    "state": "PA",
    "city": "Meadville"
  },
  {
    "value": "16340",
    "label": "Pittsfield - PA 16340",
    "county": "Warren County",
    "state": "PA",
    "city": "Pittsfield"
  },
  {
    "value": "16341",
    "label": "Pleasantville - PA 16341",
    "county": "Venango County",
    "state": "PA",
    "city": "Pleasantville"
  },
  {
    "value": "16342",
    "label": "Polk - PA 16342",
    "county": "Venango County",
    "state": "PA",
    "city": "Polk"
  },
  {
    "value": "16345",
    "label": "Russell - PA 16345",
    "county": "Warren County",
    "state": "PA",
    "city": "Russell"
  },
  {
    "value": "16346",
    "label": "Seneca - PA 16346",
    "county": "Venango County",
    "state": "PA",
    "city": "Seneca"
  },
  {
    "value": "16347",
    "label": "Sheffield - PA 16347",
    "county": "Warren County",
    "state": "PA",
    "city": "Sheffield"
  },
  {
    "value": "16350",
    "label": "Sugar Grove - PA 16350",
    "county": "Warren County",
    "state": "PA",
    "city": "Sugar Grove"
  },
  {
    "value": "16351",
    "label": "Tidioute - PA 16351",
    "county": "Warren County",
    "state": "PA",
    "city": "Tidioute"
  },
  {
    "value": "16353",
    "label": "Tionesta - PA 16353",
    "county": "Forest County",
    "state": "PA",
    "city": "Tionesta"
  },
  {
    "value": "16354",
    "label": "Titusville - PA 16354",
    "county": "Crawford County",
    "state": "PA",
    "city": "Titusville"
  },
  {
    "value": "16360",
    "label": "Townville - PA 16360",
    "county": "Crawford County",
    "state": "PA",
    "city": "Townville"
  },
  {
    "value": "16362",
    "label": "Utica - PA 16362",
    "county": "Venango County",
    "state": "PA",
    "city": "Utica"
  },
  {
    "value": "16364",
    "label": "Venus - PA 16364",
    "county": "Venango County",
    "state": "PA",
    "city": "Venus"
  },
  {
    "value": "16365",
    "label": "Warren - PA 16365",
    "county": "Warren County",
    "state": "PA",
    "city": "Warren"
  },
  {
    "value": "16371",
    "label": "Youngsville - PA 16371",
    "county": "Warren County",
    "state": "PA",
    "city": "Youngsville"
  },
  {
    "value": "16373",
    "label": "Emlenton - PA 16373",
    "county": "Venango County",
    "state": "PA",
    "city": "Emlenton"
  },
  {
    "value": "16374",
    "label": "Kennerdell - PA 16374",
    "county": "Venango County",
    "state": "PA",
    "city": "Kennerdell"
  },
  {
    "value": "16401",
    "label": "Albion - PA 16401",
    "county": "Erie County",
    "state": "PA",
    "city": "Albion"
  },
  {
    "value": "16402",
    "label": "Bear Lake - PA 16402",
    "county": "Warren County",
    "state": "PA",
    "city": "Bear Lake"
  },
  {
    "value": "16403",
    "label": "Cambridge Springs - PA 16403",
    "county": "Crawford County",
    "state": "PA",
    "city": "Cambridge Springs"
  },
  {
    "value": "16404",
    "label": "Centerville - PA 16404",
    "county": "Crawford County",
    "state": "PA",
    "city": "Centerville"
  },
  {
    "value": "16405",
    "label": "Columbus - PA 16405",
    "county": "Warren County",
    "state": "PA",
    "city": "Columbus"
  },
  {
    "value": "16406",
    "label": "Conneautville - PA 16406",
    "county": "Crawford County",
    "state": "PA",
    "city": "Conneautville"
  },
  {
    "value": "16407",
    "label": "Corry - PA 16407",
    "county": "Erie County",
    "state": "PA",
    "city": "Corry"
  },
  {
    "value": "16410",
    "label": "Cranesville - PA 16410",
    "county": "Erie County",
    "state": "PA",
    "city": "Cranesville"
  },
  {
    "value": "16411",
    "label": "East Springfield - PA 16411",
    "county": "Erie County",
    "state": "PA",
    "city": "East Springfield"
  },
  {
    "value": "16412",
    "label": "Edinboro - PA 16412",
    "county": "Erie County",
    "state": "PA",
    "city": "Edinboro"
  },
  {
    "value": "16415",
    "label": "Fairview - PA 16415",
    "county": "Erie County",
    "state": "PA",
    "city": "Fairview"
  },
  {
    "value": "16417",
    "label": "Girard - PA 16417",
    "county": "Erie County",
    "state": "PA",
    "city": "Girard"
  },
  {
    "value": "16420",
    "label": "Grand Valley - PA 16420",
    "county": "Warren County",
    "state": "PA",
    "city": "Grand Valley"
  },
  {
    "value": "16421",
    "label": "Harborcreek - PA 16421",
    "county": "Erie County",
    "state": "PA",
    "city": "Harborcreek"
  },
  {
    "value": "16423",
    "label": "Lake City - PA 16423",
    "county": "Erie County",
    "state": "PA",
    "city": "Lake City"
  },
  {
    "value": "16424",
    "label": "Linesville - PA 16424",
    "county": "Crawford County",
    "state": "PA",
    "city": "Linesville"
  },
  {
    "value": "16426",
    "label": "Mc Kean - PA 16426",
    "county": "Erie County",
    "state": "PA",
    "city": "Mc Kean"
  },
  {
    "value": "16428",
    "label": "North East - PA 16428",
    "county": "Erie County",
    "state": "PA",
    "city": "North East"
  },
  {
    "value": "16430",
    "label": "North Springfield - PA 16430",
    "county": "Erie County",
    "state": "PA",
    "city": "North Springfield"
  },
  {
    "value": "16432",
    "label": "Riceville - PA 16432",
    "county": "Crawford County",
    "state": "PA",
    "city": "Riceville"
  },
  {
    "value": "16433",
    "label": "Saegertown - PA 16433",
    "county": "Crawford County",
    "state": "PA",
    "city": "Saegertown"
  },
  {
    "value": "16434",
    "label": "Spartansburg - PA 16434",
    "county": "Crawford County",
    "state": "PA",
    "city": "Spartansburg"
  },
  {
    "value": "16435",
    "label": "Springboro - PA 16435",
    "county": "Crawford County",
    "state": "PA",
    "city": "Springboro"
  },
  {
    "value": "16436",
    "label": "Spring Creek - PA 16436",
    "county": "Warren County",
    "state": "PA",
    "city": "Spring Creek"
  },
  {
    "value": "16438",
    "label": "Union City - PA 16438",
    "county": "Erie County",
    "state": "PA",
    "city": "Union City"
  },
  {
    "value": "16440",
    "label": "Venango - PA 16440",
    "county": "Crawford County",
    "state": "PA",
    "city": "Venango"
  },
  {
    "value": "16441",
    "label": "Waterford - PA 16441",
    "county": "Erie County",
    "state": "PA",
    "city": "Waterford"
  },
  {
    "value": "16442",
    "label": "Wattsburg - PA 16442",
    "county": "Erie County",
    "state": "PA",
    "city": "Wattsburg"
  },
  {
    "value": "16443",
    "label": "West Springfield - PA 16443",
    "county": "Erie County",
    "state": "PA",
    "city": "West Springfield"
  },
  {
    "value": "16501",
    "label": "Erie - PA 16501",
    "county": "Erie County",
    "state": "PA",
    "city": "Erie"
  },
  {
    "value": "16502",
    "label": "Erie - PA 16502",
    "county": "Erie County",
    "state": "PA",
    "city": "Erie"
  },
  {
    "value": "16503",
    "label": "Erie - PA 16503",
    "county": "Erie County",
    "state": "PA",
    "city": "Erie"
  },
  {
    "value": "16504",
    "label": "Erie - PA 16504",
    "county": "Erie County",
    "state": "PA",
    "city": "Erie"
  },
  {
    "value": "16505",
    "label": "Erie - PA 16505",
    "county": "Erie County",
    "state": "PA",
    "city": "Erie"
  },
  {
    "value": "16506",
    "label": "Erie - PA 16506",
    "county": "Erie County",
    "state": "PA",
    "city": "Erie"
  },
  {
    "value": "16507",
    "label": "Erie - PA 16507",
    "county": "Erie County",
    "state": "PA",
    "city": "Erie"
  },
  {
    "value": "16508",
    "label": "Erie - PA 16508",
    "county": "Erie County",
    "state": "PA",
    "city": "Erie"
  },
  {
    "value": "16509",
    "label": "Erie - PA 16509",
    "county": "Erie County",
    "state": "PA",
    "city": "Erie"
  },
  {
    "value": "16510",
    "label": "Erie - PA 16510",
    "county": "Erie County",
    "state": "PA",
    "city": "Erie"
  },
  {
    "value": "16511",
    "label": "Erie - PA 16511",
    "county": "Erie County",
    "state": "PA",
    "city": "Erie"
  },
  {
    "value": "16515",
    "label": "Erie - PA 16515",
    "county": "Erie County",
    "state": "PA",
    "city": "Erie"
  },
  {
    "value": "16565",
    "label": "Erie - PA 16565",
    "county": "Erie County",
    "state": "PA",
    "city": "Erie"
  },
  {
    "value": "16601",
    "label": "Altoona - PA 16601",
    "county": "Blair County",
    "state": "PA",
    "city": "Altoona"
  },
  {
    "value": "16602",
    "label": "Altoona - PA 16602",
    "county": "Blair County",
    "state": "PA",
    "city": "Altoona"
  },
  {
    "value": "16611",
    "label": "Alexandria - PA 16611",
    "county": "Huntingdon County",
    "state": "PA",
    "city": "Alexandria"
  },
  {
    "value": "16613",
    "label": "Ashville - PA 16613",
    "county": "Cambria County",
    "state": "PA",
    "city": "Ashville"
  },
  {
    "value": "16616",
    "label": "Beccaria - PA 16616",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Beccaria"
  },
  {
    "value": "16617",
    "label": "Bellwood - PA 16617",
    "county": "Blair County",
    "state": "PA",
    "city": "Bellwood"
  },
  {
    "value": "16621",
    "label": "Broad Top - PA 16621",
    "county": "Huntingdon County",
    "state": "PA",
    "city": "Broad Top"
  },
  {
    "value": "16622",
    "label": "Calvin - PA 16622",
    "county": "Huntingdon County",
    "state": "PA",
    "city": "Calvin"
  },
  {
    "value": "16623",
    "label": "Cassville - PA 16623",
    "county": "Huntingdon County",
    "state": "PA",
    "city": "Cassville"
  },
  {
    "value": "16625",
    "label": "Claysburg - PA 16625",
    "county": "Blair County",
    "state": "PA",
    "city": "Claysburg"
  },
  {
    "value": "16627",
    "label": "Coalport - PA 16627",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Coalport"
  },
  {
    "value": "16629",
    "label": "Coupon - PA 16629",
    "county": "Cambria County",
    "state": "PA",
    "city": "Coupon"
  },
  {
    "value": "16630",
    "label": "Cresson - PA 16630",
    "county": "Cambria County",
    "state": "PA",
    "city": "Cresson"
  },
  {
    "value": "16635",
    "label": "Duncansville - PA 16635",
    "county": "Blair County",
    "state": "PA",
    "city": "Duncansville"
  },
  {
    "value": "16636",
    "label": "Dysart - PA 16636",
    "county": "Cambria County",
    "state": "PA",
    "city": "Dysart"
  },
  {
    "value": "16637",
    "label": "East Freedom - PA 16637",
    "county": "Blair County",
    "state": "PA",
    "city": "East Freedom"
  },
  {
    "value": "16638",
    "label": "Entriken - PA 16638",
    "county": "Huntingdon County",
    "state": "PA",
    "city": "Entriken"
  },
  {
    "value": "16639",
    "label": "Fallentimber - PA 16639",
    "county": "Cambria County",
    "state": "PA",
    "city": "Fallentimber"
  },
  {
    "value": "16640",
    "label": "Flinton - PA 16640",
    "county": "Cambria County",
    "state": "PA",
    "city": "Flinton"
  },
  {
    "value": "16641",
    "label": "Gallitzin - PA 16641",
    "county": "Cambria County",
    "state": "PA",
    "city": "Gallitzin"
  },
  {
    "value": "16645",
    "label": "Glen Hope - PA 16645",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Glen Hope"
  },
  {
    "value": "16646",
    "label": "Hastings - PA 16646",
    "county": "Cambria County",
    "state": "PA",
    "city": "Hastings"
  },
  {
    "value": "16647",
    "label": "Hesston - PA 16647",
    "county": "Huntingdon County",
    "state": "PA",
    "city": "Hesston"
  },
  {
    "value": "16648",
    "label": "Hollidaysburg - PA 16648",
    "county": "Blair County",
    "state": "PA",
    "city": "Hollidaysburg"
  },
  {
    "value": "16650",
    "label": "Hopewell - PA 16650",
    "county": "Bedford County",
    "state": "PA",
    "city": "Hopewell"
  },
  {
    "value": "16651",
    "label": "Houtzdale - PA 16651",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Houtzdale"
  },
  {
    "value": "16652",
    "label": "Huntingdon - PA 16652",
    "county": "Huntingdon County",
    "state": "PA",
    "city": "Huntingdon"
  },
  {
    "value": "16655",
    "label": "Imler - PA 16655",
    "county": "Bedford County",
    "state": "PA",
    "city": "Imler"
  },
  {
    "value": "16656",
    "label": "Irvona - PA 16656",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Irvona"
  },
  {
    "value": "16657",
    "label": "James Creek - PA 16657",
    "county": "Huntingdon County",
    "state": "PA",
    "city": "James Creek"
  },
  {
    "value": "16659",
    "label": "Loysburg - PA 16659",
    "county": "Bedford County",
    "state": "PA",
    "city": "Loysburg"
  },
  {
    "value": "16661",
    "label": "Madera - PA 16661",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Madera"
  },
  {
    "value": "16662",
    "label": "Martinsburg - PA 16662",
    "county": "Blair County",
    "state": "PA",
    "city": "Martinsburg"
  },
  {
    "value": "16664",
    "label": "New Enterprise - PA 16664",
    "county": "Bedford County",
    "state": "PA",
    "city": "New Enterprise"
  },
  {
    "value": "16666",
    "label": "Osceola Mills - PA 16666",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Osceola Mills"
  },
  {
    "value": "16667",
    "label": "Osterburg - PA 16667",
    "county": "Bedford County",
    "state": "PA",
    "city": "Osterburg"
  },
  {
    "value": "16668",
    "label": "Patton - PA 16668",
    "county": "Cambria County",
    "state": "PA",
    "city": "Patton"
  },
  {
    "value": "16669",
    "label": "Petersburg - PA 16669",
    "county": "Huntingdon County",
    "state": "PA",
    "city": "Petersburg"
  },
  {
    "value": "16670",
    "label": "Queen - PA 16670",
    "county": "Bedford County",
    "state": "PA",
    "city": "Queen"
  },
  {
    "value": "16673",
    "label": "Roaring Spring - PA 16673",
    "county": "Blair County",
    "state": "PA",
    "city": "Roaring Spring"
  },
  {
    "value": "16674",
    "label": "Robertsdale - PA 16674",
    "county": "Huntingdon County",
    "state": "PA",
    "city": "Robertsdale"
  },
  {
    "value": "16678",
    "label": "Saxton - PA 16678",
    "county": "Bedford County",
    "state": "PA",
    "city": "Saxton"
  },
  {
    "value": "16679",
    "label": "Six Mile Run - PA 16679",
    "county": "Bedford County",
    "state": "PA",
    "city": "Six Mile Run"
  },
  {
    "value": "16680",
    "label": "Smithmill - PA 16680",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Smithmill"
  },
  {
    "value": "16682",
    "label": "Sproul - PA 16682",
    "county": "Blair County",
    "state": "PA",
    "city": "Sproul"
  },
  {
    "value": "16683",
    "label": "Spruce Creek - PA 16683",
    "county": "Huntingdon County",
    "state": "PA",
    "city": "Spruce Creek"
  },
  {
    "value": "16685",
    "label": "Todd - PA 16685",
    "county": "Huntingdon County",
    "state": "PA",
    "city": "Todd"
  },
  {
    "value": "16686",
    "label": "Tyrone - PA 16686",
    "county": "Blair County",
    "state": "PA",
    "city": "Tyrone"
  },
  {
    "value": "16689",
    "label": "Waterfall - PA 16689",
    "county": "Fulton County",
    "state": "PA",
    "city": "Waterfall"
  },
  {
    "value": "16691",
    "label": "Wells Tannery - PA 16691",
    "county": "Fulton County",
    "state": "PA",
    "city": "Wells Tannery"
  },
  {
    "value": "16692",
    "label": "Westover - PA 16692",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Westover"
  },
  {
    "value": "16693",
    "label": "Williamsburg - PA 16693",
    "county": "Blair County",
    "state": "PA",
    "city": "Williamsburg"
  },
  {
    "value": "16695",
    "label": "Woodbury - PA 16695",
    "county": "Bedford County",
    "state": "PA",
    "city": "Woodbury"
  },
  {
    "value": "16701",
    "label": "Bradford - PA 16701",
    "county": "McKean County",
    "state": "PA",
    "city": "Bradford"
  },
  {
    "value": "16720",
    "label": "Austin - PA 16720",
    "county": "Potter County",
    "state": "PA",
    "city": "Austin"
  },
  {
    "value": "16726",
    "label": "Cyclone - PA 16726",
    "county": "McKean County",
    "state": "PA",
    "city": "Cyclone"
  },
  {
    "value": "16727",
    "label": "Derrick City - PA 16727",
    "county": "McKean County",
    "state": "PA",
    "city": "Derrick City"
  },
  {
    "value": "16728",
    "label": "De Young - PA 16728",
    "county": "Elk County",
    "state": "PA",
    "city": "De Young"
  },
  {
    "value": "16729",
    "label": "Duke Center - PA 16729",
    "county": "McKean County",
    "state": "PA",
    "city": "Duke Center"
  },
  {
    "value": "16731",
    "label": "Eldred - PA 16731",
    "county": "McKean County",
    "state": "PA",
    "city": "Eldred"
  },
  {
    "value": "16732",
    "label": "Gifford - PA 16732",
    "county": "McKean County",
    "state": "PA",
    "city": "Gifford"
  },
  {
    "value": "16735",
    "label": "Kane - PA 16735",
    "county": "McKean County",
    "state": "PA",
    "city": "Kane"
  },
  {
    "value": "16738",
    "label": "Lewis Run - PA 16738",
    "county": "McKean County",
    "state": "PA",
    "city": "Lewis Run"
  },
  {
    "value": "16740",
    "label": "Mount Jewett - PA 16740",
    "county": "McKean County",
    "state": "PA",
    "city": "Mount Jewett"
  },
  {
    "value": "16743",
    "label": "Port Allegany - PA 16743",
    "county": "McKean County",
    "state": "PA",
    "city": "Port Allegany"
  },
  {
    "value": "16744",
    "label": "Rew - PA 16744",
    "county": "McKean County",
    "state": "PA",
    "city": "Rew"
  },
  {
    "value": "16745",
    "label": "Rixford - PA 16745",
    "county": "McKean County",
    "state": "PA",
    "city": "Rixford"
  },
  {
    "value": "16746",
    "label": "Roulette - PA 16746",
    "county": "Potter County",
    "state": "PA",
    "city": "Roulette"
  },
  {
    "value": "16748",
    "label": "Shinglehouse - PA 16748",
    "county": "Potter County",
    "state": "PA",
    "city": "Shinglehouse"
  },
  {
    "value": "16749",
    "label": "Smethport - PA 16749",
    "county": "McKean County",
    "state": "PA",
    "city": "Smethport"
  },
  {
    "value": "16750",
    "label": "Turtlepoint - PA 16750",
    "county": "McKean County",
    "state": "PA",
    "city": "Turtlepoint"
  },
  {
    "value": "16801",
    "label": "State College - PA 16801",
    "county": "Centre County",
    "state": "PA",
    "city": "State College"
  },
  {
    "value": "16802",
    "label": "University Park - PA 16802",
    "county": "Centre County",
    "state": "PA",
    "city": "University Park"
  },
  {
    "value": "16803",
    "label": "State College - PA 16803",
    "county": "Centre County",
    "state": "PA",
    "city": "State College"
  },
  {
    "value": "16820",
    "label": "Aaronsburg - PA 16820",
    "county": "Centre County",
    "state": "PA",
    "city": "Aaronsburg"
  },
  {
    "value": "16821",
    "label": "Allport - PA 16821",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Allport"
  },
  {
    "value": "16822",
    "label": "Beech Creek - PA 16822",
    "county": "Clinton County",
    "state": "PA",
    "city": "Beech Creek"
  },
  {
    "value": "16823",
    "label": "Bellefonte - PA 16823",
    "county": "Centre County",
    "state": "PA",
    "city": "Bellefonte"
  },
  {
    "value": "16827",
    "label": "Boalsburg - PA 16827",
    "county": "Centre County",
    "state": "PA",
    "city": "Boalsburg"
  },
  {
    "value": "16828",
    "label": "Centre Hall - PA 16828",
    "county": "Centre County",
    "state": "PA",
    "city": "Centre Hall"
  },
  {
    "value": "16829",
    "label": "Clarence - PA 16829",
    "county": "Centre County",
    "state": "PA",
    "city": "Clarence"
  },
  {
    "value": "16830",
    "label": "Clearfield - PA 16830",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Clearfield"
  },
  {
    "value": "16832",
    "label": "Coburn - PA 16832",
    "county": "Centre County",
    "state": "PA",
    "city": "Coburn"
  },
  {
    "value": "16833",
    "label": "Curwensville - PA 16833",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Curwensville"
  },
  {
    "value": "16836",
    "label": "Frenchville - PA 16836",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Frenchville"
  },
  {
    "value": "16837",
    "label": "Glen Richey - PA 16837",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Glen Richey"
  },
  {
    "value": "16838",
    "label": "Grampian - PA 16838",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Grampian"
  },
  {
    "value": "16839",
    "label": "Grassflat - PA 16839",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Grassflat"
  },
  {
    "value": "16840",
    "label": "Hawk Run - PA 16840",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Hawk Run"
  },
  {
    "value": "16841",
    "label": "Howard - PA 16841",
    "county": "Centre County",
    "state": "PA",
    "city": "Howard"
  },
  {
    "value": "16844",
    "label": "Julian - PA 16844",
    "county": "Centre County",
    "state": "PA",
    "city": "Julian"
  },
  {
    "value": "16845",
    "label": "Karthaus - PA 16845",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Karthaus"
  },
  {
    "value": "16850",
    "label": "Lecontes Mills - PA 16850",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Lecontes Mills"
  },
  {
    "value": "16852",
    "label": "Madisonburg - PA 16852",
    "county": "Centre County",
    "state": "PA",
    "city": "Madisonburg"
  },
  {
    "value": "16854",
    "label": "Millheim - PA 16854",
    "county": "Centre County",
    "state": "PA",
    "city": "Millheim"
  },
  {
    "value": "16858",
    "label": "Morrisdale - PA 16858",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Morrisdale"
  },
  {
    "value": "16859",
    "label": "Moshannon - PA 16859",
    "county": "Centre County",
    "state": "PA",
    "city": "Moshannon"
  },
  {
    "value": "16860",
    "label": "Munson - PA 16860",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Munson"
  },
  {
    "value": "16861",
    "label": "New Millport - PA 16861",
    "county": "Clearfield County",
    "state": "PA",
    "city": "New Millport"
  },
  {
    "value": "16863",
    "label": "Olanta - PA 16863",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Olanta"
  },
  {
    "value": "16864",
    "label": "Orviston - PA 16864",
    "county": "Centre County",
    "state": "PA",
    "city": "Orviston"
  },
  {
    "value": "16865",
    "label": "Pennsylvania Furnace - PA 16865",
    "county": "Centre County",
    "state": "PA",
    "city": "Pennsylvania Furnace"
  },
  {
    "value": "16866",
    "label": "Philipsburg - PA 16866",
    "county": "Centre County",
    "state": "PA",
    "city": "Philipsburg"
  },
  {
    "value": "16870",
    "label": "Port Matilda - PA 16870",
    "county": "Centre County",
    "state": "PA",
    "city": "Port Matilda"
  },
  {
    "value": "16871",
    "label": "Pottersdale - PA 16871",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Pottersdale"
  },
  {
    "value": "16872",
    "label": "Rebersburg - PA 16872",
    "county": "Centre County",
    "state": "PA",
    "city": "Rebersburg"
  },
  {
    "value": "16874",
    "label": "Snow Shoe - PA 16874",
    "county": "Centre County",
    "state": "PA",
    "city": "Snow Shoe"
  },
  {
    "value": "16875",
    "label": "Spring Mills - PA 16875",
    "county": "Centre County",
    "state": "PA",
    "city": "Spring Mills"
  },
  {
    "value": "16877",
    "label": "Warriors Mark - PA 16877",
    "county": "Huntingdon County",
    "state": "PA",
    "city": "Warriors Mark"
  },
  {
    "value": "16878",
    "label": "West Decatur - PA 16878",
    "county": "Clearfield County",
    "state": "PA",
    "city": "West Decatur"
  },
  {
    "value": "16879",
    "label": "Winburne - PA 16879",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Winburne"
  },
  {
    "value": "16881",
    "label": "Woodland - PA 16881",
    "county": "Clearfield County",
    "state": "PA",
    "city": "Woodland"
  },
  {
    "value": "16882",
    "label": "Woodward - PA 16882",
    "county": "Centre County",
    "state": "PA",
    "city": "Woodward"
  },
  {
    "value": "16901",
    "label": "Wellsboro - PA 16901",
    "county": "Tioga County",
    "state": "PA",
    "city": "Wellsboro"
  },
  {
    "value": "16910",
    "label": "Alba - PA 16910",
    "county": "Bradford County",
    "state": "PA",
    "city": "Alba"
  },
  {
    "value": "16912",
    "label": "Blossburg - PA 16912",
    "county": "Tioga County",
    "state": "PA",
    "city": "Blossburg"
  },
  {
    "value": "16914",
    "label": "Columbia Cross Roads - PA 16914",
    "county": "Bradford County",
    "state": "PA",
    "city": "Columbia Cross Roads"
  },
  {
    "value": "16915",
    "label": "Coudersport - PA 16915",
    "county": "Potter County",
    "state": "PA",
    "city": "Coudersport"
  },
  {
    "value": "16917",
    "label": "Covington - PA 16917",
    "county": "Tioga County",
    "state": "PA",
    "city": "Covington"
  },
  {
    "value": "16918",
    "label": "Cowanesque - PA 16918",
    "county": "Tioga County",
    "state": "PA",
    "city": "Cowanesque"
  },
  {
    "value": "16920",
    "label": "Elkland - PA 16920",
    "county": "Tioga County",
    "state": "PA",
    "city": "Elkland"
  },
  {
    "value": "16921",
    "label": "Gaines - PA 16921",
    "county": "Tioga County",
    "state": "PA",
    "city": "Gaines"
  },
  {
    "value": "16922",
    "label": "Galeton - PA 16922",
    "county": "Potter County",
    "state": "PA",
    "city": "Galeton"
  },
  {
    "value": "16923",
    "label": "Genesee - PA 16923",
    "county": "Potter County",
    "state": "PA",
    "city": "Genesee"
  },
  {
    "value": "16925",
    "label": "Gillett - PA 16925",
    "county": "Bradford County",
    "state": "PA",
    "city": "Gillett"
  },
  {
    "value": "16926",
    "label": "Granville Summit - PA 16926",
    "county": "Bradford County",
    "state": "PA",
    "city": "Granville Summit"
  },
  {
    "value": "16927",
    "label": "Harrison Valley - PA 16927",
    "county": "Potter County",
    "state": "PA",
    "city": "Harrison Valley"
  },
  {
    "value": "16928",
    "label": "Knoxville - PA 16928",
    "county": "Tioga County",
    "state": "PA",
    "city": "Knoxville"
  },
  {
    "value": "16929",
    "label": "Lawrenceville - PA 16929",
    "county": "Tioga County",
    "state": "PA",
    "city": "Lawrenceville"
  },
  {
    "value": "16930",
    "label": "Liberty - PA 16930",
    "county": "Tioga County",
    "state": "PA",
    "city": "Liberty"
  },
  {
    "value": "16932",
    "label": "Mainesburg - PA 16932",
    "county": "Tioga County",
    "state": "PA",
    "city": "Mainesburg"
  },
  {
    "value": "16933",
    "label": "Mansfield - PA 16933",
    "county": "Tioga County",
    "state": "PA",
    "city": "Mansfield"
  },
  {
    "value": "16935",
    "label": "Middlebury Center - PA 16935",
    "county": "Tioga County",
    "state": "PA",
    "city": "Middlebury Center"
  },
  {
    "value": "16936",
    "label": "Millerton - PA 16936",
    "county": "Tioga County",
    "state": "PA",
    "city": "Millerton"
  },
  {
    "value": "16937",
    "label": "Mills - PA 16937",
    "county": "Potter County",
    "state": "PA",
    "city": "Mills"
  },
  {
    "value": "16938",
    "label": "Morris - PA 16938",
    "county": "Tioga County",
    "state": "PA",
    "city": "Morris"
  },
  {
    "value": "16941",
    "label": "Genesee - PA 16941",
    "county": "Potter County",
    "state": "PA",
    "city": "Genesee"
  },
  {
    "value": "16942",
    "label": "Osceola - PA 16942",
    "county": "Tioga County",
    "state": "PA",
    "city": "Osceola"
  },
  {
    "value": "16943",
    "label": "Sabinsville - PA 16943",
    "county": "Tioga County",
    "state": "PA",
    "city": "Sabinsville"
  },
  {
    "value": "16946",
    "label": "Tioga - PA 16946",
    "county": "Tioga County",
    "state": "PA",
    "city": "Tioga"
  },
  {
    "value": "16947",
    "label": "Troy - PA 16947",
    "county": "Bradford County",
    "state": "PA",
    "city": "Troy"
  },
  {
    "value": "16948",
    "label": "Ulysses - PA 16948",
    "county": "Potter County",
    "state": "PA",
    "city": "Ulysses"
  },
  {
    "value": "16950",
    "label": "Westfield - PA 16950",
    "county": "Tioga County",
    "state": "PA",
    "city": "Westfield"
  },
  {
    "value": "17002",
    "label": "Allensville - PA 17002",
    "county": "Mifflin County",
    "state": "PA",
    "city": "Allensville"
  },
  {
    "value": "17003",
    "label": "Annville - PA 17003",
    "county": "Lebanon County",
    "state": "PA",
    "city": "Annville"
  },
  {
    "value": "17004",
    "label": "Belleville - PA 17004",
    "county": "Mifflin County",
    "state": "PA",
    "city": "Belleville"
  },
  {
    "value": "17006",
    "label": "Blain - PA 17006",
    "county": "Perry County",
    "state": "PA",
    "city": "Blain"
  },
  {
    "value": "17007",
    "label": "Boiling Springs - PA 17007",
    "county": "Cumberland County",
    "state": "PA",
    "city": "Boiling Springs"
  },
  {
    "value": "17009",
    "label": "Burnham - PA 17009",
    "county": "Mifflin County",
    "state": "PA",
    "city": "Burnham"
  },
  {
    "value": "17011",
    "label": "Camp Hill - PA 17011",
    "county": "Cumberland County",
    "state": "PA",
    "city": "Camp Hill"
  },
  {
    "value": "17013",
    "label": "Carlisle - PA 17013",
    "county": "Cumberland County",
    "state": "PA",
    "city": "Carlisle"
  },
  {
    "value": "17014",
    "label": "Cocolamus - PA 17014",
    "county": "Juniata County",
    "state": "PA",
    "city": "Cocolamus"
  },
  {
    "value": "17015",
    "label": "Carlisle - PA 17015",
    "county": "Cumberland County",
    "state": "PA",
    "city": "Carlisle"
  },
  {
    "value": "17017",
    "label": "Dalmatia - PA 17017",
    "county": "Northumberland County",
    "state": "PA",
    "city": "Dalmatia"
  },
  {
    "value": "17018",
    "label": "Dauphin - PA 17018",
    "county": "Dauphin County",
    "state": "PA",
    "city": "Dauphin"
  },
  {
    "value": "17019",
    "label": "Dillsburg - PA 17019",
    "county": "York County",
    "state": "PA",
    "city": "Dillsburg"
  },
  {
    "value": "17020",
    "label": "Duncannon - PA 17020",
    "county": "Perry County",
    "state": "PA",
    "city": "Duncannon"
  },
  {
    "value": "17021",
    "label": "East Waterford - PA 17021",
    "county": "Juniata County",
    "state": "PA",
    "city": "East Waterford"
  },
  {
    "value": "17022",
    "label": "Elizabethtown - PA 17022",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Elizabethtown"
  },
  {
    "value": "17023",
    "label": "Elizabethville - PA 17023",
    "county": "Dauphin County",
    "state": "PA",
    "city": "Elizabethville"
  },
  {
    "value": "17024",
    "label": "Elliottsburg - PA 17024",
    "county": "Perry County",
    "state": "PA",
    "city": "Elliottsburg"
  },
  {
    "value": "17025",
    "label": "Enola - PA 17025",
    "county": "Cumberland County",
    "state": "PA",
    "city": "Enola"
  },
  {
    "value": "17026",
    "label": "Fredericksburg - PA 17026",
    "county": "Lebanon County",
    "state": "PA",
    "city": "Fredericksburg"
  },
  {
    "value": "17028",
    "label": "Grantville - PA 17028",
    "county": "Dauphin County",
    "state": "PA",
    "city": "Grantville"
  },
  {
    "value": "17029",
    "label": "Granville - PA 17029",
    "county": "Mifflin County",
    "state": "PA",
    "city": "Granville"
  },
  {
    "value": "17030",
    "label": "Gratz - PA 17030",
    "county": "Dauphin County",
    "state": "PA",
    "city": "Gratz"
  },
  {
    "value": "17032",
    "label": "Halifax - PA 17032",
    "county": "Dauphin County",
    "state": "PA",
    "city": "Halifax"
  },
  {
    "value": "17033",
    "label": "Hershey - PA 17033",
    "county": "Dauphin County",
    "state": "PA",
    "city": "Hershey"
  },
  {
    "value": "17034",
    "label": "Highspire - PA 17034",
    "county": "Dauphin County",
    "state": "PA",
    "city": "Highspire"
  },
  {
    "value": "17035",
    "label": "Honey Grove - PA 17035",
    "county": "Juniata County",
    "state": "PA",
    "city": "Honey Grove"
  },
  {
    "value": "17036",
    "label": "Hummelstown - PA 17036",
    "county": "Dauphin County",
    "state": "PA",
    "city": "Hummelstown"
  },
  {
    "value": "17037",
    "label": "Ickesburg - PA 17037",
    "county": "Perry County",
    "state": "PA",
    "city": "Ickesburg"
  },
  {
    "value": "17038",
    "label": "Jonestown - PA 17038",
    "county": "Lebanon County",
    "state": "PA",
    "city": "Jonestown"
  },
  {
    "value": "17040",
    "label": "Landisburg - PA 17040",
    "county": "Perry County",
    "state": "PA",
    "city": "Landisburg"
  },
  {
    "value": "17042",
    "label": "Lebanon - PA 17042",
    "county": "Lebanon County",
    "state": "PA",
    "city": "Lebanon"
  },
  {
    "value": "17043",
    "label": "Lemoyne - PA 17043",
    "county": "Cumberland County",
    "state": "PA",
    "city": "Lemoyne"
  },
  {
    "value": "17044",
    "label": "Lewistown - PA 17044",
    "county": "Mifflin County",
    "state": "PA",
    "city": "Lewistown"
  },
  {
    "value": "17045",
    "label": "Liverpool - PA 17045",
    "county": "Perry County",
    "state": "PA",
    "city": "Liverpool"
  },
  {
    "value": "17046",
    "label": "Lebanon - PA 17046",
    "county": "Lebanon County",
    "state": "PA",
    "city": "Lebanon"
  },
  {
    "value": "17047",
    "label": "Loysville - PA 17047",
    "county": "Perry County",
    "state": "PA",
    "city": "Loysville"
  },
  {
    "value": "17048",
    "label": "Lykens - PA 17048",
    "county": "Dauphin County",
    "state": "PA",
    "city": "Lykens"
  },
  {
    "value": "17049",
    "label": "Mc Alisterville - PA 17049",
    "county": "Juniata County",
    "state": "PA",
    "city": "Mc Alisterville"
  },
  {
    "value": "17050",
    "label": "Mechanicsburg - PA 17050",
    "county": "Cumberland County",
    "state": "PA",
    "city": "Mechanicsburg"
  },
  {
    "value": "17051",
    "label": "Mc Veytown - PA 17051",
    "county": "Mifflin County",
    "state": "PA",
    "city": "Mc Veytown"
  },
  {
    "value": "17052",
    "label": "Mapleton Depot - PA 17052",
    "county": "Huntingdon County",
    "state": "PA",
    "city": "Mapleton Depot"
  },
  {
    "value": "17053",
    "label": "Marysville - PA 17053",
    "county": "Perry County",
    "state": "PA",
    "city": "Marysville"
  },
  {
    "value": "17055",
    "label": "Mechanicsburg - PA 17055",
    "county": "Cumberland County",
    "state": "PA",
    "city": "Mechanicsburg"
  },
  {
    "value": "17057",
    "label": "Middletown - PA 17057",
    "county": "Dauphin County",
    "state": "PA",
    "city": "Middletown"
  },
  {
    "value": "17058",
    "label": "Mifflin - PA 17058",
    "county": "Juniata County",
    "state": "PA",
    "city": "Mifflin"
  },
  {
    "value": "17059",
    "label": "Mifflintown - PA 17059",
    "county": "Juniata County",
    "state": "PA",
    "city": "Mifflintown"
  },
  {
    "value": "17060",
    "label": "Mill Creek - PA 17060",
    "county": "Huntingdon County",
    "state": "PA",
    "city": "Mill Creek"
  },
  {
    "value": "17061",
    "label": "Millersburg - PA 17061",
    "county": "Dauphin County",
    "state": "PA",
    "city": "Millersburg"
  },
  {
    "value": "17062",
    "label": "Millerstown - PA 17062",
    "county": "Perry County",
    "state": "PA",
    "city": "Millerstown"
  },
  {
    "value": "17063",
    "label": "Milroy - PA 17063",
    "county": "Mifflin County",
    "state": "PA",
    "city": "Milroy"
  },
  {
    "value": "17065",
    "label": "Mount Holly Springs - PA 17065",
    "county": "Cumberland County",
    "state": "PA",
    "city": "Mount Holly Springs"
  },
  {
    "value": "17066",
    "label": "Mount Union - PA 17066",
    "county": "Huntingdon County",
    "state": "PA",
    "city": "Mount Union"
  },
  {
    "value": "17067",
    "label": "Myerstown - PA 17067",
    "county": "Lebanon County",
    "state": "PA",
    "city": "Myerstown"
  },
  {
    "value": "17068",
    "label": "New Bloomfield - PA 17068",
    "county": "Perry County",
    "state": "PA",
    "city": "New Bloomfield"
  },
  {
    "value": "17070",
    "label": "New Cumberland - PA 17070",
    "county": "Cumberland County",
    "state": "PA",
    "city": "New Cumberland"
  },
  {
    "value": "17073",
    "label": "Newmanstown - PA 17073",
    "county": "Lebanon County",
    "state": "PA",
    "city": "Newmanstown"
  },
  {
    "value": "17074",
    "label": "Newport - PA 17074",
    "county": "Perry County",
    "state": "PA",
    "city": "Newport"
  },
  {
    "value": "17076",
    "label": "Oakland Mills - PA 17076",
    "county": "Juniata County",
    "state": "PA",
    "city": "Oakland Mills"
  },
  {
    "value": "17078",
    "label": "Palmyra - PA 17078",
    "county": "Lebanon County",
    "state": "PA",
    "city": "Palmyra"
  },
  {
    "value": "17082",
    "label": "Port Royal - PA 17082",
    "county": "Juniata County",
    "state": "PA",
    "city": "Port Royal"
  },
  {
    "value": "17084",
    "label": "Reedsville - PA 17084",
    "county": "Mifflin County",
    "state": "PA",
    "city": "Reedsville"
  },
  {
    "value": "17086",
    "label": "Richfield - PA 17086",
    "county": "Juniata County",
    "state": "PA",
    "city": "Richfield"
  },
  {
    "value": "17087",
    "label": "Richland - PA 17087",
    "county": "Lebanon County",
    "state": "PA",
    "city": "Richland"
  },
  {
    "value": "17090",
    "label": "Shermans Dale - PA 17090",
    "county": "Perry County",
    "state": "PA",
    "city": "Shermans Dale"
  },
  {
    "value": "17094",
    "label": "Thompsontown - PA 17094",
    "county": "Juniata County",
    "state": "PA",
    "city": "Thompsontown"
  },
  {
    "value": "17098",
    "label": "Williamstown - PA 17098",
    "county": "Dauphin County",
    "state": "PA",
    "city": "Williamstown"
  },
  {
    "value": "17099",
    "label": "Yeagertown - PA 17099",
    "county": "Mifflin County",
    "state": "PA",
    "city": "Yeagertown"
  },
  {
    "value": "17101",
    "label": "Harrisburg - PA 17101",
    "county": "Dauphin County",
    "state": "PA",
    "city": "Harrisburg"
  },
  {
    "value": "17102",
    "label": "Harrisburg - PA 17102",
    "county": "Dauphin County",
    "state": "PA",
    "city": "Harrisburg"
  },
  {
    "value": "17103",
    "label": "Harrisburg - PA 17103",
    "county": "Dauphin County",
    "state": "PA",
    "city": "Harrisburg"
  },
  {
    "value": "17104",
    "label": "Harrisburg - PA 17104",
    "county": "Dauphin County",
    "state": "PA",
    "city": "Harrisburg"
  },
  {
    "value": "17109",
    "label": "Harrisburg - PA 17109",
    "county": "Dauphin County",
    "state": "PA",
    "city": "Harrisburg"
  },
  {
    "value": "17110",
    "label": "Harrisburg - PA 17110",
    "county": "Dauphin County",
    "state": "PA",
    "city": "Harrisburg"
  },
  {
    "value": "17111",
    "label": "Harrisburg - PA 17111",
    "county": "Dauphin County",
    "state": "PA",
    "city": "Harrisburg"
  },
  {
    "value": "17112",
    "label": "Harrisburg - PA 17112",
    "county": "Dauphin County",
    "state": "PA",
    "city": "Harrisburg"
  },
  {
    "value": "17113",
    "label": "Harrisburg - PA 17113",
    "county": "Dauphin County",
    "state": "PA",
    "city": "Harrisburg"
  },
  {
    "value": "17129",
    "label": "Harrisburg - PA 17129",
    "county": "Dauphin County",
    "state": "PA",
    "city": "Harrisburg"
  },
  {
    "value": "17201",
    "label": "Chambersburg - PA 17201",
    "county": "Franklin County",
    "state": "PA",
    "city": "Chambersburg"
  },
  {
    "value": "17202",
    "label": "Chambersburg - PA 17202",
    "county": "Franklin County",
    "state": "PA",
    "city": "Chambersburg"
  },
  {
    "value": "17211",
    "label": "Artemas - PA 17211",
    "county": "Bedford County",
    "state": "PA",
    "city": "Artemas"
  },
  {
    "value": "17212",
    "label": "Big Cove Tannery - PA 17212",
    "county": "Fulton County",
    "state": "PA",
    "city": "Big Cove Tannery"
  },
  {
    "value": "17213",
    "label": "Blairs Mills - PA 17213",
    "county": "Huntingdon County",
    "state": "PA",
    "city": "Blairs Mills"
  },
  {
    "value": "17214",
    "label": "Blue Ridge Summit - PA 17214",
    "county": "Franklin County",
    "state": "PA",
    "city": "Blue Ridge Summit"
  },
  {
    "value": "17215",
    "label": "Burnt Cabins - PA 17215",
    "county": "Fulton County",
    "state": "PA",
    "city": "Burnt Cabins"
  },
  {
    "value": "17217",
    "label": "Concord - PA 17217",
    "county": "Franklin County",
    "state": "PA",
    "city": "Concord"
  },
  {
    "value": "17219",
    "label": "Doylesburg - PA 17219",
    "county": "Franklin County",
    "state": "PA",
    "city": "Doylesburg"
  },
  {
    "value": "17220",
    "label": "Dry Run - PA 17220",
    "county": "Franklin County",
    "state": "PA",
    "city": "Dry Run"
  },
  {
    "value": "17221",
    "label": "Fannettsburg - PA 17221",
    "county": "Franklin County",
    "state": "PA",
    "city": "Fannettsburg"
  },
  {
    "value": "17222",
    "label": "Fayetteville - PA 17222",
    "county": "Franklin County",
    "state": "PA",
    "city": "Fayetteville"
  },
  {
    "value": "17223",
    "label": "Fort Littleton - PA 17223",
    "county": "Fulton County",
    "state": "PA",
    "city": "Fort Littleton"
  },
  {
    "value": "17224",
    "label": "Fort Loudon - PA 17224",
    "county": "Franklin County",
    "state": "PA",
    "city": "Fort Loudon"
  },
  {
    "value": "17225",
    "label": "Greencastle - PA 17225",
    "county": "Franklin County",
    "state": "PA",
    "city": "Greencastle"
  },
  {
    "value": "17228",
    "label": "Harrisonville - PA 17228",
    "county": "Fulton County",
    "state": "PA",
    "city": "Harrisonville"
  },
  {
    "value": "17229",
    "label": "Hustontown - PA 17229",
    "county": "Fulton County",
    "state": "PA",
    "city": "Hustontown"
  },
  {
    "value": "17232",
    "label": "Lurgan - PA 17232",
    "county": "Franklin County",
    "state": "PA",
    "city": "Lurgan"
  },
  {
    "value": "17233",
    "label": "Mc Connellsburg - PA 17233",
    "county": "Fulton County",
    "state": "PA",
    "city": "Mc Connellsburg"
  },
  {
    "value": "17236",
    "label": "Mercersburg - PA 17236",
    "county": "Franklin County",
    "state": "PA",
    "city": "Mercersburg"
  },
  {
    "value": "17237",
    "label": "Mont Alto - PA 17237",
    "county": "Franklin County",
    "state": "PA",
    "city": "Mont Alto"
  },
  {
    "value": "17238",
    "label": "Needmore - PA 17238",
    "county": "Fulton County",
    "state": "PA",
    "city": "Needmore"
  },
  {
    "value": "17239",
    "label": "Neelyton - PA 17239",
    "county": "Huntingdon County",
    "state": "PA",
    "city": "Neelyton"
  },
  {
    "value": "17240",
    "label": "Newburg - PA 17240",
    "county": "Cumberland County",
    "state": "PA",
    "city": "Newburg"
  },
  {
    "value": "17241",
    "label": "Newville - PA 17241",
    "county": "Cumberland County",
    "state": "PA",
    "city": "Newville"
  },
  {
    "value": "17243",
    "label": "Orbisonia - PA 17243",
    "county": "Huntingdon County",
    "state": "PA",
    "city": "Orbisonia"
  },
  {
    "value": "17244",
    "label": "Orrstown - PA 17244",
    "county": "Franklin County",
    "state": "PA",
    "city": "Orrstown"
  },
  {
    "value": "17246",
    "label": "Pleasant Hall - PA 17246",
    "county": "Franklin County",
    "state": "PA",
    "city": "Pleasant Hall"
  },
  {
    "value": "17252",
    "label": "Saint Thomas - PA 17252",
    "county": "Franklin County",
    "state": "PA",
    "city": "Saint Thomas"
  },
  {
    "value": "17255",
    "label": "Shade Gap - PA 17255",
    "county": "Huntingdon County",
    "state": "PA",
    "city": "Shade Gap"
  },
  {
    "value": "17257",
    "label": "Shippensburg - PA 17257",
    "county": "Cumberland County",
    "state": "PA",
    "city": "Shippensburg"
  },
  {
    "value": "17260",
    "label": "Shirleysburg - PA 17260",
    "county": "Huntingdon County",
    "state": "PA",
    "city": "Shirleysburg"
  },
  {
    "value": "17262",
    "label": "Spring Run - PA 17262",
    "county": "Franklin County",
    "state": "PA",
    "city": "Spring Run"
  },
  {
    "value": "17264",
    "label": "Three Springs - PA 17264",
    "county": "Huntingdon County",
    "state": "PA",
    "city": "Three Springs"
  },
  {
    "value": "17265",
    "label": "Upperstrasburg - PA 17265",
    "county": "Franklin County",
    "state": "PA",
    "city": "Upperstrasburg"
  },
  {
    "value": "17266",
    "label": "Walnut Bottom - PA 17266",
    "county": "Cumberland County",
    "state": "PA",
    "city": "Walnut Bottom"
  },
  {
    "value": "17267",
    "label": "Warfordsburg - PA 17267",
    "county": "Fulton County",
    "state": "PA",
    "city": "Warfordsburg"
  },
  {
    "value": "17268",
    "label": "Waynesboro - PA 17268",
    "county": "Franklin County",
    "state": "PA",
    "city": "Waynesboro"
  },
  {
    "value": "17271",
    "label": "Willow Hill - PA 17271",
    "county": "Franklin County",
    "state": "PA",
    "city": "Willow Hill"
  },
  {
    "value": "17301",
    "label": "Abbottstown - PA 17301",
    "county": "Adams County",
    "state": "PA",
    "city": "Abbottstown"
  },
  {
    "value": "17302",
    "label": "Airville - PA 17302",
    "county": "York County",
    "state": "PA",
    "city": "Airville"
  },
  {
    "value": "17304",
    "label": "Aspers - PA 17304",
    "county": "Adams County",
    "state": "PA",
    "city": "Aspers"
  },
  {
    "value": "17307",
    "label": "Biglerville - PA 17307",
    "county": "Adams County",
    "state": "PA",
    "city": "Biglerville"
  },
  {
    "value": "17309",
    "label": "Brogue - PA 17309",
    "county": "York County",
    "state": "PA",
    "city": "Brogue"
  },
  {
    "value": "17313",
    "label": "Dallastown - PA 17313",
    "county": "York County",
    "state": "PA",
    "city": "Dallastown"
  },
  {
    "value": "17314",
    "label": "Delta - PA 17314",
    "county": "York County",
    "state": "PA",
    "city": "Delta"
  },
  {
    "value": "17315",
    "label": "Dover - PA 17315",
    "county": "York County",
    "state": "PA",
    "city": "Dover"
  },
  {
    "value": "17316",
    "label": "East Berlin - PA 17316",
    "county": "Adams County",
    "state": "PA",
    "city": "East Berlin"
  },
  {
    "value": "17319",
    "label": "Etters - PA 17319",
    "county": "York County",
    "state": "PA",
    "city": "Etters"
  },
  {
    "value": "17320",
    "label": "Fairfield - PA 17320",
    "county": "Adams County",
    "state": "PA",
    "city": "Fairfield"
  },
  {
    "value": "17321",
    "label": "Fawn Grove - PA 17321",
    "county": "York County",
    "state": "PA",
    "city": "Fawn Grove"
  },
  {
    "value": "17322",
    "label": "Felton - PA 17322",
    "county": "York County",
    "state": "PA",
    "city": "Felton"
  },
  {
    "value": "17324",
    "label": "Gardners - PA 17324",
    "county": "Cumberland County",
    "state": "PA",
    "city": "Gardners"
  },
  {
    "value": "17325",
    "label": "Gettysburg - PA 17325",
    "county": "Adams County",
    "state": "PA",
    "city": "Gettysburg"
  },
  {
    "value": "17327",
    "label": "Glen Rock - PA 17327",
    "county": "York County",
    "state": "PA",
    "city": "Glen Rock"
  },
  {
    "value": "17329",
    "label": "Glenville - PA 17329",
    "county": "York County",
    "state": "PA",
    "city": "Glenville"
  },
  {
    "value": "17331",
    "label": "Hanover - PA 17331",
    "county": "York County",
    "state": "PA",
    "city": "Hanover"
  },
  {
    "value": "17339",
    "label": "Lewisberry - PA 17339",
    "county": "York County",
    "state": "PA",
    "city": "Lewisberry"
  },
  {
    "value": "17340",
    "label": "Littlestown - PA 17340",
    "county": "Adams County",
    "state": "PA",
    "city": "Littlestown"
  },
  {
    "value": "17344",
    "label": "Mc Sherrystown - PA 17344",
    "county": "Adams County",
    "state": "PA",
    "city": "Mc Sherrystown"
  },
  {
    "value": "17345",
    "label": "Manchester - PA 17345",
    "county": "York County",
    "state": "PA",
    "city": "Manchester"
  },
  {
    "value": "17347",
    "label": "Mount Wolf - PA 17347",
    "county": "York County",
    "state": "PA",
    "city": "Mount Wolf"
  },
  {
    "value": "17349",
    "label": "New Freedom - PA 17349",
    "county": "York County",
    "state": "PA",
    "city": "New Freedom"
  },
  {
    "value": "17350",
    "label": "New Oxford - PA 17350",
    "county": "Adams County",
    "state": "PA",
    "city": "New Oxford"
  },
  {
    "value": "17352",
    "label": "New Park - PA 17352",
    "county": "York County",
    "state": "PA",
    "city": "New Park"
  },
  {
    "value": "17353",
    "label": "Orrtanna - PA 17353",
    "county": "Adams County",
    "state": "PA",
    "city": "Orrtanna"
  },
  {
    "value": "17354",
    "label": "Porters Sideling - PA 17354",
    "county": "York County",
    "state": "PA",
    "city": "Porters Sideling"
  },
  {
    "value": "17356",
    "label": "Red Lion - PA 17356",
    "county": "York County",
    "state": "PA",
    "city": "Red Lion"
  },
  {
    "value": "17360",
    "label": "Seven Valleys - PA 17360",
    "county": "York County",
    "state": "PA",
    "city": "Seven Valleys"
  },
  {
    "value": "17361",
    "label": "Shrewsbury - PA 17361",
    "county": "York County",
    "state": "PA",
    "city": "Shrewsbury"
  },
  {
    "value": "17362",
    "label": "Spring Grove - PA 17362",
    "county": "York County",
    "state": "PA",
    "city": "Spring Grove"
  },
  {
    "value": "17363",
    "label": "Stewartstown - PA 17363",
    "county": "York County",
    "state": "PA",
    "city": "Stewartstown"
  },
  {
    "value": "17364",
    "label": "Thomasville - PA 17364",
    "county": "York County",
    "state": "PA",
    "city": "Thomasville"
  },
  {
    "value": "17365",
    "label": "Wellsville - PA 17365",
    "county": "York County",
    "state": "PA",
    "city": "Wellsville"
  },
  {
    "value": "17366",
    "label": "Windsor - PA 17366",
    "county": "York County",
    "state": "PA",
    "city": "Windsor"
  },
  {
    "value": "17368",
    "label": "Wrightsville - PA 17368",
    "county": "York County",
    "state": "PA",
    "city": "Wrightsville"
  },
  {
    "value": "17370",
    "label": "York Haven - PA 17370",
    "county": "York County",
    "state": "PA",
    "city": "York Haven"
  },
  {
    "value": "17372",
    "label": "York Springs - PA 17372",
    "county": "Adams County",
    "state": "PA",
    "city": "York Springs"
  },
  {
    "value": "17401",
    "label": "York - PA 17401",
    "county": "York County",
    "state": "PA",
    "city": "York"
  },
  {
    "value": "17402",
    "label": "York - PA 17402",
    "county": "York County",
    "state": "PA",
    "city": "York"
  },
  {
    "value": "17403",
    "label": "York - PA 17403",
    "county": "York County",
    "state": "PA",
    "city": "York"
  },
  {
    "value": "17404",
    "label": "York - PA 17404",
    "county": "York County",
    "state": "PA",
    "city": "York"
  },
  {
    "value": "17406",
    "label": "York - PA 17406",
    "county": "York County",
    "state": "PA",
    "city": "York"
  },
  {
    "value": "17407",
    "label": "York - PA 17407",
    "county": "York County",
    "state": "PA",
    "city": "York"
  },
  {
    "value": "17408",
    "label": "York - PA 17408",
    "county": "York County",
    "state": "PA",
    "city": "York"
  },
  {
    "value": "17501",
    "label": "Akron - PA 17501",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Akron"
  },
  {
    "value": "17502",
    "label": "Bainbridge - PA 17502",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Bainbridge"
  },
  {
    "value": "17505",
    "label": "Bird In Hand - PA 17505",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Bird In Hand"
  },
  {
    "value": "17509",
    "label": "Christiana - PA 17509",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Christiana"
  },
  {
    "value": "17512",
    "label": "Columbia - PA 17512",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Columbia"
  },
  {
    "value": "17516",
    "label": "Conestoga - PA 17516",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Conestoga"
  },
  {
    "value": "17517",
    "label": "Denver - PA 17517",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Denver"
  },
  {
    "value": "17518",
    "label": "Drumore - PA 17518",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Drumore"
  },
  {
    "value": "17519",
    "label": "East Earl - PA 17519",
    "county": "Lancaster County",
    "state": "PA",
    "city": "East Earl"
  },
  {
    "value": "17520",
    "label": "East Petersburg - PA 17520",
    "county": "Lancaster County",
    "state": "PA",
    "city": "East Petersburg"
  },
  {
    "value": "17522",
    "label": "Ephrata - PA 17522",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Ephrata"
  },
  {
    "value": "17527",
    "label": "Gap - PA 17527",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Gap"
  },
  {
    "value": "17529",
    "label": "Gordonville - PA 17529",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Gordonville"
  },
  {
    "value": "17532",
    "label": "Holtwood - PA 17532",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Holtwood"
  },
  {
    "value": "17535",
    "label": "Kinzers - PA 17535",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Kinzers"
  },
  {
    "value": "17536",
    "label": "Kirkwood - PA 17536",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Kirkwood"
  },
  {
    "value": "17538",
    "label": "Landisville - PA 17538",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Landisville"
  },
  {
    "value": "17540",
    "label": "Leola - PA 17540",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Leola"
  },
  {
    "value": "17543",
    "label": "Lititz - PA 17543",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Lititz"
  },
  {
    "value": "17545",
    "label": "Manheim - PA 17545",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Manheim"
  },
  {
    "value": "17547",
    "label": "Marietta - PA 17547",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Marietta"
  },
  {
    "value": "17551",
    "label": "Millersville - PA 17551",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Millersville"
  },
  {
    "value": "17552",
    "label": "Mount Joy - PA 17552",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Mount Joy"
  },
  {
    "value": "17554",
    "label": "Mountville - PA 17554",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Mountville"
  },
  {
    "value": "17555",
    "label": "Narvon - PA 17555",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Narvon"
  },
  {
    "value": "17557",
    "label": "New Holland - PA 17557",
    "county": "Lancaster County",
    "state": "PA",
    "city": "New Holland"
  },
  {
    "value": "17560",
    "label": "New Providence - PA 17560",
    "county": "Lancaster County",
    "state": "PA",
    "city": "New Providence"
  },
  {
    "value": "17562",
    "label": "Paradise - PA 17562",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Paradise"
  },
  {
    "value": "17563",
    "label": "Peach Bottom - PA 17563",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Peach Bottom"
  },
  {
    "value": "17565",
    "label": "Pequea - PA 17565",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Pequea"
  },
  {
    "value": "17566",
    "label": "Quarryville - PA 17566",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Quarryville"
  },
  {
    "value": "17569",
    "label": "Reinholds - PA 17569",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Reinholds"
  },
  {
    "value": "17572",
    "label": "Ronks - PA 17572",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Ronks"
  },
  {
    "value": "17576",
    "label": "Smoketown - PA 17576",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Smoketown"
  },
  {
    "value": "17578",
    "label": "Stevens - PA 17578",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Stevens"
  },
  {
    "value": "17579",
    "label": "Strasburg - PA 17579",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Strasburg"
  },
  {
    "value": "17581",
    "label": "Terre Hill - PA 17581",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Terre Hill"
  },
  {
    "value": "17582",
    "label": "Washington Boro - PA 17582",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Washington Boro"
  },
  {
    "value": "17584",
    "label": "Willow Street - PA 17584",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Willow Street"
  },
  {
    "value": "17601",
    "label": "Lancaster - PA 17601",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Lancaster"
  },
  {
    "value": "17602",
    "label": "Lancaster - PA 17602",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Lancaster"
  },
  {
    "value": "17603",
    "label": "Lancaster - PA 17603",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Lancaster"
  },
  {
    "value": "17611",
    "label": "Lancaster - PA 17611",
    "county": "Lancaster County",
    "state": "PA",
    "city": "Lancaster"
  },
  {
    "value": "17701",
    "label": "Williamsport - PA 17701",
    "county": "Lycoming County",
    "state": "PA",
    "city": "Williamsport"
  },
  {
    "value": "17702",
    "label": "Williamsport - PA 17702",
    "county": "Lycoming County",
    "state": "PA",
    "city": "Williamsport"
  },
  {
    "value": "17723",
    "label": "Cammal - PA 17723",
    "county": "Lycoming County",
    "state": "PA",
    "city": "Cammal"
  },
  {
    "value": "17724",
    "label": "Canton - PA 17724",
    "county": "Bradford County",
    "state": "PA",
    "city": "Canton"
  },
  {
    "value": "17727",
    "label": "Cedar Run - PA 17727",
    "county": "Lycoming County",
    "state": "PA",
    "city": "Cedar Run"
  },
  {
    "value": "17728",
    "label": "Cogan Station - PA 17728",
    "county": "Lycoming County",
    "state": "PA",
    "city": "Cogan Station"
  },
  {
    "value": "17729",
    "label": "Cross Fork - PA 17729",
    "county": "Clinton County",
    "state": "PA",
    "city": "Cross Fork"
  },
  {
    "value": "17737",
    "label": "Hughesville - PA 17737",
    "county": "Lycoming County",
    "state": "PA",
    "city": "Hughesville"
  },
  {
    "value": "17738",
    "label": "North Bend - PA 17738",
    "county": "Clinton County",
    "state": "PA",
    "city": "North Bend"
  },
  {
    "value": "17739",
    "label": "Jersey Mills - PA 17739",
    "county": "Lycoming County",
    "state": "PA",
    "city": "Jersey Mills"
  },
  {
    "value": "17740",
    "label": "Jersey Shore - PA 17740",
    "county": "Lycoming County",
    "state": "PA",
    "city": "Jersey Shore"
  },
  {
    "value": "17742",
    "label": "Lairdsville - PA 17742",
    "county": "Lycoming County",
    "state": "PA",
    "city": "Lairdsville"
  },
  {
    "value": "17744",
    "label": "Linden - PA 17744",
    "county": "Lycoming County",
    "state": "PA",
    "city": "Linden"
  },
  {
    "value": "17745",
    "label": "Lock Haven - PA 17745",
    "county": "Clinton County",
    "state": "PA",
    "city": "Lock Haven"
  },
  {
    "value": "17747",
    "label": "Loganton - PA 17747",
    "county": "Clinton County",
    "state": "PA",
    "city": "Loganton"
  },
  {
    "value": "17751",
    "label": "Mill Hall - PA 17751",
    "county": "Clinton County",
    "state": "PA",
    "city": "Mill Hall"
  },
  {
    "value": "17752",
    "label": "Montgomery - PA 17752",
    "county": "Lycoming County",
    "state": "PA",
    "city": "Montgomery"
  },
  {
    "value": "17754",
    "label": "Montoursville - PA 17754",
    "county": "Lycoming County",
    "state": "PA",
    "city": "Montoursville"
  },
  {
    "value": "17756",
    "label": "Muncy - PA 17756",
    "county": "Lycoming County",
    "state": "PA",
    "city": "Muncy"
  },
  {
    "value": "17758",
    "label": "Muncy Valley - PA 17758",
    "county": "Sullivan County",
    "state": "PA",
    "city": "Muncy Valley"
  },
  {
    "value": "17760",
    "label": "North Bend - PA 17760",
    "county": "Clinton County",
    "state": "PA",
    "city": "North Bend"
  },
  {
    "value": "17764",
    "label": "Renovo - PA 17764",
    "county": "Clinton County",
    "state": "PA",
    "city": "Renovo"
  },
  {
    "value": "17765",
    "label": "Roaring Branch - PA 17765",
    "county": "Tioga County",
    "state": "PA",
    "city": "Roaring Branch"
  },
  {
    "value": "17768",
    "label": "Shunk - PA 17768",
    "county": "Sullivan County",
    "state": "PA",
    "city": "Shunk"
  },
  {
    "value": "17769",
    "label": "Slate Run - PA 17769",
    "county": "Lycoming County",
    "state": "PA",
    "city": "Slate Run"
  },
  {
    "value": "17771",
    "label": "Trout Run - PA 17771",
    "county": "Lycoming County",
    "state": "PA",
    "city": "Trout Run"
  },
  {
    "value": "17772",
    "label": "Turbotville - PA 17772",
    "county": "Northumberland County",
    "state": "PA",
    "city": "Turbotville"
  },
  {
    "value": "17774",
    "label": "Unityville - PA 17774",
    "county": "Lycoming County",
    "state": "PA",
    "city": "Unityville"
  },
  {
    "value": "17776",
    "label": "Waterville - PA 17776",
    "county": "Lycoming County",
    "state": "PA",
    "city": "Waterville"
  },
  {
    "value": "17777",
    "label": "Watsontown - PA 17777",
    "county": "Northumberland County",
    "state": "PA",
    "city": "Watsontown"
  },
  {
    "value": "17778",
    "label": "Westport - PA 17778",
    "county": "Clinton County",
    "state": "PA",
    "city": "Westport"
  },
  {
    "value": "17801",
    "label": "Sunbury - PA 17801",
    "county": "Northumberland County",
    "state": "PA",
    "city": "Sunbury"
  },
  {
    "value": "17810",
    "label": "Allenwood - PA 17810",
    "county": "Union County",
    "state": "PA",
    "city": "Allenwood"
  },
  {
    "value": "17812",
    "label": "Beaver Springs - PA 17812",
    "county": "Snyder County",
    "state": "PA",
    "city": "Beaver Springs"
  },
  {
    "value": "17813",
    "label": "Beavertown - PA 17813",
    "county": "Snyder County",
    "state": "PA",
    "city": "Beavertown"
  },
  {
    "value": "17814",
    "label": "Benton - PA 17814",
    "county": "Columbia County",
    "state": "PA",
    "city": "Benton"
  },
  {
    "value": "17815",
    "label": "Bloomsburg - PA 17815",
    "county": "Columbia County",
    "state": "PA",
    "city": "Bloomsburg"
  },
  {
    "value": "17820",
    "label": "Catawissa - PA 17820",
    "county": "Columbia County",
    "state": "PA",
    "city": "Catawissa"
  },
  {
    "value": "17821",
    "label": "Danville - PA 17821",
    "county": "Montour County",
    "state": "PA",
    "city": "Danville"
  },
  {
    "value": "17823",
    "label": "Dornsife - PA 17823",
    "county": "Northumberland County",
    "state": "PA",
    "city": "Dornsife"
  },
  {
    "value": "17824",
    "label": "Elysburg - PA 17824",
    "county": "Northumberland County",
    "state": "PA",
    "city": "Elysburg"
  },
  {
    "value": "17827",
    "label": "Freeburg - PA 17827",
    "county": "Snyder County",
    "state": "PA",
    "city": "Freeburg"
  },
  {
    "value": "17830",
    "label": "Herndon - PA 17830",
    "county": "Northumberland County",
    "state": "PA",
    "city": "Herndon"
  },
  {
    "value": "17834",
    "label": "Kulpmont - PA 17834",
    "county": "Northumberland County",
    "state": "PA",
    "city": "Kulpmont"
  },
  {
    "value": "17836",
    "label": "Leck Kill - PA 17836",
    "county": "Northumberland County",
    "state": "PA",
    "city": "Leck Kill"
  },
  {
    "value": "17837",
    "label": "Lewisburg - PA 17837",
    "county": "Union County",
    "state": "PA",
    "city": "Lewisburg"
  },
  {
    "value": "17840",
    "label": "Locust Gap - PA 17840",
    "county": "Northumberland County",
    "state": "PA",
    "city": "Locust Gap"
  },
  {
    "value": "17841",
    "label": "Mc Clure - PA 17841",
    "county": "Mifflin County",
    "state": "PA",
    "city": "Mc Clure"
  },
  {
    "value": "17842",
    "label": "Middleburg - PA 17842",
    "county": "Snyder County",
    "state": "PA",
    "city": "Middleburg"
  },
  {
    "value": "17843",
    "label": "Beaver Springs - PA 17843",
    "county": "Snyder County",
    "state": "PA",
    "city": "Beaver Springs"
  },
  {
    "value": "17844",
    "label": "Mifflinburg - PA 17844",
    "county": "Union County",
    "state": "PA",
    "city": "Mifflinburg"
  },
  {
    "value": "17845",
    "label": "Millmont - PA 17845",
    "county": "Union County",
    "state": "PA",
    "city": "Millmont"
  },
  {
    "value": "17846",
    "label": "Millville - PA 17846",
    "county": "Columbia County",
    "state": "PA",
    "city": "Millville"
  },
  {
    "value": "17847",
    "label": "Milton - PA 17847",
    "county": "Northumberland County",
    "state": "PA",
    "city": "Milton"
  },
  {
    "value": "17851",
    "label": "Mount Carmel - PA 17851",
    "county": "Northumberland County",
    "state": "PA",
    "city": "Mount Carmel"
  },
  {
    "value": "17853",
    "label": "Mount Pleasant Mills - PA 17853",
    "county": "Snyder County",
    "state": "PA",
    "city": "Mount Pleasant Mills"
  },
  {
    "value": "17855",
    "label": "New Berlin - PA 17855",
    "county": "Union County",
    "state": "PA",
    "city": "New Berlin"
  },
  {
    "value": "17856",
    "label": "New Columbia - PA 17856",
    "county": "Union County",
    "state": "PA",
    "city": "New Columbia"
  },
  {
    "value": "17857",
    "label": "Northumberland - PA 17857",
    "county": "Northumberland County",
    "state": "PA",
    "city": "Northumberland"
  },
  {
    "value": "17859",
    "label": "Orangeville - PA 17859",
    "county": "Columbia County",
    "state": "PA",
    "city": "Orangeville"
  },
  {
    "value": "17860",
    "label": "Paxinos - PA 17860",
    "county": "Northumberland County",
    "state": "PA",
    "city": "Paxinos"
  },
  {
    "value": "17864",
    "label": "Port Trevorton - PA 17864",
    "county": "Snyder County",
    "state": "PA",
    "city": "Port Trevorton"
  },
  {
    "value": "17866",
    "label": "Coal Township - PA 17866",
    "county": "Northumberland County",
    "state": "PA",
    "city": "Coal Township"
  },
  {
    "value": "17867",
    "label": "Rebuck - PA 17867",
    "county": "Northumberland County",
    "state": "PA",
    "city": "Rebuck"
  },
  {
    "value": "17870",
    "label": "Selinsgrove - PA 17870",
    "county": "Snyder County",
    "state": "PA",
    "city": "Selinsgrove"
  },
  {
    "value": "17872",
    "label": "Shamokin - PA 17872",
    "county": "Northumberland County",
    "state": "PA",
    "city": "Shamokin"
  },
  {
    "value": "17876",
    "label": "Shamokin Dam - PA 17876",
    "county": "Snyder County",
    "state": "PA",
    "city": "Shamokin Dam"
  },
  {
    "value": "17878",
    "label": "Stillwater - PA 17878",
    "county": "Columbia County",
    "state": "PA",
    "city": "Stillwater"
  },
  {
    "value": "17881",
    "label": "Trevorton - PA 17881",
    "county": "Northumberland County",
    "state": "PA",
    "city": "Trevorton"
  },
  {
    "value": "17886",
    "label": "West Milton - PA 17886",
    "county": "Union County",
    "state": "PA",
    "city": "West Milton"
  },
  {
    "value": "17888",
    "label": "Wilburton - PA 17888",
    "county": "Columbia County",
    "state": "PA",
    "city": "Wilburton"
  },
  {
    "value": "17889",
    "label": "Winfield - PA 17889",
    "county": "Union County",
    "state": "PA",
    "city": "Winfield"
  },
  {
    "value": "17901",
    "label": "Pottsville - PA 17901",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Pottsville"
  },
  {
    "value": "17921",
    "label": "Ashland - PA 17921",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Ashland"
  },
  {
    "value": "17922",
    "label": "Auburn - PA 17922",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Auburn"
  },
  {
    "value": "17925",
    "label": "Brockton - PA 17925",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Brockton"
  },
  {
    "value": "17929",
    "label": "Cressona - PA 17929",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Cressona"
  },
  {
    "value": "17931",
    "label": "Frackville - PA 17931",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Frackville"
  },
  {
    "value": "17934",
    "label": "Gilberton - PA 17934",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Gilberton"
  },
  {
    "value": "17935",
    "label": "Girardville - PA 17935",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Girardville"
  },
  {
    "value": "17938",
    "label": "Hegins - PA 17938",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Hegins"
  },
  {
    "value": "17941",
    "label": "Klingerstown - PA 17941",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Klingerstown"
  },
  {
    "value": "17948",
    "label": "Mahanoy City - PA 17948",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Mahanoy City"
  },
  {
    "value": "17952",
    "label": "Mary D - PA 17952",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Mary D"
  },
  {
    "value": "17953",
    "label": "Middleport - PA 17953",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Middleport"
  },
  {
    "value": "17954",
    "label": "Minersville - PA 17954",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Minersville"
  },
  {
    "value": "17957",
    "label": "Muir - PA 17957",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Muir"
  },
  {
    "value": "17959",
    "label": "New Philadelphia - PA 17959",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "New Philadelphia"
  },
  {
    "value": "17960",
    "label": "New Ringgold - PA 17960",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "New Ringgold"
  },
  {
    "value": "17961",
    "label": "Orwigsburg - PA 17961",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Orwigsburg"
  },
  {
    "value": "17963",
    "label": "Pine Grove - PA 17963",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Pine Grove"
  },
  {
    "value": "17964",
    "label": "Pitman - PA 17964",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Pitman"
  },
  {
    "value": "17965",
    "label": "Port Carbon - PA 17965",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Port Carbon"
  },
  {
    "value": "17967",
    "label": "Ringtown - PA 17967",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Ringtown"
  },
  {
    "value": "17968",
    "label": "Sacramento - PA 17968",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Sacramento"
  },
  {
    "value": "17970",
    "label": "Saint Clair - PA 17970",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Saint Clair"
  },
  {
    "value": "17972",
    "label": "Schuylkill Haven - PA 17972",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Schuylkill Haven"
  },
  {
    "value": "17976",
    "label": "Shenandoah - PA 17976",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Shenandoah"
  },
  {
    "value": "17978",
    "label": "Spring Glen - PA 17978",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Spring Glen"
  },
  {
    "value": "17980",
    "label": "Tower City - PA 17980",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Tower City"
  },
  {
    "value": "17981",
    "label": "Tremont - PA 17981",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Tremont"
  },
  {
    "value": "17982",
    "label": "Tuscarora - PA 17982",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Tuscarora"
  },
  {
    "value": "17983",
    "label": "Valley View - PA 17983",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Valley View"
  },
  {
    "value": "17985",
    "label": "Zion Grove - PA 17985",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Zion Grove"
  },
  {
    "value": "18011",
    "label": "Alburtis - PA 18011",
    "county": "Lehigh County",
    "state": "PA",
    "city": "Alburtis"
  },
  {
    "value": "18013",
    "label": "Bangor - PA 18013",
    "county": "Northampton County",
    "state": "PA",
    "city": "Bangor"
  },
  {
    "value": "18014",
    "label": "Bath - PA 18014",
    "county": "Northampton County",
    "state": "PA",
    "city": "Bath"
  },
  {
    "value": "18015",
    "label": "Bethlehem - PA 18015",
    "county": "Northampton County",
    "state": "PA",
    "city": "Bethlehem"
  },
  {
    "value": "18016",
    "label": "Bethlehem - PA 18016",
    "county": "Lehigh County",
    "state": "PA",
    "city": "Bethlehem"
  },
  {
    "value": "18017",
    "label": "Bethlehem - PA 18017",
    "county": "Northampton County",
    "state": "PA",
    "city": "Bethlehem"
  },
  {
    "value": "18018",
    "label": "Bethlehem - PA 18018",
    "county": "Lehigh County",
    "state": "PA",
    "city": "Bethlehem"
  },
  {
    "value": "18020",
    "label": "Bethlehem - PA 18020",
    "county": "Northampton County",
    "state": "PA",
    "city": "Bethlehem"
  },
  {
    "value": "18031",
    "label": "Breinigsville - PA 18031",
    "county": "Lehigh County",
    "state": "PA",
    "city": "Breinigsville"
  },
  {
    "value": "18032",
    "label": "Catasauqua - PA 18032",
    "county": "Lehigh County",
    "state": "PA",
    "city": "Catasauqua"
  },
  {
    "value": "18034",
    "label": "Center Valley - PA 18034",
    "county": "Lehigh County",
    "state": "PA",
    "city": "Center Valley"
  },
  {
    "value": "18035",
    "label": "Cherryville - PA 18035",
    "county": "Northampton County",
    "state": "PA",
    "city": "Cherryville"
  },
  {
    "value": "18036",
    "label": "Coopersburg - PA 18036",
    "county": "Lehigh County",
    "state": "PA",
    "city": "Coopersburg"
  },
  {
    "value": "18037",
    "label": "Coplay - PA 18037",
    "county": "Lehigh County",
    "state": "PA",
    "city": "Coplay"
  },
  {
    "value": "18038",
    "label": "Danielsville - PA 18038",
    "county": "Northampton County",
    "state": "PA",
    "city": "Danielsville"
  },
  {
    "value": "18040",
    "label": "Easton - PA 18040",
    "county": "Northampton County",
    "state": "PA",
    "city": "Easton"
  },
  {
    "value": "18041",
    "label": "East Greenville - PA 18041",
    "county": "Montgomery County",
    "state": "PA",
    "city": "East Greenville"
  },
  {
    "value": "18042",
    "label": "Easton - PA 18042",
    "county": "Northampton County",
    "state": "PA",
    "city": "Easton"
  },
  {
    "value": "18045",
    "label": "Easton - PA 18045",
    "county": "Northampton County",
    "state": "PA",
    "city": "Easton"
  },
  {
    "value": "18049",
    "label": "Emmaus - PA 18049",
    "county": "Lehigh County",
    "state": "PA",
    "city": "Emmaus"
  },
  {
    "value": "18051",
    "label": "Fogelsville - PA 18051",
    "county": "Lehigh County",
    "state": "PA",
    "city": "Fogelsville"
  },
  {
    "value": "18052",
    "label": "Whitehall - PA 18052",
    "county": "Lehigh County",
    "state": "PA",
    "city": "Whitehall"
  },
  {
    "value": "18053",
    "label": "Germansville - PA 18053",
    "county": "Lehigh County",
    "state": "PA",
    "city": "Germansville"
  },
  {
    "value": "18054",
    "label": "Green Lane - PA 18054",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Green Lane"
  },
  {
    "value": "18055",
    "label": "Hellertown - PA 18055",
    "county": "Northampton County",
    "state": "PA",
    "city": "Hellertown"
  },
  {
    "value": "18056",
    "label": "Hereford - PA 18056",
    "county": "Berks County",
    "state": "PA",
    "city": "Hereford"
  },
  {
    "value": "18058",
    "label": "Kunkletown - PA 18058",
    "county": "Monroe County",
    "state": "PA",
    "city": "Kunkletown"
  },
  {
    "value": "18059",
    "label": "Laurys Station - PA 18059",
    "county": "Lehigh County",
    "state": "PA",
    "city": "Laurys Station"
  },
  {
    "value": "18062",
    "label": "Macungie - PA 18062",
    "county": "Lehigh County",
    "state": "PA",
    "city": "Macungie"
  },
  {
    "value": "18064",
    "label": "Nazareth - PA 18064",
    "county": "Northampton County",
    "state": "PA",
    "city": "Nazareth"
  },
  {
    "value": "18066",
    "label": "New Tripoli - PA 18066",
    "county": "Lehigh County",
    "state": "PA",
    "city": "New Tripoli"
  },
  {
    "value": "18067",
    "label": "Northampton - PA 18067",
    "county": "Northampton County",
    "state": "PA",
    "city": "Northampton"
  },
  {
    "value": "18069",
    "label": "Orefield - PA 18069",
    "county": "Lehigh County",
    "state": "PA",
    "city": "Orefield"
  },
  {
    "value": "18070",
    "label": "Palm - PA 18070",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Palm"
  },
  {
    "value": "18071",
    "label": "Palmerton - PA 18071",
    "county": "Carbon County",
    "state": "PA",
    "city": "Palmerton"
  },
  {
    "value": "18072",
    "label": "Pen Argyl - PA 18072",
    "county": "Northampton County",
    "state": "PA",
    "city": "Pen Argyl"
  },
  {
    "value": "18073",
    "label": "Pennsburg - PA 18073",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Pennsburg"
  },
  {
    "value": "18074",
    "label": "Perkiomenville - PA 18074",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Perkiomenville"
  },
  {
    "value": "18076",
    "label": "Red Hill - PA 18076",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Red Hill"
  },
  {
    "value": "18077",
    "label": "Riegelsville - PA 18077",
    "county": "Bucks County",
    "state": "PA",
    "city": "Riegelsville"
  },
  {
    "value": "18078",
    "label": "Schnecksville - PA 18078",
    "county": "Lehigh County",
    "state": "PA",
    "city": "Schnecksville"
  },
  {
    "value": "18080",
    "label": "Slatington - PA 18080",
    "county": "Lehigh County",
    "state": "PA",
    "city": "Slatington"
  },
  {
    "value": "18087",
    "label": "Trexlertown - PA 18087",
    "county": "Lehigh County",
    "state": "PA",
    "city": "Trexlertown"
  },
  {
    "value": "18088",
    "label": "Walnutport - PA 18088",
    "county": "Northampton County",
    "state": "PA",
    "city": "Walnutport"
  },
  {
    "value": "18091",
    "label": "Wind Gap - PA 18091",
    "county": "Northampton County",
    "state": "PA",
    "city": "Wind Gap"
  },
  {
    "value": "18092",
    "label": "Zionsville - PA 18092",
    "county": "Lehigh County",
    "state": "PA",
    "city": "Zionsville"
  },
  {
    "value": "18101",
    "label": "Allentown - PA 18101",
    "county": "Lehigh County",
    "state": "PA",
    "city": "Allentown"
  },
  {
    "value": "18102",
    "label": "Allentown - PA 18102",
    "county": "Lehigh County",
    "state": "PA",
    "city": "Allentown"
  },
  {
    "value": "18103",
    "label": "Allentown - PA 18103",
    "county": "Lehigh County",
    "state": "PA",
    "city": "Allentown"
  },
  {
    "value": "18104",
    "label": "Allentown - PA 18104",
    "county": "Lehigh County",
    "state": "PA",
    "city": "Allentown"
  },
  {
    "value": "18106",
    "label": "Allentown - PA 18106",
    "county": "Lehigh County",
    "state": "PA",
    "city": "Allentown"
  },
  {
    "value": "18109",
    "label": "Allentown - PA 18109",
    "county": "Lehigh County",
    "state": "PA",
    "city": "Allentown"
  },
  {
    "value": "18195",
    "label": "Allentown - PA 18195",
    "county": "Lehigh County",
    "state": "PA",
    "city": "Allentown"
  },
  {
    "value": "18201",
    "label": "Hazleton - PA 18201",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Hazleton"
  },
  {
    "value": "18202",
    "label": "Hazleton - PA 18202",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Hazleton"
  },
  {
    "value": "18210",
    "label": "Albrightsville - PA 18210",
    "county": "Carbon County",
    "state": "PA",
    "city": "Albrightsville"
  },
  {
    "value": "18211",
    "label": "Andreas - PA 18211",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Andreas"
  },
  {
    "value": "18214",
    "label": "Barnesville - PA 18214",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Barnesville"
  },
  {
    "value": "18216",
    "label": "Beaver Meadows - PA 18216",
    "county": "Carbon County",
    "state": "PA",
    "city": "Beaver Meadows"
  },
  {
    "value": "18218",
    "label": "Coaldale - PA 18218",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Coaldale"
  },
  {
    "value": "18222",
    "label": "Drums - PA 18222",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Drums"
  },
  {
    "value": "18224",
    "label": "Freeland - PA 18224",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Freeland"
  },
  {
    "value": "18229",
    "label": "Jim Thorpe - PA 18229",
    "county": "Carbon County",
    "state": "PA",
    "city": "Jim Thorpe"
  },
  {
    "value": "18232",
    "label": "Lansford - PA 18232",
    "county": "Carbon County",
    "state": "PA",
    "city": "Lansford"
  },
  {
    "value": "18235",
    "label": "Lehighton - PA 18235",
    "county": "Carbon County",
    "state": "PA",
    "city": "Lehighton"
  },
  {
    "value": "18237",
    "label": "Mcadoo - PA 18237",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Mcadoo"
  },
  {
    "value": "18240",
    "label": "Nesquehoning - PA 18240",
    "county": "Carbon County",
    "state": "PA",
    "city": "Nesquehoning"
  },
  {
    "value": "18241",
    "label": "Nuremberg - PA 18241",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Nuremberg"
  },
  {
    "value": "18249",
    "label": "Sugarloaf - PA 18249",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Sugarloaf"
  },
  {
    "value": "18250",
    "label": "Summit Hill - PA 18250",
    "county": "Carbon County",
    "state": "PA",
    "city": "Summit Hill"
  },
  {
    "value": "18252",
    "label": "Tamaqua - PA 18252",
    "county": "Schuylkill County",
    "state": "PA",
    "city": "Tamaqua"
  },
  {
    "value": "18255",
    "label": "Weatherly - PA 18255",
    "county": "Carbon County",
    "state": "PA",
    "city": "Weatherly"
  },
  {
    "value": "18301",
    "label": "East Stroudsburg - PA 18301",
    "county": "Monroe County",
    "state": "PA",
    "city": "East Stroudsburg"
  },
  {
    "value": "18302",
    "label": "East Stroudsburg - PA 18302",
    "county": "Monroe County",
    "state": "PA",
    "city": "East Stroudsburg"
  },
  {
    "value": "18321",
    "label": "Bartonsville - PA 18321",
    "county": "Monroe County",
    "state": "PA",
    "city": "Bartonsville"
  },
  {
    "value": "18322",
    "label": "Brodheadsville - PA 18322",
    "county": "Monroe County",
    "state": "PA",
    "city": "Brodheadsville"
  },
  {
    "value": "18324",
    "label": "Bushkill - PA 18324",
    "county": "Pike County",
    "state": "PA",
    "city": "Bushkill"
  },
  {
    "value": "18325",
    "label": "Canadensis - PA 18325",
    "county": "Monroe County",
    "state": "PA",
    "city": "Canadensis"
  },
  {
    "value": "18326",
    "label": "Cresco - PA 18326",
    "county": "Monroe County",
    "state": "PA",
    "city": "Cresco"
  },
  {
    "value": "18328",
    "label": "Dingmans Ferry - PA 18328",
    "county": "Pike County",
    "state": "PA",
    "city": "Dingmans Ferry"
  },
  {
    "value": "18330",
    "label": "Effort - PA 18330",
    "county": "Monroe County",
    "state": "PA",
    "city": "Effort"
  },
  {
    "value": "18331",
    "label": "Gilbert - PA 18331",
    "county": "Monroe County",
    "state": "PA",
    "city": "Gilbert"
  },
  {
    "value": "18332",
    "label": "Henryville - PA 18332",
    "county": "Monroe County",
    "state": "PA",
    "city": "Henryville"
  },
  {
    "value": "18333",
    "label": "Kresgeville - PA 18333",
    "county": "Monroe County",
    "state": "PA",
    "city": "Kresgeville"
  },
  {
    "value": "18334",
    "label": "Long Pond - PA 18334",
    "county": "Monroe County",
    "state": "PA",
    "city": "Long Pond"
  },
  {
    "value": "18336",
    "label": "Matamoras - PA 18336",
    "county": "Pike County",
    "state": "PA",
    "city": "Matamoras"
  },
  {
    "value": "18337",
    "label": "Milford - PA 18337",
    "county": "Pike County",
    "state": "PA",
    "city": "Milford"
  },
  {
    "value": "18343",
    "label": "Mount Bethel - PA 18343",
    "county": "Northampton County",
    "state": "PA",
    "city": "Mount Bethel"
  },
  {
    "value": "18344",
    "label": "Mount Pocono - PA 18344",
    "county": "Monroe County",
    "state": "PA",
    "city": "Mount Pocono"
  },
  {
    "value": "18346",
    "label": "Pocono Summit - PA 18346",
    "county": "Monroe County",
    "state": "PA",
    "city": "Pocono Summit"
  },
  {
    "value": "18347",
    "label": "Pocono Lake - PA 18347",
    "county": "Monroe County",
    "state": "PA",
    "city": "Pocono Lake"
  },
  {
    "value": "18350",
    "label": "Pocono Pines - PA 18350",
    "county": "Monroe County",
    "state": "PA",
    "city": "Pocono Pines"
  },
  {
    "value": "18352",
    "label": "Reeders - PA 18352",
    "county": "Monroe County",
    "state": "PA",
    "city": "Reeders"
  },
  {
    "value": "18353",
    "label": "Saylorsburg - PA 18353",
    "county": "Monroe County",
    "state": "PA",
    "city": "Saylorsburg"
  },
  {
    "value": "18354",
    "label": "Sciota - PA 18354",
    "county": "Monroe County",
    "state": "PA",
    "city": "Sciota"
  },
  {
    "value": "18355",
    "label": "Scotrun - PA 18355",
    "county": "Monroe County",
    "state": "PA",
    "city": "Scotrun"
  },
  {
    "value": "18360",
    "label": "Stroudsburg - PA 18360",
    "county": "Monroe County",
    "state": "PA",
    "city": "Stroudsburg"
  },
  {
    "value": "18370",
    "label": "Swiftwater - PA 18370",
    "county": "Monroe County",
    "state": "PA",
    "city": "Swiftwater"
  },
  {
    "value": "18371",
    "label": "Tamiment - PA 18371",
    "county": "Pike County",
    "state": "PA",
    "city": "Tamiment"
  },
  {
    "value": "18372",
    "label": "Tannersville - PA 18372",
    "county": "Monroe County",
    "state": "PA",
    "city": "Tannersville"
  },
  {
    "value": "18403",
    "label": "Archbald - PA 18403",
    "county": "Lackawanna County",
    "state": "PA",
    "city": "Archbald"
  },
  {
    "value": "18405",
    "label": "Beach Lake - PA 18405",
    "county": "Wayne County",
    "state": "PA",
    "city": "Beach Lake"
  },
  {
    "value": "18407",
    "label": "Carbondale - PA 18407",
    "county": "Lackawanna County",
    "state": "PA",
    "city": "Carbondale"
  },
  {
    "value": "18411",
    "label": "Clarks Summit - PA 18411",
    "county": "Lackawanna County",
    "state": "PA",
    "city": "Clarks Summit"
  },
  {
    "value": "18414",
    "label": "Dalton - PA 18414",
    "county": "Lackawanna County",
    "state": "PA",
    "city": "Dalton"
  },
  {
    "value": "18415",
    "label": "Damascus - PA 18415",
    "county": "Wayne County",
    "state": "PA",
    "city": "Damascus"
  },
  {
    "value": "18417",
    "label": "Equinunk - PA 18417",
    "county": "Wayne County",
    "state": "PA",
    "city": "Equinunk"
  },
  {
    "value": "18419",
    "label": "Factoryville - PA 18419",
    "county": "Wyoming County",
    "state": "PA",
    "city": "Factoryville"
  },
  {
    "value": "18421",
    "label": "Forest City - PA 18421",
    "county": "Susquehanna County",
    "state": "PA",
    "city": "Forest City"
  },
  {
    "value": "18424",
    "label": "Gouldsboro - PA 18424",
    "county": "Lackawanna County",
    "state": "PA",
    "city": "Gouldsboro"
  },
  {
    "value": "18425",
    "label": "Greeley - PA 18425",
    "county": "Pike County",
    "state": "PA",
    "city": "Greeley"
  },
  {
    "value": "18426",
    "label": "Greentown - PA 18426",
    "county": "Pike County",
    "state": "PA",
    "city": "Greentown"
  },
  {
    "value": "18428",
    "label": "Hawley - PA 18428",
    "county": "Pike County",
    "state": "PA",
    "city": "Hawley"
  },
  {
    "value": "18430",
    "label": "Herrick Center - PA 18430",
    "county": "Susquehanna County",
    "state": "PA",
    "city": "Herrick Center"
  },
  {
    "value": "18431",
    "label": "Honesdale - PA 18431",
    "county": "Wayne County",
    "state": "PA",
    "city": "Honesdale"
  },
  {
    "value": "18433",
    "label": "Jermyn - PA 18433",
    "county": "Lackawanna County",
    "state": "PA",
    "city": "Jermyn"
  },
  {
    "value": "18434",
    "label": "Jessup - PA 18434",
    "county": "Lackawanna County",
    "state": "PA",
    "city": "Jessup"
  },
  {
    "value": "18435",
    "label": "Lackawaxen - PA 18435",
    "county": "Pike County",
    "state": "PA",
    "city": "Lackawaxen"
  },
  {
    "value": "18436",
    "label": "Lake Ariel - PA 18436",
    "county": "Wayne County",
    "state": "PA",
    "city": "Lake Ariel"
  },
  {
    "value": "18437",
    "label": "Lake Como - PA 18437",
    "county": "Wayne County",
    "state": "PA",
    "city": "Lake Como"
  },
  {
    "value": "18438",
    "label": "Lakeville - PA 18438",
    "county": "Wayne County",
    "state": "PA",
    "city": "Lakeville"
  },
  {
    "value": "18439",
    "label": "Lakewood - PA 18439",
    "county": "Wayne County",
    "state": "PA",
    "city": "Lakewood"
  },
  {
    "value": "18441",
    "label": "Lenoxville - PA 18441",
    "county": "Susquehanna County",
    "state": "PA",
    "city": "Lenoxville"
  },
  {
    "value": "18443",
    "label": "Milanville - PA 18443",
    "county": "Wayne County",
    "state": "PA",
    "city": "Milanville"
  },
  {
    "value": "18444",
    "label": "Moscow - PA 18444",
    "county": "Lackawanna County",
    "state": "PA",
    "city": "Moscow"
  },
  {
    "value": "18445",
    "label": "Newfoundland - PA 18445",
    "county": "Wayne County",
    "state": "PA",
    "city": "Newfoundland"
  },
  {
    "value": "18446",
    "label": "Nicholson - PA 18446",
    "county": "Wyoming County",
    "state": "PA",
    "city": "Nicholson"
  },
  {
    "value": "18447",
    "label": "Olyphant - PA 18447",
    "county": "Lackawanna County",
    "state": "PA",
    "city": "Olyphant"
  },
  {
    "value": "18451",
    "label": "Paupack - PA 18451",
    "county": "Pike County",
    "state": "PA",
    "city": "Paupack"
  },
  {
    "value": "18452",
    "label": "Peckville - PA 18452",
    "county": "Lackawanna County",
    "state": "PA",
    "city": "Peckville"
  },
  {
    "value": "18453",
    "label": "Pleasant Mount - PA 18453",
    "county": "Wayne County",
    "state": "PA",
    "city": "Pleasant Mount"
  },
  {
    "value": "18455",
    "label": "Preston Park - PA 18455",
    "county": "Wayne County",
    "state": "PA",
    "city": "Preston Park"
  },
  {
    "value": "18456",
    "label": "Prompton - PA 18456",
    "county": "Wayne County",
    "state": "PA",
    "city": "Prompton"
  },
  {
    "value": "18458",
    "label": "Shohola - PA 18458",
    "county": "Pike County",
    "state": "PA",
    "city": "Shohola"
  },
  {
    "value": "18460",
    "label": "South Sterling - PA 18460",
    "county": "Wayne County",
    "state": "PA",
    "city": "South Sterling"
  },
  {
    "value": "18461",
    "label": "Starlight - PA 18461",
    "county": "Wayne County",
    "state": "PA",
    "city": "Starlight"
  },
  {
    "value": "18462",
    "label": "Starrucca - PA 18462",
    "county": "Wayne County",
    "state": "PA",
    "city": "Starrucca"
  },
  {
    "value": "18463",
    "label": "Sterling - PA 18463",
    "county": "Wayne County",
    "state": "PA",
    "city": "Sterling"
  },
  {
    "value": "18464",
    "label": "Tafton - PA 18464",
    "county": "Pike County",
    "state": "PA",
    "city": "Tafton"
  },
  {
    "value": "18465",
    "label": "Thompson - PA 18465",
    "county": "Susquehanna County",
    "state": "PA",
    "city": "Thompson"
  },
  {
    "value": "18466",
    "label": "Tobyhanna - PA 18466",
    "county": "Monroe County",
    "state": "PA",
    "city": "Tobyhanna"
  },
  {
    "value": "18469",
    "label": "Tyler Hill - PA 18469",
    "county": "Wayne County",
    "state": "PA",
    "city": "Tyler Hill"
  },
  {
    "value": "18470",
    "label": "Union Dale - PA 18470",
    "county": "Susquehanna County",
    "state": "PA",
    "city": "Union Dale"
  },
  {
    "value": "18472",
    "label": "Waymart - PA 18472",
    "county": "Wayne County",
    "state": "PA",
    "city": "Waymart"
  },
  {
    "value": "18503",
    "label": "Scranton - PA 18503",
    "county": "Lackawanna County",
    "state": "PA",
    "city": "Scranton"
  },
  {
    "value": "18504",
    "label": "Scranton - PA 18504",
    "county": "Lackawanna County",
    "state": "PA",
    "city": "Scranton"
  },
  {
    "value": "18505",
    "label": "Scranton - PA 18505",
    "county": "Lackawanna County",
    "state": "PA",
    "city": "Scranton"
  },
  {
    "value": "18507",
    "label": "Moosic - PA 18507",
    "county": "Lackawanna County",
    "state": "PA",
    "city": "Moosic"
  },
  {
    "value": "18508",
    "label": "Scranton - PA 18508",
    "county": "Lackawanna County",
    "state": "PA",
    "city": "Scranton"
  },
  {
    "value": "18509",
    "label": "Scranton - PA 18509",
    "county": "Lackawanna County",
    "state": "PA",
    "city": "Scranton"
  },
  {
    "value": "18510",
    "label": "Scranton - PA 18510",
    "county": "Lackawanna County",
    "state": "PA",
    "city": "Scranton"
  },
  {
    "value": "18512",
    "label": "Scranton - PA 18512",
    "county": "Lackawanna County",
    "state": "PA",
    "city": "Scranton"
  },
  {
    "value": "18515",
    "label": "Scranton - PA 18515",
    "county": "Lackawanna County",
    "state": "PA",
    "city": "Scranton"
  },
  {
    "value": "18517",
    "label": "Taylor - PA 18517",
    "county": "Lackawanna County",
    "state": "PA",
    "city": "Taylor"
  },
  {
    "value": "18518",
    "label": "Old Forge - PA 18518",
    "county": "Lackawanna County",
    "state": "PA",
    "city": "Old Forge"
  },
  {
    "value": "18519",
    "label": "Scranton - PA 18519",
    "county": "Lackawanna County",
    "state": "PA",
    "city": "Scranton"
  },
  {
    "value": "18603",
    "label": "Berwick - PA 18603",
    "county": "Columbia County",
    "state": "PA",
    "city": "Berwick"
  },
  {
    "value": "18610",
    "label": "Blakeslee - PA 18610",
    "county": "Monroe County",
    "state": "PA",
    "city": "Blakeslee"
  },
  {
    "value": "18612",
    "label": "Dallas - PA 18612",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Dallas"
  },
  {
    "value": "18614",
    "label": "Dushore - PA 18614",
    "county": "Sullivan County",
    "state": "PA",
    "city": "Dushore"
  },
  {
    "value": "18615",
    "label": "Falls - PA 18615",
    "county": "Wyoming County",
    "state": "PA",
    "city": "Falls"
  },
  {
    "value": "18616",
    "label": "Forksville - PA 18616",
    "county": "Sullivan County",
    "state": "PA",
    "city": "Forksville"
  },
  {
    "value": "18617",
    "label": "Glen Lyon - PA 18617",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Glen Lyon"
  },
  {
    "value": "18618",
    "label": "Harveys Lake - PA 18618",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Harveys Lake"
  },
  {
    "value": "18619",
    "label": "Hillsgrove - PA 18619",
    "county": "Sullivan County",
    "state": "PA",
    "city": "Hillsgrove"
  },
  {
    "value": "18621",
    "label": "Hunlock Creek - PA 18621",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Hunlock Creek"
  },
  {
    "value": "18622",
    "label": "Huntington Mills - PA 18622",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Huntington Mills"
  },
  {
    "value": "18623",
    "label": "Laceyville - PA 18623",
    "county": "Wyoming County",
    "state": "PA",
    "city": "Laceyville"
  },
  {
    "value": "18628",
    "label": "Lopez - PA 18628",
    "county": "Sullivan County",
    "state": "PA",
    "city": "Lopez"
  },
  {
    "value": "18629",
    "label": "Mehoopany - PA 18629",
    "county": "Wyoming County",
    "state": "PA",
    "city": "Mehoopany"
  },
  {
    "value": "18630",
    "label": "Meshoppen - PA 18630",
    "county": "Wyoming County",
    "state": "PA",
    "city": "Meshoppen"
  },
  {
    "value": "18632",
    "label": "Mildred - PA 18632",
    "county": "Sullivan County",
    "state": "PA",
    "city": "Mildred"
  },
  {
    "value": "18634",
    "label": "Nanticoke - PA 18634",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Nanticoke"
  },
  {
    "value": "18635",
    "label": "Nescopeck - PA 18635",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Nescopeck"
  },
  {
    "value": "18636",
    "label": "Noxen - PA 18636",
    "county": "Wyoming County",
    "state": "PA",
    "city": "Noxen"
  },
  {
    "value": "18640",
    "label": "Pittston - PA 18640",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Pittston"
  },
  {
    "value": "18641",
    "label": "Pittston - PA 18641",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Pittston"
  },
  {
    "value": "18642",
    "label": "Duryea - PA 18642",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Duryea"
  },
  {
    "value": "18643",
    "label": "Pittston - PA 18643",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Pittston"
  },
  {
    "value": "18644",
    "label": "Wyoming - PA 18644",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Wyoming"
  },
  {
    "value": "18651",
    "label": "Plymouth - PA 18651",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Plymouth"
  },
  {
    "value": "18655",
    "label": "Shickshinny - PA 18655",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Shickshinny"
  },
  {
    "value": "18656",
    "label": "Sweet Valley - PA 18656",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Sweet Valley"
  },
  {
    "value": "18657",
    "label": "Tunkhannock - PA 18657",
    "county": "Wyoming County",
    "state": "PA",
    "city": "Tunkhannock"
  },
  {
    "value": "18660",
    "label": "Wapwallopen - PA 18660",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Wapwallopen"
  },
  {
    "value": "18661",
    "label": "White Haven - PA 18661",
    "county": "Luzerne County",
    "state": "PA",
    "city": "White Haven"
  },
  {
    "value": "18701",
    "label": "Wilkes Barre - PA 18701",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Wilkes Barre"
  },
  {
    "value": "18702",
    "label": "Wilkes Barre - PA 18702",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Wilkes Barre"
  },
  {
    "value": "18704",
    "label": "Kingston - PA 18704",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Kingston"
  },
  {
    "value": "18705",
    "label": "Wilkes Barre - PA 18705",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Wilkes Barre"
  },
  {
    "value": "18706",
    "label": "Wilkes Barre - PA 18706",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Wilkes Barre"
  },
  {
    "value": "18707",
    "label": "Mountain Top - PA 18707",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Mountain Top"
  },
  {
    "value": "18708",
    "label": "Shavertown - PA 18708",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Shavertown"
  },
  {
    "value": "18709",
    "label": "Luzerne - PA 18709",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Luzerne"
  },
  {
    "value": "18711",
    "label": "Wilkes Barre - PA 18711",
    "county": "Luzerne County",
    "state": "PA",
    "city": "Wilkes Barre"
  },
  {
    "value": "18801",
    "label": "Montrose - PA 18801",
    "county": "Susquehanna County",
    "state": "PA",
    "city": "Montrose"
  },
  {
    "value": "18810",
    "label": "Athens - PA 18810",
    "county": "Bradford County",
    "state": "PA",
    "city": "Athens"
  },
  {
    "value": "18812",
    "label": "Brackney - PA 18812",
    "county": "Susquehanna County",
    "state": "PA",
    "city": "Brackney"
  },
  {
    "value": "18817",
    "label": "East Smithfield - PA 18817",
    "county": "Bradford County",
    "state": "PA",
    "city": "East Smithfield"
  },
  {
    "value": "18818",
    "label": "Friendsville - PA 18818",
    "county": "Susquehanna County",
    "state": "PA",
    "city": "Friendsville"
  },
  {
    "value": "18821",
    "label": "Great Bend - PA 18821",
    "county": "Susquehanna County",
    "state": "PA",
    "city": "Great Bend"
  },
  {
    "value": "18822",
    "label": "Hallstead - PA 18822",
    "county": "Susquehanna County",
    "state": "PA",
    "city": "Hallstead"
  },
  {
    "value": "18823",
    "label": "Harford - PA 18823",
    "county": "Susquehanna County",
    "state": "PA",
    "city": "Harford"
  },
  {
    "value": "18824",
    "label": "Hop Bottom - PA 18824",
    "county": "Susquehanna County",
    "state": "PA",
    "city": "Hop Bottom"
  },
  {
    "value": "18825",
    "label": "Jackson - PA 18825",
    "county": "Susquehanna County",
    "state": "PA",
    "city": "Jackson"
  },
  {
    "value": "18826",
    "label": "Kingsley - PA 18826",
    "county": "Susquehanna County",
    "state": "PA",
    "city": "Kingsley"
  },
  {
    "value": "18828",
    "label": "Lawton - PA 18828",
    "county": "Susquehanna County",
    "state": "PA",
    "city": "Lawton"
  },
  {
    "value": "18829",
    "label": "Le Raysville - PA 18829",
    "county": "Bradford County",
    "state": "PA",
    "city": "Le Raysville"
  },
  {
    "value": "18830",
    "label": "Little Meadows - PA 18830",
    "county": "Susquehanna County",
    "state": "PA",
    "city": "Little Meadows"
  },
  {
    "value": "18831",
    "label": "Milan - PA 18831",
    "county": "Bradford County",
    "state": "PA",
    "city": "Milan"
  },
  {
    "value": "18832",
    "label": "Monroeton - PA 18832",
    "county": "Bradford County",
    "state": "PA",
    "city": "Monroeton"
  },
  {
    "value": "18833",
    "label": "New Albany - PA 18833",
    "county": "Bradford County",
    "state": "PA",
    "city": "New Albany"
  },
  {
    "value": "18834",
    "label": "New Milford - PA 18834",
    "county": "Susquehanna County",
    "state": "PA",
    "city": "New Milford"
  },
  {
    "value": "18837",
    "label": "Rome - PA 18837",
    "county": "Bradford County",
    "state": "PA",
    "city": "Rome"
  },
  {
    "value": "18840",
    "label": "Sayre - PA 18840",
    "county": "Bradford County",
    "state": "PA",
    "city": "Sayre"
  },
  {
    "value": "18842",
    "label": "South Gibson - PA 18842",
    "county": "Susquehanna County",
    "state": "PA",
    "city": "South Gibson"
  },
  {
    "value": "18844",
    "label": "Springville - PA 18844",
    "county": "Susquehanna County",
    "state": "PA",
    "city": "Springville"
  },
  {
    "value": "18845",
    "label": "Stevensville - PA 18845",
    "county": "Bradford County",
    "state": "PA",
    "city": "Stevensville"
  },
  {
    "value": "18846",
    "label": "Sugar Run - PA 18846",
    "county": "Bradford County",
    "state": "PA",
    "city": "Sugar Run"
  },
  {
    "value": "18847",
    "label": "Susquehanna - PA 18847",
    "county": "Susquehanna County",
    "state": "PA",
    "city": "Susquehanna"
  },
  {
    "value": "18848",
    "label": "Towanda - PA 18848",
    "county": "Bradford County",
    "state": "PA",
    "city": "Towanda"
  },
  {
    "value": "18850",
    "label": "Ulster - PA 18850",
    "county": "Bradford County",
    "state": "PA",
    "city": "Ulster"
  },
  {
    "value": "18851",
    "label": "Warren Center - PA 18851",
    "county": "Bradford County",
    "state": "PA",
    "city": "Warren Center"
  },
  {
    "value": "18853",
    "label": "Wyalusing - PA 18853",
    "county": "Bradford County",
    "state": "PA",
    "city": "Wyalusing"
  },
  {
    "value": "18854",
    "label": "Wysox - PA 18854",
    "county": "Bradford County",
    "state": "PA",
    "city": "Wysox"
  },
  {
    "value": "18901",
    "label": "Doylestown - PA 18901",
    "county": "Bucks County",
    "state": "PA",
    "city": "Doylestown"
  },
  {
    "value": "18902",
    "label": "Doylestown - PA 18902",
    "county": "Bucks County",
    "state": "PA",
    "city": "Doylestown"
  },
  {
    "value": "18913",
    "label": "Carversville - PA 18913",
    "county": "Bucks County",
    "state": "PA",
    "city": "Carversville"
  },
  {
    "value": "18914",
    "label": "Chalfont - PA 18914",
    "county": "Bucks County",
    "state": "PA",
    "city": "Chalfont"
  },
  {
    "value": "18915",
    "label": "Colmar - PA 18915",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Colmar"
  },
  {
    "value": "18917",
    "label": "Dublin - PA 18917",
    "county": "Bucks County",
    "state": "PA",
    "city": "Dublin"
  },
  {
    "value": "18920",
    "label": "Erwinna - PA 18920",
    "county": "Bucks County",
    "state": "PA",
    "city": "Erwinna"
  },
  {
    "value": "18923",
    "label": "Fountainville - PA 18923",
    "county": "Bucks County",
    "state": "PA",
    "city": "Fountainville"
  },
  {
    "value": "18925",
    "label": "Furlong - PA 18925",
    "county": "Bucks County",
    "state": "PA",
    "city": "Furlong"
  },
  {
    "value": "18927",
    "label": "Hilltown - PA 18927",
    "county": "Bucks County",
    "state": "PA",
    "city": "Hilltown"
  },
  {
    "value": "18929",
    "label": "Jamison - PA 18929",
    "county": "Bucks County",
    "state": "PA",
    "city": "Jamison"
  },
  {
    "value": "18930",
    "label": "Kintnersville - PA 18930",
    "county": "Bucks County",
    "state": "PA",
    "city": "Kintnersville"
  },
  {
    "value": "18932",
    "label": "Line Lexington - PA 18932",
    "county": "Bucks County",
    "state": "PA",
    "city": "Line Lexington"
  },
  {
    "value": "18933",
    "label": "Lumberville - PA 18933",
    "county": "Bucks County",
    "state": "PA",
    "city": "Lumberville"
  },
  {
    "value": "18934",
    "label": "Mechanicsville - PA 18934",
    "county": "Bucks County",
    "state": "PA",
    "city": "Mechanicsville"
  },
  {
    "value": "18936",
    "label": "Montgomeryville - PA 18936",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Montgomeryville"
  },
  {
    "value": "18938",
    "label": "New Hope - PA 18938",
    "county": "Bucks County",
    "state": "PA",
    "city": "New Hope"
  },
  {
    "value": "18940",
    "label": "Newtown - PA 18940",
    "county": "Bucks County",
    "state": "PA",
    "city": "Newtown"
  },
  {
    "value": "18942",
    "label": "Ottsville - PA 18942",
    "county": "Bucks County",
    "state": "PA",
    "city": "Ottsville"
  },
  {
    "value": "18944",
    "label": "Perkasie - PA 18944",
    "county": "Bucks County",
    "state": "PA",
    "city": "Perkasie"
  },
  {
    "value": "18947",
    "label": "Pipersville - PA 18947",
    "county": "Bucks County",
    "state": "PA",
    "city": "Pipersville"
  },
  {
    "value": "18951",
    "label": "Quakertown - PA 18951",
    "county": "Bucks County",
    "state": "PA",
    "city": "Quakertown"
  },
  {
    "value": "18954",
    "label": "Richboro - PA 18954",
    "county": "Bucks County",
    "state": "PA",
    "city": "Richboro"
  },
  {
    "value": "18955",
    "label": "Richlandtown - PA 18955",
    "county": "Bucks County",
    "state": "PA",
    "city": "Richlandtown"
  },
  {
    "value": "18960",
    "label": "Sellersville - PA 18960",
    "county": "Bucks County",
    "state": "PA",
    "city": "Sellersville"
  },
  {
    "value": "18964",
    "label": "Souderton - PA 18964",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Souderton"
  },
  {
    "value": "18966",
    "label": "Southampton - PA 18966",
    "county": "Bucks County",
    "state": "PA",
    "city": "Southampton"
  },
  {
    "value": "18969",
    "label": "Telford - PA 18969",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Telford"
  },
  {
    "value": "18972",
    "label": "Upper Black Eddy - PA 18972",
    "county": "Bucks County",
    "state": "PA",
    "city": "Upper Black Eddy"
  },
  {
    "value": "18974",
    "label": "Warminster - PA 18974",
    "county": "Bucks County",
    "state": "PA",
    "city": "Warminster"
  },
  {
    "value": "18976",
    "label": "Warrington - PA 18976",
    "county": "Bucks County",
    "state": "PA",
    "city": "Warrington"
  },
  {
    "value": "18977",
    "label": "Washington Crossing - PA 18977",
    "county": "Bucks County",
    "state": "PA",
    "city": "Washington Crossing"
  },
  {
    "value": "19001",
    "label": "Abington - PA 19001",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Abington"
  },
  {
    "value": "19002",
    "label": "Ambler - PA 19002",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Ambler"
  },
  {
    "value": "19003",
    "label": "Ardmore - PA 19003",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Ardmore"
  },
  {
    "value": "19004",
    "label": "Bala Cynwyd - PA 19004",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Bala Cynwyd"
  },
  {
    "value": "19006",
    "label": "Huntingdon Valley - PA 19006",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Huntingdon Valley"
  },
  {
    "value": "19007",
    "label": "Bristol - PA 19007",
    "county": "Bucks County",
    "state": "PA",
    "city": "Bristol"
  },
  {
    "value": "19008",
    "label": "Broomall - PA 19008",
    "county": "Delaware County",
    "state": "PA",
    "city": "Broomall"
  },
  {
    "value": "19010",
    "label": "Bryn Mawr - PA 19010",
    "county": "Delaware County",
    "state": "PA",
    "city": "Bryn Mawr"
  },
  {
    "value": "19012",
    "label": "Cheltenham - PA 19012",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Cheltenham"
  },
  {
    "value": "19013",
    "label": "Chester - PA 19013",
    "county": "Delaware County",
    "state": "PA",
    "city": "Chester"
  },
  {
    "value": "19014",
    "label": "Aston - PA 19014",
    "county": "Delaware County",
    "state": "PA",
    "city": "Aston"
  },
  {
    "value": "19015",
    "label": "Brookhaven - PA 19015",
    "county": "Delaware County",
    "state": "PA",
    "city": "Brookhaven"
  },
  {
    "value": "19018",
    "label": "Clifton Heights - PA 19018",
    "county": "Delaware County",
    "state": "PA",
    "city": "Clifton Heights"
  },
  {
    "value": "19020",
    "label": "Bensalem - PA 19020",
    "county": "Bucks County",
    "state": "PA",
    "city": "Bensalem"
  },
  {
    "value": "19021",
    "label": "Croydon - PA 19021",
    "county": "Bucks County",
    "state": "PA",
    "city": "Croydon"
  },
  {
    "value": "19022",
    "label": "Crum Lynne - PA 19022",
    "county": "Delaware County",
    "state": "PA",
    "city": "Crum Lynne"
  },
  {
    "value": "19023",
    "label": "Darby - PA 19023",
    "county": "Delaware County",
    "state": "PA",
    "city": "Darby"
  },
  {
    "value": "19025",
    "label": "Dresher - PA 19025",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Dresher"
  },
  {
    "value": "19026",
    "label": "Drexel Hill - PA 19026",
    "county": "Delaware County",
    "state": "PA",
    "city": "Drexel Hill"
  },
  {
    "value": "19027",
    "label": "Elkins Park - PA 19027",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Elkins Park"
  },
  {
    "value": "19029",
    "label": "Essington - PA 19029",
    "county": "Delaware County",
    "state": "PA",
    "city": "Essington"
  },
  {
    "value": "19030",
    "label": "Fairless Hills - PA 19030",
    "county": "Bucks County",
    "state": "PA",
    "city": "Fairless Hills"
  },
  {
    "value": "19031",
    "label": "Flourtown - PA 19031",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Flourtown"
  },
  {
    "value": "19032",
    "label": "Folcroft - PA 19032",
    "county": "Delaware County",
    "state": "PA",
    "city": "Folcroft"
  },
  {
    "value": "19033",
    "label": "Folsom - PA 19033",
    "county": "Delaware County",
    "state": "PA",
    "city": "Folsom"
  },
  {
    "value": "19034",
    "label": "Fort Washington - PA 19034",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Fort Washington"
  },
  {
    "value": "19035",
    "label": "Gladwyne - PA 19035",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Gladwyne"
  },
  {
    "value": "19036",
    "label": "Glenolden - PA 19036",
    "county": "Delaware County",
    "state": "PA",
    "city": "Glenolden"
  },
  {
    "value": "19038",
    "label": "Glenside - PA 19038",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Glenside"
  },
  {
    "value": "19040",
    "label": "Hatboro - PA 19040",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Hatboro"
  },
  {
    "value": "19041",
    "label": "Haverford - PA 19041",
    "county": "Delaware County",
    "state": "PA",
    "city": "Haverford"
  },
  {
    "value": "19043",
    "label": "Holmes - PA 19043",
    "county": "Delaware County",
    "state": "PA",
    "city": "Holmes"
  },
  {
    "value": "19044",
    "label": "Horsham - PA 19044",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Horsham"
  },
  {
    "value": "19046",
    "label": "Jenkintown - PA 19046",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Jenkintown"
  },
  {
    "value": "19047",
    "label": "Langhorne - PA 19047",
    "county": "Bucks County",
    "state": "PA",
    "city": "Langhorne"
  },
  {
    "value": "19050",
    "label": "Lansdowne - PA 19050",
    "county": "Delaware County",
    "state": "PA",
    "city": "Lansdowne"
  },
  {
    "value": "19053",
    "label": "Feasterville Trevose - PA 19053",
    "county": "Bucks County",
    "state": "PA",
    "city": "Feasterville Trevose"
  },
  {
    "value": "19054",
    "label": "Levittown - PA 19054",
    "county": "Bucks County",
    "state": "PA",
    "city": "Levittown"
  },
  {
    "value": "19055",
    "label": "Levittown - PA 19055",
    "county": "Bucks County",
    "state": "PA",
    "city": "Levittown"
  },
  {
    "value": "19056",
    "label": "Levittown - PA 19056",
    "county": "Bucks County",
    "state": "PA",
    "city": "Levittown"
  },
  {
    "value": "19057",
    "label": "Levittown - PA 19057",
    "county": "Bucks County",
    "state": "PA",
    "city": "Levittown"
  },
  {
    "value": "19060",
    "label": "Garnet Valley - PA 19060",
    "county": "Delaware County",
    "state": "PA",
    "city": "Garnet Valley"
  },
  {
    "value": "19061",
    "label": "Marcus Hook - PA 19061",
    "county": "Delaware County",
    "state": "PA",
    "city": "Marcus Hook"
  },
  {
    "value": "19063",
    "label": "Media - PA 19063",
    "county": "Delaware County",
    "state": "PA",
    "city": "Media"
  },
  {
    "value": "19064",
    "label": "Springfield - PA 19064",
    "county": "Delaware County",
    "state": "PA",
    "city": "Springfield"
  },
  {
    "value": "19066",
    "label": "Merion Station - PA 19066",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Merion Station"
  },
  {
    "value": "19067",
    "label": "Morrisville - PA 19067",
    "county": "Bucks County",
    "state": "PA",
    "city": "Morrisville"
  },
  {
    "value": "19070",
    "label": "Morton - PA 19070",
    "county": "Delaware County",
    "state": "PA",
    "city": "Morton"
  },
  {
    "value": "19072",
    "label": "Narberth - PA 19072",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Narberth"
  },
  {
    "value": "19073",
    "label": "Newtown Square - PA 19073",
    "county": "Delaware County",
    "state": "PA",
    "city": "Newtown Square"
  },
  {
    "value": "19074",
    "label": "Norwood - PA 19074",
    "county": "Delaware County",
    "state": "PA",
    "city": "Norwood"
  },
  {
    "value": "19075",
    "label": "Oreland - PA 19075",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Oreland"
  },
  {
    "value": "19076",
    "label": "Prospect Park - PA 19076",
    "county": "Delaware County",
    "state": "PA",
    "city": "Prospect Park"
  },
  {
    "value": "19078",
    "label": "Ridley Park - PA 19078",
    "county": "Delaware County",
    "state": "PA",
    "city": "Ridley Park"
  },
  {
    "value": "19079",
    "label": "Sharon Hill - PA 19079",
    "county": "Delaware County",
    "state": "PA",
    "city": "Sharon Hill"
  },
  {
    "value": "19081",
    "label": "Swarthmore - PA 19081",
    "county": "Delaware County",
    "state": "PA",
    "city": "Swarthmore"
  },
  {
    "value": "19082",
    "label": "Upper Darby - PA 19082",
    "county": "Delaware County",
    "state": "PA",
    "city": "Upper Darby"
  },
  {
    "value": "19083",
    "label": "Havertown - PA 19083",
    "county": "Delaware County",
    "state": "PA",
    "city": "Havertown"
  },
  {
    "value": "19085",
    "label": "Villanova - PA 19085",
    "county": "Delaware County",
    "state": "PA",
    "city": "Villanova"
  },
  {
    "value": "19086",
    "label": "Wallingford - PA 19086",
    "county": "Delaware County",
    "state": "PA",
    "city": "Wallingford"
  },
  {
    "value": "19087",
    "label": "Wayne - PA 19087",
    "county": "Chester County",
    "state": "PA",
    "city": "Wayne"
  },
  {
    "value": "19090",
    "label": "Willow Grove - PA 19090",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Willow Grove"
  },
  {
    "value": "19094",
    "label": "Woodlyn - PA 19094",
    "county": "Delaware County",
    "state": "PA",
    "city": "Woodlyn"
  },
  {
    "value": "19095",
    "label": "Wyncote - PA 19095",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Wyncote"
  },
  {
    "value": "19096",
    "label": "Wynnewood - PA 19096",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Wynnewood"
  },
  {
    "value": "19102",
    "label": "Philadelphia - PA 19102",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19103",
    "label": "Philadelphia - PA 19103",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19104",
    "label": "Philadelphia - PA 19104",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19106",
    "label": "Philadelphia - PA 19106",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19107",
    "label": "Philadelphia - PA 19107",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19109",
    "label": "Philadelphia - PA 19109",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19110",
    "label": "Philadelphia - PA 19110",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19111",
    "label": "Philadelphia - PA 19111",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19112",
    "label": "Philadelphia - PA 19112",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19113",
    "label": "Philadelphia - PA 19113",
    "county": "Delaware County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19114",
    "label": "Philadelphia - PA 19114",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19115",
    "label": "Philadelphia - PA 19115",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19116",
    "label": "Philadelphia - PA 19116",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19118",
    "label": "Philadelphia - PA 19118",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19119",
    "label": "Philadelphia - PA 19119",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19120",
    "label": "Philadelphia - PA 19120",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19121",
    "label": "Philadelphia - PA 19121",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19122",
    "label": "Philadelphia - PA 19122",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19123",
    "label": "Philadelphia - PA 19123",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19124",
    "label": "Philadelphia - PA 19124",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19125",
    "label": "Philadelphia - PA 19125",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19126",
    "label": "Philadelphia - PA 19126",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19127",
    "label": "Philadelphia - PA 19127",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19128",
    "label": "Philadelphia - PA 19128",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19129",
    "label": "Philadelphia - PA 19129",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19130",
    "label": "Philadelphia - PA 19130",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19131",
    "label": "Philadelphia - PA 19131",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19132",
    "label": "Philadelphia - PA 19132",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19133",
    "label": "Philadelphia - PA 19133",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19134",
    "label": "Philadelphia - PA 19134",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19135",
    "label": "Philadelphia - PA 19135",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19136",
    "label": "Philadelphia - PA 19136",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19137",
    "label": "Philadelphia - PA 19137",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19138",
    "label": "Philadelphia - PA 19138",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19139",
    "label": "Philadelphia - PA 19139",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19140",
    "label": "Philadelphia - PA 19140",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19141",
    "label": "Philadelphia - PA 19141",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19142",
    "label": "Philadelphia - PA 19142",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19143",
    "label": "Philadelphia - PA 19143",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19144",
    "label": "Philadelphia - PA 19144",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19145",
    "label": "Philadelphia - PA 19145",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19146",
    "label": "Philadelphia - PA 19146",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19147",
    "label": "Philadelphia - PA 19147",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19148",
    "label": "Philadelphia - PA 19148",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19149",
    "label": "Philadelphia - PA 19149",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19150",
    "label": "Philadelphia - PA 19150",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19151",
    "label": "Philadelphia - PA 19151",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19152",
    "label": "Philadelphia - PA 19152",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19153",
    "label": "Philadelphia - PA 19153",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19154",
    "label": "Philadelphia - PA 19154",
    "county": "Philadelphia County",
    "state": "PA",
    "city": "Philadelphia"
  },
  {
    "value": "19301",
    "label": "Paoli - PA 19301",
    "county": "Chester County",
    "state": "PA",
    "city": "Paoli"
  },
  {
    "value": "19310",
    "label": "Atglen - PA 19310",
    "county": "Chester County",
    "state": "PA",
    "city": "Atglen"
  },
  {
    "value": "19311",
    "label": "Avondale - PA 19311",
    "county": "Chester County",
    "state": "PA",
    "city": "Avondale"
  },
  {
    "value": "19312",
    "label": "Berwyn - PA 19312",
    "county": "Chester County",
    "state": "PA",
    "city": "Berwyn"
  },
  {
    "value": "19317",
    "label": "Chadds Ford - PA 19317",
    "county": "Delaware County",
    "state": "PA",
    "city": "Chadds Ford"
  },
  {
    "value": "19319",
    "label": "Cheyney - PA 19319",
    "county": "Delaware County",
    "state": "PA",
    "city": "Cheyney"
  },
  {
    "value": "19320",
    "label": "Coatesville - PA 19320",
    "county": "Chester County",
    "state": "PA",
    "city": "Coatesville"
  },
  {
    "value": "19330",
    "label": "Cochranville - PA 19330",
    "county": "Chester County",
    "state": "PA",
    "city": "Cochranville"
  },
  {
    "value": "19333",
    "label": "Devon - PA 19333",
    "county": "Chester County",
    "state": "PA",
    "city": "Devon"
  },
  {
    "value": "19335",
    "label": "Downingtown - PA 19335",
    "county": "Chester County",
    "state": "PA",
    "city": "Downingtown"
  },
  {
    "value": "19341",
    "label": "Exton - PA 19341",
    "county": "Chester County",
    "state": "PA",
    "city": "Exton"
  },
  {
    "value": "19342",
    "label": "Glen Mills - PA 19342",
    "county": "Delaware County",
    "state": "PA",
    "city": "Glen Mills"
  },
  {
    "value": "19343",
    "label": "Glenmoore - PA 19343",
    "county": "Chester County",
    "state": "PA",
    "city": "Glenmoore"
  },
  {
    "value": "19344",
    "label": "Honey Brook - PA 19344",
    "county": "Chester County",
    "state": "PA",
    "city": "Honey Brook"
  },
  {
    "value": "19348",
    "label": "Kennett Square - PA 19348",
    "county": "Chester County",
    "state": "PA",
    "city": "Kennett Square"
  },
  {
    "value": "19350",
    "label": "Landenberg - PA 19350",
    "county": "Chester County",
    "state": "PA",
    "city": "Landenberg"
  },
  {
    "value": "19352",
    "label": "Lincoln University - PA 19352",
    "county": "Chester County",
    "state": "PA",
    "city": "Lincoln University"
  },
  {
    "value": "19355",
    "label": "Malvern - PA 19355",
    "county": "Chester County",
    "state": "PA",
    "city": "Malvern"
  },
  {
    "value": "19362",
    "label": "Nottingham - PA 19362",
    "county": "Chester County",
    "state": "PA",
    "city": "Nottingham"
  },
  {
    "value": "19363",
    "label": "Oxford - PA 19363",
    "county": "Chester County",
    "state": "PA",
    "city": "Oxford"
  },
  {
    "value": "19365",
    "label": "Parkesburg - PA 19365",
    "county": "Chester County",
    "state": "PA",
    "city": "Parkesburg"
  },
  {
    "value": "19372",
    "label": "Thorndale - PA 19372",
    "county": "Chester County",
    "state": "PA",
    "city": "Thorndale"
  },
  {
    "value": "19373",
    "label": "Thornton - PA 19373",
    "county": "Delaware County",
    "state": "PA",
    "city": "Thornton"
  },
  {
    "value": "19374",
    "label": "Toughkenamon - PA 19374",
    "county": "Chester County",
    "state": "PA",
    "city": "Toughkenamon"
  },
  {
    "value": "19380",
    "label": "West Chester - PA 19380",
    "county": "Chester County",
    "state": "PA",
    "city": "West Chester"
  },
  {
    "value": "19382",
    "label": "West Chester - PA 19382",
    "county": "Chester County",
    "state": "PA",
    "city": "West Chester"
  },
  {
    "value": "19390",
    "label": "West Grove - PA 19390",
    "county": "Chester County",
    "state": "PA",
    "city": "West Grove"
  },
  {
    "value": "19401",
    "label": "Norristown - PA 19401",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Norristown"
  },
  {
    "value": "19403",
    "label": "Norristown - PA 19403",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Norristown"
  },
  {
    "value": "19405",
    "label": "Bridgeport - PA 19405",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Bridgeport"
  },
  {
    "value": "19406",
    "label": "King Of Prussia - PA 19406",
    "county": "Montgomery County",
    "state": "PA",
    "city": "King Of Prussia"
  },
  {
    "value": "19422",
    "label": "Blue Bell - PA 19422",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Blue Bell"
  },
  {
    "value": "19425",
    "label": "Chester Springs - PA 19425",
    "county": "Chester County",
    "state": "PA",
    "city": "Chester Springs"
  },
  {
    "value": "19426",
    "label": "Collegeville - PA 19426",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Collegeville"
  },
  {
    "value": "19428",
    "label": "Conshohocken - PA 19428",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Conshohocken"
  },
  {
    "value": "19435",
    "label": "Frederick - PA 19435",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Frederick"
  },
  {
    "value": "19436",
    "label": "Gwynedd - PA 19436",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Gwynedd"
  },
  {
    "value": "19438",
    "label": "Harleysville - PA 19438",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Harleysville"
  },
  {
    "value": "19440",
    "label": "Hatfield - PA 19440",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Hatfield"
  },
  {
    "value": "19444",
    "label": "Lafayette Hill - PA 19444",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Lafayette Hill"
  },
  {
    "value": "19446",
    "label": "Lansdale - PA 19446",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Lansdale"
  },
  {
    "value": "19453",
    "label": "Mont Clare - PA 19453",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Mont Clare"
  },
  {
    "value": "19454",
    "label": "North Wales - PA 19454",
    "county": "Montgomery County",
    "state": "PA",
    "city": "North Wales"
  },
  {
    "value": "19460",
    "label": "Phoenixville - PA 19460",
    "county": "Chester County",
    "state": "PA",
    "city": "Phoenixville"
  },
  {
    "value": "19462",
    "label": "Plymouth Meeting - PA 19462",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Plymouth Meeting"
  },
  {
    "value": "19464",
    "label": "Pottstown - PA 19464",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Pottstown"
  },
  {
    "value": "19465",
    "label": "Pottstown - PA 19465",
    "county": "Chester County",
    "state": "PA",
    "city": "Pottstown"
  },
  {
    "value": "19468",
    "label": "Royersford - PA 19468",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Royersford"
  },
  {
    "value": "19473",
    "label": "Schwenksville - PA 19473",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Schwenksville"
  },
  {
    "value": "19475",
    "label": "Spring City - PA 19475",
    "county": "Chester County",
    "state": "PA",
    "city": "Spring City"
  },
  {
    "value": "19477",
    "label": "Spring House - PA 19477",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Spring House"
  },
  {
    "value": "19492",
    "label": "Zieglerville - PA 19492",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Zieglerville"
  },
  {
    "value": "19503",
    "label": "Bally - PA 19503",
    "county": "Berks County",
    "state": "PA",
    "city": "Bally"
  },
  {
    "value": "19504",
    "label": "Barto - PA 19504",
    "county": "Berks County",
    "state": "PA",
    "city": "Barto"
  },
  {
    "value": "19505",
    "label": "Bechtelsville - PA 19505",
    "county": "Berks County",
    "state": "PA",
    "city": "Bechtelsville"
  },
  {
    "value": "19506",
    "label": "Bernville - PA 19506",
    "county": "Berks County",
    "state": "PA",
    "city": "Bernville"
  },
  {
    "value": "19507",
    "label": "Bethel - PA 19507",
    "county": "Berks County",
    "state": "PA",
    "city": "Bethel"
  },
  {
    "value": "19508",
    "label": "Birdsboro - PA 19508",
    "county": "Berks County",
    "state": "PA",
    "city": "Birdsboro"
  },
  {
    "value": "19510",
    "label": "Blandon - PA 19510",
    "county": "Berks County",
    "state": "PA",
    "city": "Blandon"
  },
  {
    "value": "19512",
    "label": "Boyertown - PA 19512",
    "county": "Berks County",
    "state": "PA",
    "city": "Boyertown"
  },
  {
    "value": "19518",
    "label": "Douglassville - PA 19518",
    "county": "Berks County",
    "state": "PA",
    "city": "Douglassville"
  },
  {
    "value": "19520",
    "label": "Elverson - PA 19520",
    "county": "Chester County",
    "state": "PA",
    "city": "Elverson"
  },
  {
    "value": "19522",
    "label": "Fleetwood - PA 19522",
    "county": "Berks County",
    "state": "PA",
    "city": "Fleetwood"
  },
  {
    "value": "19525",
    "label": "Gilbertsville - PA 19525",
    "county": "Montgomery County",
    "state": "PA",
    "city": "Gilbertsville"
  },
  {
    "value": "19526",
    "label": "Hamburg - PA 19526",
    "county": "Berks County",
    "state": "PA",
    "city": "Hamburg"
  },
  {
    "value": "19529",
    "label": "Kempton - PA 19529",
    "county": "Berks County",
    "state": "PA",
    "city": "Kempton"
  },
  {
    "value": "19530",
    "label": "Kutztown - PA 19530",
    "county": "Berks County",
    "state": "PA",
    "city": "Kutztown"
  },
  {
    "value": "19533",
    "label": "Leesport - PA 19533",
    "county": "Berks County",
    "state": "PA",
    "city": "Leesport"
  },
  {
    "value": "19534",
    "label": "Lenhartsville - PA 19534",
    "county": "Berks County",
    "state": "PA",
    "city": "Lenhartsville"
  },
  {
    "value": "19539",
    "label": "Mertztown - PA 19539",
    "county": "Berks County",
    "state": "PA",
    "city": "Mertztown"
  },
  {
    "value": "19540",
    "label": "Mohnton - PA 19540",
    "county": "Berks County",
    "state": "PA",
    "city": "Mohnton"
  },
  {
    "value": "19541",
    "label": "Mohrsville - PA 19541",
    "county": "Berks County",
    "state": "PA",
    "city": "Mohrsville"
  },
  {
    "value": "19543",
    "label": "Morgantown - PA 19543",
    "county": "Berks County",
    "state": "PA",
    "city": "Morgantown"
  },
  {
    "value": "19547",
    "label": "Oley - PA 19547",
    "county": "Berks County",
    "state": "PA",
    "city": "Oley"
  },
  {
    "value": "19551",
    "label": "Robesonia - PA 19551",
    "county": "Berks County",
    "state": "PA",
    "city": "Robesonia"
  },
  {
    "value": "19555",
    "label": "Shoemakersville - PA 19555",
    "county": "Berks County",
    "state": "PA",
    "city": "Shoemakersville"
  },
  {
    "value": "19560",
    "label": "Temple - PA 19560",
    "county": "Berks County",
    "state": "PA",
    "city": "Temple"
  },
  {
    "value": "19562",
    "label": "Topton - PA 19562",
    "county": "Berks County",
    "state": "PA",
    "city": "Topton"
  },
  {
    "value": "19565",
    "label": "Wernersville - PA 19565",
    "county": "Berks County",
    "state": "PA",
    "city": "Wernersville"
  },
  {
    "value": "19567",
    "label": "Womelsdorf - PA 19567",
    "county": "Berks County",
    "state": "PA",
    "city": "Womelsdorf"
  },
  {
    "value": "19601",
    "label": "Reading - PA 19601",
    "county": "Berks County",
    "state": "PA",
    "city": "Reading"
  },
  {
    "value": "19602",
    "label": "Reading - PA 19602",
    "county": "Berks County",
    "state": "PA",
    "city": "Reading"
  },
  {
    "value": "19604",
    "label": "Reading - PA 19604",
    "county": "Berks County",
    "state": "PA",
    "city": "Reading"
  },
  {
    "value": "19605",
    "label": "Reading - PA 19605",
    "county": "Berks County",
    "state": "PA",
    "city": "Reading"
  },
  {
    "value": "19606",
    "label": "Reading - PA 19606",
    "county": "Berks County",
    "state": "PA",
    "city": "Reading"
  },
  {
    "value": "19607",
    "label": "Reading - PA 19607",
    "county": "Berks County",
    "state": "PA",
    "city": "Reading"
  },
  {
    "value": "19608",
    "label": "Reading - PA 19608",
    "county": "Berks County",
    "state": "PA",
    "city": "Reading"
  },
  {
    "value": "19609",
    "label": "Reading - PA 19609",
    "county": "Berks County",
    "state": "PA",
    "city": "Reading"
  },
  {
    "value": "19610",
    "label": "Reading - PA 19610",
    "county": "Berks County",
    "state": "PA",
    "city": "Reading"
  },
  {
    "value": "19611",
    "label": "Reading - PA 19611",
    "county": "Berks County",
    "state": "PA",
    "city": "Reading"
  },
  {
    "value": "00601",
    "label": "Adjuntas - PR 00601",
    "county": "Adjuntas Municipio",
    "state": "PR",
    "city": "Adjuntas"
  },
  {
    "value": "00602",
    "label": "Aguada - PR 00602",
    "county": "Aguada Municipio",
    "state": "PR",
    "city": "Aguada"
  },
  {
    "value": "00603",
    "label": "Aguadilla - PR 00603",
    "county": "Aguadilla Municipio",
    "state": "PR",
    "city": "Aguadilla"
  },
  {
    "value": "00606",
    "label": "Maricao - PR 00606",
    "county": "Maricao Municipio",
    "state": "PR",
    "city": "Maricao"
  },
  {
    "value": "00610",
    "label": "Anasco - PR 00610",
    "county": "Anasco Municipio",
    "state": "PR",
    "city": "Anasco"
  },
  {
    "value": "00612",
    "label": "Arecibo - PR 00612",
    "county": "Arecibo Municipio",
    "state": "PR",
    "city": "Arecibo"
  },
  {
    "value": "00616",
    "label": "Bajadero - PR 00616",
    "county": "Arecibo Municipio",
    "state": "PR",
    "city": "Bajadero"
  },
  {
    "value": "00617",
    "label": "Barceloneta - PR 00617",
    "county": "Barceloneta Municipio",
    "state": "PR",
    "city": "Barceloneta"
  },
  {
    "value": "00622",
    "label": "Boqueron - PR 00622",
    "county": "Cabo Rojo Municipio",
    "state": "PR",
    "city": "Boqueron"
  },
  {
    "value": "00623",
    "label": "Cabo Rojo - PR 00623",
    "county": "Cabo Rojo Municipio",
    "state": "PR",
    "city": "Cabo Rojo"
  },
  {
    "value": "00624",
    "label": "Penuelas - PR 00624",
    "county": "Penuelas Municipio",
    "state": "PR",
    "city": "Penuelas"
  },
  {
    "value": "00627",
    "label": "Camuy - PR 00627",
    "county": "Camuy Municipio",
    "state": "PR",
    "city": "Camuy"
  },
  {
    "value": "00637",
    "label": "Sabana Grande - PR 00637",
    "county": "Sabana Grande Municipio",
    "state": "PR",
    "city": "Sabana Grande"
  },
  {
    "value": "00638",
    "label": "Ciales - PR 00638",
    "county": "Ciales Municipio",
    "state": "PR",
    "city": "Ciales"
  },
  {
    "value": "00641",
    "label": "Utuado - PR 00641",
    "county": "Utuado Municipio",
    "state": "PR",
    "city": "Utuado"
  },
  {
    "value": "00646",
    "label": "Dorado - PR 00646",
    "county": "Dorado Municipio",
    "state": "PR",
    "city": "Dorado"
  },
  {
    "value": "00647",
    "label": "Ensenada - PR 00647",
    "county": "Guanica Municipio",
    "state": "PR",
    "city": "Ensenada"
  },
  {
    "value": "00650",
    "label": "Florida - PR 00650",
    "county": "Florida Municipio",
    "state": "PR",
    "city": "Florida"
  },
  {
    "value": "00652",
    "label": "Garrochales - PR 00652",
    "county": "Arecibo Municipio",
    "state": "PR",
    "city": "Garrochales"
  },
  {
    "value": "00653",
    "label": "Guanica - PR 00653",
    "county": "Guanica Municipio",
    "state": "PR",
    "city": "Guanica"
  },
  {
    "value": "00656",
    "label": "Guayanilla - PR 00656",
    "county": "Guayanilla Municipio",
    "state": "PR",
    "city": "Guayanilla"
  },
  {
    "value": "00659",
    "label": "Hatillo - PR 00659",
    "county": "Hatillo Municipio",
    "state": "PR",
    "city": "Hatillo"
  },
  {
    "value": "00660",
    "label": "Hormigueros - PR 00660",
    "county": "Hormigueros Municipio",
    "state": "PR",
    "city": "Hormigueros"
  },
  {
    "value": "00662",
    "label": "Isabela - PR 00662",
    "county": "Isabela Municipio",
    "state": "PR",
    "city": "Isabela"
  },
  {
    "value": "00664",
    "label": "Jayuya - PR 00664",
    "county": "Jayuya Municipio",
    "state": "PR",
    "city": "Jayuya"
  },
  {
    "value": "00667",
    "label": "Lajas - PR 00667",
    "county": "Lajas Municipio",
    "state": "PR",
    "city": "Lajas"
  },
  {
    "value": "00669",
    "label": "Lares - PR 00669",
    "county": "Lares Municipio",
    "state": "PR",
    "city": "Lares"
  },
  {
    "value": "00670",
    "label": "Las Marias - PR 00670",
    "county": "Las Marias Municipio",
    "state": "PR",
    "city": "Las Marias"
  },
  {
    "value": "00674",
    "label": "Manati - PR 00674",
    "county": "Manati Municipio",
    "state": "PR",
    "city": "Manati"
  },
  {
    "value": "00676",
    "label": "Moca - PR 00676",
    "county": "Moca Municipio",
    "state": "PR",
    "city": "Moca"
  },
  {
    "value": "00677",
    "label": "Rincon - PR 00677",
    "county": "Rincon Municipio",
    "state": "PR",
    "city": "Rincon"
  },
  {
    "value": "00678",
    "label": "Quebradillas - PR 00678",
    "county": "Quebradillas Municipio",
    "state": "PR",
    "city": "Quebradillas"
  },
  {
    "value": "00680",
    "label": "Mayaguez - PR 00680",
    "county": "Mayaguez Municipio",
    "state": "PR",
    "city": "Mayaguez"
  },
  {
    "value": "00682",
    "label": "Mayaguez - PR 00682",
    "county": "Mayaguez Municipio",
    "state": "PR",
    "city": "Mayaguez"
  },
  {
    "value": "00683",
    "label": "San German - PR 00683",
    "county": "San German Municipio",
    "state": "PR",
    "city": "San German"
  },
  {
    "value": "00685",
    "label": "San Sebastian - PR 00685",
    "county": "San Sebastian Municipio",
    "state": "PR",
    "city": "San Sebastian"
  },
  {
    "value": "00687",
    "label": "Morovis - PR 00687",
    "county": "Morovis Municipio",
    "state": "PR",
    "city": "Morovis"
  },
  {
    "value": "00688",
    "label": "Sabana Hoyos - PR 00688",
    "county": "Arecibo Municipio",
    "state": "PR",
    "city": "Sabana Hoyos"
  },
  {
    "value": "00690",
    "label": "San Antonio - PR 00690",
    "county": "Aguadilla Municipio",
    "state": "PR",
    "city": "San Antonio"
  },
  {
    "value": "00692",
    "label": "Vega Alta - PR 00692",
    "county": "Vega Alta Municipio",
    "state": "PR",
    "city": "Vega Alta"
  },
  {
    "value": "00693",
    "label": "Vega Baja - PR 00693",
    "county": "Vega Baja Municipio",
    "state": "PR",
    "city": "Vega Baja"
  },
  {
    "value": "00698",
    "label": "Yauco - PR 00698",
    "county": "Yauco Municipio",
    "state": "PR",
    "city": "Yauco"
  },
  {
    "value": "00703",
    "label": "Aguas Buenas - PR 00703",
    "county": "Aguas Buenas Municipio",
    "state": "PR",
    "city": "Aguas Buenas"
  },
  {
    "value": "00704",
    "label": "Aguirre - PR 00704",
    "county": "Salinas Municipio",
    "state": "PR",
    "city": "Aguirre"
  },
  {
    "value": "00705",
    "label": "Aibonito - PR 00705",
    "county": "Aibonito Municipio",
    "state": "PR",
    "city": "Aibonito"
  },
  {
    "value": "00707",
    "label": "Maunabo - PR 00707",
    "county": "Maunabo Municipio",
    "state": "PR",
    "city": "Maunabo"
  },
  {
    "value": "00714",
    "label": "Arroyo - PR 00714",
    "county": "Arroyo Municipio",
    "state": "PR",
    "city": "Arroyo"
  },
  {
    "value": "00715",
    "label": "Mercedita - PR 00715",
    "county": "Ponce Municipio",
    "state": "PR",
    "city": "Mercedita"
  },
  {
    "value": "00716",
    "label": "Ponce - PR 00716",
    "county": "Ponce Municipio",
    "state": "PR",
    "city": "Ponce"
  },
  {
    "value": "00717",
    "label": "Ponce - PR 00717",
    "county": "Ponce Municipio",
    "state": "PR",
    "city": "Ponce"
  },
  {
    "value": "00718",
    "label": "Naguabo - PR 00718",
    "county": "Naguabo Municipio",
    "state": "PR",
    "city": "Naguabo"
  },
  {
    "value": "00719",
    "label": "Naranjito - PR 00719",
    "county": "Naranjito Municipio",
    "state": "PR",
    "city": "Naranjito"
  },
  {
    "value": "00720",
    "label": "Orocovis - PR 00720",
    "county": "Orocovis Municipio",
    "state": "PR",
    "city": "Orocovis"
  },
  {
    "value": "00723",
    "label": "Patillas - PR 00723",
    "county": "Patillas Municipio",
    "state": "PR",
    "city": "Patillas"
  },
  {
    "value": "00725",
    "label": "Caguas - PR 00725",
    "county": "Caguas Municipio",
    "state": "PR",
    "city": "Caguas"
  },
  {
    "value": "00727",
    "label": "Caguas - PR 00727",
    "county": "Caguas Municipio",
    "state": "PR",
    "city": "Caguas"
  },
  {
    "value": "00728",
    "label": "Ponce - PR 00728",
    "county": "Ponce Municipio",
    "state": "PR",
    "city": "Ponce"
  },
  {
    "value": "00729",
    "label": "Canovanas - PR 00729",
    "county": "Canovanas Municipio",
    "state": "PR",
    "city": "Canovanas"
  },
  {
    "value": "00730",
    "label": "Ponce - PR 00730",
    "county": "Ponce Municipio",
    "state": "PR",
    "city": "Ponce"
  },
  {
    "value": "00731",
    "label": "Ponce - PR 00731",
    "county": "Ponce Municipio",
    "state": "PR",
    "city": "Ponce"
  },
  {
    "value": "00735",
    "label": "Ceiba - PR 00735",
    "county": "Ceiba Municipio",
    "state": "PR",
    "city": "Ceiba"
  },
  {
    "value": "00736",
    "label": "Cayey - PR 00736",
    "county": "Cayey Municipio",
    "state": "PR",
    "city": "Cayey"
  },
  {
    "value": "00738",
    "label": "Fajardo - PR 00738",
    "county": "Fajardo Municipio",
    "state": "PR",
    "city": "Fajardo"
  },
  {
    "value": "00739",
    "label": "Cidra - PR 00739",
    "county": "Cidra Municipio",
    "state": "PR",
    "city": "Cidra"
  },
  {
    "value": "00740",
    "label": "Puerto Real - PR 00740",
    "county": "Fajardo Municipio",
    "state": "PR",
    "city": "Puerto Real"
  },
  {
    "value": "00741",
    "label": "Punta Santiago - PR 00741",
    "county": "Humacao Municipio",
    "state": "PR",
    "city": "Punta Santiago"
  },
  {
    "value": "00742",
    "label": "Roosevelt Roads - PR 00742",
    "county": "",
    "state": "PR",
    "city": "Roosevelt Roads"
  },
  {
    "value": "00745",
    "label": "Rio Grande - PR 00745",
    "county": "Rio Grande Municipio",
    "state": "PR",
    "city": "Rio Grande"
  },
  {
    "value": "00751",
    "label": "Salinas - PR 00751",
    "county": "Salinas Municipio",
    "state": "PR",
    "city": "Salinas"
  },
  {
    "value": "00754",
    "label": "San Lorenzo - PR 00754",
    "county": "San Lorenzo Municipio",
    "state": "PR",
    "city": "San Lorenzo"
  },
  {
    "value": "00757",
    "label": "Santa Isabel - PR 00757",
    "county": "Santa Isabel Municipio",
    "state": "PR",
    "city": "Santa Isabel"
  },
  {
    "value": "00765",
    "label": "Vieques - PR 00765",
    "county": "Vieques Municipio",
    "state": "PR",
    "city": "Vieques"
  },
  {
    "value": "00766",
    "label": "Villalba - PR 00766",
    "county": "Villalba Municipio",
    "state": "PR",
    "city": "Villalba"
  },
  {
    "value": "00767",
    "label": "Yabucoa - PR 00767",
    "county": "Yabucoa Municipio",
    "state": "PR",
    "city": "Yabucoa"
  },
  {
    "value": "00769",
    "label": "Coamo - PR 00769",
    "county": "Coamo Municipio",
    "state": "PR",
    "city": "Coamo"
  },
  {
    "value": "00771",
    "label": "Las Piedras - PR 00771",
    "county": "Las Piedras Municipio",
    "state": "PR",
    "city": "Las Piedras"
  },
  {
    "value": "00772",
    "label": "Loiza - PR 00772",
    "county": "Loiza Municipio",
    "state": "PR",
    "city": "Loiza"
  },
  {
    "value": "00773",
    "label": "Luquillo - PR 00773",
    "county": "Luquillo Municipio",
    "state": "PR",
    "city": "Luquillo"
  },
  {
    "value": "00777",
    "label": "Juncos - PR 00777",
    "county": "Juncos Municipio",
    "state": "PR",
    "city": "Juncos"
  },
  {
    "value": "00778",
    "label": "Gurabo - PR 00778",
    "county": "Gurabo Municipio",
    "state": "PR",
    "city": "Gurabo"
  },
  {
    "value": "00780",
    "label": "Coto Laurel - PR 00780",
    "county": "Ponce Municipio",
    "state": "PR",
    "city": "Coto Laurel"
  },
  {
    "value": "00782",
    "label": "Comerio - PR 00782",
    "county": "Comerio Municipio",
    "state": "PR",
    "city": "Comerio"
  },
  {
    "value": "00783",
    "label": "Corozal - PR 00783",
    "county": "Corozal Municipio",
    "state": "PR",
    "city": "Corozal"
  },
  {
    "value": "00784",
    "label": "Guayama - PR 00784",
    "county": "Guayama Municipio",
    "state": "PR",
    "city": "Guayama"
  },
  {
    "value": "00791",
    "label": "Humacao - PR 00791",
    "county": "Humacao Municipio",
    "state": "PR",
    "city": "Humacao"
  },
  {
    "value": "00794",
    "label": "Barranquitas - PR 00794",
    "county": "Barranquitas Municipio",
    "state": "PR",
    "city": "Barranquitas"
  },
  {
    "value": "00795",
    "label": "Juana Diaz - PR 00795",
    "county": "Juana Diaz Municipio",
    "state": "PR",
    "city": "Juana Diaz"
  },
  {
    "value": "00901",
    "label": "San Juan - PR 00901",
    "county": "San Juan Municipio",
    "state": "PR",
    "city": "San Juan"
  },
  {
    "value": "00907",
    "label": "San Juan - PR 00907",
    "county": "San Juan Municipio",
    "state": "PR",
    "city": "San Juan"
  },
  {
    "value": "00909",
    "label": "San Juan - PR 00909",
    "county": "San Juan Municipio",
    "state": "PR",
    "city": "San Juan"
  },
  {
    "value": "00911",
    "label": "San Juan - PR 00911",
    "county": "San Juan Municipio",
    "state": "PR",
    "city": "San Juan"
  },
  {
    "value": "00912",
    "label": "San Juan - PR 00912",
    "county": "San Juan Municipio",
    "state": "PR",
    "city": "San Juan"
  },
  {
    "value": "00913",
    "label": "San Juan - PR 00913",
    "county": "San Juan Municipio",
    "state": "PR",
    "city": "San Juan"
  },
  {
    "value": "00915",
    "label": "San Juan - PR 00915",
    "county": "San Juan Municipio",
    "state": "PR",
    "city": "San Juan"
  },
  {
    "value": "00917",
    "label": "San Juan - PR 00917",
    "county": "San Juan Municipio",
    "state": "PR",
    "city": "San Juan"
  },
  {
    "value": "00918",
    "label": "San Juan - PR 00918",
    "county": "San Juan Municipio",
    "state": "PR",
    "city": "San Juan"
  },
  {
    "value": "00920",
    "label": "San Juan - PR 00920",
    "county": "San Juan Municipio",
    "state": "PR",
    "city": "San Juan"
  },
  {
    "value": "00921",
    "label": "San Juan - PR 00921",
    "county": "San Juan Municipio",
    "state": "PR",
    "city": "San Juan"
  },
  {
    "value": "00923",
    "label": "San Juan - PR 00923",
    "county": "San Juan Municipio",
    "state": "PR",
    "city": "San Juan"
  },
  {
    "value": "00924",
    "label": "San Juan - PR 00924",
    "county": "San Juan Municipio",
    "state": "PR",
    "city": "San Juan"
  },
  {
    "value": "00925",
    "label": "San Juan - PR 00925",
    "county": "San Juan Municipio",
    "state": "PR",
    "city": "San Juan"
  },
  {
    "value": "00926",
    "label": "San Juan - PR 00926",
    "county": "San Juan Municipio",
    "state": "PR",
    "city": "San Juan"
  },
  {
    "value": "00927",
    "label": "San Juan - PR 00927",
    "county": "San Juan Municipio",
    "state": "PR",
    "city": "San Juan"
  },
  {
    "value": "00934",
    "label": "Fort Buchanan - PR 00934",
    "county": "Guaynabo Municipio",
    "state": "PR",
    "city": "Fort Buchanan"
  },
  {
    "value": "00949",
    "label": "Toa Baja - PR 00949",
    "county": "Toa Baja Municipio",
    "state": "PR",
    "city": "Toa Baja"
  },
  {
    "value": "00952",
    "label": "Sabana Seca - PR 00952",
    "county": "Toa Baja Municipio",
    "state": "PR",
    "city": "Sabana Seca"
  },
  {
    "value": "00953",
    "label": "Toa Alta - PR 00953",
    "county": "Toa Alta Municipio",
    "state": "PR",
    "city": "Toa Alta"
  },
  {
    "value": "00956",
    "label": "Bayamon - PR 00956",
    "county": "Bayamon Municipio",
    "state": "PR",
    "city": "Bayamon"
  },
  {
    "value": "00957",
    "label": "Bayamon - PR 00957",
    "county": "Bayamon Municipio",
    "state": "PR",
    "city": "Bayamon"
  },
  {
    "value": "00959",
    "label": "Bayamon - PR 00959",
    "county": "Bayamon Municipio",
    "state": "PR",
    "city": "Bayamon"
  },
  {
    "value": "00961",
    "label": "Bayamon - PR 00961",
    "county": "Bayamon Municipio",
    "state": "PR",
    "city": "Bayamon"
  },
  {
    "value": "00962",
    "label": "Catano - PR 00962",
    "county": "Catano Municipio",
    "state": "PR",
    "city": "Catano"
  },
  {
    "value": "00965",
    "label": "Guaynabo - PR 00965",
    "county": "Guaynabo Municipio",
    "state": "PR",
    "city": "Guaynabo"
  },
  {
    "value": "00966",
    "label": "Guaynabo - PR 00966",
    "county": "Guaynabo Municipio",
    "state": "PR",
    "city": "Guaynabo"
  },
  {
    "value": "00968",
    "label": "Guaynabo - PR 00968",
    "county": "Guaynabo Municipio",
    "state": "PR",
    "city": "Guaynabo"
  },
  {
    "value": "00969",
    "label": "Guaynabo - PR 00969",
    "county": "Guaynabo Municipio",
    "state": "PR",
    "city": "Guaynabo"
  },
  {
    "value": "00971",
    "label": "Guaynabo - PR 00971",
    "county": "Guaynabo Municipio",
    "state": "PR",
    "city": "Guaynabo"
  },
  {
    "value": "00976",
    "label": "Trujillo Alto - PR 00976",
    "county": "Trujillo Alto Municipio",
    "state": "PR",
    "city": "Trujillo Alto"
  },
  {
    "value": "00979",
    "label": "Carolina - PR 00979",
    "county": "Carolina Municipio",
    "state": "PR",
    "city": "Carolina"
  },
  {
    "value": "00982",
    "label": "Carolina - PR 00982",
    "county": "Carolina Municipio",
    "state": "PR",
    "city": "Carolina"
  },
  {
    "value": "00983",
    "label": "Carolina - PR 00983",
    "county": "Carolina Municipio",
    "state": "PR",
    "city": "Carolina"
  },
  {
    "value": "00985",
    "label": "Carolina - PR 00985",
    "county": "Carolina Municipio",
    "state": "PR",
    "city": "Carolina"
  },
  {
    "value": "00987",
    "label": "Carolina - PR 00987",
    "county": "Carolina Municipio",
    "state": "PR",
    "city": "Carolina"
  },
  {
    "value": "02804",
    "label": "Ashaway - RI 02804",
    "county": "Washington County",
    "state": "RI",
    "city": "Ashaway"
  },
  {
    "value": "02806",
    "label": "Barrington - RI 02806",
    "county": "Bristol County",
    "state": "RI",
    "city": "Barrington"
  },
  {
    "value": "02808",
    "label": "Bradford - RI 02808",
    "county": "Washington County",
    "state": "RI",
    "city": "Bradford"
  },
  {
    "value": "02809",
    "label": "Bristol - RI 02809",
    "county": "Bristol County",
    "state": "RI",
    "city": "Bristol"
  },
  {
    "value": "02812",
    "label": "Carolina - RI 02812",
    "county": "Washington County",
    "state": "RI",
    "city": "Carolina"
  },
  {
    "value": "02813",
    "label": "Charlestown - RI 02813",
    "county": "Washington County",
    "state": "RI",
    "city": "Charlestown"
  },
  {
    "value": "02814",
    "label": "Chepachet - RI 02814",
    "county": "Providence County",
    "state": "RI",
    "city": "Chepachet"
  },
  {
    "value": "02815",
    "label": "Clayville - RI 02815",
    "county": "Providence County",
    "state": "RI",
    "city": "Clayville"
  },
  {
    "value": "02816",
    "label": "Coventry - RI 02816",
    "county": "Kent County",
    "state": "RI",
    "city": "Coventry"
  },
  {
    "value": "02817",
    "label": "West Greenwich - RI 02817",
    "county": "Kent County",
    "state": "RI",
    "city": "West Greenwich"
  },
  {
    "value": "02818",
    "label": "East Greenwich - RI 02818",
    "county": "Kent County",
    "state": "RI",
    "city": "East Greenwich"
  },
  {
    "value": "02822",
    "label": "Exeter - RI 02822",
    "county": "Washington County",
    "state": "RI",
    "city": "Exeter"
  },
  {
    "value": "02825",
    "label": "Foster - RI 02825",
    "county": "Providence County",
    "state": "RI",
    "city": "Foster"
  },
  {
    "value": "02827",
    "label": "Greene - RI 02827",
    "county": "Kent County",
    "state": "RI",
    "city": "Greene"
  },
  {
    "value": "02828",
    "label": "Greenville - RI 02828",
    "county": "Providence County",
    "state": "RI",
    "city": "Greenville"
  },
  {
    "value": "02830",
    "label": "Harrisville - RI 02830",
    "county": "Providence County",
    "state": "RI",
    "city": "Harrisville"
  },
  {
    "value": "02831",
    "label": "Hope - RI 02831",
    "county": "Providence County",
    "state": "RI",
    "city": "Hope"
  },
  {
    "value": "02832",
    "label": "Hope Valley - RI 02832",
    "county": "Washington County",
    "state": "RI",
    "city": "Hope Valley"
  },
  {
    "value": "02833",
    "label": "Hopkinton - RI 02833",
    "county": "Washington County",
    "state": "RI",
    "city": "Hopkinton"
  },
  {
    "value": "02835",
    "label": "Jamestown - RI 02835",
    "county": "Newport County",
    "state": "RI",
    "city": "Jamestown"
  },
  {
    "value": "02836",
    "label": "Kenyon - RI 02836",
    "county": "Washington County",
    "state": "RI",
    "city": "Kenyon"
  },
  {
    "value": "02837",
    "label": "Little Compton - RI 02837",
    "county": "Newport County",
    "state": "RI",
    "city": "Little Compton"
  },
  {
    "value": "02838",
    "label": "Manville - RI 02838",
    "county": "Providence County",
    "state": "RI",
    "city": "Manville"
  },
  {
    "value": "02839",
    "label": "Mapleville - RI 02839",
    "county": "Providence County",
    "state": "RI",
    "city": "Mapleville"
  },
  {
    "value": "02840",
    "label": "Newport - RI 02840",
    "county": "Newport County",
    "state": "RI",
    "city": "Newport"
  },
  {
    "value": "02841",
    "label": "Newport - RI 02841",
    "county": "Newport County",
    "state": "RI",
    "city": "Newport"
  },
  {
    "value": "02842",
    "label": "Middletown - RI 02842",
    "county": "Newport County",
    "state": "RI",
    "city": "Middletown"
  },
  {
    "value": "02852",
    "label": "North Kingstown - RI 02852",
    "county": "Washington County",
    "state": "RI",
    "city": "North Kingstown"
  },
  {
    "value": "02854",
    "label": "North Kingstown - RI 02854",
    "county": "Washington County",
    "state": "RI",
    "city": "North Kingstown"
  },
  {
    "value": "02857",
    "label": "North Scituate - RI 02857",
    "county": "Providence County",
    "state": "RI",
    "city": "North Scituate"
  },
  {
    "value": "02858",
    "label": "Oakland - RI 02858",
    "county": "Providence County",
    "state": "RI",
    "city": "Oakland"
  },
  {
    "value": "02859",
    "label": "Pascoag - RI 02859",
    "county": "Providence County",
    "state": "RI",
    "city": "Pascoag"
  },
  {
    "value": "02860",
    "label": "Pawtucket - RI 02860",
    "county": "Providence County",
    "state": "RI",
    "city": "Pawtucket"
  },
  {
    "value": "02861",
    "label": "Pawtucket - RI 02861",
    "county": "Providence County",
    "state": "RI",
    "city": "Pawtucket"
  },
  {
    "value": "02863",
    "label": "Central Falls - RI 02863",
    "county": "Providence County",
    "state": "RI",
    "city": "Central Falls"
  },
  {
    "value": "02864",
    "label": "Cumberland - RI 02864",
    "county": "Providence County",
    "state": "RI",
    "city": "Cumberland"
  },
  {
    "value": "02865",
    "label": "Lincoln - RI 02865",
    "county": "Providence County",
    "state": "RI",
    "city": "Lincoln"
  },
  {
    "value": "02871",
    "label": "Portsmouth - RI 02871",
    "county": "Newport County",
    "state": "RI",
    "city": "Portsmouth"
  },
  {
    "value": "02874",
    "label": "Saunderstown - RI 02874",
    "county": "Washington County",
    "state": "RI",
    "city": "Saunderstown"
  },
  {
    "value": "02875",
    "label": "Shannock - RI 02875",
    "county": "Washington County",
    "state": "RI",
    "city": "Shannock"
  },
  {
    "value": "02876",
    "label": "Slatersville - RI 02876",
    "county": "Providence County",
    "state": "RI",
    "city": "Slatersville"
  },
  {
    "value": "02877",
    "label": "Slocum - RI 02877",
    "county": "Washington County",
    "state": "RI",
    "city": "Slocum"
  },
  {
    "value": "02878",
    "label": "Tiverton - RI 02878",
    "county": "Newport County",
    "state": "RI",
    "city": "Tiverton"
  },
  {
    "value": "02879",
    "label": "Wakefield - RI 02879",
    "county": "Washington County",
    "state": "RI",
    "city": "Wakefield"
  },
  {
    "value": "02881",
    "label": "Kingston - RI 02881",
    "county": "Washington County",
    "state": "RI",
    "city": "Kingston"
  },
  {
    "value": "02882",
    "label": "Narragansett - RI 02882",
    "county": "Washington County",
    "state": "RI",
    "city": "Narragansett"
  },
  {
    "value": "02885",
    "label": "Warren - RI 02885",
    "county": "Bristol County",
    "state": "RI",
    "city": "Warren"
  },
  {
    "value": "02886",
    "label": "Warwick - RI 02886",
    "county": "Kent County",
    "state": "RI",
    "city": "Warwick"
  },
  {
    "value": "02888",
    "label": "Warwick - RI 02888",
    "county": "Kent County",
    "state": "RI",
    "city": "Warwick"
  },
  {
    "value": "02889",
    "label": "Warwick - RI 02889",
    "county": "Kent County",
    "state": "RI",
    "city": "Warwick"
  },
  {
    "value": "02891",
    "label": "Westerly - RI 02891",
    "county": "Washington County",
    "state": "RI",
    "city": "Westerly"
  },
  {
    "value": "02892",
    "label": "West Kingston - RI 02892",
    "county": "Washington County",
    "state": "RI",
    "city": "West Kingston"
  },
  {
    "value": "02893",
    "label": "West Warwick - RI 02893",
    "county": "Kent County",
    "state": "RI",
    "city": "West Warwick"
  },
  {
    "value": "02894",
    "label": "Wood River Junction - RI 02894",
    "county": "Washington County",
    "state": "RI",
    "city": "Wood River Junction"
  },
  {
    "value": "02895",
    "label": "Woonsocket - RI 02895",
    "county": "Providence County",
    "state": "RI",
    "city": "Woonsocket"
  },
  {
    "value": "02896",
    "label": "North Smithfield - RI 02896",
    "county": "Providence County",
    "state": "RI",
    "city": "North Smithfield"
  },
  {
    "value": "02898",
    "label": "Wyoming - RI 02898",
    "county": "Washington County",
    "state": "RI",
    "city": "Wyoming"
  },
  {
    "value": "02903",
    "label": "Providence - RI 02903",
    "county": "Providence County",
    "state": "RI",
    "city": "Providence"
  },
  {
    "value": "02904",
    "label": "Providence - RI 02904",
    "county": "Providence County",
    "state": "RI",
    "city": "Providence"
  },
  {
    "value": "02905",
    "label": "Providence - RI 02905",
    "county": "Providence County",
    "state": "RI",
    "city": "Providence"
  },
  {
    "value": "02906",
    "label": "Providence - RI 02906",
    "county": "Providence County",
    "state": "RI",
    "city": "Providence"
  },
  {
    "value": "02907",
    "label": "Providence - RI 02907",
    "county": "Providence County",
    "state": "RI",
    "city": "Providence"
  },
  {
    "value": "02908",
    "label": "Providence - RI 02908",
    "county": "Providence County",
    "state": "RI",
    "city": "Providence"
  },
  {
    "value": "02909",
    "label": "Providence - RI 02909",
    "county": "Providence County",
    "state": "RI",
    "city": "Providence"
  },
  {
    "value": "02910",
    "label": "Cranston - RI 02910",
    "county": "Providence County",
    "state": "RI",
    "city": "Cranston"
  },
  {
    "value": "02911",
    "label": "North Providence - RI 02911",
    "county": "Providence County",
    "state": "RI",
    "city": "North Providence"
  },
  {
    "value": "02914",
    "label": "East Providence - RI 02914",
    "county": "Providence County",
    "state": "RI",
    "city": "East Providence"
  },
  {
    "value": "02915",
    "label": "Riverside - RI 02915",
    "county": "Providence County",
    "state": "RI",
    "city": "Riverside"
  },
  {
    "value": "02916",
    "label": "Rumford - RI 02916",
    "county": "Providence County",
    "state": "RI",
    "city": "Rumford"
  },
  {
    "value": "02917",
    "label": "Smithfield - RI 02917",
    "county": "Providence County",
    "state": "RI",
    "city": "Smithfield"
  },
  {
    "value": "02919",
    "label": "Johnston - RI 02919",
    "county": "Providence County",
    "state": "RI",
    "city": "Johnston"
  },
  {
    "value": "02920",
    "label": "Cranston - RI 02920",
    "county": "Providence County",
    "state": "RI",
    "city": "Cranston"
  },
  {
    "value": "02921",
    "label": "Cranston - RI 02921",
    "county": "Providence County",
    "state": "RI",
    "city": "Cranston"
  },
  {
    "value": "29001",
    "label": "Alcolu - SC 29001",
    "county": "Clarendon County",
    "state": "SC",
    "city": "Alcolu"
  },
  {
    "value": "29003",
    "label": "Bamberg - SC 29003",
    "county": "Bamberg County",
    "state": "SC",
    "city": "Bamberg"
  },
  {
    "value": "29006",
    "label": "Batesburg - SC 29006",
    "county": "Lexington County",
    "state": "SC",
    "city": "Batesburg"
  },
  {
    "value": "29009",
    "label": "Bethune - SC 29009",
    "county": "Kershaw County",
    "state": "SC",
    "city": "Bethune"
  },
  {
    "value": "29010",
    "label": "Bishopville - SC 29010",
    "county": "Lee County",
    "state": "SC",
    "city": "Bishopville"
  },
  {
    "value": "29014",
    "label": "Blackstock - SC 29014",
    "county": "Chester County",
    "state": "SC",
    "city": "Blackstock"
  },
  {
    "value": "29015",
    "label": "Blair - SC 29015",
    "county": "Fairfield County",
    "state": "SC",
    "city": "Blair"
  },
  {
    "value": "29016",
    "label": "Blythewood - SC 29016",
    "county": "Richland County",
    "state": "SC",
    "city": "Blythewood"
  },
  {
    "value": "29018",
    "label": "Bowman - SC 29018",
    "county": "Orangeburg County",
    "state": "SC",
    "city": "Bowman"
  },
  {
    "value": "29020",
    "label": "Camden - SC 29020",
    "county": "Kershaw County",
    "state": "SC",
    "city": "Camden"
  },
  {
    "value": "29030",
    "label": "Cameron - SC 29030",
    "county": "Calhoun County",
    "state": "SC",
    "city": "Cameron"
  },
  {
    "value": "29031",
    "label": "Carlisle - SC 29031",
    "county": "Union County",
    "state": "SC",
    "city": "Carlisle"
  },
  {
    "value": "29032",
    "label": "Cassatt - SC 29032",
    "county": "Kershaw County",
    "state": "SC",
    "city": "Cassatt"
  },
  {
    "value": "29033",
    "label": "Cayce - SC 29033",
    "county": "Lexington County",
    "state": "SC",
    "city": "Cayce"
  },
  {
    "value": "29036",
    "label": "Chapin - SC 29036",
    "county": "Lexington County",
    "state": "SC",
    "city": "Chapin"
  },
  {
    "value": "29037",
    "label": "Chappells - SC 29037",
    "county": "Newberry County",
    "state": "SC",
    "city": "Chappells"
  },
  {
    "value": "29038",
    "label": "Cope - SC 29038",
    "county": "Orangeburg County",
    "state": "SC",
    "city": "Cope"
  },
  {
    "value": "29039",
    "label": "Cordova - SC 29039",
    "county": "Orangeburg County",
    "state": "SC",
    "city": "Cordova"
  },
  {
    "value": "29040",
    "label": "Dalzell - SC 29040",
    "county": "Sumter County",
    "state": "SC",
    "city": "Dalzell"
  },
  {
    "value": "29042",
    "label": "Denmark - SC 29042",
    "county": "Bamberg County",
    "state": "SC",
    "city": "Denmark"
  },
  {
    "value": "29044",
    "label": "Eastover - SC 29044",
    "county": "Richland County",
    "state": "SC",
    "city": "Eastover"
  },
  {
    "value": "29045",
    "label": "Elgin - SC 29045",
    "county": "Kershaw County",
    "state": "SC",
    "city": "Elgin"
  },
  {
    "value": "29047",
    "label": "Elloree - SC 29047",
    "county": "Orangeburg County",
    "state": "SC",
    "city": "Elloree"
  },
  {
    "value": "29048",
    "label": "Eutawville - SC 29048",
    "county": "Orangeburg County",
    "state": "SC",
    "city": "Eutawville"
  },
  {
    "value": "29051",
    "label": "Gable - SC 29051",
    "county": "Sumter County",
    "state": "SC",
    "city": "Gable"
  },
  {
    "value": "29052",
    "label": "Gadsden - SC 29052",
    "county": "Richland County",
    "state": "SC",
    "city": "Gadsden"
  },
  {
    "value": "29053",
    "label": "Gaston - SC 29053",
    "county": "Lexington County",
    "state": "SC",
    "city": "Gaston"
  },
  {
    "value": "29054",
    "label": "Gilbert - SC 29054",
    "county": "Lexington County",
    "state": "SC",
    "city": "Gilbert"
  },
  {
    "value": "29055",
    "label": "Great Falls - SC 29055",
    "county": "Chester County",
    "state": "SC",
    "city": "Great Falls"
  },
  {
    "value": "29056",
    "label": "Greeleyville - SC 29056",
    "county": "Williamsburg County",
    "state": "SC",
    "city": "Greeleyville"
  },
  {
    "value": "29058",
    "label": "Heath Springs - SC 29058",
    "county": "Lancaster County",
    "state": "SC",
    "city": "Heath Springs"
  },
  {
    "value": "29059",
    "label": "Holly Hill - SC 29059",
    "county": "Orangeburg County",
    "state": "SC",
    "city": "Holly Hill"
  },
  {
    "value": "29061",
    "label": "Hopkins - SC 29061",
    "county": "Richland County",
    "state": "SC",
    "city": "Hopkins"
  },
  {
    "value": "29063",
    "label": "Irmo - SC 29063",
    "county": "Richland County",
    "state": "SC",
    "city": "Irmo"
  },
  {
    "value": "29065",
    "label": "Jenkinsville - SC 29065",
    "county": "Fairfield County",
    "state": "SC",
    "city": "Jenkinsville"
  },
  {
    "value": "29067",
    "label": "Kershaw - SC 29067",
    "county": "Lancaster County",
    "state": "SC",
    "city": "Kershaw"
  },
  {
    "value": "29069",
    "label": "Lamar - SC 29069",
    "county": "Darlington County",
    "state": "SC",
    "city": "Lamar"
  },
  {
    "value": "29070",
    "label": "Leesville - SC 29070",
    "county": "Lexington County",
    "state": "SC",
    "city": "Leesville"
  },
  {
    "value": "29072",
    "label": "Lexington - SC 29072",
    "county": "Lexington County",
    "state": "SC",
    "city": "Lexington"
  },
  {
    "value": "29073",
    "label": "Lexington - SC 29073",
    "county": "Lexington County",
    "state": "SC",
    "city": "Lexington"
  },
  {
    "value": "29075",
    "label": "Little Mountain - SC 29075",
    "county": "Newberry County",
    "state": "SC",
    "city": "Little Mountain"
  },
  {
    "value": "29078",
    "label": "Lugoff - SC 29078",
    "county": "Kershaw County",
    "state": "SC",
    "city": "Lugoff"
  },
  {
    "value": "29080",
    "label": "Lynchburg - SC 29080",
    "county": "Lee County",
    "state": "SC",
    "city": "Lynchburg"
  },
  {
    "value": "29081",
    "label": "Ehrhardt - SC 29081",
    "county": "Bamberg County",
    "state": "SC",
    "city": "Ehrhardt"
  },
  {
    "value": "29082",
    "label": "Lodge - SC 29082",
    "county": "Colleton County",
    "state": "SC",
    "city": "Lodge"
  },
  {
    "value": "29101",
    "label": "Mc Bee - SC 29101",
    "county": "Chesterfield County",
    "state": "SC",
    "city": "Mc Bee"
  },
  {
    "value": "29102",
    "label": "Manning - SC 29102",
    "county": "Clarendon County",
    "state": "SC",
    "city": "Manning"
  },
  {
    "value": "29104",
    "label": "Mayesville - SC 29104",
    "county": "Sumter County",
    "state": "SC",
    "city": "Mayesville"
  },
  {
    "value": "29105",
    "label": "Monetta - SC 29105",
    "county": "Aiken County",
    "state": "SC",
    "city": "Monetta"
  },
  {
    "value": "29107",
    "label": "Neeses - SC 29107",
    "county": "Orangeburg County",
    "state": "SC",
    "city": "Neeses"
  },
  {
    "value": "29108",
    "label": "Newberry - SC 29108",
    "county": "Newberry County",
    "state": "SC",
    "city": "Newberry"
  },
  {
    "value": "29111",
    "label": "New Zion - SC 29111",
    "county": "Clarendon County",
    "state": "SC",
    "city": "New Zion"
  },
  {
    "value": "29112",
    "label": "North - SC 29112",
    "county": "Orangeburg County",
    "state": "SC",
    "city": "North"
  },
  {
    "value": "29113",
    "label": "Norway - SC 29113",
    "county": "Orangeburg County",
    "state": "SC",
    "city": "Norway"
  },
  {
    "value": "29114",
    "label": "Olanta - SC 29114",
    "county": "Florence County",
    "state": "SC",
    "city": "Olanta"
  },
  {
    "value": "29115",
    "label": "Orangeburg - SC 29115",
    "county": "Orangeburg County",
    "state": "SC",
    "city": "Orangeburg"
  },
  {
    "value": "29118",
    "label": "Orangeburg - SC 29118",
    "county": "Orangeburg County",
    "state": "SC",
    "city": "Orangeburg"
  },
  {
    "value": "29123",
    "label": "Pelion - SC 29123",
    "county": "Lexington County",
    "state": "SC",
    "city": "Pelion"
  },
  {
    "value": "29125",
    "label": "Pinewood - SC 29125",
    "county": "Sumter County",
    "state": "SC",
    "city": "Pinewood"
  },
  {
    "value": "29126",
    "label": "Pomaria - SC 29126",
    "county": "Newberry County",
    "state": "SC",
    "city": "Pomaria"
  },
  {
    "value": "29127",
    "label": "Prosperity - SC 29127",
    "county": "Newberry County",
    "state": "SC",
    "city": "Prosperity"
  },
  {
    "value": "29128",
    "label": "Rembert - SC 29128",
    "county": "Sumter County",
    "state": "SC",
    "city": "Rembert"
  },
  {
    "value": "29129",
    "label": "Ridge Spring - SC 29129",
    "county": "Aiken County",
    "state": "SC",
    "city": "Ridge Spring"
  },
  {
    "value": "29130",
    "label": "Ridgeway - SC 29130",
    "county": "Fairfield County",
    "state": "SC",
    "city": "Ridgeway"
  },
  {
    "value": "29133",
    "label": "Rowesville - SC 29133",
    "county": "Orangeburg County",
    "state": "SC",
    "city": "Rowesville"
  },
  {
    "value": "29135",
    "label": "Saint Matthews - SC 29135",
    "county": "Calhoun County",
    "state": "SC",
    "city": "Saint Matthews"
  },
  {
    "value": "29137",
    "label": "Salley - SC 29137",
    "county": "Aiken County",
    "state": "SC",
    "city": "Salley"
  },
  {
    "value": "29138",
    "label": "Saluda - SC 29138",
    "county": "Saluda County",
    "state": "SC",
    "city": "Saluda"
  },
  {
    "value": "29142",
    "label": "Santee - SC 29142",
    "county": "Orangeburg County",
    "state": "SC",
    "city": "Santee"
  },
  {
    "value": "29145",
    "label": "Silverstreet - SC 29145",
    "county": "Newberry County",
    "state": "SC",
    "city": "Silverstreet"
  },
  {
    "value": "29146",
    "label": "Springfield - SC 29146",
    "county": "Orangeburg County",
    "state": "SC",
    "city": "Springfield"
  },
  {
    "value": "29148",
    "label": "Summerton - SC 29148",
    "county": "Clarendon County",
    "state": "SC",
    "city": "Summerton"
  },
  {
    "value": "29150",
    "label": "Sumter - SC 29150",
    "county": "Sumter County",
    "state": "SC",
    "city": "Sumter"
  },
  {
    "value": "29152",
    "label": "Shaw AFB - SC 29152",
    "county": "Sumter County",
    "state": "SC",
    "city": "Shaw AFB"
  },
  {
    "value": "29153",
    "label": "Sumter - SC 29153",
    "county": "Sumter County",
    "state": "SC",
    "city": "Sumter"
  },
  {
    "value": "29154",
    "label": "Sumter - SC 29154",
    "county": "Sumter County",
    "state": "SC",
    "city": "Sumter"
  },
  {
    "value": "29160",
    "label": "Swansea - SC 29160",
    "county": "Lexington County",
    "state": "SC",
    "city": "Swansea"
  },
  {
    "value": "29161",
    "label": "Timmonsville - SC 29161",
    "county": "Florence County",
    "state": "SC",
    "city": "Timmonsville"
  },
  {
    "value": "29162",
    "label": "Turbeville - SC 29162",
    "county": "Clarendon County",
    "state": "SC",
    "city": "Turbeville"
  },
  {
    "value": "29163",
    "label": "Vance - SC 29163",
    "county": "Orangeburg County",
    "state": "SC",
    "city": "Vance"
  },
  {
    "value": "29164",
    "label": "Wagener - SC 29164",
    "county": "Aiken County",
    "state": "SC",
    "city": "Wagener"
  },
  {
    "value": "29166",
    "label": "Ward - SC 29166",
    "county": "Saluda County",
    "state": "SC",
    "city": "Ward"
  },
  {
    "value": "29168",
    "label": "Wedgefield - SC 29168",
    "county": "Sumter County",
    "state": "SC",
    "city": "Wedgefield"
  },
  {
    "value": "29169",
    "label": "West Columbia - SC 29169",
    "county": "Lexington County",
    "state": "SC",
    "city": "West Columbia"
  },
  {
    "value": "29170",
    "label": "West Columbia - SC 29170",
    "county": "Lexington County",
    "state": "SC",
    "city": "West Columbia"
  },
  {
    "value": "29172",
    "label": "West Columbia - SC 29172",
    "county": "Lexington County",
    "state": "SC",
    "city": "West Columbia"
  },
  {
    "value": "29175",
    "label": "Westville - SC 29175",
    "county": "Kershaw County",
    "state": "SC",
    "city": "Westville"
  },
  {
    "value": "29178",
    "label": "Whitmire - SC 29178",
    "county": "Newberry County",
    "state": "SC",
    "city": "Whitmire"
  },
  {
    "value": "29180",
    "label": "Winnsboro - SC 29180",
    "county": "Fairfield County",
    "state": "SC",
    "city": "Winnsboro"
  },
  {
    "value": "29201",
    "label": "Columbia - SC 29201",
    "county": "Richland County",
    "state": "SC",
    "city": "Columbia"
  },
  {
    "value": "29203",
    "label": "Columbia - SC 29203",
    "county": "Richland County",
    "state": "SC",
    "city": "Columbia"
  },
  {
    "value": "29204",
    "label": "Columbia - SC 29204",
    "county": "Richland County",
    "state": "SC",
    "city": "Columbia"
  },
  {
    "value": "29205",
    "label": "Columbia - SC 29205",
    "county": "Richland County",
    "state": "SC",
    "city": "Columbia"
  },
  {
    "value": "29206",
    "label": "Columbia - SC 29206",
    "county": "Richland County",
    "state": "SC",
    "city": "Columbia"
  },
  {
    "value": "29209",
    "label": "Columbia - SC 29209",
    "county": "Richland County",
    "state": "SC",
    "city": "Columbia"
  },
  {
    "value": "29210",
    "label": "Columbia - SC 29210",
    "county": "Richland County",
    "state": "SC",
    "city": "Columbia"
  },
  {
    "value": "29212",
    "label": "Columbia - SC 29212",
    "county": "Lexington County",
    "state": "SC",
    "city": "Columbia"
  },
  {
    "value": "29222",
    "label": "Columbia - SC 29222",
    "county": "Richland County",
    "state": "SC",
    "city": "Columbia"
  },
  {
    "value": "29223",
    "label": "Columbia - SC 29223",
    "county": "Richland County",
    "state": "SC",
    "city": "Columbia"
  },
  {
    "value": "29229",
    "label": "Columbia - SC 29229",
    "county": "Richland County",
    "state": "SC",
    "city": "Columbia"
  },
  {
    "value": "29301",
    "label": "Spartanburg - SC 29301",
    "county": "Spartanburg County",
    "state": "SC",
    "city": "Spartanburg"
  },
  {
    "value": "29302",
    "label": "Spartanburg - SC 29302",
    "county": "Spartanburg County",
    "state": "SC",
    "city": "Spartanburg"
  },
  {
    "value": "29303",
    "label": "Spartanburg - SC 29303",
    "county": "Spartanburg County",
    "state": "SC",
    "city": "Spartanburg"
  },
  {
    "value": "29306",
    "label": "Spartanburg - SC 29306",
    "county": "Spartanburg County",
    "state": "SC",
    "city": "Spartanburg"
  },
  {
    "value": "29307",
    "label": "Spartanburg - SC 29307",
    "county": "Spartanburg County",
    "state": "SC",
    "city": "Spartanburg"
  },
  {
    "value": "29316",
    "label": "Boiling Springs - SC 29316",
    "county": "Spartanburg County",
    "state": "SC",
    "city": "Boiling Springs"
  },
  {
    "value": "29318",
    "label": "Boiling Springs - SC 29318",
    "county": "Spartanburg County",
    "state": "SC",
    "city": "Boiling Springs"
  },
  {
    "value": "29321",
    "label": "Buffalo - SC 29321",
    "county": "Union County",
    "state": "SC",
    "city": "Buffalo"
  },
  {
    "value": "29322",
    "label": "Campobello - SC 29322",
    "county": "Spartanburg County",
    "state": "SC",
    "city": "Campobello"
  },
  {
    "value": "29323",
    "label": "Chesnee - SC 29323",
    "county": "Spartanburg County",
    "state": "SC",
    "city": "Chesnee"
  },
  {
    "value": "29325",
    "label": "Clinton - SC 29325",
    "county": "Laurens County",
    "state": "SC",
    "city": "Clinton"
  },
  {
    "value": "29330",
    "label": "Cowpens - SC 29330",
    "county": "Spartanburg County",
    "state": "SC",
    "city": "Cowpens"
  },
  {
    "value": "29332",
    "label": "Cross Hill - SC 29332",
    "county": "Laurens County",
    "state": "SC",
    "city": "Cross Hill"
  },
  {
    "value": "29334",
    "label": "Duncan - SC 29334",
    "county": "Spartanburg County",
    "state": "SC",
    "city": "Duncan"
  },
  {
    "value": "29335",
    "label": "Enoree - SC 29335",
    "county": "Spartanburg County",
    "state": "SC",
    "city": "Enoree"
  },
  {
    "value": "29340",
    "label": "Gaffney - SC 29340",
    "county": "Cherokee County",
    "state": "SC",
    "city": "Gaffney"
  },
  {
    "value": "29341",
    "label": "Gaffney - SC 29341",
    "county": "Cherokee County",
    "state": "SC",
    "city": "Gaffney"
  },
  {
    "value": "29349",
    "label": "Inman - SC 29349",
    "county": "Spartanburg County",
    "state": "SC",
    "city": "Inman"
  },
  {
    "value": "29351",
    "label": "Joanna - SC 29351",
    "county": "Laurens County",
    "state": "SC",
    "city": "Joanna"
  },
  {
    "value": "29353",
    "label": "Jonesville - SC 29353",
    "county": "Union County",
    "state": "SC",
    "city": "Jonesville"
  },
  {
    "value": "29355",
    "label": "Kinards - SC 29355",
    "county": "Laurens County",
    "state": "SC",
    "city": "Kinards"
  },
  {
    "value": "29356",
    "label": "Landrum - SC 29356",
    "county": "Spartanburg County",
    "state": "SC",
    "city": "Landrum"
  },
  {
    "value": "29360",
    "label": "Laurens - SC 29360",
    "county": "Laurens County",
    "state": "SC",
    "city": "Laurens"
  },
  {
    "value": "29365",
    "label": "Lyman - SC 29365",
    "county": "Spartanburg County",
    "state": "SC",
    "city": "Lyman"
  },
  {
    "value": "29369",
    "label": "Moore - SC 29369",
    "county": "Spartanburg County",
    "state": "SC",
    "city": "Moore"
  },
  {
    "value": "29370",
    "label": "Mountville - SC 29370",
    "county": "Laurens County",
    "state": "SC",
    "city": "Mountville"
  },
  {
    "value": "29372",
    "label": "Pacolet - SC 29372",
    "county": "Spartanburg County",
    "state": "SC",
    "city": "Pacolet"
  },
  {
    "value": "29374",
    "label": "Pauline - SC 29374",
    "county": "Spartanburg County",
    "state": "SC",
    "city": "Pauline"
  },
  {
    "value": "29376",
    "label": "Roebuck - SC 29376",
    "county": "Spartanburg County",
    "state": "SC",
    "city": "Roebuck"
  },
  {
    "value": "29379",
    "label": "Union - SC 29379",
    "county": "Union County",
    "state": "SC",
    "city": "Union"
  },
  {
    "value": "29384",
    "label": "Waterloo - SC 29384",
    "county": "Laurens County",
    "state": "SC",
    "city": "Waterloo"
  },
  {
    "value": "29385",
    "label": "Wellford - SC 29385",
    "county": "Spartanburg County",
    "state": "SC",
    "city": "Wellford"
  },
  {
    "value": "29388",
    "label": "Woodruff - SC 29388",
    "county": "Spartanburg County",
    "state": "SC",
    "city": "Woodruff"
  },
  {
    "value": "29401",
    "label": "Charleston - SC 29401",
    "county": "Charleston County",
    "state": "SC",
    "city": "Charleston"
  },
  {
    "value": "29403",
    "label": "Charleston - SC 29403",
    "county": "Charleston County",
    "state": "SC",
    "city": "Charleston"
  },
  {
    "value": "29404",
    "label": "Charleston AFB - SC 29404",
    "county": "Charleston County",
    "state": "SC",
    "city": "Charleston AFB"
  },
  {
    "value": "29405",
    "label": "North Charleston - SC 29405",
    "county": "Charleston County",
    "state": "SC",
    "city": "North Charleston"
  },
  {
    "value": "29406",
    "label": "Charleston - SC 29406",
    "county": "Charleston County",
    "state": "SC",
    "city": "Charleston"
  },
  {
    "value": "29407",
    "label": "Charleston - SC 29407",
    "county": "Charleston County",
    "state": "SC",
    "city": "Charleston"
  },
  {
    "value": "29410",
    "label": "Hanahan - SC 29410",
    "county": "Berkeley County",
    "state": "SC",
    "city": "Hanahan"
  },
  {
    "value": "29412",
    "label": "Charleston - SC 29412",
    "county": "Charleston County",
    "state": "SC",
    "city": "Charleston"
  },
  {
    "value": "29414",
    "label": "Charleston - SC 29414",
    "county": "Charleston County",
    "state": "SC",
    "city": "Charleston"
  },
  {
    "value": "29418",
    "label": "North Charleston - SC 29418",
    "county": "Charleston County",
    "state": "SC",
    "city": "North Charleston"
  },
  {
    "value": "29420",
    "label": "North Charleston - SC 29420",
    "county": "Dorchester County",
    "state": "SC",
    "city": "North Charleston"
  },
  {
    "value": "29426",
    "label": "Adams Run - SC 29426",
    "county": "Charleston County",
    "state": "SC",
    "city": "Adams Run"
  },
  {
    "value": "29429",
    "label": "Awendaw - SC 29429",
    "county": "Charleston County",
    "state": "SC",
    "city": "Awendaw"
  },
  {
    "value": "29431",
    "label": "Bonneau - SC 29431",
    "county": "Berkeley County",
    "state": "SC",
    "city": "Bonneau"
  },
  {
    "value": "29432",
    "label": "Branchville - SC 29432",
    "county": "Orangeburg County",
    "state": "SC",
    "city": "Branchville"
  },
  {
    "value": "29434",
    "label": "Cordesville - SC 29434",
    "county": "Berkeley County",
    "state": "SC",
    "city": "Cordesville"
  },
  {
    "value": "29435",
    "label": "Cottageville - SC 29435",
    "county": "Colleton County",
    "state": "SC",
    "city": "Cottageville"
  },
  {
    "value": "29436",
    "label": "Cross - SC 29436",
    "county": "Berkeley County",
    "state": "SC",
    "city": "Cross"
  },
  {
    "value": "29437",
    "label": "Dorchester - SC 29437",
    "county": "Dorchester County",
    "state": "SC",
    "city": "Dorchester"
  },
  {
    "value": "29438",
    "label": "Edisto Island - SC 29438",
    "county": "Charleston County",
    "state": "SC",
    "city": "Edisto Island"
  },
  {
    "value": "29440",
    "label": "Georgetown - SC 29440",
    "county": "Georgetown County",
    "state": "SC",
    "city": "Georgetown"
  },
  {
    "value": "29445",
    "label": "Goose Creek - SC 29445",
    "county": "Berkeley County",
    "state": "SC",
    "city": "Goose Creek"
  },
  {
    "value": "29446",
    "label": "Green Pond - SC 29446",
    "county": "Colleton County",
    "state": "SC",
    "city": "Green Pond"
  },
  {
    "value": "29448",
    "label": "Harleyville - SC 29448",
    "county": "Dorchester County",
    "state": "SC",
    "city": "Harleyville"
  },
  {
    "value": "29449",
    "label": "Hollywood - SC 29449",
    "county": "Charleston County",
    "state": "SC",
    "city": "Hollywood"
  },
  {
    "value": "29450",
    "label": "Huger - SC 29450",
    "county": "Berkeley County",
    "state": "SC",
    "city": "Huger"
  },
  {
    "value": "29451",
    "label": "Isle Of Palms - SC 29451",
    "county": "Charleston County",
    "state": "SC",
    "city": "Isle Of Palms"
  },
  {
    "value": "29453",
    "label": "Jamestown - SC 29453",
    "county": "Berkeley County",
    "state": "SC",
    "city": "Jamestown"
  },
  {
    "value": "29455",
    "label": "Johns Island - SC 29455",
    "county": "Charleston County",
    "state": "SC",
    "city": "Johns Island"
  },
  {
    "value": "29456",
    "label": "Ladson - SC 29456",
    "county": "Dorchester County",
    "state": "SC",
    "city": "Ladson"
  },
  {
    "value": "29458",
    "label": "Mc Clellanville - SC 29458",
    "county": "Charleston County",
    "state": "SC",
    "city": "Mc Clellanville"
  },
  {
    "value": "29461",
    "label": "Moncks Corner - SC 29461",
    "county": "Berkeley County",
    "state": "SC",
    "city": "Moncks Corner"
  },
  {
    "value": "29464",
    "label": "Mount Pleasant - SC 29464",
    "county": "Charleston County",
    "state": "SC",
    "city": "Mount Pleasant"
  },
  {
    "value": "29466",
    "label": "Mount Pleasant - SC 29466",
    "county": "Charleston County",
    "state": "SC",
    "city": "Mount Pleasant"
  },
  {
    "value": "29468",
    "label": "Pineville - SC 29468",
    "county": "Berkeley County",
    "state": "SC",
    "city": "Pineville"
  },
  {
    "value": "29469",
    "label": "Pinopolis - SC 29469",
    "county": "Berkeley County",
    "state": "SC",
    "city": "Pinopolis"
  },
  {
    "value": "29470",
    "label": "Ravenel - SC 29470",
    "county": "Charleston County",
    "state": "SC",
    "city": "Ravenel"
  },
  {
    "value": "29471",
    "label": "Reevesville - SC 29471",
    "county": "Dorchester County",
    "state": "SC",
    "city": "Reevesville"
  },
  {
    "value": "29472",
    "label": "Ridgeville - SC 29472",
    "county": "Dorchester County",
    "state": "SC",
    "city": "Ridgeville"
  },
  {
    "value": "29474",
    "label": "Round O - SC 29474",
    "county": "Colleton County",
    "state": "SC",
    "city": "Round O"
  },
  {
    "value": "29475",
    "label": "Ruffin - SC 29475",
    "county": "Colleton County",
    "state": "SC",
    "city": "Ruffin"
  },
  {
    "value": "29477",
    "label": "Saint George - SC 29477",
    "county": "Dorchester County",
    "state": "SC",
    "city": "Saint George"
  },
  {
    "value": "29479",
    "label": "Saint Stephen - SC 29479",
    "county": "Berkeley County",
    "state": "SC",
    "city": "Saint Stephen"
  },
  {
    "value": "29481",
    "label": "Smoaks - SC 29481",
    "county": "Colleton County",
    "state": "SC",
    "city": "Smoaks"
  },
  {
    "value": "29482",
    "label": "Sullivans Island - SC 29482",
    "county": "Charleston County",
    "state": "SC",
    "city": "Sullivans Island"
  },
  {
    "value": "29483",
    "label": "Summerville - SC 29483",
    "county": "Dorchester County",
    "state": "SC",
    "city": "Summerville"
  },
  {
    "value": "29485",
    "label": "Summerville - SC 29485",
    "county": "Dorchester County",
    "state": "SC",
    "city": "Summerville"
  },
  {
    "value": "29486",
    "label": "Summerville - SC 29486",
    "county": "Berkeley County",
    "state": "SC",
    "city": "Summerville"
  },
  {
    "value": "29487",
    "label": "Wadmalaw Island - SC 29487",
    "county": "Charleston County",
    "state": "SC",
    "city": "Wadmalaw Island"
  },
  {
    "value": "29488",
    "label": "Walterboro - SC 29488",
    "county": "Colleton County",
    "state": "SC",
    "city": "Walterboro"
  },
  {
    "value": "29492",
    "label": "Charleston - SC 29492",
    "county": "Berkeley County",
    "state": "SC",
    "city": "Charleston"
  },
  {
    "value": "29501",
    "label": "Florence - SC 29501",
    "county": "Florence County",
    "state": "SC",
    "city": "Florence"
  },
  {
    "value": "29505",
    "label": "Florence - SC 29505",
    "county": "Florence County",
    "state": "SC",
    "city": "Florence"
  },
  {
    "value": "29506",
    "label": "Florence - SC 29506",
    "county": "Florence County",
    "state": "SC",
    "city": "Florence"
  },
  {
    "value": "29510",
    "label": "Andrews - SC 29510",
    "county": "Georgetown County",
    "state": "SC",
    "city": "Andrews"
  },
  {
    "value": "29511",
    "label": "Aynor - SC 29511",
    "county": "Horry County",
    "state": "SC",
    "city": "Aynor"
  },
  {
    "value": "29512",
    "label": "Bennettsville - SC 29512",
    "county": "Marlboro County",
    "state": "SC",
    "city": "Bennettsville"
  },
  {
    "value": "29516",
    "label": "Blenheim - SC 29516",
    "county": "Marlboro County",
    "state": "SC",
    "city": "Blenheim"
  },
  {
    "value": "29518",
    "label": "Cades - SC 29518",
    "county": "Williamsburg County",
    "state": "SC",
    "city": "Cades"
  },
  {
    "value": "29520",
    "label": "Cheraw - SC 29520",
    "county": "Chesterfield County",
    "state": "SC",
    "city": "Cheraw"
  },
  {
    "value": "29525",
    "label": "Clio - SC 29525",
    "county": "Marlboro County",
    "state": "SC",
    "city": "Clio"
  },
  {
    "value": "29526",
    "label": "Conway - SC 29526",
    "county": "Horry County",
    "state": "SC",
    "city": "Conway"
  },
  {
    "value": "29527",
    "label": "Conway - SC 29527",
    "county": "Horry County",
    "state": "SC",
    "city": "Conway"
  },
  {
    "value": "29530",
    "label": "Coward - SC 29530",
    "county": "Florence County",
    "state": "SC",
    "city": "Coward"
  },
  {
    "value": "29532",
    "label": "Darlington - SC 29532",
    "county": "Darlington County",
    "state": "SC",
    "city": "Darlington"
  },
  {
    "value": "29536",
    "label": "Dillon - SC 29536",
    "county": "Dillon County",
    "state": "SC",
    "city": "Dillon"
  },
  {
    "value": "29540",
    "label": "Darlington - SC 29540",
    "county": "Darlington County",
    "state": "SC",
    "city": "Darlington"
  },
  {
    "value": "29541",
    "label": "Effingham - SC 29541",
    "county": "Florence County",
    "state": "SC",
    "city": "Effingham"
  },
  {
    "value": "29543",
    "label": "Fork - SC 29543",
    "county": "Dillon County",
    "state": "SC",
    "city": "Fork"
  },
  {
    "value": "29544",
    "label": "Galivants Ferry - SC 29544",
    "county": "Horry County",
    "state": "SC",
    "city": "Galivants Ferry"
  },
  {
    "value": "29545",
    "label": "Green Sea - SC 29545",
    "county": "Horry County",
    "state": "SC",
    "city": "Green Sea"
  },
  {
    "value": "29546",
    "label": "Gresham - SC 29546",
    "county": "Marion County",
    "state": "SC",
    "city": "Gresham"
  },
  {
    "value": "29547",
    "label": "Hamer - SC 29547",
    "county": "Dillon County",
    "state": "SC",
    "city": "Hamer"
  },
  {
    "value": "29550",
    "label": "Hartsville - SC 29550",
    "county": "Darlington County",
    "state": "SC",
    "city": "Hartsville"
  },
  {
    "value": "29554",
    "label": "Hemingway - SC 29554",
    "county": "Williamsburg County",
    "state": "SC",
    "city": "Hemingway"
  },
  {
    "value": "29555",
    "label": "Johnsonville - SC 29555",
    "county": "Florence County",
    "state": "SC",
    "city": "Johnsonville"
  },
  {
    "value": "29556",
    "label": "Kingstree - SC 29556",
    "county": "Williamsburg County",
    "state": "SC",
    "city": "Kingstree"
  },
  {
    "value": "29560",
    "label": "Lake City - SC 29560",
    "county": "Florence County",
    "state": "SC",
    "city": "Lake City"
  },
  {
    "value": "29563",
    "label": "Lake View - SC 29563",
    "county": "Dillon County",
    "state": "SC",
    "city": "Lake View"
  },
  {
    "value": "29564",
    "label": "Lane - SC 29564",
    "county": "Williamsburg County",
    "state": "SC",
    "city": "Lane"
  },
  {
    "value": "29565",
    "label": "Latta - SC 29565",
    "county": "Dillon County",
    "state": "SC",
    "city": "Latta"
  },
  {
    "value": "29566",
    "label": "Little River - SC 29566",
    "county": "Horry County",
    "state": "SC",
    "city": "Little River"
  },
  {
    "value": "29567",
    "label": "Little Rock - SC 29567",
    "county": "Dillon County",
    "state": "SC",
    "city": "Little Rock"
  },
  {
    "value": "29568",
    "label": "Longs - SC 29568",
    "county": "Horry County",
    "state": "SC",
    "city": "Longs"
  },
  {
    "value": "29569",
    "label": "Loris - SC 29569",
    "county": "Horry County",
    "state": "SC",
    "city": "Loris"
  },
  {
    "value": "29570",
    "label": "Mc Coll - SC 29570",
    "county": "Marlboro County",
    "state": "SC",
    "city": "Mc Coll"
  },
  {
    "value": "29571",
    "label": "Marion - SC 29571",
    "county": "Marion County",
    "state": "SC",
    "city": "Marion"
  },
  {
    "value": "29572",
    "label": "Myrtle Beach - SC 29572",
    "county": "Horry County",
    "state": "SC",
    "city": "Myrtle Beach"
  },
  {
    "value": "29574",
    "label": "Mullins - SC 29574",
    "county": "Marion County",
    "state": "SC",
    "city": "Mullins"
  },
  {
    "value": "29575",
    "label": "Myrtle Beach - SC 29575",
    "county": "Horry County",
    "state": "SC",
    "city": "Myrtle Beach"
  },
  {
    "value": "29576",
    "label": "Murrells Inlet - SC 29576",
    "county": "Horry County",
    "state": "SC",
    "city": "Murrells Inlet"
  },
  {
    "value": "29577",
    "label": "Myrtle Beach - SC 29577",
    "county": "Horry County",
    "state": "SC",
    "city": "Myrtle Beach"
  },
  {
    "value": "29579",
    "label": "Myrtle Beach - SC 29579",
    "county": "Horry County",
    "state": "SC",
    "city": "Myrtle Beach"
  },
  {
    "value": "29580",
    "label": "Nesmith - SC 29580",
    "county": "Williamsburg County",
    "state": "SC",
    "city": "Nesmith"
  },
  {
    "value": "29581",
    "label": "Nichols - SC 29581",
    "county": "Horry County",
    "state": "SC",
    "city": "Nichols"
  },
  {
    "value": "29582",
    "label": "North Myrtle Beach - SC 29582",
    "county": "Horry County",
    "state": "SC",
    "city": "North Myrtle Beach"
  },
  {
    "value": "29583",
    "label": "Pamplico - SC 29583",
    "county": "Florence County",
    "state": "SC",
    "city": "Pamplico"
  },
  {
    "value": "29584",
    "label": "Patrick - SC 29584",
    "county": "Chesterfield County",
    "state": "SC",
    "city": "Patrick"
  },
  {
    "value": "29585",
    "label": "Pawleys Island - SC 29585",
    "county": "Georgetown County",
    "state": "SC",
    "city": "Pawleys Island"
  },
  {
    "value": "29588",
    "label": "Myrtle Beach - SC 29588",
    "county": "Horry County",
    "state": "SC",
    "city": "Myrtle Beach"
  },
  {
    "value": "29590",
    "label": "Salters - SC 29590",
    "county": "Williamsburg County",
    "state": "SC",
    "city": "Salters"
  },
  {
    "value": "29591",
    "label": "Scranton - SC 29591",
    "county": "Florence County",
    "state": "SC",
    "city": "Scranton"
  },
  {
    "value": "29592",
    "label": "Sellers - SC 29592",
    "county": "Dillon County",
    "state": "SC",
    "city": "Sellers"
  },
  {
    "value": "29593",
    "label": "Society Hill - SC 29593",
    "county": "Darlington County",
    "state": "SC",
    "city": "Society Hill"
  },
  {
    "value": "29596",
    "label": "Wallace - SC 29596",
    "county": "Marlboro County",
    "state": "SC",
    "city": "Wallace"
  },
  {
    "value": "29601",
    "label": "Greenville - SC 29601",
    "county": "Greenville County",
    "state": "SC",
    "city": "Greenville"
  },
  {
    "value": "29605",
    "label": "Greenville - SC 29605",
    "county": "Greenville County",
    "state": "SC",
    "city": "Greenville"
  },
  {
    "value": "29607",
    "label": "Greenville - SC 29607",
    "county": "Greenville County",
    "state": "SC",
    "city": "Greenville"
  },
  {
    "value": "29609",
    "label": "Greenville - SC 29609",
    "county": "Greenville County",
    "state": "SC",
    "city": "Greenville"
  },
  {
    "value": "29611",
    "label": "Greenville - SC 29611",
    "county": "Greenville County",
    "state": "SC",
    "city": "Greenville"
  },
  {
    "value": "29615",
    "label": "Greenville - SC 29615",
    "county": "Greenville County",
    "state": "SC",
    "city": "Greenville"
  },
  {
    "value": "29617",
    "label": "Greenville - SC 29617",
    "county": "Greenville County",
    "state": "SC",
    "city": "Greenville"
  },
  {
    "value": "29620",
    "label": "Abbeville - SC 29620",
    "county": "Abbeville County",
    "state": "SC",
    "city": "Abbeville"
  },
  {
    "value": "29621",
    "label": "Anderson - SC 29621",
    "county": "Anderson County",
    "state": "SC",
    "city": "Anderson"
  },
  {
    "value": "29624",
    "label": "Anderson - SC 29624",
    "county": "Anderson County",
    "state": "SC",
    "city": "Anderson"
  },
  {
    "value": "29625",
    "label": "Anderson - SC 29625",
    "county": "Anderson County",
    "state": "SC",
    "city": "Anderson"
  },
  {
    "value": "29626",
    "label": "Anderson - SC 29626",
    "county": "Anderson County",
    "state": "SC",
    "city": "Anderson"
  },
  {
    "value": "29627",
    "label": "Belton - SC 29627",
    "county": "Anderson County",
    "state": "SC",
    "city": "Belton"
  },
  {
    "value": "29628",
    "label": "Calhoun Falls - SC 29628",
    "county": "Abbeville County",
    "state": "SC",
    "city": "Calhoun Falls"
  },
  {
    "value": "29630",
    "label": "Central - SC 29630",
    "county": "Pickens County",
    "state": "SC",
    "city": "Central"
  },
  {
    "value": "29631",
    "label": "Clemson - SC 29631",
    "county": "Pickens County",
    "state": "SC",
    "city": "Clemson"
  },
  {
    "value": "29635",
    "label": "Cleveland - SC 29635",
    "county": "Greenville County",
    "state": "SC",
    "city": "Cleveland"
  },
  {
    "value": "29638",
    "label": "Donalds - SC 29638",
    "county": "Abbeville County",
    "state": "SC",
    "city": "Donalds"
  },
  {
    "value": "29639",
    "label": "Due West - SC 29639",
    "county": "Abbeville County",
    "state": "SC",
    "city": "Due West"
  },
  {
    "value": "29640",
    "label": "Easley - SC 29640",
    "county": "Pickens County",
    "state": "SC",
    "city": "Easley"
  },
  {
    "value": "29642",
    "label": "Easley - SC 29642",
    "county": "Pickens County",
    "state": "SC",
    "city": "Easley"
  },
  {
    "value": "29643",
    "label": "Fair Play - SC 29643",
    "county": "Oconee County",
    "state": "SC",
    "city": "Fair Play"
  },
  {
    "value": "29644",
    "label": "Fountain Inn - SC 29644",
    "county": "Greenville County",
    "state": "SC",
    "city": "Fountain Inn"
  },
  {
    "value": "29645",
    "label": "Gray Court - SC 29645",
    "county": "Laurens County",
    "state": "SC",
    "city": "Gray Court"
  },
  {
    "value": "29646",
    "label": "Greenwood - SC 29646",
    "county": "Greenwood County",
    "state": "SC",
    "city": "Greenwood"
  },
  {
    "value": "29649",
    "label": "Greenwood - SC 29649",
    "county": "Greenwood County",
    "state": "SC",
    "city": "Greenwood"
  },
  {
    "value": "29650",
    "label": "Greer - SC 29650",
    "county": "Greenville County",
    "state": "SC",
    "city": "Greer"
  },
  {
    "value": "29651",
    "label": "Greer - SC 29651",
    "county": "Greenville County",
    "state": "SC",
    "city": "Greer"
  },
  {
    "value": "29653",
    "label": "Hodges - SC 29653",
    "county": "Greenwood County",
    "state": "SC",
    "city": "Hodges"
  },
  {
    "value": "29654",
    "label": "Honea Path - SC 29654",
    "county": "Anderson County",
    "state": "SC",
    "city": "Honea Path"
  },
  {
    "value": "29655",
    "label": "Iva - SC 29655",
    "county": "Anderson County",
    "state": "SC",
    "city": "Iva"
  },
  {
    "value": "29657",
    "label": "Liberty - SC 29657",
    "county": "Pickens County",
    "state": "SC",
    "city": "Liberty"
  },
  {
    "value": "29658",
    "label": "Long Creek - SC 29658",
    "county": "Oconee County",
    "state": "SC",
    "city": "Long Creek"
  },
  {
    "value": "29661",
    "label": "Marietta - SC 29661",
    "county": "Greenville County",
    "state": "SC",
    "city": "Marietta"
  },
  {
    "value": "29662",
    "label": "Mauldin - SC 29662",
    "county": "Greenville County",
    "state": "SC",
    "city": "Mauldin"
  },
  {
    "value": "29664",
    "label": "Mountain Rest - SC 29664",
    "county": "Oconee County",
    "state": "SC",
    "city": "Mountain Rest"
  },
  {
    "value": "29666",
    "label": "Ninety Six - SC 29666",
    "county": "Greenwood County",
    "state": "SC",
    "city": "Ninety Six"
  },
  {
    "value": "29667",
    "label": "Norris - SC 29667",
    "county": "Pickens County",
    "state": "SC",
    "city": "Norris"
  },
  {
    "value": "29669",
    "label": "Pelzer - SC 29669",
    "county": "Anderson County",
    "state": "SC",
    "city": "Pelzer"
  },
  {
    "value": "29670",
    "label": "Pendleton - SC 29670",
    "county": "Anderson County",
    "state": "SC",
    "city": "Pendleton"
  },
  {
    "value": "29671",
    "label": "Pickens - SC 29671",
    "county": "Pickens County",
    "state": "SC",
    "city": "Pickens"
  },
  {
    "value": "29672",
    "label": "Seneca - SC 29672",
    "county": "Oconee County",
    "state": "SC",
    "city": "Seneca"
  },
  {
    "value": "29673",
    "label": "Piedmont - SC 29673",
    "county": "Greenville County",
    "state": "SC",
    "city": "Piedmont"
  },
  {
    "value": "29676",
    "label": "Salem - SC 29676",
    "county": "Oconee County",
    "state": "SC",
    "city": "Salem"
  },
  {
    "value": "29678",
    "label": "Seneca - SC 29678",
    "county": "Oconee County",
    "state": "SC",
    "city": "Seneca"
  },
  {
    "value": "29680",
    "label": "Simpsonville - SC 29680",
    "county": "Greenville County",
    "state": "SC",
    "city": "Simpsonville"
  },
  {
    "value": "29681",
    "label": "Simpsonville - SC 29681",
    "county": "Greenville County",
    "state": "SC",
    "city": "Simpsonville"
  },
  {
    "value": "29682",
    "label": "Six Mile - SC 29682",
    "county": "Pickens County",
    "state": "SC",
    "city": "Six Mile"
  },
  {
    "value": "29684",
    "label": "Starr - SC 29684",
    "county": "Anderson County",
    "state": "SC",
    "city": "Starr"
  },
  {
    "value": "29685",
    "label": "Sunset - SC 29685",
    "county": "Pickens County",
    "state": "SC",
    "city": "Sunset"
  },
  {
    "value": "29686",
    "label": "Tamassee - SC 29686",
    "county": "Oconee County",
    "state": "SC",
    "city": "Tamassee"
  },
  {
    "value": "29687",
    "label": "Taylors - SC 29687",
    "county": "Greenville County",
    "state": "SC",
    "city": "Taylors"
  },
  {
    "value": "29689",
    "label": "Townville - SC 29689",
    "county": "Anderson County",
    "state": "SC",
    "city": "Townville"
  },
  {
    "value": "29690",
    "label": "Travelers Rest - SC 29690",
    "county": "Greenville County",
    "state": "SC",
    "city": "Travelers Rest"
  },
  {
    "value": "29691",
    "label": "Walhalla - SC 29691",
    "county": "Oconee County",
    "state": "SC",
    "city": "Walhalla"
  },
  {
    "value": "29692",
    "label": "Ware Shoals - SC 29692",
    "county": "Laurens County",
    "state": "SC",
    "city": "Ware Shoals"
  },
  {
    "value": "29693",
    "label": "Westminster - SC 29693",
    "county": "Oconee County",
    "state": "SC",
    "city": "Westminster"
  },
  {
    "value": "29696",
    "label": "West Union - SC 29696",
    "county": "Oconee County",
    "state": "SC",
    "city": "West Union"
  },
  {
    "value": "29697",
    "label": "Williamston - SC 29697",
    "county": "Anderson County",
    "state": "SC",
    "city": "Williamston"
  },
  {
    "value": "29702",
    "label": "Blacksburg - SC 29702",
    "county": "Cherokee County",
    "state": "SC",
    "city": "Blacksburg"
  },
  {
    "value": "29704",
    "label": "Catawba - SC 29704",
    "county": "York County",
    "state": "SC",
    "city": "Catawba"
  },
  {
    "value": "29706",
    "label": "Chester - SC 29706",
    "county": "Chester County",
    "state": "SC",
    "city": "Chester"
  },
  {
    "value": "29707",
    "label": "Fort Mill - SC 29707",
    "county": "Lancaster County",
    "state": "SC",
    "city": "Fort Mill"
  },
  {
    "value": "29708",
    "label": "Fort Mill - SC 29708",
    "county": "York County",
    "state": "SC",
    "city": "Fort Mill"
  },
  {
    "value": "29709",
    "label": "Chesterfield - SC 29709",
    "county": "Chesterfield County",
    "state": "SC",
    "city": "Chesterfield"
  },
  {
    "value": "29710",
    "label": "Clover - SC 29710",
    "county": "York County",
    "state": "SC",
    "city": "Clover"
  },
  {
    "value": "29712",
    "label": "Edgemoor - SC 29712",
    "county": "Chester County",
    "state": "SC",
    "city": "Edgemoor"
  },
  {
    "value": "29714",
    "label": "Fort Lawn - SC 29714",
    "county": "Chester County",
    "state": "SC",
    "city": "Fort Lawn"
  },
  {
    "value": "29715",
    "label": "Fort Mill - SC 29715",
    "county": "York County",
    "state": "SC",
    "city": "Fort Mill"
  },
  {
    "value": "29717",
    "label": "Hickory Grove - SC 29717",
    "county": "York County",
    "state": "SC",
    "city": "Hickory Grove"
  },
  {
    "value": "29718",
    "label": "Jefferson - SC 29718",
    "county": "Chesterfield County",
    "state": "SC",
    "city": "Jefferson"
  },
  {
    "value": "29720",
    "label": "Lancaster - SC 29720",
    "county": "Lancaster County",
    "state": "SC",
    "city": "Lancaster"
  },
  {
    "value": "29726",
    "label": "Mc Connells - SC 29726",
    "county": "York County",
    "state": "SC",
    "city": "Mc Connells"
  },
  {
    "value": "29727",
    "label": "Mount Croghan - SC 29727",
    "county": "Chesterfield County",
    "state": "SC",
    "city": "Mount Croghan"
  },
  {
    "value": "29728",
    "label": "Pageland - SC 29728",
    "county": "Chesterfield County",
    "state": "SC",
    "city": "Pageland"
  },
  {
    "value": "29729",
    "label": "Richburg - SC 29729",
    "county": "Chester County",
    "state": "SC",
    "city": "Richburg"
  },
  {
    "value": "29730",
    "label": "Rock Hill - SC 29730",
    "county": "York County",
    "state": "SC",
    "city": "Rock Hill"
  },
  {
    "value": "29732",
    "label": "Rock Hill - SC 29732",
    "county": "York County",
    "state": "SC",
    "city": "Rock Hill"
  },
  {
    "value": "29734",
    "label": "Rock Hill - SC 29734",
    "county": "York County",
    "state": "SC",
    "city": "Rock Hill"
  },
  {
    "value": "29741",
    "label": "Ruby - SC 29741",
    "county": "Chesterfield County",
    "state": "SC",
    "city": "Ruby"
  },
  {
    "value": "29742",
    "label": "Sharon - SC 29742",
    "county": "York County",
    "state": "SC",
    "city": "Sharon"
  },
  {
    "value": "29743",
    "label": "Smyrna - SC 29743",
    "county": "York County",
    "state": "SC",
    "city": "Smyrna"
  },
  {
    "value": "29745",
    "label": "York - SC 29745",
    "county": "York County",
    "state": "SC",
    "city": "York"
  },
  {
    "value": "29801",
    "label": "Aiken - SC 29801",
    "county": "Aiken County",
    "state": "SC",
    "city": "Aiken"
  },
  {
    "value": "29803",
    "label": "Aiken - SC 29803",
    "county": "Aiken County",
    "state": "SC",
    "city": "Aiken"
  },
  {
    "value": "29805",
    "label": "Aiken - SC 29805",
    "county": "Aiken County",
    "state": "SC",
    "city": "Aiken"
  },
  {
    "value": "29809",
    "label": "New Ellenton - SC 29809",
    "county": "Aiken County",
    "state": "SC",
    "city": "New Ellenton"
  },
  {
    "value": "29810",
    "label": "Allendale - SC 29810",
    "county": "Allendale County",
    "state": "SC",
    "city": "Allendale"
  },
  {
    "value": "29812",
    "label": "Barnwell - SC 29812",
    "county": "Barnwell County",
    "state": "SC",
    "city": "Barnwell"
  },
  {
    "value": "29817",
    "label": "Blackville - SC 29817",
    "county": "Barnwell County",
    "state": "SC",
    "city": "Blackville"
  },
  {
    "value": "29819",
    "label": "Bradley - SC 29819",
    "county": "Greenwood County",
    "state": "SC",
    "city": "Bradley"
  },
  {
    "value": "29821",
    "label": "Clarks Hill - SC 29821",
    "county": "McCormick County",
    "state": "SC",
    "city": "Clarks Hill"
  },
  {
    "value": "29824",
    "label": "Edgefield - SC 29824",
    "county": "Edgefield County",
    "state": "SC",
    "city": "Edgefield"
  },
  {
    "value": "29827",
    "label": "Fairfax - SC 29827",
    "county": "Allendale County",
    "state": "SC",
    "city": "Fairfax"
  },
  {
    "value": "29828",
    "label": "Gloverville - SC 29828",
    "county": "Aiken County",
    "state": "SC",
    "city": "Gloverville"
  },
  {
    "value": "29829",
    "label": "Graniteville - SC 29829",
    "county": "Aiken County",
    "state": "SC",
    "city": "Graniteville"
  },
  {
    "value": "29831",
    "label": "Jackson - SC 29831",
    "county": "Aiken County",
    "state": "SC",
    "city": "Jackson"
  },
  {
    "value": "29832",
    "label": "Johnston - SC 29832",
    "county": "Edgefield County",
    "state": "SC",
    "city": "Johnston"
  },
  {
    "value": "29835",
    "label": "Mc Cormick - SC 29835",
    "county": "McCormick County",
    "state": "SC",
    "city": "Mc Cormick"
  },
  {
    "value": "29836",
    "label": "Martin - SC 29836",
    "county": "Allendale County",
    "state": "SC",
    "city": "Martin"
  },
  {
    "value": "29838",
    "label": "Modoc - SC 29838",
    "county": "Edgefield County",
    "state": "SC",
    "city": "Modoc"
  },
  {
    "value": "29840",
    "label": "Mount Carmel - SC 29840",
    "county": "McCormick County",
    "state": "SC",
    "city": "Mount Carmel"
  },
  {
    "value": "29841",
    "label": "North Augusta - SC 29841",
    "county": "Aiken County",
    "state": "SC",
    "city": "North Augusta"
  },
  {
    "value": "29842",
    "label": "Beech Island - SC 29842",
    "county": "Aiken County",
    "state": "SC",
    "city": "Beech Island"
  },
  {
    "value": "29843",
    "label": "Olar - SC 29843",
    "county": "Bamberg County",
    "state": "SC",
    "city": "Olar"
  },
  {
    "value": "29845",
    "label": "Plum Branch - SC 29845",
    "county": "McCormick County",
    "state": "SC",
    "city": "Plum Branch"
  },
  {
    "value": "29847",
    "label": "Trenton - SC 29847",
    "county": "Edgefield County",
    "state": "SC",
    "city": "Trenton"
  },
  {
    "value": "29848",
    "label": "Troy - SC 29848",
    "county": "Greenwood County",
    "state": "SC",
    "city": "Troy"
  },
  {
    "value": "29849",
    "label": "Ulmer - SC 29849",
    "county": "Allendale County",
    "state": "SC",
    "city": "Ulmer"
  },
  {
    "value": "29851",
    "label": "Warrenville - SC 29851",
    "county": "Aiken County",
    "state": "SC",
    "city": "Warrenville"
  },
  {
    "value": "29853",
    "label": "Williston - SC 29853",
    "county": "Barnwell County",
    "state": "SC",
    "city": "Williston"
  },
  {
    "value": "29856",
    "label": "Windsor - SC 29856",
    "county": "Aiken County",
    "state": "SC",
    "city": "Windsor"
  },
  {
    "value": "29860",
    "label": "North Augusta - SC 29860",
    "county": "Edgefield County",
    "state": "SC",
    "city": "North Augusta"
  },
  {
    "value": "29902",
    "label": "Beaufort - SC 29902",
    "county": "Beaufort County",
    "state": "SC",
    "city": "Beaufort"
  },
  {
    "value": "29906",
    "label": "Beaufort - SC 29906",
    "county": "Beaufort County",
    "state": "SC",
    "city": "Beaufort"
  },
  {
    "value": "29907",
    "label": "Beaufort - SC 29907",
    "county": "Beaufort County",
    "state": "SC",
    "city": "Beaufort"
  },
  {
    "value": "29909",
    "label": "Okatie - SC 29909",
    "county": "Beaufort County",
    "state": "SC",
    "city": "Okatie"
  },
  {
    "value": "29910",
    "label": "Bluffton - SC 29910",
    "county": "Beaufort County",
    "state": "SC",
    "city": "Bluffton"
  },
  {
    "value": "29911",
    "label": "Brunson - SC 29911",
    "county": "Hampton County",
    "state": "SC",
    "city": "Brunson"
  },
  {
    "value": "29912",
    "label": "Coosawhatchie - SC 29912",
    "county": "Jasper County",
    "state": "SC",
    "city": "Coosawhatchie"
  },
  {
    "value": "29915",
    "label": "Daufuskie Island - SC 29915",
    "county": "Beaufort County",
    "state": "SC",
    "city": "Daufuskie Island"
  },
  {
    "value": "29916",
    "label": "Early Branch - SC 29916",
    "county": "Hampton County",
    "state": "SC",
    "city": "Early Branch"
  },
  {
    "value": "29918",
    "label": "Estill - SC 29918",
    "county": "Hampton County",
    "state": "SC",
    "city": "Estill"
  },
  {
    "value": "29920",
    "label": "Saint Helena Island - SC 29920",
    "county": "Beaufort County",
    "state": "SC",
    "city": "Saint Helena Island"
  },
  {
    "value": "29922",
    "label": "Garnett - SC 29922",
    "county": "Hampton County",
    "state": "SC",
    "city": "Garnett"
  },
  {
    "value": "29924",
    "label": "Hampton - SC 29924",
    "county": "Hampton County",
    "state": "SC",
    "city": "Hampton"
  },
  {
    "value": "29926",
    "label": "Hilton Head Island - SC 29926",
    "county": "Beaufort County",
    "state": "SC",
    "city": "Hilton Head Island"
  },
  {
    "value": "29927",
    "label": "Hardeeville - SC 29927",
    "county": "Jasper County",
    "state": "SC",
    "city": "Hardeeville"
  },
  {
    "value": "29928",
    "label": "Hilton Head Island - SC 29928",
    "county": "Beaufort County",
    "state": "SC",
    "city": "Hilton Head Island"
  },
  {
    "value": "29929",
    "label": "Islandton - SC 29929",
    "county": "Colleton County",
    "state": "SC",
    "city": "Islandton"
  },
  {
    "value": "29932",
    "label": "Luray - SC 29932",
    "county": "Hampton County",
    "state": "SC",
    "city": "Luray"
  },
  {
    "value": "29934",
    "label": "Pineland - SC 29934",
    "county": "Jasper County",
    "state": "SC",
    "city": "Pineland"
  },
  {
    "value": "29935",
    "label": "Port Royal - SC 29935",
    "county": "Beaufort County",
    "state": "SC",
    "city": "Port Royal"
  },
  {
    "value": "29936",
    "label": "Ridgeland - SC 29936",
    "county": "Jasper County",
    "state": "SC",
    "city": "Ridgeland"
  },
  {
    "value": "29940",
    "label": "Seabrook - SC 29940",
    "county": "Beaufort County",
    "state": "SC",
    "city": "Seabrook"
  },
  {
    "value": "29941",
    "label": "Sheldon - SC 29941",
    "county": "Beaufort County",
    "state": "SC",
    "city": "Sheldon"
  },
  {
    "value": "29943",
    "label": "Tillman - SC 29943",
    "county": "Jasper County",
    "state": "SC",
    "city": "Tillman"
  },
  {
    "value": "29944",
    "label": "Varnville - SC 29944",
    "county": "Hampton County",
    "state": "SC",
    "city": "Varnville"
  },
  {
    "value": "29945",
    "label": "Yemassee - SC 29945",
    "county": "Hampton County",
    "state": "SC",
    "city": "Yemassee"
  },
  {
    "value": "57001",
    "label": "Alcester - SD 57001",
    "county": "Union County",
    "state": "SD",
    "city": "Alcester"
  },
  {
    "value": "57002",
    "label": "Aurora - SD 57002",
    "county": "Brookings County",
    "state": "SD",
    "city": "Aurora"
  },
  {
    "value": "57003",
    "label": "Baltic - SD 57003",
    "county": "Minnehaha County",
    "state": "SD",
    "city": "Baltic"
  },
  {
    "value": "57004",
    "label": "Beresford - SD 57004",
    "county": "Union County",
    "state": "SD",
    "city": "Beresford"
  },
  {
    "value": "57005",
    "label": "Brandon - SD 57005",
    "county": "Minnehaha County",
    "state": "SD",
    "city": "Brandon"
  },
  {
    "value": "57006",
    "label": "Brookings - SD 57006",
    "county": "Brookings County",
    "state": "SD",
    "city": "Brookings"
  },
  {
    "value": "57010",
    "label": "Burbank - SD 57010",
    "county": "Clay County",
    "state": "SD",
    "city": "Burbank"
  },
  {
    "value": "57012",
    "label": "Canistota - SD 57012",
    "county": "McCook County",
    "state": "SD",
    "city": "Canistota"
  },
  {
    "value": "57013",
    "label": "Canton - SD 57013",
    "county": "Lincoln County",
    "state": "SD",
    "city": "Canton"
  },
  {
    "value": "57014",
    "label": "Centerville - SD 57014",
    "county": "Turner County",
    "state": "SD",
    "city": "Centerville"
  },
  {
    "value": "57015",
    "label": "Chancellor - SD 57015",
    "county": "Turner County",
    "state": "SD",
    "city": "Chancellor"
  },
  {
    "value": "57016",
    "label": "Chester - SD 57016",
    "county": "Lake County",
    "state": "SD",
    "city": "Chester"
  },
  {
    "value": "57017",
    "label": "Colman - SD 57017",
    "county": "Moody County",
    "state": "SD",
    "city": "Colman"
  },
  {
    "value": "57018",
    "label": "Colton - SD 57018",
    "county": "Minnehaha County",
    "state": "SD",
    "city": "Colton"
  },
  {
    "value": "57020",
    "label": "Crooks - SD 57020",
    "county": "Minnehaha County",
    "state": "SD",
    "city": "Crooks"
  },
  {
    "value": "57021",
    "label": "Davis - SD 57021",
    "county": "Turner County",
    "state": "SD",
    "city": "Davis"
  },
  {
    "value": "57022",
    "label": "Dell Rapids - SD 57022",
    "county": "Minnehaha County",
    "state": "SD",
    "city": "Dell Rapids"
  },
  {
    "value": "57024",
    "label": "Egan - SD 57024",
    "county": "Moody County",
    "state": "SD",
    "city": "Egan"
  },
  {
    "value": "57025",
    "label": "Elk Point - SD 57025",
    "county": "Union County",
    "state": "SD",
    "city": "Elk Point"
  },
  {
    "value": "57026",
    "label": "Elkton - SD 57026",
    "county": "Brookings County",
    "state": "SD",
    "city": "Elkton"
  },
  {
    "value": "57027",
    "label": "Fairview - SD 57027",
    "county": "Lincoln County",
    "state": "SD",
    "city": "Fairview"
  },
  {
    "value": "57028",
    "label": "Flandreau - SD 57028",
    "county": "Moody County",
    "state": "SD",
    "city": "Flandreau"
  },
  {
    "value": "57029",
    "label": "Freeman - SD 57029",
    "county": "Hutchinson County",
    "state": "SD",
    "city": "Freeman"
  },
  {
    "value": "57030",
    "label": "Garretson - SD 57030",
    "county": "Minnehaha County",
    "state": "SD",
    "city": "Garretson"
  },
  {
    "value": "57031",
    "label": "Gayville - SD 57031",
    "county": "Yankton County",
    "state": "SD",
    "city": "Gayville"
  },
  {
    "value": "57032",
    "label": "Harrisburg - SD 57032",
    "county": "Lincoln County",
    "state": "SD",
    "city": "Harrisburg"
  },
  {
    "value": "57033",
    "label": "Hartford - SD 57033",
    "county": "Minnehaha County",
    "state": "SD",
    "city": "Hartford"
  },
  {
    "value": "57034",
    "label": "Hudson - SD 57034",
    "county": "Lincoln County",
    "state": "SD",
    "city": "Hudson"
  },
  {
    "value": "57035",
    "label": "Humboldt - SD 57035",
    "county": "Minnehaha County",
    "state": "SD",
    "city": "Humboldt"
  },
  {
    "value": "57036",
    "label": "Hurley - SD 57036",
    "county": "Turner County",
    "state": "SD",
    "city": "Hurley"
  },
  {
    "value": "57037",
    "label": "Irene - SD 57037",
    "county": "Yankton County",
    "state": "SD",
    "city": "Irene"
  },
  {
    "value": "57038",
    "label": "Jefferson - SD 57038",
    "county": "Union County",
    "state": "SD",
    "city": "Jefferson"
  },
  {
    "value": "57039",
    "label": "Lennox - SD 57039",
    "county": "Lincoln County",
    "state": "SD",
    "city": "Lennox"
  },
  {
    "value": "57040",
    "label": "Lesterville - SD 57040",
    "county": "Yankton County",
    "state": "SD",
    "city": "Lesterville"
  },
  {
    "value": "57041",
    "label": "Lyons - SD 57041",
    "county": "Minnehaha County",
    "state": "SD",
    "city": "Lyons"
  },
  {
    "value": "57042",
    "label": "Madison - SD 57042",
    "county": "Lake County",
    "state": "SD",
    "city": "Madison"
  },
  {
    "value": "57043",
    "label": "Marion - SD 57043",
    "county": "Turner County",
    "state": "SD",
    "city": "Marion"
  },
  {
    "value": "57045",
    "label": "Menno - SD 57045",
    "county": "Hutchinson County",
    "state": "SD",
    "city": "Menno"
  },
  {
    "value": "57046",
    "label": "Mission Hill - SD 57046",
    "county": "Yankton County",
    "state": "SD",
    "city": "Mission Hill"
  },
  {
    "value": "57047",
    "label": "Monroe - SD 57047",
    "county": "Turner County",
    "state": "SD",
    "city": "Monroe"
  },
  {
    "value": "57048",
    "label": "Montrose - SD 57048",
    "county": "McCook County",
    "state": "SD",
    "city": "Montrose"
  },
  {
    "value": "57049",
    "label": "North Sioux City - SD 57049",
    "county": "Union County",
    "state": "SD",
    "city": "North Sioux City"
  },
  {
    "value": "57050",
    "label": "Nunda - SD 57050",
    "county": "Lake County",
    "state": "SD",
    "city": "Nunda"
  },
  {
    "value": "57051",
    "label": "Oldham - SD 57051",
    "county": "Kingsbury County",
    "state": "SD",
    "city": "Oldham"
  },
  {
    "value": "57052",
    "label": "Olivet - SD 57052",
    "county": "Hutchinson County",
    "state": "SD",
    "city": "Olivet"
  },
  {
    "value": "57053",
    "label": "Parker - SD 57053",
    "county": "Turner County",
    "state": "SD",
    "city": "Parker"
  },
  {
    "value": "57054",
    "label": "Ramona - SD 57054",
    "county": "Lake County",
    "state": "SD",
    "city": "Ramona"
  },
  {
    "value": "57055",
    "label": "Renner - SD 57055",
    "county": "Minnehaha County",
    "state": "SD",
    "city": "Renner"
  },
  {
    "value": "57056",
    "label": "Rowena - SD 57056",
    "county": "Minnehaha County",
    "state": "SD",
    "city": "Rowena"
  },
  {
    "value": "57057",
    "label": "Rutland - SD 57057",
    "county": "Lake County",
    "state": "SD",
    "city": "Rutland"
  },
  {
    "value": "57058",
    "label": "Salem - SD 57058",
    "county": "McCook County",
    "state": "SD",
    "city": "Salem"
  },
  {
    "value": "57059",
    "label": "Scotland - SD 57059",
    "county": "Bon Homme County",
    "state": "SD",
    "city": "Scotland"
  },
  {
    "value": "57062",
    "label": "Springfield - SD 57062",
    "county": "Bon Homme County",
    "state": "SD",
    "city": "Springfield"
  },
  {
    "value": "57063",
    "label": "Tabor - SD 57063",
    "county": "Bon Homme County",
    "state": "SD",
    "city": "Tabor"
  },
  {
    "value": "57064",
    "label": "Tea - SD 57064",
    "county": "Lincoln County",
    "state": "SD",
    "city": "Tea"
  },
  {
    "value": "57065",
    "label": "Trent - SD 57065",
    "county": "Moody County",
    "state": "SD",
    "city": "Trent"
  },
  {
    "value": "57066",
    "label": "Tyndall - SD 57066",
    "county": "Bon Homme County",
    "state": "SD",
    "city": "Tyndall"
  },
  {
    "value": "57067",
    "label": "Utica - SD 57067",
    "county": "Yankton County",
    "state": "SD",
    "city": "Utica"
  },
  {
    "value": "57068",
    "label": "Valley Springs - SD 57068",
    "county": "Minnehaha County",
    "state": "SD",
    "city": "Valley Springs"
  },
  {
    "value": "57069",
    "label": "Vermillion - SD 57069",
    "county": "Clay County",
    "state": "SD",
    "city": "Vermillion"
  },
  {
    "value": "57070",
    "label": "Viborg - SD 57070",
    "county": "Turner County",
    "state": "SD",
    "city": "Viborg"
  },
  {
    "value": "57071",
    "label": "Volga - SD 57071",
    "county": "Brookings County",
    "state": "SD",
    "city": "Volga"
  },
  {
    "value": "57072",
    "label": "Volin - SD 57072",
    "county": "Yankton County",
    "state": "SD",
    "city": "Volin"
  },
  {
    "value": "57073",
    "label": "Wakonda - SD 57073",
    "county": "Clay County",
    "state": "SD",
    "city": "Wakonda"
  },
  {
    "value": "57075",
    "label": "Wentworth - SD 57075",
    "county": "Lake County",
    "state": "SD",
    "city": "Wentworth"
  },
  {
    "value": "57076",
    "label": "Winfred - SD 57076",
    "county": "Lake County",
    "state": "SD",
    "city": "Winfred"
  },
  {
    "value": "57077",
    "label": "Worthing - SD 57077",
    "county": "Lincoln County",
    "state": "SD",
    "city": "Worthing"
  },
  {
    "value": "57078",
    "label": "Yankton - SD 57078",
    "county": "Yankton County",
    "state": "SD",
    "city": "Yankton"
  },
  {
    "value": "57103",
    "label": "Sioux Falls - SD 57103",
    "county": "Minnehaha County",
    "state": "SD",
    "city": "Sioux Falls"
  },
  {
    "value": "57104",
    "label": "Sioux Falls - SD 57104",
    "county": "Minnehaha County",
    "state": "SD",
    "city": "Sioux Falls"
  },
  {
    "value": "57105",
    "label": "Sioux Falls - SD 57105",
    "county": "Minnehaha County",
    "state": "SD",
    "city": "Sioux Falls"
  },
  {
    "value": "57106",
    "label": "Sioux Falls - SD 57106",
    "county": "Minnehaha County",
    "state": "SD",
    "city": "Sioux Falls"
  },
  {
    "value": "57107",
    "label": "Sioux Falls - SD 57107",
    "county": "Minnehaha County",
    "state": "SD",
    "city": "Sioux Falls"
  },
  {
    "value": "57108",
    "label": "Sioux Falls - SD 57108",
    "county": "Lincoln County",
    "state": "SD",
    "city": "Sioux Falls"
  },
  {
    "value": "57110",
    "label": "Sioux Falls - SD 57110",
    "county": "Minnehaha County",
    "state": "SD",
    "city": "Sioux Falls"
  },
  {
    "value": "57201",
    "label": "Watertown - SD 57201",
    "county": "Codington County",
    "state": "SD",
    "city": "Watertown"
  },
  {
    "value": "57212",
    "label": "Arlington - SD 57212",
    "county": "Kingsbury County",
    "state": "SD",
    "city": "Arlington"
  },
  {
    "value": "57213",
    "label": "Astoria - SD 57213",
    "county": "Deuel County",
    "state": "SD",
    "city": "Astoria"
  },
  {
    "value": "57216",
    "label": "Big Stone City - SD 57216",
    "county": "Grant County",
    "state": "SD",
    "city": "Big Stone City"
  },
  {
    "value": "57217",
    "label": "Bradley - SD 57217",
    "county": "Clark County",
    "state": "SD",
    "city": "Bradley"
  },
  {
    "value": "57218",
    "label": "Brandt - SD 57218",
    "county": "Deuel County",
    "state": "SD",
    "city": "Brandt"
  },
  {
    "value": "57219",
    "label": "Bristol - SD 57219",
    "county": "Day County",
    "state": "SD",
    "city": "Bristol"
  },
  {
    "value": "57220",
    "label": "Bruce - SD 57220",
    "county": "Brookings County",
    "state": "SD",
    "city": "Bruce"
  },
  {
    "value": "57221",
    "label": "Bryant - SD 57221",
    "county": "Hamlin County",
    "state": "SD",
    "city": "Bryant"
  },
  {
    "value": "57223",
    "label": "Castlewood - SD 57223",
    "county": "Hamlin County",
    "state": "SD",
    "city": "Castlewood"
  },
  {
    "value": "57224",
    "label": "Claire City - SD 57224",
    "county": "Roberts County",
    "state": "SD",
    "city": "Claire City"
  },
  {
    "value": "57225",
    "label": "Clark - SD 57225",
    "county": "Clark County",
    "state": "SD",
    "city": "Clark"
  },
  {
    "value": "57226",
    "label": "Clear Lake - SD 57226",
    "county": "Deuel County",
    "state": "SD",
    "city": "Clear Lake"
  },
  {
    "value": "57227",
    "label": "Corona - SD 57227",
    "county": "Roberts County",
    "state": "SD",
    "city": "Corona"
  },
  {
    "value": "57231",
    "label": "De Smet - SD 57231",
    "county": "Kingsbury County",
    "state": "SD",
    "city": "De Smet"
  },
  {
    "value": "57232",
    "label": "Eden - SD 57232",
    "county": "Marshall County",
    "state": "SD",
    "city": "Eden"
  },
  {
    "value": "57233",
    "label": "Erwin - SD 57233",
    "county": "Kingsbury County",
    "state": "SD",
    "city": "Erwin"
  },
  {
    "value": "57234",
    "label": "Estelline - SD 57234",
    "county": "Hamlin County",
    "state": "SD",
    "city": "Estelline"
  },
  {
    "value": "57235",
    "label": "Florence - SD 57235",
    "county": "Codington County",
    "state": "SD",
    "city": "Florence"
  },
  {
    "value": "57236",
    "label": "Garden City - SD 57236",
    "county": "Clark County",
    "state": "SD",
    "city": "Garden City"
  },
  {
    "value": "57237",
    "label": "Gary - SD 57237",
    "county": "Deuel County",
    "state": "SD",
    "city": "Gary"
  },
  {
    "value": "57238",
    "label": "Goodwin - SD 57238",
    "county": "Deuel County",
    "state": "SD",
    "city": "Goodwin"
  },
  {
    "value": "57239",
    "label": "Grenville - SD 57239",
    "county": "Day County",
    "state": "SD",
    "city": "Grenville"
  },
  {
    "value": "57241",
    "label": "Hayti - SD 57241",
    "county": "Hamlin County",
    "state": "SD",
    "city": "Hayti"
  },
  {
    "value": "57242",
    "label": "Hazel - SD 57242",
    "county": "Hamlin County",
    "state": "SD",
    "city": "Hazel"
  },
  {
    "value": "57243",
    "label": "Henry - SD 57243",
    "county": "Codington County",
    "state": "SD",
    "city": "Henry"
  },
  {
    "value": "57246",
    "label": "Labolt - SD 57246",
    "county": "Grant County",
    "state": "SD",
    "city": "Labolt"
  },
  {
    "value": "57247",
    "label": "Lake City - SD 57247",
    "county": "Marshall County",
    "state": "SD",
    "city": "Lake City"
  },
  {
    "value": "57248",
    "label": "Lake Norden - SD 57248",
    "county": "Hamlin County",
    "state": "SD",
    "city": "Lake Norden"
  },
  {
    "value": "57249",
    "label": "Lake Preston - SD 57249",
    "county": "Kingsbury County",
    "state": "SD",
    "city": "Lake Preston"
  },
  {
    "value": "57251",
    "label": "Marvin - SD 57251",
    "county": "Grant County",
    "state": "SD",
    "city": "Marvin"
  },
  {
    "value": "57252",
    "label": "Milbank - SD 57252",
    "county": "Grant County",
    "state": "SD",
    "city": "Milbank"
  },
  {
    "value": "57255",
    "label": "New Effington - SD 57255",
    "county": "Roberts County",
    "state": "SD",
    "city": "New Effington"
  },
  {
    "value": "57256",
    "label": "Ortley - SD 57256",
    "county": "Roberts County",
    "state": "SD",
    "city": "Ortley"
  },
  {
    "value": "57257",
    "label": "Peever - SD 57257",
    "county": "Roberts County",
    "state": "SD",
    "city": "Peever"
  },
  {
    "value": "57258",
    "label": "Raymond - SD 57258",
    "county": "Clark County",
    "state": "SD",
    "city": "Raymond"
  },
  {
    "value": "57259",
    "label": "Revillo - SD 57259",
    "county": "Grant County",
    "state": "SD",
    "city": "Revillo"
  },
  {
    "value": "57260",
    "label": "Rosholt - SD 57260",
    "county": "Roberts County",
    "state": "SD",
    "city": "Rosholt"
  },
  {
    "value": "57261",
    "label": "Roslyn - SD 57261",
    "county": "Day County",
    "state": "SD",
    "city": "Roslyn"
  },
  {
    "value": "57262",
    "label": "Sisseton - SD 57262",
    "county": "Roberts County",
    "state": "SD",
    "city": "Sisseton"
  },
  {
    "value": "57263",
    "label": "South Shore - SD 57263",
    "county": "Codington County",
    "state": "SD",
    "city": "South Shore"
  },
  {
    "value": "57264",
    "label": "Stockholm - SD 57264",
    "county": "Grant County",
    "state": "SD",
    "city": "Stockholm"
  },
  {
    "value": "57265",
    "label": "Strandburg - SD 57265",
    "county": "Grant County",
    "state": "SD",
    "city": "Strandburg"
  },
  {
    "value": "57266",
    "label": "Summit - SD 57266",
    "county": "Grant County",
    "state": "SD",
    "city": "Summit"
  },
  {
    "value": "57268",
    "label": "Toronto - SD 57268",
    "county": "Deuel County",
    "state": "SD",
    "city": "Toronto"
  },
  {
    "value": "57269",
    "label": "Twin Brooks - SD 57269",
    "county": "Grant County",
    "state": "SD",
    "city": "Twin Brooks"
  },
  {
    "value": "57270",
    "label": "Veblen - SD 57270",
    "county": "Marshall County",
    "state": "SD",
    "city": "Veblen"
  },
  {
    "value": "57271",
    "label": "Vienna - SD 57271",
    "county": "Clark County",
    "state": "SD",
    "city": "Vienna"
  },
  {
    "value": "57272",
    "label": "Wallace - SD 57272",
    "county": "Codington County",
    "state": "SD",
    "city": "Wallace"
  },
  {
    "value": "57273",
    "label": "Waubay - SD 57273",
    "county": "Day County",
    "state": "SD",
    "city": "Waubay"
  },
  {
    "value": "57274",
    "label": "Webster - SD 57274",
    "county": "Day County",
    "state": "SD",
    "city": "Webster"
  },
  {
    "value": "57276",
    "label": "White - SD 57276",
    "county": "Brookings County",
    "state": "SD",
    "city": "White"
  },
  {
    "value": "57278",
    "label": "Willow Lake - SD 57278",
    "county": "Clark County",
    "state": "SD",
    "city": "Willow Lake"
  },
  {
    "value": "57279",
    "label": "Wilmot - SD 57279",
    "county": "Roberts County",
    "state": "SD",
    "city": "Wilmot"
  },
  {
    "value": "57301",
    "label": "Mitchell - SD 57301",
    "county": "Davison County",
    "state": "SD",
    "city": "Mitchell"
  },
  {
    "value": "57311",
    "label": "Alexandria - SD 57311",
    "county": "Hanson County",
    "state": "SD",
    "city": "Alexandria"
  },
  {
    "value": "57312",
    "label": "Alpena - SD 57312",
    "county": "Jerauld County",
    "state": "SD",
    "city": "Alpena"
  },
  {
    "value": "57313",
    "label": "Armour - SD 57313",
    "county": "Douglas County",
    "state": "SD",
    "city": "Armour"
  },
  {
    "value": "57314",
    "label": "Artesian - SD 57314",
    "county": "Sanborn County",
    "state": "SD",
    "city": "Artesian"
  },
  {
    "value": "57315",
    "label": "Avon - SD 57315",
    "county": "Bon Homme County",
    "state": "SD",
    "city": "Avon"
  },
  {
    "value": "57317",
    "label": "Bonesteel - SD 57317",
    "county": "Gregory County",
    "state": "SD",
    "city": "Bonesteel"
  },
  {
    "value": "57319",
    "label": "Bridgewater - SD 57319",
    "county": "McCook County",
    "state": "SD",
    "city": "Bridgewater"
  },
  {
    "value": "57321",
    "label": "Canova - SD 57321",
    "county": "Miner County",
    "state": "SD",
    "city": "Canova"
  },
  {
    "value": "57322",
    "label": "Carpenter - SD 57322",
    "county": "Beadle County",
    "state": "SD",
    "city": "Carpenter"
  },
  {
    "value": "57323",
    "label": "Carthage - SD 57323",
    "county": "Miner County",
    "state": "SD",
    "city": "Carthage"
  },
  {
    "value": "57324",
    "label": "Cavour - SD 57324",
    "county": "Beadle County",
    "state": "SD",
    "city": "Cavour"
  },
  {
    "value": "57325",
    "label": "Chamberlain - SD 57325",
    "county": "Brule County",
    "state": "SD",
    "city": "Chamberlain"
  },
  {
    "value": "57328",
    "label": "Corsica - SD 57328",
    "county": "Douglas County",
    "state": "SD",
    "city": "Corsica"
  },
  {
    "value": "57329",
    "label": "Dante - SD 57329",
    "county": "Charles Mix County",
    "state": "SD",
    "city": "Dante"
  },
  {
    "value": "57330",
    "label": "Delmont - SD 57330",
    "county": "Douglas County",
    "state": "SD",
    "city": "Delmont"
  },
  {
    "value": "57331",
    "label": "Dimock - SD 57331",
    "county": "Hutchinson County",
    "state": "SD",
    "city": "Dimock"
  },
  {
    "value": "57332",
    "label": "Emery - SD 57332",
    "county": "Hanson County",
    "state": "SD",
    "city": "Emery"
  },
  {
    "value": "57334",
    "label": "Ethan - SD 57334",
    "county": "Davison County",
    "state": "SD",
    "city": "Ethan"
  },
  {
    "value": "57335",
    "label": "Fairfax - SD 57335",
    "county": "Gregory County",
    "state": "SD",
    "city": "Fairfax"
  },
  {
    "value": "57337",
    "label": "Fedora - SD 57337",
    "county": "Miner County",
    "state": "SD",
    "city": "Fedora"
  },
  {
    "value": "57339",
    "label": "Fort Thompson - SD 57339",
    "county": "Buffalo County",
    "state": "SD",
    "city": "Fort Thompson"
  },
  {
    "value": "57340",
    "label": "Fulton - SD 57340",
    "county": "Hanson County",
    "state": "SD",
    "city": "Fulton"
  },
  {
    "value": "57341",
    "label": "Gann Valley - SD 57341",
    "county": "Buffalo County",
    "state": "SD",
    "city": "Gann Valley"
  },
  {
    "value": "57342",
    "label": "Geddes - SD 57342",
    "county": "Charles Mix County",
    "state": "SD",
    "city": "Geddes"
  },
  {
    "value": "57344",
    "label": "Harrison - SD 57344",
    "county": "Douglas County",
    "state": "SD",
    "city": "Harrison"
  },
  {
    "value": "57345",
    "label": "Highmore - SD 57345",
    "county": "Hyde County",
    "state": "SD",
    "city": "Highmore"
  },
  {
    "value": "57346",
    "label": "Stephan - SD 57346",
    "county": "Hyde County",
    "state": "SD",
    "city": "Stephan"
  },
  {
    "value": "57348",
    "label": "Hitchcock - SD 57348",
    "county": "Beadle County",
    "state": "SD",
    "city": "Hitchcock"
  },
  {
    "value": "57349",
    "label": "Howard - SD 57349",
    "county": "Miner County",
    "state": "SD",
    "city": "Howard"
  },
  {
    "value": "57350",
    "label": "Huron - SD 57350",
    "county": "Beadle County",
    "state": "SD",
    "city": "Huron"
  },
  {
    "value": "57353",
    "label": "Iroquois - SD 57353",
    "county": "Kingsbury County",
    "state": "SD",
    "city": "Iroquois"
  },
  {
    "value": "57355",
    "label": "Kimball - SD 57355",
    "county": "Brule County",
    "state": "SD",
    "city": "Kimball"
  },
  {
    "value": "57356",
    "label": "Lake Andes - SD 57356",
    "county": "Charles Mix County",
    "state": "SD",
    "city": "Lake Andes"
  },
  {
    "value": "57359",
    "label": "Letcher - SD 57359",
    "county": "Sanborn County",
    "state": "SD",
    "city": "Letcher"
  },
  {
    "value": "57362",
    "label": "Miller - SD 57362",
    "county": "Hand County",
    "state": "SD",
    "city": "Miller"
  },
  {
    "value": "57363",
    "label": "Mount Vernon - SD 57363",
    "county": "Davison County",
    "state": "SD",
    "city": "Mount Vernon"
  },
  {
    "value": "57364",
    "label": "New Holland - SD 57364",
    "county": "Douglas County",
    "state": "SD",
    "city": "New Holland"
  },
  {
    "value": "57365",
    "label": "Oacoma - SD 57365",
    "county": "Lyman County",
    "state": "SD",
    "city": "Oacoma"
  },
  {
    "value": "57366",
    "label": "Parkston - SD 57366",
    "county": "Hutchinson County",
    "state": "SD",
    "city": "Parkston"
  },
  {
    "value": "57368",
    "label": "Plankinton - SD 57368",
    "county": "Aurora County",
    "state": "SD",
    "city": "Plankinton"
  },
  {
    "value": "57369",
    "label": "Platte - SD 57369",
    "county": "Charles Mix County",
    "state": "SD",
    "city": "Platte"
  },
  {
    "value": "57370",
    "label": "Pukwana - SD 57370",
    "county": "Brule County",
    "state": "SD",
    "city": "Pukwana"
  },
  {
    "value": "57371",
    "label": "Ree Heights - SD 57371",
    "county": "Hand County",
    "state": "SD",
    "city": "Ree Heights"
  },
  {
    "value": "57373",
    "label": "Saint Lawrence - SD 57373",
    "county": "Hand County",
    "state": "SD",
    "city": "Saint Lawrence"
  },
  {
    "value": "57374",
    "label": "Spencer - SD 57374",
    "county": "McCook County",
    "state": "SD",
    "city": "Spencer"
  },
  {
    "value": "57375",
    "label": "Stickney - SD 57375",
    "county": "Aurora County",
    "state": "SD",
    "city": "Stickney"
  },
  {
    "value": "57376",
    "label": "Tripp - SD 57376",
    "county": "Hutchinson County",
    "state": "SD",
    "city": "Tripp"
  },
  {
    "value": "57379",
    "label": "Virgil - SD 57379",
    "county": "Beadle County",
    "state": "SD",
    "city": "Virgil"
  },
  {
    "value": "57380",
    "label": "Wagner - SD 57380",
    "county": "Charles Mix County",
    "state": "SD",
    "city": "Wagner"
  },
  {
    "value": "57381",
    "label": "Wessington - SD 57381",
    "county": "Beadle County",
    "state": "SD",
    "city": "Wessington"
  },
  {
    "value": "57382",
    "label": "Wessington Springs - SD 57382",
    "county": "Jerauld County",
    "state": "SD",
    "city": "Wessington Springs"
  },
  {
    "value": "57383",
    "label": "White Lake - SD 57383",
    "county": "Aurora County",
    "state": "SD",
    "city": "White Lake"
  },
  {
    "value": "57384",
    "label": "Wolsey - SD 57384",
    "county": "Beadle County",
    "state": "SD",
    "city": "Wolsey"
  },
  {
    "value": "57385",
    "label": "Woonsocket - SD 57385",
    "county": "Sanborn County",
    "state": "SD",
    "city": "Woonsocket"
  },
  {
    "value": "57386",
    "label": "Yale - SD 57386",
    "county": "Beadle County",
    "state": "SD",
    "city": "Yale"
  },
  {
    "value": "57401",
    "label": "Aberdeen - SD 57401",
    "county": "Brown County",
    "state": "SD",
    "city": "Aberdeen"
  },
  {
    "value": "57421",
    "label": "Amherst - SD 57421",
    "county": "Marshall County",
    "state": "SD",
    "city": "Amherst"
  },
  {
    "value": "57422",
    "label": "Andover - SD 57422",
    "county": "Day County",
    "state": "SD",
    "city": "Andover"
  },
  {
    "value": "57424",
    "label": "Ashton - SD 57424",
    "county": "Spink County",
    "state": "SD",
    "city": "Ashton"
  },
  {
    "value": "57427",
    "label": "Bath - SD 57427",
    "county": "Brown County",
    "state": "SD",
    "city": "Bath"
  },
  {
    "value": "57428",
    "label": "Bowdle - SD 57428",
    "county": "Edmunds County",
    "state": "SD",
    "city": "Bowdle"
  },
  {
    "value": "57430",
    "label": "Britton - SD 57430",
    "county": "Marshall County",
    "state": "SD",
    "city": "Britton"
  },
  {
    "value": "57432",
    "label": "Claremont - SD 57432",
    "county": "Brown County",
    "state": "SD",
    "city": "Claremont"
  },
  {
    "value": "57433",
    "label": "Columbia - SD 57433",
    "county": "Brown County",
    "state": "SD",
    "city": "Columbia"
  },
  {
    "value": "57434",
    "label": "Conde - SD 57434",
    "county": "Spink County",
    "state": "SD",
    "city": "Conde"
  },
  {
    "value": "57435",
    "label": "Cresbard - SD 57435",
    "county": "Faulk County",
    "state": "SD",
    "city": "Cresbard"
  },
  {
    "value": "57436",
    "label": "Doland - SD 57436",
    "county": "Spink County",
    "state": "SD",
    "city": "Doland"
  },
  {
    "value": "57437",
    "label": "Eureka - SD 57437",
    "county": "McPherson County",
    "state": "SD",
    "city": "Eureka"
  },
  {
    "value": "57438",
    "label": "Faulkton - SD 57438",
    "county": "Faulk County",
    "state": "SD",
    "city": "Faulkton"
  },
  {
    "value": "57440",
    "label": "Frankfort - SD 57440",
    "county": "Spink County",
    "state": "SD",
    "city": "Frankfort"
  },
  {
    "value": "57441",
    "label": "Frederick - SD 57441",
    "county": "Brown County",
    "state": "SD",
    "city": "Frederick"
  },
  {
    "value": "57442",
    "label": "Gettysburg - SD 57442",
    "county": "Potter County",
    "state": "SD",
    "city": "Gettysburg"
  },
  {
    "value": "57445",
    "label": "Groton - SD 57445",
    "county": "Brown County",
    "state": "SD",
    "city": "Groton"
  },
  {
    "value": "57446",
    "label": "Hecla - SD 57446",
    "county": "Brown County",
    "state": "SD",
    "city": "Hecla"
  },
  {
    "value": "57448",
    "label": "Hosmer - SD 57448",
    "county": "Edmunds County",
    "state": "SD",
    "city": "Hosmer"
  },
  {
    "value": "57449",
    "label": "Houghton - SD 57449",
    "county": "Brown County",
    "state": "SD",
    "city": "Houghton"
  },
  {
    "value": "57450",
    "label": "Hoven - SD 57450",
    "county": "Potter County",
    "state": "SD",
    "city": "Hoven"
  },
  {
    "value": "57451",
    "label": "Ipswich - SD 57451",
    "county": "Edmunds County",
    "state": "SD",
    "city": "Ipswich"
  },
  {
    "value": "57452",
    "label": "Java - SD 57452",
    "county": "Walworth County",
    "state": "SD",
    "city": "Java"
  },
  {
    "value": "57454",
    "label": "Langford - SD 57454",
    "county": "Marshall County",
    "state": "SD",
    "city": "Langford"
  },
  {
    "value": "57455",
    "label": "Lebanon - SD 57455",
    "county": "Potter County",
    "state": "SD",
    "city": "Lebanon"
  },
  {
    "value": "57456",
    "label": "Leola - SD 57456",
    "county": "McPherson County",
    "state": "SD",
    "city": "Leola"
  },
  {
    "value": "57457",
    "label": "Long Lake - SD 57457",
    "county": "McPherson County",
    "state": "SD",
    "city": "Long Lake"
  },
  {
    "value": "57460",
    "label": "Mansfield - SD 57460",
    "county": "Brown County",
    "state": "SD",
    "city": "Mansfield"
  },
  {
    "value": "57461",
    "label": "Mellette - SD 57461",
    "county": "Spink County",
    "state": "SD",
    "city": "Mellette"
  },
  {
    "value": "57465",
    "label": "Northville - SD 57465",
    "county": "Spink County",
    "state": "SD",
    "city": "Northville"
  },
  {
    "value": "57466",
    "label": "Onaka - SD 57466",
    "county": "Faulk County",
    "state": "SD",
    "city": "Onaka"
  },
  {
    "value": "57467",
    "label": "Orient - SD 57467",
    "county": "Hand County",
    "state": "SD",
    "city": "Orient"
  },
  {
    "value": "57468",
    "label": "Pierpont - SD 57468",
    "county": "Day County",
    "state": "SD",
    "city": "Pierpont"
  },
  {
    "value": "57469",
    "label": "Redfield - SD 57469",
    "county": "Spink County",
    "state": "SD",
    "city": "Redfield"
  },
  {
    "value": "57470",
    "label": "Rockham - SD 57470",
    "county": "Faulk County",
    "state": "SD",
    "city": "Rockham"
  },
  {
    "value": "57471",
    "label": "Roscoe - SD 57471",
    "county": "Edmunds County",
    "state": "SD",
    "city": "Roscoe"
  },
  {
    "value": "57472",
    "label": "Selby - SD 57472",
    "county": "Walworth County",
    "state": "SD",
    "city": "Selby"
  },
  {
    "value": "57473",
    "label": "Seneca - SD 57473",
    "county": "Faulk County",
    "state": "SD",
    "city": "Seneca"
  },
  {
    "value": "57474",
    "label": "Stratford - SD 57474",
    "county": "Brown County",
    "state": "SD",
    "city": "Stratford"
  },
  {
    "value": "57475",
    "label": "Tolstoy - SD 57475",
    "county": "Potter County",
    "state": "SD",
    "city": "Tolstoy"
  },
  {
    "value": "57476",
    "label": "Tulare - SD 57476",
    "county": "Spink County",
    "state": "SD",
    "city": "Tulare"
  },
  {
    "value": "57477",
    "label": "Turton - SD 57477",
    "county": "Spink County",
    "state": "SD",
    "city": "Turton"
  },
  {
    "value": "57479",
    "label": "Warner - SD 57479",
    "county": "Brown County",
    "state": "SD",
    "city": "Warner"
  },
  {
    "value": "57481",
    "label": "Westport - SD 57481",
    "county": "Brown County",
    "state": "SD",
    "city": "Westport"
  },
  {
    "value": "57501",
    "label": "Pierre - SD 57501",
    "county": "Hughes County",
    "state": "SD",
    "city": "Pierre"
  },
  {
    "value": "57520",
    "label": "Agar - SD 57520",
    "county": "Sully County",
    "state": "SD",
    "city": "Agar"
  },
  {
    "value": "57521",
    "label": "Belvidere - SD 57521",
    "county": "Jackson County",
    "state": "SD",
    "city": "Belvidere"
  },
  {
    "value": "57522",
    "label": "Blunt - SD 57522",
    "county": "Hughes County",
    "state": "SD",
    "city": "Blunt"
  },
  {
    "value": "57523",
    "label": "Burke - SD 57523",
    "county": "Gregory County",
    "state": "SD",
    "city": "Burke"
  },
  {
    "value": "57528",
    "label": "Colome - SD 57528",
    "county": "Tripp County",
    "state": "SD",
    "city": "Colome"
  },
  {
    "value": "57529",
    "label": "Dallas - SD 57529",
    "county": "Gregory County",
    "state": "SD",
    "city": "Dallas"
  },
  {
    "value": "57531",
    "label": "Draper - SD 57531",
    "county": "Jones County",
    "state": "SD",
    "city": "Draper"
  },
  {
    "value": "57532",
    "label": "Fort Pierre - SD 57532",
    "county": "Stanley County",
    "state": "SD",
    "city": "Fort Pierre"
  },
  {
    "value": "57533",
    "label": "Gregory - SD 57533",
    "county": "Gregory County",
    "state": "SD",
    "city": "Gregory"
  },
  {
    "value": "57534",
    "label": "Hamill - SD 57534",
    "county": "Tripp County",
    "state": "SD",
    "city": "Hamill"
  },
  {
    "value": "57536",
    "label": "Harrold - SD 57536",
    "county": "Hughes County",
    "state": "SD",
    "city": "Harrold"
  },
  {
    "value": "57537",
    "label": "Hayes - SD 57537",
    "county": "Stanley County",
    "state": "SD",
    "city": "Hayes"
  },
  {
    "value": "57538",
    "label": "Herrick - SD 57538",
    "county": "Gregory County",
    "state": "SD",
    "city": "Herrick"
  },
  {
    "value": "57540",
    "label": "Holabird - SD 57540",
    "county": "Hyde County",
    "state": "SD",
    "city": "Holabird"
  },
  {
    "value": "57541",
    "label": "Ideal - SD 57541",
    "county": "Tripp County",
    "state": "SD",
    "city": "Ideal"
  },
  {
    "value": "57543",
    "label": "Kadoka - SD 57543",
    "county": "Jackson County",
    "state": "SD",
    "city": "Kadoka"
  },
  {
    "value": "57544",
    "label": "Kennebec - SD 57544",
    "county": "Lyman County",
    "state": "SD",
    "city": "Kennebec"
  },
  {
    "value": "57547",
    "label": "Long Valley - SD 57547",
    "county": "Jackson County",
    "state": "SD",
    "city": "Long Valley"
  },
  {
    "value": "57548",
    "label": "Lower Brule - SD 57548",
    "county": "Lyman County",
    "state": "SD",
    "city": "Lower Brule"
  },
  {
    "value": "57551",
    "label": "Martin - SD 57551",
    "county": "Bennett County",
    "state": "SD",
    "city": "Martin"
  },
  {
    "value": "57552",
    "label": "Midland - SD 57552",
    "county": "Haakon County",
    "state": "SD",
    "city": "Midland"
  },
  {
    "value": "57553",
    "label": "Milesville - SD 57553",
    "county": "Haakon County",
    "state": "SD",
    "city": "Milesville"
  },
  {
    "value": "57555",
    "label": "Mission - SD 57555",
    "county": "Todd County",
    "state": "SD",
    "city": "Mission"
  },
  {
    "value": "57559",
    "label": "Murdo - SD 57559",
    "county": "Jones County",
    "state": "SD",
    "city": "Murdo"
  },
  {
    "value": "57560",
    "label": "Norris - SD 57560",
    "county": "Mellette County",
    "state": "SD",
    "city": "Norris"
  },
  {
    "value": "57562",
    "label": "Okaton - SD 57562",
    "county": "Jones County",
    "state": "SD",
    "city": "Okaton"
  },
  {
    "value": "57564",
    "label": "Onida - SD 57564",
    "county": "Sully County",
    "state": "SD",
    "city": "Onida"
  },
  {
    "value": "57566",
    "label": "Parmelee - SD 57566",
    "county": "Mellette County",
    "state": "SD",
    "city": "Parmelee"
  },
  {
    "value": "57567",
    "label": "Philip - SD 57567",
    "county": "Haakon County",
    "state": "SD",
    "city": "Philip"
  },
  {
    "value": "57568",
    "label": "Presho - SD 57568",
    "county": "Lyman County",
    "state": "SD",
    "city": "Presho"
  },
  {
    "value": "57569",
    "label": "Reliance - SD 57569",
    "county": "Lyman County",
    "state": "SD",
    "city": "Reliance"
  },
  {
    "value": "57571",
    "label": "Saint Charles - SD 57571",
    "county": "Gregory County",
    "state": "SD",
    "city": "Saint Charles"
  },
  {
    "value": "57572",
    "label": "Saint Francis - SD 57572",
    "county": "Todd County",
    "state": "SD",
    "city": "Saint Francis"
  },
  {
    "value": "57574",
    "label": "Tuthill - SD 57574",
    "county": "Bennett County",
    "state": "SD",
    "city": "Tuthill"
  },
  {
    "value": "57576",
    "label": "Vivian - SD 57576",
    "county": "Lyman County",
    "state": "SD",
    "city": "Vivian"
  },
  {
    "value": "57577",
    "label": "Wanblee - SD 57577",
    "county": "Jackson County",
    "state": "SD",
    "city": "Wanblee"
  },
  {
    "value": "57579",
    "label": "White River - SD 57579",
    "county": "Mellette County",
    "state": "SD",
    "city": "White River"
  },
  {
    "value": "57580",
    "label": "Winner - SD 57580",
    "county": "Tripp County",
    "state": "SD",
    "city": "Winner"
  },
  {
    "value": "57584",
    "label": "Witten - SD 57584",
    "county": "Tripp County",
    "state": "SD",
    "city": "Witten"
  },
  {
    "value": "57585",
    "label": "Wood - SD 57585",
    "county": "Mellette County",
    "state": "SD",
    "city": "Wood"
  },
  {
    "value": "57601",
    "label": "Mobridge - SD 57601",
    "county": "Walworth County",
    "state": "SD",
    "city": "Mobridge"
  },
  {
    "value": "57620",
    "label": "Bison - SD 57620",
    "county": "Perkins County",
    "state": "SD",
    "city": "Bison"
  },
  {
    "value": "57622",
    "label": "Cherry Creek - SD 57622",
    "county": "Ziebach County",
    "state": "SD",
    "city": "Cherry Creek"
  },
  {
    "value": "57623",
    "label": "Dupree - SD 57623",
    "county": "Ziebach County",
    "state": "SD",
    "city": "Dupree"
  },
  {
    "value": "57625",
    "label": "Eagle Butte - SD 57625",
    "county": "Dewey County",
    "state": "SD",
    "city": "Eagle Butte"
  },
  {
    "value": "57626",
    "label": "Faith - SD 57626",
    "county": "Meade County",
    "state": "SD",
    "city": "Faith"
  },
  {
    "value": "57630",
    "label": "Glencross - SD 57630",
    "county": "Dewey County",
    "state": "SD",
    "city": "Glencross"
  },
  {
    "value": "57631",
    "label": "Glenham - SD 57631",
    "county": "Walworth County",
    "state": "SD",
    "city": "Glenham"
  },
  {
    "value": "57632",
    "label": "Herreid - SD 57632",
    "county": "Campbell County",
    "state": "SD",
    "city": "Herreid"
  },
  {
    "value": "57633",
    "label": "Isabel - SD 57633",
    "county": "Dewey County",
    "state": "SD",
    "city": "Isabel"
  },
  {
    "value": "57634",
    "label": "Keldron - SD 57634",
    "county": "Corson County",
    "state": "SD",
    "city": "Keldron"
  },
  {
    "value": "57638",
    "label": "Lemmon - SD 57638",
    "county": "Perkins County",
    "state": "SD",
    "city": "Lemmon"
  },
  {
    "value": "57640",
    "label": "Lodgepole - SD 57640",
    "county": "Perkins County",
    "state": "SD",
    "city": "Lodgepole"
  },
  {
    "value": "57641",
    "label": "Mc Intosh - SD 57641",
    "county": "Corson County",
    "state": "SD",
    "city": "Mc Intosh"
  },
  {
    "value": "57642",
    "label": "Mc Laughlin - SD 57642",
    "county": "Corson County",
    "state": "SD",
    "city": "Mc Laughlin"
  },
  {
    "value": "57644",
    "label": "Meadow - SD 57644",
    "county": "Perkins County",
    "state": "SD",
    "city": "Meadow"
  },
  {
    "value": "57645",
    "label": "Morristown - SD 57645",
    "county": "Grant County",
    "state": "SD",
    "city": "Morristown"
  },
  {
    "value": "57646",
    "label": "Mound City - SD 57646",
    "county": "Campbell County",
    "state": "SD",
    "city": "Mound City"
  },
  {
    "value": "57648",
    "label": "Pollock - SD 57648",
    "county": "Campbell County",
    "state": "SD",
    "city": "Pollock"
  },
  {
    "value": "57649",
    "label": "Prairie City - SD 57649",
    "county": "Perkins County",
    "state": "SD",
    "city": "Prairie City"
  },
  {
    "value": "57650",
    "label": "Ralph - SD 57650",
    "county": "Harding County",
    "state": "SD",
    "city": "Ralph"
  },
  {
    "value": "57651",
    "label": "Reva - SD 57651",
    "county": "Harding County",
    "state": "SD",
    "city": "Reva"
  },
  {
    "value": "57652",
    "label": "Ridgeview - SD 57652",
    "county": "Dewey County",
    "state": "SD",
    "city": "Ridgeview"
  },
  {
    "value": "57656",
    "label": "Timber Lake - SD 57656",
    "county": "Dewey County",
    "state": "SD",
    "city": "Timber Lake"
  },
  {
    "value": "57657",
    "label": "Trail City - SD 57657",
    "county": "Dewey County",
    "state": "SD",
    "city": "Trail City"
  },
  {
    "value": "57658",
    "label": "Wakpala - SD 57658",
    "county": "Corson County",
    "state": "SD",
    "city": "Wakpala"
  },
  {
    "value": "57659",
    "label": "Walker - SD 57659",
    "county": "Corson County",
    "state": "SD",
    "city": "Walker"
  },
  {
    "value": "57660",
    "label": "Watauga - SD 57660",
    "county": "Grant County",
    "state": "SD",
    "city": "Watauga"
  },
  {
    "value": "57701",
    "label": "Rapid City - SD 57701",
    "county": "Pennington County",
    "state": "SD",
    "city": "Rapid City"
  },
  {
    "value": "57702",
    "label": "Rapid City - SD 57702",
    "county": "Pennington County",
    "state": "SD",
    "city": "Rapid City"
  },
  {
    "value": "57703",
    "label": "Rapid City - SD 57703",
    "county": "Pennington County",
    "state": "SD",
    "city": "Rapid City"
  },
  {
    "value": "57706",
    "label": "Ellsworth AFB - SD 57706",
    "county": "Meade County",
    "state": "SD",
    "city": "Ellsworth AFB"
  },
  {
    "value": "57714",
    "label": "Allen - SD 57714",
    "county": "Bennett County",
    "state": "SD",
    "city": "Allen"
  },
  {
    "value": "57716",
    "label": "Batesland - SD 57716",
    "county": "Shannon County",
    "state": "SD",
    "city": "Batesland"
  },
  {
    "value": "57717",
    "label": "Belle Fourche - SD 57717",
    "county": "Butte County",
    "state": "SD",
    "city": "Belle Fourche"
  },
  {
    "value": "57718",
    "label": "Black Hawk - SD 57718",
    "county": "Meade County",
    "state": "SD",
    "city": "Black Hawk"
  },
  {
    "value": "57719",
    "label": "Box Elder - SD 57719",
    "county": "Pennington County",
    "state": "SD",
    "city": "Box Elder"
  },
  {
    "value": "57720",
    "label": "Buffalo - SD 57720",
    "county": "Harding County",
    "state": "SD",
    "city": "Buffalo"
  },
  {
    "value": "57722",
    "label": "Buffalo Gap - SD 57722",
    "county": "Custer County",
    "state": "SD",
    "city": "Buffalo Gap"
  },
  {
    "value": "57724",
    "label": "Camp Crook - SD 57724",
    "county": "Harding County",
    "state": "SD",
    "city": "Camp Crook"
  },
  {
    "value": "57725",
    "label": "Caputa - SD 57725",
    "county": "Pennington County",
    "state": "SD",
    "city": "Caputa"
  },
  {
    "value": "57730",
    "label": "Custer - SD 57730",
    "county": "Custer County",
    "state": "SD",
    "city": "Custer"
  },
  {
    "value": "57732",
    "label": "Deadwood - SD 57732",
    "county": "Lawrence County",
    "state": "SD",
    "city": "Deadwood"
  },
  {
    "value": "57735",
    "label": "Edgemont - SD 57735",
    "county": "Fall River County",
    "state": "SD",
    "city": "Edgemont"
  },
  {
    "value": "57737",
    "label": "Enning - SD 57737",
    "county": "Meade County",
    "state": "SD",
    "city": "Enning"
  },
  {
    "value": "57738",
    "label": "Fairburn - SD 57738",
    "county": "Custer County",
    "state": "SD",
    "city": "Fairburn"
  },
  {
    "value": "57744",
    "label": "Hermosa - SD 57744",
    "county": "Custer County",
    "state": "SD",
    "city": "Hermosa"
  },
  {
    "value": "57745",
    "label": "Hill City - SD 57745",
    "county": "Pennington County",
    "state": "SD",
    "city": "Hill City"
  },
  {
    "value": "57747",
    "label": "Hot Springs - SD 57747",
    "county": "Fall River County",
    "state": "SD",
    "city": "Hot Springs"
  },
  {
    "value": "57748",
    "label": "Howes - SD 57748",
    "county": "Meade County",
    "state": "SD",
    "city": "Howes"
  },
  {
    "value": "57750",
    "label": "Interior - SD 57750",
    "county": "Jackson County",
    "state": "SD",
    "city": "Interior"
  },
  {
    "value": "57751",
    "label": "Keystone - SD 57751",
    "county": "Pennington County",
    "state": "SD",
    "city": "Keystone"
  },
  {
    "value": "57752",
    "label": "Kyle - SD 57752",
    "county": "Shannon County",
    "state": "SD",
    "city": "Kyle"
  },
  {
    "value": "57754",
    "label": "Lead - SD 57754",
    "county": "Lawrence County",
    "state": "SD",
    "city": "Lead"
  },
  {
    "value": "57755",
    "label": "Ludlow - SD 57755",
    "county": "Harding County",
    "state": "SD",
    "city": "Ludlow"
  },
  {
    "value": "57758",
    "label": "Mud Butte - SD 57758",
    "county": "Meade County",
    "state": "SD",
    "city": "Mud Butte"
  },
  {
    "value": "57759",
    "label": "Nemo - SD 57759",
    "county": "Lawrence County",
    "state": "SD",
    "city": "Nemo"
  },
  {
    "value": "57760",
    "label": "Newell - SD 57760",
    "county": "Butte County",
    "state": "SD",
    "city": "Newell"
  },
  {
    "value": "57761",
    "label": "New Underwood - SD 57761",
    "county": "Pennington County",
    "state": "SD",
    "city": "New Underwood"
  },
  {
    "value": "57762",
    "label": "Nisland - SD 57762",
    "county": "Butte County",
    "state": "SD",
    "city": "Nisland"
  },
  {
    "value": "57763",
    "label": "Oelrichs - SD 57763",
    "county": "Fall River County",
    "state": "SD",
    "city": "Oelrichs"
  },
  {
    "value": "57764",
    "label": "Oglala - SD 57764",
    "county": "Shannon County",
    "state": "SD",
    "city": "Oglala"
  },
  {
    "value": "57766",
    "label": "Oral - SD 57766",
    "county": "Fall River County",
    "state": "SD",
    "city": "Oral"
  },
  {
    "value": "57767",
    "label": "Owanka - SD 57767",
    "county": "Pennington County",
    "state": "SD",
    "city": "Owanka"
  },
  {
    "value": "57769",
    "label": "Piedmont - SD 57769",
    "county": "Meade County",
    "state": "SD",
    "city": "Piedmont"
  },
  {
    "value": "57770",
    "label": "Pine Ridge - SD 57770",
    "county": "Shannon County",
    "state": "SD",
    "city": "Pine Ridge"
  },
  {
    "value": "57772",
    "label": "Porcupine - SD 57772",
    "county": "Shannon County",
    "state": "SD",
    "city": "Porcupine"
  },
  {
    "value": "57775",
    "label": "Quinn - SD 57775",
    "county": "Pennington County",
    "state": "SD",
    "city": "Quinn"
  },
  {
    "value": "57779",
    "label": "Saint Onge - SD 57779",
    "county": "Lawrence County",
    "state": "SD",
    "city": "Saint Onge"
  },
  {
    "value": "57780",
    "label": "Scenic - SD 57780",
    "county": "Pennington County",
    "state": "SD",
    "city": "Scenic"
  },
  {
    "value": "57783",
    "label": "Spearfish - SD 57783",
    "county": "Lawrence County",
    "state": "SD",
    "city": "Spearfish"
  },
  {
    "value": "57785",
    "label": "Sturgis - SD 57785",
    "county": "Meade County",
    "state": "SD",
    "city": "Sturgis"
  },
  {
    "value": "57787",
    "label": "Union Center - SD 57787",
    "county": "Meade County",
    "state": "SD",
    "city": "Union Center"
  },
  {
    "value": "57788",
    "label": "Vale - SD 57788",
    "county": "Butte County",
    "state": "SD",
    "city": "Vale"
  },
  {
    "value": "57790",
    "label": "Wall - SD 57790",
    "county": "Pennington County",
    "state": "SD",
    "city": "Wall"
  },
  {
    "value": "57791",
    "label": "Wasta - SD 57791",
    "county": "Pennington County",
    "state": "SD",
    "city": "Wasta"
  },
  {
    "value": "57792",
    "label": "White Owl - SD 57792",
    "county": "Meade County",
    "state": "SD",
    "city": "White Owl"
  },
  {
    "value": "57793",
    "label": "Whitewood - SD 57793",
    "county": "Lawrence County",
    "state": "SD",
    "city": "Whitewood"
  },
  {
    "value": "57794",
    "label": "Wounded Knee - SD 57794",
    "county": "Shannon County",
    "state": "SD",
    "city": "Wounded Knee"
  },
  {
    "value": "37010",
    "label": "Adams - TN 37010",
    "county": "Montgomery County",
    "state": "TN",
    "city": "Adams"
  },
  {
    "value": "37012",
    "label": "Alexandria - TN 37012",
    "county": "DeKalb County",
    "state": "TN",
    "city": "Alexandria"
  },
  {
    "value": "37013",
    "label": "Antioch - TN 37013",
    "county": "Davidson County",
    "state": "TN",
    "city": "Antioch"
  },
  {
    "value": "37014",
    "label": "Arrington - TN 37014",
    "county": "Williamson County",
    "state": "TN",
    "city": "Arrington"
  },
  {
    "value": "37015",
    "label": "Ashland City - TN 37015",
    "county": "Cheatham County",
    "state": "TN",
    "city": "Ashland City"
  },
  {
    "value": "37016",
    "label": "Auburntown - TN 37016",
    "county": "Cannon County",
    "state": "TN",
    "city": "Auburntown"
  },
  {
    "value": "37018",
    "label": "Beechgrove - TN 37018",
    "county": "Coffee County",
    "state": "TN",
    "city": "Beechgrove"
  },
  {
    "value": "37019",
    "label": "Belfast - TN 37019",
    "county": "Marshall County",
    "state": "TN",
    "city": "Belfast"
  },
  {
    "value": "37020",
    "label": "Bell Buckle - TN 37020",
    "county": "Bedford County",
    "state": "TN",
    "city": "Bell Buckle"
  },
  {
    "value": "37022",
    "label": "Bethpage - TN 37022",
    "county": "Sumner County",
    "state": "TN",
    "city": "Bethpage"
  },
  {
    "value": "37023",
    "label": "Big Rock - TN 37023",
    "county": "Stewart County",
    "state": "TN",
    "city": "Big Rock"
  },
  {
    "value": "37025",
    "label": "Bon Aqua - TN 37025",
    "county": "Hickman County",
    "state": "TN",
    "city": "Bon Aqua"
  },
  {
    "value": "37026",
    "label": "Bradyville - TN 37026",
    "county": "Cannon County",
    "state": "TN",
    "city": "Bradyville"
  },
  {
    "value": "37027",
    "label": "Brentwood - TN 37027",
    "county": "Williamson County",
    "state": "TN",
    "city": "Brentwood"
  },
  {
    "value": "37028",
    "label": "Bumpus Mills - TN 37028",
    "county": "Stewart County",
    "state": "TN",
    "city": "Bumpus Mills"
  },
  {
    "value": "37029",
    "label": "Burns - TN 37029",
    "county": "Dickson County",
    "state": "TN",
    "city": "Burns"
  },
  {
    "value": "37030",
    "label": "Carthage - TN 37030",
    "county": "Smith County",
    "state": "TN",
    "city": "Carthage"
  },
  {
    "value": "37031",
    "label": "Castalian Springs - TN 37031",
    "county": "Sumner County",
    "state": "TN",
    "city": "Castalian Springs"
  },
  {
    "value": "37032",
    "label": "Cedar Hill - TN 37032",
    "county": "Robertson County",
    "state": "TN",
    "city": "Cedar Hill"
  },
  {
    "value": "37033",
    "label": "Centerville - TN 37033",
    "county": "Hickman County",
    "state": "TN",
    "city": "Centerville"
  },
  {
    "value": "37034",
    "label": "Chapel Hill - TN 37034",
    "county": "Marshall County",
    "state": "TN",
    "city": "Chapel Hill"
  },
  {
    "value": "37035",
    "label": "Chapmansboro - TN 37035",
    "county": "Cheatham County",
    "state": "TN",
    "city": "Chapmansboro"
  },
  {
    "value": "37036",
    "label": "Charlotte - TN 37036",
    "county": "Dickson County",
    "state": "TN",
    "city": "Charlotte"
  },
  {
    "value": "37037",
    "label": "Christiana - TN 37037",
    "county": "Rutherford County",
    "state": "TN",
    "city": "Christiana"
  },
  {
    "value": "37040",
    "label": "Clarksville - TN 37040",
    "county": "Montgomery County",
    "state": "TN",
    "city": "Clarksville"
  },
  {
    "value": "37042",
    "label": "Clarksville - TN 37042",
    "county": "Montgomery County",
    "state": "TN",
    "city": "Clarksville"
  },
  {
    "value": "37043",
    "label": "Clarksville - TN 37043",
    "county": "Montgomery County",
    "state": "TN",
    "city": "Clarksville"
  },
  {
    "value": "37046",
    "label": "College Grove - TN 37046",
    "county": "Williamson County",
    "state": "TN",
    "city": "College Grove"
  },
  {
    "value": "37047",
    "label": "Cornersville - TN 37047",
    "county": "Marshall County",
    "state": "TN",
    "city": "Cornersville"
  },
  {
    "value": "37048",
    "label": "Cottontown - TN 37048",
    "county": "Sumner County",
    "state": "TN",
    "city": "Cottontown"
  },
  {
    "value": "37049",
    "label": "Cross Plains - TN 37049",
    "county": "Robertson County",
    "state": "TN",
    "city": "Cross Plains"
  },
  {
    "value": "37050",
    "label": "Cumberland City - TN 37050",
    "county": "Stewart County",
    "state": "TN",
    "city": "Cumberland City"
  },
  {
    "value": "37051",
    "label": "Cumberland Furnace - TN 37051",
    "county": "Dickson County",
    "state": "TN",
    "city": "Cumberland Furnace"
  },
  {
    "value": "37052",
    "label": "Cunningham - TN 37052",
    "county": "Montgomery County",
    "state": "TN",
    "city": "Cunningham"
  },
  {
    "value": "37055",
    "label": "Dickson - TN 37055",
    "county": "Dickson County",
    "state": "TN",
    "city": "Dickson"
  },
  {
    "value": "37057",
    "label": "Dixon Springs - TN 37057",
    "county": "Smith County",
    "state": "TN",
    "city": "Dixon Springs"
  },
  {
    "value": "37058",
    "label": "Dover - TN 37058",
    "county": "Stewart County",
    "state": "TN",
    "city": "Dover"
  },
  {
    "value": "37059",
    "label": "Dowelltown - TN 37059",
    "county": "DeKalb County",
    "state": "TN",
    "city": "Dowelltown"
  },
  {
    "value": "37060",
    "label": "Eagleville - TN 37060",
    "county": "Rutherford County",
    "state": "TN",
    "city": "Eagleville"
  },
  {
    "value": "37061",
    "label": "Erin - TN 37061",
    "county": "Houston County",
    "state": "TN",
    "city": "Erin"
  },
  {
    "value": "37062",
    "label": "Fairview - TN 37062",
    "county": "Williamson County",
    "state": "TN",
    "city": "Fairview"
  },
  {
    "value": "37064",
    "label": "Franklin - TN 37064",
    "county": "Williamson County",
    "state": "TN",
    "city": "Franklin"
  },
  {
    "value": "37066",
    "label": "Gallatin - TN 37066",
    "county": "Sumner County",
    "state": "TN",
    "city": "Gallatin"
  },
  {
    "value": "37067",
    "label": "Franklin - TN 37067",
    "county": "Williamson County",
    "state": "TN",
    "city": "Franklin"
  },
  {
    "value": "37069",
    "label": "Franklin - TN 37069",
    "county": "Williamson County",
    "state": "TN",
    "city": "Franklin"
  },
  {
    "value": "37072",
    "label": "Goodlettsville - TN 37072",
    "county": "Davidson County",
    "state": "TN",
    "city": "Goodlettsville"
  },
  {
    "value": "37073",
    "label": "Greenbrier - TN 37073",
    "county": "Robertson County",
    "state": "TN",
    "city": "Greenbrier"
  },
  {
    "value": "37074",
    "label": "Hartsville - TN 37074",
    "county": "Trousdale County",
    "state": "TN",
    "city": "Hartsville"
  },
  {
    "value": "37075",
    "label": "Hendersonville - TN 37075",
    "county": "Sumner County",
    "state": "TN",
    "city": "Hendersonville"
  },
  {
    "value": "37076",
    "label": "Hermitage - TN 37076",
    "county": "Davidson County",
    "state": "TN",
    "city": "Hermitage"
  },
  {
    "value": "37078",
    "label": "Hurricane Mills - TN 37078",
    "county": "Humphreys County",
    "state": "TN",
    "city": "Hurricane Mills"
  },
  {
    "value": "37079",
    "label": "Indian Mound - TN 37079",
    "county": "Stewart County",
    "state": "TN",
    "city": "Indian Mound"
  },
  {
    "value": "37080",
    "label": "Joelton - TN 37080",
    "county": "Davidson County",
    "state": "TN",
    "city": "Joelton"
  },
  {
    "value": "37082",
    "label": "Kingston Springs - TN 37082",
    "county": "Cheatham County",
    "state": "TN",
    "city": "Kingston Springs"
  },
  {
    "value": "37083",
    "label": "Lafayette - TN 37083",
    "county": "Macon County",
    "state": "TN",
    "city": "Lafayette"
  },
  {
    "value": "37085",
    "label": "Lascassas - TN 37085",
    "county": "Rutherford County",
    "state": "TN",
    "city": "Lascassas"
  },
  {
    "value": "37086",
    "label": "La Vergne - TN 37086",
    "county": "Rutherford County",
    "state": "TN",
    "city": "La Vergne"
  },
  {
    "value": "37087",
    "label": "Lebanon - TN 37087",
    "county": "Wilson County",
    "state": "TN",
    "city": "Lebanon"
  },
  {
    "value": "37090",
    "label": "Lebanon - TN 37090",
    "county": "Wilson County",
    "state": "TN",
    "city": "Lebanon"
  },
  {
    "value": "37091",
    "label": "Lewisburg - TN 37091",
    "county": "Marshall County",
    "state": "TN",
    "city": "Lewisburg"
  },
  {
    "value": "37095",
    "label": "Liberty - TN 37095",
    "county": "DeKalb County",
    "state": "TN",
    "city": "Liberty"
  },
  {
    "value": "37096",
    "label": "Linden - TN 37096",
    "county": "Perry County",
    "state": "TN",
    "city": "Linden"
  },
  {
    "value": "37097",
    "label": "Lobelville - TN 37097",
    "county": "Perry County",
    "state": "TN",
    "city": "Lobelville"
  },
  {
    "value": "37098",
    "label": "Lyles - TN 37098",
    "county": "Hickman County",
    "state": "TN",
    "city": "Lyles"
  },
  {
    "value": "37101",
    "label": "Mc Ewen - TN 37101",
    "county": "Humphreys County",
    "state": "TN",
    "city": "Mc Ewen"
  },
  {
    "value": "37110",
    "label": "Mcminnville - TN 37110",
    "county": "Warren County",
    "state": "TN",
    "city": "Mcminnville"
  },
  {
    "value": "37115",
    "label": "Madison - TN 37115",
    "county": "Davidson County",
    "state": "TN",
    "city": "Madison"
  },
  {
    "value": "37118",
    "label": "Milton - TN 37118",
    "county": "Rutherford County",
    "state": "TN",
    "city": "Milton"
  },
  {
    "value": "37122",
    "label": "Mount Juliet - TN 37122",
    "county": "Wilson County",
    "state": "TN",
    "city": "Mount Juliet"
  },
  {
    "value": "37127",
    "label": "Murfreesboro - TN 37127",
    "county": "Rutherford County",
    "state": "TN",
    "city": "Murfreesboro"
  },
  {
    "value": "37128",
    "label": "Murfreesboro - TN 37128",
    "county": "Rutherford County",
    "state": "TN",
    "city": "Murfreesboro"
  },
  {
    "value": "37129",
    "label": "Murfreesboro - TN 37129",
    "county": "Rutherford County",
    "state": "TN",
    "city": "Murfreesboro"
  },
  {
    "value": "37130",
    "label": "Murfreesboro - TN 37130",
    "county": "Rutherford County",
    "state": "TN",
    "city": "Murfreesboro"
  },
  {
    "value": "37134",
    "label": "New Johnsonville - TN 37134",
    "county": "Humphreys County",
    "state": "TN",
    "city": "New Johnsonville"
  },
  {
    "value": "37135",
    "label": "Nolensville - TN 37135",
    "county": "Williamson County",
    "state": "TN",
    "city": "Nolensville"
  },
  {
    "value": "37137",
    "label": "Nunnelly - TN 37137",
    "county": "Hickman County",
    "state": "TN",
    "city": "Nunnelly"
  },
  {
    "value": "37138",
    "label": "Old Hickory - TN 37138",
    "county": "Davidson County",
    "state": "TN",
    "city": "Old Hickory"
  },
  {
    "value": "37140",
    "label": "Only - TN 37140",
    "county": "Hickman County",
    "state": "TN",
    "city": "Only"
  },
  {
    "value": "37141",
    "label": "Orlinda - TN 37141",
    "county": "Robertson County",
    "state": "TN",
    "city": "Orlinda"
  },
  {
    "value": "37142",
    "label": "Palmyra - TN 37142",
    "county": "Montgomery County",
    "state": "TN",
    "city": "Palmyra"
  },
  {
    "value": "37143",
    "label": "Pegram - TN 37143",
    "county": "Cheatham County",
    "state": "TN",
    "city": "Pegram"
  },
  {
    "value": "37144",
    "label": "Petersburg - TN 37144",
    "county": "Lincoln County",
    "state": "TN",
    "city": "Petersburg"
  },
  {
    "value": "37145",
    "label": "Pleasant Shade - TN 37145",
    "county": "Smith County",
    "state": "TN",
    "city": "Pleasant Shade"
  },
  {
    "value": "37146",
    "label": "Pleasant View - TN 37146",
    "county": "Cheatham County",
    "state": "TN",
    "city": "Pleasant View"
  },
  {
    "value": "37148",
    "label": "Portland - TN 37148",
    "county": "Sumner County",
    "state": "TN",
    "city": "Portland"
  },
  {
    "value": "37149",
    "label": "Readyville - TN 37149",
    "county": "Cannon County",
    "state": "TN",
    "city": "Readyville"
  },
  {
    "value": "37150",
    "label": "Red Boiling Springs - TN 37150",
    "county": "Macon County",
    "state": "TN",
    "city": "Red Boiling Springs"
  },
  {
    "value": "37151",
    "label": "Riddleton - TN 37151",
    "county": "Smith County",
    "state": "TN",
    "city": "Riddleton"
  },
  {
    "value": "37153",
    "label": "Rockvale - TN 37153",
    "county": "Rutherford County",
    "state": "TN",
    "city": "Rockvale"
  },
  {
    "value": "37160",
    "label": "Shelbyville - TN 37160",
    "county": "Bedford County",
    "state": "TN",
    "city": "Shelbyville"
  },
  {
    "value": "37166",
    "label": "Smithville - TN 37166",
    "county": "DeKalb County",
    "state": "TN",
    "city": "Smithville"
  },
  {
    "value": "37167",
    "label": "Smyrna - TN 37167",
    "county": "Rutherford County",
    "state": "TN",
    "city": "Smyrna"
  },
  {
    "value": "37171",
    "label": "Southside - TN 37171",
    "county": "Montgomery County",
    "state": "TN",
    "city": "Southside"
  },
  {
    "value": "37172",
    "label": "Springfield - TN 37172",
    "county": "Robertson County",
    "state": "TN",
    "city": "Springfield"
  },
  {
    "value": "37174",
    "label": "Spring Hill - TN 37174",
    "county": "Williamson County",
    "state": "TN",
    "city": "Spring Hill"
  },
  {
    "value": "37175",
    "label": "Stewart - TN 37175",
    "county": "Houston County",
    "state": "TN",
    "city": "Stewart"
  },
  {
    "value": "37178",
    "label": "Tennessee Ridge - TN 37178",
    "county": "Houston County",
    "state": "TN",
    "city": "Tennessee Ridge"
  },
  {
    "value": "37179",
    "label": "Thompsons Station - TN 37179",
    "county": "Williamson County",
    "state": "TN",
    "city": "Thompsons Station"
  },
  {
    "value": "37180",
    "label": "Unionville - TN 37180",
    "county": "Bedford County",
    "state": "TN",
    "city": "Unionville"
  },
  {
    "value": "37181",
    "label": "Vanleer - TN 37181",
    "county": "Dickson County",
    "state": "TN",
    "city": "Vanleer"
  },
  {
    "value": "37183",
    "label": "Wartrace - TN 37183",
    "county": "Bedford County",
    "state": "TN",
    "city": "Wartrace"
  },
  {
    "value": "37184",
    "label": "Watertown - TN 37184",
    "county": "Wilson County",
    "state": "TN",
    "city": "Watertown"
  },
  {
    "value": "37185",
    "label": "Waverly - TN 37185",
    "county": "Humphreys County",
    "state": "TN",
    "city": "Waverly"
  },
  {
    "value": "37186",
    "label": "Westmoreland - TN 37186",
    "county": "Sumner County",
    "state": "TN",
    "city": "Westmoreland"
  },
  {
    "value": "37187",
    "label": "White Bluff - TN 37187",
    "county": "Dickson County",
    "state": "TN",
    "city": "White Bluff"
  },
  {
    "value": "37188",
    "label": "White House - TN 37188",
    "county": "Robertson County",
    "state": "TN",
    "city": "White House"
  },
  {
    "value": "37189",
    "label": "Whites Creek - TN 37189",
    "county": "Davidson County",
    "state": "TN",
    "city": "Whites Creek"
  },
  {
    "value": "37190",
    "label": "Woodbury - TN 37190",
    "county": "Cannon County",
    "state": "TN",
    "city": "Woodbury"
  },
  {
    "value": "37191",
    "label": "Woodlawn - TN 37191",
    "county": "Montgomery County",
    "state": "TN",
    "city": "Woodlawn"
  },
  {
    "value": "37201",
    "label": "Nashville - TN 37201",
    "county": "Davidson County",
    "state": "TN",
    "city": "Nashville"
  },
  {
    "value": "37203",
    "label": "Nashville - TN 37203",
    "county": "Davidson County",
    "state": "TN",
    "city": "Nashville"
  },
  {
    "value": "37204",
    "label": "Nashville - TN 37204",
    "county": "Davidson County",
    "state": "TN",
    "city": "Nashville"
  },
  {
    "value": "37205",
    "label": "Nashville - TN 37205",
    "county": "Davidson County",
    "state": "TN",
    "city": "Nashville"
  },
  {
    "value": "37206",
    "label": "Nashville - TN 37206",
    "county": "Davidson County",
    "state": "TN",
    "city": "Nashville"
  },
  {
    "value": "37207",
    "label": "Nashville - TN 37207",
    "county": "Davidson County",
    "state": "TN",
    "city": "Nashville"
  },
  {
    "value": "37208",
    "label": "Nashville - TN 37208",
    "county": "Davidson County",
    "state": "TN",
    "city": "Nashville"
  },
  {
    "value": "37209",
    "label": "Nashville - TN 37209",
    "county": "Davidson County",
    "state": "TN",
    "city": "Nashville"
  },
  {
    "value": "37210",
    "label": "Nashville - TN 37210",
    "county": "Davidson County",
    "state": "TN",
    "city": "Nashville"
  },
  {
    "value": "37211",
    "label": "Nashville - TN 37211",
    "county": "Davidson County",
    "state": "TN",
    "city": "Nashville"
  },
  {
    "value": "37212",
    "label": "Nashville - TN 37212",
    "county": "Davidson County",
    "state": "TN",
    "city": "Nashville"
  },
  {
    "value": "37213",
    "label": "Nashville - TN 37213",
    "county": "Davidson County",
    "state": "TN",
    "city": "Nashville"
  },
  {
    "value": "37214",
    "label": "Nashville - TN 37214",
    "county": "Davidson County",
    "state": "TN",
    "city": "Nashville"
  },
  {
    "value": "37215",
    "label": "Nashville - TN 37215",
    "county": "Davidson County",
    "state": "TN",
    "city": "Nashville"
  },
  {
    "value": "37216",
    "label": "Nashville - TN 37216",
    "county": "Davidson County",
    "state": "TN",
    "city": "Nashville"
  },
  {
    "value": "37217",
    "label": "Nashville - TN 37217",
    "county": "Davidson County",
    "state": "TN",
    "city": "Nashville"
  },
  {
    "value": "37218",
    "label": "Nashville - TN 37218",
    "county": "Davidson County",
    "state": "TN",
    "city": "Nashville"
  },
  {
    "value": "37219",
    "label": "Nashville - TN 37219",
    "county": "Davidson County",
    "state": "TN",
    "city": "Nashville"
  },
  {
    "value": "37220",
    "label": "Nashville - TN 37220",
    "county": "Davidson County",
    "state": "TN",
    "city": "Nashville"
  },
  {
    "value": "37221",
    "label": "Nashville - TN 37221",
    "county": "Davidson County",
    "state": "TN",
    "city": "Nashville"
  },
  {
    "value": "37228",
    "label": "Nashville - TN 37228",
    "county": "Davidson County",
    "state": "TN",
    "city": "Nashville"
  },
  {
    "value": "37238",
    "label": "Nashville - TN 37238",
    "county": "Davidson County",
    "state": "TN",
    "city": "Nashville"
  },
  {
    "value": "37245",
    "label": "Nashville - TN 37245",
    "county": "Davidson County",
    "state": "TN",
    "city": "Nashville"
  },
  {
    "value": "37247",
    "label": "Nashville - TN 37247",
    "county": "Davidson County",
    "state": "TN",
    "city": "Nashville"
  },
  {
    "value": "37248",
    "label": "Nashville - TN 37248",
    "county": "Davidson County",
    "state": "TN",
    "city": "Nashville"
  },
  {
    "value": "37249",
    "label": "Nashville - TN 37249",
    "county": "Davidson County",
    "state": "TN",
    "city": "Nashville"
  },
  {
    "value": "37301",
    "label": "Altamont - TN 37301",
    "county": "Grundy County",
    "state": "TN",
    "city": "Altamont"
  },
  {
    "value": "37302",
    "label": "Apison - TN 37302",
    "county": "Hamilton County",
    "state": "TN",
    "city": "Apison"
  },
  {
    "value": "37303",
    "label": "Athens - TN 37303",
    "county": "McMinn County",
    "state": "TN",
    "city": "Athens"
  },
  {
    "value": "37304",
    "label": "Bakewell - TN 37304",
    "county": "Hamilton County",
    "state": "TN",
    "city": "Bakewell"
  },
  {
    "value": "37305",
    "label": "Beersheba Springs - TN 37305",
    "county": "Grundy County",
    "state": "TN",
    "city": "Beersheba Springs"
  },
  {
    "value": "37306",
    "label": "Belvidere - TN 37306",
    "county": "Franklin County",
    "state": "TN",
    "city": "Belvidere"
  },
  {
    "value": "37307",
    "label": "Benton - TN 37307",
    "county": "Polk County",
    "state": "TN",
    "city": "Benton"
  },
  {
    "value": "37308",
    "label": "Birchwood - TN 37308",
    "county": "Hamilton County",
    "state": "TN",
    "city": "Birchwood"
  },
  {
    "value": "37309",
    "label": "Calhoun - TN 37309",
    "county": "McMinn County",
    "state": "TN",
    "city": "Calhoun"
  },
  {
    "value": "37310",
    "label": "Charleston - TN 37310",
    "county": "Bradley County",
    "state": "TN",
    "city": "Charleston"
  },
  {
    "value": "37311",
    "label": "Cleveland - TN 37311",
    "county": "Bradley County",
    "state": "TN",
    "city": "Cleveland"
  },
  {
    "value": "37312",
    "label": "Cleveland - TN 37312",
    "county": "Bradley County",
    "state": "TN",
    "city": "Cleveland"
  },
  {
    "value": "37313",
    "label": "Coalmont - TN 37313",
    "county": "Grundy County",
    "state": "TN",
    "city": "Coalmont"
  },
  {
    "value": "37317",
    "label": "Copperhill - TN 37317",
    "county": "Polk County",
    "state": "TN",
    "city": "Copperhill"
  },
  {
    "value": "37318",
    "label": "Cowan - TN 37318",
    "county": "Franklin County",
    "state": "TN",
    "city": "Cowan"
  },
  {
    "value": "37321",
    "label": "Dayton - TN 37321",
    "county": "Rhea County",
    "state": "TN",
    "city": "Dayton"
  },
  {
    "value": "37322",
    "label": "Decatur - TN 37322",
    "county": "Meigs County",
    "state": "TN",
    "city": "Decatur"
  },
  {
    "value": "37323",
    "label": "Cleveland - TN 37323",
    "county": "Bradley County",
    "state": "TN",
    "city": "Cleveland"
  },
  {
    "value": "37324",
    "label": "Decherd - TN 37324",
    "county": "Franklin County",
    "state": "TN",
    "city": "Decherd"
  },
  {
    "value": "37325",
    "label": "Delano - TN 37325",
    "county": "Polk County",
    "state": "TN",
    "city": "Delano"
  },
  {
    "value": "37327",
    "label": "Dunlap - TN 37327",
    "county": "Sequatchie County",
    "state": "TN",
    "city": "Dunlap"
  },
  {
    "value": "37328",
    "label": "Elora - TN 37328",
    "county": "Lincoln County",
    "state": "TN",
    "city": "Elora"
  },
  {
    "value": "37329",
    "label": "Englewood - TN 37329",
    "county": "McMinn County",
    "state": "TN",
    "city": "Englewood"
  },
  {
    "value": "37330",
    "label": "Estill Springs - TN 37330",
    "county": "Franklin County",
    "state": "TN",
    "city": "Estill Springs"
  },
  {
    "value": "37331",
    "label": "Etowah - TN 37331",
    "county": "McMinn County",
    "state": "TN",
    "city": "Etowah"
  },
  {
    "value": "37332",
    "label": "Evensville - TN 37332",
    "county": "Rhea County",
    "state": "TN",
    "city": "Evensville"
  },
  {
    "value": "37333",
    "label": "Farner - TN 37333",
    "county": "Polk County",
    "state": "TN",
    "city": "Farner"
  },
  {
    "value": "37334",
    "label": "Fayetteville - TN 37334",
    "county": "Lincoln County",
    "state": "TN",
    "city": "Fayetteville"
  },
  {
    "value": "37335",
    "label": "Flintville - TN 37335",
    "county": "Lincoln County",
    "state": "TN",
    "city": "Flintville"
  },
  {
    "value": "37336",
    "label": "Georgetown - TN 37336",
    "county": "Bradley County",
    "state": "TN",
    "city": "Georgetown"
  },
  {
    "value": "37337",
    "label": "Grandview - TN 37337",
    "county": "Rhea County",
    "state": "TN",
    "city": "Grandview"
  },
  {
    "value": "37338",
    "label": "Graysville - TN 37338",
    "county": "Rhea County",
    "state": "TN",
    "city": "Graysville"
  },
  {
    "value": "37339",
    "label": "Gruetli Laager - TN 37339",
    "county": "Grundy County",
    "state": "TN",
    "city": "Gruetli Laager"
  },
  {
    "value": "37340",
    "label": "Guild - TN 37340",
    "county": "Marion County",
    "state": "TN",
    "city": "Guild"
  },
  {
    "value": "37341",
    "label": "Harrison - TN 37341",
    "county": "Hamilton County",
    "state": "TN",
    "city": "Harrison"
  },
  {
    "value": "37342",
    "label": "Hillsboro - TN 37342",
    "county": "Coffee County",
    "state": "TN",
    "city": "Hillsboro"
  },
  {
    "value": "37343",
    "label": "Hixson - TN 37343",
    "county": "Hamilton County",
    "state": "TN",
    "city": "Hixson"
  },
  {
    "value": "37345",
    "label": "Huntland - TN 37345",
    "county": "Franklin County",
    "state": "TN",
    "city": "Huntland"
  },
  {
    "value": "37347",
    "label": "Jasper - TN 37347",
    "county": "Marion County",
    "state": "TN",
    "city": "Jasper"
  },
  {
    "value": "37348",
    "label": "Kelso - TN 37348",
    "county": "Lincoln County",
    "state": "TN",
    "city": "Kelso"
  },
  {
    "value": "37350",
    "label": "Lookout Mountain - TN 37350",
    "county": "Hamilton County",
    "state": "TN",
    "city": "Lookout Mountain"
  },
  {
    "value": "37351",
    "label": "Lupton City - TN 37351",
    "county": "Hamilton County",
    "state": "TN",
    "city": "Lupton City"
  },
  {
    "value": "37352",
    "label": "Lynchburg - TN 37352",
    "county": "Moore County",
    "state": "TN",
    "city": "Lynchburg"
  },
  {
    "value": "37353",
    "label": "Mc Donald - TN 37353",
    "county": "Bradley County",
    "state": "TN",
    "city": "Mc Donald"
  },
  {
    "value": "37354",
    "label": "Madisonville - TN 37354",
    "county": "Monroe County",
    "state": "TN",
    "city": "Madisonville"
  },
  {
    "value": "37355",
    "label": "Manchester - TN 37355",
    "county": "Coffee County",
    "state": "TN",
    "city": "Manchester"
  },
  {
    "value": "37356",
    "label": "Monteagle - TN 37356",
    "county": "Grundy County",
    "state": "TN",
    "city": "Monteagle"
  },
  {
    "value": "37357",
    "label": "Morrison - TN 37357",
    "county": "Warren County",
    "state": "TN",
    "city": "Morrison"
  },
  {
    "value": "37359",
    "label": "Mulberry - TN 37359",
    "county": "Lincoln County",
    "state": "TN",
    "city": "Mulberry"
  },
  {
    "value": "37360",
    "label": "Normandy - TN 37360",
    "county": "Coffee County",
    "state": "TN",
    "city": "Normandy"
  },
  {
    "value": "37361",
    "label": "Ocoee - TN 37361",
    "county": "Polk County",
    "state": "TN",
    "city": "Ocoee"
  },
  {
    "value": "37362",
    "label": "Old Fort - TN 37362",
    "county": "Polk County",
    "state": "TN",
    "city": "Old Fort"
  },
  {
    "value": "37363",
    "label": "Ooltewah - TN 37363",
    "county": "Hamilton County",
    "state": "TN",
    "city": "Ooltewah"
  },
  {
    "value": "37365",
    "label": "Palmer - TN 37365",
    "county": "Grundy County",
    "state": "TN",
    "city": "Palmer"
  },
  {
    "value": "37366",
    "label": "Pelham - TN 37366",
    "county": "Grundy County",
    "state": "TN",
    "city": "Pelham"
  },
  {
    "value": "37367",
    "label": "Pikeville - TN 37367",
    "county": "Bledsoe County",
    "state": "TN",
    "city": "Pikeville"
  },
  {
    "value": "37369",
    "label": "Reliance - TN 37369",
    "county": "Polk County",
    "state": "TN",
    "city": "Reliance"
  },
  {
    "value": "37370",
    "label": "Riceville - TN 37370",
    "county": "McMinn County",
    "state": "TN",
    "city": "Riceville"
  },
  {
    "value": "37373",
    "label": "Sale Creek - TN 37373",
    "county": "Hamilton County",
    "state": "TN",
    "city": "Sale Creek"
  },
  {
    "value": "37374",
    "label": "Sequatchie - TN 37374",
    "county": "Marion County",
    "state": "TN",
    "city": "Sequatchie"
  },
  {
    "value": "37375",
    "label": "Sewanee - TN 37375",
    "county": "Franklin County",
    "state": "TN",
    "city": "Sewanee"
  },
  {
    "value": "37376",
    "label": "Sherwood - TN 37376",
    "county": "Franklin County",
    "state": "TN",
    "city": "Sherwood"
  },
  {
    "value": "37377",
    "label": "Signal Mountain - TN 37377",
    "county": "Hamilton County",
    "state": "TN",
    "city": "Signal Mountain"
  },
  {
    "value": "37379",
    "label": "Soddy Daisy - TN 37379",
    "county": "Hamilton County",
    "state": "TN",
    "city": "Soddy Daisy"
  },
  {
    "value": "37380",
    "label": "South Pittsburg - TN 37380",
    "county": "Marion County",
    "state": "TN",
    "city": "South Pittsburg"
  },
  {
    "value": "37381",
    "label": "Spring City - TN 37381",
    "county": "Rhea County",
    "state": "TN",
    "city": "Spring City"
  },
  {
    "value": "37385",
    "label": "Tellico Plains - TN 37385",
    "county": "Monroe County",
    "state": "TN",
    "city": "Tellico Plains"
  },
  {
    "value": "37387",
    "label": "Tracy City - TN 37387",
    "county": "Grundy County",
    "state": "TN",
    "city": "Tracy City"
  },
  {
    "value": "37388",
    "label": "Tullahoma - TN 37388",
    "county": "Coffee County",
    "state": "TN",
    "city": "Tullahoma"
  },
  {
    "value": "37389",
    "label": "Arnold AFB - TN 37389",
    "county": "Coffee County",
    "state": "TN",
    "city": "Arnold AFB"
  },
  {
    "value": "37391",
    "label": "Turtletown - TN 37391",
    "county": "Polk County",
    "state": "TN",
    "city": "Turtletown"
  },
  {
    "value": "37396",
    "label": "Whiteside - TN 37396",
    "county": "Marion County",
    "state": "TN",
    "city": "Whiteside"
  },
  {
    "value": "37397",
    "label": "Whitwell - TN 37397",
    "county": "Marion County",
    "state": "TN",
    "city": "Whitwell"
  },
  {
    "value": "37398",
    "label": "Winchester - TN 37398",
    "county": "Franklin County",
    "state": "TN",
    "city": "Winchester"
  },
  {
    "value": "37402",
    "label": "Chattanooga - TN 37402",
    "county": "Hamilton County",
    "state": "TN",
    "city": "Chattanooga"
  },
  {
    "value": "37403",
    "label": "Chattanooga - TN 37403",
    "county": "Hamilton County",
    "state": "TN",
    "city": "Chattanooga"
  },
  {
    "value": "37404",
    "label": "Chattanooga - TN 37404",
    "county": "Hamilton County",
    "state": "TN",
    "city": "Chattanooga"
  },
  {
    "value": "37405",
    "label": "Chattanooga - TN 37405",
    "county": "Hamilton County",
    "state": "TN",
    "city": "Chattanooga"
  },
  {
    "value": "37406",
    "label": "Chattanooga - TN 37406",
    "county": "Hamilton County",
    "state": "TN",
    "city": "Chattanooga"
  },
  {
    "value": "37407",
    "label": "Chattanooga - TN 37407",
    "county": "Hamilton County",
    "state": "TN",
    "city": "Chattanooga"
  },
  {
    "value": "37408",
    "label": "Chattanooga - TN 37408",
    "county": "Hamilton County",
    "state": "TN",
    "city": "Chattanooga"
  },
  {
    "value": "37409",
    "label": "Chattanooga - TN 37409",
    "county": "Hamilton County",
    "state": "TN",
    "city": "Chattanooga"
  },
  {
    "value": "37410",
    "label": "Chattanooga - TN 37410",
    "county": "Hamilton County",
    "state": "TN",
    "city": "Chattanooga"
  },
  {
    "value": "37411",
    "label": "Chattanooga - TN 37411",
    "county": "Hamilton County",
    "state": "TN",
    "city": "Chattanooga"
  },
  {
    "value": "37412",
    "label": "Chattanooga - TN 37412",
    "county": "Hamilton County",
    "state": "TN",
    "city": "Chattanooga"
  },
  {
    "value": "37415",
    "label": "Chattanooga - TN 37415",
    "county": "Hamilton County",
    "state": "TN",
    "city": "Chattanooga"
  },
  {
    "value": "37416",
    "label": "Chattanooga - TN 37416",
    "county": "Hamilton County",
    "state": "TN",
    "city": "Chattanooga"
  },
  {
    "value": "37419",
    "label": "Chattanooga - TN 37419",
    "county": "Hamilton County",
    "state": "TN",
    "city": "Chattanooga"
  },
  {
    "value": "37421",
    "label": "Chattanooga - TN 37421",
    "county": "Hamilton County",
    "state": "TN",
    "city": "Chattanooga"
  },
  {
    "value": "37450",
    "label": "Chattanooga - TN 37450",
    "county": "Hamilton County",
    "state": "TN",
    "city": "Chattanooga"
  },
  {
    "value": "37601",
    "label": "Johnson City - TN 37601",
    "county": "Washington County",
    "state": "TN",
    "city": "Johnson City"
  },
  {
    "value": "37604",
    "label": "Johnson City - TN 37604",
    "county": "Washington County",
    "state": "TN",
    "city": "Johnson City"
  },
  {
    "value": "37615",
    "label": "Johnson City - TN 37615",
    "county": "Washington County",
    "state": "TN",
    "city": "Johnson City"
  },
  {
    "value": "37616",
    "label": "Afton - TN 37616",
    "county": "Greene County",
    "state": "TN",
    "city": "Afton"
  },
  {
    "value": "37617",
    "label": "Blountville - TN 37617",
    "county": "Sullivan County",
    "state": "TN",
    "city": "Blountville"
  },
  {
    "value": "37618",
    "label": "Bluff City - TN 37618",
    "county": "Sullivan County",
    "state": "TN",
    "city": "Bluff City"
  },
  {
    "value": "37620",
    "label": "Bristol - TN 37620",
    "county": "Sullivan County",
    "state": "TN",
    "city": "Bristol"
  },
  {
    "value": "37640",
    "label": "Butler - TN 37640",
    "county": "Johnson County",
    "state": "TN",
    "city": "Butler"
  },
  {
    "value": "37641",
    "label": "Chuckey - TN 37641",
    "county": "Greene County",
    "state": "TN",
    "city": "Chuckey"
  },
  {
    "value": "37642",
    "label": "Church Hill - TN 37642",
    "county": "Hawkins County",
    "state": "TN",
    "city": "Church Hill"
  },
  {
    "value": "37643",
    "label": "Elizabethton - TN 37643",
    "county": "Carter County",
    "state": "TN",
    "city": "Elizabethton"
  },
  {
    "value": "37645",
    "label": "Mount Carmel - TN 37645",
    "county": "Hawkins County",
    "state": "TN",
    "city": "Mount Carmel"
  },
  {
    "value": "37650",
    "label": "Erwin - TN 37650",
    "county": "Unicoi County",
    "state": "TN",
    "city": "Erwin"
  },
  {
    "value": "37656",
    "label": "Fall Branch - TN 37656",
    "county": "Washington County",
    "state": "TN",
    "city": "Fall Branch"
  },
  {
    "value": "37657",
    "label": "Flag Pond - TN 37657",
    "county": "Unicoi County",
    "state": "TN",
    "city": "Flag Pond"
  },
  {
    "value": "37658",
    "label": "Hampton - TN 37658",
    "county": "Carter County",
    "state": "TN",
    "city": "Hampton"
  },
  {
    "value": "37659",
    "label": "Jonesborough - TN 37659",
    "county": "Washington County",
    "state": "TN",
    "city": "Jonesborough"
  },
  {
    "value": "37660",
    "label": "Kingsport - TN 37660",
    "county": "Sullivan County",
    "state": "TN",
    "city": "Kingsport"
  },
  {
    "value": "37663",
    "label": "Kingsport - TN 37663",
    "county": "Sullivan County",
    "state": "TN",
    "city": "Kingsport"
  },
  {
    "value": "37664",
    "label": "Kingsport - TN 37664",
    "county": "Sullivan County",
    "state": "TN",
    "city": "Kingsport"
  },
  {
    "value": "37665",
    "label": "Kingsport - TN 37665",
    "county": "Sullivan County",
    "state": "TN",
    "city": "Kingsport"
  },
  {
    "value": "37680",
    "label": "Laurel Bloomery - TN 37680",
    "county": "Johnson County",
    "state": "TN",
    "city": "Laurel Bloomery"
  },
  {
    "value": "37681",
    "label": "Limestone - TN 37681",
    "county": "Washington County",
    "state": "TN",
    "city": "Limestone"
  },
  {
    "value": "37683",
    "label": "Mountain City - TN 37683",
    "county": "Johnson County",
    "state": "TN",
    "city": "Mountain City"
  },
  {
    "value": "37686",
    "label": "Piney Flats - TN 37686",
    "county": "Sullivan County",
    "state": "TN",
    "city": "Piney Flats"
  },
  {
    "value": "37687",
    "label": "Roan Mountain - TN 37687",
    "county": "Carter County",
    "state": "TN",
    "city": "Roan Mountain"
  },
  {
    "value": "37688",
    "label": "Shady Valley - TN 37688",
    "county": "Johnson County",
    "state": "TN",
    "city": "Shady Valley"
  },
  {
    "value": "37690",
    "label": "Telford - TN 37690",
    "county": "Washington County",
    "state": "TN",
    "city": "Telford"
  },
  {
    "value": "37691",
    "label": "Trade - TN 37691",
    "county": "Johnson County",
    "state": "TN",
    "city": "Trade"
  },
  {
    "value": "37692",
    "label": "Unicoi - TN 37692",
    "county": "Unicoi County",
    "state": "TN",
    "city": "Unicoi"
  },
  {
    "value": "37694",
    "label": "Watauga - TN 37694",
    "county": "Carter County",
    "state": "TN",
    "city": "Watauga"
  },
  {
    "value": "37701",
    "label": "Alcoa - TN 37701",
    "county": "Blount County",
    "state": "TN",
    "city": "Alcoa"
  },
  {
    "value": "37705",
    "label": "Andersonville - TN 37705",
    "county": "Anderson County",
    "state": "TN",
    "city": "Andersonville"
  },
  {
    "value": "37708",
    "label": "Bean Station - TN 37708",
    "county": "Grainger County",
    "state": "TN",
    "city": "Bean Station"
  },
  {
    "value": "37709",
    "label": "Blaine - TN 37709",
    "county": "Grainger County",
    "state": "TN",
    "city": "Blaine"
  },
  {
    "value": "37710",
    "label": "Briceville - TN 37710",
    "county": "Anderson County",
    "state": "TN",
    "city": "Briceville"
  },
  {
    "value": "37711",
    "label": "Bulls Gap - TN 37711",
    "county": "Hawkins County",
    "state": "TN",
    "city": "Bulls Gap"
  },
  {
    "value": "37713",
    "label": "Bybee - TN 37713",
    "county": "Cocke County",
    "state": "TN",
    "city": "Bybee"
  },
  {
    "value": "37714",
    "label": "Caryville - TN 37714",
    "county": "Campbell County",
    "state": "TN",
    "city": "Caryville"
  },
  {
    "value": "37715",
    "label": "Clairfield - TN 37715",
    "county": "Claiborne County",
    "state": "TN",
    "city": "Clairfield"
  },
  {
    "value": "37716",
    "label": "Clinton - TN 37716",
    "county": "Anderson County",
    "state": "TN",
    "city": "Clinton"
  },
  {
    "value": "37721",
    "label": "Corryton - TN 37721",
    "county": "Knox County",
    "state": "TN",
    "city": "Corryton"
  },
  {
    "value": "37722",
    "label": "Cosby - TN 37722",
    "county": "Cocke County",
    "state": "TN",
    "city": "Cosby"
  },
  {
    "value": "37723",
    "label": "Crab Orchard - TN 37723",
    "county": "Cumberland County",
    "state": "TN",
    "city": "Crab Orchard"
  },
  {
    "value": "37724",
    "label": "Cumberland Gap - TN 37724",
    "county": "Claiborne County",
    "state": "TN",
    "city": "Cumberland Gap"
  },
  {
    "value": "37725",
    "label": "Dandridge - TN 37725",
    "county": "Jefferson County",
    "state": "TN",
    "city": "Dandridge"
  },
  {
    "value": "37726",
    "label": "Deer Lodge - TN 37726",
    "county": "Morgan County",
    "state": "TN",
    "city": "Deer Lodge"
  },
  {
    "value": "37727",
    "label": "Del Rio - TN 37727",
    "county": "Cocke County",
    "state": "TN",
    "city": "Del Rio"
  },
  {
    "value": "37729",
    "label": "Duff - TN 37729",
    "county": "Campbell County",
    "state": "TN",
    "city": "Duff"
  },
  {
    "value": "37731",
    "label": "Eidson - TN 37731",
    "county": "Hawkins County",
    "state": "TN",
    "city": "Eidson"
  },
  {
    "value": "37737",
    "label": "Friendsville - TN 37737",
    "county": "Blount County",
    "state": "TN",
    "city": "Friendsville"
  },
  {
    "value": "37738",
    "label": "Gatlinburg - TN 37738",
    "county": "Sevier County",
    "state": "TN",
    "city": "Gatlinburg"
  },
  {
    "value": "37742",
    "label": "Greenback - TN 37742",
    "county": "Loudon County",
    "state": "TN",
    "city": "Greenback"
  },
  {
    "value": "37743",
    "label": "Greeneville - TN 37743",
    "county": "Greene County",
    "state": "TN",
    "city": "Greeneville"
  },
  {
    "value": "37745",
    "label": "Greeneville - TN 37745",
    "county": "Greene County",
    "state": "TN",
    "city": "Greeneville"
  },
  {
    "value": "37748",
    "label": "Harriman - TN 37748",
    "county": "Roane County",
    "state": "TN",
    "city": "Harriman"
  },
  {
    "value": "37752",
    "label": "Harrogate - TN 37752",
    "county": "Claiborne County",
    "state": "TN",
    "city": "Harrogate"
  },
  {
    "value": "37753",
    "label": "Hartford - TN 37753",
    "county": "Cocke County",
    "state": "TN",
    "city": "Hartford"
  },
  {
    "value": "37754",
    "label": "Heiskell - TN 37754",
    "county": "Anderson County",
    "state": "TN",
    "city": "Heiskell"
  },
  {
    "value": "37755",
    "label": "Helenwood - TN 37755",
    "county": "Scott County",
    "state": "TN",
    "city": "Helenwood"
  },
  {
    "value": "37756",
    "label": "Huntsville - TN 37756",
    "county": "Scott County",
    "state": "TN",
    "city": "Huntsville"
  },
  {
    "value": "37757",
    "label": "Jacksboro - TN 37757",
    "county": "Campbell County",
    "state": "TN",
    "city": "Jacksboro"
  },
  {
    "value": "37760",
    "label": "Jefferson City - TN 37760",
    "county": "Jefferson County",
    "state": "TN",
    "city": "Jefferson City"
  },
  {
    "value": "37762",
    "label": "Jellico - TN 37762",
    "county": "Campbell County",
    "state": "TN",
    "city": "Jellico"
  },
  {
    "value": "37763",
    "label": "Kingston - TN 37763",
    "county": "Roane County",
    "state": "TN",
    "city": "Kingston"
  },
  {
    "value": "37764",
    "label": "Kodak - TN 37764",
    "county": "Sevier County",
    "state": "TN",
    "city": "Kodak"
  },
  {
    "value": "37765",
    "label": "Kyles Ford - TN 37765",
    "county": "Hancock County",
    "state": "TN",
    "city": "Kyles Ford"
  },
  {
    "value": "37766",
    "label": "La Follette - TN 37766",
    "county": "Campbell County",
    "state": "TN",
    "city": "La Follette"
  },
  {
    "value": "37769",
    "label": "Rocky Top - TN 37769",
    "county": "Anderson County",
    "state": "TN",
    "city": "Rocky Top"
  },
  {
    "value": "37770",
    "label": "Lancing - TN 37770",
    "county": "Morgan County",
    "state": "TN",
    "city": "Lancing"
  },
  {
    "value": "37771",
    "label": "Lenoir City - TN 37771",
    "county": "Loudon County",
    "state": "TN",
    "city": "Lenoir City"
  },
  {
    "value": "37772",
    "label": "Lenoir City - TN 37772",
    "county": "Loudon County",
    "state": "TN",
    "city": "Lenoir City"
  },
  {
    "value": "37774",
    "label": "Loudon - TN 37774",
    "county": "Loudon County",
    "state": "TN",
    "city": "Loudon"
  },
  {
    "value": "37777",
    "label": "Louisville - TN 37777",
    "county": "Blount County",
    "state": "TN",
    "city": "Louisville"
  },
  {
    "value": "37779",
    "label": "Luttrell - TN 37779",
    "county": "Union County",
    "state": "TN",
    "city": "Luttrell"
  },
  {
    "value": "37801",
    "label": "Maryville - TN 37801",
    "county": "Blount County",
    "state": "TN",
    "city": "Maryville"
  },
  {
    "value": "37803",
    "label": "Maryville - TN 37803",
    "county": "Blount County",
    "state": "TN",
    "city": "Maryville"
  },
  {
    "value": "37804",
    "label": "Maryville - TN 37804",
    "county": "Blount County",
    "state": "TN",
    "city": "Maryville"
  },
  {
    "value": "37806",
    "label": "Mascot - TN 37806",
    "county": "Knox County",
    "state": "TN",
    "city": "Mascot"
  },
  {
    "value": "37807",
    "label": "Maynardville - TN 37807",
    "county": "Union County",
    "state": "TN",
    "city": "Maynardville"
  },
  {
    "value": "37809",
    "label": "Midway - TN 37809",
    "county": "Greene County",
    "state": "TN",
    "city": "Midway"
  },
  {
    "value": "37810",
    "label": "Mohawk - TN 37810",
    "county": "Greene County",
    "state": "TN",
    "city": "Mohawk"
  },
  {
    "value": "37811",
    "label": "Mooresburg - TN 37811",
    "county": "Hawkins County",
    "state": "TN",
    "city": "Mooresburg"
  },
  {
    "value": "37813",
    "label": "Morristown - TN 37813",
    "county": "Hamblen County",
    "state": "TN",
    "city": "Morristown"
  },
  {
    "value": "37814",
    "label": "Morristown - TN 37814",
    "county": "Hamblen County",
    "state": "TN",
    "city": "Morristown"
  },
  {
    "value": "37818",
    "label": "Mosheim - TN 37818",
    "county": "Greene County",
    "state": "TN",
    "city": "Mosheim"
  },
  {
    "value": "37819",
    "label": "Newcomb - TN 37819",
    "county": "Campbell County",
    "state": "TN",
    "city": "Newcomb"
  },
  {
    "value": "37820",
    "label": "New Market - TN 37820",
    "county": "Jefferson County",
    "state": "TN",
    "city": "New Market"
  },
  {
    "value": "37821",
    "label": "Newport - TN 37821",
    "county": "Cocke County",
    "state": "TN",
    "city": "Newport"
  },
  {
    "value": "37825",
    "label": "New Tazewell - TN 37825",
    "county": "Claiborne County",
    "state": "TN",
    "city": "New Tazewell"
  },
  {
    "value": "37826",
    "label": "Niota - TN 37826",
    "county": "McMinn County",
    "state": "TN",
    "city": "Niota"
  },
  {
    "value": "37829",
    "label": "Oakdale - TN 37829",
    "county": "Morgan County",
    "state": "TN",
    "city": "Oakdale"
  },
  {
    "value": "37830",
    "label": "Oak Ridge - TN 37830",
    "county": "Anderson County",
    "state": "TN",
    "city": "Oak Ridge"
  },
  {
    "value": "37840",
    "label": "Oliver Springs - TN 37840",
    "county": "Roane County",
    "state": "TN",
    "city": "Oliver Springs"
  },
  {
    "value": "37841",
    "label": "Oneida - TN 37841",
    "county": "Scott County",
    "state": "TN",
    "city": "Oneida"
  },
  {
    "value": "37843",
    "label": "Parrottsville - TN 37843",
    "county": "Cocke County",
    "state": "TN",
    "city": "Parrottsville"
  },
  {
    "value": "37846",
    "label": "Philadelphia - TN 37846",
    "county": "Loudon County",
    "state": "TN",
    "city": "Philadelphia"
  },
  {
    "value": "37847",
    "label": "Pioneer - TN 37847",
    "county": "Scott County",
    "state": "TN",
    "city": "Pioneer"
  },
  {
    "value": "37848",
    "label": "Powder Springs - TN 37848",
    "county": "Grainger County",
    "state": "TN",
    "city": "Powder Springs"
  },
  {
    "value": "37849",
    "label": "Powell - TN 37849",
    "county": "Knox County",
    "state": "TN",
    "city": "Powell"
  },
  {
    "value": "37852",
    "label": "Robbins - TN 37852",
    "county": "Scott County",
    "state": "TN",
    "city": "Robbins"
  },
  {
    "value": "37853",
    "label": "Rockford - TN 37853",
    "county": "Blount County",
    "state": "TN",
    "city": "Rockford"
  },
  {
    "value": "37854",
    "label": "Rockwood - TN 37854",
    "county": "Roane County",
    "state": "TN",
    "city": "Rockwood"
  },
  {
    "value": "37857",
    "label": "Rogersville - TN 37857",
    "county": "Hawkins County",
    "state": "TN",
    "city": "Rogersville"
  },
  {
    "value": "37860",
    "label": "Russellville - TN 37860",
    "county": "Hamblen County",
    "state": "TN",
    "city": "Russellville"
  },
  {
    "value": "37861",
    "label": "Rutledge - TN 37861",
    "county": "Grainger County",
    "state": "TN",
    "city": "Rutledge"
  },
  {
    "value": "37862",
    "label": "Sevierville - TN 37862",
    "county": "Sevier County",
    "state": "TN",
    "city": "Sevierville"
  },
  {
    "value": "37863",
    "label": "Pigeon Forge - TN 37863",
    "county": "Sevier County",
    "state": "TN",
    "city": "Pigeon Forge"
  },
  {
    "value": "37865",
    "label": "Seymour - TN 37865",
    "county": "Sevier County",
    "state": "TN",
    "city": "Seymour"
  },
  {
    "value": "37866",
    "label": "Sharps Chapel - TN 37866",
    "county": "Union County",
    "state": "TN",
    "city": "Sharps Chapel"
  },
  {
    "value": "37869",
    "label": "Sneedville - TN 37869",
    "county": "Hancock County",
    "state": "TN",
    "city": "Sneedville"
  },
  {
    "value": "37870",
    "label": "Speedwell - TN 37870",
    "county": "Claiborne County",
    "state": "TN",
    "city": "Speedwell"
  },
  {
    "value": "37871",
    "label": "Strawberry Plains - TN 37871",
    "county": "Jefferson County",
    "state": "TN",
    "city": "Strawberry Plains"
  },
  {
    "value": "37872",
    "label": "Sunbright - TN 37872",
    "county": "Morgan County",
    "state": "TN",
    "city": "Sunbright"
  },
  {
    "value": "37873",
    "label": "Surgoinsville - TN 37873",
    "county": "Hawkins County",
    "state": "TN",
    "city": "Surgoinsville"
  },
  {
    "value": "37874",
    "label": "Sweetwater - TN 37874",
    "county": "Monroe County",
    "state": "TN",
    "city": "Sweetwater"
  },
  {
    "value": "37876",
    "label": "Sevierville - TN 37876",
    "county": "Sevier County",
    "state": "TN",
    "city": "Sevierville"
  },
  {
    "value": "37877",
    "label": "Talbott - TN 37877",
    "county": "Hamblen County",
    "state": "TN",
    "city": "Talbott"
  },
  {
    "value": "37878",
    "label": "Tallassee - TN 37878",
    "county": "Blount County",
    "state": "TN",
    "city": "Tallassee"
  },
  {
    "value": "37879",
    "label": "Tazewell - TN 37879",
    "county": "Claiborne County",
    "state": "TN",
    "city": "Tazewell"
  },
  {
    "value": "37880",
    "label": "Ten Mile - TN 37880",
    "county": "Meigs County",
    "state": "TN",
    "city": "Ten Mile"
  },
  {
    "value": "37881",
    "label": "Thorn Hill - TN 37881",
    "county": "Grainger County",
    "state": "TN",
    "city": "Thorn Hill"
  },
  {
    "value": "37882",
    "label": "Townsend - TN 37882",
    "county": "Blount County",
    "state": "TN",
    "city": "Townsend"
  },
  {
    "value": "37885",
    "label": "Vonore - TN 37885",
    "county": "Monroe County",
    "state": "TN",
    "city": "Vonore"
  },
  {
    "value": "37886",
    "label": "Walland - TN 37886",
    "county": "Blount County",
    "state": "TN",
    "city": "Walland"
  },
  {
    "value": "37887",
    "label": "Wartburg - TN 37887",
    "county": "Morgan County",
    "state": "TN",
    "city": "Wartburg"
  },
  {
    "value": "37888",
    "label": "Washburn - TN 37888",
    "county": "Grainger County",
    "state": "TN",
    "city": "Washburn"
  },
  {
    "value": "37890",
    "label": "White Pine - TN 37890",
    "county": "Jefferson County",
    "state": "TN",
    "city": "White Pine"
  },
  {
    "value": "37891",
    "label": "Whitesburg - TN 37891",
    "county": "Hamblen County",
    "state": "TN",
    "city": "Whitesburg"
  },
  {
    "value": "37892",
    "label": "Winfield - TN 37892",
    "county": "Scott County",
    "state": "TN",
    "city": "Winfield"
  },
  {
    "value": "37902",
    "label": "Knoxville - TN 37902",
    "county": "Knox County",
    "state": "TN",
    "city": "Knoxville"
  },
  {
    "value": "37909",
    "label": "Knoxville - TN 37909",
    "county": "Knox County",
    "state": "TN",
    "city": "Knoxville"
  },
  {
    "value": "37912",
    "label": "Knoxville - TN 37912",
    "county": "Knox County",
    "state": "TN",
    "city": "Knoxville"
  },
  {
    "value": "37914",
    "label": "Knoxville - TN 37914",
    "county": "Knox County",
    "state": "TN",
    "city": "Knoxville"
  },
  {
    "value": "37915",
    "label": "Knoxville - TN 37915",
    "county": "Knox County",
    "state": "TN",
    "city": "Knoxville"
  },
  {
    "value": "37916",
    "label": "Knoxville - TN 37916",
    "county": "Knox County",
    "state": "TN",
    "city": "Knoxville"
  },
  {
    "value": "37917",
    "label": "Knoxville - TN 37917",
    "county": "Knox County",
    "state": "TN",
    "city": "Knoxville"
  },
  {
    "value": "37918",
    "label": "Knoxville - TN 37918",
    "county": "Knox County",
    "state": "TN",
    "city": "Knoxville"
  },
  {
    "value": "37919",
    "label": "Knoxville - TN 37919",
    "county": "Knox County",
    "state": "TN",
    "city": "Knoxville"
  },
  {
    "value": "37920",
    "label": "Knoxville - TN 37920",
    "county": "Knox County",
    "state": "TN",
    "city": "Knoxville"
  },
  {
    "value": "37921",
    "label": "Knoxville - TN 37921",
    "county": "Knox County",
    "state": "TN",
    "city": "Knoxville"
  },
  {
    "value": "37922",
    "label": "Knoxville - TN 37922",
    "county": "Knox County",
    "state": "TN",
    "city": "Knoxville"
  },
  {
    "value": "37923",
    "label": "Knoxville - TN 37923",
    "county": "Knox County",
    "state": "TN",
    "city": "Knoxville"
  },
  {
    "value": "37924",
    "label": "Knoxville - TN 37924",
    "county": "Knox County",
    "state": "TN",
    "city": "Knoxville"
  },
  {
    "value": "37929",
    "label": "Knoxville - TN 37929",
    "county": "Knox County",
    "state": "TN",
    "city": "Knoxville"
  },
  {
    "value": "37931",
    "label": "Knoxville - TN 37931",
    "county": "Knox County",
    "state": "TN",
    "city": "Knoxville"
  },
  {
    "value": "37932",
    "label": "Knoxville - TN 37932",
    "county": "Knox County",
    "state": "TN",
    "city": "Knoxville"
  },
  {
    "value": "37934",
    "label": "Farragut - TN 37934",
    "county": "Knox County",
    "state": "TN",
    "city": "Farragut"
  },
  {
    "value": "37938",
    "label": "Knoxville - TN 37938",
    "county": "Knox County",
    "state": "TN",
    "city": "Knoxville"
  },
  {
    "value": "38001",
    "label": "Alamo - TN 38001",
    "county": "Crockett County",
    "state": "TN",
    "city": "Alamo"
  },
  {
    "value": "38002",
    "label": "Arlington - TN 38002",
    "county": "Shelby County",
    "state": "TN",
    "city": "Arlington"
  },
  {
    "value": "38004",
    "label": "Atoka - TN 38004",
    "county": "Tipton County",
    "state": "TN",
    "city": "Atoka"
  },
  {
    "value": "38006",
    "label": "Bells - TN 38006",
    "county": "Crockett County",
    "state": "TN",
    "city": "Bells"
  },
  {
    "value": "38008",
    "label": "Bolivar - TN 38008",
    "county": "Hardeman County",
    "state": "TN",
    "city": "Bolivar"
  },
  {
    "value": "38011",
    "label": "Brighton - TN 38011",
    "county": "Tipton County",
    "state": "TN",
    "city": "Brighton"
  },
  {
    "value": "38012",
    "label": "Brownsville - TN 38012",
    "county": "Haywood County",
    "state": "TN",
    "city": "Brownsville"
  },
  {
    "value": "38015",
    "label": "Burlison - TN 38015",
    "county": "Tipton County",
    "state": "TN",
    "city": "Burlison"
  },
  {
    "value": "38016",
    "label": "Cordova - TN 38016",
    "county": "Shelby County",
    "state": "TN",
    "city": "Cordova"
  },
  {
    "value": "38017",
    "label": "Collierville - TN 38017",
    "county": "Shelby County",
    "state": "TN",
    "city": "Collierville"
  },
  {
    "value": "38018",
    "label": "Cordova - TN 38018",
    "county": "Shelby County",
    "state": "TN",
    "city": "Cordova"
  },
  {
    "value": "38019",
    "label": "Covington - TN 38019",
    "county": "Tipton County",
    "state": "TN",
    "city": "Covington"
  },
  {
    "value": "38023",
    "label": "Drummonds - TN 38023",
    "county": "Tipton County",
    "state": "TN",
    "city": "Drummonds"
  },
  {
    "value": "38024",
    "label": "Dyersburg - TN 38024",
    "county": "Dyer County",
    "state": "TN",
    "city": "Dyersburg"
  },
  {
    "value": "38028",
    "label": "Eads - TN 38028",
    "county": "Fayette County",
    "state": "TN",
    "city": "Eads"
  },
  {
    "value": "38030",
    "label": "Finley - TN 38030",
    "county": "Dyer County",
    "state": "TN",
    "city": "Finley"
  },
  {
    "value": "38034",
    "label": "Friendship - TN 38034",
    "county": "Crockett County",
    "state": "TN",
    "city": "Friendship"
  },
  {
    "value": "38037",
    "label": "Gates - TN 38037",
    "county": "Lauderdale County",
    "state": "TN",
    "city": "Gates"
  },
  {
    "value": "38039",
    "label": "Grand Junction - TN 38039",
    "county": "Hardeman County",
    "state": "TN",
    "city": "Grand Junction"
  },
  {
    "value": "38040",
    "label": "Halls - TN 38040",
    "county": "Lauderdale County",
    "state": "TN",
    "city": "Halls"
  },
  {
    "value": "38041",
    "label": "Henning - TN 38041",
    "county": "Lauderdale County",
    "state": "TN",
    "city": "Henning"
  },
  {
    "value": "38042",
    "label": "Hickory Valley - TN 38042",
    "county": "Hardeman County",
    "state": "TN",
    "city": "Hickory Valley"
  },
  {
    "value": "38044",
    "label": "Hornsby - TN 38044",
    "county": "Hardeman County",
    "state": "TN",
    "city": "Hornsby"
  },
  {
    "value": "38047",
    "label": "Lenox - TN 38047",
    "county": "Dyer County",
    "state": "TN",
    "city": "Lenox"
  },
  {
    "value": "38049",
    "label": "Mason - TN 38049",
    "county": "Tipton County",
    "state": "TN",
    "city": "Mason"
  },
  {
    "value": "38052",
    "label": "Middleton - TN 38052",
    "county": "Hardeman County",
    "state": "TN",
    "city": "Middleton"
  },
  {
    "value": "38053",
    "label": "Millington - TN 38053",
    "county": "Shelby County",
    "state": "TN",
    "city": "Millington"
  },
  {
    "value": "38057",
    "label": "Moscow - TN 38057",
    "county": "Fayette County",
    "state": "TN",
    "city": "Moscow"
  },
  {
    "value": "38058",
    "label": "Munford - TN 38058",
    "county": "Tipton County",
    "state": "TN",
    "city": "Munford"
  },
  {
    "value": "38059",
    "label": "Newbern - TN 38059",
    "county": "Dyer County",
    "state": "TN",
    "city": "Newbern"
  },
  {
    "value": "38060",
    "label": "Oakland - TN 38060",
    "county": "Fayette County",
    "state": "TN",
    "city": "Oakland"
  },
  {
    "value": "38061",
    "label": "Pocahontas - TN 38061",
    "county": "Hardeman County",
    "state": "TN",
    "city": "Pocahontas"
  },
  {
    "value": "38063",
    "label": "Ripley - TN 38063",
    "county": "Lauderdale County",
    "state": "TN",
    "city": "Ripley"
  },
  {
    "value": "38066",
    "label": "Rossville - TN 38066",
    "county": "Fayette County",
    "state": "TN",
    "city": "Rossville"
  },
  {
    "value": "38067",
    "label": "Saulsbury - TN 38067",
    "county": "Hardeman County",
    "state": "TN",
    "city": "Saulsbury"
  },
  {
    "value": "38068",
    "label": "Somerville - TN 38068",
    "county": "Fayette County",
    "state": "TN",
    "city": "Somerville"
  },
  {
    "value": "38069",
    "label": "Stanton - TN 38069",
    "county": "Haywood County",
    "state": "TN",
    "city": "Stanton"
  },
  {
    "value": "38075",
    "label": "Whiteville - TN 38075",
    "county": "Hardeman County",
    "state": "TN",
    "city": "Whiteville"
  },
  {
    "value": "38076",
    "label": "Williston - TN 38076",
    "county": "Fayette County",
    "state": "TN",
    "city": "Williston"
  },
  {
    "value": "38079",
    "label": "Tiptonville - TN 38079",
    "county": "Lake County",
    "state": "TN",
    "city": "Tiptonville"
  },
  {
    "value": "38080",
    "label": "Ridgely - TN 38080",
    "county": "Lake County",
    "state": "TN",
    "city": "Ridgely"
  },
  {
    "value": "38103",
    "label": "Memphis - TN 38103",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38104",
    "label": "Memphis - TN 38104",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38105",
    "label": "Memphis - TN 38105",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38106",
    "label": "Memphis - TN 38106",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38107",
    "label": "Memphis - TN 38107",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38108",
    "label": "Memphis - TN 38108",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38109",
    "label": "Memphis - TN 38109",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38111",
    "label": "Memphis - TN 38111",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38112",
    "label": "Memphis - TN 38112",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38114",
    "label": "Memphis - TN 38114",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38115",
    "label": "Memphis - TN 38115",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38116",
    "label": "Memphis - TN 38116",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38117",
    "label": "Memphis - TN 38117",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38118",
    "label": "Memphis - TN 38118",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38119",
    "label": "Memphis - TN 38119",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38120",
    "label": "Memphis - TN 38120",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38122",
    "label": "Memphis - TN 38122",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38125",
    "label": "Memphis - TN 38125",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38126",
    "label": "Memphis - TN 38126",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38127",
    "label": "Memphis - TN 38127",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38128",
    "label": "Memphis - TN 38128",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38131",
    "label": "Memphis - TN 38131",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38132",
    "label": "Memphis - TN 38132",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38133",
    "label": "Memphis - TN 38133",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38134",
    "label": "Memphis - TN 38134",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38135",
    "label": "Memphis - TN 38135",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38137",
    "label": "Memphis - TN 38137",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38138",
    "label": "Germantown - TN 38138",
    "county": "Shelby County",
    "state": "TN",
    "city": "Germantown"
  },
  {
    "value": "38139",
    "label": "Germantown - TN 38139",
    "county": "Shelby County",
    "state": "TN",
    "city": "Germantown"
  },
  {
    "value": "38141",
    "label": "Memphis - TN 38141",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38157",
    "label": "Memphis - TN 38157",
    "county": "Shelby County",
    "state": "TN",
    "city": "Memphis"
  },
  {
    "value": "38201",
    "label": "Mc Kenzie - TN 38201",
    "county": "Carroll County",
    "state": "TN",
    "city": "Mc Kenzie"
  },
  {
    "value": "38220",
    "label": "Atwood - TN 38220",
    "county": "Carroll County",
    "state": "TN",
    "city": "Atwood"
  },
  {
    "value": "38221",
    "label": "Big Sandy - TN 38221",
    "county": "Benton County",
    "state": "TN",
    "city": "Big Sandy"
  },
  {
    "value": "38222",
    "label": "Buchanan - TN 38222",
    "county": "Henry County",
    "state": "TN",
    "city": "Buchanan"
  },
  {
    "value": "38224",
    "label": "Cottage Grove - TN 38224",
    "county": "Henry County",
    "state": "TN",
    "city": "Cottage Grove"
  },
  {
    "value": "38225",
    "label": "Dresden - TN 38225",
    "county": "Weakley County",
    "state": "TN",
    "city": "Dresden"
  },
  {
    "value": "38226",
    "label": "Dukedom - TN 38226",
    "county": "Weakley County",
    "state": "TN",
    "city": "Dukedom"
  },
  {
    "value": "38227",
    "label": "Elbridge - TN 38227",
    "county": "",
    "state": "TN",
    "city": "Elbridge"
  },
  {
    "value": "38229",
    "label": "Gleason - TN 38229",
    "county": "Weakley County",
    "state": "TN",
    "city": "Gleason"
  },
  {
    "value": "38230",
    "label": "Greenfield - TN 38230",
    "county": "Weakley County",
    "state": "TN",
    "city": "Greenfield"
  },
  {
    "value": "38231",
    "label": "Henry - TN 38231",
    "county": "Henry County",
    "state": "TN",
    "city": "Henry"
  },
  {
    "value": "38232",
    "label": "Hornbeak - TN 38232",
    "county": "Obion County",
    "state": "TN",
    "city": "Hornbeak"
  },
  {
    "value": "38233",
    "label": "Kenton - TN 38233",
    "county": "Gibson County",
    "state": "TN",
    "city": "Kenton"
  },
  {
    "value": "38236",
    "label": "Mansfield - TN 38236",
    "county": "Henry County",
    "state": "TN",
    "city": "Mansfield"
  },
  {
    "value": "38237",
    "label": "Martin - TN 38237",
    "county": "Weakley County",
    "state": "TN",
    "city": "Martin"
  },
  {
    "value": "38240",
    "label": "Obion - TN 38240",
    "county": "Obion County",
    "state": "TN",
    "city": "Obion"
  },
  {
    "value": "38241",
    "label": "Palmersville - TN 38241",
    "county": "Weakley County",
    "state": "TN",
    "city": "Palmersville"
  },
  {
    "value": "38242",
    "label": "Paris - TN 38242",
    "county": "Henry County",
    "state": "TN",
    "city": "Paris"
  },
  {
    "value": "38251",
    "label": "Puryear - TN 38251",
    "county": "Henry County",
    "state": "TN",
    "city": "Puryear"
  },
  {
    "value": "38253",
    "label": "Rives - TN 38253",
    "county": "Obion County",
    "state": "TN",
    "city": "Rives"
  },
  {
    "value": "38255",
    "label": "Sharon - TN 38255",
    "county": "Weakley County",
    "state": "TN",
    "city": "Sharon"
  },
  {
    "value": "38256",
    "label": "Springville - TN 38256",
    "county": "Henry County",
    "state": "TN",
    "city": "Springville"
  },
  {
    "value": "38257",
    "label": "South Fulton - TN 38257",
    "county": "Obion County",
    "state": "TN",
    "city": "South Fulton"
  },
  {
    "value": "38258",
    "label": "Trezevant - TN 38258",
    "county": "Carroll County",
    "state": "TN",
    "city": "Trezevant"
  },
  {
    "value": "38259",
    "label": "Trimble - TN 38259",
    "county": "Dyer County",
    "state": "TN",
    "city": "Trimble"
  },
  {
    "value": "38260",
    "label": "Troy - TN 38260",
    "county": "Obion County",
    "state": "TN",
    "city": "Troy"
  },
  {
    "value": "38261",
    "label": "Union City - TN 38261",
    "county": "Obion County",
    "state": "TN",
    "city": "Union City"
  },
  {
    "value": "38301",
    "label": "Jackson - TN 38301",
    "county": "Madison County",
    "state": "TN",
    "city": "Jackson"
  },
  {
    "value": "38305",
    "label": "Jackson - TN 38305",
    "county": "Madison County",
    "state": "TN",
    "city": "Jackson"
  },
  {
    "value": "38310",
    "label": "Adamsville - TN 38310",
    "county": "McNairy County",
    "state": "TN",
    "city": "Adamsville"
  },
  {
    "value": "38311",
    "label": "Bath Springs - TN 38311",
    "county": "Decatur County",
    "state": "TN",
    "city": "Bath Springs"
  },
  {
    "value": "38313",
    "label": "Beech Bluff - TN 38313",
    "county": "Madison County",
    "state": "TN",
    "city": "Beech Bluff"
  },
  {
    "value": "38315",
    "label": "Bethel Springs - TN 38315",
    "county": "McNairy County",
    "state": "TN",
    "city": "Bethel Springs"
  },
  {
    "value": "38316",
    "label": "Bradford - TN 38316",
    "county": "Gibson County",
    "state": "TN",
    "city": "Bradford"
  },
  {
    "value": "38317",
    "label": "Bruceton - TN 38317",
    "county": "Carroll County",
    "state": "TN",
    "city": "Bruceton"
  },
  {
    "value": "38318",
    "label": "Buena Vista - TN 38318",
    "county": "Carroll County",
    "state": "TN",
    "city": "Buena Vista"
  },
  {
    "value": "38320",
    "label": "Camden - TN 38320",
    "county": "Benton County",
    "state": "TN",
    "city": "Camden"
  },
  {
    "value": "38321",
    "label": "Cedar Grove - TN 38321",
    "county": "Carroll County",
    "state": "TN",
    "city": "Cedar Grove"
  },
  {
    "value": "38326",
    "label": "Counce - TN 38326",
    "county": "Hardin County",
    "state": "TN",
    "city": "Counce"
  },
  {
    "value": "38327",
    "label": "Crump - TN 38327",
    "county": "Hardin County",
    "state": "TN",
    "city": "Crump"
  },
  {
    "value": "38328",
    "label": "Darden - TN 38328",
    "county": "Henderson County",
    "state": "TN",
    "city": "Darden"
  },
  {
    "value": "38329",
    "label": "Decaturville - TN 38329",
    "county": "Decatur County",
    "state": "TN",
    "city": "Decaturville"
  },
  {
    "value": "38330",
    "label": "Dyer - TN 38330",
    "county": "Gibson County",
    "state": "TN",
    "city": "Dyer"
  },
  {
    "value": "38332",
    "label": "Enville - TN 38332",
    "county": "Chester County",
    "state": "TN",
    "city": "Enville"
  },
  {
    "value": "38333",
    "label": "Eva - TN 38333",
    "county": "Benton County",
    "state": "TN",
    "city": "Eva"
  },
  {
    "value": "38334",
    "label": "Finger - TN 38334",
    "county": "McNairy County",
    "state": "TN",
    "city": "Finger"
  },
  {
    "value": "38337",
    "label": "Gadsden - TN 38337",
    "county": "Crockett County",
    "state": "TN",
    "city": "Gadsden"
  },
  {
    "value": "38339",
    "label": "Guys - TN 38339",
    "county": "McNairy County",
    "state": "TN",
    "city": "Guys"
  },
  {
    "value": "38340",
    "label": "Henderson - TN 38340",
    "county": "Chester County",
    "state": "TN",
    "city": "Henderson"
  },
  {
    "value": "38341",
    "label": "Holladay - TN 38341",
    "county": "Benton County",
    "state": "TN",
    "city": "Holladay"
  },
  {
    "value": "38342",
    "label": "Hollow Rock - TN 38342",
    "county": "Carroll County",
    "state": "TN",
    "city": "Hollow Rock"
  },
  {
    "value": "38343",
    "label": "Humboldt - TN 38343",
    "county": "Gibson County",
    "state": "TN",
    "city": "Humboldt"
  },
  {
    "value": "38344",
    "label": "Huntingdon - TN 38344",
    "county": "Carroll County",
    "state": "TN",
    "city": "Huntingdon"
  },
  {
    "value": "38345",
    "label": "Huron - TN 38345",
    "county": "Henderson County",
    "state": "TN",
    "city": "Huron"
  },
  {
    "value": "38347",
    "label": "Jacks Creek - TN 38347",
    "county": "Chester County",
    "state": "TN",
    "city": "Jacks Creek"
  },
  {
    "value": "38348",
    "label": "Lavinia - TN 38348",
    "county": "Carroll County",
    "state": "TN",
    "city": "Lavinia"
  },
  {
    "value": "38351",
    "label": "Lexington - TN 38351",
    "county": "Henderson County",
    "state": "TN",
    "city": "Lexington"
  },
  {
    "value": "38352",
    "label": "Luray - TN 38352",
    "county": "Chester County",
    "state": "TN",
    "city": "Luray"
  },
  {
    "value": "38355",
    "label": "Medina - TN 38355",
    "county": "Gibson County",
    "state": "TN",
    "city": "Medina"
  },
  {
    "value": "38356",
    "label": "Medon - TN 38356",
    "county": "Madison County",
    "state": "TN",
    "city": "Medon"
  },
  {
    "value": "38357",
    "label": "Michie - TN 38357",
    "county": "McNairy County",
    "state": "TN",
    "city": "Michie"
  },
  {
    "value": "38358",
    "label": "Milan - TN 38358",
    "county": "Gibson County",
    "state": "TN",
    "city": "Milan"
  },
  {
    "value": "38359",
    "label": "Milledgeville - TN 38359",
    "county": "McNairy County",
    "state": "TN",
    "city": "Milledgeville"
  },
  {
    "value": "38361",
    "label": "Morris Chapel - TN 38361",
    "county": "Hardin County",
    "state": "TN",
    "city": "Morris Chapel"
  },
  {
    "value": "38362",
    "label": "Oakfield - TN 38362",
    "county": "Madison County",
    "state": "TN",
    "city": "Oakfield"
  },
  {
    "value": "38363",
    "label": "Parsons - TN 38363",
    "county": "Decatur County",
    "state": "TN",
    "city": "Parsons"
  },
  {
    "value": "38366",
    "label": "Pinson - TN 38366",
    "county": "Madison County",
    "state": "TN",
    "city": "Pinson"
  },
  {
    "value": "38367",
    "label": "Ramer - TN 38367",
    "county": "McNairy County",
    "state": "TN",
    "city": "Ramer"
  },
  {
    "value": "38368",
    "label": "Reagan - TN 38368",
    "county": "Henderson County",
    "state": "TN",
    "city": "Reagan"
  },
  {
    "value": "38369",
    "label": "Rutherford - TN 38369",
    "county": "Gibson County",
    "state": "TN",
    "city": "Rutherford"
  },
  {
    "value": "38370",
    "label": "Saltillo - TN 38370",
    "county": "Hardin County",
    "state": "TN",
    "city": "Saltillo"
  },
  {
    "value": "38371",
    "label": "Sardis - TN 38371",
    "county": "Henderson County",
    "state": "TN",
    "city": "Sardis"
  },
  {
    "value": "38372",
    "label": "Savannah - TN 38372",
    "county": "Hardin County",
    "state": "TN",
    "city": "Savannah"
  },
  {
    "value": "38374",
    "label": "Scotts Hill - TN 38374",
    "county": "Henderson County",
    "state": "TN",
    "city": "Scotts Hill"
  },
  {
    "value": "38375",
    "label": "Selmer - TN 38375",
    "county": "McNairy County",
    "state": "TN",
    "city": "Selmer"
  },
  {
    "value": "38376",
    "label": "Shiloh - TN 38376",
    "county": "Hardin County",
    "state": "TN",
    "city": "Shiloh"
  },
  {
    "value": "38379",
    "label": "Stantonville - TN 38379",
    "county": "McNairy County",
    "state": "TN",
    "city": "Stantonville"
  },
  {
    "value": "38380",
    "label": "Sugar Tree - TN 38380",
    "county": "Decatur County",
    "state": "TN",
    "city": "Sugar Tree"
  },
  {
    "value": "38381",
    "label": "Toone - TN 38381",
    "county": "Hardeman County",
    "state": "TN",
    "city": "Toone"
  },
  {
    "value": "38382",
    "label": "Trenton - TN 38382",
    "county": "Gibson County",
    "state": "TN",
    "city": "Trenton"
  },
  {
    "value": "38387",
    "label": "Westport - TN 38387",
    "county": "Carroll County",
    "state": "TN",
    "city": "Westport"
  },
  {
    "value": "38388",
    "label": "Wildersville - TN 38388",
    "county": "Henderson County",
    "state": "TN",
    "city": "Wildersville"
  },
  {
    "value": "38390",
    "label": "Yuma - TN 38390",
    "county": "Carroll County",
    "state": "TN",
    "city": "Yuma"
  },
  {
    "value": "38391",
    "label": "Denmark - TN 38391",
    "county": "Madison County",
    "state": "TN",
    "city": "Denmark"
  },
  {
    "value": "38392",
    "label": "Mercer - TN 38392",
    "county": "Madison County",
    "state": "TN",
    "city": "Mercer"
  },
  {
    "value": "38401",
    "label": "Columbia - TN 38401",
    "county": "Maury County",
    "state": "TN",
    "city": "Columbia"
  },
  {
    "value": "38425",
    "label": "Clifton - TN 38425",
    "county": "Wayne County",
    "state": "TN",
    "city": "Clifton"
  },
  {
    "value": "38449",
    "label": "Ardmore - TN 38449",
    "county": "Giles County",
    "state": "TN",
    "city": "Ardmore"
  },
  {
    "value": "38450",
    "label": "Collinwood - TN 38450",
    "county": "Wayne County",
    "state": "TN",
    "city": "Collinwood"
  },
  {
    "value": "38451",
    "label": "Culleoka - TN 38451",
    "county": "Maury County",
    "state": "TN",
    "city": "Culleoka"
  },
  {
    "value": "38452",
    "label": "Cypress Inn - TN 38452",
    "county": "Wayne County",
    "state": "TN",
    "city": "Cypress Inn"
  },
  {
    "value": "38453",
    "label": "Dellrose - TN 38453",
    "county": "Lincoln County",
    "state": "TN",
    "city": "Dellrose"
  },
  {
    "value": "38454",
    "label": "Duck River - TN 38454",
    "county": "Hickman County",
    "state": "TN",
    "city": "Duck River"
  },
  {
    "value": "38456",
    "label": "Ethridge - TN 38456",
    "county": "Lawrence County",
    "state": "TN",
    "city": "Ethridge"
  },
  {
    "value": "38457",
    "label": "Five Points - TN 38457",
    "county": "Lawrence County",
    "state": "TN",
    "city": "Five Points"
  },
  {
    "value": "38459",
    "label": "Frankewing - TN 38459",
    "county": "Lincoln County",
    "state": "TN",
    "city": "Frankewing"
  },
  {
    "value": "38460",
    "label": "Goodspring - TN 38460",
    "county": "Giles County",
    "state": "TN",
    "city": "Goodspring"
  },
  {
    "value": "38461",
    "label": "Hampshire - TN 38461",
    "county": "Maury County",
    "state": "TN",
    "city": "Hampshire"
  },
  {
    "value": "38462",
    "label": "Hohenwald - TN 38462",
    "county": "Lewis County",
    "state": "TN",
    "city": "Hohenwald"
  },
  {
    "value": "38463",
    "label": "Iron City - TN 38463",
    "county": "Wayne County",
    "state": "TN",
    "city": "Iron City"
  },
  {
    "value": "38464",
    "label": "Lawrenceburg - TN 38464",
    "county": "Lawrence County",
    "state": "TN",
    "city": "Lawrenceburg"
  },
  {
    "value": "38468",
    "label": "Leoma - TN 38468",
    "county": "Lawrence County",
    "state": "TN",
    "city": "Leoma"
  },
  {
    "value": "38469",
    "label": "Loretto - TN 38469",
    "county": "Lawrence County",
    "state": "TN",
    "city": "Loretto"
  },
  {
    "value": "38471",
    "label": "Lutts - TN 38471",
    "county": "Wayne County",
    "state": "TN",
    "city": "Lutts"
  },
  {
    "value": "38472",
    "label": "Lynnville - TN 38472",
    "county": "Giles County",
    "state": "TN",
    "city": "Lynnville"
  },
  {
    "value": "38473",
    "label": "Minor Hill - TN 38473",
    "county": "Giles County",
    "state": "TN",
    "city": "Minor Hill"
  },
  {
    "value": "38474",
    "label": "Mount Pleasant - TN 38474",
    "county": "Maury County",
    "state": "TN",
    "city": "Mount Pleasant"
  },
  {
    "value": "38475",
    "label": "Olivehill - TN 38475",
    "county": "Hardin County",
    "state": "TN",
    "city": "Olivehill"
  },
  {
    "value": "38476",
    "label": "Primm Springs - TN 38476",
    "county": "Williamson County",
    "state": "TN",
    "city": "Primm Springs"
  },
  {
    "value": "38477",
    "label": "Prospect - TN 38477",
    "county": "Giles County",
    "state": "TN",
    "city": "Prospect"
  },
  {
    "value": "38478",
    "label": "Pulaski - TN 38478",
    "county": "Giles County",
    "state": "TN",
    "city": "Pulaski"
  },
  {
    "value": "38481",
    "label": "Saint Joseph - TN 38481",
    "county": "Lawrence County",
    "state": "TN",
    "city": "Saint Joseph"
  },
  {
    "value": "38482",
    "label": "Santa Fe - TN 38482",
    "county": "Maury County",
    "state": "TN",
    "city": "Santa Fe"
  },
  {
    "value": "38483",
    "label": "Summertown - TN 38483",
    "county": "Lawrence County",
    "state": "TN",
    "city": "Summertown"
  },
  {
    "value": "38485",
    "label": "Waynesboro - TN 38485",
    "county": "Wayne County",
    "state": "TN",
    "city": "Waynesboro"
  },
  {
    "value": "38486",
    "label": "Westpoint - TN 38486",
    "county": "Lawrence County",
    "state": "TN",
    "city": "Westpoint"
  },
  {
    "value": "38487",
    "label": "Williamsport - TN 38487",
    "county": "Maury County",
    "state": "TN",
    "city": "Williamsport"
  },
  {
    "value": "38488",
    "label": "Taft - TN 38488",
    "county": "Lincoln County",
    "state": "TN",
    "city": "Taft"
  },
  {
    "value": "38501",
    "label": "Cookeville - TN 38501",
    "county": "Putnam County",
    "state": "TN",
    "city": "Cookeville"
  },
  {
    "value": "38504",
    "label": "Allardt - TN 38504",
    "county": "Fentress County",
    "state": "TN",
    "city": "Allardt"
  },
  {
    "value": "38506",
    "label": "Cookeville - TN 38506",
    "county": "Putnam County",
    "state": "TN",
    "city": "Cookeville"
  },
  {
    "value": "38541",
    "label": "Allons - TN 38541",
    "county": "Overton County",
    "state": "TN",
    "city": "Allons"
  },
  {
    "value": "38542",
    "label": "Allred - TN 38542",
    "county": "Overton County",
    "state": "TN",
    "city": "Allred"
  },
  {
    "value": "38543",
    "label": "Alpine - TN 38543",
    "county": "Overton County",
    "state": "TN",
    "city": "Alpine"
  },
  {
    "value": "38544",
    "label": "Baxter - TN 38544",
    "county": "Putnam County",
    "state": "TN",
    "city": "Baxter"
  },
  {
    "value": "38545",
    "label": "Bloomington Springs - TN 38545",
    "county": "Jackson County",
    "state": "TN",
    "city": "Bloomington Springs"
  },
  {
    "value": "38547",
    "label": "Brush Creek - TN 38547",
    "county": "Smith County",
    "state": "TN",
    "city": "Brush Creek"
  },
  {
    "value": "38548",
    "label": "Buffalo Valley - TN 38548",
    "county": "Putnam County",
    "state": "TN",
    "city": "Buffalo Valley"
  },
  {
    "value": "38549",
    "label": "Byrdstown - TN 38549",
    "county": "Pickett County",
    "state": "TN",
    "city": "Byrdstown"
  },
  {
    "value": "38551",
    "label": "Celina - TN 38551",
    "county": "Clay County",
    "state": "TN",
    "city": "Celina"
  },
  {
    "value": "38552",
    "label": "Chestnut Mound - TN 38552",
    "county": "Smith County",
    "state": "TN",
    "city": "Chestnut Mound"
  },
  {
    "value": "38553",
    "label": "Clarkrange - TN 38553",
    "county": "Fentress County",
    "state": "TN",
    "city": "Clarkrange"
  },
  {
    "value": "38554",
    "label": "Crawford - TN 38554",
    "county": "Overton County",
    "state": "TN",
    "city": "Crawford"
  },
  {
    "value": "38555",
    "label": "Crossville - TN 38555",
    "county": "Cumberland County",
    "state": "TN",
    "city": "Crossville"
  },
  {
    "value": "38556",
    "label": "Jamestown - TN 38556",
    "county": "Fentress County",
    "state": "TN",
    "city": "Jamestown"
  },
  {
    "value": "38558",
    "label": "Crossville - TN 38558",
    "county": "Cumberland County",
    "state": "TN",
    "city": "Crossville"
  },
  {
    "value": "38559",
    "label": "Doyle - TN 38559",
    "county": "White County",
    "state": "TN",
    "city": "Doyle"
  },
  {
    "value": "38560",
    "label": "Elmwood - TN 38560",
    "county": "Smith County",
    "state": "TN",
    "city": "Elmwood"
  },
  {
    "value": "38562",
    "label": "Gainesboro - TN 38562",
    "county": "Jackson County",
    "state": "TN",
    "city": "Gainesboro"
  },
  {
    "value": "38563",
    "label": "Gordonsville - TN 38563",
    "county": "Smith County",
    "state": "TN",
    "city": "Gordonsville"
  },
  {
    "value": "38564",
    "label": "Granville - TN 38564",
    "county": "Jackson County",
    "state": "TN",
    "city": "Granville"
  },
  {
    "value": "38565",
    "label": "Grimsley - TN 38565",
    "county": "Fentress County",
    "state": "TN",
    "city": "Grimsley"
  },
  {
    "value": "38567",
    "label": "Hickman - TN 38567",
    "county": "Smith County",
    "state": "TN",
    "city": "Hickman"
  },
  {
    "value": "38568",
    "label": "Hilham - TN 38568",
    "county": "Overton County",
    "state": "TN",
    "city": "Hilham"
  },
  {
    "value": "38569",
    "label": "Lancaster - TN 38569",
    "county": "Smith County",
    "state": "TN",
    "city": "Lancaster"
  },
  {
    "value": "38570",
    "label": "Livingston - TN 38570",
    "county": "Overton County",
    "state": "TN",
    "city": "Livingston"
  },
  {
    "value": "38571",
    "label": "Crossville - TN 38571",
    "county": "Cumberland County",
    "state": "TN",
    "city": "Crossville"
  },
  {
    "value": "38572",
    "label": "Crossville - TN 38572",
    "county": "Cumberland County",
    "state": "TN",
    "city": "Crossville"
  },
  {
    "value": "38573",
    "label": "Monroe - TN 38573",
    "county": "Overton County",
    "state": "TN",
    "city": "Monroe"
  },
  {
    "value": "38574",
    "label": "Monterey - TN 38574",
    "county": "Putnam County",
    "state": "TN",
    "city": "Monterey"
  },
  {
    "value": "38575",
    "label": "Moss - TN 38575",
    "county": "Clay County",
    "state": "TN",
    "city": "Moss"
  },
  {
    "value": "38577",
    "label": "Pall Mall - TN 38577",
    "county": "Pickett County",
    "state": "TN",
    "city": "Pall Mall"
  },
  {
    "value": "38579",
    "label": "Quebeck - TN 38579",
    "county": "White County",
    "state": "TN",
    "city": "Quebeck"
  },
  {
    "value": "38580",
    "label": "Rickman - TN 38580",
    "county": "Overton County",
    "state": "TN",
    "city": "Rickman"
  },
  {
    "value": "38581",
    "label": "Rock Island - TN 38581",
    "county": "Warren County",
    "state": "TN",
    "city": "Rock Island"
  },
  {
    "value": "38582",
    "label": "Silver Point - TN 38582",
    "county": "Putnam County",
    "state": "TN",
    "city": "Silver Point"
  },
  {
    "value": "38583",
    "label": "Sparta - TN 38583",
    "county": "White County",
    "state": "TN",
    "city": "Sparta"
  },
  {
    "value": "38585",
    "label": "Spencer - TN 38585",
    "county": "Van Buren County",
    "state": "TN",
    "city": "Spencer"
  },
  {
    "value": "38587",
    "label": "Walling - TN 38587",
    "county": "White County",
    "state": "TN",
    "city": "Walling"
  },
  {
    "value": "38588",
    "label": "Whitleyville - TN 38588",
    "county": "Jackson County",
    "state": "TN",
    "city": "Whitleyville"
  },
  {
    "value": "38589",
    "label": "Wilder - TN 38589",
    "county": "Overton County",
    "state": "TN",
    "city": "Wilder"
  },
  {
    "value": "75001",
    "label": "Addison - TX 75001",
    "county": "Dallas County",
    "state": "TX",
    "city": "Addison"
  },
  {
    "value": "75002",
    "label": "Allen - TX 75002",
    "county": "Collin County",
    "state": "TX",
    "city": "Allen"
  },
  {
    "value": "75006",
    "label": "Carrollton - TX 75006",
    "county": "Dallas County",
    "state": "TX",
    "city": "Carrollton"
  },
  {
    "value": "75007",
    "label": "Carrollton - TX 75007",
    "county": "Denton County",
    "state": "TX",
    "city": "Carrollton"
  },
  {
    "value": "75009",
    "label": "Celina - TX 75009",
    "county": "Collin County",
    "state": "TX",
    "city": "Celina"
  },
  {
    "value": "75010",
    "label": "Carrollton - TX 75010",
    "county": "Denton County",
    "state": "TX",
    "city": "Carrollton"
  },
  {
    "value": "75013",
    "label": "Allen - TX 75013",
    "county": "Collin County",
    "state": "TX",
    "city": "Allen"
  },
  {
    "value": "75019",
    "label": "Coppell - TX 75019",
    "county": "Dallas County",
    "state": "TX",
    "city": "Coppell"
  },
  {
    "value": "75020",
    "label": "Denison - TX 75020",
    "county": "Grayson County",
    "state": "TX",
    "city": "Denison"
  },
  {
    "value": "75021",
    "label": "Denison - TX 75021",
    "county": "Grayson County",
    "state": "TX",
    "city": "Denison"
  },
  {
    "value": "75022",
    "label": "Flower Mound - TX 75022",
    "county": "Denton County",
    "state": "TX",
    "city": "Flower Mound"
  },
  {
    "value": "75023",
    "label": "Plano - TX 75023",
    "county": "Collin County",
    "state": "TX",
    "city": "Plano"
  },
  {
    "value": "75024",
    "label": "Plano - TX 75024",
    "county": "Collin County",
    "state": "TX",
    "city": "Plano"
  },
  {
    "value": "75025",
    "label": "Plano - TX 75025",
    "county": "Collin County",
    "state": "TX",
    "city": "Plano"
  },
  {
    "value": "75028",
    "label": "Flower Mound - TX 75028",
    "county": "Denton County",
    "state": "TX",
    "city": "Flower Mound"
  },
  {
    "value": "75032",
    "label": "Rockwall - TX 75032",
    "county": "Rockwall County",
    "state": "TX",
    "city": "Rockwall"
  },
  {
    "value": "75033",
    "label": "Frisco - TX 75033",
    "county": "",
    "state": "TX",
    "city": "Frisco"
  },
  {
    "value": "75034",
    "label": "Frisco - TX 75034",
    "county": "Denton County",
    "state": "TX",
    "city": "Frisco"
  },
  {
    "value": "75035",
    "label": "Frisco - TX 75035",
    "county": "Collin County",
    "state": "TX",
    "city": "Frisco"
  },
  {
    "value": "75036",
    "label": "Frisco - TX 75036",
    "county": "Denton County",
    "state": "TX",
    "city": "Frisco"
  },
  {
    "value": "75038",
    "label": "Irving - TX 75038",
    "county": "Dallas County",
    "state": "TX",
    "city": "Irving"
  },
  {
    "value": "75039",
    "label": "Irving - TX 75039",
    "county": "Dallas County",
    "state": "TX",
    "city": "Irving"
  },
  {
    "value": "75040",
    "label": "Garland - TX 75040",
    "county": "Dallas County",
    "state": "TX",
    "city": "Garland"
  },
  {
    "value": "75041",
    "label": "Garland - TX 75041",
    "county": "Dallas County",
    "state": "TX",
    "city": "Garland"
  },
  {
    "value": "75042",
    "label": "Garland - TX 75042",
    "county": "Dallas County",
    "state": "TX",
    "city": "Garland"
  },
  {
    "value": "75043",
    "label": "Garland - TX 75043",
    "county": "Dallas County",
    "state": "TX",
    "city": "Garland"
  },
  {
    "value": "75044",
    "label": "Garland - TX 75044",
    "county": "Dallas County",
    "state": "TX",
    "city": "Garland"
  },
  {
    "value": "75048",
    "label": "Sachse - TX 75048",
    "county": "Dallas County",
    "state": "TX",
    "city": "Sachse"
  },
  {
    "value": "75050",
    "label": "Grand Prairie - TX 75050",
    "county": "Dallas County",
    "state": "TX",
    "city": "Grand Prairie"
  },
  {
    "value": "75051",
    "label": "Grand Prairie - TX 75051",
    "county": "Dallas County",
    "state": "TX",
    "city": "Grand Prairie"
  },
  {
    "value": "75052",
    "label": "Grand Prairie - TX 75052",
    "county": "Dallas County",
    "state": "TX",
    "city": "Grand Prairie"
  },
  {
    "value": "75054",
    "label": "Grand Prairie - TX 75054",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Grand Prairie"
  },
  {
    "value": "75056",
    "label": "The Colony - TX 75056",
    "county": "Denton County",
    "state": "TX",
    "city": "The Colony"
  },
  {
    "value": "75057",
    "label": "Lewisville - TX 75057",
    "county": "Denton County",
    "state": "TX",
    "city": "Lewisville"
  },
  {
    "value": "75058",
    "label": "Gunter - TX 75058",
    "county": "Grayson County",
    "state": "TX",
    "city": "Gunter"
  },
  {
    "value": "75060",
    "label": "Irving - TX 75060",
    "county": "Dallas County",
    "state": "TX",
    "city": "Irving"
  },
  {
    "value": "75061",
    "label": "Irving - TX 75061",
    "county": "Dallas County",
    "state": "TX",
    "city": "Irving"
  },
  {
    "value": "75062",
    "label": "Irving - TX 75062",
    "county": "Dallas County",
    "state": "TX",
    "city": "Irving"
  },
  {
    "value": "75063",
    "label": "Irving - TX 75063",
    "county": "Dallas County",
    "state": "TX",
    "city": "Irving"
  },
  {
    "value": "75065",
    "label": "Lake Dallas - TX 75065",
    "county": "Denton County",
    "state": "TX",
    "city": "Lake Dallas"
  },
  {
    "value": "75067",
    "label": "Lewisville - TX 75067",
    "county": "Denton County",
    "state": "TX",
    "city": "Lewisville"
  },
  {
    "value": "75068",
    "label": "Little Elm - TX 75068",
    "county": "Denton County",
    "state": "TX",
    "city": "Little Elm"
  },
  {
    "value": "75069",
    "label": "Mckinney - TX 75069",
    "county": "Collin County",
    "state": "TX",
    "city": "Mckinney"
  },
  {
    "value": "75070",
    "label": "Mckinney - TX 75070",
    "county": "Collin County",
    "state": "TX",
    "city": "Mckinney"
  },
  {
    "value": "75071",
    "label": "Mckinney - TX 75071",
    "county": "Collin County",
    "state": "TX",
    "city": "Mckinney"
  },
  {
    "value": "75072",
    "label": "Mckinney - TX 75072",
    "county": "Collin County",
    "state": "TX",
    "city": "Mckinney"
  },
  {
    "value": "75074",
    "label": "Plano - TX 75074",
    "county": "Collin County",
    "state": "TX",
    "city": "Plano"
  },
  {
    "value": "75075",
    "label": "Plano - TX 75075",
    "county": "Collin County",
    "state": "TX",
    "city": "Plano"
  },
  {
    "value": "75076",
    "label": "Pottsboro - TX 75076",
    "county": "Grayson County",
    "state": "TX",
    "city": "Pottsboro"
  },
  {
    "value": "75077",
    "label": "Lewisville - TX 75077",
    "county": "Denton County",
    "state": "TX",
    "city": "Lewisville"
  },
  {
    "value": "75078",
    "label": "Prosper - TX 75078",
    "county": "Collin County",
    "state": "TX",
    "city": "Prosper"
  },
  {
    "value": "75080",
    "label": "Richardson - TX 75080",
    "county": "Dallas County",
    "state": "TX",
    "city": "Richardson"
  },
  {
    "value": "75081",
    "label": "Richardson - TX 75081",
    "county": "Dallas County",
    "state": "TX",
    "city": "Richardson"
  },
  {
    "value": "75082",
    "label": "Richardson - TX 75082",
    "county": "Collin County",
    "state": "TX",
    "city": "Richardson"
  },
  {
    "value": "75087",
    "label": "Rockwall - TX 75087",
    "county": "Rockwall County",
    "state": "TX",
    "city": "Rockwall"
  },
  {
    "value": "75088",
    "label": "Rowlett - TX 75088",
    "county": "Dallas County",
    "state": "TX",
    "city": "Rowlett"
  },
  {
    "value": "75089",
    "label": "Rowlett - TX 75089",
    "county": "Dallas County",
    "state": "TX",
    "city": "Rowlett"
  },
  {
    "value": "75090",
    "label": "Sherman - TX 75090",
    "county": "Grayson County",
    "state": "TX",
    "city": "Sherman"
  },
  {
    "value": "75092",
    "label": "Sherman - TX 75092",
    "county": "Grayson County",
    "state": "TX",
    "city": "Sherman"
  },
  {
    "value": "75093",
    "label": "Plano - TX 75093",
    "county": "Collin County",
    "state": "TX",
    "city": "Plano"
  },
  {
    "value": "75094",
    "label": "Plano - TX 75094",
    "county": "Collin County",
    "state": "TX",
    "city": "Plano"
  },
  {
    "value": "75098",
    "label": "Wylie - TX 75098",
    "county": "Collin County",
    "state": "TX",
    "city": "Wylie"
  },
  {
    "value": "75102",
    "label": "Barry - TX 75102",
    "county": "Navarro County",
    "state": "TX",
    "city": "Barry"
  },
  {
    "value": "75103",
    "label": "Canton - TX 75103",
    "county": "Van Zandt County",
    "state": "TX",
    "city": "Canton"
  },
  {
    "value": "75104",
    "label": "Cedar Hill - TX 75104",
    "county": "Dallas County",
    "state": "TX",
    "city": "Cedar Hill"
  },
  {
    "value": "75105",
    "label": "Chatfield - TX 75105",
    "county": "Navarro County",
    "state": "TX",
    "city": "Chatfield"
  },
  {
    "value": "75109",
    "label": "Corsicana - TX 75109",
    "county": "Navarro County",
    "state": "TX",
    "city": "Corsicana"
  },
  {
    "value": "75110",
    "label": "Corsicana - TX 75110",
    "county": "Navarro County",
    "state": "TX",
    "city": "Corsicana"
  },
  {
    "value": "75114",
    "label": "Crandall - TX 75114",
    "county": "Kaufman County",
    "state": "TX",
    "city": "Crandall"
  },
  {
    "value": "75115",
    "label": "Desoto - TX 75115",
    "county": "Dallas County",
    "state": "TX",
    "city": "Desoto"
  },
  {
    "value": "75116",
    "label": "Duncanville - TX 75116",
    "county": "Dallas County",
    "state": "TX",
    "city": "Duncanville"
  },
  {
    "value": "75117",
    "label": "Edgewood - TX 75117",
    "county": "Van Zandt County",
    "state": "TX",
    "city": "Edgewood"
  },
  {
    "value": "75119",
    "label": "Ennis - TX 75119",
    "county": "Ellis County",
    "state": "TX",
    "city": "Ennis"
  },
  {
    "value": "75124",
    "label": "Eustace - TX 75124",
    "county": "Henderson County",
    "state": "TX",
    "city": "Eustace"
  },
  {
    "value": "75125",
    "label": "Ferris - TX 75125",
    "county": "Ellis County",
    "state": "TX",
    "city": "Ferris"
  },
  {
    "value": "75126",
    "label": "Forney - TX 75126",
    "county": "Kaufman County",
    "state": "TX",
    "city": "Forney"
  },
  {
    "value": "75127",
    "label": "Fruitvale - TX 75127",
    "county": "Van Zandt County",
    "state": "TX",
    "city": "Fruitvale"
  },
  {
    "value": "75134",
    "label": "Lancaster - TX 75134",
    "county": "Dallas County",
    "state": "TX",
    "city": "Lancaster"
  },
  {
    "value": "75135",
    "label": "Caddo Mills - TX 75135",
    "county": "Hunt County",
    "state": "TX",
    "city": "Caddo Mills"
  },
  {
    "value": "75137",
    "label": "Duncanville - TX 75137",
    "county": "Dallas County",
    "state": "TX",
    "city": "Duncanville"
  },
  {
    "value": "75140",
    "label": "Grand Saline - TX 75140",
    "county": "Van Zandt County",
    "state": "TX",
    "city": "Grand Saline"
  },
  {
    "value": "75141",
    "label": "Hutchins - TX 75141",
    "county": "Dallas County",
    "state": "TX",
    "city": "Hutchins"
  },
  {
    "value": "75142",
    "label": "Kaufman - TX 75142",
    "county": "Kaufman County",
    "state": "TX",
    "city": "Kaufman"
  },
  {
    "value": "75143",
    "label": "Kemp - TX 75143",
    "county": "Henderson County",
    "state": "TX",
    "city": "Kemp"
  },
  {
    "value": "75144",
    "label": "Kerens - TX 75144",
    "county": "Navarro County",
    "state": "TX",
    "city": "Kerens"
  },
  {
    "value": "75146",
    "label": "Lancaster - TX 75146",
    "county": "Dallas County",
    "state": "TX",
    "city": "Lancaster"
  },
  {
    "value": "75147",
    "label": "Mabank - TX 75147",
    "county": "Kaufman County",
    "state": "TX",
    "city": "Mabank"
  },
  {
    "value": "75148",
    "label": "Malakoff - TX 75148",
    "county": "Henderson County",
    "state": "TX",
    "city": "Malakoff"
  },
  {
    "value": "75149",
    "label": "Mesquite - TX 75149",
    "county": "Dallas County",
    "state": "TX",
    "city": "Mesquite"
  },
  {
    "value": "75150",
    "label": "Mesquite - TX 75150",
    "county": "Dallas County",
    "state": "TX",
    "city": "Mesquite"
  },
  {
    "value": "75152",
    "label": "Palmer - TX 75152",
    "county": "Ellis County",
    "state": "TX",
    "city": "Palmer"
  },
  {
    "value": "75153",
    "label": "Powell - TX 75153",
    "county": "Navarro County",
    "state": "TX",
    "city": "Powell"
  },
  {
    "value": "75154",
    "label": "Red Oak - TX 75154",
    "county": "Ellis County",
    "state": "TX",
    "city": "Red Oak"
  },
  {
    "value": "75155",
    "label": "Rice - TX 75155",
    "county": "Navarro County",
    "state": "TX",
    "city": "Rice"
  },
  {
    "value": "75156",
    "label": "Mabank - TX 75156",
    "county": "Henderson County",
    "state": "TX",
    "city": "Mabank"
  },
  {
    "value": "75158",
    "label": "Scurry - TX 75158",
    "county": "Kaufman County",
    "state": "TX",
    "city": "Scurry"
  },
  {
    "value": "75159",
    "label": "Seagoville - TX 75159",
    "county": "Dallas County",
    "state": "TX",
    "city": "Seagoville"
  },
  {
    "value": "75160",
    "label": "Terrell - TX 75160",
    "county": "Kaufman County",
    "state": "TX",
    "city": "Terrell"
  },
  {
    "value": "75161",
    "label": "Terrell - TX 75161",
    "county": "Kaufman County",
    "state": "TX",
    "city": "Terrell"
  },
  {
    "value": "75163",
    "label": "Trinidad - TX 75163",
    "county": "Henderson County",
    "state": "TX",
    "city": "Trinidad"
  },
  {
    "value": "75165",
    "label": "Waxahachie - TX 75165",
    "county": "Ellis County",
    "state": "TX",
    "city": "Waxahachie"
  },
  {
    "value": "75166",
    "label": "Lavon - TX 75166",
    "county": "Collin County",
    "state": "TX",
    "city": "Lavon"
  },
  {
    "value": "75167",
    "label": "Waxahachie - TX 75167",
    "county": "Ellis County",
    "state": "TX",
    "city": "Waxahachie"
  },
  {
    "value": "75169",
    "label": "Wills Point - TX 75169",
    "county": "Van Zandt County",
    "state": "TX",
    "city": "Wills Point"
  },
  {
    "value": "75172",
    "label": "Wilmer - TX 75172",
    "county": "Dallas County",
    "state": "TX",
    "city": "Wilmer"
  },
  {
    "value": "75173",
    "label": "Nevada - TX 75173",
    "county": "Collin County",
    "state": "TX",
    "city": "Nevada"
  },
  {
    "value": "75180",
    "label": "Balch Springs - TX 75180",
    "county": "Dallas County",
    "state": "TX",
    "city": "Balch Springs"
  },
  {
    "value": "75181",
    "label": "Mesquite - TX 75181",
    "county": "Dallas County",
    "state": "TX",
    "city": "Mesquite"
  },
  {
    "value": "75182",
    "label": "Sunnyvale - TX 75182",
    "county": "Dallas County",
    "state": "TX",
    "city": "Sunnyvale"
  },
  {
    "value": "75189",
    "label": "Royse City - TX 75189",
    "county": "Rockwall County",
    "state": "TX",
    "city": "Royse City"
  },
  {
    "value": "75201",
    "label": "Dallas - TX 75201",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75202",
    "label": "Dallas - TX 75202",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75203",
    "label": "Dallas - TX 75203",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75204",
    "label": "Dallas - TX 75204",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75205",
    "label": "Dallas - TX 75205",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75206",
    "label": "Dallas - TX 75206",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75207",
    "label": "Dallas - TX 75207",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75208",
    "label": "Dallas - TX 75208",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75209",
    "label": "Dallas - TX 75209",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75210",
    "label": "Dallas - TX 75210",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75211",
    "label": "Dallas - TX 75211",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75212",
    "label": "Dallas - TX 75212",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75214",
    "label": "Dallas - TX 75214",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75215",
    "label": "Dallas - TX 75215",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75216",
    "label": "Dallas - TX 75216",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75217",
    "label": "Dallas - TX 75217",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75218",
    "label": "Dallas - TX 75218",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75219",
    "label": "Dallas - TX 75219",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75220",
    "label": "Dallas - TX 75220",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75223",
    "label": "Dallas - TX 75223",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75224",
    "label": "Dallas - TX 75224",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75225",
    "label": "Dallas - TX 75225",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75226",
    "label": "Dallas - TX 75226",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75227",
    "label": "Dallas - TX 75227",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75228",
    "label": "Dallas - TX 75228",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75229",
    "label": "Dallas - TX 75229",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75230",
    "label": "Dallas - TX 75230",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75231",
    "label": "Dallas - TX 75231",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75232",
    "label": "Dallas - TX 75232",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75233",
    "label": "Dallas - TX 75233",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75234",
    "label": "Dallas - TX 75234",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75235",
    "label": "Dallas - TX 75235",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75236",
    "label": "Dallas - TX 75236",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75237",
    "label": "Dallas - TX 75237",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75238",
    "label": "Dallas - TX 75238",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75240",
    "label": "Dallas - TX 75240",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75241",
    "label": "Dallas - TX 75241",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75242",
    "label": "Dallas - TX 75242",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75243",
    "label": "Dallas - TX 75243",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75244",
    "label": "Dallas - TX 75244",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75245",
    "label": "Dallas - TX 75245",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75246",
    "label": "Dallas - TX 75246",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75247",
    "label": "Dallas - TX 75247",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75248",
    "label": "Dallas - TX 75248",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75249",
    "label": "Dallas - TX 75249",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75251",
    "label": "Dallas - TX 75251",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75252",
    "label": "Dallas - TX 75252",
    "county": "Collin County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75253",
    "label": "Dallas - TX 75253",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75254",
    "label": "Dallas - TX 75254",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75258",
    "label": "Dallas - TX 75258",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75260",
    "label": "Dallas - TX 75260",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75270",
    "label": "Dallas - TX 75270",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75287",
    "label": "Dallas - TX 75287",
    "county": "Denton County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75398",
    "label": "Dallas - TX 75398",
    "county": "Dallas County",
    "state": "TX",
    "city": "Dallas"
  },
  {
    "value": "75401",
    "label": "Greenville - TX 75401",
    "county": "Hunt County",
    "state": "TX",
    "city": "Greenville"
  },
  {
    "value": "75402",
    "label": "Greenville - TX 75402",
    "county": "Hunt County",
    "state": "TX",
    "city": "Greenville"
  },
  {
    "value": "75407",
    "label": "Princeton - TX 75407",
    "county": "Collin County",
    "state": "TX",
    "city": "Princeton"
  },
  {
    "value": "75409",
    "label": "Anna - TX 75409",
    "county": "Collin County",
    "state": "TX",
    "city": "Anna"
  },
  {
    "value": "75410",
    "label": "Alba - TX 75410",
    "county": "Wood County",
    "state": "TX",
    "city": "Alba"
  },
  {
    "value": "75411",
    "label": "Arthur City - TX 75411",
    "county": "Lamar County",
    "state": "TX",
    "city": "Arthur City"
  },
  {
    "value": "75412",
    "label": "Bagwell - TX 75412",
    "county": "Red River County",
    "state": "TX",
    "city": "Bagwell"
  },
  {
    "value": "75414",
    "label": "Bells - TX 75414",
    "county": "Grayson County",
    "state": "TX",
    "city": "Bells"
  },
  {
    "value": "75415",
    "label": "Ben Franklin - TX 75415",
    "county": "Delta County",
    "state": "TX",
    "city": "Ben Franklin"
  },
  {
    "value": "75416",
    "label": "Blossom - TX 75416",
    "county": "Lamar County",
    "state": "TX",
    "city": "Blossom"
  },
  {
    "value": "75417",
    "label": "Bogata - TX 75417",
    "county": "Red River County",
    "state": "TX",
    "city": "Bogata"
  },
  {
    "value": "75418",
    "label": "Bonham - TX 75418",
    "county": "Fannin County",
    "state": "TX",
    "city": "Bonham"
  },
  {
    "value": "75420",
    "label": "Brashear - TX 75420",
    "county": "Hopkins County",
    "state": "TX",
    "city": "Brashear"
  },
  {
    "value": "75421",
    "label": "Brookston - TX 75421",
    "county": "Lamar County",
    "state": "TX",
    "city": "Brookston"
  },
  {
    "value": "75422",
    "label": "Campbell - TX 75422",
    "county": "Hunt County",
    "state": "TX",
    "city": "Campbell"
  },
  {
    "value": "75423",
    "label": "Celeste - TX 75423",
    "county": "Hunt County",
    "state": "TX",
    "city": "Celeste"
  },
  {
    "value": "75424",
    "label": "Blue Ridge - TX 75424",
    "county": "Collin County",
    "state": "TX",
    "city": "Blue Ridge"
  },
  {
    "value": "75426",
    "label": "Clarksville - TX 75426",
    "county": "Red River County",
    "state": "TX",
    "city": "Clarksville"
  },
  {
    "value": "75428",
    "label": "Commerce - TX 75428",
    "county": "Hunt County",
    "state": "TX",
    "city": "Commerce"
  },
  {
    "value": "75431",
    "label": "Como - TX 75431",
    "county": "Hopkins County",
    "state": "TX",
    "city": "Como"
  },
  {
    "value": "75432",
    "label": "Cooper - TX 75432",
    "county": "Delta County",
    "state": "TX",
    "city": "Cooper"
  },
  {
    "value": "75433",
    "label": "Cumby - TX 75433",
    "county": "Hopkins County",
    "state": "TX",
    "city": "Cumby"
  },
  {
    "value": "75435",
    "label": "Deport - TX 75435",
    "county": "Lamar County",
    "state": "TX",
    "city": "Deport"
  },
  {
    "value": "75436",
    "label": "Detroit - TX 75436",
    "county": "Red River County",
    "state": "TX",
    "city": "Detroit"
  },
  {
    "value": "75437",
    "label": "Dike - TX 75437",
    "county": "Hopkins County",
    "state": "TX",
    "city": "Dike"
  },
  {
    "value": "75438",
    "label": "Dodd City - TX 75438",
    "county": "Fannin County",
    "state": "TX",
    "city": "Dodd City"
  },
  {
    "value": "75439",
    "label": "Ector - TX 75439",
    "county": "Fannin County",
    "state": "TX",
    "city": "Ector"
  },
  {
    "value": "75440",
    "label": "Emory - TX 75440",
    "county": "Rains County",
    "state": "TX",
    "city": "Emory"
  },
  {
    "value": "75442",
    "label": "Farmersville - TX 75442",
    "county": "Collin County",
    "state": "TX",
    "city": "Farmersville"
  },
  {
    "value": "75446",
    "label": "Honey Grove - TX 75446",
    "county": "Fannin County",
    "state": "TX",
    "city": "Honey Grove"
  },
  {
    "value": "75447",
    "label": "Ivanhoe - TX 75447",
    "county": "Fannin County",
    "state": "TX",
    "city": "Ivanhoe"
  },
  {
    "value": "75448",
    "label": "Klondike - TX 75448",
    "county": "Delta County",
    "state": "TX",
    "city": "Klondike"
  },
  {
    "value": "75449",
    "label": "Ladonia - TX 75449",
    "county": "Fannin County",
    "state": "TX",
    "city": "Ladonia"
  },
  {
    "value": "75450",
    "label": "Lake Creek - TX 75450",
    "county": "Delta County",
    "state": "TX",
    "city": "Lake Creek"
  },
  {
    "value": "75451",
    "label": "Leesburg - TX 75451",
    "county": "Camp County",
    "state": "TX",
    "city": "Leesburg"
  },
  {
    "value": "75452",
    "label": "Leonard - TX 75452",
    "county": "Fannin County",
    "state": "TX",
    "city": "Leonard"
  },
  {
    "value": "75453",
    "label": "Lone Oak - TX 75453",
    "county": "Hunt County",
    "state": "TX",
    "city": "Lone Oak"
  },
  {
    "value": "75454",
    "label": "Melissa - TX 75454",
    "county": "Collin County",
    "state": "TX",
    "city": "Melissa"
  },
  {
    "value": "75455",
    "label": "Mount Pleasant - TX 75455",
    "county": "Titus County",
    "state": "TX",
    "city": "Mount Pleasant"
  },
  {
    "value": "75457",
    "label": "Mount Vernon - TX 75457",
    "county": "Franklin County",
    "state": "TX",
    "city": "Mount Vernon"
  },
  {
    "value": "75459",
    "label": "Howe - TX 75459",
    "county": "Grayson County",
    "state": "TX",
    "city": "Howe"
  },
  {
    "value": "75460",
    "label": "Paris - TX 75460",
    "county": "Lamar County",
    "state": "TX",
    "city": "Paris"
  },
  {
    "value": "75462",
    "label": "Paris - TX 75462",
    "county": "Lamar County",
    "state": "TX",
    "city": "Paris"
  },
  {
    "value": "75468",
    "label": "Pattonville - TX 75468",
    "county": "Lamar County",
    "state": "TX",
    "city": "Pattonville"
  },
  {
    "value": "75469",
    "label": "Pecan Gap - TX 75469",
    "county": "Delta County",
    "state": "TX",
    "city": "Pecan Gap"
  },
  {
    "value": "75470",
    "label": "Petty - TX 75470",
    "county": "Lamar County",
    "state": "TX",
    "city": "Petty"
  },
  {
    "value": "75471",
    "label": "Pickton - TX 75471",
    "county": "Hopkins County",
    "state": "TX",
    "city": "Pickton"
  },
  {
    "value": "75472",
    "label": "Point - TX 75472",
    "county": "Rains County",
    "state": "TX",
    "city": "Point"
  },
  {
    "value": "75473",
    "label": "Powderly - TX 75473",
    "county": "Lamar County",
    "state": "TX",
    "city": "Powderly"
  },
  {
    "value": "75474",
    "label": "Quinlan - TX 75474",
    "county": "Hunt County",
    "state": "TX",
    "city": "Quinlan"
  },
  {
    "value": "75476",
    "label": "Ravenna - TX 75476",
    "county": "Fannin County",
    "state": "TX",
    "city": "Ravenna"
  },
  {
    "value": "75477",
    "label": "Roxton - TX 75477",
    "county": "Lamar County",
    "state": "TX",
    "city": "Roxton"
  },
  {
    "value": "75478",
    "label": "Saltillo - TX 75478",
    "county": "Hopkins County",
    "state": "TX",
    "city": "Saltillo"
  },
  {
    "value": "75479",
    "label": "Savoy - TX 75479",
    "county": "Fannin County",
    "state": "TX",
    "city": "Savoy"
  },
  {
    "value": "75480",
    "label": "Scroggins - TX 75480",
    "county": "Franklin County",
    "state": "TX",
    "city": "Scroggins"
  },
  {
    "value": "75481",
    "label": "Sulphur Bluff - TX 75481",
    "county": "Hopkins County",
    "state": "TX",
    "city": "Sulphur Bluff"
  },
  {
    "value": "75482",
    "label": "Sulphur Springs - TX 75482",
    "county": "Hopkins County",
    "state": "TX",
    "city": "Sulphur Springs"
  },
  {
    "value": "75486",
    "label": "Sumner - TX 75486",
    "county": "Lamar County",
    "state": "TX",
    "city": "Sumner"
  },
  {
    "value": "75487",
    "label": "Talco - TX 75487",
    "county": "Titus County",
    "state": "TX",
    "city": "Talco"
  },
  {
    "value": "75488",
    "label": "Telephone - TX 75488",
    "county": "Fannin County",
    "state": "TX",
    "city": "Telephone"
  },
  {
    "value": "75490",
    "label": "Trenton - TX 75490",
    "county": "Fannin County",
    "state": "TX",
    "city": "Trenton"
  },
  {
    "value": "75491",
    "label": "Whitewright - TX 75491",
    "county": "Grayson County",
    "state": "TX",
    "city": "Whitewright"
  },
  {
    "value": "75492",
    "label": "Windom - TX 75492",
    "county": "Fannin County",
    "state": "TX",
    "city": "Windom"
  },
  {
    "value": "75494",
    "label": "Winnsboro - TX 75494",
    "county": "Wood County",
    "state": "TX",
    "city": "Winnsboro"
  },
  {
    "value": "75495",
    "label": "Van Alstyne - TX 75495",
    "county": "Grayson County",
    "state": "TX",
    "city": "Van Alstyne"
  },
  {
    "value": "75496",
    "label": "Wolfe City - TX 75496",
    "county": "Hunt County",
    "state": "TX",
    "city": "Wolfe City"
  },
  {
    "value": "75497",
    "label": "Yantis - TX 75497",
    "county": "Wood County",
    "state": "TX",
    "city": "Yantis"
  },
  {
    "value": "75501",
    "label": "Texarkana - TX 75501",
    "county": "Bowie County",
    "state": "TX",
    "city": "Texarkana"
  },
  {
    "value": "75503",
    "label": "Texarkana - TX 75503",
    "county": "Bowie County",
    "state": "TX",
    "city": "Texarkana"
  },
  {
    "value": "75550",
    "label": "Annona - TX 75550",
    "county": "Red River County",
    "state": "TX",
    "city": "Annona"
  },
  {
    "value": "75551",
    "label": "Atlanta - TX 75551",
    "county": "Cass County",
    "state": "TX",
    "city": "Atlanta"
  },
  {
    "value": "75554",
    "label": "Avery - TX 75554",
    "county": "Red River County",
    "state": "TX",
    "city": "Avery"
  },
  {
    "value": "75555",
    "label": "Bivins - TX 75555",
    "county": "Cass County",
    "state": "TX",
    "city": "Bivins"
  },
  {
    "value": "75556",
    "label": "Bloomburg - TX 75556",
    "county": "Cass County",
    "state": "TX",
    "city": "Bloomburg"
  },
  {
    "value": "75558",
    "label": "Cookville - TX 75558",
    "county": "Titus County",
    "state": "TX",
    "city": "Cookville"
  },
  {
    "value": "75559",
    "label": "De Kalb - TX 75559",
    "county": "Bowie County",
    "state": "TX",
    "city": "De Kalb"
  },
  {
    "value": "75560",
    "label": "Douglassville - TX 75560",
    "county": "Cass County",
    "state": "TX",
    "city": "Douglassville"
  },
  {
    "value": "75561",
    "label": "Hooks - TX 75561",
    "county": "Bowie County",
    "state": "TX",
    "city": "Hooks"
  },
  {
    "value": "75563",
    "label": "Linden - TX 75563",
    "county": "Cass County",
    "state": "TX",
    "city": "Linden"
  },
  {
    "value": "75566",
    "label": "Marietta - TX 75566",
    "county": "Cass County",
    "state": "TX",
    "city": "Marietta"
  },
  {
    "value": "75567",
    "label": "Maud - TX 75567",
    "county": "Bowie County",
    "state": "TX",
    "city": "Maud"
  },
  {
    "value": "75568",
    "label": "Naples - TX 75568",
    "county": "Morris County",
    "state": "TX",
    "city": "Naples"
  },
  {
    "value": "75569",
    "label": "Nash - TX 75569",
    "county": "Bowie County",
    "state": "TX",
    "city": "Nash"
  },
  {
    "value": "75570",
    "label": "New Boston - TX 75570",
    "county": "Bowie County",
    "state": "TX",
    "city": "New Boston"
  },
  {
    "value": "75571",
    "label": "Omaha - TX 75571",
    "county": "Morris County",
    "state": "TX",
    "city": "Omaha"
  },
  {
    "value": "75572",
    "label": "Queen City - TX 75572",
    "county": "Cass County",
    "state": "TX",
    "city": "Queen City"
  },
  {
    "value": "75574",
    "label": "Simms - TX 75574",
    "county": "Bowie County",
    "state": "TX",
    "city": "Simms"
  },
  {
    "value": "75601",
    "label": "Longview - TX 75601",
    "county": "Gregg County",
    "state": "TX",
    "city": "Longview"
  },
  {
    "value": "75602",
    "label": "Longview - TX 75602",
    "county": "Gregg County",
    "state": "TX",
    "city": "Longview"
  },
  {
    "value": "75603",
    "label": "Longview - TX 75603",
    "county": "Gregg County",
    "state": "TX",
    "city": "Longview"
  },
  {
    "value": "75604",
    "label": "Longview - TX 75604",
    "county": "Gregg County",
    "state": "TX",
    "city": "Longview"
  },
  {
    "value": "75605",
    "label": "Longview - TX 75605",
    "county": "Gregg County",
    "state": "TX",
    "city": "Longview"
  },
  {
    "value": "75630",
    "label": "Avinger - TX 75630",
    "county": "Marion County",
    "state": "TX",
    "city": "Avinger"
  },
  {
    "value": "75631",
    "label": "Beckville - TX 75631",
    "county": "Panola County",
    "state": "TX",
    "city": "Beckville"
  },
  {
    "value": "75633",
    "label": "Carthage - TX 75633",
    "county": "Panola County",
    "state": "TX",
    "city": "Carthage"
  },
  {
    "value": "75638",
    "label": "Daingerfield - TX 75638",
    "county": "Morris County",
    "state": "TX",
    "city": "Daingerfield"
  },
  {
    "value": "75639",
    "label": "De Berry - TX 75639",
    "county": "Panola County",
    "state": "TX",
    "city": "De Berry"
  },
  {
    "value": "75640",
    "label": "Diana - TX 75640",
    "county": "Upshur County",
    "state": "TX",
    "city": "Diana"
  },
  {
    "value": "75643",
    "label": "Gary - TX 75643",
    "county": "Panola County",
    "state": "TX",
    "city": "Gary"
  },
  {
    "value": "75644",
    "label": "Gilmer - TX 75644",
    "county": "Upshur County",
    "state": "TX",
    "city": "Gilmer"
  },
  {
    "value": "75645",
    "label": "Gilmer - TX 75645",
    "county": "Upshur County",
    "state": "TX",
    "city": "Gilmer"
  },
  {
    "value": "75647",
    "label": "Gladewater - TX 75647",
    "county": "Gregg County",
    "state": "TX",
    "city": "Gladewater"
  },
  {
    "value": "75650",
    "label": "Hallsville - TX 75650",
    "county": "Harrison County",
    "state": "TX",
    "city": "Hallsville"
  },
  {
    "value": "75651",
    "label": "Harleton - TX 75651",
    "county": "Harrison County",
    "state": "TX",
    "city": "Harleton"
  },
  {
    "value": "75652",
    "label": "Henderson - TX 75652",
    "county": "Rusk County",
    "state": "TX",
    "city": "Henderson"
  },
  {
    "value": "75654",
    "label": "Henderson - TX 75654",
    "county": "Rusk County",
    "state": "TX",
    "city": "Henderson"
  },
  {
    "value": "75656",
    "label": "Hughes Springs - TX 75656",
    "county": "Cass County",
    "state": "TX",
    "city": "Hughes Springs"
  },
  {
    "value": "75657",
    "label": "Jefferson - TX 75657",
    "county": "Marion County",
    "state": "TX",
    "city": "Jefferson"
  },
  {
    "value": "75661",
    "label": "Karnack - TX 75661",
    "county": "Harrison County",
    "state": "TX",
    "city": "Karnack"
  },
  {
    "value": "75662",
    "label": "Kilgore - TX 75662",
    "county": "Gregg County",
    "state": "TX",
    "city": "Kilgore"
  },
  {
    "value": "75667",
    "label": "Laneville - TX 75667",
    "county": "Rusk County",
    "state": "TX",
    "city": "Laneville"
  },
  {
    "value": "75668",
    "label": "Lone Star - TX 75668",
    "county": "Morris County",
    "state": "TX",
    "city": "Lone Star"
  },
  {
    "value": "75669",
    "label": "Long Branch - TX 75669",
    "county": "Panola County",
    "state": "TX",
    "city": "Long Branch"
  },
  {
    "value": "75670",
    "label": "Marshall - TX 75670",
    "county": "Harrison County",
    "state": "TX",
    "city": "Marshall"
  },
  {
    "value": "75672",
    "label": "Marshall - TX 75672",
    "county": "Harrison County",
    "state": "TX",
    "city": "Marshall"
  },
  {
    "value": "75681",
    "label": "Mount Enterprise - TX 75681",
    "county": "Rusk County",
    "state": "TX",
    "city": "Mount Enterprise"
  },
  {
    "value": "75683",
    "label": "Ore City - TX 75683",
    "county": "Upshur County",
    "state": "TX",
    "city": "Ore City"
  },
  {
    "value": "75684",
    "label": "Overton - TX 75684",
    "county": "Rusk County",
    "state": "TX",
    "city": "Overton"
  },
  {
    "value": "75686",
    "label": "Pittsburg - TX 75686",
    "county": "Camp County",
    "state": "TX",
    "city": "Pittsburg"
  },
  {
    "value": "75691",
    "label": "Tatum - TX 75691",
    "county": "Rusk County",
    "state": "TX",
    "city": "Tatum"
  },
  {
    "value": "75692",
    "label": "Waskom - TX 75692",
    "county": "Harrison County",
    "state": "TX",
    "city": "Waskom"
  },
  {
    "value": "75693",
    "label": "White Oak - TX 75693",
    "county": "Gregg County",
    "state": "TX",
    "city": "White Oak"
  },
  {
    "value": "75701",
    "label": "Tyler - TX 75701",
    "county": "Smith County",
    "state": "TX",
    "city": "Tyler"
  },
  {
    "value": "75702",
    "label": "Tyler - TX 75702",
    "county": "Smith County",
    "state": "TX",
    "city": "Tyler"
  },
  {
    "value": "75703",
    "label": "Tyler - TX 75703",
    "county": "Smith County",
    "state": "TX",
    "city": "Tyler"
  },
  {
    "value": "75704",
    "label": "Tyler - TX 75704",
    "county": "Smith County",
    "state": "TX",
    "city": "Tyler"
  },
  {
    "value": "75705",
    "label": "Tyler - TX 75705",
    "county": "Smith County",
    "state": "TX",
    "city": "Tyler"
  },
  {
    "value": "75706",
    "label": "Tyler - TX 75706",
    "county": "Smith County",
    "state": "TX",
    "city": "Tyler"
  },
  {
    "value": "75707",
    "label": "Tyler - TX 75707",
    "county": "Smith County",
    "state": "TX",
    "city": "Tyler"
  },
  {
    "value": "75708",
    "label": "Tyler - TX 75708",
    "county": "Smith County",
    "state": "TX",
    "city": "Tyler"
  },
  {
    "value": "75709",
    "label": "Tyler - TX 75709",
    "county": "Smith County",
    "state": "TX",
    "city": "Tyler"
  },
  {
    "value": "75750",
    "label": "Arp - TX 75750",
    "county": "Smith County",
    "state": "TX",
    "city": "Arp"
  },
  {
    "value": "75751",
    "label": "Athens - TX 75751",
    "county": "Henderson County",
    "state": "TX",
    "city": "Athens"
  },
  {
    "value": "75752",
    "label": "Athens - TX 75752",
    "county": "Henderson County",
    "state": "TX",
    "city": "Athens"
  },
  {
    "value": "75754",
    "label": "Ben Wheeler - TX 75754",
    "county": "Van Zandt County",
    "state": "TX",
    "city": "Ben Wheeler"
  },
  {
    "value": "75755",
    "label": "Big Sandy - TX 75755",
    "county": "Upshur County",
    "state": "TX",
    "city": "Big Sandy"
  },
  {
    "value": "75756",
    "label": "Brownsboro - TX 75756",
    "county": "Henderson County",
    "state": "TX",
    "city": "Brownsboro"
  },
  {
    "value": "75757",
    "label": "Bullard - TX 75757",
    "county": "Smith County",
    "state": "TX",
    "city": "Bullard"
  },
  {
    "value": "75758",
    "label": "Chandler - TX 75758",
    "county": "Henderson County",
    "state": "TX",
    "city": "Chandler"
  },
  {
    "value": "75760",
    "label": "Cushing - TX 75760",
    "county": "Nacogdoches County",
    "state": "TX",
    "city": "Cushing"
  },
  {
    "value": "75762",
    "label": "Flint - TX 75762",
    "county": "Smith County",
    "state": "TX",
    "city": "Flint"
  },
  {
    "value": "75763",
    "label": "Frankston - TX 75763",
    "county": "Anderson County",
    "state": "TX",
    "city": "Frankston"
  },
  {
    "value": "75765",
    "label": "Hawkins - TX 75765",
    "county": "Wood County",
    "state": "TX",
    "city": "Hawkins"
  },
  {
    "value": "75766",
    "label": "Jacksonville - TX 75766",
    "county": "Cherokee County",
    "state": "TX",
    "city": "Jacksonville"
  },
  {
    "value": "75770",
    "label": "Larue - TX 75770",
    "county": "Henderson County",
    "state": "TX",
    "city": "Larue"
  },
  {
    "value": "75771",
    "label": "Lindale - TX 75771",
    "county": "Smith County",
    "state": "TX",
    "city": "Lindale"
  },
  {
    "value": "75773",
    "label": "Mineola - TX 75773",
    "county": "Wood County",
    "state": "TX",
    "city": "Mineola"
  },
  {
    "value": "75778",
    "label": "Murchison - TX 75778",
    "county": "Henderson County",
    "state": "TX",
    "city": "Murchison"
  },
  {
    "value": "75783",
    "label": "Quitman - TX 75783",
    "county": "Wood County",
    "state": "TX",
    "city": "Quitman"
  },
  {
    "value": "75784",
    "label": "Reklaw - TX 75784",
    "county": "Cherokee County",
    "state": "TX",
    "city": "Reklaw"
  },
  {
    "value": "75785",
    "label": "Rusk - TX 75785",
    "county": "Cherokee County",
    "state": "TX",
    "city": "Rusk"
  },
  {
    "value": "75789",
    "label": "Troup - TX 75789",
    "county": "Smith County",
    "state": "TX",
    "city": "Troup"
  },
  {
    "value": "75790",
    "label": "Van - TX 75790",
    "county": "Van Zandt County",
    "state": "TX",
    "city": "Van"
  },
  {
    "value": "75791",
    "label": "Whitehouse - TX 75791",
    "county": "Smith County",
    "state": "TX",
    "city": "Whitehouse"
  },
  {
    "value": "75792",
    "label": "Winona - TX 75792",
    "county": "Smith County",
    "state": "TX",
    "city": "Winona"
  },
  {
    "value": "75801",
    "label": "Palestine - TX 75801",
    "county": "Anderson County",
    "state": "TX",
    "city": "Palestine"
  },
  {
    "value": "75803",
    "label": "Palestine - TX 75803",
    "county": "Anderson County",
    "state": "TX",
    "city": "Palestine"
  },
  {
    "value": "75831",
    "label": "Buffalo - TX 75831",
    "county": "Leon County",
    "state": "TX",
    "city": "Buffalo"
  },
  {
    "value": "75833",
    "label": "Centerville - TX 75833",
    "county": "Leon County",
    "state": "TX",
    "city": "Centerville"
  },
  {
    "value": "75834",
    "label": "Centralia - TX 75834",
    "county": "Trinity County",
    "state": "TX",
    "city": "Centralia"
  },
  {
    "value": "75835",
    "label": "Crockett - TX 75835",
    "county": "Houston County",
    "state": "TX",
    "city": "Crockett"
  },
  {
    "value": "75838",
    "label": "Donie - TX 75838",
    "county": "Freestone County",
    "state": "TX",
    "city": "Donie"
  },
  {
    "value": "75839",
    "label": "Elkhart - TX 75839",
    "county": "Anderson County",
    "state": "TX",
    "city": "Elkhart"
  },
  {
    "value": "75840",
    "label": "Fairfield - TX 75840",
    "county": "Freestone County",
    "state": "TX",
    "city": "Fairfield"
  },
  {
    "value": "75844",
    "label": "Grapeland - TX 75844",
    "county": "Houston County",
    "state": "TX",
    "city": "Grapeland"
  },
  {
    "value": "75845",
    "label": "Groveton - TX 75845",
    "county": "Trinity County",
    "state": "TX",
    "city": "Groveton"
  },
  {
    "value": "75846",
    "label": "Jewett - TX 75846",
    "county": "Leon County",
    "state": "TX",
    "city": "Jewett"
  },
  {
    "value": "75847",
    "label": "Kennard - TX 75847",
    "county": "Houston County",
    "state": "TX",
    "city": "Kennard"
  },
  {
    "value": "75850",
    "label": "Leona - TX 75850",
    "county": "Leon County",
    "state": "TX",
    "city": "Leona"
  },
  {
    "value": "75851",
    "label": "Lovelady - TX 75851",
    "county": "Houston County",
    "state": "TX",
    "city": "Lovelady"
  },
  {
    "value": "75852",
    "label": "Midway - TX 75852",
    "county": "Madison County",
    "state": "TX",
    "city": "Midway"
  },
  {
    "value": "75853",
    "label": "Montalba - TX 75853",
    "county": "Anderson County",
    "state": "TX",
    "city": "Montalba"
  },
  {
    "value": "75855",
    "label": "Oakwood - TX 75855",
    "county": "Leon County",
    "state": "TX",
    "city": "Oakwood"
  },
  {
    "value": "75856",
    "label": "Pennington - TX 75856",
    "county": "Trinity County",
    "state": "TX",
    "city": "Pennington"
  },
  {
    "value": "75859",
    "label": "Streetman - TX 75859",
    "county": "Freestone County",
    "state": "TX",
    "city": "Streetman"
  },
  {
    "value": "75860",
    "label": "Teague - TX 75860",
    "county": "Freestone County",
    "state": "TX",
    "city": "Teague"
  },
  {
    "value": "75861",
    "label": "Tennessee Colony - TX 75861",
    "county": "Anderson County",
    "state": "TX",
    "city": "Tennessee Colony"
  },
  {
    "value": "75862",
    "label": "Trinity - TX 75862",
    "county": "Trinity County",
    "state": "TX",
    "city": "Trinity"
  },
  {
    "value": "75901",
    "label": "Lufkin - TX 75901",
    "county": "Angelina County",
    "state": "TX",
    "city": "Lufkin"
  },
  {
    "value": "75904",
    "label": "Lufkin - TX 75904",
    "county": "Angelina County",
    "state": "TX",
    "city": "Lufkin"
  },
  {
    "value": "75925",
    "label": "Alto - TX 75925",
    "county": "Cherokee County",
    "state": "TX",
    "city": "Alto"
  },
  {
    "value": "75926",
    "label": "Apple Springs - TX 75926",
    "county": "Trinity County",
    "state": "TX",
    "city": "Apple Springs"
  },
  {
    "value": "75928",
    "label": "Bon Wier - TX 75928",
    "county": "Newton County",
    "state": "TX",
    "city": "Bon Wier"
  },
  {
    "value": "75929",
    "label": "Broaddus - TX 75929",
    "county": "San Augustine County",
    "state": "TX",
    "city": "Broaddus"
  },
  {
    "value": "75930",
    "label": "Bronson - TX 75930",
    "county": "Sabine County",
    "state": "TX",
    "city": "Bronson"
  },
  {
    "value": "75931",
    "label": "Brookeland - TX 75931",
    "county": "Jasper County",
    "state": "TX",
    "city": "Brookeland"
  },
  {
    "value": "75932",
    "label": "Burkeville - TX 75932",
    "county": "Newton County",
    "state": "TX",
    "city": "Burkeville"
  },
  {
    "value": "75933",
    "label": "Call - TX 75933",
    "county": "Newton County",
    "state": "TX",
    "city": "Call"
  },
  {
    "value": "75935",
    "label": "Center - TX 75935",
    "county": "Shelby County",
    "state": "TX",
    "city": "Center"
  },
  {
    "value": "75936",
    "label": "Chester - TX 75936",
    "county": "Tyler County",
    "state": "TX",
    "city": "Chester"
  },
  {
    "value": "75937",
    "label": "Chireno - TX 75937",
    "county": "Nacogdoches County",
    "state": "TX",
    "city": "Chireno"
  },
  {
    "value": "75938",
    "label": "Colmesneil - TX 75938",
    "county": "Tyler County",
    "state": "TX",
    "city": "Colmesneil"
  },
  {
    "value": "75939",
    "label": "Corrigan - TX 75939",
    "county": "Polk County",
    "state": "TX",
    "city": "Corrigan"
  },
  {
    "value": "75941",
    "label": "Diboll - TX 75941",
    "county": "Angelina County",
    "state": "TX",
    "city": "Diboll"
  },
  {
    "value": "75943",
    "label": "Douglass - TX 75943",
    "county": "Nacogdoches County",
    "state": "TX",
    "city": "Douglass"
  },
  {
    "value": "75944",
    "label": "Etoile - TX 75944",
    "county": "Nacogdoches County",
    "state": "TX",
    "city": "Etoile"
  },
  {
    "value": "75946",
    "label": "Garrison - TX 75946",
    "county": "Nacogdoches County",
    "state": "TX",
    "city": "Garrison"
  },
  {
    "value": "75948",
    "label": "Hemphill - TX 75948",
    "county": "Sabine County",
    "state": "TX",
    "city": "Hemphill"
  },
  {
    "value": "75949",
    "label": "Huntington - TX 75949",
    "county": "Angelina County",
    "state": "TX",
    "city": "Huntington"
  },
  {
    "value": "75951",
    "label": "Jasper - TX 75951",
    "county": "Jasper County",
    "state": "TX",
    "city": "Jasper"
  },
  {
    "value": "75954",
    "label": "Joaquin - TX 75954",
    "county": "Shelby County",
    "state": "TX",
    "city": "Joaquin"
  },
  {
    "value": "75956",
    "label": "Kirbyville - TX 75956",
    "county": "Jasper County",
    "state": "TX",
    "city": "Kirbyville"
  },
  {
    "value": "75959",
    "label": "Milam - TX 75959",
    "county": "Sabine County",
    "state": "TX",
    "city": "Milam"
  },
  {
    "value": "75960",
    "label": "Moscow - TX 75960",
    "county": "Polk County",
    "state": "TX",
    "city": "Moscow"
  },
  {
    "value": "75961",
    "label": "Nacogdoches - TX 75961",
    "county": "Nacogdoches County",
    "state": "TX",
    "city": "Nacogdoches"
  },
  {
    "value": "75964",
    "label": "Nacogdoches - TX 75964",
    "county": "Nacogdoches County",
    "state": "TX",
    "city": "Nacogdoches"
  },
  {
    "value": "75965",
    "label": "Nacogdoches - TX 75965",
    "county": "Nacogdoches County",
    "state": "TX",
    "city": "Nacogdoches"
  },
  {
    "value": "75966",
    "label": "Newton - TX 75966",
    "county": "Newton County",
    "state": "TX",
    "city": "Newton"
  },
  {
    "value": "75968",
    "label": "Pineland - TX 75968",
    "county": "Sabine County",
    "state": "TX",
    "city": "Pineland"
  },
  {
    "value": "75969",
    "label": "Pollok - TX 75969",
    "county": "Angelina County",
    "state": "TX",
    "city": "Pollok"
  },
  {
    "value": "75972",
    "label": "San Augustine - TX 75972",
    "county": "San Augustine County",
    "state": "TX",
    "city": "San Augustine"
  },
  {
    "value": "75973",
    "label": "Shelbyville - TX 75973",
    "county": "Shelby County",
    "state": "TX",
    "city": "Shelbyville"
  },
  {
    "value": "75974",
    "label": "Tenaha - TX 75974",
    "county": "Shelby County",
    "state": "TX",
    "city": "Tenaha"
  },
  {
    "value": "75975",
    "label": "Timpson - TX 75975",
    "county": "Shelby County",
    "state": "TX",
    "city": "Timpson"
  },
  {
    "value": "75977",
    "label": "Wiergate - TX 75977",
    "county": "Newton County",
    "state": "TX",
    "city": "Wiergate"
  },
  {
    "value": "75979",
    "label": "Woodville - TX 75979",
    "county": "Tyler County",
    "state": "TX",
    "city": "Woodville"
  },
  {
    "value": "75980",
    "label": "Zavalla - TX 75980",
    "county": "Angelina County",
    "state": "TX",
    "city": "Zavalla"
  },
  {
    "value": "76001",
    "label": "Arlington - TX 76001",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Arlington"
  },
  {
    "value": "76002",
    "label": "Arlington - TX 76002",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Arlington"
  },
  {
    "value": "76005",
    "label": "Arlington - TX 76005",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Arlington"
  },
  {
    "value": "76006",
    "label": "Arlington - TX 76006",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Arlington"
  },
  {
    "value": "76008",
    "label": "Aledo - TX 76008",
    "county": "Parker County",
    "state": "TX",
    "city": "Aledo"
  },
  {
    "value": "76009",
    "label": "Alvarado - TX 76009",
    "county": "Johnson County",
    "state": "TX",
    "city": "Alvarado"
  },
  {
    "value": "76010",
    "label": "Arlington - TX 76010",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Arlington"
  },
  {
    "value": "76011",
    "label": "Arlington - TX 76011",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Arlington"
  },
  {
    "value": "76012",
    "label": "Arlington - TX 76012",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Arlington"
  },
  {
    "value": "76013",
    "label": "Arlington - TX 76013",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Arlington"
  },
  {
    "value": "76014",
    "label": "Arlington - TX 76014",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Arlington"
  },
  {
    "value": "76015",
    "label": "Arlington - TX 76015",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Arlington"
  },
  {
    "value": "76016",
    "label": "Arlington - TX 76016",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Arlington"
  },
  {
    "value": "76017",
    "label": "Arlington - TX 76017",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Arlington"
  },
  {
    "value": "76018",
    "label": "Arlington - TX 76018",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Arlington"
  },
  {
    "value": "76020",
    "label": "Azle - TX 76020",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Azle"
  },
  {
    "value": "76021",
    "label": "Bedford - TX 76021",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Bedford"
  },
  {
    "value": "76022",
    "label": "Bedford - TX 76022",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Bedford"
  },
  {
    "value": "76023",
    "label": "Boyd - TX 76023",
    "county": "Wise County",
    "state": "TX",
    "city": "Boyd"
  },
  {
    "value": "76028",
    "label": "Burleson - TX 76028",
    "county": "Johnson County",
    "state": "TX",
    "city": "Burleson"
  },
  {
    "value": "76031",
    "label": "Cleburne - TX 76031",
    "county": "Johnson County",
    "state": "TX",
    "city": "Cleburne"
  },
  {
    "value": "76033",
    "label": "Cleburne - TX 76033",
    "county": "Johnson County",
    "state": "TX",
    "city": "Cleburne"
  },
  {
    "value": "76034",
    "label": "Colleyville - TX 76034",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Colleyville"
  },
  {
    "value": "76035",
    "label": "Cresson - TX 76035",
    "county": "Parker County",
    "state": "TX",
    "city": "Cresson"
  },
  {
    "value": "76036",
    "label": "Crowley - TX 76036",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Crowley"
  },
  {
    "value": "76039",
    "label": "Euless - TX 76039",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Euless"
  },
  {
    "value": "76040",
    "label": "Euless - TX 76040",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Euless"
  },
  {
    "value": "76041",
    "label": "Forreston - TX 76041",
    "county": "Ellis County",
    "state": "TX",
    "city": "Forreston"
  },
  {
    "value": "76043",
    "label": "Glen Rose - TX 76043",
    "county": "Somervell County",
    "state": "TX",
    "city": "Glen Rose"
  },
  {
    "value": "76044",
    "label": "Godley - TX 76044",
    "county": "Johnson County",
    "state": "TX",
    "city": "Godley"
  },
  {
    "value": "76048",
    "label": "Granbury - TX 76048",
    "county": "Hood County",
    "state": "TX",
    "city": "Granbury"
  },
  {
    "value": "76049",
    "label": "Granbury - TX 76049",
    "county": "Hood County",
    "state": "TX",
    "city": "Granbury"
  },
  {
    "value": "76050",
    "label": "Grandview - TX 76050",
    "county": "Johnson County",
    "state": "TX",
    "city": "Grandview"
  },
  {
    "value": "76051",
    "label": "Grapevine - TX 76051",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Grapevine"
  },
  {
    "value": "76052",
    "label": "Haslet - TX 76052",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Haslet"
  },
  {
    "value": "76053",
    "label": "Hurst - TX 76053",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Hurst"
  },
  {
    "value": "76054",
    "label": "Hurst - TX 76054",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Hurst"
  },
  {
    "value": "76055",
    "label": "Itasca - TX 76055",
    "county": "Hill County",
    "state": "TX",
    "city": "Itasca"
  },
  {
    "value": "76058",
    "label": "Joshua - TX 76058",
    "county": "Johnson County",
    "state": "TX",
    "city": "Joshua"
  },
  {
    "value": "76059",
    "label": "Keene - TX 76059",
    "county": "Johnson County",
    "state": "TX",
    "city": "Keene"
  },
  {
    "value": "76060",
    "label": "Kennedale - TX 76060",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Kennedale"
  },
  {
    "value": "76063",
    "label": "Mansfield - TX 76063",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Mansfield"
  },
  {
    "value": "76064",
    "label": "Maypearl - TX 76064",
    "county": "Ellis County",
    "state": "TX",
    "city": "Maypearl"
  },
  {
    "value": "76065",
    "label": "Midlothian - TX 76065",
    "county": "Ellis County",
    "state": "TX",
    "city": "Midlothian"
  },
  {
    "value": "76066",
    "label": "Millsap - TX 76066",
    "county": "Parker County",
    "state": "TX",
    "city": "Millsap"
  },
  {
    "value": "76067",
    "label": "Mineral Wells - TX 76067",
    "county": "Palo Pinto County",
    "state": "TX",
    "city": "Mineral Wells"
  },
  {
    "value": "76070",
    "label": "Nemo - TX 76070",
    "county": "Somervell County",
    "state": "TX",
    "city": "Nemo"
  },
  {
    "value": "76071",
    "label": "Newark - TX 76071",
    "county": "Wise County",
    "state": "TX",
    "city": "Newark"
  },
  {
    "value": "76073",
    "label": "Paradise - TX 76073",
    "county": "Wise County",
    "state": "TX",
    "city": "Paradise"
  },
  {
    "value": "76077",
    "label": "Rainbow - TX 76077",
    "county": "Somervell County",
    "state": "TX",
    "city": "Rainbow"
  },
  {
    "value": "76078",
    "label": "Rhome - TX 76078",
    "county": "Wise County",
    "state": "TX",
    "city": "Rhome"
  },
  {
    "value": "76082",
    "label": "Springtown - TX 76082",
    "county": "Parker County",
    "state": "TX",
    "city": "Springtown"
  },
  {
    "value": "76084",
    "label": "Venus - TX 76084",
    "county": "Johnson County",
    "state": "TX",
    "city": "Venus"
  },
  {
    "value": "76085",
    "label": "Weatherford - TX 76085",
    "county": "Parker County",
    "state": "TX",
    "city": "Weatherford"
  },
  {
    "value": "76086",
    "label": "Weatherford - TX 76086",
    "county": "Parker County",
    "state": "TX",
    "city": "Weatherford"
  },
  {
    "value": "76087",
    "label": "Weatherford - TX 76087",
    "county": "Parker County",
    "state": "TX",
    "city": "Weatherford"
  },
  {
    "value": "76088",
    "label": "Weatherford - TX 76088",
    "county": "Parker County",
    "state": "TX",
    "city": "Weatherford"
  },
  {
    "value": "76092",
    "label": "Southlake - TX 76092",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Southlake"
  },
  {
    "value": "76093",
    "label": "Rio Vista - TX 76093",
    "county": "Johnson County",
    "state": "TX",
    "city": "Rio Vista"
  },
  {
    "value": "76102",
    "label": "Fort Worth - TX 76102",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76103",
    "label": "Fort Worth - TX 76103",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76104",
    "label": "Fort Worth - TX 76104",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76105",
    "label": "Fort Worth - TX 76105",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76106",
    "label": "Fort Worth - TX 76106",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76107",
    "label": "Fort Worth - TX 76107",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76108",
    "label": "Fort Worth - TX 76108",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76109",
    "label": "Fort Worth - TX 76109",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76110",
    "label": "Fort Worth - TX 76110",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76111",
    "label": "Fort Worth - TX 76111",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76112",
    "label": "Fort Worth - TX 76112",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76114",
    "label": "Fort Worth - TX 76114",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76115",
    "label": "Fort Worth - TX 76115",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76116",
    "label": "Fort Worth - TX 76116",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76117",
    "label": "Haltom City - TX 76117",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Haltom City"
  },
  {
    "value": "76118",
    "label": "Fort Worth - TX 76118",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76119",
    "label": "Fort Worth - TX 76119",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76120",
    "label": "Fort Worth - TX 76120",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76123",
    "label": "Fort Worth - TX 76123",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76126",
    "label": "Fort Worth - TX 76126",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76127",
    "label": "Naval Air Station Jrb - TX 76127",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Naval Air Station Jrb"
  },
  {
    "value": "76131",
    "label": "Fort Worth - TX 76131",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76132",
    "label": "Fort Worth - TX 76132",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76133",
    "label": "Fort Worth - TX 76133",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76134",
    "label": "Fort Worth - TX 76134",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76135",
    "label": "Fort Worth - TX 76135",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76137",
    "label": "Fort Worth - TX 76137",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76140",
    "label": "Fort Worth - TX 76140",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76148",
    "label": "Fort Worth - TX 76148",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76155",
    "label": "Fort Worth - TX 76155",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76164",
    "label": "Fort Worth - TX 76164",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76177",
    "label": "Fort Worth - TX 76177",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76179",
    "label": "Fort Worth - TX 76179",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Fort Worth"
  },
  {
    "value": "76180",
    "label": "North Richland Hills - TX 76180",
    "county": "Tarrant County",
    "state": "TX",
    "city": "North Richland Hills"
  },
  {
    "value": "76182",
    "label": "North Richland Hills - TX 76182",
    "county": "Tarrant County",
    "state": "TX",
    "city": "North Richland Hills"
  },
  {
    "value": "76201",
    "label": "Denton - TX 76201",
    "county": "Denton County",
    "state": "TX",
    "city": "Denton"
  },
  {
    "value": "76205",
    "label": "Denton - TX 76205",
    "county": "Denton County",
    "state": "TX",
    "city": "Denton"
  },
  {
    "value": "76207",
    "label": "Denton - TX 76207",
    "county": "Denton County",
    "state": "TX",
    "city": "Denton"
  },
  {
    "value": "76208",
    "label": "Denton - TX 76208",
    "county": "Denton County",
    "state": "TX",
    "city": "Denton"
  },
  {
    "value": "76209",
    "label": "Denton - TX 76209",
    "county": "Denton County",
    "state": "TX",
    "city": "Denton"
  },
  {
    "value": "76210",
    "label": "Denton - TX 76210",
    "county": "Denton County",
    "state": "TX",
    "city": "Denton"
  },
  {
    "value": "76225",
    "label": "Alvord - TX 76225",
    "county": "Wise County",
    "state": "TX",
    "city": "Alvord"
  },
  {
    "value": "76226",
    "label": "Argyle - TX 76226",
    "county": "Denton County",
    "state": "TX",
    "city": "Argyle"
  },
  {
    "value": "76227",
    "label": "Aubrey - TX 76227",
    "county": "Denton County",
    "state": "TX",
    "city": "Aubrey"
  },
  {
    "value": "76228",
    "label": "Bellevue - TX 76228",
    "county": "Clay County",
    "state": "TX",
    "city": "Bellevue"
  },
  {
    "value": "76230",
    "label": "Bowie - TX 76230",
    "county": "Montague County",
    "state": "TX",
    "city": "Bowie"
  },
  {
    "value": "76233",
    "label": "Collinsville - TX 76233",
    "county": "Grayson County",
    "state": "TX",
    "city": "Collinsville"
  },
  {
    "value": "76234",
    "label": "Decatur - TX 76234",
    "county": "Wise County",
    "state": "TX",
    "city": "Decatur"
  },
  {
    "value": "76238",
    "label": "Era - TX 76238",
    "county": "Cooke County",
    "state": "TX",
    "city": "Era"
  },
  {
    "value": "76239",
    "label": "Forestburg - TX 76239",
    "county": "Montague County",
    "state": "TX",
    "city": "Forestburg"
  },
  {
    "value": "76240",
    "label": "Gainesville - TX 76240",
    "county": "Cooke County",
    "state": "TX",
    "city": "Gainesville"
  },
  {
    "value": "76244",
    "label": "Keller - TX 76244",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Keller"
  },
  {
    "value": "76245",
    "label": "Gordonville - TX 76245",
    "county": "Grayson County",
    "state": "TX",
    "city": "Gordonville"
  },
  {
    "value": "76247",
    "label": "Justin - TX 76247",
    "county": "Denton County",
    "state": "TX",
    "city": "Justin"
  },
  {
    "value": "76248",
    "label": "Keller - TX 76248",
    "county": "Tarrant County",
    "state": "TX",
    "city": "Keller"
  },
  {
    "value": "76249",
    "label": "Krum - TX 76249",
    "county": "Denton County",
    "state": "TX",
    "city": "Krum"
  },
  {
    "value": "76250",
    "label": "Lindsay - TX 76250",
    "county": "Cooke County",
    "state": "TX",
    "city": "Lindsay"
  },
  {
    "value": "76251",
    "label": "Montague - TX 76251",
    "county": "Montague County",
    "state": "TX",
    "city": "Montague"
  },
  {
    "value": "76252",
    "label": "Muenster - TX 76252",
    "county": "Cooke County",
    "state": "TX",
    "city": "Muenster"
  },
  {
    "value": "76255",
    "label": "Nocona - TX 76255",
    "county": "Montague County",
    "state": "TX",
    "city": "Nocona"
  },
  {
    "value": "76258",
    "label": "Pilot Point - TX 76258",
    "county": "Denton County",
    "state": "TX",
    "city": "Pilot Point"
  },
  {
    "value": "76259",
    "label": "Ponder - TX 76259",
    "county": "Denton County",
    "state": "TX",
    "city": "Ponder"
  },
  {
    "value": "76261",
    "label": "Ringgold - TX 76261",
    "county": "Montague County",
    "state": "TX",
    "city": "Ringgold"
  },
  {
    "value": "76262",
    "label": "Roanoke - TX 76262",
    "county": "Denton County",
    "state": "TX",
    "city": "Roanoke"
  },
  {
    "value": "76263",
    "label": "Rosston - TX 76263",
    "county": "Cooke County",
    "state": "TX",
    "city": "Rosston"
  },
  {
    "value": "76264",
    "label": "Sadler - TX 76264",
    "county": "Grayson County",
    "state": "TX",
    "city": "Sadler"
  },
  {
    "value": "76265",
    "label": "Saint Jo - TX 76265",
    "county": "Montague County",
    "state": "TX",
    "city": "Saint Jo"
  },
  {
    "value": "76266",
    "label": "Sanger - TX 76266",
    "county": "Denton County",
    "state": "TX",
    "city": "Sanger"
  },
  {
    "value": "76270",
    "label": "Sunset - TX 76270",
    "county": "Montague County",
    "state": "TX",
    "city": "Sunset"
  },
  {
    "value": "76271",
    "label": "Tioga - TX 76271",
    "county": "Grayson County",
    "state": "TX",
    "city": "Tioga"
  },
  {
    "value": "76272",
    "label": "Valley View - TX 76272",
    "county": "Cooke County",
    "state": "TX",
    "city": "Valley View"
  },
  {
    "value": "76273",
    "label": "Whitesboro - TX 76273",
    "county": "Grayson County",
    "state": "TX",
    "city": "Whitesboro"
  },
  {
    "value": "76301",
    "label": "Wichita Falls - TX 76301",
    "county": "Wichita County",
    "state": "TX",
    "city": "Wichita Falls"
  },
  {
    "value": "76302",
    "label": "Wichita Falls - TX 76302",
    "county": "Wichita County",
    "state": "TX",
    "city": "Wichita Falls"
  },
  {
    "value": "76305",
    "label": "Wichita Falls - TX 76305",
    "county": "Wichita County",
    "state": "TX",
    "city": "Wichita Falls"
  },
  {
    "value": "76306",
    "label": "Wichita Falls - TX 76306",
    "county": "Wichita County",
    "state": "TX",
    "city": "Wichita Falls"
  },
  {
    "value": "76308",
    "label": "Wichita Falls - TX 76308",
    "county": "Wichita County",
    "state": "TX",
    "city": "Wichita Falls"
  },
  {
    "value": "76309",
    "label": "Wichita Falls - TX 76309",
    "county": "Wichita County",
    "state": "TX",
    "city": "Wichita Falls"
  },
  {
    "value": "76310",
    "label": "Wichita Falls - TX 76310",
    "county": "Wichita County",
    "state": "TX",
    "city": "Wichita Falls"
  },
  {
    "value": "76311",
    "label": "Sheppard AFB - TX 76311",
    "county": "Wichita County",
    "state": "TX",
    "city": "Sheppard AFB"
  },
  {
    "value": "76354",
    "label": "Burkburnett - TX 76354",
    "county": "Wichita County",
    "state": "TX",
    "city": "Burkburnett"
  },
  {
    "value": "76357",
    "label": "Byers - TX 76357",
    "county": "Clay County",
    "state": "TX",
    "city": "Byers"
  },
  {
    "value": "76360",
    "label": "Electra - TX 76360",
    "county": "Wichita County",
    "state": "TX",
    "city": "Electra"
  },
  {
    "value": "76363",
    "label": "Goree - TX 76363",
    "county": "Knox County",
    "state": "TX",
    "city": "Goree"
  },
  {
    "value": "76364",
    "label": "Harrold - TX 76364",
    "county": "Wilbarger County",
    "state": "TX",
    "city": "Harrold"
  },
  {
    "value": "76365",
    "label": "Henrietta - TX 76365",
    "county": "Clay County",
    "state": "TX",
    "city": "Henrietta"
  },
  {
    "value": "76366",
    "label": "Holliday - TX 76366",
    "county": "Archer County",
    "state": "TX",
    "city": "Holliday"
  },
  {
    "value": "76367",
    "label": "Iowa Park - TX 76367",
    "county": "Wichita County",
    "state": "TX",
    "city": "Iowa Park"
  },
  {
    "value": "76371",
    "label": "Munday - TX 76371",
    "county": "Knox County",
    "state": "TX",
    "city": "Munday"
  },
  {
    "value": "76372",
    "label": "Newcastle - TX 76372",
    "county": "Young County",
    "state": "TX",
    "city": "Newcastle"
  },
  {
    "value": "76373",
    "label": "Oklaunion - TX 76373",
    "county": "Wilbarger County",
    "state": "TX",
    "city": "Oklaunion"
  },
  {
    "value": "76374",
    "label": "Olney - TX 76374",
    "county": "Young County",
    "state": "TX",
    "city": "Olney"
  },
  {
    "value": "76377",
    "label": "Petrolia - TX 76377",
    "county": "Clay County",
    "state": "TX",
    "city": "Petrolia"
  },
  {
    "value": "76379",
    "label": "Scotland - TX 76379",
    "county": "Archer County",
    "state": "TX",
    "city": "Scotland"
  },
  {
    "value": "76380",
    "label": "Seymour - TX 76380",
    "county": "Baylor County",
    "state": "TX",
    "city": "Seymour"
  },
  {
    "value": "76384",
    "label": "Vernon - TX 76384",
    "county": "Wilbarger County",
    "state": "TX",
    "city": "Vernon"
  },
  {
    "value": "76388",
    "label": "Weinert - TX 76388",
    "county": "Haskell County",
    "state": "TX",
    "city": "Weinert"
  },
  {
    "value": "76389",
    "label": "Windthorst - TX 76389",
    "county": "Archer County",
    "state": "TX",
    "city": "Windthorst"
  },
  {
    "value": "76401",
    "label": "Stephenville - TX 76401",
    "county": "Erath County",
    "state": "TX",
    "city": "Stephenville"
  },
  {
    "value": "76424",
    "label": "Breckenridge - TX 76424",
    "county": "Stephens County",
    "state": "TX",
    "city": "Breckenridge"
  },
  {
    "value": "76426",
    "label": "Bridgeport - TX 76426",
    "county": "Wise County",
    "state": "TX",
    "city": "Bridgeport"
  },
  {
    "value": "76427",
    "label": "Bryson - TX 76427",
    "county": "Jack County",
    "state": "TX",
    "city": "Bryson"
  },
  {
    "value": "76429",
    "label": "Caddo - TX 76429",
    "county": "Stephens County",
    "state": "TX",
    "city": "Caddo"
  },
  {
    "value": "76430",
    "label": "Albany - TX 76430",
    "county": "Shackelford County",
    "state": "TX",
    "city": "Albany"
  },
  {
    "value": "76431",
    "label": "Chico - TX 76431",
    "county": "Wise County",
    "state": "TX",
    "city": "Chico"
  },
  {
    "value": "76432",
    "label": "Blanket - TX 76432",
    "county": "Brown County",
    "state": "TX",
    "city": "Blanket"
  },
  {
    "value": "76433",
    "label": "Bluff Dale - TX 76433",
    "county": "Erath County",
    "state": "TX",
    "city": "Bluff Dale"
  },
  {
    "value": "76435",
    "label": "Carbon - TX 76435",
    "county": "Eastland County",
    "state": "TX",
    "city": "Carbon"
  },
  {
    "value": "76436",
    "label": "Carlton - TX 76436",
    "county": "Hamilton County",
    "state": "TX",
    "city": "Carlton"
  },
  {
    "value": "76437",
    "label": "Cisco - TX 76437",
    "county": "Eastland County",
    "state": "TX",
    "city": "Cisco"
  },
  {
    "value": "76442",
    "label": "Comanche - TX 76442",
    "county": "Comanche County",
    "state": "TX",
    "city": "Comanche"
  },
  {
    "value": "76443",
    "label": "Cross Plains - TX 76443",
    "county": "Callahan County",
    "state": "TX",
    "city": "Cross Plains"
  },
  {
    "value": "76444",
    "label": "De Leon - TX 76444",
    "county": "Comanche County",
    "state": "TX",
    "city": "De Leon"
  },
  {
    "value": "76445",
    "label": "Desdemona - TX 76445",
    "county": "Eastland County",
    "state": "TX",
    "city": "Desdemona"
  },
  {
    "value": "76446",
    "label": "Dublin - TX 76446",
    "county": "Erath County",
    "state": "TX",
    "city": "Dublin"
  },
  {
    "value": "76448",
    "label": "Eastland - TX 76448",
    "county": "Eastland County",
    "state": "TX",
    "city": "Eastland"
  },
  {
    "value": "76449",
    "label": "Graford - TX 76449",
    "county": "Palo Pinto County",
    "state": "TX",
    "city": "Graford"
  },
  {
    "value": "76450",
    "label": "Graham - TX 76450",
    "county": "Young County",
    "state": "TX",
    "city": "Graham"
  },
  {
    "value": "76453",
    "label": "Gordon - TX 76453",
    "county": "Palo Pinto County",
    "state": "TX",
    "city": "Gordon"
  },
  {
    "value": "76454",
    "label": "Gorman - TX 76454",
    "county": "Eastland County",
    "state": "TX",
    "city": "Gorman"
  },
  {
    "value": "76455",
    "label": "Gustine - TX 76455",
    "county": "Comanche County",
    "state": "TX",
    "city": "Gustine"
  },
  {
    "value": "76457",
    "label": "Hico - TX 76457",
    "county": "Hamilton County",
    "state": "TX",
    "city": "Hico"
  },
  {
    "value": "76458",
    "label": "Jacksboro - TX 76458",
    "county": "Jack County",
    "state": "TX",
    "city": "Jacksboro"
  },
  {
    "value": "76459",
    "label": "Jermyn - TX 76459",
    "county": "Jack County",
    "state": "TX",
    "city": "Jermyn"
  },
  {
    "value": "76460",
    "label": "Loving - TX 76460",
    "county": "Young County",
    "state": "TX",
    "city": "Loving"
  },
  {
    "value": "76462",
    "label": "Lipan - TX 76462",
    "county": "Hood County",
    "state": "TX",
    "city": "Lipan"
  },
  {
    "value": "76463",
    "label": "Mingus - TX 76463",
    "county": "Palo Pinto County",
    "state": "TX",
    "city": "Mingus"
  },
  {
    "value": "76464",
    "label": "Moran - TX 76464",
    "county": "Shackelford County",
    "state": "TX",
    "city": "Moran"
  },
  {
    "value": "76470",
    "label": "Ranger - TX 76470",
    "county": "Eastland County",
    "state": "TX",
    "city": "Ranger"
  },
  {
    "value": "76471",
    "label": "Rising Star - TX 76471",
    "county": "Eastland County",
    "state": "TX",
    "city": "Rising Star"
  },
  {
    "value": "76472",
    "label": "Santo - TX 76472",
    "county": "Palo Pinto County",
    "state": "TX",
    "city": "Santo"
  },
  {
    "value": "76474",
    "label": "Sidney - TX 76474",
    "county": "Comanche County",
    "state": "TX",
    "city": "Sidney"
  },
  {
    "value": "76475",
    "label": "Strawn - TX 76475",
    "county": "Palo Pinto County",
    "state": "TX",
    "city": "Strawn"
  },
  {
    "value": "76476",
    "label": "Tolar - TX 76476",
    "county": "Hood County",
    "state": "TX",
    "city": "Tolar"
  },
  {
    "value": "76481",
    "label": "South Bend - TX 76481",
    "county": "Young County",
    "state": "TX",
    "city": "South Bend"
  },
  {
    "value": "76483",
    "label": "Throckmorton - TX 76483",
    "county": "Throckmorton County",
    "state": "TX",
    "city": "Throckmorton"
  },
  {
    "value": "76484",
    "label": "Palo Pinto - TX 76484",
    "county": "Palo Pinto County",
    "state": "TX",
    "city": "Palo Pinto"
  },
  {
    "value": "76486",
    "label": "Perrin - TX 76486",
    "county": "Jack County",
    "state": "TX",
    "city": "Perrin"
  },
  {
    "value": "76487",
    "label": "Poolville - TX 76487",
    "county": "Parker County",
    "state": "TX",
    "city": "Poolville"
  },
  {
    "value": "76490",
    "label": "Whitt - TX 76490",
    "county": "Parker County",
    "state": "TX",
    "city": "Whitt"
  },
  {
    "value": "76491",
    "label": "Woodson - TX 76491",
    "county": "Throckmorton County",
    "state": "TX",
    "city": "Woodson"
  },
  {
    "value": "76501",
    "label": "Temple - TX 76501",
    "county": "Bell County",
    "state": "TX",
    "city": "Temple"
  },
  {
    "value": "76502",
    "label": "Temple - TX 76502",
    "county": "Bell County",
    "state": "TX",
    "city": "Temple"
  },
  {
    "value": "76504",
    "label": "Temple - TX 76504",
    "county": "Bell County",
    "state": "TX",
    "city": "Temple"
  },
  {
    "value": "76511",
    "label": "Bartlett - TX 76511",
    "county": "Williamson County",
    "state": "TX",
    "city": "Bartlett"
  },
  {
    "value": "76513",
    "label": "Belton - TX 76513",
    "county": "Bell County",
    "state": "TX",
    "city": "Belton"
  },
  {
    "value": "76518",
    "label": "Buckholts - TX 76518",
    "county": "Milam County",
    "state": "TX",
    "city": "Buckholts"
  },
  {
    "value": "76519",
    "label": "Burlington - TX 76519",
    "county": "Bell County",
    "state": "TX",
    "city": "Burlington"
  },
  {
    "value": "76520",
    "label": "Cameron - TX 76520",
    "county": "Milam County",
    "state": "TX",
    "city": "Cameron"
  },
  {
    "value": "76522",
    "label": "Copperas Cove - TX 76522",
    "county": "Coryell County",
    "state": "TX",
    "city": "Copperas Cove"
  },
  {
    "value": "76524",
    "label": "Eddy - TX 76524",
    "county": "McLennan County",
    "state": "TX",
    "city": "Eddy"
  },
  {
    "value": "76525",
    "label": "Evant - TX 76525",
    "county": "Coryell County",
    "state": "TX",
    "city": "Evant"
  },
  {
    "value": "76527",
    "label": "Florence - TX 76527",
    "county": "Williamson County",
    "state": "TX",
    "city": "Florence"
  },
  {
    "value": "76528",
    "label": "Gatesville - TX 76528",
    "county": "Coryell County",
    "state": "TX",
    "city": "Gatesville"
  },
  {
    "value": "76530",
    "label": "Granger - TX 76530",
    "county": "Williamson County",
    "state": "TX",
    "city": "Granger"
  },
  {
    "value": "76531",
    "label": "Hamilton - TX 76531",
    "county": "Hamilton County",
    "state": "TX",
    "city": "Hamilton"
  },
  {
    "value": "76534",
    "label": "Holland - TX 76534",
    "county": "Bell County",
    "state": "TX",
    "city": "Holland"
  },
  {
    "value": "76537",
    "label": "Jarrell - TX 76537",
    "county": "Williamson County",
    "state": "TX",
    "city": "Jarrell"
  },
  {
    "value": "76538",
    "label": "Jonesboro - TX 76538",
    "county": "Coryell County",
    "state": "TX",
    "city": "Jonesboro"
  },
  {
    "value": "76539",
    "label": "Kempner - TX 76539",
    "county": "Lampasas County",
    "state": "TX",
    "city": "Kempner"
  },
  {
    "value": "76541",
    "label": "Killeen - TX 76541",
    "county": "Bell County",
    "state": "TX",
    "city": "Killeen"
  },
  {
    "value": "76542",
    "label": "Killeen - TX 76542",
    "county": "Bell County",
    "state": "TX",
    "city": "Killeen"
  },
  {
    "value": "76543",
    "label": "Killeen - TX 76543",
    "county": "Bell County",
    "state": "TX",
    "city": "Killeen"
  },
  {
    "value": "76544",
    "label": "Fort Hood - TX 76544",
    "county": "Bell County",
    "state": "TX",
    "city": "Fort Hood"
  },
  {
    "value": "76548",
    "label": "Harker Heights - TX 76548",
    "county": "Bell County",
    "state": "TX",
    "city": "Harker Heights"
  },
  {
    "value": "76549",
    "label": "Killeen - TX 76549",
    "county": "Bell County",
    "state": "TX",
    "city": "Killeen"
  },
  {
    "value": "76550",
    "label": "Lampasas - TX 76550",
    "county": "Lampasas County",
    "state": "TX",
    "city": "Lampasas"
  },
  {
    "value": "76554",
    "label": "Little River Academy - TX 76554",
    "county": "Bell County",
    "state": "TX",
    "city": "Little River Academy"
  },
  {
    "value": "76556",
    "label": "Milano - TX 76556",
    "county": "Milam County",
    "state": "TX",
    "city": "Milano"
  },
  {
    "value": "76557",
    "label": "Moody - TX 76557",
    "county": "McLennan County",
    "state": "TX",
    "city": "Moody"
  },
  {
    "value": "76559",
    "label": "Nolanville - TX 76559",
    "county": "Bell County",
    "state": "TX",
    "city": "Nolanville"
  },
  {
    "value": "76561",
    "label": "Oglesby - TX 76561",
    "county": "Coryell County",
    "state": "TX",
    "city": "Oglesby"
  },
  {
    "value": "76565",
    "label": "Pottsville - TX 76565",
    "county": "Hamilton County",
    "state": "TX",
    "city": "Pottsville"
  },
  {
    "value": "76566",
    "label": "Purmela - TX 76566",
    "county": "Coryell County",
    "state": "TX",
    "city": "Purmela"
  },
  {
    "value": "76567",
    "label": "Rockdale - TX 76567",
    "county": "Milam County",
    "state": "TX",
    "city": "Rockdale"
  },
  {
    "value": "76569",
    "label": "Rogers - TX 76569",
    "county": "Bell County",
    "state": "TX",
    "city": "Rogers"
  },
  {
    "value": "76570",
    "label": "Rosebud - TX 76570",
    "county": "Falls County",
    "state": "TX",
    "city": "Rosebud"
  },
  {
    "value": "76571",
    "label": "Salado - TX 76571",
    "county": "Bell County",
    "state": "TX",
    "city": "Salado"
  },
  {
    "value": "76574",
    "label": "Taylor - TX 76574",
    "county": "Williamson County",
    "state": "TX",
    "city": "Taylor"
  },
  {
    "value": "76577",
    "label": "Thorndale - TX 76577",
    "county": "Milam County",
    "state": "TX",
    "city": "Thorndale"
  },
  {
    "value": "76578",
    "label": "Thrall - TX 76578",
    "county": "Williamson County",
    "state": "TX",
    "city": "Thrall"
  },
  {
    "value": "76579",
    "label": "Troy - TX 76579",
    "county": "Bell County",
    "state": "TX",
    "city": "Troy"
  },
  {
    "value": "76621",
    "label": "Abbott - TX 76621",
    "county": "Hill County",
    "state": "TX",
    "city": "Abbott"
  },
  {
    "value": "76622",
    "label": "Aquilla - TX 76622",
    "county": "Hill County",
    "state": "TX",
    "city": "Aquilla"
  },
  {
    "value": "76624",
    "label": "Axtell - TX 76624",
    "county": "McLennan County",
    "state": "TX",
    "city": "Axtell"
  },
  {
    "value": "76626",
    "label": "Blooming Grove - TX 76626",
    "county": "Navarro County",
    "state": "TX",
    "city": "Blooming Grove"
  },
  {
    "value": "76627",
    "label": "Blum - TX 76627",
    "county": "Hill County",
    "state": "TX",
    "city": "Blum"
  },
  {
    "value": "76629",
    "label": "Bremond - TX 76629",
    "county": "Robertson County",
    "state": "TX",
    "city": "Bremond"
  },
  {
    "value": "76630",
    "label": "Bruceville - TX 76630",
    "county": "McLennan County",
    "state": "TX",
    "city": "Bruceville"
  },
  {
    "value": "76631",
    "label": "Bynum - TX 76631",
    "county": "Hill County",
    "state": "TX",
    "city": "Bynum"
  },
  {
    "value": "76632",
    "label": "Chilton - TX 76632",
    "county": "Falls County",
    "state": "TX",
    "city": "Chilton"
  },
  {
    "value": "76633",
    "label": "China Spring - TX 76633",
    "county": "McLennan County",
    "state": "TX",
    "city": "China Spring"
  },
  {
    "value": "76634",
    "label": "Clifton - TX 76634",
    "county": "Bosque County",
    "state": "TX",
    "city": "Clifton"
  },
  {
    "value": "76635",
    "label": "Coolidge - TX 76635",
    "county": "Limestone County",
    "state": "TX",
    "city": "Coolidge"
  },
  {
    "value": "76636",
    "label": "Covington - TX 76636",
    "county": "Hill County",
    "state": "TX",
    "city": "Covington"
  },
  {
    "value": "76637",
    "label": "Cranfills Gap - TX 76637",
    "county": "Bosque County",
    "state": "TX",
    "city": "Cranfills Gap"
  },
  {
    "value": "76638",
    "label": "Crawford - TX 76638",
    "county": "McLennan County",
    "state": "TX",
    "city": "Crawford"
  },
  {
    "value": "76639",
    "label": "Dawson - TX 76639",
    "county": "Navarro County",
    "state": "TX",
    "city": "Dawson"
  },
  {
    "value": "76640",
    "label": "Elm Mott - TX 76640",
    "county": "McLennan County",
    "state": "TX",
    "city": "Elm Mott"
  },
  {
    "value": "76641",
    "label": "Frost - TX 76641",
    "county": "Navarro County",
    "state": "TX",
    "city": "Frost"
  },
  {
    "value": "76642",
    "label": "Groesbeck - TX 76642",
    "county": "Limestone County",
    "state": "TX",
    "city": "Groesbeck"
  },
  {
    "value": "76643",
    "label": "Hewitt - TX 76643",
    "county": "McLennan County",
    "state": "TX",
    "city": "Hewitt"
  },
  {
    "value": "76645",
    "label": "Hillsboro - TX 76645",
    "county": "Hill County",
    "state": "TX",
    "city": "Hillsboro"
  },
  {
    "value": "76648",
    "label": "Hubbard - TX 76648",
    "county": "Hill County",
    "state": "TX",
    "city": "Hubbard"
  },
  {
    "value": "76649",
    "label": "Iredell - TX 76649",
    "county": "Bosque County",
    "state": "TX",
    "city": "Iredell"
  },
  {
    "value": "76651",
    "label": "Italy - TX 76651",
    "county": "Ellis County",
    "state": "TX",
    "city": "Italy"
  },
  {
    "value": "76652",
    "label": "Kopperl - TX 76652",
    "county": "Bosque County",
    "state": "TX",
    "city": "Kopperl"
  },
  {
    "value": "76653",
    "label": "Kosse - TX 76653",
    "county": "Limestone County",
    "state": "TX",
    "city": "Kosse"
  },
  {
    "value": "76655",
    "label": "Lorena - TX 76655",
    "county": "McLennan County",
    "state": "TX",
    "city": "Lorena"
  },
  {
    "value": "76656",
    "label": "Lott - TX 76656",
    "county": "Falls County",
    "state": "TX",
    "city": "Lott"
  },
  {
    "value": "76657",
    "label": "Mc Gregor - TX 76657",
    "county": "McLennan County",
    "state": "TX",
    "city": "Mc Gregor"
  },
  {
    "value": "76660",
    "label": "Malone - TX 76660",
    "county": "Hill County",
    "state": "TX",
    "city": "Malone"
  },
  {
    "value": "76661",
    "label": "Marlin - TX 76661",
    "county": "Falls County",
    "state": "TX",
    "city": "Marlin"
  },
  {
    "value": "76664",
    "label": "Mart - TX 76664",
    "county": "McLennan County",
    "state": "TX",
    "city": "Mart"
  },
  {
    "value": "76665",
    "label": "Meridian - TX 76665",
    "county": "Bosque County",
    "state": "TX",
    "city": "Meridian"
  },
  {
    "value": "76666",
    "label": "Mertens - TX 76666",
    "county": "Hill County",
    "state": "TX",
    "city": "Mertens"
  },
  {
    "value": "76667",
    "label": "Mexia - TX 76667",
    "county": "Limestone County",
    "state": "TX",
    "city": "Mexia"
  },
  {
    "value": "76670",
    "label": "Milford - TX 76670",
    "county": "Ellis County",
    "state": "TX",
    "city": "Milford"
  },
  {
    "value": "76671",
    "label": "Morgan - TX 76671",
    "county": "Bosque County",
    "state": "TX",
    "city": "Morgan"
  },
  {
    "value": "76673",
    "label": "Mount Calm - TX 76673",
    "county": "Hill County",
    "state": "TX",
    "city": "Mount Calm"
  },
  {
    "value": "76676",
    "label": "Penelope - TX 76676",
    "county": "Hill County",
    "state": "TX",
    "city": "Penelope"
  },
  {
    "value": "76678",
    "label": "Prairie Hill - TX 76678",
    "county": "Limestone County",
    "state": "TX",
    "city": "Prairie Hill"
  },
  {
    "value": "76679",
    "label": "Purdon - TX 76679",
    "county": "Navarro County",
    "state": "TX",
    "city": "Purdon"
  },
  {
    "value": "76680",
    "label": "Reagan - TX 76680",
    "county": "Falls County",
    "state": "TX",
    "city": "Reagan"
  },
  {
    "value": "76681",
    "label": "Richland - TX 76681",
    "county": "Navarro County",
    "state": "TX",
    "city": "Richland"
  },
  {
    "value": "76682",
    "label": "Riesel - TX 76682",
    "county": "McLennan County",
    "state": "TX",
    "city": "Riesel"
  },
  {
    "value": "76685",
    "label": "Satin - TX 76685",
    "county": "Falls County",
    "state": "TX",
    "city": "Satin"
  },
  {
    "value": "76687",
    "label": "Thornton - TX 76687",
    "county": "Limestone County",
    "state": "TX",
    "city": "Thornton"
  },
  {
    "value": "76689",
    "label": "Valley Mills - TX 76689",
    "county": "Bosque County",
    "state": "TX",
    "city": "Valley Mills"
  },
  {
    "value": "76690",
    "label": "Walnut Springs - TX 76690",
    "county": "Bosque County",
    "state": "TX",
    "city": "Walnut Springs"
  },
  {
    "value": "76691",
    "label": "West - TX 76691",
    "county": "McLennan County",
    "state": "TX",
    "city": "West"
  },
  {
    "value": "76692",
    "label": "Whitney - TX 76692",
    "county": "Hill County",
    "state": "TX",
    "city": "Whitney"
  },
  {
    "value": "76693",
    "label": "Wortham - TX 76693",
    "county": "Freestone County",
    "state": "TX",
    "city": "Wortham"
  },
  {
    "value": "76701",
    "label": "Waco - TX 76701",
    "county": "McLennan County",
    "state": "TX",
    "city": "Waco"
  },
  {
    "value": "76704",
    "label": "Waco - TX 76704",
    "county": "McLennan County",
    "state": "TX",
    "city": "Waco"
  },
  {
    "value": "76705",
    "label": "Waco - TX 76705",
    "county": "McLennan County",
    "state": "TX",
    "city": "Waco"
  },
  {
    "value": "76706",
    "label": "Waco - TX 76706",
    "county": "McLennan County",
    "state": "TX",
    "city": "Waco"
  },
  {
    "value": "76707",
    "label": "Waco - TX 76707",
    "county": "McLennan County",
    "state": "TX",
    "city": "Waco"
  },
  {
    "value": "76708",
    "label": "Waco - TX 76708",
    "county": "McLennan County",
    "state": "TX",
    "city": "Waco"
  },
  {
    "value": "76710",
    "label": "Waco - TX 76710",
    "county": "McLennan County",
    "state": "TX",
    "city": "Waco"
  },
  {
    "value": "76711",
    "label": "Waco - TX 76711",
    "county": "McLennan County",
    "state": "TX",
    "city": "Waco"
  },
  {
    "value": "76712",
    "label": "Woodway - TX 76712",
    "county": "McLennan County",
    "state": "TX",
    "city": "Woodway"
  },
  {
    "value": "76798",
    "label": "Waco - TX 76798",
    "county": "McLennan County",
    "state": "TX",
    "city": "Waco"
  },
  {
    "value": "76801",
    "label": "Brownwood - TX 76801",
    "county": "Brown County",
    "state": "TX",
    "city": "Brownwood"
  },
  {
    "value": "76802",
    "label": "Early - TX 76802",
    "county": "Brown County",
    "state": "TX",
    "city": "Early"
  },
  {
    "value": "76820",
    "label": "Art - TX 76820",
    "county": "Mason County",
    "state": "TX",
    "city": "Art"
  },
  {
    "value": "76821",
    "label": "Ballinger - TX 76821",
    "county": "Runnels County",
    "state": "TX",
    "city": "Ballinger"
  },
  {
    "value": "76823",
    "label": "Bangs - TX 76823",
    "county": "Brown County",
    "state": "TX",
    "city": "Bangs"
  },
  {
    "value": "76825",
    "label": "Brady - TX 76825",
    "county": "McCulloch County",
    "state": "TX",
    "city": "Brady"
  },
  {
    "value": "76827",
    "label": "Brookesmith - TX 76827",
    "county": "Brown County",
    "state": "TX",
    "city": "Brookesmith"
  },
  {
    "value": "76828",
    "label": "Burkett - TX 76828",
    "county": "Coleman County",
    "state": "TX",
    "city": "Burkett"
  },
  {
    "value": "76831",
    "label": "Castell - TX 76831",
    "county": "Llano County",
    "state": "TX",
    "city": "Castell"
  },
  {
    "value": "76832",
    "label": "Cherokee - TX 76832",
    "county": "San Saba County",
    "state": "TX",
    "city": "Cherokee"
  },
  {
    "value": "76834",
    "label": "Coleman - TX 76834",
    "county": "Coleman County",
    "state": "TX",
    "city": "Coleman"
  },
  {
    "value": "76836",
    "label": "Doole - TX 76836",
    "county": "McCulloch County",
    "state": "TX",
    "city": "Doole"
  },
  {
    "value": "76837",
    "label": "Eden - TX 76837",
    "county": "Concho County",
    "state": "TX",
    "city": "Eden"
  },
  {
    "value": "76841",
    "label": "Fort Mc Kavett - TX 76841",
    "county": "Menard County",
    "state": "TX",
    "city": "Fort Mc Kavett"
  },
  {
    "value": "76842",
    "label": "Fredonia - TX 76842",
    "county": "Mason County",
    "state": "TX",
    "city": "Fredonia"
  },
  {
    "value": "76844",
    "label": "Goldthwaite - TX 76844",
    "county": "Mills County",
    "state": "TX",
    "city": "Goldthwaite"
  },
  {
    "value": "76845",
    "label": "Gouldbusk - TX 76845",
    "county": "Coleman County",
    "state": "TX",
    "city": "Gouldbusk"
  },
  {
    "value": "76848",
    "label": "Hext - TX 76848",
    "county": "Menard County",
    "state": "TX",
    "city": "Hext"
  },
  {
    "value": "76849",
    "label": "Junction - TX 76849",
    "county": "Kimble County",
    "state": "TX",
    "city": "Junction"
  },
  {
    "value": "76852",
    "label": "Lohn - TX 76852",
    "county": "McCulloch County",
    "state": "TX",
    "city": "Lohn"
  },
  {
    "value": "76853",
    "label": "Lometa - TX 76853",
    "county": "Lampasas County",
    "state": "TX",
    "city": "Lometa"
  },
  {
    "value": "76854",
    "label": "London - TX 76854",
    "county": "Kimble County",
    "state": "TX",
    "city": "London"
  },
  {
    "value": "76856",
    "label": "Mason - TX 76856",
    "county": "Mason County",
    "state": "TX",
    "city": "Mason"
  },
  {
    "value": "76857",
    "label": "May - TX 76857",
    "county": "Brown County",
    "state": "TX",
    "city": "May"
  },
  {
    "value": "76858",
    "label": "Melvin - TX 76858",
    "county": "McCulloch County",
    "state": "TX",
    "city": "Melvin"
  },
  {
    "value": "76859",
    "label": "Menard - TX 76859",
    "county": "Menard County",
    "state": "TX",
    "city": "Menard"
  },
  {
    "value": "76861",
    "label": "Miles - TX 76861",
    "county": "Runnels County",
    "state": "TX",
    "city": "Miles"
  },
  {
    "value": "76862",
    "label": "Millersview - TX 76862",
    "county": "Concho County",
    "state": "TX",
    "city": "Millersview"
  },
  {
    "value": "76864",
    "label": "Mullin - TX 76864",
    "county": "Mills County",
    "state": "TX",
    "city": "Mullin"
  },
  {
    "value": "76865",
    "label": "Norton - TX 76865",
    "county": "Runnels County",
    "state": "TX",
    "city": "Norton"
  },
  {
    "value": "76866",
    "label": "Paint Rock - TX 76866",
    "county": "Concho County",
    "state": "TX",
    "city": "Paint Rock"
  },
  {
    "value": "76869",
    "label": "Pontotoc - TX 76869",
    "county": "Mason County",
    "state": "TX",
    "city": "Pontotoc"
  },
  {
    "value": "76870",
    "label": "Priddy - TX 76870",
    "county": "Mills County",
    "state": "TX",
    "city": "Priddy"
  },
  {
    "value": "76871",
    "label": "Richland Springs - TX 76871",
    "county": "San Saba County",
    "state": "TX",
    "city": "Richland Springs"
  },
  {
    "value": "76872",
    "label": "Rochelle - TX 76872",
    "county": "McCulloch County",
    "state": "TX",
    "city": "Rochelle"
  },
  {
    "value": "76873",
    "label": "Rockwood - TX 76873",
    "county": "Coleman County",
    "state": "TX",
    "city": "Rockwood"
  },
  {
    "value": "76874",
    "label": "Roosevelt - TX 76874",
    "county": "Kimble County",
    "state": "TX",
    "city": "Roosevelt"
  },
  {
    "value": "76875",
    "label": "Rowena - TX 76875",
    "county": "Runnels County",
    "state": "TX",
    "city": "Rowena"
  },
  {
    "value": "76877",
    "label": "San Saba - TX 76877",
    "county": "San Saba County",
    "state": "TX",
    "city": "San Saba"
  },
  {
    "value": "76878",
    "label": "Santa Anna - TX 76878",
    "county": "Coleman County",
    "state": "TX",
    "city": "Santa Anna"
  },
  {
    "value": "76882",
    "label": "Talpa - TX 76882",
    "county": "Coleman County",
    "state": "TX",
    "city": "Talpa"
  },
  {
    "value": "76883",
    "label": "Telegraph - TX 76883",
    "county": "Kimble County",
    "state": "TX",
    "city": "Telegraph"
  },
  {
    "value": "76884",
    "label": "Valera - TX 76884",
    "county": "Coleman County",
    "state": "TX",
    "city": "Valera"
  },
  {
    "value": "76885",
    "label": "Valley Spring - TX 76885",
    "county": "Llano County",
    "state": "TX",
    "city": "Valley Spring"
  },
  {
    "value": "76887",
    "label": "Voca - TX 76887",
    "county": "McCulloch County",
    "state": "TX",
    "city": "Voca"
  },
  {
    "value": "76888",
    "label": "Voss - TX 76888",
    "county": "Coleman County",
    "state": "TX",
    "city": "Voss"
  },
  {
    "value": "76890",
    "label": "Zephyr - TX 76890",
    "county": "Brown County",
    "state": "TX",
    "city": "Zephyr"
  },
  {
    "value": "76901",
    "label": "San Angelo - TX 76901",
    "county": "Tom Green County",
    "state": "TX",
    "city": "San Angelo"
  },
  {
    "value": "76903",
    "label": "San Angelo - TX 76903",
    "county": "Tom Green County",
    "state": "TX",
    "city": "San Angelo"
  },
  {
    "value": "76904",
    "label": "San Angelo - TX 76904",
    "county": "Tom Green County",
    "state": "TX",
    "city": "San Angelo"
  },
  {
    "value": "76905",
    "label": "San Angelo - TX 76905",
    "county": "Tom Green County",
    "state": "TX",
    "city": "San Angelo"
  },
  {
    "value": "76908",
    "label": "Goodfellow AFB - TX 76908",
    "county": "Tom Green County",
    "state": "TX",
    "city": "Goodfellow AFB"
  },
  {
    "value": "76932",
    "label": "Big Lake - TX 76932",
    "county": "Reagan County",
    "state": "TX",
    "city": "Big Lake"
  },
  {
    "value": "76933",
    "label": "Bronte - TX 76933",
    "county": "Coke County",
    "state": "TX",
    "city": "Bronte"
  },
  {
    "value": "76934",
    "label": "Carlsbad - TX 76934",
    "county": "Tom Green County",
    "state": "TX",
    "city": "Carlsbad"
  },
  {
    "value": "76935",
    "label": "Christoval - TX 76935",
    "county": "Tom Green County",
    "state": "TX",
    "city": "Christoval"
  },
  {
    "value": "76936",
    "label": "Eldorado - TX 76936",
    "county": "Schleicher County",
    "state": "TX",
    "city": "Eldorado"
  },
  {
    "value": "76937",
    "label": "Eola - TX 76937",
    "county": "Tom Green County",
    "state": "TX",
    "city": "Eola"
  },
  {
    "value": "76940",
    "label": "Mereta - TX 76940",
    "county": "Tom Green County",
    "state": "TX",
    "city": "Mereta"
  },
  {
    "value": "76941",
    "label": "Mertzon - TX 76941",
    "county": "Irion County",
    "state": "TX",
    "city": "Mertzon"
  },
  {
    "value": "76943",
    "label": "Ozona - TX 76943",
    "county": "Crockett County",
    "state": "TX",
    "city": "Ozona"
  },
  {
    "value": "76945",
    "label": "Robert Lee - TX 76945",
    "county": "Coke County",
    "state": "TX",
    "city": "Robert Lee"
  },
  {
    "value": "76949",
    "label": "Silver - TX 76949",
    "county": "Coke County",
    "state": "TX",
    "city": "Silver"
  },
  {
    "value": "76950",
    "label": "Sonora - TX 76950",
    "county": "Sutton County",
    "state": "TX",
    "city": "Sonora"
  },
  {
    "value": "76951",
    "label": "Sterling City - TX 76951",
    "county": "Sterling County",
    "state": "TX",
    "city": "Sterling City"
  },
  {
    "value": "76955",
    "label": "Vancourt - TX 76955",
    "county": "Tom Green County",
    "state": "TX",
    "city": "Vancourt"
  },
  {
    "value": "76957",
    "label": "Wall - TX 76957",
    "county": "Tom Green County",
    "state": "TX",
    "city": "Wall"
  },
  {
    "value": "77002",
    "label": "Houston - TX 77002",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77003",
    "label": "Houston - TX 77003",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77004",
    "label": "Houston - TX 77004",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77005",
    "label": "Houston - TX 77005",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77006",
    "label": "Houston - TX 77006",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77007",
    "label": "Houston - TX 77007",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77008",
    "label": "Houston - TX 77008",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77009",
    "label": "Houston - TX 77009",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77010",
    "label": "Houston - TX 77010",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77011",
    "label": "Houston - TX 77011",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77012",
    "label": "Houston - TX 77012",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77013",
    "label": "Houston - TX 77013",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77014",
    "label": "Houston - TX 77014",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77015",
    "label": "Houston - TX 77015",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77016",
    "label": "Houston - TX 77016",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77017",
    "label": "Houston - TX 77017",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77018",
    "label": "Houston - TX 77018",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77019",
    "label": "Houston - TX 77019",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77020",
    "label": "Houston - TX 77020",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77021",
    "label": "Houston - TX 77021",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77022",
    "label": "Houston - TX 77022",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77023",
    "label": "Houston - TX 77023",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77024",
    "label": "Houston - TX 77024",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77025",
    "label": "Houston - TX 77025",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77026",
    "label": "Houston - TX 77026",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77027",
    "label": "Houston - TX 77027",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77028",
    "label": "Houston - TX 77028",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77029",
    "label": "Houston - TX 77029",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77030",
    "label": "Houston - TX 77030",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77031",
    "label": "Houston - TX 77031",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77032",
    "label": "Houston - TX 77032",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77033",
    "label": "Houston - TX 77033",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77034",
    "label": "Houston - TX 77034",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77035",
    "label": "Houston - TX 77035",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77036",
    "label": "Houston - TX 77036",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77037",
    "label": "Houston - TX 77037",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77038",
    "label": "Houston - TX 77038",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77039",
    "label": "Houston - TX 77039",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77040",
    "label": "Houston - TX 77040",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77041",
    "label": "Houston - TX 77041",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77042",
    "label": "Houston - TX 77042",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77043",
    "label": "Houston - TX 77043",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77044",
    "label": "Houston - TX 77044",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77045",
    "label": "Houston - TX 77045",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77046",
    "label": "Houston - TX 77046",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77047",
    "label": "Houston - TX 77047",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77048",
    "label": "Houston - TX 77048",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77049",
    "label": "Houston - TX 77049",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77050",
    "label": "Houston - TX 77050",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77051",
    "label": "Houston - TX 77051",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77053",
    "label": "Houston - TX 77053",
    "county": "Fort Bend County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77054",
    "label": "Houston - TX 77054",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77055",
    "label": "Houston - TX 77055",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77056",
    "label": "Houston - TX 77056",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77057",
    "label": "Houston - TX 77057",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77058",
    "label": "Houston - TX 77058",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77059",
    "label": "Houston - TX 77059",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77060",
    "label": "Houston - TX 77060",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77061",
    "label": "Houston - TX 77061",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77062",
    "label": "Houston - TX 77062",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77063",
    "label": "Houston - TX 77063",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77064",
    "label": "Houston - TX 77064",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77065",
    "label": "Houston - TX 77065",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77066",
    "label": "Houston - TX 77066",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77067",
    "label": "Houston - TX 77067",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77068",
    "label": "Houston - TX 77068",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77069",
    "label": "Houston - TX 77069",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77070",
    "label": "Houston - TX 77070",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77071",
    "label": "Houston - TX 77071",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77072",
    "label": "Houston - TX 77072",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77073",
    "label": "Houston - TX 77073",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77074",
    "label": "Houston - TX 77074",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77075",
    "label": "Houston - TX 77075",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77076",
    "label": "Houston - TX 77076",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77077",
    "label": "Houston - TX 77077",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77078",
    "label": "Houston - TX 77078",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77079",
    "label": "Houston - TX 77079",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77080",
    "label": "Houston - TX 77080",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77081",
    "label": "Houston - TX 77081",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77082",
    "label": "Houston - TX 77082",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77083",
    "label": "Houston - TX 77083",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77084",
    "label": "Houston - TX 77084",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77085",
    "label": "Houston - TX 77085",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77086",
    "label": "Houston - TX 77086",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77087",
    "label": "Houston - TX 77087",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77088",
    "label": "Houston - TX 77088",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77089",
    "label": "Houston - TX 77089",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77090",
    "label": "Houston - TX 77090",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77091",
    "label": "Houston - TX 77091",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77092",
    "label": "Houston - TX 77092",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77093",
    "label": "Houston - TX 77093",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77094",
    "label": "Houston - TX 77094",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77095",
    "label": "Houston - TX 77095",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77096",
    "label": "Houston - TX 77096",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77097",
    "label": "Houston - TX 77097",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77098",
    "label": "Houston - TX 77098",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77099",
    "label": "Houston - TX 77099",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77201",
    "label": "Houston - TX 77201",
    "county": "Harris County",
    "state": "TX",
    "city": "Houston"
  },
  {
    "value": "77301",
    "label": "Conroe - TX 77301",
    "county": "Montgomery County",
    "state": "TX",
    "city": "Conroe"
  },
  {
    "value": "77302",
    "label": "Conroe - TX 77302",
    "county": "Montgomery County",
    "state": "TX",
    "city": "Conroe"
  },
  {
    "value": "77303",
    "label": "Conroe - TX 77303",
    "county": "Montgomery County",
    "state": "TX",
    "city": "Conroe"
  },
  {
    "value": "77304",
    "label": "Conroe - TX 77304",
    "county": "Montgomery County",
    "state": "TX",
    "city": "Conroe"
  },
  {
    "value": "77306",
    "label": "Conroe - TX 77306",
    "county": "Montgomery County",
    "state": "TX",
    "city": "Conroe"
  },
  {
    "value": "77315",
    "label": "North Houston - TX 77315",
    "county": "Harris County",
    "state": "TX",
    "city": "North Houston"
  },
  {
    "value": "77316",
    "label": "Montgomery - TX 77316",
    "county": "Montgomery County",
    "state": "TX",
    "city": "Montgomery"
  },
  {
    "value": "77318",
    "label": "Willis - TX 77318",
    "county": "Montgomery County",
    "state": "TX",
    "city": "Willis"
  },
  {
    "value": "77320",
    "label": "Huntsville - TX 77320",
    "county": "Walker County",
    "state": "TX",
    "city": "Huntsville"
  },
  {
    "value": "77327",
    "label": "Cleveland - TX 77327",
    "county": "Liberty County",
    "state": "TX",
    "city": "Cleveland"
  },
  {
    "value": "77328",
    "label": "Cleveland - TX 77328",
    "county": "San Jacinto County",
    "state": "TX",
    "city": "Cleveland"
  },
  {
    "value": "77331",
    "label": "Coldspring - TX 77331",
    "county": "San Jacinto County",
    "state": "TX",
    "city": "Coldspring"
  },
  {
    "value": "77335",
    "label": "Goodrich - TX 77335",
    "county": "Polk County",
    "state": "TX",
    "city": "Goodrich"
  },
  {
    "value": "77336",
    "label": "Huffman - TX 77336",
    "county": "Harris County",
    "state": "TX",
    "city": "Huffman"
  },
  {
    "value": "77338",
    "label": "Humble - TX 77338",
    "county": "Harris County",
    "state": "TX",
    "city": "Humble"
  },
  {
    "value": "77339",
    "label": "Kingwood - TX 77339",
    "county": "Harris County",
    "state": "TX",
    "city": "Kingwood"
  },
  {
    "value": "77340",
    "label": "Huntsville - TX 77340",
    "county": "Walker County",
    "state": "TX",
    "city": "Huntsville"
  },
  {
    "value": "77345",
    "label": "Kingwood - TX 77345",
    "county": "Harris County",
    "state": "TX",
    "city": "Kingwood"
  },
  {
    "value": "77346",
    "label": "Humble - TX 77346",
    "county": "Harris County",
    "state": "TX",
    "city": "Humble"
  },
  {
    "value": "77351",
    "label": "Livingston - TX 77351",
    "county": "Polk County",
    "state": "TX",
    "city": "Livingston"
  },
  {
    "value": "77354",
    "label": "Magnolia - TX 77354",
    "county": "Montgomery County",
    "state": "TX",
    "city": "Magnolia"
  },
  {
    "value": "77355",
    "label": "Magnolia - TX 77355",
    "county": "Montgomery County",
    "state": "TX",
    "city": "Magnolia"
  },
  {
    "value": "77356",
    "label": "Montgomery - TX 77356",
    "county": "Montgomery County",
    "state": "TX",
    "city": "Montgomery"
  },
  {
    "value": "77357",
    "label": "New Caney - TX 77357",
    "county": "Montgomery County",
    "state": "TX",
    "city": "New Caney"
  },
  {
    "value": "77358",
    "label": "New Waverly - TX 77358",
    "county": "Walker County",
    "state": "TX",
    "city": "New Waverly"
  },
  {
    "value": "77359",
    "label": "Oakhurst - TX 77359",
    "county": "San Jacinto County",
    "state": "TX",
    "city": "Oakhurst"
  },
  {
    "value": "77360",
    "label": "Onalaska - TX 77360",
    "county": "Polk County",
    "state": "TX",
    "city": "Onalaska"
  },
  {
    "value": "77362",
    "label": "Pinehurst - TX 77362",
    "county": "Montgomery County",
    "state": "TX",
    "city": "Pinehurst"
  },
  {
    "value": "77363",
    "label": "Plantersville - TX 77363",
    "county": "Grimes County",
    "state": "TX",
    "city": "Plantersville"
  },
  {
    "value": "77364",
    "label": "Pointblank - TX 77364",
    "county": "San Jacinto County",
    "state": "TX",
    "city": "Pointblank"
  },
  {
    "value": "77365",
    "label": "Porter - TX 77365",
    "county": "Montgomery County",
    "state": "TX",
    "city": "Porter"
  },
  {
    "value": "77371",
    "label": "Shepherd - TX 77371",
    "county": "San Jacinto County",
    "state": "TX",
    "city": "Shepherd"
  },
  {
    "value": "77372",
    "label": "Splendora - TX 77372",
    "county": "Montgomery County",
    "state": "TX",
    "city": "Splendora"
  },
  {
    "value": "77373",
    "label": "Spring - TX 77373",
    "county": "Harris County",
    "state": "TX",
    "city": "Spring"
  },
  {
    "value": "77375",
    "label": "Tomball - TX 77375",
    "county": "Harris County",
    "state": "TX",
    "city": "Tomball"
  },
  {
    "value": "77377",
    "label": "Tomball - TX 77377",
    "county": "Harris County",
    "state": "TX",
    "city": "Tomball"
  },
  {
    "value": "77378",
    "label": "Willis - TX 77378",
    "county": "Montgomery County",
    "state": "TX",
    "city": "Willis"
  },
  {
    "value": "77379",
    "label": "Spring - TX 77379",
    "county": "Harris County",
    "state": "TX",
    "city": "Spring"
  },
  {
    "value": "77380",
    "label": "Spring - TX 77380",
    "county": "Montgomery County",
    "state": "TX",
    "city": "Spring"
  },
  {
    "value": "77381",
    "label": "Spring - TX 77381",
    "county": "Montgomery County",
    "state": "TX",
    "city": "Spring"
  },
  {
    "value": "77382",
    "label": "Spring - TX 77382",
    "county": "Montgomery County",
    "state": "TX",
    "city": "Spring"
  },
  {
    "value": "77384",
    "label": "Conroe - TX 77384",
    "county": "Montgomery County",
    "state": "TX",
    "city": "Conroe"
  },
  {
    "value": "77385",
    "label": "Conroe - TX 77385",
    "county": "Montgomery County",
    "state": "TX",
    "city": "Conroe"
  },
  {
    "value": "77386",
    "label": "Spring - TX 77386",
    "county": "Montgomery County",
    "state": "TX",
    "city": "Spring"
  },
  {
    "value": "77388",
    "label": "Spring - TX 77388",
    "county": "Harris County",
    "state": "TX",
    "city": "Spring"
  },
  {
    "value": "77389",
    "label": "Spring - TX 77389",
    "county": "Harris County",
    "state": "TX",
    "city": "Spring"
  },
  {
    "value": "77396",
    "label": "Humble - TX 77396",
    "county": "Harris County",
    "state": "TX",
    "city": "Humble"
  },
  {
    "value": "77401",
    "label": "Bellaire - TX 77401",
    "county": "Harris County",
    "state": "TX",
    "city": "Bellaire"
  },
  {
    "value": "77406",
    "label": "Richmond - TX 77406",
    "county": "Fort Bend County",
    "state": "TX",
    "city": "Richmond"
  },
  {
    "value": "77407",
    "label": "Richmond - TX 77407",
    "county": "Fort Bend County",
    "state": "TX",
    "city": "Richmond"
  },
  {
    "value": "77414",
    "label": "Bay City - TX 77414",
    "county": "Matagorda County",
    "state": "TX",
    "city": "Bay City"
  },
  {
    "value": "77417",
    "label": "Beasley - TX 77417",
    "county": "Fort Bend County",
    "state": "TX",
    "city": "Beasley"
  },
  {
    "value": "77418",
    "label": "Bellville - TX 77418",
    "county": "Austin County",
    "state": "TX",
    "city": "Bellville"
  },
  {
    "value": "77420",
    "label": "Boling - TX 77420",
    "county": "Wharton County",
    "state": "TX",
    "city": "Boling"
  },
  {
    "value": "77422",
    "label": "Brazoria - TX 77422",
    "county": "Brazoria County",
    "state": "TX",
    "city": "Brazoria"
  },
  {
    "value": "77423",
    "label": "Brookshire - TX 77423",
    "county": "Waller County",
    "state": "TX",
    "city": "Brookshire"
  },
  {
    "value": "77426",
    "label": "Chappell Hill - TX 77426",
    "county": "Washington County",
    "state": "TX",
    "city": "Chappell Hill"
  },
  {
    "value": "77429",
    "label": "Cypress - TX 77429",
    "county": "Harris County",
    "state": "TX",
    "city": "Cypress"
  },
  {
    "value": "77430",
    "label": "Damon - TX 77430",
    "county": "Brazoria County",
    "state": "TX",
    "city": "Damon"
  },
  {
    "value": "77433",
    "label": "Cypress - TX 77433",
    "county": "Harris County",
    "state": "TX",
    "city": "Cypress"
  },
  {
    "value": "77434",
    "label": "Eagle Lake - TX 77434",
    "county": "Colorado County",
    "state": "TX",
    "city": "Eagle Lake"
  },
  {
    "value": "77435",
    "label": "East Bernard - TX 77435",
    "county": "Wharton County",
    "state": "TX",
    "city": "East Bernard"
  },
  {
    "value": "77437",
    "label": "El Campo - TX 77437",
    "county": "Wharton County",
    "state": "TX",
    "city": "El Campo"
  },
  {
    "value": "77441",
    "label": "Fulshear - TX 77441",
    "county": "Fort Bend County",
    "state": "TX",
    "city": "Fulshear"
  },
  {
    "value": "77442",
    "label": "Garwood - TX 77442",
    "county": "Colorado County",
    "state": "TX",
    "city": "Garwood"
  },
  {
    "value": "77444",
    "label": "Guy - TX 77444",
    "county": "Fort Bend County",
    "state": "TX",
    "city": "Guy"
  },
  {
    "value": "77445",
    "label": "Hempstead - TX 77445",
    "county": "Waller County",
    "state": "TX",
    "city": "Hempstead"
  },
  {
    "value": "77447",
    "label": "Hockley - TX 77447",
    "county": "Harris County",
    "state": "TX",
    "city": "Hockley"
  },
  {
    "value": "77449",
    "label": "Katy - TX 77449",
    "county": "Harris County",
    "state": "TX",
    "city": "Katy"
  },
  {
    "value": "77450",
    "label": "Katy - TX 77450",
    "county": "Harris County",
    "state": "TX",
    "city": "Katy"
  },
  {
    "value": "77455",
    "label": "Louise - TX 77455",
    "county": "Wharton County",
    "state": "TX",
    "city": "Louise"
  },
  {
    "value": "77459",
    "label": "Missouri City - TX 77459",
    "county": "Fort Bend County",
    "state": "TX",
    "city": "Missouri City"
  },
  {
    "value": "77461",
    "label": "Needville - TX 77461",
    "county": "Fort Bend County",
    "state": "TX",
    "city": "Needville"
  },
  {
    "value": "77465",
    "label": "Palacios - TX 77465",
    "county": "Matagorda County",
    "state": "TX",
    "city": "Palacios"
  },
  {
    "value": "77468",
    "label": "Pledger - TX 77468",
    "county": "Matagorda County",
    "state": "TX",
    "city": "Pledger"
  },
  {
    "value": "77469",
    "label": "Richmond - TX 77469",
    "county": "Fort Bend County",
    "state": "TX",
    "city": "Richmond"
  },
  {
    "value": "77471",
    "label": "Rosenberg - TX 77471",
    "county": "Fort Bend County",
    "state": "TX",
    "city": "Rosenberg"
  },
  {
    "value": "77474",
    "label": "Sealy - TX 77474",
    "county": "Austin County",
    "state": "TX",
    "city": "Sealy"
  },
  {
    "value": "77477",
    "label": "Stafford - TX 77477",
    "county": "Fort Bend County",
    "state": "TX",
    "city": "Stafford"
  },
  {
    "value": "77478",
    "label": "Sugar Land - TX 77478",
    "county": "Fort Bend County",
    "state": "TX",
    "city": "Sugar Land"
  },
  {
    "value": "77479",
    "label": "Sugar Land - TX 77479",
    "county": "Fort Bend County",
    "state": "TX",
    "city": "Sugar Land"
  },
  {
    "value": "77480",
    "label": "Sweeny - TX 77480",
    "county": "Brazoria County",
    "state": "TX",
    "city": "Sweeny"
  },
  {
    "value": "77482",
    "label": "Van Vleck - TX 77482",
    "county": "Matagorda County",
    "state": "TX",
    "city": "Van Vleck"
  },
  {
    "value": "77484",
    "label": "Waller - TX 77484",
    "county": "Waller County",
    "state": "TX",
    "city": "Waller"
  },
  {
    "value": "77485",
    "label": "Wallis - TX 77485",
    "county": "Austin County",
    "state": "TX",
    "city": "Wallis"
  },
  {
    "value": "77486",
    "label": "West Columbia - TX 77486",
    "county": "Brazoria County",
    "state": "TX",
    "city": "West Columbia"
  },
  {
    "value": "77488",
    "label": "Wharton - TX 77488",
    "county": "Wharton County",
    "state": "TX",
    "city": "Wharton"
  },
  {
    "value": "77489",
    "label": "Missouri City - TX 77489",
    "county": "Fort Bend County",
    "state": "TX",
    "city": "Missouri City"
  },
  {
    "value": "77493",
    "label": "Katy - TX 77493",
    "county": "Harris County",
    "state": "TX",
    "city": "Katy"
  },
  {
    "value": "77494",
    "label": "Katy - TX 77494",
    "county": "Fort Bend County",
    "state": "TX",
    "city": "Katy"
  },
  {
    "value": "77498",
    "label": "Sugar Land - TX 77498",
    "county": "Fort Bend County",
    "state": "TX",
    "city": "Sugar Land"
  },
  {
    "value": "77502",
    "label": "Pasadena - TX 77502",
    "county": "Harris County",
    "state": "TX",
    "city": "Pasadena"
  },
  {
    "value": "77503",
    "label": "Pasadena - TX 77503",
    "county": "Harris County",
    "state": "TX",
    "city": "Pasadena"
  },
  {
    "value": "77504",
    "label": "Pasadena - TX 77504",
    "county": "Harris County",
    "state": "TX",
    "city": "Pasadena"
  },
  {
    "value": "77505",
    "label": "Pasadena - TX 77505",
    "county": "Harris County",
    "state": "TX",
    "city": "Pasadena"
  },
  {
    "value": "77506",
    "label": "Pasadena - TX 77506",
    "county": "Harris County",
    "state": "TX",
    "city": "Pasadena"
  },
  {
    "value": "77507",
    "label": "Pasadena - TX 77507",
    "county": "Harris County",
    "state": "TX",
    "city": "Pasadena"
  },
  {
    "value": "77510",
    "label": "Santa Fe - TX 77510",
    "county": "Galveston County",
    "state": "TX",
    "city": "Santa Fe"
  },
  {
    "value": "77511",
    "label": "Alvin - TX 77511",
    "county": "Brazoria County",
    "state": "TX",
    "city": "Alvin"
  },
  {
    "value": "77514",
    "label": "Anahuac - TX 77514",
    "county": "Chambers County",
    "state": "TX",
    "city": "Anahuac"
  },
  {
    "value": "77515",
    "label": "Angleton - TX 77515",
    "county": "Brazoria County",
    "state": "TX",
    "city": "Angleton"
  },
  {
    "value": "77517",
    "label": "Santa Fe - TX 77517",
    "county": "Galveston County",
    "state": "TX",
    "city": "Santa Fe"
  },
  {
    "value": "77518",
    "label": "Bacliff - TX 77518",
    "county": "Galveston County",
    "state": "TX",
    "city": "Bacliff"
  },
  {
    "value": "77519",
    "label": "Batson - TX 77519",
    "county": "Hardin County",
    "state": "TX",
    "city": "Batson"
  },
  {
    "value": "77520",
    "label": "Baytown - TX 77520",
    "county": "Harris County",
    "state": "TX",
    "city": "Baytown"
  },
  {
    "value": "77521",
    "label": "Baytown - TX 77521",
    "county": "Harris County",
    "state": "TX",
    "city": "Baytown"
  },
  {
    "value": "77523",
    "label": "Baytown - TX 77523",
    "county": "Chambers County",
    "state": "TX",
    "city": "Baytown"
  },
  {
    "value": "77530",
    "label": "Channelview - TX 77530",
    "county": "Harris County",
    "state": "TX",
    "city": "Channelview"
  },
  {
    "value": "77531",
    "label": "Clute - TX 77531",
    "county": "Brazoria County",
    "state": "TX",
    "city": "Clute"
  },
  {
    "value": "77532",
    "label": "Crosby - TX 77532",
    "county": "Harris County",
    "state": "TX",
    "city": "Crosby"
  },
  {
    "value": "77534",
    "label": "Danbury - TX 77534",
    "county": "Brazoria County",
    "state": "TX",
    "city": "Danbury"
  },
  {
    "value": "77535",
    "label": "Dayton - TX 77535",
    "county": "Liberty County",
    "state": "TX",
    "city": "Dayton"
  },
  {
    "value": "77536",
    "label": "Deer Park - TX 77536",
    "county": "Harris County",
    "state": "TX",
    "city": "Deer Park"
  },
  {
    "value": "77538",
    "label": "Devers - TX 77538",
    "county": "Liberty County",
    "state": "TX",
    "city": "Devers"
  },
  {
    "value": "77539",
    "label": "Dickinson - TX 77539",
    "county": "Galveston County",
    "state": "TX",
    "city": "Dickinson"
  },
  {
    "value": "77541",
    "label": "Freeport - TX 77541",
    "county": "Brazoria County",
    "state": "TX",
    "city": "Freeport"
  },
  {
    "value": "77545",
    "label": "Fresno - TX 77545",
    "county": "Fort Bend County",
    "state": "TX",
    "city": "Fresno"
  },
  {
    "value": "77546",
    "label": "Friendswood - TX 77546",
    "county": "Galveston County",
    "state": "TX",
    "city": "Friendswood"
  },
  {
    "value": "77547",
    "label": "Galena Park - TX 77547",
    "county": "Harris County",
    "state": "TX",
    "city": "Galena Park"
  },
  {
    "value": "77550",
    "label": "Galveston - TX 77550",
    "county": "Galveston County",
    "state": "TX",
    "city": "Galveston"
  },
  {
    "value": "77551",
    "label": "Galveston - TX 77551",
    "county": "Galveston County",
    "state": "TX",
    "city": "Galveston"
  },
  {
    "value": "77554",
    "label": "Galveston - TX 77554",
    "county": "Galveston County",
    "state": "TX",
    "city": "Galveston"
  },
  {
    "value": "77560",
    "label": "Hankamer - TX 77560",
    "county": "Chambers County",
    "state": "TX",
    "city": "Hankamer"
  },
  {
    "value": "77562",
    "label": "Highlands - TX 77562",
    "county": "Harris County",
    "state": "TX",
    "city": "Highlands"
  },
  {
    "value": "77563",
    "label": "Hitchcock - TX 77563",
    "county": "Galveston County",
    "state": "TX",
    "city": "Hitchcock"
  },
  {
    "value": "77564",
    "label": "Hull - TX 77564",
    "county": "Liberty County",
    "state": "TX",
    "city": "Hull"
  },
  {
    "value": "77565",
    "label": "Kemah - TX 77565",
    "county": "Galveston County",
    "state": "TX",
    "city": "Kemah"
  },
  {
    "value": "77566",
    "label": "Lake Jackson - TX 77566",
    "county": "Brazoria County",
    "state": "TX",
    "city": "Lake Jackson"
  },
  {
    "value": "77568",
    "label": "La Marque - TX 77568",
    "county": "Galveston County",
    "state": "TX",
    "city": "La Marque"
  },
  {
    "value": "77571",
    "label": "La Porte - TX 77571",
    "county": "Harris County",
    "state": "TX",
    "city": "La Porte"
  },
  {
    "value": "77573",
    "label": "League City - TX 77573",
    "county": "Galveston County",
    "state": "TX",
    "city": "League City"
  },
  {
    "value": "77575",
    "label": "Liberty - TX 77575",
    "county": "Liberty County",
    "state": "TX",
    "city": "Liberty"
  },
  {
    "value": "77577",
    "label": "Liverpool - TX 77577",
    "county": "Brazoria County",
    "state": "TX",
    "city": "Liverpool"
  },
  {
    "value": "77578",
    "label": "Manvel - TX 77578",
    "county": "Brazoria County",
    "state": "TX",
    "city": "Manvel"
  },
  {
    "value": "77581",
    "label": "Pearland - TX 77581",
    "county": "Brazoria County",
    "state": "TX",
    "city": "Pearland"
  },
  {
    "value": "77583",
    "label": "Rosharon - TX 77583",
    "county": "Brazoria County",
    "state": "TX",
    "city": "Rosharon"
  },
  {
    "value": "77584",
    "label": "Pearland - TX 77584",
    "county": "Brazoria County",
    "state": "TX",
    "city": "Pearland"
  },
  {
    "value": "77585",
    "label": "Saratoga - TX 77585",
    "county": "Hardin County",
    "state": "TX",
    "city": "Saratoga"
  },
  {
    "value": "77586",
    "label": "Seabrook - TX 77586",
    "county": "Harris County",
    "state": "TX",
    "city": "Seabrook"
  },
  {
    "value": "77587",
    "label": "South Houston - TX 77587",
    "county": "Harris County",
    "state": "TX",
    "city": "South Houston"
  },
  {
    "value": "77590",
    "label": "Texas City - TX 77590",
    "county": "Galveston County",
    "state": "TX",
    "city": "Texas City"
  },
  {
    "value": "77591",
    "label": "Texas City - TX 77591",
    "county": "Galveston County",
    "state": "TX",
    "city": "Texas City"
  },
  {
    "value": "77597",
    "label": "Wallisville - TX 77597",
    "county": "Chambers County",
    "state": "TX",
    "city": "Wallisville"
  },
  {
    "value": "77598",
    "label": "Webster - TX 77598",
    "county": "Harris County",
    "state": "TX",
    "city": "Webster"
  },
  {
    "value": "77611",
    "label": "Bridge City - TX 77611",
    "county": "Orange County",
    "state": "TX",
    "city": "Bridge City"
  },
  {
    "value": "77612",
    "label": "Buna - TX 77612",
    "county": "Jasper County",
    "state": "TX",
    "city": "Buna"
  },
  {
    "value": "77616",
    "label": "Fred - TX 77616",
    "county": "Tyler County",
    "state": "TX",
    "city": "Fred"
  },
  {
    "value": "77619",
    "label": "Groves - TX 77619",
    "county": "Jefferson County",
    "state": "TX",
    "city": "Groves"
  },
  {
    "value": "77622",
    "label": "Hamshire - TX 77622",
    "county": "Jefferson County",
    "state": "TX",
    "city": "Hamshire"
  },
  {
    "value": "77624",
    "label": "Hillister - TX 77624",
    "county": "Tyler County",
    "state": "TX",
    "city": "Hillister"
  },
  {
    "value": "77625",
    "label": "Kountze - TX 77625",
    "county": "Hardin County",
    "state": "TX",
    "city": "Kountze"
  },
  {
    "value": "77627",
    "label": "Nederland - TX 77627",
    "county": "Jefferson County",
    "state": "TX",
    "city": "Nederland"
  },
  {
    "value": "77630",
    "label": "Orange - TX 77630",
    "county": "Orange County",
    "state": "TX",
    "city": "Orange"
  },
  {
    "value": "77632",
    "label": "Orange - TX 77632",
    "county": "Orange County",
    "state": "TX",
    "city": "Orange"
  },
  {
    "value": "77640",
    "label": "Port Arthur - TX 77640",
    "county": "Jefferson County",
    "state": "TX",
    "city": "Port Arthur"
  },
  {
    "value": "77642",
    "label": "Port Arthur - TX 77642",
    "county": "Jefferson County",
    "state": "TX",
    "city": "Port Arthur"
  },
  {
    "value": "77651",
    "label": "Port Neches - TX 77651",
    "county": "Jefferson County",
    "state": "TX",
    "city": "Port Neches"
  },
  {
    "value": "77655",
    "label": "Sabine Pass - TX 77655",
    "county": "Jefferson County",
    "state": "TX",
    "city": "Sabine Pass"
  },
  {
    "value": "77656",
    "label": "Silsbee - TX 77656",
    "county": "Hardin County",
    "state": "TX",
    "city": "Silsbee"
  },
  {
    "value": "77657",
    "label": "Lumberton - TX 77657",
    "county": "Hardin County",
    "state": "TX",
    "city": "Lumberton"
  },
  {
    "value": "77659",
    "label": "Sour Lake - TX 77659",
    "county": "Hardin County",
    "state": "TX",
    "city": "Sour Lake"
  },
  {
    "value": "77660",
    "label": "Spurger - TX 77660",
    "county": "Tyler County",
    "state": "TX",
    "city": "Spurger"
  },
  {
    "value": "77662",
    "label": "Vidor - TX 77662",
    "county": "Orange County",
    "state": "TX",
    "city": "Vidor"
  },
  {
    "value": "77664",
    "label": "Warren - TX 77664",
    "county": "Tyler County",
    "state": "TX",
    "city": "Warren"
  },
  {
    "value": "77665",
    "label": "Winnie - TX 77665",
    "county": "Chambers County",
    "state": "TX",
    "city": "Winnie"
  },
  {
    "value": "77701",
    "label": "Beaumont - TX 77701",
    "county": "Jefferson County",
    "state": "TX",
    "city": "Beaumont"
  },
  {
    "value": "77702",
    "label": "Beaumont - TX 77702",
    "county": "Jefferson County",
    "state": "TX",
    "city": "Beaumont"
  },
  {
    "value": "77703",
    "label": "Beaumont - TX 77703",
    "county": "Jefferson County",
    "state": "TX",
    "city": "Beaumont"
  },
  {
    "value": "77705",
    "label": "Beaumont - TX 77705",
    "county": "Jefferson County",
    "state": "TX",
    "city": "Beaumont"
  },
  {
    "value": "77706",
    "label": "Beaumont - TX 77706",
    "county": "Jefferson County",
    "state": "TX",
    "city": "Beaumont"
  },
  {
    "value": "77707",
    "label": "Beaumont - TX 77707",
    "county": "Jefferson County",
    "state": "TX",
    "city": "Beaumont"
  },
  {
    "value": "77708",
    "label": "Beaumont - TX 77708",
    "county": "Jefferson County",
    "state": "TX",
    "city": "Beaumont"
  },
  {
    "value": "77713",
    "label": "Beaumont - TX 77713",
    "county": "Jefferson County",
    "state": "TX",
    "city": "Beaumont"
  },
  {
    "value": "77801",
    "label": "Bryan - TX 77801",
    "county": "Brazos County",
    "state": "TX",
    "city": "Bryan"
  },
  {
    "value": "77802",
    "label": "Bryan - TX 77802",
    "county": "Brazos County",
    "state": "TX",
    "city": "Bryan"
  },
  {
    "value": "77803",
    "label": "Bryan - TX 77803",
    "county": "Brazos County",
    "state": "TX",
    "city": "Bryan"
  },
  {
    "value": "77807",
    "label": "Bryan - TX 77807",
    "county": "Brazos County",
    "state": "TX",
    "city": "Bryan"
  },
  {
    "value": "77808",
    "label": "Bryan - TX 77808",
    "county": "Brazos County",
    "state": "TX",
    "city": "Bryan"
  },
  {
    "value": "77830",
    "label": "Anderson - TX 77830",
    "county": "Grimes County",
    "state": "TX",
    "city": "Anderson"
  },
  {
    "value": "77831",
    "label": "Bedias - TX 77831",
    "county": "Grimes County",
    "state": "TX",
    "city": "Bedias"
  },
  {
    "value": "77833",
    "label": "Brenham - TX 77833",
    "county": "Washington County",
    "state": "TX",
    "city": "Brenham"
  },
  {
    "value": "77835",
    "label": "Burton - TX 77835",
    "county": "Washington County",
    "state": "TX",
    "city": "Burton"
  },
  {
    "value": "77836",
    "label": "Caldwell - TX 77836",
    "county": "Burleson County",
    "state": "TX",
    "city": "Caldwell"
  },
  {
    "value": "77837",
    "label": "Calvert - TX 77837",
    "county": "Robertson County",
    "state": "TX",
    "city": "Calvert"
  },
  {
    "value": "77840",
    "label": "College Station - TX 77840",
    "county": "Brazos County",
    "state": "TX",
    "city": "College Station"
  },
  {
    "value": "77845",
    "label": "College Station - TX 77845",
    "county": "Brazos County",
    "state": "TX",
    "city": "College Station"
  },
  {
    "value": "77853",
    "label": "Dime Box - TX 77853",
    "county": "Lee County",
    "state": "TX",
    "city": "Dime Box"
  },
  {
    "value": "77856",
    "label": "Franklin - TX 77856",
    "county": "Robertson County",
    "state": "TX",
    "city": "Franklin"
  },
  {
    "value": "77859",
    "label": "Hearne - TX 77859",
    "county": "Robertson County",
    "state": "TX",
    "city": "Hearne"
  },
  {
    "value": "77861",
    "label": "Iola - TX 77861",
    "county": "Grimes County",
    "state": "TX",
    "city": "Iola"
  },
  {
    "value": "77864",
    "label": "Madisonville - TX 77864",
    "county": "Madison County",
    "state": "TX",
    "city": "Madisonville"
  },
  {
    "value": "77865",
    "label": "Marquez - TX 77865",
    "county": "Leon County",
    "state": "TX",
    "city": "Marquez"
  },
  {
    "value": "77868",
    "label": "Navasota - TX 77868",
    "county": "Grimes County",
    "state": "TX",
    "city": "Navasota"
  },
  {
    "value": "77871",
    "label": "Normangee - TX 77871",
    "county": "Leon County",
    "state": "TX",
    "city": "Normangee"
  },
  {
    "value": "77872",
    "label": "North Zulch - TX 77872",
    "county": "Madison County",
    "state": "TX",
    "city": "North Zulch"
  },
  {
    "value": "77873",
    "label": "Richards - TX 77873",
    "county": "Grimes County",
    "state": "TX",
    "city": "Richards"
  },
  {
    "value": "77879",
    "label": "Somerville - TX 77879",
    "county": "Burleson County",
    "state": "TX",
    "city": "Somerville"
  },
  {
    "value": "77880",
    "label": "Washington - TX 77880",
    "county": "Washington County",
    "state": "TX",
    "city": "Washington"
  },
  {
    "value": "77901",
    "label": "Victoria - TX 77901",
    "county": "Victoria County",
    "state": "TX",
    "city": "Victoria"
  },
  {
    "value": "77904",
    "label": "Victoria - TX 77904",
    "county": "Victoria County",
    "state": "TX",
    "city": "Victoria"
  },
  {
    "value": "77905",
    "label": "Victoria - TX 77905",
    "county": "Victoria County",
    "state": "TX",
    "city": "Victoria"
  },
  {
    "value": "77954",
    "label": "Cuero - TX 77954",
    "county": "DeWitt County",
    "state": "TX",
    "city": "Cuero"
  },
  {
    "value": "77957",
    "label": "Edna - TX 77957",
    "county": "Jackson County",
    "state": "TX",
    "city": "Edna"
  },
  {
    "value": "77962",
    "label": "Ganado - TX 77962",
    "county": "Jackson County",
    "state": "TX",
    "city": "Ganado"
  },
  {
    "value": "77963",
    "label": "Goliad - TX 77963",
    "county": "Goliad County",
    "state": "TX",
    "city": "Goliad"
  },
  {
    "value": "77964",
    "label": "Hallettsville - TX 77964",
    "county": "Lavaca County",
    "state": "TX",
    "city": "Hallettsville"
  },
  {
    "value": "77968",
    "label": "Inez - TX 77968",
    "county": "Victoria County",
    "state": "TX",
    "city": "Inez"
  },
  {
    "value": "77971",
    "label": "Lolita - TX 77971",
    "county": "Jackson County",
    "state": "TX",
    "city": "Lolita"
  },
  {
    "value": "77974",
    "label": "Meyersville - TX 77974",
    "county": "Victoria County",
    "state": "TX",
    "city": "Meyersville"
  },
  {
    "value": "77975",
    "label": "Moulton - TX 77975",
    "county": "Lavaca County",
    "state": "TX",
    "city": "Moulton"
  },
  {
    "value": "77979",
    "label": "Port Lavaca - TX 77979",
    "county": "Calhoun County",
    "state": "TX",
    "city": "Port Lavaca"
  },
  {
    "value": "77983",
    "label": "Seadrift - TX 77983",
    "county": "Calhoun County",
    "state": "TX",
    "city": "Seadrift"
  },
  {
    "value": "77984",
    "label": "Shiner - TX 77984",
    "county": "Lavaca County",
    "state": "TX",
    "city": "Shiner"
  },
  {
    "value": "77986",
    "label": "Sublime - TX 77986",
    "county": "Lavaca County",
    "state": "TX",
    "city": "Sublime"
  },
  {
    "value": "77990",
    "label": "Tivoli - TX 77990",
    "county": "Refugio County",
    "state": "TX",
    "city": "Tivoli"
  },
  {
    "value": "77994",
    "label": "Westhoff - TX 77994",
    "county": "DeWitt County",
    "state": "TX",
    "city": "Westhoff"
  },
  {
    "value": "77995",
    "label": "Yoakum - TX 77995",
    "county": "Lavaca County",
    "state": "TX",
    "city": "Yoakum"
  },
  {
    "value": "78002",
    "label": "Atascosa - TX 78002",
    "county": "Bexar County",
    "state": "TX",
    "city": "Atascosa"
  },
  {
    "value": "78003",
    "label": "Bandera - TX 78003",
    "county": "Bandera County",
    "state": "TX",
    "city": "Bandera"
  },
  {
    "value": "78004",
    "label": "Bergheim - TX 78004",
    "county": "Kendall County",
    "state": "TX",
    "city": "Bergheim"
  },
  {
    "value": "78005",
    "label": "Bigfoot - TX 78005",
    "county": "Frio County",
    "state": "TX",
    "city": "Bigfoot"
  },
  {
    "value": "78006",
    "label": "Boerne - TX 78006",
    "county": "Kendall County",
    "state": "TX",
    "city": "Boerne"
  },
  {
    "value": "78007",
    "label": "Calliham - TX 78007",
    "county": "McMullen County",
    "state": "TX",
    "city": "Calliham"
  },
  {
    "value": "78008",
    "label": "Campbellton - TX 78008",
    "county": "Atascosa County",
    "state": "TX",
    "city": "Campbellton"
  },
  {
    "value": "78009",
    "label": "Castroville - TX 78009",
    "county": "Medina County",
    "state": "TX",
    "city": "Castroville"
  },
  {
    "value": "78010",
    "label": "Center Point - TX 78010",
    "county": "Kerr County",
    "state": "TX",
    "city": "Center Point"
  },
  {
    "value": "78011",
    "label": "Charlotte - TX 78011",
    "county": "Atascosa County",
    "state": "TX",
    "city": "Charlotte"
  },
  {
    "value": "78012",
    "label": "Christine - TX 78012",
    "county": "Atascosa County",
    "state": "TX",
    "city": "Christine"
  },
  {
    "value": "78013",
    "label": "Comfort - TX 78013",
    "county": "Kendall County",
    "state": "TX",
    "city": "Comfort"
  },
  {
    "value": "78014",
    "label": "Cotulla - TX 78014",
    "county": "La Salle County",
    "state": "TX",
    "city": "Cotulla"
  },
  {
    "value": "78015",
    "label": "Boerne - TX 78015",
    "county": "Bexar County",
    "state": "TX",
    "city": "Boerne"
  },
  {
    "value": "78016",
    "label": "Devine - TX 78016",
    "county": "Medina County",
    "state": "TX",
    "city": "Devine"
  },
  {
    "value": "78017",
    "label": "Dilley - TX 78017",
    "county": "Frio County",
    "state": "TX",
    "city": "Dilley"
  },
  {
    "value": "78021",
    "label": "Fowlerton - TX 78021",
    "county": "La Salle County",
    "state": "TX",
    "city": "Fowlerton"
  },
  {
    "value": "78022",
    "label": "George West - TX 78022",
    "county": "Live Oak County",
    "state": "TX",
    "city": "George West"
  },
  {
    "value": "78023",
    "label": "Helotes - TX 78023",
    "county": "Bexar County",
    "state": "TX",
    "city": "Helotes"
  },
  {
    "value": "78024",
    "label": "Hunt - TX 78024",
    "county": "Kerr County",
    "state": "TX",
    "city": "Hunt"
  },
  {
    "value": "78025",
    "label": "Ingram - TX 78025",
    "county": "Kerr County",
    "state": "TX",
    "city": "Ingram"
  },
  {
    "value": "78026",
    "label": "Jourdanton - TX 78026",
    "county": "Atascosa County",
    "state": "TX",
    "city": "Jourdanton"
  },
  {
    "value": "78027",
    "label": "Kendalia - TX 78027",
    "county": "Kendall County",
    "state": "TX",
    "city": "Kendalia"
  },
  {
    "value": "78028",
    "label": "Kerrville - TX 78028",
    "county": "Kerr County",
    "state": "TX",
    "city": "Kerrville"
  },
  {
    "value": "78039",
    "label": "La Coste - TX 78039",
    "county": "Medina County",
    "state": "TX",
    "city": "La Coste"
  },
  {
    "value": "78040",
    "label": "Laredo - TX 78040",
    "county": "Webb County",
    "state": "TX",
    "city": "Laredo"
  },
  {
    "value": "78041",
    "label": "Laredo - TX 78041",
    "county": "Webb County",
    "state": "TX",
    "city": "Laredo"
  },
  {
    "value": "78043",
    "label": "Laredo - TX 78043",
    "county": "Webb County",
    "state": "TX",
    "city": "Laredo"
  },
  {
    "value": "78045",
    "label": "Laredo - TX 78045",
    "county": "Webb County",
    "state": "TX",
    "city": "Laredo"
  },
  {
    "value": "78046",
    "label": "Laredo - TX 78046",
    "county": "Webb County",
    "state": "TX",
    "city": "Laredo"
  },
  {
    "value": "78052",
    "label": "Lytle - TX 78052",
    "county": "Atascosa County",
    "state": "TX",
    "city": "Lytle"
  },
  {
    "value": "78055",
    "label": "Medina - TX 78055",
    "county": "Bandera County",
    "state": "TX",
    "city": "Medina"
  },
  {
    "value": "78056",
    "label": "Mico - TX 78056",
    "county": "Medina County",
    "state": "TX",
    "city": "Mico"
  },
  {
    "value": "78057",
    "label": "Moore - TX 78057",
    "county": "Frio County",
    "state": "TX",
    "city": "Moore"
  },
  {
    "value": "78058",
    "label": "Mountain Home - TX 78058",
    "county": "Kerr County",
    "state": "TX",
    "city": "Mountain Home"
  },
  {
    "value": "78059",
    "label": "Natalia - TX 78059",
    "county": "Medina County",
    "state": "TX",
    "city": "Natalia"
  },
  {
    "value": "78060",
    "label": "Oakville - TX 78060",
    "county": "Live Oak County",
    "state": "TX",
    "city": "Oakville"
  },
  {
    "value": "78061",
    "label": "Pearsall - TX 78061",
    "county": "Frio County",
    "state": "TX",
    "city": "Pearsall"
  },
  {
    "value": "78063",
    "label": "Pipe Creek - TX 78063",
    "county": "Bandera County",
    "state": "TX",
    "city": "Pipe Creek"
  },
  {
    "value": "78064",
    "label": "Pleasanton - TX 78064",
    "county": "Atascosa County",
    "state": "TX",
    "city": "Pleasanton"
  },
  {
    "value": "78065",
    "label": "Poteet - TX 78065",
    "county": "Atascosa County",
    "state": "TX",
    "city": "Poteet"
  },
  {
    "value": "78066",
    "label": "Rio Medina - TX 78066",
    "county": "Medina County",
    "state": "TX",
    "city": "Rio Medina"
  },
  {
    "value": "78067",
    "label": "San Ygnacio - TX 78067",
    "county": "Zapata County",
    "state": "TX",
    "city": "San Ygnacio"
  },
  {
    "value": "78069",
    "label": "Somerset - TX 78069",
    "county": "Atascosa County",
    "state": "TX",
    "city": "Somerset"
  },
  {
    "value": "78070",
    "label": "Spring Branch - TX 78070",
    "county": "Comal County",
    "state": "TX",
    "city": "Spring Branch"
  },
  {
    "value": "78071",
    "label": "Three Rivers - TX 78071",
    "county": "Live Oak County",
    "state": "TX",
    "city": "Three Rivers"
  },
  {
    "value": "78072",
    "label": "Tilden - TX 78072",
    "county": "McMullen County",
    "state": "TX",
    "city": "Tilden"
  },
  {
    "value": "78073",
    "label": "Von Ormy - TX 78073",
    "county": "Bexar County",
    "state": "TX",
    "city": "Von Ormy"
  },
  {
    "value": "78075",
    "label": "Whitsett - TX 78075",
    "county": "Live Oak County",
    "state": "TX",
    "city": "Whitsett"
  },
  {
    "value": "78076",
    "label": "Zapata - TX 78076",
    "county": "Zapata County",
    "state": "TX",
    "city": "Zapata"
  },
  {
    "value": "78101",
    "label": "Adkins - TX 78101",
    "county": "Bexar County",
    "state": "TX",
    "city": "Adkins"
  },
  {
    "value": "78102",
    "label": "Beeville - TX 78102",
    "county": "Bee County",
    "state": "TX",
    "city": "Beeville"
  },
  {
    "value": "78108",
    "label": "Cibolo - TX 78108",
    "county": "Guadalupe County",
    "state": "TX",
    "city": "Cibolo"
  },
  {
    "value": "78109",
    "label": "Converse - TX 78109",
    "county": "Bexar County",
    "state": "TX",
    "city": "Converse"
  },
  {
    "value": "78111",
    "label": "Ecleto - TX 78111",
    "county": "Karnes County",
    "state": "TX",
    "city": "Ecleto"
  },
  {
    "value": "78112",
    "label": "Elmendorf - TX 78112",
    "county": "Bexar County",
    "state": "TX",
    "city": "Elmendorf"
  },
  {
    "value": "78113",
    "label": "Falls City - TX 78113",
    "county": "Karnes County",
    "state": "TX",
    "city": "Falls City"
  },
  {
    "value": "78114",
    "label": "Floresville - TX 78114",
    "county": "Wilson County",
    "state": "TX",
    "city": "Floresville"
  },
  {
    "value": "78116",
    "label": "Gillett - TX 78116",
    "county": "Karnes County",
    "state": "TX",
    "city": "Gillett"
  },
  {
    "value": "78117",
    "label": "Hobson - TX 78117",
    "county": "Karnes County",
    "state": "TX",
    "city": "Hobson"
  },
  {
    "value": "78118",
    "label": "Karnes City - TX 78118",
    "county": "Karnes County",
    "state": "TX",
    "city": "Karnes City"
  },
  {
    "value": "78119",
    "label": "Kenedy - TX 78119",
    "county": "Karnes County",
    "state": "TX",
    "city": "Kenedy"
  },
  {
    "value": "78121",
    "label": "La Vernia - TX 78121",
    "county": "Wilson County",
    "state": "TX",
    "city": "La Vernia"
  },
  {
    "value": "78122",
    "label": "Leesville - TX 78122",
    "county": "Gonzales County",
    "state": "TX",
    "city": "Leesville"
  },
  {
    "value": "78123",
    "label": "Mc Queeney - TX 78123",
    "county": "Guadalupe County",
    "state": "TX",
    "city": "Mc Queeney"
  },
  {
    "value": "78124",
    "label": "Marion - TX 78124",
    "county": "Guadalupe County",
    "state": "TX",
    "city": "Marion"
  },
  {
    "value": "78130",
    "label": "New Braunfels - TX 78130",
    "county": "Comal County",
    "state": "TX",
    "city": "New Braunfels"
  },
  {
    "value": "78132",
    "label": "New Braunfels - TX 78132",
    "county": "Comal County",
    "state": "TX",
    "city": "New Braunfels"
  },
  {
    "value": "78133",
    "label": "Canyon Lake - TX 78133",
    "county": "Comal County",
    "state": "TX",
    "city": "Canyon Lake"
  },
  {
    "value": "78135",
    "label": "New Braunfels - TX 78135",
    "county": "Comal County",
    "state": "TX",
    "city": "New Braunfels"
  },
  {
    "value": "78140",
    "label": "Nixon - TX 78140",
    "county": "Gonzales County",
    "state": "TX",
    "city": "Nixon"
  },
  {
    "value": "78141",
    "label": "Nordheim - TX 78141",
    "county": "DeWitt County",
    "state": "TX",
    "city": "Nordheim"
  },
  {
    "value": "78148",
    "label": "Universal City - TX 78148",
    "county": "Bexar County",
    "state": "TX",
    "city": "Universal City"
  },
  {
    "value": "78150",
    "label": "Jbsa Randolph - TX 78150",
    "county": "Bexar County",
    "state": "TX",
    "city": "Jbsa Randolph"
  },
  {
    "value": "78151",
    "label": "Runge - TX 78151",
    "county": "Karnes County",
    "state": "TX",
    "city": "Runge"
  },
  {
    "value": "78152",
    "label": "Saint Hedwig - TX 78152",
    "county": "Bexar County",
    "state": "TX",
    "city": "Saint Hedwig"
  },
  {
    "value": "78154",
    "label": "Schertz - TX 78154",
    "county": "Guadalupe County",
    "state": "TX",
    "city": "Schertz"
  },
  {
    "value": "78155",
    "label": "Seguin - TX 78155",
    "county": "Guadalupe County",
    "state": "TX",
    "city": "Seguin"
  },
  {
    "value": "78159",
    "label": "Smiley - TX 78159",
    "county": "Gonzales County",
    "state": "TX",
    "city": "Smiley"
  },
  {
    "value": "78160",
    "label": "Stockdale - TX 78160",
    "county": "Wilson County",
    "state": "TX",
    "city": "Stockdale"
  },
  {
    "value": "78161",
    "label": "Sutherland Springs - TX 78161",
    "county": "Wilson County",
    "state": "TX",
    "city": "Sutherland Springs"
  },
  {
    "value": "78163",
    "label": "Bulverde - TX 78163",
    "county": "Comal County",
    "state": "TX",
    "city": "Bulverde"
  },
  {
    "value": "78164",
    "label": "Yorktown - TX 78164",
    "county": "DeWitt County",
    "state": "TX",
    "city": "Yorktown"
  },
  {
    "value": "78201",
    "label": "San Antonio - TX 78201",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78202",
    "label": "San Antonio - TX 78202",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78203",
    "label": "San Antonio - TX 78203",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78204",
    "label": "San Antonio - TX 78204",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78205",
    "label": "San Antonio - TX 78205",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78206",
    "label": "San Antonio - TX 78206",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78207",
    "label": "San Antonio - TX 78207",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78208",
    "label": "San Antonio - TX 78208",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78209",
    "label": "San Antonio - TX 78209",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78210",
    "label": "San Antonio - TX 78210",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78211",
    "label": "San Antonio - TX 78211",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78212",
    "label": "San Antonio - TX 78212",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78213",
    "label": "San Antonio - TX 78213",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78214",
    "label": "San Antonio - TX 78214",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78215",
    "label": "San Antonio - TX 78215",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78216",
    "label": "San Antonio - TX 78216",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78217",
    "label": "San Antonio - TX 78217",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78218",
    "label": "San Antonio - TX 78218",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78219",
    "label": "San Antonio - TX 78219",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78220",
    "label": "San Antonio - TX 78220",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78221",
    "label": "San Antonio - TX 78221",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78222",
    "label": "San Antonio - TX 78222",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78223",
    "label": "San Antonio - TX 78223",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78224",
    "label": "San Antonio - TX 78224",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78225",
    "label": "San Antonio - TX 78225",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78226",
    "label": "San Antonio - TX 78226",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78227",
    "label": "San Antonio - TX 78227",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78228",
    "label": "San Antonio - TX 78228",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78229",
    "label": "San Antonio - TX 78229",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78230",
    "label": "San Antonio - TX 78230",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78231",
    "label": "San Antonio - TX 78231",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78232",
    "label": "San Antonio - TX 78232",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78233",
    "label": "San Antonio - TX 78233",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78234",
    "label": "Jbsa Ft Sam Houston - TX 78234",
    "county": "Bexar County",
    "state": "TX",
    "city": "Jbsa Ft Sam Houston"
  },
  {
    "value": "78235",
    "label": "San Antonio - TX 78235",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78236",
    "label": "Jbsa Lackland - TX 78236",
    "county": "Bexar County",
    "state": "TX",
    "city": "Jbsa Lackland"
  },
  {
    "value": "78237",
    "label": "San Antonio - TX 78237",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78238",
    "label": "San Antonio - TX 78238",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78239",
    "label": "San Antonio - TX 78239",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78240",
    "label": "San Antonio - TX 78240",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78241",
    "label": "San Antonio - TX 78241",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78242",
    "label": "San Antonio - TX 78242",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78243",
    "label": "San Antonio - TX 78243",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78244",
    "label": "San Antonio - TX 78244",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78245",
    "label": "San Antonio - TX 78245",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78247",
    "label": "San Antonio - TX 78247",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78248",
    "label": "San Antonio - TX 78248",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78249",
    "label": "San Antonio - TX 78249",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78250",
    "label": "San Antonio - TX 78250",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78251",
    "label": "San Antonio - TX 78251",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78252",
    "label": "San Antonio - TX 78252",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78253",
    "label": "San Antonio - TX 78253",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78254",
    "label": "San Antonio - TX 78254",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78255",
    "label": "San Antonio - TX 78255",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78256",
    "label": "San Antonio - TX 78256",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78257",
    "label": "San Antonio - TX 78257",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78258",
    "label": "San Antonio - TX 78258",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78259",
    "label": "San Antonio - TX 78259",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78260",
    "label": "San Antonio - TX 78260",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78261",
    "label": "San Antonio - TX 78261",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78262",
    "label": "San Antonio - TX 78262",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78263",
    "label": "San Antonio - TX 78263",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78264",
    "label": "San Antonio - TX 78264",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78266",
    "label": "San Antonio - TX 78266",
    "county": "Comal County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78284",
    "label": "San Antonio - TX 78284",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78285",
    "label": "San Antonio - TX 78285",
    "county": "Bexar County",
    "state": "TX",
    "city": "San Antonio"
  },
  {
    "value": "78330",
    "label": "Agua Dulce - TX 78330",
    "county": "Nueces County",
    "state": "TX",
    "city": "Agua Dulce"
  },
  {
    "value": "78332",
    "label": "Alice - TX 78332",
    "county": "Jim Wells County",
    "state": "TX",
    "city": "Alice"
  },
  {
    "value": "78336",
    "label": "Aransas Pass - TX 78336",
    "county": "San Patricio County",
    "state": "TX",
    "city": "Aransas Pass"
  },
  {
    "value": "78340",
    "label": "Bayside - TX 78340",
    "county": "Refugio County",
    "state": "TX",
    "city": "Bayside"
  },
  {
    "value": "78343",
    "label": "Bishop - TX 78343",
    "county": "Nueces County",
    "state": "TX",
    "city": "Bishop"
  },
  {
    "value": "78344",
    "label": "Bruni - TX 78344",
    "county": "Webb County",
    "state": "TX",
    "city": "Bruni"
  },
  {
    "value": "78349",
    "label": "Concepcion - TX 78349",
    "county": "Duval County",
    "state": "TX",
    "city": "Concepcion"
  },
  {
    "value": "78353",
    "label": "Encino - TX 78353",
    "county": "Brooks County",
    "state": "TX",
    "city": "Encino"
  },
  {
    "value": "78355",
    "label": "Falfurrias - TX 78355",
    "county": "Brooks County",
    "state": "TX",
    "city": "Falfurrias"
  },
  {
    "value": "78357",
    "label": "Freer - TX 78357",
    "county": "Duval County",
    "state": "TX",
    "city": "Freer"
  },
  {
    "value": "78360",
    "label": "Guerra - TX 78360",
    "county": "Jim Hogg County",
    "state": "TX",
    "city": "Guerra"
  },
  {
    "value": "78361",
    "label": "Hebbronville - TX 78361",
    "county": "Jim Hogg County",
    "state": "TX",
    "city": "Hebbronville"
  },
  {
    "value": "78362",
    "label": "Ingleside - TX 78362",
    "county": "San Patricio County",
    "state": "TX",
    "city": "Ingleside"
  },
  {
    "value": "78363",
    "label": "Kingsville - TX 78363",
    "county": "Kleberg County",
    "state": "TX",
    "city": "Kingsville"
  },
  {
    "value": "78368",
    "label": "Mathis - TX 78368",
    "county": "San Patricio County",
    "state": "TX",
    "city": "Mathis"
  },
  {
    "value": "78369",
    "label": "Mirando City - TX 78369",
    "county": "Webb County",
    "state": "TX",
    "city": "Mirando City"
  },
  {
    "value": "78370",
    "label": "Odem - TX 78370",
    "county": "San Patricio County",
    "state": "TX",
    "city": "Odem"
  },
  {
    "value": "78372",
    "label": "Orange Grove - TX 78372",
    "county": "Jim Wells County",
    "state": "TX",
    "city": "Orange Grove"
  },
  {
    "value": "78373",
    "label": "Port Aransas - TX 78373",
    "county": "Nueces County",
    "state": "TX",
    "city": "Port Aransas"
  },
  {
    "value": "78374",
    "label": "Portland - TX 78374",
    "county": "San Patricio County",
    "state": "TX",
    "city": "Portland"
  },
  {
    "value": "78375",
    "label": "Premont - TX 78375",
    "county": "Jim Wells County",
    "state": "TX",
    "city": "Premont"
  },
  {
    "value": "78376",
    "label": "Realitos - TX 78376",
    "county": "Duval County",
    "state": "TX",
    "city": "Realitos"
  },
  {
    "value": "78377",
    "label": "Refugio - TX 78377",
    "county": "Refugio County",
    "state": "TX",
    "city": "Refugio"
  },
  {
    "value": "78379",
    "label": "Riviera - TX 78379",
    "county": "Kleberg County",
    "state": "TX",
    "city": "Riviera"
  },
  {
    "value": "78380",
    "label": "Robstown - TX 78380",
    "county": "Nueces County",
    "state": "TX",
    "city": "Robstown"
  },
  {
    "value": "78382",
    "label": "Rockport - TX 78382",
    "county": "Aransas County",
    "state": "TX",
    "city": "Rockport"
  },
  {
    "value": "78383",
    "label": "Sandia - TX 78383",
    "county": "Jim Wells County",
    "state": "TX",
    "city": "Sandia"
  },
  {
    "value": "78384",
    "label": "San Diego - TX 78384",
    "county": "Duval County",
    "state": "TX",
    "city": "San Diego"
  },
  {
    "value": "78387",
    "label": "Sinton - TX 78387",
    "county": "San Patricio County",
    "state": "TX",
    "city": "Sinton"
  },
  {
    "value": "78389",
    "label": "Skidmore - TX 78389",
    "county": "Bee County",
    "state": "TX",
    "city": "Skidmore"
  },
  {
    "value": "78390",
    "label": "Taft - TX 78390",
    "county": "San Patricio County",
    "state": "TX",
    "city": "Taft"
  },
  {
    "value": "78391",
    "label": "Tynan - TX 78391",
    "county": "Bee County",
    "state": "TX",
    "city": "Tynan"
  },
  {
    "value": "78393",
    "label": "Woodsboro - TX 78393",
    "county": "Refugio County",
    "state": "TX",
    "city": "Woodsboro"
  },
  {
    "value": "78401",
    "label": "Corpus Christi - TX 78401",
    "county": "Nueces County",
    "state": "TX",
    "city": "Corpus Christi"
  },
  {
    "value": "78402",
    "label": "Corpus Christi - TX 78402",
    "county": "Nueces County",
    "state": "TX",
    "city": "Corpus Christi"
  },
  {
    "value": "78404",
    "label": "Corpus Christi - TX 78404",
    "county": "Nueces County",
    "state": "TX",
    "city": "Corpus Christi"
  },
  {
    "value": "78405",
    "label": "Corpus Christi - TX 78405",
    "county": "Nueces County",
    "state": "TX",
    "city": "Corpus Christi"
  },
  {
    "value": "78406",
    "label": "Corpus Christi - TX 78406",
    "county": "Nueces County",
    "state": "TX",
    "city": "Corpus Christi"
  },
  {
    "value": "78407",
    "label": "Corpus Christi - TX 78407",
    "county": "Nueces County",
    "state": "TX",
    "city": "Corpus Christi"
  },
  {
    "value": "78408",
    "label": "Corpus Christi - TX 78408",
    "county": "Nueces County",
    "state": "TX",
    "city": "Corpus Christi"
  },
  {
    "value": "78409",
    "label": "Corpus Christi - TX 78409",
    "county": "Nueces County",
    "state": "TX",
    "city": "Corpus Christi"
  },
  {
    "value": "78410",
    "label": "Corpus Christi - TX 78410",
    "county": "Nueces County",
    "state": "TX",
    "city": "Corpus Christi"
  },
  {
    "value": "78411",
    "label": "Corpus Christi - TX 78411",
    "county": "Nueces County",
    "state": "TX",
    "city": "Corpus Christi"
  },
  {
    "value": "78412",
    "label": "Corpus Christi - TX 78412",
    "county": "Nueces County",
    "state": "TX",
    "city": "Corpus Christi"
  },
  {
    "value": "78413",
    "label": "Corpus Christi - TX 78413",
    "county": "Nueces County",
    "state": "TX",
    "city": "Corpus Christi"
  },
  {
    "value": "78414",
    "label": "Corpus Christi - TX 78414",
    "county": "Nueces County",
    "state": "TX",
    "city": "Corpus Christi"
  },
  {
    "value": "78415",
    "label": "Corpus Christi - TX 78415",
    "county": "Nueces County",
    "state": "TX",
    "city": "Corpus Christi"
  },
  {
    "value": "78416",
    "label": "Corpus Christi - TX 78416",
    "county": "Nueces County",
    "state": "TX",
    "city": "Corpus Christi"
  },
  {
    "value": "78417",
    "label": "Corpus Christi - TX 78417",
    "county": "Nueces County",
    "state": "TX",
    "city": "Corpus Christi"
  },
  {
    "value": "78418",
    "label": "Corpus Christi - TX 78418",
    "county": "Nueces County",
    "state": "TX",
    "city": "Corpus Christi"
  },
  {
    "value": "78419",
    "label": "Corpus Christi - TX 78419",
    "county": "Nueces County",
    "state": "TX",
    "city": "Corpus Christi"
  },
  {
    "value": "78470",
    "label": "Corpus Christi - TX 78470",
    "county": "Nueces County",
    "state": "TX",
    "city": "Corpus Christi"
  },
  {
    "value": "78471",
    "label": "Corpus Christi - TX 78471",
    "county": "Nueces County",
    "state": "TX",
    "city": "Corpus Christi"
  },
  {
    "value": "78473",
    "label": "Corpus Christi - TX 78473",
    "county": "Nueces County",
    "state": "TX",
    "city": "Corpus Christi"
  },
  {
    "value": "78474",
    "label": "Corpus Christi - TX 78474",
    "county": "Nueces County",
    "state": "TX",
    "city": "Corpus Christi"
  },
  {
    "value": "78475",
    "label": "Corpus Christi - TX 78475",
    "county": "Nueces County",
    "state": "TX",
    "city": "Corpus Christi"
  },
  {
    "value": "78476",
    "label": "Corpus Christi - TX 78476",
    "county": "Nueces County",
    "state": "TX",
    "city": "Corpus Christi"
  },
  {
    "value": "78477",
    "label": "Corpus Christi - TX 78477",
    "county": "Nueces County",
    "state": "TX",
    "city": "Corpus Christi"
  },
  {
    "value": "78478",
    "label": "Corpus Christi - TX 78478",
    "county": "Nueces County",
    "state": "TX",
    "city": "Corpus Christi"
  },
  {
    "value": "78501",
    "label": "Mcallen - TX 78501",
    "county": "Hidalgo County",
    "state": "TX",
    "city": "Mcallen"
  },
  {
    "value": "78503",
    "label": "Mcallen - TX 78503",
    "county": "Hidalgo County",
    "state": "TX",
    "city": "Mcallen"
  },
  {
    "value": "78504",
    "label": "Mcallen - TX 78504",
    "county": "Hidalgo County",
    "state": "TX",
    "city": "Mcallen"
  },
  {
    "value": "78516",
    "label": "Alamo - TX 78516",
    "county": "Hidalgo County",
    "state": "TX",
    "city": "Alamo"
  },
  {
    "value": "78520",
    "label": "Brownsville - TX 78520",
    "county": "Cameron County",
    "state": "TX",
    "city": "Brownsville"
  },
  {
    "value": "78521",
    "label": "Brownsville - TX 78521",
    "county": "Cameron County",
    "state": "TX",
    "city": "Brownsville"
  },
  {
    "value": "78526",
    "label": "Brownsville - TX 78526",
    "county": "Cameron County",
    "state": "TX",
    "city": "Brownsville"
  },
  {
    "value": "78536",
    "label": "Delmita - TX 78536",
    "county": "Starr County",
    "state": "TX",
    "city": "Delmita"
  },
  {
    "value": "78537",
    "label": "Donna - TX 78537",
    "county": "Hidalgo County",
    "state": "TX",
    "city": "Donna"
  },
  {
    "value": "78538",
    "label": "Edcouch - TX 78538",
    "county": "Hidalgo County",
    "state": "TX",
    "city": "Edcouch"
  },
  {
    "value": "78539",
    "label": "Edinburg - TX 78539",
    "county": "Hidalgo County",
    "state": "TX",
    "city": "Edinburg"
  },
  {
    "value": "78541",
    "label": "Edinburg - TX 78541",
    "county": "Hidalgo County",
    "state": "TX",
    "city": "Edinburg"
  },
  {
    "value": "78542",
    "label": "Edinburg - TX 78542",
    "county": "Hidalgo County",
    "state": "TX",
    "city": "Edinburg"
  },
  {
    "value": "78550",
    "label": "Harlingen - TX 78550",
    "county": "Cameron County",
    "state": "TX",
    "city": "Harlingen"
  },
  {
    "value": "78552",
    "label": "Harlingen - TX 78552",
    "county": "Cameron County",
    "state": "TX",
    "city": "Harlingen"
  },
  {
    "value": "78557",
    "label": "Hidalgo - TX 78557",
    "county": "Hidalgo County",
    "state": "TX",
    "city": "Hidalgo"
  },
  {
    "value": "78559",
    "label": "La Feria - TX 78559",
    "county": "Cameron County",
    "state": "TX",
    "city": "La Feria"
  },
  {
    "value": "78560",
    "label": "La Joya - TX 78560",
    "county": "Hidalgo County",
    "state": "TX",
    "city": "La Joya"
  },
  {
    "value": "78563",
    "label": "Linn - TX 78563",
    "county": "Hidalgo County",
    "state": "TX",
    "city": "Linn"
  },
  {
    "value": "78564",
    "label": "Lopeno - TX 78564",
    "county": "Zapata County",
    "state": "TX",
    "city": "Lopeno"
  },
  {
    "value": "78566",
    "label": "Los Fresnos - TX 78566",
    "county": "Cameron County",
    "state": "TX",
    "city": "Los Fresnos"
  },
  {
    "value": "78569",
    "label": "Lyford - TX 78569",
    "county": "Willacy County",
    "state": "TX",
    "city": "Lyford"
  },
  {
    "value": "78570",
    "label": "Mercedes - TX 78570",
    "county": "Hidalgo County",
    "state": "TX",
    "city": "Mercedes"
  },
  {
    "value": "78572",
    "label": "Mission - TX 78572",
    "county": "Hidalgo County",
    "state": "TX",
    "city": "Mission"
  },
  {
    "value": "78573",
    "label": "Mission - TX 78573",
    "county": "Hidalgo County",
    "state": "TX",
    "city": "Mission"
  },
  {
    "value": "78574",
    "label": "Mission - TX 78574",
    "county": "Hidalgo County",
    "state": "TX",
    "city": "Mission"
  },
  {
    "value": "78575",
    "label": "Olmito - TX 78575",
    "county": "Cameron County",
    "state": "TX",
    "city": "Olmito"
  },
  {
    "value": "78576",
    "label": "Penitas - TX 78576",
    "county": "Hidalgo County",
    "state": "TX",
    "city": "Penitas"
  },
  {
    "value": "78577",
    "label": "Pharr - TX 78577",
    "county": "Hidalgo County",
    "state": "TX",
    "city": "Pharr"
  },
  {
    "value": "78578",
    "label": "Port Isabel - TX 78578",
    "county": "Cameron County",
    "state": "TX",
    "city": "Port Isabel"
  },
  {
    "value": "78580",
    "label": "Raymondville - TX 78580",
    "county": "Willacy County",
    "state": "TX",
    "city": "Raymondville"
  },
  {
    "value": "78582",
    "label": "Rio Grande City - TX 78582",
    "county": "Starr County",
    "state": "TX",
    "city": "Rio Grande City"
  },
  {
    "value": "78583",
    "label": "Rio Hondo - TX 78583",
    "county": "Cameron County",
    "state": "TX",
    "city": "Rio Hondo"
  },
  {
    "value": "78584",
    "label": "Roma - TX 78584",
    "county": "Starr County",
    "state": "TX",
    "city": "Roma"
  },
  {
    "value": "78586",
    "label": "San Benito - TX 78586",
    "county": "Cameron County",
    "state": "TX",
    "city": "San Benito"
  },
  {
    "value": "78588",
    "label": "San Isidro - TX 78588",
    "county": "Starr County",
    "state": "TX",
    "city": "San Isidro"
  },
  {
    "value": "78589",
    "label": "San Juan - TX 78589",
    "county": "Hidalgo County",
    "state": "TX",
    "city": "San Juan"
  },
  {
    "value": "78591",
    "label": "Santa Elena - TX 78591",
    "county": "Starr County",
    "state": "TX",
    "city": "Santa Elena"
  },
  {
    "value": "78593",
    "label": "Santa Rosa - TX 78593",
    "county": "Cameron County",
    "state": "TX",
    "city": "Santa Rosa"
  },
  {
    "value": "78595",
    "label": "Sullivan City - TX 78595",
    "county": "Hidalgo County",
    "state": "TX",
    "city": "Sullivan City"
  },
  {
    "value": "78596",
    "label": "Weslaco - TX 78596",
    "county": "Hidalgo County",
    "state": "TX",
    "city": "Weslaco"
  },
  {
    "value": "78597",
    "label": "South Padre Island - TX 78597",
    "county": "Cameron County",
    "state": "TX",
    "city": "South Padre Island"
  },
  {
    "value": "78599",
    "label": "Weslaco - TX 78599",
    "county": "Hidalgo County",
    "state": "TX",
    "city": "Weslaco"
  },
  {
    "value": "78602",
    "label": "Bastrop - TX 78602",
    "county": "Bastrop County",
    "state": "TX",
    "city": "Bastrop"
  },
  {
    "value": "78605",
    "label": "Bertram - TX 78605",
    "county": "Burnet County",
    "state": "TX",
    "city": "Bertram"
  },
  {
    "value": "78606",
    "label": "Blanco - TX 78606",
    "county": "Blanco County",
    "state": "TX",
    "city": "Blanco"
  },
  {
    "value": "78607",
    "label": "Bluffton - TX 78607",
    "county": "Llano County",
    "state": "TX",
    "city": "Bluffton"
  },
  {
    "value": "78608",
    "label": "Briggs - TX 78608",
    "county": "Burnet County",
    "state": "TX",
    "city": "Briggs"
  },
  {
    "value": "78609",
    "label": "Buchanan Dam - TX 78609",
    "county": "Llano County",
    "state": "TX",
    "city": "Buchanan Dam"
  },
  {
    "value": "78610",
    "label": "Buda - TX 78610",
    "county": "Hays County",
    "state": "TX",
    "city": "Buda"
  },
  {
    "value": "78611",
    "label": "Burnet - TX 78611",
    "county": "Burnet County",
    "state": "TX",
    "city": "Burnet"
  },
  {
    "value": "78612",
    "label": "Cedar Creek - TX 78612",
    "county": "Bastrop County",
    "state": "TX",
    "city": "Cedar Creek"
  },
  {
    "value": "78613",
    "label": "Cedar Park - TX 78613",
    "county": "Williamson County",
    "state": "TX",
    "city": "Cedar Park"
  },
  {
    "value": "78614",
    "label": "Cost - TX 78614",
    "county": "Gonzales County",
    "state": "TX",
    "city": "Cost"
  },
  {
    "value": "78615",
    "label": "Coupland - TX 78615",
    "county": "Williamson County",
    "state": "TX",
    "city": "Coupland"
  },
  {
    "value": "78616",
    "label": "Dale - TX 78616",
    "county": "Caldwell County",
    "state": "TX",
    "city": "Dale"
  },
  {
    "value": "78617",
    "label": "Del Valle - TX 78617",
    "county": "Travis County",
    "state": "TX",
    "city": "Del Valle"
  },
  {
    "value": "78618",
    "label": "Doss - TX 78618",
    "county": "Gillespie County",
    "state": "TX",
    "city": "Doss"
  },
  {
    "value": "78619",
    "label": "Driftwood - TX 78619",
    "county": "Hays County",
    "state": "TX",
    "city": "Driftwood"
  },
  {
    "value": "78620",
    "label": "Dripping Springs - TX 78620",
    "county": "Hays County",
    "state": "TX",
    "city": "Dripping Springs"
  },
  {
    "value": "78621",
    "label": "Elgin - TX 78621",
    "county": "Bastrop County",
    "state": "TX",
    "city": "Elgin"
  },
  {
    "value": "78623",
    "label": "Fischer - TX 78623",
    "county": "Comal County",
    "state": "TX",
    "city": "Fischer"
  },
  {
    "value": "78624",
    "label": "Fredericksburg - TX 78624",
    "county": "Gillespie County",
    "state": "TX",
    "city": "Fredericksburg"
  },
  {
    "value": "78626",
    "label": "Georgetown - TX 78626",
    "county": "Williamson County",
    "state": "TX",
    "city": "Georgetown"
  },
  {
    "value": "78628",
    "label": "Georgetown - TX 78628",
    "county": "Williamson County",
    "state": "TX",
    "city": "Georgetown"
  },
  {
    "value": "78629",
    "label": "Gonzales - TX 78629",
    "county": "Gonzales County",
    "state": "TX",
    "city": "Gonzales"
  },
  {
    "value": "78631",
    "label": "Harper - TX 78631",
    "county": "Gillespie County",
    "state": "TX",
    "city": "Harper"
  },
  {
    "value": "78632",
    "label": "Harwood - TX 78632",
    "county": "Gonzales County",
    "state": "TX",
    "city": "Harwood"
  },
  {
    "value": "78633",
    "label": "Georgetown - TX 78633",
    "county": "Williamson County",
    "state": "TX",
    "city": "Georgetown"
  },
  {
    "value": "78634",
    "label": "Hutto - TX 78634",
    "county": "Williamson County",
    "state": "TX",
    "city": "Hutto"
  },
  {
    "value": "78635",
    "label": "Hye - TX 78635",
    "county": "Blanco County",
    "state": "TX",
    "city": "Hye"
  },
  {
    "value": "78636",
    "label": "Johnson City - TX 78636",
    "county": "Blanco County",
    "state": "TX",
    "city": "Johnson City"
  },
  {
    "value": "78638",
    "label": "Kingsbury - TX 78638",
    "county": "Guadalupe County",
    "state": "TX",
    "city": "Kingsbury"
  },
  {
    "value": "78639",
    "label": "Kingsland - TX 78639",
    "county": "Llano County",
    "state": "TX",
    "city": "Kingsland"
  },
  {
    "value": "78640",
    "label": "Kyle - TX 78640",
    "county": "Hays County",
    "state": "TX",
    "city": "Kyle"
  },
  {
    "value": "78641",
    "label": "Leander - TX 78641",
    "county": "Williamson County",
    "state": "TX",
    "city": "Leander"
  },
  {
    "value": "78642",
    "label": "Liberty Hill - TX 78642",
    "county": "Williamson County",
    "state": "TX",
    "city": "Liberty Hill"
  },
  {
    "value": "78643",
    "label": "Llano - TX 78643",
    "county": "Llano County",
    "state": "TX",
    "city": "Llano"
  },
  {
    "value": "78644",
    "label": "Lockhart - TX 78644",
    "county": "Caldwell County",
    "state": "TX",
    "city": "Lockhart"
  },
  {
    "value": "78645",
    "label": "Leander - TX 78645",
    "county": "Travis County",
    "state": "TX",
    "city": "Leander"
  },
  {
    "value": "78648",
    "label": "Luling - TX 78648",
    "county": "Caldwell County",
    "state": "TX",
    "city": "Luling"
  },
  {
    "value": "78650",
    "label": "Mc Dade - TX 78650",
    "county": "Bastrop County",
    "state": "TX",
    "city": "Mc Dade"
  },
  {
    "value": "78652",
    "label": "Manchaca - TX 78652",
    "county": "Travis County",
    "state": "TX",
    "city": "Manchaca"
  },
  {
    "value": "78653",
    "label": "Manor - TX 78653",
    "county": "Travis County",
    "state": "TX",
    "city": "Manor"
  },
  {
    "value": "78654",
    "label": "Marble Falls - TX 78654",
    "county": "Burnet County",
    "state": "TX",
    "city": "Marble Falls"
  },
  {
    "value": "78655",
    "label": "Martindale - TX 78655",
    "county": "Caldwell County",
    "state": "TX",
    "city": "Martindale"
  },
  {
    "value": "78656",
    "label": "Maxwell - TX 78656",
    "county": "Caldwell County",
    "state": "TX",
    "city": "Maxwell"
  },
  {
    "value": "78657",
    "label": "Horseshoe Bay - TX 78657",
    "county": "Llano County",
    "state": "TX",
    "city": "Horseshoe Bay"
  },
  {
    "value": "78659",
    "label": "Paige - TX 78659",
    "county": "Bastrop County",
    "state": "TX",
    "city": "Paige"
  },
  {
    "value": "78660",
    "label": "Pflugerville - TX 78660",
    "county": "Travis County",
    "state": "TX",
    "city": "Pflugerville"
  },
  {
    "value": "78662",
    "label": "Red Rock - TX 78662",
    "county": "Bastrop County",
    "state": "TX",
    "city": "Red Rock"
  },
  {
    "value": "78663",
    "label": "Round Mountain - TX 78663",
    "county": "Blanco County",
    "state": "TX",
    "city": "Round Mountain"
  },
  {
    "value": "78664",
    "label": "Round Rock - TX 78664",
    "county": "Williamson County",
    "state": "TX",
    "city": "Round Rock"
  },
  {
    "value": "78665",
    "label": "Round Rock - TX 78665",
    "county": "Williamson County",
    "state": "TX",
    "city": "Round Rock"
  },
  {
    "value": "78666",
    "label": "San Marcos - TX 78666",
    "county": "Hays County",
    "state": "TX",
    "city": "San Marcos"
  },
  {
    "value": "78669",
    "label": "Spicewood - TX 78669",
    "county": "Travis County",
    "state": "TX",
    "city": "Spicewood"
  },
  {
    "value": "78671",
    "label": "Stonewall - TX 78671",
    "county": "Gillespie County",
    "state": "TX",
    "city": "Stonewall"
  },
  {
    "value": "78672",
    "label": "Tow - TX 78672",
    "county": "Llano County",
    "state": "TX",
    "city": "Tow"
  },
  {
    "value": "78675",
    "label": "Willow City - TX 78675",
    "county": "Gillespie County",
    "state": "TX",
    "city": "Willow City"
  },
  {
    "value": "78676",
    "label": "Wimberley - TX 78676",
    "county": "Hays County",
    "state": "TX",
    "city": "Wimberley"
  },
  {
    "value": "78677",
    "label": "Wrightsboro - TX 78677",
    "county": "Gonzales County",
    "state": "TX",
    "city": "Wrightsboro"
  },
  {
    "value": "78681",
    "label": "Round Rock - TX 78681",
    "county": "Williamson County",
    "state": "TX",
    "city": "Round Rock"
  },
  {
    "value": "78701",
    "label": "Austin - TX 78701",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78702",
    "label": "Austin - TX 78702",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78703",
    "label": "Austin - TX 78703",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78704",
    "label": "Austin - TX 78704",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78705",
    "label": "Austin - TX 78705",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78710",
    "label": "Austin - TX 78710",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78712",
    "label": "Austin - TX 78712",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78717",
    "label": "Austin - TX 78717",
    "county": "Williamson County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78719",
    "label": "Austin - TX 78719",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78721",
    "label": "Austin - TX 78721",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78722",
    "label": "Austin - TX 78722",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78723",
    "label": "Austin - TX 78723",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78724",
    "label": "Austin - TX 78724",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78725",
    "label": "Austin - TX 78725",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78726",
    "label": "Austin - TX 78726",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78727",
    "label": "Austin - TX 78727",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78728",
    "label": "Austin - TX 78728",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78729",
    "label": "Austin - TX 78729",
    "county": "Williamson County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78730",
    "label": "Austin - TX 78730",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78731",
    "label": "Austin - TX 78731",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78732",
    "label": "Austin - TX 78732",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78733",
    "label": "Austin - TX 78733",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78734",
    "label": "Austin - TX 78734",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78735",
    "label": "Austin - TX 78735",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78736",
    "label": "Austin - TX 78736",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78737",
    "label": "Austin - TX 78737",
    "county": "Hays County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78738",
    "label": "Austin - TX 78738",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78739",
    "label": "Austin - TX 78739",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78741",
    "label": "Austin - TX 78741",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78742",
    "label": "Austin - TX 78742",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78744",
    "label": "Austin - TX 78744",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78745",
    "label": "Austin - TX 78745",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78746",
    "label": "Austin - TX 78746",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78747",
    "label": "Austin - TX 78747",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78748",
    "label": "Austin - TX 78748",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78749",
    "label": "Austin - TX 78749",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78750",
    "label": "Austin - TX 78750",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78751",
    "label": "Austin - TX 78751",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78752",
    "label": "Austin - TX 78752",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78753",
    "label": "Austin - TX 78753",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78754",
    "label": "Austin - TX 78754",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78756",
    "label": "Austin - TX 78756",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78757",
    "label": "Austin - TX 78757",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78758",
    "label": "Austin - TX 78758",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78759",
    "label": "Austin - TX 78759",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78798",
    "label": "Austin - TX 78798",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78799",
    "label": "Austin - TX 78799",
    "county": "Travis County",
    "state": "TX",
    "city": "Austin"
  },
  {
    "value": "78801",
    "label": "Uvalde - TX 78801",
    "county": "Uvalde County",
    "state": "TX",
    "city": "Uvalde"
  },
  {
    "value": "78827",
    "label": "Asherton - TX 78827",
    "county": "Dimmit County",
    "state": "TX",
    "city": "Asherton"
  },
  {
    "value": "78828",
    "label": "Barksdale - TX 78828",
    "county": "Edwards County",
    "state": "TX",
    "city": "Barksdale"
  },
  {
    "value": "78829",
    "label": "Batesville - TX 78829",
    "county": "Zavala County",
    "state": "TX",
    "city": "Batesville"
  },
  {
    "value": "78830",
    "label": "Big Wells - TX 78830",
    "county": "Dimmit County",
    "state": "TX",
    "city": "Big Wells"
  },
  {
    "value": "78832",
    "label": "Brackettville - TX 78832",
    "county": "Kinney County",
    "state": "TX",
    "city": "Brackettville"
  },
  {
    "value": "78833",
    "label": "Camp Wood - TX 78833",
    "county": "Real County",
    "state": "TX",
    "city": "Camp Wood"
  },
  {
    "value": "78834",
    "label": "Carrizo Springs - TX 78834",
    "county": "Dimmit County",
    "state": "TX",
    "city": "Carrizo Springs"
  },
  {
    "value": "78837",
    "label": "Comstock - TX 78837",
    "county": "Val Verde County",
    "state": "TX",
    "city": "Comstock"
  },
  {
    "value": "78838",
    "label": "Concan - TX 78838",
    "county": "Uvalde County",
    "state": "TX",
    "city": "Concan"
  },
  {
    "value": "78839",
    "label": "Crystal City - TX 78839",
    "county": "Zavala County",
    "state": "TX",
    "city": "Crystal City"
  },
  {
    "value": "78840",
    "label": "Del Rio - TX 78840",
    "county": "Val Verde County",
    "state": "TX",
    "city": "Del Rio"
  },
  {
    "value": "78843",
    "label": "Laughlin AFB - TX 78843",
    "county": "Val Verde County",
    "state": "TX",
    "city": "Laughlin AFB"
  },
  {
    "value": "78850",
    "label": "D Hanis - TX 78850",
    "county": "Medina County",
    "state": "TX",
    "city": "D Hanis"
  },
  {
    "value": "78851",
    "label": "Dryden - TX 78851",
    "county": "Terrell County",
    "state": "TX",
    "city": "Dryden"
  },
  {
    "value": "78852",
    "label": "Eagle Pass - TX 78852",
    "county": "Maverick County",
    "state": "TX",
    "city": "Eagle Pass"
  },
  {
    "value": "78861",
    "label": "Hondo - TX 78861",
    "county": "Medina County",
    "state": "TX",
    "city": "Hondo"
  },
  {
    "value": "78870",
    "label": "Knippa - TX 78870",
    "county": "Uvalde County",
    "state": "TX",
    "city": "Knippa"
  },
  {
    "value": "78873",
    "label": "Leakey - TX 78873",
    "county": "Real County",
    "state": "TX",
    "city": "Leakey"
  },
  {
    "value": "78877",
    "label": "Quemado - TX 78877",
    "county": "Maverick County",
    "state": "TX",
    "city": "Quemado"
  },
  {
    "value": "78879",
    "label": "Rio Frio - TX 78879",
    "county": "Real County",
    "state": "TX",
    "city": "Rio Frio"
  },
  {
    "value": "78880",
    "label": "Rocksprings - TX 78880",
    "county": "Edwards County",
    "state": "TX",
    "city": "Rocksprings"
  },
  {
    "value": "78881",
    "label": "Sabinal - TX 78881",
    "county": "Uvalde County",
    "state": "TX",
    "city": "Sabinal"
  },
  {
    "value": "78883",
    "label": "Tarpley - TX 78883",
    "county": "Bandera County",
    "state": "TX",
    "city": "Tarpley"
  },
  {
    "value": "78884",
    "label": "Utopia - TX 78884",
    "county": "Uvalde County",
    "state": "TX",
    "city": "Utopia"
  },
  {
    "value": "78885",
    "label": "Vanderpool - TX 78885",
    "county": "Bandera County",
    "state": "TX",
    "city": "Vanderpool"
  },
  {
    "value": "78886",
    "label": "Yancey - TX 78886",
    "county": "Medina County",
    "state": "TX",
    "city": "Yancey"
  },
  {
    "value": "78931",
    "label": "Bleiblerville - TX 78931",
    "county": "Austin County",
    "state": "TX",
    "city": "Bleiblerville"
  },
  {
    "value": "78932",
    "label": "Carmine - TX 78932",
    "county": "Fayette County",
    "state": "TX",
    "city": "Carmine"
  },
  {
    "value": "78933",
    "label": "Cat Spring - TX 78933",
    "county": "Colorado County",
    "state": "TX",
    "city": "Cat Spring"
  },
  {
    "value": "78934",
    "label": "Columbus - TX 78934",
    "county": "Colorado County",
    "state": "TX",
    "city": "Columbus"
  },
  {
    "value": "78935",
    "label": "Alleyton - TX 78935",
    "county": "Colorado County",
    "state": "TX",
    "city": "Alleyton"
  },
  {
    "value": "78938",
    "label": "Ellinger - TX 78938",
    "county": "Fayette County",
    "state": "TX",
    "city": "Ellinger"
  },
  {
    "value": "78940",
    "label": "Fayetteville - TX 78940",
    "county": "Fayette County",
    "state": "TX",
    "city": "Fayetteville"
  },
  {
    "value": "78941",
    "label": "Flatonia - TX 78941",
    "county": "Fayette County",
    "state": "TX",
    "city": "Flatonia"
  },
  {
    "value": "78942",
    "label": "Giddings - TX 78942",
    "county": "Lee County",
    "state": "TX",
    "city": "Giddings"
  },
  {
    "value": "78943",
    "label": "Glidden - TX 78943",
    "county": "Colorado County",
    "state": "TX",
    "city": "Glidden"
  },
  {
    "value": "78944",
    "label": "Industry - TX 78944",
    "county": "Austin County",
    "state": "TX",
    "city": "Industry"
  },
  {
    "value": "78945",
    "label": "La Grange - TX 78945",
    "county": "Fayette County",
    "state": "TX",
    "city": "La Grange"
  },
  {
    "value": "78946",
    "label": "Ledbetter - TX 78946",
    "county": "Lee County",
    "state": "TX",
    "city": "Ledbetter"
  },
  {
    "value": "78947",
    "label": "Lexington - TX 78947",
    "county": "Lee County",
    "state": "TX",
    "city": "Lexington"
  },
  {
    "value": "78948",
    "label": "Lincoln - TX 78948",
    "county": "Lee County",
    "state": "TX",
    "city": "Lincoln"
  },
  {
    "value": "78949",
    "label": "Muldoon - TX 78949",
    "county": "Fayette County",
    "state": "TX",
    "city": "Muldoon"
  },
  {
    "value": "78950",
    "label": "New Ulm - TX 78950",
    "county": "Austin County",
    "state": "TX",
    "city": "New Ulm"
  },
  {
    "value": "78953",
    "label": "Rosanky - TX 78953",
    "county": "Bastrop County",
    "state": "TX",
    "city": "Rosanky"
  },
  {
    "value": "78954",
    "label": "Round Top - TX 78954",
    "county": "Fayette County",
    "state": "TX",
    "city": "Round Top"
  },
  {
    "value": "78956",
    "label": "Schulenburg - TX 78956",
    "county": "Fayette County",
    "state": "TX",
    "city": "Schulenburg"
  },
  {
    "value": "78957",
    "label": "Smithville - TX 78957",
    "county": "Bastrop County",
    "state": "TX",
    "city": "Smithville"
  },
  {
    "value": "78959",
    "label": "Waelder - TX 78959",
    "county": "Gonzales County",
    "state": "TX",
    "city": "Waelder"
  },
  {
    "value": "78962",
    "label": "Weimar - TX 78962",
    "county": "Colorado County",
    "state": "TX",
    "city": "Weimar"
  },
  {
    "value": "78963",
    "label": "West Point - TX 78963",
    "county": "Fayette County",
    "state": "TX",
    "city": "West Point"
  },
  {
    "value": "79001",
    "label": "Adrian - TX 79001",
    "county": "Oldham County",
    "state": "TX",
    "city": "Adrian"
  },
  {
    "value": "79005",
    "label": "Booker - TX 79005",
    "county": "Lipscomb County",
    "state": "TX",
    "city": "Booker"
  },
  {
    "value": "79007",
    "label": "Borger - TX 79007",
    "county": "Hutchinson County",
    "state": "TX",
    "city": "Borger"
  },
  {
    "value": "79009",
    "label": "Bovina - TX 79009",
    "county": "Parmer County",
    "state": "TX",
    "city": "Bovina"
  },
  {
    "value": "79011",
    "label": "Briscoe - TX 79011",
    "county": "Wheeler County",
    "state": "TX",
    "city": "Briscoe"
  },
  {
    "value": "79013",
    "label": "Cactus - TX 79013",
    "county": "Moore County",
    "state": "TX",
    "city": "Cactus"
  },
  {
    "value": "79014",
    "label": "Canadian - TX 79014",
    "county": "Hemphill County",
    "state": "TX",
    "city": "Canadian"
  },
  {
    "value": "79015",
    "label": "Canyon - TX 79015",
    "county": "Randall County",
    "state": "TX",
    "city": "Canyon"
  },
  {
    "value": "79018",
    "label": "Channing - TX 79018",
    "county": "Hartley County",
    "state": "TX",
    "city": "Channing"
  },
  {
    "value": "79019",
    "label": "Claude - TX 79019",
    "county": "Armstrong County",
    "state": "TX",
    "city": "Claude"
  },
  {
    "value": "79022",
    "label": "Dalhart - TX 79022",
    "county": "Dallam County",
    "state": "TX",
    "city": "Dalhart"
  },
  {
    "value": "79027",
    "label": "Dimmitt - TX 79027",
    "county": "Castro County",
    "state": "TX",
    "city": "Dimmitt"
  },
  {
    "value": "79029",
    "label": "Dumas - TX 79029",
    "county": "Moore County",
    "state": "TX",
    "city": "Dumas"
  },
  {
    "value": "79031",
    "label": "Earth - TX 79031",
    "county": "Lamb County",
    "state": "TX",
    "city": "Earth"
  },
  {
    "value": "79034",
    "label": "Follett - TX 79034",
    "county": "Lipscomb County",
    "state": "TX",
    "city": "Follett"
  },
  {
    "value": "79035",
    "label": "Friona - TX 79035",
    "county": "Parmer County",
    "state": "TX",
    "city": "Friona"
  },
  {
    "value": "79036",
    "label": "Fritch - TX 79036",
    "county": "Hutchinson County",
    "state": "TX",
    "city": "Fritch"
  },
  {
    "value": "79039",
    "label": "Groom - TX 79039",
    "county": "Carson County",
    "state": "TX",
    "city": "Groom"
  },
  {
    "value": "79040",
    "label": "Gruver - TX 79040",
    "county": "Hansford County",
    "state": "TX",
    "city": "Gruver"
  },
  {
    "value": "79041",
    "label": "Hale Center - TX 79041",
    "county": "Hale County",
    "state": "TX",
    "city": "Hale Center"
  },
  {
    "value": "79042",
    "label": "Happy - TX 79042",
    "county": "Swisher County",
    "state": "TX",
    "city": "Happy"
  },
  {
    "value": "79043",
    "label": "Hart - TX 79043",
    "county": "Castro County",
    "state": "TX",
    "city": "Hart"
  },
  {
    "value": "79044",
    "label": "Hartley - TX 79044",
    "county": "Hartley County",
    "state": "TX",
    "city": "Hartley"
  },
  {
    "value": "79045",
    "label": "Hereford - TX 79045",
    "county": "Deaf Smith County",
    "state": "TX",
    "city": "Hereford"
  },
  {
    "value": "79046",
    "label": "Higgins - TX 79046",
    "county": "Lipscomb County",
    "state": "TX",
    "city": "Higgins"
  },
  {
    "value": "79052",
    "label": "Kress - TX 79052",
    "county": "Swisher County",
    "state": "TX",
    "city": "Kress"
  },
  {
    "value": "79056",
    "label": "Lipscomb - TX 79056",
    "county": "Lipscomb County",
    "state": "TX",
    "city": "Lipscomb"
  },
  {
    "value": "79057",
    "label": "Mclean - TX 79057",
    "county": "Gray County",
    "state": "TX",
    "city": "Mclean"
  },
  {
    "value": "79058",
    "label": "Masterson - TX 79058",
    "county": "Potter County",
    "state": "TX",
    "city": "Masterson"
  },
  {
    "value": "79059",
    "label": "Miami - TX 79059",
    "county": "Roberts County",
    "state": "TX",
    "city": "Miami"
  },
  {
    "value": "79061",
    "label": "Mobeetie - TX 79061",
    "county": "Wheeler County",
    "state": "TX",
    "city": "Mobeetie"
  },
  {
    "value": "79062",
    "label": "Morse - TX 79062",
    "county": "Hansford County",
    "state": "TX",
    "city": "Morse"
  },
  {
    "value": "79063",
    "label": "Nazareth - TX 79063",
    "county": "Castro County",
    "state": "TX",
    "city": "Nazareth"
  },
  {
    "value": "79064",
    "label": "Olton - TX 79064",
    "county": "Lamb County",
    "state": "TX",
    "city": "Olton"
  },
  {
    "value": "79065",
    "label": "Pampa - TX 79065",
    "county": "Gray County",
    "state": "TX",
    "city": "Pampa"
  },
  {
    "value": "79068",
    "label": "Panhandle - TX 79068",
    "county": "Carson County",
    "state": "TX",
    "city": "Panhandle"
  },
  {
    "value": "79070",
    "label": "Perryton - TX 79070",
    "county": "Ochiltree County",
    "state": "TX",
    "city": "Perryton"
  },
  {
    "value": "79072",
    "label": "Plainview - TX 79072",
    "county": "Hale County",
    "state": "TX",
    "city": "Plainview"
  },
  {
    "value": "79079",
    "label": "Shamrock - TX 79079",
    "county": "Wheeler County",
    "state": "TX",
    "city": "Shamrock"
  },
  {
    "value": "79080",
    "label": "Skellytown - TX 79080",
    "county": "Carson County",
    "state": "TX",
    "city": "Skellytown"
  },
  {
    "value": "79081",
    "label": "Spearman - TX 79081",
    "county": "Hansford County",
    "state": "TX",
    "city": "Spearman"
  },
  {
    "value": "79082",
    "label": "Springlake - TX 79082",
    "county": "Lamb County",
    "state": "TX",
    "city": "Springlake"
  },
  {
    "value": "79083",
    "label": "Stinnett - TX 79083",
    "county": "Hutchinson County",
    "state": "TX",
    "city": "Stinnett"
  },
  {
    "value": "79084",
    "label": "Stratford - TX 79084",
    "county": "Sherman County",
    "state": "TX",
    "city": "Stratford"
  },
  {
    "value": "79085",
    "label": "Summerfield - TX 79085",
    "county": "Castro County",
    "state": "TX",
    "city": "Summerfield"
  },
  {
    "value": "79086",
    "label": "Sunray - TX 79086",
    "county": "Moore County",
    "state": "TX",
    "city": "Sunray"
  },
  {
    "value": "79087",
    "label": "Texline - TX 79087",
    "county": "Dallam County",
    "state": "TX",
    "city": "Texline"
  },
  {
    "value": "79088",
    "label": "Tulia - TX 79088",
    "county": "Swisher County",
    "state": "TX",
    "city": "Tulia"
  },
  {
    "value": "79092",
    "label": "Vega - TX 79092",
    "county": "Oldham County",
    "state": "TX",
    "city": "Vega"
  },
  {
    "value": "79094",
    "label": "Wayside - TX 79094",
    "county": "Armstrong County",
    "state": "TX",
    "city": "Wayside"
  },
  {
    "value": "79095",
    "label": "Wellington - TX 79095",
    "county": "Collingsworth County",
    "state": "TX",
    "city": "Wellington"
  },
  {
    "value": "79096",
    "label": "Wheeler - TX 79096",
    "county": "Wheeler County",
    "state": "TX",
    "city": "Wheeler"
  },
  {
    "value": "79097",
    "label": "White Deer - TX 79097",
    "county": "Carson County",
    "state": "TX",
    "city": "White Deer"
  },
  {
    "value": "79098",
    "label": "Wildorado - TX 79098",
    "county": "Oldham County",
    "state": "TX",
    "city": "Wildorado"
  },
  {
    "value": "79101",
    "label": "Amarillo - TX 79101",
    "county": "Potter County",
    "state": "TX",
    "city": "Amarillo"
  },
  {
    "value": "79102",
    "label": "Amarillo - TX 79102",
    "county": "Potter County",
    "state": "TX",
    "city": "Amarillo"
  },
  {
    "value": "79103",
    "label": "Amarillo - TX 79103",
    "county": "Potter County",
    "state": "TX",
    "city": "Amarillo"
  },
  {
    "value": "79104",
    "label": "Amarillo - TX 79104",
    "county": "Potter County",
    "state": "TX",
    "city": "Amarillo"
  },
  {
    "value": "79106",
    "label": "Amarillo - TX 79106",
    "county": "Potter County",
    "state": "TX",
    "city": "Amarillo"
  },
  {
    "value": "79107",
    "label": "Amarillo - TX 79107",
    "county": "Potter County",
    "state": "TX",
    "city": "Amarillo"
  },
  {
    "value": "79108",
    "label": "Amarillo - TX 79108",
    "county": "Potter County",
    "state": "TX",
    "city": "Amarillo"
  },
  {
    "value": "79109",
    "label": "Amarillo - TX 79109",
    "county": "Randall County",
    "state": "TX",
    "city": "Amarillo"
  },
  {
    "value": "79110",
    "label": "Amarillo - TX 79110",
    "county": "Randall County",
    "state": "TX",
    "city": "Amarillo"
  },
  {
    "value": "79111",
    "label": "Amarillo - TX 79111",
    "county": "Potter County",
    "state": "TX",
    "city": "Amarillo"
  },
  {
    "value": "79118",
    "label": "Amarillo - TX 79118",
    "county": "Randall County",
    "state": "TX",
    "city": "Amarillo"
  },
  {
    "value": "79119",
    "label": "Amarillo - TX 79119",
    "county": "Randall County",
    "state": "TX",
    "city": "Amarillo"
  },
  {
    "value": "79121",
    "label": "Amarillo - TX 79121",
    "county": "Randall County",
    "state": "TX",
    "city": "Amarillo"
  },
  {
    "value": "79124",
    "label": "Amarillo - TX 79124",
    "county": "Potter County",
    "state": "TX",
    "city": "Amarillo"
  },
  {
    "value": "79201",
    "label": "Childress - TX 79201",
    "county": "Childress County",
    "state": "TX",
    "city": "Childress"
  },
  {
    "value": "79220",
    "label": "Afton - TX 79220",
    "county": "Dickens County",
    "state": "TX",
    "city": "Afton"
  },
  {
    "value": "79223",
    "label": "Cee Vee - TX 79223",
    "county": "Cottle County",
    "state": "TX",
    "city": "Cee Vee"
  },
  {
    "value": "79225",
    "label": "Chillicothe - TX 79225",
    "county": "Hardeman County",
    "state": "TX",
    "city": "Chillicothe"
  },
  {
    "value": "79226",
    "label": "Clarendon - TX 79226",
    "county": "Donley County",
    "state": "TX",
    "city": "Clarendon"
  },
  {
    "value": "79227",
    "label": "Crowell - TX 79227",
    "county": "Foard County",
    "state": "TX",
    "city": "Crowell"
  },
  {
    "value": "79229",
    "label": "Dickens - TX 79229",
    "county": "Dickens County",
    "state": "TX",
    "city": "Dickens"
  },
  {
    "value": "79230",
    "label": "Dodson - TX 79230",
    "county": "Collingsworth County",
    "state": "TX",
    "city": "Dodson"
  },
  {
    "value": "79234",
    "label": "Flomot - TX 79234",
    "county": "Motley County",
    "state": "TX",
    "city": "Flomot"
  },
  {
    "value": "79235",
    "label": "Floydada - TX 79235",
    "county": "Floyd County",
    "state": "TX",
    "city": "Floydada"
  },
  {
    "value": "79237",
    "label": "Hedley - TX 79237",
    "county": "Donley County",
    "state": "TX",
    "city": "Hedley"
  },
  {
    "value": "79239",
    "label": "Lakeview - TX 79239",
    "county": "Hall County",
    "state": "TX",
    "city": "Lakeview"
  },
  {
    "value": "79240",
    "label": "Lelia Lake - TX 79240",
    "county": "Donley County",
    "state": "TX",
    "city": "Lelia Lake"
  },
  {
    "value": "79241",
    "label": "Lockney - TX 79241",
    "county": "Floyd County",
    "state": "TX",
    "city": "Lockney"
  },
  {
    "value": "79243",
    "label": "Mcadoo - TX 79243",
    "county": "Dickens County",
    "state": "TX",
    "city": "Mcadoo"
  },
  {
    "value": "79244",
    "label": "Matador - TX 79244",
    "county": "Motley County",
    "state": "TX",
    "city": "Matador"
  },
  {
    "value": "79245",
    "label": "Memphis - TX 79245",
    "county": "Hall County",
    "state": "TX",
    "city": "Memphis"
  },
  {
    "value": "79247",
    "label": "Odell - TX 79247",
    "county": "Wilbarger County",
    "state": "TX",
    "city": "Odell"
  },
  {
    "value": "79248",
    "label": "Paducah - TX 79248",
    "county": "Cottle County",
    "state": "TX",
    "city": "Paducah"
  },
  {
    "value": "79250",
    "label": "Petersburg - TX 79250",
    "county": "Hale County",
    "state": "TX",
    "city": "Petersburg"
  },
  {
    "value": "79251",
    "label": "Quail - TX 79251",
    "county": "Collingsworth County",
    "state": "TX",
    "city": "Quail"
  },
  {
    "value": "79252",
    "label": "Quanah - TX 79252",
    "county": "Hardeman County",
    "state": "TX",
    "city": "Quanah"
  },
  {
    "value": "79255",
    "label": "Quitaque - TX 79255",
    "county": "Briscoe County",
    "state": "TX",
    "city": "Quitaque"
  },
  {
    "value": "79256",
    "label": "Roaring Springs - TX 79256",
    "county": "Motley County",
    "state": "TX",
    "city": "Roaring Springs"
  },
  {
    "value": "79257",
    "label": "Silverton - TX 79257",
    "county": "Briscoe County",
    "state": "TX",
    "city": "Silverton"
  },
  {
    "value": "79259",
    "label": "Tell - TX 79259",
    "county": "Childress County",
    "state": "TX",
    "city": "Tell"
  },
  {
    "value": "79261",
    "label": "Turkey - TX 79261",
    "county": "Hall County",
    "state": "TX",
    "city": "Turkey"
  },
  {
    "value": "79311",
    "label": "Abernathy - TX 79311",
    "county": "Hale County",
    "state": "TX",
    "city": "Abernathy"
  },
  {
    "value": "79312",
    "label": "Amherst - TX 79312",
    "county": "Lamb County",
    "state": "TX",
    "city": "Amherst"
  },
  {
    "value": "79313",
    "label": "Anton - TX 79313",
    "county": "Hockley County",
    "state": "TX",
    "city": "Anton"
  },
  {
    "value": "79316",
    "label": "Brownfield - TX 79316",
    "county": "Terry County",
    "state": "TX",
    "city": "Brownfield"
  },
  {
    "value": "79320",
    "label": "Bula - TX 79320",
    "county": "Bailey County",
    "state": "TX",
    "city": "Bula"
  },
  {
    "value": "79322",
    "label": "Crosbyton - TX 79322",
    "county": "Crosby County",
    "state": "TX",
    "city": "Crosbyton"
  },
  {
    "value": "79323",
    "label": "Denver City - TX 79323",
    "county": "Yoakum County",
    "state": "TX",
    "city": "Denver City"
  },
  {
    "value": "79324",
    "label": "Enochs - TX 79324",
    "county": "Bailey County",
    "state": "TX",
    "city": "Enochs"
  },
  {
    "value": "79325",
    "label": "Farwell - TX 79325",
    "county": "Parmer County",
    "state": "TX",
    "city": "Farwell"
  },
  {
    "value": "79326",
    "label": "Fieldton - TX 79326",
    "county": "Lamb County",
    "state": "TX",
    "city": "Fieldton"
  },
  {
    "value": "79329",
    "label": "Idalou - TX 79329",
    "county": "Lubbock County",
    "state": "TX",
    "city": "Idalou"
  },
  {
    "value": "79330",
    "label": "Justiceburg - TX 79330",
    "county": "Garza County",
    "state": "TX",
    "city": "Justiceburg"
  },
  {
    "value": "79331",
    "label": "Lamesa - TX 79331",
    "county": "Dawson County",
    "state": "TX",
    "city": "Lamesa"
  },
  {
    "value": "79336",
    "label": "Levelland - TX 79336",
    "county": "Hockley County",
    "state": "TX",
    "city": "Levelland"
  },
  {
    "value": "79339",
    "label": "Littlefield - TX 79339",
    "county": "Lamb County",
    "state": "TX",
    "city": "Littlefield"
  },
  {
    "value": "79342",
    "label": "Loop - TX 79342",
    "county": "Gaines County",
    "state": "TX",
    "city": "Loop"
  },
  {
    "value": "79343",
    "label": "Lorenzo - TX 79343",
    "county": "Crosby County",
    "state": "TX",
    "city": "Lorenzo"
  },
  {
    "value": "79344",
    "label": "Maple - TX 79344",
    "county": "Bailey County",
    "state": "TX",
    "city": "Maple"
  },
  {
    "value": "79345",
    "label": "Meadow - TX 79345",
    "county": "Terry County",
    "state": "TX",
    "city": "Meadow"
  },
  {
    "value": "79346",
    "label": "Morton - TX 79346",
    "county": "Cochran County",
    "state": "TX",
    "city": "Morton"
  },
  {
    "value": "79347",
    "label": "Muleshoe - TX 79347",
    "county": "Bailey County",
    "state": "TX",
    "city": "Muleshoe"
  },
  {
    "value": "79351",
    "label": "Odonnell - TX 79351",
    "county": "Lynn County",
    "state": "TX",
    "city": "Odonnell"
  },
  {
    "value": "79353",
    "label": "Pep - TX 79353",
    "county": "Hockley County",
    "state": "TX",
    "city": "Pep"
  },
  {
    "value": "79355",
    "label": "Plains - TX 79355",
    "county": "Yoakum County",
    "state": "TX",
    "city": "Plains"
  },
  {
    "value": "79356",
    "label": "Post - TX 79356",
    "county": "Garza County",
    "state": "TX",
    "city": "Post"
  },
  {
    "value": "79357",
    "label": "Ralls - TX 79357",
    "county": "Crosby County",
    "state": "TX",
    "city": "Ralls"
  },
  {
    "value": "79358",
    "label": "Ropesville - TX 79358",
    "county": "Hockley County",
    "state": "TX",
    "city": "Ropesville"
  },
  {
    "value": "79359",
    "label": "Seagraves - TX 79359",
    "county": "Gaines County",
    "state": "TX",
    "city": "Seagraves"
  },
  {
    "value": "79360",
    "label": "Seminole - TX 79360",
    "county": "Gaines County",
    "state": "TX",
    "city": "Seminole"
  },
  {
    "value": "79363",
    "label": "Shallowater - TX 79363",
    "county": "Lubbock County",
    "state": "TX",
    "city": "Shallowater"
  },
  {
    "value": "79364",
    "label": "Slaton - TX 79364",
    "county": "Lubbock County",
    "state": "TX",
    "city": "Slaton"
  },
  {
    "value": "79366",
    "label": "Ransom Canyon - TX 79366",
    "county": "Lubbock County",
    "state": "TX",
    "city": "Ransom Canyon"
  },
  {
    "value": "79370",
    "label": "Spur - TX 79370",
    "county": "Dickens County",
    "state": "TX",
    "city": "Spur"
  },
  {
    "value": "79371",
    "label": "Sudan - TX 79371",
    "county": "Lamb County",
    "state": "TX",
    "city": "Sudan"
  },
  {
    "value": "79373",
    "label": "Tahoka - TX 79373",
    "county": "Lynn County",
    "state": "TX",
    "city": "Tahoka"
  },
  {
    "value": "79376",
    "label": "Tokio - TX 79376",
    "county": "Yoakum County",
    "state": "TX",
    "city": "Tokio"
  },
  {
    "value": "79377",
    "label": "Welch - TX 79377",
    "county": "Dawson County",
    "state": "TX",
    "city": "Welch"
  },
  {
    "value": "79381",
    "label": "Wilson - TX 79381",
    "county": "Lynn County",
    "state": "TX",
    "city": "Wilson"
  },
  {
    "value": "79382",
    "label": "Wolfforth - TX 79382",
    "county": "Lubbock County",
    "state": "TX",
    "city": "Wolfforth"
  },
  {
    "value": "79401",
    "label": "Lubbock - TX 79401",
    "county": "Lubbock County",
    "state": "TX",
    "city": "Lubbock"
  },
  {
    "value": "79402",
    "label": "Lubbock - TX 79402",
    "county": "Lubbock County",
    "state": "TX",
    "city": "Lubbock"
  },
  {
    "value": "79403",
    "label": "Lubbock - TX 79403",
    "county": "Lubbock County",
    "state": "TX",
    "city": "Lubbock"
  },
  {
    "value": "79404",
    "label": "Lubbock - TX 79404",
    "county": "Lubbock County",
    "state": "TX",
    "city": "Lubbock"
  },
  {
    "value": "79405",
    "label": "Lubbock - TX 79405",
    "county": "Lubbock County",
    "state": "TX",
    "city": "Lubbock"
  },
  {
    "value": "79406",
    "label": "Lubbock - TX 79406",
    "county": "Lubbock County",
    "state": "TX",
    "city": "Lubbock"
  },
  {
    "value": "79407",
    "label": "Lubbock - TX 79407",
    "county": "Lubbock County",
    "state": "TX",
    "city": "Lubbock"
  },
  {
    "value": "79409",
    "label": "Lubbock - TX 79409",
    "county": "Lubbock County",
    "state": "TX",
    "city": "Lubbock"
  },
  {
    "value": "79410",
    "label": "Lubbock - TX 79410",
    "county": "Lubbock County",
    "state": "TX",
    "city": "Lubbock"
  },
  {
    "value": "79411",
    "label": "Lubbock - TX 79411",
    "county": "Lubbock County",
    "state": "TX",
    "city": "Lubbock"
  },
  {
    "value": "79412",
    "label": "Lubbock - TX 79412",
    "county": "Lubbock County",
    "state": "TX",
    "city": "Lubbock"
  },
  {
    "value": "79413",
    "label": "Lubbock - TX 79413",
    "county": "Lubbock County",
    "state": "TX",
    "city": "Lubbock"
  },
  {
    "value": "79414",
    "label": "Lubbock - TX 79414",
    "county": "Lubbock County",
    "state": "TX",
    "city": "Lubbock"
  },
  {
    "value": "79415",
    "label": "Lubbock - TX 79415",
    "county": "Lubbock County",
    "state": "TX",
    "city": "Lubbock"
  },
  {
    "value": "79416",
    "label": "Lubbock - TX 79416",
    "county": "Lubbock County",
    "state": "TX",
    "city": "Lubbock"
  },
  {
    "value": "79423",
    "label": "Lubbock - TX 79423",
    "county": "Lubbock County",
    "state": "TX",
    "city": "Lubbock"
  },
  {
    "value": "79424",
    "label": "Lubbock - TX 79424",
    "county": "Lubbock County",
    "state": "TX",
    "city": "Lubbock"
  },
  {
    "value": "79501",
    "label": "Anson - TX 79501",
    "county": "Jones County",
    "state": "TX",
    "city": "Anson"
  },
  {
    "value": "79502",
    "label": "Aspermont - TX 79502",
    "county": "Stonewall County",
    "state": "TX",
    "city": "Aspermont"
  },
  {
    "value": "79503",
    "label": "Avoca - TX 79503",
    "county": "Jones County",
    "state": "TX",
    "city": "Avoca"
  },
  {
    "value": "79504",
    "label": "Baird - TX 79504",
    "county": "Callahan County",
    "state": "TX",
    "city": "Baird"
  },
  {
    "value": "79505",
    "label": "Benjamin - TX 79505",
    "county": "Knox County",
    "state": "TX",
    "city": "Benjamin"
  },
  {
    "value": "79506",
    "label": "Blackwell - TX 79506",
    "county": "Nolan County",
    "state": "TX",
    "city": "Blackwell"
  },
  {
    "value": "79508",
    "label": "Buffalo Gap - TX 79508",
    "county": "Taylor County",
    "state": "TX",
    "city": "Buffalo Gap"
  },
  {
    "value": "79510",
    "label": "Clyde - TX 79510",
    "county": "Callahan County",
    "state": "TX",
    "city": "Clyde"
  },
  {
    "value": "79511",
    "label": "Coahoma - TX 79511",
    "county": "Howard County",
    "state": "TX",
    "city": "Coahoma"
  },
  {
    "value": "79512",
    "label": "Colorado City - TX 79512",
    "county": "Mitchell County",
    "state": "TX",
    "city": "Colorado City"
  },
  {
    "value": "79517",
    "label": "Fluvanna - TX 79517",
    "county": "Scurry County",
    "state": "TX",
    "city": "Fluvanna"
  },
  {
    "value": "79518",
    "label": "Girard - TX 79518",
    "county": "Kent County",
    "state": "TX",
    "city": "Girard"
  },
  {
    "value": "79519",
    "label": "Goldsboro - TX 79519",
    "county": "Coleman County",
    "state": "TX",
    "city": "Goldsboro"
  },
  {
    "value": "79520",
    "label": "Hamlin - TX 79520",
    "county": "Jones County",
    "state": "TX",
    "city": "Hamlin"
  },
  {
    "value": "79521",
    "label": "Haskell - TX 79521",
    "county": "Haskell County",
    "state": "TX",
    "city": "Haskell"
  },
  {
    "value": "79525",
    "label": "Hawley - TX 79525",
    "county": "Jones County",
    "state": "TX",
    "city": "Hawley"
  },
  {
    "value": "79526",
    "label": "Hermleigh - TX 79526",
    "county": "Scurry County",
    "state": "TX",
    "city": "Hermleigh"
  },
  {
    "value": "79527",
    "label": "Ira - TX 79527",
    "county": "Scurry County",
    "state": "TX",
    "city": "Ira"
  },
  {
    "value": "79528",
    "label": "Jayton - TX 79528",
    "county": "Kent County",
    "state": "TX",
    "city": "Jayton"
  },
  {
    "value": "79529",
    "label": "Knox City - TX 79529",
    "county": "Knox County",
    "state": "TX",
    "city": "Knox City"
  },
  {
    "value": "79530",
    "label": "Lawn - TX 79530",
    "county": "Taylor County",
    "state": "TX",
    "city": "Lawn"
  },
  {
    "value": "79532",
    "label": "Loraine - TX 79532",
    "county": "Mitchell County",
    "state": "TX",
    "city": "Loraine"
  },
  {
    "value": "79533",
    "label": "Lueders - TX 79533",
    "county": "Jones County",
    "state": "TX",
    "city": "Lueders"
  },
  {
    "value": "79534",
    "label": "Mc Caulley - TX 79534",
    "county": "Fisher County",
    "state": "TX",
    "city": "Mc Caulley"
  },
  {
    "value": "79535",
    "label": "Maryneal - TX 79535",
    "county": "Nolan County",
    "state": "TX",
    "city": "Maryneal"
  },
  {
    "value": "79536",
    "label": "Merkel - TX 79536",
    "county": "Taylor County",
    "state": "TX",
    "city": "Merkel"
  },
  {
    "value": "79537",
    "label": "Nolan - TX 79537",
    "county": "Nolan County",
    "state": "TX",
    "city": "Nolan"
  },
  {
    "value": "79538",
    "label": "Novice - TX 79538",
    "county": "Coleman County",
    "state": "TX",
    "city": "Novice"
  },
  {
    "value": "79539",
    "label": "O Brien - TX 79539",
    "county": "Haskell County",
    "state": "TX",
    "city": "O Brien"
  },
  {
    "value": "79540",
    "label": "Old Glory - TX 79540",
    "county": "Stonewall County",
    "state": "TX",
    "city": "Old Glory"
  },
  {
    "value": "79541",
    "label": "Ovalo - TX 79541",
    "county": "Taylor County",
    "state": "TX",
    "city": "Ovalo"
  },
  {
    "value": "79543",
    "label": "Roby - TX 79543",
    "county": "Fisher County",
    "state": "TX",
    "city": "Roby"
  },
  {
    "value": "79544",
    "label": "Rochester - TX 79544",
    "county": "Haskell County",
    "state": "TX",
    "city": "Rochester"
  },
  {
    "value": "79545",
    "label": "Roscoe - TX 79545",
    "county": "Nolan County",
    "state": "TX",
    "city": "Roscoe"
  },
  {
    "value": "79546",
    "label": "Rotan - TX 79546",
    "county": "Fisher County",
    "state": "TX",
    "city": "Rotan"
  },
  {
    "value": "79547",
    "label": "Rule - TX 79547",
    "county": "Haskell County",
    "state": "TX",
    "city": "Rule"
  },
  {
    "value": "79548",
    "label": "Rule - TX 79548",
    "county": "Haskell County",
    "state": "TX",
    "city": "Rule"
  },
  {
    "value": "79549",
    "label": "Snyder - TX 79549",
    "county": "Scurry County",
    "state": "TX",
    "city": "Snyder"
  },
  {
    "value": "79553",
    "label": "Stamford - TX 79553",
    "county": "Jones County",
    "state": "TX",
    "city": "Stamford"
  },
  {
    "value": "79556",
    "label": "Sweetwater - TX 79556",
    "county": "Nolan County",
    "state": "TX",
    "city": "Sweetwater"
  },
  {
    "value": "79560",
    "label": "Sylvester - TX 79560",
    "county": "Fisher County",
    "state": "TX",
    "city": "Sylvester"
  },
  {
    "value": "79561",
    "label": "Trent - TX 79561",
    "county": "Taylor County",
    "state": "TX",
    "city": "Trent"
  },
  {
    "value": "79562",
    "label": "Tuscola - TX 79562",
    "county": "Taylor County",
    "state": "TX",
    "city": "Tuscola"
  },
  {
    "value": "79563",
    "label": "Tye - TX 79563",
    "county": "Taylor County",
    "state": "TX",
    "city": "Tye"
  },
  {
    "value": "79565",
    "label": "Westbrook - TX 79565",
    "county": "Mitchell County",
    "state": "TX",
    "city": "Westbrook"
  },
  {
    "value": "79566",
    "label": "Wingate - TX 79566",
    "county": "Taylor County",
    "state": "TX",
    "city": "Wingate"
  },
  {
    "value": "79567",
    "label": "Winters - TX 79567",
    "county": "Runnels County",
    "state": "TX",
    "city": "Winters"
  },
  {
    "value": "79601",
    "label": "Abilene - TX 79601",
    "county": "Taylor County",
    "state": "TX",
    "city": "Abilene"
  },
  {
    "value": "79602",
    "label": "Abilene - TX 79602",
    "county": "Taylor County",
    "state": "TX",
    "city": "Abilene"
  },
  {
    "value": "79603",
    "label": "Abilene - TX 79603",
    "county": "Taylor County",
    "state": "TX",
    "city": "Abilene"
  },
  {
    "value": "79605",
    "label": "Abilene - TX 79605",
    "county": "Taylor County",
    "state": "TX",
    "city": "Abilene"
  },
  {
    "value": "79606",
    "label": "Abilene - TX 79606",
    "county": "Taylor County",
    "state": "TX",
    "city": "Abilene"
  },
  {
    "value": "79607",
    "label": "Dyess AFB - TX 79607",
    "county": "Taylor County",
    "state": "TX",
    "city": "Dyess AFB"
  },
  {
    "value": "79701",
    "label": "Midland - TX 79701",
    "county": "Midland County",
    "state": "TX",
    "city": "Midland"
  },
  {
    "value": "79703",
    "label": "Midland - TX 79703",
    "county": "Midland County",
    "state": "TX",
    "city": "Midland"
  },
  {
    "value": "79705",
    "label": "Midland - TX 79705",
    "county": "Midland County",
    "state": "TX",
    "city": "Midland"
  },
  {
    "value": "79706",
    "label": "Midland - TX 79706",
    "county": "Midland County",
    "state": "TX",
    "city": "Midland"
  },
  {
    "value": "79707",
    "label": "Midland - TX 79707",
    "county": "Midland County",
    "state": "TX",
    "city": "Midland"
  },
  {
    "value": "79712",
    "label": "Midland - TX 79712",
    "county": "Midland County",
    "state": "TX",
    "city": "Midland"
  },
  {
    "value": "79713",
    "label": "Ackerly - TX 79713",
    "county": "Martin County",
    "state": "TX",
    "city": "Ackerly"
  },
  {
    "value": "79714",
    "label": "Andrews - TX 79714",
    "county": "Andrews County",
    "state": "TX",
    "city": "Andrews"
  },
  {
    "value": "79718",
    "label": "Balmorhea - TX 79718",
    "county": "Reeves County",
    "state": "TX",
    "city": "Balmorhea"
  },
  {
    "value": "79720",
    "label": "Big Spring - TX 79720",
    "county": "Howard County",
    "state": "TX",
    "city": "Big Spring"
  },
  {
    "value": "79731",
    "label": "Crane - TX 79731",
    "county": "Crane County",
    "state": "TX",
    "city": "Crane"
  },
  {
    "value": "79734",
    "label": "Fort Davis - TX 79734",
    "county": "Jeff Davis County",
    "state": "TX",
    "city": "Fort Davis"
  },
  {
    "value": "79735",
    "label": "Fort Stockton - TX 79735",
    "county": "Pecos County",
    "state": "TX",
    "city": "Fort Stockton"
  },
  {
    "value": "79738",
    "label": "Gail - TX 79738",
    "county": "Borden County",
    "state": "TX",
    "city": "Gail"
  },
  {
    "value": "79739",
    "label": "Garden City - TX 79739",
    "county": "Glasscock County",
    "state": "TX",
    "city": "Garden City"
  },
  {
    "value": "79740",
    "label": "Girvin - TX 79740",
    "county": "Crane County",
    "state": "TX",
    "city": "Girvin"
  },
  {
    "value": "79741",
    "label": "Goldsmith - TX 79741",
    "county": "Ector County",
    "state": "TX",
    "city": "Goldsmith"
  },
  {
    "value": "79745",
    "label": "Kermit - TX 79745",
    "county": "Winkler County",
    "state": "TX",
    "city": "Kermit"
  },
  {
    "value": "79748",
    "label": "Knott - TX 79748",
    "county": "Howard County",
    "state": "TX",
    "city": "Knott"
  },
  {
    "value": "79749",
    "label": "Lenorah - TX 79749",
    "county": "Martin County",
    "state": "TX",
    "city": "Lenorah"
  },
  {
    "value": "79752",
    "label": "Mc Camey - TX 79752",
    "county": "Upton County",
    "state": "TX",
    "city": "Mc Camey"
  },
  {
    "value": "79755",
    "label": "Midkiff - TX 79755",
    "county": "Upton County",
    "state": "TX",
    "city": "Midkiff"
  },
  {
    "value": "79756",
    "label": "Monahans - TX 79756",
    "county": "Ward County",
    "state": "TX",
    "city": "Monahans"
  },
  {
    "value": "79758",
    "label": "Gardendale - TX 79758",
    "county": "Ector County",
    "state": "TX",
    "city": "Gardendale"
  },
  {
    "value": "79761",
    "label": "Odessa - TX 79761",
    "county": "Ector County",
    "state": "TX",
    "city": "Odessa"
  },
  {
    "value": "79762",
    "label": "Odessa - TX 79762",
    "county": "Ector County",
    "state": "TX",
    "city": "Odessa"
  },
  {
    "value": "79763",
    "label": "Odessa - TX 79763",
    "county": "Ector County",
    "state": "TX",
    "city": "Odessa"
  },
  {
    "value": "79764",
    "label": "Odessa - TX 79764",
    "county": "Ector County",
    "state": "TX",
    "city": "Odessa"
  },
  {
    "value": "79765",
    "label": "Odessa - TX 79765",
    "county": "Ector County",
    "state": "TX",
    "city": "Odessa"
  },
  {
    "value": "79766",
    "label": "Odessa - TX 79766",
    "county": "Ector County",
    "state": "TX",
    "city": "Odessa"
  },
  {
    "value": "79772",
    "label": "Pecos - TX 79772",
    "county": "Reeves County",
    "state": "TX",
    "city": "Pecos"
  },
  {
    "value": "79782",
    "label": "Stanton - TX 79782",
    "county": "Martin County",
    "state": "TX",
    "city": "Stanton"
  },
  {
    "value": "79783",
    "label": "Tarzan - TX 79783",
    "county": "Martin County",
    "state": "TX",
    "city": "Tarzan"
  },
  {
    "value": "79821",
    "label": "Anthony - TX 79821",
    "county": "El Paso County",
    "state": "TX",
    "city": "Anthony"
  },
  {
    "value": "79830",
    "label": "Alpine - TX 79830",
    "county": "Brewster County",
    "state": "TX",
    "city": "Alpine"
  },
  {
    "value": "79835",
    "label": "Canutillo - TX 79835",
    "county": "El Paso County",
    "state": "TX",
    "city": "Canutillo"
  },
  {
    "value": "79836",
    "label": "Clint - TX 79836",
    "county": "El Paso County",
    "state": "TX",
    "city": "Clint"
  },
  {
    "value": "79839",
    "label": "Fort Hancock - TX 79839",
    "county": "Hudspeth County",
    "state": "TX",
    "city": "Fort Hancock"
  },
  {
    "value": "79843",
    "label": "Marfa - TX 79843",
    "county": "Presidio County",
    "state": "TX",
    "city": "Marfa"
  },
  {
    "value": "79847",
    "label": "Salt Flat - TX 79847",
    "county": "Hudspeth County",
    "state": "TX",
    "city": "Salt Flat"
  },
  {
    "value": "79849",
    "label": "San Elizario - TX 79849",
    "county": "El Paso County",
    "state": "TX",
    "city": "San Elizario"
  },
  {
    "value": "79852",
    "label": "Terlingua - TX 79852",
    "county": "Brewster County",
    "state": "TX",
    "city": "Terlingua"
  },
  {
    "value": "79854",
    "label": "Valentine - TX 79854",
    "county": "Jeff Davis County",
    "state": "TX",
    "city": "Valentine"
  },
  {
    "value": "79901",
    "label": "El Paso - TX 79901",
    "county": "El Paso County",
    "state": "TX",
    "city": "El Paso"
  },
  {
    "value": "79902",
    "label": "El Paso - TX 79902",
    "county": "El Paso County",
    "state": "TX",
    "city": "El Paso"
  },
  {
    "value": "79903",
    "label": "El Paso - TX 79903",
    "county": "El Paso County",
    "state": "TX",
    "city": "El Paso"
  },
  {
    "value": "79904",
    "label": "El Paso - TX 79904",
    "county": "El Paso County",
    "state": "TX",
    "city": "El Paso"
  },
  {
    "value": "79905",
    "label": "El Paso - TX 79905",
    "county": "El Paso County",
    "state": "TX",
    "city": "El Paso"
  },
  {
    "value": "79906",
    "label": "El Paso - TX 79906",
    "county": "El Paso County",
    "state": "TX",
    "city": "El Paso"
  },
  {
    "value": "79907",
    "label": "El Paso - TX 79907",
    "county": "El Paso County",
    "state": "TX",
    "city": "El Paso"
  },
  {
    "value": "79908",
    "label": "El Paso - TX 79908",
    "county": "El Paso County",
    "state": "TX",
    "city": "El Paso"
  },
  {
    "value": "79910",
    "label": "El Paso - TX 79910",
    "county": "El Paso County",
    "state": "TX",
    "city": "El Paso"
  },
  {
    "value": "79911",
    "label": "El Paso - TX 79911",
    "county": "El Paso County",
    "state": "TX",
    "city": "El Paso"
  },
  {
    "value": "79912",
    "label": "El Paso - TX 79912",
    "county": "El Paso County",
    "state": "TX",
    "city": "El Paso"
  },
  {
    "value": "79915",
    "label": "El Paso - TX 79915",
    "county": "El Paso County",
    "state": "TX",
    "city": "El Paso"
  },
  {
    "value": "79916",
    "label": "Fort Bliss - TX 79916",
    "county": "El Paso County",
    "state": "TX",
    "city": "Fort Bliss"
  },
  {
    "value": "79918",
    "label": "Fort Bliss - TX 79918",
    "county": "El Paso County",
    "state": "TX",
    "city": "Fort Bliss"
  },
  {
    "value": "79922",
    "label": "El Paso - TX 79922",
    "county": "El Paso County",
    "state": "TX",
    "city": "El Paso"
  },
  {
    "value": "79924",
    "label": "El Paso - TX 79924",
    "county": "El Paso County",
    "state": "TX",
    "city": "El Paso"
  },
  {
    "value": "79925",
    "label": "El Paso - TX 79925",
    "county": "El Paso County",
    "state": "TX",
    "city": "El Paso"
  },
  {
    "value": "79927",
    "label": "El Paso - TX 79927",
    "county": "El Paso County",
    "state": "TX",
    "city": "El Paso"
  },
  {
    "value": "79928",
    "label": "El Paso - TX 79928",
    "county": "El Paso County",
    "state": "TX",
    "city": "El Paso"
  },
  {
    "value": "79930",
    "label": "El Paso - TX 79930",
    "county": "El Paso County",
    "state": "TX",
    "city": "El Paso"
  },
  {
    "value": "79932",
    "label": "El Paso - TX 79932",
    "county": "El Paso County",
    "state": "TX",
    "city": "El Paso"
  },
  {
    "value": "79934",
    "label": "El Paso - TX 79934",
    "county": "El Paso County",
    "state": "TX",
    "city": "El Paso"
  },
  {
    "value": "79935",
    "label": "El Paso - TX 79935",
    "county": "El Paso County",
    "state": "TX",
    "city": "El Paso"
  },
  {
    "value": "79936",
    "label": "El Paso - TX 79936",
    "county": "El Paso County",
    "state": "TX",
    "city": "El Paso"
  },
  {
    "value": "79938",
    "label": "El Paso - TX 79938",
    "county": "El Paso County",
    "state": "TX",
    "city": "El Paso"
  },
  {
    "value": "84001",
    "label": "Altamont - UT 84001",
    "county": "Duchesne County",
    "state": "UT",
    "city": "Altamont"
  },
  {
    "value": "84002",
    "label": "Altonah - UT 84002",
    "county": "Duchesne County",
    "state": "UT",
    "city": "Altonah"
  },
  {
    "value": "84003",
    "label": "American Fork - UT 84003",
    "county": "Utah County",
    "state": "UT",
    "city": "American Fork"
  },
  {
    "value": "84004",
    "label": "Alpine - UT 84004",
    "county": "Utah County",
    "state": "UT",
    "city": "Alpine"
  },
  {
    "value": "84005",
    "label": "Eagle Mountain - UT 84005",
    "county": "Utah County",
    "state": "UT",
    "city": "Eagle Mountain"
  },
  {
    "value": "84006",
    "label": "Bingham Canyon - UT 84006",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Bingham Canyon"
  },
  {
    "value": "84007",
    "label": "Bluebell - UT 84007",
    "county": "Duchesne County",
    "state": "UT",
    "city": "Bluebell"
  },
  {
    "value": "84008",
    "label": "Bonanza - UT 84008",
    "county": "Uintah County",
    "state": "UT",
    "city": "Bonanza"
  },
  {
    "value": "84009",
    "label": "South Jordan - UT 84009",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "South Jordan"
  },
  {
    "value": "84010",
    "label": "Bountiful - UT 84010",
    "county": "Davis County",
    "state": "UT",
    "city": "Bountiful"
  },
  {
    "value": "84013",
    "label": "Cedar Valley - UT 84013",
    "county": "Utah County",
    "state": "UT",
    "city": "Cedar Valley"
  },
  {
    "value": "84014",
    "label": "Centerville - UT 84014",
    "county": "Davis County",
    "state": "UT",
    "city": "Centerville"
  },
  {
    "value": "84015",
    "label": "Clearfield - UT 84015",
    "county": "Davis County",
    "state": "UT",
    "city": "Clearfield"
  },
  {
    "value": "84017",
    "label": "Coalville - UT 84017",
    "county": "Summit County",
    "state": "UT",
    "city": "Coalville"
  },
  {
    "value": "84018",
    "label": "Croydon - UT 84018",
    "county": "Morgan County",
    "state": "UT",
    "city": "Croydon"
  },
  {
    "value": "84020",
    "label": "Draper - UT 84020",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Draper"
  },
  {
    "value": "84021",
    "label": "Duchesne - UT 84021",
    "county": "Duchesne County",
    "state": "UT",
    "city": "Duchesne"
  },
  {
    "value": "84022",
    "label": "Dugway - UT 84022",
    "county": "Tooele County",
    "state": "UT",
    "city": "Dugway"
  },
  {
    "value": "84023",
    "label": "Dutch John - UT 84023",
    "county": "Daggett County",
    "state": "UT",
    "city": "Dutch John"
  },
  {
    "value": "84025",
    "label": "Farmington - UT 84025",
    "county": "Davis County",
    "state": "UT",
    "city": "Farmington"
  },
  {
    "value": "84026",
    "label": "Fort Duchesne - UT 84026",
    "county": "Uintah County",
    "state": "UT",
    "city": "Fort Duchesne"
  },
  {
    "value": "84027",
    "label": "Fruitland - UT 84027",
    "county": "Duchesne County",
    "state": "UT",
    "city": "Fruitland"
  },
  {
    "value": "84028",
    "label": "Garden City - UT 84028",
    "county": "Rich County",
    "state": "UT",
    "city": "Garden City"
  },
  {
    "value": "84029",
    "label": "Grantsville - UT 84029",
    "county": "Tooele County",
    "state": "UT",
    "city": "Grantsville"
  },
  {
    "value": "84031",
    "label": "Hanna - UT 84031",
    "county": "Duchesne County",
    "state": "UT",
    "city": "Hanna"
  },
  {
    "value": "84032",
    "label": "Heber City - UT 84032",
    "county": "Wasatch County",
    "state": "UT",
    "city": "Heber City"
  },
  {
    "value": "84034",
    "label": "Ibapah - UT 84034",
    "county": "Tooele County",
    "state": "UT",
    "city": "Ibapah"
  },
  {
    "value": "84035",
    "label": "Jensen - UT 84035",
    "county": "Uintah County",
    "state": "UT",
    "city": "Jensen"
  },
  {
    "value": "84036",
    "label": "Kamas - UT 84036",
    "county": "Summit County",
    "state": "UT",
    "city": "Kamas"
  },
  {
    "value": "84037",
    "label": "Kaysville - UT 84037",
    "county": "Davis County",
    "state": "UT",
    "city": "Kaysville"
  },
  {
    "value": "84039",
    "label": "Lapoint - UT 84039",
    "county": "Uintah County",
    "state": "UT",
    "city": "Lapoint"
  },
  {
    "value": "84040",
    "label": "Layton - UT 84040",
    "county": "Davis County",
    "state": "UT",
    "city": "Layton"
  },
  {
    "value": "84041",
    "label": "Layton - UT 84041",
    "county": "Davis County",
    "state": "UT",
    "city": "Layton"
  },
  {
    "value": "84042",
    "label": "Lindon - UT 84042",
    "county": "Utah County",
    "state": "UT",
    "city": "Lindon"
  },
  {
    "value": "84043",
    "label": "Lehi - UT 84043",
    "county": "Utah County",
    "state": "UT",
    "city": "Lehi"
  },
  {
    "value": "84044",
    "label": "Magna - UT 84044",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Magna"
  },
  {
    "value": "84045",
    "label": "Saratoga Springs - UT 84045",
    "county": "Utah County",
    "state": "UT",
    "city": "Saratoga Springs"
  },
  {
    "value": "84046",
    "label": "Manila - UT 84046",
    "county": "Daggett County",
    "state": "UT",
    "city": "Manila"
  },
  {
    "value": "84047",
    "label": "Midvale - UT 84047",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Midvale"
  },
  {
    "value": "84049",
    "label": "Midway - UT 84049",
    "county": "Wasatch County",
    "state": "UT",
    "city": "Midway"
  },
  {
    "value": "84050",
    "label": "Morgan - UT 84050",
    "county": "Morgan County",
    "state": "UT",
    "city": "Morgan"
  },
  {
    "value": "84051",
    "label": "Mountain Home - UT 84051",
    "county": "Duchesne County",
    "state": "UT",
    "city": "Mountain Home"
  },
  {
    "value": "84052",
    "label": "Myton - UT 84052",
    "county": "Duchesne County",
    "state": "UT",
    "city": "Myton"
  },
  {
    "value": "84053",
    "label": "Neola - UT 84053",
    "county": "Duchesne County",
    "state": "UT",
    "city": "Neola"
  },
  {
    "value": "84054",
    "label": "North Salt Lake - UT 84054",
    "county": "Davis County",
    "state": "UT",
    "city": "North Salt Lake"
  },
  {
    "value": "84055",
    "label": "Oakley - UT 84055",
    "county": "Summit County",
    "state": "UT",
    "city": "Oakley"
  },
  {
    "value": "84056",
    "label": "Hill AFB - UT 84056",
    "county": "Davis County",
    "state": "UT",
    "city": "Hill AFB"
  },
  {
    "value": "84057",
    "label": "Orem - UT 84057",
    "county": "Utah County",
    "state": "UT",
    "city": "Orem"
  },
  {
    "value": "84058",
    "label": "Orem - UT 84058",
    "county": "Utah County",
    "state": "UT",
    "city": "Orem"
  },
  {
    "value": "84059",
    "label": "Vineyard - UT 84059",
    "county": "Utah County",
    "state": "UT",
    "city": "Vineyard"
  },
  {
    "value": "84060",
    "label": "Park City - UT 84060",
    "county": "Summit County",
    "state": "UT",
    "city": "Park City"
  },
  {
    "value": "84061",
    "label": "Peoa - UT 84061",
    "county": "Summit County",
    "state": "UT",
    "city": "Peoa"
  },
  {
    "value": "84062",
    "label": "Pleasant Grove - UT 84062",
    "county": "Utah County",
    "state": "UT",
    "city": "Pleasant Grove"
  },
  {
    "value": "84063",
    "label": "Randlett - UT 84063",
    "county": "Uintah County",
    "state": "UT",
    "city": "Randlett"
  },
  {
    "value": "84065",
    "label": "Riverton - UT 84065",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Riverton"
  },
  {
    "value": "84066",
    "label": "Roosevelt - UT 84066",
    "county": "Duchesne County",
    "state": "UT",
    "city": "Roosevelt"
  },
  {
    "value": "84067",
    "label": "Roy - UT 84067",
    "county": "Weber County",
    "state": "UT",
    "city": "Roy"
  },
  {
    "value": "84069",
    "label": "Rush Valley - UT 84069",
    "county": "Tooele County",
    "state": "UT",
    "city": "Rush Valley"
  },
  {
    "value": "84070",
    "label": "Sandy - UT 84070",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Sandy"
  },
  {
    "value": "84071",
    "label": "Stockton - UT 84071",
    "county": "Tooele County",
    "state": "UT",
    "city": "Stockton"
  },
  {
    "value": "84072",
    "label": "Tabiona - UT 84072",
    "county": "Duchesne County",
    "state": "UT",
    "city": "Tabiona"
  },
  {
    "value": "84073",
    "label": "Talmage - UT 84073",
    "county": "Duchesne County",
    "state": "UT",
    "city": "Talmage"
  },
  {
    "value": "84074",
    "label": "Tooele - UT 84074",
    "county": "Tooele County",
    "state": "UT",
    "city": "Tooele"
  },
  {
    "value": "84075",
    "label": "Syracuse - UT 84075",
    "county": "Davis County",
    "state": "UT",
    "city": "Syracuse"
  },
  {
    "value": "84076",
    "label": "Tridell - UT 84076",
    "county": "Uintah County",
    "state": "UT",
    "city": "Tridell"
  },
  {
    "value": "84078",
    "label": "Vernal - UT 84078",
    "county": "Uintah County",
    "state": "UT",
    "city": "Vernal"
  },
  {
    "value": "84080",
    "label": "Vernon - UT 84080",
    "county": "Tooele County",
    "state": "UT",
    "city": "Vernon"
  },
  {
    "value": "84081",
    "label": "West Jordan - UT 84081",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "West Jordan"
  },
  {
    "value": "84082",
    "label": "Wallsburg - UT 84082",
    "county": "Wasatch County",
    "state": "UT",
    "city": "Wallsburg"
  },
  {
    "value": "84083",
    "label": "Wendover - UT 84083",
    "county": "Tooele County",
    "state": "UT",
    "city": "Wendover"
  },
  {
    "value": "84084",
    "label": "West Jordan - UT 84084",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "West Jordan"
  },
  {
    "value": "84085",
    "label": "Whiterocks - UT 84085",
    "county": "Uintah County",
    "state": "UT",
    "city": "Whiterocks"
  },
  {
    "value": "84086",
    "label": "Woodruff - UT 84086",
    "county": "Rich County",
    "state": "UT",
    "city": "Woodruff"
  },
  {
    "value": "84087",
    "label": "Woods Cross - UT 84087",
    "county": "Davis County",
    "state": "UT",
    "city": "Woods Cross"
  },
  {
    "value": "84088",
    "label": "West Jordan - UT 84088",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "West Jordan"
  },
  {
    "value": "84092",
    "label": "Sandy - UT 84092",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Sandy"
  },
  {
    "value": "84093",
    "label": "Sandy - UT 84093",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Sandy"
  },
  {
    "value": "84094",
    "label": "Sandy - UT 84094",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Sandy"
  },
  {
    "value": "84095",
    "label": "South Jordan - UT 84095",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "South Jordan"
  },
  {
    "value": "84096",
    "label": "Herriman - UT 84096",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Herriman"
  },
  {
    "value": "84097",
    "label": "Orem - UT 84097",
    "county": "Utah County",
    "state": "UT",
    "city": "Orem"
  },
  {
    "value": "84098",
    "label": "Park City - UT 84098",
    "county": "Summit County",
    "state": "UT",
    "city": "Park City"
  },
  {
    "value": "84101",
    "label": "Salt Lake City - UT 84101",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Salt Lake City"
  },
  {
    "value": "84102",
    "label": "Salt Lake City - UT 84102",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Salt Lake City"
  },
  {
    "value": "84103",
    "label": "Salt Lake City - UT 84103",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Salt Lake City"
  },
  {
    "value": "84104",
    "label": "Salt Lake City - UT 84104",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Salt Lake City"
  },
  {
    "value": "84105",
    "label": "Salt Lake City - UT 84105",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Salt Lake City"
  },
  {
    "value": "84106",
    "label": "Salt Lake City - UT 84106",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Salt Lake City"
  },
  {
    "value": "84107",
    "label": "Salt Lake City - UT 84107",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Salt Lake City"
  },
  {
    "value": "84108",
    "label": "Salt Lake City - UT 84108",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Salt Lake City"
  },
  {
    "value": "84109",
    "label": "Salt Lake City - UT 84109",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Salt Lake City"
  },
  {
    "value": "84111",
    "label": "Salt Lake City - UT 84111",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Salt Lake City"
  },
  {
    "value": "84112",
    "label": "Salt Lake City - UT 84112",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Salt Lake City"
  },
  {
    "value": "84113",
    "label": "Salt Lake City - UT 84113",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Salt Lake City"
  },
  {
    "value": "84114",
    "label": "Salt Lake City - UT 84114",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Salt Lake City"
  },
  {
    "value": "84115",
    "label": "Salt Lake City - UT 84115",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Salt Lake City"
  },
  {
    "value": "84116",
    "label": "Salt Lake City - UT 84116",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Salt Lake City"
  },
  {
    "value": "84117",
    "label": "Salt Lake City - UT 84117",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Salt Lake City"
  },
  {
    "value": "84118",
    "label": "Salt Lake City - UT 84118",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Salt Lake City"
  },
  {
    "value": "84119",
    "label": "West Valley City - UT 84119",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "West Valley City"
  },
  {
    "value": "84120",
    "label": "West Valley City - UT 84120",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "West Valley City"
  },
  {
    "value": "84121",
    "label": "Salt Lake City - UT 84121",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Salt Lake City"
  },
  {
    "value": "84123",
    "label": "Salt Lake City - UT 84123",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Salt Lake City"
  },
  {
    "value": "84124",
    "label": "Salt Lake City - UT 84124",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Salt Lake City"
  },
  {
    "value": "84128",
    "label": "West Valley City - UT 84128",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "West Valley City"
  },
  {
    "value": "84129",
    "label": "Salt Lake City - UT 84129",
    "county": "",
    "state": "UT",
    "city": "Salt Lake City"
  },
  {
    "value": "84133",
    "label": "Salt Lake City - UT 84133",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Salt Lake City"
  },
  {
    "value": "84138",
    "label": "Salt Lake City - UT 84138",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Salt Lake City"
  },
  {
    "value": "84144",
    "label": "Salt Lake City - UT 84144",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Salt Lake City"
  },
  {
    "value": "84180",
    "label": "Salt Lake City - UT 84180",
    "county": "Salt Lake County",
    "state": "UT",
    "city": "Salt Lake City"
  },
  {
    "value": "84302",
    "label": "Brigham City - UT 84302",
    "county": "Box Elder County",
    "state": "UT",
    "city": "Brigham City"
  },
  {
    "value": "84304",
    "label": "Cache Junction - UT 84304",
    "county": "Cache County",
    "state": "UT",
    "city": "Cache Junction"
  },
  {
    "value": "84306",
    "label": "Collinston - UT 84306",
    "county": "Box Elder County",
    "state": "UT",
    "city": "Collinston"
  },
  {
    "value": "84307",
    "label": "Corinne - UT 84307",
    "county": "Box Elder County",
    "state": "UT",
    "city": "Corinne"
  },
  {
    "value": "84308",
    "label": "Cornish - UT 84308",
    "county": "Cache County",
    "state": "UT",
    "city": "Cornish"
  },
  {
    "value": "84309",
    "label": "Deweyville - UT 84309",
    "county": "Box Elder County",
    "state": "UT",
    "city": "Deweyville"
  },
  {
    "value": "84310",
    "label": "Eden - UT 84310",
    "county": "Weber County",
    "state": "UT",
    "city": "Eden"
  },
  {
    "value": "84311",
    "label": "Fielding - UT 84311",
    "county": "Box Elder County",
    "state": "UT",
    "city": "Fielding"
  },
  {
    "value": "84312",
    "label": "Garland - UT 84312",
    "county": "Box Elder County",
    "state": "UT",
    "city": "Garland"
  },
  {
    "value": "84313",
    "label": "Grouse Creek - UT 84313",
    "county": "Box Elder County",
    "state": "UT",
    "city": "Grouse Creek"
  },
  {
    "value": "84314",
    "label": "Honeyville - UT 84314",
    "county": "Box Elder County",
    "state": "UT",
    "city": "Honeyville"
  },
  {
    "value": "84315",
    "label": "Hooper - UT 84315",
    "county": "Weber County",
    "state": "UT",
    "city": "Hooper"
  },
  {
    "value": "84317",
    "label": "Huntsville - UT 84317",
    "county": "Weber County",
    "state": "UT",
    "city": "Huntsville"
  },
  {
    "value": "84318",
    "label": "Hyde Park - UT 84318",
    "county": "Cache County",
    "state": "UT",
    "city": "Hyde Park"
  },
  {
    "value": "84319",
    "label": "Hyrum - UT 84319",
    "county": "Cache County",
    "state": "UT",
    "city": "Hyrum"
  },
  {
    "value": "84320",
    "label": "Lewiston - UT 84320",
    "county": "Cache County",
    "state": "UT",
    "city": "Lewiston"
  },
  {
    "value": "84321",
    "label": "Logan - UT 84321",
    "county": "Cache County",
    "state": "UT",
    "city": "Logan"
  },
  {
    "value": "84324",
    "label": "Mantua - UT 84324",
    "county": "Box Elder County",
    "state": "UT",
    "city": "Mantua"
  },
  {
    "value": "84325",
    "label": "Mendon - UT 84325",
    "county": "Cache County",
    "state": "UT",
    "city": "Mendon"
  },
  {
    "value": "84328",
    "label": "Paradise - UT 84328",
    "county": "Cache County",
    "state": "UT",
    "city": "Paradise"
  },
  {
    "value": "84329",
    "label": "Park Valley - UT 84329",
    "county": "Box Elder County",
    "state": "UT",
    "city": "Park Valley"
  },
  {
    "value": "84331",
    "label": "Portage - UT 84331",
    "county": "Box Elder County",
    "state": "UT",
    "city": "Portage"
  },
  {
    "value": "84332",
    "label": "Providence - UT 84332",
    "county": "Cache County",
    "state": "UT",
    "city": "Providence"
  },
  {
    "value": "84333",
    "label": "Richmond - UT 84333",
    "county": "Cache County",
    "state": "UT",
    "city": "Richmond"
  },
  {
    "value": "84335",
    "label": "Smithfield - UT 84335",
    "county": "Cache County",
    "state": "UT",
    "city": "Smithfield"
  },
  {
    "value": "84336",
    "label": "Snowville - UT 84336",
    "county": "Box Elder County",
    "state": "UT",
    "city": "Snowville"
  },
  {
    "value": "84337",
    "label": "Tremonton - UT 84337",
    "county": "Box Elder County",
    "state": "UT",
    "city": "Tremonton"
  },
  {
    "value": "84338",
    "label": "Trenton - UT 84338",
    "county": "Cache County",
    "state": "UT",
    "city": "Trenton"
  },
  {
    "value": "84339",
    "label": "Wellsville - UT 84339",
    "county": "Cache County",
    "state": "UT",
    "city": "Wellsville"
  },
  {
    "value": "84340",
    "label": "Willard - UT 84340",
    "county": "Box Elder County",
    "state": "UT",
    "city": "Willard"
  },
  {
    "value": "84341",
    "label": "Logan - UT 84341",
    "county": "Cache County",
    "state": "UT",
    "city": "Logan"
  },
  {
    "value": "84401",
    "label": "Ogden - UT 84401",
    "county": "Weber County",
    "state": "UT",
    "city": "Ogden"
  },
  {
    "value": "84403",
    "label": "Ogden - UT 84403",
    "county": "Weber County",
    "state": "UT",
    "city": "Ogden"
  },
  {
    "value": "84404",
    "label": "Ogden - UT 84404",
    "county": "Weber County",
    "state": "UT",
    "city": "Ogden"
  },
  {
    "value": "84405",
    "label": "Ogden - UT 84405",
    "county": "Weber County",
    "state": "UT",
    "city": "Ogden"
  },
  {
    "value": "84414",
    "label": "Ogden - UT 84414",
    "county": "Weber County",
    "state": "UT",
    "city": "Ogden"
  },
  {
    "value": "84501",
    "label": "Price - UT 84501",
    "county": "Carbon County",
    "state": "UT",
    "city": "Price"
  },
  {
    "value": "84511",
    "label": "Blanding - UT 84511",
    "county": "San Juan County",
    "state": "UT",
    "city": "Blanding"
  },
  {
    "value": "84515",
    "label": "Cisco - UT 84515",
    "county": "Grand County",
    "state": "UT",
    "city": "Cisco"
  },
  {
    "value": "84520",
    "label": "East Carbon - UT 84520",
    "county": "Carbon County",
    "state": "UT",
    "city": "East Carbon"
  },
  {
    "value": "84521",
    "label": "Elmo - UT 84521",
    "county": "Emery County",
    "state": "UT",
    "city": "Elmo"
  },
  {
    "value": "84525",
    "label": "Green River - UT 84525",
    "county": "Emery County",
    "state": "UT",
    "city": "Green River"
  },
  {
    "value": "84526",
    "label": "Helper - UT 84526",
    "county": "Carbon County",
    "state": "UT",
    "city": "Helper"
  },
  {
    "value": "84529",
    "label": "Kenilworth - UT 84529",
    "county": "Carbon County",
    "state": "UT",
    "city": "Kenilworth"
  },
  {
    "value": "84532",
    "label": "Moab - UT 84532",
    "county": "Grand County",
    "state": "UT",
    "city": "Moab"
  },
  {
    "value": "84533",
    "label": "Lake Powell - UT 84533",
    "county": "Kane County",
    "state": "UT",
    "city": "Lake Powell"
  },
  {
    "value": "84535",
    "label": "Monticello - UT 84535",
    "county": "San Juan County",
    "state": "UT",
    "city": "Monticello"
  },
  {
    "value": "84540",
    "label": "Thompson - UT 84540",
    "county": "Grand County",
    "state": "UT",
    "city": "Thompson"
  },
  {
    "value": "84542",
    "label": "Wellington - UT 84542",
    "county": "Carbon County",
    "state": "UT",
    "city": "Wellington"
  },
  {
    "value": "84601",
    "label": "Provo - UT 84601",
    "county": "Utah County",
    "state": "UT",
    "city": "Provo"
  },
  {
    "value": "84602",
    "label": "Provo - UT 84602",
    "county": "Utah County",
    "state": "UT",
    "city": "Provo"
  },
  {
    "value": "84604",
    "label": "Provo - UT 84604",
    "county": "Utah County",
    "state": "UT",
    "city": "Provo"
  },
  {
    "value": "84606",
    "label": "Provo - UT 84606",
    "county": "Utah County",
    "state": "UT",
    "city": "Provo"
  },
  {
    "value": "84621",
    "label": "Axtell - UT 84621",
    "county": "Sanpete County",
    "state": "UT",
    "city": "Axtell"
  },
  {
    "value": "84623",
    "label": "Chester - UT 84623",
    "county": "Sanpete County",
    "state": "UT",
    "city": "Chester"
  },
  {
    "value": "84624",
    "label": "Delta - UT 84624",
    "county": "Millard County",
    "state": "UT",
    "city": "Delta"
  },
  {
    "value": "84627",
    "label": "Ephraim - UT 84627",
    "county": "Sanpete County",
    "state": "UT",
    "city": "Ephraim"
  },
  {
    "value": "84629",
    "label": "Fairview - UT 84629",
    "county": "Sanpete County",
    "state": "UT",
    "city": "Fairview"
  },
  {
    "value": "84630",
    "label": "Fayette - UT 84630",
    "county": "Sanpete County",
    "state": "UT",
    "city": "Fayette"
  },
  {
    "value": "84631",
    "label": "Fillmore - UT 84631",
    "county": "Millard County",
    "state": "UT",
    "city": "Fillmore"
  },
  {
    "value": "84635",
    "label": "Hinckley - UT 84635",
    "county": "Millard County",
    "state": "UT",
    "city": "Hinckley"
  },
  {
    "value": "84642",
    "label": "Manti - UT 84642",
    "county": "Sanpete County",
    "state": "UT",
    "city": "Manti"
  },
  {
    "value": "84645",
    "label": "Mona - UT 84645",
    "county": "Juab County",
    "state": "UT",
    "city": "Mona"
  },
  {
    "value": "84647",
    "label": "Mount Pleasant - UT 84647",
    "county": "Sanpete County",
    "state": "UT",
    "city": "Mount Pleasant"
  },
  {
    "value": "84648",
    "label": "Nephi - UT 84648",
    "county": "Juab County",
    "state": "UT",
    "city": "Nephi"
  },
  {
    "value": "84651",
    "label": "Payson - UT 84651",
    "county": "Utah County",
    "state": "UT",
    "city": "Payson"
  },
  {
    "value": "84653",
    "label": "Salem - UT 84653",
    "county": "Utah County",
    "state": "UT",
    "city": "Salem"
  },
  {
    "value": "84654",
    "label": "Salina - UT 84654",
    "county": "Sevier County",
    "state": "UT",
    "city": "Salina"
  },
  {
    "value": "84655",
    "label": "Santaquin - UT 84655",
    "county": "Utah County",
    "state": "UT",
    "city": "Santaquin"
  },
  {
    "value": "84660",
    "label": "Spanish Fork - UT 84660",
    "county": "Utah County",
    "state": "UT",
    "city": "Spanish Fork"
  },
  {
    "value": "84663",
    "label": "Springville - UT 84663",
    "county": "Utah County",
    "state": "UT",
    "city": "Springville"
  },
  {
    "value": "84664",
    "label": "Mapleton - UT 84664",
    "county": "Utah County",
    "state": "UT",
    "city": "Mapleton"
  },
  {
    "value": "84667",
    "label": "Wales - UT 84667",
    "county": "Sanpete County",
    "state": "UT",
    "city": "Wales"
  },
  {
    "value": "84701",
    "label": "Richfield - UT 84701",
    "county": "Sevier County",
    "state": "UT",
    "city": "Richfield"
  },
  {
    "value": "84712",
    "label": "Antimony - UT 84712",
    "county": "Garfield County",
    "state": "UT",
    "city": "Antimony"
  },
  {
    "value": "84713",
    "label": "Beaver - UT 84713",
    "county": "Beaver County",
    "state": "UT",
    "city": "Beaver"
  },
  {
    "value": "84714",
    "label": "Beryl - UT 84714",
    "county": "Iron County",
    "state": "UT",
    "city": "Beryl"
  },
  {
    "value": "84717",
    "label": "Bryce Canyon - UT 84717",
    "county": "Garfield County",
    "state": "UT",
    "city": "Bryce Canyon"
  },
  {
    "value": "84720",
    "label": "Cedar City - UT 84720",
    "county": "Iron County",
    "state": "UT",
    "city": "Cedar City"
  },
  {
    "value": "84721",
    "label": "Cedar City - UT 84721",
    "county": "Iron County",
    "state": "UT",
    "city": "Cedar City"
  },
  {
    "value": "84722",
    "label": "Central - UT 84722",
    "county": "Washington County",
    "state": "UT",
    "city": "Central"
  },
  {
    "value": "84724",
    "label": "Elsinore - UT 84724",
    "county": "Sevier County",
    "state": "UT",
    "city": "Elsinore"
  },
  {
    "value": "84728",
    "label": "Garrison - UT 84728",
    "county": "Millard County",
    "state": "UT",
    "city": "Garrison"
  },
  {
    "value": "84731",
    "label": "Greenville - UT 84731",
    "county": "Beaver County",
    "state": "UT",
    "city": "Greenville"
  },
  {
    "value": "84732",
    "label": "Greenwich - UT 84732",
    "county": "Piute County",
    "state": "UT",
    "city": "Greenwich"
  },
  {
    "value": "84733",
    "label": "Gunlock - UT 84733",
    "county": "Washington County",
    "state": "UT",
    "city": "Gunlock"
  },
  {
    "value": "84737",
    "label": "Hurricane - UT 84737",
    "county": "Washington County",
    "state": "UT",
    "city": "Hurricane"
  },
  {
    "value": "84738",
    "label": "Ivins - UT 84738",
    "county": "Washington County",
    "state": "UT",
    "city": "Ivins"
  },
  {
    "value": "84739",
    "label": "Joseph - UT 84739",
    "county": "Sevier County",
    "state": "UT",
    "city": "Joseph"
  },
  {
    "value": "84741",
    "label": "Kanab - UT 84741",
    "county": "Kane County",
    "state": "UT",
    "city": "Kanab"
  },
  {
    "value": "84742",
    "label": "Kanarraville - UT 84742",
    "county": "Iron County",
    "state": "UT",
    "city": "Kanarraville"
  },
  {
    "value": "84743",
    "label": "Kingston - UT 84743",
    "county": "Piute County",
    "state": "UT",
    "city": "Kingston"
  },
  {
    "value": "84744",
    "label": "Koosharem - UT 84744",
    "county": "Sevier County",
    "state": "UT",
    "city": "Koosharem"
  },
  {
    "value": "84745",
    "label": "La Verkin - UT 84745",
    "county": "Washington County",
    "state": "UT",
    "city": "La Verkin"
  },
  {
    "value": "84747",
    "label": "Loa - UT 84747",
    "county": "Wayne County",
    "state": "UT",
    "city": "Loa"
  },
  {
    "value": "84751",
    "label": "Milford - UT 84751",
    "county": "Beaver County",
    "state": "UT",
    "city": "Milford"
  },
  {
    "value": "84753",
    "label": "Modena - UT 84753",
    "county": "Iron County",
    "state": "UT",
    "city": "Modena"
  },
  {
    "value": "84754",
    "label": "Monroe - UT 84754",
    "county": "Sevier County",
    "state": "UT",
    "city": "Monroe"
  },
  {
    "value": "84757",
    "label": "New Harmony - UT 84757",
    "county": "Washington County",
    "state": "UT",
    "city": "New Harmony"
  },
  {
    "value": "84759",
    "label": "Panguitch - UT 84759",
    "county": "Garfield County",
    "state": "UT",
    "city": "Panguitch"
  },
  {
    "value": "84762",
    "label": "Duck Creek Village - UT 84762",
    "county": "Kane County",
    "state": "UT",
    "city": "Duck Creek Village"
  },
  {
    "value": "84765",
    "label": "Santa Clara - UT 84765",
    "county": "Washington County",
    "state": "UT",
    "city": "Santa Clara"
  },
  {
    "value": "84766",
    "label": "Sevier - UT 84766",
    "county": "Sevier County",
    "state": "UT",
    "city": "Sevier"
  },
  {
    "value": "84770",
    "label": "Saint George - UT 84770",
    "county": "Washington County",
    "state": "UT",
    "city": "Saint George"
  },
  {
    "value": "84773",
    "label": "Teasdale - UT 84773",
    "county": "Wayne County",
    "state": "UT",
    "city": "Teasdale"
  },
  {
    "value": "84774",
    "label": "Toquerville - UT 84774",
    "county": "Washington County",
    "state": "UT",
    "city": "Toquerville"
  },
  {
    "value": "84775",
    "label": "Torrey - UT 84775",
    "county": "Wayne County",
    "state": "UT",
    "city": "Torrey"
  },
  {
    "value": "84779",
    "label": "Virgin - UT 84779",
    "county": "Washington County",
    "state": "UT",
    "city": "Virgin"
  },
  {
    "value": "84780",
    "label": "Washington - UT 84780",
    "county": "Washington County",
    "state": "UT",
    "city": "Washington"
  },
  {
    "value": "84781",
    "label": "Pine Valley - UT 84781",
    "county": "Washington County",
    "state": "UT",
    "city": "Pine Valley"
  },
  {
    "value": "84782",
    "label": "Veyo - UT 84782",
    "county": "Washington County",
    "state": "UT",
    "city": "Veyo"
  },
  {
    "value": "84783",
    "label": "Dammeron Valley - UT 84783",
    "county": "Washington County",
    "state": "UT",
    "city": "Dammeron Valley"
  },
  {
    "value": "84790",
    "label": "Saint George - UT 84790",
    "county": "Washington County",
    "state": "UT",
    "city": "Saint George"
  },
  {
    "value": "05001",
    "label": "White River Junction - VT 05001",
    "county": "Windsor County",
    "state": "VT",
    "city": "White River Junction"
  },
  {
    "value": "05032",
    "label": "Bethel - VT 05032",
    "county": "Windsor County",
    "state": "VT",
    "city": "Bethel"
  },
  {
    "value": "05033",
    "label": "Bradford - VT 05033",
    "county": "Orange County",
    "state": "VT",
    "city": "Bradford"
  },
  {
    "value": "05034",
    "label": "Bridgewater - VT 05034",
    "county": "Windsor County",
    "state": "VT",
    "city": "Bridgewater"
  },
  {
    "value": "05035",
    "label": "Bridgewater Corners - VT 05035",
    "county": "Windsor County",
    "state": "VT",
    "city": "Bridgewater Corners"
  },
  {
    "value": "05036",
    "label": "Brookfield - VT 05036",
    "county": "Orange County",
    "state": "VT",
    "city": "Brookfield"
  },
  {
    "value": "05037",
    "label": "Brownsville - VT 05037",
    "county": "Windsor County",
    "state": "VT",
    "city": "Brownsville"
  },
  {
    "value": "05038",
    "label": "Chelsea - VT 05038",
    "county": "Orange County",
    "state": "VT",
    "city": "Chelsea"
  },
  {
    "value": "05039",
    "label": "Corinth - VT 05039",
    "county": "Orange County",
    "state": "VT",
    "city": "Corinth"
  },
  {
    "value": "05040",
    "label": "East Corinth - VT 05040",
    "county": "Orange County",
    "state": "VT",
    "city": "East Corinth"
  },
  {
    "value": "05041",
    "label": "East Randolph - VT 05041",
    "county": "Orange County",
    "state": "VT",
    "city": "East Randolph"
  },
  {
    "value": "05042",
    "label": "East Ryegate - VT 05042",
    "county": "Caledonia County",
    "state": "VT",
    "city": "East Ryegate"
  },
  {
    "value": "05043",
    "label": "East Thetford - VT 05043",
    "county": "Orange County",
    "state": "VT",
    "city": "East Thetford"
  },
  {
    "value": "05045",
    "label": "Fairlee - VT 05045",
    "county": "Orange County",
    "state": "VT",
    "city": "Fairlee"
  },
  {
    "value": "05046",
    "label": "Groton - VT 05046",
    "county": "Caledonia County",
    "state": "VT",
    "city": "Groton"
  },
  {
    "value": "05048",
    "label": "Hartland - VT 05048",
    "county": "Windsor County",
    "state": "VT",
    "city": "Hartland"
  },
  {
    "value": "05051",
    "label": "Newbury - VT 05051",
    "county": "Orange County",
    "state": "VT",
    "city": "Newbury"
  },
  {
    "value": "05052",
    "label": "North Hartland - VT 05052",
    "county": "Windsor County",
    "state": "VT",
    "city": "North Hartland"
  },
  {
    "value": "05053",
    "label": "North Pomfret - VT 05053",
    "county": "Windsor County",
    "state": "VT",
    "city": "North Pomfret"
  },
  {
    "value": "05055",
    "label": "Norwich - VT 05055",
    "county": "Windsor County",
    "state": "VT",
    "city": "Norwich"
  },
  {
    "value": "05056",
    "label": "Plymouth - VT 05056",
    "county": "Windsor County",
    "state": "VT",
    "city": "Plymouth"
  },
  {
    "value": "05058",
    "label": "Post Mills - VT 05058",
    "county": "Orange County",
    "state": "VT",
    "city": "Post Mills"
  },
  {
    "value": "05060",
    "label": "Randolph - VT 05060",
    "county": "Orange County",
    "state": "VT",
    "city": "Randolph"
  },
  {
    "value": "05061",
    "label": "Randolph Center - VT 05061",
    "county": "Orange County",
    "state": "VT",
    "city": "Randolph Center"
  },
  {
    "value": "05062",
    "label": "Reading - VT 05062",
    "county": "Windsor County",
    "state": "VT",
    "city": "Reading"
  },
  {
    "value": "05065",
    "label": "Sharon - VT 05065",
    "county": "Windsor County",
    "state": "VT",
    "city": "Sharon"
  },
  {
    "value": "05067",
    "label": "South Pomfret - VT 05067",
    "county": "Windsor County",
    "state": "VT",
    "city": "South Pomfret"
  },
  {
    "value": "05068",
    "label": "South Royalton - VT 05068",
    "county": "Windsor County",
    "state": "VT",
    "city": "South Royalton"
  },
  {
    "value": "05069",
    "label": "South Ryegate - VT 05069",
    "county": "Caledonia County",
    "state": "VT",
    "city": "South Ryegate"
  },
  {
    "value": "05070",
    "label": "South Strafford - VT 05070",
    "county": "Orange County",
    "state": "VT",
    "city": "South Strafford"
  },
  {
    "value": "05071",
    "label": "South Woodstock - VT 05071",
    "county": "Windsor County",
    "state": "VT",
    "city": "South Woodstock"
  },
  {
    "value": "05072",
    "label": "Strafford - VT 05072",
    "county": "Orange County",
    "state": "VT",
    "city": "Strafford"
  },
  {
    "value": "05073",
    "label": "Taftsville - VT 05073",
    "county": "Windsor County",
    "state": "VT",
    "city": "Taftsville"
  },
  {
    "value": "05075",
    "label": "Thetford Center - VT 05075",
    "county": "Orange County",
    "state": "VT",
    "city": "Thetford Center"
  },
  {
    "value": "05076",
    "label": "Topsham - VT 05076",
    "county": "Orange County",
    "state": "VT",
    "city": "Topsham"
  },
  {
    "value": "05077",
    "label": "Tunbridge - VT 05077",
    "county": "Orange County",
    "state": "VT",
    "city": "Tunbridge"
  },
  {
    "value": "05079",
    "label": "Vershire - VT 05079",
    "county": "Orange County",
    "state": "VT",
    "city": "Vershire"
  },
  {
    "value": "05081",
    "label": "Wells River - VT 05081",
    "county": "Orange County",
    "state": "VT",
    "city": "Wells River"
  },
  {
    "value": "05083",
    "label": "West Fairlee - VT 05083",
    "county": "Orange County",
    "state": "VT",
    "city": "West Fairlee"
  },
  {
    "value": "05084",
    "label": "West Hartford - VT 05084",
    "county": "Windsor County",
    "state": "VT",
    "city": "West Hartford"
  },
  {
    "value": "05086",
    "label": "West Topsham - VT 05086",
    "county": "Orange County",
    "state": "VT",
    "city": "West Topsham"
  },
  {
    "value": "05089",
    "label": "Windsor - VT 05089",
    "county": "Windsor County",
    "state": "VT",
    "city": "Windsor"
  },
  {
    "value": "05091",
    "label": "Woodstock - VT 05091",
    "county": "Windsor County",
    "state": "VT",
    "city": "Woodstock"
  },
  {
    "value": "05101",
    "label": "Bellows Falls - VT 05101",
    "county": "Windham County",
    "state": "VT",
    "city": "Bellows Falls"
  },
  {
    "value": "05141",
    "label": "Cambridgeport - VT 05141",
    "county": "Windham County",
    "state": "VT",
    "city": "Cambridgeport"
  },
  {
    "value": "05142",
    "label": "Cavendish - VT 05142",
    "county": "Windsor County",
    "state": "VT",
    "city": "Cavendish"
  },
  {
    "value": "05143",
    "label": "Chester - VT 05143",
    "county": "Windsor County",
    "state": "VT",
    "city": "Chester"
  },
  {
    "value": "05146",
    "label": "Grafton - VT 05146",
    "county": "Windham County",
    "state": "VT",
    "city": "Grafton"
  },
  {
    "value": "05148",
    "label": "Londonderry - VT 05148",
    "county": "Windham County",
    "state": "VT",
    "city": "Londonderry"
  },
  {
    "value": "05149",
    "label": "Ludlow - VT 05149",
    "county": "Windsor County",
    "state": "VT",
    "city": "Ludlow"
  },
  {
    "value": "05150",
    "label": "North Springfield - VT 05150",
    "county": "Windsor County",
    "state": "VT",
    "city": "North Springfield"
  },
  {
    "value": "05151",
    "label": "Perkinsville - VT 05151",
    "county": "Windsor County",
    "state": "VT",
    "city": "Perkinsville"
  },
  {
    "value": "05152",
    "label": "Peru - VT 05152",
    "county": "Bennington County",
    "state": "VT",
    "city": "Peru"
  },
  {
    "value": "05153",
    "label": "Proctorsville - VT 05153",
    "county": "Windsor County",
    "state": "VT",
    "city": "Proctorsville"
  },
  {
    "value": "05154",
    "label": "Saxtons River - VT 05154",
    "county": "Windham County",
    "state": "VT",
    "city": "Saxtons River"
  },
  {
    "value": "05155",
    "label": "South Londonderry - VT 05155",
    "county": "Windham County",
    "state": "VT",
    "city": "South Londonderry"
  },
  {
    "value": "05156",
    "label": "Springfield - VT 05156",
    "county": "Windsor County",
    "state": "VT",
    "city": "Springfield"
  },
  {
    "value": "05158",
    "label": "Westminster - VT 05158",
    "county": "Windham County",
    "state": "VT",
    "city": "Westminster"
  },
  {
    "value": "05161",
    "label": "Weston - VT 05161",
    "county": "Windsor County",
    "state": "VT",
    "city": "Weston"
  },
  {
    "value": "05201",
    "label": "Bennington - VT 05201",
    "county": "Bennington County",
    "state": "VT",
    "city": "Bennington"
  },
  {
    "value": "05250",
    "label": "Arlington - VT 05250",
    "county": "Bennington County",
    "state": "VT",
    "city": "Arlington"
  },
  {
    "value": "05251",
    "label": "Dorset - VT 05251",
    "county": "Bennington County",
    "state": "VT",
    "city": "Dorset"
  },
  {
    "value": "05252",
    "label": "East Arlington - VT 05252",
    "county": "Bennington County",
    "state": "VT",
    "city": "East Arlington"
  },
  {
    "value": "05253",
    "label": "East Dorset - VT 05253",
    "county": "Bennington County",
    "state": "VT",
    "city": "East Dorset"
  },
  {
    "value": "05255",
    "label": "Manchester Center - VT 05255",
    "county": "Bennington County",
    "state": "VT",
    "city": "Manchester Center"
  },
  {
    "value": "05257",
    "label": "North Bennington - VT 05257",
    "county": "Bennington County",
    "state": "VT",
    "city": "North Bennington"
  },
  {
    "value": "05260",
    "label": "North Pownal - VT 05260",
    "county": "Bennington County",
    "state": "VT",
    "city": "North Pownal"
  },
  {
    "value": "05261",
    "label": "Pownal - VT 05261",
    "county": "Bennington County",
    "state": "VT",
    "city": "Pownal"
  },
  {
    "value": "05262",
    "label": "Shaftsbury - VT 05262",
    "county": "Bennington County",
    "state": "VT",
    "city": "Shaftsbury"
  },
  {
    "value": "05301",
    "label": "Brattleboro - VT 05301",
    "county": "Windham County",
    "state": "VT",
    "city": "Brattleboro"
  },
  {
    "value": "05340",
    "label": "Bondville - VT 05340",
    "county": "Bennington County",
    "state": "VT",
    "city": "Bondville"
  },
  {
    "value": "05341",
    "label": "East Dover - VT 05341",
    "county": "Windham County",
    "state": "VT",
    "city": "East Dover"
  },
  {
    "value": "05342",
    "label": "Jacksonville - VT 05342",
    "county": "Windham County",
    "state": "VT",
    "city": "Jacksonville"
  },
  {
    "value": "05343",
    "label": "Jamaica - VT 05343",
    "county": "Windham County",
    "state": "VT",
    "city": "Jamaica"
  },
  {
    "value": "05345",
    "label": "Newfane - VT 05345",
    "county": "Windham County",
    "state": "VT",
    "city": "Newfane"
  },
  {
    "value": "05346",
    "label": "Putney - VT 05346",
    "county": "Windham County",
    "state": "VT",
    "city": "Putney"
  },
  {
    "value": "05350",
    "label": "Readsboro - VT 05350",
    "county": "Bennington County",
    "state": "VT",
    "city": "Readsboro"
  },
  {
    "value": "05351",
    "label": "South Newfane - VT 05351",
    "county": "Windham County",
    "state": "VT",
    "city": "South Newfane"
  },
  {
    "value": "05352",
    "label": "Stamford - VT 05352",
    "county": "Bennington County",
    "state": "VT",
    "city": "Stamford"
  },
  {
    "value": "05353",
    "label": "Townshend - VT 05353",
    "county": "Windham County",
    "state": "VT",
    "city": "Townshend"
  },
  {
    "value": "05354",
    "label": "Vernon - VT 05354",
    "county": "Windham County",
    "state": "VT",
    "city": "Vernon"
  },
  {
    "value": "05355",
    "label": "Wardsboro - VT 05355",
    "county": "Windham County",
    "state": "VT",
    "city": "Wardsboro"
  },
  {
    "value": "05356",
    "label": "West Dover - VT 05356",
    "county": "Windham County",
    "state": "VT",
    "city": "West Dover"
  },
  {
    "value": "05358",
    "label": "West Halifax - VT 05358",
    "county": "Windham County",
    "state": "VT",
    "city": "West Halifax"
  },
  {
    "value": "05359",
    "label": "West Townshend - VT 05359",
    "county": "Windham County",
    "state": "VT",
    "city": "West Townshend"
  },
  {
    "value": "05360",
    "label": "West Wardsboro - VT 05360",
    "county": "Windham County",
    "state": "VT",
    "city": "West Wardsboro"
  },
  {
    "value": "05361",
    "label": "Whitingham - VT 05361",
    "county": "Windham County",
    "state": "VT",
    "city": "Whitingham"
  },
  {
    "value": "05362",
    "label": "Williamsville - VT 05362",
    "county": "Windham County",
    "state": "VT",
    "city": "Williamsville"
  },
  {
    "value": "05363",
    "label": "Wilmington - VT 05363",
    "county": "Windham County",
    "state": "VT",
    "city": "Wilmington"
  },
  {
    "value": "05401",
    "label": "Burlington - VT 05401",
    "county": "Chittenden County",
    "state": "VT",
    "city": "Burlington"
  },
  {
    "value": "05403",
    "label": "South Burlington - VT 05403",
    "county": "Chittenden County",
    "state": "VT",
    "city": "South Burlington"
  },
  {
    "value": "05404",
    "label": "Winooski - VT 05404",
    "county": "Chittenden County",
    "state": "VT",
    "city": "Winooski"
  },
  {
    "value": "05408",
    "label": "Burlington - VT 05408",
    "county": "Chittenden County",
    "state": "VT",
    "city": "Burlington"
  },
  {
    "value": "05440",
    "label": "Alburgh - VT 05440",
    "county": "Grand Isle County",
    "state": "VT",
    "city": "Alburgh"
  },
  {
    "value": "05441",
    "label": "Bakersfield - VT 05441",
    "county": "Franklin County",
    "state": "VT",
    "city": "Bakersfield"
  },
  {
    "value": "05442",
    "label": "Belvidere Center - VT 05442",
    "county": "Lamoille County",
    "state": "VT",
    "city": "Belvidere Center"
  },
  {
    "value": "05443",
    "label": "Bristol - VT 05443",
    "county": "Addison County",
    "state": "VT",
    "city": "Bristol"
  },
  {
    "value": "05444",
    "label": "Cambridge - VT 05444",
    "county": "Franklin County",
    "state": "VT",
    "city": "Cambridge"
  },
  {
    "value": "05445",
    "label": "Charlotte - VT 05445",
    "county": "Chittenden County",
    "state": "VT",
    "city": "Charlotte"
  },
  {
    "value": "05446",
    "label": "Colchester - VT 05446",
    "county": "Chittenden County",
    "state": "VT",
    "city": "Colchester"
  },
  {
    "value": "05447",
    "label": "East Berkshire - VT 05447",
    "county": "Franklin County",
    "state": "VT",
    "city": "East Berkshire"
  },
  {
    "value": "05448",
    "label": "East Fairfield - VT 05448",
    "county": "Franklin County",
    "state": "VT",
    "city": "East Fairfield"
  },
  {
    "value": "05450",
    "label": "Enosburg Falls - VT 05450",
    "county": "Franklin County",
    "state": "VT",
    "city": "Enosburg Falls"
  },
  {
    "value": "05452",
    "label": "Essex Junction - VT 05452",
    "county": "Chittenden County",
    "state": "VT",
    "city": "Essex Junction"
  },
  {
    "value": "05454",
    "label": "Fairfax - VT 05454",
    "county": "Franklin County",
    "state": "VT",
    "city": "Fairfax"
  },
  {
    "value": "05455",
    "label": "Fairfield - VT 05455",
    "county": "Franklin County",
    "state": "VT",
    "city": "Fairfield"
  },
  {
    "value": "05456",
    "label": "Ferrisburgh - VT 05456",
    "county": "Addison County",
    "state": "VT",
    "city": "Ferrisburgh"
  },
  {
    "value": "05457",
    "label": "Franklin - VT 05457",
    "county": "Franklin County",
    "state": "VT",
    "city": "Franklin"
  },
  {
    "value": "05458",
    "label": "Grand Isle - VT 05458",
    "county": "Grand Isle County",
    "state": "VT",
    "city": "Grand Isle"
  },
  {
    "value": "05459",
    "label": "Highgate Center - VT 05459",
    "county": "Franklin County",
    "state": "VT",
    "city": "Highgate Center"
  },
  {
    "value": "05461",
    "label": "Hinesburg - VT 05461",
    "county": "Chittenden County",
    "state": "VT",
    "city": "Hinesburg"
  },
  {
    "value": "05462",
    "label": "Huntington - VT 05462",
    "county": "Chittenden County",
    "state": "VT",
    "city": "Huntington"
  },
  {
    "value": "05463",
    "label": "Isle La Motte - VT 05463",
    "county": "Grand Isle County",
    "state": "VT",
    "city": "Isle La Motte"
  },
  {
    "value": "05464",
    "label": "Jeffersonville - VT 05464",
    "county": "Lamoille County",
    "state": "VT",
    "city": "Jeffersonville"
  },
  {
    "value": "05465",
    "label": "Jericho - VT 05465",
    "county": "Chittenden County",
    "state": "VT",
    "city": "Jericho"
  },
  {
    "value": "05468",
    "label": "Milton - VT 05468",
    "county": "Chittenden County",
    "state": "VT",
    "city": "Milton"
  },
  {
    "value": "05471",
    "label": "Montgomery Center - VT 05471",
    "county": "Franklin County",
    "state": "VT",
    "city": "Montgomery Center"
  },
  {
    "value": "05472",
    "label": "New Haven - VT 05472",
    "county": "Addison County",
    "state": "VT",
    "city": "New Haven"
  },
  {
    "value": "05473",
    "label": "North Ferrisburgh - VT 05473",
    "county": "Addison County",
    "state": "VT",
    "city": "North Ferrisburgh"
  },
  {
    "value": "05474",
    "label": "North Hero - VT 05474",
    "county": "Grand Isle County",
    "state": "VT",
    "city": "North Hero"
  },
  {
    "value": "05476",
    "label": "Richford - VT 05476",
    "county": "Franklin County",
    "state": "VT",
    "city": "Richford"
  },
  {
    "value": "05477",
    "label": "Richmond - VT 05477",
    "county": "Chittenden County",
    "state": "VT",
    "city": "Richmond"
  },
  {
    "value": "05478",
    "label": "Saint Albans - VT 05478",
    "county": "Franklin County",
    "state": "VT",
    "city": "Saint Albans"
  },
  {
    "value": "05482",
    "label": "Shelburne - VT 05482",
    "county": "Chittenden County",
    "state": "VT",
    "city": "Shelburne"
  },
  {
    "value": "05483",
    "label": "Sheldon - VT 05483",
    "county": "Franklin County",
    "state": "VT",
    "city": "Sheldon"
  },
  {
    "value": "05486",
    "label": "South Hero - VT 05486",
    "county": "Grand Isle County",
    "state": "VT",
    "city": "South Hero"
  },
  {
    "value": "05487",
    "label": "Starksboro - VT 05487",
    "county": "Addison County",
    "state": "VT",
    "city": "Starksboro"
  },
  {
    "value": "05488",
    "label": "Swanton - VT 05488",
    "county": "Franklin County",
    "state": "VT",
    "city": "Swanton"
  },
  {
    "value": "05489",
    "label": "Underhill - VT 05489",
    "county": "Chittenden County",
    "state": "VT",
    "city": "Underhill"
  },
  {
    "value": "05491",
    "label": "Vergennes - VT 05491",
    "county": "Addison County",
    "state": "VT",
    "city": "Vergennes"
  },
  {
    "value": "05492",
    "label": "Waterville - VT 05492",
    "county": "Lamoille County",
    "state": "VT",
    "city": "Waterville"
  },
  {
    "value": "05494",
    "label": "Westford - VT 05494",
    "county": "Chittenden County",
    "state": "VT",
    "city": "Westford"
  },
  {
    "value": "05495",
    "label": "Williston - VT 05495",
    "county": "Chittenden County",
    "state": "VT",
    "city": "Williston"
  },
  {
    "value": "05602",
    "label": "Montpelier - VT 05602",
    "county": "Washington County",
    "state": "VT",
    "city": "Montpelier"
  },
  {
    "value": "05640",
    "label": "Adamant - VT 05640",
    "county": "Washington County",
    "state": "VT",
    "city": "Adamant"
  },
  {
    "value": "05641",
    "label": "Barre - VT 05641",
    "county": "Washington County",
    "state": "VT",
    "city": "Barre"
  },
  {
    "value": "05647",
    "label": "Cabot - VT 05647",
    "county": "Washington County",
    "state": "VT",
    "city": "Cabot"
  },
  {
    "value": "05648",
    "label": "Calais - VT 05648",
    "county": "Washington County",
    "state": "VT",
    "city": "Calais"
  },
  {
    "value": "05649",
    "label": "East Barre - VT 05649",
    "county": "Orange County",
    "state": "VT",
    "city": "East Barre"
  },
  {
    "value": "05650",
    "label": "East Calais - VT 05650",
    "county": "Washington County",
    "state": "VT",
    "city": "East Calais"
  },
  {
    "value": "05651",
    "label": "East Montpelier - VT 05651",
    "county": "Washington County",
    "state": "VT",
    "city": "East Montpelier"
  },
  {
    "value": "05652",
    "label": "Eden - VT 05652",
    "county": "Lamoille County",
    "state": "VT",
    "city": "Eden"
  },
  {
    "value": "05653",
    "label": "Eden Mills - VT 05653",
    "county": "Lamoille County",
    "state": "VT",
    "city": "Eden Mills"
  },
  {
    "value": "05654",
    "label": "Graniteville - VT 05654",
    "county": "Washington County",
    "state": "VT",
    "city": "Graniteville"
  },
  {
    "value": "05655",
    "label": "Hyde Park - VT 05655",
    "county": "Lamoille County",
    "state": "VT",
    "city": "Hyde Park"
  },
  {
    "value": "05656",
    "label": "Johnson - VT 05656",
    "county": "Lamoille County",
    "state": "VT",
    "city": "Johnson"
  },
  {
    "value": "05658",
    "label": "Marshfield - VT 05658",
    "county": "Washington County",
    "state": "VT",
    "city": "Marshfield"
  },
  {
    "value": "05660",
    "label": "Moretown - VT 05660",
    "county": "Washington County",
    "state": "VT",
    "city": "Moretown"
  },
  {
    "value": "05661",
    "label": "Morrisville - VT 05661",
    "county": "Lamoille County",
    "state": "VT",
    "city": "Morrisville"
  },
  {
    "value": "05663",
    "label": "Northfield - VT 05663",
    "county": "Washington County",
    "state": "VT",
    "city": "Northfield"
  },
  {
    "value": "05666",
    "label": "North Montpelier - VT 05666",
    "county": "Washington County",
    "state": "VT",
    "city": "North Montpelier"
  },
  {
    "value": "05667",
    "label": "Plainfield - VT 05667",
    "county": "Washington County",
    "state": "VT",
    "city": "Plainfield"
  },
  {
    "value": "05669",
    "label": "Roxbury - VT 05669",
    "county": "Washington County",
    "state": "VT",
    "city": "Roxbury"
  },
  {
    "value": "05672",
    "label": "Stowe - VT 05672",
    "county": "Lamoille County",
    "state": "VT",
    "city": "Stowe"
  },
  {
    "value": "05673",
    "label": "Waitsfield - VT 05673",
    "county": "Washington County",
    "state": "VT",
    "city": "Waitsfield"
  },
  {
    "value": "05674",
    "label": "Warren - VT 05674",
    "county": "Washington County",
    "state": "VT",
    "city": "Warren"
  },
  {
    "value": "05675",
    "label": "Washington - VT 05675",
    "county": "Orange County",
    "state": "VT",
    "city": "Washington"
  },
  {
    "value": "05676",
    "label": "Waterbury - VT 05676",
    "county": "Washington County",
    "state": "VT",
    "city": "Waterbury"
  },
  {
    "value": "05677",
    "label": "Waterbury Center - VT 05677",
    "county": "Washington County",
    "state": "VT",
    "city": "Waterbury Center"
  },
  {
    "value": "05679",
    "label": "Williamstown - VT 05679",
    "county": "Orange County",
    "state": "VT",
    "city": "Williamstown"
  },
  {
    "value": "05680",
    "label": "Wolcott - VT 05680",
    "county": "Lamoille County",
    "state": "VT",
    "city": "Wolcott"
  },
  {
    "value": "05681",
    "label": "Woodbury - VT 05681",
    "county": "Washington County",
    "state": "VT",
    "city": "Woodbury"
  },
  {
    "value": "05682",
    "label": "Worcester - VT 05682",
    "county": "Washington County",
    "state": "VT",
    "city": "Worcester"
  },
  {
    "value": "05701",
    "label": "Rutland - VT 05701",
    "county": "Rutland County",
    "state": "VT",
    "city": "Rutland"
  },
  {
    "value": "05730",
    "label": "Belmont - VT 05730",
    "county": "Rutland County",
    "state": "VT",
    "city": "Belmont"
  },
  {
    "value": "05732",
    "label": "Bomoseen - VT 05732",
    "county": "Rutland County",
    "state": "VT",
    "city": "Bomoseen"
  },
  {
    "value": "05733",
    "label": "Brandon - VT 05733",
    "county": "Rutland County",
    "state": "VT",
    "city": "Brandon"
  },
  {
    "value": "05734",
    "label": "Bridport - VT 05734",
    "county": "Addison County",
    "state": "VT",
    "city": "Bridport"
  },
  {
    "value": "05735",
    "label": "Castleton - VT 05735",
    "county": "Rutland County",
    "state": "VT",
    "city": "Castleton"
  },
  {
    "value": "05736",
    "label": "Center Rutland - VT 05736",
    "county": "Rutland County",
    "state": "VT",
    "city": "Center Rutland"
  },
  {
    "value": "05737",
    "label": "Chittenden - VT 05737",
    "county": "Rutland County",
    "state": "VT",
    "city": "Chittenden"
  },
  {
    "value": "05738",
    "label": "Cuttingsville - VT 05738",
    "county": "Rutland County",
    "state": "VT",
    "city": "Cuttingsville"
  },
  {
    "value": "05739",
    "label": "Danby - VT 05739",
    "county": "Rutland County",
    "state": "VT",
    "city": "Danby"
  },
  {
    "value": "05742",
    "label": "East Wallingford - VT 05742",
    "county": "Rutland County",
    "state": "VT",
    "city": "East Wallingford"
  },
  {
    "value": "05743",
    "label": "Fair Haven - VT 05743",
    "county": "Rutland County",
    "state": "VT",
    "city": "Fair Haven"
  },
  {
    "value": "05744",
    "label": "Florence - VT 05744",
    "county": "Rutland County",
    "state": "VT",
    "city": "Florence"
  },
  {
    "value": "05747",
    "label": "Granville - VT 05747",
    "county": "Addison County",
    "state": "VT",
    "city": "Granville"
  },
  {
    "value": "05748",
    "label": "Hancock - VT 05748",
    "county": "Addison County",
    "state": "VT",
    "city": "Hancock"
  },
  {
    "value": "05751",
    "label": "Killington - VT 05751",
    "county": "Rutland County",
    "state": "VT",
    "city": "Killington"
  },
  {
    "value": "05753",
    "label": "Middlebury - VT 05753",
    "county": "Addison County",
    "state": "VT",
    "city": "Middlebury"
  },
  {
    "value": "05757",
    "label": "Middletown Springs - VT 05757",
    "county": "Rutland County",
    "state": "VT",
    "city": "Middletown Springs"
  },
  {
    "value": "05758",
    "label": "Mount Holly - VT 05758",
    "county": "Rutland County",
    "state": "VT",
    "city": "Mount Holly"
  },
  {
    "value": "05759",
    "label": "North Clarendon - VT 05759",
    "county": "Rutland County",
    "state": "VT",
    "city": "North Clarendon"
  },
  {
    "value": "05760",
    "label": "Orwell - VT 05760",
    "county": "Addison County",
    "state": "VT",
    "city": "Orwell"
  },
  {
    "value": "05761",
    "label": "Pawlet - VT 05761",
    "county": "Rutland County",
    "state": "VT",
    "city": "Pawlet"
  },
  {
    "value": "05762",
    "label": "Pittsfield - VT 05762",
    "county": "Rutland County",
    "state": "VT",
    "city": "Pittsfield"
  },
  {
    "value": "05763",
    "label": "Pittsford - VT 05763",
    "county": "Rutland County",
    "state": "VT",
    "city": "Pittsford"
  },
  {
    "value": "05764",
    "label": "Poultney - VT 05764",
    "county": "Rutland County",
    "state": "VT",
    "city": "Poultney"
  },
  {
    "value": "05765",
    "label": "Proctor - VT 05765",
    "county": "Rutland County",
    "state": "VT",
    "city": "Proctor"
  },
  {
    "value": "05766",
    "label": "Ripton - VT 05766",
    "county": "Addison County",
    "state": "VT",
    "city": "Ripton"
  },
  {
    "value": "05767",
    "label": "Rochester - VT 05767",
    "county": "Windsor County",
    "state": "VT",
    "city": "Rochester"
  },
  {
    "value": "05769",
    "label": "Salisbury - VT 05769",
    "county": "Addison County",
    "state": "VT",
    "city": "Salisbury"
  },
  {
    "value": "05770",
    "label": "Shoreham - VT 05770",
    "county": "Addison County",
    "state": "VT",
    "city": "Shoreham"
  },
  {
    "value": "05772",
    "label": "Stockbridge - VT 05772",
    "county": "Windsor County",
    "state": "VT",
    "city": "Stockbridge"
  },
  {
    "value": "05773",
    "label": "Wallingford - VT 05773",
    "county": "Rutland County",
    "state": "VT",
    "city": "Wallingford"
  },
  {
    "value": "05774",
    "label": "Wells - VT 05774",
    "county": "Rutland County",
    "state": "VT",
    "city": "Wells"
  },
  {
    "value": "05775",
    "label": "West Pawlet - VT 05775",
    "county": "Rutland County",
    "state": "VT",
    "city": "West Pawlet"
  },
  {
    "value": "05776",
    "label": "West Rupert - VT 05776",
    "county": "Bennington County",
    "state": "VT",
    "city": "West Rupert"
  },
  {
    "value": "05777",
    "label": "West Rutland - VT 05777",
    "county": "Rutland County",
    "state": "VT",
    "city": "West Rutland"
  },
  {
    "value": "05778",
    "label": "Whiting - VT 05778",
    "county": "Addison County",
    "state": "VT",
    "city": "Whiting"
  },
  {
    "value": "05819",
    "label": "Saint Johnsbury - VT 05819",
    "county": "Caledonia County",
    "state": "VT",
    "city": "Saint Johnsbury"
  },
  {
    "value": "05820",
    "label": "Albany - VT 05820",
    "county": "Orleans County",
    "state": "VT",
    "city": "Albany"
  },
  {
    "value": "05821",
    "label": "Barnet - VT 05821",
    "county": "Caledonia County",
    "state": "VT",
    "city": "Barnet"
  },
  {
    "value": "05822",
    "label": "Barton - VT 05822",
    "county": "Orleans County",
    "state": "VT",
    "city": "Barton"
  },
  {
    "value": "05824",
    "label": "Concord - VT 05824",
    "county": "Essex County",
    "state": "VT",
    "city": "Concord"
  },
  {
    "value": "05826",
    "label": "Craftsbury - VT 05826",
    "county": "Orleans County",
    "state": "VT",
    "city": "Craftsbury"
  },
  {
    "value": "05827",
    "label": "Craftsbury Common - VT 05827",
    "county": "Orleans County",
    "state": "VT",
    "city": "Craftsbury Common"
  },
  {
    "value": "05828",
    "label": "Danville - VT 05828",
    "county": "Caledonia County",
    "state": "VT",
    "city": "Danville"
  },
  {
    "value": "05829",
    "label": "Derby - VT 05829",
    "county": "Orleans County",
    "state": "VT",
    "city": "Derby"
  },
  {
    "value": "05830",
    "label": "Derby Line - VT 05830",
    "county": "Orleans County",
    "state": "VT",
    "city": "Derby Line"
  },
  {
    "value": "05832",
    "label": "East Burke - VT 05832",
    "county": "Caledonia County",
    "state": "VT",
    "city": "East Burke"
  },
  {
    "value": "05833",
    "label": "East Charleston - VT 05833",
    "county": "Orleans County",
    "state": "VT",
    "city": "East Charleston"
  },
  {
    "value": "05836",
    "label": "East Hardwick - VT 05836",
    "county": "Caledonia County",
    "state": "VT",
    "city": "East Hardwick"
  },
  {
    "value": "05837",
    "label": "East Haven - VT 05837",
    "county": "Essex County",
    "state": "VT",
    "city": "East Haven"
  },
  {
    "value": "05839",
    "label": "Glover - VT 05839",
    "county": "Orleans County",
    "state": "VT",
    "city": "Glover"
  },
  {
    "value": "05841",
    "label": "Greensboro - VT 05841",
    "county": "Orleans County",
    "state": "VT",
    "city": "Greensboro"
  },
  {
    "value": "05842",
    "label": "Greensboro Bend - VT 05842",
    "county": "Orleans County",
    "state": "VT",
    "city": "Greensboro Bend"
  },
  {
    "value": "05843",
    "label": "Hardwick - VT 05843",
    "county": "Caledonia County",
    "state": "VT",
    "city": "Hardwick"
  },
  {
    "value": "05845",
    "label": "Irasburg - VT 05845",
    "county": "Orleans County",
    "state": "VT",
    "city": "Irasburg"
  },
  {
    "value": "05846",
    "label": "Island Pond - VT 05846",
    "county": "Essex County",
    "state": "VT",
    "city": "Island Pond"
  },
  {
    "value": "05847",
    "label": "Lowell - VT 05847",
    "county": "Orleans County",
    "state": "VT",
    "city": "Lowell"
  },
  {
    "value": "05850",
    "label": "Lyndon Center - VT 05850",
    "county": "Caledonia County",
    "state": "VT",
    "city": "Lyndon Center"
  },
  {
    "value": "05851",
    "label": "Lyndonville - VT 05851",
    "county": "Caledonia County",
    "state": "VT",
    "city": "Lyndonville"
  },
  {
    "value": "05853",
    "label": "Morgan - VT 05853",
    "county": "Orleans County",
    "state": "VT",
    "city": "Morgan"
  },
  {
    "value": "05855",
    "label": "Newport - VT 05855",
    "county": "Orleans County",
    "state": "VT",
    "city": "Newport"
  },
  {
    "value": "05857",
    "label": "Newport Center - VT 05857",
    "county": "Orleans County",
    "state": "VT",
    "city": "Newport Center"
  },
  {
    "value": "05858",
    "label": "North Concord - VT 05858",
    "county": "Essex County",
    "state": "VT",
    "city": "North Concord"
  },
  {
    "value": "05859",
    "label": "North Troy - VT 05859",
    "county": "Orleans County",
    "state": "VT",
    "city": "North Troy"
  },
  {
    "value": "05860",
    "label": "Orleans - VT 05860",
    "county": "Orleans County",
    "state": "VT",
    "city": "Orleans"
  },
  {
    "value": "05862",
    "label": "Peacham - VT 05862",
    "county": "Caledonia County",
    "state": "VT",
    "city": "Peacham"
  },
  {
    "value": "05866",
    "label": "Sheffield - VT 05866",
    "county": "Caledonia County",
    "state": "VT",
    "city": "Sheffield"
  },
  {
    "value": "05867",
    "label": "Sutton - VT 05867",
    "county": "Caledonia County",
    "state": "VT",
    "city": "Sutton"
  },
  {
    "value": "05868",
    "label": "Troy - VT 05868",
    "county": "Orleans County",
    "state": "VT",
    "city": "Troy"
  },
  {
    "value": "05871",
    "label": "West Burke - VT 05871",
    "county": "Caledonia County",
    "state": "VT",
    "city": "West Burke"
  },
  {
    "value": "05872",
    "label": "West Charleston - VT 05872",
    "county": "Orleans County",
    "state": "VT",
    "city": "West Charleston"
  },
  {
    "value": "05873",
    "label": "West Danville - VT 05873",
    "county": "Caledonia County",
    "state": "VT",
    "city": "West Danville"
  },
  {
    "value": "05874",
    "label": "Westfield - VT 05874",
    "county": "Orleans County",
    "state": "VT",
    "city": "Westfield"
  },
  {
    "value": "05875",
    "label": "Barton - VT 05875",
    "county": "Orleans County",
    "state": "VT",
    "city": "Barton"
  },
  {
    "value": "05902",
    "label": "Beecher Falls - VT 05902",
    "county": "Essex County",
    "state": "VT",
    "city": "Beecher Falls"
  },
  {
    "value": "05903",
    "label": "Canaan - VT 05903",
    "county": "Essex County",
    "state": "VT",
    "city": "Canaan"
  },
  {
    "value": "05904",
    "label": "Gilman - VT 05904",
    "county": "Essex County",
    "state": "VT",
    "city": "Gilman"
  },
  {
    "value": "05905",
    "label": "Guildhall - VT 05905",
    "county": "Essex County",
    "state": "VT",
    "city": "Guildhall"
  },
  {
    "value": "05906",
    "label": "Lunenburg - VT 05906",
    "county": "Essex County",
    "state": "VT",
    "city": "Lunenburg"
  },
  {
    "value": "05907",
    "label": "Norton - VT 05907",
    "county": "Essex County",
    "state": "VT",
    "city": "Norton"
  },
  {
    "value": "00802",
    "label": "St Thomas - VI 00802",
    "county": "",
    "state": "VI",
    "city": "St Thomas"
  },
  {
    "value": "00820",
    "label": "Christiansted - VI 00820",
    "county": "",
    "state": "VI",
    "city": "Christiansted"
  },
  {
    "value": "00830",
    "label": "St John - VI 00830",
    "county": "",
    "state": "VI",
    "city": "St John"
  },
  {
    "value": "00840",
    "label": "Frederiksted - VI 00840",
    "county": "",
    "state": "VI",
    "city": "Frederiksted"
  },
  {
    "value": "00850",
    "label": "Kingshill - VI 00850",
    "county": "",
    "state": "VI",
    "city": "Kingshill"
  },
  {
    "value": "20105",
    "label": "Aldie - VA 20105",
    "county": "Loudoun County",
    "state": "VA",
    "city": "Aldie"
  },
  {
    "value": "20106",
    "label": "Amissville - VA 20106",
    "county": "Culpeper County",
    "state": "VA",
    "city": "Amissville"
  },
  {
    "value": "20107",
    "label": "Arcola - VA 20107",
    "county": "Loudoun County",
    "state": "VA",
    "city": "Arcola"
  },
  {
    "value": "20109",
    "label": "Manassas - VA 20109",
    "county": "Prince William County",
    "state": "VA",
    "city": "Manassas"
  },
  {
    "value": "20110",
    "label": "Manassas - VA 20110",
    "county": "Manassas city",
    "state": "VA",
    "city": "Manassas"
  },
  {
    "value": "20111",
    "label": "Manassas - VA 20111",
    "county": "Prince William County",
    "state": "VA",
    "city": "Manassas"
  },
  {
    "value": "20112",
    "label": "Manassas - VA 20112",
    "county": "Prince William County",
    "state": "VA",
    "city": "Manassas"
  },
  {
    "value": "20115",
    "label": "Marshall - VA 20115",
    "county": "Fauquier County",
    "state": "VA",
    "city": "Marshall"
  },
  {
    "value": "20117",
    "label": "Middleburg - VA 20117",
    "county": "Loudoun County",
    "state": "VA",
    "city": "Middleburg"
  },
  {
    "value": "20119",
    "label": "Catlett - VA 20119",
    "county": "Fauquier County",
    "state": "VA",
    "city": "Catlett"
  },
  {
    "value": "20120",
    "label": "Centreville - VA 20120",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Centreville"
  },
  {
    "value": "20121",
    "label": "Centreville - VA 20121",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Centreville"
  },
  {
    "value": "20124",
    "label": "Clifton - VA 20124",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Clifton"
  },
  {
    "value": "20129",
    "label": "Paeonian Springs - VA 20129",
    "county": "Loudoun County",
    "state": "VA",
    "city": "Paeonian Springs"
  },
  {
    "value": "20130",
    "label": "Paris - VA 20130",
    "county": "Clarke County",
    "state": "VA",
    "city": "Paris"
  },
  {
    "value": "20132",
    "label": "Purcellville - VA 20132",
    "county": "Loudoun County",
    "state": "VA",
    "city": "Purcellville"
  },
  {
    "value": "20135",
    "label": "Bluemont - VA 20135",
    "county": "Clarke County",
    "state": "VA",
    "city": "Bluemont"
  },
  {
    "value": "20136",
    "label": "Bristow - VA 20136",
    "county": "Prince William County",
    "state": "VA",
    "city": "Bristow"
  },
  {
    "value": "20137",
    "label": "Broad Run - VA 20137",
    "county": "Fauquier County",
    "state": "VA",
    "city": "Broad Run"
  },
  {
    "value": "20141",
    "label": "Round Hill - VA 20141",
    "county": "Loudoun County",
    "state": "VA",
    "city": "Round Hill"
  },
  {
    "value": "20143",
    "label": "Catharpin - VA 20143",
    "county": "Prince William County",
    "state": "VA",
    "city": "Catharpin"
  },
  {
    "value": "20144",
    "label": "Delaplane - VA 20144",
    "county": "Fauquier County",
    "state": "VA",
    "city": "Delaplane"
  },
  {
    "value": "20147",
    "label": "Ashburn - VA 20147",
    "county": "Loudoun County",
    "state": "VA",
    "city": "Ashburn"
  },
  {
    "value": "20148",
    "label": "Ashburn - VA 20148",
    "county": "Loudoun County",
    "state": "VA",
    "city": "Ashburn"
  },
  {
    "value": "20151",
    "label": "Chantilly - VA 20151",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Chantilly"
  },
  {
    "value": "20152",
    "label": "Chantilly - VA 20152",
    "county": "Loudoun County",
    "state": "VA",
    "city": "Chantilly"
  },
  {
    "value": "20155",
    "label": "Gainesville - VA 20155",
    "county": "Prince William County",
    "state": "VA",
    "city": "Gainesville"
  },
  {
    "value": "20158",
    "label": "Hamilton - VA 20158",
    "county": "Loudoun County",
    "state": "VA",
    "city": "Hamilton"
  },
  {
    "value": "20164",
    "label": "Sterling - VA 20164",
    "county": "Loudoun County",
    "state": "VA",
    "city": "Sterling"
  },
  {
    "value": "20165",
    "label": "Sterling - VA 20165",
    "county": "Loudoun County",
    "state": "VA",
    "city": "Sterling"
  },
  {
    "value": "20166",
    "label": "Sterling - VA 20166",
    "county": "Loudoun County",
    "state": "VA",
    "city": "Sterling"
  },
  {
    "value": "20169",
    "label": "Haymarket - VA 20169",
    "county": "Prince William County",
    "state": "VA",
    "city": "Haymarket"
  },
  {
    "value": "20170",
    "label": "Herndon - VA 20170",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Herndon"
  },
  {
    "value": "20171",
    "label": "Herndon - VA 20171",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Herndon"
  },
  {
    "value": "20175",
    "label": "Leesburg - VA 20175",
    "county": "Loudoun County",
    "state": "VA",
    "city": "Leesburg"
  },
  {
    "value": "20176",
    "label": "Leesburg - VA 20176",
    "county": "Loudoun County",
    "state": "VA",
    "city": "Leesburg"
  },
  {
    "value": "20180",
    "label": "Lovettsville - VA 20180",
    "county": "Loudoun County",
    "state": "VA",
    "city": "Lovettsville"
  },
  {
    "value": "20181",
    "label": "Nokesville - VA 20181",
    "county": "Prince William County",
    "state": "VA",
    "city": "Nokesville"
  },
  {
    "value": "20184",
    "label": "Upperville - VA 20184",
    "county": "Fauquier County",
    "state": "VA",
    "city": "Upperville"
  },
  {
    "value": "20186",
    "label": "Warrenton - VA 20186",
    "county": "Fauquier County",
    "state": "VA",
    "city": "Warrenton"
  },
  {
    "value": "20187",
    "label": "Warrenton - VA 20187",
    "county": "Fauquier County",
    "state": "VA",
    "city": "Warrenton"
  },
  {
    "value": "20189",
    "label": "Dulles - VA 20189",
    "county": "Loudoun County",
    "state": "VA",
    "city": "Dulles"
  },
  {
    "value": "20190",
    "label": "Reston - VA 20190",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Reston"
  },
  {
    "value": "20191",
    "label": "Reston - VA 20191",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Reston"
  },
  {
    "value": "20194",
    "label": "Reston - VA 20194",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Reston"
  },
  {
    "value": "20197",
    "label": "Waterford - VA 20197",
    "county": "Loudoun County",
    "state": "VA",
    "city": "Waterford"
  },
  {
    "value": "20198",
    "label": "The Plains - VA 20198",
    "county": "Fauquier County",
    "state": "VA",
    "city": "The Plains"
  },
  {
    "value": "22003",
    "label": "Annandale - VA 22003",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Annandale"
  },
  {
    "value": "22015",
    "label": "Burke - VA 22015",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Burke"
  },
  {
    "value": "22025",
    "label": "Dumfries - VA 22025",
    "county": "Prince William County",
    "state": "VA",
    "city": "Dumfries"
  },
  {
    "value": "22026",
    "label": "Dumfries - VA 22026",
    "county": "Prince William County",
    "state": "VA",
    "city": "Dumfries"
  },
  {
    "value": "22027",
    "label": "Dunn Loring - VA 22027",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Dunn Loring"
  },
  {
    "value": "22030",
    "label": "Fairfax - VA 22030",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Fairfax"
  },
  {
    "value": "22031",
    "label": "Fairfax - VA 22031",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Fairfax"
  },
  {
    "value": "22032",
    "label": "Fairfax - VA 22032",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Fairfax"
  },
  {
    "value": "22033",
    "label": "Fairfax - VA 22033",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Fairfax"
  },
  {
    "value": "22034",
    "label": "Fairfax - VA 22034",
    "county": "Fairfax City",
    "state": "VA",
    "city": "Fairfax"
  },
  {
    "value": "22035",
    "label": "Fairfax - VA 22035",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Fairfax"
  },
  {
    "value": "22036",
    "label": "Fairfax - VA 22036",
    "county": "Fairfax City",
    "state": "VA",
    "city": "Fairfax"
  },
  {
    "value": "22039",
    "label": "Fairfax Station - VA 22039",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Fairfax Station"
  },
  {
    "value": "22041",
    "label": "Falls Church - VA 22041",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Falls Church"
  },
  {
    "value": "22042",
    "label": "Falls Church - VA 22042",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Falls Church"
  },
  {
    "value": "22043",
    "label": "Falls Church - VA 22043",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Falls Church"
  },
  {
    "value": "22044",
    "label": "Falls Church - VA 22044",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Falls Church"
  },
  {
    "value": "22046",
    "label": "Falls Church - VA 22046",
    "county": "Falls Church city",
    "state": "VA",
    "city": "Falls Church"
  },
  {
    "value": "22060",
    "label": "Fort Belvoir - VA 22060",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Fort Belvoir"
  },
  {
    "value": "22066",
    "label": "Great Falls - VA 22066",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Great Falls"
  },
  {
    "value": "22067",
    "label": "Greenway - VA 22067",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Greenway"
  },
  {
    "value": "22079",
    "label": "Lorton - VA 22079",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Lorton"
  },
  {
    "value": "22081",
    "label": "Merrifield - VA 22081",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Merrifield"
  },
  {
    "value": "22101",
    "label": "Mc Lean - VA 22101",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Mc Lean"
  },
  {
    "value": "22102",
    "label": "Mc Lean - VA 22102",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Mc Lean"
  },
  {
    "value": "22124",
    "label": "Oakton - VA 22124",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Oakton"
  },
  {
    "value": "22134",
    "label": "Quantico - VA 22134",
    "county": "Prince William County",
    "state": "VA",
    "city": "Quantico"
  },
  {
    "value": "22150",
    "label": "Springfield - VA 22150",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Springfield"
  },
  {
    "value": "22151",
    "label": "Springfield - VA 22151",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Springfield"
  },
  {
    "value": "22152",
    "label": "Springfield - VA 22152",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Springfield"
  },
  {
    "value": "22153",
    "label": "Springfield - VA 22153",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Springfield"
  },
  {
    "value": "22159",
    "label": "Springfield - VA 22159",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Springfield"
  },
  {
    "value": "22172",
    "label": "Triangle - VA 22172",
    "county": "Prince William County",
    "state": "VA",
    "city": "Triangle"
  },
  {
    "value": "22180",
    "label": "Vienna - VA 22180",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Vienna"
  },
  {
    "value": "22181",
    "label": "Vienna - VA 22181",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Vienna"
  },
  {
    "value": "22182",
    "label": "Vienna - VA 22182",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Vienna"
  },
  {
    "value": "22191",
    "label": "Woodbridge - VA 22191",
    "county": "Prince William County",
    "state": "VA",
    "city": "Woodbridge"
  },
  {
    "value": "22192",
    "label": "Woodbridge - VA 22192",
    "county": "Prince William County",
    "state": "VA",
    "city": "Woodbridge"
  },
  {
    "value": "22193",
    "label": "Woodbridge - VA 22193",
    "county": "Prince William County",
    "state": "VA",
    "city": "Woodbridge"
  },
  {
    "value": "22201",
    "label": "Arlington - VA 22201",
    "county": "Arlington County",
    "state": "VA",
    "city": "Arlington"
  },
  {
    "value": "22202",
    "label": "Arlington - VA 22202",
    "county": "Arlington County",
    "state": "VA",
    "city": "Arlington"
  },
  {
    "value": "22203",
    "label": "Arlington - VA 22203",
    "county": "Arlington County",
    "state": "VA",
    "city": "Arlington"
  },
  {
    "value": "22204",
    "label": "Arlington - VA 22204",
    "county": "Arlington County",
    "state": "VA",
    "city": "Arlington"
  },
  {
    "value": "22205",
    "label": "Arlington - VA 22205",
    "county": "Arlington County",
    "state": "VA",
    "city": "Arlington"
  },
  {
    "value": "22206",
    "label": "Arlington - VA 22206",
    "county": "Arlington County",
    "state": "VA",
    "city": "Arlington"
  },
  {
    "value": "22207",
    "label": "Arlington - VA 22207",
    "county": "Arlington County",
    "state": "VA",
    "city": "Arlington"
  },
  {
    "value": "22209",
    "label": "Arlington - VA 22209",
    "county": "Arlington County",
    "state": "VA",
    "city": "Arlington"
  },
  {
    "value": "22211",
    "label": "Fort Myer - VA 22211",
    "county": "Arlington County",
    "state": "VA",
    "city": "Fort Myer"
  },
  {
    "value": "22213",
    "label": "Arlington - VA 22213",
    "county": "Arlington County",
    "state": "VA",
    "city": "Arlington"
  },
  {
    "value": "22214",
    "label": "Arlington - VA 22214",
    "county": "Arlington County",
    "state": "VA",
    "city": "Arlington"
  },
  {
    "value": "22301",
    "label": "Alexandria - VA 22301",
    "county": "Alexandria city",
    "state": "VA",
    "city": "Alexandria"
  },
  {
    "value": "22302",
    "label": "Alexandria - VA 22302",
    "county": "Alexandria city",
    "state": "VA",
    "city": "Alexandria"
  },
  {
    "value": "22303",
    "label": "Alexandria - VA 22303",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Alexandria"
  },
  {
    "value": "22304",
    "label": "Alexandria - VA 22304",
    "county": "Alexandria city",
    "state": "VA",
    "city": "Alexandria"
  },
  {
    "value": "22305",
    "label": "Alexandria - VA 22305",
    "county": "Alexandria city",
    "state": "VA",
    "city": "Alexandria"
  },
  {
    "value": "22306",
    "label": "Alexandria - VA 22306",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Alexandria"
  },
  {
    "value": "22307",
    "label": "Alexandria - VA 22307",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Alexandria"
  },
  {
    "value": "22308",
    "label": "Alexandria - VA 22308",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Alexandria"
  },
  {
    "value": "22309",
    "label": "Alexandria - VA 22309",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Alexandria"
  },
  {
    "value": "22310",
    "label": "Alexandria - VA 22310",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Alexandria"
  },
  {
    "value": "22311",
    "label": "Alexandria - VA 22311",
    "county": "Alexandria city",
    "state": "VA",
    "city": "Alexandria"
  },
  {
    "value": "22312",
    "label": "Alexandria - VA 22312",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Alexandria"
  },
  {
    "value": "22314",
    "label": "Alexandria - VA 22314",
    "county": "Alexandria city",
    "state": "VA",
    "city": "Alexandria"
  },
  {
    "value": "22315",
    "label": "Alexandria - VA 22315",
    "county": "Fairfax County",
    "state": "VA",
    "city": "Alexandria"
  },
  {
    "value": "22331",
    "label": "Alexandria - VA 22331",
    "county": "Alexandria City",
    "state": "VA",
    "city": "Alexandria"
  },
  {
    "value": "22332",
    "label": "Alexandria - VA 22332",
    "county": "Alexandria City",
    "state": "VA",
    "city": "Alexandria"
  },
  {
    "value": "22401",
    "label": "Fredericksburg - VA 22401",
    "county": "Fredericksburg city",
    "state": "VA",
    "city": "Fredericksburg"
  },
  {
    "value": "22405",
    "label": "Fredericksburg - VA 22405",
    "county": "Stafford County",
    "state": "VA",
    "city": "Fredericksburg"
  },
  {
    "value": "22406",
    "label": "Fredericksburg - VA 22406",
    "county": "Stafford County",
    "state": "VA",
    "city": "Fredericksburg"
  },
  {
    "value": "22407",
    "label": "Fredericksburg - VA 22407",
    "county": "Spotsylvania County",
    "state": "VA",
    "city": "Fredericksburg"
  },
  {
    "value": "22408",
    "label": "Fredericksburg - VA 22408",
    "county": "Spotsylvania County",
    "state": "VA",
    "city": "Fredericksburg"
  },
  {
    "value": "22427",
    "label": "Bowling Green - VA 22427",
    "county": "Caroline County",
    "state": "VA",
    "city": "Bowling Green"
  },
  {
    "value": "22432",
    "label": "Burgess - VA 22432",
    "county": "Northumberland County",
    "state": "VA",
    "city": "Burgess"
  },
  {
    "value": "22433",
    "label": "Burr Hill - VA 22433",
    "county": "Orange County",
    "state": "VA",
    "city": "Burr Hill"
  },
  {
    "value": "22435",
    "label": "Callao - VA 22435",
    "county": "Northumberland County",
    "state": "VA",
    "city": "Callao"
  },
  {
    "value": "22436",
    "label": "Caret - VA 22436",
    "county": "Essex County",
    "state": "VA",
    "city": "Caret"
  },
  {
    "value": "22437",
    "label": "Center Cross - VA 22437",
    "county": "Essex County",
    "state": "VA",
    "city": "Center Cross"
  },
  {
    "value": "22438",
    "label": "Champlain - VA 22438",
    "county": "Essex County",
    "state": "VA",
    "city": "Champlain"
  },
  {
    "value": "22443",
    "label": "Colonial Beach - VA 22443",
    "county": "Westmoreland County",
    "state": "VA",
    "city": "Colonial Beach"
  },
  {
    "value": "22448",
    "label": "Dahlgren - VA 22448",
    "county": "King George County",
    "state": "VA",
    "city": "Dahlgren"
  },
  {
    "value": "22454",
    "label": "Dunnsville - VA 22454",
    "county": "Essex County",
    "state": "VA",
    "city": "Dunnsville"
  },
  {
    "value": "22460",
    "label": "Farnham - VA 22460",
    "county": "Richmond County",
    "state": "VA",
    "city": "Farnham"
  },
  {
    "value": "22469",
    "label": "Hague - VA 22469",
    "county": "Westmoreland County",
    "state": "VA",
    "city": "Hague"
  },
  {
    "value": "22473",
    "label": "Heathsville - VA 22473",
    "county": "Northumberland County",
    "state": "VA",
    "city": "Heathsville"
  },
  {
    "value": "22476",
    "label": "Hustle - VA 22476",
    "county": "Essex County",
    "state": "VA",
    "city": "Hustle"
  },
  {
    "value": "22480",
    "label": "Irvington - VA 22480",
    "county": "Lancaster County",
    "state": "VA",
    "city": "Irvington"
  },
  {
    "value": "22482",
    "label": "Kilmarnock - VA 22482",
    "county": "Lancaster County",
    "state": "VA",
    "city": "Kilmarnock"
  },
  {
    "value": "22485",
    "label": "King George - VA 22485",
    "county": "King George County",
    "state": "VA",
    "city": "King George"
  },
  {
    "value": "22488",
    "label": "Kinsale - VA 22488",
    "county": "Westmoreland County",
    "state": "VA",
    "city": "Kinsale"
  },
  {
    "value": "22501",
    "label": "Ladysmith - VA 22501",
    "county": "Caroline County",
    "state": "VA",
    "city": "Ladysmith"
  },
  {
    "value": "22503",
    "label": "Lancaster - VA 22503",
    "county": "Lancaster County",
    "state": "VA",
    "city": "Lancaster"
  },
  {
    "value": "22504",
    "label": "Laneview - VA 22504",
    "county": "Essex County",
    "state": "VA",
    "city": "Laneview"
  },
  {
    "value": "22508",
    "label": "Locust Grove - VA 22508",
    "county": "Orange County",
    "state": "VA",
    "city": "Locust Grove"
  },
  {
    "value": "22509",
    "label": "Loretto - VA 22509",
    "county": "Essex County",
    "state": "VA",
    "city": "Loretto"
  },
  {
    "value": "22511",
    "label": "Lottsburg - VA 22511",
    "county": "Northumberland County",
    "state": "VA",
    "city": "Lottsburg"
  },
  {
    "value": "22514",
    "label": "Milford - VA 22514",
    "county": "Caroline County",
    "state": "VA",
    "city": "Milford"
  },
  {
    "value": "22520",
    "label": "Montross - VA 22520",
    "county": "Westmoreland County",
    "state": "VA",
    "city": "Montross"
  },
  {
    "value": "22534",
    "label": "Partlow - VA 22534",
    "county": "Spotsylvania County",
    "state": "VA",
    "city": "Partlow"
  },
  {
    "value": "22535",
    "label": "Port Royal - VA 22535",
    "county": "Caroline County",
    "state": "VA",
    "city": "Port Royal"
  },
  {
    "value": "22538",
    "label": "Rappahannock Academy - VA 22538",
    "county": "Caroline County",
    "state": "VA",
    "city": "Rappahannock Academy"
  },
  {
    "value": "22539",
    "label": "Reedville - VA 22539",
    "county": "Northumberland County",
    "state": "VA",
    "city": "Reedville"
  },
  {
    "value": "22542",
    "label": "Rhoadesville - VA 22542",
    "county": "Orange County",
    "state": "VA",
    "city": "Rhoadesville"
  },
  {
    "value": "22546",
    "label": "Ruther Glen - VA 22546",
    "county": "Caroline County",
    "state": "VA",
    "city": "Ruther Glen"
  },
  {
    "value": "22551",
    "label": "Spotsylvania - VA 22551",
    "county": "Spotsylvania County",
    "state": "VA",
    "city": "Spotsylvania"
  },
  {
    "value": "22553",
    "label": "Spotsylvania - VA 22553",
    "county": "Spotsylvania County",
    "state": "VA",
    "city": "Spotsylvania"
  },
  {
    "value": "22554",
    "label": "Stafford - VA 22554",
    "county": "Stafford County",
    "state": "VA",
    "city": "Stafford"
  },
  {
    "value": "22556",
    "label": "Stafford - VA 22556",
    "county": "Stafford County",
    "state": "VA",
    "city": "Stafford"
  },
  {
    "value": "22560",
    "label": "Tappahannock - VA 22560",
    "county": "Essex County",
    "state": "VA",
    "city": "Tappahannock"
  },
  {
    "value": "22567",
    "label": "Unionville - VA 22567",
    "county": "Orange County",
    "state": "VA",
    "city": "Unionville"
  },
  {
    "value": "22572",
    "label": "Warsaw - VA 22572",
    "county": "Richmond County",
    "state": "VA",
    "city": "Warsaw"
  },
  {
    "value": "22576",
    "label": "Weems - VA 22576",
    "county": "Lancaster County",
    "state": "VA",
    "city": "Weems"
  },
  {
    "value": "22578",
    "label": "White Stone - VA 22578",
    "county": "Lancaster County",
    "state": "VA",
    "city": "White Stone"
  },
  {
    "value": "22580",
    "label": "Woodford - VA 22580",
    "county": "Caroline County",
    "state": "VA",
    "city": "Woodford"
  },
  {
    "value": "22601",
    "label": "Winchester - VA 22601",
    "county": "Winchester city",
    "state": "VA",
    "city": "Winchester"
  },
  {
    "value": "22602",
    "label": "Winchester - VA 22602",
    "county": "Frederick County",
    "state": "VA",
    "city": "Winchester"
  },
  {
    "value": "22603",
    "label": "Winchester - VA 22603",
    "county": "Frederick County",
    "state": "VA",
    "city": "Winchester"
  },
  {
    "value": "22610",
    "label": "Bentonville - VA 22610",
    "county": "Warren County",
    "state": "VA",
    "city": "Bentonville"
  },
  {
    "value": "22611",
    "label": "Berryville - VA 22611",
    "county": "Clarke County",
    "state": "VA",
    "city": "Berryville"
  },
  {
    "value": "22620",
    "label": "Boyce - VA 22620",
    "county": "Clarke County",
    "state": "VA",
    "city": "Boyce"
  },
  {
    "value": "22623",
    "label": "Chester Gap - VA 22623",
    "county": "Rappahannock County",
    "state": "VA",
    "city": "Chester Gap"
  },
  {
    "value": "22624",
    "label": "Clear Brook - VA 22624",
    "county": "Frederick County",
    "state": "VA",
    "city": "Clear Brook"
  },
  {
    "value": "22625",
    "label": "Cross Junction - VA 22625",
    "county": "Frederick County",
    "state": "VA",
    "city": "Cross Junction"
  },
  {
    "value": "22627",
    "label": "Flint Hill - VA 22627",
    "county": "Rappahannock County",
    "state": "VA",
    "city": "Flint Hill"
  },
  {
    "value": "22630",
    "label": "Front Royal - VA 22630",
    "county": "Warren County",
    "state": "VA",
    "city": "Front Royal"
  },
  {
    "value": "22637",
    "label": "Gore - VA 22637",
    "county": "Frederick County",
    "state": "VA",
    "city": "Gore"
  },
  {
    "value": "22639",
    "label": "Hume - VA 22639",
    "county": "Fauquier County",
    "state": "VA",
    "city": "Hume"
  },
  {
    "value": "22640",
    "label": "Huntly - VA 22640",
    "county": "Rappahannock County",
    "state": "VA",
    "city": "Huntly"
  },
  {
    "value": "22641",
    "label": "Strasburg - VA 22641",
    "county": "Shenandoah County",
    "state": "VA",
    "city": "Strasburg"
  },
  {
    "value": "22642",
    "label": "Linden - VA 22642",
    "county": "Warren County",
    "state": "VA",
    "city": "Linden"
  },
  {
    "value": "22643",
    "label": "Markham - VA 22643",
    "county": "Fauquier County",
    "state": "VA",
    "city": "Markham"
  },
  {
    "value": "22644",
    "label": "Maurertown - VA 22644",
    "county": "Shenandoah County",
    "state": "VA",
    "city": "Maurertown"
  },
  {
    "value": "22645",
    "label": "Middletown - VA 22645",
    "county": "Frederick County",
    "state": "VA",
    "city": "Middletown"
  },
  {
    "value": "22649",
    "label": "Middletown - VA 22649",
    "county": "Frederick County",
    "state": "VA",
    "city": "Middletown"
  },
  {
    "value": "22650",
    "label": "Rileyville - VA 22650",
    "county": "Page County",
    "state": "VA",
    "city": "Rileyville"
  },
  {
    "value": "22652",
    "label": "Fort Valley - VA 22652",
    "county": "Shenandoah County",
    "state": "VA",
    "city": "Fort Valley"
  },
  {
    "value": "22654",
    "label": "Star Tannery - VA 22654",
    "county": "Frederick County",
    "state": "VA",
    "city": "Star Tannery"
  },
  {
    "value": "22655",
    "label": "Stephens City - VA 22655",
    "county": "Frederick County",
    "state": "VA",
    "city": "Stephens City"
  },
  {
    "value": "22656",
    "label": "Stephenson - VA 22656",
    "county": "Frederick County",
    "state": "VA",
    "city": "Stephenson"
  },
  {
    "value": "22657",
    "label": "Strasburg - VA 22657",
    "county": "Shenandoah County",
    "state": "VA",
    "city": "Strasburg"
  },
  {
    "value": "22660",
    "label": "Toms Brook - VA 22660",
    "county": "Shenandoah County",
    "state": "VA",
    "city": "Toms Brook"
  },
  {
    "value": "22663",
    "label": "White Post - VA 22663",
    "county": "Frederick County",
    "state": "VA",
    "city": "White Post"
  },
  {
    "value": "22664",
    "label": "Woodstock - VA 22664",
    "county": "Shenandoah County",
    "state": "VA",
    "city": "Woodstock"
  },
  {
    "value": "22701",
    "label": "Culpeper - VA 22701",
    "county": "Culpeper County",
    "state": "VA",
    "city": "Culpeper"
  },
  {
    "value": "22709",
    "label": "Aroda - VA 22709",
    "county": "Madison County",
    "state": "VA",
    "city": "Aroda"
  },
  {
    "value": "22711",
    "label": "Banco - VA 22711",
    "county": "Madison County",
    "state": "VA",
    "city": "Banco"
  },
  {
    "value": "22712",
    "label": "Bealeton - VA 22712",
    "county": "Fauquier County",
    "state": "VA",
    "city": "Bealeton"
  },
  {
    "value": "22713",
    "label": "Boston - VA 22713",
    "county": "Culpeper County",
    "state": "VA",
    "city": "Boston"
  },
  {
    "value": "22714",
    "label": "Brandy Station - VA 22714",
    "county": "Culpeper County",
    "state": "VA",
    "city": "Brandy Station"
  },
  {
    "value": "22715",
    "label": "Brightwood - VA 22715",
    "county": "Madison County",
    "state": "VA",
    "city": "Brightwood"
  },
  {
    "value": "22716",
    "label": "Castleton - VA 22716",
    "county": "Rappahannock County",
    "state": "VA",
    "city": "Castleton"
  },
  {
    "value": "22718",
    "label": "Elkwood - VA 22718",
    "county": "Culpeper County",
    "state": "VA",
    "city": "Elkwood"
  },
  {
    "value": "22719",
    "label": "Etlan - VA 22719",
    "county": "Madison County",
    "state": "VA",
    "city": "Etlan"
  },
  {
    "value": "22720",
    "label": "Goldvein - VA 22720",
    "county": "Fauquier County",
    "state": "VA",
    "city": "Goldvein"
  },
  {
    "value": "22721",
    "label": "Graves Mill - VA 22721",
    "county": "Madison County",
    "state": "VA",
    "city": "Graves Mill"
  },
  {
    "value": "22722",
    "label": "Haywood - VA 22722",
    "county": "Madison County",
    "state": "VA",
    "city": "Haywood"
  },
  {
    "value": "22723",
    "label": "Hood - VA 22723",
    "county": "Madison County",
    "state": "VA",
    "city": "Hood"
  },
  {
    "value": "22724",
    "label": "Jeffersonton - VA 22724",
    "county": "Culpeper County",
    "state": "VA",
    "city": "Jeffersonton"
  },
  {
    "value": "22725",
    "label": "Leon - VA 22725",
    "county": "Madison County",
    "state": "VA",
    "city": "Leon"
  },
  {
    "value": "22726",
    "label": "Lignum - VA 22726",
    "county": "Culpeper County",
    "state": "VA",
    "city": "Lignum"
  },
  {
    "value": "22727",
    "label": "Madison - VA 22727",
    "county": "Madison County",
    "state": "VA",
    "city": "Madison"
  },
  {
    "value": "22728",
    "label": "Midland - VA 22728",
    "county": "Fauquier County",
    "state": "VA",
    "city": "Midland"
  },
  {
    "value": "22729",
    "label": "Mitchells - VA 22729",
    "county": "Culpeper County",
    "state": "VA",
    "city": "Mitchells"
  },
  {
    "value": "22730",
    "label": "Oakpark - VA 22730",
    "county": "Madison County",
    "state": "VA",
    "city": "Oakpark"
  },
  {
    "value": "22731",
    "label": "Pratts - VA 22731",
    "county": "Madison County",
    "state": "VA",
    "city": "Pratts"
  },
  {
    "value": "22732",
    "label": "Radiant - VA 22732",
    "county": "Madison County",
    "state": "VA",
    "city": "Radiant"
  },
  {
    "value": "22733",
    "label": "Rapidan - VA 22733",
    "county": "Culpeper County",
    "state": "VA",
    "city": "Rapidan"
  },
  {
    "value": "22734",
    "label": "Remington - VA 22734",
    "county": "Fauquier County",
    "state": "VA",
    "city": "Remington"
  },
  {
    "value": "22735",
    "label": "Reva - VA 22735",
    "county": "Culpeper County",
    "state": "VA",
    "city": "Reva"
  },
  {
    "value": "22736",
    "label": "Richardsville - VA 22736",
    "county": "Culpeper County",
    "state": "VA",
    "city": "Richardsville"
  },
  {
    "value": "22737",
    "label": "Rixeyville - VA 22737",
    "county": "Culpeper County",
    "state": "VA",
    "city": "Rixeyville"
  },
  {
    "value": "22738",
    "label": "Rochelle - VA 22738",
    "county": "Madison County",
    "state": "VA",
    "city": "Rochelle"
  },
  {
    "value": "22740",
    "label": "Sperryville - VA 22740",
    "county": "Rappahannock County",
    "state": "VA",
    "city": "Sperryville"
  },
  {
    "value": "22741",
    "label": "Stevensburg - VA 22741",
    "county": "Culpeper County",
    "state": "VA",
    "city": "Stevensburg"
  },
  {
    "value": "22742",
    "label": "Sumerduck - VA 22742",
    "county": "Fauquier County",
    "state": "VA",
    "city": "Sumerduck"
  },
  {
    "value": "22743",
    "label": "Syria - VA 22743",
    "county": "Madison County",
    "state": "VA",
    "city": "Syria"
  },
  {
    "value": "22746",
    "label": "Viewtown - VA 22746",
    "county": "Rappahannock County",
    "state": "VA",
    "city": "Viewtown"
  },
  {
    "value": "22747",
    "label": "Washington - VA 22747",
    "county": "Rappahannock County",
    "state": "VA",
    "city": "Washington"
  },
  {
    "value": "22749",
    "label": "Woodville - VA 22749",
    "county": "Rappahannock County",
    "state": "VA",
    "city": "Woodville"
  },
  {
    "value": "22801",
    "label": "Harrisonburg - VA 22801",
    "county": "Harrisonburg city",
    "state": "VA",
    "city": "Harrisonburg"
  },
  {
    "value": "22802",
    "label": "Harrisonburg - VA 22802",
    "county": "Harrisonburg city",
    "state": "VA",
    "city": "Harrisonburg"
  },
  {
    "value": "22810",
    "label": "Basye - VA 22810",
    "county": "Shenandoah County",
    "state": "VA",
    "city": "Basye"
  },
  {
    "value": "22811",
    "label": "Bergton - VA 22811",
    "county": "Rockingham County",
    "state": "VA",
    "city": "Bergton"
  },
  {
    "value": "22812",
    "label": "Bridgewater - VA 22812",
    "county": "Rockingham County",
    "state": "VA",
    "city": "Bridgewater"
  },
  {
    "value": "22815",
    "label": "Broadway - VA 22815",
    "county": "Rockingham County",
    "state": "VA",
    "city": "Broadway"
  },
  {
    "value": "22820",
    "label": "Criders - VA 22820",
    "county": "Rockingham County",
    "state": "VA",
    "city": "Criders"
  },
  {
    "value": "22821",
    "label": "Dayton - VA 22821",
    "county": "Rockingham County",
    "state": "VA",
    "city": "Dayton"
  },
  {
    "value": "22824",
    "label": "Edinburg - VA 22824",
    "county": "Shenandoah County",
    "state": "VA",
    "city": "Edinburg"
  },
  {
    "value": "22827",
    "label": "Elkton - VA 22827",
    "county": "Rockingham County",
    "state": "VA",
    "city": "Elkton"
  },
  {
    "value": "22830",
    "label": "Fulks Run - VA 22830",
    "county": "Rockingham County",
    "state": "VA",
    "city": "Fulks Run"
  },
  {
    "value": "22831",
    "label": "Hinton - VA 22831",
    "county": "Rockingham County",
    "state": "VA",
    "city": "Hinton"
  },
  {
    "value": "22832",
    "label": "Keezletown - VA 22832",
    "county": "Rockingham County",
    "state": "VA",
    "city": "Keezletown"
  },
  {
    "value": "22834",
    "label": "Linville - VA 22834",
    "county": "Rockingham County",
    "state": "VA",
    "city": "Linville"
  },
  {
    "value": "22835",
    "label": "Luray - VA 22835",
    "county": "Page County",
    "state": "VA",
    "city": "Luray"
  },
  {
    "value": "22840",
    "label": "Mc Gaheysville - VA 22840",
    "county": "Rockingham County",
    "state": "VA",
    "city": "Mc Gaheysville"
  },
  {
    "value": "22841",
    "label": "Mount Crawford - VA 22841",
    "county": "Rockingham County",
    "state": "VA",
    "city": "Mount Crawford"
  },
  {
    "value": "22842",
    "label": "Mount Jackson - VA 22842",
    "county": "Shenandoah County",
    "state": "VA",
    "city": "Mount Jackson"
  },
  {
    "value": "22843",
    "label": "Mount Solon - VA 22843",
    "county": "Augusta County",
    "state": "VA",
    "city": "Mount Solon"
  },
  {
    "value": "22844",
    "label": "New Market - VA 22844",
    "county": "Shenandoah County",
    "state": "VA",
    "city": "New Market"
  },
  {
    "value": "22845",
    "label": "Orkney Springs - VA 22845",
    "county": "Shenandoah County",
    "state": "VA",
    "city": "Orkney Springs"
  },
  {
    "value": "22846",
    "label": "Penn Laird - VA 22846",
    "county": "Rockingham County",
    "state": "VA",
    "city": "Penn Laird"
  },
  {
    "value": "22847",
    "label": "Quicksburg - VA 22847",
    "county": "Shenandoah County",
    "state": "VA",
    "city": "Quicksburg"
  },
  {
    "value": "22849",
    "label": "Shenandoah - VA 22849",
    "county": "Page County",
    "state": "VA",
    "city": "Shenandoah"
  },
  {
    "value": "22850",
    "label": "Singers Glen - VA 22850",
    "county": "Rockingham County",
    "state": "VA",
    "city": "Singers Glen"
  },
  {
    "value": "22851",
    "label": "Stanley - VA 22851",
    "county": "Page County",
    "state": "VA",
    "city": "Stanley"
  },
  {
    "value": "22853",
    "label": "Timberville - VA 22853",
    "county": "Rockingham County",
    "state": "VA",
    "city": "Timberville"
  },
  {
    "value": "22901",
    "label": "Charlottesville - VA 22901",
    "county": "Albemarle County",
    "state": "VA",
    "city": "Charlottesville"
  },
  {
    "value": "22902",
    "label": "Charlottesville - VA 22902",
    "county": "Charlottesville city",
    "state": "VA",
    "city": "Charlottesville"
  },
  {
    "value": "22903",
    "label": "Charlottesville - VA 22903",
    "county": "Charlottesville city",
    "state": "VA",
    "city": "Charlottesville"
  },
  {
    "value": "22904",
    "label": "Charlottesville - VA 22904",
    "county": "Albemarle County",
    "state": "VA",
    "city": "Charlottesville"
  },
  {
    "value": "22911",
    "label": "Charlottesville - VA 22911",
    "county": "Albemarle County",
    "state": "VA",
    "city": "Charlottesville"
  },
  {
    "value": "22920",
    "label": "Afton - VA 22920",
    "county": "Nelson County",
    "state": "VA",
    "city": "Afton"
  },
  {
    "value": "22922",
    "label": "Arrington - VA 22922",
    "county": "Nelson County",
    "state": "VA",
    "city": "Arrington"
  },
  {
    "value": "22923",
    "label": "Barboursville - VA 22923",
    "county": "Greene County",
    "state": "VA",
    "city": "Barboursville"
  },
  {
    "value": "22931",
    "label": "Covesville - VA 22931",
    "county": "Albemarle County",
    "state": "VA",
    "city": "Covesville"
  },
  {
    "value": "22932",
    "label": "Crozet - VA 22932",
    "county": "Albemarle County",
    "state": "VA",
    "city": "Crozet"
  },
  {
    "value": "22935",
    "label": "Dyke - VA 22935",
    "county": "Greene County",
    "state": "VA",
    "city": "Dyke"
  },
  {
    "value": "22936",
    "label": "Earlysville - VA 22936",
    "county": "Albemarle County",
    "state": "VA",
    "city": "Earlysville"
  },
  {
    "value": "22937",
    "label": "Esmont - VA 22937",
    "county": "Albemarle County",
    "state": "VA",
    "city": "Esmont"
  },
  {
    "value": "22938",
    "label": "Faber - VA 22938",
    "county": "Nelson County",
    "state": "VA",
    "city": "Faber"
  },
  {
    "value": "22939",
    "label": "Fishersville - VA 22939",
    "county": "Augusta County",
    "state": "VA",
    "city": "Fishersville"
  },
  {
    "value": "22940",
    "label": "Free Union - VA 22940",
    "county": "Albemarle County",
    "state": "VA",
    "city": "Free Union"
  },
  {
    "value": "22942",
    "label": "Gordonsville - VA 22942",
    "county": "Louisa County",
    "state": "VA",
    "city": "Gordonsville"
  },
  {
    "value": "22943",
    "label": "Greenwood - VA 22943",
    "county": "Albemarle County",
    "state": "VA",
    "city": "Greenwood"
  },
  {
    "value": "22946",
    "label": "Keene - VA 22946",
    "county": "Albemarle County",
    "state": "VA",
    "city": "Keene"
  },
  {
    "value": "22947",
    "label": "Keswick - VA 22947",
    "county": "Albemarle County",
    "state": "VA",
    "city": "Keswick"
  },
  {
    "value": "22948",
    "label": "Locust Dale - VA 22948",
    "county": "Madison County",
    "state": "VA",
    "city": "Locust Dale"
  },
  {
    "value": "22949",
    "label": "Lovingston - VA 22949",
    "county": "Nelson County",
    "state": "VA",
    "city": "Lovingston"
  },
  {
    "value": "22952",
    "label": "Lyndhurst - VA 22952",
    "county": "Augusta County",
    "state": "VA",
    "city": "Lyndhurst"
  },
  {
    "value": "22958",
    "label": "Nellysford - VA 22958",
    "county": "Nelson County",
    "state": "VA",
    "city": "Nellysford"
  },
  {
    "value": "22959",
    "label": "North Garden - VA 22959",
    "county": "Albemarle County",
    "state": "VA",
    "city": "North Garden"
  },
  {
    "value": "22960",
    "label": "Orange - VA 22960",
    "county": "Orange County",
    "state": "VA",
    "city": "Orange"
  },
  {
    "value": "22963",
    "label": "Palmyra - VA 22963",
    "county": "Fluvanna County",
    "state": "VA",
    "city": "Palmyra"
  },
  {
    "value": "22964",
    "label": "Piney River - VA 22964",
    "county": "Nelson County",
    "state": "VA",
    "city": "Piney River"
  },
  {
    "value": "22967",
    "label": "Roseland - VA 22967",
    "county": "Nelson County",
    "state": "VA",
    "city": "Roseland"
  },
  {
    "value": "22968",
    "label": "Ruckersville - VA 22968",
    "county": "Greene County",
    "state": "VA",
    "city": "Ruckersville"
  },
  {
    "value": "22969",
    "label": "Schuyler - VA 22969",
    "county": "Nelson County",
    "state": "VA",
    "city": "Schuyler"
  },
  {
    "value": "22971",
    "label": "Shipman - VA 22971",
    "county": "Nelson County",
    "state": "VA",
    "city": "Shipman"
  },
  {
    "value": "22972",
    "label": "Somerset - VA 22972",
    "county": "Orange County",
    "state": "VA",
    "city": "Somerset"
  },
  {
    "value": "22973",
    "label": "Stanardsville - VA 22973",
    "county": "Greene County",
    "state": "VA",
    "city": "Stanardsville"
  },
  {
    "value": "22974",
    "label": "Troy - VA 22974",
    "county": "Fluvanna County",
    "state": "VA",
    "city": "Troy"
  },
  {
    "value": "22976",
    "label": "Tyro - VA 22976",
    "county": "Nelson County",
    "state": "VA",
    "city": "Tyro"
  },
  {
    "value": "22980",
    "label": "Waynesboro - VA 22980",
    "county": "Waynesboro city",
    "state": "VA",
    "city": "Waynesboro"
  },
  {
    "value": "23002",
    "label": "Amelia Court House - VA 23002",
    "county": "Amelia County",
    "state": "VA",
    "city": "Amelia Court House"
  },
  {
    "value": "23004",
    "label": "Arvonia - VA 23004",
    "county": "Buckingham County",
    "state": "VA",
    "city": "Arvonia"
  },
  {
    "value": "23005",
    "label": "Ashland - VA 23005",
    "county": "Hanover County",
    "state": "VA",
    "city": "Ashland"
  },
  {
    "value": "23009",
    "label": "Aylett - VA 23009",
    "county": "King William County",
    "state": "VA",
    "city": "Aylett"
  },
  {
    "value": "23011",
    "label": "Barhamsville - VA 23011",
    "county": "New Kent County",
    "state": "VA",
    "city": "Barhamsville"
  },
  {
    "value": "23014",
    "label": "Beaumont - VA 23014",
    "county": "Powhatan County",
    "state": "VA",
    "city": "Beaumont"
  },
  {
    "value": "23015",
    "label": "Beaverdam - VA 23015",
    "county": "Hanover County",
    "state": "VA",
    "city": "Beaverdam"
  },
  {
    "value": "23021",
    "label": "Bohannon - VA 23021",
    "county": "Mathews County",
    "state": "VA",
    "city": "Bohannon"
  },
  {
    "value": "23022",
    "label": "Bremo Bluff - VA 23022",
    "county": "Fluvanna County",
    "state": "VA",
    "city": "Bremo Bluff"
  },
  {
    "value": "23023",
    "label": "Bruington - VA 23023",
    "county": "King and Queen County",
    "state": "VA",
    "city": "Bruington"
  },
  {
    "value": "23024",
    "label": "Bumpass - VA 23024",
    "county": "Louisa County",
    "state": "VA",
    "city": "Bumpass"
  },
  {
    "value": "23025",
    "label": "Cardinal - VA 23025",
    "county": "Mathews County",
    "state": "VA",
    "city": "Cardinal"
  },
  {
    "value": "23027",
    "label": "Cartersville - VA 23027",
    "county": "Cumberland County",
    "state": "VA",
    "city": "Cartersville"
  },
  {
    "value": "23030",
    "label": "Charles City - VA 23030",
    "county": "Charles City County",
    "state": "VA",
    "city": "Charles City"
  },
  {
    "value": "23032",
    "label": "Church View - VA 23032",
    "county": "Middlesex County",
    "state": "VA",
    "city": "Church View"
  },
  {
    "value": "23035",
    "label": "Cobbs Creek - VA 23035",
    "county": "Mathews County",
    "state": "VA",
    "city": "Cobbs Creek"
  },
  {
    "value": "23038",
    "label": "Columbia - VA 23038",
    "county": "Goochland County",
    "state": "VA",
    "city": "Columbia"
  },
  {
    "value": "23039",
    "label": "Crozier - VA 23039",
    "county": "Goochland County",
    "state": "VA",
    "city": "Crozier"
  },
  {
    "value": "23040",
    "label": "Cumberland - VA 23040",
    "county": "Cumberland County",
    "state": "VA",
    "city": "Cumberland"
  },
  {
    "value": "23043",
    "label": "Deltaville - VA 23043",
    "county": "Middlesex County",
    "state": "VA",
    "city": "Deltaville"
  },
  {
    "value": "23045",
    "label": "Diggs - VA 23045",
    "county": "Mathews County",
    "state": "VA",
    "city": "Diggs"
  },
  {
    "value": "23047",
    "label": "Doswell - VA 23047",
    "county": "Hanover County",
    "state": "VA",
    "city": "Doswell"
  },
  {
    "value": "23050",
    "label": "Dutton - VA 23050",
    "county": "Mathews County",
    "state": "VA",
    "city": "Dutton"
  },
  {
    "value": "23055",
    "label": "Fork Union - VA 23055",
    "county": "Fluvanna County",
    "state": "VA",
    "city": "Fork Union"
  },
  {
    "value": "23056",
    "label": "Foster - VA 23056",
    "county": "Mathews County",
    "state": "VA",
    "city": "Foster"
  },
  {
    "value": "23059",
    "label": "Glen Allen - VA 23059",
    "county": "Henrico County",
    "state": "VA",
    "city": "Glen Allen"
  },
  {
    "value": "23060",
    "label": "Glen Allen - VA 23060",
    "county": "Henrico County",
    "state": "VA",
    "city": "Glen Allen"
  },
  {
    "value": "23061",
    "label": "Gloucester - VA 23061",
    "county": "Gloucester County",
    "state": "VA",
    "city": "Gloucester"
  },
  {
    "value": "23062",
    "label": "Gloucester Point - VA 23062",
    "county": "Gloucester County",
    "state": "VA",
    "city": "Gloucester Point"
  },
  {
    "value": "23063",
    "label": "Goochland - VA 23063",
    "county": "Goochland County",
    "state": "VA",
    "city": "Goochland"
  },
  {
    "value": "23065",
    "label": "Gum Spring - VA 23065",
    "county": "Goochland County",
    "state": "VA",
    "city": "Gum Spring"
  },
  {
    "value": "23068",
    "label": "Hallieford - VA 23068",
    "county": "Mathews County",
    "state": "VA",
    "city": "Hallieford"
  },
  {
    "value": "23069",
    "label": "Hanover - VA 23069",
    "county": "Hanover County",
    "state": "VA",
    "city": "Hanover"
  },
  {
    "value": "23070",
    "label": "Hardyville - VA 23070",
    "county": "Middlesex County",
    "state": "VA",
    "city": "Hardyville"
  },
  {
    "value": "23071",
    "label": "Hartfield - VA 23071",
    "county": "Middlesex County",
    "state": "VA",
    "city": "Hartfield"
  },
  {
    "value": "23072",
    "label": "Hayes - VA 23072",
    "county": "Gloucester County",
    "state": "VA",
    "city": "Hayes"
  },
  {
    "value": "23075",
    "label": "Henrico - VA 23075",
    "county": "Henrico County",
    "state": "VA",
    "city": "Henrico"
  },
  {
    "value": "23076",
    "label": "Hudgins - VA 23076",
    "county": "Mathews County",
    "state": "VA",
    "city": "Hudgins"
  },
  {
    "value": "23079",
    "label": "Jamaica - VA 23079",
    "county": "Middlesex County",
    "state": "VA",
    "city": "Jamaica"
  },
  {
    "value": "23083",
    "label": "Jetersville - VA 23083",
    "county": "Amelia County",
    "state": "VA",
    "city": "Jetersville"
  },
  {
    "value": "23084",
    "label": "Kents Store - VA 23084",
    "county": "Fluvanna County",
    "state": "VA",
    "city": "Kents Store"
  },
  {
    "value": "23085",
    "label": "King And Queen Court House - VA 23085",
    "county": "King and Queen County",
    "state": "VA",
    "city": "King And Queen Court House"
  },
  {
    "value": "23086",
    "label": "King William - VA 23086",
    "county": "King William County",
    "state": "VA",
    "city": "King William"
  },
  {
    "value": "23089",
    "label": "Lanexa - VA 23089",
    "county": "New Kent County",
    "state": "VA",
    "city": "Lanexa"
  },
  {
    "value": "23091",
    "label": "Little Plymouth - VA 23091",
    "county": "King and Queen County",
    "state": "VA",
    "city": "Little Plymouth"
  },
  {
    "value": "23092",
    "label": "Locust Hill - VA 23092",
    "county": "Middlesex County",
    "state": "VA",
    "city": "Locust Hill"
  },
  {
    "value": "23093",
    "label": "Louisa - VA 23093",
    "county": "Louisa County",
    "state": "VA",
    "city": "Louisa"
  },
  {
    "value": "23102",
    "label": "Maidens - VA 23102",
    "county": "Goochland County",
    "state": "VA",
    "city": "Maidens"
  },
  {
    "value": "23103",
    "label": "Manakin Sabot - VA 23103",
    "county": "Goochland County",
    "state": "VA",
    "city": "Manakin Sabot"
  },
  {
    "value": "23106",
    "label": "Manquin - VA 23106",
    "county": "King William County",
    "state": "VA",
    "city": "Manquin"
  },
  {
    "value": "23108",
    "label": "Mascot - VA 23108",
    "county": "King and Queen County",
    "state": "VA",
    "city": "Mascot"
  },
  {
    "value": "23109",
    "label": "Mathews - VA 23109",
    "county": "Mathews County",
    "state": "VA",
    "city": "Mathews"
  },
  {
    "value": "23110",
    "label": "Mattaponi - VA 23110",
    "county": "King and Queen County",
    "state": "VA",
    "city": "Mattaponi"
  },
  {
    "value": "23111",
    "label": "Mechanicsville - VA 23111",
    "county": "Hanover County",
    "state": "VA",
    "city": "Mechanicsville"
  },
  {
    "value": "23112",
    "label": "Midlothian - VA 23112",
    "county": "Chesterfield County",
    "state": "VA",
    "city": "Midlothian"
  },
  {
    "value": "23113",
    "label": "Midlothian - VA 23113",
    "county": "Chesterfield County",
    "state": "VA",
    "city": "Midlothian"
  },
  {
    "value": "23114",
    "label": "Midlothian - VA 23114",
    "county": "Chesterfield County",
    "state": "VA",
    "city": "Midlothian"
  },
  {
    "value": "23116",
    "label": "Mechanicsville - VA 23116",
    "county": "Hanover County",
    "state": "VA",
    "city": "Mechanicsville"
  },
  {
    "value": "23117",
    "label": "Mineral - VA 23117",
    "county": "Louisa County",
    "state": "VA",
    "city": "Mineral"
  },
  {
    "value": "23119",
    "label": "Moon - VA 23119",
    "county": "Mathews County",
    "state": "VA",
    "city": "Moon"
  },
  {
    "value": "23120",
    "label": "Moseley - VA 23120",
    "county": "Chesterfield County",
    "state": "VA",
    "city": "Moseley"
  },
  {
    "value": "23123",
    "label": "New Canton - VA 23123",
    "county": "Buckingham County",
    "state": "VA",
    "city": "New Canton"
  },
  {
    "value": "23124",
    "label": "New Kent - VA 23124",
    "county": "New Kent County",
    "state": "VA",
    "city": "New Kent"
  },
  {
    "value": "23125",
    "label": "New Point - VA 23125",
    "county": "Mathews County",
    "state": "VA",
    "city": "New Point"
  },
  {
    "value": "23126",
    "label": "Newtown - VA 23126",
    "county": "King and Queen County",
    "state": "VA",
    "city": "Newtown"
  },
  {
    "value": "23128",
    "label": "North - VA 23128",
    "county": "Mathews County",
    "state": "VA",
    "city": "North"
  },
  {
    "value": "23129",
    "label": "Oilville - VA 23129",
    "county": "Goochland County",
    "state": "VA",
    "city": "Oilville"
  },
  {
    "value": "23130",
    "label": "Onemo - VA 23130",
    "county": "Mathews County",
    "state": "VA",
    "city": "Onemo"
  },
  {
    "value": "23138",
    "label": "Port Haywood - VA 23138",
    "county": "Mathews County",
    "state": "VA",
    "city": "Port Haywood"
  },
  {
    "value": "23139",
    "label": "Powhatan - VA 23139",
    "county": "Powhatan County",
    "state": "VA",
    "city": "Powhatan"
  },
  {
    "value": "23140",
    "label": "Providence Forge - VA 23140",
    "county": "New Kent County",
    "state": "VA",
    "city": "Providence Forge"
  },
  {
    "value": "23141",
    "label": "Quinton - VA 23141",
    "county": "New Kent County",
    "state": "VA",
    "city": "Quinton"
  },
  {
    "value": "23146",
    "label": "Rockville - VA 23146",
    "county": "Hanover County",
    "state": "VA",
    "city": "Rockville"
  },
  {
    "value": "23148",
    "label": "Saint Stephens Church - VA 23148",
    "county": "King and Queen County",
    "state": "VA",
    "city": "Saint Stephens Church"
  },
  {
    "value": "23149",
    "label": "Saluda - VA 23149",
    "county": "Middlesex County",
    "state": "VA",
    "city": "Saluda"
  },
  {
    "value": "23150",
    "label": "Sandston - VA 23150",
    "county": "Henrico County",
    "state": "VA",
    "city": "Sandston"
  },
  {
    "value": "23153",
    "label": "Sandy Hook - VA 23153",
    "county": "Goochland County",
    "state": "VA",
    "city": "Sandy Hook"
  },
  {
    "value": "23156",
    "label": "Shacklefords - VA 23156",
    "county": "King and Queen County",
    "state": "VA",
    "city": "Shacklefords"
  },
  {
    "value": "23160",
    "label": "State Farm - VA 23160",
    "county": "Powhatan County",
    "state": "VA",
    "city": "State Farm"
  },
  {
    "value": "23161",
    "label": "Stevensville - VA 23161",
    "county": "King and Queen County",
    "state": "VA",
    "city": "Stevensville"
  },
  {
    "value": "23163",
    "label": "Susan - VA 23163",
    "county": "Mathews County",
    "state": "VA",
    "city": "Susan"
  },
  {
    "value": "23168",
    "label": "Toano - VA 23168",
    "county": "James City County",
    "state": "VA",
    "city": "Toano"
  },
  {
    "value": "23169",
    "label": "Topping - VA 23169",
    "county": "Middlesex County",
    "state": "VA",
    "city": "Topping"
  },
  {
    "value": "23175",
    "label": "Urbanna - VA 23175",
    "county": "Middlesex County",
    "state": "VA",
    "city": "Urbanna"
  },
  {
    "value": "23176",
    "label": "Wake - VA 23176",
    "county": "Middlesex County",
    "state": "VA",
    "city": "Wake"
  },
  {
    "value": "23177",
    "label": "Walkerton - VA 23177",
    "county": "King and Queen County",
    "state": "VA",
    "city": "Walkerton"
  },
  {
    "value": "23180",
    "label": "Water View - VA 23180",
    "county": "Middlesex County",
    "state": "VA",
    "city": "Water View"
  },
  {
    "value": "23181",
    "label": "West Point - VA 23181",
    "county": "King William County",
    "state": "VA",
    "city": "West Point"
  },
  {
    "value": "23185",
    "label": "Williamsburg - VA 23185",
    "county": "James City County",
    "state": "VA",
    "city": "Williamsburg"
  },
  {
    "value": "23188",
    "label": "Williamsburg - VA 23188",
    "county": "James City County",
    "state": "VA",
    "city": "Williamsburg"
  },
  {
    "value": "23192",
    "label": "Montpelier - VA 23192",
    "county": "Hanover County",
    "state": "VA",
    "city": "Montpelier"
  },
  {
    "value": "23219",
    "label": "Richmond - VA 23219",
    "county": "Richmond city",
    "state": "VA",
    "city": "Richmond"
  },
  {
    "value": "23220",
    "label": "Richmond - VA 23220",
    "county": "Richmond city",
    "state": "VA",
    "city": "Richmond"
  },
  {
    "value": "23221",
    "label": "Richmond - VA 23221",
    "county": "Richmond city",
    "state": "VA",
    "city": "Richmond"
  },
  {
    "value": "23222",
    "label": "Richmond - VA 23222",
    "county": "Richmond city",
    "state": "VA",
    "city": "Richmond"
  },
  {
    "value": "23223",
    "label": "Richmond - VA 23223",
    "county": "Henrico County",
    "state": "VA",
    "city": "Richmond"
  },
  {
    "value": "23224",
    "label": "Richmond - VA 23224",
    "county": "Richmond city",
    "state": "VA",
    "city": "Richmond"
  },
  {
    "value": "23225",
    "label": "Richmond - VA 23225",
    "county": "Richmond city",
    "state": "VA",
    "city": "Richmond"
  },
  {
    "value": "23226",
    "label": "Richmond - VA 23226",
    "county": "Richmond city",
    "state": "VA",
    "city": "Richmond"
  },
  {
    "value": "23227",
    "label": "Richmond - VA 23227",
    "county": "Henrico County",
    "state": "VA",
    "city": "Richmond"
  },
  {
    "value": "23228",
    "label": "Henrico - VA 23228",
    "county": "Henrico County",
    "state": "VA",
    "city": "Henrico"
  },
  {
    "value": "23229",
    "label": "Henrico - VA 23229",
    "county": "Henrico County",
    "state": "VA",
    "city": "Henrico"
  },
  {
    "value": "23230",
    "label": "Richmond - VA 23230",
    "county": "Henrico County",
    "state": "VA",
    "city": "Richmond"
  },
  {
    "value": "23231",
    "label": "Henrico - VA 23231",
    "county": "Henrico County",
    "state": "VA",
    "city": "Henrico"
  },
  {
    "value": "23232",
    "label": "Richmond - VA 23232",
    "county": "Richmond City",
    "state": "VA",
    "city": "Richmond"
  },
  {
    "value": "23233",
    "label": "Henrico - VA 23233",
    "county": "Henrico County",
    "state": "VA",
    "city": "Henrico"
  },
  {
    "value": "23234",
    "label": "Richmond - VA 23234",
    "county": "Chesterfield County",
    "state": "VA",
    "city": "Richmond"
  },
  {
    "value": "23235",
    "label": "Richmond - VA 23235",
    "county": "Chesterfield County",
    "state": "VA",
    "city": "Richmond"
  },
  {
    "value": "23236",
    "label": "Richmond - VA 23236",
    "county": "Chesterfield County",
    "state": "VA",
    "city": "Richmond"
  },
  {
    "value": "23237",
    "label": "Richmond - VA 23237",
    "county": "Chesterfield County",
    "state": "VA",
    "city": "Richmond"
  },
  {
    "value": "23238",
    "label": "Henrico - VA 23238",
    "county": "Henrico County",
    "state": "VA",
    "city": "Henrico"
  },
  {
    "value": "23240",
    "label": "Richmond - VA 23240",
    "county": "Richmond City",
    "state": "VA",
    "city": "Richmond"
  },
  {
    "value": "23250",
    "label": "Richmond - VA 23250",
    "county": "Henrico County",
    "state": "VA",
    "city": "Richmond"
  },
  {
    "value": "23294",
    "label": "Henrico - VA 23294",
    "county": "Henrico County",
    "state": "VA",
    "city": "Henrico"
  },
  {
    "value": "23298",
    "label": "Richmond - VA 23298",
    "county": "Richmond City",
    "state": "VA",
    "city": "Richmond"
  },
  {
    "value": "23301",
    "label": "Accomac - VA 23301",
    "county": "Accomack County",
    "state": "VA",
    "city": "Accomac"
  },
  {
    "value": "23302",
    "label": "Assawoman - VA 23302",
    "county": "Accomack County",
    "state": "VA",
    "city": "Assawoman"
  },
  {
    "value": "23303",
    "label": "Atlantic - VA 23303",
    "county": "Accomack County",
    "state": "VA",
    "city": "Atlantic"
  },
  {
    "value": "23306",
    "label": "Belle Haven - VA 23306",
    "county": "Accomack County",
    "state": "VA",
    "city": "Belle Haven"
  },
  {
    "value": "23307",
    "label": "Birdsnest - VA 23307",
    "county": "Northampton County",
    "state": "VA",
    "city": "Birdsnest"
  },
  {
    "value": "23308",
    "label": "Bloxom - VA 23308",
    "county": "Accomack County",
    "state": "VA",
    "city": "Bloxom"
  },
  {
    "value": "23310",
    "label": "Cape Charles - VA 23310",
    "county": "Northampton County",
    "state": "VA",
    "city": "Cape Charles"
  },
  {
    "value": "23314",
    "label": "Carrollton - VA 23314",
    "county": "Isle of Wight County",
    "state": "VA",
    "city": "Carrollton"
  },
  {
    "value": "23315",
    "label": "Carrsville - VA 23315",
    "county": "Isle of Wight County",
    "state": "VA",
    "city": "Carrsville"
  },
  {
    "value": "23320",
    "label": "Chesapeake - VA 23320",
    "county": "Chesapeake city",
    "state": "VA",
    "city": "Chesapeake"
  },
  {
    "value": "23321",
    "label": "Chesapeake - VA 23321",
    "county": "Chesapeake city",
    "state": "VA",
    "city": "Chesapeake"
  },
  {
    "value": "23322",
    "label": "Chesapeake - VA 23322",
    "county": "Chesapeake city",
    "state": "VA",
    "city": "Chesapeake"
  },
  {
    "value": "23323",
    "label": "Chesapeake - VA 23323",
    "county": "Chesapeake city",
    "state": "VA",
    "city": "Chesapeake"
  },
  {
    "value": "23324",
    "label": "Chesapeake - VA 23324",
    "county": "Chesapeake city",
    "state": "VA",
    "city": "Chesapeake"
  },
  {
    "value": "23325",
    "label": "Chesapeake - VA 23325",
    "county": "Chesapeake city",
    "state": "VA",
    "city": "Chesapeake"
  },
  {
    "value": "23336",
    "label": "Chincoteague Island - VA 23336",
    "county": "Accomack County",
    "state": "VA",
    "city": "Chincoteague Island"
  },
  {
    "value": "23337",
    "label": "Wallops Island - VA 23337",
    "county": "Accomack County",
    "state": "VA",
    "city": "Wallops Island"
  },
  {
    "value": "23345",
    "label": "Davis Wharf - VA 23345",
    "county": "Accomack County",
    "state": "VA",
    "city": "Davis Wharf"
  },
  {
    "value": "23350",
    "label": "Exmore - VA 23350",
    "county": "Northampton County",
    "state": "VA",
    "city": "Exmore"
  },
  {
    "value": "23354",
    "label": "Franktown - VA 23354",
    "county": "Northampton County",
    "state": "VA",
    "city": "Franktown"
  },
  {
    "value": "23356",
    "label": "Greenbackville - VA 23356",
    "county": "Accomack County",
    "state": "VA",
    "city": "Greenbackville"
  },
  {
    "value": "23357",
    "label": "Greenbush - VA 23357",
    "county": "Accomack County",
    "state": "VA",
    "city": "Greenbush"
  },
  {
    "value": "23358",
    "label": "Hacksneck - VA 23358",
    "county": "Accomack County",
    "state": "VA",
    "city": "Hacksneck"
  },
  {
    "value": "23359",
    "label": "Hallwood - VA 23359",
    "county": "Accomack County",
    "state": "VA",
    "city": "Hallwood"
  },
  {
    "value": "23395",
    "label": "Horntown - VA 23395",
    "county": "Accomack County",
    "state": "VA",
    "city": "Horntown"
  },
  {
    "value": "23396",
    "label": "Oak Hall - VA 23396",
    "county": "Accomack County",
    "state": "VA",
    "city": "Oak Hall"
  },
  {
    "value": "23399",
    "label": "Jenkins Bridge - VA 23399",
    "county": "Accomack County",
    "state": "VA",
    "city": "Jenkins Bridge"
  },
  {
    "value": "23404",
    "label": "Locustville - VA 23404",
    "county": "Accomack County",
    "state": "VA",
    "city": "Locustville"
  },
  {
    "value": "23405",
    "label": "Machipongo - VA 23405",
    "county": "Northampton County",
    "state": "VA",
    "city": "Machipongo"
  },
  {
    "value": "23409",
    "label": "Mears - VA 23409",
    "county": "Accomack County",
    "state": "VA",
    "city": "Mears"
  },
  {
    "value": "23410",
    "label": "Melfa - VA 23410",
    "county": "Accomack County",
    "state": "VA",
    "city": "Melfa"
  },
  {
    "value": "23415",
    "label": "New Church - VA 23415",
    "county": "Accomack County",
    "state": "VA",
    "city": "New Church"
  },
  {
    "value": "23416",
    "label": "Oak Hall - VA 23416",
    "county": "Accomack County",
    "state": "VA",
    "city": "Oak Hall"
  },
  {
    "value": "23417",
    "label": "Onancock - VA 23417",
    "county": "Accomack County",
    "state": "VA",
    "city": "Onancock"
  },
  {
    "value": "23418",
    "label": "Onley - VA 23418",
    "county": "Accomack County",
    "state": "VA",
    "city": "Onley"
  },
  {
    "value": "23420",
    "label": "Painter - VA 23420",
    "county": "Accomack County",
    "state": "VA",
    "city": "Painter"
  },
  {
    "value": "23421",
    "label": "Parksley - VA 23421",
    "county": "Accomack County",
    "state": "VA",
    "city": "Parksley"
  },
  {
    "value": "23426",
    "label": "Sanford - VA 23426",
    "county": "Accomack County",
    "state": "VA",
    "city": "Sanford"
  },
  {
    "value": "23430",
    "label": "Smithfield - VA 23430",
    "county": "Isle of Wight County",
    "state": "VA",
    "city": "Smithfield"
  },
  {
    "value": "23432",
    "label": "Suffolk - VA 23432",
    "county": "Suffolk city",
    "state": "VA",
    "city": "Suffolk"
  },
  {
    "value": "23433",
    "label": "Suffolk - VA 23433",
    "county": "Suffolk city",
    "state": "VA",
    "city": "Suffolk"
  },
  {
    "value": "23434",
    "label": "Suffolk - VA 23434",
    "county": "Suffolk city",
    "state": "VA",
    "city": "Suffolk"
  },
  {
    "value": "23435",
    "label": "Suffolk - VA 23435",
    "county": "Suffolk city",
    "state": "VA",
    "city": "Suffolk"
  },
  {
    "value": "23436",
    "label": "Suffolk - VA 23436",
    "county": "Suffolk city",
    "state": "VA",
    "city": "Suffolk"
  },
  {
    "value": "23437",
    "label": "Suffolk - VA 23437",
    "county": "Suffolk city",
    "state": "VA",
    "city": "Suffolk"
  },
  {
    "value": "23438",
    "label": "Suffolk - VA 23438",
    "county": "Suffolk city",
    "state": "VA",
    "city": "Suffolk"
  },
  {
    "value": "23442",
    "label": "Temperanceville - VA 23442",
    "county": "Accomack County",
    "state": "VA",
    "city": "Temperanceville"
  },
  {
    "value": "23451",
    "label": "Virginia Beach - VA 23451",
    "county": "Virginia Beach city",
    "state": "VA",
    "city": "Virginia Beach"
  },
  {
    "value": "23452",
    "label": "Virginia Beach - VA 23452",
    "county": "Virginia Beach city",
    "state": "VA",
    "city": "Virginia Beach"
  },
  {
    "value": "23453",
    "label": "Virginia Beach - VA 23453",
    "county": "Virginia Beach city",
    "state": "VA",
    "city": "Virginia Beach"
  },
  {
    "value": "23454",
    "label": "Virginia Beach - VA 23454",
    "county": "Virginia Beach city",
    "state": "VA",
    "city": "Virginia Beach"
  },
  {
    "value": "23455",
    "label": "Virginia Beach - VA 23455",
    "county": "Virginia Beach city",
    "state": "VA",
    "city": "Virginia Beach"
  },
  {
    "value": "23456",
    "label": "Virginia Beach - VA 23456",
    "county": "Virginia Beach city",
    "state": "VA",
    "city": "Virginia Beach"
  },
  {
    "value": "23457",
    "label": "Virginia Beach - VA 23457",
    "county": "Virginia Beach city",
    "state": "VA",
    "city": "Virginia Beach"
  },
  {
    "value": "23459",
    "label": "Virginia Beach - VA 23459",
    "county": "Virginia Beach city",
    "state": "VA",
    "city": "Virginia Beach"
  },
  {
    "value": "23460",
    "label": "Virginia Beach - VA 23460",
    "county": "Virginia Beach city",
    "state": "VA",
    "city": "Virginia Beach"
  },
  {
    "value": "23461",
    "label": "Virginia Beach - VA 23461",
    "county": "Virginia Beach city",
    "state": "VA",
    "city": "Virginia Beach"
  },
  {
    "value": "23462",
    "label": "Virginia Beach - VA 23462",
    "county": "Virginia Beach city",
    "state": "VA",
    "city": "Virginia Beach"
  },
  {
    "value": "23464",
    "label": "Virginia Beach - VA 23464",
    "county": "Virginia Beach city",
    "state": "VA",
    "city": "Virginia Beach"
  },
  {
    "value": "23487",
    "label": "Windsor - VA 23487",
    "county": "Isle of Wight County",
    "state": "VA",
    "city": "Windsor"
  },
  {
    "value": "23488",
    "label": "Withams - VA 23488",
    "county": "Accomack County",
    "state": "VA",
    "city": "Withams"
  },
  {
    "value": "23502",
    "label": "Norfolk - VA 23502",
    "county": "Norfolk city",
    "state": "VA",
    "city": "Norfolk"
  },
  {
    "value": "23503",
    "label": "Norfolk - VA 23503",
    "county": "Norfolk city",
    "state": "VA",
    "city": "Norfolk"
  },
  {
    "value": "23504",
    "label": "Norfolk - VA 23504",
    "county": "Norfolk city",
    "state": "VA",
    "city": "Norfolk"
  },
  {
    "value": "23505",
    "label": "Norfolk - VA 23505",
    "county": "Norfolk city",
    "state": "VA",
    "city": "Norfolk"
  },
  {
    "value": "23507",
    "label": "Norfolk - VA 23507",
    "county": "Norfolk city",
    "state": "VA",
    "city": "Norfolk"
  },
  {
    "value": "23508",
    "label": "Norfolk - VA 23508",
    "county": "Norfolk city",
    "state": "VA",
    "city": "Norfolk"
  },
  {
    "value": "23509",
    "label": "Norfolk - VA 23509",
    "county": "Norfolk city",
    "state": "VA",
    "city": "Norfolk"
  },
  {
    "value": "23510",
    "label": "Norfolk - VA 23510",
    "county": "Norfolk city",
    "state": "VA",
    "city": "Norfolk"
  },
  {
    "value": "23511",
    "label": "Norfolk - VA 23511",
    "county": "Norfolk city",
    "state": "VA",
    "city": "Norfolk"
  },
  {
    "value": "23513",
    "label": "Norfolk - VA 23513",
    "county": "Norfolk city",
    "state": "VA",
    "city": "Norfolk"
  },
  {
    "value": "23517",
    "label": "Norfolk - VA 23517",
    "county": "Norfolk city",
    "state": "VA",
    "city": "Norfolk"
  },
  {
    "value": "23518",
    "label": "Norfolk - VA 23518",
    "county": "Norfolk city",
    "state": "VA",
    "city": "Norfolk"
  },
  {
    "value": "23519",
    "label": "Norfolk - VA 23519",
    "county": "Norfolk City",
    "state": "VA",
    "city": "Norfolk"
  },
  {
    "value": "23520",
    "label": "Norfolk - VA 23520",
    "county": "Norfolk City",
    "state": "VA",
    "city": "Norfolk"
  },
  {
    "value": "23521",
    "label": "Norfolk - VA 23521",
    "county": "Virginia Beach City",
    "state": "VA",
    "city": "Norfolk"
  },
  {
    "value": "23523",
    "label": "Norfolk - VA 23523",
    "county": "Norfolk city",
    "state": "VA",
    "city": "Norfolk"
  },
  {
    "value": "23601",
    "label": "Newport News - VA 23601",
    "county": "Newport News city",
    "state": "VA",
    "city": "Newport News"
  },
  {
    "value": "23602",
    "label": "Newport News - VA 23602",
    "county": "Newport News city",
    "state": "VA",
    "city": "Newport News"
  },
  {
    "value": "23603",
    "label": "Newport News - VA 23603",
    "county": "Newport News city",
    "state": "VA",
    "city": "Newport News"
  },
  {
    "value": "23604",
    "label": "Fort Eustis - VA 23604",
    "county": "Newport News city",
    "state": "VA",
    "city": "Fort Eustis"
  },
  {
    "value": "23605",
    "label": "Newport News - VA 23605",
    "county": "Newport News city",
    "state": "VA",
    "city": "Newport News"
  },
  {
    "value": "23606",
    "label": "Newport News - VA 23606",
    "county": "Newport News city",
    "state": "VA",
    "city": "Newport News"
  },
  {
    "value": "23607",
    "label": "Newport News - VA 23607",
    "county": "Newport News city",
    "state": "VA",
    "city": "Newport News"
  },
  {
    "value": "23608",
    "label": "Newport News - VA 23608",
    "county": "Newport News city",
    "state": "VA",
    "city": "Newport News"
  },
  {
    "value": "23651",
    "label": "Fort Monroe - VA 23651",
    "county": "Hampton city",
    "state": "VA",
    "city": "Fort Monroe"
  },
  {
    "value": "23661",
    "label": "Hampton - VA 23661",
    "county": "Hampton city",
    "state": "VA",
    "city": "Hampton"
  },
  {
    "value": "23662",
    "label": "Poquoson - VA 23662",
    "county": "Poquoson city",
    "state": "VA",
    "city": "Poquoson"
  },
  {
    "value": "23663",
    "label": "Hampton - VA 23663",
    "county": "Hampton city",
    "state": "VA",
    "city": "Hampton"
  },
  {
    "value": "23664",
    "label": "Hampton - VA 23664",
    "county": "Hampton city",
    "state": "VA",
    "city": "Hampton"
  },
  {
    "value": "23665",
    "label": "Hampton - VA 23665",
    "county": "York County",
    "state": "VA",
    "city": "Hampton"
  },
  {
    "value": "23666",
    "label": "Hampton - VA 23666",
    "county": "Hampton city",
    "state": "VA",
    "city": "Hampton"
  },
  {
    "value": "23669",
    "label": "Hampton - VA 23669",
    "county": "Hampton city",
    "state": "VA",
    "city": "Hampton"
  },
  {
    "value": "23690",
    "label": "Yorktown - VA 23690",
    "county": "York County",
    "state": "VA",
    "city": "Yorktown"
  },
  {
    "value": "23691",
    "label": "Yorktown - VA 23691",
    "county": "York County",
    "state": "VA",
    "city": "Yorktown"
  },
  {
    "value": "23692",
    "label": "Yorktown - VA 23692",
    "county": "York County",
    "state": "VA",
    "city": "Yorktown"
  },
  {
    "value": "23693",
    "label": "Yorktown - VA 23693",
    "county": "York County",
    "state": "VA",
    "city": "Yorktown"
  },
  {
    "value": "23696",
    "label": "Seaford - VA 23696",
    "county": "York County",
    "state": "VA",
    "city": "Seaford"
  },
  {
    "value": "23701",
    "label": "Portsmouth - VA 23701",
    "county": "Portsmouth city",
    "state": "VA",
    "city": "Portsmouth"
  },
  {
    "value": "23702",
    "label": "Portsmouth - VA 23702",
    "county": "Portsmouth city",
    "state": "VA",
    "city": "Portsmouth"
  },
  {
    "value": "23703",
    "label": "Portsmouth - VA 23703",
    "county": "Portsmouth city",
    "state": "VA",
    "city": "Portsmouth"
  },
  {
    "value": "23704",
    "label": "Portsmouth - VA 23704",
    "county": "Portsmouth city",
    "state": "VA",
    "city": "Portsmouth"
  },
  {
    "value": "23707",
    "label": "Portsmouth - VA 23707",
    "county": "Portsmouth city",
    "state": "VA",
    "city": "Portsmouth"
  },
  {
    "value": "23708",
    "label": "Portsmouth - VA 23708",
    "county": "Portsmouth city",
    "state": "VA",
    "city": "Portsmouth"
  },
  {
    "value": "23709",
    "label": "Portsmouth - VA 23709",
    "county": "Portsmouth city",
    "state": "VA",
    "city": "Portsmouth"
  },
  {
    "value": "23801",
    "label": "Fort Lee - VA 23801",
    "county": "Prince George County",
    "state": "VA",
    "city": "Fort Lee"
  },
  {
    "value": "23803",
    "label": "Petersburg - VA 23803",
    "county": "Petersburg city",
    "state": "VA",
    "city": "Petersburg"
  },
  {
    "value": "23805",
    "label": "Petersburg - VA 23805",
    "county": "Petersburg city",
    "state": "VA",
    "city": "Petersburg"
  },
  {
    "value": "23821",
    "label": "Alberta - VA 23821",
    "county": "Brunswick County",
    "state": "VA",
    "city": "Alberta"
  },
  {
    "value": "23824",
    "label": "Blackstone - VA 23824",
    "county": "Nottoway County",
    "state": "VA",
    "city": "Blackstone"
  },
  {
    "value": "23827",
    "label": "Boykins - VA 23827",
    "county": "Southampton County",
    "state": "VA",
    "city": "Boykins"
  },
  {
    "value": "23828",
    "label": "Branchville - VA 23828",
    "county": "Southampton County",
    "state": "VA",
    "city": "Branchville"
  },
  {
    "value": "23829",
    "label": "Capron - VA 23829",
    "county": "Southampton County",
    "state": "VA",
    "city": "Capron"
  },
  {
    "value": "23830",
    "label": "Carson - VA 23830",
    "county": "Prince George County",
    "state": "VA",
    "city": "Carson"
  },
  {
    "value": "23831",
    "label": "Chester - VA 23831",
    "county": "Chesterfield County",
    "state": "VA",
    "city": "Chester"
  },
  {
    "value": "23832",
    "label": "Chesterfield - VA 23832",
    "county": "Chesterfield County",
    "state": "VA",
    "city": "Chesterfield"
  },
  {
    "value": "23833",
    "label": "Church Road - VA 23833",
    "county": "Dinwiddie County",
    "state": "VA",
    "city": "Church Road"
  },
  {
    "value": "23834",
    "label": "Colonial Heights - VA 23834",
    "county": "Colonial Heights city",
    "state": "VA",
    "city": "Colonial Heights"
  },
  {
    "value": "23836",
    "label": "Chester - VA 23836",
    "county": "Chesterfield County",
    "state": "VA",
    "city": "Chester"
  },
  {
    "value": "23837",
    "label": "Courtland - VA 23837",
    "county": "Southampton County",
    "state": "VA",
    "city": "Courtland"
  },
  {
    "value": "23838",
    "label": "Chesterfield - VA 23838",
    "county": "Chesterfield County",
    "state": "VA",
    "city": "Chesterfield"
  },
  {
    "value": "23839",
    "label": "Dendron - VA 23839",
    "county": "Surry County",
    "state": "VA",
    "city": "Dendron"
  },
  {
    "value": "23840",
    "label": "Dewitt - VA 23840",
    "county": "Dinwiddie County",
    "state": "VA",
    "city": "Dewitt"
  },
  {
    "value": "23841",
    "label": "Dinwiddie - VA 23841",
    "county": "Dinwiddie County",
    "state": "VA",
    "city": "Dinwiddie"
  },
  {
    "value": "23842",
    "label": "Disputanta - VA 23842",
    "county": "Prince George County",
    "state": "VA",
    "city": "Disputanta"
  },
  {
    "value": "23843",
    "label": "Dolphin - VA 23843",
    "county": "Brunswick County",
    "state": "VA",
    "city": "Dolphin"
  },
  {
    "value": "23844",
    "label": "Drewryville - VA 23844",
    "county": "Southampton County",
    "state": "VA",
    "city": "Drewryville"
  },
  {
    "value": "23845",
    "label": "Ebony - VA 23845",
    "county": "Brunswick County",
    "state": "VA",
    "city": "Ebony"
  },
  {
    "value": "23846",
    "label": "Elberon - VA 23846",
    "county": "Surry County",
    "state": "VA",
    "city": "Elberon"
  },
  {
    "value": "23847",
    "label": "Emporia - VA 23847",
    "county": "Greensville County",
    "state": "VA",
    "city": "Emporia"
  },
  {
    "value": "23850",
    "label": "Ford - VA 23850",
    "county": "Dinwiddie County",
    "state": "VA",
    "city": "Ford"
  },
  {
    "value": "23851",
    "label": "Franklin - VA 23851",
    "county": "Franklin city",
    "state": "VA",
    "city": "Franklin"
  },
  {
    "value": "23856",
    "label": "Freeman - VA 23856",
    "county": "Brunswick County",
    "state": "VA",
    "city": "Freeman"
  },
  {
    "value": "23857",
    "label": "Gasburg - VA 23857",
    "county": "Brunswick County",
    "state": "VA",
    "city": "Gasburg"
  },
  {
    "value": "23860",
    "label": "Hopewell - VA 23860",
    "county": "Hopewell city",
    "state": "VA",
    "city": "Hopewell"
  },
  {
    "value": "23866",
    "label": "Ivor - VA 23866",
    "county": "Southampton County",
    "state": "VA",
    "city": "Ivor"
  },
  {
    "value": "23867",
    "label": "Jarratt - VA 23867",
    "county": "Greensville County",
    "state": "VA",
    "city": "Jarratt"
  },
  {
    "value": "23868",
    "label": "Lawrenceville - VA 23868",
    "county": "Brunswick County",
    "state": "VA",
    "city": "Lawrenceville"
  },
  {
    "value": "23872",
    "label": "Mc Kenney - VA 23872",
    "county": "Dinwiddie County",
    "state": "VA",
    "city": "Mc Kenney"
  },
  {
    "value": "23874",
    "label": "Newsoms - VA 23874",
    "county": "Southampton County",
    "state": "VA",
    "city": "Newsoms"
  },
  {
    "value": "23875",
    "label": "Prince George - VA 23875",
    "county": "Prince George County",
    "state": "VA",
    "city": "Prince George"
  },
  {
    "value": "23876",
    "label": "Rawlings - VA 23876",
    "county": "Brunswick County",
    "state": "VA",
    "city": "Rawlings"
  },
  {
    "value": "23878",
    "label": "Sedley - VA 23878",
    "county": "Southampton County",
    "state": "VA",
    "city": "Sedley"
  },
  {
    "value": "23879",
    "label": "Skippers - VA 23879",
    "county": "Greensville County",
    "state": "VA",
    "city": "Skippers"
  },
  {
    "value": "23881",
    "label": "Spring Grove - VA 23881",
    "county": "Surry County",
    "state": "VA",
    "city": "Spring Grove"
  },
  {
    "value": "23882",
    "label": "Stony Creek - VA 23882",
    "county": "Sussex County",
    "state": "VA",
    "city": "Stony Creek"
  },
  {
    "value": "23883",
    "label": "Surry - VA 23883",
    "county": "Surry County",
    "state": "VA",
    "city": "Surry"
  },
  {
    "value": "23885",
    "label": "Sutherland - VA 23885",
    "county": "Dinwiddie County",
    "state": "VA",
    "city": "Sutherland"
  },
  {
    "value": "23887",
    "label": "Valentines - VA 23887",
    "county": "Brunswick County",
    "state": "VA",
    "city": "Valentines"
  },
  {
    "value": "23888",
    "label": "Wakefield - VA 23888",
    "county": "Sussex County",
    "state": "VA",
    "city": "Wakefield"
  },
  {
    "value": "23889",
    "label": "Warfield - VA 23889",
    "county": "Brunswick County",
    "state": "VA",
    "city": "Warfield"
  },
  {
    "value": "23890",
    "label": "Waverly - VA 23890",
    "county": "Sussex County",
    "state": "VA",
    "city": "Waverly"
  },
  {
    "value": "23893",
    "label": "White Plains - VA 23893",
    "county": "Brunswick County",
    "state": "VA",
    "city": "White Plains"
  },
  {
    "value": "23894",
    "label": "Wilsons - VA 23894",
    "county": "Dinwiddie County",
    "state": "VA",
    "city": "Wilsons"
  },
  {
    "value": "23897",
    "label": "Yale - VA 23897",
    "county": "Sussex County",
    "state": "VA",
    "city": "Yale"
  },
  {
    "value": "23898",
    "label": "Zuni - VA 23898",
    "county": "Isle of Wight County",
    "state": "VA",
    "city": "Zuni"
  },
  {
    "value": "23901",
    "label": "Farmville - VA 23901",
    "county": "Prince Edward County",
    "state": "VA",
    "city": "Farmville"
  },
  {
    "value": "23915",
    "label": "Baskerville - VA 23915",
    "county": "Mecklenburg County",
    "state": "VA",
    "city": "Baskerville"
  },
  {
    "value": "23917",
    "label": "Boydton - VA 23917",
    "county": "Mecklenburg County",
    "state": "VA",
    "city": "Boydton"
  },
  {
    "value": "23919",
    "label": "Bracey - VA 23919",
    "county": "Mecklenburg County",
    "state": "VA",
    "city": "Bracey"
  },
  {
    "value": "23920",
    "label": "Brodnax - VA 23920",
    "county": "Brunswick County",
    "state": "VA",
    "city": "Brodnax"
  },
  {
    "value": "23921",
    "label": "Buckingham - VA 23921",
    "county": "Buckingham County",
    "state": "VA",
    "city": "Buckingham"
  },
  {
    "value": "23922",
    "label": "Burkeville - VA 23922",
    "county": "Nottoway County",
    "state": "VA",
    "city": "Burkeville"
  },
  {
    "value": "23923",
    "label": "Charlotte Court House - VA 23923",
    "county": "Charlotte County",
    "state": "VA",
    "city": "Charlotte Court House"
  },
  {
    "value": "23924",
    "label": "Chase City - VA 23924",
    "county": "Mecklenburg County",
    "state": "VA",
    "city": "Chase City"
  },
  {
    "value": "23927",
    "label": "Clarksville - VA 23927",
    "county": "Mecklenburg County",
    "state": "VA",
    "city": "Clarksville"
  },
  {
    "value": "23930",
    "label": "Crewe - VA 23930",
    "county": "Nottoway County",
    "state": "VA",
    "city": "Crewe"
  },
  {
    "value": "23934",
    "label": "Cullen - VA 23934",
    "county": "Charlotte County",
    "state": "VA",
    "city": "Cullen"
  },
  {
    "value": "23936",
    "label": "Dillwyn - VA 23936",
    "county": "Buckingham County",
    "state": "VA",
    "city": "Dillwyn"
  },
  {
    "value": "23937",
    "label": "Drakes Branch - VA 23937",
    "county": "Charlotte County",
    "state": "VA",
    "city": "Drakes Branch"
  },
  {
    "value": "23938",
    "label": "Dundas - VA 23938",
    "county": "Brunswick County",
    "state": "VA",
    "city": "Dundas"
  },
  {
    "value": "23942",
    "label": "Green Bay - VA 23942",
    "county": "Prince Edward County",
    "state": "VA",
    "city": "Green Bay"
  },
  {
    "value": "23944",
    "label": "Kenbridge - VA 23944",
    "county": "Lunenburg County",
    "state": "VA",
    "city": "Kenbridge"
  },
  {
    "value": "23947",
    "label": "Keysville - VA 23947",
    "county": "Charlotte County",
    "state": "VA",
    "city": "Keysville"
  },
  {
    "value": "23950",
    "label": "La Crosse - VA 23950",
    "county": "Mecklenburg County",
    "state": "VA",
    "city": "La Crosse"
  },
  {
    "value": "23952",
    "label": "Lunenburg - VA 23952",
    "county": "Lunenburg County",
    "state": "VA",
    "city": "Lunenburg"
  },
  {
    "value": "23954",
    "label": "Meherrin - VA 23954",
    "county": "Prince Edward County",
    "state": "VA",
    "city": "Meherrin"
  },
  {
    "value": "23958",
    "label": "Pamplin - VA 23958",
    "county": "Appomattox County",
    "state": "VA",
    "city": "Pamplin"
  },
  {
    "value": "23959",
    "label": "Phenix - VA 23959",
    "county": "Charlotte County",
    "state": "VA",
    "city": "Phenix"
  },
  {
    "value": "23960",
    "label": "Prospect - VA 23960",
    "county": "Prince Edward County",
    "state": "VA",
    "city": "Prospect"
  },
  {
    "value": "23962",
    "label": "Randolph - VA 23962",
    "county": "Charlotte County",
    "state": "VA",
    "city": "Randolph"
  },
  {
    "value": "23963",
    "label": "Red House - VA 23963",
    "county": "Charlotte County",
    "state": "VA",
    "city": "Red House"
  },
  {
    "value": "23964",
    "label": "Red Oak - VA 23964",
    "county": "Charlotte County",
    "state": "VA",
    "city": "Red Oak"
  },
  {
    "value": "23966",
    "label": "Rice - VA 23966",
    "county": "Prince Edward County",
    "state": "VA",
    "city": "Rice"
  },
  {
    "value": "23967",
    "label": "Saxe - VA 23967",
    "county": "Charlotte County",
    "state": "VA",
    "city": "Saxe"
  },
  {
    "value": "23968",
    "label": "Skipwith - VA 23968",
    "county": "Mecklenburg County",
    "state": "VA",
    "city": "Skipwith"
  },
  {
    "value": "23970",
    "label": "South Hill - VA 23970",
    "county": "Mecklenburg County",
    "state": "VA",
    "city": "South Hill"
  },
  {
    "value": "23974",
    "label": "Victoria - VA 23974",
    "county": "Lunenburg County",
    "state": "VA",
    "city": "Victoria"
  },
  {
    "value": "23976",
    "label": "Wylliesburg - VA 23976",
    "county": "Charlotte County",
    "state": "VA",
    "city": "Wylliesburg"
  },
  {
    "value": "24011",
    "label": "Roanoke - VA 24011",
    "county": "Roanoke city",
    "state": "VA",
    "city": "Roanoke"
  },
  {
    "value": "24012",
    "label": "Roanoke - VA 24012",
    "county": "Roanoke city",
    "state": "VA",
    "city": "Roanoke"
  },
  {
    "value": "24013",
    "label": "Roanoke - VA 24013",
    "county": "Roanoke city",
    "state": "VA",
    "city": "Roanoke"
  },
  {
    "value": "24014",
    "label": "Roanoke - VA 24014",
    "county": "Roanoke city",
    "state": "VA",
    "city": "Roanoke"
  },
  {
    "value": "24015",
    "label": "Roanoke - VA 24015",
    "county": "Roanoke city",
    "state": "VA",
    "city": "Roanoke"
  },
  {
    "value": "24016",
    "label": "Roanoke - VA 24016",
    "county": "Roanoke city",
    "state": "VA",
    "city": "Roanoke"
  },
  {
    "value": "24017",
    "label": "Roanoke - VA 24017",
    "county": "Roanoke city",
    "state": "VA",
    "city": "Roanoke"
  },
  {
    "value": "24018",
    "label": "Roanoke - VA 24018",
    "county": "Roanoke County",
    "state": "VA",
    "city": "Roanoke"
  },
  {
    "value": "24019",
    "label": "Roanoke - VA 24019",
    "county": "Roanoke County",
    "state": "VA",
    "city": "Roanoke"
  },
  {
    "value": "24053",
    "label": "Ararat - VA 24053",
    "county": "Patrick County",
    "state": "VA",
    "city": "Ararat"
  },
  {
    "value": "24054",
    "label": "Axton - VA 24054",
    "county": "Henry County",
    "state": "VA",
    "city": "Axton"
  },
  {
    "value": "24055",
    "label": "Bassett - VA 24055",
    "county": "Henry County",
    "state": "VA",
    "city": "Bassett"
  },
  {
    "value": "24059",
    "label": "Bent Mountain - VA 24059",
    "county": "Roanoke County",
    "state": "VA",
    "city": "Bent Mountain"
  },
  {
    "value": "24060",
    "label": "Blacksburg - VA 24060",
    "county": "Montgomery County",
    "state": "VA",
    "city": "Blacksburg"
  },
  {
    "value": "24064",
    "label": "Blue Ridge - VA 24064",
    "county": "Botetourt County",
    "state": "VA",
    "city": "Blue Ridge"
  },
  {
    "value": "24065",
    "label": "Boones Mill - VA 24065",
    "county": "Franklin County",
    "state": "VA",
    "city": "Boones Mill"
  },
  {
    "value": "24066",
    "label": "Buchanan - VA 24066",
    "county": "Botetourt County",
    "state": "VA",
    "city": "Buchanan"
  },
  {
    "value": "24067",
    "label": "Callaway - VA 24067",
    "county": "Franklin County",
    "state": "VA",
    "city": "Callaway"
  },
  {
    "value": "24069",
    "label": "Cascade - VA 24069",
    "county": "Pittsylvania County",
    "state": "VA",
    "city": "Cascade"
  },
  {
    "value": "24070",
    "label": "Catawba - VA 24070",
    "county": "Roanoke County",
    "state": "VA",
    "city": "Catawba"
  },
  {
    "value": "24072",
    "label": "Check - VA 24072",
    "county": "Floyd County",
    "state": "VA",
    "city": "Check"
  },
  {
    "value": "24073",
    "label": "Christiansburg - VA 24073",
    "county": "Montgomery County",
    "state": "VA",
    "city": "Christiansburg"
  },
  {
    "value": "24076",
    "label": "Claudville - VA 24076",
    "county": "Patrick County",
    "state": "VA",
    "city": "Claudville"
  },
  {
    "value": "24077",
    "label": "Cloverdale - VA 24077",
    "county": "Botetourt County",
    "state": "VA",
    "city": "Cloverdale"
  },
  {
    "value": "24078",
    "label": "Collinsville - VA 24078",
    "county": "Henry County",
    "state": "VA",
    "city": "Collinsville"
  },
  {
    "value": "24079",
    "label": "Copper Hill - VA 24079",
    "county": "Floyd County",
    "state": "VA",
    "city": "Copper Hill"
  },
  {
    "value": "24082",
    "label": "Critz - VA 24082",
    "county": "Patrick County",
    "state": "VA",
    "city": "Critz"
  },
  {
    "value": "24083",
    "label": "Daleville - VA 24083",
    "county": "Botetourt County",
    "state": "VA",
    "city": "Daleville"
  },
  {
    "value": "24084",
    "label": "Dublin - VA 24084",
    "county": "Pulaski County",
    "state": "VA",
    "city": "Dublin"
  },
  {
    "value": "24085",
    "label": "Eagle Rock - VA 24085",
    "county": "Botetourt County",
    "state": "VA",
    "city": "Eagle Rock"
  },
  {
    "value": "24086",
    "label": "Eggleston - VA 24086",
    "county": "Giles County",
    "state": "VA",
    "city": "Eggleston"
  },
  {
    "value": "24087",
    "label": "Elliston - VA 24087",
    "county": "Montgomery County",
    "state": "VA",
    "city": "Elliston"
  },
  {
    "value": "24088",
    "label": "Ferrum - VA 24088",
    "county": "Franklin County",
    "state": "VA",
    "city": "Ferrum"
  },
  {
    "value": "24089",
    "label": "Fieldale - VA 24089",
    "county": "Henry County",
    "state": "VA",
    "city": "Fieldale"
  },
  {
    "value": "24090",
    "label": "Fincastle - VA 24090",
    "county": "Botetourt County",
    "state": "VA",
    "city": "Fincastle"
  },
  {
    "value": "24091",
    "label": "Floyd - VA 24091",
    "county": "Floyd County",
    "state": "VA",
    "city": "Floyd"
  },
  {
    "value": "24092",
    "label": "Glade Hill - VA 24092",
    "county": "Franklin County",
    "state": "VA",
    "city": "Glade Hill"
  },
  {
    "value": "24093",
    "label": "Glen Lyn - VA 24093",
    "county": "Giles County",
    "state": "VA",
    "city": "Glen Lyn"
  },
  {
    "value": "24095",
    "label": "Goodview - VA 24095",
    "county": "Bedford County",
    "state": "VA",
    "city": "Goodview"
  },
  {
    "value": "24101",
    "label": "Hardy - VA 24101",
    "county": "Franklin County",
    "state": "VA",
    "city": "Hardy"
  },
  {
    "value": "24102",
    "label": "Henry - VA 24102",
    "county": "Franklin County",
    "state": "VA",
    "city": "Henry"
  },
  {
    "value": "24104",
    "label": "Huddleston - VA 24104",
    "county": "Bedford County",
    "state": "VA",
    "city": "Huddleston"
  },
  {
    "value": "24105",
    "label": "Indian Valley - VA 24105",
    "county": "Floyd County",
    "state": "VA",
    "city": "Indian Valley"
  },
  {
    "value": "24112",
    "label": "Martinsville - VA 24112",
    "county": "Henry County",
    "state": "VA",
    "city": "Martinsville"
  },
  {
    "value": "24120",
    "label": "Meadows Of Dan - VA 24120",
    "county": "Patrick County",
    "state": "VA",
    "city": "Meadows Of Dan"
  },
  {
    "value": "24121",
    "label": "Moneta - VA 24121",
    "county": "Bedford County",
    "state": "VA",
    "city": "Moneta"
  },
  {
    "value": "24122",
    "label": "Montvale - VA 24122",
    "county": "Bedford County",
    "state": "VA",
    "city": "Montvale"
  },
  {
    "value": "24124",
    "label": "Narrows - VA 24124",
    "county": "Giles County",
    "state": "VA",
    "city": "Narrows"
  },
  {
    "value": "24127",
    "label": "New Castle - VA 24127",
    "county": "Craig County",
    "state": "VA",
    "city": "New Castle"
  },
  {
    "value": "24128",
    "label": "Newport - VA 24128",
    "county": "Giles County",
    "state": "VA",
    "city": "Newport"
  },
  {
    "value": "24130",
    "label": "Oriskany - VA 24130",
    "county": "Botetourt County",
    "state": "VA",
    "city": "Oriskany"
  },
  {
    "value": "24131",
    "label": "Paint Bank - VA 24131",
    "county": "Craig County",
    "state": "VA",
    "city": "Paint Bank"
  },
  {
    "value": "24133",
    "label": "Patrick Springs - VA 24133",
    "county": "Patrick County",
    "state": "VA",
    "city": "Patrick Springs"
  },
  {
    "value": "24134",
    "label": "Pearisburg - VA 24134",
    "county": "Giles County",
    "state": "VA",
    "city": "Pearisburg"
  },
  {
    "value": "24136",
    "label": "Pembroke - VA 24136",
    "county": "Giles County",
    "state": "VA",
    "city": "Pembroke"
  },
  {
    "value": "24137",
    "label": "Penhook - VA 24137",
    "county": "Franklin County",
    "state": "VA",
    "city": "Penhook"
  },
  {
    "value": "24138",
    "label": "Pilot - VA 24138",
    "county": "Montgomery County",
    "state": "VA",
    "city": "Pilot"
  },
  {
    "value": "24139",
    "label": "Pittsville - VA 24139",
    "county": "Pittsylvania County",
    "state": "VA",
    "city": "Pittsville"
  },
  {
    "value": "24141",
    "label": "Radford - VA 24141",
    "county": "Radford city",
    "state": "VA",
    "city": "Radford"
  },
  {
    "value": "24147",
    "label": "Rich Creek - VA 24147",
    "county": "Giles County",
    "state": "VA",
    "city": "Rich Creek"
  },
  {
    "value": "24148",
    "label": "Ridgeway - VA 24148",
    "county": "Henry County",
    "state": "VA",
    "city": "Ridgeway"
  },
  {
    "value": "24149",
    "label": "Riner - VA 24149",
    "county": "Montgomery County",
    "state": "VA",
    "city": "Riner"
  },
  {
    "value": "24150",
    "label": "Ripplemead - VA 24150",
    "county": "Giles County",
    "state": "VA",
    "city": "Ripplemead"
  },
  {
    "value": "24151",
    "label": "Rocky Mount - VA 24151",
    "county": "Franklin County",
    "state": "VA",
    "city": "Rocky Mount"
  },
  {
    "value": "24153",
    "label": "Salem - VA 24153",
    "county": "Salem city",
    "state": "VA",
    "city": "Salem"
  },
  {
    "value": "24161",
    "label": "Sandy Level - VA 24161",
    "county": "Pittsylvania County",
    "state": "VA",
    "city": "Sandy Level"
  },
  {
    "value": "24162",
    "label": "Shawsville - VA 24162",
    "county": "Montgomery County",
    "state": "VA",
    "city": "Shawsville"
  },
  {
    "value": "24165",
    "label": "Spencer - VA 24165",
    "county": "Henry County",
    "state": "VA",
    "city": "Spencer"
  },
  {
    "value": "24167",
    "label": "Staffordsville - VA 24167",
    "county": "Giles County",
    "state": "VA",
    "city": "Staffordsville"
  },
  {
    "value": "24168",
    "label": "Stanleytown - VA 24168",
    "county": "Henry County",
    "state": "VA",
    "city": "Stanleytown"
  },
  {
    "value": "24171",
    "label": "Stuart - VA 24171",
    "county": "Patrick County",
    "state": "VA",
    "city": "Stuart"
  },
  {
    "value": "24174",
    "label": "Thaxton - VA 24174",
    "county": "Bedford County",
    "state": "VA",
    "city": "Thaxton"
  },
  {
    "value": "24175",
    "label": "Troutville - VA 24175",
    "county": "Botetourt County",
    "state": "VA",
    "city": "Troutville"
  },
  {
    "value": "24176",
    "label": "Union Hall - VA 24176",
    "county": "Franklin County",
    "state": "VA",
    "city": "Union Hall"
  },
  {
    "value": "24179",
    "label": "Vinton - VA 24179",
    "county": "Roanoke County",
    "state": "VA",
    "city": "Vinton"
  },
  {
    "value": "24184",
    "label": "Wirtz - VA 24184",
    "county": "Franklin County",
    "state": "VA",
    "city": "Wirtz"
  },
  {
    "value": "24185",
    "label": "Woolwine - VA 24185",
    "county": "Patrick County",
    "state": "VA",
    "city": "Woolwine"
  },
  {
    "value": "24201",
    "label": "Bristol - VA 24201",
    "county": "Bristol city",
    "state": "VA",
    "city": "Bristol"
  },
  {
    "value": "24202",
    "label": "Bristol - VA 24202",
    "county": "Washington County",
    "state": "VA",
    "city": "Bristol"
  },
  {
    "value": "24210",
    "label": "Abingdon - VA 24210",
    "county": "Washington County",
    "state": "VA",
    "city": "Abingdon"
  },
  {
    "value": "24211",
    "label": "Abingdon - VA 24211",
    "county": "Washington County",
    "state": "VA",
    "city": "Abingdon"
  },
  {
    "value": "24216",
    "label": "Appalachia - VA 24216",
    "county": "Wise County",
    "state": "VA",
    "city": "Appalachia"
  },
  {
    "value": "24217",
    "label": "Bee - VA 24217",
    "county": "Dickenson County",
    "state": "VA",
    "city": "Bee"
  },
  {
    "value": "24219",
    "label": "Big Stone Gap - VA 24219",
    "county": "Wise County",
    "state": "VA",
    "city": "Big Stone Gap"
  },
  {
    "value": "24220",
    "label": "Birchleaf - VA 24220",
    "county": "Dickenson County",
    "state": "VA",
    "city": "Birchleaf"
  },
  {
    "value": "24221",
    "label": "Blackwater - VA 24221",
    "county": "Lee County",
    "state": "VA",
    "city": "Blackwater"
  },
  {
    "value": "24224",
    "label": "Castlewood - VA 24224",
    "county": "Russell County",
    "state": "VA",
    "city": "Castlewood"
  },
  {
    "value": "24225",
    "label": "Cleveland - VA 24225",
    "county": "Russell County",
    "state": "VA",
    "city": "Cleveland"
  },
  {
    "value": "24226",
    "label": "Clinchco - VA 24226",
    "county": "Dickenson County",
    "state": "VA",
    "city": "Clinchco"
  },
  {
    "value": "24228",
    "label": "Clintwood - VA 24228",
    "county": "Dickenson County",
    "state": "VA",
    "city": "Clintwood"
  },
  {
    "value": "24230",
    "label": "Coeburn - VA 24230",
    "county": "Wise County",
    "state": "VA",
    "city": "Coeburn"
  },
  {
    "value": "24236",
    "label": "Damascus - VA 24236",
    "county": "Washington County",
    "state": "VA",
    "city": "Damascus"
  },
  {
    "value": "24237",
    "label": "Dante - VA 24237",
    "county": "Dickenson County",
    "state": "VA",
    "city": "Dante"
  },
  {
    "value": "24239",
    "label": "Davenport - VA 24239",
    "county": "Buchanan County",
    "state": "VA",
    "city": "Davenport"
  },
  {
    "value": "24243",
    "label": "Dryden - VA 24243",
    "county": "Lee County",
    "state": "VA",
    "city": "Dryden"
  },
  {
    "value": "24244",
    "label": "Duffield - VA 24244",
    "county": "Scott County",
    "state": "VA",
    "city": "Duffield"
  },
  {
    "value": "24245",
    "label": "Dungannon - VA 24245",
    "county": "Scott County",
    "state": "VA",
    "city": "Dungannon"
  },
  {
    "value": "24248",
    "label": "Ewing - VA 24248",
    "county": "Lee County",
    "state": "VA",
    "city": "Ewing"
  },
  {
    "value": "24250",
    "label": "Fort Blackmore - VA 24250",
    "county": "Scott County",
    "state": "VA",
    "city": "Fort Blackmore"
  },
  {
    "value": "24251",
    "label": "Gate City - VA 24251",
    "county": "Scott County",
    "state": "VA",
    "city": "Gate City"
  },
  {
    "value": "24256",
    "label": "Haysi - VA 24256",
    "county": "Dickenson County",
    "state": "VA",
    "city": "Haysi"
  },
  {
    "value": "24258",
    "label": "Hiltons - VA 24258",
    "county": "Scott County",
    "state": "VA",
    "city": "Hiltons"
  },
  {
    "value": "24260",
    "label": "Honaker - VA 24260",
    "county": "Russell County",
    "state": "VA",
    "city": "Honaker"
  },
  {
    "value": "24263",
    "label": "Jonesville - VA 24263",
    "county": "Lee County",
    "state": "VA",
    "city": "Jonesville"
  },
  {
    "value": "24265",
    "label": "Keokee - VA 24265",
    "county": "Lee County",
    "state": "VA",
    "city": "Keokee"
  },
  {
    "value": "24266",
    "label": "Lebanon - VA 24266",
    "county": "Russell County",
    "state": "VA",
    "city": "Lebanon"
  },
  {
    "value": "24269",
    "label": "Mc Clure - VA 24269",
    "county": "Dickenson County",
    "state": "VA",
    "city": "Mc Clure"
  },
  {
    "value": "24270",
    "label": "Mendota - VA 24270",
    "county": "Washington County",
    "state": "VA",
    "city": "Mendota"
  },
  {
    "value": "24271",
    "label": "Nickelsville - VA 24271",
    "county": "Scott County",
    "state": "VA",
    "city": "Nickelsville"
  },
  {
    "value": "24272",
    "label": "Nora - VA 24272",
    "county": "Dickenson County",
    "state": "VA",
    "city": "Nora"
  },
  {
    "value": "24273",
    "label": "Norton - VA 24273",
    "county": "Norton city",
    "state": "VA",
    "city": "Norton"
  },
  {
    "value": "24277",
    "label": "Pennington Gap - VA 24277",
    "county": "Lee County",
    "state": "VA",
    "city": "Pennington Gap"
  },
  {
    "value": "24279",
    "label": "Pound - VA 24279",
    "county": "Wise County",
    "state": "VA",
    "city": "Pound"
  },
  {
    "value": "24280",
    "label": "Rosedale - VA 24280",
    "county": "Russell County",
    "state": "VA",
    "city": "Rosedale"
  },
  {
    "value": "24281",
    "label": "Rose Hill - VA 24281",
    "county": "Lee County",
    "state": "VA",
    "city": "Rose Hill"
  },
  {
    "value": "24282",
    "label": "Saint Charles - VA 24282",
    "county": "Lee County",
    "state": "VA",
    "city": "Saint Charles"
  },
  {
    "value": "24283",
    "label": "Saint Paul - VA 24283",
    "county": "Wise County",
    "state": "VA",
    "city": "Saint Paul"
  },
  {
    "value": "24290",
    "label": "Weber City - VA 24290",
    "county": "Scott County",
    "state": "VA",
    "city": "Weber City"
  },
  {
    "value": "24292",
    "label": "Whitetop - VA 24292",
    "county": "Grayson County",
    "state": "VA",
    "city": "Whitetop"
  },
  {
    "value": "24293",
    "label": "Wise - VA 24293",
    "county": "Wise County",
    "state": "VA",
    "city": "Wise"
  },
  {
    "value": "24301",
    "label": "Pulaski - VA 24301",
    "county": "Pulaski County",
    "state": "VA",
    "city": "Pulaski"
  },
  {
    "value": "24311",
    "label": "Atkins - VA 24311",
    "county": "Smyth County",
    "state": "VA",
    "city": "Atkins"
  },
  {
    "value": "24312",
    "label": "Austinville - VA 24312",
    "county": "Carroll County",
    "state": "VA",
    "city": "Austinville"
  },
  {
    "value": "24313",
    "label": "Barren Springs - VA 24313",
    "county": "Wythe County",
    "state": "VA",
    "city": "Barren Springs"
  },
  {
    "value": "24314",
    "label": "Bastian - VA 24314",
    "county": "Bland County",
    "state": "VA",
    "city": "Bastian"
  },
  {
    "value": "24315",
    "label": "Bland - VA 24315",
    "county": "Bland County",
    "state": "VA",
    "city": "Bland"
  },
  {
    "value": "24316",
    "label": "Broadford - VA 24316",
    "county": "Tazewell County",
    "state": "VA",
    "city": "Broadford"
  },
  {
    "value": "24317",
    "label": "Cana - VA 24317",
    "county": "Carroll County",
    "state": "VA",
    "city": "Cana"
  },
  {
    "value": "24318",
    "label": "Ceres - VA 24318",
    "county": "Bland County",
    "state": "VA",
    "city": "Ceres"
  },
  {
    "value": "24319",
    "label": "Chilhowie - VA 24319",
    "county": "Smyth County",
    "state": "VA",
    "city": "Chilhowie"
  },
  {
    "value": "24322",
    "label": "Cripple Creek - VA 24322",
    "county": "Wythe County",
    "state": "VA",
    "city": "Cripple Creek"
  },
  {
    "value": "24323",
    "label": "Crockett - VA 24323",
    "county": "Wythe County",
    "state": "VA",
    "city": "Crockett"
  },
  {
    "value": "24324",
    "label": "Draper - VA 24324",
    "county": "Pulaski County",
    "state": "VA",
    "city": "Draper"
  },
  {
    "value": "24325",
    "label": "Dugspur - VA 24325",
    "county": "Carroll County",
    "state": "VA",
    "city": "Dugspur"
  },
  {
    "value": "24326",
    "label": "Elk Creek - VA 24326",
    "county": "Grayson County",
    "state": "VA",
    "city": "Elk Creek"
  },
  {
    "value": "24328",
    "label": "Fancy Gap - VA 24328",
    "county": "Carroll County",
    "state": "VA",
    "city": "Fancy Gap"
  },
  {
    "value": "24330",
    "label": "Fries - VA 24330",
    "county": "Grayson County",
    "state": "VA",
    "city": "Fries"
  },
  {
    "value": "24333",
    "label": "Galax - VA 24333",
    "county": "Galax city",
    "state": "VA",
    "city": "Galax"
  },
  {
    "value": "24340",
    "label": "Glade Spring - VA 24340",
    "county": "Washington County",
    "state": "VA",
    "city": "Glade Spring"
  },
  {
    "value": "24343",
    "label": "Hillsville - VA 24343",
    "county": "Carroll County",
    "state": "VA",
    "city": "Hillsville"
  },
  {
    "value": "24347",
    "label": "Hiwassee - VA 24347",
    "county": "Pulaski County",
    "state": "VA",
    "city": "Hiwassee"
  },
  {
    "value": "24348",
    "label": "Independence - VA 24348",
    "county": "Grayson County",
    "state": "VA",
    "city": "Independence"
  },
  {
    "value": "24350",
    "label": "Ivanhoe - VA 24350",
    "county": "Wythe County",
    "state": "VA",
    "city": "Ivanhoe"
  },
  {
    "value": "24351",
    "label": "Lambsburg - VA 24351",
    "county": "Carroll County",
    "state": "VA",
    "city": "Lambsburg"
  },
  {
    "value": "24352",
    "label": "Laurel Fork - VA 24352",
    "county": "Carroll County",
    "state": "VA",
    "city": "Laurel Fork"
  },
  {
    "value": "24354",
    "label": "Marion - VA 24354",
    "county": "Smyth County",
    "state": "VA",
    "city": "Marion"
  },
  {
    "value": "24360",
    "label": "Max Meadows - VA 24360",
    "county": "Wythe County",
    "state": "VA",
    "city": "Max Meadows"
  },
  {
    "value": "24361",
    "label": "Meadowview - VA 24361",
    "county": "Washington County",
    "state": "VA",
    "city": "Meadowview"
  },
  {
    "value": "24363",
    "label": "Mouth Of Wilson - VA 24363",
    "county": "Grayson County",
    "state": "VA",
    "city": "Mouth Of Wilson"
  },
  {
    "value": "24366",
    "label": "Rocky Gap - VA 24366",
    "county": "Bland County",
    "state": "VA",
    "city": "Rocky Gap"
  },
  {
    "value": "24368",
    "label": "Rural Retreat - VA 24368",
    "county": "Wythe County",
    "state": "VA",
    "city": "Rural Retreat"
  },
  {
    "value": "24370",
    "label": "Saltville - VA 24370",
    "county": "Smyth County",
    "state": "VA",
    "city": "Saltville"
  },
  {
    "value": "24374",
    "label": "Speedwell - VA 24374",
    "county": "Wythe County",
    "state": "VA",
    "city": "Speedwell"
  },
  {
    "value": "24375",
    "label": "Sugar Grove - VA 24375",
    "county": "Smyth County",
    "state": "VA",
    "city": "Sugar Grove"
  },
  {
    "value": "24377",
    "label": "Tannersville - VA 24377",
    "county": "Tazewell County",
    "state": "VA",
    "city": "Tannersville"
  },
  {
    "value": "24378",
    "label": "Troutdale - VA 24378",
    "county": "Grayson County",
    "state": "VA",
    "city": "Troutdale"
  },
  {
    "value": "24380",
    "label": "Willis - VA 24380",
    "county": "Floyd County",
    "state": "VA",
    "city": "Willis"
  },
  {
    "value": "24381",
    "label": "Woodlawn - VA 24381",
    "county": "Carroll County",
    "state": "VA",
    "city": "Woodlawn"
  },
  {
    "value": "24382",
    "label": "Wytheville - VA 24382",
    "county": "Wythe County",
    "state": "VA",
    "city": "Wytheville"
  },
  {
    "value": "24401",
    "label": "Staunton - VA 24401",
    "county": "Staunton city",
    "state": "VA",
    "city": "Staunton"
  },
  {
    "value": "24413",
    "label": "Blue Grass - VA 24413",
    "county": "Highland County",
    "state": "VA",
    "city": "Blue Grass"
  },
  {
    "value": "24416",
    "label": "Buena Vista - VA 24416",
    "county": "Buena Vista city",
    "state": "VA",
    "city": "Buena Vista"
  },
  {
    "value": "24421",
    "label": "Churchville - VA 24421",
    "county": "Augusta County",
    "state": "VA",
    "city": "Churchville"
  },
  {
    "value": "24422",
    "label": "Clifton Forge - VA 24422",
    "county": "Alleghany County",
    "state": "VA",
    "city": "Clifton Forge"
  },
  {
    "value": "24426",
    "label": "Covington - VA 24426",
    "county": "Alleghany County",
    "state": "VA",
    "city": "Covington"
  },
  {
    "value": "24430",
    "label": "Craigsville - VA 24430",
    "county": "Augusta County",
    "state": "VA",
    "city": "Craigsville"
  },
  {
    "value": "24431",
    "label": "Crimora - VA 24431",
    "county": "Augusta County",
    "state": "VA",
    "city": "Crimora"
  },
  {
    "value": "24432",
    "label": "Deerfield - VA 24432",
    "county": "Augusta County",
    "state": "VA",
    "city": "Deerfield"
  },
  {
    "value": "24433",
    "label": "Doe Hill - VA 24433",
    "county": "Highland County",
    "state": "VA",
    "city": "Doe Hill"
  },
  {
    "value": "24435",
    "label": "Fairfield - VA 24435",
    "county": "Rockbridge County",
    "state": "VA",
    "city": "Fairfield"
  },
  {
    "value": "24437",
    "label": "Fort Defiance - VA 24437",
    "county": "Augusta County",
    "state": "VA",
    "city": "Fort Defiance"
  },
  {
    "value": "24439",
    "label": "Goshen - VA 24439",
    "county": "Rockbridge County",
    "state": "VA",
    "city": "Goshen"
  },
  {
    "value": "24440",
    "label": "Greenville - VA 24440",
    "county": "Augusta County",
    "state": "VA",
    "city": "Greenville"
  },
  {
    "value": "24441",
    "label": "Grottoes - VA 24441",
    "county": "Rockingham County",
    "state": "VA",
    "city": "Grottoes"
  },
  {
    "value": "24442",
    "label": "Head Waters - VA 24442",
    "county": "Highland County",
    "state": "VA",
    "city": "Head Waters"
  },
  {
    "value": "24445",
    "label": "Hot Springs - VA 24445",
    "county": "Bath County",
    "state": "VA",
    "city": "Hot Springs"
  },
  {
    "value": "24450",
    "label": "Lexington - VA 24450",
    "county": "Rockbridge County",
    "state": "VA",
    "city": "Lexington"
  },
  {
    "value": "24458",
    "label": "Mc Dowell - VA 24458",
    "county": "Highland County",
    "state": "VA",
    "city": "Mc Dowell"
  },
  {
    "value": "24459",
    "label": "Middlebrook - VA 24459",
    "county": "Augusta County",
    "state": "VA",
    "city": "Middlebrook"
  },
  {
    "value": "24460",
    "label": "Millboro - VA 24460",
    "county": "Bath County",
    "state": "VA",
    "city": "Millboro"
  },
  {
    "value": "24464",
    "label": "Montebello - VA 24464",
    "county": "Nelson County",
    "state": "VA",
    "city": "Montebello"
  },
  {
    "value": "24465",
    "label": "Monterey - VA 24465",
    "county": "Highland County",
    "state": "VA",
    "city": "Monterey"
  },
  {
    "value": "24467",
    "label": "Mount Sidney - VA 24467",
    "county": "Augusta County",
    "state": "VA",
    "city": "Mount Sidney"
  },
  {
    "value": "24468",
    "label": "Mustoe - VA 24468",
    "county": "Highland County",
    "state": "VA",
    "city": "Mustoe"
  },
  {
    "value": "24471",
    "label": "Port Republic - VA 24471",
    "county": "Rockingham County",
    "state": "VA",
    "city": "Port Republic"
  },
  {
    "value": "24472",
    "label": "Raphine - VA 24472",
    "county": "Rockbridge County",
    "state": "VA",
    "city": "Raphine"
  },
  {
    "value": "24473",
    "label": "Rockbridge Baths - VA 24473",
    "county": "Rockbridge County",
    "state": "VA",
    "city": "Rockbridge Baths"
  },
  {
    "value": "24476",
    "label": "Steeles Tavern - VA 24476",
    "county": "Augusta County",
    "state": "VA",
    "city": "Steeles Tavern"
  },
  {
    "value": "24477",
    "label": "Stuarts Draft - VA 24477",
    "county": "Augusta County",
    "state": "VA",
    "city": "Stuarts Draft"
  },
  {
    "value": "24479",
    "label": "Swoope - VA 24479",
    "county": "Augusta County",
    "state": "VA",
    "city": "Swoope"
  },
  {
    "value": "24482",
    "label": "Verona - VA 24482",
    "county": "Augusta County",
    "state": "VA",
    "city": "Verona"
  },
  {
    "value": "24483",
    "label": "Vesuvius - VA 24483",
    "county": "Rockbridge County",
    "state": "VA",
    "city": "Vesuvius"
  },
  {
    "value": "24484",
    "label": "Warm Springs - VA 24484",
    "county": "Bath County",
    "state": "VA",
    "city": "Warm Springs"
  },
  {
    "value": "24485",
    "label": "West Augusta - VA 24485",
    "county": "Augusta County",
    "state": "VA",
    "city": "West Augusta"
  },
  {
    "value": "24486",
    "label": "Weyers Cave - VA 24486",
    "county": "Augusta County",
    "state": "VA",
    "city": "Weyers Cave"
  },
  {
    "value": "24487",
    "label": "Williamsville - VA 24487",
    "county": "Bath County",
    "state": "VA",
    "city": "Williamsville"
  },
  {
    "value": "24501",
    "label": "Lynchburg - VA 24501",
    "county": "Lynchburg city",
    "state": "VA",
    "city": "Lynchburg"
  },
  {
    "value": "24502",
    "label": "Lynchburg - VA 24502",
    "county": "Lynchburg city",
    "state": "VA",
    "city": "Lynchburg"
  },
  {
    "value": "24503",
    "label": "Lynchburg - VA 24503",
    "county": "Lynchburg city",
    "state": "VA",
    "city": "Lynchburg"
  },
  {
    "value": "24504",
    "label": "Lynchburg - VA 24504",
    "county": "Lynchburg city",
    "state": "VA",
    "city": "Lynchburg"
  },
  {
    "value": "24517",
    "label": "Altavista - VA 24517",
    "county": "Campbell County",
    "state": "VA",
    "city": "Altavista"
  },
  {
    "value": "24520",
    "label": "Alton - VA 24520",
    "county": "Halifax County",
    "state": "VA",
    "city": "Alton"
  },
  {
    "value": "24521",
    "label": "Amherst - VA 24521",
    "county": "Amherst County",
    "state": "VA",
    "city": "Amherst"
  },
  {
    "value": "24522",
    "label": "Appomattox - VA 24522",
    "county": "Appomattox County",
    "state": "VA",
    "city": "Appomattox"
  },
  {
    "value": "24523",
    "label": "Bedford - VA 24523",
    "county": "Bedford County",
    "state": "VA",
    "city": "Bedford"
  },
  {
    "value": "24526",
    "label": "Big Island - VA 24526",
    "county": "Bedford County",
    "state": "VA",
    "city": "Big Island"
  },
  {
    "value": "24527",
    "label": "Blairs - VA 24527",
    "county": "Pittsylvania County",
    "state": "VA",
    "city": "Blairs"
  },
  {
    "value": "24528",
    "label": "Brookneal - VA 24528",
    "county": "Campbell County",
    "state": "VA",
    "city": "Brookneal"
  },
  {
    "value": "24529",
    "label": "Buffalo Junction - VA 24529",
    "county": "Mecklenburg County",
    "state": "VA",
    "city": "Buffalo Junction"
  },
  {
    "value": "24530",
    "label": "Callands - VA 24530",
    "county": "Pittsylvania County",
    "state": "VA",
    "city": "Callands"
  },
  {
    "value": "24531",
    "label": "Chatham - VA 24531",
    "county": "Pittsylvania County",
    "state": "VA",
    "city": "Chatham"
  },
  {
    "value": "24534",
    "label": "Clover - VA 24534",
    "county": "Halifax County",
    "state": "VA",
    "city": "Clover"
  },
  {
    "value": "24536",
    "label": "Coleman Falls - VA 24536",
    "county": "Bedford County",
    "state": "VA",
    "city": "Coleman Falls"
  },
  {
    "value": "24538",
    "label": "Concord - VA 24538",
    "county": "Campbell County",
    "state": "VA",
    "city": "Concord"
  },
  {
    "value": "24539",
    "label": "Crystal Hill - VA 24539",
    "county": "Halifax County",
    "state": "VA",
    "city": "Crystal Hill"
  },
  {
    "value": "24540",
    "label": "Danville - VA 24540",
    "county": "Danville city",
    "state": "VA",
    "city": "Danville"
  },
  {
    "value": "24541",
    "label": "Danville - VA 24541",
    "county": "Danville city",
    "state": "VA",
    "city": "Danville"
  },
  {
    "value": "24549",
    "label": "Dry Fork - VA 24549",
    "county": "Pittsylvania County",
    "state": "VA",
    "city": "Dry Fork"
  },
  {
    "value": "24550",
    "label": "Evington - VA 24550",
    "county": "Campbell County",
    "state": "VA",
    "city": "Evington"
  },
  {
    "value": "24551",
    "label": "Forest - VA 24551",
    "county": "Bedford County",
    "state": "VA",
    "city": "Forest"
  },
  {
    "value": "24553",
    "label": "Gladstone - VA 24553",
    "county": "Nelson County",
    "state": "VA",
    "city": "Gladstone"
  },
  {
    "value": "24554",
    "label": "Gladys - VA 24554",
    "county": "Campbell County",
    "state": "VA",
    "city": "Gladys"
  },
  {
    "value": "24555",
    "label": "Glasgow - VA 24555",
    "county": "Rockbridge County",
    "state": "VA",
    "city": "Glasgow"
  },
  {
    "value": "24556",
    "label": "Goode - VA 24556",
    "county": "Bedford County",
    "state": "VA",
    "city": "Goode"
  },
  {
    "value": "24557",
    "label": "Gretna - VA 24557",
    "county": "Pittsylvania County",
    "state": "VA",
    "city": "Gretna"
  },
  {
    "value": "24558",
    "label": "Halifax - VA 24558",
    "county": "Halifax County",
    "state": "VA",
    "city": "Halifax"
  },
  {
    "value": "24562",
    "label": "Howardsville - VA 24562",
    "county": "Buckingham County",
    "state": "VA",
    "city": "Howardsville"
  },
  {
    "value": "24563",
    "label": "Hurt - VA 24563",
    "county": "Pittsylvania County",
    "state": "VA",
    "city": "Hurt"
  },
  {
    "value": "24565",
    "label": "Java - VA 24565",
    "county": "Pittsylvania County",
    "state": "VA",
    "city": "Java"
  },
  {
    "value": "24566",
    "label": "Keeling - VA 24566",
    "county": "Pittsylvania County",
    "state": "VA",
    "city": "Keeling"
  },
  {
    "value": "24569",
    "label": "Long Island - VA 24569",
    "county": "Pittsylvania County",
    "state": "VA",
    "city": "Long Island"
  },
  {
    "value": "24570",
    "label": "Lowry - VA 24570",
    "county": "Bedford County",
    "state": "VA",
    "city": "Lowry"
  },
  {
    "value": "24571",
    "label": "Lynch Station - VA 24571",
    "county": "Campbell County",
    "state": "VA",
    "city": "Lynch Station"
  },
  {
    "value": "24572",
    "label": "Madison Heights - VA 24572",
    "county": "Amherst County",
    "state": "VA",
    "city": "Madison Heights"
  },
  {
    "value": "24574",
    "label": "Monroe - VA 24574",
    "county": "Amherst County",
    "state": "VA",
    "city": "Monroe"
  },
  {
    "value": "24577",
    "label": "Nathalie - VA 24577",
    "county": "Halifax County",
    "state": "VA",
    "city": "Nathalie"
  },
  {
    "value": "24578",
    "label": "Natural Bridge - VA 24578",
    "county": "Rockbridge County",
    "state": "VA",
    "city": "Natural Bridge"
  },
  {
    "value": "24579",
    "label": "Natural Bridge Station - VA 24579",
    "county": "Rockbridge County",
    "state": "VA",
    "city": "Natural Bridge Station"
  },
  {
    "value": "24580",
    "label": "Nelson - VA 24580",
    "county": "Mecklenburg County",
    "state": "VA",
    "city": "Nelson"
  },
  {
    "value": "24581",
    "label": "Norwood - VA 24581",
    "county": "Nelson County",
    "state": "VA",
    "city": "Norwood"
  },
  {
    "value": "24586",
    "label": "Ringgold - VA 24586",
    "county": "Pittsylvania County",
    "state": "VA",
    "city": "Ringgold"
  },
  {
    "value": "24588",
    "label": "Rustburg - VA 24588",
    "county": "Campbell County",
    "state": "VA",
    "city": "Rustburg"
  },
  {
    "value": "24589",
    "label": "Scottsburg - VA 24589",
    "county": "Halifax County",
    "state": "VA",
    "city": "Scottsburg"
  },
  {
    "value": "24590",
    "label": "Scottsville - VA 24590",
    "county": "Albemarle County",
    "state": "VA",
    "city": "Scottsville"
  },
  {
    "value": "24592",
    "label": "South Boston - VA 24592",
    "county": "Halifax County",
    "state": "VA",
    "city": "South Boston"
  },
  {
    "value": "24593",
    "label": "Spout Spring - VA 24593",
    "county": "Appomattox County",
    "state": "VA",
    "city": "Spout Spring"
  },
  {
    "value": "24594",
    "label": "Sutherlin - VA 24594",
    "county": "Pittsylvania County",
    "state": "VA",
    "city": "Sutherlin"
  },
  {
    "value": "24597",
    "label": "Vernon Hill - VA 24597",
    "county": "Halifax County",
    "state": "VA",
    "city": "Vernon Hill"
  },
  {
    "value": "24598",
    "label": "Virgilina - VA 24598",
    "county": "Halifax County",
    "state": "VA",
    "city": "Virgilina"
  },
  {
    "value": "24599",
    "label": "Wingina - VA 24599",
    "county": "Buckingham County",
    "state": "VA",
    "city": "Wingina"
  },
  {
    "value": "24602",
    "label": "Bandy - VA 24602",
    "county": "Tazewell County",
    "state": "VA",
    "city": "Bandy"
  },
  {
    "value": "24603",
    "label": "Big Rock - VA 24603",
    "county": "Buchanan County",
    "state": "VA",
    "city": "Big Rock"
  },
  {
    "value": "24605",
    "label": "Bluefield - VA 24605",
    "county": "Tazewell County",
    "state": "VA",
    "city": "Bluefield"
  },
  {
    "value": "24609",
    "label": "Cedar Bluff - VA 24609",
    "county": "Tazewell County",
    "state": "VA",
    "city": "Cedar Bluff"
  },
  {
    "value": "24613",
    "label": "Falls Mills - VA 24613",
    "county": "Tazewell County",
    "state": "VA",
    "city": "Falls Mills"
  },
  {
    "value": "24614",
    "label": "Grundy - VA 24614",
    "county": "Buchanan County",
    "state": "VA",
    "city": "Grundy"
  },
  {
    "value": "24620",
    "label": "Hurley - VA 24620",
    "county": "Buchanan County",
    "state": "VA",
    "city": "Hurley"
  },
  {
    "value": "24622",
    "label": "Jewell Ridge - VA 24622",
    "county": "Buchanan County",
    "state": "VA",
    "city": "Jewell Ridge"
  },
  {
    "value": "24628",
    "label": "Maxie - VA 24628",
    "county": "Buchanan County",
    "state": "VA",
    "city": "Maxie"
  },
  {
    "value": "24630",
    "label": "North Tazewell - VA 24630",
    "county": "Tazewell County",
    "state": "VA",
    "city": "North Tazewell"
  },
  {
    "value": "24631",
    "label": "Oakwood - VA 24631",
    "county": "Buchanan County",
    "state": "VA",
    "city": "Oakwood"
  },
  {
    "value": "24634",
    "label": "Pilgrims Knob - VA 24634",
    "county": "Buchanan County",
    "state": "VA",
    "city": "Pilgrims Knob"
  },
  {
    "value": "24637",
    "label": "Pounding Mill - VA 24637",
    "county": "Tazewell County",
    "state": "VA",
    "city": "Pounding Mill"
  },
  {
    "value": "24639",
    "label": "Raven - VA 24639",
    "county": "Tazewell County",
    "state": "VA",
    "city": "Raven"
  },
  {
    "value": "24641",
    "label": "Richlands - VA 24641",
    "county": "Tazewell County",
    "state": "VA",
    "city": "Richlands"
  },
  {
    "value": "24646",
    "label": "Rowe - VA 24646",
    "county": "Buchanan County",
    "state": "VA",
    "city": "Rowe"
  },
  {
    "value": "24649",
    "label": "Swords Creek - VA 24649",
    "county": "Russell County",
    "state": "VA",
    "city": "Swords Creek"
  },
  {
    "value": "24651",
    "label": "Tazewell - VA 24651",
    "county": "Tazewell County",
    "state": "VA",
    "city": "Tazewell"
  },
  {
    "value": "24656",
    "label": "Vansant - VA 24656",
    "county": "Buchanan County",
    "state": "VA",
    "city": "Vansant"
  },
  {
    "value": "24657",
    "label": "Whitewood - VA 24657",
    "county": "Buchanan County",
    "state": "VA",
    "city": "Whitewood"
  },
  {
    "value": "98001",
    "label": "Auburn - WA 98001",
    "county": "King County",
    "state": "WA",
    "city": "Auburn"
  },
  {
    "value": "98002",
    "label": "Auburn - WA 98002",
    "county": "King County",
    "state": "WA",
    "city": "Auburn"
  },
  {
    "value": "98003",
    "label": "Federal Way - WA 98003",
    "county": "King County",
    "state": "WA",
    "city": "Federal Way"
  },
  {
    "value": "98004",
    "label": "Bellevue - WA 98004",
    "county": "King County",
    "state": "WA",
    "city": "Bellevue"
  },
  {
    "value": "98005",
    "label": "Bellevue - WA 98005",
    "county": "King County",
    "state": "WA",
    "city": "Bellevue"
  },
  {
    "value": "98006",
    "label": "Bellevue - WA 98006",
    "county": "King County",
    "state": "WA",
    "city": "Bellevue"
  },
  {
    "value": "98007",
    "label": "Bellevue - WA 98007",
    "county": "King County",
    "state": "WA",
    "city": "Bellevue"
  },
  {
    "value": "98008",
    "label": "Bellevue - WA 98008",
    "county": "King County",
    "state": "WA",
    "city": "Bellevue"
  },
  {
    "value": "98010",
    "label": "Black Diamond - WA 98010",
    "county": "King County",
    "state": "WA",
    "city": "Black Diamond"
  },
  {
    "value": "98011",
    "label": "Bothell - WA 98011",
    "county": "King County",
    "state": "WA",
    "city": "Bothell"
  },
  {
    "value": "98012",
    "label": "Bothell - WA 98012",
    "county": "Snohomish County",
    "state": "WA",
    "city": "Bothell"
  },
  {
    "value": "98014",
    "label": "Carnation - WA 98014",
    "county": "King County",
    "state": "WA",
    "city": "Carnation"
  },
  {
    "value": "98019",
    "label": "Duvall - WA 98019",
    "county": "King County",
    "state": "WA",
    "city": "Duvall"
  },
  {
    "value": "98020",
    "label": "Edmonds - WA 98020",
    "county": "Snohomish County",
    "state": "WA",
    "city": "Edmonds"
  },
  {
    "value": "98021",
    "label": "Bothell - WA 98021",
    "county": "Snohomish County",
    "state": "WA",
    "city": "Bothell"
  },
  {
    "value": "98022",
    "label": "Enumclaw - WA 98022",
    "county": "King County",
    "state": "WA",
    "city": "Enumclaw"
  },
  {
    "value": "98023",
    "label": "Federal Way - WA 98023",
    "county": "King County",
    "state": "WA",
    "city": "Federal Way"
  },
  {
    "value": "98024",
    "label": "Fall City - WA 98024",
    "county": "King County",
    "state": "WA",
    "city": "Fall City"
  },
  {
    "value": "98026",
    "label": "Edmonds - WA 98026",
    "county": "Snohomish County",
    "state": "WA",
    "city": "Edmonds"
  },
  {
    "value": "98027",
    "label": "Issaquah - WA 98027",
    "county": "King County",
    "state": "WA",
    "city": "Issaquah"
  },
  {
    "value": "98028",
    "label": "Kenmore - WA 98028",
    "county": "King County",
    "state": "WA",
    "city": "Kenmore"
  },
  {
    "value": "98029",
    "label": "Issaquah - WA 98029",
    "county": "King County",
    "state": "WA",
    "city": "Issaquah"
  },
  {
    "value": "98030",
    "label": "Kent - WA 98030",
    "county": "King County",
    "state": "WA",
    "city": "Kent"
  },
  {
    "value": "98031",
    "label": "Kent - WA 98031",
    "county": "King County",
    "state": "WA",
    "city": "Kent"
  },
  {
    "value": "98032",
    "label": "Kent - WA 98032",
    "county": "King County",
    "state": "WA",
    "city": "Kent"
  },
  {
    "value": "98033",
    "label": "Kirkland - WA 98033",
    "county": "King County",
    "state": "WA",
    "city": "Kirkland"
  },
  {
    "value": "98034",
    "label": "Kirkland - WA 98034",
    "county": "King County",
    "state": "WA",
    "city": "Kirkland"
  },
  {
    "value": "98036",
    "label": "Lynnwood - WA 98036",
    "county": "Snohomish County",
    "state": "WA",
    "city": "Lynnwood"
  },
  {
    "value": "98037",
    "label": "Lynnwood - WA 98037",
    "county": "Snohomish County",
    "state": "WA",
    "city": "Lynnwood"
  },
  {
    "value": "98038",
    "label": "Maple Valley - WA 98038",
    "county": "King County",
    "state": "WA",
    "city": "Maple Valley"
  },
  {
    "value": "98039",
    "label": "Medina - WA 98039",
    "county": "King County",
    "state": "WA",
    "city": "Medina"
  },
  {
    "value": "98040",
    "label": "Mercer Island - WA 98040",
    "county": "King County",
    "state": "WA",
    "city": "Mercer Island"
  },
  {
    "value": "98042",
    "label": "Kent - WA 98042",
    "county": "King County",
    "state": "WA",
    "city": "Kent"
  },
  {
    "value": "98043",
    "label": "Mountlake Terrace - WA 98043",
    "county": "Snohomish County",
    "state": "WA",
    "city": "Mountlake Terrace"
  },
  {
    "value": "98045",
    "label": "North Bend - WA 98045",
    "county": "King County",
    "state": "WA",
    "city": "North Bend"
  },
  {
    "value": "98047",
    "label": "Pacific - WA 98047",
    "county": "King County",
    "state": "WA",
    "city": "Pacific"
  },
  {
    "value": "98051",
    "label": "Ravensdale - WA 98051",
    "county": "King County",
    "state": "WA",
    "city": "Ravensdale"
  },
  {
    "value": "98052",
    "label": "Redmond - WA 98052",
    "county": "King County",
    "state": "WA",
    "city": "Redmond"
  },
  {
    "value": "98053",
    "label": "Redmond - WA 98053",
    "county": "King County",
    "state": "WA",
    "city": "Redmond"
  },
  {
    "value": "98054",
    "label": "Redondo - WA 98054",
    "county": "King County",
    "state": "WA",
    "city": "Redondo"
  },
  {
    "value": "98055",
    "label": "Renton - WA 98055",
    "county": "King County",
    "state": "WA",
    "city": "Renton"
  },
  {
    "value": "98056",
    "label": "Renton - WA 98056",
    "county": "King County",
    "state": "WA",
    "city": "Renton"
  },
  {
    "value": "98057",
    "label": "Renton - WA 98057",
    "county": "King County",
    "state": "WA",
    "city": "Renton"
  },
  {
    "value": "98058",
    "label": "Renton - WA 98058",
    "county": "King County",
    "state": "WA",
    "city": "Renton"
  },
  {
    "value": "98059",
    "label": "Renton - WA 98059",
    "county": "King County",
    "state": "WA",
    "city": "Renton"
  },
  {
    "value": "98065",
    "label": "Snoqualmie - WA 98065",
    "county": "King County",
    "state": "WA",
    "city": "Snoqualmie"
  },
  {
    "value": "98070",
    "label": "Vashon - WA 98070",
    "county": "King County",
    "state": "WA",
    "city": "Vashon"
  },
  {
    "value": "98072",
    "label": "Woodinville - WA 98072",
    "county": "King County",
    "state": "WA",
    "city": "Woodinville"
  },
  {
    "value": "98074",
    "label": "Sammamish - WA 98074",
    "county": "King County",
    "state": "WA",
    "city": "Sammamish"
  },
  {
    "value": "98075",
    "label": "Sammamish - WA 98075",
    "county": "King County",
    "state": "WA",
    "city": "Sammamish"
  },
  {
    "value": "98077",
    "label": "Woodinville - WA 98077",
    "county": "King County",
    "state": "WA",
    "city": "Woodinville"
  },
  {
    "value": "98087",
    "label": "Lynnwood - WA 98087",
    "county": "Snohomish County",
    "state": "WA",
    "city": "Lynnwood"
  },
  {
    "value": "98092",
    "label": "Auburn - WA 98092",
    "county": "King County",
    "state": "WA",
    "city": "Auburn"
  },
  {
    "value": "98101",
    "label": "Seattle - WA 98101",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98102",
    "label": "Seattle - WA 98102",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98103",
    "label": "Seattle - WA 98103",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98104",
    "label": "Seattle - WA 98104",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98105",
    "label": "Seattle - WA 98105",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98106",
    "label": "Seattle - WA 98106",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98107",
    "label": "Seattle - WA 98107",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98108",
    "label": "Seattle - WA 98108",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98109",
    "label": "Seattle - WA 98109",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98110",
    "label": "Bainbridge Island - WA 98110",
    "county": "Kitsap County",
    "state": "WA",
    "city": "Bainbridge Island"
  },
  {
    "value": "98112",
    "label": "Seattle - WA 98112",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98115",
    "label": "Seattle - WA 98115",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98116",
    "label": "Seattle - WA 98116",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98117",
    "label": "Seattle - WA 98117",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98118",
    "label": "Seattle - WA 98118",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98119",
    "label": "Seattle - WA 98119",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98121",
    "label": "Seattle - WA 98121",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98122",
    "label": "Seattle - WA 98122",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98125",
    "label": "Seattle - WA 98125",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98126",
    "label": "Seattle - WA 98126",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98131",
    "label": "Seattle - WA 98131",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98132",
    "label": "Seattle - WA 98132",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98133",
    "label": "Seattle - WA 98133",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98134",
    "label": "Seattle - WA 98134",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98136",
    "label": "Seattle - WA 98136",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98144",
    "label": "Seattle - WA 98144",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98146",
    "label": "Seattle - WA 98146",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98148",
    "label": "Seattle - WA 98148",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98154",
    "label": "Seattle - WA 98154",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98155",
    "label": "Seattle - WA 98155",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98158",
    "label": "Seattle - WA 98158",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98161",
    "label": "Seattle - WA 98161",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98164",
    "label": "Seattle - WA 98164",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98166",
    "label": "Seattle - WA 98166",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98168",
    "label": "Seattle - WA 98168",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98171",
    "label": "Seattle - WA 98171",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98174",
    "label": "Seattle - WA 98174",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98177",
    "label": "Seattle - WA 98177",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98178",
    "label": "Seattle - WA 98178",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98188",
    "label": "Seattle - WA 98188",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98198",
    "label": "Seattle - WA 98198",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98199",
    "label": "Seattle - WA 98199",
    "county": "King County",
    "state": "WA",
    "city": "Seattle"
  },
  {
    "value": "98201",
    "label": "Everett - WA 98201",
    "county": "Snohomish County",
    "state": "WA",
    "city": "Everett"
  },
  {
    "value": "98203",
    "label": "Everett - WA 98203",
    "county": "Snohomish County",
    "state": "WA",
    "city": "Everett"
  },
  {
    "value": "98204",
    "label": "Everett - WA 98204",
    "county": "Snohomish County",
    "state": "WA",
    "city": "Everett"
  },
  {
    "value": "98205",
    "label": "Everett - WA 98205",
    "county": "Snohomish County",
    "state": "WA",
    "city": "Everett"
  },
  {
    "value": "98208",
    "label": "Everett - WA 98208",
    "county": "Snohomish County",
    "state": "WA",
    "city": "Everett"
  },
  {
    "value": "98220",
    "label": "Acme - WA 98220",
    "county": "Whatcom County",
    "state": "WA",
    "city": "Acme"
  },
  {
    "value": "98221",
    "label": "Anacortes - WA 98221",
    "county": "Skagit County",
    "state": "WA",
    "city": "Anacortes"
  },
  {
    "value": "98222",
    "label": "Blakely Island - WA 98222",
    "county": "San Juan County",
    "state": "WA",
    "city": "Blakely Island"
  },
  {
    "value": "98223",
    "label": "Arlington - WA 98223",
    "county": "Snohomish County",
    "state": "WA",
    "city": "Arlington"
  },
  {
    "value": "98225",
    "label": "Bellingham - WA 98225",
    "county": "Whatcom County",
    "state": "WA",
    "city": "Bellingham"
  },
  {
    "value": "98226",
    "label": "Bellingham - WA 98226",
    "county": "Whatcom County",
    "state": "WA",
    "city": "Bellingham"
  },
  {
    "value": "98229",
    "label": "Bellingham - WA 98229",
    "county": "Whatcom County",
    "state": "WA",
    "city": "Bellingham"
  },
  {
    "value": "98230",
    "label": "Blaine - WA 98230",
    "county": "Whatcom County",
    "state": "WA",
    "city": "Blaine"
  },
  {
    "value": "98232",
    "label": "Bow - WA 98232",
    "county": "Skagit County",
    "state": "WA",
    "city": "Bow"
  },
  {
    "value": "98233",
    "label": "Burlington - WA 98233",
    "county": "Skagit County",
    "state": "WA",
    "city": "Burlington"
  },
  {
    "value": "98236",
    "label": "Clinton - WA 98236",
    "county": "Island County",
    "state": "WA",
    "city": "Clinton"
  },
  {
    "value": "98237",
    "label": "Concrete - WA 98237",
    "county": "Skagit County",
    "state": "WA",
    "city": "Concrete"
  },
  {
    "value": "98239",
    "label": "Coupeville - WA 98239",
    "county": "Island County",
    "state": "WA",
    "city": "Coupeville"
  },
  {
    "value": "98240",
    "label": "Custer - WA 98240",
    "county": "Whatcom County",
    "state": "WA",
    "city": "Custer"
  },
  {
    "value": "98241",
    "label": "Darrington - WA 98241",
    "county": "Snohomish County",
    "state": "WA",
    "city": "Darrington"
  },
  {
    "value": "98244",
    "label": "Deming - WA 98244",
    "county": "Whatcom County",
    "state": "WA",
    "city": "Deming"
  },
  {
    "value": "98245",
    "label": "Eastsound - WA 98245",
    "county": "San Juan County",
    "state": "WA",
    "city": "Eastsound"
  },
  {
    "value": "98247",
    "label": "Everson - WA 98247",
    "county": "Whatcom County",
    "state": "WA",
    "city": "Everson"
  },
  {
    "value": "98248",
    "label": "Ferndale - WA 98248",
    "county": "Whatcom County",
    "state": "WA",
    "city": "Ferndale"
  },
  {
    "value": "98249",
    "label": "Freeland - WA 98249",
    "county": "Island County",
    "state": "WA",
    "city": "Freeland"
  },
  {
    "value": "98250",
    "label": "Friday Harbor - WA 98250",
    "county": "San Juan County",
    "state": "WA",
    "city": "Friday Harbor"
  },
  {
    "value": "98251",
    "label": "Gold Bar - WA 98251",
    "county": "Snohomish County",
    "state": "WA",
    "city": "Gold Bar"
  },
  {
    "value": "98252",
    "label": "Granite Falls - WA 98252",
    "county": "Snohomish County",
    "state": "WA",
    "city": "Granite Falls"
  },
  {
    "value": "98253",
    "label": "Greenbank - WA 98253",
    "county": "Island County",
    "state": "WA",
    "city": "Greenbank"
  },
  {
    "value": "98257",
    "label": "La Conner - WA 98257",
    "county": "Skagit County",
    "state": "WA",
    "city": "La Conner"
  },
  {
    "value": "98258",
    "label": "Lake Stevens - WA 98258",
    "county": "Snohomish County",
    "state": "WA",
    "city": "Lake Stevens"
  },
  {
    "value": "98260",
    "label": "Langley - WA 98260",
    "county": "Island County",
    "state": "WA",
    "city": "Langley"
  },
  {
    "value": "98261",
    "label": "Lopez Island - WA 98261",
    "county": "San Juan County",
    "state": "WA",
    "city": "Lopez Island"
  },
  {
    "value": "98262",
    "label": "Lummi Island - WA 98262",
    "county": "Whatcom County",
    "state": "WA",
    "city": "Lummi Island"
  },
  {
    "value": "98264",
    "label": "Lynden - WA 98264",
    "county": "Whatcom County",
    "state": "WA",
    "city": "Lynden"
  },
  {
    "value": "98266",
    "label": "Maple Falls - WA 98266",
    "county": "Whatcom County",
    "state": "WA",
    "city": "Maple Falls"
  },
  {
    "value": "98267",
    "label": "Marblemount - WA 98267",
    "county": "Skagit County",
    "state": "WA",
    "city": "Marblemount"
  },
  {
    "value": "98270",
    "label": "Marysville - WA 98270",
    "county": "Snohomish County",
    "state": "WA",
    "city": "Marysville"
  },
  {
    "value": "98271",
    "label": "Marysville - WA 98271",
    "county": "Snohomish County",
    "state": "WA",
    "city": "Marysville"
  },
  {
    "value": "98272",
    "label": "Monroe - WA 98272",
    "county": "Snohomish County",
    "state": "WA",
    "city": "Monroe"
  },
  {
    "value": "98273",
    "label": "Mount Vernon - WA 98273",
    "county": "Skagit County",
    "state": "WA",
    "city": "Mount Vernon"
  },
  {
    "value": "98274",
    "label": "Mount Vernon - WA 98274",
    "county": "Skagit County",
    "state": "WA",
    "city": "Mount Vernon"
  },
  {
    "value": "98275",
    "label": "Mukilteo - WA 98275",
    "county": "Snohomish County",
    "state": "WA",
    "city": "Mukilteo"
  },
  {
    "value": "98276",
    "label": "Nooksack - WA 98276",
    "county": "Whatcom County",
    "state": "WA",
    "city": "Nooksack"
  },
  {
    "value": "98277",
    "label": "Oak Harbor - WA 98277",
    "county": "Island County",
    "state": "WA",
    "city": "Oak Harbor"
  },
  {
    "value": "98279",
    "label": "Olga - WA 98279",
    "county": "San Juan County",
    "state": "WA",
    "city": "Olga"
  },
  {
    "value": "98281",
    "label": "Point Roberts - WA 98281",
    "county": "Whatcom County",
    "state": "WA",
    "city": "Point Roberts"
  },
  {
    "value": "98282",
    "label": "Camano Island - WA 98282",
    "county": "Island County",
    "state": "WA",
    "city": "Camano Island"
  },
  {
    "value": "98283",
    "label": "Rockport - WA 98283",
    "county": "Skagit County",
    "state": "WA",
    "city": "Rockport"
  },
  {
    "value": "98284",
    "label": "Sedro Woolley - WA 98284",
    "county": "Skagit County",
    "state": "WA",
    "city": "Sedro Woolley"
  },
  {
    "value": "98290",
    "label": "Snohomish - WA 98290",
    "county": "Snohomish County",
    "state": "WA",
    "city": "Snohomish"
  },
  {
    "value": "98292",
    "label": "Stanwood - WA 98292",
    "county": "Snohomish County",
    "state": "WA",
    "city": "Stanwood"
  },
  {
    "value": "98294",
    "label": "Sultan - WA 98294",
    "county": "Snohomish County",
    "state": "WA",
    "city": "Sultan"
  },
  {
    "value": "98295",
    "label": "Sumas - WA 98295",
    "county": "Whatcom County",
    "state": "WA",
    "city": "Sumas"
  },
  {
    "value": "98296",
    "label": "Snohomish - WA 98296",
    "county": "Snohomish County",
    "state": "WA",
    "city": "Snohomish"
  },
  {
    "value": "98303",
    "label": "Anderson Island - WA 98303",
    "county": "Pierce County",
    "state": "WA",
    "city": "Anderson Island"
  },
  {
    "value": "98304",
    "label": "Ashford - WA 98304",
    "county": "Pierce County",
    "state": "WA",
    "city": "Ashford"
  },
  {
    "value": "98305",
    "label": "Beaver - WA 98305",
    "county": "Clallam County",
    "state": "WA",
    "city": "Beaver"
  },
  {
    "value": "98310",
    "label": "Bremerton - WA 98310",
    "county": "Kitsap County",
    "state": "WA",
    "city": "Bremerton"
  },
  {
    "value": "98311",
    "label": "Bremerton - WA 98311",
    "county": "Kitsap County",
    "state": "WA",
    "city": "Bremerton"
  },
  {
    "value": "98312",
    "label": "Bremerton - WA 98312",
    "county": "Kitsap County",
    "state": "WA",
    "city": "Bremerton"
  },
  {
    "value": "98315",
    "label": "Silverdale - WA 98315",
    "county": "Kitsap County",
    "state": "WA",
    "city": "Silverdale"
  },
  {
    "value": "98320",
    "label": "Brinnon - WA 98320",
    "county": "Jefferson County",
    "state": "WA",
    "city": "Brinnon"
  },
  {
    "value": "98321",
    "label": "Buckley - WA 98321",
    "county": "Pierce County",
    "state": "WA",
    "city": "Buckley"
  },
  {
    "value": "98323",
    "label": "Carbonado - WA 98323",
    "county": "Pierce County",
    "state": "WA",
    "city": "Carbonado"
  },
  {
    "value": "98325",
    "label": "Chimacum - WA 98325",
    "county": "Jefferson County",
    "state": "WA",
    "city": "Chimacum"
  },
  {
    "value": "98326",
    "label": "Clallam Bay - WA 98326",
    "county": "Clallam County",
    "state": "WA",
    "city": "Clallam Bay"
  },
  {
    "value": "98327",
    "label": "Dupont - WA 98327",
    "county": "Pierce County",
    "state": "WA",
    "city": "Dupont"
  },
  {
    "value": "98328",
    "label": "Eatonville - WA 98328",
    "county": "Pierce County",
    "state": "WA",
    "city": "Eatonville"
  },
  {
    "value": "98329",
    "label": "Gig Harbor - WA 98329",
    "county": "Pierce County",
    "state": "WA",
    "city": "Gig Harbor"
  },
  {
    "value": "98330",
    "label": "Elbe - WA 98330",
    "county": "Pierce County",
    "state": "WA",
    "city": "Elbe"
  },
  {
    "value": "98331",
    "label": "Forks - WA 98331",
    "county": "Clallam County",
    "state": "WA",
    "city": "Forks"
  },
  {
    "value": "98332",
    "label": "Gig Harbor - WA 98332",
    "county": "Pierce County",
    "state": "WA",
    "city": "Gig Harbor"
  },
  {
    "value": "98333",
    "label": "Fox Island - WA 98333",
    "county": "Pierce County",
    "state": "WA",
    "city": "Fox Island"
  },
  {
    "value": "98335",
    "label": "Gig Harbor - WA 98335",
    "county": "Pierce County",
    "state": "WA",
    "city": "Gig Harbor"
  },
  {
    "value": "98336",
    "label": "Glenoma - WA 98336",
    "county": "Lewis County",
    "state": "WA",
    "city": "Glenoma"
  },
  {
    "value": "98337",
    "label": "Bremerton - WA 98337",
    "county": "Kitsap County",
    "state": "WA",
    "city": "Bremerton"
  },
  {
    "value": "98338",
    "label": "Graham - WA 98338",
    "county": "Pierce County",
    "state": "WA",
    "city": "Graham"
  },
  {
    "value": "98339",
    "label": "Port Hadlock - WA 98339",
    "county": "Jefferson County",
    "state": "WA",
    "city": "Port Hadlock"
  },
  {
    "value": "98340",
    "label": "Hansville - WA 98340",
    "county": "Kitsap County",
    "state": "WA",
    "city": "Hansville"
  },
  {
    "value": "98342",
    "label": "Indianola - WA 98342",
    "county": "Kitsap County",
    "state": "WA",
    "city": "Indianola"
  },
  {
    "value": "98346",
    "label": "Kingston - WA 98346",
    "county": "Kitsap County",
    "state": "WA",
    "city": "Kingston"
  },
  {
    "value": "98349",
    "label": "Lakebay - WA 98349",
    "county": "Pierce County",
    "state": "WA",
    "city": "Lakebay"
  },
  {
    "value": "98351",
    "label": "Longbranch - WA 98351",
    "county": "Pierce County",
    "state": "WA",
    "city": "Longbranch"
  },
  {
    "value": "98354",
    "label": "Milton - WA 98354",
    "county": "Pierce County",
    "state": "WA",
    "city": "Milton"
  },
  {
    "value": "98355",
    "label": "Mineral - WA 98355",
    "county": "Lewis County",
    "state": "WA",
    "city": "Mineral"
  },
  {
    "value": "98356",
    "label": "Morton - WA 98356",
    "county": "Lewis County",
    "state": "WA",
    "city": "Morton"
  },
  {
    "value": "98358",
    "label": "Nordland - WA 98358",
    "county": "Jefferson County",
    "state": "WA",
    "city": "Nordland"
  },
  {
    "value": "98359",
    "label": "Olalla - WA 98359",
    "county": "Kitsap County",
    "state": "WA",
    "city": "Olalla"
  },
  {
    "value": "98360",
    "label": "Orting - WA 98360",
    "county": "Pierce County",
    "state": "WA",
    "city": "Orting"
  },
  {
    "value": "98361",
    "label": "Packwood - WA 98361",
    "county": "Lewis County",
    "state": "WA",
    "city": "Packwood"
  },
  {
    "value": "98362",
    "label": "Port Angeles - WA 98362",
    "county": "Clallam County",
    "state": "WA",
    "city": "Port Angeles"
  },
  {
    "value": "98363",
    "label": "Port Angeles - WA 98363",
    "county": "Clallam County",
    "state": "WA",
    "city": "Port Angeles"
  },
  {
    "value": "98365",
    "label": "Port Ludlow - WA 98365",
    "county": "Jefferson County",
    "state": "WA",
    "city": "Port Ludlow"
  },
  {
    "value": "98366",
    "label": "Port Orchard - WA 98366",
    "county": "Kitsap County",
    "state": "WA",
    "city": "Port Orchard"
  },
  {
    "value": "98367",
    "label": "Port Orchard - WA 98367",
    "county": "Kitsap County",
    "state": "WA",
    "city": "Port Orchard"
  },
  {
    "value": "98368",
    "label": "Port Townsend - WA 98368",
    "county": "Jefferson County",
    "state": "WA",
    "city": "Port Townsend"
  },
  {
    "value": "98370",
    "label": "Poulsbo - WA 98370",
    "county": "Kitsap County",
    "state": "WA",
    "city": "Poulsbo"
  },
  {
    "value": "98371",
    "label": "Puyallup - WA 98371",
    "county": "Pierce County",
    "state": "WA",
    "city": "Puyallup"
  },
  {
    "value": "98372",
    "label": "Puyallup - WA 98372",
    "county": "Pierce County",
    "state": "WA",
    "city": "Puyallup"
  },
  {
    "value": "98373",
    "label": "Puyallup - WA 98373",
    "county": "Pierce County",
    "state": "WA",
    "city": "Puyallup"
  },
  {
    "value": "98374",
    "label": "Puyallup - WA 98374",
    "county": "Pierce County",
    "state": "WA",
    "city": "Puyallup"
  },
  {
    "value": "98375",
    "label": "Puyallup - WA 98375",
    "county": "Pierce County",
    "state": "WA",
    "city": "Puyallup"
  },
  {
    "value": "98376",
    "label": "Quilcene - WA 98376",
    "county": "Jefferson County",
    "state": "WA",
    "city": "Quilcene"
  },
  {
    "value": "98377",
    "label": "Randle - WA 98377",
    "county": "Lewis County",
    "state": "WA",
    "city": "Randle"
  },
  {
    "value": "98380",
    "label": "Seabeck - WA 98380",
    "county": "Kitsap County",
    "state": "WA",
    "city": "Seabeck"
  },
  {
    "value": "98381",
    "label": "Sekiu - WA 98381",
    "county": "Clallam County",
    "state": "WA",
    "city": "Sekiu"
  },
  {
    "value": "98382",
    "label": "Sequim - WA 98382",
    "county": "Clallam County",
    "state": "WA",
    "city": "Sequim"
  },
  {
    "value": "98383",
    "label": "Silverdale - WA 98383",
    "county": "Kitsap County",
    "state": "WA",
    "city": "Silverdale"
  },
  {
    "value": "98387",
    "label": "Spanaway - WA 98387",
    "county": "Pierce County",
    "state": "WA",
    "city": "Spanaway"
  },
  {
    "value": "98388",
    "label": "Steilacoom - WA 98388",
    "county": "Pierce County",
    "state": "WA",
    "city": "Steilacoom"
  },
  {
    "value": "98390",
    "label": "Sumner - WA 98390",
    "county": "Pierce County",
    "state": "WA",
    "city": "Sumner"
  },
  {
    "value": "98391",
    "label": "Bonney Lake - WA 98391",
    "county": "Pierce County",
    "state": "WA",
    "city": "Bonney Lake"
  },
  {
    "value": "98392",
    "label": "Suquamish - WA 98392",
    "county": "Kitsap County",
    "state": "WA",
    "city": "Suquamish"
  },
  {
    "value": "98394",
    "label": "Vaughn - WA 98394",
    "county": "Pierce County",
    "state": "WA",
    "city": "Vaughn"
  },
  {
    "value": "98402",
    "label": "Tacoma - WA 98402",
    "county": "Pierce County",
    "state": "WA",
    "city": "Tacoma"
  },
  {
    "value": "98403",
    "label": "Tacoma - WA 98403",
    "county": "Pierce County",
    "state": "WA",
    "city": "Tacoma"
  },
  {
    "value": "98404",
    "label": "Tacoma - WA 98404",
    "county": "Pierce County",
    "state": "WA",
    "city": "Tacoma"
  },
  {
    "value": "98405",
    "label": "Tacoma - WA 98405",
    "county": "Pierce County",
    "state": "WA",
    "city": "Tacoma"
  },
  {
    "value": "98406",
    "label": "Tacoma - WA 98406",
    "county": "Pierce County",
    "state": "WA",
    "city": "Tacoma"
  },
  {
    "value": "98407",
    "label": "Tacoma - WA 98407",
    "county": "Pierce County",
    "state": "WA",
    "city": "Tacoma"
  },
  {
    "value": "98408",
    "label": "Tacoma - WA 98408",
    "county": "Pierce County",
    "state": "WA",
    "city": "Tacoma"
  },
  {
    "value": "98409",
    "label": "Tacoma - WA 98409",
    "county": "Pierce County",
    "state": "WA",
    "city": "Tacoma"
  },
  {
    "value": "98413",
    "label": "Tacoma - WA 98413",
    "county": "Pierce County",
    "state": "WA",
    "city": "Tacoma"
  },
  {
    "value": "98418",
    "label": "Tacoma - WA 98418",
    "county": "Pierce County",
    "state": "WA",
    "city": "Tacoma"
  },
  {
    "value": "98421",
    "label": "Tacoma - WA 98421",
    "county": "Pierce County",
    "state": "WA",
    "city": "Tacoma"
  },
  {
    "value": "98422",
    "label": "Tacoma - WA 98422",
    "county": "Pierce County",
    "state": "WA",
    "city": "Tacoma"
  },
  {
    "value": "98424",
    "label": "Fife - WA 98424",
    "county": "Pierce County",
    "state": "WA",
    "city": "Fife"
  },
  {
    "value": "98433",
    "label": "Tacoma - WA 98433",
    "county": "Pierce County",
    "state": "WA",
    "city": "Tacoma"
  },
  {
    "value": "98439",
    "label": "Lakewood - WA 98439",
    "county": "Pierce County",
    "state": "WA",
    "city": "Lakewood"
  },
  {
    "value": "98442",
    "label": "Tacoma - WA 98442",
    "county": "Pierce County",
    "state": "WA",
    "city": "Tacoma"
  },
  {
    "value": "98443",
    "label": "Tacoma - WA 98443",
    "county": "Pierce County",
    "state": "WA",
    "city": "Tacoma"
  },
  {
    "value": "98444",
    "label": "Tacoma - WA 98444",
    "county": "Pierce County",
    "state": "WA",
    "city": "Tacoma"
  },
  {
    "value": "98445",
    "label": "Tacoma - WA 98445",
    "county": "Pierce County",
    "state": "WA",
    "city": "Tacoma"
  },
  {
    "value": "98446",
    "label": "Tacoma - WA 98446",
    "county": "Pierce County",
    "state": "WA",
    "city": "Tacoma"
  },
  {
    "value": "98465",
    "label": "Tacoma - WA 98465",
    "county": "Pierce County",
    "state": "WA",
    "city": "Tacoma"
  },
  {
    "value": "98466",
    "label": "Tacoma - WA 98466",
    "county": "Pierce County",
    "state": "WA",
    "city": "Tacoma"
  },
  {
    "value": "98467",
    "label": "University Place - WA 98467",
    "county": "Pierce County",
    "state": "WA",
    "city": "University Place"
  },
  {
    "value": "98492",
    "label": "Tacoma - WA 98492",
    "county": "Pierce County",
    "state": "WA",
    "city": "Tacoma"
  },
  {
    "value": "98498",
    "label": "Lakewood - WA 98498",
    "county": "Pierce County",
    "state": "WA",
    "city": "Lakewood"
  },
  {
    "value": "98499",
    "label": "Lakewood - WA 98499",
    "county": "Pierce County",
    "state": "WA",
    "city": "Lakewood"
  },
  {
    "value": "98501",
    "label": "Olympia - WA 98501",
    "county": "Thurston County",
    "state": "WA",
    "city": "Olympia"
  },
  {
    "value": "98502",
    "label": "Olympia - WA 98502",
    "county": "Thurston County",
    "state": "WA",
    "city": "Olympia"
  },
  {
    "value": "98503",
    "label": "Lacey - WA 98503",
    "county": "Thurston County",
    "state": "WA",
    "city": "Lacey"
  },
  {
    "value": "98506",
    "label": "Olympia - WA 98506",
    "county": "Thurston County",
    "state": "WA",
    "city": "Olympia"
  },
  {
    "value": "98512",
    "label": "Olympia - WA 98512",
    "county": "Thurston County",
    "state": "WA",
    "city": "Olympia"
  },
  {
    "value": "98513",
    "label": "Olympia - WA 98513",
    "county": "Thurston County",
    "state": "WA",
    "city": "Olympia"
  },
  {
    "value": "98516",
    "label": "Olympia - WA 98516",
    "county": "Thurston County",
    "state": "WA",
    "city": "Olympia"
  },
  {
    "value": "98520",
    "label": "Aberdeen - WA 98520",
    "county": "Grays Harbor County",
    "state": "WA",
    "city": "Aberdeen"
  },
  {
    "value": "98524",
    "label": "Allyn - WA 98524",
    "county": "Mason County",
    "state": "WA",
    "city": "Allyn"
  },
  {
    "value": "98526",
    "label": "Amanda Park - WA 98526",
    "county": "Grays Harbor County",
    "state": "WA",
    "city": "Amanda Park"
  },
  {
    "value": "98528",
    "label": "Belfair - WA 98528",
    "county": "Mason County",
    "state": "WA",
    "city": "Belfair"
  },
  {
    "value": "98531",
    "label": "Centralia - WA 98531",
    "county": "Lewis County",
    "state": "WA",
    "city": "Centralia"
  },
  {
    "value": "98532",
    "label": "Chehalis - WA 98532",
    "county": "Lewis County",
    "state": "WA",
    "city": "Chehalis"
  },
  {
    "value": "98533",
    "label": "Cinebar - WA 98533",
    "county": "Lewis County",
    "state": "WA",
    "city": "Cinebar"
  },
  {
    "value": "98535",
    "label": "Copalis Beach - WA 98535",
    "county": "Grays Harbor County",
    "state": "WA",
    "city": "Copalis Beach"
  },
  {
    "value": "98536",
    "label": "Copalis Crossing - WA 98536",
    "county": "Grays Harbor County",
    "state": "WA",
    "city": "Copalis Crossing"
  },
  {
    "value": "98537",
    "label": "Cosmopolis - WA 98537",
    "county": "Grays Harbor County",
    "state": "WA",
    "city": "Cosmopolis"
  },
  {
    "value": "98538",
    "label": "Curtis - WA 98538",
    "county": "Lewis County",
    "state": "WA",
    "city": "Curtis"
  },
  {
    "value": "98541",
    "label": "Elma - WA 98541",
    "county": "Grays Harbor County",
    "state": "WA",
    "city": "Elma"
  },
  {
    "value": "98542",
    "label": "Ethel - WA 98542",
    "county": "Lewis County",
    "state": "WA",
    "city": "Ethel"
  },
  {
    "value": "98546",
    "label": "Grapeview - WA 98546",
    "county": "Mason County",
    "state": "WA",
    "city": "Grapeview"
  },
  {
    "value": "98547",
    "label": "Grayland - WA 98547",
    "county": "Grays Harbor County",
    "state": "WA",
    "city": "Grayland"
  },
  {
    "value": "98548",
    "label": "Hoodsport - WA 98548",
    "county": "Mason County",
    "state": "WA",
    "city": "Hoodsport"
  },
  {
    "value": "98550",
    "label": "Hoquiam - WA 98550",
    "county": "Grays Harbor County",
    "state": "WA",
    "city": "Hoquiam"
  },
  {
    "value": "98552",
    "label": "Humptulips - WA 98552",
    "county": "Grays Harbor County",
    "state": "WA",
    "city": "Humptulips"
  },
  {
    "value": "98555",
    "label": "Lilliwaup - WA 98555",
    "county": "Mason County",
    "state": "WA",
    "city": "Lilliwaup"
  },
  {
    "value": "98557",
    "label": "Mccleary - WA 98557",
    "county": "Grays Harbor County",
    "state": "WA",
    "city": "Mccleary"
  },
  {
    "value": "98560",
    "label": "Matlock - WA 98560",
    "county": "Mason County",
    "state": "WA",
    "city": "Matlock"
  },
  {
    "value": "98563",
    "label": "Montesano - WA 98563",
    "county": "Grays Harbor County",
    "state": "WA",
    "city": "Montesano"
  },
  {
    "value": "98564",
    "label": "Mossyrock - WA 98564",
    "county": "Lewis County",
    "state": "WA",
    "city": "Mossyrock"
  },
  {
    "value": "98568",
    "label": "Oakville - WA 98568",
    "county": "Grays Harbor County",
    "state": "WA",
    "city": "Oakville"
  },
  {
    "value": "98569",
    "label": "Ocean Shores - WA 98569",
    "county": "Grays Harbor County",
    "state": "WA",
    "city": "Ocean Shores"
  },
  {
    "value": "98570",
    "label": "Onalaska - WA 98570",
    "county": "Lewis County",
    "state": "WA",
    "city": "Onalaska"
  },
  {
    "value": "98572",
    "label": "Pe Ell - WA 98572",
    "county": "Lewis County",
    "state": "WA",
    "city": "Pe Ell"
  },
  {
    "value": "98575",
    "label": "Quinault - WA 98575",
    "county": "Grays Harbor County",
    "state": "WA",
    "city": "Quinault"
  },
  {
    "value": "98576",
    "label": "Rainier - WA 98576",
    "county": "Thurston County",
    "state": "WA",
    "city": "Rainier"
  },
  {
    "value": "98577",
    "label": "Raymond - WA 98577",
    "county": "Pacific County",
    "state": "WA",
    "city": "Raymond"
  },
  {
    "value": "98579",
    "label": "Rochester - WA 98579",
    "county": "Thurston County",
    "state": "WA",
    "city": "Rochester"
  },
  {
    "value": "98580",
    "label": "Roy - WA 98580",
    "county": "Pierce County",
    "state": "WA",
    "city": "Roy"
  },
  {
    "value": "98581",
    "label": "Ryderwood - WA 98581",
    "county": "Cowlitz County",
    "state": "WA",
    "city": "Ryderwood"
  },
  {
    "value": "98582",
    "label": "Salkum - WA 98582",
    "county": "Lewis County",
    "state": "WA",
    "city": "Salkum"
  },
  {
    "value": "98584",
    "label": "Shelton - WA 98584",
    "county": "Mason County",
    "state": "WA",
    "city": "Shelton"
  },
  {
    "value": "98585",
    "label": "Silver Creek - WA 98585",
    "county": "Lewis County",
    "state": "WA",
    "city": "Silver Creek"
  },
  {
    "value": "98586",
    "label": "South Bend - WA 98586",
    "county": "Pacific County",
    "state": "WA",
    "city": "South Bend"
  },
  {
    "value": "98588",
    "label": "Tahuya - WA 98588",
    "county": "Mason County",
    "state": "WA",
    "city": "Tahuya"
  },
  {
    "value": "98589",
    "label": "Tenino - WA 98589",
    "county": "Thurston County",
    "state": "WA",
    "city": "Tenino"
  },
  {
    "value": "98590",
    "label": "Tokeland - WA 98590",
    "county": "Pacific County",
    "state": "WA",
    "city": "Tokeland"
  },
  {
    "value": "98591",
    "label": "Toledo - WA 98591",
    "county": "Lewis County",
    "state": "WA",
    "city": "Toledo"
  },
  {
    "value": "98592",
    "label": "Union - WA 98592",
    "county": "Mason County",
    "state": "WA",
    "city": "Union"
  },
  {
    "value": "98593",
    "label": "Vader - WA 98593",
    "county": "Lewis County",
    "state": "WA",
    "city": "Vader"
  },
  {
    "value": "98595",
    "label": "Westport - WA 98595",
    "county": "Grays Harbor County",
    "state": "WA",
    "city": "Westport"
  },
  {
    "value": "98596",
    "label": "Winlock - WA 98596",
    "county": "Lewis County",
    "state": "WA",
    "city": "Winlock"
  },
  {
    "value": "98597",
    "label": "Yelm - WA 98597",
    "county": "Thurston County",
    "state": "WA",
    "city": "Yelm"
  },
  {
    "value": "98601",
    "label": "Amboy - WA 98601",
    "county": "Clark County",
    "state": "WA",
    "city": "Amboy"
  },
  {
    "value": "98602",
    "label": "Appleton - WA 98602",
    "county": "Klickitat County",
    "state": "WA",
    "city": "Appleton"
  },
  {
    "value": "98603",
    "label": "Ariel - WA 98603",
    "county": "Cowlitz County",
    "state": "WA",
    "city": "Ariel"
  },
  {
    "value": "98604",
    "label": "Battle Ground - WA 98604",
    "county": "Clark County",
    "state": "WA",
    "city": "Battle Ground"
  },
  {
    "value": "98605",
    "label": "Bingen - WA 98605",
    "county": "Klickitat County",
    "state": "WA",
    "city": "Bingen"
  },
  {
    "value": "98606",
    "label": "Brush Prairie - WA 98606",
    "county": "Clark County",
    "state": "WA",
    "city": "Brush Prairie"
  },
  {
    "value": "98607",
    "label": "Camas - WA 98607",
    "county": "Clark County",
    "state": "WA",
    "city": "Camas"
  },
  {
    "value": "98609",
    "label": "Carrolls - WA 98609",
    "county": "Cowlitz County",
    "state": "WA",
    "city": "Carrolls"
  },
  {
    "value": "98610",
    "label": "Carson - WA 98610",
    "county": "Skamania County",
    "state": "WA",
    "city": "Carson"
  },
  {
    "value": "98611",
    "label": "Castle Rock - WA 98611",
    "county": "Cowlitz County",
    "state": "WA",
    "city": "Castle Rock"
  },
  {
    "value": "98612",
    "label": "Cathlamet - WA 98612",
    "county": "Wahkiakum County",
    "state": "WA",
    "city": "Cathlamet"
  },
  {
    "value": "98613",
    "label": "Centerville - WA 98613",
    "county": "Klickitat County",
    "state": "WA",
    "city": "Centerville"
  },
  {
    "value": "98616",
    "label": "Cougar - WA 98616",
    "county": "Cowlitz County",
    "state": "WA",
    "city": "Cougar"
  },
  {
    "value": "98619",
    "label": "Glenwood - WA 98619",
    "county": "Klickitat County",
    "state": "WA",
    "city": "Glenwood"
  },
  {
    "value": "98620",
    "label": "Goldendale - WA 98620",
    "county": "Klickitat County",
    "state": "WA",
    "city": "Goldendale"
  },
  {
    "value": "98621",
    "label": "Grays River - WA 98621",
    "county": "Wahkiakum County",
    "state": "WA",
    "city": "Grays River"
  },
  {
    "value": "98624",
    "label": "Ilwaco - WA 98624",
    "county": "Pacific County",
    "state": "WA",
    "city": "Ilwaco"
  },
  {
    "value": "98625",
    "label": "Kalama - WA 98625",
    "county": "Cowlitz County",
    "state": "WA",
    "city": "Kalama"
  },
  {
    "value": "98626",
    "label": "Kelso - WA 98626",
    "county": "Cowlitz County",
    "state": "WA",
    "city": "Kelso"
  },
  {
    "value": "98628",
    "label": "Klickitat - WA 98628",
    "county": "Klickitat County",
    "state": "WA",
    "city": "Klickitat"
  },
  {
    "value": "98629",
    "label": "La Center - WA 98629",
    "county": "Clark County",
    "state": "WA",
    "city": "La Center"
  },
  {
    "value": "98631",
    "label": "Long Beach - WA 98631",
    "county": "Pacific County",
    "state": "WA",
    "city": "Long Beach"
  },
  {
    "value": "98632",
    "label": "Longview - WA 98632",
    "county": "Cowlitz County",
    "state": "WA",
    "city": "Longview"
  },
  {
    "value": "98635",
    "label": "Lyle - WA 98635",
    "county": "Klickitat County",
    "state": "WA",
    "city": "Lyle"
  },
  {
    "value": "98638",
    "label": "Naselle - WA 98638",
    "county": "Pacific County",
    "state": "WA",
    "city": "Naselle"
  },
  {
    "value": "98640",
    "label": "Ocean Park - WA 98640",
    "county": "Pacific County",
    "state": "WA",
    "city": "Ocean Park"
  },
  {
    "value": "98642",
    "label": "Ridgefield - WA 98642",
    "county": "Clark County",
    "state": "WA",
    "city": "Ridgefield"
  },
  {
    "value": "98643",
    "label": "Rosburg - WA 98643",
    "county": "Wahkiakum County",
    "state": "WA",
    "city": "Rosburg"
  },
  {
    "value": "98645",
    "label": "Silverlake - WA 98645",
    "county": "Cowlitz County",
    "state": "WA",
    "city": "Silverlake"
  },
  {
    "value": "98647",
    "label": "Skamokawa - WA 98647",
    "county": "Wahkiakum County",
    "state": "WA",
    "city": "Skamokawa"
  },
  {
    "value": "98648",
    "label": "Stevenson - WA 98648",
    "county": "Skamania County",
    "state": "WA",
    "city": "Stevenson"
  },
  {
    "value": "98649",
    "label": "Toutle - WA 98649",
    "county": "Cowlitz County",
    "state": "WA",
    "city": "Toutle"
  },
  {
    "value": "98650",
    "label": "Trout Lake - WA 98650",
    "county": "Klickitat County",
    "state": "WA",
    "city": "Trout Lake"
  },
  {
    "value": "98651",
    "label": "Underwood - WA 98651",
    "county": "Skamania County",
    "state": "WA",
    "city": "Underwood"
  },
  {
    "value": "98660",
    "label": "Vancouver - WA 98660",
    "county": "Clark County",
    "state": "WA",
    "city": "Vancouver"
  },
  {
    "value": "98661",
    "label": "Vancouver - WA 98661",
    "county": "Clark County",
    "state": "WA",
    "city": "Vancouver"
  },
  {
    "value": "98662",
    "label": "Vancouver - WA 98662",
    "county": "Clark County",
    "state": "WA",
    "city": "Vancouver"
  },
  {
    "value": "98663",
    "label": "Vancouver - WA 98663",
    "county": "Clark County",
    "state": "WA",
    "city": "Vancouver"
  },
  {
    "value": "98664",
    "label": "Vancouver - WA 98664",
    "county": "Clark County",
    "state": "WA",
    "city": "Vancouver"
  },
  {
    "value": "98665",
    "label": "Vancouver - WA 98665",
    "county": "Clark County",
    "state": "WA",
    "city": "Vancouver"
  },
  {
    "value": "98670",
    "label": "Wahkiacus - WA 98670",
    "county": "Klickitat County",
    "state": "WA",
    "city": "Wahkiacus"
  },
  {
    "value": "98671",
    "label": "Washougal - WA 98671",
    "county": "Clark County",
    "state": "WA",
    "city": "Washougal"
  },
  {
    "value": "98672",
    "label": "White Salmon - WA 98672",
    "county": "Klickitat County",
    "state": "WA",
    "city": "White Salmon"
  },
  {
    "value": "98674",
    "label": "Woodland - WA 98674",
    "county": "Cowlitz County",
    "state": "WA",
    "city": "Woodland"
  },
  {
    "value": "98675",
    "label": "Yacolt - WA 98675",
    "county": "Clark County",
    "state": "WA",
    "city": "Yacolt"
  },
  {
    "value": "98682",
    "label": "Vancouver - WA 98682",
    "county": "Clark County",
    "state": "WA",
    "city": "Vancouver"
  },
  {
    "value": "98683",
    "label": "Vancouver - WA 98683",
    "county": "Clark County",
    "state": "WA",
    "city": "Vancouver"
  },
  {
    "value": "98684",
    "label": "Vancouver - WA 98684",
    "county": "Clark County",
    "state": "WA",
    "city": "Vancouver"
  },
  {
    "value": "98685",
    "label": "Vancouver - WA 98685",
    "county": "Clark County",
    "state": "WA",
    "city": "Vancouver"
  },
  {
    "value": "98686",
    "label": "Vancouver - WA 98686",
    "county": "Clark County",
    "state": "WA",
    "city": "Vancouver"
  },
  {
    "value": "98801",
    "label": "Wenatchee - WA 98801",
    "county": "Chelan County",
    "state": "WA",
    "city": "Wenatchee"
  },
  {
    "value": "98802",
    "label": "East Wenatchee - WA 98802",
    "county": "Douglas County",
    "state": "WA",
    "city": "East Wenatchee"
  },
  {
    "value": "98812",
    "label": "Brewster - WA 98812",
    "county": "Okanogan County",
    "state": "WA",
    "city": "Brewster"
  },
  {
    "value": "98813",
    "label": "Bridgeport - WA 98813",
    "county": "Douglas County",
    "state": "WA",
    "city": "Bridgeport"
  },
  {
    "value": "98814",
    "label": "Carlton - WA 98814",
    "county": "Okanogan County",
    "state": "WA",
    "city": "Carlton"
  },
  {
    "value": "98815",
    "label": "Cashmere - WA 98815",
    "county": "Chelan County",
    "state": "WA",
    "city": "Cashmere"
  },
  {
    "value": "98816",
    "label": "Chelan - WA 98816",
    "county": "Chelan County",
    "state": "WA",
    "city": "Chelan"
  },
  {
    "value": "98822",
    "label": "Entiat - WA 98822",
    "county": "Chelan County",
    "state": "WA",
    "city": "Entiat"
  },
  {
    "value": "98823",
    "label": "Ephrata - WA 98823",
    "county": "Grant County",
    "state": "WA",
    "city": "Ephrata"
  },
  {
    "value": "98826",
    "label": "Leavenworth - WA 98826",
    "county": "Chelan County",
    "state": "WA",
    "city": "Leavenworth"
  },
  {
    "value": "98827",
    "label": "Loomis - WA 98827",
    "county": "Okanogan County",
    "state": "WA",
    "city": "Loomis"
  },
  {
    "value": "98828",
    "label": "Malaga - WA 98828",
    "county": "Chelan County",
    "state": "WA",
    "city": "Malaga"
  },
  {
    "value": "98830",
    "label": "Mansfield - WA 98830",
    "county": "Douglas County",
    "state": "WA",
    "city": "Mansfield"
  },
  {
    "value": "98831",
    "label": "Manson - WA 98831",
    "county": "Chelan County",
    "state": "WA",
    "city": "Manson"
  },
  {
    "value": "98832",
    "label": "Marlin - WA 98832",
    "county": "Grant County",
    "state": "WA",
    "city": "Marlin"
  },
  {
    "value": "98833",
    "label": "Mazama - WA 98833",
    "county": "Okanogan County",
    "state": "WA",
    "city": "Mazama"
  },
  {
    "value": "98834",
    "label": "Methow - WA 98834",
    "county": "Okanogan County",
    "state": "WA",
    "city": "Methow"
  },
  {
    "value": "98837",
    "label": "Moses Lake - WA 98837",
    "county": "Grant County",
    "state": "WA",
    "city": "Moses Lake"
  },
  {
    "value": "98840",
    "label": "Okanogan - WA 98840",
    "county": "Okanogan County",
    "state": "WA",
    "city": "Okanogan"
  },
  {
    "value": "98841",
    "label": "Omak - WA 98841",
    "county": "Okanogan County",
    "state": "WA",
    "city": "Omak"
  },
  {
    "value": "98843",
    "label": "Orondo - WA 98843",
    "county": "Douglas County",
    "state": "WA",
    "city": "Orondo"
  },
  {
    "value": "98844",
    "label": "Oroville - WA 98844",
    "county": "Okanogan County",
    "state": "WA",
    "city": "Oroville"
  },
  {
    "value": "98845",
    "label": "Palisades - WA 98845",
    "county": "Douglas County",
    "state": "WA",
    "city": "Palisades"
  },
  {
    "value": "98846",
    "label": "Pateros - WA 98846",
    "county": "Okanogan County",
    "state": "WA",
    "city": "Pateros"
  },
  {
    "value": "98847",
    "label": "Peshastin - WA 98847",
    "county": "Chelan County",
    "state": "WA",
    "city": "Peshastin"
  },
  {
    "value": "98848",
    "label": "Quincy - WA 98848",
    "county": "Grant County",
    "state": "WA",
    "city": "Quincy"
  },
  {
    "value": "98849",
    "label": "Riverside - WA 98849",
    "county": "Okanogan County",
    "state": "WA",
    "city": "Riverside"
  },
  {
    "value": "98850",
    "label": "Rock Island - WA 98850",
    "county": "Douglas County",
    "state": "WA",
    "city": "Rock Island"
  },
  {
    "value": "98851",
    "label": "Soap Lake - WA 98851",
    "county": "Grant County",
    "state": "WA",
    "city": "Soap Lake"
  },
  {
    "value": "98855",
    "label": "Tonasket - WA 98855",
    "county": "Okanogan County",
    "state": "WA",
    "city": "Tonasket"
  },
  {
    "value": "98856",
    "label": "Twisp - WA 98856",
    "county": "Okanogan County",
    "state": "WA",
    "city": "Twisp"
  },
  {
    "value": "98857",
    "label": "Warden - WA 98857",
    "county": "Grant County",
    "state": "WA",
    "city": "Warden"
  },
  {
    "value": "98858",
    "label": "Waterville - WA 98858",
    "county": "Douglas County",
    "state": "WA",
    "city": "Waterville"
  },
  {
    "value": "98859",
    "label": "Wauconda - WA 98859",
    "county": "Okanogan County",
    "state": "WA",
    "city": "Wauconda"
  },
  {
    "value": "98862",
    "label": "Winthrop - WA 98862",
    "county": "Okanogan County",
    "state": "WA",
    "city": "Winthrop"
  },
  {
    "value": "98901",
    "label": "Yakima - WA 98901",
    "county": "Yakima County",
    "state": "WA",
    "city": "Yakima"
  },
  {
    "value": "98902",
    "label": "Yakima - WA 98902",
    "county": "Yakima County",
    "state": "WA",
    "city": "Yakima"
  },
  {
    "value": "98903",
    "label": "Yakima - WA 98903",
    "county": "Yakima County",
    "state": "WA",
    "city": "Yakima"
  },
  {
    "value": "98908",
    "label": "Yakima - WA 98908",
    "county": "Yakima County",
    "state": "WA",
    "city": "Yakima"
  },
  {
    "value": "98922",
    "label": "Cle Elum - WA 98922",
    "county": "Kittitas County",
    "state": "WA",
    "city": "Cle Elum"
  },
  {
    "value": "98923",
    "label": "Cowiche - WA 98923",
    "county": "Yakima County",
    "state": "WA",
    "city": "Cowiche"
  },
  {
    "value": "98926",
    "label": "Ellensburg - WA 98926",
    "county": "Kittitas County",
    "state": "WA",
    "city": "Ellensburg"
  },
  {
    "value": "98929",
    "label": "Naches - WA 98929",
    "county": "Yakima County",
    "state": "WA",
    "city": "Naches"
  },
  {
    "value": "98930",
    "label": "Grandview - WA 98930",
    "county": "Yakima County",
    "state": "WA",
    "city": "Grandview"
  },
  {
    "value": "98932",
    "label": "Granger - WA 98932",
    "county": "Yakima County",
    "state": "WA",
    "city": "Granger"
  },
  {
    "value": "98933",
    "label": "Harrah - WA 98933",
    "county": "Yakima County",
    "state": "WA",
    "city": "Harrah"
  },
  {
    "value": "98935",
    "label": "Mabton - WA 98935",
    "county": "Yakima County",
    "state": "WA",
    "city": "Mabton"
  },
  {
    "value": "98936",
    "label": "Moxee - WA 98936",
    "county": "Yakima County",
    "state": "WA",
    "city": "Moxee"
  },
  {
    "value": "98937",
    "label": "Naches - WA 98937",
    "county": "Yakima County",
    "state": "WA",
    "city": "Naches"
  },
  {
    "value": "98938",
    "label": "Outlook - WA 98938",
    "county": "Yakima County",
    "state": "WA",
    "city": "Outlook"
  },
  {
    "value": "98942",
    "label": "Selah - WA 98942",
    "county": "Yakima County",
    "state": "WA",
    "city": "Selah"
  },
  {
    "value": "98944",
    "label": "Sunnyside - WA 98944",
    "county": "Yakima County",
    "state": "WA",
    "city": "Sunnyside"
  },
  {
    "value": "98946",
    "label": "Thorp - WA 98946",
    "county": "Kittitas County",
    "state": "WA",
    "city": "Thorp"
  },
  {
    "value": "98947",
    "label": "Tieton - WA 98947",
    "county": "Yakima County",
    "state": "WA",
    "city": "Tieton"
  },
  {
    "value": "98948",
    "label": "Toppenish - WA 98948",
    "county": "Yakima County",
    "state": "WA",
    "city": "Toppenish"
  },
  {
    "value": "98951",
    "label": "Wapato - WA 98951",
    "county": "Yakima County",
    "state": "WA",
    "city": "Wapato"
  },
  {
    "value": "98952",
    "label": "White Swan - WA 98952",
    "county": "Yakima County",
    "state": "WA",
    "city": "White Swan"
  },
  {
    "value": "98953",
    "label": "Zillah - WA 98953",
    "county": "Yakima County",
    "state": "WA",
    "city": "Zillah"
  },
  {
    "value": "99001",
    "label": "Airway Heights - WA 99001",
    "county": "Spokane County",
    "state": "WA",
    "city": "Airway Heights"
  },
  {
    "value": "99003",
    "label": "Chattaroy - WA 99003",
    "county": "Spokane County",
    "state": "WA",
    "city": "Chattaroy"
  },
  {
    "value": "99004",
    "label": "Cheney - WA 99004",
    "county": "Spokane County",
    "state": "WA",
    "city": "Cheney"
  },
  {
    "value": "99005",
    "label": "Colbert - WA 99005",
    "county": "Spokane County",
    "state": "WA",
    "city": "Colbert"
  },
  {
    "value": "99006",
    "label": "Deer Park - WA 99006",
    "county": "Spokane County",
    "state": "WA",
    "city": "Deer Park"
  },
  {
    "value": "99008",
    "label": "Edwall - WA 99008",
    "county": "Lincoln County",
    "state": "WA",
    "city": "Edwall"
  },
  {
    "value": "99009",
    "label": "Elk - WA 99009",
    "county": "Spokane County",
    "state": "WA",
    "city": "Elk"
  },
  {
    "value": "99011",
    "label": "Fairchild Air Force Base - WA 99011",
    "county": "Spokane County",
    "state": "WA",
    "city": "Fairchild Air Force Base"
  },
  {
    "value": "99012",
    "label": "Fairfield - WA 99012",
    "county": "Spokane County",
    "state": "WA",
    "city": "Fairfield"
  },
  {
    "value": "99013",
    "label": "Ford - WA 99013",
    "county": "Stevens County",
    "state": "WA",
    "city": "Ford"
  },
  {
    "value": "99016",
    "label": "Greenacres - WA 99016",
    "county": "Spokane County",
    "state": "WA",
    "city": "Greenacres"
  },
  {
    "value": "99017",
    "label": "Lamont - WA 99017",
    "county": "Whitman County",
    "state": "WA",
    "city": "Lamont"
  },
  {
    "value": "99018",
    "label": "Latah - WA 99018",
    "county": "Spokane County",
    "state": "WA",
    "city": "Latah"
  },
  {
    "value": "99019",
    "label": "Liberty Lake - WA 99019",
    "county": "Spokane County",
    "state": "WA",
    "city": "Liberty Lake"
  },
  {
    "value": "99021",
    "label": "Mead - WA 99021",
    "county": "Spokane County",
    "state": "WA",
    "city": "Mead"
  },
  {
    "value": "99022",
    "label": "Medical Lake - WA 99022",
    "county": "Spokane County",
    "state": "WA",
    "city": "Medical Lake"
  },
  {
    "value": "99023",
    "label": "Mica - WA 99023",
    "county": "Spokane County",
    "state": "WA",
    "city": "Mica"
  },
  {
    "value": "99025",
    "label": "Newman Lake - WA 99025",
    "county": "Spokane County",
    "state": "WA",
    "city": "Newman Lake"
  },
  {
    "value": "99026",
    "label": "Nine Mile Falls - WA 99026",
    "county": "Stevens County",
    "state": "WA",
    "city": "Nine Mile Falls"
  },
  {
    "value": "99027",
    "label": "Otis Orchards - WA 99027",
    "county": "Spokane County",
    "state": "WA",
    "city": "Otis Orchards"
  },
  {
    "value": "99029",
    "label": "Reardan - WA 99029",
    "county": "Lincoln County",
    "state": "WA",
    "city": "Reardan"
  },
  {
    "value": "99030",
    "label": "Rockford - WA 99030",
    "county": "Spokane County",
    "state": "WA",
    "city": "Rockford"
  },
  {
    "value": "99031",
    "label": "Spangle - WA 99031",
    "county": "Spokane County",
    "state": "WA",
    "city": "Spangle"
  },
  {
    "value": "99032",
    "label": "Sprague - WA 99032",
    "county": "Lincoln County",
    "state": "WA",
    "city": "Sprague"
  },
  {
    "value": "99033",
    "label": "Tekoa - WA 99033",
    "county": "Whitman County",
    "state": "WA",
    "city": "Tekoa"
  },
  {
    "value": "99034",
    "label": "Tumtum - WA 99034",
    "county": "Stevens County",
    "state": "WA",
    "city": "Tumtum"
  },
  {
    "value": "99036",
    "label": "Valleyford - WA 99036",
    "county": "Spokane County",
    "state": "WA",
    "city": "Valleyford"
  },
  {
    "value": "99037",
    "label": "Veradale - WA 99037",
    "county": "Spokane County",
    "state": "WA",
    "city": "Veradale"
  },
  {
    "value": "99040",
    "label": "Wellpinit - WA 99040",
    "county": "Stevens County",
    "state": "WA",
    "city": "Wellpinit"
  },
  {
    "value": "99101",
    "label": "Addy - WA 99101",
    "county": "Stevens County",
    "state": "WA",
    "city": "Addy"
  },
  {
    "value": "99103",
    "label": "Almira - WA 99103",
    "county": "Lincoln County",
    "state": "WA",
    "city": "Almira"
  },
  {
    "value": "99104",
    "label": "Belmont - WA 99104",
    "county": "Whitman County",
    "state": "WA",
    "city": "Belmont"
  },
  {
    "value": "99105",
    "label": "Benge - WA 99105",
    "county": "Adams County",
    "state": "WA",
    "city": "Benge"
  },
  {
    "value": "99107",
    "label": "Boyds - WA 99107",
    "county": "Ferry County",
    "state": "WA",
    "city": "Boyds"
  },
  {
    "value": "99109",
    "label": "Chewelah - WA 99109",
    "county": "Stevens County",
    "state": "WA",
    "city": "Chewelah"
  },
  {
    "value": "99110",
    "label": "Clayton - WA 99110",
    "county": "Stevens County",
    "state": "WA",
    "city": "Clayton"
  },
  {
    "value": "99111",
    "label": "Colfax - WA 99111",
    "county": "Whitman County",
    "state": "WA",
    "city": "Colfax"
  },
  {
    "value": "99113",
    "label": "Colton - WA 99113",
    "county": "Whitman County",
    "state": "WA",
    "city": "Colton"
  },
  {
    "value": "99114",
    "label": "Colville - WA 99114",
    "county": "Stevens County",
    "state": "WA",
    "city": "Colville"
  },
  {
    "value": "99115",
    "label": "Coulee City - WA 99115",
    "county": "Grant County",
    "state": "WA",
    "city": "Coulee City"
  },
  {
    "value": "99116",
    "label": "Coulee Dam - WA 99116",
    "county": "Douglas County",
    "state": "WA",
    "city": "Coulee Dam"
  },
  {
    "value": "99117",
    "label": "Creston - WA 99117",
    "county": "Lincoln County",
    "state": "WA",
    "city": "Creston"
  },
  {
    "value": "99118",
    "label": "Curlew - WA 99118",
    "county": "Ferry County",
    "state": "WA",
    "city": "Curlew"
  },
  {
    "value": "99119",
    "label": "Cusick - WA 99119",
    "county": "Pend Oreille County",
    "state": "WA",
    "city": "Cusick"
  },
  {
    "value": "99121",
    "label": "Danville - WA 99121",
    "county": "Ferry County",
    "state": "WA",
    "city": "Danville"
  },
  {
    "value": "99122",
    "label": "Davenport - WA 99122",
    "county": "Lincoln County",
    "state": "WA",
    "city": "Davenport"
  },
  {
    "value": "99123",
    "label": "Electric City - WA 99123",
    "county": "Grant County",
    "state": "WA",
    "city": "Electric City"
  },
  {
    "value": "99125",
    "label": "Endicott - WA 99125",
    "county": "Whitman County",
    "state": "WA",
    "city": "Endicott"
  },
  {
    "value": "99126",
    "label": "Evans - WA 99126",
    "county": "Stevens County",
    "state": "WA",
    "city": "Evans"
  },
  {
    "value": "99128",
    "label": "Farmington - WA 99128",
    "county": "Whitman County",
    "state": "WA",
    "city": "Farmington"
  },
  {
    "value": "99129",
    "label": "Fruitland - WA 99129",
    "county": "Stevens County",
    "state": "WA",
    "city": "Fruitland"
  },
  {
    "value": "99130",
    "label": "Garfield - WA 99130",
    "county": "Whitman County",
    "state": "WA",
    "city": "Garfield"
  },
  {
    "value": "99131",
    "label": "Gifford - WA 99131",
    "county": "Stevens County",
    "state": "WA",
    "city": "Gifford"
  },
  {
    "value": "99133",
    "label": "Grand Coulee - WA 99133",
    "county": "Grant County",
    "state": "WA",
    "city": "Grand Coulee"
  },
  {
    "value": "99134",
    "label": "Harrington - WA 99134",
    "county": "Lincoln County",
    "state": "WA",
    "city": "Harrington"
  },
  {
    "value": "99135",
    "label": "Hartline - WA 99135",
    "county": "Grant County",
    "state": "WA",
    "city": "Hartline"
  },
  {
    "value": "99136",
    "label": "Hay - WA 99136",
    "county": "Whitman County",
    "state": "WA",
    "city": "Hay"
  },
  {
    "value": "99137",
    "label": "Hunters - WA 99137",
    "county": "Stevens County",
    "state": "WA",
    "city": "Hunters"
  },
  {
    "value": "99138",
    "label": "Inchelium - WA 99138",
    "county": "Ferry County",
    "state": "WA",
    "city": "Inchelium"
  },
  {
    "value": "99139",
    "label": "Ione - WA 99139",
    "county": "Pend Oreille County",
    "state": "WA",
    "city": "Ione"
  },
  {
    "value": "99140",
    "label": "Keller - WA 99140",
    "county": "Ferry County",
    "state": "WA",
    "city": "Keller"
  },
  {
    "value": "99141",
    "label": "Kettle Falls - WA 99141",
    "county": "Stevens County",
    "state": "WA",
    "city": "Kettle Falls"
  },
  {
    "value": "99143",
    "label": "Lacrosse - WA 99143",
    "county": "Whitman County",
    "state": "WA",
    "city": "Lacrosse"
  },
  {
    "value": "99144",
    "label": "Lamona - WA 99144",
    "county": "Lincoln County",
    "state": "WA",
    "city": "Lamona"
  },
  {
    "value": "99147",
    "label": "Lincoln - WA 99147",
    "county": "Lincoln County",
    "state": "WA",
    "city": "Lincoln"
  },
  {
    "value": "99148",
    "label": "Loon Lake - WA 99148",
    "county": "Stevens County",
    "state": "WA",
    "city": "Loon Lake"
  },
  {
    "value": "99150",
    "label": "Malo - WA 99150",
    "county": "Ferry County",
    "state": "WA",
    "city": "Malo"
  },
  {
    "value": "99153",
    "label": "Metaline Falls - WA 99153",
    "county": "Pend Oreille County",
    "state": "WA",
    "city": "Metaline Falls"
  },
  {
    "value": "99154",
    "label": "Mohler - WA 99154",
    "county": "Lincoln County",
    "state": "WA",
    "city": "Mohler"
  },
  {
    "value": "99156",
    "label": "Newport - WA 99156",
    "county": "Pend Oreille County",
    "state": "WA",
    "city": "Newport"
  },
  {
    "value": "99157",
    "label": "Northport - WA 99157",
    "county": "Stevens County",
    "state": "WA",
    "city": "Northport"
  },
  {
    "value": "99158",
    "label": "Oakesdale - WA 99158",
    "county": "Whitman County",
    "state": "WA",
    "city": "Oakesdale"
  },
  {
    "value": "99159",
    "label": "Odessa - WA 99159",
    "county": "Lincoln County",
    "state": "WA",
    "city": "Odessa"
  },
  {
    "value": "99160",
    "label": "Orient - WA 99160",
    "county": "Ferry County",
    "state": "WA",
    "city": "Orient"
  },
  {
    "value": "99161",
    "label": "Palouse - WA 99161",
    "county": "Whitman County",
    "state": "WA",
    "city": "Palouse"
  },
  {
    "value": "99163",
    "label": "Pullman - WA 99163",
    "county": "Whitman County",
    "state": "WA",
    "city": "Pullman"
  },
  {
    "value": "99165",
    "label": "Pullman - WA 99165",
    "county": "Whitman County",
    "state": "WA",
    "city": "Pullman"
  },
  {
    "value": "99166",
    "label": "Republic - WA 99166",
    "county": "Ferry County",
    "state": "WA",
    "city": "Republic"
  },
  {
    "value": "99167",
    "label": "Rice - WA 99167",
    "county": "Stevens County",
    "state": "WA",
    "city": "Rice"
  },
  {
    "value": "99169",
    "label": "Ritzville - WA 99169",
    "county": "Adams County",
    "state": "WA",
    "city": "Ritzville"
  },
  {
    "value": "99170",
    "label": "Rosalia - WA 99170",
    "county": "Whitman County",
    "state": "WA",
    "city": "Rosalia"
  },
  {
    "value": "99171",
    "label": "Saint John - WA 99171",
    "county": "Whitman County",
    "state": "WA",
    "city": "Saint John"
  },
  {
    "value": "99173",
    "label": "Springdale - WA 99173",
    "county": "Stevens County",
    "state": "WA",
    "city": "Springdale"
  },
  {
    "value": "99176",
    "label": "Thornton - WA 99176",
    "county": "Whitman County",
    "state": "WA",
    "city": "Thornton"
  },
  {
    "value": "99179",
    "label": "Uniontown - WA 99179",
    "county": "Whitman County",
    "state": "WA",
    "city": "Uniontown"
  },
  {
    "value": "99180",
    "label": "Usk - WA 99180",
    "county": "Pend Oreille County",
    "state": "WA",
    "city": "Usk"
  },
  {
    "value": "99181",
    "label": "Valley - WA 99181",
    "county": "Stevens County",
    "state": "WA",
    "city": "Valley"
  },
  {
    "value": "99185",
    "label": "Wilbur - WA 99185",
    "county": "Lincoln County",
    "state": "WA",
    "city": "Wilbur"
  },
  {
    "value": "99201",
    "label": "Spokane - WA 99201",
    "county": "Spokane County",
    "state": "WA",
    "city": "Spokane"
  },
  {
    "value": "99202",
    "label": "Spokane - WA 99202",
    "county": "Spokane County",
    "state": "WA",
    "city": "Spokane"
  },
  {
    "value": "99203",
    "label": "Spokane - WA 99203",
    "county": "Spokane County",
    "state": "WA",
    "city": "Spokane"
  },
  {
    "value": "99204",
    "label": "Spokane - WA 99204",
    "county": "Spokane County",
    "state": "WA",
    "city": "Spokane"
  },
  {
    "value": "99205",
    "label": "Spokane - WA 99205",
    "county": "Spokane County",
    "state": "WA",
    "city": "Spokane"
  },
  {
    "value": "99206",
    "label": "Spokane - WA 99206",
    "county": "Spokane County",
    "state": "WA",
    "city": "Spokane"
  },
  {
    "value": "99207",
    "label": "Spokane - WA 99207",
    "county": "Spokane County",
    "state": "WA",
    "city": "Spokane"
  },
  {
    "value": "99208",
    "label": "Spokane - WA 99208",
    "county": "Spokane County",
    "state": "WA",
    "city": "Spokane"
  },
  {
    "value": "99212",
    "label": "Spokane - WA 99212",
    "county": "Spokane County",
    "state": "WA",
    "city": "Spokane"
  },
  {
    "value": "99216",
    "label": "Spokane - WA 99216",
    "county": "Spokane County",
    "state": "WA",
    "city": "Spokane"
  },
  {
    "value": "99217",
    "label": "Spokane - WA 99217",
    "county": "Spokane County",
    "state": "WA",
    "city": "Spokane"
  },
  {
    "value": "99218",
    "label": "Spokane - WA 99218",
    "county": "Spokane County",
    "state": "WA",
    "city": "Spokane"
  },
  {
    "value": "99223",
    "label": "Spokane - WA 99223",
    "county": "Spokane County",
    "state": "WA",
    "city": "Spokane"
  },
  {
    "value": "99224",
    "label": "Spokane - WA 99224",
    "county": "Spokane County",
    "state": "WA",
    "city": "Spokane"
  },
  {
    "value": "99301",
    "label": "Pasco - WA 99301",
    "county": "Franklin County",
    "state": "WA",
    "city": "Pasco"
  },
  {
    "value": "99320",
    "label": "Benton City - WA 99320",
    "county": "Benton County",
    "state": "WA",
    "city": "Benton City"
  },
  {
    "value": "99321",
    "label": "Beverly - WA 99321",
    "county": "Grant County",
    "state": "WA",
    "city": "Beverly"
  },
  {
    "value": "99322",
    "label": "Bickleton - WA 99322",
    "county": "Klickitat County",
    "state": "WA",
    "city": "Bickleton"
  },
  {
    "value": "99323",
    "label": "Burbank - WA 99323",
    "county": "Walla Walla County",
    "state": "WA",
    "city": "Burbank"
  },
  {
    "value": "99324",
    "label": "College Place - WA 99324",
    "county": "Walla Walla County",
    "state": "WA",
    "city": "College Place"
  },
  {
    "value": "99326",
    "label": "Connell - WA 99326",
    "county": "Franklin County",
    "state": "WA",
    "city": "Connell"
  },
  {
    "value": "99328",
    "label": "Dayton - WA 99328",
    "county": "Columbia County",
    "state": "WA",
    "city": "Dayton"
  },
  {
    "value": "99329",
    "label": "Dixie - WA 99329",
    "county": "Walla Walla County",
    "state": "WA",
    "city": "Dixie"
  },
  {
    "value": "99330",
    "label": "Eltopia - WA 99330",
    "county": "Franklin County",
    "state": "WA",
    "city": "Eltopia"
  },
  {
    "value": "99336",
    "label": "Kennewick - WA 99336",
    "county": "Benton County",
    "state": "WA",
    "city": "Kennewick"
  },
  {
    "value": "99337",
    "label": "Kennewick - WA 99337",
    "county": "Benton County",
    "state": "WA",
    "city": "Kennewick"
  },
  {
    "value": "99338",
    "label": "Kennewick - WA 99338",
    "county": "Benton County",
    "state": "WA",
    "city": "Kennewick"
  },
  {
    "value": "99341",
    "label": "Lind - WA 99341",
    "county": "Adams County",
    "state": "WA",
    "city": "Lind"
  },
  {
    "value": "99343",
    "label": "Mesa - WA 99343",
    "county": "Franklin County",
    "state": "WA",
    "city": "Mesa"
  },
  {
    "value": "99344",
    "label": "Othello - WA 99344",
    "county": "Adams County",
    "state": "WA",
    "city": "Othello"
  },
  {
    "value": "99347",
    "label": "Pomeroy - WA 99347",
    "county": "Garfield County",
    "state": "WA",
    "city": "Pomeroy"
  },
  {
    "value": "99348",
    "label": "Prescott - WA 99348",
    "county": "Walla Walla County",
    "state": "WA",
    "city": "Prescott"
  },
  {
    "value": "99349",
    "label": "Mattawa - WA 99349",
    "county": "Grant County",
    "state": "WA",
    "city": "Mattawa"
  },
  {
    "value": "99350",
    "label": "Prosser - WA 99350",
    "county": "Benton County",
    "state": "WA",
    "city": "Prosser"
  },
  {
    "value": "99352",
    "label": "Richland - WA 99352",
    "county": "Benton County",
    "state": "WA",
    "city": "Richland"
  },
  {
    "value": "99353",
    "label": "West Richland - WA 99353",
    "county": "Benton County",
    "state": "WA",
    "city": "West Richland"
  },
  {
    "value": "99354",
    "label": "Richland - WA 99354",
    "county": "Benton County",
    "state": "WA",
    "city": "Richland"
  },
  {
    "value": "99356",
    "label": "Roosevelt - WA 99356",
    "county": "Klickitat County",
    "state": "WA",
    "city": "Roosevelt"
  },
  {
    "value": "99357",
    "label": "Royal City - WA 99357",
    "county": "Grant County",
    "state": "WA",
    "city": "Royal City"
  },
  {
    "value": "99360",
    "label": "Touchet - WA 99360",
    "county": "Walla Walla County",
    "state": "WA",
    "city": "Touchet"
  },
  {
    "value": "99361",
    "label": "Waitsburg - WA 99361",
    "county": "Walla Walla County",
    "state": "WA",
    "city": "Waitsburg"
  },
  {
    "value": "99362",
    "label": "Walla Walla - WA 99362",
    "county": "Walla Walla County",
    "state": "WA",
    "city": "Walla Walla"
  },
  {
    "value": "99371",
    "label": "Washtucna - WA 99371",
    "county": "Adams County",
    "state": "WA",
    "city": "Washtucna"
  },
  {
    "value": "99401",
    "label": "Anatone - WA 99401",
    "county": "Asotin County",
    "state": "WA",
    "city": "Anatone"
  },
  {
    "value": "99402",
    "label": "Asotin - WA 99402",
    "county": "Asotin County",
    "state": "WA",
    "city": "Asotin"
  },
  {
    "value": "99403",
    "label": "Clarkston - WA 99403",
    "county": "Asotin County",
    "state": "WA",
    "city": "Clarkston"
  },
  {
    "value": "24701",
    "label": "Bluefield - WV 24701",
    "county": "Mercer County",
    "state": "WV",
    "city": "Bluefield"
  },
  {
    "value": "24712",
    "label": "Athens - WV 24712",
    "county": "Mercer County",
    "state": "WV",
    "city": "Athens"
  },
  {
    "value": "24714",
    "label": "Beeson - WV 24714",
    "county": "Mercer County",
    "state": "WV",
    "city": "Beeson"
  },
  {
    "value": "24715",
    "label": "Bramwell - WV 24715",
    "county": "Mercer County",
    "state": "WV",
    "city": "Bramwell"
  },
  {
    "value": "24724",
    "label": "Freeman - WV 24724",
    "county": "Mercer County",
    "state": "WV",
    "city": "Freeman"
  },
  {
    "value": "24726",
    "label": "Herndon - WV 24726",
    "county": "Wyoming County",
    "state": "WV",
    "city": "Herndon"
  },
  {
    "value": "24731",
    "label": "Kegley - WV 24731",
    "county": "Mercer County",
    "state": "WV",
    "city": "Kegley"
  },
  {
    "value": "24733",
    "label": "Lashmeet - WV 24733",
    "county": "Mercer County",
    "state": "WV",
    "city": "Lashmeet"
  },
  {
    "value": "24736",
    "label": "Matoaka - WV 24736",
    "county": "Mercer County",
    "state": "WV",
    "city": "Matoaka"
  },
  {
    "value": "24739",
    "label": "Princeton - WV 24739",
    "county": "Mercer County",
    "state": "WV",
    "city": "Princeton"
  },
  {
    "value": "24740",
    "label": "Princeton - WV 24740",
    "county": "Mercer County",
    "state": "WV",
    "city": "Princeton"
  },
  {
    "value": "24747",
    "label": "Rock - WV 24747",
    "county": "Mercer County",
    "state": "WV",
    "city": "Rock"
  },
  {
    "value": "24801",
    "label": "Welch - WV 24801",
    "county": "McDowell County",
    "state": "WV",
    "city": "Welch"
  },
  {
    "value": "24808",
    "label": "Anawalt - WV 24808",
    "county": "McDowell County",
    "state": "WV",
    "city": "Anawalt"
  },
  {
    "value": "24815",
    "label": "Berwind - WV 24815",
    "county": "McDowell County",
    "state": "WV",
    "city": "Berwind"
  },
  {
    "value": "24818",
    "label": "Brenton - WV 24818",
    "county": "Wyoming County",
    "state": "WV",
    "city": "Brenton"
  },
  {
    "value": "24822",
    "label": "Clear Fork - WV 24822",
    "county": "Wyoming County",
    "state": "WV",
    "city": "Clear Fork"
  },
  {
    "value": "24823",
    "label": "Coal Mountain - WV 24823",
    "county": "Wyoming County",
    "state": "WV",
    "city": "Coal Mountain"
  },
  {
    "value": "24827",
    "label": "Cyclone - WV 24827",
    "county": "Wyoming County",
    "state": "WV",
    "city": "Cyclone"
  },
  {
    "value": "24828",
    "label": "Davy - WV 24828",
    "county": "McDowell County",
    "state": "WV",
    "city": "Davy"
  },
  {
    "value": "24829",
    "label": "Eckman - WV 24829",
    "county": "McDowell County",
    "state": "WV",
    "city": "Eckman"
  },
  {
    "value": "24830",
    "label": "Elbert - WV 24830",
    "county": "McDowell County",
    "state": "WV",
    "city": "Elbert"
  },
  {
    "value": "24831",
    "label": "Elkhorn - WV 24831",
    "county": "McDowell County",
    "state": "WV",
    "city": "Elkhorn"
  },
  {
    "value": "24839",
    "label": "Hanover - WV 24839",
    "county": "Wyoming County",
    "state": "WV",
    "city": "Hanover"
  },
  {
    "value": "24844",
    "label": "Iaeger - WV 24844",
    "county": "McDowell County",
    "state": "WV",
    "city": "Iaeger"
  },
  {
    "value": "24846",
    "label": "Isaban - WV 24846",
    "county": "Mingo County",
    "state": "WV",
    "city": "Isaban"
  },
  {
    "value": "24849",
    "label": "Jesse - WV 24849",
    "county": "Wyoming County",
    "state": "WV",
    "city": "Jesse"
  },
  {
    "value": "24850",
    "label": "Jolo - WV 24850",
    "county": "McDowell County",
    "state": "WV",
    "city": "Jolo"
  },
  {
    "value": "24855",
    "label": "Kyle - WV 24855",
    "county": "McDowell County",
    "state": "WV",
    "city": "Kyle"
  },
  {
    "value": "24860",
    "label": "Matheny - WV 24860",
    "county": "Wyoming County",
    "state": "WV",
    "city": "Matheny"
  },
  {
    "value": "24862",
    "label": "Mohawk - WV 24862",
    "county": "McDowell County",
    "state": "WV",
    "city": "Mohawk"
  },
  {
    "value": "24868",
    "label": "Northfork - WV 24868",
    "county": "McDowell County",
    "state": "WV",
    "city": "Northfork"
  },
  {
    "value": "24869",
    "label": "North Spring - WV 24869",
    "county": "Wyoming County",
    "state": "WV",
    "city": "North Spring"
  },
  {
    "value": "24870",
    "label": "Oceana - WV 24870",
    "county": "Wyoming County",
    "state": "WV",
    "city": "Oceana"
  },
  {
    "value": "24872",
    "label": "Panther - WV 24872",
    "county": "McDowell County",
    "state": "WV",
    "city": "Panther"
  },
  {
    "value": "24873",
    "label": "Paynesville - WV 24873",
    "county": "McDowell County",
    "state": "WV",
    "city": "Paynesville"
  },
  {
    "value": "24874",
    "label": "Pineville - WV 24874",
    "county": "Wyoming County",
    "state": "WV",
    "city": "Pineville"
  },
  {
    "value": "24879",
    "label": "Raysal - WV 24879",
    "county": "McDowell County",
    "state": "WV",
    "city": "Raysal"
  },
  {
    "value": "24880",
    "label": "Rock View - WV 24880",
    "county": "Wyoming County",
    "state": "WV",
    "city": "Rock View"
  },
  {
    "value": "24882",
    "label": "Simon - WV 24882",
    "county": "Wyoming County",
    "state": "WV",
    "city": "Simon"
  },
  {
    "value": "24884",
    "label": "Squire - WV 24884",
    "county": "McDowell County",
    "state": "WV",
    "city": "Squire"
  },
  {
    "value": "24892",
    "label": "War - WV 24892",
    "county": "McDowell County",
    "state": "WV",
    "city": "War"
  },
  {
    "value": "24898",
    "label": "Wyoming - WV 24898",
    "county": "Wyoming County",
    "state": "WV",
    "city": "Wyoming"
  },
  {
    "value": "24901",
    "label": "Lewisburg - WV 24901",
    "county": "Greenbrier County",
    "state": "WV",
    "city": "Lewisburg"
  },
  {
    "value": "24910",
    "label": "Alderson - WV 24910",
    "county": "Greenbrier County",
    "state": "WV",
    "city": "Alderson"
  },
  {
    "value": "24915",
    "label": "Arbovale - WV 24915",
    "county": "Pocahontas County",
    "state": "WV",
    "city": "Arbovale"
  },
  {
    "value": "24916",
    "label": "Asbury - WV 24916",
    "county": "Greenbrier County",
    "state": "WV",
    "city": "Asbury"
  },
  {
    "value": "24918",
    "label": "Ballard - WV 24918",
    "county": "Monroe County",
    "state": "WV",
    "city": "Ballard"
  },
  {
    "value": "24920",
    "label": "Bartow - WV 24920",
    "county": "Pocahontas County",
    "state": "WV",
    "city": "Bartow"
  },
  {
    "value": "24924",
    "label": "Buckeye - WV 24924",
    "county": "Pocahontas County",
    "state": "WV",
    "city": "Buckeye"
  },
  {
    "value": "24925",
    "label": "Caldwell - WV 24925",
    "county": "Greenbrier County",
    "state": "WV",
    "city": "Caldwell"
  },
  {
    "value": "24927",
    "label": "Cass - WV 24927",
    "county": "Pocahontas County",
    "state": "WV",
    "city": "Cass"
  },
  {
    "value": "24931",
    "label": "Crawley - WV 24931",
    "county": "Greenbrier County",
    "state": "WV",
    "city": "Crawley"
  },
  {
    "value": "24934",
    "label": "Dunmore - WV 24934",
    "county": "Pocahontas County",
    "state": "WV",
    "city": "Dunmore"
  },
  {
    "value": "24935",
    "label": "Forest Hill - WV 24935",
    "county": "Summers County",
    "state": "WV",
    "city": "Forest Hill"
  },
  {
    "value": "24938",
    "label": "Frankford - WV 24938",
    "county": "Greenbrier County",
    "state": "WV",
    "city": "Frankford"
  },
  {
    "value": "24941",
    "label": "Gap Mills - WV 24941",
    "county": "Monroe County",
    "state": "WV",
    "city": "Gap Mills"
  },
  {
    "value": "24943",
    "label": "Grassy Meadows - WV 24943",
    "county": "Greenbrier County",
    "state": "WV",
    "city": "Grassy Meadows"
  },
  {
    "value": "24944",
    "label": "Green Bank - WV 24944",
    "county": "Pocahontas County",
    "state": "WV",
    "city": "Green Bank"
  },
  {
    "value": "24945",
    "label": "Greenville - WV 24945",
    "county": "Monroe County",
    "state": "WV",
    "city": "Greenville"
  },
  {
    "value": "24946",
    "label": "Hillsboro - WV 24946",
    "county": "Pocahontas County",
    "state": "WV",
    "city": "Hillsboro"
  },
  {
    "value": "24951",
    "label": "Lindside - WV 24951",
    "county": "Monroe County",
    "state": "WV",
    "city": "Lindside"
  },
  {
    "value": "24954",
    "label": "Marlinton - WV 24954",
    "county": "Pocahontas County",
    "state": "WV",
    "city": "Marlinton"
  },
  {
    "value": "24957",
    "label": "Maxwelton - WV 24957",
    "county": "Greenbrier County",
    "state": "WV",
    "city": "Maxwelton"
  },
  {
    "value": "24961",
    "label": "White Sulphur Springs - WV 24961",
    "county": "Greenbrier County",
    "state": "WV",
    "city": "White Sulphur Springs"
  },
  {
    "value": "24962",
    "label": "Pence Springs - WV 24962",
    "county": "Summers County",
    "state": "WV",
    "city": "Pence Springs"
  },
  {
    "value": "24963",
    "label": "Peterstown - WV 24963",
    "county": "Monroe County",
    "state": "WV",
    "city": "Peterstown"
  },
  {
    "value": "24966",
    "label": "Renick - WV 24966",
    "county": "Greenbrier County",
    "state": "WV",
    "city": "Renick"
  },
  {
    "value": "24970",
    "label": "Ronceverte - WV 24970",
    "county": "Greenbrier County",
    "state": "WV",
    "city": "Ronceverte"
  },
  {
    "value": "24974",
    "label": "Secondcreek - WV 24974",
    "county": "Monroe County",
    "state": "WV",
    "city": "Secondcreek"
  },
  {
    "value": "24976",
    "label": "Sinks Grove - WV 24976",
    "county": "Monroe County",
    "state": "WV",
    "city": "Sinks Grove"
  },
  {
    "value": "24977",
    "label": "Smoot - WV 24977",
    "county": "Greenbrier County",
    "state": "WV",
    "city": "Smoot"
  },
  {
    "value": "24981",
    "label": "Talcott - WV 24981",
    "county": "Summers County",
    "state": "WV",
    "city": "Talcott"
  },
  {
    "value": "24983",
    "label": "Union - WV 24983",
    "county": "Monroe County",
    "state": "WV",
    "city": "Union"
  },
  {
    "value": "24984",
    "label": "Waiteville - WV 24984",
    "county": "Monroe County",
    "state": "WV",
    "city": "Waiteville"
  },
  {
    "value": "24985",
    "label": "Wayside - WV 24985",
    "county": "Monroe County",
    "state": "WV",
    "city": "Wayside"
  },
  {
    "value": "24986",
    "label": "White Sulphur Springs - WV 24986",
    "county": "Greenbrier County",
    "state": "WV",
    "city": "White Sulphur Springs"
  },
  {
    "value": "24991",
    "label": "Williamsburg - WV 24991",
    "county": "Greenbrier County",
    "state": "WV",
    "city": "Williamsburg"
  },
  {
    "value": "24993",
    "label": "Wolfcreek - WV 24993",
    "county": "Monroe County",
    "state": "WV",
    "city": "Wolfcreek"
  },
  {
    "value": "25003",
    "label": "Alum Creek - WV 25003",
    "county": "Lincoln County",
    "state": "WV",
    "city": "Alum Creek"
  },
  {
    "value": "25005",
    "label": "Amma - WV 25005",
    "county": "Roane County",
    "state": "WV",
    "city": "Amma"
  },
  {
    "value": "25007",
    "label": "Arnett - WV 25007",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Arnett"
  },
  {
    "value": "25008",
    "label": "Artie - WV 25008",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Artie"
  },
  {
    "value": "25009",
    "label": "Ashford - WV 25009",
    "county": "Boone County",
    "state": "WV",
    "city": "Ashford"
  },
  {
    "value": "25015",
    "label": "Belle - WV 25015",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Belle"
  },
  {
    "value": "25019",
    "label": "Bickmore - WV 25019",
    "county": "Clay County",
    "state": "WV",
    "city": "Bickmore"
  },
  {
    "value": "25021",
    "label": "Bim - WV 25021",
    "county": "Boone County",
    "state": "WV",
    "city": "Bim"
  },
  {
    "value": "25024",
    "label": "Bloomingrose - WV 25024",
    "county": "Boone County",
    "state": "WV",
    "city": "Bloomingrose"
  },
  {
    "value": "25025",
    "label": "Blount - WV 25025",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Blount"
  },
  {
    "value": "25028",
    "label": "Bob White - WV 25028",
    "county": "Boone County",
    "state": "WV",
    "city": "Bob White"
  },
  {
    "value": "25030",
    "label": "Bomont - WV 25030",
    "county": "Clay County",
    "state": "WV",
    "city": "Bomont"
  },
  {
    "value": "25033",
    "label": "Buffalo - WV 25033",
    "county": "Putnam County",
    "state": "WV",
    "city": "Buffalo"
  },
  {
    "value": "25035",
    "label": "Cabin Creek - WV 25035",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Cabin Creek"
  },
  {
    "value": "25039",
    "label": "Cedar Grove - WV 25039",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Cedar Grove"
  },
  {
    "value": "25043",
    "label": "Clay - WV 25043",
    "county": "Clay County",
    "state": "WV",
    "city": "Clay"
  },
  {
    "value": "25044",
    "label": "Clear Creek - WV 25044",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Clear Creek"
  },
  {
    "value": "25045",
    "label": "Clendenin - WV 25045",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Clendenin"
  },
  {
    "value": "25047",
    "label": "Clothier - WV 25047",
    "county": "Logan County",
    "state": "WV",
    "city": "Clothier"
  },
  {
    "value": "25048",
    "label": "Colcord - WV 25048",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Colcord"
  },
  {
    "value": "25049",
    "label": "Comfort - WV 25049",
    "county": "Boone County",
    "state": "WV",
    "city": "Comfort"
  },
  {
    "value": "25051",
    "label": "Costa - WV 25051",
    "county": "Boone County",
    "state": "WV",
    "city": "Costa"
  },
  {
    "value": "25053",
    "label": "Danville - WV 25053",
    "county": "Boone County",
    "state": "WV",
    "city": "Danville"
  },
  {
    "value": "25059",
    "label": "Dixie - WV 25059",
    "county": "Fayette County",
    "state": "WV",
    "city": "Dixie"
  },
  {
    "value": "25060",
    "label": "Dorothy - WV 25060",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Dorothy"
  },
  {
    "value": "25062",
    "label": "Dry Creek - WV 25062",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Dry Creek"
  },
  {
    "value": "25063",
    "label": "Duck - WV 25063",
    "county": "Braxton County",
    "state": "WV",
    "city": "Duck"
  },
  {
    "value": "25064",
    "label": "Dunbar - WV 25064",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Dunbar"
  },
  {
    "value": "25067",
    "label": "East Bank - WV 25067",
    "county": "Kanawha County",
    "state": "WV",
    "city": "East Bank"
  },
  {
    "value": "25071",
    "label": "Elkview - WV 25071",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Elkview"
  },
  {
    "value": "25075",
    "label": "Eskdale - WV 25075",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Eskdale"
  },
  {
    "value": "25079",
    "label": "Falling Rock - WV 25079",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Falling Rock"
  },
  {
    "value": "25081",
    "label": "Foster - WV 25081",
    "county": "Boone County",
    "state": "WV",
    "city": "Foster"
  },
  {
    "value": "25082",
    "label": "Fraziers Bottom - WV 25082",
    "county": "Putnam County",
    "state": "WV",
    "city": "Fraziers Bottom"
  },
  {
    "value": "25083",
    "label": "Gallagher - WV 25083",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Gallagher"
  },
  {
    "value": "25085",
    "label": "Gauley Bridge - WV 25085",
    "county": "Fayette County",
    "state": "WV",
    "city": "Gauley Bridge"
  },
  {
    "value": "25088",
    "label": "Glen - WV 25088",
    "county": "Clay County",
    "state": "WV",
    "city": "Glen"
  },
  {
    "value": "25093",
    "label": "Gordon - WV 25093",
    "county": "Boone County",
    "state": "WV",
    "city": "Gordon"
  },
  {
    "value": "25103",
    "label": "Hansford - WV 25103",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Hansford"
  },
  {
    "value": "25106",
    "label": "Henderson - WV 25106",
    "county": "Mason County",
    "state": "WV",
    "city": "Henderson"
  },
  {
    "value": "25107",
    "label": "Hernshaw - WV 25107",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Hernshaw"
  },
  {
    "value": "25108",
    "label": "Hewett - WV 25108",
    "county": "Boone County",
    "state": "WV",
    "city": "Hewett"
  },
  {
    "value": "25111",
    "label": "Indore - WV 25111",
    "county": "Clay County",
    "state": "WV",
    "city": "Indore"
  },
  {
    "value": "25113",
    "label": "Ivydale - WV 25113",
    "county": "Clay County",
    "state": "WV",
    "city": "Ivydale"
  },
  {
    "value": "25114",
    "label": "Jeffrey - WV 25114",
    "county": "Boone County",
    "state": "WV",
    "city": "Jeffrey"
  },
  {
    "value": "25115",
    "label": "Kanawha Falls - WV 25115",
    "county": "Fayette County",
    "state": "WV",
    "city": "Kanawha Falls"
  },
  {
    "value": "25118",
    "label": "Kimberly - WV 25118",
    "county": "Fayette County",
    "state": "WV",
    "city": "Kimberly"
  },
  {
    "value": "25119",
    "label": "Kincaid - WV 25119",
    "county": "Fayette County",
    "state": "WV",
    "city": "Kincaid"
  },
  {
    "value": "25121",
    "label": "Lake - WV 25121",
    "county": "Logan County",
    "state": "WV",
    "city": "Lake"
  },
  {
    "value": "25123",
    "label": "Leon - WV 25123",
    "county": "Mason County",
    "state": "WV",
    "city": "Leon"
  },
  {
    "value": "25124",
    "label": "Liberty - WV 25124",
    "county": "Putnam County",
    "state": "WV",
    "city": "Liberty"
  },
  {
    "value": "25125",
    "label": "Lizemores - WV 25125",
    "county": "Clay County",
    "state": "WV",
    "city": "Lizemores"
  },
  {
    "value": "25130",
    "label": "Madison - WV 25130",
    "county": "Boone County",
    "state": "WV",
    "city": "Madison"
  },
  {
    "value": "25132",
    "label": "Mammoth - WV 25132",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Mammoth"
  },
  {
    "value": "25133",
    "label": "Maysel - WV 25133",
    "county": "Clay County",
    "state": "WV",
    "city": "Maysel"
  },
  {
    "value": "25136",
    "label": "Montgomery - WV 25136",
    "county": "Fayette County",
    "state": "WV",
    "city": "Montgomery"
  },
  {
    "value": "25139",
    "label": "Mount Carbon - WV 25139",
    "county": "Fayette County",
    "state": "WV",
    "city": "Mount Carbon"
  },
  {
    "value": "25140",
    "label": "Naoma - WV 25140",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Naoma"
  },
  {
    "value": "25141",
    "label": "Nebo - WV 25141",
    "county": "Clay County",
    "state": "WV",
    "city": "Nebo"
  },
  {
    "value": "25142",
    "label": "Nellis - WV 25142",
    "county": "Boone County",
    "state": "WV",
    "city": "Nellis"
  },
  {
    "value": "25143",
    "label": "Nitro - WV 25143",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Nitro"
  },
  {
    "value": "25148",
    "label": "Orgas - WV 25148",
    "county": "Boone County",
    "state": "WV",
    "city": "Orgas"
  },
  {
    "value": "25154",
    "label": "Peytona - WV 25154",
    "county": "Boone County",
    "state": "WV",
    "city": "Peytona"
  },
  {
    "value": "25159",
    "label": "Poca - WV 25159",
    "county": "Putnam County",
    "state": "WV",
    "city": "Poca"
  },
  {
    "value": "25160",
    "label": "Pond Gap - WV 25160",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Pond Gap"
  },
  {
    "value": "25161",
    "label": "Powellton - WV 25161",
    "county": "Fayette County",
    "state": "WV",
    "city": "Powellton"
  },
  {
    "value": "25164",
    "label": "Procious - WV 25164",
    "county": "Clay County",
    "state": "WV",
    "city": "Procious"
  },
  {
    "value": "25165",
    "label": "Racine - WV 25165",
    "county": "Boone County",
    "state": "WV",
    "city": "Racine"
  },
  {
    "value": "25168",
    "label": "Red House - WV 25168",
    "county": "Putnam County",
    "state": "WV",
    "city": "Red House"
  },
  {
    "value": "25169",
    "label": "Ridgeview - WV 25169",
    "county": "Boone County",
    "state": "WV",
    "city": "Ridgeview"
  },
  {
    "value": "25173",
    "label": "Robson - WV 25173",
    "county": "Fayette County",
    "state": "WV",
    "city": "Robson"
  },
  {
    "value": "25174",
    "label": "Rock Creek - WV 25174",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Rock Creek"
  },
  {
    "value": "25177",
    "label": "Saint Albans - WV 25177",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Saint Albans"
  },
  {
    "value": "25180",
    "label": "Saxon - WV 25180",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Saxon"
  },
  {
    "value": "25181",
    "label": "Seth - WV 25181",
    "county": "Boone County",
    "state": "WV",
    "city": "Seth"
  },
  {
    "value": "25187",
    "label": "Southside - WV 25187",
    "county": "Mason County",
    "state": "WV",
    "city": "Southside"
  },
  {
    "value": "25193",
    "label": "Sylvester - WV 25193",
    "county": "Boone County",
    "state": "WV",
    "city": "Sylvester"
  },
  {
    "value": "25202",
    "label": "Tornado - WV 25202",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Tornado"
  },
  {
    "value": "25203",
    "label": "Turtle Creek - WV 25203",
    "county": "Boone County",
    "state": "WV",
    "city": "Turtle Creek"
  },
  {
    "value": "25204",
    "label": "Twilight - WV 25204",
    "county": "Boone County",
    "state": "WV",
    "city": "Twilight"
  },
  {
    "value": "25206",
    "label": "Van - WV 25206",
    "county": "Boone County",
    "state": "WV",
    "city": "Van"
  },
  {
    "value": "25208",
    "label": "Wharton - WV 25208",
    "county": "Boone County",
    "state": "WV",
    "city": "Wharton"
  },
  {
    "value": "25209",
    "label": "Whitesville - WV 25209",
    "county": "Boone County",
    "state": "WV",
    "city": "Whitesville"
  },
  {
    "value": "25213",
    "label": "Winfield - WV 25213",
    "county": "Putnam County",
    "state": "WV",
    "city": "Winfield"
  },
  {
    "value": "25214",
    "label": "Winifrede - WV 25214",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Winifrede"
  },
  {
    "value": "25231",
    "label": "Advent - WV 25231",
    "county": "Jackson County",
    "state": "WV",
    "city": "Advent"
  },
  {
    "value": "25234",
    "label": "Arnoldsburg - WV 25234",
    "county": "Calhoun County",
    "state": "WV",
    "city": "Arnoldsburg"
  },
  {
    "value": "25235",
    "label": "Chloe - WV 25235",
    "county": "Calhoun County",
    "state": "WV",
    "city": "Chloe"
  },
  {
    "value": "25239",
    "label": "Cottageville - WV 25239",
    "county": "Jackson County",
    "state": "WV",
    "city": "Cottageville"
  },
  {
    "value": "25241",
    "label": "Evans - WV 25241",
    "county": "Jackson County",
    "state": "WV",
    "city": "Evans"
  },
  {
    "value": "25243",
    "label": "Gandeeville - WV 25243",
    "county": "Roane County",
    "state": "WV",
    "city": "Gandeeville"
  },
  {
    "value": "25244",
    "label": "Gay - WV 25244",
    "county": "Jackson County",
    "state": "WV",
    "city": "Gay"
  },
  {
    "value": "25245",
    "label": "Given - WV 25245",
    "county": "Jackson County",
    "state": "WV",
    "city": "Given"
  },
  {
    "value": "25248",
    "label": "Kenna - WV 25248",
    "county": "Jackson County",
    "state": "WV",
    "city": "Kenna"
  },
  {
    "value": "25251",
    "label": "Left Hand - WV 25251",
    "county": "Roane County",
    "state": "WV",
    "city": "Left Hand"
  },
  {
    "value": "25252",
    "label": "Le Roy - WV 25252",
    "county": "Jackson County",
    "state": "WV",
    "city": "Le Roy"
  },
  {
    "value": "25253",
    "label": "Letart - WV 25253",
    "county": "Mason County",
    "state": "WV",
    "city": "Letart"
  },
  {
    "value": "25259",
    "label": "Looneyville - WV 25259",
    "county": "Roane County",
    "state": "WV",
    "city": "Looneyville"
  },
  {
    "value": "25260",
    "label": "Mason - WV 25260",
    "county": "Mason County",
    "state": "WV",
    "city": "Mason"
  },
  {
    "value": "25261",
    "label": "Millstone - WV 25261",
    "county": "Calhoun County",
    "state": "WV",
    "city": "Millstone"
  },
  {
    "value": "25262",
    "label": "Millwood - WV 25262",
    "county": "Jackson County",
    "state": "WV",
    "city": "Millwood"
  },
  {
    "value": "25264",
    "label": "Mount Alto - WV 25264",
    "county": "Jackson County",
    "state": "WV",
    "city": "Mount Alto"
  },
  {
    "value": "25266",
    "label": "Newton - WV 25266",
    "county": "Roane County",
    "state": "WV",
    "city": "Newton"
  },
  {
    "value": "25267",
    "label": "Normantown - WV 25267",
    "county": "Gilmer County",
    "state": "WV",
    "city": "Normantown"
  },
  {
    "value": "25268",
    "label": "Orma - WV 25268",
    "county": "Calhoun County",
    "state": "WV",
    "city": "Orma"
  },
  {
    "value": "25270",
    "label": "Reedy - WV 25270",
    "county": "Roane County",
    "state": "WV",
    "city": "Reedy"
  },
  {
    "value": "25271",
    "label": "Ripley - WV 25271",
    "county": "Jackson County",
    "state": "WV",
    "city": "Ripley"
  },
  {
    "value": "25275",
    "label": "Sandyville - WV 25275",
    "county": "Jackson County",
    "state": "WV",
    "city": "Sandyville"
  },
  {
    "value": "25276",
    "label": "Spencer - WV 25276",
    "county": "Roane County",
    "state": "WV",
    "city": "Spencer"
  },
  {
    "value": "25285",
    "label": "Wallback - WV 25285",
    "county": "Clay County",
    "state": "WV",
    "city": "Wallback"
  },
  {
    "value": "25286",
    "label": "Walton - WV 25286",
    "county": "Roane County",
    "state": "WV",
    "city": "Walton"
  },
  {
    "value": "25287",
    "label": "West Columbia - WV 25287",
    "county": "Mason County",
    "state": "WV",
    "city": "West Columbia"
  },
  {
    "value": "25301",
    "label": "Charleston - WV 25301",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Charleston"
  },
  {
    "value": "25302",
    "label": "Charleston - WV 25302",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Charleston"
  },
  {
    "value": "25303",
    "label": "South Charleston - WV 25303",
    "county": "Kanawha County",
    "state": "WV",
    "city": "South Charleston"
  },
  {
    "value": "25304",
    "label": "Charleston - WV 25304",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Charleston"
  },
  {
    "value": "25305",
    "label": "Charleston - WV 25305",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Charleston"
  },
  {
    "value": "25306",
    "label": "Charleston - WV 25306",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Charleston"
  },
  {
    "value": "25309",
    "label": "South Charleston - WV 25309",
    "county": "Kanawha County",
    "state": "WV",
    "city": "South Charleston"
  },
  {
    "value": "25311",
    "label": "Charleston - WV 25311",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Charleston"
  },
  {
    "value": "25312",
    "label": "Charleston - WV 25312",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Charleston"
  },
  {
    "value": "25313",
    "label": "Charleston - WV 25313",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Charleston"
  },
  {
    "value": "25314",
    "label": "Charleston - WV 25314",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Charleston"
  },
  {
    "value": "25315",
    "label": "Charleston - WV 25315",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Charleston"
  },
  {
    "value": "25320",
    "label": "Charleston - WV 25320",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Charleston"
  },
  {
    "value": "25387",
    "label": "Charleston - WV 25387",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Charleston"
  },
  {
    "value": "25389",
    "label": "Charleston - WV 25389",
    "county": "Kanawha County",
    "state": "WV",
    "city": "Charleston"
  },
  {
    "value": "25401",
    "label": "Martinsburg - WV 25401",
    "county": "Berkeley County",
    "state": "WV",
    "city": "Martinsburg"
  },
  {
    "value": "25403",
    "label": "Martinsburg - WV 25403",
    "county": "Berkeley County",
    "state": "WV",
    "city": "Martinsburg"
  },
  {
    "value": "25404",
    "label": "Martinsburg - WV 25404",
    "county": "Berkeley County",
    "state": "WV",
    "city": "Martinsburg"
  },
  {
    "value": "25405",
    "label": "Martinsburg - WV 25405",
    "county": "Berkeley County",
    "state": "WV",
    "city": "Martinsburg"
  },
  {
    "value": "25411",
    "label": "Berkeley Springs - WV 25411",
    "county": "Morgan County",
    "state": "WV",
    "city": "Berkeley Springs"
  },
  {
    "value": "25413",
    "label": "Bunker Hill - WV 25413",
    "county": "Berkeley County",
    "state": "WV",
    "city": "Bunker Hill"
  },
  {
    "value": "25414",
    "label": "Charles Town - WV 25414",
    "county": "Jefferson County",
    "state": "WV",
    "city": "Charles Town"
  },
  {
    "value": "25419",
    "label": "Falling Waters - WV 25419",
    "county": "Berkeley County",
    "state": "WV",
    "city": "Falling Waters"
  },
  {
    "value": "25420",
    "label": "Gerrardstown - WV 25420",
    "county": "Berkeley County",
    "state": "WV",
    "city": "Gerrardstown"
  },
  {
    "value": "25421",
    "label": "Glengary - WV 25421",
    "county": "Berkeley County",
    "state": "WV",
    "city": "Glengary"
  },
  {
    "value": "25422",
    "label": "Great Cacapon - WV 25422",
    "county": "Morgan County",
    "state": "WV",
    "city": "Great Cacapon"
  },
  {
    "value": "25425",
    "label": "Harpers Ferry - WV 25425",
    "county": "Jefferson County",
    "state": "WV",
    "city": "Harpers Ferry"
  },
  {
    "value": "25427",
    "label": "Hedgesville - WV 25427",
    "county": "Berkeley County",
    "state": "WV",
    "city": "Hedgesville"
  },
  {
    "value": "25428",
    "label": "Inwood - WV 25428",
    "county": "Berkeley County",
    "state": "WV",
    "city": "Inwood"
  },
  {
    "value": "25430",
    "label": "Kearneysville - WV 25430",
    "county": "Jefferson County",
    "state": "WV",
    "city": "Kearneysville"
  },
  {
    "value": "25431",
    "label": "Levels - WV 25431",
    "county": "Hampshire County",
    "state": "WV",
    "city": "Levels"
  },
  {
    "value": "25432",
    "label": "Millville - WV 25432",
    "county": "Jefferson County",
    "state": "WV",
    "city": "Millville"
  },
  {
    "value": "25434",
    "label": "Paw Paw - WV 25434",
    "county": "Hampshire County",
    "state": "WV",
    "city": "Paw Paw"
  },
  {
    "value": "25437",
    "label": "Points - WV 25437",
    "county": "Hampshire County",
    "state": "WV",
    "city": "Points"
  },
  {
    "value": "25438",
    "label": "Ranson - WV 25438",
    "county": "Jefferson County",
    "state": "WV",
    "city": "Ranson"
  },
  {
    "value": "25442",
    "label": "Shenandoah Junction - WV 25442",
    "county": "Jefferson County",
    "state": "WV",
    "city": "Shenandoah Junction"
  },
  {
    "value": "25443",
    "label": "Shepherdstown - WV 25443",
    "county": "Jefferson County",
    "state": "WV",
    "city": "Shepherdstown"
  },
  {
    "value": "25444",
    "label": "Slanesville - WV 25444",
    "county": "Hampshire County",
    "state": "WV",
    "city": "Slanesville"
  },
  {
    "value": "25446",
    "label": "Summit Point - WV 25446",
    "county": "Jefferson County",
    "state": "WV",
    "city": "Summit Point"
  },
  {
    "value": "25501",
    "label": "Alkol - WV 25501",
    "county": "Lincoln County",
    "state": "WV",
    "city": "Alkol"
  },
  {
    "value": "25502",
    "label": "Apple Grove - WV 25502",
    "county": "Mason County",
    "state": "WV",
    "city": "Apple Grove"
  },
  {
    "value": "25503",
    "label": "Ashton - WV 25503",
    "county": "Mason County",
    "state": "WV",
    "city": "Ashton"
  },
  {
    "value": "25504",
    "label": "Barboursville - WV 25504",
    "county": "Cabell County",
    "state": "WV",
    "city": "Barboursville"
  },
  {
    "value": "25505",
    "label": "Big Creek - WV 25505",
    "county": "Logan County",
    "state": "WV",
    "city": "Big Creek"
  },
  {
    "value": "25506",
    "label": "Branchland - WV 25506",
    "county": "Lincoln County",
    "state": "WV",
    "city": "Branchland"
  },
  {
    "value": "25508",
    "label": "Chapmanville - WV 25508",
    "county": "Logan County",
    "state": "WV",
    "city": "Chapmanville"
  },
  {
    "value": "25510",
    "label": "Culloden - WV 25510",
    "county": "Cabell County",
    "state": "WV",
    "city": "Culloden"
  },
  {
    "value": "25511",
    "label": "Dunlow - WV 25511",
    "county": "Wayne County",
    "state": "WV",
    "city": "Dunlow"
  },
  {
    "value": "25512",
    "label": "East Lynn - WV 25512",
    "county": "Wayne County",
    "state": "WV",
    "city": "East Lynn"
  },
  {
    "value": "25514",
    "label": "Fort Gay - WV 25514",
    "county": "Wayne County",
    "state": "WV",
    "city": "Fort Gay"
  },
  {
    "value": "25515",
    "label": "Gallipolis Ferry - WV 25515",
    "county": "Mason County",
    "state": "WV",
    "city": "Gallipolis Ferry"
  },
  {
    "value": "25517",
    "label": "Genoa - WV 25517",
    "county": "Wayne County",
    "state": "WV",
    "city": "Genoa"
  },
  {
    "value": "25520",
    "label": "Glenwood - WV 25520",
    "county": "Mason County",
    "state": "WV",
    "city": "Glenwood"
  },
  {
    "value": "25521",
    "label": "Griffithsville - WV 25521",
    "county": "Lincoln County",
    "state": "WV",
    "city": "Griffithsville"
  },
  {
    "value": "25523",
    "label": "Hamlin - WV 25523",
    "county": "Lincoln County",
    "state": "WV",
    "city": "Hamlin"
  },
  {
    "value": "25524",
    "label": "Harts - WV 25524",
    "county": "Lincoln County",
    "state": "WV",
    "city": "Harts"
  },
  {
    "value": "25526",
    "label": "Hurricane - WV 25526",
    "county": "Putnam County",
    "state": "WV",
    "city": "Hurricane"
  },
  {
    "value": "25529",
    "label": "Julian - WV 25529",
    "county": "Boone County",
    "state": "WV",
    "city": "Julian"
  },
  {
    "value": "25530",
    "label": "Kenova - WV 25530",
    "county": "Wayne County",
    "state": "WV",
    "city": "Kenova"
  },
  {
    "value": "25534",
    "label": "Kiahsville - WV 25534",
    "county": "Wayne County",
    "state": "WV",
    "city": "Kiahsville"
  },
  {
    "value": "25535",
    "label": "Lavalette - WV 25535",
    "county": "Wayne County",
    "state": "WV",
    "city": "Lavalette"
  },
  {
    "value": "25537",
    "label": "Lesage - WV 25537",
    "county": "Cabell County",
    "state": "WV",
    "city": "Lesage"
  },
  {
    "value": "25540",
    "label": "Midkiff - WV 25540",
    "county": "Lincoln County",
    "state": "WV",
    "city": "Midkiff"
  },
  {
    "value": "25541",
    "label": "Milton - WV 25541",
    "county": "Cabell County",
    "state": "WV",
    "city": "Milton"
  },
  {
    "value": "25544",
    "label": "Myra - WV 25544",
    "county": "Lincoln County",
    "state": "WV",
    "city": "Myra"
  },
  {
    "value": "25545",
    "label": "Ona - WV 25545",
    "county": "Cabell County",
    "state": "WV",
    "city": "Ona"
  },
  {
    "value": "25547",
    "label": "Pecks Mill - WV 25547",
    "county": "Logan County",
    "state": "WV",
    "city": "Pecks Mill"
  },
  {
    "value": "25550",
    "label": "Point Pleasant - WV 25550",
    "county": "Mason County",
    "state": "WV",
    "city": "Point Pleasant"
  },
  {
    "value": "25555",
    "label": "Prichard - WV 25555",
    "county": "Wayne County",
    "state": "WV",
    "city": "Prichard"
  },
  {
    "value": "25557",
    "label": "Ranger - WV 25557",
    "county": "Lincoln County",
    "state": "WV",
    "city": "Ranger"
  },
  {
    "value": "25559",
    "label": "Salt Rock - WV 25559",
    "county": "Cabell County",
    "state": "WV",
    "city": "Salt Rock"
  },
  {
    "value": "25560",
    "label": "Scott Depot - WV 25560",
    "county": "Putnam County",
    "state": "WV",
    "city": "Scott Depot"
  },
  {
    "value": "25564",
    "label": "Sod - WV 25564",
    "county": "Lincoln County",
    "state": "WV",
    "city": "Sod"
  },
  {
    "value": "25565",
    "label": "Spurlockville - WV 25565",
    "county": "Lincoln County",
    "state": "WV",
    "city": "Spurlockville"
  },
  {
    "value": "25567",
    "label": "Sumerco - WV 25567",
    "county": "Lincoln County",
    "state": "WV",
    "city": "Sumerco"
  },
  {
    "value": "25570",
    "label": "Wayne - WV 25570",
    "county": "Wayne County",
    "state": "WV",
    "city": "Wayne"
  },
  {
    "value": "25571",
    "label": "West Hamlin - WV 25571",
    "county": "Lincoln County",
    "state": "WV",
    "city": "West Hamlin"
  },
  {
    "value": "25572",
    "label": "Woodville - WV 25572",
    "county": "Lincoln County",
    "state": "WV",
    "city": "Woodville"
  },
  {
    "value": "25573",
    "label": "Yawkey - WV 25573",
    "county": "Lincoln County",
    "state": "WV",
    "city": "Yawkey"
  },
  {
    "value": "25601",
    "label": "Logan - WV 25601",
    "county": "Logan County",
    "state": "WV",
    "city": "Logan"
  },
  {
    "value": "25607",
    "label": "Amherstdale - WV 25607",
    "county": "Logan County",
    "state": "WV",
    "city": "Amherstdale"
  },
  {
    "value": "25608",
    "label": "Baisden - WV 25608",
    "county": "Mingo County",
    "state": "WV",
    "city": "Baisden"
  },
  {
    "value": "25612",
    "label": "Chauncey - WV 25612",
    "county": "Logan County",
    "state": "WV",
    "city": "Chauncey"
  },
  {
    "value": "25614",
    "label": "Cora - WV 25614",
    "county": "Logan County",
    "state": "WV",
    "city": "Cora"
  },
  {
    "value": "25617",
    "label": "Davin - WV 25617",
    "county": "Logan County",
    "state": "WV",
    "city": "Davin"
  },
  {
    "value": "25621",
    "label": "Gilbert - WV 25621",
    "county": "Mingo County",
    "state": "WV",
    "city": "Gilbert"
  },
  {
    "value": "25625",
    "label": "Holden - WV 25625",
    "county": "Logan County",
    "state": "WV",
    "city": "Holden"
  },
  {
    "value": "25632",
    "label": "Lyburn - WV 25632",
    "county": "Logan County",
    "state": "WV",
    "city": "Lyburn"
  },
  {
    "value": "25635",
    "label": "Man - WV 25635",
    "county": "Logan County",
    "state": "WV",
    "city": "Man"
  },
  {
    "value": "25638",
    "label": "Omar - WV 25638",
    "county": "Logan County",
    "state": "WV",
    "city": "Omar"
  },
  {
    "value": "25650",
    "label": "Verner - WV 25650",
    "county": "Mingo County",
    "state": "WV",
    "city": "Verner"
  },
  {
    "value": "25651",
    "label": "Wharncliffe - WV 25651",
    "county": "Mingo County",
    "state": "WV",
    "city": "Wharncliffe"
  },
  {
    "value": "25654",
    "label": "Yolyn - WV 25654",
    "county": "Logan County",
    "state": "WV",
    "city": "Yolyn"
  },
  {
    "value": "25661",
    "label": "Williamson - WV 25661",
    "county": "Mingo County",
    "state": "WV",
    "city": "Williamson"
  },
  {
    "value": "25666",
    "label": "Breeden - WV 25666",
    "county": "Mingo County",
    "state": "WV",
    "city": "Breeden"
  },
  {
    "value": "25669",
    "label": "Crum - WV 25669",
    "county": "Wayne County",
    "state": "WV",
    "city": "Crum"
  },
  {
    "value": "25670",
    "label": "Delbarton - WV 25670",
    "county": "Mingo County",
    "state": "WV",
    "city": "Delbarton"
  },
  {
    "value": "25671",
    "label": "Dingess - WV 25671",
    "county": "Mingo County",
    "state": "WV",
    "city": "Dingess"
  },
  {
    "value": "25672",
    "label": "Edgarton - WV 25672",
    "county": "Mingo County",
    "state": "WV",
    "city": "Edgarton"
  },
  {
    "value": "25674",
    "label": "Kermit - WV 25674",
    "county": "Mingo County",
    "state": "WV",
    "city": "Kermit"
  },
  {
    "value": "25676",
    "label": "Lenore - WV 25676",
    "county": "Mingo County",
    "state": "WV",
    "city": "Lenore"
  },
  {
    "value": "25678",
    "label": "Matewan - WV 25678",
    "county": "Mingo County",
    "state": "WV",
    "city": "Matewan"
  },
  {
    "value": "25699",
    "label": "Wilsondale - WV 25699",
    "county": "Wayne County",
    "state": "WV",
    "city": "Wilsondale"
  },
  {
    "value": "25701",
    "label": "Huntington - WV 25701",
    "county": "Cabell County",
    "state": "WV",
    "city": "Huntington"
  },
  {
    "value": "25702",
    "label": "Huntington - WV 25702",
    "county": "Cabell County",
    "state": "WV",
    "city": "Huntington"
  },
  {
    "value": "25703",
    "label": "Huntington - WV 25703",
    "county": "Cabell County",
    "state": "WV",
    "city": "Huntington"
  },
  {
    "value": "25704",
    "label": "Huntington - WV 25704",
    "county": "Wayne County",
    "state": "WV",
    "city": "Huntington"
  },
  {
    "value": "25705",
    "label": "Huntington - WV 25705",
    "county": "Cabell County",
    "state": "WV",
    "city": "Huntington"
  },
  {
    "value": "25801",
    "label": "Beckley - WV 25801",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Beckley"
  },
  {
    "value": "25811",
    "label": "Amigo - WV 25811",
    "county": "Wyoming County",
    "state": "WV",
    "city": "Amigo"
  },
  {
    "value": "25812",
    "label": "Ansted - WV 25812",
    "county": "Fayette County",
    "state": "WV",
    "city": "Ansted"
  },
  {
    "value": "25813",
    "label": "Beaver - WV 25813",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Beaver"
  },
  {
    "value": "25817",
    "label": "Bolt - WV 25817",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Bolt"
  },
  {
    "value": "25820",
    "label": "Camp Creek - WV 25820",
    "county": "Mercer County",
    "state": "WV",
    "city": "Camp Creek"
  },
  {
    "value": "25823",
    "label": "Coal City - WV 25823",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Coal City"
  },
  {
    "value": "25825",
    "label": "Cool Ridge - WV 25825",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Cool Ridge"
  },
  {
    "value": "25827",
    "label": "Crab Orchard - WV 25827",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Crab Orchard"
  },
  {
    "value": "25831",
    "label": "Danese - WV 25831",
    "county": "Fayette County",
    "state": "WV",
    "city": "Danese"
  },
  {
    "value": "25832",
    "label": "Daniels - WV 25832",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Daniels"
  },
  {
    "value": "25837",
    "label": "Edmond - WV 25837",
    "county": "Fayette County",
    "state": "WV",
    "city": "Edmond"
  },
  {
    "value": "25839",
    "label": "Fairdale - WV 25839",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Fairdale"
  },
  {
    "value": "25840",
    "label": "Fayetteville - WV 25840",
    "county": "Fayette County",
    "state": "WV",
    "city": "Fayetteville"
  },
  {
    "value": "25841",
    "label": "Flat Top - WV 25841",
    "county": "Mercer County",
    "state": "WV",
    "city": "Flat Top"
  },
  {
    "value": "25843",
    "label": "Ghent - WV 25843",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Ghent"
  },
  {
    "value": "25844",
    "label": "Glen Daniel - WV 25844",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Glen Daniel"
  },
  {
    "value": "25845",
    "label": "Glen Fork - WV 25845",
    "county": "Wyoming County",
    "state": "WV",
    "city": "Glen Fork"
  },
  {
    "value": "25846",
    "label": "Glen Jean - WV 25846",
    "county": "Fayette County",
    "state": "WV",
    "city": "Glen Jean"
  },
  {
    "value": "25848",
    "label": "Glen Rogers - WV 25848",
    "county": "Wyoming County",
    "state": "WV",
    "city": "Glen Rogers"
  },
  {
    "value": "25849",
    "label": "Glen White - WV 25849",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Glen White"
  },
  {
    "value": "25854",
    "label": "Hico - WV 25854",
    "county": "Fayette County",
    "state": "WV",
    "city": "Hico"
  },
  {
    "value": "25855",
    "label": "Hilltop - WV 25855",
    "county": "Fayette County",
    "state": "WV",
    "city": "Hilltop"
  },
  {
    "value": "25857",
    "label": "Josephine - WV 25857",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Josephine"
  },
  {
    "value": "25862",
    "label": "Lansing - WV 25862",
    "county": "Fayette County",
    "state": "WV",
    "city": "Lansing"
  },
  {
    "value": "25864",
    "label": "Layland - WV 25864",
    "county": "Fayette County",
    "state": "WV",
    "city": "Layland"
  },
  {
    "value": "25865",
    "label": "Lester - WV 25865",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Lester"
  },
  {
    "value": "25868",
    "label": "Lookout - WV 25868",
    "county": "Fayette County",
    "state": "WV",
    "city": "Lookout"
  },
  {
    "value": "25870",
    "label": "Maben - WV 25870",
    "county": "Wyoming County",
    "state": "WV",
    "city": "Maben"
  },
  {
    "value": "25876",
    "label": "Saulsville - WV 25876",
    "county": "Wyoming County",
    "state": "WV",
    "city": "Saulsville"
  },
  {
    "value": "25880",
    "label": "Mount Hope - WV 25880",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Mount Hope"
  },
  {
    "value": "25882",
    "label": "Mullens - WV 25882",
    "county": "Wyoming County",
    "state": "WV",
    "city": "Mullens"
  },
  {
    "value": "25901",
    "label": "Oak Hill - WV 25901",
    "county": "Fayette County",
    "state": "WV",
    "city": "Oak Hill"
  },
  {
    "value": "25902",
    "label": "Odd - WV 25902",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Odd"
  },
  {
    "value": "25908",
    "label": "Princewick - WV 25908",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Princewick"
  },
  {
    "value": "25913",
    "label": "Ravencliff - WV 25913",
    "county": "Wyoming County",
    "state": "WV",
    "city": "Ravencliff"
  },
  {
    "value": "25915",
    "label": "Rhodell - WV 25915",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Rhodell"
  },
  {
    "value": "25917",
    "label": "Scarbro - WV 25917",
    "county": "Fayette County",
    "state": "WV",
    "city": "Scarbro"
  },
  {
    "value": "25918",
    "label": "Shady Spring - WV 25918",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Shady Spring"
  },
  {
    "value": "25920",
    "label": "Slab Fork - WV 25920",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Slab Fork"
  },
  {
    "value": "25922",
    "label": "Spanishburg - WV 25922",
    "county": "Mercer County",
    "state": "WV",
    "city": "Spanishburg"
  },
  {
    "value": "25926",
    "label": "Beckley - WV 25926",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Beckley"
  },
  {
    "value": "25928",
    "label": "Stephenson - WV 25928",
    "county": "Wyoming County",
    "state": "WV",
    "city": "Stephenson"
  },
  {
    "value": "25932",
    "label": "Surveyor - WV 25932",
    "county": "Raleigh County",
    "state": "WV",
    "city": "Surveyor"
  },
  {
    "value": "25936",
    "label": "Thurmond - WV 25936",
    "county": "Fayette County",
    "state": "WV",
    "city": "Thurmond"
  },
  {
    "value": "25938",
    "label": "Victor - WV 25938",
    "county": "Fayette County",
    "state": "WV",
    "city": "Victor"
  },
  {
    "value": "25951",
    "label": "Hinton - WV 25951",
    "county": "Summers County",
    "state": "WV",
    "city": "Hinton"
  },
  {
    "value": "25958",
    "label": "Charmco - WV 25958",
    "county": "Greenbrier County",
    "state": "WV",
    "city": "Charmco"
  },
  {
    "value": "25962",
    "label": "Rainelle - WV 25962",
    "county": "Greenbrier County",
    "state": "WV",
    "city": "Rainelle"
  },
  {
    "value": "25965",
    "label": "Elton - WV 25965",
    "county": "Summers County",
    "state": "WV",
    "city": "Elton"
  },
  {
    "value": "25969",
    "label": "Jumping Branch - WV 25969",
    "county": "Summers County",
    "state": "WV",
    "city": "Jumping Branch"
  },
  {
    "value": "25971",
    "label": "Lerona - WV 25971",
    "county": "Mercer County",
    "state": "WV",
    "city": "Lerona"
  },
  {
    "value": "25976",
    "label": "Meadow Bridge - WV 25976",
    "county": "Fayette County",
    "state": "WV",
    "city": "Meadow Bridge"
  },
  {
    "value": "25977",
    "label": "Meadow Creek - WV 25977",
    "county": "Summers County",
    "state": "WV",
    "city": "Meadow Creek"
  },
  {
    "value": "25978",
    "label": "Nimitz - WV 25978",
    "county": "Summers County",
    "state": "WV",
    "city": "Nimitz"
  },
  {
    "value": "25979",
    "label": "Pipestem - WV 25979",
    "county": "Summers County",
    "state": "WV",
    "city": "Pipestem"
  },
  {
    "value": "25981",
    "label": "Quinwood - WV 25981",
    "county": "Greenbrier County",
    "state": "WV",
    "city": "Quinwood"
  },
  {
    "value": "25984",
    "label": "Rupert - WV 25984",
    "county": "Greenbrier County",
    "state": "WV",
    "city": "Rupert"
  },
  {
    "value": "25985",
    "label": "Sandstone - WV 25985",
    "county": "Summers County",
    "state": "WV",
    "city": "Sandstone"
  },
  {
    "value": "25989",
    "label": "White Oak - WV 25989",
    "county": "Raleigh County",
    "state": "WV",
    "city": "White Oak"
  },
  {
    "value": "26003",
    "label": "Wheeling - WV 26003",
    "county": "Ohio County",
    "state": "WV",
    "city": "Wheeling"
  },
  {
    "value": "26031",
    "label": "Benwood - WV 26031",
    "county": "Marshall County",
    "state": "WV",
    "city": "Benwood"
  },
  {
    "value": "26032",
    "label": "Bethany - WV 26032",
    "county": "Brooke County",
    "state": "WV",
    "city": "Bethany"
  },
  {
    "value": "26033",
    "label": "Cameron - WV 26033",
    "county": "Marshall County",
    "state": "WV",
    "city": "Cameron"
  },
  {
    "value": "26034",
    "label": "Chester - WV 26034",
    "county": "Hancock County",
    "state": "WV",
    "city": "Chester"
  },
  {
    "value": "26035",
    "label": "Colliers - WV 26035",
    "county": "Brooke County",
    "state": "WV",
    "city": "Colliers"
  },
  {
    "value": "26036",
    "label": "Dallas - WV 26036",
    "county": "Marshall County",
    "state": "WV",
    "city": "Dallas"
  },
  {
    "value": "26037",
    "label": "Follansbee - WV 26037",
    "county": "Brooke County",
    "state": "WV",
    "city": "Follansbee"
  },
  {
    "value": "26038",
    "label": "Glen Dale - WV 26038",
    "county": "Marshall County",
    "state": "WV",
    "city": "Glen Dale"
  },
  {
    "value": "26039",
    "label": "Glen Easton - WV 26039",
    "county": "Marshall County",
    "state": "WV",
    "city": "Glen Easton"
  },
  {
    "value": "26040",
    "label": "Mcmechen - WV 26040",
    "county": "Marshall County",
    "state": "WV",
    "city": "Mcmechen"
  },
  {
    "value": "26041",
    "label": "Moundsville - WV 26041",
    "county": "Marshall County",
    "state": "WV",
    "city": "Moundsville"
  },
  {
    "value": "26047",
    "label": "New Cumberland - WV 26047",
    "county": "Hancock County",
    "state": "WV",
    "city": "New Cumberland"
  },
  {
    "value": "26050",
    "label": "Newell - WV 26050",
    "county": "Hancock County",
    "state": "WV",
    "city": "Newell"
  },
  {
    "value": "26055",
    "label": "Proctor - WV 26055",
    "county": "Marshall County",
    "state": "WV",
    "city": "Proctor"
  },
  {
    "value": "26059",
    "label": "Triadelphia - WV 26059",
    "county": "Ohio County",
    "state": "WV",
    "city": "Triadelphia"
  },
  {
    "value": "26060",
    "label": "Valley Grove - WV 26060",
    "county": "Ohio County",
    "state": "WV",
    "city": "Valley Grove"
  },
  {
    "value": "26062",
    "label": "Weirton - WV 26062",
    "county": "Hancock County",
    "state": "WV",
    "city": "Weirton"
  },
  {
    "value": "26070",
    "label": "Wellsburg - WV 26070",
    "county": "Brooke County",
    "state": "WV",
    "city": "Wellsburg"
  },
  {
    "value": "26101",
    "label": "Parkersburg - WV 26101",
    "county": "Wood County",
    "state": "WV",
    "city": "Parkersburg"
  },
  {
    "value": "26104",
    "label": "Parkersburg - WV 26104",
    "county": "Wood County",
    "state": "WV",
    "city": "Parkersburg"
  },
  {
    "value": "26105",
    "label": "Vienna - WV 26105",
    "county": "Wood County",
    "state": "WV",
    "city": "Vienna"
  },
  {
    "value": "26133",
    "label": "Belleville - WV 26133",
    "county": "Wood County",
    "state": "WV",
    "city": "Belleville"
  },
  {
    "value": "26134",
    "label": "Belmont - WV 26134",
    "county": "Pleasants County",
    "state": "WV",
    "city": "Belmont"
  },
  {
    "value": "26136",
    "label": "Big Bend - WV 26136",
    "county": "Calhoun County",
    "state": "WV",
    "city": "Big Bend"
  },
  {
    "value": "26137",
    "label": "Big Springs - WV 26137",
    "county": "Calhoun County",
    "state": "WV",
    "city": "Big Springs"
  },
  {
    "value": "26138",
    "label": "Brohard - WV 26138",
    "county": "Wirt County",
    "state": "WV",
    "city": "Brohard"
  },
  {
    "value": "26141",
    "label": "Creston - WV 26141",
    "county": "Calhoun County",
    "state": "WV",
    "city": "Creston"
  },
  {
    "value": "26142",
    "label": "Davisville - WV 26142",
    "county": "Wood County",
    "state": "WV",
    "city": "Davisville"
  },
  {
    "value": "26143",
    "label": "Elizabeth - WV 26143",
    "county": "Wirt County",
    "state": "WV",
    "city": "Elizabeth"
  },
  {
    "value": "26146",
    "label": "Friendly - WV 26146",
    "county": "Tyler County",
    "state": "WV",
    "city": "Friendly"
  },
  {
    "value": "26147",
    "label": "Grantsville - WV 26147",
    "county": "Calhoun County",
    "state": "WV",
    "city": "Grantsville"
  },
  {
    "value": "26148",
    "label": "Macfarlan - WV 26148",
    "county": "Ritchie County",
    "state": "WV",
    "city": "Macfarlan"
  },
  {
    "value": "26149",
    "label": "Middlebourne - WV 26149",
    "county": "Tyler County",
    "state": "WV",
    "city": "Middlebourne"
  },
  {
    "value": "26150",
    "label": "Mineral Wells - WV 26150",
    "county": "Wood County",
    "state": "WV",
    "city": "Mineral Wells"
  },
  {
    "value": "26151",
    "label": "Mount Zion - WV 26151",
    "county": "Calhoun County",
    "state": "WV",
    "city": "Mount Zion"
  },
  {
    "value": "26152",
    "label": "Munday - WV 26152",
    "county": "Wirt County",
    "state": "WV",
    "city": "Munday"
  },
  {
    "value": "26155",
    "label": "New Martinsville - WV 26155",
    "county": "Wetzel County",
    "state": "WV",
    "city": "New Martinsville"
  },
  {
    "value": "26159",
    "label": "Paden City - WV 26159",
    "county": "Wetzel County",
    "state": "WV",
    "city": "Paden City"
  },
  {
    "value": "26160",
    "label": "Palestine - WV 26160",
    "county": "Wirt County",
    "state": "WV",
    "city": "Palestine"
  },
  {
    "value": "26161",
    "label": "Petroleum - WV 26161",
    "county": "Ritchie County",
    "state": "WV",
    "city": "Petroleum"
  },
  {
    "value": "26162",
    "label": "Porters Falls - WV 26162",
    "county": "Wetzel County",
    "state": "WV",
    "city": "Porters Falls"
  },
  {
    "value": "26164",
    "label": "Ravenswood - WV 26164",
    "county": "Jackson County",
    "state": "WV",
    "city": "Ravenswood"
  },
  {
    "value": "26167",
    "label": "Reader - WV 26167",
    "county": "Wetzel County",
    "state": "WV",
    "city": "Reader"
  },
  {
    "value": "26169",
    "label": "Rockport - WV 26169",
    "county": "Wood County",
    "state": "WV",
    "city": "Rockport"
  },
  {
    "value": "26170",
    "label": "Saint Marys - WV 26170",
    "county": "Pleasants County",
    "state": "WV",
    "city": "Saint Marys"
  },
  {
    "value": "26175",
    "label": "Sistersville - WV 26175",
    "county": "Tyler County",
    "state": "WV",
    "city": "Sistersville"
  },
  {
    "value": "26178",
    "label": "Smithville - WV 26178",
    "county": "Ritchie County",
    "state": "WV",
    "city": "Smithville"
  },
  {
    "value": "26180",
    "label": "Walker - WV 26180",
    "county": "Wood County",
    "state": "WV",
    "city": "Walker"
  },
  {
    "value": "26181",
    "label": "Washington - WV 26181",
    "county": "Wood County",
    "state": "WV",
    "city": "Washington"
  },
  {
    "value": "26184",
    "label": "Waverly - WV 26184",
    "county": "Wood County",
    "state": "WV",
    "city": "Waverly"
  },
  {
    "value": "26187",
    "label": "Williamstown - WV 26187",
    "county": "Wood County",
    "state": "WV",
    "city": "Williamstown"
  },
  {
    "value": "26201",
    "label": "Buckhannon - WV 26201",
    "county": "Upshur County",
    "state": "WV",
    "city": "Buckhannon"
  },
  {
    "value": "26202",
    "label": "Fenwick - WV 26202",
    "county": "Nicholas County",
    "state": "WV",
    "city": "Fenwick"
  },
  {
    "value": "26203",
    "label": "Erbacon - WV 26203",
    "county": "Webster County",
    "state": "WV",
    "city": "Erbacon"
  },
  {
    "value": "26205",
    "label": "Craigsville - WV 26205",
    "county": "Nicholas County",
    "state": "WV",
    "city": "Craigsville"
  },
  {
    "value": "26206",
    "label": "Cowen - WV 26206",
    "county": "Webster County",
    "state": "WV",
    "city": "Cowen"
  },
  {
    "value": "26208",
    "label": "Camden On Gauley - WV 26208",
    "county": "Webster County",
    "state": "WV",
    "city": "Camden On Gauley"
  },
  {
    "value": "26210",
    "label": "Adrian - WV 26210",
    "county": "Upshur County",
    "state": "WV",
    "city": "Adrian"
  },
  {
    "value": "26215",
    "label": "Cleveland - WV 26215",
    "county": "Webster County",
    "state": "WV",
    "city": "Cleveland"
  },
  {
    "value": "26217",
    "label": "Diana - WV 26217",
    "county": "Webster County",
    "state": "WV",
    "city": "Diana"
  },
  {
    "value": "26218",
    "label": "French Creek - WV 26218",
    "county": "Upshur County",
    "state": "WV",
    "city": "French Creek"
  },
  {
    "value": "26222",
    "label": "Hacker Valley - WV 26222",
    "county": "Webster County",
    "state": "WV",
    "city": "Hacker Valley"
  },
  {
    "value": "26224",
    "label": "Helvetia - WV 26224",
    "county": "Randolph County",
    "state": "WV",
    "city": "Helvetia"
  },
  {
    "value": "26228",
    "label": "Kanawha Head - WV 26228",
    "county": "Upshur County",
    "state": "WV",
    "city": "Kanawha Head"
  },
  {
    "value": "26230",
    "label": "Pickens - WV 26230",
    "county": "Randolph County",
    "state": "WV",
    "city": "Pickens"
  },
  {
    "value": "26234",
    "label": "Rock Cave - WV 26234",
    "county": "Upshur County",
    "state": "WV",
    "city": "Rock Cave"
  },
  {
    "value": "26236",
    "label": "Selbyville - WV 26236",
    "county": "Upshur County",
    "state": "WV",
    "city": "Selbyville"
  },
  {
    "value": "26237",
    "label": "Tallmansville - WV 26237",
    "county": "Upshur County",
    "state": "WV",
    "city": "Tallmansville"
  },
  {
    "value": "26238",
    "label": "Volga - WV 26238",
    "county": "Barbour County",
    "state": "WV",
    "city": "Volga"
  },
  {
    "value": "26241",
    "label": "Elkins - WV 26241",
    "county": "Randolph County",
    "state": "WV",
    "city": "Elkins"
  },
  {
    "value": "26250",
    "label": "Belington - WV 26250",
    "county": "Barbour County",
    "state": "WV",
    "city": "Belington"
  },
  {
    "value": "26253",
    "label": "Beverly - WV 26253",
    "county": "Randolph County",
    "state": "WV",
    "city": "Beverly"
  },
  {
    "value": "26254",
    "label": "Bowden - WV 26254",
    "county": "Randolph County",
    "state": "WV",
    "city": "Bowden"
  },
  {
    "value": "26257",
    "label": "Coalton - WV 26257",
    "county": "Randolph County",
    "state": "WV",
    "city": "Coalton"
  },
  {
    "value": "26260",
    "label": "Davis - WV 26260",
    "county": "Tucker County",
    "state": "WV",
    "city": "Davis"
  },
  {
    "value": "26261",
    "label": "Richwood - WV 26261",
    "county": "Nicholas County",
    "state": "WV",
    "city": "Richwood"
  },
  {
    "value": "26263",
    "label": "Dryfork - WV 26263",
    "county": "Randolph County",
    "state": "WV",
    "city": "Dryfork"
  },
  {
    "value": "26264",
    "label": "Durbin - WV 26264",
    "county": "Pocahontas County",
    "state": "WV",
    "city": "Durbin"
  },
  {
    "value": "26266",
    "label": "Upperglade - WV 26266",
    "county": "Webster County",
    "state": "WV",
    "city": "Upperglade"
  },
  {
    "value": "26267",
    "label": "Ellamore - WV 26267",
    "county": "Randolph County",
    "state": "WV",
    "city": "Ellamore"
  },
  {
    "value": "26268",
    "label": "Glady - WV 26268",
    "county": "Randolph County",
    "state": "WV",
    "city": "Glady"
  },
  {
    "value": "26269",
    "label": "Hambleton - WV 26269",
    "county": "Tucker County",
    "state": "WV",
    "city": "Hambleton"
  },
  {
    "value": "26270",
    "label": "Harman - WV 26270",
    "county": "Randolph County",
    "state": "WV",
    "city": "Harman"
  },
  {
    "value": "26271",
    "label": "Hendricks - WV 26271",
    "county": "Tucker County",
    "state": "WV",
    "city": "Hendricks"
  },
  {
    "value": "26273",
    "label": "Huttonsville - WV 26273",
    "county": "Randolph County",
    "state": "WV",
    "city": "Huttonsville"
  },
  {
    "value": "26276",
    "label": "Kerens - WV 26276",
    "county": "Randolph County",
    "state": "WV",
    "city": "Kerens"
  },
  {
    "value": "26278",
    "label": "Mabie - WV 26278",
    "county": "Randolph County",
    "state": "WV",
    "city": "Mabie"
  },
  {
    "value": "26280",
    "label": "Mill Creek - WV 26280",
    "county": "Randolph County",
    "state": "WV",
    "city": "Mill Creek"
  },
  {
    "value": "26282",
    "label": "Monterville - WV 26282",
    "county": "Randolph County",
    "state": "WV",
    "city": "Monterville"
  },
  {
    "value": "26283",
    "label": "Montrose - WV 26283",
    "county": "Randolph County",
    "state": "WV",
    "city": "Montrose"
  },
  {
    "value": "26285",
    "label": "Norton - WV 26285",
    "county": "Randolph County",
    "state": "WV",
    "city": "Norton"
  },
  {
    "value": "26287",
    "label": "Parsons - WV 26287",
    "county": "Tucker County",
    "state": "WV",
    "city": "Parsons"
  },
  {
    "value": "26288",
    "label": "Webster Springs - WV 26288",
    "county": "Webster County",
    "state": "WV",
    "city": "Webster Springs"
  },
  {
    "value": "26289",
    "label": "Red Creek - WV 26289",
    "county": "Tucker County",
    "state": "WV",
    "city": "Red Creek"
  },
  {
    "value": "26291",
    "label": "Slatyfork - WV 26291",
    "county": "Pocahontas County",
    "state": "WV",
    "city": "Slatyfork"
  },
  {
    "value": "26292",
    "label": "Thomas - WV 26292",
    "county": "Tucker County",
    "state": "WV",
    "city": "Thomas"
  },
  {
    "value": "26293",
    "label": "Valley Bend - WV 26293",
    "county": "Randolph County",
    "state": "WV",
    "city": "Valley Bend"
  },
  {
    "value": "26294",
    "label": "Valley Head - WV 26294",
    "county": "Randolph County",
    "state": "WV",
    "city": "Valley Head"
  },
  {
    "value": "26298",
    "label": "Bergoo - WV 26298",
    "county": "Webster County",
    "state": "WV",
    "city": "Bergoo"
  },
  {
    "value": "26301",
    "label": "Clarksburg - WV 26301",
    "county": "Harrison County",
    "state": "WV",
    "city": "Clarksburg"
  },
  {
    "value": "26320",
    "label": "Alma - WV 26320",
    "county": "Tyler County",
    "state": "WV",
    "city": "Alma"
  },
  {
    "value": "26321",
    "label": "Alum Bridge - WV 26321",
    "county": "Lewis County",
    "state": "WV",
    "city": "Alum Bridge"
  },
  {
    "value": "26325",
    "label": "Auburn - WV 26325",
    "county": "Ritchie County",
    "state": "WV",
    "city": "Auburn"
  },
  {
    "value": "26327",
    "label": "Berea - WV 26327",
    "county": "Ritchie County",
    "state": "WV",
    "city": "Berea"
  },
  {
    "value": "26330",
    "label": "Bridgeport - WV 26330",
    "county": "Harrison County",
    "state": "WV",
    "city": "Bridgeport"
  },
  {
    "value": "26335",
    "label": "Burnsville - WV 26335",
    "county": "Braxton County",
    "state": "WV",
    "city": "Burnsville"
  },
  {
    "value": "26337",
    "label": "Cairo - WV 26337",
    "county": "Ritchie County",
    "state": "WV",
    "city": "Cairo"
  },
  {
    "value": "26338",
    "label": "Camden - WV 26338",
    "county": "Lewis County",
    "state": "WV",
    "city": "Camden"
  },
  {
    "value": "26339",
    "label": "Center Point - WV 26339",
    "county": "Doddridge County",
    "state": "WV",
    "city": "Center Point"
  },
  {
    "value": "26342",
    "label": "Coxs Mills - WV 26342",
    "county": "Gilmer County",
    "state": "WV",
    "city": "Coxs Mills"
  },
  {
    "value": "26343",
    "label": "Crawford - WV 26343",
    "county": "Lewis County",
    "state": "WV",
    "city": "Crawford"
  },
  {
    "value": "26346",
    "label": "Ellenboro - WV 26346",
    "county": "Ritchie County",
    "state": "WV",
    "city": "Ellenboro"
  },
  {
    "value": "26347",
    "label": "Flemington - WV 26347",
    "county": "Taylor County",
    "state": "WV",
    "city": "Flemington"
  },
  {
    "value": "26348",
    "label": "Folsom - WV 26348",
    "county": "Wetzel County",
    "state": "WV",
    "city": "Folsom"
  },
  {
    "value": "26351",
    "label": "Glenville - WV 26351",
    "county": "Gilmer County",
    "state": "WV",
    "city": "Glenville"
  },
  {
    "value": "26354",
    "label": "Grafton - WV 26354",
    "county": "Taylor County",
    "state": "WV",
    "city": "Grafton"
  },
  {
    "value": "26362",
    "label": "Harrisville - WV 26362",
    "county": "Ritchie County",
    "state": "WV",
    "city": "Harrisville"
  },
  {
    "value": "26372",
    "label": "Horner - WV 26372",
    "county": "Lewis County",
    "state": "WV",
    "city": "Horner"
  },
  {
    "value": "26374",
    "label": "Independence - WV 26374",
    "county": "Preston County",
    "state": "WV",
    "city": "Independence"
  },
  {
    "value": "26376",
    "label": "Ireland - WV 26376",
    "county": "Lewis County",
    "state": "WV",
    "city": "Ireland"
  },
  {
    "value": "26377",
    "label": "Jacksonburg - WV 26377",
    "county": "Wetzel County",
    "state": "WV",
    "city": "Jacksonburg"
  },
  {
    "value": "26378",
    "label": "Jane Lew - WV 26378",
    "county": "Lewis County",
    "state": "WV",
    "city": "Jane Lew"
  },
  {
    "value": "26384",
    "label": "Linn - WV 26384",
    "county": "Gilmer County",
    "state": "WV",
    "city": "Linn"
  },
  {
    "value": "26385",
    "label": "Lost Creek - WV 26385",
    "county": "Harrison County",
    "state": "WV",
    "city": "Lost Creek"
  },
  {
    "value": "26386",
    "label": "Lumberport - WV 26386",
    "county": "Harrison County",
    "state": "WV",
    "city": "Lumberport"
  },
  {
    "value": "26404",
    "label": "Meadowbrook - WV 26404",
    "county": "Harrison County",
    "state": "WV",
    "city": "Meadowbrook"
  },
  {
    "value": "26405",
    "label": "Moatsville - WV 26405",
    "county": "Barbour County",
    "state": "WV",
    "city": "Moatsville"
  },
  {
    "value": "26408",
    "label": "Mount Clare - WV 26408",
    "county": "Harrison County",
    "state": "WV",
    "city": "Mount Clare"
  },
  {
    "value": "26410",
    "label": "Newburg - WV 26410",
    "county": "Preston County",
    "state": "WV",
    "city": "Newburg"
  },
  {
    "value": "26411",
    "label": "New Milton - WV 26411",
    "county": "Doddridge County",
    "state": "WV",
    "city": "New Milton"
  },
  {
    "value": "26412",
    "label": "Orlando - WV 26412",
    "county": "Lewis County",
    "state": "WV",
    "city": "Orlando"
  },
  {
    "value": "26415",
    "label": "Pennsboro - WV 26415",
    "county": "Ritchie County",
    "state": "WV",
    "city": "Pennsboro"
  },
  {
    "value": "26416",
    "label": "Philippi - WV 26416",
    "county": "Barbour County",
    "state": "WV",
    "city": "Philippi"
  },
  {
    "value": "26419",
    "label": "Pine Grove - WV 26419",
    "county": "Wetzel County",
    "state": "WV",
    "city": "Pine Grove"
  },
  {
    "value": "26421",
    "label": "Pullman - WV 26421",
    "county": "Ritchie County",
    "state": "WV",
    "city": "Pullman"
  },
  {
    "value": "26425",
    "label": "Rowlesburg - WV 26425",
    "county": "Preston County",
    "state": "WV",
    "city": "Rowlesburg"
  },
  {
    "value": "26426",
    "label": "Salem - WV 26426",
    "county": "Harrison County",
    "state": "WV",
    "city": "Salem"
  },
  {
    "value": "26430",
    "label": "Sand Fork - WV 26430",
    "county": "Gilmer County",
    "state": "WV",
    "city": "Sand Fork"
  },
  {
    "value": "26431",
    "label": "Shinnston - WV 26431",
    "county": "Harrison County",
    "state": "WV",
    "city": "Shinnston"
  },
  {
    "value": "26437",
    "label": "Smithfield - WV 26437",
    "county": "Wetzel County",
    "state": "WV",
    "city": "Smithfield"
  },
  {
    "value": "26440",
    "label": "Thornton - WV 26440",
    "county": "Taylor County",
    "state": "WV",
    "city": "Thornton"
  },
  {
    "value": "26443",
    "label": "Troy - WV 26443",
    "county": "Gilmer County",
    "state": "WV",
    "city": "Troy"
  },
  {
    "value": "26444",
    "label": "Tunnelton - WV 26444",
    "county": "Preston County",
    "state": "WV",
    "city": "Tunnelton"
  },
  {
    "value": "26447",
    "label": "Walkersville - WV 26447",
    "county": "Lewis County",
    "state": "WV",
    "city": "Walkersville"
  },
  {
    "value": "26448",
    "label": "Wallace - WV 26448",
    "county": "Harrison County",
    "state": "WV",
    "city": "Wallace"
  },
  {
    "value": "26451",
    "label": "West Milford - WV 26451",
    "county": "Harrison County",
    "state": "WV",
    "city": "West Milford"
  },
  {
    "value": "26452",
    "label": "Weston - WV 26452",
    "county": "Lewis County",
    "state": "WV",
    "city": "Weston"
  },
  {
    "value": "26456",
    "label": "West Union - WV 26456",
    "county": "Doddridge County",
    "state": "WV",
    "city": "West Union"
  },
  {
    "value": "26461",
    "label": "Wilsonburg - WV 26461",
    "county": "Harrison County",
    "state": "WV",
    "city": "Wilsonburg"
  },
  {
    "value": "26501",
    "label": "Morgantown - WV 26501",
    "county": "Monongalia County",
    "state": "WV",
    "city": "Morgantown"
  },
  {
    "value": "26505",
    "label": "Morgantown - WV 26505",
    "county": "Monongalia County",
    "state": "WV",
    "city": "Morgantown"
  },
  {
    "value": "26506",
    "label": "Morgantown - WV 26506",
    "county": "Monongalia County",
    "state": "WV",
    "city": "Morgantown"
  },
  {
    "value": "26508",
    "label": "Morgantown - WV 26508",
    "county": "Monongalia County",
    "state": "WV",
    "city": "Morgantown"
  },
  {
    "value": "26519",
    "label": "Albright - WV 26519",
    "county": "Preston County",
    "state": "WV",
    "city": "Albright"
  },
  {
    "value": "26521",
    "label": "Blacksville - WV 26521",
    "county": "Monongalia County",
    "state": "WV",
    "city": "Blacksville"
  },
  {
    "value": "26524",
    "label": "Bretz - WV 26524",
    "county": "Preston County",
    "state": "WV",
    "city": "Bretz"
  },
  {
    "value": "26525",
    "label": "Bruceton Mills - WV 26525",
    "county": "Preston County",
    "state": "WV",
    "city": "Bruceton Mills"
  },
  {
    "value": "26537",
    "label": "Kingwood - WV 26537",
    "county": "Preston County",
    "state": "WV",
    "city": "Kingwood"
  },
  {
    "value": "26541",
    "label": "Maidsville - WV 26541",
    "county": "Monongalia County",
    "state": "WV",
    "city": "Maidsville"
  },
  {
    "value": "26542",
    "label": "Masontown - WV 26542",
    "county": "Preston County",
    "state": "WV",
    "city": "Masontown"
  },
  {
    "value": "26543",
    "label": "Osage - WV 26543",
    "county": "Monongalia County",
    "state": "WV",
    "city": "Osage"
  },
  {
    "value": "26546",
    "label": "Pursglove - WV 26546",
    "county": "Monongalia County",
    "state": "WV",
    "city": "Pursglove"
  },
  {
    "value": "26547",
    "label": "Reedsville - WV 26547",
    "county": "Preston County",
    "state": "WV",
    "city": "Reedsville"
  },
  {
    "value": "26554",
    "label": "Fairmont - WV 26554",
    "county": "Marion County",
    "state": "WV",
    "city": "Fairmont"
  },
  {
    "value": "26560",
    "label": "Baxter - WV 26560",
    "county": "Marion County",
    "state": "WV",
    "city": "Baxter"
  },
  {
    "value": "26561",
    "label": "Big Run - WV 26561",
    "county": "Wetzel County",
    "state": "WV",
    "city": "Big Run"
  },
  {
    "value": "26562",
    "label": "Burton - WV 26562",
    "county": "Wetzel County",
    "state": "WV",
    "city": "Burton"
  },
  {
    "value": "26568",
    "label": "Enterprise - WV 26568",
    "county": "Harrison County",
    "state": "WV",
    "city": "Enterprise"
  },
  {
    "value": "26570",
    "label": "Fairview - WV 26570",
    "county": "Monongalia County",
    "state": "WV",
    "city": "Fairview"
  },
  {
    "value": "26571",
    "label": "Farmington - WV 26571",
    "county": "Marion County",
    "state": "WV",
    "city": "Farmington"
  },
  {
    "value": "26575",
    "label": "Hundred - WV 26575",
    "county": "Wetzel County",
    "state": "WV",
    "city": "Hundred"
  },
  {
    "value": "26581",
    "label": "Littleton - WV 26581",
    "county": "Wetzel County",
    "state": "WV",
    "city": "Littleton"
  },
  {
    "value": "26582",
    "label": "Mannington - WV 26582",
    "county": "Marion County",
    "state": "WV",
    "city": "Mannington"
  },
  {
    "value": "26585",
    "label": "Metz - WV 26585",
    "county": "Marion County",
    "state": "WV",
    "city": "Metz"
  },
  {
    "value": "26586",
    "label": "Montana Mines - WV 26586",
    "county": "Marion County",
    "state": "WV",
    "city": "Montana Mines"
  },
  {
    "value": "26587",
    "label": "Rachel - WV 26587",
    "county": "Marion County",
    "state": "WV",
    "city": "Rachel"
  },
  {
    "value": "26588",
    "label": "Rivesville - WV 26588",
    "county": "Marion County",
    "state": "WV",
    "city": "Rivesville"
  },
  {
    "value": "26590",
    "label": "Wana - WV 26590",
    "county": "Monongalia County",
    "state": "WV",
    "city": "Wana"
  },
  {
    "value": "26591",
    "label": "Worthington - WV 26591",
    "county": "Marion County",
    "state": "WV",
    "city": "Worthington"
  },
  {
    "value": "26601",
    "label": "Sutton - WV 26601",
    "county": "Braxton County",
    "state": "WV",
    "city": "Sutton"
  },
  {
    "value": "26610",
    "label": "Birch River - WV 26610",
    "county": "Nicholas County",
    "state": "WV",
    "city": "Birch River"
  },
  {
    "value": "26611",
    "label": "Cedarville - WV 26611",
    "county": "Gilmer County",
    "state": "WV",
    "city": "Cedarville"
  },
  {
    "value": "26615",
    "label": "Copen - WV 26615",
    "county": "Braxton County",
    "state": "WV",
    "city": "Copen"
  },
  {
    "value": "26617",
    "label": "Dille - WV 26617",
    "county": "Clay County",
    "state": "WV",
    "city": "Dille"
  },
  {
    "value": "26619",
    "label": "Exchange - WV 26619",
    "county": "Braxton County",
    "state": "WV",
    "city": "Exchange"
  },
  {
    "value": "26621",
    "label": "Flatwoods - WV 26621",
    "county": "Braxton County",
    "state": "WV",
    "city": "Flatwoods"
  },
  {
    "value": "26623",
    "label": "Frametown - WV 26623",
    "county": "Braxton County",
    "state": "WV",
    "city": "Frametown"
  },
  {
    "value": "26624",
    "label": "Gassaway - WV 26624",
    "county": "Braxton County",
    "state": "WV",
    "city": "Gassaway"
  },
  {
    "value": "26627",
    "label": "Heaters - WV 26627",
    "county": "Braxton County",
    "state": "WV",
    "city": "Heaters"
  },
  {
    "value": "26629",
    "label": "Little Birch - WV 26629",
    "county": "Braxton County",
    "state": "WV",
    "city": "Little Birch"
  },
  {
    "value": "26631",
    "label": "Napier - WV 26631",
    "county": "Braxton County",
    "state": "WV",
    "city": "Napier"
  },
  {
    "value": "26636",
    "label": "Rosedale - WV 26636",
    "county": "Gilmer County",
    "state": "WV",
    "city": "Rosedale"
  },
  {
    "value": "26638",
    "label": "Shock - WV 26638",
    "county": "Gilmer County",
    "state": "WV",
    "city": "Shock"
  },
  {
    "value": "26651",
    "label": "Summersville - WV 26651",
    "county": "Nicholas County",
    "state": "WV",
    "city": "Summersville"
  },
  {
    "value": "26656",
    "label": "Belva - WV 26656",
    "county": "Nicholas County",
    "state": "WV",
    "city": "Belva"
  },
  {
    "value": "26660",
    "label": "Calvin - WV 26660",
    "county": "Nicholas County",
    "state": "WV",
    "city": "Calvin"
  },
  {
    "value": "26662",
    "label": "Canvas - WV 26662",
    "county": "Nicholas County",
    "state": "WV",
    "city": "Canvas"
  },
  {
    "value": "26676",
    "label": "Leivasy - WV 26676",
    "county": "Nicholas County",
    "state": "WV",
    "city": "Leivasy"
  },
  {
    "value": "26678",
    "label": "Mount Lookout - WV 26678",
    "county": "Nicholas County",
    "state": "WV",
    "city": "Mount Lookout"
  },
  {
    "value": "26679",
    "label": "Mount Nebo - WV 26679",
    "county": "Nicholas County",
    "state": "WV",
    "city": "Mount Nebo"
  },
  {
    "value": "26680",
    "label": "Nallen - WV 26680",
    "county": "Fayette County",
    "state": "WV",
    "city": "Nallen"
  },
  {
    "value": "26681",
    "label": "Nettie - WV 26681",
    "county": "Nicholas County",
    "state": "WV",
    "city": "Nettie"
  },
  {
    "value": "26684",
    "label": "Pool - WV 26684",
    "county": "Nicholas County",
    "state": "WV",
    "city": "Pool"
  },
  {
    "value": "26690",
    "label": "Swiss - WV 26690",
    "county": "Fayette County",
    "state": "WV",
    "city": "Swiss"
  },
  {
    "value": "26691",
    "label": "Tioga - WV 26691",
    "county": "Nicholas County",
    "state": "WV",
    "city": "Tioga"
  },
  {
    "value": "26704",
    "label": "Augusta - WV 26704",
    "county": "Hampshire County",
    "state": "WV",
    "city": "Augusta"
  },
  {
    "value": "26705",
    "label": "Aurora - WV 26705",
    "county": "Preston County",
    "state": "WV",
    "city": "Aurora"
  },
  {
    "value": "26710",
    "label": "Burlington - WV 26710",
    "county": "Mineral County",
    "state": "WV",
    "city": "Burlington"
  },
  {
    "value": "26711",
    "label": "Capon Bridge - WV 26711",
    "county": "Hampshire County",
    "state": "WV",
    "city": "Capon Bridge"
  },
  {
    "value": "26714",
    "label": "Delray - WV 26714",
    "county": "Hampshire County",
    "state": "WV",
    "city": "Delray"
  },
  {
    "value": "26716",
    "label": "Eglon - WV 26716",
    "county": "Preston County",
    "state": "WV",
    "city": "Eglon"
  },
  {
    "value": "26717",
    "label": "Elk Garden - WV 26717",
    "county": "Mineral County",
    "state": "WV",
    "city": "Elk Garden"
  },
  {
    "value": "26719",
    "label": "Fort Ashby - WV 26719",
    "county": "Mineral County",
    "state": "WV",
    "city": "Fort Ashby"
  },
  {
    "value": "26720",
    "label": "Gormania - WV 26720",
    "county": "Grant County",
    "state": "WV",
    "city": "Gormania"
  },
  {
    "value": "26722",
    "label": "Green Spring - WV 26722",
    "county": "Hampshire County",
    "state": "WV",
    "city": "Green Spring"
  },
  {
    "value": "26726",
    "label": "Keyser - WV 26726",
    "county": "Mineral County",
    "state": "WV",
    "city": "Keyser"
  },
  {
    "value": "26731",
    "label": "Lahmansville - WV 26731",
    "county": "Grant County",
    "state": "WV",
    "city": "Lahmansville"
  },
  {
    "value": "26739",
    "label": "Mount Storm - WV 26739",
    "county": "Grant County",
    "state": "WV",
    "city": "Mount Storm"
  },
  {
    "value": "26743",
    "label": "New Creek - WV 26743",
    "county": "Mineral County",
    "state": "WV",
    "city": "New Creek"
  },
  {
    "value": "26750",
    "label": "Piedmont - WV 26750",
    "county": "Mineral County",
    "state": "WV",
    "city": "Piedmont"
  },
  {
    "value": "26753",
    "label": "Ridgeley - WV 26753",
    "county": "Mineral County",
    "state": "WV",
    "city": "Ridgeley"
  },
  {
    "value": "26755",
    "label": "Rio - WV 26755",
    "county": "Hampshire County",
    "state": "WV",
    "city": "Rio"
  },
  {
    "value": "26757",
    "label": "Romney - WV 26757",
    "county": "Hampshire County",
    "state": "WV",
    "city": "Romney"
  },
  {
    "value": "26761",
    "label": "Shanks - WV 26761",
    "county": "Hampshire County",
    "state": "WV",
    "city": "Shanks"
  },
  {
    "value": "26763",
    "label": "Springfield - WV 26763",
    "county": "Hampshire County",
    "state": "WV",
    "city": "Springfield"
  },
  {
    "value": "26764",
    "label": "Terra Alta - WV 26764",
    "county": "Preston County",
    "state": "WV",
    "city": "Terra Alta"
  },
  {
    "value": "26767",
    "label": "Wiley Ford - WV 26767",
    "county": "Mineral County",
    "state": "WV",
    "city": "Wiley Ford"
  },
  {
    "value": "26801",
    "label": "Baker - WV 26801",
    "county": "Hardy County",
    "state": "WV",
    "city": "Baker"
  },
  {
    "value": "26802",
    "label": "Brandywine - WV 26802",
    "county": "Pendleton County",
    "state": "WV",
    "city": "Brandywine"
  },
  {
    "value": "26804",
    "label": "Circleville - WV 26804",
    "county": "Pendleton County",
    "state": "WV",
    "city": "Circleville"
  },
  {
    "value": "26807",
    "label": "Franklin - WV 26807",
    "county": "Pendleton County",
    "state": "WV",
    "city": "Franklin"
  },
  {
    "value": "26808",
    "label": "High View - WV 26808",
    "county": "Hampshire County",
    "state": "WV",
    "city": "High View"
  },
  {
    "value": "26810",
    "label": "Lost City - WV 26810",
    "county": "Hardy County",
    "state": "WV",
    "city": "Lost City"
  },
  {
    "value": "26812",
    "label": "Mathias - WV 26812",
    "county": "Hardy County",
    "state": "WV",
    "city": "Mathias"
  },
  {
    "value": "26814",
    "label": "Riverton - WV 26814",
    "county": "Pendleton County",
    "state": "WV",
    "city": "Riverton"
  },
  {
    "value": "26815",
    "label": "Sugar Grove - WV 26815",
    "county": "Pendleton County",
    "state": "WV",
    "city": "Sugar Grove"
  },
  {
    "value": "26817",
    "label": "Bloomery - WV 26817",
    "county": "Hampshire County",
    "state": "WV",
    "city": "Bloomery"
  },
  {
    "value": "26818",
    "label": "Fisher - WV 26818",
    "county": "Hardy County",
    "state": "WV",
    "city": "Fisher"
  },
  {
    "value": "26833",
    "label": "Maysville - WV 26833",
    "county": "Grant County",
    "state": "WV",
    "city": "Maysville"
  },
  {
    "value": "26836",
    "label": "Moorefield - WV 26836",
    "county": "Hardy County",
    "state": "WV",
    "city": "Moorefield"
  },
  {
    "value": "26838",
    "label": "Milam - WV 26838",
    "county": "Hardy County",
    "state": "WV",
    "city": "Milam"
  },
  {
    "value": "26845",
    "label": "Old Fields - WV 26845",
    "county": "Hardy County",
    "state": "WV",
    "city": "Old Fields"
  },
  {
    "value": "26847",
    "label": "Petersburg - WV 26847",
    "county": "Grant County",
    "state": "WV",
    "city": "Petersburg"
  },
  {
    "value": "26851",
    "label": "Wardensville - WV 26851",
    "county": "Hardy County",
    "state": "WV",
    "city": "Wardensville"
  },
  {
    "value": "26852",
    "label": "Purgitsville - WV 26852",
    "county": "Hampshire County",
    "state": "WV",
    "city": "Purgitsville"
  },
  {
    "value": "26855",
    "label": "Cabins - WV 26855",
    "county": "Grant County",
    "state": "WV",
    "city": "Cabins"
  },
  {
    "value": "26865",
    "label": "Yellow Spring - WV 26865",
    "county": "Hampshire County",
    "state": "WV",
    "city": "Yellow Spring"
  },
  {
    "value": "26866",
    "label": "Upper Tract - WV 26866",
    "county": "Pendleton County",
    "state": "WV",
    "city": "Upper Tract"
  },
  {
    "value": "26884",
    "label": "Seneca Rocks - WV 26884",
    "county": "Pendleton County",
    "state": "WV",
    "city": "Seneca Rocks"
  },
  {
    "value": "53001",
    "label": "Adell - WI 53001",
    "county": "Sheboygan County",
    "state": "WI",
    "city": "Adell"
  },
  {
    "value": "53002",
    "label": "Allenton - WI 53002",
    "county": "Washington County",
    "state": "WI",
    "city": "Allenton"
  },
  {
    "value": "53004",
    "label": "Belgium - WI 53004",
    "county": "Ozaukee County",
    "state": "WI",
    "city": "Belgium"
  },
  {
    "value": "53005",
    "label": "Brookfield - WI 53005",
    "county": "Waukesha County",
    "state": "WI",
    "city": "Brookfield"
  },
  {
    "value": "53006",
    "label": "Brownsville - WI 53006",
    "county": "Dodge County",
    "state": "WI",
    "city": "Brownsville"
  },
  {
    "value": "53007",
    "label": "Butler - WI 53007",
    "county": "Waukesha County",
    "state": "WI",
    "city": "Butler"
  },
  {
    "value": "53010",
    "label": "Campbellsport - WI 53010",
    "county": "Fond du Lac County",
    "state": "WI",
    "city": "Campbellsport"
  },
  {
    "value": "53011",
    "label": "Cascade - WI 53011",
    "county": "Sheboygan County",
    "state": "WI",
    "city": "Cascade"
  },
  {
    "value": "53012",
    "label": "Cedarburg - WI 53012",
    "county": "Ozaukee County",
    "state": "WI",
    "city": "Cedarburg"
  },
  {
    "value": "53013",
    "label": "Cedar Grove - WI 53013",
    "county": "Sheboygan County",
    "state": "WI",
    "city": "Cedar Grove"
  },
  {
    "value": "53014",
    "label": "Chilton - WI 53014",
    "county": "Calumet County",
    "state": "WI",
    "city": "Chilton"
  },
  {
    "value": "53015",
    "label": "Cleveland - WI 53015",
    "county": "Manitowoc County",
    "state": "WI",
    "city": "Cleveland"
  },
  {
    "value": "53017",
    "label": "Colgate - WI 53017",
    "county": "Washington County",
    "state": "WI",
    "city": "Colgate"
  },
  {
    "value": "53018",
    "label": "Delafield - WI 53018",
    "county": "Waukesha County",
    "state": "WI",
    "city": "Delafield"
  },
  {
    "value": "53019",
    "label": "Eden - WI 53019",
    "county": "Fond du Lac County",
    "state": "WI",
    "city": "Eden"
  },
  {
    "value": "53020",
    "label": "Elkhart Lake - WI 53020",
    "county": "Sheboygan County",
    "state": "WI",
    "city": "Elkhart Lake"
  },
  {
    "value": "53021",
    "label": "Fredonia - WI 53021",
    "county": "Ozaukee County",
    "state": "WI",
    "city": "Fredonia"
  },
  {
    "value": "53022",
    "label": "Germantown - WI 53022",
    "county": "Washington County",
    "state": "WI",
    "city": "Germantown"
  },
  {
    "value": "53023",
    "label": "Glenbeulah - WI 53023",
    "county": "Sheboygan County",
    "state": "WI",
    "city": "Glenbeulah"
  },
  {
    "value": "53024",
    "label": "Grafton - WI 53024",
    "county": "Ozaukee County",
    "state": "WI",
    "city": "Grafton"
  },
  {
    "value": "53027",
    "label": "Hartford - WI 53027",
    "county": "Washington County",
    "state": "WI",
    "city": "Hartford"
  },
  {
    "value": "53029",
    "label": "Hartland - WI 53029",
    "county": "Waukesha County",
    "state": "WI",
    "city": "Hartland"
  },
  {
    "value": "53032",
    "label": "Horicon - WI 53032",
    "county": "Dodge County",
    "state": "WI",
    "city": "Horicon"
  },
  {
    "value": "53033",
    "label": "Hubertus - WI 53033",
    "county": "Washington County",
    "state": "WI",
    "city": "Hubertus"
  },
  {
    "value": "53034",
    "label": "Hustisford - WI 53034",
    "county": "Dodge County",
    "state": "WI",
    "city": "Hustisford"
  },
  {
    "value": "53035",
    "label": "Iron Ridge - WI 53035",
    "county": "Dodge County",
    "state": "WI",
    "city": "Iron Ridge"
  },
  {
    "value": "53036",
    "label": "Ixonia - WI 53036",
    "county": "Jefferson County",
    "state": "WI",
    "city": "Ixonia"
  },
  {
    "value": "53037",
    "label": "Jackson - WI 53037",
    "county": "Washington County",
    "state": "WI",
    "city": "Jackson"
  },
  {
    "value": "53038",
    "label": "Johnson Creek - WI 53038",
    "county": "Jefferson County",
    "state": "WI",
    "city": "Johnson Creek"
  },
  {
    "value": "53039",
    "label": "Juneau - WI 53039",
    "county": "Dodge County",
    "state": "WI",
    "city": "Juneau"
  },
  {
    "value": "53040",
    "label": "Kewaskum - WI 53040",
    "county": "Washington County",
    "state": "WI",
    "city": "Kewaskum"
  },
  {
    "value": "53042",
    "label": "Kiel - WI 53042",
    "county": "Manitowoc County",
    "state": "WI",
    "city": "Kiel"
  },
  {
    "value": "53044",
    "label": "Kohler - WI 53044",
    "county": "Sheboygan County",
    "state": "WI",
    "city": "Kohler"
  },
  {
    "value": "53045",
    "label": "Brookfield - WI 53045",
    "county": "Waukesha County",
    "state": "WI",
    "city": "Brookfield"
  },
  {
    "value": "53046",
    "label": "Lannon - WI 53046",
    "county": "Waukesha County",
    "state": "WI",
    "city": "Lannon"
  },
  {
    "value": "53048",
    "label": "Lomira - WI 53048",
    "county": "Dodge County",
    "state": "WI",
    "city": "Lomira"
  },
  {
    "value": "53049",
    "label": "Malone - WI 53049",
    "county": "Fond du Lac County",
    "state": "WI",
    "city": "Malone"
  },
  {
    "value": "53050",
    "label": "Mayville - WI 53050",
    "county": "Dodge County",
    "state": "WI",
    "city": "Mayville"
  },
  {
    "value": "53051",
    "label": "Menomonee Falls - WI 53051",
    "county": "Waukesha County",
    "state": "WI",
    "city": "Menomonee Falls"
  },
  {
    "value": "53057",
    "label": "Mount Calvary - WI 53057",
    "county": "Fond du Lac County",
    "state": "WI",
    "city": "Mount Calvary"
  },
  {
    "value": "53058",
    "label": "Nashotah - WI 53058",
    "county": "Waukesha County",
    "state": "WI",
    "city": "Nashotah"
  },
  {
    "value": "53059",
    "label": "Neosho - WI 53059",
    "county": "Dodge County",
    "state": "WI",
    "city": "Neosho"
  },
  {
    "value": "53061",
    "label": "New Holstein - WI 53061",
    "county": "Calumet County",
    "state": "WI",
    "city": "New Holstein"
  },
  {
    "value": "53063",
    "label": "Newton - WI 53063",
    "county": "Manitowoc County",
    "state": "WI",
    "city": "Newton"
  },
  {
    "value": "53065",
    "label": "Oakfield - WI 53065",
    "county": "Fond du Lac County",
    "state": "WI",
    "city": "Oakfield"
  },
  {
    "value": "53066",
    "label": "Oconomowoc - WI 53066",
    "county": "Waukesha County",
    "state": "WI",
    "city": "Oconomowoc"
  },
  {
    "value": "53069",
    "label": "Okauchee - WI 53069",
    "county": "Waukesha County",
    "state": "WI",
    "city": "Okauchee"
  },
  {
    "value": "53070",
    "label": "Oostburg - WI 53070",
    "county": "Sheboygan County",
    "state": "WI",
    "city": "Oostburg"
  },
  {
    "value": "53072",
    "label": "Pewaukee - WI 53072",
    "county": "Waukesha County",
    "state": "WI",
    "city": "Pewaukee"
  },
  {
    "value": "53073",
    "label": "Plymouth - WI 53073",
    "county": "Sheboygan County",
    "state": "WI",
    "city": "Plymouth"
  },
  {
    "value": "53074",
    "label": "Port Washington - WI 53074",
    "county": "Ozaukee County",
    "state": "WI",
    "city": "Port Washington"
  },
  {
    "value": "53075",
    "label": "Random Lake - WI 53075",
    "county": "Sheboygan County",
    "state": "WI",
    "city": "Random Lake"
  },
  {
    "value": "53076",
    "label": "Richfield - WI 53076",
    "county": "Washington County",
    "state": "WI",
    "city": "Richfield"
  },
  {
    "value": "53078",
    "label": "Rubicon - WI 53078",
    "county": "Dodge County",
    "state": "WI",
    "city": "Rubicon"
  },
  {
    "value": "53079",
    "label": "Saint Cloud - WI 53079",
    "county": "Fond du Lac County",
    "state": "WI",
    "city": "Saint Cloud"
  },
  {
    "value": "53080",
    "label": "Saukville - WI 53080",
    "county": "Ozaukee County",
    "state": "WI",
    "city": "Saukville"
  },
  {
    "value": "53081",
    "label": "Sheboygan - WI 53081",
    "county": "Sheboygan County",
    "state": "WI",
    "city": "Sheboygan"
  },
  {
    "value": "53083",
    "label": "Sheboygan - WI 53083",
    "county": "Sheboygan County",
    "state": "WI",
    "city": "Sheboygan"
  },
  {
    "value": "53085",
    "label": "Sheboygan Falls - WI 53085",
    "county": "Sheboygan County",
    "state": "WI",
    "city": "Sheboygan Falls"
  },
  {
    "value": "53086",
    "label": "Slinger - WI 53086",
    "county": "Washington County",
    "state": "WI",
    "city": "Slinger"
  },
  {
    "value": "53089",
    "label": "Sussex - WI 53089",
    "county": "Waukesha County",
    "state": "WI",
    "city": "Sussex"
  },
  {
    "value": "53090",
    "label": "West Bend - WI 53090",
    "county": "Washington County",
    "state": "WI",
    "city": "West Bend"
  },
  {
    "value": "53091",
    "label": "Theresa - WI 53091",
    "county": "Dodge County",
    "state": "WI",
    "city": "Theresa"
  },
  {
    "value": "53092",
    "label": "Mequon - WI 53092",
    "county": "Ozaukee County",
    "state": "WI",
    "city": "Mequon"
  },
  {
    "value": "53093",
    "label": "Waldo - WI 53093",
    "county": "Sheboygan County",
    "state": "WI",
    "city": "Waldo"
  },
  {
    "value": "53094",
    "label": "Watertown - WI 53094",
    "county": "Jefferson County",
    "state": "WI",
    "city": "Watertown"
  },
  {
    "value": "53095",
    "label": "West Bend - WI 53095",
    "county": "Washington County",
    "state": "WI",
    "city": "West Bend"
  },
  {
    "value": "53097",
    "label": "Mequon - WI 53097",
    "county": "Ozaukee County",
    "state": "WI",
    "city": "Mequon"
  },
  {
    "value": "53098",
    "label": "Watertown - WI 53098",
    "county": "Dodge County",
    "state": "WI",
    "city": "Watertown"
  },
  {
    "value": "53099",
    "label": "Woodland - WI 53099",
    "county": "Dodge County",
    "state": "WI",
    "city": "Woodland"
  },
  {
    "value": "53103",
    "label": "Big Bend - WI 53103",
    "county": "Waukesha County",
    "state": "WI",
    "city": "Big Bend"
  },
  {
    "value": "53104",
    "label": "Bristol - WI 53104",
    "county": "Kenosha County",
    "state": "WI",
    "city": "Bristol"
  },
  {
    "value": "53105",
    "label": "Burlington - WI 53105",
    "county": "Racine County",
    "state": "WI",
    "city": "Burlington"
  },
  {
    "value": "53108",
    "label": "Caledonia - WI 53108",
    "county": "Racine County",
    "state": "WI",
    "city": "Caledonia"
  },
  {
    "value": "53110",
    "label": "Cudahy - WI 53110",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Cudahy"
  },
  {
    "value": "53114",
    "label": "Darien - WI 53114",
    "county": "Walworth County",
    "state": "WI",
    "city": "Darien"
  },
  {
    "value": "53115",
    "label": "Delavan - WI 53115",
    "county": "Walworth County",
    "state": "WI",
    "city": "Delavan"
  },
  {
    "value": "53118",
    "label": "Dousman - WI 53118",
    "county": "Waukesha County",
    "state": "WI",
    "city": "Dousman"
  },
  {
    "value": "53119",
    "label": "Eagle - WI 53119",
    "county": "Waukesha County",
    "state": "WI",
    "city": "Eagle"
  },
  {
    "value": "53120",
    "label": "East Troy - WI 53120",
    "county": "Walworth County",
    "state": "WI",
    "city": "East Troy"
  },
  {
    "value": "53121",
    "label": "Elkhorn - WI 53121",
    "county": "Walworth County",
    "state": "WI",
    "city": "Elkhorn"
  },
  {
    "value": "53122",
    "label": "Elm Grove - WI 53122",
    "county": "Waukesha County",
    "state": "WI",
    "city": "Elm Grove"
  },
  {
    "value": "53125",
    "label": "Fontana - WI 53125",
    "county": "Walworth County",
    "state": "WI",
    "city": "Fontana"
  },
  {
    "value": "53126",
    "label": "Franksville - WI 53126",
    "county": "Racine County",
    "state": "WI",
    "city": "Franksville"
  },
  {
    "value": "53128",
    "label": "Genoa City - WI 53128",
    "county": "Walworth County",
    "state": "WI",
    "city": "Genoa City"
  },
  {
    "value": "53129",
    "label": "Greendale - WI 53129",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Greendale"
  },
  {
    "value": "53130",
    "label": "Hales Corners - WI 53130",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Hales Corners"
  },
  {
    "value": "53132",
    "label": "Franklin - WI 53132",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Franklin"
  },
  {
    "value": "53137",
    "label": "Helenville - WI 53137",
    "county": "Jefferson County",
    "state": "WI",
    "city": "Helenville"
  },
  {
    "value": "53139",
    "label": "Kansasville - WI 53139",
    "county": "Racine County",
    "state": "WI",
    "city": "Kansasville"
  },
  {
    "value": "53140",
    "label": "Kenosha - WI 53140",
    "county": "Kenosha County",
    "state": "WI",
    "city": "Kenosha"
  },
  {
    "value": "53142",
    "label": "Kenosha - WI 53142",
    "county": "Kenosha County",
    "state": "WI",
    "city": "Kenosha"
  },
  {
    "value": "53143",
    "label": "Kenosha - WI 53143",
    "county": "Kenosha County",
    "state": "WI",
    "city": "Kenosha"
  },
  {
    "value": "53144",
    "label": "Kenosha - WI 53144",
    "county": "Kenosha County",
    "state": "WI",
    "city": "Kenosha"
  },
  {
    "value": "53146",
    "label": "New Berlin - WI 53146",
    "county": "Waukesha County",
    "state": "WI",
    "city": "New Berlin"
  },
  {
    "value": "53147",
    "label": "Lake Geneva - WI 53147",
    "county": "Walworth County",
    "state": "WI",
    "city": "Lake Geneva"
  },
  {
    "value": "53149",
    "label": "Mukwonago - WI 53149",
    "county": "Waukesha County",
    "state": "WI",
    "city": "Mukwonago"
  },
  {
    "value": "53150",
    "label": "Muskego - WI 53150",
    "county": "Waukesha County",
    "state": "WI",
    "city": "Muskego"
  },
  {
    "value": "53151",
    "label": "New Berlin - WI 53151",
    "county": "Waukesha County",
    "state": "WI",
    "city": "New Berlin"
  },
  {
    "value": "53153",
    "label": "North Prairie - WI 53153",
    "county": "Waukesha County",
    "state": "WI",
    "city": "North Prairie"
  },
  {
    "value": "53154",
    "label": "Oak Creek - WI 53154",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Oak Creek"
  },
  {
    "value": "53156",
    "label": "Palmyra - WI 53156",
    "county": "Jefferson County",
    "state": "WI",
    "city": "Palmyra"
  },
  {
    "value": "53158",
    "label": "Pleasant Prairie - WI 53158",
    "county": "Kenosha County",
    "state": "WI",
    "city": "Pleasant Prairie"
  },
  {
    "value": "53168",
    "label": "Salem - WI 53168",
    "county": "Kenosha County",
    "state": "WI",
    "city": "Salem"
  },
  {
    "value": "53170",
    "label": "Silver Lake - WI 53170",
    "county": "Kenosha County",
    "state": "WI",
    "city": "Silver Lake"
  },
  {
    "value": "53172",
    "label": "South Milwaukee - WI 53172",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "South Milwaukee"
  },
  {
    "value": "53177",
    "label": "Sturtevant - WI 53177",
    "county": "Racine County",
    "state": "WI",
    "city": "Sturtevant"
  },
  {
    "value": "53178",
    "label": "Sullivan - WI 53178",
    "county": "Jefferson County",
    "state": "WI",
    "city": "Sullivan"
  },
  {
    "value": "53179",
    "label": "Trevor - WI 53179",
    "county": "Kenosha County",
    "state": "WI",
    "city": "Trevor"
  },
  {
    "value": "53181",
    "label": "Twin Lakes - WI 53181",
    "county": "Kenosha County",
    "state": "WI",
    "city": "Twin Lakes"
  },
  {
    "value": "53182",
    "label": "Union Grove - WI 53182",
    "county": "Racine County",
    "state": "WI",
    "city": "Union Grove"
  },
  {
    "value": "53183",
    "label": "Wales - WI 53183",
    "county": "Waukesha County",
    "state": "WI",
    "city": "Wales"
  },
  {
    "value": "53184",
    "label": "Walworth - WI 53184",
    "county": "Walworth County",
    "state": "WI",
    "city": "Walworth"
  },
  {
    "value": "53185",
    "label": "Waterford - WI 53185",
    "county": "Racine County",
    "state": "WI",
    "city": "Waterford"
  },
  {
    "value": "53186",
    "label": "Waukesha - WI 53186",
    "county": "Waukesha County",
    "state": "WI",
    "city": "Waukesha"
  },
  {
    "value": "53188",
    "label": "Waukesha - WI 53188",
    "county": "Waukesha County",
    "state": "WI",
    "city": "Waukesha"
  },
  {
    "value": "53189",
    "label": "Waukesha - WI 53189",
    "county": "Waukesha County",
    "state": "WI",
    "city": "Waukesha"
  },
  {
    "value": "53190",
    "label": "Whitewater - WI 53190",
    "county": "Walworth County",
    "state": "WI",
    "city": "Whitewater"
  },
  {
    "value": "53191",
    "label": "Williams Bay - WI 53191",
    "county": "Walworth County",
    "state": "WI",
    "city": "Williams Bay"
  },
  {
    "value": "53202",
    "label": "Milwaukee - WI 53202",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53203",
    "label": "Milwaukee - WI 53203",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53204",
    "label": "Milwaukee - WI 53204",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53205",
    "label": "Milwaukee - WI 53205",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53206",
    "label": "Milwaukee - WI 53206",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53207",
    "label": "Milwaukee - WI 53207",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53208",
    "label": "Milwaukee - WI 53208",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53209",
    "label": "Milwaukee - WI 53209",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53210",
    "label": "Milwaukee - WI 53210",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53211",
    "label": "Milwaukee - WI 53211",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53212",
    "label": "Milwaukee - WI 53212",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53213",
    "label": "Milwaukee - WI 53213",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53214",
    "label": "Milwaukee - WI 53214",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53215",
    "label": "Milwaukee - WI 53215",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53216",
    "label": "Milwaukee - WI 53216",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53217",
    "label": "Milwaukee - WI 53217",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53218",
    "label": "Milwaukee - WI 53218",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53219",
    "label": "Milwaukee - WI 53219",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53220",
    "label": "Milwaukee - WI 53220",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53221",
    "label": "Milwaukee - WI 53221",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53222",
    "label": "Milwaukee - WI 53222",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53223",
    "label": "Milwaukee - WI 53223",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53224",
    "label": "Milwaukee - WI 53224",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53225",
    "label": "Milwaukee - WI 53225",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53226",
    "label": "Milwaukee - WI 53226",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53227",
    "label": "Milwaukee - WI 53227",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53228",
    "label": "Milwaukee - WI 53228",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53233",
    "label": "Milwaukee - WI 53233",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Milwaukee"
  },
  {
    "value": "53235",
    "label": "Saint Francis - WI 53235",
    "county": "Milwaukee County",
    "state": "WI",
    "city": "Saint Francis"
  },
  {
    "value": "53402",
    "label": "Racine - WI 53402",
    "county": "Racine County",
    "state": "WI",
    "city": "Racine"
  },
  {
    "value": "53403",
    "label": "Racine - WI 53403",
    "county": "Racine County",
    "state": "WI",
    "city": "Racine"
  },
  {
    "value": "53404",
    "label": "Racine - WI 53404",
    "county": "Racine County",
    "state": "WI",
    "city": "Racine"
  },
  {
    "value": "53405",
    "label": "Racine - WI 53405",
    "county": "Racine County",
    "state": "WI",
    "city": "Racine"
  },
  {
    "value": "53406",
    "label": "Racine - WI 53406",
    "county": "Racine County",
    "state": "WI",
    "city": "Racine"
  },
  {
    "value": "53502",
    "label": "Albany - WI 53502",
    "county": "Green County",
    "state": "WI",
    "city": "Albany"
  },
  {
    "value": "53503",
    "label": "Arena - WI 53503",
    "county": "Iowa County",
    "state": "WI",
    "city": "Arena"
  },
  {
    "value": "53504",
    "label": "Argyle - WI 53504",
    "county": "Lafayette County",
    "state": "WI",
    "city": "Argyle"
  },
  {
    "value": "53505",
    "label": "Avalon - WI 53505",
    "county": "Rock County",
    "state": "WI",
    "city": "Avalon"
  },
  {
    "value": "53506",
    "label": "Avoca - WI 53506",
    "county": "Iowa County",
    "state": "WI",
    "city": "Avoca"
  },
  {
    "value": "53507",
    "label": "Barneveld - WI 53507",
    "county": "Iowa County",
    "state": "WI",
    "city": "Barneveld"
  },
  {
    "value": "53508",
    "label": "Belleville - WI 53508",
    "county": "Dane County",
    "state": "WI",
    "city": "Belleville"
  },
  {
    "value": "53510",
    "label": "Belmont - WI 53510",
    "county": "Lafayette County",
    "state": "WI",
    "city": "Belmont"
  },
  {
    "value": "53511",
    "label": "Beloit - WI 53511",
    "county": "Rock County",
    "state": "WI",
    "city": "Beloit"
  },
  {
    "value": "53515",
    "label": "Black Earth - WI 53515",
    "county": "Dane County",
    "state": "WI",
    "city": "Black Earth"
  },
  {
    "value": "53516",
    "label": "Blanchardville - WI 53516",
    "county": "Lafayette County",
    "state": "WI",
    "city": "Blanchardville"
  },
  {
    "value": "53517",
    "label": "Blue Mounds - WI 53517",
    "county": "Dane County",
    "state": "WI",
    "city": "Blue Mounds"
  },
  {
    "value": "53518",
    "label": "Blue River - WI 53518",
    "county": "Richland County",
    "state": "WI",
    "city": "Blue River"
  },
  {
    "value": "53520",
    "label": "Brodhead - WI 53520",
    "county": "Green County",
    "state": "WI",
    "city": "Brodhead"
  },
  {
    "value": "53521",
    "label": "Brooklyn - WI 53521",
    "county": "Dane County",
    "state": "WI",
    "city": "Brooklyn"
  },
  {
    "value": "53522",
    "label": "Browntown - WI 53522",
    "county": "Green County",
    "state": "WI",
    "city": "Browntown"
  },
  {
    "value": "53523",
    "label": "Cambridge - WI 53523",
    "county": "Dane County",
    "state": "WI",
    "city": "Cambridge"
  },
  {
    "value": "53525",
    "label": "Clinton - WI 53525",
    "county": "Rock County",
    "state": "WI",
    "city": "Clinton"
  },
  {
    "value": "53526",
    "label": "Cobb - WI 53526",
    "county": "Iowa County",
    "state": "WI",
    "city": "Cobb"
  },
  {
    "value": "53527",
    "label": "Cottage Grove - WI 53527",
    "county": "Dane County",
    "state": "WI",
    "city": "Cottage Grove"
  },
  {
    "value": "53528",
    "label": "Cross Plains - WI 53528",
    "county": "Dane County",
    "state": "WI",
    "city": "Cross Plains"
  },
  {
    "value": "53529",
    "label": "Dane - WI 53529",
    "county": "Dane County",
    "state": "WI",
    "city": "Dane"
  },
  {
    "value": "53530",
    "label": "Darlington - WI 53530",
    "county": "Lafayette County",
    "state": "WI",
    "city": "Darlington"
  },
  {
    "value": "53531",
    "label": "Deerfield - WI 53531",
    "county": "Dane County",
    "state": "WI",
    "city": "Deerfield"
  },
  {
    "value": "53532",
    "label": "Deforest - WI 53532",
    "county": "Dane County",
    "state": "WI",
    "city": "Deforest"
  },
  {
    "value": "53533",
    "label": "Dodgeville - WI 53533",
    "county": "Iowa County",
    "state": "WI",
    "city": "Dodgeville"
  },
  {
    "value": "53534",
    "label": "Edgerton - WI 53534",
    "county": "Rock County",
    "state": "WI",
    "city": "Edgerton"
  },
  {
    "value": "53536",
    "label": "Evansville - WI 53536",
    "county": "Rock County",
    "state": "WI",
    "city": "Evansville"
  },
  {
    "value": "53538",
    "label": "Fort Atkinson - WI 53538",
    "county": "Jefferson County",
    "state": "WI",
    "city": "Fort Atkinson"
  },
  {
    "value": "53541",
    "label": "Gratiot - WI 53541",
    "county": "Lafayette County",
    "state": "WI",
    "city": "Gratiot"
  },
  {
    "value": "53543",
    "label": "Highland - WI 53543",
    "county": "Iowa County",
    "state": "WI",
    "city": "Highland"
  },
  {
    "value": "53544",
    "label": "Hollandale - WI 53544",
    "county": "Iowa County",
    "state": "WI",
    "city": "Hollandale"
  },
  {
    "value": "53545",
    "label": "Janesville - WI 53545",
    "county": "Rock County",
    "state": "WI",
    "city": "Janesville"
  },
  {
    "value": "53546",
    "label": "Janesville - WI 53546",
    "county": "Rock County",
    "state": "WI",
    "city": "Janesville"
  },
  {
    "value": "53548",
    "label": "Janesville - WI 53548",
    "county": "Rock County",
    "state": "WI",
    "city": "Janesville"
  },
  {
    "value": "53549",
    "label": "Jefferson - WI 53549",
    "county": "Jefferson County",
    "state": "WI",
    "city": "Jefferson"
  },
  {
    "value": "53550",
    "label": "Juda - WI 53550",
    "county": "Green County",
    "state": "WI",
    "city": "Juda"
  },
  {
    "value": "53551",
    "label": "Lake Mills - WI 53551",
    "county": "Jefferson County",
    "state": "WI",
    "city": "Lake Mills"
  },
  {
    "value": "53553",
    "label": "Linden - WI 53553",
    "county": "Iowa County",
    "state": "WI",
    "city": "Linden"
  },
  {
    "value": "53554",
    "label": "Livingston - WI 53554",
    "county": "Grant County",
    "state": "WI",
    "city": "Livingston"
  },
  {
    "value": "53555",
    "label": "Lodi - WI 53555",
    "county": "Columbia County",
    "state": "WI",
    "city": "Lodi"
  },
  {
    "value": "53556",
    "label": "Lone Rock - WI 53556",
    "county": "Richland County",
    "state": "WI",
    "city": "Lone Rock"
  },
  {
    "value": "53557",
    "label": "Lowell - WI 53557",
    "county": "Dodge County",
    "state": "WI",
    "city": "Lowell"
  },
  {
    "value": "53558",
    "label": "Mcfarland - WI 53558",
    "county": "Dane County",
    "state": "WI",
    "city": "Mcfarland"
  },
  {
    "value": "53559",
    "label": "Marshall - WI 53559",
    "county": "Dane County",
    "state": "WI",
    "city": "Marshall"
  },
  {
    "value": "53560",
    "label": "Mazomanie - WI 53560",
    "county": "Dane County",
    "state": "WI",
    "city": "Mazomanie"
  },
  {
    "value": "53561",
    "label": "Merrimac - WI 53561",
    "county": "Sauk County",
    "state": "WI",
    "city": "Merrimac"
  },
  {
    "value": "53562",
    "label": "Middleton - WI 53562",
    "county": "Dane County",
    "state": "WI",
    "city": "Middleton"
  },
  {
    "value": "53563",
    "label": "Milton - WI 53563",
    "county": "Rock County",
    "state": "WI",
    "city": "Milton"
  },
  {
    "value": "53565",
    "label": "Mineral Point - WI 53565",
    "county": "Iowa County",
    "state": "WI",
    "city": "Mineral Point"
  },
  {
    "value": "53566",
    "label": "Monroe - WI 53566",
    "county": "Green County",
    "state": "WI",
    "city": "Monroe"
  },
  {
    "value": "53569",
    "label": "Montfort - WI 53569",
    "county": "Grant County",
    "state": "WI",
    "city": "Montfort"
  },
  {
    "value": "53570",
    "label": "Monticello - WI 53570",
    "county": "Green County",
    "state": "WI",
    "city": "Monticello"
  },
  {
    "value": "53572",
    "label": "Mount Horeb - WI 53572",
    "county": "Dane County",
    "state": "WI",
    "city": "Mount Horeb"
  },
  {
    "value": "53573",
    "label": "Muscoda - WI 53573",
    "county": "Grant County",
    "state": "WI",
    "city": "Muscoda"
  },
  {
    "value": "53574",
    "label": "New Glarus - WI 53574",
    "county": "Green County",
    "state": "WI",
    "city": "New Glarus"
  },
  {
    "value": "53575",
    "label": "Oregon - WI 53575",
    "county": "Dane County",
    "state": "WI",
    "city": "Oregon"
  },
  {
    "value": "53576",
    "label": "Orfordville - WI 53576",
    "county": "Rock County",
    "state": "WI",
    "city": "Orfordville"
  },
  {
    "value": "53577",
    "label": "Plain - WI 53577",
    "county": "Sauk County",
    "state": "WI",
    "city": "Plain"
  },
  {
    "value": "53578",
    "label": "Prairie Du Sac - WI 53578",
    "county": "Sauk County",
    "state": "WI",
    "city": "Prairie Du Sac"
  },
  {
    "value": "53579",
    "label": "Reeseville - WI 53579",
    "county": "Dodge County",
    "state": "WI",
    "city": "Reeseville"
  },
  {
    "value": "53580",
    "label": "Rewey - WI 53580",
    "county": "Iowa County",
    "state": "WI",
    "city": "Rewey"
  },
  {
    "value": "53581",
    "label": "Richland Center - WI 53581",
    "county": "Richland County",
    "state": "WI",
    "city": "Richland Center"
  },
  {
    "value": "53582",
    "label": "Ridgeway - WI 53582",
    "county": "Iowa County",
    "state": "WI",
    "city": "Ridgeway"
  },
  {
    "value": "53583",
    "label": "Sauk City - WI 53583",
    "county": "Sauk County",
    "state": "WI",
    "city": "Sauk City"
  },
  {
    "value": "53585",
    "label": "Sharon - WI 53585",
    "county": "Walworth County",
    "state": "WI",
    "city": "Sharon"
  },
  {
    "value": "53586",
    "label": "Shullsburg - WI 53586",
    "county": "Lafayette County",
    "state": "WI",
    "city": "Shullsburg"
  },
  {
    "value": "53587",
    "label": "South Wayne - WI 53587",
    "county": "Lafayette County",
    "state": "WI",
    "city": "South Wayne"
  },
  {
    "value": "53588",
    "label": "Spring Green - WI 53588",
    "county": "Sauk County",
    "state": "WI",
    "city": "Spring Green"
  },
  {
    "value": "53589",
    "label": "Stoughton - WI 53589",
    "county": "Dane County",
    "state": "WI",
    "city": "Stoughton"
  },
  {
    "value": "53590",
    "label": "Sun Prairie - WI 53590",
    "county": "Dane County",
    "state": "WI",
    "city": "Sun Prairie"
  },
  {
    "value": "53593",
    "label": "Verona - WI 53593",
    "county": "Dane County",
    "state": "WI",
    "city": "Verona"
  },
  {
    "value": "53594",
    "label": "Waterloo - WI 53594",
    "county": "Jefferson County",
    "state": "WI",
    "city": "Waterloo"
  },
  {
    "value": "53597",
    "label": "Waunakee - WI 53597",
    "county": "Dane County",
    "state": "WI",
    "city": "Waunakee"
  },
  {
    "value": "53598",
    "label": "Windsor - WI 53598",
    "county": "Dane County",
    "state": "WI",
    "city": "Windsor"
  },
  {
    "value": "53703",
    "label": "Madison - WI 53703",
    "county": "Dane County",
    "state": "WI",
    "city": "Madison"
  },
  {
    "value": "53704",
    "label": "Madison - WI 53704",
    "county": "Dane County",
    "state": "WI",
    "city": "Madison"
  },
  {
    "value": "53705",
    "label": "Madison - WI 53705",
    "county": "Dane County",
    "state": "WI",
    "city": "Madison"
  },
  {
    "value": "53706",
    "label": "Madison - WI 53706",
    "county": "Dane County",
    "state": "WI",
    "city": "Madison"
  },
  {
    "value": "53711",
    "label": "Madison - WI 53711",
    "county": "Dane County",
    "state": "WI",
    "city": "Madison"
  },
  {
    "value": "53713",
    "label": "Madison - WI 53713",
    "county": "Dane County",
    "state": "WI",
    "city": "Madison"
  },
  {
    "value": "53714",
    "label": "Madison - WI 53714",
    "county": "Dane County",
    "state": "WI",
    "city": "Madison"
  },
  {
    "value": "53715",
    "label": "Madison - WI 53715",
    "county": "Dane County",
    "state": "WI",
    "city": "Madison"
  },
  {
    "value": "53716",
    "label": "Madison - WI 53716",
    "county": "Dane County",
    "state": "WI",
    "city": "Madison"
  },
  {
    "value": "53717",
    "label": "Madison - WI 53717",
    "county": "Dane County",
    "state": "WI",
    "city": "Madison"
  },
  {
    "value": "53718",
    "label": "Madison - WI 53718",
    "county": "Dane County",
    "state": "WI",
    "city": "Madison"
  },
  {
    "value": "53719",
    "label": "Madison - WI 53719",
    "county": "Dane County",
    "state": "WI",
    "city": "Madison"
  },
  {
    "value": "53726",
    "label": "Madison - WI 53726",
    "county": "Dane County",
    "state": "WI",
    "city": "Madison"
  },
  {
    "value": "53801",
    "label": "Bagley - WI 53801",
    "county": "Grant County",
    "state": "WI",
    "city": "Bagley"
  },
  {
    "value": "53803",
    "label": "Benton - WI 53803",
    "county": "Lafayette County",
    "state": "WI",
    "city": "Benton"
  },
  {
    "value": "53804",
    "label": "Bloomington - WI 53804",
    "county": "Grant County",
    "state": "WI",
    "city": "Bloomington"
  },
  {
    "value": "53805",
    "label": "Boscobel - WI 53805",
    "county": "Grant County",
    "state": "WI",
    "city": "Boscobel"
  },
  {
    "value": "53806",
    "label": "Cassville - WI 53806",
    "county": "Grant County",
    "state": "WI",
    "city": "Cassville"
  },
  {
    "value": "53807",
    "label": "Cuba City - WI 53807",
    "county": "Grant County",
    "state": "WI",
    "city": "Cuba City"
  },
  {
    "value": "53809",
    "label": "Fennimore - WI 53809",
    "county": "Grant County",
    "state": "WI",
    "city": "Fennimore"
  },
  {
    "value": "53810",
    "label": "Glen Haven - WI 53810",
    "county": "Grant County",
    "state": "WI",
    "city": "Glen Haven"
  },
  {
    "value": "53811",
    "label": "Hazel Green - WI 53811",
    "county": "Grant County",
    "state": "WI",
    "city": "Hazel Green"
  },
  {
    "value": "53813",
    "label": "Lancaster - WI 53813",
    "county": "Grant County",
    "state": "WI",
    "city": "Lancaster"
  },
  {
    "value": "53816",
    "label": "Mount Hope - WI 53816",
    "county": "Grant County",
    "state": "WI",
    "city": "Mount Hope"
  },
  {
    "value": "53818",
    "label": "Platteville - WI 53818",
    "county": "Grant County",
    "state": "WI",
    "city": "Platteville"
  },
  {
    "value": "53820",
    "label": "Potosi - WI 53820",
    "county": "Grant County",
    "state": "WI",
    "city": "Potosi"
  },
  {
    "value": "53821",
    "label": "Prairie Du Chien - WI 53821",
    "county": "Crawford County",
    "state": "WI",
    "city": "Prairie Du Chien"
  },
  {
    "value": "53825",
    "label": "Stitzer - WI 53825",
    "county": "Grant County",
    "state": "WI",
    "city": "Stitzer"
  },
  {
    "value": "53826",
    "label": "Wauzeka - WI 53826",
    "county": "Crawford County",
    "state": "WI",
    "city": "Wauzeka"
  },
  {
    "value": "53827",
    "label": "Woodman - WI 53827",
    "county": "Grant County",
    "state": "WI",
    "city": "Woodman"
  },
  {
    "value": "53901",
    "label": "Portage - WI 53901",
    "county": "Columbia County",
    "state": "WI",
    "city": "Portage"
  },
  {
    "value": "53910",
    "label": "Adams - WI 53910",
    "county": "Adams County",
    "state": "WI",
    "city": "Adams"
  },
  {
    "value": "53911",
    "label": "Arlington - WI 53911",
    "county": "Columbia County",
    "state": "WI",
    "city": "Arlington"
  },
  {
    "value": "53913",
    "label": "Baraboo - WI 53913",
    "county": "Sauk County",
    "state": "WI",
    "city": "Baraboo"
  },
  {
    "value": "53916",
    "label": "Beaver Dam - WI 53916",
    "county": "Dodge County",
    "state": "WI",
    "city": "Beaver Dam"
  },
  {
    "value": "53919",
    "label": "Brandon - WI 53919",
    "county": "Fond du Lac County",
    "state": "WI",
    "city": "Brandon"
  },
  {
    "value": "53920",
    "label": "Briggsville - WI 53920",
    "county": "Marquette County",
    "state": "WI",
    "city": "Briggsville"
  },
  {
    "value": "53922",
    "label": "Burnett - WI 53922",
    "county": "Dodge County",
    "state": "WI",
    "city": "Burnett"
  },
  {
    "value": "53923",
    "label": "Cambria - WI 53923",
    "county": "Columbia County",
    "state": "WI",
    "city": "Cambria"
  },
  {
    "value": "53924",
    "label": "Cazenovia - WI 53924",
    "county": "Richland County",
    "state": "WI",
    "city": "Cazenovia"
  },
  {
    "value": "53925",
    "label": "Columbus - WI 53925",
    "county": "Columbia County",
    "state": "WI",
    "city": "Columbus"
  },
  {
    "value": "53926",
    "label": "Dalton - WI 53926",
    "county": "Green Lake County",
    "state": "WI",
    "city": "Dalton"
  },
  {
    "value": "53929",
    "label": "Elroy - WI 53929",
    "county": "Juneau County",
    "state": "WI",
    "city": "Elroy"
  },
  {
    "value": "53930",
    "label": "Endeavor - WI 53930",
    "county": "Marquette County",
    "state": "WI",
    "city": "Endeavor"
  },
  {
    "value": "53932",
    "label": "Fall River - WI 53932",
    "county": "Columbia County",
    "state": "WI",
    "city": "Fall River"
  },
  {
    "value": "53933",
    "label": "Fox Lake - WI 53933",
    "county": "Dodge County",
    "state": "WI",
    "city": "Fox Lake"
  },
  {
    "value": "53934",
    "label": "Friendship - WI 53934",
    "county": "Adams County",
    "state": "WI",
    "city": "Friendship"
  },
  {
    "value": "53936",
    "label": "Grand Marsh - WI 53936",
    "county": "Adams County",
    "state": "WI",
    "city": "Grand Marsh"
  },
  {
    "value": "53937",
    "label": "Hillpoint - WI 53937",
    "county": "Sauk County",
    "state": "WI",
    "city": "Hillpoint"
  },
  {
    "value": "53941",
    "label": "La Valle - WI 53941",
    "county": "Sauk County",
    "state": "WI",
    "city": "La Valle"
  },
  {
    "value": "53943",
    "label": "Loganville - WI 53943",
    "county": "Sauk County",
    "state": "WI",
    "city": "Loganville"
  },
  {
    "value": "53944",
    "label": "Lyndon Station - WI 53944",
    "county": "Juneau County",
    "state": "WI",
    "city": "Lyndon Station"
  },
  {
    "value": "53946",
    "label": "Markesan - WI 53946",
    "county": "Green Lake County",
    "state": "WI",
    "city": "Markesan"
  },
  {
    "value": "53948",
    "label": "Mauston - WI 53948",
    "county": "Juneau County",
    "state": "WI",
    "city": "Mauston"
  },
  {
    "value": "53949",
    "label": "Montello - WI 53949",
    "county": "Marquette County",
    "state": "WI",
    "city": "Montello"
  },
  {
    "value": "53950",
    "label": "New Lisbon - WI 53950",
    "county": "Juneau County",
    "state": "WI",
    "city": "New Lisbon"
  },
  {
    "value": "53951",
    "label": "North Freedom - WI 53951",
    "county": "Sauk County",
    "state": "WI",
    "city": "North Freedom"
  },
  {
    "value": "53952",
    "label": "Oxford - WI 53952",
    "county": "Adams County",
    "state": "WI",
    "city": "Oxford"
  },
  {
    "value": "53954",
    "label": "Pardeeville - WI 53954",
    "county": "Columbia County",
    "state": "WI",
    "city": "Pardeeville"
  },
  {
    "value": "53955",
    "label": "Poynette - WI 53955",
    "county": "Columbia County",
    "state": "WI",
    "city": "Poynette"
  },
  {
    "value": "53956",
    "label": "Randolph - WI 53956",
    "county": "Dodge County",
    "state": "WI",
    "city": "Randolph"
  },
  {
    "value": "53959",
    "label": "Reedsburg - WI 53959",
    "county": "Sauk County",
    "state": "WI",
    "city": "Reedsburg"
  },
  {
    "value": "53960",
    "label": "Rio - WI 53960",
    "county": "Columbia County",
    "state": "WI",
    "city": "Rio"
  },
  {
    "value": "53961",
    "label": "Rock Springs - WI 53961",
    "county": "Sauk County",
    "state": "WI",
    "city": "Rock Springs"
  },
  {
    "value": "53963",
    "label": "Waupun - WI 53963",
    "county": "Dodge County",
    "state": "WI",
    "city": "Waupun"
  },
  {
    "value": "53964",
    "label": "Westfield - WI 53964",
    "county": "Marquette County",
    "state": "WI",
    "city": "Westfield"
  },
  {
    "value": "53965",
    "label": "Wisconsin Dells - WI 53965",
    "county": "Sauk County",
    "state": "WI",
    "city": "Wisconsin Dells"
  },
  {
    "value": "53968",
    "label": "Wonewoc - WI 53968",
    "county": "Juneau County",
    "state": "WI",
    "city": "Wonewoc"
  },
  {
    "value": "54001",
    "label": "Amery - WI 54001",
    "county": "Polk County",
    "state": "WI",
    "city": "Amery"
  },
  {
    "value": "54002",
    "label": "Baldwin - WI 54002",
    "county": "St. Croix County",
    "state": "WI",
    "city": "Baldwin"
  },
  {
    "value": "54003",
    "label": "Beldenville - WI 54003",
    "county": "Pierce County",
    "state": "WI",
    "city": "Beldenville"
  },
  {
    "value": "54004",
    "label": "Clayton - WI 54004",
    "county": "Polk County",
    "state": "WI",
    "city": "Clayton"
  },
  {
    "value": "54005",
    "label": "Clear Lake - WI 54005",
    "county": "Polk County",
    "state": "WI",
    "city": "Clear Lake"
  },
  {
    "value": "54006",
    "label": "Cushing - WI 54006",
    "county": "Polk County",
    "state": "WI",
    "city": "Cushing"
  },
  {
    "value": "54007",
    "label": "Deer Park - WI 54007",
    "county": "St. Croix County",
    "state": "WI",
    "city": "Deer Park"
  },
  {
    "value": "54009",
    "label": "Dresser - WI 54009",
    "county": "Polk County",
    "state": "WI",
    "city": "Dresser"
  },
  {
    "value": "54011",
    "label": "Ellsworth - WI 54011",
    "county": "Pierce County",
    "state": "WI",
    "city": "Ellsworth"
  },
  {
    "value": "54013",
    "label": "Glenwood City - WI 54013",
    "county": "St. Croix County",
    "state": "WI",
    "city": "Glenwood City"
  },
  {
    "value": "54014",
    "label": "Hager City - WI 54014",
    "county": "Pierce County",
    "state": "WI",
    "city": "Hager City"
  },
  {
    "value": "54015",
    "label": "Hammond - WI 54015",
    "county": "St. Croix County",
    "state": "WI",
    "city": "Hammond"
  },
  {
    "value": "54016",
    "label": "Hudson - WI 54016",
    "county": "St. Croix County",
    "state": "WI",
    "city": "Hudson"
  },
  {
    "value": "54017",
    "label": "New Richmond - WI 54017",
    "county": "St. Croix County",
    "state": "WI",
    "city": "New Richmond"
  },
  {
    "value": "54020",
    "label": "Osceola - WI 54020",
    "county": "Polk County",
    "state": "WI",
    "city": "Osceola"
  },
  {
    "value": "54021",
    "label": "Prescott - WI 54021",
    "county": "Pierce County",
    "state": "WI",
    "city": "Prescott"
  },
  {
    "value": "54022",
    "label": "River Falls - WI 54022",
    "county": "Pierce County",
    "state": "WI",
    "city": "River Falls"
  },
  {
    "value": "54023",
    "label": "Roberts - WI 54023",
    "county": "St. Croix County",
    "state": "WI",
    "city": "Roberts"
  },
  {
    "value": "54024",
    "label": "Saint Croix Falls - WI 54024",
    "county": "Polk County",
    "state": "WI",
    "city": "Saint Croix Falls"
  },
  {
    "value": "54025",
    "label": "Somerset - WI 54025",
    "county": "St. Croix County",
    "state": "WI",
    "city": "Somerset"
  },
  {
    "value": "54026",
    "label": "Star Prairie - WI 54026",
    "county": "Polk County",
    "state": "WI",
    "city": "Star Prairie"
  },
  {
    "value": "54027",
    "label": "Wilson - WI 54027",
    "county": "St. Croix County",
    "state": "WI",
    "city": "Wilson"
  },
  {
    "value": "54028",
    "label": "Woodville - WI 54028",
    "county": "St. Croix County",
    "state": "WI",
    "city": "Woodville"
  },
  {
    "value": "54082",
    "label": "Houlton - WI 54082",
    "county": "St. Croix County",
    "state": "WI",
    "city": "Houlton"
  },
  {
    "value": "54101",
    "label": "Abrams - WI 54101",
    "county": "Oconto County",
    "state": "WI",
    "city": "Abrams"
  },
  {
    "value": "54102",
    "label": "Amberg - WI 54102",
    "county": "Marinette County",
    "state": "WI",
    "city": "Amberg"
  },
  {
    "value": "54103",
    "label": "Armstrong Creek - WI 54103",
    "county": "Forest County",
    "state": "WI",
    "city": "Armstrong Creek"
  },
  {
    "value": "54104",
    "label": "Athelstane - WI 54104",
    "county": "Marinette County",
    "state": "WI",
    "city": "Athelstane"
  },
  {
    "value": "54106",
    "label": "Black Creek - WI 54106",
    "county": "Outagamie County",
    "state": "WI",
    "city": "Black Creek"
  },
  {
    "value": "54107",
    "label": "Bonduel - WI 54107",
    "county": "Shawano County",
    "state": "WI",
    "city": "Bonduel"
  },
  {
    "value": "54110",
    "label": "Brillion - WI 54110",
    "county": "Calumet County",
    "state": "WI",
    "city": "Brillion"
  },
  {
    "value": "54111",
    "label": "Cecil - WI 54111",
    "county": "Shawano County",
    "state": "WI",
    "city": "Cecil"
  },
  {
    "value": "54112",
    "label": "Coleman - WI 54112",
    "county": "Marinette County",
    "state": "WI",
    "city": "Coleman"
  },
  {
    "value": "54113",
    "label": "Combined Locks - WI 54113",
    "county": "Outagamie County",
    "state": "WI",
    "city": "Combined Locks"
  },
  {
    "value": "54114",
    "label": "Crivitz - WI 54114",
    "county": "Marinette County",
    "state": "WI",
    "city": "Crivitz"
  },
  {
    "value": "54115",
    "label": "De Pere - WI 54115",
    "county": "Brown County",
    "state": "WI",
    "city": "De Pere"
  },
  {
    "value": "54119",
    "label": "Dunbar - WI 54119",
    "county": "Marinette County",
    "state": "WI",
    "city": "Dunbar"
  },
  {
    "value": "54120",
    "label": "Fence - WI 54120",
    "county": "Florence County",
    "state": "WI",
    "city": "Fence"
  },
  {
    "value": "54121",
    "label": "Florence - WI 54121",
    "county": "Florence County",
    "state": "WI",
    "city": "Florence"
  },
  {
    "value": "54123",
    "label": "Forest Junction - WI 54123",
    "county": "Calumet County",
    "state": "WI",
    "city": "Forest Junction"
  },
  {
    "value": "54124",
    "label": "Gillett - WI 54124",
    "county": "Oconto County",
    "state": "WI",
    "city": "Gillett"
  },
  {
    "value": "54125",
    "label": "Goodman - WI 54125",
    "county": "Marinette County",
    "state": "WI",
    "city": "Goodman"
  },
  {
    "value": "54126",
    "label": "Greenleaf - WI 54126",
    "county": "Brown County",
    "state": "WI",
    "city": "Greenleaf"
  },
  {
    "value": "54127",
    "label": "Green Valley - WI 54127",
    "county": "Shawano County",
    "state": "WI",
    "city": "Green Valley"
  },
  {
    "value": "54128",
    "label": "Gresham - WI 54128",
    "county": "Shawano County",
    "state": "WI",
    "city": "Gresham"
  },
  {
    "value": "54129",
    "label": "Hilbert - WI 54129",
    "county": "Calumet County",
    "state": "WI",
    "city": "Hilbert"
  },
  {
    "value": "54130",
    "label": "Kaukauna - WI 54130",
    "county": "Outagamie County",
    "state": "WI",
    "city": "Kaukauna"
  },
  {
    "value": "54135",
    "label": "Keshena - WI 54135",
    "county": "Menominee County",
    "state": "WI",
    "city": "Keshena"
  },
  {
    "value": "54136",
    "label": "Kimberly - WI 54136",
    "county": "Outagamie County",
    "state": "WI",
    "city": "Kimberly"
  },
  {
    "value": "54137",
    "label": "Krakow - WI 54137",
    "county": "Shawano County",
    "state": "WI",
    "city": "Krakow"
  },
  {
    "value": "54138",
    "label": "Lakewood - WI 54138",
    "county": "Oconto County",
    "state": "WI",
    "city": "Lakewood"
  },
  {
    "value": "54139",
    "label": "Lena - WI 54139",
    "county": "Oconto County",
    "state": "WI",
    "city": "Lena"
  },
  {
    "value": "54140",
    "label": "Little Chute - WI 54140",
    "county": "Outagamie County",
    "state": "WI",
    "city": "Little Chute"
  },
  {
    "value": "54141",
    "label": "Little Suamico - WI 54141",
    "county": "Oconto County",
    "state": "WI",
    "city": "Little Suamico"
  },
  {
    "value": "54143",
    "label": "Marinette - WI 54143",
    "county": "Marinette County",
    "state": "WI",
    "city": "Marinette"
  },
  {
    "value": "54149",
    "label": "Mountain - WI 54149",
    "county": "Oconto County",
    "state": "WI",
    "city": "Mountain"
  },
  {
    "value": "54150",
    "label": "Neopit - WI 54150",
    "county": "Menominee County",
    "state": "WI",
    "city": "Neopit"
  },
  {
    "value": "54151",
    "label": "Niagara - WI 54151",
    "county": "Marinette County",
    "state": "WI",
    "city": "Niagara"
  },
  {
    "value": "54153",
    "label": "Oconto - WI 54153",
    "county": "Oconto County",
    "state": "WI",
    "city": "Oconto"
  },
  {
    "value": "54154",
    "label": "Oconto Falls - WI 54154",
    "county": "Oconto County",
    "state": "WI",
    "city": "Oconto Falls"
  },
  {
    "value": "54155",
    "label": "Oneida - WI 54155",
    "county": "Brown County",
    "state": "WI",
    "city": "Oneida"
  },
  {
    "value": "54156",
    "label": "Pembine - WI 54156",
    "county": "Marinette County",
    "state": "WI",
    "city": "Pembine"
  },
  {
    "value": "54157",
    "label": "Peshtigo - WI 54157",
    "county": "Marinette County",
    "state": "WI",
    "city": "Peshtigo"
  },
  {
    "value": "54159",
    "label": "Porterfield - WI 54159",
    "county": "Marinette County",
    "state": "WI",
    "city": "Porterfield"
  },
  {
    "value": "54161",
    "label": "Pound - WI 54161",
    "county": "Marinette County",
    "state": "WI",
    "city": "Pound"
  },
  {
    "value": "54162",
    "label": "Pulaski - WI 54162",
    "county": "Brown County",
    "state": "WI",
    "city": "Pulaski"
  },
  {
    "value": "54165",
    "label": "Seymour - WI 54165",
    "county": "Outagamie County",
    "state": "WI",
    "city": "Seymour"
  },
  {
    "value": "54166",
    "label": "Shawano - WI 54166",
    "county": "Shawano County",
    "state": "WI",
    "city": "Shawano"
  },
  {
    "value": "54169",
    "label": "Sherwood - WI 54169",
    "county": "Calumet County",
    "state": "WI",
    "city": "Sherwood"
  },
  {
    "value": "54170",
    "label": "Shiocton - WI 54170",
    "county": "Outagamie County",
    "state": "WI",
    "city": "Shiocton"
  },
  {
    "value": "54171",
    "label": "Sobieski - WI 54171",
    "county": "Oconto County",
    "state": "WI",
    "city": "Sobieski"
  },
  {
    "value": "54173",
    "label": "Suamico - WI 54173",
    "county": "Brown County",
    "state": "WI",
    "city": "Suamico"
  },
  {
    "value": "54174",
    "label": "Suring - WI 54174",
    "county": "Oconto County",
    "state": "WI",
    "city": "Suring"
  },
  {
    "value": "54175",
    "label": "Townsend - WI 54175",
    "county": "Oconto County",
    "state": "WI",
    "city": "Townsend"
  },
  {
    "value": "54177",
    "label": "Wausaukee - WI 54177",
    "county": "Marinette County",
    "state": "WI",
    "city": "Wausaukee"
  },
  {
    "value": "54180",
    "label": "Wrightstown - WI 54180",
    "county": "Brown County",
    "state": "WI",
    "city": "Wrightstown"
  },
  {
    "value": "54201",
    "label": "Algoma - WI 54201",
    "county": "Kewaunee County",
    "state": "WI",
    "city": "Algoma"
  },
  {
    "value": "54202",
    "label": "Baileys Harbor - WI 54202",
    "county": "Door County",
    "state": "WI",
    "city": "Baileys Harbor"
  },
  {
    "value": "54204",
    "label": "Brussels - WI 54204",
    "county": "Door County",
    "state": "WI",
    "city": "Brussels"
  },
  {
    "value": "54205",
    "label": "Casco - WI 54205",
    "county": "Kewaunee County",
    "state": "WI",
    "city": "Casco"
  },
  {
    "value": "54208",
    "label": "Denmark - WI 54208",
    "county": "Brown County",
    "state": "WI",
    "city": "Denmark"
  },
  {
    "value": "54209",
    "label": "Egg Harbor - WI 54209",
    "county": "Door County",
    "state": "WI",
    "city": "Egg Harbor"
  },
  {
    "value": "54210",
    "label": "Ellison Bay - WI 54210",
    "county": "Door County",
    "state": "WI",
    "city": "Ellison Bay"
  },
  {
    "value": "54212",
    "label": "Fish Creek - WI 54212",
    "county": "Door County",
    "state": "WI",
    "city": "Fish Creek"
  },
  {
    "value": "54213",
    "label": "Forestville - WI 54213",
    "county": "Door County",
    "state": "WI",
    "city": "Forestville"
  },
  {
    "value": "54216",
    "label": "Kewaunee - WI 54216",
    "county": "Kewaunee County",
    "state": "WI",
    "city": "Kewaunee"
  },
  {
    "value": "54217",
    "label": "Luxemburg - WI 54217",
    "county": "Kewaunee County",
    "state": "WI",
    "city": "Luxemburg"
  },
  {
    "value": "54220",
    "label": "Manitowoc - WI 54220",
    "county": "Manitowoc County",
    "state": "WI",
    "city": "Manitowoc"
  },
  {
    "value": "54227",
    "label": "Maribel - WI 54227",
    "county": "Manitowoc County",
    "state": "WI",
    "city": "Maribel"
  },
  {
    "value": "54228",
    "label": "Mishicot - WI 54228",
    "county": "Manitowoc County",
    "state": "WI",
    "city": "Mishicot"
  },
  {
    "value": "54229",
    "label": "New Franken - WI 54229",
    "county": "Brown County",
    "state": "WI",
    "city": "New Franken"
  },
  {
    "value": "54230",
    "label": "Reedsville - WI 54230",
    "county": "Manitowoc County",
    "state": "WI",
    "city": "Reedsville"
  },
  {
    "value": "54234",
    "label": "Sister Bay - WI 54234",
    "county": "Door County",
    "state": "WI",
    "city": "Sister Bay"
  },
  {
    "value": "54235",
    "label": "Sturgeon Bay - WI 54235",
    "county": "Door County",
    "state": "WI",
    "city": "Sturgeon Bay"
  },
  {
    "value": "54241",
    "label": "Two Rivers - WI 54241",
    "county": "Manitowoc County",
    "state": "WI",
    "city": "Two Rivers"
  },
  {
    "value": "54245",
    "label": "Valders - WI 54245",
    "county": "Manitowoc County",
    "state": "WI",
    "city": "Valders"
  },
  {
    "value": "54246",
    "label": "Washington Island - WI 54246",
    "county": "Door County",
    "state": "WI",
    "city": "Washington Island"
  },
  {
    "value": "54247",
    "label": "Whitelaw - WI 54247",
    "county": "Manitowoc County",
    "state": "WI",
    "city": "Whitelaw"
  },
  {
    "value": "54301",
    "label": "Green Bay - WI 54301",
    "county": "Brown County",
    "state": "WI",
    "city": "Green Bay"
  },
  {
    "value": "54302",
    "label": "Green Bay - WI 54302",
    "county": "Brown County",
    "state": "WI",
    "city": "Green Bay"
  },
  {
    "value": "54303",
    "label": "Green Bay - WI 54303",
    "county": "Brown County",
    "state": "WI",
    "city": "Green Bay"
  },
  {
    "value": "54304",
    "label": "Green Bay - WI 54304",
    "county": "Brown County",
    "state": "WI",
    "city": "Green Bay"
  },
  {
    "value": "54311",
    "label": "Green Bay - WI 54311",
    "county": "Brown County",
    "state": "WI",
    "city": "Green Bay"
  },
  {
    "value": "54313",
    "label": "Green Bay - WI 54313",
    "county": "Brown County",
    "state": "WI",
    "city": "Green Bay"
  },
  {
    "value": "54401",
    "label": "Wausau - WI 54401",
    "county": "Marathon County",
    "state": "WI",
    "city": "Wausau"
  },
  {
    "value": "54403",
    "label": "Wausau - WI 54403",
    "county": "Marathon County",
    "state": "WI",
    "city": "Wausau"
  },
  {
    "value": "54404",
    "label": "Marshfield - WI 54404",
    "county": "Wood County",
    "state": "WI",
    "city": "Marshfield"
  },
  {
    "value": "54405",
    "label": "Abbotsford - WI 54405",
    "county": "Clark County",
    "state": "WI",
    "city": "Abbotsford"
  },
  {
    "value": "54406",
    "label": "Amherst - WI 54406",
    "county": "Portage County",
    "state": "WI",
    "city": "Amherst"
  },
  {
    "value": "54407",
    "label": "Amherst Junction - WI 54407",
    "county": "Portage County",
    "state": "WI",
    "city": "Amherst Junction"
  },
  {
    "value": "54408",
    "label": "Aniwa - WI 54408",
    "county": "Marathon County",
    "state": "WI",
    "city": "Aniwa"
  },
  {
    "value": "54409",
    "label": "Antigo - WI 54409",
    "county": "Langlade County",
    "state": "WI",
    "city": "Antigo"
  },
  {
    "value": "54410",
    "label": "Arpin - WI 54410",
    "county": "Wood County",
    "state": "WI",
    "city": "Arpin"
  },
  {
    "value": "54411",
    "label": "Athens - WI 54411",
    "county": "Marathon County",
    "state": "WI",
    "city": "Athens"
  },
  {
    "value": "54412",
    "label": "Auburndale - WI 54412",
    "county": "Wood County",
    "state": "WI",
    "city": "Auburndale"
  },
  {
    "value": "54414",
    "label": "Birnamwood - WI 54414",
    "county": "Shawano County",
    "state": "WI",
    "city": "Birnamwood"
  },
  {
    "value": "54416",
    "label": "Bowler - WI 54416",
    "county": "Shawano County",
    "state": "WI",
    "city": "Bowler"
  },
  {
    "value": "54418",
    "label": "Bryant - WI 54418",
    "county": "Langlade County",
    "state": "WI",
    "city": "Bryant"
  },
  {
    "value": "54420",
    "label": "Chili - WI 54420",
    "county": "Clark County",
    "state": "WI",
    "city": "Chili"
  },
  {
    "value": "54421",
    "label": "Colby - WI 54421",
    "county": "Clark County",
    "state": "WI",
    "city": "Colby"
  },
  {
    "value": "54422",
    "label": "Curtiss - WI 54422",
    "county": "Clark County",
    "state": "WI",
    "city": "Curtiss"
  },
  {
    "value": "54423",
    "label": "Custer - WI 54423",
    "county": "Portage County",
    "state": "WI",
    "city": "Custer"
  },
  {
    "value": "54424",
    "label": "Deerbrook - WI 54424",
    "county": "Langlade County",
    "state": "WI",
    "city": "Deerbrook"
  },
  {
    "value": "54425",
    "label": "Dorchester - WI 54425",
    "county": "Clark County",
    "state": "WI",
    "city": "Dorchester"
  },
  {
    "value": "54426",
    "label": "Edgar - WI 54426",
    "county": "Marathon County",
    "state": "WI",
    "city": "Edgar"
  },
  {
    "value": "54427",
    "label": "Eland - WI 54427",
    "county": "Marathon County",
    "state": "WI",
    "city": "Eland"
  },
  {
    "value": "54428",
    "label": "Elcho - WI 54428",
    "county": "Langlade County",
    "state": "WI",
    "city": "Elcho"
  },
  {
    "value": "54430",
    "label": "Elton - WI 54430",
    "county": "Langlade County",
    "state": "WI",
    "city": "Elton"
  },
  {
    "value": "54433",
    "label": "Gilman - WI 54433",
    "county": "Taylor County",
    "state": "WI",
    "city": "Gilman"
  },
  {
    "value": "54435",
    "label": "Gleason - WI 54435",
    "county": "Lincoln County",
    "state": "WI",
    "city": "Gleason"
  },
  {
    "value": "54436",
    "label": "Granton - WI 54436",
    "county": "Clark County",
    "state": "WI",
    "city": "Granton"
  },
  {
    "value": "54437",
    "label": "Greenwood - WI 54437",
    "county": "Clark County",
    "state": "WI",
    "city": "Greenwood"
  },
  {
    "value": "54440",
    "label": "Hatley - WI 54440",
    "county": "Marathon County",
    "state": "WI",
    "city": "Hatley"
  },
  {
    "value": "54441",
    "label": "Hewitt - WI 54441",
    "county": "Wood County",
    "state": "WI",
    "city": "Hewitt"
  },
  {
    "value": "54442",
    "label": "Irma - WI 54442",
    "county": "Lincoln County",
    "state": "WI",
    "city": "Irma"
  },
  {
    "value": "54443",
    "label": "Junction City - WI 54443",
    "county": "Portage County",
    "state": "WI",
    "city": "Junction City"
  },
  {
    "value": "54446",
    "label": "Loyal - WI 54446",
    "county": "Clark County",
    "state": "WI",
    "city": "Loyal"
  },
  {
    "value": "54447",
    "label": "Lublin - WI 54447",
    "county": "Taylor County",
    "state": "WI",
    "city": "Lublin"
  },
  {
    "value": "54448",
    "label": "Marathon - WI 54448",
    "county": "Marathon County",
    "state": "WI",
    "city": "Marathon"
  },
  {
    "value": "54449",
    "label": "Marshfield - WI 54449",
    "county": "Wood County",
    "state": "WI",
    "city": "Marshfield"
  },
  {
    "value": "54451",
    "label": "Medford - WI 54451",
    "county": "Taylor County",
    "state": "WI",
    "city": "Medford"
  },
  {
    "value": "54452",
    "label": "Merrill - WI 54452",
    "county": "Lincoln County",
    "state": "WI",
    "city": "Merrill"
  },
  {
    "value": "54454",
    "label": "Milladore - WI 54454",
    "county": "Wood County",
    "state": "WI",
    "city": "Milladore"
  },
  {
    "value": "54455",
    "label": "Mosinee - WI 54455",
    "county": "Marathon County",
    "state": "WI",
    "city": "Mosinee"
  },
  {
    "value": "54456",
    "label": "Neillsville - WI 54456",
    "county": "Clark County",
    "state": "WI",
    "city": "Neillsville"
  },
  {
    "value": "54457",
    "label": "Nekoosa - WI 54457",
    "county": "Wood County",
    "state": "WI",
    "city": "Nekoosa"
  },
  {
    "value": "54459",
    "label": "Ogema - WI 54459",
    "county": "Price County",
    "state": "WI",
    "city": "Ogema"
  },
  {
    "value": "54460",
    "label": "Owen - WI 54460",
    "county": "Clark County",
    "state": "WI",
    "city": "Owen"
  },
  {
    "value": "54462",
    "label": "Pearson - WI 54462",
    "county": "Langlade County",
    "state": "WI",
    "city": "Pearson"
  },
  {
    "value": "54463",
    "label": "Pelican Lake - WI 54463",
    "county": "Oneida County",
    "state": "WI",
    "city": "Pelican Lake"
  },
  {
    "value": "54465",
    "label": "Pickerel - WI 54465",
    "county": "Langlade County",
    "state": "WI",
    "city": "Pickerel"
  },
  {
    "value": "54466",
    "label": "Pittsville - WI 54466",
    "county": "Wood County",
    "state": "WI",
    "city": "Pittsville"
  },
  {
    "value": "54467",
    "label": "Plover - WI 54467",
    "county": "Portage County",
    "state": "WI",
    "city": "Plover"
  },
  {
    "value": "54469",
    "label": "Port Edwards - WI 54469",
    "county": "Wood County",
    "state": "WI",
    "city": "Port Edwards"
  },
  {
    "value": "54470",
    "label": "Rib Lake - WI 54470",
    "county": "Taylor County",
    "state": "WI",
    "city": "Rib Lake"
  },
  {
    "value": "54471",
    "label": "Ringle - WI 54471",
    "county": "Marathon County",
    "state": "WI",
    "city": "Ringle"
  },
  {
    "value": "54473",
    "label": "Rosholt - WI 54473",
    "county": "Portage County",
    "state": "WI",
    "city": "Rosholt"
  },
  {
    "value": "54474",
    "label": "Rothschild - WI 54474",
    "county": "Marathon County",
    "state": "WI",
    "city": "Rothschild"
  },
  {
    "value": "54475",
    "label": "Rudolph - WI 54475",
    "county": "Wood County",
    "state": "WI",
    "city": "Rudolph"
  },
  {
    "value": "54476",
    "label": "Schofield - WI 54476",
    "county": "Marathon County",
    "state": "WI",
    "city": "Schofield"
  },
  {
    "value": "54479",
    "label": "Spencer - WI 54479",
    "county": "Marathon County",
    "state": "WI",
    "city": "Spencer"
  },
  {
    "value": "54480",
    "label": "Stetsonville - WI 54480",
    "county": "Taylor County",
    "state": "WI",
    "city": "Stetsonville"
  },
  {
    "value": "54481",
    "label": "Stevens Point - WI 54481",
    "county": "Portage County",
    "state": "WI",
    "city": "Stevens Point"
  },
  {
    "value": "54482",
    "label": "Stevens Point - WI 54482",
    "county": "Portage County",
    "state": "WI",
    "city": "Stevens Point"
  },
  {
    "value": "54484",
    "label": "Stratford - WI 54484",
    "county": "Marathon County",
    "state": "WI",
    "city": "Stratford"
  },
  {
    "value": "54485",
    "label": "Summit Lake - WI 54485",
    "county": "Langlade County",
    "state": "WI",
    "city": "Summit Lake"
  },
  {
    "value": "54486",
    "label": "Tigerton - WI 54486",
    "county": "Shawano County",
    "state": "WI",
    "city": "Tigerton"
  },
  {
    "value": "54487",
    "label": "Tomahawk - WI 54487",
    "county": "Lincoln County",
    "state": "WI",
    "city": "Tomahawk"
  },
  {
    "value": "54488",
    "label": "Unity - WI 54488",
    "county": "Clark County",
    "state": "WI",
    "city": "Unity"
  },
  {
    "value": "54489",
    "label": "Vesper - WI 54489",
    "county": "Wood County",
    "state": "WI",
    "city": "Vesper"
  },
  {
    "value": "54490",
    "label": "Westboro - WI 54490",
    "county": "Taylor County",
    "state": "WI",
    "city": "Westboro"
  },
  {
    "value": "54491",
    "label": "White Lake - WI 54491",
    "county": "Langlade County",
    "state": "WI",
    "city": "White Lake"
  },
  {
    "value": "54493",
    "label": "Willard - WI 54493",
    "county": "Clark County",
    "state": "WI",
    "city": "Willard"
  },
  {
    "value": "54494",
    "label": "Wisconsin Rapids - WI 54494",
    "county": "Wood County",
    "state": "WI",
    "city": "Wisconsin Rapids"
  },
  {
    "value": "54495",
    "label": "Wisconsin Rapids - WI 54495",
    "county": "Wood County",
    "state": "WI",
    "city": "Wisconsin Rapids"
  },
  {
    "value": "54498",
    "label": "Withee - WI 54498",
    "county": "Clark County",
    "state": "WI",
    "city": "Withee"
  },
  {
    "value": "54499",
    "label": "Wittenberg - WI 54499",
    "county": "Shawano County",
    "state": "WI",
    "city": "Wittenberg"
  },
  {
    "value": "54501",
    "label": "Rhinelander - WI 54501",
    "county": "Oneida County",
    "state": "WI",
    "city": "Rhinelander"
  },
  {
    "value": "54511",
    "label": "Argonne - WI 54511",
    "county": "Forest County",
    "state": "WI",
    "city": "Argonne"
  },
  {
    "value": "54512",
    "label": "Boulder Junction - WI 54512",
    "county": "Vilas County",
    "state": "WI",
    "city": "Boulder Junction"
  },
  {
    "value": "54513",
    "label": "Brantwood - WI 54513",
    "county": "Price County",
    "state": "WI",
    "city": "Brantwood"
  },
  {
    "value": "54514",
    "label": "Butternut - WI 54514",
    "county": "Ashland County",
    "state": "WI",
    "city": "Butternut"
  },
  {
    "value": "54515",
    "label": "Catawba - WI 54515",
    "county": "Price County",
    "state": "WI",
    "city": "Catawba"
  },
  {
    "value": "54517",
    "label": "Clam Lake - WI 54517",
    "county": "Ashland County",
    "state": "WI",
    "city": "Clam Lake"
  },
  {
    "value": "54519",
    "label": "Conover - WI 54519",
    "county": "Vilas County",
    "state": "WI",
    "city": "Conover"
  },
  {
    "value": "54520",
    "label": "Crandon - WI 54520",
    "county": "Forest County",
    "state": "WI",
    "city": "Crandon"
  },
  {
    "value": "54521",
    "label": "Eagle River - WI 54521",
    "county": "Vilas County",
    "state": "WI",
    "city": "Eagle River"
  },
  {
    "value": "54524",
    "label": "Fifield - WI 54524",
    "county": "Price County",
    "state": "WI",
    "city": "Fifield"
  },
  {
    "value": "54525",
    "label": "Gile - WI 54525",
    "county": "Iron County",
    "state": "WI",
    "city": "Gile"
  },
  {
    "value": "54526",
    "label": "Glen Flora - WI 54526",
    "county": "Rusk County",
    "state": "WI",
    "city": "Glen Flora"
  },
  {
    "value": "54527",
    "label": "Glidden - WI 54527",
    "county": "Ashland County",
    "state": "WI",
    "city": "Glidden"
  },
  {
    "value": "54529",
    "label": "Harshaw - WI 54529",
    "county": "Oneida County",
    "state": "WI",
    "city": "Harshaw"
  },
  {
    "value": "54530",
    "label": "Hawkins - WI 54530",
    "county": "Rusk County",
    "state": "WI",
    "city": "Hawkins"
  },
  {
    "value": "54531",
    "label": "Hazelhurst - WI 54531",
    "county": "Oneida County",
    "state": "WI",
    "city": "Hazelhurst"
  },
  {
    "value": "54534",
    "label": "Hurley - WI 54534",
    "county": "Iron County",
    "state": "WI",
    "city": "Hurley"
  },
  {
    "value": "54537",
    "label": "Kennan - WI 54537",
    "county": "Price County",
    "state": "WI",
    "city": "Kennan"
  },
  {
    "value": "54538",
    "label": "Lac Du Flambeau - WI 54538",
    "county": "Oneida County",
    "state": "WI",
    "city": "Lac Du Flambeau"
  },
  {
    "value": "54539",
    "label": "Lake Tomahawk - WI 54539",
    "county": "Oneida County",
    "state": "WI",
    "city": "Lake Tomahawk"
  },
  {
    "value": "54540",
    "label": "Land O Lakes - WI 54540",
    "county": "Vilas County",
    "state": "WI",
    "city": "Land O Lakes"
  },
  {
    "value": "54541",
    "label": "Laona - WI 54541",
    "county": "Forest County",
    "state": "WI",
    "city": "Laona"
  },
  {
    "value": "54542",
    "label": "Long Lake - WI 54542",
    "county": "Florence County",
    "state": "WI",
    "city": "Long Lake"
  },
  {
    "value": "54545",
    "label": "Manitowish Waters - WI 54545",
    "county": "Vilas County",
    "state": "WI",
    "city": "Manitowish Waters"
  },
  {
    "value": "54546",
    "label": "Mellen - WI 54546",
    "county": "Ashland County",
    "state": "WI",
    "city": "Mellen"
  },
  {
    "value": "54547",
    "label": "Mercer - WI 54547",
    "county": "Iron County",
    "state": "WI",
    "city": "Mercer"
  },
  {
    "value": "54548",
    "label": "Minocqua - WI 54548",
    "county": "Oneida County",
    "state": "WI",
    "city": "Minocqua"
  },
  {
    "value": "54550",
    "label": "Montreal - WI 54550",
    "county": "Iron County",
    "state": "WI",
    "city": "Montreal"
  },
  {
    "value": "54552",
    "label": "Park Falls - WI 54552",
    "county": "Price County",
    "state": "WI",
    "city": "Park Falls"
  },
  {
    "value": "54554",
    "label": "Phelps - WI 54554",
    "county": "Vilas County",
    "state": "WI",
    "city": "Phelps"
  },
  {
    "value": "54555",
    "label": "Phillips - WI 54555",
    "county": "Price County",
    "state": "WI",
    "city": "Phillips"
  },
  {
    "value": "54556",
    "label": "Prentice - WI 54556",
    "county": "Price County",
    "state": "WI",
    "city": "Prentice"
  },
  {
    "value": "54557",
    "label": "Presque Isle - WI 54557",
    "county": "Vilas County",
    "state": "WI",
    "city": "Presque Isle"
  },
  {
    "value": "54558",
    "label": "Saint Germain - WI 54558",
    "county": "Vilas County",
    "state": "WI",
    "city": "Saint Germain"
  },
  {
    "value": "54559",
    "label": "Saxon - WI 54559",
    "county": "Iron County",
    "state": "WI",
    "city": "Saxon"
  },
  {
    "value": "54560",
    "label": "Sayner - WI 54560",
    "county": "Vilas County",
    "state": "WI",
    "city": "Sayner"
  },
  {
    "value": "54561",
    "label": "Star Lake - WI 54561",
    "county": "Vilas County",
    "state": "WI",
    "city": "Star Lake"
  },
  {
    "value": "54562",
    "label": "Three Lakes - WI 54562",
    "county": "Oneida County",
    "state": "WI",
    "city": "Three Lakes"
  },
  {
    "value": "54563",
    "label": "Tony - WI 54563",
    "county": "Rusk County",
    "state": "WI",
    "city": "Tony"
  },
  {
    "value": "54564",
    "label": "Tripoli - WI 54564",
    "county": "Oneida County",
    "state": "WI",
    "city": "Tripoli"
  },
  {
    "value": "54565",
    "label": "Upson - WI 54565",
    "county": "Iron County",
    "state": "WI",
    "city": "Upson"
  },
  {
    "value": "54566",
    "label": "Wabeno - WI 54566",
    "county": "Forest County",
    "state": "WI",
    "city": "Wabeno"
  },
  {
    "value": "54568",
    "label": "Woodruff - WI 54568",
    "county": "Vilas County",
    "state": "WI",
    "city": "Woodruff"
  },
  {
    "value": "54601",
    "label": "La Crosse - WI 54601",
    "county": "La Crosse County",
    "state": "WI",
    "city": "La Crosse"
  },
  {
    "value": "54603",
    "label": "La Crosse - WI 54603",
    "county": "La Crosse County",
    "state": "WI",
    "city": "La Crosse"
  },
  {
    "value": "54610",
    "label": "Alma - WI 54610",
    "county": "Buffalo County",
    "state": "WI",
    "city": "Alma"
  },
  {
    "value": "54611",
    "label": "Alma Center - WI 54611",
    "county": "Jackson County",
    "state": "WI",
    "city": "Alma Center"
  },
  {
    "value": "54612",
    "label": "Arcadia - WI 54612",
    "county": "Trempealeau County",
    "state": "WI",
    "city": "Arcadia"
  },
  {
    "value": "54613",
    "label": "Arkdale - WI 54613",
    "county": "Adams County",
    "state": "WI",
    "city": "Arkdale"
  },
  {
    "value": "54614",
    "label": "Bangor - WI 54614",
    "county": "La Crosse County",
    "state": "WI",
    "city": "Bangor"
  },
  {
    "value": "54615",
    "label": "Black River Falls - WI 54615",
    "county": "Jackson County",
    "state": "WI",
    "city": "Black River Falls"
  },
  {
    "value": "54616",
    "label": "Blair - WI 54616",
    "county": "Trempealeau County",
    "state": "WI",
    "city": "Blair"
  },
  {
    "value": "54618",
    "label": "Camp Douglas - WI 54618",
    "county": "Juneau County",
    "state": "WI",
    "city": "Camp Douglas"
  },
  {
    "value": "54619",
    "label": "Cashton - WI 54619",
    "county": "Monroe County",
    "state": "WI",
    "city": "Cashton"
  },
  {
    "value": "54621",
    "label": "Chaseburg - WI 54621",
    "county": "Vernon County",
    "state": "WI",
    "city": "Chaseburg"
  },
  {
    "value": "54622",
    "label": "Cochrane - WI 54622",
    "county": "Buffalo County",
    "state": "WI",
    "city": "Cochrane"
  },
  {
    "value": "54623",
    "label": "Coon Valley - WI 54623",
    "county": "Vernon County",
    "state": "WI",
    "city": "Coon Valley"
  },
  {
    "value": "54624",
    "label": "De Soto - WI 54624",
    "county": "Vernon County",
    "state": "WI",
    "city": "De Soto"
  },
  {
    "value": "54625",
    "label": "Dodge - WI 54625",
    "county": "Trempealeau County",
    "state": "WI",
    "city": "Dodge"
  },
  {
    "value": "54626",
    "label": "Eastman - WI 54626",
    "county": "Crawford County",
    "state": "WI",
    "city": "Eastman"
  },
  {
    "value": "54627",
    "label": "Ettrick - WI 54627",
    "county": "Trempealeau County",
    "state": "WI",
    "city": "Ettrick"
  },
  {
    "value": "54628",
    "label": "Ferryville - WI 54628",
    "county": "Crawford County",
    "state": "WI",
    "city": "Ferryville"
  },
  {
    "value": "54629",
    "label": "Fountain City - WI 54629",
    "county": "Buffalo County",
    "state": "WI",
    "city": "Fountain City"
  },
  {
    "value": "54630",
    "label": "Galesville - WI 54630",
    "county": "Trempealeau County",
    "state": "WI",
    "city": "Galesville"
  },
  {
    "value": "54631",
    "label": "Gays Mills - WI 54631",
    "county": "Crawford County",
    "state": "WI",
    "city": "Gays Mills"
  },
  {
    "value": "54632",
    "label": "Genoa - WI 54632",
    "county": "Vernon County",
    "state": "WI",
    "city": "Genoa"
  },
  {
    "value": "54634",
    "label": "Hillsboro - WI 54634",
    "county": "Vernon County",
    "state": "WI",
    "city": "Hillsboro"
  },
  {
    "value": "54635",
    "label": "Hixton - WI 54635",
    "county": "Jackson County",
    "state": "WI",
    "city": "Hixton"
  },
  {
    "value": "54636",
    "label": "Holmen - WI 54636",
    "county": "La Crosse County",
    "state": "WI",
    "city": "Holmen"
  },
  {
    "value": "54638",
    "label": "Kendall - WI 54638",
    "county": "Monroe County",
    "state": "WI",
    "city": "Kendall"
  },
  {
    "value": "54639",
    "label": "La Farge - WI 54639",
    "county": "Vernon County",
    "state": "WI",
    "city": "La Farge"
  },
  {
    "value": "54642",
    "label": "Melrose - WI 54642",
    "county": "Jackson County",
    "state": "WI",
    "city": "Melrose"
  },
  {
    "value": "54644",
    "label": "Mindoro - WI 54644",
    "county": "La Crosse County",
    "state": "WI",
    "city": "Mindoro"
  },
  {
    "value": "54646",
    "label": "Necedah - WI 54646",
    "county": "Juneau County",
    "state": "WI",
    "city": "Necedah"
  },
  {
    "value": "54648",
    "label": "Norwalk - WI 54648",
    "county": "Monroe County",
    "state": "WI",
    "city": "Norwalk"
  },
  {
    "value": "54650",
    "label": "Onalaska - WI 54650",
    "county": "La Crosse County",
    "state": "WI",
    "city": "Onalaska"
  },
  {
    "value": "54651",
    "label": "Ontario - WI 54651",
    "county": "Vernon County",
    "state": "WI",
    "city": "Ontario"
  },
  {
    "value": "54652",
    "label": "Readstown - WI 54652",
    "county": "Vernon County",
    "state": "WI",
    "city": "Readstown"
  },
  {
    "value": "54653",
    "label": "Rockland - WI 54653",
    "county": "La Crosse County",
    "state": "WI",
    "city": "Rockland"
  },
  {
    "value": "54655",
    "label": "Soldiers Grove - WI 54655",
    "county": "Crawford County",
    "state": "WI",
    "city": "Soldiers Grove"
  },
  {
    "value": "54656",
    "label": "Sparta - WI 54656",
    "county": "Monroe County",
    "state": "WI",
    "city": "Sparta"
  },
  {
    "value": "54657",
    "label": "Steuben - WI 54657",
    "county": "Crawford County",
    "state": "WI",
    "city": "Steuben"
  },
  {
    "value": "54658",
    "label": "Stoddard - WI 54658",
    "county": "Vernon County",
    "state": "WI",
    "city": "Stoddard"
  },
  {
    "value": "54659",
    "label": "Taylor - WI 54659",
    "county": "Jackson County",
    "state": "WI",
    "city": "Taylor"
  },
  {
    "value": "54660",
    "label": "Tomah - WI 54660",
    "county": "Monroe County",
    "state": "WI",
    "city": "Tomah"
  },
  {
    "value": "54661",
    "label": "Trempealeau - WI 54661",
    "county": "Trempealeau County",
    "state": "WI",
    "city": "Trempealeau"
  },
  {
    "value": "54664",
    "label": "Viola - WI 54664",
    "county": "Richland County",
    "state": "WI",
    "city": "Viola"
  },
  {
    "value": "54665",
    "label": "Viroqua - WI 54665",
    "county": "Vernon County",
    "state": "WI",
    "city": "Viroqua"
  },
  {
    "value": "54666",
    "label": "Warrens - WI 54666",
    "county": "Monroe County",
    "state": "WI",
    "city": "Warrens"
  },
  {
    "value": "54667",
    "label": "Westby - WI 54667",
    "county": "Vernon County",
    "state": "WI",
    "city": "Westby"
  },
  {
    "value": "54669",
    "label": "West Salem - WI 54669",
    "county": "La Crosse County",
    "state": "WI",
    "city": "West Salem"
  },
  {
    "value": "54670",
    "label": "Wilton - WI 54670",
    "county": "Monroe County",
    "state": "WI",
    "city": "Wilton"
  },
  {
    "value": "54701",
    "label": "Eau Claire - WI 54701",
    "county": "Eau Claire County",
    "state": "WI",
    "city": "Eau Claire"
  },
  {
    "value": "54703",
    "label": "Eau Claire - WI 54703",
    "county": "Eau Claire County",
    "state": "WI",
    "city": "Eau Claire"
  },
  {
    "value": "54720",
    "label": "Altoona - WI 54720",
    "county": "Eau Claire County",
    "state": "WI",
    "city": "Altoona"
  },
  {
    "value": "54721",
    "label": "Arkansaw - WI 54721",
    "county": "Pepin County",
    "state": "WI",
    "city": "Arkansaw"
  },
  {
    "value": "54722",
    "label": "Augusta - WI 54722",
    "county": "Eau Claire County",
    "state": "WI",
    "city": "Augusta"
  },
  {
    "value": "54723",
    "label": "Bay City - WI 54723",
    "county": "Pierce County",
    "state": "WI",
    "city": "Bay City"
  },
  {
    "value": "54724",
    "label": "Bloomer - WI 54724",
    "county": "Chippewa County",
    "state": "WI",
    "city": "Bloomer"
  },
  {
    "value": "54725",
    "label": "Boyceville - WI 54725",
    "county": "Dunn County",
    "state": "WI",
    "city": "Boyceville"
  },
  {
    "value": "54726",
    "label": "Boyd - WI 54726",
    "county": "Chippewa County",
    "state": "WI",
    "city": "Boyd"
  },
  {
    "value": "54727",
    "label": "Cadott - WI 54727",
    "county": "Chippewa County",
    "state": "WI",
    "city": "Cadott"
  },
  {
    "value": "54728",
    "label": "Chetek - WI 54728",
    "county": "Barron County",
    "state": "WI",
    "city": "Chetek"
  },
  {
    "value": "54729",
    "label": "Chippewa Falls - WI 54729",
    "county": "Chippewa County",
    "state": "WI",
    "city": "Chippewa Falls"
  },
  {
    "value": "54730",
    "label": "Colfax - WI 54730",
    "county": "Dunn County",
    "state": "WI",
    "city": "Colfax"
  },
  {
    "value": "54731",
    "label": "Conrath - WI 54731",
    "county": "Rusk County",
    "state": "WI",
    "city": "Conrath"
  },
  {
    "value": "54732",
    "label": "Cornell - WI 54732",
    "county": "Chippewa County",
    "state": "WI",
    "city": "Cornell"
  },
  {
    "value": "54733",
    "label": "Dallas - WI 54733",
    "county": "Barron County",
    "state": "WI",
    "city": "Dallas"
  },
  {
    "value": "54734",
    "label": "Downing - WI 54734",
    "county": "Dunn County",
    "state": "WI",
    "city": "Downing"
  },
  {
    "value": "54736",
    "label": "Durand - WI 54736",
    "county": "Pepin County",
    "state": "WI",
    "city": "Durand"
  },
  {
    "value": "54737",
    "label": "Eau Galle - WI 54737",
    "county": "Dunn County",
    "state": "WI",
    "city": "Eau Galle"
  },
  {
    "value": "54738",
    "label": "Eleva - WI 54738",
    "county": "Eau Claire County",
    "state": "WI",
    "city": "Eleva"
  },
  {
    "value": "54739",
    "label": "Elk Mound - WI 54739",
    "county": "Dunn County",
    "state": "WI",
    "city": "Elk Mound"
  },
  {
    "value": "54740",
    "label": "Elmwood - WI 54740",
    "county": "Pierce County",
    "state": "WI",
    "city": "Elmwood"
  },
  {
    "value": "54741",
    "label": "Fairchild - WI 54741",
    "county": "Eau Claire County",
    "state": "WI",
    "city": "Fairchild"
  },
  {
    "value": "54742",
    "label": "Fall Creek - WI 54742",
    "county": "Eau Claire County",
    "state": "WI",
    "city": "Fall Creek"
  },
  {
    "value": "54745",
    "label": "Holcombe - WI 54745",
    "county": "Chippewa County",
    "state": "WI",
    "city": "Holcombe"
  },
  {
    "value": "54746",
    "label": "Humbird - WI 54746",
    "county": "Clark County",
    "state": "WI",
    "city": "Humbird"
  },
  {
    "value": "54747",
    "label": "Independence - WI 54747",
    "county": "Trempealeau County",
    "state": "WI",
    "city": "Independence"
  },
  {
    "value": "54748",
    "label": "Jim Falls - WI 54748",
    "county": "Chippewa County",
    "state": "WI",
    "city": "Jim Falls"
  },
  {
    "value": "54749",
    "label": "Knapp - WI 54749",
    "county": "Dunn County",
    "state": "WI",
    "city": "Knapp"
  },
  {
    "value": "54750",
    "label": "Maiden Rock - WI 54750",
    "county": "Pierce County",
    "state": "WI",
    "city": "Maiden Rock"
  },
  {
    "value": "54751",
    "label": "Menomonie - WI 54751",
    "county": "Dunn County",
    "state": "WI",
    "city": "Menomonie"
  },
  {
    "value": "54754",
    "label": "Merrillan - WI 54754",
    "county": "Jackson County",
    "state": "WI",
    "city": "Merrillan"
  },
  {
    "value": "54755",
    "label": "Mondovi - WI 54755",
    "county": "Buffalo County",
    "state": "WI",
    "city": "Mondovi"
  },
  {
    "value": "54756",
    "label": "Nelson - WI 54756",
    "county": "Buffalo County",
    "state": "WI",
    "city": "Nelson"
  },
  {
    "value": "54757",
    "label": "New Auburn - WI 54757",
    "county": "Chippewa County",
    "state": "WI",
    "city": "New Auburn"
  },
  {
    "value": "54758",
    "label": "Osseo - WI 54758",
    "county": "Trempealeau County",
    "state": "WI",
    "city": "Osseo"
  },
  {
    "value": "54761",
    "label": "Plum City - WI 54761",
    "county": "Pierce County",
    "state": "WI",
    "city": "Plum City"
  },
  {
    "value": "54762",
    "label": "Prairie Farm - WI 54762",
    "county": "Barron County",
    "state": "WI",
    "city": "Prairie Farm"
  },
  {
    "value": "54763",
    "label": "Ridgeland - WI 54763",
    "county": "Dunn County",
    "state": "WI",
    "city": "Ridgeland"
  },
  {
    "value": "54766",
    "label": "Sheldon - WI 54766",
    "county": "Rusk County",
    "state": "WI",
    "city": "Sheldon"
  },
  {
    "value": "54767",
    "label": "Spring Valley - WI 54767",
    "county": "Pierce County",
    "state": "WI",
    "city": "Spring Valley"
  },
  {
    "value": "54768",
    "label": "Stanley - WI 54768",
    "county": "Chippewa County",
    "state": "WI",
    "city": "Stanley"
  },
  {
    "value": "54769",
    "label": "Stockholm - WI 54769",
    "county": "Pepin County",
    "state": "WI",
    "city": "Stockholm"
  },
  {
    "value": "54770",
    "label": "Strum - WI 54770",
    "county": "Trempealeau County",
    "state": "WI",
    "city": "Strum"
  },
  {
    "value": "54771",
    "label": "Thorp - WI 54771",
    "county": "Clark County",
    "state": "WI",
    "city": "Thorp"
  },
  {
    "value": "54772",
    "label": "Wheeler - WI 54772",
    "county": "Dunn County",
    "state": "WI",
    "city": "Wheeler"
  },
  {
    "value": "54773",
    "label": "Whitehall - WI 54773",
    "county": "Trempealeau County",
    "state": "WI",
    "city": "Whitehall"
  },
  {
    "value": "54801",
    "label": "Spooner - WI 54801",
    "county": "Washburn County",
    "state": "WI",
    "city": "Spooner"
  },
  {
    "value": "54805",
    "label": "Almena - WI 54805",
    "county": "Barron County",
    "state": "WI",
    "city": "Almena"
  },
  {
    "value": "54806",
    "label": "Ashland - WI 54806",
    "county": "Ashland County",
    "state": "WI",
    "city": "Ashland"
  },
  {
    "value": "54810",
    "label": "Balsam Lake - WI 54810",
    "county": "Polk County",
    "state": "WI",
    "city": "Balsam Lake"
  },
  {
    "value": "54812",
    "label": "Barron - WI 54812",
    "county": "Barron County",
    "state": "WI",
    "city": "Barron"
  },
  {
    "value": "54813",
    "label": "Barronett - WI 54813",
    "county": "Barron County",
    "state": "WI",
    "city": "Barronett"
  },
  {
    "value": "54814",
    "label": "Bayfield - WI 54814",
    "county": "Bayfield County",
    "state": "WI",
    "city": "Bayfield"
  },
  {
    "value": "54817",
    "label": "Birchwood - WI 54817",
    "county": "Washburn County",
    "state": "WI",
    "city": "Birchwood"
  },
  {
    "value": "54819",
    "label": "Bruce - WI 54819",
    "county": "Rusk County",
    "state": "WI",
    "city": "Bruce"
  },
  {
    "value": "54820",
    "label": "Brule - WI 54820",
    "county": "Douglas County",
    "state": "WI",
    "city": "Brule"
  },
  {
    "value": "54821",
    "label": "Cable - WI 54821",
    "county": "Bayfield County",
    "state": "WI",
    "city": "Cable"
  },
  {
    "value": "54822",
    "label": "Cameron - WI 54822",
    "county": "Barron County",
    "state": "WI",
    "city": "Cameron"
  },
  {
    "value": "54824",
    "label": "Centuria - WI 54824",
    "county": "Polk County",
    "state": "WI",
    "city": "Centuria"
  },
  {
    "value": "54826",
    "label": "Comstock - WI 54826",
    "county": "Barron County",
    "state": "WI",
    "city": "Comstock"
  },
  {
    "value": "54827",
    "label": "Cornucopia - WI 54827",
    "county": "Bayfield County",
    "state": "WI",
    "city": "Cornucopia"
  },
  {
    "value": "54828",
    "label": "Couderay - WI 54828",
    "county": "Sawyer County",
    "state": "WI",
    "city": "Couderay"
  },
  {
    "value": "54829",
    "label": "Cumberland - WI 54829",
    "county": "Barron County",
    "state": "WI",
    "city": "Cumberland"
  },
  {
    "value": "54830",
    "label": "Danbury - WI 54830",
    "county": "Burnett County",
    "state": "WI",
    "city": "Danbury"
  },
  {
    "value": "54832",
    "label": "Drummond - WI 54832",
    "county": "Bayfield County",
    "state": "WI",
    "city": "Drummond"
  },
  {
    "value": "54835",
    "label": "Exeland - WI 54835",
    "county": "Sawyer County",
    "state": "WI",
    "city": "Exeland"
  },
  {
    "value": "54836",
    "label": "Foxboro - WI 54836",
    "county": "Douglas County",
    "state": "WI",
    "city": "Foxboro"
  },
  {
    "value": "54837",
    "label": "Frederic - WI 54837",
    "county": "Polk County",
    "state": "WI",
    "city": "Frederic"
  },
  {
    "value": "54838",
    "label": "Gordon - WI 54838",
    "county": "Douglas County",
    "state": "WI",
    "city": "Gordon"
  },
  {
    "value": "54839",
    "label": "Grand View - WI 54839",
    "county": "Bayfield County",
    "state": "WI",
    "city": "Grand View"
  },
  {
    "value": "54840",
    "label": "Grantsburg - WI 54840",
    "county": "Burnett County",
    "state": "WI",
    "city": "Grantsburg"
  },
  {
    "value": "54841",
    "label": "Haugen - WI 54841",
    "county": "Barron County",
    "state": "WI",
    "city": "Haugen"
  },
  {
    "value": "54843",
    "label": "Hayward - WI 54843",
    "county": "Sawyer County",
    "state": "WI",
    "city": "Hayward"
  },
  {
    "value": "54844",
    "label": "Herbster - WI 54844",
    "county": "Bayfield County",
    "state": "WI",
    "city": "Herbster"
  },
  {
    "value": "54845",
    "label": "Hertel - WI 54845",
    "county": "Burnett County",
    "state": "WI",
    "city": "Hertel"
  },
  {
    "value": "54846",
    "label": "High Bridge - WI 54846",
    "county": "Ashland County",
    "state": "WI",
    "city": "High Bridge"
  },
  {
    "value": "54847",
    "label": "Iron River - WI 54847",
    "county": "Bayfield County",
    "state": "WI",
    "city": "Iron River"
  },
  {
    "value": "54848",
    "label": "Ladysmith - WI 54848",
    "county": "Rusk County",
    "state": "WI",
    "city": "Ladysmith"
  },
  {
    "value": "54849",
    "label": "Lake Nebagamon - WI 54849",
    "county": "Douglas County",
    "state": "WI",
    "city": "Lake Nebagamon"
  },
  {
    "value": "54853",
    "label": "Luck - WI 54853",
    "county": "Polk County",
    "state": "WI",
    "city": "Luck"
  },
  {
    "value": "54854",
    "label": "Maple - WI 54854",
    "county": "Douglas County",
    "state": "WI",
    "city": "Maple"
  },
  {
    "value": "54855",
    "label": "Marengo - WI 54855",
    "county": "Ashland County",
    "state": "WI",
    "city": "Marengo"
  },
  {
    "value": "54856",
    "label": "Mason - WI 54856",
    "county": "Bayfield County",
    "state": "WI",
    "city": "Mason"
  },
  {
    "value": "54857",
    "label": "Mikana - WI 54857",
    "county": "Barron County",
    "state": "WI",
    "city": "Mikana"
  },
  {
    "value": "54858",
    "label": "Milltown - WI 54858",
    "county": "Polk County",
    "state": "WI",
    "city": "Milltown"
  },
  {
    "value": "54859",
    "label": "Minong - WI 54859",
    "county": "Washburn County",
    "state": "WI",
    "city": "Minong"
  },
  {
    "value": "54862",
    "label": "Ojibwa - WI 54862",
    "county": "Sawyer County",
    "state": "WI",
    "city": "Ojibwa"
  },
  {
    "value": "54864",
    "label": "Poplar - WI 54864",
    "county": "Douglas County",
    "state": "WI",
    "city": "Poplar"
  },
  {
    "value": "54865",
    "label": "Port Wing - WI 54865",
    "county": "Bayfield County",
    "state": "WI",
    "city": "Port Wing"
  },
  {
    "value": "54867",
    "label": "Radisson - WI 54867",
    "county": "Sawyer County",
    "state": "WI",
    "city": "Radisson"
  },
  {
    "value": "54868",
    "label": "Rice Lake - WI 54868",
    "county": "Barron County",
    "state": "WI",
    "city": "Rice Lake"
  },
  {
    "value": "54870",
    "label": "Sarona - WI 54870",
    "county": "Washburn County",
    "state": "WI",
    "city": "Sarona"
  },
  {
    "value": "54871",
    "label": "Shell Lake - WI 54871",
    "county": "Washburn County",
    "state": "WI",
    "city": "Shell Lake"
  },
  {
    "value": "54872",
    "label": "Siren - WI 54872",
    "county": "Burnett County",
    "state": "WI",
    "city": "Siren"
  },
  {
    "value": "54873",
    "label": "Solon Springs - WI 54873",
    "county": "Douglas County",
    "state": "WI",
    "city": "Solon Springs"
  },
  {
    "value": "54874",
    "label": "South Range - WI 54874",
    "county": "Douglas County",
    "state": "WI",
    "city": "South Range"
  },
  {
    "value": "54875",
    "label": "Springbrook - WI 54875",
    "county": "Washburn County",
    "state": "WI",
    "city": "Springbrook"
  },
  {
    "value": "54876",
    "label": "Stone Lake - WI 54876",
    "county": "Sawyer County",
    "state": "WI",
    "city": "Stone Lake"
  },
  {
    "value": "54880",
    "label": "Superior - WI 54880",
    "county": "Douglas County",
    "state": "WI",
    "city": "Superior"
  },
  {
    "value": "54888",
    "label": "Trego - WI 54888",
    "county": "Washburn County",
    "state": "WI",
    "city": "Trego"
  },
  {
    "value": "54889",
    "label": "Turtle Lake - WI 54889",
    "county": "Barron County",
    "state": "WI",
    "city": "Turtle Lake"
  },
  {
    "value": "54891",
    "label": "Washburn - WI 54891",
    "county": "Bayfield County",
    "state": "WI",
    "city": "Washburn"
  },
  {
    "value": "54893",
    "label": "Webster - WI 54893",
    "county": "Burnett County",
    "state": "WI",
    "city": "Webster"
  },
  {
    "value": "54895",
    "label": "Weyerhaeuser - WI 54895",
    "county": "Rusk County",
    "state": "WI",
    "city": "Weyerhaeuser"
  },
  {
    "value": "54896",
    "label": "Winter - WI 54896",
    "county": "Sawyer County",
    "state": "WI",
    "city": "Winter"
  },
  {
    "value": "54901",
    "label": "Oshkosh - WI 54901",
    "county": "Winnebago County",
    "state": "WI",
    "city": "Oshkosh"
  },
  {
    "value": "54902",
    "label": "Oshkosh - WI 54902",
    "county": "Winnebago County",
    "state": "WI",
    "city": "Oshkosh"
  },
  {
    "value": "54904",
    "label": "Oshkosh - WI 54904",
    "county": "Winnebago County",
    "state": "WI",
    "city": "Oshkosh"
  },
  {
    "value": "54909",
    "label": "Almond - WI 54909",
    "county": "Portage County",
    "state": "WI",
    "city": "Almond"
  },
  {
    "value": "54911",
    "label": "Appleton - WI 54911",
    "county": "Outagamie County",
    "state": "WI",
    "city": "Appleton"
  },
  {
    "value": "54913",
    "label": "Appleton - WI 54913",
    "county": "Outagamie County",
    "state": "WI",
    "city": "Appleton"
  },
  {
    "value": "54914",
    "label": "Appleton - WI 54914",
    "county": "Outagamie County",
    "state": "WI",
    "city": "Appleton"
  },
  {
    "value": "54915",
    "label": "Appleton - WI 54915",
    "county": "Outagamie County",
    "state": "WI",
    "city": "Appleton"
  },
  {
    "value": "54921",
    "label": "Bancroft - WI 54921",
    "county": "Portage County",
    "state": "WI",
    "city": "Bancroft"
  },
  {
    "value": "54922",
    "label": "Bear Creek - WI 54922",
    "county": "Outagamie County",
    "state": "WI",
    "city": "Bear Creek"
  },
  {
    "value": "54923",
    "label": "Berlin - WI 54923",
    "county": "Green Lake County",
    "state": "WI",
    "city": "Berlin"
  },
  {
    "value": "54928",
    "label": "Caroline - WI 54928",
    "county": "Shawano County",
    "state": "WI",
    "city": "Caroline"
  },
  {
    "value": "54929",
    "label": "Clintonville - WI 54929",
    "county": "Waupaca County",
    "state": "WI",
    "city": "Clintonville"
  },
  {
    "value": "54930",
    "label": "Coloma - WI 54930",
    "county": "Waushara County",
    "state": "WI",
    "city": "Coloma"
  },
  {
    "value": "54932",
    "label": "Eldorado - WI 54932",
    "county": "Fond du Lac County",
    "state": "WI",
    "city": "Eldorado"
  },
  {
    "value": "54935",
    "label": "Fond Du Lac - WI 54935",
    "county": "Fond du Lac County",
    "state": "WI",
    "city": "Fond Du Lac"
  },
  {
    "value": "54937",
    "label": "Fond Du Lac - WI 54937",
    "county": "Fond du Lac County",
    "state": "WI",
    "city": "Fond Du Lac"
  },
  {
    "value": "54940",
    "label": "Fremont - WI 54940",
    "county": "Waupaca County",
    "state": "WI",
    "city": "Fremont"
  },
  {
    "value": "54941",
    "label": "Green Lake - WI 54941",
    "county": "Green Lake County",
    "state": "WI",
    "city": "Green Lake"
  },
  {
    "value": "54942",
    "label": "Greenville - WI 54942",
    "county": "Outagamie County",
    "state": "WI",
    "city": "Greenville"
  },
  {
    "value": "54943",
    "label": "Hancock - WI 54943",
    "county": "Waushara County",
    "state": "WI",
    "city": "Hancock"
  },
  {
    "value": "54944",
    "label": "Hortonville - WI 54944",
    "county": "Outagamie County",
    "state": "WI",
    "city": "Hortonville"
  },
  {
    "value": "54945",
    "label": "Iola - WI 54945",
    "county": "Waupaca County",
    "state": "WI",
    "city": "Iola"
  },
  {
    "value": "54947",
    "label": "Larsen - WI 54947",
    "county": "Winnebago County",
    "state": "WI",
    "city": "Larsen"
  },
  {
    "value": "54948",
    "label": "Leopolis - WI 54948",
    "county": "Shawano County",
    "state": "WI",
    "city": "Leopolis"
  },
  {
    "value": "54949",
    "label": "Manawa - WI 54949",
    "county": "Waupaca County",
    "state": "WI",
    "city": "Manawa"
  },
  {
    "value": "54950",
    "label": "Marion - WI 54950",
    "county": "Waupaca County",
    "state": "WI",
    "city": "Marion"
  },
  {
    "value": "54952",
    "label": "Menasha - WI 54952",
    "county": "Winnebago County",
    "state": "WI",
    "city": "Menasha"
  },
  {
    "value": "54956",
    "label": "Neenah - WI 54956",
    "county": "Winnebago County",
    "state": "WI",
    "city": "Neenah"
  },
  {
    "value": "54960",
    "label": "Neshkoro - WI 54960",
    "county": "Marquette County",
    "state": "WI",
    "city": "Neshkoro"
  },
  {
    "value": "54961",
    "label": "New London - WI 54961",
    "county": "Waupaca County",
    "state": "WI",
    "city": "New London"
  },
  {
    "value": "54962",
    "label": "Ogdensburg - WI 54962",
    "county": "Waupaca County",
    "state": "WI",
    "city": "Ogdensburg"
  },
  {
    "value": "54963",
    "label": "Omro - WI 54963",
    "county": "Winnebago County",
    "state": "WI",
    "city": "Omro"
  },
  {
    "value": "54964",
    "label": "Pickett - WI 54964",
    "county": "Winnebago County",
    "state": "WI",
    "city": "Pickett"
  },
  {
    "value": "54965",
    "label": "Pine River - WI 54965",
    "county": "Waushara County",
    "state": "WI",
    "city": "Pine River"
  },
  {
    "value": "54966",
    "label": "Plainfield - WI 54966",
    "county": "Waushara County",
    "state": "WI",
    "city": "Plainfield"
  },
  {
    "value": "54967",
    "label": "Poy Sippi - WI 54967",
    "county": "Waushara County",
    "state": "WI",
    "city": "Poy Sippi"
  },
  {
    "value": "54968",
    "label": "Princeton - WI 54968",
    "county": "Green Lake County",
    "state": "WI",
    "city": "Princeton"
  },
  {
    "value": "54970",
    "label": "Redgranite - WI 54970",
    "county": "Waushara County",
    "state": "WI",
    "city": "Redgranite"
  },
  {
    "value": "54971",
    "label": "Ripon - WI 54971",
    "county": "Fond du Lac County",
    "state": "WI",
    "city": "Ripon"
  },
  {
    "value": "54974",
    "label": "Rosendale - WI 54974",
    "county": "Fond du Lac County",
    "state": "WI",
    "city": "Rosendale"
  },
  {
    "value": "54977",
    "label": "Scandinavia - WI 54977",
    "county": "Waupaca County",
    "state": "WI",
    "city": "Scandinavia"
  },
  {
    "value": "54978",
    "label": "Tilleda - WI 54978",
    "county": "Shawano County",
    "state": "WI",
    "city": "Tilleda"
  },
  {
    "value": "54979",
    "label": "Van Dyne - WI 54979",
    "county": "Fond du Lac County",
    "state": "WI",
    "city": "Van Dyne"
  },
  {
    "value": "54981",
    "label": "Waupaca - WI 54981",
    "county": "Waupaca County",
    "state": "WI",
    "city": "Waupaca"
  },
  {
    "value": "54982",
    "label": "Wautoma - WI 54982",
    "county": "Waushara County",
    "state": "WI",
    "city": "Wautoma"
  },
  {
    "value": "54983",
    "label": "Weyauwega - WI 54983",
    "county": "Waupaca County",
    "state": "WI",
    "city": "Weyauwega"
  },
  {
    "value": "54984",
    "label": "Wild Rose - WI 54984",
    "county": "Waushara County",
    "state": "WI",
    "city": "Wild Rose"
  },
  {
    "value": "54986",
    "label": "Winneconne - WI 54986",
    "county": "Winnebago County",
    "state": "WI",
    "city": "Winneconne"
  },
  {
    "value": "82001",
    "label": "Cheyenne - WY 82001",
    "county": "Laramie County",
    "state": "WY",
    "city": "Cheyenne"
  },
  {
    "value": "82005",
    "label": "Fe Warren AFB - WY 82005",
    "county": "Laramie County",
    "state": "WY",
    "city": "Fe Warren AFB"
  },
  {
    "value": "82007",
    "label": "Cheyenne - WY 82007",
    "county": "Laramie County",
    "state": "WY",
    "city": "Cheyenne"
  },
  {
    "value": "82009",
    "label": "Cheyenne - WY 82009",
    "county": "Laramie County",
    "state": "WY",
    "city": "Cheyenne"
  },
  {
    "value": "82050",
    "label": "Albin - WY 82050",
    "county": "Laramie County",
    "state": "WY",
    "city": "Albin"
  },
  {
    "value": "82051",
    "label": "Bosler - WY 82051",
    "county": "Albany County",
    "state": "WY",
    "city": "Bosler"
  },
  {
    "value": "82052",
    "label": "Buford - WY 82052",
    "county": "Albany County",
    "state": "WY",
    "city": "Buford"
  },
  {
    "value": "82053",
    "label": "Burns - WY 82053",
    "county": "Laramie County",
    "state": "WY",
    "city": "Burns"
  },
  {
    "value": "82054",
    "label": "Carpenter - WY 82054",
    "county": "Laramie County",
    "state": "WY",
    "city": "Carpenter"
  },
  {
    "value": "82058",
    "label": "Garrett - WY 82058",
    "county": "Albany County",
    "state": "WY",
    "city": "Garrett"
  },
  {
    "value": "82063",
    "label": "Jelm - WY 82063",
    "county": "Albany County",
    "state": "WY",
    "city": "Jelm"
  },
  {
    "value": "82070",
    "label": "Laramie - WY 82070",
    "county": "Albany County",
    "state": "WY",
    "city": "Laramie"
  },
  {
    "value": "82072",
    "label": "Laramie - WY 82072",
    "county": "Albany County",
    "state": "WY",
    "city": "Laramie"
  },
  {
    "value": "82081",
    "label": "Meriden - WY 82081",
    "county": "Laramie County",
    "state": "WY",
    "city": "Meriden"
  },
  {
    "value": "82082",
    "label": "Pine Bluffs - WY 82082",
    "county": "Laramie County",
    "state": "WY",
    "city": "Pine Bluffs"
  },
  {
    "value": "82083",
    "label": "Rock River - WY 82083",
    "county": "Albany County",
    "state": "WY",
    "city": "Rock River"
  },
  {
    "value": "82190",
    "label": "Yellowstone National Park - WY 82190",
    "county": "Park County",
    "state": "WY",
    "city": "Yellowstone National Park"
  },
  {
    "value": "82201",
    "label": "Wheatland - WY 82201",
    "county": "Platte County",
    "state": "WY",
    "city": "Wheatland"
  },
  {
    "value": "82210",
    "label": "Chugwater - WY 82210",
    "county": "Platte County",
    "state": "WY",
    "city": "Chugwater"
  },
  {
    "value": "82212",
    "label": "Fort Laramie - WY 82212",
    "county": "Goshen County",
    "state": "WY",
    "city": "Fort Laramie"
  },
  {
    "value": "82213",
    "label": "Glendo - WY 82213",
    "county": "Platte County",
    "state": "WY",
    "city": "Glendo"
  },
  {
    "value": "82214",
    "label": "Guernsey - WY 82214",
    "county": "Platte County",
    "state": "WY",
    "city": "Guernsey"
  },
  {
    "value": "82215",
    "label": "Hartville - WY 82215",
    "county": "Platte County",
    "state": "WY",
    "city": "Hartville"
  },
  {
    "value": "82217",
    "label": "Hawk Springs - WY 82217",
    "county": "Goshen County",
    "state": "WY",
    "city": "Hawk Springs"
  },
  {
    "value": "82219",
    "label": "Jay Em - WY 82219",
    "county": "Goshen County",
    "state": "WY",
    "city": "Jay Em"
  },
  {
    "value": "82221",
    "label": "Lagrange - WY 82221",
    "county": "Goshen County",
    "state": "WY",
    "city": "Lagrange"
  },
  {
    "value": "82222",
    "label": "Lance Creek - WY 82222",
    "county": "Niobrara County",
    "state": "WY",
    "city": "Lance Creek"
  },
  {
    "value": "82223",
    "label": "Lingle - WY 82223",
    "county": "Goshen County",
    "state": "WY",
    "city": "Lingle"
  },
  {
    "value": "82224",
    "label": "Lost Springs - WY 82224",
    "county": "Niobrara County",
    "state": "WY",
    "city": "Lost Springs"
  },
  {
    "value": "82225",
    "label": "Lusk - WY 82225",
    "county": "Niobrara County",
    "state": "WY",
    "city": "Lusk"
  },
  {
    "value": "82227",
    "label": "Manville - WY 82227",
    "county": "Niobrara County",
    "state": "WY",
    "city": "Manville"
  },
  {
    "value": "82229",
    "label": "Shawnee - WY 82229",
    "county": "Converse County",
    "state": "WY",
    "city": "Shawnee"
  },
  {
    "value": "82240",
    "label": "Torrington - WY 82240",
    "county": "Goshen County",
    "state": "WY",
    "city": "Torrington"
  },
  {
    "value": "82242",
    "label": "Van Tassell - WY 82242",
    "county": "Niobrara County",
    "state": "WY",
    "city": "Van Tassell"
  },
  {
    "value": "82243",
    "label": "Veteran - WY 82243",
    "county": "Goshen County",
    "state": "WY",
    "city": "Veteran"
  },
  {
    "value": "82244",
    "label": "Yoder - WY 82244",
    "county": "Goshen County",
    "state": "WY",
    "city": "Yoder"
  },
  {
    "value": "82301",
    "label": "Rawlins - WY 82301",
    "county": "Carbon County",
    "state": "WY",
    "city": "Rawlins"
  },
  {
    "value": "82321",
    "label": "Baggs - WY 82321",
    "county": "Carbon County",
    "state": "WY",
    "city": "Baggs"
  },
  {
    "value": "82323",
    "label": "Dixon - WY 82323",
    "county": "Carbon County",
    "state": "WY",
    "city": "Dixon"
  },
  {
    "value": "82325",
    "label": "Encampment - WY 82325",
    "county": "Carbon County",
    "state": "WY",
    "city": "Encampment"
  },
  {
    "value": "82327",
    "label": "Hanna - WY 82327",
    "county": "Carbon County",
    "state": "WY",
    "city": "Hanna"
  },
  {
    "value": "82329",
    "label": "Medicine Bow - WY 82329",
    "county": "Carbon County",
    "state": "WY",
    "city": "Medicine Bow"
  },
  {
    "value": "82331",
    "label": "Saratoga - WY 82331",
    "county": "Carbon County",
    "state": "WY",
    "city": "Saratoga"
  },
  {
    "value": "82332",
    "label": "Savery - WY 82332",
    "county": "Carbon County",
    "state": "WY",
    "city": "Savery"
  },
  {
    "value": "82334",
    "label": "Sinclair - WY 82334",
    "county": "Carbon County",
    "state": "WY",
    "city": "Sinclair"
  },
  {
    "value": "82401",
    "label": "Worland - WY 82401",
    "county": "Washakie County",
    "state": "WY",
    "city": "Worland"
  },
  {
    "value": "82410",
    "label": "Basin - WY 82410",
    "county": "Big Horn County",
    "state": "WY",
    "city": "Basin"
  },
  {
    "value": "82411",
    "label": "Burlington - WY 82411",
    "county": "Big Horn County",
    "state": "WY",
    "city": "Burlington"
  },
  {
    "value": "82414",
    "label": "Cody - WY 82414",
    "county": "Park County",
    "state": "WY",
    "city": "Cody"
  },
  {
    "value": "82421",
    "label": "Deaver - WY 82421",
    "county": "Big Horn County",
    "state": "WY",
    "city": "Deaver"
  },
  {
    "value": "82426",
    "label": "Greybull - WY 82426",
    "county": "Big Horn County",
    "state": "WY",
    "city": "Greybull"
  },
  {
    "value": "82428",
    "label": "Hyattville - WY 82428",
    "county": "Big Horn County",
    "state": "WY",
    "city": "Hyattville"
  },
  {
    "value": "82430",
    "label": "Kirby - WY 82430",
    "county": "Hot Springs County",
    "state": "WY",
    "city": "Kirby"
  },
  {
    "value": "82431",
    "label": "Lovell - WY 82431",
    "county": "Big Horn County",
    "state": "WY",
    "city": "Lovell"
  },
  {
    "value": "82432",
    "label": "Manderson - WY 82432",
    "county": "Big Horn County",
    "state": "WY",
    "city": "Manderson"
  },
  {
    "value": "82433",
    "label": "Meeteetse - WY 82433",
    "county": "Park County",
    "state": "WY",
    "city": "Meeteetse"
  },
  {
    "value": "82434",
    "label": "Otto - WY 82434",
    "county": "Big Horn County",
    "state": "WY",
    "city": "Otto"
  },
  {
    "value": "82435",
    "label": "Powell - WY 82435",
    "county": "Park County",
    "state": "WY",
    "city": "Powell"
  },
  {
    "value": "82441",
    "label": "Shell - WY 82441",
    "county": "Big Horn County",
    "state": "WY",
    "city": "Shell"
  },
  {
    "value": "82442",
    "label": "Ten Sleep - WY 82442",
    "county": "Washakie County",
    "state": "WY",
    "city": "Ten Sleep"
  },
  {
    "value": "82443",
    "label": "Thermopolis - WY 82443",
    "county": "Hot Springs County",
    "state": "WY",
    "city": "Thermopolis"
  },
  {
    "value": "82501",
    "label": "Riverton - WY 82501",
    "county": "Fremont County",
    "state": "WY",
    "city": "Riverton"
  },
  {
    "value": "82510",
    "label": "Arapahoe - WY 82510",
    "county": "Fremont County",
    "state": "WY",
    "city": "Arapahoe"
  },
  {
    "value": "82512",
    "label": "Crowheart - WY 82512",
    "county": "Fremont County",
    "state": "WY",
    "city": "Crowheart"
  },
  {
    "value": "82513",
    "label": "Dubois - WY 82513",
    "county": "Fremont County",
    "state": "WY",
    "city": "Dubois"
  },
  {
    "value": "82514",
    "label": "Fort Washakie - WY 82514",
    "county": "Fremont County",
    "state": "WY",
    "city": "Fort Washakie"
  },
  {
    "value": "82516",
    "label": "Kinnear - WY 82516",
    "county": "Fremont County",
    "state": "WY",
    "city": "Kinnear"
  },
  {
    "value": "82520",
    "label": "Lander - WY 82520",
    "county": "Fremont County",
    "state": "WY",
    "city": "Lander"
  },
  {
    "value": "82523",
    "label": "Pavillion - WY 82523",
    "county": "Fremont County",
    "state": "WY",
    "city": "Pavillion"
  },
  {
    "value": "82601",
    "label": "Casper - WY 82601",
    "county": "Natrona County",
    "state": "WY",
    "city": "Casper"
  },
  {
    "value": "82604",
    "label": "Casper - WY 82604",
    "county": "Natrona County",
    "state": "WY",
    "city": "Casper"
  },
  {
    "value": "82609",
    "label": "Casper - WY 82609",
    "county": "Natrona County",
    "state": "WY",
    "city": "Casper"
  },
  {
    "value": "82615",
    "label": "Shirley Basin - WY 82615",
    "county": "Carbon County",
    "state": "WY",
    "city": "Shirley Basin"
  },
  {
    "value": "82620",
    "label": "Alcova - WY 82620",
    "county": "Natrona County",
    "state": "WY",
    "city": "Alcova"
  },
  {
    "value": "82630",
    "label": "Arminto - WY 82630",
    "county": "Natrona County",
    "state": "WY",
    "city": "Arminto"
  },
  {
    "value": "82633",
    "label": "Douglas - WY 82633",
    "county": "Converse County",
    "state": "WY",
    "city": "Douglas"
  },
  {
    "value": "82636",
    "label": "Evansville - WY 82636",
    "county": "Natrona County",
    "state": "WY",
    "city": "Evansville"
  },
  {
    "value": "82637",
    "label": "Glenrock - WY 82637",
    "county": "Converse County",
    "state": "WY",
    "city": "Glenrock"
  },
  {
    "value": "82638",
    "label": "Hiland - WY 82638",
    "county": "Natrona County",
    "state": "WY",
    "city": "Hiland"
  },
  {
    "value": "82639",
    "label": "Kaycee - WY 82639",
    "county": "Johnson County",
    "state": "WY",
    "city": "Kaycee"
  },
  {
    "value": "82642",
    "label": "Lysite - WY 82642",
    "county": "Fremont County",
    "state": "WY",
    "city": "Lysite"
  },
  {
    "value": "82643",
    "label": "Midwest - WY 82643",
    "county": "Natrona County",
    "state": "WY",
    "city": "Midwest"
  },
  {
    "value": "82646",
    "label": "Natrona - WY 82646",
    "county": "Natrona County",
    "state": "WY",
    "city": "Natrona"
  },
  {
    "value": "82649",
    "label": "Shoshoni - WY 82649",
    "county": "Fremont County",
    "state": "WY",
    "city": "Shoshoni"
  },
  {
    "value": "82701",
    "label": "Newcastle - WY 82701",
    "county": "Weston County",
    "state": "WY",
    "city": "Newcastle"
  },
  {
    "value": "82710",
    "label": "Aladdin - WY 82710",
    "county": "Crook County",
    "state": "WY",
    "city": "Aladdin"
  },
  {
    "value": "82711",
    "label": "Alva - WY 82711",
    "county": "Crook County",
    "state": "WY",
    "city": "Alva"
  },
  {
    "value": "82712",
    "label": "Beulah - WY 82712",
    "county": "Crook County",
    "state": "WY",
    "city": "Beulah"
  },
  {
    "value": "82714",
    "label": "Devils Tower - WY 82714",
    "county": "Crook County",
    "state": "WY",
    "city": "Devils Tower"
  },
  {
    "value": "82715",
    "label": "Four Corners - WY 82715",
    "county": "Weston County",
    "state": "WY",
    "city": "Four Corners"
  },
  {
    "value": "82716",
    "label": "Gillette - WY 82716",
    "county": "Campbell County",
    "state": "WY",
    "city": "Gillette"
  },
  {
    "value": "82718",
    "label": "Gillette - WY 82718",
    "county": "Campbell County",
    "state": "WY",
    "city": "Gillette"
  },
  {
    "value": "82720",
    "label": "Hulett - WY 82720",
    "county": "Crook County",
    "state": "WY",
    "city": "Hulett"
  },
  {
    "value": "82721",
    "label": "Moorcroft - WY 82721",
    "county": "Crook County",
    "state": "WY",
    "city": "Moorcroft"
  },
  {
    "value": "82723",
    "label": "Osage - WY 82723",
    "county": "Weston County",
    "state": "WY",
    "city": "Osage"
  },
  {
    "value": "82725",
    "label": "Recluse - WY 82725",
    "county": "Campbell County",
    "state": "WY",
    "city": "Recluse"
  },
  {
    "value": "82727",
    "label": "Rozet - WY 82727",
    "county": "Campbell County",
    "state": "WY",
    "city": "Rozet"
  },
  {
    "value": "82729",
    "label": "Sundance - WY 82729",
    "county": "Crook County",
    "state": "WY",
    "city": "Sundance"
  },
  {
    "value": "82730",
    "label": "Upton - WY 82730",
    "county": "Weston County",
    "state": "WY",
    "city": "Upton"
  },
  {
    "value": "82731",
    "label": "Weston - WY 82731",
    "county": "Campbell County",
    "state": "WY",
    "city": "Weston"
  },
  {
    "value": "82801",
    "label": "Sheridan - WY 82801",
    "county": "Sheridan County",
    "state": "WY",
    "city": "Sheridan"
  },
  {
    "value": "82831",
    "label": "Arvada - WY 82831",
    "county": "Sheridan County",
    "state": "WY",
    "city": "Arvada"
  },
  {
    "value": "82832",
    "label": "Banner - WY 82832",
    "county": "Sheridan County",
    "state": "WY",
    "city": "Banner"
  },
  {
    "value": "82834",
    "label": "Buffalo - WY 82834",
    "county": "Johnson County",
    "state": "WY",
    "city": "Buffalo"
  },
  {
    "value": "82835",
    "label": "Clearmont - WY 82835",
    "county": "Sheridan County",
    "state": "WY",
    "city": "Clearmont"
  },
  {
    "value": "82836",
    "label": "Dayton - WY 82836",
    "county": "Sheridan County",
    "state": "WY",
    "city": "Dayton"
  },
  {
    "value": "82838",
    "label": "Parkman - WY 82838",
    "county": "Sheridan County",
    "state": "WY",
    "city": "Parkman"
  },
  {
    "value": "82839",
    "label": "Ranchester - WY 82839",
    "county": "Sheridan County",
    "state": "WY",
    "city": "Ranchester"
  },
  {
    "value": "82842",
    "label": "Story - WY 82842",
    "county": "Sheridan County",
    "state": "WY",
    "city": "Story"
  },
  {
    "value": "82844",
    "label": "Wolf - WY 82844",
    "county": "Sheridan County",
    "state": "WY",
    "city": "Wolf"
  },
  {
    "value": "82901",
    "label": "Rock Springs - WY 82901",
    "county": "Sweetwater County",
    "state": "WY",
    "city": "Rock Springs"
  },
  {
    "value": "82922",
    "label": "Bondurant - WY 82922",
    "county": "Sublette County",
    "state": "WY",
    "city": "Bondurant"
  },
  {
    "value": "82923",
    "label": "Boulder - WY 82923",
    "county": "Sublette County",
    "state": "WY",
    "city": "Boulder"
  },
  {
    "value": "82925",
    "label": "Cora - WY 82925",
    "county": "Sublette County",
    "state": "WY",
    "city": "Cora"
  },
  {
    "value": "82930",
    "label": "Evanston - WY 82930",
    "county": "Uinta County",
    "state": "WY",
    "city": "Evanston"
  },
  {
    "value": "82932",
    "label": "Farson - WY 82932",
    "county": "Sweetwater County",
    "state": "WY",
    "city": "Farson"
  },
  {
    "value": "82933",
    "label": "Fort Bridger - WY 82933",
    "county": "Uinta County",
    "state": "WY",
    "city": "Fort Bridger"
  },
  {
    "value": "82935",
    "label": "Green River - WY 82935",
    "county": "Sweetwater County",
    "state": "WY",
    "city": "Green River"
  },
  {
    "value": "82936",
    "label": "Lonetree - WY 82936",
    "county": "Uinta County",
    "state": "WY",
    "city": "Lonetree"
  },
  {
    "value": "82937",
    "label": "Lyman - WY 82937",
    "county": "Uinta County",
    "state": "WY",
    "city": "Lyman"
  },
  {
    "value": "82941",
    "label": "Pinedale - WY 82941",
    "county": "Sublette County",
    "state": "WY",
    "city": "Pinedale"
  },
  {
    "value": "83001",
    "label": "Jackson - WY 83001",
    "county": "Teton County",
    "state": "WY",
    "city": "Jackson"
  },
  {
    "value": "83011",
    "label": "Kelly - WY 83011",
    "county": "Teton County",
    "state": "WY",
    "city": "Kelly"
  },
  {
    "value": "83012",
    "label": "Moose - WY 83012",
    "county": "Teton County",
    "state": "WY",
    "city": "Moose"
  },
  {
    "value": "83013",
    "label": "Moran - WY 83013",
    "county": "Teton County",
    "state": "WY",
    "city": "Moran"
  },
  {
    "value": "83014",
    "label": "Wilson - WY 83014",
    "county": "Teton County",
    "state": "WY",
    "city": "Wilson"
  },
  {
    "value": "83101",
    "label": "Kemmerer - WY 83101",
    "county": "Lincoln County",
    "state": "WY",
    "city": "Kemmerer"
  },
  {
    "value": "83110",
    "label": "Afton - WY 83110",
    "county": "Lincoln County",
    "state": "WY",
    "city": "Afton"
  },
  {
    "value": "83111",
    "label": "Auburn - WY 83111",
    "county": "Lincoln County",
    "state": "WY",
    "city": "Auburn"
  },
  {
    "value": "83112",
    "label": "Bedford - WY 83112",
    "county": "Lincoln County",
    "state": "WY",
    "city": "Bedford"
  },
  {
    "value": "83113",
    "label": "Big Piney - WY 83113",
    "county": "Sublette County",
    "state": "WY",
    "city": "Big Piney"
  },
  {
    "value": "83114",
    "label": "Cokeville - WY 83114",
    "county": "Lincoln County",
    "state": "WY",
    "city": "Cokeville"
  },
  {
    "value": "83115",
    "label": "Daniel - WY 83115",
    "county": "Sublette County",
    "state": "WY",
    "city": "Daniel"
  },
  {
    "value": "83118",
    "label": "Etna - WY 83118",
    "county": "Lincoln County",
    "state": "WY",
    "city": "Etna"
  },
  {
    "value": "83120",
    "label": "Freedom - WY 83120",
    "county": "Lincoln County",
    "state": "WY",
    "city": "Freedom"
  },
  {
    "value": "83122",
    "label": "Grover - WY 83122",
    "county": "Lincoln County",
    "state": "WY",
    "city": "Grover"
  },
  {
    "value": "83123",
    "label": "La Barge - WY 83123",
    "county": "Lincoln County",
    "state": "WY",
    "city": "La Barge"
  },
  {
    "value": "83126",
    "label": "Smoot - WY 83126",
    "county": "Lincoln County",
    "state": "WY",
    "city": "Smoot"
  },
  {
    "value": "83127",
    "label": "Thayne - WY 83127",
    "county": "Lincoln County",
    "state": "WY",
    "city": "Thayne"
  },
  {
    "value": "83128",
    "label": "Alpine - WY 83128",
    "county": "Lincoln County",
    "state": "WY",
    "city": "Alpine"
  },
  {
    "value": "83414",
    "label": "Alta - WY 83414",
    "county": "Teton County",
    "state": "WY",
    "city": "Alta"
  }
]