import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { FormattedMessage, useIntl } from "react-intl";
import Image from "react-bootstrap/Image";
import {
  ConfigProvider,
  Tabs,
  DatePicker,
  Form,
  Row,
  Col,
  Checkbox,
  Input,
  InputNumber,
  Select,
  Modal,
  Button,
  Spin,
  message,
  Radio,
} from "antd";
import theme from "@/AntDesignConfig";

import italicTextLogo from "@/assets/italic_text_logo.png";
import topAlertIcon from "@/assets/top_alert_icon.png";
import palletIcon from "@/assets/pallet_icon@3x.png";
import boxIcon from "@/assets/box_icon@3x.png";
import startIcon from "@/assets/Start@3x.png";
import endIcon from "@/assets/Stop@3x.png";
import iconAlert from "@/assets/icon_alert@2x.png";
import emptyIcon from "@/assets/empty_box.png";
import OrderDetails from "./OrderDetails";

import addCardIcon from "@/assets/add.svg";
import { createStyles } from "antd-style";

const useStyle = createStyles(({ prefixCls, css }) => ({
  linearGradientButton: css`
    &.${prefixCls}-btn-primary:not([disabled]):not(
        .${prefixCls}-btn-dangerous
      ) {
      border-width: 0;

      > span {
        position: relative;
        font-size: 17px;
        font-weight: 600;
      }

      &::before {
        content: "";
        background: linear-gradient(166deg, #ea7e30 0%, #f5be51 100%);
        position: absolute;
        inset: 0;
        opacity: 1;
        transition: all 0.3s;
        border-radius: inherit;
      }

      &:hover::before {
        opacity: 0;
      }
    }
  `,
}));

export default function OrderList(props) {
  const { orderList, goToQuote, continuePay } = props;
  const { styles } = useStyle();

  const [orderDetails, setOrderDetails] = useState(undefined);

  useEffect(() => {
    setOrderDetails();
  }, [orderList]);

  const [messageApi, contextHolder] = message.useMessage();
  const showAlertMessage = (content) => {
    messageApi.open({
      type: "error",
      content: content,
      style: {
        marginTop: "30vh",
      },
    });
  };

  return (
    <ConfigProvider
      theme={theme}
      button={{
        className: styles.linearGradientButton,
      }}
    >
      {contextHolder}
      {props.loading && (
        <div className="quote-wrapper">
          <div className="quote-wrapper-3">
            <Container className="form-container">
              <div
                style={{
                  width: "100%",
                  height: "360px",
                  backgroundColor: "#fff",
                  fontSize: "16px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spin size="large" tip="加载中..." />
              </div>
            </Container>
          </div>
        </div>
      )}
      {!props.loading && (
        <div className="quote-wrapper">
          <div className="quote-wrapper-3">
            {orderList.length === 0 && (
              <>
                <Container className="form-container">
                  <div
                    style={{
                      flex: 1,
                      height: "360px",
                      backgroundColor: "#fff",
                      fontSize: "16px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={emptyIcon}
                        style={{
                          width: "96px",
                          height: "96px",
                        }}
                      />
                      <div
                        style={{
                          marginLeft: "16px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "17px",
                            color: "#686868",
                            marginBottom: "12px",
                          }}
                        >
                          暂无订单
                        </div>
                        <div
                          style={{
                            fontSize: "17px",
                            color: "#686868",
                          }}
                        >
                          快去下单吧!
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    style={{
                      width: "194px",
                      height: "60px",
                      border: "none",
                      color: "#fff",
                      borderRadius: "8px",
                      marginTop: "50px",
                      cursor: "pointer",
                      background:
                        "linear-gradient(165.96deg, #EA7E30 0%, #F5BE51 100%)",
                      float: "right",
                    }}
                    onClick={goToQuote}
                  >
                    在线下单
                  </button>
                  <div
                    style={{
                      paddingBottom: "100px",
                    }}
                  ></div>
                </Container>
              </>
            )}
            {orderDetails && (
              <OrderDetails order={orderDetails} continuePay={continuePay} />
            )}
            {orderDetails === undefined && (
              <Container className="form-container">
                {orderList.map((order) => {
                  return (
                    <div
                      key={order.id}
                      style={{
                        border: "1px solid #EA7E3080",
                        borderRadius: "16px",
                        overflow: "hidden",
                        margin: "0 auto",
                        backgroundColor: "#FFF",
                        fontFamily: "Arial, sans-serif",
                        marginBlock: "16px",
                      }}
                      onClick={() => {
                        setOrderDetails(order);
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "8px 40px",
                          background:
                            "linear-gradient(to bottom, #EA7E30 0%, #F5BE51 100%)",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "17px",
                            color: "#fff",
                          }}
                        >
                          订单号 {order.order_id}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          padding: "24px 6px",
                        }}
                      >
                        <div
                          style={{
                            borderRight: "1px solid #E5E5E5",
                            width: "14%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              fontFamily: "PingFang SC",
                              fontSize: "16px",
                              fontWeight: 600,
                              lineHeight: "21.5px",
                              textAlign: "left",
                              marginBottom: "8px",
                            }}
                          >
                            订单价格
                          </div>
                          <div
                            style={{
                              fontSize: "18px",
                              color: "#E85D1F",

                              fontWeight: "bold",
                            }}
                          >
                            ${order.price}
                          </div>
                        </div>

                        <div
                          style={{
                            borderRight: "1px solid #E5E5E5",
                            width: "26%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              fontFamily: "PingFang SC",
                              fontSize: "16px",
                              fontWeight: 600,
                              lineHeight: "20px",
                              textAlign: "left",
                              marginBottom: "8px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              style={{
                                width: "18px",
                                height: "18px",
                                marginRight: "4px",
                              }}
                              src={iconAlert}
                              alt=""
                            />
                            <div>发货点 —— 送货点</div>
                          </div>
                          <div
                            style={{
                              fontFamily: "PingFang SC",
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "20px",
                              textAlign: "left",
                            }}
                          >
                            {order.pickup_address.city} －{" "}
                            {order.delivery_address.city}
                          </div>
                        </div>

                        {[0, 1, 2, 3, -1].includes(order.status) && (
                          <div
                            style={{
                              borderRight: "1px solid #E5E5E5",
                              width: "20%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                fontFamily: "PingFang SC",
                                fontSize: "16px",
                                fontWeight: 600,
                                lineHeight: "20px",
                                textAlign: "left",
                                marginBottom: "8px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                style={{
                                  width: "18px",
                                  height: "18px",
                                  marginRight: "4px",
                                }}
                                src={iconAlert}
                                alt=""
                              />
                              <div>
                                {order.estimated_date ? "送货时间" : "取货时间"}
                              </div>
                            </div>
                            <div
                              style={{
                                fontFamily: "PingFang SC",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "20px",
                                textAlign: "left",
                              }}
                            >
                              <span>
                                {order.estimated_date && (
                                  <span>
                                    {`${order.estimated_date}`} <br />{" "}
                                    {`${order.estimated_start_time}  - ${order.estimated_end_time}`}
                                  </span>
                                )}
                                {!order.estimated_date &&
                                  (order.pickup_estimated_start_time &&
                                  order.pickup_estimated_end_time ? (
                                    `${order.pickup_estimated_date} ${order.pickup_estimated_start_time} - ${order.pickup_estimated_end_time}`
                                  ) : (
                                    <span>
                                      {order.pickup_date} - <br />{" "}
                                      {order.pickup_date_end}
                                    </span>
                                  ))}
                              </span>
                            </div>
                          </div>
                        )}
                        {[4, 5, 6].includes(order.status) && (
                          <div
                            style={{
                              borderRight: "1px solid #E5E5E5",
                              width: "20%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                fontFamily: "PingFang SC",
                                fontSize: "16px",
                                fontWeight: 600,
                                lineHeight: "20px",
                                textAlign: "left",
                                marginBottom: "8px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                style={{
                                  width: "18px",
                                  height: "18px",
                                  marginRight: "4px",
                                }}
                                src={iconAlert}
                                alt=""
                              />
                              <div>
                                {order.estimated_date ? "送达时间" : "取货时间"}
                              </div>
                            </div>
                            <div
                              style={{
                                fontFamily: "PingFang SC",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "20px",
                                textAlign: "left",
                              }}
                            >
                              {order.delivery_at
                                ? new Date(
                                    order.delivery_at * 1000
                                  ).toLocaleString("en-US", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                    hour: "numeric",
                                    minute: "2-digit",
                                    hour12: true,
                                  })
                                : `${order.estimated_date} ${order.estimated_start_time} - ${order.estimated_end_time}`}
                            </div>
                          </div>
                        )}

                        <div
                          style={{
                            borderRight: "1px solid #E5E5E5",
                            width: "20%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              fontFamily: "PingFang SC",
                              fontSize: "16px",
                              fontWeight: 600,
                              lineHeight: "20px",
                              textAlign: "left",
                              marginBottom: "8px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              style={{
                                width: "18px",
                                height: "18px",
                                marginRight: "4px",
                              }}
                              src={iconAlert}
                              alt=""
                            />
                            <div>货物类别</div>
                          </div>
                          <div
                            style={{
                              fontFamily: "PingFang SC",
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "20px",
                              textAlign: "left",
                            }}
                          >
                            {order.goods_type}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "20%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              fontFamily: "PingFang SC",
                              fontSize: "16px",
                              fontWeight: 600,
                              lineHeight: "20px",
                              textAlign: "left",
                              marginBottom: "8px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              style={{
                                width: "18px",
                                height: "18px",
                                marginRight: "4px",
                              }}
                              src={iconAlert}
                              alt=""
                            />
                            <div>货物尺寸</div>
                          </div>
                          <div
                            style={{
                              fontFamily: "PingFang SC",
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "20px",
                              textAlign: "left",
                            }}
                          >
                            长{order.length}in, 宽{order.width}in, 高
                            {order.height}in
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          textAlign: "right",
                          marginTop: "10px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {!order.payment_card_id && (
                          <button
                            style={{
                              padding: "4px 20px",
                              margin: "0 10px 20px 0",
                              border: "1px solid #EA7E30",
                              color: "#EA7E30",
                              borderRadius: "20px",
                              cursor: "pointer",
                              backgroundColor: "#fff",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              continuePay(order.order_id);
                            }}
                          >
                            支付
                          </button>
                        )}
                        {[0, 1].includes(order.status) && (
                          <button
                            style={{
                              padding: "4px 20px",
                              margin: "0 40px 20px 0",
                              border: "1px solid red",
                              color: "red",
                              opacity: 0.5,
                              borderRadius: "20px",
                              cursor: "pointer",
                              backgroundColor: "#fff",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              props.deleteOrder(order.order_id);
                            }}
                          >
                            {"删除"}
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
              </Container>
            )}
          </div>
        </div>
      )}
    </ConfigProvider>
  );
}
