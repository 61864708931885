import React from "react";
import { useState, useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import { message, ConfigProvider, Button, Form, Input } from "antd";
import { useParams } from "react-router-dom";

import { AppContext } from "@/Context";

import theme from "@/AntDesignConfig";

import "./index.css";

import logo from "@/assets/app_logo@2x.png";
import loginRightBg from "@/assets/login_right_bg@2x.png";

// Import the necessary Firebase functions
import { logEvent } from "firebase/analytics";
import { analytics } from "@/components/firebaseConfig";

import { BASE_API_URL } from "@/network.config";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const SubmitButton = ({ form, loading, children }) => {
  const [submittable, setSubmittable] = useState(false);

  // Watch all values
  const values = Form.useWatch([], form);
  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);
  return (
    <Button
      type="primary"
      size="large"
      shape="round"
      htmlType="submit"
      className="login-form-button"
      disabled={!submittable}
      loading={loading}
    >
      {children}
    </Button>
  );
};

const Login = (props) => {
  const appCtx = useContext(AppContext);

  const [messageApi, messageContextHolder] = message.useMessage();

  const [submitting, setSubmitting] = useState(false);

  const [form] = Form.useForm();
  const paramsFromRouter = useParams();
  form.setFieldsValue(paramsFromRouter);

  const onFinish = (values) => {
    logEvent(analytics, "login_submit", values);
    submitFunc(values);
  };

  const networkErrorMsg = useIntl().formatMessage({
    id: "message.network.error",
  });

  const submitFunc = async (values) => {
    setSubmitting(true);
    try {
      const rawResponse = await fetch(`${BASE_API_URL}/api/v1/app/web/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...values,
          country_code: "+1",
        }),
      });
      const result = await rawResponse.json();

      if (result.code !== 0) {
        console.log(`request login api failed:${result}`);
        messageApi.error(result.errmsg);
        return;
      }
      appCtx.onLogin(result.data.access_token, values.phone);
      messageApi.success(
        useIntl().formatMessage({
          id: "message.network.success.login",
          description: "登录成功",
        })
      );
    } catch (error) {
      console.log(`request login api error:${error}`);
      messageApi.error(networkErrorMsg);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(() => {
        submitFunc(paramsFromRouter);
      });
  }, []);

  return (
    <ConfigProvider theme={theme}>
      {messageContextHolder}
      <div className="login-wrapper">
        <div className="login-container">
          <div class="left-wrapper">
            <div className="top-space">
              <div className="header-line">
                <a
                  className={"item"}
                  hoverable={true}
                  href={"/"}
                  target="_self"
                  rel="noopener noreferrer"
                >
                  {useIntl().formatMessage({
                    id: "page.login.header.back.home",
                    description: "返回首页",
                  })}
                </a>
                <a
                  className={"item"}
                  hoverable={false}
                  href={"/static/agent_portal_manual.pdf"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {useIntl().formatMessage({
                    id: "page.login.header.manual",
                    description: "使用手册",
                  })}
                </a>
              </div>
            </div>
            <img src={logo} className="logo-img" alt="" />
            <p className="logo-context">{`${useIntl().formatMessage({
              id: "page.login.title.main.1",
              description: "欢迎使用空仓货运",
            })}\n${useIntl().formatMessage({
              id: "page.login.title.main.2",
              description: "运单管理平台",
            })}`}</p>
            <Form
              {...layout}
              form={form}
              className="login-form"
              requiredMark={false}
              layout={"vertical"}
              onFinish={onFinish}
            >
              <Form.Item
                label={
                  <span className="login-form-label">
                    {useIntl().formatMessage({
                      id: "page.login.title.phone",
                      description: "手机号",
                    })}
                  </span>
                }
                name="phone"
                rules={[
                  {
                    required: true,
                    message: useIntl().formatMessage({
                      id: "page.login.error.required.phone",
                      description: "手机号必填错误提示",
                    }),
                  },
                ]}
              >
                <Input variant="filled" className="login-form-filled" />
              </Form.Item>
              <Form.Item
                label={
                  <span className="login-form-label">
                    {useIntl().formatMessage({
                      id: "page.login.title.code",
                      description: "登录口令",
                    })}
                  </span>
                }
                name="token"
                rules={[
                  {
                    required: true,
                    message: useIntl().formatMessage({
                      id: "page.login.error.required.code",
                      description: "登录口令必填错误提示",
                    }),
                  },
                ]}
              >
                <Input.Password
                  variant="filled"
                  className="login-form-filled"
                />
              </Form.Item>

              <Form.Item className="login-form-buttons-container">
                <SubmitButton form={form} loading={submitting}>
                  {useIntl().formatMessage({
                    id: "page.login.button.login",
                    description: "登录",
                  })}
                </SubmitButton>
              </Form.Item>
            </Form>
          </div>
          <div
            class="right-wrapper"
            style={{
              backgroundImage: `url(${loginRightBg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default Login;
