import React from "react";

import {
  GoogleMap,
  useJsApiLoader,
  Polyline,
} from "@react-google-maps/api";

export default function MyMap(props) {

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: 'AIzaSyBtFqwaaZ4Yyn2EQAUnRYkdnGmMWJfb-k8',
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(newMap) {
    setMap(newMap);
  }, []);

  const onUnmount = React.useCallback(function callback(newMap) {
    setMap(null);
  }, []);

const mapContainerStyle = {
    height: "555px",
    width: "100%",
    marginBottom: 10,
    borderRadius: 8,
  };

  const options = {
    strokeColor: "#1120f4",
    strokeOpacity: 1.0,
    strokeWeight: 8,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    map: map,
  };

  return isLoaded ? (
    <GoogleMap
      id="surveys"
      mapContainerStyle={mapContainerStyle}
      zoom={12}
      options={{
        streetViewControl: false,
        rotateControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
      }}
      center={props.center}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <Polyline path={props.path} options={options} />
    </GoogleMap>
  ) : (
    <p>not loaded</p>
  );
}