import React, { useContext } from "react";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FormattedMessage, useIntl } from "react-intl";
import { AppContext, getLanguageIntlMessages, languageList, Dropdown } from "@/Context";
import "./Header.css";
import router from "@/router";

import italicTextLogo from "@/assets/italic_text_logo_black.png";

export default function Header({ children }) {
  const { phone, isLoggedIn, currentLanguage, setCurrentLanguage, onLogout } =
    useContext(AppContext);
  const changeLanguageEvent = (val) => {
    setCurrentLanguage(getLanguageIntlMessages(val));
  };

  const items = [
    {
      key: 'shipper',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
          我是司机
        </a>
      ),
    },
    {
      key: 'carrier',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
        我要寄货
        </a>
      ),
      disabled: true,
    },
  ];

  return (
    <div className="Header-wrapper">
      <header className="App-header">
        <Navbar expand="lg" className="header-navbar">
          <Container className="container-navbar">
            <Navbar.Brand href="/" className="logo-title">
              <Image src={italicTextLogo} className={"logo-title-img"} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-end"
            >
              <Nav className="nav-link-wrapper">
                <Nav.Link href="/">
                  <FormattedMessage id="nav.link.home" />
                </Nav.Link>
                <Nav.Link href="/quote">
                  <FormattedMessage id="nav.link.quote" />
                </Nav.Link>
                {/* <Nav.Link href="/privacy-policy">
                  <FormattedMessage id="nav.link.privacy" />
                </Nav.Link>
                <Nav.Link href="/transaction-and-security">
                  <FormattedMessage id="nav.link.safety-of-transaction" />
                </Nav.Link>
                <Nav.Link href="/shipping-policies">
                  <FormattedMessage id="nav.link.transportation-strategy" />
                </Nav.Link>
                <Nav.Link href="/terms-of-service">
                  <FormattedMessage id="nav.link.terms-of-service" />
                </Nav.Link> */}
                <Nav.Link href="/contact-us">
                  <FormattedMessage id="nav.link.contact-us" />
                </Nav.Link>
              </Nav>
              <NavDropdown
                title={useIntl().formatMessage({
                  id: "nav.link.manual",
                  description: "手册下载",
                })}
                className="dropdown-manual"
              >
                <NavDropdown.Item
                  href="/static/shipper_app_flow_manual.pdf"
                  target="_blank"
                >
                  {useIntl().formatMessage({
                    id: "nav.link.manual.shipper.app",
                    description: "寄货人App端",
                  })}
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/static/carrier_app_flow_manual.pdf"
                  target="_blank"
                >
                  {useIntl().formatMessage({
                    id: "nav.link.manual.carrier.app",
                    description: "运货人App端",
                  })}
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/static/agent_portal_manual.pdf"
                  target="_blank"
                >
                  {useIntl().formatMessage({
                    id: "nav.link.manual.carrier.web",
                    description: "运货人网页端",
                  })}
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                onSelect={changeLanguageEvent}
                title={
                  currentLanguage.locale === "en"
                    ? "EN"
                    : currentLanguage.locale === "zh-CN"
                    ? "中文"
                    : currentLanguage.locale
                }
                id="basic-nav-dropdown"
                className="dropdown-language-switch"
              >
                {languageList.map((item) => {
                  let text;
                  if (item === "en") {
                    text = "EN";
                  } else if (item === "zh-CN") {
                    text = "中文";
                  } else {
                    text = item;
                  }

                  return (
                    <NavDropdown.Item key={item} href={item} as="span">
                      {text}
                    </NavDropdown.Item>
                  );
                })}
              </NavDropdown>
              {isLoggedIn ? (
                <NavDropdown
                  title={
                    useIntl().formatMessage({
                      id: "nav.link.user.prefix",
                      description: "账号前缀",
                    }) +
                    " " +
                    phone
                  }
                  className="dropdown-logout"
                  onSelect={(eventKey) => {
                    if (eventKey === "order") {
                      router.navigate("/orders");
                    } else if (eventKey === "logout") {
                      onLogout(false);
                    }
                  }}
                >
                  <NavDropdown.Item eventKey="order">
                    {useIntl().formatMessage({
                      id: "nav.link.order",
                      description: "订单管理",
                    })}
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey="logout">
                    {useIntl().formatMessage({
                      id: "nav.link.logout",
                      description: "登出",
                    })}
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <NavDropdown title={<FormattedMessage id="nav.link.login" />}>
                  <NavDropdown.Item href="/login">
                    我是司机
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/quote?modal=1">
                    我要寄货
                  </NavDropdown.Item>
                </NavDropdown>
              )}
            </Navbar.Collapse>
          </Container>
        </Navbar>
        {/*{children}*/}
      </header>
    </div>
  );
}
